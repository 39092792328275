<template>
    <div>
        <div class="title">设置网络钩子</div>
        <div style="display: flex;align-items: center;">
            <el-input v-model="webhooks" placeholder="请输入回调URL" size="small"></el-input>
            <el-switch v-model="isWebhooksEnable" style="margin: 0 20px;"></el-switch>
            <el-button type="primary" :disabled="!webhooks" :loading="btnLoading" @click="onSaveSet" size="small">保 存</el-button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            specialKey: "",
            webhooks: "",
            isWebhooksEnable: true,
            btnLoading: false,
        };
    },
    methods: {
        onSaveSet(){
            this.btnLoading = true;
            this.$axios.post(`${this.$openUrl}/ApplyManage/SetWebhooks`,{
                specialKey: this.specialKey,
                webhooks: this.webhooks,
                isWebhooksEnable: this.isWebhooksEnable,
            }).then((res) => {
                const { isSuccess } = res;
                this.btnLoading = false;
                if (isSuccess) {
                    this.$message({ message: '设置成功', type: 'success'});
                }
            });
        },

        getDetails(){
            this.$axios.get(`${this.$openUrl}/ApplyManage/GetApplyDetail?specialKey=${this.specialKey}`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.webhooks = data.webhooks;
                    this.isWebhooksEnable = data.isWebhooksEnable;
                }
            });
        },
    },
    mounted() {
        this.specialKey = this.$route.query.specialKey;
        this.getDetails();  //获取应用详情
    },
};
</script>

<style lang="scss" scoped>
.title{
    height: 36px;
    line-height: 36px;
    font-size: 18px;
    font-weight: 700;
    color: #333;
    margin-bottom: 10px;
}
</style>