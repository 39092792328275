<template>
    <div class="OrderSpending">
        <div class="title">
            <span>订单支出</span>
            <el-button class="newBtn" @click="toNewSpending" type="primary" icon="el-icon-plus" size="mini">新建支出</el-button>
        </div>
        <el-table :data="spendingList" :header-cell-style="{ 'background-color':'#F3F3F3','color':'#3A3A3A' }" :row-style="({ rowIndex }) => (rowIndex % 2 == 1 ? { 'background-color': '#F9FAFC' } : {})">
            <el-table-column prop="paymentName" label="款项名称"></el-table-column>
            <el-table-column prop="payerDate" label="支出日期"></el-table-column>
            <el-table-column prop="payerPayAmount" label="支出金额">
                <template slot-scope="scope">
                    <span>{{ scope.row.payerPayAmount }}</span>
                    <span style="padding-left: 5px;">{{ scope.row.collectionCurrency }}</span>
                    (<span>{{ scope.row.cnyAmount }}</span>
                    <span style="padding-left: 5px;">CNY</span>)
                </template>
            </el-table-column>
            <el-table-column label="操作" width="100">
                <template slot-scope="scope">
                    <el-link :underline="false" type="danger" @click="deleteSpending(scope.row)">删除</el-link>
                </template>
            </el-table-column>
        </el-table>

        <!-- 新建支出(简化) -->
        <el-dialog title="新建支出" :visible.sync="newSpendingVisible" width="400px">
            <el-form label-width="100px" size="small">
                <el-form-item label="款项名称">
                    <el-input v-model="spendingForm.paymentName"></el-input>
                </el-form-item>
                <el-form-item label="支出日期">
                    <el-date-picker v-model="spendingForm.payerDate" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
                </el-form-item>
                <el-form-item label="支出金额">
                    <el-input-number v-model="spendingForm.payerPayAmount" :min="0" :controls="false" @blur="calcCNYPrice"></el-input-number>
                </el-form-item>
                <el-form-item label="币种">
                    <el-select v-model="spendingForm.collectionCurrency" size="small" filterable style="width:130px;" @change="chooseCurrency">
                        <el-option v-for="item in currencyOption" :key="item.key" :label="item.currency" :value="item.currency"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="汇率">
                    <el-input-number :disabled="spendingForm.collectionCurrency=='CNY'" v-model="spendingForm.exchangeRate" size="small" :min="0" :controls="false" @blur="calcCNYPrice"></el-input-number>
                </el-form-item>
                <el-form-item label="人民币金额">
                    <el-input-number :disabled="spendingForm.collectionCurrency=='CNY'" v-model="spendingForm.cnyAmount" size="small" :min="0" :controls="false" @blur="calcRate"></el-input-number>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="newSpendingVisible = false">取 消</el-button>
                <el-button size="small" type="primary" :disabled="!spendingForm.paymentName" @click="newSpendingSubmit" :loading="btnLoading">确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import dayjs from "dayjs";
export default {
    props:["basicInfo"],
    data() {
        return {
            spendingList: [],
            btnLoading: false,
            //新建支出(简化)
            newSpendingVisible: false,
            spendingForm: {},
            currencyOption: [],
        };
    },
    methods: {
        getSpendingInfo(){
            this.$axios.post(`${this.$orderUrl}/api/Collection/GetCollections`,{
                orderKey: this.basicInfo.orderKey,
                type: 2,
                pageIndex: 1,
                pageSize: 20,
            }).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    this.spendingList = data.collectionData;
                }
            });
        },
        //新建支出(简化)
        toNewSpending() {
            this.newSpendingVisible = true;
            let userInfo = JSON.parse(localStorage.userInfo);
            this.spendingForm = {
                "type": 2,                                  //新建支出
                "paymentName": "",
                "payer": userInfo.companyCnFullName,        //付款方 
                "payerKey": userInfo.companyKey,            //付款方key
                "payerPayAmount": 0,                        //付款方付款金额
                "payerDate": dayjs().format('YYYY-MM-DD'),
                "payee": this.basicInfo.customerName,       //收款方
                "payeeKey": this.basicInfo.customerKey,     //收款方key
                "payeeCollectionAmount": 0,                 //收款方收款金额
                "collectionCurrency": "CNY",
                "exchangeRate": 1,
                "cnyAmount": 0,
                "handler": userInfo.userCnName,
                "remarks": "",
                "orderKey": this.basicInfo.orderKey,  //关联订单
            }
        },
        newSpendingSubmit(){
            this.spendingForm.payeeCollectionAmount = this.spendingForm.payerPayAmount;
            this.btnLoading = true;
            this.$axios.post(`${this.$orderUrl}/api/Collection/SaveCollection`,this.spendingForm).then((res) => {
                const { isSuccess } = res;
                this.btnLoading = false;
                this.newSpendingVisible = false;
                if (isSuccess) {
                    this.getSpendingInfo();
                    this.$message({ message: '新建成功', type: 'success'});
                }
            });
        },
        //删除支出
        deleteSpending(row){
            this.$confirm('是否删除该支出?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios.get(`${this.$orderUrl}/api/Collection/DeleteCollection?collectionKey=${row.key}`).then((res) => {
                    const { isSuccess } = res;
                    if (isSuccess) {
                        this.$message({ message: '删除成功', type: 'success'});
                        this.getSpendingInfo();
                    }
                });
            }).catch(() => {});
        },

        getCurrencyConfig(){
            this.$axios.get(`${this.$customerUrl}/v1/Config/GetCurrencyConfig`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.currencyOption = data;
                }
            })
        },
        chooseCurrency(value){
            let cItem = this.currencyOption.filter(ele => ele.currency == value)[0];
            this.spendingForm.exchangeRate = cItem.exchangeRate;
            this.calcCNYPrice();
        },
        calcCNYPrice(){
            this.spendingForm.cnyAmount = this.spendingForm.payerPayAmount * this.spendingForm.exchangeRate * 1;
            this.spendingForm.cnyAmount = this.spendingForm.cnyAmount.toFixed(2) * 1;
        },
        calcRate(){
            this.spendingForm.exchangeRate = this.spendingForm.cnyAmount / this.spendingForm.payerPayAmount;
            this.spendingForm.exchangeRate = this.spendingForm.exchangeRate.toFixed(8) * 1;
        },
    },
    mounted() {
        this.orderKey = this.$route.query.orderKey;
    },
    watch:{
        basicInfo(){
            this.getSpendingInfo();
            this.getCurrencyConfig();
        }
    }
};
</script>

<style lang="scss" scoped>
.OrderSpending{
    margin-top: 20px;
    .title{
        height: 20px;
        line-height: 20px;
        font-size: 16px;
        font-weight: bold;
        color: #3A3A3A;
        margin-bottom: 10px;
        border-left: 3px solid var(--themeColor);
        padding-left: 5px;
        .newBtn{
            float: right;
        }
    }
}
</style>