<script>
import Vue from "vue";
import router from "@/router";
import { reactive, ref } from "@vue/composition-api";
import axios from "@/assets/js/axios";
import CMenu from "../MarketingWA/components/Menu.vue";
import HuaXiangVue from './ReportCom/HuaXiang.vue';
import KanXinVue from './ReportCom/KanXin.vue';
export default Vue.extend({
    components: { CMenu, HuaXiangVue, KanXinVue },
    setup() {
        // 获取员工
        const staffList = ref([]);
        const activeUkey = ref("all");
        const ukeys = ref([]);
        const getStaff = async () => {
            const res = await axios({
                method: "GET",
                url:
                    Vue.prototype.$personnelUrl +
                    "/Organization/FindSaleByOrganization",
                params: {
                    organizationKey: "",
                },
            });
            const { isSuccess, data } = res;
            if (isSuccess) {
                staffList.value = data;
                queryData();
            }
        };
        getStaff();
        //查询报表数据
        const queryData = () => {
            if(activeUkey.value == 'all'){
                ukeys.value = staffList.value.map(item => item.key);
            }else{
                ukeys.value = [activeUkey.value];
            }
            // console.log(ukeys.value);
            getCount();
            getAddresseeList();
            getStatusBox();
        };

        const countData = reactive({
            clickPeopleCount: 0,
            clickRate: 0,
            openAvg: 0,
            openFrequency: 0,
            openPeopleCount: 0,
            openRate: 0,
            sendsEnvelopes: 0,
            sendsFrequency: 0,
            totalSends: 0,
            unsubscribeCount: 0,
        });
        const getCount = async() => {
            const res = await axios({
                method: "POST",
                url: Vue.prototype.$marketUrl + "/MassAnalysis/GetUserSendEffectz",
                data: ukeys.value,
            });
            countData.clickPeopleCount = res.data.clickPeopleCount;
            countData.clickRate = res.data.clickRate;
            countData.openAvg = res.data.openAvg;
            countData.openFrequency = res.data.openFrequency;
            countData.openPeopleCount = res.data.openPeopleCount;
            countData.openRate = res.data.openRate;
            countData.sendsEnvelopes = res.data.sendsEnvelopes;
            countData.sendsFrequency = res.data.sendsFrequency;
            countData.totalSends = res.data.totalSends;
            countData.unsubscribeCount = res.data.unsubscribeCount;
        };

        const addressee = reactive({
            loading: false,
            list: [],
            page: {
                total: 0,
                index: 1,
            },
        });
        const newFilterForm = reactive({
            recipientStatus: 0, //收件人状态 0 全部 1 多次看信 2 已查看邮件 3 点击链接 4 投递成功 5 发信失败 6 取消订阅
            date: 0, //0 全部 1 3日内已看信 2 7日内已看信 3 30日内已看信
            sort: 0, //排序 0 看信时间 1 收件人邮箱
            addressee: "",
        })
        const getAddresseeList = async() => {
            try {
                addressee.loading = true;
                const res = await axios({
                    method: "POST",
                    url: Vue.prototype.$marketUrl + "/MassAnalysis/PageReportUserSents",
                    data: {
                        pageIndex: addressee.page.index,
                        pageSize: 10,
                        ukeys: ukeys.value,
                        ...newFilterForm,
                    },
                });
                // console.log(res.data);
                addressee.list = res.data.datas;
                addressee.page.total = res.data.totalItems;
            } catch {
                null;
            } finally {
                addressee.loading = false;
            }
        };
        
        const onFilter = () =>{
            addressee.page.index = 1;
            getAddresseeList();
        }

        //再次群发
        const againSendJump = () =>{
            let sendParamOne = {
                pageIndex: 1,
                pageSize: 5000,
                ukeys: ukeys.value,
                ...newFilterForm,
            }
            localStorage.setItem("sendParamOne",JSON.stringify(sendParamOne));
            let routeData = router.resolve({
                path: '/marketing/MailMarketing',
                query: { againSendType: 1 },
            });
            window.open(routeData.href, "_blank");
        }

        //获取收件人状态下拉框
        const statusOption = ref([]);
        const getStatusBox = async () => {
            try {
                const res = await axios({
                    method: 'POST',
                    url: Vue.prototype.$marketUrl + '/MassAnalysis/GetRecipientStatusBox',
                    data: ukeys.value,
                })
                statusOption.value = res.data;
            } catch { null }
        }

        const copyText = (value) =>{
            const input = document.createElement('input');
            document.body.appendChild(input);
            input.setAttribute('value', value);
            input.select();
            if (document.execCommand('copy')) {
                document.execCommand('copy');
            }
            document.body.removeChild(input);
            Message.success('复制成功')
        }
        const timer = ref();
        const handleLoad = () => {
            // 刷新子组件
            timer.value = new Date().getTime()
        }

        return {
            staffList,
            activeUkey,
            ukeys,
            getStaff,
            queryData,
            countData,
            getCount,
            tabMode: "aaa",
            addressee,
            getAddresseeList,
            copyText,
            timer,
            handleLoad,
            statusOption,
            newFilterForm,
            onFilter,
            againSendJump,
        };
    },
});
</script>

<template>
    <div style="display: flex; box-sizing: border-box;width: 100%;height: 100%;">
        <div>
            <CMenu active="TXRpoert" />
        </div>
        <div class="view">
            <div class="selectBox">
                <el-tabs v-model="activeUkey" @tab-click="queryData">
                    <el-tab-pane label="全部" name="all"></el-tab-pane>
                    <el-tab-pane v-for="item in staffList" :key="item.key" :label="item.name" :name="item.key"></el-tab-pane>
                </el-tabs>
            </div>
            <div class="countBox">
                <div class="countItem">
                    <div class="value">{{countData.totalSends}}个</div>
                    <div class="text">收件人</div>
                </div>
                <div class="countItem">
                    <div class="value">{{countData.sendsFrequency}}次</div>
                    <div class="text">群发次数</div>
                </div>
                <div class="countItem">
                    <div class="value">{{countData.sendsEnvelopes}}封</div>
                    <div class="text">邮件封数</div>
                </div>
                <div class="countItem">
                    <div class="value">{{countData.openPeopleCount}}个</div>
                    <div class="text">看信人数</div>
                </div>
                <div class="countItem">
                    <div class="value">{{countData.openFrequency}}次</div>
                    <div class="text">看信次数</div>
                </div>
                <div class="countItem">
                    <div class="value">{{countData.openRate}}%</div>
                    <div class="text">看信率</div>
                </div>
                <div class="countItem">
                    <div class="value">{{countData.openAvg}}次</div>
                    <div class="text">人均看信</div>
                </div>
                <div class="countItem">
                    <div class="value">{{countData.clickPeopleCount}}个</div>
                    <div class="text">点击数</div>
                </div>
                <div class="countItem">
                    <div class="value">{{countData.clickRate}}%</div>
                    <div class="text">点击率</div>
                </div>
                <div class="countItem">
                    <div class="value">{{countData.unsubscribeCount}}个</div>
                    <div class="text">取消订阅数</div>
                </div>
            </div>
            <div class="tabBox">
                <el-tabs v-model="tabMode">
                    <el-tab-pane label="收件人" name="aaa" />
                    <el-tab-pane label="群发画像" name="bbb" />
                    <el-tab-pane label="看信记录" name="ccc" />
                </el-tabs>
                <div class="content-wrapper">
                    <div v-show="tabMode == 'aaa'" v-loading="addressee.loading">
                        <div style="display: flex;justify-content: space-between;margin-bottom: 10px;">
                            <div style="height: 32px;display: flex;align-items: center;">
                                <el-input v-model="newFilterForm.addressee" @change="onFilter" style="width:220px;" placeholder="收件人" size="small"></el-input>
                                <span style="font-size: 14px;color: #6a6a6a;margin-left: 20px;">状态：</span>
                                <el-select v-model="newFilterForm.recipientStatus" style="width:180px;" size="small" @change="onFilter">
                                    <el-option v-for="item in statusOption" :key="item.key" :label="item.name" :value="item.key">
                                        <span>{{ item.name }}</span>
                                        <span>({{ item.count }})</span>
                                    </el-option>
                                </el-select>
                                <span style="font-size: 14px;color: #6a6a6a;margin-left: 20px;">时间：</span>
                                <el-select v-model="newFilterForm.date" style="width:180px;" size="small" @change="onFilter">
                                    <el-option label="全部" :value="0"></el-option>
                                    <el-option label="3日内已看信" :value="1"></el-option>
                                    <el-option label="7日内已看信" :value="2"></el-option>
                                    <el-option label="30日内已看信" :value="3"></el-option>
                                </el-select>
                                <span style="font-size: 14px;color: #6a6a6a;margin-left: 20px;">排序：</span>
                                <el-select v-model="newFilterForm.sort" style="width:180px;" size="small" @change="onFilter">
                                    <el-option label="看信时间" :value="0"></el-option>
                                    <el-option label="收件人邮箱" :value="1"></el-option>
                                </el-select>
                            </div>
                            <el-button type="primary" plain size="small" @click="againSendJump">再次群发</el-button>
                        </div>
                        <div style="height: 35px; background: #F9F9F9; display: flex; align-items: center; color: #475669; font-weight: bold">
                            <div style="box-sizing: border-box; width: 35%; padding-left: 30px">收件人</div>
                            <div style="width: 65%; text-align: center">状态</div>
                        </div>
                        <div class="item" style="padding-left: 30px" v-for="item of addressee.list" :key="item.senderAddress">
                            <div style="width: 35%; display: flex; align-items: center">
                                <img src="@/assets/img/headImg.png" style="width: 50px; height: 50px" />
                                <div style="padding-left: 20px; font-size: 14px">
                                    <p style="color: #1F2D3D; font-weight: bold">{{ item.name }}</p>
                                    <p class="senderName">
                                        <span>{{ item.senderAddress }}</span>
                                        <el-tooltip effect="dark" content="复制" placement="bottom">
                                            <i class="el-icon-copy-document" @click="copyText(item.senderAddress)"></i>
                                        </el-tooltip>
                                    </p>
                                </div>
                            </div>
                            <div style="width: 65%; display: flex; align-items: center">
                                <div style="flex-shrink: 0; font-size: 14px; padding-right: 20px;">
                                    <div v-for="ele in item.status" :key="ele" style="line-height: 24px;">{{ele}}</div>
                                </div>
                                <el-descriptions :column="3" :labelStyle="{ color: '#1F2D3D' }" :contentStyle="{ color: '#1F2D3D' }">
                                    <el-descriptions-item label="职位">{{ item.position || '--' }}</el-descriptions-item>
                                    <el-descriptions-item label="发信次数">{{ item.sendCount || '--' }}</el-descriptions-item>
                                    <el-descriptions-item label="看信次数">{{ item.opneCount || '--' }}</el-descriptions-item>
                                    <el-descriptions-item label="公司">{{ item.companyName || '--' }}</el-descriptions-item>
                                    <el-descriptions-item label="发信时间">{{ item.sendTime || '--' }}</el-descriptions-item>
                                    <el-descriptions-item label="看信时间">{{ item.opneTime || '--' }}</el-descriptions-item>
                                </el-descriptions>
                            </div>
                        </div>
                        <div style="display: flex; justify-content: flex-end">
                            <el-pagination hide-on-single-page :current-page.sync="addressee.page.index" :total="addressee.page.total" @current-change="getAddresseeList" background layout="total, prev, pager, next, jumper" />
                        </div>
                    </div>
                    <div v-show="tabMode == 'bbb'">
                        <HuaXiangVue :ukeys="ukeys" :key="timer" />
                    </div>
                    <div v-show="tabMode == 'ccc'">
                        <KanXinVue :ukeys="ukeys" :key="timer" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.view {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background: #f9f9fa;
    padding: 20px;
    box-sizing: border-box;
    overflow: auto;
    .selectBox {
        background: #fff;
        box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
        margin-bottom: 10px;
        padding: 0 20px;
    }
    .countBox {
        background: #fff;
        box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
        margin-bottom: 10px;
        padding: 20px;
        display: flex;
        .countItem {
            width: 10%;
            text-align: center;
            .value {
                line-height: 28px;
                font-size: 18px;
                font-weight: bold;
            }
            .text {
                font-size: 14px;
                color: #6a6a6a;
            }
        }
    }
    .tabBox {
        background: #fff;
        box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
        padding: 0 20px 20px;
        .content-wrapper{
            .item {
                box-sizing: border-box;
                border-bottom: 1px solid #D3DCE6;
                display: flex;
                padding: 20px 0;
            }
            .el-icon-copy-document{
                cursor: pointer;
                padding-left: 5px;
                font-size: 16px;
                color: var(--themeColor);
            }
            .senderName{
                cursor: pointer;
                .el-icon-copy-document{
                    display: none;
                }
                &:hover .el-icon-copy-document{
                    display: inline-block;
                }
            }
        }
    }
}
</style>
