<template>
  <!-- 复用 -->
  <el-popover v-model="show" placement="bottom-end" trigger="click" :visible-arrow="false">
    <div class="content" style="width: 136px">
      <div class="row" style="margin-bottom: 12px">
        <span @click="feedback('数据错误')" class="cursor-pointer">数据错误</span>
        <span @click="feedback('数据不全')" class="cursor-pointer">数据不全</span>
      </div>
      <div class="row">
        <span @click="feedback('打开速度慢')" class="cursor-pointer">打开速度慢</span>
        <span @click="feedback('无反应')" class="cursor-pointer">无反应</span>
      </div>
    </div>
    <div></div>
    <div v-if="false" class="feedback cursor-pointer" slot="reference">
      <img src="@/firm/images/company/icon-feedback.png" style="margin-left: 1em" />
      <span style="color: var(--themeColor, #3152AF); font-size: 14px; margin-left: 0.5em">反馈</span>
    </div>
  </el-popover>
</template>

<script>
import Vue from "vue";
import { getUserAgentInfo } from "@/firm/utils/broswer";
import axios from "@/firm/plugins/axios";

export default Vue.extend({
  props: ["position", "url"],
  inheritAttrs: false,
  data() {
    return {
      show: false,
    };
  },
  methods: {
    feedback(reason) {
      this.show = false;
      const [browser, browserVersion] = getUserAgentInfo();
      axios({
        method: "post",
        url: "/search/Credit/InsertFeedbackRecord",
        data: {
          position: this.position,
          reason,
          browser,
          browserVersion,
          url: this.url,
        },
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.content {
  .row {
    display: flex;
    justify-content: space-between;
    span {
      font-size: 12px;
      display: inline-block;
      padding: 2px 7px;
      border-radius: 5px;
      background: rgb(255, 242, 230);
      color: rgb(233, 145, 25);
    }
  }
}
.feedback {
  display: flex;
  align-items: center;
}
</style>
