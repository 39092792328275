<template>
<div>
    <div v-if="ie=='i'">
        <!-- 秘鲁(PER)进口提单明细 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th width="25%">进口商ID</th>
                        <td width="25%">{{billInfo.ImporterId}}</td>
                        <th width="25%">进口商电话</th>
                        <td width="25%">{{billInfo.ImporterPhone}}</td>
                    </tr>
                    <tr>
                        <th>进口商传真</th>
                        <td>{{billInfo.ImporterFax}}</td>
                        <th>数量单位</th>
                        <td>{{billInfo.MeasureUnit}}</td>
                    </tr>
                    <tr>
                        <th>商业数量</th>
                        <td>{{billInfo.CommercialQuantity}}</td>
                        <th>商业数量单位</th>
                        <td>{{billInfo.CommercialUnit}}</td>
                    </tr>
                    <tr>
                        <th>商品数量单价</th>
                        <td>{{billInfo.CommercialUnitPrice}}</td>
                        <th>净重</th>
                        <td>{{billInfo.NetWeight}}</td>
                    </tr>
                    <tr>
                        <th>CIF单价（美元）</th>
                        <td>{{billInfo.UsCifUnit}}</td>
                        <th>CIF总价（美元）</th>
                        <td>{{billInfo.UsCif}}</td>
                    </tr>
                    <tr>
                        <th>FOB单价（美元）</th>
                        <td>{{billInfo.UsFobUnit}}</td>
                        <th>FOB总价（美元）</th>
                        <td>{{billInfo.Value}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">运输信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">来源国</th>
                        <td width="25%">{{billInfo.Country}}</td>
                        <th width="25%">中转国</th>
                        <td width="25%">{{billInfo.AcquisitionCountry}}</td>
                    </tr>
                    <tr>
                        <th>装运港</th>
                        <td>{{billInfo.ShippingPort}}</td>
                        <th>海关</th>
                        <td>{{billInfo.Custom}}</td>
                    </tr>
                    <tr>
                        <th>报关代理人</th>
                        <td>{{billInfo.CustomAgent}}</td>
                        <th>运输方式</th>
                        <td>{{billInfo.TransportType}}</td>
                    </tr>
                    <tr>
                        <th>运输公司</th>
                        <td>{{billInfo.TransportCompany}}</td>
                        <th>运费(美元)</th>
                        <td>{{billInfo.Freight}}</td>
                    </tr>
                    <tr>
                        <th>保险(美元)</th>
                        <td colspan="3">{{billInfo.Insurance}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">货物信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">制造年份</th>
                        <td width="25%">{{billInfo.ManufactureYear}}</td>
                        <th width="25%">产品状态</th>
                        <td width="25%">{{billInfo.ProductStatus}}</td>
                    </tr>
                    <tr>
                        <th>包装数量</th>
                        <td>{{billInfo.PackageQuantity}}</td>
                        <th>包装方式</th>
                        <td>{{billInfo.PackageType}}</td>
                    </tr>
                    <tr>
                        <th>成交方式</th>
                        <td>{{billInfo.Incoterm}}</td>
                        <th>交易地点</th>
                        <td>{{billInfo.ShippingPort}}</td>
                    </tr>
                    <tr>
                        <th>从价税</th>
                        <td>{{billInfo.Advalorem}}</td>
                        <th>地方税</th>
                        <td>{{billInfo.LocalTax}}</td>
                    </tr>
                    <tr>
                        <th>银行</th>
                        <td>{{billInfo.Bank}}</td>
                        <th>系列/批量</th>
                        <td>{{billInfo.Serie}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
    <div v-if="ie=='e'">
        <!-- 秘鲁(PER)出口提单明细 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th width="25%">报关单号</th>
                        <td width="25%">{{billInfo.DuaId}}</td>
                        <th width="25%">出口商ID</th>
                        <td width="25%">{{billInfo.ExporterId}}</td>
                    </tr>
                    <tr>
                        <th>出口商电话</th>
                        <td>{{billInfo.ExporterPhone}}</td>
                        <th>出口商传真</th>
                        <td>{{billInfo.ExporterFax}}</td>
                    </tr>
                    <tr>
                        <th>数量单位</th>
                        <td>{{billInfo.MeasureUnit}}</td>
                        <th>净重</th>
                        <td>{{billInfo.NetWeight}}</td>
                    </tr>
                    <tr>
                        <th>商业数量</th>
                        <td>{{billInfo.CommercialQuantity}}</td>
                        <th>商业数量单位</th>
                        <td>{{billInfo.CommercialUnit}}</td>
                    </tr>
                    <tr>
                        <th>FOB单价（美元）</th>
                        <td>{{billInfo.UsFobUnit}}</td>
                        <th>FOB总价（美元）</th>
                        <td>{{billInfo.Value}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">运输信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">目的国</th>
                        <td width="25%">{{billInfo.Country}}</td>
                        <th width="25%">目的港</th>
                        <td width="25%">{{billInfo.DestPort}}</td>
                    </tr>
                    <tr>
                        <th>海关</th>
                        <td>{{billInfo.Custom}}</td>
                        <th>报关代理人</th>
                        <td>{{billInfo.CustomAgent}}</td>
                    </tr>
                    <tr>
                        <th>运输方式</th>
                        <td>{{billInfo.TransportType}}</td>
                        <th>船</th>
                        <td>{{billInfo.Ship}}</td>
                    </tr>
                    <tr>
                        <th>运输公司</th>
                        <td>{{billInfo.TransportCompany}}</td>
                        <th>银行</th>
                        <td>{{billInfo.Bank}}</td>
                    </tr>
                    <tr>
                        <th>系列/批量</th>
                        <td colspan="3">{{billInfo.Serie}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
</div>
</template>

<script>
export default {
    props:["billInfo","ie"],
    data() {
        return {

        };
    },
    methods: {
        
    },
    mounted() {
        
    },
};
</script>

<style lang="scss" scoped>
.partItem {
    margin-bottom: 20px;
    /deep/.el-card__header {
        padding: 10px 20px;
        .headTitle {
            display: inline-block;
            padding-left: 10px;
            font-size: 14px;
            border-left: 2px solid var(--themeColor);
            font-weight: bold;
        }
    }
}
table {
    width: 100%;
    border-collapse: collapse;
    tr {
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;
        th,
        td {
            font-size: 12px;
            color: #666;
            height: 40px;
            border: 1px solid #eaeaea;
            text-align: center;
        }
        th {
            background-color: #f5f7fa;
            font-weight: 700;
        }
        .bg {
            background-color: var(--themeColor);
            color: #fff;
            font-weight: 700;
            opacity: 0.8;
        }
    }
}
</style>