<template>
<div style="display: flex;">
    <ClueNav @chooseNav="chooseNav" :currentPath="currentPath" />
    <div class="viewbox">
        <div class="header">
            <img class="logo" src="@/assets/cluePicture/logo-tender.png" alt="" />
            <div>
                <div class="theme">招标信息线索</div>
                <div class="txt">统计来自招标信息的相关线索</div>
            </div>
        </div>

        <div class="searchTerms">
            <!-- <div class="itemTerm">
                <div class="name">洲:</div>
                <div class="spanItem" v-for="ele in continentList" :key="ele" :class="{'active':continentIndex==ele}" @click="continentIndex=ele">
                    {{ele}}
                </div>
            </div> -->
            <div class="itemTerm">
                <div class="name">国家:</div>
                <div class="spanItem" :class="{'active':countryIndex==''}" @click="clickCountry('')">不限</div>
                <div class="spanItem" v-for="ele in countryList" :key="ele" :class="{'active':countryIndex==ele}" @click="clickCountry(ele)">
                    {{ele}}
                </div>
            </div>
            <!-- <div class="itemTerm">
                <div class="name">招标时间:</div>
                <div class="name">开始时间:</div>
                <el-date-picker v-model="startTime" size="mini" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
                <div class="name">结束时间:</div>
                <el-date-picker v-model="endTime" size="mini" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
            </div> -->
            <!-- <div class="itemTerm">
                <div class="name">招标类型:</div>
                <div class="spanItem" v-for="ele in typeList" :key="ele" :class="{'active':typeIndex==ele}" @click="typeIndex=ele">
                    {{ele}}
                </div>
            </div> -->
            <!-- <div class="itemTerm">
                <div class="name">招标范围:</div>
                <div class="spanItem" v-for="ele in scopeList" :key="ele" :class="{'active':scopeIndex==ele}" @click="scopeIndex=ele">
                    {{ele}}
                </div>
            </div> -->
            <div class="searchBox" style="margin-top: 20px;">
                <el-input placeholder="招标名称" size="small" prefix-icon="el-icon-search" v-model="searchValue" clearable style="width:400px;"></el-input>
                <el-button type="primary" style="margin-left:20px;" @click="onSearch" size="small">搜索</el-button>
            </div>
        </div>

        <div class="searchResult" v-show="totalItems!=0" v-loading="searchLoading">
            <div class="title">为您找到<span style="color:#FF2D2D;">{{totalItems}}</span>条相关结果</div>
            <div class="resultList">
                <div class="resultItem" v-for="item in resultList" :key="item.key">
                    <div style="margin-bottom: 8px;">
                        <div class="name">{{item.name}}</div>
                        <div class="country">
                            <img :src="item.flagUrl" style="margin-right:5px;height:14px" />
                            <span>{{item.country}}</span>
                        </div>
                    </div>                    
                    <div class="field">
                        <span class="color99">开展日期：</span>
                        <span class="color3a">{{item.publishedDate}}</span>
                    </div>
                    <div class="field">
                        <span class="color99">关闭日期：</span>
                        <span class="color3a">{{item.closesDate}}</span>
                    </div>
                    <div class="field">
                        <span class="color99">代理商：</span>
                        <span class="color3a">{{item.agency}}</span>
                    </div>
                    <div class="field">
                        <span class="color99">合同价值：</span>
                        <span class="color3a">{{item.contractValue}}</span>
                    </div>
                    <div class="field">
                        <span class="color99">种类：</span>
                        <span class="color3a">{{item.category}}</span>
                    </div>
                    <div class="field">
                        <span class="color99">网址：</span>
                        <span class="primaryColor" @click="openWebsite(item.url)">{{item.url}}</span>
                    </div>
                </div>
                <el-pagination background layout="total,sizes,prev,pager,next" :page-sizes="[10, 20, 40]" :page-size.sync="pageSize" @size-change="pageSizeChange" :total="totalItems" style="float:right;margin-top:15px;" :current-page.sync="pageIndex" @current-change="pageIndexChange"></el-pagination>
            </div>
        </div>

    </div>
</div>
</template>

<script>
import ClueNav from "@/components/ClueNav.vue";
export default {
    components: { ClueNav },
    props: {
        handleSelect: {
            type: Function,
            default: null,
        },
    },
    data() {
        return {
            currentPath: "/clue/tenderClue",
            //搜索条件
            // continentList: ['亚洲','北美洲','欧洲','南美洲','大洋洲'],
            // continentIndex: "亚洲",
            countryList: [],
            countryIndex: "",
            // startTime: "",
            // endTime: "",
            // typeList: ['征求建议书','兴趣登记','询价'],
            // typeIndex: "征求建议书",
            // scopeList: ['独家代理','所有政府'],
            // scopeIndex: "独家代理",
            searchLoading: false,
            searchValue: "",
            resultList: [],
            totalItems: 0,
            pageIndex: 1,
            pageSize: 10,
        };
    },
    methods: {
        chooseNav(index) {
            this.handleSelect(index);
            this.$router.push(index);
        },

        //获取招标国家
        getCountrys(){
            this.$axios.get(`${this.$clueUrl}/Tender/GetCountry`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.countryList = data;
                }
            });
        },
        clickCountry(ele){
            this.countryIndex = ele;
            this.onSearch();
        },

        getResult(){
            if(this.searchLoading){ 
                return false;   //数据正在请求中,无法进行下一个请求;
            }
            //请求接口获取数据
            this.searchLoading = true;
            this.$axios.post(`${this.$clueUrl}/Tender/List`,{
                "name": this.searchValue,
                "country": this.countryIndex,
                "pageIndex": this.pageIndex,
                "pageSize": this.pageSize,
            }).then((res) => {
                const { isSuccess, data } = res;
                this.searchLoading = false;
                if (isSuccess) {
                    this.totalItems = data.totalItems;
                    if(this.totalItems == 0){
                        this.$message({ message: '搜索无数据,请修改搜索条件重试!',type: 'warning' });
                        return false;
                    }
                    this.resultList = data.datas.map(item=>({
                        ...item,
                        flagUrl: window.getFlagUrlByCountry?window.getFlagUrlByCountry(item.country):'',
                    }))
                }
            });
        },
        onSearch(){
            this.pageIndex = 1;
            this.getResult();
        },
        pageIndexChange(page) {
            this.pageIndex = page;
            if(this.pageIndex > 30){
                this.pageIndex = 30;
                this.$notify({ title: '警告',message: '目前线索模块处于免费试用阶段,搜索页码不能超过30!',type: 'warning' });
                return false;
            }
            this.getResult();
        },
        pageSizeChange() {
            this.pageIndex = 1;
            this.getResult();
        },
      
        //打开网址
        openWebsite(url){
            let jumpUrl = url.indexOf("http") > -1 ? url : "http://" + url;
            let routeData = this.$router.resolve({
                path: "/jumpWeb",
                query: { jumpUrl: jumpUrl },
            });
            window.open(routeData.href, "_blank");
        },
    },
    mounted() {
        this.getCountrys();
        this.getResult();
    },
};
</script>

<style lang="scss" scoped>
@import '@/scss/common.scss';
.viewbox{
    width: calc(100% - 200px);
    height: calc(100vh - 80px);
    box-sizing: border-box;
    overflow: auto;
    background: rgba($color: #FFFFFF, $alpha: 0.7);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    padding: 20px;
    .header{
        display: flex;
        padding-bottom: 20px;
        border-bottom: 1px solid #E1E1E1;
        .logo{
            padding: 0 15px;
        }
        .theme{
            font-size: 20px;
            line-height: 32px;
            font-weight: bold;
            color: #092338;
        }
        .txt{
            font-size: 14px;
            line-height: 20px;
            color: #092338;
        }
    }

    .searchTerms{
        margin-top: 20px;
        .itemTerm{
            display: flex;
            height: 26px;
            line-height: 26px;
            margin-bottom: 10px;
            .name{
                width: 65px;
                font-size: 14px;
                color: #092338;
                text-align: right;
                margin-right: 15px;
            }
            .spanItem{
                font-size: 14px;
                color: #092338;
                padding: 0 10px;
                margin-right: 15px;
                cursor: pointer;
            }
            .active{
                color: #FFFFFF;
                background: $themeColor;
                border-radius: 2px;
            }
        }
    }

    .searchResult{
        margin-top: 20px;
        .title{
            height: 40px;
            line-height: 40px;
            background: #F1F1F1;
            color: #3A3A3A;
            padding-left: 20px;
        }
        .resultList{
            padding: 20px;
            overflow: hidden;
            .resultItem{
                font-size: 14px;
                padding: 10px 0;
                border-bottom: 1px solid #E8E8E8;
                .name{
                    display: inline-block;
                    color: var(--themeColor);
                    // text-decoration: underline;
                    // cursor: pointer;
                }
                .country{
                    display: inline-block;
                    margin-left: 20px;
                    img{
                        vertical-align: middle;
                    }
                }
                .field{
                    line-height: 28px;
                }
                .color99{    
                    color: #999999;
                }
                .color3a{    
                    color: #3A3A3A;
                }
                .primaryColor{
                    color: var(--themeColor);
                    cursor: pointer;
                }
            }
        }
    }
}
</style>