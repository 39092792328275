<template>
    <div class="addSpending">
        <div class="title">新建支出</div>

        <!-- 请选择订单 -->
        <!-- <div class="selectOrder">
            <span style="padding-right:20px;">订单编号</span>
            <el-select v-model="addForm.orderKey" placeholder="请选择订单" style="width:200px;" size="small" @change="selectChange">
                <el-option v-for="item in orderOption" :key="item.key" :label="item.orderNo" :value="item.key"></el-option>
            </el-select>
            <span style="padding-left:20px;color:red;">请选择订单后在填写下方信息</span>
        </div> -->
        

        <div class="oneSide">
            <div class="title">
                <img src="@/assets/img/money2.png" alt="" />
                <span class="sp1">收款方：{{addForm.payee}}</span>
            </div>
            <el-form :model="addForm" label-width="80px" size="small" style="margin-top:20px;">
                <el-row :gutter="16">
                    <el-col :span="12">
                        <el-form-item label="收款方式">
                            <el-input v-model="addForm.payeePaymentMethod"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="跟进人">
                            <el-input v-model="addForm.payeeFollowUp"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="收款银行">
                            <el-input v-model="addForm.payeeBank"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="收款账号">
                            <el-input v-model="addForm.payeeCollectionAccount"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="收款日期">
                            <el-date-picker v-model="addForm.payeeCollectionDate" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="收款金额">
                            <el-input-number v-model="addForm.payeeCollectionAmount" :min="0" :controls="false" class="orange"></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <div class="twoSide">
            <div class="title">
                <span class="sp2">{{addForm.payer}}</span>
            </div>
            <el-form :model="addForm" label-width="80px" size="small" style="margin-top:20px;">
                <el-row :gutter="16">
                    <el-col :span="12">
                        <el-form-item label="支付方式">
                            <el-input v-model="addForm.payerPayType"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="联系人">
                            <el-input v-model="addForm.payerContacts"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="开户银行">
                            <el-input v-model="addForm.payerBank"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="支付账号">
                            <el-input v-model="addForm.payerPayAccount"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="付款日期">
                            <el-date-picker v-model="addForm.payerDate" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="付款金额">
                            <el-input-number v-model="addForm.payerPayAmount" :min="0" :controls="false"></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>

        <el-form :model="addForm" label-width="100px" size="small" style="margin-top:20px;">
            <el-row :gutter="16">
                <el-col :span="8">
                    <el-form-item label="款项名称">
                        <el-input v-model="addForm.paymentName" class="w220"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="收汇币种">
                        <el-input v-model="addForm.collectionCurrency" class="w220"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="汇率">
                        <el-input-number v-model="addForm.exchangeRate" :min="0" :controls="false" class="w220"></el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="美金汇率">
                        <el-input-number v-model="addForm.usExchangeRate" :min="0" :controls="false" class="w220"></el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="收汇净额">
                        <el-input-number v-model="addForm.collectionNetAmount" :min="0" :controls="false" class="w220"></el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="收汇净额($)">
                        <el-input-number v-model="addForm.usCollectionNetAmount" :min="0" :controls="false" class="w220"></el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="经手人">
                        <el-input v-model="addForm.handler" class="w220"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="订单已收金额">
                        <el-input-number v-model="addForm.amountReceived" :min="0" :controls="false" class="w220"></el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="订单待收金额">
                        <el-input-number v-model="addForm.remainingAmount" :min="0" :controls="false" class="w220"></el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="备注说明">
                        <el-input v-model="addForm.remarks" class="w220"></el-input>
                    </el-form-item>
                </el-col>
                 <el-col :span="8" :offset="8">
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit" :loading="btnLoading" :disabled="isSubmit">立即创建</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>

    </div>
</template>

<script>
export default {
    data() {
        return {
            btnLoading: false,
            isSubmit: false,
            addForm: {
                "type": 2,  //新建支出
                //付款方
                "payer": "",        
                "payerKey": "",     //付款方key
                "payerPayType": "", //付款方支付方式
                "payerContacts": "",//付款方联系人
                "payerBank": "",    //付款方开户行
                "payerPayAccount": "",//付款方支付账号
                "payerDate": "",    //付款方付款日期
                "payerPayAmount": 0,//付款方付款金额
                //收款方
                "payee": "",
                "payeeKey": "",     //收款方key
                "payeePaymentMethod": "",//收款方收款方式
                "payeeFollowUp": "",    //收款方跟进人
                "payeeBank": "",        //收款方收款银行
                "payeeCollectionAccount": "",//收款方收款账号
                "payeeCollectionDate": "",  //收款方收款日期
                "payeeCollectionAmount": 0, //收款方收款金额
                //收款信息
                "weCompany": "",
                "collectionCurrency": "CNY",
                "exchangeRate": 1,
                "usExchangeRate": 0,
                "collectionNetAmount": 0,
                "usCollectionNetAmount": 0,
                "collectionAmount": 0,
                "amountReceived": 0,
                "remainingAmount": 0,
                "handler": "",
                "handlerKey": "",
                "remarks": "",
                "relatedContract": "",
                "paymentName": ""
            },
            orderOption: [],
        };
    },
    methods: {
        onSubmit(){
            if(this.addForm.payerPayAmount == 0 || this.addForm.payeeCollectionAmount == 0){
                this.$message({ message: '付款金额,收款金额不能为0', type: 'warning'});
                return false;
            }
            this.btnLoading = true;
            this.$axios.post(`${this.$orderUrl}/api/Collection/AddCollection`,this.addForm).then((res) => {
                const { isSuccess } = res;
                this.btnLoading = false;
                if (isSuccess) {
                    this.$message({ message: '提交成功', type: 'success'});
                    this.isSubmit = true;
                }
            });
        },

        //订单列表选择一个订单
        getOrderOption(){
            this.$axios.post(`${this.$orderUrl}/api/Order`,{
                pageIndex: 1,
                pageSize: 100
            }).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    this.orderOption = data.datas;
                    
                    let orderKey = this.$route.query.orderKey;
                    if(orderKey){
                        this.addForm.orderKey = orderKey;
                        this.selectChange(orderKey);
                    }
                }
            });
        },
        selectChange(value){
            let chooseItem = this.orderOption.filter(ele => ele.key == value)[0];
            this.addForm.payee = chooseItem.customerName;
        }
    },
    mounted() {
        this.getOrderOption();

        let userInfo = JSON.parse(localStorage.userInfo);
        this.addForm.payer = userInfo.companyCnFullName;
        this.addForm.payerKey = userInfo.companyKey;
        this.addForm.handler = userInfo.userCnName;

        //初始化客户信息
        let customerName = this.$route.query.customerName;
        let customerKey = this.$route.query.customerKey;
        this.addForm.payee = customerName;
        this.addForm.payeeKey = customerKey;
    },
};
</script>

<style lang="scss" scoped>
.addSpending{
    background: #FFFFFF;
    padding: 20px;
    .title{
        height: 32px;
        line-height: 32px;
        font-size: 16px;
        font-weight: bold;
        color: #3A3A3A;
        margin-bottom: 10px;
    }
    .twoSideInfo{
        background: #F6FBFE;
        border: 1px solid #5DE5EB;
        display: flex;
        .leftSide,.rightSide{
            width: 45%;
            margin-right: 5%;
            .title{
                height: 32px;
                line-height: 32px;
                font-size: 16px;
                font-weight: bold;
                position: relative;
                img{
                    position: absolute;
                    top: 8px;
                }
                .sp1{
                    padding-left: 20px;
                    color: #9C9C9C;
                }
            }
        }
    }

    .selectOrder{
        margin-bottom:20px;
        font-size:14px;
    }

    .oneSide{
        background: rgba(245, 157, 99, 0.1);
        border: 1px solid #F59D63;
    }
    .twoSide{
        background: rgba(215, 215, 216, 0.15);
        border: 1px solid #D7D7D8;
    }
    .oneSide,.twoSide{
        padding: 20px;
        margin-bottom: 20px;
        .title{
            height: 32px;
            line-height: 32px;
            font-size: 16px;
            font-weight: bold;
            position: relative;
            img{
                position: absolute;
                top: 8px;
            }
            .sp1{
                padding-left: 20px;
                color: #E5694F;
            }
            .sp2{                   
                color: #3A3A3A;
            }
        }
    }

    .w220{
        width: 220px;
        height: 32px;
    }
    .orange /deep/.el-input__inner{
        color: #E5694F;
        font-weight: bold;
    }
}
</style>