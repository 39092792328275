<template>
    <div class="logBox">
        <div class="title">登录日志</div>
        <div class="searchBox">
            <el-select v-if="isAdmin" v-model="searchForm.account" placeholder="账号" class="searchItem" size="small" @change="onSearch">
                <el-option v-for="item in staffOption" :key="item.key" :label="item.account" :value="item.account">
                    <span>{{ item.name }}&lt;{{ item.account }}&gt;</span>
                </el-option>
            </el-select>
            <el-select v-model="searchForm.loginType" class="searchItem" size="small" @change="onSearch">
                <el-option label="全部" :value="-1"></el-option>
                <el-option label="账户密码登录" :value="0"></el-option>
                <el-option label="手机号验证码登陆" :value="3"></el-option>
                <el-option label="微信公众号扫码" :value="1"></el-option>
                <el-option label="VIP授权登录" :value="6"></el-option>
            </el-select>
            <el-input v-model="searchForm.ip" @keyup.enter.native="onSearch" placeholder="登陆IP" size="small" class="searchItem"></el-input>
            <el-date-picker v-model="searchForm.startTime" type="date" value-format="yyyy-MM-dd" @change="onSearch" size="small" placeholder="开始时间" class="searchItem"></el-date-picker>
            <el-date-picker v-model="searchForm.endTime" type="date" value-format="yyyy-MM-dd" @change="onSearch" size="small" placeholder="结束时间" class="searchItem"></el-date-picker>
            <el-button type="primary" size="small" icon="el-icon-search" @click="onSearch">搜 索</el-button>
        </div>
        <div style="margin-top:20px;overflow: hidden;">
            <el-table :data="loginLogList" style="width: 100%" size="small">
                <el-table-column prop="account" label="账号"></el-table-column>
                <el-table-column prop="loginTime" label="登录时间"></el-table-column>
                <el-table-column prop="ip" label="登陆IP"></el-table-column>
                <el-table-column prop="location" label="登陆地址"></el-table-column>
                <el-table-column prop="loginStatusStr" label="登录状态"></el-table-column>
                <el-table-column prop="loginTypeStr" label="登陆类型"></el-table-column>
            </el-table>
            <el-pagination hide-on-single-page background layout="total, sizes, prev, pager, next" :page-sizes="[10, 20, 40]" :page-size.sync="pageSize" @size-change="pageSizeChange" :total="totalItems" small style="float: right; margin: 10px 0" :current-page.sync="pageIndex" @current-change="pageIndexChange"></el-pagination>
        </div>
    </div>
</template>

<script>
import dayjs from "dayjs";
export default {
    data() {
        return {
            isAdmin: false,
            staffOption: [],
            searchForm: {
                "account": "",
                "loginType": -1,
                "ip": "",
                //开始时间
                "startTime": dayjs().startOf('month').format('YYYY-MM-DD'),
                //结束时间
                "endTime": dayjs().endOf('month').format('YYYY-MM-DD'),
            },
            loginLogList: [],
            tableLoading: false,
            totalItems: 220,
            pageIndex: 1,
            pageSize: 10,            
        };
    },
    methods: {
        getList() {
            // console.log(this.searchForm);
            this.tableLoading = true;
            this.$axios.post(`${this.$personnelUrl}/Staff/GetLoginLog`, {
                ...this.searchForm,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
            }).then((res) => {
                const { isSuccess, data } = res;
                this.tableLoading = false;
                if (isSuccess) {
                    this.loginLogList = data.datas;
                    this.totalItems = data.totalItems;
                }
            });
        },
        pageIndexChange(page) {
            this.pageIndex = page;
            this.getList();
        },
        pageSizeChange() {
            this.pageIndex = 1;
            this.getList();
        },
        onSearch() {
            this.pageIndex = 1;
            this.getList();
        },

        //获取所有成员集合
        getStaffBox(){
            this.$axios.get(`${this.$personnelUrl}/Organization/FindStaffBox`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.staffOption = data;
                    // console.log(data);
                }
            })
        },

    },
    mounted() {
        this.getList();
        this.isAdmin = JSON.parse(localStorage.userInfo).isAdmin;
        if(this.isAdmin){
            this.getStaffBox();
        }
    },
};
</script>

<style lang="scss" scoped>
.logBox{
    background: #FFFFFF;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    padding: 20px;
    height: calc(100vh - 120px);
    overflow: auto;
    .title{
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 20px;
    }
    .searchBox{
        display: flex;
        .searchItem {
            width: 200px;
            margin-right: 10px;
        }
    }
}
</style>