<script>
import Vue from 'vue'
import { ref, defineComponent } from '@vue/composition-api'
import CustomerDynamics from '@/components/CustomerDynamics.vue'
import InfoPanel from './components/InfoPanel.vue'
import StatusPanel from './components/StatusPanel.vue'
import MoneyPanel from './components/MoneyPanel.vue'
import AnnexPanel from './components/AnnexPanel.vue'
import axios from '@/assets/js/axios'
import router from '@/router'
export default defineComponent({
  components: {
    CustomerDynamics,
    InfoPanel,
    StatusPanel,
    MoneyPanel,
    AnnexPanel
  },
  setup() {
    const businessKey = ref(router.currentRoute.query.key || '')
    const companyKey = ref('');
    (async () => {
      try {
        const res = await axios({
          method: 'GET',
          url: Vue.prototype.$sjUrl + '/BusinessChance/GetBusinessDetail',
          params: {
            businessKey: businessKey.value
          }
        })
        if (!res.isSuccess) throw new Error()
        companyKey.value = res.data.companyKey
      } catch { null }
    })()
    const StatusPanelInstance = ref()
    const CustomerDynamicsInstance = ref()
    return {
      businessKey,
      companyKey,
      StatusPanelInstance,
      CustomerDynamicsInstance,
      onInfoPanelStageChange() {
        StatusPanelInstance.value.getAllData()
        CustomerDynamicsInstance.value.refreshAllRecords()
      }
    }
  }
})
</script>

<template>
  <div class="detail-panel">
    <div class="top">
      <span>商机详情</span>
    </div>
    <div class="body">
      <div style="box-sizing: border-box; width: 100%; height: 100%; padding: 0 20px; border-right: 1px solid #B7B7B7">
        <div style="box-sizing: border-box; width: 100%; height: 291px; overflow-y: hidden">
          <InfoPanel :businessKey="businessKey" @stage-change="onInfoPanelStageChange" @delete="$emit('close')" />
        </div>
        <div style="box-sizing: border-box; width: 100%; height: calc(100% - 291px)">
          <div style="background: #E5E9F2; font-size: 16px; color: #000; display: flex; align-items: center; padding-left: 16px; height: 32px; box-sizing: border-box; margin-bottom: 10px">跟进动态</div>
          <el-scrollbar class="scrollable" style="height: calc(100% - 42px)">
            <div style="padding-bottom: 20px">
              <CustomerDynamics v-if="companyKey" :customerKey="companyKey" ref="CustomerDynamicsInstance" />
            </div>
          </el-scrollbar>
        </div>
      </div>
      <div style="box-sizing: border-box; width: 100%; height: 100%; padding: 0 20px">
        <div style="box-sizing: border-box; height: 35%">
          <StatusPanel :businessKey="businessKey" ref="StatusPanelInstance" />
        </div>
        <el-scrollbar class="scrollable" style="height: 65%">
          <MoneyPanel :businessKey="businessKey" />
          <AnnexPanel :businessKey="businessKey" />
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
::v-deep .el-dialog {
  margin: 0 auto;
}

::v-deep .el-dialog__header {
  display: none;
}

::v-deep .el-dialog__body {
  padding: 0;
}

.detail-panel {
  background: #fff;
  box-sizing: border-box;
  height: calc(100vh - 40px);
  font-size: 16px;
}

.top {
  box-sizing: border-box;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  color: #3a3a3a;
  border-bottom: 1px solid #d9d9d9;
}

.body {
  box-sizing: border-box;
  height: calc(100% - 60px);
  display: grid;
  grid-template-columns: 740px calc(100% - 740px);
  grid-template-rows: 100%;
  padding-top: 20px;
}

.scrollable {
  ::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
  }
}
</style>
