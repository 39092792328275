<template>
    <div>
        <div style="height: 60px; padding-left: 10px; display: flex; align-items: center; font-size: 14px; color: #707070">
            <span style="flex-shrink: 0">日期：</span>
            <el-date-picker type="daterange" @change="dateChange" v-model="form.date" value-format="yyyy-MM-dd" size="small" unlink-panels />
        </div>
        <div class="grid-container" style="height: calc(100% - 60px)">
            <div class="grid-item-panel" v-loading="fenbu.loading">
                <div class="grid-item-select">
                    <div @click="setType('fenbu', '1')" class="grid-item-select-option" :class="{ active: fenbu.type === '1' }">状态
                    </div>
                    <div @click="setType('fenbu', '2')" class="grid-item-select-option" :class="{ active: fenbu.type === '2' }">
                        国家地区</div>
                    <div @click="setType('fenbu', '3')" class="grid-item-select-option" :class="{ active: fenbu.type === '3' }">来源
                    </div>
                    <div @click="setType('fenbu', '4')" class="grid-item-select-option" :class="{ active: fenbu.type === '4' }">星级
                    </div>
                    <div @click="setType('fenbu', '5')" class="grid-item-select-option" :class="{ active: fenbu.type === '5' }">分组
                    </div>
                </div>
                <div class="grid-item-panel-title">
                    <div style="display: flex; align-items: flex-end">
                        <span class="grid-item-panel-title-text">客户分布</span>
                        <span class="grid-item-panel-title-sub-text" style="margin-left: 10px">{{ fenbu.date }}</span>
                    </div>
                    <div class="icon-container">
                        <img src="@/assets/icon/refresh.png" />
                        <img src="@/assets/icon/close.png" />
                    </div>
                </div>
                <div ref="fenbu-dom" class="grid-item-panel-chart-dom"></div>
            </div>
            <div class="grid-item-panel" v-loading="dingdanjineqingkuang.loading">
                <div class="grid-item-panel-title">
                    <div style="display: flex; align-items: flex-end">
                        <span class="grid-item-panel-title-text">客户订单金额情况</span>
                    </div>
                    <div class="icon-container">
                        <img src="@/assets/icon/refresh.png" />
                        <img src="@/assets/icon/close.png" />
                    </div>
                </div>
                <div ref="dingdanjineqingkuang-dom" class="grid-item-panel-chart-dom"></div>
            </div>
            <div class="grid-item-panel" v-loading="zhongdiangengjin.loading">
                <div class="grid-item-panel-title bb">
                    <div style="display: flex; align-items: flex-end">
                        <span class="grid-item-panel-title-text">重点跟进客户</span>
                    </div>
                    <div class="icon-container">
                        <img src="@/assets/icon/refresh.png" />
                        <img src="@/assets/icon/close.png" />
                    </div>
                </div>
                <div class="grid-item-panel-chart-dom">
                    <el-table class="custom-el-table" :data="zhongdiangengjin.tableData" height="calc(100% - 20px)">
                        <el-table-column label="#" prop="order" width="50px" align="center" />
                        <el-table-column label="客户" prop="name" show-overflow-tooltip />
                        <el-table-column label="销售金额总额" prop="amount" show-overflow-tooltip />
                        <el-table-column label="均单价" prop="price" show-overflow-tooltip />
                        <el-table-column label="成单量" prop="count" show-overflow-tooltip />
                    </el-table>
                </div>
            </div>
            <div class="grid-item-panel" v-loading="guanjianxingweiqushi.loading">
                <div class="grid-item-panel-title bb">
                    <div style="display: flex; align-items: flex-end">
                        <span class="grid-item-panel-title-text">客户关键行为趋势</span>
                    </div>
                    <div class="icon-container">
                        <img src="@/assets/icon/refresh.png" />
                        <img src="@/assets/icon/close.png" />
                    </div>
                </div>
                <div ref="guanjianxingweiqushi-dom" class="grid-item-panel-chart-dom"></div>
            </div>
        </div>
    </div>
</template>

<script>
import * as echarts from "echarts";
import dayjs from "dayjs";
export default {
    chartInstance: {
        fenbu: null,
        dingdanjineqingkuang: null,
        guanjianxingweiqushi: null,
    },
    data() {
        const date = [
            dayjs().subtract(90, "days").format("YYYY-MM-DD"),
            dayjs().format("YYYY-MM-DD"),
        ];
        return {
            form: {
                range: "",
                date,
            },
            fenbu: {
                loading: false,
                date: date.join(" —— "),
                type: "1",
            },
            dingdanjineqingkuang: {
                loading: false,
            },
            zhongdiangengjin: {
                loading: false,
                tableData: [],
            },
            guanjianxingweiqushi: {
                loading: false,
            },
        };
    },
    watch: {
        "fenbu.type"() {
            this.getFenbu();
        },
    },
    methods: {
        setType(key, value) {
            this[key].type = value;
        },
        async getFenbu() {
            const [startDate, endDate] = this.form.date || [];
            const { type } = this.fenbu;
            if (!(startDate && endDate && type)) {
                return;
            }
            try {
                this.fenbu.loading = true;
                const res = await this.$axios({
                    method: "GET",
                    url: this.$customerUrl + "/Report/CustomerDistribution",
                    params: {
                        type,
                        startDate,
                        endDate,
                    },
                });
                const data = res.data;
                this.$options.chartInstance.fenbu.setOption({
                    series: [
                        {
                            name: "客户分布",
                            data,
                        },
                    ],
                });
                this.fenbu.date = this.form.date.join(" —— ");
            } catch {
                null;
            } finally {
                this.fenbu.loading = false;
            }
        },
        async getDingdanjineqingkuang() {
            try {
                this.dingdanjineqingkuang.loading = true;
                const res = await this.$axios({
                    method: "GET",
                    url: this.$customerUrl + "/Report/GetOrderAmount",
                });
                const data = res.data;
                this.$options.chartInstance.dingdanjineqingkuang.setOption({
                    xAxis: {
                        data: data.data,
                    },
                    series: data.series,
                });
            } catch {
                null;
            } finally {
                this.dingdanjineqingkuang.loading = false;
            }
        },
        async getZhongdiangengjin() {
            try {
                this.zhongdiangengjin.loading = true;
                const res = await this.$axios({
                    method: "GET",
                    url: this.$customerUrl + "/Report/ImportantCustomer",
                });
                const data = res.data;
                this.zhongdiangengjin.tableData = data.map((item) => ({
                    order: item.order,
                    name: item.name,
                    amount: item.amount,
                    price: item.price,
                    count: item.count,
                }));
            } catch {
                null;
            } finally {
                this.zhongdiangengjin.loading = false;
            }
        },
        async getGuanjianxingweiqushi() {
            try {
                this.guanjianxingweiqushi.loading = true;
                const res = await this.$axios({
                    method: "GET",
                    url: this.$customerUrl + "/Report/CustomerTrend",
                });
                const data = res.data;
                this.$options.chartInstance.guanjianxingweiqushi.setOption({
                    xAxis: {
                        data: data.month,
                    },
                    series: data.series,
                });
            } catch {
                null;
            } finally {
                this.guanjianxingweiqushi.loading = false;
            }
        },

        dateChange(val) {
            if (val) {
                this.getFenbu();
                localStorage.setItem("timeRange", val);
            }
        },
    },
    mounted() {
        //获取上次选择的时间段
        if (localStorage.timeRange) {
            this.form.date = localStorage.timeRange.split(",");
        }

        this.$options.chartInstance.fenbu = echarts.init(
            this.$refs["fenbu-dom"]
        );
        this.$options.chartInstance.fenbu.setOption({
            legend: {
                top: "middle",
                type: "scroll",
                orient: "vertical",
                right: "5%",
                icon: "circle",
                textStyle: {
                    color: "#7C90A5",
                },
                itemWidth: 14,
                itemHeight: 14,
            },
            tooltip: {},
            series: [
                {
                    name: "客户分布",
                    type: "pie",
                    radius: ["30%", "70%"],
                    center: ["40%", "50%"],
                    label: {
                        show: true,
                        color: "#94979C",
                    },
                    labelLine: {
                        smooth: true,
                        lineStyle: {
                            color: "#94979C",
                        },
                    },
                    emphasis: {
                        scale: false,
                    },
                    data: [],
                },
            ],
            color: [
                "#62D0D3",
                "#67A0C8",
                "#9BC586",
                "#DFD982",
                "#DC7683",
                "#8897C0",
                "#2560A3",
            ],
        });
        this.$options.chartInstance.dingdanjineqingkuang = echarts.init(
            this.$refs["dingdanjineqingkuang-dom"]
        );
        this.$options.chartInstance.dingdanjineqingkuang.setOption({
            legend: {
                top: 5,
                right: 30,
                icon: "circle",
                itemWidth: 8,
                itemHeight: 8,
                itemStyle: {
                    opacity: 1,
                },
                itemGap: 20,
            },
            grid: {
                containLabel: true,
                top: 40,
                left: 30,
                right: 30,
                bottom: 20,
            },
            xAxis: {
                axisLine: {
                    lineStyle: {
                        color: "#BFBFBF",
                    },
                },
                axisTick: {
                    alignWithLabel: true,
                },
                axisLabel: {
                    color: "#595959",
                },
                data: [],
            },
            yAxis: {
                axisLabel: {
                    color: "#595959",
                },
                splitLine: {
                    lineStyle: {
                        color: "#E7E7E7",
                        type: "dashed",
                    },
                },
            },
            tooltip: {
                trigger: "axis",
            },
            series: [
                {
                    name: "2019",
                    type: "line",
                    itemStyle: {
                        opacity: 0,
                    },
                    data: [],
                },
                {
                    name: "2020",
                    type: "line",
                    itemStyle: {
                        opacity: 0,
                    },
                    data: [],
                },
                {
                    name: "2021",
                    type: "line",
                    itemStyle: {
                        opacity: 0,
                    },
                    data: [],
                },
            ],
            color: ["#2E7DB5", "#79D6D9", "#D65F6E"],
        });
        this.$options.chartInstance.guanjianxingweiqushi = echarts.init(
            this.$refs["guanjianxingweiqushi-dom"]
        );
        this.$options.chartInstance.guanjianxingweiqushi.setOption({
            legend: {
                top: 5,
                right: 30,
                icon: "circle",
                itemWidth: 10,
                itemHeight: 10,
                itemStyle: {
                    opacity: 1,
                },
                itemGap: 22,
            },
            grid: {
                containLabel: true,
                top: 40,
                left: 30,
                right: 30,
                bottom: 10,
            },
            xAxis: {
                axisLine: {
                    lineStyle: {
                        color: "#BFBFBF",
                    },
                },
                axisTick: {
                    alignWithLabel: true,
                },
                axisLabel: {
                    color: "#595959",
                },
                data: [],
            },
            yAxis: {
                axisLabel: {
                    color: "#595959",
                },
                splitLine: {
                    lineStyle: {
                        color: "#E7E7E7",
                        type: "dashed",
                    },
                },
            },
            tooltip: {},
            series: [
                {
                    name: "跟进客户数",
                    type: "bar",
                    barMaxWidth: 30,
                    data: [],
                },
                {
                    name: "新建客户数",
                    type: "bar",
                    barMaxWidth: 30,
                    data: [],
                },
            ],
            color: ["#2E7DB5", "#79D6D9"],
        });
        this.getFenbu();
        this.getDingdanjineqingkuang();
        this.getZhongdiangengjin();
        this.getGuanjianxingweiqushi();
    },
};
</script>

<style lang="scss" scoped>
.grid-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: -20px;
}

.grid-item-panel {
    width: calc(50% - 10px);
    margin-top: 20px;
    position: relative;
    height: 380px;
    background: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

$grid-item-panel-title-height: 42px;

.grid-item-panel-title {
    box-sizing: border-box;
    height: $grid-item-panel-title-height;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 14px 0 20px;

    &.bb {
        border-bottom: 1px solid #e6e7eb;
    }
}

.grid-item-panel-title-text {
    font-size: 18px;
    color: #1b2841;
    font-weight: bold;
}

.grid-item-panel-title-sub-text {
    font-size: 14px;
    color: #c7c7c7;
}

.icon-container {
    display: flex;
    align-items: center;

    img {
        cursor: pointer;

        &:last-child {
            margin-left: 10px;
        }
    }
}

.grid-item-select {
    position: absolute;
    z-index: 2;
    left: 20px;
    top: $grid-item-panel-title-height;
    border: 1px solid #c7c7c7;
    border-radius: 2px;
    width: 80px;
    font-size: 14px;
    color: #7c90a5;
    cursor: pointer;
    background: #fff;
}

.grid-item-select-option {
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover,
    &.active {
        background: var(--themeColor);
        color: #fff;
    }
}

.grid-item-panel-chart-dom {
    box-sizing: border-box;
    height: calc(100% - #{$grid-item-panel-title-height});
}

.custom-el-table {
    &.el-table::before {
        height: 0 !important;
        background: none !important;
    }

    font-size: 14px;

    ::v-deep .el-table__cell {
        height: 30px;
        padding: 0;
        border: none;
    }

    ::v-deep thead .el-table__cell {
        color: #3a3a3a;
        font-weight: normal;
    }

    ::v-deep tbody .el-table__cell {
        color: #1a2745;
        font-weight: normal;
    }

    ::v-deep th {
        border: none !important;
    }

    ::v-deep tbody tr {
        &:nth-child(odd) {
            background: #e3eaef;
        }
    }
}
</style>