<script>
import Vue from 'vue'
import { ref, defineComponent } from '@vue/composition-api'
import axios from '@/assets/js/axios'
import { Message } from 'element-ui';
export default defineComponent({
  setup(props, { emit }) {
    const list = ref([]);
    (async () => {
      try {
        const res = await axios({
          method: 'GET',
          url: Vue.prototype.$sjUrl + '/Stage/GetSystemProcess'
        })
        if (!res.isSuccess) throw new Error()
        list.value = res.data
      } catch {
        list.value = []
      }
    })()
    const handleUseClick = async (index) => {
      try {
        const data = list.value[index]
        const res = await axios({
          method: 'POST',
          url: Vue.prototype.$sjUrl + '/Stage/AddStage',
          data: {
            process: data.processName,
            stages: data.stages
          }
        })
        if (!res.isSuccess) throw new Error()
        const result = await axios({
          method: 'GET',
          url: Vue.prototype.$sjUrl + '/Stage/IsDefaultProcess',
          params: {
            processKey: res.data
          }
        })
        if (!result.isSuccess) throw new Error()
        Message.success('操作成功')
        emit('use')
      } catch { null }
    }
    return {
      list,
      handleUseClick
    }
  }
})
</script>

<template>
  <div style="position: absolute; z-index: 10; width: 100%; height: 100%; background: transparent; display: flex; align-items: center; justify-content: center">
    <div class="recommend">
     <!--  <i @click="$emit('close')" class="el-icon-close" style="position: absolute; right: 10px; top: 10px; font-size: 20px; cursor: pointer"></i> -->
      <p style="color: #092338; font-weight: bold">推荐商机流程：</p>
      <div class="panel" v-for="(item, index) of list" :key="item.processKey">
        <p class="title">{{ `${item.processName}：` }}</p>
        <div style="display: flex; align-items: center">
          <div class="block" style="background: #33a76f">
            <div class="item f" v-for="unit of item.stages.filter(i => !i.isWinStatge)" :key="unit.stageKey">{{ unit.stageName }}</div>
          </div>
          <div class="hori"></div>
          <div class="block" style="background: #4480CF">
            <div class="item s" v-for="unit of item.stages.filter(i => i.isWinStatge)" :key="unit.stageKey">{{ unit.stageName }}</div>
          </div>
          <div class="item fail">输单</div>
          <div role="button" @click="handleUseClick(index)">去使用</div>
        </div>
      </div>
      <div @click="$router.push({ name: 'SettingIndex' })" class="add-btn">
        <img src="@/assets/img/add-cyan.png" style="margin-right: 2px" />
        <span>自定义添加列表</span>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.recommend {
  position: absolute;
  background: #ececec;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 30px 40px;
  font-size: 16px;
}
.panel {
  margin-top: 40px;
}
.title {
  color: #092338;
  margin-bottom: 20px;
}
.block {
  border-radius: 4px;
  display: flex;
}
.item {
  border-radius: 4px;
  box-sizing: border-box;
  width: 4em;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  &.f {
    background: #33a76f;
  }
  &.s {
    background: #4480cf;
  }
  &.fail {
    margin-left: 20px;
    background: #a1a1a1;
  }
}
.hori {
  margin: 0 3px;
  box-sizing: border-box;
  width: 27px;
  height: 2px;
  background: #3a3a3a;
}
div[role="button"] {
  cursor: pointer;
  margin-left: 80px;
  box-sizing: border-box;
  width: 88px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: 1px solid var(--themeColor);
  background: rgba(49, 82, 175, 0.2);
  color: var(--themeColor);
  &.ing {
    border-color: var(--themeColor);
    background: var(--themeColor);
    color: #fff;
  }
}
.add-btn {
  margin-top: 40px;
  cursor: pointer;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  height: 36px;
  width: fit-content;
  padding: 0 20px;
  border-radius: 18px;
  background: #fff;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--themeColor);
}
</style>
