<template>
    <div v-loading="loading">
        <!-- 越南 -->
        <el-descriptions :column="2" border :labelStyle="{width:'15%'}" :contentStyle="{width:'35%'}">
            <el-descriptions-item label="公司代码">{{businessInfo.companyID}}</el-descriptions-item>
            <el-descriptions-item label="公司名">{{businessInfo.company}}</el-descriptions-item>
            <el-descriptions-item label="公司名标准">{{businessInfo.companyStd}}</el-descriptions-item>
            <el-descriptions-item label="公司名英文">{{businessInfo.companyEn}}</el-descriptions-item>
            <el-descriptions-item label="Thistransaction">{{businessInfo.thistransaction}}</el-descriptions-item>
            <el-descriptions-item label="税务代码">{{businessInfo.taxCode}}</el-descriptions-item>
            <el-descriptions-item label="状态">{{businessInfo.activitystate}}</el-descriptions-item>
            <el-descriptions-item label="注册管理局">{{businessInfo.registryManager}}</el-descriptions-item>
            <el-descriptions-item label="DPD地址">{{businessInfo.address}}</el-descriptions-item>
            <el-descriptions-item label="电话">{{businessInfo.telephone}}</el-descriptions-item>
            <el-descriptions-item label="法人">{{businessInfo.legalPerson}}</el-descriptions-item>
            <el-descriptions-item label="法人地址">{{businessInfo.addressofDPLperson}}</el-descriptions-item>
            <el-descriptions-item label="负责人">{{businessInfo.director}}</el-descriptions-item>
            <el-descriptions-item label="许可日期">{{businessInfo.heavenallowed}}</el-descriptions-item>
            <el-descriptions-item label="开始运营时间">{{businessInfo.dateofcommencementofactivities}}</el-descriptions-item>
            <el-descriptions-item label="注册时间">{{businessInfo.registrationDate}}</el-descriptions-item>
            <el-descriptions-item label="财政年度">{{businessInfo.fiscalyear}}</el-descriptions-item>
            <el-descriptions-item label="Labornumber">{{businessInfo.labornumber}}</el-descriptions-item>
            <el-descriptions-item label="项目代码">{{businessInfo.chargeAccountofChapter}}</el-descriptions-item>
            <el-descriptions-item label="经营范围">{{businessInfo.industryenterprise}}</el-descriptions-item>
            <el-descriptions-item label="传真">{{businessInfo.fax}}</el-descriptions-item>
            <el-descriptions-item label="Wegotthesystembank">{{businessInfo.wegotthesystembank}}</el-descriptions-item>
            <el-descriptions-item label="DivId">{{businessInfo.divId}}</el-descriptions-item>
            <el-descriptions-item label="省">{{businessInfo.province}}</el-descriptions-item>
            <el-descriptions-item label="公司传真">{{businessInfo.companyFax}}</el-descriptions-item>
            <el-descriptions-item label="公司电话">{{businessInfo.companyPhone}}</el-descriptions-item>
            <el-descriptions-item label="临近地区">{{businessInfo.neighborhood}}</el-descriptions-item>
            <el-descriptions-item label="公司所在县区">{{businessInfo.region}}</el-descriptions-item>
            <el-descriptions-item label="时区名称">{{businessInfo.timezone}}</el-descriptions-item>
            <el-descriptions-item label="网站">{{businessInfo.website}}</el-descriptions-item>
            <el-descriptions-item label="公司Facebook账号">{{businessInfo.facebook}}</el-descriptions-item>
            <el-descriptions-item label="联系人职位">{{businessInfo.position}}</el-descriptions-item>
            <el-descriptions-item label="性别">{{businessInfo.gender}}</el-descriptions-item>
            <el-descriptions-item label="电子邮件">{{businessInfo.emails}}</el-descriptions-item>
            <el-descriptions-item label="联系人所在地区">{{businessInfo.contactRegion}}</el-descriptions-item>
            <el-descriptions-item label="联系人所在城市">{{businessInfo.contactCity}}</el-descriptions-item>
            <el-descriptions-item label="电话2">{{businessInfo.telephone2}}</el-descriptions-item>
        </el-descriptions>
    </div>
</template>

<script>
export default {
    data() {
        return {
            businessInfo: {},
            loading: false,
        };
    },
    methods: {
        getInfo(){
            let companyName = encodeURIComponent(this.$route.query.companyName);
            let country = this.$route.query.country;
            this.loading = true;
            this.$axios.get(`${this.$clueUrl}/BuyerLeads/GetBusinessInformation?country=${country}&company=${companyName}`).then((res) => {
                const { isSuccess, data } = res;
                this.loading = false;
                if (isSuccess) {
                    this.businessInfo = data;
                }
            });
        },
    },
    mounted() {
        this.getInfo();
    },
};
</script>

<style lang="scss" scoped>

</style>