<template>
    <div class="pageBox">
        <div class="header">
            <el-input class="searchBox" placeholder="请输入搜索内容" v-model="searchValue" prefix-icon="el-icon-search" clearable>
                <el-button slot="append" @click="onSearch">文件搜索</el-button>
            </el-input>
        </div>
        <div class="contentBox">
            <div class="topHeader">
                <img v-if="BreadcrumbList.length > 1" src="@/assets/doc/goBack.png" alt="" class="goBack" @click="goBack" />
                <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb">
                    <el-breadcrumb-item v-for="(item,index) in BreadcrumbList" :key="item.key">
                        <span @click="goJump(index)" class="spanItem">{{item.name}}</span>
                    </el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="tableHeader">
                <div class="name">文件名称</div>
                <div class="size">大小</div>
                <div class="creator">权限</div>
                <div class="time">分享时间</div>
                <div class="moreOption">更多操作</div>
            </div>
            <div class="tableBody" v-loading="FolderLoading || FileLoading">
                <div v-if="FolderList.length == 0 && FileList.length == 0">
                    <el-empty description="暂无数据"></el-empty>
                </div>
                <!-- 文件夹列表 -->
                <div class="FolderItem" v-for="item in FolderList" :key="item.key">
                    <div class="name">
                        <img class="imgIcon" src="@/assets/doc/folderIcon.png" alt="" />
                        <span>{{item.folderName}}</span>
                    </div>
                    <div class="size"></div>
                    <div class="creator">{{item.userCnName}}</div>
                    <div class="time">{{item.timeExhibition}}</div>
                    <div class="moreOption">
                        <el-dropdown>
                            <span style="color:var(--themeColor);cursor:pointer;font-size:20px;" @click.stop="() =>{}"><i class="el-icon-more"></i></span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item>下载</el-dropdown-item>
                                <el-dropdown-item>分享</el-dropdown-item>
                                <el-dropdown-item>重命名</el-dropdown-item>
                                <el-dropdown-item>移动至</el-dropdown-item>
                                <el-dropdown-item>详情信息</el-dropdown-item>
                                <el-dropdown-item>删除</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </div>
                <!-- 文件列表 -->
                <div class="FileItem" v-for="item in FileList" :key="item.key">
                    <div class="name">
                        <img class="imgIcon fileIcon" :src="item.fileIcon" alt="" />
                        <span class="pointer" @click="viewFileDetails(item)">{{item.fileName}}</span>
                    </div>
                    <div class="size">{{item.fileSize}}</div>
                    <div class="creator">
                        <el-popover placement="left" width="450" trigger="click">
                            <el-table :data="personList">
                                <el-table-column label="分享人">
                                    <template slot-scope="scope">
                                        {{scope.row.shareName}} => {{scope.row.name}}
                                    </template>
                                </el-table-column>
                                <el-table-column property="shareTime" label="分享时间"></el-table-column>
                                <el-table-column label="是否接受">
                                    <template slot-scope="scope">
                                        {{scope.row.isReceive ? '已接受' : '未接受'}}
                                    </template>
                                </el-table-column>
                            </el-table>
                            <el-button slot="reference" type="text" @click="viewSharePerson(item)">查看分享人</el-button>
                        </el-popover>
                    </div>
                    <div class="time">{{item.lastShareTime}}</div>
                    <div class="moreOption">
                        <el-dropdown>
                            <span style="color:var(--themeColor);cursor:pointer;font-size:20px;" @click.stop="() =>{}"><i class="el-icon-more"></i></span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item @click.native="fileSharing(item)">再次分享</el-dropdown-item>
                                <el-dropdown-item @click.native="delShare(item)">删除分享</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </div>
            </div>
        </div>
        <!-- 文件详情 -->
        <FileInfo :infoVisible="infoVisible" :infoKey="infoKey" @closedialog="infoVisible = false;" />
        <!-- 文件分享 -->
        <FileShare :shareVisible="shareVisible" :shareFileKey="shareFileKey" @closedialog="shareVisible = false;" />
    </div>
</template>

<script>
import FileInfo from "./components/FileInfo.vue"
import FileShare from "./components/FileShare.vue"
export default {
    components: { FileInfo, FileShare },
    data() {
        return {
            searchValue: "",
            BreadcrumbList:[
                {name:'我的分享',key:"0"},
            ],
            //文件夹列表
            FolderList:[],
            FolderLoading: false,
            //文件列表
            FileList: [],
            FileLoading: false,

            //查看分享人
            personList: [],
            //查看文件详情
            infoVisible: false,
            infoKey: "",
            //再次分享
            shareVisible: false,
            shareFileKey: "",
        };
    },
    methods: {
        //文件搜索
        onSearch(){
            console.log(this.searchValue);
            console.log("文件搜索");
        },

        //返回上一级文件夹
        goBack(){
            console.log("返回")
        },
        //跳转指定文件夹
        goJump(index){
            console.log(index);
        },

        //获取我的分享
        getShareList(){
            this.FileLoading = true;
            this.$axios.post(`${this.$diskUrl}/Share/GetMyShares`,{
                "pageIndex": 1,
                "pageSize": 20,
            }).then((res) => {
                this.FileLoading = false;
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.FileList = data.datas;
                    this.FileList.forEach(item =>{
                        item.fileIcon = require(`@/assets/doc/file_${item.fileCategory}.png`)
                    })
                }
            });
        },

        //查看分享人
        viewSharePerson(item){
            this.$axios.get(`${this.$diskUrl}/Share/GetFileShare?fileKey=${item.fileKey}`).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    this.personList = data;
                }
            });
        },
        //查看文件详情 
        viewFileDetails(item){
            this.infoVisible = true;
            this.infoKey = item.fileKey;
        },
        //再次分享
        fileSharing(item){
            this.shareVisible = true;
            this.shareFileKey = item.fileKey;
        },
        //删除分享
        delShare(item){
            this.$confirm('此操作将删除分享文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios.get(`${this.$diskUrl}/Share/Delete?key=${item.fileKey}&IsMy=true`).then((res) => {
                    const { isSuccess } = res;
                    if (isSuccess) {
                        this.getShareList();
                        this.$message({ type: 'success', message: '操作成功!'});
                    }
                })
            }).catch(() => {});
        },
    },
    mounted() {
        //获取我的分享
        this.getShareList();
    },
};
</script>

<style lang="scss" scoped>
.pageBox{
    padding: 20px;
    .header{
        margin-bottom: 20px;
        overflow: hidden;
        .searchBox{
            width: 500px;
            float: right;
        }
    }
    .contentBox{
        height: calc(100vh - 180px);
        background: #FFFFFF;
        box-shadow: 0px 1px 6px 1px rgba(0, 0, 0, 0.16);
        .topHeader{
            height: 45px;
            line-height: 45px;
            background: #FBFBFB;
            padding-left: 30px;
            position: relative;
            .goBack{
                position: absolute;
                top: 0;
                bottom: 0; 
                left: 30px;
                margin: auto;
                cursor: pointer;
            }
            .breadcrumb{
                margin-left: 50px;
                display: inline-block;
                line-height: 45px;
                .spanItem{
                    cursor: pointer;
                    font-size: 16px;
                    font-weight: bold;
                    color: #3A3A3A;
                }
                /deep/.el-breadcrumb__separator[class*=icon] {
                    margin: 0 10px;
                    font-size: 16px;
                    font-weight: bold;
                    color: #9C9C9C;
                }
            }
        }
        .tableHeader{
            height: 45px;
            line-height: 45px;
            background: #F3F3F3;
            font-size: 16px;
            font-weight: bold;
            color: #3A3A3A;
            display: flex;
            padding: 0 20px;
        }
        .tableBody{
            padding-bottom: 10px;
            height: calc(100vh - 280px);
            overflow: overlay;
            .FolderItem,.FileItem{
                padding: 0 10px;
                display: flex;
                height: 50px;
                line-height: 50px;
                border-bottom: 1px solid #EEEEEE;
                font-size: 14px;
                color: #1A2745;
                .imgIcon{
                    vertical-align: middle;
                    padding: 0 20px;
                }
            }
        }
        .tableHeader,.FolderItem,.FileItem{
            .name{
                width: calc(100% - 650px);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .size{
                width: 130px;
                padding: 0 10px;
                text-align: center;
            }
            .creator{
                width: 130px;
                padding: 0 10px;
                text-align: center;
            }
            .time{
                width: 180px;
                padding: 0 10px;
                text-align: center;
            }
            .moreOption{
                width: 130px;
                padding: 0 10px;
                text-align: center;
            }
        }
    }
}
</style>