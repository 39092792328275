<template>
  <!-- 采购供应商国家编码分布图 -->
  <div class="imp-exp-con-code" :class="{ full: control.full }" id="imp-exp-con-code" v-loading="control.loading" v-show="control.show && companyType === 'importer'">
    <div class="title" ref="title">
      <div class="text">
        <div style="font-weight: bold">【<span style="color: var(--themeColor, #3152AF)">采购</span>供应商国家编码图谱】</div>
      </div>
      <div style="display: flex; align-items: center">
        <el-select v-model="control.year" placeholder="选择年份" size="mini" style="width: 100px">
          <el-option v-for="item of control.yearOptions" :label="item" :value="item" :key="item" />
        </el-select>
        <el-radio-group v-model="control.option" size="mini" style="margin-left: 15px">
          <el-radio-button :label="0">频次</el-radio-button>
          <el-radio-button :label="1">金额</el-radio-button>
          <el-radio-button :label="2">数量</el-radio-button>
          <el-radio-button :label="3">重量</el-radio-button>
        </el-radio-group>
        <img src="@/firm/images/company/icon-refresh.png" style="margin-left: 15px; margin-right: 5px; cursor: pointer" @click="refresh" />
        <i v-show="control.full" class="el-icon-close" style="font-size: 30px; color: #e9911b; cursor: pointer" @click="closeGraphFull"></i>
        <img v-show="!control.full && !control.isEmpty" class="icon" src="@/firm/images/company/icon-expand.png" style="margin-left: 1em" @click="graphFull" />
      </div>
    </div>
    <div v-show="!control.isEmpty" ref="container" class="graph" :class="{ full: control.full }"></div>
    <el-empty v-show="control.isEmpty" description="暂无数据" class="graph"></el-empty>
    <div style="display: flex; align-items: center; padding-top: 20px">
      <div class="circle" style="background: #0ba7f0"></div>
      <span style="margin-right: 12px">供应商</span>
      <div class="circle" style="background: #ff3250"></div>
      <span style="margin-right: 12px">国家</span>
      <div class="circle" style="background: #ff9f1e"></div>
      <span style="margin-right: 12px">编码</span>
    </div>
  </div>
</template>

<script>
import G6, { config, disableScroll, enableScroll } from "./utils";
import axios from "@/firm/plugins/axios";
import { mapState, mapMutations } from "vuex";
import dayjs from "@/firm/plugins/dayjs";
import LazyOperation from "@/firm/plugins/lazy-execute";
import { AnchorInditor } from "../../utils";
import { Message } from "@/firm/plugins/element";

let graph = null;
let lazyOperation = null;

export default {
  data() {
    const year = dayjs().year();
    const yearOptions = (() => {
      const options = [];
      for (let i = 2018; i <= year; i++) {
        options.push(i.toString());
      }
      return options;
    })();
    return {
      control: {
        option: 0,
        show: true,
        loading: false,
        year: dayjs().year().toString(),
        yearOptions,
        full: false,
        isEmpty: false,
      },
    };
  },
  computed: {
    ...mapState(["company", "companyType", "similar"]),
  },
  watch: {
    "control.year"() {
      lazyOperation.reobserve();
    },
    "control.option"() {
      lazyOperation.reobserve();
    },
  },
  methods: {
    refresh: (function () {
      let enable = true;
      let num = 30;
      let timer = NaN;
      return () => {
        if (enable) {
          lazyOperation.reobserve();
          enable = false;
          timer = setInterval(() => {
            num--;
            if (num <= 0) {
              enable = true;
              num = 30;
              clearInterval(timer);
            }
          }, 1000);
        } else {
          Message.warning(`请在${num}秒后刷新图谱`);
        }
      };
    })(),
    ...mapMutations("anchor", {
      setAnchorFlag: "setAnchorFlag",
      setCurrent: "setCurrent",
    }),
    graphFull() {
      this.control.full = true;
      disableScroll();
      this.$nextTick(() => {
        graph.changeSize(this.$refs["container"].clientWidth, this.$refs["container"].clientHeight);
      });
    },
    closeGraphFull() {
      this.control.full = false;
      enableScroll();
      this.$nextTick(() => {
        graph.changeSize(this.$refs["container"].clientWidth, this.$refs["container"].clientHeight);
      });
    },
    async getData() {
      try {
        this.control.loading = true;
        const res = await axios({
          method: "POST",
          url: "/search/Credit/Trade",
          data: {
            companys: [this.company, ...this.similar],
            company: this.company, // "ESSECO USA LLC", //this.company,
            year: this.control.year,
            type: 1, //进口商->出口商->国家->编码
            option: this.control.option,
          },
        });
        const data = res.data.result;
        if (data.edges.length === 0) {
          throw new Error();
        }
        graph.data(data);
        graph.render();
        this.setAnchorFlag([["gxtixcc", true]]);
        this.control.isEmpty = false;
      } catch {
        this.control.isEmpty = true;
        Message.warning(`无${this.control.year}年数据`);
      } finally {
        this.control.loading = false;
      }
    },
  },
  mounted() {
    const tooltip = new G6.Tooltip({
      itemTypes: ["node"],
      getContent(e) {
        try {
          const name = e.item.getModel().name;
          return name;
        } catch {
          return "";
        }
      },
    });
    graph = new G6.Graph({
      container: this.$refs["container"],
      layout: {
        type: "comboForce",
        preventOverlap: true,
        nodeSpacing: 100,
      },
      modes: {
        default: ["drag-canvas", "zoom-canvas", "drag-node", "activate-relations"],
      },
      plugins: [tooltip],
      defaultEdge: {
        style: {
          stroke: config.color.line,
          endArrow: {
            path: G6.Arrow.triangle(8, 8, 0),
            fill: config.color.line,
          },
        },
      },
      nodeStateStyles: {
        active: {
          opacity: 1,
        },
        inactive: {
          opacity: 0.2,
        },
      },
      edgeStateStyles: {
        active: {
          stroke: config.color.line,
          lineWidth: config.lineActiveWidth,
        },
      },
    });
    graph.node((node) => {
      if (node.type.includes("HsCode")) {
        return {
          ...node,
          type: "HsCode",
        };
      } else if (node.type.includes("Company")) {
        return {
          ...node,
          type: "Company",
        };
      } else if (node.type.includes("Country")) {
        return {
          ...node,
          type: "Country",
        };
      } else {
        return node;
      }
    });

    lazyOperation = new LazyOperation(() => {
      this.getData();
    }, this.$el);

    new AnchorInditor(() => {
      this.setCurrent("gxtixcc");
    }, this.$refs["title"]);
  },
};
</script>

<style lang="scss">
@import "@/firm/scss/mixin.scss";

.imp-exp-con-code {
  box-sizing: border-box;
  margin-top: 1em;
  background: #fff;
  padding: 1rem;
  padding-top: 0;
  @include mixin-panel-title;
  &.full {
    margin: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999;
  }
}
.graph {
  height: 500px;
  &.full {
    height: calc(100vh - 64px - 60px);
  }
}
.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 2px;
}
</style>
