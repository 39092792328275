<template>
  <!-- 表格内的箭头指示器单元格 -->
  <div class="table-cell-indicator">
    <span :class="[data.status, { arrow: data.arrow }]">{{ data.data }}</span>
    <img v-if="data.arrow && data.status === 'increase'" src="@/firm/images/indicator-icon-up.png" style="margin-left: 5px" />
    <img v-else-if="data.arrow && data.status === 'decrease'" src="@/firm/images/indicator-icon-down.png" style="margin-left: 5px" />
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  props: ["data"],
});
</script>

<style lang="scss" scoped>
.table-cell-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  .arrow {
    $statusToColor: (
      increase: #20bc5f,
      decrease: #ff2d2d,
    );
    @each $status, $color in $statusToColor {
      &.#{$status} {
        color: $color;
      }
    }
  }
}
</style>
