<script>
import Vue from 'vue'
import { ref, defineComponent } from '@vue/composition-api'
import CustomerDynamics from '@/components/CustomerDynamics.vue'
import InfoPanel from './components/InfoPanel.vue'
import StatusPanel from './components/StatusPanel.vue'
import MoneyPanel from './components/MoneyPanel.vue'
import AnnexPanel from './components/AnnexPanel.vue'
import QuotationPanel from './components/QuotationPanel.vue'
import OrderPanel from './components/OrderPanel.vue'
import axios from '@/assets/js/axios'
export default defineComponent({
  components: {
    CustomerDynamics,
    InfoPanel,
    StatusPanel,
    MoneyPanel,
    AnnexPanel,
    QuotationPanel,
    OrderPanel,
  },
  props: {
    businessKey: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const companyKey = ref('');
    (async () => {
      try {
        const res = await axios({
          method: 'GET',
          url: Vue.prototype.$sjUrl + '/BusinessChance/GetBusinessDetail',
          params: {
            businessKey: props.businessKey
          }
        })
        if (!res.isSuccess) throw new Error()
        companyKey.value = res.data.companyKey
      } catch { null }
    })()
    const StatusPanelInstance = ref()
    const CustomerDynamicsInstance = ref()
    return {
      companyKey,
      StatusPanelInstance,
      CustomerDynamicsInstance,
      onInfoPanelStageChange() {
        StatusPanelInstance.value.getAllData()
        CustomerDynamicsInstance.value.refreshAllRecords()
      }
    }
  }
})
</script>

<template>
  <el-dialog top="20px" width="1200px" :visible="true" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
    <div class="detail-panel">
      <div class="top">
        <span>商机详情</span>
        <i @click="$emit('close')" class="el-icon-close" style="cursor: pointer; font-size: 20px"></i>
      </div>
      <div class="body">
        <div style="box-sizing: border-box; width: 100%; height: 100%; padding: 0 20px; border-right: 1px solid #B7B7B7">
          <div style="box-sizing: border-box; width: 100%; height: 291px; overflow-y: hidden">
            <InfoPanel :businessKey="$props.businessKey" @stage-change="onInfoPanelStageChange" @delete="$emit('close')" />
          </div>
          <div style="box-sizing: border-box; width: 100%; height: calc(100% - 291px)">
            <div style="background: #E5E9F2; font-size: 16px; color: #000; display: flex; align-items: center; padding-left: 16px; height: 32px; box-sizing: border-box; margin-bottom: 10px">跟进动态</div>
            <el-scrollbar class="scrollable" style="height: calc(100% - 42px)">
              <div style="padding-bottom: 20px">
                <CustomerDynamics v-if="companyKey" :customerKey="companyKey" ref="CustomerDynamicsInstance" />
              </div>
            </el-scrollbar>
          </div>
        </div>
        <div style="box-sizing: border-box; width: 100%; height: 100%; padding: 0 20px">
          <div style="box-sizing: border-box; height: 35%">
            <StatusPanel :businessKey="$props.businessKey" ref="StatusPanelInstance" />
          </div>
          <el-scrollbar class="scrollable" style="height: 65%">
            <QuotationPanel :businessKey="$props.businessKey" :customerKey="companyKey" />
            <div style="height:10px;"></div>
            <OrderPanel :businessKey="$props.businessKey" :customerKey="companyKey" />
            <div style="height:10px;"></div>
            <MoneyPanel :businessKey="$props.businessKey" />
            <div style="height:10px;"></div>
            <AnnexPanel :businessKey="$props.businessKey" />
            <div style="height:10px;"></div>
          </el-scrollbar>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<style scoped lang="scss">
::v-deep .el-dialog {
  margin: 0 auto;
}
::v-deep .el-dialog__header {
  display: none;
}
::v-deep .el-dialog__body {
  padding: 0;
}
.detail-panel {
  box-sizing: border-box;
  height: calc(100vh - 40px);
  font-size: 16px;
}
.top {
  box-sizing: border-box;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  color: #3a3a3a;
  border-bottom: 1px solid #d9d9d9;
}
.body {
  box-sizing: border-box;
  height: calc(100% - 60px);
  display: grid;
  grid-template-columns: 740px calc(100% - 740px);
  grid-template-rows: 100%;
  padding-top: 20px;
}
.scrollable {
  ::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
  }
}
</style>
