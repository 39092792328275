<template>
    <div>
        <div style="height: 60px; padding-left: 10px; display: flex; align-items: center; font-size: 14px; color: #707070">
            <span style="flex-shrink: 0">时间：</span>
            <el-select v-model="filterYear" placeholder="年份" size="small" style="width:100px;" @change="onFilterAll">
                <el-option label="2021年" value="2021"></el-option>
                <el-option label="2022年" value="2022"></el-option>
                <el-option label="2023年" value="2023"></el-option>
            </el-select>
            <el-select v-model="filterMonth" placeholder="月份" size="small" style="width:100px;" @change="onFilterAll">
                <el-option label="1月" value="1"></el-option>
                <el-option label="2月" value="2"></el-option>
                <el-option label="3月" value="3"></el-option>
                <el-option label="4月" value="4"></el-option>
                <el-option label="5月" value="5"></el-option>
                <el-option label="6月" value="6"></el-option>
                <el-option label="7月" value="7"></el-option>
                <el-option label="8月" value="8"></el-option>
                <el-option label="9月" value="9"></el-option>
                <el-option label="10月" value="10"></el-option>
                <el-option label="11月" value="11"></el-option>
                <el-option label="12月" value="12"></el-option>
            </el-select>
        </div>

        <div class="grid-container" style="height: calc(100% - 60px)">
            <div class="grid-item-panel" style="width: 100%;" v-loading="loadingOne">
                <div class="grid-item-panel-title bb">
                    <div style="display: flex; align-items: center;">
                        <span class="grid-item-panel-title-text">员工销售数据</span>
                        <span class="grid-item-panel-title-sub-text" style="margin-left: 10px"></span>
                    </div>
                    <div class="icon-container">
                        <img src="@/assets/icon/refresh.png" />
                        <img src="@/assets/icon/close.png" />
                    </div>
                </div>
                <div class="grid-item-panel-chart-dom">
                    <el-table class="custom-el-table" :data="tableDataOne" height="100%">
                        <el-table-column label="#" type="index" width="50" align="center"></el-table-column>
                        <el-table-column label="员工" prop="userName" show-overflow-tooltip />
                        <el-table-column label="成交订单数" prop="orderNum" show-overflow-tooltip />
                        <el-table-column label="到账总金额" prop="totalPrice" show-overflow-tooltip />
                        <el-table-column label="到账均单价" prop="avgPrice" show-overflow-tooltip />
                        <el-table-column label="到账最大金额" prop="maxPrice" show-overflow-tooltip />
                        <el-table-column label="到账最小金额" prop="minPrice" show-overflow-tooltip />
                    </el-table>
                </div>
            </div>

            <div class="grid-item-panel" style="width: 100%;" v-loading="loadingTwo">
                <div class="grid-item-panel-title bb">
                    <div style="display: flex; align-items: center;">
                        <span class="grid-item-panel-title-text">销售订单到账情况</span>
                        <span class="grid-item-panel-title-sub-text" style="margin-left: 10px"></span>
                    </div>
                    <div class="icon-container">
                        <img src="@/assets/icon/refresh.png" />
                        <img src="@/assets/icon/close.png" />
                    </div>
                </div>
                <div class="grid-item-panel-chart-dom">
                    <el-table class="custom-el-table" :data="tableDataTwo" height="calc(100% - 50px)">
                        <el-table-column label="#" type="index" width="50" align="center"></el-table-column>
                        <el-table-column label="订单编号" prop="orderNo" min-width="150" show-overflow-tooltip />
                        <el-table-column label="客户" prop="customerName" min-width="200" show-overflow-tooltip />
                        <el-table-column label="所属员工" prop="userName" show-overflow-tooltip />
                        <el-table-column label="订单销售额" prop="orderAmount" show-overflow-tooltip />
                        <el-table-column label="已到账金额" prop="receivedPaymentAmount" show-overflow-tooltip />
                        <el-table-column label="未到账金额" prop="uncollectedPaymentAmount" show-overflow-tooltip />
                        <el-table-column label="到账次数" prop="receivedCount" show-overflow-tooltip />
                    </el-table>
                    <el-pagination hide-on-single-page background layout="total,prev,pager,next" :page-size="8" :total="totalTwo" style="float:right;margin:5px;" :current-page.sync="pageIndexTwo" @current-change="getNewOrderCollection"></el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import dayjs from "dayjs";
export default {
    data() {
        return {
            filterYear: dayjs().year().toString(),
            filterMonth: (dayjs().month() + 1).toString(),
            //员工销售数据
            loadingOne: false,
            tableDataOne: [],
            //销售订单到账情况
            loadingTwo: false,
            tableDataTwo: [],
            pageIndexTwo: 1,
            totalTwo: 0,
        };
    },
    methods: {
        onFilterAll(){
            this.getStaffSaleData();
            this.getNewOrderCollection();
        },
        async getStaffSaleData() {
            try {
                this.loadingOne = true;
                const res = await this.$axios({
                    method: "POST",
                    url: this.$customerUrl + "/Report/StaffSaleData",
                    data: {
                        year: this.filterYear,
                        month: this.filterMonth,
                        organizationKey: "",
                    },
                });
                this.tableDataOne = res.data;
            } catch {
                null;
            } finally {
                this.loadingOne = false;
            }
        },
        async getNewOrderCollection() {
            try {
                this.loadingTwo = true;
                const res = await this.$axios({
                    method: "POST",
                    url: this.$customerUrl + "/Report/NewOrderCollection",
                    data: {
                        year: this.filterYear,
                        month: this.filterMonth,
                        organizationKey: "",
                        pageIndex: this.pageIndexTwo,
                        pageSize: 8,
                    },
                });
                this.tableDataTwo = res.data.datas;
                this.totalTwo = res.data.totalItems;
            } catch {
                null;
            } finally {
                this.loadingTwo = false;
            }
        },
    },
    mounted() {
        //员工销售数据
        this.getStaffSaleData();
        //销售订单到账情况
        this.getNewOrderCollection();
    },
};
</script>

<style lang="scss" scoped>
.grid-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: -20px;
}
.grid-item-panel {
    width: calc(50% - 10px);
    margin-top: 20px;
    position: relative;
    height: 380px;
    background: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}
$grid-item-panel-title-height: 42px;
.grid-item-panel-title {
    box-sizing: border-box;
    height: $grid-item-panel-title-height;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 14px 0 20px;
    &.bb {
        border-bottom: 1px solid #e6e7eb;
    }
}
.grid-item-panel-title-text {
    font-size: 18px;
    color: #1b2841;
    font-weight: bold;
}
.grid-item-panel-title-sub-text {
    font-size: 14px;
    color: #c7c7c7;
}
.icon-container {
    display: flex;
    align-items: center;
    img {
        cursor: pointer;
        &:last-child {
            margin-left: 10px;
        }
    }
}
.grid-item-select {
    max-height: calc(100% - #{$grid-item-panel-title-height} - 30px);
    overflow-y: auto;
    position: absolute;
    z-index: 2;
    left: 20px;
    top: $grid-item-panel-title-height;
    border: 1px solid #c7c7c7;
    border-radius: 2px;
    width: 80px;
    font-size: 14px;
    color: #7c90a5;
    cursor: pointer;
    background: #fff;
}
.grid-item-select-option {
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover,
    &.active {
        background: var(--themeColor);
        color: #fff;
    }
}
.grid-item-panel-chart-dom {
    box-sizing: border-box;
    height: calc(100% - #{$grid-item-panel-title-height});
}
.custom-el-table {
    &.el-table::before {
        height: 0 !important;
        background: none !important;
    }
    font-size: 14px;
    ::v-deep .el-table__cell {
        height: 30px;
        padding: 0;
        border: none;
    }
    ::v-deep thead .el-table__cell {
        color: #3a3a3a;
        font-weight: normal;
    }
    ::v-deep tbody .el-table__cell {
        color: #1a2745;
        font-weight: normal;
    }
    ::v-deep th {
        border: none !important;
    }
    ::v-deep tbody tr {
        &:nth-child(odd) {
            background: #e3eaef;
        }
    }
}
</style>