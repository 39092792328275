<template>
<div>
    <div v-if="ie=='i'">
        <!-- 土耳其(TUR)进口提单明细 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th width="25%">注册号</th>
                        <td width="25%">{{billInfo.RegistrationNumber}}</td>
                        <th width="25%">统计价值（美元）</th>
                        <td width="25%">{{billInfo.Value}} {{billInfo.ValueUnit}}</td>
                    </tr>
                    <tr>
                        <th>原产国</th>
                        <td>{{billInfo.Country}}</td>
                        <th>净重</th>
                        <td>{{billInfo.NetWeight}}</td>
                    </tr>
                    <tr>
                        <th>单位</th>
                        <td>{{billInfo.Unit}}</td>
                        <th>单价</th>
                        <td>{{billInfo.UnitPrice}}</td>
                    </tr>
                    <tr>
                        <th>统计数量</th>
                        <td>{{billInfo.StatQuantity}}</td>
                        <th>发票金额</th>
                        <td>{{billInfo.InvoiceAmount}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">其他信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th>进口商所在国</th>
                        <td colspan="3">{{billInfo.ArrivalCountry}}</td>
                    </tr>
                    <tr>
                        <th width="25%">运费</th>
                        <td width="25%">{{billInfo.Freight}}</td>
                        <th width="25%">海关办公室</th>
                        <td width="25%">{{billInfo.CustomsOfficeName}}</td>
                    </tr>
                    <tr>
                        <th>国际支出金额</th>
                        <td>{{billInfo.InternationalExpAmount}}</td>
                        <th>国内支出金额</th>
                        <td>{{billInfo.DomesticExpAmount}}</td>
                    </tr>
                    <tr>
                        <th>土耳其地区</th>
                        <td colspan="3">{{billInfo.TurkeyRegion}}</td>
                    </tr>
                    <tr>
                        <th>项目名称</th>
                        <td>{{billInfo.ItemRegimeName}}</td>
                        <th>项目名称代码</th>
                        <td>{{billInfo.ItemRegimeCode}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
    <div v-if="ie=='e'">
        <!-- 土耳其(TUR)出口提单明细 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th width="25%">注册日期</th>
                        <td width="25%">{{billInfo.RegistrationDate}}</td>
                        <th width="25%">注册号</th>
                        <td width="25%">{{billInfo.RegistrationNumber}}</td>
                    </tr>
                    <tr>
                        <th>净重</th>
                        <td>{{billInfo.NetWeight}} {{billInfo.NetWeightUnit}}</td>
                        <th>统计价值（美元）</th>
                        <td>{{billInfo.Value}} {{billInfo.ValueUnit}}</td>
                    </tr>
                    <tr>
                        <th>发票金额</th>
                        <td>{{billInfo.InvoiceAmount}}</td>
                        <th>统计数量</th>
                        <td>{{billInfo.StatisticalQuantity}}</td>
                    </tr>
                    <tr>
                        <th>单价</th>
                        <td>{{billInfo.UnitPrice}}</td>
                        <th>量单位代码</th>
                        <td>{{billInfo.UnitCode}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">运输信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">目的国</th>
                        <td width="25%">{{billInfo.Country}}</td>
                        <th width="25%">运费</th>
                        <td width="25%">{{billInfo.Freight}}</td>
                    </tr>
                    <tr>
                        <th>国际支出金额</th>
                        <td>{{billInfo.InternationalExpendituresAmount}}</td>
                        <th>国内支出金额</th>
                        <td>{{billInfo.DomesticExpendituresAmount}}</td>
                    </tr>
                    <tr>
                        <th>国际条例代码</th>
                        <td>{{billInfo.InterntiomalAgreementCode}}</td>
                        <th>原产国</th>
                        <td>{{billInfo.OriginCountry}}</td>
                    </tr>
                    <tr>
                        <th>海关办公室</th>
                        <td>{{billInfo.CustomsOffice}}</td>
                        <th>土耳其地区</th>
                        <td>{{billInfo.TurkeyRegion}}</td>
                    </tr>
                    <tr>
                        <th>项目名称</th>
                        <td>{{billInfo.RegimeName}}</td>
                        <th>项目名称代码</th>
                        <td>{{billInfo.RegimeCode}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
</div>
</template>

<script>
export default {
    props:["billInfo","ie"],
    data() {
        return {

        };
    },
    methods: {
        
    },
    mounted() {

    },
};
</script>

<style lang="scss" scoped>
.partItem {
    margin-bottom: 20px;
    /deep/.el-card__header {
        padding: 10px 20px;
        .headTitle {
            display: inline-block;
            padding-left: 10px;
            font-size: 14px;
            border-left: 2px solid var(--themeColor);
            font-weight: bold;
        }
    }
}
table {
    width: 100%;
    border-collapse: collapse;
    tr {
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;
        th,
        td {
            font-size: 12px;
            color: #666;
            height: 40px;
            border: 1px solid #eaeaea;
            text-align: center;
        }
        th {
            background-color: #f5f7fa;
            font-weight: 700;
        }
        .bg {
            background-color: var(--themeColor);
            color: #fff;
            font-weight: 700;
            opacity: 0.8;
        }
    }
}
</style>