<template>
    <el-tooltip effect="dark" content="复制" placement="top">
        <i class="el-icon-copy-document" ref="CopyIcon" :data-clipboard-text="copyValue"></i>
    </el-tooltip>
</template>

<script>
import Clipboard from "clipboard";
export default {
    props:["copyValue"],
    data() {
        return {};
    },
    methods: {},
    mounted() {
        new Clipboard(this.$refs.CopyIcon).on("success", () => {
            this.$message({ message: '复制成功', type: 'success' });
        });
    },
};
</script>

<style lang="scss" scoped>
.el-icon-copy-document{
    cursor: pointer;
    display: inline-block;
    margin-left: 8px;
    color: var(--themeColor);
    font-size: 16px;
}
</style>