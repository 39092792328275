<template>
    <div class="viewBox">
        <i class="el-icon-loading" v-if="isLoading"></i>
        <div class="text-current">
            {{textCurrent}}
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            detailsInfo: {},
            isLoading: true,
            textCurrent: "生成采购记录中",
        };
    },
    methods: {
        initInfo(){
            let key = this.$route.query.key;
            let ukey = JSON.parse(localStorage.userInfo).uKey;
            if(!key){ 
                this.isLoading = false;
                this.textCurrent = "生成采购记录失败";
                return false;
            }
            this.$axios.get(`${this.$OAService}/GetPurchaseDemandDetails?key=${key}&ukey=${ukey}`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.detailsInfo = data;
                    this.detailsInfo.company = this.$route.query.p1;
                    this.detailsInfo.contacts = this.$route.query.p2;
                    this.detailsInfo.contactPhone = this.$route.query.p3;
                    this.detailsInfo.contactEmail = "";
                    this.generatePurchaseRecord();
                }
            })
        },
        generatePurchaseRecord(){
            this.$axios.post(`${this.$customerUrl}/Procure/CreatePurchasingRecord`,this.detailsInfo).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.isLoading = false;
                    this.textCurrent = "生成采购记录成功";
                    this.$message({message: '生成采购记录成功!',type: 'success'});
                    this.$router.push({
                        path: "/purchase/recordDetails",
                        query: { key: data },
                    });
                }
            });
        }
    },
    mounted() {
        this.initInfo();
    },
};
</script>

<style lang="scss" scoped>
.viewBox{
    text-align: center;
    padding-top: 200px;
    color: #3a3a3a;
    .el-icon-loading{
        font-size: 200px;
    }
    .text-current{
        font-size: 28px;
    }
}
</style>