<script>
import Vue from "vue";
import { watchEffect, ref } from "@vue/composition-api";
import { Message } from "element-ui";
import { useClipboard } from "@vueuse/core";
import axios from "@/assets/js/axios";

export default Vue.extend({
    props: ["ckey"],
    setup(props) {
        const info = ref({});
        const { isSupported, copy } = useClipboard();
        const handleCopyClick = async (text) => {
            try {
                if (!isSupported) throw new Error();
                await copy(text);
                Message.success("复制成功");
            } catch {
                Message.error("复制失败");
            }
        };
        watchEffect(async () => {
            const key = props.ckey;
            if (key) {
                const res = await axios({
                    method: "GET",
                    url: Vue.prototype.$customerUrl + "/Card/GetContactsCard",
                    params: {
                        contactsKey: key,
                    },
                });
                info.value = res.data;
            }
        });
        return {
            handleCopyClick,
            info,
            getFlagUrlByCountry: window.getFlagUrlByCountry,
        };
    },
});
</script>

<template>
    <div class="contact-card">
        <div style="display: flex; padding-bottom: 10px; border-bottom: 1px solid #D9D9D9">
            <img src="@/assets/img/headImg.png" style="width: 45px; height: 45px; flex-shrink: 0" />
            <div style="margin-left: 18px; display: flex; flex-direction: column; justify-content: space-between">
                <p style="color: #000">{{ info.contacts }}</p>
                <el-rate :value="Number(info.star)" disabled />
            </div>
        </div>
        <div>
            <p class="description-item utils-omit-1">分组：{{ info.publicGroupName }}</p>
            <p class="description-item utils-omit-1">状态：{{ info.statuName }}</p>
            <p class="description-item utils-omit-1">
                <span>所属公司：</span>
                <span style="color: #092338">{{ info.companyName }}</span>
            </p>
            <div class="description-item utils-omit-1" style="display: flex; align-items: center">
                <span>国家地区：</span>
                <img :src="getFlagUrlByCountry(info.country)" style="height: 14px; margin-right: 3px" />
                <span style="color: #1A2745">{{ info.country }}</span>
            </div>
            <p class="description-item utils-omit-1">跟进人：{{ info.followUserName }}</p>
            <div class="description-item utils-omit-1" style="display: flex; align-items: center">
                <img src="@/assets/img/icon-mail.png" style="flex-shrink: 0; margin-right: 3px" />
                <p class="utils-omit-1" style="width: 100%">{{ info.email }}</p>
            </div>
            <div class="tag-area" v-show="info.industry">
                <span class="tag" style="background: #FFAA48">{{ info.industry }}</span>
            </div>
        </div>
        <div style="padding-top: 10px; border-bottom: 1px solid #D9D9D9">
            <div class="description-item utils-omit-1" style="display: flex; align-items: center">
                <p class="utils-omit-1" style="color: var(--themeColor)">{{ info.email }}</p>
                <img @click="handleCopyClick(info.email)" class="utils-cursor-pointer" src="@/assets/img/icon-copy.png" style="flex-shrink: 0; margin-left: 8px" />
            </div>
            <p class="description-item utils-omit-1">职位：{{ info.position }}</p>
            <p class="description-item utils-omit-1">电话：{{ info.phone }}</p>
        </div>
        <!-- <div style="padding-top: 12px; display: flex">
      <div class="link-item">
        <span class="utils-cursor-pointer">写跟进</span>
      </div>
      <div class="link-item">
        <span class="utils-cursor-pointer">编辑客户</span>
      </div>
      <div class="link-item">
        <span class="utils-cursor-pointer">新建商机</span>
      </div>
      <div class="link-item">
        <span class="utils-cursor-pointer">往来邮件</span>
      </div>
    </div> -->
    </div>
</template>

<style scoped lang="scss">
.contact-card {
    box-sizing: border-box;
    width: 420px;
    font-size: 16px;
}

.description-item {
    width: 100%;
    font-size: 14px;
    color: #707070;
    margin: 5px 0;

    &:first-child {
        margin-top: 10px;
    }
}

.tag-area {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -10px;
    border-bottom: 1px solid #d9d9d9;
}

.tag {
    cursor: default;
    height: 20px;
    padding: 0 5px;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #ffffff;
    margin-right: 10px;
    margin-bottom: 10px;
}

.link-item {
    color: #333;
    font-size: 14px;
    padding: 0 1em;
    border-right: 1px solid #d9d9d9;

    &:first-child {
        padding-left: 0;
    }
}
</style>
