<template>
    <div style="background-color: #fff;">
        <div class="w">
            <div class="title">选择适合您的产品</div>
            <div class="describe">所有的账户额度都是以月来进行计算，下月额度将会重新充值</div>
            <div class="chooseYear">
                <div class="oneItem" :class="{'activeYear': yearValue == '一年'}" @click="chooseYear('一年')">一年</div>
                <div class="twoItem" :class="{'activeYear': yearValue == '两年'}" @click="chooseYear('两年')">两年(80%折扣)</div>
            </div>
            <div class="chooseVersion">
                <div class="leftItem">
                    <div class="header"></div>
                    <div class="content">
                        <div class="permissionItem moduleTitle">获客模块</div>
                        <div class="permissionItem">社媒获客</div>
                        <div class="permissionItem">客户线索</div>
                        <div class="permissionItem">商机推荐</div>
                        <div class="permissionItem">企业资讯</div>
                        <div class="permissionItem">客户管理</div>
                        <div class="permissionItem">客户营销（EDM、WA等）</div>
                        <div class="permissionItem">贸易全景</div>
                        <div class="permissionItem moduleTitle">营销模块</div>
                        <div class="permissionItem">订单管理</div>
                        <div class="permissionItem">出口选品 ToB</div>
                        <div class="permissionItem">电商选品 ToC</div>
                        <div class="permissionItem">预警提示</div>
                        <div class="permissionItem">产品手册配置</div>
                        <div class="permissionItem">合同、报价配置</div>
                        <div class="permissionItem moduleTitle">工具类</div>
                        <div class="permissionItem">全球企业风险核实</div>
                        <div class="permissionItem">营销分析</div>
                        <div class="permissionItem">进出口验证</div>
                        <div class="permissionItem">API服务</div>
                        <div class="permissionItem">集团ERP中台服务</div>
                        <div class="permissionItem">外贸工具</div>
                        <div class="permissionItem">行业分析</div>
                    </div>
                </div>
                <div class="versionItem">
                    <div class="header">
                        <div class="name">基础版</div>
                        <div class="price">永久免费</div>
                    </div>
                    <div class="content">
                        <div class="permissionItem"></div>
                        <div class="permissionItem"><i class="el-icon-circle-close"></i></div>
                        <div class="permissionItem"><i class="el-icon-circle-close"></i></div>
                        <div class="permissionItem"><i class="el-icon-circle-close"></i></div>
                        <div class="permissionItem"><i class="el-icon-circle-close"></i></div>
                        <div class="permissionItem"><i class="el-icon-circle-close"></i></div>
                        <div class="permissionItem"><i class="el-icon-circle-close"></i></div>
                        <div class="permissionItem"><i class="el-icon-circle-close"></i></div>
                        <div class="permissionItem"></div>
                        <div class="permissionItem"><i class="el-icon-circle-close"></i></div>
                        <div class="permissionItem"><i class="el-icon-circle-close"></i></div>
                        <div class="permissionItem"><i class="el-icon-circle-check"></i></div>
                        <div class="permissionItem"><i class="el-icon-circle-check"></i></div>
                        <div class="permissionItem"><i class="el-icon-circle-check"></i></div>
                        <div class="permissionItem"><i class="el-icon-circle-check"></i></div>
                        <div class="permissionItem"></div>
                        <div class="permissionItem"><i class="el-icon-circle-close"></i></div>
                        <div class="permissionItem"><i class="el-icon-circle-close"></i></div>
                        <div class="permissionItem"><i class="el-icon-circle-check"></i></div>
                        <div class="permissionItem"><i class="el-icon-circle-close"></i></div>
                        <div class="permissionItem"><i class="el-icon-circle-close"></i></div>
                        <div class="permissionItem"><i class="el-icon-circle-close"></i></div>
                        <div class="permissionItem"><i class="el-icon-circle-close"></i></div>
                    </div>
                </div>
                <div class="versionItem">
                    <div class="header">
                        <div class="name">启航版</div>
                        <div class="price">¥9800元<span class="unit">/年</span></div>
                        <div class="btn" @click="toBuy('启航版')">立即购买</div>
                    </div>
                    <div class="content">
                        <div class="permissionItem"></div>
                        <div class="permissionItem"><i class="el-icon-circle-check"></i></div>
                        <div class="permissionItem"><i class="el-icon-circle-check"></i></div>
                        <div class="permissionItem"><i class="el-icon-circle-check"></i></div>
                        <div class="permissionItem"><i class="el-icon-circle-close"></i></div>
                        <div class="permissionItem"><i class="el-icon-circle-close"></i></div>
                        <div class="permissionItem"><i class="el-icon-circle-close"></i></div>
                        <div class="permissionItem"><i class="el-icon-circle-close"></i></div>
                        <div class="permissionItem"></div>
                        <div class="permissionItem"><i class="el-icon-circle-close"></i></div>
                        <div class="permissionItem"><i class="el-icon-circle-close"></i></div>
                        <div class="permissionItem"><i class="el-icon-circle-check"></i></div>
                        <div class="permissionItem"><i class="el-icon-circle-check"></i></div>
                        <div class="permissionItem"><i class="el-icon-circle-check"></i></div>
                        <div class="permissionItem"><i class="el-icon-circle-check"></i></div>
                        <div class="permissionItem"></div>
                        <div class="permissionItem"><i class="el-icon-circle-close"></i></div>
                        <div class="permissionItem"><i class="el-icon-circle-close"></i></div>
                        <div class="permissionItem"><i class="el-icon-circle-check"></i></div>
                        <div class="permissionItem"><i class="el-icon-circle-close"></i></div>
                        <div class="permissionItem"><i class="el-icon-circle-close"></i></div>
                        <div class="permissionItem"><i class="el-icon-circle-close"></i></div>
                        <div class="permissionItem"><i class="el-icon-circle-close"></i></div>
                    </div>
                </div>
                <div class="versionItem">
                    <div class="header">
                        <div class="name">企业版</div>
                        <div class="price">¥19800元<span class="unit">/年</span></div>
                        <div class="btn" @click="toBuy('企业版')">立即购买</div>
                    </div>
                    <div class="content">
                        <div class="permissionItem"></div>
                        <div class="permissionItem"><i class="el-icon-circle-check"></i></div>
                        <div class="permissionItem"><i class="el-icon-circle-check"></i></div>
                        <div class="permissionItem"><i class="el-icon-circle-check"></i></div>
                        <div class="permissionItem"><i class="el-icon-circle-check"></i></div>
                        <div class="permissionItem"><i class="el-icon-circle-check"></i></div>
                        <div class="permissionItem"><i class="el-icon-circle-check"></i></div>
                        <div class="permissionItem"><i class="el-icon-circle-check"></i></div>
                        <div class="permissionItem"></div>
                        <div class="permissionItem"><i class="el-icon-circle-close"></i></div>
                        <div class="permissionItem"><i class="el-icon-circle-close"></i></div>
                        <div class="permissionItem"><i class="el-icon-circle-check"></i></div>
                        <div class="permissionItem"><i class="el-icon-circle-check"></i></div>
                        <div class="permissionItem"><i class="el-icon-circle-check"></i></div>
                        <div class="permissionItem"><i class="el-icon-circle-check"></i></div>
                        <div class="permissionItem"></div>
                        <div class="permissionItem"><i class="el-icon-circle-close"></i></div>
                        <div class="permissionItem"><i class="el-icon-circle-close"></i></div>
                        <div class="permissionItem"><i class="el-icon-circle-check"></i></div>
                        <div class="permissionItem"><i class="el-icon-circle-close"></i></div>
                        <div class="permissionItem"><i class="el-icon-circle-close"></i></div>
                        <div class="permissionItem"><i class="el-icon-circle-close"></i></div>
                        <div class="permissionItem"><i class="el-icon-circle-close"></i></div>
                    </div>
                </div>
                <div class="versionItem">
                    <div class="header">
                        <div class="name">定制版</div>
                        <div class="price">面谈另议</div>
                        <div class="btn" @click="contactService">联系客服</div>
                    </div>
                    <div class="content">
                        <div class="permissionItem"></div>
                        <div class="permissionItem"></div>
                        <div class="permissionItem"></div>
                        <div class="permissionItem"></div>
                        <div class="permissionItem"></div>
                        <div class="permissionItem"></div>
                        <div class="permissionItem"></div>
                        <div class="permissionItem"></div>
                        <div class="permissionItem"></div>
                        <div class="permissionItem"></div>
                        <div class="permissionItem"></div>
                        <div class="permissionItem"></div>
                        <div class="permissionItem"></div>
                        <div class="permissionItem"></div>
                        <div class="permissionItem"></div>
                        <div class="permissionItem"></div>
                        <div class="permissionItem"></div>
                        <div class="permissionItem"></div>
                        <div class="permissionItem"></div>
                        <div class="permissionItem"></div>
                        <div class="permissionItem"></div>
                        <div class="permissionItem"></div>
                        <div class="permissionItem"></div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            yearValue: "一年",
        };
    },
    methods: {
        chooseYear(value) {
            this.yearValue = value;
        },

        toBuy(versionValue) {
            this.$router.push(`/PayforOrder`);
            localStorage.setItem("versionValue", versionValue);
            localStorage.setItem("yearValue", this.yearValue);
        },

        contactService(){
            let userInfo = JSON.parse(localStorage.userInfo);
            const {mailAddress,phone,uKey} = userInfo;
            let url = `https://yzf.qq.com/xv/web/static/chat/index.html?sign=37ef9b97877604937445cdeb4ab2b2347cf4c2a1a8fda5d26628aac35a36f5a4937b415c45c8c72e35c204a2b1bc26079ef2b3&uid=${uKey}&c1=${mailAddress}&c2=${phone}`
            window.open(url);
            
        }
    },
    mounted() {},
};
</script>

<style lang="scss" scoped>
.w {
    width: 1250px;
    margin: 0 auto;
    padding: 20px 0;
    .title {
        text-align: center;
        height: 44px;
        line-height: 44px;
        font-size: 30px;
        font-weight: bold;
        color: #2c405a;
    }
    .describe {
        margin-top: 10px;
        text-align: center;
        height: 36px;
        line-height: 36px;
        font-size: 18px;
        color: #3f536e;
    }
    .chooseYear {
        width: 600px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        margin: 10px auto;
        background: #eef3f9;
        border-radius: 7px 7px 7px 7px;
        border: 1px solid #d7edf9;
        filter: blur(undefinedpx);
        display: flex;
        cursor: pointer;
        font-size: 20px;
        color: #225ec4;
        .oneItem {
            width: 200px;
            border-radius: 7px 0px 0px 7px;
            border: 1px solid #d7edf9;
            filter: blur(undefinedpx);
        }
        .twoItem {
            width: 400px;
            border-radius: 0px 7px 7px 0px;
            border: 1px solid #d7edf9;
            filter: blur(undefinedpx);
        }
        .activeYear {
            background: #ffffff;
        }
    }
    .chooseVersion {
        width: 1250px;
        display: flex;
        margin: 20px auto;
        .versionItem {
            width: 250px;
            text-align: center;
            padding: 20px 0;
            cursor: pointer;
            .name {
                font-size: 20px;
                font-weight: bold;
            }
            .price {
                font-size: 34px;
                font-weight: 400;
                color: #2c405a;
                .unit {
                    padding-left: 5px;
                    font-size: 20px;
                }
            }
            .btn {
                width: 200px;
                height: 60px;
                line-height: 60px;
                margin: 10px auto 0;
                font-size: 20px;
                color: #ffffff;
                background-color: var(--themeColor);
                border-radius: 4px 4px 4px 4px;
                opacity: 0.8;
                cursor: pointer;
            }
            &:hover {
                background: rgba(113, 174, 254, 0.15);
            }
            &:hover .name {
                color: var(--themeColor);
            }
            &:hover .btn {
                opacity: 1;
            }
            .header {
                height: 160px;
            }
            .content {
                .permissionItem {
                    height: 40px;
                    line-height: 40px;
                    width: 250px;
                    text-align: center;
                    box-sizing: border-box;
                    border-bottom: 1px solid #d9e4ed;
                }
            }
        }
        .leftItem {
            width: 250px;
            padding: 20px 0;
            .header {
                height: 160px;
            }
            .content {
                .moduleTitle {
                    padding-left: 30px !important;
                    font-size: 20px;
                    font-weight: bold;
                    color: #000;
                }
                .permissionItem {
                    height: 40px;
                    line-height: 40px;
                    width: 250px;
                    padding-left: 50px;
                    box-sizing: border-box;
                    border-bottom: 1px solid #d9e4ed;
                }
            }
        }
        .el-icon-circle-check {
            color: #000000;
            font-size: 20px;
        }
        .el-icon-circle-close {
            color: #dfdfdf;
            font-size: 20px;
        }
    }
}
</style>