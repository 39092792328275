<template>
<div style="display: flex;">
    <PurchaseNav @chooseNav="chooseNav" :currentPath="currentPath" />
    <div class="viewbox">
        <div class="boxOne">
            <div class="title">采购记录</div>
            <!-- 管理员可以查看整个部门 -->
            <PowerSelect defaultText="我的采购" @onChoose="onChoose" />

            <div class="rightHandle">
                <el-button size="small" type="primary" icon="el-icon-plus" @click="toNew">新建采购记录</el-button>
                <el-button size="small" @click="recordImport">采购记录导入</el-button>
            </div>
        </div>

        <div class="boxTwo">
            <div class="txt">已选择{{chooseList.length}}个采购记录</div>
            <div class="rightHandle">
                <el-input v-model="filterParam.procurementPlan" placeholder="采购计划" prefix-icon="el-icon-search" size="small" style="width:300px;margin-right:10px;" @keyup.enter.native="toSearch"></el-input>
            </div>
        </div>

        <div class="boxTable">
            <el-table style="width:100%;" height="calc(100vh - 300px)" v-loading="tableLoading" :data="recordList"
                :header-cell-style="{'background-color':'#F2F2F2','color':'#3A3A3A'}" :row-style="rowStyle"
                @selection-change="selectionChange">
                <el-table-column type="selection" width="45"></el-table-column>
                <el-table-column label="采购计划">
                    <template slot-scope="scope">
                        <el-link @click.stop="toDetails(scope.row)">{{scope.row.procurementPlan}}</el-link>
                    </template>
                </el-table-column>
                <el-table-column prop="purchaseNo" label="采购编号"></el-table-column>
                <el-table-column prop="purchaseDate" label="采购日期"></el-table-column>
                <el-table-column prop="deliveryDate" label="交货日期"></el-table-column>
                <el-table-column prop="manufacturerName" label="供应商名称"></el-table-column>
                <el-table-column prop="productInfo.productName" label="采购产品"></el-table-column>
                <el-table-column prop="purchasingTotalNum" label="采购数量"></el-table-column>
                <el-table-column prop="purchasingTotalPrice" label="采购金额"></el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-link type="info" @click.stop="toDetails(scope.row)">查看</el-link>
                        <span style="padding:0 5px;"></span>
                        <el-link type="info" @click.stop="toEdit(scope.row)">编辑</el-link>
                        <span style="padding:0 5px;"></span>
                        <el-link type="info" @click.stop="toCopy(scope.row)">复制</el-link>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <div class="bottomPagination">
            <el-button style="float:left;margin:15px 20px;" size="small" icon="el-icon-refresh" @click="onRefresh">刷 新</el-button>
            <el-pagination background layout="total,sizes,prev,pager,next" :page-sizes="[10, 20, 40]" :page-size.sync="pageSize" @size-change="pageSizeChange" :total="totalItems" style="float:right;margin:15px 20px;" :current-page.sync="pageIndex" @current-change="pageIndexChange"></el-pagination>
        </div>

    </div>
</div>
</template>

<script>
import PurchaseNav from "@/components/PurchaseNav.vue";
import PowerSelect from "@/components/PowerSelect.vue";
export default {
    components: { PurchaseNav, PowerSelect },
    props: {
        handleSelect: {
            type: Function,
            default: null,
        },
    },
    data() {
        return {
            currentPath: "/purchase/recordList",
            organizationKey: "",
            salekey: "",
            // 过滤字段
            filterParam: {
                procurementPlan: "",
                purchaseNo: "",
            },
            //列表展示
            tableLoading: false,
            recordList: [],
            totalItems: 0,
            pageIndex: 1,
            pageSize: 10,

            chooseList: [],
        };
    },
    methods: {
        chooseNav(index){
            this.handleSelect(index);
            this.$router.push(index);
        },
        rowStyle({rowIndex}){
            // 表格隔行换色
            if(rowIndex % 2 == 1){
                return {'background-color':'#F9FAFC'}
            }
        },

        onRefresh(){
            this.filterParam = {
                procurementPlan: "",
                purchaseNo: "",
            };
            this.toSearch();
        },
        toSearch(){
            this.pageIndex = 1;
            this.getList();
        },
        getList(){
            const {procurementPlan,purchaseNo} = this.filterParam;
            this.tableLoading = true;
            this.$axios.post(`${this.$orderUrl}/Purchase/GetPurchasingRecords`,{
                procurementPlan,purchaseNo,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
                organizationKey: this.organizationKey,
                ukey: this.salekey,
            }).then((res) => {
                const { isSuccess , data } = res;
                this.tableLoading = false;
                if (isSuccess) {
                    this.recordList = data.datas;
                    this.totalItems = data.totalItems;
                }
            });
        },
        pageIndexChange(page) {
            this.pageIndex = page;
            this.getList();
        },
        pageSizeChange() {
            this.pageIndex = 1;
            this.getList();
        },

        selectionChange(value){
            console.log(value);
            this.chooseList = value;
        },

        recordImport(){
            this.handleSelect(`/purchase/recordImport`);
            this.$router.push(`/purchase/recordImport`);
        },
        toNew(){
            let routeData = this.$router.resolve({
                path: "/purchase/newRecord",
            });
            window.open(routeData.href, "_blank");
        },
        toEdit(row){
            let routeData = this.$router.resolve({
                path: "/purchase/newRecord",
                query: { key: row.key },
            });
            window.open(routeData.href, "_blank");
        },
        toCopy(row){
            let routeData = this.$router.resolve({
                path: "/purchase/newRecord",
                query: { key: row.key, optype: "copy" },
            });
            window.open(routeData.href, "_blank");
        },
        toDetails(row){
            let routeData = this.$router.resolve({
                path: "/purchase/recordDetails",
                query: { key: row.key },
            });
            window.open(routeData.href, "_blank");
        },


        //选择组织结构、员工
        onChoose(valueOne,valueTwo){
            this.organizationKey = valueOne;
            this.salekey = valueTwo;
            this.onRefresh();
        },

    },
    mounted() {
        this.getList();
    },
};
</script>

<style lang="scss" scoped>
.viewbox{
    width: calc(100% - 200px);
    height: calc(100vh - 140px);
    background: rgba($color: #FFFFFF, $alpha: 0.7);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    margin-bottom: 60px;
    .boxOne,.boxTwo{
        overflow: hidden;
        padding: 8px 0;
        line-height: 32px;
        border-bottom: 1px solid #E6E7EB;
        .title{
            float: left;
            margin-left: 40px;
            font-size: 24px;
            font-weight: bold;
            color: #3A3A3A;
        }
        .txt{
            float: left;
            margin-left: 40px;
            font-size: 16px;
            color: #7C90A5;
        }
        .rightHandle{
            float: right;
            margin-right: 20px;
        }
    }

    .bottomPagination{
        position: fixed;
        bottom: 0;
        right: 0;
        z-index: 10;
        width: calc(100vw - 200px);
        height: 60px;
        background: #FFFFFF;
        box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.16);
    }
}
</style>