<template>
<div style="display: flex;">
    <ClueNav @chooseNav="chooseNav" :currentPath="currentPath" />

    <div class="bigViewbox">
        <div class="termViewbox" v-show="pageState=='查询条件'">
            <div class="headerOne">
                <div class="theme">搜索全球企业报告</div>
                <!-- <div class="residue">剩余点数：<span>{{bonusPoint}}</span></div> -->
            </div>
            <div class="headerTwo">
                <div class="describe">通过客户所在国家或地区、创立年份、行业类型等找到他们</div>
                <div class="lookover" @click="viewPurchase">查看我购买的公司>></div>
            </div>
            <BasicInfo @inputComplete="inputCompleteOne" @countryChange="countryChange" />
            <CompanyScale @inputComplete="inputCompleteTwo" />
            <WebsiteInfo @inputComplete="inputCompleteThree" />
            <ContactInfo @inputComplete="inputCompleteFour" />
            <GeographicInfo @inputComplete="inputCompleteFive" :countryCode="countryCode" />
            <OtherInfo @inputComplete="inputCompleteSix" />
        </div>
        <div class="bottomBg" v-show="pageState=='查询条件'">
            <span class="sp1">共查找出</span>
            <span style="padding:0 10px;"></span>
            <span class="sp1">公司数：</span>
            <span class="sp2">{{bottomCountForm.total}}</span>
            <span class="sp1">家</span>
            <span style="padding:0 10px;"></span>
            <span class="sp1">联系人数：</span>
            <span class="sp2">{{bottomCountForm.hasContact}}</span>
            <span class="sp1">个</span>
            <span style="padding:0 10px;"></span>
            <span class="sp1">电话数：</span>
            <span class="sp2">{{bottomCountForm.hasPhone}}</span>
            <span class="sp1">个</span>
            <span style="padding:0 10px;"></span>
            <span class="sp1">邮箱数：</span>
            <span class="sp2">{{bottomCountForm.hasEmail}}</span>
            <span class="sp1">个</span>
            <span style="padding:0 10px;"></span>
            <el-button type="primary" icon="el-icon-search" @click="toSearch" :loading="searchLoading">搜索</el-button>
            <el-button type="primary" icon="el-icon-view" @click="viewResult" :disabled="bottomCountForm.total==0||searchId==0">查看结果</el-button>
        </div>

        <div class="resultViewbox" v-show="pageState=='得到结果'">
            <el-button type="primary" plain size="small" icon="el-icon-d-arrow-left" @click="goBack">返回到条件搜索页</el-button>
            <!-- 商业数据/工商信息 -->
            <div class="searchResult" v-loading="tableLoading" v-if="dataType == 'BusinessInformation' || dataType == 'BusinessData'">
                <div class="title">
                    <div>为您找到<span style="color:#FF2D2D;">{{totalItems}}</span>条相关结果</div>
                </div>
                <div class="resultList">
                    <div class="resultItem" v-for="item in resultList" :key="item.key">
                        <div style="margin-bottom: 8px;">
                            <div class="name" @click="toDetails(item)">{{item.company}}</div>
                            <div class="country">
                                <img :src="item.flagUrl" style="margin-right:5px;height:14px" />
                                <span>{{item.country}}</span>
                            </div>
                        </div>
                        <div v-if="dataType == 'BusinessInformation'">
                            <div class="twoField">
                                <span class="color99">邮箱：</span>
                                <span class="color3a">{{item.email}}</span>
                            </div>
                            <div class="twoField">
                                <span class="color99">企业官网：</span>
                                <span class="primaryColor" @click="openWebsite(item.domain)">{{item.domain}}</span>
                            </div>
                            <div class="twoField">
                                <span class="color99">公司地址：</span>
                                <span class="color3a">{{item.address}}</span>
                            </div>
                        </div>
                        <div v-if="dataType == 'BusinessData'">
                            <div class="twoField">
                                <span class="color99">注册时间：</span>
                                <span class="color3a">{{item.incorporationDateStr}}</span>
                            </div>
                            <div class="twoField">
                                <span class="color99">企业官网：</span>
                                <span class="primaryColor" @click="openWebsite(item.domain)">{{item.domain}}</span>
                            </div>
                            <div class="twoField">
                                <span class="color99">公司地址：</span>
                                <span class="color3a">{{item.address}}</span>
                            </div>
                            <div class="twoField">
                                <span class="color99">行业：</span>
                                <span class="color3a" v-for="ele in item.sicModels" :key="ele.primaryCode" style="margin-right:20px;">
                                    ({{ele.primaryCode}}){{ele.sicDescription}}
                                </span>
                            </div>
                        </div>
                        <template>
                            <div class="hasAdd" v-if="item.isMyClue">已加入我的线索</div>
                            <div class="addBtn" @click="addMyClueOne(item)" :class="isDisable ? 'forbidden' :''" v-else>
                                加入我的线索
                            </div>
                        </template>
                    </div>
                    <el-pagination v-show="totalItems != 0" background layout="total,prev,pager,next" :total="totalItems" style="float:right;margin-top:15px;" :current-page.sync="pageIndex" @current-change="pageIndexChange"></el-pagination>
                </div>
            </div>
            <!-- Infobel信息 -->
            <div class="resultContent" v-loading="tableLoading" v-else>
                <el-table :data="resultList" :header-cell-style="{'background-color':'#E9E9E9','color':'#3A3A3A'}">
                    <!-- @selection-change="selectionChange" -->
                    <!-- <el-table-column type="selection" width="45"></el-table-column> -->
                    <el-table-column label="线索信息">
                        <template slot="header">
                            共有<span style="font-size: 18px;color: var(--themeColor);padding: 0 5px;">{{totalItems}}</span>家企业
                        </template>
                        <template slot-scope="scope">
                            <div class="clueInfo">
                                <!-- <div class="contactsNum">
                                    <div>联系人</div>
                                    <div style="font-size:18px;">100</div>
                                </div> -->
                                <div>
                                    <div style="margin-bottom: 8px;">
                                        <div class="companyName" @click="toDetails(scope.row)">{{scope.row.businessName}}</div>
                                        <div class="country">
                                            <img :src="scope.row.nationalFlag" style="margin-right:5px;height:14px" />
                                            <span>{{scope.row.countryCn}}</span>
                                        </div>
                                        <el-tag v-if="scope.row.isMyCustomer" size="small" type="success">我的客户</el-tag>
                                    </div>
                                    <div style="display: flex;">
                                        <div class="field">
                                            <span class="color99">营销额：</span>
                                            <span class="color3a">{{scope.row.salesVolume || "-"}}</span>
                                        </div>
                                        <div class="field">
                                            <span class="color99">员工数：</span>
                                            <span class="color3a">{{scope.row.employeesTotal || "-"}}</span>
                                        </div>
                                        <div class="field">
                                            <span class="color99">联系电话：</span>
                                            <span class="color3a">{{scope.row.phone || "-"}}</span>
                                        </div>
                                    </div>
                                    <div style="display: flex;">
                                        <div class="field">
                                            <span class="color99">企业官网：</span>
                                            <span class="primaryColor" @click="openWebsite(scope.row.website)">
                                                {{scope.row.website || "-"}}
                                            </span>
                                        </div>
                                        <div class="field">
                                            <span class="color99">企业邮箱：</span>
                                            <span class="color3a">{{scope.row.email || "-"}}</span>
                                        </div>
                                        <div class="field">
                                            <span class="color99">ceo姓名：</span>
                                            <span class="color3a">{{scope.row.admin || "-"}}</span>
                                        </div>
                                    </div>
                                    <div class="field">
                                        <span class="color99">关联公司：</span>
                                        <span class="color3a">{{scope.row.linkage || "-"}}</span>
                                    </div>
                                    <div class="field">
                                        <span class="color99">行业：</span>
                                        <span class="color3a" v-for="ele in scope.row.industryCodes" :key="ele.code">
                                            {{ele.describeCn}}({{ele.code}})
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column width="180">
                        <template slot-scope="scope">
                            <div class="hasAdd" v-if="scope.row.isMyClue">已加入我的线索</div>
                            <div class="addBtn" @click="addMyClueTwo(scope.row)" :class="isDisable ? 'forbidden' :''" v-else>
                                加入我的线索
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination v-show="totalItems != 0" background layout="total,prev,pager,next" :total="totalItems" style="float:right;margin-top:15px;" :current-page.sync="pageIndex" @current-change="pageIndexChange"></el-pagination>
            </div>
        </div>

        <div class="resultViewbox" v-show="pageState=='查看购买公司'">
            <div style="display: flex;">
                <el-button type="primary" plain size="small" icon="el-icon-d-arrow-left" @click="goBack">返 回</el-button>
                <el-input v-model="businessName" placeholder="公司名称" clearable size="small" style="width:280px;margin:0 10px;"></el-input>
                <el-button type="primary" size="small" @click="viewPurchase">搜 索</el-button>
            </div>
            <div class="resultContent" v-loading="tableLoading">
                <el-table :data="purchaseList" :header-cell-style="{'background-color':'#E9E9E9','color':'#3A3A3A'}">
                    <!-- @selection-change="selectionChange" -->
                    <!-- <el-table-column type="selection" width="45"></el-table-column> -->
                    <el-table-column label="线索信息">
                        <template slot="header">
                            共有<span style="font-size: 18px;color: var(--themeColor);padding: 0 5px;">{{purchaseTotal}}</span>家企业
                        </template>
                        <template slot-scope="scope">
                            <div class="clueInfo">
                                <div>
                                    <div style="margin-bottom: 8px;">
                                        <div class="companyName" @click="toDetails(scope.row)">{{scope.row.businessName}}</div>
                                        <div class="country">
                                            <img :src="scope.row.nationalFlag" style="margin-right:5px;height:14px" />
                                            <span>{{scope.row.countryCn}}</span>
                                        </div>
                                        <el-tag v-if="scope.row.isMyCustomer" size="small" type="success">我的客户</el-tag>
                                    </div>
                                    <div style="display: flex;">
                                        <div class="field">
                                            <span class="color99">营销额：</span>
                                            <span class="color3a">{{scope.row.salesVolume || "-"}}</span>
                                        </div>
                                        <div class="field">
                                            <span class="color99">员工数：</span>
                                            <span class="color3a">{{scope.row.employeesTotal || "-"}}</span>
                                        </div>
                                        <div class="field">
                                            <span class="color99">联系电话：</span>
                                            <span class="color3a">{{scope.row.phone || "-"}}</span>
                                        </div>
                                    </div>
                                    <div style="display: flex;">
                                        <div class="field">
                                            <span class="color99">企业官网：</span>
                                            <span class="primaryColor" @click="openWebsite(scope.row.website)">
                                                {{scope.row.website || "-"}}
                                            </span>
                                        </div>
                                        <div class="field">
                                            <span class="color99">企业邮箱：</span>
                                            <span class="color3a">{{scope.row.email || "-"}}</span>
                                        </div>
                                        <div class="field">
                                            <span class="color99">ceo姓名：</span>
                                            <span class="color3a">{{scope.row.admin || "-"}}</span>
                                        </div>
                                    </div>
                                    <div class="field">
                                        <span class="color99">关联公司：</span>
                                        <span class="color3a">{{scope.row.linkage || "-"}}</span>
                                    </div>
                                    <div class="field">
                                        <span class="color99">行业：</span>
                                        <span class="color3a" v-for="ele in scope.row.industryCodes" :key="ele.code">
                                            {{ele.describeCn}}({{ele.code}})
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column width="180">
                        <template slot-scope="scope">
                            <div class="hasAdd" v-if="scope.row.isMyClue">已加入我的线索</div>
                            <div class="addBtn" @click="addMyClueTwo(scope.row)" :class="isDisable ? 'forbidden' :''" v-else>
                                加入我的线索
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination v-show="purchaseTotal != 0" background layout="total,prev,pager,next" :total="purchaseTotal" style="float:right;margin-top:15px;" :current-page.sync="purchasePage" @current-change="getInfobelPurchase"></el-pagination>
            </div>
        </div>

    </div>
</div>
</template>

<script>
import ClueNav from "@/components/ClueNav.vue";
import BasicInfo from "./BusinessDataCom/BasicInfo.vue";        //基础信息
import CompanyScale from "./BusinessDataCom/CompanyScale.vue";  //公司规模
import WebsiteInfo from "./BusinessDataCom/WebsiteInfo.vue";    //网站信息
import ContactInfo from "./BusinessDataCom/ContactInfo.vue";    //联系信息
import GeographicInfo from "./BusinessDataCom/GeographicInfo.vue";  //地理信息
import OtherInfo from "./BusinessDataCom/OtherInfo.vue";        //其他信息
import {toFirm} from '@/utils'
export default {
    components: { ClueNav, BasicInfo, CompanyScale, WebsiteInfo, ContactInfo, GeographicInfo, OtherInfo },
    props: {
        handleSelect: {
            type: Function,
            default: null,
        },
    },
    data() {
        return {
            currentPath: "/clue/businessDataSearch",
            pageState: "查询条件",
            bonusPoint: 999,
            countryCode: "",
            basicsForm: {},
            scaleForm: {},
            websiteForm: {},
            contactForm: {},
            geographyForm: {},
            otherForm: {},
            searchLoading: false,
            bottomCountForm: {
                total: 311382345,
                hasContact: 121199933,
                hasPhone: 92327154,
                hasEmail: 19647999,
            },
            searchId: 0,
            dataType: "",   //BusinessData(商业数据) BusinessInformation(工商信息) Infobel信息
            //搜索结果
            tableLoading: false,
            resultList: [],
            totalItems: 0,
            pageIndex: 1,
            //选中集合
            selectionList: [],
            isDisable: false,

            //已购买公司
            businessName: "",
            purchaseList: [],
            purchaseTotal: 0,
            purchasePage: 1,
        };
    },
    methods: {
        chooseNav(index){
            this.handleSelect(index);
            this.$router.push(index);
        },

        goBack(){
            this.pageState = "查询条件";
        },

        inputCompleteOne(basicsForm){
            this.basicsForm = basicsForm;
        },
        countryChange(value){
            this.countryCode = value;
        },
        inputCompleteTwo(scaleForm){
            this.scaleForm = scaleForm;
        },
        inputCompleteThree(websiteForm){
            this.websiteForm = websiteForm;
        },
        inputCompleteFour(contactForm){
            this.contactForm = contactForm;
        },
        inputCompleteFive(geographyForm){
            this.geographyForm = geographyForm;
        },
        inputCompleteSix(otherForm){
            this.otherForm = otherForm;
            this.otherForm.languages = this.otherForm.languages.trim();
        },
        //点击底部搜索按钮
        toSearch(){
            // 查询公司结果条数
            this.searchLoading = true;
            // v2版本搜索
            this.$axios.post(`${this.$clueUrl}/BusinessData/Infobel/v2/SearchCount`, {
                request: {
                    pageIndex: 1,
                    pageSize: 10,
                    ...this.basicsForm,
                    ...this.scaleForm,
                    ...this.websiteForm,
                    ...this.contactForm,
                    ...this.geographyForm,
                    ...this.otherForm
                },
                searchId: 0,
            }).then((res) => {
                const { isSuccess, data } = res;
                this.searchLoading = false;
                if (isSuccess) {
                    if(data.counts.total == 0){
                        this.$message({message: '查询无数据,请更换搜索条件重新搜索!',type: 'warning'});
                    }else{
                        this.bottomCountForm = data.counts;
                        this.searchId = data.searchId;
                        this.dataType = data.counts.dataType;
                    }
                }
            });
        },
        selectionChange(value){
            this.selectionList = value;
        },
        viewResult(){
            this.pageState = "得到结果";
            this.pageIndex = 1;
            this.getResultList();
        },
        getResultList(){
            this.tableLoading = true;
            this.$axios.post(`${this.$clueUrl}/BusinessData/Infobel/v2/SearchPage`, {
                searchId: this.searchId,
                pageIndex: this.pageIndex,
                pageSize: 10,
            }).then((res) => {
                const { isSuccess, data } = res;
                this.tableLoading = false;
                if (isSuccess) {
                    if(this.dataType == 'BusinessInformation' || this.dataType == 'BusinessData'){
                        this.resultList = data.datas.map(item=>({
                            ...item,
                            flagUrl: window.getFlagUrlByCountry?window.getFlagUrlByCountry(item.country):'',
                        }))
                        this.totalItems = data.totalItems * 1;
                    }else{
                        this.resultList = data.data;
                        this.totalItems = data.total;
                    }
                }
            });
        },
        pageIndexChange(page) {
            this.pageIndex = page;
            this.getResultList();
        },




        //打开网址
        openWebsite(url){
            let jumpUrl = url.indexOf("http") > -1 ? url : "http://" + url;
            let routeData = this.$router.resolve({
                path: "/jumpWeb",
                query: { jumpUrl: jumpUrl },
            });
            window.open(routeData.href, "_blank");
        },
        //查看详情
        toDetails(item){
            /* let industrys = [];
            if(item.sicModels){
                item.sicModels.forEach(element =>{
                    industrys.push(`(${element.primaryCode})${element.sicDescription}`)
                })
            }
            let submitObj = {
                "clueSource": item.dataType,
                "clueRelationKey": item.key,
                "companyName": item.company,
                "country": item.country,
                "legalPerson": "",
                "contactNumber": "",
                "registrationTime": item.incorporationDateStr,
                "employeesNumber": 0,
                "officialWebsite": item.website ? item.website : item.domain,
                "corporateEmail": item.email,
                "businessAddress": item.address ? item.address : item.address1,
                "companyProfile": "",
                "industrys": industrys,
                isMyClue:  !item.company||  item.isMyClue
            } */
            //toFirm({item:submitObj,company:item.company||item.businessName,countryCode:item.threeEnCountryCode})
            if(this.dataType == 'BusinessInformation'){
                let routeData = this.$router.resolve({
                    path: "/clue/clueDetails",
                    query: { key: item.key, companyName: item.company, clueType: 'business', country: item.country },
                });
                window.open(routeData.href, "_blank");
            }else if(this.dataType == 'BusinessData'){
                let routeData = this.$router.resolve({
                    path: "/clue/clueDetails",
                    query: { key: item.key, companyName: item.company, clueType: 'commercial', },
                });
               window.open(routeData.href, "_blank");
            }else{
                let routeData = this.$router.resolve({
                    path: "/clue/businessDataDetails",
                    query: { uniqueId: item.uniqueID, countryCn: item.countryCn, },
                });
               window.open(routeData.href, "_blank");
            }
        },
        
        //加入我的线索
        addMyClueOne(item){
            if(this.isDisable){
                return false;
            }
            let industrys = [];
            if(item.sicModels){
                item.sicModels.forEach(element =>{
                    industrys.push(`(${element.primaryCode})${element.sicDescription}`)
                })
            }
            let submitObj = {
                "clueSource": item.dataType,
                "clueRelationKey": item.key,
                "companyName": item.company,
                "country": item.country,
                "legalPerson": "",
                "contactNumber": "",
                "registrationTime": item.incorporationDateStr,
                "employeesNumber": 0,
                "officialWebsite": item.website ? item.website : item.domain,
                "corporateEmail": item.email,
                "businessAddress": item.address ? item.address : item.address1,
                "companyProfile": "",
                "industrys": industrys,
            }
            this.isDisable = true;
            this.$axios.post(`${this.$clueUrl}/MyClue/AddMyClue`, submitObj).then((res) => {
                const { isSuccess } = res;
                this.isDisable = false;
                if (isSuccess) {
                    this.$message({ message: '添加成功', type: 'success'});
                    item.isMyClue = true;
                }
            });
        },
        addMyClueTwo(item){
            if(this.isDisable){
                return false;
            }
            let submitObj = {
                "clueSource": 10,
                "clueRelationKey": "",
                "companyName": item.businessName,
                "country": item.countryCn,
                "legalPerson": "",
                "contactNumber": "",
                "registrationTime": "",
                "employeesNumber": item.employeesTotal,
                "officialWebsite": item.website,
                "corporateEmail": item.email,
                "businessAddress": item.address,
                "companyProfile": "",
                "industrys": [],
            }
            this.isDisable = true;
            this.$axios.post(`${this.$clueUrl}/MyClue/AddMyClue`, submitObj).then((res) => {
                const { isSuccess } = res;
                this.isDisable = false;
                if (isSuccess) {
                    this.$message({ message: '添加成功', type: 'success'});
                    item.isMyClue = true;
                }
            });
        },

        //获取已购买公司列表
        viewPurchase(){
            this.pageState = "查看购买公司";
            this.purchasePage = 1;
            this.getInfobelPurchase();
        },
        getInfobelPurchase(){
            this.tableLoading = true;
            this.$axios.post(`${this.$clueUrl}/BusinessData/GetInfobelPurchase`, {
                businessName: this.businessName,
                pageIndex: this.purchasePage,
                pageSize: 10,
            }).then((res) => {
                const { isSuccess, data } = res;
                this.tableLoading = false;
                if (isSuccess) {
                    this.purchaseList = data.datas;
                    this.purchaseTotal = data.totalItems;
                }
            });
        },

    },
    mounted() {

    },
};
</script>

<style lang="scss" scoped>


/deep/ .el-form--label-top .el-form-item__label {
    padding: 0 !important;
}
/deep/ .el-form-item--small .el-form-item__content {
    height: 32px;
    line-height: 32px;
}
/deep/ .el-form-item--small .el-form-item__label {
    height: 32px;
    line-height: 32px;
}
.bigViewbox{
    width: calc(100% - 200px);
    height: calc(100vh - 80px);
    box-sizing: border-box;
    overflow: auto;
    background: #FFFFFF;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    position: relative;
}

.termViewbox{
    padding: 20px;
    .headerOne{
        display: flex;
        justify-content: space-between;
        padding-left: 40px;
        height: 40px;
        line-height: 40px;
        .theme{
            font-size: 26px;
            font-weight: bold;
            color: var(--themeColor);
        }
        .residue{
            font-size: 16px;
            font-weight: bold;
            color: #3A3A3A;
            span{
                font-size: 20px;
                color: var(--themeColor);
            }
        }
    }
    .headerTwo{
        display: flex;
        justify-content: space-between;
        padding-left: 40px;
        margin-top: 5px;
        margin-bottom: 10px;
        height: 24px;
        line-height: 24px;
        font-size: 16px;
        .describe{
            color: #A6A6A6;
        }
        .lookover{
            cursor: pointer;
            color: var(--themeColor);
        }
    }
}
.bottomBg{
    position: sticky;
    bottom: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100px;
    line-height: 100px;
    text-align: center;
    background-image: url("../../assets/cluePicture/bottomBg.png");
    background-position: center;
    background-size: 100% 100%;
    .sp1{
        display: inline-block;
        font-size: 18px;
        color: #9C9C9C;
    }
    .sp2{
        display: inline-block;
        font-size: 24px;
        font-weight: bold;
        color: var(--themeColor);
    }
}

.resultViewbox{
    padding: 20px;
    .resultContent{
        margin-top: 10px;
        overflow: hidden;
    }
    .clueInfo{
        display: flex;
        .contactsNum{
            margin-top: 50px;
            margin-right: 20px;
            width: 80px;
            height: 56px;
            background: #6986D8;
            border-radius: 8px 8px 8px 8px;
            color: #FFFFFF;
            line-height: 28px;
            text-align: center;
        }
        .companyName{
            display: inline-block;
            color: var(--themeColor);
            text-decoration: underline;
            cursor: pointer;
        }
        .country{
            display: inline-block;
            margin-left: 20px;
            img{
                vertical-align: middle;
            }
        }
        .field{
            line-height: 28px;
            margin-right: 30px;
        }
        .color99{
            color: #999999;
        }
        .color3a{
            color: #3A3A3A;
        }
        .primaryColor{
            color: var(--themeColor);
            cursor: pointer;
        }
    }
}

.searchResult{
    margin-top: 10px;
    .title{
        height: 40px;
        line-height: 40px;
        background: #F1F1F1;
        color: #3A3A3A;
        padding-left: 20px;
        display: flex;
    }
    .resultList{
        padding: 20px;
        overflow: hidden;
        .resultItem{
            font-size: 14px;
            padding: 10px 0;
            border-bottom: 1px solid #E8E8E8;
            position: relative;
            .name{
                display: inline-block;
                color: var(--themeColor);
                text-decoration: underline;
                cursor: pointer;
            }
            .country{
                display: inline-block;
                margin-left: 20px;
                img{
                    vertical-align: middle;
                }
            }
            .tagModule{
                display: flex;
                .tag1{
                    height: 20px;
                    line-height: 20px;
                    padding: 0 5px;
                    background: rgba(255, 170, 72, 0.2);
                    border-radius: 1px;
                    font-size: 12px;
                    color: #FFAA48;
                    margin-right: 10px;
                }
                .tag2{
                    height: 20px;
                    line-height: 20px;
                    padding: 0 5px;
                    background: rgba(255, 94, 126, 0.2);
                    border-radius: 1px;
                    font-size: 12px;
                    color: #FF5E7E;
                    margin-right: 10px;
                }
            }
            .color99{
                color: #999999;
            }
            .color3a{
                color: #3A3A3A;
            }
            .primaryColor{
                color: var(--themeColor);
                cursor: pointer;
            }
            .oneField{
                width: 200px;
                text-align: left;
            }
            .twoField{
                line-height: 28px;
            }
        }
    }
}
.addBtn{
    position: absolute;
    top: 10px;
    right: 0;
    padding: 0 10px;
    height: 28px;
    line-height: 28px;
    font-size: 14px;
    color: var(--themeColor);
    background: #eaeef7;
    border: 1px solid #adbadf;
    border-radius: 4px;
    cursor: pointer;
    &:hover{
        background: var(--themeColor);
        color: #FFFFFF;
    }
    img{
        position: absolute;
        left: 10px;
        top: 5px;
    }
}
.forbidden{
    cursor: not-allowed;
}
.hasAdd{
    position: absolute;
    top: 10px;
    right: 0;
    padding: 0 10px;
    height: 28px;
    line-height: 28px;
    font-size: 14px;
    color: #B7B7B7;
    background: rgba(183, 183, 183, 0.2);
    border: 1px solid #B7B7B7;
    border-radius: 4px;
}
</style>
