<template>
    <div class="FreightInfo">
        <div class="title">
            <span>货运信息</span>
            <el-button class="newBtn" @click="toNew" type="primary" icon="el-icon-plus" size="mini">新建货运</el-button>
        </div>
        <el-table :data="tableInfo" :header-cell-style="{ 'background-color':'#F3F3F3','color':'#3A3A3A' }" :row-style="({ rowIndex }) => (rowIndex % 2 == 1 ? { 'background-color': '#F9FAFC' } : {})">
            <el-table-column prop="waybillNumber" label="运单编号"></el-table-column>
            <el-table-column prop="waybillDate" label="运单日期"></el-table-column>
            <el-table-column prop="freightState" label="货运状态"></el-table-column>
            <el-table-column prop="ourCompany" label="我方公司"></el-table-column>
            <el-table-column prop="departurePort" label="出运口岸"></el-table-column>
            <el-table-column prop="destinationPort" label="目的口岸"></el-table-column>
            <el-table-column prop="tradeCountry" label="贸易国别"></el-table-column>
            <el-table-column prop="settlementMethod" label="结汇方式"></el-table-column>
            <el-table-column prop="transportationMode" label="运输方式"></el-table-column>
            <el-table-column prop="follower" label="跟进人"></el-table-column>
            <el-table-column prop="estimatedArrivalTime" label="预计到港时间"></el-table-column>
            <el-table-column prop="containerType" label="货柜类型"></el-table-column>
            <el-table-column prop="containerNumber" label="集装箱号"></el-table-column>
            <el-table-column prop="billOfLading" label="提单号"></el-table-column>
            <el-table-column label="操作" width="100">
                <template slot-scope="scope">
                    <el-link :underline="false" type="primary" @click="toEdit(scope.row)">编辑</el-link>
                    <span style="padding:0 5px;"></span>
                    <el-link :underline="false" type="danger" @click="toDel(scope.row)">删除</el-link>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="600px" top="50px">
            <el-form label-width="100px" size="small">
                <el-form-item label="运单编号">
                    <el-input v-model="saveForm.waybillNumber"></el-input>
                </el-form-item>
                <el-form-item label="运单日期">
                    <el-date-picker v-model="saveForm.waybillDate" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
                </el-form-item>
                <el-form-item label="货运状态">
                    <el-input v-model="saveForm.freightState"></el-input>
                </el-form-item>
                <el-form-item label="我方公司">
                    <el-input v-model="saveForm.ourCompany"></el-input>
                </el-form-item>
                <el-form-item label="出运口岸">
                    <el-input v-model="saveForm.departurePort"></el-input>
                </el-form-item>
                <el-form-item label="目的口岸">
                    <el-input v-model="saveForm.destinationPort"></el-input>
                </el-form-item>
                <el-form-item label="贸易国别">
                    <el-input v-model="saveForm.tradeCountry"></el-input>
                </el-form-item>
                <el-form-item label="结汇方式">
                    <el-input v-model="saveForm.settlementMethod"></el-input>
                </el-form-item>
                <el-form-item label="运输方式">
                    <el-input v-model="saveForm.transportationMode"></el-input>
                </el-form-item>
                <el-form-item label="跟进人">
                    <el-input v-model="saveForm.follower"></el-input>
                </el-form-item>
                <el-form-item label="预计到港时间">
                    <el-date-picker v-model="saveForm.estimatedArrivalTime" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
                </el-form-item>
                <el-form-item label="货柜类型">
                    <el-input v-model="saveForm.containerType"></el-input>
                </el-form-item>
                <el-form-item label="集装箱号">
                    <el-input v-model="saveForm.containerNumber"></el-input>
                </el-form-item>
                <el-form-item label="提单号">
                    <el-input v-model="saveForm.billOfLading"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="dialogVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="onSave" :loading="btnLoading">保 存</el-button>
            </span>
        </el-dialog>

        
    </div>
</template>

<script>
export default {
    props:["basicInfo"],
    data() {
        return {
            tableInfo: [],
            btnLoading: false,
            dialogTitle: "新建货运",
            dialogVisible: false,
            saveForm: {},
        };
    },
    methods: {
        //获取货运信息列表
        getFreightInfo(){
            this.$axios.get(`${this.$orderUrl}/api/Order/GetFreights?orderKey=${this.basicInfo.orderKey}`).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    this.tableInfo = data;
                }
            });
        },

        toNew() {
            this.dialogTitle = "新建货运";
            this.dialogVisible = true;
            this.saveForm = {
                key: "",
                orderKey: this.basicInfo.orderKey,  //关联订单
                waybillNumber: "",
                waybillDate: "",
                freightState: "",
                ourCompany: "",
                departurePort: "",
                destinationPort: "",
                tradeCountry: "",
                settlementMethod: "",
                transportationMode: "",
                follower: "",
                estimatedArrivalTime: "",
                containerType: "",
                containerNumber: "",
                billOfLading: "",
            }
        },
        toEdit(row){
            this.dialogTitle = "编辑货运";
            this.dialogVisible = true;
            this.saveForm = row;
        },
        onSave(){
            this.btnLoading = true;
            this.$axios.post(`${this.$orderUrl}/api/Order/SaveFreights`,this.saveForm).then((res) => {
                const { isSuccess } = res;
                this.btnLoading = false;
                this.dialogVisible = false;
                if (isSuccess) {
                    this.getFreightInfo();
                    this.$message({ message: '保存成功', type: 'success'});
                }
            });
        },
        toDel(row){
            this.$confirm('是否删除该货运信息?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios.get(`${this.$orderUrl}/api/Order/DeleteFreight?key=${row.key}`).then((res) => {
                    const { isSuccess } = res;
                    if (isSuccess) {
                        this.$message({ message: '删除成功', type: 'success'});
                        this.getFreightInfo();
                    }
                });
            }).catch(() => {});
        },
    },
    mounted() {
        this.orderKey = this.$route.query.orderKey;
    },
    watch:{
        basicInfo(){
            this.getFreightInfo(); //获取货运信息列表
        }
    }
};
</script>

<style lang="scss" scoped>
.FreightInfo{
    margin-top: 20px;
    .title{
        height: 20px;
        line-height: 20px;
        font-size: 16px;
        font-weight: bold;
        color: #3A3A3A;
        margin-bottom: 10px;
        border-left: 3px solid var(--themeColor);
        padding-left: 5px;
        .newBtn{
            float: right;
        }
    }
}
</style>