<template>
    <div>
        <div class="topHeader" v-loading="infoLoading">
            <div class="leftBox">
                <div class="clueInfo">
                    <div style="margin-bottom: 8px; height: 32px; line-height: 32px; display: flex; align-items: center;">
                        <el-tooltip effect="dark" :content="basisInfo.companyName" placement="top-start">
                            <div class="companyName">{{basisInfo.companyName}}</div>
                        </el-tooltip>
                        <CopyIcon :copyValue="basisInfo.companyName" />
                        <div class="country">
                            <img :src="basisInfo.flagUrl" style="margin-right:5px;height:14px" />
                            <span>{{basisInfo.country}}</span>
                        </div>
                        <el-tooltip effect="dark" content="刷新" placement="top">
                            <i class="el-icon-refresh" @click="getTopInfo(true)"></i>
                        </el-tooltip>
                    </div>
                    <div>
                        <el-tag size="small" type="success">来源：{{ basisInfo.sourceStr }}</el-tag>
                        <el-tag v-if="basisInfo.isMyCustomer" size="small" type="success">我的客户</el-tag>
                        <el-tag size="small" class="pointer" v-if="isgrabing" @click="lookVisible = true;">已挖掘联系人</el-tag>
                    </div>

                    <div style="height: 12px;"></div>
                    <el-descriptions :column="2" border :labelStyle="{width:'15%'}" :contentStyle="{width:'35%'}">
                        <el-descriptions-item label="公司名称">{{basisInfo.companyName}}</el-descriptions-item>
                        <el-descriptions-item label="国家">
                            <img :src="basisInfo.flagUrl" style="margin-right:5px;height:14px" />
                            <span>{{basisInfo.country}}</span>
                        </el-descriptions-item>
                        <el-descriptions-item label="详细地址" :span="2">{{basisInfo.businessAddress || "--"}}</el-descriptions-item>
                        <!-- <el-descriptions-item label="经度">{{basisInfo.longitude || "--"}}</el-descriptions-item> -->
                        <!-- <el-descriptions-item label="纬度">{{basisInfo.latitude || "--"}}</el-descriptions-item> -->
                        <!-- <el-descriptions-item label="地理精度" :span="2">{{basisInfo.geographicLongitude || "--"}}</el-descriptions-item> -->
                        <el-descriptions-item label="洲">{{basisInfo.continent || "--"}}</el-descriptions-item>
                        <el-descriptions-item label="城市">{{basisInfo.city || "--"}}</el-descriptions-item>
                        <el-descriptions-item label="公司类型">{{basisInfo.companyType || "--"}}</el-descriptions-item>
                        <el-descriptions-item label="状态">{{basisInfo.companyStatus || "--"}}</el-descriptions-item>
                        <!-- <el-descriptions-item label="法人">{{basisInfo.legalPerson || "--"}}</el-descriptions-item> -->
                        <!-- <el-descriptions-item label="家庭成员数量">{{basisInfo.familyNumber || "--"}}</el-descriptions-item> -->
                        <el-descriptions-item label="注册时间">{{basisInfo.registrationTime || "--"}}</el-descriptions-item>
                        <el-descriptions-item label="员工人数">{{basisInfo.employeesNumber || "--"}}</el-descriptions-item>
                        <el-descriptions-item label="邮政编码">{{basisInfo.postCode || "--"}}</el-descriptions-item>
                        <el-descriptions-item label="传真">{{basisInfo.faxNumber || "--"}}</el-descriptions-item>
                        <el-descriptions-item label="电话" :span="2">{{basisInfo.contactNumber || "--"}}</el-descriptions-item>
                        <el-descriptions-item label="网址" :span="2">
                            <div v-for="(ele,index) in basisInfo.officialWebsites" :key="index" style="display: inline-block;">
                                <span v-if="index != 0" style="padding-right:5px;">;</span>
                                <span v-if="ele" class="primaryColor" @click="openWebsite(ele)">{{ele}}</span>
                            </div>
                        </el-descriptions-item>
                        <el-descriptions-item label="年收入(单位:千美元)">{{basisInfo.reverueAmount || "--" }}</el-descriptions-item>
                        <el-descriptions-item label="US SIS">{{basisInfo.ussicCoreCode || "--"}}</el-descriptions-item>
                        <el-descriptions-item label="国内主要国家">{{basisInfo.majorCountryIn || "--"}}</el-descriptions-item>
                        <el-descriptions-item label="国外主要国家或地区">{{basisInfo.majorCountryOut || "--"}}</el-descriptions-item>
                        <el-descriptions-item label="经营范围" :span="2">{{basisInfo.businessline || "--"}}</el-descriptions-item>
                        <el-descriptions-item label="历史沿革" :span="2">{{basisInfo.history || "--"}}</el-descriptions-item>
                        <el-descriptions-item label="主营业务说明" :span="2">{{basisInfo.tradeEn || "--"}}</el-descriptions-item>
                        <el-descriptions-item label="产品和服务" :span="2">{{basisInfo.productService || "--"}}</el-descriptions-item>
                        <el-descriptions-item label="Nuts" :span="2">{{basisInfo.nuts1 || "--"}} {{basisInfo.nuts2 || "--"}} {{basisInfo.nuts3 || "--"}}</el-descriptions-item>
                        <el-descriptions-item label="简介" :span="2">{{basisInfo.companyProfile || "--"}}</el-descriptions-item>
                        <!-- 新增4个社媒信息字段: FaceBook主页 Twitter主页 Instagram主页 Linkedin主页 -->
                        <el-descriptions-item label="FaceBook主页">
                            <span v-if="basisInfo.faceBook" class="primaryColor" @click="openWebsite(basisInfo.faceBook)">{{basisInfo.faceBook}}</span>
                            <span v-else>--</span>
                        </el-descriptions-item>
                        <el-descriptions-item label="Twitter主页">
                            <span v-if="basisInfo.twitter" class="primaryColor" @click="openWebsite(basisInfo.twitter)">{{basisInfo.twitter}}</span>
                            <span v-else>--</span>
                        </el-descriptions-item>
                        <el-descriptions-item label="Instagram主页">
                            <span v-if="basisInfo.instagram" class="primaryColor" @click="openWebsite(basisInfo.instagram)">{{basisInfo.instagram}}</span>
                            <span v-else>--</span>
                        </el-descriptions-item>
                        <el-descriptions-item label="Linkedin主页">
                            <span v-if="basisInfo.linkedin" class="primaryColor" @click="openWebsite(basisInfo.linkedin)">{{basisInfo.linkedin}}</span>
                            <span v-else>--</span>
                        </el-descriptions-item>
                    </el-descriptions>
                </div>
                <!-- <div class="clueState">
                    0 待处理 1 完善信息 2 初步触达 3 联系互动 4 转化客户 5 无效
                    <div class="stateItem" :class="{'active': basisInfo.state==0}" @click="changeState(0)">待处理</div>
                    <div class="stateItem" :class="{'active': basisInfo.state==1}" @click="changeState(1)">完善信息</div>
                    <div class="stateItem" :class="{'active': basisInfo.state==2}" @click="changeState(2)">初步触达</div>
                    <div class="stateItem" :class="{'active': basisInfo.state==3}" @click="changeState(3)">联系互动</div>
                    <div class="stateItem" :class="{'active': basisInfo.state==4}" @click="changeState(4)">转化客户</div>
                    <div class="stateItem invalid">无效</div>
                </div> -->
                <div class="rightOperate">
                    <!-- 查看合并公司 -->
                    <el-popover placement="bottom" width="400" trigger="click">
                        <el-table :data="mergeList" size="mini">
                            <el-table-column property="companyName" label="公司"></el-table-column>
                            <el-table-column property="country" label="国家"></el-table-column>
                            <el-table-column label="操作" width="60">
                                <template slot-scope="scope">
                                    <el-button @click="toDetails(scope.row)" type="text" size="small">查看</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-button slot="reference" size="small" v-show="mergeList.length > 0" style="margin-right:10px;">合并公司</el-button>
                    </el-popover>

                    <el-button v-if="!isgrabing" type="primary" plain size="small" @click="miningContacts">挖掘联系人</el-button>

                    <el-button type="primary" plain size="small" @click="toComplete">补全公司信息</el-button>

                    <el-button type="primary" v-show="!basisInfo.isMyCustomer" plain size="small" @click="joinMyCustomer(basisInfo)">加入我的客户</el-button>

                    
                    <el-dropdown>
                        <el-button type="info" plain size="small" style="margin-left:10px;">
                            更多操作<i class="el-icon-arrow-down el-icon--right"></i>
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item @click.native="toUpdateClue(basisInfo)">编辑我的线索</el-dropdown-item>
                            <el-dropdown-item :disabled="basisInfo.contactsCount == 0" @click.native="toMassOne">邮件营销</el-dropdown-item>
                            <el-dropdown-item :disabled="basisInfo.contactsCount == 0" @click.native="toMassTwo">WhatsApp群发</el-dropdown-item>
                            <el-dropdown-item @click.native="removeClue">移出我的线索</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
            <div class="rightBox">
                <el-row :gutter="12">
                    <el-col :span="8" class="itemOne">
                        <div>
                            <span class="count">{{basisInfo.notContactedDay}}</span>
                            <span class="unit">天</span>
                        </div>
                        <div class="txt">未联系</div>
                    </el-col>
                    <el-col :span="8" class="itemOne">
                        <div>
                            <span class="count">{{basisInfo.joinClueCount}}</span>
                            <span class="unit">次</span>
                        </div>
                        <div class="txt">进入线索</div>
                    </el-col>
                    <el-col :span="8" class="itemOne">
                        <div>
                            <span class="count">{{basisInfo.massSendingCount}}</span>
                            <span class="unit">次</span>
                        </div>
                        <div class="txt">群发次数</div>
                    </el-col>
                </el-row>
                <div class="itemTwo">
                    <div class="label">来源:</div>
                    <div class="value">
                        {{basisInfo.sourceStr}}
                    </div>
                </div>
                <div class="itemTwo">
                    <div class="label">线索状态:</div>
                    <el-select v-model="basisInfo.state" @change="changeState" size="small" style="margin-left:10px;width:120px;">
                        <el-option label="待处理" :value="0"></el-option>
                        <el-option label="完善信息" :value="1"></el-option>
                        <el-option label="初步触达" :value="2"></el-option>
                        <el-option label="联系互动" :value="3"></el-option>
                        <el-option label="转化客户" :value="4"></el-option>
                        <el-option label="无效" :value="5"></el-option>
                    </el-select>
                    <!-- <div class="value" v-if="basisInfo.state==0">待处理</div>
                    <div class="value" v-else-if="basisInfo.state==1">完善信息</div>
                    <div class="value" v-else-if="basisInfo.state==2">初步触达</div>
                    <div class="value" v-else-if="basisInfo.state==3">联系互动</div>
                    <div class="value" v-else-if="basisInfo.state==4">转化客户</div>
                    <div class="value" v-else-if="basisInfo.state==5">无效</div> -->
                </div>
                <div class="itemTwo">
                    <div class="label">跟进人:</div>
                    <div class="value">{{basisInfo.createUserName}}</div>
                </div>
                <div class="itemTwo">
                    <div class="label">加入时间:</div>
                    <div class="value">{{basisInfo.createTime}}</div>
                </div>
            </div>
        </div>

        <!-- 地图信息 -->
        <Mapbox :coordinate="coordinate" v-if="isShowMap" style="margin-top:20px;" />

        <!-- 图片信息 -->
        <div class="productImg">
            <el-image v-for="ele in imgUrlList" :key="ele" :src="ele" :preview-src-list="imgUrlList"></el-image>
        </div>

        <div class="tabCard">
            <el-tabs>
                <el-tab-pane label="线索信息">
                    <ClueInformation :clueSource="basisInfo.clueSource" :clueRelationKey="basisInfo.clueRelationKey" />
                </el-tab-pane>
                <el-tab-pane>
                    <div slot="label">
                        <span>发信记录</span>
                        <span>({{basisInfo.massSendingCount || "无"}})</span>
                    </div>
                    <MailRecord />
                </el-tab-pane>
                <el-tab-pane>
                    <div slot="label">
                        <span>联系人</span>
                        <span>({{basisInfo.contactsCount || "无"}})</span>
                    </div>
                    <LinkmanList />
                </el-tab-pane>
                <el-tab-pane label="社媒信息">
                    <SocialMediaInfo />
                </el-tab-pane>
                <!-- 获取高管/获取注册号/获取股权信息/获取行业分类 -->
                <el-tab-pane>
                    <div slot="label">
                        <span>高管</span>
                        <span>({{oneInfoList.length || "无"}})</span>
                    </div>
                    <el-table :data="oneInfoList">
                        <el-table-column type="index" label="序号" width="50px" align="center"></el-table-column>
                        <el-table-column label="姓名">
                            <template slot-scope="scope">
                                <div>{{scope.row.fullname}}</div>  
                            </template>
                        </el-table-column>
                        <el-table-column label="职位">
                            <template slot-scope="scope">
                                <div>{{scope.row.originalpost2}}</div>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-tab-pane>
                <el-tab-pane>
                    <div slot="label">
                        <span>注册号</span>
                        <span>({{twoInfoList.length || "无"}})</span>
                    </div>
                    <el-table :data="twoInfoList">
                        <el-table-column type="index" label="序号" width="50px" align="center"></el-table-column>
                        <el-table-column label="natId">
                            <template slot-scope="scope">
                                <div>{{scope.row.natId}}</div>  
                            </template>
                        </el-table-column>
                        <el-table-column label="natLabel">
                            <template slot-scope="scope">
                                <div>{{scope.row.natLabel}}</div>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-tab-pane>
                <el-tab-pane>
                    <div slot="label">
                        <span>股权信息</span>
                        <span>({{threeInfoList.length || "无"}})</span>
                    </div>
                    <el-table :data="threeInfoList">
                        <el-table-column type="index" label="序号" width="50px" align="center"></el-table-column>
                        <el-table-column label="公司股东">
                            <template slot-scope="scope">
                                <div>{{scope.row.shareHoldersName}}</div>  
                            </template>
                        </el-table-column>
                        <el-table-column label="占股比例">
                            <template slot-scope="scope">
                                <div>{{scope.row.shareHoldersDirect + "%"}}</div>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-tab-pane>
                <el-tab-pane>
                    <div slot="label">
                        <span>行业分类</span>
                        <span>({{fourInfoList.length || "无"}})</span>
                    </div>
                    <el-table :data="fourInfoList">
                        <el-table-column type="index" label="序号" width="50px" align="center"></el-table-column>
                        <el-table-column label="行业类型">
                            <template slot-scope="scope">
                                <div>{{scope.row.type}}</div>
                            </template>
                        </el-table-column>
                        <el-table-column label="行业代码">
                            <template slot-scope="scope">
                                <div>{{scope.row.Code}}</div>  
                            </template>
                        </el-table-column>
                        <el-table-column label="行业描述">
                            <template slot-scope="scope">
                                <div>{{scope.row.Description}}</div>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-tab-pane>
               
            </el-tabs>
        </div>

        <!-- 加入我的客户 -->
        <JoinCustomer :joinDialogVisible="joinDialogVisible" :clickItem="clickItem" @closeDialog="joinDialogVisible = false" @onFinish="onFinish" />

        <el-dialog title="编辑线索" :visible.sync="editClueVisible" width="1000px" top="50px">
            <div style="height:calc(100vh - 240px);overflow-x: hidden;overflow-y: auto;">
                <el-form :model="editClueForm" label-width="100px" size="small">
                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-form-item label="公司名称" required>
                                <el-input v-model="editClueForm.companyName" disabled></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="国家" required>
                                <el-input v-model="editClueForm.country"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="详细地址">
                                <div v-for="(ele,index) in editClueForm.businessAddresss" :key="index" class="flexBox" style="width: 100%;">
                                    <el-input v-model="editClueForm.businessAddresss[index]"></el-input>
                                    <i class="el-icon-circle-plus-outline" v-if="index == 0" @click="addItem('businessAddresss')"></i>
                                    <i class="el-icon-remove-outline" v-else @click="delItem('businessAddresss',index)"></i>
                                </div>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="洲">
                                <el-input v-model="editClueForm.continent"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="城市">
                                <el-input v-model="editClueForm.city"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="公司类型">
                                <el-input v-model="editClueForm.companyType"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="状态">
                                <el-input v-model="editClueForm.companyStatus"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="注册时间">
                                <el-date-picker style="width:100%" v-model="editClueForm.registrationTime" type="date" value-format="yyyy-MM-dd" placeholder="注册时间"></el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="员工人数">
                                <el-input-number style="width:100%" v-model="editClueForm.employeesNumber" :min="0" :controls="false"></el-input-number>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="邮政编码">
                                <el-input v-model="editClueForm.postCode"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="传真">
                                <el-input v-model="editClueForm.faxNumber"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="电话">
                                <div v-for="(ele,index) in editClueForm.contactNumbers" :key="index" class="flexBox">
                                    <el-input v-model="editClueForm.contactNumbers[index]"></el-input>
                                    <i class="el-icon-circle-plus-outline" v-if="index == 0" @click="addItem('contactNumbers')"></i>
                                    <i class="el-icon-remove-outline" v-else @click="delItem('contactNumbers',index)"></i>
                                </div>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="网址">
                                <el-row :gutter="6">
                                    <div v-for="(ele,index) in editClueForm.officialWebsites" :key="index" class="flexBox">
                                        <el-input v-model="editClueForm.officialWebsites[index]"></el-input>
                                        <i class="el-icon-circle-plus-outline" v-if="index == 0" @click="addItem('officialWebsites')"></i>
                                        <i class="el-icon-remove-outline" v-else @click="delItem('officialWebsites',index)"></i>
                                    </div>
                                </el-row>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="年收入">
                                <div v-for="(ele,index) in editClueForm.reverueAmounts" :key="index" class="flexBox">
                                    <el-input v-model="editClueForm.reverueAmounts[index]"></el-input>
                                    <i class="el-icon-circle-plus-outline" v-if="index == 0" @click="addItem('reverueAmounts')"></i>
                                    <i class="el-icon-remove-outline" v-else @click="delItem('reverueAmounts',index)"></i>
                                </div>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="国内主要国家">
                                <el-input v-model="editClueForm.majorCountryIn"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="国外主要国家或地区">
                                <el-input v-model="editClueForm.majorCountryOut"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="经营范围">
                                <el-input type="textarea" v-model="editClueForm.businessline"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="历史沿革">
                                <el-input type="textarea" v-model="editClueForm.history"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="主营业务说明">
                                <el-input type="textarea" v-model="editClueForm.tradeEn"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="产品和服务">
                                <el-input type="textarea" v-model="editClueForm.productService"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="nuts1">
                                <el-input v-model="editClueForm.nuts1"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="nuts2">
                                <el-input v-model="editClueForm.nuts2"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="nuts3">
                                <el-input v-model="editClueForm.nuts3"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="简介">
                                <el-input type="textarea" v-model="editClueForm.companyProfile"></el-input>
                            </el-form-item>
                        </el-col>
                        <!-- 新增4个社媒信息字段: FaceBook主页 Twitter主页 Instagram主页 Linkedin主页 -->
                        <el-col :span="12">
                            <el-form-item label="FaceBook">
                                <el-input v-model="editClueForm.faceBook"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="Twitter">
                                <el-input v-model="editClueForm.twitter"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="Instagram">
                                <el-input v-model="editClueForm.instagram"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="Linkedin">
                                <el-input v-model="editClueForm.linkedin"></el-input>
                            </el-form-item>
                        </el-col>

                        <!-- <el-col :span="24">
                            <el-form-item label="行业">
                                <el-select v-model="editClueForm.industrys" multiple filterable allow-create default-first-option placeholder="输入行业格式:(行业代码)行业描述;回车确认;" style="width:100%;">
                                </el-select>
                            </el-form-item>
                        </el-col> -->
                    </el-row>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="editClueVisible = false;">取 消</el-button>
                <el-button size="small" type="primary" @click="updateSubmit" :loading="btnLoading">确 定</el-button>
            </span>
        </el-dialog>

        <!-- 挖掘联系人详情 -->
        <el-dialog :title="basisInfo.companyName" :visible.sync="lookVisible" width="500px">
            <div class="grabBox">
                <div class="grabItem">
                    <span class="label">网址:</span>
                    <span v-if="miningWebSize">{{miningWebSize}}</span>
                    <span v-else>暂无数据</span>
                </div>
                <div class="grabItem">
                    <span class="label">邮箱:</span>
                    <span v-if="emailTask.key" class="value" @click="lookGrabDetails(1)">
                        已挖掘({{emailTask.count}}个)
                    </span>
                    <span v-else>暂无数据</span>
                </div>
                <div class="grabItem">
                    <span class="label">手机号:</span>
                    <span v-if="googleSearchTask.key" class="value" @click="lookGrabDetails(2)">
                        已挖掘({{googleSearchTask.waCount}}个)
                    </span>
                    <span v-else>暂无数据</span>
                </div>
            </div>
        </el-dialog>

        <!-- 补全公司信息 -->
        <CompleteInfo :completeVisible="completeVisible" :searchCompany="searchCompany" @closeDialog="completeVisible = false" @onComplete="onComplete" />

    </div>
</template>

<script>
import ClueInformation from "./components/ClueInformation.vue"
import MailRecord from "./components/MailRecord.vue"
import LinkmanList from "./components/LinkmanList.vue"
import SocialMediaInfo from "./components/SocialMediaInfo.vue"
import JoinCustomer from "./components/JoinCustomer.vue"
import Mapbox from "./components/Mapbox.vue"
import CompleteInfo from "./components/CompleteInfo.vue"
import IndustrySic from "@/assets/json/IndustrySic.json"
export default {
    components: { ClueInformation, MailRecord, LinkmanList, SocialMediaInfo, JoinCustomer, Mapbox, CompleteInfo },
    props: {
        handleSelect: {
            type: Function,
            default: null,
        },
    },
    data() {
        return {
            myClueKey: "",
            infoLoading: false,
            basisInfo: {},
            //公司图片信息
            imgUrlList: [],

            //加入我的客户
            joinDialogVisible: false,
            clickItem: {},

            //编辑线索
            editClueVisible: false,
            editClueForm: {},
            btnLoading: false,

            //地图坐标
            coordinate: [],
            isShowMap: false,
            
            //合并公司
            mergeList: [],

            //挖掘联系人
            isgrabing: false,
            miningWebSize: "",
            emailTask: {},
            googleSearchTask: {},
            lookVisible: false,
            //补全公司信息
            completeVisible: false,
            searchCompany: "",

            //获取高管/获取注册号/获取股权信息/获取行业分类
            oneInfoList: [],
            twoInfoList: [],
            threeInfoList: [],
            fourInfoList: [],

        };
    },
    methods: {
        //获取头部信息
        getTopInfo(isGet){
            this.infoLoading = true;
            this.$axios.get(`${this.$clueUrl}/MyClue/GetMyClueDetail?myClueKey=${this.myClueKey}`).then((res) => {
                const { isSuccess, data } = res;
                this.infoLoading = false;
                if (isSuccess) {
                    data.flagUrl = window.getFlagUrlByCountry?window.getFlagUrlByCountry(data.country):'';
                    this.basisInfo = data;
                    document.title = `我的线索详情[${this.basisInfo.companyName}]`
                    // 根据经度，纬度展示地图信息
                    if(Number(data.longitude) && Number(data.latitude)){
                        this.coordinate = [Number(data.longitude),Number(data.latitude)];
                        this.isShowMap = true;
                    }
                    //获取合并公司
                    this.mergeList = data.merge;
                    //挖掘联系人
                    const {miningWebSize,emailTask,googleSearchTask} = data;    
                    // console.log(miningWebSize);
                    // console.log(emailTask);
                    // console.log(googleSearchTask);
                    this.isgrabing = miningWebSize || emailTask.length > 0 || googleSearchTask.length > 0;
                    this.miningWebSize = miningWebSize;
                    this.emailTask = emailTask.length > 0 ? emailTask[0] : {};
                    this.googleSearchTask = googleSearchTask.length > 0 ? googleSearchTask[0] : {};
                    //(US SIS)匹配描述
                    if(this.basisInfo.ussicCoreCode){
                        const aItem = IndustrySic.find(item => item.Code == this.basisInfo.ussicCoreCode);
                        if(aItem){
                            this.basisInfo.ussicCoreCode = `${aItem.Code} - ${aItem.Description}`;
                            this.basisInfo.mainCode = aItem.Code;
                        }else{
                            this.basisInfo.mainCode = this.basisInfo.ussicCoreCode.split(' - ')[0];
                        }
                    }
                    //获取高管/获取注册号/获取股权信息/获取行业分类
                    if(isGet && data.pid){
                        this.getInfoList('/MyClue/GetSeniors',1,data.pid);
                        this.getInfoList('/MyClue/GetNationalIdentifiers',2,data.pid);
                        this.getInfoList('/MyClue/v2/GetShareholders',3,data.pid);
                        this.getInfoList('/MyClue/GetIndustryCodes',4,data.pid);
                    }
                }
            });
        },
        getInfoList(requestUrl,type,pid){
            this.$axios.get(`${this.$clueUrl}${requestUrl}?pid=${pid}`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    if(type==1){this.oneInfoList = data};
                    if(type==2){this.twoInfoList = data};
                    if(type==3){this.threeInfoList = data};
                    if(type==4){
                        if(this.basisInfo.mainCode){
                            data.unshift({
                                type: "核心行业",
                                code: this.basisInfo.mainCode,
                            })
                        }
                        this.fourInfoList = data.map(ele => {
                            const oneItem = IndustrySic.find(item => item.Code == ele.code);
                            return oneItem ? { ...oneItem, type: ele.type} : { type: ele.type, Code: ele.code, Description: "" };
                        });
                    };
                }
            });
        },

        //修改线索状态
        changeState(value){
            this.$axios.get(`${this.$clueUrl}/MyClue/UpdateClueState?myClueKey=${this.myClueKey}&state=${value}`).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    this.$message({ message: '修改成功', type: 'success'});
                    this.basisInfo.state = value;
                }
            });
        },

        //打开网址
        openWebsite(url){
            let jumpUrl = url.indexOf("http") > -1 ? url : "http://" + url;
            let routeData = this.$router.resolve({
                path: "/jumpWeb",
                query: { jumpUrl: jumpUrl },
            });
            window.open(routeData.href, "_blank");
        },

        //加入我的客户
        joinMyCustomer(item){
            this.joinDialogVisible = true;
            this.clickItem = item;
        },
        onFinish(){
            this.joinDialogVisible = false;
            this.$message({ message: '加入成功', type: 'success'});
            this.getTopInfo(false);
        },
        //移出我的线索
        removeClue(){
            this.$confirm('此操作将移出我的线索, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios.get(`${this.$clueUrl}/MyClue/RemoveMyClue?key=${this.myClueKey}`).then((res) => {
                    const { isSuccess } = res;
                    if (isSuccess) {
                        this.$message({ type: 'success', message: '移出成功!'});
                        this.handleSelect("/clue/myClue");
                        this.$router.push("/clue/myClue");
                    }
                });
            }).catch(() => {});
        },

        //获取公司图片
        getCompanyImg(){
            let companyName = this.$route.query.companyName;
            this.$axios.get(`${this.$clueUrl}/Recommendation/GetRECCompanyImg?companyName=${companyName}`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.imgUrlList = data.map(item => item.img);
                }
            });
        },

        //编辑我的线索
        toUpdateClue(row){
            this.editClueVisible = true;
            this.editClueForm = {
                "key": this.myClueKey,
                "companyName": row.companyName,
                "country": row.country,
                "legalPerson": row.legalPerson,
                "contactNumbers": row.contactNumbers.length >0 ? row.contactNumbers : [""],
                "registrationTime": row.registrationTime,
                "employeesNumber": row.employeesNumber,
                "officialWebsites": row.officialWebsites.length >0 ? row.officialWebsites : [""],
                "corporateEmail": row.corporateEmail,
                "businessAddresss": row.businessAddresss.length >0 ? row.businessAddresss : [""],
                "companyProfile": row.companyProfile,
                "clueSource": row.clueSource,
                "industrys": row.industry.split(','),
                "reverueAmounts": row.reverueAmounts.length >0 ? row.reverueAmounts : [""],
                "faxNumber": row.faxNumber,
                "companyStatus": row.companyStatus,
                "longitude": row.longitude,
                "latitude": row.latitude,
                "geographicLongitude": row.geographicLongitude,
                "companyType": row.companyType,
                "familyNumber": row.familyNumber,
                "businessline": row.businessline,
                "registrationNumber": row.registrationNumber,
                //补充字段
                "postCode": row.postCode,
                "city": row.city,
                "nuts1": row.nuts1,
                "nuts2": row.nuts2,
                "nuts3": row.nuts3,
                "continent": row.continent,
                "history": row.history,
                "majorCountryIn": row.majorCountryIn,
                "majorCountryOut": row.majorCountryOut,
                "tradeEn": row.tradeEn,
                "productService": row.productService,
                "ussicCoreCode": row.ussicCoreCode,
                //社媒信息字段
                "faceBook": row.faceBook,
                "twitter": row.twitter,
                "instagram": row.instagram,
                "linkedin": row.linkedin,
            }
        },
        addItem(type){
            this.editClueForm[type].push("");
            // console.log(this.editClueForm[type]);
        },
        delItem(type,index){
            this.editClueForm[type] = this.editClueForm[type].filter((ele,i) => i != index);
            // console.log(this.editClueForm[type]);
        },
        updateSubmit(){
            this.btnLoading = true;
            this.$axios.post(`${this.$clueUrl}/MyClue/UpdateMyClue`, this.editClueForm).then((res) => {
                const { isSuccess } = res;
                this.btnLoading = false;
                if (isSuccess) {
                    this.editClueVisible = false;
                    this.$message({ message: '编辑线索成功', type: 'success' });
                    this.getTopInfo(false);
                }
            });
        },

        

        //查看我的线索详情
        toDetails(item) {
            let routeData = this.$router.resolve({
                path: "/clue/myClueDetails",
                query: { myClueKey: item.myClueKey, companyName: item.companyName, country: item.country },
            });
            window.open(routeData.href, "_blank");
        },

        //邮件营销
        toMassOne(){
            localStorage.setItem('clueKeyList',this.myClueKey);
            let routeData = this.$router.resolve({
                // path: '/marketing/New',
                path: '/marketing/MailMarketing',
                query: { isClueMass: true },
            });
            window.open(routeData.href, "_blank");
        },
        //WhatsApp群发
        toMassTwo(){
            localStorage.setItem('clueKeyList',this.myClueKey);
            let routeData = this.$router.resolve({
                // path: '/marketingWA/NewWA',
                path: '/marketingWA/WAMass',
                query: { isClueMass: true },
            });
            window.open(routeData.href, "_blank");
        },

        //挖掘联系人功能
        miningContacts(){
            this.$axios.get(`${this.$clueUrl}/MyClue/MiningContacts?myClueKey=${this.myClueKey}`).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    this.$message({ type: 'success', message: '挖掘任务进行中!'});
                    this.getTopInfo(false);
                }
            });
        },
        lookGrabDetails(type){
            if(type == 1){
                let routeData = this.$router.resolve({
                    path: '/clue/searchMailbox',
                    query: { grabKey: this.emailTask.key },
                });
                window.open(routeData.href, "_blank");
            }
            if(type == 2){
                let routeData = this.$router.resolve({
                    path: '/clue/searchWhatsApp',
                    query: { grabKey: this.googleSearchTask.key },
                });
                window.open(routeData.href, "_blank");
            }
        },

        //补全公司信息功能
        toComplete(){
            this.completeVisible = true;
            this.searchCompany = this.basisInfo.companyName;
        },
        onComplete(condition){
            this.completeVisible = false;
            if(condition){
                this.$message({ message: '补全成功', type: 'success'});
                this.getTopInfo(true);
            }else{
                this.$message({ message: '补全失败', type: 'warning'});
            }
        },

    },
    mounted() {
        this.myClueKey = this.$route.query.myClueKey;
        //获取头部信息
        this.getTopInfo(true);
        //获取公司图片
        this.getCompanyImg();

    },
};
</script>

<style lang="scss" scoped>
.topHeader {
    display: flex;
    .leftBox {
        width: calc(100% - 460px);
        padding: 20px;
        box-sizing: border-box;
        background: #ffffff;
        box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
        position: relative;
        .clueInfo{
            font-size: 14px;           
            .companyName{
                display: inline-block;
                font-size: 18px;
                color: #3A3A3A;
                font-weight: bold;
                //超出最大值宽度隐藏
                max-width: 250px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .country{
                display: inline-block;
                margin-left: 10px;
                img{
                    vertical-align: middle;
                }
            }
            .field{
                line-height: 28px;
                margin-right: 30px;
            }
            .color99{
                color: #999999;
            }
            .color3a{
                color: #3A3A3A;
            }
            .primaryColor{
                color: var(--themeColor);
                cursor: pointer;
            }
        }
        .clueState{
            display: flex;
            .stateItem{
                width: 200px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                background: #A9CBFB;
                border-radius: 20px;
                color: #ffffff;
                margin-right: 10px;
                cursor: pointer;
            }
            .active{
                height: 38px;
                line-height: 38px;
                border: 1px solid var(--themeColor);
                background: #ffffff;
                color: var(--themeColor);
            }
            .invalid{            
                background: #A1A1A1;
            }
        }
        .rightOperate{
            position: absolute;
            right: 20px;
            top: 20px;
        }
    }
    .rightBox {
        margin-left: 20px;
        width: 440px;
        padding: 20px;
        box-sizing: border-box;
        background: #ffffff;
        box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
        .itemOne{
            text-align: center;
            font-size: 16px;
            .txt{
                color: #919191;
                line-height: 24px;
            }
            .count{
                font-size: 30px;
                color: var(--themeColor);
                font-weight: bold;
            }
            .unit{ 
                color: #3A3A3A;
            }
        }
        .itemTwo{
            display: flex;
            font-size: 16px;
            line-height: 32px;
            margin-top: 8px;
            .label{
                width: 80px;
                color: #B7B9BC;
                text-align: right;
            }
            .value{
                margin-left: 10px;
                color: #3A3A3A;
            }
        }
    }
}
.tabCard{
    margin-top: 20px;
    background: rgba(255, 255, 255, 0.7);
    padding: 0 20px 20px;
    min-height: 500px;
}
.productImg{
    width: 100%;
    .el-image{
        width: 120px;
        height: 120px;
        margin-top: 20px;
        margin-right: 20px;
    }
}

.flexBox{
    display: inline-flex;
    align-items: center;
    width: 33%;
}
.el-icon-circle-plus-outline,.el-icon-remove-outline{
    font-size: 20px;
    cursor: pointer;
}
.el-icon-refresh{
    font-size: 18px;
    cursor: pointer;
    margin-left: 10px;
    color: var(--themeColor);
}
/deep/.el-dialog__body{
    padding: 20px;
}
.grabItem{
    width: 100%;
    height: 40px;
    line-height: 40px;
    box-sizing: border-box;
    padding: 0 20px;
    background: #FFFFFF;
    box-shadow: 0px 0px 6px 1px rgba(0,0,0,0.16);
    border-radius: 4px;
    margin-top: 20px;
    color: #919191;
    &:first-child{
        margin-top: 0;
    }
    .label{
        font-weight: bold;
        color: #000;
        padding-right: 8px;
    }
    .value{
        color: var(--themeColor);
        cursor: pointer;
    }
}
</style>