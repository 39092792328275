<template>
    <div class="pageBox">
        <div class="header">
            <el-button class="uploadBtn" type="primary" @click="showBox = true;">上传文件</el-button>
            <UploadBox :showBox="showBox" @onClose="showBox = false;" @onComplete="onComplete" />

            <!-- <el-button>删 除</el-button> -->
            <el-input class="searchBox" placeholder="请输入搜索内容" v-model="searchValue" prefix-icon="el-icon-search" clearable>
                <el-button slot="append" @click="onSearch">文件搜索</el-button>
            </el-input>
        </div>
        <div class="contentBox">
            <div class="topHeader">
                <img v-if="BreadcrumbList.length > 1" src="@/assets/doc/goBack.png" alt="" class="goBack" @click="goBack" />
                <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb">
                    <el-breadcrumb-item v-for="(item,index) in BreadcrumbList" :key="item.key">
                        <span @click="goJump(index)" class="spanItem">{{item.name}}</span>
                    </el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="tableHeader">
                <div class="emailTitle">邮件标题</div>
                <div class="name">文件名称</div>
                <div class="size">大小</div>
                <div class="time">到期时间</div>
                <div class="moreOption">更多操作</div>
            </div>
            <div class="tableBody" v-loading="FolderLoading || FileLoading">
                <div v-if="FolderList.length == 0 && FileList.length == 0">
                    <el-empty description="暂无数据"></el-empty>
                </div>
                <!-- 文件夹列表 -->
                <div class="FolderItem" v-for="item in FolderList" :key="item.key">
                    <div class="name">
                        <img class="imgIcon" src="@/assets/doc/folderIcon.png" alt="" />
                        <span>{{item.folderName}}</span>
                    </div>
                    <div class="size"></div>
                    <div class="creator">{{item.userCnName}}</div>
                    <div class="time">{{item.timeExhibition}}</div>
                    <div class="moreOption">
                        
                    </div>
                </div>
                <!-- 文件列表 -->
                <div class="FileItem" v-for="item in FileList" :key="item.key">
                    <div class="emailTitle">{{item.emailTitle}}</div>
                    <div class="name">
                        <img class="imgIcon fileIcon" :src="item.fileIcon" alt="" />
                        <span class="pointer" @click="viewFileDetails(item)">{{item.fileName}}</span>
                    </div>
                    <div class="size">{{item.fileSize}}</div>
                    <div class="time">{{item.expirationTime}}</div>
                    <div class="moreOption">
                        <el-dropdown>
                            <span style="color:var(--themeColor);cursor:pointer;font-size:20px;" @click.stop="() =>{}"><i class="el-icon-more"></i></span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item @click.native="toTransfer(item)">转存到个人空间</el-dropdown-item>
                                <el-dropdown-item @click.native="viewFileDetails(item)">详情信息</el-dropdown-item>
                                <el-dropdown-item @click.native="fileJoinRecycle(item)">加入回收站</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </div>
            </div>
        </div>

        <el-dialog title="转存到个人空间" :visible.sync="transferVisible" width="500px">
            <!-- 文件夹集合(树形结构) -->
            <el-cascader v-model="belongValue" :options="folderTreeData" :props="{ value: 'key', label: 'name', checkStrictly: true }"
             clearable @change="belongChange" style="width:100%;"></el-cascader>
            <span slot="footer" class="dialog-footer">
                <el-button @click="transferVisible = false" size="small">取 消</el-button>
                <el-button type="primary" @click="transferFinish" size="small" :loading="btnLoading">确 定</el-button>
            </span>
        </el-dialog>

        <!-- 文件详情 -->
        <FileInfo :infoVisible="infoVisible" :infoKey="infoKey" @closedialog="infoVisible = false;" />

    </div>
</template>

<script>
import FileInfo from "./components/FileInfo.vue"
import UploadBox from "./components/UploadBox.vue" //上传文件组件
export default {
    components: { FileInfo, UploadBox },
    data() {
        return {
            btnLoading: false,
            searchValue: "",
            BreadcrumbList:[
                {name:'邮件云附件',key:"0"},
            ],
            //文件夹列表
            FolderList:[],
            FolderLoading: false,
            //文件列表
            FileList: [],
            FileLoading: false,

            //云附件上传
            progressVisible: false,
            progressNumber: 0,

            //转存到个人空间
            transferVisible: false,
            transferFileKey: "",
            //文件夹集合(树形结构)
            folderTreeData: [],
            belongValue: [],
            //查看文件详情
            infoVisible: false,
            infoKey: "",
            //上传文件组件
            showBox: false,
        };
    },
    methods: {
        //文件搜索
        onSearch(){
            console.log(this.searchValue);
            this.getCloudList();
        },

        //返回上一级文件夹
        goBack(){
            console.log("返回")
        },
        //跳转指定文件夹
        goJump(index){
            console.log(index);
        },

        //获取云附件列表
        getCloudList(){
            this.FileLoading = true;
            this.$axios.post(`${this.$diskUrl}/Clound/GetClouds`,{
                "pageIndex": 1,
                "pageSize": 20,
            }).then((res) => {
                this.FileLoading = false;
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.FileList = data.datas;
                    this.FileList.forEach(item =>{
                        item.fileIcon = require(`@/assets/doc/file_${item.fileCategory}.png`)
                    })
                }
            });
        },
        //查看文件详情 
        viewFileDetails(item){
            this.infoVisible = true;
            this.infoKey = item.key;
        },

        onComplete(resObj){
            this.$axios.post(`${this.$diskUrl}/Clound/SaveCloudAttachment`,{
                folderKey: "0",
                ...resObj,
            }).then((res) => {
                const { isSuccess } = res;
                this.showBox = false;
                if (isSuccess) {
                    this.$message({message: "上传成功",type: 'success'});
                    this.getCloudList();
                }
            })
        },


        //转存到个人空间
        toTransfer(item){
            this.transferVisible = true;
            this.transferFileKey = item.key;
            this.belongValue = [];//转移到的对象
            this.GetFolders();
        },
        transferFinish(){
            const {transferFileKey,belongValue} = this;
            if(belongValue.length == 0){
                this.$message({message: '请选择转移到那个文件夹!',type: 'warning'});
                return false;
            }
            let folderKey = this.belongValue[this.belongValue.length - 1];
            this.btnLoading = true;
            this.$axios.get(`${this.$diskUrl}/Clound/Redeposit?fileKey=${transferFileKey}&folderKey=${folderKey}`).then((res) => {
                const { isSuccess } = res;
                this.btnLoading = false;
                if (isSuccess) {
                    this.$message({ message: '转存成功', type: 'success' });
                    this.getCloudList();
                    this.transferVisible = false;
                }
            });
        },

        //云附件文件加入回收站
        fileJoinRecycle(item){
            this.$confirm('此操作将文件加入回收站, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios.get(`${this.$diskUrl}/RecycleBin/AddRecycleBin?filekey=${item.key}&type=2`).then((res) => {
                    const { isSuccess } = res;
                    if (isSuccess) {
                        this.getCloudList();
                        this.$message({ type: 'success', message: '操作成功!'});
                    }
                })
            }).catch(() => {});
        },
        //(我的空间)文件夹集合(树形结构)
        GetFolders(){
            this.$axios.get(`${this.$diskUrl}/Folder/GetFolders?type=0`).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    for (const item of data) {
                        this.disposeTree(item)
                    }
                    this.folderTreeData = data;
                }
            });
        },
        belongChange(value){
            this.belongValue = value;
            console.log(this.belongValue);
        },
        disposeTree(node) {
            //删除叶子节点的 children 属性
            if (node.children.length) {
                for (const item of node.children) {
                    this.disposeTree(item)
                }
            } else {
                delete node.children
            }
        },

    },
    mounted() {
        //获取云附件列表
        this.getCloudList();
    },
};
</script>

<style lang="scss" scoped>
.pageBox{
    padding: 20px;
    .header{
        margin-bottom: 20px;
        overflow: hidden;
        .searchBox{
            width: 500px;
            float: right;
        }
    }
    .contentBox{
        height: calc(100vh - 180px);
        background: #FFFFFF;
        box-shadow: 0px 1px 6px 1px rgba(0, 0, 0, 0.16);
        .topHeader{
            height: 45px;
            line-height: 45px;
            background: #FBFBFB;
            padding-left: 30px;
            position: relative;
            .goBack{
                position: absolute;
                top: 0;
                bottom: 0; 
                left: 30px;
                margin: auto;
                cursor: pointer;
            }
            .breadcrumb{
                margin-left: 50px;
                display: inline-block;
                line-height: 45px;
                .spanItem{
                    cursor: pointer;
                    font-size: 16px;
                    font-weight: bold;
                    color: #3A3A3A;
                }
                /deep/.el-breadcrumb__separator[class*=icon] {
                    margin: 0 10px;
                    font-size: 16px;
                    font-weight: bold;
                    color: #9C9C9C;
                }
            }
        }
        .tableHeader{
            height: 45px;
            line-height: 45px;
            background: #F3F3F3;
            font-size: 16px;
            font-weight: bold;
            color: #3A3A3A;
            display: flex;
            padding: 0 20px;
        }
        .tableBody{
            padding-bottom: 10px;
            height: calc(100vh - 280px);
            overflow: overlay;
            .FolderItem,.FileItem{
                padding: 0 10px;
                display: flex;
                height: 50px;
                line-height: 50px;
                border-bottom: 1px solid #EEEEEE;
                font-size: 14px;
                color: #1A2745;
                .imgIcon{
                    vertical-align: middle;
                    padding: 0 20px;
                }
            }
        }
        .tableHeader,.FolderItem,.FileItem{
            .emailTitle{
                width: 280px;
                padding: 0 10px;
            }
            .name{
                width: calc(100% - 800px);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .size{
                width: 130px;
                padding: 0 10px;
                text-align: center;
            }
            .creator{
                width: 130px;
                padding: 0 10px;
                text-align: center;
            }
            .time{
                width: 180px;
                padding: 0 10px;
                text-align: center;
            }
            .moreOption{
                width: 130px;
                padding: 0 10px;
                text-align: center;
            }
        }
    }
}
</style>