<script>
import Vue from 'vue'
import { ref } from '@vue/composition-api'
export default Vue.extend({
  setup() {
    const un = ref(false)

    const onClick = () => un.value = true

    return {
      un,
      onClick
    }
  }
})
</script>

<template>
  <div style="background: #e7f5fb; width: 100vw; height: 100vh; box-sizing: border-box; padding-top: 10px;">
    <div style="background-color:#FFFFFF;margin:0 auto;width:890px;height:600px">
      <div style="background-color:#eeeeee;position:relative;" role="banner" class="ui-header ui-bar-a">
        <h1 style="font-size: 16px;text-align:center;line-height:40px;">Unsubscribe</h1>
      </div>
      <div style="background-color:#fff;">
        <div v-if="!un">
          <p style="text-align: center; font-size: 16px;margin-top:100px">
            If you are sure not to receive any more messages, please click "Next"
          </p>
          <div style="text-align:center;margin:0 auto;width:50%;margin-top:100px">
            <div>
              <button @click="onClick" type="button" style=" color: #ffffff; background-color: #38c; width: 100%; border: 0; line-height: 36px; border-radius: 5px;">Next</button>
            </div>
          </div>
        </div>
        <div v-else>
          <p style="text-align: center; font-size: 16px;margin-top:100px">
            Your email has been removed from our list. Thank you!
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
