<template>
    <div class="forgotPwd">
        <div class="header">
            <img class="logo" src="@/assets/img/logo.png" alt="" />
            <p>
                已有账号，
                <span class="pointer" style="color:var(--themeColor);" @click="goBack">马上登录</span>
                <span style="padding: 0 20px;">|</span>
                <span class="pointer" style="color:var(--themeColor);" @click="goBack">返回企芯</span>
            </p>
        </div>

        <div class="content" v-show="stepIndex==1">
            <div class="title">找回密码</div>
            <div class="body">
                <el-input class="inputItem" v-model="oneStep.account" placeholder="账号/邮箱/手机号" clearable></el-input>
                <div class="inputItem">
                    <el-input v-model="oneStep.verifyCode" placeholder="验证码" clearable></el-input>
                    <img :src="oneStep.imgSrc" style="width:120px;margin-left:20px;height:40px;cursor: pointer;" @click="getImgCode" />
                </div>
                <el-button type="primary" style="width:100%" @click="verifyAccount">下一步</el-button>
            </div>
        </div>
        
        <div class="content" v-show="stepIndex==2">
            <div class="title">选择验证方式</div>
            <div class="body">
                <div class="chooseItem" @click="toWeChatReset">
                    <img src="@/assets/icon/verify1.png" alt="" />
                    <p class="p1">微信重置</p>
                    <p class="p2">通过微信扫一扫重置密码</p>
                </div>
                <div class="chooseItem" @click="toEmailReset">
                    <img src="@/assets/icon/verify2.png" alt="" />
                    <p class="p1">邮箱重置</p>
                    <p class="p2">通过邮箱接收邮件重置密码</p>
                </div>
                <div class="chooseItem" @click="toPhoneReset">
                    <img src="@/assets/icon/verify3.png" alt="" />
                    <p class="p1">手机号重置</p>
                    <p class="p2">通过手机号接收短信重置密码</p>
                </div>
            </div>
        </div>

        <div class="content" v-show="stepIndex==3">
            <div class="title">手机号验证</div>
            <div class="body">
                <el-input class="inputItem" v-model="threeStep.phone" placeholder="手机号" clearable></el-input>
                <div class="inputItem">
                    <el-input v-model="threeStep.verifyCode" placeholder="验证码" clearable></el-input>
                    <el-button @click="getPhoneCode" :disabled="btnHtml != '获取验证码' " type="primary" style="width:120px;margin-left:20px;">{{btnHtml}}</el-button>
                </div>
                <el-button type="primary" style="width:100%" @click="phoneVerify">确认</el-button>
            </div>
        </div>

        <div class="content" v-show="stepIndex==4">
            <div class="title">邮箱验证</div>
            <div class="body">
                <el-input class="inputItem" v-model="fourStep.email" placeholder="邮箱" clearable></el-input>
                <div class="inputItem">
                    <el-input v-model="fourStep.verifyCode" placeholder="验证码" clearable></el-input>
                    <el-button @click="getEmailCode" :disabled="btnHtml != '获取验证码' " type="primary" style="width:120px;margin-left:20px;">{{btnHtml}}</el-button>
                </div>
                <el-button type="primary" style="width:100%" @click="emailVerify">确认</el-button>
            </div>
        </div>

        <div class="content" v-show="stepIndex==5">
            <div class="title">微信验证</div>
            <div class="body">
                <p class="desc">请使用微信扫描二维码确认身份</p>
                <iframe src="https://open.weixin.qq.com/connect/qrconnect?appid=wxc1188a2db178f69f&redirect_uri=https://oa.lsmaps.com/auth/callback/Wechart?ukey=find|https://oa.lsmaps.com&response_type=code&scope=snsapi_login&state=CRM&href=https://vip.lsmaps.com/context/css/wx.css" frameborder="0" scrolling="no" style="height: 250px;" sandbox="allow-top-navigation allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>
            </div>
        </div>

        <div class="content" v-show="stepIndex==6">
            <div class="title">重置密码</div>
            <div class="body">
                <el-input class="inputItem" v-model="sixStep.password1" placeholder="设置新密码" show-password></el-input>
                <el-input class="inputItem" v-model="sixStep.password2" placeholder="再次输入新密码" show-password></el-input>
                <el-button type="primary" style="width:100%" @click="onSubmit">确认</el-button>
            </div>
        </div>

        <div class="content" v-show="stepIndex==7">
            <div class="title">修改完成</div>
            <div class="body">
                <div class="finish">
                    <img src="@/assets/img/ok.png" alt="" />
                    <p>修改完成</p>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            stepIndex: 1,
            btnHtml: "获取验证码",
            resPhone: "",
            resEmail: "",
            isBindWechat: false,
            ukey: "",
            oneStep:{
                account: "",
                verifyCode: "",
                imgSrc: "",
                imgKey: "",
            },
            threeStep:{
                phone: "",
                verifyCode: "",
            },
            fourStep:{
                email: "",
                verifyCode: "",
            },
            sixStep:{
                password1: "",
                password2: "",
            }
        };
    },
    methods: {
        goBack(){
            this.$router.push('/login');
        },

        //获取图片验证码
        getImgCode(){
            this.$axios.get(`${this.$authUrl}/api/Account/Verifycode`).then((res) => {
                this.oneStep.imgSrc = res.image;
                this.oneStep.imgKey = res.key;
            });
        },
        //验证账号是否存在
        verifyAccount(){
            const {account,imgKey,verifyCode} = this.oneStep;
            if(!account || !verifyCode){
                this.$message({message: '请输入账号和验证码',type: 'warning'});
                return false;
            }
            this.$axios.post(`${this.$authUrl}/api/Account/VerifyAccount`,{
                "account": account,
                "key": imgKey,
                "code": verifyCode
            }).then((res) => {
                const { account , phone , isBindWechat } = res;
                if (account || phone || isBindWechat) {
                    this.resPhone = phone;
                    this.resEmail = account;
                    this.isBindWechat = isBindWechat;
                    this.stepIndex = 2;
                }
            });           
        },

        toPhoneReset(){
            if(!this.resPhone){
                this.$message({message: '暂无绑定手机号,请尝试其他方式验证!',type: 'warning'});
            }else{
                this.stepIndex = 3;
            }
        },
        getPhoneCode(){
            if(!this.threeStep.phone){
                this.$message({message: '请输入手机号获取验证码',type: 'warning'});
                return false;
            }
            if(this.threeStep.phone !== this.resPhone){
                this.$message({message: '绑定手机号输入错误',type: 'warning'});
                return false;
            }
            this.$axios.post(`${this.$authUrl}/api/Account/SendSms`,{
                phone: this.threeStep.phone,
            }).then((res) => {
                if(res === true){
                    this.$message({message: "手机验证码已发送",type: 'success'});
                    var num = 60;
                    var timer = setInterval(() => {
                        if(num == 0){
                            clearInterval(timer)
                            this.btnHtml = "获取验证码"
                        }else{
                            this.btnHtml = `倒计时${num}s`
                            num--
                        }
                    }, 1000);
                }
            });
        },
        phoneVerify(){
            if(this.threeStep.phone !== this.resPhone){
                this.$message({message: '绑定手机号输入错误',type: 'warning'});
                return false;
            }
            if(!this.threeStep.verifyCode){
                this.$message({message: '请输入验证码',type: 'warning'});
                return false;
            }
            this.$axios.post(`${this.$authUrl}/api/Account/VerifySms`,{
                phone:  this.threeStep.phone,
                code: this.threeStep.verifyCode,
            }).then((res) => {
                const {isSuccess,data} = res
                if(isSuccess){
                    this.ukey = data;
                    this.stepIndex = 6;
                }
            });
        },

        toEmailReset(){
            if(!this.resEmail){
                this.$message({message: '暂无绑定邮箱,请尝试其他方式验证!',type: 'warning'});
            }else{
                this.stepIndex = 4;
            }
        },
        getEmailCode(){
            if(!this.fourStep.email){
                this.$message({message: '请输入邮箱获取验证码',type: 'warning'});
                return false;
            }
            if(this.fourStep.email !== this.resEmail){
                this.$message({message: '绑定邮箱输入错误',type: 'warning'});
                return false;
            }
            this.$axios.post(`${this.$authUrl}/api/Account/SendEmailCode`,{
                email: this.fourStep.email,
            }).then((res) => {
                if(res === true){
                    this.$message({message: "邮箱验证码已发送",type: 'success'});
                    var num = 60;
                    var timer = setInterval(() => {
                        if(num == 0){
                            clearInterval(timer)
                            this.btnHtml = "获取验证码"
                        }else{
                            this.btnHtml = `倒计时${num}s`
                            num--
                        }
                    }, 1000);
                }
            });
        },
        emailVerify(){
            if(this.fourStep.email !== this.resEmail){
                this.$message({message: '绑定邮箱输入错误',type: 'warning'});
                return false;
            }
            if(!this.fourStep.verifyCode){
                this.$message({message: '请输入验证码',type: 'warning'});
                return false;
            }
            this.$axios.post(`${this.$authUrl}/api/Account/VerifyEmailCode`,{
                email:  this.fourStep.email,
                verifyCode: this.fourStep.verifyCode,
            }).then((res) => {
                const {isSuccess,data} = res
                if(isSuccess){
                    this.ukey = data;
                    this.stepIndex = 6;
                }
            });
        },

        toWeChatReset(){
            if(!this.isBindWechat){
                this.$message({message: '暂无绑定微信,请尝试其他方式验证!',type: 'warning'});
            }else{
                this.stepIndex = 5;
            }
        },

        onSubmit(){
            const {password1,password2} = this.sixStep;
            if(!password1 || !password2){
                this.$message({message: '请输入重置密码',type: 'warning'});
                return false;
            }
            if(password1 !== password2){
                this.$message({message: '请确保二次输入的密码一致',type: 'warning'});
                return false;
            }
            this.$axios.post(`${this.$authUrl}/api/Account/ResetPassword`,{
                ukey: this.ukey,
                password: password1
            }).then((res) => {
                if(res === true){
                    this.stepIndex = 7;
                }
            });
        }
    },
    mounted() {
        this.getImgCode();

        
    },
    beforeMount(){
        const ukey = this.$route.query.ukey;
        const msg = this.$route.query.msg;
        if(ukey){
            this.ukey = ukey;
            this.stepIndex = 6;
        }
        if(msg){
            this.$message({message: msg ,type: 'warning'});
            this.stepIndex = 5;
        }
    }
};
</script>

<style lang="scss" scoped>
.forgotPwd{
    background: #F4F4F4;
    width: 100vw;
    height: 100vh;
    .header{
        width: 1100px;
        margin: auto;
        padding: 100px 0 40px;
        overflow: hidden;
        p{
            float: right;
            line-height: 38px;
        }
    }
    .content{
        width: 1100px;
        margin: auto;
        height: 569px;
        background: #FFFFFF;
        border: 1px solid #707070;
        .title{
            width: 1100px;
            height: 80px;
            line-height: 80px;
            text-align: center;
            background: #092338;
            font-size: 30px;
            font-weight: bold;
            color: #FFFFFF;
        }
        .body{
            width: 360px;
            margin: auto;
            padding-top: 50px;
            .inputItem{
                margin-bottom: 20px;
                display: flex;
            }
            .chooseItem{
                margin-bottom: 20px;
                height: 48px;
                background: #F5F5F5;
                border: 1px solid #D6D6D6;
                padding: 16px;
                cursor: pointer;
                position: relative;
                padding-left: 80px;
                img{
                    position: absolute;
                    left: 30px;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                }
                .p1{
                    height: 24px;
                    line-height: 24px;
                    font-size: 16px;
                    font-weight: bold;
                    color: #4C4C4C;
                }
                .p2{     
                    height: 24px;
                    line-height: 24px;
                    font-size: 14px;
                    color: #A5A5A5;
                }
            }
            .desc{
                text-align: center;
                font-size: 24px;
                line-height: 32px;
                color: #000000;
            }
            .finish{
                text-align: center;
                p{
                    margin-top: 20px;
                    line-height: 32px;
                    font-size: 24px;
                    font-weight: bold;
                    color: #4C4C4C;
                }
            }
        }
    }
}
</style>