<template>
    <div class="staffAudit">
        <div class="title">员工审核</div>
        <div class="tableData" v-loading="tableLoading">
            <el-empty :image-size="200" v-show="staffList.length==0"></el-empty>
            <div class="staffItem" v-for="item in staffList" :key="item.key">
                <el-avatar class="avatarImg" :size="80" src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"></el-avatar>
                <el-row :gutter="24">
                    <el-col :span="8" class="field">
                        <div class="label">姓名：</div>
                        <div class="value">{{item.name}}</div>
                    </el-col>
                    <el-col :span="8" class="field">
                        <div class="label">性别：</div>
                        <div class="value">{{item.sex == 1 ? '男':'女'}}</div>
                    </el-col>
                    <el-col :span="8" class="field">
                        <div class="label">生日：</div>
                        <div class="value">{{item.birthday}}</div>
                    </el-col>
                    <!-- <el-col :span="8" class="field">
                        <div class="label">邮箱：</div>
                        <div class="value">{{item.email}}</div>
                    </el-col> -->
                    <!-- <el-col :span="8" class="field">
                        <div class="label">微信：</div>
                        <div class="value">
                            <img src="@/assets/img/wechat.png" alt="" />
                        </div>
                    </el-col> -->
                    <el-col :span="8" class="field">
                        <div class="label">电话：</div>
                        <div class="value">
                            <img src="@/assets/img/tel.png" alt="" />{{item.phone}}
                        </div>
                    </el-col>
                    <!-- <el-col :span="8" class="field">
                        <div class="label">部门：</div>
                        <div class="value"></div>
                    </el-col> -->
                    <!-- <el-col :span="8" class="field">
                        <div class="label">职位：</div>
                        <div class="value"></div>
                    </el-col> -->
                </el-row>
                <div class="operationBtn">
                    <el-button type="primary" plain size="mini" @click="toAuditDetails(item)">查看</el-button>
                </div>
            </div>
            <el-pagination v-show="totalItems>10" background layout="total,prev,pager,next" :total="totalItems" style="float:right;margin:10px 0;" :current-page.sync="pageIndex" @current-change="pageIndexChange"></el-pagination>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            //列表展示
            tableLoading: false,
            staffList: [],
            totalItems: 0,
            pageIndex: 1,
        };
    },
    methods: {
        // 获取入职申请列表
        getStaffList(){
            this.tableLoading = true;
            this.$axios.post(`${this.$personnelUrl}/EntryApplication/GetEntryApplicationList`,{
                name: "",
                pageIndex: this.pageIndex,
                pageSize: 10,
            }).then((res) => {
                const { isSuccess, data } = res;
                this.tableLoading = false;
                if (isSuccess) {
                    this.staffList = data.datas;
                    this.totalItems = data.totalItems;
                }
            });
        },
        pageIndexChange(page){
            this.pageIndex = page;
            this.getStaffList();
        },
        toAuditDetails(item){
            let routeData = this.$router.resolve({
                path: "/staff/auditDetails",
                query: { key: item.key },
            });
            window.open(routeData.href, "_blank");
        }
    },
    mounted() {
        this.getStaffList();    //获取入职申请列表
    },
};
</script>

<style lang="scss" scoped>
.staffAudit{
    background: rgba($color: #ffffff, $alpha: 0.7);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    padding: 10px 20px;
    .title {
        font-size: 24px;
        font-weight: bold;
        color: #2e4756;
        padding-bottom: 12px;
        padding-left: 12px;
        border-bottom: 1px solid #d3dce6;
    }
    .tableData{
        margin-top: 20px;
        .staffItem{
            height: 120px;
            background: #FFFFFF;
            border: 1px solid #EBEBEB;
            margin-bottom: 12px;
            position: relative;
            display: flex;
            .manageImg{
                position: absolute;
                top: 0;
                left: 0;
            }
            .avatarImg{
                margin-left: 40px;
                margin-top: 20px;
            }
            .operationBtn{
                width: 130px;
                margin-top: 40px;
            }
            .el-row{
                margin: 12px 0 0 12px !important;
                width: 75%;
                .field{
                    line-height: 32px;
                    display: flex;
                    font-size: 14px;
                    .label{
                        width: 50px;
                        color: #858585;
                    }
                    .value{
                        flex-grow: 1;
                        color: #092338;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        img{
                            vertical-align: middle;
                            padding-right: 5px;
                        }
                    }
                }
            }
        }
    }
}
</style>