<template>
    <div>
        <el-result style="margin-top:200px;" icon="success" title="支付成功">
            <template slot="extra">
                <el-button type="primary" size="medium" @click="goBack">返回首页</el-button>
            </template>
        </el-result>
    </div>
</template>

<script>
export default {
    data() {
        return {

        };
    },
    methods: {
        goBack(){
            this.$router.push(`/homepage/workbench`);
        }
    },
    mounted() {

    },
};
</script>

<style lang="scss" scoped>

</style>