<template>
    <div style="padding: 0 5px">
        <el-button @click="toAdd" type="primary" icon="el-icon-plus" size="small" style="margin-bottom: 20px">新建联系人</el-button>
        <el-button size="small" style="margin-left: 10px;" icon="el-icon-s-promotion" @click="onWriteMail">
            写邮件
        </el-button>
        <div v-if="contactList.length==0" style="text-align: center;">
            <img src="@/assets/img/nodata.png" alt="" />
            <div style="font-size: 14px;color: #909399;margin-top: 10px;">暂无联系人</div>
        </div>
        <el-table v-else :data="contactList" :header-cell-style="{'background-color':'#F2F2F2','color':'#3A3A3A'}">
            <el-table-column label="姓名">
                <template slot-scope="scope">
                    <div style="display: flex;">
                        <span>{{scope.row.nickname || "——"}}</span>
                        <el-tag size="mini" v-if="scope.row.isMain">主要联系人</el-tag>
                        <el-tooltip class="item" effect="dark" content="Linkedin" placement="top">
                            <div class="inIcon" v-if="scope.row.linkedin" @click="jumpWeb(scope.row.linkedin)">
                                <svg t="1678958153071" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3034" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="20">
                                    <path d="M852 0 172 0C77.408 0 0 77.408 0 172l0 680c0 94.592 77.408 172 172 172l680 0c94.592 0 172-77.408 172-172L1024 172C1024 77.408 946.592 0 852 0zM384 832l-128 0L256 384l128 0L384 832zM320 320c-35.36 0-64-28.64-64-64s28.64-64 64-64 64 28.64 64 64S355.36 320 320 320zM832 832l-128 0L704 576c0-35.36-28.64-64-64-64s-64 28.64-64 64l0 256-128 0L448 384l128 0 0 79.456C602.4 427.2 642.752 384 688 384c79.52 0 144 71.648 144 160L832 832z" fill="var(--themeColor)" p-id="3035"></path>
                                </svg>
                            </div>
                        </el-tooltip>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="gender" label="性别"></el-table-column>
            <el-table-column prop="position" label="职位"></el-table-column>
            <el-table-column prop="email" label="邮箱">
                <template slot-scope="scope">
                    <EmailValue :value="scope.row.email" :isVerify="scope.row.isVerify" />
                </template>
            </el-table-column>
            <el-table-column prop="call" label="手机号"></el-table-column>
            <el-table-column prop="note" label="备注"></el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-link type="primary" @click.stop="toEdit(scope.row)">编辑</el-link>
                    <span style="padding:0 5px;"></span>
                    <el-link type="danger" @click.stop="toDel(scope.row)">删除</el-link>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog :title="dialogTitle" :visible.sync="contactVisible" width="600px">
            <el-form :model="contactForm" label-width="110px" size="small">
                <el-form-item label="主要联系人">
                    <el-radio-group v-model="contactForm.isMain">
                        <el-radio :label="true">是</el-radio>
                        <el-radio :label="false">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="姓名" required>
                    <el-input v-model="contactForm.nickname" placeholder="姓名"></el-input>
                </el-form-item>
                <el-form-item label="邮箱" required>
                    <el-input v-model="contactForm.email" placeholder="邮箱"></el-input>
                </el-form-item>
                 <el-form-item label="手机号">
                    <el-input v-model="contactForm.call" placeholder="手机号"></el-input>
                </el-form-item>
                <el-form-item label="职位">
                    <el-input v-model="contactForm.position" placeholder="职位"></el-input>
                </el-form-item>
                <el-form-item label="性别">
                    <el-radio-group v-model="contactForm.gender">
                        <el-radio label="男">男</el-radio>
                        <el-radio label="女">女</el-radio>
                        <el-radio label="未知">未知</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input v-model="contactForm.note" type="textarea"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button size="small" @click="contactVisible = false">取 消</el-button>
                <el-button @click="validateContactUnique" type="primary" size="small" :loading="btnLoading">确 定</el-button>
            </div>
        </el-dialog>

        <!-- 多个联系人写邮件 -->
        <el-dialog title="写邮件" :visible.sync="selectedContactVisible" width="500px">
            <el-table ref="multipleTable" :data="contactList" @selection-change="selectedContactChange">
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column prop="nickname" label="姓名"></el-table-column>
                <el-table-column prop="email" label="邮箱"></el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="selectedContactVisible = false">取 消</el-button>
                <el-button size="small" type="primary" :disabled="selectedContactList.length==0" @click="onSendMultiple">写邮件</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import { Message, MessageBox } from "element-ui";
export default {
    props:["contactList"],
    data() {
        return {
            btnLoading: false,
            contactVisible: false,
            dialogTitle: "",
            contactForm: {
                key: "",
                companyKey: this.$route.query.customerKey,
                isMain: false,
                nickname: "",
                email: "",
                position: "",
                call: "",
                gender: "",
                note: "",
            },

            //多个联系人写邮件
            selectedContactVisible: false,
            selectedContactList: [],
        };
    },
    methods: {
        // 写邮件
        onWriteMail(){
            if(this.contactList.length == 0){
                this.$message({ message: '请添加联系人后再写邮件!', type: 'warning'});
            }else if(this.contactList.length == 1){
                let receiver = this.contactList[0].email;
                window.open(
                    `${process.env.VUE_APP_MAIL_BASE}#/index/editor?type=send&receiver=${receiver}`,
                    "_blank"
                );
            }else{
                this.selectedContactVisible = true;
                this.$nextTick(()=>{
                    this.contactList.forEach(row => {
                        this.$refs.multipleTable.toggleRowSelection(row);
                    });
                })
            }
        },
        selectedContactChange(val) {
            this.selectedContactList = val;
        },
        onSendMultiple(){
            let receiver = this.selectedContactList.map(item => item.email);
            window.open(
                `${process.env.VUE_APP_MAIL_BASE}#/index/editor?type=send&receiver=${receiver.join(',')}`,
                "_blank"
            );
        },

        async handleDeleteContact(key) {
            try {
                const res = await this.$axios({
                    method: "DELETE",
                    url: this.$customerUrl + "/Company/RemoveContacter",
                    params: {
                        key,
                    },
                });
                if (res.isSuccess) {
                    Message.success("操作成功");
                    this.$emit("onSave")
                } else {
                    throw new Error();
                }
            } catch {
                Message.error("操作失败");
            } 
        },
        toAdd(){
            this.contactVisible = true;
            this.dialogTitle = "添加联系人";
            this.contactForm = {
                key: "",
                companyKey: this.$route.query.customerKey,
                isMain: false,
                nickname: "",
                email: "",
                position: "",
                call: "",
                gender: "未知",
                note: "",
            };
        },
        toEdit(row){
            this.contactVisible = true;
            this.dialogTitle = "编辑联系人";
            this.contactForm = {
                key: row.key,
                companyKey: this.$route.query.customerKey,
                isMain: row.isMain,
                nickname: row.nickname,
                email: row.email,
                position: row.position,
                call: row.call,
                gender: row.gender,
                note: row.note,
            };
        },
        toDel(row){
            this.$confirm('是否删除该联系人?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.handleDeleteContact(row.key);
            }).catch(() => {});
        },
        validateNotEmpty(str, label) {
            if (str.trim() === "") {
                Message.warning(`${label}不得为空`);
                throw new Error();
            }
        },
        async validateContactUnique() {
            try {
                const arr = [
                    [this.contactForm.nickname, "姓名"],
                    [this.contactForm.email, "邮箱"],
                ];
                for (const [str, label] of arr) {
                    this.validateNotEmpty(str, label);
                }
            } catch {
                return;
            }
            let r1 = false,
                r2 = false;
            if (this.contactForm.email.trim()) {
                const res = await this.$axios({
                    method: "GET",
                    url: this.$customerUrl + "/Company/ContactVerify",
                    params: {
                        customerKey: this.customerKey,
                        text: this.contactForm.email,
                    },
                });
                r1 = res.data.isExis;
            }
            if (this.contactForm.call.trim()) {
                const res = await this.$axios({
                    method: "GET",
                    url: this.$customerUrl + "/Company/ContactVerify",
                    params: {
                        customerKey: this.customerKey,
                        text: this.contactForm.call,
                    },
                });
                r2 = res.data.isExis;
            }
            if (r1 || r2) {
                MessageBox.confirm("联系人已存在，是否继续添加")
                    .then(() => {
                        this.createNewContact();
                    })
                    .catch(() => {});
            } else {
                this.createNewContact();
            }
        },
        async createNewContact() {
            try {
                this.btnLoading = true;
                await this.$axios({
                    method: "POST",
                    url: this.$customerUrl + "/Company/SaveContacter",
                    data: {
                        ...this.contactForm,
                    },
                });
                this.$emit("onSave")
                this.contactVisible = false;
            } catch {
                null;
            } finally {
                this.btnLoading = false;
            }
        },
    },
    mounted() {

    },
};
</script>

<style lang='scss' scoped>

</style>