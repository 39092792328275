<template>
  <div class="MarketingNav">
    <div class="navItem" @click="chooseNav('/marketing/TaskDashboard')" :class="{ active: currentPath === '/marketing/TaskDashboard' }">邮件营销</div>
    <div class="navItem" @click="chooseNav('/marketingWA/TaskDashboardWA')" :class="{ active: currentPath === '/marketingWA/TaskDashboardWA' }">WhatsApp营销</div>
  </div>
</template>

<script>

export default {
  props: ['currentPath'],
  methods: {
    chooseNav(index) {
      this.$router.push(index);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/common.scss';
.MarketingNav {
  box-sizing: border-box;
  height: 50px;
  overflow-y: hidden;
  padding: 0 30px 15px 20px;
  display: flex;
  align-items: center;

  .navItem {
    cursor: pointer;
    // width: 96px;
    padding: 0 20px;
    height: 32px;
    background: rgba(112, 112, 112, 0.1);
    border: 1px solid #b6b6b6;
    border-radius: 16px;
    text-align: center;
    line-height: 32px;
    color: #707070;
    font-size: 14px;

    &.active {
        border-color: $themeColor;
        background: $lightColor;
        color: #fff;
    }

    &:not(:first-child) {
      margin-left: 20px;
    }
  }
}
</style>
