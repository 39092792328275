<template>
    <div>
        <div class="header">
            <div class="title">我的标签</div>
            <div class="describe">管理您的个人标签</div>
            <el-button class="addBtn" @click="toNew" size="small" type="primary" icon="el-icon-plus">新建</el-button>
        </div>
        <el-table :data="LabelList" :header-cell-style="{'background-color':'#F3F3F3','color':'#3A3A3A'}" :row-style="rowStyle">
            <el-table-column label="标签名称">
                <template slot-scope="scope">
                    <el-tag :color="scope.row.color" effect="dark" size="mini">{{scope.row.text}}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="createDate" label="创建时间"></el-table-column>
            <el-table-column label="操作" width="200">
                <template slot-scope="scope">
                    <div>
                        <div class="handleEditBtn" @click="toEdit(scope.row)">编辑</div>
                        <div class="handleDelBtn" @click="toDel(scope.row)">删除</div>
                    </div>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="500px">
        <!-- 替代颜色选择器 -->
            <el-form label-width="100px" label-suffix="：">
                <el-form-item label="标签名称">
                         <el-input v-model.trim="submitForm.text" size="small" placeholder="标签名称"></el-input>
                </el-form-item>
                <el-form-item label="标签颜色">
                        <div v-for="item of predefineColors" :key="item" @click="submitForm.color=item" :style="{display:'inline-block',cursor: 'pointer', width: '1.5em', height: '1.5em', background: item, marginRight: '10px', boxShadow: item === submitForm.color ? '0 0 3px 2px #40a9ff' : 'none'}"></div>
                </el-form-item>
                <el-form-item>
                    <el-button size="small" type="primary" @click="onSubmit" :loading="btnLoading">确 认</el-button>
                <el-button size="small" @click="dialogVisible = false">取 消</el-button>
                </el-form-item>
            </el-form>
            <!-- <div style="display: flex;">
                <el-color-picker v-model="submitForm.color" :predefine="predefineColors" size="small" @active-change="(color) => submitForm.color = color"></el-color-picker>
                <el-input v-model="submitForm.text" size="small" placeholder="标签名称"></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" @click="onSubmit" :loading="btnLoading">确 认</el-button>
                <el-button size="small" @click="dialogVisible = false">取 消</el-button>
             </span> -->
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            LabelList: [],
            dialogVisible: false,
            dialogTitle: "",
            submitForm: {},
            btnLoading: false,
            predefineColors: ["#EA9426","#f759ab","#9254de","#597ef7","#40a9ff","#36cfc9","#73d13d","#ffec3d","#ff4d4f","#ff7a45"],
        };
    },
    methods: {
        rowStyle({rowIndex}){
            // 表格隔行换色
            if(rowIndex % 2 == 1){
                return {'background-color':'#F9FAFC'}
            }
        },

        getList(){
            this.$axios.get(`${this.$mailUrl}/v1/config/Lable`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.LabelList = data;
                }
            });
        },
        toNew(){
            this.dialogTitle = "添加标签";
            this.dialogVisible = true;
            this.submitForm = {
                key: "",
                text: "",
                color: "#EA9426",
            };
        },
        toEdit(row){
            this.dialogTitle = "编辑标签";
            this.dialogVisible = true;
            this.submitForm = {...row};
        },
        onSubmit(){
            if(!this.submitForm.text){
                this.$message({ message: '请输入标签名称', type: 'warning'});
                return false;
            }
            this.btnLoading = true;
            this.$axios.post(`${this.$mailUrl}/v1/config/Lable`,this.submitForm).then((res) => {
                const { isSuccess } = res;
                this.btnLoading = false;
                if (isSuccess) {
                    this.dialogVisible = false;
                    this.$message({ message: '提交成功', type: 'success'});
                    this.getList();
                }
            });
        },
        toDel(row){
            this.$confirm(`是否删除该标签：<strong style='color:var(--themeColor);'>${row.text}</strong>?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                dangerouslyUseHTMLString: true
            }).then(() => {
                this.$axios.delete(`${this.$mailUrl}/v1/config/Lable?key=${row.key}`).then(res => {
                    const {isSuccess} = res;
                    if(isSuccess){
                        this.$message({type: 'success',message: '删除成功!'});
                        this.getList();
                    }
                });
            }).catch(() => {});
        }
    },
    mounted() {
        this.getList();
    },
};
</script>

<style lang="scss" scoped>
.header{
    margin-bottom: 20px;
    overflow: hidden;
    height: 32px;
    line-height: 32px;
    .title{
        float: left;
        font-size: 16px;
        color: #333;
        font-weight: bold;
    }
    .describe{
        float: left;
        margin-left: 20px;
        font-size: 12px;
        color: #708090;
    }
    .addBtn{
        float: right;
    }
}
</style>