<template>
  <div v-loading="loading">
    <div id="state-analyse" class="state-analyse">
      <div class="title" ref="title">
        <p>所属洲</p>
        <div style="display: flex; align-items: center">
          <!-- <el-radio-group v-model="controlForm.option" size="mini">
          <el-radio-button :label="0">频次</el-radio-button>
          <el-radio-button :label="1">金额</el-radio-button>
          <el-radio-button :label="2">数量</el-radio-button>
          <el-radio-button :label="3">重量</el-radio-button>
        </el-radio-group> -->
          <img class="icon" src="@/firm/images/company/icon-download.png" style="margin-left: 1em" v-show="false" />
        </div>
      </div>
      <div class="content">
        <div class="chart-area">
          <div class="item" v-show="chartData.na.frequencyStr !== '0' || chartData.na.amountStr !== '0' || chartData.na.countStr !== '0' || chartData.na.weightStr !== '0'">
            <p class="title">【北美洲】</p>
            <div class="image-chart-area">
              <div class="map-image NA"></div>
              <div class="chart">
                <div class="indicator">
                  <p>频次：{{ chartData.na.frequencyStr }}</p>
                  <p>金额：{{ chartData.na.amountStr }}</p>
                  <p>数量：{{ chartData.na.countStr }}</p>
                  <p>重量：{{ chartData.na.weightStr }}</p>
                </div>
                <div class="instance" ref="NA"></div>
              </div>
            </div>
          </div>
          <div class="item" v-show="chartData.as.frequencyStr !== '0' || chartData.as.amountStr !== '0' || chartData.as.countStr !== '0' || chartData.as.weightStr !== '0'">
            <p class="title">【亚洲】</p>
            <div class="image-chart-area">
              <div class="map-image AS"></div>
              <div class="chart">
                <div class="indicator">
                  <p>频次：{{ chartData.as.frequencyStr }}</p>
                  <p>金额：{{ chartData.as.amountStr }}</p>
                  <p>数量：{{ chartData.as.countStr }}</p>
                  <p>重量：{{ chartData.as.weightStr }}</p>
                </div>
                <div class="instance" ref="AS"></div>
              </div>
            </div>
          </div>
          <div class="item" v-show="chartData.eu.frequencyStr !== '0' || chartData.eu.amountStr !== '0' || chartData.eu.countStr !== '0' || chartData.eu.weightStr !== '0'">
            <p class="title">【欧洲】</p>
            <div class="image-chart-area">
              <div class="map-image EU"></div>
              <div class="chart">
                <div class="indicator">
                  <p>频次：{{ chartData.eu.frequencyStr }}</p>
                  <p>金额：{{ chartData.eu.amountStr }}</p>
                  <p>数量：{{ chartData.eu.countStr }}</p>
                  <p>重量：{{ chartData.eu.weightStr }}</p>
                </div>
                <div class="instance" ref="EU"></div>
              </div>
            </div>
          </div>
          <div class="item" v-show="chartData.sa.frequencyStr !== '0' || chartData.sa.amountStr !== '0' || chartData.sa.countStr !== '0' || chartData.sa.weightStr !== '0'">
            <p class="title">【南美洲】</p>
            <div class="image-chart-area">
              <div class="map-image SA"></div>
              <div class="chart">
                <div class="indicator">
                  <p>频次：{{ chartData.sa.frequencyStr }}</p>
                  <p>金额：{{ chartData.sa.amountStr }}</p>
                  <p>数量：{{ chartData.sa.countStr }}</p>
                  <p>重量：{{ chartData.sa.weightStr }}</p>
                </div>
                <div class="instance" ref="SA"></div>
              </div>
            </div>
          </div>
          <div class="item" v-show="chartData.af.frequencyStr !== '0' || chartData.af.amountStr !== '0' || chartData.af.countStr !== '0' || chartData.af.weightStr !== '0'">
            <p class="title">【非洲】</p>
            <div class="image-chart-area">
              <div class="map-image AF"></div>
              <div class="chart">
                <div class="indicator">
                  <p>频次：{{ chartData.af.frequencyStr }}</p>
                  <p>金额：{{ chartData.af.amountStr }}</p>
                  <p>数量：{{ chartData.af.countStr }}</p>
                  <p>重量：{{ chartData.af.weightStr }}</p>
                </div>
                <div class="instance" ref="AF"></div>
              </div>
            </div>
          </div>
          <div class="item" v-show="chartData.oa.frequencyStr !== '0' || chartData.oa.amountStr !== '0' || chartData.oa.countStr !== '0' || chartData.oa.weightStr !== '0'">
            <p class="title">【大洋洲】</p>
            <div class="image-chart-area">
              <div class="map-image OA"></div>
              <div class="chart">
                <div class="indicator">
                  <p>频次：{{ chartData.oa.frequencyStr }}</p>
                  <p>金额：{{ chartData.oa.amountStr }}</p>
                  <p>数量：{{ chartData.oa.countStr }}</p>
                  <p>重量：{{ chartData.oa.weightStr }}</p>
                </div>
                <div class="instance" ref="OA"></div>
              </div>
            </div>
          </div>
          <div class="item" v-show="chartData.un.frequencyStr !== '0' || chartData.un.amountStr !== '0' || chartData.un.countStr !== '0' || chartData.un.weightStr !== '0'">
            <p class="title">【未分类数据】</p>
            <div class="image-chart-area">
              <div class="map-image UN"></div>
              <div class="chart">
                <div class="indicator">
                  <p>频次：{{ chartData.un.frequencyStr }}</p>
                  <p>金额：{{ chartData.un.amountStr }}</p>
                  <p>数量：{{ chartData.un.countStr }}</p>
                  <p>重量：{{ chartData.un.weightStr }}</p>
                </div>
                <div class="instance" ref="UN"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import echarts from "@/firm/plugins/echarts";
import axios from "@/firm/plugins/axios";
import { mapState, mapGetters, mapMutations } from "vuex";
import LazyOperation from "@/firm/plugins/lazy-execute";
import { AnchorInditor } from "../utils";

const chartInstances = {
  NA: null,
  AS: null,
  EU: null,
  SA: null,
  AF: null,
  OA: null,
  UN: null,
};
let lazyOperation = null;

export default Vue.extend({
  data() {
    return {
      loading: false,
      controlForm: {
        option: 0,
      },
      chartData: {
        na: {
          frequencyStr: "0",
          amountStr: "0",
          countStr: "0",
          weightStr: "0",
          frequencyPer: 0,
          amountPer: 0,
          countPer: 0,
          weightPer: 0,
        },
        as: {
          frequencyStr: "0",
          amountStr: "0",
          countStr: "0",
          weightStr: "0",
          frequencyPer: 0,
          amountPer: 0,
          countPer: 0,
          weightPer: 0,
        },
        eu: {
          frequencyStr: "0",
          amountStr: "0",
          countStr: "0",
          weightStr: "0",
          frequencyPer: 0,
          amountPer: 0,
          countPer: 0,
          weightPer: 0,
        },
        sa: {
          frequencyStr: "0",
          amountStr: "0",
          countStr: "0",
          weightStr: "0",
          frequencyPer: 0,
          amountPer: 0,
          countPer: 0,
          weightPer: 0,
        },
        af: {
          frequencyStr: "0",
          amountStr: "0",
          countStr: "0",
          weightStr: "0",
          frequencyPer: 0,
          amountPer: 0,
          countPer: 0,
          weightPer: 0,
        },
        oa: {
          frequencyStr: "0",
          amountStr: "0",
          countStr: "0",
          weightStr: "0",
          frequencyPer: 0,
          amountPer: 0,
          countPer: 0,
          weightPer: 0,
        },
        un: {
          frequencyStr: "0",
          amountStr: "0",
          countStr: "0",
          weightStr: "0",
          frequencyPer: 0,
          amountPer: 0,
          countPer: 0,
          weightPer: 0,
        },
      },
    };
  },
  computed: {
    ...mapState(["company", "companyType"]),
    ...mapState("controlFormGlobal", {
      ie: "ie",
      dataCountry: "dataCountry",
      dateRange: "dateRange",
      hsCode: "hsCode",
      importer: "importer",
      exporter: "exporter",
      country: "country",

      exHsCode: "exHsCode",
      exExporter: "exExporter",
      exImporter: "exImporter",
      exCountry: "exCountry",
    }),
    ...mapGetters("controlFormGlobal", {
      startTime: "startTime",
      endTime: "endTime",
    }),
  },
  watch: {
    "controlForm.option"() {
      lazyOperation?.reobserve();
    },
    ie() {
      lazyOperation?.reobserve();
    },
    dataCountry() {
      lazyOperation?.reobserve();
    },
    dateRange() {
      lazyOperation?.reobserve();
    },
    hsCode() {
      lazyOperation?.reobserve();
    },
    importer() {
      lazyOperation?.reobserve();
    },
    exporter() {
      lazyOperation?.reobserve();
    },
    country() {
      lazyOperation?.reobserve();
    },

    exHsCode() {
      lazyOperation?.reobserve();
    },
    exExporter() {
      lazyOperation?.reobserve();
    },
    exImporter() {
      lazyOperation?.reobserve();
    },
    exCountry() {
      lazyOperation?.reobserve();
    },

    companyType() {
      lazyOperation?.reobserve();
    },
  },
  methods: {
    ...mapMutations("anchor", {
      setCurrent: "setCurrent",
    }),
    reset() {
      for (const key in chartInstances) {
        chartInstances[key]?.setOption({
          series: [
            {
              name: `${key}-frequency`,
              data: [],
            },
            {
              name: `${key}-amount`,
              data: [],
            },
            {
              name: `${key}-count`,
              data: [],
            },
            {
              name: `${key}-weight`,
              data: [],
            },
          ],
        });
      }
      this.chartData = {
        na: {
          frequencyStr: "0",
          amountStr: "0",
          countStr: "0",
          weightStr: "0",
          frequencyPer: 0,
          amountPer: 0,
          countPer: 0,
          weightPer: 0,
        },
        as: {
          frequencyStr: "0",
          amountStr: "0",
          countStr: "0",
          weightStr: "0",
          frequencyPer: 0,
          amountPer: 0,
          countPer: 0,
          weightPer: 0,
        },
        eu: {
          frequencyStr: "0",
          amountStr: "0",
          countStr: "0",
          weightStr: "0",
          frequencyPer: 0,
          amountPer: 0,
          countPer: 0,
          weightPer: 0,
        },
        sa: {
          frequencyStr: "0",
          amountStr: "0",
          countStr: "0",
          weightStr: "0",
          frequencyPer: 0,
          amountPer: 0,
          countPer: 0,
          weightPer: 0,
        },
        af: {
          frequencyStr: "0",
          amountStr: "0",
          countStr: "0",
          weightStr: "0",
          frequencyPer: 0,
          amountPer: 0,
          countPer: 0,
          weightPer: 0,
        },
        oa: {
          frequencyStr: "0",
          amountStr: "0",
          countStr: "0",
          weightStr: "0",
          frequencyPer: 0,
          amountPer: 0,
          countPer: 0,
          weightPer: 0,
        },
        un: {
          frequencyStr: "0",
          amountStr: "0",
          countStr: "0",
          weightStr: "0",
          frequencyPer: 0,
          amountPer: 0,
          countPer: 0,
          weightPer: 0,
        },
      };
    },
    async refreshChartInstances() {
      try {
        this.loading = true;
        const res = await axios({
          method: "post",
          url: "/search/Credit/Ranking/Continent",
          data: {
            ie: this.ie,
            importer: this.importer,
            exporter: this.exporter,
            dataCountry: this.dataCountry,
            country: this.country,
            startTime: this.startTime,
            endTime: this.endTime,
            hsCode: this.hsCode,
            sortType: this.controlForm.option,
            noHsCode: this.exHsCode,
            noExporter: this.exExporter,
            noImporter: this.exImporter,
            noCountry: this.exCountry,
            company: this.company,
            companyType: this.companyType,
          },
        });
        this.loading = false;
        const data = res.data.result;
        this.chartData = data;
        if (!data) {
          throw new Error();
        }
        for (const key in chartInstances) {
          chartInstances[key] = echarts.init(this.$refs[key]);
          const frequencyPer = data[key.toLowerCase()].frequencyPer * 0.75;
          const amountPer = data[key.toLowerCase()].amountPer * 0.75;
          const countPer = data[key.toLowerCase()].countPer * 0.75;
          const weightPer = data[key.toLowerCase()].weightPer * 0.75;
          chartInstances[key]?.setOption({
            series: [
              {
                name: `${key}-frequency`,
                type: "pie",
                label: {
                  show: false,
                },
                labelLine: {
                  show: false,
                },
                data: [
                  { value: 100 - frequencyPer, name: "" },
                  { value: frequencyPer, name: "频次" },
                ],
              },
              {
                name: `${key}-amount`,
                type: "pie",
                label: {
                  show: false,
                },
                labelLine: {
                  show: false,
                },
                data: [
                  { value: 100 - amountPer, name: " " },
                  { value: amountPer, name: "金额" },
                ],
              },
              {
                name: `${key}-count`,
                type: "pie",
                label: {
                  show: false,
                },
                labelLine: {
                  show: false,
                },
                data: [
                  { value: 100 - countPer, name: "  " },
                  { value: countPer, name: "数量" },
                ],
              },
              {
                name: `${key}-weight`,
                type: "pie",
                label: {
                  show: false,
                },
                labelLine: {
                  show: false,
                },
                data: [
                  { value: 100 - weightPer, name: "   " },
                  { value: weightPer, name: "重量" },
                ],
              },
            ],
          });
        }
        this.$nextTick(() => {
          for (const key in chartInstances) {
            //chartInstances[key] = echarts.init(this.$refs[key] as HTMLDivElement);
            chartInstances[key]?.resize();
          }
        });
      } catch {
        this.loading = false;
      } finally {
        this.loading = false;
      }
      /* const chartData = {
        NA: {
          //键名 NA->北美洲  AS->亚洲  EU-> 欧洲  SA->南美洲  AF->非洲  OA->大洋洲  UN->未分类数据
          frequencyStr: "2,250", //频次
          amountStr: "4,54242(USD)", //金额
          countStr: "5,435", //数量
          weightStr: "1,000", //重量
          frequencyPer: 75, //频次占比(百分比)
          amountPer: 75, //金额占比(百分比)
          countPer: 75, //数量占比(百分比)
          weightPer: 75, //重量占比(百分比)
        },
      };
      console.log(chartData); */
    },
  },
  mounted() {
    for (const key in chartInstances) {
      chartInstances[key] = echarts.init(this.$refs[key]);
      chartInstances[key]?.setOption({
        series: [
          {
            name: `${key}-frequency`,
            type: "pie",
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },
            radius: ["70%", "80%"],
            data: [],
            color: ["#E3F0FF", "#FFC183"],
          },
          {
            name: `${key}-amount`,
            type: "pie",
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },
            radius: ["55%", "65%"],
            data: [],
            color: ["#E3F0FF", "#ED7F36"],
          },
          {
            name: `${key}-count`,
            type: "pie",
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },
            radius: ["40%", "50%"],
            data: [],
            color: ["#E3F0FF", "#D8A28B"],
          },
          {
            name: `${key}-weight`,
            type: "pie",
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },
            radius: ["25%", "35%"],
            data: [],
            color: ["#E3F0FF", "#9b7164"],
          },
        ],
      });
    }
    lazyOperation = new LazyOperation(() => {
      this.reset();
      this.refreshChartInstances();
    }, this.$el);
    new AnchorInditor(() => {
      this.setCurrent("SSZ");
    }, this.$refs["title"]);
  },
});
</script>

<style lang="scss" scoped>
@import "@/firm/scss/mixin.scss";

.state-analyse {
  margin-top: 1rem;
  background: #fff;
  padding: 1rem;
  padding-top: 0;
  @include mixin-panel-title;

  .chart-area {
    min-height: 264px;
    /* display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, calc(200px + 4em)); */
    display: flex;
    flex-wrap: wrap;

    .item {
      width: 50%;
      height: calc(200px + 4em);

      .title {
        height: 2em;
        line-height: 2em;
      }

      .image-chart-area {
        display: flex;

        .map-image {
          $states: (NA, AS, EU, SA, AF, OA, UN);
          width: 200px;
          height: 200px;
          margin: 1em;
          background-position: center center;
          background-size: contain;
          background-repeat: no-repeat;

          @each $state in $states {
            &.#{$state} {
              background-image: url("../../../images/map-#{$state}.png");
            }
          }

          &.unknown {
            box-sizing: border-box;
            border: 1px #848484 solid;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        .chart {
          position: relative;
          width: calc(100% - 200px - 2em);
          height: calc(200px + 2em);

          .indicator {
            position: absolute;
            width: 50%;
            left: 50%;
            bottom: 50%;
            z-index: 2;
            background: #fff;
            padding-top: 20px;
            padding-bottom: 26px;

            p {
              padding-left: 1em;
              font-size: 12px;
              line-height: 1.45em;
              font-weight: 400;
            }
          }

          .instance {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
</style>
