<template>
    <div class="searchBox">
        <div class="title">
            <span>基础信息</span>
        </div>
        <el-form :model="basicsForm" labelPosition='top' size="small">
            <el-row :gutter="30">
                <el-col :span="6">
                    <el-form-item label="公司名称">
                        <el-input v-model="basicsForm.businessName" @change="fieldChange" placeholder="公司名称" style="width:100%;"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="国家">
                        <el-select v-model="basicsForm.country" @change="countryChange" placeholder="国家" style="width:100%;" filterable clearable>
                            <el-option v-for="item of countryOption" :key="item.twoCountryCode" :label="item.label" :value="item.twoCountryCode">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="行业分类(SIC)">
                        <el-input v-model="SIC_ChineseStr" placeholder="行业分类(SIC)" style="width:100%;" disabled>
                            <el-button slot="append" @click="SIC_dialogVisible = true;">选择</el-button>
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="行业分类(NACE)">
                        <el-input v-model="NACE_ChineseStr" placeholder="行业分类(NACE)" style="width:100%;" disabled>
                            <el-button slot="append" @click="NACE_dialogVisible = true;">选择</el-button>
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="进口/出口/代理所有">
                        <el-select v-model="basicsForm.importExportAgentCodes" @change="fieldChange" placeholder=" 进口/出口/代理所有" style="width:100%;" filterable clearable>
                            <el-option v-for="item of abcOption" :key="item.code" :label="item.name" :value="item.code">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>

        <SICChooseBox :SIC_dialogVisible="SIC_dialogVisible" @closeDialog="SIC_dialogVisible = false;" @onSubmit="onSICSubmit" />

        <NACEChooseBox :NACE_dialogVisible="NACE_dialogVisible" @closeDialog="NACE_dialogVisible = false;" @onSubmit="onNACESubmit" />

    </div>
</template>

<script>
import SICChooseBox from "./SICChooseBox.vue";
import NACEChooseBox from "./NACEChooseBox.vue"
export default {
    components: { SICChooseBox, NACEChooseBox },
    data() {
        return {
            basicsForm:{
                businessName: "",
                country: "",
                internationalCodes: "",
                altInternationalCodes: "",
                importExportAgentCodes: "",
            },
            SIC_ChineseStr: "",
            NACE_ChineseStr: "",
            //配置选项
            countryOption: [],
            abcOption: [],
            //选择行业分类(SIC)
            SIC_dialogVisible: false,            
            //选择行业分类(NACE)
            NACE_dialogVisible: false,
        };
    },
    methods: {
        //查询infobel 国家信息
        getCountry(){
            this.$axios.get(`${this.$clueUrl}/BusinessData/Infobel/Country`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    data.forEach(item => {
                        item.label = item.countryCn + "-" + item.country + "-" + item.twoCountryCode;
                    });
                    this.countryOption = data;
                }
            });
        },
        //查询infobel Importexportagentcode信息
        getImportexportagent(){
            this.$axios.get(`${this.$clueUrl}/BusinessData/Infobel/Importexportagentcode`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.abcOption = data;
                }
            });
        },
        //选择SIC提交
        onSICSubmit(value){
            let codeStr = "";
            let showStr = "";
            value.forEach(item =>{
                codeStr += item.code + ",";
                showStr += item.describeCn + "(" + item.code + ")" + ","
            })
            codeStr = codeStr.substring(0,codeStr.length-1);
            showStr = showStr.substring(0,showStr.length-1);
            this.basicsForm.internationalCodes = codeStr;
            this.SIC_ChineseStr = showStr;
            this.$emit('inputComplete',this.basicsForm);
        },
        //选择NACE提交
        onNACESubmit(value){
            let codeStr = "";
            let showStr = "";
            value.forEach(item =>{
                codeStr += item.code + ",";
                showStr += item.describeCn + "(" + item.code + ")" + ","
            })
            codeStr = codeStr.substring(0,codeStr.length-1);
            showStr = showStr.substring(0,showStr.length-1);
            this.basicsForm.altInternationalCodes = codeStr;
            this.NACE_ChineseStr = showStr;
            this.$emit('inputComplete',this.basicsForm);
        },
        //选择国家
        countryChange(value){
            this.$emit('countryChange',value);
            this.$emit('inputComplete',this.basicsForm);
        },
        //字段改变,向父组件传递参数
        fieldChange(){
            this.$emit('inputComplete',this.basicsForm);
        },
    },
    mounted() {
        this.getCountry();
        this.getImportexportagent();
    },
};
</script>

<style lang="scss" scoped>
.searchBox{
    border-top: 1px solid #E6E6E6;
    padding-left: 40px;
    .title{
        color: #3A3A3A;
        font-weight: bold;
        font-size: 16px;
        margin: 10px 0;
    }
}
</style>