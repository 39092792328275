<template>
    <div class="ClueNav">
        <!-- <div class="navItem" @click="chooseNav('/clue/customsClue')" :class="{ active: currentPath === '/clue/customsClue' }">海关数据</div> -->
        <!-- <div class="navItem" @click="chooseNav('/clue/businessClue')" :class="{ active: currentPath === '/clue/businessClue' }">工商信息</div> -->
        <!-- <div class="navItem" @click="chooseNav('/clue/commercialClue')" :class="{ active: currentPath === '/clue/commercialClue' }">商业数据</div> -->
        <!-- <div class="navItem" @click="chooseNav('/clue/buyerClue')" :class="{ active: currentPath === '/clue/buyerClue' }">采购商</div> -->

        <div class="navItem" @click="chooseNav('/clue/myClue')" :class="{ active: currentPath === '/clue/myClue' }">
            <img v-if="currentPath === '/clue/myClue'" src="@/assets/cluePicture/nav1_1.png" alt="">
            <img v-else src="@/assets/cluePicture/nav1_0.png" alt="">
            <span>我的线索</span>
        </div>

        <div class="oneClass">公司线索</div>

        <div class="navItem" @click="chooseNav('/clue/productClueSearch')" :class="{ active: currentPath === '/clue/productClueSearch' }">
            <img v-if="currentPath === '/clue/productClueSearch'" src="@/assets/cluePicture/nav2_1.png" alt="">
            <img v-else src="@/assets/cluePicture/nav2_0.png" alt="">
            <span>产品线索</span>
        </div>

        <!-- <div class="navItem" v-show="this.$isTest" @click="chooseNav('/clue/allClue')" :class="{ active: currentPath === '/clue/allClue' }">客户线索</div> -->

        <div class="navItem" @click="chooseNav('/clue/wholeSearch')" :class="{ active: currentPath === '/clue/wholeSearch' }">
            <img v-if="currentPath === '/clue/wholeSearch'" src="@/assets/cluePicture/nav3_1.png" alt="">
            <img v-else src="@/assets/cluePicture/nav3_0.png" alt="">
            <span>全网搜索</span>
        </div>

        <div class="navItem" @click="chooseNav('/clue/businessDataSearch')" :class="{ active: currentPath === '/clue/businessDataSearch' }">
            <img v-if="currentPath === '/clue/businessDataSearch'" src="@/assets/cluePicture/nav3_1.png" alt="">
            <img v-else src="@/assets/cluePicture/nav3_0.png" alt="">
            <span>全球企业风险评估</span>
        </div>

        <div class="navItem" @click="chooseNav('/clue/tenderClue')" :class="{ active: currentPath === '/clue/tenderClue' }">
            <img v-if="currentPath === '/clue/tenderClue'" src="@/assets/cluePicture/nav4_1.png" alt="">
            <img v-else src="@/assets/cluePicture/nav4_0.png" alt="">
            <span>招标信息</span>
        </div>

        <!-- <div class="navItem" @click="chooseNav('/clue/exhibitionClue')" :class="{ active: currentPath === '/clue/exhibitionClue' }">
            <img v-if="currentPath === '/clue/exhibitionClue'" src="@/assets/cluePicture/nav7_1.png" alt="">
            <img v-else src="@/assets/cluePicture/nav7_0.png" alt="">
            <span>展会线索</span>
        </div> -->

        <div class="oneClass">联系人线索</div>

        <div class="navItem" @click="chooseNav('/clue/searchMailbox')" :class="{ active: currentPath === '/clue/searchMailbox' }">
            <img v-if="currentPath === '/clue/searchMailbox'" src="@/assets/cluePicture/nav5_1.png" alt="">
            <img v-else src="@/assets/cluePicture/nav5_0.png" alt="">
            <span>搜联系人邮箱</span>
        </div>

        <div class="navItem" @click="chooseNav('/clue/searchWhatsApp')" :class="{ active: currentPath === '/clue/searchWhatsApp' }">
            <img v-if="currentPath === '/clue/searchWhatsApp'" src="@/assets/cluePicture/nav6_1.png" alt="">
            <img v-else src="@/assets/cluePicture/nav6_0.png" alt="">
            <span>搜联系人WhatsApp</span>
        </div>

    </div>
</template>

<script>
export default {
    props: ["currentPath"],
    data() {
        return {
            
        };
    },
    methods: {
        chooseNav(index) {
            this.$emit("chooseNav",index);
        },

        
    },
    mounted() {
        
    },
};
</script>

<style lang="scss" scoped>
@import '@/scss/common.scss';
.ClueNav {
    width: 200px;
    height: calc(100vh - 80px);
    background: #ffffff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    .oneClass{
        padding: 5px 15px;
        height: 24px;
        line-height: 24px;
        font-size: 12px;
        color: #aaa;
        // font-weight: bold;
    }

    .navItem {
        cursor: pointer;
        padding: 0 15px;
        height: 40px;
        line-height: 40px;
        color: #000;
        font-size: 14px;
        &.active {
            color: #fff;
            background: $themeColor;
        }
        img{
            vertical-align: text-bottom;
            margin-right: 5px;
        }
    }
}
</style>