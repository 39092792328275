<script>
import Vue from 'vue'
import { reactive } from '@vue/composition-api'
import axios from '@/assets/js/axios'
export default Vue.extend({
  props: ['campaignId'],
  setup(props) {
    const campaignId = Number(props.campaignId)

    const panel = reactive({
      data: {}
    });
    (async () => {
      try {
        const res = await axios({
          method: 'GET',
          url: Vue.prototype.$marketUrl + '/MassAnalysis/GetSendEffectz',
          params: {
            campaignId
          }
        })
        panel.data = res.data
      } catch { null }
    })()

    const location = reactive({
      data: [] // as { count: number, country: string, proportion: string }[]
    });
    (async () => {
      try {
        const res = await axios({
          method: 'GET',
          url: Vue.prototype.$marketUrl + '/MassAnalysis/GetLocationAnalysis',
          params: {
            campaignId
          }
        })
        location.data = res.data
      } catch { null }
    })()

    const device = reactive({
      data: [] // as { device: string, count: number, proportion: strig }[]
    });
    (async () => {
      try {
        const res = await axios({
          method: 'GET',
          url: Vue.prototype.$marketUrl + '/MassAnalysis/GetDeviceAnalysis',
          params: {
            campaignId
          }
        })
        device.data = res.data
      } catch { null }
    })()

    const date = reactive({
      data: [] // as { count: number, peopleCount: number, peopleProportion: string, proportion: string, timeSlot: string }[]
    });
    (async () => {
      try {
        const res = await axios({
          method: 'GET',
          url: Vue.prototype.$marketUrl + '/MassAnalysis/GetOpneTimeSlotAnalysis',
          params: {
            campaignId
          }
        })
        date.data = res.data
      } catch { null }
    })()

    const lang = reactive({
      data: [] // as { count: number, language: string, proportion: string }[]
    });
    (async () => {
      try {
        const res = await axios({
          method: 'GET',
          url: Vue.prototype.$marketUrl + '/MassAnalysis/GetLanguageAnalysis',
          params: {
            campaignId
          }
        })
        lang.data = res.data
      } catch { null }
    })()

    return {
      panel,
      location,
      device,
      date,
      lang
    }
  }
})
</script>

<template>
  <div style="height: 100%; overflow-y: auto; font-size: 14px">
    <div class="panel">
      <div></div>
      <span>收件人</span>
      <span>看信人数</span>
      <span>看信率</span>
      <span>看信次数</span>
      <span>人均看信</span>
      <span>点击数</span>
      <span>取消订阅数</span>

      <span>本次群发</span>
      <span>{{ panel.data.thisAddresseeCount || '--' }}个</span>
      <span>{{ panel.data.thisOpenPeopleCount || '--' }}个</span>
      <span>{{ panel.data.thisOpenRate || '0.00' }}%</span>
      <span>{{ panel.data.thisOpenCount || '--' }}次</span>
      <span>{{ panel.data.thisPerCapitaOpen || '--' }}次</span>
      <span>{{ panel.data.thisCliskCount || '--' }}个</span>
      <span>{{ panel.data.thisUnsubscribeCount || '--' }}次</span>

      <span>所有群发</span>
      <span>{{ panel.data.allAddresseeCount || '--' }}个</span>
      <span>{{ panel.data.allOpenPeopleCount || '--' }}个</span>
      <span>{{ panel.data.allOpenRate || '0.00' }}%</span>
      <span>{{ panel.data.allOpenCount || '--' }}次</span>
      <span>{{ panel.data.allPerCapitaOpen || '--' }}次</span>
      <span>{{ panel.data.allCliskCount || '--' }}个</span>
      <span>{{ panel.data.allUnsubscribeCount || '--' }}次</span>
    </div>
    <div class="tableBox">
    <div class="block">
      <p class="title">
        <span>客户地理位置</span>
      </p>
      <el-table :data="location.data" size="mini">
        <el-table-column prop="country" label="国家" />
        <el-table-column prop="count" label="数量(收件人个数)" />
        <el-table-column label="占比">
          <template slot-scope="{row}">{{ row.proportion }}%</template>
        </el-table-column>
      </el-table>
    </div>
    <div class="block">
      <p class="title">
        <span>设备</span>
      </p>
      <el-table :data="device.data" size="mini">
        <el-table-column prop="device" label="设备" />
        <el-table-column prop="count" label="数量" />
        <el-table-column label="占比">
          <template slot-scope="{row}">{{ row.proportion }}%</template>
        </el-table-column>
      </el-table>
    </div>
    <div class="block">
      <p class="title">
        <span>看信时段</span>
      </p>
      <el-table :data="date.data" size="mini">
        <el-table-column prop="timeSlot" label="时段" />
        <el-table-column prop="count" label="数量(次)" />
        <el-table-column label="占比(次)">
          <template slot-scope="{row}">{{ row.proportion }}%</template>
        </el-table-column>
        <el-table-column prop="peopleCount" label="数量(人)" />
        <el-table-column label="占比(人)">
          <template slot-scope="{row}">{{ row.peopleProportion }}%</template>
        </el-table-column>
      </el-table>
    </div>
    <div class="block">
      <p class="title">
        <span>语言分析</span>
      </p>
      <el-table :data="lang.data" size="mini">
        <el-table-column prop="language" label="语言" />
        <el-table-column prop="count" label="数量" />
        <el-table-column label="占比">
          <template slot-scope="{row}">{{ row.proportion }}%</template>
        </el-table-column>
      </el-table>
    </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.panel {
  display: grid;
  grid-template-rows: repeat(3, 2.5em);
  grid-template-columns: 0.5fr repeat(7, 1fr);
  place-items: center;
}

.tableBox{
    column-count: 2;
    margin-top: 20px;
}
.block {
    margin-bottom: 1em;
}

.title {
  padding-left: 0.5em;
  border-left: 3px solid var(--themeColor);
}
</style>
