var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"100%","overflow-y":"auto","font-size":"14px"}},[_c('div',{staticClass:"panel"},[_c('div'),_c('span',[_vm._v("收件人")]),_c('span',[_vm._v("看信人数")]),_c('span',[_vm._v("看信率")]),_c('span',[_vm._v("看信次数")]),_c('span',[_vm._v("人均看信")]),_c('span',[_vm._v("点击数")]),_c('span',[_vm._v("取消订阅数")]),_c('span',[_vm._v("本次群发")]),_c('span',[_vm._v(_vm._s(_vm.panel.data.thisAddresseeCount || '--')+"个")]),_c('span',[_vm._v(_vm._s(_vm.panel.data.thisOpenPeopleCount || '--')+"个")]),_c('span',[_vm._v(_vm._s(_vm.panel.data.thisOpenRate || '0.00')+"%")]),_c('span',[_vm._v(_vm._s(_vm.panel.data.thisOpenCount || '--')+"次")]),_c('span',[_vm._v(_vm._s(_vm.panel.data.thisPerCapitaOpen || '--')+"次")]),_c('span',[_vm._v(_vm._s(_vm.panel.data.thisCliskCount || '--')+"个")]),_c('span',[_vm._v(_vm._s(_vm.panel.data.thisUnsubscribeCount || '--')+"次")]),_c('span',[_vm._v("所有群发")]),_c('span',[_vm._v(_vm._s(_vm.panel.data.allAddresseeCount || '--')+"个")]),_c('span',[_vm._v(_vm._s(_vm.panel.data.allOpenPeopleCount || '--')+"个")]),_c('span',[_vm._v(_vm._s(_vm.panel.data.allOpenRate || '0.00')+"%")]),_c('span',[_vm._v(_vm._s(_vm.panel.data.allOpenCount || '--')+"次")]),_c('span',[_vm._v(_vm._s(_vm.panel.data.allPerCapitaOpen || '--')+"次")]),_c('span',[_vm._v(_vm._s(_vm.panel.data.allCliskCount || '--')+"个")]),_c('span',[_vm._v(_vm._s(_vm.panel.data.allUnsubscribeCount || '--')+"次")])]),_c('div',{staticClass:"tableBox"},[_c('div',{staticClass:"block"},[_vm._m(0),_c('el-table',{attrs:{"data":_vm.location.data,"size":"mini"}},[_c('el-table-column',{attrs:{"prop":"country","label":"国家"}}),_c('el-table-column',{attrs:{"prop":"count","label":"数量(收件人个数)"}}),_c('el-table-column',{attrs:{"label":"占比"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.proportion)+"%")]}}])})],1)],1),_c('div',{staticClass:"block"},[_vm._m(1),_c('el-table',{attrs:{"data":_vm.device.data,"size":"mini"}},[_c('el-table-column',{attrs:{"prop":"device","label":"设备"}}),_c('el-table-column',{attrs:{"prop":"count","label":"数量"}}),_c('el-table-column',{attrs:{"label":"占比"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.proportion)+"%")]}}])})],1)],1),_c('div',{staticClass:"block"},[_vm._m(2),_c('el-table',{attrs:{"data":_vm.date.data,"size":"mini"}},[_c('el-table-column',{attrs:{"prop":"timeSlot","label":"时段"}}),_c('el-table-column',{attrs:{"prop":"count","label":"数量(次)"}}),_c('el-table-column',{attrs:{"label":"占比(次)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.proportion)+"%")]}}])}),_c('el-table-column',{attrs:{"prop":"peopleCount","label":"数量(人)"}}),_c('el-table-column',{attrs:{"label":"占比(人)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.peopleProportion)+"%")]}}])})],1)],1),_c('div',{staticClass:"block"},[_vm._m(3),_c('el-table',{attrs:{"data":_vm.lang.data,"size":"mini"}},[_c('el-table-column',{attrs:{"prop":"language","label":"语言"}}),_c('el-table-column',{attrs:{"prop":"count","label":"数量"}}),_c('el-table-column',{attrs:{"label":"占比"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.proportion)+"%")]}}])})],1)],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"title"},[_c('span',[_vm._v("客户地理位置")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"title"},[_c('span',[_vm._v("设备")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"title"},[_c('span',[_vm._v("看信时段")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"title"},[_c('span',[_vm._v("语言分析")])])}]

export { render, staticRenderFns }