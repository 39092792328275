<script>
import Vue from 'vue'
import { reactive, ref, defineComponent, watch, computed } from '@vue/composition-api'
import ItemCard from './components/card.vue'
import RecommendPanel from './components/recommend.vue'
import NewCardDialog from './components/NewCardDialog.vue'
import DetailPanel from './components/DetailPanel/Index.vue'
import axios from '@/assets/js/axios'
import iconList from '@/assets/icon/liebiao.png'
import iconKanban from '@/assets/icon/shujukanban.png'
import iconListA from '@/assets/icon/liebiao-a.png'
import iconKanbanA from '@/assets/icon/shujukanban-a.png'
import dayjs from 'dayjs'
import ColleageCardVue from '../../components/ColleageCard.vue'

/**
 * 递归处理树形结构，删除叶子节点的 children 属性
 * 副作用函数
 * 时间复杂度 O(n)
 * 空间复杂度 O(n)
 */
export const disposeTree = function (node) {
  if (node.children.length) {
    for (const item of node.children) {
      disposeTree(item)
    }
  } else {
    delete node.children
  }
}

export default defineComponent({
  components: {
    ItemCard,
    RecommendPanel,
    NewCardDialog,
    DetailPanel,
    ColleageCardVue
  },
  setup() {
    const belongOptions = ref([]);
    const saleOption = ref([]);
    (async () => {
      const res = await axios({
        method: 'GET',
        url: Vue.prototype.$personnelUrl + '/Organization/FindOrganizationDrop'
      })
      const data = res.data
      data.unshift({
        key: '',
        name: '我的',
        children: []
      })
      for (const item of data) {
        disposeTree(item)
      }
      belongOptions.value = data
    })()
    const getSaleByOrganization = (organizationKey) =>{
        axios.get(`${Vue.prototype.$personnelUrl}/Organization/FindSaleByOrganization?organizationKey=${organizationKey}`).then((res) => {
            const { isSuccess , data } = res;
            if (isSuccess) {
                saleOption.value = data;
            }
        });
    }

    const icons = reactive({
      list: iconList,
      listA: iconListA,
      kanban: iconKanban,
      kanbanA: iconKanbanA
    })
    const detailPanel = reactive({
      isExsisted: false,
      businessKey: ''
    })
    const isRecommendPanelExsisted = ref(false)
    const newCardDialog = reactive({
      isExsisted: false,
      stageKey: ''
    })
    const loading = ref(false)
    const mode = ref('看板')
    const toggleMode = (value) => {
      if (value !== mode.value) {
        mode.value = value
      }
    }
    watch(() => mode.value, () => {
      getAllDataRefresh()
    })
    const control = reactive({
      belong: [''],
      search: '',
      date: '1',
      salekey: '',
    })
    watch(() => control.belong, () => {
        control.salekey = '';
        let organizationKey = control.belong[control.belong.length - 1];
        if(organizationKey){
            getSaleByOrganization(organizationKey);
        }else{
            saleOption.value = [];
        }
        getAllDataRefresh()
    })
    watch(() => control.salekey, () => {
      getAllDataRefresh()
    })
    const result = reactive({
      total: 0,
      data: [],
      fail: [],
      tableData: [],
      tableP: {
        size: 10,
        index: 1
      }
    })
    const tableLoading = ref(false)
    function resetResult() {
      result.total = 0
      result.data = []
      result.fail = []
    }
    (async () => {
      try {
        const res = await axios({
          method: 'GET',
          url: Vue.prototype.$sjUrl + '/BusinessChance/GetStages'
        })
        if (!res.isSuccess || !res.data.length) throw new Error()
        getAllDataRefresh()
      } catch {
        isRecommendPanelExsisted.value = true
      }
    })()
    const getAllDataRefresh = async () => {
      if (mode.value === '看板') {
        try {
          loading.value = true
          const res = await axios({
            method: 'GET',
            url: Vue.prototype.$sjUrl + '/BusinessChance/GetStages',
            params: {
              organizationKey: control.belong[control.belong.length - 1],
              ukey: control.salekey,
            }
          })
          resetResult()
          if (!res.isSuccess || !res.data.length) throw new Error()
          result.total = res.data.reduce((previousValue, currentValue) => previousValue + currentValue.num, 0)
          res.data.forEach(item => {
            if (item.stageName === '输单' && !result.fail.length) {
              result.fail.push({
                ...item,
                _auxiliary: {
                  pageIndex: 1,
                  pageSize: 100,
                  loading: false
                },
                list: []
              })
            } else {
              result.data.push({
                ...item,
                _auxiliary: {
                  pageIndex: 1,
                  pageSize: 100,
                  loading: false
                },
                list: []
              })
            }
          })
          const list = result.data.concat(result.fail)
          await Promise.allSettled(list.map(item => (async (item) => {
            try {
              item._auxiliary.loading = true
              const res = await axios({
                method: 'POST',
                url: Vue.prototype.$sjUrl + '/BusinessChance/GetBusinessChancePage',
                data: {
                  businessName: control.search,
                  sort: control.date,
                  stageKey: item.stageKey,
                  organizationKey: control.belong[control.belong.length - 1],
                  ukey: control.salekey,
                  pageIndex: item._auxiliary.pageIndex,
                  pageSize: item._auxiliary.pageSize
                }
              })
              item.list.push(...res.data.datas)
              //item.totalPrice = item.list.reduce((prev, current) => Number(prev.cnyAmount) || 0 + Number(current.cnyAmount) || 0, 0)
            } catch { null } finally { item._auxiliary.loading = false }
          })(item)))
          /* const sum = (() => {
            let s = 0
            result.fail[0].list.forEach(i => {
              s += Number(i.cnyAmount) || 0
            })
            return s
          })() */
          //result.fail[0].totalPrice = sum
          //console.log(result)
        } catch { null } finally {
          loading.value = false
        }
      } else if (mode.value === '列表') {
        getDataWhenTable(1)
      }
    }
    async function getDataWhenTable(pageIndex) {
      try {
        tableLoading.value = true
        const res = await axios({
          method: 'POST',
          url: Vue.prototype.$sjUrl + '/BusinessChance/GetBusinessChancePage',
          data: {
            businessName: control.search,
            sort: control.date,
            stageKey: '',
            organizationKey: control.belong[control.belong.length - 1],
            ukey: control.salekey,
            pageIndex: pageIndex,
            pageSize: result.tableP.size
          }
        })
        result.tableData = res.data.datas
        result.total = res.data.totalItems
        result.tableP.index = pageIndex
      } catch { null } finally {
        tableLoading.value = false
      }
    }
    const onRecommendPanelUse = () => { getAllDataRefresh(); isRecommendPanelExsisted.value = false }
    const onNewCardDialogDone = () => { newCardDialog.isExsisted = false; getAllDataRefresh() }
    const handleAddCardClick = (stageKey) => {
      newCardDialog.stageKey = stageKey
      newCardDialog.isExsisted = true
    }
    const sum = computed(() => {
      try {
        const sum = [0, 0, 0]
        result.data.forEach(item => {
          if (!item.isWinStatge) {
            sum[0] += item.totalPrice
          } else {
            sum[1] += item.totalPrice
          }
        })
        sum[2] = result.fail[0].totalPrice
        return sum
      } catch {
        return [0, 0, 0]
      }
    })
    const isUrgent = (endTime) => {
      const gap = dayjs(endTime).diff(dayjs(), 'days')
      return gap < 3 && gap >= 0
    }
    return {
      belongOptions,
      saleOption,
      icons,
      loading,
      detailPanel,
      isRecommendPanelExsisted,
      newCardDialog,
      mode,
      control,
      result,
      onRecommendPanelUse,
      onNewCardDialogDone,
      handleAddCardClick,
      getAllDataRefresh,
      tableLoading,
      getDataWhenTable,
      toggleMode,
      sum,
      isUrgent
    }
  }
})
</script>

<template>
  <div class="view">
    <DetailPanel v-if="detailPanel.isExsisted" :businessKey="detailPanel.businessKey" @close="detailPanel.isExsisted = false; getAllDataRefresh()" />
    <NewCardDialog v-if="newCardDialog.isExsisted" :stageKey="newCardDialog.stageKey" @done="onNewCardDialogDone" @cancel="newCardDialog.isExsisted = false" />
    <RecommendPanel v-if="isRecommendPanelExsisted" @use="onRecommendPanelUse" @close="isRecommendPanelExsisted = false" />
    <div class="top">
      <div style="display: flex; align-items: center">
        <el-cascader class="c-el-cascader-wrapper" :show-all-levels="false" v-model="control.belong" :options="belongOptions" :props="{ value: 'key', label: 'name', checkStrictly: true }" size="small" />
        <el-select v-if="saleOption.length>0" class="c-el-cascader-wrapper" placeholder="选择员工" v-model="control.salekey" size="small" clearable>
            <el-option v-for="item in saleOption" :key="item.key" :label="item.name" :value="item.key"></el-option>
        </el-select>
        <span style="font-size: 18px; color: #353636; font-weight: bold; margin-left: 20px">{{ `共${result.total}个商机` }}</span>
        <div style="display: flex; align-items: center; background: #E9E9E9; height: 30px; width: 80px; border-radius: 4px;cursor: pointer;margin-left: 20px;">
          <div style="width: 100%;height: 100%;display: flex;align-items: center; justify-content: center;" :style="[mode === '看板' ? { background: '#fff', boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.16)', borderRadius: '4px' } : {}]">
            <el-tooltip content="看板模式">
              <img style="width: 18px; height: 18px" @click="toggleMode('看板')" :src="mode === '看板' ? icons.kanbanA : icons.kanban" />
            </el-tooltip>
          </div>
          <div style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;" :style="[mode === '列表' ? { background: '#fff', boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.16)', borderRadius: '4px' } : {}]">
            <el-tooltip content="列表模式">
              <img style="width: 23px; height: 16px" @click="toggleMode('列表')" :src="mode === '列表' ? icons.listA : icons.list" />
            </el-tooltip>
          </div>
        </div>
        <el-button @click="handleAddCardClick('')" v-if="mode === '列表'" type="primary" size="mini" style="margin-left: 50px;">新建商机</el-button>
      </div>
      <div style="display: flex;align-items: center">
        <div class="no-border" style="display: flex; align-items: center; border: 1px solid #C0CCDA; border-radius: 4px">
          <!-- <el-select class="fake-select" value="商机" size="small" style="width: 120px" disabled /> 
          <div style="flex-shrink: 0; box-sizing: border-box; width: 1px; height: 100%; padding: 10px 0; background: #C0C0C0"></div>-->
          <el-input v-model.trim="control.search" @change="getAllDataRefresh" :disabled="loading" placeholder="请输入搜索关键字" size="small">
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
        </div>
      </div>
    </div>
    <div class="sub-top">
      <div style="display: flex; align-items: center">
        <span style="margin-right: 9px">总金额：{{ sum[0] + sum[1] }} CNY</span>[
        <span style="color: #7D7D7D">推进阶段金额：{{ sum[0] }} CNY</span>
        <span style="margin: 0 16px; color: #7D7D7D">|</span>
        <span style="color: #7D7D7D">赢单阶段金额：{{ sum[1] }} CNY</span>]
        <span style="margin-left: 30px">输单金额：{{ sum[2] }} CNY</span>
      </div>
      <div style="display: flex; align-items: center">
        <el-select @change="getAllDataRefresh" v-model="control.date" :disabled="loading" placeholder="排序" size="small">
          <el-option label="创建时间降序" value="1" />
          <el-option label="创建时间升序" value="2" />
          <el-option label="过期时间降序" value="3" />
          <el-option label="过期时间升序" value="4" />
          <el-option label="金额降序" value="5" />
          <el-option label="金额升序" value="6" />
        </el-select>
      </div>
    </div>
    <div class="main" style="position: relative" v-if="mode === '看板'">
      <span style="position: absolute; left: 30px; top: 50px; display: inline-block; background: var(--themeColor); border-radius: 4px; color: #fff; font-size: 12px; padding: 3px 6px">预计业绩</span>
      <div class="main-item" v-for="(item, index) of result.data" :key="item.stageKey">
        <div class="title" :class="[{ start: index === 0, end: index === result.data.length - 1, only: result.data.length === 1 }, item.isWinStatge ? 'second' : 'first']">
          <span style="z-index: 1">{{ `${item.stageName}(${item.num})` }}</span>
        </div>
        <div class="price">CNY {{ item.totalPrice }}</div>
        <el-scrollbar style="height: calc(100% - 40px - 45px)" v-loading="item._auxiliary.loading">
          <div class="scrollable">
            <ItemCard v-for="unit of item.list" :key="unit.businessKey" :data="unit" @cardclick="detailPanel.businessKey = unit.businessKey; detailPanel.isExsisted = true" />
            <div @click="handleAddCardClick(item.stageKey)" class="more-card">
              <img src="@/assets/img/add.png" style="margin-right: 4px" />
              <span style="font-size: 14px; color: #878787">新建商机</span>
            </div>
          </div>
        </el-scrollbar>
      </div>
      <div class="main-item" style="margin-left: 30px" v-for="item of result.fail" :key="item.stageKey">
        <div class="title only fail">
          <span style="z-index: 1">{{ `${item.stageName}(${item.num})` }}</span>
        </div>
        <div class="price">CNY {{ item.totalPrice }}</div>
        <el-scrollbar style="height: calc(100% - 40px - 45px)" v-loading="item._auxiliary.loading">
          <div class="scrollable">
            <ItemCard v-for="unit of item.list" :key="unit.businessKey" :data="unit" @cardclick="detailPanel.businessKey = unit.businessKey; detailPanel.isExsisted = true" />
            <div @click="handleAddCardClick(item.stageKey)" class="more-card">
              <img src="@/assets/img/add.png" style="margin-right: 4px" />
              <span style="font-size: 14px; color: #878787">新建商机</span>
            </div>
          </div>
        </el-scrollbar>
      </div>
    </div>
    <div class="main-list" v-if="mode === '列表'" v-loading="tableLoading">
      <el-table :data="result.tableData" size="small">
        <el-table-column prop="businessChanceName" label="商机名">
          <template slot-scope="{row}">
            <el-link type="primary" @click="detailPanel.businessKey = row.businessKey; detailPanel.isExsisted = true">{{ row.businessChanceName }}</el-link>
          </template>
        </el-table-column>
        <el-table-column prop="companyName" label="公司名称" show-overflow-tooltip />
        <el-table-column label="金额">
          <template slot-scope="{row}">
            <span>{{ `${row.amount} ${row.currency}` }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="stageName" label="商机阶段" />
        <el-table-column label="停留时间">
          <template slot-scope="{row}">
            <span>{{ `${row.dwellTime}天${row.isExpire ? ' ' + '(过期' + row.expirationDays + '天)' : ''}` }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="endTime" label="结束日期">
          <template slot-scope="{row}">
            <span :style="{ color: row.isExpire ? '#F56C6C' : isUrgent(row.endTime) ? '#E6A23C' : '' }">{{ row.endTime }}</span>
          </template>
        </el-table-column>
        <el-table-column label="跟进人" show-overflow-tooltip>
          <template slot-scope="{row}">
            <div style="display: flex; align-items: center">
              <div v-for="item of row.businessMembers" :key="item.ukey">
                <el-popover trigger="hover">
                  <ColleageCardVue :ckey="item.ukey" />
                  <div slot="reference" style="box-sizing: border-box; overflow: hidden; margin-right: 3px; display: flex; justify-content: center; align-items: center; width: 24px; height: 24px; border-radius: 50%; color: #fff; font-size: 12px; background: var(--themeColor); cursor: default">{{ item.name.slice(0, 2) }}</div>
                </el-popover>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 10px; display: flex; justify-content: flex-end">
        <el-pagination background layout="prev, pager, next" @current-change="getDataWhenTable" :total="result.total" :page-size="result.tableP.size" :current-page="result.tableP.index" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.view {
  position: relative;
  background-image: linear-gradient(to bottom,
      rgba(#ffffff, 0.7) 195px,
      transparent 195px,
      transparent 100%);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  height: 50px;
  padding-left: 16px;
  padding-right: 30px;
  border-bottom: 1px solid #d5d5d5;

  .no-border {
    ::v-deep .el-input__inner {
      border: none;
    }
  }
}

.sub-top {
  box-sizing: border-box;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;

  ::v-deep .el-select:not(:last-of-type) {
    margin-right: 15px;
  }
}

.main {
  box-sizing: border-box;
  width: 100%;
  height: calc(100% - 50px - 60px);
  overflow: auto;
  padding-left: 56px;
  padding-right: 25px;
  display: flex;
}

.main-list {
  box-sizing: border-box;
  width: 100%;
  height: calc(100% - 50px - 60px);
  background: #fff;
  overflow: auto;
  padding: 0 30px;
}

@mixin backSharpe($type, $color) {
  &::before {
    @if $type==first {
      border-radius: 20px 0 0 20px;
      background: linear-gradient(-135deg, transparent 15px, $color 0) top right,
        linear-gradient(-45deg, transparent 15px, $color 0) bottom right;
    }

    @else if $type==last {
      border-radius: 0 20px 20px 0;
      background: linear-gradient(-135deg,
          $color 15px,
          calc(100% - 15px),
          transparent 0) top right,
        linear-gradient(-45deg, $color 15px, calc(100% - 15px), transparent 0) bottom right;
    }

    @else if $type==only {
      border-radius: 20px;
      background: $color;
    }

    @else {
      background: linear-gradient(-135deg,
          transparent 15px,
          $color 15px,
          calc(100% - 15px),
          transparent 0) top right,
        linear-gradient(-45deg,
          transparent 15px,
          $color 15px,
          calc(100% - 15px),
          transparent 0) bottom right;
    }

    position: absolute;
    z-index: 0;
    display: block;
    content: "";
    height: 100%;
    width: 105%;
    background-size: 100% 50%;
    background-repeat: no-repeat;
  }
}

.main-item {
  height: 100%;
  width: 270px;
  flex-shrink: 0;
}

.title {
  box-sizing: border-box;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  overflow: visible;
  position: relative;
  color: #fff;

  &.first {
    @include backSharpe(normal, #33a76f);

    &.start {
      @include backSharpe(first, #33a76f);
    }

    &.end {
      @include backSharpe(last, #33a76f);
    }

    &.only {
      @include backSharpe(only, #33a76f);
    }
  }

  &.second {
    @include backSharpe(normal, #4480cf);

    &.start {
      @include backSharpe(first, #4480cf);
    }

    &.end {
      @include backSharpe(last, #4480cf);
    }

    &.only {
      @include backSharpe(only, #4480cf);
    }
  }

  &.fail.only {
    @include backSharpe(only, #a1a1a1);
  }
}

.price {
  box-sizing: border-box;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #353636;
}

::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
}

.scrollable {
  box-sizing: border-box;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;

  ::v-deep .card:not(:first-of-type) {
    margin-top: 20px;
  }
}

.more-card {
  margin-top: 20px;
  cursor: pointer;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
  background: #fff;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:only-child {
    margin-top: 0;
  }
}

.fake-select {
  cursor: default;

  ::v-deep .el-input.is-disabled .el-input__inner {
    background: #fff;
  }

  ::v-deep .el-input__inner {
    cursor: default !important;
  }

  ::v-deep .el-input__suffix {
    display: none;
  }
}

.c-el-cascader-wrapper {
  width: 100px;

  ::v-deep .el-input__inner {
    border: none;
    background: none;
  }
}
</style>
