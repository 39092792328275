<template>
    <div>
        <!-- <el-button class="printBtn" type="primary" @click="bindPrint">打印</el-button> -->

        <div class="w" id="printContent">
            <div class="topContent">
                <el-row :gutter="16">
                    <el-col :span="8" class="infoItem">
                        <div class="label">客户:</div>
                        <div class="value">{{detailsInfo.name}}</div>
                    </el-col>
                    <el-col :span="8" class="infoItem">
                        <div class="label">订单编号:</div>
                        <div class="value">{{detailsInfo.orderNo}}</div>
                    </el-col>
                    <el-col :span="8" class="infoItem">
                        <div class="label">订单日期:</div>
                        <div class="value">{{detailsInfo.orderDate}}</div>
                    </el-col>
                </el-row>
            </div>

            <div style="margin-top:20px;">
                <div class="title">订单明细</div>
                <div class="detailBox">
                    <el-table :data="detailList" border>
                        <el-table-column prop="productNo" label="产品编码"></el-table-column>
                        <el-table-column label="产品图片">
                            <template slot-scope="scope">
                                <el-image style="width:50px;height:50px;" :src="scope.row.productUrl" :preview-src-list="[scope.row.productUrl]"></el-image>
                            </template>
                        </el-table-column>
                        <el-table-column prop="productName" label="名称"></el-table-column>
                        <el-table-column prop="productModel" label="尺寸信息"></el-table-column>
                        <el-table-column prop="productPrice" label="报价单价"></el-table-column>
                        <el-table-column prop="currency" label="货币种类"></el-table-column>
                        <el-table-column prop="productNum" label="订购数量"></el-table-column>
                        <el-table-column prop="unit" label="计量单位"></el-table-column>
                        <el-table-column prop="otherCost" label="其他费用"></el-table-column>
                        <el-table-column prop="productRemark" label="备注"></el-table-column>
                    </el-table>
                    <div class="describe">
                        <div class="oneItem">
                            <div>
                                <span>产品总数量: {{detailsInfo.productTotalNum}}</span>
                                <span style="margin-left:50px;">包装体积总计: {{detailsInfo.productTotalVolume}}{{detailsInfo.productVolumeUnit}}</span>
                            </div>
                            <div>产品总金额: {{detailsInfo.currency}}{{detailsInfo.orderAmount}}</div>
                        </div>
                        <div class="twoItem">
                            订单金额: {{detailsInfo.currency}}{{detailsInfo.orderAmount}}
                        </div>
                    </div>
                </div>
            </div>

            <div style="margin-top:20px;">
                <div class="title">基本信息</div>
                <el-descriptions border :labelStyle="{background:'#fff'}">
                    <el-descriptions-item label="订单编号">{{basicInfo.orderNo}}</el-descriptions-item>
                    <el-descriptions-item label="询价日期">{{basicInfo.inquiryDate}}</el-descriptions-item>
                    <el-descriptions-item label="实际报价日期">{{basicInfo.actualQuotationDate}}</el-descriptions-item>

                    <el-descriptions-item label="有效期限">{{basicInfo.validityDate}}</el-descriptions-item>
                    <el-descriptions-item label="报价状态">{{basicInfo.orderStatus}}</el-descriptions-item>
                    <el-descriptions-item label="客户编号">{{basicInfo.customerNo}}</el-descriptions-item>

                    <el-descriptions-item label="客户简称">{{basicInfo.nickname}}</el-descriptions-item>
                    <el-descriptions-item label="联系人">{{basicInfo.contactName}}</el-descriptions-item>
                    <el-descriptions-item label="联系人Email">{{basicInfo.contactEmail}}</el-descriptions-item>

                    <el-descriptions-item label="客户等级">{{basicInfo.itemValue}}</el-descriptions-item>
                    <el-descriptions-item label="我方公司">{{basicInfo.weCompany}}</el-descriptions-item>
                    <el-descriptions-item label="结算类别">{{basicInfo.settlementCategory}}</el-descriptions-item>

                    <el-descriptions-item label="销售币种">{{basicInfo.currency}}</el-descriptions-item>
                    <el-descriptions-item label="汇率">{{basicInfo.exchangeRate}}</el-descriptions-item>
                    <el-descriptions-item label="报价模式">{{basicInfo.quotationMode}}</el-descriptions-item>

                    <el-descriptions-item label="统一利润率">{{basicInfo.profitMargin}}</el-descriptions-item>
                    <el-descriptions-item label="结汇方式">{{basicInfo.settlementExchange}}</el-descriptions-item>
                    <el-descriptions-item label="价格条款">{{basicInfo.priceTerms}}</el-descriptions-item>

                    <el-descriptions-item label="出运口岸">{{basicInfo.portOfShipment}}</el-descriptions-item>
                    <el-descriptions-item label="目的口岸">{{basicInfo.portOfDestination}}</el-descriptions-item>
                    <el-descriptions-item label="贸易国家">{{basicInfo.tradeCountry}}</el-descriptions-item>

                    <el-descriptions-item label="运输方式">{{basicInfo.typeOfShipping}}</el-descriptions-item>
                    <el-descriptions-item label="运费币种">{{basicInfo.freightCurrency}}</el-descriptions-item>
                    <el-descriptions-item label="运费汇率">{{basicInfo.freightRate}}</el-descriptions-item>

                    <el-descriptions-item label="单位运费">{{basicInfo.freight}}</el-descriptions-item>
                    <el-descriptions-item label="保险加成">{{basicInfo.insurancePremium}}</el-descriptions-item>
                    <el-descriptions-item label="保险比率">{{basicInfo.insuranceRatio}}</el-descriptions-item>

                    <el-descriptions-item label="佣金比率">{{basicInfo.commissionRate}}</el-descriptions-item>
                    <el-descriptions-item label="销售员">{{basicInfo.sale}}</el-descriptions-item>
                </el-descriptions>
            </div>

            <div style="margin-top:20px;">
                <div class="title">联系人信息</div>
                <el-descriptions direction="vertical" border :labelStyle="{background:'#fff',width:'33%'}">
                    <el-descriptions-item label="联系人">{{detailsInfo.contactName}}</el-descriptions-item>
                    <el-descriptions-item label="联系方式">{{detailsInfo.telephone}}</el-descriptions-item>
                    <el-descriptions-item label="职位">{{detailsInfo.contactPostion}}</el-descriptions-item>
                </el-descriptions>
            </div>

        </div>
        
    </div>
</template>

<script>
// import pdf from "../../assets/js/pdf";
// import printJS from "print-js";
export default {
    data() {
        return {
            orderKey: "",
            detailsInfo: {},
            basicInfo: {},
            detailList: [],
        };
    },
    methods: {
        //获取订单信息
        getOrderInfo() {
            this.$axios
                .get(`${this.$orderUrl}/api/Order?orderKey=${this.orderKey}`)
                .then((res) => {
                    const { isSuccess, data } = res;
                    if (isSuccess) {
                        this.detailsInfo = data;
                        //获取基本信息
                        this.basicInfo = this.detailsInfo;
                        //获取订单明细
                        this.detailList = this.detailsInfo.orderProducts;
                        //数据加载完自动触发打印
                        this.$nextTick(function () {
                            window.print();
                        })
                    }
                });
        },

        //打印函数
        bindPrint() {
            // 方案一: 打印pdf
            // var dom = document.getElementById("printContent");
            // pdf.downloadPDF(dom, `订单详情-${this.detailsInfo.orderNo}`);

            // 方案二: 使用插件打印
            // printJS({
            //     printable: "printContent",
            //     type:'html',
            //     header:null,
            //     targetStyles:['*'],
            //     style:"@page {margin:0 10mm}"
            // })

            // 方案三: 原生方法打印
            // var headstr = "<html><head><title></title></head><body>";
            // var footstr = "</body>";
            // let newstr = document.getElementById("printContent" ).innerHTML;
            // let oldstr = document.body.innerHTML;
            // document.body.innerHTML = newstr;
            // document.body.innerHTML = headstr + newstr + footstr;
            // window.print();
            // document.body.innerHTML = oldstr;
            // return false;
        },
    },
    mounted() {
        this.orderKey = this.$route.query.orderKey;
        this.getOrderInfo(); //获取订单信息
    },
};
</script>

<style lang="scss" scoped>
.w {
    width: 1000px;
    padding: 0 10px;
    margin: auto;
    .topContent {
        background: #ffffff;
        padding: 10px;
        position: relative;
        .infoItem {
            height: 28px;
            line-height: 28px;
            font-size: 16px;
            .label {
                display: inline-block;
                color: #333;
                font-weight: bold;
                padding-right: 10px;
            }
            .value {
                display: inline-block;
                color: #383838;
                border-bottom: 1px solid #383838;
            }
        }
    }
    .title {
        height: 28px;
        line-height: 28px;
        font-size: 14px;
        font-weight: bold;
        color: #000000;
        margin-bottom: 10px;
    }
    .detailBox {
        border: 1px solid #EBEEF5;
        .describe {
            padding: 10px;
            font-size: 14px;
            color: #3a3a3a;
            .oneItem {
                height: 28px;
                line-height: 28px;
                display: flex;
                justify-content: space-between;
            }
            .twoItem {
                margin-top: 10px;
                height: 28px;
                line-height: 28px;
                font-weight: bold;
                text-align: right;
            }
        }
    }
}

.printBtn {
    width: 100%;
    // position: fixed;
    // top: 50px;
    // right: 50px;
}
</style>