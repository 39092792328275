<template>
    <div class="detailPage" v-loading="infoLoading">
        <div class="header">
            <span>{{basicInfo.name}}</span>
            <div class="rightHandle" v-if="!isHistory">
                <el-button size="small" @click="exportQuotation" :disabled="isHistory" :loading="downloadLoading">导出报价单</el-button>
                <el-button size="small" @click="toEditQuotation" :disabled="isHistory">编辑报价单</el-button>
                <el-button size="small" @click="toCopyQuotation" :disabled="isHistory">复制报价单</el-button>
                <el-button size="small" @click="quotationCreateOrder" :disabled="isHistory">生成订单</el-button>
                <el-dropdown>
                    <el-button size="small" :disabled="historyOption.length==0"  style="margin-left:20px;">
                        历史版本<i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-for="(item,index) in historyOption" :key="index" @click.native="chooseVersion(item)">
                            version{{index+1}}：{{item.quoteName}}({{item.createTime}})
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>

        <el-form class="basicInfo" :model="basicInfo" label-width="100px" size="small">
            <el-row :gutter="16">
                <el-col :span="6">
                    <el-form-item label="报价日期:">
                        <span class="valueColor">{{basicInfo.quotationDate}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="报价有效期:">
                        <span class="valueColor">{{basicInfo.expirationTime}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="交货地点:">
                        <span class="valueColor">{{basicInfo.placeDelivery || "--"}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="运输方式:">
                        <span class="valueColor">{{basicInfo.typeShipping || "--"}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="是否含税:">
                        <span class="valueColor">{{basicInfo.isTaxIncluded ? "含税": "不含"}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="支付方式:">
                        <span class="valueColor">{{basicInfo.paymentMethod || "--"}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="备注:">
                        <span class="valueColor">{{basicInfo.remarks || "--"}}</span>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>

        <div class="title">买方信息</div>
        <el-form class="basicInfo" :model="basicInfo" label-width="100px" size="small">
            <el-row :gutter="16">
                <el-col :span="6">
                    <el-form-item label="公司名称:">
                        <span class="valueColor">{{basicInfo.customerName}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="客户电话:">
                        <span class="valueColor">{{basicInfo.customerCall}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="客户传真:">
                        <span class="valueColor">{{basicInfo.customerFax}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="客户地址:">
                        <span class="valueColor">{{basicInfo.customerAddress}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="联系人名称:">
                        <span class="valueColor">{{basicInfo.contactsName}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="联系人电话:">
                        <span class="valueColor">{{basicInfo.contactsCall}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="联系人邮箱:">
                        <span class="valueColor">{{basicInfo.contactsEmail}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="联系人地址:">
                        <span class="valueColor">{{basicInfo.contactsAddress}}</span>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>

        <div class="title">报价产品</div>
        <el-table :data="quotedProducts" :header-cell-style="{'background-color':'#F3F3F3','color':'#3A3A3A'}">
            <el-table-column label="编号">
                <template slot-scope="scope">
                    {{scope.row.productNo}}
                </template>
            </el-table-column>
            <el-table-column label="产品名称">
                <template slot-scope="scope">
                    {{scope.row.productName}}
                </template>
            </el-table-column>
            <el-table-column label="型号">
                <template slot-scope="scope">
                    {{scope.row.specifications}}
                </template>
            </el-table-column>
            <el-table-column label="计量单位">
                <template slot-scope="scope">
                    {{scope.row.measureUnit}}
                </template>
            </el-table-column>
            <el-table-column label="单价" width="160">
                <template slot-scope="scope">
                    {{scope.row.unitPrice}}
                </template>
            </el-table-column>
            <el-table-column label="订购数量" width="160">
                <template slot-scope="scope">
                    {{scope.row.num}}
                </template>
            </el-table-column>
            <el-table-column label="总价" width="160">
                <template slot-scope="scope">
                    {{scope.row.totalPrice}}
                </template>
            </el-table-column>
            <el-table-column label="币种" width="120">
                <template slot-scope="scope">
                    {{scope.row.currency}}
                </template>
            </el-table-column>
            <el-table-column label="汇率" width="160">
                <template slot-scope="scope">
                    {{scope.row.exchangeRate}}
                </template>
            </el-table-column>
            <el-table-column label="人民币总价" width="160">
                <template slot-scope="scope">
                    {{scope.row.cnyTotalPrice}}
                </template>
            </el-table-column>
            <el-table-column label="备注">
                <template slot-scope="scope">
                    {{scope.row.remarks}}
                </template>
            </el-table-column>
        </el-table>
        <div class="summation">
            <div>
                <span>产品总数量：</span>
                <span style="padding-left:10px;">{{productTotalNum}}</span>
            </div>
            <div>
                <span>产品总金额：</span>
                <span>CNY</span>
                <span style="padding-left:10px;">{{productTotalPrice}}</span>
            </div>
        </div>

        <div class="title">贸易费用</div>
        <div class="tradeCost">
            <div v-show="tradeExpenses.length == 0" style="text-align: center;color: #7C90A5;padding: 10px;">暂无贸易费用</div>
            <div v-for="(item,index) in tradeExpenses" :key="index" class="itemCost">
                <span style="margin-right: 20px;">{{index+1}}</span>
                <div style="margin-right:50px;">
                    <span>项目：</span>
                    <span class="valueColor">{{item.project}}</span>
                </div>
                <div style="margin-right:50px;">
                    <span>金额：</span>
                    <span class="valueColor">{{item.totalPrice}} {{item.currency}}</span>
                </div>
                <div style="margin-right:50px;">
                    <span>汇率：</span>
                    <span class="valueColor">{{item.exchangeRate}}</span>
                </div>
                <div style="margin-right:50px;">
                    <span>人民币金额：</span>
                    <span class="valueColor">{{item.cnyTotalPrice}}</span>
                </div>
            </div>
        </div>
        <div class="summation">
            <div></div>
            <div>
                <span>贸易费用金额：</span>
                <span>CNY</span>
                <span style="padding-left:10px;">{{tradeExpensesTotalPrice}}</span>
            </div>
        </div>
        <div class="summation" style="font-size: 16px;font-weight: bold;">
            <div></div>
            <div>
                <span>合计总金额：</span>
                <span>CNY</span>
                <span style="padding-left:10px;">{{allTotalPrice}}</span>
            </div>
        </div>

        <!-- 自定义字段 -->
        <ShowSaveField :documentKey="basicInfo.key" :type="3" />
        <!-- 附件管理 -->
        <Fujian :documentKey="basicInfo.key" type="quotationFujian" />

        <div class="title" style="margin-top:20px;">报价人信息</div>
        <div class="quotedContact">
            <el-row :gutter="24">
                <el-col :span="24" class="fieldItem">
                    <span class="name">{{quotedContact.name}}</span>
                </el-col>
                <el-col :span="24" class="fieldItem">
                    <span class="valueColor">{{quotedContact.companyName}}</span>
                </el-col>
                <el-col :span="6" class="fieldItem">
                    <span>地址：</span>
                    <span class="valueColor">{{quotedContact.address}}</span>
                </el-col>
                <el-col :span="6" class="fieldItem">
                    <span>电话：</span>
                    <span class="valueColor">{{quotedContact.tel}}</span>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="6" class="fieldItem">
                    <span>邮箱：</span>
                    <span class="valueColor">{{quotedContact.email}}</span>
                </el-col>
                <el-col :span="6" class="fieldItem">
                    <span>备注：</span>
                    <span class="valueColor">{{quotedContact.remarks}}</span>
                </el-col>
            </el-row>
        </div>

        <el-dialog title="导出报价单" :visible.sync="exportVisible" width="1200px" top="100px">
            <div style="margin-bottom: 10px;">请选择一个报价单模板:</div>
            <el-select v-model="chooseTemplateKey" placeholder="请选择" filterable size="small" @change="onPreview">
                <el-option v-for="item in templateList" :key="item.key" :value="item.key" :label="item.value">
                </el-option>
            </el-select>
            <div style="margin-bottom: 10px;margin-top: 10px;">预览结果:</div>
            <div v-html="previewContent"></div>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="exportVisible = false;">取 消</el-button>
                <el-button size="small" type="primary" @click="exportPdf" :loading="downloadLoading">导 出</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import Fujian from "@/components/Fujian.vue";
import ShowSaveField from "@/components/ShowSaveField.vue"
export default {
    components: { Fujian , ShowSaveField },
    props: {
        handleSelect: {
            type: Function,
            default: null,
        },
    },
    data() {
        return {
            quotationKey: "",
            isHistory: false,
            infoLoading: false,
            basicInfo: {},      //基础信息
            quotedProducts: [], //报价产品
            tradeExpenses: [],  //贸易费用
            quotedContact: {},  //报价人信息
            //历史版本
            historyOption: [],
            downloadLoading: false,

            //导出报价单(选择模板导出)
            exportVisible: false,
            templateList: [],
            chooseTemplateKey: "",
            previewContent: "",

            productTotalNum: 0,
            productTotalPrice: 0,
            tradeExpensesTotalPrice: 0,
            allTotalPrice: 0,
        };
    },
    computed:{        
        //产品总金额
        productTotalAmount(){
            let productTotalAmount = 0;
            this.quotedProducts.forEach(ele =>{
                if(ele.productKey){
                    productTotalAmount += ele.totalPrice;
                }
            })
            return productTotalAmount;
        },
        //贸易费用金额
        extraChargeAmount(){
            let extraChargeAmount = 0;
            this.tradeExpenses.forEach(ele =>{
                extraChargeAmount += ele.price;
            })
            return extraChargeAmount;
        },
        //订单合计总金额
        orderTotalAmount(){
            return this.productTotalAmount + this.extraChargeAmount;
        },
    },
    methods: {
        //导出报价单(选择模板导出)
        exportQuotation(){
            this.exportVisible = true;
            this.$axios.get(`${this.$sjUrl}/ExportTemplate/DropDownBox`).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    this.templateList = data;
                    this.chooseTemplateKey = data[0].key;
                    this.onPreview();
                }
            });
        },
        onPreview(){
            this.$axios.post(`${this.$sjUrl}/Quote/Preview`,{
                "templateKey": this.chooseTemplateKey,
                "quoteKey": this.quotationKey,
            }).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    this.previewContent = data;
                }
            });
        },
        exportPdf(){
            if(!this.chooseTemplateKey){
                this.$message({ message: '请选择一个模板!', type: 'warning'});
                return false;
            }
            this.downloadLoading = true;
            this.$axios({
                url: `${this.$sjUrl}/Quote/ExportPdf?templateKey=${this.chooseTemplateKey}&quoteKey=${this.quotationKey}`,
                method: 'GET',
                responseType: 'blob',
            }).then(response => {
                this.downloadLoading = false;
                var blob = new Blob([response]);
                var link= document.createElement('a');
                link.download = `${this.basicInfo.name}.pdf`;
                link.href = URL.createObjectURL(blob);
                link.click();
                this.$message({ message: "导出报价单成功",type: 'success' });
                this.exportVisible = false;
            });
        },
        
        //复制报价单
        toCopyQuotation(){
            let routeData = this.$router.resolve({
                path: "/quotation/newQuotation",
                query: { quotationKey: this.quotationKey, optype: "copy" },
            });
            window.open(routeData.href, "_blank");
        },
        //编辑报价单
        toEditQuotation(){
            let routeData = this.$router.resolve({
                path: "/quotation/newQuotation",
                query: { quotationKey: this.quotationKey, optype: "edit" },
            });
            window.open(routeData.href, "_blank");
        },
        //报价单生成订单
        quotationCreateOrder(){
            let routeData = this.$router.resolve({
                path: "/orderManage/addOrder",
                query: { quotationKey: this.quotationKey },
            });
            window.open(routeData.href, "_blank");
        },

        //获取报价单详情
        getQuoteDetail(){
            this.infoLoading = true;
            this.$axios.get(`${this.$sjUrl}/Quote/GetQuoteDetail?key=${this.quotationKey}&isHistory=${this.isHistory}`).then((res) => {
                const { isSuccess , data } = res;
                this.infoLoading = false;
                if (isSuccess) {
                    this.productTotalNum = data.productTotalNum;
                    this.productTotalPrice = data.productTotalPrice;
                    this.tradeExpensesTotalPrice = data.tradeExpensesTotalPrice;
                    this.allTotalPrice = data.allTotalPrice;
                    this.basicInfo = data.quote;
                    this.quotedProducts = data.quotedProducts;
                    this.tradeExpenses = data.tradeExpenses;
                    this.quotedContact = data.quotedContact;
                    this.getHistoryQuotes();    //获取历史报价单列表
                    document.title = `报价单详情-${this.basicInfo.name}`;              
                }
            });
        },
        
        //获取历史报价单列表
        getHistoryQuotes(){
            this.$axios.get(`${this.$sjUrl}/Quote/GetHistoryQuotes?key=${this.basicInfo.key}`).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    this.historyOption = data;
                }
            });
        },
        chooseVersion(item){
            let routeData = this.$router.resolve({
                path: "/quotation/quotationDetails",
                query: { quotationKey: item.uniqueKey , isHistory: true },
            });
            window.open(routeData.href, "_blank");
        },

    },
    mounted() {
        this.quotationKey = this.$route.query.quotationKey;
        this.isHistory = this.$route.query.isHistory ? true : false;
        this.getQuoteDetail();      //获取报价单详情
    },
};
</script>

<style lang="scss" scoped>
.detailPage{
    background: #FFFFFF;
    padding: 20px;
    .header{
        height: 32px;
        line-height: 32px;
        font-size: 20px;
        font-weight: bold;
        color: #000;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
    }
    .title{
        margin-top:20px;
        height: 20px;
        line-height: 20px;
        font-size: 16px;
        font-weight: bold;
        color: #3A3A3A;
        margin-bottom: 10px;
        border-left: 3px solid var(--themeColor);
        padding-left: 5px;
    }
    .el-form-item {
        margin-bottom: 0;
    }
    .valueColor{
        color: #3A3A3A;
    }
    .tradeCost{
        border: 1px solid #EBEEF5;
        color: #5E6D82;
        font-size: 14px;
        .itemCost{
            display: flex;
            height: 32px;
            line-height: 32px;
            padding: 10px;
        }
        .itemCost:nth-child(2n+1){
            background: #F8F7F7;
        }
        .itemCost:nth-child(2n){
            background: #E5E9F0;
        }
        .handleDelBtn{
            margin-top: 6px;
        }
    }
    .quotedContact{
        background: #F6F4F4;
        color: #5E6D82;
        padding: 10px 50px;
        font-size: 14px;
        .fieldItem{
            height: 32px;
            line-height: 32px;
        }
        .name{
            font-size: 16px;
            font-weight: bold;
            color: #000000;
        }
    }
    .summation{
        font-size: 14px;
        height: 40px;
        line-height: 40px;
        color: #3a3a3a;
        display: flex;
        justify-content: space-between;
    }
}
/deep/ .el-dialog__body{
    padding: 10px 20px;
}
</style>