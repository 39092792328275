<template>
    <div>
        <!-- 趋势分析(0) 原产国排名(1) 数据源国排名(2) Hs编码排名(3) 起运港排名(4) 卸货港排名(5) -->
        <el-radio-group v-model="rankingType" size="small" style="margin: 10px 15px;" @change="rankingChange">
            <el-radio :label="0" border>趋势分析</el-radio>
            <el-radio :label="1" border>原产国排名</el-radio>
            <el-radio :label="2" border>数据源国排名</el-radio>
            <el-radio :label="3" border>Hs编码排名</el-radio>
            <el-radio :label="4" border>起运港排名</el-radio>
            <el-radio :label="5" border>卸货港排名</el-radio>
        </el-radio-group>
        <div style="overflow: hidden;" v-loading="visualRanking.tableLoading">
            <div v-show="rankingType == 0" id="trendDom" style="width:100%;height:300px;"></div>
            <div v-show="rankingType != 0" id="visualDom" style="width:100%;height:300px;"></div>
            <el-table :data="visualRanking.tableData">
                <el-table-column prop="name" :label="labelName[rankingType]"></el-table-column>
                <el-table-column prop="productCn" label="中文描述" v-if="rankingType == 3"></el-table-column>
                <el-table-column prop="frequency" label="频次"></el-table-column>
                <el-table-column prop="weight" label="重量"></el-table-column>
                <el-table-column prop="quantity" label="数量"></el-table-column>
                <el-table-column prop="value" label="金额"></el-table-column>
            </el-table>
            <el-pagination v-show="rankingType != 0" hide-on-single-page background layout="total,prev,pager,next" :total="visualRanking.totalItems" style="float:right;margin:15px 20px;" :current-page.sync="visualRanking.pageIndex" @current-change="getRanking"></el-pagination>
        </div>
    </div>
</template>

<script>
import * as echarts from "echarts";
var visualChart = null;
var trendChart = null;
export default {
    props: ["searchField"],
    data() {
        return {
            rankingType: 0,
            labelName: ["月份","国家","数据源国","Hs编码","起运港","卸货港"],
            interface:[
                "/Trade/Trend",
                "/Trade/Ranking/Country",
                "/Trade/Ranking/DataCountry",
                "/Trade/Ranking/HsCode",
                "/Trade/Ranking/LoadingPort",
                "/Trade/Ranking/UnloadingPort",
            ],
            titleList:[
                "趋势分析",
                "原产国Top10排名",
                "数据源国Top10排名",
                "Hs编码Top10排名",
                "起运港Top10排名",
                "卸货港Top10排名",
            ],
            visualRanking: {
                tableLoading: false,
                tableData: [],
                pageIndex: 1,
                totalItems: 0,
            },
        };
    },
    methods: {
        // 切换rankingType
        rankingChange(value){
            this.rankingType = value;
            this.getRanking(1);
        },
        //获取可视化排名(通过rankingType调用不用的接口请求数据)
        getRanking(pageIndex){
            this.visualRanking.pageIndex = pageIndex;
            this.visualRanking.tableLoading = true;
            this.$axios.post(`${this.$searchUrl}${this.interface[this.rankingType]}`, {
                ...this.searchField,
                pageIndex: pageIndex,
            }).then((res) => {
                const { isSuccess, data, errorMessage } = res;
                this.visualRanking.tableLoading = false;
                if (isSuccess) {
                    if(errorMessage == "无数据"){
                        this.$message({ message: '搜索暂无数据返回!', type: 'warning'});
                        return false;
                    }
                    // console.log(data.rankings);
                    data.rankings.forEach(item =>{
                        item.frequency = item.frequency.toFixed(2);
                        item.weight = item.weight.toFixed(2);
                        item.quantity = item.quantity.toFixed(2);
                        item.value = item.value.toFixed(2);
                    })
                    this.visualRanking.tableData = data.rankings;
                    this.visualRanking.totalItems = data.total;
                    if(pageIndex == 1){
                        if(this.rankingType == 0){
                            this.renderTrend(data.rankings,"trendDom","趋势分析");
                        }else{
                            this.renderChart(data.rankings,"visualDom",this.titleList[this.rankingType]);
                        }
                    }
                }
            });
        },
        //渲染图表
        renderChart(rankings,dom,title){
            let Top10 = [];
            rankings.forEach(item =>{
                Top10.push({name: item.name, value: item.frequency})
            })
            visualChart = echarts.init(document.getElementById(dom));
            visualChart.setOption(this.commonOption(Top10,title));
            visualChart.resize();
        },
        //公用的图表配置
        commonOption(Top10,title){
            return {
                title: {
                    text: title,
                    textStyle: {
                        fontSize: 14,
                    },
                    left: "center",
                },
                tooltip: {
                    trigger: "item",
                    formatter: "{b} : {c} ({d}%)",
                },
                series: [
                    {
                        type: "pie",
                        radius: "50%",
                        center: ["50%", "50%"],
                        data: Top10,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: "rgba(0, 0, 0, 0.5)",
                            },
                        },
                    },
                ],
            };
        },

        //渲染趋势分析图
        renderTrend(rankings,dom,title){
            let monthList = [];
            let frequencyList = [];
            let weightList = [];
            let quantityList = [];
            let valueList = [];
            rankings.reverse().forEach(item => {
                item.name = item.name.split("/")[0] + '-' + item.name.split("/")[1]; //日期格式处理
                monthList.push(item.name);
                frequencyList.push(item.frequency);
                weightList.push(item.weight);
                quantityList.push(item.quantity);
                valueList.push(item.value);
            });
            rankings.reverse()
            trendChart = echarts.init(document.getElementById(dom));
            var optionTrend = {
                title: {
                    text: title,
                    textStyle: {
                        fontSize: 14,
                    },
                    left: "left",
                },
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data: [ '频次','重量','数量','金额' ]
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: monthList
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        name: '频次',
                        type: 'line',
                        stack: '总量1',
                        data: frequencyList
                    },
                    {
                        name: '重量',
                        type: 'line',
                        stack: '总量2',
                        data: weightList
                    },
                    {
                        name: '数量',
                        type: 'line',
                        stack: '总量3',
                        data: quantityList
                    },
                    {
                        name: '金额',
                        type: 'line',
                        stack: '总量4',
                        data: valueList
                    },
                ]
            };
            trendChart.setOption(optionTrend);
            trendChart.resize();
        },
    },
    mounted() {

    },
};
</script>

<style lang="scss" scoped>

</style>