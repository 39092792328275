<template>
<div>
    <div class="chatBox" ref="chatBox" v-show="isCustomer">
        <div v-for="(item, index) in chatList" :key="index">
            <div class="leftItem" v-if="!item.isCustomer">
                <img style="width:50px;height:50px;margin-top:20px;" src="@/assets/img/kefuImg.png" alt="">
                <div class="info">
                    <div class="title">
                        <!-- <span style="font-weight: bold;color: #475669;">{{item.createUser}}</span> -->
                        <span style="color: #8492A6;margin-left:10px;">{{item.createTime}}</span>
                    </div>
                    <div class="message">
                        <div class="triangle"></div>
                        <div>{{item.message}}</div>
                        <div v-for="url in item.imgList" :key="url">
                            <el-image style="width:100%;height:100%;" :src="url" :preview-src-list="item.imgList"></el-image>
                        </div>
                    </div>
                </div>
            </div>
            <div class="rightItem" v-else>
                <img style="width:40px;height:40px;margin-top:20px;" src="@/assets/img/headImg.png" alt="">
                <div class="info">
                    <div class="title">
                        <!-- <span style="font-weight: bold;color: #475669;">{{item.createUser}}</span> -->
                        <span style="color: #8492A6;margin-right:10px;">{{item.createTime}}</span>
                    </div>
                    <div class="message">
                        <div class="triangle"></div>
                        <div>{{item.message}}</div>
                        <div v-for="url in item.imgList" :key="url">
                            <el-image style="width:100%;height:100%;" :src="url" :preview-src-list="item.imgList"></el-image>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="chatBox" ref="chatBox" v-show="!isCustomer">
        <div v-for="(item, index) in chatList" :key="index">
            <div class="leftItem" v-if="item.isCustomer">
                <img style="width:40px;height:40px;margin-top:20px;" src="@/assets/img/headImg.png" alt="">
                <div class="info">
                    <div class="title">
                        <!-- <span style="font-weight: bold;color: #475669;">{{item.createUser}}</span> -->
                        <span style="color: #8492A6;margin-left:10px;">{{item.createTime}}</span>
                    </div>
                    <div class="message">
                        <div class="triangle"></div>
                        <div>{{item.message}}</div>
                        <div v-for="url in item.imgList" :key="url">
                            <el-image style="width:100%;height:100%;" :src="url" :preview-src-list="item.imgList"></el-image>
                        </div>
                    </div>
                </div>
            </div>
            <div class="rightItem" v-else>
                <img style="width:50px;height:50px;margin-top:20px;" src="@/assets/img/kefuImg.png" alt="">
                <div class="info">
                    <div class="title">
                        <!-- <span style="font-weight: bold;color: #475669;">{{item.createUser}}</span> -->
                        <span style="color: #8492A6;margin-right:10px;">{{item.createTime}}</span>
                    </div>
                    <div class="message">
                        <div class="triangle"></div>
                        <div>{{item.message}}</div>
                        <div v-for="url in item.imgList" :key="url">
                            <el-image style="width:100%;height:100%;" :src="url" :preview-src-list="item.imgList"></el-image>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: ["chatList","isCustomer"],
    data() {
        return {};
    },
    methods: {},
    mounted() {
        //聊天记录自动滚动到底部
        this.$nextTick(() =>{
            setTimeout(() => {
                this.$refs.chatBox.scrollTop = this.$refs.chatBox.scrollHeight;
            }, 500);
        })
    },
};
</script>

<style lang="scss" scoped>
.chatBox {
    height: calc(100vh - 450px);
    overflow: auto;
	scroll-behavior: smooth;
    .leftItem {
        display: flex;
        margin-top: 20px;
        margin-left: 20px;
        margin-bottom: 20px;
        .info {
            margin-left: 25px;
            .title {
                display: flex;
                font-size: 14px;
                line-height: 24px;
                margin-bottom: 8px;
            }
            .message {
                max-width: 500px;
                padding: 10px;
                background: #FFFFFF;
                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
                border-radius: 5px;
                font-size: 14px;
                line-height: 24px;
                color: #0a0a0a;
                position: relative;
                .triangle {
                    position: absolute;
                    width: 0px;
                    height: 0px;
                    border: 10px solid #FFFFFF;
                    border-top-color: transparent;
                    border-bottom-color: transparent;
                    border-left-color: transparent;
                    top: 10px;
                    left: -20px;
                }
            }
        }
    }
    .rightItem {
        display: flex;
        flex-direction: row-reverse;
        margin-top: 20px;
        margin-right: 20px;
        margin-bottom: 20px;
        .info {
            margin-right: 25px;
            .title {
                display: flex;
                flex-direction: row-reverse;
                font-size: 14px;
                line-height: 24px;
                margin-bottom: 8px;
            }
            .message {
                max-width: 500px;
                padding: 10px;
                background: #E1F4FF;
                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
                border-radius: 5px;
                font-size: 14px;
                line-height: 24px;
                color: #0a0a0a;
                position: relative;
                .triangle {
                    position: absolute;
                    width: 0px;
                    height: 0px;
                    border: 10px solid #E1F4FF;
                    border-top-color: transparent;
                    border-bottom-color: transparent;
                    border-right-color: transparent;
                    top: 10px;
                    right: -20px;
                }
            }
        }
    }
}
</style>