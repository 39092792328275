<template>
    <div class="CustomerNav">
        <div class="navItem" @click="chooseNav('/customerManage/myCustomer')" :class="{ active: currentPath === '/customerManage/myCustomer' }">
            <span>我的客户</span>
            <span style="margin-left: 5px;">({{ navNumObj.myCustomerNum || "无" }})</span>
        </div>
        <div class="navItem" @click="chooseNav('/customerManage/seasCustomer')" :class="{ active: currentPath === '/customerManage/seasCustomer' }">
            <span>公海客户</span>
            <span style="margin-left: 5px;">({{ navNumObj.publicCustomerNum || "无" }})</span>
        </div>
        <div class="navItem" @click="chooseNav('/customerManage/perspCustomer')" :class="{ active: currentPath === '/customerManage/perspCustomer' }">
            <span>透视客户</span>
        </div>
        <div class="navItem" @click="chooseNav('/customerManage/dynamic')" :class="{ active: currentPath === '/customerManage/dynamic' }">
            <span>客户动态</span>
            <span style="margin-left: 5px;">({{ navNumObj.followRecordNum || "无" }})</span>
        </div>
        <div class="navItem" @click="chooseNav('/customerManage/recycle')" :class="{ active: currentPath === '/customerManage/recycle' }">
            <span>客户回收站</span>
            <span style="margin-left: 5px;">({{ navNumObj.trashDepotNum || "无" }})</span>
        </div>
    </div>
</template>

<script>
export default {
    props: ["currentPath"],
    data() {
        return {
            navNumObj:{
                myCustomerNum: 0,
                publicCustomerNum: 0,
                followRecordNum: 0,
                trashDepotNum: 0,
            }
        };
    },
    methods: {
        chooseNav(index) {
            this.$emit("chooseNav",index);
        },

        //导航标记数量
        getNum(){
            this.$axios.get(`${this.$customerUrl}/Company/GetCustomerMenuNum`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.navNumObj = data;
                    localStorage.setItem("customerNavNum",JSON.stringify(data));
                }
            });
        },
    },
    mounted() {
        if(this.currentPath == "/customerManage/myCustomer"){
            this.getNum();
        }else if(localStorage.customerNavNum){
            this.navNumObj = JSON.parse(localStorage.customerNavNum);
        }else{
            this.getNum();
        }
    },
};
</script>

<style lang="scss" scoped>
@import '@/scss/common.scss';
.CustomerNav {
    width: 200px;
    height: calc(100vh - 140px);
    background: #ffffff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    .oneClass{
        padding: 5px 15px;
        height: 24px;
        line-height: 24px;
        font-size: 12px;
        color: #aaa;
    }

    .navItem {
        cursor: pointer;
        padding: 0 15px;
        height: 40px;
        line-height: 40px;
        color: #000;
        font-size: 14px;
        &.active {
            color: #fff;
            background: $themeColor;
        }
        img{
            vertical-align: text-bottom;
            margin-right: 5px;
        }
    }
}
</style>