<script>
import { defineComponent } from '@vue/composition-api'
import Flow from './BusinessSet/Flow.vue'
import Reason from './BusinessSet/Reason.vue'
// import Currency from './BusinessSet/Currency.vue'
export default defineComponent({
	components: {
		Flow,
		Reason,
		// Currency
	}
})
</script>

<template>
	<div class="view">
		<el-tabs>
			<el-tab-pane label="商机流程设置">
				<Flow />
			</el-tab-pane>
			<el-tab-pane label="输单原因">
				<Reason />
			</el-tab-pane>
			<!-- <el-tab-pane label="货币">
				<Currency />
			</el-tab-pane> -->
		</el-tabs>
	</div>
</template>

<style scoped lang="scss">
.view {
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	padding: 0 20px 20px;
}
::v-deep .el-tabs {
	height: 100%;
}
::v-deep .el-tabs__content {
	height: calc(100% - 55px);
}
::v-deep .el-tab-pane {
	height: 100%;
}
</style>
