<script>
import Vue from 'vue'
import router from "@/router";
import { reactive, ref } from '@vue/composition-api'
import axios from '@/assets/js/axios'
import dayjs from 'dayjs'
import qs from 'qs'
import HuaXiangVue from './components/HuaXiang.vue'
import KanXinVue from './components/KanXin.vue'
import { Message } from 'element-ui'
export default Vue.extend({
  components: { HuaXiangVue, KanXinVue },
  setup() {
    const url = window.location.href
    const index = url.lastIndexOf('?')
    const campaignId = qs.parse(url.slice(index + 1))['campaignId'] || ''

    const panelData = reactive({
        taskName: '',
        sentTime: '',
        createTime: '',
        updateTime: '',
        senderName: '',
        senderAddress: '',
        sentCount: 0,
        successCount: 0,
        failCount: 0,
        openCount: 0,
        letterReadingRate: '',
        loading: false,
    });
    const getTaskDetail = async () => {
      const res = await axios({
        method: 'GET',
        url: Vue.prototype.$marketUrl + '/MassSending/GetTaskDetail',
        params: {
          campaignId
        }
      })
      const data = res.data
      document.title = `营销模块-统计报告[${data.taskName}]`;
      panelData.taskName = data.taskName;
      panelData.sentTime = data.sentTime;
      panelData.createTime = data.createTime;
      panelData.updateTime = data.updateTime;
      panelData.senderName = `${data.senderName}<${data.senderAddress}>`;
      panelData.senderAddress = data.senderAddress;
      panelData.sentCount = data.sentCount
      panelData.successCount = data.successCount
      panelData.failCount = data.failCount
      panelData.openCount = data.openCount
      panelData.letterReadingRate = data.letterReadingRate
    }

    getTaskDetail();

    const mode = ref('1')

    const addressee = reactive({
      loading: false,
      list: [],
      page: {
        total: 0,
        index: 1,
        size: 10
      }
    })
    const newFilterForm = reactive({
        recipientStatus: 0, //收件人状态 0 全部 1 多次看信 2 已查看邮件 3 点击链接 4 投递成功 5 发信失败 6 取消订阅
        date: 0, //0 全部 1 3日内已看信 2 7日内已看信 3 30日内已看信
        sort: 0, //排序 0 看信时间 1 收件人邮箱
        addressee: "",
    })
    const getAddresseeList = async () => {
      try {
        addressee.loading = true
        const res = await axios({
          method: 'POST',
          url: Vue.prototype.$marketUrl + '/MassSending/GetRecipients',
          data: {
            pageIndex: addressee.page.index,
            pageSize: addressee.page.size,
            campaignId: Number(campaignId),
            ...newFilterForm,
          }
        })
        addressee.list = res.data.datas
        addressee.page.total = res.data.totalItems
      } catch { null } finally {
        addressee.loading = false
      }
    }
    getAddresseeList()
    const onFilter = () =>{
        addressee.page.index = 1;
        getAddresseeList()
    }

    //再次群发
    const againSendJump = () =>{
        let sendParamTwo = {
            pageIndex: 1,
            pageSize: 5000,
            campaignId: Number(campaignId),
            ...newFilterForm,
        }
        localStorage.setItem("sendParamTwo",JSON.stringify(sendParamTwo));
        let routeData = router.resolve({
            path: '/marketing/MailMarketing',
            query: { againSendType: 2 },
        });
        window.open(routeData.href, "_blank");
    }


    //获取收件人状态下拉框
    const statusOption = ref([]);
    const getStatusBox = async () => {
        try {
            const res = await axios({
                method: 'GET',
                url: Vue.prototype.$marketUrl + '/MassSending/GetRecipientStatusBox',
                params: {
                    campaignId
                }
            })
            statusOption.value = res.data;
        } catch { null }
    }
    getStatusBox();

    const mailObj = reactive({
        mailTitle: "",
        mailContent: "",
    })

    const getHtmlBody = async () => {
        try {
            const res = await axios({
                method: 'GET',
                url: Vue.prototype.$marketUrl + '/MassSending/GetHtmlBody',
                params: {
                    campaignId
                }
            })
            mailObj.mailTitle = res.data.subject;
            mailObj.mailContent = res.data.htmlBody;
        } catch { null }
    }
    getHtmlBody();

    const refreshTime = async() =>{
        panelData.loading = true;
        const res = await axios({
            method: 'GET',
            url: Vue.prototype.$marketUrl + '/MassSending/UpdateReport',
            params: {
                campaignId
            }
        })
        panelData.loading = false;
        if(res.isSuccess){
            getTaskDetail();
            getAddresseeList();
            handleLoad();
        }
    }
    const copyText = (value) =>{
        const input = document.createElement('input');
        document.body.appendChild(input);
        input.setAttribute('value', value);
        input.select();
        if (document.execCommand('copy')) {
            document.execCommand('copy');
        }
        document.body.removeChild(input);
        Message.success('复制成功')
    }

    const timer = ref();

    const handleLoad = () => {
        // 刷新子组件
        timer.value = new Date().getTime()
    }

    return {
      panelData,
      mode,
      campaignId,
      addressee,
      getTaskDetail,
      getAddresseeList,
      mailObj,
      getHtmlBody,
      dayjs,
      refreshTime,
      copyText,
      timer,
      handleLoad,
      statusOption,
      newFilterForm,
      onFilter,
      againSendJump,
    }
  }
})
</script>

<template>
  <div style="width: 100%; height: 100%">
    <div class="view">
      <div>
        <p style="font-weight: bold">{{panelData.taskName}}</p>
        <div style="display: flex; align-items: center; padding: 20px 0; padding-left: 30px">
          <el-descriptions :column="1" :labelStyle="{ color: '#B1B1B1' }" :contentStyle="{ color: '#434343' }">
            <el-descriptions-item label="发信时间">
                {{panelData.sentTime ? dayjs(panelData.sentTime).format('MM-DD HH:mm') : "--"}}
            </el-descriptions-item>
            <el-descriptions-item label="发送人" content-class-name="senderName">
                {{ panelData.senderName }}
                <el-tooltip effect="dark" content="复制" placement="bottom">
                    <i class="el-icon-copy-document" @click="copyText(panelData.senderAddress)"></i>
                </el-tooltip>
            </el-descriptions-item>
            <el-descriptions-item label="创建时间">
                {{panelData.createTime ? dayjs(panelData.createTime).format('MM-DD HH:mm') : "--"}}
            </el-descriptions-item>
            <el-descriptions-item label="报告更新时间">
                {{panelData.updateTime ? dayjs(panelData.updateTime).format('MM-DD HH:mm') : "--"}}
                <el-tooltip effect="dark" content="更新" placement="bottom">
                    <i class="el-icon-loading" v-if="panelData.loading"></i>
                    <i class="el-icon-refresh" v-if="!panelData.loading" @click="refreshTime"></i>
                </el-tooltip>
            </el-descriptions-item>
          </el-descriptions>
          <el-descriptions :column="5" direction="vertical" :labelStyle="{ color: '#B1B1B1' }">
            <el-descriptions-item label="收件人数" :contentStyle="{ color: '#434343', fontSize: '18px' }">
                {{ panelData.sentCount || '--' }}
            </el-descriptions-item>
            <el-descriptions-item label="送达总数" :contentStyle="{ color: '#13CE66', fontSize: '18px' }">
                {{ panelData.successCount || '--' }}
            </el-descriptions-item>
            <el-descriptions-item label="打开人数" :contentStyle="{ color: '#FA5151', fontSize: '18px' }">
                {{ panelData.openCount || '--' }}
            </el-descriptions-item>
            <el-descriptions-item label="看信率" :contentStyle="{ color: '#FA5151', fontSize: '18px' }">
                {{ panelData.letterReadingRate ? panelData.letterReadingRate+'%' : '--' }}
            </el-descriptions-item>
            <el-descriptions-item label="取消订阅数" :contentStyle="{ color: '#FF9707', fontSize: '18px' }">
                {{ panelData.unsubscribeTotalCount || '--' }}
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </div>
      <div style="height: calc(100% - 200px)">
        <el-tabs v-model="mode">
          <el-tab-pane label="收件人" name="1" />
          <el-tab-pane label="群发画像" name="2" />
          <el-tab-pane label="看信记录" name="3" />
          <el-tab-pane label="邮件内容" name="4" />
        </el-tabs>
        <div class="content-wrapper">
          <div v-show="mode === '1'" v-loading="addressee.loading" class="content">
            <div style="display: flex;justify-content: space-between;margin-bottom: 10px;">
                <div style="height: 32px;display: flex;align-items: center;">
                    <el-input v-model="newFilterForm.addressee" @change="onFilter" style="width:220px;" placeholder="收件人" size="small"></el-input>
                    <span style="font-size: 14px;color: #6a6a6a;margin-left: 20px;">状态：</span>
                    <el-select v-model="newFilterForm.recipientStatus" style="width:180px;" size="small" @change="onFilter">
                        <el-option v-for="item in statusOption" :key="item.key" :label="item.name" :value="item.key">
                            <span>{{ item.name }}</span>
                            <span>({{ item.count }})</span>
                        </el-option>
                    </el-select>
                    <span style="font-size: 14px;color: #6a6a6a;margin-left: 20px;">时间：</span>
                    <el-select v-model="newFilterForm.date" style="width:180px;" size="small" @change="onFilter">
                        <el-option label="全部" :value="0"></el-option>
                        <el-option label="3日内已看信" :value="1"></el-option>
                        <el-option label="7日内已看信" :value="2"></el-option>
                        <el-option label="30日内已看信" :value="3"></el-option>
                    </el-select>
                    <span style="font-size: 14px;color: #6a6a6a;margin-left: 20px;">排序：</span>
                    <el-select v-model="newFilterForm.sort" style="width:180px;" size="small" @change="onFilter">
                        <el-option label="看信时间" :value="0"></el-option>
                        <el-option label="收件人邮箱" :value="1"></el-option>
                    </el-select>
                </div>
                <el-button type="primary" plain size="small" @click="againSendJump">再次群发</el-button>
            </div>
            <div style="height: 35px; background: #F9F9F9; display: flex; align-items: center; color: #475669; font-weight: bold">
              <div style="box-sizing: border-box; width: 35%; padding-left: 30px">收件人</div>
              <div style="width: 65%; text-align: center">状态</div>
            </div>
            <div style="height: calc(100% - 90px); overflow-y: auto">
              <div class="item" style="padding-left: 30px" v-for="item of addressee.list" :key="item.senderAddress">
                <div style="width: 35%; display: flex; align-items: center">
                  <img src="@/assets/img/headImg.png" style="width: 50px; height: 50px" />
                  <div style="padding-left: 20px; font-size: 14px">
                    <p style="color: #1F2D3D; font-weight: bold">{{ item.name }}</p>
                    <p class="senderName">
                        {{ item.senderAddress }}
                        <el-tooltip effect="dark" content="复制" placement="bottom">
                            <i class="el-icon-copy-document" @click="copyText(item.senderAddress)"></i>
                        </el-tooltip>
                    </p>
                  </div>
                </div>
                <div style="width: 65%; display: flex; align-items: center">
                  <el-descriptions :column="2" :labelStyle="{ color: '#1F2D3D' }" :contentStyle="{ color: '#1F2D3D' }">
                    <el-descriptions-item label="职位">{{ item.position || '--' }}</el-descriptions-item>
                    <el-descriptions-item label="看信次数">{{ item.opneCount || '--' }}</el-descriptions-item>
                    <el-descriptions-item label="公司">{{ item.companyName || '--' }}</el-descriptions-item>
                    <el-descriptions-item label="看信时间">{{ item.opneTime || '--' }}</el-descriptions-item>
                  </el-descriptions>
                  <div style="flex-shrink: 0; font-size: 14px; padding-right: 35px">
                    <i v-if="item.isSuccess" class="el-icon-check" style="color: #13CE66; font-weight: bold"></i>
                    <i v-else class="el-icon-close" style="color: #FA5151; font-weight: bold"></i>
                    <span style="margin-left: 5px">{{ item.isSuccess ? '发送成功' : '发送失败' }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div style="display: flex; justify-content: flex-end">
              <el-pagination :current-page.sync="addressee.page.index" :page-size="addressee.page.size" :total="addressee.page.total" @current-change="getAddresseeList" background layout="total, prev, pager, next, jumper" />
            </div>
          </div>
          <div v-show="mode === '2'" class="content">
            <HuaXiangVue :campaignId="campaignId" :key="timer" />
          </div>
          <div v-show="mode === '3'" class="content">
            <KanXinVue :campaignId="campaignId" :key="timer" />
          </div>
          <div v-show="mode === '4'" class="content" style="overflow-y: auto">
                <div style="font-weight: bold;">{{mailObj.mailTitle}}</div>
                <div v-html="mailObj.mailContent"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.view {
  box-sizing: border-box;
  padding: 20px;
  width: 100%;
  height: 100%;
  background: #fff;

  ::v-deep .el-tabs__nav-wrap::after {
    display: none;
  }
}

.content-wrapper {
  height: calc(100% - 54px);
}

.content {
  height: 100%;
}

.item {
  box-sizing: border-box;
  border-bottom: 1px solid #D3DCE6;
  display: flex;
  padding: 20px 0;
}
.el-icon-refresh,.el-icon-loading,.el-icon-copy-document{
    cursor: pointer;
    padding-left: 5px;
    font-size: 16px;
    color: var(--themeColor);
}
.senderName{
    cursor: pointer;
    .el-icon-copy-document{
        display: none;
    }
    &:hover .el-icon-copy-document{
        display: inline-block;
    }
}
</style>
