<template>
    <div>
        <div class="title">个性主题</div>
        <ThemePicker @change="themeChange"></ThemePicker>
    </div>
</template>

<script>
import ThemePicker from '@/components/ThemePicker.vue';
export default {
    components:{ThemePicker},
    data() {
        return {

        };
    },
    methods: {
        //切换主题色
        themeChange(val){
            this.$store.dispatch('changeSetting',{
                key: 'theme',
                value: val
            })
            this.$emit('change', val)
        },
    },
    mounted() {

    },
};
</script>

<style lang="scss" scoped>
.title{
    height: 24px;
    font-size: 24px;
    font-weight: bold;
    color: #3A3A3A;
    margin-bottom: 20px;
}
</style>