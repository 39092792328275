<template>
<div>
    <div v-if="ie=='i'">
        <!-- 委内瑞拉(VEN)进口提单明细 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th width="25%">清单号码</th>
                        <td width="25%">{{billInfo.Bl}}</td>
                        <th width="25%">出口商所在国</th>
                        <td width="25%">{{billInfo.AdqCountry}}</td>
                    </tr>
                    <tr>
                        <th>6位编码</th>
                        <td>{{billInfo.Chapter}}</td>
                        <th>6位编码描述</th>
                        <td>{{billInfo.ChapterDes}}</td>
                    </tr>
                    <tr>
                        <th>毛重</th>
                        <td>{{billInfo.Weight}}</td>
                        <th>净重</th>
                        <td>{{billInfo.NetWeight}}</td>
                    </tr>
                    <tr>
                        <th>FOB总价（美元）</th>
                        <td>{{billInfo.UsFob}}</td>
                        <th>CIF总价（美元）</th>
                        <td>{{billInfo.Value}}</td>
                    </tr>
                    <tr>
                        <th>FOB总价(玻利瓦尔)</th>
                        <td>{{billInfo.BolivaresFob}}</td>
                        <th>CIF总价(玻利瓦尔)</th>
                        <td>{{billInfo.BolivaresCif}}</td>
                    </tr>
                    <tr>
                        <th>CIF单价（美元）</th>
                        <td>{{billInfo.UsCif}}</td>
                        <th>汇率</th>
                        <td>{{billInfo.ExchangeRate}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">运输信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">起运港</th>
                        <td width="25%">{{billInfo.EmbarqPort}}</td>
                        <th width="25%">来源国</th>
                        <td width="25%">{{billInfo.Country}}</td>
                    </tr>
                    <tr>
                        <th>采购国</th>
                        <td>{{billInfo.BuyCountry}}</td>
                        <th>运输方式</th>
                        <td>{{billInfo.TransportType}}</td>
                    </tr>
                    <tr>
                        <th>运费(玻利瓦尔)</th>
                        <td>{{billInfo.BolivaresFreight}}</td>
                        <th>保险(玻利瓦尔)</th>
                        <td>{{billInfo.BolivaresInsurance}}</td>
                    </tr>
                    <tr>
                        <th>海关</th>
                        <td>{{billInfo.Customs}}</td>
                        <th>记录号</th>
                        <td>{{billInfo.Record}}</td>
                    </tr>
                    <tr>
                        <th>公司类型</th>
                        <td>{{billInfo.CompanyClass}}</td>
                        <th>卖方的身份</th>
                        <td>{{billInfo.SellerCondition}}</td>
                    </tr>
                    <tr>
                        <th>政体</th>
                        <td>{{billInfo.Regime}}</td>
                        <th>旗帜</th>
                        <td>{{billInfo.Flag}}</td>
                    </tr>
                    <tr>
                        <th>链接类</th>
                        <td>{{billInfo.VinculationClass}}</td>
                        <th>商业水平</th>
                        <td>{{billInfo.CommercialLevel}}</td>
                    </tr>
                    <tr>
                        <th>机构</th>
                        <td>{{billInfo.Agency}}</td>
                        <th>集装箱号</th>
                        <td>{{billInfo.Container}}</td>
                    </tr>
                    <tr>
                        <th>集装箱总数</th>
                        <td>{{billInfo.TotalContainer}}</td>
                        <th>进出口类型</th>
                        <td>{{billInfo.Type}}</td>
                    </tr>
                    <tr>
                        <th>城市</th>
                        <td>{{billInfo.City}}</td>
                        <th>吨数</th>
                        <td>{{billInfo.Tons}}</td>
                    </tr>
                    <tr>
                        <th>航班号</th>
                        <td colspan="3">{{billInfo.Fligth}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
    <div v-if="ie=='e'">
        <!-- 委内瑞拉(VEN)出口提单明细 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th width="25%">清单号码</th>
                        <td width="25%">{{billInfo.Bl}}</td>
                        <th width="25%">汇率</th>
                        <td width="25%">{{billInfo.ExchangeRate}}</td>
                    </tr>
                    <tr>
                        <th>6位编码</th>
                        <td>{{billInfo.Chapter}}</td>
                        <th>6位编码描述</th>
                        <td>{{billInfo.ChapterDes}}</td>
                    </tr>
                    <tr>
                        <th>毛重</th>
                        <td>{{billInfo.Weight}}</td>
                        <th>净重</th>
                        <td>{{billInfo.NetWeight}}</td>
                    </tr>
                    <tr>
                        <th>FOB总价（美元）</th>
                        <td>{{billInfo.Value}}</td>
                        <th>FOB总价(玻利瓦尔)</th>
                        <td>{{billInfo.BolivaresFob}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">运输信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">起运港</th>
                        <td width="25%">{{billInfo.DestPort}}</td>
                        <th width="25%">目的国</th>
                        <td width="25%">{{billInfo.Country}}</td>
                    </tr>
                    <tr>
                        <th>运输方式</th>
                        <td>{{billInfo.TransportType}}</td>
                        <th>海关</th>
                        <td>{{billInfo.Customs}}</td>
                    </tr>
                    <tr>
                        <th>记录号</th>
                        <td>{{billInfo.Record}}</td>
                        <th>公司类型</th>
                        <td>{{billInfo.CompanyClass}}</td>
                    </tr>
                    <tr>
                        <th>政体</th>
                        <td>{{billInfo.Regime}}</td>
                        <th>旗帜</th>
                        <td>{{billInfo.Flag}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
</div>
</template>

<script>
export default {
    props:["billInfo","ie"],
    data() {
        return {

        };
    },
    methods: {
        
    },
    mounted() {

    },
};
</script>

<style lang="scss" scoped>
.partItem {
    margin-bottom: 20px;
    /deep/.el-card__header {
        padding: 10px 20px;
        .headTitle {
            display: inline-block;
            padding-left: 10px;
            font-size: 14px;
            border-left: 2px solid var(--themeColor);
            font-weight: bold;
        }
    }
}
table {
    width: 100%;
    border-collapse: collapse;
    tr {
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;
        th,
        td {
            font-size: 12px;
            color: #666;
            height: 40px;
            border: 1px solid #eaeaea;
            text-align: center;
        }
        th {
            background-color: #f5f7fa;
            font-weight: 700;
        }
        .bg {
            background-color: var(--themeColor);
            color: #fff;
            font-weight: 700;
            opacity: 0.8;
        }
    }
}
</style>