<template>
    <div class="performanceTarget">
        <!-- <div class="header">
            <div>
                <span class="label">团队名称：</span>
                <span class="value">{{teamName}}</span>
            </div>
            <div style="margin-left:20px;">
                <span class="label">团队负责人：</span>
                <span class="value">{{principal}}</span>
            </div>
            <el-date-picker v-model="currentDate" type="month" size="mini" value-format="yyyy-MM" :clearable="false"
                @change="monthChange" v-if="isShowSetBtn" style="margin-left:20px;width: 120px;">
            </el-date-picker>
        </div> -->
        <el-card>
            <div slot="header">
                <span>本月绩效目标</span>
                <el-tooltip effect="dark" content="刷新" placement="top">
                    <el-button style="float:right;" icon="el-icon-refresh" size="mini"  @click="getList"></el-button>
                </el-tooltip>
            </div>
            <div v-loading="tableLoading" style="min-height:100px;">
                <el-row :gutter="16" class="memberItem" v-for="(item,index) in memberList" :key="index">
                    <el-col :span="4" class="name">{{item.name}}</el-col>
                    <el-col :span="5" class="target">
                        <div class="number">{{item.targetMoney}}</div>
                        <div class="text">本月目标成交金额</div>
                    </el-col>
                    <el-col :span="5" class="finish">
                        <div class="number">
                            <span>{{item.finishMoney}}</span>
                            <span v-if="item.setFinishMoney > 0">({{item.setFinishMoney}})</span>
                        </div>
                        <div class="text">
                            <span>本月完成成交金额</span>
                            <span v-if="isShowSetBtn" class="setting" @click="toSet(item)">设置</span>
                        </div>
                    </el-col>
                    <el-col :span="6" style="margin-top:12px;">
                        <el-progress :percentage="item.finishRatio"></el-progress>
                    </el-col>
                    <el-col :span="4" style="margin-top:12px;">
                        <!-- 状态：0 未设置目标 1 未完成目标 2 已完成目标 -->
                        <el-tag class="eltag" v-show="item.currentState == 0" size="small" type="danger" effect="dark">未设置目标</el-tag>
                        <el-tag class="eltag" v-show="item.currentState == 1" size="small" type="warning" effect="dark">未完成目标</el-tag>
                        <el-tag class="eltag" v-show="item.currentState == 2" size="small" type="success" effect="dark">已完成目标</el-tag>
                    </el-col>
                </el-row>
            </div>
        </el-card>

        <el-dialog title="设置成交金额" :visible.sync="setVisible" width="500px">
            <span>{{setName}}成交金额：</span>
            <el-input-number v-model="setAmount" :min="0"></el-input-number>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" @click="setSubmit">确 认</el-button>
                <el-button size="small" @click="setVisible = false">取 消</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
export default {
    data() {
        return {
            teamName: "",   //所属团队
            principal: "",  //团队负责人
            tableLoading: false,
            memberList: [],
            isShowSetBtn: false,
            year: "",
            month: "",
            currentDate: "",
            //设置成交金额(特殊功能)
            setVisible: false,
            setUkey: "",
            setName: "",
            setAmount: 0,
        };
    },
    methods: {
        //选择月份
        monthChange(value){
            this.year = value.split('-')[0];
            this.month = value.split('-')[1];
            this.getList();
        },

        //获取成员绩效列表
        getList(){
            this.tableLoading = true;
            this.$axios.get(`${this.$customerUrl}/Targets/GetFinishTargets?year=${this.year}&month=${this.month}`).then(res => {
                const {isSuccess,data} = res;
                this.tableLoading = false;
                if(isSuccess){
                    this.memberList = data.finishTargets;
                    this.teamName = data.department;
                    this.principal = data.principal;
                    //计算比例
                    this.memberList.forEach(ele =>{
                        if(ele.setFinishMoney > 0){
                            ele.finishRatio = this.getRatio(ele.setFinishMoney,ele.targetMoney);
                        }else{
                            ele.finishRatio = this.getRatio(ele.finishMoney,ele.targetMoney);
                        }
                    })
                }
            });
        },

        //计算比例
        getRatio(numOne,numTwo){
            let ratio = 0;
            if(numTwo == 0){
                ratio = 0;
            }else{
                ratio = (numOne / numTwo) * 100;
            }
            return ratio >= 100 ? 100 : ratio.toFixed(0) * 1;
        },

        //设置成交金额
        toSet(item){
            this.setVisible = true;
            this.setUkey = item.ukey;
            this.setName = item.name;
            this.setAmount = 0;
        },
        setSubmit(){
            if(this.setAmount == 0){
                this.$message({ message: '设置金额不能为0', type: 'warning' });
                return false;
            }
            this.$axios.get(`${this.$customerUrl}/Targets/SetFinishMoney?ukey=${this.setUkey}&amount=${this.setAmount}`).then(res => {
                const {isSuccess,data} = res;
                if(isSuccess){
                    this.setVisible = false;
                    this.$message({ message: '设置完成', type: 'success' });
                    this.getList();
                }
            });
        }

    },
    mounted() {
        this.currentDate = new Date();
        this.year = new Date().getFullYear();
        let month = new Date().getMonth() + 1;
        if (month < 10) {
            this.month = "0" + month;
        } else {
            this.month = "" + month;
        }
        //获取成员绩效列表
        this.getList(); 
        this.isShowSetBtn = JSON.parse(localStorage.userInfo).isAdmin;
    },
};
</script>

<style lang="scss" scoped>
.performanceTarget {
    .header{
        padding: 15px;
        height: 32px;
        line-height: 32px;
        background-color: #fff;
        display: flex;
        .label{
            color: #666;
        }
        .value{
            color: #000;
            font-weight: bold;
            font-size: 14px;
        }
    }
    .memberItem{
        display: flex;
        padding: 16px 0;
        border-bottom: 1px solid #ddd;
        .name{
            font-size: 18px;
            font-weight: bold;
        }
        .target,.finish{
            .number{
                color: #2a2a2a;
                font-size: 22px;
                font-weight: bold;
            }
            .text{
                color: #909090;
                font-size: 14px;
            }
        }
        .eltag{
            float: right;
        }
        .setting{
            padding-left: 10px;
            color: #E6A23C;
            cursor: pointer;
        }
    }
}
</style>