<template>
    <div class="setTarget">
        <div class="header">
            <div style="display: flex;align-items: center;">
                <span style="font-weight: 600;">设定成员目标</span>
                <el-select v-model="selectYear" @change="yearChange" size="mini" style="width: 90px;margin-left:20px;">
                    <el-option v-for="item in yearList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
            </div>
            <div>
                <div v-show="!isEditing">
                    <el-button type="primary" @click="isEditing = true;" size="small">设定目标</el-button>
                </div>
                <div v-show="isEditing">
                    <el-button type="primary" @click="setFinish" size="small">保 存</el-button>
                    <el-button @click="setCancel" size="small">取 消</el-button>
                </div>
            </div>
        </div>
        <el-table style="width:100%;" height="calc(100vh - 200px)" :data="targetList" show-summary v-loading="tableLoading" stripe size="small">
            <el-table-column prop="name" label="成员名称" fixed></el-table-column>
            <el-table-column prop="department" label="所属部门" fixed></el-table-column>
            <el-table-column prop="allYear" label="全年" fixed></el-table-column>
            <el-table-column prop="january" label="1月" width="90px">
                <template slot-scope="scope">
                    <el-input-number v-if="isEditing" v-model="scope.row.january" style="width:80px" @change="scope.row.isChange=true;"
                        size="mini" :controls="false" :min="0"></el-input-number>
                    <span v-else>{{scope.row.january}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="february" label="2月" width="90px">
                <template slot-scope="scope">
                    <el-input-number v-if="isEditing" v-model="scope.row.february" style="width:80px" @change="scope.row.isChange=true;"
                        size="mini" :controls="false" :min="0"></el-input-number>
                    <span v-else>{{scope.row.february}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="march" label="3月" width="90px">
                <template slot-scope="scope">
                    <el-input-number v-if="isEditing" v-model="scope.row.march" style="width:80px" @change="scope.row.isChange=true;"
                        size="mini" :controls="false" :min="0"></el-input-number>
                    <span v-else>{{scope.row.march}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="firstQuarter" label="第一季度"></el-table-column>
            <el-table-column prop="april" label="4月" width="90px">
                <template slot-scope="scope">
                    <el-input-number v-if="isEditing" v-model="scope.row.april" style="width:80px" @change="scope.row.isChange=true;"
                        size="mini" :controls="false" :min="0"></el-input-number>
                    <span v-else>{{scope.row.april}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="may" label="5月" width="90px">
                <template slot-scope="scope">
                    <el-input-number v-if="isEditing" v-model="scope.row.may" style="width:80px" @change="scope.row.isChange=true;"
                        size="mini" :controls="false" :min="0"></el-input-number>
                    <span v-else>{{scope.row.may}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="june" label="6月" width="90px">
                <template slot-scope="scope">
                    <el-input-number v-if="isEditing" v-model="scope.row.june" style="width:80px" @change="scope.row.isChange=true;"
                        size="mini" :controls="false" :min="0"></el-input-number>
                    <span v-else>{{scope.row.june}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="secondQuarter" label="第二季度"></el-table-column>
            <el-table-column prop="july" label="7月" width="90px">
                <template slot-scope="scope">
                    <el-input-number v-if="isEditing" v-model="scope.row.july" style="width:80px" @change="scope.row.isChange=true;"
                        size="mini" :controls="false" :min="0"></el-input-number>
                    <span v-else>{{scope.row.july}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="august" label="8月" width="90px">
                <template slot-scope="scope">
                    <el-input-number v-if="isEditing" v-model="scope.row.august" style="width:80px" @change="scope.row.isChange=true;"
                        size="mini" :controls="false" :min="0"></el-input-number>
                    <span v-else>{{scope.row.august}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="september" label="9月" width="90px">
                <template slot-scope="scope">
                    <el-input-number v-if="isEditing" v-model="scope.row.september" style="width:80px" @change="scope.row.isChange=true;"
                        size="mini" :controls="false" :min="0"></el-input-number>
                    <span v-else>{{scope.row.september}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="thirdQuarter" label="第三季度"></el-table-column>
            <el-table-column prop="october" label="10月" width="90px">
                <template slot-scope="scope">
                    <el-input-number v-if="isEditing" v-model="scope.row.october" style="width:80px" @change="scope.row.isChange=true;"
                        size="mini" :controls="false" :min="0"></el-input-number>
                    <span v-else>{{scope.row.october}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="november" label="11月" width="90px">
                <template slot-scope="scope">
                    <el-input-number v-if="isEditing" v-model="scope.row.november" style="width:80px" @change="scope.row.isChange=true;"
                        size="mini" :controls="false" :min="0"></el-input-number>
                    <span v-else>{{scope.row.november}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="december" label="12月" width="90px">
                <template slot-scope="scope">
                    <el-input-number v-if="isEditing" v-model="scope.row.december" style="width:80px" @change="scope.row.isChange=true;"
                        size="mini" :controls="false" :min="0"></el-input-number>
                    <span v-else>{{scope.row.december}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="fourthQuarter" label="第四季度"></el-table-column>
        </el-table>
    </div>
</template>

<script>
export default {
    data() {
        return {
            selectYear: "2024",
            yearList: [],
            tableLoading: false,
            targetList: [],
            isEditing: false,
        };
    },
    methods: {
        //年份改变
        yearChange(value){
            this.selectYear = value;
            this.getList();
        },

        //获取成员目标列表
        getList(){
            this.tableLoading = true;
            this.$axios.get(`${this.$customerUrl}/Targets/GetTargets?year=${this.selectYear}`).then(res => {
                const {isSuccess,data} = res;
                this.tableLoading = false;
                if(isSuccess){
                    data.forEach(item =>{
                        item.isChange = false;
                        item.year = this.selectYear;
                    })
                    this.targetList = data;
                    this.sumFun();
                }
            });
        },
        setFinish(){
            let submitList = this.targetList.filter(item => item.isChange == true);
            this.$axios.post(`${this.$customerUrl}/Targets/SetOrUpdateTargets`, submitList).then(res => {
                const {isSuccess,data} = res;
                if(isSuccess){
                    this.getList();
                    this.$message({message: '设定成功',type: 'success'});
                    this.isEditing = false;
                }
            });
        },
        setCancel(){
            this.getList();
            this.isEditing = false;
        },

        //合计功能
        sumFun(){
            this.targetList.forEach(element =>{
                element.firstQuarter = element.january + element.february + element.march;
                element.secondQuarter = element.april + element.may + element.june;
                element.thirdQuarter = element.july + element.august + element.september;
                element.fourthQuarter = element.october + element.november + element.december;
                element.allYear = element.firstQuarter + element.secondQuarter + element.thirdQuarter + element.fourthQuarter;
            })
        },

    },
    mounted() {
        let year = new Date().getFullYear();
        this.yearList = [
            {label: (year - 2) + "年", value: (year - 2) + "" },
            {label: (year - 1) + "年", value: (year - 1) + "" },
            {label: year + "年", value: year + "" },
            {label: (year + 1) + "年", value: (year + 1) + ""},
            {label: (year + 2) + "年", value: (year + 2) + ""},
        ]
        this.selectYear = year.toString();
        //获取成员目标列表
        this.getList();
    },
};
</script>

<style lang="scss" scoped>
.setTarget {
    padding: 20px;
    height: calc(100vh - 80px);
    box-sizing: border-box;
    overflow: auto;
    .header{
        margin-bottom: 20px;
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
</style>