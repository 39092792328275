<template>
    <div>
        中国供应商-详情
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
    methods: {},
    mounted() {},
};
</script>

<style lang='scss' scoped>

</style>