<template>
<div>
    <div v-if="ie=='i'">
        <!-- 独联体(CIS)进口提单明细 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th width="25%">进口港</th>
                        <td width="25%">{{billInfo.UnLoadingPort}}</td>
                        <th width="25%">进口国</th>
                        <td width="25%">{{billInfo.ImporterCountry}}</td>
                    </tr>
                    <tr>
                        <th>进口国家码</th>
                        <td>{{billInfo.ImporterCountryCode}}</td>
                        <th>进口FOB金额</th>
                        <td>{{billInfo.Value}}</td>
                    </tr>
                    <tr>
                        <th>进口CIF金额</th>
                        <td>{{billInfo.TotalCif}}</td>
                        <th>出发港</th>
                        <td>{{billInfo.LoadingPort}}</td>
                    </tr>
                    <tr>
                        <th>出口国</th>
                        <td>{{billInfo.ShipperCountry}}</td>
                        <th>原产国</th>
                        <td>{{billInfo.Country}}</td>
                    </tr>
                    <tr>
                        <th>单价</th>
                        <td>{{billInfo.Price}}</td>
                        <th>数量单位</th>
                        <td>{{billInfo.QuantityUnit}}</td>
                    </tr>
                    <tr>
                        <th>货币单位</th>
                        <td>{{billInfo.Currency}}</td>
                        <th>净重</th>
                        <td>{{billInfo.NetWeight}}</td>
                    </tr>
                    <tr>
                        <th>净重单位</th>
                        <td>{{billInfo.NetWeightUnit}}</td>
                        <th>毛重单位</th>
                        <td>{{billInfo.WeightUnit}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">其他信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">电话</th>
                        <td width="25%">{{billInfo.Tel}}</td>
                        <th width="25%">传真</th>
                        <td width="25%">{{billInfo.Fax}}</td>
                    </tr>
                    <tr>
                        <th>电子邮件</th>
                        <td>{{billInfo.Email}}</td>
                        <th>网站</th>
                        <td>{{billInfo.Web}}</td>
                    </tr>
                    <tr>
                        <th>包装数量</th>
                        <td>{{billInfo.NumberOfPackages}}</td>
                        <th>包装单位</th>
                        <td>{{billInfo.PackagesUnit}}</td>
                    </tr>
                    <tr>
                        <th>贸易术语</th>
                        <td>{{billInfo.Incoterms}}</td>
                        <th>运输地点</th>
                        <td>{{billInfo.DeliveryPlace}}</td>
                    </tr>
                    <tr>
                        <th>品名</th>
                        <td>{{billInfo.Brand}}</td>
                        <th>制造商</th>
                        <td>{{billInfo.ManufacturingCompany}}</td>
                    </tr>
                    <tr>
                        <th>通知方</th>
                        <td>{{billInfo.NotifyName}}</td>
                        <th>通知方地址</th>
                        <td>{{billInfo.NotifyAddress}}</td>
                    </tr>
                    <tr>
                        <th>量（US数据）</th>
                        <td>{{billInfo.Volume}}</td>
                        <th>量单位（US数据）</th>
                        <td>{{billInfo.VolumeUnit}}</td>
                    </tr>
                    <tr>
                        <th>US数据</th>
                        <td>{{billInfo.Preport}}</td>
                        <th>美国州</th>
                        <td>{{billInfo.StateOfUS}}</td>
                    </tr>
                    <tr>
                        <th>美国港口2</th>
                        <td>{{billInfo.PortOfUS}}</td>
                        <th>最终美国港口</th>
                        <td>{{billInfo.FinalPortOfUS}}</td>
                    </tr>
                    <tr>
                        <th>最终目的国港口</th>
                        <td>{{billInfo.FinalForeignPort}}</td>
                        <th>目的国</th>
                        <td>{{billInfo.FinalCountry}}</td>
                    </tr>
                    <tr>
                        <th>集装箱数量</th>
                        <td>{{billInfo.ContainerNumber}}</td>
                        <th>总标准箱数</th>
                        <td>{{billInfo.TotalTeus}}</td>
                    </tr>
                    <tr>
                        <th>标准国家名</th>
                        <td colspan="3">{{billInfo.CountryStd}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
    <div v-if="ie=='e'">
        <!-- 独联体(CIS)出口提单明细 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th width="25%">进口港</th>
                        <td width="25%">{{billInfo.UnLoadingPort}}</td>
                        <th width="25%">进口国</th>
                        <td width="25%">{{billInfo.ImporterCountry}}</td>
                    </tr>
                    <tr>
                        <th>进口国家码</th>
                        <td>{{billInfo.ImporterCountryCode}}</td>
                        <th>进口FOB金额</th>
                        <td>{{billInfo.Value}}</td>
                    </tr>
                    <tr>
                        <th>进口CIF金额</th>
                        <td>{{billInfo.TotalCif}}</td>
                        <th>出发港</th>
                        <td>{{billInfo.LoadingPort}}</td>
                    </tr>
                    <tr>
                        <th>出口国</th>
                        <td>{{billInfo.ShipperCountry}}</td>
                        <th>原产国</th>
                        <td>{{billInfo.Country}}</td>
                    </tr>
                    <tr>
                        <th>单价</th>
                        <td>{{billInfo.Price}}</td>
                        <th>数量单位</th>
                        <td>{{billInfo.QuantityUnit}}</td>
                    </tr>
                    <tr>
                        <th>货币单位</th>
                        <td>{{billInfo.Currency}}</td>
                        <th>净重</th>
                        <td>{{billInfo.NetWeight}}</td>
                    </tr>
                    <tr>
                        <th>净重单位</th>
                        <td>{{billInfo.NetWeightUnit}}</td>
                        <th>毛重单位</th>
                        <td>{{billInfo.WeightUnit}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
</div>
</template>

<script>
export default {
    props:["billInfo","ie"],
    data() {
        return {

        };
    },
    methods: {
        
    },
    mounted() {

    },
};
</script>

<style lang="scss" scoped>
.partItem {
    margin-bottom: 20px;
    /deep/.el-card__header {
        padding: 10px 20px;
        .headTitle {
            display: inline-block;
            padding-left: 10px;
            font-size: 14px;
            border-left: 2px solid var(--themeColor);
            font-weight: bold;
        }
    }
}
table {
    width: 100%;
    border-collapse: collapse;
    tr {
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;
        th,
        td {
            font-size: 12px;
            color: #666;
            height: 40px;
            border: 1px solid #eaeaea;
            text-align: center;
        }
        th {
            background-color: #f5f7fa;
            font-weight: 700;
        }
        .bg {
            background-color: var(--themeColor);
            color: #fff;
            font-weight: 700;
            opacity: 0.8;
        }
    }
}
</style>