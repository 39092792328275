<template>
    <div>
        <div class="title">应用信息</div>
        <div class="content">
            <div class="field">
                <div class="label">应用名称:</div>
                <div class="value">{{applyInfo.applyName}}</div>
            </div>
            <div class="field">
                <div class="label">应用代号:</div>
                <div class="value">{{applyInfo.applyCode}}</div>
            </div>
            <div class="field">
                <div class="label">应用key:</div>
                <div class="value">{{applyInfo.specialKey}}</div>
            </div>
            <div class="field">
                <div class="label">应用秘钥:</div>
                <div class="value">{{applyInfo.specialSecret}}</div>
            </div>
            <div class="field">
                <div class="label">有效期:</div>
                <div class="value">
                    {{applyInfo.startDate}}
                    <span>至</span>
                    {{applyInfo.endDate}}
                </div>
            </div>
            <div class="field">
                <div class="label">是否启用:</div>
                <div class="value">
                    <el-switch v-model="applyInfo.isEnable" :disabled="true" style="padding-right:5px;"></el-switch>
                    <span v-if="applyInfo.isEnable">启用</span>
                    <span v-else>禁用</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            specialKey: "",
            applyInfo: {},
        };
    },
    methods: {
        //获取应用详情
        getDetails(){
            this.$axios.get(`${this.$openUrl}/ApplyManage/GetApplyDetail?specialKey=${this.specialKey}`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.applyInfo = data;
                }
            });
        },
    },
    mounted() {
        this.specialKey = this.$route.query.specialKey;
        this.getDetails();  //获取应用详情
    },
};
</script>

<style lang="scss" scoped>
.title{
    height: 36px;
    line-height: 36px;
    font-size: 18px;
    font-weight: 700;
    color: #333;
    margin-bottom: 10px;
}
.field{
    display: flex;
    font-size: 16px;
    line-height: 40px;
    .label{
        width: 120px;
        color: #3a3a3a;
        text-align: right;
        margin-right: 30px;
    }
    .value{
        color: #6a6a6a;
    }
}
</style>