<script>
import Vue from "vue";
import { defineComponent, reactive, ref } from "@vue/composition-api";
import http from "@/assets/js/axios";
import crypto from "crypto-js";
import dayjs from "dayjs";

const encrypted = (() => {
    const str = `${dayjs().format("YYYY-MM-DD HH:mm:ss")}/KJSLSMAPS`;
    const key = crypto.enc.Utf8.parse("JwCvog1l");
    const iv = crypto.enc.Utf8.parse("JwCvog1l");
    return crypto.DES.encrypt(str, key, {
        iv,
        mode: crypto.mode.CBC,
        padding: crypto.pad.Pkcs7,
    }).toString();
})();

export default defineComponent({
    setup() {
        const LOCALSTORAGE_USERINFO = (() => {
            try {
                return JSON.parse(window.localStorage.getItem("userInfo"));
            } catch {
                return {};
            }
        })();
        const creditRecord = reactive({
            showDialog: false,
            loading: false,
            data: [],
            total: 0,
            page: {
                index: 1,
                size: 10,
            },
        });
        const getCreditRecordData = async () => {
            try {
                creditRecord.loading = true;
                const res = await http({
                    method: "POST",
                    url: Vue.prototype.$OAService + "/GetPointsDetails",
                    headers: {
                        AccessToken: encrypted,
                    },
                    data: {
                        ukey: LOCALSTORAGE_USERINFO["uKey"],
                        project: "crm",
                        pageIndex: creditRecord.page.index,
                        pageSize: creditRecord.page.size,
                    },
                });
                creditRecord.data = res.data.datas;
                creditRecord.total = res.data.totalItems;
            } catch {
                null;
            } finally {
                creditRecord.loading = false;
            }
        };
        getCreditRecordData();
        const exchangeRecord = reactive({
            showDialog: false,
            loading: false,
            data: [],
            total: 0,
            page: {
                index: 1,
                size: 10,
            },
        });
        const getExchangeRecordData = async () => {
            try {
                exchangeRecord.loading = true;
                const res = await http({
                    method: "POST",
                    url: Vue.prototype.$OAService + "/GetOrderList",
                    headers: {
                        AccessToken: encrypted,
                    },
                    data: {
                        ukey: LOCALSTORAGE_USERINFO["uKey"],
                        project: "crm",
                        pageIndex: exchangeRecord.page.index,
                        pageSize: exchangeRecord.page.size,
                    },
                });
                exchangeRecord.data = res.data.datas;
                exchangeRecord.total = res.data.totalItems;
            } catch {
                null;
            } finally {
                exchangeRecord.loading = false;
            }
        };
        getExchangeRecordData();
        const descrip = [
            "持卡人可将个人客户积分依照企芯积分兑换标准兑换相应的回馈项目，积分兑换申请仅限主账号用户提出。",
            "积分礼品兑换：客户可通过企芯积分兑换系统发起积分礼品兑换申请，常规积分礼品、年费等兑换项目均扣减客户级积分余额。",
            "积分消费及其他各积分兑换项目的具体兑换规则，均以兑换当时最新积分活动公告或目录为准。",
            "客户级积分扣减将优先扣减到期时间较早的积分。",
            "须有足够的可用积分方可换取兑换项目。如果积分不足，换礼申请将自动取消。",
            "兑换申请一经提交，不接受更改或撤销，所需积分将企芯账户中扣除。除礼品在配送途中发生损毁、缺失或礼品本身有质量瑕疵外，不退换。",
            "在申请兑换时，视为本人同意将通讯、邮寄等个人资料用于礼品供应商配送处理。",
            "若兑换申请中所提交的礼品名称与兑换编号不符，以兑换编号为准。",
        ];
        const namecard = ref({});
        http({
            method: "GET",
            url: Vue.prototype.$OAService + "/GetUserIntegralInformation",
            headers: {
                AccessToken: encrypted,
            },
            params: {
                ukey: LOCALSTORAGE_USERINFO["uKey"],
                project: "crm",
            },
        }).then((res) => {
            namecard.value = res.data;
            namecard.value.setMealName = LOCALSTORAGE_USERINFO["setMealName"];
        });
        const total = ref(0);
        http({
            method: "GET",
            url: Vue.prototype.$OAService + "/GetTotalIntegral",
            headers: {
                AccessToken: encrypted,
            },
            params: {
                ukey: LOCALSTORAGE_USERINFO["uKey"],
                project: "crm",
            },
        }).then((res) => {
            total.value = res.data;
        });
        const qrcode = ref("");
        http({
            method: "GET",
            url: Vue.prototype.$OAService + "/GetUnlimited",
            headers: {
                AccessToken: encrypted,
            },
            params: {
                uid: LOCALSTORAGE_USERINFO["id"],
                project: "crm",
            },
        }).then((res) => {
            qrcode.value = res.data;
        });
        const productList = ref([]);
        http({
            method: "POST",
            url: Vue.prototype.$OAService + "/GetProductPageList",
            headers: {
                AccessToken: encrypted,
            },
            data: {
                type: 0,
                ukey: LOCALSTORAGE_USERINFO["uKey"],
                pageIndex: 1,
                pageSize: 8,
            },
        }).then((res) => {
            productList.value = res.data.datas;
        });
        return {
            descrip,
            creditRecord,
            exchangeRecord,
            namecard,
            total,
            qrcode,
            productList,
            getCreditRecordData,
            getExchangeRecordData,
        };
    },
});
</script>

<template>
    <div class="jifen">
        <el-dialog :visible.sync="creditRecord.showDialog">
            <div v-loading="creditRecord.loading">
                <el-table :data="creditRecord.data" size="small">
                    <el-table-column prop="describe" label="描述" />
                    <el-table-column prop="integral" label="积分变动" />
                    <el-table-column prop="createTime" label="时间" />
                </el-table>
                <div style="display: flex;justify-content: flex-end;padding-top: 20px;">
                    <el-pagination hide-on-single-page background layout="prev, pager, next" @current-change="getCreditRecordData" :current-page.sync="creditRecord.page.index" :total="creditRecord.total" :page-size="creditRecord.page.size" />
                </div>
            </div>
        </el-dialog>
        <el-dialog :visible.sync="exchangeRecord.showDialog">
            <div v-loading="exchangeRecord.loading">
                <el-table :data="exchangeRecord.data" size="small">
                    <el-table-column prop="orderNo" label="编号" />
                    <el-table-column prop="productName" label="商品信息" />
                    <el-table-column prop="num" label="扣除积分数" />
                    <el-table-column prop="createTime" label="时间" />
                </el-table>
                <div style="display: flex;justify-content: flex-end;padding-top: 20px;">
                    <el-pagination hide-on-single-page background layout="prev, pager, next" @current-change="getExchangeRecordData" :current-page.sync="exchangeRecord.page.index" :total="exchangeRecord.total" :page-size="exchangeRecord.page.size" />
                </div>
            </div>
        </el-dialog>
        <div style="height: 80px;background: var(--themeColor);">
            <div style="width: 1170px;height: 100%;margin: 0 auto;display: flex;align-items: center;">
                <img src="./image/1.png" />
            </div>
        </div>
        <div style="background: #EEEEEE; padding: 40px 0;">
            <div style="width: 1170px;background: #fff;margin: 0 auto;padding-bottom: 45px;">
                <div style="padding: 0 44px 0 26px">
                    <div style="display: flex;align-items: center;height: 93px;">
                        <img src="./image/2.png" />
                        <span style="margin-left: 10px;font-size: 20px;color: #3D3D3D;">积分兑换</span>
                        <span style="margin-left: 10px;color: #B0B0B0">(请使用微信扫码进入积分小程序兑换，网页端不支持兑换)</span>
                    </div>
                    <div style="display: flex">
                        <div style="width: 100%">
                            <div style="display: flex;flex-direction: column;align-items: center;padding-top: 30px;padding-right: 50px;">
                                <div style="color: #3A3A3A;font-size: 18px;">我的可用积分</div>
                                <div style="color: var(--themeColor);font-size: 34px;font-weight: bold;">{{total}}</div>
                                <div @click="creditRecord.showDialog=true" style="cursor: pointer;margin-top: 34px;width: 138px;height: 40px;box-sizing: border-box;border: 1px solid #FFBD4B;border-radius: 4px;display: flex;align-items: center;">
                                    <img src="./image/9.png" style="position: relative;top:5px;left: 2px;" />
                                    <span style="color: #666666;font-size: 18px;position: relative;left: 7px;">积分记录</span>
                                </div>
                                <div @click="exchangeRecord.showDialog=true" style="cursor: pointer;margin-top: 23px;width: 138px;height: 40px;box-sizing: border-box;border: 1px solid #B36FF3;border-radius: 4px;display: flex;align-items: center;">
                                    <img src="./image/10.png" style="position: relative;top:5px;left: 2px;" />
                                    <span style="color: #666666;font-size: 18px;position: relative;left: 4px;">兑换记录</span>
                                </div>
                            </div>
                        </div>
                        <div style="flex-shrink: 0;box-sizing: border-box;border: 1px solid #C7C7C7;display: flex;background: #F2F2F2;">
                            <div style="width: 417px;">
                                <div style="padding-left: 24px;color: #3D3D3D; font-size: 20px;height: 47px;display: flex;align-items: center;">兑换方式：</div>
                                <div style="text-align: center">
                                    <img :src="qrcode" style="width: 171px;height: 171px;" />
                                </div>
                                <div style="font-size: 20px;color: #3D3D3D;padding: 39px 0 45px;display: flex;justify-content: center;">
                                    <span style="font-weight: bold; margin-right: 10px;">微信扫码</span>
                                    <span>进入积分商城小程序</span>
                                </div>
                            </div>
                            <div style="width: 0;border-left: 1px solid #C7C7C7;margin: 26px 0 24px;"></div>
                            <div style="width: 469px;box-sizing: border-box;padding-left: 93px;">
                                <div style="padding-left: 4px;display: flex;padding: 20px 0 18px;">
                                    <img :src="namecard.wechatAvatar||require('./image/3.png')" style="width: 46px;height: 46px;margin-right: 11px;" />
                                    <div>
                                        <div style="display: flex;align-items: center;">
                                            <span style="color: #353A3E;font-weight: bold;font-size: 17px;">{{namecard.companyName}}</span>
                                            <div style="margin-left: 6px;background: linear-gradient(90deg, #FEE1B2 0%, #F0B553 100%);border-radius: 10px; height: 16px;display: flex;align-items: center;padding: 0 6px;">
                                                <span style="font-size: 10px;color: #A26B0F;">{{namecard.setMealName}}</span>
                                            </div>
                                        </div>
                                        <div style="font-size: 12px;color: #9C9C9C;">{{namecard.name}}&lt;{{namecard.account}}&gt;</div>
                                    </div>
                                </div>
                                <div style="padding-right: 29px;padding-bottom: 14px;">
                                    <div style="padding-bottom: 10px">
                                        <span style="color: #3A3A3A;font-size: 18px;">已累计{{namecard.totalIntegral}} 积分</span>
                                        <span style="color: #B5B5B5;margin-left: 27px;">还差{{namecard.differenceIntegral}}积分下一等级</span>
                                    </div>
                                    <div style="height: 4px;overflow: hidden;background: #B6B6B6;">
                                        <div style="height: 100%;background: var(--themeColor);" :style="{width:namecard.rankingPercentage}"></div>
                                    </div>
                                </div>
                                <div class="grade-list-item">
                                    <div>
                                        <img src="./image/4.png" />
                                    </div>
                                    <span>会员卡</span>
                                    <span>0 ~ 50000积分</span>
                                </div>
                                <div class="grade-list-item">
                                    <div>
                                        <img src="./image/5.png" />
                                    </div>
                                    <span>白银卡</span>
                                    <span>50000 ~ 200000分</span>
                                </div>
                                <div class="grade-list-item">
                                    <div>
                                        <img src="./image/6.png" />
                                    </div>
                                    <span>贵宾卡</span>
                                    <span>200000 ~ 500000分</span>
                                </div>
                                <div class="grade-list-item">
                                    <div>
                                        <img src="./image/7.png" />
                                    </div>
                                    <span>金尊卡</span>
                                    <span>500000 ~ 1000000分</span>
                                </div>
                                <div class="grade-list-item">
                                    <div>
                                        <img src="./image/8.png" />
                                    </div>
                                    <span>至尊卡</span>
                                    <span>1000000分以上</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div style="padding: 60px 0 33px;padding-left: 50px;display: flex;align-items: center;">
                        <img src="./image/11.png" />
                        <span style="color: #333;font-size: 20px;margin-left: 5px;">精选奖品兑款</span>
                    </div>
                    <div style="display: flex;justify-content: center;">
                        <div style="display: grid; grid-template-columns: repeat(4,257px);grid-auto-rows: 349px; gap: 33px 28px;">
                            <div v-for="item of productList" :key="item.productKey" class="hot-list-item">
                                <img :alt="item.name" :src="item.image" style="width: 255px;height: 255px;" />
                                <div>
                                    <div>{{item.name}}</div>
                                    <div>{{item.integral}}积分</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="padding: 0 70px 0 31px">
                    <div style="padding: 67px 0 28px;display: flex;align-items: center;">
                        <img src="./image/12.png" />
                        <span style="color: #333;font-size: 20px;margin-left: 5px;">积分兑换活动说明</span>
                    </div>
                    <div style="border: 1px solid #CBCBCB;padding: 15px 18px 17px 18px;color: #333333;">
                        <div class="descrip-list-item" v-for="(item,index) of descrip" :key="index">
                            <div>{{index+1}}</div>
                            {{item}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.jifen {
    font-size: 16px;

    img {
        vertical-align: bottom;
    }
}

.grade-list-item {
    display: flex;
    color: #3a3a3a;
    align-items: center;
    padding-bottom: 5px;

    > div:first-child {
        width: 43px;
        text-align: center;
    }

    > span:nth-child(2) {
        margin-left: 15px;
    }

    > span:last-child {
        margin-left: 32px;
    }
}

.hot-list-item {
    border: 1px solid #cbcbcb;

    > div {
        padding: 0 11px;

        > div:first-child {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding-top: 16px;
            font-size: 20px;
            color: #404040;
        }

        > div:last-child {
            font-size: 24px;
            color: #e09730;
        }
    }
}

.descrip-list-item {
    display: flex;
    align-items: center;

    &:not(:last-of-type) {
        margin-bottom: 10px;
    }

    > div {
        margin-right: 12px;
        font-size: 15px;
        width: 19px;
        height: 19px;
        background: var(--themeColor);
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
    }
}
</style>
