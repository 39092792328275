<template>
    <div style="display: flex; width: 100%; height: 100%;">
        <CMenu active="WATemplate" />
        <div v-if="currentTab == '消息模版'" class="viewBox">
            <div class="headerTitle">
                <span style="padding-right:20px;">{{currentTab}}</span>
                <el-button size="small" type="primary" plain @click="currentTab = '新建模板';">新建模板</el-button>
            </div>
            <div class="listBox">
                <div class="cardItem" v-for="item in templateList" :key="item.key">
                    <div class="cardTitle">
                        <span class="name">{{item.templateName}}</span>
                    </div>
                    <div v-show="item.fileUrl">
                        <img style="width:100%;height:120px;" :src="item.fileUrl" alt="" />
                    </div>
                    <div v-show="item.content">
                        <p class="textContent textOverFour">{{item.content}}</p>
                    </div>
                    <div v-show="item.url">
                        <div class="bottomLink">
                            <img style="width:24px;height:24px;" src="@/assets/img/link.png" alt="" />
                            <span>点击了解更多</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="currentTab == '新建模板'" class="viewBox">
            <div class="headerTitle">
                <el-page-header @back="currentTab = '消息模版';"></el-page-header>
                <span>{{currentTab}}</span>
            </div>
            <div class="newBox">
                <div class="fillParameter">
                    <div class="parameterItem">
                        <span class="leftlabel">模版名称：</span>
                        <el-input class="rightItem" v-model="sendParams.templateName" placeholder="模版名称" size="small"></el-input>
                    </div>
                    <div class="parameterItem">
                        <span class="leftlabel">消息类型：</span>
                        <el-select class="rightItem" v-model="typeValue" placeholder="请选择" size="small">
                            <el-option v-for="item in MsgTypeOption" :key="item.name" :label="item.name" :value="item.name"></el-option>
                        </el-select>
                    </div>
                    <div class="parameterItem">
                        <span class="leftlabel"></span>
                        <div class="rightItem fillContent">
                            <div v-show="isShowOne" style="margin-bottom:10px;">
                                <el-upload :action="uploadUrl" :headers="headers" :data="uploadData" :show-file-list="false" :before-upload="beforeUpload" :on-progress="uploading" :on-success="uploadOk" :on-error="uploadError" >
                                    <div class="uploadBtn" v-loading="uploadLoading">
                                        <img v-if="sendParams.fileUrl" :src="sendParams.fileUrl" alt="">
                                        <i v-else class="el-icon-plus"></i>
                                    </div>
                                </el-upload>
                            </div>
                            <div v-show="isShowTwo">
                                <el-input type="textarea" placeholder="请输入消息文本" v-model="sendParams.content" maxlength="1000" show-word-limit></el-input>
                            </div>
                            <div v-show="isShowThree" style="margin-top:10px;">
                                <el-input v-model="sendParams.url" placeholder="按钮链接路径" size="small"></el-input>
                            </div>
                        </div>
                    </div>
                    <el-button type="primary" size="small" :loading="btnLoading" @click="onNewTemplate">新建模板</el-button>
                </div>
                <div class="previewBox">
                    <MessagePreview :valueOne="sendParams.fileUrl" :valueTwo="sendParams.content" :valueThree="sendParams.url"
                    :isShowOne="isShowOne" :isShowTwo="isShowTwo" :isShowThree="isShowThree" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CMenu from "./components/Menu.vue";
import MessagePreview from "./WAMass/components/MessagePreview.vue";
export default {
    components: { CMenu , MessagePreview},
    data() {
        return {
            currentTab: "消息模版",
            templateList: [],
            //新建
            sendParams:{
                key: "",
                templateName: "",
                templateTypeKey: "",
                fileUrl: "",
                content: "",
                url: "",
                isSystem: false,
            },
            btnLoading: false,
            //消息类型
            MsgTypeOption: [],
            typeValue: "文字",
            //上传图片
            uploadUrl: `${this.$diskUrl}/File/Upload`,
            headers: { authorization: 'Bearer ' + localStorage.getItem('token') },
            uploadData: { folderKey: "0", remarkName: "", permission: '我的' },
            uploadLoading: false,
        };
    },
    computed:{
        isShowOne(){
            return this.typeValue.includes("图片");
        },
        isShowTwo(){
            return this.typeValue.includes("文字");
        },
        isShowThree(){
            return this.typeValue.includes("按钮");
        },
    },
    methods: {
        //获取模板列表
        getTemplates(){
            this.$axios({method: "GET", url: this.$marketUrl + "/WhatsApp/v2/GetTemplates",}).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.templateList = data;
                }
            })
        },
        //消息类型下拉框
        getMsgType(){
            this.$axios({method: 'GET',url: this.$marketUrl + '/WhatsApp/GetMsgType',}).then((res) =>{
                const {isSuccess ,data} = res;
                if (isSuccess) {
                    this.MsgTypeOption = data;
                }
            })
        },
        //上传图片
        beforeUpload(file) {
            var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
            const isImg = testmsg === "png" || testmsg === "jpg" || testmsg === "jpeg";
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isImg) {
                this.$message({ message: "请上传图片(png/jpg/jpeg)!", type: "warning"});
            }
            if (!isLt2M) {
                this.$message({ message: "图片大小不能超过2MB!", type: "warning"});
            }
            return isImg && isLt2M;
        },
        uploading(){ 
            this.uploadLoading = true;
        },
        uploadOk(response){
            this.uploadLoading = false;
            const {isSuccess, data} = response;
            if(isSuccess){
                this.sendParams.fileUrl = data.fileUrl;
                this.$message({message: "上传成功",type: 'success'});
            }
        },
        uploadError(){
            this.uploadLoading = false;
            this.$message({message: "上传失败",type: 'warning'});
        },

        //新建模板提交
        isBefore(){
            const {templateName,fileUrl,content,url} = this.sendParams;
            this.sendParams.templateTypeKey = this.MsgTypeOption.filter(item => item.name == this.typeValue)[0].value;
            console.log(this.sendParams);
            if(!templateName){ this.$message({message: '请填写模版名称!',type: 'warning'}); return false; }
            if(this.isShowOne && !fileUrl.trim()){
                this.$message({message: '请上传图片!',type: 'warning'});
                return false;
            }
            if(this.isShowTwo && !content.trim()){
                this.$message({message: '请输入消息文本!',type: 'warning'});
                return false;
            }
            if(this.isShowThree && !url.trim()){
                this.$message({message: '请填写按钮链接路径!',type: 'warning'});
                return false;
            }
            return true;
        },
        async onNewTemplate(){
            if(this.isBefore()){
                this.btnLoading = true;
                const res = await this.$axios({
                    method: 'POST',
                    url: this.$marketUrl + '/WhatsApp/v2/AddTemplate',
                    data: this.sendParams,
                })
                this.btnLoading = false;
                if (res.isSuccess) {
                    this.$message({message: '新建成功!',type: 'success'});
                    this.currentTab = "消息模版";
                    this.getTemplates();
                    this.sendParams = {
                        key: "",
                        templateName: "",
                        templateTypeKey: "",
                        fileUrl: "",
                        content: "",
                        url: "",
                        isSystem: false,
                    };
                }
            }
        },

    },
    mounted() {
        this.getTemplates(); //获取模板列表
        this.getMsgType(); //消息类型下拉框
    },
};
</script>

<style lang='scss' scoped>
.viewBox{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 20px;
    background: #fafafa;
    box-shadow: 0px 1px 6px 1px rgba(0, 0, 0, 0.16);
}
.headerTitle{
    color: #3a3a3a;
    font-size: 18px;
    font-weight: bold;
    height: 32px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.listBox{
    height: calc(100% - 42px);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: flex-start;
    .cardItem{
        width: 330px;
        min-height: 40px;
        background: #FFFFFF;
        padding: 10px;
        box-sizing: border-box;
        border-radius: 10px;
        margin-right: 20px;
        margin-bottom: 20px;
        .cardTitle{
            display: flex;
            justify-content: space-between;
            margin-bottom: 8px;
            .name{
                height: 28px;
                line-height: 28px;
                border-radius: 28px;
                font-size: 14px;
                padding: 0 15px;
                background: #d6f4e3;
                color: #2dc76d;
            }
        }
        .textContent{
            font-size: 14px;
            color: #3a3a3a;
            line-height: 20px;
        }
        .bottomLink{
            margin-top: 5px;
            border-top: 1px solid #dadada;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            color: #00a5f4;
            height: 30px;
        }
    }
}
.newBox{
    height: calc(100% - 42px);
    display: flex;
    justify-content: space-between;
    padding: 20px;
    box-sizing: border-box;
    .fillParameter{
        width: calc(100% - 400px);
        height: 100%;
        .parameterItem{
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            .leftlabel{
                width: 80px;
                display: inline-block;
                color: #5E6D82;
                font-size: 14px;
            }
            .rightItem{
                width: calc(100% - 80px);
            }
            .fillContent{
                padding: 10px;
                box-sizing: border-box;
                background: #F2F2F2;
                border: 1px solid #D8D8D8;
                /deep/.el-textarea__inner{
                    height: 170px;
                }
                .uploadBtn{
                    width: 100px;
                    height: 100px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 4px;
                    border: 1px solid #C3C3C3;
                    box-sizing: border-box;
                    background: #FFF;
                    &:hover{
                        border-color: var(--themeColor);
                    }
                    img{
                        width: 100%;
                        height: 100%;
                        border-radius: 4px;
                    }
                    .el-icon-plus{
                        font-size: 40px;
                        color: #C3C3C3;
                    }
                    &:hover .el-icon-plus{
                        color: var(--themeColor);
                    }
                }
            }
        }
    }
    .previewBox{
        width: 380px;
        height: 100%;
    }
}
</style>