<script>
import Vue from 'vue'
import { ref, defineComponent, reactive, toRefs, watch } from '@vue/composition-api'
import axios from '@/assets/js/axios'
import dayjs from 'dayjs'
export default defineComponent({
  props: ['businessKey'],
  setup(props) {
    const kanban = reactive({
      color: '',
      status: '',
      stay: '',
      persist: ''
    })
    let dataCache = []
    const timeLine = ref([])
    const page = reactive({
      total: 0,
      index: 1,
      size: 10
    })
    const isOutDate = ref(false)
    const expirationDays = ref(0)
    const gap = ref(0)
    const isUrgent = ref(false)
    const getAllData = async () => {
      try {
        const res = await axios({
          method: 'GET',
          url: Vue.prototype.$sjUrl + '/BusinessChance/GetBusinessDetail',
          params: {
            businessKey: props.businessKey
          }
        })
        if (!res.isSuccess) throw new Error()
        const data = res.data
        kanban.color = data.stageName === '输单' ? '#a6a9ad' : data.isWinStatge ? '#4480cf' : '#33a76f'
        kanban.status = data.stageName
        kanban.stay = data.dwellTime
        kanban.persist = data.totalFollowUpTime
        isOutDate.value = data.isExpire
        expirationDays.value = data.expirationDays
        gap.value = dayjs(data.endTime).diff(dayjs(dayjs().format('YYYY-MM-DD')), 'days')
        isUrgent.value = gap.value < 3 && gap.value >= 0
        dataCache = data.operation.map(item => ({
          content: item.description,
          date: item.operatingTime,
          isWinStatge: item.isWinStatge
        }))
        page.total = dataCache.length
      } catch { null }
    }
    getAllData()
    const pageResf = toRefs(page)
    watch([pageResf.total, pageResf.index], () => {
      timeLine.value = dataCache.slice(10 * (page.index - 1), page.index * 10)
    })
    return {
      kanban,
      timeLine,
      getAllData,
      page,
      isOutDate,
      expirationDays,
      gap,
      isUrgent
    }
  }
})
</script>

<template>
  <div class="status-panel">
    <div class="top">
      <div class="card">
        <p class="title">订单状态</p>
        <div class="content">
          <div style="width: 16px; height: 16px; border-radius: 50%; margin-right: 9px" :style="{ background: kanban.color }"></div>
          <span>{{ kanban.status }}</span>
        </div>
      </div>
      <div class="card">
        <p class="title">停留时间</p>
        <p class="content" :style="{ color: isOutDate ? '#ff0000' : isUrgent && gap === 0 ? '#E6A23C' : '' }">{{ isOutDate ? `过期${expirationDays}天` : isUrgent && gap === 0 ? '今日过期' : `${kanban.stay}天` }}</p>
      </div>
      <div class="card">
        <p class="title">总跟进时间</p>
        <p class="content">{{ `${kanban.persist}天` }}</p>
      </div>
    </div>
    <el-scrollbar style="height: calc(100% - 66px); margin-top: 10px">
      <el-timeline>
        <el-timeline-item v-for="(item, index) of timeLine" :key="index" :color="item.content.includes('输单') ? '#a6a9ad' : item.isWinStatge ? '#4480cf' : '#33a76f'">
          <div style="display: flex; align-items: center; justify-content: space-between; padding-right: 10px">
            <p style="color: #3A3A3A; max-width: 240px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap">{{ item.content }}</p>
            <span style="color: #707070">{{ item.date }}</span>
          </div>
        </el-timeline-item>
      </el-timeline>
      <div style="display: flex; justify-content: flex-end">
        <el-pagination layout="prev, pager, next" :page-size="page.size" :current-page.sync="page.index" :total="page.total" />
      </div>
    </el-scrollbar>
  </div>
</template>

<style scoped lang="scss">
.status-panel {
  box-sizing: border-box;
  height: 100%;
}

.top {
  box-sizing: border-box;
  height: 56px;
  overflow: visible;
  display: flex;
  justify-content: space-between;

  .card {
    box-sizing: border-box;
    height: 100%;
    width: 120px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 2px;
    padding: 10px;
  }

  .title {
    color: #b7b9bc;
    font-size: 14px;
    text-align: center;
  }

  .content {
    color: #040404;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
}

::v-deep .el-timeline-item {
  padding-bottom: 10px;
}
</style>
