import G6 from '@antv/g6'

export const color = {
  root: '#409EFF',
  product: '#FF9F1E',
  'second-product': '#FF9F1E',
  'more-product': '#FF9F1E',
  country: '#FF3250',
  'second-country': '#FF3250',
  'more-country': '#FF3250',
  company: '#0BA7F0',
  'second-company': '#0BA7F0',
  'more-company': '#0BA7F0',
  default: '#000',
  empty: '#909399'
}

export const rectConfig = {
  width: 200,
  secondWidth: 100,
  emptyWidth: 100,
  height: 24
}

const flagSize = {
  width: 24,
  height: 16
}

function transformStr(text, fontSize, length, max) {
  try {
    if (G6.Util.getTextSize(text, fontSize)[0] > length) {
      return text.substring(0, max) + '...'
    } else {
      return text
    }
  } catch {
    return ''
  }
}

G6.registerNode(
  'rect-node-okj6uhgb-new',
  {
    draw(cfg, group) {
      const second = cfg.classify.includes('second')
      const country = cfg.classify === 'country'
      const empty = cfg.classify === 'empty'
      const leaf = cfg.leaf
      const node = group.addShape('rect', {
        attrs: {
          width: cfg.classify === 'root' ? G6.Util.getTextSize(cfg.label, 12)[0] + 12 : second ? rectConfig.secondWidth : empty ? rectConfig.emptyWidth : rectConfig.width,
          height: rectConfig.height,
          radius: 2,
          stroke: second ? '#fff' : color[cfg.classify] || color['default'],
          lineWidth: 1,
          fill: cfg.classify === 'root' ? color[cfg.classify] : '#fff',
        }
      })
      if (cfg.direction === 'right') {
        if (second) {
          group.addShape('circle', {
            attrs: {
              y: rectConfig.height / 2,
              x: 15,
              r: 5,
              fill: color[cfg.classify]
            }
          })
          group.addShape('marker', {
            attrs: {
              x: -7,
              y: rectConfig.height / 2,
              r: 4,
              fill: '#409EFF',
              symbol: function (x, y, r) {
                return [['M', x, y], ['L', x + 3 * r, y + r], ['L', x + 3 * r, y - r], ['Z']];
              }
            }
          })
        }
        if (country) {
          group.addShape('image', {
            attrs: {
              x: 5,
              y: (rectConfig.height - flagSize.height) / 2,
              width: flagSize.width,
              height: flagSize.height,
              img: cfg.flagSrc
            }
          })
        }
        group.addShape('text', {
          attrs: {
            y: (rectConfig.height + 12) / 2,
            x: second || empty ? 25 : country ? flagSize.width + 8 : 6,
            fontSize: 12,
            text: transformStr(cfg.label, 12, country ? 100 : 120, country ? 16 : leaf ? 18 : 16),
            fill: '#303133',
            textAlign: 'left'
          },
          name: 'label-text'
        })
        if (!second && !cfg.label.includes('展开更多')) {
          group.addShape('text', {
            attrs: {
              y: (rectConfig.height + 12) / 2,
              x: leaf ? rectConfig.width - 10 : rectConfig.width - 25,
              fontSize: 12,
              text: cfg.subLabel,
              fill: color[cfg.classify],
              textAlign: 'right'
            },
            name: 'sub-label'
          })
        }
        if (!leaf) {
          group.addShape('circle', {
            attrs: {
              y: rectConfig.height / 2,
              x: second ? rectConfig.secondWidth - 12 : rectConfig.width - 12,
              r: 6,
              stroke: '#909399',
              cursor: 'pointer'
            }
          })
          group.addShape('text', {
            attrs: {
              y: (rectConfig.height + 10) / 2,
              x: second ? rectConfig.secondWidth - 12 : rectConfig.width - 12,
              text: cfg.collapsed ? '+' : '-',
              fontSize: 10,
              fill: '#909399',
              textAlign: 'center',
              cursor: 'pointer'
            },
            name: 'collapse-text'
          })
        }
      } else if (cfg.direction === 'left') {
        if (second) {
          group.addShape('circle', {
            attrs: {
              y: rectConfig.height / 2,
              x: rectConfig.secondWidth - 15,
              r: 5,
              fill: color[cfg.classify]
            }
          })
          group.addShape('marker', {
            attrs: {
              x: rectConfig.secondWidth - 7,
              y: rectConfig.height / 2,
              r: 4,
              fill: '#409EFF',
              symbol: function (x, y, r) {
                return [['M', x, y], ['L', x + 3 * r, y + r], ['L', x + 3 * r, y - r], ['Z']];
              }
            }
          })
        }
        if (country) {
          group.addShape('image', {
            attrs: {
              x: rectConfig.width - 5 - flagSize.width,
              y: (rectConfig.height - flagSize.height) / 2,
              width: flagSize.width,
              height: flagSize.height,
              img: cfg.flagSrc
            }
          })
        }
        group.addShape('text', {
          attrs: {
            y: (rectConfig.height + 12) / 2,
            x: second || empty ? rectConfig.secondWidth - 25 : country ? rectConfig.width - 8 - flagSize.width : rectConfig.width - 6,
            fontSize: 12,
            text: transformStr(cfg.label, 12, country ? 100 : 120, country ? 16 : leaf ? 18 : 16),
            fill: '#303133',
            textAlign: 'right'
          },
          name: 'label-text'
        })
        if (!second && !cfg.label.includes('展开更多')) {
          group.addShape('text', {
            attrs: {
              y: (rectConfig.height + 12) / 2,
              x: leaf ? 10 : 25,
              fontSize: 12,
              text: cfg.subLabel,
              fill: color[cfg.classify],
              textAlign: 'left'
            },
            name: 'sub-label'
          })
        }
        if (!leaf) {
          group.addShape('circle', {
            attrs: {
              y: rectConfig.height / 2,
              x: 12,
              r: 6,
              stroke: '#909399',
              cursor: 'pointer'
            }
          })
          group.addShape('text', {
            attrs: {
              y: (rectConfig.height + 10) / 2,
              x: 12,
              text: cfg.collapsed ? '+' : '-',
              fontSize: 10,
              fill: '#909399',
              textAlign: 'center',
              cursor: 'pointer'
            },
            name: 'collapse-text'
          })
        }
      } else {
        group.addShape('text', {
          attrs: {
            y: (rectConfig.height + 12) / 2,
            x: (G6.Util.getTextSize(cfg.label, 12)[0] + 12) / 2,
            fontSize: 12,
            text: cfg.label,
            fill: '#fff',
            textAlign: 'center'
          },
          name: 'label-text'
        })
        group.addShape('text', {
          attrs: {
            y: 12,
            x: cfg.leftEmpty ? -67 : -30,
            fontSize: 12,
            text: cfg.leftEmpty ? '' : '出口',
            fill: '#000'
          }
        })
        group.addShape('text', {
          attrs: {
            y: 12,
            x: (G6.Util.getTextSize(cfg.label, 12)[0] + 12) + 6,
            fontSize: 12,
            text: cfg.rightEmpty ? '' : '进口',
            fill: '#000'
          }
        })
      }
      return node
    },
    setState(name, value, item) {
      if (name === 'collapsed') {
        const group = item.getContainer()
        const collapseText = group.find((e) => e.get("name") === "collapse-text")
        collapseText ? collapseText.attr({
          text: value ? '+' : '-'
        }) : null
      }
    },
    getAnchorPoints() {
      return [
        [0, 0.5],
        [1, 0.5]
      ];
    }
  },
  'rect'
)

G6.registerEdge(
  "step-line-okj6uhgb",
  {
    getControlPoints: function getControlPoints(cfg) {
      const startPoint = cfg.startPoint;
      const endPoint = cfg.endPoint;
      return [
        startPoint,
        {
          x: (startPoint.x + endPoint.x) / 2,
          y: startPoint.y,
        }, {
          x: (startPoint.x + endPoint.x) / 2,
          y: endPoint.y
        },
        endPoint,
      ];
    },
  },
  "polyline"
)

export default G6
