<template>
    <div>
        <!-- 俄罗斯进口提单明细/俄罗斯出口提单明细(字段共用) -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th>制造商</th>
                        <td colspan="3">{{billInfo.ShippingName}}</td>
                    </tr>
                    <tr>
                        <th width="25%">品牌</th>
                        <td width="25%">{{billInfo.Brand}}</td>
                        <th width="25%">净重</th>
                        <td width="25%">{{billInfo.NetWeight}}</td>
                    </tr>
                    <tr>
                        <th>海关成本值（卢布）</th>
                        <td>{{billInfo.CustomsValueRubles}}</td>
                        <th>货币汇率</th>
                        <td>{{billInfo.Currency}}</td>
                    </tr>
                    <tr>
                        <th>汇率的日期</th>
                        <td>{{billInfo.CurrencyDate}}</td>
                        <th>USD/KG</th>
                        <td>{{billInfo.USDKG}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">财务结算企业信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">财务结算企业</th>
                        <td width="25%">{{billInfo.FinancialSettlement}}</td>
                        <th width="25%">财务结算企业ID</th>
                        <td width="25%">{{billInfo.FinancialSettlementId}}</td>
                    </tr>
                    <tr>
                        <th>财务结算企业地址</th>
                        <td>{{billInfo.FinancialSettlementAddr}}</td>
                        <th>财务结算企业的ORGN代码,企业登记号</th>
                        <td>{{billInfo.FinancialSettlementOgrn}}</td>
                    </tr>
                    <tr>
                        <th>财务结算企业所在地区</th>
                        <td colspan="3">{{billInfo.FinancialSettlementSoato}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">货物信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">是否为货物</th>
                        <td width="25%">{{billInfo.Stat}}</td>
                        <th width="25%">来源国</th>
                        <td width="25%">{{billInfo.Country}}</td>
                    </tr>
                    <tr>
                        <th>起运国</th>
                        <td>{{billInfo.CountryDeparture}}</td>
                        <th>起运国代码</th>
                        <td>{{billInfo.CountryDepartureCode}}</td>
                    </tr>
                    <tr>
                        <th>目的国</th>
                        <td>{{billInfo.CountryDestination}}</td>
                        <th>目的国代码</th>
                        <td>{{billInfo.CountryDestinationCode}}</td>
                    </tr>
                    <tr>
                        <th>交易国代码</th>
                        <td>{{billInfo.TradingCountryCode}}</td>
                        <th>货物报关代码</th>
                        <td>{{billInfo.GoodsDecisionCode}}</td>
                    </tr>
                    <tr>
                        <th>贸易条款</th>
                        <td>{{billInfo.TradeClause}}</td>
                        <th>货物交付点</th>
                        <td>{{billInfo.DeliveryGoods}}</td>
                    </tr>
                    <tr>
                        <th>货物的配额</th>
                        <td>{{billInfo.GoodsQuota}}</td>
                        <th>附加的测量单位代码</th>
                        <td>{{billInfo.AdditionalUnitMeasure}}</td>
                    </tr>
                    <tr>
                        <th>货物成本金额（发票金额）</th>
                        <td>{{billInfo.InvoiceValue}}</td>
                        <th>集装箱数量</th>
                        <td>{{billInfo.NumberOfContainer}}</td>
                    </tr>
                    <tr>
                        <th>集装箱运输记录</th>
                        <td>{{billInfo.ContainerShippingRecords}}</td>
                        <th>包装数量</th>
                        <td>{{billInfo.PlaceNumberOfGoods}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">海关信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">报关代码</th>
                        <td width="25%">{{billInfo.DeclarationCode}}</td>
                        <th width="25%">报关企业</th>
                        <td width="25%">{{billInfo.DeclarationEnterprise}}</td>
                    </tr>
                    <tr>
                        <th>报关企业ID</th>
                        <td>{{billInfo.CustomsApplicationId}}</td>
                        <th>报关企业地址</th>
                        <td>{{billInfo.DeclarationEnterpriseAddr}}</td>
                    </tr>
                    <tr>
                        <th>海关机构代码</th>
                        <td>{{billInfo.CustomsCode}}</td>
                        <th>表单序列号</th>
                        <td>{{billInfo.SerialNumber}}</td>
                    </tr>
                    <tr>
                        <th>海关港口名称</th>
                        <td>{{billInfo.CustomPort}}</td>
                        <th>海关基本代码</th>
                        <td>{{billInfo.BasicCustomsCode}}</td>
                    </tr>
                    <tr>
                        <th>海关程序代码</th>
                        <td>{{billInfo.CustomerProcedureCode}}</td>
                        <th>海关经纪人名称</th>
                        <td>{{billInfo.CustomsBroker2}}</td>
                    </tr>
                    <tr>
                        <th>海关经纪人的证书编号</th>
                        <td>{{billInfo.CustomsBroker}}</td>
                        <th>仓库代码</th>
                        <td>{{billInfo.WarehouseCode}}</td>
                    </tr>
                    <tr>
                        <th>发货关区代码</th>
                        <td>{{billInfo.ExportCustomCode}}</td>
                        <th>收货关区代码</th>
                        <td>{{billInfo.ImportCustomerCode}}</td>
                    </tr>
                    <tr>
                        <th>车站名称/收货商仓库</th>
                        <td>{{billInfo.StationName}}</td>
                        <th>TBG产品编号</th>
                        <td>{{billInfo.DeclarationId}}</td>
                    </tr>
                    <tr>
                        <th>附加功能TBG</th>
                        <td>{{billInfo.AdditionalFeatureTBG}}</td>
                        <th>TBG发票总额</th>
                        <td>{{billInfo.TotalInvoiceValueTbg}}</td>
                    </tr>
                    <tr>
                        <th>海关货币代码</th>
                        <td>{{billInfo.CustomerCurrencyCode}}</td>
                        <th>GTE总海关价值</th>
                        <td>{{billInfo.TotalCustomValue}}</td>
                    </tr>
                    <tr>
                        <th>银行代码</th>
                        <td>{{billInfo.BankCode}}</td>
                        <th>证书编号/许可证</th>
                        <td>{{billInfo.License}}</td>
                    </tr>
                    <tr>
                        <th>装货/卸货地文件类型</th>
                        <td>{{billInfo.LoadingUnloadingAdressFileType}}</td>
                        <th>文件类型</th>
                        <td>{{billInfo.DocumentType}}</td>
                    </tr>  
                    <tr>
                        <th>LNP官方</th>
                        <td colspan="3">{{billInfo.OfficialLnp}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
</template>

<script>
export default {
    props:["billInfo","ie"],
    data() {
        return {

        };
    },
    methods: {
        
    },
    mounted() {

    },
};
</script>

<style lang="scss" scoped>
.partItem {
    margin-bottom: 20px;
    /deep/.el-card__header {
        padding: 10px 20px;
        .headTitle {
            display: inline-block;
            padding-left: 10px;
            font-size: 14px;
            border-left: 2px solid var(--themeColor);
            font-weight: bold;
        }
    }
}
table {
    width: 100%;
    border-collapse: collapse;
    tr {
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;
        th,
        td {
            font-size: 12px;
            color: #666;
            height: 40px;
            border: 1px solid #eaeaea;
            text-align: center;
        }
        th {
            background-color: #f5f7fa;
            font-weight: 700;
        }
        .bg {
            background-color: var(--themeColor);
            color: #fff;
            font-weight: 700;
            opacity: 0.8;
        }
    }
}
</style>