<template>
    <div>
        <InterfaceName :interfaceName="interfaceName" />
        <InterfaceSpe :requestUrl="requestUrl" :requestMethod="requestMethod" />
        <InputParam :inputTableData="inputTableData" />
        <ReturnParam :returnObj="returnObj" />
    </div>
</template>

<script>
import InterfaceName from "../Acomponents/InterfaceName.vue";
import InterfaceSpe from "../Acomponents/InterfaceSpe.vue";
import InputParam from "../Acomponents/InputParam.vue";
import ReturnParam from "../Acomponents/ReturnParam.vue";

export default {
    components: { InterfaceName, InterfaceSpe, InputParam, ReturnParam },
    data() {
        return {
            interfaceName: "查询订单详情",
            requestUrl: "https://crm.qixinerp.com/open/Order/Detail",
            requestMethod: "GET",
            inputTableData: [],
            returnObj: {},
        };
    },
    methods: {},
    mounted() {
        this.inputTableData = [
            { paramName: "uniqueKey",valueType: "string",explain: "订单唯一key",required: true },
        ];
        this.returnObj = {
            "isSuccess": true,
            "errorMessage": null,
            "data": {
                "order": {
                    "orderNo": "20230511161933114",
                    "uniqueKey": "d4aa3ce6ebfb46c082ece303f4d4e0f1",
                    "orderStatus": "草稿",
                    "handler": "彭贤鑫",
                    "performanceAttribution": "彭贤鑫",
                    "organizationName": "前端开发",
                    "orderAmount": "80000.00",
                    "createTime": "2023-05-11",
                    "createUserKey": "af19a3cbd7094bafa19e629b04b482ae",
                    "createUserName": "彭贤鑫",
                    "createType": "手动创建",
                    "customerTel": "51 3474-3426",
                    "customerFax": "",
                    "quoteKey": "",
                    "orderKey": "0f5ac92de3aa43a9a02b0c2b9b5f26c0",
                    "orderName": "0511采购",
                    "currency": "CNY",
                    "orderDate": "2023-05-11",
                    "customerKey": "61a683bdee08474198fe2c101a242bec",
                    "customerName": "GLADEMIR BUSI",
                    "contactKey": "056c72fee3dd4ea3b056b8345d525d6d",
                    "contactsName": "dd1",
                    "contactsCall": "",
                    "contactsEmail": "",
                    "customerAddress": "Rio Grande do Sul Sapucaia do Sul Rua DULCIMAR CARDOSO 106 93220-560",
                    "priceTerms": "无",
                    "paymentMethod": "银行转账",
                    "deliveryDate": "2023-05-18",
                    "bankInfo": "xxxx-xxxx-xxxx-1234",
                    "remarks": "测试数据",
                    "businessChanceKey": "",
                    "productTotalVolume": "",
                    "productTotalWeight": "",
                    "portOfShipment": "无",
                    "portOfDestination": "无         ",
                    "typeOfShipping": "",
                    "handlerKey": "af19a3cbd7094bafa19e629b04b482ae",
                    "performanceAttributionKey": "af19a3cbd7094bafa19e629b04b482ae",
                    "expectedReceiptDate": "2023-05-18",
                    "organizationKey": [
                        "80c6c41e49e04550807568379e26adc7",
                        "31b739fe51b841dda1abc99e62970b9d"
                    ]
                },
                "suppliers": [],
                "tradeExpenses": [],
                "followUps": [],
                "orderProducts": [
                    {
                        "productKey": "6903c47a216b48559c2ab33e247f7969",
                        "productNo": "CS001",
                        "productName": "耐克鞋子",
                        "productImg": "http://qixin-crm-disk.oss-cn-shanghai.aliyuncs.com/92606a12-11f2-4305-b19e-a06e503ec76a.png",
                        "specifications": "0.1m*0.1m*0.1m",
                        "productDescription": "",
                        "totalPrice": 80000,
                        "num": 100,
                        "measureUnit": "PCS",
                        "otherExpenses": 0,
                        "currency": "CNY",
                        "remarks": "",
                        "exchangeRate": "1",
                        "cnyTotalPrice": 80000,
                        "unitPrice": 800
                    }
                ],
                "productTotalNum": 100,
                "productTotalPrice": 80000,
                "tradeExpensesTotalPrice": 0,
                "allTotalPrice": 80000
            },
        }
    },
};
</script>

<style lang="scss" scoped>

</style>