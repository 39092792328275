<template>
    <div v-loading="cardLoading">
        <el-button type="primary" plain @click="toMassOne" size="small" style="margin-bottom:10px;">邮件营销</el-button>
        <el-button type="primary" plain @click="toMassTwo" size="small" style="margin-bottom:10px;">WhatsApp群发</el-button>
        <el-empty description="暂无发信记录" v-show="mailRecordTotal == 0"></el-empty>
        <div class="cardItem" v-for="(item,index) in mailRecordList" :key="index">
            <div class="taskName">{{item.taskName}}</div>
            <el-row :gutter="12" style="width:80%;">
                <el-col :span="4">
                    <span class="label">收件人数：</span>
                    <span class="value">{{ item.recipientsCount || '--' }}</span>
                </el-col>
                <el-col :span="4">
                    <span class="label">发送：</span>
                    <span class="value">{{ item.sentCount || '--' }}</span>
                </el-col>
                <el-col :span="4">
                    <span class="label">送达：</span>
                    <span class="value">{{ item.serviceCount || '--' }}</span>
                </el-col>
                <el-col :span="4">
                    <span class="label">打开：</span>
                    <span class="value">{{ item.openCount || '--' }}</span>
                </el-col>
                <el-col :span="4">
                    <span class="label">看信率：</span>
                    <span class="value">{{ item.letterReadingRate ? item.letterReadingRate+'%' : '--' }}</span>
                </el-col>
                <el-col :span="4">
                    <span class="label">退订：</span>
                    <span class="value">{{ item.unsubscribeCount || '--' }}</span>
                </el-col>
            </el-row>
            <el-row :gutter="12" style="width:80%;">
                <el-col :span="8">
                    <span class="label">发送时间：</span>
                    <span class="value">{{ item.sentTime }}</span>
                </el-col>
                <el-col :span="8">
                    <span class="label">最近更新：</span>
                    <span class="value">{{ item.updateTime }}</span>
                </el-col>
            </el-row>
            <el-button v-if="item.isShow" class="btn" type="primary" plain size="mini" icon="el-icon-arrow-up" @click="item.isShow = false;">收起</el-button>
            <el-button v-else class="btn" type="primary" plain size="mini" icon="el-icon-arrow-down" @click="getRecipients(item,1)">展开</el-button>
            <!-- 群发联系人列表 -->
            <div class="recipientBox" v-show="item.isShow">
                <el-table :data="item.recipientList" size="small" :header-cell-style="{'background-color':'#E5E9F2','color':'#1F2D3D','font-weight': 'bold'}">
                    <el-table-column prop="name" label="姓名"></el-table-column>
                    <el-table-column prop="position" label="职位"></el-table-column>
                    <el-table-column prop="sourceStr" label="来源"></el-table-column>
                    <el-table-column prop="sentCount" label="发信次数"></el-table-column>
                    <el-table-column prop="email" label="联系人邮箱"></el-table-column>
                    <el-table-column label="发信时间">
                        <template slot-scope="scope">
                            <span :class="scope.row.isSuccess ? 'succeed' : 'fail'"></span>
                            <span>{{scope.row.sentTime || "--"}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="看信时间">
                        <template slot-scope="scope">
                            <span :class="scope.row.openTime ? 'succeed' : 'unread'"></span>
                            <span>{{scope.row.openTime || "未读"}}</span>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination v-show="item.recipientTotal > 10" layout="total,prev,pager,next" :total="item.recipientTotal" style="float:right;margin:5px 0;" :current-page.sync="item.pageIndex" @current-change="(page)=>getRecipients(item,page)"></el-pagination>
            </div>
        </div>
        <el-pagination v-show="mailRecordTotal > 10" background layout="total,prev,pager,next" :total="mailRecordTotal" style="float:right;" :current-page.sync="pageIndex" @current-change="pageChange"></el-pagination>
    </div>
</template>

<script>
import dayjs from "dayjs";
export default {
    data() {
        return {
            myClueKey: "",
            // 发信记录列表
            cardLoading: false,
            mailRecordList: [],
            mailRecordTotal: 0,
            pageIndex: 1,
        };
    },
    methods: {
        //邮件营销
        toMassOne(){
            localStorage.setItem('clueKeyList',this.myClueKey);
            let routeData = this.$router.resolve({
                // path: '/marketing/New',
                path: '/marketing/MailMarketing',
                query: { isClueMass: true },
            });
            window.open(routeData.href, "_blank");
        },
        //WhatsApp群发
        toMassTwo(){
            localStorage.setItem('clueKeyList',this.myClueKey);
            let routeData = this.$router.resolve({
                // path: '/marketingWA/NewWA',
                path: '/marketingWA/WAMass',
                query: { isClueMass: true },
            });
            window.open(routeData.href, "_blank");
        },

        //获取发信记录
        getMailRecord(){
            this.cardLoading = true;
            this.$axios.post(`${this.$clueUrl}/MyClue/GetClueMassSending`, {
                myClueKey: this.myClueKey,
                pageIndex: this.pageIndex,
                pageSize: 10,
            }).then((res) => {
                const { isSuccess, data } = res;
                this.cardLoading = false;
                if (isSuccess) {
                    data.datas.forEach((item) => {
                        item.sentTime = item.sentTime ? dayjs(item.sentTime).format("MM-DD HH:mm") : "--";
                        item.updateTime = item.updateTime ? dayjs(item.updateTime).format("MM-DD HH:mm") : "--";
                        item.isShow = false;
                        item.pageIndex = 1;
                        item.recipientList = [];
                        item.recipientTotal = 0;
                    });
                    this.mailRecordList = data.datas;
                    this.mailRecordTotal = data.totalItems;
                }
            });
        },
        pageChange(page) {
            this.pageIndex = page;
            this.getMailRecord();
        },

        //群发联系人列表
        getRecipients(item, page) {
            item.isShow = true;
            item.pageIndex = page;
            this.$axios.post(`${this.$clueUrl}/MyClue/GetRecipients`, {
                myClueKey: this.myClueKey,
                eventNo: item.eventNo,
                campaignId: item.campaignId,
                pageIndex: item.pageIndex,
                pageSize: 10,
            }).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    item.recipientList = data.datas;
                    item.recipientTotal = data.totalItems;
                }
            });
        },

    },
    mounted() {
        this.myClueKey = this.$route.query.myClueKey;
        this.getMailRecord(); //获取发信记录
    },
};
</script>

<style lang="scss" scoped>
.cardItem {
    padding: 10px 20px;
    background: #ffffff;
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    position: relative;
    font-size: 14px;
    margin-bottom: 10px;
    .taskName {
        font-size: 16px;
        line-height: 32px;
        font-weight: bold;
        color: #000000;
    }
    .label {
        display: inline-block;
        line-height: 32px;
        color: #9c9c9c;
    }
    .value {
        display: inline-block;
        line-height: 32px;
        font-weight: bold;
        color: #3a3a3a;
    }
    .btn {
        position: absolute;
        top: 10px;
        right: 20px;
    }
    .recipientBox{
        overflow: hidden;
        border: 1px solid #D7D7D8;
        .succeed{
            display: inline-block;
            margin-right: 5px;
            width: 12px;
            height: 12px;
            background: #20BC5F;
            border-radius: 50%;
        }
        .fail{
            display: inline-block;
            margin-right: 5px;
            width: 12px;
            height: 12px;
            background: #FF2D2D;
            border-radius: 50%;
        }
        .unread{
            display: inline-block;
            margin-right: 5px;
            width: 12px;
            height: 12px;
            background: #C7C7C7;
            border-radius: 50%;
        }
    }
}
</style>