<template>
    <div class="viewbox">
        <div class="title">线索搜索</div>
        <el-row :gutter="24" style="padding:20px;">
            <el-col :span="8">
                <el-card class="viewItem">
                    <img class="bgImg" src="@/assets/cluePicture/bg1.png" alt="" />
                    <div class="poImg">
                        <img src="@/assets/cluePicture/po1.png" alt="" />
                    </div>
                    <div class="display:flex;">
                        <div class="name">工商信息线索</div>
                        <div class="price">￥100/10次</div>
                    </div>
                    <div class="vip">VIP</div>
                    <div class="describe">整合50+欧美商会，80+知名展会、贸易机构、政府采购平台、B2B平台等行业网站、海关数据等3000万家精准客户，覆盖全球200多个国家地区。</div>
                    <div style="overflow:hidden;">
                        <el-link style="float:right;" type="primary" :underline="false" @click="toDetails">查看详情>></el-link>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="8">
                <el-card class="viewItem">
                    <img class="bgImg" src="@/assets/cluePicture/bg2.png" alt="" />
                    <div class="poImg">
                        <img src="@/assets/cluePicture/po2.png" alt="" />
                    </div>
                    <div class="display:flex;">
                        <div class="name">展会线索</div>
                        <div class="price">￥100/10次</div>
                    </div>
                    <div class="vip">VIP</div>
                    <div class="describe">帮助外贸企业了解全球区域产品需求的淡旺季；及时发现新兴市场和成长性较好的市场；锁定需求量大、利润高的目标市场。</div>
                    <div style="overflow:hidden;">
                        <el-link style="float:right;" type="primary" :underline="false" @click="toDetails">查看详情>></el-link>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="8">
                <el-card class="viewItem">
                    <img class="bgImg" src="@/assets/cluePicture/bg3.png" alt="" />
                    <div class="poImg">
                        <img src="@/assets/cluePicture/po3.png" alt="" />
                    </div>
                    <div class="display:flex;">
                        <div class="name">商业数据线索</div>
                        <div class="price">￥100/10次</div>
                    </div>
                    <div class="vip">VIP</div>
                    <div class="describe">在有效时间内获取最全面的商业资讯,结合全球200多个国家3亿多企业联系方式及其深度报告,一键挖掘全球市场和新客户。</div>
                    <div style="overflow:hidden;">
                        <el-link style="float:right;" type="primary" :underline="false" @click="toDetails">查看详情>></el-link>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="8" style="margin-top:20px;">
                <el-card class="viewItem">
                    <img class="bgImg" src="@/assets/cluePicture/bg4.png" alt="" />
                    <div class="poImg">
                        <img src="@/assets/cluePicture/po4.png" alt="" />
                    </div>
                    <div class="display:flex;">
                        <div class="name">海关数据线索</div>
                        <div class="price">￥100/10次</div>
                    </div>
                    <div class="vip">VIP</div>
                    <div class="describe">国家齐全，覆盖北美洲、南美洲、亚洲、欧洲、非洲五大洲90多个国家和地区进口商、出口商、原产国、目的国、起运港、卸货港、数量、金额等数据。</div>
                    <div style="overflow:hidden;">
                        <el-link style="float:right;" type="primary" :underline="false" @click="toDetails">查看详情>></el-link>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="8" style="margin-top:20px;">
                <el-card class="viewItem">
                    <img class="bgImg" src="@/assets/cluePicture/bg5.png" alt="" />
                    <div class="poImg">
                        <img src="@/assets/cluePicture/po5.png" alt="" />
                    </div>
                    <div class="display:flex;">
                        <div class="name">招标信息线索</div>
                        <div class="price">￥100/10次</div>
                    </div>
                    <div class="vip">VIP</div>
                    <div class="describe">清洗并补充您的数据库以挖掘具有价值的目标及潜在客户。</div>
                    <div style="overflow:hidden;">
                        <el-link style="float:right;" type="primary" :underline="false" @click="toDetails">查看详情>></el-link>
                    </div>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    data() {
        return {

        };
    },
    methods: {
        //前往介绍详情
        toDetails(){
            let routeData = this.$router.resolve({
                path: "/clue/introduce",
            });
            window.open(routeData.href, "_blank");
        },
    },
    mounted() {
        
    },
};
</script>

<style lang="scss" scoped>
.viewbox{
    background: rgba($color: #FFFFFF, $alpha: 0.7);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    .title{
        padding-left: 20px;
        line-height: 48px;
        font-size: 20px;
        font-weight: bold;
        color: #3A3A3A;
        border-bottom: 1px solid #E6E7EB;
    }
    .viewItem{
        position: relative;
        .bgImg{
            width: 100%;
            height: 180px;
        }
        .poImg{
            position: absolute;
            right: 45px;
            top: 175px;
            width: 50px;
            height: 50px;
            background: #FFFFFF;
            box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.16);
            border-radius: 50%;
            line-height: 60px;
            text-align: center;
        }
        .name{
            display: inline-block;
            font-size: 20px;
            line-height: 32px;
            font-weight: bold;
            color: #5F6B7D;
        }
        .price{
            display: inline-block;
            font-size: 14px;
            line-height: 32px;
            font-weight: bold;
            color: var(--themeColor);
            margin-left: 10px;
        }
        .vip{
            width: 50px;
            text-align: center;
            font-size: 14px;
            line-height: 22px;
            font-weight: bold;
            color: #FFC526;
            background: rgba(255, 197, 38, 0.2);
            border: 1px solid #FFC526;
            border-radius: 2px;
        }
        .describe{
            font-size: 14px;
            line-height: 22px;
            color: #666666;
        }
    }
}
</style>