<template>
    <div v-loading="cardLoading">
        <div class="header">
            <el-button type="primary" @click="toNewContact" size="small">新建联系人</el-button>
            <el-button @click="toMassMuch(1)" size="small" :disabled="selectionList.length == 0">邮件营销</el-button>
            <el-button @click="toMassMuch(2)" size="small" :disabled="selectionList.length == 0">WhatsApp群发</el-button>
            <el-button @click="toWriteMail" size="small" :disabled="selectionList.length == 0" icon="el-icon-s-promotion">写邮件</el-button>
            <el-button @click="onSync" size="small" :loading="btnLoading">同步联系人</el-button>
        </div>
        <div class="searchBox">
            <el-input v-model="filterParam.name" placeholder="姓名" size="small" clearable style="width:180px;"></el-input>
            <span style="padding:0 5px;"></span>
            <el-input v-model="filterParam.email" placeholder="邮箱" size="small" clearable style="width:180px;"></el-input>
            <span style="padding:0 5px;"></span>
            <el-input v-model="filterParam.tel" placeholder="手机号" size="small" clearable style="width:180px;"></el-input>
            <span style="padding:0 5px;"></span>
            <el-input v-model="filterParam.position" placeholder="职位" size="small" clearable style="width:180px;"></el-input>
            <span style="padding:0 5px;"></span>
            <el-checkbox v-model="filterParam.isEmail" @change="pageChange(1)">有邮箱</el-checkbox>
            <el-checkbox v-model="filterParam.isPhone" @change="pageChange(1)">有手机号</el-checkbox>
            <span style="padding:0 5px;"></span>
            <el-button icon="el-icon-search" size="small" @click="pageChange(1)">搜索</el-button>
        </div>
        
        <el-table :data="contactList" :header-cell-style="{'background-color':'#F2F2F2','color':'#3A3A3A'}"
            @selection-change="selectionChange" :row-key="(row) => {return row.key}">
            <el-table-column type="selection" width="45" :reserve-selection="true"></el-table-column>
            <el-table-column label="姓名">
                <template slot-scope="scope">
                    <div class="name">
                        <el-link type="primary" @click="toContactDetail(scope.row)">{{scope.row.name || "——"}}</el-link>
                        <i class="el-icon-edit" @click="toEdit(scope.row)"></i>
                        <el-tooltip class="item" effect="dark" content="Linkedin" placement="top">
                            <div class="inIcon" v-if="scope.row.linkedin" @click="jumpWeb(scope.row.linkedin)">
                                <svg t="1678958153071" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3034" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="20">
                                    <path d="M852 0 172 0C77.408 0 0 77.408 0 172l0 680c0 94.592 77.408 172 172 172l680 0c94.592 0 172-77.408 172-172L1024 172C1024 77.408 946.592 0 852 0zM384 832l-128 0L256 384l128 0L384 832zM320 320c-35.36 0-64-28.64-64-64s28.64-64 64-64 64 28.64 64 64S355.36 320 320 320zM832 832l-128 0L704 576c0-35.36-28.64-64-64-64s-64 28.64-64 64l0 256-128 0L448 384l128 0 0 79.456C602.4 427.2 642.752 384 688 384c79.52 0 144 71.648 144 160L832 832z" fill="var(--themeColor)" p-id="3035"></path>
                                </svg>
                            </div>
                        </el-tooltip>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="sexStr" label="性别" width="60px"></el-table-column>
            <el-table-column prop="position" label="职位"></el-table-column>
            <!-- <el-table-column prop="companyName" label="公司"></el-table-column> -->
            <el-table-column label="国家">
                <template slot-scope="scope">
                    <img :src="scope.row.flagUrl" style="margin-right:5px;height:14px;vertical-align: middle;" />
                    <span>{{ scope.row.country }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="email" label="邮箱">
                <template slot-scope="scope">
                    <EmailValue :value="scope.row.email" :isVerify="scope.row.isVerify" />
                </template>
            </el-table-column>
            <el-table-column prop="tel" label="手机号"></el-table-column>
        </el-table>

        <el-pagination hide-on-single-page background layout="total,prev,pager,next" :total="contactTotal" style="float:right;" :current-page.sync="pageIndex" @current-change="pageChange"></el-pagination>


        <el-dialog title="新建联系人" :visible.sync="newVisible" width="600px">
            <el-form label-width="80px" :model="newForm" size="small">
                <el-form-item label="姓名" required>
                    <el-input v-model="newForm.name"></el-input>
                </el-form-item>
                <el-form-item label="邮箱" required>
                    <el-input v-model="newForm.email"></el-input>
                </el-form-item>
                <el-form-item label="手机号" required>
                    <el-input v-model="newForm.tel"></el-input>
                </el-form-item>
                <el-form-item label="职位">
                    <el-input v-model="newForm.position"></el-input>
                </el-form-item>
                <el-form-item label="性别">
                    <el-radio-group v-model="newForm.sex">
                        <el-radio :label="1">男</el-radio>
                        <el-radio :label="0">女</el-radio>
                        <el-radio :label="2">未知</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="newVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="newSubmit" :loading="btnLoading">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog title="编辑联系人" :visible.sync="editVisible" width="600px">
            <el-form label-width="80px" :model="editInfo" size="small">
                <el-form-item label="姓名" required>
                    <el-input v-model="editInfo.name"></el-input>
                </el-form-item>
                <el-form-item label="邮箱" required>
                    <el-input v-model="editInfo.email"></el-input>
                </el-form-item>
                <el-form-item label="手机号" required>
                    <el-input v-model="editInfo.tel"></el-input>
                </el-form-item>
                <el-form-item label="职位">
                    <el-input v-model="editInfo.position"></el-input>
                </el-form-item>
                <el-form-item label="性别">
                    <el-radio-group v-model="editInfo.sex">
                        <el-radio :label="1">男</el-radio>
                        <el-radio :label="0">女</el-radio>
                        <el-radio :label="2">未知</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="editVisible = false">取 消</el-button>
                <el-button type="primary" size="small" @click="editFinish" :loading="btnLoading">确 定</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
export default {
    data() {
        return {
            myClueKey: "",
            filterParam:{
                name: "",
                email: "",
                tel: "",
                positoin: "",
                isPhone: false,
                isEmail: false,
            },
            // 联系人列表
            cardLoading: false,
            contactList: [],
            contactTotal: 0,
            pageIndex: 1,

            // 新建联系人
            newVisible: false,
            btnLoading: false,
            newForm: {},
            // 营销群发
            selectionList: [],

            // 编辑联系人
            editVisible: false,
            editInfo: {},
        };
    },
    methods: {
        //获取联系人列表
        getContacts(){
            this.cardLoading = true;
            this.$axios.post(`${this.$clueUrl}/MyClue/GetContactsClue`, {
                myClueKey: this.myClueKey,
                ...this.filterParam,//过滤条件
                pageIndex: this.pageIndex,
                pageSize: 10,
            }).then((res) => {
                const { isSuccess, data } = res;
                this.cardLoading = false;
                if (isSuccess) {
                    this.contactList = data.datas.map(item => ({
                        ...item,
                        flagUrl: window.getFlagUrlByCountry ? window.getFlagUrlByCountry(item.country) : '',
                    }))
                    this.contactTotal = data.totalItems;
                }
            });
        },
        pageChange(page) {
            this.pageIndex = page;
            this.getContacts();
        },

        
        selectionChange(value){
            this.selectionList = value;
        },
        //邮件营销(1) WhatsApp群发(2)
        toMassMuch(type){
            localStorage.setItem('myContacts',JSON.stringify(this.selectionList));
            if(type == 1){
                let routeData = this.$router.resolve({
                    // path: '/marketing/New',
                    path: '/marketing/MailMarketing',
                    query: { isContactMass: true },
                });
                window.open(routeData.href, "_blank");
            }
            if(type == 2){
                let routeData = this.$router.resolve({
                    // path: '/marketingWA/NewWA',
                    path: '/marketingWA/WAMass',
                    query: { isContactMass: true },
                });
                window.open(routeData.href, "_blank");
            }
            
        },

        //写邮件
        toWriteMail(){
            let receiver = this.selectionList.filter(item => item.email != "").map(item => item.email);
            // console.log(receiver);
            window.open(
                `${process.env.VUE_APP_MAIL_BASE}#/index/editor?type=send&receiver=${receiver.join(',')}`,
                "_blank"
            );
        },

        //新建联系人
        toNewContact(){
            this.newVisible = true;
            this.newForm = {
                myClueKey: this.myClueKey,
                name: "",
                email: "",
                tel: "",
                position: "",
                sex: 2,
            }
        },
        newSubmit(){
            let email = this.newForm.email.trim();
            let tel = this.newForm.tel.trim();
            let name = this.newForm.name.trim();
            if(!name){
                this.$message({ message: '请填写姓名!', type: 'warning'});
                return false;
            }
            if(!email && !tel){
                this.$message({ message: '请填写邮箱或手机号!', type: 'warning'});
                return false;
            }
            this.btnLoading = true;
            this.$axios.post(`${this.$clueUrl}/MyClue/AddContacts`,this.newForm).then((res) => {
                const { isSuccess } = res;
                this.btnLoading = false;
                this.newVisible = false;
                if (isSuccess) {    
                    this.$message({ message: '新建联系人成功', type: 'success'});
                    this.getContacts();
                }
            });
        },

        //同步联系人
        onSync(){
            this.btnLoading = true;
            this.$axios.get(`${this.$clueUrl}/MyClue/SyncContacts?myClueKey=${this.myClueKey}`).then((res) => {
                const { isSuccess, data } = res;
                this.btnLoading = false;
                if (isSuccess) {
                    if(data == 0){
                        this.$message({ message: '没有同步到联系人', type: 'success'});
                    }else{
                        this.$message({ message: '同步完成', type: 'success'});
                        this.getContacts();
                    }
                }
            });
        },

        //编辑联系人
        toEdit(row){
            this.editVisible = true;
            this.editInfo = {
                "key": row.key,
                "companyName": row.companyName,
                "country": row.country,
                "name": row.name,
                "email": row.email,
                "tel": row.tel,
                "position": row.position,
                "sex": row.sex,
            }
        },
        editFinish(){
            let email = this.editInfo.email.trim();
            let tel = this.editInfo.tel.trim();
            let name = this.editInfo.name.trim();
            if(!name){
                this.$message({ message: '请填写姓名!', type: 'warning'});
                return false;
            }
            if(!email && !tel){
                this.$message({ message: '请填写邮箱或手机号!', type: 'warning'});
                return false;
            }
            this.btnLoading = true;
            this.$axios.post(`${this.$clueUrl}/MyClue/UpdateContacts`,this.editInfo).then((res) => {
                const { isSuccess } = res;
                this.btnLoading = false;
                this.editVisible = false;
                if (isSuccess) {
                    this.$message({ message: '编辑完成', type: 'success'});
                    this.getContacts();
                }
            });
        },

        //查看联系人详情
        toContactDetail(row){
            let routeData = this.$router.resolve({
                path: "/clue/contactDetails",
                query: { key: row.key },
            });
            window.open(routeData.href, "_blank");
        },
        //打开外链
        jumpWeb(url){
            let jumpUrl = url.indexOf("http") > -1 ? url : "http://" + url;
            let routeData = this.$router.resolve({
                path: "/jumpWeb",
                query: { jumpUrl: jumpUrl },
            });
            window.open(routeData.href, "_blank");
        },
    },
    mounted() {
        this.myClueKey = this.$route.query.myClueKey;
        this.getContacts(); //获取联系人列表
    },
};
</script>

<style lang="scss" scoped>
.header{
    display: flex;
    margin-bottom: 10px;
}
.searchBox{
    display: flex;
    margin-bottom: 10px;
    align-items: center;
}
.name{
    display: flex;
    align-items: center;
    .el-icon-edit{
        margin-left: 10px;
        cursor: pointer;
        display: none;
        color: var(--themeColor);
    }
    &:hover .el-icon-edit{
        display: inline-block;
    }
    .inIcon{
        padding-left: 5px;
        cursor: pointer;
        display: inline-flex;
        align-items: center;
    }
}
</style>