<template>
    <div class="viewbox">
        <div class="boxOne">
            <div class="title">工单管理</div>
            <div class="rightHandle">
                <el-button size="small" type="primary" icon="el-icon-plus" @click="toNew">新建工单</el-button>
            </div>
        </div>

        <div class="boxTwo">
            <div class="txt">已选择{{chooseList.length}}个工单</div>
            <div class="rightHandle">
                <el-input v-model="filterParam.title" placeholder="工单标题" prefix-icon="el-icon-search" size="small" style="width:180px;margin-right:10px;" @keyup.enter.native="toSearch"></el-input>

                <el-select v-model="filterParam.status" placeholder="工单状态" size="small" style="width:180px;" @change="toSearch">
                    <!-- 0 待处理 1 已受理 2 已关闭 -->
                    <el-option label="待处理" :value="0"></el-option>
                    <el-option label="已受理" :value="1"></el-option>
                    <el-option label="已关闭" :value="2"></el-option>
                </el-select>
            </div>
        </div>

        <div class="boxTable">
            <el-table style="width:100%;cursor: pointer;" height="calc(100vh - 250px)" v-loading="tableLoading" :data="workOrderList"
                :header-cell-style="{'background-color':'#F2F2F2','color':'#3A3A3A'}" :row-style="rowStyle"
                @selection-change="selectionChange">
                <el-table-column type="selection" width="45"></el-table-column>
                <el-table-column prop="no" label="工单编号">
                    <template slot-scope="scope">
                        <el-link @click.stop="toDetails(scope.row)">{{scope.row.no}}</el-link>
                    </template>
                </el-table-column>
                <el-table-column prop="title" label="工单标题"></el-table-column>
                <el-table-column prop="status" label="工单状态">
                    <template slot-scope="scope">
                        <div v-show="scope.row.status=='待处理'" style="color: #F96B0D;">待处理</div>
                        <div v-show="scope.row.status=='已受理'" style="color: #13CE66;">已受理</div>
                        <div v-show="scope.row.status=='已关闭'" style="color: #9A9A9A;">已关闭</div>
                    </template>
                </el-table-column>
                <el-table-column prop="type" label="反馈类型"></el-table-column>
                <el-table-column prop="createUser" label="创建人"></el-table-column>
                <el-table-column prop="createTime" label="发布时间"></el-table-column>
                <!-- <el-table-column label="操作" width="100">
                    <template slot-scope="scope">
                        <el-link @click.stop="toDetails(scope.row)">查看</el-link>
                    </template>
                </el-table-column> -->
            </el-table>
        </div>

        <div class="bottomPagination">
            <el-button style="float:left;margin:15px 20px;" size="small" icon="el-icon-refresh" @click="onRefresh">刷 新</el-button>
            <el-pagination background layout="total,sizes,prev,pager,next" :page-sizes="[10, 20, 40]" :page-size.sync="pageSize" @size-change="pageSizeChange" :total="totalItems" style="float:right;margin:15px 20px;" :current-page.sync="pageIndex" @current-change="pageIndexChange"></el-pagination>
        </div>

    </div>
</template>

<script>
export default {
    props: {
        handleSelect: {
            type: Function,
            default: null,
        },
    },
    name: 'workOrderList',
    data() {
        return {
            // 过滤字段
            filterParam: {
                title: "",
                status: 0,
            },
            //列表展示
            tableLoading: false,
            workOrderList: [],
            totalItems: 0,
            pageIndex: 1,
            pageSize: 10,

            chooseList: [],
        };
    },
    methods: {
        onRefresh(){
            this.filterParam = {
                title: "",
                status: 0,
            };
            this.toSearch();
        },
        rowStyle({rowIndex}){
            // 表格隔行换色
            if(rowIndex % 2 == 1){
                return {'background-color':'#F9FAFC'}
            }
        },

        //新建工单
        toNew(){         
            this.handleSelect(`/workOrder/newWorkOrder`);
            this.$router.push(`/workOrder/newWorkOrder`);
        },
        
        //获取工单列表
        getList(){
            const {title,status} = this.filterParam;
            this.tableLoading = true;
            let uKey = JSON.parse(localStorage.userInfo).uKey;
            if(uKey=="55dfce24-0971-40b0-a328-f8620ca1c27f"){
                //获取所有工单(客服账号)
                this.$axios.post(`${this.$customerUrl}/WorkOrder/GetWorkOrder`,{
                    title,status,
                    pageIndex: this.pageIndex,
                    pageSize: this.pageSize
                }).then((res) => {
                    const { isSuccess , data } = res;
                    this.tableLoading = false;
                    if (isSuccess) {
                        this.workOrderList = data.datas;
                        this.totalItems = data.totalItems;
                    }
                });
            }else{
                //获取我的工单
                this.$axios.post(`${this.$customerUrl}/WorkOrder/GetMyWorkOrder`,{
                    title,status,
                    pageIndex: this.pageIndex,
                    pageSize: this.pageSize
                }).then((res) => {
                    const { isSuccess , data } = res;
                    this.tableLoading = false;
                    if (isSuccess) {
                        this.workOrderList = data.datas;
                        this.totalItems = data.totalItems;
                    }
                });
            }
        },
        toSearch(){
            this.pageIndex = 1;
            this.getList();
        },
        pageIndexChange(page) {
            this.pageIndex = page;
            this.getList();
        },
        pageSizeChange() {
            this.pageIndex = 1;
            this.getList();
        },
        //前往工单详情
        toDetails(row){
            let routeData = this.$router.resolve({
                path: "/workOrder/workOrderDetails",
                query: { workOrderKey: row.key },
            });
            window.open(routeData.href, "_blank");
        },

        selectionChange(value){
            this.chooseList = value;
        },

    },
    mounted() {
        this.getList();    //获取工单列表
    },
};
</script>

<style lang="scss" scoped>
.viewbox{
    background: rgba($color: #FFFFFF, $alpha: 0.7);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    margin-bottom: 60px;
    .boxOne,.boxTwo{
        overflow: hidden;
        padding: 8px 0;
        line-height: 32px;
        border-bottom: 1px solid #E6E7EB;
        .title{
            float: left;
            margin-left: 40px;
            font-size: 24px;
            font-weight: bold;
            color: #3A3A3A;
        }
        .txt{
            float: left;
            margin-left: 40px;
            font-size: 16px;
            color: #7C90A5;
        }
        .rightHandle{
            float: right;
            margin-right: 20px;
        }
    }

    .bottomPagination{
        position: fixed;
        bottom: 0;
        right: 0;
        z-index: 10;
        width: calc(100vw - 200px);
        height: 60px;
        background: #FFFFFF;
        box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.16);
    }
}
</style>