<template>
    <div>
        <div class="cardBox" v-if="clueSource == 3">
            <div class="title">商业数据</div>
            <BusinessData :clueRelationKey="clueRelationKey" />
        </div>

        <div class="cardBox" v-else-if="clueSource == 2">
            <div class="title">工商信息({{country}})</div>
            <CommonInfo v-if="comCountryList.includes(country)" />
            <CostaRica v-else-if="country=='哥斯达黎加'" />
            <Ecuador v-else-if="country=='厄瓜多尔'" />
            <India v-else-if="country=='印度'" />
            <Korea v-else-if="country=='韩国'" />
            <Peru v-else-if="country=='秘鲁'" />
            <Russia v-else-if="country=='俄罗斯'" />
            <Vietnam v-else-if="country=='越南'" />
            <America v-else-if="country=='美国'" />
            <Kenya v-else-if="country=='肯尼亚'" />
            <el-empty v-else :image-size="100"></el-empty>
        </div>

        <!-- <div class="cardBox" v-else>
            <el-empty description="暂无数据"></el-empty>
        </div> -->

        <div class="cardBox" style="margin-top: 20px;" v-show="marketAnalysis.length != 0 || productAnalysis.length != 0 || vendorAnalysis.length != 0">
            <div class="title">海关数据</div>
            <div class="sublevel">（1）市场分析 - 共 {{marketAnalysis.length}} 条</div>
            <el-table :data="marketAnalysis.slice(10*(this.marketPageIndex-1),10*this.marketPageIndex)" :header-cell-style="{'background-color':'#E5E9F2','color':'#1F2D3D'}">
                <el-table-column type="index" width="50" align="center"></el-table-column>
                <el-table-column prop="name" label="国家"></el-table-column>
                <el-table-column prop="frequency" label="频次"></el-table-column>
                <el-table-column prop="value" label="金额"></el-table-column>
                <el-table-column prop="quantity" label="数量"></el-table-column>
                <el-table-column prop="weight" label="重量"></el-table-column>
                <el-table-column prop="proportion" label="频次占比" width="320px">
                    <template slot-scope="scope">
                        <el-progress class="progress" color="#746B63" :show-text="false" :stroke-width="16" :percentage="scope.row.proportion*1"></el-progress>
                        <span>{{scope.row.proportion+"%"}}</span>
                    </template>
                </el-table-column>
            </el-table>
            <div style="overflow:hidden;">
                <el-pagination background layout="total,prev,pager,next" :total="marketAnalysis.length" style="float:right;margin-top:15px;" :current-page.sync="marketPageIndex"></el-pagination>
            </div>
            <div class="sublevel">（2）产品分析 - 共 {{productAnalysis.length}} 条</div>
            <el-table :data="productAnalysis.slice(10*(this.productPageIndex-1),10*this.productPageIndex)" :header-cell-style="{'background-color':'#E5E9F2','color':'#1F2D3D'}">
                <el-table-column type="index" width="50" align="center"></el-table-column>
                <el-table-column prop="name" label="HS编码"></el-table-column>
                <el-table-column prop="frequency" label="频次"></el-table-column>
                <el-table-column prop="value" label="金额"></el-table-column>
                <el-table-column prop="quantity" label="数量"></el-table-column>
                <el-table-column prop="weight" label="重量"></el-table-column>
                <el-table-column prop="proportion" label="频次占比" width="320px">
                    <template slot-scope="scope">
                        <el-progress class="progress" color="#E99019" :show-text="false" :stroke-width="16" :percentage="scope.row.proportion*1"></el-progress>
                        <span>{{scope.row.proportion+"%"}}</span>
                    </template>
                </el-table-column>
            </el-table>
            <div style="overflow:hidden;">
                <el-pagination background layout="total,prev,pager,next" :total="productAnalysis.length" style="float:right;margin-top:15px;" :current-page.sync="productPageIndex"></el-pagination>
            </div>
            <div class="sublevel">（3）供应商分析 - 共 {{vendorAnalysis.length}} 条</div>
            <el-table :data="vendorAnalysis.slice(10*(this.vendorPageIndex-1),10*this.vendorPageIndex)" :header-cell-style="{'background-color':'#E5E9F2','color':'#1F2D3D'}">
                <el-table-column type="index" width="50" align="center"></el-table-column>
                <el-table-column prop="name" label="供应商"></el-table-column>
                <el-table-column prop="frequency" label="频次"></el-table-column>
                <el-table-column prop="value" label="金额"></el-table-column>
                <el-table-column prop="quantity" label="数量"></el-table-column>
                <el-table-column prop="weight" label="重量"></el-table-column>
                <el-table-column prop="proportion" label="频次占比" width="320px">
                    <template slot-scope="scope">
                        <el-progress class="progress" color="#B28B7C" :show-text="false" :stroke-width="16" :percentage="scope.row.proportion*1"></el-progress>
                        <span>{{scope.row.proportion+"%"}}</span>
                    </template>
                </el-table-column>
            </el-table>
            <div style="overflow:hidden;">
                <el-pagination background layout="total,prev,pager,next" :total="vendorAnalysis.length" style="float:right;margin-top:15px;" :current-page.sync="vendorPageIndex"></el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import BusinessData from "../BusinessInfo/BusinessData.vue"
import CommonInfo from "../BusinessInfo/CommonInfo.vue";
import CostaRica from "../BusinessInfo/CostaRica.vue";   //哥斯达黎加
import Ecuador from "../BusinessInfo/Ecuador.vue";       //厄瓜多尔
import India from "../BusinessInfo/India.vue";           //印度
import Korea from "../BusinessInfo/Korea.vue";           //韩国
import Peru from "../BusinessInfo/Peru.vue";             //秘鲁
import Russia from "../BusinessInfo/Russia.vue";         //俄罗斯
import Vietnam from "../BusinessInfo/Vietnam.vue";       //越南
import America from "../BusinessInfo/America.vue";       //美国
import Kenya from "../BusinessInfo/Kenya.vue";           //肯尼亚

export default {
    components: { BusinessData,CommonInfo,CostaRica,Ecuador,India,Korea,Peru,Russia,Vietnam,America,Kenya },
    props: ["clueSource","clueRelationKey"],
    data() {
        return {
            companyName: "",
            comCountryList:["孟加拉","巴西","博茨瓦纳","喀麦隆","德国","埃塞俄比亚","印度尼西亚","哈萨克斯坦","利比里亚","墨西哥","尼日利亚","菲律宾","巴基斯坦","新加坡","西班牙","坦桑尼亚","乌干达","英国","意大利"],
            country: "",

            //海关数据
            marketAnalysis: [],
            productAnalysis : [],
            vendorAnalysis: [],
            marketPageIndex: 1,
            productPageIndex: 1,
            vendorPageIndex: 1,
        };
    },
    methods: {
        // 获取海关数据(市场分析,产品分析,供应商分析)
        getCustomsData(){
            // 先获取SearchKey
            this.$axios.get(`${this.$clueUrl}/BuyerLeads/GetSearchKey?company=${encodeURIComponent(this.companyName)}`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    let searchKey = data;
                    // 通过SearchKey获取 市场分析数据
                    this.$axios.get(`${this.$clueUrl}/BuyerLeads/GetCountryRanking?searchKey=${searchKey}`).then((res) => {
                        const { isSuccess, data } = res;
                        if (isSuccess) {
                            data.forEach(element => {
                                element.value = element.value.toFixed(2);
                                element.quantity = element.quantity.toFixed(2);
                                element.weight = element.weight.toFixed(2);
                            });
                            this.marketAnalysis = data;
                        }
                    });
                    // 通过SearchKey获取 产品分析数据
                    this.$axios.get(`${this.$clueUrl}/BuyerLeads/GetHsCodeRanking?searchKey=${searchKey}`).then((res) => {
                        const { isSuccess, data } = res;
                        if (isSuccess) {
                            data.forEach(element => {
                                element.value = element.value.toFixed(2);
                                element.quantity = element.quantity.toFixed(2);
                                element.weight = element.weight.toFixed(2);
                            });
                            this.productAnalysis = data;
                        }
                    });
                    // 通过SearchKey获取 供应商分析数据
                    this.$axios.get(`${this.$clueUrl}/BuyerLeads/GetImporterRanking?searchKey=${searchKey}`).then((res) => {
                        const { isSuccess, data } = res;
                        if (isSuccess) {
                            data.forEach(element => {
                                element.value = element.value.toFixed(2);
                                element.quantity = element.quantity.toFixed(2);
                                element.weight = element.weight.toFixed(2);
                            });
                            this.vendorAnalysis = data;
                        }
                    });
                }
            });
        },
    },
    mounted() {
        this.companyName = this.$route.query.companyName;
        this.country = this.$route.query.country;
        if(this.companyName){
            this.getCustomsData();  // 获取海关数据
        }
    },
};
</script>

<style lang="scss" scoped>
.cardBox{
    background: #ffffff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    padding: 15px 20px;
    .title{
        font-size: 20px;
        height: 20px;
        line-height: 20px;
        font-weight: bold;
        color: #3A3A3A;
        padding-left: 8px;
        border-left: 3px solid var(--themeColor);
        margin-bottom: 15px;
    }
    .sublevel{
        margin-top: 20px;
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 20px;
        font-weight: bold;
        color: #3A3A3A;
    }
    .progress{
        width: 240px;
        display: inline-block;
    }
}
</style>