<template>
    <div class="PurchaseDetails">
        <div class="headline">
            <span>采购详情</span>
            <div class="rightBtn">
                <el-button size="small" :loading="btnLoading" @click="actionFunction(1)">我要采购</el-button>
                <el-button size="small" :loading="btnLoading" @click="actionFunction(2)">生成订单</el-button>
                <el-button size="small" :loading="btnLoading" @click="actionFunction(3)">生成商机</el-button>
            </div>
        </div>
        <div class="oneItem">
            <div class="leftImg">
                <el-image style="width: 100px; height: 100px" :src="showImgUrl" :preview-src-list="previewList"></el-image>
            </div>
            <div class="rightBox">
                <div>
                    <span class="title">{{detailsInfo.title}}</span>
                    <span class="date">{{detailsInfo.purchaseStartDate}} to {{detailsInfo.purchaseEndDate}}</span>
                    <el-tag effect="dark" type="success" size="mini" v-if="detailsInfo.expireStatus==1">长期</el-tag>
                    <el-tag effect="dark" type="info" size="mini" v-if="detailsInfo.expireStatus==2">过期</el-tag>
                    <el-tag effect="dark" type="warning" size="mini" v-if="detailsInfo.urgentStatus==1">紧急</el-tag>
                </div>
                <div class="oneHang" style="display: flex;">
                    <div style="margin-right:10px;"><span class="label">交货地：</span>{{detailsInfo.placeDelivery}}</div>
                    <div style="margin-right:10px;"><span class="label">采购国：</span>{{detailsInfo.purchasingCountry}}</div>
                </div>
                <div class="oneHang" style="display: flex;">
                    <div style="margin-right:10px;"><span class="label">公司：</span>{{detailsInfo.company}}</div>
                </div>
                <div class="oneHang" style="display: flex;">
                    <div style="margin-right:10px;"><span class="label">联系人：</span>{{detailsInfo.contacts}}</div>
                    <div style="margin-right:10px;"><span class="label">电话：</span>{{detailsInfo.contactPhone}}</div>
                    <div style="margin-right:10px;"><span class="label">邮箱：</span>{{detailsInfo.contactEmail}}</div>
                </div>
                <div class="oneHang">
                    <span class="label">采购量：</span>{{detailsInfo.purchaseVolume}} {{detailsInfo.unit}}
                </div>
                <div class="oneHang">
                    <span class="label">需求描述：</span>{{detailsInfo.describe}}
                </div>
                <div class="oneHang">
                    <span class="label">采购要求：</span>{{detailsInfo.requirement}}
                </div>
            </div>
        </div>
        <div class="picture" v-if="imgList.length >= 2">
            <div class="tt">图片信息</div>
            <el-image class="imgItem" v-for="(item,index) in imgList" :key="index" :src="item.imgUrl" :preview-src-list="previewList"></el-image>
        </div>
        <div class="fujian" style="margin-top: 10px;">
            <div class="tt">附件列表</div>
            <div v-if="attachmentList.length > 0">
                <div class="attchItem" v-for="(item,index) in attachmentList" :key="index">
                    <img src="@/assets/icon/fujian.png" />
                    <el-link :href="item.imgUrl" :underline="false" target="_blank">
                        <span style="padding-right: 10px;">{{item.imgName}}</span>
                        <i class="el-icon-download" style="font-size: 20px;"></i>
                    </el-link>
                </div>
            </div>
            <div v-else>
                <span style="padding-left:10px;">暂无数据</span>
            </div>
        </div>
        <div class="submitInfo" style="margin-top: 10px;">
            <div class="tt">提交供应信息</div>
            <el-form :model="submitForm" label-width="100px" size="small" style="width: 500px;">
                <el-form-item label="供应商">
                    <el-input v-model="submitForm.company"></el-input>
                </el-form-item>
                <el-form-item label="联系人">
                    <el-input v-model="submitForm.contacts"></el-input>
                </el-form-item>
                <el-form-item label="联系人电话">
                    <el-input v-model="submitForm.contactPhone"></el-input>
                </el-form-item>
                <el-form-item label="供应描述">
                    <el-input type="textarea" v-model="submitForm.describe" :rows="3" :maxlength="2000" show-word-limit></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit" size="mini" :loading="btnLoading">提 交</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            //采购详情
            detailsInfo: {},
            imgList: [],
            attachmentList: [],
            previewList: [],
            showImgUrl: "",
            //提交供应信息
            btnLoading: false,
            submitForm: {
                purchaseDemandKey: "",
                company: "",
                contacts: "",
                contactPhone: "",
                describe: "",
                source: "",
                ukey: "",
            },
        };
    },
    methods: {
        getDetails(){
            let key = this.$route.query.key;
            let ukey = JSON.parse(localStorage.userInfo).uKey; //记录用户进入详情
            this.$axios.get(`${this.$OAService}/GetPurchaseDemandDetails?key=${key}&ukey=${ukey}`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.detailsInfo = data;
                    this.imgList = data.imgs.filter(ele => ele.type == 0);
                    this.attachmentList = data.imgs.filter(ele => ele.type == 1);
                    this.previewList = this.imgList.map(ele => ele.imgUrl);
                    this.showImgUrl = this.imgList[0].imgUrl;
                }
            })
        },
        //提交供应信息
        onSubmit(){
            this.submitForm.purchaseDemandKey = this.$route.query.key;
            this.submitForm.source = "CRM系统";
            this.submitForm.ukey = JSON.parse(localStorage.userInfo).uKey;
            console.log(this.submitForm);
            const {company,contacts,contactPhone,describe} = this.submitForm;
            if(!company || !contacts || !contactPhone || !describe){
                this.$message({ type: 'warning', message: '供应信息确保完整!'});
                return false;
            }
            this.btnLoading = true;
            this.$axios.post(`${this.$OAService}/SaveSupplier`, this.submitForm).then((res) => {
                const { isSuccess } = res;
                this.btnLoading = false;
                if (isSuccess) {
                    this.$message({message: '提交完成!请等待该公司的联系!',type: 'success'});
                    this.submitForm = {
                        company: "",
                        contacts: "",
                        contactPhone: "",
                        describe: "",
                    }
                }
            });
        },

        //新增3个功能按钮
        actionFunction(type){
            switch (type) {
                case 1:
                    this.btnLoading = true;
                    this.$axios.post(`${this.$customerUrl}/Procure/CreatePurchasingRecord`,this.detailsInfo).then((res) => {
                        const { isSuccess, data } = res;
                        this.btnLoading = false;
                        if (isSuccess) {
                            this.$message({message: '添加成功!',type: 'success'});
                            let routeData = this.$router.resolve({
                                path: "/purchase/recordDetails",
                                query: { key: data },
                            });
                            window.open(routeData.href, "_blank");
                        }
                    });
                    break;
                case 2:
                    this.btnLoading = true;
                    this.$axios.post(`${this.$customerUrl}/Procure/CreateOrder`,this.detailsInfo).then((res) => {
                        const { isSuccess, data } = res;
                        this.btnLoading = false;
                        if (isSuccess) {
                            this.$message({message: '添加成功!',type: 'success'});
                            let routeData = this.$router.resolve({
                                path: "/orderManage/orderDetails",
                                query: { orderKey: data },
                            });
                            window.open(routeData.href, "_blank");
                        }
                    });
                    break;
                case 3:
                    this.btnLoading = true;
                    this.$axios.post(`${this.$customerUrl}/Procure/CreateBusiness`,this.detailsInfo).then((res) => {
                        const { isSuccess, data } = res;
                        this.btnLoading = false;
                        if (isSuccess) {
                            this.$message({message: '添加成功!',type: 'success'});
                            let routeData = this.$router.resolve({
                                path: "/business/businessManage",
                            });
                            window.open(routeData.href, "_blank");
                        }
                    });
                    break;
                default:
                    break;
            }
        },
    },
    mounted() {
        this.getDetails();
    },
};
</script>

<style lang="scss" scoped>
.PurchaseDetails{
    background: #FFFFFF;
    padding: 10px 20px;
    overflow: hidden;
    .headline{
        font-size: 20px;
        line-height: 40px;
        color: #333;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
    }
    .oneItem{
        padding: 10px;
        display: flex;
        .leftImg{
            margin-right: 20px;
        }
        .rightBox{
            font-size: 14px;
            color: #3A3A3A;
            .title{
                display: inline-block;
                font-size: 18px;
                height: 32px;
                line-height: 32px;
                color: #333;
                font-weight: bold;
            }
            .date{
                display: inline-block;
                padding-left: 20px;
            }
            .oneHang{
                line-height: 24px;
            }
            .label{
                color: #999999;
            }
        }
    }
    .picture,.fujian,.submitInfo{
        .tt{
            height: 20px;
            line-height: 20px;
            font-size: 16px;
            font-weight: bold;
            color: #3A3A3A;
            margin-bottom: 10px;
            border-left: 3px solid var(--themeColor);
            padding-left: 5px;
        }
        .imgItem{
            width: 80px;
            height: 80px;
            display: inline-block;
            margin-right: 10px;
        }
        .attchItem{
            display: inline-flex;
            width: 180px;
            height: 60px;
            background: #d9d9d9;
            margin-right: 10px;
            img{
                width: 27px;
                height: 33px;
                margin: 12px;
            }
        }
    }
}
</style>