<template>
  <!-- 全景分析下内容，不复用 -->
  <div id="abroad-sitc" class="abroad-sitc" v-loading="loading" v-show="!(industryData.length === 0 && hidden)">
    <div class="title" style="padding-left: 1rem" ref="title">
      <p>SITC——国际贸易标准分类</p>
      <feedback position="SITC——国际贸易标准分类" url="/search/Credit/Sitc" />
      <!-- <div class="flex-container">
        <img class="icon" src="@/firm/images/company/icon-feedback.png" style="margin-left: 1em" />
        <span class="text-with-icon" style="color: var(--themeColor, #3152AF)">反馈</span>
      </div> -->
    </div>
    <div class="content">
      <div v-for="(item, index) of industryData" :key="index">
        <el-tooltip class="item" effect="light" placement="top-start">
          <div slot="content">{{ item.sitc }}<br />{{ item.sitcDescription }}</div>
          <div class="item">
            <!-- <span class="badge">{{ index + 1 }}</span> -->
            <p class="title">{{ `${item.sitc}` }}</p>
            <p class="description">{{ item.sitcDescription }}</p>
          </div>
        </el-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "@/firm/plugins/axios";
import { mapState, mapGetters, mapMutations } from "vuex";
import { AnchorInditor } from "../../../utils";
import LazyOperation from "@/firm/plugins/lazy-execute";

let lazyOperation = null;

export default Vue.extend({
  data() {
    return {
      loading: false,
      industryData: [],
      hidden: false,
    };
  },
  computed: {
    ...mapState(["company", "companyType"]),
    ...mapState("controlFormGlobal", {
      ie: "ie",
      dataCountry: "dataCountry",
      dateRange: "dateRange",
      hsCode: "hsCode",
      importer: "importer",
      exporter: "exporter",
      country: "country",

      exHsCode: "exHsCode",
      exExporter: "exExporter",
      exImporter: "exImporter",
      exCountry: "exCountry",
    }),
    ...mapGetters("controlFormGlobal", {
      startTime: "startTime",
      endTime: "endTime",
    }),
  },
  watch: {
    ie() {
      this.hidden = false;
      this.setAnchorFlag([["SITC", true]]);
      lazyOperation?.reobserve();
    },
    dataCountry() {
      this.hidden = false;
      this.setAnchorFlag([["SITC", true]]);
      lazyOperation?.reobserve();
    },
    dateRange() {
      this.hidden = false;
      this.setAnchorFlag([["SITC", true]]);
      lazyOperation?.reobserve();
    },
    hsCode() {
      this.hidden = false;
      this.setAnchorFlag([["SITC", true]]);
      lazyOperation?.reobserve();
    },
    importer() {
      this.hidden = false;
      this.setAnchorFlag([["SITC", true]]);
      lazyOperation?.reobserve();
    },
    exporter() {
      this.hidden = false;
      this.setAnchorFlag([["SITC", true]]);
      lazyOperation?.reobserve();
    },
    country() {
      this.hidden = false;
      this.setAnchorFlag([["SITC", true]]);
      lazyOperation?.reobserve();
    },

    exHsCode() {
      this.hidden = false;
      this.setAnchorFlag([["SITC", true]]);
      lazyOperation?.reobserve();
    },
    exExporter() {
      this.hidden = false;
      this.setAnchorFlag([["SITC", true]]);
      lazyOperation?.reobserve();
    },
    exImporter() {
      this.hidden = false;
      this.setAnchorFlag([["SITC", true]]);
      lazyOperation?.reobserve();
    },
    exCountry() {
      this.hidden = false;
      this.setAnchorFlag([["SITC", true]]);
      lazyOperation?.reobserve();
    },

    companyType() {
      this.hidden = false;
      this.setAnchorFlag([["SITC", true]]);
      lazyOperation?.reobserve();
    },
  },
  methods: {
    ...mapMutations("anchor", {
      setAnchorFlag: "setAnchorFlag",
      setCurrent: "setCurrent",
    }),
    async getData() {
      try {
        this.loading = true;
        const res = await axios({
          method: "post",
          url: "/search/Credit/Ranking/HsCode",
          data: {
            pageIndex: 1,
            pageSize: 100,
            ie: this.ie,
            importer: this.importer,
            exporter: this.exporter,
            dataCountry: this.dataCountry,
            country: this.country,
            startTime: this.startTime,
            endTime: this.endTime,
            hsCode: this.hsCode,
            sortType: 0,
            noHsCode: this.exHsCode,
            noExporter: this.exExporter,
            noImporter: this.exImporter,
            noCountry: this.exCountry,
            company: this.company,
            companyType: this.companyType,
          },
        });
        const data = res.data.result.data.map((item) => item.encode);
        const result = await axios({
          method: "post",
          url: "/search/Credit/Sitc",
          data,
        });
        const dataS = result.data.result;
        this.industryData = dataS || [];
        if (this.industryData.length === 0) {
          //this.setAnchorFlag([["SITC", true]]);
          throw new Error();
        }
        this.setAnchorFlag([["SITC", true]]);
      } catch {
        this.setAnchorFlag([["SITC", false]]);
        this.industryData = [];
        this.hidden = true;
      } finally {
        this.loading = false;
      }
    },
  },
  async mounted() {
    lazyOperation = new LazyOperation(() => {
      this.industryData = [];
      this.getData();
    }, this.$el);
    new AnchorInditor(() => {
      this.setCurrent("SITC");
    }, this.$refs["title"]);
  },
});
</script>

<style lang="scss" scoped>
@import "@/firm/scss/mixin.scss";

.abroad-sitc {
  margin-top: 1rem;
  background: #fff;
  padding: 1rem;
  padding-left: 0;
  padding-top: 0;
  @include mixin-panel-title;
  .content {
    display: flex;
    flex-wrap: wrap;
    .item {
      border-right: 5px solid #fff;
      box-sizing: border-box;
      border-bottom: 15px solid #fff;
      height: 80px;
      overflow: hidden;
      margin-left: 33px;
      margin-bottom: 33px;
      width: 250px;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
      border-radius: 4px;
      position: relative;
      .badge {
        font-size: 13px;
        position: absolute;
        top: 5px;
        left: 5px;
        color: var(--themeColor, #3152AF);
      }
      $paddingLeft: 2em;
      .title {
        height: auto;
        margin-left: $paddingLeft;
        margin-top: 12px;
        font-size: 13px;
        color: var(--themeColor, #3152AF);
      }
      .description {
        margin-left: $paddingLeft;
        font-size: 13px;
        margin-bottom: 12px;
        color: #909090;
      }
    }
  }
}
</style>
