<template>
    <div>
        <InterfaceName :interfaceName="interfaceName" />
        <InterfaceSpe :requestUrl="requestUrl" :requestMethod="requestMethod" />
        <InputParam :inputTableData="inputTableData" />
        <ReturnParam :returnObj="returnObj" />   
    </div>
</template>

<script>
import InterfaceName from "../Acomponents/InterfaceName.vue";
import InterfaceSpe from "../Acomponents/InterfaceSpe.vue";
import InputParam from "../Acomponents/InputParam.vue";
import ReturnParam from "../Acomponents/ReturnParam.vue";

export default {
    components: { InterfaceName, InterfaceSpe, InputParam, ReturnParam },
    data() {
        return {
            interfaceName: "上传文件",
            requestUrl: "https://crm.qixinerp.com/open/Upload",
            requestMethod: "POST",
            inputTableData: [],
            returnObj: {},
        };
    },
    methods: {
        
    },
    mounted() {
        this.inputTableData = [
            {paramName: "base64", valueType: "string", explain: `文件base64(例如: var base64 = $"data:image/jpg;base64," + Convert.ToBase64String(bt);)`, required: true},
            {paramName: "fileName", valueType: "string", explain: "文件名称", required: true},
            {paramName: "fileExt", valueType: "string", explain: "文件后缀名(例如: .jpg)", required: true},
        ]
        this.returnObj = {
            "isSuccess": true,
            "errorMessage": null,
            "data": {
                "fileName": "xxx.jpg",
                "fileSize": "80.33K",
                "fileUrl": "https://crm.qixinerp.com/ossfile/upfiles/file/xxx.jpg",
                "fileType": "jpg"
            }
        }
    },
};
</script>

<style lang="scss" scoped>

</style>