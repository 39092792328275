import Vue from 'vue'
import router from '../router'


export const toFirm = ({ company, countryCode, item }) => {
  Vue.prototype.$axios({
    method: 'POST',
    url: 'https://crm.qixinerp.com/search/Credit/Exchange/Key',
    data: {
      company,
      ie: "I",
      "importerOrExporter": "Importer",
      "mergerCompany": []
    }
  }).then(res => {

    const url = router.resolve({
      name: 'Firm', query: {
        Token: res.data,
        countryCode
      }
    }).href
    window.open(url).sessionStorage.setItem('xiansuo-2r23fafaw', JSON.stringify(item))
  })
}
