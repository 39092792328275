<template>
    <div>
        <!-- <div style="height: 60px; padding-left: 10px; display: flex; align-items: center; font-size: 14px; color: #707070">
            <span style="flex-shrink: 0">范围：</span>
            <el-input v-model="range" placeholder="所有分组" prefix-icon="el-icon-search" size="small" style="width: 220px" />
        </div> -->

        <el-row :gutter="16" style="margin-top: 15px;">
            <el-col :span="4">
                <div class="smallCard">
                    <div class="tt">年度利润</div>
                    <div class="profit">{{ annualProfit }}元</div>
                    <div style="display: flex;border-top:1px solid #eee;padding-top:5px;">
                        <div class="twoField" style="border-right:1px solid #eee;">
                            <div class="value">0%</div>
                            <div class="label">完成率</div>
                        </div>
                        <div class="twoField">
                            <div class="value">0%</div>
                            <div class="label">同比</div>
                        </div>
                    </div>
                </div>
            </el-col>
            <el-col :span="4">
                <div class="smallCard">
                    <div class="tt">
                        年度收入<span style="font-size:12px;color:#666;">目标：{{ annualTarget }}</span>
                    </div>
                    <div class="progress">
                        <el-progress type="dashboard" :percentage="annualRatio" color="var(--themeColor)"
                            :stroke-width="16"></el-progress>
                    </div>
                    <div style="display: flex;border-top:1px solid #eee;padding-top:5px;">
                        <div class="twoField" style="border-right:1px solid #eee;">
                            <div class="value">{{ annualIncome }}</div>
                            <div class="label">累计收入</div>
                        </div>
                        <div class="twoField">
                            <div class="value">0%</div>
                            <div class="label">同比</div>
                        </div>
                    </div>
                </div>
            </el-col>
            <el-col :span="4">
                <div class="smallCard">
                    <div class="tt">年度支出</div>
                    <div class="progress">
                        <el-progress type="dashboard" :percentage="0" color="#1989fa" :stroke-width="16"></el-progress>
                    </div>
                    <div style="display: flex;border-top:1px solid #eee;padding-top:5px;">
                        <div class="twoField" style="border-right:1px solid #eee;">
                            <div class="value">{{ annualExpend }}</div>
                            <div class="label">累计支出</div>
                        </div>
                        <div class="twoField">
                            <div class="value">0%</div>
                            <div class="label">同比</div>
                        </div>
                    </div>
                </div>
            </el-col>
            <el-col :span="4">
                <div class="smallCard">
                    <div class="tt">月度利润</div>
                    <div class="profit">{{ monthlyProfit }}元</div>
                    <div style="display: flex;border-top:1px solid #eee;padding-top:5px;">
                        <div class="twoField" style="border-right:1px solid #eee;">
                            <div class="value">0%</div>
                            <div class="label">完成率</div>
                        </div>
                        <div class="twoField">
                            <div class="value">0%</div>
                            <div class="label">同比</div>
                        </div>
                    </div>
                </div>
            </el-col>
            <el-col :span="4">
                <div class="smallCard">
                    <div class="tt">
                        月度收入<span style="font-size:12px;color:#666;">目标：{{ monthlyTarget }}</span>
                    </div>
                    <div class="progress">
                        <el-progress type="dashboard" :percentage="monthlyRatio" color="var(--themeColor)"
                            :stroke-width="16"></el-progress>
                    </div>
                    <div style="display: flex;border-top:1px solid #eee;padding-top:5px;">
                        <div class="twoField" style="border-right:1px solid #eee;">
                            <div class="value">{{ monthlyIncome }}</div>
                            <div class="label">累计收入</div>
                        </div>
                        <div class="twoField">
                            <div class="value">0%</div>
                            <div class="label">同比</div>
                        </div>
                    </div>
                </div>
            </el-col>
            <el-col :span="4">
                <div class="smallCard">
                    <div class="tt">月度支出</div>
                    <div class="progress">
                        <el-progress type="dashboard" :percentage="0" color="#1989fa" :stroke-width="16"></el-progress>
                    </div>
                    <div style="display: flex;border-top:1px solid #eee;padding-top:5px;">
                        <div class="twoField" style="border-right:1px solid #eee;">
                            <div class="value">{{ monthlyExpend }}</div>
                            <div class="label">累计支出</div>
                        </div>
                        <div class="twoField">
                            <div class="value">0%</div>
                            <div class="label">同比</div>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>

        <div class="grid-container" style="height: calc(100% - 292px)">
            <div class="grid-item-panel" v-loading="incomeCustomer.loading">
                <!-- <div class="grid-item-select">
                    <div @click="setType('incomeCustomer', '1')" class="grid-item-select-option" :class="{ active: incomeCustomer.type === '1' }">客户</div>
                    <div @click="setType('incomeCustomer', '2')" class="grid-item-select-option" :class="{ active: incomeCustomer.type === '2' }">城市</div>
                    <div @click="setType('incomeCustomer', '3')" class="grid-item-select-option" :class="{ active: incomeCustomer.type === '3' }">产品</div>
                </div> -->
                <div class="grid-item-panel-title">
                    <div style="display: flex; align-items: flex-end">
                        <span class="grid-item-panel-title-text">收汇客户详情</span>
                        <span class="grid-item-panel-title-sub-text" style="margin-left: 10px">{{ incomeCustomer.date
                        }}</span>
                        <span style="margin-left: 10px; color: #6a6a6a;">总额：<em
                                style="color:#e6a23c">{{ incomeCustomer.totalMoney }}元</em></span>
                    </div>
                    <div class="icon-container">
                        <img src="@/assets/icon/refresh.png" />
                        <img src="@/assets/icon/close.png" />
                    </div>
                </div>
                <div ref="incomeCustomer-dom" class="grid-item-panel-chart-dom"></div>
            </div>

            <div class="grid-item-panel" v-loading="expendCustomer.loading">
                <div class="grid-item-panel-title">
                    <div style="display: flex; align-items: flex-end">
                        <span class="grid-item-panel-title-text">支出客户详情</span>
                        <span class="grid-item-panel-title-sub-text" style="margin-left: 10px">{{ expendCustomer.date
                        }}</span>
                        <span style="margin-left: 10px; color: #6a6a6a;">总额：<em
                                style="color:#e6a23c">{{ expendCustomer.totalMoney }}元</em></span>
                    </div>
                    <div class="icon-container">
                        <img src="@/assets/icon/refresh.png" />
                        <img src="@/assets/icon/close.png" />
                    </div>
                </div>
                <div ref="expendCustomer-dom" class="grid-item-panel-chart-dom"></div>
            </div>

            <div class="grid-item-panel" v-loading="incomeMoneyForm.loading">
                <div class="grid-item-panel-title">
                    <div style="display: flex; align-items: flex-end">
                        <span class="grid-item-panel-title-text">收款金额构成</span>
                        <span class="grid-item-panel-title-sub-text" style="margin-left: 10px">{{ incomeMoneyForm.date
                        }}</span>
                    </div>
                    <div class="icon-container">
                        <img src="@/assets/icon/refresh.png" />
                        <img src="@/assets/icon/close.png" />
                    </div>
                </div>
                <div ref="incomeMoneyForm-dom" class="grid-item-panel-chart-dom"></div>
            </div>

            <div class="grid-item-panel" v-loading="expendMoneyForm.loading">
                <div class="grid-item-panel-title">
                    <div style="display: flex; align-items: flex-end">
                        <span class="grid-item-panel-title-text">支出金额构成</span>
                        <span class="grid-item-panel-title-sub-text" style="margin-left: 10px">{{ expendMoneyForm.date
                        }}</span>
                    </div>
                    <div class="icon-container">
                        <img src="@/assets/icon/refresh.png" />
                        <img src="@/assets/icon/close.png" />
                    </div>
                </div>
                <div ref="expendMoneyForm-dom" class="grid-item-panel-chart-dom"></div>
            </div>

            <div class="grid-item-panel" v-loading="incomeExpendCondition.loading">
                <div class="grid-item-panel-title">
                    <div style="display: flex; align-items: flex-end">
                        <span class="grid-item-panel-title-text">收支情况</span>
                        <el-select v-model="incomeExpendCondition.typeValue" @change="getFiveChart" size="mini"
                            style="margin-left:20px;width:100px;">
                            <el-option label="收入" :value="1"></el-option>
                            <el-option label="支出" :value="2"></el-option>
                        </el-select>
                        <!-- <el-select v-model="incomeExpendCondition.staffValue" placeholder="请选择人员" size="mini" style="margin-left:20px;">
                            <el-option v-for="item in staffOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select> -->
                    </div>
                    <div class="icon-container">
                        <img src="@/assets/icon/refresh.png" />
                        <img src="@/assets/icon/close.png" />
                    </div>
                </div>
                <div class="grid-item-panel-chart-dom">
                    <el-table :data="incomeExpendCondition.tableData" height="calc(100% - 20px)">
                        <el-table-column label="日期" prop="date" show-overflow-tooltip />
                        <el-table-column label="类型" prop="type" show-overflow-tooltip />
                        <el-table-column label="客户" prop="companyName" show-overflow-tooltip />
                        <el-table-column label="金额" prop="amount" show-overflow-tooltip />
                        <el-table-column label="款项名称" prop="paymentName" show-overflow-tooltip />
                    </el-table>
                </div>
            </div>

            <div class="grid-item-panel" v-loading="comparisonForm.loading">
                <div class="grid-item-panel-title">
                    <div style="display: flex; align-items: flex-end">
                        <span class="grid-item-panel-title-text">收支对比</span>
                    </div>
                    <div class="icon-container">
                        <img src="@/assets/icon/refresh.png" />
                        <img src="@/assets/icon/close.png" />
                    </div>
                </div>
                <div ref="comparisonForm-dom" class="grid-item-panel-chart-dom"></div>
            </div>

        </div>
    </div>
</template>

<script>
import * as echarts from "echarts";
import dayjs from "dayjs";
export default {
    chartInstance: {
        incomeCustomer: null,
        expendCustomer: null,
        incomeMoneyForm: null,
        expendMoneyForm: null,
        comparisonForm: null,
    },
    data() {
        const date = [dayjs().startOf('month').format('YYYY-MM-DD'), dayjs().endOf('month').format('YYYY-MM-DD')];
        return {
            startTime: dayjs().startOf('month').format('YYYY-MM-DD'),
            endTime: dayjs().endOf('month').format('YYYY-MM-DD'),
            //年度信息
            annualProfit: 0,
            annualIncome: 0,
            annualTarget: 0,
            annualRatio: 0,
            annualExpend: 0,
            //月度信息
            monthlyProfit: 0,
            monthlyIncome: 0,
            monthlyTarget: 0,
            monthlyRatio: 0,
            monthlyExpend: 0,

            range: "",
            // 收汇客户详情
            incomeCustomer: {
                loading: false,
                date: date.join(" —— "),
                type: "1",
                totalMoney: 0,
            },
            // 支出客户详情
            expendCustomer: {
                loading: false,
                date: date.join(" —— "),
                type: "1",
                totalMoney: 0,
            },
            // 收款金额构成
            incomeMoneyForm: {
                loading: false,
                date: date.join(" —— "),
                type: "1",
            },
            // 支出金额构成
            expendMoneyForm: {
                loading: false,
                date: date.join(" —— "),
                type: "1",
            },
            // 收支情况
            incomeExpendCondition: {
                loading: false,
                typeValue: 1,   //1 收入 2支出
                staffValue: "",
                tableData: [],
            },
            staffOptions: [],
            // 收支对比
            comparisonForm: {
                loading: false,
            },
        };
    },
    methods: {
        setType(key, value) {
            this[key].type = value;
        },

        //获取年度信息
        getAnnualInfo() {
            // 年度利润
            this.$axios.get(`${this.$customerUrl}/Report/GetAnnualProfit`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.annualProfit = data;
                }
            });
            // 年度收入
            this.$axios.get(`${this.$customerUrl}/Report/GetAnnualIncome`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.annualIncome = data.annualIncome;
                    this.annualTarget = data.annualTarget;
                    let ratio = (data.annualIncome / data.annualTarget) * 100;
                    this.annualRatio = ratio > 100 ? 100 : ratio ? ratio.toFixed(2) * 1 : 0;
                }
            });
            // 年度支出
            this.$axios.get(`${this.$customerUrl}/Report/GetAnnualExpenditure`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.annualExpend = data;
                }
            });
        },
        //获取月度信息
        getMonthlyInfo() {
            // 月度利润
            this.$axios.get(`${this.$customerUrl}/Report/GetMonthlyProfit`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.monthlyProfit = data;
                }
            });
            // 月度收入
            this.$axios.get(`${this.$customerUrl}/Report/GetMonthlyIncome`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.monthlyIncome = data.monthlyIncome;
                    this.monthlyTarget = data.monthlyTarget;
                    let ratio = (data.monthlyIncome / data.monthlyTarget) * 100;
                    this.monthlyRatio = ratio > 100 ? 100 : ratio ? ratio.toFixed(2) * 1 : 0;
                }
            });
            // 月度支出
            this.$axios.get(`${this.$customerUrl}/Report/GetMonthlyExpenditure`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.monthlyExpend = data;
                }
            });
        },

        getOneChart() {
            // 收汇客户详情
            this.incomeCustomer.loading = true;
            this.$axios.post(`${this.$customerUrl}/Report/GetCustomerRevenueDetails`, {
                "startTime": this.startTime,
                "endTime": this.endTime,
            }).then((res) => {
                const { isSuccess, data } = res;
                this.incomeCustomer.loading = false;
                if (isSuccess) {
                    let totalMoney = 0;
                    data.forEach(element => {
                        element.value = element.money;
                        totalMoney += element.money;
                    });
                    this.incomeCustomer.totalMoney = totalMoney;
                    this.$options.chartInstance.incomeCustomer.setOption({
                        series: [
                            { data: data },
                        ],
                    })
                }
            });
        },
        getTwoChart() {
            // 支出客户详情
            this.expendCustomer.loading = true;
            this.$axios.post(`${this.$customerUrl}/Report/GetCustomerRxpenditureDetails`, {
                "startTime": this.startTime,
                "endTime": this.endTime,
            }).then((res) => {
                const { isSuccess, data } = res;
                this.expendCustomer.loading = false;
                if (isSuccess) {
                    let totalMoney = 0;
                    data.forEach(element => {
                        element.value = element.money;
                        totalMoney += element.money;
                    });
                    this.expendCustomer.totalMoney = totalMoney;
                    this.$options.chartInstance.expendCustomer.setOption({
                        series: [
                            { data: data },
                        ],
                    })
                }
            });
        },
        getThreeChart() {
            // 收款金额构成
            this.incomeMoneyForm.loading = true;
            this.$axios.post(`${this.$customerUrl}/Report/GetRevenueAmountComposition`, {
                "startTime": this.startTime,
                "endTime": this.endTime,
            }).then((res) => {
                const { isSuccess, data } = res;
                this.incomeMoneyForm.loading = false;
                if (isSuccess) {
                    data.forEach(element => {
                        element.value = element.money;
                    });
                    this.$options.chartInstance.incomeMoneyForm.setOption({
                        series: [
                            { data: data },
                        ],
                    })
                }
            });
        },
        getFourChart() {
            // 支出金额构成
            this.expendMoneyForm.loading = true;
            this.$axios.post(`${this.$customerUrl}/Report/GetRxpenditureAmountComposition`, {
                "startTime": this.startTime,
                "endTime": this.endTime,
            }).then((res) => {
                const { isSuccess, data } = res;
                this.expendMoneyForm.loading = false;
                if (isSuccess) {
                    data.forEach(element => {
                        element.value = element.money;
                    });
                    this.$options.chartInstance.expendMoneyForm.setOption({
                        series: [
                            { data: data },
                        ],
                    })
                }
            });
        },
        getFiveChart() {
            // 收支情况
            this.incomeExpendCondition.loading = true;
            this.$axios.get(`${this.$customerUrl}/Report/GetRevenueAndExpenditure?type=${this.incomeExpendCondition.typeValue}`).then((res) => {
                const { isSuccess, data } = res;
                this.incomeExpendCondition.loading = false;
                if (isSuccess) {
                    this.incomeExpendCondition.tableData = data;
                }
            });
        },
        getSixChart() {
            // 收支对比
            this.comparisonForm.loading = true;
            this.$axios.get(`${this.$customerUrl}/Report/ComparisonRevenueAndExpenditure`).then((res) => {
                const { isSuccess, data } = res;
                this.comparisonForm.loading = false;
                if (isSuccess) {
                    this.$options.chartInstance.comparisonForm.setOption({
                        xAxis: {
                            data: data.month,
                        },
                        series: data.series,
                    })
                }
            });
        },
        //图表渲染
        chartRender() {
            this.getOneChart();
            this.getTwoChart();
            this.getThreeChart();
            this.getFourChart();
            this.getFiveChart();
            this.getSixChart();
        },
    },
    mounted() {
        //获取年度信息
        this.getAnnualInfo();
        //获取月度信息
        this.getMonthlyInfo();
        // 收汇客户详情
        this.$options.chartInstance.incomeCustomer = echarts.init(
            this.$refs["incomeCustomer-dom"]
        );
        this.$options.chartInstance.incomeCustomer.setOption({
            legend: {
                top: "middle",
                type: "scroll",
                orient: "vertical",
                right: "5%",
                icon: "circle",
                textStyle: {
                    color: "#7C90A5",
                },
                itemWidth: 14,
                itemHeight: 14,
            },
            tooltip: {},
            series: [
                {
                    name: "收汇客户",
                    type: "pie",
                    radius: ["30%", "70%"],
                    center: ["40%", "50%"],
                    label: {
                        show: true,
                        color: "#94979C",
                    },
                    labelLine: {
                        smooth: true,
                        lineStyle: {
                            color: "#94979C",
                        },
                    },
                    emphasis: {
                        scale: false,
                    },
                    data: [
                        { name: "客户一", value: 1000 },
                        { name: "客户二", value: 600 },
                        { name: "客户三", value: 500 },
                        { name: "客户四", value: 400 },
                        { name: "客户五", value: 300 },
                    ],
                },
            ],
            color: [
                "#62D0D3",
                "#67A0C8",
                "#9BC586",
                "#DFD982",
                "#DC7683",
                "#8897C0",
                "#2560A3",
            ],
        });
        // 支出客户详情
        this.$options.chartInstance.expendCustomer = echarts.init(
            this.$refs["expendCustomer-dom"]
        );
        this.$options.chartInstance.expendCustomer.setOption({
            legend: {
                top: "middle",
                type: "scroll",
                orient: "vertical",
                right: "5%",
                icon: "circle",
                textStyle: {
                    color: "#7C90A5",
                },
                itemWidth: 14,
                itemHeight: 14,
            },
            tooltip: {},
            series: [
                {
                    name: "支出客户",
                    type: "pie",
                    radius: ["30%", "70%"],
                    center: ["40%", "50%"],
                    label: {
                        show: true,
                        color: "#94979C",
                    },
                    labelLine: {
                        smooth: true,
                        lineStyle: {
                            color: "#94979C",
                        },
                    },
                    emphasis: {
                        scale: false,
                    },
                    data: [
                        { name: "customer 1", value: 700 },
                        { name: "customer 2", value: 600 },
                        { name: "customer 3", value: 500 },
                        { name: "customer 4", value: 400 },
                        { name: "customer 5", value: 300 },
                    ],
                },
            ],
            color: [
                "#62D0D3",
                "#67A0C8",
                "#9BC586",
                "#DFD982",
                "#DC7683",
                "#8897C0",
                "#2560A3",
            ],
        });
        // 收款金额构成
        this.$options.chartInstance.incomeMoneyForm = echarts.init(
            this.$refs["incomeMoneyForm-dom"]
        );
        this.$options.chartInstance.incomeMoneyForm.setOption({
            legend: {
                top: "middle",
                type: "scroll",
                orient: "vertical",
                right: "5%",
                icon: "circle",
                textStyle: {
                    color: "#7C90A5",
                },
                itemWidth: 14,
                itemHeight: 14,
            },
            tooltip: {},
            series: [
                {
                    name: "收款金额",
                    type: "pie",
                    radius: ["30%", "70%"],
                    center: ["40%", "50%"],
                    label: {
                        show: true,
                        color: "#94979C",
                    },
                    labelLine: {
                        smooth: true,
                        lineStyle: {
                            color: "#94979C",
                        },
                    },
                    emphasis: {
                        scale: false,
                    },
                    data: [
                        { name: "定金", value: 1000 },
                        { name: "首期款", value: 500 },
                        { name: "二期款", value: 300 },
                        { name: "尾款", value: 200 },
                    ],
                },
            ],
            color: [
                "#62D0D3",
                "#67A0C8",
                "#9BC586",
                "#DFD982",
                "#DC7683",
                "#8897C0",
                "#2560A3",
            ],
        });
        // 支出金额构成
        this.$options.chartInstance.expendMoneyForm = echarts.init(
            this.$refs["expendMoneyForm-dom"]
        );
        this.$options.chartInstance.expendMoneyForm.setOption({
            legend: {
                top: "middle",
                type: "scroll",
                orient: "vertical",
                right: "5%",
                icon: "circle",
                textStyle: {
                    color: "#7C90A5",
                },
                itemWidth: 14,
                itemHeight: 14,
            },
            tooltip: {},
            series: [
                {
                    name: "支出金额",
                    type: "pie",
                    radius: ["30%", "70%"],
                    center: ["40%", "50%"],
                    label: {
                        show: true,
                        color: "#94979C",
                    },
                    labelLine: {
                        smooth: true,
                        lineStyle: {
                            color: "#94979C",
                        },
                    },
                    emphasis: {
                        scale: false,
                    },
                    data: [
                        { name: "定金", value: 1000 },
                        { name: "首期款", value: 500 },
                        { name: "二期款", value: 300 },
                        { name: "尾款", value: 200 },
                    ],
                },
            ],
            color: [
                "#62D0D3",
                "#67A0C8",
                "#9BC586",
                "#DFD982",
                "#DC7683",
                "#8897C0",
                "#2560A3",
            ],
        });



        this.$options.chartInstance.comparisonForm = echarts.init(
            this.$refs["comparisonForm-dom"]
        );
        this.$options.chartInstance.comparisonForm.setOption({
            legend: {
                top: 5,
                right: 30,
                icon: "circle",
                itemWidth: 10,
                itemHeight: 10,
                itemStyle: {
                    opacity: 1,
                },
                itemGap: 22,
            },
            grid: {
                containLabel: true,
                top: 40,
                left: 30,
                right: 30,
                bottom: 10,
            },
            xAxis: {
                axisLine: {
                    lineStyle: {
                        color: "#BFBFBF",
                    },
                },
                axisTick: {
                    alignWithLabel: true,
                },
                axisLabel: {
                    color: "#595959",
                },
                data: [1, 2, 3, 4, 5, 6, 7, 8, 9],
            },
            yAxis: {
                axisLabel: {
                    color: "#595959",
                },
                splitLine: {
                    lineStyle: {
                        color: "#E7E7E7",
                        type: "dashed",
                    },
                },
            },
            tooltip: {},
            series: [
                {
                    name: "收入",
                    type: "bar",
                    barMaxWidth: 30,
                    data: [10, 20, 30, 40, 50, 60, 70, 80, 90],
                },
                {
                    name: "支出",
                    type: "bar",
                    barMaxWidth: 30,
                    data: [5, 10, 15, 20, 25, 30, 35, 40, 45],
                },
            ],
            color: ["#2E7DB5", "#79D6D9"],
        });
        // 图表渲染
        this.chartRender();
    },
};
</script>

<style lang="scss" scoped>
.smallCard {
    background-color: #fff;
    padding: 10px;
    height: 200px;

    .tt {
        font-weight: bold;
        font-size: 18px;
    }

    .profit {
        font-size: 30px;
        line-height: 130px;
        text-align: center;
    }

    .oneField {
        width: 50%;
        height: 32px;
        line-height: 32px;
        padding: 0 5px;
        font-size: 14px;

        .label {
            padding-right: 10px;
            color: #9a9a9a;
        }
    }

    .progress {
        margin: auto;
        text-align: center;
    }

    .twoField {
        width: 50%;
        text-align: center;
        font-size: 14px;

        .label {
            color: #9a9a9a;
        }
    }
}

.grid-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.grid-item-panel {
    width: calc(50% - 10px);
    margin-top: 20px;
    position: relative;
    height: 380px;
    background: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

$grid-item-panel-title-height: 42px;

.grid-item-panel-title {
    box-sizing: border-box;
    height: $grid-item-panel-title-height;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 14px 0 20px;

    &.bb {
        border-bottom: 1px solid #e6e7eb;
    }
}

.grid-item-panel-title-text {
    font-size: 18px;
    color: #1b2841;
    font-weight: bold;
}

.grid-item-panel-title-sub-text {
    font-size: 14px;
    color: #c7c7c7;
}

.icon-container {
    display: flex;
    align-items: center;

    img {
        cursor: pointer;

        &:last-child {
            margin-left: 10px;
        }
    }
}

.grid-item-select {
    position: absolute;
    z-index: 2;
    left: 20px;
    top: $grid-item-panel-title-height;
    border: 1px solid #c7c7c7;
    border-radius: 2px;
    width: 80px;
    font-size: 14px;
    color: #7c90a5;
    cursor: pointer;
    background: #fff;
}

.grid-item-select-option {
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover,
    &.active {
        background: var(--themeColor);
        color: #fff;
    }
}

.grid-item-panel-chart-dom {
    box-sizing: border-box;
    height: calc(100% - #{$grid-item-panel-title-height});
}
</style>