<template>
    <div class="customerDetails" v-loading="loading.view">
        <div class="topContent">
            <div class="basicInfo">
                <div class="companyImg">
                    <img src="@/assets/img/companyImg.png" />
                </div>
                <div style="padding-left: 20px">
                    <div class="companyName" style="display: flex; align-items: center">
                        <span>{{ customerInfo.name }}</span>
                        <CopyIcon :copyValue="customerInfo.name" />
                        <span style="margin-left: 12px; display: inline-block; width: 53px; height: 18px; text-align: center; line-height: 18px; font-size: 12px; color: #fff; background: #e56210">我的客户</span>
                        <el-popover placement="right" width="300" trigger="hover">
                            <div style="color:#E6A23C">*撞单风险提示</div>
                            <el-table :data="customerInfo.slightCollisionRisk" stripe size="mini">
                                <el-table-column width="100" property="userName" label="同事姓名"></el-table-column>
                                <el-table-column width="200" property="email" label="联系人邮箱"></el-table-column>
                            </el-table>
                            <img v-show="isShowCollisionRisk && customerInfo.slightCollisionRisk.length>0" class="riskIcon" slot="reference" src="@/assets/icon/risk1.png" alt="" />
                        </el-popover>
                        <el-popover placement="right" width="400" trigger="hover">
                            <div style="color:#F56C6C;">*撞单提示</div>
                            <div v-for="(ele,index) in customerInfo.seriousCollisionRisk" :key="index">
                                近30天内,{{ele.userName}}给{{ele.email}}发送过邮件
                            </div>
                            <img v-show="isShowCollisionRisk && customerInfo.seriousCollisionRisk.length>0" class="riskIcon" slot="reference" src="@/assets/icon/risk2.png" alt="" />
                        </el-popover>
                    </div>
                    <div class="country" style="display: flex; align-items: center">
                        <img :src="customerInfo.flagUrl" style="height: 14px; margin-right: 5px" />
                        <span>{{ customerInfo.country }}</span>
                    </div>
                </div>
                <div class="rightHandle">
                    <el-button size="small" style="margin-right: 10px;" icon="el-icon-s-promotion" @click="onWriteMail">
                        写邮件
                    </el-button>
                    <div v-if="customerInfo.isFollow" class="fill" @click="handleCFollowClick(0)">
                        <img src="@/assets/icon/xin1.png" alt="" />
                        已关注
                    </div>
                    <div v-else class="blank" @click="handleCFollowClick(1)">
                        <!-- <img src="@/assets/icon/xin2.png" alt="" /> -->

                        <svg class="img" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="11.081" height="11.094" viewBox="0 0 11.081 11.094">
                            <defs>
                                <clipPath id="a24fgyhjk">
                                    <rect fill="var(--themeColor)" width="11.081" height="11.094" />
                                </clipPath>
                            </defs>
                            <g transform="translate(0 0)">
                                <g clip-path="url(#a24fgyhjk)">
                                    <path fill="var(--themeColor)" d="M-109.517,362.618a.484.484,0,0,0-.483.484v.616a.484.484,0,0,0,.483.484H-99.4a.483.483,0,0,0,.483-.484V363.1a.484.484,0,0,0-.483-.484Zm0,0" transform="translate(110 -357.87)" />
                                    <path fill="var(--themeColor)" d="M241.739,14.269a.484.484,0,0,0-.484-.483h-.616a.484.484,0,0,0-.484.483V24.385a.483.483,0,0,0,.484.483h.616a.484.484,0,0,0,.484-.483Zm0,0" transform="translate(-235.388 -13.787)" />
                                </g>
                            </g>
                        </svg>
                        关注
                    </div>
                    <!-- 关注和置顶保留关注 -->
                    <!-- <div v-if="customerInfo.isTop" class="fill" @click="handleCTopClick(0)">
                        <img src="@/assets/icon/ding1.png" alt="" />
                        已置顶
                    </div> -->
                    <!-- <div v-else class="blank" @click="handleCTopClick(1)">
                        <svg class="img" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="11.955" height="11.974" viewBox="0 0 11.955 11.974">
                            <defs>
                                <clipPath id="#8ijkuh4g">
                                    <rect fill="var(--themeColor)" width="11.955" height="11.974" />
                                </clipPath>
                            </defs>
                            <g transform="translate(0 0)">
                                <g clip-path="url(#8ijkuh4g)" transform="translate(0 0)">
                                    <path fill="var(--themeColor)" d="M-10.12,114.37H1.826v1.394H-10.12Zm9.4,5.724-2.745-2.9a.95.95,0,0,0-.681-.288.933.933,0,0,0-.678.289l-2.751,2.891a.855.855,0,0,0-.235.581.893.893,0,0,0,.916.868h.021v.005H-5.64v4.771h2.986v-4.771h1.2v-.007l.054,0a.893.893,0,0,0,.916-.868.836.836,0,0,0-.238-.579Zm0,0" transform="translate(10.118 -114.361)" />
                                </g>
                            </g>
                        </svg>
                        置顶
                    </div> -->
                    <!-- 更多操作 -->
                    <el-dropdown @command="handleMoreOperateCommand">
                        <el-button size="small">更多<i class="el-icon-arrow-down el-icon--right"></i></el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="新建联系人">新建联系人</el-dropdown-item>
                            <el-dropdown-item command="新建订单">新建订单</el-dropdown-item>
                            <el-dropdown-item command="新建报价单">新建报价单</el-dropdown-item>
                            <el-dropdown-item command="新增商机">新增商机</el-dropdown-item>
                            <el-dropdown-item command="上传附件">上传附件</el-dropdown-item>
                            <el-dropdown-item command="新建收入">新建收入</el-dropdown-item>
                            <el-dropdown-item command="新建支出">新建支出</el-dropdown-item>
                            <el-dropdown-item command="客户转移">客户转移</el-dropdown-item>
                            <el-dropdown-item command="释放到公海">释放到公海</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
            <el-row :gutter="16" class="importantInfo">
                <el-col :span="4">
                    <div class="label">私海分组:</div>
                    <el-select @change="modifyCustomerInofo" v-model="customerInfo.privateGroupKey" placeholder="请选择" size="small">
                        <el-option v-for="item in seasGroupOption" :key="item.key" :label="item.name" :value="item.key" />
                    </el-select>
                </el-col>

                <el-col :span="4">
                    <div class="label">公司状态:</div>
                    <el-select @change="modifyCustomerInofo" v-model="customerInfo.statuKey" placeholder="请选择" size="small">
                        <el-option v-for="item in statusOption" :key="item.key" :label="item.name" :value="item.key" />
                    </el-select>
                </el-col>

            
                <el-col :span="4">
                    <div class="label">星级:</div>
                    <div class="value">
                        <el-rate @change="modifyCustomerInofo" v-model="customerInfo.star" style="padding-top: 6px"></el-rate>
                    </div>
                </el-col>
             
            </el-row>
            <!-- 标签信息 -->
            <div class="tagInfo">
                <el-tag @close="handleTagClose(item.key)" v-for="item in customerInfo.tags" :key="item.key" :color="item.color" closable effect="dark" size="small" style="margin-right: 10px; border-color: transparent">{{ item.name }}</el-tag>
                <el-button size="mini" @click="show.newTag = true">+标签</el-button>
            </div>
            <AddTagDialog :visible.sync="show.newTag" :old-tags="customerInfo.tags" @cancel="show.newTag = false" :companykey="customerKey" @success="getCustomerInfo" />
        </div>

        <div style="display: flex; margin-top: 20px">
            <div class="leftContent">
                <el-tabs v-model="activeName">
                    <el-tab-pane name="dynamic" label="跟进动态">
                        <div style="width: 100%">
                            <CustomerDynamics :customerKey="customerKey" />
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="详细资料">
                        <div>
                            <div class="ziliaoTitle">公司信息字段</div>
                            <el-form label-width="120px" size="small" label-position="left">
                                <el-row :gutter="16">
                                    <el-col :span="8">
                                        <el-form-item label="公司名称">
                                            <el-input v-model="customerInfo.name" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="公司简称">
                                            <el-input v-model="customerInfo.nickname" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="公司来源">
                                            <el-select v-model="customerInfo.customerSourceKey" style="width:100%;">
                                                <el-option v-for="item of sourceOption" :key="item.key" :value="item.key" :label="item.name" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="所属行业">
                                            <el-input v-model="customerInfo.mainIndustryValue" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="公司类型">
                                            <el-input v-model="customerInfo.mainBusinessTypeValue" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="规模">
                                            <el-select v-model="customerInfo.companyScaleValue" style="width:100%;">
                                                <el-option v-for="item of companyScaleValueOptions" :key="item.value" :label="item.name" :value="item.name" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="国家地区">
                                            <el-select v-model="customerInfo.country" style="width:100%;" filterable>
                                                <el-option v-for="item of countryOptions" :key="item" :value="item" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="公司状态">
                                            <el-input v-model="customerInfo.statuName" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="网址">
                                            <el-input v-model="customerInfo.webSite" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="传真">
                                            <el-input v-model="customerInfo.fax" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="公司备注">
                                            <el-input v-model="customerInfo.note" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="评分">
                                            <el-input-number v-model="customerInfo.score" :min="1" :max="100"></el-input-number>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="外/内贸">
                                            <el-select v-model="customerInfo.tradeType" style="width:100%;">
                                                <el-option label="外贸" value="外贸"></el-option>
                                                <el-option label="内贸" value="内贸"></el-option>
                                                <el-option label="外/内贸" value="外/内贸"></el-option>
                                                <el-option label="未知" value="未知"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="公司地址">
                                            <el-input v-model="customerInfo.address" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-button :loading="loading.modifyCustomerInofo" type="primary" size="small" @click="modifyCustomerInofo">保存</el-button>
                                    </el-col>
                                </el-row>
                            </el-form>
                            <div class="ziliaoTitle">统计信息字段</div>
                            <el-form label-width="120px" size="small" label-position="left" disabled>
                                <el-row :gutter="16">
                                    <el-col :span="8">
                                        <el-form-item label="跟进人">
                                            <el-input v-model="customerInfo.followUpUser" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="创建时间">
                                            <el-input v-model="customerInfo.createTime" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="创建人">
                                            <el-input v-model="customerInfo.createUser" />
                                        </el-form-item>
                                    </el-col>
                                    
                                </el-row>
                            </el-form>
                            <!-- 自定义字段 -->
                            <ShowSaveField :documentKey="customerKey" :type="0" />
                        </div>
                    </el-tab-pane>
                    <el-tab-pane name="linkman" :label="`联系人（${contactList.length}）`">
                        <CustomerContact ref="CustomerContact" :contactList="contactList" @onSave="getCustomerInfo" />
                    </el-tab-pane>
                    <el-tab-pane label="公司报告" v-if="false">
                        <CustomerData />
                    </el-tab-pane>
                    <el-tab-pane name="order" :label="`订单（${tabDingdan.totalItems}）`">
                        <div>
                            <el-button @click="createNewDingdan" type="primary" icon="el-icon-plus" size="small" style="margin-bottom: 20px">
                                新建订单
                            </el-button>
                            <el-table :data="tabDingdan.tabledata" :header-cell-style="{ 'background-color': '#F3F3F3', color: '#3A3A3A', 'font-weight': '400' }" :row-style="({ rowIndex }) => (rowIndex % 2 == 1 ? { 'background-color': '#F9FAFC' } : {})" v-loading="tableLoading">
                                <el-table-column type="index" width="50" />
                                <el-table-column prop="orderNo" label="订单编号"></el-table-column>
                                <el-table-column prop="orderName" label="订单名称"></el-table-column>
                                <el-table-column prop="orderDate" label="订单时间"></el-table-column>
                                <el-table-column prop="orderAmount" label="实际金额">
                                    <template slot-scope="scope">
                                        {{scope.row.orderAmount}} {{scope.row.currency}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作">
                                    <template slot-scope="{ row }">
                                        <el-link @click="navigateToDingdanDetail(row)" :underline="false" type="primary">查看更多</el-link>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                        <div style="display: flex; justify-content: flex-end; padding-top: 12px">
                            <el-pagination hide-on-single-page background layout="prev, pager, next" :total="tabDingdan.totalItems" :page-size="10" :current-page="tabDingdan.pageIndex" @current-change="getOrder" />
                        </div>
                    </el-tab-pane>
                    <el-tab-pane name="quotation" :label="`报价单（${tabQuotation.totalItems}）`">
                        <div>
                            <el-button @click="createNewQuotation" type="primary" icon="el-icon-plus" size="small" style="margin-bottom: 20px">
                                新建报价单
                            </el-button>
                            <el-table :data="tabQuotation.tabledata" :header-cell-style="{ 'background-color': '#F3F3F3', color: '#3A3A3A', 'font-weight': '400' }" :row-style="({ rowIndex }) => (rowIndex % 2 == 1 ? { 'background-color': '#F9FAFC' } : {})" v-loading="tableLoading">
                                <el-table-column type="index" width="50" />
                                <el-table-column prop="quoteName" label="报价单名称"></el-table-column>
                                <el-table-column prop="quotationDate" label="报价时间"></el-table-column>
                                <el-table-column prop="expirationTime" label="报价有效期"></el-table-column>
                                <el-table-column label="报价单状态">
                                    <template slot-scope="scope">
                                        <div style="display: flex;">
                                            <span class="globe" :class="'stateColor_'+scope.row.state"></span>
                                            <span>{{scope.row.stateStr}}</span>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column label="实际金额">
                                    <template slot-scope="scope">
                                        {{scope.row.totalPrice}} {{scope.row.settlementCurrency}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作">
                                    <template slot-scope="{ row }">
                                        <el-link @click="toQuotationDetail(row)" :underline="false" type="primary">查看更多</el-link>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                        <div style="display: flex; justify-content: flex-end; padding-top: 12px">
                            <el-pagination hide-on-single-page background layout="prev, pager, next" :total="tabQuotation.totalItems" :page-size="10" :current-page="tabQuotation.pageIndex" @current-change="getQuotation" />
                        </div>
                    </el-tab-pane>
                    <el-tab-pane :label="`商机（${tabBusiness.totalItems}）`">
                        <div>
                            <el-button @click="newCardVisible = true;" type="primary" icon="el-icon-plus" size="small" style="margin-bottom: 20px">
                                新建商机
                            </el-button>
                            <el-table :data="tabBusiness.tabledata" :header-cell-style="{ 'background-color': '#F3F3F3', color: '#3A3A3A', 'font-weight': '400' }" :row-style="({ rowIndex }) => (rowIndex % 2 == 1 ? { 'background-color': '#F9FAFC' } : {})" v-loading="tableLoading">
                                <el-table-column type="index" width="50" />
                                <el-table-column prop="businessChanceName" label="商机名称"></el-table-column>
                                <el-table-column label="金额">
                                    <template slot-scope="{row}">
                                        <span>{{row.amount}} {{row.currency}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="stageName" label="商机阶段" />
                                <el-table-column label="停留时间">
                                    <template slot-scope="{row}">
                                        <span>{{ `${row.dwellTime}天${row.isExpire ? ' ' + '(过期' + row.expirationDays + '天)' : ''}` }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="endTime" label="结束日期">
                                    <template slot-scope="{row}">
                                        <span :style="{ color: row.isExpire ? '#F56C6C' : '' }">{{ row.endTime }}</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                        <div style="display: flex; justify-content: flex-end; padding-top: 12px">
                            <el-pagination hide-on-single-page background layout="prev, pager, next" :total="tabBusiness.totalItems" :page-size="10" :current-page="tabBusiness.pageIndex" @current-change="getBusiness" />
                        </div>
                    </el-tab-pane>
                    <el-tab-pane :label="`附件（${tabFujian.pagination.total}）`">
                        <div>
                            <el-button @click="$refs.uploadFileInput.click()" type="primary" icon="el-icon-plus" size="small" style="margin-bottom: 20px">上传</el-button>
                            <input type="file" style="display: none" ref="uploadFileInput" @change="handleUploadFileInputChange" />
                            <el-table :data="tabFujian.table.data" :header-cell-style="{ 'background-color': '#F3F3F3', color: '#3A3A3A', 'font-weight': '400' }" :row-style="({ rowIndex }) => (rowIndex % 2 == 1 ? { 'background-color': '#F9FAFC' } : {})">
                                <el-table-column type="index" width="50" />
                                <el-table-column label="文件名" show-overflow-tooltip>
                                    <template slot-scope="{ row }">
                                        <div style="display: flex; align-items: center">
                                            <img :src="row.imageUrl" style="width: 27px; height: 33px; margin-right: 5px" />
                                            <el-link :underline="false" @click="openFileDetail(row)">{{row.name}}</el-link>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="suffix" label="文件类型"></el-table-column>
                                <el-table-column prop="createTime" label="上传日期"></el-table-column>
                                <el-table-column prop="source" label="附件来源"></el-table-column>
                                <el-table-column prop="userCnName" label="上传人">
                                    <template slot-scope="{ row }">
                                        <el-link :underline="false" @click="toStaffInfo(row)">{{row.userCnName}}</el-link>
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作">
                                    <template slot-scope="{ row }">
                                        <div style="display: flex; align-items: center">
                                            <!-- <el-link :underline="false" type="primary" style="margin-right: 20px">查看更多</el-link> -->
                                            <el-link :underline="false" type="danger" @click="deleteFujian(row.key)">删除</el-link>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                        <div style="display: flex; justify-content: flex-end; padding-top: 12px">
                            <el-pagination hide-on-single-page background layout="prev, pager, next" :total="tabFujian.pagination.total" :page-size="tabFujian.pagination.pageSize" :current-page="tabFujian.pagination.pageIndex" @current-change="handleTabFujianPageIndexChange" />
                        </div>
                    </el-tab-pane>
                    <el-tab-pane :label="`收入（${shouru.pagination.total}）`">
                        <div>
                            <div style="margin-bottom: 20px">
                                <el-button @click="toNewIncome" type="primary" icon="el-icon-plus" size="small">新建收入</el-button>
                                <span style="color:#666;font-size: 14px;margin-left: 10px;">
                                    共计：{{ shouru.pagination.total }}项收入，{{ shouru.totalPrice }} CNY
                                </span>
                            </div>
                            <el-table :data="shouru.shouruList" :header-cell-style="{ 'background-color': '#F3F3F3', color: '#3A3A3A', 'font-weight': '400' }" :row-style="({ rowIndex }) => (rowIndex % 2 == 1 ? { 'background-color': '#F9FAFC' } : {})">
                                <el-table-column prop="paymentName" label="款项名称"></el-table-column>
                                <el-table-column prop="payeeCollectionDate" label="收入日期"></el-table-column>
                                <el-table-column prop="payeeCollectionAmount" label="收入金额">
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.payeeCollectionAmount }}</span>
                                        <span style="padding-left: 5px;">{{ scope.row.collectionCurrency }}</span>
                                        (<span>{{ scope.row.cnyAmount }}</span>
                                        <span style="padding-left: 5px;">CNY</span>)
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作" width="100">
                                    <template slot-scope="scope">
                                        <el-link :underline="false" type="danger" @click="deleteCollection(scope.row,1)">删除</el-link>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                        <div style="display: flex; justify-content: flex-end; padding-top: 12px">
                            <el-pagination hide-on-single-page background layout="prev, pager, next" :total="shouru.pagination.total" :page-size="shouru.pagination.pageSize" :current-page="shouru.pagination.pageIndex" @current-change="getShouru" />
                        </div>
                    </el-tab-pane>
                    <el-tab-pane :label="`支出（${zhichu.pagination.total}）`">
                        <div>
                            <div style="margin-bottom: 20px">
                                <el-button @click="toNewSpending" type="primary" icon="el-icon-plus" size="small">新建支出</el-button>
                                <span style="color:#666;font-size: 14px;margin-left: 10px;">
                                    共计：{{ zhichu.pagination.total }}项支出，{{ zhichu.totalPrice }} CNY
                                </span>
                            </div>
                            <el-table :data="zhichu.zhichuList" :header-cell-style="{ 'background-color': '#F3F3F3', color: '#3A3A3A', 'font-weight': '400' }" :row-style="({ rowIndex }) => (rowIndex % 2 == 1 ? { 'background-color': '#F9FAFC' } : {})">
                                <el-table-column prop="paymentName" label="款项名称"></el-table-column>
                                <el-table-column prop="payerDate" label="支出日期"></el-table-column>
                                <el-table-column prop="payerPayAmount" label="支出金额">
                                    <template slot-scope="scope">
                                        <span>{{ scope.row.payerPayAmount }}</span>
                                        <span style="padding-left: 5px;">{{ scope.row.collectionCurrency }}</span>
                                        (<span>{{ scope.row.cnyAmount }}</span>
                                        <span style="padding-left: 5px;">CNY</span>)
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作" width="100">
                                    <template slot-scope="scope">
                                        <el-link :underline="false" type="danger" @click="deleteCollection(scope.row,2)">删除</el-link>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                        <div style="display: flex; justify-content: flex-end; padding-top: 12px">
                            <el-pagination hide-on-single-page background layout="prev, pager, next" :total="zhichu.pagination.total" :page-size="zhichu.pagination.pageSize" :current-page="zhichu.pagination.pageIndex" @current-change="getZhichu" />
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="系统日志">
                        <el-timeline :reverse="false">
                            <el-timeline-item v-for="(item, index) in operationHistory.data" :key="index">
                                <div>{{item.content}}</div>
                                <div style="color: #909399;margin-top: 8px;">{{item.inDate}} {{item.name}}</div>
                            </el-timeline-item>
                        </el-timeline>
                        <el-pagination hide-on-single-page @current-change="handleOperationHistoryPageINdexChange" :total="operationHistory.totalData.length" layout="prev, pager, next" />
                    </el-tab-pane>
                    <el-tab-pane label="海关数据">
                        <CustomsData :companyName="customerInfo.customsDataName ? customerInfo.customsDataName : customerInfo.name" />
                    </el-tab-pane>
                    <el-tab-pane label="关联产品">
                        <RelatedProducts />
                    </el-tab-pane>
                    <el-tab-pane label="供应商信息">
                        <SupplierInfo />
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>

        <el-dialog title="客户转移" :visible.sync="kehuzhuanyi.visible" width="500px" top="10vh" v-loading="kehuzhuanyi.loading">
            <el-form size="small">
                <el-form-item label="转移到：">
                    <el-select v-model="kehuzhuanyi.value" filterable>
                        <el-option v-for="item of kehuzhuanyi.options" :key="item.uKey" :value="item.uKey" :label="item.userCnName" />
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="kehuzhuanyi.visible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="handleKehuZhuanyiConfirm">确定</el-button>
            </span>
        </el-dialog>

        <!-- 展示文件详情 -->
        <el-drawer title="文件详情" :with-header="false" :visible.sync="fileDetailVisible" size="500px">
            <div class="attachInfoStyle">
                <div class="img">
                    <el-image style="width:150px;" :src="fileDetailInfo.url" :preview-src-list="[fileDetailInfo.url]" />
                </div>
                <el-row class="infoItem">
                    <el-col :span="6">文件名</el-col>
                    <el-col :span="18">{{fileDetailInfo.name}}</el-col>
                </el-row>
                <el-row class="infoItem">
                    <el-col :span="6">文件类型</el-col>
                    <el-col :span="18">{{fileDetailInfo.suffix}}</el-col>
                </el-row>
                <el-row class="infoItem">
                    <el-col :span="6">网络路径</el-col>
                    <el-col :span="18">
                        <span>{{fileDetailInfo.url}}</span>
                        <span class="copy" @click="copyUrl(fileDetailInfo.url)">复制路径</span>
                    </el-col>
                </el-row>
                <el-row class="infoItem">
                    <el-col :span="6">附件来源</el-col>
                    <el-col :span="18">{{fileDetailInfo.source}}</el-col>
                </el-row>
                <el-row class="infoItem">
                    <el-col :span="6">上传用户</el-col>
                    <el-col :span="18">{{fileDetailInfo.userCnName}}</el-col>
                </el-row>
                <el-row class="infoItem">
                    <el-col :span="6">上传时间</el-col>
                    <el-col :span="18">{{fileDetailInfo.createTime}}</el-col>
                </el-row>
            </div>
        </el-drawer>

        <!-- 新增商机 -->
        <NewCardDialog v-if="newCardVisible" :lockCustomer="true" :customerKey="customerKey" :customerName="customerInfo.name" @done="newBusinessOk" @cancel="newCardVisible = false" />

        <!-- 新建收入(简化) -->
        <el-dialog title="新建收入" :visible.sync="newIncomeVisible" width="400px">
            <el-form label-width="100px" size="small">
                <el-form-item label="款项名称">
                    <el-input v-model="incomeForm.paymentName"></el-input>
                </el-form-item>
                <el-form-item label="收入日期">
                    <el-date-picker v-model="incomeForm.payeeCollectionDate" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
                </el-form-item>
                <el-form-item label="收入金额">
                    <el-input-number v-model="incomeForm.payeeCollectionAmount" :min="0" :controls="false" @blur="calcCNYPrice1"></el-input-number>
                </el-form-item>
                <el-form-item label="币种">
                    <el-select v-model="incomeForm.collectionCurrency" size="small" filterable style="width:130px;" @change="chooseCurrency1">
                        <el-option v-for="item in currencyOption" :key="item.key" :label="item.currency" :value="item.currency"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="汇率">
                    <el-input-number :disabled="incomeForm.collectionCurrency=='CNY'" v-model="incomeForm.exchangeRate" size="small" :min="0" :controls="false" @blur="calcCNYPrice1"></el-input-number>
                </el-form-item>
                <el-form-item label="人民币金额">
                    <el-input-number :disabled="incomeForm.collectionCurrency=='CNY'" v-model="incomeForm.cnyAmount" size="small" :min="0" :controls="false" @blur="calcRate1"></el-input-number>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="newIncomeVisible = false">取 消</el-button>
                <el-button size="small" type="primary" :disabled="!incomeForm.paymentName" @click="newIncomeSubmit" :loading="btnLoading">确定</el-button>
            </span>
        </el-dialog>
        <!-- 新建支出(简化) -->
        <el-dialog title="新建支出" :visible.sync="newSpendingVisible" width="400px">
            <el-form label-width="100px" size="small">
                <el-form-item label="款项名称">
                    <el-input v-model="spendingForm.paymentName"></el-input>
                </el-form-item>
                <el-form-item label="支出日期">
                    <el-date-picker v-model="spendingForm.payerDate" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
                </el-form-item>
                <el-form-item label="支出金额">
                    <el-input-number v-model="spendingForm.payerPayAmount" :min="0" :controls="false" @blur="calcCNYPrice2"></el-input-number>
                </el-form-item>
                <el-form-item label="币种">
                    <el-select v-model="spendingForm.collectionCurrency" size="small" filterable style="width:130px;" @change="chooseCurrency2">
                        <el-option v-for="item in currencyOption" :key="item.key" :label="item.currency" :value="item.currency"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="汇率">
                    <el-input-number :disabled="spendingForm.collectionCurrency=='CNY'" v-model="spendingForm.exchangeRate" size="small" :min="0" :controls="false" @blur="calcCNYPrice2"></el-input-number>
                </el-form-item>
                <el-form-item label="人民币金额">
                    <el-input-number :disabled="spendingForm.collectionCurrency=='CNY'" v-model="spendingForm.cnyAmount" size="small" :min="0" :controls="false" @blur="calcRate2"></el-input-number>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="newSpendingVisible = false">取 消</el-button>
                <el-button size="small" type="primary" :disabled="!spendingForm.paymentName" @click="newSpendingSubmit" :loading="btnLoading">确定</el-button>
            </span>
        </el-dialog>

        <!-- 多个联系人写邮件 -->
        <el-dialog title="写邮件" :visible.sync="selectedContactVisible" width="500px">
            <el-table ref="multipleTable" :data="contactList" @selection-change="selectedContactChange">
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column prop="nickname" label="姓名"></el-table-column>
                <el-table-column prop="email" label="邮箱"></el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="selectedContactVisible = false">取 消</el-button>
                <el-button size="small" type="primary" :disabled="selectedContactList.length==0" @click="onSendMultiple">写邮件</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import CustomerDynamics from "@/components/CustomerDynamics.vue"; //客户动态
import CustomerData from "@/components/CustomerData.vue"; //客户资料
import AddTagDialog from "@/views/CustomerManage/components/AddTagDialog.vue";
import CustomsData from "@/views/CustomerManage/components/CustomsData.vue"; //海关数据
import NewCardDialog from "@/views/Business/components/NewCardDialog.vue"; //新增商机弹框组件
import CustomerContact from "@/views/CustomerManage/components/CustomerContact.vue"//客户联系人
import dayjs from "dayjs";
import { Message } from "element-ui";

import RelatedProducts from "./components/RelatedProducts.vue"  //关联产品
import SupplierInfo from "./components/SupplierInfo.vue"        //供应商信息
import ShowSaveField from "@/components/ShowSaveField.vue"      //自定义字段

const fujianImage = require("@/assets/icon/fujian.png");

export default {
    components: {
        CustomerDynamics,
        CustomerData,
        AddTagDialog,
        CustomsData,
        NewCardDialog,
        CustomerContact,
        RelatedProducts,
        SupplierInfo,
        ShowSaveField,
    },
    props: {
        handleSelect: {
            type: Function,
            default: null,
        },
    },
    data() {
        return {
            customerKey: this.$route.query.customerKey,
            countryOptions: window.ContryList,
            seasGroupOption: [], //私海分组选项
            statusOption: [], //公司状态选项
            sourceOption: [], //公司来源选项
            stageOption: [], //商机选项
            loading: {
                view: false,
                modifyCustomerInofo: false,
            },
            show: {
                newTag: false,
            },
            //客户信息
            customerInfo: {
                slightCollisionRisk: [],
                seriousCollisionRisk: [],
            },

            activeName: "dynamic",
           

            //联系人信息
            contactList: [],

            operationHistory: {
                data: [],
                pageIndex: 1,
                totalData: [],
            },

            tabFujian: {
                data: [],
                table: {
                    data: [],
                },
                pagination: {
                    total: 0,
                    pageIndex: 1,
                    pageSize: 10,
                },
            },

            //订单列表
            tableLoading: false,
            tabDingdan: {
                tabledata: [],
                totalItems: 0,
                pageIndex: 1,
            },
            //报价单列表
            tabQuotation: {
                tabledata: [],
                totalItems: 0,
                pageIndex: 1,
            },
            //商机列表
            tabBusiness: {
                tabledata: [],
                totalItems: 0,
                pageIndex: 1,
            },

            zhichu: {
                zhichuList: [],
                pagination: {
                    total: 0,
                    pageIndex: 1,
                    pageSize: 10,
                },
                totalPrice: 0,
            },
            currencyOption: [],
            shouru: {
                shouruList: [],
                pagination: {
                    total: 0,
                    pageIndex: 1,
                    pageSize: 10,
                },
                totalPrice: 0,
            },

            companyScaleValueOptions: [
                {
                    value: 1,
                    name: "少于59人",
                    en_name: "Less than 59 employees",
                },
                {
                    value: 2,
                    name: "60-149人",
                    en_name: "60-149 employees",
                },
                {
                    value: 3,
                    name: "150-499人",
                    en_name: "150-499 employees",
                },
                {
                    value: 4,
                    name: "500-999人",
                    en_name: "500-999 employees",
                },
                {
                    value: 5,
                    name: "1000-4999人",
                    en_name: "1000-4999 employees",
                },
                {
                    value: 6,
                    name: "5000人以上",
                    en_name: "More than 5000 employees",
                },
            ],
            kehuzhuanyi: {
                visible: false,
                loading: false,
                value: "",
                options: [],
            },

            // 新增商机
            newCardVisible: false,
            //打开文件详情
            fileDetailVisible: false,
            fileDetailInfo: {},

            btnLoading: false,
            //新建收入(简化)
            newIncomeVisible: false,
            incomeForm: {},
            //新建支出(简化)
            newSpendingVisible: false,
            spendingForm: {},

            //多个联系人写邮件
            selectedContactVisible: false,
            selectedContactList: [],

            isShowCollisionRisk: localStorage.sysConfig ? JSON.parse(localStorage.sysConfig).isShowCollisionRisk : false,
        };
    },
    methods: {
        // 写邮件
        onWriteMail(){
            if(this.contactList.length == 0){
                this.$message({ message: '请添加联系人后再写邮件!', type: 'warning'});
                this.activeName = "linkman";
            }else if(this.contactList.length == 1){
                let receiver = this.contactList[0].email;
                // console.log(receiver);
                window.open(
                    `${process.env.VUE_APP_MAIL_BASE}#/index/editor?type=send&receiver=${receiver}`,
                    "_blank"
                );
            }else{
                this.selectedContactVisible = true;
                this.$nextTick(()=>{
                    this.contactList.forEach(row => {
                        this.$refs.multipleTable.toggleRowSelection(row);
                    });
                })
            }
        },
        selectedContactChange(val) {
            this.selectedContactList = val;
        },
        onSendMultiple(){
            let receiver = this.selectedContactList.map(item => item.email);
            // console.log(receiver.join(','))
            window.open(
                `${process.env.VUE_APP_MAIL_BASE}#/index/editor?type=send&receiver=${receiver.join(',')}`,
                "_blank"
            );
        },

        //查看人员信息
        toStaffInfo(row) {
            let routeData = this.$router.resolve({
                path: "/staff/staffDetails",
                query: { staffkey: row.uKey },
            });
            window.open(routeData.href, "_blank");
        },
        //打开文件详情
        openFileDetail(row) {
            this.fileDetailVisible = true;
            this.fileDetailInfo = row;
        },
        //复制url路径
        copyUrl(url) {
            const input = document.createElement("input");
            document.body.appendChild(input);
            input.setAttribute("value", url);
            input.select();
            if (document.execCommand("copy")) {
                document.execCommand("copy");
            }
            document.body.removeChild(input);
            this.$message({ message: "复制成功", type: "success" });
        },

        /**
         * @description 获取公司信息（包括联系人信息）
         */
        async getCustomerInfo() {
            try {
                const res = await this.$axios({
                    method: "GET",
                    url: this.$customerUrl + "/Company",
                    params: {
                        companykey: this.customerKey,
                    },
                });
                this.contactList = res.data.contacters;//联系人列表
                this.customerInfo = {
                    ...res.data,
                    flagUrl: window.getFlagUrlByCountry
                        ? window.getFlagUrlByCountry(res.data.country)
                        : "",
                };
                document.title = `我的客户详情-${this.customerInfo.name}[${this.customerInfo.country}]`;
            } catch {
                null;
            }
        },
        async modifyCustomerInofo() {
            try {
                this.loading.modifyCustomerInofo = true;
                const res = await this.$axios({
                    method: "POST",
                    url: this.$customerUrl + "/Company/Save",
                    data: {
                        ...this.customerInfo,
                        call: "",
                    },
                });
                if (res.isSuccess) {
                    Message.success("操作成功");
                }
            } catch {
                null;
            } finally {
                this.loading.modifyCustomerInofo = false;
            }
        },
       
        async getOperationHistroy() {
            try {
                const res = await this.$axios({
                    method: "GET",
                    url: this.$customerUrl + "/Company/Operation/List",
                    params: {
                        companykey: this.customerKey,
                    },
                });
                this.operationHistory.totalData = res.data || [];
                this.handleOperationHistoryPageINdexChange();
            } catch {
                null;
            }
        },
        handleOperationHistoryPageINdexChange(pageIndex = 1) {
            this.operationHistory.data = this.operationHistory.totalData.slice(
                10 * (pageIndex - 1),
                10 * pageIndex
            );
        },
        

        async getZhichu(pageIndex = 1) {
            try {
                const res = await this.$axios({
                    method: "POST",
                    url: this.$orderUrl + "/api/Collection/GetCollections",
                    data: {
                        customerKey: this.customerKey,
                        type: 2,
                        pageIndex,
                        pageSize: 10,
                    },
                });
                this.zhichu.zhichuList = res.data.collectionData;
                this.zhichu.totalPrice = res.data.totalPrice;
                this.zhichu.pagination.pageIndex = pageIndex;
                this.zhichu.pagination.total = res.data.totalItems;
            } catch {
                null;
            }
        },

        async getShouru(pageIndex = 1) {
            try {
                const res = await this.$axios({
                    method: "POST",
                    url: this.$orderUrl + "/api/Collection/GetCollections",
                    data: {
                        customerKey: this.customerKey,
                        type: 1,
                        pageIndex,
                        pageSize: 10,
                    },
                });
                this.shouru.shouruList = res.data.collectionData;
                this.shouru.totalPrice = res.data.totalPrice;
                this.shouru.pagination.pageIndex = pageIndex;
                this.shouru.pagination.total = res.data.totalItems;
            } catch {
                null;
            }
        },
        async getGroupList() {
            try {
                const res = await this.$axios({
                    method: "GET",
                    url: this.$customerUrl + "/v1/Config/PrivateGroup",
                });
                this.seasGroupOption = res.data || [];
            } catch {
                this.seasGroupOption = [];
            }
        },

        async getStatusOption() {
            try {
                const res = await this.$axios({
                    method: "GET",
                    url: this.$customerUrl + "/v1/Config/Statu",
                });
                this.statusOption = res.data || [];
            } catch {
                this.statusOption = [];
            }
        },
        async getSourceOption() {
            try {
                const res = await this.$axios({
                    method: "GET",
                    url: this.$customerUrl + "/v1/Config/Source",
                });
                this.sourceOption = res.data || [];
            } catch {
                this.sourceOption = [];
            }
        },
        async handleTagClose(key) {
            const res = await this.$axios({
                method: "DELETE",
                url: this.$customerUrl + "/Company/RemoveTag",
                params: {
                    key,
                },
            });
            if (res.isSuccess) {
                Message.success("操作成功");
                this.getCustomerInfo();
            }
        },

        //获取客户关联订单
        getOrder(pageIndex = 1) {
            this.tableLoading = true;
            this.$axios.post(`${this.$orderUrl}/api/Order/V2/GetOrders`, {
                customerKey: this.customerKey,
                pageIndex,
                pageSize: 10,
            }).then((res) => {
                const { isSuccess, data } = res;
                this.tableLoading = false;
                if (isSuccess) {
                    this.tabDingdan.tabledata = data.datas;
                    this.tabDingdan.totalItems = data.totalItems;
                    this.tabDingdan.pageIndex = pageIndex;
                }
            });
        },
        navigateToDingdanDetail(row) {
            let routeData = this.$router.resolve({
                path: "/orderManage/orderDetails",
                query: { orderKey: row.uniqueKey },
            });
            window.open(routeData.href, "_blank");
        },
        createNewDingdan() {
            this.handleSelect(`/orderManage/addOrder?customerKey=${this.customerKey}`);
            this.$router.push(`/orderManage/addOrder?customerKey=${this.customerKey}&isBack=1`);
        },
        //获取客户关联报价单
        getQuotation(pageIndex = 1) {
            this.tableLoading = true;
            this.$axios.post(`${this.$sjUrl}/Quote/GetQuotes`,{
                customerKey: this.customerKey,
                pageIndex,
                pageSize: 10,
            }).then((res) => {
                const { isSuccess, data } = res;
                this.tableLoading = false;
                if (isSuccess) {
                    this.tabQuotation.tabledata = data.datas;
                    this.tabQuotation.totalItems = data.totalItems;
                    this.tabQuotation.pageIndex = pageIndex;
                }
            });
        },
        createNewQuotation(){
            this.handleSelect(`/quotation/newQuotation?customerKey=${this.customerKey}`);
            this.$router.push(`/quotation/newQuotation?customerKey=${this.customerKey}&isBack=1`);
        },
        toQuotationDetail(row){
            let routeData = this.$router.resolve({
                path: "/quotation/quotationDetails",
                query: { quotationKey: row.uniqueKey },
            });
            window.open(routeData.href, "_blank");
        },
        //获取客户关联商机
        getBusiness(pageIndex = 1) {
            this.tableLoading = true;
            this.$axios.post(`${this.$sjUrl}/BusinessChance/GetBusinessChancePage`,{
                customerKey: this.customerKey,
                pageIndex,
                pageSize: 10,
            }).then((res) => {
                const { isSuccess, data } = res;
                this.tableLoading = false;
                if (isSuccess) {
                    this.tabBusiness.tabledata = data.datas;
                    this.tabBusiness.totalItems = data.totalItems;
                    this.tabBusiness.pageIndex = pageIndex;
                }
            });
        },

        //新建收入(简化)
        toNewIncome(){
            this.newIncomeVisible = true;
            let userInfo = JSON.parse(localStorage.userInfo);
            this.incomeForm = {
                "type": 1,                                      //新建收入
                "paymentName": "",
                "payer": this.customerInfo.name,                //付款方
                "payerKey": this.customerInfo.companyKey,       //付款方key
                "payerPayAmount": 0,                            //付款方付款金额
                "payee": userInfo.companyCnFullName,            //收款方
                "payeeKey": userInfo.companyKey,                //收款方key
                "payeeCollectionAmount": 0,                     //收款方收款金额
                "payeeCollectionDate": dayjs().format('YYYY-MM-DD'),
                "collectionCurrency": "CNY",
                "exchangeRate": 1,
                "cnyAmount": 0,
                "handler": userInfo.userCnName,
                "remarks": "",
            }
        },
        newIncomeSubmit(){
            this.incomeForm.payerPayAmount = this.incomeForm.payeeCollectionAmount;
            this.btnLoading = true;
            this.$axios.post(`${this.$orderUrl}/api/Collection/SaveCollection`,this.incomeForm).then((res) => {
                const { isSuccess } = res;
                this.btnLoading = false;
                this.newIncomeVisible = false;
                if (isSuccess) {
                    this.getShouru();
                    this.$message({ message: '新建成功', type: 'success'});
                }
            });
        },
        //新建支出(简化)
        toNewSpending() {
            this.newSpendingVisible = true;
            let userInfo = JSON.parse(localStorage.userInfo);
            this.spendingForm = {
                "type": 2,                                  //新建支出
                "paymentName": "",
                "payer": userInfo.companyCnFullName,        //付款方 
                "payerKey": userInfo.companyKey,            //付款方key
                "payerPayAmount": 0,                        //付款方付款金额
                "payerDate": dayjs().format('YYYY-MM-DD'),
                "payee": this.customerInfo.name,            //收款方
                "payeeKey": this.customerInfo.companyKey,   //收款方key
                "payeeCollectionAmount": 0,                 //收款方收款金额
                "collectionCurrency": "CNY",
                "exchangeRate": 1,
                "cnyAmount": 0,
                "handler": userInfo.userCnName,
                "remarks": "",
            }
        },
        newSpendingSubmit(){
            this.spendingForm.payeeCollectionAmount = this.spendingForm.payerPayAmount;
            this.btnLoading = true;
            this.$axios.post(`${this.$orderUrl}/api/Collection/SaveCollection`,this.spendingForm).then((res) => {
                const { isSuccess } = res;
                this.btnLoading = false;
                this.newSpendingVisible = false;
                if (isSuccess) {
                    this.getZhichu();
                    this.$message({ message: '新建成功', type: 'success'});
                }
            });
        },
        //删除收入、支出
        deleteCollection(row,type){
            this.$confirm(`是否删除该${type == 1 ? '收入' : '支出'}?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios.get(`${this.$orderUrl}/api/Collection/DeleteCollection?collectionKey=${row.key}`).then((res) => {
                    const { isSuccess } = res;
                    if (isSuccess) {
                        this.$message({ message: '删除成功', type: 'success'});
                        if(type == 1){ this.getShouru(); }
                        if(type == 2){ this.getZhichu(); }
                    }
                });
            }).catch(() => {});
        },
        getCurrencyConfig(){
            this.$axios.get(`${this.$customerUrl}/v1/Config/GetCurrencyConfig`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.currencyOption = data;
                }
            })
        },
        chooseCurrency1(value){
            let cItem = this.currencyOption.filter(ele => ele.currency == value)[0];
            this.incomeForm.exchangeRate = cItem.exchangeRate;
            this.calcCNYPrice1();
        },
        calcCNYPrice1(){
            this.incomeForm.cnyAmount = this.incomeForm.payeeCollectionAmount * this.incomeForm.exchangeRate * 1;
            this.incomeForm.cnyAmount = this.incomeForm.cnyAmount.toFixed(2) * 1;
        },
        calcRate1(){
            this.incomeForm.exchangeRate = this.incomeForm.cnyAmount / this.incomeForm.payeeCollectionAmount;
            this.incomeForm.exchangeRate = this.incomeForm.exchangeRate.toFixed(8) * 1;
        },
        chooseCurrency2(value){
            let cItem = this.currencyOption.filter(ele => ele.currency == value)[0];
            this.spendingForm.exchangeRate = cItem.exchangeRate;
            this.calcCNYPrice2();
        },
        calcCNYPrice2(){
            this.spendingForm.cnyAmount = this.spendingForm.payerPayAmount * this.spendingForm.exchangeRate * 1;
            this.spendingForm.cnyAmount = this.spendingForm.cnyAmount.toFixed(2) * 1;
        },
        calcRate2(){
            this.spendingForm.exchangeRate = this.spendingForm.cnyAmount / this.spendingForm.payerPayAmount;
            this.spendingForm.exchangeRate = this.spendingForm.exchangeRate.toFixed(8) * 1;
        },

        handleMoreOperateCommand(command) {
            switch (command) {
                case "新建联系人":
                    this.activeName = "linkman";
                    this.$refs.CustomerContact.toAdd();
                    break;
                case "新建订单":
                    this.createNewDingdan();
                    break;
                case "新建报价单":
                    this.createNewQuotation();
                    break;
                case "新增商机":
                    this.newCardVisible = true;
                    break;
                case "上传附件":
                    this.$refs.uploadFileInput.click();
                    break;
                case "新建收入":
                    this.toNewIncome();
                    break;
                case "新建支出":
                    this.toNewSpending();
                    break;        
                case "客户转移":
                    this.kehuzhuanyi.visible = true;
                    break;
                case "释放到公海":
                    this.release();
                    break;
                default:
                    null;
                    break;
            }
        },
        newBusinessOk() {
            this.newCardVisible = false;
            let routeData = this.$router.resolve({
                path: "/business/businessManage",
            });
            window.open(routeData.href, "_blank");
        },

        async release() {
            try {
                this.loading.view = true;
                const res = await this.$axios({
                    method: "GET",
                    url: this.$customerUrl + "/Company/RemovePrivate",
                    params: {
                        companykey: this.customerKey,
                    },
                });
                if (res.isSuccess) {
                    Message.success("操作成功");
                    this.$router.push(
                        `/customerManage/customerDetailsPublic?customerKey=${this.customerKey}`
                    );
                }
            } catch {
                null;
            } finally {
                this.loading.view = false;
            }
        },

        async handleUploadFileInputChange($event) {
            try {
                const file = $event.target.files[0];
                const formData = new FormData();
                formData.append("file", file);
                formData.append("permission", "我的");
                formData.append("remarkName", "");
                formData.append("folderKey", "");
                const data = (
                    await this.$axios({
                        url: `${this.$diskUrl}/File/Upload`,
                        method: "post",
                        data: formData,
                    })
                ).data;
                const res = await this.$axios({
                    method: "POST",
                    url: this.$customerUrl + "/Company/AddAnnex",
                    data: [
                        {
                            companyKey: this.customerKey,
                            name: data.remarkName,
                            url: data.fileUrl,
                            size: data.fileSize,
                        },
                    ],
                });
                if (res.isSuccess) {
                    Message.success("上传成功");
                    this.getFujianList();
                } else {
                    throw new Error();
                }
            } catch {
                Message.error("上传失败");
            }
        },
        async getFujianList() {
            try {
                const res = await this.$axios({
                    method: "GET",
                    url: this.$customerUrl + "/Company/GetAnnex",
                    params: {
                        customerKey: this.customerKey,
                    },
                });
                this.tabFujian.data = res.data.map((item) => ({
                    ...item,
                    imageUrl: this.isImageFile(item.name)
                        ? item.url
                        : fujianImage,
                }));
                this.tabFujian.pagination.total = res.data.length;
                this.handleTabFujianPageIndexChange();
            } catch {
                this.tabFujian.data = [];
            }
        },
        handleTabFujianPageIndexChange(pageIndex = 1) {
            this.tabFujian.table.data = this.tabFujian.data.slice(
                10 * (pageIndex - 1),
                10 * pageIndex
            );
            this.tabFujian.pagination.pageIndex = pageIndex;
        },
        async deleteFujian(key) {
            try {
                const res = await this.$axios({
                    method: "POST",
                    url: this.$customerUrl + "/Company/DeleteAnnex",
                    params: {
                        key,
                    },
                });
                if (res.isSuccess) {
                    Message.success("操作成功");
                    this.getFujianList();
                } else {
                    throw new Error();
                }
            } catch {
                Message.error("操作失败");
            }
        },
        isImageFile(fileName) {
            const dotIndex = fileName.lastIndexOf(".");
            const ext = fileName.slice(dotIndex + 1);
            if (["png", "jpg", "jpeg"].includes(ext)) {
                return true;
            }
            return false;
        },
        async handleKehuZhuanyiConfirm() {
            if (!this.kehuzhuanyi.value) {
                Message.warning("请选择同事");
                return;
            }
            try {
                this.kehuzhuanyi.loading = true;
                const res = await this.$axios({
                    method: "POST",
                    url: this.$customerUrl + "/Company/v2/Transfer",
                    data: {
                        customerKey: [this.customerKey],
                        ukey: this.kehuzhuanyi.value,
                    },
                });
                if (res.isSuccess) {
                    Message.success("操作成功");
                    this.kehuzhuanyi.visible = false;
                } else {
                    throw new Error();
                }
            } catch {
                Message.error("操作失败");
            } finally {
                this.kehuzhuanyi.loading = false;
            }
        },
        async getColleageOptions() {
            try {
                const res = await this.$axios({
                    method: "POST",
                    url: this.$authUrl + "/api/Staff",
                    data: {
                        companyKey: JSON.parse(
                            window.localStorage.getItem("userInfo")
                        ).companyKey,
                        pageIndex: 1,
                        pageSize: 50,
                    },
                });
                this.kehuzhuanyi.options = res.data.datas;
            } catch {
                null;
            }
        },
        async getStageOption() {
            try {
                const res = await this.$axios({
                    method: "GET",
                    url: this.$customerUrl + "/v1/Config/Stage",
                });
                // console.log(res.data);
                this.stageOption = res.data;
            } catch {
                null;
            }
        },
        async handleCFollowClick(type) {
            try {
                this.loading.view = true;
                const res = await this.$axios({
                    method: "GET",
                    url: this.$customerUrl + "/Company/Follow",
                    params: {
                        customerKey: this.customerKey,
                        type,
                    },
                });
                if (res.isSuccess) {
                    this.getCustomerInfo();
                }
            } catch {
                null;
            } finally {
                this.loading.view = false;
            }
        },
        async handleCTopClick(type) {
            try {
                this.loading.view = true;
                const res = await this.$axios({
                    method: "GET",
                    url: this.$customerUrl + "/Company/Top",
                    params: {
                        customerKey: this.customerKey,
                        type,
                    },
                });
                if (res.isSuccess) {
                    this.getCustomerInfo();
                }
            } catch {
                null;
            } finally {
                this.loading.view = false;
            }
        },
     
       
    },
    mounted() {
        let activeName = this.$route.query.activeName;
        if(activeName){
            this.activeName = activeName;
        }

        
        this.getCustomerInfo().then(() => {
            this.getOrder(); //获取客户关联订单
            this.getQuotation(); //获取客户关联报价单
            this.getBusiness(); //获取客户关联商机
        });
        this.getOperationHistroy();
        this.getFujianList();
        this.getZhichu();
        this.getShouru();
        this.getCurrencyConfig();

        this.getGroupList();
        this.getStatusOption();
        this.getSourceOption();
        this.getColleageOptions();
        this.getStageOption();
    },
};
</script>

<style lang="scss" scoped>
.customerDetails {
    .topContent {
        background: rgba($color: #ffffff, $alpha: 0.7);
        padding: 20px;
        .basicInfo {
            display: flex;
            position: relative;
            .companyImg {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 70px;
                height: 70px;
                border: 2px solid #d7d7d8;
                text-align: center;
            }
            .companyName {
                line-height: 32px;
                font-size: 20px;
                font-weight: bold;
                color: #383838;
            }
            .country {
                line-height: 24px;
                font-size: 12px;
                color: #909090;
            }
            .website {
                line-height: 28px;
                font-size: 14px;
                color: #383838;
            }
            .rightHandle {
                position: absolute;
                top: 0;
                right: 0;
                .fill {
                    display: inline-block;
                    margin-right: 10px;
                    cursor: pointer;
                    padding: 0 10px 0 25px;
                    text-align: center;
                    height: 30px;
                    line-height: 30px;
                    border-radius: 2px;
                    background: var(--themeColor);
                    color: #ffffff;
                    font-size: 12px;
                    position: relative;
                    img {
                        position: absolute;
                        top: 9px;
                        left: 10px;
                    }
                }
                .blank {
                    display: inline-block;
                    margin-right: 10px;
                    cursor: pointer;
                    padding: 0 10px 0 25px;
                    text-align: center;
                    height: 28px;
                    line-height: 28px;
                    border-radius: 2px;
                    background: #ffffff;
                    border: 1px solid #d7d7d8;
                    color: var(--themeColor);
                    font-size: 12px;
                    position: relative;
                    img {
                        position: absolute;
                        top: 9px;
                        left: 10px;
                    }
                    .img {
                        position: absolute;
                        top: 9px;
                        left: 10px;
                    }
                }
            }
        }
        .importantInfo {
            margin-top: 10px;
            .label {
                font-size: 14px;
                line-height: 28px;
                color: #5e6d82;
            }
            .value {
                height: 32px;
                line-height: 32px;
            }
        }
        .tagInfo {
            margin-top: 10px;
        }
    }
    .leftContent {
        box-sizing: border-box;
        width: 100%;
        //width: 65%;
        background: rgba($color: #ffffff, $alpha: 0.7);
        padding: 0 20px 20px;
        .contactInfo {
            .item {
                position: relative;
                display: flex;
                margin-bottom: 20px;
                box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
                height: 166px;
                align-items: center;
                background: #fff;
                &.main {
                    background: #fdf5f0;
                    ::v-deep .el-descriptions__body {
                        background: #fdf5f0;
                    }
                }
            }
        }
    }
    .rightContent {
        width: 34%;
        margin-left: 1%;
        background: rgba($color: #ffffff, $alpha: 0.7);
        padding: 0 20px 20px;
        .contactInfo {
            .contactItem {
                background: #f6f6f6;
                border-radius: 2px;
                padding: 10px;
                margin-bottom: 10px;
                font-size: 14px;
                .label {
                    line-height: 28px;
                    color: #c7c7c7;
                }
                .value {
                    line-height: 28px;
                    color: #3a3a3a;
                }
            }
        }
    }
}
.ziliaoTitle {
    height: 20px;
    line-height: 20px;
    font-size: 16px;
    font-weight: bold;
    color: #3A3A3A;
    margin-bottom: 10px;
    border-left: 3px solid var(--themeColor);
    padding-left: 5px;
}
.shoukuan {
    .describe {
        line-height: 28px;
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 10px;
        span {
            padding-left: 20px;
        }
    }
}

// 附件详情
.attachInfoStyle {
    padding: 20px;
    .img {
        margin-bottom: 20px;
        text-align: center;
    }
    .infoItem {
        line-height: 28px;
        font-size: 12px;
        .copy {
            color: var(--themeColor);
            cursor: pointer;
            padding-left: 20px;
        }
    }
}
.riskIcon {
    width: 24px;
    vertical-align: middle;
    margin-left: 5px;
    cursor: pointer;
}
// 报价单状态
.globe{
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    margin-top: 4px;
    margin-right: 8px;
}
.stateColor_0{
    background: #DBDBDB;
}
.stateColor_1{
    background: #DBDBDB;
}
.stateColor_2{
    background: #20BC5F;
}
.stateColor_3{
    background: #519DFF;
}
.stateColor_4{
    background: #353A3E;
}
.stateColor_5{
    background: #20BC5F;
}
.stateColor_6{
    background: #519DFF;
}
.stateColor_7{
    background: #353A3E;
}
</style>
