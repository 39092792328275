<template>
    <div>
        <InterfaceName :interfaceName="interfaceName" />
        <InterfaceSpe :requestUrl="requestUrl" :requestMethod="requestMethod" />
        <ReturnParam :returnObj="returnObj" />
    </div>
</template>

<script>
import InterfaceName from "../Acomponents/InterfaceName.vue";
import InterfaceSpe from "../Acomponents/InterfaceSpe.vue";
import ReturnParam from "../Acomponents/ReturnParam.vue";

export default {
    components: { InterfaceName, InterfaceSpe, ReturnParam },
    data() {
        return {
            interfaceName: "获取公海分组",
            requestUrl: "https://crm.qixinerp.com/open/Customer/GetPublicGroup",
            requestMethod: "GET",
            returnObj: {},
        };
    },
    methods: {},
    mounted() {
        this.returnObj = {
            isSuccess: true,
            errorMessage: null,
            data: [
                {
                    key: "d5a434c7756c4e60a96cadfe3993e811",
                    name: "未成交用户",
                    index: 0,
                    isDefault: false,
                    isSystem: false,
                },
                {
                    key: "cb05b88e9dd54a75a38e9050b3072a8c",
                    name: "成交过用户",
                    index: 0,
                    isDefault: true,
                    isSystem: false,
                },
                {
                    key: "7726caf7a22a497e9b05bb7e3f565b7c",
                    name: "公共公海分组",
                    index: 999,
                    isDefault: false,
                    isSystem: true,
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>

</style>