//锚点跟随
class AnchorInditor {
  static disable = false

  observer = null

  callback

  element

  constructor(callback, element) {
    this.callback = callback
    this.element = element
    this.observer = new IntersectionObserver((changes) => {
      if (changes[0].intersectionRatio === 1 && !AnchorInditor.disable) {
        this.callback()
      }
    }, {
      threshold: 1
    })
    this.observer.observe(this.element)
  }
}

export {
  AnchorInditor
}
