<template>
  <div id="abroad-competitor" class="abroad-competitor" v-show="competitors.length !== 0">
    <div class="title" ref="title">
      <p>竞争对手信息</p>
      <feedback position="竞争对手信息" url="" />
      <!-- <div class="flex-container">
        <img class="icon" src="@/firm/images/company/icon-feedback.png" style="margin-left: 1em" />
        <span class="text-with-icon" style="color: var(--themeColor, #3152AF)">反馈</span>
      </div> -->
    </div>
    <el-table :data="competitors" border :header-cell-style="{ background: '#f8faff', color: '#1F2D3D' }">
      <!-- <el-table-column prop="duns" label="邓白氏码" /> -->
      <el-table-column prop="primaryName" label="公司名称" />
      <el-table-column prop="individualEmployeeCount" label="雇佣人数" />
      <el-table-column prop="salesRevenue" label="销售收入" />
      <el-table-column prop="issuedShareCapitalAmount" label="发行股本金额" />
    </el-table>
  </div>
</template>

<script>
import Vue from "vue";
import { mapMutations } from "vuex";
import { AnchorInditor } from "../../../utils";

export default Vue.extend({
  props: ["competitors"],
  watch: {
    competitors() {
      try {
        if (this.competitors.length > 0) {
          this.setAnchorFlag([["JZDS", true]]);
        } else {
          throw new Error();
        }
      } catch {
        this.setAnchorFlag([["JZDS", false]]);
      }
    },
  },
  methods: {
    ...mapMutations("anchor", {
      setAnchorFlag: "setAnchorFlag",
      setCurrent: "setCurrent",
    }),
  },
  mounted() {
    new AnchorInditor(() => {
      this.setCurrent("JZDS");
    }, this.$refs["title"]);
  },
});
</script>

<style lang="scss" scoped>
@import "@/firm/scss/mixin.scss";

.abroad-competitor {
  background: #fff;
  padding: 1rem;
  padding-top: 0;
  @include mixin-panel-title;
}
</style>
