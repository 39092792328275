<template>
    <div class="Billoflading" v-loading="infoLoading">
        <div class="title">
            <span>{{dataCountryCn}}</span>
            <span class="ie">{{ie == 'i' ? '进口' : '出口'}}</span>
            <span>提单明细</span>
        </div>

        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th width="25%">数据源</th>
                        <td width="25%">{{dataCountryCn}}</td>
                        <th width="25%">日期</th>
                        <td width="25%">{{billInfo.Date}}</td>
                    </tr>
                    <tr>
                        <th>船东提单</th>
                        <td>{{billInfo.MasterBillOfLading}}</td>
                        <th>货运提单</th>
                        <td>{{billInfo.BillOfLadingNbr}}</td>
                    </tr>
                    <tr>
                        <th>通知人</th>
                        <td>{{billInfo.NotifyName ? billInfo.NotifyName : billInfo.Notify}}</td>
                        <th>通知人地址</th>
                        <td>{{billInfo.NotifyAddr ? billInfo.NotifyAddr: billInfo.NotifyAddress}}</td>
                    </tr>
                </tbody>
            </table>
            <table style="margin-top:20px;">
                <tbody>
                    <tr>
                        <th class="bg" width="50%">出口商</th>
                        <th class="bg" width="50%">进口商</th>
                    </tr>
                    <tr>
                        <td class="companyName" @click="toCompanyDetail(billInfo.Exporter,'exporter')">{{billInfo.Exporter}}</td>
                        <td class="companyName" @click="toCompanyDetail(billInfo.Importer,'importer')">{{billInfo.Importer}}</td>
                    </tr>
                    <tr>
                        <td>{{billInfo.ExporterAddr || billInfo.ExpAddress || billInfo.ExporterAddress}}</td>
                        <td>{{billInfo.ImporterAddr || billInfo.ImporterAddress || billInfo.ImpAddressCity}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">产品信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">HS</th>
                        <td colspan="3">{{billInfo.HsCode}}</td>
                    </tr>
                    <tr>
                        <th width="25%">产品描述</th>
                        <td colspan="3">{{billInfo.Product}}</td>
                    </tr>
                    <tr>
                        <th width="25%">数量</th>
                        <td width="25%">{{billInfo.Quantity}} {{billInfo.ManifestUnits}}</td>
                        <th width="25%">重量</th>
                        <td width="25%">{{billInfo.Weight}} {{billInfo.WeightUnit}}</td>
                    </tr>
                    <tr>
                        <th width="25%">价格</th>
                        <td width="25%">{{billInfo.Value ? billInfo.Value : billInfo.value}} {{billInfo.ValueUnit}}</td>
                        <th width="25%">美元金额</th>
                        <td width="25%">{{billInfo.ValueUSD ? billInfo.ValueUSD + 'USD' : '' }}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <USA v-if="dataCountryCn=='美国'" :billInfo="billInfo" :ie="ie" />
        <RUS v-else-if="dataCountryCn=='俄罗斯'" :billInfo="billInfo" :ie="ie" />
        <IND v-else-if="dataCountryCn=='印度'" :billInfo="billInfo" :ie="ie" />
        <MEX v-else-if="dataCountryCn=='墨西哥'" :billInfo="billInfo" :ie="ie" />

        <VNM v-else-if="dataCountryCn=='越南'" :billInfo="billInfo" :ie="ie" />
        <CRI v-else-if="dataCountryCn=='哥斯达黎加'" :billInfo="billInfo" :ie="ie" />
        <BRA v-else-if="dataCountryCn=='巴西'" :billInfo="billInfo" :ie="ie" />
        <ARG v-else-if="dataCountryCn=='阿根廷'" :billInfo="billInfo" :ie="ie" />

        <AFG v-else-if="dataCountryCn=='阿富汗'" :billInfo="billInfo" :ie="ie" />
        <BEN v-else-if="dataCountryCn=='贝宁'" :billInfo="billInfo" :ie="ie" />

        <BOL v-else-if="dataCountryCn=='玻利维亚'" :billInfo="billInfo" :ie="ie" />
        <BGD v-else-if="dataCountryCn=='孟加拉国'" :billInfo="billInfo" :ie="ie" />
        <YDY v-else-if="dataCountryCn=='一带一路'" :billInfo="billInfo" :ie="ie" />
        <CHL v-else-if="dataCountryCn=='智利'" :billInfo="billInfo" :ie="ie" />

        <CIS v-else-if="dataCountryCn=='独联体'" :billInfo="billInfo" :ie="ie" />
        <COD v-else-if="dataCountryCn=='刚果（金）'" :billInfo="billInfo" :ie="ie" />
        <COL v-else-if="dataCountryCn=='哥伦比亚'" :billInfo="billInfo" :ie="ie" />
        <ECU v-else-if="dataCountryCn=='厄瓜多尔'" :billInfo="billInfo" :ie="ie" />

        <ETH v-else-if="dataCountryCn=='埃塞俄比亚'" :billInfo="billInfo" :ie="ie" />
        <GTM v-else-if="dataCountryCn=='危地马拉'" :billInfo="billInfo" :ie="ie" />
        <HND v-else-if="dataCountryCn=='洪都拉斯'" :billInfo="billInfo" :ie="ie" />
        <IDN v-else-if="dataCountryCn=='印度尼西亚'" :billInfo="billInfo" :ie="ie" />

        <KAZ v-else-if="dataCountryCn=='哈萨克斯坦'" :billInfo="billInfo" :ie="ie" />
        <KEN v-else-if="dataCountryCn=='肯尼亚'" :billInfo="billInfo" :ie="ie" />
        <KGZ v-else-if="dataCountryCn=='吉尔吉斯斯坦'" :billInfo="billInfo" :ie="ie" />
        <LKA v-else-if="dataCountryCn=='斯里兰卡'" :billInfo="billInfo" :ie="ie" />

        <MDA v-else-if="dataCountryCn=='摩尔多瓦'" :billInfo="billInfo" :ie="ie" />
        <NIC v-else-if="dataCountryCn=='尼加拉瓜'" :billInfo="billInfo" :ie="ie" />
        <PAK v-else-if="dataCountryCn=='巴基斯坦'" :billInfo="billInfo" :ie="ie" />
        <PAN v-else-if="dataCountryCn=='巴拿马'" :billInfo="billInfo" :ie="ie" />

        <PER v-else-if="dataCountryCn=='秘鲁'" :billInfo="billInfo" :ie="ie" />
        <PHL v-else-if="dataCountryCn=='菲律宾'" :billInfo="billInfo" :ie="ie" />
        <PRY v-else-if="dataCountryCn=='巴拉圭'" :billInfo="billInfo" :ie="ie" />
        <TGO v-else-if="dataCountryCn=='多哥'" :billInfo="billInfo" :ie="ie" />

        <UGA v-else-if="dataCountryCn=='乌干达'" :billInfo="billInfo" :ie="ie" />
        <UKR v-else-if="dataCountryCn=='乌克兰'" :billInfo="billInfo" :ie="ie" />
        <URY v-else-if="dataCountryCn=='乌拉圭'" :billInfo="billInfo" :ie="ie" />
        <UZB v-else-if="dataCountryCn=='乌兹别克斯坦'" :billInfo="billInfo" :ie="ie" />

        <VEN v-else-if="dataCountryCn=='委内瑞拉'" :billInfo="billInfo" :ie="ie" />
        <TUR v-else-if="dataCountryCn=='土耳其'" :billInfo="billInfo" :ie="ie" />
        <KOR v-else-if="dataCountryCn=='韩国'" :billInfo="billInfo" :ie="ie" />
        <COG v-else-if="dataCountryCn=='刚果（布）'" :billInfo="billInfo" :ie="ie" />

        <CMR v-else-if="dataCountryCn=='喀麦隆'" :billInfo="billInfo" :ie="ie" />
        <LBR v-else-if="dataCountryCn=='利比里亚'" :billInfo="billInfo" :ie="ie" />
        <NAM v-else-if="dataCountryCn=='纳米比亚'" :billInfo="billInfo" :ie="ie" />
        <CAF v-else-if="dataCountryCn=='中非'" :billInfo="billInfo" :ie="ie" />

        <NGA v-else-if="dataCountryCn=='尼日利亚'" :billInfo="billInfo" :ie="ie" />
        <BWA v-else-if="dataCountryCn=='博茨瓦纳'" :billInfo="billInfo" :ie="ie" />
        <CIV v-else-if="dataCountryCn=='科特迪瓦'" :billInfo="billInfo" :ie="ie" />
        <TZA v-else-if="dataCountryCn=='坦桑尼亚'" :billInfo="billInfo" :ie="ie" />

        <LSO v-else-if="dataCountryCn=='莱索托'" :billInfo="billInfo" :ie="ie" />
        <TCD v-else-if="dataCountryCn=='乍得'" :billInfo="billInfo" :ie="ie" />
        <GHA v-else-if="dataCountryCn=='加纳'" :billInfo="billInfo" :ie="ie" />
        <BFA v-else-if="dataCountryCn=='布基纳法索'" :billInfo="billInfo" :ie="ie" />

        <!-- <el-card class="partItem" v-show="this.$isTest">
            <div slot="header">
                <span>提单明细字段动态展示</span>
            </div>
            <el-descriptions :column="2" border :labelStyle="{width:'25%',fontWeight: 700,color: '#000'}"
             :contentStyle="{width:'25%'}">
                <el-descriptions-item v-for="(fieldvalue,fieldName) in billInfo" :key="fieldName" :label="fieldName">
                    {{fieldvalue}}
                </el-descriptions-item>
            </el-descriptions>
        </el-card> -->

    </div>
</template>

<script>
import USA from "./countryCom/USA.vue";
import RUS from "./countryCom/RUS.vue";
import IND from "./countryCom/IND.vue";
import MEX from "./countryCom/MEX.vue";
import VNM from "./countryCom/VNM.vue";
import CRI from "./countryCom/CRI.vue";
import BRA from "./countryCom/BRA.vue";
import ARG from "./countryCom/ARG.vue";
import AFG from "./countryCom/AFG.vue";
import BEN from "./countryCom/BEN.vue";
import BOL from "./countryCom/BOL.vue";
import BGD from "./countryCom/BGD.vue";
import YDY from "./countryCom/YDY.vue";
import CHL from "./countryCom/CHL.vue";
import CIS from "./countryCom/CIS.vue";
import COD from "./countryCom/COD.vue";
import COL from "./countryCom/COL.vue";
import ECU from "./countryCom/ECU.vue";
import ETH from "./countryCom/ETH.vue";
import GTM from "./countryCom/GTM.vue";
import HND from "./countryCom/HND.vue";
import IDN from "./countryCom/IDN.vue";
import KAZ from "./countryCom/KAZ.vue";
import KEN from "./countryCom/KEN.vue";
import KGZ from "./countryCom/KGZ.vue";
import LKA from "./countryCom/LKA.vue";
import MDA from "./countryCom/MDA.vue";
import NIC from "./countryCom/NIC.vue";
import PAK from "./countryCom/PAK.vue";
import PAN from "./countryCom/PAN.vue";
import PER from "./countryCom/PER.vue";
import PHL from "./countryCom/PHL.vue";
import PRY from "./countryCom/PRY.vue";
import TGO from "./countryCom/TGO.vue";
import UGA from "./countryCom/UGA.vue";
import UKR from "./countryCom/UKR.vue";
import URY from "./countryCom/URY.vue";
import UZB from "./countryCom/UZB.vue";
import VEN from "./countryCom/VEN.vue";
import TUR from "./countryCom/TUR.vue";
import KOR from "./countryCom/KOR.vue";
import COG from "./countryCom/COG.vue";
import CMR from "./countryCom/CMR.vue";
import LBR from "./countryCom/LBR.vue";
import NAM from "./countryCom/NAM.vue";
import CAF from "./countryCom/CAF.vue";
import NGA from "./countryCom/NGA.vue";
import BWA from "./countryCom/BWA.vue";
import CIV from "./countryCom/CIV.vue";
import TZA from "./countryCom/TZA.vue";
import LSO from "./countryCom/LSO.vue";
import TCD from "./countryCom/TCD.vue";
import GHA from "./countryCom/GHA.vue";
import BFA from "./countryCom/BFA.vue";
export default {
    components: {
        USA,RUS,IND,MEX,VNM,CRI,BRA,ARG,AFG,BEN,BOL,BGD,YDY,CHL,CIS,COD,COL,ECU,
        ETH,GTM,HND,IDN,KAZ,KEN,KGZ,LKA,MDA,NIC,PAK,PAN,PER,PHL,PRY,TGO,UGA,UKR,URY,UZB,
        VEN,TUR,KOR,COG,CMR,LBR,NAM,CAF,NGA,BWA,CIV,TZA,LSO,TCD,GHA,BFA,
    },
    data() {
        return {
            tradeKey: "",
            dataCountryCn: "",
            threeEnCountryCode: "",
            ie: "i",
            infoLoading: false,
            billInfo: {},
        };
    },
    methods: {
        getBillInfo() {
            this.infoLoading = true;
            this.$axios.post(`${this.$searchUrl}/Trade/Bill/Details?tradeKey=${this.tradeKey}`).then((res) => {
                const { isSuccess, data } = res;
                this.infoLoading = false;
                if (isSuccess) {
                    // console.log(data);
                    if(data){
                        // console.log(typeof data);
                        if(typeof data == "string"){
                            this.billInfo = JSON.parse(data);
                        }else{
                            this.billInfo = data;
                        }
                        this.billInfo.Date = this.billInfo.Date.slice(0,10);
                    }
                }
            });
        },

        //跳转公司详情
        toCompanyDetail(companyName,isImpExp){
            companyName = companyName.replace(/<mark>/g,'').replace(/<\/mark>/g,'');//将mark标签替换掉空字符
            companyName = escape(companyName);
            let routeData = this.$router.resolve({
                path: "/AkeySearch/CompanyDetails",
                query: { 
                    companyName: companyName,
                    isImpExp: isImpExp,
                    ie: this.ie,
                    country: this.dataCountryCn,
                    countryCode: this.threeEnCountryCode,
                },
            });
            window.open(routeData.href, "_blank");
        },
    },
    mounted() {
        this.tradeKey = this.$route.query.tradeKey;
        this.dataCountryCn = this.$route.query.dataCountryCn;
        this.threeEnCountryCode = this.$route.query.threeEnCountryCode;
        this.ie = this.$route.query.ie;
        this.getBillInfo();
        document.title = `${this.dataCountryCn}${this.ie == 'i' ? '进口' : '出口'}提单明细`
    },
};
</script>

<style lang="scss" scoped>
@import '@/scss/common.scss';
.Billoflading {
    background-color: #f5f5f5;
    padding: 0 50px 20px;
    min-width: 1000px;
    .title {
        font-size: 18px;
        color: #333;
        height: 50px;
        line-height: 50px;
        .ie {
            display: inline-block;
            height: 30px;
            line-height: 30px;
            padding: 0 5px;
            margin: 0 5px;
            background-color: var(--themeColor);
            color: #fff;
        }
    }
}
.partItem {
    margin-bottom: 20px;
    /deep/.el-card__header {
        padding: 10px 20px;
        .headTitle {
            display: inline-block;
            padding-left: 10px;
            font-size: 14px;
            border-left: 2px solid var(--themeColor);
            font-weight: bold;
        }
    }
}
table {
    width: 100%;
    border-collapse: collapse;
    tr {
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;
        th,
        td {
            font-size: 12px;
            color: #666;
            height: 40px;
            border: 1px solid #eaeaea;
            text-align: center;
        }
        th {
            background-color: #f5f7fa;
            font-weight: 700;
        }
        .bg {
            background-color: var(--themeColor);
            color: #fff;
            font-weight: 700;
            opacity: 0.8;
        }
    }
}
.companyName{
    cursor: pointer;
    color: var(--themeColor);
}
</style>