import G6 from "../../../../plugins/g6";

const s = '12'
s.substring()

//  4
// 6
// 3...  | 4


function transformLongStr(str) {
  try {
    const length = str.length
    if (length <= 4) {
      return str
    } else if (length <= 10) {
      return str.substring(0, 4) + '\n' + str.substring(4)
    } else if (length <= 14) {
      return str.substring(0, 4) + '\n' + str.substring(4, 10) + '\n' + str.substring(10)
    } else {
      return str.substring(0, 4) + '\n' + str.substring(4, 10) + '\n' + str.substring(10, 13) + '...'
    }
  } catch {
    return ''
  }
}

const config = {
  defaultNodeSize: 30,
  color: {
    company: '#0BA7F0',
    encode: '#FF9F1E',
    country: '#FF3250',
    leafText: '#2A3F58',
    line: '#A0A0A0'
  },
  lineActiveWidth: 3
}

export {
  config
}

G6.registerNode(
  "Company",
  {
    drawShape: function drawShape(cfg, group) {
      const node = group.addShape("circle", {
        attrs: {
          r: config.defaultNodeSize,
          fill: config.color.company,
          cursor: "pointer",
         // stroke: '#266da0',
          lineWidth: 2,
        },
      });
      group.addShape("text", {
        attrs: {
          fontSize: 12,
          fill: "#fff",
          text: transformLongStr(cfg.name),
          textAlign: "center",
          textBaseline: "middle",
          cursor: "pointer",
        },
        draggable: true,
      });
      return node;
    },
    options: {
      stateStyles: {
        active: {
          lineWidth: 5,
          opacity: 1,
        },
        inactive: {
          opacity: 0.2,
        },
      }
    }
  },
  "circle"
);

G6.registerNode(
  "HsCode",
  {
    drawShape: function drawShape(cfg, group) {
      const node = group.addShape("circle", {
        attrs: {
          r: config.defaultNodeSize,
          fill: config.color.encode,
          cursor: "pointer",
         // stroke: '#e8591f',
          lineWidth: 2,
        },
      });
      group.addShape("text", {
        attrs: {
          fontSize: 12,
          fill: "#fff",
          text: cfg.name,
          textAlign: "center",
          textBaseline: "middle",
          cursor: "pointer",
        },
        draggable: true,
      });
      return node;
    },
    options: {
      stateStyles: {
        active: {
          lineWidth: 5,
          opacity: 1,
        },
        inactive: {
          opacity: 0.2,
        },
      }
    }
  },
  "circle"
);

G6.registerNode(
  "Country",
  {
    drawShape: function drawShape(cfg, group) {
      const node = group.addShape("circle", {
        attrs: {
          r: config.defaultNodeSize,
          fill: config.color.country,
          cursor: "pointer",
      //    stroke: '#971f4c',
          lineWidth: 2,
        },
      });
      group.addShape("text", {
        attrs: {
          fontSize: 12,
          fill: "#fff",
          text: transformLongStr(cfg.name),
          textAlign: "center",
          textBaseline: "middle",
          cursor: "pointer",
        },
        draggable: true,
      });
      return node;
    },
    options: {
      stateStyles: {
        active: {
          lineWidth: 5,
          opacity: 1,
        },
        inactive: {
          opacity: 0.2,
        },
      }
    }
  },
  "circle"
);

G6.registerNode(
  "Root",
  {
    drawShape: function drawShape(cfg, group) {
      const node = group.addShape("image", {
        attrs: {
          img: 'https://oa.lsmaps.com/ossfile/g6-icon-root.png',
          cursor: "pointer",
        },
      });
      group.addShape("text", {
        attrs: {
          x: 0,
          y: 60,
          textAlign: 'center',
          fontSize: 14,
          fill: "#1F2D3D",
          text: cfg.name,
          cursor: "pointer",
        },
        draggable: true,
      });
      return node;
    },
    options: {
      stateStyles: {
        active: {
          opacity: 1,
        },
        inactive: {
          opacity: 0.2,
        },
      }
    }
  },
  "circle"
);

export default G6


export function disableScroll() {
  document.documentElement.style.overflowY = 'hidden'
}

export function enableScroll() {
  document.documentElement.style.overflowY = 'scroll'
}
