<template>
  <div id="cycle-analyse" class="cycle-analyse">
    <div class="title" ref="title">
      <div class="text">
        <p>贸易周期频次分析</p>
        <!-- <span class="sub-title">{{ `${startTime.replaceAll("-", "/")}——${endTime.replaceAll("-", "/")}` }}</span> -->
      </div>
      <div style="display: flex; align-items: center">
        <el-radio-group v-model="year" size="mini" :disabled="loading">
          <el-radio-button v-for="item of yearOptions" :key="item" :label="item">{{ item }}</el-radio-button>
        </el-radio-group>
        <img v-show="false" class="icon" src="@/firm/images/company/icon-download.png" style="margin-left: 1em" />
      </div>
    </div>
    <div class="content">
      <el-skeleton-item v-show="loading" variant="rect" animated style="height: 220px" />
      <div v-show="!loading" ref="chart" style="height: 220px"></div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapMutations } from "vuex";
import echarts from "@/firm/plugins/echarts";
import LazyOperation from "@/firm/plugins/lazy-execute";
import { AnchorInditor } from "../utils";
import axios from "@/firm/plugins/axios";

let echartsInstance = null;
let lazyOperation = null;

export default Vue.extend({
  data() {
    return {
      yearOptions: ["2018", "2019", "2020", "2021", "2022"],
      year: "2021",
      loading: true,
    };
  },
  computed: {
    ...mapState(["company", "companyType"]),
    ...mapState("controlFormGlobal", {
      ie: "ie",
      dataCountry: "dataCountry",
      dateRange: "dateRange",
      hsCode: "hsCode",
      importer: "importer",
      exporter: "exporter",
      country: "country",

      exHsCode: "exHsCode",
      exExporter: "exExporter",
      exImporter: "exImporter",
      exCountry: "exCountry",
    }),
    /* ...mapGetters("controlFormGlobal", {
      startTime: "startTime",
      endTime: "endTime",
    }), */
  },
  watch: {
    year() {
      lazyOperation?.reobserve();
    },
    ie() {
      lazyOperation?.reobserve();
    },
    dataCountry() {
      lazyOperation?.reobserve();
    },
    /* dateRange() {
      lazyOperation?.reobserve();
    }, */
    hsCode() {
      lazyOperation?.reobserve();
    },
    importer() {
      lazyOperation?.reobserve();
    },
    exporter() {
      lazyOperation?.reobserve();
    },
    country() {
      lazyOperation?.reobserve();
    },

    exHsCode() {
      lazyOperation?.reobserve();
    },
    exExporter() {
      lazyOperation?.reobserve();
    },
    exImporter() {
      lazyOperation?.reobserve();
    },
    exCountry() {
      lazyOperation?.reobserve();
    },

    companyType() {
      lazyOperation?.reobserve();
    },
  },
  methods: {
    ...mapMutations("anchor", {
      setCurrent: "setCurrent",
    }),
    async refreshChart() {
      try {
        this.loading = true;
        const res = await axios({
          method: "post",
          url: "/search/Credit/Trend/Day",
          data: {
            ie: this.ie,
            importer: this.importer,
            exporter: this.exporter,
            dataCountry: this.dataCountry,
            country: this.country,
            startTime: `${this.year}-01-01`,
            endTime: `${this.year}-12-31`,
            hsCode: this.hsCode,
            sortType: 0,
            noHsCode: this.exHsCode,
            noImporter: this.exImporter,
            noExporter: this.exExporter,
            noCountry: this.exCountry,
            company: this.company,
            companyType: this.companyType,
          },
        });
        //console.log(res.data.result);
        const data = res.data.result;
        /* const data = [
        ["2021-01-01", 3000, 0, 120, 0], // [日期, 频次，金额，数量，重量]
        ["2021-02-22", 0, 120, 123, 10000],
        ["2021-07-22", 10000, 234, 34, 5],
      ]; */
        //const avaData = Math.max(...data.map((item: any) => Number(item[1]))) / data.length;
        echartsInstance?.setOption({
          calendar: {
            range: [`${this.year}-01-01`, `${this.year}-12-31`],
            itemStyle: {
              borderColor: "#fff",
              borderWidth: 4,
            },

            yearLabel: { show: false },
          },
          series: {
            name: "heatmap",
            data: data.map((item) => ({
              raw: item,
              value: [item[0], Number(item[1]), Number(item[1])],
            })),
          },
        });
      } catch {
        echartsInstance?.setOption({
          calendar: {
            range: [`${this.year}-01-01`, `${this.year}-12-31`],
            itemStyle: {
              borderColor: "#fff",
              borderWidth: 4,
            },

            yearLabel: { show: false },
          },
          series: {
            name: "heatmap",
            data: [],
          },
        });
      } finally {
        this.loading = false;
        setTimeout(() => {
          echartsInstance?.resize();
        });
      }
    },
  },
  mounted() {
    echartsInstance = echarts.init(this.$refs["chart"]);
    echartsInstance.setOption({
      tooltip: {
        show: true,
        trigger: "item",
        formatter: function (param) {
          const data = param.data;
          if (data) {
            return `
              ${data.raw[0]}<br />
              频次：${data.raw[1]}<br />
              金额：${data.raw[2]}<br />
              数量：${data.raw[3]}<br />
              重量：${data.raw[4]}
            `;
          } else {
            return "";
          }
        },
      },
      visualMap: {
        type: "piecewise",
        orient: "horizontal",
        pieces: [
          {
            value: 0,
            color: "#E8E8E8",
          },
          {
            value: 1,
            color: "#F9DE9A",
          },
          {
            min: 1,
            max: 4,
            color: "#F59D63",
          },
          {
            min: 4,
            color: "#CC5E39",
          },
        ],
      },
      backgroundColor: "#fff",
      calendar: {
        width: 1050,
        top: "middle",
        left: "50",
        range: [`${this.year}-01-01`, `${this.year}-12-31`],
        itemStyle: {
          borderColor: "#fff",
          borderWidth: 4,
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: "#303133",
          },
        },
        yearLabel: { show: false },
        dayLabel: {
          firstDay: 1,
          nameMap: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
        },
        monthLabel: {
          nameMap: "cn",
        },
      },
      series: {
        name: "heatmap",
        type: "heatmap",
        coordinateSystem: "calendar",
        emphasis: {
          itemStyle: {
            color: "#2BE7F5",
          },
        },
        label: {
          show: true
        },
        data: [],
      },
    });
    lazyOperation = new LazyOperation(() => {
      this.refreshChart();
    }, this.$el);
    new AnchorInditor(() => {
      this.setCurrent("MYZQ");
    }, this.$refs["title"]);
  },
});
</script>

<style lang="scss" scoped>
@import "@/firm/scss/mixin.scss";

.cycle-analyse {
  margin-top: 1rem;
  background: #fff;
  padding: 1rem;
  padding-top: 0;
  @include mixin-panel-title;
}
</style>
