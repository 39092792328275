<template>
  <!-- 国外企业，搜鱼块 -->
  <div v-loading="loading">
    <div id="enterprise-info-basic-souyu" class="enterprise-info-basic-info" v-show="!hidden">
      <div class="title" ref="title">
        <p>搜鱼公司信息</p>
        <feedback position="搜鱼公司信息" url="" />
      </div>
      <div>
        <el-row>
          <el-col :span="4"><div class="container pl border t l">公司名</div></el-col>
          <el-col :span="8"
            ><div class="container border t">
              <div class="pl border l">{{ information.companyName }}</div>
            </div></el-col
          >
          <el-col :span="4"
            ><div class="container border t">
              <div class="pl border l">网址</div>
            </div></el-col
          >
          <el-col :span="8"
            ><div class="container border t r">
              <div class="pl border l" style="color: var(--themeColor, #3152AF)" @click="navToBroswer(information.netaddress)">{{ information.netaddress }}</div>
            </div></el-col
          >
        </el-row>
        <el-row>
          <el-col :span="4"><div class="container pl border t l">联系电话</div></el-col>
          <el-col :span="20"
            ><div class="container border t r">
              <div class="pl border l">{{ information.tel }}</div>
            </div></el-col
          >
        </el-row>
        <el-row>
          <el-col :span="4"><div class="container pl border l t b">所在地址</div></el-col>
          <el-col :span="20"
            ><div class="container border t r b">
              <el-tooltip :content="information.address" placement="top" effect="light">
                <div class="pl border l signle-line-omit">{{ information.address }}</div>
              </el-tooltip>
            </div></el-col
          >
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "@/firm/plugins/axios";
import { mapState, mapMutations } from "vuex";
import { AnchorInditor } from "../../../utils";

export default Vue.extend({
  data() {
    return {
      loading: false,
      hidden: false,
      information: {
        companyName: "",
        netaddress: "",
        tel: "",
        address: "",
      },
    };
  },
  computed: {
    ...mapState(["company"]),
  },
  methods: {
    ...mapMutations("anchor", {
      setAnchorFlag: "setAnchorFlag",
      setCurrent: "setCurrent",
    }),
    async getData() {
      try {
        this.loading = true;
        const res = await axios({
          method: "GET",
          url: "/search/Credit/Info/Sofish",
          params: {
            keyword: this.company,
          },
        });
        const data = res.data.result;
        this.information = {
          companyName: data.companyName || "-",
          netaddress: data.webSite || "-",
          tel: data.phone || "-",
          address: data.address || "-",
        };
        this.hidden = false;
        this.setAnchorFlag([["SOUYU", true]]);
      } catch (error) {
        this.hidden = true;
        this.setAnchorFlag([["SOUYU", false]]);
      } finally {
        this.loading = false;
      }
    },
    navToBroswer(url) {
      if (url && url !== "-") {
        window.open("http://" + url, "_blank");
      }
    },
  },
  mounted() {
    this.getData();
    new AnchorInditor(() => {
      this.setCurrent("SOUYU");
    }, this.$refs["title"]);
  },
});
</script>
