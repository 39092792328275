<template>
  <!-- 合并公司，未复用 -->
  <div class="combaine-company">
    <el-dialog visible width="1100px" top="10px">
      <div v-loading="loading">
        <p style="display: flex; align-items: center; justify-content: space-between; margin-bottom: 18px">
          <span>合并公司</span>
        </p>
        <div style="border: 1px solid #d3dce6; border-radius: 2px">
          <div style="display: flex; align-items: center; justify-content: space-between; padding: 0.8em">
            <div style="display: flex; align-items: center">
              <span style="color: #8492a6; flex-shrink: 0">待合并公司：</span>
              <!-- <span>{{ companyName }}</span> -->
              <el-input :style="companyNameInputStyle" class="no-border-input" v-model="companyName" placeholder="合并后显示公司名" size="mini" />
              <!-- <i class="el-icon-edit" style="color: var(--themeColor, #3152AF); margin-left: 5px"></i> -->
            </div>
            <div @click="disband" style="color: var(--themeColor, #3152AF); font-size: 13px" class="cursor-pointer">解散合并公司</div>
          </div>
          <div style="border-top: 1px solid #d3dce6; display: flex; padding-top: 20px">
            <div style="box-sizing: border-box; width: 600px; padding: 0 25px">
              <div style="display: flex; align-items: center; justify-content: space-between; margin-bottom: 10px">
                <span style="color: #2a3f58">待合并公司</span>
                <span @click="clearCombained" style="color: var(--themeColor, #3152AF); font-size: 13px" class="cursor-pointer">清空合并列表</span>
              </div>
              <el-table :data="combained.tableData" size="mini" :header-cell-style="{ background: '#E5E9F2', color: '#475669', 'font-weight': 'normal' }" tooltip-effect="light">
                <el-table-column type="index" label="#" align="center" />
                <el-table-column prop="company" label="公司名" show-overflow-tooltip />
                <el-table-column label="操作" width="80px">
                  <template slot-scope="{ row }">
                    <el-button @click="removeCombainedCompany(row)" type="text" size="mini">移除</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <div style="margin: 0.5em 0; display: flex; justify-content: space-between; align-items: center">
                <span style="color: #999999; font-size: 12px">{{ `共${combained.pagination.total}条` }}</span>
                <el-pagination small background layout=" prev, pager, next" :total="combained.pagination.total" :page-size="combained.pagination.pageSize" :current-page.sync="combained.pagination.pageIndex" />
              </div>
            </div>
            <div style="box-sizing: border-box; width: calc(100% - 600px); padding: 0 20px; border-left: 1px solid #d3dce6">
              <div style="display: flex; align-items: flex-start; justify-content: space-between">
                <div style="width: 100%; padding-right: 15px">
                  <div style="display: flex; align-items: center; justify-content: space-between">
                    <el-input v-model="search.word" placeholder="公司名" size="small">
                      <i slot="prefix" class="el-input__icon el-icon-search"></i>
                    </el-input>
                  </div>
                  <div style="display: flex; align-items: center; font-size: 12px; margin: 10px 0">
                    <img src="@/firm/images/company/icon-filter-grey.png" style="margin-right: 3px" />
                    <span style="color: #999999; flex-shrink: 0">搜索工具</span>
                    <el-select size="mini" v-model="search.tool.key" style="flex-shrink: 0; width: 86px; margin: 0 10px">
                      <el-option label="不包含" :value="0" />
                    </el-select>
                    <el-input size="mini" placeholder="关键词" v-model="search.tool.word" />
                  </div>
                </div>
                <el-button @click="searchCompany" icon="el-icon-search" size="small">查询</el-button>
              </div>
              <div v-show="mode === 'recommend'" class="recommend">
                <p style="font-size: 16px; color: #2a3f58; margin: 15px 0">推荐公司</p>
                <div style="display: flex; flex-wrap: wrap">
                  <el-tooltip v-for="(item, index) of search.recommend" :key="item.company" effect="light" :content="item.company" placement="top-start">
                    <span @click="toggleRecommendChecked(index)" class="tag signle-line-omit cursor-pointer" :class="{ 'is-checked': item.selected }">{{ item.company }}</span>
                  </el-tooltip>
                </div>
              </div>
              <div v-show="mode === 'search'">
                <el-table @select-all="selectAllsearchTableRow" @select="selectsearchTableRow" ref="search-table" :data="search.tableData" max-height="350px" size="mini" :header-cell-style="{ background: '#E5E9F2', color: '#475669', 'font-weight': 'normal' }" tooltip-effect="light">
                  <el-table-column type="selection" width="45" align="center" />
                  <el-table-column type="index" label="#" align="center" />
                  <el-table-column prop="company" label="公司名" show-overflow-tooltip />
                </el-table>
                <div style="margin: 0.5em 0; display: flex; justify-content: space-between; align-items: center">
                  <span style="color: #999999; font-size: 12px">{{ `找到相关结果${search.pagination.total}个` }}</span>
                  <el-pagination small background layout=" prev, pager, next" :total="search.pagination.total" :page-size="search.pagination.pageSize" :current-page.sync="search.pagination.pageIndex" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="display: flex; justify-content: flex-end; margin-top: 10px">
          <el-button size="small" @click="$emit('destroy')">取消</el-button>
          <el-button @click="combaineCompany" type="primary" size="small">合并公司</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "@/firm/plugins/axios";

// 已通过单元测试
function transformStr(str) {
  const trimedStr = str.trim();
  const spaceStr = trimedStr.replaceAll(/\s+/g, " ");
  return spaceStr.toUpperCase();
}

export default Vue.extend({
  props: ["company"],
  data() {

    console.log(this.company)
    return {
      loading: false,
      mode: "recommend", // 'recommend' | 'search'
      companyName: this.company||'',
      combained: {
        totalData: [
          /* {
            company: "best",
          } */
        ],
        tableData: [
          /* {
            company: "best",
          } */
        ],
        pagination: {
          total: 0,
          pageIndex: 1,
          pageSize: 10,
        },
      },
      search: {
        word: "",
        tool: {
          key: 0,
          word: "",
        },
        recommend: [
          /* {
            company: "",
            selected: false,
          }, */
        ],
        totalData: [],
        tableData: [
          /* {
            company: "best",
          }, */
        ],
        pagination: {
          total: 0,
          pageIndex: 1,
          pageSize: 10,
        },
      },
    };
  },
  computed: {
    companyNameInputStyle() {
      const len = (this.$data.companyName).length;
      return {
        "min-width": "8em",
        width: `${len * 0.8}em`,
        "max-width": "800px",
      };
    },
  },
  watch: {
    "combained.totalData"(val) {
      const pageSize = this.combained.pagination.pageSize;
      const pageIndex = this.combained.pagination.pageIndex;
      const range = [(pageIndex - 1) * pageSize, pageIndex * pageSize];
      this.combained.tableData = this.combained.totalData.slice(...range);
      this.combained.pagination.total = val.length;
    },
    "combained.pagination.pageIndex"() {
      const pageSize = this.combained.pagination.pageSize;
      const pageIndex = this.combained.pagination.pageIndex;
      const range = [(pageIndex - 1) * pageSize, pageIndex * pageSize];
      this.combained.tableData = this.combained.totalData.slice(...range);
    },
    "search.word"(val) {
      if (val === "") {
        this.mode = "recommend";
      }
    },
    "search.totalData"(val) {
      const pageSize = this.search.pagination.pageSize;
      const pageIndex = this.search.pagination.pageIndex;
      const range = [(pageIndex - 1) * pageSize, pageIndex * pageSize];
      this.search.tableData = this.search.totalData.slice(...range);
      this.search.pagination.total = val.length;
    },
    "search.pagination.pageIndex"() {
      const pageSize = this.search.pagination.pageSize;
      const pageIndex = this.search.pagination.pageIndex;
      const range = [(pageIndex - 1) * pageSize, pageIndex * pageSize];
      this.search.tableData = this.search.totalData.slice(...range);
      this.detectSearchTableDataSelected();
    },
  },
  methods: {
    //清空合并列表
    clearCombained() {
      this.combained.totalData = [];
      this.search.recommend = this.search.recommend.map((item) => ({
        company: item.company,
        selected: false,
      }));
    },
    //获取已合并公司列表
    async getCombainedInfo() {
      try {
        const res = await axios({
          method: "get",
          url: "/search/Credit/MergeCompany/MergeCompanyInfo",
          params: {
            keyword: this.companyName,
          },
        });
        this.combained.totalData = res.data.result.map((item) => ({
          company: item.company,
        }));
      } catch {
        this.combained.totalData = [];
      }
    },
    //移除已合并公司列表项
    removeCombainedCompany(row) {
      const company = row.company;
      const index = this.combained.totalData.findIndex((item) => item.company === company);
      const i = this.search.recommend.findIndex((item) => item.company === company);
      if (i > -1) {
        (this.search.recommend[i]).selected = false;
      }
      this.combained.totalData.splice(index, 1);
      const item = (this.search.tableData).find((item) => item.company === company);
      if (item) {
        (this.$refs["search-table"]).toggleRowSelection(item, false);
      }
    },
    //获取推荐公司
    async getRecommand() {
      try {
        const res = await axios({
          method: "post",
          url: "/search/Credit/MergeCompany/Search",
          data: {
            companyName: this.companyName,
            searchType: 0,
            keyWord: "",
          },
        });
        const companys = res.data.result.companys.slice(0, 10);
        const hashMap = new Map();
        companys.forEach((item) => {
          hashMap.set(item, false);
        });
        this.combained.totalData.forEach((item) => {
          if (hashMap.has(item.company)) {
            hashMap.set(item.company, true);
          }
        });
        const data = [];
        hashMap.forEach((value, key) => {
          data.push({
            company: key,
            selected: value,
          });
        });
        this.search.recommend = data;
      } catch {
        this.search.recommend = [];
      }
    },
    //切换推荐公司选中状态
    toggleRecommendChecked(index) {
      const status = !(this.search.recommend[index]).selected;
      (this.search.recommend[index]).selected = status;
      const company = (this.search.recommend[index]).company;
      if (status) {
        (this.combained.totalData).push({
          company,
        });
      } else {
        const i = this.combained.totalData.findIndex((item) => item.company === company);
        this.combained.totalData.splice(i, 1);
      }
    },
    //搜索相似公司
    async searchCompany() {
      try {
        this.loading = true;
        this.search.word = transformStr(this.search.word);
        this.search.tool.word = transformStr(this.search.tool.word);
        const res = await axios({
          method: "post",
          url: "/search/Credit/MergeCompany/Search",
          data: {
            companyName: this.search.word,
            searchType: this.search.tool.key,
            keyWord: this.search.tool.word,
          },
        });
        this.search.totalData = res.data.result.companys.map((item) => ({
          company: item,
        }));
        this.mode = "search";
        this.detectSearchTableDataSelected();
      } catch {
        this.search.totalData = [];
      } finally {
        this.loading = false;
      }
    },
    selectAllsearchTableRow(selection) {
      if (this.search.tableData.length === 0) {
        return;
      }
      if (selection.length === 0) {
        const companys = this.search.tableData.map((item) => item.company);
        const set = new Set(companys);
        const indexes = [];
        this.combained.totalData.forEach((item, index) => {
          if (set.has(item.company)) {
            indexes.push(index - indexes.length);
          }
        });
        for (const i of indexes) {
          this.combained.totalData.splice(i, 1);
        }
      } else {
        const companys = this.search.tableData.map((item) => item.company);
        const set = new Set(companys);
        this.combained.totalData.forEach((item) => {
          if (set.has(item.company)) {
            set.delete(item.company);
          }
        });
        const items = [];
        set.forEach((item) => {
          items.push({
            company: item,
          });
        });
        (this.combained.totalData).push(...items);
      }
    },
    //手动勾选数据行
    selectsearchTableRow(selection, row) {
      const status = selection.includes(row);
      if (status) {
        (this.combained.totalData).push({
          company: row.company,
        });
      } else {
        const index = this.combained.totalData.findIndex((item) => item.company === row.company);
        this.combained.totalData.splice(index, 1);
      }
    },
    //合并公司
    async combaineCompany() {
      try {
        this.loading = true;
        const companys = this.combained.totalData.map((item) => item.company);
        //console.log(this.$store.state.type)
        const isExpImpType2isExpImpCompany=new Map()
        isExpImpType2isExpImpCompany.set('i','Importer')
        isExpImpType2isExpImpCompany.set('e','Exporter')
        await axios({
          method: "post",
          url: "/search/Credit/MergeCompany/UpdateMergeCompany",
          data: {
            mainCompany: this.companyName,
            isExpImpType: this.$store.state.controlFormGlobal.ie,
            isExpImpCompany: isExpImpType2isExpImpCompany.get(this.$store.state.controlFormGlobal.ie), // this.$store.state.type,
            childCompanys: companys,
          },
        });
      } catch (error) {
        console.log(error);
      } finally {
        await axios({
          method: "get",
          url: "/search/Credit/MergeCompany/MergeCompanyInfo",
          params: {
            keyword: this.companyName,
          },
        });
        window.location.reload();
      }
    },
    //解散合并公司
    async disband() {
      try {
        this.loading = true;
        await axios({
          method: "post",
          url: "/search/Credit/MergeCompany/UpdateMergeCompany",
          data: {
            mainCompany: this.companyName,
            isExpImpType: this.$store.state.controlFormGlobal.ie,
            isExpImpCompany: this.$store.state.type,
            childCompanys: [],
          },
        });
      } catch (error) {
        console.log(error);
      } finally {
        await axios({
          method: "get",
          url: "/search/Credit/MergeCompany/MergeCompanyInfo",
          params: {
            keyword: this.companyName,
          },
        });
        window.location.reload();
      }
    },

    //utils
    //检测 searchTable 的选中状态
    detectSearchTableDataSelected() {
      return setTimeout(() => {
        const hashMap = new Map();
        (this.search.tableData).forEach((item) => {
          hashMap.set(item.company, item);
        });
        (this.combained.totalData).forEach((item) => {
          if (hashMap.has(item.company)) {
            (this.$refs["search-table"]).toggleRowSelection(hashMap.get(item.company), true);
          }
        });
      });
    },
  },
  async mounted() {
    console.log('oll')
    this.loading = true;
    await this.getCombainedInfo();
    await this.getRecommand();
    this.loading = false;
  },
});
</script>

<style lang="scss" scoped>
.combaine-company {
  ::v-deep .el-dialog {
    margin: 0 auto;
  }
  ::v-deep .el-dialog__header {
    display: none;
  }
  ::v-deep .el-dialog__body {
    font-size: 14px;
    padding: 20px;
  }
  ::v-deep td {
    padding: 3px 0 !important;
    border-bottom: 1px #e0e6ed solid;
  }
  .recommend {
    .tag {
      display: inline-block;
      margin-bottom: 10px;
      margin-right: 20px;
      padding: 1px 5px;
      border: 1px solid #fcddbf;
      border-radius: 4px;
      color: var(--themeColor, #3152AF);
      &.is-checked {
        background:#bac3de;
      }
    }
  }
}
.no-border-input {
  ::v-deep .el-input__inner {
    border: none;
    padding: 0;
  }
}
</style>
