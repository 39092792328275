<template>
    <div class="addOrder">
        <div class="header">
            <span>{{theme}}</span>
            <el-button size="small" @click="goBack" v-if="$route.query.isBack">返回</el-button>
        </div>
        

        <div class="title">基础信息</div>
        <el-form :model="addForm" label-width="100px" size="small">
            <el-row :gutter="16">
                <el-col :span="8">
                    <el-form-item label="当前处理人" required>
                        <el-select v-model="addForm.handlerKey" size="small" filterable placeholder="当前处理人" class="w100">
                            <el-option v-for="item in staffOption" :key="item.key" :label="item.name" :value="item.key"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="业绩归属人" required>
                        <el-select v-model="addForm.performanceAttributionKey" size="small" filterable placeholder="业绩归属人" class="w100">
                            <el-option v-for="item in staffOption" :key="item.key" :label="item.name" :value="item.key"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="归属部门" required>
                        <el-cascader :show-all-levels="false" v-model="addForm.organizationKey" :options="belongOptions" :props="{ value: 'key', label: 'name', checkStrictly: true }" size="small" @change="belongChange" placeholder="业绩归属部门" class="w100" />
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="订单名称" required>
                        <el-input v-model="addForm.orderName" class="w100"></el-input>
                    </el-form-item>
                </el-col>
                <!-- <el-col :span="8">
                    <el-form-item label="币种" required>
                        <el-select v-model="addForm.currency" size="small" filterable class="w100">
                            <el-option v-for="item in currencyOption" :key="item.key" :label="item.currency" :value="item.currency"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col> -->
                <el-col :span="8">
                    <el-form-item label="订单日期" required>
                        <el-date-picker v-model="addForm.orderDate" type="date" value-format="yyyy-MM-dd" placeholder="选择日期" class="w100"></el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="客户" required>
                        <el-select v-model="addForm.customerKey" class="w100" placeholder="请选择客户" filterable @change="chooseCustomer" :disabled="isAbleChoose">
                            <el-option v-for="item of myCustomerOption" :key="item.key" :label="item.name" :value="item.key" />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="联系人">
                        <el-select v-model="addForm.contactKey" class="w100" placeholder="先选择客户,后选择联系人" @change="chooseContact">
                            <el-option v-for="item of contactOption" :key="item.key" :label="item.nickname" :value="item.key" />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="联系人电话">
                        <el-input v-model="addForm.contactsCall" class="w100"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="客户地址">
                        <el-input v-model="addForm.customerAddress" class="w100"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="价格条款">
                        <el-input v-model="addForm.priceTerms" class="w100" name="priceTerms" autocomplete="on"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="付款方式">
                        <el-input v-model="addForm.paymentMethod" class="w100" name="paymentMethod" autocomplete="on"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="交货日期">
                        <el-date-picker v-model="addForm.deliveryDate" type="date" value-format="yyyy-MM-dd" placeholder="选择日期" class="w100"></el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="预计到账日期">
                        <el-date-picker v-model="addForm.expectedReceiptDate" type="date" value-format="yyyy-MM-dd" placeholder="选择日期" class="w100"></el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="银行信息">
                        <el-input v-model="addForm.bankInfo" class="w100" name="bankInfo" autocomplete="on"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="目的港">
                        <el-input v-model="addForm.portOfDestination" class="w100" name="portOfDestination" autocomplete="on"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="起运港">
                        <el-input v-model="addForm.portOfShipment" class="w100" name="portOfShipment" autocomplete="on"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="16">
                    <el-form-item label="备注">
                        <el-input v-model="addForm.remarks" type="textarea"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>

        <!-- 订单产品列表 -->
        <div class="title">
            <span style="color: #ff2d2d">*</span>
            <span>订单产品</span>
            <span style="color: #afafaf">(必选项)</span>
        </div>
        <el-table :data="orderProducts" border :header-cell-style="{'background-color':'#F3F3F3','color':'#3A3A3A'}">
            <el-table-column label="产品名称" width="200">
                <template slot-scope="scope">
                    <span style="color: #ff2d2d">*</span>
                    <el-select v-model="scope.row.productKey" filterable placeholder="请选择产品" size="small" style="width:160px;"
                        @change="(value) => selectProduct(value,scope.row)">
                        <el-option v-for="item in productOption" :key="item.key" :label="item.productName" :value="item.key">
                            {{item.productName}}(编号:{{item.productNo}})(规格:{{item.dimension}})
                        </el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column label="单价" width="160">
                <template slot-scope="scope">
                    <el-input-number v-model="scope.row.unitPrice" size="small" :min="0" :controls="false" @blur="calcTotalPrice(scope.row)"></el-input-number>
                </template>
            </el-table-column>
            <el-table-column label="订购数量" width="160">
                <template slot-scope="scope">
                    <el-input-number v-model="scope.row.num" size="small" :min="0" :controls="false" @blur="calcTotalPrice(scope.row)"></el-input-number>
                </template>
            </el-table-column>
            <el-table-column label="总价" width="160">
                <template slot-scope="scope">
                    <el-input-number v-model="scope.row.totalPrice" size="small" :min="0" :controls="false" @blur="calcCNYPrice(scope.row)"></el-input-number>
                </template>
            </el-table-column>
            <el-table-column label="币种" width="120">
                <template slot-scope="scope">
                    <el-select v-model="scope.row.currency" size="small" filterable class="w100" @change="(value)=>chooseCurrency(value,scope.row)">
                        <el-option v-for="item in currencyOption" :key="item.key" :label="item.currency" :value="item.currency"></el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column label="汇率" width="160">
                <template slot-scope="scope">
                    <el-input-number :disabled="scope.row.currency=='CNY'" v-model="scope.row.exchangeRate" size="small" :min="0" :controls="false" @blur="calcCNYPrice(scope.row)"></el-input-number>
                </template>
            </el-table-column>
            <el-table-column label="人民币总价" width="160">
                <template slot-scope="scope">
                    <el-input-number :disabled="scope.row.currency=='CNY'" v-model="scope.row.cnyTotalPrice" size="small" :min="0" :controls="false" @blur="calcRate(scope.row)"></el-input-number>
                </template>
            </el-table-column>
            <el-table-column label="备注">
                <template slot-scope="scope">
                    <el-input v-model="scope.row.remarks" size="small"></el-input>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="60">
                <template slot-scope="scope">
                    <div class="handleDelBtn" @click="delProductItem(scope.$index)">删除</div>
                </template>
            </el-table-column>
        </el-table>
        <div class="bottomAddBtn" @click="addProductItem">
            <img src="@/assets/icon/plus.png" alt="" />新增产品
        </div>
        <div class="summation">
            <div>
                <span>产品总数量：</span>
                <span style="padding-left:10px;">{{productTotalNum}}</span>
            </div>
            <div>
                <span>产品总金额：</span>
                <span>CNY</span>
                <span style="padding-left:10px;">{{productTotalPrice}}</span>
            </div>
        </div>

        <div class="title" style="margin-top:20px;">贸易费用</div>
        <div class="tradeCost">
            <div v-show="tradeExpenses.length == 0" style="text-align: center;color: #7C90A5;padding: 10px;">暂无贸易费用</div>
            <div v-for="(item,index) in tradeExpenses" :key="index" class="itemCost">
                <span style="margin-right: 20px;">{{index+1}}</span>
                <div style="margin-right: 20px;">
                    <span>项目：</span>
                    <el-select v-model="item.project" size="small" style="width:180px;" filterable allow-create>
                        <el-option v-for="item in projectOption" :key="item.key" :label="item.name" :value="item.name"></el-option>
                        <div class="toConfig" @click="toConfig">
                            <i class="el-icon-plus"></i>
                            <span>配置费用</span>
                        </div>
                    </el-select>
                </div>
                <div style="margin-right: 20px;">
                    <span>金额：</span>
                    <el-input-number v-model="item.totalPrice" size="small" :min="0" :controls="false" @blur="calcCNYPrice(item)"></el-input-number>
                </div>
                <div style="margin-right: 20px;">
                    <span>币种：</span>
                    <el-select v-model="item.currency" size="small" filterable style="width:100px;" @change="(value)=>chooseCurrency(value,item)">
                        <el-option v-for="item in currencyOption" :key="item.key" :label="item.currency" :value="item.currency"></el-option>
                    </el-select>
                </div>
                <div style="margin-right: 20px;">
                    <span>汇率：</span>
                    <el-input-number :disabled="item.currency=='CNY'" v-model="item.exchangeRate" size="small" :min="0" :controls="false" @blur="calcCNYPrice(item)"></el-input-number>
                </div>
                 <div style="margin-right: 20px;">
                    <span>人民币金额：</span>
                    <el-input-number :disabled="item.currency=='CNY'" v-model="item.cnyTotalPrice" size="small" :min="0" :controls="false" @blur="calcRate(item)"></el-input-number>
                </div>
                <div class="handleDelBtn" @click="delCostItem(index)">删除</div>
            </div>
        </div>
        <div class="bottomAddBtn" @click="addCostItem">
            <img src="@/assets/icon/plus.png" alt="" />新增费用
        </div>
        <div class="summation">
            <div></div>
            <div>
                <span>贸易费用金额：</span>
                <span>CNY</span>
                <span style="padding-left:10px;">{{tradeExpensesTotalPrice}}</span>
            </div>
        </div>
        <div class="summation" style="font-size: 16px;font-weight: bold;">
            <div></div>
            <div>
                <span>合计总金额：</span>
                <span>CNY</span>
                <span style="padding-left:10px;">{{allTotalPrice}}</span>
            </div>
        </div>

        <!-- <div class="title" style="margin-top:20px;">供应商</div>
        <div>
            <el-select v-model="suppliers" size="small" multiple filterable style="width:100%;" placeholder="选择供应商">
                <el-option v-for="item in supplierOption" :key="item.key" :label="item.manufacturerName" :value="item.key"></el-option>
                <div class="newPurchaser" @click="newPurchaser">
                    <i class="el-icon-plus"></i>
                    <span>新建供应商</span>
                </div>
                <div slot="empty">
                    <div class="newPurchaser" @click="newPurchaser">
                        <i class="el-icon-plus"></i>
                        <span>新建供应商</span>
                    </div>
                </div>
            </el-select>
        </div> -->

        <!-- 跟进人列表 -->
        <div class="title" style="margin-top:20px;">跟进人</div>
        <el-table :data="followUps" border :header-cell-style="{'background-color':'#F3F3F3','color':'#3A3A3A'}">
            <el-table-column label="跟进人">
                <template slot-scope="scope">
                    <!-- 下拉框选择跟进人 -->
                    <el-select v-model="scope.row.ownerKey" size="small" filterable @change="(value)=>chooseFollow(value,scope.row)">
                        <el-option v-for="item in staffOption" :key="item.key" :label="item.name" :value="item.key"></el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column label="跟进时间" width="500px">
                <template slot-scope="scope">
                    <span style="color: #ff2d2d">*</span>
                    <el-date-picker v-model="scope.row.startTime" size="small" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
                    <span style="padding:0 5px;">—</span>
                    <el-date-picker v-model="scope.row.endTime" size="small" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
                </template>
            </el-table-column>
            <el-table-column label="佣金比率">
                <template slot-scope="scope">
                    <el-input-number v-model="scope.row.proportion" size="small" :min="0" :controls="false"></el-input-number>
                </template>
            </el-table-column>
            <el-table-column label="备注">
                <template slot-scope="scope">
                    <el-input v-model="scope.row.remarks" size="small"></el-input>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="60">
                <template slot-scope="scope">
                    <div class="handleDelBtn" @click="delFollowItem(scope.$index)">删除</div>
                </template>
            </el-table-column>
        </el-table>
        <div class="bottomAddBtn" @click="addFollowItem">
            <img src="@/assets/icon/plus.png" alt="" />新增跟进人
        </div>

        <div class="submitBtn">
            <el-button type="primary" @click="onSubmit" :loading="btnLoading" :disabled="isSubmit" size="small">
                立即创建
            </el-button>
        </div>
    
    </div>
</template>

<script>
import dayjs from "dayjs";
export default {
    props: {
        handleSelect: {
            type: Function,
            default: null,
        },
    },
    data() {
        return {
            theme: "新建订单",
            btnLoading: false,
            isSubmit: false,
            isAbleChoose: false,

            myCustomerOption: [],
            currencyOption: [],
            contactOption: [],
            productOption: [],
            staffOption: [],
            belongOptions: [],
            supplierOption: [],
            projectOption: [
                {key: 1, name: "关税"},
                {key: 2, name: "运费"},
                {key: 3, name: "保险"},
                {key: 4, name: "国内运费"},
            ],

            addForm: {
                "orderKey": "",
                "orderName": "",
                "currency": "CNY",
                "orderDate": dayjs().format('YYYY-MM-DD'),
                "customerKey": "",
                "customerName": "",
                "contactKey": "",
                "contactsName": "",
                "contactsCall": "",
                "contactsEmail": "",
                "customerAddress": "",
                "priceTerms": "",
                "paymentMethod": "",
                "deliveryDate": dayjs().add(30, 'day').format('YYYY-MM-DD'),
                "expectedReceiptDate": dayjs().add(30, 'day').format('YYYY-MM-DD'),
                "bankInfo": "",
                "remarks": "",
                "businessChanceKey": "",
                "productTotalVolume": "",
                "productTotalWeight": "",
                "portOfDestination": "",
                "portOfShipment": "",
                "typeOfShipping": "",
                "handlerKey": "",
                "performanceAttributionKey": "",
                "organizationKey": [],
                "quotationKey": "",
            },
            followUps: [],
            orderProducts: [],
            tradeExpenses: [],
            suppliers: [],
        };
    },
    computed:{
        //产品总数量
        productTotalNum(){
            let productTotalNum = 0;
            this.orderProducts.forEach(ele =>{
                if(ele.productKey){
                    productTotalNum += ele.num;
                }
            })
            return productTotalNum;
        },
        //产品总金额
        productTotalPrice(){
            let productTotalPrice = 0;
            this.orderProducts.forEach(ele =>{
                if(ele.productKey){
                    productTotalPrice += ele.cnyTotalPrice;
                }
            })
            return productTotalPrice.toFixed(2) * 1;
        },
        //贸易费用金额
        tradeExpensesTotalPrice(){
            let tradeExpensesTotalPrice = 0;
            this.tradeExpenses.forEach(ele =>{
                tradeExpensesTotalPrice += ele.cnyTotalPrice;
            })
            return tradeExpensesTotalPrice.toFixed(2) * 1;
        },
        //合计总金额
        allTotalPrice(){
            let allTotalPrice = this.productTotalPrice + this.tradeExpensesTotalPrice;
            return allTotalPrice.toFixed(2) * 1;
        },
    },
    methods: {
        goBack(){
            this.$router.push(`/customerManage/customerDetailsPrivate?customerKey=${this.$route.query.customerKey}&activeName=order`);
        },

        //获取产品列表
        getProductList(){
            this.$axios.post(`${this.$productUrl}/api/Product/List`,{
                pageIndex: 1,
                pageSize: 500
            }).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    this.productOption = data.datas;
                }
            });
        },
        addProductItem(){
            this.orderProducts.push({
                "productKey": "",
                "productNo": "",
                "productName": "",
                "specifications": "",
                "productDescription": "",
                "unitPrice": 0,
                "totalPrice": 0,
                "num": 1,
                "measureUnit": "",
                "otherExpenses": 0,
                "currency": "CNY",
                "exchangeRate": "1",
                "cnyTotalPrice": 0,
                "remarks": ""
            })
        },
        delProductItem(index){
            this.orderProducts.splice(index,1);
        },

        addFollowItem(){
            this.followUps.push({
                "key": "",
                "ownerName": "",
                "ownerKey": "",
                "startTime": dayjs().format('YYYY-MM-DD'),
                "endTime": dayjs().add(30, 'day').format('YYYY-MM-DD'),
                "proportion": 0,
                "remarks": ""
            })
        },
        delFollowItem(index){
            this.followUps.splice(index,1);
        },
        chooseFollow(value,row){
            let aItem = this.staffOption.filter(ele => ele.key == value)[0];
            row.ownerName = aItem.name;
        },

        addCostItem(){
            this.tradeExpenses.push({ 
                "project": "",
                "totalPrice": 0,
                "currency": "CNY",
                "exchangeRate": "1",
                "cnyTotalPrice": 0,
                "remarks": ""
            })
        },
        delCostItem(index){
            this.tradeExpenses.splice(index,1);
        },

        //选择产品
        selectProduct(value,row){
            let pItem = this.productOption.filter(ele => ele.key == value)[0];
            row.unitPrice = pItem.taxIncludingPrice;
            this.calcTotalPrice(row);
        },
        //通过单价和数量,计算总价
        calcTotalPrice(row){
            row.totalPrice = row.num * row.unitPrice;
            this.calcCNYPrice(row);
        },
        //选择货币类型
        chooseCurrency(value,row){
            let cItem = this.currencyOption.filter(ele => ele.currency == value)[0];
            row.exchangeRate = cItem.exchangeRate;
            this.calcCNYPrice(row);
        },
        //通过总价和汇率,计算人名币总价
        calcCNYPrice(row){
            row.cnyTotalPrice = row.totalPrice * row.exchangeRate * 1;
            row.cnyTotalPrice = row.cnyTotalPrice.toFixed(2) * 1;
        },
        //通过总价和人民币总价,计算汇率
        calcRate(row){
            row.exchangeRate = row.cnyTotalPrice / row.totalPrice;
            row.exchangeRate = row.exchangeRate.toFixed(8) * 1;
        },

        onSubmit(){
            let {handlerKey,performanceAttributionKey,organizationKey,orderName,orderDate,customerKey} = this.addForm;
            if(!handlerKey){ this.$message({ message: '请选择当前处理人', type: 'warning'}); return false; }
            if(!performanceAttributionKey){ this.$message({ message: '请选择业绩归属人', type: 'warning'}); return false; }
            if(organizationKey.length==0){ this.$message({ message: '请选择业绩归属部门', type: 'warning'}); return false; }
            if(!orderName){ this.$message({ message: '请填写订单名称', type: 'warning'}); return false; }
            if(!orderDate){ this.$message({ message: '请选择订单日期', type: 'warning'}); return false; }
            if(!customerKey){ this.$message({ message: '请选择客户', type: 'warning'}); return false;}
            if(this.productTotalNum == 0){
                this.$message({ message: '请添加订单产品', type: 'warning'}); 
                return false;
            }
            if(this.productTotalPrice == 0){
                this.$message({ message: '订单金额不能为0', type: 'warning'}); 
                return false;
            }
            this.orderProducts = this.orderProducts.filter(ele => ele.productKey !== "");
            this.followUps = this.followUps.filter(ele => ele.ownerName !== "");
            this.tradeExpenses = this.tradeExpenses.filter(ele => ele.project !== "");

            // console.log(this.addForm);
            // console.log(this.followUps);
            // console.log(this.orderProducts);

            this.btnLoading = true;
            this.$axios.post(`${this.$orderUrl}/api/Order/V2/SaveOrder`,{
                order: this.addForm,
                followUps: this.followUps,
                orderProducts: this.orderProducts,
                tradeExpenses: this.tradeExpenses,
                suppliers: this.suppliers,
            }).then((res) => {
                const { isSuccess, data } = res;
                this.btnLoading = false;
                if (isSuccess) {
                    let businessKey = this.$route.query.businessKey;
                    if(businessKey){
                        this.relationSj(data,businessKey); //关联商机
                    }
                    this.$message({ message: '提交成功', type: 'success'});
                    this.isSubmit = true;
                    this.handleSelect(`/orderManage/orderDetails?orderKey=${data}`);
                    this.$router.push(`/orderManage/orderDetails?orderKey=${data}`);
                }
            });
        },

        //关联商机
        relationSj(dataKey,businessKey){
            this.$axios.post(`${this.$sjUrl}/BusinessChance/AddBusinessChanceRelation`,{
                dataKey,businessKey,type: 0,
            }).then((res) => {});
        },

        //获取我的客户列表
        getMyCustomer(){
            this.$axios.get(`${this.$customerUrl}/Company/GetCustomerBox`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.myCustomerOption = data;
                }
            });
        },
        //获取币种配置
        getCurrencyConfig(){
            this.$axios.get(`${this.$customerUrl}/v1/Config/GetCurrencyConfig`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.currencyOption = data;
                }
            })
        },
        chooseCustomer(value){
            this.$axios.get(`${this.$customerUrl}/Company/GetContacters?customerKey=${value}`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.contactOption = data;
                    this.addForm.customerName = "";
                    this.addForm.contactKey = "";
                    this.addForm.contactsName = "";
                    this.addForm.contactsCall = "";
                    this.addForm.contactsEmail = "";
                }
            });
        },
        chooseContact(value){
            let chooseItem =  this.contactOption.filter(ele => ele.key == value)[0];
            this.addForm.contactKey = value;
            this.addForm.contactsName = chooseItem.nickname;
            this.addForm.contactsCall = chooseItem.call;
            this.addForm.contactsEmail = chooseItem.email;
        },
       
        //获取所有成员集合
        getStaffBox(){
            this.$axios.get(`${this.$personnelUrl}/Organization/FindStaffBox`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.staffOption = data;
                }
            })
        },
        //获取组织结构树下拉框
        getOrganizationDrop(){
            this.$axios.get(`${this.$personnelUrl}/Organization/FindOrganizationDrop?all=true`).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    for (const item of data) {
                        this.disposeTree(item)
                    }
                    this.belongOptions = data;
                }
            });
        },
        disposeTree(node) {
            if (node.children.length) {
                for (const item of node.children) {
                    this.disposeTree(item)
                }
            } else {
                delete node.children
            }
        },
        belongChange(value){
            this.addForm.organizationKey = value
        },

        //获取供应商
        getPurchaser(){
            this.$axios.post(`${this.$orderUrl}/Purchase/GetPurchaser`,{
                manufacturerName: "",
                pageIndex: 1,
                pageSize: 100,
            }).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    this.supplierOption = data.datas;
                }
            });
        },
        //新建供应商
        newPurchaser(){
            let routeData = this.$router.resolve({
                path: "/purchase/newPurchaser",
            });
            window.open(routeData.href, "_blank");
        },

        //获取订单详情编辑操作
        getOrderDetail(orderKey){
            this.$axios.get(`${this.$orderUrl}/api/Order/GetOrderDetail?key=${orderKey}`).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    this.addForm = data.order;
                    this.followUps = data.followUps;
                    this.orderProducts = data.orderProducts;
                    this.tradeExpenses = data.tradeExpenses;
                    this.suppliers = data.suppliers;
                    if(this.addForm.customerKey){
                        this.$axios.get(`${this.$customerUrl}/Company/GetContacters?customerKey=${this.addForm.customerKey}`).then((res) => {
                            const { isSuccess, data } = res;
                            if (isSuccess) {
                                this.contactOption = data;
                            }
                        });
                    }
                    //重新生成订单
                    let optype = this.$route.query.optype;
                    if(optype == 'regen'){
                        this.theme = "重新生成订单";
                        document.title = "重新生成订单";
                        this.addForm.orderKey = "";
                    }
                    //复制订单
                    if(optype == 'copy'){
                        this.theme = "复制订单";
                        document.title = "复制订单";
                        this.addForm.orderKey = "";
                    }
                }
            });
        },
        //报价单生成订单
        createOrder(quotationKey){
            this.$axios.get(`${this.$sjUrl}/Quote/CreateOrder?key=${quotationKey}`).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    this.addForm = data.order;
                    this.followUps = data.followUps;
                    this.orderProducts = data.orderProducts;
                    if(this.addForm.customerKey){
                        this.$axios.get(`${this.$customerUrl}/Company/GetContacters?customerKey=${this.addForm.customerKey}`).then((res) => {
                            const { isSuccess, data } = res;
                            if (isSuccess) {
                                this.contactOption = data;
                            }
                        });
                    }
                }
            });
        },

        //获取费用配置
        getProject(){
            this.$axios.get(`${this.$customerUrl}/v1/Config/GetTradeExpensesProject`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.projectOption = data;
                }
            });
        },
        toConfig(){
            let routeData = this.$router.resolve({
                path: "/settingIndex",
                query: { type: "system" },
            });
            window.open(routeData.href, "_blank");
        },

    },
    mounted() {
        //获取我的客户列表
        this.getMyCustomer();
        //获取币种配置
        this.getCurrencyConfig();
        //获取产品列表
        this.getProductList();
        //获取供应商
        this.getPurchaser();
        //获取所有成员集合
        this.getStaffBox();
        //获取组织结构树下拉框
        this.getOrganizationDrop();
        //获取费用配置
        this.getProject();

        //订单产品为必增加项，默认展示一排展品
        this.addProductItem();

        //获取订单详情编辑操作
        let orderKey = this.$route.query.orderKey;
        if(orderKey){
            this.theme = "编辑订单";
            document.title = "编辑订单";
            this.getOrderDetail(orderKey);
        }
        //报价单生成订单
        let quotationKey = this.$route.query.quotationKey;
        if(quotationKey){
            this.theme = "生成订单";
            document.title = "生成订单";
            this.createOrder(quotationKey);
            this.addForm.quotationKey = quotationKey;
        }

        //初始化客户信息
        let customerKey = this.$route.query.customerKey;
        if(customerKey){
            this.addForm.customerKey = customerKey;
            this.chooseCustomer(customerKey);
            this.isAbleChoose = true;   //携带了客户信息,不能再选择客户
        }
                
    },
};
</script>

<style lang="scss" scoped>
.addOrder{
    background: #FFFFFF;
    padding: 20px;
    .header{
        height: 32px;
        line-height: 32px;
        font-size: 20px;
        font-weight: bold;
        color: #000;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
    }
    .title{
        height: 20px;
        line-height: 20px;
        font-size: 16px;
        font-weight: bold;
        color: #3A3A3A;
        margin-bottom: 10px;
        border-left: 3px solid var(--themeColor);
        padding-left: 5px;
    }
    .w100{
        width: 100%;
        height: 32px;
    }
}
.bottomAddBtn{
    cursor: pointer;
    height: 48px;
    line-height: 48px;
    text-align: center;
    font-size: 16px;
    color: #7C90A5;
    border: 1px solid #EBEEF5;
    border-top: none;
    img{
        vertical-align: middle;
        padding-right: 10px;
    }
}
.handleDelBtn{
    cursor: pointer;
    display: inline-block;
    text-align: center;
    width: 40px;
    height: 20px;
    line-height: 18px;
    font-size: 12px;
    background: rgba(255, 86, 86, 0.26);
    border: 1px solid rgba(255, 86, 86, 0.3);
    border-radius: 2px;
    color: #FF5656;
}
.submitBtn{
    display: flex;
    margin-top: 20px;
    justify-content: flex-end;
}
.summation{
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    color: #3a3a3a;
    display: flex;
    justify-content: space-between; 
}
.tradeCost{
    border: 1px solid #EBEEF5;
    color: #5E6D82;
    font-size: 14px;
    .itemCost{
        display: flex;
        height: 32px;
        line-height: 32px;
        padding: 10px;
    }
    .itemCost:nth-child(2n+1){
        background: #F8F7F7;
    }
    .itemCost:nth-child(2n){
        background: #E5E9F0;
    }
    .handleDelBtn{
        margin-top: 6px;
    }
}
.newPurchaser{
    cursor: pointer;
    text-align: center;
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    color: #6a6a6a;
    &:hover{
        background-color: #F5F7FA;
    }
}
.toConfig{
    cursor: pointer;
    text-align: center;
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    color: #6a6a6a;
    &:hover{
        background-color: #F5F7FA;
    }
}
</style>