<template>
    <div class="Billoflading" v-loading="infoLoading">
        <div class="title"> 
            <span>船运数据</span>
            <span class="ie">{{ie == 'i' ? '进口' : '出口'}}</span>
            <span>提单明细</span>
        </div>

        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th width="25%">数据源</th>
                        <td width="25%">{{dataCountryCn}}</td>
                        <th width="25%">日期</th>
                        <td width="25%">{{billInfo.BookingDate}}</td>
                    </tr>
                    <tr>
                        <th>船东提单</th>
                        <td>{{billInfo.BookingNo}}</td>
                        <th>货运提单</th>
                        <td>{{billInfo.BLNumber}}</td>
                    </tr>
                    <tr>
                        <th>通知人</th>
                        <td>{{billInfo.NotifyName}}</td>
                        <th>通知人地址</th>
                        <td>{{billInfo.NotifyAddress}}</td>
                    </tr>
                    <tr>
                        <th>船名</th>
                        <td>{{billInfo.VesselName}}</td>
                        <th>支线连接船</th>
                        <td>{{billInfo.TrunkVVD}}</td>
                    </tr>
                    <tr>
                        <th>预计船舶靠泊时间</th>
                        <td>{{billInfo.EtbDate}}</td>
                        <th>船舶的航行日期</th>
                        <td>{{billInfo.SailingDate}}</td>
                    </tr>
                    <tr>
                        <th>联系电话</th>
                        <td>{{billInfo.BookingContactPhoneNumber}}</td>
                        <th>联系邮箱</th>
                        <td>{{billInfo.BookingContactEmail}}</td>
                    </tr>
                </tbody>
            </table>
            <table style="margin-top:20px;">
                <tbody>
                    <tr>
                        <th class="bg" width="50%">托运人</th>
                        <th class="bg" width="50%">收货人</th>
                    </tr>
                    <tr>
                        <td class="companyName" @click="toCompanyDetail(billInfo.Shipper,'exporter')">{{billInfo.Shipper}}</td>
                        <td class="companyName" @click="toCompanyDetail(billInfo.Consignee,'importer')">{{billInfo.Consignee}}</td>
                    </tr>
                    <tr>
                        <td>{{billInfo.ShipperAddress}}</td>
                        <td>{{billInfo.ConsigneeAddress}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">产品信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">HS</th>
                        <td colspan="3">{{billInfo.CmdtCode}}</td>
                    </tr>
                    <tr>
                        <th width="25%">海关产品描述</th>
                        <td colspan="3">{{billInfo.CustomsDescription}}</td>
                    </tr>
                    <tr>
                        <th width="25%">产品描述</th>
                        <td colspan="3">{{billInfo.CMDT}}</td>
                    </tr>
                    <tr>
                        <th width="25%">数量</th>
                        <td width="25%">{{billInfo.PKG}} {{billInfo.PKGUnit}}</td>
                        <th width="25%">重量</th>
                        <td width="25%">{{billInfo.Weight}} {{billInfo.WeightUnit}}</td>
                    </tr>
                    <tr>
                        <th width="25%">体积</th>
                        <td width="25%">{{billInfo.MeaCbm}}</td>
                        <th width="25%">商品REP代码</th>
                        <td width="25%">{{billInfo.RepCmdt}}</td>
                    </tr>
                    <tr>
                        <th width="25%">20英尺集装箱</th>
                        <td width="25%">{{billInfo.TEU}}</td>
                        <th width="25%">40英尺集装箱</th>
                        <td width="25%">{{billInfo.FEU}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">装卸货港信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">装货港</th>
                        <td width="25%">{{billInfo.BookingPOLNameStd}}</td>
                        <th width="25%">卸货港</th>
                        <td width="25%">{{billInfo.BookingPODNameStd}}</td>
                    </tr>
                    <tr>
                        <th width="25%">装货港国家</th>
                        <td width="25%">{{billInfo.BookingPOLCountryStd}}</td>
                        <th width="25%">卸货港国家</th>
                        <td width="25%">{{billInfo.BookingPODCountryStd}}</td>
                    </tr>
                    <tr>
                        <th width="25%">装货港船运名称</th>
                        <td width="25%">{{billInfo.TrunkPODNameStd}}</td>
                        <th width="25%">卸货港船运名称</th>
                        <td width="25%">{{billInfo.TrunkPOLNameStd}}</td>
                    </tr>
                    <tr>
                        <th width="25%">装货港船运国家</th>
                        <td width="25%">{{billInfo.TrunkPODCountryStd}}</td>
                        <th width="25%">卸货港船运国家</th>
                        <td width="25%">{{billInfo.TrunkPOLCountryStd}}</td>
                    </tr>
                    <tr>
                        <th width="25%">最终目的港名称</th>
                        <td width="25%">{{billInfo.DELNameStd}}</td>
                        <th width="25%">收货名称</th>
                        <td width="25%">{{billInfo.PorNameStd}}</td>
                    </tr>
                    <tr>
                        <th width="25%">最终目的港国家</th>
                        <td width="25%">{{billInfo.DELCountryStd}}</td>
                        <th width="25%">收货国家</th>
                        <td width="25%">{{billInfo.PorCountryStd}}</td>
                    </tr>
                    <tr>
                        <th width="25%">清空取货日期</th>
                        <td width="25%">{{billInfo.MtyPupDate}}</td>
                        <th width="25%">空的取货地点</th>
                        <td width="25%">{{billInfo.MtyPupCY}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

    </div>
</template>

<script>
export default {
    
    data() {
        return {
            tradeKey: "",
            dataCountryCn: "",
            threeEnCountryCode: "",
            ie: "i",
            infoLoading: false,
            billInfo: {},
        };
    },
    methods: {
        getBillInfo() {
            this.infoLoading = true;
            this.$axios.post(`${this.$searchUrl}/Trade/Bill/Details?tradeKey=${this.tradeKey}`).then((res) => {
                const { isSuccess, data } = res;
                this.infoLoading = false;
                if (isSuccess) {
                    // console.log(data);
                    if(data){
                        // console.log(typeof data);
                        if(typeof data == "string"){
                            this.billInfo = JSON.parse(data);
                        }else{
                            this.billInfo = data;
                        }
                        this.billInfo.BookingDate = this.billInfo.BookingDate.slice(0,10);
                    }
                }
            });
        },

        //跳转公司详情
        toCompanyDetail(companyName,isImpExp){
            companyName = companyName.replace(/<mark>/g,'').replace(/<\/mark>/g,'');//将mark标签替换掉空字符
            companyName = escape(companyName);
            let routeData = this.$router.resolve({
                path: "/AkeySearch/CompanyDetails",
                query: { 
                    companyName: companyName,
                    isImpExp: isImpExp,
                    ie: this.ie,
                    country: this.dataCountryCn,
                    countryCode: this.threeEnCountryCode,
                },
            });
            window.open(routeData.href, "_blank");
        },
    },
    mounted() {
        this.tradeKey = this.$route.query.tradeKey;
        this.dataCountryCn = this.$route.query.dataCountryCn;
        this.threeEnCountryCode = this.$route.query.threeEnCountryCode;
        this.ie = this.$route.query.ie;
        this.getBillInfo();
        document.title = `船运数据${this.ie == 'i' ? '进口' : '出口'}提单明细`
    },
};
</script>

<style lang="scss" scoped>
@import '@/scss/common.scss';
.Billoflading {
    background-color: #f5f5f5;
    padding: 0 50px 20px;
    min-width: 1000px;
    .title {
        font-size: 18px;
        color: #333;
        height: 50px;
        line-height: 50px;
        .ie {
            display: inline-block;
            height: 30px;
            line-height: 30px;
            padding: 0 5px;
            margin: 0 5px;
            background-color: var(--themeColor);
            color: #fff;
        }
    }
}
.partItem {
    margin-bottom: 20px;
    /deep/.el-card__header {
        padding: 10px 20px;
        .headTitle {
            display: inline-block;
            padding-left: 10px;
            font-size: 14px;
            border-left: 2px solid var(--themeColor);
            font-weight: bold;
        }
    }
}
table {
    width: 100%;
    border-collapse: collapse;
    tr {
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;
        th,
        td {
            font-size: 12px;
            color: #666;
            height: 40px;
            border: 1px solid #eaeaea;
            text-align: center;
        }
        th {
            background-color: #f5f7fa;
            font-weight: 700;
        }
        .bg {
            background-color: var(--themeColor);
            color: #fff;
            font-weight: 700;
            opacity: 0.8;
        }
    }
}
.companyName{
    cursor: pointer;
    color: var(--themeColor);
}
</style>