<template>
<div>
    <div v-if="ie=='i'">
        <!-- 巴拿马(PAN)进口提单明细 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th width="25%">声明序列号</th>
                        <td width="25%">{{billInfo.DeclarationNum}}</td>
                        <th width="25%">进口商ID</th>
                        <td width="25%">{{billInfo.ImporterId}}</td>
                    </tr>
                    <tr>
                        <th>6位HS编码</th>
                        <td>{{billInfo.HsCode6}}</td>
                        <th>6位HS编码描述</th>
                        <td>{{billInfo.Product6}}</td>
                    </tr>
                    <tr>
                        <th>数量单位</th>
                        <td>{{billInfo.MeasureUnit}}</td>
                        <th>净重</th>
                        <td>{{billInfo.NetWeight}}</td>
                    </tr>
                    <tr>
                        <th>FOB总价（美元）</th>
                        <td>{{billInfo.Value}}</td>
                        <th>FOB单价（美元）</th>
                        <td>{{billInfo.FobUnit}}</td>
                    </tr>
                    <tr>
                        <th>CIF总价（美元）</th>
                        <td colspan="3">{{billInfo.CifValue}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">运输信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">运输方式</th>
                        <td width="25%">{{billInfo.TransportWay}}</td>
                        <th width="25%">来源国</th>
                        <td width="25%">{{billInfo.Country}}</td>
                    </tr>
                    <tr>
                        <th>地区</th>
                        <td>{{billInfo.CustomsZone}}</td>
                        <th>港口</th>
                        <td>{{billInfo.CustomsName}}</td>
                    </tr>
                    <tr>
                        <th>仓库代码</th>
                        <td>{{billInfo.WarehouseCode}}</td>
                        <th>运费</th>
                        <td>{{billInfo.Freight}}</td>
                    </tr>
                    <tr>
                        <th>保险</th>
                        <td>{{billInfo.Insurance}}</td>
                        <th>包装数量</th>
                        <td>{{billInfo.Packages}}</td>
                    </tr>
                    <tr>
                        <th>数字控制</th>
                        <td colspan="3">{{billInfo.ControlDigit}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">计税信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">进口税</th>
                        <td width="25%">{{billInfo.ImportTax}}</td>
                        <th width="25%">增值税</th>
                        <td width="25%">{{billInfo.ItbmsTax}}</td>
                    </tr>
                    <tr>
                        <th>特别消费税</th>
                        <td>{{billInfo.IscTax}}</td>
                        <th>附加税</th>
                        <td>{{billInfo.IcddpTax}}</td>
                    </tr>
                    <tr>
                        <th>计算税额</th>
                        <td>{{billInfo.CalculatedTax}}</td>
                        <th>总税收</th>
                        <td>{{billInfo.TotalTax}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
    <div v-if="ie=='e'">
        <!-- 巴拿马(PAN)出口提单明细 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th width="25%">声明序列号</th>
                        <td width="25%">{{billInfo.DeclarationNum}}</td>
                        <th width="25%">出口商ID</th>
                        <td width="25%">{{billInfo.ExporterId}}</td>
                    </tr>
                    <tr>
                        <th>6位HS编码</th>
                        <td>{{billInfo.HsCode6}}</td>
                        <th>6位HS编码描述</th>
                        <td>{{billInfo.Product6}}</td>
                    </tr>
                    <tr>
                        <th>数量单位</th>
                        <td>{{billInfo.MeasureUnit}}</td>
                        <th>净重</th>
                        <td>{{billInfo.NetWeight}}</td>
                    </tr>
                    <tr>
                        <th>FOB总价（美元）</th>
                        <td>{{billInfo.Value}}</td>
                        <th>FOB单价（美元）</th>
                        <td>{{billInfo.FobUnit}}</td>
                    </tr>
                    <tr>
                        <th>CIF总价（美元）</th>
                        <td colspan="3">{{billInfo.CifValue}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">运输信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">运输方式</th>
                        <td width="25%">{{billInfo.TransportWay}}</td>
                        <th width="25%">目的国</th>
                        <td width="25%">{{billInfo.Country}}</td>
                    </tr>
                    <tr>
                        <th>地区</th>
                        <td>{{billInfo.CustomsZone}}</td>
                        <th>港口</th>
                        <td>{{billInfo.CustomsName}}</td>
                    </tr>
                    <tr>
                        <th>仓库代码</th>
                        <td>{{billInfo.WarehouseCode}}</td>
                        <th>运费</th>
                        <td>{{billInfo.Freight}}</td>
                    </tr>
                    <tr>
                        <th>保险</th>
                        <td>{{billInfo.Insurance}}</td>
                        <th>包装数量</th>
                        <td>{{billInfo.Packages}}</td>
                    </tr>
                    <tr>
                        <th>数字控制</th>
                        <td colspan="3">{{billInfo.ControlDigit}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">计税信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">特别消费税</th>
                        <td width="25%">{{billInfo.IscTax}}</td>
                        <th width="25%">计算税额</th>
                        <td width="25%">{{billInfo.CalculatedTax}}</td>
                    </tr>
                    <tr>
                        <th>总税收</th>
                        <td colspan="3">{{billInfo.TotalTax}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
</div>
</template>

<script>
export default {
    props:["billInfo","ie"],
    data() {
        return {

        };
    },
    methods: {
        
    },
    mounted() {

    },
};
</script>

<style lang="scss" scoped>
.partItem {
    margin-bottom: 20px;
    /deep/.el-card__header {
        padding: 10px 20px;
        .headTitle {
            display: inline-block;
            padding-left: 10px;
            font-size: 14px;
            border-left: 2px solid var(--themeColor);
            font-weight: bold;
        }
    }
}
table {
    width: 100%;
    border-collapse: collapse;
    tr {
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;
        th,
        td {
            font-size: 12px;
            color: #666;
            height: 40px;
            border: 1px solid #eaeaea;
            text-align: center;
        }
        th {
            background-color: #f5f7fa;
            font-weight: 700;
        }
        .bg {
            background-color: var(--themeColor);
            color: #fff;
            font-weight: 700;
            opacity: 0.8;
        }
    }
}
</style>