<template>
    <el-dialog :visible.sync="completeVisible" width="1000px" top="50px" @close="$emit('closeDialog')">
        <div slot="title">
            <span style="margin-right:20px;font-weight:bold;">补全公司信息</span>
        </div>
        <div class="searchBox">
            <el-input v-model="searchCountry" placeholder="国家" size="small" clearable style="width:150px;"></el-input>
            <el-input v-model="searchValue" placeholder="公司名称" size="small" clearable></el-input>
            <el-button size="small" @click="onSearch">搜 索</el-button>
        </div>
        <div class="searchContent" v-loading="tableLoading">
            <el-tabs v-model="activeName" @tab-click="onSearch">
                <el-tab-pane label="企芯企业库" name="first">
                    <el-table :data="enterpriseList" :header-cell-style="{'background-color':'#E9E9E9','color':'#3A3A3A'}">
                        <el-table-column label="线索信息">
                            <template slot="header">
                                <span>共有</span>
                                <span style="font-size: 18px;color: var(--themeColor);padding: 0 5px;">
                                    {{enterpriseTotal >= 50 ? 50 : enterpriseTotal}}
                                </span>
                                <span>家企业</span>
                                <span v-if="enterpriseTotal >= 50" style="padding-left:10px;">(最多搜索50条结果)</span>
                            </template>
                            <template slot-scope="scope">
                                <div class="clueInfo">
                                    <div>
                                        <div style="margin-bottom: 8px;">
                                            <div class="companyName" @click="toDetailOne(scope.row)">{{scope.row.companyName}}</div>
                                            <div class="country">
                                                <img :src="scope.row.nationalFlag" style="margin-right:5px;height:14px" />
                                                <span>{{scope.row.countryCn}}</span>
                                            </div>
                                            <el-tag size="small" type="success">来源：{{ scope.row.dataSource }}</el-tag>
                                        </div>
                                        <div style="display: flex;">
                                            <div class="field">
                                                <span class="color99">年收入：</span>
                                                <span class="color3a">{{scope.row.reverueAmount || "-"}}</span>
                                            </div>
                                            <div class="field">
                                                <span class="color99">员工数：</span>
                                                <span class="color3a">{{scope.row.employeesNumber || "-"}}</span>
                                            </div>
                                            <div class="field">
                                                <span class="color99">联系电话：</span>
                                                <span class="color3a">{{scope.row.phone || "-"}}</span>
                                            </div>
                                        </div>
                                        <div style="display: flex;">
                                            <div class="field">
                                                <span class="color99">企业官网：</span>
                                                <span class="color3a">
                                                    {{scope.row.website || "-"}}
                                                </span>
                                            </div>
                                            <div class="field">
                                                <span class="color99">法人：</span>
                                                <span class="color3a">{{scope.row.legalPerson || "-"}}</span>
                                            </div>
                                        </div>
                                        <div class="field">
                                            <span class="color99">详细地址：</span>
                                            <span class="color3a">{{scope.row.address || "-"}}</span>
                                        </div>
                                        <div class="field">
                                            <span class="color99">经营范围：</span>
                                            <span class="color3a">{{scope.row.businessline || "-"}}</span>
                                        </div>
                                    </div>
                                    <el-button class="compBtn" size="small" plain type="primary" @click="onComplete(scope.row)" :loading="btnLoading">补全信息</el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!-- <el-pagination v-show="enterpriseTotal != 0" background layout="total,prev,pager,next" :total="enterpriseTotal" style="float:right;margin-top:15px;" :current-page.sync="enterprisePage" @current-change="getLsmapsCompanys"></el-pagination> -->
                </el-tab-pane>
                <el-tab-pane label="全球企业风险评估(已购买)" name="second">
                    <el-table :data="purchaseList" :header-cell-style="{'background-color':'#E9E9E9','color':'#3A3A3A'}">
                        <el-table-column label="线索信息">
                            <template slot="header">
                                共有<span style="font-size: 18px;color: var(--themeColor);padding: 0 5px;">{{purchaseTotal}}</span>家企业
                            </template>
                            <template slot-scope="scope">
                                <div class="clueInfo">
                                    <div>
                                        <div style="margin-bottom: 8px;">
                                            <div class="companyName" @click="toDetailTwo(scope.row)">{{scope.row.businessName}}</div>
                                            <div class="country">
                                                <img :src="scope.row.nationalFlag" style="margin-right:5px;height:14px" />
                                                <span>{{scope.row.countryCn}}</span>
                                            </div>
                                        </div>
                                        <div style="display: flex;">
                                            <div class="field">
                                                <span class="color99">营销额：</span>
                                                <span class="color3a">{{scope.row.salesVolume || "-"}}</span>
                                            </div>
                                            <div class="field">
                                                <span class="color99">员工数：</span>
                                                <span class="color3a">{{scope.row.employeesTotal || "-"}}</span>
                                            </div>
                                            <div class="field">
                                                <span class="color99">联系电话：</span>
                                                <span class="color3a">{{scope.row.phone || "-"}}</span>
                                            </div>
                                        </div>
                                        <div style="display: flex;">
                                            <div class="field">
                                                <span class="color99">企业官网：</span>
                                                <span class="color3a">
                                                    {{scope.row.website || "-"}}
                                                </span>
                                            </div>
                                            <div class="field">
                                                <span class="color99">企业邮箱：</span>
                                                <span class="color3a">{{scope.row.email || "-"}}</span>
                                            </div>
                                            <div class="field">
                                                <span class="color99">ceo姓名：</span>
                                                <span class="color3a">{{scope.row.admin || "-"}}</span>
                                            </div>
                                        </div>
                                        <div class="field">
                                            <span class="color99">关联公司：</span>
                                            <span class="color3a">{{scope.row.linkage || "-"}}</span>
                                        </div>
                                        <div class="field">
                                            <span class="color99">行业：</span>
                                            <span class="color3a" v-for="ele in scope.row.industryCodes" :key="ele.code">
                                                {{ele.describeCn}}({{ele.code}})
                                            </span>
                                        </div>
                                    </div>
                                    <el-button class="compBtn" size="small" plain type="primary" @click="onComplete(scope.row)" :loading="btnLoading">补全信息</el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination v-show="purchaseTotal != 0" background layout="total,prev,pager,next" :total="purchaseTotal" style="float:right;margin-top:15px;" :current-page.sync="purchasePage" @current-change="getInfobelPurchase"></el-pagination>
                </el-tab-pane>
            </el-tabs>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="$emit('closeDialog')">关 闭</el-button>
        </span>

        <el-dialog title="公司详情" :visible.sync="oneVisible" width="1000px" top="50px" append-to-body>
            <div style="height:calc(100vh - 200px);overflow: auto;">
                <el-descriptions :column="2" border :labelStyle="{width:'15%'}" :contentStyle="{width:'35%'}">
                    <el-descriptions-item label="公司名称">{{oneObj.companyName}}</el-descriptions-item>
                    <el-descriptions-item label="国家">
                        <img :src="oneObj.nationalFlag" style="margin-right:5px;height:14px" />
                        <span>{{oneObj.countryCn}}</span>
                    </el-descriptions-item>
                    <el-descriptions-item label="详细地址" :span="2">{{oneObj.address || "--"}}</el-descriptions-item>
                    <el-descriptions-item label="经度">{{oneObj.longitude || "--"}}</el-descriptions-item>
                    <el-descriptions-item label="纬度">{{oneObj.latitude || "--"}}</el-descriptions-item>
                    <el-descriptions-item label="洲">{{oneObj.continent || "--"}}</el-descriptions-item>
                    <el-descriptions-item label="城市">{{oneObj.city || "--"}}</el-descriptions-item>
                    <el-descriptions-item label="公司类型">{{oneObj.companyType || "--"}}</el-descriptions-item>
                    <el-descriptions-item label="状态">{{oneObj.status || "--"}}</el-descriptions-item>
                    <el-descriptions-item label="注册时间">{{oneObj.registrationDate || "--"}}</el-descriptions-item>
                    <el-descriptions-item label="员工人数">{{oneObj.employeesNumber || "--"}}</el-descriptions-item>
                    <el-descriptions-item label="邮政编码">{{oneObj.postCode || "--"}}</el-descriptions-item>
                    <el-descriptions-item label="传真">{{oneObj.faxNumber || "--"}}</el-descriptions-item>
                    <el-descriptions-item label="网址" :span="2">{{oneObj.website || "--"}}</el-descriptions-item>
                    <el-descriptions-item label="电话" :span="2">{{oneObj.phone || "--"}}</el-descriptions-item>
                    <el-descriptions-item label="年收入(单位:千美元)">{{oneObj.reverueAmount || "--" }}</el-descriptions-item>
                    <el-descriptions-item label="US SIS">{{oneObj.ussicCoreCode || "--"}}</el-descriptions-item>
                    <el-descriptions-item label="国内主要国家">{{oneObj.majorCountryIn || "--"}}</el-descriptions-item>
                    <el-descriptions-item label="国外主要国家或地区">{{oneObj.majorCountryOut || "--"}}</el-descriptions-item>
                    <el-descriptions-item label="经营范围" :span="2">{{oneObj.businessline || "--"}}</el-descriptions-item>
                    <el-descriptions-item label="历史沿革" :span="2">{{oneObj.history || "--"}}</el-descriptions-item>
                    <el-descriptions-item label="主营业务说明" :span="2">{{oneObj.tradeEn || "--"}}</el-descriptions-item>
                    <el-descriptions-item label="产品和服务" :span="2">{{oneObj.productService || "--"}}</el-descriptions-item>
                    <el-descriptions-item label="Nuts" :span="2">{{oneObj.nuts1 || "--"}} {{oneObj.nuts2 || "--"}} {{oneObj.nuts3 || "--"}}</el-descriptions-item>
                    <el-descriptions-item label="简介" :span="2">{{oneObj.companyProfile || "--"}}</el-descriptions-item>
                </el-descriptions>
                <el-tabs>
                    <!-- 获取高管/获取注册号/获取股权信息/获取行业分类 -->
                    <el-tab-pane label="高管">
                        <el-table :data="oneInfoList">
                            <el-table-column type="index" label="序号" width="50px" align="center"></el-table-column>
                            <el-table-column label="姓名">
                                <template slot-scope="scope">
                                    <div>{{scope.row.fullname}}</div>  
                                </template>
                            </el-table-column>
                            <el-table-column label="职位">
                                <template slot-scope="scope">
                                    <div>{{scope.row.originalpost2}}</div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="注册号">
                        <el-table :data="twoInfoList">
                            <el-table-column type="index" label="序号" width="50px" align="center"></el-table-column>
                            <el-table-column label="natId">
                                <template slot-scope="scope">
                                    <div>{{scope.row.natId}}</div>  
                                </template>
                            </el-table-column>
                            <el-table-column label="natLabel">
                                <template slot-scope="scope">
                                    <div>{{scope.row.natLabel}}</div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="股权信息">
                        <el-table :data="threeInfoList">
                            <el-table-column type="index" label="序号" width="50px" align="center"></el-table-column>
                            <el-table-column label="公司股东">
                                <template slot-scope="scope">
                                    <div>{{scope.row.shareHoldersName}}</div>  
                                </template>
                            </el-table-column>
                            <el-table-column label="占股比例">
                                <template slot-scope="scope">
                                    <div>{{scope.row.shareHoldersDirect + "%"}}</div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="行业分类">
                        <el-table :data="fourInfoList">
                            <el-table-column type="index" label="序号" width="50px" align="center"></el-table-column>
                            <el-table-column label="行业类型">
                                <template slot-scope="scope">
                                    <div>{{scope.row.type}}</div>  
                                </template>
                            </el-table-column>
                            <el-table-column label="行业代码">
                                <template slot-scope="scope">
                                    <div>{{scope.row.Code}}</div>  
                                </template>
                            </el-table-column>
                            <el-table-column label="行业描述">
                                <template slot-scope="scope">
                                    <div>{{scope.row.Description}}</div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                </el-tabs>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="oneVisible = false;">关 闭</el-button>
                <el-button size="small" type="primary" @click="onComplete(oneObj)" :loading="btnLoading">补全信息</el-button>
            </span>
        </el-dialog>

    </el-dialog>
</template>

<script>
import IndustrySic from "@/assets/json/IndustrySic.json"
export default {
    props: ["completeVisible","searchCompany"],
    data() {
        return {
            btnLoading: false,
            searchValue: "",
            searchCountry: "",
            activeName: "first",
            tableLoading: false,
            //企芯企业库
            enterpriseList: [],
            enterpriseTotal: 0,
            enterprisePage: 1,
            oneVisible: false,
            oneObj: {},
            //全球企业风险评估
            purchaseList: [],
            purchaseTotal: 0,
            purchasePage: 1,
            //获取高管/获取注册号/获取股权信息/获取行业分类
            oneInfoList: [],
            twoInfoList: [],
            threeInfoList: [],
            fourInfoList: [],
            
        };
    },
    methods: {
        onSearch(){
            if(this.activeName == 'first'){
                this.enterprisePage = 1;
                this.getLsmapsCompanys();
            }else if(this.activeName == 'second'){
                this.purchasePage = 1;
                this.getInfobelPurchase();
            }
        },

        getLsmapsCompanys(){
            if(!this.searchValue){return false;}
            this.tableLoading = true;
            this.$axios.post(`${this.$clueUrl}/MyClue/v2/GetLsmapsCompanys`, {
                companyName: this.searchValue,
                country: this.searchCountry,
                pageIndex: this.enterprisePage,
                pageSize: 50,
            }).then((res) => {
                const { isSuccess, data } = res;
                this.tableLoading = false;
                if (isSuccess) {
                    this.enterpriseList = data.datas.map(item => ({
                        ...item,
                        nationalFlag: window.getFlagUrlByCountry ? window.getFlagUrlByCountry(item.countryCn) : '',
                    }))
                    this.enterpriseTotal = data.totalItems;
                }
            });
        },
        toDetailOne(item){
            this.oneVisible = true;
            this.oneObj = item;
            //(US SIS)匹配描述
            if(this.oneObj.ussicCoreCode){
                const aItem = IndustrySic.find(item => item.Code == this.oneObj.ussicCoreCode);
                if(aItem){
                    this.oneObj.ussicCoreCode = `${aItem.Code} - ${aItem.Description}`;
                    this.oneObj.mainCode = aItem.Code;
                }
            }
            //获取高管/获取注册号/获取股权信息/获取行业分类
            if(item.pid){
                this.getInfoList('/MyClue/GetSeniors',1,item.pid);
                this.getInfoList('/MyClue/GetNationalIdentifiers',2,item.pid);
                this.getInfoList('/MyClue/v2/GetShareholders',3,item.pid);
                this.getInfoList('/MyClue/GetIndustryCodes',4,item.pid);
            }
        },
        getInfoList(requestUrl,type,pid){
            this.$axios.get(`${this.$clueUrl}${requestUrl}?pid=${pid}`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    if(type==1){this.oneInfoList = data};
                    if(type==2){this.twoInfoList = data};
                    if(type==3){this.threeInfoList = data};
                    if(type==4){
                        if(this.oneObj.mainCode){
                            data.unshift({
                                type: "核心行业",
                                code: this.oneObj.mainCode,
                            })
                        }
                        this.fourInfoList = data.map(ele => {
                            const oneItem = IndustrySic.find(item => item.Code == ele.code);
                            return oneItem ? { ...oneItem, type: ele.type} : { type: ele.type, Code: ele.code, Description: "" };
                        });
                    };
                }
            });
        },


        getInfobelPurchase(){
            this.tableLoading = true;
            this.$axios.post(`${this.$clueUrl}/BusinessData/GetInfobelPurchase`, {
                businessName: this.searchValue,
                country: this.searchCountry,
                pageIndex: this.purchasePage,
                pageSize: 10,
            }).then((res) => {
                const { isSuccess, data } = res;
                this.tableLoading = false;
                if (isSuccess) {
                    this.purchaseList = data.datas;
                    this.purchaseTotal = data.totalItems;
                }
            });
        },
        toDetailTwo(item){
            let routeData = this.$router.resolve({
                path: "/clue/businessDataDetails",
                query: { uniqueId: item.uniqueID, countryCn: item.countryCn, },
            });
            window.open(routeData.href, "_blank");
        },

        //提交信息
        onComplete(item){
            this.btnLoading = true;
            this.$axios.post(`${this.$clueUrl}/MyClue/CompletionCompanyInfo`,{
                type: this.activeName == 'first' ? 0 : 1,
                country: item.countryCn,
                myClueKey: this.$route.query.myClueKey,
                // id: item.id || 0,
                uniqueID: item.uniqueID || "",
                lsmapsCompanyInfo: item,
            }).then((res) => {
                const { isSuccess, data } = res;
                this.oneVisible = false;
                this.btnLoading = false;
                if (isSuccess) {
                    this.$emit('onComplete',data);
                }
            });
        },
        
    },
    mounted() {
        
    },
    watch:{
        completeVisible(){
            if(this.$props.completeVisible){
                this.searchValue = this.$props.searchCompany;
                this.onSearch();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
/deep/.el-dialog__body{
    padding: 10px 20px;
}
.searchBox{
    display: flex;
    margin-bottom: 10px;
}
.searchContent{
    height: 666px;
    overflow: auto;
}

.clueInfo{
    display: flex;
    width: 100%;
    position: relative;
    .contactsNum{
        margin-top: 50px;
        margin-right: 20px;
        width: 80px;
        height: 56px;
        background: #6986D8;
        border-radius: 8px 8px 8px 8px;
        color: #FFFFFF;
        line-height: 28px;
        text-align: center;
    }
    .companyName{
        display: inline-block;
        color: var(--themeColor);
        text-decoration: underline;
        cursor: pointer;
    }
    .country{
        display: inline-block;
        margin-left: 20px;
        img{
            vertical-align: middle;
        }
    }
    .field{
        line-height: 28px;
        margin-right: 30px;
    }
    .color99{
        color: #999999;
    }
    .color3a{
        color: #3A3A3A;
    }
    .primaryColor{
        color: var(--themeColor);
        cursor: pointer;
    }
    .compBtn{
        position: absolute;
        top: 0;
        right: 0;
    }
}
</style>