<template>
  <div id="abroad-summary" class="abroad-summary" v-show="summary.length !== 0">
    <div class="title" ref="title">
      <p>社会评论</p>
      <feedback position="社会评论" url="" />
      <!-- <div class="flex-container">
        <img class="icon" src="@/firm/images/company/icon-feedback.png" style="margin-left: 1em" />
        <span class="text-with-icon" style="color: var(--themeColor, #3152AF)">反馈</span>
      </div> -->
    </div>
    <el-table :data="summary" border :header-cell-style="{ background: '#f8faff', color: '#1F2D3D' }">
      <el-table-column label="关键词" width="192px">
        <template slot-scope="{ row }">
          <div>{{ row.textType.description }}</div>
        </template>
      </el-table-column>
      <el-table-column label="摘要信息">
        <template slot-scope="{ row }">
          <div v-html="row.text"></div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import Vue from "vue";
import { mapMutations } from "vuex";
import { AnchorInditor } from "../../../utils";

export default Vue.extend({
  props: ["summary"],
  watch: {
    summary() {
      try {
        if (this.summary.length > 0) {
          this.setAnchorFlag([["SHPL", true]]);
        } else {
          throw new Error();
        }
      } catch {
        this.setAnchorFlag([["SHPL", false]]);
      }
    },
  },
  methods: {
    ...mapMutations("anchor", {
      setAnchorFlag: "setAnchorFlag",
      setCurrent: "setCurrent",
    }),
  },
  mounted() {
    new AnchorInditor(() => {
      this.setCurrent("SHPL");
    }, this.$refs["title"]);
  },
});
</script>

<style lang="scss" scoped>
@import "@/firm/scss/mixin.scss";

.abroad-summary {
  background: #fff;
  padding: 1rem;
  padding-top: 0;
  @include mixin-panel-title;
}
</style>
