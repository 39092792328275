<template>
<div v-loading="infoLoading">
    <el-empty v-show="!haveData" description="暂无数据" :image-size="200"></el-empty>
    <div class="dataBox" v-show="haveData">
        <div class="formulabar" v-if="isPermission">
            <el-button type="primary" size="small" v-show="!isEdit" @click="isEdit = true;">修 改</el-button>
            <el-button type="primary" size="small" v-show="isEdit" @click="onSave" :loading="btnLoading">保 存</el-button>
            <el-button size="small" v-show="isEdit" @click="onCancel">取 消</el-button>
        </div>
        <el-descriptions :column="2" border :labelStyle="{width:'15%'}" :contentStyle="{width:'35%'}" v-if="!isEdit">
            <el-descriptions-item label="公司名称">{{businessInfo.company_name}}</el-descriptions-item>
            <el-descriptions-item label="成立日期">{{businessInfo.incorporation_date}}</el-descriptions-item>
            <el-descriptions-item label="实体类型">{{businessInfo.entity_type}}</el-descriptions-item>
            <el-descriptions-item label="状态">{{businessInfo.status}}</el-descriptions-item>
            <el-descriptions-item label="公司地址" :span="2">
                {{businessInfo.address_line1}} {{businessInfo.address_line2}} {{businessInfo.address_line3}} {{businessInfo.address_line4}}
            </el-descriptions-item>
            <el-descriptions-item label="邮政编码">{{businessInfo.postcode}}</el-descriptions-item>
            <el-descriptions-item label="国家ISO代码">{{businessInfo.country_iso_code}}</el-descriptions-item>
            <el-descriptions-item label="国家">{{businessInfo.country}}</el-descriptions-item>
            <el-descriptions-item label="公司所在城市">{{businessInfo.city}}</el-descriptions-item>
            <el-descriptions-item label="经度">{{businessInfo.longitude}}</el-descriptions-item>
            <el-descriptions-item label="纬度">{{businessInfo.latitude}}</el-descriptions-item>
            <el-descriptions-item label="公司电话">{{businessInfo.phone}}</el-descriptions-item>
            <el-descriptions-item label="公司传真">{{businessInfo.fax}}</el-descriptions-item>
            <el-descriptions-item label="网址">
                <!-- <div v-if="businessInfo.websites.length != 0">
                    <div class="primaryColor" v-for="ele in businessInfo.websites" :key="ele" @click="openWebsite(ele)">{{ele}}</div>
                </div> -->
                <div class="primaryColor" @click="openWebsite(businessInfo.domain)">{{businessInfo.domain}}</div>
            </el-descriptions-item>
            <el-descriptions-item label="电子邮件">{{businessInfo.email}}</el-descriptions-item>
            <!-- <el-descriptions-item label="US SIC, core code (3 digits)" :span="2">
                {{businessInfo.ussic_core_code}}
            </el-descriptions-item> -->
            <!-- 2022-06-22 新增展示字段 -->
            <el-descriptions-item label="员工人数">{{businessInfo.employee}}</el-descriptions-item>
            <el-descriptions-item label="营收">{{businessInfo.revenueUsd ? businessInfo.revenueUsd + "千美元": ""}}</el-descriptions-item>
            <template v-if="businessInfo.industryInfo">
                <el-descriptions-item label="公司简介" :span="2">
                    {{businessInfo.industryInfo.companyProfile}}
                </el-descriptions-item>
                <el-descriptions-item label="主营业务单元情况">{{businessInfo.industryInfo.businessline}}</el-descriptions-item>
                <el-descriptions-item label="历史沿革">{{businessInfo.industryInfo.history}}</el-descriptions-item>
                <el-descriptions-item label="国内主要国家">{{businessInfo.industryInfo.majorCountryIn}}</el-descriptions-item>
                <el-descriptions-item label="国外主要国家或地区">{{businessInfo.industryInfo.majorCountryOut}}</el-descriptions-item>
                <el-descriptions-item label="产品和服务">{{businessInfo.industryInfo.productService}}</el-descriptions-item>
                <el-descriptions-item label="主营业务说明">{{businessInfo.industryInfo.tradeEn}}</el-descriptions-item>
            </template>
        </el-descriptions>

        <el-descriptions :column="2" border :labelStyle="{width:'15%'}" :contentStyle="{width:'35%'}" v-else>
            <el-descriptions-item label="公司名称">
                <el-input v-model="businessInfo.company_name"></el-input>
            </el-descriptions-item>
            <el-descriptions-item label="成立日期">
                <el-input v-model="businessInfo.incorporation_date"></el-input>
            </el-descriptions-item>
            <el-descriptions-item label="实体类型">
                <el-input v-model="businessInfo.entity_type"></el-input>
            </el-descriptions-item>
            <el-descriptions-item label="状态">
                <el-input v-model="businessInfo.status"></el-input>
            </el-descriptions-item>
            <el-descriptions-item label="公司地址" :span="2">
                <el-input v-model="businessInfo.address_line1" style="width:25%;"></el-input>
                <el-input v-model="businessInfo.address_line2" style="width:25%;"></el-input>
                <el-input v-model="businessInfo.address_line3" style="width:25%;"></el-input>
                <el-input v-model="businessInfo.address_line4" style="width:25%;"></el-input>
            </el-descriptions-item>
            <el-descriptions-item label="邮政编码">
                <el-input v-model="businessInfo.postcode"></el-input>
            </el-descriptions-item>
            <el-descriptions-item label="国家ISO代码">
                <el-input v-model="businessInfo.country_iso_code"></el-input>
            </el-descriptions-item>
            <el-descriptions-item label="国家">
                <el-input v-model="businessInfo.country"></el-input>
            </el-descriptions-item>
            <el-descriptions-item label="公司所在城市">
                <el-input v-model="businessInfo.city"></el-input>
            </el-descriptions-item>
            <el-descriptions-item label="经度">
                <el-input v-model="businessInfo.longitude"></el-input>
            </el-descriptions-item>
            <el-descriptions-item label="纬度">
                <el-input v-model="businessInfo.latitude"></el-input>
            </el-descriptions-item>
            <el-descriptions-item label="公司电话">
                <el-input v-model="businessInfo.phone"></el-input>
            </el-descriptions-item>
            <el-descriptions-item label="公司传真">
                <el-input v-model="businessInfo.fax"></el-input>
            </el-descriptions-item>
            <el-descriptions-item label="网址">
                <!-- <el-input v-model="businessInfo.websites[0]"></el-input> -->
                <el-input v-model="businessInfo.domain"></el-input>
            </el-descriptions-item>
            <el-descriptions-item label="电子邮件">
                <el-input v-model="businessInfo.email"></el-input>
            </el-descriptions-item>
            <el-descriptions-item label="员工人数">
                <el-input v-model="businessInfo.employee"></el-input>
            </el-descriptions-item>
            <el-descriptions-item label="营收">
                <el-input v-model="businessInfo.revenueUsd"></el-input>
            </el-descriptions-item>
            <template v-if="businessInfo.industryInfo">
                <el-descriptions-item label="公司简介" :span="2">
                    <el-input v-model="businessInfo.industryInfo.companyProfile"></el-input>
                </el-descriptions-item>
                <el-descriptions-item label="主营业务单元情况">
                    <el-input v-model="businessInfo.industryInfo.businessline"></el-input>
                </el-descriptions-item>
                <el-descriptions-item label="历史沿革">
                    <el-input v-model="businessInfo.industryInfo.history"></el-input>
                </el-descriptions-item>
                <el-descriptions-item label="国内主要国家">
                    <el-input v-model="businessInfo.industryInfo.majorCountryIn"></el-input>
                </el-descriptions-item>
                <el-descriptions-item label="国外主要国家或地区">
                    <el-input v-model="businessInfo.industryInfo.majorCountryOut"></el-input>
                </el-descriptions-item>
                <el-descriptions-item label="产品和服务">
                    <el-input v-model="businessInfo.industryInfo.productService"></el-input>
                </el-descriptions-item>
                <el-descriptions-item label="主营业务说明">
                    <el-input v-model="businessInfo.industryInfo.tradeEn"></el-input>
                </el-descriptions-item>
            </template>
        </el-descriptions>

        <div v-if="businessInfo.contacts.length != 0">
            <div class="title">联系信息</div>
            <el-table :data="businessInfo.contacts.slice(0,10)" border :header-cell-style="{'background-color':'#F8FAFF','color':'#1F2D3D'}">
                <el-table-column type="index" label="序号" width="50px" align="center"></el-table-column>
                <el-table-column prop="fullname" label="全称"></el-table-column>
                <el-table-column prop="originalpost2" label="职位"></el-table-column>
            </el-table>
        </div>
        <div v-if="businessInfo.nationalIdentifiers.length != 0">
            <div class="title">国家识别码</div>
            <el-table :data="businessInfo.nationalIdentifiers.slice(0,10)" border :header-cell-style="{'background-color':'#F8FAFF','color':'#1F2D3D'}">
                <el-table-column type="index" label="序号" width="50px" align="center"></el-table-column>
                <el-table-column prop="natId" label="本国ID号码"></el-table-column>
                <el-table-column prop="natLabel" label="本国ID标签"></el-table-column>
            </el-table>
        </div>
        <div v-if="businessInfo.sics.length != 0">
            <div class="title">US SIC</div>
            <el-table :data="businessInfo.sics.slice(0,10)" border :header-cell-style="{'background-color':'#F8FAFF','color':'#1F2D3D'}">
                <el-table-column type="index" label="序号" width="50px" align="center"></el-table-column>
                <el-table-column prop="primaryCode" label="code"></el-table-column>
                <el-table-column prop="sicDescription" label="中文描述"></el-table-column>
                <el-table-column prop="sicEnDescription" label="英文描述"></el-table-column>
            </el-table>
        </div>
        <!-- 新增股权信息(shareholders) -->
        <div v-if="shareholdersOne.length != 0 || shareholdersTwo.length != 0">
            <div class="title">股权信息</div>
            <el-table :data="shareholdersOne.slice(0,10)" border :header-cell-style="{'background-color':'#F8FAFF','color':'#1F2D3D'}">
                <el-table-column type="index" label="序号" width="50px" align="center"></el-table-column>
                <el-table-column label="公司股东">
                    <template slot-scope="scope">
                        <div v-if="scope.row.pId" class="primaryColor" @click="toView(scope.row)">{{scope.row.name}}</div>
                        <div v-else>{{scope.row.name}}</div>  
                    </template>
                </el-table-column>
                <el-table-column label="占股比例">
                    <template slot-scope="scope">
                        {{scope.row.shareHoldersDirect + "%"}}
                    </template>
                </el-table-column>
            </el-table>
            <el-table :data="shareholdersTwo.slice(0,10)" border :header-cell-style="{'background-color':'#F8FAFF','color':'#1F2D3D'}">
                <el-table-column type="index" label="序号" width="50px" align="center"></el-table-column>
                <el-table-column label="公司投资子公司">
                    <template slot-scope="scope">
                        <div v-if="scope.row.pId" class="primaryColor" @click="toView(scope.row)">{{scope.row.name}}</div>
                        <div v-else>{{scope.row.name}}</div>  
                    </template>
                </el-table-column>
                <el-table-column label="占股比例">
                    <template slot-scope="scope">
                        {{scope.row.shareHoldersDirect + "%"}}
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <!-- <Mapbox /> -->
    </div>
</div>
</template>

<script>
// import Mapbox from "../components/Mapbox.vue"
export default {
    // components: { Mapbox },
    props: ["clueRelationKey"],
    data() {
        return {
            key: "",
            company: "",
            haveData: true, //是否有数据
            businessInfo: {
                nationalIdentifiers: [],
                contacts: [],
                sics: [],
                websites: [],
                industryInfo: {},
            },
            shareholdersOne: [],
            shareholdersTwo: [],
            infoLoading: false,
            btnLoading: false,

            isPermission: false,
            isEdit: false,
        };
    },
    methods: {
        getInfo(){
            this.infoLoading = true;
            this.$axios.get(`${this.$clueUrl}/BusinessData/GetBusinessData?company=${this.key || this.company}`).then((res) => {
                const { isSuccess, data } = res;
                this.infoLoading = false;
                if (isSuccess) {
                    if(data){
                        this.businessInfo = data;
                    }else{
                        this.haveData = false;
                    }
                    
                }
            });
        },
        //获取股权信息
        getShareholders(){
            this.$axios.get(`${this.$clueUrl}/BusinessData/GetShareholders?pid=${this.key}`).then((res) => {
                const { isSuccess, data } = res;
                this.infoLoading = false;
                if (isSuccess) {
                    console.log(data);
                    this.shareholdersOne = data.filter(ele => ele.type == 0);
                    this.shareholdersTwo = data.filter(ele => ele.type == 1);
                }
            });
        },

        toView(row){
            let routeData = this.$router.resolve({
                path: "/clue/clueDetails",
                query: { key: row.pId, companyName: row.name, clueType: 'commercial', },
            });
            window.open(routeData.href, "_blank");
        },

        //打开网址
        openWebsite(url){
            let jumpUrl = url.indexOf("http") > -1 ? url : "http://" + url;
            let routeData = this.$router.resolve({
                path: "/jumpWeb",
                query: { jumpUrl: jumpUrl },
            });
            window.open(routeData.href, "_blank");
        },

        onSave(){
            this.btnLoading = true;
            this.$axios.post(`${this.$clueUrl}/BusinessData/UpdateBusiness`,this.businessInfo).then((res) => {
                const { isSuccess, data } = res;
                this.btnLoading = false;
                this.isEdit = false;
                this.getInfo();
                if (isSuccess) {
                    if(data){
                        this.$message({ message: '保存成功', type: 'success'});
                    }else{
                        this.$message({ message: '保存失败', type: 'warning'});
                    }
                }
            });
        },
        onCancel(){
            this.isEdit = false;
            this.getInfo();
        }
    },
    mounted() {
        // let companyName = encodeURIComponent(this.$route.query.companyName);
        this.key = this.$route.query.key || this.$props.clueRelationKey;
        if(this.key){
            this.getInfo();
            this.getShareholders();
        }else{
            this.company = encodeURIComponent(this.$route.query.company);
            if(this.company){
                this.getInfo();
            }
        }

        let userInfo = JSON.parse(localStorage.userInfo);
        if(userInfo.companyKey == "52b9b01c3c67494c8114f35e7b912779"){
            this.isPermission = true;
        }
        if(userInfo.mailAddress.indexOf('guodong.liu@lsmaps.com') >= 0){
            this.isPermission = true;
        }

    },
};
</script>

<style lang="scss" scoped>
.dataBox{
    position: relative;
    .title{
        margin-top: 10px;
        height: 32px;
        list-style: 32px;
        font-weight: bold;
    }
    .primaryColor{
        color: var(--themeColor);
        cursor: pointer;
    }
    .formulabar{
        position: absolute;
        top: -40px;
        right: 0;
    }
}
</style>