<template>
    <div>
        <el-card v-if="pageState == '列表'">
            <div class="inputBox">
                <el-input v-model="subNo" placeholder="请输入提单号" @blur="matchCode" size="small" style="width:300px;"></el-input>
                <span style="padding:0 10px;"></span>
                <el-select v-model="carrierCd" placeholder="搜索船司" size="small" style="width:300px;">
                    <el-option v-for="item in carrierOption" :key="item.code" :label="item.name" :value="item.code"></el-option>
                </el-select>
                <span style="padding:0 10px;"></span>
                <el-button type="primary" size="small" :disabled="isDisabled" :loading="btnLoading" @click="onSubscription">订阅</el-button>
                <span class="textSpan">每人最多订阅10个</span>
            </div>
            <div class="resultBox" :loading="resultLoading">
                <el-empty v-if="resultTotal == 0" description="暂无订阅"></el-empty>
                <div v-else>
                    <div class="resultItem" v-for="item in resultList" :key="item.subNo">
                        <div class="headerInfo">
                            <div style="width:300px;margin-right:20px;">
                                <span>提单号：</span>
                                <span>{{item.subNo}}</span>
                            </div>
                            <div style="display: flex;align-items: center;">
                                <span>起运港：</span>
                                <span>{{item.pol}}</span>
                                <span class="middle-line"></span>
                                <span>目的港：</span>
                                <span>{{item.dtp}}</span>
                            </div>
                        </div>
                        <div class="middleInfo">
                            <div style="width:200px;border-right: 1px solid #d8d8d8;">
                                <div style="height:20px;">船司：{{item.carrierCd}}</div>
                                <div style="height:20px;padding:10px 0;">船名：{{item.vslNameEn}}</div>
                                <div style="height:20px;">航次：{{item.voy}}</div>
                            </div>
                            <div class="statusBox">
                                <div class="statusItem" v-for="(ele,index) in item.statusList" :key="index">
                                    <div>{{ele.status}}</div>
                                    <div class="circle"></div>
                                    <div>{{ele.num}}箱</div>
                                </div>
                            </div>
                        </div>
                        <div class="footerInfo">
                            <div class="updateTime">数据更新时间：{{item.refreshTime}}</div>
                            <el-link type="primary" :underline="false" @click="viewDetails(item.subNo)">详情></el-link>
                        </div>
                    </div>
                    <el-pagination hide-on-single-page background layout="total,prev,pager,next" :total="resultTotal" style="float:right;margin-top:10px;" :current-page.sync="pageIndex" @current-change="getSubscription"></el-pagination>
                </div>
            </div>
        </el-card>
        <el-card v-else>
            <el-page-header @back="pageState = '列表'" content="提单详情"></el-page-header>
            <div class="detailsBox">
                <div class="basicInfo">
                    <div class="basicItem">
                        <div class="label">提单号</div>
                        <div class="value">{{detailsInfo.subNo}}</div>
                    </div>
                    <div class="basicItem">
                        <div class="label">订舱号</div>
                        <div class="value">{{detailsInfo.bkgNo}}</div>
                    </div>
                    <div class="basicItem">
                        <div class="label">船司</div>
                        <div class="value">{{detailsInfo.carrierCd}}</div>
                    </div>
                    <div class="basicItem">
                        <div class="label">箱量</div>
                        <div class="value">{{detailsInfo.bkgVolumn}}</div>
                    </div>
                    <div class="basicItem">
                        <div class="label">起运地</div>
                        <el-tooltip effect="dark" :content="detailsInfo.reception" placement="top">
                            <div class="value">{{detailsInfo.reception}}</div>
                        </el-tooltip>
                    </div>
                    <div class="basicItem">
                        <div class="label">起运港</div>
                        <el-tooltip effect="dark" :content="detailsInfo.pol" placement="top">
                            <div class="value">{{detailsInfo.pol}}</div>
                        </el-tooltip>
                    </div>
                    <div class="basicItem">
                        <div class="label">目的港</div>
                        <el-tooltip effect="dark" :content="detailsInfo.dtp" placement="top">
                            <div class="value">{{detailsInfo.dtp}}</div>
                        </el-tooltip>
                    </div>
                    <div class="basicItem">
                        <div class="label">目的地</div>
                        <el-tooltip effect="dark" :content="detailsInfo.destination" placement="top">
                            <div class="value">{{detailsInfo.destination}}</div>
                        </el-tooltip>
                    </div>
                    <div class="basicItem">
                        <div class="label">ETD</div>
                        <div class="value">{{detailsInfo.etd}}</div>
                    </div>
                    <div class="basicItem">
                        <div class="label">ATD</div>
                        <div class="value">{{detailsInfo.atd}}</div>
                    </div>
                    <div class="basicItem">
                        <div class="label">ETA</div>
                        <div class="value">{{detailsInfo.eta}}</div>
                    </div>
                    <div class="basicItem">
                        <div class="label">ATA</div>
                        <div class="value">{{detailsInfo.ata}}</div>
                    </div>
                </div>
                <div class="title">集装箱信息({{detailsInfo.cntrInfoList.length}})</div>
                <el-empty v-if="detailsInfo.cntrInfoList.length == 0" description="暂无信息"></el-empty>
                <div v-else>
                    <div class="containerInfo" v-for="item in detailsInfo.cntrInfoList" :key="item.ctnrNo">
                        <div class="headerInfo">
                            <div style="width:300px;">
                                <span>箱号：</span>
                                <span>{{item.ctnrNo}}</span>
                            </div>
                            <div style="width:300px;">
                                <span>箱型：</span>
                                <span>{{item.size}}</span>
                            </div>
                        </div>
                        <div class="statusBox">
                            <div class="statusItem" v-for="(ele,index) in item.cntrStatus" :key="index">
                                <div>{{ele.status}}</div>
                                <div class="circle"></div>
                                <el-tooltip effect="dark" :content="ele.stateDescription" placement="top">
                                    <div class="description">{{ele.stateDescription || "--"}}</div>
                                </el-tooltip>
                                <div>{{ele.statusTime}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </el-card>
    </div>
</template>

<script>
export default {
    data() {
        return {
            pageState: "列表",
            carrierOption: [
                {
                    code: "SLS",
                    name: "新加坡海领船务(SLS)",
                    pinyin: "xinjiapohailingchuanwu",
                },
                {
                    code: "COSCO",
                    name: "中远海运(COSCO)",
                    pinyin: "zhongyuanhaiyun",
                },
                {
                    code: "ONE",
                    name: "海洋网联(ONE)",
                    pinyin: "haiyangwanglian",
                },
                {
                    code: "YML",
                    name: "阳明海运(YML)",
                    pinyin: "yangminghaiyun",
                },
                {
                    code: "PIL",
                    name: "太平船务(PIL)",
                    pinyin: "taipingchuanwu",
                },
                {
                    code: "SITC",
                    name: "新海丰(SITC)",
                    pinyin: "xinhaifeng",
                },
                {
                    code: "ESL",
                    name: "阿联酋航运(ESL)",
                    pinyin: "alianqiuhangyun",
                },
                {
                    code: "SJJ",
                    name: "锦江航运(SJJ)",
                    pinyin: "jinjianghangyun",
                },
                {
                    code: "ANL",
                    name: "澳洲航运(ANL)",
                    pinyin: "aozhouhangyun",
                },
                {
                    code: "APL",
                    name: "美国总统轮船(APL)",
                    pinyin: "meiguozongtonglunchuan",
                },
                {
                    code: "HASCO",
                    name: "海华轮船(HASCO)",
                    pinyin: "haihualunchuan",
                },
                {
                    code: "FESCO",
                    name: "俄远东轮船(FESCO)",
                    pinyin: "eyuandonglunchuan",
                },
                {
                    code: "MSC",
                    name: "地中海(MSC)",
                    pinyin: "dizhonghai",
                },
                {
                    code: "VOL",
                    name: "阿联酋瓦尔塔船务(VOL)",
                    pinyin: "alianqiuwaertachuanwu",
                },
                {
                    code: "OOCL",
                    name: "东方海外(OOCL)",
                    pinyin: "dongfanghaiwai",
                },
                {
                    code: "EMC",
                    name: "长荣海运(EMC)",
                    pinyin: "zhangronghaiyun",
                },
                {
                    code: "WHL",
                    name: "万海航运(WHL)",
                    pinyin: "wanhaihangyun",
                },
                {
                    code: "KMTC",
                    name: "高丽(KMTC)",
                    pinyin: "gaoli",
                },
                {
                    code: "SML",
                    name: "森罗(SML)",
                    pinyin: "senluo",
                },
                {
                    code: "HAL",
                    name: "兴亚航运(HAL)",
                    pinyin: "xingyahangyun",
                },
                {
                    code: "RCL",
                    name: "宏海箱运(RCL)",
                    pinyin: "honghaixiangyun",
                },
                {
                    code: "CNC",
                    name: "正利航业(CNC)",
                    pinyin: "zhenglihangye",
                },
                {
                    code: "KKC",
                    name: "神原汽船(KKC)",
                    pinyin: "shenyuanqichuan",
                },
                {
                    code: "MSK",
                    name: "马士基(MSK)",
                    pinyin: "mashiji",
                },
                {
                    code: "MSSCO",
                    name: "民生轮船(MSSCO)",
                    pinyin: "minshenglunchuan",
                },
                {
                    code: "NOSCO",
                    name: "宁波远洋(NOSCO)",
                    pinyin: "ningboyuanyang",
                },
                {
                    code: "CMA",
                    name: "法国达飞(CMA)",
                    pinyin: "faguodafei",
                },
                {
                    code: "HPL",
                    name: "赫伯罗特(HPL)",
                    pinyin: "heboluote",
                },
                {
                    code: "HMM",
                    name: "现代商船(HMM)",
                    pinyin: "xiandaishangchuan",
                },
                {
                    code: "ZIM",
                    name: "以星航运(ZIM)",
                    pinyin: "yixinghangyun",
                },
                {
                    code: "SKR",
                    name: "长锦商船(SKR)",
                    pinyin: "zhangjinshangchuan",
                },
                {
                    code: "MATS",
                    name: "美森轮船(MATS)",
                    pinyin: "meisenlunchuan",
                },
                {
                    code: "TSL",
                    name: "德翔海运(TSL)",
                    pinyin: "dexianghaiyun",
                },
                {
                    code: "GSL",
                    name: "金星轮船(GSL)",
                    pinyin: "jinxinglunchuan",
                },
                {
                    code: "MCC",
                    name: "海陆马士基(MCC)",
                    pinyin: "hailumashiji",
                },
                {
                    code: "IAL",
                    name: "运达(IAL)",
                    pinyin: "yunda",
                },
                {
                    code: "CUL",
                    name: "中联航运(CUL)",
                    pinyin: "zhonglianhangyun",
                },
                {
                    code: "HBS",
                    name: "汉堡南美(HBS)",
                    pinyin: "hanbaonanmei",
                },
            ],
            //订阅
            subNo: "",
            carrierCd: "",
            ukey: "",
            //结果
            btnLoading: false,
            resultLoading: false,
            resultList: [],
            resultTotal: 0,
            pageIndex: 1,
            //详情
            detailsLoading: false,
            detailsInfo: {
                cntrInfoList: [],//集装箱信息
            },
        };
    },
    computed: {
        isDisabled() {
            let isHasValue = this.subNo && this.carrierCd ? true : false;
            return !isHasValue;
        },
    },
    methods: {
        matchCode() {
            this.subNo = this.subNo.trim();
            if(!this.subNo){ return false; };
            let string3wei = this.subNo.substring(0, 3);
            if(string3wei == 'HLC'){string3wei = 'HPL'};
            if(string3wei == 'MED'){string3wei = 'MSC'};
            if(string3wei == 'CMD'){string3wei = 'CMA'}; 
            let matchArr = this.carrierOption.filter((ele) =>
                ele.code.includes(string3wei)
            );
            if (matchArr.length > 0) {
                this.carrierCd = matchArr[0].code;
            }else{
                this.carrierCd = "";
            }
        },
        //点击订阅
        onSubscription() {
            this.btnLoading = true;
            this.$axios.get(`${this.$orderUrl}/Yxb/ShipSubscribeTo?subNo=${this.subNo}&carrierCd=${this.carrierCd}&ukey=${this.ukey}`).then((res) => {
                const { isSuccess } = res;
                this.btnLoading = false;
                if (isSuccess) {
                    this.$message({ message: '订阅成功', type: 'success'});
                    this.pageIndex = 1;
                    this.getSubscription();
                    //清空数据
                    this.subNo = "";
                    this.carrierCd = "";
                }
            });
        },
        //获取订阅
        getSubscription() {
            this.resultLoading = true;
            this.$axios.post(`${this.$orderUrl}/Yxb/GetSubscribeToList`, {
                subNo: "",
                ukey: this.ukey,
                pageIndex: this.pageIndex,
                pageSize: 10,
            }).then((res) => {
                const { isSuccess, data } = res;
                this.resultLoading = false;
                if (isSuccess) {
                    this.resultList = data.datas;
                    this.resultTotal = data.totalItems;
                }
            });
        },
        //查看详情
        viewDetails(subNo){
            this.pageState = "详情";  
            this.$axios.get(`${this.$orderUrl}/Yxb/GetSubscribeToDetail?subNo=${subNo}`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.detailsInfo = data;
                    // console.log(this.detailsInfo.cntrInfoList);
                }
            });
        },
    },
    mounted() {
        this.ukey = JSON.parse(localStorage.userInfo).uKey;
        this.getSubscription();
    },
};
</script>

<style lang="scss" scoped>
.inputBox{
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    align-items: center;
    .textSpan{
        padding-left: 10px;
        color: #666;
        font-size: 14px;
    }
}
.resultBox{
    overflow: hidden;
    .resultItem{
        border: 1px solid #d8d8d8;
        border-radius: 8px;
        position: relative;
        margin-bottom: 24px;
        color: #333;
        font-size: 14px;
        .headerInfo{
            padding: 14px 20px;
            display: flex;
            height: 32px;
            line-height: 32px;
            .middle-line{
                display: inline-block;
                width: 40px;
                height: 1px;
                background-color: #d8d8d8;
                margin: 0 20px;
            }
        }
        .middleInfo{
            border-top: 1px solid #d8d8d8;
            border-bottom: 1px solid #d8d8d8;
            padding: 16px 20px;
            display: flex;
            height: 80px;
            .statusBox{
                box-sizing: border-box;
                width: calc(100% - 200px);
                height: 80px;
                padding: 0 20px;
                white-space: nowrap;
                overflow-x: auto;
                .statusItem{
                    display: inline-block;
                    width: 150px;
                    text-align: center;
                    position: relative;
                    .circle{
                        width: 12px;
                        height: 12px;
                        background-color: var(--themeColor);
                        border-radius: 50%;
                        margin: 6px auto;
                    }
                }
                .statusItem:not(:first-child) :before{
                    content: "";
                    position: absolute;
                    height: 1px;
                    width: 75px;
                    background-color: var(--themeColor);
                    left: 0;
                    top: 50%;
                    transform: translate(-50%,100%);
                }
            }
        }
        .footerInfo{
            display: flex;
            justify-content: flex-end;
            padding: 10px 20px;
            .updateTime{
                margin-right: 40px;
                color: #00b26f;
            }
        }
    }
}
.detailsBox{
    margin-top: 10px;
    .basicInfo{
        display: flex;
        flex-wrap: wrap;
        .basicItem{
            width: 25%;
            display: flex;
            height: 32px;
            line-height: 32px;
            font-size: 14px;
            .label{
                padding-right: 10px;
                color: #999;
            }
            .label::after{
                content: ":";
            }
            .value{
                color: #333;
                font-weight: bold;
                flex: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
    .title{
        color: #333;
        font-size: 16px;
        font-weight: 700;
        margin-top: 10px;
    }
    .containerInfo{
        border: 1px solid #d8d8d8;
        border-radius: 8px;
        margin-top: 10px;
        color: #333;
        font-size: 14px;
        .headerInfo{
            padding: 10px 20px;
            display: flex;
            height: 24px;
            line-height: 24px;
        }
        .statusBox{
            border-top: 1px solid #d8d8d8;
            box-sizing: border-box;
            width: 100%;
            padding: 16px 20px;
            white-space: nowrap;
            overflow-x: auto;
            .statusItem{
                display: inline-block;
                width: 200px;
                text-align: center;
                position: relative;
                .circle{
                    width: 12px;
                    height: 12px;
                    background-color: var(--themeColor);
                    border-radius: 50%;
                    margin: 6px auto;
                }
                .description{
                    width: 120px;
                    margin: 0 auto 6px;
                    font-weight: bold;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
            .statusItem:not(:first-child) :before{
                content: "";
                position: absolute;
                height: 1px;
                width: 75px;
                background-color: var(--themeColor);
                left: 0;
                top: 35%;
                transform: translate(-50%,100%);
            }
        }
    }
}
</style>