<template>
    <el-dialog title="上传文件" :visible.sync="showBox" width="500px" :show-close="false">
        <input @change="onInputChange" ref="InputDom" type="file" v-show="false" />
        <div class="uploadBox" type="primary" @click="uploadClick" @dragover="ev=>ev.preventDefault()" @drop="onDropUpload">
            <i class="el-icon-upload"></i>
            <div>将文件拖到此处，或<span style="color:var(--themeColor)">点击上传</span></div>
        </div>
        <el-dialog title="上传文件" :visible.sync="progressVisible" :show-close="false" width="800px" append-to-body>
            <el-progress :text-inside="true" :stroke-width="20" :percentage="progressNumber"></el-progress>
        </el-dialog>
        <span slot="footer" class="dialog-footer">
            <el-button @click="$emit('onClose')" size="small">关 闭</el-button>
        </span>
    </el-dialog>
</template>

<script>
import { uploadFile } from '../api/upload.js'
export default {
    props:["showBox"],
    data() {
        return {
            progressVisible: false,
            progressNumber: 0,
        };
    },
    methods: {
        uploadClick(){
            this.$refs.InputDom.click();
        },
        async onInputChange(event){
            const file = event.target.files[0];
            this.progressVisible = true;
            this.progressNumber = 0;
            const resObj = await uploadFile(file,(number)=>{
                this.progressNumber = number;
            })
            this.progressVisible = false;
            if(resObj){
                this.$emit('onComplete',resObj);
            }else{
                this.$message({message: "上传失败",type: 'warning'});
                this.$emit('onClose');
            }
        },
        async onDropUpload(event){
            event.preventDefault();
            const file = event.dataTransfer.files[0];
            this.progressVisible = true;
            this.progressNumber = 0;
            const resObj = await uploadFile(file,(number)=>{
                this.progressNumber = number;
            })
            this.progressVisible = false;
            if(resObj){
                this.$emit('onComplete',resObj);
            }else{
                this.$message({message: "上传失败",type: 'warning'});
                this.$emit('onClose');
            }
        }
    },
    mounted() {

    },
};
</script>

<style lang='scss' scoped>
.uploadBox{
    background-color: #fff;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    box-sizing: border-box;
    width: 460px;
    height: 180px;
    text-align: center;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    .el-icon-upload{
        font-size: 60px;
        color: #c0c4cc;
        margin: 40px 0 16px;
        line-height: 50px;
    }
    &:hover{
        border: 1px dashed var(--themeColor);
    }
}
</style>