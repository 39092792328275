<template>
    <div>
        <InterfaceName :interfaceName="interfaceName" />
        <InterfaceSpe :requestUrl="requestUrl" :requestMethod="requestMethod" />
        <InputParam :inputTableData="inputTableData" />
        <ReturnParam :returnObj="returnObj" />
    </div>
</template>

<script>
import InterfaceName from "../Acomponents/InterfaceName.vue";
import InterfaceSpe from "../Acomponents/InterfaceSpe.vue";
import InputParam from "../Acomponents/InputParam.vue";
import ReturnParam from "../Acomponents/ReturnParam.vue";

export default {
    components: { InterfaceName, InterfaceSpe, InputParam, ReturnParam },
    data() {
        return {
            interfaceName: "获取客户收入",
            requestUrl: "https://crm.qixinerp.com/open/Customer/GetCustomerIncome",
            requestMethod: "POST",
            inputTableData: [],
            returnObj: {},
        };
    },
    methods: {},
    mounted() {
        this.inputTableData = [
            {
                paramName: "customerKey",
                valueType: "string",
                explain: "客户key",
                required: true,
            },
            {
                paramName: "pageIndex",
                valueType: "integer",
                explain: "当前页码",
                required: true,
            },
            {
                paramName: "pageSize",
                valueType: "integer",
                explain: "每页条数",
                required: true,
            },
        ];
        this.returnObj = {
            isSuccess: true,
            errorMessage: null,
            data: {
                datas: [
                    {
                        key: "47c101bd4dc645eba6ed9aaa7c8672e2",
                        collectionNo: "HB20220527152616849",
                        payeeCollectionDate: "2022-05-27",
                        payee: "技术部测试账号",
                        payerContacts: "PXX",
                        collectionCurrency: "CNY",
                        collectionAmount: 10000,
                        weCompany: "",
                        handler: "张三",
                        remarks: "",
                    },
                ],
                pageSize: 10,
                pageIndex: 1,
                totalItems: 1,
            },
        };
    },
};
</script>

<style lang="scss" scoped>
</style>