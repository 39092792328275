<template>
    <div class="view">
        <div class="top-grid-column-2">
            <div class="report-panel" v-loading="qushifenxi.loading">
                <div class="report-panel-title bb">
                    <div style="display: flex; align-items: flex-end">
                        <span class="report-panel-title-text">趋势分析</span>
                    </div>
                    <div class="icon-container">
                        <img src="@/assets/icon/refresh.png" />
                        <img src="@/assets/icon/close.png" />
                    </div>
                </div>
                <div ref="qushifenxi-dom" class="report-panel-chart-dom"></div>
            </div>
            <div style="display: flex; flex-direction: column; justify-content: space-between">
                <div class="card-item">
                    <div style="flex-shrink: 0; margin-left: 20px; width: 110px; display: flex; flex-direction: column; align-items: center">
                        <img style="width: 34px; height: 34px" src="@/assets/img/home-card-1.png" />
                        <p class="card-item-title">签约简报</p>
                    </div>
                    <div style="font-size: 14px; color: #1b2841">
                        <p>
                            今日：<span style="color: #d65f6e; font-weight: bold">{{ cardItem.qianyuejianbao.day[0] || 0 }}单/￥{{ cardItem.qianyuejianbao.day[1] || "0.00" }}</span>
                        </p>
                        <p style="margin: 2px 0">
                            本周：<span style="color: #8abb71; font-weight: bold">{{ cardItem.qianyuejianbao.week[0] || 0 }}单/￥{{ cardItem.qianyuejianbao.week[1] || "0.00" }}</span>
                        </p>
                        <p>
                            本月：<span style="color: #2e7db5; font-weight: bold">{{ cardItem.qianyuejianbao.month[0] || 0 }}单/￥{{ cardItem.qianyuejianbao.month[1] || "0.00" }}</span>
                        </p>
                    </div>
                </div>
                <div class="card-item">
                    <div style="flex-shrink: 0; margin-left: 20px; width: 110px; display: flex; flex-direction: column; align-items: center">
                        <img style="width: 34px; height: 34px" src="@/assets/img/home-card-2.png" />
                        <p class="card-item-title">预计签约金额</p>
                    </div>
                    <div style="font-size: 14px; font-weight: bold; width: 100%; text-align: center">
                        <p style="color: #3a3a3a">本月:</p>
                        <p style="color: #2e7db5">￥10,000</p>
                    </div>
                </div>
                <div class="card-item">
                    <div style="flex-shrink: 0; margin-left: 20px; width: 110px; display: flex; flex-direction: column; align-items: center">
                        <img style="width: 34px; height: 34px" src="@/assets/img/home-card-3.png" />
                        <p class="card-item-title">现有客户数量</p>
                    </div>
                    <div style="font-size: 14px; font-weight: bold; color: #1a2745; width: 100%; text-align: center">{{ cardItem.xianyoukehushu }}个</div>
                </div>
            </div>
        </div>
        <div class="main-grid-container">
            <div class="report-panel" v-loading="xiaoshouloudou.loading">
                <div class="report-panel-title bb">
                    <div style="display: flex; align-items: flex-end">
                        <span class="report-panel-title-text">销售漏斗</span>
                    </div>
                    <div class="icon-container">
                        <img src="@/assets/icon/refresh.png" />
                        <img src="@/assets/icon/close.png" />
                    </div>
                </div>
                <div ref="xiaoshouloudou-dom" class="report-panel-chart-dom"></div>
            </div>
            <div class="report-panel" v-loading="kehulaiyuan.loading">
                <div class="report-panel-select" style="margin-top: 16px">
                    <div @click="setType('kehulaiyuan', '全部')" class="report-panel-select-option" :class="{ active: kehulaiyuan.type === '全部' }">全部</div>
                    <div @click="setType('kehulaiyuan', '已成交')" class="report-panel-select-option" :class="{ active: kehulaiyuan.type === '已成交' }">已成交</div>
                    <div @click="setType('kehulaiyuan', '未成交')" class="report-panel-select-option" :class="{ active: kehulaiyuan.type === '未成交' }">未成交</div>
                </div>
                <div class="report-panel-title bb">
                    <div style="display: flex; align-items: flex-end">
                        <span class="report-panel-title-text">公司来源</span>
                    </div>
                    <div class="icon-container">
                        <img src="@/assets/icon/refresh.png" />
                        <img src="@/assets/icon/close.png" />
                    </div>
                </div>
                <div ref="kehulaiyuan-dom" class="report-panel-chart-dom"></div>
            </div>
            <div class="report-panel" v-loading="yijianyueyonghu.loading">
                <div class="report-panel-title bb">
                    <div style="display: flex; align-items: flex-end">
                        <span class="report-panel-title-text">已签约用户</span>
                    </div>
                    <div class="icon-container">
                        <img src="@/assets/icon/refresh.png" />
                        <img src="@/assets/icon/close.png" />
                    </div>
                </div>
                <div class="report-panel-chart-dom">
                    <el-table class="custom-el-table" :data="yijianyueyonghu.tableData" height="calc(100% - 20px)">
                        <el-table-column label="#" prop="order" width="50px" align="center" />
                        <el-table-column label="客户" prop="customer" show-overflow-tooltip />
                        <el-table-column label="签约时间" prop="date" show-overflow-tooltip />
                        <el-table-column label="公司状态" prop="status" show-overflow-tooltip />
                        <el-table-column label="签约次数" prop="count" show-overflow-tooltip />
                    </el-table>
                </div>
            </div>
            <div class="report-panel" v-loading="dingdanshoukuanqingkuang.loading">
                <div class="report-panel-title bb">
                    <div style="display: flex; align-items: flex-end">
                        <span class="report-panel-title-text">订单收款情况</span>
                    </div>
                    <div class="icon-container">
                        <img src="@/assets/icon/refresh.png" />
                        <img src="@/assets/icon/close.png" />
                    </div>
                </div>
                <div class="report-panel-chart-dom">
                    <el-table class="custom-el-table" :data="dingdanshoukuanqingkuang.tableData" height="calc(100% - 20px)">
                        <el-table-column label="#" prop="order" width="50px" align="center" />
                        <el-table-column label="客户" prop="customer" show-overflow-tooltip />
                        <el-table-column label="款项名称" prop="type" show-overflow-tooltip />
                        <el-table-column label="应付款时间" prop="should" show-overflow-tooltip />
                        <el-table-column label="款项状态" prop="status" show-overflow-tooltip />
                        <el-table-column label="订单总额" prop="amount" show-overflow-tooltip />
                        <el-table-column label="已收款金额" prop="done" show-overflow-tooltip />
                    </el-table>
                </div>
            </div>
            <div class="report-panel" v-loading="dingdanzhichuqingkuang.loading">
                <div class="report-panel-title bb">
                    <div style="display: flex; align-items: flex-end">
                        <span class="report-panel-title-text">订单支出情况</span>
                    </div>
                    <div class="icon-container">
                        <img src="@/assets/icon/refresh.png" />
                        <img src="@/assets/icon/close.png" />
                    </div>
                </div>
                <div class="report-panel-chart-dom">
                    <el-table class="custom-el-table" :data="dingdanzhichuqingkuang.tableData" height="calc(100% - 20px)">
                        <el-table-column label="#" prop="order" width="50px" align="center" />
                        <el-table-column label="客户" prop="customer" show-overflow-tooltip />
                        <el-table-column label="款项名称" prop="type" show-overflow-tooltip />
                        <el-table-column label="应付款时间" prop="should" show-overflow-tooltip />
                        <el-table-column label="款项状态" prop="status" show-overflow-tooltip />
                        <el-table-column label="支出总额" prop="amount" show-overflow-tooltip />
                    </el-table>
                </div>
            </div>
            <div class="report-panel">
                <div class="report-panel-title bb">
                    <div style="display: flex; align-items: flex-end">
                        <span class="report-panel-title-text">月度销售情况</span>
                    </div>
                    <div class="icon-container">
                        <img src="@/assets/icon/refresh.png" />
                        <img src="@/assets/icon/close.png" />
                    </div>
                </div>
                <div class="report-panel-chart-dom">
                    <el-table class="custom-el-table" :data="yueduxiaoshouqingkuang.tableData" height="calc(100% - 20px)">
                        <el-table-column label="#" prop="order" width="50px" align="center" />
                        <el-table-column label="时间" prop="date" show-overflow-tooltip />
                        <el-table-column label="成单数" prop="deal" show-overflow-tooltip />
                        <el-table-column label="成单率" prop="rate" show-overflow-tooltip />
                        <el-table-column label="成单金额" prop="amount" show-overflow-tooltip />
                        <el-table-column label="平均销售周期" prop="cycle" show-overflow-tooltip />
                        <el-table-column label="报价转化率" prop="invert" show-overflow-tooltip />
                        <el-table-column label="报价平均停留时间" prop="stay" show-overflow-tooltip />
                    </el-table>
                </div>
            </div>
            <div class="report-panel" v-loading="genjinjilu.loading">
                <div class="report-panel-title bb">
                    <div style="display: flex; align-items: flex-end">
                        <span class="report-panel-title-text">跟进记录</span>
                    </div>
                    <div class="icon-container">
                        <img src="@/assets/icon/refresh.png" />
                        <img src="@/assets/icon/close.png" />
                    </div>
                </div>
                <div class="report-panel-chart-dom">
                    <el-table class="custom-el-table" :data="genjinjilu.tableData" height="calc(100% - 20px)">
                        <el-table-column label="#" prop="order" width="50px" align="center" />
                        <el-table-column label="客户" prop="customer" show-overflow-tooltip />
                        <el-table-column label="时间" prop="date" show-overflow-tooltip />
                        <el-table-column label="动态" prop="follow" show-overflow-tooltip />
                    </el-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as echarts from "echarts";

export default {
    chartInstance: {
        qushifenxi: null,
        xiaoshouloudou: null,
        kehulaiyuan: null,
    },
    data() {
        return {
            qushifenxi: {
                loading: false,
            },
            cardItem: {
                qianyuejianbao: {
                    day: [],
                    week: [],
                    month: [],
                },
                xianyoukehushu: 0,
            },
            xiaoshouloudou: {
                loading: false,
            },
            kehulaiyuan: {
                loading: false,
                type: "全部",
            },
            yijianyueyonghu: {
                loading: false,
                tableData: [],
            },
            dingdanshoukuanqingkuang: {
                loading: false,
                tableData: [],
            },
            dingdanzhichuqingkuang: {
                loading: false,
                tableData: [],
            },
            yueduxiaoshouqingkuang: {
                tableData: [
                    {
                        order: 1,
                        date: "2020年1月",
                        deal: "150",
                        rate: "150",
                        amount: "150",
                        cycle: "150",
                        invert: "20",
                        stay: "20",
                    },
                ],
            },
            genjinjilu: {
                loading: false,
                tableData: [],
            },
        };
    },
    watch: {
        "kehulaiyuan.type"() {
            this.getKehulaiyuan();
        },
    },
    methods: {
        setType(key, value) {
            this[key].type = value;
        },
        async getQushifenxi() {
            try {
                this.qushifenxi.loading = true;
                const res = await this.$axios({
                    method: "GET",
                    url: this.$customerUrl + "/Report/GetTrendAnalysis",
                });
                this.$options.chartInstance.qushifenxi.setOption({
                    xAxis: {
                        data: res.data.data,
                    },
                    series: res.data.series,
                });
            } catch {
                null;
            } finally {
                this.qushifenxi.loading = false;
            }
        },
        async getCardItemQianyuejianbao() {
            try {
                const res = await this.$axios({
                    method: "GET",
                    url: this.$customerUrl + "/Report/GetSigningBriefing",
                });
                const data = res.data;
                this.cardItem.qianyuejianbao.day = [
                    data.today.count,
                    data.today.orderAmount,
                ];
                this.cardItem.qianyuejianbao.week = [
                    data.thisWeek.count,
                    data.thisWeek.orderAmount,
                ];
                this.cardItem.qianyuejianbao.month = [
                    data.thisMonth.count,
                    data.thisMonth.orderAmount,
                ];
            } catch {
                null;
            }
        },
        async getCardItemXianyoukehushu() {
            try {
                const res = await this.$axios({
                    method: "GET",
                    url: this.$customerUrl + "/Report/GetExistingCustomersNum",
                });
                this.cardItem.xianyoukehushu = res.data;
            } catch {
                null;
            }
        },
        async getXiaoshouloudou() {
            try {
                const res = await this.$axios({
                    method: "GET",
                    url: this.$customerUrl + "/Report/GetSalesFunnels",
                });
                const data = res.data;
                this.$options.chartInstance.xiaoshouloudou.setOption({
                    series: [
                        {
                            name: "销售漏斗",
                            data: data.map((item) => ({
                                name: item.name,
                                value: item.count,
                            })),
                        },
                    ],
                });
            } catch {
                null;
            }
        },
        async getKehulaiyuan() {
            try {
                this.kehulaiyuan.loading = true;
                const res = await this.$axios({
                    method: "GET",
                    url: this.$customerUrl + "/Report/GetCustomerSource",
                    params: {
                        type: this.kehulaiyuan.type,
                    },
                });
                const data = res.data;
                this.$options.chartInstance.kehulaiyuan.setOption({
                    series: [
                        {
                            name: "公司来源",
                            data: data.map((item) => ({
                                name: item.name,
                                value: item.count,
                            })),
                        },
                    ],
                });
            } catch {
                null;
            } finally {
                this.kehulaiyuan.loading = false;
            }
        },
        async getYijianyueyonghu() {
            try {
                this.yijianyueyonghu.loading = true;
                const res = await this.$axios({
                    method: "GET",
                    url: this.$customerUrl + "/Report/GetContractedUser",
                });
                const data = res.data;
                this.yijianyueyonghu.tableData = data.map((item, index) => ({
                    order: index + 1,
                    customer: item.name,
                    date: item.lastOrderDate,
                    status: item.statuName,
                    count: item.count,
                }));
            } catch {
                null;
            } finally {
                this.yijianyueyonghu.loading = false;
            }
        },
        async getDingdanshoukuanqingkuang() {
            try {
                this.dingdanshoukuanqingkuang.loading = true;
                const res = await this.$axios({
                    method: "GET",
                    url: this.$customerUrl + "/Report/GetOrderCollection",
                });
                const data = res.data;
                this.dingdanshoukuanqingkuang.tableData = data.map(
                    (item, index) => ({
                        order: index + 1,
                        customer: item.customerName,
                        type: item.paymentName,
                        should: item.payerDate,
                        status: item.paymentStatus,
                        amount: item.orderAmount,
                        done: item.amountReceived,
                    })
                );
            } catch {
                null;
            } finally {
                this.dingdanshoukuanqingkuang.loading = false;
            }
        },
        async getDingdanzhichuqingkuang() {
            try {
                this.dingdanzhichuqingkuang.loading = true;
                const res = await this.$axios({
                    method: "GET",
                    url: this.$customerUrl + "/Report/FindOrderExpenditure",
                });
                const data = res.data;
                this.dingdanzhichuqingkuang.tableData = data.map(
                    (item, index) => ({
                        order: index + 1,
                        customer: item.customerName,
                        type: item.paymentName,
                        should: item.payerDate,
                        status: item.paymentStatus,
                        amount: item.orderAmount,
                    })
                );
            } catch {
                null;
            } finally {
                this.dingdanzhichuqingkuang.loading = false;
            }
        },
        async getGenjinjilu() {
            try {
                this.genjinjilu.loading = true;
                const res = await this.$axios({
                    method: "GET",
                    url: this.$customerUrl + "/Report/GetFollowRecord",
                });
                const data = res.data;
                this.genjinjilu.tableData = data.map((item, index) => ({
                    order: index + 1,
                    customer: item.name,
                    date: item.followTime,
                    follow: item.content,
                }));
            } catch {
                null;
            } finally {
                this.genjinjilu.loading = false;
            }
        },
    },
    mounted() {
        this.$options.chartInstance.qushifenxi = echarts.init(
            this.$refs["qushifenxi-dom"]
        );
        this.$options.chartInstance.qushifenxi.setOption({
            legend: {
                top: 5,
                right: 30,
                icon: "circle",
                itemWidth: 8,
                itemHeight: 8,
                itemStyle: {
                    opacity: 1,
                },
                itemGap: 50,
            },
            grid: {
                containLabel: true,
                top: 40,
                left: 30,
                right: 30,
                bottom: 20,
            },
            xAxis: {
                axisLine: {
                    lineStyle: {
                        color: "#BFBFBF",
                    },
                },
                axisTick: {
                    alignWithLabel: true,
                },
                axisLabel: {
                    color: "#595959",
                },
                data: [],
            },
            yAxis: {
                axisLabel: {
                    color: "#595959",
                },
                splitLine: {
                    lineStyle: {
                        color: "#E7E7E7",
                        type: "dashed",
                    },
                },
            },
            tooltip: {
                trigger: "axis",
            },
            series: [
                {
                    name: "支出",
                    type: "line",
                    itemStyle: {
                        opacity: 0,
                    },
                    data: [],
                },
                {
                    name: "收入",
                    type: "line",
                    itemStyle: {
                        opacity: 0,
                    },
                    data: [],
                },
                {
                    name: "客户数",
                    type: "line",
                    itemStyle: {
                        opacity: 0,
                    },
                    data: [],
                },
            ],
            color: ["#2E7DB5", "#79D6D9", "#D65F6E"],
        });
        this.$options.chartInstance.xiaoshouloudou = echarts.init(
            this.$refs["xiaoshouloudou-dom"]
        );
        this.$options.chartInstance.xiaoshouloudou.setOption({
            legend: {
                orient: "vertical",
                top: "center",
                right: 100,
                textStyle: {
                    color: "#7C90A5",
                },
                itemWidth: 14,
                itemHeight: 14,
                icon: "circle",
            },
            tooltip: {},
            series: [
                {
                    name: "销售漏斗",
                    type: "funnel",
                    left: 110,
                    right: 200,
                    label: {
                        show: true,
                        position: "inside",
                        color: "#fff",
                        fontWeight: "bold",
                        fontSize: 16,
                    },
                    sort: "none",
                    data: [],
                },
            ],
            color: [
                "#2560A3",
                "#8897C0",
                "#DC7683",
                "#DFD982",
                "#9BC586",
                "#67A0C8",
                "#62D0D3",
            ],
        });
        this.$options.chartInstance.kehulaiyuan = echarts.init(
            this.$refs["kehulaiyuan-dom"]
        );
        this.$options.chartInstance.kehulaiyuan.setOption({
            legend: {
                top: "middle",
                type: "scroll",
                orient: "vertical",
                right: "5%",
                icon: "circle",
                textStyle: {
                    color: "#7C90A5",
                },
                itemWidth: 14,
                itemHeight: 14,
            },
            tooltip: {},
            series: [
                {
                    name: "公司来源",
                    type: "pie",
                    radius: ["30%", "70%"],
                    center: ["40%", "50%"],
                    label: {
                        show: true,
                        color: "#94979C",
                    },
                    labelLine: {
                        smooth: true,
                        lineStyle: {
                            color: "#94979C",
                        },
                    },
                    emphasis: {
                        scale: false,
                    },
                    data: [],
                },
            ],
            color: [
                "#62D0D3",
                "#67A0C8",
                "#9BC586",
                "#DFD982",
                "#DC7683",
                "#8897C0",
                "#2560A3",
            ],
        });
        this.getQushifenxi();
        this.getCardItemQianyuejianbao();
        this.getCardItemXianyoukehushu();
        this.getXiaoshouloudou();
        this.getKehulaiyuan();
        this.getYijianyueyonghu();
        this.getDingdanshoukuanqingkuang();
        this.getDingdanzhichuqingkuang();
        this.getGenjinjilu();
    },
    beforeMount() {
        const token = this.$route.query.t;
        if (token) {
            localStorage.setItem("token", token);
            this.$cookieStore.setCookie("token", token, 24*7);
        }
    },
};
</script>

<style lang="scss" scoped>
.view {
    padding: 30px 20px 50px;
    background: #f9f9f9;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}
.top-grid-column-2 {
    display: grid;
    grid-template-columns: 1fr 310px;
    grid-auto-columns: auto;
    column-gap: 20px;
}
.report-panel {
    box-sizing: border-box;
    position: relative;
    height: 380px;
    background: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}
$report-panel-title-height: 42px;
.report-panel-title {
    box-sizing: border-box;
    height: $report-panel-title-height;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 14px 0 20px;
    &.bb {
        border-bottom: 1px solid #e6e7eb;
    }
}
.report-panel-title-text {
    font-size: 18px;
    color: #1b2841;
    font-weight: bold;
}
.report-panel-title-sub-text {
    font-size: 14px;
    color: #c7c7c7;
}
.icon-container {
    display: flex;
    align-items: center;
    img {
        cursor: pointer;
        &:last-child {
            margin-left: 10px;
        }
    }
}
.report-panel-select {
    position: absolute;
    z-index: 2;
    left: 20px;
    top: $report-panel-title-height;
    border: 1px solid #c7c7c7;
    border-radius: 2px;
    width: 80px;
    font-size: 14px;
    color: #7c90a5;
    cursor: pointer;
    background: #fff;
}
.report-panel-select-option {
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover,
    &.active {
        background: var(--themeColor);
        color: #fff;
    }
}
.report-panel-chart-dom {
    box-sizing: border-box;
    height: calc(100% - #{$report-panel-title-height});
}
.card-item {
    height: 120px;
    background: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    display: flex;
    align-items: center;
}
.card-item-title {
    color: #1b2841;
    font-weight: bold;
}
.main-grid-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .report-panel {
        width: calc(50% - 10px);
        margin-top: 20px;
    }
}
.custom-el-table {
    &.el-table::before {
        height: 0 !important;
        background: none !important;
    }
    font-size: 14px;
    ::v-deep .el-table__cell {
        height: 30px;
        padding: 0;
        border: none;
    }
    ::v-deep thead .el-table__cell {
        color: #3a3a3a;
        font-weight: normal;
    }
    ::v-deep tbody .el-table__cell {
        color: #1a2745;
        font-weight: normal;
    }
    ::v-deep th {
        border: none !important;
    }
    ::v-deep tbody tr {
        &:nth-child(odd) {
            background: #e3eaef;
        }
    }
}
</style>