<template>
<div style="display: flex;">
    <ClueNav @chooseNav="chooseNav" :currentPath="currentPath" />
    <div class="viewbox">
        <!-- <div class="header">
            <img class="logo" src="@/assets/cluePicture/logo-exhibition.png" alt="" />
            <div>
                <div class="theme">展会线索</div>
                <div class="txt">统计来自展会的相关线索</div>
            </div>
        </div> -->

        <div class="searchTerms">
            <!-- <div class="itemTerm">
                <div class="name">地区:</div>
                <el-radio-group v-model="region" size="mini">
                    <el-radio-button label="北京"></el-radio-button>
                    <el-radio-button label="上海"></el-radio-button>
                    <el-radio-button label="广州"></el-radio-button>
                    <el-radio-button label="深圳"></el-radio-button>
                    <el-radio-button label="重庆"></el-radio-button>
                </el-radio-group>
            </div> -->
            <!-- <div class="itemTerm">
                <div class="name">办展年份:</div>
                <el-radio-group v-model="exhibitionYear" style="line-height: 36px;">
                    <el-radio :label="0">不限</el-radio>
                    <el-radio :label="2021">2021年</el-radio>
                    <el-radio :label="2020">2020年</el-radio>
                    <el-radio :label="2019">2019年</el-radio>
                    <el-radio :label="5018">2018年</el-radio>
                </el-radio-group>
            </div> -->
            <!-- <div class="itemTerm">
                <div class="name">展商数量:</div>
                <el-radio-group v-model="exhibitionNum" style="line-height: 36px;">
                    <el-radio :label="0">不限</el-radio>
                    <el-radio :label="1">100家以内</el-radio>
                    <el-radio :label="2">100-500家</el-radio>
                    <el-radio :label="3">500家以上</el-radio>
                </el-radio-group>
            </div> -->
            <div class="itemTerm">
                <div class="name">展会类型:</div>
                <el-checkbox-group v-model="expoTypes" @change="onSearch">
                    <el-checkbox v-for="(item,index) in expoTypeOption" :key="index" :label="item"></el-checkbox>
                </el-checkbox-group>
            </div>
            <div class="itemTerm">
                <div class="name">展会界:</div>
                <el-checkbox-group v-model="classNumbers" @change="onSearch">
                    <el-checkbox v-for="(item,index) in classNumberOption" :key="index" :label="item"></el-checkbox>                  
                </el-checkbox-group>
            </div>
            <div class="itemTerm">
                <div class="name">高级筛选:</div>
                <div class="name">电话号码:</div>
                <el-checkbox v-model="isPhone" @change="onSearch">有</el-checkbox>
                <span style="padding-right:10px;color:#BABABA;">|</span>
                <div class="name">官网:</div>
                <el-checkbox v-model="isWebsite" @change="onSearch">有</el-checkbox>
                <span style="padding-right:10px;color:#BABABA;">|</span>
                <div class="name">邮箱:</div>
                <el-checkbox v-model="isEmail" @change="onSearch">有</el-checkbox>
            </div>
            <div class="searchBox" style="margin-top: 20px;">
                <el-input size="small" placeholder="查找展会公司" prefix-icon="el-icon-search" v-model="searchValue" style="width:600px;"></el-input>
                <el-button size="small" type="primary" style="margin-left:20px;" @click="onSearch">搜索</el-button>
            </div>
        </div>

        <div class="searchResult" v-show="totalItems!=0" v-loading="searchLoading">
            <div class="title">为您找到<span style="color:#FF2D2D;">{{totalItems}}</span>条相关结果</div>
            <div class="resultList">
                <div class="resultItem" v-for="(item,index) in resultList" :key="index">
                    <div style="margin-bottom: 8px;">
                        <div class="name">{{item.company}}</div>
                        <div class="country">
                            <img :src="item.flagUrl" alt="" />
                            <span>{{item.sourceCountry}}</span>
                        </div>
                    </div>
                    <div class="tagModule">
                        <div class="tag1" v-if="item.classNumber">{{item.classNumber}}</div>
                        <div class="tag2" v-if="item.source">{{item.source}}</div>
                    </div>
                    <div style="display:flex;margin-top: 8px;">
                        <div class="oneField">
                            <div class="color99">联系人</div>
                            <div class="color3a">{{item.contacts || "--"}}</div>
                        </div>
                        <div class="oneField">
                            <div class="color99">邮箱</div>
                            <div class="color3a">{{item.email || "--"}}</div>
                        </div>
                        <div class="oneField">
                            <div class="color99">手机号</div>
                            <div class="color3a">{{item.phone || "--"}}</div>
                        </div>
                        <div class="oneField">
                            <div class="color99">传真</div>
                            <div class="color3a">{{item.fax || "--"}}</div>
                        </div>
                        <div class="oneField">
                            <div class="color99">网址</div>
                            <div class="color3a">{{item.website || "--"}}</div>
                        </div>
                        <div class="oneField">
                            <div class="color99">邮政编码</div>
                            <div class="color3a">{{item.postcode || "--"}}</div>
                        </div>
                    </div>
                    <div class="twoField">
                        <span class="color99">主要市场：</span>
                        <span class="color3a">{{item.mainMarkets || "--"}}</span>
                    </div>
                    <div class="twoField">
                        <span class="color99">产品：</span>
                        <span class="color3a">{{item.product || "--"}}</span>
                    </div>
                    <div class="twoField">
                        <span class="color99">采购清单：</span>
                        <span class="color3a">{{item.purchaselist || "--"}}</span>
                    </div>
                    <div class="twoField">
                        <span class="color99">企业简介：</span>
                        <span class="color3a">{{item.companyProfile || "--"}}</span>
                    </div>
                    <div class="twoField">
                        <span class="color99">地址：</span>
                        <span class="color3a">{{item.address || "--"}}</span>
                    </div>
                    <div class="twoField">
                        <span class="color99">地区：</span>
                        <span class="color3a">{{item.region || "--"}}</span>
                    </div>
                    <!-- <div style="display: flex;background: #EEEEEE;line-height: 25px;">
                        <div class="threeField">
                            <span class="color99">展会专题：</span>
                            <span class="color3a">汽车</span>
                        </div>
                        <span style="color:#707070;">|</span>
                        <div class="threeField">
                            <span class="color99">开展时间：</span>
                            <span class="color3a">2020-01-01</span>
                        </div>
                        <span style="color:#707070;">|</span>
                        <div class="threeField">
                            <span class="color99">参展次数：</span>
                            <span class="color3a">1场</span>
                        </div>
                    </div> -->
                </div>
                <el-pagination background layout="total,sizes,prev,pager,next" :page-sizes="[10, 20, 40]" :page-size.sync="pageSize" @size-change="pageSizeChange" :total="totalItems" style="float:right;margin-top:15px;" :current-page.sync="pageIndex" @current-change="pageIndexChange"></el-pagination>
            </div>
        </div>

    </div>
</div>
</template>

<script>
import ClueNav from "@/components/ClueNav.vue";
export default {
    components: { ClueNav },
    props: {
        handleSelect: {
            type: Function,
            default: null,
        },
    },
    data() {
        return {
            currentPath: "/clue/exhibitionClue",
            //搜索条件
            // region: "上海",
            // exhibitionYear: 0,
            // exhibitionNum: 0,
            expoTypes: ['广交会'],//展会类型
            classNumbers: ['131届'],//展会界
            expoTypeOption: [],
            classNumberOption: [],
            isPhone: true,
            isWebsite: true,
            isEmail: true,
            searchLoading: false,
            searchValue: "",
            //搜索结果
            resultList: [],
            totalItems: 0,
            pageIndex: 1,
            pageSize: 10,
        };
    },
    methods: {
        chooseNav(index) {
            this.handleSelect(index);
            this.$router.push(index);
        },


        getResult(){
            if(this.searchLoading){ 
                return false;   //数据正在请求中,无法进行下一个请求;
            }
            //请求接口获取数据
            this.searchLoading = true;
            this.$axios.post(`${this.$clueUrl}/Expo/GetExpoCompany`,{
                "expoTypes": this.expoTypes,
                "classNumbers": this.classNumbers,
                "isPhone": this.isPhone,
                "isEmail": this.isEmail,
                "isWebsite": this.isWebsite,
                "company": this.searchValue,
                "pageIndex": this.pageIndex,
                "pageSize": this.pageSize,
            }).then((res) => {
                const { isSuccess, data } = res;
                this.searchLoading = false;
                if (isSuccess) {
                    this.totalItems = data.totalItems;
                    if(this.totalItems == 0){
                        this.$message({ message: '搜索无数据,请修改搜索条件重试!',type: 'warning' });
                        return false;
                    }
                    this.resultList = data.datas.map(item=>({
                        ...item,
                        flagUrl: window.getFlagUrlByCountry?window.getFlagUrlByCountry(item.sourceCountry):'',
                    }))
                }
            });
        },
        onSearch(){
            this.pageIndex = 1;
            this.getResult();
        },
        pageIndexChange(page) {
            this.pageIndex = page;
            if(this.pageIndex > 30){
                this.pageIndex = 30;
                this.$notify({ title: '警告',message: '目前线索模块处于免费试用阶段,搜索页码不能超过30!',type: 'warning' });
                return false;
            }
            this.getResult();
        },
        pageSizeChange() {
            this.pageIndex = 1;
            this.getResult();
        },

        getOption(){
            // 展会类型
            this.$axios.get(`${this.$clueUrl}/Expo/GetExpoTypes`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.expoTypeOption = data;
                }
            });
            // 展会界
            this.$axios.get(`${this.$clueUrl}/Expo/GetClassNumbers`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.classNumberOption = data;
                }
            });
        },
    },
    mounted() {
        this.getResult();
        this.getOption();
    },
};
</script>

<style lang="scss" scoped>
.viewbox{
    width: calc(100% - 200px);
    height: calc(100vh - 80px);
    box-sizing: border-box;
    overflow: auto;
    background: rgba($color: #FFFFFF, $alpha: 0.7);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    padding: 20px;
    .header{
        display: flex;
        padding-bottom: 20px;
        border-bottom: 1px solid #E1E1E1;
        .logo{
            padding: 0 15px;
        }
        .theme{
            font-size: 20px;
            line-height: 32px;
            font-weight: bold;
            color: #092338;
        }
        .txt{
            font-size: 14px;
            line-height: 20px;
            color: #092338;
        }
    }
    
    .searchTerms{
        margin-top: 20px;
        .itemTerm{
            display: flex;
            height: 26px;
            line-height: 26px;
            margin-bottom: 10px;
        }
        .name{
            width: 65px;
            font-size: 14px;
            color: #092338;
            text-align: right;
            margin-right: 15px;
        }
    }

    .searchResult{
        margin-top: 20px;
        .title{
            height: 40px;
            line-height: 40px;
            background: #F1F1F1;
            color: #3A3A3A;
            padding-left: 20px;
        }
        .resultList{
            padding: 20px;
            overflow: hidden;
            .resultItem{
                font-size: 14px;
                padding: 10px 0;
                border-bottom: 1px solid #E8E8E8;
                .name{
                    display: inline-block;
                    color: var(--themeColor);
                    // text-decoration: underline;
                    // cursor: pointer;
                }
                .country{
                    display: inline-block;
                    margin-left: 20px;
                    img{
                        vertical-align: middle;
                    }
                }
                .tagModule{
                    display: flex;
                    .tag1{
                        height: 20px;
                        line-height: 20px;
                        padding: 0 5px;
                        background: rgba(255, 170, 72, 0.2);
                        border-radius: 1px;
                        font-size: 12px;
                        color: #FFAA48;
                        margin-right: 10px;
                    }
                    .tag2{
                        height: 20px;
                        line-height: 20px;
                        padding: 0 5px;
                        background: rgba(255, 94, 126, 0.2);
                        border-radius: 1px;
                        font-size: 12px;
                        color: #FF5E7E;
                        margin-right: 10px;
                    }
                }
                .color99{    
                    color: #999999;
                }
                .color3a{    
                    color: #3A3A3A;
                }
                .oneField{
                    width: 150px;
                    text-align: center;
                }
                .twoField{
                    line-height: 28px;
                }
                .threeField{
                    width: 200px;
                    text-align: center;
                }
            }
        }
    }
}
</style>