<template>
  <!-- 使用 -->
  <div class="compete-analyse-new" id="compete-analyse" v-show="!hidden" v-loading="loading">
    <div class="title" ref="title">
      <p>企业图谱</p>
      <div style="display: flex; align-items: center">
        <img class="icon" src="@/firm/images/company/icon-expand.png" style="margin-left: 1em" @click="fullScreen" />
        <img class="icon" src="@/firm/images/company/icon-refresh.png" style="margin-left: 1em" @click="refresh" />
      </div>
    </div>
    <div class="content">
      <div ref="container" style="height: 600px"></div>
    </div>
    <el-dialog @close="destroyFullGraph" :visible.sync="fullscreen" top="0" width="100%" :close-on-press-escape="false" :close-on-click-modal="false">
      <div v-loading="loading" style="height: calc(100vh - 55px); overflow: hidden" ref="fullcontainer"></div>
    </el-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapMutations } from "vuex";
import axios from "@/firm/plugins/axios";
import { AnchorInditor } from "../utils";
import G6 from "@/firm/plugins/g6";
import { Message } from "@/firm/plugins/element";

let count = 1;

let graphPointer = null;
let fullscreenGraphPointer = null;

const configZoom = {
  fullZoom: 1.4,
  normalZoom: 1.2,
};

function initGraphOption(mockData, container, thisP, zoom) {
  const colors = {
    ROOT: "var(--themeColor, #3152AF)",
    同行: "#0E932E",
    竞争对手: "#FF2D2D",
    采购商: "#BB8862",
    供应商: "#E5694F",
  };

  //  组件props
  const props = {
    data: mockData,
    config: {
      padding: [20, 50],
      defaultLevel: 3,
      defaultZoom: 0.8,
      modes: { default: ["zoom-canvas", "drag-canvas"] },
    },
  };

  // 默认配置
  const defaultConfig = {
    modes: {
      default: ["zoom-canvas", "drag-canvas"],
    },
    fitView: true,
    animate: true,
    defaultNode: {
      type: "flow-rect-ikjngb34",
    },
    /* defaultEdge: {
          type: "cubic-horizontal",
           style: {
            stroke: "#CED4D9",
          }, 
        }, */
    layout: {
      type: "indented",
      direction: "H",
      dropCap: false,
      indent: 300,
      getHeight: () => {
        return 60;
      },
      getSide(node) {
        return node.data.direct;
      },
    },
  };

  // 自定义节点、边
  const registerFn = () => {
    /**
     * 自定义节点
     */
    G6.registerNode(
      "flow-rect-ikjngb34",
      {
        shapeType: "flow-rect-ikjngb34",
        draw(cfg, group) {
          const { name = "", variableName, variableValue, variableUp, label, collapsed, currency, status, rate } = cfg;
          const grey = "#CED4D9";
          // 逻辑不应该在这里判断
          const rectConfig = {
            width: 202,
            height: 70,
            lineWidth: 1,
            fontSize: 12,
            fill: "#fff",
            radius: 4,
            stroke: grey,
            opacity: 1,
          };

          const nodeOrigin = {
            x: -rectConfig.width / 2,
            y: -rectConfig.height / 2,
          };

          const textConfig = {
            textAlign: "left",
            textBaseline: "bottom",
          };

          const rect = group.addShape("rect", {
            attrs: {
              x: nodeOrigin.x,
              y: nodeOrigin.y,
              ...rectConfig,
            },
          });

          const rectBBox = rect.getBBox();

          // label title
          group.addShape("text", {
            attrs: {
              ...textConfig,
              x: 12 + nodeOrigin.x,
              y: 30 + nodeOrigin.y,
              //text: name.length > 28 ? name.substr(0, 28) + "..." : name,
              text: `${name.substr(0, 28)}\n${name.length > 56 ? name.substr(28, 56) + "..." : name.substr(28, 56)}`,
              fontSize: 12,
              opacity: 0.85,
              fill: "#000",
              cursor: "pointer",
            },
            name: "name-shape",
          });

          // price
          const price = group.addShape("text", {
            attrs: {
              ...textConfig,
              x: 12 + nodeOrigin.x,
              y: rectBBox.maxY - 12,
              text: label,
              fontSize: 16,
              fill: "#000",
              opacity: 0.85,
            },
          });

          // label currency
          group.addShape("text", {
            attrs: {
              ...textConfig,
              x: price.getBBox().maxX + 5,
              y: rectBBox.maxY - 12,
              text: currency,
              fontSize: 12,
              fill: "#000",
              opacity: 0.75,
            },
          });

          // percentage
          /* const percentText = group.addShape("text", {
              attrs: {
                ...textConfig,
                x: rectBBox.maxX - 8,
                y: rectBBox.maxY - 12,
                text: `${((variableValue || 0) * 100).toFixed(2)}%`,
                fontSize: 12,
                textAlign: "right",
                fill: colors[status],
              },
            }); */
          const percentText = group.addShape("text", {
            attrs: {
              ...textConfig,
              x: rectBBox.maxX - 8,
              y: rectBBox.maxY - 12,
              text: ``,
              fontSize: 12,
              textAlign: "right",
              fill: colors[status],
            },
          });

          // percentage triangle
          /* const symbol = variableUp ? "triangle" : "triangle-down";
            const triangle = group.addShape("marker", {
              attrs: {
                ...textConfig,
                x: percentText.getBBox().minX - 10,
                y: rectBBox.maxY - 12 - 6,
                symbol,
                r: 6,
                fill: colors[status],
              },
            }); */
          const symbol = variableUp ? "triangle" : "triangle-down";
          const triangle = group.addShape("marker", {
            attrs: {
              ...textConfig,
              x: percentText.getBBox().minX - 10,
              y: rectBBox.maxY - 12 - 6,
              symbol,
              r: 0,
              fill: colors[status],
            },
          });

          // variable name
          group.addShape("text", {
            attrs: {
              ...textConfig,
              x: triangle.getBBox().minX - 4,
              y: rectBBox.maxY - 12,
              text: variableName,
              fontSize: 12,
              textAlign: "right",
              fill: "#000",
              opacity: 0.45,
            },
          });

          // bottom line background
          const bottomBackRect = group.addShape("rect", {
            attrs: {
              x: nodeOrigin.x,
              y: rectBBox.maxY - 4,
              width: rectConfig.width,
              height: 4,
              radius: [0, 0, rectConfig.radius, rectConfig.radius],
              fill: "#E0DFE3",
            },
          });

          // bottom percent
          const bottomRect = group.addShape("rect", {
            attrs: {
              x: nodeOrigin.x,
              y: rectBBox.maxY - 4,
              width: (rate * rectBBox.width) / 100,
              height: 4,
              radius: [0, 0, 0, rectConfig.radius],
              fill: colors[status],
            },
          });

          // collapse rect
          /* if (cfg.children && cfg.children.length) {
              group.addShape("rect", {
                attrs: {
                  x: rectConfig.width / 2 - 8,
                  y: -8,
                  width: 16,
                  height: 16,
                  stroke: "rgba(0, 0, 0, 0.25)",
                  cursor: "pointer",
                  fill: "#fff",
                },
                name: "collapse-back",
                modelId: cfg.id,
              });

              // collpase text
              group.addShape("text", {
                attrs: {
                  x: rectConfig.width / 2,
                  y: -1,
                  textAlign: "center",
                  textBaseline: "middle",
                  text: collapsed ? "+" : "-",
                  fontSize: 16,
                  cursor: "pointer",
                  fill: "rgba(0, 0, 0, 0.25)",
                },
                name: "collapse-text",
                modelId: cfg.id,
              });
            } */
          if (cfg.children) {
            group.addShape("rect", {
              attrs: {
                x: cfg.direct === "left" ? -rectConfig.width / 2 - 8 : rectConfig.width / 2 - 8,
                y: -8,
                width: 16,
                height: 16,
                stroke: "rgba(0, 0, 0, 0.25)",
                cursor: "pointer",
                fill: "#fff",
              },
              name: "collapse-back",
              modelId: cfg.id,
            });

            // collpase text
            group.addShape("text", {
              attrs: {
                x: cfg.direct === "left" ? -rectConfig.width / 2 : rectConfig.width / 2,
                y: -1,
                textAlign: "center",
                textBaseline: "middle",
                text: collapsed ? "+" : "-",
                fontSize: 16,
                cursor: "pointer",
                fill: "rgba(0, 0, 0, 0.25)",
              },
              name: "collapse-text",
              modelId: cfg.id,
            });
          }

          this.drawLinkPoints(cfg, group);
          return rect;
        },
        update(cfg, item) {
          const group = item.getContainer();
          this.updateLinkPoints(cfg, group);
        },
        setState(name, value, item) {
          if (name === "collapse") {
            const group = item.getContainer();
            const collapseText = group.find((e) => e.get("name") === "collapse-text");
            if (collapseText) {
              if (!value) {
                collapseText.attr({
                  text: "-",
                });
              } else {
                collapseText.attr({
                  text: "+",
                });
              }
            }
          }
        },
        getAnchorPoints() {
          return [
            [0, 0.5],
            [1, 0.5],
          ];
        },
      },
      "rect"
    );

    G6.registerEdge(
      "flow-cubic-ikjngb34",
      {
        /* afterDraw(cfg, group) {
          // get the first shape in the graphics group of this edge, it is the path of the edge here
          // 获取图形组中的第一个图形，在这里就是边的路径图形
          const shape = group.get("children")[0];
          // get the coordinate of the mid point on the path
          // 获取路径图形的中点坐标
          const midPoint = shape.getPoint(0.5);
          const rectColor = cfg.color || "#333";
          // add a rect on the mid point of the path. note that the origin of a rect shape is on its lefttop
          // 在中点增加一个矩形，注意矩形的原点在其左上角
          group.addShape("rect", {
            attrs: {
              width: 10,
              height: 10,
              fill: rectColor || "#333",
              // x and y should be minus width / 2 and height / 2 respectively to translate the center of the rect to the midPoint
              // x 和 y 分别减去 width / 2 与 height / 2，使矩形中心在 midPoint 上
              x: midPoint.x - 5,
              y: midPoint.y - 5,
            },
          });
        },
        update: undefined, */
      },
      "cubic-horizontal"
    );
  };

  registerFn();

  const { data } = props;

  let graph = null;

  const initGraph = (data) => {
    if (!data) {
      return;
    }
    const { onInit, config } = props;
    const tooltip = new G6.Tooltip({
      // offsetX and offsetY include the padding of the parent container
      offsetX: 20,
      offsetY: 30,
      // the types of items that allow the tooltip show up
      // 允许出现 tooltip 的 item 类型
      itemTypes: ["node"],
      // custom the tooltip's content
      // 自定义 tooltip 内容
      getContent: (e) => {
        const outDiv = document.createElement("div");
        //outDiv.style.padding = '0px 0px 20px 0px';
        const nodeName = e.item.getModel().name;
        let formatedNodeName = "";
        for (let i = 0; i < nodeName.length; i++) {
          formatedNodeName = `${formatedNodeName}${nodeName[i]}`;
          if (i !== 0 && i % 20 === 0) formatedNodeName = `${formatedNodeName}<br/>`;
        }
        outDiv.innerHTML = `${formatedNodeName}`;
        return outDiv;
      },
      shouldBegin: (e) => {
        if (e.target.get("name") === "name-shape") return true;
        return false;
      },
    });
    graph = new G6.TreeGraph({
      container: container, //this.$refs["container"],
      ...defaultConfig,
      ...config,
      plugins: [tooltip],
    });
    if (typeof onInit === "function") {
      onInit(graph);
    }

    graph.edge(function (edge) {
      const targetId = edge.target;
      const targetNode = graph.findById(targetId);
      const nodeType = targetNode.getModel().status;
      if (nodeType === "供应商") {
        return {
          type: "flow-cubic-ikjngb34",
          color: "#66b1ff",
          /* style: {
            startArrow: {
              fill: "#66b1ff",
              path: "M9,0 L0,3 L0,-3 z",
            },
          }, */
          label: ">",
          labelCfg: {
            autoRotate: true,
            style: {
              fill: "#66b1ff",
            },
          },
        };
      } else if (nodeType === "采购商") {
        return {
          type: "flow-cubic-ikjngb34",
          color: "#66b1ff",
          /* style: {
            endArrow: {
              fill: "#66b1ff",
              path: "M0,0 L9,3 L9,-3 z",
            },
          }, */
          label: ">",
          labelCfg: {
            autoRotate: true,
            style: {
              fill: "#66b1ff",
            },
          },
        };
      } else if (nodeType === "同行") {
        return {
          type: "flow-cubic-ikjngb34",
          color: "#F56C6C",
          /* style: {
            endArrow: {
              fill: "#F56C6C",
              path: "M0,0 L9,3 L9,-3 z",
            },
          }, */
          label: "<",
          labelCfg: {
            autoRotate: true,
            style: {
              fill: "#F56C6C",
            },
          },
        };
      } else if (nodeType === "竞争对手") {
        return {
          type: "flow-cubic-ikjngb34",
          color: "#F56C6C",
          /* style: {
            startArrow: {
              fill: "#F56C6C",
              path: "M9,0 L0,3 L0,-3 z",
            },
          }, */
          label: "<",
          labelCfg: {
            autoRotate: true,
            style: {
              fill: "#F56C6C",
            },
          },
        };
      }
      return {
        type: "flow-cubic-ikjngb34",
        color: "#66b1ff",
      };
      /* if (nodeType === "同行" || nodeType === "竞争对手") {
        return {
          type: "flow-cubic-ikjngb34",
          color: "#F56C6C",
        };
      }
      return {
        type: "flow-cubic-ikjngb34",
        color: "#66b1ff",
        style: {
          endArrow: {
            fill: "#66b1ff",
            path: "M9,0 L0,3 L0,-3 z", //left
            //path: "M0,0 L9,3 L9,-3 z", //right
          },
        },
      }; */
    });

    graph.data(data);
    graph.render();
    graph.zoom(config.defaultZoom || 1);

    /* const handleCollapse = (e) => {
        const target = e.target;
        const id = target.get("modelId");
        const item = graph.findById(id);
        const nodeModel = item.getModel();
        nodeModel.collapsed = !nodeModel.collapsed;
        graph.layout();
        graph.setItemState(item, "collapse", nodeModel.collapsed);
      }; */
    const handleCollapse = async (e) => {
      const target = e.target;
      const id = target.get("modelId");
      const item = graph.findById(id);
      const nodeModel = item.getModel();
      const nodeType = nodeModel.status;
      if (nodeType === "ROOT") {
        nodeModel.collapsed = !nodeModel.collapsed;
        graph.layout();
        if (zoom) {
          graph.zoomTo(configZoom.fullZoom);
        } else {
          graph.zoomTo(configZoom.normalZoom);
        }
        graph.focusItem(id);
        graph.setItemState(item, "collapse", nodeModel.collapsed);
      } else if (nodeType === "采购商" || nodeType === "供应商") {
        if (nodeModel.children.length === 0 && nodeModel.collapsed) {
          thisP.loading = true;
          const children = await thisP.getChildren(nodeModel.name, nodeType, nodeModel.direct);
          thisP.loading = false;
          if (children.length !== 0) {
            graph.updateChildren(children, id);
            nodeModel.collapsed = !nodeModel.collapsed;
            graph.layout();
            if (zoom) {
              graph.zoomTo(configZoom.fullZoom);
            } else {
              graph.zoomTo(configZoom.normalZoom);
            }
            graph.focusItem(id);
            graph.setItemState(item, "collapse", nodeModel.collapsed);
          } else {
            if (nodeType === "供应商") {
              Message.warning("该供应商下无同行");
            } else if (nodeType === "采购商") {
              Message.warning("该采购商下无竞争对手");
            }
          }
        } else {
          nodeModel.collapsed = !nodeModel.collapsed;
          graph.layout();
          if (zoom) {
            graph.zoomTo(configZoom.fullZoom);
          } else {
            graph.zoomTo(configZoom.normalZoom);
          }
          graph.focusItem(id);
          graph.setItemState(item, "collapse", nodeModel.collapsed);
        }
      }
    };
    graph.on("collapse-text:click", (e) => {
      handleCollapse(e);
    });
    graph.on("collapse-back:click", (e) => {
      handleCollapse(e);
    });
  };

  initGraph(data);

  if (zoom) {
    graph.zoomTo(configZoom.fullZoom);
  } else {
    graph.zoomTo(configZoom.normalZoom);
  }

  graph.focusItem(mockData.id);

  return graph;
}

export default Vue.extend({
  data() {
    return {
      hidden: false,
      loading: false,
      fullscreen: false,
    };
  },
  computed: {
    ...mapState(["company"]),
  },
  methods: {
    ...mapMutations("anchor", {
      setAnchorFlag: "setAnchorFlag",
      setCurrent: "setCurrent",
    }),
    fullScreen() {
      this.fullscreen = true;
      this.$nextTick(() => {
        if (!fullscreenGraphPointer) {
          const mockData = graphPointer.save();
          fullscreenGraphPointer = initGraphOption(mockData, this.$refs["fullcontainer"], this, true);
        } else {
          const data = graphPointer.save();
          fullscreenGraphPointer.changeData(data);
          fullscreenGraphPointer.zoomTo(configZoom.fullZoom);
          fullscreenGraphPointer.focusItem(data.id);
        }
      });
    },
    destroyFullGraph() {
      const data = fullscreenGraphPointer.save();
      graphPointer.changeData(data);
      graphPointer.zoomTo(configZoom.normalZoom);
      graphPointer.focusItem(data.id);
    },
    async refresh() {
      try {
        this.loading = true;
        const mockData = await this.getData(this.company);
        graphPointer.changeData(mockData);
        graphPointer.zoomTo(configZoom.normalZoom);
        graphPointer.focusItem(mockData.id);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async getData(company) {
      try {
        const res = null
        /* const res = await axios({
          method: "get",
          url: "/api/Neo4j/CompanyMap",
          params: {
            company,
          },
        }); */
        const data = {
          id: `${new Date().getTime()}${Math.floor(Math.random() * 256)}${count++}${res.data.result.id}`,
          name: res.data.result.label,
          label: "",
          currency: "",
          rate: 100,
          status: "ROOT",
          variableName: "",
          children: [],
        };
        res.data.result.children.forEach((item) => {
          if (item.img.includes("buyer")) {
            data.children.push({
              id: `${new Date().getTime()}${Math.floor(Math.random() * 256)}${count++}${item.id}`,
              name: item.label,
              label: "采购商",
              currency: "",
              rate: item.rate,
              status: "采购商",
              variableName: `${item.text || "频次"}：${item.countNum}`,
              collapsed: true,
              direct: item.direct,
              children: [],
            });
          } else if (item.img.includes("vendor")) {
            data.children.push({
              id: `${new Date().getTime()}${Math.floor(Math.random() * 256)}${count++}${item.id}`,
              name: item.label,
              label: "供应商",
              currency: "",
              rate: item.rate,
              status: "供应商",
              variableName: `${item.text || "频次"}：${item.countNum}`,
              collapsed: true,
              direct: item.direct,
              children: [],
            });
          }
        });
        return Promise.resolve(data);
      } catch {
        return Promise.reject();
      }
    },
    async getChildren(company, parentType, direct) {
      try {
        const res = null
        /* const res = await axios({
          method: "get",
          url: "/api/Neo4j/CompanyMap",
          params: {
            company,
          },
        }); */
        if (parentType === "采购商") {
          const data = [];
          res.data.result.children.forEach((item) => {
            if (item.img.includes("compete")) {
              data.push({
                id: `${new Date().getTime()}${Math.floor(Math.random() * 256)}${count++}${item.id}`,
                name: item.label,
                label: "竞争对手",
                currency: "",
                rate: item.rate,
                status: "竞争对手",
                variableName: `${item.text || "频次"}：${item.countNum}`,
                collapsed: true,
                direct: direct,
                //children: [],
              });
            }
          });
          return Promise.resolve(data);
        } else if (parentType === "供应商") {
          const data = [];
          res.data.result.children.forEach((item) => {
            if (item.img.includes("peer")) {
              data.push({
                id: `${new Date().getTime()}${Math.floor(Math.random() * 256)}${count++}${item.id}`,
                name: item.label,
                label: "同行",
                currency: "",
                rate: item.rate,
                status: "同行",
                variableName: `${item.text || "频次"}：${item.countNum}`,
                collapsed: true,
                direct: direct,
                //children: [],
              });
            }
          });
          return Promise.resolve(data);
        }
        return Promise.resolve([]);
      } catch {
        return Promise.resolve([]);
      }
    },
  },
  async mounted() {
    try {
      new AnchorInditor(() => {
        this.setCurrent("JZDSXX");
      }, this.$refs["title"]);

      this.loading = true;
      const mockData = await this.getData(this.company);
      graphPointer = initGraphOption(mockData, this.$refs["container"], this, false);
      /* const mockData = {
      id: "g1",
      name: "TANJUNG  PRIOK", //公司名
      //count: 123456,
      label: "",
      currency: "",
      rate: 1.0,
      status: "ROOT",
      variableName: "",
      //variableValue: 0.341,
      //variableUp: false,
      children: [
        {
          id: "g2",
          name: "SOEKARNO-HATTA(U)",
          //count: 123456,
          label: "采购商",
          currency: "",
          rate: 1.0,
          status: "采购商",
          variableName: "100",
          children: [
            {
              id: "g34314",
              name: "HENAN FRIEND AGRICULTURAL",
              //count: 123456,
              label: "同行",
              currency: "",
              rate: 1.0,
              status: "同行",
              variableName: "120",
              children: [],
              //variableValue: 0.341,
              //variableUp: false,
            },
          ],
          //variableValue: 0.341,
          //variableUp: false,
        },
        {
          id: "g3",
          name: "TANJUNG EMAS",
          //count: 123456,
          label: "供应商",
          currency: "",
          rate: 1.0,
          status: "供应商",
          variableName: "200",
          children: [
            {
              id: "g34tg3g",
              name: "SHANGHAI WIN WIN INDUSTRIAL CO",
              //count: 123456,
              label: "同行",
              currency: "",
              rate: 1.0,
              status: "同行",
              variableName: "200",
              children: [],
              //variableValue: 0.341,
              //variableUp: false,
            },
          ],
          //variableValue: 0.341,
          //variableUp: false,
        },
      ],
    }; */
    } catch (e) {
      this.hidden = true;
      this.setAnchorFlag([["JZDSXX", false]]);
    } finally {
      this.loading = false;
    }
  },
});
</script>

<style lang="scss" scoped>
@import "@/firm/scss/mixin.scss";

.compete-analyse-new {
  margin-top: 1rem;
  background: #fff;
  padding: 1rem;
  padding-top: 0;
  @include mixin-panel-title;
}

::v-deep .el-dialog {
  margin: 0;
}

::v-deep .el-dialog__close {
  font-size: 50px;
}

::v-deep .el-dialog__header {
  padding: 0;
  height: 50px;
}

::v-deep .el-dialog__body {
  padding: 0;
}
</style>
