<template>
    <div>
        <InterfaceName :interfaceName="interfaceName" />
        <InterfaceSpe :requestUrl="requestUrl" :requestMethod="requestMethod" />
        <ReturnParam :returnObj="returnObj" />
    </div>
</template>

<script>
import InterfaceName from "../Acomponents/InterfaceName.vue";
import InterfaceSpe from "../Acomponents/InterfaceSpe.vue";
import ReturnParam from "../Acomponents/ReturnParam.vue";

export default {
    components: { InterfaceName, InterfaceSpe, ReturnParam },
    data() {
        return {
            interfaceName: "获取组织结构树",
            requestUrl: "https://crm.qixinerp.com/open/Staff/GetOrganization",
            requestMethod: "GET",
            returnObj: {},
        };
    },
    methods: {},
    mounted() {
        this.returnObj = {
            isSuccess: true,
            errorMessage: null,
            data: [
                {
                    id: 1005,
                    key: "80c6c41e49e04550807568379e26adc7",
                    name: "武汉技术部",
                    parentStr: "000002",
                    parentId: 0,
                    count: 1,
                    children: [
                        {
                            id: 1006,
                            key: "31b739fe51b841dda1abc99e62970b9d",
                            name: "前端开发",
                            parentStr: "000002000001",
                            parentId: 1005,
                            count: 5,
                            children: [],
                        },
                        {
                            id: 1007,
                            key: "ec1dc533d89d4591b418e5557401bb66",
                            name: "后端开发",
                            parentStr: "000002000002",
                            parentId: 1005,
                            count: 4,
                            children: [],
                        },
                        {
                            id: 1008,
                            key: "5729d5f99c7e48e7817c75a60e799880",
                            name: "UI设计",
                            parentStr: "000002000003",
                            parentId: 1005,
                            count: 1,
                            children: [],
                        },
                        {
                            id: 1009,
                            key: "39dee225753e412cb05e41a3b2a4cc31",
                            name: "测试部门",
                            parentStr: "000002000004",
                            parentId: 1005,
                            count: 2,
                            children: [],
                        },
                    ],
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>

</style>