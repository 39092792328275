<template>
  <div class="comment" v-loading="control.loading">
    <div class="top">
      <div>
        <span style="font-size: 18px; font-weight: 400; color: #333333">评论</span>
        <span style="font-size: 14px; font-weight: 400; color: #909090">(共{{ total }}条)</span>
      </div>
      <i @click="handleCloseClick" class="el-icon-close" style="font-size: 24px; cursor: pointer"></i>
    </div>
    <div class="middle" v-infinite-scroll="loadMore" :infinite-scroll-delay="500">
      <div class="comment-card" v-for="item of list" :key="item.key">
        <div style="padding: 0 12px 0 5px">
          <img style="width: 36px; height: 36px" :src="item.avatar" />
        </div>
        <div style="width: 100%">
          <div style="display: flex; justify-content: space-between; margin: 0 0 5px 0">
            <span style="font-size: 14px; color: #617b95">{{ item.name }}</span>
            <div style="display: flex; align-items: center">
              <img @click="handleFavorClick(item)" style="margin-right: 4px; cursor: pointer" :src="item.isFavor ? image.fContent : image.fEmpty" />
              <span style="font-size: 14px" :style="{ color: item.isFavor ? 'var(--themeColor, #3152AF)' : '#1D1D1D' }">{{ item.favor }}</span>
            </div>
          </div>
          <div style="font-size: 16px; color: #191919; text-align: justify; line-height: 18px">{{ item.comment }}</div>
          <div style="font-size: 12px; color: #909090; margin-top: 5px">{{ item.date }}</div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <el-input placeholder="写下您的评论~" v-model="form.comment" />
      <el-button @click="addComment" style="margin-left: 6px; border-color: #eeeeee; background: #eeeeee; color: #b7b7b7">发表评论</el-button>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Message } from "@/firm/plugins/element";
import { getUserAgentInfo } from "@/firm/utils/broswer";
import axios from "@/firm/plugins/axios";
import dayjs from "@/firm/plugins/dayjs";



export default Vue.extend({
  props: ["total"],
  model: {
    prop: "total",
    event: "change",
  },
  data() {
    return {
      control: {
        loading: false,
        pageIndex: 1,
        pageSize: 10,
      },
      image: {
        fEmpty: require("@/firm/images/company/new/f-empty.png"),
        fContent: require("@/firm/images/company/new/f-content.png"),
      },
      form: {
        comment: "",
      },
      list: []
    };
  },
  methods: {
    handleCloseClick() {
      this.$emit("close");
    },
    async loadMore() {
      if (this.total < this.control.pageSize) {
        return;
      }
      try {
        this.control.loading = true;
        const current = this.control.pageIndex;
        const data = await this.getCommentList(current + 1);
        if (data.length !== 0) {
          this.list.push(...data);
          this.control.pageIndex++;
        } else {
          Message.warning("已展示全部评论");
        }
      } catch {
        console.log("more error");
      } finally {
        this.control.loading = false;
      }
    },
    async getCommentList(pageIndex) {
      try {
        const res = null
        /* const res = await axios({
          method: "POST",
          url: "/search/Credit/Comment",
          data: {
            pageIndex,
            pageSize: this.control.pageSize,
          },
        }); */
        this.$emit("change", res.data.result.total);
        return res.data.result.datas.map((item) => ({
          avatar: item.imgurl,
          key: item.key,
          name: item.contact,
          isFavor: item.isLiked,
          favor: item.likeCount,
          comment: item.content,
          date: dayjs.tz(item.dateTime).format("YYYY-MM-DD HH:mm:ss"),
        }));
      } catch {
        return [];
      }
    },
    async addComment() {
      if (this.form.comment.trim() === "") {
        Message.warning("请输入评论内容!");
        return;
      }
      try {
        this.control.loading = true;
        const broserInfo = getUserAgentInfo();
        const res = await axios({
          method: "POST",
          url: "/search/Credit/Comment/Insert",
          data: {
            content: this.form.comment.trim(),
            browser: broserInfo[0].toString(),
            browserVersion: broserInfo[1].toString(),
          },
        });
        if (!res.data.result) {
          throw new Error();
        }
        //refresh list
        this.list = await this.getCommentList(1);
        this.control.pageIndex = 1;
        this.form.comment = "";
      } catch {
        Message.warning("发表评论失败");
      } finally {
        this.control.loading = false;
      }
    },
    async handleFavorClick(item) {
      const status = item.isFavor;
      const broserInfo = getUserAgentInfo();
      try {
        this.control.loading = true;
        const res = await axios({
          method: "POST",
          url: status ? "/search/Credit/Comment/NoLike" : "/search/Credit/Comment/Like",
          data: {
            commentKey: item.key,
            browser: broserInfo[0].toString(),
            browserVersion: broserInfo[1].toString(),
          },
        });
        if (res.data.result) {
          item.isFavor = !item.isFavor;
          if (item.isFavor) {
            item.favor++;
          } else {
            item.favor--;
          }
        } else {
          throw new Error();
        }
      } finally {
        this.control.loading = false;
      }
    },
  },
  async mounted() {
    this.list = await this.getCommentList(1);
  },
});
</script>

<style lang="scss" scoped>
$paddingH: 20px;

.comment {
  background: #fff;
  z-index: 9999;
  position: fixed;
  right: calc(((100vw - 1170px) / 2) + 20px);
  bottom: 10px;
  width: 396px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

.top {
  padding: 20px $paddingH;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eeeeee;
}

.middle {
  padding: 0 15px;
  height: 515px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
    background: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background: #e5e5e5;
  }

  scrollbar-width: thin;
  scrollbar-color: #e5e5e5 #fff;
}

.comment-card {
  border-bottom: 1px solid #d7d7d8;
  display: flex;
  padding-top: 18px;
  padding-bottom: 20px;
}

.bottom {
  padding: 0 $paddingH;
  padding-bottom: 27px;
  display: flex;
  justify-content: space-between;
}
</style>
