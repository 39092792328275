<template>
    <div>
        <div class="title">输入参数</div>
        <el-table class="table" :data="inputTableData" :header-cell-style="{'background-color':'#F2F2F2','color':'#3A3A3A'}">
            <el-table-column prop="paramName" label="参数名"></el-table-column>
            <el-table-column prop="valueType" label="值类型"></el-table-column>
            <el-table-column prop="explain" label="说明"></el-table-column>
            <el-table-column label="是否必填" width="120">
                <template slot-scope="scope">
                    {{ scope.row.required ? '是' : '否' }}
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
export default {
    props: ["inputTableData"], 
    data() {
        return {};
    },
    methods: {},
    mounted() {},
};
</script>

<style lang="scss" scoped>
.title {
    font-weight: bold;
    font-size: 20px;
    line-height: 40px;
    margin-top: 20px;
}
.table{
    border: 1px solid #d7d7d7;
}
</style>