<template>
    <div>
        <div class="headerBox" v-if="clueType=='customs'">
            <div style="display:flex;">
                <img src="@/assets/cluePicture/logo-commercial.png" alt="" />
                <!-- <div class="companyLogo">LOGO</div> -->
                <div style="margin-left:12px;">
                    <div class="companyName">
                        <span style="margin-right:30px;">{{companyName}}</span>
                        <img :src="clueInfo.flagUrl" style="margin-right:5px;height:14px" />
                        <span style="font-size:14px;">{{clueInfo.country}}</span>
                    </div>
                    <!-- <div class="tagModule">
                        <div class="tag1">零售商</div>
                        <div class="tag2">5个联系方式</div>
                    </div> -->
                </div>
            </div>
            <div style="display:flex;margin-top: 8px;">
                <div class="oneField">
                    <div class="color99">最新交易日期</div>
                    <div class="color3a">{{clueInfo.lastDate}}</div>
                </div>
                <div class="oneField">
                    <div class="color99">交易总次数</div>
                    <div class="color3a">{{clueInfo.totalNum}}</div>
                </div>
                <div class="oneField">
                    <div class="color99">交易国家数</div>
                    <div class="color3a">{{clueInfo.countryNum}}</div>
                </div>
                <div class="oneField">
                    <div class="color99">交易伙伴数</div>
                    <div class="color3a">{{clueInfo.partnerNum}}</div>
                </div>
            </div>
            <div class="twoField" v-if="clueInfo.hsCode.length != 0">
                <span class="color99">HS编码：</span>
                <span class="color3a" style="padding-right:10px;" v-for="(ele,index) in clueInfo.hsCode" :key="index" v-show="index<5">{{ele}}</span>
                <span class="primaryColor" style="margin-left:10px;" @click="toMoreCode">[查看更多]</span>
            </div>
            <div class="twoField" v-if="clueInfo.productDesc.length != 0">
                <span class="color99">产品描述：</span>
                <span class="color3a" v-html="clueInfo.productDesc[0]"></span>
                <span class="primaryColor" style="margin-left:10px;" @click="toMoreDes">[查看更多]</span>
            </div>
            <!-- <div class="hasAdd" v-if="clueInfo.isMyCustomer">已加入我的客户</div>
            <div class="addBtn" @click="addCustomer" v-else>
                <img src="@/assets/cluePicture/addCustomer.png" alt="" />加入我的客户
            </div> -->
        </div>
        <div class="headerBox" v-else-if="clueType=='commercial'">
            <div style="display:flex;">
                <img src="@/assets/cluePicture/logo-commercial.png" alt="" />
                <div style="margin-left:12px;">
                    <div class="companyName">
                        <span style="margin-right:30px;">{{companyName}}</span>
                        <img :src="clueInfo.flagUrl" style="margin-right:5px;height:14px" />
                        <span style="font-size:14px;">{{clueInfo.country}}</span>
                    </div>
                </div>
            </div>
            <div class="twoField">
                <span class="color99">联系电话：</span>
                <span class="color3a">{{clueInfo.phone}}</span>
            </div>
            <div class="twoField">
                <span class="color99">注册时间：</span>
                <span class="color3a">{{clueInfo.incorporationDateStr}}</span>
            </div>
            <div class="twoField">
                <span class="color99">企业官网：</span>
                <span class="primaryColor" @click="openWebsite(clueInfo.domain)">{{clueInfo.domain}}</span>
            </div>
            <div class="twoField">
                <span class="color99">公司地址：</span>
                <span class="color3a">{{clueInfo.address1}}</span>
            </div>
            <!-- <div class="hasAdd" v-if="clueInfo.isMyCustomer">已加入我的客户</div>
            <div class="addBtn" @click="addCustomer" v-else>
                <img src="@/assets/cluePicture/addCustomer.png" alt="" />加入我的客户
            </div> -->
            <div class="hasAdd" v-if="clueInfo.isMyClue">已加入我的线索</div>
            <div class="addBtn" @click="addMyClue(clueInfo,3)" :class="isDisable ? 'forbidden' :''" v-else>
                加入我的线索
            </div>
        </div>
        <div class="headerBox" v-else-if="clueType=='business'">
            <div style="display:flex;">
                <img src="@/assets/cluePicture/logo-commercial.png" alt="" />
                <div style="margin-left:12px;">
                    <div class="companyName">
                        <span style="margin-right:30px;">{{companyName}}</span>
                        <img :src="clueInfo.flagUrl" style="margin-right:5px;height:14px" />
                        <span style="font-size:14px;">{{clueInfo.country}}</span>
                    </div>
                </div>
            </div>
            <div class="twoField">
                <span class="color99">邮箱：</span>
                <span class="color3a">{{clueInfo.email}}</span>
            </div>
            <div class="twoField">
                <span class="color99">企业官网：</span>
                <span class="primaryColor" @click="openWebsite(clueInfo.website)">{{clueInfo.website}}</span>
            </div>
            <div class="twoField">
                <span class="color99">公司地址：</span>
                <span class="color3a">{{clueInfo.address}}</span>
            </div>
            <!-- <div class="hasAdd" v-if="clueInfo.isMyCustomer">已加入我的客户</div>
            <div class="addBtn" @click="addCustomer" v-else>
                <img src="@/assets/cluePicture/addCustomer.png" alt="" />加入我的客户
            </div> -->
            <div class="hasAdd" v-if="clueInfo.isMyClue">已加入我的线索</div>
            <div class="addBtn" @click="addMyClue(clueInfo,2)" :class="isDisable ? 'forbidden' :''" v-else>
                加入我的线索
            </div>
        </div>
        <div class="headerBox" v-else-if="clueType=='buyer'">
            <div style="display:flex;">
                <img src="@/assets/cluePicture/logo-commercial.png" alt="" />
                <div style="margin-left:12px;">
                    <div class="companyName">
                        <span style="margin-right:30px;">{{companyName}}</span>
                        <img :src="clueInfo.flagUrl" style="margin-right:5px;height:14px" />
                        <span style="font-size:14px;">{{clueInfo.country}}</span>
                    </div>
                </div>
            </div>
            <div class="twoField" v-show="clueInfo.tel">
                <span class="color99">电话：</span>
                <span class="color3a">{{clueInfo.tel}}</span>
            </div>
            <div class="twoField" v-show="clueInfo.fax">
                <span class="color99">传真：</span>
                <span class="color3a">{{clueInfo.fax}}</span>
            </div>
            <div class="twoField" v-show="clueInfo.website">
                <span class="color99">官网：</span>
                <span class="primaryColor" @click="openWebsite(clueInfo.website)">{{clueInfo.website}}</span>
            </div>
            <div class="twoField" v-show="clueInfo.address">
                <span class="color99">地址：</span>
                <span class="color3a">{{clueInfo.address}}</span>
            </div>
            <div class="twoField" v-show="clueInfo.hsCode">
                <span class="color99">HS编码：</span>
                <span class="color3a">{{clueInfo.hsCode}}</span>
            </div>
            <div class="twoField" v-show="clueInfo.productDesc">
                <span class="color99">产品描述：</span>
                <span class="color3a">{{clueInfo.productDesc}}</span>
            </div>
            <!-- <div class="hasAdd" v-if="clueInfo.isMyCustomer">已加入我的客户</div>
            <div class="addBtn" @click="addCustomer" v-else>
                <img src="@/assets/cluePicture/addCustomer.png" alt="" />加入我的客户
            </div> -->
        </div>
        <div class="headerBox" v-else-if="clueType=='wholeSearch'">
            <div style="display:flex;">
                <img src="@/assets/cluePicture/logo-commercial.png" alt="" />
                <div style="margin-left:12px;">
                    <div class="companyName">
                        <span style="margin-right:30px;">{{companyName}}</span>
                    </div>
                </div>
            </div>
            <div class="twoField">
                <span class="color99">联系人数量：</span>
                <span class="color3a">{{clueInfo.contactsNum}}</span>
            </div>
            <div class="twoField" v-show="clueInfo.title">
                <span class="color99">标题：</span>
                <span class="color3a">{{clueInfo.title}}</span>
            </div>
            <div class="twoField" v-show="clueInfo.site">
                <span class="color99">网站：</span>
                <span class="primaryColor" @click="openWebsite(clueInfo.site)">{{clueInfo.site}}</span>
            </div>
            <div class="twoField" v-show="clueInfo.description">
                <span class="color99">描述：</span>
                <span class="color3a">{{clueInfo.description}}</span>
            </div>
            <div class="hasAdd" v-if="clueInfo.isMyClue">已加入联系人线索</div>
            <div class="addBtn" @click="addContactsClue(clueInfo)" :class="isDisable ? 'forbidden' :''" v-else>
                加入联系人线索
            </div>
        </div>


        <div class="cardBox" v-if="clueType=='commercial'">
            <div class="title">商业数据</div>
            <BusinessData />
        </div>
        <div class="cardBox" v-else-if="clueType=='business'">
            <div class="title">工商信息({{country}})</div>
            <CommonInfo v-if="comCountryList.includes(country)" />
            <CostaRica v-else-if="country=='哥斯达黎加'" />
            <Ecuador v-else-if="country=='厄瓜多尔'" />
            <India v-else-if="country=='印度'" />
            <Korea v-else-if="country=='韩国'" />
            <Peru v-else-if="country=='秘鲁'" />
            <Russia v-else-if="country=='俄罗斯'" />
            <Vietnam v-else-if="country=='越南'" />
            <America v-else-if="country=='美国'" />
            <Kenya v-else-if="country=='肯尼亚'" />
            <el-empty v-else :image-size="100"></el-empty>
        </div>

        <div class="cardBox" v-show="marketAnalysis.length != 0 || productAnalysis.length != 0 || vendorAnalysis.length != 0">
            <div class="title">海关数据</div>
            <div class="sublevel">（1）市场分析 - 共 {{marketAnalysis.length}} 条</div>
            <el-table :data="marketAnalysis.slice(10*(this.marketPageIndex-1),10*this.marketPageIndex)" :header-cell-style="{'background-color':'#E5E9F2','color':'#1F2D3D'}">
                <el-table-column type="index" width="50" align="center"></el-table-column>
                <el-table-column prop="name" label="国家"></el-table-column>
                <el-table-column prop="frequency" label="频次"></el-table-column>
                <el-table-column prop="value" label="金额"></el-table-column>
                <el-table-column prop="quantity" label="数量"></el-table-column>
                <el-table-column prop="weight" label="重量"></el-table-column>
                <el-table-column prop="proportion" label="频次占比" width="320px">
                    <template slot-scope="scope">
                        <el-progress class="progress" color="#746B63" :show-text="false" :stroke-width="16" :percentage="scope.row.proportion*1"></el-progress>
                        <span>{{scope.row.proportion+"%"}}</span>
                    </template>
                </el-table-column>
            </el-table>
            <div style="overflow:hidden;">
                <el-pagination background layout="total,prev,pager,next" :total="marketAnalysis.length" style="float:right;margin-top:15px;" :current-page.sync="marketPageIndex"></el-pagination>
            </div>
            <div class="sublevel">（2）产品分析 - 共 {{productAnalysis.length}} 条</div>
            <el-table :data="productAnalysis.slice(10*(this.productPageIndex-1),10*this.productPageIndex)" :header-cell-style="{'background-color':'#E5E9F2','color':'#1F2D3D'}">
                <el-table-column type="index" width="50" align="center"></el-table-column>
                <el-table-column prop="name" label="HS编码"></el-table-column>
                <el-table-column prop="frequency" label="频次"></el-table-column>
                <el-table-column prop="value" label="金额"></el-table-column>
                <el-table-column prop="quantity" label="数量"></el-table-column>
                <el-table-column prop="weight" label="重量"></el-table-column>
                <el-table-column prop="proportion" label="频次占比" width="320px">
                    <template slot-scope="scope">
                        <el-progress class="progress" color="#E99019" :show-text="false" :stroke-width="16" :percentage="scope.row.proportion*1"></el-progress>
                        <span>{{scope.row.proportion+"%"}}</span>
                    </template>
                </el-table-column>
            </el-table>
            <div style="overflow:hidden;">
                <el-pagination background layout="total,prev,pager,next" :total="productAnalysis.length" style="float:right;margin-top:15px;" :current-page.sync="productPageIndex"></el-pagination>
            </div>
            <div class="sublevel">（3）供应商分析 - 共 {{vendorAnalysis.length}} 条</div>
            <el-table :data="vendorAnalysis.slice(10*(this.vendorPageIndex-1),10*this.vendorPageIndex)" :header-cell-style="{'background-color':'#E5E9F2','color':'#1F2D3D'}">
                <el-table-column type="index" width="50" align="center"></el-table-column>
                <el-table-column prop="name" label="供应商"></el-table-column>
                <el-table-column prop="frequency" label="频次"></el-table-column>
                <el-table-column prop="value" label="金额"></el-table-column>
                <el-table-column prop="quantity" label="数量"></el-table-column>
                <el-table-column prop="weight" label="重量"></el-table-column>
                <el-table-column prop="proportion" label="频次占比" width="320px">
                    <template slot-scope="scope">
                        <el-progress class="progress" color="#B28B7C" :show-text="false" :stroke-width="16" :percentage="scope.row.proportion*1"></el-progress>
                        <span>{{scope.row.proportion+"%"}}</span>
                    </template>
                </el-table-column>
            </el-table>
            <div style="overflow:hidden;">
                <el-pagination background layout="total,prev,pager,next" :total="vendorAnalysis.length" style="float:right;margin-top:15px;" :current-page.sync="vendorPageIndex"></el-pagination>
            </div>
        </div>

        <div class="cardBox" style="overflow:hidden;" v-loading="tableLoading">
            <div class="title">联系人信息</div>
            <el-empty v-if="contactTotal==0" :image-size="100"></el-empty>
            <el-table v-else :data="contactList" border :header-cell-style="{'background-color':'#F8FAFF','color':'#1F2D3D'}">
                <el-table-column type="index" label="序号" width="50px" align="center"></el-table-column>
                <!-- <el-table-column prop="companyName" label="公司名称"></el-table-column> -->
                <el-table-column prop="name" label="联系人"></el-table-column>
                <el-table-column prop="email" label="邮箱">
                    <template slot-scope="scope">
                        <span>{{scope.row.email}}</span>
                        <el-tooltip effect="dark" content="写邮件" placement="top-start">
                            <i class="el-icon-s-promotion" @click="sendMail(scope.row.email)" style="font-size:20px;cursor:pointer;padding-left:10px;color:var(--themeColor);"></i>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column prop="position" label="职位"></el-table-column>
            </el-table>
            <el-pagination v-if="contactTotal > 10" background layout="total,prev,pager,next" :total="contactTotal" style="float:right;margin-top:15px;" :current-page.sync="contactPageIndex" @current-change="contactPageChange"></el-pagination>
        </div>

        <!-- <div class="cardBox">
            <div class="title">参展信息</div>
            <el-empty v-if="exhibitionList.length==0" :image-size="100"></el-empty>
            <el-table v-else :data="exhibitionList" border :header-cell-style="{'background-color':'#F8FAFF','color':'#1F2D3D'}">
                <el-table-column prop="index" label="序号" width="50px" align="center"></el-table-column>
                <el-table-column prop="exhibitionName" label="展会名称"></el-table-column>
                <el-table-column prop="time" label="开展时间"></el-table-column>
                <el-table-column prop="pavilionName" label="展馆名称"></el-table-column>
                <el-table-column prop="address" label="展会地址"></el-table-column>
                <el-table-column prop="website" label="展会网址"></el-table-column>
                <el-table-column prop="organization" label="组织单位"></el-table-column>
                <el-table-column prop="contactInfo" label="联系方式"></el-table-column>
                <el-table-column prop="range" label="展品范围"></el-table-column>
            </el-table>
        </div> -->

        <!-- <div class="cardBox">
            <div class="title">招标投标</div>
            <el-empty v-if="tenderList.length==0" :image-size="100"></el-empty>
            <el-table v-else :data="tenderList" border :header-cell-style="{'background-color':'#F8FAFF','color':'#1F2D3D'}">
                <el-table-column prop="index" label="序号" width="50px" align="center"></el-table-column>
                <el-table-column prop="itemDes" label="项目描述"></el-table-column>
                <el-table-column prop="noticeType" label="公告类型"></el-table-column>
                <el-table-column prop="publishTime" label="发布时间"></el-table-column>
                <el-table-column prop="region" label="所在地区"></el-table-column>
                <el-table-column label="操作" width="100">
                     <template slot-scope="scope">
                        <el-button @click="handleClick(scope.row)" type="text" size="small">查看详情</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div> -->

        <!-- 查看更多(HS编码)(商品描述) -->
        <MoreCode v-if="moreCodeVisible" :viewKey="viewKey" :dataYear="dataYear" @close="moreCodeVisible = false;" />

        <MoreDes v-if="moreDesVisible" :viewKey="viewKey" :dataYear="dataYear" @close="moreDesVisible = false;" />

    </div>
</template>

<script>
import BusinessData from "./BusinessInfo/BusinessData.vue"
import CommonInfo from "./BusinessInfo/CommonInfo.vue";
import CostaRica from "./BusinessInfo/CostaRica.vue";   //哥斯达黎加
import Ecuador from "./BusinessInfo/Ecuador.vue";       //厄瓜多尔
import India from "./BusinessInfo/India.vue";           //印度
import Korea from "./BusinessInfo/Korea.vue";           //韩国
import Peru from "./BusinessInfo/Peru.vue";             //秘鲁
import Russia from "./BusinessInfo/Russia.vue";         //俄罗斯
import Vietnam from "./BusinessInfo/Vietnam.vue";       //越南
import America from "./BusinessInfo/America.vue";       //美国
import Kenya from "./BusinessInfo/Kenya.vue";           //肯尼亚

import MoreCode from "./MoreInfo/MoreCode.vue"
import MoreDes from "./MoreInfo/MoreDes.vue"

export default {
    components: { BusinessData,CommonInfo,CostaRica,Ecuador,India,Korea,Peru,Russia,Vietnam,America,Kenya, MoreCode , MoreDes },
    data() {
        return {
            companyName: "",    //查看的公司名称
            clueType: "",       //查看的线索类型
            clueInfo: {
                hsCode: [],
                productDesc: [],
            },
            comCountryList:["孟加拉","巴西","博茨瓦纳","喀麦隆","德国","埃塞俄比亚","印度尼西亚","哈萨克斯坦","利比里亚","墨西哥","尼日利亚","菲律宾","巴基斯坦","新加坡","西班牙","坦桑尼亚","乌干达","英国","意大利"],
            country: "",        //工商信息查看的国家
            

            //海关数据
            marketAnalysis: [],
            productAnalysis : [],
            vendorAnalysis: [],
            marketPageIndex: 1,
            productPageIndex: 1,
            vendorPageIndex: 1,
            //联系人信息
            urlParam: "",
            tableLoading: false,
            contactList: [],
            contactTotal: 0,
            contactPageIndex: 1,

            exhibitionList: [],
            tenderList: [],


            //查看更多(HS编码)(商品描述)
            moreCodeVisible: false,
            moreDesVisible: false,
            viewKey: "",
            dataYear: "1",

            isDisable: false,
        };
    },
    methods: {
        sendMail(mail) {
            window.open(
                `${process.env.VUE_APP_MAIL_BASE}#/index/editor?type=send&receiver=${mail}`,
                "_blank"
            );
        },

        //加入我的客户
        addCustomer(){
            const {company,country,website,domain,phone,address,address1} = this.clueInfo;
            let submitObj = {
                companyKey: JSON.parse(localStorage.userInfo).companyKey,
                userKey: JSON.parse(localStorage.userInfo).uKey,
                name: company,
                country: country,
                webSite: website ? website : domain ? domain : "",
                call: phone ? phone : "",
                address: address ? address : address1 ? address1 : "",
            }
            console.log(submitObj);
            this.$axios.post(`${this.$customerUrl}/Common/AddCustomer`, submitObj).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    this.$message({ message: '添加成功', type: 'success'});
                    this.clueInfo.isMyCustomer = true;
                }
            });
        },
        //加入我的线索
        addMyClue(item,dataType){
            if(this.isDisable){
                return false;
            }     
            let industrys = [];
            if(item.sicModels){
                item.sicModels.forEach(element =>{
                    industrys.push(`(${element.primaryCode})${element.sicDescription}`)
                })
            }
            let submitObj = {
                "clueSource": dataType,
                "clueRelationKey": item.key,
                "companyName": item.company,
                "country": item.country,
                "legalPerson": "",
                "contactNumber": "",
                "registrationTime": item.incorporationDateStr,
                "employeesNumber": 0,
                "officialWebsite": item.website ? item.website : item.domain,
                "corporateEmail": item.email,
                "businessAddress": item.address ? item.address : item.address1,
                "companyProfile": "",
                "industrys": industrys,
            }
            this.isDisable = true;
            this.$axios.post(`${this.$clueUrl}/MyClue/AddMyClue`, submitObj).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    this.isDisable = false;
                    this.$message({ message: '添加成功', type: 'success'});
                    this.clueInfo.isMyClue = true;
                }
            });
        },
        //加入联系人线索
        addContactsClue(item){
            if(this.isDisable){
                return false;
            }
            this.isDisable = true;
            this.$axios.post(`${this.$clueUrl}/MyClue/AddContactsClue`,[{
                "companyName": item.companyName,
                "domain": item.site,
            }]).then((res) => {
                const { isSuccess } = res;
                this.isDisable = false;
                if (isSuccess) {
                    this.$message({ message: '添加成功', type: 'success'});
                    item.isMyClue = true;
                }
            });
        },

        // 获取海关数据(市场分析,产品分析,供应商分析)
        getCustomsData(){
            // 先获取SearchKey
            this.$axios.get(`${this.$clueUrl}/BuyerLeads/GetSearchKey?company=${encodeURIComponent(this.companyName)}`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    let searchKey = data;
                    // 通过SearchKey获取 市场分析数据
                    this.$axios.get(`${this.$clueUrl}/BuyerLeads/GetCountryRanking?searchKey=${searchKey}`).then((res) => {
                        const { isSuccess, data } = res;
                        if (isSuccess) {
                            data.forEach(element => {
                                element.value = element.value.toFixed(2);
                                element.quantity = element.quantity.toFixed(2);
                                element.weight = element.weight.toFixed(2);
                            });
                            this.marketAnalysis = data;
                        }
                    });
                    // 通过SearchKey获取 产品分析数据
                    this.$axios.get(`${this.$clueUrl}/BuyerLeads/GetHsCodeRanking?searchKey=${searchKey}`).then((res) => {
                        const { isSuccess, data } = res;
                        if (isSuccess) {
                            data.forEach(element => {
                                element.value = element.value.toFixed(2);
                                element.quantity = element.quantity.toFixed(2);
                                element.weight = element.weight.toFixed(2);
                            });
                            this.productAnalysis = data;
                        }
                    });
                    // 通过SearchKey获取 供应商分析数据
                    this.$axios.get(`${this.$clueUrl}/BuyerLeads/GetImporterRanking?searchKey=${searchKey}`).then((res) => {
                        const { isSuccess, data } = res;
                        if (isSuccess) {
                            data.forEach(element => {
                                element.value = element.value.toFixed(2);
                                element.quantity = element.quantity.toFixed(2);
                                element.weight = element.weight.toFixed(2);
                            });
                            this.vendorAnalysis = data;
                        }
                    });
                }
            });
        },

        // 联系人信息
        getContactInfo(url){
            this.tableLoading = true;
            this.urlParam = url;
            this.$axios.post(`${this.$clueUrl}/BuyerLeads/GetContact`,{
                url: this.urlParam,
                pageIndex: this.contactPageIndex >= 50 ? 50 : this.contactPageIndex,
                pageSize: 10,
            }).then((res) => {
                const { isSuccess, data } = res;
                this.tableLoading = false;
                if (isSuccess) {
                    this.contactList = data.ts;
                    this.contactTotal = data.totalCount >= 500 ? 500 : data.totalCount;
                }
            });
        },
        getContactTwo(url){
            this.tableLoading = true;
            this.urlParam = url;
            this.$axios.post(`${this.$clueUrl}/GoogleSearch/GetRootDomainContacts`,{
                domain: this.urlParam,
                pageIndex: this.contactPageIndex >= 50 ? 50 : this.contactPageIndex,
                pageSize: 10,
            }).then((res) => {
                const { isSuccess, data } = res;
                this.tableLoading = false;
                if (isSuccess) {
                    this.contactList = data.datas;
                    this.contactTotal = data.totalItems >= 500 ? 500 : data.totalItems;
                }
            });
        },
        contactPageChange(page){
            this.contactPageIndex = page;
            if(this.clueType=='wholeSearch'){
                this.getContactTwo(this.urlParam);
            }else{
                this.getContactInfo(this.urlParam);
            }
        },

        // 参展信息
        getExhibitionInfo(){
            this.exhibitionList = [
                {
                    index: 1,
                    exhibitionName: "BUS EXPO 2022上海国际客车展",
                    time: "2022-12-20",
                    pavilionName: "上海新国际博览中心",
                    address: "上海市浦东新区龙阳路234号",
                    website: "www.gvyfbdf.com",
                    organization: "中国土木工程学会城市公共交通分会",
                    contactInfo: "15430456460",
                    range: "整车： 节能与新能源车、公交客车、旅游客车、公路客运客车...",
                },
                {
                    index: 2,
                    exhibitionName: "BUS EXPO 2022上海国际客车展",
                    time: "2022-12-20",
                    pavilionName: "上海新国际博览中心",
                    address: "上海市浦东新区龙阳路234号",
                    website: "www.gvyfbdf.com",
                    organization: "中国土木工程学会城市公共交通分会",
                    contactInfo: "15430456460",
                    range: "整车： 节能与新能源车、公交客车、旅游客车、公路客运客车...",
                },
            ]
        },
        // 招标投标
        getTenderInfo(){
            this.tenderList = [
                {
                    index: 1,
                    itemDes: "市直机关公务用车管理平台运行管理服务",
                    noticeType: "其他",
                    publishTime: "2021-12-30",
                    region: "福建省",
                },
                {
                    index: 2,
                    itemDes: "市直机关公务用车管理平台运行管理服务",
                    noticeType: "其他",
                    publishTime: "2021-12-30",
                    region: "福建省",
                },
                {
                    index: 3,
                    itemDes: "市直机关公务用车管理平台运行管理服务",
                    noticeType: "其他",
                    publishTime: "2021-12-30",
                    region: "福建省",
                },
            ]
        },

        // 通过线索类型获取对应的线索信息
        getInfoByType(){
            this.clueType = this.$route.query.clueType;
            if(this.clueType=='customs'){
                document.title = "线索详情--海关数据"
                //海关数据
                let key = this.$route.query.key;
                let year = this.$route.query.year;
                this.$axios.get(`${this.$clueUrl}/ClickHouse/GetCustomsDataDetail?key=${key}&year=${year}`).then((res) => {
                    const { isSuccess, data } = res;
                    if (isSuccess) {
                        data.flagUrl = window.getFlagUrlByCountry?window.getFlagUrlByCountry(data.country):'';
                        this.clueInfo = data;
                    }
                });
            }
            if(this.clueType=='commercial'){
                document.title = "线索详情--商业数据"
                //商业数据
                let key = this.$route.query.key;
                this.$axios.get(`${this.$clueUrl}/BusinessData/GetBusinessDataDetail?key=${key}`).then((res) => {
                    const { isSuccess, data } = res;
                    if (isSuccess) {
                        data.flagUrl = window.getFlagUrlByCountry?window.getFlagUrlByCountry(data.country):'';
                        this.clueInfo = data;
                        // 网址获取联系人信息
                        if(data.domain){
                            this.getContactInfo(data.domain);
                        }
                    }
                });
            }
            if(this.clueType=='business'){
                document.title = "线索详情--工商信息"
                //工商信息
                let key = this.$route.query.key;
                let country = this.$route.query.country;
                this.$axios.get(`${this.$clueUrl}/BuyerLeads/GetBusinessInformationDetail?key=${key}&country=${country}`).then((res) => {
                    const { isSuccess, data } = res;
                    if (isSuccess) {
                        data.flagUrl = window.getFlagUrlByCountry?window.getFlagUrlByCountry(data.country):'';
                        this.clueInfo = data;
                        // 网址获取联系人信息
                        if(data.website){
                            this.getContactInfo(data.website);
                        }
                    }
                });
                this.country = country;
            }
            if(this.clueType=='buyer'){
                document.title = "线索详情--采购商"
                //采购商
                let key = this.$route.query.key;
                this.$axios.get(`${this.$clueUrl}/BuyerLeads/GetBuyerDetail?key=${key}&company=${this.companyName}`).then((res) => {
                    const { isSuccess, data } = res;
                    if (isSuccess) {
                        data.flagUrl = window.getFlagUrlByCountry?window.getFlagUrlByCountry(data.country.trim()):'';
                        this.clueInfo = data;
                        // 网址获取联系人信息
                        if(data.website){
                            this.getContactInfo(data.website);
                        }
                    }
                });
            }
            if(this.clueType=='wholeSearch'){
                document.title = "线索详情--全网搜索"
                //全网搜索
                this.clueInfo = JSON.parse(localStorage.getItem('wholeInfo'));
                console.log(this.clueInfo);
                // 根据域名获取联系人
                this.getContactTwo(this.clueInfo.site);
            }
        },

        //打开网址
        openWebsite(url){
            let jumpUrl = url.indexOf("http") > -1 ? url : "http://" + url;
            let routeData = this.$router.resolve({
                path: "/jumpWeb",
                query: { jumpUrl: jumpUrl },
            });
            window.open(routeData.href, "_blank");
        },

        //查看更多(HS编码)(商品描述)
        toMoreCode(){
            this.moreCodeVisible = true;
            this.viewKey = this.$route.query.key;
            this.dataYear = this.$route.query.year;
        },
        toMoreDes(){
            this.moreDesVisible = true;
            this.viewKey = this.$route.query.key;
            this.dataYear = this.$route.query.year;
        },
    },
    mounted() {
        this.companyName = this.$route.query.companyName;

        this.getInfoByType();   // 通过线索类型获取对应的线索信息
        this.getCustomsData();  // 获取海关数据

        // this.getContactInfo();
        // this.getExhibitionInfo();
        // this.getTenderInfo();
    },
};
</script>

<style lang="scss" scoped>
.headerBox {
    background: #ffffff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    padding: 20px;
    font-size: 14px;
    position: relative;
    .companyLogo{
        width: 40px;
        height: 40px;
        background: #84A4CD;
        border-radius: 4px;
        padding: 12px;
        font-size: 20px;
        line-height: 20px;
        color: #FFFFFF;
    }
    .companyName {
        font-size: 18px;
        line-height: 36px;
        color: #3A3A3A;
    }
    .tagModule {
        display: flex;
        .tag1 {
            height: 20px;
            line-height: 20px;
            padding: 0 5px;
            background: rgba(255, 170, 72, 0.2);
            border-radius: 1px;
            font-size: 12px;
            color: #ffaa48;
            margin-right: 10px;
        }
        .tag2 {
            height: 20px;
            line-height: 20px;
            padding: 0 5px;
            background: rgba(255, 94, 126, 0.2);
            border-radius: 1px;
            font-size: 12px;
            color: #ff5e7e;
            margin-right: 10px;
        }
    }
    .color99 {
        color: #999999;
    }
    .color3a {
        color: #3a3a3a;
    }
    .primaryColor{
        color: var(--themeColor);
        cursor: pointer;
    }
    .oneField {
        width: 200px;
        text-align: center;
    }
    .twoField {
        line-height: 28px;
    }
    .addBtn{
        position: absolute;
        top: 20px;
        right: 20px;
        padding: 0 10px;
        height: 28px;
        line-height: 28px;
        font-size: 14px;
        color: var(--themeColor);
        background: rgba(183, 183, 183, 0.2);
        border: 1px solid var(--themeColor);
        border-radius: 4px;
        cursor: pointer;
        &:hover{
            background: var(--themeColor);
            color: #FFFFFF;
        }
        img{
            position: absolute;
            left: 10px;
            top: 5px;
        }
    }
    .forbidden{
        cursor: not-allowed;
    }
    .hasAdd{
        position: absolute;
        top: 20px;
        right: 20px;
        padding: 0 10px;
        height: 28px;
        line-height: 28px;
        font-size: 14px;
        color: #B7B7B7;
        background: rgba(183, 183, 183, 0.2);
        border: 1px solid #B7B7B7;
        border-radius: 4px;
    }
}

.cardBox{
    background: #ffffff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    padding: 15px 20px;
    margin-top: 20px;
    .title{   
        font-size: 20px;
        height: 20px;
        line-height: 20px;
        font-weight: bold;
        color: #3A3A3A;
        padding-left: 8px;
        border-left: 3px solid var(--themeColor);
        margin-bottom: 15px;
    }
    .sublevel{
        margin-top: 20px;
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 20px;
        font-weight: bold;
        color: #3A3A3A;
    }
    .progress{
        width: 240px;
        display: inline-block;
    }
}

/deep/.el-dialog__body {
    padding: 10px 20px;
}
</style>