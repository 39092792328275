// firm.html已使用
import axios from 'axios'
import qs from './qs'
import { MessageBox } from 'element-ui';
import { delCookie } from '@/assets/js/cookie'

const http = axios.create({
  baseURL: 'https://crm.qixinerp.com/'
})

http.interceptors.request.use(config => {
  //console.log(window.location.href)

  //console.log(ttt)

  let ttt = ''
  try {
    const index = window.location.href.lastIndexOf('?')
    ttt = qs.parse(window.location.href.slice(index), { ignoreQueryPrefix: true })['Token'] || '';
  } catch {
    null
  }

  let token = localStorage.getItem('token')
  if (token) { // 判断是否存在token，如果存在的话，则每个http header都加上token
    config.headers.Authorization = 'Bearer ' + token;
  }

  (config.headers)['AccessToken'] = ttt

  //(config.headers)['AccessToken'] = qs.parse(window.location.search, { ignoreQueryPrefix: true })['Token'] || ''
  return config
})

http.interceptors.response.use(function (response) {
  try {
    if (['用户信息已修改，请重新登录', '很抱歉，您无权限访问', '用户已在其他地方登录，请重新登录'].includes(response.data.errorMessage)) {
      MessageBox({
        closeOnClickModal: false,
        showClose: false,
        title: '登录失效',
        message: '登录信息失效，请重新登录',
        confirmButtonText: '确认',
        callback: () => {
          window.location.href = '/' //跳回登录页
        },
      });
      localStorage.removeItem("token");
      localStorage.removeItem("userInfo");
      delCookie("token");
      count = 1;
    }
  } catch {
    null
  } finally {
    return {
      ...response,
      data: {
        successful: true,
        code: 200,
        result: response.data.data,
        message: {}
      }
    }
  }
}, function (error) {
  return Promise.reject(error)
})

export default http
