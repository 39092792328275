<template>
    <div> 
        <div class="header">
            <div class="title">我的文件夹</div>
            <div class="describe">管理您的个人文件夹</div>
            <el-button class="addBtn" @click="toNew" size="small" type="primary" icon="el-icon-plus">新建</el-button>
        </div>
        <el-table :data="FolderList" :header-cell-style="{'background-color':'#F3F3F3','color':'#3A3A3A'}" :row-style="rowStyle">
            <el-table-column prop="name" label="文件夹名称"></el-table-column>
            <el-table-column prop="createDate" label="创建时间"></el-table-column>
            <el-table-column label="操作" width="200">
                <template slot-scope="scope">
                    <div>
                        <div class="handleEditBtn" @click="toEdit(scope.row)">编辑</div>
                        <div class="handleDelBtn" @click="toDel(scope.row)">删除</div>
                    </div>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="500px">
            <el-input v-model="submitForm.name" size="small" placeholder="文件夹名称"></el-input>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" @click="onSubmit" :loading="btnLoading">确 认</el-button>
                <el-button size="small" @click="dialogVisible = false">取 消</el-button>
             </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            FolderList: [],
            dialogVisible: false,
            dialogTitle: "",
            submitForm: {},
            btnLoading: false,
        };
    },
    methods: {
        rowStyle({rowIndex}){
            // 表格隔行换色
            if(rowIndex % 2 == 1){
                return {'background-color':'#F9FAFC'}
            }
        },

        getList(){
            this.$axios.get(`${this.$mailUrl}/v1/config/Folder`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.FolderList = data;
                }
            });
        },
        toNew(){
            this.dialogTitle = "添加文件夹";
            this.dialogVisible = true;
            this.submitForm = {
                key: "",
                name: "",
            };
        },
        toEdit(row){
            this.dialogTitle = "编辑文件夹";
            this.dialogVisible = true;
            this.submitForm = {
                key: row.key,
                name: row.name,
            };
        },
        onSubmit(){
            if(!this.submitForm.name){
                this.$message({ message: '请输入文件夹名称', type: 'warning'});
                return false;
            }
            this.btnLoading = true;
            this.$axios.post(`${this.$mailUrl}/v1/config/Folder`,this.submitForm).then((res) => {
                const { isSuccess } = res;
                this.btnLoading = false;
                if (isSuccess) {
                    this.dialogVisible = false;
                    this.$message({ message: '提交成功', type: 'success'});
                    this.getList();
                }
            });
        },
        toDel(row){
            this.$confirm(`是否删除该文件夹：<strong style='color:var(--themeColor);'>${row.name}</strong>?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                dangerouslyUseHTMLString: true
            }).then(() => {
                this.$axios.delete(`${this.$mailUrl}/v1/config/Folder?key=${row.key}`).then(res => {
                    const {isSuccess} = res;
                    if(isSuccess){
                        this.$message({type: 'success',message: '删除成功!'});
                        this.getList();
                    }
                });
            }).catch(() => {});
        }
    },
    mounted() {
        this.getList();
    },
};
</script>

<style lang="scss" scoped>
.header{
    margin-bottom: 20px;
    overflow: hidden;
    height: 32px;
    line-height: 32px;
    .title{
        float: left;
        font-size: 16px;
        color: #333;
        font-weight: bold;
    }
    .describe{
        float: left;
        margin-left: 20px;
        font-size: 12px;
        color: #708090;
    }
    .addBtn{
        float: right;
    }
}
</style>