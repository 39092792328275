<template>
    <div>
        <div class="filterBox">
            <span style="flex-shrink: 0">时间：</span>
            <el-select v-model="filterYear" placeholder="年份" size="small" style="width:100px;" @change="onFilterAll">
                <el-option label="2021年" value="2021"></el-option>
                <el-option label="2022年" value="2022"></el-option>
                <el-option label="2023年" value="2023"></el-option>
            </el-select>
            <el-select v-model="filterMonth" placeholder="月份" size="small" style="width:100px;" @change="onFilterAll" clearable>
                <el-option label="整年" value=""></el-option>
                <el-option label="1月" value="1"></el-option>
                <el-option label="2月" value="2"></el-option>
                <el-option label="3月" value="3"></el-option>
                <el-option label="4月" value="4"></el-option>
                <el-option label="5月" value="5"></el-option>
                <el-option label="6月" value="6"></el-option>
                <el-option label="7月" value="7"></el-option>
                <el-option label="8月" value="8"></el-option>
                <el-option label="9月" value="9"></el-option>
                <el-option label="10月" value="10"></el-option>
                <el-option label="11月" value="11"></el-option>
                <el-option label="12月" value="12"></el-option>
            </el-select>
			<span style="flex-shrink: 0; margin-left: 20px;">组织：</span>
            <el-cascader class="cascader" :show-all-levels="false" v-model="belongValue" :options="belongOptions" :props="{ value: 'key', label: 'name', checkStrictly: true }" size="small" @change="onFilterAll" style="width:150px;" />
		</div>
        <div class="statBox" v-loading="statLoading">
            <div class="statItem">
                <div class="label">采购频次</div>
                <div class="value">{{statDataForm.frequency}}</div>
                <div class="ratio">
                    <el-tooltip effect="dark" placement="bottom">
                        <div slot="content">同比:{{statDataForm.yoyFrequency}}</div>
                        <div>
                            <span style="color: #9C9C9C;">同比:</span>
                            <span>{{statDataForm.yoyFrequencyRate == "-" ? "-" : statDataForm.yoyFrequencyRate + "%"}}</span>
                            <i class="el-icon-caret-top" v-if="statDataForm.yoyFrequencyRate > 0"></i>
                            <i class="el-icon-caret-bottom" v-if="statDataForm.yoyFrequencyRate < 0"></i>
                        </div>
                    </el-tooltip>
                    <span style="padding: 0 5px;"></span>
                    <el-tooltip effect="dark" placement="bottom">
                        <div slot="content">环比:{{statDataForm.qoqFrequency}}</div>
                        <div>
                            <span style="color: #9C9C9C;">环比:</span>
                            <span>{{statDataForm.qoqFrequencyRate == "-" ? "-" : statDataForm.qoqFrequencyRate + "%"}}</span>
                            <i class="el-icon-caret-top" v-if="statDataForm.qoqFrequencyRate > 0"></i>
                            <i class="el-icon-caret-bottom" v-if="statDataForm.qoqFrequencyRate < 0"></i>
                        </div>
                    </el-tooltip>
                </div>
            </div>
            <div class="statItem">
                <div class="label">采购金额</div>
                <div class="value">{{statDataForm.amount}}</div>
                <div class="ratio">
                    <el-tooltip effect="dark" placement="bottom">
                        <div slot="content">同比:{{statDataForm.yoyAmount}}</div>
                        <div>
                            <span style="color: #9C9C9C;">同比:</span>
                            <span>{{statDataForm.yoyAmountRate == "-" ? "-" : statDataForm.yoyAmountRate + "%"}}</span>
                            <i class="el-icon-caret-top" v-if="statDataForm.yoyAmountRate > 0"></i>
                            <i class="el-icon-caret-bottom" v-if="statDataForm.yoyAmountRate < 0"></i>
                        </div>
                    </el-tooltip>
                    <span style="padding: 0 5px;"></span>
                    <el-tooltip effect="dark" placement="bottom">
                        <div slot="content">环比:{{statDataForm.qoqAmount}}</div>
                        <div>
                            <span style="color: #9C9C9C;">环比:</span>
                            <span>{{statDataForm.qoqAmountRate == "-" ? "-" : statDataForm.qoqAmountRate + "%"}}</span>
                            <i class="el-icon-caret-top" v-if="statDataForm.qoqAmountRate > 0"></i>
                            <i class="el-icon-caret-bottom" v-if="statDataForm.qoqAmountRate < 0"></i>
                        </div>
                    </el-tooltip>
                </div>
            </div>
            <div class="statItem">
                <div class="label">采购总数</div>
                <div class="value">{{statDataForm.num}}</div>
                <div class="ratio">
                    <el-tooltip effect="dark" placement="bottom">
                        <div slot="content">同比:{{statDataForm.yoyNum}}</div>
                        <div>
                            <span style="color: #9C9C9C;">同比:</span>
                            <span>{{statDataForm.yoyNumRate == "-" ? "-" : statDataForm.yoyNumRate + "%"}}</span>
                            <i class="el-icon-caret-top" v-if="statDataForm.yoyNumRate > 0"></i>
                            <i class="el-icon-caret-bottom" v-if="statDataForm.yoyNumRate < 0"></i>
                        </div>
                    </el-tooltip>
                    <span style="padding: 0 5px;"></span>
                    <el-tooltip effect="dark" placement="bottom">
                        <div slot="content">环比:{{statDataForm.qoqNum}}</div>
                        <div>
                            <span style="color: #9C9C9C;">环比:</span>
                            <span>{{statDataForm.qoqNumRate == "-" ? "-" : statDataForm.qoqNumRate + "%"}}</span>
                            <i class="el-icon-caret-top" v-if="statDataForm.qoqNumRate > 0"></i>
                            <i class="el-icon-caret-bottom" v-if="statDataForm.qoqNumRate < 0"></i>
                        </div>
                    </el-tooltip>
                </div>
            </div>
            <div class="statItem">
                <div class="label">商品品类</div>
                <div class="value">{{statDataForm.productTypeNum}}</div>
                <div class="ratio">
                    <el-tooltip effect="dark" placement="bottom">
                        <div slot="content">同比:{{statDataForm.yoyProductTypeNum}}</div>
                        <div>
                            <span style="color: #9C9C9C;">同比:</span>
                            <span>{{statDataForm.yoyProductTypeNumRate == "-" ? "-" : statDataForm.yoyProductTypeNumRate + "%"}}</span>
                            <i class="el-icon-caret-top" v-if="statDataForm.yoyProductTypeNumRate > 0"></i>
                            <i class="el-icon-caret-bottom" v-if="statDataForm.yoyProductTypeNumRate < 0"></i>
                        </div>
                    </el-tooltip>
                    <span style="padding: 0 5px;"></span>
                    <el-tooltip effect="dark" placement="bottom">
                        <div slot="content">环比:{{statDataForm.qoqProductTypeNum}}</div>
                        <div>
                            <span style="color: #9C9C9C;">环比:</span>
                            <span>{{statDataForm.qoqProductTypeNumRate == "-" ? "-" : statDataForm.qoqProductTypeNumRate + "%"}}</span>
                            <i class="el-icon-caret-top" v-if="statDataForm.qoqProductTypeNumRate > 0"></i>
                            <i class="el-icon-caret-bottom" v-if="statDataForm.qoqProductTypeNumRate < 0"></i>
                        </div>
                    </el-tooltip>
                </div>
            </div>
            <div class="statItem">
                <div class="label">供应商数量</div>
                <div class="value">{{statDataForm.purchaserNum}}</div>
                <div class="ratio">
                    <el-tooltip effect="dark" placement="bottom">
                        <div slot="content">同比:{{statDataForm.yoyPurchaserNum}}</div>
                        <div>
                            <span style="color: #9C9C9C;">同比:</span>
                            <span>{{statDataForm.yoyPurchaserNumRate == "-" ? "-" : statDataForm.yoyPurchaserNumRate + "%"}}</span>
                            <i class="el-icon-caret-top" v-if="statDataForm.yoyPurchaserNumRate > 0"></i>
                            <i class="el-icon-caret-bottom" v-if="statDataForm.yoyPurchaserNumRate < 0"></i>
                        </div>
                    </el-tooltip>
                    <span style="padding: 0 5px;"></span>
                    <el-tooltip effect="dark" placement="bottom">
                        <div slot="content">环比:{{statDataForm.qoqPurchaserNum}}</div>
                        <div>
                            <span style="color: #9C9C9C;">环比:</span>
                            <span>{{statDataForm.qoqPurchaserNumRate == "-" ? "-" : statDataForm.qoqPurchaserNumRate + "%"}}</span>
                            <i class="el-icon-caret-top" v-if="statDataForm.qoqPurchaserNumRate > 0"></i>
                            <i class="el-icon-caret-bottom" v-if="statDataForm.qoqPurchaserNumRate < 0"></i>
                        </div>
                    </el-tooltip>
                </div>
            </div>
        </div>
        <div class="reportBox">
            <div class="reportItem">
                <div class="reportTitle">
                    <div style="font-size: 18px;color: #1b2841;font-weight: bold;">供应商排名</div>
                    <div style="display: flex;">
                        <img src="@/assets/icon/refresh.png" style="cursor: pointer;" />
                        <img src="@/assets/icon/close.png" style="margin-left: 10px;cursor: pointer;" />
                    </div>
                </div>
                <div v-loading="purchaserLoading">
                    <div class="filterItem">
                        <span style="flex-shrink: 0">供应商：</span>
                        <el-select v-model="filterFormOne.manufacturerKey" size="small" style="width:150px;" filterable clearable @change="onFilterOne">
                            <el-option v-for="item in onePurchaserOption" :key="item.key" :value="item.key" :label="item.value"></el-option>
                        </el-select>
                        <span style="flex-shrink: 0; margin-left: 20px;">组织：</span>
                        <el-cascader class="cascader" :show-all-levels="false" v-model="filterFormOne.orgValue" :options="belongOptions" :props="{ value: 'key', label: 'name', checkStrictly: true }" size="small" @change="onFilterOne" style="width:150px;" />
                        <span style="flex-shrink: 0; margin-left: 20px;">时间：</span>
                        <el-select v-model="filterFormOne.year" placeholder="年份" size="small" style="width:100px;" @change="onFilterOne">
                            <el-option label="2021年" value="2021"></el-option>
                            <el-option label="2022年" value="2022"></el-option>
                            <el-option label="2023年" value="2023"></el-option>
                        </el-select>
                        <el-select v-model="filterFormOne.month" placeholder="月份" size="small" style="width:100px;" @change="onFilterOne" clearable>
                            <el-option label="整年" value=""></el-option>
                            <el-option label="1月" value="1"></el-option>
                            <el-option label="2月" value="2"></el-option>
                            <el-option label="3月" value="3"></el-option>
                            <el-option label="4月" value="4"></el-option>
                            <el-option label="5月" value="5"></el-option>
                            <el-option label="6月" value="6"></el-option>
                            <el-option label="7月" value="7"></el-option>
                            <el-option label="8月" value="8"></el-option>
                            <el-option label="9月" value="9"></el-option>
                            <el-option label="10月" value="10"></el-option>
                            <el-option label="11月" value="11"></el-option>
                            <el-option label="12月" value="12"></el-option>
                        </el-select>
                    </div>
                    <el-table :data="purchaserRanking" :header-cell-style="headerCellStyle" size="small" height="285" @expand-change="expandChangeOne">
                        <el-table-column label="公司名称" prop="manufacturerName" show-overflow-tooltip />
                        <el-table-column label="采购金额" prop="totalPrice" show-overflow-tooltip />
                        <el-table-column label="采购频次" prop="count" show-overflow-tooltip />
                        <el-table-column label="采购量" prop="totalNum" show-overflow-tooltip />
                        <el-table-column label="均单价" prop="avgPrice" show-overflow-tooltip />
                        <el-table-column type="expand" width="50px">
                            <template slot-scope="props">
                                <div v-loading="props.row.loading" v-for="(ele,index) in props.row.expandList" :key="index" class="expandStyle">
                                    <div class="expandItem">{{ele.productName}}</div>
                                    <div class="expandItem">{{ele.totalPrice}}</div>
                                    <div class="expandItem">{{ele.count}}</div>
                                    <div class="expandItem">{{ele.totalNum}}</div>
                                    <div class="expandItem">{{ele.avgPrice}}</div>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination hide-on-single-page background layout="total,prev,pager,next" :page-size="5" :total="purchaserTotal" style="float:right;margin:10px 20px;" :current-page.sync="filterFormOne.pageIndex" @current-change="getPurchaser"></el-pagination>
                </div>
            </div>
            <div class="reportItem">
                <div class="reportTitle">
                    <div style="font-size: 18px;color: #1b2841;font-weight: bold;">采购产品排名</div>
                    <div style="display: flex;">
                        <img src="@/assets/icon/refresh.png" style="cursor: pointer;" />
                        <img src="@/assets/icon/close.png" style="margin-left: 10px;cursor: pointer;" />
                    </div>
                </div>
                <div v-loading="productLoading">
                    <div class="filterItem">
                        <span style="flex-shrink: 0">产品：</span>
                        <el-select v-model="filterFormTwo.productKey" size="small" style="width:150px;" filterable clearable @change="onFilterTwo">
                            <el-option v-for="item in twoProductOption" :key="item.key" :value="item.key" :label="item.value"></el-option>
                        </el-select>
                        <span style="flex-shrink: 0; margin-left: 20px;">组织：</span>
                        <el-cascader class="cascader" :show-all-levels="false" v-model="filterFormTwo.orgValue" :options="belongOptions" :props="{ value: 'key', label: 'name', checkStrictly: true }" size="small" @change="onFilterTwo" style="width:150px;" />
                        <span style="flex-shrink: 0; margin-left: 20px;">时间：</span>
                        <el-select v-model="filterFormTwo.year" placeholder="年份" size="small" style="width:100px;" @change="onFilterTwo">
                            <el-option label="2021年" value="2021"></el-option>
                            <el-option label="2022年" value="2022"></el-option>
                            <el-option label="2023年" value="2023"></el-option>
                        </el-select>
                        <el-select v-model="filterFormTwo.month" placeholder="月份" size="small" style="width:100px;" @change="onFilterTwo" clearable>
                            <el-option label="整年" value=""></el-option>
                            <el-option label="1月" value="1"></el-option>
                            <el-option label="2月" value="2"></el-option>
                            <el-option label="3月" value="3"></el-option>
                            <el-option label="4月" value="4"></el-option>
                            <el-option label="5月" value="5"></el-option>
                            <el-option label="6月" value="6"></el-option>
                            <el-option label="7月" value="7"></el-option>
                            <el-option label="8月" value="8"></el-option>
                            <el-option label="9月" value="9"></el-option>
                            <el-option label="10月" value="10"></el-option>
                            <el-option label="11月" value="11"></el-option>
                            <el-option label="12月" value="12"></el-option>
                        </el-select>
                    </div>
                    <el-table :data="productRanking" :header-cell-style="headerCellStyle" size="small" height="285" @expand-change="expandChangeTwo">
                        <el-table-column label="产品" prop="productName" show-overflow-tooltip />
                        <el-table-column label="采购金额" prop="totalPrice" show-overflow-tooltip />
                        <el-table-column label="采购频次" prop="count" show-overflow-tooltip />
                        <el-table-column label="采购量" prop="totalNum" show-overflow-tooltip />
                        <el-table-column label="均单价" prop="avgPrice" show-overflow-tooltip />
                        <el-table-column type="expand" width="50px">
                            <template slot-scope="props">
                                <div v-loading="props.row.loading" v-for="(ele,index) in props.row.expandList" :key="index" class="expandStyle">
                                    <div class="expandItem">{{ele.manufacturerName}}</div>
                                    <div class="expandItem">{{ele.totalPrice}}</div>
                                    <div class="expandItem">{{ele.count}}</div>
                                    <div class="expandItem">{{ele.totalNum}}</div>
                                    <div class="expandItem">{{ele.avgPrice}}</div>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination hide-on-single-page background layout="total,prev,pager,next" :page-size="5" :total="productTotal" style="float:right;margin:10px 20px;" :current-page.sync="filterFormTwo.pageIndex" @current-change="getProduct"></el-pagination>
                </div>
            </div>
            <div class="reportItem">
                <div class="reportTitle">
                    <div style="font-size: 18px;color: #1b2841;font-weight: bold;">采购周期</div>
                    <div style="display: flex;">
                        <img src="@/assets/icon/refresh.png" style="cursor: pointer;" />
                        <img src="@/assets/icon/close.png" style="margin-left: 10px;cursor: pointer;" />
                    </div>
                </div>
                <div v-loading="periodLoading">
                    <div class="filterItem">
                        <span style="flex-shrink: 0">供应商：</span>
                        <el-select v-model="filterFormThree.manufacturerKey" size="small" style="width:150px;" filterable clearable @change="onFilterThree">
                            <el-option v-for="item in threePurchaserOption" :key="item.key" :value="item.key" :label="item.value"></el-option>
                        </el-select>
                        <span style="flex-shrink: 0; margin-left: 20px;">组织：</span>
                        <el-cascader class="cascader" :show-all-levels="false" v-model="filterFormThree.orgValue" :options="belongOptions" :props="{ value: 'key', label: 'name', checkStrictly: true }" size="small" @change="onFilterThree" style="width:150px;" />
                        <span style="flex-shrink: 0; margin-left: 20px;">时间：</span>
                        <el-select v-model="filterFormThree.year" placeholder="年份" size="small" style="width:100px;" @change="onFilterThree">
                            <el-option label="2021年" value="2021"></el-option>
                            <el-option label="2022年" value="2022"></el-option>
                            <el-option label="2023年" value="2023"></el-option>
                        </el-select>
                    </div>
                    <div id="purchasingCycle"></div>
                </div>
            </div>
            <div class="reportItem">
                <div class="reportTitle">
                    <div style="font-size: 18px;color: #1b2841;font-weight: bold;">联系人排名</div>
                    <div style="display: flex;">
                        <img src="@/assets/icon/refresh.png" style="cursor: pointer;" />
                        <img src="@/assets/icon/close.png" style="margin-left: 10px;cursor: pointer;" />
                    </div>
                </div>
                <div v-loading="contactLoading">
                    <div class="filterItem">
                        <span style="flex-shrink: 0">供应商：</span>
                        <el-select v-model="filterFormFour.manufacturerKey" size="small" style="width:150px;" filterable clearable @change="onFilterFour">
                            <el-option v-for="item in fourPurchaserOption" :key="item.key" :value="item.key" :label="item.value"></el-option>
                        </el-select>
                        <span style="flex-shrink: 0; margin-left: 20px;">组织：</span>
                        <el-cascader class="cascader" :show-all-levels="false" v-model="filterFormFour.orgValue" :options="belongOptions" :props="{ value: 'key', label: 'name', checkStrictly: true }" size="small" @change="onFilterFour" style="width:150px;" />
                        <span style="flex-shrink: 0; margin-left: 20px;">时间：</span>
                        <el-select v-model="filterFormFour.year" placeholder="年份" size="small" style="width:100px;" @change="onFilterFour">
                            <el-option label="2021年" value="2021"></el-option>
                            <el-option label="2022年" value="2022"></el-option>
                            <el-option label="2023年" value="2023"></el-option>
                        </el-select>
                        <el-select v-model="filterFormFour.month" placeholder="月份" size="small" style="width:100px;" @change="onFilterFour" clearable>
                            <el-option label="整年" value=""></el-option>
                            <el-option label="1月" value="1"></el-option>
                            <el-option label="2月" value="2"></el-option>
                            <el-option label="3月" value="3"></el-option>
                            <el-option label="4月" value="4"></el-option>
                            <el-option label="5月" value="5"></el-option>
                            <el-option label="6月" value="6"></el-option>
                            <el-option label="7月" value="7"></el-option>
                            <el-option label="8月" value="8"></el-option>
                            <el-option label="9月" value="9"></el-option>
                            <el-option label="10月" value="10"></el-option>
                            <el-option label="11月" value="11"></el-option>
                            <el-option label="12月" value="12"></el-option>
                        </el-select>
                    </div>
                    <div class="filterItem">
                        <span style="flex-shrink: 0;">金额范围：</span>
                        <el-input-number v-model="filterFormFour.moneyMin" :min="0" size="small" :controls="false"></el-input-number>
                        <span style="padding:0 5px;">-</span>
                        <el-input-number v-model="filterFormFour.moneyMax" :min="0" size="small" :controls="false"></el-input-number>
                    </div>
                    <el-table :data="contactRanking" :header-cell-style="headerCellStyle" size="small" height="260">
                        <el-table-column label="联系人" prop="contactsName" show-overflow-tooltip />
                        <el-table-column label="供应商" prop="manufacturerName" show-overflow-tooltip />
                        <el-table-column label="采购金额" prop="totalPrice" show-overflow-tooltip />
                        <el-table-column label="最近采购日期" prop="latestPurchaseDate" show-overflow-tooltip />
                        <el-table-column label="均单价" prop="avgPrice" show-overflow-tooltip />
                    </el-table>
                    <el-pagination hide-on-single-page background layout="total,prev,pager,next" :page-size="5" :total="contactTotal" style="float:right;margin:5px 20px;" :current-page.sync="filterFormFour.pageIndex" @current-change="getContact"></el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
    data() {
        return {
            headerCellStyle:{'background-color':'#F1F4F8','color':'#3A3A3A'},
            //日期筛选
            filterYear: "2023",
            filterMonth: "",
            //组织筛选
            belongValue: [""],
            belongOptions: [],
            //统计数据
            statLoading: false,
            statDataForm:{},
            //供应商排名
            filterFormOne:{
                manufacturerKey: "",
                orgValue: [""],
                year: "2023",
                month: "",
                pageIndex: 1,
                pageSize: 5,
            },
            purchaserLoading: false,
            purchaserRanking: [],
            purchaserTotal: 0,
            //采购产品排名
            filterFormTwo:{
                productKey: "",
                orgValue: [""],
                year: "2023",
                month: "",
                pageIndex: 1,
                pageSize: 5,
            },
            productLoading: false,
            productRanking: [],
            productTotal: 0,
            //采购周期
            filterFormThree:{
                manufacturerKey: "",
                orgValue: [""],
                year: "2023",
            },
            periodLoading: false,
            periodList: [],
            periodTotal: 0,
            //联系人排名
            filterFormFour:{
                manufacturerKey: "",
                orgValue: [""],
                year: "2023",
                month: "",
                moneyMin: 0,
                moneyMax: 0,
                pageIndex: 1,
                pageSize: 5,
            },
            contactLoading: false,
            contactRanking: [],
            contactTotal: 0,
            //获取(产品/供应商)下拉框数据
            onePurchaserOption: [],
            twoProductOption: [],
            threePurchaserOption: [],
            fourPurchaserOption: [],
        };
    },
    methods: {
        //获取组织结构树下拉框
        getOrganizationDrop(){
            this.$axios.get(`${this.$personnelUrl}/Organization/FindOrganizationDrop`).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    data.unshift({
                        key: '',
                        name: '我的',
                        children: []
                    })
                    for (const item of data) {
                        this.disposeTree(item)
                    }
                    this.belongOptions = data;
                }
            });
        },
        //删除叶子节点的 children 属性
        disposeTree(node) {
            if (node.children.length) {
                for (const item of node.children) {
                    this.disposeTree(item)
                }
            } else {
                delete node.children
            }
        },

        //筛选过滤
        onFilterAll(){
            this.getStatData();

            this.filterFormOne.year = this.filterYear;
            this.filterFormOne.month = this.filterMonth;
            this.filterFormOne.orgValue = this.belongValue;
            this.onFilterOne();

            this.filterFormTwo.year = this.filterYear;
            this.filterFormTwo.month = this.filterMonth;
            this.filterFormTwo.orgValue = this.belongValue;
            this.onFilterTwo();

            this.filterFormThree.year = this.filterYear;
            this.filterFormThree.orgValue = this.belongValue;
            this.onFilterThree();

            this.filterFormFour.year = this.filterYear;
            this.filterFormFour.month = this.filterMonth;
            this.filterFormFour.orgValue = this.belongValue;
            this.onFilterFour();
        },

        //获取顶部统计数据
        getStatData(){
            this.statLoading = true;
            let organizationKey = this.belongValue[this.belongValue.length-1];
            this.$axios.post(`${this.$customerUrl}/Report/Purchaser/GetPurchaserTopData`,{
                "year": this.filterYear,
                "month": this.filterMonth,
                "organizationKey": organizationKey,
                "pageIndex": 0,
                "pageSize": 0,
            }).then((res) => {
                const { isSuccess , data } = res;
                this.statLoading = false;
                if (isSuccess) {
                    this.statDataForm = data;
                }
            });
        },

        onFilterOne(){
            this.filterFormOne.pageIndex = 1;
            this.getPurchaser();
        },
        onFilterTwo(){
            this.filterFormTwo.pageIndex = 1;
            this.getProduct();
        },
        onFilterThree(){
            this.getPeriod();
        },
        onFilterFour(){
            this.filterFormFour.pageIndex = 1;
            this.getContact();
        },

        //获取供应商排名
        getPurchaser(){
            let {manufacturerKey,orgValue,year,month,pageIndex,pageSize} = this.filterFormOne;
            let organizationKey = orgValue[orgValue.length-1];
            this.purchaserLoading = true;
            this.$axios.post(`${this.$customerUrl}/Report/Purchaser/GetPurchasersRanking`,{
                "manufacturerKey": manufacturerKey,
                "productKey": "",
                "year": year,
                "month": month,
                "organizationKey": organizationKey,
                "pageIndex": pageIndex,
                "pageSize": pageSize,
            }).then((res) => {
                const { isSuccess , data } = res;
                this.purchaserLoading = false;
                if (isSuccess) {
                    data.datas.forEach(item =>{
                        item.loading = false;
                        item.expandList = [];
                    })
                    this.purchaserRanking = data.datas;
                    this.purchaserTotal = data.totalItems;
                }
            });
        },
        //获取采购产品排名
        getProduct(){
            let {productKey,orgValue,year,month,pageIndex,pageSize} = this.filterFormTwo;
            let organizationKey = orgValue[orgValue.length-1];
            this.productLoading = true;
            this.$axios.post(`${this.$customerUrl}/Report/Purchaser/GetPurchaserProductRanking`,{
                "manufacturerKey": "",
                "productKey": productKey,
                "year": year,
                "month": month,
                "organizationKey": organizationKey,
                "pageIndex": pageIndex,
                "pageSize": pageSize,
            }).then((res) => {
                const { isSuccess , data } = res;
                this.productLoading = false;
                if (isSuccess) {
                    data.datas.forEach(item =>{
                        item.loading = false;
                        item.expandList = [];
                    })
                    this.productRanking = data.datas;
                    this.productTotal = data.totalItems;
                }
            });
        },
        //获取采购周期
        getPeriod(){
            let {manufacturerKey,orgValue,year} = this.filterFormThree;
            let organizationKey = orgValue[orgValue.length-1];
            this.periodLoading = true;
            this.$axios.post(`${this.$customerUrl}/Report/Purchaser/GetProcurementCycle`,{
                "manufacturerKey": manufacturerKey,
                "productKey": "",
                "year": year,
                "organizationKey": organizationKey,
                "startAmount": 0,
                "endAmount": 0,
            }).then((res) => {
                const { isSuccess , data } = res;
                this.periodLoading = false;
                if (isSuccess) {
                    this.periodList = data;
                    let renderData = [];
                    this.periodList.forEach(item =>{
                        renderData.push([item.date,item.totalPrice]);
                    })
                    var chartDom = document.getElementById('purchasingCycle');
                    var myChart = echarts.init(chartDom);
                    var option = {
                        tooltip: {
                            position: 'top',
                            formatter: function (p) {
                                const format = echarts.time.format(p.data[0], '{yyyy}-{MM}-{dd}', false);
                                return format + ': ' + p.data[1];
                            }
                        },
                        visualMap: {
                            min: 0,
                            max: 100000,
                            calculable: true,
                            // type: 'piecewise',
                            orient: 'horizontal',
                            left: 'center',
                            top: 0,
                            color: "#333",
                        },
                        calendar: {
                            top: 100,
                            left: 30,
                            right: 30,
                            cellSize: ['auto', 16],
                            range: year,
                            itemStyle: {
                                borderWidth: 0.5
                            },
                            yearLabel: { show: false }
                        },
                        series: {
                            type: 'heatmap',
                            coordinateSystem: 'calendar',
                            data: renderData,
                        }
                    };
                    option && myChart.setOption(option);
                }
            });
        },
        //获取联系人排名
        getContact(){
            let {manufacturerKey,orgValue,year,month,moneyMin,moneyMax,pageIndex,pageSize} = this.filterFormFour;
            let organizationKey = orgValue[orgValue.length-1];
            this.contactLoading = true;
            this.$axios.post(`${this.$customerUrl}/Report/Purchaser/GetPurchaserContactsRanking`,{
                "manufacturerKey": manufacturerKey,
                "productKey": "",
                "startAmount": moneyMin,
                "endAmount": moneyMax,
                "year": year,
                "month": month,
                "organizationKey": organizationKey,
                "pageIndex": pageIndex,
                "pageSize": pageSize,
            }).then((res) => {
                const { isSuccess , data } = res;
                this.contactLoading = false;
                if (isSuccess) {
                    this.contactRanking = data.datas;
                    this.contactTotal = data.totalItems;
                }
            });
        },

        expandChangeOne(row){
            if(row.expandList.length == 0){
                row.loading = true;
                this.$axios.get(`${this.$customerUrl}/Report/Purchaser/GetPurchaserProduct?manufacturerKey=${row.manufacturerKey}`).then((res) => {
                    const { isSuccess , data } = res;
                    if (isSuccess) {
                        row.loading = false;
                        row.expandList = data;
                    }
                });
            }
        },
        expandChangeTwo(row){
            if(row.expandList.length == 0){
                let {orgValue} = this.filterFormTwo;
                let organizationKey = orgValue[orgValue.length-1];
                row.loading = true;
                this.$axios.get(`${this.$customerUrl}/Report/Purchaser/GetPurchaser?productKey=${row.productKey}&organizationKey=${organizationKey}`).then((res) => {
                    const { isSuccess , data } = res;
                    if (isSuccess) {
                        row.loading = false;
                        row.expandList = data;
                    }
                });
            }
        },

        //获取(产品/供应商)下拉框数据
        getProductDrop(){
            this.$axios.get(`${this.$customerUrl}/Report/Purchaser/GetPurchaserProductBox`).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    this.twoProductOption = data;
                }
            });
        },
        getDropdownBox(organizationKey,type){
            this.$axios.get(`${this.$customerUrl}/Report/Purchaser/GetPurchaserBox?organizationKey=${organizationKey}`).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    if(type == 0){
                        this.onePurchaserOption = data;
                        this.threePurchaserOption = data;
                        this.fourPurchaserOption = data;
                    }else if(type == 1){
                        this.onePurchaserOption = data;
                    }else if(type == 3){
                        this.threePurchaserOption = data;
                    }else if(type == 4){
                        this.fourPurchaserOption = data;
                    }
                }
            });
        },

    },
    mounted() {
        //获取组织结构树下拉框
        this.getOrganizationDrop();
        //获取(产品/供应商)下拉框数据
        this.getProductDrop();
        this.getDropdownBox("",0);
        //获取顶部统计数据
        this.getStatData();
        //获取供应商排名
        this.getPurchaser();
        //获取采购产品排名
        this.getProduct();
        //获取采购周期
        this.getPeriod();
        //获取联系人排名
        this.getContact();
    },
    watch:{
        "filterFormOne.orgValue"(){
            let {orgValue} = this.filterFormOne;
            let organizationKey = orgValue[orgValue.length-1];
            this.getDropdownBox(organizationKey,1)
        },
        "filterFormTwo.orgValue"(){
            
        },
        "filterFormThree.orgValue"(){
            let {orgValue} = this.filterFormThree;
            let organizationKey = orgValue[orgValue.length-1];
            this.getDropdownBox(organizationKey,3);
        },
        "filterFormFour.orgValue"(){
            let {orgValue} = this.filterFormFour;
            let organizationKey = orgValue[orgValue.length-1];
            this.getDropdownBox(organizationKey,4);
        },
    },
};
</script>

<style lang="scss" scoped>
.filterBox{
    height: 60px;
    padding-left: 10px;
    display: flex; 
    align-items: center;
    font-size: 14px;
    color: #707070;
}
.statBox{
    background: #FFFFFF;
    box-shadow: 0px 0px 6px 1px rgba(0,0,0,0.16);
    border-radius: 4px 4px 4px 4px;
    padding: 15px 20px;
    display: flex;
    text-align: center;
    .statItem{
        width: 20%;
        border-left: 1px solid #E6E6E6;
        .label{
            font-size: 16px;
            line-height: 24px;
            font-weight: bold;
            color: #1B2841;
        }
        .value{
            font-size: 32px;
            color: #1B2841;
        }
        .ratio{
            display: flex;
            justify-content: center;
            .el-icon-caret-top{
                color: #2DC76D;
            }
            .el-icon-caret-bottom{
                color: #F56C6C;
            }
        }
    }
    .statItem:first-child{
        border-left: none;
    }
}
.reportBox{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .reportItem{
        width: calc(50% - 10px);
        margin-top: 20px;
        position: relative;
        height: 420px;
        background: #fff;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        .reportTitle {
            box-sizing: border-box;
            height: 42px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 14px 0 20px;
        }
    }
}
.filterItem{
    padding-left: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 14px;
    color: #707070;
    margin-bottom: 5px;
}
#purchasingCycle{
    height: 255px;
}
.expandStyle{
    display: flex;
    .expandItem{
        width: calc(20% - 10px);
        padding: 0 10px;
        box-sizing: border-box;
        height: 24px;
        line-height: 24px;
        color: #909090;
    }
}
</style>