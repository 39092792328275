<template>
    <div>
        <InterfaceName :interfaceName="interfaceName" />
        <InterfaceSpe :requestUrl="requestUrl" :requestMethod="requestMethod" />
        <InputParam :inputTableData="inputTableData" />
        <ReturnParam :returnObj="returnObj" />
    </div>
</template>

<script>
import InterfaceName from "../Acomponents/InterfaceName.vue";
import InterfaceSpe from "../Acomponents/InterfaceSpe.vue";
import InputParam from "../Acomponents/InputParam.vue";
import ReturnParam from "../Acomponents/ReturnParam.vue";

export default {
    components: { InterfaceName, InterfaceSpe, InputParam, ReturnParam },
    data() {
        return {
            interfaceName: "获取供应商详情",
            requestUrl: "https://crm.qixinerp.com/open/Procure/Supplier/Detail",
            requestMethod: "GET",
            inputTableData: [],
            returnObj: {},
        };
    },
    methods: {},
    mounted() {
        this.inputTableData = [
            {paramName: "supplierKey", valueType: "string", explain: "供应商key", required: true},
        ]
        this.returnObj = {
            "isSuccess": true,
            "errorMessage": null,
            "data": {
                "totalProcurement": "0.00",
                "createTime": "2023-04-21 16:35:21",
                "key": "7753e78756e840f1a1ea14a6f95243ea",
                "manufacturerNo": "23042192170",
                "manufacturerName": "东莞市力平电子制品有限公司",
                "companyHomepage": "",
                "city": "",
                "province": "",
                "placeOrigin": "乌兹别克斯坦",
                "postalCode": "",
                "address": "乌兹别克斯坦",
                "creditRating": "",
                "manufacturerSource": "采购需求",
                "businessScope": "",
                "settlementMethod": "",
                "cooperationLevel": "",
                "isInvoicing": "",
                "companyTaxNo": "",
                "depositBank": "",
                "bankAccount": "",
                "bankAddress": "",
                "latestTransactionDate": "",
                "developmentDate": "2023-04-21",
                "contacts": [
                    {
                        "key": "f1cd884ad7f044dfaacb344373528434",
                        "contactsName": "曾太源",
                        "contactsDepartment": "",
                        "contactsSex": "",
                        "contactsPost": "",
                        "contactsTel": "13533998466     17873686566",
                        "contactsPhone": "13533998466     17873686566",
                        "contactsFax": "",
                        "contactsEmail": ""
                    }
                ]
            },
        }
    },
};
</script>

<style lang="scss" scoped>

</style>