<template>
<div>
    <div v-if="ie=='i'">
        <!-- 加纳(GHA)进口提单明细 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th>原产国</th>
                        <td colspan="3">{{billInfo.Country}}</td>
                    </tr>
                    <tr>
                        <th width="25%">提单号</th>
                        <td width="25%">{{billInfo.BillOfLoadingNumber}}</td>
                        <th width="25%">海关录入日期</th>
                        <td width="25%">{{billInfo.ProcDate}}</td>
                    </tr>
                    <tr>
                        <th>结关日期</th>
                        <td>{{billInfo.RecpDate}}</td>
                        <th>进口商代码</th>
                        <td>{{billInfo.ImporterCode}}</td>
                    </tr>
                    <tr>
                        <th>净重</th>
                        <td>{{billInfo.NetWeight}} {{billInfo.NetWeightUnit}}</td>
                        <th>汇率</th>
                        <td>{{billInfo.ExchangeRate}}</td>
                    </tr>
                    <tr>
                        <th>项目编号</th>
                        <td>{{billInfo.ItemNo}}</td>
                        <th>包装数</th>
                        <td>{{billInfo.Package}}</td>
                    </tr>
                    <tr>
                        <th>包装类型</th>
                        <td>{{billInfo.PackageType}}</td>
                        <th>进口港</th>
                        <td>{{billInfo.PortName}}</td>
                    </tr>
                    <tr>
                        <th>章节</th>
                        <td>{{billInfo.Chapter}}</td>
                        <th>前四位编码</th>
                        <td>{{billInfo.HsCode4}}</td>
                    </tr>
                    <tr>
                        <th>申报人</th>
                        <td>{{billInfo.DeclarantName}}</td>
                        <th>监管人</th>
                        <td>{{billInfo.Supervisor}}</td>
                    </tr>
                    <tr>
                        <th>申报人代码</th>
                        <td>{{billInfo.DeclarantNum}}</td>
                        <th>海关程序代码</th>
                        <td>{{billInfo.Cpc}}</td>
                    </tr>
                    <tr>
                        <th>外币</th>
                        <td>{{billInfo.Currency}}</td>
                        <th>FOB价（外币）</th>
                        <td>{{billInfo.FOBInFc}}</td>
                    </tr>
                    <tr>
                        <th>FOB价（加纳币）</th>
                        <td>{{billInfo.FOBInGh}}</td>
                        <th>CIF价（加纳币）</th>
                        <td>{{billInfo.CIFInGh}}</td>
                    </tr>
                    <tr>
                        <th>关税</th>
                        <td>{{billInfo.DeclTax}}</td>
                        <th>关税豁免</th>
                        <td>{{billInfo.DeclExemp}}</td>
                    </tr>
                    <tr>
                        <th>相应税费的计算税率</th>
                        <td>{{billInfo.BitTaxRa}}</td>
                        <th>进口港代码</th>
                        <td>{{billInfo.BoeHeadCustOffCode}}</td>
                    </tr>
                    <tr>
                        <th>申报人地址</th>
                        <td>{{billInfo.DeclarantNameAddress}}</td>
                        <th>运输方式代码</th>
                        <td>{{billInfo.BoeHeadModeTransCode}}</td>
                    </tr>
                    <tr>
                        <th>船公司归属国代码</th>
                        <td>{{billInfo.BoeHeadCtryNatVessel}}</td>
                        <th>保险费（加纳币）</th>
                        <td>{{billInfo.BoeItemsDeclInsureGhc}}</td>
                    </tr>
                    <tr>
                        <th>运费（加纳币）</th>
                        <td>{{billInfo.BoeItemsDeclFreightGhc}}</td>
                        <th>进口商地址</th>
                        <td>{{billInfo.BoeHeadImporterAddress}}</td>
                    </tr>
                    <tr>
                        <th>总项目</th>
                        <td>{{billInfo.TotalItems}}</td>
                        <th>总支付凭证号</th>
                        <td>{{billInfo.PayRecpNo}}</td>
                    </tr>
                    <tr>
                        <th>总发票金额（外币）</th>
                        <td>{{billInfo.TotalInvoiceFC}}</td>
                        <th>总发票金额币种</th>
                        <td>{{billInfo.TotalInvoiceCurrency}}</td>
                    </tr>
                    <tr>
                        <th>总FOB价币种</th>
                        <td>{{billInfo.TotalFobCurrency}}</td>
                        <th>总运费（外币）</th>
                        <td>{{billInfo.TotalFreightFC}}</td>
                    </tr>
                    <tr>
                        <th>总保险费（外币）</th>
                        <td>{{billInfo.TotalInsureFC}}</td>
                        <th>总运费外币币种</th>
                        <td>{{billInfo.TotalFreightCurrency}}</td>
                    </tr>
                    <tr>
                        <th>总运费（加纳币）</th>
                        <td>{{billInfo.TotalFreightGHC}}</td>
                        <th>总FOB价（外币）</th>
                        <td>{{billInfo.TotalFobFC}}</td>
                    </tr>
                    <tr>
                        <th>总保险费（加纳币）</th>
                        <td>{{billInfo.TotalInsurGHC}}</td>
                        <th>总CIF价币种</th>
                        <td>{{billInfo.TotalCifCurrency}}</td>
                    </tr>
                    <tr>
                        <th>总CIF价（外币）</th>
                        <td>{{billInfo.TotalCifFC}}</td>
                        <th>项目验证号</th>
                        <td>{{billInfo.TotalItemsVerNo}}</td>
                    </tr>
                    <tr>
                        <th>分包装数</th>
                        <td>{{billInfo.TotalItemsNoPKG}}</td>
                        <th>总项总毛重</th>
                        <td>{{billInfo.TotalGrossWeight}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
    <div v-if="ie=='e'">
        <!-- 加纳(GHA)出口提单明细 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th>原产国</th>
                        <td colspan="3">{{billInfo.Country}}</td>
                    </tr>
                    <tr>
                        <th width="25%">提单号</th>
                        <td width="25%">{{billInfo.BillOfLoadingNumber}}</td>
                        <th width="25%">海关录入日期</th>
                        <td width="25%">{{billInfo.ProcDate}}</td>
                    </tr>
                    <tr>
                        <th>结关日期</th>
                        <td>{{billInfo.RecpDate}}</td>
                        <th>进口商代码</th>
                        <td>{{billInfo.ImporterCode}}</td>
                    </tr>
                    <tr>
                        <th>净重</th>
                        <td>{{billInfo.NetWeight}} {{billInfo.NetWeightUnit}}</td>
                        <th>汇率</th>
                        <td>{{billInfo.ExchangeRate}}</td>
                    </tr>
                    <tr>
                        <th>项目编号</th>
                        <td>{{billInfo.ItemNo}}</td>
                        <th>包装数</th>
                        <td>{{billInfo.Package}}</td>
                    </tr>
                    <tr>
                        <th>包装类型</th>
                        <td>{{billInfo.PackageType}}</td>
                        <th>进口港</th>
                        <td>{{billInfo.PortName}}</td>
                    </tr>
                    <tr>
                        <th>申报人</th>
                        <td>{{billInfo.DeclarantName}}</td>
                        <th>监管人</th>
                        <td>{{billInfo.Supervisor}}</td>
                    </tr>
                    <tr>
                        <th>申报人代码</th>
                        <td>{{billInfo.DeclarantNum}}</td>
                        <th>海关程序代码</th>
                        <td>{{billInfo.Cpc}}</td>
                    </tr>
                    <tr>
                        <th>外币</th>
                        <td>{{billInfo.Currency}}</td>
                        <th>FOB价（外币）</th>
                        <td>{{billInfo.FOBInFc}}</td>
                    </tr>
                    <tr>
                        <th>FOB价（加纳币）</th>
                        <td>{{billInfo.FOBInGh}}</td>
                        <th>CIF价（加纳币）</th>
                        <td>{{billInfo.CIFInGh}}</td>
                    </tr>
                    <tr>
                        <th>关税</th>
                        <td>{{billInfo.DeclTax}}</td>
                        <th>关税豁免</th>
                        <td>{{billInfo.DeclExemp}}</td>
                    </tr>
                    <tr>
                        <th>相应税费的计算税率</th>
                        <td>{{billInfo.BitTaxRa}}</td>
                        <th>进口港代码</th>
                        <td>{{billInfo.BoeHeadCustOffCode}}</td>
                    </tr>
                    <tr>
                        <th>申报人地址</th>
                        <td>{{billInfo.DeclarantNameAddress}}</td>
                        <th>运输方式代码</th>
                        <td>{{billInfo.BoeHeadModeTransCode}}</td>
                    </tr>
                    <tr>
                        <th>船公司归属国代码</th>
                        <td>{{billInfo.BoeHeadCtryNatVessel}}</td>
                        <th>保险费（加纳币）</th>
                        <td>{{billInfo.BoeItemsDeclInsureGhc}}</td>
                    </tr>
                    <tr>
                        <th>运费（加纳币）</th>
                        <td>{{billInfo.BoeItemsDeclFreightGhc}}</td>
                        <th>进口商地址</th>
                        <td>{{billInfo.BoeHeadImporterAddress}}</td>
                    </tr>
                    <tr>
                        <th>总项目</th>
                        <td>{{billInfo.TotalItems}}</td>
                        <th>总支付凭证号</th>
                        <td>{{billInfo.PayRecpNo}}</td>
                    </tr>
                    <tr>
                        <th>总发票金额（外币）</th>
                        <td>{{billInfo.TotalInvoiceFC}}</td>
                        <th>总发票金额币种</th>
                        <td>{{billInfo.TotalInvoiceCurrency}}</td>
                    </tr>
                    <tr>
                        <th>总FOB价币种</th>
                        <td>{{billInfo.TotalFobCurrency}}</td>
                        <th>总运费（外币）</th>
                        <td>{{billInfo.TotalFreightFC}}</td>
                    </tr>
                    <tr>
                        <th>总保险费（外币）</th>
                        <td>{{billInfo.TotalInsureFC}}</td>
                        <th>总运费外币币种</th>
                        <td>{{billInfo.TotalFreightCurrency}}</td>
                    </tr>
                    <tr>
                        <th>总运费（加纳币）</th>
                        <td>{{billInfo.TotalFreightGHC}}</td>
                        <th>总FOB价（外币）</th>
                        <td>{{billInfo.TotalFobFC}}</td>
                    </tr>
                    <tr>
                        <th>总保险费（加纳币）</th>
                        <td>{{billInfo.TotalInsurGHC}}</td>
                        <th>总CIF价币种</th>
                        <td>{{billInfo.TotalCifCurrency}}</td>
                    </tr>
                    <tr>
                        <th>总CIF价（外币）</th>
                        <td>{{billInfo.TotalCifFC}}</td>
                        <th>项目验证号</th>
                        <td>{{billInfo.TotalItemsVerNo}}</td>
                    </tr>
                    <tr>
                        <th>分包装数</th>
                        <td>{{billInfo.TotalItemsNoPKG}}</td>
                        <th>总项总毛重</th>
                        <td>{{billInfo.TotalGrossWeight}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
</div>
</template>

<script>
export default {
    props:["billInfo","ie"],
    data() {
        return {

        };
    },
    methods: {
        
    },
    mounted() {

    },
};
</script>

<style lang="scss" scoped>
.partItem {
    margin-bottom: 20px;
    /deep/.el-card__header {
        padding: 10px 20px;
        .headTitle {
            display: inline-block;
            padding-left: 10px;
            font-size: 14px;
            border-left: 2px solid var(--themeColor);
            font-weight: bold;
        }
    }
}
table {
    width: 100%;
    border-collapse: collapse;
    tr {
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;
        th,
        td {
            font-size: 12px;
            color: #666;
            height: 40px;
            border: 1px solid #eaeaea;
            text-align: center;
        }
        th {
            background-color: #f5f7fa;
            font-weight: 700;
        }
        .bg {
            background-color: var(--themeColor);
            color: #fff;
            font-weight: 700;
            opacity: 0.8;
        }
    }
}
</style>