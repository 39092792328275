<template>
<div>
    <div v-if="ie=='i'">
        <!-- 越南进口提单明细 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th width="25%">报关号</th>
                        <td width="25%">{{billInfo.DeclarationNumber}}</td>
                        <th width="25%">税务代码</th>
                        <td width="25%">{{billInfo.TraderCode}}</td>
                    </tr>
                    <tr>
                        <th>进口类型</th>
                        <td>{{billInfo.ImportType}}</td>
                        <th>出口商所在国</th>
                        <td>{{billInfo.ExporterCountry}}</td>
                    </tr>
                    <tr>
                        <th>SITC</th>
                        <td colspan="3">{{billInfo.SitcCode}} {{billInfo.SitcDescription}}</td>
                    </tr>
                    <tr>
                        <th>单价</th>
                        <td>{{billInfo.Price}}</td>
                        <th>原单价</th>
                        <td>{{billInfo.OriginalPrice}}</td>
                    </tr>

                    <tr>
                        <th>汇率</th>
                        <td>{{billInfo.ExchangeRate}}</td>
                        <th>税额</th>
                        <td>{{billInfo.TaxValue}}</td>
                    </tr>
                    <tr>
                        <th>进口税率</th>
                        <td>{{billInfo.ImportTax}}</td>
                        <th>进口税额</th>
                        <td>{{billInfo.ImportDutyAmount}}</td>
                    </tr>
                    <tr>
                        <th>贸易方式</th>
                        <td>{{billInfo.DeliveryTerm}}</td>
                        <th>集装箱数量</th>
                        <td>{{billInfo.ContainerNumbers}}</td>
                    </tr>
                    <tr>
                        <th>付款方式</th>
                        <td>{{billInfo.PaymentTerm}}</td>
                        <th>原始总价</th>
                        <td>{{billInfo.Amount}} {{billInfo.Currency}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
        
        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">运输信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">海关名称</th>
                        <td width="25%">{{billInfo.Customers}}</td>
                        <th width="25%">海关代码</th>
                        <td width="25%">{{billInfo.customsCode}}</td>
                    </tr>
                    <tr>
                        <th>海关代理代码</th>
                        <td>{{billInfo.customsAgencyCode}}</td>
                        <th>起运港</th>
                        <td>{{billInfo.UnloadingPort}}</td>
                    </tr>
                    <tr>
                        <th>起运港代码</th>
                        <td>{{billInfo.queueLocationCode}}</td>
                        <th>目的港</th>
                        <td>{{billInfo.VietnamImportPort}}</td>
                    </tr>
                    <tr>
                        <th>目的港代码</th>
                        <td>{{billInfo.lastLocationCode}}</td>
                        <th>来源国</th>
                        <td>{{billInfo.Country}}</td>
                    </tr>
                    <tr>
                        <th>原产国家代码</th>
                        <td>{{billInfo.originCountryCode}}</td>
                        <th>承运人</th>
                        <td>{{billInfo.Carrier}}</td>
                    </tr>
                    <tr>
                        <th>追踪号</th>
                        <td>{{billInfo.trackingNuber}}</td>
                        <th>船名</th>
                        <td>{{billInfo.TransportationName}}</td>
                    </tr>
                    <tr>
                        <th>抵港日期</th>
                        <td>{{billInfo.ArrivalDate}}</td>
                        <th>清关日期</th>
                        <td>{{billInfo.ClearanceDate}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
    <div v-if="ie=='e'">
        <!-- 越南出口提单明细 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th width="25%">报关号</th>
                        <td width="25%">{{billInfo.DeclarationNumber}}</td>
                        <th width="25%">税务代码</th>
                        <td width="25%">{{billInfo.TraderCode}}</td>
                    </tr>
                    <tr>
                        <th>进口商所在国</th>
                        <td>{{billInfo.Country}}</td>
                        <th>出口商所在国</th>
                        <td>{{billInfo.ExporterCountry}}</td>
                    </tr>
                    <tr>
                        <th>SITC</th>
                        <td colspan="3">{{billInfo.SitcCode}} {{billInfo.SitcDescription}}</td>
                    </tr>
                    <tr>
                        <th>数量单位</th>
                        <td>{{billInfo.Unit}}</td>
                        <th>单价</th>
                        <td>{{billInfo.Price}}</td>
                    </tr>
                    <tr>
                        <th>出口税率</th>
                        <td>{{billInfo.ExportTax}}</td>
                        <th>出口税额</th>
                        <td>{{billInfo.ExportTaxAmount}}</td>
                    </tr>
                    <tr>
                        <th>出口税额单位</th>
                        <td>{{billInfo.CurrencyCodeOfExportTaxAmount}}</td>
                        <th>税额</th>
                        <td>{{billInfo.TaxValue}}</td>
                    </tr>
                    <tr>
                        <th>贸易方式</th>
                        <td>{{billInfo.DeliveryTerm}}</td>
                        <th>付款方式</th>
                        <td>{{billInfo.PaymentTerm}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">运输信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">海关名称</th>
                        <td width="25%">{{billInfo.CustomsName}}</td>
                        <th width="25%">海关代码</th>
                        <td width="25%">{{billInfo.CustomsCode}}</td>
                    </tr>
                    <tr>
                        <th>海关代理代码</th>
                        <td>{{billInfo.CustomsAgencyCode}}</td>
                        <th>起运港</th>
                        <td>{{billInfo.VietnamExportPort}}</td>
                    </tr>
                    <tr>
                        <th>起运港代码</th>
                        <td>{{billInfo.QueueLocationCode}}</td>
                        <th>目的港</th>
                        <td>{{billInfo.DischargingPort}}</td>
                    </tr>
                    <tr>
                        <th>目的港代码</th>
                        <td>{{billInfo.LastLocationCode}}</td>
                        <th>运输方式</th>
                        <td>{{billInfo.ShippingMethod}}</td>
                    </tr>
                    <tr>
                        <th>承运人</th>
                        <td>{{billInfo.Carrier}}</td>
                        <th>追踪号</th>
                        <td>{{billInfo.TrackingNumber}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
</div>
</template>

<script>
export default {
    props:["billInfo","ie"],
    data() {
        return {

        };
    },
    methods: {
        
    },
    mounted() {

    },
};
</script>

<style lang="scss" scoped>
.partItem {
    margin-bottom: 20px;
    /deep/.el-card__header {
        padding: 10px 20px;
        .headTitle {
            display: inline-block;
            padding-left: 10px;
            font-size: 14px;
            border-left: 2px solid var(--themeColor);
            font-weight: bold;
        }
    }
}
table {
    width: 100%;
    border-collapse: collapse;
    tr {
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;
        th,
        td {
            font-size: 12px;
            color: #666;
            height: 40px;
            border: 1px solid #eaeaea;
            text-align: center;
        }
        th {
            background-color: #f5f7fa;
            font-weight: 700;
        }
        .bg {
            background-color: var(--themeColor);
            color: #fff;
            font-weight: 700;
            opacity: 0.8;
        }
    }
}
</style>