<template>
    <div class="tabCard">
        <el-tabs v-model="activeType">
            <el-tab-pane name="0" label="客户字段"></el-tab-pane>
            <el-tab-pane name="1" label="订单字段"></el-tab-pane>
            <el-tab-pane name="2" label="产品字段"></el-tab-pane>
            <el-tab-pane name="3" label="报价单字段"></el-tab-pane>
            <!-- <el-tab-pane name="4" label="供应商字段"></el-tab-pane> -->
            <!-- <el-tab-pane name="5" label="采购记录字段"></el-tab-pane> -->
        </el-tabs>
        <FieldSet :type="activeType" />
    </div>
</template>

<script>
import FieldSet from "./ComSet/FieldSet.vue";
export default {
    components: { FieldSet },
    data() {
        return {
            activeType: "0",
        };
    },
    methods: {

    },
    mounted() {

    },
};
</script>

<style lang="scss" scoped>
.tabCard{
    background: #FFFFFF;
    padding: 0 20px 20px;
}
</style>