<template>
    <div>
        《服务协议》英文
    </div>
</template>

<script>
export default {
    data() {
        return {

        };
    },
    methods: {
        
    },
    mounted() {
        
    },
};
</script>

<style lang="scss" scoped>

</style>