<template>
    <div v-loading="loading" class="module">
        <div class="updateBtn">
            <el-link icon="el-icon-refresh" :underline="false" type="primary" @click="updateNews">更新新闻信息</el-link>
        </div>
        <div class="itemCard" v-for="(item,index) in newsList" :key="index" @click="openNews(item.link)">
            <div class="leftContent">
                <div class="source">{{item.source}}</div>
                <div class="title">{{item.title}}</div>
                <div class="snippet">{{item.snippet}}</div>
                <div class="date">{{item.date}}</div>
            </div>
            <div class="rightImg">
                <el-image class="thumbnail" :src="item.thumbnail"></el-image>
            </div>
        </div>
        <el-empty v-show="newsList.length == 0" description="暂无新闻信息"></el-empty>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            companyName: "",
            newsList: [],
        };
    },
    methods: {
        //获取新闻信息
        getNews() {
            this.loading = true;
            this.$axios.get(`${this.$clueUrl}/Recommendation/GetRECNews?companyName=${this.companyName}`).then((res) => {
                const { isSuccess, data } = res;
                this.loading = false;
                if (isSuccess) {
                    this.newsList = data;
                }
            });
        },
        //更新新闻信息
        updateNews() {
            this.loading = true;
            this.$axios.get(`${this.$clueUrl}/Recommendation/UpdateRECNews?companyName=${this.companyName}`).then((res) => {
                const { isSuccess, data } = res;
                this.loading = false;
                if (isSuccess) {
                    this.newsList = data;
                }
            });
        },
        //打开新闻链接
        openNews(link){
            window.open(link)
        },

    },
    mounted() {
        this.companyName = encodeURIComponent(this.$route.query.companyName);
        this.getNews();
    },
};
</script>

<style lang="scss" scoped>
@import '@/scss/common.scss';
.module{
    position: relative;
    .updateBtn{
        position: absolute;
        right: 10px;
        top: 0;
    }
}
.itemCard{
    width: 900px;
    overflow: auto;
    cursor: pointer;
    margin-left: 20px;
    margin-bottom: 30px;
    .leftContent{
        width: 780px;
        float: left;
        .source{
            margin-bottom: 8px;
            color: #202124;
            font-size: 12px;
            line-height: 16px;
        }
        .title{
            color: $themeColor;
            font-size: 20px;
            line-height: 24px;
        }
        .snippet{
            margin-top: 8px;
            margin-bottom: 8px;
            color: #4d5156;
            font-size: 14px;
            line-height: 20px;
        }
        .date{
            color: #70757a;
            font-size: 14px;
            line-height: 20px;
        }
    }
    .rightImg{
        width: 100px;
        float: right;
        margin-left: 20px;
        .thumbnail{
            width: 100px;
            height: 100px;
            border-radius: 10px;
        }
    }
    &:hover .title{
        text-decoration: underline;
    }
}
</style>