<template>
    <div>
        <InterfaceName :interfaceName="interfaceName" />
        <InterfaceSpe :requestUrl="requestUrl" :requestMethod="requestMethod" />
        <ReturnParam :returnObj="returnObj" />
    </div>
</template>

<script>
import InterfaceName from "../Acomponents/InterfaceName.vue";
import InterfaceSpe from "../Acomponents/InterfaceSpe.vue";
import InputParam from "../Acomponents/InputParam.vue";
import ReturnParam from "../Acomponents/ReturnParam.vue";

export default {
    components: { InterfaceName, InterfaceSpe, InputParam, ReturnParam },
    data() {
        return {
            interfaceName: "获取产品分组",
            requestUrl: "https://crm.qixinerp.com/open/Product/Group/List",
            requestMethod: "GET",
            inputTableData: [],
            returnObj: {},
        };
    },
    methods: {
        
    },
    mounted() {
        this.returnObj = {
            "isSuccess": true,
            "errorMessage": null,
            "data": [
                {
                    "id": 1,
                    "key": "b6fe82b6be284d6da4de7e6e4bfdf0e7",
                    "name": "服装",
                    "desc": "各种衣服",
                    "parentId": 0,
                    "children": [
                        {
                            "id": 2,
                            "key": "d6e44c6dcfc84b72adfa28369f938a25",
                            "name": "上衣",
                            "desc": "T恤 寸衣",
                            "parentId": 1,
                            "children": []
                        },
                        {
                            "id": 3,
                            "key": "6cfa0e29b4cb42098fbdc0ebed5f5d53",
                            "name": "鞋类",
                            "desc": "鞋类",
                            "parentId": 1,
                            "children": []
                        },
                        {
                            "id": 4,
                            "key": "1b0832b2d9d044a38517d6e02c0179ed",
                            "name": "裙子",
                            "desc": "裙子",
                            "parentId": 1,
                            "children": []
                        }
                    ]
                },
                {
                    "id": 5,
                    "key": "678ff95398714e5987c71f6beb987075",
                    "name": "生活类",
                    "desc": "各种生活用品",
                    "parentId": 0,
                    "children": [
                        {
                            "id": 6,
                            "key": "24cf523d83d64145aea4fa3bfa0944db",
                            "name": "电子产品",
                            "desc": "电子产品",
                            "parentId": 5,
                            "children": []
                        },
                        {
                            "id": 7,
                            "key": "f2087e1aea8e4fb99fc108494e78415a",
                            "name": "吸尘器",
                            "desc": "吸尘器",
                            "parentId": 5,
                            "children": []
                        },
                    ]
                },              
            ],
        }
    },
};
</script>

<style lang="scss" scoped>

</style>