<template>
    <div>    
        <el-button v-if="isAdmin" class="addBtn" @click="toNew" size="small" type="primary" icon="el-icon-plus">新建</el-button>
        <span v-else class="setTipText">非管理员无法设置操作,请联系管理员操作</span>
        <div style="padding:5px 0;"></div>
        
        <el-table :data="resultList" :header-cell-style="{'background-color':'#F3F3F3','color':'#3A3A3A'}" :row-style="rowStyle">
            <el-table-column prop="name" label="职位名称"></el-table-column>
            <!-- <el-table-column prop="createDate" label="创建时间"></el-table-column> -->
            <el-table-column label="操作" width="200" v-if="isAdmin">
                <template slot-scope="scope">
                    <div v-if="!scope.row.isSystem">
                        <div class="handleEditBtn" @click="toEdit(scope.row)">编辑</div>
                        <div class="handleDelBtn" @click="toDel(scope.row)">删除</div>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        
        <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="500px" :close-on-click-modal="false" top="10vh">
            <el-input v-model="submitForm.name" size="small" placeholder="职位名称"></el-input>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" @click="onSubmit" :loading="btnLoading">确 认</el-button>
                <el-button size="small" @click="dialogVisible = false">取 消</el-button>
             </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isAdmin: false,
            resultList: [],
            dialogVisible: false,
            dialogTitle: "",
            submitForm: {},
            btnLoading: false,
        };
    },
    methods: {
        rowStyle({rowIndex}){
            // 表格隔行换色
            if(rowIndex % 2 == 1){
                return {'background-color':'#F9FAFC'}
            }
        },

        getList(){
            this.$axios.get(`${this.$customerUrl}/v1/Config/GetPosition`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.resultList = data;
                }
            });
        },
        toNew(){
            this.dialogTitle = "添加职位配置";
            this.dialogVisible = true;
            this.submitForm = {
                key: "",
                name: "",
            };
        },
        toEdit(row){
            this.dialogTitle = "编辑职位配置";
            this.dialogVisible = true;
            this.submitForm = {
                key: row.key,
                name: row.name,
            };
        },
        onSubmit(){
            const {name} = this.submitForm;
            if(!name){
                this.$message({ message: '请输入职位名称', type: 'warning'});
                return false;
            }
            this.btnLoading = true;
            this.$axios.post(`${this.$customerUrl}/v1/Config/SavePosition`,this.submitForm).then((res) => {
                const { isSuccess } = res;
                this.btnLoading = false;
                if (isSuccess) {
                    this.dialogVisible = false;
                    this.$message({ message: '提交成功', type: 'success'});
                    this.getList();
                }
            });
        },
        toDel(row){
            this.$confirm(`是否删除该职位配置：<strong style='color:var(--themeColor);'>${row.name}</strong>?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                dangerouslyUseHTMLString: true
            }).then(() => {
                this.$axios.get(`${this.$customerUrl}/v1/Config/DeletePosition?key=${row.key}`).then(res => {
                    const {isSuccess} = res;
                    if(isSuccess){
                        this.$message({type: 'success',message: '删除成功!'});
                        this.getList();
                    }
                });
            }).catch(() => {});
        }
    },
    mounted() {
        this.isAdmin = JSON.parse(localStorage.userInfo).isAdmin;
        this.getList();
    },
};
</script>
<style lang="scss" scoped>
</style>