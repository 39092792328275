<script>
import Vue from "vue";

export default Vue.extend({
    props: ["active"],
    methods: {
        selectpath(name) {
            this.$router.replace({ name });
        },
    },
});
</script>

<template>
    <div class="menu">
        <div style="position: absolute;top: 0;left: 0;width: 100%;">
            <div class="oneClass">邮件营销</div>
            <el-menu @select="selectpath" :default-active="$props['active']">
                <el-menu-item indexPath="TaskDashboard" index="TaskDashboard">邮件群发</el-menu-item>
                <el-menu-item indexPath="TXRpoert" index="TXRpoert">群发报告</el-menu-item>
            </el-menu>
            <div class="oneClass">WhatsApp营销</div>
            <el-menu @select="selectpath" :default-active="$props['active']">
                <el-menu-item indexPath="TaskDashboardWA" index="TaskDashboardWA">群发任务</el-menu-item>
                <el-menu-item indexPath="TaskDashboardMWA" index="TaskDashboardMWA">消息</el-menu-item>
                <el-menu-item indexPath="TaskDashboardPWA" index="TaskDashboardPWA">存档客户</el-menu-item>
                <!-- <el-menu-item indexPath="TaskDashboardTWA" index="TaskDashboardTWA">消息模版配置</el-menu-item> -->
                <!-- <el-menu-item indexPath="WATemplate" index="WATemplate">WhatsApp消息模版</el-menu-item> -->
            </el-menu>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.oneClass {
    padding: 5px 15px;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
    color: #aaa;
}
.menu {
    width: 192px;
    background: #fff;
    height: 100%;
    z-index: 100;
    position: relative;
    box-shadow: 0px 1px 6px 1px rgba(0, 0, 0, 0.16);
    ::v-deep .el-menu {
        border-right: none;
    }
    ::v-deep .el-menu-item {
        height: 40px;
        line-height: 40px;
        padding-left: 30px !important;
        color: #092338;
        &:hover,
        &.is-active {
            color: #fff;
            background: var(--themeColor);
        }
    }
}
</style>
