<template>
    <div>
        <img class="bgImg" src="@/assets/default/two.png" alt="" />
        <div class="describe">
            <span style="margin-right: 20px;">没有绑定邮箱</span>
            <el-link type="primary" href="/#/settingIndex?type=mail&index=3" target="_blank">前往设置</el-link>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        };
    },
    methods: {

    },
    mounted() {

    },
};
</script>

<style lang="scss" scoped>
.bgImg{
    display: block;
    margin: 200px auto 30px;
}
.describe{
    text-align: center;
    font-size: 24px;
    color: #333333;
}
</style>