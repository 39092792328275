import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import './firm/components'
// import 'element-ui/lib/theme-chalk/index.css'
import './scss/element-variables.scss'
import './scss/common.scss'
import './scss/scrollbar.scss'
import axios from './assets/js/axios'
import Router from 'vue-router'
import VueCompositionAPI from '@vue/composition-api'
import { setCookie, getCookie, delCookie } from './assets/js/cookie'
import VueHighlightJS from 'vue-highlightjs'
import 'highlight.js/styles/atom-one-dark.css'
import VueClipboard from 'vue-clipboard2';
import VueI18n from 'vue-i18n'
//引入全局组件
import CopyIcon from "@/components/tool/CopyIcon.vue"
import EmailValue from "@/components/tool/EmailValue.vue"
Vue.use(VueI18n)
const i18n = new VueI18n({
    locale: localStorage.getItem('languageSet') || 'zh',   // 默认中文
    messages: {
        'zh': require('./components/language/zh'),
        'en': require('./components/language/en'),
        'ko': require('./components/language/ko'),
    }
})
Vue.use(VueHighlightJS)
Vue.use(VueClipboard);
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
ElementUI.Dialog.props.closeOnClickModal.default = false;   //全局关闭：点遮罩层关闭弹框

Vue.use(ElementUI)

Vue.use(VueCompositionAPI)

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title
    }
    if(to.path.indexOf('clue') >= 0){
        next('/defaultTwo')
    }
    if(to.path.indexOf('marketing') >= 0){
        next('/defaultTwo')
    }
    if(to.path.indexOf('AkeySearch') >= 0){
        next('/defaultTwo')
    }
    if(to.path.indexOf('purchaseDemand') >= 0){
        next('/defaultTwo')
    }
    /* (线索/营销)模块权限控制 */
    let userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : "";
    if (userInfo) {
        const { isOpenClueModel, isOpenMarketingModel, isCustomsData, isProcureModel, isBindEmail } = userInfo;
        if (!isOpenClueModel && to.path.indexOf('clue') >= 0) {
            next('/noPower')
        } else if (!isOpenMarketingModel && to.path.indexOf('marketing') >= 0) {
            next('/noPower')
        } else if (!isCustomsData && to.path.indexOf('AkeySearch') >= 0) {
            next('/noPower')
        } else if (!isProcureModel && to.path.indexOf('purchase') >= 0) {
            next('/noPower')
        } else if (!isBindEmail && to.path.indexOf('mailIndex') >= 0) {
            next('/noBindEmail')
        } else {
            next()
        }
    } else {
        next()
    }
})

Vue.component('CopyIcon',CopyIcon)
Vue.component('EmailValue',EmailValue)

// 将cookie设置成vue的全局变量
Vue.prototype.$cookieStore = {
    setCookie,
    getCookie,
    delCookie
}

Vue.prototype.$isTest = window.location.host == "localhost:8082" || window.location.host == "test.qixinerp.com"

Vue.prototype.$axios = axios;

Vue.prototype.$authUrl = 'https://crm.qixinerp.com/auth';

Vue.prototype.$customerUrl = 'https://crm.qixinerp.com/customer';

Vue.prototype.$orderUrl = 'https://crm.qixinerp.com/order';

Vue.prototype.$productUrl = 'https://crm.qixinerp.com/product';

Vue.prototype.$diskUrl = 'https://crm.qixinerp.com/disk';

Vue.prototype.$mailUrl = 'https://crm.qixinerp.com/mail';

Vue.prototype.$personnelUrl = 'https://crm.qixinerp.com/personnel';

Vue.prototype.$sjUrl = 'https://crm.qixinerp.com/salesleads';

Vue.prototype.$clueUrl = 'https://crm.qixinerp.com/salesclue';

Vue.prototype.$openUrl = 'https://crm.qixinerp.com/open';

Vue.prototype.$marketUrl = 'https://crm.qixinerp.com/marketing';

Vue.prototype.$payUrl = 'https://crm.qixinerp.com/pay';

Vue.prototype.$searchUrl = 'https://crm.qixinerp.com/search';

Vue.prototype.$OAService = 'https://crm.qixinerp.com/auth/OAService';

new Vue({
    router,
    store,
    i18n,   //把 i18n 挂载到 vue 根实例上
    render: h => h(App)
}).$mount('#app')
