<template>
    <div class="detailsBox">
        <div class="header">
            <span>{{detailsInfo.procurementPlan}}-{{detailsInfo.purchaseNo}}</span>
            <div class="rightOption">
                <el-button size="small" @click="toEdit">编 辑</el-button>
                <el-button size="small" @click="toCopy">复 制</el-button>
            </div>
        </div>
        <div class="title">基本信息</div>
        <el-form :model="detailsInfo" label-width="120px" size="small" label-suffix=":">
            <el-row :gutter="16">
                <el-col :span="8">
                    <el-form-item label="采购计划">
                        <span class="w100">{{detailsInfo.procurementPlan}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="采购编号">
                        <span class="w100">{{detailsInfo.purchaseNo}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="采购日期">
                        <span class="w100">{{detailsInfo.purchaseDate}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="交货日期">
                        <span class="w100">{{detailsInfo.deliveryDate}}</span>
                        <el-tag type="success" size="small" v-if="isOverdue">已完成</el-tag>
                        <el-tag size="small" v-if="!isOverdue">待交货</el-tag>
                        <el-tag size="small" v-if="!isOverdue">剩余{{remainDays}}天交货</el-tag>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="跟进人">
                        <span class="w100">{{detailsInfo.followerName}}</span>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div class="title">产品及供应商信息</div>
        <el-form :model="detailsInfo" label-width="120px" size="small" label-suffix=":">
            <el-row :gutter="16">
                <el-col :span="8">
                    <el-form-item label="厂商名称">
                        <span class="w100">{{detailsInfo.manufacturerName}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="厂商编号">
                        <span class="w100">{{detailsInfo.manufacturerNo}}</span>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <el-table :data="purchasingProducts" :header-cell-style="{'background-color':'#F3F3F3','color':'#3A3A3A'}">
            <el-table-column label="编号">
                <template slot-scope="scope">
                    {{scope.row.productNo}}
                </template>
            </el-table-column>
            <el-table-column label="产品名称">
                <template slot-scope="scope">
                    {{scope.row.productName}}
                </template>
            </el-table-column>
            <el-table-column label="型号">
                <template slot-scope="scope">
                    {{scope.row.specifications}}
                </template>
            </el-table-column>
            <el-table-column label="计量单位">
                <template slot-scope="scope">
                    {{scope.row.measureUnit}}
                </template>
            </el-table-column>
            <el-table-column label="单价" width="160">
                <template slot-scope="scope">
                    {{scope.row.unitPrice}}
                </template>
            </el-table-column>
            <el-table-column label="订购数量" width="160">
                <template slot-scope="scope">
                    {{scope.row.num}}
                </template>
            </el-table-column>
            <el-table-column label="总价" width="160">
                <template slot-scope="scope">
                    {{scope.row.totalPrice}}
                </template>
            </el-table-column>
            <el-table-column label="币种" width="120">
                <template slot-scope="scope">
                    {{scope.row.currency}}
                </template>
            </el-table-column>
            <el-table-column label="汇率" width="160">
                <template slot-scope="scope">
                    {{scope.row.exchangeRate}}
                </template>
            </el-table-column>
            <el-table-column label="人民币总价" width="160">
                <template slot-scope="scope">
                    {{scope.row.cnyTotalPrice}}
                </template>
            </el-table-column>
            <el-table-column label="备注">
                <template slot-scope="scope">
                    {{scope.row.remarks}}
                </template>
            </el-table-column>
        </el-table>
        <div class="summation">
            <div>
                <span>采购产品数量：</span>
                <span style="padding-left:10px;">{{detailsInfo.productTotalNum}}</span>
            </div>
            <div>
                <span>采购产品金额：</span>
                <span>CNY</span>
                <span style="padding-left:10px;">{{detailsInfo.productTotalPrice}}</span>
            </div>
        </div>

        <div class="title">贸易费用</div>
        <div class="tradeCost">
            <div v-show="tradeExpenses.length == 0" style="text-align: center;color: #7C90A5;padding: 10px;">暂无贸易费用</div>
            <div v-for="(item,index) in tradeExpenses" :key="index" class="itemCost">
                <span style="margin-right: 20px;">{{index+1}}</span>
                <div style="margin-right:50px;">
                    <span>项目：</span>
                    <span class="valueColor">{{item.project}}</span>
                </div>
                <div style="margin-right:50px;">
                    <span>金额：</span>
                    <span class="valueColor">{{item.totalPrice}} {{item.currency}}</span>
                </div>
                <div style="margin-right:50px;">
                    <span>汇率：</span>
                    <span class="valueColor">{{item.exchangeRate}}</span>
                </div>
                <div style="margin-right:50px;">
                    <span>人民币金额：</span>
                    <span class="valueColor">{{item.cnyTotalPrice}}</span>
                </div>
            </div>
        </div>
        <div class="summation">
            <div></div>
            <div>
                <span>贸易费用金额：</span>
                <span>CNY</span>
                <span style="padding-left:10px;">{{detailsInfo.tradeExpensesTotalPrice}}</span>
            </div>
        </div>
        <div class="summation" style="font-size: 16px;font-weight: bold;">
            <div></div>
            <div>
                <span>合计总金额：</span>
                <span>CNY</span>
                <span style="padding-left:10px;">{{detailsInfo.allTotalPrice}}</span>
            </div>
        </div>

        <!-- 附件管理 -->
        <Fujian :documentKey="$route.query.key" type="purchasingRecordFujian" />

    </div>
</template>

<script>
import dayjs from "dayjs";
var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);
import Fujian from "@/components/Fujian.vue";
export default {
    components: { Fujian },
    data() {
        return {
            detailsInfo: {},
            purchasingProducts: [],
            tradeExpenses: [],
            //交货日期提醒
            isOverdue: false,
            remainDays: 0,
        };
    },
    methods: {
        //获取采购记录详情
        getDetail(key){
            this.$axios.get(`${this.$orderUrl}/Purchase/GetPurchasingRecordDetail?key=${key}`).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    this.detailsInfo = data;
                    this.purchasingProducts = data.purchasingProducts;
                    this.tradeExpenses = data.tradeExpenses;
                    //交货日期提醒
                    this.isOverdue = dayjs().isAfter(dayjs(data.deliveryDate));
                    this.remainDays = dayjs(data.deliveryDate).diff(dayjs(),'day');
                    // console.log(this.isOverdue);
                    // console.log(this.remainDays);
                }
            });
        },
        
        toEdit(){
            let routeData = this.$router.resolve({
                path: "/purchase/newRecord",
                query: { key: this.$route.query.key },
            });
            window.open(routeData.href, "_blank");            
        },
        toCopy(){
            let routeData = this.$router.resolve({
                path: "/purchase/newRecord",
                query: { key: this.$route.query.key, optype: "copy" },
            });
            window.open(routeData.href, "_blank");
        },
    },
    mounted() {
        let key = this.$route.query.key;
        if(key){
            this.getDetail(key); //获取采购记录详情
        }
    },
};
</script>

<style lang="scss" scoped>
.detailsBox{
    background: #FFFFFF;
    padding: 10px 20px;
    .header{
        height: 32px;
        line-height: 32px;
        font-size: 20px;
        font-weight: bold;
        color: #000;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
    }
    .title{
        height: 20px;
        line-height: 20px;
        font-size: 16px;
        font-weight: bold;
        color: #3A3A3A;
        margin-bottom: 10px;
        border-left: 3px solid var(--themeColor);
        padding-left: 5px;
    }
    .w100{
        width: 100%;
        height: 32px;
        color: #333;
    }
    .summation{
        font-size: 14px;
        height: 40px;
        line-height: 40px;
        color: #3a3a3a;
        display: flex;
        justify-content: space-between;
    }
    .tradeCost{
        border: 1px solid #EBEEF5;
        color: #5E6D82;
        font-size: 14px;
        .itemCost{
            display: flex;
            height: 32px;
            line-height: 32px;
            padding: 10px;
        }
        .itemCost:nth-child(2n+1){
            background: #F8F7F7;
        }
        .itemCost:nth-child(2n){
            background: #E5E9F0;
        }
        .handleDelBtn{
            margin-top: 6px;
        }
    }
}
</style>