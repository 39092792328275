<template>
  <div id="port-load" class="port-load" v-loading="loading" v-show="!(tableData.length === 0 && hidden)">
    <div class="title" ref="title">
      <div class="text">
        <p>起运港分析</p>
      </div>
      <div style="display: flex; align-items: center">
        <el-radio-group v-model="controlForm.option" size="mini">
          <el-radio-button :label="0">频次</el-radio-button>
          <el-radio-button :label="1">金额</el-radio-button>
          <el-radio-button :label="2">数量</el-radio-button>
          <el-radio-button :label="3">重量</el-radio-button>
        </el-radio-group>
        <img v-show="false" class="icon" src="@/firm/images/company/icon-download.png" style="margin-left: 1em" />
      </div>
    </div>
    <div class="content">
      <div class="content-child chart" style="height: 400px">
        <div ref="chart" class="chart-instance" style="height: 100%"></div>
        <div class="country-area">
          <div class="item" v-for="item of barItems" :key="item.port">
            <!-- <img :src="item.flagSrc" class="flag" /> -->
            <el-tooltip effect="light" :content="item.port" placement="top-start">
              <div class="country signle-line-omit" style="width: 120px; margin-right: 5px; font-size: 14px">{{ item.port }}</div>
            </el-tooltip>
            <el-progress style="width: 280px" color="#CC5E39" :stroke-width="16" :percentage="item.percentage" :show-text="false" />
            <div class="indicator" style="margin-left: 0.5em">{{ item.indicator }}</div>
          </div>
        </div>
      </div>
      <div class="content-child table" style="margin-top: 1em">
        <el-table :data="tableData" :header-cell-style="{ background: '#E5E9F2', color: '#1F2D3D' }" :row-style="tableRowStyleCallBack">
          <el-table-column prop="order" align="center" width="50px" />
          <el-table-column prop="port" label="港口" />
          <el-table-column prop="frequencyStr" label="频次" width="120px" show-overflow-tooltip />
          <el-table-column prop="amountStr" label="金额" width="120px" show-overflow-tooltip />
          <el-table-column prop="countStr" label="数量" width="120px" show-overflow-tooltip />
          <el-table-column prop="weightStr" label="重量" width="120px" show-overflow-tooltip />
        </el-table>
        <div style="margin-top: 1em; display: flex; justify-content: flex-end">
          <el-pagination background layout="total, prev, pager, next" :total="pagination.total" :page-size="pagination.pageSize" :current-page="pagination.pageIndex" @current-change="handlePageIndexChange" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import echarts from "@/firm/plugins/echarts";
import { tableRowStyleCallBack } from "@/firm/utils";
import axios from "@/firm/plugins/axios";
import { mapState, mapGetters, mapMutations } from "vuex";
import LazyOperation from "@/firm/plugins/lazy-execute";
import { AnchorInditor } from "../utils";

let chartInstance = null;
let lazyOperation = null;

export default Vue.extend({
  data() {
    return {
      hidden: false,
      loading: false,
      controlForm: {
        option: 0,
      },
      tableData: [],
      barItems: [],
      pagination: {
        total: 0,
        pageSize: 10,
        pageIndex: 1,
      },
    };
  },
  computed: {
    ...mapState(["company", "companyType"]),
    ...mapState("controlFormGlobal", {
      ie: "ie",
      dataCountry: "dataCountry",
      dateRange: "dateRange",
      hsCode: "hsCode",
      importer: "importer",
      exporter: "exporter",
      country: "country",

      exHsCode: "exHsCode",
      exExporter: "exExporter",
      exImporter: "exImporter",
      exCountry: "exCountry",
    }),
    ...mapGetters("controlFormGlobal", {
      startTime: "startTime",
      endTime: "endTime",
    }),
  },
  watch: {
    "controlForm.option"() {
      lazyOperation?.reobserve();
    },
    ie() {
      this.hidden = false;
      this.setAnchorFlag([["GNWQYG", true]]);
      lazyOperation?.reobserve();
    },
    dataCountry() {
      this.hidden = false;
      this.setAnchorFlag([["GNWQYG", true]]);
      lazyOperation?.reobserve();
    },
    dateRange() {
      this.hidden = false;
      this.setAnchorFlag([["GNWQYG", true]]);
      lazyOperation?.reobserve();
    },
    hsCode() {
      this.hidden = false;
      this.setAnchorFlag([["GNWQYG", true]]);
      lazyOperation?.reobserve();
    },
    importer() {
      this.hidden = false;
      this.setAnchorFlag([["GNWQYG", true]]);
      lazyOperation?.reobserve();
    },
    exporter() {
      this.hidden = false;
      this.setAnchorFlag([["GNWQYG", true]]);
      lazyOperation?.reobserve();
    },
    country() {
      this.hidden = false;
      this.setAnchorFlag([["GNWQYG", true]]);
      lazyOperation?.reobserve();
    },

    exHsCode() {
      this.hidden = false;
      this.setAnchorFlag([["GNWQYG", true]]);
      lazyOperation?.reobserve();
    },
    exExporter() {
      this.hidden = false;
      this.setAnchorFlag([["GNWQYG", true]]);
      lazyOperation?.reobserve();
    },
    exImporter() {
      this.hidden = false;
      this.setAnchorFlag([["GNWQYG", true]]);
      lazyOperation?.reobserve();
    },
    exCountry() {
      this.hidden = false;
      this.setAnchorFlag([["GNWQYG", true]]);
      lazyOperation?.reobserve();
    },

    companyType() {
      this.hidden = false;
      this.setAnchorFlag([["GNWQYG", true]]);
      lazyOperation?.reobserve();
    },
  },
  methods: {
    ...mapMutations("anchor", {
      setAnchorFlag: "setAnchorFlag",
      setCurrent: "setCurrent",
    }),
    tableRowStyleCallBack,
    async handlePageIndexChange(pageIndex) {
      this.loading = true;
      await this.getTableData(pageIndex);
      this.loading = false;
    },
    async getTableData(pageIndex = 1) {
      try {
        const res = await axios({
          method: "post",
          url: "/search/Credit/Ranking/Port",
          data: {
            pageIndex,
            pageSize: this.pagination.pageSize,
            ie: this.ie,
            importer: this.importer,
            exporter: this.exporter,
            dataCountry: this.dataCountry,
            country: this.country,
            startTime: this.startTime,
            endTime: this.endTime,
            hsCode: this.hsCode,
            sortType: this.controlForm.option,
            noHsCode: this.exHsCode,
            noExporter: this.exExporter,
            noImporter: this.exImporter,
            noCountry: this.exCountry,
            company: this.company,
            companyType: this.companyType,
          },
        });
        const data = res.data.result;
        //console.log(data);
        this.tableData = data.data;
        this.pagination.total = data.total;
        this.pagination.pageIndex = data.pageIndex;
        if (this.tableData.length === 0) {
          throw new Error();
        }
        this.hidden = false;
        this.setAnchorFlag([["GNWQYG", true]]);
        return Promise.resolve("resolve");
      } catch {
        this.tableData = [];
        this.hidden = true;
        this.setAnchorFlag([["GNWQYG", false]]);
        return Promise.resolve("resolve");
      }
      /* this.tableData = [
        {
          order: 1, //排名
          port: "vsvrberwgv", //港口
          frequencyStr: "1231", //频次
          frequency: 1231, //频次
          amountStr: "431452", //金额
          amount: 431452, //金额
          countStr: "4343", //数量
          count: 4343, //数量
          weightStr: "4325425", //重量
          weight: 4325425, //重量
        },
        {
          order: 2,
          port: "vsvccdvvsdvrberwgv",
          frequencyStr: "1231",
          frequency: 1641,
          amountStr: "431452",
          amount: 445623,
          countStr: "4343",
          count: 4785,
          weightStr: "4325425",
          weight: 4345235,
        },
      ] as any; */
    },
    async refreshChartInstance() {
      try {
        const res = await axios({
          method: "post",
          url: "/search/Credit/Ranking/Port",
          data: {
            pageIndex: 1,
            pageSize: 10,
            ie: this.ie,
            importer: this.importer,
            exporter: this.exporter,
            dataCountry: this.dataCountry,
            country: this.country,
            startTime: this.startTime,
            endTime: this.endTime,
            hsCode: this.hsCode,
            sortType: this.controlForm.option,
            noHsCode: this.exHsCode,
            noExporter: this.exExporter,
            noImporter: this.exImporter,
            noCountry: this.exCountry,
            company: this.company,
            companyType: this.companyType,
          },
        });
        const keys = ["frequency", "amount", "count", "weight"];
        const data = res.data.result.data;
        const barItems = [];
        const treeMapData = [];
        const progressBase = data[0][keys[this.controlForm.option]] * 1.2;
        data.forEach((item) => {
          barItems.push({
            //flagSrc: item.flagSrc,
            port: item.port,
            percentage: (item[keys[this.controlForm.option]] / progressBase) * 100,
            indicator: item[keys[this.controlForm.option]],
          });
          treeMapData.push({
            name: item.port,
            value: item[keys[this.controlForm.option]],
          });
        });
        this.barItems = barItems;
        chartInstance?.setOption({
          series: [
            {
              name: "treemap",
              data: treeMapData,
            },
          ],
        });
        this.$nextTick(() => {
          chartInstance?.resize();
        });
        this.hidden = false;
        this.setAnchorFlag([["GNWQYG", true]]);
        return Promise.resolve("resolve");
      } catch {
        this.hidden = true;
        this.setAnchorFlag([["GNWQYG", false]]);
        return Promise.resolve("resolve");
      }
    },
  },
  mounted() {
    chartInstance = echarts.init(this.$refs["chart"]);
    chartInstance.setOption({
      tooltip: {
        formatter(param) {
          try {
            const data = param.data;
            if (data.name === "treemap") {
              throw new Error();
            } else {
              return `
                ${data.name}<br />
                ${data.value}
              `;
            }
          } catch {
            return "";
          }
        },
      },
      series: [
        {
          name: "treemap",
          type: "treemap",
          roam: false,
          nodeClick: false,
          width: "100%",
          height: "100%",
          breadcrumb: {
            show: false,
          },
          itemStyle: {
            gapWidth: 10,
          },
          data: [],
        },
      ],
      color: ["#C85A35", "#E97B32", "#FABD7F", "#DBA076", "#976E62", "#F5DA96", "#DCB6A9", "#E0A554", "#F1DAC8", "#DBA076"],
    });
    lazyOperation = new LazyOperation(() => {
      this.loading = true;
      Promise.all([this.refreshChartInstance(), this.getTableData()]).then(() => (this.loading = false));
    }, this.$el);
    new AnchorInditor(() => {
      this.setCurrent("GNWQYG");
    }, this.$refs["title"]);
  },
});
</script>

<style lang="scss" scoped>
@import "@/firm/scss/mixin.scss";

.port-load {
  margin-top: 1rem;
  background: #fff;
  padding: 1rem;
  padding-top: 0;
  @include mixin-panel-title;

  .content {
    .content-child {
      background: #fff;

      &.chart {
        //display: grid;
        //grid-template-columns: 4.5fr 5.5fr;
        display: flex;

        .chart-instance {
          width: 45%;
        }

        .country-area {
          height: 100%;
          width: 55%;
          padding-left: 2em;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          justify-content: space-around;

          .item {
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
}
</style>
