<template>
    <div class="chatViewBox">
        <div class="headerTitle">
            <span>AI聊天</span>
            <span style="font-size: 14px;" v-if="surplusCount > 0">今日还有{{surplusCount}}次提问</span>
            <span style="font-size: 14px;" v-else>今日提问次数已用完,明日再来</span>
        </div>
        <div class="middleContent" ref="scrollDiv" v-loading="dataLoading">
            <div class="loadMore" v-if="isMore" @click="loadMore">加载更多历史记录</div>
            <div class="chatItem" v-for="(item,index) in chatShowList" :key="index" :class="{'bgf0': item.role=='assistant'}">
                <div class="iconImg">
                    <el-avatar v-if="item.role=='user'" :size="30" :src="userInfo.headPortraitUrl ? userInfo.headPortraitUrl : circleUrl"></el-avatar>
                    <el-avatar v-if="item.role=='assistant'" :size="30" :src="aiUrl"></el-avatar>
                </div>
                <div v-if="item.role=='assistant'" class="message" v-html="mdiRender(item.content)"></div>
                <div v-if="item.role=='user'" class="message">
                    <span v-html="item.content"></span>
                    <span style="font-size:12px;float:right;">{{item.createTime}}</span>
                </div>
            </div>
            <div class="chatItem bgf0" v-if="sendLoading">
                <img style="width: 30px;height: 30px;" :src="aiUrl" />
                <div class="message">
                    <div v-if="!htmlStr" class="cursorIcon"></div>
                    <div v-html="mdiRender(htmlStr)"></div>
                </div>
            </div>
        </div>
        <div class="bottomSend">
            <el-input @keyup.enter.native="onSend" resize="none" type="textarea" :rows="4" maxlength="100" placeholder="请输入内容(最多100字)" v-model="sendValue"></el-input>
            <el-button class="sendbtn" type="primary" size="small" :loading="sendLoading" :disabled="!sendValue.trim()" @click="onSend">发送</el-button>
        </div>
    </div>
</template>

<script>
import dayjs from "dayjs";
import MarkdownIt from "markdown-it";
import mdKatex from "@traptitech/markdown-it-katex";
import hljs from "highlight.js";
import "highlight.js/styles/atom-one-dark.css";
export default {
    data() {
        return {
            circleUrl: require("@/assets/img/headImg.png"),
            aiUrl: require("@/assets/img/AI.png"),
            userInfo: {},
            surplusCount: 10,
            sendValue: "",
            sendLoading: false,
            chatShowList: [],
            htmlStr: "",
            isMore: true,
            pageIndex: 1,
            dataLoading: false,
        };
    },
    methods: {
        highlightBlock(str, lang, code) {
            code = code.replace(/'/g, "<pxxsy>");
            return `<pre class="pre-code-box">
        <div class="pre-code-header">
          <span class="code-block-header__lang">${lang}</span>
          <span class="code-block-header__copy">
            <i class="icopy" onclick='onCopy(${JSON.stringify(code)})' data-copy="点击复制">
              <svg viewBox="64 64 896 896" focusable="false" data-icon="copy" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                <path d="M832 64H296c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h496v688c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V96c0-17.7-14.3-32-32-32zM704 192H192c-17.7 0-32 14.3-32 32v530.7c0 8.5 3.4 16.6 9.4 22.6l173.3 173.3c2.2 2.2 4.7 4 7.4 5.5v1.9h4.2c3.5 1.3 7.2 2 11 2H704c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32zM350 856.2L263.9 770H350v86.2zM664 888H414V746c0-22.1-17.9-40-40-40H232V264h432v624z"></path>
              </svg><span style="padding-left: 8px;">Copy code</span>
            </i>
          </span>
        </div><div class="pre-code"><code class="hljs code-block-body ${lang}">${str}</code></div></pre>`;
        },
        onCopy(code) {
            if (!code) return;
            code = code.replace(/<pxxsy>/g, "'");
            this.$copyText(code).then(() => {
                this.$message({ message: "复制成功", type: "success" });
            }).catch(() => {
                this.$message({ message: "复制失败",type: "warning" });
            });
        },
        mdiRender(data){
            const mdi = new MarkdownIt({
                linkify: true,
                highlight: (code, language) => {
                    const validLang = !!(language && hljs.getLanguage(language));
                    if (validLang) {
                        const lang = language ?? "";
                        return this.highlightBlock(hljs.highlight(lang, code, true).value,lang,code);
                    }
                    return this.highlightBlock(hljs.highlightAuto(code).value,"",code);
                },
            });
            mdi.use(mdKatex, {
                blockClass: "katexmath-block rounded-md p-[10px]",
                errorColor: " #cc0000",
            });
            return mdi.render(data);
        },

        async onSend() {
            if (!this.sendValue.trim()) {
                this.$message({ message: "请输入提问内容!",type: "warning" });
                return false;
            }
            if (this.sendLoading) {
                return false;
            }
            if (this.surplusCount <= 0) {
                this.$message({ message: "今日提问次数已用完,明日再来!",type: "warning" });
                return false;
            }
            //添加user数据
            this.chatShowList.push({
                role: "user",
                content: this.sendValue,
                createTime: dayjs().format("YYYY-MM-DD HH:mm:ss"),
            });
            this.onScrollBottom();
            try {
                this.sendLoading = true;
                this.htmlStr = "";
                let token = localStorage.getItem('token');
                const response = await fetch(`${this.$marketUrl}/Ai/ChatAi`, {
                    method: "POST",
                    body: JSON.stringify([{ role: "user", content: this.sendValue },]),
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + token,
                    },
                });
                const encode = new TextDecoder("utf-8"); //获取UTF8的解码
                const reader = response.body.getReader(); //获取body的reader
                //循环读取reponse中的内容
                while (true) {
                    const { done, value } = await reader.read();
                    if (done) {
                        break;
                    }
                    const text = encode.decode(value); //解码内容
                    if (text === "Error") { //当获取错误token时，输出错误信息
                        this.$message({ message: "AI出现错误!",type: "warning" });
                        break;
                    } else {
                        if (text) { //获取正常信息时，逐字追加输出
                            this.htmlStr += text;
                            this.onScrollBottom();
                        }
                    }
                }
                this.sendLoading = false;
                this.sendValue = "";
                this.surplusCount--;
                //添加assistant数据
                if(this.htmlStr){
                    this.chatShowList.push({
                        role: "assistant",
                        content: this.htmlStr,
                    });
                }
            } catch {
                this.sendLoading = false;
                this.sendValue = "";
            }
        },
        onScrollBottom() {
            this.$nextTick(() => {
                this.$refs.scrollDiv.scrollTo({
                    top: this.$refs.scrollDiv.scrollHeight,
                    behavior: "smooth",
                });
            });
        },
        //获取剩余发送次数
        getSurplusCount() {
            this.$axios.get(`${this.$marketUrl}/Ai/GetSurplusCount`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.surplusCount = data;
                }
            });
        },
        //获取历史记录
        getHistoryRecord() {
            this.dataLoading = true;
            this.$axios.post(`${this.$marketUrl}/Ai/GetHistoryChatRecord`, {
                pageIndex: this.pageIndex,
                pageSize: 5,
            }).then((res) => {
                const { isSuccess, data } = res;
                this.dataLoading = false;
                if (isSuccess) {
                    this.chatShowList = data.datas.concat(
                        this.chatShowList
                    );
                    if(this.pageIndex == 1){
                        this.onScrollBottom();
                    }
                    if (data.datas.length < 10) {
                        this.isMore = false;
                    }
                }
            });
        },
        loadMore() {
            this.pageIndex++;
            this.getHistoryRecord();
        },
    },
    mounted() {
        window.onCopy = this.onCopy;
        this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
        this.getSurplusCount();
        this.getHistoryRecord();
    },
};
</script>
<style lang="scss">
.cursorIcon {
    display: inline-block;
    width: 3px;
    height: 30px;
    background-color: #333;
    vertical-align: text-bottom;
    animation: blink 1s infinite;
}
@keyframes blink {
    50% {
        opacity: 0;
    }
}
.pre-code-box {
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
    font-size: 16px;
    margin-bottom: 5px;
    width: calc(100vw - 80px);
    code {
        font-size: 14px;
        line-height: 20px;
        font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
        background: #000;
        border-radius: 0 0 6px 6px;
    }
}
.pre-code-header {
    background: #343540;
    border-radius: 6px 6px 0 0;
    height: 35px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    position: relative;
    margin-top: -15px;
}
.icopy {
    font-style: normal;
    font-weight: normal;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 35px;
}
.icopy[data-copy]:hover::after {
    content: attr(data-copy);
    position: absolute;
    top: -30px;
    right: 30px;
    padding: 5px;
    background-color: #343540;
    color: #fff;
    font-size: 12px;
    white-space: nowrap;
}
.message ul,ol{
    margin-left: 15px;
}
</style>

<style lang="scss" scoped>
.chatViewBox {
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    .headerTitle {
        height: 50px;
        line-height: 50px;
        padding: 0 20px;
        font-size: 18px;
        font-weight: bold;
        background-color: #545c64;
        color: #fff;
        display: flex;
        justify-content: space-between;
    }
    .middleContent {
        height: calc(100vh - 200px);
        overflow: auto;
        padding-bottom: 54px;
        .loadMore {
            text-align: center;
            padding: 10px;
            cursor: pointer;
            font-size: 14px;
            color: #e6a23c;
        }
        .chatItem {
            padding: 12px 20px;
            display: flex;
            .iconImg {
                width: 30px;
                height: 30px;
                text-align: center;
                line-height: 30px;
            }
            .message {
                margin-left: 10px;
                width: calc(100vw - 80px);
                line-height: 24px;
            }
        }
        .bgf0 {
            background: #f0f0f0;
        }
    }
    .bottomSend {
        height: 96px;
        position: relative;
        .sendbtn {
            position: absolute;
            right: 1px;
            bottom: 1px;
        }
    }
}
</style>