<template>
<div>
    <div v-if="ie=='i'">
        <!-- 智利(CHL)进口提单明细 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th width="25%">清单日期</th>
                        <td width="25%">{{billInfo.ManifestDate}}</td>
                        <th width="25%">报关日期</th>
                        <td width="25%">{{billInfo.DeclarationDate}}</td>
                    </tr>
                    <tr>
                        <th>清单号码</th>
                        <td>{{billInfo.ManifestNum}}</td>
                        <th>进口编号</th>
                        <td>{{billInfo.ImportId}}</td>
                    </tr>
                    <tr>
                        <th>进口制度</th>
                        <td>{{billInfo.ImporterRegimen}}</td>
                        <th>进口商ID</th>
                        <td>{{billInfo.ImporterId}}</td>
                    </tr>
                    <tr>
                        <th>进口商企业代码校验</th>
                        <td>{{billInfo.ImporterCheck}}</td>
                        <th>进口商主要商品类</th>
                        <td>{{billInfo.ImporterEconomicKey}}</td>
                    </tr>
                    <tr>
                        <th>进口商城市</th>
                        <td>{{billInfo.ImporterCity}}</td>
                        <th>进口活动</th>
                        <td>{{billInfo.ImporterActivity}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th width="25%">品牌</th>
                        <td width="25%">{{billInfo.Brand}}</td>
                        <th width="25%">规格</th>
                        <td width="25%">{{billInfo.Description}}</td>
                    </tr>
                    <tr>
                        <th>种类</th>
                        <td>{{billInfo.Varietey}}</td>
                        <th>其他</th>
                        <td>{{billInfo.Other}}</td>
                    </tr>
                    <tr>
                        <th>币种</th>
                        <td>{{billInfo.Currency}}</td>
                        <th>数量单位</th>
                        <td>{{billInfo.UnitofMeasure}}</td>
                    </tr>
                    <tr>
                        <th>FOB总项总价</th>
                        <td>{{billInfo.TotalFob}}</td>
                        <th>CIF总项总价</th>
                        <td>{{billInfo.TotalCif}}</td>
                    </tr>
                    <tr>
                        <th>总项</th>
                        <td>{{billInfo.TotalItem}}</td>
                        <th>子项</th>
                        <td>{{billInfo.Item}}</td>
                    </tr>
                    <tr>
                        <th>FOB总价</th>
                        <td>{{billInfo.UsFob}}</td>
                        <th>FOB单价</th>
                        <td>{{billInfo.UsFobUnit}}</td>
                    </tr>
                    <tr>
                        <th>CIF总价</th>
                        <td>{{billInfo.Value}}</td>
                        <th>CIF单价</th>
                        <td>{{billInfo.UsCifUnit}}</td>
                    </tr>
                    <tr>
                        <th>付款方式</th>
                        <td>{{billInfo.TypeofPayment}}</td>
                        <th>成交方式</th>
                        <td>{{billInfo.Incoterms}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">运输信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">卸货港</th>
                        <td width="25%">{{billInfo.PortOfLanding}}</td>
                        <th width="25%">起运港</th>
                        <td width="25%">{{billInfo.OriginPort}}</td>
                    </tr>
                    <tr>
                        <th>来源国</th>
                        <td>{{billInfo.Country}}</td>
                        <th>运输方式</th>
                        <td>{{billInfo.TypeOfTransport}}</td>
                    </tr>
                    <tr>
                        <th>运输单据号码</th>
                        <td>{{billInfo.TransDocumentNum}}</td>
                        <th>运输单据日期</th>
                        <td>{{billInfo.TransDocumentDate}}</td>
                    </tr>
                    <tr>
                        <th>运输公司ID</th>
                        <td>{{billInfo.TransportCompanyId}}</td>
                        <th>运输公司地址</th>
                        <td>{{billInfo.TransportCompanyAddress}}</td>
                    </tr>
                    <tr>
                        <th>运输公司</th>
                        <td>{{billInfo.TransportCompany}}</td>
                        <th>运输公司所在国</th>
                        <td>{{billInfo.TransCompanyCountry}}</td>
                    </tr>
                    <tr>
                        <th>运输公司所在地区</th>
                        <td>{{billInfo.TransportCompanyCity}}</td>
                        <th>装载类型</th>
                        <td>{{billInfo.LoadType}}</td>
                    </tr>
                    <tr>
                        <th>编码运费</th>
                        <td>{{billInfo.FreightCode}}</td>
                        <th>编码保险</th>
                        <td>{{billInfo.InsuranceCode}}</td>
                    </tr>
                    <tr>
                        <th>总保险</th>
                        <td>{{billInfo.Insurance}}</td>
                        <th>总运费</th>
                        <td>{{billInfo.Freight}}</td>
                    </tr>
                    <tr>
                        <th>总包装数量</th>
                        <td>{{billInfo.TotalPackageQuantity}}</td>
                        <th>包装数量</th>
                        <td>{{billInfo.PackageQuantity}}</td>
                    </tr>
                    <tr>
                        <th>包装ID</th>
                        <td>{{billInfo.PackageId}}</td>
                        <th>包装方式</th>
                        <td>{{billInfo.TypeOfPackage}}</td>
                    </tr>
                    <tr>
                        <th>关区</th>
                        <td>{{billInfo.Custom}}</td>
                        <th>关区仓库</th>
                        <td>{{billInfo.CustomsWarehouse}}</td>
                    </tr>
                    <tr>
                        <th>增值税额</th>
                        <td>{{billInfo.UsTax}}</td>
                        <th>税率</th>
                        <td>{{billInfo.Tax}}</td>
                    </tr>
                    <tr>
                        <th>到货日期</th>
                        <td>{{billInfo.ArrivalDate}}</td>
                        <th>货源国</th>
                        <td>{{billInfo.PurchaseCountry}}</td>
                    </tr>
                    <tr>
                        <th>商品注销日期</th>
                        <td>{{billInfo.CancellationDate}}</td>
                        <th>商业银行</th>
                        <td>{{billInfo.CommercialBank}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">账目信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">从价账目代码</th>
                        <td width="25%">{{billInfo.infoAdvaloremAccountCode}}</td>
                        <th width="25%">从价账目金额</th>
                        <td width="25%">{{billInfo.AdvaloremAccountAmmount}}</td>
                    </tr>
                    <tr>
                        <th>其他账目汇率1</th>
                        <td>{{billInfo.OtherAccountRate1}}</td>
                        <th>其他账目代码1</th>
                        <td>{{billInfo.OtherAccountCode1}}</td>
                    </tr>
                    <tr>
                        <th>其他账目金额1</th>
                        <td>{{billInfo.OtherAccountAmount1}}</td>
                        <th>其他账目汇率2</th>
                        <td>{{billInfo.OtherAccountRate2}}</td>
                    </tr>
                    <tr>
                        <th>其他账目代码2</th>
                        <td>{{billInfo.OtherAccountCode2}}</td>
                        <th>其他账目金额2</th>
                        <td>{{billInfo.OtherAccountAmount2}}</td>
                    </tr>
                    <tr>
                        <th>其他账目汇率3</th>
                        <td>{{billInfo.OtherAccountRate3}}</td>
                        <th>其他账目代码3</th>
                        <td>{{billInfo.OtherAccountCode3}}</td>
                    </tr>
                    <tr>
                        <th>其他账目金额3</th>
                        <td>{{billInfo.OtherAccountAmount3}}</td>
                        <th>其他账目汇率4</th>
                        <td>{{billInfo.OtherAccountRate4}}</td>
                    </tr>
                    <tr>
                        <th>其他账目代码4</th>
                        <td>{{billInfo.OtherAccountCode4}}</td>
                        <th>其他账目金额4</th>
                        <td>{{billInfo.OtherAccountAmount4}}</td>
                    </tr>
                    <tr>
                        <th>总从价账目代码</th>
                        <td>{{billInfo.TotalAdvaloremAccountCode}}</td>
                        <th>总从价账目金额</th>
                        <td>{{billInfo.TotalAdvaloremAccountAmmount}}</td>
                    </tr>
                    <tr>
                        <th>调整标识</th>
                        <td>{{billInfo.AjusteSigno}}</td>
                        <th>调整金额</th>
                        <td>{{billInfo.AjusteAmount}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
    <div v-if="ie=='e'">
        <!-- 智利(CHL)出口提单明细 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th width="25%">出口商ID</th>
                        <td width="25%">{{billInfo.ExporterId}}</td>
                        <th width="25%">税务校验码</th>
                        <td width="25%">{{billInfo.ExporterCheck}}</td>
                    </tr>
                    <tr>
                        <th>出口商地区</th>
                        <td colspan="3">{{billInfo.ExporterRegion}}</td>
                    </tr>
                    <tr>
                        <th>品牌</th>
                        <td>{{billInfo.Brand}}</td>
                        <th>种类</th>
                        <td>{{billInfo.Varietey}}</td>
                    </tr>
                    <tr>
                        <th>币种</th>
                        <td>{{billInfo.Currency}}</td>
                        <th>数量单位</th>
                        <td>{{billInfo.UnitofMeasure}}</td>
                    </tr>
                    <tr>
                        <th>FOB总价</th>
                        <td>{{billInfo.UsFob}}</td>
                        <th>FOB单价</th>
                        <td>{{billInfo.UsFobUnit}}</td>
                    </tr>
                    <tr>
                        <th>运费</th>
                        <td>{{billInfo.Freight}}</td>
                        <th>保险</th>
                        <td>{{billInfo.Insurance}}</td>
                    </tr>
                    <tr>
                        <th>付款方式</th>
                        <td>{{billInfo.TypeofPayment}}</td>
                        <th>成交方式</th>
                        <td>{{billInfo.Incoterms}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">运输信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">目的国</th>
                        <td width="25%">{{billInfo.Country}}</td>
                        <th width="25%">起运港</th>
                        <td width="25%">{{billInfo.OriginPort}}</td>
                    </tr>
                    <tr>
                        <th>卸货港</th>
                        <td>{{billInfo.PortOfLanding}}</td>
                        <th>运输方式</th>
                        <td>{{billInfo.TypeOfTransport}}</td>
                    </tr>
                    <tr>
                        <th>运输公司</th>
                        <td>{{billInfo.TransportCompany}}</td>
                        <th>运输公司ID</th>
                        <td>{{billInfo.TransDocumentNum}}</td>
                    </tr>
                    <tr>
                        <th>运输单据日期</th>
                        <td>{{billInfo.TransDocumentDate}}</td>
                        <th>运输公司所在地区</th>
                        <td>{{billInfo.TransCompanyCity}}</td>
                    </tr>
                    <tr>
                        <th>运输公司地址</th>
                        <td>{{billInfo.TransCompanyCountry}}</td>
                        <th>船名</th>
                        <td>{{billInfo.ShipName}}</td>
                    </tr>
                    <tr>
                        <th>航次号</th>
                        <td>{{billInfo.VoyageNumber}}</td>
                        <th>海关</th>
                        <td>{{billInfo.Custom}}</td>
                    </tr>
                    <tr>
                        <th>包装数量</th>
                        <td>{{billInfo.PackageQuantity}}</td>
                        <th>包装方式</th>
                        <td>{{billInfo.TypeOfPackage}}</td>
                    </tr>
                    <tr>
                        <th>包装说明</th>
                        <td>{{billInfo.PackagesDes}}</td>
                        <th>装载类型</th>
                        <td>{{billInfo.LoadType}}{{billInfo.ExporterEconomicKey}}</td>
                    </tr>
                    <tr>
                        <th>经济区</th>
                        <td>{{billInfo.EconomicZone}}</td>
                        <th>销售状态</th>
                        <td>{{billInfo.SaleCondition}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
</div>
</template>

<script>
export default {
    props:["billInfo","ie"],
    data() {
        return {

        };
    },
    methods: {
        
    },
    mounted() {

    },
};
</script>

<style lang="scss" scoped>
.partItem {
    margin-bottom: 20px;
    /deep/.el-card__header {
        padding: 10px 20px;
        .headTitle {
            display: inline-block;
            padding-left: 10px;
            font-size: 14px;
            border-left: 2px solid var(--themeColor);
            font-weight: bold;
        }
    }
}
table {
    width: 100%;
    border-collapse: collapse;
    tr {
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;
        th,
        td {
            font-size: 12px;
            color: #666;
            height: 40px;
            border: 1px solid #eaeaea;
            text-align: center;
        }
        th {
            background-color: #f5f7fa;
            font-weight: 700;
        }
        .bg {
            background-color: var(--themeColor);
            color: #fff;
            font-weight: 700;
            opacity: 0.8;
        }
    }
}
</style>