<template>
    <div class="viewbox">
        <div class="title">自定义模板</div>
        <div class="mainContent">
            <div class="leftInput">
                <div style="margin-bottom: 10px;">
                    <span style="color: #5E6D82;">模板名称：</span>
                    <el-input v-model="submitForm.templateName" placeholder="模板名称" size="small" style="width:500px;"></el-input>
                </div>
                <Editor v-model="submitForm.templateContent" :init="{
                    language: 'zh_CN',
                    resize: false,
                    height: '680px',
                    branding: false,
                    menubar: false,
                    toolbar: 'undo redo | emoticons | image link | formatselect fontsizeselect forecolor | bold underline italic | alignleft aligncenter alignright | preview fullscreen table',
                    plugins: 'emoticons image link preview fullscreen table',
                    images_upload_handler}"
                    tinymceScriptSrc="tinymce/tinymce.min.js"
                    apiKey="l6y3tipsjmozdy96gs2qcbjjjaauujp07q4r0rpnyxqjh4ac">
                </Editor>
                <el-button class="saveBtn" type="primary" @click="onSave" :loading="btnLoading" size="small">保存模板</el-button>
              
                <el-button class="saveBtn" type="primary" @click="toPreview" size="small">预览</el-button>
              
                <!-- <el-button class="saveBtn" type="primary" @click="exportPdf" size="small">导出Pdf</el-button> -->
            </div>
            <div class="rightChoose">
                <div class="tt">报价单变量</div>
                <div class="varItem" v-for="(item,index) in varList" :key="index" @click="insertVariable(item.value)">
                    <span class="label">{{item.label}}</span>
                    <span class="value">{{item.value}}</span>
                </div>
            </div>
        </div>

        <el-dialog title="编辑产品表格" :visible.sync="isEditProduct" width="500px" top="100px">
            <div style="margin-bottom: 10px;">请选择产品表格字段:</div>
            <el-checkbox-group v-model="checkField">
                <el-checkbox v-for="item in productVar" :key="item.value" :label="item.label"></el-checkbox>
            </el-checkbox-group>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="isEditProduct = false;">取 消</el-button>
                <el-button size="small" type="primary" @click="editSubmit">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog title="选择报价单预览" :visible.sync="chooseVisible" width="500px" top="100px">
            <div style="margin-bottom: 10px;">请选择报价单预览结果:</div>
            <el-select v-model="chooseQuoteKey" placeholder="请选择" filterable size="small">
                <el-option v-for="item in quotationList" :key="item.uniqueKey" :value="item.uniqueKey" :label="item.quoteName">
                </el-option>
            </el-select>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="chooseVisible = false;">取 消</el-button>
                <el-button size="small" type="primary" @click="onPreview">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="预览结果" :visible.sync="previewVisible" width="1200px">
            <div v-html="previewContent"></div>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="previewVisible = false;">关 闭</el-button>
            </span>
        </el-dialog>


    </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
import { getTinymce } from '@tinymce/tinymce-vue/lib/cjs/main/ts/TinyMCE';
export default {
    components: { Editor },
    data() {
        return {
            submitForm: {
                key: "",
                templateName: "",
                templateContent: "",
                isSysTem: false,
            },
            btnLoading: false,
            // 变量集合
            // [ProductNo]
            // [ProductName]
            // [Specifications]
            // [OrderedQuantity]
            // [MeasureUnit]
            // [OtherExpenses]
            // [ProductTotalPrice]
            // [ProductRemarks]


            varList:[
                {label: "报价单名称：", value: "[QuotationName]"},
                {label: "报价有效期：", value: "[Validity]"},
                {label: "报价总金额：", value: "[TotalPrice]"},
                {label: "报价日期：", value: "[QuotationDate]"},
                {label: "我方公司：", value: "[OurCompany]"},
                {label: "报价人：", value: "[Bidder]"},
                {label: "公司名称：", value: "[Customer]"},
                {label: "客户地址：", value: "[CustomerAddress]"},
                {label: "联系人名称：", value: "[Contacts]"},
                {label: "联系人邮箱：", value: "[Mail]"},
                {label: "联系人电话：", value: "[Telephone]"},
                {label: "结算货币：", value: "[SettlementCurrency]"},
                {label: "运输方式：", value: "[TypeShipping]"},
                {label: "贸易费用：", value: "[TradeCosts]"},
                {label: "备注：", value: "[Remarks]"},
            ],
            
            productVar:[
                {label: "产品编号", value: "[ProductNo]", enName: "Product No"},
                {label: "产品名称", value: "[ProductName]", enName: "Product name"},
                {label: "型号", value: "[Specifications]", enName: "Dimension information"},
                {label: "订购数量", value: "[OrderedQuantity]", enName: "Ordered quantity"},
                {label: "计量单位", value: "[MeasureUnit]", enName: "Unit of measurement"},
                {label: "其他费用", value: "[OtherExpenses]", enName: "other expenses"},
                {label: "总价", value: "[ProductTotalPrice]", enName: "Total price"},
                {label: "备注", value: "[ProductRemarks]", enName: "Remarks"},
            ],
            isEditProduct: false,
            checkField: [],

            //选择报价单预览功能
            chooseVisible: false,
            quotationList: [],
            chooseQuoteKey: "",
            previewVisible: false,
            previewContent: "",
        };
    },
    methods: {
        async images_upload_handler(blobInfo) {
            try {
                const formData = new FormData();
                formData.append("folderKey", "0");
                formData.append("remarkName", "");
                formData.append("permission", "我的");
                formData.append("file", blobInfo.blob());
                const res = await this.$axios({
                    method: "POST",
                    url: this.$diskUrl + "/OtherFile/Upload",
                    data: formData,
                });
                return res.fileUrl;
            } catch (error) {
                this.$message.error("插入图片失败");
            }
        },

        //保存模板
        onSave() {
            if(!this.submitForm.templateName){
                this.$message({ message: '请填写模板名称', type: 'warning'});
                return false;
            }
            this.btnLoading = true;
            this.$axios.post(`${this.$sjUrl}/ExportTemplate/Save`, this.submitForm).then((res) => {
                const { isSuccess , data } = res;
                this.btnLoading = false;
                if (isSuccess) {
                    console.log(data);
                    this.$message({ message: '保存模板成功', type: 'success'});
                }
            });
        },

        //选择报价单预览功能
        toPreview(){
            this.chooseVisible = true;
            this.$axios.post(`${this.$sjUrl}/Quote/GetQuotes`,{
                quoteName:"",
                customerName:"",
                startTime:"",
                endTime:"",
                pageIndex: 1,
                pageSize: 50,
            }).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    this.quotationList = data.datas;
                }
            });
        },
        onPreview(){
            if(!this.chooseQuoteKey){
                this.$message({ message: '请选择一个报价单!', type: 'warning'});
                return false;
            }
            this.$axios.post(`${this.$sjUrl}/Quote/Preview`,{
                "templateKey": "",
                "templateContent": this.submitForm.templateContent,
                "quoteKey": this.chooseQuoteKey,
            }).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    this.chooseVisible = false;
                    this.previewVisible = true;
                    this.previewContent = data;
                }
            });
        },

        //导出Pdf
        // exportPdf(){
        //     let templateKey = "b9afb67c94e947669e945b132726258a";
        //     let quoteKey = "47c286d2d35f400ca92688c5db042cf1";
        //     this.$axios({
        //         url: `${this.$sjUrl}/Quote/ExportPdf?templateKey=${templateKey}&quoteKey=${quoteKey}`,
        //         method: 'GET',
        //         responseType: 'blob',
        //     }).then(response => {
        //         var blob = new Blob([response]);
        //         var link= document.createElement('a');
        //         link.download = `报价单.pdf`;
        //         link.href = URL.createObjectURL(blob);
        //         link.click();
        //         this.$message({ message: "导出报价单成功",type: 'success' });
        //     });
        // },

        //插入变量
        insertVariable(value){
            let themeColor = localStorage.getItem('theme');
            getTinymce().activeEditor.insertContent(`<span contenteditable="false" style="color:${themeColor};cursor: pointer;">${value}</span>`);
        },

        //编辑产品表格
        editSubmit(){
            if(this.checkField.length < 3){
                this.$message({ message: '至少勾选3个字段!', type: 'warning'});
                return false;
            }
            // 1.获取重新渲染的数据(字符串)
            let renderList = [];
            this.checkField.forEach(item =>{
                this.productVar.forEach(element =>{
                    if(item == element.label){
                        renderList.push(element);
                    }
                })
            })
            let aString = "";
            let bString = "";
            renderList.forEach(element =>{
                aString += `<td style="empty-cells: show; line-height: normal; vertical-align: middle; outline-width: 0px; overflow-wrap: break-word; word-break: normal; text-align: center; font-size: 11pt; background-color: #b0c4de; font-weight: bold; font-family: 宋体; border: 1px solid #000000;"><span style="white-space: pre-wrap; font-size: 11pt;">${element.enName}</span></td>`;
                bString += `<td style="empty-cells: show; line-height: normal; vertical-align: middle; outline-width: 0px; overflow-wrap: break-word; word-break: normal; text-align: center; font-size: 11pt; background-color: #ffff00; font-weight: bold; font-family: 宋体; border: 1px solid #000000;">${element.value}</td>`
            })
            let resultString = `<table style="width:100%;border: 1px solid black;border-collapse: collapse;cursor: pointer;" contenteditable="false" id="productEdit">
                <thead>
                    <tr style="height: 24px;">${aString}</tr>
                </thead>
                <tbody class="products">
                    <tr style="height: 24px;">${bString}</tr>
                </tbody>
            </table>`
            // 2.生成一个新的表格
            let insertTable = document.createElement('table');
            insertTable.style.width = "100%";
            insertTable.style.border = "1px solid black";
            insertTable.style.borderCollapse = "collapse";
            insertTable.style.cursor = "pointer";
            insertTable.setAttribute("contenteditable","false");
            insertTable.setAttribute("id","productEdit");
            insertTable.innerHTML = resultString;
            // 3.获取需要删除的表格,并用新表格替换
            let bodyHtml = getTinymce().activeEditor.getBody();
            let delTable = bodyHtml.querySelector('#productEdit');
            bodyHtml.replaceChild(insertTable,delTable);
            this.isEditProduct = false;
        },

    },

    mounted() {
        this.$axios.post(`${this.$sjUrl}/ExportTemplate/Pages`,{
            "templateName": "",
            "pageIndex": 1,
            "pageSize": 10
        }).then((res) => {
            const { isSuccess , data } = res;
            if (isSuccess) {
                // console.log(data.datas);
                let templateKey = this.$route.query.templateKey;
                if(templateKey){
                    let editItem = data.datas.filter(item => item.key == templateKey)[0];
                    this.submitForm = {
                        key: editItem.key,
                        templateName: editItem.templateName,
                        templateContent: editItem.templateContent,
                        isSysTem: false,
                    };
                }else{
                    let systemItem = data.datas.filter(item => item.templateName == '系统模板')[0];
                    this.submitForm.templateContent = systemItem.templateContent;
                }
                //监听编辑器点击事件
                setTimeout(() => {
                    let bodyHtml = getTinymce().activeEditor.getBody();
                    // console.log(bodyHtml);
                    bodyHtml.addEventListener('click', (ev) => {
                        let nodeDiv1 = ev.target.parentNode.parentNode.parentNode;
                        let nodeDiv2 = ev.target.parentNode.parentNode.parentNode.parentNode;
                        let idName = nodeDiv1.getAttribute('id') || nodeDiv2.getAttribute('id');
                        // console.log(idName);
                        if(idName == 'productEdit'){
                            this.isEditProduct = true;
                        }
                    }, true)
                    // getTinymce().activeEditor.on('click', function(){
                    //     console.log('edit click');
                    // })
                }, 1000);
            }
        });

    },
};
</script>

<style lang="scss" scoped>
.viewbox{
    background: #FFFFFF;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    padding: 20px;
    .title{
        height: 32px;
        line-height: 32px;
        font-size: 20px;
        font-weight: bold;
        color: #383838;
        margin-bottom: 10px;
    }
    .mainContent{
        display: flex;
        .leftInput{
            width: calc(100% - 320px);
            .saveBtn{
                float: right;
                margin-top: 10px;
                margin-left: 20px;
            }
        }
        .rightChoose{
            width: 300px;
            margin-left: 20px;
            margin-top: 42px;
            border: 1px solid #ccc;
            box-sizing: border-box;
            height: 725px;
            overflow: auto;
            .tt{
                height: 32px;
                line-height: 32px;
                font-size: 16px;
                font-weight: bold;
                color: #383838;
                margin-bottom: 10px;
                padding: 0 10px;
            }
            .varItem{
                cursor: pointer;
                height: 32px;
                line-height: 32px;
                padding: 0 10px;
                font-size: 14px;
                &:hover{
                    color: #FFFFFF;
                    background-color: var(--themeColor);
                    opacity: 0.6;
                }
                .label{
                    display: inline-block;
                    width: 90px;
                }
            }
        }
    }
}
/deep/ .el-dialog__body{
    padding: 10px 20px;
    .el-checkbox{
        margin-bottom: 10px;
    }
}
</style>