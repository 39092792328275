<template>
    <div class="viewbox">
        <div class="header">
            <img class="logo" src="@/assets/cluePicture/logo-socialMedia.png" alt="" />
            <div>
                <div class="theme">社媒线索</div>
                <div class="txt">统计来自社媒的相关线索</div>
            </div>
        </div>

        <div class="searchTerms">
            <div class="itemTerm">
                <div class="name">洲:</div>
                <div class="spanList">
                    <div class="spanItem" :class="{'active':continentIndex==''}" @click="getCountrys('')">不限</div>
                    <div class="spanItem" v-for="ele in continentList" :key="ele.name" :class="{'active':continentIndex==ele.name}" @click="getCountrys(ele.name)">
                        {{ele.name}}({{ele.count}})
                    </div>
                </div>
            </div>
            <div class="itemTerm" v-show="isShowCountry">
                <div class="name">国家:</div>
                <div class="spanList">
                    <div class="spanItem" :class="{'active':countryIndex==''}" @click="clickCountry('')">不限</div>
                    <div class="spanItem" v-for="(ele,index) in countryList" v-show="index < showCountryNumMax" :key="ele.country" :class="{'active':countryIndex==ele.country}" @click="clickCountry(ele.country)">
                        {{ele.country}}({{ele.count}})
                    </div>
                    <div class="spanItem" style="color:var(--themeColor);" v-show="showCountryNumMax < countryList.length" @click="showCountryNumMax = countryList.length;">查看更多</div>
                </div>
            </div>
            <div class="searchBox" style="margin: 10px 0;">
                <el-input placeholder="公司关键字" prefix-icon="el-icon-search" v-model="searchValue" @keyup.enter.native="onSearch" style="width:600px;"></el-input>
                <el-button type="primary" style="margin-left:20px;" @click="onSearch" :loading="searchLoading">搜索</el-button>
            </div>
        </div>

        <div class="searchResult" v-show="totalItems==0" v-loading="searchLoading">
            <el-empty :image-size="200"></el-empty>
        </div>

        <div class="searchResult" v-show="totalItems!=0" v-loading="searchLoading">
            <div class="title">为您找到<span style="color:#FF2D2D;">{{totalItems}}</span>条相关结果</div>
            <div class="resultList">
                <div class="resultItem" v-for="item in resultList" :key="item.key">
                    <div style="margin-bottom: 8px;">
                        <div class="name" @click="toDetails(item)">{{item.company}}</div>
                        <div class="country">
                            <img :src="item.flagUrl" style="margin-right:5px;height:14px" />
                            <span>{{item.country}}</span>
                        </div>
                    </div>
                    <div style="display:flex;">
                        <div class="oneField">
                            <div class="color99">最新交易日期</div>
                            <div class="color3a">{{item.lastDate}}</div>
                        </div>
                        <div class="oneField">
                            <div class="color99">交易总次数</div>
                            <div class="color3a">{{item.totalNum}}</div>
                        </div>
                        <div class="oneField">
                            <div class="color99">交易国家数</div>
                            <div class="color3a">{{item.countryNum}}</div>
                        </div>
                        <div class="oneField">
                            <div class="color99">交易伙伴数</div>
                            <div class="color3a">{{item.partnerNum}}</div>
                        </div>
                    </div>
                </div>
                <el-pagination background layout="total,sizes,prev,pager,next" :page-sizes="[10, 20, 40]" :page-size.sync="pageSize" @size-change="pageSizeChange" :total="totalItems" style="float:right;margin-top:15px;" :current-page.sync="pageIndex" @current-change="pageIndexChange"></el-pagination>
            </div>
        </div>

        <!-- 验证解锁 -->
        <el-dialog title="验证解锁" :visible.sync="dialogVisible" width="500px" :show-close="false"
            :close-on-click-modal="false" :close-on-press-escape="false">
            <p style="line-height:28px;font-weight:bold;">请求过于频繁，请输入验证码：</p>
            <div style="display:flex;">
                <el-input v-model="imgCode" size="small"></el-input>
                <img class="pointer" :src="imgSrc" alt="" @click="openVerify" />
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" :disabled="!imgCode" @click="finishVerify">确 认</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
export default {
    data() {
        return {
            //搜索条件：洲，国家
            continentList: [],
            continentIndex: "",
            countryList: [],
            countryIndex: "",
            isShowCountry: false,
            showCountryNumMax: 10,
            //搜索结果
            searchValue: "",
            searchLoading: false,
            resultList: [],
            totalItems: 0,
            pageIndex: 1,
            pageSize: 10,
            //验证解锁
            dialogVisible: false,
            imgCode: "",
            imgKey: "",
            imgSrc: "",
        };
    },
    methods: {
        getContinents(){
            this.$axios.get(`${this.$clueUrl}/Linkedin/GetContinent`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.continentList = data;
                }
            });
        },
        getCountrys(continent){
            this.continentIndex = continent;
            this.countryIndex = "";
            this.countryList = [];
            this.showCountryNumMax = 10;
            // this.onSearch();
            if(continent){
                this.isShowCountry = true;
                this.$axios.get(`${this.$clueUrl}/Linkedin/GetCountry?continent=${continent}`).then((res) => {
                    const { isSuccess, data } = res;
                    if (isSuccess) {
                        this.countryList = data;
                    }
                });
            }else{
                this.isShowCountry = false;
            }
        },        
        clickCountry(country){
            this.countryIndex = country;
            this.onSearch();
        },
        
        //获取搜索结果
        getResult(){
            //请求接口获取数据
            this.searchLoading = true;
            this.$axios.get(`${this.$clueUrl}/Linkedin/Search?country=${this.countryIndex}&company=${this.searchValue}&pageIndex=${this.pageIndex}&pageSize=${this.pageSize}`)
            .then((res) => {
                const { isSuccess, data, errorMessage } = res;
                this.searchLoading = false;
                if (isSuccess) {
                    this.resultList = data.datas.map(item=>({
                        ...item,
                        flagUrl: window.getFlagUrlByCountry?window.getFlagUrlByCountry(item.country):'',
                    }))
                    this.totalItems = data.totalItems;
                    if(this.totalItems == 0){
                        this.$message({ message: '搜索无数据,请修改搜索条件重试!',type: 'warning' });
                    }
                }else{
                    if(errorMessage == "请求过于频繁，请输入验证码"){
                        this.openVerify();
                    }
                }
            });
        },
        onSearch(){
            this.pageIndex = 1;
            this.getResult();
        },
        pageIndexChange(page) {
            this.pageIndex = page;
            if(this.pageIndex > 30){
                this.pageIndex = 30;
                this.$notify({ title: '警告',message: '目前线索模块处于免费试用阶段,搜索页码不能超过30!',type: 'warning' });
                return false;
            }
            this.getResult();
        },
        pageSizeChange() {
            this.pageIndex = 1;
            this.getResult();
        },


        //请求频繁，需要输入验证解锁
        openVerify(){
            this.dialogVisible = true;
            this.$axios.get(`${this.$clueUrl}/SecurityStrategy/GetVerifycode`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.imgKey = data.key;
                    this.imgSrc = data.image;
                }
            });
        },
        finishVerify(){
            this.$axios.get(`${this.$clueUrl}/SecurityStrategy/VerifyVerificationCode?key=${this.imgKey}&code=${this.imgCode}`).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {                  
                    this.dialogVisible = false;
                    this.imgCode = "";
                    this.onSearch();
                }
            });           
        },

    },
    mounted() {
        this.getContinents();
        
        this.getResult();
    },
};
</script>

<style lang="scss" scoped>
@import '@/scss/common.scss';
.viewbox{
    background: rgba($color: #FFFFFF, $alpha: 0.7);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    padding: 20px;
    .header{
        display: flex;
        padding-bottom: 20px;
        border-bottom: 1px solid #E1E1E1;
        .logo{
            padding: 0 15px;
        }
        .theme{
            font-size: 20px;
            line-height: 32px;
            font-weight: bold;
            color: #092338;
        }
        .txt{
            font-size: 14px;
            line-height: 20px;
            color: #092338;
        }
    }

    .searchTerms{
        margin-top: 20px;
        .itemTerm{
            line-height: 26px;
            margin-bottom: 10px;
            overflow: hidden;
            .name{
                float: left;
                width: 65px;
                font-size: 14px;
                color: #092338;
                text-align: right;
                margin-right: 15px;
            }
            .spanList{
                float: left;
                width: calc(100% - 80px);
            }
            .spanItem{
                display: inline-block;
                font-size: 14px;
                color: #092338;
                padding: 0 10px;
                margin-right: 15px;
                cursor: pointer;
            }
            .active{
                color: #FFFFFF;
                background: $themeColor;
                border-radius: 2px;
            }
        }
    }

    .searchResult{
        margin-top: 20px;
        .title{
            height: 40px;
            line-height: 40px;
            background: #F1F1F1;
            color: #3A3A3A;
            padding-left: 20px;
        }
        .resultList{
            padding: 20px;
            overflow: hidden;
            .resultItem{
                font-size: 14px;
                padding: 10px 0;
                border-bottom: 1px solid #E8E8E8;
                position: relative;
                .name{
                    display: inline-block;
                    color: var(--themeColor);
                    text-decoration: underline;
                    cursor: pointer;
                }
                .country{
                    display: inline-block;
                    margin-left: 20px;
                }
                .color99{    
                    color: #999999;
                }
                .color3a{    
                    color: #3A3A3A;
                }
                .primaryColor{
                    color: var(--themeColor);
                    cursor: pointer;
                }
                .oneField{
                    width: 200px;
                    text-align: center;
                }
                .twoField{
                    line-height: 28px;
                }
                .addBtn{
                    position: absolute;
                    top: 10px;
                    right: 0;
                    padding: 0 10px 0 32px;
                    height: 28px;
                    line-height: 28px;
                    font-size: 14px;
                    color: #FFFFFF;
                    background: var(--themeColor);
                    border-radius: 4px;
                    cursor: pointer;
                    &:hover{
                       opacity: 0.7;
                    }
                    img{
                        position: absolute;
                        left: 10px;
                        top: 5px;
                    }
                }
                .hasAdd{
                    position: absolute;
                    top: 10px;
                    right: 0;
                    padding: 0 10px;
                    height: 28px;
                    line-height: 28px;
                    font-size: 14px;
                    color: #B7B7B7;
                    background: rgba(183, 183, 183, 0.2);
                    border: 1px solid #B7B7B7;
                    border-radius: 4px;
                }
            }
        }
    }

    /deep/.el-dialog__body {
        padding: 10px 20px;
    }
}
</style>