<template>
    <div>
        <!-- <el-select v-model="countryValue" placeholder="请选择国家" size="small" @change="getFestival" filterable>
            <el-option v-for="item in countryOption" :key="item" :label="item" :value="item"></el-option>
        </el-select> -->
        <div class="topOption">
            <PowerSelect defaultText="我的日程" @onChoose="onChoose" />
            
            <div style="display: flex;align-items: center;">
                <span style="color: #6a6a6a;padding-right: 10px;">显示国外节假日</span>
                <el-switch v-model="isShowFestival" @change="onShowChange"></el-switch>
            </div>
        </div>

        <FullCalendar ref="calendar" :options="calendarOptions" />

        <el-dialog :title="dialogTitle" :visible.sync="scheduleVisible" width="500px">
            <el-form label-width="80px" size="small">
                <el-form-item label="日程名称">
                    <el-input v-model="scheduleObj.scheduleName"></el-input>
                </el-form-item>
                <el-form-item label="日程类型">
                    <el-select v-model="scheduleObj.type">
                        <el-option label="不紧急不重要" :value="0"></el-option>
                        <el-option label="不紧急重要" :value="1"></el-option>
                        <el-option label="不重要且紧急" :value="2"></el-option>
                        <el-option label="紧急且重要" :value="3"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="开始时间">
                    <el-date-picker value-format="yyyy-MM-dd HH:mm" format="yyyy-MM-dd HH:mm" v-model="scheduleObj.startTime" type="datetime" placeholder="选择日期时间" style="width: 100%;"></el-date-picker>                   
                </el-form-item>
                <el-form-item label="结束时间">
                    <el-date-picker value-format="yyyy-MM-dd HH:mm" format="yyyy-MM-dd HH:mm" v-model="scheduleObj.endTime" type="datetime" placeholder="选择日期时间" style="width: 100%;"></el-date-picker>
                </el-form-item>
                <el-form-item label="提醒时间">
                     <el-select v-model="scheduleObj.remindTime">
                        <el-option label="日程开始时" :value="0"></el-option>
                        <el-option label="5分钟前" :value="5"></el-option>
                        <el-option label="10分钟前" :value="10"></el-option>
                        <el-option label="15分钟前" :value="15"></el-option>
                        <el-option label="30分钟前" :value="30"></el-option>
                        <el-option label="1个小时前" :value="60"></el-option>
                        <el-option label="2个小时前" :value="120"></el-option>
                        <el-option label="1天前" :value="1440"></el-option>
                        <el-option label="2天前" :value="2880"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="创建人" v-if="scheduleObj.createUserName">
                    {{ scheduleObj.createUserName }}
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button v-if="dialogTitle == '编辑日程'" size="small" type="danger" :loading="btnLoading" @click="delSchedule">删除日程</el-button>
                <el-button size="small" @click="scheduleVisible = false">取 消</el-button>
                <el-button size="small" type="primary" :loading="btnLoading" @click="saveSchedule">确 认</el-button>
            </span>
        </el-dialog>

        <el-dialog title="节假日详情" :visible.sync="festivalVisible" width="800px">
            <el-table :data="currentList" style="width: 100%" size="small">
                <el-table-column prop="country" label="国家"></el-table-column>
                <el-table-column prop="festivalDate" label="节日日期"></el-table-column>
                <el-table-column prop="festivalName" label="节日名称"></el-table-column>
                <el-table-column prop="datePeriod" label="日期段"></el-table-column>
                <el-table-column prop="describe" label="节日描述" width="200" show-overflow-tooltip></el-table-column>
                <el-table-column prop="habit" label="重要习惯" width="200" show-overflow-tooltip></el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="festivalVisible = false">取 消</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import PowerSelect from "@/components/PowerSelect.vue";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import dayjs from "dayjs";
let colors = ["#FEC000","#4472C4","#41B052","#FF504F"];
export default {
    components: { FullCalendar, PowerSelect },
    data() {
        return {
            organizationKey: "",
            salekey: "",
            calendarOptions: {
                plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
                headerToolbar: {
                    left: "prev,next today",
                    center: "title",
                    right: "dayGridMonth,timeGridWeek,timeGridDay",
                },
                initialView: "dayGridMonth",
                buttonText: {
                    today: "今日",
                    month: "月",
                    week: "周",
                    day: "日",
                    list: "列表",
                },
                initialEvents: [],
                locale: "zh-cn",
                height: 850,
                editable: true,
                selectable: true,
                selectMirror: true,
                dayMaxEvents: true,
                weekends: true,
                select: this.handleDateSelect,
                eventClick: this.handleEventClick,
                eventsSet: this.handleEvents,
                eventDrop: this.handleDrop,
                eventResize: this.handleDrop,
                eventAllow: this.eventAllow,
            },
            currentEvents: [],
            //新建日程-编辑日程
            dialogTitle: "",
            scheduleVisible: false,
            scheduleObj: {},
            btnLoading: false,

            //对应不同国家节日
            countryValue: "",
            countryOption: [],
            isShowFestival: true,
            oneSouce: {},
            allFestivalList: [],
            chinaList: [],
            foreignList: [],
            //查看节假日详情
            festivalVisible: false,
            currentList: [],
        };
    },
    methods: {
        //选中日期新建日程
        handleDateSelect(selectInfo) {
            this.dialogTitle = "新建日程";
            this.scheduleVisible = true;
            this.scheduleObj = {
                key: "",
                scheduleName: "",
                scheduleItems: [],
                type: 0,
                remindTime: 0,
                startTime: selectInfo.start,
                endTime: selectInfo.end,
            }
        },
        //点击日程
        handleEventClick(clickInfo) {
            if(clickInfo.event.id.indexOf("%%%") >= 0){
                this.viewFestivalDetail(clickInfo.event.startStr);
                return false;
            }
            this.dialogTitle = "编辑日程";
            this.scheduleVisible = true;
            let getItem = this.scheduleList.filter(item => item.key == clickInfo.event.id)[0];
            this.scheduleObj = {
                key: getItem.key,
                scheduleName: clickInfo.event.title,
                scheduleItems: getItem.scheduleItems,
                type: colors.indexOf(clickInfo.event.backgroundColor),
                remindTime: getItem.remindTime,
                startTime: clickInfo.event.start,
                endTime: clickInfo.event.end,
                createUserName: getItem.createUserName,
            }
        },

        //保存日程
        saveSchedule() {
            if(!this.scheduleObj.scheduleName){
                this.$message({message: '请填写日程名称!',type: 'warning'});
                return false;
            }
            if(!this.scheduleObj.startTime){
                this.$message({message: '请填写开始时间!',type: 'warning'});
                return false;
            }
            this.scheduleObj.startTime = dayjs(this.scheduleObj.startTime).format('YYYY-MM-DD HH:mm:ss');
            if(this.scheduleObj.startTime.split(" ")[1] === "00:00:00"){
                this.scheduleObj.startTime = this.scheduleObj.startTime.split(" ")[0];
            }
            this.scheduleObj.endTime = this.scheduleObj.endTime ? dayjs(this.scheduleObj.endTime).format('YYYY-MM-DD HH:mm:ss') : "";
            if(this.scheduleObj.endTime.split(" ")[1] === "00:00:00"){
                this.scheduleObj.endTime = this.scheduleObj.endTime.split(" ")[0];
            }
            // console.log(this.scheduleObj.endTime)
            this.btnLoading = true;
            this.$axios.post(`${this.$customerUrl}/WorkBench/SaveSchedule`, this.scheduleObj).then((res) => {
                const { isSuccess, data } = res;
                this.btnLoading = false;
                this.scheduleVisible = false;
                if (isSuccess) {
                    // this.getSchedule();
                    if(!this.scheduleObj.key){
                        this.scheduleObj.key = data;
                        let calendarApi = this.$refs.calendar.getApi()
                        calendarApi.addEvent({
                            id: this.scheduleObj.key,
                            title: this.scheduleObj.scheduleName,
                            start: this.scheduleObj.startTime,
                            end: this.scheduleObj.endTime,
                            color: colors[this.scheduleObj.type],
                        });
                        this.scheduleList.push(this.scheduleObj);
                    }else{
                        let calendarApi = this.$refs.calendar.getApi()
                        let events = calendarApi.getEvents();
                        if(events.length > 0){
                            events.forEach(event =>{
                                if(event.id == this.scheduleObj.key){
                                    event.remove()
                                }
                            })
                        }
                        this.scheduleList = this.scheduleList.filter(ele => ele.key != this.scheduleObj.key);
                        calendarApi.addEvent({
                            id: this.scheduleObj.key,
                            title: this.scheduleObj.scheduleName,
                            start: this.scheduleObj.startTime,
                            end: this.scheduleObj.endTime,
                            color: colors[this.scheduleObj.type],
                        });
                        this.scheduleList.push(this.scheduleObj);
                    }
                }
            })
        },
        //删除日程
        delSchedule(){
            this.btnLoading = true;
            this.$axios.get(`${this.$customerUrl}/WorkBench/DeleteSchedules?key=${this.scheduleObj.key}`).then((res) => {
                const { isSuccess } = res;
                this.btnLoading = false;
                this.scheduleVisible = false;
                if (isSuccess) {
                    // this.getSchedule();
                    let calendarApi = this.$refs.calendar.getApi()
                    let events = calendarApi.getEvents();
                    if(events.length > 0){
                        events.forEach(event =>{
                            if(event.id == this.scheduleObj.key){
                                event.remove()
                            }
                        })
                    }
                }
            })
        },

        //拖拽日程/改变日程时间范围
        handleDrop(clickInfo){
            if(clickInfo.event.id.indexOf("%%%") >= 0){
                return false;
            }
            let getItem = this.scheduleList.filter(item => item.key == clickInfo.event.id)[0];
            this.scheduleObj = {
                key: getItem.key,
                scheduleName: getItem.scheduleName,
                scheduleItems: getItem.scheduleItems,
                type: getItem.type,
                remindTime: getItem.remindTime,
                startTime: clickInfo.event.start,
                endTime: clickInfo.event.end,
            }
            this.saveSchedule();
        },

        handleEvents(events) {
            // console.log(events);
            this.currentEvents = events;
        },

        eventAllow(dropLocation, draggedEvent) {
            // console.log(dropLocation, draggedEvent);
            if (draggedEvent.id.indexOf("%%%") >= 0) {
                return false;
            }
            else {
                return true;
            }
        },

        //获取通知日程
        getSchedule(){
            let year = new Date().getFullYear();
            let month = new Date().getMonth() + 1;
            if(month < 10){ month = "0" + month; }
            this.$axios.post(`${this.$customerUrl}/WorkBench/GetSchedules`,{
                "year": year.toString(),
                "month": "",
                "organizationKey": this.organizationKey,
                "ukeys": this.salekey ? [this.salekey] : [],
            }).then((res) => {
                const { isSuccess,data } = res;
                if (isSuccess) {
                    this.scheduleList = data;
                    // console.log(this.scheduleList);
                    let calendarApi = this.$refs.calendar.getApi()
                    let events = calendarApi.getEvents();
                    if(events.length > 0){
                        events.forEach(event =>{
                            if(event.id.indexOf("%%%") < 0){
                                event.remove();
                            }
                        })
                    }
                    data.forEach(element => {
                        calendarApi.addEvent({
                            id: element.key,
                            title: element.scheduleName,
                            start: element.startTime.trim(),
                            end: element.endTime.trim(),
                            color: colors[element.type],
                        });
                    });
                }
            })
        },
        //选择组织结构、员工
        onChoose(valueOne,valueTwo){
            this.organizationKey = valueOne;
            this.salekey = valueTwo;
            this.getSchedule();
        },

        //获取节日日程
        getFestival(){
            let year = new Date().getFullYear().toString();
            this.$axios.get(`${this.$customerUrl}/WorkBench/GetFestivals?country=${this.countryValue}&year=${year}`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.allFestivalList = data;
                    //中国节假日事件渲染
                    this.chinaList = data.filter(item => item.country == "中国");
                    let renderList = [];
                    this.chinaList.forEach(element => {
                        renderList.push({
                            id: element.id + "%%%",
                            title: element.festivalName,
                            start: element.festivalStartDate.trim(),
                            end: element.festivalEndDate.trim(),
                            color: localStorage.getItem('theme'),
                        })
                    });
                    let calendarApi = this.$refs.calendar.getApi()
                    calendarApi.batchRendering(()=> {
                        calendarApi.addEventSource({events:renderList});
                    });
                    //国外节假日事件渲染
                    this.foreignList = data.filter(item => item.country != "中国");
                    this.onShowChange(true);
                }
            })
        },
        onShowChange(value){
            this.isShowFestival = value;
            if(value){
                let renderList = [];
                this.foreignList.forEach(element => {
                    renderList.push({
                        id: element.id + "%%%",
                        title: element.festivalName,
                        start: element.festivalStartDate.trim(),
                        end: element.festivalEndDate.trim(),
                        color: localStorage.getItem('theme'),
                    })
                });
                //通过将多个事件封装成事件源，然后一次性添加事件源，可以减少对日历进行多次的渲染操作，从而提高性能。
                let calendarApi = this.$refs.calendar.getApi()
                calendarApi.batchRendering(()=> {
                    this.oneSouce = calendarApi.addEventSource({events:renderList});
                });
            }else{
                this.oneSouce.remove();
            }
        },
        //查看节假日详情
        viewFestivalDetail(itemDate){
            this.festivalVisible = true;
            this.currentList = this.allFestivalList.filter(item => item.festivalStartDate == itemDate);
            // console.log(this.currentList);
        },

        // getCountry(){
        //     this.$axios.get(`${this.$customerUrl}/WorkBench/GetFestivalCountry`).then((res) => {
        //         const { isSuccess,data } = res;
        //         if (isSuccess) {
        //             this.countryOption = data;
        //         }
        //     })
        // },

    },
    mounted() {
        // console.log(this.$refs.calendar);

        this.getSchedule();
        this.getFestival();
        // this.getCountry();
    },
};
</script>

<style lang="scss" scoped>
.topOption{
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}
</style>