<template>
    <div v-if="fieldList.length > 0">
        <div v-for="(element,elIndex) in fieldList" :key="element.groupName">
            <div class="title">{{element.groupName}}</div>
            <el-form label-width="120px" size="small" label-position="left">
                <el-row :gutter="16">
                    <el-col :span="8" v-for="(item,index) in element.fields" :key="index">
                        <el-form-item :label="item.field">
                            <el-input v-model="item.value"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" v-if="elIndex+1==fieldList.length">
                        <el-button v-if="documentKey" type="primary" size="small" @click="onSave" :loading="btnLoading">保 存</el-button>
                    </el-col>
                </el-row>
            </el-form>
        </div>
    </div>
</template>

<script>
export default {
    props:["type","documentKey"],
    data() {
        return {
            btnLoading: false,
            fieldList: [],
        };
    },
    methods: {
        getField(){
            // if(!this.documentKey){return false;}
            this.$axios.get(`${this.$customerUrl}/DynamicField/v2/GetDocumentFieldValues?documentKey=${this.documentKey}&type=${this.type}`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.fieldList = data;
                }
            });
        },
        onSave(key){
            let fieldValues = [];
            this.fieldList.forEach(element => {
                element.fields.forEach(ele => {
                    fieldValues.push({
                        dynamicFieldKey: ele.key,
                        fieldValue: ele.value,
                    })
                });
            })
            this.btnLoading = true;
            this.$axios.post(`${this.$customerUrl}/DynamicField/SaveFieldValue`,{
                documentKey: this.documentKey || key,
                fieldValues: fieldValues,
            }).then((res) => {
                const { isSuccess } = res;
                this.btnLoading = false;
                if (isSuccess) {
                    this.$message({ message: '保存成功', type: 'success'});
                }
            });
        }, 
    },
    mounted() {
        this.getField();
    },
    watch: {
        documentKey(){
            this.getField();
        }
    }
};
</script>
<style lang="scss" scoped>
.title{
    height: 20px;
    line-height: 20px;
    font-size: 16px;
    font-weight: bold;
    color: #3A3A3A;
    margin-bottom: 10px;
    border-left: 3px solid var(--themeColor);
    padding-left: 5px;
}
</style>