<template>
    <div>
        <!-- 孟加拉国(BGD)进口提单明细/孟加拉国(BGD)出口提单明细(字段共用) -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th width="25%">发票日期</th>
                        <td width="25%">{{billInfo.ReceiptDate}}</td>
                        <th width="25%">入境单号</th>
                        <td width="25%">{{billInfo.BeNumber}}</td>
                    </tr>
                    <tr>
                        <th>入境日期</th>
                        <td>{{billInfo.BeDate}}</td>
                        <th>类型</th>
                        <td>{{billInfo.Type}}</td>
                    </tr>
                    <tr>
                        <th>进口商代码</th>
                        <td>{{billInfo.ImporterCode}}</td>
                        <th>供应商代码</th>
                        <td>{{billInfo.ExporterCode}}</td>
                    </tr>
                    <tr>
                        <th>出口国</th>
                        <td>{{billInfo.ExportCountry}}</td>
                        <th>原产国</th>
                        <td>{{billInfo.Country}}</td>
                    </tr>
                    <tr>
                        <th>标记数字</th>
                        <td>{{billInfo.MarksNumbers}}</td>
                        <th>项目</th>
                        <td>{{billInfo.Items}}</td>
                    </tr>
                    <tr>
                        <th>编号</th>
                        <td>{{billInfo.ItemNo}}</td>
                        <th>包装数</th>
                        <td>{{billInfo.PackagesItemsNo}}</td>
                    </tr>
                    <tr>
                        <th>总包装数</th>
                        <td>{{billInfo.TotalPackages}}</td>
                        <th>净重</th>
                        <td>{{billInfo.NetWeight}}</td>
                    </tr>
                    <tr>
                        <th>数量单位</th>
                        <td>{{billInfo.Unit}}</td>
                        <th>声明的单位价格（外币）</th>
                        <td>{{billInfo.DeclaredUnitPrice}}</td>
                    </tr>
                    <tr>
                        <th>可评估的单位价格（外币）</th>
                        <td>{{billInfo.AssessableUnitPrice}}</td>
                        <th>物品价格（外币）</th>
                        <td>{{billInfo.ItemPrice}}</td>
                    </tr>
                    <tr>
                        <th>发票总值（外币）</th>
                        <td>{{billInfo.TotalInvoiceValue}}</td>
                        <th>币种</th>
                        <td>{{billInfo.Currency}}</td>
                    </tr>
                    <tr>
                        <th>评估价（孟加拉币种）</th>
                        <td>{{billInfo.AssessableValueBDT}}</td>
                        <th>评估价（USD）</th>
                        <td>{{billInfo.AssessableValueUSD}}</td>
                    </tr>
                    <tr>
                        <th>税收总额（孟加拉币种）</th>
                        <td>{{billInfo.TotalTaxBDT}}</td>
                        <th>总申报价格</th>
                        <td>{{billInfo.TotalDeclarationPrice}}</td>
                    </tr>
                    <tr>
                        <th>其他费用合计</th>
                        <td>{{billInfo.TotalOtherCosts}}</td>
                        <th>罚金</th>
                        <td>{{billInfo.FinePenalty}}</td>
                    </tr>
                    <tr>
                        <th>汇率</th>
                        <td>{{billInfo.ExchangeRate}}</td>
                        <th>贸易术语</th>
                        <td>{{billInfo.DeliveryTerms}}</td>
                    </tr>
                    <tr>
                        <th>银行代码</th>
                        <td>{{billInfo.BankCode}}</td>
                        <th>分行代码</th>
                        <td>{{billInfo.BranchCode}}</td>
                    </tr>
                    <tr>
                        <th>信用证号</th>
                        <td>{{billInfo.LcNo}}</td>
                        <th>行业基金</th>
                        <td>{{billInfo.SectorFund}}</td>
                    </tr>
                    <tr>
                        <th>农业代码</th>
                        <td>{{billInfo.AgrCode}}</td>
                        <th>提单编号</th>
                        <td>{{billInfo.BillOfLadingNumber}}</td>
                    </tr>
                    <tr>
                        <th>MENIFEST</th>
                        <td>{{billInfo.Menifest}}</td>
                        <th>CPC_CODE</th>
                        <td>{{billInfo.CpcCode}}</td>
                    </tr>
                    <tr>
                        <th>付款方式</th>
                        <td>{{billInfo.PaymentMode}}</td>
                        <th>评估编号</th>
                        <td>{{billInfo.AssessmentNumber}}</td>
                    </tr>
                    <tr>
                        <th>评估日期</th>
                        <td>{{billInfo.AssessmentDate}}</td>
                        <th>收据号</th>
                        <td>{{billInfo.ReceiptNumber}}</td>
                    </tr>
                    <tr>
                        <th>DF CV ITC FP</th>
                        <td>{{billInfo.DfCvItcFp}}</td>
                        <th>发票号码</th>
                        <td>{{billInfo.InvoiceNo}}</td>
                    </tr>
                    <tr>
                        <th>CRF EXP NO</th>
                        <td>{{billInfo.CrfExpNo}}</td>
                        <th>UP_UD</th>
                        <td>{{billInfo.UpUd}}</td>
                    </tr>
                    <tr>
                        <th>A_D</th>
                        <td>{{billInfo.AD}}</td>
                        <th>代理人参考</th>
                        <td>{{billInfo.AgentReference}}</td>
                    </tr>
                    <tr>
                        <th>名称运营商的国籍</th>
                        <td>{{billInfo.NameCarrierNationlity}}</td>
                        <th>交通运输部</th>
                        <td>{{billInfo.Mot}}</td>
                    </tr>
                    <tr>
                        <th>货物位置</th>
                        <td>{{billInfo.GoodsLocation}}</td>
                        <th>办公入口</th>
                        <td>{{billInfo.OfficeEntryExit}}</td>
                    </tr>
                    <tr>
                        <th>卸货地点代码</th>
                        <td>{{billInfo.UnloadingPlaceCode}}</td>
                        <th>卸货地点</th>
                        <td>{{billInfo.UnloadingPlace}}</td>
                    </tr>
                    <tr>
                        <th>位置商品</th>
                        <td>{{billInfo.LocationGoods}}</td>
                        <th>办公代码</th>
                        <td>{{billInfo.OfficeCode}}</td>
                    </tr>
                    <tr>
                        <th>港口办公室名称</th>
                        <td>{{billInfo.PortOfficeName}}</td>
                        <th>PROC</th>
                        <td>{{billInfo.Proc}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
</template>

<script>
export default {
    props:["billInfo","ie"],
    data() {
        return {

        };
    },
    methods: {
        
    },
    mounted() {

    },
};
</script>

<style lang="scss" scoped>
.partItem {
    margin-bottom: 20px;
    /deep/.el-card__header {
        padding: 10px 20px;
        .headTitle {
            display: inline-block;
            padding-left: 10px;
            font-size: 14px;
            border-left: 2px solid var(--themeColor);
            font-weight: bold;
        }
    }
}
table {
    width: 100%;
    border-collapse: collapse;
    tr {
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;
        th,
        td {
            font-size: 12px;
            color: #666;
            height: 40px;
            border: 1px solid #eaeaea;
            text-align: center;
        }
        th {
            background-color: #f5f7fa;
            font-weight: 700;
        }
        .bg {
            background-color: var(--themeColor);
            color: #fff;
            font-weight: 700;
            opacity: 0.8;
        }
    }
}
</style>