<script>
import Vue from "vue";
import router from "@/router";
import { reactive, ref } from "@vue/composition-api";
import axios from "@/assets/js/axios";
import dayjs from "dayjs";
import { MessageBox, Message } from "element-ui";
import CMenu from "./components/Menu.vue";
import { watchDebounced } from "@vueuse/core";
import MarketingNav from "@/components/MarketingNav.vue";
export default Vue.extend({
    components: {
        MarketingNav,
        CMenu,
    },
    setup() {
        const bill = reactive({
            data: "--",
        });
        (async () => {
            const res = await axios({
                method: "GET",
                url: Vue.prototype.$marketUrl + "/api/Bill/GetSendPoint",
            });
            bill.data = res.data.surplusCount;
        })();

        const cardData = reactive({
            sendEmailTotalCount: 0,
            serviceTotalCount: 0,
            openTotalCount: 0,
            replyTotalCount: 0,
            unsubscribeTotalCount: 0,
        });

        (async () => {
            const res = await axios({
                method: "GET",
                url: Vue.prototype.$marketUrl + "/WhatsApp/GetEventReport",
            });
            const data = res.data;
            cardData.sendEmailTotalCount = data.marketingCount;
            cardData.serviceTotalCount = data.replyCount;
            cardData.openTotalCount = data.opneCount;
            cardData.letterReadingRate = data.eventCount;
        })();

        const form = reactive({
            state: "",
            eventName: "",
            whatsApp: "",
            sendTime: "",
        });

        watchDebounced(
            [() => form.eventName, () => form.whatsApp],
            () => getListData(),
            {
                debounce: 500,
            }
        );

        const list = reactive({
            loading: false,
            data: [],
            page: {
                total: 0,
                size: 10,
                index: 1,
            },
        });
        const getListData = async (pageIndex = 1) => {
            try {
                list.loading = true;
                const res = await axios({
                    method: "POST",
                    url: Vue.prototype.$marketUrl + "/WhatsApp/GetSendEvents",
                    data: {
                        pageIndex: pageIndex,
                        pageSize: 10,
                        state: form.state,
                        eventName: form.eventName,
                        whatsApp: form.whatsApp,
                        sendTime: form.sendTime,
                    },
                });
                // console.log(res.data);
                list.data = res.data.datas;
                list.page.index = pageIndex;
                list.page.total = res.data.totalItems;
            } catch {
                null;
            } finally {
                list.loading = false;
            }
        };
        getListData();

        const onCommand = async (item) => {
            item.loading = true;
            const res = await axios({
                method: "GET",
                url: Vue.prototype.$marketUrl + "/MassSending/UpdateReport",
                params: {
                    campaignId: item.campaignId,
                },
            });
            item.loading = false;
            res.isSuccess && getListData();
        };

        const onNewTask = () => {
            let routeData = router.resolve({
                // path: '/marketingWA/NewWA',
                path: "/marketingWA/WAMass",
            });
            window.open(routeData.href, "_blank");
        };
        const againSend = (item) => {
            let routeData = router.resolve({
                path: "/marketingWA/WAMass",
                query: {
                    againSendKey: item.eventKey,
                },
            });
            window.open(routeData.href, "_blank");
        }

        const deleteTask = (k) => {
            MessageBox.confirm("此操作将删除任务, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    axios({
                        method: "GET",
                        url: Vue.prototype.$marketUrl + "/WhatsApp/DeleteEvent",
                        params: {
                            eventKey: k,
                        },
                    }).then((res) => {
                        res.isSuccess && getListData();
                    });
                })
                .catch(() => {});
        };
        const withdrawTask = (k) => {
            MessageBox.confirm("此操作将撤回任务, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    axios({
                        method: "GET",
                        url:
                            Vue.prototype.$marketUrl +
                            "/WhatsApp/WithdrawEvent",
                        params: {
                            eventKey: k,
                        },
                    }).then((res) => {
                        res.isSuccess && getListData();
                    });
                })
                .catch(() => {});
        };

        const afregerg = (k) => {
            MessageBox.confirm("此操作将暂停任务, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    axios({
                        method: "GET",
                        url: Vue.prototype.$marketUrl + "/WhatsApp/StopEvent",
                        params: {
                            eventKey: k,
                        },
                    }).then((res) => {
                        res.isSuccess && getListData();
                    });
                })
                .catch(() => {});
        };

        const toView = (item) => {
            const { href: location } = router.resolve({
                name: "ReportWA",
                query: {
                    campaignId: item.eventKey,
                },
            });
            window.open(location, "_blank");
        };

        const fwnoreng = ([c, k]) => {
            // console.log({ c, k })
            if (c === "1") {
                deleteTask(k);
            } else if (c === "2") {
                withdrawTask(k);
            } else if (c === "3") {
                afregerg(k);
            } else if (c === "4") {
                const { href: location } = router.resolve({
                    // name: "NewWA",
                    name: "WAMass",
                    query: {
                        eventNo: k,
                    },
                });
                window.open(location, "_blank");
            } else if (c === "5") {
                // 直接发送
                axios({
                    method: "GET",
                    url: Vue.prototype.$marketUrl + "/WhatsApp/v2/SendEvent",
                    params: {
                        eventKey: k,
                    },
                }).then((res) => {
                    if (res.isSuccess) {
                        Message.success("发送成功");
                        getListData();
                    }
                });
            }
        };

        return {
            form,
            bill,
            cardData,
            list,
            getListData,
            onCommand,
            onNewTask,
            dayjs,
            deleteTask,
            withdrawTask,
            currentPath: "/marketingWA/TaskDashboardWA",
            toView,
            againSend,
            fwnoreng,
        };
    },
});
</script>

<template>
    <div style="width: 100%; height: 100%">
        <div style="display: flex;width: 100%; height: calc(100%);">
            <div>
                <CMenu active="TaskDashboardWA" />
            </div>
            <div class="view" style="width: 100%;">
                <div style="display: flex; justify-content: space-between">
                    <span style="color: #3A3A3A;font-weight: bold;font-size: 18px;">WhatsApp群发任务</span>
                    <div style="display: flex; align-items: center">
                        <el-button @click="onNewTask" size="mini" type="primary">新建任务</el-button>
                    </div>
                </div>
                <div style="padding: 10px 0">
                    <el-select @change="getListData()" v-model="form.state" style="margin-right: 12px" size="small">
                        <el-option label="全部" value="" />
                        <el-option v-for="(ite, i) of ['草稿', '已发送', '暂停', '发送中', '撤回', 'job发送中']" :label="ite" :key="i" :value="i.toString()" />
                    </el-select>
                    <el-input v-model="form.eventName" placeholder="任务名称" style="width: 224px;margin-right: 12px" size="small" @keyup.enter.native="getListData()" />
                    <el-date-picker @change="getListData()" v-model="form.sendTime" value-format="yyyy-MM-dd" placeholder="任务时间" style="margin-right: 12px" size="small" />
                    <el-input v-model="form.whatsApp" placeholder="收件人" style="width: 224px" size="small" @keyup.enter.native="getListData()" />
                </div>
                <div style="display: flex;">
                    <div class="card">
                        <img src="@/assets/img/market/icon-1.png" style="width: 46px; height: 46px" />
                        <div style="display: flex; flex-direction: column; justify-content: space-between; height: 46px; overflow: hidden">
                            <p style="color: #9C9C9C; font-size: 14px">营销人数</p>
                            <p style="font-weight: bold; font-size: 18px">{{  cardData.sendEmailTotalCount  }}</p>
                        </div>
                    </div>
                    <div class="card">
                        <img src="@/assets/img/market/icon-3.png" style="width: 46px; height: 46px" />
                        <div style="display: flex; flex-direction: column; justify-content: space-between; height: 46px; overflow: hidden">
                            <p style="color: #9C9C9C; font-size: 14px">读信人数</p>
                            <p style="font-weight: bold; font-size: 18px">{{  cardData.openTotalCount  }}</p>
                        </div>
                    </div>
                    <div class="card">
                        <img src="@/assets/img/market/icon-2.png" style="width: 46px; height: 46px" />
                        <div style="display: flex; flex-direction: column; justify-content: space-between; height: 46px; overflow: hidden">
                            <p style="color: #9C9C9C; font-size: 14px">回复人数</p>
                            <p style="font-weight: bold; font-size: 18px">{{  cardData.serviceTotalCount  }}</p>
                        </div>
                    </div>
                    <div class="card">
                        <img src="@/assets/img/market/icon-4.png" style="width: 46px; height: 46px" />
                        <div style="display: flex; flex-direction: column; justify-content: space-between; height: 46px; overflow: hidden">
                            <p style="color: #9C9C9C; font-size: 14px">任务数</p>
                            <p style="font-weight: bold; font-size: 18px">{{  cardData.letterReadingRate  }}</p>
                        </div>
                    </div>
                </div>
                <div v-loading="list.loading" style="box-sizing: border-box; height: calc(100% - 130px); padding-top: 20px;">
                    <p style="color: #3A3A3A;font-weight: bold;font-size: 18px;">任务列表</p>
                    <div style="height: calc(100% - 50px);overflow-y: auto;">
                        <el-table :data="list.data" :header-cell-style="{ background: '#F0F0F0', color: '#797979' }">
                            <el-table-column prop="eventName" label="任务名称" />
                            <el-table-column prop="totalCount" label="营销人数" />
                            <el-table-column prop="openCount" label="读信人数" />
                            <el-table-column prop="replyCount" label="回复人数" />
                            <el-table-column prop="sendTime" label="执行时间" />
                            <el-table-column prop="stateStr" label="状态" />
                            <el-table-column label="操作">
                                <template slot-scope="{row}">
                                    <div style="display: flex; align-items: center;">
                                        <el-link @click="toView(row)" type="primary" :underline="false" style="margin-right: 10px;">查看</el-link>
                                        <el-link @click="againSend(row)" type="primary" :underline="false" style="margin-right: 10px;">再次群发</el-link>
                                        <!-- ['草稿', '已发送', '暂停', '发送中', '撤回', 'job发送中'] -->
                                        <el-dropdown v-if="![1, 3].includes(row.state)" trigger="click" @command="fwnoreng">
                                            <el-link type="primary" :underline="false">更多</el-link>
                                            <el-dropdown-menu slot="dropdown">
                                                <el-dropdown-item v-if="[0, 4].includes(row.state)" :command="['1', row.eventKey]">删除</el-dropdown-item>
                                                <el-dropdown-item v-if="[5].includes(row.state)" :command="['2', row.eventKey]">撤回</el-dropdown-item>
                                                <el-dropdown-item v-if="[5].includes(row.state)" :command="['3', row.eventKey]">暂停</el-dropdown-item>
                                                <el-dropdown-item v-if="[0, 2, 4].includes(row.state)" :command="['4', row.eventKey]">编辑</el-dropdown-item>
                                                <el-dropdown-item v-if="[0].includes(row.state)" :command="['5', row.eventKey]">发送</el-dropdown-item>
                                            </el-dropdown-menu>
                                        </el-dropdown>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div style="display: flex; justify-content: flex-end">
                            <el-pagination hide-on-single-page :current-page.sync="list.page.index" :page-size="list.page.size" :total="list.page.total" @current-change="getListData" background layout="prev, pager, next" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.asd {
    ::v-deep .el-input__inner {
        padding-left: 45px !important;
    }

    ::v-deep .el-input.is-disabled .el-input__inner {
        background: #fff;
    }
}

.view {
    box-sizing: border-box;
    padding: 20px;
    width: 100%;
    height: 100%;
    background: #fff;
}

.card {
    width: 100%;
    border: 1px solid #cdcdcd;
    border-radius: 4px;
    display: flex;
    padding: 10px;

    img {
        margin-right: 10px;
    }

    &:not(:last-of-type) {
        margin-right: 20px;
    }
}

.item {
    border-bottom: 1px solid #cdcdcd;
    display: flex;
    align-items: center;
    position: relative;
    margin-right: 20px;
    padding: 20px 0;
}

.el-icon-refresh,
.el-icon-loading {
    cursor: pointer;
    padding-left: 5px;
    font-size: 20px;
    color: var(--themeColor);
}

::v-deep .el-input--mini .el-input__inner {
    border: none;
}

.el-icon-search {
    cursor: pointer;
    line-height: 28px;
    color: #3a3a3a;
}

.draft,
.dingshi,
.withdraw {
    display: inline-block;

    .elseOption {
        display: none;
    }
}

.item:hover .elseOption {
    cursor: pointer;
    display: inline-block;
}

.custome-sleect {
    * {
        line-height: normal !important;
        height: auto;
    }
}
</style>
