<template>
    <div class="view">
        <div class="title-panel">
            <i class="el-icon-back" @click="$router.go(-1)"></i>
            <span>采购记录导入</span>
        </div>
        <div style="padding-left: 80px; padding-top: 30px">
            <div style="display: flex">
                <div style="height: 100%">
                    <el-steps direction="vertical" :active="1" space="120px">
                        <el-step title="填写表格" />
                        <el-step title="上传表格" />
                    </el-steps>
                </div>
                <div style="display: flex; flex-direction: column; margin-left: 50px">
                    <div style="height: 100%; display: inline-flex">
                        <div style="color: #707070">按导入模板，填写表格</div>
                        <div style="margin-left: 34px; cursor: pointer; height: max-content; display: flex; align-items: center">
                            <img src="@/assets/icon/xls.png" />
                            <span @click="handleDownloadTemplate" style="color: var(--themeColor); text-decoration: underline">采购记录导入模板.xlsx</span>
                        </div>
                        <div style="margin-left: 36px; color: #c7c7c7">单次导入不超过1000条</div>
                    </div>
                    <div style="height: 100%"> 
                        <el-upload ref="upload" :headers="headers" :action="uploadUrl" :auto-upload="false" :multiple="false" :limit="1" :data="uploadData" :on-success="uploadOk">
                            <el-button slot="trigger" size="small" type="primary" plain>上传表格</el-button>
                            <span style="margin-left: 22px; color: #707070">表格大小控制在5M以内</span>
                        </el-upload>
                    </div>
                </div>
            </div>
            <div style="position: relative; bottom: 60px">
                <el-button type="primary" size="small" @click="submitUpload">一键导入</el-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            uploadUrl: `${this.$orderUrl}/Purchase/ImportPurchasingRecord`,
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('token'),
            },
            uploadData: {},
        };
    },
    methods: {
        handleDownloadTemplate() {
            window.open("https://crm.qixinerp.com/ossfile/采购记录导入模板.xlsx","_blank");
        },
        submitUpload() {
            this.$refs.upload.submit();
        },
        uploadOk(response){
            const {isSuccess,errorMessage,data} = response;
            if(isSuccess){
                this.$alert( data , '导入结果', {
                    confirmButtonText: '确定',
                    callback: () => {}
                });
            }else{
                this.$message({message: errorMessage,type: 'warning'});
            }
        },
    },
    mounted(){

    }
};
</script>

<style lang="scss" scoped>
.view {
    width: 100%;
    height: 100%;
    background: #fff;
    font-size: 14px;
}
.title-panel {
    color: #3a3a3a;
    font-size: 24px;
    padding-left: 40px;
    height: 75px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e6e7eb;
    font-weight: bold;
    .el-icon-back{
        cursor: pointer;
        padding-right: 20px;
    }
}
</style>