<template>
    <div class="OrderGather">
        <div class="title">
            <span>订单收入</span>
            <div class="describe">
                <span>回款金额：CNY {{totalCollectionAmount}}</span>
                <span>未回款金额：CNY {{basicInfo.orderAmount - totalCollectionAmount}}</span>
            </div>
            <el-button class="newBtn" @click="toNewIncome" type="primary" icon="el-icon-plus" size="mini">新建收入</el-button>
        </div>    
        <el-table :data="gatherList" :header-cell-style="{ 'background-color':'#F3F3F3','color':'#3A3A3A' }" :row-style="({ rowIndex }) => (rowIndex % 2 == 1 ? { 'background-color': '#F9FAFC' } : {})">
            <el-table-column prop="paymentName" label="款项名称"></el-table-column>
            <el-table-column prop="payeeCollectionDate" label="收入日期"></el-table-column>
            <el-table-column prop="payeeCollectionAmount" label="收入金额">
                <template slot-scope="scope">
                    <span>{{ scope.row.payeeCollectionAmount }}</span>
                    <span style="padding-left: 5px;">{{ scope.row.collectionCurrency }}</span>
                    (<span>{{ scope.row.cnyAmount }}</span>
                    <span style="padding-left: 5px;">CNY</span>)
                </template>
            </el-table-column>
            <el-table-column label="操作" width="100">
                <template slot-scope="scope">
                    <el-link :underline="false" type="danger" @click="deleteIncome(scope.row)">删除</el-link>
                </template>
            </el-table-column>
        </el-table>

        <!-- 新建收入(简化) -->
        <el-dialog title="新建收入" :visible.sync="newIncomeVisible" width="400px">
            <el-form label-width="100px" size="small">
                <el-form-item label="款项名称">
                    <el-input v-model="incomeForm.paymentName"></el-input>
                </el-form-item>
                <el-form-item label="收入日期">
                    <el-date-picker v-model="incomeForm.payeeCollectionDate" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
                </el-form-item>
                <el-form-item label="收入金额">
                    <el-input-number v-model="incomeForm.payeeCollectionAmount" :min="0" :controls="false" @blur="calcCNYPrice"></el-input-number>
                </el-form-item>
                <el-form-item label="币种">
                    <el-select v-model="incomeForm.collectionCurrency" size="small" filterable style="width:130px;" @change="chooseCurrency">
                        <el-option v-for="item in currencyOption" :key="item.key" :label="item.currency" :value="item.currency"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="汇率">
                    <el-input-number :disabled="incomeForm.collectionCurrency=='CNY'" v-model="incomeForm.exchangeRate" size="small" :min="0" :controls="false" @blur="calcCNYPrice"></el-input-number>
                </el-form-item>
                <el-form-item label="人民币金额">
                    <el-input-number :disabled="incomeForm.collectionCurrency=='CNY'" v-model="incomeForm.cnyAmount" size="small" :min="0" :controls="false" @blur="calcRate"></el-input-number>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="newIncomeVisible = false">取 消</el-button>
                <el-button size="small" type="primary" :disabled="!incomeForm.paymentName" @click="newIncomeSubmit" :loading="btnLoading">确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import dayjs from "dayjs";
export default {
    props:["basicInfo"],
    data() {
        return {
            totalCollectionAmount: 0,
            gatherList: [],
            btnLoading: false,
            //新建收入(简化)
            newIncomeVisible: false,
            incomeForm: {},
            currencyOption: [],
        };
    },
    methods: {
        getGatherInfo(){
            this.$axios.post(`${this.$orderUrl}/api/Collection/GetCollections`,{
                orderKey: this.basicInfo.orderKey,
                type: 1,
                pageIndex: 1,
                pageSize: 20,
            }).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    this.gatherList = data.collectionData;
                    this.totalCollectionAmount = data.totalPrice;
                }
            });
        },
        //新建收入(简化)
        toNewIncome(){
            this.newIncomeVisible = true;
            let userInfo = JSON.parse(localStorage.userInfo);
            this.incomeForm = {
                "type": 1,                                      //新建收入
                "paymentName": "",
                "payer": this.basicInfo.customerName,           //付款方
                "payerKey": this.basicInfo.customerKey,         //付款方key
                "payerPayAmount": 0,                            //付款方付款金额
                "payee": userInfo.companyCnFullName,            //收款方
                "payeeKey": userInfo.companyKey,                //收款方key
                "payeeCollectionAmount": 0,                     //收款方收款金额
                "payeeCollectionDate": dayjs().format('YYYY-MM-DD'),
                "collectionCurrency": "CNY",
                "exchangeRate": 1,
                "cnyAmount": 0,
                "handler": userInfo.userCnName,
                "remarks": "",
                "orderKey": this.basicInfo.orderKey,  //关联订单
            }
        },
        newIncomeSubmit(){
            this.incomeForm.payerPayAmount = this.incomeForm.payeeCollectionAmount;
            this.btnLoading = true;
            this.$axios.post(`${this.$orderUrl}/api/Collection/SaveCollection`,this.incomeForm).then((res) => {
                const { isSuccess } = res;
                this.btnLoading = false;
                this.newIncomeVisible = false;
                if (isSuccess) {
                    this.getGatherInfo();
                    this.$message({ message: '新建成功', type: 'success'});
                }
            });
        },
        //删除收入
        deleteIncome(row){
            this.$confirm('是否删除该收入?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios.get(`${this.$orderUrl}/api/Collection/DeleteCollection?collectionKey=${row.key}`).then((res) => {
                    const { isSuccess } = res;
                    if (isSuccess) {
                        this.$message({ message: '删除成功', type: 'success'});
                        this.getGatherInfo();
                    }
                });
            }).catch(() => {});
        },

        getCurrencyConfig(){
            this.$axios.get(`${this.$customerUrl}/v1/Config/GetCurrencyConfig`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.currencyOption = data;
                }
            })
        },
        chooseCurrency(value){
            let cItem = this.currencyOption.filter(ele => ele.currency == value)[0];
            this.incomeForm.exchangeRate = cItem.exchangeRate;
            this.calcCNYPrice();
        },
        calcCNYPrice(){
            this.incomeForm.cnyAmount = this.incomeForm.payeeCollectionAmount * this.incomeForm.exchangeRate * 1;
            this.incomeForm.cnyAmount = this.incomeForm.cnyAmount.toFixed(2) * 1;
        },
        calcRate(){
            this.incomeForm.exchangeRate = this.incomeForm.cnyAmount / this.incomeForm.payeeCollectionAmount;
            this.incomeForm.exchangeRate = this.incomeForm.exchangeRate.toFixed(8) * 1;
        },
    },
    mounted() {
        this.orderKey = this.$route.query.orderKey;
    },
    watch:{
        basicInfo(){
            this.getGatherInfo();
            this.getCurrencyConfig();
        }
    }
};
</script>

<style lang="scss" scoped>
.OrderGather{
    margin-top: 20px;
    .title{
        height: 20px;
        line-height: 20px;
        font-size: 16px;
        font-weight: bold;
        color: #3A3A3A;
        margin-bottom: 10px;
        border-left: 3px solid var(--themeColor);
        padding-left: 5px;
        .newBtn{
            float: right;
        }
        .describe{
            display: inline-block;
            line-height: 20px;
            font-size: 14px;
            font-weight: normal;
            span{
                padding-left: 20px;
            }
        }
    }
}
</style>