<template>
  <!-- 添加标签，不复用 -->
  <div class="add-tag">
    <div class="main" v-loading="loading">
      <el-tag @close="deleteTag(item.key)" v-for="item of tags" :key="item.key" :color="item.colour" closable effect="dark" size="mini" style="margin-right: 5px">{{ item.label }}</el-tag>
      <el-button @click="readyForAddTag" style="height: 24px; font-size: 12px; display: flex; align-items: center; padding: 0 5px">+ 标签</el-button>
    </div>
    <el-dialog title="添加标签" :visible.sync="dialog.show" width="600px" :show-close="false" :close-on-click-modal="false">
      <el-select v-model="dialog.value" :multiple-limit="5" default-first-option allow-create filterable multiple placeholder="多个标签回车" style="width: 100%" size="small">
        <el-option v-for="item in dialog.options" :key="item.key" :label="item.label" :value="item.key">
          <div style="display: flex; justify-content: space-between; align-items: center">
            <div style="display: flex; align-items: center">
              <span :style="{ background: item.colour, width: '1em', height: '1em', display: 'inline-block', 'margin-right': '5px' }"></span>
              {{ item.label }}
            </div>
          </div>
        </el-option>
      </el-select>
      <div slot="footer">
        <el-button @click="dialog.show = false" size="mini">取 消</el-button>
        <el-button @click="confirm" type="primary" size="mini" :loading="dialog.confrimLoading">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "@/firm/plugins/axios";

export default Vue.extend({
  props: ["company"],
  data() {
    return {
      loading: false,
      tags: [],
      dialog: {
        show: false,
        value: [],
        options: [],
        confrimLoading: false,
      },
    };
  },
  methods: {
    //获取已有标签
    async getTags() {
      try {
        const res = await axios({
          method: "get",
          url: "/search/Credit/Label/Get",
          params: {
            keyword: this.company,
          },
        });
        this.tags = res.data.result;
        this.dialog.options = res.data.result;
        return Promise.resolve("done");
      } catch {
        this.tags = [];
        this.dialog.options = [];
        return Promise.resolve("done");
      }
    },
    //删除标签
    async deleteTag(key) {
      try {
        this.loading = true;
        await axios({
          method: "post",
          url: "/search/Credit/Label/Delete",
          data: {
            company: this.company,
            items: [
              {
                key,
              },
            ],
          },
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.getTags().then(() => {
          this.loading = false;
        });
      }
    },
    //打开 添加标签 对话框
    readyForAddTag() {
      this.dialog.value = this.tags.map((item) => item.key) ;
      this.dialog.show = true;
    },
    //添加标签
    async confirm() {
      try {
        this.dialog.confrimLoading = true;
        const hashMap = new Map();
        this.tags.forEach((item) => {
          hashMap.set(item.key, [item.label, item.colour]);
        });
        const colors = ["#f59d63", "#f3bf6c", "var(--themeColor, #3152AF)"];
        const ans = [];
        this.dialog.value.forEach((item) => {
          if (hashMap.has(item)) {
            ans.push({
              key: "",
              label: hashMap.get(item)[0],
              colour: hashMap.get(item)[1],
            });
          } else {
            ans.push({
              key: "",
              label: item,
              colour: colors[Math.floor(Math.random() * 3)],
            });
          }
        });
        await axios({
          method: "post",
          url: "/search/Credit/Label/Save",
          data: {
            company: this.company,
            items: ans,
          },
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.dialog.confrimLoading = false;
        this.dialog.show = false;
        this.getTags();
      }
    },
  },
  mounted() {
    this.getTags();
  },
});
</script>

<style lang="scss" scoped>
.add-tag {
  .main {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  ::v-deep .el-dialog__body {
    padding: 10px 20px;
  }
  ::v-deep .el-tag--dark {
    border: none !important;
  }
}
</style>
