<template>
<div>
    <div v-if="ie=='i'">
        <!-- 巴拉圭(PRY)进口提单明细 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th>提单号</th>
                        <td colspan="3">{{billInfo.BlNumber}}</td>
                    </tr>
                    <tr>
                        <th width="25%">报关单号</th>
                        <td width="25%">{{billInfo.CustomsDeclaration}}</td>
                        <th width="25%">清单号码</th>
                        <td width="25%">{{billInfo.Manifest}}</td>
                    </tr>
                    <tr>
                        <th>进口商ID</th>
                        <td>{{billInfo.ImporterId}}</td>
                        <th>品牌</th>
                        <td>{{billInfo.Brand}}</td>
                    </tr>
                    <tr>
                        <th>毛重</th>
                        <td>{{billInfo.Weight}}</td>
                        <th>净重</th>
                        <td>{{billInfo.NetKilo}}</td>
                    </tr>
                    <tr>
                        <th>FOB总价（美元）</th>
                        <td>{{billInfo.UsFob}}</td>
                        <th>FOB单价（美元）</th>
                        <td>{{billInfo.UsFobUnit}}</td>
                    </tr>
                    <tr>
                        <th>CIF总价（美元）</th>
                        <td>{{billInfo.Value}}</td>
                        <th>CIF单价（美元）</th>
                        <td>{{billInfo.UsCifUnit}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">运输信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">运输方式</th>
                        <td width="25%">{{billInfo.TransportWay}}</td>
                        <th width="25%">运输公司</th>
                        <td width="25%">{{billInfo.TransportCompany}}</td>
                    </tr>
                    <tr>
                        <th>运输国</th>
                        <td>{{billInfo.TransportCountry}}</td>
                        <th>承运人</th>
                        <td>{{billInfo.Carrier}}</td>
                    </tr>
                    <tr>
                        <th>收购国</th>
                        <td>{{billInfo.AcquisitionCountry}}</td>
                        <th>来源国</th>
                        <td>{{billInfo.Country}}</td>
                    </tr>
                    <tr>
                        <th>运费(美元)</th>
                        <td>{{billInfo.Freight}}</td>
                        <th>保险(美元)</th>
                        <td>{{billInfo.Insurance}}</td>
                    </tr>
                    <tr>
                        <th>海关</th>
                        <td>{{billInfo.Custom}}</td>
                        <th>代理海关</th>
                        <td>{{billInfo.BrokerCustoms}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
    <div v-if="ie=='e'">
        <!-- 巴拉圭(PRY)出口提单明细 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th>提单号</th>
                        <td colspan="3">{{billInfo.BlNumber}}</td>
                    </tr>
                    <tr>
                        <th width="25%">报关单号</th>
                        <td width="25%">{{billInfo.DeclarationCustoms}}</td>
                        <th width="25%">清单号码</th>
                        <td width="25%">{{billInfo.Manifest}}</td>
                    </tr>
                    <tr>
                        <th>出口商ID</th>
                        <td>{{billInfo.ExporterId}}</td>
                        <th>品牌</th>
                        <td>{{billInfo.Brand}}</td>
                    </tr>
                    <tr>
                        <th>6位海关编码</th>
                        <td>{{billInfo.HsCode6}}</td>
                        <th>6位海关编码描述</th>
                        <td>{{billInfo.HsCode6Des}}</td>
                    </tr>
                    <tr>
                        <th>毛重</th>
                        <td>{{billInfo.Weight}}</td>
                        <th>净重</th>
                        <td>{{billInfo.NetKilo}}</td>
                    </tr>
                    <tr>
                        <th>FOB总价（美元）</th>
                        <td>{{billInfo.Value}}</td>
                        <th>FOB单价（美元）</th>
                        <td>{{billInfo.UsFobUnit}}</td>
                    </tr>
                    <tr>
                        <th>CIF总价（美元）</th>
                        <td>{{billInfo.UsCif}}</td>
                        <th>CIF单价（美元）</th>
                        <td>{{billInfo.UsCifUnit}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">运输信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">运费(美元)</th>
                        <td width="25%">{{billInfo.Freight}}</td>
                        <th width="25%">保险(美元)</th>
                        <td width="25%">{{billInfo.Insurance}}</td>
                    </tr>
                    <tr>
                        <th>海关</th>
                        <td>{{billInfo.Custom}}</td>
                        <th>运输方式</th>
                        <td>{{billInfo.TransportWay}}</td>
                    </tr>
                    <tr>
                        <th>运输公司</th>
                        <td>{{billInfo.TransportCompany}}</td>
                        <th>运输国</th>
                        <td>{{billInfo.TransportCountry}}</td>
                    </tr>
                    <tr>
                        <th>代理海关</th>
                        <td>{{billInfo.BrokerCustoms}}</td>
                        <th>运输车牌</th>
                        <td>{{billInfo.TransportLicensePlate}}</td>
                    </tr>
                    <tr>
                        <th>来源国</th>
                        <td>{{billInfo.OriginCountry}}</td>
                        <th>目的国</th>
                        <td>{{billInfo.Country}}</td>
                    </tr>
                    <tr>
                        <th>承运人</th>
                        <td colspan="3">{{billInfo.Carrier}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
</div>
</template>

<script>
export default {
    props:["billInfo","ie"],
    data() {
        return {

        };
    },
    methods: {
        
    },
    mounted() {
        
    },
};
</script>

<style lang="scss" scoped>
.partItem {
    margin-bottom: 20px;
    /deep/.el-card__header {
        padding: 10px 20px;
        .headTitle {
            display: inline-block;
            padding-left: 10px;
            font-size: 14px;
            border-left: 2px solid var(--themeColor);
            font-weight: bold;
        }
    }
}
table {
    width: 100%;
    border-collapse: collapse;
    tr {
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;
        th,
        td {
            font-size: 12px;
            color: #666;
            height: 40px;
            border: 1px solid #eaeaea;
            text-align: center;
        }
        th {
            background-color: #f5f7fa;
            font-weight: 700;
        }
        .bg {
            background-color: var(--themeColor);
            color: #fff;
            font-weight: 700;
            opacity: 0.8;
        }
    }
}
</style>