<template>
    <div>
        <el-calendar>
            <template slot="dateCell" slot-scope="{data}">
                <p :class="data.isSelected ? 'is-selected' : ''">
                    {{ data.day.split('-').slice(1).join('-') }} {{ data.isSelected ? '✔️' : ''}}
                </p>
            </template>
        </el-calendar>
    </div>
</template>

<script>
export default {
    data() {
        return {

        };
    },
    methods: {

    },
    mounted() {
        
    },
};
</script>

<style lang="scss" scoped>
.is-selected {
    color: var(--themeColor);
}
</style>