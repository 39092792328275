<template>
    <div class="viewbox">
        <div class="boxOne">
            <div class="title">产品管理</div>
            <div class="rightHandle">
                <el-button size="small" @click="toDeleteMore" v-show="chooseList.length!=0">批量删除</el-button>
                <!-- 产品采集功能 -->
                <el-button size="small" @click="gatherResult">产品采集</el-button>
                <!-- 转移分组功能 -->
                <el-button size="small" @click="toTransfer" :disabled="chooseList.length==0">转移分组</el-button>
                <el-button size="small" type="primary" icon="el-icon-plus" @click="toNewProduct">新增产品</el-button>
                <!-- 导入，导出功能 -->
                <el-button size="small" @click="productImport">产品导入</el-button>
                <el-button size="small" @click="downloadVisible = true;downloadNumber = totalItems;">产品导出</el-button>
            </div>
        </div>

        <div style="display: flex;">
            <div class="productClassify">
                <el-tree :data="productTreeData" :props="defaultProps" node-key="key" :current-node-key="currentNodeKey" highlight-current default-expand-all @node-click="handleNodeClick" class="treeStyle" icon-class="el-icon-folder-opened">
                    <span slot-scope="{ data }">
                        <span>{{ data.name }}</span>
                    </span>
                </el-tree>
            </div>
            <div style="width: calc(100% - 200px);">
                <div class="boxTwo">
                    <div class="txt">已选择{{chooseList.length}}个产品</div>
                    <div class="rightHandle">
                        <el-input v-model="filterParam.productNo" placeholder="产品编号" prefix-icon="el-icon-search" size="small" style="width:180px;margin-right:10px;" @keyup.enter.native="toSearch"></el-input>

                        <el-input v-model="filterParam.productName" placeholder="产品名称" size="small" style="width:180px;margin-right:10px;" @keyup.enter.native="toSearch"></el-input>

                        <el-date-picker v-model="filterParam.createTime" type="date" value-format="yyyy-MM-dd" placeholder="创建时间" size="small" style="width:180px;" @change="toSearch"></el-date-picker>
                    </div>
                </div>

                <div class="boxTable">
                    <el-table style="width:100%;" height="calc(100vh - 250px)" v-loading="tableLoading" :data="productList"
                        :header-cell-style="{'background-color':'#F2F2F2','color':'#3A3A3A'}" :row-style="rowStyle"
                        @selection-change="selectionChange">
                        <el-table-column type="selection" width="45"></el-table-column>
                        <el-table-column label="#" width="120">
                            <template slot-scope="scope">
                                <!-- 关注，置顶展示 -->
                                <div class="lefthandle">
                                    <span>{{(pageIndex-1)*10+scope.$index+1}}</span>
                                    <img v-if="scope.row.isAttention" @click.stop="setTopOrAttention(scope.row,1,false)" src="@/assets/icon/attention.png" class="oneItem" alt="" />
                                    <img v-else @click.stop="setTopOrAttention(scope.row,1,true)" src="@/assets/icon/noattention.png" class="oneItem hoverShow" alt=""/>

                                    <img v-if="scope.row.isTop" @click.stop="setTopOrAttention(scope.row,0,false)" src="@/assets/icon/top.png" class="twoItem" alt="" />
                                    <img v-else @click.stop="setTopOrAttention(scope.row,0,true)" src="@/assets/icon/notop.png" class="twoItem hoverShow" alt="" />
                                </div>
                            </template>
                        </el-table-column>

                        <el-table-column prop="productNo" label="产品编号">
                            <template slot-scope="scope">
                                <el-link @click.stop="toDetails(scope.row)">{{scope.row.productNo}}</el-link>
                            </template>
                        </el-table-column>
                        <el-table-column label="产品图片">
                            <template slot-scope="scope">
                                <el-image style="width:50px;height:50px;" :src="scope.row.images" :preview-src-list="[scope.row.images]"></el-image>
                            </template>
                        </el-table-column>
                        <el-table-column prop="productName" label="产品名称"></el-table-column>
                        <el-table-column prop="dimension" label="尺寸信息"></el-table-column>
                        <el-table-column prop="ingredient" label="成分"></el-table-column>
                        <el-table-column prop="supplier" label="供应商"></el-table-column>
                        <el-table-column prop="measureUnit" label="计量单位"></el-table-column>
                        <el-table-column prop="createTime" label="创建时间"></el-table-column>
                        <el-table-column label="操作" width="120">
                            <template slot-scope="scope">
                                <el-link type="info" @click.stop="toDetails(scope.row)">查看</el-link>
                                <span style="padding:0 5px;color:#C7C7C7;">|</span>
                                <el-link type="info" @click.stop="toDeleteOne(scope.row)">删除</el-link>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
        
        <div class="bottomPagination">
            <el-button style="float:left;margin:15px 20px;" size="small" icon="el-icon-refresh" @click="onRefresh">刷 新</el-button>
            <el-pagination background layout="total,sizes,prev,pager,next" :page-sizes="[10, 20, 40]" :page-size.sync="pageSize" @size-change="pageSizeChange" :total="totalItems" style="float:right;margin:15px 20px;" :current-page.sync="pageIndex" @current-change="pageIndexChange"></el-pagination>
        </div>
        
        <el-dialog title="新增产品" :visible.sync="addVisible" width="600px" top="10vh">
            <el-form :model="addForm" label-width="130px" size="small">
                <el-form-item label="产品编号:" required>
                    <el-input v-model="addForm.productNo"></el-input>
                </el-form-item>
                <el-form-item label="产品名称:" required>
                    <el-input v-model="addForm.productName"></el-input>
                </el-form-item>
                <el-form-item label="产品分组:" required>
                    <el-select v-model="clickNodeName" style="width:100%;" placeholder="请选择产品分组">
                        <el-option :value="addForm.groupID" style="height: auto">
                            <el-tree :data="productTreeData" :props="defaultProps" @node-click="chooseProductGroup"></el-tree>
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="尺寸信息:">
                    <el-input v-model="addForm.dimension"></el-input>
                </el-form-item>
                <el-form-item label="成分:">
                    <el-input v-model="addForm.ingredient"></el-input>
                </el-form-item>
                <el-form-item label="供应商:">
                    <el-input v-model="addForm.supplier"></el-input>
                </el-form-item>
                <el-form-item label="成本价(不含税):" required>
                    <el-input-number v-model="addForm.sellPrice" :min="0" :controls="false"></el-input-number>
                </el-form-item>
                <el-form-item label="成本价(含税):">
                    <el-input-number v-model="addForm.taxIncludingPrice" :min="0" :controls="false"></el-input-number>
                </el-form-item>
                <el-form-item label="计量单位:">
                    <el-input v-model="addForm.measureUnit"></el-input>
                </el-form-item>
                <el-form-item label="产品图片:" required>
                    <el-upload :action="uploadUrl" :headers="headers" :data="uploadData" list-type="picture-card" :limit="1"
                        :before-upload="beforeUpload" :on-success="uploadSuccess" :on-remove="handleRemove" :on-preview="onPreview">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                </el-form-item>
                <!-- <el-form-item label="私有产品:">
                    <el-checkbox v-model="addForm.isPrivate"></el-checkbox>
                    <span>勾选则只有自己可以使用该产品;不勾选则公司成员都可以使用该产品;</span>
                </el-form-item> -->
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" @click="addSubmit">新建产品</el-button>
                <!-- <el-button size="small" @click="addVisible = false">取 消</el-button> -->
            </span>
        </el-dialog>
        <!-- 预览图片 -->
        <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>

        <!-- 转移分组 -->
        <el-dialog title="转移分组" :visible.sync="transferVisible" width="500px">
            <div style="margin-bottom:10px">
                已勾选<strong style="color:var(--themeColor);">{{chooseList.length}}</strong>个产品，转移至：
            </div>
            <el-select v-model="clickNodeName" style="width:100%;" placeholder="请选择产品分组" size="small">
                <el-option :value="transferKey" style="height: auto">
                    <el-tree :data="productTreeData" :props="defaultProps" @node-click="chooseTransferGroup"></el-tree>
                </el-option>
            </el-select>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" @click="transferSubmit">确 认</el-button>
                <el-button size="small" @click="transferVisible = false">取 消</el-button>
            </span>
        </el-dialog>

        <!-- 产品导出 -->
        <el-dialog title="产品导出" :visible.sync="downloadVisible" width="500px">
            <span style="padding-right:10px;">导出数量</span>
            <el-input-number v-model="downloadNumber" :min="1" :max="1000" size="small"></el-input-number>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" @click="productExport" :loading="downloadLoading">确 认</el-button>
                <el-button size="small" @click="downloadVisible = false">取 消</el-button>
            </span>
        </el-dialog>

        <!-- 产品采集 -->
        <el-dialog title="产品采集：提交阿里巴巴店铺网址" :visible.sync="gatherVisible" width="500px">
            <div class="productGather">
                <div class="describe">
                    输入店铺网址后，系统将会自动采集该网址产品，采集完毕后，用户自行选择导入产品。
                </div>
                <el-input placeholder="请输入链接地址" v-model="writeUrl" size="small"></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="gatherResult">产品采集结果</el-button>
                <el-button size="small" type="primary" :disabled="!writeUrl" @click="gatherSubmit" :loading="btnLoading">确 认</el-button>
                <el-button size="small" @click="gatherVisible = false">取 消</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {
        handleSelect: {
            type: Function,
            default: null,
        },
    },
    data() {
        return {
            // 过滤字段
            filterParam: {
                productNo: "",
                productName: "",
                createTime: "",
                groupId: 0,
                groupKey: "",
            },
            //列表展示
            tableLoading: false,
            productList: [],
            totalItems: 0,
            pageIndex: 1,
            pageSize: 10,
            //转移分组
            chooseList: [],
            transferVisible: false,
            transferKey: "",
            //新增产品
            addVisible: false,
            addForm: {},

            //产品分类
            productTreeData: [],
            defaultProps: {
                children: "children",
                label: "name",
            },
            currentNodeKey: "",
            clickNodeName: "",

            //图片上传
            uploadUrl: `${this.$diskUrl}/File/Upload`,
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('token'),
            },
            uploadData: {
                folderKey: "0",
                remarkName: "",
                permission: '我的'
            },
            productImgList: [],
            dialogImageUrl: '',
            dialogVisible: false,

            //产品导出
            downloadVisible: false,
            downloadLoading: false,
            downloadNumber: 500,
            //产品采集
            gatherVisible: false,
            btnLoading: false,
            writeUrl: "",
        };
    },
    methods: {
        // onRowClick(row){
        //     this.toDetails(row)
        // },
        onRefresh(){
            this.filterParam = {
                productNo: "",
                productName: "",
                createTime: "",
                groupId: 0,
                groupKey: "",
            };
            this.toSearch();
        },
        rowStyle({rowIndex}){
            // 表格隔行换色
            if(rowIndex % 2 == 1){
                return {'background-color':'#F9FAFC'}
            }
        },

        //图片上传
        beforeUpload(file) {
            var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
            const isImg = testmsg === "png" || testmsg === "jpg" || testmsg === "jpeg";
            const isLt10M = file.size / 1024 / 1024 < 10;
            if (!isImg) {
                this.$message({ message: "上传文件只能是图片!", type: "warning"});
            }
            if (!isLt10M) {
                this.$message({ message: "上传文件大小不能超过10MB!", type: "warning"});
            }
            return isImg && isLt10M;
        },
        uploadSuccess(response){
            const {isSuccess,data} = response;
            if(isSuccess){
                this.productImgList.push(data.fileUrl);
                console.log(this.productImgList);
            }
        },
        handleRemove(file){
            this.productImgList = this.productImgList.filter(ele => ele != file.response.data.fileUrl);
            console.log(this.productImgList);
        },
        onPreview(file){
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },

        //产品导入
        productImport(){
            this.handleSelect(`/orderManage/productImport`);
            this.$router.push(`/orderManage/productImport`);
        },
        //产品导出
        productExport(){
            const {productNo,productName,createTime,groupId,groupKey} = this.filterParam;
            this.downloadLoading = true; 
            this.$axios({
                url: `${this.$productUrl}/api/Product/ExportProducts`,
                method: 'POST',
                responseType: 'blob',
                data:{
                    productNo,productName,createTime,groupId,groupKey,
                    pageIndex: 1,
                    pageSize: this.downloadNumber,
                }
            }).then(response => {
                this.downloadLoading = false;
                this.downloadVisible = false;
                var blob = new Blob([response]);
                var link= document.createElement('a');
                link.download = '我的产品.xlsx';
                link.href = URL.createObjectURL(blob);
                link.click();
                this.$message({message: "产品导出成功",type: 'success'});
            });
        },


        toNewProduct(){
            this.addVisible = true;
            this.clickNodeName = "";
            this.addForm = {
                productNo: "",  
                productName: "",
                groupID: "",
                dimension: "",
                ingredient: "",
                supplier: "",
                sellPrice: 0,
                taxIncludingPrice: 0,
                measureUnit: "",
                images: "",
                isPrivate: true,
                rotationChart: [],
            }
        },
        addSubmit(){
            this.addForm.rotationChart = this.productImgList;
            this.addForm.images = this.productImgList.join(',');
            const {productNo,productName,groupID,sellPrice,images} = this.addForm;
            if(!productNo){ this.$message({ message: '请填写产品编号', type: 'warning'}); return false; }
            if(!productName){ this.$message({ message: '请填写产品名称', type: 'warning'}); return false; }
            if(productName.length < 2){ this.$message({ message: '产品名称至少2个字', type: 'warning'}); return false; }
            if(!groupID){ this.$message({ message: '请选择产品分组', type: 'warning'}); return false; }
            if(sellPrice == 0){ this.$message({ message: '成本价不能为0', type: 'warning'}); return false; }
            if(!images){ this.$message({ message: '请上传产品图片', type: 'warning'}); return false; }
            console.log(this.addForm);

            this.$axios.post(`${this.$productUrl}/api/Product/AddUpdate`,this.addForm).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    this.addVisible = false;
                    this.$message({ message: '创建成功', type: 'success'});
                    this.pageIndex = 1;
                    this.getProductList();
                    this.getProductClassifyData();
                    this.clickNodeName = "";
                }
            });
        },

        toSearch(){
            this.pageIndex = 1;
            this.getProductList();
        },

        //获取产品列表
        getProductList(){
            const {productNo,productName,createTime,groupId,groupKey} = this.filterParam;
            this.tableLoading = true;
            this.$axios.post(`${this.$productUrl}/api/Product/List`,{
                productNo,productName,createTime,groupId,groupKey,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize
            }).then((res) => {
                const { isSuccess , data } = res;
                this.tableLoading = false;
                if (isSuccess) {
                    this.productList = data.datas;
                    this.totalItems = data.totalItems;
                }
            });
        },
        pageIndexChange(page) {
            this.pageIndex = page;
            this.getProductList();
        },
        pageSizeChange() {
            this.pageIndex = 1;
            this.getProductList();
        },
        
        //查看产品详情
        toDetails(row){
            // this.handleSelect(`/orderManage/productDetails?productKey=${row.key}`);
            // this.$router.push(`/orderManage/productDetails?productKey=${row.key}`);
            let routeData = this.$router.resolve({
                path: "/orderManage/productDetails",
                query: { productKey: row.key },
            });
            window.open(routeData.href, "_blank");
        },

        toDeleteOne(row){
            this.$confirm('是否删除该产品?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios.post(`${this.$productUrl}/api/Product/Delete`,{
                    keys: [row.key]
                }).then((res) => {
                    const { isSuccess } = res;
                    if (isSuccess) {
                        this.$message({ type: 'success', message: '删除成功!'});
                        this.getProductList();
                    }
                });
            }).catch(() => {});
        },

        toDeleteMore(){
            this.$confirm('是否删除选中的产品?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let productKeys = this.chooseList.map(item => {return item.key});
                this.$axios.post(`${this.$productUrl}/api/Product/Delete`,{
                    keys: productKeys,
                }).then((res) => {
                    const { isSuccess } = res;
                    if (isSuccess) {
                        this.$message({ type: 'success', message: '删除成功!'});
                        this.getProductList();
                    }
                });
            }).catch(() => {});
        },

        selectionChange(value){
            this.chooseList = value;
        },
        //转移分组
        toTransfer(){
            this.transferVisible = true;
            this.transferKey = "";
            this.clickNodeName = "";
        },
        transferSubmit(){
            if(!this.transferKey){
                this.$message({ message: '请选择要转移的分组', type: 'warning'});
                return false;
            }
            let productKeys = this.chooseList.map(item => {return item.key});
            this.$axios.post(`${this.$productUrl}/api/Product/TransferGrouping`,{
                groupKey: this.transferKey,
                productKeys: productKeys,
            }).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    this.transferVisible = false;
                    this.$message({ message: '转移成功', type: 'success'});
                    this.pageIndex = 1;
                    this.getProductList();
                    this.getProductClassifyData();
                    this.transferKey = "";
                }
            });
        },


        //获取产品分类数据
        getProductClassifyData(){
            this.$axios.get(`${this.$productUrl}/api/ProductGroup/GetAll?type=1`).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    this.productTreeData = data;
                }
            });
        },
        handleNodeClick(data) {
            this.currentNodeKey = data.key;
            //重新过滤产品列表
            this.filterParam.groupId = data.id;
            this.filterParam.groupKey = data.key;
            this.pageIndex = 1;
            this.getProductList();
        },
        chooseProductGroup(data){
            this.clickNodeName = data.name;
            this.addForm.groupID = data.key;
        },
        chooseTransferGroup(data){
            this.clickNodeName = data.name;
            this.transferKey = data.key;
        },

        //设置关注，置顶
        setTopOrAttention(row,type,value){
            this.$axios.get(`${this.$productUrl}/api/Product/SetProductIsTopOrIsAttention?productKey=${row.key}&type=${type}&state=${value}`).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    this.$message({ message: '操作成功', type: 'success'});
                    this.getProductList();
                }
            });
        },

        //产品采集
        toGather(){
            this.gatherVisible = true;
            this.writeUrl = "";
        },
        gatherSubmit(){
            this.btnLoading = true;
            this.$axios.get(`${this.$productUrl}/api/Product/SubmitAlibabaHost?url=${this.writeUrl}`).then((res) => {
                const { isSuccess } = res;
                this.btnLoading = false;
                this.gatherVisible = false;
                if (isSuccess) {
                    this.$message({ message: '提交成功', type: 'success'});
                }
            });
        },
        //产品采集结果
        gatherResult(){
            let routeData = this.$router.resolve({
                path: "/orderManage/gatherResult",
            });
            window.open(routeData.href, "_blank");
        }

    },
    mounted() {
        this.getProductList();    //获取产品列表
        this.getProductClassifyData();      //获取产品分类数据
    },
};
</script>

<style lang="scss" scoped>
.viewbox{
    background: rgba($color: #FFFFFF, $alpha: 0.7);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    margin-bottom: 60px;
    .boxOne,.boxTwo{
        overflow: hidden;
        padding: 8px 0;
        line-height: 32px;
        border-bottom: 1px solid #E6E7EB;
        .title{
            float: left;
            margin-left: 40px;
            font-size: 24px;
            font-weight: bold;
            color: #3A3A3A;
        }
        .txt{
            float: left;
            margin-left: 40px;
            font-size: 16px;
            color: #7C90A5;
        }
        .rightHandle{
            float: right;
            margin-right: 20px;
        }
    }

    .bottomPagination{
        position: fixed;
        bottom: 0;
        right: 0;
        z-index: 10;
        width: calc(100vw - 200px);
        height: 60px;
        background: #FFFFFF;
        box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.16);
    }
    
    .productClassify{
        width: 200px;
        border-right: 1px solid #E6E7EB;
        padding: 10px;
        box-sizing: border-box;
        .treeStyle{
            background: #fafafa;
            color: #7C90A5;
            /deep/.el-tree-node__expand-icon{
                font-size: 16px;
            }
            /deep/.el-tree-node__expand-icon.expanded {
                transform: rotate(0deg);
            }
            /deep/.el-tree-node__content {
                height: 32px;
                line-height: 32px;
            }
        }
    }

    /* 关注，置顶样式*/
    .lefthandle{
        position: relative;
        .oneItem{
            position: absolute;
            top: 2px;
            left: 20px;
        }
        .twoItem{
            position: absolute;
            top: 2px;
            right: 10px;
        }
        .hoverShow{
            display: none;
        }
        &:hover .hoverShow{
            display: inline-block;
        }
    }

    /deep/.el-dialog__body {
        padding: 10px 20px;
    }

    .productGather{
        .describe{
            color: #6A6A6A;
            margin-bottom: 20px;
        }
    }
}
</style>
