<template>
<div>
    <ClueNav @chooseNav="chooseNav" :currentPath="currentPath" />
    <div class="viewbox">
        <!-- <div class="header">
            <img class="logo" src="@/assets/cluePicture/logo-business.png" alt="" />
            <div>
                <div class="theme">工商信息线索</div>
                <div class="txt">统计来自工商信息的相关线索</div>
            </div>
        </div> -->

        <div class="searchTerms">
            <div class="itemTerm">
                <div class="name">数据源:</div>
                <div class="spanList">
                    <div class="spanItem" v-for="ele in dataSourceList" :key="ele.country" :class="{'active':dataSourceIndex==ele.country}" @click="clickCountry(ele.country)">
                        {{ele.country}}({{ele.number}})
                    </div>
                </div>
            </div>
            <div class="searchBox" style="margin-top: 20px;">
                <el-input placeholder="查找企业" prefix-icon="el-icon-search" v-model="searchValue" @keyup.enter.native="onSearch" style="width:600px;"></el-input>
                <el-button type="primary" style="margin-left:20px;" @click="onSearch" :loading="searchLoading">搜索</el-button>
            </div>
        </div>
    </div>

    <div class="viewbox" style="margin-top:20px;">
        <div class="searchResult noResult" v-show="totalItems==0" v-loading="searchLoading">
            <img class="noImg" src="@/assets/cluePicture/no2.png" alt="" />
            <div class="hintTxt">工商信息</div>
            <img class="hintImg" src="@/assets/cluePicture/hint.png" alt="" />
        </div>
        
        <div class="searchResult" v-show="totalItems!=0" v-loading="searchLoading">
            <div class="title">为您找到<span style="color:#FF2D2D;">{{totalItems}}</span>条相关结果</div>
            <div class="resultList">
                <div class="resultItem" v-for="item in resultList" :key="item.key">
                    <div style="margin-bottom: 8px;">
                        <div class="name" @click="toDetails(item)">{{item.company}}</div>
                        <div class="country">
                            <img :src="item.flagUrl" style="margin-right:5px;height:14px" />
                            <span>{{item.country}}</span>
                        </div>
                    </div>
                    <!-- <div class="tagModule">
                        <div class="tag1">Active</div>
                    </div> -->
                    <div class="twoField">
                        <span class="color99">邮箱：</span>
                        <span class="color3a">{{item.email}}</span>
                    </div>
                    <div class="twoField">
                        <span class="color99">企业官网：</span>
                        <span class="primaryColor" @click="openWebsite(item.website)">{{item.website}}</span>
                    </div>
                    <div class="twoField">
                        <span class="color99">公司地址：</span>
                        <span class="color3a">{{item.address}}</span>
                    </div>
                    <div class="hasAdd" v-if="item.isMyCustomer">已加入我的客户</div>
                    <div class="addBtn" @click="addCustomer(item)" v-else>
                        <img src="@/assets/cluePicture/addCustomer.png" alt="" />加入我的客户
                    </div>
                </div>
                <el-pagination background layout="total,sizes,prev,pager,next" :page-sizes="[10, 20, 40]" :page-size.sync="pageSize" @size-change="pageSizeChange" :total="totalItems" style="float:right;margin-top:15px;" :current-page.sync="pageIndex" @current-change="pageIndexChange"></el-pagination>
            </div>
        </div>

        <!-- 验证解锁 -->
        <el-dialog title="验证解锁" :visible.sync="dialogVisible" width="500px" :show-close="false"
            :close-on-click-modal="false" :close-on-press-escape="false">
            <p style="line-height:28px;font-weight:bold;">请求过于频繁，请输入验证码：</p>
            <div style="display:flex;">
                <el-input v-model="imgCode" size="small"></el-input>
                <img class="pointer" :src="imgSrc" alt="" @click="openVerify" />
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" :disabled="!imgCode" @click="finishVerify">确 认</el-button>
            </span>
        </el-dialog>
        
    </div>
</div>
</template>

<script>
import ClueNav from "@/components/ClueNav.vue";
export default {
    components: { ClueNav },
    props: {
        handleSelect: {
            type: Function,
            default: null,
        },
    },
    data() {
        return {
            currentPath: "/clue/businessClue",
            dataSourceList:[
                {country:"俄罗斯",number:"11025616"},
                {country:"越南",number:"3357650"},
                {country:"美国",number:"2000995"},
                {country:"意大利",number:"1226598"},
                {country:"英国",number:"1157425"},
                {country:"西班牙",number:"1107782"},
                {country:"巴西",number:"1048575"},
                {country:"印度尼西亚",number:"1048575"},
                {country:"墨西哥",number:"1048575"},
                {country:"菲律宾",number:"1048575"},
                {country:"德国",number:"1048575"},
                {country:"巴基斯坦",number:"691047"},
                {country:"新加坡",number:"466786"},
                {country:"印度",number:"411165"},
                {country:"尼日利亚",number:"361410"},
                {country:"肯尼亚",number:"344823"},
                {country:"孟加拉",number:"306537"},
                {country:"厄瓜多尔",number:"204875"},
                {country:"哈萨克斯坦",number:"117307"},
                {country:"乌干达",number:"79313"},
                {country:"韩国",number:"48400"},
                {country:"坦桑尼亚",number:"35081"},
                {country:"埃塞俄比亚",number:"20415"},
                {country:"喀麦隆",number:"16703"},
                {country:"秘鲁",number:"15728"},
                {country:"博茨瓦纳",number:"7946"},
                {country:"哥斯达黎加",number:"4961"},
                {country:"利比里亚",number:"2848"},
            ],
            dataSourceIndex: "美国",
            searchValue: "",

            //搜索结果
            searchLoading: false,
            resultList: [],
            totalItems: 0,
            pageIndex: 1,
            pageSize: 10, 
            //验证解锁
            dialogVisible: false,
            imgCode: "",
            imgKey: "",
            imgSrc: "",
        };
    },
    methods: {
        chooseNav(index){
            this.handleSelect(index);
            this.$router.push(index);
        },

        clickCountry(country){
            this.dataSourceIndex = country;
            this.onSearch();
        },

        getResult(){
            //请求接口获取数据
            this.searchLoading = true;
            this.$axios.post(`${this.$clueUrl}/BuyerLeads/SearchBusinessInformation`,{
                "country": this.dataSourceIndex,
                "company": this.searchValue,
                "pageIndex": this.pageIndex,
                "pageSize": this.pageSize
            }).then((res) => {
                const { isSuccess, data, errorMessage } = res;
                this.searchLoading = false;
                if (isSuccess) {
                    this.resultList = data.datas.map(item=>({
                        ...item,
                        flagUrl: window.getFlagUrlByCountry?window.getFlagUrlByCountry(item.country):'',
                    }))
                    this.totalItems = data.totalItems;
                    if(this.totalItems == 0){
                        this.$message({ message: '搜索无数据,请修改搜索条件重试!',type: 'warning' });
                    }
                }else{
                    if(errorMessage == "请求过于频繁，请输入验证码"){
                        this.openVerify();
                    }
                }
            });
        },
        onSearch(){
            this.pageIndex = 1;
            this.getResult();
        },
        pageIndexChange(page) {
            this.pageIndex = page;
            if(this.pageIndex > 30){
                this.pageIndex = 30;
                this.$notify({ title: '警告',message: '目前线索模块处于免费试用阶段,搜索页码不能超过30!',type: 'warning' });
                return false;
            }
            this.getResult();
        },
        pageSizeChange() {
            this.pageIndex = 1;
            this.getResult();
        },

        //前往线索详情
        toDetails(row){
            // 验证查看详情次数
            this.$axios.get(`${this.$clueUrl}/SecurityStrategy/CheckDetailsCount`).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    let routeData = this.$router.resolve({
                        path: "/clue/clueDetails",
                        query: { key: row.key, companyName: row.company, clueType: 'business', country: row.country },
                    });
                    window.open(routeData.href, "_blank");
                }
            });
        },

        //加入我的客户
        addCustomer(item){
            let submitObj = {
                companyKey: JSON.parse(localStorage.userInfo).companyKey,
                userKey: JSON.parse(localStorage.userInfo).uKey,
                name: item.company,
                country: item.country,
                webSite: item.website,
                call: "",
                address: item.address,
            }
            this.$axios.post(`${this.$customerUrl}/Common/AddCustomer`, submitObj).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    this.$message({ message: '添加成功', type: 'success'});
                    item.isMyCustomer = true;
                }
            });
        },

        //打开网址
        openWebsite(url){
            let jumpUrl = url.indexOf("http") > -1 ? url : "http://" + url;
            let routeData = this.$router.resolve({
                path: "/jumpWeb",
                query: { jumpUrl: jumpUrl },
            });
            window.open(routeData.href, "_blank");
        },

        //请求频繁，需要输入验证解锁
        openVerify(){
            this.dialogVisible = true;
            this.$axios.get(`${this.$clueUrl}/SecurityStrategy/GetVerifycode`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.imgKey = data.key;
                    this.imgSrc = data.image;
                }
            });
        },
        finishVerify(){
            this.$axios.get(`${this.$clueUrl}/SecurityStrategy/VerifyVerificationCode?key=${this.imgKey}&code=${this.imgCode}`).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {                  
                    this.dialogVisible = false;
                    this.imgCode = "";
                    this.onSearch();
                }
            });           
        },

    },
    mounted() {
        // this.getResult();
    },
};
</script>

<style lang="scss" scoped>
@import '@/scss/common.scss';
.viewbox{
    // background: rgba($color: #FFFFFF, $alpha: 0.7);
    background: #FFFFFF;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    padding: 20px;
    .header{
        display: flex;
        padding-bottom: 20px;
        border-bottom: 1px solid #E1E1E1;
        .logo{
            padding: 0 15px;
        }
        .theme{
            font-size: 20px;
            line-height: 32px;
            font-weight: bold;
            color: #092338;
        }
        .txt{
            font-size: 14px;
            line-height: 20px;
            color: #092338;
        }
    }

    .searchTerms{
        // margin-top: 20px;
        .itemTerm{
            line-height: 26px;
            margin-bottom: 10px;
            overflow: hidden;
            .name{
                float: left;
                width: 65px;
                font-size: 14px;
                color: #092338;
                text-align: right;
                margin-right: 15px;
            }
            .spanList{
                float: left;
                width: calc(100% - 80px);
            }
            .spanItem{
                display: inline-block;
                font-size: 14px;
                color: #092338;
                padding: 0 10px;
                margin-right: 15px;
                cursor: pointer;
            }
            .active{
                color: #FFFFFF;
                background: $themeColor;
                border-radius: 2px;
            }
        }
    }

    .searchResult{
        // margin-top: 20px;
        .title{
            height: 40px;
            line-height: 40px;
            background: #F1F1F1;
            color: #3A3A3A;
            padding-left: 20px;
        }
        .resultList{
            padding: 20px;
            overflow: hidden;
            .resultItem{
                font-size: 14px;
                padding: 10px 0;
                border-bottom: 1px solid #E8E8E8;
                position: relative;
                .name{
                    display: inline-block;
                    color: var(--themeColor);
                    text-decoration: underline;
                    cursor: pointer;
                }
                .country{
                    display: inline-block;
                    margin-left: 20px;
                    img{
                        vertical-align: middle;
                    }
                }
                .tagModule{
                    display: flex;
                    .tag1{
                        height: 20px;
                        line-height: 20px;
                        padding: 0 5px;
                        background: rgba(255, 170, 72, 0.2);
                        border-radius: 1px;
                        font-size: 12px;
                        color: #FFAA48;
                        margin-right: 10px;
                    }
                    .tag2{
                        height: 20px;
                        line-height: 20px;
                        padding: 0 5px;
                        background: rgba(255, 94, 126, 0.2);
                        border-radius: 1px;
                        font-size: 12px;
                        color: #FF5E7E;
                        margin-right: 10px;
                    }
                }
                .color99{    
                    color: #999999;
                }
                .color3a{    
                    color: #3A3A3A;
                }
                .primaryColor{
                    color: var(--themeColor);
                    cursor: pointer;
                }
                .oneField{
                    width: 200px;
                    text-align: left;
                }
                .twoField{
                    line-height: 28px;
                }
                .addBtn{
                    position: absolute;
                    top: 10px;
                    right: 0;
                    padding: 0 10px 0 32px;
                    height: 28px;
                    line-height: 28px;
                    font-size: 14px;
                    color: #FFFFFF;
                    background: var(--themeColor);
                    border-radius: 4px;
                    cursor: pointer;
                    &:hover{
                       opacity: 0.7;
                    }
                    img{
                        position: absolute;
                        left: 10px;
                        top: 5px;
                    }
                }
                .hasAdd{
                    position: absolute;
                    top: 10px;
                    right: 0;
                    padding: 0 10px;
                    height: 28px;
                    line-height: 28px;
                    font-size: 14px;
                    color: #B7B7B7;
                    background: rgba(183, 183, 183, 0.2);
                    border: 1px solid #B7B7B7;
                    border-radius: 4px;
                }
            }
        }
    }
    .noResult{
        overflow: hidden;
        .hintImg{
            float: left;
            width: 800px;
        }
        .noImg{
            float: left;
            margin-left: 80px;
            margin-top: 200px;
            width: 95px;
            height: 100px;
        }
        .hintTxt{
            float: left;
            margin-left: 20px;
            margin-top: 200px;
            font-size: 80px;
            line-height: 100px;
            font-weight: bold;
            color: #DFDFDF;
        }
    }
}
</style>