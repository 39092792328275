<template>
  <div id="company-encode-company" class="company-encode-company" v-loading="loading" v-show="!hidden">
    <div class="title" ref="title">
      <div class="text">
        <p style="font-weight: bold">{{ companyType === "importer" ? "供应商" : "采购商" }}编码国家分析</p>
        <span class="sub-title">(该报表不包含船运数据)</span>
      </div>
      <div style="display: flex; align-items: center">
        <el-radio-group v-model="controlForm.option" size="mini">
          <el-radio-button :label="0">频次</el-radio-button>
          <el-radio-button :label="1">金额</el-radio-button>
          <el-radio-button :label="2">数量</el-radio-button>
          <el-radio-button :label="3">重量</el-radio-button>
        </el-radio-group>
        <img v-show="false" class="icon" src="@/firm/images/company/icon-download.png" style="margin-left: 1em" />
      </div>
    </div>
    <div class="content">
      <div class="first">
        <p class="title">【{{ companyType === "importer" ? "供应商" : "采购商" }}】</p>
        <div class="container">
          <div class="item" @click="handleFirstItemClick(index, item.company)" :class="{ selected: dataFirstSelectedIndex === index }" v-for="(item, index) of dataFirst" :key="index">
            <el-tooltip placement="bottom-start" :content="item.company">
              <div class="top signle-line-omit">{{ item.company }}</div>
            </el-tooltip>
            <div class="main">
              <el-progress class="custome-el-progress-bg" style="width: 190px" :color="companyType === 'importer' ? '#E5694F' : '#B88D6A'" :stroke-width="10" :percentage="item.percentage" :show-text="false" />
              <div style="margin-left: 12px">{{ item.percentage.toFixed(1) }}%</div>
            </div>
            <div class="description" v-show="dataFirstSelectedIndex === index">
              <img :src="companyType === 'importer' ? img.caigou : img.gongying" style="margin-left: 2em; height: 15px" />
              <div style="display: flex; width: 75%; margin-top: 1em; margin-left: 5px; justify-content: space-between">
                <span>{{ ["频次", "金额", "数量", "重量"][controlForm.option] }}:{{ item[["frequency", "amount", "count", "weight"][controlForm.option]] }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="second">
        <p class="title" style="display: flex; align-items: center; justify-content: flex-start">
          【编码】
          <el-radio-group v-model="hsForm.option" size="mini">
            <el-radio-button label="/search/Credit/Ranking/HsCode">六位</el-radio-button>
            <!-- <el-radio-button label="/search/Credit/Ranking/HsCode4">四位</el-radio-button>
            <el-radio-button label="/search/Credit/Ranking/HsCode2">二位</el-radio-button> -->
          </el-radio-group>
        </p>
        <div class="container">
          <div class="item" @click="handleSecondItemClick(index, item.encode)" :class="{ selected: dataSecondSelectedIndex === index }" v-for="(item, index) of dataSecond" :key="index">
            <div class="main">
              <el-tooltip placement="bottom-start" :content="item.description">
                <span style="margin-right: 0.5em; width: 50px; text-align: right">{{ item.encode }}</span>
              </el-tooltip>
              <el-progress class="custome-el-progress-bg" style="width: 120px" color="var(--themeColor, #3152AF)" :stroke-width="10" :percentage="item.percentage" :show-text="false" />
              <div style="margin-left: 12px">{{ item.percentage.toFixed(1) }}%</div>
            </div>
            <div class="description" v-show="dataSecondSelectedIndex === index">
              <img src="@/firm/images/company/icon-tag-b.png" style="margin-left: 4em; height: 15px" />
              <div style="display: flex; width: 75%; margin-top: 1em; margin-left: 5px; justify-content: space-between">
                <span>{{ ["频次", "金额", "数量", "重量"][controlForm.option] }}:{{ item[["frequency", "amount", "count", "weight"][controlForm.option]] }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="third">
        <p class="title">【国家】</p>
        <div class="container">
          <div class="item" v-for="(item, index) of dataThird" :key="index">
            <div class="main">
              <span style="background: #746b63; color: #fff; padding: 0 0.5em; margin: 0 1em; font-size: 12px">{{ `TOP${index + 1}` }}</span>
              <img class="flag" :src="item.flagSrc" />
              <el-tooltip effect="light" :content="item.country" placement="top-start">
                <div class="country signle-line-omit" style="width: 90px; margin-right: 5px; font-size: 14px">{{ item.country }}</div>
              </el-tooltip>
              <el-progress class="custome-el-progress-bg" style="width: 220px" color="#746B63" :stroke-width="10" :percentage="item.percentage" :show-text="false" />
              <div style="margin-left: 12px; font-size: 14px">{{ item[["frequency", "amount", "count", "weight"][controlForm.option]] }}({{ item.percentage.toFixed(1) }}%)</div>
            </div>
            <div class="description">
              <img src="@/firm/images/company/encode/line-company.png" style="margin-left: 9em; height: 15px" />
              <div style="display: flex; width: 70%; margin-top: 1em; margin-left: 5px; justify-content: space-between">
                <span>频次:{{ item.frequency }}</span>
                <span>金额:{{ item.amount }}</span>
                <span>重量:{{ item.weight }}</span>
                <span>数量:{{ item.count }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "@/firm/plugins/axios";
import { mapState, mapGetters, mapMutations } from "vuex";
import LazyOperation from "@/firm/plugins/lazy-execute";
import { AnchorInditor } from "../../utils";

let lazyOperation = null;

export default Vue.extend({
  inheritAttrs: false,
  data() {
    return {
      img: {
        caigou: require("@/firm/images/company/encode/line-caigou.png"),
        gongying: require("@/firm/images/company/encode/line-gongying.png"),
      },
      hidden: false,
      loading: true,
      controlForm: {
        option: 0,
      },
      hsForm: {
        option: "/search/Credit/Ranking/HsCode",
      },
      dataFirst: [],
      dataFirstSelectedIndex: 0,
      dataSecond: [],
      dataSecondSelectedIndex: 0,
      dataThird: [],
    };
  },
  computed: {
    ...mapState(["company", "companyType"]),
    ...mapState("controlFormGlobal", {
      ie: "ie",
      dataCountry: "dataCountry",
      dateRange: "dateRange",
      hsCode: "hsCode",
      importer: "importer",
      exporter: "exporter",
      country: "country",

      exHsCode: "exHsCode",
      exExporter: "exExporter",
      exImporter: "exImporter",
      exCountry: "exCountry",
    }),
    ...mapGetters("controlFormGlobal", {
      startTime: "startTime",
      endTime: "endTime",
    }),
  },
  watch: {
    "hsForm.option"() {
      const company = (this.dataFirst[this.dataFirstSelectedIndex]).company;
      this.handleFirstItemClick(this.dataFirstSelectedIndex, company);
    },
    "controlForm.option"() {
      lazyOperation?.reobserve();
    },
    ie() {
      this.hidden = false;
      this.setAnchorFlag([["COMPECODECOUNTR", true]]);
      lazyOperation?.reobserve();
    },
    dataCountry() {
      this.hidden = false;
      this.setAnchorFlag([["COMPECODECOUNTR", true]]);
      lazyOperation?.reobserve();
    },
    dateRange() {
      this.hidden = false;
      this.setAnchorFlag([["COMPECODECOUNTR", true]]);
      lazyOperation?.reobserve();
    },
    hsCode() {
      this.hidden = false;
      this.setAnchorFlag([["COMPECODECOUNTR", true]]);
      lazyOperation?.reobserve();
    },
    importer() {
      this.hidden = false;
      this.setAnchorFlag([["COMPECODECOUNTR", true]]);
      lazyOperation?.reobserve();
    },
    exporter() {
      this.hidden = false;
      this.setAnchorFlag([["COMPECODECOUNTR", true]]);
      lazyOperation?.reobserve();
    },
    country() {
      this.hidden = false;
      this.setAnchorFlag([["COMPECODECOUNTR", true]]);
      lazyOperation?.reobserve();
    },

    exHsCode() {
      this.hidden = false;
      this.setAnchorFlag([["COMPECODECOUNTR", true]]);
      lazyOperation?.reobserve();
    },
    exExporter() {
      this.hidden = false;
      this.setAnchorFlag([["COMPECODECOUNTR", true]]);
      lazyOperation?.reobserve();
    },
    exImporter() {
      this.hidden = false;
      this.setAnchorFlag([["COMPECODECOUNTR", true]]);
      lazyOperation?.reobserve();
    },
    exCountry() {
      this.hidden = false;
      this.setAnchorFlag([["COMPECODECOUNTR", true]]);
      lazyOperation?.reobserve();
    },

    companyType() {
      this.hidden = false;
      this.setAnchorFlag([["COMPECODECOUNTR", true]]);
      lazyOperation?.reobserve();
    },
  },
  methods: {
    ...mapMutations("anchor", {
      setAnchorFlag: "setAnchorFlag",
      setCurrent: "setCurrent",
    }),
    async handleFirstItemClick(index, company) {
      try {
        this.loading = true;
        const dataSecond = await this.getDataSecond(company);
        this.dataSecond = dataSecond;
        this.dataFirstSelectedIndex = index;
        const encode = dataSecond[0].encode;
        const dataThird = await this.getDataThird(company, encode);
        this.dataThird = dataThird;
        this.dataSecondSelectedIndex = 0;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async getDataFirst() {
      try {
        const res = await axios({
          method: "post",
          url: this.companyType === "importer" ? "/search/Credit/Ranking/Exporter" : "/search/Credit/Ranking/Importer",
          data: {
            pageIndex: 1,
            pageSize: 100,
            ie: this.ie,
            importer: this.importer,
            exporter: this.exporter,
            dataCountry: this.dataCountry,
            country: this.country,
            startTime: this.startTime,
            endTime: this.endTime,
            hsCode: this.hsCode,
            sortType: this.controlForm.option,
            noHsCode: this.exHsCode,
            noExporter: this.exExporter,
            noImporter: this.exImporter,
            noCountry: this.exCountry,
            company: this.company,
            companyType: this.companyType,
          },
        });
        const data = res.data.result.data.map((item) => ({
          company: item.company,
          percentage: item[["frequencyRatio", "amountRadio", "countRadio", "weightRadio"][this.controlForm.option]], //百分比
          frequency: item.frequency, //频次
          amount: item.amount, //金额
          count: item.count, //数量
          weight: item.weight, //重量
        }));
        return Promise.resolve(data);
      } catch (error) {
        return Promise.reject("");
      }
    },
    async handleSecondItemClick(index, encode) {
      try {
        this.loading = true;
        const company = (this.dataFirst[this.dataFirstSelectedIndex]).company;
        const dataThird = await this.getDataThird(company, encode);
        this.dataThird = dataThird;
        this.dataSecondSelectedIndex = index;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async getDataSecond(impexpter) {
      try {
        const res = await axios({
          method: "post",
          url: this.hsForm.option,
          data: {
            pageIndex: 1,
            pageSize: 100,
            ie: this.ie,
            importer: this.companyType === "importer" ? this.importer : [impexpter], //this.importer,
            exporter: this.companyType === "importer" ? [impexpter] : this.exporter, //this.exporter,
            dataCountry: this.dataCountry,
            country: this.country,
            startTime: this.startTime,
            endTime: this.endTime,
            hsCode: this.hsCode,
            sortType: this.controlForm.option,
            noHsCode: this.exHsCode,
            noExporter: this.exExporter,
            noImporter: this.exImporter,
            noCountry: this.exCountry,
            company: this.company,
            companyType: this.companyType,
          },
        });
        const data = res.data.result.data.map((item) => ({
          encode: item.encode, //编码
          description: item.name, //编码信息
          percentage: item[["frequencyRatio", "amountRadio", "countRadio", "weightRadio"][this.controlForm.option]], //百分比
          frequency: item.frequency, //频次
          amount: item.amount, //金额
          count: item.count, //数量
          weight: item.weight, //重量
        }));
        return Promise.resolve(data);
      } catch (error) {
        return Promise.reject("");
      }
    },
    async getDataThird(company, encode) {
      try {
        const res = await axios({
          method: "post",
          url: "/search/Credit/Ranking/Country",
          data: {
            pageIndex: 1,
            pageSize: 100,
            ie: this.ie,
            importer: this.companyType === "importer" ? this.importer : [company],
            exporter: this.companyType === "importer" ? [company] : this.exporter,
            dataCountry: this.dataCountry,
            country: this.country,
            startTime: this.startTime,
            endTime: this.endTime,
            hsCode: [encode],
            sortType: this.controlForm.option,
            noHsCode: this.exHsCode,
            noExporter: this.exExporter,
            noImporter: this.exImporter,
            noCountry: this.exCountry,
            company: this.company,
            companyType: this.companyType,
          },
        });
        const data = res.data.result.data.map((item) => ({
          flagSrc: item.flagSrc,
          country: item.countryCN,
          percentage: item[["frequencyRatio", "amountRadio", "countRadio", "weightRadio"][this.controlForm.option]],
          frequency: item.frequency,
          amount: item.amount,
          count: item.count,
          weight: item.weight,
        }));
        return Promise.resolve(data);
      } catch (error) {
        return Promise.reject("");
      }
    },
  },
  mounted() {
    lazyOperation = new LazyOperation(async () => {
      try {
        this.loading = true;
        const dataFirst = await this.getDataFirst();
        this.dataFirst = dataFirst;
        this.dataFirstSelectedIndex = 0;
        const dataSecond = await this.getDataSecond(dataFirst[0].company);
        this.dataSecond = dataSecond;
        this.dataSecondSelectedIndex = 0;
        const dataThird = await this.getDataThird(dataFirst[0].company, dataSecond[0].encode);
        this.dataThird = dataThird;
      } catch {
        this.hidden = true;
        this.setAnchorFlag([["COMPECODECOUNTR", false]]);
      } finally {
        this.loading = false;
      }
    }, this.$el);
    new AnchorInditor(() => {
      this.setCurrent("COMPECODECOUNTR");
    }, this.$refs["title"]);
  },
});
</script>

<style lang="scss" scoped>
@import "@/firm/scss/mixin.scss";

.company-encode-company {
  margin-top: 1em;
  background: #fff;
  padding: 1rem;
  padding-top: 0;
  @include mixin-panel-title;
  .content {
    $contentHeight: 400px;
    $highlighBackground: #f5e8da;
    $borderRadius: 10px;
    display: flex;
    height: $contentHeight;
    $titleHeight: 3em;
    $selecedHeight: 4em;
    .first {
      border-top-left-radius: $borderRadius;
      border-bottom-left-radius: $borderRadius;
      background: #eeeeee;
      width: 25%;
      height: $contentHeight;
      .title {
        height: $titleHeight;
        line-height: $titleHeight;
      }
      .container {
        height: calc(100% - #{$titleHeight});
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 6px;
        }
        &::-webkit-scrollbar-thumb {
          background: #d5d5d5;
        }
        scrollbar-width: thin;
        scrollbar-color: #d5d5d5 #fff;
        .item {
          cursor: pointer;
          margin-bottom: 1em;
          &.selected {
            background: $highlighBackground;
            height: $selecedHeight;
          }
          .top {
            padding-top: 0.2em;
            padding-left: 1.5em;
            font-size: 14px;
          }
          .main {
            margin-left: 1.5em;
            font-size: 14px;
            display: flex;
            align-items: center;
          }
          .description {
            display: flex;
            align-items: center;
            font-size: 12px;
          }
        }
      }
    }
    .second {
      width: 25%;
      height: $contentHeight;
      background: $highlighBackground;
      .title {
        height: $titleHeight;
        line-height: $titleHeight;
      }
      .container {
        height: calc(100% - #{$titleHeight});
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 6px;
        }
        &::-webkit-scrollbar-thumb {
          background: #d5d5d5;
        }
        scrollbar-width: thin;
        scrollbar-color: #d5d5d5 #fff;
        .item {
          cursor: pointer;
          margin-bottom: 1em;
          padding-left: 1em;
          padding-bottom: 0.5em;
          &.selected {
            background: #e8c6a1;
          }
          .main {
            padding-top: 0.5em;
            font-size: 14px;
            display: flex;
            align-items: center;
          }
          .description {
            display: flex;
            align-items: center;
            font-size: 14px;
          }
        }
      }
    }
    .third {
      width: 50%;
      border-top-right-radius: $borderRadius;
      border-bottom-right-radius: $borderRadius;
      height: $contentHeight;
      background: #e8c6a1;
      $titleHeight: 3em;
      .title {
        height: $titleHeight;
        line-height: $titleHeight;
      }
      .container {
        height: calc(100% - #{$titleHeight});
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 6px;
        }
        &::-webkit-scrollbar-thumb {
          background: #d5d5d5;
        }
        scrollbar-width: thin;
        scrollbar-color: #d5d5d5 #fff;
        .item {
          margin-bottom: 1em;
          .main {
            display: flex;
            align-items: center;
            .flag {
              height: 1em;
              margin-right: 1em;
              box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
            }
          }
          .description {
            display: flex;
            align-items: center;
            font-size: 0.8em;
          }
        }
      }
    }
  }
}
</style>
