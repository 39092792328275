<template>
<div>
    <div v-if="ie=='i'">
        <!-- 科特迪瓦(CIV)进口提单明细 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th width="25%">进口商ID</th>
                        <td width="25%">{{billInfo.ImporterId}}</td>
                        <th width="25%">净重</th>
                        <td width="25%">{{billInfo.NetWeight}} {{billInfo.NetWeightUnit}}</td>
                    </tr>
                    <tr>
                        <th>美元金额</th>
                        <td>{{billInfo.Value}} {{billInfo.ValueUnit}}</td>
                        <th>量单位代码</th>
                        <td>{{billInfo.QuantityUnitCode}}</td>
                    </tr>
                    <tr>
                        <th>包装数</th>
                        <td>{{billInfo.Packages}}</td>
                        <th>CIF当地金额</th>
                        <td>{{billInfo.LocalCifValue}} {{billInfo.LocalCifValueUnit}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">运输信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">原产国</th>
                        <td width="25%">{{billInfo.Country}}</td>
                        <th width="25%">原产国代码</th>
                        <td width="25%">{{billInfo.OriginCountryCode}}</td>
                    </tr>
                    <tr>
                        <th>货源国</th>
                        <td>{{billInfo.SalesCountry}}</td>
                        <th>货源国代码</th>
                        <td>{{billInfo.SalesCountryCode}}</td>
                    </tr>
                    <tr>
                        <th>目的国</th>
                        <td>{{billInfo.DestinationCountry}}</td>
                        <th>目的国代码</th>
                        <td>{{billInfo.DestinationCountryCode}}</td>
                    </tr>
                    <tr>
                        <th>装货港</th>
                        <td>{{billInfo.LoadingPort}}</td>
                        <th>装货港代码</th>
                        <td>{{billInfo.LoadingPortCode}}</td>
                    </tr>
                    <tr>
                        <th>运输方式</th>
                        <td>{{billInfo.TransportType}}</td>
                        <th>运输方式代码</th>
                        <td>{{billInfo.TransportTypeCode}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
    <div v-if="ie=='e'">
        <!-- 科特迪瓦(CIV)出口提单明细 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th width="25%">出口商ID</th>
                        <td width="25%">{{billInfo.ExporterId}}</td>
                        <th width="25%">净重</th>
                        <td width="25%">{{billInfo.NetWeight}} {{billInfo.NetWeightUnit}}</td>
                    </tr>
                    <tr>
                        <th>量单位代码</th>
                        <td>{{billInfo.QuantityUnitCode}}</td>
                        <th>包装数</th>
                        <td>{{billInfo.Packages}}</td>
                    </tr>
                    <tr>
                        <th>CIF当地金额</th>
                        <td colspan="3">{{billInfo.Value}} {{billInfo.ValueUnit}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">运输信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">原产国</th>
                        <td width="25%">{{billInfo.OriginCountry}}</td>
                        <th width="25%">原产国代码</th>
                        <td width="25%">{{billInfo.OriginCountryCode}}</td>
                    </tr>
                    <tr>
                        <th>货源国</th>
                        <td>{{billInfo.SalesCountry}}</td>
                        <th>货源国代码</th>
                        <td>{{billInfo.SalesCountryCode}}</td>
                    </tr>
                    <tr>
                        <th>目的国</th>
                        <td>{{billInfo.Country}}</td>
                        <th>目的国代码</th>
                        <td>{{billInfo.DestinationCountryCode}}</td>
                    </tr>
                    <tr>
                        <th>卸货港</th>
                        <td>{{billInfo.UnloadingPort}}</td>
                        <th>卸货港代码</th>
                        <td>{{billInfo.UnloadingPortCode}}</td>
                    </tr>
                    <tr>
                        <th>运输方式</th>
                        <td>{{billInfo.TransportType}}</td>
                        <th>运输方式代码</th>
                        <td>{{billInfo.TransportTypeCode}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
</div>
</template>

<script>
export default {
    props:["billInfo","ie"],
    data() {
        return {

        };
    },
    methods: {
        
    },
    mounted() {

    },
};
</script>

<style lang="scss" scoped>
.partItem {
    margin-bottom: 20px;
    /deep/.el-card__header {
        padding: 10px 20px;
        .headTitle {
            display: inline-block;
            padding-left: 10px;
            font-size: 14px;
            border-left: 2px solid var(--themeColor);
            font-weight: bold;
        }
    }
}
table {
    width: 100%;
    border-collapse: collapse;
    tr {
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;
        th,
        td {
            font-size: 12px;
            color: #666;
            height: 40px;
            border: 1px solid #eaeaea;
            text-align: center;
        }
        th {
            background-color: #f5f7fa;
            font-weight: 700;
        }
        .bg {
            background-color: var(--themeColor);
            color: #fff;
            font-weight: 700;
            opacity: 0.8;
        }
    }
}
</style>