<script>
import { ref, defineComponent } from '@vue/composition-api'
import dayjs from 'dayjs'
export default defineComponent({
  props: ['data'],
  setup(props) {
    const gap = ref(dayjs(props.data.endTime).diff(dayjs(dayjs().format('YYYY-MM-DD')), 'days'))
    const isOut = ref(gap.value < 0)
    const isUrgent = ref(gap.value < 3 && gap.value >= 0)
    return {
      gap,
      isOut,
      isUrgent
    }
  }
})
</script>

<template>
  <div @click="$emit('cardclick')" class="card">
    <p style="color: #092338; font-weight: bold">{{ $props.data.businessChanceName }}</p>
    <p style="color: #878787; margin-bottom: 10px">{{ `${$props.data.currency}${$props.data.amount}` }}</p>
    <p style="color: #878787">{{ `结束日期：${$props.data.endTime || '不限'}` }}</p>
    <div style="color: #878787; display: flex; align-items: center">
      <p>{{ `当前阶段停留时间：${$props.data.dwellTime}天` }}</p>
      <span v-if="isUrgent && gap !== 0" style="margin-left: 3px; padding: 0 3px; font-size: 12px; color: #E56210; background: rgba(229, 98, 16, 0.1); border: 1px solid #E56210; border-radius: 2px">{{ `剩余${gap + 1}天` }}</span>
      <span v-if="isUrgent && gap === 0" style="margin-left: 3px; padding: 0 3px; font-size: 12px; color: #E56210; background: rgba(229, 98, 16, 0.1); border: 1px solid #E56210; border-radius: 2px">今日过期</span>
      <span v-if="isOut" style="margin-left: 3px; padding: 0 3px; font-size: 12px; color: #FF0000; background: rgba(255, 0, 0, 0.1); border: 1px solid #FF0000; border-radius: 2px">{{ `过期${Math.abs(gap)}天` }}</span>
    </div>
    <p style="color: #878787">{{ `创建人：${$props.data.createUserName}` }}</p>
  </div>
</template>

<style scoped lang="scss">
.card {
  cursor: pointer;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
  background: #fff;
  box-sizing: border-box;
  padding: 18px 12px;
  font-size: 14px;
  color: #878787;

  >p,
  div {
    margin-bottom: 3px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
