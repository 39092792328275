<template>
    <div style="display: flex;">
        <CustomerNav @chooseNav="chooseNav" :currentPath="currentPath" />
        <div class="view" v-loading="loading.view">
            <div>
                <div style="display: flex">
                    <el-select style="width: 100px" v-model="form.range" size="small">
                        <el-option value="全部" />
                        <el-option value="公司名称" />
                        <el-option value="联系人邮箱" />
                        <el-option value="电话" />
                        <el-option value="社媒网址" />
                    </el-select>
                    <el-input style="width: 800px" prefix-icon="el-icon-search" v-model="form.word" size="small" placeholder="请输入公司名称、邮箱地址、联系人名称、主页、电话、社交账号" />
                    <el-button @click="handleSearchClick" type="primary" size="small" style="margin-left: 20px">客户查重</el-button>
                </div>
                <p v-show="step === 1" style="font-size: 14px; color: #7c90a5; margin-top: 20px">为了避免销售冲突，我们可以通过客户查重来查看客户的归属情况；客户查重是基于全公司所有客户资料的全局查找。</p>
            </div>
            <div v-show="step === 2" style="padding-top: 50px; width: 1160px">
                <el-table :data="tableData" height="400px" :header-cell-style="{ 'background-color': '#E5E9F2', color: '#1F2D3D' }" size="small">
                    <el-table-column label="客户编号" show-overflow-tooltip>
                        <template slot-scope="{ row }">
                            <span style="color: #0165b4">{{ row.no }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="公司状态" prop="statuName" show-overflow-tooltip />
                    <el-table-column label="公司名称" prop="name" show-overflow-tooltip />
                    <el-table-column label="联系人名" show-overflow-tooltip />
                    <el-table-column label="客户邮箱" prop="email" show-overflow-tooltip />
                    <el-table-column label="客户电话" prop="call" show-overflow-tooltip />
                    <el-table-column label="当前归属" prop="userCnName" show-overflow-tooltip />
                    <el-table-column label="创建时间" prop="createTime" show-overflow-tooltip />
                    <el-table-column label="社交账号" show-overflow-tooltip />
                </el-table>
                <div style="display: flex; justify-content: flex-end; padding-top: 12px">
                    <el-pagination background layout="prev, pager, next, jumper" :total="pagination.total" :page-size="pagination.pageSize" :current-page="pagination.pageIndex" @current-change="search" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CustomerNav from "@/components/CustomerNav.vue";
export default {
    components: { CustomerNav },
    props: {
        handleSelect: {
            type: Function,
            default: null,
        },
    },
    data() {
        return {
            currentPath: "/customerManage/perspCustomer",
            step: 1,
            loading: {
                view: false,
            },
            form: {
                range: "全部",
                word: "",
            },
            tableData: [],
            pagination: {
                total: 0,
                pageIndex: 1,
                pageSize: 10,
            },
        };
    },
    methods: {
        chooseNav(index){
            this.handleSelect(index);
            this.$router.push(index);
        },

        async search(pageIndex = 1) {
            try {
                this.loading.view = true;
                const res = await this.$axios({
                    method: "GET",
                    url: this.$customerUrl + "/Company/CheckDuplicate",
                    params: {
                        name: this.form.word,
                        pageIndex,
                        pageSize: this.pagination.pageSize,
                    },
                });
                const data = res.data;
                this.tableData = data.datas;
                this.pagination.total = data.totalItems;
                this.pagination.pageIndex = pageIndex;
            } catch {
                null;
            } finally {
                this.loading.view = false;
            }
        },
        async handleSearchClick() {
            this.step = 2;
            this.search();
        },
    },
};
</script>

<style lang="scss" scoped>
.view {
    width: calc(100% - 200px);
    height: calc(100vh - 140px);
    background: #ffffff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
</style>
