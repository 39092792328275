<template>
<div style="display: flex;">
    <div class="ReportNav">
        <div @click="changeCurrent('CustomerAnalysis')" class="navItem" :class="{ active: current === 'CustomerAnalysis' }">客户分析</div>
        <div @click="changeCurrent('ProductAnalysis')" class="navItem" :class="{ active: current === 'ProductAnalysis' }">产品分析</div>
        <!-- <div @click="changeCurrent('OrderAnalysis')" class="navItem" :class="{ active: current === 'OrderAnalysis' }">订单分析(Old)</div> -->
        <div @click="changeCurrent('NewOrderAnalysis')" class="navItem" :class="{ active: current === 'NewOrderAnalysis' }">订单分析</div>
        <div v-if="userInfo.isProcureModel" @click="changeCurrent('PurchaseAnalysis')" class="navItem" :class="{ active: current === 'PurchaseAnalysis' }">采购分析</div>
        <div @click="changeCurrent('StaffAnalysis')" class="navItem" :class="{ active: current === 'StaffAnalysis' }">员工分析</div>
        <div @click="changeCurrent('FinancialAnalysis')" class="navItem" :class="{ active: current === 'FinancialAnalysis' }">财务分析</div>
        <div @click="changeCurrent('BussinessAnalysis')" class="navItem" :class="{ active: current === 'BussinessAnalysis' }">商机分析</div>
        <!-- <div @click="changeCurrent('ClueAnalysis')" class="navItem" :class="{ active: current === 'ClueAnalysis' }">线索分析</div> -->
        <div @click="changeCurrent('MailAnalysis')" class="navItem" :class="{ active: current === 'MailAnalysis' }">邮箱分析</div>
        <!-- <div @click="changeCurrent('MarketingAnalysis')" class="navItem" :class="{ active: current === 'MarketingAnalysis' }">营销分析</div> -->
        <div @click="changeCurrent('PerformanceAnalysis')" class="navItem" :class="{ active: current === 'PerformanceAnalysis' }">绩效分析</div>
    </div>
    <div class="rightBox">
        <component :is="current" />
    </div>
</div>
</template>

<script>
import CustomerAnalysis from "./CustomerAnalysis.vue";
import ProductAnalysis from "./ProductAnalysis.vue";
import OrderAnalysis from "./OrderAnalysis.vue";
import NewOrderAnalysis from "./NewOrderAnalysis.vue";
import PurchaseAnalysis from "./PurchaseAnalysis.vue";
import StaffAnalysis from "./StaffAnalysis.vue";
import FinancialAnalysis from "./FinancialAnalysis.vue";
import BussinessAnalysis from "./BussinessAnalysis.vue";
import ClueAnalysis from "./ClueAnalysis.vue";
import MailAnalysis from "./MailAnalysis.vue";
import MarketingAnalysis from "./MarketingAnalysis.vue";
import PerformanceAnalysis from "./PerformanceAnalysis.vue";
export default {
    components: {
        CustomerAnalysis,
        ProductAnalysis,
        OrderAnalysis,
        NewOrderAnalysis,
        PurchaseAnalysis,
        StaffAnalysis,
        FinancialAnalysis,
        BussinessAnalysis,
        ClueAnalysis,
        MailAnalysis,
        MarketingAnalysis,
        PerformanceAnalysis,
    },
    data() {
        return {
            current: "CustomerAnalysis",
            userInfo: {},
        };
    },
    methods: {
        changeCurrent(type) {
            this.current = type;
        },
    },
    mounted() {
        this.userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {};
    },
};
</script>

<style lang="scss" scoped>
@import '@/scss/common.scss';
.ReportNav {
    width: 160px;
    height: calc(100vh - 80px);
    background: #ffffff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    .navItem {
        cursor: pointer;
        padding: 0 15px;
        height: 40px;
        line-height: 40px;
        color: #000;
        font-size: 14px;
        &.active {
            color: #fff;
            background: $themeColor;
        }
        img{
            vertical-align: text-bottom;
            margin-right: 5px;
        }
    }
}
.rightBox{
    width: calc(100% - 160px);
    height: calc(100vh - 80px);
    box-sizing: border-box;
    overflow: auto;
    padding: 0 20px 15px 20px;
    background: rgba($color: #FFFFFF, $alpha: 0.7);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}
</style>
