import { render, staticRenderFns } from "./CostaRica.vue?vue&type=template&id=607958fc&scoped=true&"
import script from "./CostaRica.vue?vue&type=script&lang=js&"
export * from "./CostaRica.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "607958fc",
  null
  
)

export default component.exports