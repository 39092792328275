<template>
  <div id="abroad-other" class="abroad-other" v-show="show">
    <div class="title" ref="title">
      <p>其他信息</p>
      <feedback position="其他信息" url="" />
      <!-- <div class="flex-container">
        <img class="icon" src="@/firm/images/company/icon-feedback.png" style="margin-left: 1em" />
        <span class="text-with-icon" style="color: var(--themeColor, #3152AF)">反馈</span>
      </div> -->
    </div>
    <div class="content">
      <div class="head">
        <p>其它信息</p>
      </div>
      <p class="item" v-show="other.statue">
        <span class="key">公司状态：</span>
        <span class="description">{{ other.statue }}</span>
      </p>
      <p class="item" v-show="other.year">
        <span class="key">成立年份：</span>
        <span class="description">{{ other.year }}</span>
      </p>
      <p class="item" v-show="other.count">
        <span class="key">员工数：</span>
        <span class="description">{{ other.count }}</span>
      </p>
      <p class="item" v-show="other.web">
        <span class="key">官网：</span>
        <span class="description">{{ other.web }}</span>
      </p>
      <p class="item" v-show="other.country">
        <span class="key">国家：</span>
        <span class="description">{{ other.country }}</span>
      </p>
      <p class="item" v-show="other.area">
        <span class="key">地区、州：</span>
        <span class="description">{{ other.area }}</span>
      </p>
      <p class="item" v-show="other.city">
        <span class="key">城市：</span>
        <span class="description">{{ other.city }}</span>
      </p>
      <p class="item" v-show="other.address">
        <span class="key">地址：</span>
        <span class="description">{{ other.address }}</span>
      </p>
      <p class="item" v-show="other.zip">
        <span class="key">邮编：</span>
        <span class="description">{{ other.zip }}</span>
      </p>
      <p class="item" v-show="other.product">
        <span class="key">产品：</span>
        <span class="description">{{ other.product }}</span>
      </p>
      <p class="item" v-show="other.contact">
        <span class="key">联系人：</span>
        <span class="description">{{ other.contact }}</span>
      </p>
      <p class="item" v-show="other.contact && other.position">
        <span class="key">职位：</span>
        <span class="description">{{ other.position }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapMutations } from "vuex";
import { AnchorInditor } from "../../../utils";

export default Vue.extend({
  props: ["other"],
  data() {
    return {
      show: false,
    };
  },
  watch: {
    other() {
      try {
        for (const key in this.other) {
          if (this.other[key]) {
            this.setAnchorFlag([["QTXX", true]]);
            this.show = true;
            return;
          }
        }
        throw new Error();
      } catch {
        this.setAnchorFlag([["QTXX", false]]);
        this.show = false;
      }
    },
  },
  methods: {
    ...mapMutations("anchor", {
      setAnchorFlag: "setAnchorFlag",
      setCurrent: "setCurrent",
    }),
  },
  mounted() {
    new AnchorInditor(() => {
      this.setCurrent("QTXX");
    }, this.$refs["title"]);
  },
});
</script>

<style lang="scss" scoped>
@import "@/firm/scss/mixin.scss";

.abroad-other {
  background: #fff;
  padding: 1rem;
  padding-top: 0;
  @include mixin-panel-title;
  .content {
    color: #1f2d3d;
    font-size: 14px;
    $borderStyle: 1px solid #d7d7d8;
    box-sizing: border-box;
    border: $borderStyle;
    .head {
      display: flex;
      align-items: center;
      height: 52px;
      box-sizing: border-box;
      border-bottom: $borderStyle;
      background: #f8faff;
      padding-left: 22px;
      margin-bottom: 5px;
    }
    .item {
      margin-bottom: 5px;
      .key {
        margin-right: 1em;
        display: inline-block;
        width: 90px;
        text-align: right;
      }
    }
  }
}
</style>
