<template>
    <div v-loading="loading">
        <!-- 印度 -->
        <el-descriptions :column="2" border :labelStyle="{width:'15%'}" :contentStyle="{width:'35%'}">
            <el-descriptions-item label="企业代码">{{businessInfo.india.iec}}</el-descriptions-item>
            <el-descriptions-item label="进出口授权日期">{{businessInfo.india.iecAllotmentDate}}</el-descriptions-item>
            <el-descriptions-item label="授权文件编码">{{businessInfo.india.fileNumber}}</el-descriptions-item>
            <el-descriptions-item label="授权文件标注日期">{{businessInfo.india.fileDate}}</el-descriptions-item>
            <el-descriptions-item label="企业名称">{{businessInfo.india.companyName}}</el-descriptions-item>
            <el-descriptions-item label="企业地址">{{businessInfo.india.address}}</el-descriptions-item>
            <el-descriptions-item label="企业地址2">{{businessInfo.india.companyAddress2}}</el-descriptions-item>
            <el-descriptions-item label="企业城市">{{businessInfo.india.companyCity}}</el-descriptions-item>
            <el-descriptions-item label="企业邮编">{{businessInfo.india.companyPin}}</el-descriptions-item>
            <el-descriptions-item label="企业电话">{{businessInfo.india.phoneNo}}</el-descriptions-item>
            <el-descriptions-item label="企业邮箱">{{businessInfo.india.email}}</el-descriptions-item>
            <el-descriptions-item label="企业类型">{{businessInfo.india.exporterType}}</el-descriptions-item>
            <el-descriptions-item label="企业成立日期">{{businessInfo.india.establishmentDate}}</el-descriptions-item>
            <el-descriptions-item label="企业永久账户代码">{{businessInfo.india.bin}}</el-descriptions-item>
            <el-descriptions-item label="账户授权日期">{{businessInfo.india.panIssueDate}}</el-descriptions-item>
            <el-descriptions-item label="账户授权机构">{{businessInfo.india.panIssuedBy}}</el-descriptions-item>
            <el-descriptions-item label="企业性质">{{businessInfo.india.natureOfConcern}}</el-descriptions-item>
            <el-descriptions-item label="开户行">{{businessInfo.india.bankerDetail}}</el-descriptions-item>
            <el-descriptions-item label="账户类型">{{businessInfo.india.accountType}}</el-descriptions-item>
            <el-descriptions-item label="开户行账号">{{businessInfo.india.accountNum}}</el-descriptions-item>
            <el-descriptions-item label="公司名标准">{{businessInfo.india.companyStd}}</el-descriptions-item>
            
            <el-descriptions-item label="联系人">{{businessInfo.indiaDirectors[0].directors}}</el-descriptions-item>
            <el-descriptions-item label="联系人2">{{businessInfo.indiaDirectors[0].directors2}}</el-descriptions-item>
            <el-descriptions-item label="联系人地址">{{businessInfo.indiaDirectors[0].directorsAddress}}</el-descriptions-item>
            <el-descriptions-item label="联系人地址2">{{businessInfo.indiaDirectors[0].directorsAddress2}}</el-descriptions-item>
            <el-descriptions-item label="联系人所在城市">{{businessInfo.indiaDirectors[0].directorsCity}}</el-descriptions-item>
            <el-descriptions-item label="联系人邮编">{{businessInfo.indiaDirectors[0].directorsPin}}</el-descriptions-item>
            <el-descriptions-item label="联系人邮箱">{{businessInfo.indiaDirectors[0].phoneEmail1}}</el-descriptions-item>
        </el-descriptions>
    </div>
</template>

<script>
export default {
    data() {
        return {
            businessInfo: {},
            loading: false,
        };
    },
    methods: {
        getInfo(){
            let companyName = encodeURIComponent(this.$route.query.companyName);
            let country = this.$route.query.country;
            this.loading = true;
            this.$axios.get(`${this.$clueUrl}/BuyerLeads/GetBusinessInformation?country=${country}&company=${companyName}`).then((res) => {
                const { isSuccess, data } = res;
                this.loading = false;
                if (isSuccess) {
                    this.businessInfo = data;
                }
            });
        },
    },
    mounted() {
        this.getInfo();
    },
};
</script>

<style lang="scss" scoped>

</style>