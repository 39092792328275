<template>
    <div v-loading="loading">
        <!-- 美国 -->
        <el-descriptions :column="2" border :labelStyle="{width:'15%'}" :contentStyle="{width:'35%'}">
            <el-descriptions-item label="公司代码">{{businessInfo.companyId}}</el-descriptions-item>
            <el-descriptions-item label="公司名">{{businessInfo.company}}</el-descriptions-item>
            <el-descriptions-item label="公司名标准">{{businessInfo.companyStd}}</el-descriptions-item>
            <el-descriptions-item label="公司名英文">{{businessInfo.companyEn}}</el-descriptions-item>
            <el-descriptions-item label="公司所在国">{{businessInfo.country}}</el-descriptions-item>
            <el-descriptions-item label="州">{{businessInfo.state}}</el-descriptions-item>
            <el-descriptions-item label="公司所在城市">{{businessInfo.city}}</el-descriptions-item>
            <el-descriptions-item label="地址">{{businessInfo.address}}</el-descriptions-item>
            <el-descriptions-item label="地址2">{{businessInfo.address2}}</el-descriptions-item>
            <el-descriptions-item label="邮编">{{businessInfo.zipCode}}</el-descriptions-item>
            <el-descriptions-item label="员工数">{{businessInfo.employeesTotal}}</el-descriptions-item>
            <el-descriptions-item label="公司类型">{{businessInfo.companyType}}</el-descriptions-item>
            <el-descriptions-item label="状态">{{businessInfo.status}}</el-descriptions-item>
            <el-descriptions-item label="成立年份">{{businessInfo.yearStarted}}</el-descriptions-item>
            <el-descriptions-item label="注册资本">{{businessInfo.registeredCapital}}</el-descriptions-item>
            <el-descriptions-item label="官网">{{businessInfo.officialWebsite}}</el-descriptions-item>
            <el-descriptions-item label="贸易类型">{{businessInfo.tradeStyle}}</el-descriptions-item>
            <el-descriptions-item label="联系人">{{businessInfo.contact}}</el-descriptions-item>
            <el-descriptions-item label="职位">{{businessInfo.contactTitle}}</el-descriptions-item>
            <el-descriptions-item label="电话">{{businessInfo.telephone}}</el-descriptions-item>
            <el-descriptions-item label="邮箱">{{businessInfo.email}}</el-descriptions-item>
            <el-descriptions-item label="产品描述">{{businessInfo.product}}</el-descriptions-item>
            <el-descriptions-item label="国家代码">{{businessInfo.countryCode}}</el-descriptions-item>
            <el-descriptions-item label="经度">{{businessInfo.longitude}}</el-descriptions-item>
            <el-descriptions-item label="纬度">{{businessInfo.latitude}}</el-descriptions-item>
            <el-descriptions-item label="Facsimile">{{businessInfo.facsimile}}</el-descriptions-item>
            <el-descriptions-item label="EmployeesHere">{{businessInfo.employeesHere}}</el-descriptions-item>
            <el-descriptions-item label="SubsidiaryIndicator">{{businessInfo.subsidiaryIndicator}}</el-descriptions-item>
            <el-descriptions-item label="业务线">{{businessInfo.businessLine}}</el-descriptions-item>
            <el-descriptions-item label="IEAgentCode">{{businessInfo.ieAgentCode}}</el-descriptions-item>
            <el-descriptions-item label="PreviousDunsNumber">{{businessInfo.previousDunsNumber}}</el-descriptions-item>
            <el-descriptions-item label="LegalStatus">{{businessInfo.legalStatus}}</el-descriptions-item>
            <el-descriptions-item label="LocationStatus">{{businessInfo.locationStatus}}</el-descriptions-item>
            <!-- <el-descriptions-item label="BillsOfLading">{{businessInfo.billsOfLading}}</el-descriptions-item> -->
            <!-- <el-descriptions-item label="MetricTons">{{businessInfo.metricTons}}</el-descriptions-item> -->
            <!-- <el-descriptions-item label="TotalCalculatedValueUS">{{businessInfo.totalCalculatedValueUS}}</el-descriptions-item> -->
            <el-descriptions-item label="SalesVolumeUS">{{businessInfo.salesVolumeUS}}</el-descriptions-item>
        </el-descriptions>
    </div>
</template>

<script>
export default {
    data() {
        return {
            businessInfo: {},
            loading: false,
        };
    },
    methods: {
        getInfo(){
            let companyName = encodeURIComponent(this.$route.query.companyName);
            let country = this.$route.query.country;
            this.loading = true;
            this.$axios.get(`${this.$clueUrl}/BuyerLeads/GetBusinessInformation?country=${country}&company=${companyName}`).then((res) => {
                const { isSuccess, data } = res;
                this.loading = false;
                if (isSuccess) {
                    this.businessInfo = data;
                }
            });
        },
    },
    mounted() {
        this.getInfo();
    },
};
</script>

<style lang="scss" scoped>

</style>