<template>
<div>
    <div v-if="ie=='i'">
        <!-- 摩尔多瓦(MDA)进口提单明细 -->
        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">商品信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th>承运人信息</th>
                        <td colspan="3">{{billInfo.ContractorNameAddress}}</td>
                    </tr>
                    <tr>
                        <th width="25%">来源国</th>
                        <td width="25%">{{billInfo.Country}}</td>
                        <th width="25%">目的国</th>
                        <td width="25%">{{billInfo.DestinationCountry}}</td>
                    </tr>
                    <tr>
                        <th>摩尔多瓦金额</th>
                        <td>{{billInfo.Value}} {{billInfo.ValueUnit}}</td>
                        <th>摩尔多瓦均价</th>
                        <td>{{billInfo.AveragePrice}} {{billInfo.AveragePriceUnit}}</td>
                    </tr>
                    <tr>
                        <th>数量单位</th>
                        <td>{{billInfo.QuantityUnit}}</td>
                        <th>货币汇率</th>
                        <td>{{billInfo.CurrencyRate}}</td>
                    </tr>
                    <tr>
                        <th>美元总价</th>
                        <td>{{billInfo.ValueInUSD}} {{billInfo.ValueInUSDUnit}}</td>
                        <th>美元均价</th>
                        <td>{{billInfo.AveragePriceInUSD}} {{billInfo.AveragePriceUSDUnit}}</td>
                    </tr>
                    <tr>
                        <th>进出口类型代码</th>
                        <td colspan="3">{{billInfo.DeclarationMode}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
    <div v-if="ie=='e'">
        <!-- 摩尔多瓦(MDA)出口提单明细 -->
        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">商品信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th>承运人信息</th>
                        <td colspan="3">{{billInfo.ContractorNameAddress}}</td>
                    </tr>
                    <tr>
                        <th width="25%">来源国</th>
                        <td width="25%">{{billInfo.OriginCountry}}</td>
                        <th width="25%">目的国</th>
                        <td width="25%">{{billInfo.Country}}</td>
                    </tr>
                    <tr>
                        <th>摩尔多瓦金额</th>
                        <td>{{billInfo.Value}} {{billInfo.ValueUnit}}</td>
                        <th>摩尔多瓦均价</th>
                        <td>{{billInfo.AveragePrice}} {{billInfo.AveragePriceUnit}}</td>
                    </tr>
                    <tr>
                        <th>数量单位</th>
                        <td>{{billInfo.QuantityUnit}}</td>
                        <th>货币汇率</th>
                        <td>{{billInfo.CurrencyRate}}</td>
                    </tr>
                    <tr>
                        <th>美元总价</th>
                        <td>{{billInfo.ValueInUSD}} {{billInfo.ValueInUSDUnit}}</td>
                        <th>美元均价</th>
                        <td>{{billInfo.AveragePriceInUSD}} {{billInfo.AveragePriceUSDUnit}}</td>
                    </tr>
                    <tr>
                        <th>进出口类型代码</th>
                        <td colspan="3">{{billInfo.DeclarationMode}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
</div>
</template>

<script>
export default {
    props:["billInfo","ie"],
    data() {
        return {

        };
    },
    methods: {
        
    },
    mounted() {

    },
};
</script>

<style lang="scss" scoped>
.partItem {
    margin-bottom: 20px;
    /deep/.el-card__header {
        padding: 10px 20px;
        .headTitle {
            display: inline-block;
            padding-left: 10px;
            font-size: 14px;
            border-left: 2px solid var(--themeColor);
            font-weight: bold;
        }
    }
}
table {
    width: 100%;
    border-collapse: collapse;
    tr {
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;
        th,
        td {
            font-size: 12px;
            color: #666;
            height: 40px;
            border: 1px solid #eaeaea;
            text-align: center;
        }
        th {
            background-color: #f5f7fa;
            font-weight: 700;
        }
        .bg {
            background-color: var(--themeColor);
            color: #fff;
            font-weight: 700;
            opacity: 0.8;
        }
    }
}
</style>