<template>
    <div>
        <div class="title">返回示例</div>
        <pre class="content">{{ JSON.stringify(returnObj, null, 4)  }}</pre>
        <!-- <el-table class="table" :data="returnTableData" :header-cell-style="{'background-color':'#F2F2F2','color':'#3A3A3A'}">
            <el-table-column prop="paramName" label="参数名"></el-table-column>
            <el-table-column prop="valueType" label="值类型"></el-table-column>
            <el-table-column prop="explain" label="说明"></el-table-column>
        </el-table> -->
    </div>
</template>

<script>
export default {
    props: ["returnObj"],
    data() {
        return {};
    },
    methods: {},
    mounted() {},
};
</script>

<style lang="scss" scoped>
.title {
    font-weight: bold;
    font-size: 20px;
    line-height: 40px;
    margin-top: 20px;
}
.content{
    background-color: #fafafa;
    padding: 10px;
    color: #4a4a4a;
}
.table{
    border: 1px solid #d7d7d7;
}
</style>