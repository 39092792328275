<template>
    <div class="searchBox">
        <div class="title">
            <span>网站信息</span>
        </div>
        <el-form :model="websiteForm" labelPosition='top' size="small">
            <el-row :gutter="30">
                <el-col :span="6">
                    <el-form-item label="网站">
                        <el-input v-model="websiteForm.website" @change="fieldChange" placeholder="网站" style="width:100%;"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="网站IP地址">
                        <el-input v-model="websiteForm.websiteIpAddress" @change="fieldChange" placeholder="网站IP地址" style="width:100%;"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="筛选网站">
                        <el-checkbox v-model="websiteForm.hasWebsite" @change="fieldChange">筛选有网站记录</el-checkbox>
                        <el-checkbox v-show="websiteForm.hasWebsite" v-model="websiteForm.hasWebsiteDeduplicated" @change="fieldChange">过滤重复网址记录</el-checkbox>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
export default {
    data() {
        return {
            websiteForm: {
                website: "",
                websiteIpAddress: "",
                hasWebsite: false,
                hasWebsiteDeduplicated: false,
            },
        };
    },
    methods: {
        fieldChange(){
            if(this.websiteForm.hasWebsite == false){
                this.websiteForm.hasWebsiteDeduplicated = false;
            }
            this.$emit('inputComplete',this.websiteForm);
        },
    },
    mounted() {

    },
};
</script>

<style lang="scss" scoped>
.searchBox{
    border-top: 1px solid #E6E6E6;
    padding-left: 40px;
    .title{
        color: #3A3A3A;
        font-weight: bold;
        font-size: 16px;
        margin: 10px 0;
    }
}
</style>