<script>
import Vue from "vue";
import { defineComponent, reactive } from "@vue/composition-api";
import CurrencyDialog from "./components/CurrencyDialog.vue";
import axios from "@/assets/js/axios";
import { Message } from "element-ui";
export default defineComponent({
    components: {
        CurrencyDialog,
    },
    setup() {
        const dialog = reactive({
            type: "",
            ckey: "",
            exsisted: false,
        });
        const currency = reactive({
            data: [],
        });
        const getCurrencyData = async () => {
            try {
                const res = await axios({
                    method: "GET",
                    url: Vue.prototype.$customerUrl + "/v1/Config/GetCurrencyConfig",
                });
                currency.data = res.data.map((item) => ({
                    ...item,
                    loading: false,
                    showDefault: false,
                }));
            } catch {
                currency.data = [];
            }
        };
        getCurrencyData();
        const onCurrencyDialogSuccess = () => {
            getCurrencyData();
            dialog.exsisted = false;
        };
        const handleDeleteClick = async (key, index) => {
            currency.data[index].loading = true;
            try {
                const res = await axios({
                    method: "GET",
                    url: Vue.prototype.$customerUrl + "/v1/Config/CurrencyConfigReason",
                    params: {
                        key,
                    },
                });
                if (!res.isSuccess) throw new Error();
                Message.success("删除成功");
                getCurrencyData();
            } catch {
                null;
            }
        };
        return {
            dialog,
            currency,
            onCurrencyDialogSuccess,
            handleDeleteClick,
            isAdmin: JSON.parse(localStorage.userInfo).isAdmin,
        };
    },
});
</script>

<template>
    <div class="currency">
        <CurrencyDialog v-if="dialog.exsisted" :type="dialog.type" :ckey="dialog.ckey" @close="dialog.exsisted = false" @success="onCurrencyDialogSuccess" />
       
        <el-button v-if="isAdmin" @click="dialog.type = '新建'; dialog.ckey = ''; dialog.exsisted = true" size="small" type="primary" icon="el-icon-plus">新建</el-button>
        <span v-else class="setTipText">非管理员无法设置操作,请联系管理员操作</span>
        <div style="padding:5px 0;"></div>
      
        <el-table :data="currency.data" height="calc(100% - 60px)" :header-cell-style="{ backgroundColor: '#F3F3F3', color: '#3A3A3A' }" :row-style="({ rowIndex }) => rowIndex % 2 == 1 ? { backgroundColor: '#F9FAFC' } : {}" @cell-mouse-enter="(row) => { row.showDefault = false }" @cell-mouse-leave="(row) => { row.showDefault = false }">
            <el-table-column label="币种名称">
                <template slot-scope="{row}">
                    <div style="display: flex; align-items: center">
                        <span v-if="row.isDefault" style="margin-right: 3px; display: inline-block; box-sizing: border-box; width: 40px; height: 20px; display: flex; justify-content: center; align-items: center; color: #F96B0D; border: 1px solid #F96B0D; background: #fff; border-radius: 4px">默认</span>
                        <span>{{ row.currency }}</span>
                        <div v-show="row.showDefault" style="display: flex; align-items: center; margin-left: 5px; cursor: pointer">
                            <i class="el-icon-success" style="color:#F96B0D;"></i>
                            <span style="color:#999;">设为默认</span>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="币种描述">
                <template slot-scope="{row}">
                    <span>{{ row.currencyCn }}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="200px" v-if="isAdmin">
                <template slot-scope="{row,$index}">
                    <div v-if="row.currency=='CNY' || row.currency=='USD'"></div>
                    <div v-else style="display: flex" v-loading="row.loading">
                        <span @click="dialog.ckey = row.key; dialog.type = '编辑'; dialog.exsisted = true" role="button" class="edit">编辑</span>
                        <span @click="handleDeleteClick(row.key, $index)" role="button" class="delete">删除</span>
                    </div>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<style scoped lang="scss">
.currency {
    box-sizing: border-box;
    height: 100%;
}

span[role="button"] {
    cursor: pointer;
    display: inline-block;
    box-sizing: border-box;
    width: 40px;
    height: 20px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    border-style: solid;
    border-width: 1px;
    &.edit {
        background: rgba(124, 144, 165, 0.1);
        color: #7c90a5;
        border-color: #7c90a5;
    }
    &.delete {
        background: rgba(255, 86, 86, 0.1);
        color: #ff5656;
        border-color: #ff5656;
    }
    &:not(:last-of-type) {
        margin-right: 10px;
    }
}
</style>
