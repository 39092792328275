<script>
import Vue from 'vue'
import { ref, defineComponent, reactive } from '@vue/composition-api'
import axios from '@/assets/js/axios'
import { Message } from 'element-ui'
//import AddCollectionVue from './components/AddCollection.vue'
//import AddSpendingVue from './components/AddSpending.vue'
export default defineComponent({
  /* components: {
    AddCollectionVue,
    AddSpendingVue
  }, */
  props: ['businessKey'],
  setup(props) {
    const isExpand = ref(true)
    const list = ref([])
    const num = ref([0, 0])
    const dialog = reactive({
      showOption: false,
      exsistIn: false,
      exsistOut: false
    })
    const toChild = reactive({
      key: '',
      customerName: '',
      customerKey: ''
    });
    (async () => {
      try {
        const res = await axios({
          method: 'GET',
          url: Vue.prototype.$sjUrl + '/BusinessChance/GetBusinessDetail',
          params: {
            businessKey: props.businessKey
          }
        })
        if (!res.isSuccess) throw new Error()
        const data = res.data
        toChild.customerName = data.companyName
        toChild.customerKey = data.companyKey
      } catch { null }
    })();
    const getList = async () => {
      list.value = []
      num.value = [0, 0]
      const res = await axios({
        method: 'GET',
        url: Vue.prototype.$sjUrl + '/BusinessChance/GetCollection',
        params: {
          businessKey: props.businessKey
        }
      })
      if (!res.isSuccess) throw new Error()
      const data = res.data
      list.value = data.map(item => {
        num.value[item.type - 1] += 1
        return { key: item.key, name: item.paymentName, amount: item.payerPayAmount, type: ['', 'in', 'out'][item.type], isedting: false }
      })
    }
    getList()
    const handleDeleteClick = async (key) => {
      try {
        const res = await axios({
          method: 'GET',
          url: Vue.prototype.$orderUrl + '/api/Collection/DeleteCollection',
          params: {
            collectionKey: key
          }
        })
        if (!res.isSuccess) throw new Error()
        getList()
      } catch {
        null
      }
    }
    const handleEditClick = (type, item) => {
      for (let i = 0; i < list.value.length; i++) {
        list.value[i].isedting = false
      }
      showEditor.value = false
      editForm.update = true
      editForm.key = item.key
      editForm.type = type === 'in' ? 1 : 2
      editForm.name = item.name
      editForm.count = item.amount
      item.isedting = true
    }
    const showEditor = ref(false)
    const initialForm = {
      update: false,
      key: '',
      type: 1,
      name: '',
      count: 0
    }
    const editForm = reactive({ ...initialForm })
    const editLoading = ref(false)
    const resetEditForm = () => {
      editForm.update = initialForm.update
      editForm.key = initialForm.key
      editForm.count = initialForm.count
      editForm.name = initialForm.name
      editForm.type = initialForm.type
    }
    const handleEditConfirm = async () => {
      try {
        if (!editForm.name) {
          Message.warning('款项名称不能为空')
          return
        }
        if (!editForm.count) {
          Message.warning('金额不能为0')
          return
        }
        editLoading.value = true
        const localData = JSON.parse(window.localStorage.getItem('userInfo'))
        const form = {
          "key": editForm.key,
          "type": editForm.type,
          "payer": editForm.type === 1 ? toChild.customerName : localData.companyCnFullName,
          "payerKey": editForm.type === 1 ? toChild.customerKey : localData.companyKey,
          "payerPayType": "",
          "payerContacts": "",
          "payerBank": "",
          "payerPayAccount": "",
          "payerDate": "",
          "payerPayAmount": editForm.count,
          "payee": editForm.type === 1 ? localData.companyCnFullName : toChild.customerName,
          "payeeKey": editForm.type === 1 ? localData.companyKey : toChild.customerKey,
          "payeePaymentMethod": "",
          "payeeFollowUp": "",
          "payeeBank": "",
          "payeeCollectionAccount": "",
          "payeeCollectionDate": "",
          "payeeCollectionAmount": editForm.count,
          "weCompany": "",
          "collectionCurrency": "CNY",
          "exchangeRate": 1,
          "usExchangeRate": 0,
          "collectionNetAmount": 0,
          "usCollectionNetAmount": 0,
          "collectionAmount": 0,
          "amountReceived": 0,
          "remainingAmount": 0,
          "handler": localData.userCnName,
          "handlerKey": "",
          "remarks": "",
          "relatedContract": "",
          "paymentName": editForm.name,
          "orderKey": '',
          "businessChanceKey": props.businessKey
        }
        const res = await axios({
          method: 'POST',
          url: editForm.update ? Vue.prototype.$orderUrl + '/api/Collection/UpdateCollection' : Vue.prototype.$sjUrl + '/BusinessChance/AddCollection',
          data: form
        })
        if (res.isSuccess) {
          Message.success('提交成功')
          showEditor.value = false
          resetEditForm()
          getList()
        }
      } catch (err) {
        console.log(err)
      } finally {
        editLoading.value = false
      }
    }
    const onAdd = () => {
      for (let i = 0; i < list.value.length; i++) {
        list.value[i].isedting = false
      }
      resetEditForm()
      showEditor.value = true
    }
    return {
      isExpand,
      num,
      list,
      dialog,
      toChild,
      getList,
      handleDeleteClick,
      handleEditClick,
      showEditor,
      editForm,
      editLoading,
      resetEditForm,
      handleEditConfirm,
      onAdd
    }
  }
})
</script>

<template>
  <div>
    <!-- <AddCollectionVue v-if="dialog.exsistIn" :ckey="toChild.key" @done="getList(); dialog.exsistIn = false" :businessKey="$props.businessKey" :customerName="toChild.customerName" :customerKey="toChild.customerKey" @close="dialog.exsistIn = false" />
    <AddSpendingVue v-if="dialog.exsistOut" :ckey="toChild.key" @done="getList(); dialog.exsistOut = false" :businessKey="$props.businessKey" :customerName="toChild.customerName" :customerKey="toChild.customerKey" @close="dialog.exsistOut = false" /> -->
    <div @click="isExpand = !isExpand" class="top">
      <span>收入({{ num[0] }})/支出({{ num[1] }})</span>
      <div style="display: flex; align-items: center">
        <!-- <el-popover placement="bottom" v-model="dialog.showOption">
          <div style="text-align: right; margin: 0">
            <el-button size="mini" type="text" @click="toChild.key = ''; dialog.showOption = false; dialog.exsistIn = true">收入</el-button>
            <el-button size="mini" type="text" @click="toChild.key = ''; dialog.showOption = false; dialog.exsistOut = true">支出</el-button>
          </div>
          <img @click.stop="() => { }" slot="reference" src="@/assets/img/add-icon.png" style="margin-right: 20px" />
        </el-popover> -->
        <img @click.stop="onAdd" src="@/assets/img/add-icon.png" style="margin-right: 20px" />
        <i :class="isExpand ? 'el-icon-arrow-down' : 'el-icon-arrow-up'" style="font-size: 20px"></i>
      </div>
    </div>
    <div class="body" :class="isExpand ? 'expand' : ''">
      <el-scrollbar style="height: 100%">
        <div v-loading="editLoading" class="item no-border" v-show="showEditor">
          <el-select size="small" style="width: 70px; flex-shrink: 0" v-model="editForm.type">
            <el-option label="收入" :value="1" />
            <el-option label="支出" :value="2" />
          </el-select>
          <el-input size="small" v-model.trim="editForm.name" placeholder="款项名称" />
          <el-input-number style="width: 90%" size="small" placeholder="金额" v-model="editForm.count" :min="0" :controls="false" />
          <div style="display: flex; align-items: center; padding-right: 5px">
            <el-tag @click="handleEditConfirm" size="small" type="success" style="cursor: pointer">确认</el-tag>
            <el-tag @click="showEditor = false; resetEditForm()" size="small" type="danger" style="cursor: pointer">取消</el-tag>
          </div>
        </div>
        <div v-for="item of list" :key="item.key">
          <div v-show="!item.isedting" class="item">
            <div style="width: 50px; display: flex; justify-content: center">
              <i :class="item.type === 'in' ? 'el-icon-plus' : 'el-icon-minus'" :style="{ color: item.type === 'in' ? '#FF2D2D' : '#20BC5F' }"></i>
            </div>
            <p style="color: #2A3F58; box-sizing: border-box; padding-right: 10px; width: calc(50% - 65px); overflow: hidden; text-overflow: ellipsis; white-space: nowrap">{{ item.name }}</p>
            <p style="color: #2A3F58; box-sizing: border-box; padding-right: 10px; width: calc(50% - 65px); overflow: hidden; text-overflow: ellipsis; white-space: nowrap">CNY{{ item.amount }}</p>
            <div style="width: 80px">
              <i @click="handleEditClick(item.type, item)" class="el-icon-edit" style="color: #909399; cursor: pointer; margin-right: 12px"></i>
              <i @click="handleDeleteClick(item.key)" class="el-icon-delete" style="color: #F56C6C; cursor: pointer"></i>
            </div>
          </div>
          <div v-if="item.isedting" v-loading="editLoading" class="item no-border">
            <el-select disabled size="small" style="width: 70px; flex-shrink: 0" v-model="editForm.type">
              <el-option label="收入" :value="1" />
              <el-option label="支出" :value="2" />
            </el-select>
            <el-input size="small" v-model.trim="editForm.name" placeholder="款项名称" />
            <el-input-number style="width: 90%" size="small" placeholder="金额" v-model="editForm.count" :min="0" :controls="false" />
            <div style="display: flex; align-items: center; padding-right: 5px">
              <el-tag @click="handleEditConfirm" size="small" type="success" style="cursor: pointer">确认</el-tag>
              <el-tag @click="item.isedting = false; resetEditForm()" size="small" type="danger" style="cursor: pointer">取消</el-tag>
            </div>
          </div>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<style scoped lang="scss">
.top {
  cursor: pointer;
  box-sizing: border-box;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  color: #000000;
  background: #e5e9f2;
  border: 1px solid #d5d5d5;
  padding: 0 17px;
}

.body {
  box-sizing: border-box;
  border: 1px solid #d5d5d5;
  border-top: none;
  height: 0;
  overflow: hidden;
  transition: all 0.3s;

  &.expand {
    height: 120px;
  }
}

::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
}

.item {
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d5d5d5;
  font-size: 14px;
}

.no-border {
  ::v-deep .el-input__inner {
    border: none !important;
  }
}
</style>
