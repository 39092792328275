<template>
    <div>
        <InterfaceName :interfaceName="interfaceName" />
        <InterfaceSpe :requestUrl="requestUrl" :requestMethod="requestMethod" />
        <InputParam :inputTableData="inputTableData" />
        <ReturnParam :returnObj="returnObj" />   
    </div>
</template>

<script>
import InterfaceName from "../Acomponents/InterfaceName.vue";
import InterfaceSpe from "../Acomponents/InterfaceSpe.vue";
import InputParam from "../Acomponents/InputParam.vue";
import ReturnParam from "../Acomponents/ReturnParam.vue";

export default {
    components: { InterfaceName, InterfaceSpe, InputParam, ReturnParam },
    data() {
        return {
            interfaceName: "获取产品列表",
            requestUrl: "https://crm.qixinerp.com/open/Product/List",
            requestMethod: "POST",
            inputTableData: [],
            returnObj: {},
        };
    },
    methods: {
        
    },
    mounted() {
        this.inputTableData = [
            {paramName: "productName", valueType: "string", explain: "产品名称", required: false},
            {paramName: "groupId", valueType: "integer", explain: "分组Id", required: false},
            {paramName: "groupKey", valueType: "string", explain: "分组key", required: false},
            {paramName: "productNo", valueType: "string", explain: "产品编号", required: false},
            {paramName: "createTime", valueType: "string", explain: "创建时间", required: false},
            {paramName: "pageIndex", valueType: "integer", explain: "当前页码", required: true},
            {paramName: "pageSize", valueType: "integer", explain: "每页条数", required: true},
        ]
        this.returnObj = {
            "isSuccess": true,
            "errorMessage": null,
            "data": {
                "datas": [
                    {
                        "groupName": "鞋类",
                        "createUserName": null,
                        "cKey": "52b9b01c3c67494c8114f35e7b912779",
                        "uKey": "af19a3cbd7094bafa19e629b04b482ae",
                        "productNo": "CS001",
                        "images": "http://qixin-crm-disk.oss-cn-shanghai.aliyuncs.com/92606a12-11f2-4305-b19e-a06e503ec76a.png",
                        "productName": "耐克鞋子",
                        "dimension": "0.1m*0.1m*0.1m",
                        "specDesc": "合格",
                        "ingredient": "人工合成橡胶",
                        "supplier": "耐克",
                        "measureUnit": "PCS",
                        "hscode": "11220033",
                        "grade": "A+",
                        "basicModel": "J123456",
                        "productionProcess": "工艺1",
                        "productionStandard": "标准",
                        "declareElement": "要素1",
                        "productIns": "ins",
                        "productCns": "cns",
                        "ecNumber": "100",
                        "isCommodityInspection": true,
                        "isNeedPurchase": true,
                        "isSale": true,
                        "isSelfProduced": true,
                        "brand": "耐克",
                        "insuredBaseQuantity": "200",
                        "preferredContainer": "300",
                        "alias": "鞋子",
                        "isDangerousGoods": false,
                        "qualityGuaranteePeriod": "无",
                        "sellPrice": 599,
                        "rotationChart": "http://qixin-crm-disk.oss-cn-shanghai.aliyuncs.com/92606a12-11f2-4305-b19e-a06e503ec76a.png",
                        "groupID": "6cfa0e29b4cb42098fbdc0ebed5f5d53",
                        "updateUserKey": "af19a3cbd7094bafa19e629b04b482ae",
                        "updateUser": "彭贤鑫",
                        "updateTime": "2023-04-19 17:29:52",
                        "isTop": true,
                        "isAttention": true,
                        "isState": "1",
                        "description": "",
                        "taxIncludingPrice": 800,
                        "specNo": "",
                        "productModel": "",
                        "productType": "",
                        "chineseName": "",
                        "englishName": "",
                        "artNo": "",
                        "barCode": "",
                        "color": "",
                        "sellPriceCurrency": "",
                        "costPriceCurrency": "",
                        "costPrice": 0,
                        "addTax": "",
                        "chineseDesc": "",
                        "packageUnit": "",
                        "eachQuantity": 0,
                        "length": 0,
                        "width": 0,
                        "height": 0,
                        "volume": 0,
                        "singleGrossWeight": 0,
                        "singleNetWeight": 0,
                        "packageDesc": "",
                        "fileName": "",
                        "id": 1103,
                        "key": "6903c47a216b48559c2ab33e247f7969",
                        "isDelete": false,
                        "deleteTime": "1990-01-01 00:00:00",
                        "deleteIp": "",
                        "deleteUserKey": "",
                        "deleteCompanyKey": "",
                        "deleteRoleKey": "",
                        "deleteTeamKey": "",
                        "createTime": "2022-01-12 15:48:00",
                        "createIp": "27.19.155.79, 180.163.72.2",
                        "createUserKey": "CE99F173-7E5F-4B8E-8265-8A4C4EC19A56",
                        "createCompanyKey": "52b9b01c3c67494c8114f35e7b912779",
                        "createRoleKey": "",
                        "createTeamKey": ""
                    },
                ],
                "pageSize": 10,
                "pageIndex": 1,
                "totalItems": 1
            },
        }
    },
};
</script>

<style lang="scss" scoped>

</style>