<template>
  <div id="sitc-encide-rank" class="sitc-encide-rank" v-show="!hidden">
    <div class="title" ref="title">
      <div class="text">
        <div style="font-weight: bold">
          【<span style="color: var(--themeColor, #3152AF)">{{ $store.getters.titleText }}</span>SITC排名】
        </div>
        <span class="sub-title">(该报表不包含船运数据)</span>
      </div>
      <div style="display: flex; align-items: center">
        <el-radio-group v-model="controlForm.option" size="mini" :disabled="loading">
          <el-radio-button :label="0">频次</el-radio-button>
          <el-radio-button :label="1">金额</el-radio-button>
          <el-radio-button :label="2">数量</el-radio-button>
          <el-radio-button :label="3">重量</el-radio-button>
        </el-radio-group>
        <img v-show="false" class="icon" src="@/firm/images/company/icon-download.png" style="margin-left: 1em" />
      </div>
    </div>
    <div class="content">
      <el-skeleton v-show="loading" :rows="20" animated />
      <div v-show="!loading" class="tab-encode-encode-analyse-unit">
        <div class="main">
          <div>
            <div class="bar-item" v-for="item of barItems" :key="item.encode">
              <div class="encode">{{ item.encode }}</div>
              <el-progress style="width: 390px; flex-shrink: 0" :color="$store.getters.barColor" :stroke-width="16" :percentage="item.percentage" :show-text="false" />
              <div class="indicator">{{ item.indicator }}</div>
              <div class="description">({{ item.name }})</div>
            </div>
          </div>
          <el-table :data="tableData" size="mini" style="font-size: 14px; color: #1f2d3d" :header-cell-style="{ background: '#E5E9F2', color: '#1F2D3D' }">
            <el-table-column prop="order" width="50px" align="center" />
            <el-table-column prop="encode" label="SITC" align="center" width="80px" />
            <el-table-column prop="name" label="商品名称" show-overflow-tooltip />
            <el-table-column prop="frequencyStr" label="频次" width="120px" show-overflow-tooltip />
            <el-table-column prop="amountStr" label="金额" width="120px" show-overflow-tooltip />
            <el-table-column prop="countStr" label="数量" width="120px" show-overflow-tooltip />
            <el-table-column prop="weightStr" label="重量" width="120px" show-overflow-tooltip />
          </el-table>
          <div style="margin-top: 1em; display: flex; justify-content: flex-end">
            <el-pagination background layout="total, prev, pager, next" :total="pagination.total" :page-size="pagination.pageSize" :current-page="pagination.pageIndex" @current-change="handlePageIndexChange" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters, mapMutations } from "vuex";
import LazyOperation from "@/firm/plugins/lazy-execute";
import { AnchorInditor } from "../../utils";
import axios from "@/firm/plugins/axios";

let lazyOperation = null;

export default Vue.extend({
  data() {
    return {
      loading: true,
      hidden: false,
      controlForm: {
        option: 0,
      },
      barItems: [],
      tableData: [],
      pagination: {
        total: 0,
        pageSize: 10,
        pageIndex: 1,
      },
    };
  },
  computed: {
    ...mapState(["company", "companyType"]),
    ...mapState("controlFormGlobal", {
      ie: "ie",
      dataCountry: "dataCountry",
      dateRange: "dateRange",
      hsCode: "hsCode",
      importer: "importer",
      exporter: "exporter",
      country: "country",

      exHsCode: "exHsCode",
      exExporter: "exExporter",
      exImporter: "exImporter",
      exCountry: "exCountry",
    }),
    ...mapGetters("controlFormGlobal", {
      startTime: "startTime",
      endTime: "endTime",
    }),
  },
  watch: {
    "controlForm.option"() {
      this.hidden = false;
      lazyOperation?.reobserve();
    },
    ie() {
      this.hidden = false;
      lazyOperation?.reobserve();
    },
    dataCountry() {
      this.hidden = false;
      lazyOperation?.reobserve();
    },
    dateRange() {
      this.hidden = false;
      lazyOperation?.reobserve();
    },
    hsCode() {
      this.hidden = false;
      lazyOperation?.reobserve();
    },
    importer() {
      this.hidden = false;
      lazyOperation?.reobserve();
    },
    exporter() {
      this.hidden = false;
      lazyOperation?.reobserve();
    },
    country() {
      this.hidden = false;
      lazyOperation?.reobserve();
    },

    exHsCode() {
      this.hidden = false;
      lazyOperation?.reobserve();
    },
    exExporter() {
      this.hidden = false;
      lazyOperation?.reobserve();
    },
    exImporter() {
      this.hidden = false;
      lazyOperation?.reobserve();
    },
    exCountry() {
      this.hidden = false;
      lazyOperation?.reobserve();
    },

    companyType() {
      this.hidden = false;
      lazyOperation?.reobserve();
    },
  },
  methods: {
    ...mapMutations("anchor", {
      setCurrent: "setCurrent",
      setAnchorFlag: "setAnchorFlag",
    }),
    async handlePageIndexChange(pageIndex) {
      try {
        this.loading = true;
        await this.getAllData(pageIndex);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async getAllData(pageIndex = 1) {
      try {
        const res = await axios({
          method: "post",
          url: "/search/Credit/Ranking/Sitc",
          data: {
            pageIndex,
            pageSize: this.pagination.pageSize,
            ie: this.ie,
            importer: this.importer,
            exporter: this.exporter,
            dataCountry: this.dataCountry,
            country: this.country,
            startTime: this.startTime,
            endTime: this.endTime,
            hsCode: this.hsCode,
            sortType: this.controlForm.option,
            noHsCode: this.exHsCode,
            noExporter: this.exExporter,
            noImporter: this.exImporter,
            noCountry: this.exCountry,
            company: this.company,
            companyType: this.companyType,
          },
        });
        const data = res.data.result.data;
        this.pagination.total = res.data.result.total;
        this.pagination.pageIndex = pageIndex;
        if (data.length === 0) {
          throw new Error();
        }
        this.barItems = data.map((item) => ({
          encode: item.name,
          percentage: item[["frequencyRatio", "amountRadio", "countRadio", "weightRadio"][this.controlForm.option]],
          indicator: item[["frequency", "amount", "count", "weight"][this.controlForm.option]],
          name: item.sitcDescription,
        }));
        this.tableData = data.map((item) => ({
          order: item.order,
          encode: item.name,
          name: item.sitcDescription,
          frequencyStr: item.frequency,
          amountStr: item.amount,
          countStr: item.count,
          weightStr: item.weight,
        }));
        return Promise.resolve();
      } catch {
        return Promise.reject();
      }
    },
  },
  mounted() {
    lazyOperation = new LazyOperation(() => {
      this.loading = true;
      this.getAllData()
        .catch(() => {
          this.hidden = true;
          this.setAnchorFlag([["SITCRANK", false]]);
        })
        .finally(() => {
          this.loading = false;
        });
    }, this.$el);
    new AnchorInditor(() => {
      this.setCurrent("SITCRANK");
    }, this.$refs["title"]);
  },
});
</script>

<style lang="scss" scoped>
@import "@/firm/scss/mixin.scss";

.sitc-encide-rank {
  margin-top: 1em;
  background: #fff;
  padding: 1rem;
  padding-top: 0;
  @include mixin-panel-title;

  .content {
    .tab-encode-encode-analyse-unit {
      color: #2a3f58;

      .title {
        padding-left: 0.5em;
        margin-bottom: 0.8em;
      }

      .main {
        padding-bottom: 1em;
      }

      .bar-item {
        display: flex;
        align-items: center;
        margin-bottom: 0.8em;
        $padding-horzon: 12px;

        .encode {
          flex-shrink: 0;
          width: 6em;
          text-align: right;
          padding-right: $padding-horzon;
        }

        .indicator {
          flex-shrink: 0;
          word-break: break-all;
          width: 7em;
          padding-right: 2 * $padding-horzon;
          padding-left: $padding-horzon;
        }

        .description {
          display: flex;
          align-items: center;
          color: #8492a6;
        }
      }
    }
  }
}
</style>
