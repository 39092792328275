<template>
    <div class="pageBox">
        <el-row :gutter="24">
            <el-col :span="12">
                <div class="cardBox">
                    <div class="title">最近打开</div>
                    <div class="content" v-loading="loadingOne">
                        <div class="fileItem" v-for="item in openRecordList" :key="item.key">
                            <div class="name ellipsis" @click="onView(item)">
                                <img v-if="item.type==0" :src="item.fileCategory" alt="" class="fileIcon" />
                                <img v-else src="@/assets/doc/folderIcon.png" alt="" />
                                <span>{{item.name}}</span>
                            </div>
                            <div class="time">{{item.openTime}}</div>
                            <div class="size">{{item.fileSize}}</div>
                        </div>
                        <el-empty v-if="openRecordList.length==0" description="暂无数据" :image-size="100"></el-empty>
                    </div>
                </div>
            </el-col>
            <el-col :span="12">
                <div class="cardBox">
                    <div class="title">待下载阅读文件</div>
                    <div class="content" v-loading="loadingTwo">
                        <div class="fileItem" v-for="item in todownloadList" :key="item.key">
                            <div class="name ellipsis">
                                <img v-if="item.type==0" :src="item.fileCategory" alt="" class="fileIcon" />
                                <img v-else src="@/assets/doc/folderIcon.png" alt="" />
                                <span>{{item.name}}</span>
                            </div>
                            <div class="time">{{item.time}}</div>
                            <div class="size">{{item.size}}</div>
                        </div>
                        <el-empty v-if="todownloadList.length==0" description="暂无数据" :image-size="100"></el-empty>
                    </div>
                </div>
            </el-col>
            <el-col :span="12" style="margin-top:20px;">
                <div class="cardBox">
                    <div class="title">
                        <el-page-header v-if="isViewFileTag" @back="isViewFileTag = false;" content="文件标签"></el-page-header>
                        <span v-else>文件标签</span>
                    </div>
                    <div class="content" v-loading="loadingThree">
                        <div v-if="isViewFileTag">
                            <div class="fileItem" v-for="item in viewFileList" :key="item.key">
                                <div class="name ellipsis" @click="onView(item)">
                                    <img v-if="item.type==0" :src="item.fileCategory" alt="" class="fileIcon" />
                                    <img v-else src="@/assets/doc/folderIcon.png" alt="" />
                                    <span>{{item.name}}</span>
                                </div>
                                <div class="time">{{item.createTime}}</div>
                                <div class="size">{{item.fileSize}}</div>
                            </div>
                        </div>
                        <div v-else>
                            <div class="fileItem" v-for="item in fileTagList" :key="item.key">
                                <div class="tagName" @click="getLableDetails(item)">
                                    <el-tag :color="item.color" effect="dark" size="small">{{ item.text }}</el-tag>
                                </div>
                                <div class="markCount">已使用<span style="padding: 0 5px;color: var(--themeColor);">{{ item.markCount }}</span>次</div>
                            </div>
                        </div>
                        <el-empty v-if="fileTagList.length==0" description="暂无数据" :image-size="100"></el-empty>
                    </div>
                </div>
            </el-col>
            <el-col :span="12" style="margin-top:20px;">
                <div class="cardBox">
                    <div class="title">我的收藏</div>
                    <div class="content" v-loading="loadingFour">
                        <div class="fileItem" v-for="item in collectList" :key="item.key">
                            <div class="name ellipsis" @click="onView(item)">
                                <img v-if="item.type==0" :src="item.fileCategory" alt="" class="fileIcon" />
                                <img v-else src="@/assets/doc/folderIcon.png" alt="" />
                                <span>{{item.name}}</span>
                            </div>
                            <div class="time">{{item.collectionTime}}</div>
                            <div class="size">{{item.fileSize}}</div>
                        </div>
                        <el-empty v-if="collectList.length==0" description="暂无数据" :image-size="100"></el-empty>
                    </div>
                </div>
            </el-col>
            <el-col :span="24" style="margin-top:20px;">
                <div class="dynamicBox">
                    <div class="title">动态</div>
                    <div class="content">
                        <el-table v-loading="tableLoading" :data="tableData" :header-cell-style="{'background-color':'#F2F3F4','color':'#003333'}">
                            <el-table-column prop="createUserName" label="操作者"></el-table-column>
                            <el-table-column prop="createTime" label="时间"></el-table-column>
                            <el-table-column prop="scene" label="场景"></el-table-column>
                            <el-table-column prop="actionType" label="动作类型"></el-table-column>
                            <el-table-column prop="action" label="动作"></el-table-column>
                            <el-table-column prop="fileName" label="详细信息"></el-table-column>
                        </el-table>
                        <el-pagination v-if="totalItems > 10" background layout="total,prev,pager,next" :total="totalItems" style="float:right;margin:15px 20px;" :current-page.sync="pageIndex" @current-change="pageChange"></el-pagination>
                    </div>
                </div>
            </el-col>
        </el-row>

        <!-- 文件详情 -->
        <FileInfo :infoVisible="infoVisible" :infoKey="infoKey" @closedialog="infoVisible = false;" />

    </div>
</template>

<script>
import FileInfo from "./components/FileInfo.vue"
export default {
    components: { FileInfo },
    data() {
        return {
            loadingOne: false,
            loadingTwo: false,
            loadingThree: false,
            loadingFour: false,
            openRecordList: [],
            todownloadList: [],
            fileTagList: [],
            collectList: [],
            //动态
            tableLoading: false,
            tableData: [],
            totalItems: 0,
            pageIndex: 1,

            //查看文件详情
            infoVisible: false,
            infoKey: "",
            
            //查看标签详情
            isViewFileTag: false,
            viewFileList: [],
        };
    },
    methods: {
        onView(item){
            //查看文件详情
            if(item.type == 0){
                this.infoVisible = true;
                this.infoKey = item.key;
            }
            //查看文件夹
            if(item.type == 1 && item.url){
                this.$emit('onJump',JSON.parse(item.url));
            }
        },

        //获取最近打开记录
        getRecentlyOpened(){
            this.loadingOne = true;
            this.$axios.get(`${this.$diskUrl}/Home/GetRecentlyOpened`).then((res) => {
                const { isSuccess, data } = res;
                this.loadingOne = false;
                if (isSuccess) {
                    this.openRecordList = data;
                    this.openRecordList.forEach(item =>{
                        if(item.fileCategory){
                            item.fileCategory = require(`@/assets/doc/file_${item.fileCategory}.png`)
                        }
                    })
                }
            });
        },

        //获取待下载阅读文件
        getToBeDownloadedFiles(){
            this.loadingTwo = true;
            this.$axios.get(`${this.$diskUrl}/Home/GetToBeDownloadedFiles`).then((res) => {
                const { isSuccess, data } = res;
                this.loadingTwo = false;
                if (isSuccess) {
                    this.todownloadList = data;
                    this.todownloadList.forEach(item =>{
                        if(item.fileCategory){
                            item.fileCategory = require(`@/assets/doc/file_${item.fileCategory}.png`)
                        }
                    })
                }
            });
        },

        //获取文件标签
        getFileLabel(){
            this.loadingThree = true;
            this.$axios.get(`${this.$diskUrl}/Lable/GetLableConfig`).then((res) => {
                const { isSuccess, data } = res;
                this.loadingThree = false;
                if (isSuccess) {
                    this.fileTagList = data;
                }
            })
        },
        getLableDetails(item){
            this.loadingThree = true;
            this.$axios.get(`${this.$diskUrl}/Lable/GetLableDetails?key=${item.key}`).then((res) => {
                const { isSuccess, data } = res;
                this.loadingThree = false;
                if (isSuccess) {
                    this.isViewFileTag = true;
                    this.viewFileList = data;
                    this.viewFileList.forEach(item =>{
                        if(item.fileCategory){
                            item.fileCategory = require(`@/assets/doc/file_${item.fileCategory}.png`)
                        }
                    })
                }
            })
        },

        //获取我的收藏
        getCollectionFiles(){
            this.loadingFour = true;
            this.$axios.get(`${this.$diskUrl}/Home/GetCollectionFiles`).then((res) => {
                const { isSuccess, data } = res;
                this.loadingFour = false;
                if (isSuccess) {
                    this.collectList = data;
                    this.collectList.forEach(item =>{
                        if(item.fileCategory){
                            item.fileCategory = require(`@/assets/doc/file_${item.fileCategory}.png`)
                        }
                    })
                }
            });
        },

        //获取动态
        getDynamics(){
            this.tableLoading = true;
            this.$axios.post(`${this.$diskUrl}/Home/GetDynamics`,{
                pageIndex: this.pageIndex,
                pageSize: 10,
            }).then((res) => {
                const { isSuccess, data } = res;
                this.tableLoading = false;
                if (isSuccess) {
                    this.tableData = data.datas;
                    this.totalItems = data.totalItems;
                }
            });
        },
        pageChange(page){
            this.pageIndex = page;
            this.getDynamics();
        }
    },
    mounted() {
        this.getRecentlyOpened();       //获取最近打开记录
        this.getToBeDownloadedFiles();  //获取待下载阅读文件
        this.getFileLabel();            //获取文件标签
        this.getCollectionFiles();      //获取我的收藏
       
        this.getDynamics();             //获取动态
    },
};
</script>

<style lang="scss" scoped>
.pageBox{
    padding: 20px;
    .cardBox{
        height: 315px;
        background: #FFFFFF;
        box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.16);
        .title{
            height: 42px;
            line-height: 42px;
            font-size: 18px;
            font-weight: bold;
            color: #1B2841;
            padding-left: 20px;
            border-bottom: 1px solid #E6E7EB;
        }
        .content{
            padding: 0 20px 20px 20px;
            height: 252px;
            overflow: auto;
            .fileItem{
                display: flex;
                height: 40px;
                line-height: 40px;
                padding: 10px;
                border-bottom: 1px solid #D7D7D8;
                .name{
                    width: calc(100% - 200px);
                    font-weight: bold;
                    font-size: 16px;
                    color: #000000;
                    cursor: pointer;
                    img{
                        vertical-align: middle;
                        padding-right: 10px;
                    }
                }
                .time{
                    width: 150px;
                    font-size: 14px;
                    color: #C7C7C7;
                }
                .size{
                    width: 50px;
                    font-size: 14px;
                    color: #C7C7C7;
                }
                .tagName{
                    width: calc(100% - 100px);
                    cursor: pointer;
                }
                .markCount{
                    width: 100px;
                    font-size: 14px;
                    color: #C7C7C7;
                }
            }
        }
    }
    .dynamicBox{
        background: #FFFFFF;
        box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.16);
        .title{
            height: 42px;
            line-height: 42px;
            font-size: 18px;
            font-weight: bold;
            color: #1B2841;
            padding-left: 20px;
        }
        .content{
            padding: 0 20px 20px 20px;
            min-height: 250px;
            overflow: auto;
        }
    }
}
.el-page-header{
    line-height: 42px;
}
</style>