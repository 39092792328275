<template>
    <div class="register">
        <img class="bg3" src="@/assets/img/bg3.png" alt="" />
        <img class="logo" src="@/assets/img/logo.png" alt="" />
        <div class="copyright">©Copyright 2022 上海企芯信息科技有限公司保留所有权利 沪ICP备2022000736号</div>

        <div class="middleBox">
            <div class="title">创建用户</div>
            <el-form ref="registerForm" :model="registerForm" :rules="rules" label-width="110px">
                <el-form-item prop="companyName">
                    <div slot="label" class="label">公司名称</div>
                    <el-input v-model="registerForm.companyName" placeholder="公司名称" clearable></el-input>
                </el-form-item>
                <el-form-item prop="name">
                    <div slot="label" class="label">姓<span style="padding:0 20px;"></span>名</div>
                    <el-input v-model="registerForm.name" placeholder="姓名" clearable></el-input>
                </el-form-item>
                <el-form-item prop="email">
                    <div slot="label" class="label">邮<span style="padding:0 20px;"></span>箱</div>
                    <el-input v-model="registerForm.email" placeholder="邮箱" clearable></el-input>
                </el-form-item>
                <el-form-item prop="phone">
                    <div slot="label" class="label">手机号码</div>
                    <el-input v-model="registerForm.phone" placeholder="手机号码" clearable></el-input>
                </el-form-item>
                <el-form-item prop="verifyCode" class="verifyCode">
                    <div slot="label" class="label">信息验证</div>
                    <el-input v-model="registerForm.verifyCode" placeholder="短信验证码" clearable></el-input>
                    <el-button class="getCodeBtn" :disabled="btnHtml != '获取验证码'" @click="getVerifyCode">{{btnHtml}}</el-button>
                </el-form-item>
                <el-form-item>
                    <div slot="label" class="label">
                        <!-- <span style="color:#F56C6C;font-size: 14px;margin-right:4px">*</span> -->
                        <span>营业执照</span>
                    </div>
                    <el-upload v-loading="uploadLoading" class="uploadBox" :action="uploadUrl" :headers="headers" :data="uploadData" :show-file-list="false" :before-upload="beforeUpload" :on-success="uploadSuccess" :on-progress="uploading" :on-error="uploadError">
                        <img v-if="imageUrl" :src="imageUrl" class="avatar">
                        <i v-else class="el-icon-plus"></i>
                        <span class="tipTxt" v-show="imageUrl">重新上传</span>
                    </el-upload>
                    <div class="txtBox">上传示例</div>
                    <div class="sampleBox">
                        <el-image :src="sampleUrl" fit="cover" style="width:100%;height:100%;" :preview-src-list="[sampleUrl]"></el-image>
                    </div>
                </el-form-item>
            </el-form>
            <div class="clause">
                <span>注册即表明您同意</span>
                <span class="toAgreement" @click="toAgreement(1)">《服务协议》</span>和
                <span class="toAgreement" @click="toAgreement(2)">《隐私条款》</span>
            </div>
            <el-button class="subBtn" type="primary" @click="onSubmit" :loading="btnLoading" :disabled="isSubmit">确 认 创 建</el-button>
            <div class="link"><el-link :underline="false" style="color: var(--themeColor);" @click="toLogin">已有账号登录</el-link></div>
        </div>

        <el-dialog title="微信公众号" :visible.sync="dialogVisible" width="500px">
            <div style="text-align: center;margin-top:10px;" v-loading="codeLoading">
                <img :src="imgCodeSrc" alt="" style="width:300px;height:300px;" />
                <p style="height: 28px;line-height: 28px;font-size: 14px;color: #C7C7C7;">微信扫一扫关注公众号</p>
            </div>
        </el-dialog>

    </div>
</template>

<script>
let codetimer = null;
export default {
    data() {
        return {
            btnLoading: false,
            isSubmit: false,
            registerForm: {
                openid: "",
                companyName: "",
                name: "",
                email: "",
                phone: "",
                verifyCode: "",
                annexs: [],
            },
            rules: {
                companyName: [{ required: true, message: "请输入公司名称", trigger: "blur" }],
                name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
                email: [
                    { required: true, message: "请输入邮箱", trigger: "blur" },
                    { type: 'email', message: '请输入正确的邮箱', trigger: ['blur', 'change'] }
                ],
                phone: [
                    { required: true, message: "请输入手机号码", trigger: "blur" },
                ],
                verifyCode: [{ required: true, message: "请输入短信验证码", trigger: "blur" }],
            },
            btnHtml: "获取验证码",
            //微信公众号弹框
            dialogVisible: false,
            ticket: "",
            imgCodeSrc: "",
            codeLoading: false,

            //上传营业执照
            uploadUrl: `${this.$diskUrl}/OtherFile/Upload`,
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('token'),
            },
            uploadData: {
                folderKey: "0",
                remarkName: "",
                permission: '我的'
            },
            uploadLoading: false,
            imageUrl: "",
            sampleUrl: require("@/assets/img/sample.jpg"),
        };
    },
    methods: {
        getVerifyCode(){
            const {companyName,name,email,phone} = this.registerForm;
            if(!(companyName && name && email)){
                this.$message({message: '请确保公司名称,姓名,邮箱填写完整!',type: 'warning'});
                return false;
            }
            if(!phone){
                this.$message({message: '请输入手机号获取验证码',type: 'warning'});
                return false;
            }
            //验证用户是否存在
            this.$axios.post(`${this.$authUrl}/api/Account/VerifyUser`,{
                companyName,name,email,phone
            }).then((res) => {
                //不存在就可以发送手机验证码
                if(res === false){
                    this.sendCode();
                }
            });
        },
        sendCode(){
            this.$axios.post(`${this.$authUrl}/api/Account/SendSms`,{
                phone: this.registerForm.phone,
            }).then((res) => {
                if(res === true){
                    this.$message({message: "手机验证码已发送",type: 'success'});
                    var num = 60;
                    var timer = setInterval(() => {
                        if(num == 0){
                            clearInterval(timer)
                            this.btnHtml = "获取验证码"
                        }else{
                            this.btnHtml = `倒计时${num}s`
                            num--
                        }
                    }, 1000);
                }
            });
        },

        //注册提交
        onSubmit(){
            this.$refs["registerForm"].validate((valid) => {
                if (valid) {
                    // if(!this.imageUrl){
                    //     this.$message({message: '请上传营业执照',type: 'warning'});
                    //     return false;
                    // }
                    // 注册之前需要关注公众号
                    if(this.registerForm.openid){
                        this.newRegister();
                    }else{
                        this.dialogVisible = true;
                        this.CreateCode();
                    }
                }
            })
        },
        CreateCode(){
            this.codeLoading = true;
            this.$axios.get(`${this.$authUrl}/WX/Create`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.ticket = data.ticket;
                    this.imgCodeSrc = `${this.$authUrl}/WX/GetQrCode?ticket=${this.ticket}`;
                    this.codeLoading = false;
                    let num = 0;
                    codetimer = setInterval(() => {
                        num++
                        this.CheckQrLogin();
                        if(num >= 60){
                            clearInterval(codetimer);
                        }
                    }, 1000);
                }
            });
        },
        CheckQrLogin(){
            this.$axios.get(`${this.$authUrl}/WX/CheckQrLogin?ticket=${this.ticket}`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    clearInterval(codetimer);
                    this.dialogVisible = false;
                    this.registerForm.openid = data.openid;
                    this.newRegister();
                }
            });
        },
        newRegister(){
            this.btnLoading = true;
            this.$axios.post(`${this.$authUrl}/api/Account/NewRegister`,this.registerForm).then((res) => {
                this.btnLoading = false;
                if (res == true) {
                    this.isSubmit = true;
                    this.$message({message: "注册成功,请等待1到3个工作日审核!",type: 'success'});
                }
            });
        },

        toLogin(){
            this.$router.push('/login');
        },
        toAgreement(type){
            if(type == 1){
                let routeData = this.$router.resolve({
                    path: "/ServiceAgreementCN",
                });
                window.open(routeData.href, "_blank");
            }
            if(type == 2){
                let routeData = this.$router.resolve({
                    path: "/PrivacyPolicyCN",
                });
                window.open(routeData.href, "_blank");
            }
        },


        //上传营业执照
        beforeUpload(file) {
            var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
            const isImg = testmsg === "png" || testmsg === "jpg" || testmsg === "jpeg";
            const isLt10M = file.size / 1024 / 1024 < 10;
            if (!isImg) {
                this.$message({ message: "上传文件只能是图片!", type: "warning"});
            }
            if (!isLt10M) {
                this.$message({ message: "上传文件大小不能超过10MB!", type: "warning"});
            }
            return isImg && isLt10M;
        },
        uploading(){
            this.uploadLoading = true;
        },
        uploadSuccess(response){
            this.uploadLoading = false;
            this.$message({message: "上传成功",type: 'success'});
            this.imageUrl = response.fileUrl;
            this.registerForm.annexs = [{
                name: response.remarkName,
                url: response.fileUrl,
                size: response.fileSize,
            }]
        },
        uploadError(){
            this.uploadLoading = false;
            this.$message({message: '上传失败',type: 'warning'});
        },


    },
    mounted() {
        this.registerForm.openid = this.$route.query.openid;
        
    },
};
</script>

<style lang="scss" scoped>
@import '@/scss/common.scss';
.register{
    width: 100vw;
    height: 100vh;
    .bg3{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
    }
    .logo{
        position: fixed;
        top: 62px;
        left: 72px;
        z-index: 20;
    }
    .copyright{
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 30;
        width: 100%;
        height: 48px;
        line-height: 48px;
        font-size: 20px;
        color: #FFFFFF;
        opacity: 0.67;
        text-align: center;
    }

    .middleBox{
        position: fixed;
        z-index: 40;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 600px;
        height: 750px;
        background: #FFFFFF;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
        border-radius: 16px;
        box-sizing: border-box;
        padding: 30px 60px;
        .title{
            font-size: 30px;
            color: $themeColor;
            margin-bottom: 20px;
        }
        .label{
            display: inline-block;
            font-size: 20px;
            color: #092338;
            height: 48px;
            line-height: 48px;
        }
        // 修改input框样式
        /deep/.el-input__inner {
            height: 48px;
            line-height: 48px;
            border-radius: 36px;
            font-size: 18px;
            color: #333;
            font-weight: bold;
            background: rgba($color: #c4c4c4, $alpha: 0.13);
            border: none;
            padding: 0 35px;
        }
        .clause{
            height: 28px;
            line-height: 28px;
            font-size: 14px;
            color: #C7C7C7;
            margin-bottom: 10px;
            .toAgreement{
                cursor: pointer;
                color: $themeColor;
            }
        }
        .subBtn{
            width: 100%;
            height: 48px;
            border-radius: 32px;
            background-color: $themeColor;
            border-color: $themeColor;
            box-shadow: 0px 5px 12px rgba(37, 88, 191, 0.1);
            font-size: 20px;
            color: #FFFFFF;
            margin-bottom: 10px;
            &:hover{
                opacity: 0.8;
            }
        }
        .link{
            text-align: center;
            font-size: 16px;
        }
        .verifyCode{
            position: relative;
            .getCodeBtn{
                position: absolute;
                right: 0;
                top: 0;
                width: 120px;
                text-align: center;
                height: 48px;
                background-color: rgba($color: #919191, $alpha: 0.13);
                border: none;
                border-radius: 36px;
                font-size: 16px;
                color: #707070;
                &:hover{
                    opacity: 0.8;
                }
            }
        }
    }
}

.uploadBox,.sampleBox {
    width: 120px;
    height: 120px;
    display: inline-block;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    &:hover{
        border-color: var(--themeColor);
    }
    .avatar {
        width: 120px;
        height: 120px;
        display: inline-block;
    }
    .el-icon-plus {
        font-size: 28px;
        color: #8c939d;
        width: 120px;
        height: 120px;
        line-height: 120px;
        text-align: center;
    }
    .tipTxt{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 120px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        background-color: rgba($color: #000, $alpha: 0.6);
        color: #FFFFFF;
    }
}
.sampleBox{
    margin-left: 100px;
}
.txtBox{
    display: inline-block;
    position: absolute;
    margin-left: 24px;
    font-size: 16px;
    color: #092338;
}
</style>