<template>
    <div class="workOrderDetails">
        <div class="header">工单沟通详情</div>
        <div class="content">
            <div class="leftBox">
                <div class="chatBox">
                    <ChatBox :chatList="chatList" :isCustomer="isCustomer" />
                </div>
                <div class="replyBox">
                    <div class="closeBtn" @click="onClose" v-show="!isClose">
                        <img src="@/assets/img/guanbi.png" alt="" />
                        <span>关闭工单</span>
                    </div>
                    <el-input type="textarea" v-model="messageContent" :rows="3" :disabled="isClose" show-word-limit maxlength="500"></el-input>
                    <el-button class="sendBtn" size="small" type="primary" :disabled="isClose" @click="onReply" :loading="btnLoading">发 送</el-button>
                    <div class="imgItem" v-for="(item,index) in imgSrcList" :key="index">
                        <el-image style="width:100%;height:100%;" :src="item" :preview-src-list="imgSrcList"></el-image>
                        <i class="el-icon-circle-close"  @click="handleRemove(index)"></i>
                    </div>
                    <div class="pastebox" v-on:paste="handlePaste" v-show="imgSrcList.length < 3">
                        <el-upload drag :action="uploadUrl" :headers="headers" :data="uploadData" :show-file-list="false"
                        :before-upload="beforeUpload" :on-success="uploadSuccess" :disabled="isClose">
                            <i class="el-icon-upload"></i>
                            <div>将图片拖到此处，或<span>点击上传</span></div>
                            <div>或将图片按<span>Ctrl+V</span>粘贴至此处</div>
                        </el-upload>
                    </div>
                </div>
            </div>
            <div class="rightBox">
                <div class="title">工单信息</div>
                <div class="infoItem">
                    <span class="label">工单编号：</span>
                    <span>{{detailInfo.no}}</span>
                </div>
                <div class="infoItem">
                    <span class="label">问题描述：</span>
                    <span>{{detailInfo.problemDescription}}</span>
                </div>
                <div class="infoItem">
                    <span class="label">工单状态：</span>
                    <span>{{detailInfo.status}}</span>
                </div>
                <div class="infoItem">
                    <span class="label">提交时间：</span>
                    <span>{{detailInfo.createTime}}</span>
                </div>
                <div class="infoItem">
                    <span class="label">提交人：</span>
                    <span>{{detailInfo.createUser}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ChatBox from "./ChatBox/Index.vue"
export default {
    components: { ChatBox },
    data() {
        return {
            btnLoading: false,
            //工单回复
            workOrderKey: "",
            isCustomer: true,
            messageContent: "",
            attachmentList: [],
            imgSrcList: [],
            //上传附件
            uploadUrl: `${this.$diskUrl}/File/Upload`,
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('token'),
            },
            uploadData: {
                folderKey: "0",
                remarkName: "",
                permission: '我的'
            },
            //信息展示
            detailInfo: {},
            chatList: [],
            isClose: false,
        };
    },
    methods: {
        // 粘贴上传图片
        handlePaste(event) {
            const items = (event.clipboardData || window.clipboardData).items;
            let file = null;
            if (!items || items.length === 0) { this.$message.error("当前浏览器不支持本地"); return; }
            for (let i = 0; i < items.length; i++) {
                if (items[i].type.indexOf("image") !== -1) {
                    file = items[i].getAsFile();
                    break;
                }
            }
            if (!file) { this.$message.error("粘贴内容非图片"); return; }
            if(this.imgSrcList.length >= 3){ this.$message.warning("最多上传三张图片"); return; }
            this.uploadPlans(file);
        },
        uploadPlans(file) {
            const from = new FormData();
            from.append("file", file);
            from.append("permission", "我的");
            from.append("remarkName", "");
            from.append("folderKey", "0");
            this.$axios.post(this.uploadUrl, from).then((res) => {
                const {isSuccess,data} = res;
                if(isSuccess){
                    this.$message({message: "上传成功",type: 'success'});
                    this.attachmentList.push({
                        fileName: data.fileName,
                        fileUrl: data.fileUrl,
                        fileSize: data.fileSize,
                    })
                    this.imgSrcList.push(data.fileUrl)
                }
            });
        },
        // 点击上传图片
        beforeUpload(file) {
            var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
            const isImg = testmsg === "png" || testmsg === "jpg" || testmsg === "jpeg";
            const isLt10M = file.size / 1024 / 1024 < 10;
            if(this.imgSrcList.length >= 3){ 
                this.$message.warning("最多上传三张图片"); 
            }
            if (!isImg) {
                this.$message({ message: "上传文件只能是图片!", type: "warning"});
            }
            if (!isLt10M) {
                this.$message({ message: "上传文件大小不能超过10MB!", type: "warning"});
            }
            return isImg && isLt10M && this.imgSrcList.length < 3;
        },
        uploadSuccess(response){
            const {isSuccess,data} = response;
            if(isSuccess){
                this.$message({message: "上传成功",type: 'success'});
                this.attachmentList.push({
                    fileName: data.fileName,
                    fileUrl: data.fileUrl,
                    fileSize: data.fileSize,
                })
                this.imgSrcList.push(data.fileUrl)
            }
        },
        handleRemove(index){
            this.attachmentList.splice(index,1)
            this.imgSrcList.splice(index,1)
        },
        // 工单回复
        onReply(){
            if(!this.messageContent && this.attachmentList.length == 0){
                this.$message({message: "请填写问题描述或者上传图片",type: 'warning'});
                return false;
            }
            //请求接口
            this.btnLoading = true;
            this.$axios.post(`${this.$customerUrl}/WorkOrder/WorkOrderReply`,{
                workOrderKey: this.workOrderKey,
                message: this.messageContent,
                isCustomer: this.isCustomer,
                annexs: this.attachmentList,
            }).then((res) => {
                const { isSuccess } = res;
                this.btnLoading = false;
                if (isSuccess) {
                    this.$message({message: "发送成功",type: 'success'});
                    this.getDetail();
                    //清空数据
                    this.messageContent = "";
                    this.attachmentList = [];
                    this.imgSrcList = [];
                }
            });
        },

        // 关闭工单
        onClose(){    
            this.$confirm(`是否关闭工单?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$axios.get(`${this.$customerUrl}/WorkOrder/CloseWorkOrder?workOrderKey=${this.workOrderKey}`).then((res) => {
                    const { isSuccess } = res;
                    if (isSuccess) {
                        this.getDetail();
                        this.$message({type: 'success',message: '关闭工单成功!'});
                    }
                });
            }).catch(() => {});
        },

        //获取工单详细
        getDetail(){
            this.$axios.get(`${this.$customerUrl}/WorkOrder/GetWorkOrderDetail?workOrderKey=${this.workOrderKey}`).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    this.detailInfo = data;
                    data.messageInfos.forEach(element => {
                        element.imgList = element.annexs.map(item => item.fileUrl);
                    });
                    this.chatList = data.messageInfos;
                    console.log(this.chatList);
                    if(this.detailInfo.status == "已关闭"){
                        this.isClose = true;
                    }
                }
            });
        },
    },
    mounted() {
        this.workOrderKey = this.$route.query.workOrderKey;
        // 客服账号
        let uKey = JSON.parse(localStorage.userInfo).uKey;
        if(uKey=="55dfce24-0971-40b0-a328-f8620ca1c27f"){
            this.isCustomer = false;
        }
        //获取工单详细
        this.getDetail();
    },
};
</script>

<style lang="scss" scoped>
.workOrderDetails{
    .header{
        font-size: 20px;
        font-weight: bold;
        color: #383838;
        height: 40px;
        line-height: 40px;
    }
    .content{
        height: calc(100vh - 120px);
        background: #F9F9F9;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        overflow: hidden;
    }
    .leftBox{
        float: left;
        width: calc(100% - 300px);
        height: calc(100vh - 120px);
        .chatBox{
            height: calc(100vh - 450px);
            overflow: auto;
        }
        .replyBox{
            height: 330px;
            background: #FFFFFF;
            box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
            padding: 10px 20px;
            box-sizing: border-box;
            .closeBtn{
                cursor: pointer;
                float: right;
                margin-bottom: 10px;
                font-size: 14px;
            }
            .sendBtn{
                float:right;
                margin-top:10px;
            }
        }
    }
    .rightBox{
        float: right;
        width: 300px;
        height: calc(100vh - 120px);
        background: #FFFFFF;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
        padding: 10px 20px;
        box-sizing: border-box;
        color: #092338;
        .title{
            font-size: 16px;
            line-height: 32px;
        }
        .infoItem{
            font-size: 14px;
            line-height: 28px;
            .label{
                color: #707070;
            }
        }
    }
    // 上传图片以及图片预览样式
    .imgItem{
        margin-top:10px;
        display: inline-block;
        width: 360px;
        height: 180px;
        margin-right: 20px;
        position: relative;
        .el-icon-circle-close{
            position: absolute;
            z-index: 10;
            right: 5px;
            top: 5px;
            cursor: pointer;
            font-size: 20px;
            color: #6a6a6a;
            display: none;
        }
        &:hover .el-icon-circle-close{
            display: inline-block;
        }
    }
    .pastebox{
        margin-top:10px;
        display: inline-block;
        span{
            padding:0 10px;
            color:var(--themeColor);
        }
    }
}
</style>