import { render, staticRenderFns } from "./PrivacyPolicyEN.vue?vue&type=template&id=45d41d79&scoped=true&"
import script from "./PrivacyPolicyEN.vue?vue&type=script&lang=js&"
export * from "./PrivacyPolicyEN.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45d41d79",
  null
  
)

export default component.exports