<template>
    <div class="tabCard">
        <el-tabs>
            <el-tab-pane label="订单字段">
                <FieldSet :type="1" />
            </el-tab-pane>
            <el-tab-pane label="报价单字段">
                <FieldSet :type="3" />
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import FieldSet from "./ComSet/FieldSet.vue";
export default {
    components: { FieldSet },
    data() {
        return {

        };
    },
    methods: {

    },
    mounted() {

    },
};
</script>

<style lang="scss" scoped>
.tabCard{
    background: #FFFFFF;
    padding: 0 20px 20px;
}
</style>