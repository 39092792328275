<script>
import Vue from 'vue'
import router from "@/router";
import { reactive, ref, defineComponent, computed } from '@vue/composition-api'
import ShowTooltipOverflowText from '@/components/ShowTooltipOverflowText.vue'
import axios from '@/assets/js/axios'
import dayjs from 'dayjs'
import { Message, MessageBox } from 'element-ui'
import CompanyCardVue from '@/components/CompanyCard.vue'
import ColleageCardVue from '../../../../../components/ColleageCard.vue'
import ContactCardVue from '../../../../../components/ContactCard.vue'

const _cache = {
  count: 0,
  followerOption: []
}

export default defineComponent({
  props: ['businessKey'],
  components: {
    ShowTooltipOverflowText,
    ContactCardVue,
    CompanyCardVue,
    ColleageCardVue
  },
  setup(props, { emit }) {
    const showStageBarWhemFail = ref(false)
    const failStageKey = ref('')
    const ukey = ref(JSON.parse(window.localStorage.getItem('userInfo')).uKey || '')
    const followerOption = ref(_cache.followerOption)
    if ((_cache.count >= 5 || !followerOption.value.length) && !(_cache.count = 0)) {
      (async () => {
        try {
          const res = await axios({
            method: 'GET',
            url: Vue.prototype.$sjUrl + '/BusinessChance/GetMemebers',
          })
          _cache.followerOption = res.data
        } catch {
          _cache.followerOption = []
        } finally {
          followerOption.value = _cache.followerOption
        }
      })()
    }
    const contact = reactive({
      loading: false,
      visiable: false,
      form: {
        key: "",
        companyKey: '',
        isMain: false,
        nickname: "",
        email: "",
        workRank: "",
        position: "",
        call: "",
        linkedin: "",
        twitter: "",
        gender: "",
        birthday: "",
        note: "",
        images: [],
      }
    })
    const form = reactive({
      name: '',
      no: '',
      isNameEditing: false,
      customer: '',
      customerKey: '',
      currency: '',
      amount: 0,
      isAmountEditing: false,
      follower: [],
      isFollowerEditing: false,
      end: '',
      isEndEditing: false,
      contact: [],
      stageKey: '',
      reason: '',
      creason: '',
      expirationDays: 0,
      isExpire: false
    })
    const autoShudanRQ = computed(() => dayjs(form.end).add('7', 'days').format('MM-DD'))
    const reasonOptions = ref([]);
    (async () => {
      try {
        const res = await axios({
          method: 'GET',
          url: Vue.prototype.$customerUrl + '/v1/Config/GetOrderInputReason',
        })
        reasonOptions.value = res.data
      } catch { null }
    })()
    const endDisplay = computed(() => form.end ? dayjs(form.end).format('YYYY-MM-DD') : '')
    const progress = ref([])
    function disposeProgress(list) {
      if (isFail.value) {
        progress.value = list.filter(item => item.stageName !== '输单').map(item => ({
          ...item,
          done: false
        }))
      } else {
        let done = true
        progress.value = list.filter(item => item.stageName !== '输单').map(item => ({
          ...item,
          done: item.stageKey === form.stageKey ? !(done = false) : done
        }))
      }
    }
    const getContactList = async (companykey) => {
      try {
        const response = await axios({
          method: 'GET',
          url: Vue.prototype.$customerUrl + '/Company',
          params: {
            companykey
          }
        })
        if (!response.isSuccess) throw new Error()
        form.contact = response.data.contacters.map(item => ({
          name: item.nickname,
          position: item.position,
          email: item.email,
          key: item.key,
          isMain: item.isMain,
          isVerify: item.isVerify,
        }))
      } catch { null }
    }
    (async () => {
      try {
        const res = await axios({
          method: 'GET',
          url: Vue.prototype.$sjUrl + '/BusinessChance/GetBusinessDetail',
          params: {
            businessKey: props.businessKey
          }
        })
        if (!res.isSuccess) throw new Error()
        const data = res.data
        form.name = data.businessName
        form.no = data.no
        form.customer = data.companyName
        form.customerKey = data.companyKey
        form.currency = data.currency
        form.amount = data.amount
        form.follower = data.businessMembers
        form.end = data.endTime
        form.stageKey = data.stageKey
        form.isExpire = data.isExpire
        form.expirationDays = data.expirationDays
        contact.form.companyKey = data.companyKey
        const result = await axios({
          method: 'GET',
          url: Vue.prototype.$sjUrl + '/BusinessChance/GetStages'
        })
        if (!result.isSuccess) throw new Error()
        failStageKey.value = result.data.find(item => item.stageName === '输单').stageKey
        disposeProgress(result.data)
        await getContactList(data.companyKey)
      } catch { null }
    })()
    const modifyBasic = async (option = {}) => {
      try {
        const res = await axios({
          method: 'POST',
          url: Vue.prototype.$sjUrl + '/BusinessChance/UpdateBusiness',
          data: {
            businessKey: props.businessKey,
            name: form.name,
            amount: form.amount,
            stageKey: form.stageKey,
            endTime: dayjs(form.end).format('YYYY-MM-DD'),
            businessMembers: form.follower.map(item => ({
              name: item.name,
              ukey: item.ukey,
              isAdmin: item.isAdmin || false,
              isCreate: item.isCreate || false
            })),
            reason: option.reason || ''
          }
        })
        if (!res.isSuccess) throw new Error()
        Message.success('修改成功')
      } catch { null }
    }
    const handleProgressItemClick = (stageKey) => {
      form.stageKey = stageKey
      disposeProgress(progress.value)
      modifyBasic().then(() => emit('stage-change'))
    }
    const handleCreateContactConfirm = async () => {
      try {
        contact.loading = true
        const arr = [
          [contact.form.nickname, "联系人名称"],
          [contact.form.email, "邮箱"],
        ];
        for (const [str, label] of arr) {
          if (str.trim() === "") {
            Message.warning(`${label}不得为空`);
            throw new Error();
          }
        }
        let r1 = false,
          r2 = false;
        const res = await axios({
          method: "GET",
          url: Vue.prototype.$customerUrl + "/Company/ContactVerify",
          params: {
            customerKey: contact.form.companyKey,
            text: contact.form.email,
          }
        })
        r1 = res.data.isExis;
        if (contact.form.call.trim()) {
          const res = await axios({
            method: "GET",
            url: Vue.prototype.$customerUrl + "/Company/ContactVerify",
            params: {
              customerKey: contact.form.companyKey,
              text: contact.form.call,
            },
          });
          r2 = res.data.isExis;
        }
        if (r1 || r2) {
          MessageBox.confirm("联系人已存在，是否继续添加")
            .then(() => {
              createNewContact();
            })
            .catch(() => { }).finally(() => { contact.loading = true })
        } else {
          createNewContact();
        }
      } catch { contact.loading = false }
    }
    const createNewContact = async () => {
      try {
        await axios({
          method: "POST",
          url: Vue.prototype.$customerUrl + "/Company/SaveContacter",
          data: {
            ...contact.form,
          },
        })
        Message.success('操作成功')
        contact.visiable = false;
        getContactList(contact.form.companyKey)
      } catch { null } finally { contact.loading = false }
    }
    const failDialog = reactive({
      exsisted: false
    })
    const handleFailClick = () => {
      if (!form.reason) {
        Message.warning('请选择输单原因')
        return
      }
      if (form.reason === '其它' && !form.creason) {
        Message.warning('请输入输单原因')
        return
      }
      form.stageKey = failStageKey.value
      disposeProgress(progress.value)
      modifyBasic({ reason: form.reason === '其它' ? form.creason : form.reason }).then(() => { emit('stage-change'); failDialog.exsisted = false })
    }
    const isFail = computed(() => form.stageKey === failStageKey.value)
    const onCommand = async (command) => {
      switch (command) {
        case 'quotation':
            let location = router.resolve({
                path: "/quotation/newQuotation",
                query: { customerKey: form.customerKey, businessKey: props.businessKey },
            });
            window.open(location.href, "_blank");
          break
        case 'order':
            let routeData = router.resolve({
                path: "/orderManage/addOrder",
                query: { customerKey: form.customerKey, businessKey: props.businessKey },
            });
            window.open(routeData.href, "_blank");
          break
        case 'delete':
          try {
            const res = await axios({
              method: "GET",
              url: Vue.prototype.$sjUrl + "/BusinessChance/DeleteBusiness",
              params: {
                key: props.businessKey
              }
            })
            if (!res.isSuccess) throw new Error()
            Message.success('删除成功')
            emit('delete')
          } catch { null }
          break
        case 'stagemodify':
          showStageBarWhemFail.value = true
          break
        default:
          break
      }
    }
    return {
      ukey,
      followerOption,
      form,
      endDisplay,
      progress,
      modifyBasic,
      handleProgressItemClick,
      contact,
      handleCreateContactConfirm,
      failDialog,
      handleFailClick,
      reasonOptions,
      isFail,
      onCommand,
      showStageBarWhemFail,
      autoShudanRQ
    }
  }
})
</script>

<template>
  <div class="info-panel">
    <el-dialog v-if="failDialog.exsisted" width="400px" :visible="true" :modal="false" :append-to-body="true" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
      <div style="padding: 20px; display: flex; flex-direction: column">
        <span style="font-weight: bold">输单原因</span>
        <div style="max-height: 260px; overflow-y: auto">
          <el-radio-group v-model="form.reason">
            <el-radio v-for="item of reasonOptions" :key="item.key" :label="item.reason" style="display: block;margin-top: 0.5em;" />
            <el-radio :label="'其它'" style="display: block;margin-top: 0.5em;" />
          </el-radio-group>
        </div>
        <el-input v-model.trim="form.creason" :disabled="form.reason !== '其它'" type="textarea" style="margin-top: 0.5em;" />
        <div style="display: flex; justify-content: flex-end; margin-top: 15px">
          <el-button size="small" @click="failDialog.exsisted = false">取消</el-button>
          <el-button @click="handleFailClick" type="primary" size="small">输单</el-button>
        </div>
      </div>
    </el-dialog>
    <div style="box-sizing: border-box; height: 46px; display: flex; justify-content: space-between">
      <div style="box-sizing: border-box; display: flex">
        <img src="@/assets/img/avatar-b.png" style="vertical-align: bottom" />
        <div style="margin-left: 8px; display: flex; flex-direction: column; justify-content: space-between">
          <div style="display: flex" :style="{ alignItems: form.isNameEditing ? 'center' : 'flex-end' }">
            <span v-show="!form.isNameEditing" style="color: #1A2745; font-size: 20px; font-weight: bold; line-height: 1; display: inline-block; max-width: 420px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap">{{ form.name }}</span>
            <el-input @blur="form.isNameEditing = false; modifyBasic()" v-show="form.isNameEditing" v-model.trim="form.name" size="mini" />
            <i @click="form.isNameEditing = true" class="el-icon-edit" style="cursor: pointer; margin-left: 10px"></i>
          </div>
          <p style="font-size: 16px; color: #3A3A3A; line-height: 1">{{ form.no }}</p>
        </div>
      </div>
      <div>
        <span style="margin-right: 15px">{{ autoShudanRQ }}自动过期输单</span>
        <span v-if="isFail">输单</span>
        <el-button v-else @click="failDialog.exsisted = true" type="info" size="mini">输单</el-button>
        <el-dropdown trigger="click" @command="onCommand">
          <el-button size="mini" style="margin-left: 20px">操作</el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="quotation">创建报价单</el-dropdown-item>
            <el-dropdown-item command="order">创建订单</el-dropdown-item>
            <el-dropdown-item command="delete">删除</el-dropdown-item>
            <el-dropdown-item v-if="isFail" command="stagemodify">修改商机阶段</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div style="box-sizing: border-box; height: 70px; display: grid; grid-template-rows: 100%; grid-template-columns: 35% 16%  25% 24%">
      <div style="display: flex; flex-direction: column; justify-content: center">
        <p style="color: #B7B9BC; font-size: 14px">公司名称：</p>
        <div style="box-sizing: border-box; width: 100%; height: 32px">
          <el-popover trigger="hover">
            <CompanyCardVue :ckey="form.customerKey" />
            <ShowTooltipOverflowText slot="reference" :text="form.customer" width="auto" :custome-style="{ maxWidth: '95%' }" />
          </el-popover>
        </div>
      </div>
      <div style="display: flex; flex-direction: column; justify-content: center">
        <p style="color: #B7B9BC; font-size: 14px">金额：</p>
        <div style="box-sizing: border-box; width: 100%; height: 32px">
          <span v-show="!form.isAmountEditing" @click="form.isAmountEditing = true" style="display: inline-block; max-width: 95%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap">{{ `${form.currency}${form.amount}` }}</span>
          <el-input-number class="input-number" v-show="form.isAmountEditing" @blur="form.isAmountEditing = false; modifyBasic()" v-model.trim="form.amount" size="mini" />
        </div>
      </div>
      <div style="display: flex; flex-direction: column; justify-content: center">
        <p style="color: #B7B9BC; font-size: 14px">跟进人：</p>
        <div style="box-sizing: border-box; width: 100%; height: 32px">
          <div v-show="!form.isFollowerEditing" style="display: flex; align-items: center">
            <div v-for="item of form.follower.slice(0, 2)" :key="item.ukey">
              <el-popover trigger="hover">
                <ColleageCardVue :ckey="item.ukey" />
                <div slot="reference" style="box-sizing: border-box; overflow: hidden; margin-right: 3px; display: flex; justify-content: center; align-items: center; width: 24px; height: 24px; border-radius: 50%; color: #fff; font-size: 12px; background: var(--themeColor); cursor: default">{{ item.name.slice(0, 2) }}</div>
              </el-popover>
            </div>
            <span v-if="form.follower.length > 2">...</span>
            <i @click="form.isFollowerEditing = true" class="el-icon-circle-plus-outline" style="font-size: 27px; cursor: pointer"></i>
          </div>
          <div v-show="form.isFollowerEditing" style="display: flex; align-items: center; padding-right: 5px">
            <el-select v-model="form.follower" multiple collapse-tags size="mini" value-key="ukey">
              <el-option v-for="item of followerOption" :key="item.ukey" :label="item.name" :value="item" :disabled="item.ukey === ukey" />
            </el-select>
            <el-tag @click="form.isFollowerEditing = false; modifyBasic()" style="cursor: pointer" size="mini">完成</el-tag>
          </div>
        </div>
      </div>
      <div style="display: flex; flex-direction: column; justify-content: center">
        <p style="color: #B7B9BC; font-size: 14px">结束日期：</p>
        <div style="box-sizing: border-box; width: 100%; height: 32px">
          <span v-show="!form.isEndEditing" @click="form.isEndEditing = true" style="display: inline-block; max-width: 95%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap" :style="{ color: form.isExpire ? '#ff0000' : '' }">{{ `${endDisplay}${form.isExpire ? '(过期' + form.expirationDays + '天)' : ''}` || '不限' }}</span>
          <div v-show="form.isEndEditing" style="display: flex; align-items: center; width: 100%; padding-right: 5px">
            <el-date-picker v-model="form.end" type="date" format="yyyy-MM-dd" :clearable="false" :editable="false" style="width: 100%" size="mini" />
            <el-tag @click="form.isEndEditing = false; modifyBasic().then(() => $emit('stage-change'))" style="cursor: pointer" size="mini">完成</el-tag>
          </div>
        </div>
      </div>
    </div>
    <div class="progress">
      <div v-if="isFail && !showStageBarWhemFail" class="item fail">
        <span>输单</span>
      </div>
      <template v-else>
        <div @click="handleProgressItemClick(item.stageKey)" v-for="(item, index) of progress" :key="index" class="item" :class="[item.isWinStatge ? 'second' : 'first', item.done ? 'done' : '']">
          <span>{{ item.stageName }}</span>
        </div>
      </template>
    </div>
    <div class="contact">
      <div style="height: 30px; display: flex; align-items: center; font-size: 14px; line-height: 1">
        <span style="color: #B7B9BC; margin-right: 20px">联系人：</span>
        <p style="display: flex; align-items: center; cursor: pointer">
          <i class="el-icon-plus" style="color: #545D67"></i>
          <span @click="contact.visiable = true" style="color: #545D67">新增联系人</span>
        </p>
      </div>
      <el-scrollbar style="width: 100%; height: 90px">
        <div style="box-sizing: border-box; padding: 0 5px; padding-top: 5px; width: fit-content; height: 90px; display: flex; align-items: flex-start">
          <el-popover trigger="hover" v-for="(item, index) of form.contact" :key="index">
            <ContactCardVue :ckey="item.key" />
            <div slot="reference" class="contact-card-c">
                <p>
                    <span>{{ `${item.name}${item.position ? ' - ' + item.position : ''}` }}</span>
                    <el-tag size="mini" v-if="item.isMain">主要联系人</el-tag>
                </p>
                <p>
                    <EmailValue :value="item.email" :isVerify="item.isVerify" />
                </p>
            </div>
          </el-popover>
        </div>
      </el-scrollbar>
    </div>
    <el-dialog title="添加联系人" :visible.sync="contact.visiable" width="700px" :modal="false" :append-to-body="true" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
      <div style="padding: 20px;">
        <el-form :model="contact.form" label-width="110px" size="small">
          <el-form-item label="主要联系人">
            <el-radio-group v-model="contact.form.isMain">
              <el-radio :label="true">是</el-radio>
              <el-radio :label="false">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="联系人名称" required>
            <el-input v-model.trim="contact.form.nickname" placeholder="联系人名称"></el-input>
          </el-form-item>
          <el-form-item label="邮箱" required>
            <el-input v-model.trim="contact.form.email" placeholder="邮箱"></el-input>
          </el-form-item>
        </el-form>
        <div style="display: flex; justify-content: flex-end">
          <el-button size="small" @click="contact.visiable = false" v-loading="contact.loading">取 消</el-button>
          <el-button @click="handleCreateContactConfirm" type="primary" size="small" :loading="contact.loading">确 定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<style scoped lang="scss">
::v-deep .el-dialog {
  margin: 0 auto;
}

::v-deep .el-dialog__header {
  display: none;
}

::v-deep .el-dialog__body {
  padding: 0;
}

.info-panel {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.input-number {
  width: 100%;

  ::v-deep .el-input-number__decrease,
  ::v-deep .el-input-number__increase {
    display: none;
  }

  ::v-deep .el-input__inner {
    padding-left: 15px;
    text-align: left;
  }
}

.progress {
  box-sizing: border-box;
  height: 26px;
  display: flex;
  align-items: center;
  padding: 0 20px;

  .item {
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: #fff;
    position: relative;
    overflow: visible;

    span {
      z-index: 2;
    }

    @mixin backSharpe($type, $color) {
      &::before {
        @if $type==first {
          border-radius: 13px 0 0 13px;
          background: linear-gradient(-135deg, transparent 15px, $color 0) top right,
            linear-gradient(-45deg, transparent 15px, $color 0) bottom right;
        }

        @else if $type==last {
          border-radius: 0 13px 13px 0;
          background: linear-gradient(-135deg,
              $color 15px,
              calc(100% - 15px),
              transparent 0) top right,
            linear-gradient(-45deg,
              $color 15px,
              calc(100% - 15px),
              transparent 0) bottom right;
        }

        @else if $type==only {
          border-radius: 13px;
          background: $color;
        }

        @else {
          background: linear-gradient(-135deg,
              transparent 15px,
              $color 15px,
              calc(100% - 15px),
              transparent 0) top right,
            linear-gradient(-45deg,
              transparent 15px,
              $color 15px,
              calc(100% - 15px),
              transparent 0) bottom right;
        }

        position: absolute;
        z-index: 0;
        display: block;
        content: "";
        height: 100%;
        width: calc(100% + 20px);
        background-size: 100% 51%;
        background-repeat: no-repeat;
      }
    }

    &.first {
      @include backSharpe(normal, #8cd2b0);

      &:first-child {
        @include backSharpe(first, #8cd2b0);
      }

      &:last-child {
        @include backSharpe(last, #8cd2b0);
      }

      &:only-child {
        @include backSharpe(only, #8cd2b0);
      }

      &.done {
        @include backSharpe(normal, #33a76f);

        &:first-child {
          @include backSharpe(first, #33a76f);
        }

        &:last-child {
          @include backSharpe(last, #33a76f);
        }

        &:only-child {
          @include backSharpe(only, #33a76f);
        }
      }
    }

    &.second {
      @include backSharpe(normal, #93b6e5);

      &:first-child {
        @include backSharpe(first, #93b6e5);
      }

      &:last-child {
        @include backSharpe(last, #93b6e5);
      }

      &:only-child {
        @include backSharpe(only, #93b6e5);
      }

      &.done {
        @include backSharpe(normal, #4480cf);

        &:first-child {
          @include backSharpe(first, #4480cf);
        }

        &:last-child {
          @include backSharpe(last, #4480cf);
        }

        &:only-child {
          @include backSharpe(only, #4480cf);
        }
      }
    }

    &.fail {
      @include backSharpe(only, #a1a1a1);
      cursor: default;
    }
  }
}

.contact {
  box-sizing: border-box;
  padding-top: 10px;
}

::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
}

.contact-card-c {
  box-sizing: border-box;
  flex-shrink: 0;
  width: 180px;
  height: 66px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #1f2d3d;

  &:not(:last-child) {
    margin-right: 16px;
  }

  p {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
