import G6 from '@antv/g6'
import { TreeGraph } from '@antv/g6-pc'
import { isObject } from '@antv/util'

export {
  TreeGraph,
  isObject
}

export default G6
