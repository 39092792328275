<template>
    <div>
        <div class="filterBox">
            <span style="flex-shrink: 0">时间：</span>
            <el-select v-model="filterYear" placeholder="年份" size="small" style="width:100px;" @change="onFilterAll(1)">
                <el-option label="2021年" value="2021"></el-option>
                <el-option label="2022年" value="2022"></el-option>
                <el-option label="2023年" value="2023"></el-option>
            </el-select>
            <el-select v-model="filterMonth" placeholder="月份" size="small" style="width:100px;" @change="onFilterAll(1)" clearable>
                <el-option label="整年" value=""></el-option>
                <el-option label="1月" value="1"></el-option>
                <el-option label="2月" value="2"></el-option>
                <el-option label="3月" value="3"></el-option>
                <el-option label="4月" value="4"></el-option>
                <el-option label="5月" value="5"></el-option>
                <el-option label="6月" value="6"></el-option>
                <el-option label="7月" value="7"></el-option>
                <el-option label="8月" value="8"></el-option>
                <el-option label="9月" value="9"></el-option>
                <el-option label="10月" value="10"></el-option>
                <el-option label="11月" value="11"></el-option>
                <el-option label="12月" value="12"></el-option>
            </el-select>
			<span style="flex-shrink: 0; margin-left: 20px;">组织：</span>
            <el-cascader class="cascader" :show-all-levels="false" v-model="belongValue" :options="belongOptions" :props="{ value: 'key', label: 'name', checkStrictly: true }" size="small" @change="onFilterAll(2)" style="width:150px;" />
		</div>

        <div class="grid-container" style="height:150px" v-loading="statLoading">
            <div class="statBox">
                <div class="ttt">今年</div>
                <div style="display: flex;padding:15px;">
                    <div class="statItem">
                        <div class="label">订单总量</div>
                        <div class="value">{{statDataOne.orderNum}}</div>
                        <div class="ratio">
                            <el-tooltip effect="dark" placement="bottom">
                                <div slot="content">同比:{{statDataOne.yoyOrderNum}}</div>
                                <div>
                                    <span style="color: #9C9C9C;">同比:</span>
                                    <span>{{statDataOne.yoyOrderNumRate == "-" ? "-" : statDataOne.yoyOrderNumRate + "%"}}</span>
                                    <i class="el-icon-caret-top" v-if="statDataOne.yoyOrderNumRate > 0"></i>
                                    <i class="el-icon-caret-bottom" v-if="statDataOne.yoyOrderNumRate < 0"></i>
                                </div>
                            </el-tooltip>
                        </div>
                    </div>
                    <div class="statItem">
                        <div class="label">订单金额</div>
                        <div class="value">{{statDataOne.orderAmount}}</div>
                        <div class="ratio">
                            <el-tooltip effect="dark" placement="bottom">
                                <div slot="content">同比:{{statDataOne.yoyOrderAmount}}</div>
                                <div>
                                    <span style="color: #9C9C9C;">同比:</span>
                                    <span>{{statDataOne.yoyOrderAmountRate == "-" ? "-" : statDataOne.yoyOrderAmountRate + "%"}}</span>
                                    <i class="el-icon-caret-top" v-if="statDataOne.yoyOrderAmountRate > 0"></i>
                                    <i class="el-icon-caret-bottom" v-if="statDataOne.yoyOrderAmountRate < 0"></i>
                                </div>
                            </el-tooltip>
                        </div>
                    </div>
                </div>
            </div>
            <div class="statBox">
                <div class="ttt">本月</div>
                <div style="display: flex;padding:15px;">
                    <div class="statItem">
                        <div class="label">订单总量</div>
                        <div class="value">{{statDataTwo.orderNum}}</div>
                        <div class="ratio">
                            <el-tooltip effect="dark" placement="bottom">
                                <div slot="content">同比:{{statDataTwo.yoyOrderNum}}</div>
                                <div>
                                    <span style="color: #9C9C9C;">同比:</span>
                                    <span>{{statDataTwo.yoyOrderNumRate == "-" ? "-" : statDataTwo.yoyOrderNumRate + "%"}}</span>
                                    <i class="el-icon-caret-top" v-if="statDataTwo.yoyOrderNumRate > 0"></i>
                                    <i class="el-icon-caret-bottom" v-if="statDataTwo.yoyOrderNumRate < 0"></i>
                                </div>
                            </el-tooltip>
                        </div>
                    </div>
                    <div class="statItem">
                        <div class="label">订单金额</div>
                        <div class="value">{{statDataTwo.orderAmount}}</div>
                        <div class="ratio">
                            <el-tooltip effect="dark" placement="bottom">
                                <div slot="content">同比:{{statDataTwo.yoyOrderAmount}}</div>
                                <div>
                                    <span style="color: #9C9C9C;">同比:</span>
                                    <span>{{statDataTwo.yoyOrderAmountRate == "-" ? "-" : statDataTwo.yoyOrderAmountRate + "%"}}</span>
                                    <i class="el-icon-caret-top" v-if="statDataTwo.yoyOrderAmountRate > 0"></i>
                                    <i class="el-icon-caret-bottom" v-if="statDataTwo.yoyOrderAmountRate < 0"></i>
                                </div>
                            </el-tooltip>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="grid-container">
            <div class="grid-item-panel" v-loading="orderDetails.loading">
                <div class="grid-item-select">
                    <div @click="setType('orderDetails', '0')" class="grid-item-select-option" :class="{ active: orderDetails.type === '0' }">客户</div>
                    <div @click="setType('orderDetails', '1')" class="grid-item-select-option" :class="{ active: orderDetails.type === '1' }">城市</div>
                    <div @click="setType('orderDetails', '2')" class="grid-item-select-option" :class="{ active: orderDetails.type === '2' }">产品</div>
                </div>
                <div class="grid-item-panel-title">
                    <div style="display: flex; align-items: flex-end">
                        <span class="grid-item-panel-title-text">订单详情</span>
                        <span class="grid-item-panel-title-sub-text" style="margin-left: 10px"></span>
                    </div>
                    <div class="icon-container">
                        <img src="@/assets/icon/refresh.png" />
                        <img src="@/assets/icon/close.png" />
                    </div>
                </div>
                <div ref="orderDetails-dom" class="grid-item-panel-chart-dom"></div>
            </div>
            <div class="grid-item-panel" v-loading="orderRanking.loading">
                <div class="grid-item-select">
                    <div @click="setType('orderRanking', '0')" class="grid-item-select-option" :class="{ active: orderRanking.type === '0' }">金额</div>
                    <div @click="setType('orderRanking', '1')" class="grid-item-select-option" :class="{ active: orderRanking.type === '1' }">数量</div>
                </div>
                <div class="grid-item-panel-title">
                    <div style="display: flex; align-items: flex-end">
                        <span class="grid-item-panel-title-text">订单排名</span>
                        <span class="grid-item-panel-title-sub-text" style="margin-left: 10px"></span>
                    </div>
                    <div class="icon-container">
                        <img src="@/assets/icon/refresh.png" />
                        <img src="@/assets/icon/close.png" />
                    </div>
                </div>
                <div class="grid-item-panel-chart-dom" style="padding: 0 90px 0 110px">
                    <el-table class="custom-el-table" :data="orderRanking.tableData" :show-header="false" height="calc(100% - 20px)">
                        <el-table-column width="50px" align="center">
                            <template slot-scope="{ row }">
                                <img v-if="row.rankSrc" :src="row.rankSrc" />
                                <span v-else>{{ row.rank }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="name" />
                        <el-table-column align="right">
                            <template slot-scope="{ row }">
                                <span>{{ row.amount }}</span>
                                <span style="color: #7c90a5; margin-left: 9px">{{orderRanking.type === '0' ? '万元':'个'}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column width="40px" align="center">
                            <template slot-scope="{ row }">
                                <span v-if="row.status === '-'">{{ row.status }}</span>
                                <img v-else :src="row.status" />
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <div class="grid-item-panel" v-loading="orderAnnualAmount.loading">
                <div class="grid-item-panel-title">
                    <div style="display: flex; align-items: flex-end">
                        <span class="grid-item-panel-title-text">订单年度金额</span>
                    </div>
                    <div class="icon-container">
                        <img src="@/assets/icon/refresh.png" />
                        <img src="@/assets/icon/close.png" />
                    </div>
                </div>
                <div ref="orderAnnualAmount-dom" class="grid-item-panel-chart-dom"></div>
            </div>
            <div class="grid-item-panel" v-loading="orderAnnualQuantity.loading">
                <div class="grid-item-panel-title">
                    <div style="display: flex; align-items: flex-end">
                        <span class="grid-item-panel-title-text">订单年度数量</span>
                    </div>
                    <div class="icon-container">
                        <img src="@/assets/icon/refresh.png" />
                        <img src="@/assets/icon/close.png" />
                    </div>
                </div>
                <div ref="orderAnnualQuantity-dom" class="grid-item-panel-chart-dom"></div>
            </div>
        </div>
    </div>
</template>

<script>
import * as echarts from "echarts";
import dayjs from "dayjs";
const medals = [
    require("@/assets/icon/medal-1.png"),
    require("@/assets/icon/medal-2.png"),
    require("@/assets/icon/medal-3.png"),
];
const arrow = [
    "-",
    require("@/assets/icon/arrow-increase.png"),
    require("@/assets/icon/arrow-decrease.png"),
];

export default {
    chartInstance: {
        orderDetails: null,
        orderAnnualAmount: null,
        orderAnnualQuantity: null,
    },
    data() {
        return {
            //日期筛选
            filterYear: "2023",
            filterMonth: "",
            //组织筛选
            belongValue: [""],
            belongOptions: [],
            //统计数据
            statLoading: false,
            statDataOne:{},
            statDataTwo:{},
            //订单详情
            orderDetails: {
                loading: false,
                type: "0", //0 客户 1 城市 2 产品
            },
            //订单排名
            orderRanking: {
                loading: false,
                type: "0", //0 金额 1 数量
                tableData: [],
            },
            //订单年度金额
            orderAnnualAmount: {
                loading: false,
            },
            //订单年度数量
            orderAnnualQuantity: {
                loading: false,
            },
        };
    },
    watch: {
        "orderDetails.type"() {
            this.getOrderDetails();
        },
        "orderRanking.type"() {
            this.getOrderRanking();
        },
    },
    methods: {
        //获取组织结构树下拉框
        getOrganizationDrop(){
            this.$axios.get(`${this.$personnelUrl}/Organization/FindOrganizationDrop`).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    data.unshift({
                        key: '',
                        name: '我的',
                        children: []
                    })
                    for (const item of data) {
                        this.disposeTree(item)
                    }
                    this.belongOptions = data;
                }
            });
        },
        //删除叶子节点的 children 属性
        disposeTree(node) {
            if (node.children.length) {
                for (const item of node.children) {
                    this.disposeTree(item)
                }
            } else {
                delete node.children
            }
        },

        onFilterAll(filterType){
            if(filterType==1){
                this.getOrderDetails();
                this.getOrderRanking();
            }
            if(filterType==2){
                this.getOrderDetails();
                this.getOrderRanking();
                this.getOrderAnnualAmount();
                this.getOrderAnnualQuantity();
            }
        },

        //获取顶部统计数据
        getStatData(){
            this.statLoading = true;
            let currentYear = new Date().getFullYear();
            let currentMonth = new Date().getMonth() + 1;
            let organizationKey = this.belongValue[this.belongValue.length-1];
            //本年数据
            this.$axios.post(`${this.$customerUrl}/Report/GetOrderTopData`,{
                "year": currentYear,
                "month": "",
                "organizationKey": organizationKey,
            }).then((res) => {
                const { isSuccess , data } = res;
                this.statLoading = false;
                if (isSuccess) {
                    this.statDataOne = data;
                }
            });
            //本月数据
            this.$axios.post(`${this.$customerUrl}/Report/GetOrderTopData`,{
                "year": currentYear,
                "month": currentMonth,
                "organizationKey": organizationKey,
            }).then((res) => {
                const { isSuccess , data } = res;
                this.statLoading = false;
                if (isSuccess) {
                    this.statDataTwo = data;
                }
            });
        },

        setType(key, value) {
            this[key].type = value;
        },

        async getOrderDetails() {
            let organizationKey = this.belongValue[this.belongValue.length-1];
            try {
                this.orderDetails.loading = true;
                const res = await this.$axios({
                    method: "POST",
                    url: this.$customerUrl + "/Report/GetOrderDetail",
                    data: {
                        type: this.orderDetails.type,
                        year: this.filterYear,
                        month: this.filterMonth,
                        organizationKey: organizationKey,
                    },
                });
                const data = res.data;
                this.$options.chartInstance.orderDetails.setOption({
                    series: [
                        {
                            name: "订单详情",
                            data,
                        },
                    ],
                });
            } catch {
                null;
            } finally {
                this.orderDetails.loading = false;
            }
        },
        async getOrderRanking() {
            let organizationKey = this.belongValue[this.belongValue.length-1];
            try {
                this.orderRanking.loading = true;
                const res = await this.$axios({
                    method: "POST",
                    url: this.$customerUrl + "/Report/GetOrderRanking",
                    data: {
                        type: this.orderRanking.type,
                        year: this.filterYear,
                        month: this.filterMonth,
                        organizationKey: organizationKey,
                    },
                });
                const data = res.data;
                this.orderRanking.tableData = data.map((item) => ({
                    rank: item.rank,
                    rankSrc: medals[+item.rank - 1],
                    name: item.name,
                    amount: item.amount,
                    // status: arrow[+item.status],
                }));
            } catch {
                null;
            } finally {
                this.orderRanking.loading = false;
            }
        },
        async getOrderAnnualAmount() {
            let organizationKey = this.belongValue[this.belongValue.length-1];
            try {
                this.orderAnnualAmount.loading = true;
                const res = await this.$axios({
                    method: "POST",
                    url: this.$customerUrl + "/Report/GetOrderTrendChart",
                    data: {
                        type: "0",
                        organizationKey: organizationKey,
                    }
                });
                const data = res.data;
                this.$options.chartInstance.orderAnnualAmount.setOption({
                    xAxis: {
                        data: data.data,
                    },
                    series: data.series,
                });
            } catch {
                null;
            } finally {
                this.orderAnnualAmount.loading = false;
            }
        },
        async getOrderAnnualQuantity() {
            let organizationKey = this.belongValue[this.belongValue.length-1];
            try {
                this.orderAnnualQuantity.loading = true;
                const res = await this.$axios({
                    method: "POST",
                    url: this.$customerUrl + "/Report/GetOrderTrendChart",
                    data: {
                        type: "1",
                        organizationKey: organizationKey,
                    }
                });
                const data = res.data;
                this.$options.chartInstance.orderAnnualQuantity.setOption({
                    xAxis: {
                        data: data.data,
                    },
                    series: data.series,
                });
            } catch {
                null;
            } finally {
                this.orderAnnualQuantity.loading = false;
            }
        },

        
    },
    mounted() {
        //获取组织结构树下拉框
        this.getOrganizationDrop();        

        this.$options.chartInstance.orderDetails = echarts.init(
            this.$refs["orderDetails-dom"]
        );
        this.$options.chartInstance.orderDetails.setOption({
            legend: {
                top: "middle",
                type: "scroll",
                orient: "vertical",
                right: "5%",
                icon: "circle",
                textStyle: {
                    color: "#7C90A5",
                },
                itemWidth: 14,
                itemHeight: 14,
            },
            tooltip: {},
            series: [
                {
                    name: "订单详情",
                    type: "pie",
                    radius: ["30%", "70%"],
                    center: ["40%", "50%"],
                    label: {
                        show: true,
                        color: "#94979C",
                    },
                    labelLine: {
                        smooth: true,
                        lineStyle: {
                            color: "#94979C",
                        },
                    },
                    emphasis: {
                        scale: false,
                    },
                    data: [
                        { name: "Africa", value: 100 },
                        { name: "Europe", value: 200 },
                        { name: "South America", value: 300 },
                        { name: "Oceania", value: 400 },
                        { name: "North America", value: 500 },
                        { name: "未分类", value: 600 },
                        { name: "Asia", value: 700 },
                    ],
                },
            ],
            color: [
                "#62D0D3",
                "#67A0C8",
                "#9BC586",
                "#DFD982",
                "#DC7683",
                "#8897C0",
                "#2560A3",
            ],
        });

        this.$options.chartInstance.orderAnnualAmount = echarts.init(
            this.$refs["orderAnnualAmount-dom"]
        );
        this.$options.chartInstance.orderAnnualAmount.setOption({
            legend: {
                top: 5,
                right: 30,
                icon: "circle",
                itemWidth: 8,
                itemHeight: 8,
                itemStyle: {
                    opacity: 1,
                },
                itemGap: 20,
            },
            grid: {
                containLabel: true,
                top: 40,
                left: 30,
                right: 30,
                bottom: 20,
            },
            xAxis: {
                axisLine: {
                    lineStyle: {
                        color: "#BFBFBF",
                    },
                },
                axisTick: {
                    alignWithLabel: true,
                },
                axisLabel: {
                    color: "#595959",
                },
                data: [],
            },
            yAxis: {
                axisLabel: {
                    color: "#595959",
                },
                splitLine: {
                    lineStyle: {
                        color: "#E7E7E7",
                        type: "dashed",
                    },
                },
            },
            tooltip: {
                trigger: "axis",
            },
            series: [
                {
                    name: "name1",
                    type: "line",
                    itemStyle: {
                        opacity: 0,
                    },
                    data: [],
                },
                {
                    name: "name2",
                    type: "line",
                    itemStyle: {
                        opacity: 0,
                    },
                    data: [],
                },
                {
                    name: "name3",
                    type: "line",
                    itemStyle: {
                        opacity: 0,
                    },
                    data: [],
                },
            ],
            color: ["#2E7DB5", "#79D6D9", "#D65F6E"],
        });

        this.$options.chartInstance.orderAnnualQuantity = echarts.init(
            this.$refs["orderAnnualQuantity-dom"]
        );
        this.$options.chartInstance.orderAnnualQuantity.setOption({
            legend: {
                top: 5,
                right: 30,
                icon: "circle",
                itemWidth: 8,
                itemHeight: 8,
                itemStyle: {
                    opacity: 1,
                },
                itemGap: 20,
            },
            grid: {
                containLabel: true,
                top: 40,
                left: 30,
                right: 30,
                bottom: 20,
            },
            xAxis: {
                axisLine: {
                    lineStyle: {
                        color: "#BFBFBF",
                    },
                },
                axisTick: {
                    alignWithLabel: true,
                },
                axisLabel: {
                    color: "#595959",
                },
                data: [],
            },
            yAxis: {
                axisLabel: {
                    color: "#595959",
                },
                splitLine: {
                    lineStyle: {
                        color: "#E7E7E7",
                        type: "dashed",
                    },
                },
            },
            tooltip: {
                trigger: "axis",
            },
            series: [
                {
                    name: "name1",
                    type: "line",
                    itemStyle: {
                        opacity: 0,
                    },
                    data: [],
                },
                {
                    name: "name2",
                    type: "line",
                    itemStyle: {
                        opacity: 0,
                    },
                    data: [],
                },
                {
                    name: "name3",
                    type: "line",
                    itemStyle: {
                        opacity: 0,
                    },
                    data: [],
                },
            ],
            color: ["#2E7DB5", "#79D6D9", "#D65F6E"],
        });

        //获取顶部统计数据
        this.getStatData();
        //订单详情
        this.getOrderDetails();
        //订单排名
        this.getOrderRanking();
        //订单年度金额
        this.getOrderAnnualAmount();
        //订单年度数量
        this.getOrderAnnualQuantity();

    },
};
</script>

<style lang="scss" scoped>
.filterBox{
    height: 60px;
    padding-left: 10px;
    display: flex; 
    align-items: center;
    font-size: 14px;
    color: #707070;
}
.statBox{
    width: calc(50% - 10px);
    position: relative;
    height: 150px;
    background: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    .ttt{
        font-size: 16px;
        color: #1B2841;
        font-weight: bold;
        height: 32px;
        line-height: 32px;
        padding-left: 20px;
        border-bottom: 1px solid #E6E6E6;
    }
    .statItem{
        text-align: center;
        width: 50%;
        border-left: 1px solid #E6E6E6;
        .label{
            font-size: 16px;
            line-height: 24px;
            font-weight: bold;
            color: #1B2841;
        }
        .value{
            font-size: 32px;
            color: #1B2841;
        }
        .ratio{
            line-height: 24px;
            color: #9C9C9C;
            .el-icon-caret-top{
                color: #2DC76D;
            }
            .el-icon-caret-bottom{
                color: #F56C6C;
            }
        }
    }
    .statItem:first-child{
        border-left: none;
    }
}
.grid-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.grid-item-panel {
    width: calc(50% - 10px);
    margin-top: 20px;
    position: relative;
    height: 380px;
    background: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}
$grid-item-panel-title-height: 42px;
.grid-item-panel-title {
    box-sizing: border-box;
    height: $grid-item-panel-title-height;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 14px 0 20px;
    &.bb {
        border-bottom: 1px solid #e6e7eb;
    }
}
.grid-item-panel-title-text {
    font-size: 18px;
    color: #1b2841;
    font-weight: bold;
}
.grid-item-panel-title-sub-text {
    font-size: 14px;
    color: #c7c7c7;
}
.icon-container {
    display: flex;
    align-items: center;
    img {
        cursor: pointer;
        &:last-child {
            margin-left: 10px;
        }
    }
}
.grid-item-select {
    position: absolute;
    z-index: 2;
    left: 20px;
    top: $grid-item-panel-title-height;
    border: 1px solid #c7c7c7;
    border-radius: 2px;
    width: 80px;
    font-size: 14px;
    color: #7c90a5;
    cursor: pointer;
    background: #fff;
}
.grid-item-select-option {
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover,
    &.active {
        background: var(--themeColor);
        color: #fff;
    }
}
.grid-item-panel-chart-dom {
    box-sizing: border-box;
    height: calc(100% - #{$grid-item-panel-title-height});
}
.custom-el-table {
    &.el-table::before {
        height: 0 !important;
        background: none !important;
    }
    font-size: 14px;
    ::v-deep .el-table__cell {
        height: 25px;
        padding: 0;
        border: none;
    }
    ::v-deep tbody {
        .el-table__cell {
            color: #3f404a;
            font-weight: normal;
        }
        .cell {
            padding: 0;
        }
        tr:hover {
            td {
                background: #e3eaef !important;
            }
        }
    }
}
</style>