<template>
    <div>
        <el-dialog title="设置列表字段" :visible="setVisible" width="850px" @close="$emit('close')" top="100px">
            <div class="dialogBody">
                <div class="leftSide">
                    <div class="sideItem" :class="{ active: clickType == '推荐' }" @click="clickType = '推荐'">推荐</div>
                    <div class="sideItem" :class="{ active: clickType == '自定义' }" @click="clickType = '自定义'">自定义</div>
                </div>
                <div class="rightContent" v-show="clickType == '推荐'">
                    <div class="preview">
                        <div class="title">预览：</div>
                        <div class="content">
                            <span v-for="tag in freezeList" :key="tag">{{tag}}</span>
                            <div style="height: 32px;width: 2px;background-color: #d9d9d9;"></div>
                            <span v-for="tag in moveList" :key="tag">{{tag}}</span>
                        </div>
                    </div>
                    <div class="checkedBox">
                        <div class="lineItem">
                            <div class="title">固定列：</div>
                            <div class="content">
                                <VueDraggable v-model="freezeList" group="site" filter=".forbid">
                                    <el-tag v-for="(tag, index) in freezeList" :key="index" :class="tag=='公司名称'||tag=='国家' ? 'forbid':''" :closable="tag != '公司名称' && tag != '国家' " type="info" @close="closeTagOne(index)">{{ tag }}</el-tag>
                                </VueDraggable>
                            </div>
                        </div>
                        <div class="lineItem">
                            <div class="title">其他列：</div>
                            <div class="content">
                                <VueDraggable v-model="moveList" group="site">
                                    <el-tag v-for="(tag, index) in moveList" :key="index" closable type="info" @close="closeTagTwo(index)">{{ tag }}</el-tag>
                                </VueDraggable>
                            </div>
                        </div>
                        <div style="display: flex; justify-content: end">
                            <el-button type="primary" size="small" plain @click="clickType='自定义'">编 辑</el-button>
                        </div>
                    </div>
                    <div class="checkedBox" v-for="item in recommendList" :key="item.key">
                        <div class="title">{{ item.title }}</div>
                        <el-tag v-for="(tag, index) in item.fieldList" :key="index" type="info">{{ tag }}</el-tag>
                        <div style="display: flex; justify-content: end">
                            <el-button type="primary" size="small" plain @click="useField(item.fieldList)">使 用</el-button>
                        </div>
                    </div>
                </div>
                <div class="rightContent" v-show="clickType == '自定义'">
                    <div class="preview">
                        <div class="title">预览：</div>
                        <div class="content">
                            <span v-for="tag in freezeList" :key="tag">{{tag}}</span>
                            <div style="height: 32px;width: 2px;background-color: #d9d9d9;"></div>
                            <span v-for="tag in moveList" :key="tag">{{tag}}</span>
                        </div>
                    </div>
                    <div class="checkedBox">
                        <div class="lineItem">
                            <div class="title">固定列：</div>
                            <div class="content">
                                <VueDraggable v-model="freezeList" group="site" filter=".forbid">
                                    <el-tag v-for="(tag, index) in freezeList" :key="index" :class="tag=='公司名称'||tag=='国家' ? 'forbid':''" :closable="tag != '公司名称' && tag != '国家' " type="info" @close="closeTagOne(index)">{{ tag }}</el-tag>
                                </VueDraggable>
                            </div>
                        </div>
                        <div class="lineItem">
                            <div class="title">其他列：</div>
                            <div class="content">
                                <VueDraggable v-model="moveList" group="site">
                                    <el-tag v-for="(tag, index) in moveList" :key="index" closable type="info" @close="closeTagTwo(index)">{{ tag }}</el-tag>
                                </VueDraggable>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="title">公司信息字段：</div>
                        <el-checkbox-group v-model="checkedField" @change="selectChange">
                            <el-row :gutter="20">
                                <el-col :span="6" v-for="(label, index) in fieldOneList" :key="index" style="margin-bottom: 10px">
                                    <el-checkbox :label="label" :disabled="label=='公司名称' || label=='国家'"></el-checkbox>
                                </el-col>
                            </el-row>
                        </el-checkbox-group>
                    </div>
                    <div>
                        <div class="title">联系人信息字段：</div>
                        <el-checkbox-group v-model="checkedField" @change="selectChange">
                            <el-row :gutter="20">
                                <el-col :span="6" v-for="(label, index) in fieldTwoList" :key="index" style="margin-bottom: 10px">
                                    <el-checkbox :label="label"></el-checkbox>
                                </el-col>
                            </el-row>
                        </el-checkbox-group>
                    </div>
                    <div>
                        <div class="title">统计信息字段：</div>
                        <el-checkbox-group v-model="checkedField" @change="selectChange">
                            <el-row :gutter="20">
                                <el-col :span="6" v-for="(label, index) in fieldThreeList" :key="index" style="margin-bottom: 10px">
                                    <el-checkbox :label="label"></el-checkbox>
                                </el-col>
                            </el-row>
                        </el-checkbox-group>
                    </div>

                </div>
            </div>
            <div slot="footer">
                <el-button type="primary" size="small" @click="saveSet">确 认</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
const kanji2Property = (() => {
    const data = [
        // 公司信息字段
        ["公司名称", "companyName"],
        ["国家", "country"],
        ["公司简称", "companyNickName"],
        ["公司来源", "source"],
        ["公司类型", "mainBusinessTypeValue"],
        ["年采购额", "annualPurchaseAmount"],
        ["规模", "companyScaleValue"],
        ["星级", "star"],
        ["公司分组", "groupName"],
        ["标签", "tags"],
        ["公司状态", "statuName"],
        ["主页", "webSite"],
        ["传真", "fax"],
        ["座机", "call"],
        ["公司备注", "remarks"],
        ["时区", "timeZone"],
        ["下次跟进时间", "nextFollowUpTime"],
        ["所属行业", "mainIndustryValue"],
        ["外/内贸", "tradeType"],
        // 联系人信息字段
        ["主要联系人", "nickname"],
        ["邮箱", "email"],
        ["手机号", "phone"],
        ["职位", "position"],
        ["性别", "gender"],
        ["联系人备注", "contactNotes"],
        // 统计信息字段
        ["跟进人", "followUpUser"],
        ["原跟进人", "originalFollowUpUser"],
        ["评分", "score"],
        ["最近联系时间", "lastContactTime"],
        ["创建时间", "createTime"],
        ["最近跟进", "lstFollowUpContent"],
        ["创建人", "createUser"],
        ["最近进入公海时间", "lastPublicTime"],
        ["进入公海次数", "publicNumber"],
    ];
    const map = new Map();
    data.forEach((item) => {
        const [kanji, property, width] = item;
        map.set(kanji, [property, width]);
    });
    return map;
})();

function getResultOne(kanjiArr) {
    return kanjiArr
        .map((kanji) => {
            const [property, width] = kanji2Property.get(kanji) || [];
            if (property) {
                return {
                    kanji,
                    property,
                    width,
                    isFixed: true,
                };
            } else {
                return undefined;
            }
        })
        .filter((item) => item);
}
function getResultTwo(kanjiArr) {
    return kanjiArr
        .map((kanji) => {
            const [property, width] = kanji2Property.get(kanji) || [];
            if (property) {
                return {
                    kanji,
                    property,
                    width,
                    isFixed: false,
                };
            } else {
                return undefined;
            }
        })
        .filter((item) => item);
}
import VueDraggable from "vuedraggable";
export default {
    components: { VueDraggable },
    props: ["setVisible", "tableColumns"],
    data() {
        return {
            clickType: "推荐",
            //推荐设置
            recommendList: [],
            //自定义设置
            freezeList: ["国家", "公司名称"], //固定列
            moveList: [], //其他列
            checkedField: ["国家", "公司名称"], //选中的字段列表
            //公司信息字段：
            fieldOneList: [
                "公司名称",
                "国家",
                "公司简称",
                "公司来源",
                "公司类型",
                "年采购额",
                "规模",
                "星级",
                "公司分组",
                "标签",
                "公司状态",
                "主页",
                "传真",
                "座机",
                "公司备注",
                "时区",
                "下次跟进时间",
                "所属行业",
                "外/内贸",
            ],
            //联系人信息字段：
            fieldTwoList: [
                "主要联系人",
                "邮箱",
                "手机号",
                "职位",
                "性别",
                "联系人备注",
            ],
            //统计信息字段：
            fieldThreeList: [
                "跟进人",
                "原跟进人",
                "评分",
                "最近联系时间",
                "创建时间",
                "最近跟进",
                "创建人",
                "最近进入公海时间",
                "进入公海次数",
            ],
        };
    },
    methods: {
        //获取推荐设置列表
        getList() {
            this.recommendList = [
                {
                    key: "1",
                    title: "方案一(默认)：",
                    fieldList: [
                        "公司名称",
                        "国家",
                        "公司分组",
                        "最近联系时间",
                        "星级",
                        "评分",
                        "标签",
                        "公司状态",
                        "公司来源",
                        "公司类型",
                        "所属行业",
                        "跟进人",
                        "外/内贸",
                    ],
                },
                {
                    key: "2",
                    title: "方案二(推荐领导)：",
                    fieldList: [
                        "公司名称",
                        "国家",
                        "最近联系时间",
                        "跟进人",
                        "公司状态",
                        "公司来源",
                        "星级",
                    ],
                },
                {
                    key: "3",
                    title: "方案三(推荐销售员)：",
                    fieldList: [
                        "公司名称",
                        "国家",
                        "公司分组",
                        "最近联系时间",
                        "星级",
                        "公司状态",
                        "所属行业",
                    ],
                },
            ];
        },

        //使用推荐设置
        useField(fieldList) {
            this.freezeList = ["国家", "公司名称"];
            this.checkedField = fieldList;
            this.selectChange();
        },

        //关闭已选择的标签
        closeTagOne(index) {
            this.freezeList.splice(index, 1);
            this.checkedField = this.moveList.concat(this.freezeList);
        },
        closeTagTwo(index) {
            this.moveList.splice(index, 1);
            this.checkedField = this.moveList.concat(this.freezeList);
        },
        selectChange() {
            this.moveList = this.checkedField.filter((item) => {
                return !this.freezeList.includes(item);
            });
        },

        //自定义设置确认保存
        saveSet() {
            if (this.checkedField.length == 0) {
                this.$message({
                    message: "选中字段不可为空!",
                    type: "warning",
                });
                return false;
            }
            this.setTableColumn();
        },

        setTableColumn() {
            let aList = getResultOne(this.freezeList);
            let bList = getResultTwo(this.moveList);
            let columns = aList.concat(bList);
            console.log(columns);
            this.$emit("set-table-column", columns);
        },
    },
    mounted() {
        this.getList();
    },
    watch: {
        tableColumns() {
            // console.log(newValue,oldValue);
            // 初始化字段
            this.freezeList = this.tableColumns.filter((item) => item.isFixed).map((item) => item.kanji);
            this.moveList = this.tableColumns.filter((item) => !item.isFixed).map((item) => item.kanji);
            this.checkedField = this.moveList.concat(this.freezeList);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/scss/common.scss";
/deep/.el-dialog__body {
    padding: 0 !important;
}
.el-tag {
    margin-bottom: 10px;
    color: #092338;
    background: rgba(9, 35, 56, 0.1);
}
.dialogBody {
    display: flex;
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    .leftSide {
        width: 149px;
        height: 600px;
        border-right: 1px solid #d9d9d9;
        .sideItem {
            width: 119px;
            height: 50px;
            line-height: 50px;
            font-size: 16px;
            color: #3a3a3a;
            padding-left: 30px;
            cursor: pointer;
        }
        .active {
            background: $themeColor;
            color: #fff;
        }
    }
    .rightContent {
        width: 700px;
        height: 600px;
        box-sizing: border-box;
        padding: 20px;
        overflow: auto;
        .title {
            line-height: 32px;
            font-size: 16px;
            font-weight: bold;
            color: #092338;
        }
        .checkedBox {
            width: 640px;
            box-sizing: border-box;
            padding: 10px 20px;
            margin-bottom: 10px;
            background: #f6f6f6;
            border-radius: 4px;
        }
    }
    .preview {
        display: flex;
        margin-bottom: 10px;
        .title {
            width: 50px;
            line-height: 32px;
            font-size: 16px;
            font-weight: bold;
            color: #092338;
        }
        .content {
            display: flex;
            flex-wrap: wrap;
            width: calc(100% - 50px);
            background: #f6f6f6;
            border-radius: 4px;
            padding: 0 10px;
            span {
                line-height: 32px;
                display: inline-block;
                padding: 0 10px;
            }
        }
    }
    .lineItem {
        display: flex;
        .title {
            width: 80px;
            line-height: 32px;
            font-size: 16px;
            font-weight: bold;
            color: #092338;
        }
        .content {
            width: calc(100% - 80px);
        }
    }
}
</style>