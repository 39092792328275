var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"100%","overflow-y":"auto","font-size":"14px"}},[_c('div',{staticClass:"tableBox"},[_c('div',{staticClass:"block"},[_vm._m(0),_c('el-table',{attrs:{"data":_vm.tableData.locationAnalysis,"size":"mini"}},[_c('el-table-column',{attrs:{"prop":"country","label":"国家"}}),_c('el-table-column',{attrs:{"prop":"count","label":"数量(收件人个数)"}}),_c('el-table-column',{attrs:{"label":"占比"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.proportion)+"%")]}}])})],1)],1),_c('div',{staticClass:"block"},[_vm._m(1),_c('el-table',{attrs:{"data":_vm.tableData.deviceAnalysis,"size":"mini"}},[_c('el-table-column',{attrs:{"prop":"device","label":"设备"}}),_c('el-table-column',{attrs:{"prop":"count","label":"数量"}}),_c('el-table-column',{attrs:{"label":"占比"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.proportion)+"%")]}}])})],1)],1),_c('div',{staticClass:"block"},[_vm._m(2),_c('el-table',{attrs:{"data":_vm.tableData.opneTimeSlotAnalysis,"size":"mini"}},[_c('el-table-column',{attrs:{"prop":"timeSlot","label":"时段"}}),_c('el-table-column',{attrs:{"prop":"count","label":"数量(次)"}}),_c('el-table-column',{attrs:{"label":"占比(次)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.proportion)+"%")]}}])}),_c('el-table-column',{attrs:{"prop":"peopleCount","label":"数量(人)"}}),_c('el-table-column',{attrs:{"label":"占比(人)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.peopleProportion)+"%")]}}])})],1)],1),_c('div',{staticClass:"block"},[_vm._m(3),_c('el-table',{attrs:{"data":_vm.tableData.languageAnalysis,"size":"mini"}},[_c('el-table-column',{attrs:{"prop":"language","label":"语言"}}),_c('el-table-column',{attrs:{"prop":"count","label":"数量"}}),_c('el-table-column',{attrs:{"label":"占比"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.proportion)+"%")]}}])})],1)],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"title"},[_c('span',[_vm._v("客户地理位置")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"title"},[_c('span',[_vm._v("设备")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"title"},[_c('span',[_vm._v("看信时段")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"title"},[_c('span',[_vm._v("语言分析")])])}]

export { render, staticRenderFns }