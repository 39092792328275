<script>
import Vue from "vue";
import CMenu from "./components/Menu.vue";
import { Message, MessageBox } from "element-ui";
import axios from "axios";
import Editor from "@tinymce/tinymce-vue";
import { reactive, ref } from "@vue/composition-api";
import MarketingNav from "@/components/MarketingNav.vue";
export default Vue.extend({
    components: {
        MarketingNav,
        Editor,
        CMenu,
    },
    setup() {
        /**
         * @description 获取字符串中 '{{}}' 的对数
         * 时间复杂度 O(n)
         * 空间复杂度 O(1)
         * @param {string} str 检测的字符串
         */
        const matchedBracketNum = (str) => {
            let ans = 0;
            let leftBracketNum = 0;
            for (let i = 1, len = str.length; i < len; ) {
                const word = `${str[i - 1]}${str[i]}`;
                if (word === "{{") {
                    leftBracketNum++;
                    i += 2;
                    continue;
                } else if (word === "}}" && leftBracketNum > 0) {
                    leftBracketNum--;
                    ans++;
                }
                i++;
            }
            return ans;
        };

        // console.log(matchedBracketNum('{{1}}afsa}  }f{a{{{sd}}}a{s{{{2}}}{{}')) // 3

        const bakkkk = reactive({
            disabled: false,
        });
        const bakkkkFun = {
            handleRemove(file) {
                console.log(file);
            },
        };

        const form = reactive({
            t: "",
        });

        const oyktjgnhjf = reactive({
            templateName: "",
            category: "MarKeting",
            language: "zh_CN",
            components: [
                {
                    type: "HEADER",
                    format: "IMAGE", // "TEXT",
                    text: "",
                    example: [],
                    buttons: [
                        /*  {
               "type": "",
               "text": "",
               "url": "",
               "phoneNumber": ""
             } */
                    ],
                },
                {
                    type: "BODY",
                    format: "TEXT",
                    text: "",
                    example: [], // string[]
                    buttons: [
                        /*  {
               "type": "",
               "text": "",
               "url": "",
               "phoneNumber": ""
             } */
                    ],
                },
                {
                    type: "FOOTER",
                    format: "TEXT",
                    text: "",
                    example: [], // string[]
                    buttons: [
                        /*   {
                "type": "",
                "text": "",
                "url": "",
                "phoneNumber": ""
              } */
                    ],
                },
                {
                    type: "BUTTONS",
                    format: "TEXT",
                    text: "",
                    example: [], // string[]
                    buttons: [
                        {
                            type: "PHONE_NUMBER",
                            text: "",
                            url: "",
                            phoneNumber: "",
                        },
                        {
                            type: "URL",
                            text: "",
                            url: "",
                            phoneNumber: "",
                        },
                    ],
                },
            ],
        });
        const gegnewoigfero = () => {
            oyktjgnhjf.components[0].text = "";
            oyktjgnhjf.components[0].example = [];
        };

        const showModal = ref(false);
        const addLoading = ref(false);

        const jroeigjiorhg = async () => {
            if (!oyktjgnhjf.templateName) {
                Message.warning("请输入模版名");
                return;
            }
            for (const char of oyktjgnhjf.templateName) {
                const code = char.charCodeAt(0);
                if (code !== 95 && (code < 97 || code > 122)) {
                    Message.warning("模版名只能由小写字母和下划线组成");
                    return;
                }
            }
            if (matchedBracketNum(oyktjgnhjf.components[0].text) > 1) {
                Message.warning("首部最多只能有一个变量");
                return;
            }

            if (!oyktjgnhjf.components[1].text) {
                Message.warning("主体内容不得为空");
                return;
            }
            if (!oyktjgnhjf.components[2].text) {
                Message.warning("尾部内容不得为空");
                return;
            }
            // if
            if (
                !oyktjgnhjf.components[3].buttons[0].phoneNumber ||
                !oyktjgnhjf.components[3].buttons[0].text ||
                !oyktjgnhjf.components[3].buttons[1].url ||
                !oyktjgnhjf.components[3].buttons[1].text
            ) {
                Message.warning("请输入按钮内容");
                return;
            }
            try {
                addLoading.value = true;

                //matchedBracketNum

                oyktjgnhjf.components[0].example = Array(
                    matchedBracketNum(oyktjgnhjf.components[0].text)
                ).fill("text");
                oyktjgnhjf.components[1].example = Array(
                    matchedBracketNum(oyktjgnhjf.components[1].text)
                ).fill("text");

                const rrr = await axios({
                    method: "POST",
                    url: Vue.prototype.$marketUrl + "/WhatsApp/WA/AddTemplate",
                    data: oyktjgnhjf,
                });
                //console.log(rrr.isSuccess)
                if (rrr.isSuccess) {
                    Message.success("添加成功");
                    setTimeout(() => {
                        window.location.reload();
                    }, 500);
                }
            } catch (error) {
                null;
            } finally {
                addLoading.value = false;
            }
        };

        // 保存模板
        const templateForm = reactive({
            key: "",
            templateName: "",
            templateTypeKey: "0989046A95BE4E5F825052CAD678C0DF",
            content: "",
            url: "",
            fileUrl: "",
            fileName: "",
        });
        const createTemplate = async () => {
            if (!templateForm.templateName) {
                Message.warning("请输入模版名称");
                return false;
            }
            if (!templateForm.content) {
                Message.warning("请输入模版内容");
                return false;
            }
            try {
                addLoading.value = true;
                const res = await axios({
                    method: "POST",
                    url: Vue.prototype.$marketUrl + "/WhatsApp/v2/AddTemplate",
                    data: templateForm,
                });
                if (res.isSuccess) {
                    Message.success("添加成功");
                    showModal.value = false;
                    getTemplates();
                }
            } catch (error) {
                null;
            } finally {
                addLoading.value = false;
            }
        };

        const emailVariable = ref("");
        const chooseVariable = (value) => {
            if (templateForm.content.indexOf(value) == -1) {
                templateForm.content = templateForm.content + value;
            }
            emailVariable.value = "";
        };

        // 获取模板列表
        const listLoading = ref(true);
        const templateList = ref([]);
        const getTemplates = () => {
            axios({
                method: "GET",
                url: Vue.prototype.$marketUrl + "/WhatsApp/v2/GetTemplates",
            })
                .then((res) => {
                    if (res.isSuccess) {
                        templateList.value = res.data;
                    }
                })
                .finally(() => {
                    listLoading.value = false;
                });
        };
        getTemplates();

        // 删除模板
        const toDelTemplate = (key) => {
            MessageBox.confirm("是否删除该模板?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    axios({
                        method: "GET",
                        url:
                            Vue.prototype.$marketUrl +
                            "/WhatsApp/v2/DeleteTemplate",
                        params: { templateKey: key },
                    }).then((res) => {
                        if (res.isSuccess) {
                            Message.success("删除成功");
                            getTemplates();
                        }
                    });
                })
                .catch(() => {});
        };

        // let addVc = 1
        // const addV = () => {
        //   oyktjgnhjf.components[0].text += `{{${addVc++}}}`
        // }

        // let addVVVVVVc = 1
        // const addVVVVVV = () => {
        //   oyktjgnhjf.components[1].text += `{{${addVVVVVVc++}}}`
        // }

        return {
            bakkkk,
            gegnewoigfero,
            listLoading,
            addLoading,
            templateForm,
            createTemplate,
            templateList,
            getTemplates,
            emailVariable,
            chooseVariable,
            toDelTemplate,
            //   addVVVVVV,
            //   addV,
            jroeigjiorhg,
            bakkkkFun,
            uploadUrl: `${Vue.prototype.$diskUrl}/File/Upload`,
            headers: {
                authorization: "Bearer " + localStorage.getItem("token"),
            },
            uploadData: {
                folderKey: "0",
                remarkName: "",
                permission: "我的",
            },
            beforeUpload(file) {
                var testmsg = file.name.substring(
                    file.name.lastIndexOf(".") + 1
                );
                const isImg =
                    testmsg === "png" ||
                    testmsg === "jpg" ||
                    testmsg === "jpeg";
                const isLt10M = file.size / 1024 / 1024 < 10;
                if (!isImg) {
                    Message({
                        message: "上传文件只能是图片!",
                        type: "warning",
                    });
                }
                if (!isLt10M) {
                    Message({
                        message: "上传文件大小不能超过10MB!",
                        type: "warning",
                    });
                }
                return isImg && isLt10M;
            },
            uploadSuccess(response) {
                const { isSuccess, data } = response;
                if (isSuccess) {
                    oyktjgnhjf.components[0].example = [data.fileUrl];
                }
            },
            oyktjgnhjf,
            form,
            showModal,
            images_upload_handler: async (blobInfo) => {
                try {
                    const formData = new FormData();
                    formData.append("folderKey", "0");
                    formData.append("remarkName", "");
                    formData.append("permission", "我的");
                    formData.append("file", blobInfo.blob());
                    const res = await axios({
                        headers: {
                            Authorization:
                                "Bearer " +
                                window.localStorage.getItem("token"),
                        },
                        method: "POST",
                        url: Vue.prototype.$diskUrl + "/OtherFile/Upload",
                        data: formData,
                    });
                    return res.fileUrl;
                } catch (error) {
                    Message.error("插入图片失败");
                }
            },
            chooseNav: (index) => {
                this.handleSelect(index);
                this.$router.push(index);
            },
            rgweffwef(url) {
                window.open(url, "_blank");
            },
        };
    },
});
</script>

<template>
    <div v-loading="listLoading" style=" width: 100%; height: 100%; position: relative;">
        <!-- <MarketingNav @chooseNav="chooseNav" currentPath="/marketingWA/TaskDashboardWA" /> -->
        <div style="display: flex; width: 100%; height: calc(100%); ">
            <div v-loading="addLoading" class="model" v-show="showModal" style="box-sizing: border-box;padding: 20px;display: flex;position: absolute;width: 100%;height: 100%;top: 0;left: 0;background: #F6F6F6;z-index: 202;">
                <!-- <div style="width: 435px;height: 100%;margin-right: 40px;">
          <div style="color: #092338;font-size: 18px;height: 32px;line-height: 32px;">预览：</div>
          <div style="width: 100%;height: calc(100% - 32px);overflow-y: auto;background: #fff;
border-radius: 6px 6px 6px 6px;
border: 1px solid #D1D1D1;">
            <div style="padding: 20px">
              <div style="width: 100%;word-break: break-all;">
                <img style="width:100%" v-if="oyktjgnhjf.components[0].format === 'IMAGE'" :src="oyktjgnhjf.components[0].example[0]" />
                <p v-if="oyktjgnhjf.components[0].format === 'TEXT'">{{ oyktjgnhjf.components[0].text }}</p>
                <p>{{ oyktjgnhjf.components[1].text }}</p>
                <p>{{ oyktjgnhjf.components[2].text }}</p>
                <div style="border-top: 1px solid #eee"></div>
                <div style="display: flex;justify-content: center;align-items: center;height: 2em;" v-if="oyktjgnhjf.components[3].buttons[0].text && oyktjgnhjf.components[3].buttons[0].phoneNumber">
                  <i class="el-icon-phone-outline" style="color: var(--themeColor)"></i>
                  <span style="color: var(--themeColor)">{{ oyktjgnhjf.components[3].buttons[0].text }}</span>
                </div>
                <div style="display: flex;justify-content: center;align-items: center;height: 2em;" v-if="oyktjgnhjf.components[3].buttons[1].text && oyktjgnhjf.components[3].buttons[1].url">
                  <i class="el-icon-link" style="color: var(--themeColor)"></i>
                  <span @click="rgweffwef(oyktjgnhjf.components[3].buttons[1].url)" style="color: var(--themeColor)">{{ oyktjgnhjf.components[3].buttons[1].text }}</span>
                </div>
              </div>
            </div>
          </div>
        </div> -->
                <div style="width: 100%">
                    <div style="color: #092338;font-size: 18px;height: 32px;line-height: 32px;">模板信息：</div>
                    <div style="box-sizing: border-box;height: calc(100% - 32px);overflow-y: auto;background: #fff;
border-radius: 6px 6px 6px 6px;padding: 20px;
border: 1px solid #D1D1D1;">
                        <!-- 2022-11-14 创建模板修改 -->
                        <el-form label-suffix="：" label-width="100px" size="small">
                            <el-form-item label="模版名称" required>
                                <el-input v-model.trim="templateForm.templateName" />
                            </el-form-item>
                            <el-form-item label="模版内容">
                                <el-select v-model="emailVariable" placeholder="插入变量" @change="chooseVariable" size="small" class="selectBtn">
                                    <el-option label="姓名变量" value="[FIRSTNAME]"></el-option>
                                </el-select>
                                <el-input type="textarea" :rows="10" placeholder="请输入内容" v-model="templateForm.content" maxlength="2000" show-word-limit>
                                </el-input>
                            </el-form-item>
                        </el-form>
                        <div style="margin-top:20px;">
                            <el-button @click="createTemplate" type="primary" size="small">保 存</el-button>
                            <el-button size="small" @click="showModal = false">关 闭</el-button>
                        </div>

                        <div v-if="false" style="height: calc(100% - 60px)">
                            <Editor v-model="templateForm.content" :init="{
                                language: 'zh_CN',
                                resize: false,
                                height: '100%',
                                branding: false,
                                menubar: false,
                                toolbar: 'undo redo | emoticons | image link | formatselect fontsizeselect forecolor | bold underline italic | alignleft aligncenter alignright | preview fullscreen',
                                plugins: 'emoticons image link preview fullscreen',
                                images_upload_handler}"
                                tinymceScriptSrc="tinymce/tinymce.min.js"
                                apiKey="l6y3tipsjmozdy96gs2qcbjjjaauujp07q4r0rpnyxqjh4ac" />
                        </div>
                        <!-- <div>
              <el-form label-suffix="：" label-width="100px" size="small">
                <el-form-item label="模版名" required>
                  <el-input v-model.trim="oyktjgnhjf.templateName" />
                </el-form-item>
                <el-form-item label="模版语言" required>
                  <el-select v-model="oyktjgnhjf.language">
                    <el-option value="zh_CN" label="中文" />
                    <el-option value="en" label="英文" />
                  </el-select>
                </el-form-item>
                <el-form-item label="首部" required>
                  <el-form label-suffix="：" label-width="60px" size="small">
                    <el-form-item label="类型">
                      <el-select @change="gegnewoigfero" v-model="oyktjgnhjf.components[0].format">
                        <el-option value="TEXT" label="文本" />
                        <el-option value="IMAGE" label="图片" />
                      </el-select>
                    </el-form-item>
                    <el-form-item label="内容">
                      <template v-if="oyktjgnhjf.components[0].format === 'TEXT'">
                        <el-input v-model.trim="oyktjgnhjf.components[0].text" />
                        <el-button type="primary" @click="addV" size="mini">添加变量</el-button>
                      </template>
                      <div v-if="oyktjgnhjf.components[0].format === 'IMAGE'" style="display: flex;">
                        <div class="imgStyle" v-if="oyktjgnhjf.components[0].example.length" style="position: relative;padding-right: 20px;">
                          <el-image :src="oyktjgnhjf.components[0].example[0]" style="width: 80px;height: 80px;"></el-image>
                          <el-tag @click="oyktjgnhjf.components[0].text = ''" style="position: absolute;right: 22px; top: 2px;cursor: pointer;" size="mini" type="danger" effect="plain" class="closeTag ">删除</el-tag>
                        </div>
                        <el-upload v-else :action="uploadUrl" :headers="headers" :data="uploadData" list-type="picture-card" :show-file-list="false" :before-upload="beforeUpload" :on-success="uploadSuccess">
                          <i class="el-icon-plus"></i>
                        </el-upload>
                      </div>
                    </el-form-item>
                  </el-form>
                </el-form-item>
                <el-form-item label="主体" required>
                  <el-input v-model.trim="oyktjgnhjf.components[1].text" />
                  <el-button type="primary" @click="addVVVVVV" size="mini">添加变量</el-button>
                </el-form-item>
                <el-form-item label="尾部" required>
                  <el-input v-model.trim="oyktjgnhjf.components[2].text" />
                </el-form-item>
                <el-form-item label="按钮" required>
                  <el-form label-suffix="：" label-width="60px" size="small">
                    <el-form label-suffix="：" label-width="60px" size="small">
                      <el-form-item label="电话">
                        <el-input v-model="oyktjgnhjf.components[3].buttons[0].phoneNumber" />
                      </el-form-item>
                      <el-form-item label="文本">
                        <el-input v-model="oyktjgnhjf.components[3].buttons[0].text" />
                      </el-form-item>
                    </el-form>
                    <el-form label-suffix="：" label-width="60px" size="small">
                      <el-form-item label="链接">
                        <el-input v-model="oyktjgnhjf.components[3].buttons[1].url" />
                      </el-form-item>
                      <el-form-item label="文本">
                        <el-input v-model="oyktjgnhjf.components[3].buttons[1].text" />
                      </el-form-item>
                    </el-form>
                  </el-form>
                </el-form-item>
              </el-form>
            </div> -->
                    </div>
                </div>
            </div>
            <div>
                <CMenu active="TaskDashboardTWA" />
            </div>
            <div class="view" style="width: 100%;">
                <div style="height: 80px ;display: flex;align-items: center;padding-left: 30px;">
                    <span style="font-size: 18px;color: #25265E;margin-right: 27px;">我的模板</span>
                    <el-button type="primary" size="small" @click="showModal = true">新建模板</el-button>
                </div>
                <div style="height: calc(100% - 80px);display: flex;">
                    <div style="height: 100%;width: 385px;flex-shrink: 0; overflow-y: auto;">
                        <div v-for="(item, index) of templateList" :key="index" class="cardItem">
                            <div class="b">{{ item.templateName }}</div>
                            <div style="margin-top:5px;">{{item.content}}</div>
                            <i class="el-icon-delete" @click="toDelTemplate(item.key)" v-if="!item.isSystem"></i>
                            <!-- <div style="width: 100%;word-break: break-all;">
                <img style="width:100%" v-if="item.components[0] && item.components[0].format === 'IMAGE'" :src="item.components[0].text" />
                <p v-if="item.components[0] || item.components[0].format === 'TEXT'">{{ item.components[0].text }}</p>
                <p v-if="item.components[1]">{{ item.components[1].text }}</p>
                <p v-if="item.components[2]">{{ item.components[2].text }}</p>
                <div style="border-top: 1px solid #eee"></div>
                <div style="display: flex;justify-content: center;align-items: center;height: 2em;" v-if="item.components[3] && item.components[3].buttons[0] && item.components[3].buttons[0].text && item.components[3].buttons[0].phoneNumber">
                  <i class="el-icon-phone-outline" style="color: var(--themeColor)"></i>
                  <span style="color: var(--themeColor)">{{ item.components[3].buttons[0].text }}</span>
                </div>
                <div style="display: flex;justify-content: center;align-items: center;height: 2em;" v-if="item.components[3] && item.components[3].buttons[1] && item.components[3].buttons[1].text && item.components[3].buttons[1].url">
                  <i class="el-icon-link" style="color: var(--themeColor)"></i>
                  <span @click="rgweffwef(item.components[3].buttons[1].url)" style="color: var(--themeColor)">{{ item.components[3].buttons[1].text }}</span>
                </div>
              </div> -->
                        </div>

                    </div>
                    <!-- <div style="background: #fff;width: 100%;margin-left: 40px;padding: 30px;box-sizing: border-box;border-radius:6px ;border: 1px solid #D1D1D1;">
            <div style="display: flex; align-items: center; justify-content: space-between;">
              <div class="b">上次使用：2月12日</div>
              <el-button size="small">分享给同事</el-button>
            </div>
            <div style="height: calc(100% - 30px);overflow-y: auto;margin-top: 10px;"></div>
          </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.cardItem {
    padding: 16px 33px;
    background: #fff;
    border-radius: 8px;
    margin-bottom: 20px;
    position: relative;
    cursor: pointer;
    .el-icon-delete {
        display: none;
        color: #f56c6c;
        position: absolute;
        right: 15px;
        top: 15px;
    }
    &:hover .el-icon-delete {
        display: inline-block;
    }
}
.b {
    font-size: 14px;
    padding: 0 15px;
    height: 27px;
    line-height: 27px;
    border-radius: 27px;
    width: fit-content;
    background: #d6f4e3;
    color: #2dc76d;
}

.model {
    ::v-deep .el-upload--picture-card {
        width: 80px;
        height: 80px;

        i {
            position: relative;
            bottom: 30px;
        }
    }

    ::v-deep .el-upload-list__item {
        width: 80px;
        height: 80px;
    }
}

.view {
    box-sizing: border-box;
    padding: 20px;
    padding-top: 0;
    width: 100%;
    height: 100%;
    background: #f6f6f6;
    //display: flex;

    ::v-deep
        .el-table--enable-row-hover
        .el-table__body
        tr:hover
        > td.el-table__cell {
        background: #e3eaef !important;
    }
}

.card {
    width: 100%;
    border: 1px solid #cdcdcd;
    border-radius: 4px;
    display: flex;
    padding: 10px;

    img {
        margin-right: 10px;
    }

    &:not(:last-of-type) {
        margin-right: 20px;
    }
}

.item {
    border-bottom: 1px solid #cdcdcd;
    display: flex;
    align-items: center;
    position: relative;
    margin-right: 20px;
    padding: 20px 0;
}

.el-icon-refresh,
.el-icon-loading {
    cursor: pointer;
    padding-left: 5px;
    font-size: 20px;
    color: var(--themeColor);
}

::v-deep .el-input--mini .el-input__inner {
    border: none;
}

.el-icon-search {
    cursor: pointer;
    line-height: 28px;
    color: #3a3a3a;
}

.draft,
.dingshi,
.withdraw {
    display: inline-block;

    .elseOption {
        display: none;
    }
}

.item:hover .elseOption {
    cursor: pointer;
    display: inline-block;
}

.custome-sleect {
    * {
        line-height: normal !important;
        height: auto;
    }
}
</style>
