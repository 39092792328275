<template>
    <div class="customerDetails" v-loading="loading.view">
        <div class="topContent">
            <div class="basicInfo">
                <div class="companyImg">
                    <img src="@/assets/img/companyImg.png" />
                </div>
                <div style="padding-left: 20px">
                    <div class="companyName">
                        <span>{{ customerInfo.name }}</span>
                        <CopyIcon :copyValue="customerInfo.name" />
                    </div>
                    <div class="country" style="display: flex; align-items: center">
                        <img :src="customerInfo.flagUrl" style="height: 14px; margin-right: 5px" />
                        <span>{{ customerInfo.country }}</span>
                    </div>
                </div>
                <div class="rightHandle">
                    <el-button type="primary" plain size="small" @click="addToMine">加入我的客户</el-button>

                </div>
            </div>
            <el-row :gutter="16" class="importantInfo">
                <el-col :span="4">
                    <div class="label">公海分组:</div>
                    <el-select @change="modifyCustomerInofo" v-model="customerInfo.publicGroupKey" placeholder="请选择" size="small">
                        <el-option v-for="item in seasGroupOption" :key="item.key" :label="item.name" :value="item.key" />
                    </el-select>
                </el-col>

                <el-col :span="4">
                    <div class="label">公司状态:</div>
                    <el-select @change="modifyCustomerInofo" v-model="customerInfo.statuKey" placeholder="请选择" size="small">
                        <el-option v-for="item in statusOption" :key="item.key" :label="item.name" :value="item.key" />
                    </el-select>
                </el-col>


                <el-col :span="4">
                    <div class="label">星级:</div>
                    <div class="value">
                        <el-rate @change="modifyCustomerInofo" v-model="customerInfo.star" style="padding-top: 6px"></el-rate>
                    </div>
                </el-col>

            </el-row>
            <!-- 标签信息 -->
            <div class="tagInfo">
                <el-tag @close="handleTagClose(item.key)" v-for="item in customerInfo.tags" :key="item.key" :color="item.color" closable effect="dark" size="small" style="margin-right: 10px; border-color: transparent">{{ item.name }}</el-tag>
                <el-button size="mini" @click="show.newTag = true">+标签</el-button>
            </div>
            <AddTagDialog :visible.sync="show.newTag" :old-tags="customerInfo.tags" @cancel="show.newTag = false" :companykey="customerKey" @success="getCustomerInfo" />
        </div>

        <div style="display: flex; margin-top: 20px">
            <div class="leftContent">
                <el-tabs>
                    <el-tab-pane label="跟进动态">
                        <div style="width: 100%">
                            <CustomerDynamics :customerKey="customerKey" />
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="详细资料">
                        <div>
                            <div class="ziliaoTitle">公司信息字段</div>
                            <el-form label-width="120px" size="small" label-position="left">
                                <el-row :gutter="16">
                                    <el-col :span="8">
                                        <el-form-item label="公司名称">
                                            <el-input v-model="customerInfo.name" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="公司简称">
                                            <el-input v-model="customerInfo.nickname" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="公司来源">
                                            <el-select v-model="customerInfo.customerSourceKey" style="width:100%;">
                                                <el-option v-for="item of sourceOption" :key="item.key" :value="item.key" :label="item.name" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="所属行业">
                                            <el-input v-model="customerInfo.mainIndustryValue" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="公司类型">
                                            <el-input v-model="customerInfo.mainBusinessTypeValue" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="规模">
                                            <el-select v-model="customerInfo.companyScaleValue" style="width:100%;">
                                                <el-option v-for="item of companyScaleValueOptions" :key="item.value" :label="item.name" :value="item.name" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="国家地区">
                                            <el-select v-model="customerInfo.country" style="width:100%;" filterable>
                                                <el-option v-for="item of countryOptions" :key="item" :value="item" />
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="公司状态">
                                            <el-input v-model="customerInfo.statuName" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="网址">
                                            <el-input v-model="customerInfo.webSite" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="传真">
                                            <el-input v-model="customerInfo.fax" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="公司备注">
                                            <el-input v-model="customerInfo.note" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="评分">
                                            <el-input-number v-model="customerInfo.score" :min="1" :max="100"></el-input-number>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="外/内贸">
                                            <el-select v-model="customerInfo.tradeType" style="width:100%;">
                                                <el-option label="外贸" value="外贸"></el-option>
                                                <el-option label="内贸" value="内贸"></el-option>
                                                <el-option label="外/内贸" value="外/内贸"></el-option>
                                                <el-option label="未知" value="未知"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="公司地址">
                                            <el-input v-model="customerInfo.address" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-button :loading="loading.modifyCustomerInofo" type="primary" size="small" @click="modifyCustomerInofo">保存</el-button>
                                    </el-col>
                                </el-row>
                            </el-form>
                            <div class="ziliaoTitle">统计信息字段</div>
                            <el-form label-width="120px" size="small" label-position="left" disabled>
                                <el-row :gutter="16">
                                    <el-col :span="8">
                                        <el-form-item label="跟进人">
                                            <el-input v-model="customerInfo.followUpUser" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="创建时间">
                                            <el-input v-model="customerInfo.createTime" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="创建人">
                                            <el-input v-model="customerInfo.createUser" />
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-form>
                            <!-- 自定义字段 -->
                            <ShowSaveField :documentKey="customerKey" :type="0" />
                        </div>
                    </el-tab-pane>
                    <el-tab-pane :label="`联系人（${contactList.length}）`">
                        <CustomerContact :contactList="contactList" @onSave="getCustomerInfo" />
                    </el-tab-pane>
                    <el-tab-pane label="公司报告" v-if="false">
                        <CustomerData />
                    </el-tab-pane>
                    <el-tab-pane :label="`订单（${tabDingdan.totalItems}）`" v-if="false">
                        <div>
                            <el-button @click="createNewDingdan" type="primary" icon="el-icon-plus" size="small" style="margin-bottom: 20px">新建</el-button>
                            <el-table :data="tabDingdan.tabledata" :header-cell-style="{ 'background-color': '#F3F3F3', color: '#3A3A3A', 'font-weight': '400' }" :row-style="({ rowIndex }) => (rowIndex % 2 == 1 ? { 'background-color': '#F9FAFC' } : {})" v-loading="tableLoading">
                                <el-table-column type="index" width="50" />
                                <el-table-column prop="orderNo" label="订单编号"></el-table-column>
                                <el-table-column prop="orderName" label="订单名称"></el-table-column>
                                <el-table-column prop="orderDate" label="订单时间"></el-table-column>
                                <el-table-column prop="customerName" label="公司名称"></el-table-column>
                                <el-table-column prop="orderAmount" label="实际金额">
                                    <template slot-scope="scope">
                                        {{scope.row.orderAmount}} {{scope.row.currency}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作">
                                    <template slot-scope="{ row }">
                                        <el-link @click="navigateToDingdanDetail(row)" :underline="false" type="primary">查看更多</el-link>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                        <div style="display: flex; justify-content: flex-end; padding-top: 12px">
                            <el-pagination hide-on-single-page background layout="prev, pager, next" :total="tabDingdan.totalItems" :page-size="10" :current-page="tabDingdan.pageIndex" @current-change="getOrder" />
                        </div>
                    </el-tab-pane>
                    <el-tab-pane :label="`附件（${tabFujian.pagination.total}）`">
                        <div>
                            <el-button @click="$refs.uploadFileInput.click()" type="primary" icon="el-icon-plus" size="small" style="margin-bottom: 20px">上传</el-button>
                            <input type="file" style="display: none" ref="uploadFileInput" @change="handleUploadFileInputChange" />
                            <el-table :data="tabFujian.table.data" :header-cell-style="{ 'background-color': '#F3F3F3', color: '#3A3A3A', 'font-weight': '400' }" :row-style="({ rowIndex }) => (rowIndex % 2 == 1 ? { 'background-color': '#F9FAFC' } : {})">
                                <el-table-column type="index" width="50" />
                                <el-table-column label="文件名" show-overflow-tooltip>
                                    <template slot-scope="{ row }">
                                        <div style="display: flex; align-items: center">
                                            <img :src="row.imageUrl" style="width: 27px; height: 33px; margin-right: 5px" />
                                            <el-link :underline="false" @click="openFileDetail(row)">{{row.name}}</el-link>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="suffix" label="文件类型"></el-table-column>
                                <el-table-column prop="createTime" label="上传日期"></el-table-column>
                                <el-table-column prop="source" label="附件来源"></el-table-column>
                                <el-table-column prop="userCnName" label="上传人">
                                    <template slot-scope="{ row }">
                                        <el-link :underline="false" @click="toStaffInfo(row)">{{row.userCnName}}</el-link>
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作">
                                    <template slot-scope="{ row }">
                                        <div style="display: flex; align-items: center">
                                            <!-- <el-link :underline="false" type="primary" style="margin-right: 20px">查看更多</el-link> -->
                                            <el-link :underline="false" type="danger" @click="deleteFujian(row.key)">删除</el-link>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                        <div style="display: flex; justify-content: flex-end; padding-top: 12px">
                            <el-pagination hide-on-single-page background layout="prev, pager, next" :total="tabFujian.pagination.total" :page-size="tabFujian.pagination.pageSize" :current-page="tabFujian.pagination.pageIndex" @current-change="handleTabFujianPageIndexChange" />
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="收入" v-if="false">
                        <div>
                            <!-- <el-button @click="routerPushShouru" type="primary" icon="el-icon-plus" size="small" style="margin-bottom: 20px">新建</el-button> -->
                            <el-table :data="shouru.gatherList" :header-cell-style="{ 'background-color': '#F3F3F3', color: '#3A3A3A', 'font-weight': '400' }" :row-style="({ rowIndex }) => (rowIndex % 2 == 1 ? { 'background-color': '#F9FAFC' } : {})">
                                <el-table-column prop="collectionNo" label="收汇单号"></el-table-column>
                                <el-table-column prop="payeeCollectionDate" label="收汇日期"></el-table-column>
                                <el-table-column prop="collectionCurrency" label="收汇币种"></el-table-column>
                                <el-table-column prop="payee" label="收款方"></el-table-column>
                                <el-table-column prop="payerContacts" label="付款方联系人"></el-table-column>
                                <el-table-column prop="weCompany" label="我方公司"></el-table-column>
                                <el-table-column prop="collectionAmount" label="收汇金额"></el-table-column>
                                <el-table-column prop="handler" label="经手人"></el-table-column>
                                <el-table-column prop="remarks" label="备注"></el-table-column>
                            </el-table>
                        </div>
                        <div style="display: flex; justify-content: flex-end; padding-top: 12px">
                            <el-pagination hide-on-single-page background layout="prev, pager, next" :total="shouru.pagination.total" :page-size="shouru.pagination.pageSize" :current-page="shouru.pagination.pageIndex" @current-change="getShouru" />
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="支出" v-if="false">
                        <div>
                            <!-- <el-button @click="routerPushZhichu" type="primary" icon="el-icon-plus" size="small" style="margin-bottom: 20px">新建</el-button> -->
                            <el-table :data="zhichu.gatherList" :header-cell-style="{ 'background-color': '#F3F3F3', color: '#3A3A3A', 'font-weight': '400' }" :row-style="({ rowIndex }) => (rowIndex % 2 == 1 ? { 'background-color': '#F9FAFC' } : {})">
                                <el-table-column prop="collectionNo" label="收汇单号"></el-table-column>
                                <el-table-column prop="payeeCollectionDate" label="收汇日期"></el-table-column>
                                <el-table-column prop="collectionCurrency" label="收汇币种"></el-table-column>
                                <el-table-column prop="payee" label="收款方"></el-table-column>
                                <el-table-column prop="payerContacts" label="付款方联系人"></el-table-column>
                                <el-table-column prop="weCompany" label="我方公司"></el-table-column>
                                <el-table-column prop="collectionAmount" label="收汇金额"></el-table-column>
                                <el-table-column prop="handler" label="经手人"></el-table-column>
                                <el-table-column prop="remarks" label="备注"></el-table-column>
                            </el-table>
                        </div>
                        <div style="display: flex; justify-content: flex-end; padding-top: 12px">
                            <el-pagination hide-on-single-page background layout="prev, pager, next" :total="zhichu.pagination.total" :page-size="zhichu.pagination.pageSize" :current-page="zhichu.pagination.pageIndex" @current-change="getZhichu" />
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="系统日志">
                        <el-timeline :reverse="false">
                            <el-timeline-item v-for="(item, index) in operationHistory.data" :key="index" :timestamp="item.inDate"> {{ item.content }}</el-timeline-item>
                        </el-timeline>
                        <el-pagination hide-on-single-page @current-change="handleOperationHistoryPageINdexChange" :total="operationHistory.totalData.length" layout="prev, pager, next" />
                    </el-tab-pane>
                    <el-tab-pane label="海关数据">
                        <CustomsData :companyName="customerInfo.name" />
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>

       

        <!-- 展示文件详情 -->
        <el-drawer title="文件详情" :with-header="false" :visible.sync="fileDetailVisible" size="500px">
            <div class="attachInfoStyle">
                <div class="img">
                    <el-image style="width:150px;" :src="fileDetailInfo.url" :preview-src-list="[fileDetailInfo.url]" />
                </div>
                <el-row class="infoItem">
                    <el-col :span="6">文件名</el-col>
                    <el-col :span="18">{{fileDetailInfo.name}}</el-col>
                </el-row>
                <el-row class="infoItem">
                    <el-col :span="6">文件类型</el-col>
                    <el-col :span="18">{{fileDetailInfo.suffix}}</el-col>
                </el-row>
                <el-row class="infoItem">
                    <el-col :span="6">网络路径</el-col>
                    <el-col :span="18">
                        <span>{{fileDetailInfo.url}}</span>
                        <span class="copy" @click="copyUrl(fileDetailInfo.url)">复制路径</span>
                    </el-col>
                </el-row>
                <el-row class="infoItem">
                    <el-col :span="6">附件来源</el-col>
                    <el-col :span="18">{{fileDetailInfo.source}}</el-col>
                </el-row>
                <el-row class="infoItem">
                    <el-col :span="6">上传用户</el-col>
                    <el-col :span="18">{{fileDetailInfo.userCnName}}</el-col>
                </el-row>
                <el-row class="infoItem">
                    <el-col :span="6">上传时间</el-col>
                    <el-col :span="18">{{fileDetailInfo.createTime}}</el-col>
                </el-row>
            </div>
        </el-drawer>
    </div>
</template>

<script>
import CustomerDynamics from "@/components/CustomerDynamics.vue"; //客户动态
import CustomerData from "@/components/CustomerData.vue"; //客户资料
import AddTagDialog from "@/views/CustomerManage/components/AddTagDialog.vue";
import CustomsData from "@/views/CustomerManage/components/CustomsData.vue"; //海关数据
import CustomerContact from "@/views/CustomerManage/components/CustomerContact.vue"//客户联系人
import { Message } from "element-ui";
//自定义字段
import ShowSaveField from "@/components/ShowSaveField.vue"

const fujianImage = require("@/assets/icon/fujian.png");

export default {
    components: {
        CustomerDynamics,
        CustomerData,
        AddTagDialog,
        CustomsData,
        CustomerContact,
        ShowSaveField,
    },
    props: {
        handleSelect: {
            type: Function,
            default: null,
        },
    },
    data() {
        return {
            customerKey: this.$route.query.customerKey,
            seasGroupOption: [], //公司分组
            statusOption: [], //公司状态选项
            sourceOption: [], //公司来源选项
            stageOption: [], //商机选项
            countryOptions: window.ContryList,
            loading: {
                view: false,
                modifyCustomerInofo: false,
            },
            show: {
                newTag: false,
            },
            //客户信息
            customerInfo: {},




            //联系人信息
            contactList: [],

            operationHistory: {
                data: [],
                pageIndex: 1,
                totalData: [],
            },

            tabFujian: {
                data: [],
                table: {
                    data: [],
                },
                pagination: {
                    total: 0,
                    pageIndex: 1,
                    pageSize: 10,
                },
            },

            //订单列表
            tableLoading: false,
            tabDingdan: {
                tabledata: [],
                totalItems: 0,
                pageIndex: 1,
            },

            zhichu: {
                gatherList: [],
                pagination: {
                    total: 0,
                    pageIndex: 1,
                    pageSize: 10,
                },
            },

            shouru: {
                gatherList: [],
                pagination: {
                    total: 0,
                    pageIndex: 1,
                    pageSize: 10,
                },
            },

            companyScaleValueOptions: [
                {
                    value: 1,
                    name: "少于59人",
                    en_name: "Less than 59 employees",
                },
                {
                    value: 2,
                    name: "60-149人",
                    en_name: "60-149 employees",
                },
                {
                    value: 3,
                    name: "150-499人",
                    en_name: "150-499 employees",
                },
                {
                    value: 4,
                    name: "500-999人",
                    en_name: "500-999 employees",
                },
                {
                    value: 5,
                    name: "1000-4999人",
                    en_name: "1000-4999 employees",
                },
                {
                    value: 6,
                    name: "5000人以上",
                    en_name: "More than 5000 employees",
                },
            ],

            //打开文件详情
            fileDetailVisible: false,
            fileDetailInfo: {},
        };
    },
    methods: {
        
        //查看人员信息
        toStaffInfo(row) {
            let routeData = this.$router.resolve({
                path: "/staff/staffDetails",
                query: { staffkey: row.uKey },
            });
            window.open(routeData.href, "_blank");
        },
        //打开文件详情
        openFileDetail(row) {
            this.fileDetailVisible = true;
            this.fileDetailInfo = row;
        },
        //复制url路径
        copyUrl(url) {
            const input = document.createElement("input");
            document.body.appendChild(input);
            input.setAttribute("value", url);
            input.select();
            if (document.execCommand("copy")) {
                document.execCommand("copy");
            }
            document.body.removeChild(input);
            this.$message({ message: "复制成功", type: "success" });
        },

        /**
         * @description 获取公司信息（包括联系人信息）
         */
        async getCustomerInfo() {
            try {
                const res = await this.$axios({
                    method: "GET",
                    url: this.$customerUrl + "/Company",
                    params: {
                        companykey: this.customerKey,
                    },
                });
                this.contactList = res.data.contacters;//联系人列表
                this.customerInfo = {
                    ...res.data,
                    flagUrl: window.getFlagUrlByCountry
                        ? window.getFlagUrlByCountry(res.data.country)
                        : "",
                };
                document.title = `公海客户详情-${this.customerInfo.name}[${this.customerInfo.country}]`;
            } catch {
                null;
            }
        },
        async modifyCustomerInofo() {
            try {
                this.loading.modifyCustomerInofo = true;
                const res = await this.$axios({
                    method: "POST",
                    url: this.$customerUrl + "/Company/Save",
                    data: {
                        ...this.customerInfo,
                        call: "",
                    },
                });
                if (res.isSuccess) {
                    Message.success("操作成功");
                }
            } catch {
                null;
            } finally {
                this.loading.modifyCustomerInofo = false;
            }
        },
       
       
        
        async getOperationHistroy() {
            try {
                const res = await this.$axios({
                    method: "GET",
                    url: this.$customerUrl + "/Company/Operation/List",
                    params: {
                        companykey: this.customerKey,
                    },
                });
                this.operationHistory.totalData = res.data || [];
                this.handleOperationHistoryPageINdexChange();
            } catch {
                null;
            }
        },
        handleOperationHistoryPageINdexChange(pageIndex = 1) {
            this.operationHistory.data = this.operationHistory.totalData.slice(
                10 * (pageIndex - 1),
                10 * pageIndex
            );
        },
        

        async getZhichu(pageIndex = 1) {
            try {
                const res = await this.$axios({
                    method: "GET",
                    url:
                        this.$orderUrl +
                        "/api/Collection/GetCustomerCollection",
                    params: {
                        customerKey: this.customerKey, //"69779230-64C1-4254-A338-09239194689C"
                        type: 2,
                        pageIndex,
                        pageSize: 10,
                    },
                });
                this.zhichu.gatherList = res.data.datas;
                this.zhichu.pagination.pageIndex = pageIndex;
                this.zhichu.pagination.total = res.data.totalItems;
            } catch {
                null;
            }
        },

        async getShouru(pageIndex = 1) {
            try {
                const res = await this.$axios({
                    method: "GET",
                    url:
                        this.$orderUrl +
                        "/api/Collection/GetCustomerCollection",
                    params: {
                        customerKey: this.customerKey, //"69779230-64C1-4254-A338-09239194689C"
                        type: 1,
                        pageIndex,
                        pageSize: 10,
                    },
                });
                this.shouru.gatherList = res.data.datas;
                this.shouru.pagination.pageIndex = pageIndex;
                this.shouru.pagination.total = res.data.totalItems;
            } catch {
                null;
            }
        },
        async getGroupList() {
            try {
                const res = await this.$axios({
                    method: "GET",
                    url: this.$customerUrl + "/v1/Config/PublicGroup",
                });
                this.seasGroupOption = res.data || [];
            } catch {
                this.seasGroupOption = [];
            }
        },

        async getStatusOption() {
            try {
                const res = await this.$axios({
                    method: "GET",
                    url: this.$customerUrl + "/v1/Config/Statu",
                });
                this.statusOption = res.data || [];
            } catch {
                this.statusOption = [];
            }
        },
        async getSourceOption() {
            try {
                const res = await this.$axios({
                    method: "GET",
                    url: this.$customerUrl + "/v1/Config/Source",
                });
                this.sourceOption = res.data || [];
            } catch {
                this.sourceOption = [];
            }
        },
        async handleTagClose(key) {
            const res = await this.$axios({
                method: "DELETE",
                url: this.$customerUrl + "/Company/RemoveTag",
                params: {
                    key,
                },
            });
            if (res.isSuccess) {
                Message.success("操作成功");
                this.getCustomerInfo();
            }
        },

        //获取客户关联订单
        getOrder(pageIndex = 1) {
            this.tableLoading = true;
            this.$axios.post(`${this.$orderUrl}/api/Order/V2/GetOrders`, {
                customerKey: this.customerKey,
                pageIndex,
                pageSize: 10,
            }).then((res) => {
                const { isSuccess, data } = res;
                this.tableLoading = false;
                if (isSuccess) {
                    this.tabDingdan.tabledata = data.datas;
                    this.tabDingdan.totalItems = data.totalItems;
                    this.tabDingdan.pageIndex = pageIndex;
                }
            });
        },
        navigateToDingdanDetail(row) {
            let routeData = this.$router.resolve({
                path: "/orderManage/orderDetails",
                query: { orderKey: row.uniqueKey },
            });
            window.open(routeData.href, "_blank");
        },
        createNewDingdan() {
            this.handleSelect(`/orderManage/addOrder?customerKey=${this.customerKey}`);
            this.$router.push(`/orderManage/addOrder?customerKey=${this.customerKey}`);
        },

        // routerPushZhichu() {
        //     console.log('新建支出');
        // },
        // routerPushShouru() {
        //     console.log('新建收入');
        // },
        
        handleMoreOperateCommand(command) {
            switch (command) {
                case "加入我的客户":
                    this.addToMine();
                    break;
                default:
                    null;
                    break;
            }
        },
        async addToMine() {
            try {
                this.loading.view = true;
                const res = await this.$axios({
                    method: "GET",
                    url: this.$customerUrl + "/Company/AddPrivate",
                    params: {
                        companykey: this.customerKey,
                    },
                });
                if (res.isSuccess) {
                    Message.success("操作成功");
                    this.$router.push(
                        `/customerManage/customerDetailsPrivate?customerKey=${this.customerKey}`
                    );
                }
            } catch {
                null;
            } finally {
                this.loading.view = false;
            }
        },

        async handleUploadFileInputChange($event) {
            try {
                const file = $event.target.files[0];
                const formData = new FormData();
                formData.append("file", file);
                formData.append("permission", "我的");
                formData.append("remarkName", "");
                formData.append("folderKey", "");
                const data = (
                    await this.$axios({
                        url: `${this.$diskUrl}/File/Upload`,
                        method: "post",
                        data: formData,
                    })
                ).data;
                const res = await this.$axios({
                    method: "POST",
                    url: this.$customerUrl + "/Company/AddAnnex",
                    data: [
                        {
                            companyKey: this.customerKey,
                            name: data.remarkName,
                            url: data.fileUrl,
                            size: data.fileSize,
                        },
                    ],
                });
                if (res.isSuccess) {
                    Message.success("上传成功");
                    this.getFujianList();
                } else {
                    throw new Error();
                }
            } catch {
                Message.error("上传失败");
            }
        },
        async getFujianList() {
            try {
                const res = await this.$axios({
                    method: "GET",
                    url: this.$customerUrl + "/Company/GetAnnex",
                    params: {
                        customerKey: this.customerKey,
                    },
                });
                this.tabFujian.data = res.data.map((item) => ({
                    ...item,
                    imageUrl: this.isImageFile(item.name)
                        ? item.url
                        : fujianImage,
                }));
                this.tabFujian.pagination.total = res.data.length;
                this.handleTabFujianPageIndexChange();
            } catch {
                this.tabFujian.data = [];
            }
        },
        handleTabFujianPageIndexChange(pageIndex = 1) {
            this.tabFujian.table.data = this.tabFujian.data.slice(
                10 * (pageIndex - 1),
                10 * pageIndex
            );
            this.tabFujian.pagination.pageIndex = pageIndex;
        },
        async deleteFujian(key) {
            try {
                const res = await this.$axios({
                    method: "POST",
                    url: this.$customerUrl + "/Company/DeleteAnnex",
                    params: {
                        key,
                    },
                });
                if (res.isSuccess) {
                    Message.success("操作成功");
                    this.getFujianList();
                } else {
                    throw new Error();
                }
            } catch {
                Message.error("操作失败");
            }
        },
        isImageFile(fileName) {
            const dotIndex = fileName.lastIndexOf(".");
            const ext = fileName.slice(dotIndex + 1);
            if (["png", "jpg", "jpeg"].includes(ext)) {
                return true;
            }
            return false;
        },
        async getStageOption() {
            try {
                const res = await this.$axios({
                    method: "GET",
                    url: this.$customerUrl + "/v1/Config/Stage",
                });
                this.stageOption = res.data;
            } catch {
                null;
            }
        },
        
        
    },
    mounted() {
        
        this.getCustomerInfo().then(() => {
            // this.getOrder();
        });
        this.getOperationHistroy();
        this.getFujianList();
        this.getZhichu();
        this.getShouru();

        this.getGroupList();
        this.getStatusOption();
        this.getSourceOption();
        this.getStageOption();
    },
};
</script>

<style lang="scss" scoped>
.customerDetails {
    .topContent {
        background: rgba($color: #ffffff, $alpha: 0.7);
        padding: 20px;
        .basicInfo {
            display: flex;
            position: relative;
            .companyImg {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 70px;
                height: 70px;
                border: 2px solid #d7d7d8;
                text-align: center;
            }
            .companyName {
                line-height: 32px;
                font-size: 20px;
                font-weight: bold;
                color: #383838;
            }
            .country {
                line-height: 24px;
                font-size: 12px;
                color: #909090;
            }
            .website {
                line-height: 28px;
                font-size: 14px;
                color: #383838;
            }
            .rightHandle {
                position: absolute;
                top: 0;
                right: 0;
            }
        }
        .importantInfo {
            margin-top: 10px;
            .label {
                font-size: 14px;
                line-height: 28px;
                color: #5e6d82;
            }
            .value {
                height: 32px;
                line-height: 32px;
            }
        }
        .tagInfo {
            margin-top: 10px;
        }
    }
    .leftContent {
        box-sizing: border-box;
        width: 100%;
        //width: 65%;
        background: rgba($color: #ffffff, $alpha: 0.7);
        padding: 0 20px 20px;
        .contactInfo {
            .item {
                position: relative;
                display: flex;
                margin-bottom: 20px;
                box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
                height: 166px;
                align-items: center;
                background: #fff;
                &.main {
                    background: #fdf5f0;
                    ::v-deep .el-descriptions__body {
                        background: #fdf5f0;
                    }
                }
            }
        }
    }
    .rightContent {
        width: 34%;
        margin-left: 1%;
        background: rgba($color: #ffffff, $alpha: 0.7);
        padding: 0 20px 20px;
        .contactInfo {
            .contactItem {
                background: #f6f6f6;
                border-radius: 2px;
                padding: 10px;
                margin-bottom: 10px;
                font-size: 14px;
                .label {
                    line-height: 28px;
                    color: #c7c7c7;
                }
                .value {
                    line-height: 28px;
                    color: #3a3a3a;
                }
            }
        }
    }
}
.ziliaoTitle {
    height: 20px;
    line-height: 20px;
    font-size: 16px;
    font-weight: bold;
    color: #3A3A3A;
    margin-bottom: 10px;
    border-left: 3px solid var(--themeColor);
    padding-left: 5px;
}
.shoukuan {
    .describe {
        line-height: 28px;
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 10px;
        span {
            padding-left: 20px;
        }
    }
}

// 附件详情
.attachInfoStyle {
    padding: 20px;
    .img {
        margin-bottom: 20px;
        text-align: center;
    }
    .infoItem {
        line-height: 28px;
        font-size: 12px;
        .copy {
            color: var(--themeColor);
            cursor: pointer;
            padding-left: 20px;
        }
    }
}
</style>
