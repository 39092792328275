var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticStyle:{"display":"flex","height":"32px","align-items":"center"}},[_c('span',{staticStyle:{"font-size":"14px","padding-right":"30px"}},[_vm._v("回复/转发前缀:")]),_c('el-radio-group',{model:{value:(_vm.routineInfo.language),callback:function ($$v) {_vm.$set(_vm.routineInfo, "language", $$v)},expression:"routineInfo.language"}},[_c('el-radio',{attrs:{"label":"cn"}},[_vm._v("回复:/转发:")]),_c('el-radio',{attrs:{"label":"en"}},[_vm._v("Re:/Fw:")])],1)],1),_c('div',{staticStyle:{"margin-top":"20px"}},[_c('el-checkbox',{model:{value:(_vm.routineInfo.isAutoReply),callback:function ($$v) {_vm.$set(_vm.routineInfo, "isAutoReply", $$v)},expression:"routineInfo.isAutoReply"}},[_vm._v("启用自动回复")]),_c('div',{staticStyle:{"height":"20px"}}),_c('Editor',{attrs:{"placeholder":"自动回复内容","init":{
            language: 'zh_CN',
            resize: false,
            height: '300px',
            branding: false,
            menubar: false,
            toolbar: 'undo redo | emoticons | image link | formatselect fontsizeselect forecolor | bold underline italic | alignleft aligncenter alignright | preview fullscreen insertVariable',
            plugins: 'emoticons image link preview fullscreen',
            paste_data_images: true,
            urlconverter_callback: _vm.urlconverter_callback,
            images_upload_handler: _vm.images_upload_handler},"tinymceScriptSrc":"tinymce/tinymce.min.js","apiKey":"l6y3tipsjmozdy96gs2qcbjjjaauujp07q4r0rpnyxqjh4ac"},model:{value:(_vm.routineInfo.autoReplyHtml),callback:function ($$v) {_vm.$set(_vm.routineInfo, "autoReplyHtml", $$v)},expression:"routineInfo.autoReplyHtml"}})],1),_c('div',{staticStyle:{"display":"flex","margin-top":"20px","align-items":"center"}},[_c('el-checkbox',{model:{value:(_vm.routineInfo.isAutoReplyDate),callback:function ($$v) {_vm.$set(_vm.routineInfo, "isAutoReplyDate", $$v)},expression:"routineInfo.isAutoReplyDate"}},[_vm._v("在以下时间段内自动回复")]),_c('span',{staticStyle:{"padding":"0 10px"}}),_c('el-date-picker',{attrs:{"disabled":!_vm.routineInfo.isAutoReplyDate,"size":"small","type":"date","value-format":"yyyy-MM-dd","placeholder":"开始日期"},model:{value:(_vm.routineInfo.autoReplyDateStart),callback:function ($$v) {_vm.$set(_vm.routineInfo, "autoReplyDateStart", $$v)},expression:"routineInfo.autoReplyDateStart"}}),_c('span',{staticStyle:{"padding":"0 10px"}}),_c('el-date-picker',{attrs:{"disabled":!_vm.routineInfo.isAutoReplyDate,"size":"small","type":"date","value-format":"yyyy-MM-dd","placeholder":"结束日期"},model:{value:(_vm.routineInfo.autoReplyDateEnd),callback:function ($$v) {_vm.$set(_vm.routineInfo, "autoReplyDateEnd", $$v)},expression:"routineInfo.autoReplyDateEnd"}})],1),_c('div',{staticStyle:{"margin-top":"20px"}},[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":_vm.setRoutine}},[_vm._v("保存设置")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }