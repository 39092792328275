import Vue from 'vue'
import Router from 'vue-router'

import PageFirm from '@/firm/pages/company/App.vue'


import Login from '@/views/LoginNew.vue'    //登录
import ForgotPwd from '@/views/ForgotPwd/Index.vue'     //找回密码
import Register from '@/views/Register.vue'             //注册账号
import HelperInstall from '@/views/HelperInstall.vue'   //企芯助手安装教程
import ClientInstall from '@/views/ClientInstall.vue'   //企芯助手

import CreateCode from '@/views/CreateCode.vue'//创建二维码

import Frame from '@/views/FrameNew.vue'//导航框架

import OldIndex from '@/views/Homepage/Index.vue'                       //工作台(旧版本)
import Workbench from '@/views/Homepage/Workbench.vue'                  //工作台(新版本)
import PersonalInfo from '@/views/Homepage/PersonalInfo.vue'            //个人资料
import LoginLog from '@/views/Homepage/LoginLog.vue'                    //登录日志
import Calendar from '@/views/Homepage/Calendar.vue'                    //日程表
import Multilanguage from '@/views/Homepage/Multilanguage.vue'          //多语种测试
import AdminConfig from '@/views/Homepage/AdminConfig.vue'              //管理员系统配置
import PersonalConfig from '@/views/Homepage/PersonalConfig.vue'        //个人使用配置
import StylePage from '@/views/Homepage/StylePage.vue'                  //个性主题
import ChatAI from '@/views/Homepage/ChatAI.vue'                        //AI聊天

//客户管理
import SeasCustomer from '@/views/CustomerManage/SeasCustomer.vue'      //公海客户
import MyCustomer from '@/views/CustomerManage/MyCustomer.vue'          //我的客户
import PerspCustomer from '@/views/CustomerManage/PerspCustomer.vue'    //透视客户
import CustomerDetailsPublic from '@/views/CustomerManage/CustomerDetailsPublic.vue'//客户详情（公海）
import CustomerDetailsPrivate from '@/views/CustomerManage/CustomerDetailsPrivate.vue'//客户详情（我的）
import Import from '@/views/CustomerManage/Import.vue'                  //导入客户
import Dynamic from '@/views/CustomerManage/Dynamic.vue'                //客户动态
import Recycle from '@/views/CustomerManage/Recycle.vue'                //客户回收站

//订单管理
import OrderList from '@/views/OrderManage/OrderList.vue'               //订单管理
import OrderCollection from '@/views/OrderManage/OrderCollection.vue'   //财务收入
import OrderSpending from '@/views/OrderManage/OrderSpending.vue'       //财务支出
import ProductManage from '@/views/OrderManage/ProductManage.vue'       //产品管理

import AddOrder from '@/views/OrderManage/AddOrder.vue'                 //新建订单
import OrderDetails from '@/views/OrderManage/OrderDetails.vue'         //订单详情
import AddCollection from '@/views/OrderManage/AddCollection.vue'       //新建收入
import CollectionDetails from '@/views/OrderManage/CollectionDetails.vue'   //收入详情
import AddSpending from '@/views/OrderManage/AddSpending.vue'           //新建支出
import SpendingDetails from '@/views/OrderManage/SpendingDetails.vue'   //支出详情

import ProductDetails from '@/views/OrderManage/ProductDetails.vue'     //产品详情
import ProductImport from '@/views/OrderManage/ProductImport.vue'       //产品导入
import GatherResult from '@/views/OrderManage/GatherResult.vue'         //产品采集结果

//报价单管理
import QuotationList from '@/views/Quotation/QuotationList.vue'         //报价单
import NewQuotation from '@/views/Quotation/NewQuotation.vue'           //新建报价单
import QuotationDetails from '@/views/Quotation/QuotationDetails.vue'   //报价单详情
import CustomTemplate from '@/views/Quotation/CustomTemplate.vue'       //自定义模板
import TemplateManage from '@/views/Quotation/TemplateManage.vue'       //报价单模板管理

//采购模块
import PurchaserManage from '@/views/Purchase/PurchaserManage.vue'      //供应商管理
import NewPurchaser from '@/views/Purchase/NewPurchaser.vue'            //新增供应商
import PurchaserDetails from '@/views/Purchase/PurchaserDetails.vue'    //供应商详情
import RecordList from '@/views/Purchase/RecordList.vue'                //采购记录
import NewRecord from '@/views/Purchase/NewRecord.vue'                  //新增采购记录
import RecordDetails from '@/views/Purchase/RecordDetails.vue'          //采购记录详情
import RecordImport from '@/views/Purchase/RecordImport.vue'            //采购记录导入

//BI报表  
import ReportIndex from '@/views/Report/Index.vue'                      //BI报表
import CustomerAnalysis from '@/views/Report/CustomerAnalysis.vue'      //客户分析
import OrderAnalysis from '@/views/Report/OrderAnalysis.vue'            //订单分析
import ProductAnalysis from '@/views/Report/ProductAnalysis.vue'        //产品分析
import StaffAnalysis from '@/views/Report/StaffAnalysis.vue'            //员工分析

//商机模块
//import BusinessManage from '@/views/Business/BusinessManage'            //商机管理(暂存)
//import BusinessDetails from '@/views/Business/BusinessDetails'          //商机详情(暂存)
import BusinessManage from '@/views/Business/Index'
import BusinessDetailPanel from '@/views/Business/components/DetailPanel/IndexPage.vue'

//线索模块
import FindClue from '@/views/ClueManage/FindClue.vue'                  //查找线索
import BuyerClue from '@/views/ClueManage/BuyerClue.vue'                //采购商线索
import BusinessClue from '@/views/ClueManage/BusinessClue.vue'          //工商信息线索
import ExhibitionClue from '@/views/ClueManage/ExhibitionClue.vue'      //展会线索
import CommercialClue from '@/views/ClueManage/CommercialClue.vue'      //商业数据线索
import CustomsClue from '@/views/ClueManage/CustomsClue.vue'            //海关数据线索
import TenderClue from '@/views/ClueManage/TenderClue.vue'              //招标信息线索
import SocialMediaClue from '@/views/ClueManage/SocialMediaClue.vue'    //社媒线索
import ClueDetails from '@/views/ClueManage/ClueDetails.vue'            //线索详情
import Introduce from '@/views/ClueManage/Introduce.vue'                //介绍详情
import WholeSearch from '@/views/ClueManage/WholeSearch.vue'            //全网搜索
import AllClue from '@/views/ClueManage/AllClue.vue'                    //线索搜索
import MyClue from '@/views/ClueManage/MyClue.vue'                      //我的线索
import MyClueDetails from '@/views/ClueManage/MyClueDetails.vue'        //我的线索详情
import ClueImport from '@/views/ClueManage/ClueImport.vue'              //线索导入
import BusinessDataSearch from '@/views/ClueManage/BusinessDataSearch.vue'//商业数据搜索
import BusinessDataDetails from '@/views/ClueManage/BusinessDataDetails.vue'//商业数据详情
import ProductClueSearch from '@/views/ClueManage/ProductClueSearch.vue'    //产品线索搜索
import ProductClueDetails from '@/views/ClueManage/ProductClueDetails.vue'  //产品线索详情
import QichachaDetails from '@/views/ClueManage/QichachaDetails.vue'        //企查查详情

import ChinaSupplierSearch from '@/views/ClueManage/ChinaSupplier/Search.vue'   //中国供应商-搜索
import ChinaSupplierDetails from '@/views/ClueManage/ChinaSupplier/Details.vue' //中国供应商-详情

import SearchMailbox from '@/views/ClueManage/ContactClue/SearchMailbox.vue'    //搜联系人邮箱
import SearchWhatsApp from '@/views/ClueManage/ContactClue/SearchWhatsApp.vue'  //搜联系人WhatsApp
import ContactDetails from '@/views/ClueManage/ContactClue/ContactDetails.vue'  //我的线索-联系人详情

//营销模块
import TaskDashboard from '@/views/Marketing/TaskDashboard.vue'
import Report from '@/views/Marketing/Report/Index.vue'
import New from '@/views/Marketing/New/Index.vue'
import UnsubscribPage from '@/views/Marketing/page/unsubscrib.vue'
import TXRpoert from '@/views/Marketing/Report.vue'
import TXSearch from '@/views/Marketing/Search.vue'
import MailMarketing from '@/views/Marketing/MailMarketing/Index.vue'

//营销模块 (WA)
import TaskDashboardWA from '@/views/MarketingWA/TaskDashboard.vue'
import TaskDashboardPWA from '@/views/MarketingWA/P.vue'
import TaskDashboardMWA from '@/views/MarketingWA/M.vue'
import TaskDashboardTWA from '@/views/MarketingWA/T.vue'
import ReportWA from '@/views/MarketingWA/Report/Index.vue'
import NewWA from '@/views/MarketingWA/New/Index.vue'
import UnsubscribPageWA from '@/views/MarketingWA/page/unsubscrib.vue'
import WATemplate from '@/views/MarketingWA/WATemplate.vue'
import WAMass from '@/views/MarketingWA/WAMass/Index.vue'

//团队管理
import TeamManage from '@/views/TeamManage/Index.vue'

//员工模块
import StaffList from '@/views/Staff/StaffList.vue'         //员工管理
import StaffSearch from '@/views/Staff/StaffSearch.vue'     //员工搜索
import StaffDetails from '@/views/Staff/StaffDetails.vue'   //员工详情
import StaffAudit from '@/views/Staff/StaffAudit.vue'       //员工审核
import AuditDetails from '@/views/Staff/AuditDetails.vue'   //入职审核
import EntryWrite from '@/views/Staff/EntryWrite.vue'       //入职填写
import AddStaff from '@/views/Staff/AddStaff.vue'           //新增员工
import ImportStaff from '@/views/Staff/ImportStaff.vue'     //员工导入

//财务模块
import PerformanceManage from '@/views/Finance/PerformanceManage.vue'   //业绩管理
import ReceiptRecord from '@/views/Finance/ReceiptRecord.vue'           //到账记录

//工单模块
import NewWorkOrder from '@/views/WorkOrder/NewWorkOrder.vue'           //新建工单 
import WorkOrderList from '@/views/WorkOrder/WorkOrderList.vue'         //工单列表 
import WorkOrderDetails from '@/views/WorkOrder/WorkOrderDetails.vue'   //工单详情 

//网盘
import DiskIndex from '@/views/Disk/Index.vue'
//云文档
import CloudDoc from '@/views/CloudDoc/Index.vue'
import FilePreview from '@/views/CloudDoc/FilePreview.vue' //(分享的)文件预览
import FolderPreview from '@/views/CloudDoc/FolderPreview.vue' //(分享的)文件夹预览

//绩效目标
import PerformanceTarget from '@/views/PerformanceTarget.vue'

//邮箱
import MailIndex from '@/views/Mail/Index.vue'

//设置
import SettingIndex from '@/views/Setting/Index.vue'

//采购需求
import PurchaseDemand from '@/views/Homepage/PurchaseDemand.vue'
import PurchaseDetails from '@/views/Homepage/PurchaseDetails.vue'
import PurchaseDetailsTwo from '@/views/Homepage/PurchaseDetailsTwo.vue'

//更新日志
import UpdateLog from '@/views/Homepage/UpdateLog.vue'

// 开放平台模块
import AppManage from '@/views/OpenPlatform/AppManage.vue'      //应用管理
import AppDetails from '@/views/OpenPlatform/AppDetails.vue'    //应用详情
import OpenDoc from '@/views/OpenPlatform/OpenDoc/Index.vue'    //开放文档

//缺省页面
import DefaultOne from '@/views/Defaultpage/DefaultOne.vue'
import DefaultTwo from '@/views/Defaultpage/DefaultTwo.vue'
import DefaultThree from '@/views/Defaultpage/DefaultThree.vue'
import DefaultFour from '@/views/Defaultpage/DefaultFour.vue'
import NoPower from '@/views/Defaultpage/NoPower.vue'
import NoBindEmail from '@/views/Defaultpage/NoBindEmail.vue'
//打印订单
import PrintOrder from '@/views/Defaultpage/PrintOrder.vue'
//跳转到外部网站
import JumpWeb from '@/views/Defaultpage/JumpWeb.vue'

//《服务协议》
import ServiceAgreementCN from '@/views/Agreement/ServiceAgreementCN.vue'
import ServiceAgreementEN from '@/views/Agreement/ServiceAgreementEN.vue'
//《隐私条款》
import PrivacyPolicyCN from '@/views/Agreement/PrivacyPolicyCN.vue'
import PrivacyPolicyEN from '@/views/Agreement/PrivacyPolicyEN.vue'
//支付模块
import SelectProduct from '@/views/Payment/SelectProduct.vue'   //选择产品
import PayforOrder from '@/views/Payment/PayforOrder.vue'       //支付订单
import PurchaseRecord from '@/views/Payment/PurchaseRecord.vue' //购买记录
import PaySuccess from '@/views/Payment/PaySuccess.vue'         //支付成功
//发票模块
import MyInvoice from '@/views/Invoice/MyInvoice.vue'           //我的发票
import AddInvoice from '@/views/Invoice/AddInvoice.vue'         //开发票

// 一键搜(AkeySearch)
import TradeData from '@/views/AkeySearch/TradeData/Index.vue'
import CompanyDetails from '@/views/AkeySearch/CompanyDetails/Index.vue'
import Billoflading from '@/views/AkeySearch/Billoflading/Index.vue'
import Shiplading from '@/views/AkeySearch/Billoflading/Shiplading.vue'

// AI推荐
import RecommendSquare from '@/views/AIRecommend/RecommendSquare.vue'   //推荐广场
import TodayNew from '@/views/AIRecommend/TodayNew.vue'                 //新增推荐
import AIDetails from '@/views/AIRecommend/AIDetails.vue'               //AI详情

import BillofLadingInquire from '@/views/BillofLading/Inquire.vue'      //集装箱订单跟踪
import CantonFair from '@/views/CantonFair/Index.vue' //广交会

import JiFen from '@/views/JiFen/Index.vue'

import PageExcel from '@/views/Excel/Index.vue'
import PageExcelIndex from '@/views/Excel/TestList.vue'

//其他
import GeneratePurchaseRecord from '@/views/OtherPage/GeneratePurchaseRecord.vue'

Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/',
            redirect: 'login'
        }, {
            path: '/firm',
            name: 'Firm',
            component: PageFirm,
            meta: {
                title: '企业资信'
            }
        },
        {
            path: '/login',
            name: 'login',
            component: Login,
            meta: {
                title: '登录'
            }
        },
        {
            path: '/forgotPwd',
            name: 'forgotPwd',
            component: ForgotPwd,
            meta: {
                title: '找回密码'
            }
        },
        {
            path: '/Register',
            name: 'Register',
            component: Register,
            meta: {
                title: '注册账号'
            }
        },
        {
            path: '/HelperInstall',
            name: 'HelperInstall',
            component: HelperInstall,
            meta: {
                title: '企芯助手安装教程'
            }
        },
        {
            path: '/ClientInstall',
            name: 'ClientInstall',
            component: ClientInstall,
            meta: {
                title: '企芯助手'
            }
        },
        {
            path: '/CreateCode',
            name: 'CreateCode',
            component: CreateCode,
            meta: {
                title: '创建二维码'
            }
        },
        {
            path: '/ChatAI',
            name: 'ChatAI',
            component: ChatAI,
            meta: {
                title: 'AI聊天'
            }
        },
        //支付模块
        {
            path: '',
            component: Frame,
            children: [
                {
                    path: '/SelectProduct',
                    name: 'SelectProduct',
                    component: SelectProduct,
                    meta: {
                        title: '选择产品'
                    }
                },
                {
                    path: '/PayforOrder',
                    name: 'PayforOrder',
                    component: PayforOrder,
                    meta: {
                        title: '支付订单'
                    }
                },
                {
                    path: '/PurchaseRecord',
                    name: 'PurchaseRecord',
                    component: PurchaseRecord,
                    meta: {
                        title: '购买记录'
                    }
                },

                {
                    path: '/MyInvoice',
                    name: 'MyInvoice',
                    component: MyInvoice,
                    meta: {
                        title: '我的发票'
                    }
                },
                {
                    path: '/AddInvoice',
                    name: 'AddInvoice',
                    component: AddInvoice,
                    meta: {
                        title: '开发票'
                    }
                },
            ]
        },
        {
            path: '/PaySuccess',
            name: 'PaySuccess',
            component: PaySuccess,
            meta: {
                title: '支付成功'
            }
        },
        //《服务协议》
        {
            path: '/ServiceAgreementCN',
            name: 'ServiceAgreementCN',
            component: ServiceAgreementCN,
            meta: {
                title: '《服务协议》'
            }
        },
        {
            path: '/ServiceAgreementEN',
            name: 'ServiceAgreementEN',
            component: ServiceAgreementEN,
            meta: {
                title: '《服务协议》'
            }
        },
        //《隐私条款》
        {
            path: '/PrivacyPolicyCN',
            name: 'PrivacyPolicyCN',
            component: PrivacyPolicyCN,
            meta: {
                title: '《隐私条款》'
            }
        },
        {
            path: '/PrivacyPolicyEN',
            name: 'PrivacyPolicyEN',
            component: PrivacyPolicyEN,
            meta: {
                title: '《隐私条款》'
            }
        },
        // 首页
        {
            path: '/homepage',
            component: Frame,
            children: [
                {
                    path: 'Index',
                    name: 'Index',
                    component: OldIndex,
                    meta: {
                        title: '工作台'
                    }
                },
                {
                    path: 'Workbench',
                    name: 'Workbench',
                    component: Workbench,
                    meta: {
                        title: '工作台'
                    }
                },
                {
                    path: 'PersonalInfo',
                    name: 'PersonalInfo',
                    component: PersonalInfo,
                    meta: {
                        title: '个人资料'
                    }
                },
                {
                    path: 'LoginLog',
                    name: 'LoginLog',
                    component: LoginLog,
                    meta: {
                        title: '登录日志'
                    }
                },
                {
                    path: 'Calendar',
                    name: 'Calendar',
                    component: Calendar,
                    meta: {
                        title: '日程表'
                    }
                },
                {
                    path: 'Multilanguage',
                    name: 'Multilanguage',
                    component: Multilanguage,
                    meta: {
                        title: '多语种测试'
                    }
                },
                {
                    path: 'AdminConfig',
                    name: 'AdminConfig',
                    component: AdminConfig,
                    meta: {
                        title: '管理员系统配置'
                    }
                },
                {
                    path: 'PersonalConfig',
                    name: 'PersonalConfig',
                    component: PersonalConfig,
                    meta: {
                        title: '个人使用配置'
                    }
                },
                {
                    path: 'StylePage',
                    name: 'StylePage',
                    component: StylePage,
                    meta: {
                        title: '个性主题'
                    }
                },
            ]
        },
        // 客户管理
        {
            path: '/customerManage',
            component: Frame,
            children: [
                {
                    path: 'SeasCustomer',
                    name: 'SeasCustomer',
                    component: SeasCustomer,
                    meta: {
                        title: '客户管理-公海客户'
                    }
                },
                {
                    path: 'MyCustomer',
                    name: 'MyCustomer',
                    component: MyCustomer,
                    meta: {
                        title: '客户管理-我的客户'
                    }
                },
                {
                    path: 'PerspCustomer',
                    name: 'PerspCustomer',
                    component: PerspCustomer,
                    meta: {
                        title: '客户管理-透视客户'
                    }
                },
                {
                    path: 'CustomerDetailsPublic',
                    name: 'CustomerDetailsPublic',
                    component: CustomerDetailsPublic,
                    meta: {
                        title: '客户管理-公海客户详情'
                    }
                },
                {
                    path: 'CustomerDetailsPrivate',
                    name: 'CustomerDetailsPrivate',
                    component: CustomerDetailsPrivate,
                    meta: {
                        title: '客户管理-我的客户详情'
                    }
                },
                {
                    path: 'Import',
                    name: 'Import',
                    component: Import,
                    meta: {
                        title: '客户管理-导入客户'
                    }
                },
                {
                    path: 'Dynamic',
                    name: 'Dynamic',
                    component: Dynamic,
                    meta: {
                        title: '客户管理-客户动态'
                    }
                },
                {
                    path: 'Recycle',
                    name: 'Recycle',
                    component: Recycle,
                    meta: {
                        title: '客户管理-客户回收站'
                    }
                },
            ]
        },
        // 订单管理
        {
            path: '/orderManage',
            component: Frame,
            children: [
                {
                    path: 'OrderList',
                    name: 'OrderList',
                    component: OrderList,
                    meta: {
                        title: '订单管理'
                    }
                },
                {
                    path: 'OrderCollection',
                    name: 'OrderCollection',
                    component: OrderCollection,
                    meta: {
                        title: '财务收入'
                    }
                },
                {
                    path: 'OrderSpending',
                    name: 'OrderSpending',
                    component: OrderSpending,
                    meta: {
                        title: '财务支出'
                    }
                },
                {
                    path: 'ProductManage',
                    name: 'ProductManage',
                    component: ProductManage,
                    meta: {
                        title: '产品管理'
                    }
                },
                {
                    path: 'AddOrder',
                    name: 'AddOrder',
                    component: AddOrder,
                    meta: {
                        title: '新建订单'
                    }
                },
                {
                    path: 'OrderDetails',
                    name: 'OrderDetails',
                    component: OrderDetails,
                    meta: {
                        title: '订单详情'
                    }
                },
                {
                    path: 'AddCollection',
                    name: 'AddCollection',
                    component: AddCollection,
                    meta: {
                        title: '新建收入'
                    }
                },
                {
                    path: 'CollectionDetails',
                    name: 'CollectionDetails',
                    component: CollectionDetails,
                    meta: {
                        title: '收入详情'
                    }
                },
                {
                    path: 'AddSpending',
                    name: 'AddSpending',
                    component: AddSpending,
                    meta: {
                        title: '新建支出'
                    }
                },
                {
                    path: 'SpendingDetails',
                    name: 'SpendingDetails',
                    component: SpendingDetails,
                    meta: {
                        title: '支出详情'
                    }
                },
                {
                    path: 'ProductDetails',
                    name: 'ProductDetails',
                    component: ProductDetails,
                    meta: {
                        title: '产品详情'
                    }
                },
                {
                    path: 'ProductImport',
                    name: 'ProductImport',
                    component: ProductImport,
                    meta: {
                        title: '产品导入'
                    }
                },
                {
                    path: 'GatherResult',
                    name: 'GatherResult',
                    component: GatherResult,
                    meta: {
                        title: '产品采集结果'
                    }
                },
            ]
        },
        // 报价单管理
        {
            path: '/quotation',
            component: Frame,
            children: [
                {
                    path: 'QuotationList',
                    name: 'QuotationList',
                    component: QuotationList,
                    meta: {
                        title: '报价单管理'
                    }
                },
                {
                    path: 'NewQuotation',
                    name: 'NewQuotation',
                    component: NewQuotation,
                    meta: {
                        title: '新建报价单'
                    }
                },
                {
                    path: 'QuotationDetails',
                    name: 'QuotationDetails',
                    component: QuotationDetails,
                    meta: {
                        title: '报价单详情'
                    }
                },
                {
                    path: 'CustomTemplate',
                    name: 'CustomTemplate',
                    component: CustomTemplate,
                    meta: {
                        title: '自定义模板'
                    }
                },
                {
                    path: 'TemplateManage',
                    name: 'TemplateManage',
                    component: TemplateManage,
                    meta: {
                        title: '报价单模板管理'
                    }
                },
            ]
        },
        // 采购模块
        {
            path: '/purchase',
            component: Frame,
            children: [
                {
                    path: 'PurchaserManage',
                    name: 'PurchaserManage',
                    component: PurchaserManage,
                    meta: {
                        title: '供应商管理'
                    }
                },
                {
                    path: 'NewPurchaser',
                    name: 'NewPurchaser',
                    component: NewPurchaser,
                    meta: {
                        title: '新增供应商'
                    }
                },
                {
                    path: 'PurchaserDetails',
                    name: 'PurchaserDetails',
                    component: PurchaserDetails,
                    meta: {
                        title: '供应商详情'
                    }
                },
                {
                    path: 'RecordList',
                    name: 'RecordList',
                    component: RecordList,
                    meta: {
                        title: '采购记录'
                    }
                },
                {
                    path: 'NewRecord',
                    name: 'NewRecord',
                    component: NewRecord,
                    meta: {
                        title: '新增采购记录'
                    }
                },
                {
                    path: 'RecordDetails',
                    name: 'RecordDetails',
                    component: RecordDetails,
                    meta: {
                        title: '采购记录详情'
                    }
                },
                {
                    path: 'RecordImport',
                    name: 'RecordImport',
                    component: RecordImport,
                    meta: {
                        title: '采购记录导入'
                    }
                },
            ]
        },
        // BI报表
        {
            path: '/report',
            component: Frame,
            children: [
                {
                    path: 'index',
                    name: 'index',
                    component: ReportIndex,
                    meta: {
                        title: 'BI报表'
                    }
                },
                {
                    path: 'CustomerAnalysis',
                    name: 'CustomerAnalysis',
                    component: CustomerAnalysis,
                    meta: {
                        title: '客户分析'
                    }
                },
                {
                    path: 'OrderAnalysis',
                    name: 'OrderAnalysis',
                    component: OrderAnalysis,
                    meta: {
                        title: '订单分析'
                    }
                },
                {
                    path: 'ProductAnalysis',
                    name: 'ProductAnalysis',
                    component: ProductAnalysis,
                    meta: {
                        title: '产品分析'
                    }
                },
                {
                    path: 'StaffAnalysis',
                    name: 'StaffAnalysis',
                    component: StaffAnalysis,
                    meta: {
                        title: '员工分析'
                    }
                },
            ]
        },
        // 商机模块
        {
            path: '/business',
            component: Frame,
            children: [
                {
                    path: 'BusinessManage',
                    name: 'BusinessManage',
                    component: BusinessManage,
                    meta: {
                        title: '商机管理'
                    }
                }, {
                    path: 'BusinessDetailPanel',
                    name: 'BusinessDetailPanel',
                    component: BusinessDetailPanel,
                    meta: {
                        title: '商机详情'
                    }
                }
                /* {
                    path: 'BusinessDetails',
                    name: 'BusinessDetails',
                    component: BusinessDetails,
                    meta: {
                        title: '商机详情'
                    }
                }, */
            ]
        },
        // 线索模块
        {
            path: '/clue',
            component: Frame,
            children: [
                {
                    path: 'FindClue',
                    name: 'FindClue',
                    component: FindClue,
                    meta: {
                        title: '查找线索'
                    }
                },
                {
                    path: 'BuyerClue',
                    name: 'BuyerClue',
                    component: BuyerClue,
                    meta: {
                        title: '采购商线索'
                    }
                },
                {
                    path: 'BusinessClue',
                    name: 'BusinessClue',
                    component: BusinessClue,
                    meta: {
                        title: '工商信息线索'
                    }
                },
                {
                    path: 'ExhibitionClue',
                    name: 'ExhibitionClue',
                    component: ExhibitionClue,
                    meta: {
                        title: '展会线索'
                    }
                },
                {
                    path: 'CommercialClue',
                    name: 'CommercialClue',
                    component: CommercialClue,
                    meta: {
                        title: '商业数据线索'
                    }
                },
                {
                    path: 'CustomsClue',
                    name: 'CustomsClue',
                    component: CustomsClue,
                    meta: {
                        title: '海关数据线索'
                    }
                },
                {
                    path: 'TenderClue',
                    name: 'TenderClue',
                    component: TenderClue,
                    meta: {
                        title: '招标信息线索'
                    }
                },
                {
                    path: 'SocialMediaClue',
                    name: 'SocialMediaClue',
                    component: SocialMediaClue,
                    meta: {
                        title: '社媒线索'
                    }
                },
                {
                    path: 'ClueDetails',
                    name: 'ClueDetails',
                    component: ClueDetails,
                    meta: {
                        title: '线索详情'
                    }
                },
                {
                    path: 'Introduce',
                    name: 'Introduce',
                    component: Introduce,
                    meta: {
                        title: '介绍详情'
                    }
                },
                {
                    path: 'WholeSearch',
                    name: 'WholeSearch',
                    component: WholeSearch,
                    meta: {
                        title: '线索模块-全网搜索'
                    }
                },
                {
                    path: 'AllClue',
                    name: 'AllClue',
                    component: AllClue,
                    meta: {
                        title: '线索模块-线索搜索'
                    }
                },
                {
                    path: 'MyClue',
                    name: 'MyClue',
                    component: MyClue,
                    meta: {
                        title: '线索模块-我的线索'
                    }
                },
                {
                    path: 'MyClueDetails',
                    name: 'MyClueDetails',
                    component: MyClueDetails,
                    meta: {
                        title: '我的线索详情'
                    }
                },
                {
                    path: 'ClueImport',
                    name: 'ClueImport',
                    component: ClueImport,
                    meta: {
                        title: '线索导入'
                    }
                },
                {
                    path: 'BusinessDataSearch',
                    name: 'BusinessDataSearch',
                    component: BusinessDataSearch,
                    meta: {
                        title: '线索模块-商业数据搜索'
                    }
                },
                {
                    path: 'BusinessDataDetails',
                    name: 'BusinessDataDetails',
                    component: BusinessDataDetails,
                    meta: {
                        title: '线索模块-商业数据详情'
                    }
                },
                {
                    path: 'ProductClueSearch',
                    name: 'ProductClueSearch',
                    component: ProductClueSearch,
                    meta: {
                        title: '线索模块-产品线索搜索'
                    }
                },
                {
                    path: 'ProductClueDetails',
                    name: 'ProductClueDetails',
                    component: ProductClueDetails,
                    meta: {
                        title: '线索模块-产品线索详情'
                    }
                },
                {
                    path: 'QichachaDetails',
                    name: 'QichachaDetails',
                    component: QichachaDetails,
                    meta: {
                        title: '线索模块-企查查详情'
                    }
                },
                {
                    path: 'ChinaSupplierSearch',
                    name: 'ChinaSupplierSearch',
                    component: ChinaSupplierSearch,
                    meta: {
                        title: '中国供应商-搜索'
                    }
                },
                {
                    path: 'ChinaSupplierDetails',
                    name: 'ChinaSupplierDetails',
                    component: ChinaSupplierDetails,
                    meta: {
                        title: '中国供应商-详情'
                    }
                },
                {
                    path: 'SearchMailbox',
                    name: 'SearchMailbox',
                    component: SearchMailbox,
                    meta: {
                        title: '搜联系人邮箱'
                    }
                },
                {
                    path: 'SearchWhatsApp',
                    name: 'SearchWhatsApp',
                    component: SearchWhatsApp,
                    meta: {
                        title: '搜联系人WhatsApp'
                    }
                },
                {
                    path: 'ContactDetails',
                    name: 'ContactDetails',
                    component: ContactDetails,
                    meta: {
                        title: '我的线索-联系人详情'
                    }
                },
            ]
        },
        // AI推荐
        {
            path: '/AI',
            component: Frame,
            children: [
                {
                    path: 'RecommendSquare',
                    name: 'RecommendSquare',
                    component: RecommendSquare,
                    meta: {
                        title: '推荐广场'
                    }
                },
                {
                    path: 'TodayNew',
                    name: 'TodayNew',
                    component: TodayNew,
                    meta: {
                        title: '新增推荐'
                    }
                },
                {
                    path: 'AIDetails',
                    name: 'AIDetails',
                    component: AIDetails,
                    meta: {
                        title: 'AI详情'
                    }
                },
            ]
        },
        // 营销模块 
        {
            path: '/marketing',
            component: Frame,
            children: [
                {
                    path: 'MailMarketing',
                    name: 'MailMarketing',
                    component: MailMarketing,
                    meta: {
                        title: '邮件营销'
                    }
                },
                {
                    path: 'TaskDashboard',
                    name: 'TaskDashboard',
                    component: TaskDashboard,
                    meta: {
                        title: '营销模块-邮件群发'
                    }
                },
                {
                    path: 'Report',
                    name: 'Report',
                    component: Report,
                    meta: {
                        title: '营销模块-统计报告'
                    }
                },
                {
                    path: 'New',
                    name: 'New',
                    component: New,
                    meta: {
                        title: '营销模块-新建群发'
                    }
                },{
                    path:'TXRpoert',
                    name:'TXRpoert',
                    component:TXRpoert,
                    meta:{
                        title: '营销模块-群发报告'
                    }
                },{
                    path:'TXSearch',
                    name:'TXSearch',
                    component:TXSearch,
                    meta:{
                        title: '营销模块-搜联系人邮箱'
                    }
                }
            ]
        },// 营销模块 (WA)
        {
            path: '/marketingWA',
            component: Frame,
            children: [
                {
                    path: 'WAMass',
                    name: 'WAMass',
                    component: WAMass,
                    meta: {
                        title: 'WhatsApp群发'
                    }
                },
                {
                    path: 'TaskDashboardWA',
                    name: 'TaskDashboardWA',
                    component: TaskDashboardWA,
                    meta: {
                        title: '营销模块-WhatsApp群发任务'
                    }
                },
                {
                    path: 'TaskDashboardPWA',
                    name: 'TaskDashboardPWA',
                    component: TaskDashboardPWA,
                    meta: {
                        title: '营销模块-存档客户'
                    }
                },
                {
                    path: 'TaskDashboardMWA',
                    name: 'TaskDashboardMWA',
                    component: TaskDashboardMWA,
                    meta: {
                        title: '营销模块-消息'
                    }
                },
                {
                    path: 'TaskDashboardTWA',
                    name: 'TaskDashboardTWA',
                    component: TaskDashboardTWA,
                    meta: {
                        title: '营销模块-消息模版配置'
                    }
                },
                {
                    path: 'ReportWA',
                    name: 'ReportWA',
                    component: ReportWA,
                    meta: {
                        title: '营销模块-统计报告'
                    }
                },
                {
                    path: 'NewWA',
                    name: 'NewWA',
                    component: NewWA,
                    meta: {
                        title: '营销模块-新建群发'
                    }
                },
                {
                    path: 'WATemplate',
                    name: 'WATemplate',
                    component: WATemplate,
                    meta: {
                        title: 'WhatsApp消息模版'
                    }
                },
            ]
        },
        // 员工模块
        {
            path: '/staff',
            component: Frame,
            children: [
                {
                    path: 'StaffList',
                    name: 'StaffList',
                    component: StaffList,
                    meta: {
                        title: '员工管理'
                    }
                },
                {
                    path: 'StaffSearch',
                    name: 'StaffSearch',
                    component: StaffSearch,
                    meta: {
                        title: '员工搜索'
                    }
                },
                {
                    path: 'StaffDetails',
                    name: 'StaffDetails',
                    component: StaffDetails,
                    meta: {
                        title: '员工详情'
                    }
                },
                {
                    path: 'StaffAudit',
                    name: 'StaffAudit',
                    component: StaffAudit,
                    meta: {
                        title: '员工审核'
                    }
                },
                {
                    path: 'AuditDetails',
                    name: 'AuditDetails',
                    component: AuditDetails,
                    meta: {
                        title: '入职审核'
                    }
                },
                {
                    path: 'AddStaff',
                    name: 'AddStaff',
                    component: AddStaff,
                    meta: {
                        title: '新增员工'
                    }
                },
                {
                    path: 'ImportStaff',
                    name: 'ImportStaff',
                    component: ImportStaff,
                    meta: {
                        title: '员工导入'
                    }
                },
            ]
        },
        //财务模块
        {
            path: '/finance',
            component: Frame,
            children: [
                {
                    path: 'PerformanceManage',
                    name: 'PerformanceManage',
                    component: PerformanceManage,
                    meta: {
                        title: '业绩管理'
                    }
                },
                {
                    path: 'ReceiptRecord',
                    name: 'ReceiptRecord',
                    component: ReceiptRecord,
                    meta: {
                        title: '到账记录'
                    }
                },
            ]
        },
        // 工单模块
        {
            path: '/workOrder',
            component: Frame,
            children: [
                {
                    path: 'NewWorkOrder',
                    name: 'NewWorkOrder',
                    component: NewWorkOrder,
                    meta: {
                        title: '新建工单'
                    }
                },
                {
                    path: 'WorkOrderList',
                    name: 'WorkOrderList',
                    component: WorkOrderList,
                    meta: {
                        title: '工单列表'
                    }
                },
                {
                    path: 'WorkOrderDetails',
                    name: 'WorkOrderDetails',
                    component: WorkOrderDetails,
                    meta: {
                        title: '工单详情'
                    }
                },
            ]
        },
        //其他
        {
            path: '/',
            component: Frame,
            children: [
                {
                    path: 'TeamManage',
                    name: 'TeamManage',
                    component: TeamManage,
                    meta: {
                        title: '团队管理'
                    }
                },
                {
                    path: 'DiskIndex',
                    name: 'DiskIndex',
                    component: DiskIndex,
                    meta: {
                        title: '网盘'
                    }
                },
                {
                    path: 'CloudDoc',
                    name: 'CloudDoc',
                    component: CloudDoc,
                    meta: {
                        title: '云文档'
                    }
                },
                {
                    path: 'PerformanceTarget',
                    name: 'PerformanceTarget',
                    component: PerformanceTarget,
                    meta: {
                        title: '绩效目标'
                    }
                },
                {
                    path: 'MailIndex',
                    name: 'MailIndex',
                    component: MailIndex,
                    meta: {
                        title: '邮箱'
                    }
                },
                {
                    path: 'SettingIndex',
                    name: 'SettingIndex',
                    component: SettingIndex,
                    meta: {
                        title: '设置'
                    }
                },
                {
                    path: 'PurchaseDemand',
                    name: 'PurchaseDemand',
                    component: PurchaseDemand,
                    meta: {
                        title: '采购需求'
                    }
                },
                {
                    path: 'PurchaseDetails',
                    name: 'PurchaseDetails',
                    component: PurchaseDetails,
                    meta: {
                        title: '采购详情'
                    }
                },
                {
                    path: 'PurchaseDetailsTwo',
                    name: 'PurchaseDetailsTwo',
                    component: PurchaseDetailsTwo,
                    meta: {
                        title: '采购详情'
                    }
                },
                {
                    path: '/BillofLadingInquire',
                    name: 'BillofLadingInquire',
                    component: BillofLadingInquire,
                    meta: {
                        title: '集装箱订单跟踪'
                    }
                },
                {
                    path: '/CantonFair',
                    name: 'CantonFair',
                    component: CantonFair,
                    meta: {
                        title: '广交会'
                    }
                },
                {
                    path: 'UpdateLog',
                    name: 'UpdateLog',
                    component: UpdateLog,
                    meta: {
                        title: '更新日志'
                    }
                },
                {
                    path: '/NoPower',
                    name: 'NoPower',
                    component: NoPower,
                    meta: {
                        title: '暂无权限'
                    }
                },
                {
                    path: '/NoBindEmail',
                    name: 'NoBindEmail',
                    component: NoBindEmail,
                    meta: {
                        title: '暂无邮箱'
                    }
                },
            ]
        },
        //开放平台模块
        {
            path: '/AppManage',
            name: 'AppManage',
            component: AppManage,
            meta: {
                title: '应用管理'
            }
        },
        {
            path: '/AppDetails',
            name: 'AppDetails',
            component: AppDetails,
            meta: {
                title: '应用详情'
            }
        },
        {
            path: '/OpenDoc',
            name: 'OpenDoc',
            component: OpenDoc,
            meta: {
                title: '开放文档'
            }
        },
        //缺省页面
        {
            path: '/DefaultOne',
            name: 'DefaultOne',
            component: DefaultOne,
        },
        {
            path: '/DefaultTwo',
            name: 'DefaultTwo',
            component: DefaultTwo,
        },
        {
            path: '/DefaultThree',
            name: 'DefaultThree',
            component: DefaultThree,
        },
        {
            path: '/DefaultFour',
            name: 'DefaultFour',
            component: DefaultFour,
        },
        
        //打印订单
        {
            path: '/PrintOrder',
            name: 'PrintOrder',
            component: PrintOrder,
            meta: {
                title: '打印订单'
            }
        },
        {
            path: '/JumpWeb',
            name: 'JumpWeb',
            component: JumpWeb,
            meta: {
                title: '跳转到外部网站'
            }
        },
        {
            path: '/EntryWrite',
            name: 'EntryWrite',
            component: EntryWrite,
            meta: {
                title: '入职填写'
            }
        },
        {
            path: '/page/UnsubscribPage',
            name: 'UnsubscribPage',
            component: UnsubscribPage,
            meta: {
                title: '退订'
            }
        },
        {
            path: '/page/UnsubscribPageWA',
            name: 'UnsubscribPageWA',
            component: UnsubscribPageWA,
            meta: {
                title: '退订'
            }
        },

        // 一键搜(AkeySearch)
        {
            path: '/',
            component: Frame,
            children: [
                {
                    path: '/AkeySearch/TradeData',
                    name: 'TradeData',
                    component: TradeData,
                    meta: {
                        title: '贸易数据'
                    }
                },
                {
                    path: '/AkeySearch/CompanyDetails',
                    name: 'CompanyDetails',
                    component: CompanyDetails,
                    meta: {
                        title: '公司详情'
                    }
                },
                {
                    path: '/AkeySearch/Billoflading',
                    name: 'Billoflading',
                    component: Billoflading,
                    meta: {
                        title: '提单详情'
                    }
                },
                {
                    path: '/AkeySearch/Shiplading',
                    name: 'Shiplading',
                    component: Shiplading,
                    meta: {
                        title: '船运提单'
                    }
                },
            ]
        },
        {
            path: '/FilePreview',
            name: 'FilePreview',
            component: FilePreview,
            meta: {
                title: '文件预览'
            }
        },
        {
            path: '/FolderPreview',
            name: 'FolderPreview',
            component: FolderPreview,
            meta: {
                title: '文件夹预览'
            }
        },
        {
            path: '/GeneratePurchaseRecord',
            name: 'GeneratePurchaseRecord',
            component: GeneratePurchaseRecord,
            meta: {
                title: '生成采购记录'
            }
        },
        {
            path: '/JiFen',
            name: 'JiFen',
            component: JiFen,
            meta: {
                title: '积分'
            }
        },
        {
            path: '/Excel',
            name: 'Excel',
            component: PageExcel,
            meta: 'Excel'
        },
        {
            path: '/ExcelList',
            name: 'ExcelList',
            component: PageExcelIndex,
            meta: 'ExcelList'
        }
    ]
})
