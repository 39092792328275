<template>
    <div>
        <InterfaceName :interfaceName="interfaceName" />
        <InterfaceSpe :requestUrl="requestUrl" :requestMethod="requestMethod" />
        <InputParam :inputTableData="inputTableData" />
        <ReturnParam :returnObj="returnObj" />
    </div>
</template>

<script>
import InterfaceName from "../Acomponents/InterfaceName.vue";
import InterfaceSpe from "../Acomponents/InterfaceSpe.vue";
import InputParam from "../Acomponents/InputParam.vue";
import ReturnParam from "../Acomponents/ReturnParam.vue";

export default {
    components: { InterfaceName, InterfaceSpe, InputParam, ReturnParam },
    data() {
        return {
            interfaceName: "获取我的客户列表",
            requestUrl: "https://crm.qixinerp.com/open/Customer/MyCustomerList",
            requestMethod: "POST",
            inputTableData: [],
            returnObj: {},
        };
    },
    methods: {},
    mounted() {
        this.inputTableData = [
            {paramName: "privateGroupKey", valueType: "string", explain: "私海组", required: false},
            {paramName: "publicGroupKey", valueType: "string", explain: "公海组", required: false},
            {paramName: "statuKey", valueType: "string", explain: "状态", required: false},
            {paramName: "name", valueType: "string", explain: "公司名称", required: false},
            {paramName: "mainIndustryValue", valueType: "string", explain: "行业", required: false},
            {paramName: "mainBusinessTypeValue", valueType: "string", explain: "商业类型", required: false},
            {paramName: "companyScaleValue", valueType: "string", explain: "规模", required: false},
            {paramName: "country", valueType: "string", explain: "国家", required: false},
            {paramName: "star", valueType: "integer", explain: "星级", required: false},
            {paramName: "phone", valueType: "string", explain: "手机号", required: false},
            {paramName: "lastFollow", valueType: "string", explain: "7天未更进(0) 15天未更进(1) 一月未更进(2) 一年未更进(3) 更久未更进(4) 半年未更进(5) 一年未更进(6)", required: false},
            {paramName: "sourceKey", valueType: "string", explain: "来源", required: false},
            {paramName: "sort", valueType: "string", explain: "排序 最新更进排序(0) 星级排序(1) 创建时间排序(2) 评分排序(3)", required: false},
            {paramName: "isAttention", valueType: "boolean", explain: "是否关注", required: false},
            {paramName: "tagFilter", valueType: "array", explain: "标签", required: false},
            {paramName: "contactsName", valueType: "string", explain: "联系人名称", required: false},
            {paramName: "contactsEmail", valueType: "string", explain: "联系人邮箱", required: false},
            {paramName: "contactsCall", valueType: "string", explain: "联系人手机号", required: false},
            {paramName: "contactsNote", valueType: "string", explain: "联系人备注", required: false},
            {paramName: "isPreciseSearch", valueType: "boolean", explain: "是否精确搜索", required: false},
            {paramName: "pageIndex",valueType: "integer",explain: "当前页码",required: true},
            {paramName: "pageSize",valueType: "integer",explain: "每页条数",required: true},
        ];
        this.returnObj = {
            "isSuccess": true,
            "errorMessage": null,
            "data": {
                "datas": [
                    {
                        "companyKey": "1382994122284bc8a5d77f0fed4f87b2",
                        "no": "CBS095ae0",
                        "companyName": "PRIME CHAMP PRODUCTION B.V.",
                        "companyNickName": null,
                        "source": "电商平台",
                        "mainIndustryValue": null,
                        "mainBusinessTypeValue": null,
                        "annualPurchaseAmount": 0,
                        "companyScaleValue": "少于59人",
                        "star": 0,
                        "statuName": "无",
                        "webSite": "primechamp.com",
                        "fax": null,
                        "call": "",
                        "remarks": null,
                        "country": "荷兰",
                        "timeZone": null,
                        "nickname": null,
                        "email": null,
                        "position": null,
                        "phone": null,
                        "twitter": null,
                        "linkedin": null,
                        "birthday": "0001-01-01T00:00:00",
                        "gender": null,
                        "note": null,
                        "followUpUser": "彭贤鑫",
                        "originalFollowUpUser": null,
                        "lastContactTime": "",
                        "lstFollowUpContent": null,
                        "lastPublicTime": "0001-01-01T00:00:00",
                        "publicNumber": 0,
                        "nextFollowUpTime": "0001-01-01T00:00:00",
                        "groupName": "潜在D类客户",
                        "createCompanyKey": null,
                        "createUserKey": "af19a3cbd7094bafa19e629b04b482ae",
                        "score": 60,
                        "tradeType": "未知",
                        "isTop": false,
                        "isFollow": true,
                        "tags": [
                            {
                                "companyKey": "1382994122284bc8a5d77f0fed4f87b2",
                                "key": "eb94f1d693a349779fe7ecb8b3f2d3f2",
                                "name": "家用电器",
                                "color": "#1890ff"
                            },
                            {
                                "companyKey": "1382994122284bc8a5d77f0fed4f87b2",
                                "key": "138fe056efac445cab54400db7719c4e",
                                "name": "医药",
                                "color": "#52c41a"
                            }
                        ],
                        "slightCollisionRisk": [],
                        "seriousCollisionRisk": [],
                        "contactsKey": null,
                        "contactNotes": null,
                        "createTime": "2022-05-20 17:51:35",
                        "createUser": "彭贤鑫"
                    },
                ],
                "pageSize": 10,
                "pageIndex": 1,
                "totalItems": 1
            },
        }
    },
};
</script>

<style lang="scss" scoped>
</style>