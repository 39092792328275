<template>
    <div class="viewBox">
        <div class="header">
            开放文档
            <el-button type="primary" size="small" style="float:right;margin-top:10px;" @click="toAppManage">应用管理</el-button>
        </div>
        <div class="leftAside">
            <el-menu @select="handleSelect" background-color="#FAFBFC" text-color="#333333" active-text-color="#333333" default-active="AuthToken">
                <el-submenu v-for="subMenu of menuItems" :key="subMenu.id" :index="subMenu.id">
                    <template slot="title">{{ subMenu.label }}</template>
                    <el-menu-item :class="{ active: currentActive === item.id }" v-for="item of subMenu.children" :key="item.id" :index="item.id">
                        {{ item.label }}
                    </el-menu-item>
                </el-submenu>
            </el-menu>
        </div>
        <div class="rightContent">
            <component :is="currentActive" />
        </div>
    </div>
</template>
<script>
//授权--Authorize
import AuthToken from './Authorize/AuthToken.vue'
import PushCheck from './Authorize/PushCheck.vue'
//客户--Customer
import GetPrivateGroup from './Customer/GetPrivateGroup.vue'
import GetPublicGroup from './Customer/GetPublicGroup.vue'
import GetCustomerSource from './Customer/GetCustomerSource.vue'
import GetCustomerStatu from './Customer/GetCustomerStatu.vue'
import GetCompanySize from './Customer/GetCompanySize.vue'
import SaveCustomer from './Customer/SaveCustomer.vue'
import GetPublicCustomerList from './Customer/GetPublicCustomerList.vue'
import GetMyCustomerList from './Customer/GetMyCustomerList.vue'
import GetCustomerDetail from './Customer/GetCustomerDetail.vue'
import AddMyCustomer from './Customer/AddMyCustomer.vue'
import RemoveMyCustomer from './Customer/RemoveMyCustomer.vue'
import AddFollowRecord from './Customer/AddFollowRecord.vue'
import GetCustomerContacts from './Customer/GetCustomerContacts.vue'
import GetCustomerOrders from './Customer/GetCustomerOrders.vue'
import GetCustomerDynamic from './Customer/GetCustomerDynamic.vue'
import GetCustomerPay from './Customer/GetCustomerPay.vue'
import GetCustomerIncome from './Customer/GetCustomerIncome.vue'
//邮箱--Mailbox
import BindEmail from './Mailbox/BindEmail.vue'
import DelBindEMail from './Mailbox/DelBindEMail.vue'
import GetEmails from './Mailbox/GetEmails.vue'
import PreviewMail from './Mailbox/PreviewMail.vue'
//产品--Product
import ProductGroupSave from './Product/ProductGroupSave.vue'
import ProductGroupList from './Product/ProductGroupList.vue'
import ProductList from './Product/ProductList.vue'
import ProductSave from './Product/ProductSave.vue'
import ProductDel from './Product/ProductDel.vue'
import ProductDetail from './Product/ProductDetail.vue'

//订单--Order
import OrderSave from './Order/OrderSave.vue'
import OrderList from './Order/OrderList.vue'
import OrderDetail from './Order/OrderDetail.vue'
import OrderDel from './Order/OrderDel.vue'

//员工--Staff
import GetStaff from './Staff/GetStaff.vue'
import GetLoginLog from './Staff/GetLoginLog.vue'
import GetOrganization from './Staff/GetOrganization.vue'
import BatchCreateStaff from './Staff/BatchCreateStaff.vue'
import UpdateUser from './Staff/UpdateUser.vue'

//日程--Schedule

//采购--Purchase
import AddPurchaseDemand from './Purchase/AddPurchaseDemand.vue'
import InformationPush from './Purchase/InformationPush.vue'
import UploadFile from './Purchase/UploadFile.vue' 
import SaveSupplier from './Purchase/SaveSupplier.vue'
import DelSupplier from './Purchase/DelSupplier.vue'
import SupplierList from './Purchase/SupplierList.vue'
import SupplierDetail from './Purchase/SupplierDetail.vue'
import SupplierContactsBox from './Purchase/SupplierContactsBox.vue'
import SavePurchasingRecord from './Purchase/SavePurchasingRecord.vue'
import DelPurchasingRecord from './Purchase/DelPurchasingRecord.vue'
import PurchasingRecordList from './Purchase/PurchasingRecordList.vue'
import PurchasingRecordDetail from './Purchase/PurchasingRecordDetail.vue'
export default {
    components: { 
        //授权--Authorize
        AuthToken,PushCheck,
        //客户--Customer
        GetPrivateGroup,GetPublicGroup,GetCustomerSource,GetCustomerStatu,GetCompanySize,
        SaveCustomer,GetPublicCustomerList,GetMyCustomerList,GetCustomerDetail,AddMyCustomer,RemoveMyCustomer,
        AddFollowRecord,GetCustomerContacts,GetCustomerOrders,GetCustomerDynamic,GetCustomerPay,GetCustomerIncome,
        //邮箱--Mailbox
        BindEmail,DelBindEMail,GetEmails,PreviewMail,
        //产品--Product
        ProductGroupSave,ProductGroupList,ProductList,ProductSave,ProductDel,ProductDetail,
        //订单--Order
        OrderSave,OrderList,OrderDetail,OrderDel,
        //员工--Staff
        GetStaff,GetLoginLog,GetOrganization,BatchCreateStaff,UpdateUser,
        //采购--Purchase
        AddPurchaseDemand,InformationPush,UploadFile,
        SaveSupplier,DelSupplier,SupplierList,SupplierDetail,SupplierContactsBox,
        SavePurchasingRecord,DelPurchasingRecord,PurchasingRecordList,PurchasingRecordDetail,
    },
    data() {
        return {
            currentActive: "AuthToken",
            menuItems: [
                {
                    id: "1",
                    label: "授权",
                    children: [
                        {
                            id: "AuthToken",
                            label: "授权获取token",
                        },
                        {
                            id: "PushCheck",
                            label: "数据推送验签机制",
                        },
                    ],
                },
                {
                    id: "2",
                    label: "客户",
                    children: [
                        {
                            id: "GetPrivateGroup",
                            label: "获取私海分组",
                        },
                        {
                            id: "GetPublicGroup",
                            label: "获取公海分组",
                        },
                        {
                            id: "GetCustomerSource",
                            label: "获取客户来源",
                        },
                        {
                            id: "GetCustomerStatu",
                            label: "获取客户状态",
                        },
                        {
                            id: "GetCompanySize",
                            label: "获取客户规模",
                        },
                        {
                            id: "SaveCustomer",
                            label: "保存客户信息",
                        },
                        {
                            id: "GetPublicCustomerList",
                            label: "获取公海客户列表",
                        },
                        {
                            id: "GetMyCustomerList",
                            label: "获取我的客户列表",
                        },
                        {
                            id: "GetCustomerDetail",
                            label: "获取客户详情",
                        },
                        {
                            id: "AddMyCustomer",
                            label: "添加到我的客户",
                        },
                        {
                            id: "RemoveMyCustomer",
                            label: "我的客户移除到公海",
                        },
                        {
                            id: "AddFollowRecord",
                            label: "添加跟进记录",
                        },
                        {
                            id: "GetCustomerContacts",
                            label: "获取客户联系人",
                        },
                        {
                            id: "GetCustomerOrders",
                            label: "获取客户订单",
                        },
                        {
                            id: "GetCustomerDynamic",
                            label: "获取客户动态",
                        },
                        {
                            id: "GetCustomerPay",
                            label: "获取客户支出",
                        },
                        {
                            id: "GetCustomerIncome",
                            label: "获取客户收入",
                        },
                    ],
                },
                {
                    id: "3",
                    label: "产品",
                    children: [
                        {
                            id: "ProductGroupSave",
                            label: "保存产品分组",
                        },
                        {
                            id: "ProductGroupList",
                            label: "获取产品分组",
                        },
                        {
                            id: "ProductList",
                            label: "获取产品列表",
                        },
                        {
                            id: "ProductSave",
                            label: "保存产品",
                        },
                        {
                            id: "ProductDel",
                            label: "删除产品",
                        },
                        {
                            id: "ProductDetail",
                            label: "获取产品详情",
                        },
                    ],
                },
                {
                    id: "4",
                    label: "订单",
                    children: [
                        {
                            id: "OrderSave",
                            label: "保存订单",
                        },
                        {
                            id: "OrderList",
                            label: "获取我的订单",
                        },
                        {
                            id: "OrderDetail",
                            label: "查询订单详情",
                        },
                        {
                            id: "OrderDel",
                            label: "删除订单",
                        }
                    ],
                },
                {
                    id: "5",
                    label: "采购",
                    children: [
                        {
                            id: "AddPurchaseDemand",
                            label: "添加采购需求",
                        },
                        {
                            id: "InformationPush",
                            label: "供应商信息推送",
                        },
                        // {
                        //     id: "UploadFile",
                        //     label: "上传文件",
                        // },
                        {
                            id: "SaveSupplier",
                            label: "保存供应商",
                        },
                        {
                            id: "DelSupplier",
                            label: "删除供应商",
                        },
                        {
                            id: "SupplierList",
                            label: "获取供应商列表",
                        },
                        {
                            id: "SupplierDetail",
                            label: "获取供应商详情",
                        },
                        {
                            id: "SupplierContactsBox",
                            label: "获取供应商联系人下拉框",
                        },
                        {
                            id: "SavePurchasingRecord",
                            label: "保存采购记录",
                        },
                        {
                            id: "DelPurchasingRecord",
                            label: "删除采购记录",
                        },
                        {
                            id: "PurchasingRecordList",
                            label: "获取采购记录列表",
                        },
                        {
                            id: "PurchasingRecordDetail",
                            label: "获取采购记录详情",
                        },
                    ],
                },
                // {
                //     id: "6",
                //     label: "邮箱",
                //     children: [
                //         {
                //             id: "BindEmail",
                //             label: "绑定邮箱",
                //         },
                //         {
                //             id: "DelBindEMail",
                //             label: "删除绑定邮箱",
                //         },
                //         {
                //             id: "GetEmails",
                //             label: "查询用户邮件",
                //         },
                //         {
                //             id: "PreviewMail",
                //             label: "预览邮件内容",
                //         },
                //     ],
                // },
                // {
                //     id: "7",
                //     label: "员工",
                //     children: [
                //         {
                //             id: "GetStaff",
                //             label: "获取用户列表",
                //         },
                //         {
                //             id: "GetLoginLog",
                //             label: "获取登陆日志",
                //         },
                //         {
                //             id: "GetOrganization",
                //             label: "获取组织结构树",
                //         },
                //         {
                //             id: "BatchCreateStaff",
                //             label: "批量创建员工",
                //         },
                //         {
                //             id: "UpdateUser",
                //             label: "更新员工信息",
                //         },
                //     ],
                // }
                {
                    id: "8",
                    label: "上传文件",
                    children: [
                        {
                            id: "UploadFile",
                            label: "上传文件",
                        },
                    ],
                }
            ],
        };
    },
    methods: {
        handleSelect(item){
            // console.log(item);
            this.currentActive = item;
        },

        toAppManage(){
            let routeData = this.$router.resolve({
                path: "/appManage",
            });
            window.open(routeData.href, "_blank");             
        }
    },
    mounted() {
        
    },
};
</script>

<style lang="scss" scoped>

.viewBox {
    width: 1200px;
    margin: auto;
    overflow: hidden;
    .header{
        font-weight: bold;
        font-size: 30px;
        height: 60px;
        line-height: 60px;
        padding-left: 50px;
        background-color: #FAFBFC;
    }
    .leftAside{
        float: left;
        width: 300px;
        background-color: #FAFBFC;
        height: calc(100vh - 60px);
        overflow: auto;
    }
    .rightContent{
        float: right;
        width: 900px;
        height: calc(100vh - 60px);
        overflow: auto;
        padding: 20px;
        box-sizing: border-box;
    }
    .el-menu{
        font-size: 14px;
        margin-left: 30px;
        border-right: none;
        .el-menu-item {
            &.active {
                background: #e5e9f2 !important;
                border-radius: 8px 0px 0px 8px;
            }
        }
        .el-menu-item, /deep/.el-submenu__title {
            height: 40px;
            line-height: 40px;
            &:hover{
                background: #e5e9f2 !important;
                border-radius: 8px 0px 0px 8px;
            }
        }
    }
}
</style>