<template>
<div>
    <div v-if="ie=='i'">
        <!-- 刚果(金)(COD)进口提单明细 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th width="25%">分单数</th>
                        <td width="25%">{{billInfo.ItemNumber}}</td>
                        <th width="25%">数量单位</th>
                        <td width="25%">{{billInfo.QuantityUnit}}</td>
                    </tr>
                    <tr>
                        <th>净重</th>
                        <td>{{billInfo.NetWeight}}</td>
                        <th>净重单位</th>
                        <td>{{billInfo.NetWeightUnit}}</td>
                    </tr>
                    <tr>
                        <th>商品发票金额</th>
                        <td>{{billInfo.Value}}</td>
                        <th>币种</th>
                        <td>{{billInfo.Currency}}</td>
                    </tr>
                    <tr>
                        <th>原产国</th>
                        <td>{{billInfo.Country}}</td>
                        <th>货源国</th>
                        <td>{{billInfo.SourceCountry}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
    <div v-if="ie=='e'">
        <!-- 刚果(金)(COD)出口提单明细 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th width="25%">分单数</th>
                        <td width="25%">{{billInfo.ItemNumber}}</td>
                        <th width="25%">数量单位</th>
                        <td width="25%">{{billInfo.QuantityUnit}}</td>
                    </tr>
                    <tr>
                        <th>净重</th>
                        <td>{{billInfo.NetWeight}}</td>
                        <th>净重单位</th>
                        <td>{{billInfo.NetWeightUnit}}</td>
                    </tr>
                    <tr>
                        <th>商品发票金额</th>
                        <td>{{billInfo.Value}}</td>
                        <th>币种</th>
                        <td>{{billInfo.Currency}}</td>
                    </tr>
                    <tr>
                        <th>目的国</th>
                        <td>{{billInfo.Country}}</td>
                        <th>出口商所在国</th>
                        <td>{{billInfo.ExportCountry}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
</div>
</template>

<script>
export default {
    props:["billInfo","ie"],
    data() {
        return {

        };
    },
    methods: {
        
    },
    mounted() {

    },
};
</script>

<style lang="scss" scoped>
.partItem {
    margin-bottom: 20px;
    /deep/.el-card__header {
        padding: 10px 20px;
        .headTitle {
            display: inline-block;
            padding-left: 10px;
            font-size: 14px;
            border-left: 2px solid var(--themeColor);
            font-weight: bold;
        }
    }
}
table {
    width: 100%;
    border-collapse: collapse;
    tr {
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;
        th,
        td {
            font-size: 12px;
            color: #666;
            height: 40px;
            border: 1px solid #eaeaea;
            text-align: center;
        }
        th {
            background-color: #f5f7fa;
            font-weight: 700;
        }
        .bg {
            background-color: var(--themeColor);
            color: #fff;
            font-weight: 700;
            opacity: 0.8;
        }
    }
}
</style>