<template>
    <div class="detailBox" v-loading="infoLoading">
        <div class="cardBox">
            <div class="header">
                <span class="title">联系人信息</span>
                <el-button type="primary" @click="toEdit(detailInfo)" size="small">编辑</el-button>
            </div>
            <div style="margin-bottom: 8px;">
                <div class="linkmanName">{{detailInfo.name || "——"}}</div>
                <div class="country">
                    <img :src="detailInfo.flagUrl" style="margin-right:5px;height:14px" />
                    <span>{{detailInfo.country}}</span>
                </div>
            </div>
            <div style="display:flex;">
                <div class="twoField">
                    <span class="color99">邮箱：</span>
                    <span class="color3a">{{detailInfo.email || "--"}}</span>
                </div>
                <div class="twoField">
                    <span class="color99">手机号：</span>
                    <span class="color3a">{{detailInfo.tel || "--"}}</span>
                </div>
                <div class="twoField">
                    <span class="color99">性别：</span>
                    <span class="color3a">{{detailInfo.sexStr || "--"}}</span>
                </div>
                <div class="twoField">
                    <span class="color99">职位：</span>
                    <span class="color3a">{{detailInfo.position || "--"}}</span>
                </div>
            </div>
        </div>
        <div class="cardBox" style="margin-top: 20px;" v-if="detailInfo.companyName">
            <div class="header">
                <span class="title">公司信息</span>
            </div>
            <div style="margin-bottom: 8px;">
                <div class="companyName">{{detailInfo.companyName}}</div>
                <div class="country">
                    <img :src="detailInfo.flagUrl" style="margin-right:5px;height:14px" />
                    <span>{{detailInfo.country}}</span>
                </div>
            </div>
            <el-descriptions :column="2" border :labelStyle="{width:'15%'}" :contentStyle="{width:'35%'}">
                <el-descriptions-item label="公司名称">{{detailInfo.companyName}}</el-descriptions-item>
                <el-descriptions-item label="国家">
                    <img :src="detailInfo.flagUrl" style="margin-right:5px;height:14px" />
                    <span>{{detailInfo.country}}</span>
                </el-descriptions-item>
                <el-descriptions-item label="详细地址" :span="2">{{detailInfo.businessAddress || "--"}}</el-descriptions-item>
                <el-descriptions-item label="经度">{{detailInfo.longitude || "--"}}</el-descriptions-item>
                <el-descriptions-item label="纬度">{{detailInfo.latitude || "--"}}</el-descriptions-item>
                <el-descriptions-item label="地理精度" :span="2">{{detailInfo.geographicLongitude || "--"}}</el-descriptions-item>
                <el-descriptions-item label="公司类型">{{detailInfo.companyType || "--"}}</el-descriptions-item>
                <el-descriptions-item label="状态">{{detailInfo.companyStatus || "--"}}</el-descriptions-item>
                <el-descriptions-item label="法人">{{detailInfo.legalPerson || "--"}}</el-descriptions-item>
                <el-descriptions-item label="家庭成员数量">{{detailInfo.familyNumber || "--"}}</el-descriptions-item>
                <el-descriptions-item label="注册时间">{{detailInfo.registrationTime || "--"}}</el-descriptions-item>
                <el-descriptions-item label="注册号">{{detailInfo.registrationNumber || "--"}}</el-descriptions-item>
                <el-descriptions-item label="经营范围" :span="2">{{detailInfo.businessline || "--"}}</el-descriptions-item>
                <el-descriptions-item label="简介" :span="2">{{detailInfo.companyProfile || "--"}}</el-descriptions-item>
                <el-descriptions-item label="电话" :span="2">{{detailInfo.contactNumber || "--"}}</el-descriptions-item>
                <el-descriptions-item label="传真">{{detailInfo.faxNumber || "--"}}</el-descriptions-item>
                <el-descriptions-item label="网址">
                    <span v-if="detailInfo.officialWebsite" class="primaryColor" @click="openWebsite(detailInfo.officialWebsite)">{{detailInfo.officialWebsite}}</span>
                    <span v-else>--</span>
                </el-descriptions-item>
                <el-descriptions-item label="年收入">{{detailInfo.reverueAmount || "--" }}</el-descriptions-item>
                <el-descriptions-item label="员工人数">{{detailInfo.employeesNumber || "--"}}</el-descriptions-item>
            </el-descriptions>
        </div>
        <div class="cardBox" style="margin-top: 20px;" v-else>
            <el-empty description="暂无公司信息" :image-size="100"></el-empty>
        </div>

        <el-dialog title="编辑联系人信息" :visible.sync="editVisible" width="600px">
            <el-form label-width="80px" :model="editInfo" size="small">
                <el-form-item label="姓名" required>
                    <el-input v-model="editInfo.name"></el-input>
                </el-form-item>
                <el-form-item label="邮箱" required>
                    <el-input v-model="editInfo.email"></el-input>
                </el-form-item>
                <el-form-item label="手机号" required>
                    <el-input v-model="editInfo.tel"></el-input>
                </el-form-item>
                <el-form-item label="公司名称">
                    <el-input v-model="editInfo.companyName" :disabled="editInfo.companyName"></el-input>
                </el-form-item>
                <el-form-item label="国家">
                    <el-input v-model="editInfo.country"></el-input>
                </el-form-item>
                <el-form-item label="职位">
                    <el-input v-model="editInfo.position"></el-input>
                </el-form-item>
                <el-form-item label="性别">
                    <el-radio-group v-model="editInfo.sex">
                        <el-radio :label="1">男</el-radio>
                        <el-radio :label="0">女</el-radio>
                        <el-radio :label="2">未知</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="editVisible = false">取 消</el-button>
                <el-button type="primary" size="small" @click="editFinish" :loading="btnLoading">确 定</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
export default {
    data() {
        return {
            key: "",
            infoLoading: false,
            detailInfo: {},

            //编辑联系人信息
            editVisible: false,
            editInfo: {},
            btnLoading: false,
        };
    },
    methods: {
        openWebsite(url){
            let jumpUrl = url.indexOf("http") > -1 ? url : "http://" + url;
            let routeData = this.$router.resolve({
                path: "/jumpWeb",
                query: { jumpUrl: jumpUrl },
            });
            window.open(routeData.href, "_blank");
        },

        getDetail(){
            this.infoLoading = true;
            this.$axios.get(`${this.$clueUrl}/MyClue/GetContactsDetails?key=${this.key}`).then((res) => {
                const { isSuccess, data } = res;
                this.infoLoading = false;
                if (isSuccess) {
                    // console.log(data);
                    data.flagUrl = window.getFlagUrlByCountry ? window.getFlagUrlByCountry(data.country) : "";
                    this.detailInfo = data;
                }
            });
        },

        toEdit(row){
            this.editVisible = true;
            this.editInfo = {
                "key": row.key,
                "companyName": row.companyName,
                "country": row.country,
                "webSite": row.webSite,
                "name": row.name,
                "email": row.email,
                "tel": row.tel,
                "position": row.position,
                "sex": row.sex,
            }
        },
        editFinish(){
            let email = this.editInfo.email.trim();
            let tel = this.editInfo.tel.trim();
            let name = this.editInfo.name.trim();
            if(!name){
                this.$message({ message: '请填写姓名!', type: 'warning'});
                return false;
            }
            if(!email && !tel){
                this.$message({ message: '请填写邮箱或手机号!', type: 'warning'});
                return false;
            }
            this.btnLoading = true;
            this.$axios.post(`${this.$clueUrl}/MyClue/UpdateContacts`,this.editInfo).then((res) => {
                const { isSuccess } = res;
                this.btnLoading = false;
                this.editVisible = false;
                if (isSuccess) {
                    this.$message({ message: '编辑完成', type: 'success'});
                    this.getDetail();
                }
            });
        },
    },
    mounted() {
        this.key = this.$route.query.key;
        this.getDetail();
    },
};
</script>

<style lang="scss" scoped>
.detailBox{
    width: 1200px;
    margin: auto;
    .cardBox{
        background: #FFFFFF;
        box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.16);
        padding: 20px;
        position: relative;
        .header{
            display: flex;
            justify-content: space-between;
            .title{
                height: 20px;
                line-height: 20px;
                font-size: 16px;
                font-weight: bold;
                color: #333333;
                border-left: 3px solid var(--themeColor);
                padding-left: 5px;
                margin-bottom: 10px;
            }
        }
        .linkmanName,.companyName{
            display: inline-block;
            height: 24px;
            font-size: 18px;
            font-weight: bold;
            color: #3A3A3A;
        }
        .country{
            display: inline-block;
            margin-left: 20px;
            img{
                vertical-align: middle;
            }
        }
        .color99{
            color: #999999;
        }
        .color3a{
            color: #3A3A3A;
        }
        .primaryColor{
            color: var(--themeColor);
            cursor: pointer;
        }
        .twoField{
            line-height: 28px;
            margin-right: 20px;
        }
    }
}
</style>