<template>
    <div class="cloudDoc">
        <div class="leftAside">
            <div class="title">云文档</div>
            <div class="oneItem" :class="{ 'active': activePage == '主页' }" @click="clickPage('主页')">
                <img v-if="activePage == '主页'" src="@/assets/doc/1-1.png" alt="" />
                <img v-else src="@/assets/doc/1-2.png" alt="" />
                <span>主页</span>
            </div>
            <div class="oneItem" :class="{ 'active': activePage == '我的空间' }" @click="clickPage('我的空间')">
                <img v-if="activePage == '我的空间'" src="@/assets/doc/2-1.png" alt="" />
                <img v-else src="@/assets/doc/2-2.png" alt="" />
                <span>我的空间</span>
            </div>
            <div class="oneItem" :class="{ 'active': activePage == '公司空间' }" @click="clickPage('公司空间')">
                <img v-if="activePage == '公司空间'" src="@/assets/doc/3-1.png" alt="" />
                <img v-else src="@/assets/doc/3-2.png" alt="" />
                <span>公司空间</span>
            </div>
            <div v-show="this.$isTest" class="oneItem" :class="{ 'active': activePage == '文件集市' }" @click="clickPage('文件集市')">
                <img v-if="activePage == '文件集市'" src="@/assets/doc/4-1.png" alt="" />
                <img v-else src="@/assets/doc/4-2.png" alt="" />
                <span>文件集市</span>
            </div>
            <div class="oneItem" :class="{ 'active': activePage == '与我分享' }" @click="clickPage('与我分享')">
                <img v-if="activePage == '与我分享'" src="@/assets/doc/5-1.png" alt="" />
                <img v-else src="@/assets/doc/5-2.png" alt="" />
                <span>与我分享</span>
            </div>
            <div class="oneItem" :class="{ 'active': activePage == '我的分享' }" @click="clickPage('我的分享')">
                <img v-if="activePage == '我的分享'" src="@/assets/doc/6-1.png" alt="" />
                <img v-else src="@/assets/doc/6-2.png" alt="" />
                <span>我的分享</span>
            </div>
            <div class="oneItem" :class="{ 'active': activePage == '邮件云附件' }" @click="clickPage('邮件云附件')">
                <img v-if="activePage == '邮件云附件'" src="@/assets/doc/7-1.png" alt="" />
                <img v-else src="@/assets/doc/7-2.png" alt="" />
                <span>邮件云附件</span>
            </div>
            <div class="oneItem" :class="{ 'active': activePage == '回收站' }" @click="clickPage('回收站')">
                <img v-if="activePage == '回收站'" src="@/assets/doc/8-1.png" alt="" />
                <img v-else src="@/assets/doc/8-2.png" alt="" />
                <span>回收站</span>
            </div>
        </div>
        <div class="content">
            <Home v-if="activePage == '主页'" @onJump="onJump" />
            <MySpace ref="mySpace" v-if="activePage == '我的空间'" />
            <CompanySpace ref="companySpace" v-if="activePage == '公司空间'" />
            <FileBazaar v-if="activePage == '文件集市'" />
            <ShareWithMe v-if="activePage == '与我分享'" />
            <MyShare v-if="activePage == '我的分享'" />
            <CloudAttach v-if="activePage == '邮件云附件'" />
            <Recycle v-if="activePage == '回收站'" />
        </div>
    </div>
</template>

<script>  
import Home from "./Home.vue";                  //主页
import MySpace from "./MySpace.vue";            //我的空间
import CompanySpace from "./CompanySpace.vue";  //公司空间
import FileBazaar from "./FileBazaar.vue";      //文件集市
import ShareWithMe from "./ShareWithMe.vue";    //与我分享
import MyShare from "./MyShare.vue";            //我的分享
import CloudAttach from "./CloudAttach.vue";    //邮件云附件
import Recycle from "./Recycle.vue";            //回收站

export default {
    components: { Home, MySpace, CompanySpace, FileBazaar, ShareWithMe, MyShare, CloudAttach, Recycle },
    data() {
        return {
            activePage: "",
        };
    },
    methods: {
        onJump(value){
            let activePage = value.activePage;
            let BreadcrumbList = value.BreadcrumbList;
            this.activePage = activePage;
            this.$router.push(`${this.$route.path}?activePage=${activePage}`);
            this.$nextTick(()=>{
                if(activePage == "我的空间"){ this.$refs.mySpace.initBreadcrumb(BreadcrumbList); }
                if(activePage == "公司空间"){ this.$refs.companySpace.initBreadcrumb(BreadcrumbList); }
            })
        },
        clickPage(activePage){
            this.activePage = activePage;
            this.$router.push(`${this.$route.path}?activePage=${activePage}`);
            this.$nextTick(()=>{
                if(activePage == "我的空间"){ this.$refs.mySpace.initBreadcrumb(""); }
                if(activePage == "公司空间"){ this.$refs.companySpace.initBreadcrumb(""); }
            })
        },
    },
    mounted() {
        let activePage = this.$route.query.activePage || "主页";
        this.clickPage(activePage);
    },
};
</script>

<style lang="scss" scoped>
@import '@/scss/common.scss';
.cloudDoc {
    background: #FFFFFF;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.16);
    height: calc(100vh - 80px);
    display: flex;

    .leftAside {
        width: 180px;
        background: #F5F6F7;
        box-shadow: 0px 1px 6px 1px rgba(0, 0, 0, 0.16);

        .title{
            height: 50px;
            line-height: 50px;
            font-size: 20px;
            font-weight: bold;
            color: #333333;
            padding-left: 30px;
        }

        .oneItem {
            height: 50px;
            line-height: 50px;
            color: #092338;
            cursor: pointer;
            padding-left: 50px;
            position: relative;
            img{
                position: absolute;
                left: 25px;
                top: 0;
                bottom: 0;
                margin: auto;
            }
        }

        .active {            
            background: $themeColor;
            color: #FFFFFF;
        }
    }

    .content {
        width: calc(100% - 180px);
        overflow: auto;
    }
}
</style>