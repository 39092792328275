<script>
import Vue from "vue";
import router from "@/router";
import { reactive, watch } from "@vue/composition-api";
import axios from "@/assets/js/axios";
import dayjs from "dayjs";
import { MessageBox, Message } from "element-ui";
import MarketingNav from "@/components/MarketingNav.vue";
import CMenu from '../MarketingWA/components/Menu.vue'
export default Vue.extend({
    components: { MarketingNav,CMenu },
    props: {
        handleSelect: {
            type: Function,
            default: null,
        },
    },
    setup() {
        const form = reactive({
            date: "",
            searchKeyword: "",
            srotType: "0",
        });
        watch(
            () => form.date,
            () => getListData({ pageIndex: 1 })
        );

        const bill = reactive({
            data: "--",
        });
        (async () => {
            const res = await axios({
                method: "GET",
                url: Vue.prototype.$marketUrl + "/api/Bill/GetSendPoint",
            });
            bill.data = res.data.surplusCount;
        })();

        const cardData = reactive({
            sendEmailTotalCount: 0,
            serviceTotalCount: 0,
            openTotalCount: 0,
            replyTotalCount: 0,
            letterReadingRate: 0,
            unsubscribeTotalCount: 0,
        });
        (async () => {
            const res = await axios({
                method: "GET",
                url: Vue.prototype.$marketUrl + "/MassSending/GetTaskTopReport",
            });
            const data = res.data;
            cardData.sendEmailTotalCount = data.sendEmailTotalCount;
            cardData.serviceTotalCount = data.serviceTotalCount;
            cardData.openTotalCount = data.openTotalCount;
            cardData.replyTotalCount = data.replyTotalCount;
            cardData.letterReadingRate = data.letterReadingRate;
            cardData.unsubscribeTotalCount = data.unsubscribeTotalCount;
        })();

        const list = reactive({
            loading: false,
            data: [],
            page: {
                total: 0,
                size: 10,
                index: 1,
            },
        });
        const getListData = async (options) => {
            try {
                list.loading = true;
                const res = await axios({
                    method: "POST",
                    url: Vue.prototype.$marketUrl + "/MassSending/GetTask",
                    data: {
                        pageIndex: list.page.index,
                        pageSize: list.page.size,
                        time: form.date,
                        searchKeyword: form.searchKeyword,
                        srotType: form.srotType,
                        ...options,
                    },
                });
                const data = res.data.datas;
                data.forEach((element) => {
                    element.senderName =
                        element.senderName + "<" + element.senderAddress + ">";
                    element.loading = false;
                });
                list.data = data;
                list.page.total = res.data.totalItems;
                options.pageIndex && (list.page.index = options.pageIndex);
            } catch {
                null;
            } finally {
                list.loading = false;
            }
        };
        getListData();

        const onDetail = (campaignId) => {
            const { href: location } = router.resolve({
                path: "/marketing/Report",
                query: {
                    campaignId,
                },
            });
            window.open(location, "_blank");
        };

        const onCommand = async (item) => {
            item.loading = true;
            const res = await axios({
                method: "GET",
                url: Vue.prototype.$marketUrl + "/MassSending/UpdateReport",
                params: {
                    campaignId: item.campaignId,
                },
            });
            item.loading = false;
            res.isSuccess && getListData();
        };

        const onNewTask = () => {
            const { href: location } = router.resolve({
                // path: "/marketing/New",
                path: "/marketing/MailMarketing",
            });
            window.open(location, "_blank");
        };

        const startSend = async (item) => {
            const res = await axios({
                method: "GET",
                url: Vue.prototype.$marketUrl + "/MassSending/SendEvent",
                params: {
                    eventNo: item.eventNo,
                },
            });
            if (res.isSuccess) {
                Message.success("发送成功");
                getListData();
            }
        };
        const editTask = (item) => {
            const { href: location } = router.resolve({
                // path: "/marketing/New",
                path: "/marketing/MailMarketing",
                query: { eventNo: item.eventNo },
            });
            window.open(location, "_blank");
        };
        const deleteTask = (item) => {
            MessageBox.confirm("此操作将删除任务, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    axios({
                        method: "GET",
                        url: Vue.prototype.$marketUrl + "/MassSending/DelEvent",
                        params: {
                            eventNo: item.eventNo,
                        },
                    }).then((res) => {
                        res.isSuccess && getListData();
                    });
                })
                .catch(() => {});
        };
        const withdrawTask = (item) => {
            MessageBox.confirm("此操作将撤回任务, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    axios({
                        method: "GET",
                        url:
                            Vue.prototype.$marketUrl +
                            "/MassSending/WithdrawSend",
                        params: {
                            eventNo: item.eventNo,
                        },
                    }).then((res) => {
                        res.isSuccess && getListData();
                    });
                })
                .catch(() => {});
        };

        const chooseNav = (index) => {
            this.handleSelect(index);
            this.$router.push(index);
        };

        return {
            form,
            bill,
            cardData,
            list,
            getListData,
            onDetail,
            onCommand,
            onNewTask,
            dayjs,
            startSend,
            editTask,
            deleteTask,
            withdrawTask,
            currentPath: "/marketing/TaskDashboard",
            chooseNav,
            gridTip: [
                {name: "草稿",describe: "没有发送"},
                {name: "发送中",describe: "邮件排队发送中"},
                {name: "信件送达",describe: "邮件已经全部推送完毕"},
                {name: "报告采集",describe: ""},
                {name: "任务完成",describe: "报告采集超过30天，任务结束，停止采集报告"},
            ],
        };
    },
});
</script>

<template>
    <div style="display: flex">
     <div>
      <CMenu active="TaskDashboard" />
    </div>
    <div style="width: calc(100% - 200px); height: 100%">
        <!-- <MarketingNav @chooseNav="chooseNav" :currentPath="currentPath" /> -->
        <div class="view">
            <div style="display: flex; justify-content: space-between;margin-bottom: 10px;">
                <span style="color: #3A3A3A;font-weight: bold;font-size: 24px;">邮件群发任务看板</span>
                <div style="display: flex; align-items: center">
                    <span style="font-size: 14px; margin-right: 15px">点数：{{ bill.data }}封</span>
                    <el-button @click="onNewTask" size="mini" type="primary">新建任务</el-button>
                </div>
            </div>
            <div style="display: flex;">
                <div class="card">
                    <p style="color: #9C9C9C; font-size: 14px">收件人数</p>
                    <p style="font-weight: bold; font-size: 18px">{{ cardData.sendEmailTotalCount || '--' }}</p>
                </div>
                <div class="card">
                    <p style="color: #9C9C9C; font-size: 14px">送达总数</p>
                    <p style="font-weight: bold; font-size: 18px">{{ cardData.serviceTotalCount || '--' }}</p>
                </div>
                <div class="card">
                    <p style="color: #9C9C9C; font-size: 14px">打开人数</p>
                    <p style="font-weight: bold; font-size: 18px">{{ cardData.openTotalCount || '--' }}</p>
                </div>
                <div class="card">
                    <p style="color: #9C9C9C; font-size: 14px">看信率</p>
                    <p style="font-weight: bold; font-size: 18px">{{ cardData.letterReadingRate ? cardData.letterReadingRate+'%' : '--' }}</p>
                </div>
                <div class="card">
                    <p style="color: #9C9C9C; font-size: 14px">取消订阅数</p>
                    <p style="font-weight: bold; font-size: 18px">{{ cardData.unsubscribeTotalCount || '--' }}</p>
                </div>
            </div>
        </div>
        <el-card>
            <div style="display: flex; justify-content: space-between">
                <p style="color: #3A3A3A"></p>
                <div style="display: flex;">
                    <el-select v-model="form.srotType" size="mini" @change="getListData({ pageIndex: 1 })">
                        <el-option label="默认排序" value="0"></el-option>
                        <el-option label="发信时间" value="1"></el-option>
                        <el-option label="状态" value="2"></el-option>
                        <el-option label="收件人数" value="3"></el-option>
                    </el-select>
                    <el-input v-model="form.searchKeyword" placeholder="营销名称/收件人" clearable size="mini"></el-input>
                    <i class="el-icon-search" @click="getListData({ pageIndex: 1 })"></i>
                </div>
            </div>
            <div v-loading="list.loading" style="box-sizing: border-box; height: calc(100vh - 305px);overflow-y: auto">
                <el-empty v-if="!list.data.length" description="暂无数据" />
                <el-table v-else :data="list.data">
                    <el-table-column label="  " width="65px">
                        <template slot-scope="{row}">
                            <div class="draft" v-if="row.senStatus==2">
                                <el-tag type="info" size="mini">草稿</el-tag>
                            </div>
                            <div class="dingshi" v-if="row.senStatus==14">
                                <el-tooltip effect="dark" placement="top">
                                    <span slot="content">定时 {{dayjs(row.sentTime).format('MM-DD HH:mm')}} 发送</span>
                                    <el-tag size="mini">定时</el-tag>
                                </el-tooltip>
                            </div>
                            <div class="withdraw" v-if="row.senStatus==15">
                                <el-tag type="info" size="mini">撤回</el-tag>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="主题名称" width="150px" show-overflow-tooltip>
                        <!-- taskName 任务名称 / subject 邮件主题 -->
                        <template slot-scope="{row}">
                            <el-link v-if="row.senStatus==16 || row.senStatus==17 || row.senStatus==18" type="primary" @click="onDetail(row.campaignId)">{{row.subject}}</el-link>
                            <span v-else>{{row.subject}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="状态" width="150px">
                        <template slot="header">
                            <span style="padding-right:5px;">状态</span>
                            <el-popover placement="right" trigger="hover">
                                <el-table :data="gridTip" size="mini">
                                    <el-table-column property="name" label="状态名称"></el-table-column>
                                    <el-table-column property="describe" label="描述" width="300"></el-table-column>
                                </el-table>
                                <img slot="reference" style="vertical-align:text-top;cursor: pointer;" src="@/assets/icon/tip.png" alt="" />
                            </el-popover>
                        </template>
                        <template slot-scope="{row}">
                            <!-- <el-link v-if="row.senStatus==1" type="info" :underline="false">已完成</el-link> -->
                            <!-- <el-link v-if="row.senStatus==4 || row.senStatus==5" type="success" :underline="false">发送中</el-link> -->
                            <div style="display: flex;" v-if="row.senStatus==4 || row.senStatus==5">
                                <span style="color:#2DC76D">发送中</span>
                                <el-progress style="width:85px;margin-top:8px;" :percentage="30" color="#2DC76D" :show-text="false"></el-progress>
                            </div>
                            <div class="draft" v-if="row.senStatus==2">
                                <el-link type="primary" @click="startSend(row)">开始群发</el-link>
                                <span style="padding:0 3px;"></span>
                                <el-link type="success" @click="editTask(row)">编辑</el-link>
                                <span style="padding:0 3px;"></span>
                                <el-link type="danger" @click="deleteTask(row)">删除</el-link>
                            </div>
                            <div class="dingshi" v-if="row.senStatus==14">
                                <el-link type="success" @click="editTask(row)">编辑</el-link>
                                <span style="padding:0 3px;"></span>
                                <el-link type="info" @click="withdrawTask(row)">撤回</el-link>
                            </div>
                            <div class="withdraw" v-if="row.senStatus==15">
                                <el-link type="danger" @click="deleteTask(row)">删除</el-link>
                            </div>
                            <div v-if="row.senStatus==16">
                                <img style="vertical-align:text-top;" src="@/assets/icon/status16.png" alt="" />
                                <span style="color:#919191;padding:0 5px;">报告采集</span>
                            </div>
                            <div v-if="row.senStatus==17">
                                <img style="vertical-align:text-top;" src="@/assets/icon/status17.png" alt="" />
                                <span style="color:#919191;padding:0 5px;">任务完成</span>
                                <el-tooltip effect="dark" content="报告采集超过30天，任务结束，停止采集报告" placement="bottom-start">
                                    <img style="vertical-align:text-top;cursor: pointer;" src="@/assets/icon/tip.png" alt="" />
                                </el-tooltip>
                            </div>
                            <div v-if="row.senStatus==18">
                                <img style="vertical-align:text-top;" src="@/assets/icon/status18.png" alt="" />
                                <span style="color:#919191;padding:0 5px;">信件送达</span>
                                <el-tooltip effect="dark" content="邮件已经全部推送完毕" placement="bottom-start">
                                    <img style="vertical-align:text-top;cursor: pointer;" src="@/assets/icon/tip.png" alt="" />
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="收件人数">
                        <template slot-scope="{row}">
                            {{ row.recipientsCount || '--' }}
                        </template>
                    </el-table-column>
                    <el-table-column label="发送">
                        <template slot-scope="{row}">
                            {{ row.sentCount || '--' }}
                        </template>
                    </el-table-column>
                    <el-table-column label="送达">
                        <template slot-scope="{row}">
                            {{ row.serviceCount || '--' }}
                        </template>
                    </el-table-column>
                    <el-table-column label="打开">
                        <template slot-scope="{row}">
                            {{ row.openCount || '--' }}
                        </template>
                    </el-table-column>
                    <el-table-column label="看信率">
                        <template slot-scope="{row}">
                            {{ row.letterReadingRate ? row.letterReadingRate+'%' : '--' }}
                        </template>
                    </el-table-column>
                    <el-table-column label="退订">
                        <template slot-scope="{row}">
                            {{ row.unsubscribeCount || '--' }}
                        </template>
                    </el-table-column>
                    <el-table-column label="报告更新" width="130px">
                        <template slot-scope="{row}">
                            <span>{{row.updateTime ? dayjs(row.updateTime).format('MM-DD HH:mm') : "--"}}</span>
                            <el-tooltip effect="dark" content="更新" placement="bottom">
                                <i class="el-icon-loading" v-if="row.loading"></i>
                                <i class="el-icon-refresh" v-if="row.senStatus==1 && !row.loading" @click="onCommand(row)"></i>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="发件人" show-overflow-tooltip width="150px">
                        <template slot-scope="{row}">
                            {{row.senderName}}
                        </template>
                    </el-table-column>
                    <el-table-column label="创建时间" width="120px">
                        <template slot-scope="{row}">
                            {{row.createTime ? dayjs(row.createTime).format('MM-DD HH:mm') : "--"}}
                        </template>
                    </el-table-column>
                    <el-table-column label="发送时间" width="120px">
                        <template slot-scope="{row}">
                            {{row.sentTime ? dayjs(row.sentTime).format('MM-DD HH:mm') : "--"}}
                        </template>
                    </el-table-column>
                </el-table>
                <div style="display: flex; justify-content: flex-end; margin-top:10px;">
                    <el-pagination hide-on-single-page :current-page.sync="list.page.index" :page-size="list.page.size" :total="list.page.total" @current-change="getListData()" background layout="prev, pager, next" />
                </div>
            </div>
        </el-card>
    </div>
</div>
</template>

<style scoped lang="scss">
.view {
    box-sizing: border-box;
    padding: 20px;
    background: #fff;
    margin-bottom: 10px;
}

.card {
    width: 20%;
    text-align: center;
    
    &:not(:last-of-type) {
        margin-right: 20px;
    }
}

.el-icon-refresh,
.el-icon-loading {
    cursor: pointer;
    padding-left: 5px;
    font-size: 20px;
    color: var(--themeColor);
}
::v-deep .el-input--mini .el-input__inner {
    border: none;
}
.el-icon-search {
    cursor: pointer;
    line-height: 28px;
    color: #3a3a3a;
}
.draft,
.dingshi,
.withdraw {
    display: inline-block;
}
</style>
