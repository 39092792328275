<template>
    <div class="FinanceReceipt">
        <div class="title">
            <span>财务到账</span>
        </div>
        <el-table :data="tableData" :header-cell-style="{ 'background-color':'#F3F3F3','color':'#3A3A3A' }"
            :row-style="({ rowIndex }) => (rowIndex % 2 == 1 ? { 'background-color': '#F9FAFC' } : {})">
            <el-table-column prop="remittanceCompany" label="汇款公司"></el-table-column>
            <el-table-column label="汇款金额">
                <template slot-scope="scope">
                    <div>{{scope.row.receiptAmount}} {{scope.row.currency}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="receiptDate" label="汇款时间"></el-table-column>
            <el-table-column prop="remittingBank" label="汇款银行"></el-table-column>
            <el-table-column prop="ourAccount" label="我方账户"></el-table-column>
            <el-table-column label="付款凭证">
                <template slot-scope="scope">
                    <el-image style="width:48px;height:48px" :src="scope.row.paymentVoucher" :preview-src-list="[scope.row.paymentVoucher]"></el-image>
                </template>
            </el-table-column>
            <el-table-column prop="userName" label="经办人"></el-table-column>
            <el-table-column prop="createTime" label="创建时间"></el-table-column>
            <el-table-column prop="remark" label="汇款备注"></el-table-column>
        </el-table>
    </div>
</template>

<script>
export default {
    props:["basicInfo"],
    data() {
        return {
            tableData: [],
        };
    },
    methods: {
        getReceipt(){
            this.$axios.post(`${this.$orderUrl}/Performance/GetRecordReceipt`,{
                orderKey: this.basicInfo.orderKey,
            }).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    this.tableData = data;
                }
            });
        },
    },
    mounted() {

    },
    watch:{
        basicInfo(){
            this.getReceipt();
        }
    }
};
</script>

<style lang="scss" scoped>
.FinanceReceipt{
    margin-top: 20px;
    .title{
        height: 20px;
        line-height: 20px;
        font-size: 16px;
        font-weight: bold;
        color: #3A3A3A;
        margin-bottom: 10px;
        border-left: 3px solid var(--themeColor);
        padding-left: 5px;
    }
}
</style>