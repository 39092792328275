<script>
import Vue from "vue";
import { watchEffect, ref } from "@vue/composition-api";
import axios from "@/assets/js/axios";

export default Vue.extend({
    props: ["ckey"],
    setup(props) {
        const info = ref({});
        watchEffect(async () => {
            const key = props.ckey;
            if (key) {
                const res = await axios({
                    method: "GET",
                    url: Vue.prototype.$customerUrl + "/Card/GetCompanyCard",
                    params: {
                        companyKey: key,
                    },
                });
                info.value = res.data;
            }
        });
        return {
            info,
            getFlagUrlByCountry: window.getFlagUrlByCountry,
        };
    },
});
</script>

<template>
    <div class="company-card">
        <div style="padding-bottom: 10px; border-bottom: 1px solid #D9D9D9">
            <div style="display: flex">
                <img src="@/assets/img/avacom.png" style="width: 45px; height: 45px; flex-shrink: 0" />
                <div style="margin-left: 18px; display: flex; flex-direction: column; justify-content: space-between">
                    <p style="color: #000">{{ info.companyName }}</p>
                    <el-rate :value="Number(info.star)" disabled />
                </div>
            </div>
            <div class="description-item utils-omit-1" style="display: flex; align-items: center">
                <span>国家地区：</span>
                <img :src="getFlagUrlByCountry(info.country)" style="height: 14px; margin-right: 3px" />
                <span style="color: #1A2745">{{ info.country }}</span>
            </div>
            <p class="description-item utils-omit-1">公司类型：{{ info.companyType }}</p>
        </div>
        <div>
            <p class="description-item utils-omit-1">分组：{{ info.publicGroupName }}</p>
            <p class="description-item utils-omit-1">状态：{{ info.statuName }}</p>
            <p class="description-item utils-omit-1">联系人：{{ info.contacts }}</p>
            <p class="description-item utils-omit-1">跟进人：{{ info.followUserName }}</p>
            <div class="description-item utils-omit-1" style="display: flex; align-items: center">
                <img src="@/assets/img/icon-mail.png" style="flex-shrink: 0; margin-right: 3px" />
                <p class="utils-omit-1" style="width: 100%">{{ info.email }}</p>
            </div>
            <div class="tag-area" v-show="info.industry">
                <span class="tag" style="background: #FFAA48">{{ info.industry }}</span>
            </div>
        </div>

    </div>
</template>

<style scoped lang="scss">
.company-card {
    box-sizing: border-box;
    width: 420px;
    font-size: 16px;
}

.description-item {
    width: 100%;
    font-size: 14px;
    color: #707070;
    margin: 5px 0;

    &:first-child {
        margin-top: 10px;
    }
}

.tag-area {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -10px;
    border-bottom: 1px solid #d9d9d9;
}

.tag {
    cursor: default;
    height: 20px;
    padding: 0 5px;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #ffffff;
    margin-right: 10px;
    margin-bottom: 10px;
}
</style>
