<template>
    <div>
        <InterfaceName :interfaceName="interfaceName" />
        <InterfaceSpe :requestUrl="requestUrl" :requestMethod="requestMethod" />
        <ReturnParam :returnObj="returnObj" />
    </div>
</template>

<script>
import InterfaceName from "../Acomponents/InterfaceName.vue";
import InterfaceSpe from "../Acomponents/InterfaceSpe.vue";
import ReturnParam from "../Acomponents/ReturnParam.vue";

export default {
    components: { InterfaceName, InterfaceSpe, ReturnParam },
    data() {
        return {
            interfaceName: "获取客户状态",
            requestUrl: "https://crm.qixinerp.com/open/Customer/GetCustomerStatu",
            requestMethod: "GET",
            returnObj: {},
        };
    },
    methods: {},
    mounted() {
        this.returnObj = {
            isSuccess: true,
            errorMessage: null,
            data: [
                {
                    key: "48aa7244a6e640b994ef80bc2a0612ff",
                    name: "停滞",
                    color: "",
                    index: 0,
                    isDefault: false,
                    isSystem: false,
                },
                {
                    key: "0c135bffb62448dc9ec5803149df5018",
                    name: "暂缓",
                    color: "",
                    index: 0,
                    isDefault: false,
                    isSystem: false,
                },
                {
                    key: "4c8d185fc722451581fe2dcec6ac6081",
                    name: "电话邀约",
                    color: "",
                    index: 0,
                    isDefault: false,
                    isSystem: false,
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>

</style>