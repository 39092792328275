<template>
  <div class="tree-rect-dasf3rrff2-new" :class="{ full: control.full }" id="tree-rect-dasf3rrff2" v-loading="control.loading" v-show="control.show">
    <div class="title" ref="title">
      <p>全景图谱</p>
      <div style="display: flex; align-items: center">
        <el-radio-group v-model="control.option" size="mini">
          <el-radio-button :label="0">频次</el-radio-button>
          <el-radio-button :label="1">金额</el-radio-button>
          <el-radio-button :label="2">数量</el-radio-button>
          <el-radio-button :label="3">重量</el-radio-button>
        </el-radio-group>
        <el-tooltip effect="dark" content="保存为图片" placement="top-start">
          <img @click="saveImage" src="@/firm/images/company/icon-download.png" style="margin-left: 1em; cursor: pointer" />
        </el-tooltip>
        <el-tooltip effect="dark" content="刷新" placement="top-start">
          <img @click="refresh" src="@/firm/images/company/icon-refresh.png" style="margin-left: 15px; margin-right: 5px; cursor: pointer" />
        </el-tooltip>
        <el-tooltip v-show="control.full" effect="dark" content="取消全屏" placement="top-start">
          <i class="el-icon-close" style="font-size: 30px; color: #e9911b; cursor: pointer" @click="closeGraphFull"></i>
        </el-tooltip>
        <el-tooltip v-show="!control.full" effect="dark" content="全屏" placement="top-start">
          <img class="icon" src="@/firm/images/company/icon-expand.png" style="margin-left: 1em" @click="graphFull" />
        </el-tooltip>
      </div>
    </div>
    <div ref="container" class="graph" :class="{ full: control.full }"></div>
  </div>
</template>

<script>
import G6, { rectConfig, color as classifyToColor } from "./utils";
import { mapState, mapGetters, mapMutations } from "vuex";
import { disableScroll, enableScroll } from "../graph/utils";
import axios from "@/firm/plugins/axios";
import { AnchorInditor } from "../../utils";
import { Message } from '@/firm/plugins/element'

const classifyToValueType = {
  product: 2,
  country: 3,
  company: 4,
};

const labelToReportType = {
  采购商: 0,
  供应商: 1,
  采购产品: 4,
  供应产品: 5,
  采购国家: 6,
  供应国家: 7,
};

let morePaginationFlag = {};

let graph = null;

export default {
  data() {
    return {
      control: {
        show: true,
        loading: false,
        full: false,
        option: 0,
      },
    };
  },
  computed: {
    ...mapState(["company"]),
    ...mapGetters("controlFormGlobal", {
      startTime: "startTime",
      endTime: "endTime",
    }),
    subLabelKey() {
      return ["frequencyRatio", "amountRadio", "quantityRadio", "weightRadio"][this.control.option];
    },
  },
  watch: {
    "control.option"() {
      this.refresh();
    },
  },
  methods: {
    ...mapMutations("anchor", {
      setAnchorFlag: "setAnchorFlag",
      setCurrent: "setCurrent",
    }),
    saveImage() {
      graph.downloadFullImage(`${this.company}全景图谱`, "image/png", {
        backgroundColor: "#ffffff",
      });
    },
    async refresh() {
      try {
        this.control.loading = true;
        const data = await this.getInitData();
        if (!data) {
          throw new Error();
        }
        graph.data(data);
        graph.render();
        graph.fitCenter();
      } catch {
        console.log("刷新失败");
      } finally {
        this.control.loading = false;
      }
    },
    graphFull() {
      this.control.full = true;
      disableScroll();
      this.$nextTick(() => {
        graph.changeSize(this.$refs["container"].clientWidth, this.$refs["container"].clientHeight);
      });
    },
    closeGraphFull() {
      this.control.full = false;
      enableScroll();
      this.$nextTick(() => {
        graph.changeSize(this.$refs["container"].clientWidth, this.$refs["container"].clientHeight);
      });
    },
    async getInitData() {
      try {
        const data = (
          await axios({
            method: "POST",
            url: "/search/Credit/Diagram/New",
            data: {
              company: this.company,
              value: this.company,
              valueType: 1, //0:进口商 Importer,1:出口商 Exporter,2: hs编码 Hscode,3: 国家 Country  ,4:公司 Company
              isImporter: false,
              startTime: this.startTime,
              endTime: this.endTime,
              direction: "left",
              sortType: this.control.option,
            },
          })
        ).data.result;
        const tmpArr1 = data.children;
        data.children = [];
        const leftTMP = [];
        tmpArr1.forEach((item) => {
          item.levelFlag = 2;
          if (["供应产品", "供应国家", "采购商"].includes(item.label)) {
            for (const i of item.children) {
              i.subLabel = (i[this.subLabelKey] || 0.1).toString() + "%";
            }
            leftTMP.push(item);
          }
        });
        const leftEmpty = (() => {
          for (const item of leftTMP) {
            if (item.children.length !== 0) {
              return false;
            }
          }
          return true;
        })();
        const dataRight = (
          await axios({
            method: "POST",
            url: "/search/Credit/Diagram/New",
            data: {
              company: this.company,
              value: this.company,
              valueType: 0,
              isImporter: true,
              startTime: this.startTime,
              endTime: this.endTime,
              direction: "right",
              sortType: this.control.option,
            },
          })
        ).data.result.children;
        const rightTMP = [];
        dataRight.forEach((item) => {
          if (["采购产品", "采购国家", "供应商"].includes(item.label)) {
            for (const i of item.children) {
              i.subLabel = (i[this.subLabelKey] || 0.1).toString() + "%";
            }
            rightTMP.push(item);
          }
        });
        const rightEmpty = (() => {
          for (const item of rightTMP) {
            if (item.children.length !== 0) {
              return false;
            }
          }
          return true;
        })();
        data.collapsed = false;
        if (!leftEmpty) {
          data.children.push(...leftTMP);
        } else {
          data.children.push({
            id: "1",
            classify: "empty",
            label: "无出口数据",
            direction: "left",
            leaf: true,
          });
        }
        data.leftEmpty = leftEmpty;
        if (!rightEmpty) {
          data.children.push(...rightTMP);
        } else {
          data.children.push({
            id: "2",
            classify: "empty",
            label: "无进口数据",
            direction: "right",
            leaf: true,
          });
        }
        data.rightEmpty = rightEmpty;
        for (const item of data.children) {
          item.collapsed = true;
        }
        morePaginationFlag = {};
        return data;
      } catch {
        return null;
      }
    },
    async getChildren(value, valueType, direction) {
      try {
        const children = (
          await axios({
            method: "POST",
            url: "/search/Credit/Diagram/New",
            data: {
              company: this.company,
              value,
              valueType,
              isImporter: direction === "right",
              startTime: this.startTime,
              endTime: this.endTime,
              direction,
              sortType: this.control.option,
            },
          })
        ).data.result.children;
        for (const child of children) {
          child.direction = direction;
          child.collapsed = true;
          for (const item of child.children) {
            item.subLabel = (item[this.subLabelKey] || 0.1).toString() + "%";
            item.leaf = true;
            item.direction = direction;
          }
        }
        return children;
      } catch {
        return [];
      }
    },
    async getMore(value, valueType, direction, reportType, leaf, id) {
      try {
        const res = await axios({
          method: "POST",
          url: "/search/Credit/Diagram/More/New",
          data: {
            company: this.company,
            value,
            valueType,
            isImporter: direction === "right",
            startTime: this.startTime,
            endTime: this.endTime,
            direction,
            sortType: this.control.option,
            reportType,
          },
        });
        morePaginationFlag[id] ? morePaginationFlag[id]++ : (morePaginationFlag[id] = 1);
        const pageIndex = morePaginationFlag[id];
        const more = res.data.result.children.slice((pageIndex - 1) * 5, pageIndex * 5);
        for (const item of more) {
          item.subLabel = (item[this.subLabelKey] || 0.1).toString() + "%";
          item.leaf = leaf;
        }
        return more;
      } catch {
        return [];
      }
    },
  },
  async mounted() {
    try {
      const tooltip = new G6.Tooltip({
        itemTypes: ["node"],
        getContent(e) {
          try {
            const name = e.target.get("name");
            if (name === "label-text") {
              const nodeModel = e.item.getModel();
              return `
                <div>
                  <p>${nodeModel.label}</p>
                  ${nodeModel.descrip ? `<p>描述：${nodeModel.descrip}</p>` : ""}
                  <p>频次：${nodeModel.frequency}<span style="margin-left: 1em">(${nodeModel.frequencyRatio || 0.1}%)</span></p>
                  <p>金额：${nodeModel.amount}<span style="margin-left: 1em">(${nodeModel.amountRadio || 0.1}%)</span></p>
                  <p>数量：${nodeModel.quantity}<span style="margin-left: 1em">(${nodeModel.quantityRadio || 0.1}%)</span></p>
                  <p>重量：${nodeModel.weight}<span style="margin-left: 1em">(${nodeModel.weightRadio || 0.1}%)</span></p>
                </div>
              `;
            }
            throw new Error();
          } catch {
            return "";
          }
        },
        shouldBegin(e) {
          try {
            const nodeModel = e.item.getModel();
            if (e.target.get("name") === "label-text" && ["product", "country", "company"].includes(nodeModel.classify)) {
              return true;
            } else {
              throw new Error();
            }
          } catch {
            return false;
          }
        },
      });
      const rootLength = G6.Util.getTextSize(this.company, 12)[0] + 12;
      console.log(rootLength);
      graph = new G6.TreeGraph({
        container: this.$refs["container"],
        modes: {
          default: ["collapse-expand", "drag-canvas", "zoom-canvas"],
        },
        plugins: [tooltip],
        layout: {
          type: "compactBox",
          direction: "H",
          getHGap() {
            return 20;
          },
          getVGap() {
            return 5;
          },
          getHeight() {
            return rectConfig.height;
          },
          getWidth(node) {
            if (node.classify.includes("second")) {
              return rectConfig.secondWidth;
            }
            return rectConfig.width;
          },
          getSide(node) {
            return node.data.direction;
          },
        },
        defaultNode: {
          type: "rect-node-okj6uhgb-new",
        },
        defaultEdge: {
          type: "step-line-okj6uhgb",
        },
      });
      graph.on("node:click", async (evt) => {
        const item = evt.item;
        const nodeModel = item.getModel();
        const { classify, leaf, children } = nodeModel;
        if (["product", "country", "company"].includes(classify) && children.length === 0 && !leaf) {
          const value = classify !== "country" ? nodeModel.label : nodeModel.label2;
          const valueType = classifyToValueType[classify];
          const direction = nodeModel.direction;
          this.control.loading = true;
          const children = await this.getChildren(value, valueType, direction);
          this.control.loading = false;
          const nodeId = item.getID();
          graph.updateChildren(children, nodeId);
          nodeModel.collapsed = !nodeModel.collapsed;
          graph.layout();
        } else if (classify.includes("more")) {
          const parentItem = item.getNeighbors()[0];
          const parentNodeModel = parentItem.getModel();
          const grandFatherItem = parentItem.getNeighbors()[0];
          const grandFatherNodeModel = grandFatherItem.getModel();
          const value = grandFatherNodeModel.label;
          const grandFatherClassify = grandFatherNodeModel.classify;
          const valueType = grandFatherClassify === "root" ? (direction === "left" ? 1 : 0) : classifyToValueType[grandFatherNodeModel.classify];
          const direction = nodeModel.direction;
          const reportType = labelToReportType[parentNodeModel.label];
          const id = nodeModel.id;
          const leaf = nodeModel.leaf;
          this.control.loading = true;
          const more = await this.getMore(value, valueType, direction, reportType, leaf, id);
          if (more.length === 5) {
            const moreNode = parentNodeModel.children[parentNodeModel.children.length - 1];
            const oldChildren = parentNodeModel.children.slice(0, parentNodeModel.children.length - 1);
            const children = oldChildren.concat(more).concat([moreNode]);
            graph.updateChildren(children, parentNodeModel.id);
          } else {
            const moreNode = parentNodeModel.children[parentNodeModel.children.length - 1];
            const oldChildren = parentNodeModel.children.slice(0, parentNodeModel.children.length - 1);
            const children = oldChildren.concat(more).concat([moreNode]);
            children.pop();
            graph.updateChildren(children, parentNodeModel.id);
          }
          this.control.loading = false;
        } else if (nodeModel.classify.includes('second')) {
          if (nodeModel.children.length === 0) {
            Message.warning('无数据')
          }
        }
        graph.setItemState(item, "collapsed", nodeModel.collapsed);
      });
      const data = await this.getInitData();
      if (!data) {
        throw new Error("initData失败");
      }
      graph.edge((edge) => {
        const targetId = edge.target;
        const targetNode = graph.findById(targetId);
        const direction = targetNode.getModel().direction;
        const nodeClassify = targetNode.getModel().classify;
        if (nodeClassify.includes("second")) {
          if (direction === "left") {
            return {
              type: "step-line-okj6uhgb",
              sourceAnchor: 0,
              targetAnchor: 1,
            };
          }
          return {
            type: "step-line-okj6uhgb",
            sourceAnchor: 1,
            targetAnchor: 0,
          };
        } else {
          if (direction === "left") {
            return {
              type: "step-line-okj6uhgb",
              sourceAnchor: 0,
              targetAnchor: 1,
              color: classifyToColor[nodeClassify],
            };
          }
          return {
            type: "step-line-okj6uhgb",
            sourceAnchor: 1,
            targetAnchor: 0,
            color: classifyToColor[nodeClassify],
          };
        }
      });
      graph.data(data);
      graph.render();
      graph.fitCenter();
    } catch (error) {
      console.log({
        position: "新树图错误",
        message: error,
      });
      this.control.show = false;
      this.setAnchorFlag([["QYTPRECT", false]]);
    }
    new AnchorInditor(() => {
      this.setCurrent("QYTPRECT");
    }, this.$refs["title"]);
  },
};
</script>

<style lang="scss" scoped>
@import "@/firm/scss/mixin.scss";

.tree-rect-dasf3rrff2-new {
  box-sizing: border-box;
  margin-top: 1em;
  background: #fff;
  padding: 1rem;
  padding-top: 0;
  @include mixin-panel-title;
  &.full {
    margin: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999;
  }
}
.graph {
  height: 550px;
  &.full {
    height: calc(100vh - 64px - 10px);
  }
}
</style>
