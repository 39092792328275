<template>
    <div class="tabCard">
        <el-tabs type="border-card">
            <el-tab-pane label="应用信息">
                <Info />
            </el-tab-pane>
            <el-tab-pane label="应用IP白名单">
                <Whitelist />
            </el-tab-pane>
            <el-tab-pane label="应用IP黑名单">
                <Blacklist />
            </el-tab-pane>
            <el-tab-pane label="网络钩子">
                <SetHook />
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import Info from "./components/Info.vue"
import Whitelist from "./components/Whitelist.vue"
import Blacklist from "./components/Blacklist.vue"
import SetHook from "./components/SetHook.vue"
export default {
    components: { Info,Whitelist,Blacklist,SetHook },
    data() {
        return {

        };
    },
    methods: {

    },
    mounted() {
        
    },
};
</script>

<style lang="scss" scoped>
.tabCard{
    width: 1000px;
    margin: 20px auto 0;
    background: #FFFFFF;
}
</style>