<template>
<div>
    <div v-if="ie=='i'">
        <!-- 印度进口提单明细 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th width="25%">发票币种</th>
                        <td width="25%">{{billInfo.InvoiceCurrency}}</td>
                        <th width="25%">发票号码</th>
                        <td width="25%">{{billInfo.InvoiceNumber}}</td>
                    </tr>
                    <tr>
                        <th>CIF外币发票单价</th>
                        <td>{{billInfo.UnitValueInFC}}</td>
                        <th>CIF外币发票总价</th>
                        <td>{{billInfo.TotalValueInFC}}</td>
                    </tr>
                    <tr>
                        <th>卢比单价</th>
                        <td>{{billInfo.UnitValueInINR}}</td>
                        <th>卢比总价</th>
                        <td>{{billInfo.TotalValueInINR}}</td>
                    </tr>
                    <tr>
                        <th>税款总额</th>
                        <td>{{billInfo.DutyAmount}}</td>
                        <th>货币汇率</th>
                        <td>{{billInfo.CurrencyRate}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">运输信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">来源国</th>
                        <td width="25%">{{billInfo.Country}}</td>
                        <th width="25%">印度港口</th>
                        <td width="25%">{{billInfo.IndianPortName}}</td>
                    </tr>
                    <tr>
                        <th>运输方式</th>
                        <td>{{billInfo.ModeShipment}}</td>
                        <th>印度港口代码</th>
                        <td>{{billInfo.PortCode}}</td>
                    </tr>
                    <tr>
                        <th>起运港</th>
                        <td colspan="3">{{billInfo.OriginPort}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
    <div v-if="ie=='e'">
        <!-- 印度出口提单明细 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th width="25%">外币</th>
                        <td width="25%">{{billInfo.InvoiceCurrency}}</td>
                        <th width="25%">货币汇率</th>
                        <td width="25%">{{billInfo.CurrencyRate}}</td>
                    </tr>
                    <tr>
                        <th>FOB外币单价</th>
                        <td>{{billInfo.UnitValueInFC}}</td>
                        <th>FOB外币总价</th>
                        <td>{{billInfo.FobInFC}}</td>
                    </tr>
                    <tr>
                        <th>卢比单价</th>
                        <td>{{billInfo.UnitValueInINR}}</td>
                        <th>卢比总价</th>
                        <td>{{billInfo.TotalValueInINR}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">运输信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">目的国</th>
                        <td width="25%">{{billInfo.Country}}</td>
                        <th width="25%">目的港</th>
                        <td width="25%">{{billInfo.DestinationPort}}</td>
                    </tr>
                    <tr>
                        <th>印度港口</th>
                        <td>{{billInfo.IndianPortName}}</td>
                        <th>印度港口代码</th>
                        <td>{{billInfo.PortCode}}</td>
                    </tr>
                    <tr>
                        <th>发票号码</th>
                        <td>{{billInfo.InvoiceNo}}</td>
                        <th>运输方式</th>
                        <td>{{billInfo.ModeShipment}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
</div>
</template>

<script>
export default {
    props:["billInfo","ie"],
    data() {
        return {

        };
    },
    methods: {
        
    },
    mounted() {

    },
};
</script>

<style lang="scss" scoped>
.partItem {
    margin-bottom: 20px;
    /deep/.el-card__header {
        padding: 10px 20px;
        .headTitle {
            display: inline-block;
            padding-left: 10px;
            font-size: 14px;
            border-left: 2px solid var(--themeColor);
            font-weight: bold;
        }
    }
}
table {
    width: 100%;
    border-collapse: collapse;
    tr {
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;
        th,
        td {
            font-size: 12px;
            color: #666;
            height: 40px;
            border: 1px solid #eaeaea;
            text-align: center;
        }
        th {
            background-color: #f5f7fa;
            font-weight: 700;
        }
        .bg {
            background-color: var(--themeColor);
            color: #fff;
            font-weight: 700;
            opacity: 0.8;
        }
    }
}
</style>