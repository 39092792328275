<script>
import Vue from "vue";
import { ref, reactive, defineComponent } from "@vue/composition-api";
import VueDraggable from "vuedraggable";
import axios from "@/assets/js/axios";
import { Message } from "element-ui";
export default defineComponent({
    components: {
        VueDraggable,
    },
    setup() {
        const _helper = {
            processKey: "",
        };
        const loading = ref(false);
        const form = reactive({
            first: [],
            second: [],
        });
        const getAllStates = async () => {
            try {
                form.first = [];
                form.second = [];
                loading.value = true;
                const res = await axios({
                    method: "GET",
                    url: Vue.prototype.$sjUrl + "/BusinessChance/GetStages",
                });
                _helper.processKey = res.data[0].processKey;
                res.data.forEach((item) => {
                    if (item.stageName !== "输单") {
                        form[item.isWinStatge ? "second" : "first"].push({
                            key: item.stageKey,
                            processKey: _helper.processKey,
                            stageName: item.stageName,
                            isWinStatge: item.isWinStatge,
                        });
                    }
                });
            } catch {
                null;
            } finally {
                loading.value = false;
            }
        };
        getAllStates();
        const handleNewButtonClick = (type) => {
            if (form.first.length + form.second.length >= 10) {
                Message.warning("最多添加十个阶段");
                return;
            }
            form[type].push({
                key: "",
                processKey: _helper.processKey,
                stageName: "",
                isWinStatge: type === "second",
            });
        };
        const handleDeleteClick = (type, index) => {
            const key = form[type][index].key;
            if (key) {
                dialog.stageKey = key;
                dialog.newStageKey = "";
                dialog.visible = true;
            } else {
                form[type].splice(index, 1);
            }
        };
        const handleSaveClick = () => {
            for (const item of form.first) {
                if (!item.stageName) {
                    Message.warning("请输入商机阶段");
                    return;
                }
            }
            for (const item of form.second) {
                if (!item.stageName) {
                    Message.warning("请输入商机阶段");
                    return;
                }
            }
            loading.value = true;
            Promise.all([
                ...form.first.map(
                    (item, index) =>
                        new Promise((resovle, reject) => {
                            (async () => {
                                const res = await axios({
                                    method: "POST",
                                    url:
                                        Vue.prototype.$sjUrl +
                                        "/Stage/UpdateStage",
                                    data: {
                                        key: item.key,
                                        processKey: _helper.processKey,
                                        stageName: item.stageName,
                                        isWinStatge: item.isWinStatge,
                                        index: index,
                                    },
                                });
                                if (res.isSuccess) {
                                    resovle(true);
                                } else {
                                    reject(false);
                                }
                            })();
                        })
                ),
                ...form.second.map(
                    (item, index) =>
                        new Promise((resovle, reject) => {
                            (async () => {
                                const res = await axios({
                                    method: "POST",
                                    url:
                                        Vue.prototype.$sjUrl +
                                        "/Stage/UpdateStage",
                                    data: {
                                        key: item.key,
                                        processKey: _helper.processKey,
                                        stageName: item.stageName,
                                        isWinStatge: item.isWinStatge,
                                        index: index + form.first.length,
                                    },
                                });
                                if (res.isSuccess) {
                                    resovle(true);
                                } else {
                                    reject(false);
                                }
                            })();
                        })
                ),
            ])
                .then(() => {
                    Message.success("保存成功");
                })
                .finally(() => {
                    loading.value = false;
                });
        };
        const dialog = reactive({
            loading: false,
            visible: false,
            stageKey: "",
            newStageKey: "",
            options: [],
        });
        const onDialogOpen = async () => {
            try {
                dialog.options = [];
                const res = await axios({
                    method: "GET",
                    url: Vue.prototype.$sjUrl + "/BusinessChance/GetStages",
                });
                res.data.forEach((item) => {
                    if (
                        item.stageName !== "输单" &&
                        item.stageKey !== dialog.stageKey
                    ) {
                        dialog.options.push({
                            key: item.stageKey,
                            stageName: item.stageName,
                        });
                    }
                });
            } catch {
                dialog.options = [];
            }
        };
        const handleDeleteConfirmClick = async () => {
            try {
                if (!dialog.newStageKey) {
                    Message.warning("请选择转移商机阶段");
                    return;
                }
                dialog.loading = true;
                const res = await axios({
                    method: "GET",
                    url: Vue.prototype.$sjUrl + "/Stage/DeleteStage",
                    params: {
                        stageKey: dialog.stageKey,
                        newStageKey: dialog.newStageKey,
                    },
                });
                if (res.isSuccess) {
                    Message.success("操作成功");
                    dialog.visible = false;
                    getAllStates();
                }
            } catch {
                null;
            } finally {
                dialog.loading = false;
            }
        };
        return {
            loading,
            form,
            handleNewButtonClick,
            handleDeleteClick,
            handleSaveClick,
            dialog,
            onDialogOpen,
            handleDeleteConfirmClick,
            isAdmin: JSON.parse(localStorage.userInfo).isAdmin,
        };
    },
});
</script>

<template>
    <div class="flow-dialog" v-loading="loading">
        <el-dialog title="删除商机阶段" :visible.sync="dialog.visible" @open="onDialogOpen">
            <el-form label-width="120px" size="small" v-loading="dialog.loading">
                <el-form-item label="转移商机阶段">
                    <el-select v-model="dialog.newStageKey">
                        <el-option v-for="item of dialog.options" :key="item.key" :value="item.key" :label="item.stageName" />
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button @click="dialog.visible = false">取 消</el-button>
                    <el-button @click="handleDeleteConfirmClick" type="primary">确 定</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <span v-if="!isAdmin" class="setTipText">非管理员无法设置操作,请联系管理员操作</span>
        <div class="body">
            <div style="box-sizing: border-box; height: calc(100% - 50px)">
                <div class="block">
                    <div class="block-title">
                        <span>推进状态阶段：</span>
                        <el-button @click="handleNewButtonClick('first')" icon="el-icon-plus" size="small" type="primary" v-if="isAdmin">新增</el-button>
                    </div>
                    <div class="block-content">
                        <VueDraggable v-model="form.first">
                            <div v-for="(item, index) of form.first" :key="index" class="item">
                                <div class="circle first">
                                    <span class="first">{{ index + 1 }}</span>
                                </div>
                                <div style="box-sizing: border-box; padding: 0 40px 0 30px; width: 100%; height: 100%; display: flex; align-items: center; justify-content: space-between; border-bottom: 1px solid #D5D5D5">
                                    <el-input v-model.trim="item.stageName" style="width: 300px" size="small" />
                                    <div style="display: flex; align-items: center">
                                        <span @click="handleDeleteClick('first', index)" role="button" class="delete" v-if="isAdmin">删除</span>
                                    </div>
                                </div>
                            </div>
                        </VueDraggable>
                    </div>
                </div>
                <div class="block">
                    <div class="block-title">
                        <span>赢单状态阶段：</span>
                        <el-button @click="handleNewButtonClick('second')" icon="el-icon-plus" size="small" type="primary" v-if="isAdmin">新增</el-button>
                    </div>
                    <div class="block-content">
                        <VueDraggable v-model="form.second">
                            <div v-for="(item, index) of form.second" :key="index" class="item">
                                <div class="circle second">
                                    <span class="second">{{ form.first.length + index + 1 }}</span>
                                </div>
                                <div style="box-sizing: border-box; padding: 0 40px 0 30px; width: 100%; height: 100%; display: flex; align-items: center; justify-content: space-between; border-bottom: 1px solid #D5D5D5">
                                    <el-input v-model.trim="item.stageName" style="width: 300px" size="small" />
                                    <div style="display: flex; align-items: center">
                                        <span @click="handleDeleteClick('second', index)" role="button" class="delete" v-if="isAdmin">删除</span>
                                    </div>
                                </div>
                            </div>
                        </VueDraggable>
                    </div>
                </div>
            </div>
        </div>
        <div class="foot">
            <el-button v-if="isAdmin" @click="handleSaveClick" type="primary" size="small">保存</el-button>
        </div>
    </div>
</template>

<style scoped lang="scss">
.flow-dialog {
    box-sizing: border-box;
    height: 100%;
    overflow-y: auto;
    font-size: 16px;
}
.top {
    box-sizing: border-box;
    height: 60px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    color: #3a3a3a;
    border-bottom: 1px solid #d9d9d9;
}
.block {
    box-sizing: border-box;
    height: 50%;
}
.block-title {
    box-sizing: border-box;
    height: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #092338;
    font-weight: bold;
}
.item {
    cursor: move;
    box-sizing: border-box;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:first-child {
        .circle {
            &::before {
                height: 50%;
                bottom: 0;
            }
        }
    }
    &:last-child {
        .circle {
            &::before {
                height: 50%;
                top: 0;
            }
        }
    }
    &:only-child {
        .circle {
            &::before {
                display: none;
            }
        }
    }
    .circle {
        position: relative;
        flex-shrink: 0;
        box-sizing: border-box;
        height: 100%;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        &::before {
            display: block;
            content: "";
            width: 1px;
            height: 100%;
            position: absolute;
            z-index: 2;
        }
        &.first {
            &::before {
                background: #33a76f;
            }
        }
        &.second {
            &::before {
                background: #4480cf;
            }
        }
        span {
            z-index: 3;
            display: inline-block;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            &.first {
                background: #33a76f;
            }
            &.second {
                background: #4480cf;
            }
        }
    }
}
span[role="button"] {
    cursor: pointer;
    display: inline-block;
    box-sizing: border-box;
    width: 40px;
    height: 20px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    border-style: solid;
    border-width: 1px;
    &.edit {
        background: rgba(124, 144, 165, 0.1);
        color: #7c90a5;
        border-color: #7c90a5;
    }
    &.done {
        background: #f0f9eb;
        color: #67c23a;
        border-color: #67c23a;
    }
    &.delete {
        background: rgba(255, 86, 86, 0.1);
        color: #ff5656;
        border-color: #ff5656;
    }
    &:not(:last-of-type) {
        margin-right: 10px;
    }
}
.foot {
    box-sizing: border-box;
    height: 80px;
    padding: 0 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid #d9d9d9;
    span {
        line-height: 32px;
        font-size: 14px;
        color: #6a6a6a;
        padding-right: 20px;
    }
}
</style>