<template>
    <div>
        <InterfaceName :interfaceName="interfaceName" />
        <InterfaceSpe :requestUrl="requestUrl" :requestMethod="requestMethod" />
        <InputParam :inputTableData="inputTableData" />
        <ReturnParam :returnObj="returnObj" />
    </div>
</template>

<script>
import InterfaceName from "../Acomponents/InterfaceName.vue";
import InterfaceSpe from "../Acomponents/InterfaceSpe.vue";
import InputParam from "../Acomponents/InputParam.vue";
import ReturnParam from "../Acomponents/ReturnParam.vue";

export default {
    components: { InterfaceName, InterfaceSpe, InputParam, ReturnParam },
    data() {
        return {
            interfaceName: "保存客户信息",
            requestUrl: "https://crm.qixinerp.com/open/Customer/Save",
            requestMethod: "POST",
            inputTableData: [],
            returnObj: {},
        };
    },
    methods: {},
    mounted() {
        this.inputTableData = [
            {paramName: "companyKey", valueType: "string", explain: "公司标识", required: true},
            {paramName: "type", valueType: "integer", explain: "0公海 1私海", required: true},
            {paramName: "name", valueType: "string", explain: "公司名称", required: true},
            {paramName: "nickname", valueType: "string", explain: "公司昵称", required: false},
            {paramName: "customerSourceKey", valueType: "string", explain: "公司来源", required: false},
            {paramName: "mainIndustryValue", valueType: "string", explain: "主营行业", required: false},
            {paramName: "mainBusinessTypeValue", valueType: "string", explain: "客户主要商业类型", required: false},
            {paramName: "companyScaleValue", valueType: "string", explain: "公司规模", required: false},
            {paramName: "country", valueType: "string", explain: "国家", required: true},
            {paramName: "star", valueType: "integer", explain: "星级(1~5)", required: true},
            {paramName: "webSite", valueType: "string", explain: "公司官网", required: false},
            {paramName: "fax", valueType: "string", explain: "传真", required: false},
            {paramName: "call", valueType: "string", explain: "电话号码，分号直接拼接", required: false},
            {paramName: "address", valueType: "string", explain: "地址", required: false},
            {paramName: "privateGroupKey", valueType: "string", explain: "私海组", required: false},
            {paramName: "publicGroupKey", valueType: "string", explain: "公海组", required: false},
            {paramName: "statuKey", valueType: "string", explain: "公司状态", required: false},
            {paramName: "tradeType", valueType: "string", explain: "外/内贸", required: false},
            {paramName: "note", valueType: "string", explain: "备注", required: false},
            {paramName: "contacters", valueType: "array", explain: "联系人", required: true},
            {paramName: "contacters:isMain", valueType: "boolean", explain: "是否主要联系人", required: true},
            {paramName: "contacters:nickname", valueType: "string", explain: "昵称", required: true},
            {paramName: "contacters:email", valueType: "string", explain: "邮箱", required: true},
            {paramName: "contacters:workRank", valueType: "string", explain: "职级", required: false},
            {paramName: "contacters:position", valueType: "string", explain: "职位", required: false},
            {paramName: "contacters:call", valueType: "string", explain: "联系电话,多个用英文分号拼接;", required: false},
            {paramName: "contacters:linkedin", valueType: "string", explain: "linkedin社交平台", required: false},
            {paramName: "contacters:twitter", valueType: "string", explain: "twitter社交平台", required: false},
            {paramName: "contacters:gender", valueType: "string", explain: "性别", required: false},
            {paramName: "contacters:birthday", valueType: "string", explain: "生日", required: false},
            {paramName: "contacters:note", valueType: "string", explain: "备注", required: false},
        ]
        this.returnObj = {
            isSuccess: true,
            errorMessage: null,
            data: true,
        };
    },
};
</script>

<style lang="scss" scoped>

</style>