export const MODE = 'json' // 'json' | 'excel'

const BASE_URL_EXCEL = 'http://localhost:3000/api/excel'
const BASE_URL_JSON = 'http://localhost:3000/api/json'

const BASE_URL_ASSET_EXCEL = 'http://localhost:3000/static/excel'
const BASE_URL_ASSET_JSON = 'http://localhost:3000/static/json'

export const BASE_URL = (() => {
  if (MODE === 'excel') {
    return BASE_URL_EXCEL
  } else {
    return BASE_URL_JSON
  }
})()

export const BASE_URL_ASSET = (() => {
  if (MODE === 'excel') {
    return BASE_URL_ASSET_EXCEL
  } else {
    return BASE_URL_ASSET_JSON
  }
})()
