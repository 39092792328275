<template>
    <div class="viewBox">
        <div class="title">
            <span>产品采集结果</span>
            <el-button size="small" @click="toGather">产品采集</el-button>
        </div>
        <el-table v-loading="tableLoading" :data="resultList" :header-cell-style="{'background-color':'#F2F2F2','color':'#3A3A3A'}">
            <el-table-column prop="url" label="网络地址"></el-table-column>
            <el-table-column prop="createTime" label="提交时间"></el-table-column>
            <el-table-column prop="statusStr" label="状态">
                <template slot-scope="scope">
                    <!-- 0待执行 1执行中 2已完成 3阿里巴巴地址错误 -->
                    <span v-if="scope.row.status == 0" style="color:#909399">{{scope.row.statusStr}}</span>
                    <span v-if="scope.row.status == 1" style="color:var(--themeColor);">{{scope.row.statusStr}}</span>
                    <span v-if="scope.row.status == 2" style="color:#67C23A">{{scope.row.statusStr}}</span>
                    <span v-if="scope.row.status == 3" style="color:#F56C6C">{{scope.row.statusStr}}</span>
                </template>
            </el-table-column> 
            <el-table-column label="操作" width="200">
                <template slot-scope="scope">
                    <el-link class="handle" v-if="scope.row.status == 2" type="primary" @click="onPreview(scope.row)">预览产品</el-link>
                    <el-link class="handle" type="primary" @click="onSync(scope.row)">重新同步</el-link>
                    <el-link class="handle" type="info" @click="onDel(scope.row)">删除</el-link>
                </template>
            </el-table-column>
        </el-table>
        
        <el-drawer title="预览产品" :visible.sync="drawerVisible" :with-header="false" :size="800">
            <div class="drawerContent">
                <div class="title">公司信息：</div>
                <div class="infoItem">公司名称：{{companyInfo.companyName}}</div>
                <div class="infoItem">阿里巴巴网址：{{companyInfo.aibabaHost}}</div>
                <div class="infoItem">网址：{{companyInfo.website}}</div>
                <div class="infoItem">国家：{{companyInfo.country}}</div>
                <div class="infoItem">省份：{{companyInfo.province}}</div>
                <div class="infoItem">城市：{{companyInfo.city}}</div>
                <div class="infoItem">地址：{{companyInfo.address}}</div>
                <div class="title" style="margin-top:20px;">
                    <span>产品信息：</span>
                    <el-button size="mini" @click="joinVisible = true" type="primary" style="margin-left: 16px;">加入我的产品</el-button>
                </div>
                <el-table :data="aShowList" :header-cell-style="{'background-color':'#F2F2F2','color':'#3A3A3A'}">
                    <el-table-column label="产品图片" width="80">
                        <template slot-scope="scope">
                            <el-image style="width:50px;height:50px;" :src="scope.row.images" :preview-src-list="[scope.row.images]"></el-image>
                        </template>
                    </el-table-column>
                    <el-table-column prop="productName" label="产品名称"></el-table-column>
                </el-table>
                <el-pagination background layout="total,prev,pager,next" :total="alibabaProducts.length" style="float:right;margin-top:10px;" :current-page.sync="aPageIndex"></el-pagination>
            </div>
        </el-drawer>

        <el-dialog title="加入我的产品" :visible.sync="joinVisible" width="1200px" top="50px">
            <div style="overflow: hidden;">
                <el-table height="calc(100vh - 250px)" :data="bShowList" :header-cell-style="{'background-color':'#F2F2F2','color':'#3A3A3A'}" @selection-change="selectionChange">
                    <el-table-column type="selection" width="45"></el-table-column>
                    <el-table-column prop="productNo" label="产品编号">
                        <template slot-scope="scope">
                            <el-input v-model="scope.row.productNo" size="mini"></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column label="产品图片" width="80">
                        <template slot-scope="scope">
                            <el-image style="width:50px;height:50px;" :src="scope.row.images" :preview-src-list="[scope.row.images]"></el-image>
                        </template>
                    </el-table-column>
                    <el-table-column prop="productName" label="产品名称">
                        <template slot-scope="scope">
                            <el-input v-model="scope.row.productName" size="mini"></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column prop="dimension" label="尺寸信息">
                        <template slot-scope="scope">
                            <el-input v-model="scope.row.dimension" size="mini"></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column prop="ingredient" label="成分">
                        <template slot-scope="scope">
                            <el-input v-model="scope.row.ingredient" size="mini"></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column prop="supplier" label="供应商">
                        <template slot-scope="scope">
                            <el-input v-model="scope.row.supplier" size="mini"></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column prop="sellPrice" label="成本价">
                        <template slot-scope="scope">
                            <el-input-number v-model="scope.row.sellPrice" :min="0" :controls="false" size="mini" style="width:100px;"></el-input-number>
                        </template>
                    </el-table-column>
                    <el-table-column prop="measureUnit" label="计量单位">
                        <template slot-scope="scope">
                            <el-input v-model="scope.row.measureUnit" size="mini"></el-input>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total,prev,pager,next" :total="alibabaProducts.length" style="float:right;margin-top:10px;" :current-page.sync="bPageIndex"></el-pagination>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" :disabled="checkList.length == 0" :loading="btnLoading" @click="addProduct">加入我的产品</el-button>
            </span>
        </el-dialog>

        <!-- 产品采集 -->
        <el-dialog title="产品采集：提交阿里巴巴店铺网址" :visible.sync="gatherVisible" width="500px">
            <div class="productGather">
                <div class="describe">
                    输入店铺网址后，系统将会自动采集该网址产品，采集完毕后，用户自行选择导入产品。
                </div>
                <el-input placeholder="请输入链接地址" v-model="writeUrl" size="small"></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" :disabled="!writeUrl" @click="gatherSubmit" :loading="btnLoading">提 交</el-button>
                <el-button size="small" @click="gatherVisible = false">取 消</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
export default {
    data() {
        return {
            tableLoading: false,
            resultList: [],
            drawerVisible: false,
            companyInfo: {},
            alibabaProducts: [],
            aPageIndex: 1,
            bPageIndex: 1,
            //加入我的产品
            joinVisible: false,
            checkList: [],
            btnLoading: false,
            //产品采集
            gatherVisible: false,
            writeUrl: "",
        };
    },
    computed: {
        aShowList(){
            return this.alibabaProducts.slice((this.aPageIndex-1)*10,this.aPageIndex*10);
        },
        bShowList(){
            return this.alibabaProducts.slice((this.bPageIndex-1)*10,this.bPageIndex*10);
        }
    },
    methods: {
        //产品采集
        toGather(){
            this.gatherVisible = true;
            this.writeUrl = "";
        },
        gatherSubmit(){
            this.btnLoading = true;
            this.$axios.get(`${this.$productUrl}/api/Product/SubmitAlibabaHost?url=${this.writeUrl}`).then((res) => {
                const { isSuccess } = res;
                this.btnLoading = false;
                this.gatherVisible = false;
                if (isSuccess) {
                    this.$message({ message: '提交成功', type: 'success'});
                    this.getResult();
                }
            });
        },

        //重新同步
        onSync(row){
            this.$axios.get(`${this.$productUrl}/api/Product/AgainAlibabaProduct?key=${row.key}`).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    this.$message({type: 'success',message: '数据采集中,请稍后查看!' });
                    this.getResult();
                }
            });
        },
        //删除
        onDel(row){
            this.$confirm('是否删除该条采集结果?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios.get(`${this.$productUrl}/api/Product/DeleteAlibabaHost?key=${row.key}`).then((res) => {
                    const { isSuccess } = res;
                    if (isSuccess) {
                        this.$message({type: 'success',message: '删除成功!' });
                        this.getResult();
                    }
                });
            }).catch(() => {});
        },

        //获取采集结果
        getResult(){
            this.tableLoading = true;
            this.$axios.get(`${this.$productUrl}/api/Product/GetAlibabaHostList`).then((res) => {
                const { isSuccess , data } = res;
                this.tableLoading = false;
                if (isSuccess) {
                    this.resultList = data;
                }
            });
        },
        //预览产品
        onPreview(row){
            this.drawerVisible = true;
            this.$axios.get(`${this.$productUrl}/api/Product/GetHostDetail?key=${row.key}`).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    this.companyInfo = data.companyInfo;
                    this.alibabaProducts = data.alibabaProducts;
                }
            });
        },
        //加入我的产品
        selectionChange(value){
            this.checkList = value;
        },
        addProduct(){
            this.btnLoading = true;
            this.$axios.post(`${this.$productUrl}/api/Product/BatchAddProduct`,this.checkList).then((res) => {
                const { isSuccess } = res;
                this.btnLoading = false;
                this.joinVisible = false;
                if (isSuccess) {
                    this.$message({ message: '加入成功', type: 'success'});
                }
            });
        },
    },
    mounted() {
        this.getResult(); //获取采集结果
    },
};
</script>

<style lang="scss" scoped>
.viewBox {
    width: 1200px;
    min-height: 800px;
    margin: auto;
    background: #ffffff;
    padding: 20px;

    .title {
        font-size: 24px;
        font-weight: bold;
        color: #3A3A3A;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
    }

    .handle{
        margin-right: 10px;
    }
}
.drawerContent{
    padding: 20px;
    overflow: hidden;
    .title{
        font-size: 16px;
        font-weight: bold;
        color: #3A3A3A;
        margin-bottom: 10px;
    }
    .infoItem{
        font-size: 14px;
        line-height: 28px;
        color: #3A3A3A;
    }
}
/deep/.el-dialog__body{
    padding: 10px 20px;
}
</style>