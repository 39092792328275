<template>
    <div>
        <InterfaceName :interfaceName="interfaceName" />
        <InterfaceSpe :requestUrl="requestUrl" :requestMethod="requestMethod" />
        <InputParam :inputTableData="inputTableData" />
        <ReturnParam :returnObj="returnObj" />
    </div>
</template>

<script>
import InterfaceName from "../Acomponents/InterfaceName.vue";
import InterfaceSpe from "../Acomponents/InterfaceSpe.vue";
import InputParam from "../Acomponents/InputParam.vue";
import ReturnParam from "../Acomponents/ReturnParam.vue";

export default {
    components: { InterfaceName, InterfaceSpe, InputParam, ReturnParam },
    data() {
        return {
            interfaceName: "获取采购记录详情",
            requestUrl: "https://crm.qixinerp.com/open/Procure/PurchasingRecord/Detail",
            requestMethod: "GET",
            inputTableData: [],
            returnObj: {},
        };
    },
    methods: {},
    mounted() {
        this.inputTableData = [
            {paramName: "purchasingRecordKey", valueType: "string", explain: "采购记录key", required: true},
        ]
        this.returnObj = {
            "isSuccess": true,
            "errorMessage": null,
            "data": {
                "createTime": "2023-05-10 11:14:33",
                "purchasingTotalNum": 500,
                "purchasingTotalPrice": 0,
                "productTotalNum": 500,
                "productTotalPrice": 0,
                "tradeExpensesTotalPrice": 0,
                "allTotalPrice": 0,
                "key": "35d70d004dbc44b4b87488a2cd91740d",
                "procurementPlan": "搅拌杯需求采购计划",
                "purchaseNo": "23051066728",
                "purchaseDate": "2023-05-10",
                "deliveryDate": "2023-05-25",
                "followerKey": "af19a3cbd7094bafa19e629b04b482ae",
                "followerName": "彭贤鑫",
                "customerName": "",
                "customerKey": "",
                "manufacturerKey": "83082d5a5bb949d8937499bd61f54992",
                "manufacturerName": "帝擎信息科技（上海）有限公司",
                "manufacturerNo": "23042164578",
                "purchasingProducts": [
                    {
                        "productKey": "6fd18b428fea414f8493c09fc0482495",
                        "productNo": "CP96511",
                        "productName": "搅拌杯",
                        "specifications": "",
                        "productDescription": "",
                        "totalPrice": 0,
                        "num": 500,
                        "measureUnit": "个",
                        "otherExpenses": 0,
                        "currency": "CNY",
                        "remarks": "",
                        "exchangeRate": "6.9185",
                        "cnyTotalPrice": 0,
                        "unitPrice": 0
                    }
                ],
                "settlementCurrency": "CNY",
                "contactsKey": "a8714253e5ae425b9e08b9fc3531cf2f",
                "tradeExpenses": []
            },
        }
    },
};
</script>

<style lang="scss" scoped>

</style>