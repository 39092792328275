<template>
  <div id="abroad-address" class="abroad-address" v-show="!hidden">
    <div class="title" ref="title">
      <div class="text">
        <p>企业地址</p>
        <span class="sub-title">{{ `${locationAddress}` }}</span>
      </div>
      <feedback position="企业地址" url="" />
      <!-- <div class="flex-container">
        <img class="icon" src="@/firm/images/company/icon-feedback.png" style="margin-left: 1em" />
        <span class="text-with-icon" style="color: var(--themeColor, #3152AF)">反馈</span>
      </div> -->
    </div>
    <div>
      <div id="mapbox-instance-4ujhg6yb" style="height: 324px"></div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import mapboxgl from "@/firm/plugins/mapbox";
import { mapMutations } from "vuex";
import { AnchorInditor } from "../../../utils";

export default Vue.extend({
  props: ["location", "locationAddress"], // typeof location === [number, number]
  data() {
    return {
      hidden: false,
    };
  },
  methods: {
    ...mapMutations("anchor", {
      setAnchorFlag: "setAnchorFlag",
      setCurrent: "setCurrent",
    }),
  },
  mounted() {
    try {
      if (!Array.isArray(this.location)) {
        throw new Error();
      }
      const { 1: lng, 0: lat } = this.location;
      if (lng === '' && lat === '') throw new Error()
      const coordinate = [Number(lng), Number(lat)];
      for (const item of coordinate) {
        if (isNaN(item)) {
          throw new Error();
        }
      }
      const map = new mapboxgl.Map({
        container: "mapbox-instance-4ujhg6yb",
        style: "mapbox://styles/mapbox/streets-v10",
        center: coordinate, //[30.5, 50.5],
        zoom: 10,
      });
      new mapboxgl.Marker({
        color: "var(--themeColor, #3152AF)",
      })
        .setLngLat(coordinate /* [30.5, 50.5] */)
        .addTo(map);
      // 为地图绑定点击事件
      map.on("dblclick", (ev) => {
        console.log({
          经度: ev.lngLat.lng,
          纬度: ev.lngLat.lat,
        });
      });
      this.setAnchorFlag([["QYDZ", true]]);
    } catch (e) {
      console.log(e)
      this.hidden = true;
      this.setAnchorFlag([["QYDZ", false]]);
    }
    new AnchorInditor(() => {
      this.setCurrent("QYDZ");
    }, this.$refs["title"]);
  },
});
</script>

<style lang="scss" scoped>
@import "@/firm/scss/mixin.scss";

.abroad-address {
  background: #fff;
  padding: 1rem;
  padding-top: 0;
  @include mixin-panel-title;
}
</style>
