<template>
    <div>
        <div class="headerBox" v-loading="infoLoading">
            <el-descriptions title="企查查详情" v-if="infoObj" :column="2" border :labelStyle="{width:'15%'}" :contentStyle="{width:'35%'}">
                <el-descriptions-item label="公司名称">{{infoObj.companyName || "--"}}</el-descriptions-item>
                <el-descriptions-item label="国家">
                    <img :src="flagUrl" style="margin-right:5px;height:14px" />
                    <span>{{dataCountryCn}}</span>
                </el-descriptions-item>
                <el-descriptions-item label="省">{{infoObj.region || "--" }}</el-descriptions-item>
                <el-descriptions-item label="市">{{infoObj.city || "--" }}</el-descriptions-item>
                <el-descriptions-item label="地址" :span="2">{{infoObj.address || "--"}}</el-descriptions-item>
                <el-descriptions-item label="公司类型">{{infoObj.companyType || "--"}}</el-descriptions-item>
                <el-descriptions-item label="法人">{{infoObj.legalPerson || "--"}}</el-descriptions-item>
                <el-descriptions-item label="成立日期">{{infoObj.establishmentTime || "--"}}</el-descriptions-item>
                <el-descriptions-item label="经营状态">{{infoObj.managementState || "--"}}</el-descriptions-item>
                <el-descriptions-item label="统一社会信用代码">{{infoObj.unifiedSocialCreditCode || "--"}}</el-descriptions-item>
                <el-descriptions-item label="纳税人识别号">{{infoObj.taxpayerIdentificationNumber || "--"}}</el-descriptions-item>
                <el-descriptions-item label="工商注册号">{{infoObj.registrationNumber || "--"}}</el-descriptions-item>
                <el-descriptions-item label="组织机构代码">{{infoObj.organizationCode || "--"}}</el-descriptions-item>
                <el-descriptions-item label="经营范围" :span="2">{{infoObj.businessScope || "--"}}</el-descriptions-item>
                <el-descriptions-item label="所属行业" :span="2">{{infoObj.industry || "--"}}</el-descriptions-item>
                <el-descriptions-item label="邮箱">{{infoObj.email || "--"}}</el-descriptions-item>
                <el-descriptions-item label="电话">{{infoObj.call || "--"}}</el-descriptions-item>
                <el-descriptions-item label="更多电话">{{infoObj.moreCalls || "--"}}</el-descriptions-item>
                <el-descriptions-item label="官网">
                    <span class="primaryColor" @click="openWebsite(infoObj.website)">{{infoObj.website || "--"}}</span>
                </el-descriptions-item>
                <el-descriptions-item label="注册资金">{{infoObj.registeredCapital || "--" }}</el-descriptions-item>
                <el-descriptions-item label="保险编号">{{infoObj.insuredNumber || "--" }}</el-descriptions-item>
            </el-descriptions>
            <el-empty description="暂无数据" v-else></el-empty>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            company: "",
            dataCountryCn: "",
            flagUrl: "",
            infoLoading: false,
            infoObj: null,
        };
    },
    methods: {
        getCompanyInfo() {
            this.infoLoading = true;
            this.$axios.get(`${this.$clueUrl}/Product/QiChaCha?company=${this.company}`).then((res) => {
                const { isSuccess, data } = res;
                this.infoLoading = false;
                if (isSuccess) {
                    if(data){
                        this.infoObj = data;
                    }else{
                        this.$message({ message: '查询无数据',type: 'warning'});
                    }
                }
            });
        },

        openWebsite(url){
            let jumpUrl = url.indexOf("http") > -1 ? url : "http://" + url;
            let routeData = this.$router.resolve({
                path: "/jumpWeb",
                query: { jumpUrl: jumpUrl },
            });
            window.open(routeData.href, "_blank");
        },

    },
    mounted() {
        this.company = encodeURIComponent(this.$route.query.company);
        this.dataCountryCn = this.$route.query.dataCountryCn;
        this.flagUrl = window.getFlagUrlByCountry ? window.getFlagUrlByCountry(this.dataCountryCn) : "";
        this.getCompanyInfo();
    },
};
</script>

<style lang="scss" scoped>
.headerBox {
    background: #ffffff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    padding: 20px;
    font-size: 14px;
    position: relative;
    .name {
        display: inline-block;
        color: var(--themeColor);
    }
    .country {
        display: inline-block;
        margin-left: 20px;
    }
    .color99 {
        color: #999999;
    }
    .color3a {
        color: #3a3a3a;
    }
    .primaryColor {
        color: var(--themeColor);
        cursor: pointer;
    }
    .oneField {
        width: 200px;
        text-align: center;
    }
    .twoField {
        line-height: 28px;
    }
    .addBtn {
        position: absolute;
        top: 20px;
        right: 20px;
        padding: 0 10px;
        height: 28px;
        line-height: 28px;
        font-size: 14px;
        color: var(--themeColor);
        background: rgba(183, 183, 183, 0.2);
        border: 1px solid var(--themeColor);
        border-radius: 4px;
        cursor: pointer;
        &:hover {
            background: var(--themeColor);
            color: #ffffff;
        }
        img {
            position: absolute;
            left: 10px;
            top: 5px;
        }
    }
    .forbidden {
        cursor: not-allowed;
    }
    .hasAdd {
        position: absolute;
        top: 20px;
        right: 20px;
        padding: 0 10px;
        height: 28px;
        line-height: 28px;
        font-size: 14px;
        color: #b7b7b7;
        background: rgba(183, 183, 183, 0.2);
        border: 1px solid #b7b7b7;
        border-radius: 4px;
    }
}

.cardBox {
    background: #ffffff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    padding: 15px 20px;
    margin-top: 20px;
    .title {
        font-size: 20px;
        height: 20px;
        line-height: 20px;
        font-weight: bold;
        color: #3a3a3a;
        padding-left: 8px;
        border-left: 3px solid var(--themeColor);
        margin-bottom: 15px;
    }
    .sublevel {
        margin-top: 20px;
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 20px;
        font-weight: bold;
        color: #3a3a3a;
    }
    .progress {
        width: 240px;
        display: inline-block;
    }
}
</style>