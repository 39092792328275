<script>
import Vue from "vue";
import { reactive, ref } from "@vue/composition-api";
import axios from "@/assets/js/axios";
import dayjs from "dayjs";
import qs from "qs";
export default Vue.extend({
    setup() {
        const mode = ref("1");
        const url = window.location.href;
        const index = url.lastIndexOf("?");
        const campaignId = qs.parse(url.slice(index + 1))["campaignId"] || "";

        const panelData = reactive({
            eventName: "",
            sendTime: "",
            stateStr: "",
            totalCount: 0,
            sendCount: 0,
            openCount: 0,
            replyCount: 0,
        });
        const detatilll = ref({});
        const getTaskDetail = async () => {
            const res = await axios({
                method: "GET",
                url:
                    Vue.prototype.$marketUrl +
                    "/WhatsApp/v2/GetSendEventDetailed",
                params: {
                    eventKey: campaignId,
                },
            });
            const data = res.data;
            document.title = `营销模块-统计报告`;
            panelData.eventName = data.eventName;
            panelData.sendTime = data.sendTime;
            panelData.stateStr = data.stateStr;
            panelData.totalCount = data.totalCount;
            panelData.sendCount = data.sendCount;
            panelData.openCount = data.openCount;
            panelData.replyCount = data.replyCount;
            detatilll.value = data;
            // console.log(detatilll.value);
        };
        getTaskDetail();

        const addressee = reactive({
            loading: false,
            list: [],
            page: {
                total: 0,
                index: 1,
                size: 10,
            },
        });
        const getAddresseeList = async (options) => {
            try {
                addressee.loading = true;
                const res = await axios({
                    method: "POST",
                    url:
                        Vue.prototype.$marketUrl +
                        "/WhatsApp/GetSendEventContacts",
                    data: {
                        pageIndex: addressee.page.index,
                        pageSize: addressee.page.size,
                        eventKey: campaignId,
                        ...options,
                    },
                });
                addressee.list = res.data.datas;
                addressee.page.total = res.data.totalItems;
            } catch {
                null;
            } finally {
                addressee.loading = false;
            }
        };
        getAddresseeList();

        return {
            mode,
            dayjs,
            panelData,
            detatilll,
            campaignId,
            addressee,
            getTaskDetail,
            getAddresseeList,
        };
    },
});
</script>

<template>
    <div style="width: 100%; height: 100%">
        <div class="view">
            <div>
                <div style="display: flex; align-items: center; padding: 20px 0; padding-left: 30px">
                    <el-descriptions :column="1" :labelStyle="{ color: '#B1B1B1' }" :contentStyle="{ color: '#434343' }">
                        <el-descriptions-item label="任务名称">
                            {{ panelData.eventName }}
                        </el-descriptions-item>
                        <el-descriptions-item label="发信时间">
                            {{ panelData.sendTime ? dayjs(panelData.sendTime).format('MM-DD HH:mm') : "--" }}
                        </el-descriptions-item>
                        <el-descriptions-item label="发送状态">
                            {{ panelData.stateStr }}
                        </el-descriptions-item>
                    </el-descriptions>
                    <el-descriptions :column="4" direction="vertical" :labelStyle="{ color: '#B1B1B1' }">
                        <el-descriptions-item label="收件人数" :contentStyle="{ color: '#434343', fontSize: '18px' }">
                            {{ panelData.totalCount }}
                        </el-descriptions-item>
                        <el-descriptions-item label="送达总数" :contentStyle="{ color: '#13CE66', fontSize: '18px' }">
                            {{ panelData.sendCount }}
                        </el-descriptions-item>
                        <el-descriptions-item label="打开人数" :contentStyle="{ color: '#FA5151', fontSize: '18px' }">
                            {{ panelData.openCount }}
                        </el-descriptions-item>
                        <el-descriptions-item label="回复数" :contentStyle="{ color: '#FA5151', fontSize: '18px' }">
                            {{ panelData.replyCount }}
                        </el-descriptions-item>
                    </el-descriptions>
                </div>
            </div>
            <div style="height: calc(100% - 200px)">
                <el-tabs v-model="mode">
                    <el-tab-pane label="发信内容" name="1" />
                    <el-tab-pane label="收件人" name="2" />
                </el-tabs>
                <div class="content-wrapper">
                    <div v-show="mode === '1'" style="overflow-y: auto">
                        <div class="cardItem">
                            <div v-show="detatilll.sendFileUrl">
                                <img style="width:100%;" :src="detatilll.sendFileUrl" alt="" />
                            </div>
                            <div v-show="detatilll.sendText">
                                <p class="textContent">{{detatilll.sendText}}</p>
                            </div>
                            <div v-show="detatilll.link">
                                <div class="bottomLink">
                                    <img style="width:24px;height:24px;" src="@/assets/img/link.png" alt="" />
                                    <span>点击了解更多</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-show="mode === '2'" v-loading="addressee.loading">
                        <div style="height: 35px; background: #F9F9F9; display: flex; align-items: center; color: #475669; font-weight: bold">
                            <div style="box-sizing: border-box; width: 35%; padding-left: 30px">收件人</div>
                            <div style="width: 65%; text-align: center">状态</div>
                        </div>
                        <div style="height: calc(100% - 67px); overflow-y: auto">
                            <div class="item" style="padding-left: 30px" v-for="item of addressee.list" :key="item.senderAddress">
                                <div style="width: 35%; display: flex; align-items: center">
                                    <img src="@/assets/img/headImg.png" style="width: 50px; height: 50px" />
                                    <div style="padding-left: 20px; font-size: 14px">
                                        <p style="color: #1F2D3D; font-weight: bold">{{ item.name }}</p>
                                        <p>{{ item.whatsApp }}</p>
                                    </div>
                                </div>
                                <div style="width: 65%; display: flex; align-items: center">
                                    <el-descriptions :column="2" :labelStyle="{ color: '#1F2D3D' }" :contentStyle="{ color: '#1F2D3D' }">
                                        <el-descriptions-item label="是否验证">{{ item.isVerify ? '已验证' : '未验证' }}</el-descriptions-item>
                                        <el-descriptions-item label="是否看信">{{ item.isOpen ? '已读' : '未读' }}</el-descriptions-item>
                                        <el-descriptions-item label="是否回复">{{ item.isReply ? '已回复' : '未回复' }}</el-descriptions-item>
                                        <el-descriptions-item label="发送时间">{{ item.sendTime || '--' }}</el-descriptions-item>
                                    </el-descriptions>
                                    <div style="flex-shrink: 0; font-size: 14px; padding-right: 35px">
                                        <!--  <i v-if="item.isSuccess" class="el-icon-check" style="color: #13CE66; font-weight: bold"></i>
                                        <i v-else class="el-icon-close" style="color: #FA5151; font-weight: bold"></i> -->
                                        <span style="margin-left: 5px">{{ item.stateStr }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="display: flex; justify-content: flex-end">
                            <el-pagination hide-on-single-page :current-page.sync="addressee.page.index" :page-size="addressee.page.size" :total="addressee.page.total" @current-change="getAddresseeList()" background layout="total, prev, pager, next" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.view {
    box-sizing: border-box;
    padding: 20px;
    width: 100%;
    height: 100%;
    background: #fff;
    ::v-deep .el-tabs__nav-wrap::after {
        display: none;
    }
}
.content-wrapper {
    height: calc(100% - 54px);
}
.item {
    box-sizing: border-box;
    border-bottom: 1px solid #d3dce6;
    display: flex;
    padding: 20px 0;
}
.b {
    font-size: 14px;
    padding: 0 15px;
    height: 27px;
    line-height: 27px;
    border-radius: 27px;
    width: fit-content;
    background: #d6f4e3;
    color: #2dc76d;
}

.cardItem{
    width: 330px;
    min-height: 40px;
    background: #fafafa;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 10px;
    margin-right: 20px;
    margin-bottom: 20px;
    .textContent{
        font-size: 14px;
        color: #3a3a3a;
        line-height: 20px;
    }
    .bottomLink{
        margin-top: 5px;
        border-top: 1px solid #dadada;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        color: #00a5f4;
        height: 30px;
    }
}
</style>