import { render, staticRenderFns } from "./Blacklist.vue?vue&type=template&id=44f2e061&scoped=true&"
import script from "./Blacklist.vue?vue&type=script&lang=js&"
export * from "./Blacklist.vue?vue&type=script&lang=js&"
import style0 from "./Blacklist.vue?vue&type=style&index=0&id=44f2e061&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44f2e061",
  null
  
)

export default component.exports