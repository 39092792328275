<template>
<div>
    <div v-if="ie=='i'">
        <!-- 乌克兰(UKR)进口提单明细 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th>报关单号</th>
                        <td colspan="3">{{billInfo.DeclarationNumber}}</td>
                    </tr>
                    <tr>
                        <th width="25%">进口商ID</th>
                        <td width="25%">{{billInfo.ImporterId}}</td>
                        <th width="25%">出口商ID</th>
                        <td width="25%">{{billInfo.ExporterId}}</td>
                    </tr>
                    <tr>
                        <th>毛重</th>
                        <td>{{billInfo.GrossWeight}}</td>
                        <th>净重</th>
                        <td>{{billInfo.Weight}}</td>
                    </tr>
                    <tr>
                        <th>海关价格（乌克兰格里夫纳）</th>
                        <td>{{billInfo.Value}}</td>
                        <th>海关金额（美金）</th>
                        <td>{{billInfo.ValueUSD}}</td>
                    </tr>
                    <tr>
                        <th>每KG单价（美元）</th>
                        <td>{{billInfo.UsdKg}}</td>
                        <th>美元单价</th>
                        <td>{{billInfo.UsdUnit}}</td>
                    </tr>
                    <tr>
                        <th>海关统计值</th>
                        <td>{{billInfo.CustomsValue}}</td>
                        <th>每T货物花费的美元</th>
                        <td>{{billInfo.UsdT}}</td>
                    </tr>
                    <tr>
                        <th>货物的统计价值（乌克兰格里夫纳）</th>
                        <td colspan="3">{{billInfo.StatisticalValue}}</td>
                    </tr>
                    <tr>
                        <th>品牌</th>
                        <td>{{billInfo.Brand}}</td>
                        <th>汇率</th>
                        <td>{{billInfo.ExchangeRate}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">运输信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">报关类型</th>
                        <td width="25%">{{billInfo.DeclarationType}}</td>
                        <th width="25%">海关代码</th>
                        <td width="25%">{{billInfo.CustomsOfficeCode}}</td>
                    </tr>
                    <tr>
                        <th>关税制度</th>
                        <td>{{billInfo.CustomRegime}}</td>
                        <th>申报者</th>
                        <td>{{billInfo.DeclarantName}}</td>
                    </tr>
                    <tr>
                        <th>申报者ID</th>
                        <td>{{billInfo.DeclarantId}}</td>
                        <th>申报者地址</th>
                        <td>{{billInfo.DeclarantAddress}}</td>
                    </tr>
                    <tr>
                        <th>原产国</th>
                        <td>{{billInfo.Country}}</td>
                        <th>运输方式</th>
                        <td>{{billInfo.TransportType}}</td>
                    </tr>
                    <tr>
                        <th>成交方式</th>
                        <td>{{billInfo.Incoterms}}</td>
                        <th>关区</th>
                        <td>{{billInfo.Customs}}</td>
                    </tr>
                    <tr>
                        <th>起运国</th>
                        <td>{{billInfo.DepartureCountry}}</td>
                        <th>起运港</th>
                        <td>{{billInfo.DeliveryPlace}}</td>
                    </tr>
                    <tr>
                        <th>贸易国</th>
                        <td>{{billInfo.TradingCountry}}</td>
                        <th>特定报关程序下产品序列号</th>
                        <td>{{billInfo.ItemNumber}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">担保人信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">担保人</th>
                        <td width="25%">{{billInfo.Guarntor}}</td>
                        <th width="25%">担保人地址</th>
                        <td width="25%">{{billInfo.GuarntorAddress}}</td>
                    </tr>
                    <tr>
                        <th>担保人代码</th>
                        <td colspan="3">{{billInfo.GuarntorEnterpriseCode}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
    <div v-if="ie=='e'">
        <!-- 乌克兰(UKR)出口提单明细 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th>报关单号</th>
                        <td colspan="3">{{billInfo.DeclarationNumber}}</td>
                    </tr>
                    <tr>
                        <th width="25%">进口商ID</th>
                        <td width="25%">{{billInfo.ImporterId}}</td>
                        <th width="25%">出口商ID</th>
                        <td width="25%">{{billInfo.ExporterId}}</td>
                    </tr>
                    <tr>
                        <th>毛重</th>
                        <td>{{billInfo.GrossWeight}}</td>
                        <th>净重</th>
                        <td>{{billInfo.Weight}}</td>
                    </tr>
                    <tr>
                        <th>发票金额（乌克兰格里夫纳）</th>
                        <td>{{billInfo.Value}}</td>
                        <th>发票金额（美元）</th>
                        <td>{{billInfo.InvoiceUSD}}</td>
                    </tr>
                    <tr>
                        <th>每KG单价（美元）</th>
                        <td>{{billInfo.UsdKg}}</td>
                        <th>美元单价</th>
                        <td>{{billInfo.UsdUnit}}</td>
                    </tr>
                    <tr>
                        <th>海关统计值</th>
                        <td>{{billInfo.CustomsValue}}</td>
                        <th>每T货物花费的美元</th>
                        <td>{{billInfo.UsdT}}</td>
                    </tr>
                    <tr>
                        <th>货物的统计价值（乌克兰格里夫纳）</th>
                        <td colspan="3">{{billInfo.StatisticalValue}}</td>
                    </tr>
                    <tr>
                        <th>汇率</th>
                        <td>{{billInfo.ExchangeRate}}</td>
                        <th>数据类型</th>
                        <td>{{billInfo.DirectionType}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">运输信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">原产国</th>
                        <td width="25%">{{billInfo.OriginCountry}}</td>
                        <th width="25%">贸易国</th>
                        <td width="25%">{{billInfo.TradingCountry}}</td>
                    </tr>
                    <tr>
                        <th>目的国</th>
                        <td>{{billInfo.Country}}</td>
                        <th>报关类型</th>
                        <td>{{billInfo.DeclarationType}}</td>
                    </tr>
                    <tr>
                        <th>海关代码</th>
                        <td>{{billInfo.CustomRegime}}</td>
                        <th>海关完整代码</th>
                        <td>{{billInfo.Customs}}</td>
                    </tr>
                    <tr>
                        <th>申报者</th>
                        <td>{{billInfo.DeclarantName}}</td>
                        <th>申报者ID</th>
                        <td>{{billInfo.DeclarantId}}</td>
                    </tr>
                    <tr>
                        <th>起运港</th>
                        <td>{{billInfo.DeliveryPlace}}</td>
                        <th>运输方式</th>
                        <td>{{billInfo.TransportType}}</td>
                    </tr>
                    <tr>
                        <th>成交方式</th>
                        <td>{{billInfo.Incoterms}}</td>
                        <th>特定报关程序下产品序列号</th>
                        <td>{{billInfo.ItemNumber}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">担保人信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">担保人</th>
                        <td width="25%">{{billInfo.Guarntor}}</td>
                        <th width="25%">担保人地址</th>
                        <td width="25%">{{billInfo.GuarntorAddress}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
</div>
</template>

<script>
export default {
    props:["billInfo","ie"],
    data() {
        return {

        };
    },
    methods: {
        
    },
    mounted() {

    },
};
</script>

<style lang="scss" scoped>
.partItem {
    margin-bottom: 20px;
    /deep/.el-card__header {
        padding: 10px 20px;
        .headTitle {
            display: inline-block;
            padding-left: 10px;
            font-size: 14px;
            border-left: 2px solid var(--themeColor);
            font-weight: bold;
        }
    }
}
table {
    width: 100%;
    border-collapse: collapse;
    tr {
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;
        th,
        td {
            font-size: 12px;
            color: #666;
            height: 40px;
            border: 1px solid #eaeaea;
            text-align: center;
        }
        th {
            background-color: #f5f7fa;
            font-weight: 700;
        }
        .bg {
            background-color: var(--themeColor);
            color: #fff;
            font-weight: 700;
            opacity: 0.8;
        }
    }
}
</style>