<template>
<div style="display: flex;">
    <FinanceNav @chooseNav="chooseNav" :currentPath="currentPath" />
    <div class="viewbox">
        <div class="boxOne">
            <div class="title">业绩管理</div>
            <el-select class="searchItem" v-model="filterParam.isCheckOut" size="small" @change="toSearch">
                <el-option label="待收款订单" :value="0"></el-option>
                <el-option label="已结账订单" :value="1"></el-option>
            </el-select>
            <div class="rightHandle"></div>
        </div>

        <div class="boxTwo">
            <div style="display: flex;">
                <el-input class="searchItem" v-model="filterParam.customerName" placeholder="公司名称" size="small" clearable></el-input>
                <el-select class="searchItem" v-model="filterParam.userName" placeholder="选择员工" filterable size="small" clearable @change="toSearch">
                    <el-option v-for="item in staffOption" :key="item.key" :label="item.name" :value="item.name"></el-option>
                </el-select>
                <el-input class="searchItem" v-model="filterParam.orderNo" placeholder="订单编号" size="small" clearable></el-input>
                <span style="padding-left:10px;color:#6a6a6a;font-size:14px;">预计到款日期:</span>
                <el-date-picker v-model="filterParam.satrtExpectedReceiptDate" placeholder="起始日期" type="date" value-format="yyyy-MM-dd" size="small" style="width:150px;" @change="toSearch"></el-date-picker>
                <span style="padding:0 5px;color:#6a6a6a;font-size:14px;">至</span>
                <el-date-picker v-model="filterParam.endExpectedReceiptDate" placeholder="结束日期" type="date" value-format="yyyy-MM-dd" size="small" style="width:150px;" @change="toSearch"></el-date-picker>
            </div>
            <div style="margin-top:10px;display: flex;">
                <span style="padding-left:10px;color:#6a6a6a;font-size:14px;">应收款金额:</span>
                <el-input-number v-model="filterParam.startOrderAmount" :step="2" size="small" :controls="false"></el-input-number>
                <span style="padding:0 5px;color:#6a6a6a;font-size:14px;">至</span>
                <el-input-number v-model="filterParam.endOrderAmount" :step="2" size="small" :controls="false"></el-input-number>
                <span style="padding-left:10px;color:#6a6a6a;font-size:14px;">到账金额:</span>
                <el-input-number v-model="filterParam.startReceivedAmount" :step="2" size="small" :controls="false"></el-input-number>
                <span style="padding:0 5px;color:#6a6a6a;font-size:14px;">至</span>
                <el-input-number v-model="filterParam.endReceivedAmount" :step="2" size="small" :controls="false"></el-input-number>
                <el-button type="primary" size="small" @click="toSearch" style="margin-left:10px;">搜 索</el-button>
            </div>
        </div>

        <div class="boxTable">
            <el-table style="width:100%;" height="calc(100vh - 300px)" v-loading="tableLoading" :data="resultList"
                :header-cell-style="{'background-color':'#F2F2F2','color':'#3A3A3A'}" :row-style="rowStyle" :row-class-name="getRowClass">
                <el-table-column prop="userName" label="员工" width="80"></el-table-column>
                <el-table-column prop="orderNo" label="订单编号"></el-table-column>
                <el-table-column prop="customerName" label="公司名称"></el-table-column>
                <el-table-column label="预计到款日期">
                    <template slot-scope="scope">
                        <div v-if="scope.row.isOverdue">
                            <span style="color:#f56c6c;">{{scope.row.expectedReceiptDate}}</span>
                            <el-tag size="mini" type="danger" effect="dark">超期</el-tag>
                        </div>
                        <div v-else>{{scope.row.expectedReceiptDate}}</div>
                    </template>
                </el-table-column>
                <el-table-column label="应收款金额">
                    <template slot-scope="scope">
                        <span>{{scope.row.orderAmount}} {{scope.row.currency}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="到账金额">
                    <template slot-scope="scope">
                        <span>{{scope.row.receivedAmount}} {{scope.row.currency}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="180" v-if="filterParam.isCheckOut==0">
                    <template slot-scope="scope">
                        <el-button type="primary" size="mini" @click="toNewEntry(scope.row)">新增入账</el-button>
                        <el-button size="mini" @click="toCheckOut(scope.row)">结账</el-button>
                    </template>
                </el-table-column>
                <el-table-column type="expand">
                    <template slot-scope="props">
                        <div class="recordItem" v-for="(item,index) in props.row.recordReceipts" :key="index">
                            <div class="label">汇款公司</div>
                            <div class="value">{{item.remittanceCompany}}</div>
                            <div class="label">汇款金额</div>
                            <div class="value">{{item.receiptAmount}} {{item.currency}}</div>
                            <div class="label">汇款时间</div>
                            <div class="value">{{item.receiptDate}}</div>
                            <div class="label">汇款银行</div>
                            <div class="value">{{item.remittingBank}}</div>
                            <div class="label">我方账户</div>
                            <div class="value">{{item.ourAccount}}</div>
                            <div class="label">付款凭证</div>
                            <div class="value">
                                <el-image style="width:48px;height:48px" :src="item.paymentVoucher" :preview-src-list="[item.paymentVoucher]"></el-image>
                            </div>
                            <div class="label">经办人</div>
                            <div class="value">{{item.userName}}</div>
                            <div class="label">创建时间</div>
                            <div class="value">{{item.createTime}}</div>
                            <div class="label">汇款备注</div>
                            <div class="value">{{item.remark}}</div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <div class="bottomPagination">
            <el-button style="float:left;margin:15px 20px;" size="small" icon="el-icon-refresh" @click="onRefresh">刷 新</el-button>
            <el-pagination background layout="total,sizes,prev,pager,next" :page-sizes="[10, 20, 40]" :page-size.sync="pageSize" @size-change="pageSizeChange" :total="totalItems" style="float:right;margin:15px 20px;" :current-page.sync="pageIndex" @current-change="pageIndexChange"></el-pagination>
        </div>

        <el-dialog title="新增入账" :visible.sync="writeVisible" width="600px">
            <el-form :model="writeObj" label-width="100px" size="small">
                <el-form-item label="汇款公司">
                    <el-input v-model="writeObj.remittanceCompany" placeholder="汇款公司"></el-input>
                </el-form-item>
                <el-form-item label="汇款金额">
                    <el-input-number v-model="writeObj.receiptAmount" size="small" :min="0" :controls="false"></el-input-number>
                </el-form-item>
                <el-form-item label="币种/汇率">
                    <el-select v-model="writeObj.currency" size="small" filterable @change="chooseCurrency" style="width:130px;">
                        <el-option v-for="item in currencyOption" :key="item.key" :label="item.currency" :value="item.currency"></el-option>
                    </el-select>
                    <el-input-number :disabled="writeObj.currency=='CNY'" v-model="writeObj.exchangeRate" size="small" :min="0" :controls="false"></el-input-number>
                </el-form-item>
                <el-form-item label="汇款时间">
                    <el-date-picker v-model="writeObj.receiptDate" type="date" value-format="yyyy-MM-dd" placeholder="汇款时间"></el-date-picker>
                </el-form-item>
                <el-form-item label="汇款银行">
                    <el-input v-model="writeObj.remittingBank" placeholder="汇款银行"></el-input>
                </el-form-item>
                <el-form-item label="我方账户">
                    <el-input v-model="writeObj.ourAccount" placeholder="xx公司-xx0616-xx银行-xx支行" name="ourAccount" autocomplete="on"></el-input>
                </el-form-item>
                <el-form-item label="汇款备注">
                    <el-input v-model="writeObj.remark" placeholder="汇款备注" type="textarea" :rows="2"></el-input>
                </el-form-item>
                <el-form-item label="是否记入业绩">
                    <el-checkbox v-model="writeObj.isPerformance">是否记入业绩</el-checkbox>
                </el-form-item>
                <el-form-item label="上传付款凭证">
                    <el-image v-if="writeObj.paymentVoucher" style="width: 100px; height: 100px"
                        :src="writeObj.paymentVoucher" :preview-src-list="[writeObj.paymentVoucher]">
                    </el-image>
                    <el-upload :action="uploadUrl" :headers="headers" :data="uploadData" :limit="1" :show-file-list="false" :before-upload="beforeUpload" :on-success="uploadSuccess" :on-progress="()=>{uploadLoading = true;}">
                        <el-button size="mini" :loading="uploadLoading">{{writeObj.paymentVoucher? "重新上传" : "上传图片"}}</el-button>
                    </el-upload>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="writeVisible = false" size="small">取 消</el-button>
                <el-button type="primary" @click="onSubmit" size="small" :loading="btnLoading">提 交</el-button>
            </span>
        </el-dialog>

    </div>
</div>
</template>

<script>
import dayjs from "dayjs";
import FinanceNav from "@/components/FinanceNav.vue";
export default {
    components: { FinanceNav },
    props: {
        handleSelect: {
            type: Function,
            default: null,
        },
    },
    data() {
        return {
            currentPath: "/finance/performanceManage",
            // 过滤字段
            filterParam: {
                customerName: "",
                userName: "",
                orderNo: "",
                satrtExpectedReceiptDate: "",
                endExpectedReceiptDate: "",
                startOrderAmount: 0,
                endOrderAmount: 0,
                startReceivedAmount: 0,
                endReceivedAmount: 0,
                isCheckOut: 0,
            },
            staffOption: [],
            currencyOption: [],
            //列表展示
            tableLoading: false,
            resultList: [],
            totalItems: 0,
            pageIndex: 1,
            pageSize: 10,
            //新增入账
            btnLoading: false,
            writeVisible: false,
            writeObj: {},

            //图片上传
            uploadUrl: `${this.$diskUrl}/File/Upload`,
            headers: { authorization: 'Bearer ' + localStorage.getItem('token') },
            uploadData: {
                folderKey: "0",
                remarkName: "",
                permission: '我的'
            },
            uploadLoading: false,
        };
    },
    methods: {
        chooseNav(index){
            this.handleSelect(index);
            this.$router.push(index);
        },
        rowStyle({rowIndex}){
            if(rowIndex % 2 == 1){
                return {'background-color':'#F9FAFC'}
            }
        },
        //隐藏展开箭头
        getRowClass({row}) {
            if (row.recordReceipts.length === 0) { return 'row-expand-cover';}
        },

        getStaffBox(){
            this.$axios.get(`${this.$personnelUrl}/Organization/FindStaffBox`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.staffOption = data;
                }
            })
        },
        getCurrencyConfig(){
            this.$axios.get(`${this.$customerUrl}/v1/Config/GetCurrencyConfig`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.currencyOption = data;
                }
            })
        },
        chooseCurrency(value){
            let cItem = this.currencyOption.filter(ele => ele.currency == value)[0];
            this.writeObj.exchangeRate = cItem.exchangeRate;
        },

        onRefresh(){
            this.filterParam = {
                customerName: "",
                userName: "",
                orderNo: "",
                satrtExpectedReceiptDate: "",
                endExpectedReceiptDate: "",
                startOrderAmount: 0,
                endOrderAmount: 0,
                startReceivedAmount: 0,
                endReceivedAmount: 0,
                isCheckOut: 0,
            };
            this.toSearch();
        },
        toSearch(){
            this.pageIndex = 1;
            this.getList();
        },
        //获取代收款列表
        getList(){
            this.tableLoading = true;
            this.$axios.post(`${this.$orderUrl}/Performance/GetPage`,{
                ...this.filterParam,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
            }).then((res) => {
                const { isSuccess , data } = res;
                this.tableLoading = false;
                if (isSuccess) {
                    this.resultList = data.datas;
                    this.totalItems = data.totalItems;
                }
            });
        },
        pageIndexChange(page) {
            this.pageIndex = page;
            this.getList();
        },
        pageSizeChange() {
            this.pageIndex = 1;
            this.getList();
        },

        //图片上传
        beforeUpload(file) {
            var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
            const isImg = testmsg === "png" || testmsg === "jpg" || testmsg === "jpeg";
            const isLt10M = file.size / 1024 / 1024 < 10;
            if (!isImg) {
                this.$message({ message: "上传文件只能是图片!", type: "warning"});
            }
            if (!isLt10M) {
                this.$message({ message: "上传文件大小不能超过10MB!", type: "warning"});
            }
            return isImg && isLt10M;
        },
        uploadSuccess(response){
            const {isSuccess,data} = response;
            this.uploadLoading = false;
            if(isSuccess){
                this.writeObj.paymentVoucher = data.fileUrl;
            }
        },

        //新增入账
        toNewEntry(row){
            this.writeVisible = true;
            this.getCurrencyConfig();
            this.writeObj = {
                remittanceCompany: row.customerName,
                receiptDate: dayjs().format('YYYY-MM-DD'),
                receiptAmount: 0,
                currency: "CNY",
                exchangeRate: "1",
                cnyAmount: 0,
                remittingBank: "",
                ourAccount: "",
                remark: "",
                isPerformance: true,
                paymentVoucher: "",
                //关联key
                orderKey: row.orderKey,
                customerKey: row.customerKey,
                ukey: row.ukey,
            }
        },
        onSubmit(){
            const {remittanceCompany,receiptDate,receiptAmount,remittingBank,ourAccount,paymentVoucher,exchangeRate} = this.writeObj;
            if(!remittanceCompany){this.$message({ type: 'warning', message: '请填写汇款公司!'});return false;}
            if(!receiptDate){this.$message({ type: 'warning', message: '请选择汇款时间!'});return false;}
            if(!receiptAmount){this.$message({ type: 'warning', message: '请填写汇款金额!'});return false;}
            if(!remittingBank){this.$message({ type: 'warning', message: '请填写汇款银行!'});return false;}
            if(!ourAccount){this.$message({ type: 'warning', message: '请填写我方账户!'});return false;}
            if(!paymentVoucher){this.$message({ type: 'warning', message: '请上传付款凭证!'});return false;}
            this.writeObj.cnyAmount = exchangeRate * receiptAmount;
            this.writeObj.exchangeRate = exchangeRate + "";
            this.btnLoading = true;
            this.$axios.post(`${this.$orderUrl}/Performance/AddRecordReceipt`,this.writeObj).then((res) => {
                const { isSuccess } = res;
                this.btnLoading = false;
                this.writeVisible = false;
                if (isSuccess) {
                    this.$message({ type: 'success', message: '入账成功!'});
                    this.getList();
                }
            });
        },
        //结账
        toCheckOut(row){
            this.$confirm('是否进行结账操作?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios.get(`${this.$orderUrl}/Performance/CheckOut?orderKey=${row.orderKey}`).then((res) => {
                    const { isSuccess } = res;
                    if (isSuccess) {
                        this.$message({ type: 'success', message: '结账成功!'});
                        this.getList();
                    }
                });
            }).catch(() => {});
        },

    },
    mounted() {
        this.getStaffBox();
        this.getList(); //获取代收款列表
    },
};
</script>

<style lang="scss" scoped>
.viewbox{
    width: calc(100% - 200px);
    height: calc(100vh - 140px);
    background: rgba($color: #FFFFFF, $alpha: 0.7);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    margin-bottom: 60px;
    .boxOne,.boxTwo{
        overflow: hidden;
        padding: 8px 0;
        line-height: 32px;
        border-bottom: 1px solid #E6E7EB;
        .title{
            float: left;
            margin-left: 40px;
            font-size: 24px;
            font-weight: bold;
            color: #3A3A3A;
        }
        .rightHandle{
            float: right;
            margin-right: 20px;
        }
        .searchItem{
            width: 160px;
            margin-left: 10px;
        }
    }
    .bottomPagination{
        position: fixed;
        bottom: 0;
        right: 0;
        z-index: 10;
        width: calc(100vw - 70px);
        height: 60px;
        background: #FFFFFF;
        box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.16);
    }
}
/deep/.row-expand-cover td .el-table__expand-icon {
    display: none !important;
}
.recordItem{
    display: flex;
    font-size: 12px;
    line-height: 24px;
    .label{
        color: #7C90A5;
        width: 60px;
        text-align: right;
    }
    .label::after{
        content: ":";
    }
    .value{
        width: 120px;
        padding-left: 10px;
    }
}
</style>