<template>
    <div>
        <InterfaceName :interfaceName="interfaceName" />
        <p>创建Token</p>
        <p>Header: SpecialKey</p>
        <p>Header: SpecialSecret</p>
        <p>有效期 2小时</p>
        <InterfaceSpe :requestUrl="requestUrl" :requestMethod="requestMethod" :isHide="true" />
        <ReturnParam :returnObj="returnObj" />
    </div>
</template>

<script>
import InterfaceName from '../Acomponents/InterfaceName.vue';
import InterfaceSpe from '../Acomponents/InterfaceSpe.vue';
import ReturnParam from '../Acomponents/ReturnParam.vue';

export default {
    components: {InterfaceName,InterfaceSpe,ReturnParam},
    data() {
        return {
            interfaceName: "授权获取token",
            requestUrl: "https://crm.qixinerp.com/open/ApplyManage/Authorize",
            requestMethod: "GET",
            returnObj: {},
        };
    },
    methods: {
        
    },
    mounted() {
        this.returnObj = {
            "isSuccess":true,
            "errorMessage":null,
            "data":"af19a3cbd7094bafa19e629b04b482ae"
        }
    },
};
</script>

<style lang="scss" scoped>
p{
    font-size: 14px;
    line-height: 28px;
    color: #333;
}
</style>