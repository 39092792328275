<template>
    <div class="view">
        <div class="left">
            <img src="@/assets/default/four.png" alt="" />
        </div>
        <div class="right">
            <div class="describe">操作过于频繁，请验证身份</div>
            <div style="margin-top:15px;">
                <el-input v-model="inputValue" size="small"></el-input>
            </div>
            <div style="margin-top:15px;">
                <el-button type="primary" size="small" style="width:100px;" @click="confirm">确定</el-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            inputValue: "",
        };
    },
    methods: {
        confirm(){
            console.log(this.inputValue);
        }
    },
    mounted() {

    },
};
</script>

<style lang="scss" scoped>
.view{
    overflow: hidden;
    width: 1200px;
    margin: 200px auto;
    .left{
        float: left;
    }
    .right{
        float: left;
        margin-left: 150px;
        .describe{
            margin-top: 100px;
            font-size: 20px;
            line-height: 40px;
            color: #112446;
        }
    }
}
</style>