<template>
    <div class="clientInstall">
        <img class="bgImg" src="@/assets/helper/bg.png" alt="" />
        <div class="txt">
            <div class="title">企芯助手</div>
            <div class="describe">
                <p>企芯助手客户端获取全球社媒WhatsApp联系人，全网获取公司联系人邮箱，获取社媒和全网线索更及时，不落下任何一个线索机会。</p>
                <p>企芯助手客户端WhatsApp群发，不限量不限模板的优势，让营销转化更先进一步。</p>
            </div>
            <div style="margin-top:50px;">
                <el-button round class="btnOne" @click="installPlugin">
                    <img src="@/assets/helper/chrome.png" alt="" />
                    <span>企芯助手浏览器插件</span>
                </el-button>
            </div>
            <div style="margin-top:20px;">
                <el-button type="primary" round class="btnTwo" @click="installClient">
                    <img src="@/assets/helper/client.png" alt="" />
                    <span>企芯助手客户端</span>
                </el-button>
            </div>
        </div>

        <el-dialog title="提示" :visible.sync="dialogVisible" width="600px">
            <div style="text-align: center;margin-bottom: 10px;">如果你的下载在5秒后没有开始</div>
            <el-input v-model="urlLink" disabled>
                <template slot="prepend">链接</template>
                <el-button slot="append" icon="el-icon-document-copy" @click="copyUrl">复制</el-button>
            </el-input>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            urlLink: "https://cbserp-sh.oss-cn-shanghai.aliyuncs.com/%E4%BC%81%E8%8A%AF%20Setup%202.0.1.exe",
            dialogVisible: false,
        };
    },
    methods: {
        installPlugin(){
            let routeData = this.$router.resolve({
                path: "/helperInstall",
            });
            window.open(routeData.href, "_blank");
        },
        installClient(){
            window.open(this.urlLink);
            this.dialogVisible = true;
        },

        copyUrl(){
            const input = document.createElement('input');
            document.body.appendChild(input);
            input.setAttribute('value', this.urlLink);
            input.select();
            if (document.execCommand('copy')) {
                document.execCommand('copy');
            }
            document.body.removeChild(input);
            this.$message({message: '复制成功',type: 'success'});
        },
    },
    mounted() {
        this.$axios.get(`https://sofish.lsmaps.com/api/Renew/V2/GetRenew?app=qixin`).then((res) => {
            const { isSuccess , data } = res;
            if (isSuccess) {
                this.urlLink = data.win.url; //客户端助手链接
            }
        });
    },
};
</script>

<style lang="scss" scoped>
.clientInstall{
    .bgImg{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
    }
    .txt{
        position: fixed;
        top: 18vh;
        left: 10vw;
        z-index: 20;
        .title{
            width: 500px;
            height: 60px;
            font-size: 50px;
            color: #FFFFFF;
            line-height: 72px;
        }
        .describe{
            margin-top: 30px;
            width: 500px;
            height: 66px;
            font-size: 16px;
            color: #FFFFFF;
            line-height: 25px;
        }
        .btnOne,.btnTwo{
            width: 240px;
            img{
                margin-right: 10px;
                vertical-align: middle;
            }
        }
    }
}
</style>