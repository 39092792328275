<script>
import Vue from "vue";
import { watchEffect, ref } from "@vue/composition-api";
import { Message } from "element-ui";
import { useClipboard } from "@vueuse/core";
import axios from "@/assets/js/axios";

const avaccc = require("@/assets/img/avaccc.png");

export default Vue.extend({
    props: ["ckey"],
    setup(props) {
        const info = ref({});
        const { isSupported, copy } = useClipboard();
        const handleCopyClick = async (text) => {
            try {
                if (!isSupported) throw new Error();
                await copy(text);
                Message.success("复制成功");
            } catch {
                Message.error("复制失败");
            }
        };
        watchEffect(async () => {
            const key = props.ckey;
            if (key) {
                const res = await axios({
                    method: "GET",
                    url: Vue.prototype.$customerUrl + "/Card/GetColleagueCard",
                    params: {
                        ukey: props.ckey,
                    },
                });
                info.value = res.data;
            }
        });
        const sendMail = (mail) =>
            window.open(
                `${process.env.VUE_APP_MAIL_BASE}#/index/editor?type=send&receiver=${mail}`,
                "_blank"
            );
        return {
            info,
            avaccc,
            handleCopyClick,
            sendMail,
        };
    },
});
</script>

<template>
    <div class="colleage-card">
        <div style="padding-bottom: 10px; border-bottom: 1px solid #D9D9D9; position: relative">
            <span v-if="info.isQuit" style="border: 1px solid #000000;color: #000000; display: inline-block; position: absolute; right: 0; background: #E8E8E8; font-size: 12px; padding: 1px 3px;">已离职</span>
            <div style="display: flex">
                <img :src="info.avatar || avaccc" style="width: 45px; height: 45px; border-radius: 50%; flex-shrink: 0" />
                <div style="margin-left: 18px; display: flex; flex-direction: column; justify-content: space-between">
                    <p style="color: #000">
                        <span style="font-weight: bold; margin-right: 5px">{{ info.name }}</span>
                        <span v-if="info.isQuit" style="color: #9C9C9C">(该员工于{{ info.quitDate }}离职)</span>
                    </p>
                    <div style="display: flex; align-items: center">
                        <p style="color: #9C9C9C; margin-right: 10px">{{ info.email }}</p>
                        <img @click="sendMail(info.email)" class="utils-cursor-pointer" src="@/assets/img/sendmmm.png" style="width: 17px; height: 16px; flex-shrink: 0" />
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div style="display: flex; align-items: center; font-size: 14px; padding: 15px 0;">
                <div style="width: 100%;text-align: center;">
                    <p>职位:</p>
                    <p style="color: #353A3E;font-weight: bold; white-space: pre">{{ info.position || ' ' }}</p>
                </div>
                <div style="width: 2px; height: 20px; background: #D9D9D9;flex-shrink: 0"></div>
                <div style="width: 100%;text-align: center;">
                    <p>部门:</p>
                    <p style="color: #353A3E;font-weight: bold; white-space: pre">{{ info.department }}</p>
                </div>
                <div style="width: 2px; height: 20px; background: #D9D9D9;flex-shrink: 0"></div>
                <div style="width: 100%;text-align: center;">
                    <p>工龄:</p>
                    <p style="color: #353A3E;font-weight: bold; white-space: pre">{{ ' ' }}</p>
                </div>
            </div>
            <div style="display: flex" class="description-item">
                <span style="flex-shrink: 0">所属部门：</span>
                <div v-if="info.organizations" style="width: 100%">
                    <p v-for="(item, index) of info.organizations" :key="index">
                        <span>{{ item.organization[0].name }}</span>
                        <span v-if="item.isManager" style="border: 1px solid var(--themeColor);color:var(--themeColor); display: inline-block;margin-left: 8px; background: rgba(175, 202, 209, 0.2); font-size: 12px; padding: 1px 3px;">部门经理</span>
                    </p>
                </div>
            </div>
            <p class="description-item utils-omit-1">性别：{{ info.sex }}</p>
            <p class="description-item utils-omit-1">生日：{{ info.birthday }}</p>
            <p class="description-item utils-omit-1">手机号：{{ info.phone }}<span v-if="info.phone" @click="handleCopyClick(info.phone)" class="utils-cursor-pointer" style="color: var(--themeColor); margin-left: 20px">复制</span></p>
        </div>
    </div>
</template>

<style scoped lang="scss">
.colleage-card {
    box-sizing: border-box;
    width: 420px;
    font-size: 16px;
}

.description-item {
    width: 100%;
    font-size: 14px;
    color: #707070;
    margin: 5px 0;

    &:first-child {
        margin-top: 10px;
    }
}

.tag-area {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -10px;
    border-bottom: 1px solid #d9d9d9;
}

.tag {
    cursor: default;
    height: 20px;
    padding: 0 5px;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #ffffff;
    margin-right: 10px;
    margin-bottom: 10px;
}

.link-item {
    color: #1fcad1;
    font-size: 14px;
    padding: 0 1em;
    border-right: 1px solid #d9d9d9;

    &:first-child {
        padding-left: 0;
    }
}
</style>
