<template>
    <div>
        <!-- <el-button type="primary" plain size="small" @click="toViewList">任务列表</el-button> -->

        <!-- <el-dialog title="任务列表" :visible.sync="dialogVisible" width="800px"> -->
            <div class="searchBox">
                <el-input v-model="seekCondition.keyword" size="small" placeholder="关键字" clearable style="width:300px;"></el-input>
                <el-select v-model="seekCondition.status" size="small" @change="onSearch">
                    <el-option label="全部" :value="9"></el-option>
                    <el-option label="进行中" :value="0"></el-option>
                    <el-option label="已完成" :value="1"></el-option>
                    <el-option label="暂停" :value="2"></el-option>
                </el-select>
                <el-button type="primary" plain size="small" @click="onSearch">搜 索</el-button>
            </div>
            <div class="resultBox">
                <el-table :data="taskList" :header-cell-style="{'color':'#3A3A3A'}">
                    <el-table-column prop="keyword" label="关键字">
                        <template slot-scope="scope">
                            <el-link type="primary" @click="lookTaskDetails(scope.row)">{{scope.row.keyword}}</el-link>
                        </template>
                    </el-table-column>
                    <el-table-column prop="waCount" label="WhatsApp数量"></el-table-column>
                    <el-table-column prop="createTime" label="创建时间" width="180px"></el-table-column>
                    <el-table-column prop="statusStr" label="状态"></el-table-column>
                    <el-table-column label="操作" width="280px">
                        <template slot-scope="scope">
                            <el-tag effect="plain" size="small" @click="toWaMass(scope.row.key)">WhatsApp群发</el-tag>
                            <el-tag v-if="scope.row.isSystem" effect="plain" size="small" @click="lookTaskDetails(scope.row)">演示数据</el-tag>
                            <div v-if="!scope.row.isSystem" style="display: inline-block;">
                            <el-tag v-if="scope.row.status == 2" type="success" effect="plain" size="small" @click="startTask(scope.row)">启动任务</el-tag>
                            <el-tag v-if="scope.row.status == 0" type="info" effect="plain" size="small" @click="pauseTask(scope.row)">暂停任务</el-tag>
                            <el-tag type="danger" effect="plain" size="small" @click="delTask(scope.row)">删除任务</el-tag>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination hide-on-single-page background layout="total,prev,pager,next" :total="taskTotal" style="float:right;margin-top: 10px;" :current-page.sync="seekCondition.pageIndex" @current-change="pageChange"></el-pagination>
            </div>
            <!-- <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false;" size="small">关 闭</el-button>
            </span>
        </el-dialog> -->
        
        <el-drawer title="任务详情" :visible.sync="drawerVisible" size="50%">
            <div>
                <el-tag effect="plain" size="small" @click="toWaMass(lookTaskKey)">WhatsApp群发</el-tag>
                <div style="margin-top: 12px;">
                    <el-input v-model="searchObj.whatsapp" placeholder="whatsapp" clearable size="mini" style="width:150px;margin-left:5px;"></el-input>
                    <el-input v-model="searchObj.country" placeholder="国家" clearable size="mini" style="width:150px;margin-left:5px;"></el-input>
                    <el-button size="mini" style="margin-left:5px;" @click="lookChange(1)">搜 索</el-button>
                </div>
                <el-table :data="detailList" :header-cell-style="{'color':'#3A3A3A'}">
                    <el-table-column prop="whatsapp" label="whatsapp"></el-table-column>
                    <el-table-column label="国家">
                        <template slot-scope="scope">
                            <img :src="scope.row.flagUrl" style="margin-right:5px;height:14px;vertical-align: middle;" />
                            <span>{{ scope.row.country }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="title" label="标题" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="desc" label="描述" show-overflow-tooltip></el-table-column>
                </el-table>
                <div style="height:40px;">
                    <el-pagination background layout="total,prev,pager,next" :total="detailTotal" style="float:right;margin-top:4px;" :current-page.sync="lookPageIndex" @current-change="lookChange"></el-pagination>
                </div>
            </div>
        </el-drawer>

    </div>
</template>

<script>
export default {
    data() {
        return {
            // 任务列表
            dialogVisible: false,
            seekCondition: {
                keyword: "",
                status: 9, // 9全部 0进行中 1已完成 2暂停
                pageIndex: 1,
                pageSize: 10,
            },
            taskList: [],
            taskTotal: 0,
            // 任务详情
            drawerVisible: false,
            clickItem: null,
            lookTaskKey: "",
            searchObj:{
                whatsapp: "",
                country: "",
            },
            lookPageIndex: 1,
            detailList: [],
            detailTotal: 0,
        };
    },
    methods: {
        // 获取任务列表
        getTask(){
            this.$axios.post(`${this.$clueUrl}/MyClue/GetGoogleSearchTask`, this.seekCondition).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    this.taskList = data.datas;
                    this.taskTotal = data.totalItems;
                }
            });
        },
        toViewList(){
            this.dialogVisible = true;
            this.seekCondition = {
                keyword: "",
                status: 9,
                pageIndex: 1,
                pageSize: 10,
            }
            this.getTask();
        },
        pageChange(page){
            this.seekCondition.pageIndex = page;
            this.getTask();
        },
        onSearch(){
            this.seekCondition.pageIndex = 1;
            this.getTask();
        },

        //启动任务
        startTask(row){
            this.$axios.get(`${this.$clueUrl}/MyClue/Update/GoogleSearchTaskStatus?taskKey=${row.key}&status=0`,).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    this.$message({ message: '启动任务成功', type: 'success'});
                    this.getTask();
                }
            });
        },
        //暂停任务
        pauseTask(row){
            this.$axios.get(`${this.$clueUrl}/MyClue/Update/GoogleSearchTaskStatus?taskKey=${row.key}&status=2`,).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    this.$message({ message: '暂停任务成功', type: 'success'});
                    this.getTask();
                }
            });
        },
        //删除任务
        delTask(row){
            this.$confirm(`是否删除该任务?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$axios.get(`${this.$clueUrl}/MyClue/Delete/GoogleSearchTask?taskKey=${row.key}`,).then((res) => {
                    const { isSuccess } = res;
                    if (isSuccess) {
                        this.$message({ message: '删除任务成功', type: 'success'});
                        this.getTask();
                    }
                });
            }).catch(() => {});
        },

        //查看任务详情
        lookTaskDetails(row){
            this.drawerVisible = true;
            this.clickItem = row;
            this.lookTaskKey = row.key;
            this.searchObj.whatsapp = "";
            this.searchObj.country = "";
            this.lookPageIndex = 1;
            this.getDetail();
        },
        getDetail(){
            this.$axios.post(`${this.$clueUrl}/MyClue/Detail/GoogleSearchTask`,{
                taskKey: this.lookTaskKey,
                whatsapp: this.searchObj.whatsapp,
                country: this.searchObj.country,
                pageIndex: this.lookPageIndex,
                pageSize: 10,
            }).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    this.detailList = data.datas.map(item => ({
                        ...item,
                        flagUrl: window.getFlagUrlByCountry ? window.getFlagUrlByCountry(item.country) : '',
                    }))
                    this.detailTotal = data.totalItems;
                }
            });
        },
        lookChange(page){
            this.lookPageIndex = page;
            this.getDetail();
        },

        //WhatsApp群发
        toWaMass(key){
            let routeData = this.$router.resolve({
                // path: '/marketingWA/NewWA',
                path: '/marketingWA/WAMass',
                query: { searchRecordKey: key },
            });
            window.open(routeData.href, "_blank");
        },

    },
    mounted() {
        this.toViewList();  //获取任务列表
        let grabKey = this.$route.query.grabKey;
        if(grabKey){
            this.drawerVisible = true;
            this.lookTaskKey = grabKey;
            this.getDetail();
        }
    },
};
</script>

<style lang="scss" scoped>
/deep/.el-dialog__body {
    padding: 10px 20px;
}
/deep/.el-tag{
    cursor: pointer;
}
.searchBox{
    display: flex;
    margin-bottom: 10px;
}
.resultBox{
    overflow: hidden;
}
</style>