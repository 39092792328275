<template>
  <!-- 不复用 -->
  <div style="position: absolute">
    <img @click="toggleExpand" class="arrow cursor-pointer" :class="expand ? 'expand' : 'collapse'" :src="expand ? icon.expand : icon.collapse" />
    <div class="anchor" :class="expand ? 'expand' : 'collapse'">
      <div class="block">
        <div class="title">
          <img src="@/firm/images/company/anchor/qyxx.png" />
          <span>企业信息</span>
        </div>
        <div class="item" v-show="QYGK" :class="{ current: current === 'QYGK' }" @click="scrollTo('enterprise-info-basic-info-QYGK', 'QYGK')">
          <span class="signle-line-omit cursor-pointer">企业概况</span>
        </div>
        <div class="item" v-show="QYXX" :class="{ current: current === 'QYXX' }" @click="scrollTo('enterprise-info-basic-info', 'QYXX')">
          <span class="signle-line-omit cursor-pointer">企业详情信息</span>
        </div>
        <div class="item" v-show="SOCIAL" :class="{ current: current === 'SOCIAL' }" @click="scrollTo('abroad-social-grfwb', 'SOCIAL')">
          <span class="signle-line-omit cursor-pointer">社媒信息推荐</span>
        </div>
        <div class="item" v-show="false" :class="{ current: current === 'SOUYU' }" @click="scrollTo('enterprise-info-basic-souyu', 'SOUYU')">
          <span class="signle-line-omit cursor-pointer">搜鱼公司信息</span>
        </div>
        <div class="item" v-show="QYDZ" :class="{ current: current === 'QYDZ' }" @click="scrollTo('abroad-address', 'QYDZ')">
          <span class="signle-line-omit cursor-pointer">企业地址地图</span>
        </div>
        <div class="item" v-show="CWXX" :class="{ current: current === 'CWXX' }" @click="scrollTo('abroad-finance', 'CWXX')">
          <span class="signle-line-omit cursor-pointer">财务信息</span>
        </div>
        <div class="item" v-show="LXR" :class="{ current: current === 'LXR' }" @click="scrollTo('abroad-contact', 'LXR')">
          <span class="signle-line-omit cursor-pointer">联系人信息</span>
        </div>
        <div class="item" v-show="LXRDUNS" :class="{ current: current === 'LXRDUNS' }" @click="scrollTo('abroad-contact-duns', 'LXRDUNS')">
          <span class="signle-line-omit cursor-pointer">关联公司</span>
        </div>
        <div class="item" v-show="GYXX" :class="{ current: current === 'GYXX' }" @click="scrollTo('abroad-employee', 'GYXX')">
          <span class="signle-line-omit cursor-pointer">雇员信息</span>
        </div>
        <div class="item" v-show="USSIC" :class="{ current: current === 'USSIC' }" @click="scrollTo('abroad-ussic', 'USSIC')">
          <span class="signle-line-omit cursor-pointer">所属 UsSIC</span>
        </div>
        <div class="item" v-show="NAICS" :class="{ current: current === 'NAICS' }" @click="scrollTo('abroad-naics', 'NAICS')">
          <span class="signle-line-omit cursor-pointer">所属 NAICS</span>
        </div>
        <div class="item" v-show="HY" :class="{ current: current === 'HY' }" @click="scrollTo('abroad-industry', 'HY')">
          <span class="signle-line-omit cursor-pointer">行业</span>
        </div>
        <div class="item" v-show="CYM" :class="{ current: current === 'CYM' }" @click="scrollTo('abroad-used-name', 'CYM')">
          <span class="signle-line-omit cursor-pointer">公司曾用名</span>
        </div>
        <div class="item" v-show="SHPL" :class="{ current: current === 'SHPL' }" @click="scrollTo('abroad-summary', 'SHPL')">
          <span class="signle-line-omit cursor-pointer">社会评论</span>
        </div>
        <div class="item" v-show="LSGYQK" :class="{ current: current === 'LSGYQK' }" @click="scrollTo('abroad-employee-history', 'LSGYQK')">
          <span class="signle-line-omit cursor-pointer">历史雇佣情况</span>
        </div>
        <div class="item" v-show="YH" :class="{ current: current === 'YH' }" @click="scrollTo('abroad-bank', 'YH')">
          <span class="signle-line-omit cursor-pointer">拥有业务的银行或金融机构信息</span>
        </div>
        <div class="item" v-show="JZDS" :class="{ current: current === 'JZDS' }" @click="scrollTo('abroad-competitor', 'JZDS')">
          <span class="signle-line-omit cursor-pointer">竞争对手信息</span>
        </div>
        <div class="item" v-show="ZBXJ" :class="{ current: current === 'ZBXJ' }" @click="scrollTo('abroad-captical', 'ZBXJ')">
          <span class="signle-line-omit cursor-pointer">资本细节</span>
        </div>
        <div class="item" v-show="QTXX" :class="{ current: current === 'QTXX' }" @click="scrollTo('abroad-other', 'QTXX')">
          <span class="signle-line-omit cursor-pointer">其他信息</span>
        </div>
        <div class="divi"></div>
      </div>
      <div class="block">
        <div class="title">
          <img src="@/firm/images/company/anchor/ztsjfx.png" />
          <span>总体分析</span>
        </div>
        <div v-if="false" class="item" v-show="HZSJ" :class="{ current: current === 'HZSJ' }" @click="scrollTo('summary-card', 'HZSJ')">
          <span class="signle-line-omit cursor-pointer">汇总数据</span>
        </div>
        <div class="item" v-show="TABJYJL" :class="{ current: current === 'TABJYJL' }" @click="scrollTo('tab-record-country-enterprise', 'TABJYJL')">
          <span class="signle-line-omit cursor-pointer">交易记录</span>
        </div>
        <div class="item" v-show="QSFX" :class="{ current: current === 'QSFX' }" @click="scrollTo('trend-analyse', 'QSFX')">
          <span class="signle-line-omit cursor-pointer">趋势分析</span>
        </div>
        <div class="item" v-show="MYZQ" :class="{ current: current === 'MYZQ' }" @click="scrollTo('cycle-analyse', 'MYZQ')">
          <span class="signle-line-omit cursor-pointer">贸易周期频次分析</span>
        </div>
        <div class="item" v-show="JZDSXX" :class="{ current: current === 'JZDSXX' }" @click="scrollTo('compete-analyse', 'JZDSXX')">
          <span class="signle-line-omit cursor-pointer">企业图谱</span>
        </div>
        <div class="item" v-show="QYTPRECT" :class="{ current: current === 'QYTPRECT' }" @click="scrollTo('tree-rect-dasf3rrff2', 'QYTPRECT')">
          <span class="signle-line-omit cursor-pointer">全景图谱</span>
          <img class="new-flag" src="@/firm/images/company/new/new-flag.png" />
        </div>
        <div class="item" v-show="BMFX1144" :class="{ current: current === 'BMFX1144' }" @click="scrollTo('code-analyse', 'BMFX1144')">
          <span class="signle-line-omit cursor-pointer">编码分析</span>
        </div>
        <div class="divi"></div>
      </div>
      <div class="block">
        <div class="title">
          <img src="@/firm/images/company/anchor/cpsjfx.png" />
          <span>产品分析</span>
        </div>
        <div class="item" v-show="HSBM6" :class="{ current: current === 'HSBM6' }" @click="scrollTo('tab-encode-encode-analyse-6', 'HSBM6')">
          <span class="signle-line-omit cursor-pointer">六位编码排名</span>
        </div>
        <div class="item" v-show="HSBM6C" :class="{ current: current === 'HSBM6C' }" @click="scrollTo('encode-analyse-6-country', 'HSBM6C')">
          <span class="signle-line-omit cursor-pointer">六位编码国家分析</span>
        </div>
        <div class="item" v-show="HSBM6CC" :class="{ current: current === 'HSBM6CC' }" @click="scrollTo('encode-analyse-6-country-company', 'HSBM6CC')">
          <span class="signle-line-omit cursor-pointer">六位编码国家{{ companyType === "importer" ? "供应商" : "采购商" }}分析</span>
        </div>
        <div class="item" v-show="HSBM6CCC" :class="{ current: current === 'HSBM6CCC' }" @click="scrollTo('encode-analyse-6-company', 'HSBM6CCC')">
          <span class="signle-line-omit cursor-pointer">六位编码{{ companyType === "importer" ? "供应商" : "采购商" }}分析</span>
        </div>
        <template v-if="false">
          <div class="item" v-show="HSBM4" :class="{ current: current === 'HSBM4' }" @click="scrollTo('tab-encode-encode-analyse-4', 'HSBM4')">
            <span class="signle-line-omit cursor-pointer">四位编码排名</span>
          </div>
          <div class="item" v-show="HSBM4C" :class="{ current: current === 'HSBM4C' }" @click="scrollTo('encode-analyse-4-country', 'HSBM4C')">
            <span class="signle-line-omit cursor-pointer">四位编码国家分析</span>
          </div>
          <div class="item" v-show="HSBM4CC" :class="{ current: current === 'HSBM4CC' }" @click="scrollTo('encode-analyse-4-country-company', 'HSBM4CC')">
            <span class="signle-line-omit cursor-pointer">四位编码国家{{ companyType === "importer" ? "供应商" : "采购商" }}分析</span>
          </div>
          <div class="item" v-show="HSBM4CCC" :class="{ current: current === 'HSBM4CCC' }" @click="scrollTo('encode-analyse-4-company', 'HSBM4CCC')">
            <span class="signle-line-omit cursor-pointer">四位编码{{ companyType === "importer" ? "供应商" : "采购商" }}分析</span>
          </div>
        </template>
        <template v-if="false">
          <div class="item" v-show="HSBM2" :class="{ current: current === 'HSBM2' }" @click="scrollTo('tab-encode-encode-analyse-2', 'HSBM2')">
            <span class="signle-line-omit cursor-pointer">二位编码排名</span>
          </div>
          <div class="item" v-show="HSBM2C" :class="{ current: current === 'HSBM2C' }" @click="scrollTo('encode-analyse-2-country', 'HSBM2C')">
            <span class="signle-line-omit cursor-pointer">二位编码国家分析</span>
          </div>
          <div class="item" v-show="HSBM2CC" :class="{ current: current === 'HSBM2CC' }" @click="scrollTo('encode-analyse-2-country-company', 'HSBM2CC')">
            <span class="signle-line-omit cursor-pointer">二位编码国家{{ companyType === "importer" ? "供应商" : "采购商" }}分析</span>
          </div>
          <div class="item" v-show="HSBM2CCC" :class="{ current: current === 'HSBM2CCC' }" @click="scrollTo('encode-analyse-2-company', 'HSBM2CCC')">
            <span class="signle-line-omit cursor-pointer">二位编码{{ companyType === "importer" ? "供应商" : "采购商" }}分析</span>
          </div>
        </template>
        <!-- SITC delete! -->
        <!-- <div class="item" v-show="SITC" :class="{ current: current === 'SITC' }" @click="scrollTo('abroad-sitc', 'SITC')">
          <span class="signle-line-omit cursor-pointer">SITC</span>
        </div> -->
        <div class="item" v-show="SITCRANK" :class="{ current: current === 'SITCRANK' }" @click="scrollTo('sitc-encide-rank', 'SITCRANK')">
          <span class="signle-line-omit cursor-pointer">{{ companyType === "importer" ? "采购" : "供应" }}SITC排名</span>
        </div>
        <div class="item" v-show="SITCCOUNTRY" :class="{ current: current === 'SITCCOUNTRY' }" @click="scrollTo('sitc-encode-country', 'SITCCOUNTRY')">
          <span class="signle-line-omit cursor-pointer">{{ companyType === "importer" ? "采购" : "供应" }}SITC国家分析</span>
        </div>
        <div class="item" v-show="SITCCOUNTRYC" :class="{ current: current === 'SITCCOUNTRYC' }" @click="scrollTo('sitc-encode-country-company', 'SITCCOUNTRYC')">
          <span class="signle-line-omit cursor-pointer">{{ companyType === "importer" ? "采购" : "供应" }}SITC国家{{ companyType === "importer" ? "供应商" : "采购商" }}分析</span>
        </div>
        <div class="item" v-show="SITCCOMPANY" :class="{ current: current === 'SITCCOMPANY' }" @click="scrollTo('sitc-encode-company', 'SITCCOMPANY')">
          <span class="signle-line-omit cursor-pointer">{{ companyType === "importer" ? "采购" : "供应" }}SITC{{ companyType === "importer" ? "供应商" : "采购商" }}分析</span>
        </div>
        <div class="divi"></div>
      </div>
      <div class="block">
        <div class="title">
          <img src="@/firm/images/company/anchor/gjsjfx.png" />
          <span>国家分析</span>
        </div>
        <div class="item" v-show="GJFX" :class="{ current: current === 'GJFX' }" @click="scrollTo('contry-analyse', 'GJFX')">
          <span class="signle-line-omit cursor-pointer">国家排名</span>
        </div>
        <div class="item" v-show="COUNTRENCODE" :class="{ current: current === 'COUNTRENCODE' }" @click="scrollTo('country-encode', 'COUNTRENCODE')">
          <span class="signle-line-omit cursor-pointer">国家编码分析</span>
        </div>
        <div class="item" v-show="COUNTRYENCODECOM" :class="{ current: current === 'COUNTRYENCODECOM' }" @click="scrollTo('country-encode-company', 'COUNTRYENCODECOM')">
          <span class="signle-line-omit cursor-pointer">国家编码{{ companyType === "importer" ? "供应商" : "采购商" }}分析</span>
        </div>
        <div class="item" v-show="COUNTRYCOM" :class="{ current: current === 'COUNTRYCOM' }" @click="scrollTo('country-company', 'COUNTRYCOM')">
          <span class="signle-line-omit cursor-pointer">国家{{ companyType === "importer" ? "供应商" : "采购商" }}分析</span>
        </div>
        <div class="item" v-show="COUNTRYCOMENCODE" :class="{ current: current === 'COUNTRYCOMENCODE' }" @click="scrollTo('country-company-encode', 'COUNTRYCOMENCODE')">
          <span class="signle-line-omit cursor-pointer">国家{{ companyType === "importer" ? "供应商" : "采购商" }}编码分析</span>
        </div>
        <!-- <div class="item" v-show="COUNTRYEN" :class="{ current: current === 'COUNTRYEN' }" @click="scrollTo('country-enterprise', 'COUNTRYEN')">
          <span class="signle-line-omit cursor-pointer">国家企业关系</span>
        </div> -->
        <div class="item" v-show="SSZ" :class="{ current: current === 'SSZ' }" @click="scrollTo('state-analyse', 'SSZ')">
          <span class="signle-line-omit cursor-pointer">所属洲分析</span>
        </div>
        <div class="divi"></div>
      </div>
      <div class="block">
        <div class="title">
          <img src="@/firm/images/company/anchor/qysjfx.png" />
          <span>企业分析</span>
        </div>
        <div class="item" v-show="COMPAANLYSE" :class="{ current: current === 'COMPAANLYSE' }" @click="scrollTo('company-analyse', 'COMPAANLYSE')">
          <span class="signle-line-omit cursor-pointer">{{ companyType === "importer" ? "供应商" : "采购商" }}排名</span>
        </div>
        <div v-if="false" class="item" v-show="gxtixcc && companyType === 'importer'" :class="{ current: current === 'gxtixcc' }" @click="scrollTo('imp-exp-con-code', 'gxtixcc')">
          <span class="signle-line-omit cursor-pointer">采购供应商国家编码图谱</span>
          <img class="new-flag" src="@/firm/images/company/new/new-flag.png" />
        </div>
        <div class="item" v-show="COMPECODE" :class="{ current: current === 'COMPECODE' }" @click="scrollTo('company-encode', 'COMPECODE')">
          <span class="signle-line-omit cursor-pointer">{{ companyType === "importer" ? "供应商" : "采购商" }}编码分析</span>
        </div>
        <div v-if="false" class="item" v-show="gtxicdce && companyType === 'importer'" :class="{ current: current === 'gtxicdce' }" @click="scrollTo('imp-code-con-exp', 'gtxicdce')">
          <span class="signle-line-omit cursor-pointer">采购编码国家供应商图谱</span>
          <img class="new-flag" src="@/firm/images/company/new/new-flag.png" />
        </div>
        <div class="item" v-show="COMPECODECOUNTR" :class="{ current: current === 'COMPECODECOUNTR' }" @click="scrollTo('company-encode-company', 'COMPECODECOUNTR')">
          <span class="signle-line-omit cursor-pointer">{{ companyType === "importer" ? "供应商" : "采购商" }}编码国家分析</span>
        </div>
        <div v-if="false" class="item" v-show="gxticce && companyType === 'importer'" :class="{ current: current === 'gxticce' }" @click="scrollTo('imp-con-code-exp', 'gxticce')">
          <span class="signle-line-omit cursor-pointer">采购国家编码供应商图谱</span>
          <img class="new-flag" src="@/firm/images/company/new/new-flag.png" />
        </div>
        <div class="item" v-show="COMPIMPORTER" :class="{ current: current === 'COMPIMPORTER' }" @click="scrollTo('company-import', 'COMPIMPORTER')">
          <span class="signle-line-omit cursor-pointer">{{ companyType === "importer" ? "供应商" : "采购商" }}对应采购商分析</span>
        </div>
        <div class="item" v-show="COMPIMPORTERENCODE" :class="{ current: current === 'COMPIMPORTERENCODE' }" @click="scrollTo('company-import-encode', 'COMPIMPORTERENCODE')">
          <span class="signle-line-omit cursor-pointer">{{ companyType === "importer" ? "供应商" : "采购商" }}对应采购商的编码分析</span>
        </div>
        <div class="item" v-show="COMPEXPORTER" :class="{ current: current === 'COMPEXPORTER' }" @click="scrollTo('company-export', 'COMPEXPORTER')">
          <span class="signle-line-omit cursor-pointer">{{ companyType === "importer" ? "供应商" : "采购商" }}对应供应商分析</span>
        </div>
        <div class="item" v-show="COMPEXPORTERENCODE" :class="{ current: current === 'COMPEXPORTERENCODE' }" @click="scrollTo('company-export-encode', 'COMPEXPORTERENCODE')">
          <span class="signle-line-omit cursor-pointer">{{ companyType === "importer" ? "供应商" : "采购商" }}对应供应商的编码分析</span>
        </div>
        <!-- <div class="item" v-show="JKSPM" :class="{ current: current === 'JKSPM' }" @click="scrollTo('rank-import', 'JKSPM')">
          <span class="signle-line-omit cursor-pointer">进口商排名</span>
        </div>
        <div class="item" v-show="CKSPM" :class="{ current: current === 'CKSPM' }" @click="scrollTo('rank-export', 'CKSPM')">
          <span class="signle-line-omit cursor-pointer">出口商排名</span>
        </div> -->
        <div class="divi"></div>
      </div>
      <div class="block">
        <div class="title">
          <img src="@/firm/images/company/anchor/gkfx.png" />
          <span>港口分析</span>
        </div>
        <div class="item" v-show="GNWQYG" :class="{ current: current === 'GNWQYG' }" @click="scrollTo('port-load', 'GNWQYG')">
          <span class="signle-line-omit cursor-pointer">起运港分析</span>
        </div>
        <div class="item" v-show="GNGK" :class="{ current: current === 'GNGK' }" @click="scrollTo('port-domestic', 'GNGK')">
          <span class="signle-line-omit cursor-pointer">国内起运港分析</span>
        </div>
        <div class="item" v-show="GNWXHG" :class="{ current: current === 'GNWXHG' }" @click="scrollTo('port-unload', 'GNWXHG')">
          <span class="signle-line-omit cursor-pointer">卸货港分析</span>
        </div>
        <div class="item" v-show="GWGK" :class="{ current: current === 'GWGK' }" @click="scrollTo('port-abroad', 'GWGK')">
          <span class="signle-line-omit cursor-pointer">国外卸货港分析</span>
        </div>
      </div>
      <div class="block">
        <div class="bottom-flag">
          <div class="text">到底了哦~</div>
        </div>
      </div>
    </div>
  </div>
  <!--
    <div class="item" @click="scrollToTop()">
      <div class="text" style="border: none">
        <span class="signle-line-omit">
          <img src="@/firm/images/icon-scrolltop.png" />
          顶部</span
        >
      </div>
    </div> -->
</template>

<script>
import Vue from "vue";
import { mapState, mapMutations } from "vuex";
import { AnchorInditor } from "../utils";

let timer = NaN;

export default Vue.extend({
  data() {
    return {
      expand: true,
      icon: {
        expand: require("@/firm/images/company/anchor/expand.png"),
        collapse: require("@/firm/images/company/anchor/collapse.png"),
      },
    };
  },
  computed: {
    ...mapState(["companyType"]),
    ...mapState("anchor", {
      current: "current",
      QYGK: "QYGK",
      QYXX: "QYXX",
      BMFX1144: 'BMFX1144',
      SOCIAL: "SOCIAL",
      SOUYU: "SOUYU",
      QYDZ: "QYDZ",
      CWXX: "CWXX",
      LXR: "LXR",
      LXRDUNS: "LXRDUNS",
      GYXX: "GYXX",
      HY: "HY",
      SITC: "SITC",
      SITCRANK: "SITCRANK",
      USSIC: "USSIC",
      NAICS: "NAICS",
      CYM: "CYM",
      SHPL: "SHPL",
      LSGYQK: "LSGYQK",
      YH: "YH",
      JZDS: "JZDS",
      ZBXJ: "ZBXJ",
      QTXX: "QTXX",
      COUNTRYEN: "COUNTRYEN",
      QSFX: "QSFX",
      MYZQ: "MYZQ",
      JKSPM: "JKSPM",
      CKSPM: "CKSPM",
      GJFX: "GJFX",
      SSZ: "SSZ",
      HSBM6: "HSBM6",
      HSBM6C: "HSBM6C",
      HSBM6CC: "HSBM6CC",
      HSBM6CCC: "HSBM6CCC",
      HSBM4: "HSBM4",
      HSBM4C: "HSBM4C",
      HSBM4CC: "HSBM4CC",
      HSBM4CCC: "HSBM4CCC",
      HSBM2: "HSBM2",
      HSBM2C: "HSBM2C",
      HSBM2CC: "HSBM2CC",
      HSBM2CCC: "HSBM2CCC",
      SITCCOUNTRY: "SITCCOUNTRY",
      SITCCOUNTRYC: "SITCCOUNTRYC",
      SITCCOMPANY: "SITCCOMPANY",
      COUNTRENCODE: "COUNTRENCODE",
      COUNTRYENCODECOM: "COUNTRYENCODECOM",
      COUNTRYCOM: "COUNTRYCOM",
      COUNTRYCOMENCODE: "COUNTRYCOMENCODE",
      COMPAANLYSE: "COMPAANLYSE",
      COMPECODE: "COMPECODE",
      COMPECODECOUNTR: "COMPECODECOUNTR",
      COMPIMPORTER: "COMPIMPORTER",
      COMPIMPORTERENCODE: "COMPIMPORTERENCODE",
      COMPEXPORTER: "COMPEXPORTER",
      COMPEXPORTERENCODE: "COMPEXPORTERENCODE",
      GNWQYG: "GNWQYG",
      GNGK: "GNGK",
      GNWXHG: "GNWXHG",
      GWGK: "GWGK",
      TABJYJL: "TABJYJL",
      JZDSXX: "JZDSXX",
      QYTPRECT: "QYTPRECT",
      HZSJ: "HZSJ",
      gxtixcc: "gxtixcc",
      gxticce: "gxticce",
      gtxicdce: "gtxicdce",
    }),
  },
  methods: {
    ...mapMutations("anchor", {
      setCurrent: "setCurrent",
    }),
    scrollTo(id, name) {
      clearTimeout(timer);
      AnchorInditor.disable = true;
      document.getElementById(id)?.scrollIntoView({
        behavior: "smooth",
      });
      this.setCurrent(name);
      timer = setTimeout(() => {
        AnchorInditor.disable = false;
      }, 1000);
    },
    scrollToTop() {
      clearTimeout(timer);
      AnchorInditor.disable = true;
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.setCurrent("");
      timer = setTimeout(() => {
        AnchorInditor.disable = false;
      }, 1000);
    },
    toggleExpand() {
      this.expand = !this.expand;
    },
  },
});
</script>

<style lang="scss" scoped>
.new-flag {
  position: relative;
  left: 5px;
}

.bottom-flag {
  position: relative;
  color: #a5a5a5;
  margin-top: 14px;
  margin-bottom: 28px;
  display: flex;
  justify-content: center;
  align-items: center;

  .text {
    padding: 0 15px;
    position: absolute;
    background: #fff;
    z-index: 2;
  }

  &::after {
    position: absolute;
    z-index: 1;
    content: "";
    display: block;
    height: 1px;
    width: 100%;
    background: #f0f2f5;
  }
}

@mixin transitionOption {
  transition: all 0.3s;
}

$anchorWidth: 210px;

.arrow {
  @include transitionOption();
  position: fixed;
  left: $anchorWidth;
  z-index: 100;
  top: calc(50vh - 40px);

  &.collapse {
    left: 0;
  }
}

.anchor {
  @include transitionOption();
  box-sizing: border-box;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  background: #fff;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  font-size: 12px;

  &.collapse {
    left: 0 - $anchorWidth;
  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #d5d5d5;
  }

  scrollbar-width: thin;
  scrollbar-color: #d5d5d5 #fff;
  width: $anchorWidth;
  padding-top: 18px;

  .block {
    padding: 0 15px;

    .title {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #333;
      font-weight: bold;
      margin: 12px 0;
      margin-top: 0;

      img {
        margin-right: 5px;
      }
    }

    .item {
      font-size: 12px;
      margin-top: 8px;
      color: #8492a6;
      display: flex;
      align-items: center;

      &.current {
        color: var(--themeColor, #3152AF);
      }

      &:hover {
        color: var(--themeColor, #3152AF);
      }
    }

    .divi {
      height: 1px;
      background: #f0f2f5;
      margin: 18px 0;
    }
  }

  /* .item {
    &.current {
      background: #fff4e8;
      color: var(--themeColor, #3152AF);
    }
    box-sizing: border-box;
    padding: 0 0.5em;
    cursor: pointer;
    width: 75px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    .text {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px dashed #f6c37e;
    }
  } */
}
</style>
