<template>
    <div>
        <InterfaceName :interfaceName="interfaceName" />
        <InterfaceSpe :requestUrl="requestUrl" :requestMethod="requestMethod" />
        <InputParam :inputTableData="inputTableData" />
        <ReturnParam :returnObj="returnObj" />
    </div>
</template>

<script>
import InterfaceName from "../Acomponents/InterfaceName.vue";
import InterfaceSpe from "../Acomponents/InterfaceSpe.vue";
import InputParam from "../Acomponents/InputParam.vue";
import ReturnParam from "../Acomponents/ReturnParam.vue";

export default {
    components: { InterfaceName, InterfaceSpe, InputParam, ReturnParam },
    data() {
        return {
            interfaceName: "获取供应商联系人下拉框",
            requestUrl: "https://crm.qixinerp.com/open/Procure/Supplier/ContactsBox",
            requestMethod: "GET",
            inputTableData: [],
            returnObj: {},
        };
    },
    methods: {},
    mounted() {
        this.inputTableData = [
            {paramName: "supplierKey", valueType: "string", explain: "供应商key", required: true},
        ]
        this.returnObj = {
            "isSuccess": true,
            "errorMessage": null,
            "data": [
                {
                    "key": "f1cd884ad7f044dfaacb344373528434",
                    "contactsName": "曾太源",
                    "contactsDepartment": "",
                    "contactsSex": "",
                    "contactsPost": "",
                    "contactsTel": "13533998466     17873686566",
                    "contactsPhone": "13533998466     17873686566",
                    "contactsFax": "",
                    "contactsEmail": ""
                }
            ],
        }
    },
};
</script>

<style lang="scss" scoped>

</style>