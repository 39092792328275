<script>
import { defineComponent } from "@vue/composition-api";
import Currency from "./BusinessSet/Currency.vue";
import CostSet from "./ComSet/CostSet.vue";
import PositionSet from "./ComSet/PositionSet.vue";
export default defineComponent({
    components: {
        Currency,
        CostSet,
        PositionSet,
    },
});
</script>

<template>
    <div class="view">
        <el-tabs>
            <el-tab-pane label="货币">
                <Currency />
            </el-tab-pane>
            <el-tab-pane label="贸易费用">
                <CostSet />
            </el-tab-pane>
            <el-tab-pane label="职位">
                <PositionSet />
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<style scoped lang="scss">
.view {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 0 20px 20px;
}
::v-deep .el-tabs {
    height: 100%;
}
::v-deep .el-tabs__content {
    height: calc(100% - 55px);
}
::v-deep .el-tab-pane {
    height: 100%;
}
</style>
