<template>
    <div>
        <div style="height: 60px; padding-left: 10px; display: flex; align-items: center; font-size: 14px; color: #707070">
            <span style="flex-shrink: 0">日期：</span>
            <el-date-picker type="daterange" @change="dateChange" v-model="form.date" value-format="yyyy-MM-dd" size="small" unlink-panels />
        </div>
        <div class="grid-container" style="height: calc(100% - 60px)">
            <div class="grid-item-panel" v-loading="zhuangtaifenbu.loading">
                <div class="grid-item-panel-title">
                    <div style="display: flex; align-items: flex-end">
                        <span class="grid-item-panel-title-text">销售订单状态分布</span>
                        <span class="grid-item-panel-title-sub-text" style="margin-left: 10px">{{ zhuangtaifenbu.date }}</span>
                    </div>
                    <div class="icon-container">
                        <img src="@/assets/icon/refresh.png" />
                        <img src="@/assets/icon/close.png" />
                    </div>
                </div>
                <div ref="zhuangtaifenbu-dom" class="grid-item-panel-chart-dom"></div>
            </div>
            <div class="grid-item-panel" v-loading="chanpinjinegoucheng.loading">
                <div class="grid-item-panel-title">
                    <div style="display: flex; align-items: flex-end">
                        <span class="grid-item-panel-title-text">产品订单金额构成</span>
                        <span class="grid-item-panel-title-sub-text" style="margin-left: 10px">{{ chanpinjinegoucheng.date }}</span>
                    </div>
                    <div class="icon-container">
                        <img src="@/assets/icon/refresh.png" />
                        <img src="@/assets/icon/close.png" />
                    </div>
                </div>
                <div ref="chanpinjinegoucheng-dom" class="grid-item-panel-chart-dom"></div>
            </div>
            <div class="grid-item-panel" v-loading="xiaoshouhuikuan.loading">
                <div class="grid-item-panel-title bb">
                    <div style="display: flex; align-items: flex-end">
                        <span class="grid-item-panel-title-text">销售订单回款情况</span>
                        <span class="grid-item-panel-title-sub-text" style="margin-left: 10px">{{ xiaoshouhuikuan.date }}</span>
                    </div>
                    <div class="icon-container">
                        <img src="@/assets/icon/refresh.png" />
                        <img src="@/assets/icon/close.png" />
                    </div>
                </div>
                <div class="grid-item-panel-chart-dom">
                    <el-table class="custom-el-table" :data="xiaoshouhuikuan.tableData" height="calc(100% - 20px)">
                        <el-table-column label="#" prop="order" width="50px" align="center" />
                        <el-table-column label="订单编号" prop="num" show-overflow-tooltip />
                        <el-table-column label="订单销售额" prop="amount" show-overflow-tooltip />
                        <el-table-column label="回款金额" prop="price" show-overflow-tooltip />
                        <el-table-column label="未回款金额" prop="unprice" show-overflow-tooltip />
                        <el-table-column label="回款次数" prop="count" show-overflow-tooltip />
                    </el-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as echarts from "echarts";
import dayjs from "dayjs";
export default {
    chartInstance: {
        zhuangtaifenbu: null,
        chanpinjinegoucheng: null,
    },
    data() {
        const date = [
            dayjs().subtract(90, "days").format("YYYY-MM-DD"),
            dayjs().format("YYYY-MM-DD"),
        ];
        return {
            form: {
                range: "",
                date,
            },
            zhuangtaifenbu: {
                loading: false,
                date: date.join(" —— "),
            },
            chanpinjinegoucheng: {
                loading: false,
                date: date.join(" —— "),
            },
            xiaoshouhuikuan: {
                loading: false,
                date: date.join(" —— "),
                tableData: [],
            },
        };
    },
    watch: {},
    methods: {
        async getZhuangtaifenbu() {
            const [startDate, endDate] = this.form.date || [];
            if (!(startDate && endDate)) {
                return;
            }
            try {
                this.zhuangtaifenbu.loading = true;
                const res = await this.$axios({
                    method: "GET",
                    url: this.$customerUrl + "/Report/OrderStatus",
                    params: {
                        startDate,
                        endDate,
                    },
                });
                const data = res.data;
                this.$options.chartInstance.zhuangtaifenbu.setOption({
                    series: [
                        {
                            name: "销售订单状态分布",
                            data,
                        },
                    ],
                });
                this.zhuangtaifenbu.date = this.form.date.join(" —— ");
            } catch {
                null;
            } finally {
                this.zhuangtaifenbu.loading = false;
            }
        },

        async getChanpinjinegoucheng() {
            const [startDate, endDate] = this.form.date || [];
            if (!(startDate && endDate)) {
                return;
            }
            try {
                this.chanpinjinegoucheng.loading = true;
                const res = await this.$axios({
                    method: "GET",
                    url: this.$customerUrl + "/Report/OrderAmountComposition",
                    params: {
                        startDate,
                        endDate,
                    },
                });
                const data = res.data;
                this.$options.chartInstance.chanpinjinegoucheng.setOption({
                    series: [
                        {
                            name: "产品订单金额构成",
                            data,
                        },
                    ],
                });
                this.chanpinjinegoucheng.date = this.form.date.join(" —— ");
            } catch {
                null;
            } finally {
                this.chanpinjinegoucheng.loading = false;
            }
        },

        async getXiaoshouhuikuan() {
            const [startDate, endDate] = this.form.date || [];
            if (!(startDate && endDate)) {
                return;
            }
            try {
                this.xiaoshouhuikuan.loading = true;
                const res = await this.$axios({
                    method: "GET",
                    url: this.$customerUrl + "/Report/OrderCollections",
                    params: {
                        startDate,
                        endDate,
                    },
                });
                const data = res.data;
                this.xiaoshouhuikuan.tableData = data.map((item, index) => ({
                    order: index + 1,
                    num: item.orderNo,
                    amount: item.orderAmount,
                    price: item.amountReceived,
                    unprice: item.surplusAmount,
                    count: item.receivedNumber,
                }));
                this.xiaoshouhuikuan.date = this.form.date.join(" —— ");
            } catch {
                null;
            } finally {
                this.xiaoshouhuikuan.loading = false;
            }
        },

        dateChange(val) {
            if (val) {
                this.getZhuangtaifenbu();
                this.getChanpinjinegoucheng();
                this.getXiaoshouhuikuan();
                localStorage.setItem("timeRange", val);
            }
        },
    },
    mounted() {
        //获取上次选择的时间段
        if (localStorage.timeRange) {
            this.form.date = localStorage.timeRange.split(",");
        }

        this.$options.chartInstance.zhuangtaifenbu = echarts.init(
            this.$refs["zhuangtaifenbu-dom"]
        );
        this.$options.chartInstance.zhuangtaifenbu.setOption({
            legend: {
                top: "middle",
                type: "scroll",
                orient: "vertical",
                right: "5%",
                icon: "circle",
                textStyle: {
                    color: "#7C90A5",
                },
                itemWidth: 14,
                itemHeight: 14,
            },
            tooltip: {},
            series: [
                {
                    name: "销售订单状态分布",
                    type: "pie",
                    radius: ["30%", "70%"],
                    center: ["40%", "50%"],
                    label: {
                        show: true,
                        color: "#94979C",
                    },
                    labelLine: {
                        smooth: true,
                        lineStyle: {
                            color: "#94979C",
                        },
                    },
                    emphasis: {
                        scale: false,
                    },
                    data: [],
                },
            ],
            color: [
                "#62D0D3",
                "#67A0C8",
                "#9BC586",
                "#DFD982",
                "#DC7683",
                "#8897C0",
                "#2560A3",
            ],
        });
        this.$options.chartInstance.chanpinjinegoucheng = echarts.init(
            this.$refs["chanpinjinegoucheng-dom"]
        );
        this.$options.chartInstance.chanpinjinegoucheng.setOption({
            legend: {
                top: "middle",
                type: "scroll",
                orient: "vertical",
                right: "5%",
                icon: "circle",
                textStyle: {
                    color: "#7C90A5",
                },
                itemWidth: 14,
                itemHeight: 14,
            },
            tooltip: {},
            series: [
                {
                    name: "产品订单金额构成",
                    type: "pie",
                    radius: ["30%", "70%"],
                    center: ["40%", "50%"],
                    label: {
                        show: true,
                        color: "#94979C",
                    },
                    labelLine: {
                        smooth: true,
                        lineStyle: {
                            color: "#94979C",
                        },
                    },
                    emphasis: {
                        scale: false,
                    },
                    data: [],
                },
            ],
            color: [
                "#62D0D3",
                "#67A0C8",
                "#9BC586",
                "#DFD982",
                "#DC7683",
                "#8897C0",
                "#2560A3",
            ],
        });
        this.getZhuangtaifenbu();
        this.getChanpinjinegoucheng();
        this.getXiaoshouhuikuan();
    },
};
</script>

<style lang="scss" scoped>
.grid-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: -20px;
}

.grid-item-panel {
    width: calc(50% - 10px);
    margin-top: 20px;
    position: relative;
    height: 380px;
    background: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

$grid-item-panel-title-height: 42px;

.grid-item-panel-title {
    box-sizing: border-box;
    height: $grid-item-panel-title-height;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 14px 0 20px;

    &.bb {
        border-bottom: 1px solid #e6e7eb;
    }
}

.grid-item-panel-title-text {
    font-size: 18px;
    color: #1b2841;
    font-weight: bold;
}

.grid-item-panel-title-sub-text {
    font-size: 14px;
    color: #c7c7c7;
}

.icon-container {
    display: flex;
    align-items: center;

    img {
        cursor: pointer;

        &:last-child {
            margin-left: 10px;
        }
    }
}

.grid-item-select {
    position: absolute;
    z-index: 2;
    left: 20px;
    top: $grid-item-panel-title-height;
    border: 1px solid #c7c7c7;
    border-radius: 2px;
    width: 80px;
    font-size: 14px;
    color: #7c90a5;
    cursor: pointer;
    background: #fff;
}

.grid-item-select-option {
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover,
    &.active {
        background: var(--themeColor);
        color: #fff;
    }
}

.grid-item-panel-chart-dom {
    box-sizing: border-box;
    height: calc(100% - #{$grid-item-panel-title-height});
}

.custom-el-table {
    &.el-table::before {
        height: 0 !important;
        background: none !important;
    }

    font-size: 14px;

    ::v-deep .el-table__cell {
        height: 30px;
        padding: 0;
        border: none;
    }

    ::v-deep thead .el-table__cell {
        color: #3a3a3a;
        font-weight: normal;
    }

    ::v-deep tbody .el-table__cell {
        color: #1a2745;
        font-weight: normal;
    }

    ::v-deep th {
        border: none !important;
    }

    ::v-deep tbody tr {
        &:nth-child(odd) {
            background: #e3eaef;
        }
    }
}
</style>