<template>
    <el-dialog title="文件夹属性" :visible.sync="propertyVisible" width="500px" @close="$emit('closedialog')">
        <div class="infoBox" v-loading="propertyLoading">
            <div class="infoItem">
                <div class="label">名称</div>
                <div class="value">{{propertyInfo.folderName}}</div>
            </div>
            <div class="infoItem">
                <div class="label">位置</div>
                <div class="value">{{propertyInfo.location}}</div>
            </div>
            <div class="infoItem">
                <div class="label">大小</div>
                <div class="value">{{propertyInfo.fileSizeTransfer}}</div>
            </div>
            <div class="infoItem">
                <div class="label">包含</div>
                <div class="value">{{propertyInfo.fileCount || 0}}个文件，{{propertyInfo.folderCount || 0}}个文件夹</div>
            </div>
            <div class="infoItem">
                <div class="label">创建时间</div>
                <div class="value">{{propertyInfo.createTime}}</div>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="toSetLink" type="primary" plain size="small">分享文件夹</el-button>
            <el-button @click="toViewAllFile" type="primary" size="small">查看全部文件</el-button>
            <el-button @click="$emit('closedialog')" size="small">关 闭</el-button>
        </span>

        <!-- 分享文件夹(设置有效期和密码) -->
        <el-dialog width="500px" title="设置有效期和密码" :visible.sync="setVisible" append-to-body>
            <div class="setBox">
                <el-form label-width="100px" size="small">
                    <el-form-item label="设置有效期">
                        <el-radio-group v-model="setForm.day">
                            <el-radio :label="1">1天</el-radio>
                            <el-radio :label="3">3天</el-radio>
                            <el-radio :label="7">7天</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="设置密码">
                        <el-input placeholder="密码(选填)" v-model="setForm.password"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="setVisible = false" size="small">取 消</el-button>
                <el-button type="primary" @click="shareLink" size="small">生成链接</el-button>
            </div>
        </el-dialog>

        <!-- 查看全部文件 -->
        <el-dialog width="1000px" title="查看全部文件" :visible.sync="viewVisible" append-to-body top="80px">
            <div style="margin-bottom: 10px;" v-if="viewFileList.length > 0">
                <el-button :disabled="checkFileList.length==0" @click="downloadFiles" size="small" :loading="exportLoading">下载勾选文件</el-button>
                <el-button @click="allDownload" type="primary" size="small" :loading="exportLoading">下载全部文件</el-button>
            </div>
            <div class="contentBox">
                <div class="tableHeader">
                    <div class="name">文件名</div>
                    <div class="size">大小</div>
                    <div class="creator">创建人</div>
                    <div class="time">创建时间</div>
                    <div class="moreOption">操作</div>
                </div>
                <div class="tableBody" v-loading="tableLoading">
                    <div v-if="viewFileList.length == 0">
                        <el-empty description="暂无数据"></el-empty>
                    </div>               
                    <!-- 文件列表 -->
                    <div class="FileItem" v-for="item in viewFileList" :key="item.key" :class="item.checked ? 'checkedBgStyle':''">
                        <div class="name">
                            <el-checkbox v-model="item.checked"></el-checkbox>
                            <img class="imgIcon fileIcon" :src="item.fileIcon" alt="" />
                            <span class="pointer" @click="viewFileDetails(item)">{{item.remarkName}}</span>
                        </div>
                        <div class="size">{{item.fileSize}}</div>
                        <div class="creator">{{item.userCnName}}</div>
                        <div class="time">{{item.timeExhibition}}</div>
                        <div class="moreOption">
                            <el-button type="text" @click="fileDownload(item)">下载</el-button>
                        </div>
                    </div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="viewVisible = false" size="small">关 闭</el-button>
            </div>
        </el-dialog>

        <el-dialog title="下载文件打包成zip" :visible.sync="exportLoading" :show-close="false" width="800px" append-to-body>
            <el-progress :text-inside="true" :stroke-width="20" :percentage="progressNumber"></el-progress>
        </el-dialog>

    </el-dialog>
</template>

<script>
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

export default {
    props: ["propertyVisible", "propertyLoading", "propertyInfo"],
    data() {
        return {
            //查看全部文件
            viewVisible: false,
            tableLoading: false,
            viewFileList: [],
            //分享文件夹(设置有效期和密码)
            setVisible: false,
            setForm:{
                day: 1,
                password: "",
            },
            //下载文件打包成zip
            exportLoading: false,
            progressNumber: 0,
            finishCount: 0,
            totalSize: 0,
            loadeSize: 0,
        };
    },
    computed:{
        checkFileList(){
            return this.viewFileList.filter(item => item.checked);
        }
    },
    methods: {
        //查看全部文件
        toViewAllFile(){
            this.viewVisible = true;
            this.tableLoading = true;
            this.$axios.get(`${this.$diskUrl}/Folder/GetFolderAllFile?folderKey=${this.propertyInfo.folderKey}`).then((res) => {
                const { isSuccess, data } = res;
                this.tableLoading = false;
                if (isSuccess) {
                    data.forEach(item =>{
                        item.fileIcon = require(`@/assets/doc/file_${item.fileCategory}.png`)
                        item.checked = false;
                    })
                    this.viewFileList = data;
                }
            })
        },
        fileDownload(item){
            const link = document.createElement('a');
            link.href = item.fileUrl;
            link.download = item.remarkName;
            link.target = '_blank';
            (document.body || document.documentElement).appendChild(link);
            link.click();
            link.remove();
        },
        viewFileDetails(item){
            console.log(item);
        },

        //下载文件打包成zip
        downloadFiles() {
            this.exportLoading = true;
            this.progressNumber = 0;
            this.finishCount = 0;
            this.totalSize = 0;
            this.loadeSize = 0;
            this.checkFileList.forEach(ele => {
                this.totalSize += ele.originalSize;
            });
            // console.log(this.totalSize);
            // console.log(this.checkFileList)
            // 创建一个新的JSZip实例
            const zip = new JSZip();
            // 添加网络路径的文件到zip
            this.checkFileList.forEach((ele) => {
                if(ele.fileUrl.indexOf("http://")>=0){
                    ele.fileUrl = ele.fileUrl.replace("http://","https://");
                }
                this.addFileToZip(zip, ele);
            });
        },
        addFileToZip(zip, ele) {
            this.$axios.get(ele.fileUrl, {
                responseType: 'arraybuffer',
                onDownloadProgress: (event) => {
                    // 更新进度条
                    let downloadProgress = Math.round(((event.loaded + this.loadeSize) / this.totalSize) * 100);
                    this.progressNumber = downloadProgress > this.progressNumber ? downloadProgress : this.progressNumber;
                },
            }).then((response) => {
                zip.file(ele.remarkName, response);
            }).catch((error) => {
                console.error(error);
            }).finally(()=>{
                this.finishCount++;
                this.loadeSize += ele.originalSize;
                if (this.finishCount == this.checkFileList.length) {
                    //生成zip文件
                    zip.generateAsync({ type: 'blob' }).then((content) => {
                        // 使用file-saver保存并下载zip文件
                        saveAs(content, `${this.propertyInfo.folderName}.zip`);
                        this.exportLoading = false;
                        this.progressNumber = 0;
                    });
                }
            })
        },
        allDownload(){
            this.viewFileList.forEach(item =>{
                item.checked = true;
            })
            this.downloadFiles();
        },

        //下载全部文件(后端下载)
        onDownload(){
            this.$confirm(`是否确认下载?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.exportLoading = true;
                //下载文件夹中所有文件生成zip
                this.$axios({
                    url: `${this.$diskUrl}/Folder/DownloadFolderAllFile?folderKey=${this.propertyInfo.folderKey}`,
                    method: 'GET',
                    responseType: 'blob',
                }).then(async(response) => {
                    this.exportLoading = false;
                    if(response.type != "application/zip"){
                        let jsonData = JSON.parse(await response.text());
                        this.$notify({ message: jsonData.errorMessage, position: "top-right",duration: 2000,type: 'warning' })
                        return false;
                    }
                    var blob = new Blob([response]);
                    var link= document.createElement('a');
                    link.download = `${this.propertyInfo.folderName}.zip`;
                    link.href = URL.createObjectURL(blob);
                    link.click();
                    this.$message({message: "下载成功",type: 'success'});
                }).catch(() => {
                    this.exportLoading = false;
                })
            })
        },

        //分享文件夹
        toSetLink(){
            this.setVisible = true;
            this.setForm = {
                day: 1,
                password: "",
            }
        },
        shareLink(){
            const {day,password} = this.setForm;
            this.$axios.get(`${this.$diskUrl}/Folder/GetShareFolderLink?folderKey=${this.propertyInfo.folderKey}&day=${day}&pwd=${password.trim()}`).then((res) => {
                const { isSuccess, data } = res;
                this.setVisible = false;
                if (isSuccess) {
                    this.copyUrl(data);
                }
            })
        },
        // 复制url路径
        copyUrl(value){
            const input = document.createElement('input');
            document.body.appendChild(input);
            input.setAttribute('value', value);
            input.select();
            if (document.execCommand('copy')) {
                document.execCommand('copy');
            }
            document.body.removeChild(input);
            this.$message({message: '已复制该链接',type: 'success'});
        },
        
    },
    mounted() {

    },

};
</script>

<style lang='scss' scoped>
/deep/.el-dialog__body {
    padding: 10px 20px;
}
.infoBox{
    box-sizing: border-box;
    position: relative;
    .infoItem{
        line-height: 32px;
        margin-bottom: 10px;
        display: flex;
        .label{
            width: 80px;
            text-align: right;
            color: #9a9a9a;
            font-weight: bold;
        }
        .value{
            width: calc(100% - 100px);
            margin-left: 20px;
            color: #000;
        }
    }
}
.contentBox{
    width: 100%;
    height: calc(100vh - 300px);
    background: #FFFFFF;
    box-shadow: 0px 1px 6px 1px rgba(0, 0, 0, 0.16);
   
    .tableHeader{
        height: 45px;
        line-height: 45px;
        background: #F3F3F3;
        font-size: 16px;
        font-weight: bold;
        color: #3A3A3A;
        display: flex;
        padding: 0 20px;
    }
    .tableBody{
        padding-bottom: 10px;
        height: calc(100vh - 355px);
        overflow: overlay;
        .FolderItem,.FileItem{
            padding: 0 20px;
            display: flex;
            height: 50px;
            line-height: 50px;
            border-bottom: 1px solid #EEEEEE;
            font-size: 14px;
            color: #1A2745;
            .imgIcon{
                vertical-align: middle;
                padding: 0 20px;
            }
        } 
    }
    .tableHeader,.FolderItem,.FileItem{
        position: relative;
        .name{
            width: calc(100% - 650px);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .size{
            width: 130px;
            padding: 0 10px;
            text-align: center;
        }
        .creator{
            width: 130px;
            padding: 0 10px;
            text-align: center;
        }
        .time{
            width: 180px;
            padding: 0 10px;
            text-align: center;
        }
        .moreOption{
            width: 130px;
            padding: 0 10px;
            text-align: center;
        }
    }
}
.checkedBgStyle{
    background-color: #fcfcfc;
}
</style>