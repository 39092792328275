<script>
import Vue from "vue";
import { ref, defineComponent, reactive } from "@vue/composition-api";
import { Message } from "element-ui";
import axios from "@/assets/js/axios";
export default defineComponent({
    props: {
        type: {
            type: String,
            required: true,
        },
        ckey: {
            type: String,
            required: true,
        },
    },
    setup(props, { emit }) {
        const loading = ref(false);
        const options = ref([]);
        const form = reactive({
            currency: "",
        });
        (async () => {
            try {
                const res = await axios({
                    method: "GET",
                    url:
                        Vue.prototype.$customerUrl +
                        "/v1/Config/V2/GetExchangeRate",
                });
                options.value = res.data;
            } catch {
                options.value = [];
            }
        })();
        const handleConfirmClick = async () => {
            try {
                loading.value = true;
                const res = await axios({
                    method: "POST",
                    url:
                        Vue.prototype.$customerUrl +
                        "/v1/Config/CurrencyConfigSave",
                    data: {
                        key: props.ckey,
                        currency: form.currency,
                    },
                });
                if (!res.isSuccess) throw new Error();
                Message.success("操作成功");
                emit("success");
            } catch {
                null;
            } finally {
                loading.value = false;
            }
        };
        return {
            loading,
            options,
            form,
            handleConfirmClick,
        };
    },
});
</script>

<template>
    <el-dialog top="60px" :visible="true" width="674px" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
        <div class="flow-dialog" v-loading="loading">
            <div class="top">{{ `${$props.type}货币` }}</div>
            <div class="body">
                <el-form size="small" label-width="80px">
                    <el-form-item label="货币">
                        <el-select v-model="form.currency" filterable>
                            <el-option v-for="item of options" :key="item.currency" :value="item.currency" :label="item.currency">
                                <span>{{item.currency}}({{item.currencyCn}})</span>
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button @click="handleConfirmClick" type="primary" size="small">创建</el-button>
                        <el-button @click="$emit('close')" size="small">取消</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </el-dialog>
</template>

<style scoped lang="scss">
::v-deep .el-dialog__header {
    display: none;
}
::v-deep .el-dialog__body {
    padding: 0;
}
.flow-dialog {
    box-sizing: border-box;
    font-size: 16px;
}
.top {
    box-sizing: border-box;
    height: 60px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    color: #3a3a3a;
    border-bottom: 1px solid #d9d9d9;
}
.body {
    box-sizing: border-box;
    padding: 20px;
}
</style>
