<template>
    <div>
        <InterfaceName :interfaceName="interfaceName" />
        <InterfaceSpe :requestUrl="requestUrl" :requestMethod="requestMethod" />
        <InputParam :inputTableData="inputTableData" />
        <ReturnParam :returnObj="returnObj" />
    </div>
</template>

<script>
import InterfaceName from "../Acomponents/InterfaceName.vue";
import InterfaceSpe from "../Acomponents/InterfaceSpe.vue";
import InputParam from "../Acomponents/InputParam.vue";
import ReturnParam from "../Acomponents/ReturnParam.vue";

export default {
    components: { InterfaceName, InterfaceSpe, InputParam, ReturnParam },
    data() {
        return {
            interfaceName: "获取客户订单",
            requestUrl: "https://crm.qixinerp.com/open/Customer/GetCustomerOrders",
            requestMethod: "POST",
            inputTableData: [],
            returnObj: {},
        };
    },
    methods: {},
    mounted() {
        this.inputTableData = [
            {
                paramName: "customerKey",
                valueType: "string",
                explain: "客户key",
                required: true,
            },
            {
                paramName: "orderNo",
                valueType: "string",
                explain: "订单编号",
                required: false,
            },
            {
                paramName: "pageIndex",
                valueType: "integer",
                explain: "当前页码",
                required: true,
            },
            {
                paramName: "pageSize",
                valueType: "integer",
                explain: "每页条数",
                required: true,
            },
        ];
        this.returnObj = {
            isSuccess: true,
            errorMessage: null,
            data: {
                datas: [
                    {
                        key: "bb44ae45b80e4bc4b808b97a6ac99d06",
                        orderName: "000111222",
                        orderNo: "SKD20220527150207381",
                        orderDate: "2022-05-27 00:00:00",
                        orderAmount: 999.0,
                        customerName: "跨境搜信息科技有限公司123",
                        orderStatus: "草稿",
                        currency: "CNY",
                    },
                ],
                pageSize: 10,
                pageIndex: 1,
                totalItems: 1,
            },
        };
    },
};
</script>

<style lang="scss" scoped>
</style>