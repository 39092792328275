<template>
<div style="display: flex;">
    <ClueNav @chooseNav="chooseNav" :currentPath="currentPath" />
    <div class="viewbox">
        <div class="title">搜联系人邮箱</div>
        <div class="searchBox">
            <el-select size="small" v-model="searchTerms.type" style="width:100px;" :disabled="searchLoading">
                <el-option label="域名" :value="0"></el-option>
                <el-option label="公司" :value="1"></el-option>
            </el-select>
            <el-input placeholder="搜索关键字" size="small" prefix-icon="el-icon-search" v-model="searchTerms.keyword" style="width:500px;" :disabled="searchLoading"></el-input>
            <el-button type="primary" plain size="small" @click="toSearch" style="width:100px;" :loading="searchLoading">
                {{searchLoading ? "搜索中" : "搜索"}}
            </el-button>
        </div>
        <!-- 搜索完毕提示 -->
        <div v-show="isEndSearch">
            <el-alert v-if="resultList.length == 0" title="搜索无数据" type="warning"></el-alert>
            <el-alert v-else type="success">
                <div slot="title">
                    <span style="cursor: pointer;" @click="chooseNav('/clue/myClue')">搜索完毕,请前往我的线索查看!</span>
                </div>
            </el-alert>
        </div>

        <div class="searchLogList" v-show="!isClickSearch && searchLogList.length !== 0">
            <div class="tt">搜索记录</div>
            <!-- <div class="logItem" v-for="(item,index) in searchLogList" :key="index">
                <span>{{item.createTime}}</span>
                <span style="padding-left: 10px;">搜索关键字：</span>
                <span style="color:var(--themeColor)">{{item.keyword}}</span>
            </div> -->
            <el-table :data="searchLogList" :header-cell-style="{'color':'#3A3A3A'}">
                <el-table-column prop="keyword" label="(域名/公司)关键字">
                    <template slot-scope="scope">
                        <el-link v-if="scope.row.count > 0" type="primary" @click="toViewContact(scope.row)">{{scope.row.keyword}}</el-link>
                        <span v-else>{{scope.row.keyword}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="count" label="邮箱个数"></el-table-column>
                <el-table-column prop="createTime" label="时间" width="180px"></el-table-column>   
                <el-table-column label="操作" width="300px">
                    <template slot-scope="scope">
                        <div v-if="scope.row.count > 0">
                            <el-tag effect="plain" size="small" @click="toEDM(scope.row.key)">邮件营销</el-tag>
                            <el-tag effect="plain" size="small" @click="exportContact(scope.row.key)">导出联系人</el-tag>
                            <el-tag effect="plain" size="small" @click="toViewContact(scope.row)">查看全部联系人</el-tag>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination hide-on-single-page background layout="total,prev,pager,next" :total="logTotal" style="float:right;margin-top: 10px;" :current-page.sync="logPage" @current-change="getSearchRecords"></el-pagination>
        </div>

        <div class="searchBefore" v-show="!isClickSearch && searchLogList.length == 0">
            暂无搜索记录,勾选域名或公司,输入关键字搜索!
        </div>

        <div class="searchResult" v-show="isClickSearch">
            <el-empty v-if="isEndSearch && resultList.length == 0" description="搜索无数据"></el-empty>
            <div v-else>
                <div class="resultNumber">为您找到<span>{{resultList.length}}</span>个联系人</div>
                <el-table :data="resultList.slice(20*(resultPageIndex-1),20*resultPageIndex)" height="calc(100vh - 320px)" :header-cell-style="{'color':'#3A3A3A'}">
                    <el-table-column label="姓名">
                        <template slot-scope="scope">
                            <div style="display: flex;">
                                <span>{{scope.row.name}}</span>
                                <el-tooltip class="item" effect="dark" content="Linkedin" placement="top">
                                    <div class="inIcon" v-if="scope.row.linkedin" @click="jumpWeb(scope.row.linkedin)">
                                        <svg t="1678958153071" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3034" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="20">
                                            <path d="M852 0 172 0C77.408 0 0 77.408 0 172l0 680c0 94.592 77.408 172 172 172l680 0c94.592 0 172-77.408 172-172L1024 172C1024 77.408 946.592 0 852 0zM384 832l-128 0L256 384l128 0L384 832zM320 320c-35.36 0-64-28.64-64-64s28.64-64 64-64 64 28.64 64 64S355.36 320 320 320zM832 832l-128 0L704 576c0-35.36-28.64-64-64-64s-64 28.64-64 64l0 256-128 0L448 384l128 0 0 79.456C602.4 427.2 642.752 384 688 384c79.52 0 144 71.648 144 160L832 832z" fill="var(--themeColor)" p-id="3035"></path>
                                        </svg>
                                    </div>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="sexStr" label="性别" width="60px"></el-table-column>
                    <el-table-column prop="position" label="职位"></el-table-column>
                    <el-table-column prop="companyName" label="公司"></el-table-column>
                    <el-table-column label="国家">
                        <template slot-scope="scope">
                            <img :src="scope.row.flagUrl" style="margin-right:5px;height:14px;vertical-align: middle;" />
                            <span>{{ scope.row.country }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="email" label="邮箱">
                        <template slot-scope="scope">
                            <EmailValue :value="scope.row.email" :isVerify="scope.row.isVerify" />
                        </template>
                    </el-table-column>
                    <el-table-column prop="tel" label="手机号"></el-table-column>
                </el-table>
                <div style="height:40px;">
                    <el-pagination background layout="total,prev,pager,next" :total="resultList.length" :page-size="20" style="float:right;margin-top:4px;" :current-page.sync="resultPageIndex"></el-pagination>
                </div>
            </div>
        </div>

        <el-drawer title="查看全部联系人" :visible.sync="drawerVisible" size="66%">
            <el-tag effect="plain" size="small" @click="toEDM(viewKey)">邮件营销</el-tag>
            <el-tag effect="plain" size="small" @click="exportContact(viewKey)">导出联系人</el-tag>
            <div style="margin-top:20px;" v-loading="detailLoading">
                <el-radio-group v-model="viewType" size="small" @change="typeChange">
                    <el-radio-button label="">全部联系人</el-radio-button>
                    <el-radio-button label="1">企业联系人</el-radio-button>
                    <el-radio-button label="2">有社媒</el-radio-button>
                    <el-radio-button label="3">私人邮箱</el-radio-button>
                </el-radio-group>
                <el-table :data="detailList" :header-cell-style="{'color':'#3A3A3A'}">
                    <el-table-column prop="name" label="姓名">
                        <template slot-scope="scope">
                            <div style="display: flex;">
                                <el-link type="primary" @click="toContactDetail(scope.row)">{{scope.row.name || "——"}}</el-link>
                                <el-tooltip class="item" effect="dark" content="Linkedin" placement="top">
                                    <div class="inIcon" v-if="scope.row.linkedin" @click="jumpWeb(scope.row.linkedin)">
                                        <svg t="1678958153071" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3034" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="20">
                                            <path d="M852 0 172 0C77.408 0 0 77.408 0 172l0 680c0 94.592 77.408 172 172 172l680 0c94.592 0 172-77.408 172-172L1024 172C1024 77.408 946.592 0 852 0zM384 832l-128 0L256 384l128 0L384 832zM320 320c-35.36 0-64-28.64-64-64s28.64-64 64-64 64 28.64 64 64S355.36 320 320 320zM832 832l-128 0L704 576c0-35.36-28.64-64-64-64s-64 28.64-64 64l0 256-128 0L448 384l128 0 0 79.456C602.4 427.2 642.752 384 688 384c79.52 0 144 71.648 144 160L832 832z" fill="var(--themeColor)" p-id="3035"></path>
                                        </svg>
                                    </div>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="sexStr" label="性别" width="60px"></el-table-column>
                    <el-table-column prop="position" label="职位"></el-table-column>
                    <el-table-column prop="companyName" label="公司"></el-table-column>
                    <el-table-column label="国家">
                        <template slot-scope="scope">
                            <img :src="scope.row.flagUrl" style="margin-right:5px;height:14px;vertical-align: middle;" />
                            <span>{{ scope.row.country }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="email" label="邮箱">
                        <template slot-scope="scope">
                            <EmailValue :value="scope.row.email" :isVerify="scope.row.isVerify" />
                        </template>
                    </el-table-column>
                    <el-table-column prop="tel" label="手机号"></el-table-column>
                </el-table>
                <div style="height:40px;">
                    <el-pagination background layout="total,prev,pager,next" :total="detailTotal" style="float:right;margin-top:4px;" :current-page.sync="detailPage" @current-change="getDetail"></el-pagination>
                </div>
            </div>
        </el-drawer>

    </div>
</div>
</template>

<script>
import ClueNav from "@/components/ClueNav.vue";
export default {
    components: { ClueNav },
    props: {
        handleSelect: {
            type: Function,
            default: null,
        },
    },
    data() {
        return {
            currentPath: "/clue/searchMailbox",
            searchLoading: false,
            searchTerms: {
                "keyword": "", //搜索关键字
                "type": 0,  // 0域名 1公司
                "pageIndex": 1,
                "pageSize": 50,
            },
            resultList: [],
            isClickSearch: false,
            isEndSearch: false,
            resultPageIndex: 1,
            //搜索记录
            searchLogList: [],
            logPage: 1,
            logTotal: 0,
            //查看全部联系人
            drawerVisible: false,
            viewKey: "",
            viewType: "",
            detailPage: 1,
            detailLoading: false,
            detailList: [],
            detailTotal: 0,
        };
    },
    methods: {
        chooseNav(index) {
            this.handleSelect(index);
            this.$router.push(index);
        },

        //点击搜索
        toSearch(){
            this.searchTerms.keyword = this.searchTerms.keyword.trim();
            if(!this.searchTerms.keyword){
                this.$message({ message: '搜索关键字不能为空!', type: 'warning'});
                return false;
            }
            this.isClickSearch = true;
            this.isEndSearch = false;
            this.resultPageIndex = 1;
            this.searchLoading = true;
            this.resultList = [];
            this.requestPort(1);
        },
        requestPort(pageIndex){
            this.searchTerms.pageIndex = pageIndex;
            this.$axios.post(`${this.$clueUrl}/MyClue/SearchEmail`,this.searchTerms).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    data.contacts = data.contacts.map(item => ({
                        ...item,
                        flagUrl: window.getFlagUrlByCountry ? window.getFlagUrlByCountry(item.country) : '',
                    }))
                    this.resultList = this.resultList.concat(data.contacts);
                    if(data.isEnd){
                        this.searchLoading = false;
                        this.isEndSearch = true;
                        this.$message({ message: '搜索完毕', type: 'success'});
                    }else{
                        this.requestPort(pageIndex + 1);
                    }
                }
            });
        },

        //获取搜索记录
        getSearchRecords(){
            this.$axios.post(`${this.$clueUrl}/MyClue/GetSearchEmailLog`,{
                "keyword": "",
                "pageIndex": this.logPage,
                "pageSize": 10
            }).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    this.searchLogList = data.datas;
                    this.logTotal = data.totalItems;
                }
            });
        },
        //邮件营销
        toEDM(key){
            let routeData = this.$router.resolve({
                // path: '/marketing/New',
                path: '/marketing/MailMarketing',
                query: { searchRecordKey: key},
            });
            window.open(routeData.href, "_blank");
        },
        //导出联系人
        exportContact(key){
            this.$axios({
                url: `${this.$clueUrl}/MyClue/ExportEmail?key=${key}`,
                method: 'GET',
                responseType: 'blob',
            }).then(response => {
                var blob = new Blob([response]);
                var link= document.createElement('a');
                link.download = '联系人.xlsx';
                link.href = URL.createObjectURL(blob);
                link.click();
                this.$message({message: "导出联系人成功",type: 'success'});
            });
        },
        //查看全部联系人
        toViewContact(row){
            this.drawerVisible = true;
            this.viewKey = row.key;
            this.viewType = "";
            this.detailPage = 1;
            this.getDetail();
        },
        getDetail(){
            this.detailLoading = true;
            this.$axios.post(`${this.$clueUrl}/MyClue/GetEmailLogDetail`,{
                key: this.viewKey,
                type: this.viewType,
                pageIndex: this.detailPage,
                pageSize: 10,
            }).then((res) => {
                const { isSuccess , data } = res;
                this.detailLoading = false;
                if (isSuccess) {
                    this.detailList = data.datas.map(item => ({
                        ...item,
                        flagUrl: window.getFlagUrlByCountry ? window.getFlagUrlByCountry(item.country) : '',
                    }))
                    this.detailTotal = data.totalItems;
                }
            });
        },
        typeChange(value){
            this.viewType = value;
            this.detailPage = 1;
            this.getDetail();
        },

        //查看联系人详情
        toContactDetail(row){
            let routeData = this.$router.resolve({
                path: "/clue/contactDetails",
                query: { key: row.key },
            });
            window.open(routeData.href, "_blank");
        },
        //打开外链
        jumpWeb(url){
            let jumpUrl = url.indexOf("http") > -1 ? url : "http://" + url;
            let routeData = this.$router.resolve({
                path: "/jumpWeb",
                query: { jumpUrl: jumpUrl },
            });
            window.open(routeData.href, "_blank");
        },
    },
    mounted() {
        this.getSearchRecords();
        let grabKey = this.$route.query.grabKey;
        if(grabKey){
            this.drawerVisible = true;
            this.viewKey = grabKey;
            this.getDetail();
        }
    },
};
</script>

<style lang="scss" scoped>
@import '@/scss/common.scss';
.viewbox{
    width: calc(100% - 200px);
    height: calc(100vh - 80px);
    box-sizing: border-box;
    overflow: auto;
    background: #FFFFFF;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    padding: 20px;
    .title{
        font-size: 16px;
        font-weight: bold;
        height: 32px;
        line-height: 32px;
        margin-bottom: 10px;
    }
    .searchBox{
        margin-bottom: 10px;
    }
    .searchLogList{
        margin-bottom: 10px;
        padding: 10px 20px;
        color: #9a9a9a;
        border: 1px solid #dadada;
        box-sizing: border-box;
        overflow: hidden;
        .tt{
            color: #3A3A3A;
            margin-bottom: 10px;
        }
        .logItem{
            height: 24px;
            line-height: 24px;
            color: #6a6a6a;
        }

    }
    .searchBefore{
        padding: 100px 0;
        line-height: 60px;
        text-align: center;
        font-size: 40px;
        color: #9a9a9a;
        border: 1px solid #dadada;
        box-sizing: border-box;
    }
    .searchResult{
        height: calc(100% - 120px);
        border: 1px solid #dadada;
        box-sizing: border-box;
        .resultNumber{
            height: 40px;
            line-height: 40px;
            background: #F1F1F1;
            color: #3A3A3A;
            padding-left: 20px;
            display: flex;
            span{
                padding: 0 10px;
                color: var(--themeColor);
            }
        }
    }
}
.el-tag{
    cursor: pointer;
}
.inIcon{
    padding-left: 5px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
}
</style>