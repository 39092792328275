<template>
<div style="display: flex;">
    <ClueNav @chooseNav="chooseNav" :currentPath="currentPath" />
    <div class="viewbox">
        <div class="header">
            <el-checkbox v-model="isContact" style="margin-right: 10px;">有联系人</el-checkbox>
            <el-input style="width: 400px;" prefix-icon="el-icon-search" placeholder="搜索关键字" v-model="keyword" size="small" @keyup.enter.native="onSearch" :disabled="searchLoading"></el-input>
            <el-button type="primary" size="small" :loading="searchLoading" style="margin-left:10px;width:80px;" @click="onSearch">
                {{searchLoading ? "搜索中" : "搜 索"}}
            </el-button>
            <el-button type="info" size="small" v-if="searchLoading" @click="stopSearch">终止搜索</el-button>
        </div>

        <div style="margin-top: 10px;">
            <div class="searchRecord" v-show="totalItems==0">
                <div class="title">全网搜索记录</div>
                <div class="oneBox" v-show="step==1">
                    <el-table :data="recordList" :header-cell-style="{'color':'#3A3A3A'}">
                        <el-table-column prop="keyword" label="关键字"></el-table-column>
                        <el-table-column prop="count" label="总条数"></el-table-column>
                        <el-table-column prop="contactsNum" label="联系人数量"></el-table-column>
                        <el-table-column prop="createTime" label="创建时间" width="180px"></el-table-column>
                        <el-table-column label="操作" width="180px">
                            <template slot-scope="scope">
                                <el-tag effect="plain" size="small" @click="toViewResult(scope.row)">查看结果</el-tag>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination hide-on-single-page background layout="total,prev,pager,next" :total="recordTotal" style="float:right;margin-top: 10px;" :current-page.sync="recordPage" @current-change="getSearchRecord"></el-pagination>
                </div>
                <div class="twoBox searchResult" v-show="step==2">
                    <div class="title">
                        <el-page-header @back="step = 1"></el-page-header>
                        为您找到<span style="color:#FF2D2D;">{{detailTotal}}</span>条相关结果
                    </div>
                    <div class="resultList" v-loading="tabLoading">
                        <div class="resultItem" v-for="(item,index) in detailList" :key="index">
                            <div class="contactsNum">
                                <div>联系人</div>
                                <div style="font-size:18px;">{{item.contactsNum}}</div>
                            </div>
                            <div style="margin-left:20px;float: left;width:calc(100% - 120px)">
                                <div style="margin-bottom: 8px;">
                                    <div class="name" @click="toDetails(item)">{{item.companyName}}</div>
                                    <!-- <div class="country">
                                        <img :src="item.flagUrl" style="margin-right:5px;height:14px" />
                                        <span>{{item.country}}</span>
                                    </div> -->
                                </div>
                                <div class="twoField">
                                    <span class="color99">标题：</span>
                                    <span class="color3a">{{item.title}}</span>
                                </div>
                                <div class="twoField">
                                    <span class="color99">网站：</span>
                                    <span class="primaryColor" @click="openWebsite(item.site)">{{item.site}}</span>
                                </div>
                                <div class="twoField">
                                    <span class="color99">描述：</span>
                                    <span class="color3a">{{item.description}}</span>
                                </div>
                                <!-- 新增功能 -->
                                <div class="hasAdd" v-if="item.isMyClue">已加入联系人线索</div>
                                <div class="addBtn" @click="addContactsClue(item)" :class="isDisable ? 'forbidden' :''" v-else>
                                    加入联系人线索
                                </div>
                            </div>
                        </div>
                        <el-pagination background layout="total,prev,pager,next" :total="detailTotal" style="float:right;margin-top:15px;" :current-page.sync="detailPage" @current-change="getRecordDetails"></el-pagination>
                    </div>
                </div>
            </div>
            
            <!-- <div class="searchResult noResult" v-show="totalItems==0">
                <div class="hintTxt">全网搜</div>
                <img class="hintImg" src="@/assets/cluePicture/hint2.png" alt="" />
            </div> -->

            <div class="searchResult" v-show="totalItems!=0">
                <div class="title">为您找到<span style="color:#FF2D2D;">{{totalItems}}</span>条相关结果</div>
                <div class="resultList">
                    <div class="resultItem" v-for="(item,index) in showList" :key="index">
                        <div class="contactsNum">
                            <div>联系人</div>
                            <div style="font-size:18px;">{{item.contactsNum}}</div>
                        </div>
                        <div style="margin-left:20px;float: left;width:calc(100% - 120px)">
                            <div style="margin-bottom: 8px;">
                                <div class="name" @click="toDetails(item)">{{item.companyName}}</div>
                                <!-- <div class="country">
                                    <img :src="item.flagUrl" style="margin-right:5px;height:14px" />
                                    <span>{{item.country}}</span>
                                </div> -->
                            </div>
                            <div class="twoField">
                                <span class="color99">标题：</span>
                                <span class="color3a">{{item.title}}</span>
                            </div>
                            <div class="twoField">
                                <span class="color99">网站：</span>
                                <span class="primaryColor" @click="openWebsite(item.site)">{{item.site}}</span>
                            </div>
                            <div class="twoField">
                                <span class="color99">描述：</span>
                                <span class="color3a">{{item.description}}</span>
                            </div>
                            <!-- 新增功能 -->
                            <div class="hasAdd" v-if="item.isMyClue">已加入联系人线索</div>
                            <div class="addBtn" @click="addContactsClue(item)" :class="isDisable ? 'forbidden' :''" v-else>
                                加入联系人线索
                            </div>
                        </div>
                    </div>
                    <el-pagination background layout="total,prev,pager,next" :total="totalItems" style="float:right;margin-top:15px;" :current-page.sync="pageIndex" @current-change="pageChange"></el-pagination>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import ClueNav from "@/components/ClueNav.vue";
import {toFirm} from '@/utils'
export default {
    components: { ClueNav },
    props: {
        handleSelect: {
            type: Function,
            default: null,
        },
    },
    data() {
        return {
            currentPath: "/clue/wholeSearch",
            //搜索条件
            keyword: "",    //搜索关键字
            offset: 0,
            isContact: true,
            //列表展示
            isStop: false,      //是否停止搜索
            searchLoading: false,
            searchList: [],     //搜索结果列表
            showList: [],       //搜索展示列表
            totalItems: 0,
            pageIndex: 1,
            isDisable: false,

            //全网搜索记录
            step: 1,
            //搜索记录列表(step==1)
            recordList: [],
            recordPage: 1,
            recordTotal: 0,
            //记录详情列表(step==2)
            viewKeyword: "",
            tabLoading: false,
            detailList: [],
            detailPage: 1,
            detailTotal: 0,
        };
    },
    methods: {
        chooseNav(index){
            this.handleSelect(index);
            this.$router.push(index);
        },

        //加入联系人线索
        addContactsClue(item){
            if(this.isDisable){
                return false;
            }
            this.isDisable = true;
            this.$axios.post(`${this.$clueUrl}/MyClue/AddContactsClue`,[{
                "companyName": item.companyName,
                "domain": item.site,
            }]).then((res) => {
                const { isSuccess } = res;
                this.isDisable = false;
                if (isSuccess) {
                    this.$message({ message: '添加成功', type: 'success'});
                    item.isMyClue = true;
                }
            });
        },

        getList() {
            this.keyword = this.keyword.trim();
            this.searchLoading = true;
            this.$axios.post(`${this.$clueUrl}/GoogleSearch/GetRootDomain`, {
                keyword: this.keyword,
                offset: this.offset,
                isContact: this.isContact ? 1 : 0,
            }).then((res) => {
                const { isSuccess, data } = res;
                this.searchLoading = false;
                if (isSuccess) {
                    this.searchList = this.searchList.concat(data);
                    this.totalItems = this.searchList.length;
                    this.pageChange(this.pageIndex);
                    if(this.isStop){
                        return false;
                    }
                    if(data.length == 0){
                        if(this.totalItems == 0){
                            this.$message({message: "搜索无数据",type: "warning",duration: 1000});
                        }else{
                            this.$message({message: "搜索完毕",type: "success",duration: 1000});
                        }
                    }else{
                        this.offset = this.offset + 10;
                        this.getList();
                    }
                }
            })
        },
        pageChange(page) {
            this.pageIndex = page;
            this.showList = this.searchList.slice((this.pageIndex - 1) * 10 ,this.pageIndex * 10);
        },
        onSearch() {
            this.keyword = this.keyword.trim();
            if(!this.keyword){
                this.$message({message: "请输入搜索关键字",type: "warning",duration: 1000});
                return false;
            }
            this.isStop = false;
            this.offset = 0;
            this.searchList = [];
            this.showList = [];
            this.getList();
        },
        stopSearch(){
            this.$message({message: "已停止搜索",type: "info",duration: 1000});
            this.isStop = true;
            this.searchLoading = false;
        },

        //前往线索详情
        toDetails(row){
            // 验证查看详情次数
            this.$axios.get(`${this.$clueUrl}/SecurityStrategy/CheckDetailsCount`).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    localStorage.setItem('wholeInfo',JSON.stringify(row));
                    let routeData = this.$router.resolve({
                       path: "/clue/clueDetails",
                       query: { companyName: row.companyName, clueType: 'wholeSearch', },
                    });
                    window.open(routeData.href, "_blank");
                    // console.log(row)
                    // let submitObj = {
                    //     "clueSource": 6,
                    //     "clueRelationKey": "",
                    //     "companyName": row.companyName,
                    //     "country": "",
                    //     "legalPerson": "",
                    //     "contactNumber": "",
                    //     "registrationTime": "",
                    //     "employeesNumber": 0,
                    //     "officialWebsite": row.site,
                    //     "corporateEmail": "",
                    //     "businessAddress": "",
                    //     "companyProfile": "",
                    //     "industrys": [],
                    //     isMyClue:row.isMyClue
                    // }
                    // toFirm({item:submitObj,company:row.companyName,countryCode:row.threeEnCountryCode})
                }
            });
        },
        //打开网址
        openWebsite(url){
            let jumpUrl = url.indexOf("http") > -1 ? url : "http://" + url;
            let routeData = this.$router.resolve({
                path: "/jumpWeb",
                query: { jumpUrl: jumpUrl },
            });
            window.open(routeData.href, "_blank");
        },

        //获取全网搜索记录
        getSearchRecord(){
            this.$axios.post(`${this.$clueUrl}/GoogleSearch/GetRootDomainLogs`,{
                pageIndex: this.recordPage,
                pageSize: 10,
            }).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.recordList = data.datas;
                    this.recordTotal = data.totalItems;
                }
            });
        },
        toViewResult(row){
            this.step = 2;
            this.viewKeyword = row.keyword;
            this.detailPage = 1;
            this.getRecordDetails();
        },
        getRecordDetails(){
            this.tabLoading = true;
            this.$axios.post(`${this.$clueUrl}/GoogleSearch/GetLogsDetails`,{
                keyword: this.viewKeyword,
                pageIndex: this.detailPage,
                pageSize: 10,
            }).then((res) => {
                const { isSuccess, data } = res;
                this.tabLoading = false;
                if (isSuccess) {
                    this.detailList = data.datas;
                    this.detailTotal = data.totalItems;
                }
            });
        },

    },
    mounted() {
        this.getSearchRecord(); //获取全网搜索记录
    },
};
</script>

<style lang="scss" scoped>
.viewbox {
    width: calc(100% - 200px);
    height: calc(100vh - 80px);
    box-sizing: border-box;
    overflow: auto;
    background: #FFFFFF;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    padding: 20px;
    .searchResult{
        // margin-top: 20px;
        .title{
            display: flex;
            height: 40px;
            line-height: 40px;
            background: #F1F1F1;
            color: #3A3A3A;
            padding-left: 20px;
        }
        .resultList{
            padding: 20px;
            overflow: hidden;
            .resultItem{
                font-size: 14px;
                padding: 10px 0;
                border-bottom: 1px solid #E8E8E8;
                position: relative;
                overflow: auto;
                .contactsNum{
                    margin-top: 30px;
                    float: left;
                    width: 100px;
                    height: 56px;
                    background: var(--themeColor);
                    opacity: 0.8;
                    border-radius: 8px 8px 8px 8px;
                    color: #FFFFFF;
                    line-height: 28px;
                    text-align: center;
                }
                .name{
                    display: inline-block;
                    color: var(--themeColor);
                    text-decoration: underline;
                    cursor: pointer;
                }
                .country{
                    display: inline-block;
                    margin-left: 20px;
                    img{
                        vertical-align: middle;
                    }
                }
                .color99{
                    color: #999999;
                }
                .color3a{
                    color: #3A3A3A;
                }
                .primaryColor{
                    color: var(--themeColor);
                    cursor: pointer;
                }
                .twoField{
                    line-height: 28px;
                }
                .addBtn{
                    position: absolute;
                    top: 10px;
                    right: 0;
                    padding: 0 10px;
                    height: 28px;
                    line-height: 28px;
                    font-size: 14px;
                    color: var(--themeColor);
                    background: #eaeef7;
                    border: 1px solid #adbadf;
                    border-radius: 4px;
                    cursor: pointer;
                    &:hover{
                        background: var(--themeColor);
                        color: #FFFFFF;
                    }
                    img{
                        position: absolute;
                        left: 10px;
                        top: 5px;
                    }
                }
                .forbidden{
                    cursor: not-allowed;
                }
                .hasAdd{
                    position: absolute;
                    top: 10px;
                    right: 0;
                    padding: 0 10px;
                    height: 28px;
                    line-height: 28px;
                    font-size: 14px;
                    color: #B7B7B7;
                    background: rgba(183, 183, 183, 0.2);
                    border: 1px solid #B7B7B7;
                    border-radius: 4px;
                }
            }
        }
    }
    .noResult{
        overflow: hidden;
        .hintImg{
            float: left;
            width: 800px;
        }
        .hintTxt{
            float: left;
            margin-left: 80px;
            margin-top: 200px;
            font-size: 80px;
            line-height: 100px;
            font-weight: bold;
            color: #DFDFDF;
        }
    }
    .searchRecord{
        .title{
            font-size: 16px;
            color: #3A3A3A;
            font-weight: bold;
            line-height: 40px;
        }
        .el-tag{
            cursor: pointer;
        }
    }
    /deep/.el-page-header__title{
        line-height: 40px;
    }
}
</style>
