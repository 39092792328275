<template>
  <div id="company-analyse" class="company-analyse" v-loading="loading" v-show="!hidden">
    <div class="title" ref="title">
      <div class="text">
        <p style="font-weight: bold">{{ companyType === "importer" ? "供应商" : "采购商" }}排名</p>
      </div>
      <div style="display: flex; align-items: center">
        <el-radio-group v-model="controlForm.option" size="mini">
          <el-radio-button :label="0">频次</el-radio-button>
          <el-radio-button :label="1">金额</el-radio-button>
          <el-radio-button :label="2">数量</el-radio-button>
          <el-radio-button :label="3">重量</el-radio-button>
        </el-radio-group>
        <img v-show="false" class="icon" src="@/firm/images/company/icon-download.png" style="margin-left: 1em" />
      </div>
    </div>
    <div class="content">
      <div ref="chart" style="height: 400px"></div>
      <el-table :data="tableData" :header-cell-style="{ background: '#E5E9F2', color: '#1F2D3D' }" :row-style="tableRowStyleCallBack">
        <el-table-column prop="order" width="65px" align="center" />
        <el-table-column prop="company" :label="companyType === 'importer' ? '供应商' : '采购商'" />
        <el-table-column prop="frequency" label="频次" width="120px" show-overflow-tooltip />
        <el-table-column prop="amount" label="金额" width="120px" show-overflow-tooltip />
        <el-table-column prop="count" label="数量" width="120px" show-overflow-tooltip />
        <el-table-column prop="weight" label="重量" width="120px" show-overflow-tooltip />
      </el-table>
      <div style="margin-top: 1em; display: flex; justify-content: flex-end">
        <el-pagination background layout="total, prev, pager, next" :total="pagination.total" :page-size="pagination.pageSize" :current-page="pagination.pageIndex" @current-change="handlePageIndexChange" />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import echarts from "@/firm/plugins/echarts";
import { tableRowStyleCallBack } from "@/firm/utils";
import axios from "@/firm/plugins/axios";
import { mapState, mapGetters, mapMutations } from "vuex";
import LazyOperation from "@/firm/plugins/lazy-execute";
import { AnchorInditor } from "../../utils";

let chartInstance = null;
let lazyOperation = null;

export default Vue.extend({
  data() {
    return {
      hidden: false,
      loading: false,
      controlForm: {
        option: 0,
      },
      tableData: [],
      pagination: {
        total: 0,
        pageSize: 10,
        pageIndex: 1,
      },
    };
  },
  computed: {
    ...mapState(["company", "companyType"]),
    ...mapState("controlFormGlobal", {
      ie: "ie",
      dataCountry: "dataCountry",
      dateRange: "dateRange",
      hsCode: "hsCode",
      importer: "importer",
      exporter: "exporter",
      country: "country",

      exHsCode: "exHsCode",
      exExporter: "exExporter",
      exImporter: "exImporter",
      exCountry: "exCountry",
    }),
    ...mapGetters("controlFormGlobal", {
      startTime: "startTime",
      endTime: "endTime",
    }),
  },
  watch: {
    "controlForm.option"() {
      this.hidden = false;
      this.setAnchorFlag([["COMPAANLYSE", true]]);
      lazyOperation?.reobserve();
    },
    ie() {
      this.hidden = false;
      this.setAnchorFlag([["COMPAANLYSE", true]]);
      lazyOperation?.reobserve();
    },
    dataCountry() {
      this.hidden = false;
      this.setAnchorFlag([["COMPAANLYSE", true]]);
      lazyOperation?.reobserve();
    },
    dateRange() {
      this.hidden = false;
      this.setAnchorFlag([["COMPAANLYSE", true]]);
      lazyOperation?.reobserve();
    },
    hsCode() {
      this.hidden = false;
      this.setAnchorFlag([["COMPAANLYSE", true]]);
      lazyOperation?.reobserve();
    },
    importer() {
      this.hidden = false;
      this.setAnchorFlag([["COMPAANLYSE", true]]);
      lazyOperation?.reobserve();
    },
    exporter() {
      this.hidden = false;
      this.setAnchorFlag([["COMPAANLYSE", true]]);
      lazyOperation?.reobserve();
    },
    country() {
      this.hidden = false;
      this.setAnchorFlag([["COMPAANLYSE", true]]);
      lazyOperation?.reobserve();
    },

    exHsCode() {
      this.hidden = false;
      this.setAnchorFlag([["COMPAANLYSE", true]]);
      lazyOperation?.reobserve();
    },
    exExporter() {
      this.hidden = false;
      this.setAnchorFlag([["COMPAANLYSE", true]]);
      lazyOperation?.reobserve();
    },
    exImporter() {
      this.hidden = false;
      this.setAnchorFlag([["COMPAANLYSE", true]]);
      lazyOperation?.reobserve();
    },
    exCountry() {
      this.hidden = false;
      this.setAnchorFlag([["COMPAANLYSE", true]]);
      lazyOperation?.reobserve();
    },

    companyType() {
      this.hidden = false;
      this.setAnchorFlag([["COMPAANLYSE", true]]);
      lazyOperation?.reobserve();
    },
  },
  methods: {
    ...mapMutations("anchor", {
      setAnchorFlag: "setAnchorFlag",
      setCurrent: "setCurrent",
    }),
    tableRowStyleCallBack,
    handlePageIndexChange(pageIndex) {
      this.getData(pageIndex);
    },
    async getData(pageIndex = 1) {
      try {
        this.loading = true;
        const res = await axios({
          method: "post",
          url: this.companyType === "importer" ? "/search/Credit/Ranking/Exporter" : "/search/Credit/Ranking/Importer",
          data: {
            pageIndex,
            pageSize: this.pagination.pageSize,
            ie: this.ie,
            importer: this.importer,
            exporter: this.exporter,
            dataCountry: this.dataCountry,
            country: this.country,
            startTime: this.startTime,
            endTime: this.endTime,
            hsCode: this.hsCode,
            sortType: this.controlForm.option,
            noHsCode: this.exHsCode,
            noExporter: this.exExporter,
            noImporter: this.exImporter,
            noCountry: this.exCountry,
            company: this.company,
            companyType: this.companyType,
          },
        });
        chartInstance?.setOption({
          legend: {
            data: res.data.result.chartData.map((item) => item.name),
          },
          series: [
            {
              name: "company-analyse",
              data: res.data.result.chartData,
            },
          ],
          color: this.companyType === "importer" ? ["#FCE6D4", "#EFD4BF", "#D8AB9B", "#E3B98E", "#D7915F", "#D29277", "#B98C69", "#AA7C6B", "#8A5949", "#5E372A"].reverse() : ["#FDF4B9", "#F9D989", "#FFB76E", "#E09B3B", "#EA853F", "#EB6914", "#E5694F", "#CF5143", "#C44317", "#A64733"].reverse(),
        });
        this.tableData = res.data.result.data.map((item) => ({
          order: item.order,
          company: item.company,
          frequency: item.frequency,
          amount: item.amount,
          count: item.count,
          weight: item.weight,
        }));
        this.pagination.total = res.data.result.total;
        this.pagination.pageIndex = pageIndex;
        if (this.tableData.length === 0) {
          throw new Error();
        }
      } catch {
        this.hidden = true;
        this.setAnchorFlag([["COMPAANLYSE", false]]);
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    chartInstance = echarts.init(this.$refs["chart"]);
    // eslint-disable-next-line
    const _this = this;
    chartInstance.setOption({
      tooltip: {
        show: true,
        trigger: "item",
        formatter(param) {
          if (param.data) {
            let str = "";
            for (let i = 1, len = param.data.name.length + 1; i < len; i++) {
              if (i % 70 === 0) {
                str += param.data.name[i - 1] + "<br />";
              } else {
                str += param.data.name[i - 1];
              }
            }
            return `
              ${str}<br />
              ${["频次", "金额", "数量", "重量"][_this.controlForm.option]}：${param.data.value}
            `;
          } else {
            return "";
          }
        },
      },
      legend: {
        type: "scroll",
        orient: "vertical",
        right: 10,
        top: 20,
        bottom: 20,
        data: [],
        textStyle: {
          width: 450,
          overflow: "breakAll",
        },
      },
      series: [
        {
          name: "company-analyse",
          type: "pie",
          radius: ["30%", "60%"],
          center: ["30%", "50%"],
          data: [],
        },
      ],
      //color: ["#FCE6D4", "#EFD4BF", "#D8AB9B", "#E3B98E", "#D7915F", "#D29277", "#B98C69", "#AA7C6B", "#8A5949", "#5E372A"].reverse(),
    });
    lazyOperation = new LazyOperation(() => {
      this.getData();
    }, this.$el);
    new AnchorInditor(() => {
      this.setCurrent("COMPAANLYSE");
    }, this.$refs["title"]);
  },
});
</script>

<style lang="scss" scoped>
@import "@/firm/scss/mixin.scss";

.company-analyse {
  margin-top: 1rem;
  background: #fff;
  padding: 1rem;
  padding-top: 0;
  @include mixin-panel-title;
}
</style>
