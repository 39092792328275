<template>
    <div class="viewBox">
        <div class="header">《上海企芯信息科技有限公司隐私协议》</div>
        <p>为帮助您充分了解在您使用企芯产品和服务的过程中，企芯服务提供者（或简称“我们”）会如何收集、使用、对外提供、存储和保护您的个人信息以及您就个人信息可行使哪些权利，我们制定了《上海企芯信息科技有限公司隐私政策》（下称“隐私政策”），以便您做出适当的选择。</p>
        <p>在您使用企芯产品或服务前，请您务必仔细阅读并透彻理解本政策，重点阅读我们以粗体或粗体下划线标识的信息，在确认充分理解并同意后使用相关产品或服务。如对本政策内容有任何疑问、意见或建议，您可通过文末的联系方式与我们联系。</p>
        <p>您同意隐私政策表示您已了解相关功能运行所需的必要个人信息，并给予相应的收集使用授权，但对于相关附加功能的开启、处理非必要个人信息以及处理敏感个人信息，我们会根据您的实际使用情况单独征求您的同意。</p>
        <p>本隐私政策将帮助您了解以下内容：</p>
        <p>一、本政策适用范围</p>
        <p>二、我们收集哪些信息</p>
        <p>三、我们如何使用Cookie和同类技术</p>
        <p>四、信息的对外提供、公开和转移</p>
        <p>五、信息的存储和保护</p>
        <p>六、您的权利</p>
        <p>七、未成年人保护</p>
        <p>八、本政策的更新</p>
        <p>九、如何联系我们</p>
        <p>附录：相关定义</p>
        <div>
            <div class="title">一、本政策适用范围</div>
            <p>本政策仅适用于企芯服务提供者以企芯网站（crm.qixinerp.com）、企芯各版本客户端应用程序、小程序、供第三方网站和应用程序使用的企芯软件开发工具包（SDK）和应用程序编程接口（API）以及随技术发展出现的我们向您提供的新形态产品及服务。</p>
            <p>除本政策说明的信息收集使用活动外，本政策不适用于企芯的第三方服务商向您提供的其他服务，其他服务适用其向您另行说明的个人信息收集使用规则。</p>
        </div>
        <div>
            <div class="title">二、我们收集哪些信息</div>
            <p>2.1 注册、登录</p>
            <p>2.1.1. 企业/组织用户</p>
            <p>1) 基本信息：为注册成为企芯企业/组织用户，使用企芯智能办公应用服务，您需经所在的企业/组织授权后，提供企业/组织名称、营业执照复印件及联系方式，否则您将不能基于企业/组织管理行为使用企芯服务。</p>
            <p>2) 补充信息：注册成为企芯企业/组织用户后，您可进一步提供企业/组织所在地、行业类型、人员规模信息。如果您需要获得更多权益，企业/组织管理员用户需代表企业/组织向我们提交法律规定的特定信息和资料，包括营业执照复印件、企业/组织名称、经营地址、法定代表人姓名、法人身份信息、手机号码。</p>
            <p>2.1.2. 最终用户</p>
            <p>1) 基本信息：企芯最终用户，为企业/组织用户体系下的成员，由企业/组织用户管理员创建，最终用户账号由企业/组织用户管控。您所属的企业/组织用户管理员需在为您创建账号时提供您的手机号码及姓名，但仅当您使用您的手机号或被管理员分配的账号密码首次登陆时，该账号才会被激活，您方可使用您所在企业/组织开通的诸如即时通讯、智能办公应用等服务，否则您将不能使用企芯服务。</p>
            <p>2) 补充信息：被创建成为企芯最终用户后，您可进一步提供头像、昵称、性别、职位、汇报对象、企业邮箱或您所属企业组织用户要求的其他信息。</p>
            <p>您理解并知悉，您的上述信息可能会在所在的企业/组织用户成员范围内展示。</p>
            <p>2.2 提供服务所需</p>
            <p>在您使用企芯服务过程中，为向您提供企芯服务，维护我们服务的正常运行，改进及优化我们的服务并保障您的账号安全或基于遵守法律法规的需求，我们会收集下述信息：</p>
            <p>2.2.1. 即时通讯</p>
            <p>您使用企信与所属的企业组织用户成员或其关联的用户进行工作交流时，我们需要记录您的交流信息、交互状态以便实现信息的传输与交互，并同步到您所有登录设备中。</p>
            <p>如果您需要发送语音信息、进行语音/视频通话、发送即时拍摄的照片/视频，您需要授权企芯获取您设备的麦克风/摄像头权限。</p>
            <p>如果您需要向对方发送照片、文件，您需要授权企芯获取您设备的相册/文件存储权限。</p>
            <p>为方便您接受语音信息，企芯向您提供语音转文字功能，您可以通过“设置-常规-企信语音消息附带转译文字”关闭该功能。</p>
            <p>如果您需要向对方发送您的位置信息时，企芯会获取您的位置信息以便向对方进行发送和展示。</p>
            <p>2.2.2. 考勤、外勤</p>
            <p>如果您的企业/组织选择地理位置打卡，企芯将会收集您的GPS位置信息或WiFi接入点进行记录。为防止打卡签到考勤作弊，企芯将会获取您设备的软件安装列表信息，比对确认是否存在作弊软件。</p>
            <p>2.2.3. 系统内搜索</p>
            <p>您可以使用各功能模块中的搜索功能，在可见权限内搜索包括聊天记录、文档、图片、日程、部门、功能名称、审批、业务数据等信息。为向您展示相关信息，我们会收集您的搜索内容及对应的信息记录。</p>
            <p>2.2.4. 消息通知</p>
            <p>在您使用企芯服务的过程中，我们可能会通过电话（使用您的注册手机号）、短信（使用您的注册手机号）、push消息（使用您的登录设备号）中的一种或多种方式向您发送消息，以便实现信息触达。对于短信通知，您可以选择退订短信；对于企芯系统内通知，您可在设备里的“设置-通知”中关闭。</p>
            <p>2.2.5. 设备信息</p>
            <p>我们会根据您在软件安装及使用中授予的具体权限，接收并记录您所使用的设备相关信息包括设备标识符（IMEI、MAC等相关信息）、应用信息（应用崩溃信息、通知开关状态、软件列表等相关信息）、设备参数及系统信息（例如设备型号、操作系统版本、设备设置等软硬件特征信息）、设备所在位置相关信息（例如IP地址、GPS/北斗位置信息以及能够提供相关信息的Wi-Fi接入点、和基站等传感器信息）。</p>
            <p>2.2.6. 日志信息</p>
            <p>当您使用我们提供的产品或服务时，我们会自动收集您对我们服务的详细使用情况，作为有关网络日志保存。例如您的搜索查询内容、IP地址、浏览器的类型、电信运营商、使用的语言、访问日期和时间、您访问的网页记录、审批日志信息、考勤日志信息、外勤日志信息、对象操作日志信息等操作日志信息。</p>
            <p>请注意，单独的设备信息、日志信息等是无法识别特定自然人身份的信息。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将有可能被视为个人信息，除取得您授权或您所在的企业组织授权（属于企业/组织用户控制数据情形下）或法律法规另有规定外，我们会将该类个人信息做匿名化、去标识化处理。</p>
            <p>2.2.7. 添加联系人</p>
            <p>您可通过扫码、通讯录添加、交换电子名片的方式添加联系人至手机通讯录。如您通过以上方式添加联系人，则您需授权企芯访问您的手机通讯录信息，我们承诺以最低频率获取手机通讯录信息。</p>
            <p>您可通过设置-隐私-通讯录隐私设置，设置您的手机号在企业组织成员内的可见范围。</p>
            <p>如您所在的企业组织用户开通了企业互联服务，您可将您所在的企业组织授权的外部联系人添加至企芯通讯录中，您可以通过 “向对方开放手机号” 设置，自行决定是否向对方展示您的手机号。</p>
            <p>2.2.8. 信息发布和互动</p>
            <p>您可通过我们的工作圈、分享、日志、网盘等信息发布功能在所属的企业组织用户范围内发布信息及评论互动，例如发布图文、链接、发表评论及问答等内容，除了您发布的信息外，我们展示的信息可能还包括您的头像、昵称、姓名、职位、部门。</p>
            <p>请注意，您发布的信息中可能会涉及您或他人的个人信息甚至个人敏感信息、任何第三方（包括但不限于您所在的企业/组织用户）的商机秘密。请您在发布相关信息之前确保已取得相关权利人明示同意。若您发布的信息中涉及儿童个人信息的，您需在发布前征得对应儿童监护人的同意。</p>
            <p>2.2.9. 参加我们举办的活动</p>
            <p>您参与我们或我们合作的第三方举办的线上或线下活动时，为保障您在联合活动中获得相关服务体验（获取相关通知等），您填写的申请表或调查问卷中可能包含收集您的姓名、电话、通讯地址、企业名称、职位等信息，其中部分信息可能是个人敏感信息，拒绝提供可能会影响您参加相关活动。</p>
            <p>2.2.10. 企业/组织控制数据</p>
            <p>我们会根据企业/组织管理员的指令，记录该企业/组织用户下的最终用户使用企芯相关功能过程中提交或产生的信息和数据（下称“企业/组织控制数据”），您使用企芯服务上载、录入、发布、传输、存储等产生的数据和资料，均为企业组织控制数据。</p>
            <p>企业/组织控制数据可能包括：</p>
            <p>a.您所在企业/组织管理员选择开通、管理和使用企芯企业通讯录、企芯智能办公应用服务的，企业/组织用户可能提交或要求您提供：您的身份信息（姓名、生日、首次就职日期）、您个人的联系方式（手机号码、个人邮箱）等信息。</p>
            <p>b.您所在企业/组织分配给您的信息（职位、隶属部门、职级、入职日期、员工编号、办公邮箱账号、办公电话等）。</p>
            <p>c.您使用企业/组织用户开通的考勤、企信、审批、签到、日志、网盘、邮箱功能而被要求提供或产生的签到人脸照片、地理位置、考勤打卡信息、审批记录、发布日志及分享信息、日程信息、企信交流记录、网盘文件信息等。</p>
            <p>d.企业/组织用户或最终用户在为业务管理需要录入的含个人信息的业务数据。</p>
            <p>e.您使用企芯订单服务时生成的订单交易信息，用于您或所属的企业组织用户管理订单。</p>
            <p>企业/组织用户要求最终用户提交人事信息、外部业务联系人信息等个人信息之前，应事先充分告知最终用户及个人信息权利人相关数据收集的目的、范围及使用方式并获得最终用户及个人信息权利人的明确同意，并仅限收集实现企业/组织用户运营及管理目的所必需的个人信息。</p>
            <p>您理解和同意，企业组织用户为上述个人信息的处理者，我们接受企业组织用户的委托，仅根据企业组织用户的指示，以及我们与企业组织用户之间的协议或基于实现企业组织用户办公管理等目的受托处理此类个人信息。</p>
            <p>对于企业组织控制数据，由相应的企业组织负责向个人信息权利人告知个人信息收集、使用的目的、方式和范围，如您对相关内容有疑问或主张相关的个人信息权益，可联系相应的企业组织管理员处理。</p>
            <p>2.2.11. 企业办公支付</p>
            <p>1) 接入：为在企芯客户端完成企业办公支付服务，您需要先按要求接入支付公司：</p>
            <p>a. 如您选择微信支付，可能需要您提供如下信息，用以提交给微信支付平台：企芯企业账号、负责人、联系人姓名、联系人手机号码、邮箱地址、企业名称、营业执照、证件持有人类型、身份证正反面、结算银行卡号、联系人身份证号及其他商户经营信息。</p>
            <p>b. 如您选择支付宝支付，由您直接在支付宝平台按要求提供相关信息。</p>
            <p>2) 支付：您通过企芯客户端使用企业办公支付服务时，我们会将您提供的企业名称、订单名称、金额及备注推送给你选择的支付公司。</p>
            <p>3) 提现：鉴于企芯已依据法律规定完成了支付功能的改造，为对历史企业/组织用户及其最终用户（2021年6月1日前创建的账户）下的钱包资金（金额大于0）完成清退，在您提现时需要您提供如下信息，用于提交至快钱支付清算信息有限公司完成提现操作：</p>
            <p>a. 企业钱包：如提现到对公账户，需要您提供营业执照、银行账户、法人身份证、身份证号码、手机号码；如提现到个人账户，需要您提供营业执照、持卡人身份证、银行账户、身份证号、手机号。</p>
            <p>b. 个人钱包：需要您提供持卡人身份证、银行卡账户、手机号。</p>
            <p>2.2.12. 客服及争议处理</p>
            <p>当您与我们联系或提出售中售后、争议纠纷处理申请时，为了保障您的账户及系统安全，我们需要您提供必要的个人信息以核验您的用户身份。</p>
            <p>为便于与您联系、尽快帮助您解决问题或记录相关问题的处理方案及结果，我们可能会使用及保存您与我们的通信/通话记录及相关内容（包括账号信息、工单信息、您为了证明相关事实提供的其他信息，或您留下的联系方式信息、反馈的问题或建议）。</p>
            <p>为了改进服务质量，我们还可能使用您与客服联系时提供的相关信息，以及您参与问卷调查时向我们发送的问卷答复信息。</p>
            <p>2.2.13. 附加服务</p>
            <p>为向您提供更便捷、更优质、个性化的产品及服务，努力提升您的体验，我们向您提供的附加服务可能会通过开启系统权限收集和使用您的个人信息。如果您不同意开启相关权限，不会影响您使用企芯其他服务，但您可能无法获得这些附加服务给您带来的用户体验。</p>
            <p>您可在您的设备的 “设置-应用权限”中逐项查看权限的状态，并可自行决定这些权限随时的开启或关闭。请您注意，这些权限默认关闭状态，您开启任一权限即代表您授权我们收集和使用相关个人信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关个人信息，也无法为您提供该权限所对应的服务。但是，您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。</p>
            <p>详情请点击查看<a href="https://www.fxiaoke.com/secure/appauth.html" target="_blank">《应用权限申请与使用情况说明》</a></p>
        </div>
        <div>
            <div class="title">三、我们如何使用Cookie和同类技术</div>
            <p>我们使用Cookie和其他类似技术，以提升您使用本服务的体验。当您使用本服务时，我们可能会使用相关技术向您的设备发送一个或多个Cookie或匿名标识符，以收集和存储您访问、使用本服务时的信息。我们承诺，不会将Cookie用于本隐私政策所述目的之外的任何其他用途。我们使用Cookie和同类技术主要为了实现以下功能或服务：</p>
            <p>1) 保障产品与服务的安全、高效运转：我们可能会设置认证与保障安全性的Cookie 或匿名标识符，使我们确认您是否安全登录服务，或者是否遇到盗用、欺诈及其他不法行为。这些技术还会帮助我们改进服务效率，提升登录和响应速度；</p>
            <p>2) 帮助您获得更轻松的访问体验：使用此类技术可以帮助您省去重复填写账户信息。</p>
            <p>大多数浏览器均为用户提供了清除浏览器缓存数据的功能，您可以在浏览器设置功能中进行相应的数据清除操作。如您进行清除，您将无法使用由我们提供的、依赖于Cookie的服务或功能。我们的全部服务只能通过使用Cookie才可得到实现，拒绝企芯的Cookie您将无法使用依赖于Cookies的企芯服务。</p>
        </div>
        <div>
            <div class="title">四、信息的对外提供、公开和转移</div>
            <p>4.1 共享</p>
            <p>我们不会与企芯以外的公司、组织和个人共享您的个人信息，但以下情况除外：</p>
            <p>1) 您已明确同意：经您明确同意，我们可能与第三方共享您的个人信息。</p>
            <p>2) 法定义务：我们可能会根据法律法规规定、诉讼、争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息。例如，司法机构基于侦查、审理与您有关的案件，可能需要调取您存储于企芯的相关数据资料。</p>
            <p>3) 为订立、履行您作为一方当事人的合同所必需的情况下的共享：如您使用企芯的企业办公支付服务，我们会根据您的选择，将您的订单信息中与交易有关的必要信息共享给交易相对方、支付公司，用于完成交易。</p>
            <p>4) 企业/组织控制：基于企业组织用户与您订立、履行您作为一方当事人的合同所必需，或者通过依法制定的劳动规章制度和依法签订的集体合同实施人力资源管理所必需，企业组织用户即为您个人信息的处理者，我们将根据企业组织用户的指示，向企业组织用户共享相关信息。</p>
            <p>5) 接入第三方系统：基于企业组织用户要求，将其指定的第三方系统与企业组织用户使用的企芯服务进行对接的，需要将您所在企业组织用户控制的企业组织员工个人基础信息（UserID、头像、昵称等信息）、基础权限、最终用户账号下的相关数据及按照企业组织用户自行配置许可的其他数据共享给指定的第三方服务商。此类第三方服务，适用第三方服务商另行向您公示的隐私保护政策，您接入并使用此类第三方服务前，需阅读并充分了解并遵守第三方服务商的产品功能、声明及相应隐私保护政策。</p>
            <p>请注意，您在使用我们服务时自愿共享的信息，可能会涉及您或他人的个人信息甚至敏感个人信息。请您更加谨慎地考虑并做出决定。</p>
            <p>4.2 委托处理</p>
            <p>我们可能委托合作伙伴处理您的个人信息，以便合作伙伴代表我们为您提供某些服务或履行职能。我们仅会出于合法、正当、必要、特定、明确的目的共享您的信息，合作伙伴只能接触到其履行职责所需信息，且我们将会通过协议要求其不得将此信息用于其他任何超出委托范围的目的。如果合作伙伴将您的信息用于我们未委托的用途，其将单独征得您的同意。</p>
            <p>我们委托的合作伙伴，可能包括受我们委托提供技术基础设施服务、分析我们服务的使用方式、提供客户服务、支付便利或进行学术研究和调查。</p>
            <p>为保障我们客户端的稳定运行、功能实现，使您能够使用和享受更多的服务及功能，我们的应用中会嵌入合作伙伴的SDK或其他类似的应用程序。我们会对合作伙伴获取有关信息的应用程序接口（API）、软件工具开发包（SDK）进行严格的安全检测，并与合作伙伴约定严格的数据保护措施，令其按照本政策以及其他任何相关的保密和安全措施来处理个人信息。</p>
            <p>有关共享及委托处理情况，您可点击查看<a href="https://www.fxiaoke.com/secure/join.html" target="_blank">《接入合作方信息收集说明》</a></p>
            <p>4.3 转让</p>
            <p>如果我们因合并、分立、解散、被宣告破产而需要转移个人信息的，我们会向您告知接收方的名称或姓名和联系方式。接收方将继续履行本政策及其他法定义务。接收方变更原先的处理目的、处理方式的，会重新取得您的同意。</p>
            <p>4.4 公开披露</p>
            <p>我们仅会在以下情况下，公开披露您的个人信息：</p>
            <p>1) 获得您单独同意或基于您的主动选择，我们可能会公开披露您的个人信息；</p>
            <p>2) 如果我们确定您出现违反法律法规或严重违反企芯相关协议规则的情况或为保护企芯用户或公众的人身财产安全免遭侵害，我们可能披露您的个人信息，包括相关违规行为以及企芯已对您采取的措施。</p>
        </div>
        <div>
            <div class="title">五、信息的存储和保护</div>
            <p>5.1 安全防护</p>
            <p>5.1.1 技术保护措施</p>
            <p>为保障您的信息安全，我们已采取符合业界标准、合理可行的安全防护措施保护您的信息，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。例如，在您的企芯客户端APP与服务器之间交换数据时受SSL协议加密保护；我们同时对企芯官网提供HTTPS协议安全浏览方式；我们会使用加密技术提高个人信息的安全性；我们会使用受信赖的保护机制防止个人信息遭到恶意攻击；我们会部署访问控制机制，尽力确保只有授权人员才可访问个人信息。</p>
            <p>5.1.2 安全体系认证</p>
            <p>目前，我们的重要信息系统已经通过GB/T22080-2016/ISO/IEC27001(信息安全管理体系认证证书)、ISO/IEC27701(隐私信息管理体系认证证书)、ISO/IEC20000-1(信息技术服务管理体系认证证书)、GB/T19001-2016/ISO9001:2015(质量管理体系认证证书)、信息系统安全等级保护备案证明（三级）。</p>
            <p>5.1.3 管理组织措施</p>
            <p>我们建立了行业内先进的以数据为核心、围绕数据生命周期进行的数据安全管理体系，从组织建设、制度设计、人员管理及产品技术的维度提升个人信息的安全性，设置了个人信息保护专职部门。我们会定期举办安全和隐私保护培训课程，通过培训课程和考试，不断加强员工对于保护个人信息重要性的认识。</p>
            <p>5.1.4 个人信息安全事件的响应</p>
            <p>如果我们的物理、技术或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改或毁坏，导致您的合法权益受损的，我们会及时启动应急预案，采取合理必要的措施，以尽可能降低对您个人的影响。如发生个人信息安全事件，我们还将按照法律法规的要求向您告知安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施。我们将以短信、电话、推送通知及其他合理渠道告知您，难以逐一告知的，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。</p>
            <p>5.2 信息存储</p>
            <p>5.2.1存储地点</p>
            <p>我们在中华人民共和国境内运营中收集和产生的个人信息，将存储在中华人民共和国境内。以下情形下，我们会在履行法律规定的义务后，向可能的境外实体提供您的个人信息：</p>
            <p>1)适用的法律有明确规定；</p>
            <p>2)获得您的单独同意；</p>
            <p>3)您作为一方当事人的跨境电子商务交易及其他类型合同订立和履行所必需。</p>
            <p>针对以上情形，我们会按照本政策依法保护您的个人信息安全。</p>
            <p>5.2.2存储期限</p>
            <p>除非法律法规有强制的留存要求，我们只会在达成本政策所述目的所需的期限内保留您的个人信息。例如，《中华人民共和国电子商务法》要求商品、服务信息、交易信息保存时间自交易完成之日起不少于三年；《中华人民共和国网络安全法》要求网络日志留存不少于六个月。我们判断个人信息的存储期限主要依据以下标准：</p>
            <p>1)完成与您相关的服务目的、维护相应服务及业务记录、应对您可能的查询或投诉；</p>
            <p>2)保证我们为您提供服务的安全和质量；</p>
            <p>3)您或您所属的企业组织用户是否同意更长的留存期间；</p>
            <p>4)是否存在保留期限的其他特别约定。</p>
            <p>超出保留期间后，我们会根据适用法律的要求删除您的个人信息，或使其匿名化处理。</p>
        </div>
        <div>
            <div class="title">六、您的权利</div>
            <p>您可以通过以下方式管理您的信息，我们会在符合法律法规要求的情形下响应您的请求：</p>
            <p>6.1查询、更正和补充</p>
            <p>6.1.1企业/组织用户</p>
            <p>1) 企业/组织用户的管理员打开企芯官方主页（www.qixinerp.com），选择右上角“用户登录”并输入账号、密码后，选择企业/组织，登陆企芯，点击“管理”菜单，经二次验密后进入企业管理界面后，可以查询、更正企业/组织控制信息，包括通讯录信息、修改登陆密码、更换管理员等。</p>
            <p>2) 拨打企芯客服热线寻求帮助。</p>
            <p>6.1.2最终用户</p>
            <p>1)登录企芯手机客户端，通过操作“我-设置-个人资料”可以查询、更正个人资料及账户相关信息：头像、昵称、电话、性别、工作描述、邮箱、微信、QQ等信息。</p>
            <p>2)拨打企芯客服热线寻求帮助。</p>
            <p>针对企业控制的数据，我们将根据企业组织用户指示处理，如您对相关内容有疑问或主张相关的个人信息权益，您可以联系您所属的企业组织管理员处理。</p>
            <p>6.2删除您的信息</p>
            <p>您可以通过上述“查询、更正和补充”中列明的方式或通过您所属的企业/组织用户管理员操作删除您的部分信息。</p>
            <p>在以下情形中，您可以向我们提出删除个人信息的请求：</p>
            <p>1)如果我们处理个人信息的行为违反法律法规；</p>
            <p>2)如果我们处理个人信息的行为严重违反了与您的约定；</p>
            <p>3)处理目的已实现、无法实现或者为实现处理目的不再必要；</p>
            <p>4)如果我们永久不再为您所在的企业组织提供产品或服务。</p>
            <p>若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的个人信息的主体，并要求其及时删除（除非法律法规另有规定，或这些主体已独立获得您的授权）。</p>
            <p>当您或我们协助您删除相关信息后，因为适用的法律和安全技术限制，我们可能无法立即从备份系统中删除相应的信息，我们将安全地存储您的个人信息并限制对其进一步处理，直到备份可以清除或实现匿名化。</p>
            <p>6.3改变授权范围</p>
            <p>您可以通过如下方式改变或撤回您授权我们收集和处理您的个人信息的范围：</p>
            <p>1)企业组织用户：您可以拨打客服热线请求我们协助您进行相关配置，具体协助受限于现有产品功能，如涉及相关费用将由企业组织用户承担。</p>
            <p>2)最终用户：您可以通过您的智能移动设备的设置给予或收回您的授权同意。</p>
            <p>当您收回同意后，我们将不再处理相应的个人信息。但您收回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。</p>
            <p>6.4注销账户</p>
            <p>6.4.1企业组织用户</p>
            <p>在停止使用企芯服务后，可通过拨打客服热线向我们主动提出账户注销申请，在账号相关信息保留达到法定最低保存期限后，我们将协助注销该企业组织用户账户及其控制的全部最终用户账号。</p>
            <p>6.4.2最终用户</p>
            <p>可以向所在企业组织管理员申请注销您使用的账户或在您所在企业组织用户账户注销的同时您使用的账户将一并注销。</p>
            <p>6.5响应您的上述请求</p>
            <p>对于您或可能的您的监护人、近亲属及其他有权主体向我们提出上述请求，以及中华人民共和国法律与其他适用法律规定的您的相关个人信息权利，您可以通过客服联系我们或直接向我们的个人信息保护专职部门发起投诉。我们将在15天内做出答复。</p>
            <p>为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。</p>
            <p>对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将酌情收取一定费用。对于与您的身份不直接关联的信息、无端重复信息，或者需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者不切实际的请求，我们可能会予以拒绝。</p>
        </div>
        <div>
            <div class="title">七、未成年人保护</div>
            <p>在数字化办公、沟通与协同活动中我们推定您具有相应的民事行为能力。如您为未成年人，请停止使用，且我们要求您请您的父母或其他监护人仔细阅读本政策。</p>
            <p>我们已制定儿童信息安全事件应急预案，定期组织内部相关人员进行应急响应培训和应急演练，使其掌握岗位职责和应急处置策略和规程。在不幸发生儿童信息安全事件后，我们将按照法律法规的要求，及时向儿童及其监护人告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、儿童及其监护人可自主防范和降低风险的建议、对儿童及其监护人的补救措施等。我们将及时将事件相关情况以APP推送通知、发送邮件/短消息等方式告知儿童及其监护人。难以逐一告知时，我们会采取合理、有效的方式发布相关警示信息。同时，我们还将按照监管部门要求，主动上报儿童信息安全事件的处置情况。若儿童及其监护人的合法权益受损，我们将按照法律规定承担相应的法律责任。</p>
            <p>如您对儿童的个人信息有疑问，请与我们的个人信息保护专职部门联系。</p>
        </div>
        <div>
            <div class="title">八、本政策的更新</div>
            <p>为给您带来更好的产品和服务体验，我们持续努力改进我们的产品、服务及技术。在新的服务及业务流程变更时，我们可能会更新我们的隐私政策，告知您具体的变化。未经您明确同意我们不会限制您按照本隐私政策所享有的权利。对于重大变更，我们会在官方主页（www.qixinerp.com）或系统站内通知（包括我们会通过小红点进行通知或弹窗提示）向您提供显著的通知。本政策所指的重大变更包括但不限于：</p>
            <p>1)产品的业务模式发生重大变化，如处理个人信息的目的、处理个人信息的类型以及个人信息的使用方式等；</p>
            <p>2)业务调整、交易并购引起的隐私政策主体变更，新主体变更原先的处理目的、处理方式；</p>
            <p>3)个人信息共享、转让或公开披露的主要对象发生变化；</p>
            <p>4)个人信息权利及其行使方式发生重大变化；</p>
            <p>5)个人信息保护负责人的联络方式及投诉渠道发生变化；</p>
            <p>6)个人信息保护影响评估报告表明产品存在对个人权益有重大影响。</p>
            <p>我们还会将本政策的旧版本进行存档，您可点击此处查阅。</p>
        </div>
        <div>
            <div class="title">九、如何联系我们</div>
            <p>您可以通过以下方式与我们联系，我们将在15天内回复您的请求：</p>
            <p>1) 如您对本政策内容有任何疑问、意见或建议，您可通过我的-帮助中心-在线服务或拨打服务热线（400-118-1622）（9:00-18:00））与我们联系；</p>
            <p>2) 我们还设立了个人信息保护专职部门，您可以通过【info@dqxx.com.cn】与其联系，办公地址：上海闵行区新龙路1333弄96号万科七宝国际29 幢303室。</p>
            <p>如果您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，您还可以通过向被告住所地有管辖权的法院提起诉讼来寻求解决方案。</p>
        </div>
        <div>
            <div class="title">附录：定义</div>
            <p>1.企芯服务提供者：是指提供企芯产品和服务的法律主体，即上海企芯信息科技有限公司及服务相关关联公司，统称企芯或者“我们”。</p>
            <p>2.支付公司：指 企芯接入的提供支付功能服务的主体：支付宝（中国）网络技术有限公司、财付通支付科技有限公司及快钱支付清算信息有限公司。</p>
            <p>3.企业/组织用户：指企业或其他组织申请在企芯平台上创建企业组织架构，并为组织成员（最终用户）配置账号。完成企芯企业组织创建后，由企业组织授权管理员配置、开通使用企芯服务并管理最终用户的使用行为。企芯企业/组织用户包括但不限于法人、政府机构、其他组织、合伙或个体工商户等。</p>
            <p>4.企业/组织管理员：指经企芯企业/组织用户授权或指定，拥有企芯企业/组织用户管理平台系统操作权限，代表企业组织开通、管理和使用企芯服务的最终用户，诸如有权管理所属企业组织通讯录及组织成员，进行企芯工作台的相关操作和配置、设定子管理员、管理组织内的最终用户，代表所属企业组织对接和管理第三方应用、确认和签署相应企芯在线协议，申请注销所属的企业组织账号等。企业用组织管理员可以为一人或多人，简称“管理员”。</p>
            <p>5.企业/组织最终用户：是指被企业/组织用户或管理员创建，作为该企业/组织用户在企芯平台搭建的企业/组织架构的成员，并在企业/组织用户或管理员设置的权限内使用该企业/组织用户开通的企芯服务，包括管理员、其他成员用户，以下统称“您”。</p>
            <p>6.个人信息：指以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息（我们将在本隐私政策中对具体个人信息以粗体下划线进行显著标识）。</p>
            <p>7.个人敏感信息：是指一旦泄露或非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，包括生物识别、宗教信仰、特定身份、医疗健康、金融账户、行踪轨迹等信息，以及不满十四周岁未成年人的个人信息。</p>
            <p>8.儿童：是指不满十四周岁的未成年人。</p>
            <p>9.个人信息处理：包括个人信息的收集、存储、使用、加工、传输、提供、公开、删除等。</p>
            <p>10.个人信息删除：指在实现日常业务功能所涉及的系统中去除个人信息的行为，使其保持不可被检索、访问的状态。</p>
            <p>11.个人信息匿名化：指个人信息经过处理无法识别特定自然人且不能复原的过程。</p>
            <p>12.个人信息处理者：是指在个人信息处理活动中自主决定处理目的、处理方式的组织、个人。在企业组织用户及最终用户使用企芯服务进行移动办公、沟通与协同时，企芯服务相关的个人信息处理者为企业/组织用户，我们受托依据管理员操作、配置等指令，对企业组织控制数据（含个人信息）进行收集、存储、使用、加工、传输、提供、公开、删除等数据受托处理。</p>
            <p>13.企业组织控制数据：指企业组织用户及其最终用户使用企芯服务过程中提交或产生的信息和数据，包括企业用户及其管理员提交或要求最终用户提供的信息、企业用户分配给最终用户的信息，具体关于企业组织控制数据相关约定，请参见本政策中关于企业组织控制数据的表述。</p>
            <p>除另有约定外，本政策所用定义与《企芯服务协议》中的定义具有相同的涵义。</p>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        };
    },
    methods: {
        
    },
    mounted() {

    },
};
</script>

<style lang="scss" scoped>
.viewBox{
    width: 1000px;
    margin: auto;
    padding: 20px;
    .header{
        font-size: 24px;
        font-weight: bold;
        line-height: 48px;
        text-align: center;
    }
    .title{
        margin-top: 10px;
        font-size: 20px;
        font-weight: bold;
    }
    p{
        font-size: 16px;
        line-height: 32px;
        text-indent: 2em;
    }
}
</style>