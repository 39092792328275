<template>
    <div>
        <div>
            <div class="title">基本信息</div>
            <div style="display: flex;">
                <el-form :model="productInfo" label-width="120px" size="small" style="width: 66%;">
                    <el-row :gutter="16">
                        <el-col :span="8">
                            <el-form-item label="尺寸信息">
                                <el-input v-model="productInfo.dimension" class="w100"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="成分">
                                <el-input v-model="productInfo.ingredient" class="w100"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="成本价(不含税)">
                                <el-input-number v-model="productInfo.sellPrice" :min="0" :controls="false" class="w100"></el-input-number>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="成本价(含税)">
                                <el-input-number v-model="productInfo.taxIncludingPrice" :min="0" :controls="false" class="w100"></el-input-number>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="计量单位">
                                <el-input v-model="productInfo.measureUnit" class="w100"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="规格/型号">
                                <el-input v-model="productInfo.specDesc" class="w100"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="海关编码">
                                <el-input v-model="productInfo.hscode" class="w100"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="产品等级">
                                <el-input v-model="productInfo.grade" class="w100"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="基本型号">
                                <el-input v-model="productInfo.basicModel" class="w100"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="是否危险品">
                                <el-switch v-model="productInfo.isDangerousGoods" class="w100"></el-switch>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="描述">
                                <el-input type="textarea" :rows="2" placeholder="请输入描述" v-model="productInfo.description"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item>
                                <el-button size="small" type="primary" @click="onSaveEdit">保 存</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
                <div style="width: 33%;text-align: center;">
                    <!-- <el-image v-if="productInfo.images" style="width:200px;height:200px;" :src="productInfo.images"></el-image> -->
                </div>
            </div>
        </div>
        
        <div>
            <div class="title">图片信息</div>
            <div style="display: flex;">
                <div class="imgStyle" v-for="(ele,index) in productImgList" :key="index">
                    <el-image :src="ele" :preview-src-list="productImgList" style="width:100%;"></el-image>
                    <el-tag v-if="ele==productInfo.images" size="mini" type="primary" effect="plain" class="zhutu">主图</el-tag>
                    <template v-else>
                        <el-tag size="mini" type="primary" effect="plain" class="setTag" @click="setMain(ele)">设置主图</el-tag>
                        <el-tag size="mini" type="danger" effect="plain" class="closeTag" @click="handleRemove(index)">删除</el-tag>
                    </template>
                </div>
                
                <el-upload :action="uploadUrl" :headers="headers" :data="uploadData" list-type="picture-card" :show-file-list="false"
                    :before-upload="beforeUpload" :on-success="uploadSuccess">
                    <i class="el-icon-plus"></i>
                </el-upload>
            </div>
        </div>

        <!-- 自定义字段 -->
        <ShowSaveField :documentKey="productKey" :type="2" />
        <!-- 附件管理 -->
        <Fujian :documentKey="productKey" type="productFujian" />

        <!-- <div style="margin-top:20px;">
            <div class="title">包装</div>
            <el-table :data="packageList" border :header-cell-style="{'background-color':'#F3F3F3','color':'#3A3A3A'}">
                <el-table-column prop="basicPackaging" label="基本包装"></el-table-column>
                <el-table-column prop="packing" label="包装"></el-table-column>
                <el-table-column prop="deadWeight" label="每包自重"></el-table-column>
                <el-table-column prop="netWeight" label="净重"></el-table-column>
                <el-table-column prop="grossWeight" label="毛重"></el-table-column>
                <el-table-column prop="volume" label="体积"></el-table-column>
                <el-table-column label="操作" width="130">
                    <template slot-scope="scope">
                        <div class="handleDelBtn" @click="toDel(scope.row,0)">删除</div>
                        <div class="handleEditBtn" @click="toEdit(scope.row,0)">编辑</div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="bottomAddBtn" @click="toAdd(0)">
                <img src="@/assets/icon/plus.png" alt="" />新增
            </div>
        </div>

        <div style="margin-top:20px;">
            <div class="title">包装计费</div>
            <el-table :data="billList" border :header-cell-style="{'background-color':'#F3F3F3','color':'#3A3A3A'}">
                <el-table-column prop="packing" label="包装"></el-table-column>
                <el-table-column prop="packingUnitPrice" label="包装单价"></el-table-column>
                <el-table-column prop="effectiveDate" label="生效日期"></el-table-column>
                <el-table-column prop="expirationDate" label="失效日期"></el-table-column>
                <el-table-column label="操作" width="130">
                    <template slot-scope="scope">
                        <div class="handleDelBtn" @click="toDel(scope.row,1)">删除</div>
                        <div class="handleEditBtn" @click="toEdit(scope.row,1)">编辑</div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="bottomAddBtn" @click="toAdd(1)">
                <img src="@/assets/icon/plus.png" alt="" />新增
            </div>
        </div>

        <div style="margin-top:20px;">
            <div class="title">税</div>
            <el-table :data="taxList" border :header-cell-style="{'background-color':'#F3F3F3','color':'#3A3A3A'}">
                <el-table-column prop="taxCategory" label="税小类"></el-table-column>
                <el-table-column prop="taxType" label="税类别"></el-table-column>
                <el-table-column prop="type" label="类型"></el-table-column>
                <el-table-column prop="taxRate" label="税率"></el-table-column>
                <el-table-column label="操作" width="130">
                    <template slot-scope="scope">
                        <div class="handleDelBtn" @click="toDel(scope.row,2)">删除</div>
                        <div class="handleEditBtn" @click="toEdit(scope.row,2)">编辑</div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="bottomAddBtn" @click="toAdd(2)">
                <img src="@/assets/icon/plus.png" alt="" />新增
            </div>
        </div>

        <div style="margin-top:20px;">
            <div class="title">计量单位</div>
            <el-table :data="unitList" border :header-cell-style="{'background-color':'#F3F3F3','color':'#3A3A3A'}">
                <el-table-column prop="unitMeasure" label="计量单位"></el-table-column>
                <el-table-column prop="basicMeasurement" label="基础计量"></el-table-column>
                <el-table-column prop="baseUnitQuantity" label="基础单位数量"></el-table-column>
                <el-table-column prop="purchaseMeasurement" label="采购计量"></el-table-column>
                <el-table-column prop="salesMeasurement" label="销售计量"></el-table-column>
                <el-table-column label="操作" width="130">
                    <template slot-scope="scope">
                        <div class="handleDelBtn" @click="toDel(scope.row,3)">删除</div>
                        <div class="handleEditBtn" @click="toEdit(scope.row,3)">编辑</div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="bottomAddBtn" @click="toAdd(3)">
                <img src="@/assets/icon/plus.png" alt="" />新增
            </div>
        </div>

        <div style="margin-top:20px;">
            <div class="title">载重</div>
            <el-table :data="loadList" border :header-cell-style="{'background-color':'#F3F3F3','color':'#3A3A3A'}">
                <el-table-column prop="packing" label="包装"></el-table-column>
                <el-table-column prop="boxType" label="箱型"></el-table-column>
                <el-table-column prop="containerLoad" label="集装箱载重"></el-table-column>
                <el-table-column prop="isNeedTray" label="是否需要托盘">
                    <template slot-scope="scope">
                        {{scope.row.isNeedTray ? "是" : "否"}}
                    </template>
                </el-table-column>
                <el-table-column prop="piecesPerPallet" label="每托盘件数"></el-table-column>
                <el-table-column label="操作" width="130">
                    <template slot-scope="scope">
                        <div class="handleDelBtn" @click="toDel(scope.row,4)">删除</div>
                        <div class="handleEditBtn" @click="toEdit(scope.row,4)">编辑</div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="bottomAddBtn" @click="toAdd(4)">
                <img src="@/assets/icon/plus.png" alt="" />新增
            </div>
        </div> -->

        <div style="margin-top:20px;">
            <div class="title">系统信息</div>
            <el-row :gutter="16">
                <el-col :span="8" class="systemItem">
                    <div class="label">创建人:</div>
                    <div class="value">{{productInfo.createUserName}}</div>
                </el-col>
                <el-col :span="8" class="systemItem">
                    <div class="label">创建时间:</div>
                    <div class="value">{{productInfo.createTime}}</div>
                </el-col>
                <el-col :span="8" class="systemItem">
                    <div class="label">更新人:</div>
                    <div class="value">{{productInfo.updateUser}}</div>
                </el-col>
                <el-col :span="8" class="systemItem">
                    <div class="label">更新时间:</div>
                    <div class="value">{{productInfo.updateTime}}</div>
                </el-col>
            </el-row>
        </div>

        <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="500px">
            <el-form v-if="handleType == 0" :model="productPacking" label-width="100px" size="small">
                <el-form-item label="基本包装">
                    <el-input v-model="productPacking.basicPackaging"></el-input>
                </el-form-item>
                <el-form-item label="包装">
                    <el-input v-model="productPacking.packing"></el-input>
                </el-form-item>
                <el-form-item label="每包自重">
                    <el-input v-model="productPacking.deadWeight"></el-input>
                </el-form-item>
                <el-form-item label="净重">
                    <el-input v-model="productPacking.netWeight"></el-input>
                </el-form-item>
                <el-form-item label="毛重">
                    <el-input v-model="productPacking.grossWeight"></el-input>
                </el-form-item>
                <el-form-item label="体积">
                    <el-input v-model="productPacking.volume"></el-input>
                </el-form-item>
            </el-form>

            <el-form v-if="handleType == 1" :model="productPackagingBilling" label-width="100px" size="small">
                <el-form-item label="包装">
                    <el-input v-model="productPackagingBilling.packing"></el-input>
                </el-form-item>
                <el-form-item label="包装单价">
                    <el-input v-model="productPackagingBilling.packingUnitPrice"></el-input>
                </el-form-item>
                <el-form-item label="生效日期">
                    <el-date-picker v-model="productPackagingBilling.effectiveDate" type="date" value-format="yyyy-MM-dd"></el-date-picker>
                </el-form-item>
                <el-form-item label="失效日期">
                    <el-date-picker v-model="productPackagingBilling.expirationDate" type="date" value-format="yyyy-MM-dd"></el-date-picker>
                </el-form-item>
            </el-form>

            <el-form v-if="handleType == 2" :model="productTax" label-width="100px" size="small">
                <el-form-item label="税小类">
                    <el-input v-model="productTax.taxCategory"></el-input>
                </el-form-item>
                <el-form-item label="税类别">
                    <el-input v-model="productTax.taxType"></el-input>
                </el-form-item>
                <el-form-item label="类型">
                    <el-input v-model="productTax.type"></el-input>
                </el-form-item>
                <el-form-item label="税率">
                    <el-input-number v-model="productTax.taxRate" :min="0" :controls="false"></el-input-number>
                </el-form-item>
            </el-form>

            <el-form v-if="handleType == 3" :model="productUnitMeasure" label-width="100px" size="small">
                <el-form-item label="计量单位">
                    <el-input v-model="productUnitMeasure.unitMeasure"></el-input>
                </el-form-item>
                <el-form-item label="基础计量">
                    <el-input v-model="productUnitMeasure.basicMeasurement"></el-input>
                </el-form-item>
                <el-form-item label="基础单位数量">
                    <el-input v-model="productUnitMeasure.baseUnitQuantity"></el-input>
                </el-form-item>
                <el-form-item label="采购计量">
                    <el-input v-model="productUnitMeasure.purchaseMeasurement"></el-input>
                </el-form-item>
                <el-form-item label="销售计量">
                    <el-input v-model="productUnitMeasure.salesMeasurement"></el-input>
                </el-form-item>
            </el-form>

            <el-form v-if="handleType == 4" :model="productLoad" label-width="100px" size="small">
                <el-form-item label="包装">
                    <el-input v-model="productLoad.packing"></el-input>
                </el-form-item>
                <el-form-item label="箱型">
                    <el-input v-model="productLoad.boxType"></el-input>
                </el-form-item>
                <el-form-item label="集装箱载重">
                    <el-input v-model="productLoad.containerLoad"></el-input>
                </el-form-item>
                <el-form-item label="是否需要托盘">
                    <el-switch v-model="productLoad.isNeedTray"></el-switch>
                </el-form-item>
                <el-form-item label="每托盘件数">
                    <el-input-number v-model="productLoad.piecesPerPallet" :min="0" :controls="false"></el-input-number>
                </el-form-item>
            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" @click="onSubmit" :loading="btnLoading">确 认</el-button>
                <el-button size="small" @click="dialogVisible = false">取 消</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import Fujian from "@/components/Fujian.vue";
import ShowSaveField from "@/components/ShowSaveField.vue"
export default {
    components: { Fujian , ShowSaveField },
    data() {
        return {
            productKey: "",
            productInfo: {},
            
            // 包装，包装计费，税，计量单位，载重列表信息
            packageList: [],
            billList: [],
            taxList: [],
            unitList: [],
            loadList: [],
            dialogVisible: false,
            dialogTitle: "",
            handleType: 0,
            productPacking: {},
            productPackagingBilling: {},
            productTax: {},
            productUnitMeasure: {},
            productLoad: {},
            btnLoading: false,

            //图片上传
            uploadUrl: `${this.$diskUrl}/File/Upload`,
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('token'),
            },
            uploadData: {
                folderKey: "0",
                remarkName: "",
                permission: '我的'
            },
            productImgList: [],
        };
    },
    methods: {
        //图片上传
        beforeUpload(file) {
            var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
            const isImg = testmsg === "png" || testmsg === "jpg" || testmsg === "jpeg";
            const isLt10M = file.size / 1024 / 1024 < 10;
            if (!isImg) {
                this.$message({ message: "上传文件只能是图片!", type: "warning"});
            }
            if (!isLt10M) {
                this.$message({ message: "上传文件大小不能超过10MB!", type: "warning"});
            }
            return isImg && isLt10M;
        },
        uploadSuccess(response){
            const {isSuccess,data} = response;
            if(isSuccess){
                this.productImgList.push(data.fileUrl);
                this.productInfo.rotationChart = this.productImgList;
                this.onSaveEdit();
            }
        },
        handleRemove(index){
            this.productImgList.splice(index,1);
            this.productInfo.rotationChart = this.productImgList;
            this.onSaveEdit();
        },
        setMain(ele){
            this.productInfo.images = ele;
            this.onSaveEdit();
        },


        //获取产品详情
        getProductInfo(){
            this.$axios.get(`${this.$productUrl}/api/Product/Detail?key=${this.productKey}`).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    this.productInfo = data;
                    this.productImgList = data.rotationChart;
                }
            });
        },
        onSaveEdit(){
            this.$axios.post(`${this.$productUrl}/api/Product/AddUpdate`,this.productInfo).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    this.$message({ message: '操作成功', type: 'success'});
                }
            });
        },


        // 获取(0 包装 1 包装计费 2 税 3 计量单位 4 载重)   /api/Product/GetDetailTableList
        getItemList(type){
            this.$axios.get(`${this.$productUrl}/api/Product/GetDetailTableList?productKey=${this.productKey}&type=${type}`).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    if(type == 0){ this.packageList = data }
                    if(type == 1){ this.billList = data }
                    if(type == 2){ this.taxList = data }
                    if(type == 3){ this.unitList = data }
                    if(type == 4){ this.loadList = data }
                }
            });
        },
        getOtherInfo(){
            this.getItemList(0);
            this.getItemList(1);
            this.getItemList(2);
            this.getItemList(3);
            this.getItemList(4);
        },

        toAdd(type){            
            this.dialogVisible = true;
            this.handleType = type;
            if(type == 0){
                this.dialogTitle = "添加包装";
                this.productPacking = {
                    "key": "",
                    "basicPackaging": "",
                    "packing": "",
                    "deadWeight": "",
                    "netWeight": "",
                    "grossWeight": "",
                    "volume": ""
                }
            }
            if(type == 1){
                this.dialogTitle = "添加包装计费";
                this.productPackagingBilling = {
                    "key": "",
                    "packing": "",
                    "packingUnitPrice": "",
                    "effectiveDate": "",
                    "expirationDate": ""
                }
            }
            if(type == 2){
                this.dialogTitle = "添加税";
                this.productTax = {
                    "key": "",
                    "taxCategory": "",
                    "taxType": "",
                    "type": "",
                    "taxRate": 0
                }
            }
            if(type == 3){
                this.dialogTitle = "添加计量单位";
                this.productUnitMeasure = {
                    "key": "",
                    "unitMeasure": "",
                    "basicMeasurement": "",
                    "baseUnitQuantity": "",
                    "purchaseMeasurement": "",
                    "salesMeasurement": ""
                }
            }
            if(type == 4){
                this.dialogTitle = "添加载重";
                this.productLoad = {
                    "key": "",
                    "packing": "",
                    "boxType": "",
                    "containerLoad": "",
                    "isNeedTray": true,
                    "piecesPerPallet": 0
                }
            }
        },
        toEdit(row,type){
            this.dialogVisible = true;
            this.handleType = type;
            if(type == 0){
                this.dialogTitle = "编辑包装";
                this.productPacking = row;
            }
            if(type == 1){
                this.dialogTitle = "编辑包装计费";
                this.productPackagingBilling = row;
            }
            if(type == 2){
                this.dialogTitle = "编辑税";
                this.productTax = row;
            }
            if(type == 3){
                this.dialogTitle = "编辑计量单位";
                this.productUnitMeasure = row;
            }
            if(type == 4){
                this.dialogTitle = "编辑载重";
                this.productLoad = row;
            }
        },
        onSubmit(){
            let submitForm = {
                type: this.handleType,
                productKey: this.productKey,
                productPacking: this.productPacking,
                productPackagingBilling: this.productPackagingBilling,
                productTax: this.productTax,
                productUnitMeasure: this.productUnitMeasure,
                productLoad: this.productLoad,
            }
            this.btnLoading = true;
            this.$axios.post(`${this.$productUrl}/api/Product/AddUpdateProductDetailTable`,submitForm).then((res) => {
                const { isSuccess } = res;
                this.btnLoading = false;
                if (isSuccess) {
                    this.dialogVisible = false;
                    this.$message({ message: '操作成功', type: 'success'});
                    this.getItemList(this.handleType);
                }
            });
        },
        toDel(row,type){
            this.$confirm(`是否删除该项?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$axios.get(`${this.$productUrl}/api/Product/DeleteDetailTable?key=${row.key}&type=${type}`).then((res) => {
                    const { isSuccess } = res;
                    if (isSuccess) {
                        this.$message({ type: 'success', message: '删除成功!'});
                        this.getItemList(type);
                    }
                });
            }).catch(() => {});
        },

    },
    mounted() {
        this.productKey = this.$route.query.productKey;
        this.getProductInfo();      //获取产品详情
        
        // this.getOtherInfo();        //获取(0 包装 1 包装计费 2 税 3 计量单位 4 载重)


    },
};
</script>

<style lang="scss" scoped>
.title{
    height: 20px;
    line-height: 20px;
    font-size: 16px;
    font-weight: bold;
    color: #3A3A3A;
    margin-bottom: 10px;
    border-left: 3px solid var(--themeColor);
    padding-left: 5px;
}
.w100{
    width: 100%;
    height: 32px;
}
.systemItem{
    display: flex;
    font-size: 14px;
    line-height: 28px;
    .label{
        width: 80px;
        color: #9C9C9C;
        text-align: right;
    }
    .value{
        padding-left: 10px;
        color: #3A3A3A;
    }
}
/* 底部新增按钮 */
.bottomAddBtn{
    cursor: pointer;
    height: 48px;
    line-height: 48px;
    text-align: center;
    font-size: 16px;
    color: #7C90A5;
    border: 1px solid #EBEEF5;
    border-top: none;
    img{
        vertical-align: middle;
        padding-right: 10px;
    }
}


/* 图片信息样式 */
.imgStyle{
    width:150px;
    height:150px;
    margin-right:10px;
    position: relative;
    .zhutu{
        position: absolute;
        top: 0;
        left: 0;
    }
    .closeTag{
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
    }
    &:hover .closeTag{
        display: inline-block;
    }
    .setTag{
        display: none;
        position: absolute;
        top: 0;
        right: 40px;
        cursor: pointer;
    }
    &:hover .setTag{
        display: inline-block;
    }
}
</style>
