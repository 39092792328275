<template>
  <div id="trend-analyse" class="new-trend-analyse">
    <div class="title" ref="title">
      <p>趋势分析</p>
      <div style="display: flex; align-items: center">
        <el-radio-group v-model="controlForm.option" size="mini" v-show="mode === 'table'" :disabled="loading">
          <el-radio-button :label="0">频次</el-radio-button>
          <el-radio-button :label="1">金额</el-radio-button>
          <el-radio-button :label="2">数量</el-radio-button>
          <el-radio-button :label="3">重量</el-radio-button>
        </el-radio-group>
        <img class="icon" src="@/firm/images/company/icon-toggle.png" style="margin-left: 1em" @click="toggleMode" />
        <img v-show="false" class="icon" src="@/firm/images/company/icon-download.png" style="margin-left: 1em" />
        <img class="icon" src="@/firm/images/company/icon-refresh.png" style="margin-left: 1em" @click="refresh" />
      </div>
    </div>
    <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 10px">
      <div class="mode-toggle-contaner" style="margin-left: 20px">
        <div @click="changeType('0')" class="mode-item" :class="{ active: type === '0' }">月度</div>
        <div @click="changeType('1')" class="mode-item" :class="{ active: type === '1' }">季度</div>
      </div>
      <el-radio-group v-model="controlForm.option" v-show="mode === 'chart'" :disabled="loading">
        <el-radio :label="0">频次</el-radio>
        <el-radio :label="1">金额</el-radio>
        <el-radio :label="2">数量</el-radio>
        <el-radio :label="3">重量</el-radio>
      </el-radio-group>
    </div>
    <div class="content">
      <el-skeleton-item variant="rect" animated v-show="loading" style="height: 400px" />
      <div v-show="!loading">
        <div class="content-child chart" :class="{ show: mode === 'chart' }">
          <div ref="chart" style="height: 400px"></div>
        </div>
        <div class="content-child table" :class="{ show: mode === 'table' }">
          <el-table :data="tableData" height="350px" :header-cell-style="{ background: '#E5E9F2', color: '#1F2D3D' }" :row-style="tableRowStyleCallBack">
            <el-table-column prop="date" label="日期" align="center" />
            <el-table-column prop="amount" label="金额" align="center" />
            <el-table-column prop="count" label="数量" align="center" />
            <el-table-column prop="weight" label="重量" align="center" />
            <el-table-column prop="frequency" label="频次" align="center" />
          </el-table>
          <div style="margin-top: 1em; display: flex; justify-content: flex-end">
            <el-pagination background layout="total, prev, pager, next" :total="pagination.total" :page-size="pagination.pageSize" :current-page="pagination.pageIndex" @current-change="handlePageIndexChange" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import echarts from "@/firm/plugins/echarts";
import { tableRowStyleCallBack } from "@/firm/utils";
import axios from "@/firm/plugins/axios";
import { mapState, mapGetters, mapMutations } from "vuex";
import LazyOperation from "@/firm/plugins/lazy-execute";
import { AnchorInditor } from "../utils";

let chartInstance = null;
let lazyOperation = null;

const generateNextMode = (function* () {
  while (true) {
    yield "chart";
    yield "table";
  }
})();

function formatNum(data) {
  let num = (data || 0).toString(),
    result = "";
  while (num.length > 3) {
    result = "," + num.slice(-3) + result;
    num = num.slice(0, num.length - 3);
  }
  if (num) {
    result = num + result;
  }
  return result;
}

export default Vue.extend({
  data() {
    return {
      type: "0", // '0' 月度 ;  '1'  季度
      loading: true,
      mode: generateNextMode.next().value,
      controlForm: {
        option: 0,
      },
      tableData: [],
      pagination: {
        total: 0,
        pageSize: 10,
        pageIndex: 1,
      },
    };
  },
  computed: {
    ...mapState(["company", "companyType"]),
    ...mapState("controlFormGlobal", {
      ie: "ie",
      dataCountry: "dataCountry",
      dateRange: "dateRange",
      hsCode: "hsCode",
      importer: "importer",
      exporter: "exporter",
      country: "country",

      exHsCode: "exHsCode",
      exExporter: "exExporter",
      exImporter: "exImporter",
      exCountry: "exCountry",
    }),
    ...mapGetters("controlFormGlobal", {
      startTime: "startTime",
      endTime: "endTime",
    }),
  },
  watch: {
    type() {
      lazyOperation?.reobserve();
    },
    "controlForm.option"() {
      lazyOperation?.reobserve();
    },
    ie() {
      lazyOperation?.reobserve();
    },
    dataCountry() {
      lazyOperation?.reobserve();
    },
    dateRange() {
      lazyOperation?.reobserve();
    },
    hsCode() {
      lazyOperation?.reobserve();
    },
    importer() {
      lazyOperation?.reobserve();
    },
    exporter() {
      lazyOperation?.reobserve();
    },
    country() {
      lazyOperation?.reobserve();
    },

    exHsCode() {
      lazyOperation?.reobserve();
    },
    exExporter() {
      lazyOperation?.reobserve();
    },
    exImporter() {
      lazyOperation?.reobserve();
    },
    exCountry() {
      lazyOperation?.reobserve();
    },

    companyType() {
      lazyOperation?.reobserve();
    },
  },
  methods: {
    ...mapMutations("anchor", {
      setCurrent: "setCurrent",
    }),
    changeType(typeCode) {
      this.type = typeCode;
    },
    toggleMode() {
      this.mode = generateNextMode.next().value;
    },
    refresh() {
      lazyOperation?.reobserve();
    },
    reset() {
      this.tableData = [];
      chartInstance?.setOption({
        xAxis: [
          {
            data: [],
          },
        ],
        series: [
          {
            name: "金额",
            data: [],
          },
          {
            name: "数量",
            data: [],
          },
          {
            name: "重量",
            data: [],
          },
          {
            name: "频次",
            data: [],
          },
        ],
      });
    },
    tableRowStyleCallBack,
    //分页器页码改变时触发
    async handlePageIndexChange(pageIndex) {
      this.loading = true;
      await this.getTableData(pageIndex);
      this.loading = false;
    },
    async getTableData(pageIndex = 1) {
      try {
        this.loading = true;
        const res = await axios({
          method: "post",
          url: this.type === "0" ? "/search/Credit/Trend" : "/search/Credit/TrendQuarter",
          data: {
            pageIndex,
            pageSize: this.pagination.pageSize,
            ie: this.ie,
            importer: this.importer,
            exporter: this.exporter,
            dataCountry: this.dataCountry,
            country: this.country,
            startTime: this.startTime, //"2019-01-01", //this.startTime,
            endTime: this.endTime, //dayjs().format("YYYY-MM-DD"), //this.endTime,
            hsCode: this.hsCode,
            sortType: this.controlForm.option,
            noHsCode: this.exHsCode,
            noExporter: this.exExporter,
            noImporter: this.exImporter,
            noCountry: this.exCountry,
            company: this.company,
            companyType: this.companyType,
          },
        });
        const tableData = res.data.result.data;
        const total = res.data.result.total;
        this.tableData = tableData;
        this.pagination.total = total;
        this.pagination.pageIndex = pageIndex;
        return Promise.resolve("");
      } catch {
        return Promise.reject("");
      }
    },
    async refreshChartInstance() {
      try {
        chartInstance = echarts.init(this.$refs["chart"]);
        chartInstance?.setOption({
          grid: {
            containLabel: true,
            left: 25,
            right: 25,
          },
          tooltip: {
            show: true,
            trigger: "axis",
          },
          yAxis: [
            {
              type: "value",
              axisTick: {
                show: false,
              },
            },
          ],
          xAxis: [
            {
              type: "category",
              axisTick: {
                alignWithLabel: true,
              },
              data: [],
            },
          ],
          series: [
            {
              color: "#FABD7F",
              name: "金额",
              type: "line",
              areaStyle: {},
              data: [],
              smooth: true,
              label: {
                show: true,
                position: "top",
                color: "#858585",
                formatter(param) {
                  return formatNum(param.data);
                },
              },
            },
            {
              color: "#FABD7F",
              name: "数量",
              type: "line",
              areaStyle: {},
              data: [],
              smooth: true,
              label: {
                show: true,
                position: "top",
                color: "#858585",
                formatter(param) {
                  return formatNum(param.data);
                },
              },
            },
            {
              color: "#FABD7F",
              name: "重量",
              type: "line",
              areaStyle: {},
              data: [],
              smooth: true,
              label: {
                show: true,
                position: "top",
                color: "#858585",
                formatter(param) {
                  return formatNum(param.data);
                },
              },
            },
            {
              color: "#FABD7F",
              name: "频次",
              type: "line",
              areaStyle: {},
              data: [],
              smooth: true,
              label: {
                show: true,
                position: "top",
                color: "#858585",
                formatter(param) {
                  return formatNum(param.data);
                },
              },
            },
          ],
        });
        const res = await axios({
          method: "post",
          url: this.type === "0" ? "/search/Credit/Trend" : "/search/Credit/TrendQuarter",
          data: {
            pageIndex: 1,
            pageSize: this.pagination.pageSize,
            ie: this.ie,
            importer: this.importer,
            exporter: this.exporter,
            dataCountry: this.dataCountry,
            country: this.country,
            startTime: this.startTime, //"2019-01-01", //this.startTime,
            endTime: this.endTime, //dayjs().format("YYYY-MM-DD"), //this.endTime,
            hsCode: this.hsCode,
            sortType: this.controlForm.option,
            noHsCode: this.exHsCode,
            noExporter: this.exExporter,
            noImporter: this.exImporter,
            noCountry: this.exCountry,
            company: this.company,
            companyType: this.companyType,
          },
        });
        const chartData = res.data.result.data.reverse();
        const xaxisData = [];
        /* const frequencyData = [] as number[];
        const amountData = [] as number[];
        const countData = [] as number[];
        const weightData = [] as number[]; */
        const serdata = [];
        const map = [
          ["频次", "frequency"],
          ["金额", "amount"],
          ["数量", "count"],
          ["重量", "weight"],
        ];
        chartData.forEach((item) => {
          xaxisData.push(item.date);
          serdata.push(item[map[this.controlForm.option][1]]);
        });
        chartInstance?.setOption({
          xAxis: [
            {
              data: xaxisData,
            },
          ],
          series: [
            {
              name: map[this.controlForm.option][0],
              data: serdata,
            },
          ],
        });
        /* const seriesData = [
          ["频次", "frequencyData"],
          ["金额", "amountData"],
          ["数量", "countData"],
          ["重量", "weightData"],
        ].map((item, index) => {
          if (index === this.controlForm.option) {
            return {
              name: item[0],
              data: chartData[item[1]].reverse(),
            };
          } else {
            return {
              name: item[0],
              data: [],
            };
          }
        });

        chartInstance?.setOption({
          xAxis: [
            {
              data: chartData.xaxisData.reverse(),
            },
          ],
          series: seriesData,
        }); */
        return Promise.resolve("");
      } catch {
        return Promise.reject("");
      }
    },
  },
  mounted() {
    lazyOperation = new LazyOperation(() => {
      Promise.all([this.reset(), this.refreshChartInstance(), this.getTableData()])
        .then(() => {
          setTimeout(() => {
            chartInstance?.resize();
          });
        })
        .finally(() => (this.loading = false));
    }, this.$el);
    new AnchorInditor(() => {
      this.setCurrent("QSFX");
    }, this.$refs["title"]);
  },
});
</script>

<style lang="scss" scoped>
@import "@/firm/scss/mixin.scss";

.new-trend-analyse {
  margin-top: 1rem;
  background: #fff;
  padding: 1rem;
  padding-top: 0;
  @include mixin-panel-title;
  .content {
    $contentHeight: 400px;
    position: relative;
    height: $contentHeight;
    .content-child {
      position: absolute;
      width: 100%;
      height: 100%;
      background: #fff;
      z-index: -1;
      &.show {
        z-index: 10;
      }
    }
  }
}
.mode-toggle-contaner {
  display: flex;
  background: #f6f6f6;
  font-size: 16px;
  padding: 2px;
  border-radius: 4px;
  .mode-item {
    border-radius: 4px;
    color: #595959;
    padding: 5px 8px;
    cursor: pointer;

    &.active {
      background: var(--themeColor, #3152AF);
      color: #fff;
    }
  }
}
</style>
