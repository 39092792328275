<template>
    <div>
        <div style="height: 60px; padding-left: 10px; display: flex; align-items: center; font-size: 14px; color: #707070">
            <span style="flex-shrink: 0">日期：</span>
            <el-date-picker type="daterange" @change="dateChange" v-model="form.date" value-format="yyyy-MM-dd" size="small" unlink-panels />
        </div>
        <div class="grid-container" style="height: calc(100% - 60px)">
            <div class="grid-item-panel" v-loading="xiaoshoupaihang.loading">
                <div class="grid-item-select">
                    <div @click="setType('xiaoshoupaihang', '1')" class="grid-item-select-option" :class="{ active: xiaoshoupaihang.type === '1' }">产品</div>
                    <div @click="setType('xiaoshoupaihang', '2')" class="grid-item-select-option" :class="{ active: xiaoshoupaihang.type === '2' }">产品型号</div>
                    <div @click="setType('xiaoshoupaihang', '3')" class="grid-item-select-option" :class="{ active: xiaoshoupaihang.type === '3' }">产品类目</div>
                    <div @click="setType('xiaoshoupaihang', '4')" class="grid-item-select-option" :class="{ active: xiaoshoupaihang.type === '4' }">产品分组</div>
                </div>
                <div class="grid-item-panel-title">
                    <div style="display: flex; align-items: flex-end">
                        <span class="grid-item-panel-title-text">产品销售排行</span>
                        <span class="grid-item-panel-title-sub-text" style="margin-left: 10px">{{ xiaoshoupaihang.date }}</span>
                    </div>
                    <div class="icon-container">
                        <img src="@/assets/icon/refresh.png" />
                        <img src="@/assets/icon/close.png" />
                    </div>
                </div>
                <div ref="xiaoshoupaihang-dom" class="grid-item-panel-chart-dom"></div>
            </div>
            <div class="grid-item-panel" v-loading="xiaoshoukehupaihang.loading">
                <div class="grid-item-select">
                    <div @click="setType('xiaoshoukehupaihang', '1')" class="grid-item-select-option" :class="{ active: xiaoshoukehupaihang.type === '1' }">客户</div>
                    <div @click="setType('xiaoshoukehupaihang', '2')" class="grid-item-select-option" :class="{ active: xiaoshoukehupaihang.type === '2' }">来源</div>
                    <div @click="setType('xiaoshoukehupaihang', '3')" class="grid-item-select-option" :class="{ active: xiaoshoukehupaihang.type === '3' }">星级</div>
                    <div @click="setType('xiaoshoukehupaihang', '4')" class="grid-item-select-option" :class="{ active: xiaoshoukehupaihang.type === '4' }">分组</div>
                    <div @click="setType('xiaoshoukehupaihang', '5')" class="grid-item-select-option" :class="{ active: xiaoshoukehupaihang.type === '5' }">地区</div>
                </div>
                <div class="grid-item-panel-title">
                    <div style="display: flex; align-items: flex-end">
                        <span class="grid-item-panel-title-text">产品销售客户排行</span>
                        <span class="grid-item-panel-title-sub-text" style="margin-left: 10px">{{ xiaoshoukehupaihang.date }}</span>
                    </div>
                    <div class="icon-container">
                        <img src="@/assets/icon/refresh.png" />
                        <img src="@/assets/icon/close.png" />
                    </div>
                </div>
                <div class="grid-item-panel-chart-dom" style="padding: 0 90px 0 110px">
                    <el-table class="custom-el-table" :data="xiaoshoukehupaihang.tableData" :show-header="false" height="calc(100% - 20px)">
                        <el-table-column width="50px" align="center">
                            <template slot-scope="{ row }">
                                <img v-if="row.rankSrc" :src="row.rankSrc" />
                                <span v-else>{{ row.rank }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="name" />
                        <el-table-column align="right">
                            <template slot-scope="{ row }">
                                <span>{{ row.amount }}</span>
                                <span style="color: #7c90a5; margin-left: 9px">万元</span>
                            </template>
                        </el-table-column>
                        <el-table-column width="40px" align="center">
                            <template slot-scope="{ row }">
                                <span v-if="row.status === '-'">{{ row.status }}</span>
                                <img v-else :src="row.status" />
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <div class="grid-item-panel" v-loading="xiaoshouyewuyuanpaihang.loading">
                <div class="grid-item-panel-title">
                    <div style="display: flex; align-items: flex-end">
                        <span class="grid-item-panel-title-text">产品销售业务员排行</span>
                        <span class="grid-item-panel-title-sub-text" style="margin-left: 10px">{{ xiaoshouyewuyuanpaihang.date }}</span>
                    </div>
                    <div class="icon-container">
                        <img src="@/assets/icon/refresh.png" />
                        <img src="@/assets/icon/close.png" />
                    </div>
                </div>
                <div class="grid-item-panel-chart-dom" style="padding: 0 90px 0 30px">
                    <el-table class="custom-el-table" :data="xiaoshouyewuyuanpaihang.tableData" :show-header="false" height="calc(100% - 20px)">
                        <el-table-column width="50px" align="center">
                            <template slot-scope="{ row }">
                                <img v-if="row.rankSrc" :src="row.rankSrc" />
                                <span v-else>{{ row.rank }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="name" />
                        <el-table-column align="right">
                            <template slot-scope="{ row }">
                                <span>{{ row.amount }}</span>
                                <span style="color: #7c90a5; margin-left: 9px">万元</span>
                            </template>
                        </el-table-column>
                        <el-table-column width="40px" align="center">
                            <template slot-scope="{ row }">
                                <span v-if="row.status === '-'">{{ row.status }}</span>
                                <img v-else :src="row.status" />
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <!-- <div  class="grid-item-panel">
        <div class="grid-item-select">
          <div class="grid-item-select-option">销售金额</div>
          <div class="grid-item-select-option active">销量</div>
          <div class="grid-item-select-option">均单价</div>
        </div>
        <div class="grid-item-panel-title">
          <div style="display: flex; align-items: flex-end">
            <span class="grid-item-panel-title-text">产品销售趋势</span>
            <span class="grid-item-panel-title-sub-text" style="margin-left: 10px">2020-07-01 —— 2021-01-01</span>
          </div>
          <div class="icon-container">
            <img src="@/assets/icon/refresh.png" />
            <img src="@/assets/icon/close.png" />
          </div>
        </div>
        <div ref="xiaoshouqushi-dom" class="grid-item-panel-chart-dom"></div>
      </div> -->
        </div>
    </div>
</template>

<script>
import * as echarts from "echarts";
import dayjs from "dayjs";
const medals = [
    require("@/assets/icon/medal-1.png"),
    require("@/assets/icon/medal-2.png"),
    require("@/assets/icon/medal-3.png"),
];
const arrow = [
    "-",
    require("@/assets/icon/arrow-increase.png"),
    require("@/assets/icon/arrow-decrease.png"),
];

export default {
    chartInstance: {
        xiaoshoupaihang: null,
        xiaoshouqushi: null,
    },
    data() {
        const date = [
            dayjs().subtract(90, "days").format("YYYY-MM-DD"),
            dayjs().format("YYYY-MM-DD"),
        ];
        return {
            form: {
                range: "",
                date,
            },
            xiaoshoupaihang: {
                loading: false,
                date: date.join(" —— "),
                type: "1",
            },
            xiaoshoukehupaihang: {
                loading: false,
                date: date.join(" —— "),
                type: "1",
                tableData: [
                    /* {
            key: "dafergqwge",
            rank: 1,
            rankSrc: medals[0],
            name: "杜苗",
            amount: "102.12",
            status: arrow[1],
          }, */
                ],
            },
            xiaoshouyewuyuanpaihang: {
                loading: false,
                date: date.join(" —— "),
                tableData: [],
            },
        };
    },
    watch: {
        "xiaoshoupaihang.type"() {
            this.getXiaoshoupaihang();
        },
        "xiaoshoukehupaihang.type"() {
            this.getXiaoshoukehupaihang();
        },
    },
    methods: {
        setType(key, value) {
            this[key].type = value;
        },
        async getXiaoshoupaihang() {
            const [startDate, endDate] = this.form.date || [];
            const { type } = this.xiaoshoupaihang;
            if (!(startDate && endDate && type)) {
                return;
            }
            try {
                this.xiaoshoupaihang.loading = true;
                const res = await this.$axios({
                    method: "GET",
                    url:
                        this.$customerUrl +
                        "/Report/CustomerDistributionProduct",
                    params: {
                        type,
                        startDate,
                        endDate,
                    },
                });
                const data = res.data;
                this.$options.chartInstance.xiaoshoupaihang.setOption({
                    series: [
                        {
                            name: "产品销售排行",
                            data,
                        },
                    ],
                });
                this.xiaoshoupaihang.date = this.form.date.join(" —— ");
            } catch {
                null;
            } finally {
                this.xiaoshoupaihang.loading = false;
            }
        },
        async getXiaoshoukehupaihang() {
            const [startDate, endDate] = this.form.date || [];
            const { type } = this.xiaoshoukehupaihang;
            if (!(startDate && endDate && type)) {
                return;
            }
            try {
                this.xiaoshoukehupaihang.loading = true;
                const res = await this.$axios({
                    method: "GET",
                    url: this.$customerUrl + "/Report/CustomerRank",
                    params: {
                        type,
                        startDate,
                        endDate,
                    },
                });
                const data = res.data;
                this.xiaoshoukehupaihang.tableData = data.map((item) => ({
                    key: item.key,
                    rank: item.rank,
                    rankSrc: medals[+item.rank - 1],
                    name: item.name,
                    amount: item.amount,
                    status: arrow[+item.status],
                }));
                this.xiaoshoukehupaihang.date = this.form.date.join(" —— ");
            } catch {
                null;
            } finally {
                this.xiaoshoukehupaihang.loading = false;
            }
        },
        async getXiaoshouyewuyuanpaihang() {
            const [startDate, endDate] = this.form.date || [];
            if (!(startDate && endDate)) {
                return;
            }
            try {
                this.xiaoshouyewuyuanpaihang.loading = true;
                const res = await this.$axios({
                    method: "GET",
                    url: this.$customerUrl + "/Report/SaleRank",
                    params: {
                        startDate,
                        endDate,
                    },
                });
                const data = res.data;
                this.xiaoshouyewuyuanpaihang.tableData = data.map((item) => ({
                    key: item.key,
                    rank: item.rank,
                    rankSrc: medals[+item.rank - 1],
                    name: item.name,
                    amount: item.amount,
                    status: arrow[+item.status],
                }));
                this.xiaoshouyewuyuanpaihang.date = this.form.date.join(" —— ");
            } catch {
                null;
            } finally {
                this.xiaoshouyewuyuanpaihang.loading = false;
            }
        },

        dateChange(val) {
            if (val) {
                this.getXiaoshoupaihang();
                this.getXiaoshoukehupaihang();
                this.getXiaoshouyewuyuanpaihang();
                localStorage.setItem("timeRange", val);
            }
        },
    },
    mounted() {
        //获取上次选择的时间段
        if (localStorage.timeRange) {
            this.form.date = localStorage.timeRange.split(",");
        }

        this.$options.chartInstance.xiaoshoupaihang = echarts.init(
            this.$refs["xiaoshoupaihang-dom"]
        );
        this.$options.chartInstance.xiaoshoupaihang.setOption({
            legend: {
                top: "middle",
                type: "scroll",
                orient: "vertical",
                right: "5%",
                icon: "circle",
                textStyle: {
                    color: "#7C90A5",
                },
                itemWidth: 14,
                itemHeight: 14,
            },
            tooltip: {},
            series: [
                {
                    name: "产品销售排行",
                    type: "pie",
                    radius: ["30%", "70%"],
                    center: ["40%", "50%"],
                    label: {
                        show: true,
                        color: "#94979C",
                    },
                    labelLine: {
                        smooth: true,
                        lineStyle: {
                            color: "#94979C",
                        },
                    },
                    emphasis: {
                        scale: false,
                    },
                    data: [
                        { name: "Africa", value: 100 },
                        { name: "Europe", value: 200 },
                        { name: "South America", value: 300 },
                        { name: "Oceania", value: 400 },
                        { name: "North America", value: 500 },
                        { name: "未分类", value: 600 },
                        { name: "Asia", value: 700 },
                    ],
                },
            ],
            color: [
                "#62D0D3",
                "#67A0C8",
                "#9BC586",
                "#DFD982",
                "#DC7683",
                "#8897C0",
                "#2560A3",
            ],
        });

        // this.$options.chartInstance.xiaoshouqushi = echarts.init(this.$refs["xiaoshouqushi-dom"]);
        // this.$options.chartInstance.xiaoshouqushi.setOption({
        //   legend: {
        //     top: 5,
        //     right: 30,
        //     icon: "circle",
        //     itemWidth: 8,
        //     itemHeight: 8,
        //     itemStyle: {
        //       opacity: 1,
        //     },
        //     itemGap: 20,
        //   },
        //   grid: {
        //     containLabel: true,
        //     top: 40,
        //     left: 115,
        //     right: 30,
        //     bottom: 20,
        //   },
        //   xAxis: {
        //     axisLine: {
        //       lineStyle: {
        //         color: "#BFBFBF",
        //       },
        //     },
        //     axisTick: {
        //       alignWithLabel: true,
        //     },
        //     axisLabel: {
        //       color: "#595959",
        //     },
        //     data: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
        //   },
        //   yAxis: {
        //     axisLabel: {
        //       color: "#595959",
        //     },
        //     splitLine: {
        //       lineStyle: {
        //         color: "#E7E7E7",
        //         type: "dashed",
        //       },
        //     },
        //   },
        //   tooltip: {
        //     trigger: "axis",
        //   },
        //   series: [
        //     {
        //       name: "2019",
        //       type: "line",
        //       itemStyle: {
        //         opacity: 0,
        //       },
        //       data: [100, 120, 110, 200, 200],
        //     },
        //     {
        //       name: "2020",
        //       type: "line",
        //       itemStyle: {
        //         opacity: 0,
        //       },
        //       data: [110, 130, 100, 200, 220],
        //     },
        //     {
        //       name: "2021",
        //       type: "line",
        //       itemStyle: {
        //         opacity: 0,
        //       },
        //       data: [210, 230, 300, 500, 320],
        //     },
        //   ],
        //   color: ["#2E7DB5", "#79D6D9", "#D65F6E"],
        // });

        this.getXiaoshoupaihang();
        this.getXiaoshoukehupaihang();
        this.getXiaoshouyewuyuanpaihang();
    },
};
</script>

<style lang="scss" scoped>
.grid-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: -20px;
}
.grid-item-panel {
    width: calc(50% - 10px);
    margin-top: 20px;
    position: relative;
    height: 380px;
    background: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}
$grid-item-panel-title-height: 42px;
.grid-item-panel-title {
    box-sizing: border-box;
    height: $grid-item-panel-title-height;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 14px 0 20px;
    &.bb {
        border-bottom: 1px solid #e6e7eb;
    }
}
.grid-item-panel-title-text {
    font-size: 18px;
    color: #1b2841;
    font-weight: bold;
}
.grid-item-panel-title-sub-text {
    font-size: 14px;
    color: #c7c7c7;
}
.icon-container {
    display: flex;
    align-items: center;
    img {
        cursor: pointer;
        &:last-child {
            margin-left: 10px;
        }
    }
}
.grid-item-select {
    position: absolute;
    z-index: 2;
    left: 20px;
    top: $grid-item-panel-title-height;
    border: 1px solid #c7c7c7;
    border-radius: 2px;
    width: 80px;
    font-size: 14px;
    color: #7c90a5;
    cursor: pointer;
    background: #fff;
}
.grid-item-select-option {
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover,
    &.active {
        background: var(--themeColor);
        color: #fff;
    }
}
.grid-item-panel-chart-dom {
    box-sizing: border-box;
    height: calc(100% - #{$grid-item-panel-title-height});
}
.custom-el-table {
    &.el-table::before {
        height: 0 !important;
        background: none !important;
    }
    font-size: 14px;
    ::v-deep .el-table__cell {
        height: 25px;
        padding: 0;
        border: none;
    }
    ::v-deep tbody {
        .el-table__cell {
            color: #3f404a;
            font-weight: normal;
        }
        .cell {
            padding: 0;
        }
        tr:hover {
            td {
                background: #e3eaef !important;
            }
        }
    }
}
</style>