<template>
  <div id="port-domestic" class="port-domestic" v-loading="loading" v-show="!(tableData.length === 0 && hidden)">
    <div class="title" ref="title">
      <div class="text">
        <p>国内起运港分析</p>
      </div>
      <div style="display: flex; align-items: center">
        <el-radio-group v-model="controlForm.option" size="mini">
          <el-radio-button :label="0">频次</el-radio-button>
          <el-radio-button :label="1">金额</el-radio-button>
          <el-radio-button :label="2">数量</el-radio-button>
          <el-radio-button :label="3">重量</el-radio-button>
        </el-radio-group>
        <img v-show="false" class="icon" src="@/firm/images/company/icon-download.png" style="margin-left: 1em" />
      </div>
    </div>
    <div class="content">
      <div ref="chart" style="height: 400px"></div>
      <div>
        <el-table :data="tableData" :header-cell-style="{ background: '#E5E9F2', color: '#1F2D3D' }" :row-style="tableRowStyleCallBack">
          <el-table-column prop="order" align="center" width="50px" />
          <el-table-column prop="port" label="港口" />
          <el-table-column prop="frequencyStr" label="频次" width="120px" show-overflow-tooltip />
          <el-table-column prop="amountStr" label="金额" width="120px" show-overflow-tooltip />
          <el-table-column prop="countStr" label="数量" width="120px" show-overflow-tooltip />
          <el-table-column prop="weightStr" label="重量" width="120px" show-overflow-tooltip />
        </el-table>
        <div style="margin-top: 1em; display: flex; justify-content: flex-end">
          <el-pagination background layout="total, prev, pager, next" :total="pagination.total" :page-size="pagination.pageSize" :current-page="pagination.pageIndex" @current-change="handlePageIndexChange" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import echarts from "@/firm/plugins/echarts";
import { tableRowStyleCallBack } from "@/firm/utils";
import axios from "@/firm/plugins/axios";
import { mapState, mapGetters, mapMutations } from "vuex";
import LazyOperation from "@/firm/plugins/lazy-execute";
import { AnchorInditor } from "../utils";

let chartInstance = null;
let lazyOperation = null;

export default Vue.extend({
  data() {
    return {
      hidden: false,
      loading: false,
      controlForm: {
        option: 0,
      },
      colors: ["#FDF5C3", "#F9DE9A", "#FFC183", "#E4A958", "#ED975B", "#ED7F36", "#E87F69", "#D66A5E", "#CC5E39", "#B36251"].reverse(),
      tableData: [],
      pagination: {
        total: 0,
        pageSize: 10,
        pageIndex: 1,
      },
    };
  },
  computed: {
    ...mapState(["company", "companyType"]),
    ...mapState("controlFormGlobal", {
      ie: "ie",
      dataCountry: "dataCountry",
      dateRange: "dateRange",
      hsCode: "hsCode",
      importer: "importer",
      exporter: "exporter",
      country: "country",

      exHsCode: "exHsCode",
      exExporter: "exExporter",
      exImporter: "exImporter",
      exCountry: "exCountry",
    }),
    ...mapGetters("controlFormGlobal", {
      startTime: "startTime",
      endTime: "endTime",
    }),
  },
  watch: {
    "controlForm.option"() {
      lazyOperation?.reobserve();
    },
    ie() {
      this.hidden = false;
      this.setAnchorFlag([["GNGK", true]]);
      lazyOperation?.reobserve();
    },
    dataCountry() {
      this.hidden = false;
      this.setAnchorFlag([["GNGK", true]]);
      lazyOperation?.reobserve();
    },
    dateRange() {
      this.hidden = false;
      this.setAnchorFlag([["GNGK", true]]);
      lazyOperation?.reobserve();
    },
    hsCode() {
      this.hidden = false;
      this.setAnchorFlag([["GNGK", true]]);
      lazyOperation?.reobserve();
    },
    importer() {
      this.hidden = false;
      this.setAnchorFlag([["GNGK", true]]);
      lazyOperation?.reobserve();
    },
    exporter() {
      this.hidden = false;
      this.setAnchorFlag([["GNGK", true]]);
      lazyOperation?.reobserve();
    },
    country() {
      this.hidden = false;
      this.setAnchorFlag([["GNGK", true]]);
      lazyOperation?.reobserve();
    },

    exHsCode() {
      this.hidden = false;
      this.setAnchorFlag([["GNGK", true]]);
      lazyOperation?.reobserve();
    },
    exExporter() {
      this.hidden = false;
      this.setAnchorFlag([["GNGK", true]]);
      lazyOperation?.reobserve();
    },
    exImporter() {
      this.hidden = false;
      this.setAnchorFlag([["GNGK", true]]);
      lazyOperation?.reobserve();
    },
    exCountry() {
      this.hidden = false;
      this.setAnchorFlag([["GNGK", true]]);
      lazyOperation?.reobserve();
    },

    companyType() {
      this.hidden = false;
      this.setAnchorFlag([["GNGK", true]]);
      lazyOperation?.reobserve();
    },
  },
  methods: {
    ...mapMutations("anchor", {
      setAnchorFlag: "setAnchorFlag",
      setCurrent: "setCurrent",
    }),
    tableRowStyleCallBack,
    handlePageIndexChange(pageIndex) {
      this.getTableData(pageIndex);
    },
    async getTableData(pageIndex = 1) {
      try {
        this.loading = true;
        const res = await axios({
          method: "post",
          url: "/search/Credit/Ranking/DomesticPort",
          data: {
            pageIndex,
            pageSize: this.pagination.pageSize,
            ie: this.ie,
            importer: this.importer,
            exporter: this.exporter,
            dataCountry: this.dataCountry,
            country: this.country,
            startTime: this.startTime,
            endTime: this.endTime,
            hsCode: this.hsCode,
            sortType: this.controlForm.option,
            noHsCode: this.exHsCode,
            noExporter: this.exExporter,
            noImporter: this.exImporter,
            noCountry: this.exCountry,
            company: this.company,
            companyType: this.companyType,
          },
        });
        const data = res.data.result;
        //console.log(data);
        this.tableData = data.data;
        this.pagination.total = data.total;
        this.pagination.pageIndex = data.pageIndex;
        if (this.tableData.length === 0) {
          throw new Error();
        }
        this.$nextTick(() => {
          this.refreshChartInstance();
        });
        this.setAnchorFlag([["GNGK", true]]);
      } catch {
        this.tableData = [];
        this.hidden = true;
        this.setAnchorFlag([["GNGK", false]]);
      } finally {
        this.loading = false;
      }
      /* this.tableData = [
        {
          order: 1, //排名
          port: "vsvrberwgv", //港口
          frequencyStr: "1231", //频次
          frequency: 1231, //频次
          amountStr: "431452", //金额
          amount: 431452, //金额
          countStr: "4343", //数量
          count: 4343, //数量
          weightStr: "4325425", //重量
          weight: 4325425, //重量
        },
        {
          order: 2,
          port: "vsvccdvvsdvrberwgv",
          frequencyStr: "1231",
          frequency: 1641,
          amountStr: "431452",
          amount: 445623,
          countStr: "4343",
          count: 4785,
          weightStr: "4325425",
          weight: 4345235,
        },
      ] as any; */
    },
    refreshChartInstance() {
      chartInstance = echarts.init(this.$refs["chart"]);
      const key = ["frequency", "amount", "count", "weight"][this.controlForm.option];
      // eslint-disable-next-line
      const _this = this;
      chartInstance.setOption({
        tooltip: {
          show: true,
          trigger: "item",
          formatter(param) {
            if (param.data) {
              return `
                港口：${param.data.name}<br />
                ${["频次", "金额", "数量", "重量"][_this.controlForm.option]}：${param.data.value}
              `;
            } else {
              return "";
            }
          },
        },
        series: [
          {
            name: "国内目的港",
            type: "pie",
            roseType: "radius",
            label: {
              formatter: function (param) {
                const data = param.data;
                if (data) {
                  return `
                  ${param.data.name}
                  ${param.data.value}(${param.percent}%)
                `;
                } else {
                  return "";
                }
              },
            },
            data: this.tableData.slice(0, 10).map((item) => ({
              name: item.port,
              value: item[key],
            })),
          },
        ],
        color: _this.colors,
      });
      chartInstance.resize();
    },
  },
  mounted() {
    //this.getTableData();
    lazyOperation = new LazyOperation(() => {
      this.getTableData();
    }, this.$el);
    new AnchorInditor(() => {
      this.setCurrent("GNGK");
    }, this.$refs["title"]);
  },
});
</script>

<style lang="scss" scoped>
@import "@/firm/scss/mixin.scss";

.port-domestic {
  margin-top: 1rem;
  background: #fff;
  padding: 1rem;
  padding-top: 0;
  @include mixin-panel-title;
}
</style>
