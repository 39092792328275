<template>
    <div style="position: relative;">
        <div class="zuobiao">
            ({{coordinate[0]}},{{coordinate[1]}})
        </div>
        <div id="mapboxshow" style="height:350px;width:100%;"></div>
    </div>
</template>

<script>
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
export default {
    props: ["coordinate"],
    data() {
        return {

        };
    },
    methods: {
        init() {
            // var coordinate = [114.31667,30.51667];
            var coordinate = this.coordinate;

            mapboxgl.accessToken ="pk.eyJ1IjoiZ2d5eS0xOTk4IiwiYSI6ImNrdGkxZDlvYTA3eGIydG1zcHJhNHM0ZGoifQ.js1d4eYpNBlvIvgh07mtXw";

            const map = new mapboxgl.Map({
                container: "mapboxshow",
                style: "mapbox://styles/mapbox/streets-v10",
                center: coordinate,
                zoom: 10,
            });

            new mapboxgl.Marker({
                color: "red",
                draggable: false,
            }).setLngLat(coordinate).addTo(map);

        },
    },
    mounted() {
        this.init();

    },
};
</script>


<style lang="scss" scoped>
.zuobiao{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    width: 200px;
    padding: 0 10px;
    height: 32px;
    line-height: 32px;
    color: var(--themeColor);
    font-weight: bold;
}
#mapboxshow{
    // 隐藏mapbox商标
    /deep/ .mapboxgl-ctrl-logo {
        display: none;
    }
}
</style>

