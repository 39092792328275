<template>
<div>
    <div v-if="ie=='i'">
        <!-- 埃塞俄比亚(ETH)进口提单明细 -->
        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">其他信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">来源国</th>
                        <td width="25%">{{billInfo.Country}}</td>
                        <th width="25%">中转国</th>
                        <td width="25%">{{billInfo.SaleCountry}}</td>
                    </tr>
                    <tr>
                        <th>数量</th>
                        <td>{{billInfo.Quantity}}</td>
                        <th>数量单位</th>
                        <td>{{billInfo.QuantityUnit}}</td>
                    </tr>
                    <tr>
                        <th>净重</th>
                        <td>{{billInfo.NetWeight ? billInfo.NetWeight+'KG' : ''}}</td>
                        <th>毛重</th>
                        <td>{{billInfo.Weight? billInfo.Weight+'KG' : ''}}</td>
                    </tr>
                    <tr>
                        <th>总税金额</th>
                        <td>{{billInfo.TotalTax ? billInfo.TotalTax+'ETB' : ''}}</td>
                        <th>Cif金额</th>
                        <td>{{billInfo.Value ? billInfo.Value+'ETB' : ''}}</td>
                    </tr>
                    <tr>
                        <th>Cpc</th>
                        <td colspan="3">{{billInfo.Cpc}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
    <div v-if="ie=='e'">
        <!-- 埃塞俄比亚(ETH)出口提单明细 -->
        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">其他信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">目的国</th>
                        <td width="25%">{{billInfo.Country}}</td>
                        <th width="25%">关区代码</th>
                        <td width="25%">{{billInfo.Office}}</td>
                    </tr>
                    <tr>
                        <th>数量</th>
                        <td>{{billInfo.Quantity}}</td>
                        <th>数量单位</th>
                        <td>{{billInfo.QuantityUnit}}</td>
                    </tr>
                    <tr>
                        <th>净重</th>
                        <td>{{billInfo.NetWeight}}</td>
                        <th>毛重</th>
                        <td>{{billInfo.Weight}}</td>
                    </tr>
                    <tr>
                        <th>FOB金额(美金)</th>
                        <td>{{billInfo.Value}}</td>
                        <th>FOB金额(比尔币)</th>
                        <td>{{billInfo.ValueOfBirr}}</td>
                    </tr>
                    <tr>
                        <th>商品描述</th>
                        <td colspan="3">{{billInfo.GoodsDiscription}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
</div>
</template>

<script>
export default {
    props:["billInfo","ie"],
    data() {
        return {

        };
    },
    methods: {
        
    },
    mounted() {

    },
};
</script>

<style lang="scss" scoped>
.partItem {
    margin-bottom: 20px;
    /deep/.el-card__header {
        padding: 10px 20px;
        .headTitle {
            display: inline-block;
            padding-left: 10px;
            font-size: 14px;
            border-left: 2px solid var(--themeColor);
            font-weight: bold;
        }
    }
}
table {
    width: 100%;
    border-collapse: collapse;
    tr {
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;
        th,
        td {
            font-size: 12px;
            color: #666;
            height: 40px;
            border: 1px solid #eaeaea;
            text-align: center;
        }
        th {
            background-color: #f5f7fa;
            font-weight: 700;
        }
        .bg {
            background-color: var(--themeColor);
            color: #fff;
            font-weight: 700;
            opacity: 0.8;
        }
    }
}
</style>