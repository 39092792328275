<script lang="js">
import Vue from 'vue'
import axios from "@/firm/plugins/axios";
import { mapState, mapMutations } from "vuex";
import { AnchorInditor } from '../../utils'
import { tableRowStyleCallBack } from "@/firm/utils";
export default Vue.extend({
  data() {
    return {
      hidden: false,
      loading: false,
      data: {
        total: 0,
        list: [],
        page: {
          size: 10,
          index: 1
        }
      }
    }
  },
  computed: {
    ...mapState(["company"]),
  },
  methods: {
    ...mapMutations("anchor", {
      setCurrent: "setCurrent",
      setAnchorFlag: "setAnchorFlag"
    }),
    tableRowStyleCallBack,
    async getData(pageIndex = 1) {
      try {
        this.loading = true
        const res = await axios({
          method: 'POST',
          url: Vue.prototype.$searchUrl + '/Trade/Ranking/HsCodeCN',
          data: {
            keyDoc: this.company,
            threeEnCountryCode: this.$route.query.countryCode,
            pageIndex: pageIndex,
            pageSize: this.data.page.size
          }
        })
        this.data.list = res.data.result.rankings
        this.data.total = res.data.result.total
        this.data.page.index = pageIndex
      } catch {
        this.setAnchorFlag([["BMFX1144", false]]);
        this.hidden = true
      } finally {
        this.loading = false
      }
    }
  },
  mounted() {
    this.getData()
    new AnchorInditor(() => {
      this.setCurrent("BMFX1144");
    }, this.$refs["title"]);
    this.setAnchorFlag([["BMFX1144", true]]);


    //this.setAnchorFlag([["BMFX1144", false]]);
    //this.hidden = true
  }
})
</script>

<template>
  <div v-show="!hidden" v-loading="loading">
    <div id="code-analyse" class="code-analyse">
      <div class="title" ref="title">
        <div class="text">
          <p style="font-weight: bold">编码分析</p>
        </div>
      </div>
      <div style="color: #1f2d3d">
        <el-table :data="data.list" :header-cell-style="{ background: '#E5E9F2', color: '#1F2D3D' }" :row-style="tableRowStyleCallBack">
          <el-table-column label="#" width="60">
            <template slot-scope="{ row,$index }">
              <span>{{ (((data.page.index - 1) * data.page.size) + $index + 1) }}</span>
            </template>
          </el-table-column>
          <el-table-column width="150" label="产品" prop="hsCodeCn" />
          <el-table-column width="100" label="Hs编码" prop="name" />
          <el-table-column label="中文描述" prop="productCn" />
          <el-table-column width="120" label="频次" prop="frequency" show-overflow-tooltip />
          <el-table-column width="120" label="金额" prop="value" show-overflow-tooltip />
          <el-table-column width="120" label="重量" prop="weight" show-overflow-tooltip />
          <el-table-column width="120" label="数量" prop="quantity" show-overflow-tooltip />
        </el-table>
        <div style="margin-top: 1em; display: flex; justify-content: flex-end">
          <el-pagination background layout="total, prev, pager, next" :total="data.total" :page-size="data.page.size" :current-page="data.page.index" @current-change="getData" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/firm/scss/mixin.scss";

.code-analyse {
  margin-top: 1rem;
  background: #fff;
  padding: 1rem;
  padding-top: 0;
  @include mixin-panel-title;
}
</style>
