<template>
    <div>
        <!-- 一带一路(YDY)进口提单明细/没有出口 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th width="25%">注册号</th>
                        <td width="25%">{{billInfo.RegistrationNumber}}</td>
                        <th width="25%">声明表数</th>
                        <td width="25%">{{billInfo.DeclarationSheetNumber}}</td>
                    </tr>
                    <tr>
                        <th>发货商(代码)</th>
                        <td>{{billInfo.SenderCode}}</td>
                        <th>发件人原籍国(原始)</th>
                        <td>{{billInfo.SenderOriginCountry}}</td>
                    </tr>
                    <tr>
                        <th>发货商城市(原始)</th>
                        <td>{{billInfo.SenderCity}}</td>
                        <th>发货商地址(原始)</th>
                        <td>{{billInfo.SenderAddress}}</td>
                    </tr>
                    <tr>
                        <th>收货商(代码)</th>
                        <td>{{billInfo.ReceiverCode}}</td>
                        <th>收货商原产国(原始)</th>
                        <td>{{billInfo.ReceiverOriginCountry}}</td>
                    </tr>
                    <tr>
                        <th>收货商城市(原始)</th>
                        <td>{{billInfo.ReceiverCity}}</td>
                        <th>收货商地址(原始)</th>
                        <td>{{billInfo.ReceiverAddress}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">运输信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th>原产国</th>
                        <td colspan="3">{{billInfo.Country}}</td>
                    </tr>
                    <tr>
                        <th width="25%">船上运输方式</th>
                        <td width="25%">{{billInfo.TransportModeOnBoarderCode}}</td>
                        <th width="25%">目的国(代码)</th>
                        <td width="25%">{{billInfo.DestinationCountryCode}}</td>
                    </tr>
                    <tr>
                        <th>运输人所在国家(代码)</th>
                        <td>{{billInfo.TransportOwnerCountryCode}}</td>
                        <th>运输方式离港</th>
                        <td>{{billInfo.TransportModeDepartureCode}}</td>
                    </tr>
                    <tr>
                        <th>表单数</th>
                        <td>{{billInfo.FormNumber}}</td>
                        <th>表格总数</th>
                        <td>{{billInfo.TotalNumberOfForms}}</td>
                    </tr>
                    <tr>
                        <th>全部清单申报费用</th>
                        <td>{{billInfo.TotalCostAllListDeclaration}}</td>
                        <th>发票的货币代码</th>
                        <td>{{billInfo.InvoiceCurrencyCode}}</td>
                    </tr>
                    <tr>
                        <th>主要关系描述</th>
                        <td>{{billInfo.Principal}}</td>
                        <th>主要关系代码</th>
                        <td>{{billInfo.PrincipalCode}}</td>
                    </tr>
                    <tr>
                        <th>主要关系人</th>
                        <td>{{billInfo.PrincipalName}}</td>
                        <th>主要国家代码</th>
                        <td>{{billInfo.PrincipalCountryCode}}</td>
                    </tr>
                    <tr>
                        <th>主要城市</th>
                        <td>{{billInfo.PrincipalCity}}</td>
                        <th>主要地区代码</th>
                        <td>{{billInfo.RegionPrincipalCode}}</td>
                    </tr>
                    <tr>
                        <th>主要地区名</th>
                        <td>{{billInfo.RegionPrincipalName}}</td>
                        <th>主要街道</th>
                        <td>{{billInfo.PrincipalStreet}}</td>
                    </tr>
                    <tr>
                        <th>过境地点签收</th>
                        <td>{{billInfo.TransitPlace}}</td>
                        <th>承运人信息</th>
                        <td>{{billInfo.Carrier}}</td>
                    </tr>
                    <tr>
                        <th>承运人代码</th>
                        <td>{{billInfo.CarrierCode}}</td>
                        <th>承运人名称</th>
                        <td>{{billInfo.CarrierName}}</td>
                    </tr>
                    <tr>
                        <th>承运人原产国代码</th>
                        <td>{{billInfo.CarrierCountryCode}}</td>
                        <th>承运人原产国</th>
                        <td>{{billInfo.OriginCarrierCountryNames}}</td>
                    </tr>
                    <tr>
                        <th>承运人地区</th>
                        <td>{{billInfo.RegionCarrierName}}</td>
                        <th>承运人城市</th>
                        <td>{{billInfo.CarrierCity}}</td>
                    </tr>
                    <tr>
                        <th>承运人街道</th>
                        <td>{{billInfo.CarrierStreet}}</td>
                        <th>目的地海关代码</th>
                        <td>{{billInfo.CustomsDestinationCode}}</td>
                    </tr>
                    <tr>
                        <th>海关所在地</th>
                        <td colspan="3">{{billInfo.DestinationCustoms}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
</template>

<script>
export default {
    props:["billInfo","ie"],
    data() {
        return {

        };
    },
    methods: {
        
    },
    mounted() {

    },
};
</script>

<style lang="scss" scoped>
.partItem {
    margin-bottom: 20px;
    /deep/.el-card__header {
        padding: 10px 20px;
        .headTitle {
            display: inline-block;
            padding-left: 10px;
            font-size: 14px;
            border-left: 2px solid var(--themeColor);
            font-weight: bold;
        }
    }
}
table {
    width: 100%;
    border-collapse: collapse;
    tr {
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;
        th,
        td {
            font-size: 12px;
            color: #666;
            height: 40px;
            border: 1px solid #eaeaea;
            text-align: center;
        }
        th {
            background-color: #f5f7fa;
            font-weight: 700;
        }
        .bg {
            background-color: var(--themeColor);
            color: #fff;
            font-weight: 700;
            opacity: 0.8;
        }
    }
}
</style>