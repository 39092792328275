<template>
    <div>
        <h2>{{interfaceName}}</h2>
    </div>
</template>

<script>
export default {
    props: ["interfaceName"],
    data() {
        return {};
    },
    methods: {},
    mounted() {},
};
</script>

<style lang="scss" scoped>
h2 {
    padding-bottom: 10px;
    border-bottom: 1px solid #d9d9d9;
}
</style>