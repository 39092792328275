<template>
    <div class="outsideCard">
        <div class="insideCard">
            <span>预览：</span>
            <div class="msgCard"> 
                <div v-show="isShowOne">
                    <img style="width:100%;height:120px;" :src="valueOne ? valueOne : defaultImg" alt="" />
                </div>
                <div v-show="isShowTwo">
                    <p class="textContent">{{valueTwo}}</p>
                </div>
                <div v-show="isShowThree">
                    <div class="bottomLink">
                        <img style="width:24px;height:24px;" :src="linkImg" alt="" />
                        <span>点击了解更多</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:["valueOne","valueTwo","valueThree","isShowOne","isShowTwo","isShowThree"],
    data() {
        return {
            defaultImg: require("@/assets/img/default.png"),
            linkImg: require("@/assets/img/link.png"),
        };
    },
    methods: {},
    mounted() {},
};
</script>

<style lang='scss' scoped>
.outsideCard{
    width: 380px;
    height: 100%;
    background: #FFFFFF;
    box-shadow: 0px 0px 16px 1px rgba(0,0,0,0.35);
    border-radius: 20px;
    padding: 10px;
    box-sizing: border-box;
}
.insideCard{
    width: 360px;
    height: 100%;
    background: #EEEAE7;
    border-radius: 20px;
    padding: 10px 15px;
    box-sizing: border-box;
    overflow: auto;
}
.msgCard{
    margin-top: 10px;
    width: 100%;
    min-height: 40px;
    background: #FFFFFF;
    padding: 10px;
    box-sizing: border-box;
}
.msgCard::before{
    content: "";
    height: 0px;
    width: 0px;
    border: 10px solid #FFF;
    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    position: absolute;
    margin-left: -20px;
    margin-top: -10px;
}
.textContent{
    font-size: 14px;
    color: #3a3a3a;
    line-height: 20px;
}
.bottomLink{
    margin-top: 5px;
    border-top: 1px solid #dadada;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #00a5f4;
    height: 30px;
}
</style>