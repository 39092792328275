<template>
    <el-dialog :visible.sync="screenDialogVisible" width="1150px" top="50px" :show-close="false">
        <div slot="title" class="header">
            <div class="title">贸易数据国家</div>
            <div class="saveBtn" @click="onSave">保存</div>
        </div>
        <div class="control">
            <el-checkbox v-model="checkAll" @change="checkAllChange" style="margin-top: 6px;">所有国家</el-checkbox>
            <el-radio-group v-model="ImpOrExp" style="margin-left: 20px;" @change="getCountryCode">
                <el-radio label="i" size="medium">进口</el-radio>
                <el-radio label="e" size="medium">出口</el-radio>
            </el-radio-group>
            <!-- <el-radio-group v-model="dataClass" size="small" style="float: right;" @change='getCountryCode'>
                <el-radio-button :label="2">全部</el-radio-button>
                <el-radio-button :label="0">海关</el-radio-button>
                <el-radio-button :label="1">船运</el-radio-button>
            </el-radio-group> -->
            <el-checkbox v-model="includeOne" @change="onChange" style="float: right;">包含船运</el-checkbox>
        </div>
        <div class="countryGroup" v-loading="CountryCodeLoading">
            <div class="list" v-if="NorthAmericaList.length != 0">
                <el-checkbox class="title" v-model="checkAllFour" @change="checkAllFourChange">北美洲</el-checkbox>
                <el-checkbox-group v-model="checkedCountryFour" @change="checkedCountryFourChange" style="overflow: hidden;">
                    <div class="option" v-for="(item,index) in NorthAmericaList" :key="index">
                        <el-checkbox  :label="item.threeCountryCode" :key="index">
                            <img :src="item.nationalFlag" alt="" />
                            <span>{{item.countryCn}}</span>
                        </el-checkbox>
                    </div>
                </el-checkbox-group>
            </div>
            <div class="list" v-if="AsiaList.length != 0">
                <el-checkbox class="title" v-model="checkAllOne" @change="checkAllOneChange">亚洲</el-checkbox>
                <el-checkbox-group v-model="checkedCountryOne" @change="checkedCountryOneChange" style="overflow: hidden;">
                    <div class="option" v-for="(item,index) in AsiaList" :key="index">
                        <el-checkbox  :label="item.threeCountryCode" :key="index">
                            <img :src="item.nationalFlag" alt="" />
                            <span>{{item.countryCn}}</span>
                        </el-checkbox>
                    </div>
                </el-checkbox-group>
            </div>
            <div class="list" v-if="EuropeList.length != 0">
                <el-checkbox class="title" v-model="checkAllTwo" @change="checkAllTwoChange">欧洲</el-checkbox>
                <el-checkbox-group v-model="checkedCountryTwo" @change="checkedCountryTwoChange" style="overflow: hidden;">
                    <div class="option" v-for="(item,index) in EuropeList" :key="index">
                        <el-checkbox  :label="item.threeCountryCode" :key="index">
                            <img :src="item.nationalFlag" alt="" />
                            <span>{{item.countryCn}}</span>
                        </el-checkbox>
                    </div>
                </el-checkbox-group>
            </div>
            <div class="list" v-if="SouthAmericaList.length != 0">
                <el-checkbox class="title" v-model="checkAllFive" @change="checkAllFiveChange">南美洲</el-checkbox>
                <el-checkbox-group v-model="checkedCountryFive" @change="checkedCountryFiveChange" style="overflow: hidden;">
                    <div class="option" v-for="(item,index) in SouthAmericaList" :key="index">
                        <el-checkbox  :label="item.threeCountryCode" :key="index">
                            <img :src="item.nationalFlag" alt="" />
                            <span>{{item.countryCn}}</span>
                        </el-checkbox>
                    </div>
                </el-checkbox-group>
            </div>
            <div class="list" v-if="AfricaList.length != 0">
                <el-checkbox class="title" v-model="checkAllThree" @change="checkAllThreeChange">非洲</el-checkbox>
                <el-checkbox-group v-model="checkedCountryThree" @change="checkedCountryThreeChange" style="overflow: hidden;">
                    <div class="option" v-for="(item,index) in AfricaList" :key="index">
                        <el-checkbox  :label="item.threeCountryCode" :key="index">
                            <img :src="item.nationalFlag" alt="" />
                            <span>{{item.countryCn}}</span>
                        </el-checkbox>
                    </div>
                </el-checkbox-group>
            </div>
            <div class="list" v-if="OceaniaList.length != 0">
                <el-checkbox class="title" v-model="checkAllSix" @change="checkAllSixChange">大洋洲</el-checkbox>
                <el-checkbox-group v-model="checkedCountrySix" @change="checkedCountrySixChange" style="overflow: hidden;">
                    <div class="option" v-for="(item,index) in OceaniaList" :key="index">
                        <el-checkbox  :label="item.threeCountryCode" :key="index">
                            <img :src="item.nationalFlag" alt="" />
                            <span>{{item.countryCn}}</span>
                        </el-checkbox>
                    </div>
                </el-checkbox-group>
            </div>
            <div class="list" v-if="AntarcticaList.length != 0">
                <el-checkbox class="title" v-model="checkAllSeven" @change="checkAllSevenChange">南极洲</el-checkbox>
                <el-checkbox-group v-model="checkedCountrySeven" @change="checkedCountrySevenChange" style="overflow: hidden;">
                    <div class="option" v-for="(item,index) in AntarcticaList" :key="index">
                        <el-checkbox  :label="item.threeCountryCode" :key="index">
                            <img :src="item.nationalFlag" alt="" />
                            <span>{{item.countryCn}}</span>
                        </el-checkbox>
                    </div>
                </el-checkbox-group>
            </div>
        </div>
    </el-dialog>
</template>

<script>
export default {
    props: ["screenDialogVisible"],
    data() {
        return {
            //国家选择
            ImpOrExp: "i",
            includeOne: true,
            dataClass: 2,
            checkAll: true, //所有国家全选(默认全选)
            checkAllOne: false, //亚洲全选
            checkedCountryOne: [],
            AsiaList: [],
            checkAllTwo: false, //欧洲全选
            checkedCountryTwo: [],
            EuropeList: [],
            checkAllThree: false, //非洲全选
            checkedCountryThree: [],
            AfricaList: [],
            checkAllFour: false, //北美洲全选
            checkedCountryFour: [],
            NorthAmericaList: [],
            checkAllFive: false, //南美洲全选
            checkedCountryFive: [],
            SouthAmericaList: [],
            checkAllSix: false, //大洋洲全选
            checkedCountrySix: [],
            OceaniaList: [],
            checkAllSeven: false, //南极洲全选
            checkedCountrySeven: [],
            AntarcticaList: [],
            CountryCodeLoading: true, //国家码的loading
        };
    },
    methods: {
        onChange(value){
            if(value){
                this.dataClass = 2;
            }else{
                this.dataClass = 0;
            }
            this.getCountryCode();
        },

        //获取国家配置
        getCountryCode() {
            // console.log(this.ImpOrExp);
            // console.log(this.dataClass);
            this.CountryCodeLoading = true;
            this.$axios.post(`${this.$searchUrl}/Trade/Country`, {
                ie: this.ImpOrExp,
                dataClass: this.dataClass,
            }).then((res) => {
                const { isSuccess, data } = res;
                this.CountryCodeLoading = false;
                if (isSuccess) {
                    // console.log(data);
                    data.forEach((item) => {
                        if (item.continentCn == "亚洲") {
                            this.AsiaList = item.country;
                        } else if (item.continentCn == "欧洲") {
                            this.EuropeList = item.country;
                        } else if (item.continentCn == "非洲") {
                            this.AfricaList = item.country;
                        } else if (item.continentCn == "北美洲") {
                            this.NorthAmericaList = item.country;
                        } else if (item.continentCn == "南美洲") {
                            this.SouthAmericaList = item.country;
                        } else if (item.continentCn == "大洋洲") {
                            this.OceaniaList = item.country;
                        } else if (item.continentCn == "南极洲") {
                            this.AntarcticaList = item.country;
                        }
                    });
                    //获取国家码列表之后，所有国家默认全选
                    this.checkAllChange(true);
                }
            });
        },
        //所有国家是否全选
        checkAllChange(val) {
            this.checkAllOne = val;
            this.checkAllTwo = val;
            this.checkAllThree = val;
            this.checkAllFour = val;
            this.checkAllFive = val;
            this.checkAllSix = val;
            this.checkAllSeven = val;
            this.checkAllOneChange(val);
            this.checkAllTwoChange(val);
            this.checkAllThreeChange(val);
            this.checkAllFourChange(val);
            this.checkAllFiveChange(val);
            this.checkAllSixChange(val);
            this.checkAllSevenChange(val);
        },
        //判断所有国家是否全选
        allCountryIsChoose(){
            this.checkAll =
                this.checkAllOne &&
                this.checkAllTwo &&
                this.checkAllThree &&
                this.checkAllFour &&
                this.checkAllFive &&
                this.checkAllSix &&
                this.checkAllSeven;
        },
        //亚洲
        checkAllOneChange(val) {
            const list = [];
            this.AsiaList.forEach((item) => {
                list.push(item.threeCountryCode);
            });
            this.checkedCountryOne = val ? list : [];
            this.allCountryIsChoose();
        },
        checkedCountryOneChange(value) {
            let checkedCount = value.length;
            this.checkAllOne = checkedCount === this.AsiaList.length;
            this.allCountryIsChoose();
        },
        //欧洲
        checkAllTwoChange(val) {
            const list = [];
            this.EuropeList.forEach((item) => {
                list.push(item.threeCountryCode);
            });
            this.checkedCountryTwo = val ? list : [];
            this.allCountryIsChoose();
        },
        checkedCountryTwoChange(value) {
            let checkedCount = value.length;
            this.checkAllTwo = checkedCount === this.EuropeList.length;
            this.allCountryIsChoose();
        },
        //非洲
        checkAllThreeChange(val) {
            const list = [];
            this.AfricaList.forEach((item) => {
                list.push(item.threeCountryCode);
            });
            this.checkedCountryThree = val ? list : [];
            this.allCountryIsChoose();
        },
        checkedCountryThreeChange(value) {
            let checkedCount = value.length;
            this.checkAllThree = checkedCount === this.AfricaList.length;
            this.allCountryIsChoose();
        },
        //北美洲
        checkAllFourChange(val) {
            const list = [];
            this.NorthAmericaList.forEach((item) => {
                list.push(item.threeCountryCode);
            });
            this.checkedCountryFour = val ? list : [];
            this.allCountryIsChoose();
        },
        checkedCountryFourChange(value) {
            let checkedCount = value.length;
            this.checkAllFour = checkedCount === this.NorthAmericaList.length;
            this.allCountryIsChoose();
        },
        //南美洲
        checkAllFiveChange(val) {
            const list = [];
            this.SouthAmericaList.forEach((item) => {
                list.push(item.threeCountryCode);
            });
            this.checkedCountryFive = val ? list : [];
            this.allCountryIsChoose();
        },
        checkedCountryFiveChange(value) {
            let checkedCount = value.length;
            this.checkAllFive = checkedCount === this.SouthAmericaList.length;
            this.allCountryIsChoose();
        },
        //大洋洲
        checkAllSixChange(val) {
            const list = [];
            this.OceaniaList.forEach((item) => {
                list.push(item.threeCountryCode);
            });
            this.checkedCountrySix = val ? list : [];
            this.allCountryIsChoose();
        },
        checkedCountrySixChange(value) {
            let checkedCount = value.length;
            this.checkAllSix = checkedCount === this.OceaniaList.length;
            this.allCountryIsChoose();
        },
        //南极洲
        checkAllSevenChange(val) {
            const list = [];
            this.AntarcticaList.forEach((item) => {
                list.push(item.threeCountryCode);
            });
            this.checkedCountrySeven = val ? list : [];
            this.allCountryIsChoose();
        },
        checkedCountrySevenChange(value) {
            let checkedCount = value.length;
            this.checkAllSeven = checkedCount === this.AntarcticaList.length;
            this.allCountryIsChoose();
        },

        //点击保存按钮提交数据
        onSave() {
            let threeEnCountryCode = "";
            const checkedList = this.checkedCountryOne.concat(this.checkedCountryTwo,this.checkedCountryThree,this.checkedCountryFour,this.checkedCountryFive,this.checkedCountrySix,this.checkedCountrySeven)
            if(this.checkAll){
                threeEnCountryCode = "";
            }else{
                threeEnCountryCode = checkedList.join(',');
            }
            let saveParam = {
                threeEnCountryCode,
                ie: this.ImpOrExp,
                dataClass: this.dataClass,
            }
            this.$emit("onSave",saveParam);
        },

    },
    mounted() {
        this.getCountryCode(); //获取国家配置
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog__header {
    padding: 0;
}
/deep/ .el-dialog__body {
    padding: 0;
}
.header {
    background-color: #30344b;
    padding: 10px 20px;
    color: #fff;
    height: 40px;
    line-height: 40px;
    display: flex;
    justify-content: space-between;
    .title {
        font-size: 16px;
    }
    .saveBtn {
        font-size: 20px;
        cursor: pointer;
    }
}
.control {
    padding: 10px 25px;
    background-color: #f5f7fa;
    height: 32px;
}
.countryGroup{
    height: 700px;
    overflow-y: scroll;
    padding: 0 20px 20px;
    .list{
        margin-top: 20px;
        .title{
            width: 20%;
            padding: 10px 7px;
            border: 1px solid #ddd;
            border-bottom: none;
        }
        .el-checkbox-group{
            padding: 7px;
            border: 1px solid #ddd;
        }
        .option{
            width: 20%;
            float: left;
            padding: 10px;
            height: 50px;
            box-sizing: border-box;
            img{
                vertical-align: middle;
                margin-right: 10px;
            }
        }
    }
}
</style>