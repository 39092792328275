<template>
    <div class="helperInstall">
        <el-card class="w">
            <h3>如何安装企芯助手</h3>
            <el-button class="btnOne" type="primary" round size="small" @click="download">下载企芯助手插件</el-button>
            <h4><span class="icon">//</span><span class="text">视频教程</span></h4>     
            <video controls autoplay :src="videoUrl" style="width: 100%;"></video>
            <div style="height:30px;"></div>
            <h4><span class="icon">//</span><span class="text">图文教程</span></h4>
            <div class="content">
                <div class="tt">
                    <span class="steps">第一步、</span>
                    <span>[下载插件]</span>
                </div>
                <el-button class="btnTwo" type="primary" round size="small" @click="download">下载插件</el-button>
                <div class="tt">
                    <span class="steps">第二步、</span>
                    <span>[Google浏览器——设置——更多工具——扩展程序]</span>
                </div>
                <el-image class="picture" :src="img1"></el-image>
                <div class="tt">
                    <span class="steps">第三步、</span>
                    <span>[开启开发者模式——刷新页面——解压下载好的插件——点击加载已解压的扩展程序按钮]</span>
                </div>
                <el-image class="picture" :src="img2"></el-image>
                <el-image class="picture" :src="img3"></el-image>
                <el-image class="picture" :src="img4"></el-image>
                <el-image class="picture" :src="img5"></el-image>
                <div class="tt">
                    <span class="steps">第四步、</span>
                    <span>[打开WhatsApp——点击授权按钮——登录搜鱼网页授权版授权]</span>
                </div>
                <el-image class="picture" :src="img6"></el-image>
                <div class="tt">
                    <span class="steps">第五步、</span>
                    <span>[重新打开WhatsApp]</span>
                </div>
                <el-image class="picture" :src="img7"></el-image>
            </div>
        </el-card>
    </div>
</template>

<script>
export default {
    data() {
        return {
            videoUrl: "http://cbserp-sh.oss-cn-shanghai.aliyuncs.com/sytool/1665655134938_1.mp4",
            img1: require('@/assets/helper/img1.png'),
            img2: require('@/assets/helper/img2.png'),
            img3: require('@/assets/helper/img3.png'),
            img4: require('@/assets/helper/img4.png'),
            img5: require('@/assets/helper/img5.png'),
            img6: require('@/assets/helper/img6.png'),
            img7: require('@/assets/helper/img7.png'),
        };
    },
    methods: {
        download(){
            this.$axios.get(`https://sofish.lsmaps.com/api/Renew/V2/GetRenew?app=qixin`).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    window.open(data.win.toolUrl); //插件链接
                }
            });
        }
    },
    mounted() {

    },
};
</script>

<style lang="scss" scoped>
@import '@/scss/common.scss';
.helperInstall{
    .w{
        width: 1200px;
        margin: 20px auto;
        position: relative;
        .btnOne{
            position: absolute;
            right: 20px;
            top: 20px;
            width: 150px;
            font-weight: bold;
        }
        .btnTwo{
            width: 120px;
            margin-bottom:20px;
            margin-left:60px;
            font-weight: bold;
        }
        h3{
            color: #000;
            margin-bottom: 20px;
        }
        h4{
            margin-bottom: 20px;
            .icon{
                color: $themeColor;
                padding-right: 5px;
                font-weight: bold;
            }
            .text{
                color: #3a3a3a;
            }
        }
        .content{
            .tt{
                margin-bottom: 10px;
                .steps{
                    color: $themeColor;
                    font-weight: bold;
                }
            }
            .picture{
                margin-bottom: 20px;
                border: 4px solid $themeColor;
            }
        }
    }
}
</style>