<template>
    <div v-loading="loading">
        <!-- 厄瓜多尔 -->
        <el-descriptions :column="2" border :labelStyle="{width:'15%'}" :contentStyle="{width:'35%'}">
            <el-descriptions-item label="公司代码">{{businessInfo.companyId}}</el-descriptions-item>
            <el-descriptions-item label="公司名">{{businessInfo.companyName}}</el-descriptions-item>
            <el-descriptions-item label="公司名标准">{{businessInfo.companyStd}}</el-descriptions-item>
            <el-descriptions-item label="注册时间">{{businessInfo.registrationDate}}</el-descriptions-item>
            <el-descriptions-item label="注册国家">{{businessInfo.registrationCountry}}</el-descriptions-item>
            <el-descriptions-item label="公司邮箱">{{businessInfo.email}}</el-descriptions-item>
            <el-descriptions-item label="公司电话">{{businessInfo.phone}}</el-descriptions-item>
            <el-descriptions-item label="省">{{businessInfo.province}}</el-descriptions-item>
            <el-descriptions-item label="地方省">{{businessInfo.localProvince}}</el-descriptions-item>
            <el-descriptions-item label="公司所在城市">{{businessInfo.city}}</el-descriptions-item>
            <el-descriptions-item label="公司地址">{{businessInfo.address}}</el-descriptions-item>
            <el-descriptions-item label="代码">{{businessInfo.number}}</el-descriptions-item>
            <el-descriptions-item label="其它地址信息">{{businessInfo.otherAddressInformation}}</el-descriptions-item>
            <el-descriptions-item label="写字楼">{{businessInfo.building}}</el-descriptions-item>
            <el-descriptions-item label="区">{{businessInfo.zone}}</el-descriptions-item>
            <el-descriptions-item label="公司传真">{{businessInfo.fax}}</el-descriptions-item>
            <el-descriptions-item label="总额">{{businessInfo.totalAmount}}</el-descriptions-item>
            <el-descriptions-item label="在职员工人数">{{businessInfo.employeesNumber}}</el-descriptions-item>
            <el-descriptions-item label="直接对外投资">{{businessInfo.ditectForeignInvest}}</el-descriptions-item>
            <el-descriptions-item label="社会项目">{{businessInfo.socialObject}}</el-descriptions-item>
            <el-descriptions-item label="SocialStatus">{{businessInfo.socialStatus}}</el-descriptions-item>
            <el-descriptions-item label="公司类型">{{businessInfo.companyType}}</el-descriptions-item>
            <el-descriptions-item label="经济活动等级1">{{businessInfo.economicActivityLevel1}}</el-descriptions-item>
            <el-descriptions-item label="经济活动等级2">{{businessInfo.economicActivityLevel2}}</el-descriptions-item>
            <el-descriptions-item label="经济活动级别3">{{businessInfo.economicActivityLevel3}}</el-descriptions-item>
            <el-descriptions-item label="经济活动级别4">{{businessInfo.economicActivityLevel4}}</el-descriptions-item>
            <el-descriptions-item label="经济活动级别5">{{businessInfo.economicActivityLevel5}}</el-descriptions-item>
            <el-descriptions-item label="经济活动等级6">{{businessInfo.economicActivityLevel6}}</el-descriptions-item>
            <el-descriptions-item label="美元资本">{{businessInfo.usdCapital}}</el-descriptions-item>
        </el-descriptions>
    </div>
</template>

<script>
export default {
    data() {
        return {
            businessInfo: {},
            loading: false,
        };
    },
    methods: {
        getInfo(){
            let companyName = encodeURIComponent(this.$route.query.companyName);
            let country = this.$route.query.country;
            this.loading = true;
            this.$axios.get(`${this.$clueUrl}/BuyerLeads/GetBusinessInformation?country=${country}&company=${companyName}`).then((res) => {
                const { isSuccess, data } = res;
                this.loading = false;
                if (isSuccess) {
                    this.businessInfo = data;
                }
            });
        },
    },
    mounted() {
        this.getInfo();
    },
};
</script>

<style lang="scss" scoped>

</style>