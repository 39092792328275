<script>
import Vue from 'vue'
import { ref } from '@vue/composition-api'
import Step1 from './components/Step1.vue'
import Step2 from './components/Step2.vue'
import Step3 from './components/Step3.vue'
import axios from '@/assets/js/axios'
import qs from 'qs'
import { Message } from 'element-ui'
import router from '@/router'
import CMenu from '../../MarketingWA/components/Menu.vue'
export default Vue.extend({
  components: { Step1, Step2, Step3, CMenu },
  setup() {
    const step = ref(1)

    const loading = ref(false)
    const form = {
      eventNo: "",
      subscribers: [],
      subject: '',
      bodyHtml: '',
      attachments: [],
      isTiming: false,
      sendTime: '',
      templateKey: '',
    }
    const myClueKeys = ref();

    const onStep1Done = (recipient, checkedKey) => {
      form.subscribers = recipient
      console.log(recipient);
      myClueKeys.value = checkedKey;
      console.log(myClueKeys.value);
      step.value = 2
    }
    const handSubject = ref("");
    const onStep2Done = val => {
      Object.assign(form, val)
      handSubject.value = form.subject;
      step.value = 3
    }
    const onSend = async val => {
      try {
        Object.assign(form, val)
        loading.value = true
        // const res = await axios({
        //   method: 'POST',
        //   url: Vue.prototype.$marketUrl + '/MassSending/SendMassSending',
        //   data: form
        // })
        // if (res.isSuccess) {
        //   Message.success('发送成功')
        //   router.replace({ name: 'TaskDashboard' })
        // }
        // 保存群发,发送群发
        const res = await axios({
          method: 'POST',
          url: Vue.prototype.$marketUrl + '/MassSending/SaveEvent',
          data: form
        })
        if (res.isSuccess) {
          const resTwo = await axios({
            method: 'GET',
            url: Vue.prototype.$marketUrl + '/MassSending/SendEvent',
            params: {
              eventNo: res.data,
            }
          })
          if (resTwo.isSuccess) {
            Message.success('发送成功')
            if (myClueKeys.value.length > 0) {
              // 添加线索群发关系
              axios({
                method: 'POST',
                url: Vue.prototype.$clueUrl + '/MyClue/AddClueMassSendingRelation',
                data: {
                  eventNo: res.data,
                  myClueKeys: myClueKeys.value,
                }
              })
            }
            router.replace({ name: 'TaskDashboard' })
          }
        }
      } catch (error) {
        Message.error(error)
      } finally {
        loading.value = false
      }
    }

    const onSave = async val => {
      try {
        Object.assign(form, val)
        loading.value = true
        // 保存群发
        const res = await axios({
          method: 'POST',
          url: Vue.prototype.$marketUrl + '/MassSending/SaveEvent',
          data: form
        })
        if (res.isSuccess) {
          Message.success('保存成功')
          router.replace({ name: 'TaskDashboard' })
        }
      } catch (error) {
        Message.error(error)
      } finally {
        loading.value = false
      }
    }

    const url = window.location.href
    const index = url.lastIndexOf('?')
    const eventNo = qs.parse(url.slice(index + 1))['eventNo'] || '';
    if (eventNo) {
      form.eventNo = eventNo;
      document.title = "营销模块-编辑营销";
    }

    return {
      step,
      loading,
      myClueKeys,
      onStep1Done,
      onStep2Done,
      onSend,
      onSave,
      handSubject,
    }
  }
})
</script>

<template>
  <div style="display: flex; box-sizing: border-box;width: 100%;height: 100%;">
    <!-- <div>
      <CMenu active="New" />
    </div> -->
    <div v-loading="loading" class="view">
      <div style="position: relative; box-sizing: border-box; height: 80px; display: flex; align-items: center;">
        <!--  <span style="position: absolute; left: 20px; top: 20px">可用发件量: 1393封/日</span> -->
        <el-tabs ref="ElTabsStep" class="step" style="width: 100%" :before-leave="() => false">
          <el-tab-pane>
            <template #label>
              <div :class="[step >= 1 ? 'active' : '']" style="display: flex; align-items: center">
                <div class="step-icon">1</div>
                <span>添加收件人</span>
              </div>
            </template>
          </el-tab-pane>
          <el-tab-pane>
            <template #label>
              <div :class="[step >= 2 ? 'active' : '']" style="display: flex; align-items: center">
                <div class="step-icon">2</div>
                <span>邮件内容</span>
              </div>
            </template>
          </el-tab-pane>
          <el-tab-pane>
            <template #label>
              <div :class="[step >= 3 ? 'active' : '']" style="display: flex; align-items: center">
                <div class="step-icon">3</div>
                <span>营销设置</span>
              </div>
            </template>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="content-wrapper">
        <Step1 v-show="step === 1" @done="onStep1Done" />
        <Step2 v-show="step === 2" @done="onStep2Done" @prev="step -= 1" />
        <Step3 v-show="step === 3" @done="onSend" @save="onSave" @prev="step -= 1" :handSubject="handSubject" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.view {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background: #fff;
}

.step {
  ::v-deep .el-tabs__nav-scroll {
    display: flex;
    justify-content: center;
  }

  .active {
    color: var(--themeColor);

    .step-icon {
      border-color: var(--themeColor);
      background: var(--themeColor);
      color: #fff;
    }
  }

  .step-icon {
    margin-right: 0.5em;
    width: 1.3em;
    height: 1.3em;
    border-radius: 50%;
    border: 1px solid #919191;
    color: #919191;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.content-wrapper {
  box-sizing: border-box;
  height: calc(100% - 80px);
}
</style>
