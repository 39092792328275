<template>
    <div class="rightOption">
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="AI写信" name="first"></el-tab-pane>
            <el-tab-pane label="开发信" name="second"></el-tab-pane>
            <el-tab-pane label="节日模板" name="third"></el-tab-pane>
            <el-tab-pane label="我的模板" name="fourth"></el-tab-pane>
            <div v-if="activeName=='first'" class="itemBox AiLetter">
                <div class="lineItem">
                    <div class="label">产品关键词</div>
                    <el-input class="elinput" v-model="productKeyword" clearable placeholder="产品关键词" size="small"></el-input>
                </div>
                <div class="lineItem">
                    <div class="label">语言</div>
                    <el-select v-model="language" placeholder="请选择" size="small" style="width:110px;">
                        <el-option label="中文" value="中文"></el-option>
                        <el-option label="英语" value="英语"></el-option>
                        <el-option label="日语" value="日语"></el-option>
                        <el-option label="西班牙语" value="西班牙语"></el-option>
                        <el-option label="法语" value="法语"></el-option>
                        <el-option label="德语" value="德语"></el-option>
                        <el-option label="俄语" value="俄语"></el-option>
                        <el-option label="葡萄牙语" value="葡萄牙语"></el-option>
                    </el-select>
                    <div class="label">语气</div>
                    <el-select v-model="tone" placeholder="请选择" size="small" style="width:110px;">
                        <el-option label="正式商务" value="正式商务"></el-option>
                        <el-option label="有说服力" value="有说服力"></el-option>
                        <el-option label="尊敬" value="尊敬"></el-option>
                        <el-option label="自信" value="自信"></el-option>
                        <el-option label="礼貌" value="礼貌"></el-option>
                        <el-option label="委婉" value="委婉"></el-option>
                    </el-select>
                    <el-button class="elbutton" type="primary" size="small" @click="createLetter" :loading="createLoading">生成</el-button>
                </div>
                <div class="tipItem">
                    <span>请使用</span>
                    <span class="fw-blod">"{{language}}"</span>
                    <span>以</span>
                    <span class="fw-blod">"{{tone}}"</span>
                    <span>的语气写一封产品为</span>
                    <span class="fw-blod">"{{productKeyword || '产品关键词'}}"</span>
                    <span>的外贸开发信</span>
                </div>
                <div class="writeResult">
                    <div v-if="isClickWrite">
                        <div v-if="!generateHtml" class="cursorIcon"></div>
                        <div v-html="generateHtml" class="generateBox"></div>
                        <el-button v-if="!createLoading" class="usebutton" type="primary" plain size="small" @click="fillMail">使用</el-button>
                    </div>
                    <div v-else class="example">
                        <p class="fw-blod">By ChatGPT AI开发信</p>
                        <p class="fw-blod">产品关键词样例：</p>
                        <p>SYNTHETIC RUBBER SBR-1502 、</p>
                        <p>POLYURETHANE PREPOLYMER FOR SHOE SOLE 、</p>
                        <p>FITTING FLANGE 1.5 SCH 80 X 5 LG THRD 、</p>
                        <p>Rebar Tying Machine Fitting Coil Wire Loop Wire 、</p>
                        <p>Globe Valve, Gate Valve, Check</p>
                    </div>
                </div>
            </div>
            <div v-else class="itemBox templateBox">
                <div class="templateItem" v-for="ele in showTemplateList" :key="ele.templateKey"
                    @mouseenter="onPreview(ele.htmlImg)" @mouseleave="isPreviewing = false;">
                    <el-image class="elImage" :src="ele.htmlImg" fit="cover"></el-image>
                    <div class="theme utils-omit-1" :title="ele.subject">{{ele.subject}}</div>
                    <div class="backdrop">
                        <i class="el-icon-delete" v-if="activeName == 'fourth'" @click="delMyTemplate(ele.templateKey)"></i>
                        <div class="touse" @click="getTempContent(ele.templateKey)">使用模板</div>
                    </div>
                </div>
                <el-empty v-if="showTemplateList.length == 0" description="暂无数据"></el-empty>
            </div>
        </el-tabs>
        <div v-if="isPreviewing" class="previewPop">
            <img style="max-width:800px;max-height:600px;" :src="previewImg" alt="" />
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            activeName: "first",
            //AI写信
            productKeyword: "",
            language: "中文",
            tone: "正式商务",
            createLoading: false,
            generateHtml: "",
            isClickWrite: false,
            //模板列表
            allTemplateList: [],
            showTemplateList: [],
            isPreviewing: false,
            previewImg: "",
        };
    },
    methods: {
        handleClick() {
            if (this.activeName == "second") {
                this.showTemplateList = this.allTemplateList.filter(
                    (ele) => ele.typeName == "开发信"
                )[0].datas;
            }
            if (this.activeName == "third") {
                this.showTemplateList = this.allTemplateList.filter(
                    (ele) => ele.typeName == "节日模板"
                )[0].datas;
            }
            if (this.activeName == "fourth") {
                this.showTemplateList = this.allTemplateList.filter(
                    (ele) => ele.typeName == "我的模板"
                )[0].datas;
            }
            // console.log(this.showTemplateList);
        },
        //AI写信
        async createLetter() {
            if(!this.productKeyword.trim()){
                this.$message({message: '请输入产品关键词!',type: 'warning'});
                return false;
            }
            this.isClickWrite = true;
            this.createLoading = true;
            this.generateHtml = "";
            const params = {
                keyword: this.productKeyword.trim(),
                language: this.language,
                tone: this.tone,
            };
            const url = new URL(this.$marketUrl + "/MassSending/v2/ChatgptAiWriteMail");
            url.search = new URLSearchParams(params).toString();
            let token = localStorage.getItem('token');
            try{
                const response = await fetch(url, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Connection": "keep-alive",
                        "Authorization": "Bearer " + token,
                    }
                })
                const encode = new TextDecoder("utf-8");
                const reader = response.body.getReader();
                while (true) {
                    const { done, value } = await reader.read();
                    if (done) {
                        break;
                    }
                    const text = encode.decode(value);
                    if (text === "Error") {
                        this.$message({message: '生成AI信失败!',type: 'warning'});
                        break;
                    } else {
                        if (text) {
                            this.generateHtml += text;
                        }
                    }
                }
                this.createLoading = false;
            }catch{
                this.$message({message: '生成AI信失败!',type: 'warning'});
                this.createLoading = false;
            }
        },
        fillMail(){
            this.$emit("fillMail",this.generateHtml);
        },

        //获取模板列表
        getTemplate() {
            this.$axios.get(this.$marketUrl + "/MassSending/GetEmailTemplateList").then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.allTemplateList = data;
                    this.handleClick();
                }
            });
        },
        onPreview(htmlImg) {
            this.isPreviewing = true;
            this.previewImg = htmlImg;
        },
        getTempContent(templateKey) {
            this.$axios({
                method: "GET",
                url: this.$marketUrl + "/MassSending/GetEmailTemplateByKey",
                params: { templatekey: templateKey },
            }).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.$emit("useTemplate",data.subject,data.textHtml,templateKey);
                }
            });
        },
        
        //删除我的模板
        delMyTemplate(templateKey){
            this.$confirm('是否删除该模板?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios({
                    method: "GET",
                    url: this.$marketUrl + "/MassSending/DelEmailTemplate",
                    params: { templatekey: templateKey },
                }).then((res) => {
                    const { isSuccess } = res;
                    if (isSuccess) {
                        this.$message({ type: 'success', message: '删除成功!'});
                        this.getTemplate();
                    }
                });
            }).catch(() => {});
        },

    },
    mounted() {
        this.getTemplate();
    },
};
</script>

<style lang="scss" scoped>
.rightOption {
    /deep/.el-tabs__item {
        padding: 0 20px !important;
    }
    /deep/.el-tabs__header {
        margin-bottom: 0 !important;
    }
}
.previewPop{
    position: fixed;
    top: 65px;
    right: 520px;
    background: #ffffff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border: 2px solid var(--themeColor);
    z-index: 999;
}

.itemBox {
    height: calc(100vh - 120px);
    overflow: auto;
    box-sizing: border-box;
    padding: 0 20px 20px;
}
.AiLetter {
    .lineItem {
        margin-top: 10px;
        height: 32px;
        line-height: 32px;
        display: flex;
        font-size: 14px;
        .label {
            color: #1f2d3d;
            font-weight: bold;
            width: 70px;
            margin-right: 10px;
            text-align: right;
        }
        .elinput {
            width: calc(100% - 80px);
        }
        .elbutton {
            margin-left: 24px;
        }
    }
    .tipItem {
        margin-top: 10px;
        background: #ebebeb;
        font-size: 12px;
        padding: 10px;
    }
    .fw-blod {
        font-weight: bold;
    }
    .writeResult{
        margin-top: 20px;
        position: relative;
        .example{
            padding: 50px 80px;
            color: #999;
            font-size: 12px;
            p{
                line-height: 18px;
            }
        }
        .usebutton{
            position: absolute;
            right: 0;
            bottom: 0;
        }
        .cursorIcon {
            display: inline-block;
            width: 3px;
            height: 30px;
            background-color: #333;
            vertical-align: text-bottom;
            animation: blink 1s infinite;
        }
        @keyframes blink {
            50% {
                opacity: 0;
            }
        }
    }
}

.templateBox {
    padding: 10px;
    .templateItem {
        float: left;
        width: 32%;
        height: 200px;
        margin-right: 2%;
        margin-bottom: 10px;
        border: 2px solid #c9c9c9;
        box-sizing: border-box;
        padding: 5px 5px 24px 5px;
        text-align: center;
        position: relative;
        .theme {
            color: #6a6a6a;
            font-size: 14px;
        }
        .elImage {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        &:hover{
            border: 2px solid var(--themeColor);
        }
        &:nth-child(3n){
            margin-right: 0;
        }
    }
    .backdrop {
        width: 100%;
        height: 100%;
        display: none;
        z-index: 100;
        background-color: rgba(0, 0, 0, 0.3);
        position: absolute;
        top: 0;
        left: 0;
        .touse {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 30px;
            line-height: 30px;
            color: #fff;
            background-color: var(--themeColor);
            cursor: pointer;
        }
        .el-icon-delete{
            position: absolute;
            top: 5px;
            right: 5px;
            font-size: 20px;
            color: var(--themeColor);
            cursor: pointer;
        }
    }
    .templateItem:hover .backdrop {
        display: inline-block;
    }
}
</style>