<template>
  <div id="abroad-used-name" class="abroad-used-name" v-show="formerPrimaryNames.length !== 0">
    <div class="title" ref="title">
      <p>公司曾用名</p>
      <feedback position="公司曾用名" url="" />
      <!-- <div class="flex-container">
        <img class="icon" src="@/firm/images/company/icon-feedback.png" style="margin-left: 1em" />
        <span class="text-with-icon" style="color: var(--themeColor, #3152AF)">反馈</span>
      </div> -->
    </div>
    <el-table :data="formerPrimaryNames" border :header-cell-style="{ background: '#f8faff', color: '#1F2D3D' }">
      <el-table-column prop="name" label="名称" />
      <el-table-column prop="startDate" label="开始时间" />
      <el-table-column prop="endDate" label="结束时间" />
    </el-table>
  </div>
</template>

<script>
import Vue from "vue";
import { mapMutations } from "vuex";
import { AnchorInditor } from "../../../utils";

export default Vue.extend({
  props: ["formerPrimaryNames"],
  watch: {
    formerPrimaryNames() {
      try {
        if (this.formerPrimaryNames.length > 0) {
          this.setAnchorFlag([["CYM", true]]);
        } else {
          throw new Error();
        }
      } catch {
        this.setAnchorFlag([["CYM", false]]);
      }
    },
  },
  methods: {
    ...mapMutations("anchor", {
      setAnchorFlag: "setAnchorFlag",
      setCurrent: "setCurrent",
    }),
  },
  mounted() {
    new AnchorInditor(() => {
      this.setCurrent("CYM");
    }, this.$refs["title"]);
  },
});
</script>

<style lang="scss" scoped>
@import "@/firm/scss/mixin.scss";

.abroad-used-name {
  background: #fff;
  padding: 1rem;
  padding-top: 0;
  @include mixin-panel-title;
}
</style>
