<template>
    <div class="JumpWeb">
        <div class="box">
            <div class="p1">这不是企芯的网站,即将跳转到外部网站</div>
            <div class="p2">安全性未知,是否继续</div>
            <div class="p3" @click="goJump">{{jumpUrl}}</div>
            <div class="btn" @click="goJump">继续前往</div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            jumpUrl: ""
        };
    },
    methods: {
        goJump(){
            window.location.href = this.jumpUrl;
        }
    },
    mounted() {
        this.jumpUrl = this.$route.query.jumpUrl;
    },
};
</script>

<style lang="scss" scoped>
.JumpWeb{
    background-color: #fafafa;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    padding-top: 120px;
    .box{
        background-color: #fff;
        border-radius: 10px;
        width: 640px;
        margin: auto;
        padding: 20px;
        text-align: center;
        .p1{
            font-size: 24px;
            color: #3a3a3a;
            margin-bottom: 10px;
        }
        .p2{
            font-size: 16px;
            color: #9a9a9a;
            margin-bottom: 10px;
        }
        .p3{
            width: 400px;
            height: 60px;
            line-height: 60px;
            margin: auto;
            background-color: #fafafa;
            border: 1px solid #dadada;
            color: var(--themeColor);
            cursor: pointer;
        }
        .btn{
            margin: 15px auto;
            width: 100px;
            height: 40px;
            line-height: 40px;
            border-radius: 20px;
            border: 1px solid var(--themeColor);
            color: var(--themeColor);
            cursor: pointer;
        }
    }
}
</style>