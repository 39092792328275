<template>
    <div class="pageBox" v-loading="uploadLoading" element-loading-text="文件上传中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(255, 255, 255, 0.8)">
        <div class="header">
            <!-- <el-upload class="uploadBtn" :action="uploadUrl" :headers="headers" :data="uploadData" :show-file-list="false" :on-success="uploadOk" :on-progress="uploading" :on-error="uploadError">
                <el-button type="primary">上传文件</el-button>
            </el-upload> -->

            <el-button class="uploadBtn" type="primary" @click="showBox = true;">上传文件</el-button>
            <UploadBox :showBox="showBox" @onClose="showBox = false;" @onComplete="onComplete" />

            <el-button class="newFolder" @click="addFolderVisible = true;">新增文件夹</el-button>

            <!-- 新增批量操作--/文件批量转移/文件批量打标签/文件批量加入回收站 -->
            <div style="display: inline-block;margin-left: 20px;" v-if="checkFileKeys.length > 0 || checkFolderKeys.length > 0">
                <el-button @click="batchOperation(1)">移动至</el-button>
                <el-button @click="batchOperation(2)" v-if="checkFileKeys.length > 0">打标签</el-button>
                <el-button @click="batchOperation(3)">加入回收站</el-button>
            </div>

            <el-input class="searchBox" placeholder="请输入搜索内容" v-model="searchValue" prefix-icon="el-icon-search" clearable>
                <el-button slot="append" @click="onSearch">文件搜索</el-button>
            </el-input>
        </div>
        <div class="contentBox">
            <div class="topHeader">
                <div>
                    <img v-if="BreadcrumbList.length > 1" src="@/assets/doc/goBack.png" alt="" class="goBack" @click="goBack" />
                    <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb">
                        <el-breadcrumb-item v-for="(item,index) in BreadcrumbList" :key="item.key">
                            <span @click="goJump(index)" class="spanItem">{{item.name}}</span>
                        </el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
                <!-- 新增排序 -->
                <div>
                    <el-select v-model="sort" size="small" @change="getFileList" style="width: 110px;">
                        <el-option label="创建时间" :value="0"></el-option>
                        <el-option label="文件大小" :value="1"></el-option>
                        <el-option label="文件名称" :value="2"></el-option>
                    </el-select>
                    <el-select v-model="sortType" size="small" @change="getFileList" style="width: 80px;">
                        <el-option label="升序" value="asc"></el-option>
                        <el-option label="降序" value="desc"></el-option>
                    </el-select>
                </div>
            </div>
            <div class="tableHeader">
                <div class="name">文件夹名/文件名</div>
                <div class="size">大小</div>
                <div class="creator">创建人</div>
                <div class="time">创建时间</div>
                <div class="moreOption">更多操作</div>
            </div>
            <div class="tableBody" v-loading="FolderLoading || FileLoading">
                <div v-if="FolderList.length == 0 && FileList.length == 0">
                    <el-empty description="暂无数据"></el-empty>
                </div>
                <!-- 文件夹列表 -->
                <div class="FolderItem" v-for="item in FolderList" :key="item.key" draggable @dragstart="dragstart(item,1)"
                    @dragover="ev=>ev.preventDefault()" @drop="onDropShift($event,item)" :class="item.checked ? 'checkedBgStyle':''">
                    <div class="name">
                        <el-checkbox v-model="item.checked"></el-checkbox>
                        <img class="imgIcon" src="@/assets/doc/folderIcon.png" alt="" />
                        <span class="pointer" @click="viewFolder(item)">{{item.folderName}}</span>
                        <el-tooltip effect="dark" content="属性" placement="top">
                            <i class="el-icon-info" style="margin-left: 5px;cursor: pointer;" @click="viewProperty(item)"></i>
                        </el-tooltip>
                        <!-- <el-tag type="success" size="mini" v-if="item.isCollection">已收藏</el-tag> -->
                    </div>
                    <div class="size"></div>
                    <div class="creator">{{item.userCnName}}</div>
                    <div class="time">{{item.timeExhibition}}</div>
                    <div class="moreOption">
                        <el-dropdown>
                            <span style="color:var(--themeColor);cursor:pointer;font-size:20px;" @click.stop="() =>{}"><i class="el-icon-more"></i></span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item @click.native="modifyFolder(item)">重命名</el-dropdown-item>
                                <el-dropdown-item @click.native="shiftFolder(item)">移动至</el-dropdown-item>
                                <!-- <el-dropdown-item @click.native="saveCollection(item,1)">
                                    {{item.isCollection ? "取消收藏" : "收藏"}}
                                </el-dropdown-item> -->
                                <el-dropdown-item @click.native="deleteFolder(item)">删除</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </div>
                <!-- 文件列表 -->
                <div class="FileItem" v-for="item in FileList" :key="item.key" draggable @dragstart="dragstart(item,0)" 
                    :class="item.checked ? 'checkedBgStyle':''">
                    <div class="name">
                        <el-checkbox v-model="item.checked"></el-checkbox>
                        <img class="imgIcon fileIcon" :src="item.fileIcon" alt="" />
                        <span class="pointer" @click="viewFileDetails(item)">{{item.remarkName}}</span>
                        <!-- 快捷收藏/取消收藏 -->
                        <el-tooltip effect="dark" :content="item.isCollection ? '取消收藏' : '收藏'" placement="top">
                            <i class="el-icon-star-on collectIcon" v-if="item.isCollection" @click="saveCollection(item,0)"></i>
                        </el-tooltip>
                        <i class="el-icon-star-off collectIcon" v-if="!item.isCollection" @click="saveCollection(item,0)"></i>
                        <!-- 是否分享 -->
                        <el-tag size="mini" v-if="item.isShare">已分享</el-tag>
                        <!-- 快捷打标签功能 -->
                        <el-tag v-for="ele in item.lables" :key="ele.key" :color="ele.color" closable effect="dark" size="mini"
                         @close="delTag(ele.key)">{{ ele.text }}</el-tag>
                        <el-tag size="mini" type="success" class="openTag" @click="openTagBox(item)">打标签</el-tag>
                    </div>
                    <div class="size">{{item.fileSize}}</div>
                    <div class="creator">{{item.userCnName}}</div>
                    <div class="time">{{item.timeExhibition}}</div>
                    <div class="moreOption">
                        <el-dropdown>
                            <span style="color:var(--themeColor);cursor:pointer;font-size:20px;" @click.stop="() =>{}"><i class="el-icon-more"></i></span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item @click.native="fileDownload(item)">下载</el-dropdown-item>
                                <el-dropdown-item @click.native="fileSharing(item)">分享</el-dropdown-item>
                                <el-dropdown-item @click.native="modifyFile(item)">重命名</el-dropdown-item>
                                <el-dropdown-item @click.native="transferFile(item)">移动至</el-dropdown-item>
                                <el-dropdown-item @click.native="saveCollection(item,0)">
                                    {{item.isCollection ? "取消收藏" : "收藏"}}
                                </el-dropdown-item>
                                <el-dropdown-item @click.native="viewFileDetails(item)">详情信息</el-dropdown-item>
                                <el-dropdown-item @click.native="fileJoinRecycle(item)">加入回收站</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </div>
            </div>
        </div>

        <el-dialog title="新增文件夹" :visible.sync="addFolderVisible" width="500px">
            <el-input v-model="addFolderName" placeholder="请输入文件夹名称" size="small"></el-input>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addFolderCancel" size="small">取 消</el-button>
                <el-button type="primary" @click="addFolderOk" size="small" :loading="btnLoading">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog title="重命名文件夹" :visible.sync="modifyFolderVisible" width="500px">
            <el-input v-model="modifyFolderName" placeholder="请输入文件夹名称" size="small"></el-input>
            <span slot="footer" class="dialog-footer">
                <el-button @click="modifyFolderVisible = false;" size="small">取 消</el-button>
                <el-button type="primary" @click="modifyFolderOk" size="small" :loading="btnLoading">确 定</el-button>
            </span>
        </el-dialog>



        <!-- 文件分享 -->
        <FileShare :shareVisible="shareVisible" :shareFileKey="shareFileKey" @closedialog="shareVisible = false;" />

        <el-dialog title="重命名文件" :visible.sync="modifyFileVisible" width="500px">
            <el-input v-model="modifyFileName" placeholder="请输入文件名称" size="small"></el-input>
            <span slot="footer" class="dialog-footer">
                <el-button @click="modifyFileVisible = false;" size="small">取 消</el-button>
                <el-button type="primary" @click="modifyFileOk" size="small" :loading="btnLoading">确 定</el-button>
            </span>
        </el-dialog>

        <!-- 文件转移/文件夹转移/合并 -->
        <el-dialog title="文件/文件夹转移" :visible.sync="transferVisible" width="500px">
            <!-- 文件夹集合(树形结构) -->
            <el-cascader v-model="belongValue" :options="folderTreeData" :props="{value:'key',label:'name',disabled:'isDisable',checkStrictly:true}"
             clearable @change="belongChange" style="width:100%;"></el-cascader>
            <span slot="footer" class="dialog-footer">
                <el-button @click="transferVisible = false" size="small">取 消</el-button>
                <el-button type="primary" @click="transferFinish" size="small" :loading="btnLoading">确 定</el-button>
            </span>
        </el-dialog>

        <!-- 文件详情 -->
        <FileInfo :infoVisible="infoVisible" :infoKey="infoKey" @closedialog="infoVisible = false;" />

        <!-- 打标签 -->
        <AddTagBox :addTagVisible="addTagVisible" :signFileKey="signFileKey" :oldLables="oldLables" @onClose="addTagVisible = false;" @onSubmit="addTagFinish" />

        <!-- 文件夹属性 -->
        <FolderInfo :propertyVisible="propertyVisible" :propertyLoading="propertyLoading" :propertyInfo="propertyInfo" @closedialog="propertyVisible = false;" />

    </div>
</template>

<script>
import FileInfo from "./components/FileInfo.vue"
import FileShare from "./components/FileShare.vue"
import UploadBox from "./components/UploadBox.vue" //上传文件组件
import AddTagBox from "./components/AddTagBox.vue";//打标签组件
import FolderInfo from "./components/FolderInfo.vue"
export default {
    components: { FileInfo, FileShare, UploadBox, AddTagBox, FolderInfo },
    data() {
        return {
            userInfo: {},
            btnLoading: false,
            //上传文件
            uploadUrl: `${this.$diskUrl}/File/Upload`,
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('token'),
            },
            uploadData: {
                folderKey: "0",
                remarkName: "",
                permission: '我的'
            },
            uploadLoading: false,
            //搜索条件
            searchValue: "",
            currentMenu: '我的',            //**权限：我的空间
            currentParentKey: "0",          //当前所属父文件夹key
            BreadcrumbList:[{name: '我的空间',key: "0"}],
            //新增排序
            sort: 0,
            sortType: "desc",
            //文件夹列表
            FolderList:[],
            FolderLoading: false,
            //文件列表
            FileList: [],
            FileLoading: false,
            //新增文件夹
            addFolderVisible: false,
            addFolderName: "",
            //** 文件夹--更多操作 **//
            //重命名文件夹
            modifyFolderVisible: false,
            modifyFolderKey: "",
            modifyFolderName: "",


            //** 文件--更多操作 **//
            //重命名文件
            modifyFileVisible: false,
            modifyFileKey: "",
            modifyFileName: "",
            //文件分享
            shareVisible: false,
            shareFileKey: "",
            //文件转移/文件夹转移/合并
            transferVisible: false,
            transferFileKey: [],
            transferFolderKey: [],
            //查看文件详情
            infoVisible: false,
            infoKey: "",
            //文件夹属性
            propertyVisible: false,
            propertyLoading: false,
            propertyInfo: {},
            //文件夹集合(树形结构)
            folderTreeData: [],
            belongValue: [],
            //上传文件组件
            showBox: false,
            //打标签功能
            addTagVisible: false,
            signFileKey: [],
            oldLables: [],
            //快捷拖拽转移
            dragObj: {},
            dragType: 0,
        };
    },
    computed:{
        //批量操作勾选的文件夹和文件列表
        checkFileKeys(){
            return this.FileList.filter(item => item.checked).map(item => item.key);
        },
        checkFolderKeys(){
            return this.FolderList.filter(item => item.checked).map(item => item.key);
        },
    },
    methods: {
        //文件搜索
        onSearch(){
            this.getFolderList();
            this.getFileList();
        },
        //获取文件夹集合
        getFolderList(){
            const {searchValue,currentMenu,currentParentKey} = this;
            this.FolderLoading = true;
            this.$axios.post(`${this.$diskUrl}/Folder`,{
                folderName: searchValue,
                parentKey: currentParentKey,
                permission: currentMenu,
            }).then(res =>{
                const {isSuccess,data} = res;
                this.FolderLoading = false;
                if(isSuccess){
                    data.forEach(item =>{
                        item.checked = false;
                    })
                    this.FolderList = data;
                }
            })
        },
        //获取文件集合
        getFileList(){
            const {searchValue,currentMenu,currentParentKey} = this;
            this.FileLoading = true;
            this.$axios.post(`${this.$diskUrl}/File`,{
                folderKey: currentParentKey,
                remarkName: searchValue,
                fileType: "",
                permission: currentMenu,
                location: "",
                //排序
                sort: this.sort,
                sortType: this.sortType,
            }).then(res =>{
                const {isSuccess,data} = res;
                this.FileLoading = false;
                if(isSuccess){
                    data.forEach(item =>{
                        item.fileIcon = require(`@/assets/doc/file_${item.fileCategory}.png`)
                        item.checked = false;
                    })
                    this.FileList = data;
                }
            })
        },
        //查看文件夹，进入到该文件夹的目录
        viewFolder(item){
            this.BreadcrumbList.push({name: item.folderName,key: item.key});     //新增一项
            this.currentParentKey = item.key;       //改变当前所属父文件夹key
            this.getFolderList();                   //查看当前所属父文件夹key的列表
            this.getFileList();                     //重新获取文件集合
            this.uploadData =  {
                folderKey: this.currentParentKey,
                remarkName: "",
                permission: this.currentMenu
            }
            this.saveOpenRecord(item.key,1);//保存最近打开记录
        },
        //返回上一级文件夹
        goBack(){
            const BreadcrumbList = this.BreadcrumbList;
            if(BreadcrumbList.length == 1){
                return false;
            }
            BreadcrumbList.pop();                                                   //删除最后一项
            this.BreadcrumbList = BreadcrumbList;                                  
            this.currentParentKey = BreadcrumbList[BreadcrumbList.length-1].key     //返回后取最后一项的key作为当前所属父文件夹key
            this.getFolderList();                                                   //查看当前所属父文件夹key的列表
            this.getFileList();                                                     //重新获取文件集合
            this.uploadData =  {
                folderKey: this.currentParentKey,
                remarkName: "",
                permission: this.currentMenu
            }
        },
        //跳转指定文件夹
        goJump(index){
            const BreadcrumbList = this.BreadcrumbList;
            if(BreadcrumbList.length == 1){
                return false;
            }
            BreadcrumbList.splice(index + 1,BreadcrumbList.length);                 //删除指定文件夹后面的项
            this.BreadcrumbList = BreadcrumbList;                                  
            this.currentParentKey = BreadcrumbList[BreadcrumbList.length-1].key     //返回后取最后一项的key作为当前所属父文件夹key
            this.getFolderList();                                                   //查看当前所属父文件夹key的列表
            this.getFileList();                                                     //重新获取文件集合
            this.uploadData =  {
                folderKey: this.currentParentKey,
                remarkName: "",
                permission: this.currentMenu
            }
        },
        //上传文件功能
        uploading(){
            this.uploadLoading = true;
        },
        uploadOk(response){
            this.uploadLoading = false;
            const {isSuccess} = response;
            if(isSuccess){
                this.$message({message: "上传成功",type: 'success'});
                this.getFileList();
            }
        },
        uploadError(){
            this.uploadLoading = false;
            this.$message({message: '上传失败',type: 'warning'});
        },
        //新增文件夹
        addFolderOk(){
            const {currentMenu,addFolderName,currentParentKey} = this;
            if(!addFolderName){
                this.$message({message: '文件夹名称不能为空',type: 'warning'});
                return false
            }
            this.btnLoading = true;
            this.$axios.post(`${this.$diskUrl}/Folder/Add`,{
                folderName: addFolderName,
                parentKey: currentParentKey,
                permission: currentMenu
            }).then(res =>{
                const {isSuccess} = res;
                this.btnLoading = false;
                if(isSuccess){
                    this.$message({message: "新增文件夹成功",type: 'success'});
                    this.getFolderList();
                    this.addFolderVisible = false;
                    this.addFolderName = "";
                }
            })
        },
        addFolderCancel(){
            this.addFolderVisible = false;
            this.addFolderName = "";
        },

        //** 文件夹--更多操作 **//
        //重命名文件夹
        modifyFolder(item){
            this.modifyFolderVisible = true;
            this.modifyFolderKey = item.key;
            this.modifyFolderName = item.folderName;      
        },
        modifyFolderOk(){
            const {currentMenu,modifyFolderKey,modifyFolderName} = this;
            if(!modifyFolderName){
                this.$message({message: '文件夹名称不能为空',type: 'warning'});
                return false
            }
            this.btnLoading = true;
            this.$axios.post(`${this.$diskUrl}/Folder/Update`,{
                folderKey:  modifyFolderKey,
                folderName: modifyFolderName,
                permission: currentMenu
            }).then(res =>{
                const {isSuccess} = res;
                this.btnLoading = false;
                if(isSuccess){
                    this.$message({message: "重命名成功",type: 'success'});
                    this.getFolderList();
                    this.modifyFolderVisible = false;
                }
            })
        },

        //删除文件夹
        deleteFolder(item){
            this.$confirm('此操作将该文件夹删除,并将该文件夹内文件加入回收站,是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios.post(`${this.$diskUrl}/Folder/Delete?folderKey=${item.key}&type=0`).then(res =>{
                    const {isSuccess} = res;
                    if(isSuccess){
                        this.$message({message: "删除成功",type: 'success'});
                        this.getFolderList();
                    }
                })
            }).catch(() => {});
        },
        //查看文件夹属性
        viewProperty(item){
            this.propertyVisible = true;
            this.propertyLoading = true;
            this.$axios.get(`${this.$diskUrl}/Folder/GetFolderDetail?folderKey=${item.key}&type=0`).then(res =>{
                const {isSuccess, data} = res;
                this.propertyLoading = false;
                if(isSuccess){
                    this.propertyInfo = {
                        ...data,
                        location: this.BreadcrumbList.map(item => item.name).join('\\')
                    }
                }
            })
        },

        //** 文件--更多操作 **//
        //文件下载
        fileDownload(item){
            // window.open(item.fileUrl);
            const link = document.createElement('a');
            link.href = item.fileUrl;
            link.download = item.remarkName;
            link.target = '_blank';
            (document.body || document.documentElement).appendChild(link);
            link.click();
            link.remove();
        },
        //文件分享
        fileSharing(item){
            if(item.fileCategory == 'video' && !item.isFragmentationExis){
                this.$message({message: '视频处理中,请稍后再试!',type: 'warning'});
                return false;
            }
            this.shareVisible = true;
            this.shareFileKey = item.key;
        },        
        //重命名文件
        modifyFile(item){
            this.modifyFileVisible = true;
            this.modifyFileKey = item.key;
            this.modifyFileName = item.remarkName;
        },
        modifyFileOk(){
            const {currentMenu,modifyFileKey,modifyFileName} = this;
            if(!modifyFileName){
                this.$message({message: '文件名称不能为空',type: 'warning'});
                return false
            }
            this.btnLoading = true;
            this.$axios.post(`${this.$diskUrl}/File/Modify`,{
                fileKey:  modifyFileKey,
                remarkName: modifyFileName,
                permission: currentMenu,
            }).then(res =>{
                const {isSuccess} = res;
                this.btnLoading = false;
                if(isSuccess){
                    this.$message({message: "重命名成功",type: 'success'});
                    this.getFileList();
                    this.modifyFileVisible = false;
                }
            })
        },
        //文件转移/文件夹转移/合并
        transferFile(item){
            this.transferVisible = true;
            this.transferFileKey = [item.key];
            this.transferFolderKey = [];
            this.belongValue = [];//转移到的对象
            this.GetFolders([]);
        },
        shiftFolder(item){
            this.transferVisible = true;
            this.transferFileKey = [];
            this.transferFolderKey = [item.key];
            this.belongValue = [];//转移到的对象
            this.GetFolders([item.key]);
        },
        transferFinish(){
            const {belongValue,transferFileKey,transferFolderKey} = this;
            if(belongValue.length == 0){
                this.$message({message: '请选择转移到那个文件夹!',type: 'warning'});
                return false;
            }
            let toFolderKey = this.belongValue[this.belongValue.length - 1];
            if(this.transferFileKey.length > 0){
                this.btnLoading = true;
                this.$axios.post(`${this.$diskUrl}/File/BatchTransferFolder`,{
                    fileKeys: transferFileKey,
                    folderKey: toFolderKey,
                }).then((res) => {
                    const { isSuccess } = res;
                    this.btnLoading = false;
                    if (isSuccess) {
                        this.$message({ message: '文件转移成功', type: 'success' });
                        this.getFileList();
                        this.transferVisible = false;
                    }
                });
            }
            if(this.transferFolderKey.length > 0){
                this.btnLoading = true;
                this.$axios.post(`${this.$diskUrl}/Folder/BatchTransfer`,{
                    folderKeys:  transferFolderKey,
                    parentKey: toFolderKey,
                }).then(res =>{
                    const {isSuccess} = res;
                    this.btnLoading = false;
                    if(isSuccess){
                        this.$message({message: "文件夹转移成功",type: 'success'});
                        this.getFolderList();
                        this.transferVisible = false;
                    }
                })
            }
        },
        //查看文件详情 
        viewFileDetails(item){
            this.infoVisible = true;
            this.infoKey = item.key;
            this.saveOpenRecord(item.key,0);//保存最近打开记录
        },
        //文件加入回收站
        fileJoinRecycle(item){
            this.$confirm('此操作将文件加入回收站, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios.get(`${this.$diskUrl}/RecycleBin/AddRecycleBin?filekey=${item.key}&type=0`).then((res) => {
                    const { isSuccess } = res;
                    if (isSuccess) {
                        this.getFileList();
                        this.$message({ type: 'success', message: '操作成功!'});
                    }
                })
            }).catch(() => {});
        },


        //(我的空间)文件夹集合(树形结构)
        GetFolders(keyList){
            this.$axios.get(`${this.$diskUrl}/Folder/GetFolders?type=0`).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    const keySet = new Set(keyList);
                    for (const item of data) {
                        this.disposeTree(item,keySet)
                    }
                    this.folderTreeData = data;
                }
            });
        },
        belongChange(value){
            this.belongValue = value;
            console.log(this.belongValue);
        },
        disposeTree(node,keySet,isDisable) {
            if(isDisable){node.isDisable = isDisable};
            if(keySet.has(node.key)) {
                node.isDisable = true; //设置节点为禁用
            }
            if (node.children.length) {
                for (const item of node.children) {
                    this.disposeTree(item,keySet,node.isDisable)
                }
            } else {
                delete node.children; //删除子节点的 children 属性
            }
        },
        //保存最近打开记录(类型 0文件 1文件夹)
        saveOpenRecord(dataKey,type){
            let saveObj = {
                activePage: "我的空间",
                BreadcrumbList: this.BreadcrumbList,
            }
            this.$axios.post(`${this.$diskUrl}/Home/SaveOpenRecord`,{
                dataKey,type,
                url: JSON.stringify(saveObj),
            }).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    // console.log(isSuccess);
                }
            })
        },
        //收藏功能(类型 0文件 1文件夹)
        saveCollection(item,type){
            this.$axios.post(`${this.$diskUrl}/File/SaveCollection`,{
                relationKey: item.key,
                type: type,
            }).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    item.isCollection = !item.isCollection;
                    this.$message({ type: 'success', message: '操作成功!'});
                }
            })
        },
        //打标签功能
        openTagBox(item){
            // console.log(item.key);
            this.addTagVisible = true;
            this.signFileKey = [item.key];
            this.oldLables = item.lables;
        },
        addTagFinish(){
            this.addTagVisible = false;
            this.$message({ message: '添加成功', type: 'success'});
            this.getFileList();
        },
        delTag(key){
            this.$axios.get(`${this.$diskUrl}/Lable/DeleteLableRelation?key=${key}`).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    this.$message({ message: '删除成功', type: 'success'});
                    this.getFileList();
                }
            })
        },
        //新增批量操作
        batchOperation(type){
            if(type == 1){
                this.transferVisible = true;
                this.transferFileKey = this.checkFileKeys;
                this.transferFolderKey = this.checkFolderKeys;
                this.belongValue = [];//转移到的对象
                this.GetFolders(this.checkFolderKeys);
            }
            if(type == 2){
                this.addTagVisible = true;
                this.signFileKey = this.checkFileKeys;
                this.oldLables = [];
            }
            if(type == 3){
                this.$confirm('此操作将文件加入回收站, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    if(this.checkFileKeys.length > 0){
                        this.$axios.post(`${this.$diskUrl}/RecycleBin/BatchAddRecycleBin`,{
                            filekeys: this.checkFileKeys,
                            type: 0,
                        }).then((res) => {
                            const { isSuccess } = res;
                            if (isSuccess) {
                                this.getFileList();
                                this.$message({ type: 'success', message: '操作成功!'});
                            }
                        })
                    }
                    if(this.checkFolderKeys.length > 0){
                        this.$axios.post(`${this.$diskUrl}/RecycleBin/BatchAddFolderRecycleBin`,{
                            folderkeys: this.checkFolderKeys,
                            type: 0,
                        }).then((res) => {
                            const { isSuccess } = res;
                            if (isSuccess) {
                                this.getFolderList();
                            }
                        })
                    }
                }).catch(() => {});
            }
        },

        onComplete(resObj){
            this.$axios.post(`${this.$diskUrl}/File/SaveFileInfo`,{
                ...this.uploadData,
                ...resObj,
            }).then((res) => {
                const { isSuccess } = res;
                this.showBox = false;
                if (isSuccess) {
                    this.$message({message: "上传成功",type: 'success'});
                    this.getFileList();
                }
            })
        },
        //快捷拖拽转移
        dragstart(item,type){
            this.dragObj = item;
            this.dragType = type;
        },
        onDropShift(ev, item){
            ev.preventDefault();
            if(this.dragObj.key == item.key){
                console.error("不能拖拽给自己");
                return false;
            }
            if(this.dragType == 0){
                this.$confirm(`是否转移该文件：<strong style='color:var(--themeColor);'>${this.dragObj.remarkName}</strong>到文件夹：<strong style='color:var(--themeColor);'>${item.folderName}</strong>?`, '文件转移', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    dangerouslyUseHTMLString: true
                }).then(() => {
                    this.$axios.get(`${this.$diskUrl}/File/TransferFolder?fileKey=${this.dragObj.key}&folderKey=${item.key}`).then((res) => {
                        const { isSuccess } = res;
                        if (isSuccess) {
                            this.$message({ message: '转移成功', type: 'success' });
                            this.getFileList();
                        }
                    });
                }).catch(() => {});
            }
            if(this.dragType == 1){
                this.$confirm(`是否转移该文件夹：<strong style='color:var(--themeColor);'>${this.dragObj.folderName}</strong>到文件夹：<strong style='color:var(--themeColor);'>${item.folderName}</strong>?`, '文件夹转移', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    dangerouslyUseHTMLString: true
                }).then(() => {         
                    this.$axios.post(`${this.$diskUrl}/Folder/Update`,{
                        folderKey:  this.dragObj.key,
                        parentKey: item.key,
                        permission: this.currentMenu
                    }).then(res =>{
                        const {isSuccess} = res;
                        if(isSuccess){
                            this.$message({message: "转移成功",type: 'success'});
                            this.getFolderList();
                        }
                    })
                }).catch(() => {});
            }
        },

        initBreadcrumb(BreadcrumbList){
            if(BreadcrumbList){
                this.BreadcrumbList = BreadcrumbList;
                this.currentParentKey = BreadcrumbList[BreadcrumbList.length-1].key     //返回后取最后一项的key作为当前所属父文件夹key
                this.getFolderList();                                                   //查看当前所属父文件夹key的列表
                this.getFileList();                                                     //重新获取文件集合
                this.uploadData =  {
                    folderKey: this.currentParentKey,
                    remarkName: "",
                    permission: this.currentMenu
                }
            }else{
                this.getFolderList();   //获取文件夹集合
                this.getFileList();     //获取文件集合
            }
        },
    },
    mounted() {

        this.userInfo = JSON.parse(localStorage.userInfo);

    },
};
</script>

<style lang="scss" scoped>
.pageBox{
    padding: 20px;
    .header{
        margin-bottom: 20px;
        overflow: hidden;
        .uploadBtn{
            float: left;
        }
        .newFolder{
            float: left;
            margin-left: 20px;
        }
        .searchBox{
            width: 500px;
            float: right;
        }
    }
    .contentBox{
        height: calc(100vh - 180px);
        background: #FFFFFF;
        box-shadow: 0px 1px 6px 1px rgba(0, 0, 0, 0.16);
        .topHeader{
            height: 45px;
            line-height: 45px;
            background: #FBFBFB;
            padding: 0 15px 0 30px;
            position: relative;
            display: flex;
            justify-content: space-between;
            .goBack{
                position: absolute;
                top: 0;
                bottom: 0; 
                left: 30px;
                margin: auto;
                cursor: pointer;
            }
            .breadcrumb{
                margin-left: 50px;
                display: inline-block;
                line-height: 45px;
                .spanItem{
                    cursor: pointer;
                    font-size: 16px;
                    font-weight: bold;
                    color: #3A3A3A;
                }
                /deep/.el-breadcrumb__separator[class*=icon] {
                    margin: 0 10px;
                    font-size: 16px;
                    font-weight: bold;
                    color: #9C9C9C;
                }
            }
        }
        .tableHeader{
            height: 45px;
            line-height: 45px;
            background: #F3F3F3;
            font-size: 16px;
            font-weight: bold;
            color: #3A3A3A;
            display: flex;
            padding: 0 20px;
        }
        .tableBody{
            padding-bottom: 10px;
            height: calc(100vh - 280px);
            overflow: overlay;
            .FolderItem,.FileItem{
                padding: 0 10px;
                display: flex;
                height: 50px;
                line-height: 50px;
                border-bottom: 1px solid #EEEEEE;
                font-size: 14px;
                color: #1A2745;
                .imgIcon{
                    vertical-align: middle;
                    padding: 0 20px 0 40px;
                }
            }
        }
        .tableHeader,.FolderItem,.FileItem{
            position: relative;
            .name{
                width: calc(100% - 650px);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                .collectIcon{
                    position: absolute;
                    cursor: pointer;
                    color: var(--themeColor);
                    top: 17px;
                }
                .el-icon-star-on{
                    left: 33px;
                    font-size: 20px;
                }
                .el-icon-star-off{
                    left: 35px;
                    font-size: 16px;
                    display: none;
                }
                &:hover .el-icon-star-off{
                    display: inline-block;
                }
                .openTag{
                    cursor: pointer;
                    display: none;
                }
                &:hover .openTag{
                    display: inline-block;
                }
            }
            .size{
                width: 130px;
                padding: 0 10px;
                text-align: center;
            }
            .creator{
                width: 130px;
                padding: 0 10px;
                text-align: center;
            }
            .time{
                width: 180px;
                padding: 0 10px;
                text-align: center;
            }
            .moreOption{
                width: 130px;
                padding: 0 10px;
                text-align: center;
            }
        }
    }
}
.infoBox{
    box-sizing: border-box;
    position: relative;
    .infoItem{
        line-height: 32px;
        margin-bottom: 10px;
        display: flex;
        .label{
            width: 80px;
            text-align: right;
            color: #9a9a9a;
            font-weight: bold;
        }
        .value{
            width: calc(100% - 100px);
            margin-left: 20px;
            color: #000;
        }
    }
}
.FolderItem:hover{
    background-color: #fafafa;
}
.FileItem:hover{
    background-color: #fafafa;
}
.checkedBgStyle{
    background-color: #fcfcfc;
}
</style>