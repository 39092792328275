<template>
    <div>
        <div class="countryGroup" v-loading="CountryCodeLoading">
            <div class="header">
                <el-checkbox v-model="checkAll" @change="checkAllChange">全球({{totalItems}})</el-checkbox>
                <div>
                    <el-checkbox :class="{'active':showItem == '北美洲'}" v-model="checkAllFour" @change="checkAllFourChange">
                        北美洲({{NorthAmericaList.length}})
                    </el-checkbox>
                    <el-checkbox :class="{'active':showItem == '亚洲'}" v-model="checkAllOne" @change="checkAllOneChange">
                        亚洲({{AsiaList.length}})
                    </el-checkbox>
                    <el-checkbox :class="{'active':showItem == '欧洲'}" v-model="checkAllTwo" @change="checkAllTwoChange">
                        欧洲({{EuropeList.length}})
                    </el-checkbox>
                    <el-checkbox :class="{'active':showItem == '南美洲'}" v-model="checkAllFive" @change="checkAllFiveChange">
                        南美洲({{SouthAmericaList.length}})
                    </el-checkbox>
                    <el-checkbox :class="{'active':showItem == '非洲'}" v-model="checkAllThree" @change="checkAllThreeChange">
                        非洲({{AfricaList.length}})
                    </el-checkbox>
                    <el-checkbox :class="{'active':showItem == '大洋洲'}" v-model="checkAllSix" @change="checkAllSixChange">
                        大洋洲({{OceaniaList.length}})
                    </el-checkbox>
                    <el-checkbox :class="{'active':showItem == '南极洲'}" v-model="checkAllSeven" @change="checkAllSevenChange">
                        南极洲({{AntarcticaList.length}})
                    </el-checkbox>
                </div>
            </div>
            <div class="list" v-if="NorthAmericaList.length != 0 && showItem == '北美洲'">
                <el-checkbox-group v-model="checkedCountryFour" @change="checkedCountryFourChange" style="overflow: hidden;">
                    <div class="option" v-for="(item,index) in NorthAmericaList" :key="index">
                        <el-checkbox  :label="item.countryCn" :key="index">
                            <img :src="item.nationalFlag" alt="" />
                            <span>{{item.countryCn}}</span>
                        </el-checkbox>
                    </div>
                </el-checkbox-group>
            </div>
            <div class="list" v-if="AsiaList.length != 0 && showItem == '亚洲'">
                <el-checkbox-group v-model="checkedCountryOne" @change="checkedCountryOneChange" style="overflow: hidden;">
                    <div class="option" v-for="(item,index) in AsiaList" :key="index">
                        <el-checkbox  :label="item.countryCn" :key="index">
                            <img :src="item.nationalFlag" alt="" />
                            <span>{{item.countryCn}}</span>
                        </el-checkbox>
                    </div>
                </el-checkbox-group>
            </div>
            <div class="list" v-if="EuropeList.length != 0 && showItem == '欧洲'">
                <el-checkbox-group v-model="checkedCountryTwo" @change="checkedCountryTwoChange" style="overflow: hidden;">
                    <div class="option" v-for="(item,index) in EuropeList" :key="index">
                        <el-checkbox  :label="item.countryCn" :key="index">
                            <img :src="item.nationalFlag" alt="" />
                            <span>{{item.countryCn}}</span>
                        </el-checkbox>
                    </div>
                </el-checkbox-group>
            </div>
            <div class="list" v-if="SouthAmericaList.length != 0 && showItem == '南美洲'">
                <el-checkbox-group v-model="checkedCountryFive" @change="checkedCountryFiveChange" style="overflow: hidden;">
                    <div class="option" v-for="(item,index) in SouthAmericaList" :key="index">
                        <el-checkbox  :label="item.countryCn" :key="index">
                            <img :src="item.nationalFlag" alt="" />
                            <span>{{item.countryCn}}</span>
                        </el-checkbox>
                    </div>
                </el-checkbox-group>
            </div>
            <div class="list" v-if="AfricaList.length != 0 && showItem == '非洲'">
                <el-checkbox-group v-model="checkedCountryThree" @change="checkedCountryThreeChange" style="overflow: hidden;">
                    <div class="option" v-for="(item,index) in AfricaList" :key="index">
                        <el-checkbox  :label="item.countryCn" :key="index">
                            <img :src="item.nationalFlag" alt="" />
                            <span>{{item.countryCn}}</span>
                        </el-checkbox>
                    </div>
                </el-checkbox-group>
            </div>
            <div class="list" v-if="OceaniaList.length != 0 && showItem == '大洋洲'">
                <el-checkbox-group v-model="checkedCountrySix" @change="checkedCountrySixChange" style="overflow: hidden;">
                    <div class="option" v-for="(item,index) in OceaniaList" :key="index">
                        <el-checkbox  :label="item.countryCn" :key="index">
                            <img :src="item.nationalFlag" alt="" />
                            <span>{{item.countryCn}}</span>
                        </el-checkbox>
                    </div>
                </el-checkbox-group>
            </div>
            <div class="list" v-if="AntarcticaList.length != 0 && showItem == '南极洲'">
                <el-checkbox-group v-model="checkedCountrySeven" @change="checkedCountrySevenChange" style="overflow: hidden;">
                    <div class="option" v-for="(item,index) in AntarcticaList" :key="index">
                        <el-checkbox  :label="item.countryCn" :key="index">
                            <img :src="item.nationalFlag" alt="" />
                            <span>{{item.countryCn}}</span>
                        </el-checkbox>
                    </div>
                </el-checkbox-group>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["countryList"],
    data() {
        return {
            showItem: "",
            totalItems: 0,
            checkAll: true, //所有国家全选(默认全选)
            checkAllOne: false, //亚洲全选
            checkedCountryOne: [],
            AsiaList: [],
            checkAllTwo: false, //欧洲全选
            checkedCountryTwo: [],
            EuropeList: [],
            checkAllThree: false, //非洲全选
            checkedCountryThree: [],
            AfricaList: [],
            checkAllFour: false, //北美洲全选
            checkedCountryFour: [],
            NorthAmericaList: [],
            checkAllFive: false, //南美洲全选
            checkedCountryFive: [],
            SouthAmericaList: [],
            checkAllSix: false, //大洋洲全选
            checkedCountrySix: [],
            OceaniaList: [],
            checkAllSeven: false, //南极洲全选
            checkedCountrySeven: [],
            AntarcticaList: [],
            CountryCodeLoading: true, //国家码的loading
        };
    },
    methods: {
        //获取国家配置
        getCountryCode() {
            this.CountryCodeLoading = true;
            this.$axios.get(`${this.$clueUrl}/api/AIRec/GetCountry`).then((res=>{
                const { isSuccess, data } = res;
                this.CountryCodeLoading = false;
                if (isSuccess) {
                    // console.log(data);
                    data.forEach((item) => {
                        if (item.continentCn == "亚洲") {
                            this.AsiaList = item.country;
                        } else if (item.continentCn == "欧洲") {
                            this.EuropeList = item.country;
                        } else if (item.continentCn == "非洲") {
                            this.AfricaList = item.country;
                        } else if (item.continentCn == "北美洲") {
                            this.NorthAmericaList = item.country;
                        } else if (item.continentCn == "南美洲") {
                            this.SouthAmericaList = item.country;
                        } else if (item.continentCn == "大洋洲") {
                            this.OceaniaList = item.country;
                        } else if (item.continentCn == "南极洲") {
                            this.AntarcticaList = item.country;
                        }
                    });
                    this.totalItems = this.NorthAmericaList.length + this.AsiaList.length + this.EuropeList.length + this.SouthAmericaList.length + this.AfricaList.length + this.OceaniaList.length + this.AntarcticaList.length;
                    if(this.countryList){
                        if(this.countryList.length == 0){
                            //获取国家码列表之后，所有国家默认全选
                            this.checkAllChange(true);
                        }else{
                            this.countryList.forEach(ele =>{
                                this.AsiaList.forEach(item =>{
                                    if(item.countryCn == ele){
                                        this.checkedCountryOne.push(ele);
                                    }
                                });
                                this.EuropeList.forEach(item =>{
                                    if(item.countryCn == ele){
                                        this.checkedCountryTwo.push(ele);
                                    }
                                })
                                this.AfricaList.forEach(item =>{
                                    if(item.countryCn == ele){
                                        this.checkedCountryThree.push(ele);
                                    }
                                })
                                this.NorthAmericaList.forEach(item =>{
                                    if(item.countryCn == ele){
                                        this.checkedCountryFour.push(ele);
                                    }
                                })
                                this.SouthAmericaList.forEach(item =>{
                                    if(item.countryCn == ele){
                                        this.checkedCountryFive.push(ele);
                                    }
                                })
                                this.OceaniaList.forEach(item =>{
                                    if(item.countryCn == ele){
                                        this.checkedCountrySix.push(ele);
                                    }
                                })
                                this.AntarcticaList.forEach(item =>{
                                    if(item.countryCn == ele){
                                        this.checkedCountrySeven.push(ele);
                                    }
                                })
                            })
                            this.checkAll = false;
                            this.showItem = '北美洲';                            
                        }
                    }else{
                        //获取国家码列表之后，所有国家默认全选
                        this.checkAllChange(true);
                    }
                }
            }))
            /* this.$axios.get(`${this.$searchUrl}/Trade/IsoCountry`).then((res) => {
                const { isSuccess, data } = res;
                this.CountryCodeLoading = false;
                if (isSuccess) {
                    // console.log(data);
                    data.forEach((item) => {
                        if (item.continentCn == "亚洲") {
                            this.AsiaList = item.country;
                        } else if (item.continentCn == "欧洲") {
                            this.EuropeList = item.country;
                        } else if (item.continentCn == "非洲") {
                            this.AfricaList = item.country;
                        } else if (item.continentCn == "北美洲") {
                            this.NorthAmericaList = item.country;
                        } else if (item.continentCn == "南美洲") {
                            this.SouthAmericaList = item.country;
                        } else if (item.continentCn == "大洋洲") {
                            this.OceaniaList = item.country;
                        } else if (item.continentCn == "南极洲") {
                            this.AntarcticaList = item.country;
                        }
                    });
                    this.totalItems = this.NorthAmericaList.length + this.AsiaList.length + this.EuropeList.length + this.SouthAmericaList.length + this.AfricaList.length + this.OceaniaList.length + this.AntarcticaList.length;
                    if(this.countryList){
                        if(this.countryList.length == 0){
                            //获取国家码列表之后，所有国家默认全选
                            this.checkAllChange(true);
                        }else{
                            this.countryList.forEach(ele =>{
                                this.AsiaList.forEach(item =>{
                                    if(item.countryCn == ele){
                                        this.checkedCountryOne.push(ele);
                                    }
                                });
                                this.EuropeList.forEach(item =>{
                                    if(item.countryCn == ele){
                                        this.checkedCountryTwo.push(ele);
                                    }
                                })
                                this.AfricaList.forEach(item =>{
                                    if(item.countryCn == ele){
                                        this.checkedCountryThree.push(ele);
                                    }
                                })
                                this.NorthAmericaList.forEach(item =>{
                                    if(item.countryCn == ele){
                                        this.checkedCountryFour.push(ele);
                                    }
                                })
                                this.SouthAmericaList.forEach(item =>{
                                    if(item.countryCn == ele){
                                        this.checkedCountryFive.push(ele);
                                    }
                                })
                                this.OceaniaList.forEach(item =>{
                                    if(item.countryCn == ele){
                                        this.checkedCountrySix.push(ele);
                                    }
                                })
                                this.AntarcticaList.forEach(item =>{
                                    if(item.countryCn == ele){
                                        this.checkedCountrySeven.push(ele);
                                    }
                                })
                            })
                            this.checkAll = false;
                            this.showItem = '北美洲';                            
                        }
                    }else{
                        //获取国家码列表之后，所有国家默认全选
                        this.checkAllChange(true);
                    }
                }
            }); */
        },
        //所有国家是否全选
        checkAllChange(val) {
            this.checkAllOne = val;
            this.checkAllTwo = val;
            this.checkAllThree = val;
            this.checkAllFour = val;
            this.checkAllFive = val;
            this.checkAllSix = val;
            this.checkAllSeven = val;
            this.checkAllOneChange(val);
            this.checkAllTwoChange(val);
            this.checkAllThreeChange(val);
            this.checkAllFourChange(val);
            this.checkAllFiveChange(val);
            this.checkAllSixChange(val);
            this.checkAllSevenChange(val);
            this.showItem = '北美洲';
        },
        //判断所有国家是否全选
        allCountryIsChoose(){
            this.checkAll = this.checkAllOne && this.checkAllTwo && this.checkAllThree && this.checkAllFour && this.checkAllFive && this.checkAllSix && this.checkAllSeven;
            this.onSave();
        },
        //亚洲
        checkAllOneChange(val) {
            this.showItem = '亚洲';
            const list = [];
            this.AsiaList.forEach((item) => {
                list.push(item.countryCn);
            });
            this.checkedCountryOne = val ? list : [];
            this.allCountryIsChoose();
        },
        checkedCountryOneChange(value) {
            let checkedCount = value.length;
            this.checkAllOne = checkedCount === this.AsiaList.length;
            this.allCountryIsChoose();
        },
        //欧洲
        checkAllTwoChange(val) {
            this.showItem = '欧洲';
            const list = [];
            this.EuropeList.forEach((item) => {
                list.push(item.countryCn);
            });
            this.checkedCountryTwo = val ? list : [];
            this.allCountryIsChoose();
        },
        checkedCountryTwoChange(value) {
            let checkedCount = value.length;
            this.checkAllTwo = checkedCount === this.EuropeList.length;
            this.allCountryIsChoose();
        },
        //非洲
        checkAllThreeChange(val) {
            this.showItem = '非洲';
            const list = [];
            this.AfricaList.forEach((item) => {
                list.push(item.countryCn);
            });
            this.checkedCountryThree = val ? list : [];
            this.allCountryIsChoose();
        },
        checkedCountryThreeChange(value) {
            let checkedCount = value.length;
            this.checkAllThree = checkedCount === this.AfricaList.length;
            this.allCountryIsChoose();
        },
        //北美洲
        checkAllFourChange(val) {
            this.showItem = '北美洲';
            const list = [];
            this.NorthAmericaList.forEach((item) => {
                list.push(item.countryCn);
            });
            this.checkedCountryFour = val ? list : [];
            this.allCountryIsChoose();
        },
        checkedCountryFourChange(value) {
            let checkedCount = value.length;
            this.checkAllFour = checkedCount === this.NorthAmericaList.length;
            this.allCountryIsChoose();
        },
        //南美洲
        checkAllFiveChange(val) {
            this.showItem = '南美洲';
            const list = [];
            this.SouthAmericaList.forEach((item) => {
                list.push(item.countryCn);
            });
            this.checkedCountryFive = val ? list : [];
            this.allCountryIsChoose();
        },
        checkedCountryFiveChange(value) {
            let checkedCount = value.length;
            this.checkAllFive = checkedCount === this.SouthAmericaList.length;
            this.allCountryIsChoose();
        },
        //大洋洲
        checkAllSixChange(val) {
            this.showItem = '大洋洲';
            const list = [];
            this.OceaniaList.forEach((item) => {
                list.push(item.countryCn);
            });
            this.checkedCountrySix = val ? list : [];
            this.allCountryIsChoose();
        },
        checkedCountrySixChange(value) {
            let checkedCount = value.length;
            this.checkAllSix = checkedCount === this.OceaniaList.length;
            this.allCountryIsChoose();
        },
        //南极洲
        checkAllSevenChange(val) {
            this.showItem = '南极洲';
            const list = [];
            this.AntarcticaList.forEach((item) => {
                list.push(item.countryCn);
            });
            this.checkedCountrySeven = val ? list : [];
            this.allCountryIsChoose();
        },
        checkedCountrySevenChange(value) {
            let checkedCount = value.length;
            this.checkAllSeven = checkedCount === this.AntarcticaList.length;
            this.allCountryIsChoose();
        },

        //点击保存按钮提交数据
        onSave() {
            let countryList = [];
            const mergeArr = this.checkedCountryOne.concat(this.checkedCountryTwo,this.checkedCountryThree,this.checkedCountryFour,this.checkedCountryFive,this.checkedCountrySix,this.checkedCountrySeven);
            var newArr = Array.from(new Set(mergeArr));
            if(this.checkAll){
                countryList = [];
            }else{
                countryList = newArr;
            }
            // console.log(countryList);
            this.$emit("onSave",countryList);
        },

    },
    mounted() {
        this.getCountryCode(); //获取国家配置
    },
};
</script>

<style lang="scss" scoped>
.countryGroup{
    .header{
        .el-checkbox{
            padding: 5px;
        }
        .active{
            background: #EAEAEA;
        }
    }
    .list{
        .el-checkbox-group{
            padding: 0 5px;
            background: #EAEAEA;
        }
        .option{
            width: 20%;
            float: left;
            height: 30px;
            padding: 5px 0;
            box-sizing: border-box;
            img{
                vertical-align: middle;
                margin-right: 10px;
            }
        }
    }
}
</style>