<template>
    <div class="setBox">
        <div class="leftAside">
            <div class="oneItem" :class="{ 'active': activePage == '客户设置' }" @click="activePage = '客户设置'">客户设置</div>
            <div class="oneItem" :class="{ 'active': activePage == '邮箱设置' }" @click="activePage = '邮箱设置'">邮箱设置</div>
            <div class="oneItem" :class="{ 'active': activePage == '商机设置' }" @click="activePage = '商机设置'">商机设置</div>
            <div class="oneItem" :class="{ 'active': activePage == '产品设置' }" @click="activePage = '产品设置'">产品设置</div>
            <div class="oneItem" :class="{ 'active': activePage == '订单设置' }" @click="activePage = '订单设置'">订单设置</div>
            <div class="oneItem" :class="{ 'active': activePage == '系统设置' }" @click="activePage = '系统设置'">系统设置</div>
            <!-- <div class="oneItem" :class="{ 'active': activePage == '字段设置' }" @click="activePage = '字段设置'">字段设置</div> -->
            <div v-if="isAdmin" class="oneItem" :class="{ 'active': activePage == '目标设置' }" @click="activePage = '目标设置'">目标设置</div>
        </div>
        <div class="content">
            <Customer v-if="activePage == '客户设置'" />
            <Email v-if="activePage == '邮箱设置'" />
            <Business v-if="activePage == '商机设置'" />
            <Product v-if="activePage == '产品设置'" />
            <Order v-if="activePage == '订单设置'" />
            <System v-if="activePage == '系统设置'" />
            <DynamicField v-if="activePage == '字段设置'" />
            <SetTarget v-if="activePage == '目标设置'" />
        </div>
    </div>
</template>

<script>

import Customer from "./Customer.vue";  //客户设置
import Email from "./Email.vue";        //邮箱设置
import Business from "./Business.vue";  //商机设置
import Product from "./Product.vue";    //产品设置
import Order from "./Order.vue";        //订单设置
import System from "./System.vue";      //系统设置
import DynamicField from "./DynamicField.vue";  //字段设置
import SetTarget from "./SetTarget.vue" //目标设置

export default {
    components: { Customer, Email, Business, Product, Order, System, DynamicField, SetTarget },
    data() {
        return {
            activePage: "客户设置",
            isAdmin: false,
        };
    },
    methods: {},
    mounted() {
        const { type } = this.$route.query;
        if (type === 'mail') {
            this.activePage = '邮箱设置';
        }
        if (type === 'business'){
            this.activePage = '商机设置';
        }
        if (type === 'product'){
            this.activePage = '产品设置';
        }
        if (type === 'order'){
            this.activePage = '订单设置';
        }
        if (type === 'system'){
            this.activePage = '系统设置';
        }
        if (type === 'target'){
            this.activePage = '目标设置';
        }
        this.isAdmin = JSON.parse(localStorage.userInfo).isAdmin;
    },
};
</script>

<style lang="scss" scoped>
.setBox {
    background: #FFFFFF;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.16);
    height: calc(100vh - 80px);
    display: flex;

    .leftAside {
        width: 192px;
        border-right: 2px solid #eee;

        .oneItem {
            height: 50px;
            line-height: 50px;
            text-align: center;
            color: #092338;
            cursor: pointer;
        }

        .active {
            color: #FFFFFF;
            background: var(--themeColor);
        }
    }

    .content {
        width: calc(100% - 194px);
    }
}
</style>