<template>
    <div>
        <InterfaceName :interfaceName="interfaceName" />
        <InterfaceSpe :requestUrl="requestUrl" :requestMethod="requestMethod" />
        <InputParam :inputTableData="inputTableData" />
        <ReturnParam :returnObj="returnObj" />
    </div>
</template>

<script>
import InterfaceName from "../Acomponents/InterfaceName.vue";
import InterfaceSpe from "../Acomponents/InterfaceSpe.vue";
import InputParam from "../Acomponents/InputParam.vue";
import ReturnParam from "../Acomponents/ReturnParam.vue";

export default {
    components: { InterfaceName, InterfaceSpe, InputParam, ReturnParam },
    data() {
        return {
            interfaceName: "保存订单",
            requestUrl: "https://crm.qixinerp.com/open/Order/Save",
            requestMethod: "POST",
            inputTableData: [],
            returnObj: {},
        };
    },
    methods: {},
    mounted() {
        this.inputTableData = [
            {paramName: "order", valueType: "object", explain: "订单基础信息", required: true},
            {paramName: "order:orderKey", valueType: "string", explain: "订单Key", required: true},
            {paramName: "order:orderName", valueType: "string", explain: "订单名称", required: true},
            {paramName: "order:currency", valueType: "string", explain: "币种", required: true},
            {paramName: "order:orderDate", valueType: "string", explain: "订单日期", required: true},
            {paramName: "order:customerKey", valueType: "string", explain: "客户Key", required: true},
            {paramName: "order:customerName", valueType: "string", explain: "客户名称", required: true},
            {paramName: "order:contactKey", valueType: "string", explain: "联系人Key", required: false},
            {paramName: "order:contactsName", valueType: "string", explain: "联系人名称", required: false},
            {paramName: "order:contactsCall", valueType: "string", explain: "联系电话", required: false},
            {paramName: "order:contactsEmail", valueType: "string", explain: "联系邮箱", required: false},
            {paramName: "order:customerAddress", valueType: "string", explain: "客户地址", required: false},
            {paramName: "order:priceTerms", valueType: "string", explain: "价格条款", required: false},
            {paramName: "order:paymentMethod", valueType: "string", explain: "付款方式", required: false},
            {paramName: "order:deliveryDate", valueType: "string", explain: "交货期", required: false},
            {paramName: "order:bankInfo", valueType: "string", explain: "银行信息", required: false},
            {paramName: "order:remarks", valueType: "string", explain: "备注", required: false},
            {paramName: "order:quoteKey", valueType: "string", explain: "报价单key", required: false},
            {paramName: "order:businessChanceKey", valueType: "string", explain: "商机Key", required: false},
            {paramName: "order:productTotalVolume", valueType: "string", explain: "产品包装体积总计", required: false},
            {paramName: "order:productTotalWeight", valueType: "string", explain: "产品包装毛重总计", required: false},
            {paramName: "order:portOfShipment", valueType: "string", explain: "出运口岸", required: false},
            {paramName: "order:portOfDestination", valueType: "string", explain: "目的口岸", required: false},
            {paramName: "order:typeOfShipping", valueType: "string", explain: "运输方式", required: false},
            {paramName: "order:handlerKey", valueType: "string", explain: "处理人key", required: true},
            {paramName: "order:performanceAttributionKey", valueType: "string", explain: "业绩归属人key", required: true},
            {paramName: "order:expectedReceiptDate", valueType: "string", explain: "预计到账日期", required: false},
            {paramName: "order:organizationKey", valueType: "array", explain: "业绩归属组织", required: true},
            
            {paramName: "orderProducts", valueType: "array", explain: "订单产品", required: true},
            {paramName: "orderProducts:productKey", valueType: "string", explain: "产品key", required: true},
            {paramName: "orderProducts:productNo", valueType: "string", explain: "产品编号", required: true},
            {paramName: "orderProducts:productName", valueType: "string", explain: "产品名称", required: true},
            {paramName: "orderProducts:specifications", valueType: "string", explain: "产品规格", required: true},
            {paramName: "orderProducts:productDescription", valueType: "string", explain: "产品描述", required: true},
            {paramName: "orderProducts:totalPrice", valueType: "number", explain: "总价格", required: true},
            {paramName: "orderProducts:num", valueType: "integer", explain: "数量", required: true},
            {paramName: "orderProducts:measureUnit", valueType: "string", explain: "计量单位", required: true},
            {paramName: "orderProducts:otherExpenses", valueType: "number", explain: "其它费用", required: false},
            {paramName: "orderProducts:currency", valueType: "string", explain: "货币", required: true},
            {paramName: "orderProducts:remarks", valueType: "string", explain: "备注", required: false},
            {paramName: "orderProducts:exchangeRate", valueType: "string", explain: "汇率", required: true},
            {paramName: "orderProducts:cnyTotalPrice", valueType: "number", explain: "人民币总价格", required: true},
            {paramName: "orderProducts:unitPrice", valueType: "number", explain: "单价", required: true},

            {paramName: "tradeExpenses", valueType: "array", explain: "贸易费用", required: false},
            {paramName: "tradeExpenses:project", valueType: "string", explain: "项目", required: false},
            {paramName: "tradeExpenses:totalPrice", valueType: "number", explain: "金额", required: false},
            {paramName: "tradeExpenses:currency", valueType: "string", explain: "货币", required: false},
            {paramName: "tradeExpenses:exchangeRate", valueType: "string", explain: "汇率", required: false},
            {paramName: "tradeExpenses:cnyTotalPrice", valueType: "number", explain: "人民币金额", required: false},
            {paramName: "tradeExpenses:remarks", valueType: "string", explain: "备注", required: false},

            {paramName: "suppliers", valueType: "array", explain: "供应商", required: false},
            {paramName: "followUps", valueType: "array", explain: "跟进人列表", required: false},
            {paramName: "followUps:key", valueType: "string", explain: "主键", required: false},
            {paramName: "followUps:ownerName", valueType: "string", explain: "跟进人", required: false},
            {paramName: "followUps:ownerKey", valueType: "string", explain: "跟进人key", required: false},
            {paramName: "followUps:startTime", valueType: "string", explain: "开始跟进时间", required: false},
            {paramName: "followUps:endTime", valueType: "string", explain: "结束跟进时间", required: false},
            {paramName: "followUps:proportion", valueType: "number", explain: "佣金比率", required: false},
            {paramName: "followUps:remarks", valueType: "string", explain: "备注", required: false},
        ];
        this.returnObj = {
            isSuccess: true,
            errorMessage: null,
            data: true,
        };                  
    },
};
</script>

<style lang="scss" scoped>

</style>