<template>
  <div class="okk">
    <div class="firm">
      <div style="padding-bottom: 10px">
        <enterprise-info />
        <div v-if="done">
          <filter-criteria />
          <summary-card />
          <tab-record-country-enterprise />
          <!-- <trend-analyse /> -->
          <new-trend-analyse />
          <cycle-analyse />
          <compete-analyse v-if="0" />
          <compete-analyse-new v-if="1" />
          <tree-rect-new />
          <code-analyse/>
          <nav-bar />
          <!-- 六位编码-start -->
          <encode-analyse-6 />
          <encode-analyse-x-country x="6" kanji="六" anchor="HSBM6C" firstHTTP="/search/Credit/Ranking/HsCode" secondHTTP="/search/Credit/Ranking/Country" />
          <encode-analyse-x-country-company x="6" kanji="六" anchor="HSBM6CC" firstHTTP="/search/Credit/Ranking/HsCode" secondHTTP="/search/Credit/Ranking/Country" thirdHTTP="/search/Credit/Ranking/Exporter" />
          <encode-anlyse-x-company x="6" kanji="六" anchor="HSBM6CCC" firstHTTP="/search/Credit/Ranking/HsCode" secondHTTP="/search/Credit/Ranking/Exporter" />
          <!-- 六位编码-end -->
          <!-- 四位编码-start -->
          <template v-if="false">
            <encode-analyse-4 />
            <encode-analyse-x-country x="4" kanji="四" anchor="HSBM4C" firstHTTP="/search/Credit/Ranking/HsCode4" secondHTTP="/search/Credit/Ranking/Country" />
            <encode-analyse-x-country-company x="4" kanji="四" anchor="HSBM4CC" firstHTTP="/search/Credit/Ranking/HsCode4" secondHTTP="/search/Credit/Ranking/Country" thirdHTTP="/search/Credit/Ranking/Exporter" />
            <encode-anlyse-x-company x="4" kanji="四" anchor="HSBM4CCC" firstHTTP="/search/Credit/Ranking/HsCode4" secondHTTP="/search/Credit/Ranking/Exporter" />
          </template>
          <!-- 四位编码-end -->
          <!-- 二位编码-start -->
          <template v-if="false">
            <encode-analyse-2 />
            <encode-analyse-x-country x="2" kanji="二" anchor="HSBM2C" firstHTTP="/search/Credit/Ranking/HsCode2" secondHTTP="/search/Credit/Ranking/Country" />
            <encode-analyse-x-country-company x="2" kanji="二" anchor="HSBM2CC" firstHTTP="/search/Credit/Ranking/HsCode2" secondHTTP="/search/Credit/Ranking/Country" thirdHTTP="/search/Credit/Ranking/Exporter" />
            <encode-anlyse-x-company x="2" kanji="二" anchor="HSBM2CCC" firstHTTP="/search/Credit/Ranking/HsCode2" secondHTTP="/search/Credit/Ranking/Exporter" />
          </template>
          <!-- 二位编码-end -->
          <!-- SITC-start -->
          <aborad-sitc v-if="false" />
          <sitc-encode-rank />
          <sitc-encode-country />
          <sitc-encode-country-company />
          <sitc-encode-comapny />
          <!-- SITC-end -->
          <country-analyse />
          <country-encode />
          <country-encode-company />
          <country-company />
          <country-company-encode />
          <state-analyse />
          <company-analyse />
          <imp-exp-con-code v-if="false" />
          <company-encode />
          <imp-code-con-exp v-if="false" />
          <company-encode-country />
          <imp-con-code-exp v-if="false" />
          <company-import />
          <company-import-encode />
          <company-export />
          <company-export-encode />
          <port-load />
          <port-domestic />
          <port-unload />
          <port-abroad />
          <anchor v-if="anchorReady" />
          <!---->
          <comment v-show="showCommentDiv" v-model="comment.total" @close="showCommentDiv = false" />
          <div v-if="false" class="comment-fix">
            <el-badge :value="comment.total" :hidden="comment.total === 0" :max="99">
              <img @click="showCommentDiv = !showCommentDiv" src="@/firm/images/company/new/comment.png" />
            </el-badge>
          </div>
          <img src="@/firm/images/company/new/totop.png" @click="handleTopClick" class="to-top" />
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import axios from "@/firm/plugins/axios";
import { getUserAgentInfo } from "@/firm/utils/broswer";

import enterpriseInfo from "./views/enterprise-info/index.vue";
import filterCriteria from "./views/filter-criteria.vue";
import summaryCard from "./views/summary-card.vue";

import navBar from "./views/nav/nav-bar.vue";

//import trendAnalyse from "./views/trend-analyse.vue";
import newTrendAnalyse from './views/new-trend.vue'
import cycleAnalyse from "./views/cycle-analyse.vue";
import countryAnalyse from "./views/country-analyse.vue";
import stateAnalyse from "./views/state-analyse.vue";
import aboradSitc from "./views/enterprise-info/abroad/abroad-sitc.vue";
import CodeAnalyse from './views/code-analyse/Index.vue'
import encodeAnalyse6 from "./views/tab-encode/encode-analyse-6.vue";
import encodeAnalyseXCountry from "./views/tab-encode/encode-analyse-x-country.vue";
import encodeAnalyseXCountryCompany from "./views/tab-encode/encode-analyse-x-country-company.vue";
import encodeAnlyseXCompany from "./views/tab-encode/encode-analyse-x-comapny.vue";
import encodeAnalyse4 from "./views/tab-encode/encode-analyse-4.vue";
import encodeAnalyse2 from "./views/tab-encode/encode-analyse-2.vue";
import sitcEncodeRank from "./views/tab-sitc/sitc-encode-rank.vue";
import sitcEncodeCountry from "./views/tab-sitc/sitc-encode-country.vue";
import sitcEncodeCountryCompany from "./views/tab-sitc/sitc-encode-country-company.vue";
import sitcEncodeComapny from "./views/tab-sitc/sitc-encode-company.vue";
import countryEncode from "./views/tab-country/country-encode.vue";
import countryEncodeCompany from "./views/tab-country/country-encode-company.vue";
import countryCompany from "./views/tab-country/country-company.vue";
import countryCompanyEncode from "./views/tab-country/country-company-encode.vue";
import companyAnalyse from "./views/tab-company/company-analyse.vue";
import companyEncode from "./views/tab-company/company-encode.vue";
import companyEncodeCountry from "./views/tab-company/company-encode-country.vue";
import companyImport from "./views/tab-company/company-import.vue";
import companyImportEncode from "./views/tab-company/company-import-encode.vue";
import companyExport from "./views/tab-company/company-export.vue";
import companyExportEncode from "./views/tab-company/company-export-encode.vue";
import portLoad from "./views/port-load.vue";
import portDomestic from "./views/port-domestic.vue";
import portUnload from "./views/port-unload.vue";
import portAbroad from "./views/port-abroad.vue";

import tabRecordCountryEnterprise from "./views/tab-record/country-enterprise.vue";

import competeAnalyse from "./views/compete-analyse.vue";
import competeAnalyseNew from "./views/compete-analyse-new/Index.vue";
import TreeRectNew from "./views/tree-rect-new/Index.vue";

import anchor from "./components/anchor.vue";

import impExpConCode from "./views/graph/imp-exp-con-code.vue";
import impConCodeExp from "./views/graph/imp-con-code-exp.vue";
import impCodeConExp from "./views/graph/imp-code-con-exp.vue";

import Comment from "./Comment.vue";

export default Vue.extend({
  components: {
    CodeAnalyse,
    enterpriseInfo,
    filterCriteria,
    summaryCard,
    navBar,
    //trendAnalyse,
    newTrendAnalyse,
    cycleAnalyse,
    countryAnalyse,
    stateAnalyse,
    aboradSitc,
    encodeAnalyse6,
    encodeAnalyseXCountry,
    encodeAnalyseXCountryCompany,
    encodeAnlyseXCompany,
    sitcEncodeCountryCompany,
    sitcEncodeComapny,
    encodeAnalyse4,
    encodeAnalyse2,
    sitcEncodeRank,
    sitcEncodeCountry,
    countryEncode,
    countryEncodeCompany,
    countryCompany,
    companyImportEncode,
    companyExport,
    companyExportEncode,
    portLoad,
    countryCompanyEncode,
    companyAnalyse,
    companyEncode,
    companyEncodeCountry,
    companyImport,
    portDomestic,
    portUnload,
    portAbroad,
    tabRecordCountryEnterprise,
    competeAnalyse,
    competeAnalyseNew,
    TreeRectNew,
    anchor,
    impExpConCode,
    impConCodeExp,
    impCodeConExp,
    Comment,
  },
  data() {
    return {
      showCommentDiv: false,
      comment: {
        total: 0,
      },
    };
  },
  computed: {
    ...mapState(["done"]),
    ...mapState("anchor", {
      anchorReady: "ready",
    }),
  },
  methods: {
    handleTopClick() {
      document.getElementsByTagName("html")[0].scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
  mounted() {
    const [browser, browserVersion] = getUserAgentInfo();
    /* axios({
      method: "POST",
      url: "/search/Credit/ShowRecord",
      data: {
        browser,
        browserVersion,
      },
    }); */
  },
});
</script>

<style lang="scss" scoped>
//@import '@/firm/scss/el-special.scss';

$bodyWidth: 1170px;

.okk {
  padding-top: 1rem;
  font-size: 16px;
  color: #333;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial,
    sans-serif;
  background: #f1f2f6;

  ::v-deep p {
    margin: 0;
  }


  /* 修改 Element UI 样式 */

  $borderColor: #d9d9d9;

  ::v-deep .el-special-border-left {
    box-sizing: border-box;
    border-left: 1px solid $borderColor;
  }

  ::v-deep .el-special-border-right {
    box-sizing: border-box;
    border-right: 1px solid $borderColor;
  }

  ::v-deep .el-table th.is-leaf,
  .el-table td {
    border-bottom: none;
  }

  ::v-deep .el-popover {
    min-width: 0 !important;
  }

  ::v-deep .custom-el-checkbox-group {
    ::v-deep .el-radio__input {
      display: none;
    }

    ::v-deep .el-radio__label {
      font-size: 12px;
      padding: 0;
    }

    ::v-deep .el-radio {
      padding: 2px 9px 1px 9px;
      border-radius: 5px;

      &.is-checked {
        background: #bac3de;
      }

      &:hover {
        color: var(--themeColor, #3152AF);
        background: #bac3de;
      }
    }

    ::v-deep .el-checkbox__input {
      display: none;
    }

    ::v-deep .el-checkbox__label {
      font-size: 12px;
      padding: 0;
    }

    ::v-deep .el-checkbox {
      margin: 0;
      border-radius: 5px;

      &.is-checked {
        background: #bac3de;
      }

      &:hover {
        color: var(--themeColor, #3152AF);
        background: #bac3de;
      }
    }

    ::v-deep .el-checkbox__label {
      box-sizing: border-box;
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  ::v-deep .custome-el-progress-bg {
    .el-progress-bar__outer {
      background: #fff !important;
    }
  }



}

.firm {
  width: $bodyWidth;
  margin: 0 auto;

  ::v-deep .signle-line-omit {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  ::v-deep .cursor-pointer {
    cursor: pointer;

    * {
      cursor: pointer;
    }
  }


  ::v-deep .app-cutome-el-tabs {
    .el-tabs__header {
      background: #fff;
      padding: 0 30px;
      margin: 0;
      padding-bottom: 15px;

      .el-tabs__item {
        padding: 0;
        color: #8492a6;

        &.is-active {
          color: var(--themeColor, #3152AF);
        }

        .custome-label {
          padding: 0 16px;
          font-size: 14px;
        }
      }
    }
  }

}

.comment-fix {
  cursor: pointer;
  position: fixed;
  right: calc((100vw - #{$bodyWidth}) / 2 - 66px);
  bottom: 70px;
}

.to-top {
  cursor: pointer;
  position: fixed;
  right: calc((100vw - #{$bodyWidth}) / 2 - 66px);
  bottom: 10px;
}
</style>
