<template>
    <div>
        <el-dialog title="文件分享" :visible.sync="shareVisible" width="630px" @close="$emit('closedialog')">
            <el-transfer v-model="shareUkey" :titles="['待分享成员', '已分享成员']"
                :props="{key: 'key',label: 'name',disabled: 'disabled'}" :data="staffOption">
            </el-transfer>
            <span slot="footer" class="dialog-footer">
                <el-button @click="$emit('closedialog')" size="small">取 消</el-button>
                <el-button type="primary" @click="shareSubmit" size="small" :loading="btnLoading">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: ["shareVisible", "shareFileKey"],
    data() {
        return {
            btnLoading: false,
            //所有成员集合
            staffOption: [],
            //文件分享人集合
            shareUkey: [],
        };
    },
    methods: {
        shareSubmit(){
            if(this.shareUkey.length == 0){
                this.$message({message: '请选择分享给谁',type: 'warning'});
                return false;
            }
            console.log(this.shareUkey);
            this.btnLoading = true;
            this.$axios.post(`${this.$diskUrl}/Share/Shares`,{
                fileKeys: [this.shareFileKey],
                ukeys: this.shareUkey,
                expirationTime: null,
            }).then((res) => {
                const { isSuccess } = res;
                this.btnLoading = false;
                this.$emit('closedialog');  //关闭弹框
                if (isSuccess) {
                    this.$message({message: "分享成功",type: 'success'});
                }
            });
        },
        
        //获取所有成员集合
        getStaffBox(){
            this.$axios.get(`${this.$personnelUrl}/Organization/FindStaffBox`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.staffOption = data;
                }
            })
        },
    },
    mounted() {
        this.getStaffBox();    //获取所有成员集合
    },
    watch:{
        shareFileKey(){
            this.$axios.get(`${this.$diskUrl}/Share/GetFileShare?fileKey=${this.shareFileKey}`).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    //获取文件分享人集合
                    this.shareUkey = data.map(item =>{return item.ukey});
                    this.staffOption.forEach(element =>{
                        data.forEach(item =>{
                            if(element.key == item.ukey){
                                element.disabled = true;
                            }
                        })
                    })
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
/deep/.el-dialog__body {
    padding: 10px 20px;
}
</style>