<template>
<div style="display: flex;">
    <ClueNav @chooseNav="chooseNav" :currentPath="currentPath" />
    <div class="viewbox">
        <div class="searchTerms" v-loading="searchLoading">
            <div class="searchBox" style="margin-bottom: 10px;">
                <!-- 查询类型 公司1 网址2 地址3 -->
                <el-select v-model="searchType" placeholder="请选择" style="width:120px;">
                    <el-option label="公司" :value="1"></el-option>
                    <el-option label="网址" :value="2"></el-option>
                    <el-option label="地址" :value="3"></el-option>
                </el-select>
                <el-input placeholder="企业、网址、地址" prefix-icon="el-icon-search" v-model="searchValue" @keyup.enter.native="onSearch" style="width:600px;"></el-input>
                <el-button type="primary" style="margin-left:20px;" @click="onSearch" :loading="searchLoading">搜索</el-button>
            </div>
            <div class="itemTerm">
                <div class="name">来源:</div>
                <div class="spanList">
                    <!-- 0不限 1海关数据 2工商信息 3商业数据 -->
                    <div class="spanItem" :class="{'active':clueSource==0}" @click="chooseSource(0)">不限</div>
                    <div class="spanItem" :class="{'active':clueSource==3}" @click="chooseSource(3)">商业数据</div>
                    <div class="spanItem" :class="{'active':clueSource==2}" @click="chooseSource(2)">工商信息</div>
                    <!-- <div class="spanItem" :class="{'active':clueSource==1}" @click="chooseSource(1)">海关数据</div> -->
                </div>
            </div>
            <div class="itemTerm">
                <div class="name">洲:</div>
                <div class="spanList">
                    <div class="spanItem" :class="{'active':continentIndex==''}" @click="getCountrys('')">不限</div>
                    <div class="spanItem" v-for="ele in continentList" :key="ele.name" :class="{'active':continentIndex==ele.name}" @click="getCountrys(ele.name)">
                        {{ele.name}}({{ele.count}})
                    </div>
                </div>
            </div>
            <div class="itemTerm" v-show="isShowCountry">
                <div class="name">国家:</div>
                <div class="spanList">
                    <div class="spanItem" :class="{'active':countryIndex==''}" @click="clickCountry('','')">不限</div>
                    <div class="spanItem" v-for="(ele,index) in countryList" v-show="index < showCountryNumMax" :key="ele.country" :class="{'active':countryIndex==ele.country}" @click="clickCountry(ele.country,ele.code)">
                        {{ele.country}}({{ele.count}})
                    </div>
                    <div class="spanItem" style="color:var(--themeColor);" v-show="showCountryNumMax < countryList.length" @click="showCountryNumMax = countryList.length;">查看更多</div>
                </div>
            </div>
            <template v-if="clueSource==1">
                <div class="itemTerm">
                    <div class="name">HS编码:</div>
                    <div class="spanList">
                        <div class="spanItem" :class="{'active':twoCodeIndex==''}" @click="getFourCode('')">不限</div>
                        <div class="spanItem" v-for="(ele,index) in twoCodeList" v-show="index < showTwoCodeNumMax" :key="ele.name" :class="{'active':twoCodeIndex==ele.name}" @click="getFourCode(ele.name)">
                            {{ele.name}}({{ele.count}})
                        </div>
                        <div class="spanItem" style="color:var(--themeColor);" v-show="showTwoCodeNumMax < twoCodeList.length" @click="showTwoCodeNumMax = twoCodeList.length;">查看更多</div>
                    </div>
                </div>
                <div class="itemTerm" v-show="isShowFourCode">
                    <div class="name">四位编码:</div>
                    <div class="spanList">
                        <div class="spanItem" :class="{'active':fourCodeIndex==''}" @click="getSixCode('')">不限</div>
                        <div class="spanItem" v-for="(ele,index) in fourCodeList" v-show="index < showFourCodeNumMax" :key="ele.name" :class="{'active':fourCodeIndex==ele.name}" @click="getSixCode(ele.name)">
                            {{ele.name}}({{ele.count}})
                        </div>
                        <div class="spanItem" style="color:var(--themeColor);" v-show="showFourCodeNumMax < fourCodeList.length" @click="showFourCodeNumMax = fourCodeList.length;">查看更多</div>
                    </div>
                </div>
                <div class="itemTerm" v-show="isShowSixCode">
                    <div class="name">六位编码:</div>
                    <div class="spanList">
                        <div class="spanItem" :class="{'active':sixCodeIndex==''}" @click="clickSix('')">不限</div>
                        <div class="spanItem" v-for="(ele,index) in sixCodeList" v-show="index < showSixCodeNumMax" :key="ele.name" :class="{'active':sixCodeIndex==ele.name}" @click="clickSix(ele.name)">
                            {{ele.name}}({{ele.count}})
                        </div>
                        <div class="spanItem" style="color:var(--themeColor);" v-show="showSixCodeNumMax < sixCodeList.length" @click="showSixCodeNumMax = sixCodeList.length;">查看更多</div>
                    </div>
                </div>
            </template>
            <template v-if="clueSource==2">
                <div class="itemTerm">
                    <div class="name">联系人:</div>
                    <div class="spanList">
                        <el-checkbox v-model="isContacts">是否有联系人</el-checkbox>
                    </div>
                </div>
            </template>
            <template v-if="clueSource==3">
                <div class="itemTerm">
                    <div class="name">行业代码:</div>
                    <div class="spanItem" v-for="(ele,index) in chooseSicCode" :key="ele">
                        <span>{{ele}}</span>
                        <!-- 清除单个 -->
                        <i class="el-icon-close" @click="chooseSicCode.splice(index,1)"></i>
                    </div>
                    <div class="spanItem">
                        <el-button type="primary" size="mini" @click="openChoose">选择行业代码</el-button>
                        <el-button size="mini" v-show="chooseSicCode.length!=0" @click="chooseSicCode = [];">清除</el-button>
                    </div>
                </div>
                <div class="itemTerm">
                    <div class="name">实体类型:</div>
                    <div class="spanList">
                        <div class="spanItem" :class="{'active':entityType==''}" @click="changeOne('')">不限</div>
                        <div class="spanItem" v-for="ele in optionOne" :key="ele.name" :class="{'active':entityType==ele.name}" @click="changeOne(ele.name)">
                            {{ele.cnName}}({{ele.count}})
                        </div>
                    </div>
                </div>
                <div class="itemTerm">
                    <div class="name">公司状态:</div>
                    <div class="spanList">
                        <div class="spanItem" :class="{'active':status==''}" @click="changeTwo('')">不限</div>
                        <div class="spanItem" v-for="ele in optionTwo" :key="ele.name" :class="{'active':status==ele.name}" @click="changeTwo(ele.name)">
                            {{ele.cnName}}({{ele.count}})
                        </div>
                    </div>
                </div>
                <div class="itemTerm">
                    <div class="name">联系人:</div>
                    <div class="spanList">
                        <el-checkbox v-model="isContacts">是否有联系人</el-checkbox>
                    </div>
                </div>
                <div class="itemTerm">
                    <div class="name">员工人数:</div>
                    <div class="spanList">
                        <el-input-number v-model="addSearchCondition.employeeStart" size="mini" :controls="false"></el-input-number>
                        <span style="padding:0 10px;color: #6c6c6c;">至</span>
                        <el-input-number v-model="addSearchCondition.employeeEnd" size="mini" :controls="false"></el-input-number>
                    </div>
                </div>
                <div class="itemTerm">
                    <div class="name">营业额:</div>
                    <div class="spanList">
                        <el-input-number v-model="addSearchCondition.revenueUsdStart" size="mini" :controls="false"></el-input-number>
                        <span style="padding:0 10px;color: #6c6c6c;">至</span>
                        <el-input-number v-model="addSearchCondition.revenueUsdEnd" size="mini" :controls="false"></el-input-number>
                    </div>
                </div>
                <div class="itemTerm">
                    <div class="name">注册时间:</div>
                    <div class="spanList">
                        <el-date-picker v-model="addSearchCondition.incorporationDateStart" value-format="yyyy-MM-dd" size="mini" placeholder="选择日期" />
                        <span style="padding:0 10px;color: #6c6c6c;">至</span>
                        <el-date-picker v-model="addSearchCondition.incorporationDateEnd" value-format="yyyy-MM-dd" size="mini" placeholder="选择日期" />
                    </div>
                </div>
            </template>

        </div>

        <div style="margin-top:20px;">
            <div class="searchResult noResult" v-show="totalItems==0" v-loading="searchLoading">
                <img class="hintImg" src="@/assets/cluePicture/hint.png" alt="" />
                <!-- <img class="noImg" src="@/assets/cluePicture/no3.png" alt="" /> -->
                <!-- <div class="hintTxt">线索搜索</div> -->
            </div>

            <div class="searchResult" v-show="totalItems!=0" v-loading="searchLoading">
                <div class="title">
                    <div>为您找到<span style="color:#FF2D2D;">{{totalItems}}</span>条相关结果</div>
                    <div v-show="webSiteCount">，共有<span style="color:#FF2D2D;">{{webSiteCount}}</span>个网址</div>
                    <div v-show="emailCount">，共有<span style="color:#FF2D2D;">{{emailCount}}</span>个邮箱</div>
                </div>
                <div class="resultList">
                    <div class="resultItem" v-for="item in resultList" :key="item.key">
                        <div style="margin-bottom: 8px;">
                            <div class="name" @click="toDetails(item)">{{item.company}}</div>
                            <div class="country">
                                <img :src="item.flagUrl" style="margin-right:5px;height:14px" />
                                <span>{{item.country}}</span>
                            </div>
                        </div>
                        <div v-if="item.dataType==1">
                            <div style="display:flex;">
                                <div class="oneField">
                                    <div class="color99">最新交易日期</div>
                                    <div class="color3a">{{item.lastDateStr}}</div>
                                </div>
                                <div class="oneField">
                                    <div class="color99">交易总次数</div>
                                    <div class="color3a">{{item.totalNum}}</div>
                                </div>
                                <div class="oneField">
                                    <div class="color99">交易国家数</div>
                                    <div class="color3a">{{item.countryNum}}</div>
                                </div>
                                <div class="oneField">
                                    <div class="color99">交易伙伴数</div>
                                    <div class="color3a">{{item.partnerNum}}</div>
                                </div>
                            </div>
                            <div class="twoField" v-if="item.hsCode.length != 0">
                                <span class="color99">HS编码：</span>
                                <span class="color3a" style="padding-right:10px;" v-for="(ele,index) in item.hsCode" :key="index" v-show="index<10" v-html="ele"></span>
                                <span class="primaryColor" style="margin-left:10px;" @click="toMoreCode(item)">[查看更多]</span>
                            </div>
                            <div class="twoField" v-if="item.productDesc.length != 0">
                                <span class="color99">商品描述：</span>
                                <span class="color3a" v-html="item.productDesc[0]"></span>
                                <span class="primaryColor" style="margin-left:10px;" @click="toMoreDes(item)">[查看更多]</span>
                            </div>
                        </div>
                        <div v-if="item.dataType==2">
                            <div class="twoField">
                                <span class="color99">邮箱：</span>
                                <span class="color3a">{{item.email}}</span>
                            </div>
                            <div class="twoField">
                                <span class="color99">企业官网：</span>
                                <span class="primaryColor" @click="openWebsite(item.domain)">{{item.domain}}</span>
                            </div>
                            <div class="twoField">
                                <span class="color99">公司地址：</span>
                                <span class="color3a">{{item.address}}</span>
                            </div>
                        </div>
                        <div v-if="item.dataType==3">
                            <div class="twoField">
                                <span class="color99">注册时间：</span>
                                <span class="color3a">{{item.incorporationDateStr}}</span>
                            </div>
                            <div class="twoField">
                                <span class="color99">企业官网：</span>
                                <span class="primaryColor" @click="openWebsite(item.domain)">{{item.domain}}</span>
                            </div>
                            <div class="twoField">
                                <span class="color99">公司地址：</span>
                                <span class="color3a">{{item.address}}</span>
                            </div>
                            <div class="twoField">
                                <span class="color99">行业：</span>
                                <span class="color3a" v-for="ele in item.sicModels" :key="ele.primaryCode" style="margin-right:20px;">
                                    ({{ele.primaryCode}}){{ele.sicDescription}}
                                </span>
                            </div>
                        </div>
                        <!-- <div class="hasAdd" v-if="item.isMyCustomer">已加入我的客户</div>
                        <div class="addBtn" @click="addCustomer(item)" v-else>
                            <img src="@/assets/cluePicture/addCustomer.png" alt="" />加入我的客户
                        </div> -->
                        <template v-if="item.dataType==2 || item.dataType==3">
                            <div class="hasAdd" v-if="item.isMyClue">已加入我的线索</div>
                            <div class="addBtn" @click="addMyClue(item)" :class="isDisable ? 'forbidden' :''" v-else>
                                加入我的线索
                            </div>
                        </template>
                    </div>
                    <el-pagination background layout="total,sizes,prev,pager,next" :page-sizes="[10, 20, 40]" :page-size.sync="pageSize" @size-change="pageSizeChange" :total="totalItems" style="float:right;margin-top:15px;" :current-page.sync="pageIndex" @current-change="pageIndexChange"></el-pagination>
                </div>
            </div>

            <!-- 验证解锁 -->
            <el-dialog title="验证解锁" :visible.sync="dialogVisible" width="500px" :show-close="false"
                :close-on-click-modal="false" :close-on-press-escape="false">
                <p style="line-height:28px;font-weight:bold;">请求过于频繁，请输入验证码：</p>
                <div style="display:flex;">
                    <el-input v-model="imgCode" size="small"></el-input>
                    <img class="pointer" :src="imgSrc" alt="" @click="openVerify" />
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button size="small" type="primary" :disabled="!imgCode" @click="finishVerify">确 认</el-button>
                </span>
            </el-dialog>

            <!-- 选择行业代码 -->
            <el-dialog title="行业代码" :visible.sync="chooseVisible" width="1200px" top="5vh">
                <div slot="title" class="dialogTitle">
                    <div>行业代码</div>
                    <el-input style="width:500px;margin-right:40px;" placeholder="行业代码/中文描述" v-model="keyWord" prefix-icon="el-icon-search" clearable>
                        <el-button slot="append" @click="codeSearch">搜索</el-button>
                    </el-input>
                </div>
                <div class="chooseCode" v-if="isSearchCode">
                    <div class="chooseFour">
                        <div class="bItem" v-for="(ele,index) in matchingList" :key="index">
                            <span class="pointer" @click="confirmCode(ele)">{{ele.name}}</span>
                        </div>
                    </div>
                </div>
                <div class="chooseCode" v-else>
                    <el-breadcrumb separator="/">
                        <el-breadcrumb-item v-for="(element,index) in breadcrumbList" :key="index">
                            <span class="pointer" style="color:var(--themeColor);" @click="goBack(index)">{{element}}</span>
                        </el-breadcrumb-item>
                    </el-breadcrumb>
                    <div class="chooseTwo" v-show="currentIndex == '2code'">
                        <div class="chooseItem" v-for="(item,index) in twoSicCodeList" :key="index">
                            <div class="name">{{item.name}}</div>
                            <div class="children">
                                <div class="aItem" v-for="ele in item.children" :key="ele.name">
                                    <i class="el-icon-plus pointer" @click="getFourSic(ele)"></i>
                                    <span>{{ele.name}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="chooseFour" v-show="currentIndex == '4code'">
                        <div class="bItem" v-for="(ele,index) in fourSicCodeList" :key="index">
                            <!-- <i v-show="ele.children" class="el-icon-plus pointer" @click="getEightSic(ele)"></i> -->
                            <span class="pointer" @click="confirmCode(ele)">{{ele.name}}</span>
                        </div>
                    </div>
                    <div class="chooseEight" v-show="currentIndex == '8code'">
                        <div class="bItem" v-for="(ele,index) in eightSicCodeList" :key="index">
                            <span class="pointer" @click="confirmCode(ele)">{{ele.name}}</span>
                        </div>
                    </div>
                </div>
            </el-dialog>

            <!-- 查看更多(HS编码)(商品描述) -->
            <MoreCode v-if="moreCodeVisible" :viewKey="viewKey" :dataYear="dataYear" @close="moreCodeVisible = false;" />

            <MoreDes v-if="moreDesVisible" :viewKey="viewKey" :dataYear="dataYear" @close="moreDesVisible = false;" />

        </div>
    </div>
</div>
</template>

<script>
import SicJson from '../../assets/json/SIC.json'
import ClueNav from "@/components/ClueNav.vue";
import MoreCode from "./MoreInfo/MoreCode.vue"
import MoreDes from "./MoreInfo/MoreDes.vue"
import {toFirm} from '@/utils'
export default {
    components: { ClueNav, MoreCode , MoreDes },
    props: {
        handleSelect: {
            type: Function,
            default: null,
        },
    },
    data() {
        return {
            currentPath: "/clue/allClue",
            clueSource: 0, //线索来源 0不限 1海关数据 2工商信息 3商业数据
            //搜索条件：洲，国家
            continentList: [],
            continentIndex: "",
            countryList: [],
            countryIndex: "",
            countryCode: "",
            isShowCountry: false,
            showCountryNumMax: 10,
            //搜索条件：编码
            twoCodeList: [],
            twoCodeIndex: "",
            fourCodeList: [],
            fourCodeIndex: "",
            sixCodeList: [],
            sixCodeIndex: "",
            isShowFourCode: false,
            isShowSixCode: false,
            showTwoCodeNumMax: 10,
            showFourCodeNumMax: 10,
            showSixCodeNumMax: 10,
            //选择行业代码
            chooseVisible: false,
            breadcrumbList: ["UsSIC"],
            currentIndex: "2code",
            twoSicCodeList: [],
            fourSicCodeList: [],
            eightSicCodeList: [],
            chooseSicCode: [],
            //行业代码中文匹配搜索
            allFourCodeList: [],
            isSearchCode: false,
            keyWord: "",
            matchingList: [],
            //实体类型和公司状态
            entityType: "",
            status: "",
            optionOne: [],
            optionTwo: [],
            //搜索结果
            dataYear: "1",
            searchLoading: false,
            searchType: 1,  //查询类型 公司1 网址2 地址3
            searchValue: "",
            resultList: [],
            totalItems: 0,
            webSiteCount: 0,
            emailCount: 0,
            pageIndex: 1,
            pageSize: 10,
            //验证解锁
            dialogVisible: false,
            imgCode: "",
            imgKey: "",
            imgSrc: "",
            //查看更多(HS编码)(商品描述)
            moreCodeVisible: false,
            moreDesVisible: false,
            viewKey: "",
            // 新增搜索条件
            isContacts: false,      //是否有联系人 1是 0否
            addSearchCondition: {
                employeeStart: 0,   //员工人数
                employeeEnd: 0,
                revenueUsdStart: 0, //营业额
                revenueUsdEnd: 0,
                incorporationDateStart: "",  //注册时间
                incorporationDateEnd: "",
            },
            isDisable: false,
        };
    },
    methods: {
        chooseNav(index){
            this.handleSelect(index);
            this.$router.push(index);
        },

        //选择线索来源
        chooseSource(ele){
            this.clueSource = ele;
            this.getContinents();
            // 清空部分条件
            this.continentIndex = "";
            this.countryIndex = "";
            this.countryCode = "";
            this.isShowCountry = false;
            this.showCountryNumMax = 10;
            this.twoCodeIndex = "";
            this.fourCodeIndex = "";
            this.sixCodeIndex = "";
            this.isShowFourCode = false;
            this.isShowSixCode = false;
            this.showTwoCodeNumMax = 10;
            this.showFourCodeNumMax = 10;
            this.showSixCodeNumMax = 10;
            this.chooseSicCode = [];
            this.entityType = "";
            this.status = "";
            this.onSearch();
        },

        getContinents(){
            this.$axios.get(`${this.$clueUrl}/ClickHouse/GetContinent?type=${this.clueSource}`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.continentList = data;
                }
            });
        },
        getCountrys(continent){
            this.continentIndex = continent;
            this.countryIndex = "";
            this.countryCode = "";
            this.countryList = [];
            this.showCountryNumMax = 10;
            this.onSearch();
            if(continent){
                this.isShowCountry = true;
                this.$axios.get(`${this.$clueUrl}/ClickHouse/GetCountry?continent=${continent}&type=${this.clueSource}`).then((res) => {
                    const { isSuccess, data } = res;
                    if (isSuccess) {
                        this.countryList = data;
                    }
                });
            }else{
                this.isShowCountry = false;
            }
        },
        clickCountry(country,code){
            this.countryIndex = country;
            this.countryCode = code;
            // 清空部分条件
            this.twoCodeIndex = "";
            this.fourCodeIndex = "";
            this.sixCodeIndex = "";
            this.isShowFourCode = false;
            this.isShowSixCode = false;
            this.showTwoCodeNumMax = 10;
            this.showFourCodeNumMax = 10;
            this.showSixCodeNumMax = 10;
            this.onSearch();
        },
        //选择编码
        getTwoCode(){
            this.showTwoCodeNumMax = 10;
            this.$axios.get(`${this.$clueUrl}/ClickHouse/GetTwoCode?year=${this.dataYear}`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.twoCodeList = data;
                }
            });
        },
        getFourCode(code){
            this.twoCodeIndex = code;
            this.fourCodeIndex = "";
            this.sixCodeIndex = "";
            this.fourCodeList = [];
            this.sixCodeList = [];
            this.showFourCodeNumMax = 10;
            this.onSearch();
            if(code){
                this.isShowFourCode = true;
                this.isShowSixCode = false;
                this.$axios.get(`${this.$clueUrl}/ClickHouse/GetFourCode?year=${this.dataYear}&towCode=${code}`).then((res) => {
                    const { isSuccess, data } = res;
                    if (isSuccess) {
                        this.fourCodeList = data;
                    }
                });
            }else{
                this.isShowFourCode = false;
                this.isShowSixCode = false;
            }
        },
        getSixCode(code){
            this.fourCodeIndex = code;
            this.sixCodeIndex = "";
            this.sixCodeList = [];
            this.showSixCodeNumMax = 10;
            this.onSearch();
            if(code){
                this.isShowSixCode = true;
                this.$axios.get(`${this.$clueUrl}/ClickHouse/GetSixCode?year=${this.dataYear}&fourCode=${code}`).then((res) => {
                    const { isSuccess, data } = res;
                    if (isSuccess) {
                        this.sixCodeList = data;
                    }
                });
            }else{
                this.isShowSixCode = false;
            }
        },
        clickSix(code){
            this.sixCodeIndex = code;
            this.onSearch();
        },
        //选择行业代码
        openChoose(){
            if(this.chooseSicCode.length >= 5){
                this.$message({ message: '最多选5个行业!', type: 'warning'});
                return false;
            }
            this.chooseVisible = true;
        },
        getFourSic(ele){
            this.fourSicCodeList = ele.children;
            this.currentIndex = "4code";
            this.breadcrumbList.push(ele.name)
        },
        getEightSic(ele){
            this.eightSicCodeList = ele.children;
            this.currentIndex = "8code";
            this.breadcrumbList.push(ele.name)
        },
        goBack(index){
            if(index==0){
                this.currentIndex = "2code";
                this.breadcrumbList = ["UsSIC"];
            }else if(index==1){
                this.currentIndex = "4code";
                if(this.breadcrumbList.length > 2){
                    this.breadcrumbList.pop();
                }
            }else if(index==2){
                this.currentIndex = "8code";
            }
        },
        confirmCode(ele){
            this.chooseSicCode.push(ele.name);
            this.chooseVisible = false;
            this.onSearch();
        },
        //行业代码中文匹配搜索
        codeSearch(){
            if(this.keyWord){
                this.isSearchCode = true;
                this.matchingList = this.allFourCodeList.filter(item => item.name.indexOf(this.keyWord) >= 0);
            }else{
                this.isSearchCode = false;
                this.matchingList = [];
            }
        },
        //获取**实体类型和公司状态**下拉框数据
        getOption(){
            this.$axios.get(`${this.$clueUrl}/BusinessData/GetEntityTypes`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.optionOne = data;
                }
            });
            this.$axios.get(`${this.$clueUrl}/BusinessData/GetStatus`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.optionTwo = data;
                }
            });
        },
        changeOne(value){
            this.entityType = value;
            this.onSearch();
        },
        changeTwo(value){
            this.status = value;
            this.onSearch();
        },

        getResult(){
            if(this.searchLoading){
                return false;   //数据正在请求中,无法进行下一个请求;
            }
            // 编码整理
            var hsCode = "";
            if(this.sixCodeIndex != ""){
                hsCode = this.sixCodeIndex;
            }else if(this.fourCodeIndex != ""){
                hsCode = this.fourCodeIndex;
            }else{
                hsCode = this.twoCodeIndex;
            }
            //请求接口获取数据
            this.searchLoading = true;
            // /ClickHouse/GetClue => /Es/GetClue   接口修改
            this.$axios.post(`${this.$clueUrl}/Es/GetClue`,{
                "source": this.clueSource,
                "code": this.countryCode,
                "continent": this.continentIndex,
                "country": this.countryIndex,
                "type": this.searchType,
                "keyword": this.searchValue,
                "hsCode": hsCode,
                "sicCodes": this.chooseSicCode,
                "entityType": this.entityType,
                "status": this.status,
                "pageIndex": this.pageIndex,
                "pageSize": this.pageSize,
                isContacts: this.isContacts ? 1 : 0,
                ...this.addSearchCondition,
            }).then((res) => {
                const { isSuccess, data, errorMessage } = res;
                this.searchLoading = false;
                if (isSuccess) {
                    this.totalItems = data.totalItems * 1;
                    this.webSiteCount = data.webSiteCount;
                    this.emailCount = data.emailCount;
                    if(this.totalItems == 0){
                        this.$message({ message: '搜索无数据,请修改搜索条件重试!',type: 'warning' });
                        return false;
                    }
                    // data.datas.forEach((element,index) => {
                    //     if(element.dataType == 3){
                    //         element.key = element.pid;
                    //     }
                    //     if(element.dataType == 1){
                    //         element.key = element.id;
                    //         this.byIdGetHscode(element,index);
                    //     }
                    // });
                    this.resultList = data.datas.map(item=>({
                        ...item,
                        flagUrl: window.getFlagUrlByCountry?window.getFlagUrlByCountry(item.country):'',
                    }))

                }else{
                    if(errorMessage == "请求过于频繁，请输入验证码"){
                        this.openVerify();
                    }
                }
            });
        },
        byIdGetHscode(element,index){
            this.$axios.get(`${this.$clueUrl}/ClickHouse/GetHscode?id=${element.id}`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.resultList[index].hsCode = data;
                }
            });
        },
        onSearch(){
            this.pageIndex = 1;
            this.getResult();
        },
        pageIndexChange(page) {
            this.pageIndex = page;
            if(this.pageIndex > 30){
                this.pageIndex = 30;
                this.$notify({ title: '警告',message: '目前线索模块处于免费试用阶段,搜索页码不能超过30!',type: 'warning' });
                return false;
            }
            this.getResult();
        },
        pageSizeChange() {
            this.pageIndex = 1;
            this.getResult();
        },

        //前往线索详情
        toDetails(row){
            // 验证查看详情次数
            this.$axios.get(`${this.$clueUrl}/SecurityStrategy/CheckDetailsCount`).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    //console.log(row)
                    let industrys = [];
            if(row.sicModels){
                row.sicModels.forEach(element =>{
                    industrys.push(`(${element.primaryCode})${element.sicDescription}`)
                })
            }
            let submitObj = {
                "clueSource": row.dataType,
                "clueRelationKey": row.key,
                "companyName": row.company,
                "country": row.country,
                "legalPerson": "",
                "contactNumber": "",
                "registrationTime": row.incorporationDateStr,
                "employeesNumber": 0,
                "officialWebsite": row.website ? row.website : row.domain,
                "corporateEmail": row.email,
                "businessAddress": row.address ? row.address : row.address1,
                "companyProfile": "",
                "industrys": industrys,
                isMyClue:row.isMyClue
            }
                    toFirm({item:submitObj,company:row.company,countryCode:row.threeEnCountryCode})
                    /* if(row.dataType == 1){
                        let routeData = this.$router.resolve({
                            path: "/clue/clueDetails",
                            query: { key: row.key, companyName: row.company, clueType: 'customs', year: this.dataYear},
                        });
                        window.open(routeData.href, "_blank");
                    }else if(row.dataType == 2){
                        let routeData = this.$router.resolve({
                            path: "/clue/clueDetails",
                            query: { key: row.key, companyName: row.company, clueType: 'business', country: row.country },
                        });
                        window.open(routeData.href, "_blank");
                    }else if(row.dataType == 3){
                        let routeData = this.$router.resolve({
                            path: "/clue/clueDetails",
                            query: { key: row.key, companyName: row.company, clueType: 'commercial', },
                        });
                        window.open(routeData.href, "_blank");
                    } */
                }
            });
        },

        //加入我的客户
        addCustomer(item){
            let submitObj = {}
            if(item.dataType == 1){
                submitObj = {
                    companyKey: JSON.parse(localStorage.userInfo).companyKey,
                    userKey: JSON.parse(localStorage.userInfo).uKey,
                    name: item.company,
                    country: item.country,
                    webSite: "",
                    call: "",
                    address: "",
                }
            }else if(item.dataType == 2){
                submitObj = {
                    companyKey: JSON.parse(localStorage.userInfo).companyKey,
                    userKey: JSON.parse(localStorage.userInfo).uKey,
                    name: item.company,
                    country: item.country,
                    webSite: item.website,
                    call: "",
                    address: item.address,
                }
            }else if(item.dataType == 3){
                submitObj = {
                    companyKey: JSON.parse(localStorage.userInfo).companyKey,
                    userKey: JSON.parse(localStorage.userInfo).uKey,
                    name: item.company,
                    country: item.country,
                    webSite: item.domain,
                    call: item.phone,
                    address: item.address1,
                }
            }
            this.$axios.post(`${this.$customerUrl}/Common/AddCustomer`, submitObj).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    this.$message({ message: '添加成功', type: 'success'});
                    item.isMyCustomer = true;
                }
            });
        },
        //加入我的线索
        addMyClue(item){
            if(this.isDisable){
                return false;
            }
            let industrys = [];
            if(item.sicModels){
                item.sicModels.forEach(element =>{
                    industrys.push(`(${element.primaryCode})${element.sicDescription}`)
                })
            }
            let submitObj = {
                "clueSource": item.dataType,
                "clueRelationKey": item.key,
                "companyName": item.company,
                "country": item.country,
                "legalPerson": "",
                "contactNumber": "",
                "registrationTime": item.incorporationDateStr,
                "employeesNumber": 0,
                "officialWebsite": item.website ? item.website : item.domain,
                "corporateEmail": item.email,
                "businessAddress": item.address ? item.address : item.address1,
                "companyProfile": "",
                "industrys": industrys,
            }
            this.isDisable = true;
            this.$axios.post(`${this.$clueUrl}/MyClue/AddMyClue`, submitObj).then((res) => {
                const { isSuccess } = res;
                this.isDisable = false;
                if (isSuccess) {
                    this.$message({ message: '添加成功', type: 'success'});
                    item.isMyClue = true;
                }
            });
        },

        //打开网址
        openWebsite(url){
            let jumpUrl = url.indexOf("http") > -1 ? url : "http://" + url;
            let routeData = this.$router.resolve({
                path: "/jumpWeb",
                query: { jumpUrl: jumpUrl },
            });
            window.open(routeData.href, "_blank");
        },

        //查看更多(HS编码)(商品描述)
        toMoreCode(item){
            this.moreCodeVisible = true;
            this.viewKey = item.key;
        },
        toMoreDes(item){
            this.moreDesVisible = true;
            this.viewKey = item.key;
        },

        //请求频繁，需要输入验证解锁
        openVerify(){
            this.dialogVisible = true;
            this.$axios.get(`${this.$clueUrl}/SecurityStrategy/GetVerifycode`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.imgKey = data.key;
                    this.imgSrc = data.image;
                }
            });
        },
        finishVerify(){
            this.$axios.get(`${this.$clueUrl}/SecurityStrategy/VerifyVerificationCode?key=${this.imgKey}&code=${this.imgCode}`).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    this.dialogVisible = false;
                    this.imgCode = "";
                    this.onSearch();
                }
            });
        },

    },
    mounted() {
        this.getContinents();
        this.getTwoCode();
        this.getOption();

        this.twoSicCodeList = SicJson[0].children;
        // 行业代码中文匹配搜索
        let allFourCodeList = [];
        this.twoSicCodeList.forEach(item =>{
            item.children.forEach(element =>{
                element.children.forEach(ele =>{
                    allFourCodeList.push(ele);
                })
            })
        })
        this.allFourCodeList = allFourCodeList; //获取所有的四位码

    },
};
</script>

<style lang="scss" scoped>
@import '@/scss/common.scss';
.viewbox{
    width: calc(100% - 200px);
    height: calc(100vh - 80px);
    box-sizing: border-box;
    overflow: auto;
    background: #FFFFFF;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    padding: 20px;
    .header{
        display: flex;
        padding-bottom: 20px;
        border-bottom: 1px solid #E1E1E1;
        .logo{
            padding: 0 15px;
        }
        .theme{
            font-size: 20px;
            line-height: 32px;
            font-weight: bold;
            color: #092338;
        }
        .txt{
            font-size: 14px;
            line-height: 20px;
            color: #092338;
        }
    }

    .searchTerms{
        // margin-top: 20px;
        .itemTerm{
            line-height: 26px;
            margin-bottom: 10px;
            overflow: hidden;
            .name{
                float: left;
                width: 65px;
                font-size: 14px;
                color: #092338;
                text-align: right;
                margin-right: 15px;
            }
            .spanList{
                float: left;
                width: calc(100% - 80px);
            }
            .spanItem{
                display: inline-block;
                font-size: 14px;
                color: #092338;
                padding: 0 10px;
                margin-right: 15px;
                cursor: pointer;
            }
            .active{
                color: #FFFFFF;
                background: $themeColor;
                border-radius: 2px;
            }
        }
    }

    .searchResult{
        // margin-top: 20px;
        .title{
            height: 40px;
            line-height: 40px;
            background: #F1F1F1;
            color: #3A3A3A;
            padding-left: 20px;
            display: flex;
        }
        .resultList{
            padding: 20px;
            overflow: hidden;
            .resultItem{
                font-size: 14px;
                padding: 10px 0;
                border-bottom: 1px solid #E8E8E8;
                position: relative;
                .name{
                    display: inline-block;
                    color: var(--themeColor);
                    text-decoration: underline;
                    cursor: pointer;
                }
                .country{
                    display: inline-block;
                    margin-left: 20px;
                    img{
                        vertical-align: middle;
                    }
                }
                .tagModule{
                    display: flex;
                    .tag1{
                        height: 20px;
                        line-height: 20px;
                        padding: 0 5px;
                        background: rgba(255, 170, 72, 0.2);
                        border-radius: 1px;
                        font-size: 12px;
                        color: #FFAA48;
                        margin-right: 10px;
                    }
                    .tag2{
                        height: 20px;
                        line-height: 20px;
                        padding: 0 5px;
                        background: rgba(255, 94, 126, 0.2);
                        border-radius: 1px;
                        font-size: 12px;
                        color: #FF5E7E;
                        margin-right: 10px;
                    }
                }
                .color99{
                    color: #999999;
                }
                .color3a{
                    color: #3A3A3A;
                }
                .primaryColor{
                    color: var(--themeColor);
                    cursor: pointer;
                }
                .oneField{
                    width: 200px;
                    text-align: left;
                }
                .twoField{
                    line-height: 28px;
                }
                .addBtn{
                    position: absolute;
                    top: 10px;
                    right: 0;
                    padding: 0 10px;
                    height: 28px;
                    line-height: 28px;
                    font-size: 14px;
                    color: var(--themeColor);
                    background: rgba(183, 183, 183, 0.2);
                    border: 1px solid var(--themeColor);
                    border-radius: 4px;
                    cursor: pointer;
                    &:hover{
                        background: var(--themeColor);
                        color: #FFFFFF;
                    }
                    img{
                        position: absolute;
                        left: 10px;
                        top: 5px;
                    }
                }
                .forbidden{
                    cursor: not-allowed;
                }
                .hasAdd{
                    position: absolute;
                    top: 10px;
                    right: 0;
                    padding: 0 10px;
                    height: 28px;
                    line-height: 28px;
                    font-size: 14px;
                    color: #B7B7B7;
                    background: rgba(183, 183, 183, 0.2);
                    border: 1px solid #B7B7B7;
                    border-radius: 4px;
                }
            }
        }
    }
    .noResult{
        overflow: hidden;
        .hintImg{
            float: left;
            width: 800px;
        }
        .noImg{
            float: left;
            margin-left: 80px;
            margin-top: 200px;
            width: 100px;
            height: 105px;
        }
        .hintTxt{
            float: left;
            margin-left: 20px;
            margin-top: 200px;
            font-size: 80px;
            line-height: 100px;
            font-weight: bold;
            color: #DFDFDF;
        }
    }

    .chooseCode{
        // height: 750px;
        height: 80vh;
        overflow: auto;
        .el-breadcrumb{
            background-color: #f3f3f3;
            line-height: 24px;
            padding-left: 10px;
        }
        .chooseTwo{
            padding-left: 10px;
            .chooseItem{
                margin-bottom: 10px;
                .name{
                    font-weight: bold;
                    color: #6a6a6a;
                    font-size: 16px;
                }
                .children{
                    line-height: 24px;
                    .aItem{
                        display: inline-block;
                        margin-right: 20px;
                        span{
                            padding-left: 5px;
                        }
                    }
                }
            }
        }
        .chooseFour,.chooseEight{
            padding-left: 10px;
            .bItem{
                line-height: 32px;
                span{
                    padding-left: 5px;
                    &:hover{
                        color: var(--themeColor);
                    }
                }
            }
        }
        .el-icon-plus{
            border: 1px solid var(--themeColor);
            color:var(--themeColor);
        }
    }
    /deep/.el-dialog__body {
        padding: 10px 20px;
    }

    .dialogTitle{
        height: 24px;
        line-height: 24px;
        font-size: 18px;
        color: #303133;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
    }
}
</style>
