<template>
    <div>
        <InterfaceName :interfaceName="interfaceName" />
        <InterfaceSpe :requestUrl="requestUrl" :requestMethod="requestMethod" />
        <InputParam :inputTableData="inputTableData" />
        <ReturnParam :returnObj="returnObj" />   
    </div>
</template>

<script>
import InterfaceName from "../Acomponents/InterfaceName.vue";
import InterfaceSpe from "../Acomponents/InterfaceSpe.vue";
import InputParam from "../Acomponents/InputParam.vue";
import ReturnParam from "../Acomponents/ReturnParam.vue";

export default {
    components: { InterfaceName, InterfaceSpe, InputParam, ReturnParam },
    data() {
        return {
            interfaceName: "保存供应商",
            requestUrl: "https://crm.qixinerp.com/open/Procure/Supplier/Save",
            requestMethod: "POST",
            inputTableData: [],
            returnObj: {},
        };
    },
    methods: {
        
    },
    mounted() {
        this.inputTableData = [
            {paramName: "key", valueType: "string", explain: "唯一key", required: true},
            {paramName: "manufacturerNo", valueType: "string", explain: "厂商编号", required: true},
            {paramName: "manufacturerName", valueType: "string", explain: "厂商名称", required: true},
            {paramName: "companyHomepage", valueType: "string", explain: "公司主页", required: false},
            {paramName: "city", valueType: "string", explain: "城市", required: false},
            {paramName: "province", valueType: "string", explain: "省份", required: false},
            {paramName: "placeOrigin", valueType: "string", explain: "货源地", required: false},
            {paramName: "postalCode", valueType: "string", explain: "邮政编码", required: false},
            {paramName: "address", valueType: "string", explain: "详细地址", required: false},
            {paramName: "creditRating", valueType: "string", explain: "信用等级", required: false},
            {paramName: "manufacturerSource", valueType: "string", explain: "厂商来源", required: false},
            {paramName: "businessScope", valueType: "string", explain: "业务范围", required: false},
            {paramName: "settlementMethod", valueType: "string", explain: "结算方式", required: false},
            {paramName: "cooperationLevel", valueType: "string", explain: "合作等级", required: false},
            {paramName: "isInvoicing", valueType: "string", explain: "能否开票", required: false},
            {paramName: "companyTaxNo", valueType: "string", explain: "公司税号", required: false},
            {paramName: "depositBank", valueType: "string", explain: "开户行", required: false},
            {paramName: "bankAccount", valueType: "string", explain: "银行账户", required: false},
            {paramName: "bankAddress", valueType: "string", explain: "银行地址", required: false},
            {paramName: "latestTransactionDate", valueType: "string", explain: "最近成交日期", required: false},
            {paramName: "developmentDate", valueType: "string", explain: "开发日期", required: false},

            {paramName: "contacts", valueType: "array", explain: "联系人信息", required: true},
            {paramName: "contacts:key", valueType: "string", explain: "联系人key", required: true},
            {paramName: "contacts:contactsName", valueType: "string", explain: "联系人姓名", required: true},
            {paramName: "contacts:contactsDepartment", valueType: "string", explain: "联系人部门", required: false},
            {paramName: "contacts:contactsSex", valueType: "string", explain: "性别", required: false},
            {paramName: "contacts:contactsPost", valueType: "string", explain: "职务", required: false},
            {paramName: "contacts:contactsTel", valueType: "string", explain: "电话", required: false},
            {paramName: "contacts:contactsPhone", valueType: "string", explain: "手机号", required: false},
            {paramName: "contacts:contactsFax", valueType: "string", explain: "传真", required: false},
            {paramName: "contacts:contactsEmail", valueType: "string", explain: "邮箱", required: false},
        ]
        this.returnObj = {
            "isSuccess": true,
            "errorMessage": null,
            "data": "32b25b9e26e9468ba748c26bc8f49660",
        }
    },
};
</script>

<style lang="scss" scoped>

</style>