<script>
import Vue from 'vue'
import { reactive, ref, watch, toRef } from '@vue/composition-api'
import axios from '@/assets/js/axios'
import qs from 'qs'
import { getCustomerGroupOptions, getCustomerSourceOptions, getCustomerStatusOptions } from '../api'
import { Message, MessageBox  } from 'element-ui'
import { getBody, getError } from './utils'
export default Vue.extend({
  setup(props, { emit }) {
    const mode = ref('4')
    const onTabClick = ({ name }) => {
        mode.value = name
        if(name == '4' || name == '5'){
            onClear();
        }
    }

    const kehuList = reactive({
      loading: false,
      data: [], // as { customerName: string, email: string, name: string }[]
      checked: [], // as string(email)[]
        pageIndex: 1,
        totalItems: 0,
      dialog: {
        show: false,
        options: {
          customerGroup: [],
          customerSource: [],
          customerStatu: []
        },
        form: {
          customerName: '',
          customerGroupKey: '',
          customerSourceKey: '',
          customerStatuKey: '',
          country: '',
          mainIndustryValue: '',
          star: 0
        }
      }
    })
    Promise.allSettled([getCustomerGroupOptions(), getCustomerSourceOptions(), getCustomerStatusOptions()]).then(([groupRes, sourceRes, statusRes]) => {
      groupRes.status === 'fulfilled' && (kehuList.dialog.options.customerGroup = groupRes.value)
      sourceRes.status === 'fulfilled' && (kehuList.dialog.options.customerSource = sourceRes.value)
      statusRes.status === 'fulfilled' && (kehuList.dialog.options.customerStatu = statusRes.value)
    })
    const getKehuListData = async (option) => {
      try {
        kehuList.loading = true
        kehuList.data = []
        const res = await axios({
          method: 'POST',
          url: Vue.prototype.$marketUrl + '/MassSending/GetCustomerPage',
          data: {
            customerName: '',
            customerGroupKey: '',
            customerSourceKey: '',
            customerStatuKey: '',
            country: '',
            mainIndustryValue: '',
            star: 0,
            ...option,
            "pageIndex": kehuList.pageIndex,
            "pageSize": 50,
          }
        })
        kehuList.data = res.data.datas;
        kehuList.totalItems = res.data.totalItems;
      } catch {
        kehuList.data = []
      } finally {
        kehuList.loading = false
      }
    }
    getKehuListData()

    const pageChange = (page) =>{
        kehuList.pageIndex = page;
        getKehuListData(kehuList.dialog.form)
    }
    const onSearch = () =>{
        kehuList.pageIndex = 1;
        getKehuListData(kehuList.dialog.form).finally(() => kehuList.dialog.show = false)
    }

    const clearFilter = () =>{
        kehuList.dialog.form = {
            customerName: '',
            customerGroupKey: '',
            customerSourceKey: '',
            customerStatuKey: '',
            country: '',
            mainIndustryValue: '',
            star: 0
        }
    }

    const shoudongshuru = reactive({
      form: {
        text: ''
      },
      data: []
    })
    const onShoudongAddClick = () => {
        try {
            if(!shoudongshuru.form.text.trim()){
                throw new Error()
            }
            const textArr = shoudongshuru.form.text.split(/[\n]/);
            console.log(textArr);
            let totalNum = textArr.length;
            let errorNum = 0;
            textArr.forEach(element =>{
                const splitList = element.trim().split('|');
                let email = splitList[0];
                email = email.replace(/ /g,"");
                let name = "";
                let customerName = "";
                if(!(email.indexOf("@") > 1)){
                    errorNum++;
                    return false;
                }
                let reg = /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/
                if(!reg.test(email)){
                    errorNum++;
                    return false;
                }
                if(splitList.length >= 3){
                    name = splitList[1];
                    customerName = splitList[2];
                }else if(splitList.length == 2){
                    name = splitList[1];
                    customerName = "";
                }else{
                    name = email.split('@')[0];
                    customerName = "";
                }
                shoudongshuru.data = shoudongshuru.data.concat([{ name, email, customerName }])           
            })
            let resultTip = `共输入${totalNum}个收件人,成功${totalNum-errorNum}个,错误${errorNum}个。`
            MessageBox.alert(resultTip, '输入结果', {
                confirmButtonText: '确定',
                callback: () => {
                    shoudongshuru.form.text = '';
                }
            });
        } catch {
            Message.warning('输入框不能为空')
        }
    }
    const on2TagClose = index => shoudongshuru.data = shoudongshuru.data.slice(0, index).concat(shoudongshuru.data.slice(index + 1))

    const excelImport = reactive({
      data: [] // as { customerName: string, email: string, name: string }[]
    })
    const uid2Data = new Map()
    const onHttpRequest = (option) => {
      if (typeof XMLHttpRequest === 'undefined') {
        return;
      }
      var xhr = new XMLHttpRequest();
      var action = option.action;
      if (xhr.upload) {
        xhr.upload.onprogress = function progress(e) {
          if (e.total > 0) {
            e.percent = e.loaded / e.total * 100;
          }
          option.onProgress(e);
        };
      }
      var formData = new FormData();
      if (option.data) {
        Object.keys(option.data).forEach(function (key) {
          formData.append(key, option.data[key]);
        });
      }
      formData.append(option.filename, option.file, option.file.name);
      xhr.onerror = function error(e) {
        option.onError(e);
      };
      xhr.onload = function onload() {
        try {
          const res = JSON.parse(xhr.response)
          if (!res.isSuccess) {
            Message.error(res.errorMessage)
            return option.onError(getError(action, option, xhr));
          }
        } catch { null }
        if (xhr.status < 200 || xhr.status >= 300) {
          return option.onError(getError(action, option, xhr));
        }
        option.onSuccess(getBody(xhr));
      };
      xhr.open('post', action, true);
      if (option.withCredentials && 'withCredentials' in xhr) {
        xhr.withCredentials = true;
      }
      var headers = option.headers || {};
      for (var item in headers) {
        // if (headers.hasOwnProperty(item) && headers[item] !== null) {
        if (headers[item] !== null) {
          xhr.setRequestHeader(item, headers[item]);
        }
      }
      xhr.send(formData);
      return xhr;
    }
    const onUploadExcel = (res, file) => {
      const data = res.data.data;
      uid2Data.set(file.uid, {
        load: true,
        data
      })
      excelImport.data = excelImport.data.concat(data)
        //导入结果提示
        MessageBox.alert(res.data.info, '导入结果', {
            confirmButtonText: '确定',
            callback: () => {}
        });
    }
    const onFileClick = (file) => {
      const load = uid2Data.get(file.uid).load
      if (!load) {
        excelImport.data = excelImport.data.concat(uid2Data.get(file.uid).data)
        uid2Data.set(file.uid, { ...uid2Data.get(file.uid), load: true })
      }
    }
    const onFileRemove = (file) => {
      const unit = uid2Data.get(file.uid).data[0]
      const length = uid2Data.get(file.uid).data.length
      const index = excelImport.data.findIndex(item => item === unit)
      if (index !== -1) {
        excelImport.data = excelImport.data.slice(0, index).concat(excelImport.data.slice(index + length))
      }
      uid2Data.delete(file.uid)
    }

    const recipient = ref([]) // T = { customerName: string, email: string, name: string }[]

    watch([toRef(kehuList, 'checked'), toRef(shoudongshuru, 'data'), toRef(excelImport, 'data')], ([v1, v2, v3]) => {
      const res = []
      v1.forEach(email => {
        const item = kehuList.data.find(i => i.email === email)
        item && res.push(item)
      })
      res.push(...v2)
      res.push(...v3)
      recipient.value = res
    })

    const onClear = () => {
      kehuList.checked = []
      shoudongshuru.data = []
      excelImport.data = []
      clueList.checked = [];
      archiveList.checked = [];
      const keys = uid2Data.keys()
      for (const key of keys) {
        uid2Data.set(key, { ...uid2Data.get(key), load: false })
      }
    }

    const clearOne = (item,index) =>{
        recipient.value.splice(index,1);
        if(kehuList.checked.length > 0){
            kehuList.checked = kehuList.checked.filter(ele => ele !== item.email);
        }
        if(shoudongshuru.data.length > 0){
            shoudongshuru.data = shoudongshuru.data.filter(ele => ele.email !== item.email);
        }
        if(excelImport.data.length > 0){
            excelImport.data = excelImport.data.filter(ele => ele.email !== item.email);
        }
        if(clueList.checked.length > 0){
            let isLastDel = recipient.value.map(ele => ele.myClueKey).indexOf(item.myClueKey) == -1;
            if(isLastDel){
                clueList.checked = clueList.checked.filter(key => key != item.myClueKey);
            }
        }
    }

    const onNext = () => {
      if (recipient.value.length) {
        if (recipient.value.length > 5000) {
          Message.warning('收件人数量不能超过5000')
        } else {
          emit('done', recipient.value , clueList.checked)
        }
      } else {
        Message.warning('请选择收件人')
      }
    }

    //我的线索
    const clueList = reactive({
        loading: false,
        data: [],
        checked: [],
        pageIndex: 1,
        totalItems: 0,
    })
    const searchTerms = reactive({
        type: 0,
        keyword: "",
        isContacts: true,
        isMarketing: false,
        sort: 0,
    })
    const getMyClue = async () => {
        try {
            clueList.loading = true;
            clueList.data = []
            const res = await axios({
                method: 'POST',
                url: Vue.prototype.$clueUrl + '/MyClue/GetMyClue',
                data: {
                    ...searchTerms,
                    pageIndex: clueList.pageIndex,
                    pageSize: 50,
                }
            })
            clueList.data = res.data.datas;
            clueList.totalItems = res.data.totalItems;
        } catch {
            clueList.data = []
        } finally {
            clueList.loading = false;
        }
    }
    const pageIndexChange = (page) =>{
        clueList.pageIndex = page;
        getMyClue();
    }
    const clueChoose = (value) =>{
        clueList.checked = value;
        recipient.value = [];
        if(value.length == 0){
            return false;
        }
        getContactsList(clueList.checked);
    }
    const getContactsList = async(myClueKey) =>{
        try {
            const res = await axios.post(`${Vue.prototype.$clueUrl}/MyClue/v3/GetContactsList`,{
                myClueKeys: myClueKey,
                isWa: false,
            });
            res.data.forEach(ele =>{
                ele.customerName = ele.companyName;
            })
            recipient.value = res.data.filter(ele => ele.email != "");
        }catch{
            return null;
        }
    }


    //邮箱存档
    const archiveList = reactive({
      loading: false,
      data: [],
      checked: [],
      pageIndex: 1,
      totalItems: 0,
    })
    const getUserArchive = async () => {
      try {
        archiveList.loading = true;
        archiveList.data = []
        const res = await axios({
          method: 'POST',
          url:  Vue.prototype.$marketUrl + '/MassSending/GetEMailArchive',
          data: {
            pageIndex: archiveList.pageIndex,
            pageSize: 20,
            keyword: "",
          }
        })
        archiveList.data = res.data.datas;
        archiveList.totalItems = res.data.totalItems;
      } catch {
        archiveList.data = []
      } finally {
        archiveList.loading = false;
      }
    }
    getUserArchive();
    const archiveChange = (page) => {
      archiveList.pageIndex = page;
      getUserArchive();
    }
    const chooseArchive = (value) => {
      archiveList.checked = value;
      recipient.value = [];
      getArchiveDetail(archiveList.checked);
    }
    const getArchiveDetail = async (archiveKey) => {
      try {
        const res = await axios.post(Vue.prototype.$marketUrl + '/MassSending/GetEMailArchiveDetail' , archiveKey);
        let arr = [];
        res.data.forEach(ele =>{
            arr.push({ email: ele, name: ele.split('@')[0] })
        })
        recipient.value = arr;
      } catch {
        return null;
      }
    }


    const url = window.location.href
    const index = url.lastIndexOf('?')
    const eventNo = qs.parse(url.slice(index + 1))['eventNo'] || '';
    if(eventNo){
        (async () =>{
            const res = await axios({
                method: 'GET',
                url: Vue.prototype.$marketUrl + '/MassSending/GetEventDetailed',
                params: { eventNo }
            })
            if(res.isSuccess){
                recipient.value = res.data.subscribers;
            }
        })()
    }

    const isClueMass = qs.parse(url.slice(index + 1))['isClueMass'] || '';
    const isContactMass = qs.parse(url.slice(index + 1))['isContactMass'] || '';
    const searchRecordKey = qs.parse(url.slice(index + 1))['searchRecordKey'] || '';
    //公司线索-邮件营销
    if(isClueMass){
        let clueKeyList = localStorage.getItem("clueKeyList").split(',');
        mode.value = "4";
        (async() =>{
            await getMyClue();
            clueChoose(clueKeyList);
        })();
    }else{
        getMyClue();
    }
    //联系人线索-邮件营销
    if(isContactMass){
      let myContacts = JSON.parse(localStorage.getItem('myContacts')).filter(ele => ele.email != "");
      let arr = [];
      myContacts.forEach(ele =>{
        arr.push({ email: ele.email, name: ele.name, customerName: ele.companyName })
      })
      recipient.value = arr;
    }
    //搜联系人邮箱-搜索记录-邮件营销
    if(searchRecordKey){
        mode.value = "5";
        archiveList.checked = [searchRecordKey];
        getArchiveDetail([searchRecordKey])
    }

    return {
      mode,
      onTabClick,
      kehuList,
      clearFilter,
      getKehuListData,
      pageChange,
      onSearch,
      //我的线索
      clueList,
      searchTerms,
      getMyClue,
      pageIndexChange,
      clueChoose,
      getContactsList,
      //邮箱存档
      archiveList,
      getUserArchive,
      archiveChange,
      chooseArchive,
      getArchiveDetail,
      shoudongshuru,
      onShoudongAddClick,
      on2TagClose,
      onHttpRequest,
      onUploadExcel,
      onFileClick,
      onFileRemove,
      recipient,
      onClear,
      clearOne,
      onNext,
      window
    }
  }
})
</script>

<template>
  <div class="content-step-1">
    <el-dialog v-loading="kehuList.loading" title="高级筛选" :visible.sync="kehuList.dialog.show" width="500px" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
      <div style="border: 1px solid #E0E6ED; border-left: none; border-right: none">
        <el-form :model="kehuList.dialog.form" size="mini" label-position="right" label-width="140px" label-suffix="：" style="padding-top: 20px">
          <el-form-item label="公司名称">
            <el-input v-model.trim="kehuList.dialog.form.customerName" placeholder="请输入公司名称" style="width: 217px" />
          </el-form-item>
          <el-form-item label="公司分组">
            <el-select v-model="kehuList.dialog.form.customerGroupKey" style="width: 217px" clearable>
              <el-option v-for="item of kehuList.dialog.options.customerGroup" :key="item.key" :value="item.key" :label="item.name" />
            </el-select>
          </el-form-item>
          <el-form-item label="公司来源">
            <el-select v-model="kehuList.dialog.form.customerSourceKey" style="width: 217px" clearable>
              <el-option v-for="item of kehuList.dialog.options.customerSource" :key="item.key" :value="item.key" :label="item.name" />
            </el-select>
          </el-form-item>
          <el-form-item label="公司状态">
            <el-select v-model="kehuList.dialog.form.customerStatuKey" style="width: 217px" clearable>
              <el-option v-for="item of kehuList.dialog.options.customerStatu" :key="item.key" :value="item.key" :label="item.name" />
            </el-select>
          </el-form-item>
          <el-form-item label="国家/地区">
            <el-input v-model.trim="kehuList.dialog.form.country" style="width: 217px" />
          </el-form-item>
          <el-form-item label="主营产品">
            <el-input v-model.trim="kehuList.dialog.form.mainIndustryValue" style="width: 217px" />
          </el-form-item>
          <el-form-item label="星级">
            <el-rate v-model="kehuList.dialog.form.star" style="display: inline-block" />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer">
        <el-button size="small" @click="kehuList.dialog.show = false">取消</el-button>
        <el-button size="small" @click="clearFilter">清空</el-button>
        <el-button @click="onSearch" type="primary" size="small">搜索</el-button>
      </span>
    </el-dialog>
    <el-button @click="onNext" type="primary" size="mini" style="position: absolute; bottom: 20px; right: 20px">下一步</el-button>
    <div style="display: flex; justify-content: center; width: 100%">
      <el-tabs :value="mode" @tab-click="onTabClick" class="step-1-tabs" style="width: calc(80% + 50px); min-width: 930px; max-width: 1250px">
        <el-tab-pane name="4">
          <template #label>
            <div style="display: flex; align-items: center">
              <span>我的线索</span>
            </div>
          </template>
        </el-tab-pane>
        <el-tab-pane name="5">
          <template #label>
            <div style="display: flex; align-items: center">
              <span>邮箱存档</span>
            </div>
          </template>
        </el-tab-pane>
        <el-tab-pane name="2">
          <template #label>
            <div style="display: flex; align-items: center">
              <span>手动输入</span>
            </div>
          </template>
        </el-tab-pane>
        <el-tab-pane name="3">
          <template #label>
            <div style="display: flex; align-items: center">
              <span>文件导入</span>
            </div>
          </template>
        </el-tab-pane>
        <el-tab-pane name="1">
          <template #label>
            <div style="display: flex; align-items: center">
              <span>客户列表</span>
            </div>
          </template>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div style="height: calc(100% - 50px); display: flex; justify-content: center">
        <div v-show="mode === '1'" v-loading="kehuList.loading" style="box-sizing: border-box; flex-shrink: 0; width: 40%; min-width: 440px; max-width: 600px; height: 100%; border: 1px #DDDDDD solid">
            <div style="height: 100%">
                <div style="height: 60px; padding-left: 20px; display: flex; align-items: center">
                    <el-form size="mini" inline>
                    <el-form-item>
                        <el-input v-model.trim="kehuList.dialog.form.customerName" prefix-icon="el-icon-search" placeholder="搜索客户" />
                    </el-form-item>
                    <el-form-item>
                        <el-button @click="onSearch" type="primary" plain>搜索</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-link type="primary" :underline="false" @click="kehuList.dialog.show = true">高级搜索</el-link>
                    </el-form-item>
                    </el-form>
                </div>
                <div style="height: calc(100% - 60px); overflow-y: auto">
                    <div class="item-step-1">
                    <el-checkbox-group v-model="kehuList.checked">
                        <el-checkbox v-for="item of kehuList.data" :key="item.email" :label="item.email">
                        <div style="color: #9C9C9C">
                            <p><span style="color: #1F2D3D">{{ item.name }}</span> &lt;{{ item.email }}&gt;</p>
                            <p>{{ `${item.customerName}` }}</p>
                        </div>
                        </el-checkbox>
                    </el-checkbox-group>
                    <el-pagination small :hide-on-single-page="true" :page-size="50" background layout="total,prev,pager,next" :total="kehuList.totalItems" style="float:right;margin:0 5px;" :current-page.sync="kehuList.pageIndex" @current-change="pageChange"></el-pagination>
                    </div>
                </div>
            </div>
        </div>
        <div v-show="mode === '2'" v-loading="kehuList.loading" style="box-sizing: border-box; flex-shrink: 0; width: 40%; min-width: 440px; max-width: 600px; height: 100%;">
            <div style="height: 100%">
                <div style="box-sizing: border-box; height: 100%; display: flex; position: relative;">
                    <!-- 使用多行输入框 -->
                    <el-input  type="textarea" v-model="shoudongshuru.form.text" style="width: 100%;height: 100%;" size="mini" placeholder="收件人格式例如：邮箱|收件人名称|公司名称;邮箱必填;一行一个收件人;" />
                    <el-button @click="onShoudongAddClick" type="primary" class="shibie" size="mini">识别</el-button>
                </div>
                <!-- <div style="box-sizing: border-box; height: calc(100% - 400px); padding: 0 5px; overflow-y: auto">
                    <el-tag @close="on2TagClose(index)" closable size="small" v-for="(item, index) of shoudongshuru.data" :key="item.email" style="height: auto; display: flex; width: fit-content; margin-bottom: 10px; align-items: center">
                    <div>
                        <p><span>{{ item.name }}</span> &lt;{{ item.email }}&gt;</p>
                        <p>{{ item.customerName }}</p>
                    </div>
                    </el-tag>
                </div> -->
            </div>
        </div>
        <div v-show="mode === '3'" v-loading="kehuList.loading" style="box-sizing: border-box; flex-shrink: 0; width: 40%; min-width: 440px; max-width: 600px; height: 100%; border: 1px #DDDDDD solid">
            <div style="height: 100%; padding: 0 10px;">
                <p style="height: 32px;line-height: 32px;">
                    使用方法:
                </p>
                <p style="height: 32px;line-height: 32px;">
                    1. <el-link :underline="false" type="primary" href="https://crm.qixinerp.com/ossfile/%E8%90%A5%E9%94%80%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx">下载 Excel </el-link>模板,按照模板格式填入收件人信息
                </p>
                <p style="height: 32px;line-height: 32px;">
                    2. 按照模板格式编辑Excel文件
                </p>
                <p style="height: 32px;line-height: 32px;">
                    3. 收件人个数限制500个
                </p>
                <p style="height: 32px;line-height: 32px;">
                    4. 上传文件
                </p>
                <div style="height: calc(100% - 320px); overflow-y: hidden">
                    <el-upload :http-request="onHttpRequest" :on-remove="onFileRemove" :on-preview="onFileClick" :on-success="onUploadExcel" action="https://crm.qixinerp.com/marketing/MassSending/ExcelExport" :headers="{ Authorization: 'Bearer ' + window.localStorage.getItem('token') }">
                    <el-button type="primary" size="mini">点击上传</el-button>
                    </el-upload>
                </div>
            </div>
        </div>
        <div v-show="mode === '4'" v-loading="clueList.loading" style="box-sizing: border-box; flex-shrink: 0; width: 40%; min-width: 440px; max-width: 600px; height: 100%; border: 1px #DDDDDD solid">
            <div style="height: 60px; padding-left: 20px; display: flex; align-items: center">
                <el-select v-model="searchTerms.type" placeholder="请选择" style="width:120px;" size="mini">
                    <el-option label="联系人名称" :value="0"></el-option>
                    <el-option label="联系人邮箱" :value="1"></el-option>
                    <el-option label="公司名称" :value="2"></el-option>
                    <el-option label="电话" :value="3"></el-option>
                    <el-option label="网址" :value="4"></el-option>
                    <el-option label="法人" :value="5"></el-option>
                </el-select>
                <el-input placeholder="搜索关键字" prefix-icon="el-icon-search" v-model="searchTerms.keyword" style="width:200px;" size="mini"></el-input>
                <el-button type="primary" plain @click="pageIndexChange(1)" style="margin-left:10px;" size="mini">搜 索</el-button>
            </div>
            <div style="height: calc(100% - 60px); overflow-y: auto">
                <div class="item-step-4">
                    <el-checkbox-group v-model="clueList.checked" @change="clueChoose">
                        <el-checkbox v-for="item of clueList.data" :key="item.key" :label="item.key">
                            <div style="color: #1F2D3D;font-weight: bold;">{{item.companyName}}</div>
                            <div style="display: flex;justify-content: space-between;">
                                <div style="color: #919191;">{{item.officialWebsite}}</div>
                                <div style="color: #3a3a3a">
                                    共<span style="padding:0 5px;color:var(--themeColor);">{{item.emailCount}}</span>个邮箱
                                </div>
                            </div>
                        </el-checkbox>
                    </el-checkbox-group>
                    <el-pagination small :hide-on-single-page="true" :page-size="50" background layout="total,prev,pager,next" :total="clueList.totalItems" style="float:right;margin:0 5px;" :current-page.sync="clueList.pageIndex" @current-change="pageIndexChange"></el-pagination>
                </div>
            </div>
        </div>
        <!-- 邮箱存档 -->
        <div v-show="mode === '5'" v-loading="archiveList.loading" style="box-sizing: border-box; flex-shrink: 0; width: 40%; min-width: 440px; max-width: 600px; height: 100%; border: 1px #DDDDDD solid">
            <div style="height: calc(100% - 30px); overflow-y: auto">
            <el-empty description="暂无存档记录" v-if="archiveList.totalItems==0"></el-empty>
            <div class="item-step-4">
                <el-checkbox-group v-model="archiveList.checked" @change="chooseArchive">
                <el-checkbox v-for="item of archiveList.data" :key="item.key" :label="item.key">
                    <div style="display: flex;justify-content: space-between;padding:10px 0;">
                        <div style="color: #1F2D3D;font-weight: bold;">{{ item.keyword }}</div>
                        <div style="color: #3a3a3a">
                            共<span style="padding:0 5px;color:var(--themeColor);">{{ item.emailCount }}</span>个邮箱
                        </div>
                    </div>
                </el-checkbox>
                </el-checkbox-group>
            </div>
            </div>
            <el-pagination small :hide-on-single-page="true" :page-size="20" background layout="total,prev,pager,next" :total="archiveList.totalItems" style="float:right;margin:0 5px;" :current-page.sync="archiveList.pageIndex" @current-change="archiveChange"></el-pagination>
        </div>
      <div style="width: 50px; flex-shrink: 0"></div>
      <div style="flex-shrink: 0; width: 40%; height: 100%; min-width: 440px; max-width: 600px; margin-top: -30px;">
        <div style="height: 30px; align-items: center; display: flex; justify-content: space-between">
          <span>收件人预览</span>
          <div style="display: flex; color: #A8A8A8">
            <span>{{ recipient.length }}/5000</span>
            <div @click="onClear" style="padding-left: 30px; cursor: pointer">
              <i class="el-icon-delete"></i>
              <span>清空</span>
            </div>
          </div>
        </div>
        <div class="rightBox">
            <div class="recipient-box" v-for="(item,index) of recipient" :key="item.email">
                <p><span style="color: #1F2D3D">{{ item.name }}</span> &lt;{{ item.email }}&gt;</p>
                <p>{{ item.customerName }}</p>
                <i class="el-icon-circle-close" @click="clearOne(item,index)"></i>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.content-step-1 {
  box-sizing: border-box;
  height: 100%;
  padding: 20px;
  padding-top: 0;
  position: relative;
}

.step-1-tabs {
  ::v-deep .el-tabs__nav-wrap::after {
    display: none;
  }
}

::v-deep .el-form--inline {
  .el-form-item {
    margin-bottom: 0;
  }
}

.item-step-1 , .item-step-4 {
  margin: 0 10px;
  padding: 10px;
  overflow: hidden;

  ::v-deep .el-checkbox {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #E0E6ED;
    margin-bottom: 10px;
    padding-bottom: 10px;
    margin-right: 30px;
    .el-checkbox__label{
        width: 100%;
    }
  }
}

::v-deep .el-dialog__body {
  padding: 0;
}
::v-deep .el-textarea__inner {
    height: 100%;
}
.shibie{
    flex-shrink: 0; 
    height: 28px;
    margin-left: 15px;
    position: absolute;
    right: 0;
    bottom: 0;
}

.rightBox{
    box-sizing: border-box; 
    height: 100%; 
    border: 1px #DDDDDD solid; 
    overflow-y: auto;
    .recipient-box {
        padding: 10px 20px;
        border-bottom: 1px solid #E0E6ED;
        color: #9C9C9C; 
        font-size: 14px;
        position: relative;
        p{
            height: 20px;
        }
        .el-icon-circle-close{
            display: none;
            cursor: pointer;
            position: absolute;
            right: 20px;
            top: 20px;
        }
        &:hover .el-icon-circle-close{
            display: inline-block;
        }
    }
}
</style>
