<template>
    <div>
        <InterfaceName :interfaceName="interfaceName" />
        <InterfaceSpe :requestUrl="requestUrl" :requestMethod="requestMethod" />
        <InputParam :inputTableData="inputTableData" />
        <ReturnParam :returnObj="returnObj" />
    </div>
</template>

<script>
import InterfaceName from "../Acomponents/InterfaceName.vue";
import InterfaceSpe from "../Acomponents/InterfaceSpe.vue";
import InputParam from "../Acomponents/InputParam.vue";
import ReturnParam from "../Acomponents/ReturnParam.vue";

export default {
    components: { InterfaceName, InterfaceSpe, InputParam, ReturnParam },
    data() {
        return {
            interfaceName: "获取客户动态",
            requestUrl: "https://crm.qixinerp.com/open/Customer/GetCustomerDynamic",
            requestMethod: "POST",
            inputTableData: [],
            returnObj: {},
        };
    },
    methods: {},
    mounted() {
        this.inputTableData = [
            {
                paramName: "customerKey",
                valueType: "string",
                explain: "客户key",
                required: true,
            },
            {
                paramName: "beginTime",
                valueType: "string",
                explain: "开始时间",
                required: false,
            },
            {
                paramName: "endTime",
                valueType: "string",
                explain: "结束时间",
                required: false,
            },
            {
                paramName: "pageIndex",
                valueType: "integer",
                explain: "当前页码",
                required: true,
            },
            {
                paramName: "pageSize",
                valueType: "integer",
                explain: "每页条数",
                required: true,
            },
        ];
        this.returnObj = {
            isSuccess: true,
            errorMessage: null,
            data: {
                followList: {
                    datas: [
                        {
                            key: "26dcb4e7fac74c0f9d9b814cbbc484f5",
                            customerKey: "3108ae8d7f144fde928e4516a4f70408",
                            contactName: "",
                            followType: "快速跟进",
                            followTime: "2022-05-27 15:21:46",
                            followContent: "ooo1112222",
                            follower: "彭贤鑫",
                            followerKey: "af19a3cbd7094bafa19e629b04b482ae",
                            nextFollowTime: null,
                            location: "",
                            annex: [],
                        },
                    ],
                    pageSize: 10,
                    pageIndex: 1,
                    totalItems: 1,
                },
                dateList: [
                    {
                        beginTime: "2022-01-01 00:00:00",
                        endTime: "2022-12-31 23:59:59",
                        year: "2022",
                        count: 1,
                    },
                ],
            },
        };
    },
};
</script>

<style lang="scss" scoped>
</style>