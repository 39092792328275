<template>
    <div class="tabCard">
        <el-tabs>
            <el-tab-pane label="产品分类">
                <el-button v-if="isAdmin" @click="toAddCategory" size="small" type="primary" icon="el-icon-plus" style="margin-bottom:20px;">添加主分类</el-button>
                <span v-else class="setTipText">非管理员无法设置操作,请联系管理员操作</span>
                <div style="padding:5px 0;"></div>
                <el-table :data="productTreeData" v-loading="tableLoading" :header-cell-style="{'background-color':'#F3F3F3','color':'#3A3A3A'}"
                    row-key="id" :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
                    <el-table-column prop="name" label="产品分类名称"></el-table-column>
                    <el-table-column prop="desc" label="描述"></el-table-column>
                    <el-table-column fixed="right" label="操作" width="300" v-if="isAdmin">
                        <template slot-scope="scope">
                            <div class="handleAddBtn" @click="toAddSubclass(scope.row)">添加子分类</div>
                            <div class="handleEditBtn" @click="toEditCategory(scope.row)">编辑</div>
                            <div class="handleDelBtn" @click="toDelCategory(scope.row)">删除</div>
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
            <el-tab-pane label="产品字段">
                <FieldSet :type="2" />
            </el-tab-pane>
        </el-tabs>

        <!-- 添加/编辑分类 -->
        <el-dialog title="添加/编辑分类" :visible.sync="dialogVisible" width="500px">
            <el-form :model="submitData" label-width="100px" size="small">
                <el-form-item label="产品分类名称">
                    <el-input v-model="submitData.groupName"></el-input>
                </el-form-item>
                <el-form-item label="描述">
                    <el-input v-model="submitData.groupDesc"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="onSubmit" size="small" :loading="btnLoading">提 交</el-button>
                <el-button @click="dialogVisible = false;" size="small">取 消</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import FieldSet from "./ComSet/FieldSet.vue";
export default {
    components: { FieldSet },
    data() {
        return {
            isAdmin: false,
            //列表展示
            tableLoading: false,
            productTreeData: [],
            //添加/编辑分类
            dialogVisible: false,
            btnLoading: false,
            submitData: {},
        };
    },
    methods: {
        getProductGroup(){
            this.tableLoading = true;
            this.$axios.get(`${this.$productUrl}/api/ProductGroup/GetAll`).then((res) => {
                const { isSuccess , data } = res;
                this.tableLoading = false;
                if (isSuccess) {
                    this.productTreeData = data;
                }
            });
        },

        //添加分类
        toAddCategory(){
            this.dialogVisible = true;
            this.submitData = {
                "key": "",
                "parentId": 0,
                "groupName": "",
                "groupDesc": "",
            }
        },
        toAddSubclass(row){
            this.dialogVisible = true;
            this.submitData = {
                "key": "",
                "parentId": row.id,
                "groupName": "",
                "groupDesc": "",
            }
        },
        //编辑分类
        toEditCategory(row){
            this.dialogVisible = true;
            this.submitData = {
                "key": row.key,
                "parentId": row.parentId,
                "groupName": row.name,
                "groupDesc": row.desc,
            }
        },
        //提交
        onSubmit(){
            const {groupName} = this.submitData;
            if(!groupName){
                this.$message({message: '请填写产品分类名称!',type: 'warning'});
                return false;
            }
            this.btnLoading = true;
            this.$axios.post(`${this.$productUrl}/api/ProductGroup/AddUpdateGroup`,this.submitData).then(res => {
                const {isSuccess} = res;
                this.btnLoading = false;
                if(isSuccess){
                    this.dialogVisible = false;
                    this.$message({message: '提交成功',type: 'success'});
                    this.getProductGroup();
                }
            });
        },
        //删除分类
        toDelCategory(row){
            this.$confirm(`是否删除该产品分类：<strong style='color:var(--themeColor);'>${row.name}</strong>?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                dangerouslyUseHTMLString: true
            }).then(() => {
                this.$axios.get(`${this.$productUrl}/api/ProductGroup/DeleteProductGroup?key=${row.key}`).then(res => {
                    const {isSuccess} = res;
                    if(isSuccess){
                        this.$message({type: 'success',message: '删除成功!'});
                        this.getProductGroup();
                    }
                });
            }).catch(() => {});
        },

    },
    mounted() {
        this.isAdmin = JSON.parse(localStorage.userInfo).isAdmin;
        this.getProductGroup();
    },
};
</script>

<style lang="scss" scoped>
.tabCard{
    background: #FFFFFF;
    padding: 0 20px 20px;
}

</style>