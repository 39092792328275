import Vue from 'vue'
import Vuex from 'vuex'
import dayjs from '@/firm/plugins/dayjs'

import variables from './scss/element-variables.scss'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        theme: variables.theme,
        experiemntRender: true, //是否显示实验性组件
        done: false, //是否已根据token获取企业信息，开始加载各组件
        region: '', // 国内/国外  'domestic' | 'abroad'
        company: '', //公司名
        companyType: '', //公司类型 'importer' | 'exporter'
        website: '',
        similar: [], //已合并公司
    },
    getters: {
        titleText: state => {
            if (state.companyType === 'importer') {
                return '采购'
            } else if (state.companyType === 'exporter') {
                return '供应'
            } else {
                return ''
            }
        },
        barColor: state => {
            if (state.companyType === 'importer') {
                return '#e99019'
            } else {
                return '#B88D6A'
            }
        }
    },
    mutations: {
        CHANGE_SETTING: (state, { key, value }) => {
            // console.log(state, { key, value });
            if (Object.prototype.hasOwnProperty.call(state, 'key')) {
                state[key] = value
            }
        },
        appStart(state) {
            state.done = true
        },
        //设置企业国内/国外
        setFlagOfDomestic(state, payload) {
            state.region = payload
        },
        //设置公司名
        setCompany(state, payload) {
            state.company = payload
        },
        //设置公司类型
        setType(state, payload) {
            state.companyType = payload
        },
        //设置网址
        setWebsite(state, payload) {
            state.website = payload
        },
        //设置已合并公司
        setSimilar(state, payload) {
            state.similar = payload
        }
    },
    actions: {
        changeSetting({ commit }, data) {
            commit('CHANGE_SETTING', data)
        }
    },
    modules: {
        //全局筛选条件
        controlFormGlobal: {
            namespaced: true,
            state: {
                ie: 'i', //进出口  i e
                dataCountry: [], //数据源国家，三位码
                dateRange: '一年', //日期
                dateRangeNum: 1, //日期数字，仅filter-criteria使用
                hsCode: [], //HS编码
                importer: [], //进口商
                exporter: [], //出口商
                country: [], //国家

                exHsCode: [], //不包含HS编码
                exExporter: [], //不包含出口商
                exImporter: [], //不包含进口商
                exCountry: [], //不包含国家
            },
            getters: {
                /**
                 * @description 开始时间
                 * @listens dateRange
                 * @returns 
                 */
                startTime: state => {
                    if (state.dateRange === '一年') {
                        return dayjs().subtract(1, 'years').format('YYYY-MM-DD')
                    } else if (state.dateRange === '两年') {
                        return dayjs().subtract(2, 'years').format('YYYY-MM-DD')
                    } else if (state.dateRange === '三年') {
                        return dayjs().subtract(3, 'years').format('YYYY-MM-DD')
                    } else if (state.dateRange === '五年') {
                        return dayjs().subtract(5, 'years').format('YYYY-MM-DD')
                    } else if (state.dateRange.includes('自定义')) {
                        return state.dateRange[1] || ''
                    } else {
                        return ''
                    }
                },
                /**
                 * @description 结束时间
                 * @listens dateRange
                 * @returns 
                 */
                endTime: state => {
                    if (state.dateRange === '一年') {
                        return dayjs().format('YYYY-MM-DD')
                    } else if (state.dateRange === '两年') {
                        return dayjs().format('YYYY-MM-DD')
                    } else if (state.dateRange === '三年') {
                        return dayjs().format('YYYY-MM-DD')
                    } else if (state.dateRange === '五年') {
                        return dayjs().format('YYYY-MM-DD')
                    } else if (state.dateRange.includes('自定义')) {
                        return state.dateRange[2] || ''
                    } else {
                        return ''
                    }
                }
            },
            mutations: {
                //设置全局筛选条件
                setControlFormGlobal(state, payload) {
                    for (const item of payload) {
                        const [key, value] = item
                        state[key] = value
                    }
                },
                removeControlFormGlobalItemByIndex(state, payload) {
                    const [key, index] = payload
                    state[key].splice(index, 1)
                }
            }
        },
        anchor: {
            namespaced: true,
            state: {
                ready: false, //是否加载完毕，显示锚点导航
                /* flag: { //用于ready
                  HSBM: false,//HS编码已加载完比
                }, */
                current: '',//当前锚点
                //各锚点
                QYGK: true, //企业概况
                QYXX: false, //企业-企业信息
                SOCIAL: false, //社媒信息推荐
                SOUYU: false, //搜鱼块
                QYDZ: false, //企业-企业地址
                CWXX: false, //企业-财务信息
                LXR: false, //企业-联系人信息
                LXRDUNS: false, //企业-duns联系人信息
                GYXX: false, //企业-雇员信息
                USSIC: false, //企业-UsSIC
                NAICS: false, //企业-NAICS
                CYM: false, //企业-曾用名
                SHPL: false, //企业-社会评论
                LSGYQK: false, //企业-历史雇佣情况
                YH: false, //企业-银行信息
                JZDS: false, //企业-竞争对手
                ZBXJ: false, //企业-资本细节
                QTXX: false, //企业-其它信息
                //全景分析
                JZDSXX: true, //竞争对手信息，企业图谱
                QYTPRECT: true, //企业图谱
                COUNTRYEN: true, //国家企业关系
                QSFX: true, //趋势分析
                MYZQ: true, //贸易周期分析
                HZSJ: true, //汇总数据
                JKSPM: true, //进口商排名
                CKSPM: true, //出口商排名
                GJFX: true, //国家分析
                SSZ: true, //所属洲
                SITC: true, //SITC delete
                SITCRANK: true, // 采购/供应 SITC排名
                SITCCOUNTRY: true, //sitc国家分析
                SITCCOUNTRYC: true,//sitc国家供应商分析
                SITCCOMPANY: true, //sitc 供应/采购分析
                HY: false, //行业信息
                HSBM6: true, //HS编码-6
                HSBM6C: true, //六位HS编码国家分析
                HSBM6CC: true, //六位HS编码国家供应商分析
                HSBM6CCC: true, //六位编码供应商分析
                HSBM4: true, //HS编码-4
                HSBM4C: true, //四位HS编码国家分析
                HSBM4CC: true, //4位HS编码国家供应商分析
                HSBM4CCC: true, //4位编码供应商分析
                HSBM2: true, //HS编码-2
                HSBM2C: true,//二位HS编码国家分析
                HSBM2CC: true, //2位HS编码国家供应商分析
                HSBM2CCC: true, //2位编码供应商分析
                COUNTRENCODE: true, //国家编码分析
                COUNTRYENCODECOM: true, //国家编码采购/供应分析
                COUNTRYCOM: true,//国家 供应/采购商分析
                COUNTRYCOMENCODE: true, //国家  供应/采购商 编码 分析
                COMPAANLYSE: true,//进出口商分析
                COMPECODE: true,//供应/采购 编码分析
                COMPECODECOUNTR: true,//供应/采购 编码国家分析
                COMPIMPORTER: true,//供应/采购 对应采购商分析
                COMPIMPORTERENCODE: true,//供应/采购 对应采购商的编码分析
                COMPEXPORTER: true,//供应/采购 对应供应商分析
                COMPEXPORTERENCODE: true,//供应/采购 对应供应商的编码分析
                GNWQYG: true,//国内外起运港
                GNGK: true, //国内目的港
                GNWXHG: true,//国内外卸货港
                GWGK: true, //国外目的港
                //交易记录
                TABJYJL: true, //交易记录
                // 关系图
                gxtixcc: true, //采购供应商国家编码分布图
                gxticce: true, //采购国家编码供应商分布图
                gtxicdce: true, //采购编码国家供应商分布图
            },
            mutations: {
                setAnchorFlag(state, payload) {
                    for (const item of payload) {
                        const [key, value] = item
                        state[key] = value
                    }
                },
                setCurrent(state, payload) {
                    state.current = payload
                },
            }
        }
    }
})
