<template>
    <div>
        <InterfaceName :interfaceName="interfaceName" />
        <InterfaceSpe :requestUrl="requestUrl" :requestMethod="requestMethod" />
        <ReturnParam :returnObj="returnObj" />
    </div>
</template>

<script>
import InterfaceName from "../Acomponents/InterfaceName.vue";
import InterfaceSpe from "../Acomponents/InterfaceSpe.vue";
import ReturnParam from "../Acomponents/ReturnParam.vue";

export default {
    components: { InterfaceName, InterfaceSpe, ReturnParam },
    data() {
        return {
            interfaceName: "获取私海分组",
            requestUrl: "https://crm.qixinerp.com/open/Customer/GetPrivateGroup",
            requestMethod: "GET",
            returnObj: {},
        };
    },
    methods: {},
    mounted() {
        this.returnObj = {
            isSuccess: true,
            errorMessage: null,
            data: [
                {
                    key: "7a326d25938d47a29796f70b7df07ac3",
                    name: "无分组",
                    index: 992,
                    isDefault: false,
                    isSystem: true,
                },
                {
                    key: "e01c590860494c6caa74052ecd5ebbbb",
                    name: "一般合作客户",
                    index: 998,
                    isDefault: false,
                    isSystem: false,
                },
                {
                    key: "bcd0e2d7273d41098c0968e224d23dfc",
                    name: "VIP合作客户",
                    index: 999,
                    isDefault: false,
                    isSystem: false,
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>

</style>