<template>
    <div>
        <div class="searchBox">
            <div class="title">
                <span>其他信息</span>
            </div>
            <el-form :model="otherForm" labelPosition='top' size="small">
                <el-row :gutter="30">
                    <el-col :span="6">
                        <el-form-item label="语言">
                            <el-select v-model="otherForm.languages" @change="fieldChange" filterable clearable placeholder="语言" style="width:100%;">
                                <el-option v-for="item of oneOption" :key="item.code" :label="item.name" :value="item.code" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="商业形式">
                            <el-select v-model="otherForm.legalStatusCodes" @change="fieldChange" filterable clearable placeholder="商业形式" style="width:100%;">
                                <el-option v-for="item of twoOption" :key="item.code" :label="item.name" :value="item.code" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="公司状态">
                            <el-select v-model="otherForm.statusCodes" @change="fieldChange" placeholder="公司状态" style="width:100%;">
                                <el-option v-for="item of threeOption" :key="item.code" :label="item.name" :value="item.code" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="工商编号">
                            <el-input v-model="otherForm.nationalID" @change="fieldChange" placeholder="工商编号" style="width:100%;"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="购买力区间">
                            <el-input type="number" v-model="otherForm.publishingStrengthFrom" @change="fieldChange" style="width:calc(50% - 20px)"></el-input>
                            <span style="width:40px;display: inline-block;text-align: center;">至</span>
                            <el-input type="number" v-model="otherForm.publishingStrengthTo" @change="fieldChange" style="width:calc(50% - 20px)"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="注册时间区间">
                            <el-date-picker v-model="otherForm.yearStartedFrom" @change="fieldChange" type="date" value-format="yyyy-MM-dd" placeholder="起始年份" style="width:calc(50% - 20px)"></el-date-picker>
                            <span style="width:40px;display: inline-block;text-align: center;">至</span>
                            <el-date-picker v-model="otherForm.yearStartedTo" @change="fieldChange" type="date" value-format="yyyy-MM-dd" placeholder="结束年份" style="width:calc(50% - 20px)"></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="筛选管理员记录">
                            <el-checkbox v-model="otherForm.hasAdmin" @change="fieldChange">筛选有管理员记录</el-checkbox>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="筛选销售记录">
                            <el-checkbox v-model="otherForm.hasMarketability" @change="fieldChange">筛选可销售记录</el-checkbox>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            otherForm: {
                languages: "",
                legalStatusCodes: "",
                statusCodes: 0,
                nationalID: "",
                publishingStrengthFrom: "",
                publishingStrengthTo: "",
                yearStartedFrom: "",
                yearStartedTo: "",
                hasAdmin: false,
                hasMarketability: false,
            },
            oneOption: [],
            twoOption: [],
            threeOption: [],
        };
    },
    methods: {
        //查询infobel Language信息
        getLanguage(){
            this.$axios.get(`${this.$clueUrl}/BusinessData/Infobel/Language`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.oneOption = data;
                }
            });
        },
        //查询infobel Legalstatuscode信息
        getLegalstatuscode(){
            this.$axios.get(`${this.$clueUrl}/BusinessData/Infobel/Legalstatuscode`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.twoOption = data;
                }
            });
        },

        fieldChange(){
            this.$emit('inputComplete',this.otherForm);
        },
    },
    mounted() {
        this.getLanguage();
        this.getLegalstatuscode();
        this.threeOption = [
            {code: 0, name: '不限 - All'},
            {code: 1, name: '单一位置 - SingleLocation'},
            {code: 2, name: '总部 - HQ'},
            {code: 3, name: '分店 - Branch'},
        ]
    },
};
</script>

<style lang="scss" scoped>
.searchBox{
    border-top: 1px solid #E6E6E6;
    padding-left: 40px;
    .title{
        color: #3A3A3A;
        font-weight: bold;
        font-size: 16px;
        margin: 10px 0;
    }
}
</style>