<template>
    <div>
        <InterfaceName :interfaceName="interfaceName" />
        <InterfaceSpe :requestUrl="requestUrl" :requestMethod="requestMethod" />
        <InputParam :inputTableData="inputTableData" />
        <ReturnParam :returnObj="returnObj" />
    </div>
</template>

<script>
import InterfaceName from "../Acomponents/InterfaceName.vue";
import InterfaceSpe from "../Acomponents/InterfaceSpe.vue";
import InputParam from "../Acomponents/InputParam.vue";
import ReturnParam from "../Acomponents/ReturnParam.vue";

export default {
    components: { InterfaceName, InterfaceSpe, InputParam, ReturnParam },
    data() {
        return {
            interfaceName: "获取客户支出",
            requestUrl: "https://crm.qixinerp.com/open/Customer/GetCustomerPay",
            requestMethod: "POST",
            inputTableData: [],
            returnObj: {},
        };
    },
    methods: {},
    mounted() {
        this.inputTableData = [
            {
                paramName: "customerKey",
                valueType: "string",
                explain: "客户key",
                required: true,
            },
            {
                paramName: "pageIndex",
                valueType: "integer",
                explain: "当前页码",
                required: true,
            },
            {
                paramName: "pageSize",
                valueType: "integer",
                explain: "每页条数",
                required: true,
            },
        ];
        this.returnObj = {
            isSuccess: true,
            errorMessage: null,
            data: {
                datas: [
                    {
                        key: "a5679b144a7e49efbbdac94d178461c6",
                        collectionNo: "HB20220527153110556",
                        payeeCollectionDate: "2022-05-27",
                        payee: "跨境搜信息科技有限公司",
                        payerContacts: "王五",
                        collectionCurrency: "CNY",
                        collectionAmount: 100,
                        weCompany: "",
                        handler: "彭贤鑫",
                        remarks: "",
                    },
                ],
                pageSize: 10,
                pageIndex: 1,
                totalItems: 1,
            },
        };
    },
};
</script>

<style lang="scss" scoped>
</style>