<script>
import Vue from 'vue'
import { reactive } from '@vue/composition-api'
import axios from '@/assets/js/axios'
export default Vue.extend({
  props: ['campaignId'],
  setup(props) {
    const campaignId = Number(props.campaignId)

    const form = reactive({
      country: {
        value: '',
        option: []
      },
      device: {
        value: '',
        option: []
      },
      lang: {
        value: '',
        option: []
      },
      date: {
        value: 0,
        option: [
          { key: 0, name: '全部' },
          { key: 1, name: '发信后3天内' },
          { key: 2, name: '发信后7天内' },
          { key: 3, name: '发信后30天内' }
        ]
      },
      recipient: {
        value: ''
      }
    })
    axios({
      method: 'GET',
      url: Vue.prototype.$marketUrl + '/MassSending/GetCountryBox',
      params: {
        campaignId
      }
    }).then(res => {
      form.country.option = res.data
    })
    axios({
      method: 'GET',
      url: Vue.prototype.$marketUrl + '/MassSending/GetDeviceBox',
      params: {
        campaignId
      }
    }).then(res => {
      form.device.option = res.data
    })
    axios({
      method: 'GET',
      url: Vue.prototype.$marketUrl + '/MassSending/GetLanguageBox',
      params: {
        campaignId
      }
    }).then(res => {
      form.lang.option = res.data
    })

    const table = reactive({
      loading: false,
      data: [],
      page: {
        total: 0,
        index: 1,
        size: 10
      }
    })
    const getTable = async option => {
      try {
        table.loading = true
        const res = await axios({
          method: 'POST',
          url: Vue.prototype.$marketUrl + '/MassSending/GetOpenEmailRecord',
          data: {
            pageIndex: table.page.index,
            pageSize: table.page.size,
            campaignId,
            addressee: form.recipient.value,
            country: form.country.value,
            device: form.device.value,
            language: form.lang.value,
            date: form.date.value,
            ...option
          }
        })
        table.data = res.data.datas
        table.page.index = res.data.pageIndex
        table.page.total = res.data.totalItems
      } catch {
        table.data = []
      } finally {
        table.loading = false
      }
    }
    getTable()

    return {
      form,
      table,
      getTable
    }
  }
})
</script>

<template>
  <div v-loading="table.loading" style="height: 100%; overflow-y: auto; font-size: 14px">
    <div style="height: 46px; overflow-y: hidden">
      <el-form :model="form" inline size="mini" label-suffix=":">
        <el-form-item label="国家">
          <el-select v-model="form.country.value" placeholder="选择国家" style="width: 100px">
            <el-option v-for="item of form.country.option" :key="item.key" :value="item.key" :label="item.name" />
          </el-select>
        </el-form-item>
        <el-form-item label="设备">
          <el-select v-model="form.device.value" placeholder="选择设备" style="width: 100px">
            <el-option v-for="item of form.device.option" :key="item.key" :value="item.key" :label="item.name" />
          </el-select>
        </el-form-item>
        <el-form-item label="语言">
          <el-select v-model="form.lang.value" placeholder="选择语言" style="width: 100px">
            <el-option v-for="item of form.lang.option" :key="item.key" :value="item.key" :label="item.name" />
          </el-select>
        </el-form-item>
        <el-form-item label="看信时间">
          <el-select v-model="form.date.value" placeholder="选择看信时间" style="width: 120px">
            <el-option v-for="item of form.date.option" :key="item.key" :value="item.key" :label="item.name" />
          </el-select>
        </el-form-item>
        <el-form-item label="收件人">
          <el-input v-model.trim="form.recipient.value" placeholder="请输入收件人" style="width: 160px" />
        </el-form-item>
        <el-form-item>
          <el-button @click="getTable({ pageIndex: 1 })" type="primary">筛选</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="table.data" size="mini" height="calc(100% - 80px)">
      <el-table-column prop="email" label="收件人" show-overflow-tooltip />
      <el-table-column prop="companyName" label="公司" show-overflow-tooltip />
      <el-table-column prop="position" label="职位" show-overflow-tooltip />
      <el-table-column prop="opneTime" label="看信时间" show-overflow-tooltip />
      <el-table-column prop="countryStd" label="国家" />
      <el-table-column prop="city" label="城市" />
      <el-table-column prop="browser" label="浏览器" />
      <el-table-column prop="deviceStd" label="设备" />
      <el-table-column prop="languageStd" label="语种" />
    </el-table>
    <div style="display: flex; justify-content: flex-end">
      <el-pagination @current-change="getTable({ pageIndex: $event })" :total="table.page.total" :page-size="table.page.size" background layout="total, prev, pager, next" />
    </div>
  </div>
</template>
