<template>
    <div>
        <InterfaceName :interfaceName="interfaceName" />
        <InterfaceSpe :requestUrl="requestUrl" :requestMethod="requestMethod" />
        <InputParam :inputTableData="inputTableData" />
        <ReturnParam :returnObj="returnObj" />
    </div>
</template>

<script>
import InterfaceName from "../Acomponents/InterfaceName.vue";
import InterfaceSpe from "../Acomponents/InterfaceSpe.vue";
import InputParam from "../Acomponents/InputParam.vue";
import ReturnParam from "../Acomponents/ReturnParam.vue";

export default {
    components: { InterfaceName, InterfaceSpe, InputParam, ReturnParam },
    data() {
        return {
            interfaceName: "更新员工信息",
            requestUrl: "https://crm.qixinerp.com/open/Staff/UpdateUser",
            requestMethod: "POST",
            inputTableData: [],
            returnObj: {},
        };
    },
    methods: {},
    mounted() {
        this.inputTableData = [
            {paramName: "ukey", valueType: "string", explain: "用户ukey", required: true},
            {paramName: "name", valueType: "string", explain: "名称", required: true},
            {paramName: "account", valueType: "string", explain: "账户", required: true},
            {paramName: "phone", valueType: "string", explain: "手机号", required: true},
            {paramName: "headImg", valueType: "string", explain: "头像", required: false},
            {paramName: "position", valueType: "string", explain: "职位", required: false},
            {paramName: "sex", valueType: "integer", explain: "性别 0 女 1 男 2未知", required: false},
        ];
        this.returnObj = {
            isSuccess: true,
            errorMessage: null,
            data: true,
        };
    },
};
</script>

<style lang="scss" scoped>

</style>