<script>
import Vue from 'vue'
import CMenu from '../MarketingWA/components/Menu.vue'
export default Vue.extend({
  components: { CMenu }
})
</script>

<template>
  <div style="display: flex; box-sizing: border-box;width: 100%;height: 100%;">
    <div>
      <CMenu active="TXSearch" />
    </div>
    <div class="view">

    </div>
  </div>
</template>

<style scoped lang="scss">
.view {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background: #fff;
}
</style>
