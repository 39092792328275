<script>
import Vue from "vue";
import { reactive, ref } from "@vue/composition-api";
import { Message } from "element-ui";
import axios from "axios";
import qs from "qs";

export default Vue.extend({
    setup(props, { emit }) {
        const loading = ref(false);
        const isWriteEmail = ref(true);
        const form = reactive({
            subject: "",
            bodyHtml: "",
            templateKey: "",
        });

        const formvgvcghc = reactive({
            eventKey: "",
            eventName: "",
            contacts: [],
            whatsApp: "",
            sendType: "text",
            templateKey: "",
            templateContent: "",
            sendText: "",
            sendFileUrl: "",
            sendFileName: "",
            isTiming: false,
            sendTime: "",
            isFilterWhatsapp: false,
        });
        const onDone = () => {
            const val = JSON.parse(JSON.stringify(formvgvcghc));
            console.log(val);
            if (formvgvcghc.templateKey) {
                val.sendType = "template";
                if (formvgvcghc.eventName) {
                    emit("done", val);
                    return;
                } else {
                    Message.warning("请输入任务名称");
                }
            } else {
                if (formvgvcghc.eventName && formvgvcghc.sendText) {
                    emit("done", val);
                    return;
                } else {
                    Message.warning("请输入任务名称和消息内容");
                }
            }
        };
        const uerinfLoadin = ref(false);
        const uerinf = ref({});
        (async () => {
            try {
                uerinfLoadin.value = true;
                const res = await axios({
                    method: "GET",
                    url: Vue.prototype.$marketUrl + "/WhatsApp/v2/FindConfig",
                });
                uerinf.value = res.data;
                formvgvcghc.whatsApp = res.data.whatsApp;
            } catch (error) {
                null;
            } finally {
                uerinfLoadin.value = false;
            }
        })();

        // 获取模板列表
        const templateList = ref();
        const getTemplate = async () => {
            templateList.value = [];
            try {
                const res = await axios({
                    method: "GET",
                    url: Vue.prototype.$marketUrl + "/WhatsApp/v2/GetTemplates",
                });
                if (res.isSuccess) {
                    templateList.value = res.data;
                }
            } catch {
                templateList.value = [];
            }
        };
        getTemplate();

        // 使用模板
        const useTemp = async (item) => {
            formvgvcghc.templateKey = item.key;
            formvgvcghc.templateContent = item.content;
            isWriteEmail.value = true;
        };
        const goBack = () => {
            isWriteEmail.value = false;
        };
        const onPreview = (ele) => {
            window.open(ele.htmlImg);
        };

        const url = window.location.href;
        const index = url.lastIndexOf("?");
        const eventNo = qs.parse(url.slice(index + 1))["eventNo"] || "";
        if (eventNo) {
            (async () => {
                const res = await axios({
                    method: "GET",
                    url:
                        Vue.prototype.$marketUrl +
                        "/WhatsApp/v2/GetSendEventDetailed",
                    params: { eventKey: eventNo },
                });
                if (res.isSuccess) {
                    formvgvcghc.eventKey = res.data.eventKey;
                    formvgvcghc.eventName = res.data.eventName;
                    formvgvcghc.sendText = res.data.sendText;
                    formvgvcghc.templateKey = res.data.templateBody.key;
                    formvgvcghc.templateContent = res.data.templateBody.content;
                    formvgvcghc.isTiming = Boolean(res.data.sendTime);
                    formvgvcghc.sendTime = res.data.sendTime || "";
                    formvgvcghc.isFilterWhatsapp = Boolean(
                        res.data.filterCount
                    );
                }
            })();
        }

        //不使用模板
        const noTemplate = () => {
            formvgvcghc.templateKey = "";
            formvgvcghc.templateContent = "";
        };

        return {
            formvgvcghc,
            uerinfLoadin,
            uerinf,
            noTemplate,
            loading,
            isWriteEmail,
            templateList,
            form,
            onDone,
            getTemplate,
            useTemp,
            goBack,
            onPreview,
            onSave() {
                const val = JSON.parse(JSON.stringify(formvgvcghc));
                if (formvgvcghc.templateKey) {
                    val.sendType = "template";
                }
                emit("save", val);
            },
            rgweffwef(url) {
                window.open(url, "_blank");
            },
        };
    },
});
</script>

<template>
    <div class="content-step-2">
        <div style="position: absolute; bottom: 20px; right: 20px;z-index: 9999;">
            <el-button @click="$emit('prev')" type="primary" size="mini">上一步</el-button>
            <el-button @click="onSave" type="primary" size="mini">保存草稿</el-button>
            <el-button @click="onDone" type="primary" size="mini">发送</el-button>
        </div>
        <div style="margin: 0 auto;width: 80%;display: flex;">
            <div class="chooseTemplate" v-show="!isWriteEmail" style="position: absolute">
                <div class="title">我的模板</div>
                <div style="display: flex; flex-wrap: wrap;">
                    <div class="afagregerg" v-for="(itemmmm, index) of templateList" :key="index" style=" border:1px solid #c9c9c9; margin-bottom: 10px;margin-right: 10px;box-sizing: border-box;width: 385px;padding: 16px 33px;background: #fff;margin-bottom: 20px;">
                        <div class="b">{{ itemmmm.templateName }}</div>
                        <div style="width: 100%;word-break: break-all;position: relative;">
                            <div class="fsfgdf" @click="useTemp(itemmmm)">使用</div>
                            <div style="margin-top:5px;">{{itemmmm.content}}</div>

                        </div>
                    </div>
                </div>

            </div>
            <div class="writeEmail" style="width: 50%;flex-shrink: 0;" v-show="isWriteEmail">
                <div v-loading="uerinfLoadin">
                    <span style="color: #092338">使用的WhatsApp账号：</span>
                    <div class="asd" style="display: inline-flex;position: relative;align-items: center;">
                        <img style="width: 25px; height:25px;position: absolute;left: 16px;z-index: 200;" :src="uerinf.whatsAppHeadImg || require('@/assets/img/headImg.png')" />
                        <el-select :value="uerinf.whatsApp" popper-class="custome-sleect" disabled>
                            <el-option value="1"></el-option>
                        </el-select>
                    </div>
                </div>
                <div style="margin-top: 20px;margin-bottom: 18px;">
                    <span style="color: #092338">任务名称：</span>
                    <div style="display: inline-flex;position: relative;align-items: center;">
                        <el-input v-model="formvgvcghc.eventName" placeholder="任务名称" size="small" />
                    </div>
                </div>
                <div style="padding: 18px 0">群发消息内容：
                    <span @click="goBack" style="color:#004594;cursor: pointer;">选择模板</span>
                </div>
                <div v-if="formvgvcghc.templateKey">
                    <span @click="noTemplate" style="color:#004594;cursor: pointer;">不使用模板</span>
                </div>
                <div class="EditorContent">
                    <div class="texta-safg" style="position: reactive">

                        <el-input v-if="!formvgvcghc.templateKey" v-model="formvgvcghc.sendText" type="textarea" :rows="6" placeholder="请输入内容"></el-input>
                    </div>
                </div>
                <el-form inline size="small" style="margin-top: 20px">
                    <el-form-item>
                        <el-checkbox v-model="formvgvcghc.isTiming" style="margin-right: 6px">定时发送</el-checkbox>
                        <el-date-picker ref="datepicker" v-model="formvgvcghc.sendTime" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" size="mini" placeholder="定时发送时间" />
                    </el-form-item>
                    <el-form-item>
                        <el-checkbox v-model="formvgvcghc.isFilterWhatsapp" style="margin-right: 6px">是否过滤已发送</el-checkbox>
                    </el-form-item>
                </el-form>
            </div>
            <div v-show="isWriteEmail" style="width: 50%;margin-left: 20px;height: calc(100vh - 240px); overflow-y: auto;">
                <div class="afagregerg" v-if="formvgvcghc.templateKey" style="box-sizing: border-box;width: 385px;padding: 16px 33px;background: #fff;border-radius: 8px;margin-bottom: 20px;">
                    <div style="width: 100%;word-break: break-all;position: relative;">
                        {{formvgvcghc.templateContent}}
                    </div>
                </div>
                <div v-else>
                    <div>{{ formvgvcghc.sendText }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.fsfgdf {
    display: none;
    position: absolute;
    z-index: 100;
    width: 100%;
    height: 28px;
    line-height: 28px;
    color: #fff;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: -2em;
    left: 0;
    cursor: pointer;
}

.b {
    font-size: 14px;
    padding: 0 15px;
    height: 27px;
    line-height: 27px;
    border-radius: 27px;
    width: fit-content;
    background: #d6f4e3;
    color: #2dc76d;
}

.afagregerg:hover {
    .fsfgdf {
        display: block;
    }
}

.texta-safg {
    ::v-deep .el-textarea__inner {
        // padding-top: 40px;
        padding-top: 10px;
    }
}

.content-step-2 {
    box-sizing: border-box;
    height: 100%;
    padding: 20px;
    padding-top: 0;
    padding-bottom: 0;
    position: relative;
}

.el-input-no-border {
    ::v-deep .el-input__inner {
        border: none;
    }
}

.c-el-date-picker {
    ::v-deep .el-input__inner {
        border: none;
    }
}

.chooseTemplate {
    height: calc(100% - 30px);
    overflow: auto;

    .title {
        height: 28px;
        line-height: 28px;
        font-weight: bold;
        margin-bottom: 10px;
    }

    .templateList {
        display: flex;
        flex-wrap: wrap;

        .viewMore {
            width: 150px;
            height: 200px;
            text-align: center;
            line-height: 200px;

            .el-link {
                font-size: 20px;
            }
        }
    }

    .templateItem {
        width: 150px;
        height: 200px;
        border: 1px solid #c9c9c9;
        padding: 10px;
        box-sizing: border-box;
        cursor: pointer;
        margin-right: 20px;
        margin-bottom: 40px;
        text-align: center;
        position: relative;

        .el-image {
            width: 128px;
            height: 178px;
            object-fit: cover;
        }

        .el-icon-plus {
            font-size: 40px;
            line-height: 178px;
        }

        .theme {
            margin-top: 10px;
            color: #6a6a6a;
            font-size: 14px;
        }
    }

    .backdrop {
        width: 150px;
        height: 200px;
        display: none;
        z-index: 100;
        background-color: rgba(0, 0, 0, 0.3);
        position: absolute;
        top: 0;
        left: 0;

        .preview {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 60px;
            height: 30px;
            line-height: 30px;
            background-color: #ccc;
            color: #fff;
        }

        .touse {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 60px;
            height: 30px;
            line-height: 30px;
            background-color: #ccc;
            color: #fff;
        }
    }

    .templateItem:hover .backdrop {
        display: inline-block;
    }
}

.writeEmail {
    height: calc(100% - 30px);
    overflow: auto;
}

.EditorContent {
    position: relative;

    .selectBtn {
        position: absolute;
        top: 4px;
        left: 650px;
        z-index: 1000;
        width: 120px;
    }

    ::v-deep .el-input__inner {
        border: none;
    }
}

.asd {
    ::v-deep .el-input__inner {
        padding-left: 45px !important;
    }
}

.custome-sleect {
    * {
        line-height: 40px !important;
        height: 40px;
    }

    .el-select-dropdown__item {
        padding: 0 10px;
    }
}
</style>
