<template>
<div>
    <ClueNav @chooseNav="chooseNav" :currentPath="currentPath" />
    <div class="viewbox">
        <!-- <div class="header">
            <img class="logo" src="@/assets/cluePicture/logo-commercial.png" alt="" />
            <div>
                <div class="theme">商业数据线索</div>
                <div class="txt">统计来自商业数据的相关线索</div>
            </div>
        </div> -->

        <div class="searchTerms">
            <div class="itemTerm">
                <div class="name">洲:</div>
                <div class="spanList">
                    <div class="spanItem" :class="{'active':continentIndex==''}" @click="getCountrys('')">不限</div>
                    <div class="spanItem" v-for="ele in continentList" :key="ele.name" :class="{'active':continentIndex==ele.name}" @click="getCountrys(ele.name)">
                        {{ele.name}}({{ele.count}})
                    </div>
                </div>
            </div>
            <div class="itemTerm">
                <div class="name">数据源:</div>
                <div class="spanList">
                    <div class="spanItem" :class="{'active':dataSourceIndex==''}" @click="clickCountry('')">不限</div>
                    <div class="spanItem" v-for="(ele,index) in dataSourceList" v-show="index < showCountryNumMax" :key="ele.country" :class="{'active':dataSourceIndex==ele.country}" @click="clickCountry(ele.country)">
                        {{ele.cnCountry}}({{ele.count}})
                    </div>
                    <div class="spanItem" style="color:var(--themeColor);" v-show="showCountryNumMax < dataSourceList.length" @click="showCountryNumMax = dataSourceList.length;">查看更多</div>
                </div>
            </div>
            <div class="itemTerm">
                <div class="name">行业代码:</div>
                <div class="spanItem" v-for="(ele,index) in chooseSicCode" :key="ele">
                    <span>{{ele}}</span>
                    <!-- 清除单个 -->
                    <i class="el-icon-close" @click="chooseSicCode.splice(index,1)"></i>
                </div>
                <div class="spanItem">
                    <el-button type="primary" size="mini" @click="openChoose">选择行业代码</el-button>
                    <el-button size="mini" v-show="chooseSicCode.length!=0" @click="chooseSicCode = [];">清除</el-button>
                </div>
            </div>
            <div class="itemTerm">
                <div class="name">实体类型:</div>
                <div class="spanList">
                    <div class="spanItem" :class="{'active':entityType==''}" @click="changeOne('')">不限</div>
                    <div class="spanItem" v-for="ele in optionOne" :key="ele.name" :class="{'active':entityType==ele.name}" @click="changeOne(ele.name)">
                        {{ele.name}}({{ele.count}})
                    </div>
                </div>
                <!-- <el-select v-model="entityType" placeholder="请选择" size="small" clearable filterable @change="changeOne">
                    <el-option v-for="item in optionOne" :key="item.name" :label="item.name" :value="item.name">
                        {{item.name}} - {{item.count}}
                    </el-option>
                </el-select> -->
            </div>
            <div class="itemTerm">
                <div class="name">公司状态:</div>
                <div class="spanList">
                    <div class="spanItem" :class="{'active':status==''}" @click="changeTwo('')">不限</div>
                    <div class="spanItem" v-for="ele in optionTwo" :key="ele.name" :class="{'active':status==ele.name}" @click="changeTwo(ele.name)">
                        {{ele.name}}({{ele.count}})
                    </div>
                </div>
                <!-- <el-select v-model="status" placeholder="请选择" size="small" clearable filterable @change="changeTwo">
                    <el-option v-for="item in optionTwo" :key="item.name" :label="item.name" :value="item.name">
                        {{item.name}} - {{item.count}}
                    </el-option>
                </el-select> -->
            </div>
            <!-- <div class="itemTerm">
                <div class="name">二位编码:</div>
                <div class="spanList">
                    <div class="spanItem" :class="{'active':twoCodeIndex==''}" @click="getFourCode('')">不限</div>
                    <div class="spanItem" v-for="(ele,index) in twoCodeList" v-show="index < showTwoCodeNumMax" :key="ele.name" :class="{'active':twoCodeIndex==ele.name}" @click="getFourCode(ele.name)">
                        {{ele.name}}
                    </div>
                    <div class="spanItem" style="color:var(--themeColor);" v-show="showTwoCodeNumMax < twoCodeList.length" @click="showTwoCodeNumMax = twoCodeList.length;">查看更多</div>
                </div>
            </div> -->
            <!-- <div class="itemTerm" v-show="isShowFourCode">
                <div class="name">四位编码:</div>
                <div class="spanList">
                    <div class="spanItem" :class="{'active':fourCodeIndex==''}" @click="clickFour('')">不限</div>
                    <div class="spanItem" v-for="(ele,index) in fourCodeList" v-show="index < showFourCodeNumMax" :key="ele.name" :class="{'active':fourCodeIndex==ele.name}" @click="clickFour(ele.name)">
                        {{ele.name}}
                    </div>
                    <div class="spanItem" style="color:var(--themeColor);" v-show="showFourCodeNumMax < fourCodeList.length" @click="showFourCodeNumMax = fourCodeList.length;">查看更多</div>
                </div>
            </div> -->
            <div class="searchBox" style="margin-top: 20px;">
                <el-input placeholder="查找企业" prefix-icon="el-icon-search" v-model="searchValue" @keyup.enter.native="onSearch" style="width:600px;"></el-input>
                <el-button type="primary" style="margin-left:20px;" @click="onSearch" :loading="searchLoading">搜索</el-button>
            </div>
        </div>
    </div>
    
    <div class="viewbox" style="margin-top:20px;">
        <div class="searchResult noResult" v-show="totalItems==0" v-loading="searchLoading">
            <img class="hintImg" src="@/assets/cluePicture/hint.png" alt="" />
            <img class="noImg" src="@/assets/cluePicture/no3.png" alt="" />
            <div class="hintTxt">商业数据</div>
        </div>
        
        <div class="searchResult" v-show="totalItems!=0" v-loading="searchLoading">
            <div class="title">为您找到<span style="color:#FF2D2D;">{{totalItems}}</span>条相关结果</div>
            <div class="resultList">
                <div class="resultItem" v-for="item in resultList" :key="item.key">
                    <div style="margin-bottom: 8px;">
                        <div class="name" @click="toDetails(item)">{{item.company}}</div>
                        <div class="country">
                            <img :src="item.flagUrl" style="margin-right:5px;height:14px" />
                            <span>{{item.country}}</span>
                        </div>
                    </div>
                    <!-- <div class="tagModule">
                        <div class="tag1">Active</div>
                    </div> -->
                    <!-- <div style="display:flex;margin-top: 8px;">
                        <div class="oneField">
                            <div class="color99">联系电话</div>
                            <div class="color3a">{{item.phone}}</div>
                        </div>
                        <div class="oneField">
                            <div class="color99">注册时间</div>
                            <div class="color3a">{{item.incorporationDateStr}}</div>
                        </div>
                    </div> -->
                  
                    <div class="twoField">
                        <span class="color99">注册时间：</span>
                        <span class="color3a">{{item.incorporationDateStr}}</span>
                    </div>
                    <div class="twoField">
                        <span class="color99">企业官网：</span>
                        <span class="primaryColor" @click="openWebsite(item.domain)">{{item.domain}}</span>
                    </div>
                    <div class="twoField">
                        <span class="color99">公司地址：</span>
                        <span class="color3a">{{item.address1}}</span>
                    </div>
                    <div class="twoField">
                        <span class="color99">行业：</span>
                        <span class="color3a" v-for="ele in item.sicModels" :key="ele.primaryCode" style="margin-right:20px;">
                            ({{ele.primaryCode}}){{ele.sicDescription}}
                        </span>
                    </div>
                    <div class="hasAdd" v-if="item.isMyCustomer">已加入我的客户</div>
                    <div class="addBtn" @click="addCustomer(item)" v-else>
                        <img src="@/assets/cluePicture/addCustomer.png" alt="" />加入我的客户
                    </div>
                </div>
                <el-pagination background layout="total,sizes,prev,pager,next" :page-sizes="[10, 20, 40]" :page-size.sync="pageSize" @size-change="pageSizeChange" :total="totalItems" style="float:right;margin-top:15px;" :current-page.sync="pageIndex" @current-change="pageIndexChange"></el-pagination>
            </div>
        </div>

        <!-- 验证解锁 -->
        <el-dialog title="验证解锁" :visible.sync="dialogVisible" width="500px" :show-close="false"
            :close-on-click-modal="false" :close-on-press-escape="false">
            <p style="line-height:28px;font-weight:bold;">请求过于频繁，请输入验证码：</p>
            <div style="display:flex;">
                <el-input v-model="imgCode" size="small"></el-input>
                <img class="pointer" :src="imgSrc" alt="" @click="openVerify" />
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" :disabled="!imgCode" @click="finishVerify">确 认</el-button>
            </span>
        </el-dialog>

        <!-- 选择行业代码 -->
        <el-dialog title="行业代码" :visible.sync="chooseVisible" width="1200px" top="5vh">
            <div slot="title" class="dialogTitle">
                <div>行业代码</div>
                <el-input style="width:500px;margin-right:40px;" placeholder="行业代码/中文描述" v-model="keyWord" prefix-icon="el-icon-search" clearable>
                    <el-button slot="append" @click="codeSearch">搜索</el-button>
                </el-input>
            </div>
            <div class="chooseCode" v-if="isSearchCode">
                <div class="chooseFour">
                    <div class="bItem" v-for="(ele,index) in matchingList" :key="index">
                        <span class="pointer" @click="confirmCode(ele)">{{ele.name}}</span>
                    </div>
                </div>
            </div>
            <div class="chooseCode" v-else>
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item v-for="(element,index) in breadcrumbList" :key="index">
                        <span class="pointer" style="color:var(--themeColor);" @click="goBack(index)">{{element}}</span>
                    </el-breadcrumb-item>
                </el-breadcrumb>
                <div class="chooseTwo" v-show="currentIndex == '2code'">
                    <div class="chooseItem" v-for="(item,index) in twoSicCodeList" :key="index">
                        <div class="name">{{item.name}}</div>
                        <div class="children">
                            <div class="aItem" v-for="ele in item.children" :key="ele.name">
                                <i class="el-icon-plus pointer" @click="getFourSic(ele)"></i>
                                <span>{{ele.name}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="chooseFour" v-show="currentIndex == '4code'">
                    <div class="bItem" v-for="(ele,index) in fourSicCodeList" :key="index">
                        <!-- <i v-show="ele.children" class="el-icon-plus pointer" @click="getEightSic(ele)"></i> -->
                        <span class="pointer" @click="confirmCode(ele)">{{ele.name}}</span>
                    </div>
                </div>
                <div class="chooseEight" v-show="currentIndex == '8code'">
                    <div class="bItem" v-for="(ele,index) in eightSicCodeList" :key="index">
                        <span class="pointer" @click="confirmCode(ele)">{{ele.name}}</span>
                    </div>
                </div>
            </div>
        </el-dialog>

    </div>
</div>
</template>

<script>
import SicJson from '../../assets/json/SIC.json'
import ClueNav from "@/components/ClueNav.vue";
export default {
    components: { ClueNav },
    props: {
        handleSelect: {
            type: Function,
            default: null,
        },
    },
    data() {
        return {
            currentPath: "/clue/commercialClue",
            //数据源
            continentList: [],
            continentIndex: "",
            dataSourceList: [],
            dataSourceIndex: "",
            showCountryNumMax: 10,
            //编码
            twoCodeList: [],
            twoCodeIndex: "",
            fourCodeList: [],
            fourCodeIndex: "",
            searchValue: "",
            isShowFourCode: false,
            showTwoCodeNumMax: 10,
            showFourCodeNumMax: 10,
            //选择行业代码
            chooseVisible: false,
            breadcrumbList: ["UsSIC"],
            currentIndex: "2code",
            twoSicCodeList: [],
            fourSicCodeList: [],
            eightSicCodeList: [],
            chooseSicCode: [],
            //行业代码中文匹配搜索
            allFourCodeList: [],
            isSearchCode: false,
            keyWord: "",
            matchingList: [],
            //实体类型和公司状态
            entityType: "",
            status: "",
            optionOne: [],
            optionTwo: [],

            //搜索结果
            searchLoading: false,
            resultList: [],
            totalItems: 0,
            pageIndex: 1,
            pageSize: 10,
            //验证解锁
            dialogVisible: false,
            imgCode: "",
            imgKey: "",
            imgSrc: "",
        };
    },
    methods: {
        chooseNav(index){
            this.handleSelect(index);
            this.$router.push(index);
        },
        
        //获取**实体类型和公司状态**下拉框数据
        getOption(){
            this.$axios.get(`${this.$clueUrl}/BusinessData/GetEntityTypes`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.optionOne = data;
                }
            });
            this.$axios.get(`${this.$clueUrl}/BusinessData/GetStatus`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.optionTwo = data;
                }
            });
        },
        changeOne(value){
            this.entityType = value;
            this.onSearch();
        },
        changeTwo(value){
            this.status = value;
            this.onSearch();
        },

        getContinents(){
            this.$axios.get(`${this.$clueUrl}/BusinessData/GetContinents`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.continentList = data;
                    // this.getCountrys(data[0].name);
                }
            });
        },
        getCountrys(name){
            this.continentIndex = name;
            this.dataSourceIndex = "";
            this.dataSourceList = [];
            this.showCountryNumMax = 10;
            this.onSearch();
            this.$axios.get(`${this.$clueUrl}/BusinessData/GetCountrys?name=${name}`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.dataSourceList = data;
                }
            });
        },
        getTwoCode(){
            this.showTwoCodeNumMax = 10;
            this.$axios.get(`${this.$clueUrl}/BusinessData/GetTwoSicCode`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.twoCodeList = data;
                }
            });
        },
        getFourCode(code){
            this.twoCodeIndex = code;
            this.fourCodeIndex = "";
            this.fourCodeList = [];
            this.showFourCodeNumMax = 10;
            this.onSearch();
            if(code){
                this.isShowFourCode = true;
                this.$axios.get(`${this.$clueUrl}/BusinessData/GetFourSicCode?towSicCode=${code}`).then((res) => {
                    const { isSuccess, data } = res;
                    if (isSuccess) {
                        this.fourCodeList = data;
                    }
                });
            }else{
                this.isShowFourCode = false;
            }
        },
        clickCountry(country){
            this.dataSourceIndex = country;
            this.onSearch();
        },
        clickFour(code){
            this.fourCodeIndex = code;
            this.onSearch();
        },
        //选择行业代码
        openChoose(){
            if(this.chooseSicCode.length >= 5){
                this.$message({ message: '最多选5个行业!', type: 'warning'});
                return false;
            }
            this.chooseVisible = true;
        },
        getFourSic(ele){
            this.fourSicCodeList = ele.children;
            this.currentIndex = "4code";
            this.breadcrumbList.push(ele.name)
        },
        getEightSic(ele){
            this.eightSicCodeList = ele.children;
            this.currentIndex = "8code";
            this.breadcrumbList.push(ele.name)
        },
        goBack(index){
            if(index==0){
                this.currentIndex = "2code";
                this.breadcrumbList = ["UsSIC"];
            }else if(index==1){
                this.currentIndex = "4code";
                if(this.breadcrumbList.length > 2){
                    this.breadcrumbList.pop();
                }
            }else if(index==2){
                this.currentIndex = "8code";
            }
        },
        confirmCode(ele){
            this.chooseSicCode.push(ele.name);
            this.chooseVisible = false;
            this.onSearch();
        },
        //行业代码中文匹配搜索
        codeSearch(){
            if(this.keyWord){
                this.isSearchCode = true;
                this.matchingList = this.allFourCodeList.filter(item => item.name.indexOf(this.keyWord) >= 0);
            }else{
                this.isSearchCode = false;
                this.matchingList = [];
            }
        },

        getResult(){
            var hsCode = "";
            if(this.fourCodeIndex != ""){
                hsCode = this.fourCodeIndex;
            }else{
                hsCode = this.twoCodeIndex;
            }
            //请求接口获取数据
            this.searchLoading = true;
            this.$axios.post(`${this.$clueUrl}/BusinessData/SearchBusinessData`,{
                "continent": this.continentIndex,
                "country": this.dataSourceIndex,
                "sicCode": hsCode,
                "company": this.searchValue,
                "pageIndex": this.pageIndex,
                "pageSize": this.pageSize,
                "sicCodes": this.chooseSicCode,
                "entityType": this.entityType,
                "status": this.status,
            }).then((res) => {
                const { isSuccess, data, errorMessage } = res;
                this.searchLoading = false;
                if (isSuccess) {
                    this.resultList = data.datas.map(item=>({
                        ...item,
                        flagUrl: window.getFlagUrlByCountry?window.getFlagUrlByCountry(item.country):'',
                    }))
                    this.totalItems = data.totalItems;
                    if(this.totalItems == 0){
                        this.$message({ message: '搜索无数据,请修改搜索条件重试!',type: 'warning' });
                    }
                }else{
                    if(errorMessage == "请求过于频繁，请输入验证码"){
                        this.openVerify();
                    }
                }
            });
        },
        onSearch(){
            this.pageIndex = 1;
            this.getResult();
        },
        pageIndexChange(page) {
            this.pageIndex = page;
            if(this.pageIndex > 30){
                this.pageIndex = 30;
                this.$notify({ title: '警告',message: '目前线索模块处于免费试用阶段,搜索页码不能超过30!',type: 'warning' });
                return false;
            }
            this.getResult();
        },
        pageSizeChange() {
            this.pageIndex = 1;
            this.getResult();
        },

        //前往线索详情
        toDetails(row){
            // 验证查看详情次数
            this.$axios.get(`${this.$clueUrl}/SecurityStrategy/CheckDetailsCount`).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    let routeData = this.$router.resolve({
                        path: "/clue/clueDetails",
                        query: { key: row.key, companyName: row.company, clueType: 'commercial', },
                    });
                    window.open(routeData.href, "_blank");
                }
            });
        },

        //加入我的客户
        addCustomer(item){
            let submitObj = {
                companyKey: JSON.parse(localStorage.userInfo).companyKey,
                userKey: JSON.parse(localStorage.userInfo).uKey,
                name: item.company,
                country: item.country,
                webSite: item.domain,
                call: item.phone,
                address: item.address1,
            }
            this.$axios.post(`${this.$customerUrl}/Common/AddCustomer`, submitObj).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    this.$message({ message: '添加成功', type: 'success'});
                    item.isMyCustomer = true;
                }
            });
        },

        //打开网址
        openWebsite(url){
            let jumpUrl = url.indexOf("http") > -1 ? url : "http://" + url;
            let routeData = this.$router.resolve({
                path: "/jumpWeb",
                query: { jumpUrl: jumpUrl },
            });
            window.open(routeData.href, "_blank");
        },

        //请求频繁，需要输入验证解锁
        openVerify(){
            this.dialogVisible = true;
            this.$axios.get(`${this.$clueUrl}/SecurityStrategy/GetVerifycode`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.imgKey = data.key;
                    this.imgSrc = data.image;
                }
            });
        },
        finishVerify(){
            this.$axios.get(`${this.$clueUrl}/SecurityStrategy/VerifyVerificationCode?key=${this.imgKey}&code=${this.imgCode}`).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {                  
                    this.dialogVisible = false;
                    this.imgCode = "";
                    this.onSearch();
                }
            });           
        },

    },
    mounted() {
        this.getContinents();
        
        this.getTwoCode();
        // console.log(SicJson);
        this.twoSicCodeList = SicJson[0].children;
        // 行业代码中文匹配搜索
        let allFourCodeList = [];
        this.twoSicCodeList.forEach(item =>{
            item.children.forEach(element =>{
                element.children.forEach(ele =>{
                    allFourCodeList.push(ele);
                })
            })
        })
        this.allFourCodeList = allFourCodeList; //获取所有的四位码

        this.getOption();
    },
};
</script>

<style lang="scss" scoped>
@import '@/scss/common.scss';
.viewbox{
    // background: rgba($color: #FFFFFF, $alpha: 0.7);
    background: #FFFFFF;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    padding: 20px;
    .header{
        display: flex;
        padding-bottom: 20px;
        border-bottom: 1px solid #E1E1E1;
        .logo{
            padding: 0 15px;
        }
        .theme{
            font-size: 20px;
            line-height: 32px;
            font-weight: bold;
            color: #092338;
        }
        .txt{
            font-size: 14px;
            line-height: 20px;
            color: #092338;
        }
    }

    .searchTerms{
        // margin-top: 20px;
        .itemTerm{
            line-height: 26px;
            margin-bottom: 10px;
            overflow: hidden;
            .name{
                float: left;
                width: 65px;
                font-size: 14px;
                color: #092338;
                text-align: right;
                margin-right: 15px;
            }
            .spanList{
                float: left;
                width: calc(100% - 80px);
            }
            .spanItem{
                display: inline-block;
                font-size: 14px;
                color: #092338;
                padding: 0 10px;
                margin-right: 15px;
                cursor: pointer;
            }
            .active{
                color: #FFFFFF;
                background: $themeColor;
                border-radius: 2px;
            }
        }
    }

    .searchResult{
        // margin-top: 20px;
        .title{
            height: 40px;
            line-height: 40px;
            background: #F1F1F1;
            color: #3A3A3A;
            padding-left: 20px;
        }
        .resultList{
            padding: 20px;
            overflow: hidden;
            .resultItem{
                font-size: 14px;
                padding: 10px 0;
                border-bottom: 1px solid #E8E8E8;
                position: relative;
                .name{
                    display: inline-block;
                    color: var(--themeColor);
                    text-decoration: underline;
                    cursor: pointer;
                }
                .country{
                    display: inline-block;
                    margin-left: 20px;
                    img{
                        vertical-align: middle;
                    }
                }
                .tagModule{
                    display: flex;
                    .tag1{
                        height: 20px;
                        line-height: 20px;
                        padding: 0 5px;
                        background: rgba(255, 170, 72, 0.2);
                        border-radius: 1px;
                        font-size: 12px;
                        color: #FFAA48;
                        margin-right: 10px;
                    }
                    .tag2{
                        height: 20px;
                        line-height: 20px;
                        padding: 0 5px;
                        background: rgba(255, 94, 126, 0.2);
                        border-radius: 1px;
                        font-size: 12px;
                        color: #FF5E7E;
                        margin-right: 10px;
                    }
                }
                .color99{    
                    color: #999999;
                }
                .color3a{    
                    color: #3A3A3A;
                }
                .primaryColor{
                    color: var(--themeColor);
                    cursor: pointer;
                }
                .oneField{
                    width: 200px;
                    text-align: left;
                }
                .twoField{
                    line-height: 28px;
                }
                .addBtn{
                    position: absolute;
                    top: 10px;
                    right: 0;
                    padding: 0 10px 0 32px;
                    height: 28px;
                    line-height: 28px;
                    font-size: 14px;
                    color: #FFFFFF;
                    background: var(--themeColor);
                    border-radius: 4px;
                    cursor: pointer;
                    &:hover{
                       opacity: 0.7;
                    }
                    img{
                        position: absolute;
                        left: 10px;
                        top: 5px;
                    }
                }
                .hasAdd{
                    position: absolute;
                    top: 10px;
                    right: 0;
                    padding: 0 10px;
                    height: 28px;
                    line-height: 28px;
                    font-size: 14px;
                    color: #B7B7B7;
                    background: rgba(183, 183, 183, 0.2);
                    border: 1px solid #B7B7B7;
                    border-radius: 4px;
                }
            }
        }
    }
    .noResult{
        overflow: hidden;
        .hintImg{
            float: left;
            width: 800px;
        }
        .noImg{
            float: left;
            margin-left: 80px;
            margin-top: 200px;
            width: 100px;
            height: 105px;
        }
        .hintTxt{
            float: left;
            margin-left: 20px;
            margin-top: 200px;
            font-size: 80px;
            line-height: 100px;
            font-weight: bold;
            color: #DFDFDF;
        }
    }

    .chooseCode{
        // height: 750px;
        height: 80vh;
        overflow: auto;
        .el-breadcrumb{
            background-color: #f3f3f3;
            line-height: 24px;
            padding-left: 10px;
        }
        .chooseTwo{
            padding-left: 10px;
            .chooseItem{
                margin-bottom: 10px;
                .name{
                    font-weight: bold;
                    color: #6a6a6a;
                    font-size: 16px;
                }
                .children{
                    line-height: 24px;
                    .aItem{
                        display: inline-block;
                        margin-right: 20px;
                        span{
                            padding-left: 5px;
                        }
                    }
                }
            }
        }
        .chooseFour,.chooseEight{
            padding-left: 10px;
            .bItem{
                line-height: 32px;
                span{
                    padding-left: 5px;
                    &:hover{
                        color: var(--themeColor);
                    }
                }
            }
        }
        .el-icon-plus{
            border: 1px solid var(--themeColor);
            color:var(--themeColor);
        }
    }
    /deep/.el-dialog__body {
        padding: 10px 20px;
    }

    .dialogTitle{
        height: 24px;
        line-height: 24px;
        font-size: 18px;
        color: #303133;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
    }
}
</style>