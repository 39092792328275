<template>
    <div>
        <el-cascader class="powerSelect" :show-all-levels="false" v-model="belongValue" :options="belongOptions" :props="{ value: 'key', label: 'name', checkStrictly: true }" size="small" @change="belongChange" />

        <el-select v-if="organizationKey" class="powerSelect" placeholder="选择员工" v-model="salekey" size="small" clearable @change="onChoose">
            <el-option v-for="item in saleOption" :key="item.key" :label="item.name" :value="item.key"></el-option>
        </el-select>
    </div>
</template>

<script>
export default {
    props:["defaultText"],
    data() {
        return {
            belongValue: [""],
            belongOptions: [],
            saleOption: [],
            organizationKey: "",
            salekey: "",
        };
    },
    methods: {
        //获取组织结构树下拉框
        getOrganizationDrop(){
            this.$axios.get(`${this.$personnelUrl}/Organization/FindOrganizationDrop`).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    data.unshift({
                        key: "",
                        name: this.defaultText,
                        children: [],
                    })
                    for (const item of data) {
                        this.disposeTree(item)
                    }
                    this.belongOptions = data;
                }
            });
        },
        //删除叶子节点的 children 属性
        disposeTree(node) {
            if (node.children.length) {
                for (const item of node.children) {
                    this.disposeTree(item)
                }
            } else {
                delete node.children
            }
        },
        belongChange(value){
            this.belongValue = value;
            this.organizationKey = this.belongValue[this.belongValue.length - 1];
            this.salekey = "";
            if(this.organizationKey){
                this.getSaleByOrganization();
            }else{
                this.saleOption = [];
            }
            this.onChoose();
        },
        //根据组织结构查询员工列表
        getSaleByOrganization(){
            this.$axios.get(`${this.$personnelUrl}/Organization/FindSaleByOrganization?organizationKey=${this.organizationKey}`).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    this.saleOption = data;
                }
            });
        },
        onChoose(){
            this.$emit("onChoose",this.organizationKey,this.salekey);
        }
    },
    mounted() {
        this.getOrganizationDrop(); //获取组织结构树下拉框
    },
};
</script>

<style lang='scss' scoped>
.powerSelect {
    float: left;
    margin-left: 20px;
    width: 150px;
    ::v-deep .el-input__inner {
        border: none;
        background: none;
    }
}
</style>