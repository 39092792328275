<template>
<div>
    <div v-if="ie=='i'">
        <!-- 纳米比亚(NAM)进口提单明细 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th width="25%">注册日期</th>
                        <td width="25%">{{billInfo.RegistrationDate}}</td>
                        <th width="25%">当局海关</th>
                        <td width="25%">{{billInfo.Customs}}</td>
                    </tr>
                    <tr>
                        <th>进口模式</th>
                        <td>{{billInfo.Model}}</td>
                        <th>原产国</th>
                        <td>{{billInfo.Country}}</td>
                    </tr>
                    <tr>
                        <th>净重</th>
                        <td>{{billInfo.NetWeight}} {{billInfo.NetWeightUnit}}</td>
                        <th>毛重</th>
                        <td>{{billInfo.Weight}} {{billInfo.WeightUnit}}</td>
                    </tr>
                    <tr>
                        <th>统计金额</th>
                        <td colspan="3">{{billInfo.Value}} {{billInfo.ValueUnit}}</td>
                    </tr>
                    <tr>
                        <th>商品金额（纳米比亚币种）</th>
                        <td>{{billInfo.ItemValue}} {{billInfo.ItemValueUnit}}</td>
                        <th>项目编号</th>
                        <td>{{billInfo.Item}}</td>
                    </tr>
                    <tr>
                        <th>出口国</th>
                        <td>{{billInfo.ExportCountry}}</td>
                        <th>目的国</th>
                        <td>{{billInfo.DestinationCountry}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
    <div v-if="ie=='e'">
        <!-- 纳米比亚(NAM)出口提单明细 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th width="25%">注册日期</th>
                        <td width="25%">{{billInfo.RegistrationDate}}</td>
                        <th width="25%">当局海关</th>
                        <td width="25%">{{billInfo.Customs}}</td>
                    </tr>
                    <tr>
                        <th>目的国</th>
                        <td>{{billInfo.Country}}</td>
                        <th>项目编号</th>
                        <td>{{billInfo.ItemNumber}}</td>
                    </tr>
                    <tr>
                        <th>FOB金额(FOB金额币种)</th>
                        <td>{{billInfo.Value}} {{billInfo.ValueUnit}}</td>
                        <th>CIF金额(CIF金额币种)</th>
                        <td>{{billInfo.CifValue}} {{billInfo.CifValueUnit}}</td>
                    </tr>
                    <tr>
                        <th>出口国</th>
                        <td>{{billInfo.ExportCountry}}</td>
                        <th>原产国</th>
                        <td>{{billInfo.OriginCountry}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
</div>
</template>

<script>
export default {
    props:["billInfo","ie"],
    data() {
        return {

        };
    },
    methods: {
        
    },
    mounted() {

    },
};
</script>

<style lang="scss" scoped>
.partItem {
    margin-bottom: 20px;
    /deep/.el-card__header {
        padding: 10px 20px;
        .headTitle {
            display: inline-block;
            padding-left: 10px;
            font-size: 14px;
            border-left: 2px solid var(--themeColor);
            font-weight: bold;
        }
    }
}
table {
    width: 100%;
    border-collapse: collapse;
    tr {
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;
        th,
        td {
            font-size: 12px;
            color: #666;
            height: 40px;
            border: 1px solid #eaeaea;
            text-align: center;
        }
        th {
            background-color: #f5f7fa;
            font-weight: 700;
        }
        .bg {
            background-color: var(--themeColor);
            color: #fff;
            font-weight: 700;
            opacity: 0.8;
        }
    }
}
</style>