<template>
<div>
    <div v-if="ie=='i'">
        <!-- 乌拉圭(URY)进口提单明细 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th width="25%">报关单号</th>
                        <td width="25%">{{billInfo.DuaNumber}}</td>
                        <th width="25%">进口商ID</th>
                        <td width="25%">{{billInfo.ImporterId}}</td>
                    </tr>
                    <tr>
                        <th>校验进口商ID</th>
                        <td>{{billInfo.VerifImporterId}}</td>
                        <th>地址</th>
                        <td>{{billInfo.Address}}</td>
                    </tr>
                    <tr>
                        <th>城市</th>
                        <td>{{billInfo.Location}}</td>
                        <th>部门</th>
                        <td>{{billInfo.Department}}</td>
                    </tr>
                    <tr>
                        <th>商品状态</th>
                        <td>{{billInfo.ConditionOfTheWoods}}</td>
                        <th>统计数量</th>
                        <td>{{billInfo.StatQuantity}}</td>
                    </tr>
                    <tr>
                        <th>统计数量单位</th>
                        <td>{{billInfo.StatUnit}}</td>
                        <th>汇率</th>
                        <td>{{billInfo.ExchangeRate}}</td>
                    </tr>
                    <tr>
                        <th>净重</th>
                        <td>{{billInfo.NetWeight}}</td>
                        <th>毛重</th>
                        <td>{{billInfo.Weight}}</td>
                    </tr>
                    <tr>
                        <th>CIF总价（美元）</th>
                        <td>{{billInfo.Value}}</td>
                        <th>CIF单价（美元）</th>
                        <td>{{billInfo.UnitCif}}</td>
                    </tr>
                    <tr>
                        <th>FOB总价（美元）</th>
                        <td>{{billInfo.FobValue}}</td>
                        <th>FOB单价（美元）</th>
                        <td>{{billInfo.FobUnit}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">运输信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">来源国</th>
                        <td width="25%">{{billInfo.Country}}</td>
                        <th width="25%">中转国</th>
                        <td width="25%">{{billInfo.CountryOfAcquisition}}</td>
                    </tr>
                    <tr>
                        <th>货源国</th>
                        <td>{{billInfo.ProvenanceCountry}}</td>
                        <th>运输方式</th>
                        <td>{{billInfo.TypeOfTransport}}</td>
                    </tr>
                    <tr>
                        <th>运输公司</th>
                        <td>{{billInfo.TransportCompany}}</td>
                        <th>船运公司</th>
                        <td>{{billInfo.Carrier}}</td>
                    </tr>
                    <tr>
                        <th>项目编号</th>
                        <td>{{billInfo.Item}}</td>
                        <th>保险</th>
                        <td>{{billInfo.Insurance}}</td>
                    </tr>
                    <tr>
                        <th>保险费货币种类</th>
                        <td>{{billInfo.CurrencyOfInsurance}}</td>
                        <th>运费</th>
                        <td>{{billInfo.Freight}}</td>
                    </tr>
                    <tr>
                        <th>运费货币种类</th>
                        <td>{{billInfo.CurrencyOfFreight}}</td>
                        <th>包装数量</th>
                        <td>{{billInfo.PackageQuantity}}</td>
                    </tr>
                    <tr>
                        <th>包装类型</th>
                        <td>{{billInfo.PackageType}}</td>
                        <th>承运人</th>
                        <td>{{billInfo.CarrierName}}</td>
                    </tr>
                    <tr>
                        <th>承运单</th>
                        <td>{{billInfo.DocumentCarrier}}</td>
                        <th>货单号</th>
                        <td>{{billInfo.ManifestNumber}}</td>
                    </tr>
                    <tr>
                        <th>关单号</th>
                        <td>{{billInfo.NumberBill}}</td>
                        <th>初始清单数量</th>
                        <td>{{billInfo.ManifestNumberOrigin}}</td>
                    </tr>
                    <tr>
                        <th>项目数</th>
                        <td>{{billInfo.NumberItem}}</td>
                        <th>起运港</th>
                        <td>{{billInfo.LoadingPort}}</td>
                    </tr>
                    <tr>
                        <th>抵达港</th>
                        <td>{{billInfo.DestinationPort}}</td>
                        <th>运费计量单位</th>
                        <td>{{billInfo.FreightCurrency}}</td>
                    </tr>
                    <tr>
                        <th>交货期</th>
                        <td>{{billInfo.Year}}</td>
                        <th>单位</th>
                        <td>{{billInfo.Unit}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">报关信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">海关</th>
                        <td width="25%">{{billInfo.Custom}}</td>
                        <th width="25%">报关行</th>
                        <td width="25%">{{billInfo.CustomsBroker}}</td>
                    </tr>
                    <tr>
                        <th>海关仓库</th>
                        <td>{{billInfo.Warehouse}}</td>
                        <th>目的地仓库</th>
                        <td>{{billInfo.DestinationWarehouse}}</td>
                    </tr>
                    <tr>
                        <th>进口ID</th>
                        <td>{{billInfo.ImportId}}</td>
                        <th>进口类型</th>
                        <td>{{billInfo.ImportType}}</td>
                    </tr>
                    <tr>
                        <th>有效</th>
                        <td>{{billInfo.Vad}}</td>
                        <th>免责</th>
                        <td>{{billInfo.Exoneration}}</td>
                    </tr>
                    <tr>
                        <th>国际条约</th>
                        <td>{{billInfo.InternationalAgreement}}</td>
                        <th>国贸条规</th>
                        <td>{{billInfo.Incoterms}}</td>
                    </tr>
                    <tr>
                        <th>文件类型</th>
                        <td colspan="3">{{billInfo.DocumentType}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
        
        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">计税信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">最低额外费用占百分比</th>
                        <td width="25%">{{billInfo.MinimumSurcharge}}</td>
                        <th width="25%">最低额外费用（美元）</th>
                        <td width="25%">{{billInfo.MinimumSurchargeUs}}</td>
                    </tr>
                    <tr>
                        <th>附加费用占百分比</th>
                        <td>{{billInfo.AdditionalSurcharge}}</td>
                        <th>附加费（美元）</th>
                        <td>{{billInfo.AdditionalSurchargeUs}}</td>
                    </tr>
                    <tr>
                        <th>动产附加费（美元）</th>
                        <td>{{billInfo.MovableSurchargeUs}}</td>
                        <th>增值税占百分比</th>
                        <td>{{billInfo.Vat}}</td>
                    </tr>
                    <tr>
                        <th>增值税费用（美元）</th>
                        <td>{{billInfo.VatUs}}</td>
                        <th>预付增值税占百分比</th>
                        <td>{{billInfo.AdvancedVat}}</td>
                    </tr>
                    <tr>
                        <th>预付增值税费用（美元）</th>
                        <td colspan="3">{{billInfo.AdvancedVatUs}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
    <div v-if="ie=='e'">
        <!-- 乌拉圭(URY)出口提单明细 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th width="25%">报关单号</th>
                        <td width="25%">{{billInfo.DuaNumber}}</td>
                        <th width="25%">出口商ID</th>
                        <td width="25%">{{billInfo.ExporterId}}</td>
                    </tr>
                    <tr>
                        <th>校验出口商ID</th>
                        <td>{{billInfo.VerifExporterId}}</td>
                        <th>地址</th>
                        <td>{{billInfo.Address}}</td>
                    </tr>
                    <tr>
                        <th>城市</th>
                        <td>{{billInfo.Location}}</td>
                        <th>部门</th>
                        <td>{{billInfo.Department}}</td>
                    </tr>
                    <tr>
                        <th>商品状态</th>
                        <td>{{billInfo.ConditionOfTheWoods}}</td>
                        <th>统计数量</th>
                        <td>{{billInfo.StatQuantity}}</td>
                    </tr>
                    <tr>
                        <th>统计数量单位</th>
                        <td>{{billInfo.StatUnit}}</td>
                        <th>汇率</th>
                        <td>{{billInfo.ExchangeRate}}</td>
                    </tr>
                    <tr>
                        <th>净重</th>
                        <td>{{billInfo.NetWeight}}</td>
                        <th>毛重</th>
                        <td>{{billInfo.Weight}}</td>
                    </tr>
                    <tr>
                        <th>CIF总价（美元）</th>
                        <td>{{billInfo.Value}}</td>
                        <th>CIF单价（美元）</th>
                        <td>{{billInfo.UnitCif}}</td>
                    </tr>
                    <tr>
                        <th>FOB总价（美元）</th>
                        <td>{{billInfo.FobValue}}</td>
                        <th>FOB单价（美元）</th>
                        <td>{{billInfo.FobUnit}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">运输信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">来源国</th>
                        <td width="25%">{{billInfo.OriginCountry}}</td>
                        <th width="25%">目的国</th>
                        <td width="25%">{{billInfo.Country}}</td>
                    </tr>
                    <tr>
                        <th>运输方式</th>
                        <td>{{billInfo.TypeOfTransport}}</td>
                        <th>运输公司</th>
                        <td>{{billInfo.TransportCompany}}</td>
                    </tr>
                    <tr>
                        <th>船运公司</th>
                        <td>{{billInfo.CustomsBroker}}</td>
                        <th>项目编号</th>
                        <td>{{billInfo.Item}}</td>
                    </tr>
                    <tr>
                        <th>包装数量</th>
                        <td>{{billInfo.PackageQuantity}}</td>
                        <th>包装类型</th>
                        <td>{{billInfo.PackageType}}</td>
                    </tr>
                    <tr>
                        <th>承运人</th>
                        <td>{{billInfo.CarrierName}}</td>
                        <th>承运单</th>
                        <td>{{billInfo.DocumentCarrier}}</td>
                    </tr>
                    <tr>
                        <th>货单号</th>
                        <td>{{billInfo.ManifestNumber}}</td>
                        <th>初始清单数量</th>
                        <td>{{billInfo.ManifestNumberOrigin}}</td>
                    </tr>
                    <tr>
                        <th>项目数</th>
                        <td>{{billInfo.NumberItem}}</td>
                        <th>发货日期</th>
                        <td>{{billInfo.ShippingDate}}</td>
                    </tr>
                    <tr>
                        <th>卸货日期</th>
                        <td>{{billInfo.DischargeDate}}</td>
                        <th>起运港</th>
                        <td>{{billInfo.LoadingPort}}</td>
                    </tr>
                    <tr>
                        <th>抵达港</th>
                        <td colspan="3">{{billInfo.DestinationPort}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">报关信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">关区</th>
                        <td width="25%">{{billInfo.Custom}}</td>
                        <th width="25%">报关行</th>
                        <td width="25%">{{billInfo.CustomsBroker}}</td>
                    </tr>
                    <tr>
                        <th>海关仓库</th>
                        <td>{{billInfo.Warehouse}}</td>
                        <th>出口ID</th>
                        <td>{{billInfo.ExportId}}</td>
                    </tr>
                    <tr>
                        <th>出口类型</th>
                        <td>{{billInfo.ExportType}}</td>
                        <th>国际条约</th>
                        <td>{{billInfo.InternationalAgreement}}</td>
                    </tr>
                    <tr>
                        <th>国贸条规</th>
                        <td>{{billInfo.Incoterms}}</td>
                        <th>文件类型</th>
                        <td>{{billInfo.DocumentType}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
</div>
</template>

<script>
export default {
    props:["billInfo","ie"],
    data() {
        return {

        };
    },
    methods: {
        
    },
    mounted() {

    },
};
</script>

<style lang="scss" scoped>
.partItem {
    margin-bottom: 20px;
    /deep/.el-card__header {
        padding: 10px 20px;
        .headTitle {
            display: inline-block;
            padding-left: 10px;
            font-size: 14px;
            border-left: 2px solid var(--themeColor);
            font-weight: bold;
        }
    }
}
table {
    width: 100%;
    border-collapse: collapse;
    tr {
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;
        th,
        td {
            font-size: 12px;
            color: #666;
            height: 40px;
            border: 1px solid #eaeaea;
            text-align: center;
        }
        th {
            background-color: #f5f7fa;
            font-weight: 700;
        }
        .bg {
            background-color: var(--themeColor);
            color: #fff;
            font-weight: 700;
            opacity: 0.8;
        }
    }
}
</style>