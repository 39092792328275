<template>
    <div style="display: flex;">
        <CustomerNav @chooseNav="chooseNav" :currentPath="currentPath" />
        <div class="viewBox">
            <div class="header">
                <div class="leftSearch">
                    <el-input v-model="customerName" style="width:300px;" clearable size="small" placeholder="公司名称"></el-input>
                    <el-button type="primary" size="small" @click="pageChange(1)">搜索</el-button>
                </div>
                <div class="rightOption">
                    <span style="padding-right: 10px;">回收站有效期:</span>
                    <span v-if="validityDay==-1">永不释放</span>
                    <span v-else><b style="color: var(--themeColor);">{{ validityDay }}</b> 天释放</span>
                    <el-button size="small" v-if="isAdmin" @click="toSetDay" style="margin-left: 20px;">设置</el-button>
                    <el-button size="small" v-if="isAdmin" @click="onEmpty" style="margin-left: 20px;">清空回收站</el-button>
                </div>
            </div>
            <div class="content">
                <el-table :data="recycleList" :header-cell-style="{'background-color':'#F3F3F3','color':'#3A3A3A'}">
                    <el-table-column prop="customerName" label="公司名称"></el-table-column>
                    <el-table-column label="回收时间" width="200">
                        <template slot-scope="scope">
                            <span>{{ scope.row.createTime }}</span>
                            <span>(已回收<b style="color: var(--themeColor);">{{ scope.row.day }}</b>天)</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="createUser" label="操作人" width="150"></el-table-column>
                    <el-table-column prop="reason" label="回收原因"></el-table-column>
                    <el-table-column label="操作" width="240">
                        <template slot-scope="scope">
                            <el-button type="primary" plain size="small" @click="onRestore(scope.row,1)">加入我的客户</el-button>
                            <el-button type="primary" plain size="small" @click="onRestore(scope.row,0)">移回公海</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination hide-on-single-page background layout="total,prev,pager,next" :total="totalItems" style="float:right;margin:15px 20px;" :current-page.sync="pageIndex" @current-change="pageChange"></el-pagination>
            </div>
        </div>

        <el-dialog title="设置回收站有效期" :visible.sync="setDayVisible" width="500px">
            <div>
                <el-input-number v-model="setDayNumber" :min="1" :max="365" size="small"></el-input-number>
                <span style="padding-left: 10px;">天释放</span>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" plain @click="setDayFinish(-1)">永不释放</el-button>
                <el-button size="small" type="primary" @click="setDayFinish(setDayNumber)">确 认</el-button>
                <el-button size="small" @click="setDayVisible = false">取 消</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import CustomerNav from "@/components/CustomerNav.vue";
export default {
    components: { CustomerNav },
    props: {
        handleSelect: {
            type: Function,
            default: null,
        },
    },
    data() {
        return {
            currentPath: "/customerManage/recycle",
            isAdmin: false,
            //客户回收站
            customerName: "",
            recycleList: [],
            totalItems: 0,
            pageIndex: 1,
            //设置回收站有效期
            validityDay: -1,
            setDayVisible: false,
            setDayNumber: 30,
        };
    },
    methods: {
        chooseNav(index){
            this.handleSelect(index);
            this.$router.push(index);
        },

        //客户回收站
        getRecycle(){
            this.$axios.get(`${this.$customerUrl}/Company/FindTrashDepot?customerName=${this.customerName}&pageIndex=${this.pageIndex}&pageSize=10`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.recycleList = data.datas;
                    this.totalItems = data.totalItems;
                }
            });
        },
        pageChange(page){
            this.pageIndex = page;
            this.getRecycle();
        },
        
        //设置回收站有效期
        getCompanyConfig(){
            this.$axios.get(`${this.$customerUrl}/v1/Config/GetCompanyConfig`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.validityDay = data.trashDepotExpirationDay;
                }
            });
        },
        toSetDay(){
            this.setDayVisible = true;
            this.setDayNumber = this.validityDay == -1 ? 30 : this.validityDay;
        },
        setDayFinish(day){
            this.$axios.get(`${this.$customerUrl}/v1/Config/SetTrashDepotDay?day=${day}`).then((res) => {
                const { isSuccess } = res;
                this.setDayVisible = false;
                if (isSuccess) {
                    this.$message({ message: '设置成功', type: 'success'});
                    this.validityDay = day;
                }
            });
        },
        
        //清空回收站
        onEmpty(){
            this.$confirm('此操作将清空回收站公司, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios.get(`${this.$customerUrl}/Company/ClearTrashDepot`).then((res) => {
                    const { isSuccess } = res;
                    if (isSuccess) {
                        this.$message({ message: '操作成功', type: 'success'});
                        this.pageIndex = 1;
                        this.getRecycle();
                    }
                });
            }).catch(() => {});
        },
        
        //加入我的客户/移回公海
        onRestore(row,type){
            this.$confirm(`此操作将公司${type == 1 ? '加入我的客户' : '移回公海'}, 是否继续?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios.get(`${this.$customerUrl}/Company/TrashDepotAddHighSeas?type=${type}&customerKey=${row.customerKey}`).then((res) => {
                    const { isSuccess } = res;
                    if (isSuccess) {
                        this.$message({ message: '操作成功', type: 'success'});
                        this.getRecycle();
                    }
                });
            }).catch(() => {});
        },

    },
    mounted() {
        this.isAdmin = JSON.parse(localStorage.userInfo).isAdmin;
        this.getRecycle();
        this.getCompanyConfig()
    },
};
</script>

<style lang="scss" scoped>
.viewBox {
    width: calc(100% - 200px);
    height: calc(100vh - 140px);
    overflow: auto;
    box-sizing: border-box;
    padding: 20px;
    background: #ffffff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}
.header{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .rightOption{
        font-size: 14px;
        color: #6a6a6a;
    }
}
</style>