<template>
    <div class="CompanyDetails">
        <div class="leftSide" v-show="sidebarVisible">
            <AdvancedSearch @inputComplete="inputComplete" @toSearch="toSearch" />
        </div>
        <div class="rightSide" ref="rightSide">
            <div class="header">
                <div style="display: flex;">
                    <div class="title">{{companyName}}</div>
                    <el-radio-group v-model="isImpExp" size="small" @change="toSearch" style="margin-left: 50px;">
                        <el-radio-button label="importer">进口商</el-radio-button>
                        <el-radio-button label="exporter">出口商</el-radio-button>
                    </el-radio-group>
                </div>
                <div>
                    <span class="qiyelink" @click="toCorporationCredit">企业资信</span>
                    <el-button type="info" plain v-if="isMyClue" size="small" disabled>已加入我的线索</el-button>
                    <el-button type="primary" plain v-else size="small" @click="addMyClue" :loading="btnLoading">加入我的线索</el-button>
                </div>
            </div>
            <div class="searchAfter">
                <div class="navTradeTab">
                    <div class="tabItem" :class="{'active':resultActive==0}" @click="tradeTabClick(0)">贸易记录</div>
                    <div class="tabItem" :class="{'active':resultActive==1}" @click="tradeTabClick(1)">采购商</div>
                    <div class="tabItem" :class="{'active':resultActive==2}" @click="tradeTabClick(2)">供应商</div>
                    <div class="tabItem" :class="{'active':resultActive==3}" @click="tradeTabClick(3)">可视化排名</div>
                </div>
                <div class="searchContent" v-show="resultActive==0">
                    <TradeRecord ref="TradeRecord" :searchField="searchField" />
                </div>
                <div class="searchContent" v-show="resultActive==1">
                    <BuyersRanking ref="BuyersRanking" :searchField="searchField" />
                </div>
                <div class="searchContent" v-show="resultActive==2">
                    <SuppliersRanking ref="SuppliersRanking" :searchField="searchField" />                    
                </div>
                <div class="searchContent" v-show="resultActive==3">
                    <VisualRanking ref="VisualRanking" :searchField="searchField" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AdvancedSearch from "./components/AdvancedSearch";
import TradeRecord from "../ComResult/TradeRecord";
import BuyersRanking from "../ComResult/BuyersRanking";
import SuppliersRanking from "../ComResult/SuppliersRanking";
import VisualRanking from "../ComResult/VisualRanking";
import dayjs from 'dayjs';
import {toFirm} from '@/utils'
export default {
    components: { AdvancedSearch, TradeRecord, BuyersRanking, SuppliersRanking, VisualRanking },
    data() {
        return {
            companyName: "",
            country: "",
            countryCode: "",
            isImpExp: "importer",
            //高级筛选
            sidebarVisible: true,
            //搜索条件
            searchField: {
                "pageIndex": 1,
                "pageSize": 10,
                "keyDoc": "",
                "keyDocType": 0,
                "optionalKeyDoc": "",
                "optionalType": 0,
                "dataClass": 2,
                "ie": "i",
                "startDate": "2020-01-01",
                "endDate": dayjs().format('YYYY-MM-DD'),
                "minValue": 0,
                "maxValue": 0,
                "minQuantity": 0,
                "maxQuantity": 0,
                "minWeight": 0,
                "maxWeight": 0,
                "minPrice": 0,
                "maxPrice": 0,
                "minWeightPrice": 0,
                "maxWeightPrice": 0,
                "isNotNullImporter": true,
                "isNotNullExporter": true,
                "isNotImporterForwarder": true,
                "isNotExporterForwarder": true,
                "billNo": "",
                "brand": "",
                "country": "",
                "countryCode": "",
                "exporter": "",
                "hsCode": "",
                "importer": "",
                "manufacturCompany": "",
                "port": "",
                "unLoadingPort": "",
                "product": "",
                "threeEnCountryCode": "",
                "destinationCountry": "",
                "dataSourceContinent": "",
                "container": "",
                "transportMode": "",
                "sortType": 0,
                "size": 0,
                "customerId": "",
                "reportType": 0,
                "tandType": 5,
                "multiDataCountryCode": "",
                "noThreeEnCountryCode": "",
                "noCountryCode": "",
                "noHsCode": "",
                "noImporter": "",
                "noExporter": "",
                "noProduct": "",
                "noCountry": "",
                "noMinValue": 0,
                "noMaxValue": 0,
                "noMinQuantity": 0,
                "noMaxQuantity": 0,
                "noMinWeight": 0,
                "noMaxWeight": 0
            },
            //搜索(结果0/采购商1/供应商2/可视化排名3)
            resultActive: 0,
            hasClickOne: false,
            hasClickTwo: false,
            hasClickThree: false,

            //是否加入我的线索
            isMyClue: false,
            btnLoading: false,
        };
    },
    methods: {
        inputComplete(value){
            this.searchField = Object.assign(this.searchField,value);
        },
        toSearch(){
            if(this.isImpExp == "importer"){
                this.searchField.keyDocType = 3;
            }
            if(this.isImpExp == "exporter"){
                this.searchField.keyDocType = 4;
            }
            this.searchField.keyDoc = this.companyName;
            if(this.searchField.keyDoc.trim() == ""){
                this.$message({ message: '搜索关键字不能为空!', type: 'warning'});
                return false;
            }
            this.resultActive = 0;
            this.hasClickOne = false;
            this.hasClickTwo = false;
            this.hasClickThree = false;
            //获取贸易数据结果
            this.$refs.TradeRecord.getResult(1);  
        },
        //搜索结果切换
        tradeTabClick(type){
            this.resultActive = type;
            if(type == 1 && !this.hasClickOne){
                this.hasClickOne = true;
                this.$refs.BuyersRanking.getBuyers(1);
            }else if(type == 2 && !this.hasClickTwo){
                this.hasClickTwo = true;
                this.$refs.SuppliersRanking.getSuppliers(1);
            }else if(type == 3 && !this.hasClickThree){
                this.hasClickThree = true;
                this.$refs.VisualRanking.getRanking(1);
            }
        },

        //是否加入我的线索
        isJoin(){
            this.$axios.post(`${this.$clueUrl}/MyClue/IsMyClues`,[this.companyName]).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.isMyClue = data.length == 0 ? false : true;
                }
            });
        },
        //加入我的线索
        addMyClue(){
            let submitObj = {
                "clueSource": 1, //海关数据
                "clueRelationKey": "",
                "companyName": this.companyName,
                "country": this.country,
                "legalPerson": "",
                "contactNumber": "",
                "registrationTime": "",
                "employeesNumber": 0,
                "officialWebsite": "",
                "corporateEmail": "",
                "businessAddress": "",
                "companyProfile": "",
                "industrys": [],
            }
            this.btnLoading = true;
            this.$axios.post(`${this.$clueUrl}/MyClue/AddMyClue`, submitObj).then((res) => {
                const { isSuccess } = res;
                this.btnLoading = false;
                if (isSuccess) {
                    this.$message({ message: '添加成功', type: 'success'});
                    this.isJoin();
                }
            });
        },
        toCorporationCredit(){
            toFirm({item:{
                "clueSource": 1,
                "clueRelationKey": "",
                "companyName": this.companyName,
                "country": this.country,
                "legalPerson": "",
                "contactNumber": "",
                "registrationTime": "",
                "employeesNumber": 0,
                "officialWebsite": "",
                "corporateEmail": "",
                "businessAddress": "",
                "companyProfile": "",
                "industrys": [],
                "isMyClue": this.isMyClue
           },company: this.companyName, countryCode: this.countryCode})
        },
    },
    mounted() {
        this.companyName = unescape(this.$route.query.companyName);
        this.isImpExp = this.$route.query.isImpExp;
        this.searchField.ie = this.$route.query.ie;
        this.country = this.$route.query.country;
        this.countryCode = this.$route.query.countryCode;
        this.toSearch();
        //是否加入我的线索
        this.isJoin();
    },
};
</script>

<style lang="scss" scoped>
@import '@/scss/common.scss';
.CompanyDetails{
    width: calc(100vw - 100px);
    height: calc(100vh - 80px);
    box-sizing: border-box;
    display: flex;
    background-color: #fff;
    .leftSide{
        width: 260px;
        height: calc(100vh - 80px);
        overflow: auto;
        box-shadow: 2px 0 0 0 rgba( 0, 0, 0, 0.1);
    }
    .rightSide{
        width: calc(100vw - 360px);
        height: calc(100vh - 80px);
        padding: 0 20px 20px 20px;
        box-sizing: border-box;
        .header{
            margin-top: 10px;
            height: 32px;
            line-height: 32px;
            display: flex;
            justify-content: space-between;
            .title{
                color: #3a3a3a;
                font-size: 20px;
                font-weight: bold;  
            }
            .qiyelink{
                cursor: pointer;
                font-size: 14px;
                color: var(--themeColor);
                padding-right: 20px;
            }
        }
        .searchAfter{
            margin-top: 10px;
            .navTradeTab{
                display: flex;
                .tabItem{
                    width: 150px;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    margin-right: 10px;
                    background-color: #f5f6f8;
                    border: 1px solid #eaeaea;
                    border-bottom: none;
                    color: #666;
                    font-size: 14px;
                    cursor: pointer;
                }
                .active{
                    background-color: #fff;
                    color: $themeColor;
                }
            }
            .searchContent{
                height: calc(100vh - 200px);
                overflow: auto;
                border: 1px solid #eaeaea;
            }
        }
    }
}
</style>