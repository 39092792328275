<template>
  <!-- 筛选条件 -->
  <div class="filter-criteria" v-loading="loading">
    <div class="panel">
      <p class="title">筛选条件</p>
      <div class="filter-item">
        <div class="item-title">进出口</div>
        <div class="item-content">
          <el-checkbox-group>
            <label class="el-checkbox" :class="{ selected: ie === 'i' }">
              <span class="el-checkbox__label" @click="setControlFormGlobal([['ie', 'i']])" style="font-size: 12px">进口</span>
            </label>
            <label class="el-checkbox" :class="{ selected: ie === 'e' }">
              <span class="el-checkbox__label" @click="setControlFormGlobal([['ie', 'e']])" style="font-size: 12px">出口</span>
            </label>
          </el-checkbox-group>
          <!-- <el-radio-group :value="importOrExport">
            <el-radio label="i">进口</el-radio>
            <el-radio label="e">出口</el-radio>
          </el-radio-group> -->
        </div>
      </div>
      <div class="filter-item">
        <div class="item-title">数据源</div>
        <div class="item-content">
          <div class="more-control" @click="dataSourceShowMore = !dataSourceShowMore" v-show="dataSourceOption.option.length > 10">
            <span>{{ dataSourceShowMore ? "收起" : "更多" }}</span>
            <i :class="dataSourceShowMore ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
          </div>
          <el-checkbox-group v-model="dataSourceValue">
            <label class="el-checkbox" :class="{ selected: dataSourceValue.length === 0 }">
              <span class="el-checkbox__label" @click="dataSourceValue = []" style="font-size: 12px">{{ `全部 (${dataSourceOption.totalNum})` }}</span>
            </label>
            <el-checkbox :label="item.value" v-for="item of dataSourceOption.option.slice(0, 10)" :key="`${item.label}(${item.num})`">{{ `${item.label}(${item.num})` }}</el-checkbox>
            <template v-if="dataSourceShowMore">
              <el-checkbox :label="item.value" v-for="item of dataSourceOption.option.slice(10)" :key="`${item.label}(${item.num})`">{{ `${item.label}(${item.num})` }}</el-checkbox>
            </template>
          </el-checkbox-group>
        </div>
      </div>
      <div class="filter-item">
        <div class="item-title" style="align-items: center">日期</div>
        <div class="item-content" style="display: flex; algin-items: center">
          <el-radio-group v-model="daterangeRadio" @change="handleDateRangeChangeByRadioGroup" style="display: flex; align-items: center">
            <el-radio label="一年" />
            <el-radio label="两年" />
            <el-radio label="三年" />
            <el-radio label="五年" />
          </el-radio-group>
          <div class="custome-date-picker" style="margin-left: 2em">
            <el-date-picker class="start" v-model="daterangeStart" @change="handleDateRangeStartChange" type="date" placeholder="开始日期" value-format="yyyy-MM-dd" size="mini" :clearable="false" style="width: 120px" />至
            <el-date-picker class="end" v-model="daterangeEnd" @change="handleDateRangeEndChange" type="date" placeholder="结束日期" value-format="yyyy-MM-dd" size="mini" :clearable="false" style="width: 120px" />
          </div>
          <!-- <el-date-picker unlink-panels v-model="daterange" @change="handleDateRangeChangeByDatePicker" value-format="yyyy-MM-dd" style="margin-left: 2em; width: 240px" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" size="mini" /> -->
        </div>
      </div>
      <div class="filter-item">
        <div class="item-title">HS编码</div>
        <div class="item-content">
          <!-- <el-checkbox-group> -->
          <div>
            <label class="el-checkbox" :class="{ selected: !hs2 && !hs4 && !hs6 }">
              <span
                class="el-checkbox__label"
                @click="
                  hs2 = false;
                  hs4 = false;
                  hs6 = false;
                "
                style="font-size: 12px"
                >全部</span
              >
            </label>
            <label v-show="hsCodeOption2.option.length !== 0" class="el-checkbox" :class="{ selected: hs2 }">
              <span class="el-checkbox__label" @click="hs2 = !hs2" style="font-size: 12px">{{ `二位 (${hsCodeOption2.totalNum})` }}</span>
            </label>
            <label v-show="hsCodeOption4.option.length !== 0" class="el-checkbox" :class="{ selected: hs4 }">
              <span class="el-checkbox__label" @click="hs4 = !hs4" style="font-size: 12px">{{ `四位 (${hsCodeOption4.totalNum})` }}</span>
            </label>
            <label v-show="hsCodeOption6.option.length !== 0" class="el-checkbox" :class="{ selected: hs6 }">
              <span class="el-checkbox__label" @click="hs6 = !hs6" style="font-size: 12px">{{ `六位 (${hsCodeOption6.totalNum})` }}</span>
            </label>
          </div>
          <!-- </el-checkbox-group> -->
          <div class="item-content" v-show="hs2">
            <div class="more-control" @click="hs2ShowMore = !hs2ShowMore">
              <span>{{ hs2ShowMore ? "收起" : "更多" }}</span>
              <i :class="hs2ShowMore ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
            </div>
            <el-checkbox-group v-model="hs2Value">
              <el-checkbox v-for="item of hsCodeOption2.option.slice(0, 10)" :key="item.label" :label="item.label">{{ `${item.label}(${item.num})` }}</el-checkbox>
              <template v-if="hs2ShowMore">
                <el-checkbox v-for="item of hsCodeOption2.option.slice(10)" :key="item.label" :label="item.label">{{ `${item.label}(${item.num})` }}</el-checkbox>
              </template>
            </el-checkbox-group>
          </div>
          <div class="item-content" v-show="hs4">
            <div class="more-control" @click="hs4ShowMore = !hs4ShowMore">
              <span>{{ hs4ShowMore ? "收起" : "更多" }}</span>
              <i :class="hs4ShowMore ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
            </div>
            <el-checkbox-group v-model="hs4Value">
              <el-checkbox v-for="item of hsCodeOption4.option.slice(0, 10)" :key="item.label" :label="item.label">{{ `${item.label}(${item.num})` }}</el-checkbox>
              <template v-if="hs4ShowMore">
                <el-checkbox v-for="item of hsCodeOption4.option.slice(10)" :key="item.label" :label="item.label">{{ `${item.label}(${item.num})` }}</el-checkbox>
              </template>
            </el-checkbox-group>
          </div>
          <div class="item-content" v-show="hs6">
            <div class="more-control" @click="hs6ShowMore = !hs6ShowMore">
              <span>{{ hs6ShowMore ? "收起" : "更多" }}</span>
              <i :class="hs6ShowMore ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
            </div>
            <el-checkbox-group v-model="hs6Value">
              <el-checkbox v-for="item of hsCodeOption6.option.slice(0, 10)" :key="item.label" :label="item.label">{{ `${item.label}(${item.num})` }}</el-checkbox>
              <template v-if="hs6ShowMore">
                <el-checkbox v-for="item of hsCodeOption6.option.slice(10)" :key="item.label" :label="item.label">{{ `${item.label}(${item.num})` }}</el-checkbox>
              </template>
            </el-checkbox-group>
          </div>
          <!-- <div class="more-control" @click="hs2ShowMore = !hs2ShowMore">
            <span>{{ hs2ShowMore ? "收起" : "更多" }}</span>
            <i :class="hs2ShowMore ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
          </div> -->
        </div>
      </div>
      <div class="filter-item">
        <div class="item-title">进口商</div>
        <div class="item-content">
          <div class="more-control" @click="importShowMore = !importShowMore" v-show="importOption.option.length > 10">
            <span>{{ importShowMore ? "收起" : "更多" }}</span>
            <i :class="importShowMore ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
          </div>
          <el-checkbox-group v-model="importValue">
            <label class="el-checkbox" :class="{ selected: importValue.length === 0 }">
              <span class="el-checkbox__label" @click="importValue = []" style="font-size: 12px">{{ `全部 (${importOption.totalNum})` }}</span>
            </label>
            <el-tooltip effect="light" :content="`${item.label}(${item.num})`" placement="top-start" v-for="item of importOption.option.slice(0, 10)" :key="`${item.label}(${item.num})`">
              <el-checkbox :label="item.label">{{ `${item.label}(${item.num})` }}</el-checkbox>
            </el-tooltip>
            <template v-if="importShowMore">
              <el-tooltip effect="light" :content="`${item.label}(${item.num})`" placement="top-start" v-for="item of importOption.option.slice(10)" :key="`${item.label}(${item.num})`">
                <el-checkbox :label="item.label">{{ `${item.label}(${item.num})` }}</el-checkbox>
              </el-tooltip>
            </template>
          </el-checkbox-group>
        </div>
      </div>
      <div class="filter-item">
        <div class="item-title">出口商</div>
        <div class="item-content">
          <div class="more-control" @click="exportShowMore = !exportShowMore" v-show="exportOption.option.length > 10">
            <span>{{ exportShowMore ? "收起" : "更多" }}</span>
            <i :class="exportShowMore ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
          </div>
          <el-checkbox-group v-model="exportValue">
            <label class="el-checkbox" :class="{ selected: exportValue.length === 0 }">
              <span class="el-checkbox__label" @click="exportValue = []" style="font-size: 12px">{{ `全部 (${exportOption.totalNum})` }}</span>
            </label>
            <el-tooltip effect="light" :content="`${item.label}(${item.num})`" placement="top-start" v-for="item of exportOption.option.slice(0, 10)" :key="`${item.label}(${item.num})`">
              <el-checkbox :label="item.label">{{ `${item.label}(${item.num})` }}</el-checkbox>
            </el-tooltip>
            <template v-if="exportShowMore">
              <el-tooltip effect="light" :content="`${item.label}(${item.num})`" placement="top-start" v-for="item of exportOption.option.slice(10)" :key="`${item.label}(${item.num})`">
                <el-checkbox :label="item.label">{{ `${item.label}(${item.num})` }}</el-checkbox>
              </el-tooltip>
            </template>
          </el-checkbox-group>
        </div>
      </div>
      <div class="filter-item">
        <div class="item-title" style="position: relative">
          <span>国家</span>
          <el-tooltip effect="dark" placement="right">
            <div slot="content">进口数据的原产国<br />出口数据的目的国</div>
            <i class="el-icon-warning" style="position: absolute; top: 3px; left: 26px"></i>
          </el-tooltip>
        </div>
        <div class="item-content">
          <div class="more-control" @click="countryShowMore = !countryShowMore" v-show="countryOption.option.length > 10">
            <span>{{ countryShowMore ? "收起" : "更多" }}</span>
            <i :class="countryShowMore ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
          </div>
          <el-checkbox-group v-model="countryValue">
            <label class="el-checkbox" :class="{ selected: countryValue.length === 0 }">
              <span class="el-checkbox__label" @click="countryValue = []" style="font-size: 12px">{{ `全部 (${countryOption.totalNum})` }}</span>
            </label>
            <el-checkbox :label="item.value" v-for="item of countryOption.option.slice(0, 10)" :key="`${item.label}(${item.num})`">{{ `${item.label}(${item.num})` }}</el-checkbox>
            <template v-if="countryShowMore">
              <el-checkbox :label="item.value" v-for="item of countryOption.option.slice(10)" :key="`${item.label}(${item.num})`">{{ `${item.label}(${item.num})` }}</el-checkbox>
            </template>
          </el-checkbox-group>
        </div>
      </div>
      <div style="display: flex; align-items: flex-start">
        <div @click="dialog.render = true" class="cursor-pointer" style="flex-shrink: 0; display: flex; align-items: center; color: var(--themeColor, #3152AF)">
          <img src="@/firm/images/company/icon-filter-orange.png" />
          <span>不包含过滤：</span>
        </div>
        <div v-show="exHsCode.length !== 0 || exExporter.length !== 0 || exImporter.length !== 0 || exCountry.length !== 0" style="border: 1px solid #d7d7d8; background: #fefeff; width: 100%; border-radius: 4px; padding: 10px 10px 0 10px">
          <div class="ex-item" v-show="exHsCode.length !== 0">
            <span style="flex-shrink: 0">HS编码：</span>
            <div>
              <el-tag @close="removeExItem(['exHsCode', index])" v-for="(item, index) of exHsCode" :key="item" size="mini" style="margin: 0 10px 10px 0" closable>{{ item }}</el-tag>
            </div>
          </div>
          <div class="ex-item" v-show="exExporter.length !== 0">
            <span style="flex-shrink: 0">出口商：</span>
            <div>
              <el-tag @close="removeExItem(['exExporter', index])" v-for="(item, index) of exExporter" :key="item" size="mini" style="margin: 0 10px 10px 0" closable>{{ item }}</el-tag>
            </div>
          </div>
          <div class="ex-item" v-show="exImporter.length !== 0">
            <span style="flex-shrink: 0">进口商：</span>
            <div>
              <el-tag @close="removeExItem(['exImporter', index])" v-for="(item, index) of exImporter" :key="item" size="mini" style="margin: 0 10px 10px 0" closable>{{ item }}</el-tag>
            </div>
          </div>
          <div class="ex-item" v-show="exCountry.length !== 0">
            <span style="flex-shrink: 0">国家：</span>
            <div>
              <el-tag @close="removeExItem(['exCountry', index])" v-for="(item, index) of exCountry" :key="item" size="mini" style="margin: 0 10px 10px 0" closable>{{ item }}</el-tag>
            </div>
          </div>
        </div>
        <exclude-filter v-if="dialog.render" @destroy="dialog.render = false" />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters, mapMutations } from "vuex";
import axios from "@/firm/plugins/axios";
import dayjs from "@/firm/plugins/dayjs";
import excludeFilter from "../components/exclude-filter.vue";

export default Vue.extend({
  components: {
    excludeFilter,
  },
  data() {
    return {
      loading: false,
      dialog: {
        render: false,
      },
      //importOrExport: this.$store.state.controlFormGlobal.ie, //进出口值
      daterangeRadio: this.$store.state.controlFormGlobal.dateRange,
      //daterange: [dayjs().subtract(1, "years").format("YYYY-MM-DD"), dayjs().format("YYYY-MM-DD")], //this.$store.state.controlFormGlobal.dateRange, //日期
      daterangeStart: dayjs().subtract(this.$store.state.controlFormGlobal.dateRangeNum, "years").format("YYYY-MM-DD"),
      daterangeEnd: dayjs().format("YYYY-MM-DD"),
      dataSourceValue: this.$store.state.controlFormGlobal.dataCountry, //数据源多选框组值
      dataSourceShowMore: false,
      dataSourceOption: {
        //数据源
        totalNum: 0, //全部后面的数字
        option: [
          /*  {
            label: "India", //选项
            num: 2118, //选项后面的数字
            value: "vbr", //国家码，回传
          }, */
        ],
      },
      hsValue: this.$store.state.controlFormGlobal.hsCode,
      hs2ShowMore: false,
      hs2: false,
      hs2Value: [],
      hsCodeOption2: {
        //二位HS编码
        totalNum: 0,
        option: [],
      },
      hs4ShowMore: false,
      hs4: false,
      hs4Value: [],
      hsCodeOption4: {
        //四位HS编码
        totalNum: 0,
        option: [
          /* {
            label: "846412",
            num: 2118,
          }, */
        ],
      },
      hs6ShowMore: false,
      hs6: false,
      hs6Value: [],
      hsCodeOption6: {
        //六位HS编码
        totalNum: 0,
        option: [],
      },
      importValue: this.$store.state.controlFormGlobal.importer, //进口商多选框组值
      importShowMore: false,
      importOption: {
        //进口商
        totalNum: 0,
        option: [
          /* {
            label: "BAJ",
            num: 2118,
          }, */
        ],
      },
      exportValue: this.$store.state.controlFormGlobal.exporter, //出口商多选框组值
      exportShowMore: false,
      exportOption: {
        //出口商
        totalNum: 0,
        option: [
          /*  {
            label: "BAJ",
            num: 2118,
          }, */
        ],
      },
      countryValue: this.$store.state.controlFormGlobal.country, //国家多选框组值
      countryShowMore: false,
      countryOption: {
        //国家
        totalNum: 0,
        option: [
          /*  {
            label: "Uganda",
            num: 2118,
            value: ''
          }, */
        ],
      },
    };
  },
  computed: {
    ...mapState(["company", "companyType"]),
    ...mapState("controlFormGlobal", {
      ie: "ie",
      dataCountry: "dataCountry",
      hsCode: "hsCode",
      importer: "importer",
      exporter: "exporter",
      country: "country",

      exHsCode: "exHsCode",
      exExporter: "exExporter",
      exImporter: "exImporter",
      exCountry: "exCountry",
    }),
    ...mapGetters("controlFormGlobal", {
      startTime: "startTime",
      endTime: "endTime",
    }),
  },
  methods: {
    async getHsCodeOption2() {
      try {
        const res = await axios({
          method: "post",
          url: "/search/Credit/Ranking/HsCode2",
          data: {
            pageIndex: 1,
            pageSize: 100,
            ie: this.ie,
            importer: this.importer,
            exporter: this.exporter,
            dataCountry: this.dataCountry,
            country: this.country,
            startTime: this.startTime,
            endTime: this.endTime,
            hsCode: this.hsCode,
            sortType: 0,
            company: this.company,
            companyType: this.companyType,
          },
        });
        const data = res.data.result.data;
        this.hsCodeOption2 = {
          totalNum: res.data.result.totalFrequency || 0, //data.map((item: any) => item.frequency).reduce((total: any, item: any) => total + item),
          option: data.map((item) => ({
            label: item.encode,
            num: item.frequency,
          })),
        };
        return Promise.resolve("ok");
      } catch {
        this.hsCodeOption2 = {
          totalNum: 0,
          option: [],
        };
        return Promise.resolve("ok");
      }
    },
    async getHsCodeOption4() {
      try {
        const res = await axios({
          method: "post",
          url: "/search/Credit/Ranking/HsCode4",
          data: {
            pageIndex: 1,
            pageSize: 100,
            ie: this.ie,
            importer: this.importer,
            exporter: this.exporter,
            dataCountry: this.dataCountry,
            country: this.country,
            startTime: this.startTime,
            endTime: this.endTime,
            hsCode: this.hsCode,
            sortType: 0,
            company: this.company,
            companyType: this.companyType,
          },
        });
        const data = res.data.result.data;
        this.hsCodeOption4 = {
          totalNum: res.data.result.totalFrequency || 0, //data.map((item: any) => item.frequency).reduce((total: any, item: any) => total + item),
          option: data.map((item) => ({
            label: item.encode,
            num: item.frequency,
          })),
        };
        return Promise.resolve("ok");
      } catch {
        this.hsCodeOption4 = {
          totalNum: 0,
          option: [],
        };
        return Promise.resolve("ok");
      }
    },
    async getHsCodeOption6() {
      try {
        const res = await axios({
          method: "post",
          url: "/search/Credit/Ranking/HsCode",
          data: {
            pageIndex: 1,
            pageSize: 100,
            ie: this.ie,
            importer: this.importer,
            exporter: this.exporter,
            dataCountry: this.dataCountry,
            country: this.country,
            startTime: this.startTime,
            endTime: this.endTime,
            hsCode: this.hsCode,
            sortType: 0,
            company: this.company,
            companyType: this.companyType,
          },
        });
        const data = res.data.result.data;
        this.hsCodeOption6 = {
          totalNum: res.data.result.totalFrequency || 0, //data.map((item: any) => item.frequency).reduce((total: any, item: any) => total + item),
          option: data.map((item) => ({
            label: item.encode,
            num: item.frequency,
          })),
        };
        return Promise.resolve("ok");
      } catch {
        this.hsCodeOption6 = {
          totalNum: 0,
          option: [],
        };
        return Promise.resolve("ok");
      }
    },

    async getDataSourceOption() {
      try {
        const res = await axios({
          method: "post",
          url: "/search/Credit/Ranking/DataCountry",
          data: {
            pageIndex: 1,
            pageSize: 100,
            ie: this.ie,
            importer: this.importer,
            exporter: this.exporter,
            dataCountry: this.dataCountry,
            country: this.country,
            startTime: this.startTime,
            endTime: this.endTime,
            hsCode: this.hsCode,
            sortType: 0,
            company: this.company,
            companyType: this.companyType,
          },
        });
        const data = res.data.result.data;
        this.dataSourceOption = {
          totalNum: res.data.result.totalFrequency, //data.map((item: any) => item.frequency).reduce((total: any, item: any) => total + item),
          option: data.map((item) => ({
            label: item.countryCn,
            num: item.frequency,
            value: item.threeCountryCode,
          })),
        };
        return Promise.resolve("ok");
      } catch {
        this.dataSourceOption = {
          totalNum: 0,
          option: [],
        };
        return Promise.resolve("ok");
      }
    },
    async getImportOption() {
      try {
        const res = await axios({
          method: "post",
          url: "/search/Credit/Ranking/Importer",
          data: {
            pageIndex: 1,
            pageSize: 100,
            ie: this.ie,
            importer: this.importer,
            exporter: this.exporter,
            dataCountry: this.dataCountry,
            country: this.country,
            startTime: this.startTime,
            endTime: this.endTime,
            hsCode: this.hsCode,
            sortType: 0,
            company: this.company,
            companyType: this.companyType,
          },
        });
        const data = res.data.result.data;
        this.importOption = {
          totalNum: res.data.result.totalFrequency, //data.map((item: any) => item.frequency).reduce((total: any, item: any) => Number(total) + Number(item)),
          option: data.map((item) => ({
            label: item.company,
            num: item.frequency,
          })),
        };
        return Promise.resolve("ok");
      } catch {
        this.importOption = {
          totalNum: 0,
          option: [],
        };
        return Promise.resolve("ok");
      }
    },
    async getExportOption() {
      try {
        const res = await axios({
          method: "post",
          url: "/search/Credit/Ranking/Exporter",
          data: {
            pageIndex: 1,
            pageSize: 100,
            ie: this.ie,
            importer: this.importer,
            exporter: this.exporter,
            dataCountry: this.dataCountry,
            country: this.country,
            startTime: this.startTime,
            endTime: this.endTime,
            hsCode: this.hsCode,
            sortType: 0,
            company: this.company,
            companyType: this.companyType,
          },
        });
        const data = res.data.result.data;
        this.exportOption = {
          totalNum: res.data.result.totalFrequency, //data.map((item: any) => item.frequency).reduce((total: any, item: any) => Number(total) + Number(item)),
          option: data.map((item) => ({
            label: item.company,
            num: item.frequency,
          })),
        };
        return Promise.resolve("ok");
      } catch {
        this.exportOption = {
          totalNum: 0,
          option: [],
        };
        return Promise.resolve("ok");
      }
    },
    async getCountryOption() {
      try {
        const res = await axios({
          method: "post",
          url: "/search/Credit/Ranking/Country",
          data: {
            pageIndex: 1,
            pageSize: 100,
            ie: this.ie,
            importer: this.importer,
            exporter: this.exporter,
            dataCountry: this.dataCountry,
            country: this.country,
            startTime: this.startTime,
            endTime: this.endTime,
            hsCode: this.hsCode,
            sortType: 0,
            company: this.company,
            companyType: this.companyType,
          },
        });
        const data = res.data.result.data;
        this.countryOption = {
          totalNum: res.data.result.totalFrequency, //data.map((item: any) => item.frequency).reduce((total: any, item: any) => total + item),
          option: data.map((item) => ({
            label: item.countryCN,
            num: item.frequency,
            value: item.country,
          })),
        };
        return Promise.resolve("ok");
      } catch {
        this.countryOption = {
          totalNum: 0,
          option: [],
        };
        return Promise.resolve("ok");
      }
    },
    ...mapMutations(["setType"]),
    ...mapMutations("controlFormGlobal", {
      setControlFormGlobal: "setControlFormGlobal",
      removeControlFormGlobalItemByIndex: "removeControlFormGlobalItemByIndex",
    }),
    removeExItem(payload) {
      this.removeControlFormGlobalItemByIndex(payload);
    },
    //由单选框组改变 dateRangeRadio
    handleDateRangeChangeByRadioGroup(value) {
      if (value === "一年") {
        this.daterangeStart = dayjs().subtract(1, "years").format("YYYY-MM-DD");
        this.daterangeEnd = dayjs().format("YYYY-MM-DD");
        this.setControlFormGlobal([["dateRange", ["自定义", this.daterangeStart, this.daterangeEnd]]]);
      } else if (value === "两年") {
        this.daterangeStart = dayjs().subtract(2, "years").format("YYYY-MM-DD");
        this.daterangeEnd = dayjs().format("YYYY-MM-DD");
        this.setControlFormGlobal([["dateRange", ["自定义", this.daterangeStart, this.daterangeEnd]]]);
      } else if (value === "三年") {
        this.daterangeStart = dayjs().subtract(3, "years").format("YYYY-MM-DD");
        this.daterangeEnd = dayjs().format("YYYY-MM-DD");
        this.setControlFormGlobal([["dateRange", ["自定义", this.daterangeStart, this.daterangeEnd]]]);
      } else if (value === "五年") {
        this.daterangeStart = dayjs().subtract(5, "years").format("YYYY-MM-DD");
        this.daterangeEnd = dayjs().format("YYYY-MM-DD");
        this.setControlFormGlobal([["dateRange", ["自定义", this.daterangeStart, this.daterangeEnd]]]);
      }
      this.refresh();
    },
    //由日期选择器改变 dateRangeStart
    handleDateRangeStartChange(value) {
      this.daterangeRadio = "";
      if (value) {
        this.setControlFormGlobal([["dateRange", ["自定义", value, this.daterangeEnd]]]);
      } else {
        this.setControlFormGlobal([["dateRange", "不限"]]);
      }
      this.refresh();
    },
    //由日期选择器改变 dateRangeEnd
    handleDateRangeEndChange(value) {
      this.daterangeRadio = "";
      if (value) {
        this.setControlFormGlobal([["dateRange", ["自定义", this.daterangeStart, value]]]);
      } else {
        this.setControlFormGlobal([["dateRange", "不限"]]);
      }
      this.refresh();
    },
    //刷新选项
    async refresh() {
      this.loading = true;
      //await Promise.allSettled([this.getDataSourceOption(), this.getHsCodeOption2(), this.getHsCodeOption4(), this.getHsCodeOption6(), this.getImportOption(), this.getExportOption(), this.getCountryOption()]);
      await this.getDataSourceOption();
      await this.getHsCodeOption2();
      await this.getHsCodeOption4();
      await this.getHsCodeOption6();
      await this.getImportOption();
      await this.getExportOption();
      await this.getCountryOption();
      this.loading = false;
    },
  },
  watch: {
    ie() {
      this.refresh();
    },
    dataSourceValue(val) {
      this.setControlFormGlobal([["dataCountry", val]]);
      this.refresh();
    },
    importValue(val) {
      this.setControlFormGlobal([["importer", val]]);
      this.refresh();
    },
    exportValue(val) {
      this.setControlFormGlobal([["exporter", val]]);
      this.refresh();
    },
    countryValue(val) {
      this.setControlFormGlobal([["country", val]]);
      this.refresh();
    },
    hs2(val) {
      if (!val) {
        this.hs2Value = [];
      }
    },
    hs4(val) {
      if (!val) {
        this.hs4Value = [];
      }
    },
    hs6(val) {
      if (!val) {
        this.hs6Value = [];
      }
    },
    hs2Value(val) {
      this.hsValue = [...val, ...this.hs4Value, ...this.hs6Value];
    },
    hs4Value(val) {
      this.hsValue = [...val, ...this.hs2Value, ...this.hs6Value];
    },
    hs6Value(val) {
      this.hsValue = [...val, ...this.hs2Value, ...this.hs4Value];
    },
    hsValue(val) {
      this.setControlFormGlobal([["hsCode", val]]);
      this.refresh();
    },
    companyType() {
      this.refresh();
    },
  },
  mounted() {
    this.refresh();
  },
});
</script>

<style lang="scss" scoped>
.filter-criteria {
  margin-top: 1rem;
  background: #fff;
  padding: 1.875rem;
  padding-bottom: 0;
  .panel {
    padding: 20px 35px;
    border-radius: 4px;
    background: #f5f7fa;
    font-size: 12px;
    .title {
      font-size: 16px;
      color: #2a3f58;
      margin-bottom: 1.5em;
    }
    .filter-item {
      display: flex;
      margin-bottom: 1em;
      &:last-child {
        margin-bottom: 0;
      }
      .item-title {
        color: #5e6d82;
        width: 6em;
        display: flex;
        align-items: flex-start;
      }
      .item-content {
        position: relative;
        width: 100%;
        padding-right: 4em;
        .more-control {
          cursor: pointer;
          * {
            cursor: pointer;
          }
          position: absolute;
          top: 0;
          right: 0;
          color: #979797;
          span {
            color: #979797;
            margin: 0;
          }
        }
        label.el-checkbox {
          padding: 0 5px;
          //color: #2a3f58;
          &:hover {
            color: var(--themeColor, #3152AF);
            background:#bac3de;
          }
          &.selected {
            color: var(--themeColor, #3152AF);
            background:#bac3de;
          }
        }
        span {
          display: inline-block;
          height: 100%;
          //margin-right: 2em;
          //padding: 2px 9px 1px 9px;
          border-radius: 5px;
          /* &.all {
            line-height: 2em;
            padding-bottom: 5px;
          } */
        }
      }
    }
  }

  .ex-item {
    display: flex;
    color: #2a3f58;
  }

  ::v-deep .el-radio__input {
    display: none;
  }
  ::v-deep .el-radio__label {
    font-size: 12px;
    padding: 0;
  }
  ::v-deep .el-radio {
    padding: 2px 9px 1px 9px;
    border-radius: 5px;
    &.is-checked {
      background:#bac3de;
    }
    &:hover {
      color: var(--themeColor, #3152AF);
      background:#bac3de;
    }
  }
  ::v-deep .el-checkbox__input {
    display: none;
  }
  ::v-deep .el-checkbox__label {
    font-size: 12px;
    padding: 0;
  }
  ::v-deep .el-checkbox {
    //padding: 2px 9px 1px 9px;
    border-radius: 5px;
    &.is-checked {
      background:#bac3de;
    }
    &:hover {
      color: var(--themeColor, #3152AF);
      background:#bac3de;
    }
  }
  ::v-deep .el-checkbox__label {
    box-sizing: border-box;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .custome-date-picker {
    background: #fff;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    ::v-deep .end {
      .el-input__prefix {
        display: none;
      }
    }
    ::v-deep .el-input__inner {
      border: none;
    }
  }
}
</style>
