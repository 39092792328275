<template>
  <!-- 不包含过滤，未复用 -->
  <div class="exclude-filter">
    <el-dialog class="exclude-dialog" visible width="540px">
      <p style="font-size: 18px; color: #1f2d3d; margin-bottom: 20px">不包含过滤</p>
      <div style="border: 1px solid #d3dce6; border-radius: 2px; padding: 20px">
        <div class="form-item">
          <span class="label">HS编码</span>
          <el-select v-model="edit.hs" style="width: 100%" placeholder="不包含的HS编码" size="mini" default-first-option allow-create filterable multiple></el-select>
        </div>
        <div class="form-item">
          <span class="label">出口商</span>
          <el-select v-model="edit.export" style="width: 100%" placeholder="不包含的出口商" size="mini" default-first-option allow-create filterable multiple></el-select>
        </div>
        <div class="form-item">
          <span class="label">进口商</span>
          <el-select v-model="edit.import" style="width: 100%" placeholder="不包含的进口商" size="mini" default-first-option allow-create filterable multiple></el-select>
        </div>
        <div class="form-item">
          <span class="label">国家</span>
          <el-select v-model="edit.country" style="width: 100%" placeholder="不包含的国家" size="mini" default-first-option allow-create filterable multiple></el-select>
        </div>
      </div>
      <div style="display: flex; justify-content: flex-end; margin-top: 16px">
        <el-button @click="$emit('destroy')" size="small">取消</el-button>
        <el-button @click="confirm" type="primary" size="small">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import { mapMutations } from "vuex";

export default Vue.extend({
  data() {
    return {
      edit: {
        hs: this.$store.state.controlFormGlobal.exHsCode,
        export: this.$store.state.controlFormGlobal.exExporter,
        import: this.$store.state.controlFormGlobal.exImporter,
        country: this.$store.state.controlFormGlobal.exCountry,
      },
    };
  },
  methods: {
    ...mapMutations("controlFormGlobal", {
      setControlFormGlobal: "setControlFormGlobal",
    }),
    confirm() {
      const payload = [
        ["exHsCode", this.edit.hs],
        ["exExporter", this.edit.export],
        ["exImporter", this.edit.import],
        ["exCountry", this.edit.country],
      ];
      this.setControlFormGlobal(payload);
      this.$emit("destroy");
    },
  },
});
</script>

<style lang="scss" scoped>
.exclude-filter {
  ::v-deep .el-dialog__header {
    display: none;
  }
  ::v-deep .el-dialog__body {
    font-size: 14px;
    padding: 20px;
  }
  .form-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 13px;
    .label {
      color: #2a3f58;
      display: inline-block;
      width: 60px;
      text-align: right;
      padding-right: 10px;
    }
    ::v-deep .el-input__suffix {
      display: none;
    }
  }
}
</style>
