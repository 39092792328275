<template>
    <div>
        <div style="margin-bottom:10px;">
            <el-button size="small" type="primary" icon="el-icon-plus" @click="toRelation">关联产品</el-button>
            <el-input v-model="filterParam.productName" placeholder="产品名称" size="small" style="width:240px;margin-right:10px;margin-left: 10px;" @keyup.enter.native="onSearchRelation" clearable></el-input>
            <el-button size="small" type="primary" @click="onSearchRelation">搜 索</el-button>
        </div>
        <div style="overflow: hidden;">
            <el-table v-loading="tableLoading" :data="relationList" :header-cell-style="{'background-color':'#F2F2F2','color':'#3A3A3A'}">
                <el-table-column prop="productNo" label="产品编号">
                    <template slot-scope="scope">
                        <el-link @click.stop="toDetails(scope.row)">{{scope.row.productNo}}</el-link>
                    </template>
                </el-table-column>
                <el-table-column label="产品图片">
                    <template slot-scope="scope">
                        <el-image style="width:50px;height:50px;" :src="scope.row.images" :preview-src-list="[scope.row.images]"></el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="productName" label="产品名称"></el-table-column>
                <el-table-column prop="dimension" label="尺寸信息"></el-table-column>
                <el-table-column prop="ingredient" label="成分"></el-table-column>
                <el-table-column prop="supplier" label="供应商"></el-table-column>
                <el-table-column prop="measureUnit" label="计量单位"></el-table-column>
                <el-table-column prop="createTime" label="创建时间" width="160"></el-table-column>
                <el-table-column label="操作" width="120">
                    <template slot-scope="scope">
                        <el-link type="primary" @click.stop="toDetails(scope.row)">查看</el-link>
                        <span style="padding:0 5px;color:#C7C7C7;">|</span>
                        <el-link type="danger" @click.stop="toDelete(scope.row)">解除</el-link>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination hide-on-single-page background layout="total,prev,pager,next" :total="relationTotal" style="float:right;margin:10px 20px;" :current-page.sync="relationPageIndex" @current-change="getRelationProductList"></el-pagination>
        </div>

        <el-dialog title="关联产品" :visible.sync="relationVisible" width="800px" top="50px">
            <div style="margin-bottom:10px;">
                <el-input v-model="filterParam.productNo" placeholder="产品编号" size="small" style="width:240px;margin-right:10px;" @keyup.enter.native="toSearchSource" clearable></el-input>
                <el-input v-model="filterParam.productName" placeholder="产品名称" size="small" style="width:240px;margin-right:10px;" @keyup.enter.native="toSearchSource" clearable></el-input>
                <el-button size="small" type="primary" @click="toSearchSource">搜 索</el-button>
            </div>
            <div style="overflow: hidden;">
                <el-table v-loading="tableLoading" :data="sourceList" :header-cell-style="{'background-color':'#F2F2F2','color':'#3A3A3A'}" @selection-change="selectionChange">
                    <el-table-column type="selection" width="45"></el-table-column>
                    <el-table-column prop="productNo" label="产品编号">
                        <template slot-scope="scope">
                            <el-link @click.stop="toDetails(scope.row)">{{scope.row.productNo}}</el-link>
                        </template>
                    </el-table-column>
                    <el-table-column label="产品图片">
                        <template slot-scope="scope">
                            <el-image style="width:30px;height:30px;" :src="scope.row.images" :preview-src-list="[scope.row.images]"></el-image>
                        </template>
                    </el-table-column>
                    <el-table-column prop="productName" label="产品名称"></el-table-column>
                    <el-table-column prop="supplier" label="供应商"></el-table-column>
                    <el-table-column prop="measureUnit" label="计量单位"></el-table-column>
                </el-table>
                <el-pagination background layout="total,prev,pager,next" :total="sourceTotal" style="float:right;margin:10px 20px;" :current-page.sync="sourcePageIndex" @current-change="getSourceProductList"></el-pagination>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="relationVisible = false;">取 消</el-button>
                <el-button size="small" type="primary" :loading="btnLoading" :disabled="relationKeys.length==0" @click="relationComplete">确认关联</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
export default {
    data() {
        return {
            customerKey: this.$route.query.customerKey,
            tableLoading: false,
            filterParam: {
                productNo: "",
                productName: "",
            },
            //获取源产品列表
            sourceList: [],
            sourceTotal: 0,
            sourcePageIndex: 1,
            //关联功能
            relationVisible: false,
            relationKeys: [],
            btnLoading: false,
            //获取关联产品列表
            relationList: [],
            relationTotal: 0,
            relationPageIndex: 1,
        };
    },
    methods: {
        //查看产品详情
        toDetails(row){
            let productKey = row.productKey || row.key;
            let routeData = this.$router.resolve({
                path: "/orderManage/productDetails",
                query: { productKey: productKey },
            });
            window.open(routeData.href, "_blank");
        },

        getSourceProductList(){
            const {productNo,productName} = this.filterParam;
            this.tableLoading = true;
            this.$axios.post(`${this.$productUrl}/api/Product/List`,{
                productNo,productName,
                pageIndex: this.sourcePageIndex,
                pageSize: 10,
            }).then((res) => {
                const { isSuccess , data } = res;
                this.tableLoading = false;
                if (isSuccess) {
                    this.sourceList = data.datas;
                    this.sourceTotal = data.totalItems;
                }
            });
        },
        toSearchSource(){
            this.sourcePageIndex = 1;
            this.getSourceProductList();
        },

        toRelation(){
            this.relationVisible = true;
            this.toSearchSource();
            this.relationKeys = [];
        },
        selectionChange(value){
            this.relationKeys = value.map(ele => ele.key);
        },
        relationComplete(){
            this.btnLoading = true;
            this.$axios.post(`${this.$customerUrl}/Company/RelevanceProduct`,{
                customerKey: this.customerKey,
                productKeys: this.relationKeys,
            }).then((res) => {
                const { isSuccess } = res;
                this.btnLoading = false;
                this.relationVisible = false;
                if (isSuccess) {
                    this.$message({ message: '关联成功', type: 'success'});
                    this.onSearchRelation();
                }
            });
        },

        //获取关联列表
        getRelationProductList(){
            this.tableLoading = true;
            this.$axios.post(`${this.$customerUrl}/Company/Get/RelevanceProduct`,{
                customerKey: this.customerKey,
                productName: this.filterParam.productName,
                pageIndex: this.relationPageIndex,
                pageSize: 10,
            }).then((res) => {
                const { isSuccess, data } = res;
                this.tableLoading = false;
                if (isSuccess) {
                    this.relationList = data.datas;
                    this.relationTotal = data.totalItems;
                }
            });
        },
        onSearchRelation(){
            this.relationPageIndex = 1;
            this.getRelationProductList();
        },

        //解除关联产品
        toDelete(row){
            this.$confirm('是否解除该关联产品?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios.get(`${this.$customerUrl}/Company/Delete/RelevanceProduct?key=${row.key}`).then((res) => {
                    const { isSuccess } = res;
                    if (isSuccess) {
                        this.$message({ type: 'success', message: '解除成功!'});
                        this.onSearchRelation();
                    }
                });
            }).catch(() => {});
        },
    },
    mounted() {
        //获取关联列表
        this.getRelationProductList();
    },
};
</script>

<style lang="scss" scoped>
/deep/.el-dialog__body{
    padding: 10px 20px 0 20px;
}
</style>