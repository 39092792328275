<template>
  <el-drawer @open="onOpen" :visible="visible" :with-header="false" :size="550" @close="onClose" destroy-on-close>
    <div style="padding: 0 30px">
      <div style="padding: 20px 0">
        <img @click="onClose" src="@/assets/img/goback.png" style="cursor: pointer" />
      </div>
      <div>
        <div style="display: flex; margin-bottom: 5px">
          <div style="margin-right: 20px">
            <img src="@/assets/img/customerFace.png" style="vertical-align: bottom" />
          </div>
          <div style="display: flex; flex-direction: column; justify-content: space-between">
            <p style="font-size: 20px">{{ info.name }}</p>
            <div style="font-size: 14px; color: #1a2745; display: flex; align-items: center">
              <img :src="info.flagUrl" style="height: 14px; margin-right: 5px" />
              <span>{{ info.country }}</span>
            </div>
          </div>
        </div>
        <el-rate v-model="info.star" disabled />
      </div>
    </div>
    <el-tabs>
      <el-tab-pane label="跟进记录">
        <CustomerDynamics :customerKey="customerKey" />
      </el-tab-pane>
      <el-tab-pane label="资料">
        <el-descriptions :column="1">
          <el-descriptions-item label="客户编号">{{ info.companyNo }}</el-descriptions-item>
          <el-descriptions-item label="公司名称">
            <span style="color: var(--themeColor)">{{ info.name }}</span>
          </el-descriptions-item>
          <el-descriptions-item label="联系人">{{ info.mainContacterName }}</el-descriptions-item>
          <el-descriptions-item label="公司来源">{{ info.customerSourceName }}</el-descriptions-item>
          <el-descriptions-item label="国家地区">
            <div style="display: flex; align-items: center">
              <img :src="info.flagUrl" style="height: 14px; margin-right: 5px" />
              <span>{{ info.country }}</span>
            </div>
          </el-descriptions-item>
          <el-descriptions-item label="时区">{{ info.timeZone }}</el-descriptions-item>
          <el-descriptions-item label="传真">{{ info.fax }}</el-descriptions-item>
          <el-descriptions-item label="公司网址">
            <span style="color: var(--themeColor); text-decoration: underline">{{ info.webSite }}</span>
          </el-descriptions-item>
        </el-descriptions>
      </el-tab-pane>
      <el-tab-pane label="图片">
        <p>图片</p>
      </el-tab-pane>
    </el-tabs>
  </el-drawer>
</template>

<script>
import CustomerDynamics from "@/components/CustomerDynamics.vue"; //客户动态

export default {
  support: {
    sourceKey2Name: null,
  },
  props: ["visible", "customerKey"],
  components: { CustomerDynamics },
  data() {
    return {
      info: {},
    };
  },
  methods: {
    onClose() {
      this.$emit("update:visible", false);
    },
    async getSourceKey2Name() {
      if (this.$options.support.sourceKey2Name) {
        return;
      }
      try {
        const res = await this.$axios({
          method: "GET",
          url: this.$customerUrl + "/v1/Config/Source",
        });
        if (!res.data.length) {
          throw new Error();
        }
        const map = new Map();
        res.data.forEach((item) => {
          map.set(item.key, item.name);
        });
        this.$options.support.sourceKey2Name = map;
      } catch {
        this.$options.support.sourceKey2Name = null;
      }
    },
    async getInfo() {
      try {
        await this.getSourceKey2Name();
        const res = await this.$axios({
          method: "GET",
          url: this.$customerUrl + "/Company",
          params: {
            companykey: this.customerKey,
          },
        });
        this.info = {
          ...res.data,
          flagUrl: window.getFlagUrlByCountry ? window.getFlagUrlByCountry(res.data.country) : "",
        };
        if (this.$options.support.sourceKey2Name) {
          this.info.customerSourceName = this.$options.support.sourceKey2Name.get(this.info.customerSourceKey) || "";
        }
      } catch {
        this.info = {};
      }
    },
    onOpen() {
      this.getInfo();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
  padding-left: 24px;
}
::v-deep .el-tab-pane {
  padding: 0 24px;
  overflow-y: auto;
  height: calc(100vh - 200px);
}
::v-deep .el-descriptions-item__label {
  color: #707070;
}
::v-deep .el-descriptions-item__content {
  color: #092338;
}
</style>
