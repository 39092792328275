<template>
    <div>
        <div style="overflow: hidden;">
            <el-table v-loading="tableLoading" :data="supplierList" :header-cell-style="{'background-color':'#F2F2F2','color':'#3A3A3A'}">
                <el-table-column label="供应商名称">
                    <template slot-scope="scope">
                        <el-link @click.stop="toDetails(scope.row)">{{scope.row.manufacturerName}}</el-link>
                    </template>
                </el-table-column>
                <el-table-column prop="manufacturerNo" label="供应商编号"></el-table-column>
                <el-table-column prop="placeOrigin" label="境内货源地"></el-table-column>
                <el-table-column prop="city" label="所在城市"></el-table-column>
            </el-table>
            <el-pagination hide-on-single-page background layout="total,prev,pager,next" :total="totalItems" style="float:right;margin:10px 20px;" :current-page.sync="pageIndex" @current-change="getCustomerSupplier"></el-pagination>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            customerKey: this.$route.query.customerKey,
            tableLoading: false,
            //获取客户供应商
            supplierList: [],
            totalItems: 0,
            pageIndex: 1,
            
        };
    },
    methods: {
        //获取客户供应商
        getCustomerSupplier(){
            this.tableLoading = true;
            this.$axios.post(`${this.$orderUrl}/api/Order/GetCustomerSupplier`,{
                customerKey: this.customerKey,
                pageIndex: this.pageIndex,
                pageSize: 10,
            }).then((res) => {
                const { isSuccess, data } = res;
                this.tableLoading = false;
                if (isSuccess) {
                    this.supplierList = data.datas;
                    this.totalItems = data.totalItems;
                }
            });
        },
        toDetails(row){
            let routeData = this.$router.resolve({
                path: "/purchase/purchaserDetails",
                query: { key: row.key },
            });
            window.open(routeData.href, "_blank");
        },
    },
    mounted() {
        //获取客户供应商
        this.getCustomerSupplier();
    },
};
</script>

<style lang="scss" scoped>
/deep/.el-dialog__body{
    padding: 10px 20px 0 20px;
}
</style>