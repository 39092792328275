<template>
    <div>
        <el-card>
            <div class="header">个人使用配置</div>
            <div class="boxItem">
                <div class="title">客户设置</div>
                <div class="describe">设置公司分组，掌握客户动态</div>
                <div class="boundary"></div>
                <div class="setItem">
                    <div class="name">
                        <span class="sp1">1、私海分组</span>
                        <span class="sp2" v-show="PrivateGroupOption.length>0"><img src="@/assets/icon/haveSet.png" alt=""> 已填写</span>
                    </div>
                    <div class="setContent" v-show="PrivateGroupOption.length>0">
                        <span class="spanItem" v-for="(element,index) in PrivateGroupOption" :key="index">
                            {{element.name}}
                        </span>
                    </div>
                    <el-button size="mini" v-show="PrivateGroupOption.length==0" @click="goSee">去看看</el-button>
                </div>
            </div>
            <div class="boxItem">
                <div class="title">邮件设置</div>
                <div class="describe">添加邮箱后查看所有邮箱的邮件，及时回复客户信息</div>
                <div class="boundary"></div>
                <div class="setItem">
                    <div class="name">
                        <span class="sp1">1、标签</span>
                        <span class="sp2" v-show="LabelOption.length>0"><img src="@/assets/icon/haveSet.png" alt=""> 已填写</span>
                    </div>
                    <div class="setContent" v-show="LabelOption.length>0">
                        <el-tag class="spanItem" v-for="(element,index) in LabelOption" :key="index" :color="element.color" effect="dark" size="mini">
                            {{element.text}}
                        </el-tag>
                    </div>
                    <el-button size="mini" v-show="LabelOption.length==0" @click="goSee">去看看</el-button>
                </div>
                <div class="setItem">
                    <div class="name">
                        <span class="sp1">2、邮箱管理</span>
                        <span class="sp2" v-show="MailOption.length>0"><img src="@/assets/icon/haveSet.png" alt=""> 已填写</span>
                    </div>
                    <div class="setContent" v-show="MailOption.length>0">
                        <span class="spanItem" v-for="(element,index) in MailOption" :key="index">
                            {{element.eMail}}
                        </span>
                    </div>
                    <el-button size="mini" v-show="MailOption.length==0" @click="goSee">去看看</el-button>
                </div>
            </div>
        </el-card>
    </div>
</template>

<script>
export default {
    props: {
        handleSelect: {
            type: Function,
            default: null,
        },
    },
    data() {
        return {
            PrivateGroupOption: [],
            LabelOption: [],
            MailOption: [],
        };
    },
    methods: {
        goSee(){
            this.handleSelect(`/settingIndex`);
            this.$router.push(`/settingIndex`);
        },

        // 私海分组配置
        getPrivateGroup(){
            this.$axios.get(`${this.$customerUrl}/v1/Config/PrivateGroup`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.PrivateGroupOption = data;
                }
            });
        },
        // 标签配置
        getLable(){
            this.$axios.get(`${this.$mailUrl}/v1/config/Lable`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.LabelOption = data;
                }
            });
        },
        // 邮箱管理
        getMailbox(){
            this.$axios.get(`${this.$mailUrl}/v1/config/SendCollectMail`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.MailOption = data;
                }
            });
        },
    },
    mounted() {
        this.getPrivateGroup();     //私海分组配置
        this.getLable();            //标签配置
        this.getMailbox();          //邮箱管理
    },
};
</script>

<style lang="scss" scoped>
.header{
    height: 30px;
    line-height: 30px;
    font-size: 20px;
    font-weight: bold;
    color: var(--themeColor);
}
.boxItem{
    background: #F6F8FA;
    border: 1px solid #CFCFCF;
    padding: 10px 20px;
    margin-top: 10px;
    .title{
        font-size: 18px;
        line-height: 30px;
        font-weight: bold;
        color: #1F2D3D;
    }
    .describe{
        font-size: 14px;
        line-height: 28px;
        color: #A5A5A5;
    }
    .boundary{
        padding-bottom: 5px;
        border-bottom: 1px solid #C7C7C7;
    }
    .setItem{
        margin-top: 10px;
        .name{
            .sp1{
                padding-right: 25px;
            }
            .sp2{
                color:#20BC5F;
            }
        }
        img{
            vertical-align: sub;
        }
    }
    .setContent{
        margin-top: 10px;
        padding: 10px 20px;
        background: #EFEFEF;
        color: #3A3A3A;
        line-height: 30px;
        .spanItem{
            margin-right: 20px;
        }
    }
}
</style>