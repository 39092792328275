<template>
    <div class="searchBox">
        <div class="title">
            <span>地理信息</span>
        </div>
        <el-form :model="geographyForm" labelPosition='top' size="small">
            <el-row :gutter="30">
                <el-col :span="6">
                    <el-form-item label="邮编">
                        <el-input v-model="geographyForm.postCodes" @change="fieldChange" placeholder="邮编" style="width:100%;"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="区域">
                        <el-select v-model="geographyForm.regionCode" @change="getProvince" placeholder="区域" style="width:100%;" filterable clearable>
                            <el-option v-for="item of regionOption" :key="item.code" :label="item.name" :value="item.code">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="省">
                        <el-select v-model="geographyForm.provinceCode" @change="getCity" placeholder="省" style="width:100%;" filterable clearable>
                            <el-option v-for="item of provinceOption" :key="item.code" :label="item.name" :value="item.code">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="城市">
                        <el-select v-model="geographyForm.cityCode" @change="fieldChange" placeholder="城市" style="width:100%;" filterable clearable>
                            <el-option v-for="item of cityOption" :key="item.code" :label="item.name" :value="item.code">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="详细地址">
                        <el-input v-model="geographyForm.streetAddress" @change="fieldChange" placeholder="详细地址" style="width:100%;"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="筛选经纬度">
                        <el-checkbox v-model="geographyForm.hasCoordinates" @change="fieldChange">筛选有经纬度坐标值记录</el-checkbox>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
export default {
    props: ["countryCode"],
    data() {
        return {
            geographyForm: {
                postCodes: "",
                regionCode: "",
                provinceCode: "",
                cityCode: "",
                streetAddress: "",
                hasCoordinates: false,
            },
            regionOption: [],
            provinceOption: [],
            cityOption: [],
        };
    },
    methods: {
        //查询infobel 区域信息
        getRegion(){
            this.$axios.get(`${this.$clueUrl}/BusinessData/Infobel/Region?countryCode=${this.countryCode}`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.regionOption = data ? data : [];
                    this.provinceOption = [];
                    this.cityOption = [];
                    this.geographyForm.regionCode = "";
                    this.geographyForm.provinceCode = "";
                    this.geographyForm.cityCode = "";
                }
            });
        },
        //查询infobel 省信息
        getProvince(regionCode){
            this.$axios.get(`${this.$clueUrl}/BusinessData/Infobel/Province?countryCode=${this.countryCode}&regionCode=${regionCode}`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.provinceOption = data ? data : [];
                    this.cityOption = [];
                    this.geographyForm.provinceCode = "";
                    this.geographyForm.cityCode = "";
                }
            });
            this.$emit('inputComplete',this.geographyForm);
        },
        //查询infobel 市信息
        getCity(provinceCode){
            this.$axios.get(`${this.$clueUrl}/BusinessData/Infobel/City?countryCode=${this.countryCode}&provinceCode=${provinceCode}`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.cityOption = data ? data : [];
                    this.geographyForm.cityCode = "";
                }
            });
            this.$emit('inputComplete',this.geographyForm);
        },

        fieldChange(){
            this.$emit('inputComplete',this.geographyForm);
        },
    },
    mounted() {

    },
    watch:{
        //国家码改变，重新请求区域配置
        countryCode(){
            this.getRegion();
        }
    }
};
</script>

<style lang="scss" scoped>
.searchBox{
    border-top: 1px solid #E6E6E6;
    padding-left: 40px;
    .title{
        color: #3A3A3A;
        font-weight: bold;
        font-size: 16px;
        margin: 10px 0;
    }
}
</style>