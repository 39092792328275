<template>
    <div>
        <h2>数据推送验签机制</h2>
        <div class="title">验签说明</div>
        <p>在数据推送的时候会调用客户提供的推送接口，此时支持您按照下文约定的通用验签规则，对数据推送者进行身份验签。</p>
        <p>1.如需验签，双方需按照下文约定方式进行，验证回调者身份，保障通信安全</p>
        <p>2.如不需要验签，忽略下文</p>

        <div class="title">签名参数</div>
        <el-table class="table" :data="signParameter" :header-cell-style="{'background-color':'#F2F2F2','color':'#3A3A3A'}">
            <el-table-column prop="paramName" label="参数"></el-table-column>
            <el-table-column prop="description" label="描述"></el-table-column>
        </el-table>
        <p>a.生成签名字符串： 按照字母排序，secret 不参与排序，拼接到最后,得到签名字符串strToSign：</p>

        <pre v-highlightjs><code class="language-text">{{code1}}</code></pre>

        <p>b. 生成签名 使用MD5对签名字符串加密，得到签名sign sign = MD516(str)</p>
        <p>c. 发起回调请求 得到签名后, 企芯会将签名参数（nonce， timestamp， sign）填充到请求头后发起推送回调；</p>
        <p>您收到的请求是这样子的, 请求头中不会包含specialKey，secret：</p>

        <pre v-highlightjs><code class="language-text">{{code2}}</code></pre>

        <div class="title">验证签名</div>
        <p>您收到回调请求后，取出请求头中的签名参数nonce,timestamp，然后结合specialKey和secret生成signA,从请求头取出signB,比对2个sign的一致性,二者相等，则验签成功，否则验签失败。</p>
        <p>可参考如下验证代码：</p>
  
        <pre v-highlightjs><code>{{code3}}</code></pre>

    </div>
</template>

<script>
export default {
    data() {
        return {
            signParameter:[
                { paramName: "specialKey", description: "specialKey 开户时候企芯提供 ，不要泄露" },
                { paramName: "nonce", description: "随机数" },
                { paramName: "timestamp", description: "时间戳，单位毫秒，可以取当前接口调用时间" },
                { paramName: "secret", description: "Secret Access Key 开户时候企芯提供 ，不要泄露" },
            ],
            code1: "specialKey=xxxx&nonce=xxnonce&timestamp=xxtimestamp&secret=xxsecret",
            code2: `https://www.xxx.com/data/callback 
请求头：
nonce=xxnonce
timestamp=xxtimestamp
sign=xxsign`,
            code3: `/// <summary>
/// 比较签名
/// </summary>
/// <param name="specialKey">企芯颁发的specialKey</param>
/// <param name="nonce">接口回调通过请求头传过来的随机数</param>
/// <param name="timestamp">接口回调通过请求头传过来的时间戳</param>
/// <param name="secretKey">企芯颁发的secretKey</param>
/// <param name="requestSignature">接口回调通过请求头传过来的签名</param>
/// <returns></returns>
public bool checkSignature(string specialKey, long nonce, long timestamp, string secretKey, string requestSignature)
{
    //获取到请求nonce、timestamp 然后结合specialKey和secret生成signA
    string signA = GenerateSignature(specialKey, nonce, timestamp, secretKey);
    //比较接口回调通过请求头传过来的签名验证和生成的signA是否相等，二者相等，验签成功，否则验签失败
    return signA.Equals(requestSignature);
}

/// <summary>
/// 生成签名
/// </summary>
///  <param name="specialKey">企芯颁发的specialKey</param>
/// <param name="nonce">接口回调通过请求头传过来的随机数</param>
/// <param name="timestamp">接口回调通过请求头传过来的时间戳</param>
/// <param name="secretKey">企芯颁发的secretKey</param>
/// <returns></returns>
private static string GenerateSignature(string specialKey, long nonce, long timestamp, string secretKey)
{
    var resultStr = $"specialKey={specialKey}&nonce={nonce}&timestamp={timestamp}&secret={secretKey}";
    return MD516(resultStr);
}

/// <summary>
/// MD5加密字符串16进制
/// </summary>
/// <param name="source">源字符串</param>
private static string MD516(string source)
{
    try
    {

        //密文
        string pwd = string.Empty;
        //实例化一个md5对像
        MD5 md5 = MD5.Create();
        // 加密后是一个字节类型的数组，这里要注意编码UTF8/Unicode等的选择　
        byte[] s = md5.ComputeHash(Encoding.UTF8.GetBytes(source));
        // 通过使用循环，将字节类型的数组转换为字符串，此字符串是常规字符格式化所得
        for (int i = 0; i < s.Length; i++)
        {
            // 将得到的字符串使用十六进制类型格式。
            //格式后的字符是小写的字母，如果使用大写（X）则格式后的字符是大写字符
            //X2表示16进制
            pwd += s[i].ToString("x2");
        }
        return pwd;
    }
    catch (Exception e)
    {
        return source;
    }
}`
        };
    },
    methods: {

    },
    mounted() {

    },
};
</script>

<style lang="scss" scoped>
h2 {
    padding-bottom: 10px;
    border-bottom: 1px solid #d9d9d9;
}
p{
    font-size: 14px;
    line-height: 28px;
    color: #333;
}
.title {
    font-weight: bold;
    font-size: 20px;
    line-height: 40px;
    margin-top: 20px;
}
.table{
    border: 1px solid #d7d7d7;
}
pre code.hljs{
    font-size: 14px;
    font-family: Source Han Sans CN;
}
</style>