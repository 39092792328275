<template>
    <div class="newBox">
        <div class="header">{{theme}}</div>
        <div class="title">基本信息</div>
        <el-form :model="submitForm" label-width="100px" size="small">
            <el-row :gutter="16">
                <el-col :span="8">
                    <el-form-item label="厂商名称" required>
                        <el-input v-model="submitForm.manufacturerName" class="w100" @blur="generateNumber"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="厂商编号" required>
                        <el-input v-model="submitForm.manufacturerNo" class="w100"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="公司主页">
                        <el-input v-model="submitForm.companyHomepage" class="w100"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="所在城市">
                        <el-input v-model="submitForm.city" class="w100"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="所在省份">
                        <el-input v-model="submitForm.province" class="w100"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="境内货源地">
                        <el-input v-model="submitForm.placeOrigin" class="w100"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="邮政编码">
                        <el-input v-model="submitForm.postalCode" class="w100"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="详细地址">
                        <el-input v-model="submitForm.address" class="w100"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="信用等级">
                        <el-input v-model="submitForm.creditRating" class="w100"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="厂商来源">
                        <el-input v-model="submitForm.manufacturerSource" class="w100"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="业务范围">
                        <el-input v-model="submitForm.businessScope" class="w100"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="结算方式">
                        <el-input v-model="submitForm.settlementMethod" class="w100"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="合作等级">
                        <el-input v-model="submitForm.cooperationLevel" class="w100"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="能否开票">
                        <el-select v-model="submitForm.isInvoicing" class="w100">
                            <el-option label="是" value="是"></el-option>
                            <el-option label="否" value="否"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="公司税号">
                        <el-input v-model="submitForm.companyTaxNo" class="w100"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="开户银行">
                        <el-input v-model="submitForm.depositBank" class="w100"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="银行账号">
                        <el-input v-model="submitForm.bankAccount" class="w100"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="最近成交日期">
                        <el-date-picker v-model="submitForm.latestTransactionDate" type="date" value-format="yyyy-MM-dd" placeholder="选择日期" class="w100"></el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="银行地址">
                        <el-input v-model="submitForm.bankAddress" class="w100"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="开发日期">
                        <el-date-picker v-model="submitForm.developmentDate" type="date" value-format="yyyy-MM-dd" placeholder="选择日期" class="w100"></el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div class="title">
            <span style="color: #ff2d2d">*</span>
            <span>联系人信息</span>
        </div>
        <el-table :data="contactList" border :header-cell-style="{'background-color':'#F3F3F3','color':'#3A3A3A'}">
            <el-table-column label="联系人姓名">
                <template slot-scope="scope">
                    <div style="display: flex;">
                        <span style="color: #ff2d2d">*</span>
                        <el-input v-model="scope.row.contactsName" size="small"></el-input>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="联系人部门">
                <template slot-scope="scope">
                    <el-input v-model="scope.row.contactsDepartment" size="small"></el-input>
                </template>
            </el-table-column>
            <el-table-column label="性别">
                <template slot-scope="scope">
                    <el-select v-model="scope.row.contactsSex" size="small">
                            <el-option label="男" value="男"></el-option>
                            <el-option label="女" value="女"></el-option>
                        </el-select>
                </template>
            </el-table-column>
            <el-table-column label="职务">
                <template slot-scope="scope">
                    <el-input v-model="scope.row.contactsPost" size="small"></el-input>
                </template>
            </el-table-column>
            <el-table-column label="电话">
                <template slot-scope="scope">
                    <el-input v-model="scope.row.contactsTel" size="small"></el-input>
                </template>
            </el-table-column>
            <el-table-column label="手机号">
                <template slot-scope="scope">
                    <el-input v-model="scope.row.contactsPhone" size="small"></el-input>
                </template>
            </el-table-column>
            <el-table-column label="传真">
                <template slot-scope="scope">
                    <el-input v-model="scope.row.contactsFax" size="small"></el-input>
                </template>
            </el-table-column>
            <el-table-column label="邮箱">
                <template slot-scope="scope">
                    <el-input v-model="scope.row.contactsEmail" size="small"></el-input>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="60">
                <template slot-scope="scope">
                    <div class="handleDelBtn" @click="delContactItem(scope.$index)">删除</div>
                </template>
            </el-table-column>
        </el-table>
        <div class="bottomAddBtn" @click="addContactItem">
            <img src="@/assets/icon/plus.png" alt="" />新增联系人
        </div>

        <div class="submitBtn">
            <el-button type="primary" @click="onSubmit" :loading="btnLoading" :disabled="isSubmit" size="small">
                立即提交
            </el-button>
        </div>
    </div>
</template>

<script>
import dayjs from "dayjs";
export default {
    props: {
        handleSelect: {
            type: Function,
            default: null,
        },
    },
    data() {
        return {
            theme: "新增供应商",
            btnLoading: false,
            isSubmit: false,
            submitForm: {
                "key": "",
                "manufacturerNo": "",
                "manufacturerName": "",
                "companyHomepage": "",
                "city": "",
                "province": "",
                "placeOrigin": "",
                "postalCode": "",
                "address": "",
                "creditRating": "",
                "manufacturerSource": "",
                "businessScope": "",
                "settlementMethod": "",
                "cooperationLevel": "",
                "isInvoicing": "",
                "companyTaxNo": "",
                "depositBank": "",
                "bankAccount": "",
                "bankAddress": "",
                "latestTransactionDate": dayjs().format('YYYY-MM-DD'),
                "developmentDate": dayjs().format('YYYY-MM-DD'),
                "contacts": [],
            },
            contactList: [
                {
                    "contactsName": "",
                    "contactsDepartment": "",
                    "contactsSex": "",
                    "contactsPost": "",
                    "contactsTel": "",
                    "contactsPhone": "",
                    "contactsFax": "",
                    "contactsEmail": "",
                }
            ],
        };
    },
    methods: {
        addContactItem(){
            this.contactList.push({
                "contactsName": "",
                "contactsDepartment": "",
                "contactsSex": "",
                "contactsPost": "",
                "contactsTel": "",
                "contactsPhone": "",
                "contactsFax": "",
                "contactsEmail": "",
            })
        },
        delContactItem(index){
            this.contactList.splice(index,1);
        },


        onSubmit(){
            let {manufacturerNo,manufacturerName} = this.submitForm;
            if(!manufacturerName){ this.$message({ message: '请填写厂商名称', type: 'warning'}); return false; }
            if(!manufacturerNo){ this.$message({ message: '请填写厂商编号', type: 'warning'}); return false; }
            this.contactList = this.contactList.filter(ele => ele.contactsName != "");
            if(this.contactList.length == 0){ 
                this.$message({ message: '请填写至少一个联系人信息', type: 'warning'}); 
                return false; 
            }
            this.submitForm.contacts = this.contactList;
            // console.log(this.submitForm);
            //保存供应商
            this.btnLoading = true;
            this.$axios.post(`${this.$orderUrl}/Purchase/V2/SavePurchaser`, this.submitForm).then((res) => {
                const { isSuccess, data } = res;
                this.btnLoading = false;
                if (isSuccess) {
                    this.$message({ message: '提交成功', type: 'success'});
                    this.isSubmit = true;
                    this.handleSelect(`/purchase/purchaserDetails?key=${data}`);
                    this.$router.push(`/purchase/purchaserDetails?key=${data}`);
                }
            });
        },

        getDetail(key){
            this.$axios.get(`${this.$orderUrl}/Purchase/V2/GetPurchaserDetail?key=${key}`).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    this.submitForm = data;
                    this.contactList = data.contacts;
                    //复制供应商
                    let optype = this.$route.query.optype;
                    if(optype == 'copy'){
                        this.theme = "复制供应商";
                        document.title = "复制供应商";
                        this.submitForm.key = "";
                    }
                }
            });
        },

        generateNumber(){
            if(this.submitForm.manufacturerName){
                this.$axios.get(`${this.$orderUrl}/Purchase/GetNo?name=${this.submitForm.manufacturerName}`).then((res) => {
                    const { isSuccess , data } = res;
                    if (isSuccess) {
                        this.submitForm.manufacturerNo = data;
                    }
                });
            }
        },

    },
    mounted() {
        let key = this.$route.query.key;
        if(key){
            this.theme = "编辑供应商";
            document.title = "编辑供应商";
            this.getDetail(key);
        }
    },
};
</script>

<style lang="scss" scoped>
.newBox{
    background: #FFFFFF;
    padding: 10px 20px;
    .header{
        height: 32px;
        line-height: 32px;
        font-size: 20px;
        font-weight: bold;
        color: #000;
        margin-bottom: 10px;
    }
    .title{
        height: 20px;
        line-height: 20px;
        font-size: 16px;
        font-weight: bold;
        color: #3A3A3A;
        margin-bottom: 10px;
        border-left: 3px solid var(--themeColor);
        padding-left: 5px;
    }
    .w100{
        width: 100%;
        height: 32px;
    }
    .submitBtn{
        display: flex;
        margin-top: 20px;
        justify-content: flex-end;
    }
    .bottomAddBtn{
        cursor: pointer;
        height: 48px;
        line-height: 48px;
        text-align: center;
        font-size: 16px;
        color: #7C90A5;
        border: 1px solid #EBEEF5;
        border-top: none;
        img{
            vertical-align: middle;
            padding-right: 10px;
        }
    }
}
</style>