<template>
    <div class="newBox">
        <div class="title">新建工单</div>
        <div class="content">
            <el-form :model="submitObj" label-width="100px" size="small">
                <el-form-item label="工单标题：">
                    <el-input v-model="submitObj.title" style="width:215px;" placeholder="工单标题"></el-input>
                </el-form-item>
                <el-form-item label="反馈类型：">
                    <el-select v-model="submitObj.type" placeholder="请选择">
                        <el-option label="产品Bug" value="产品Bug"></el-option>
                        <el-option label="使用帮助" value="使用帮助"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="问题描述：">
                    <el-input type="textarea" v-model="submitObj.problemDescription" :rows="6" show-word-limit maxlength="500" placeholder="问题描述"></el-input>
                </el-form-item>
                <el-form-item label="上传图片：">
                    <div class="imgItem" v-for="(item,index) in imgSrcList" :key="index">
                        <el-image style="width:100%;height:100%;" :src="item" :preview-src-list="imgSrcList"></el-image>
                        <i class="el-icon-circle-close"  @click="handleRemove(index)"></i>
                    </div>
                    <div class="pastebox" v-on:paste="handlePaste">
                        <el-upload drag :action="uploadUrl" :headers="headers" :data="uploadData" :show-file-list="false"
                        :before-upload="beforeUpload" :on-success="uploadSuccess">
                            <i class="el-icon-upload"></i>
                            <div>将图片拖到此处，或<span>点击上传</span></div>
                            <div>或将图片按<span>Ctrl+V</span>粘贴至此处</div>
                        </el-upload>
                    </div>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" style="float:right;" @click="onSubmit" :loading="btnLoading">立即创建</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        handleSelect: {
            type: Function,
            default: null,
        },
    },
    data() {
        return {
            btnLoading: false,
            submitObj: {
                title: "",
                type: "产品Bug",
                problemDescription: "",
                annexs: [],
            },
            //上传附件
            uploadUrl: `${this.$diskUrl}/File/Upload`,
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('token'),
            },
            uploadData: {
                folderKey: "0",
                remarkName: "",
                permission: '我的'
            },
            imgSrcList: [],
        };
    },
    methods: {
        // 粘贴上传图片
        handlePaste(event) {
            const items = (event.clipboardData || window.clipboardData).items;
            let file = null;
            if (!items || items.length === 0) { this.$message.error("当前浏览器不支持本地"); return; }
            for (let i = 0; i < items.length; i++) {
                if (items[i].type.indexOf("image") !== -1) {
                    file = items[i].getAsFile();
                    break;
                }
            }
            if (!file) { this.$message.error("粘贴内容非图片"); return; }
            if(this.imgSrcList.length >= 3){ this.$message.warning("最多上传三张图片"); return; }
            this.uploadPlans(file);
        },
        uploadPlans(file) {
            const from = new FormData();
            from.append("file", file);
            from.append("permission", "我的");
            from.append("remarkName", "");
            from.append("folderKey", "0");
            this.$axios.post(this.uploadUrl, from).then((res) => {
                const {isSuccess,data} = res;
                if(isSuccess){
                    this.$message({message: "上传成功",type: 'success'});
                    this.submitObj.annexs.push({
                        fileName: data.fileName,
                        fileUrl: data.fileUrl,
                        fileSize: data.fileSize,
                    })
                    this.imgSrcList.push(data.fileUrl)
                }
            });
        },
        // 点击上传图片
        beforeUpload(file) {
            var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
            const isImg = testmsg === "png" || testmsg === "jpg" || testmsg === "jpeg";
            const isLt10M = file.size / 1024 / 1024 < 10;
            if(this.imgSrcList.length >= 3){ 
                this.$message.warning("最多上传三张图片"); 
            }
            if (!isImg) {
                this.$message({ message: "上传文件只能是图片!", type: "warning"});
            }
            if (!isLt10M) {
                this.$message({ message: "上传文件大小不能超过10MB!", type: "warning"});
            }
            return isImg && isLt10M && this.imgSrcList.length < 3;
        },
        uploadSuccess(response){
            const {isSuccess,data} = response;
            if(isSuccess){
                this.$message({message: "上传成功",type: 'success'});
                this.submitObj.annexs.push({
                    fileName: data.fileName,
                    fileUrl: data.fileUrl,
                    fileSize: data.fileSize,
                })
                this.imgSrcList.push(data.fileUrl)
            }
        },
        handleRemove(index){
            this.submitObj.annexs.splice(index,1)
            this.imgSrcList.splice(index,1)
        },

        //工单提交
        onSubmit() {
            const {title,type,problemDescription} = this.submitObj;
            if(!title){ this.$message({ message: "请填写工单标题" , type: 'warning'}); return false; }
            if(!type){ this.$message({ message: "请选择反馈类型" , type: 'warning'}); return false; }
            if(!problemDescription){ this.$message({ message: "请填写问题描述" , type: 'warning'}); return false; }
            //请求接口
            this.btnLoading = true;
            this.$axios.post(`${this.$customerUrl}/WorkOrder/AddWorkOrder`, this.submitObj).then((res) => {
                const { isSuccess } = res;
                this.btnLoading = false;
                if (isSuccess) {
                    this.$message({message: "工单创建成功",type: 'success'});
                    //清空数据
                    this.submitObj = {
                        title: "",
                        type: "产品Bug",
                        problemDescription: "",
                        annexs: [],
                    };
                    this.imgSrcList = [];
                    //跳转到工单列表
                    this.handleSelect(`/workOrder/workOrderList`);
                    this.$router.push(`/workOrder/workOrderList`);
                }
            });
        },
    },
    mounted() {

    },
};
</script>

<style lang="scss" scoped>
.newBox {
    background: rgba($color: #ffffff, $alpha: 0.7);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    .title {
        padding: 10px 20px;
        font-size: 20px;
        font-weight: bold;
        color: #383838;
        border-bottom: 1px solid #E6E7EB;
    }
    .content {
        padding: 20px;
    }
    .imgItem{
        display: inline-block;
        width: 360px;
        height: 180px;
        margin-right: 20px;
        position: relative;
        .el-icon-circle-close{
            position: absolute;
            z-index: 10;
            right: 5px;
            top: 5px;
            cursor: pointer;
            font-size: 20px;
            color: #6a6a6a;
            display: none;
        }
        &:hover .el-icon-circle-close{
            display: inline-block;
        }
    }
    .pastebox{
        display: inline-block;
        span{
            padding:0 10px;
            color:var(--themeColor);
        }
    }
}
</style>