<template>
    <div v-loading="loading">
        <!-- 俄罗斯 -->
        <el-descriptions :column="2" border :labelStyle="{width:'15%'}" :contentStyle="{width:'35%'}">
            <el-descriptions-item label="公司名">{{businessInfo.company}}</el-descriptions-item>
            <el-descriptions-item label="公司名全称">{{businessInfo.companyFullName}}</el-descriptions-item>
            <el-descriptions-item label="公司名英文">{{businessInfo.companyEn}}</el-descriptions-item>
            <el-descriptions-item label="公司英文全称">{{businessInfo.companyFullNameEn}}</el-descriptions-item>
            <el-descriptions-item label="注册日期">{{businessInfo.registrationDate.substring(0,10)}}</el-descriptions-item>
            <el-descriptions-item label="公司名标准">{{businessInfo.companyStd}}</el-descriptions-item>
            <el-descriptions-item label="税号">{{businessInfo.importerID}}</el-descriptions-item>
            <el-descriptions-item label="俄类比NACE代码">
                <p class="wordWrap">
                    {{businessInfo.naceCode}}
                </p>
            </el-descriptions-item>
            <el-descriptions-item label="据FTS统计局的状态日期">{{businessInfo.statusAccordFtsDate.substring(0,10)}}</el-descriptions-item>
            <el-descriptions-item label="据FTS现状">{{businessInfo.statusAccordFts}}</el-descriptions-item>
            <el-descriptions-item label="法人终止日期">{{businessInfo.actualisationDate}}</el-descriptions-item>
            <el-descriptions-item label="企业永久账户代码">{{businessInfo.bin}}</el-descriptions-item>
            <el-descriptions-item label="财务结算企业代码">{{businessInfo.financialSettlementId}}</el-descriptions-item>
            <el-descriptions-item label="公司注册形式版本">{{businessInfo.okopfName}}</el-descriptions-item>
            <el-descriptions-item label="公司成立形式代码">{{businessInfo.okopfCode}}</el-descriptions-item>
            <el-descriptions-item label="地址">{{businessInfo.address}}</el-descriptions-item>
            <el-descriptions-item label="行政区号">{{businessInfo.index}}</el-descriptions-item>
            <el-descriptions-item label="地区名称">{{businessInfo.indexAndAddress}}</el-descriptions-item>
            <el-descriptions-item label="法团名称">{{businessInfo.subjectRussianFederation}}</el-descriptions-item>
            <el-descriptions-item label="邮递区号">{{businessInfo.postalCode}}</el-descriptions-item>
            <el-descriptions-item label="姓氏">{{businessInfo.surname}}</el-descriptions-item>
            <el-descriptions-item label="名">{{businessInfo.name}}</el-descriptions-item>
            <el-descriptions-item label="中间名">{{businessInfo.middleName}}</el-descriptions-item>
            <el-descriptions-item label="联系人">{{businessInfo.contact}}</el-descriptions-item>
            <el-descriptions-item label="职位名称">{{businessInfo.headPosition}}</el-descriptions-item>
            <el-descriptions-item label="电话">{{businessInfo.phone}}</el-descriptions-item>

            <!-- <el-descriptions-item label="2018年收入千卢布">{{businessInfo.revenueThousandRubles2018}}</el-descriptions-item>
            <el-descriptions-item label="2017年收入千卢布">{{businessInfo.revenueThousandRubles2017}}</el-descriptions-item>
            <el-descriptions-item label="2016年收入千卢布">{{businessInfo.revenueThousandRubles2016}}</el-descriptions-item>
            <el-descriptions-item label="2015年收入千卢布">{{businessInfo.revenueThousandRubles2015}}</el-descriptions-item>
            <el-descriptions-item label="2014年收入千卢布">{{businessInfo.revenueThousandRubles2014}}</el-descriptions-item>
            <el-descriptions-item label="2013年收入千卢布">{{businessInfo.revenueThousandRubles2013}}</el-descriptions-item>
            <el-descriptions-item label="2012年收入千卢布">{{businessInfo.revenueThousandRubles2012}}</el-descriptions-item>
            <el-descriptions-item label="2011年收入千卢布">{{businessInfo.revenueThousandRubles2011}}</el-descriptions-item>
            <el-descriptions-item label="2018年净利润（LOS）千卢布">{{businessInfo.netProfitlosThousandRubles2018}}</el-descriptions-item>
            <el-descriptions-item label="2017年净利润（LOS）千卢布">{{businessInfo.netProfitlosThousandRubles2017}}</el-descriptions-item>
            <el-descriptions-item label="2016年净利润（LOS）千卢布">{{businessInfo.netProfitlosThousandRubles2016}}</el-descriptions-item>
            <el-descriptions-item label="2015年净利润（LOS）千卢布">{{businessInfo.netProfitlosThousandRubles2015}}</el-descriptions-item>
            <el-descriptions-item label="2014年净利润（LOS）千卢布">{{businessInfo.netProfitlosThousandRubles2014}}</el-descriptions-item>
            <el-descriptions-item label="2013年净利润（LOS）千卢布">{{businessInfo.netProfitlosThousandRubles2013}}</el-descriptions-item>
            <el-descriptions-item label="2012年净利润（LOS）千卢布">{{businessInfo.netProfitlosThousandRubles2012}}</el-descriptions-item>
            <el-descriptions-item label="2011年净利润（LOS）千卢布">{{businessInfo.netProfitlosThousandRubles2011}}</el-descriptions-item> -->
        </el-descriptions>
        <table>
            <tr>
                <td class="bg"></td>
                <td class="bg">收入千卢布</td>
                <td class="bg">净利润（LOS）千卢布</td>
            </tr>
            <tr>
                <td class="bg">2018年</td>
                <td>{{businessInfo.revenueThousandRubles2018}}</td>
                <td>{{businessInfo.netProfitlosThousandRubles2018}}</td>
            </tr>
            <tr>
                <td class="bg">2017年</td>
                <td>{{businessInfo.revenueThousandRubles2017}}</td>
                <td>{{businessInfo.netProfitlosThousandRubles2017}}</td>
            </tr>
            <tr>
                <td class="bg">2016年</td>
                <td>{{businessInfo.revenueThousandRubles2016}}</td>
                <td>{{businessInfo.netProfitlosThousandRubles2016}}</td>
            </tr>
            <tr>
                <td class="bg">2015年</td>
                <td>{{businessInfo.revenueThousandRubles2015}}</td>
                <td>{{businessInfo.netProfitlosThousandRubles2015}}</td>
            </tr>
            <tr>
                <td class="bg">2014年</td>
                <td>{{businessInfo.revenueThousandRubles2014}}</td>
                <td>{{businessInfo.netProfitlosThousandRubles2014}}</td>
            </tr>
            <tr>
                <td class="bg">2013年</td>
                <td>{{businessInfo.revenueThousandRubles2013}}</td>
                <td>{{businessInfo.netProfitlosThousandRubles2013}}</td>
            </tr>
            <tr>
                <td class="bg">2012年</td>
                <td>{{businessInfo.revenueThousandRubles2012}}</td>
                <td>{{businessInfo.netProfitlosThousandRubles2012}}</td>
            </tr>
            <tr>
                <td class="bg">2011年</td>
                <td>{{businessInfo.revenueThousandRubles2011}}</td>
                <td>{{businessInfo.netProfitlosThousandRubles2011}}</td>
            </tr>
        </table>
    </div>
</template>

<script>
export default {
    data() {
        return {
            businessInfo: {},
            loading: false,
        };
    },
    methods: {
        getInfo(){
            let companyName = encodeURIComponent(this.$route.query.companyName);
            let country = this.$route.query.country;
            this.loading = true;
            this.$axios.get(`${this.$clueUrl}/BuyerLeads/GetBusinessInformation?country=${country}&company=${companyName}`).then((res) => {
                const { isSuccess, data } = res;
                this.loading = false;
                if (isSuccess) {
                    this.businessInfo = data;
                }
            });
        },
    },
    mounted() {
        this.getInfo();
    },
};
</script>

<style lang="scss" scoped>
.wordWrap{
    word-break: break-all;  //强制断行
}
table{
    width: 100%;
    margin-top: 20px;
    border-collapse:collapse;
    td{
        width: 33%;
        height: 40px;
        line-height: 40px;
        text-align: center;
        border: 1px solid #EBEEF5;
        color: #6a6a6a;
    }
    .bg{
        background-color: #fafafa;
    }
}
</style>