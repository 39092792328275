<template>
    <div>
        <div class="itemBg" v-for="(item,index) in pickerList" :key="index">
            <img :src="item.bgImg" style="width:100%;height:100%;" alt="" />
            <div class="masklayer" @click="onSaveTheme(item.color)">
                <div class="apply" :style="{'background': item.color}" @click="changeHanlder(item.color)">应  用</div>
            </div>
        </div>

        <!-- <el-color-picker v-model="theme"
        :predefine="['#2E4756', '#3152AF', '#E78438', '#33A85E', '#141414','#606266','#E1902B']"
        class="theme-picker" popper-class="theme-picker-dropdown" @change="changeHanlder" /> -->
    </div>
</template>
  
<script>


// import { mapGetters, mapMutations } from 'vuex';
const version = require('element-ui/package.json').version // element-ui version from node_modules
const ORIGINAL_THEME = '#409EFF' // default color

export default {
    data() {
        return {
            chalk: '', // content of theme-chalk css
            theme: localStorage.getItem('theme') ? localStorage.getItem('theme') : '#E1902B',
            pickerList: [
                {color: "#3E3E3E", bgImg: require("@/assets/themeBg/bg0.png")},
                {color: "#E1902B", bgImg: require("@/assets/themeBg/bg1.png")},
                {color: "#2E4756", bgImg: require("@/assets/themeBg/bg2.png")},
                {color: "#606266", bgImg: require("@/assets/themeBg/bg3.png")},
            ]
        }
    },
    watch: {
        theme: {
            handler: function (val) {
                // console.log(val, '监听');
                this.changeHanlder(val);
                this.themeMethod(val);
            },
            immediate: true
        },

    },

    methods: {
        // 保存主题色
        onSaveTheme(value){
            this.$axios.get(`${this.$customerUrl}/v1/Config/SetTheme`,{
                params:{ theme: value }
            }).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    this.theme = value;
                    // this.$router.go(0);
                }
            });
        },

        changeHanlder(value) {

            this.theme = value;
            localStorage.setItem('theme', value);
            document.getElementsByTagName('body')[0].style.setProperty('--themeColor', value);
            let lightColor = "";
            if(value == "#2E4756"){ lightColor = "#4B6A7D"; }
            if(value == "#3152AF"){ lightColor = "#5F7AC7"; }
            if(value == "#E78438"){ lightColor = "#E4AD82"; }
            if(value == "#33A85E"){ lightColor = "#75C794"; }
            if(value == "#141414"){ lightColor = "#434343"; }
            if(value == "#606266"){ lightColor = "#909399"; }
            if(value == "#E1902B"){ lightColor = "#F0D9C9"; } //无色背景橙色主题
            if(value == "#3E3E3E"){ lightColor = "#EEEEEE"; } //无色背景黑色主题
            document.getElementsByTagName('body')[0].style.setProperty('--lightColor', lightColor);
        },
        async themeMethod(val) {
            const oldVal = this.chalk ? this.theme : ORIGINAL_THEME
            if (typeof val !== 'string') return
            const themeCluster = this.getThemeCluster(val.replace('#', ''))
            // console.log(themeCluster, 'kkkkk');
            const originalCluster = this.getThemeCluster(oldVal.replace('#', ''))

            // const $message = this.$message({
            //     message: '  Compiling the theme',
            //     customClass: 'theme-message',
            //     type: 'success',
            //     duration: 0,
            //     iconClass: 'el-icon-loading'
            // })

            const getHandler = (variable, id) => {
                // console.log(variable, 'variable');
                return () => {
                    const originalCluster = this.getThemeCluster(ORIGINAL_THEME.replace('#', ''))
                    const newStyle = this.updateStyle(this[variable], originalCluster, themeCluster)

                    let styleTag = document.getElementById(id)
                    if (!styleTag) {
                        styleTag = document.createElement('style')
                        styleTag.setAttribute('id', id)
                        document.head.appendChild(styleTag)
                    }
                    styleTag.innerText = newStyle
                }
            }

            if (!this.chalk) {
                const url = `https://unpkg.zhimg.com/element-ui@${version}/lib/theme-chalk/index.css`
                await this.getCSSString(url, 'chalk')
            }

            const chalkHandler = getHandler('chalk', 'chalk-style')

            chalkHandler()

            const styles = [].slice.call(document.querySelectorAll('style'))
                .filter(style => {
                    const text = style.innerText
                    return new RegExp(oldVal, 'i').test(text) && !/Chalk Variables/.test(text)
                })
            styles.forEach(style => {
                const { innerText } = style
                if (typeof innerText !== 'string') return
                style.innerText = this.updateStyle(innerText, originalCluster, themeCluster)
            })

            this.$emit('change', val)

            // $message.close()
        },
        updateStyle(style, oldCluster, newCluster) {
            let newStyle = style
            oldCluster.forEach((color, index) => {
                newStyle = newStyle.replace(new RegExp(color, 'ig'), newCluster[index])
            })
            return newStyle
        },

        getCSSString(url, variable) {
            return new Promise(resolve => {
                const xhr = new XMLHttpRequest()
                xhr.onreadystatechange = () => {
                    if (xhr.readyState === 4 && xhr.status === 200) {
                        this[variable] = xhr.responseText.replace(/@font-face{[^}]+}/, '')
                        resolve()
                    }
                }
                xhr.open('GET', url)
                xhr.send()
            })
        },

        getThemeCluster(theme) {
            const tintColor = (color, tint) => {
                let red = parseInt(color.slice(0, 2), 16)
                let green = parseInt(color.slice(2, 4), 16)
                let blue = parseInt(color.slice(4, 6), 16)

                if (tint === 0) { // when primary color is in its rgb space
                    return [red, green, blue].join(',')
                } else {
                    red += Math.round(tint * (255 - red))
                    green += Math.round(tint * (255 - green))
                    blue += Math.round(tint * (255 - blue))

                    red = red.toString(16)
                    green = green.toString(16)
                    blue = blue.toString(16)

                    return `#${red}${green}${blue}`
                }
            }

            const shadeColor = (color, shade) => {
                let red = parseInt(color.slice(0, 2), 16)
                let green = parseInt(color.slice(2, 4), 16)
                let blue = parseInt(color.slice(4, 6), 16)

                red = Math.round((1 - shade) * red)
                green = Math.round((1 - shade) * green)
                blue = Math.round((1 - shade) * blue)

                red = red.toString(16)
                green = green.toString(16)
                blue = blue.toString(16)

                return `#${red}${green}${blue}`
            }

            const clusters = [theme]
            for (let i = 0; i <= 9; i++) {
                clusters.push(tintColor(theme, Number((i / 10).toFixed(2))))
            }
            clusters.push(shadeColor(theme, 0.1))
            return clusters
        }
    }
}
</script>
  
<style>
.theme-message,
.theme-picker-dropdown {
    z-index: 99999 !important;
}

.theme-picker .el-color-picker__trigger {
    height: 26px !important;
    width: 26px !important;
    padding: 2px;
}

.theme-picker-dropdown .el-color-dropdown__link-btn {
    display: none;
}
.itemBg{
    width: 400px;
    height: 200px;
    position: relative;
    cursor: pointer;
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 20px;
}
.masklayer{
    display: none;
    width: 400px;
    height: 200px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    background-color: rgba(0,0,0,0.2)
}
.itemBg:hover .masklayer{
    display: inline-block;
}
.apply{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 120px;
    height: 36px;
    line-height: 36px;
    border-radius: 18px;
    text-align: center;
    color: #fff;
}
</style>
  