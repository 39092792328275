<template>
    <div>
        <InterfaceName :interfaceName="interfaceName" />
        <InterfaceSpe :requestUrl="requestUrl" :requestMethod="requestMethod" />
        <InputParam :inputTableData="inputTableData" />
        <ReturnParam :returnObj="returnObj" />   
    </div>
</template>

<script>
import InterfaceName from "../Acomponents/InterfaceName.vue";
import InterfaceSpe from "../Acomponents/InterfaceSpe.vue";
import InputParam from "../Acomponents/InputParam.vue";
import ReturnParam from "../Acomponents/ReturnParam.vue";

export default {
    components: { InterfaceName, InterfaceSpe, InputParam, ReturnParam },
    data() {
        return {
            interfaceName: "保存产品分组",
            requestUrl: "https://crm.qixinerp.com/open/Product/Group/Save",
            requestMethod: "POST",
            inputTableData: [],
            returnObj: {},
        };
    },
    methods: {
        
    },
    mounted() {
        this.inputTableData = [
            {paramName: "key", valueType: "string", explain: "唯一key", required: true},
            {paramName: "groupName", valueType: "string", explain: "分组名称", required: true},
            {paramName: "groupDesc", valueType: "string", explain: "分组描述", required: false},
            {paramName: "parentId", valueType: "integer", explain: "父级Id", required: true},
        ]
        this.returnObj = {
            "isSuccess": true,
            "errorMessage": null,
            "data": true,
        }
    },
};
</script>

<style lang="scss" scoped>

</style>