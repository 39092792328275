<template>
    <div>
        <div class="searchBox">
            <el-input class="searchItem" v-model="filterParam.companyName" placeholder="公司" size="small" clearable></el-input>
            <el-input class="searchItem" v-model="filterParam.position" placeholder="职位" size="small" clearable></el-input>
            <el-input class="searchItem" v-model="filterParam.name" placeholder="姓名" size="small" clearable></el-input>
            <el-input class="searchItem" v-model="filterParam.tel" placeholder="手机号" size="small" clearable></el-input>
            <el-input class="searchItem" v-model="filterParam.email" placeholder="邮箱" size="small" clearable></el-input>
            <el-input class="searchItem" v-model="filterParam.webSite" placeholder="网址" size="small" clearable></el-input>
            <el-input class="searchItem" v-model="filterParam.country" placeholder="国家" size="small" clearable></el-input>
            <el-button type="primary" size="small" plain @click="toSearch">搜 索</el-button>
        </div>
        <div class="operation">
            <div class="leftOption">
                <el-button size="small" type="primary" plain @click="toMassMuch(1)" :disabled="selectionList.length == 0">邮件营销</el-button>
                <el-button size="small" type="primary" plain @click="toMassMuch(2)" :disabled="selectionList.length == 0">WhatsApp群发</el-button>
                <el-button size="small" type="primary" plain @click="toWriteMail" :disabled="selectionList.length == 0" icon="el-icon-s-promotion">写邮件</el-button>
                <el-button size="small" type="info" plain @click="batchRemove" :disabled="selectionList.length == 0">批量移出</el-button>
                <span style="padding: 0 15px;"></span>
                <!-- 筛选：有邮箱/有手机号 -->
                <el-checkbox v-model="filterParam.isEmail" @change="toSearch">有邮箱</el-checkbox>
                <el-checkbox v-model="filterParam.isPhone" @change="toSearch">有手机号</el-checkbox>
            </div>
            <div class="rightOption">
                <el-button size="small" type="primary" plain @click="contactExport" :loading="exportLoading">联系人导出</el-button>
            </div>
        </div>
        <div class="tableBox">
            <el-table v-loading="tableLoading" :data="contactList" :header-cell-style="{'background-color':'#F2F2F2','color':'#3A3A3A'}"
                @selection-change="selectionChange" :row-key="(row) => {return row.key}">
                <el-table-column type="selection" width="45" :reserve-selection="true"></el-table-column>
                <el-table-column label="姓名">
                    <template slot-scope="scope">
                        <div style="display: flex;">
                            <el-link type="primary" @click="toContactDetail(scope.row)">{{scope.row.name || "——"}}</el-link>
                            <el-tooltip class="item" effect="dark" content="Linkedin" placement="top">
                                <div class="inIcon" v-if="scope.row.linkedin" @click="jumpWeb(scope.row.linkedin)">
                                    <svg t="1678958153071" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3034" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="20">
                                        <path d="M852 0 172 0C77.408 0 0 77.408 0 172l0 680c0 94.592 77.408 172 172 172l680 0c94.592 0 172-77.408 172-172L1024 172C1024 77.408 946.592 0 852 0zM384 832l-128 0L256 384l128 0L384 832zM320 320c-35.36 0-64-28.64-64-64s28.64-64 64-64 64 28.64 64 64S355.36 320 320 320zM832 832l-128 0L704 576c0-35.36-28.64-64-64-64s-64 28.64-64 64l0 256-128 0L448 384l128 0 0 79.456C602.4 427.2 642.752 384 688 384c79.52 0 144 71.648 144 160L832 832z" fill="var(--themeColor)" p-id="3035"></path>
                                    </svg>
                                </div>
                            </el-tooltip>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="sexStr" label="性别" width="60px"></el-table-column>
                <el-table-column prop="position" label="职位"></el-table-column>
                <el-table-column prop="companyName" label="公司"></el-table-column>
                <el-table-column label="国家">
                    <template slot-scope="scope">
                        <img :src="scope.row.flagUrl" style="margin-right:5px;height:14px;vertical-align: middle;" />
                        <span>{{ scope.row.country }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="email" label="邮箱">
                    <template slot-scope="scope">
                        <EmailValue :value="scope.row.email" :isVerify="scope.row.isVerify" />
                    </template>
                </el-table-column>
                <el-table-column prop="tel" label="手机号"></el-table-column>
            </el-table>
            <el-pagination hide-on-single-page background layout="total,sizes,prev,pager,next" :page-sizes="[10, 20, 40]" :page-size.sync="pageSize" @size-change="pageSizeChange" :total="totalItems" style="float:right;margin:15px 20px;" :current-page.sync="pageIndex" @current-change="pageIndexChange"></el-pagination>
        </div>

        <!-- <el-dialog title="联系人导出" :visible.sync="exportVisible" width="500px">
            <div style="margin-bottom: 16px;">
                <span>导出：</span>
                <el-input-number v-model="exportNumber" :min="1" :max="100" size="small"></el-input-number>
                <span>条</span>
            </div>
            <el-progress v-if="exportLoading" :text-inside="true" :stroke-width="20" :percentage="exportProgress"></el-progress>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="exportVisible = false;">取 消</el-button>
                <el-button size="small" type="primary" @click="contactExport" :loading="exportLoading">导 出</el-button>
            </span>
        </el-dialog> -->

    </div>
</template>

<script>
export default {
    data() {
        return {
            // 过滤字段
            filterParam: {
                "companyName": "",
                "position": "",
                "name": "",
                "tel": "",
                "email": "",
                "webSite": "",
                "country": "",
                "isPhone": false, //是否有手机号
                "isEmail": false, //是否有邮箱
            },
            //列表展示
            tableLoading: false,
            contactList: [],
            totalItems: 0,
            pageIndex: 1,
            pageSize: 10,
            //勾选列表
            selectionList: [],
            //联系人导出
            exportVisible: false,
            exportLoading: false,
            exportProgress: 0,
            exportNumber: 1,
        };
    },
    methods: {
        getList(){
            this.tableLoading = true;
            this.$axios.post(`${this.$clueUrl}/MyClue/GetContactsClue`,{
                ...this.filterParam,//过滤条件
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
            }).then((res) => {
                const { isSuccess , data } = res;
                this.tableLoading = false;
                if (isSuccess) {
                    this.contactList = data.datas.map(item => ({
                        ...item,
                        flagUrl: window.getFlagUrlByCountry ? window.getFlagUrlByCountry(item.country) : '',
                    }))
                    this.totalItems = data.totalItems;
                }
            });
        },
        pageIndexChange(page) {
            this.pageIndex = page;
            this.getList();
        },
        pageSizeChange() {
            this.pageIndex = 1;
            this.getList();
        },
        toSearch(){
            this.pageIndex = 1;
            this.getList();
        },
        
        //新增联系人导出功能
        // toExport(){
        //     this.exportVisible = true;
        //     this.exportProgress = 0;
        //     this.exportNumber = 1;
        // },
        contactExport(){
            const number = this.totalItems > 10000 ? 10000 : this.totalItems;
            this.$confirm(`此操作将导出${number}条联系人, 是否继续?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.exportLoading = true;
                this.$axios({
                    url: `${this.$clueUrl}/MyClue/ExportContacts`,
                    method: 'POST',
                    responseType: 'blob',
                    data: {
                        ...this.filterParam,
                        pageIndex: this.pageIndex,
                        pageSize: this.pageSize,
                    },
                    // onDownloadProgress: (progressEvent) => {
                    //     this.exportProgress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    // },
                }).then(async(response) => {
                    this.exportLoading = false;
                    if(response.type != "application/vnd.ms-excel"){
                        let jsonData = JSON.parse(await response.text());
                        this.$notify({ message: jsonData.errorMessage, position: "top-right",duration: 2000,type: 'warning' })
                        return false;
                    }
                    var blob = new Blob([response]);
                    var link= document.createElement('a');
                    link.download = '联系人线索.xlsx';
                    link.href = URL.createObjectURL(blob);
                    link.click();
                    this.$message({message: "导出成功",type: 'success'});
                }).catch(() => {
                    this.exportLoading = false;
                })
            })
        },

        

        selectionChange(value){
            // console.log(value);
            this.selectionList = value;
        },
        //邮件营销(1) WhatsApp群发(2)
        toMassMuch(type) {
            localStorage.setItem('myContacts',JSON.stringify(this.selectionList));
            if(type == 1){
                let routeData = this.$router.resolve({
                    // path: '/marketing/New',
                    path: '/marketing/MailMarketing',
                    query: { isContactMass: true },
                });
                window.open(routeData.href, "_blank");
            }
            if(type == 2){
                let routeData = this.$router.resolve({
                    // path: '/marketingWA/NewWA',
                    path: '/marketingWA/WAMass',
                    query: { isContactMass: true },
                });
                window.open(routeData.href, "_blank");
            }
        },
        
        //写邮件
        toWriteMail(){
            let receiver = this.selectionList.filter(item => item.email != "").map(item => item.email);
            // console.log(receiver);
            window.open(
                `${process.env.VUE_APP_MAIL_BASE}#/index/editor?type=send&receiver=${receiver.join(',')}`,
                "_blank"
            );
        },

        batchRemove() {
            let keyList = this.selectionList.map(item => item.key);
            // console.log(keyList);
            this.$confirm('此操作将移出勾选的线索, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios.post(`${this.$clueUrl}/MyClue/DeleteContactsEmail`, keyList).then((res) => {
                    const { isSuccess } = res;
                    if (isSuccess) {
                        this.$message({ type: 'success', message: '批量移出成功!' });
                        this.getList();
                    }
                });
            }).catch(() => { });
        },

        //查看联系人详情
        toContactDetail(row){
            let routeData = this.$router.resolve({
                path: "/clue/contactDetails",
                query: { key: row.key },
            });
            window.open(routeData.href, "_blank");
        },
        //打开外链
        jumpWeb(url){
            let jumpUrl = url.indexOf("http") > -1 ? url : "http://" + url;
            let routeData = this.$router.resolve({
                path: "/jumpWeb",
                query: { jumpUrl: jumpUrl },
            });
            window.open(routeData.href, "_blank");
        },
    },
    mounted() {
        this.getList(); //获取联系人线索列表
    },
};
</script>

<style lang="scss" scoped>
.searchBox{
    display: flex;
    .searchItem{
        width: 200px;
        margin-right: 10px;
    }
}
.operation{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}
.tableBox{
    margin-top: 20px;
    overflow: hidden;
}
.inIcon{
    padding-left: 5px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
}
</style>