<template>
    <div style="margin-top:20px;">
        <div class="title">
            <span>附件管理</span>
            <el-upload :action="uploadUrl" :headers="headers" :data="uploadData" :show-file-list="false" :on-success="uploadOk" :on-progress="uploading" :on-error="uploadError">
                <el-button type='primary' icon="el-icon-plus" size="mini" :loading="uploadLoading">上传附件</el-button>
            </el-upload>
        </div>

        <el-table :data="annexList" :header-cell-style="{'background-color':'#F3F3F3',color:'#3A3A3A'}" :row-style="rowStyle">
            <el-table-column type="index" width="50" />
            <el-table-column label="文件名" show-overflow-tooltip>
                <template slot-scope="{ row }">
                    <div style="display: flex; align-items: center">
                        <img :src="row.imageUrl" style="width: 27px; height: 33px; margin-right: 5px" />
                        <span>{{ row.name }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="size" label="文件大小"></el-table-column>
            <el-table-column prop="suffix" label="文件类型"></el-table-column>
            <el-table-column prop="createTime" label="上传日期"></el-table-column>
            <el-table-column prop="userCnName" label="上传人"></el-table-column>
            <el-table-column label="操作">
                <template slot-scope="{ row }">
                    <div style="display: flex; align-items: center">
                        <el-link :underline="false" type="primary" style="margin-right: 20px" @click="download(row)">下载</el-link>
                        <el-link :underline="false" type="danger" @click="delAnnex(row)">删除</el-link>
                    </div>
                </template>
            </el-table-column>
        </el-table>

    </div>
</template>

<script>
const fujianImage = require("@/assets/icon/fujian.png");
export default {
    props:["type","documentKey"],
    data() {
        return {
            annexList: [],
            //上传附件
            uploadUrl: `${this.$diskUrl}/File/Upload`,
            headers: {
                authorization: "Bearer " + localStorage.getItem("token"),
            },
            uploadData: {
                folderKey: "0",
                remarkName: "",
                permission: "我的",
            },
            uploadLoading: false,
        };
    },
    methods: {
        rowStyle({ rowIndex }) {
            if (rowIndex % 2 == 1) {
                return { "background-color": "#F9FAFC" };
            }
        },
        isImageFile(fileName) {
            const dotIndex = fileName.lastIndexOf(".");
            const ext = fileName.slice(dotIndex + 1);
            if (["png", "jpg", "jpeg"].includes(ext)) {
                return true;
            }
            return false;
        },

        //上传附件
        uploading() {
            this.uploadLoading = true;
        },
        uploadOk(response) {
            this.uploadLoading = false;
            const { isSuccess, data } = response;
            if (isSuccess) {
                this.$axios.post(`${this.$customerUrl}/Annex/AddAnnex`, [{
                    documentKey: this.documentKey,
                    type: this.type,
                    name: data.remarkName,
                    url: data.fileUrl,
                    size: data.fileSize,
                }]).then((res) => {
                    const { isSuccess } = res;
                    if (isSuccess) {
                        this.$message({ message: "上传成功", type: "success",});
                        this.getAnnexList();
                    }
                });
            }
        },
        uploadError() {
            this.uploadLoading = false;
            this.$message({ message: "上传失败", type: "warning" });
        },

        //获取附件列表
        getAnnexList() {
            if(!this.documentKey){return false;}
            this.$axios.post(`${this.$customerUrl}/Annex/GetDocumentAnnexs`,{
                documentKey: this.documentKey,
                pageIndex: 1,
                pageSize: 10,
            }).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.annexList = data.datas.map((item) => ({
                        ...item,
                        imageUrl: this.isImageFile(item.name) ? item.url : fujianImage,
                    }));
                }
            });
        },
        //下载附件
        download(row){
            window.open(row.url);
        },
        //删除附件
        delAnnex(row){
            this.$confirm(`是否删除该项附件?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$axios.get(`${this.$customerUrl}/Annex/DeleteAnnex?key=${row.key}`).then((res) => {
                    const { isSuccess } = res;
                    if (isSuccess) {
                        this.$message({ type: 'success', message: '删除成功!'});
                        this.getAnnexList();
                    }
                });
            }).catch(() => {});
        },
    },
    mounted() {
        this.getAnnexList(); //获取附件列表
    },
    watch: {
        documentKey(){
            this.getAnnexList();
        }
    }
};
</script>

<style lang="scss" scoped>
.title{
    height: 20px;
    line-height: 20px;
    font-size: 16px;
    font-weight: bold;
    color: #3A3A3A;
    margin-bottom: 10px;
    border-left: 3px solid var(--themeColor);
    padding-left: 5px;
    display: flex;
    justify-content: space-between;
}
</style>