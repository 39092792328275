<template>
    <div class="networkDisk">
        <div class="header">
            <el-input v-model="searchValue" style="width:300px;margin:'0 20px'" size="small" allowClear>
                <el-button slot="append" icon="el-icon-search" @click="onSearch"></el-button>
            </el-input>
        </div>
        <div class="content">
            <div class="left">
                <el-menu default-active="我的" style="border-right:0">
                    <el-menu-item index="我的" @click="menuClick('我的')">
                        <span slot="title">我的文档</span>
                    </el-menu-item>
                    <el-menu-item index="共享" @click="menuClick('共享')">
                        <span slot="title">共享文档</span>
                    </el-menu-item>
                </el-menu>
            </div>
            <div class="right" v-loading="uploadLoading" element-loading-text="文件上传中" element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(255, 255, 255, 0.8)">
                <div class="top">
                    <el-page-header @back="goBack" title="" style="float:left;height:40px;line-height: 40px;"></el-page-header>
                    <el-breadcrumb separator-class="el-icon-arrow-right" style="float:left;line-height: 40px;">
                        <el-breadcrumb-item v-for="(item,index) in BreadcrumbList" :key="item.key">
                            <span @click="goJump(index)" class="pointer">{{item.name}}</span>
                        </el-breadcrumb-item>
                    </el-breadcrumb>

                    <span style="padding:0 25px;"></span>
                    <el-button v-show="checkList.length>0" @click="batchRemove" size="small">批量删除</el-button>
                    <el-button v-show="checkList.length>0" @click="batchShift" size="small">批量转移</el-button>

                    <el-button v-show="currentMenu=='我的'" class="newFolder" @click="addFolderVisible = true" size="small">新增文件夹</el-button>
                    <el-upload v-show="currentMenu=='我的'" class="uploading" :action="uploadUrl" :headers="headers" :data="uploadData" :show-file-list="false" :on-success="uploadOk" :on-progress="uploading" :on-error="uploadError">
                        <el-button type='primary' size="small">上传文件</el-button>
                    </el-upload>
                </div>

                <!--  文件夹列表与文件列表合并展示 -->
                <div v-loading="FolderLoading || FileLoading">
                    <div v-if="FolderList.length == 0 && FileList.length == 0" class="noData">
                        暂无数据
                    </div>
                    <div class="list">
                        <div class="FolderItem" v-for="item in FolderList" :key="item.key">
                            <div class="folderName">
                                <i class="el-icon-folder-opened icon"></i>
                                <span @click="viewFolder(item)">{{item.folderName}}</span>
                            </div>
                            <div class="option">
                                <el-dropdown size="mini">
                                    <span style=" color: var(--themeColor);cursor: pointer;" @click.stop="() =>{}"><i class="el-icon-more"></i></span>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item @click.native="() => modifyFolder(item)">重命名</el-dropdown-item>
                                        <el-dropdown-item @click.native="() => deleteFolder(item.key)">删除</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </div>
                            <div class="timeExhibition">{{item.timeExhibition}}</div>
                            <div class="userAccount">{{item.userCnName}}</div>
                        </div>
                        <el-checkbox-group v-model="checkList">
                        <div class="FileItem" v-for="item in FileList" :key="item.key">
                            <div class="remarkName">
                                <i class="el-icon-picture-outline icon" v-if="item.fileType=='png' || item.fileType=='jpg'"></i>
                                <i class="el-icon-document icon" v-else></i>
                                <el-checkbox :label="item.key" style="margin-left:10px;">
                                    <span @click="openFileDetail(item)">{{item.remarkName}}</span>
                                </el-checkbox>
                                <!-- <a :href="item.fileUrl" target="_blank"></a> -->
                                <img :src="item.fileUrl" alt="" style="margin-left:20px;width:24px;" />
                            </div>
                            <div class="option" v-show="userInfo.uKey == item.uKey">
                                <el-dropdown size="mini">
                                    <span style=" color: var(--themeColor);cursor: pointer;" @click.stop="() =>{}"><i class="el-icon-more"></i></span>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item v-show="item.permission=='我的'" @click.native="() => sharedFile(item.key)">共享文件</el-dropdown-item>
                                        <el-dropdown-item @click.native="() => transferFile(item.key)">转移文件</el-dropdown-item>
                                        <el-dropdown-item @click.native="() => modifyFile(item)">重命名</el-dropdown-item>
                                        <el-dropdown-item @click.native="() => deleteFile(item.key)">删除</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </div>
                            <div class="timeExhibition">{{item.timeExhibition}}</div>
                            <div class="userAccount">{{item.userCnName}}</div>
                            <div class="fileSize">{{item.fileSize}}</div>
                        </div>
                        </el-checkbox-group>
                    </div>
                </div>
            </div>
        </div>

        <!-- 新增文件夹 -->
        <el-dialog title="新增文件夹" :visible.sync="addFolderVisible" width="500px">
            <el-input v-model="addFolderName" placeholder="请输入文件夹名称" size="small"></el-input>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addFolderCancel" size="small">取 消</el-button>
                <el-button type="primary" @click="addFolderOk" size="small" :loading="loading">确 定</el-button>
            </span>
        </el-dialog>

        <!-- 重命名文件夹 -->
        <el-dialog title="重命名文件夹" :visible.sync="modifyFolderVisible" width="500px">
            <el-input v-model="modifyFolderName" placeholder="请输入文件夹名称" size="small"></el-input>
            <span slot="footer" class="dialog-footer">
                <el-button @click="modifyFolderCancel" size="small">取 消</el-button>
                <el-button type="primary" @click="modifyFolderOk" size="small" :loading="loading">确 定</el-button>
            </span>
        </el-dialog>

        <!-- 重命名文件 -->
        <el-dialog title="重命名文件" :visible.sync="modifyFileVisible" width="500px">
            <el-input v-model="modifyFileName" placeholder="请输入文件名称" size="small"></el-input>
            <span slot="footer" class="dialog-footer">
                <el-button @click="modifyFileCancel" size="small">取 消</el-button>
                <el-button type="primary" @click="modifyFileOk" size="small" :loading="loading">确 定</el-button>
            </span>
        </el-dialog>

        <!-- 展示文件详情 -->
        <el-drawer title="文件详情" :with-header="false" :visible.sync="fileDetailVisible" size="500px">
            <div class="detailInfo">
                <div class="img">
                    <el-image style="width:150px;" :src="fileDetailInfo.fileUrl" :preview-src-list="[fileDetailInfo.fileUrl]" />
                </div>
                <el-row class="infoItem">
                    <el-col :span="6">文件名</el-col>
                    <el-col :span="18">{{fileDetailInfo.remarkName}}</el-col>
                </el-row>
                <el-row class="infoItem">
                    <el-col :span="6">文件大小</el-col>
                    <el-col :span="18">{{fileDetailInfo.fileSize}}</el-col>
                </el-row>
                <el-row class="infoItem">
                    <el-col :span="6">文件类型</el-col>
                    <el-col :span="18">{{fileDetailInfo.fileType}}</el-col>
                </el-row>
                <el-row class="infoItem">
                    <el-col :span="6">网络路径</el-col>
                    <el-col :span="18">
                        <span>{{fileDetailInfo.fileUrl}}</span>
                        <span class="copy" @click="copyUrl">复制路径</span>
                    </el-col>
                </el-row>
                <el-row class="infoItem">
                    <el-col :span="6">存储位置</el-col>
                    <el-col :span="18">{{fileDetailInfo.location}}</el-col>
                </el-row>
                <el-row class="infoItem">
                    <el-col :span="6">上传用户</el-col>
                    <el-col :span="18">{{fileDetailInfo.userCnName}}</el-col>
                </el-row>
                <el-row class="infoItem">
                    <el-col :span="6">上传时间</el-col>
                    <el-col :span="18">{{fileDetailInfo.timeExhibition}}</el-col>
                </el-row>
            </div>
        </el-drawer>

        <!-- 转移文件 -->
        <el-dialog title="转移文件" :visible.sync="transferVisible" width="500px">
            <el-select v-model="clickNodeName" style="width:100%;" placeholder="请选择文件夹" size="small">
                <el-option :value="transferObj.folderKey" style="height: auto">
                    <el-tree :data="folderTreeData" :props="defaultProps" @node-click="chooseTransferGroup"></el-tree>
                </el-option>
            </el-select>
            <span slot="footer" class="dialog-footer">
                <el-button @click="transferVisible = false" size="small">取 消</el-button>
                <el-button type="primary" @click="transferFinish" size="small" :loading="loading">确 定</el-button>
            </span>
        </el-dialog>

        <!-- 批量转移 -->
        <el-dialog title="批量转移文件" :visible.sync="shiftVisible" width="500px">
            <el-select v-model="clickNodeName" style="width:100%;" placeholder="请选择文件夹" size="small">
                <el-option :value="transferObj.folderKey" style="height: auto">
                    <el-tree :data="folderTreeData" :props="defaultProps" @node-click="chooseTransferGroup"></el-tree>
                </el-option>
            </el-select>
            <span slot="footer" class="dialog-footer">
                <el-button @click="shiftVisible = false" size="small">取 消</el-button>
                <el-button type="primary" @click="shiftFinish" size="small" :loading="loading">确 定</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
    export default {
        data(){
            return{
                userInfo: {},
                loading: false,         //确认提交loading
                searchValue: "",        //搜索的文件夹名称
                currentMenu: '我的',    //权限(我的、共享)
                currentParentKey: "0",         //当前所属父文件夹key
                BreadcrumbList:[{name:'home',key:"0"}],    //面包屑列表(用于展示多级文件夹目录)
                FolderList:[],             //文件夹列表
                FolderLoading: false,      
                //新增文件夹
                addFolderVisible: false,
                addFolderName: "",
                //编辑文件夹
                modifyFolderVisible: false,
                modifyFolderKey: "",
                modifyFolderName: "",
                FileList: [],             //文件列表
                FileLoading: false,  
                //编辑文件
                modifyFileVisible: false,
                modifyFileKey: "",
                modifyFileName: "",
                //上传文件
                uploadUrl: `${this.$diskUrl}/File/Upload`,
                headers: {
                    authorization: 'Bearer ' + localStorage.getItem('token'),
                },
                uploadData: {
                    folderKey: "0",
                    remarkName: "",
                    permission: '我的'
                },
                uploadLoading: false,   //上次中的loading
                //展示文件详情
                fileDetailVisible: false,
                fileDetailInfo: {},
                //文件转移到文件夹
                transferVisible: false,
                shiftVisible: false,
                transferObj:{
                    fileKey: "",
                    folderKey: "",
                },
                folderTreeData: [],
                defaultProps: {
                    children: "children",
                    label: "name",
                },
                clickNodeName: "",
                checkList: [],   //批量删除和批量转移
            }
        },
        methods:{
            //搜索
            onSearch(){
                this.getFolderList();
            },
            //切换权限
            menuClick(menuName){
                this.currentMenu = menuName;
                this.currentParentKey = "0";        //初始化当前所属父文件夹key为0
                this.BreadcrumbList = [{name:'home',key:"0"}];
                this.getFolderList();    //查看当前所属父文件夹key的列表
                this.getFileList();    //重新获取文件集合
                //改变上传文件的传入参数
                this.uploadData =  {
                    folderKey: this.currentParentKey,
                    remarkName: "",
                    permission: this.currentMenu
                }
            },
        
            //获取文件夹集合
            getFolderList(){
                const {searchValue,currentMenu,currentParentKey} = this;
                this.FolderLoading = true;
                this.$axios.post(`${this.$diskUrl}/Folder`,{
                    folderName: searchValue,
                    parentKey: currentParentKey,
                    permission: currentMenu,
                }).then(res =>{
                    const {isSuccess,data} = res;
                    this.FolderLoading = false;
                    if(isSuccess){
                        this.FolderList = data;
                    }
                })
            
            },
            //新增文件夹
            addFolderOk(){
                const {currentMenu,addFolderName,currentParentKey} = this;
                if(!addFolderName){
                    this.$message({message: '文件夹名称不能为空',type: 'warning'});
                    return false
                }
                this.loading = true;
                this.$axios.post(`${this.$diskUrl}/Folder/Add`,{
                    folderName: addFolderName,
                    parentKey: currentParentKey,
                    permission: currentMenu
                }).then(res =>{
                    const {isSuccess,errorMessage} = res;
                    this.loading = false;
                    if(isSuccess){
                        this.$message({message: "新增成功",type: 'success'});
                        this.getFolderList();
                        this.addFolderVisible = false;
                        this.addFolderName = "";
                    }else{
                        this.$message({message: errorMessage,type: 'warning'});
                    }
                })
            },
            addFolderCancel(){
                this.addFolderVisible = false;
                this.addFolderName = "";
            },
            //重命名文件夹
            modifyFolder(item){
                this.modifyFolderVisible = true;
                this.modifyFolderKey = item.key;
                this.modifyFolderName = item.folderName;      
            },
            modifyFolderOk(){
                const {currentMenu,modifyFolderKey,modifyFolderName} = this;
                if(!modifyFolderName){
                    this.$message({message: '文件夹名称不能为空',type: 'warning'});
                    return false
                }
                this.loading = true;
                this.$axios.post(`${this.$diskUrl}/Folder/Update`,{
                    folderKey:  modifyFolderKey,
                    folderName: modifyFolderName,
                    permission: currentMenu
                }).then(res =>{
                    const {isSuccess,errorMessage} = res;
                    this.loading = false;
                    if(isSuccess){
                        this.$message({message: "重命名成功",type: 'success'});
                        this.getFolderList();
                        this.modifyFolderVisible = false;
                    }else{
                        this.$message({message: errorMessage,type: 'warning'});
                    }
                })
            },
            modifyFolderCancel(){
                this.modifyFolderVisible = false;
            },
            //删除文件夹
            deleteFolder(folderKey){
                this.$axios.post(`${this.$diskUrl}/Folder/Delete?folderKey=${folderKey}`).then(res =>{
                    const {isSuccess,errorMessage} = res;
                    if(isSuccess){
                        this.$message({message: "删除成功",type: 'success'});
                        this.getFolderList();
                    }else{
                        this.$message({message: errorMessage,type: 'warning'});
                    }
                })
            },

            //查看文件夹，进入到该文件夹的目录
            viewFolder(item){
                this.BreadcrumbList.push({name: item.folderName,key: item.key});     //新增一项
                this.currentParentKey = item.key;       //改变当前所属父文件夹key
                this.getFolderList();                   //查看当前所属父文件夹key的列表
                this.getFileList();                     //重新获取文件集合
                this.uploadData =  {
                    folderKey: this.currentParentKey,
                    remarkName: "",
                    permission: this.currentMenu
                }
            },
            //返回上一级文件夹
            goBack(){
                const BreadcrumbList = this.BreadcrumbList;
                if(BreadcrumbList.length == 1){
                    return false            //最后一项不能返回上一级
                }
                BreadcrumbList.pop();                                                   //删除最后一项
                this.BreadcrumbList = BreadcrumbList;                                  
                this.currentParentKey = BreadcrumbList[BreadcrumbList.length-1].key     //返回后取最后一项的key作为当前所属父文件夹key
                this.getFolderList();                                                   //查看当前所属父文件夹key的列表
                this.getFileList();                                                     //重新获取文件集合
                this.uploadData =  {
                    folderKey: this.currentParentKey,
                    remarkName: "",
                    permission: this.currentMenu
                }
            },
            //跳转指定文件夹
            goJump(index){
                const BreadcrumbList = this.BreadcrumbList;
                if(BreadcrumbList.length == 1){
                    return false            //最后一项不能返回上一级
                }
                BreadcrumbList.splice(index + 1,BreadcrumbList.length);                 //删除指定文件夹后面的项
                this.BreadcrumbList = BreadcrumbList;                                  
                this.currentParentKey = BreadcrumbList[BreadcrumbList.length-1].key     //返回后取最后一项的key作为当前所属父文件夹key
                this.getFolderList();                                                   //查看当前所属父文件夹key的列表
                this.getFileList();                                                     //重新获取文件集合
                this.uploadData =  {
                    folderKey: this.currentParentKey,
                    remarkName: "",
                    permission: this.currentMenu
                }
            },

            //查询文件集合
            getFileList(){
                const {currentMenu,currentParentKey} = this;
                this.FileLoading = true;
                this.$axios.post(`${this.$diskUrl}/File`,{
                    folderKey: currentParentKey,    //所属文件夹Key
                    remarkName: "",                 //文件备注名称
                    fileType: "",                   //文件类型
                    permission: currentMenu,        //权限（我的、公司、共享）
                    location: "",                   //存储位置
                }).then(res =>{
                    const {isSuccess,data} = res;
                    this.FileLoading = false;
                    if(isSuccess){
                        this.FileList = data;
                    }
                })
            },

            //重命名文件
            modifyFile(item){
                this.modifyFileVisible = true;
                this.modifyFileKey = item.key;
                this.modifyFileName = item.remarkName;
            },
            modifyFileOk(){
                const {currentMenu,modifyFileKey,modifyFileName} = this;
                if(!modifyFileName){
                    this.$message({message: '文件名称不能为空',type: 'warning'});
                    return false
                }
                this.loading = true;
                this.$axios.post(`${this.$diskUrl}/File/Modify`,{
                    fileKey:  modifyFileKey,
                    remarkName: modifyFileName,
                    permission: currentMenu,
                }).then(res =>{
                    const {isSuccess,errorMessage} = res;
                    this.loading = false;
                    if(isSuccess){
                        this.$message({message: "重命名成功",type: 'success'});
                        this.getFileList();
                        this.modifyFileVisible = false;
                    }else{
                        this.$message({message: errorMessage,type: 'warning'});
                    }
                })
            },
            modifyFileCancel (){
                this.modifyFileVisible = false;
            },
            //删除文件
            deleteFile(fileKey){
                this.$axios.post(`${this.$diskUrl}/File/Delete?fileKey=${fileKey}`).then(res =>{
                    const {isSuccess,errorMessage} = res;
                    if(isSuccess){
                        this.$message({message: "删除成功",type: 'success'});
                        this.getFileList();
                    }else{
                        this.$message({message: errorMessage,type: 'warning'});
                    }
                })
            },

            //上传文件
            uploading(){
                this.uploadLoading = true; //上传进行中
            },
            uploadOk(response){
                this.uploadLoading = false;
                const {isSuccess,errorMessage} = response;
                if(isSuccess){
                    this.$message({message: "上传文件成功",type: 'success'});
                    this.getFileList();                     //重新获取文件集合
                }else{
                    this.$message({message: errorMessage,type: 'warning'});
                }
            },
            uploadError(){
                this.uploadLoading = false;
                this.$message({message: '上传失败',type: 'warning'});
            },

            //打开文件详情
            openFileDetail(item){
                this.fileDetailVisible = true;
                this.fileDetailInfo = item;
            },
            //复制url路径
            copyUrl(){
                const input = document.createElement('input');
                document.body.appendChild(input);
                input.setAttribute('value', this.fileDetailInfo.fileUrl);
                input.select();
                if (document.execCommand('copy')) {
                    document.execCommand('copy');
                }
                document.body.removeChild(input);
                this.$message({message: '复制成功',type: 'success'});
            },

            //共享文件
            sharedFile(fileKey){
                this.$axios.get(`${this.$diskUrl}/File/ShareFile?fileKey=${fileKey}`).then(res =>{
                    const {isSuccess} = res;
                    if(isSuccess){
                        this.$message({message: "共享成功",type: 'success'});
                        // this.getFileList();
                    }
                })
            },
            //文件转移到文件夹
            transferFile(fileKey){
                this.transferVisible = true;
                this.transferObj.fileKey = fileKey;
                this.transferObj.folderKey = "";
                this.clickNodeName = "";
                this.$axios.get(`${this.$diskUrl}/Folder/GetFolders`).then((res) => {
                    const { isSuccess , data } = res;
                    if (isSuccess) {
                        this.folderTreeData = data;     //文件夹集合(树形结构)
                    }
                });
            },
            chooseTransferGroup(data){
                this.clickNodeName = data.name;
                this.transferObj.folderKey = data.key;
            },
            transferFinish(){
                const {fileKey,folderKey} = this.transferObj;
                if(!folderKey){
                    this.$message({ message: '请选择文件夹', type: 'warning' });
                    return false;
                }
                this.$axios.get(`${this.$diskUrl}/File/TransferFolder?fileKey=${fileKey}&folderKey=${folderKey}`).then((res) => {
                    const { isSuccess } = res;
                    if (isSuccess) {
                        this.transferVisible = false;
                        this.$message({ message: '转移完成', type: 'success' });
                        this.getFileList(); //重新获取文件集合
                    }
                });
            },

            //批量删除
            batchRemove(){
                this.$confirm(`是否批量删除文件?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    this.$axios.post(`${this.$diskUrl}/File/BatchDelete`,{
                        fileKeys: this.checkList,
                    }).then((res) => {
                        const { isSuccess, data } = res;
                        if (isSuccess) {
                            if (data) {
                                this.$message({message: "删除成功",type: 'success'});
                                this.getFileList();
                            }else{
                                this.$message({message: "删除失败",type: 'warning'});
                            }
                        }
                    });
                }).catch(() => {});
            },
            //批量转移
            batchShift(){
                this.shiftVisible = true;
                this.transferObj.folderKey = "";
                this.clickNodeName = "";
                this.$axios.get(`${this.$diskUrl}/Folder/GetFolders`).then((res) => {
                    const { isSuccess , data } = res;
                    if (isSuccess) {
                        this.folderTreeData = data;     //文件夹集合(树形结构)
                    }
                });
            },
            shiftFinish(){
                if(!this.transferObj.folderKey){
                    this.$message({ message: '请选择文件夹', type: 'warning' });
                    return false;
                }
                this.$axios.post(`${this.$diskUrl}/File/BatchTransferFolder`,{
                    fileKeys: this.checkList,
                    folderKey: this.transferObj.folderKey,
                }).then((res) => {
                    const { isSuccess, data } = res;
                    this.shiftVisible = false;
                    if (isSuccess) {
                        if(data){
                            this.checkList = [];
                            this.$message({ message: '转移完成', type: 'success' });
                            this.getFileList();
                        }else{
                            this.$message({message: "转移失败",type: 'warning'});
                        }
                    }
                });
            },

        },
        mounted(){
            this.userInfo = JSON.parse(localStorage.userInfo);

            this.getFolderList();   //初始化获取文件夹集合

            this.getFileList();    //获取文件集合
        }
    }
</script>

<style lang="scss" scoped>
.networkDisk{
    height: calc(100vh - 80px);
    background-color: #fafafa;
    .header{
        height: 39px;
        line-height: 39px;
        background-color: #fafafa;
        border-bottom: 1px solid #ddd;
    }
    .content{
        overflow: hidden;
        .left{
            float: left;
            width: 240px;
            height: calc(100vh - 120px);
            border-right: 1px solid #ddd;
            background-color: #fff;
        }
        .right{
            float: left;
            width: calc(100% - 241px);
            height: calc(100vh - 120px);
            overflow: auto;
            padding: 20px;
            box-sizing: border-box;
            .top{
                height: 40px;
                line-height: 40px;
                overflow: hidden;
                .newFolder{
                    margin-top: 6px;
                    float: right;
                }
                .uploading{
                    float: right;
                    margin-right: 20px;
                }
            }

            .noData{
                background-color: #fff;
                margin: 0;
                padding: 32px;
                text-align: center;
                color: #666;
                font-size: 14px;
            }

            .list{
                .FolderItem{
                    box-sizing: border-box;
                    height: 55px;
                    padding: 16px;
                    line-height: 22px;
                    background-color: #fff;
                    margin-bottom: 1px;
                    overflow: hidden;
                    font-size: 14px;
                    .folderName{
                        float: left;
                    }
                    .userAccount{
                        float: right;
                        margin-right: 20px;
                    }
                    .timeExhibition{
                        float: right;
                        margin-right: 20px;
                    }
                    .option{
                        float: right;
                        margin-right: 20px;
                    }
                }
                .FileItem{
                    box-sizing: border-box;
                    height: 55px;
                    padding: 16px;
                    line-height: 22px;
                    background-color: #fff;
                    margin-bottom: 1px;
                    overflow: hidden;
                    font-size: 14px;
                    .remarkName{
                        float: left;
                        display: flex;
                    }
                    .userAccount{
                        float: right;
                        margin-right: 20px;
                    }
                    .timeExhibition{
                        float: right;
                        margin-right: 20px;
                    }
                    .option{
                        float: right;
                        margin-right: 20px;
                    }
                    .fileSize{
                        float: right;
                        margin-right: 20px;
                    }
                }
            }
        }
    }

    .folderName,.remarkName {
        .icon{
            color: var(--themeColor);
            font-size:18px;
        }
        span{
            padding-left: 20px;
            color: rgba(0, 0, 0, 0.65);
            cursor: pointer;
            &:hover{
                color: var(--themeColor);
            }
        }
    }

    .detailInfo{
        padding: 20px;
        .img{
            margin-bottom: 20px;
            text-align: center;
        }
        .infoItem{
            line-height: 28px;
            font-size: 12px;
            .copy{
                color: var(--themeColor);
                cursor: pointer;
                padding-left: 20px;
            }
        }
    }
}
</style>