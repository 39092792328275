<template>
<div>
    <div v-if="ie=='i'">
        <!-- 哥斯达黎加进口提单明细 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th width="25%">进口商ID</th>
                        <td width="25%">{{billInfo.ImporterId}}</td>
                        <th width="25%">数量单位</th>
                        <td width="25%">{{billInfo.Unit}}</td>
                    </tr>
                    <tr>
                        <th>总项总净重</th>
                        <td>{{billInfo.TotalNetWeight}}</td>
                        <th>总项总毛重</th>
                        <td>{{billInfo.TotalGrossWeight}}</td>
                    </tr>
                    <tr>
                        <th>CIF总项总价</th>
                        <td>{{billInfo.TotalCif}}</td>
                        <th>项目编号</th>
                        <td>{{billInfo.Item}}</td>
                    </tr>
                    <tr>
                        <th>总项发票金额</th>
                        <td>{{billInfo.TotalInvoice}}</td>
                        <th>发票金额</th>
                        <td>{{billInfo.Factura}}</td>
                    </tr>
                    <tr>
                        <th>净重</th>
                        <td>{{billInfo.NetWeight}}</td>
                        <th>毛重</th>
                        <td>{{billInfo.Weight}}</td>
                    </tr>
                    <tr>
                        <th>CIF总价（美元）</th>
                        <td>{{billInfo.Value}}</td>
                        <th>CIF单价（美元）</th>
                        <td>{{billInfo.UnitCif}}</td>
                    </tr>
                    <tr>
                        <th>FOB总价（美元）</th>
                        <td>{{billInfo.ValueFob}}</td>
                        <th>FOB单价（美元）</th>
                        <td>{{billInfo.UnitFob}}</td>
                    </tr>
                    <tr>
                        <th>商品状态</th>
                        <td>{{billInfo.EstadoMercaderia}}</td>
                        <th>离岸价$</th>
                        <td>{{billInfo.FOB}}</td>
                    </tr>
                    <tr>
                        <th>商业数量</th>
                        <td>{{billInfo.CmercialeQuantity}}</td>
                        <th>商业数量单位</th>
                        <td>{{billInfo.CmercialeUnit}}</td>
                    </tr>
                    <tr>
                        <th>发票</th>
                        <td>{{billInfo.FacturaN}}</td>
                        <th>发票行</th>
                        <td>{{billInfo.FacturaLine}}</td>
                    </tr>
                    <tr>
                        <th>品牌</th>
                        <td>{{billInfo.Brand}}</td>
                        <th>型号</th>
                        <td>{{billInfo.Model}}</td>
                    </tr>
                    <tr>
                        <th>发票离岸价$</th>
                        <td>{{billInfo.FacturaFob}}</td>
                        <th>产品商业数量</th>
                        <td>{{billInfo.CmercialeUnitQuantity}}</td>
                    </tr>
                    <tr>
                        <th>汇率</th>
                        <td>{{billInfo.Currency}}</td>
                        <th>商品单位价格</th>
                        <td>{{billInfo.UnitPrice}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
        
        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">运输信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">运输方式</th>
                        <td width="25%">{{billInfo.TransportType}}</td>
                        <th width="25%">船号</th>
                        <td width="25%">{{billInfo.ShipNum}}</td>
                    </tr>
                    <tr>
                        <th>来源国</th>
                        <td>{{billInfo.Country}}</td>
                        <th>起运国</th>
                        <td>{{billInfo.ProcedenciaCountry}}</td>
                    </tr>
                    <tr>
                        <th>中转国</th>
                        <td>{{billInfo.AdqCountry}}</td>
                        <th>运费</th>
                        <td>{{billInfo.Freight}}</td>
                    </tr>
                    <tr>
                        <th>保险</th>
                        <td>{{billInfo.Insurance}}</td>
                        <th>包装数量</th>
                        <td>{{billInfo.Packages}}</td>
                    </tr>
                    <tr>
                        <th>包装类型</th>
                        <td>{{billInfo.PackageType}}</td>
                        <th>贸易方式</th>
                        <td>{{billInfo.Remimen}}</td>
                    </tr>
                    <tr>
                        <th>仓库名称</th>
                        <td colspan="3">{{billInfo.Almacen}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">海关信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">进出口类型</th>
                        <td width="25%">{{billInfo.Type}}</td>
                        <th width="25%">海关</th>
                        <td width="25%">{{billInfo.Customs}}</td>
                    </tr>
                    <tr>
                        <th>报关单序列号</th>
                        <td>{{billInfo.Declaration}}</td>
                        <th>报关公司ID</th>
                        <td>{{billInfo.DeclarationCompanyId}}</td>
                    </tr>
                    <tr>
                        <th>报关公司</th>
                        <td>{{billInfo.DeclarationCompany}}</td>
                        <th>代理人编号</th>
                        <td>{{billInfo.AgentId}}</td>
                    </tr>
                    <tr>
                        <th>代理人</th>
                        <td>{{billInfo.Agent}}</td>
                        <th>海关税</th>
                        <td>{{billInfo.AduanaUs}}</td>
                    </tr>
                    <tr>
                        <th>备注</th>
                        <td>{{billInfo.Remarks}}</td>
                        <th>仓库名称</th>
                        <td>{{billInfo.DeclarationEnterprise}}</td>
                    </tr>
                    <tr>
                        <th>办公室</th>
                        <td>{{billInfo.Despacho}}</td>
                        <th>办公状态</th>
                        <td>{{billInfo.EstadoDespacho}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
    <div v-if="ie=='e'">
        <!-- 哥斯达黎加出口提单明细 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th width="25%">出口商ID</th>
                        <td width="25%">{{billInfo.ExporterId}}</td>
                        <th width="25%">数量单位</th>
                        <td width="25%">{{billInfo.Unit}}</td>
                    </tr>
                    <tr>
                        <th>总项总净重</th>
                        <td>{{billInfo.TotalNetWeight}}</td>
                        <th>总项总毛重</th>
                        <td>{{billInfo.TotalGrossWeight}}</td>
                    </tr>
                    <tr>
                        <th>CIF总项总价</th>
                        <td>{{billInfo.TotalCif}}</td>
                        <th>项目编号</th>
                        <td>{{billInfo.Item}}</td>
                    </tr>
                    <tr>
                        <th>总项发票金额</th>
                        <td>{{billInfo.TotalInvoice}}</td>
                        <th>发票金额</th>
                        <td>{{billInfo.Factura}}</td>
                    </tr>
                    <tr>
                        <th>净重</th>
                        <td>{{billInfo.NetWeight}}</td>
                        <th>毛重</th>
                        <td>{{billInfo.Weight}}</td>
                    </tr>
                    <tr>
                        <th>CIF总价（美元）</th>
                        <td>{{billInfo.Value}}</td>
                        <th>CIF单价（美元）</th>
                        <td>{{billInfo.UnitCif}}</td>
                    </tr>
                    <tr>
                        <th>FOB总价（美元）</th>
                        <td>{{billInfo.ValueFob}}</td>
                        <th>FOB单价（美元）</th>
                        <td>{{billInfo.UnitFob}}</td>
                    </tr>
                    <tr>
                        <th>商品状态</th>
                        <td>{{billInfo.EstadoMercaderia}}</td>
                        <th>离岸价$</th>
                        <td>{{billInfo.FOB}}</td>
                    </tr>
                    <tr>
                        <th>商业数量</th>
                        <td>{{billInfo.CmercialeQuantity}}</td>
                        <th>商业数量单位</th>
                        <td>{{billInfo.CmercialeUnit}}</td>
                    </tr>
                    <tr>
                        <th>发票</th>
                        <td>{{billInfo.FacturaN}}</td>
                        <th>发票行</th>
                        <td>{{billInfo.FacturaLine}}</td>
                    </tr>
                    <tr>
                        <th>品牌</th>
                        <td>{{billInfo.Brand}}</td>
                        <th>型号</th>
                        <td>{{billInfo.Model}}</td>
                    </tr>
                    <tr>
                        <th>发票离岸价$</th>
                        <td>{{billInfo.FacturaFob}}</td>
                        <th>产品商业数量</th>
                        <td>{{billInfo.CmercialeUnitQuantity}}</td>
                    </tr>
                    <tr>
                        <th>汇率</th>
                        <td>{{billInfo.Currency}}</td>
                        <th>商品单位价格</th>
                        <td>{{billInfo.UnitPrice}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">运输信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">运输方式</th>
                        <td width="25%">{{billInfo.TransportType}}</td>
                        <th width="25%">目的国</th>
                        <td width="25%">{{billInfo.Country}}</td>
                    </tr>
                    <tr>
                        <th>船号</th>
                        <td>{{billInfo.ShipNum}}</td>
                        <th>包装数量</th>
                        <td>{{billInfo.Packages}}</td>
                    </tr>
                    <tr>
                        <th>包装类型</th>
                        <td>{{billInfo.PackageType}}</td>
                        <th>贸易方式</th>
                        <td>{{billInfo.Remimen}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">海关信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">进出口类型</th>
                        <td width="25%">{{billInfo.Type}}</td>
                        <th width="25%">海关</th>
                        <td width="25%">{{billInfo.Customs}}</td>
                    </tr>
                    <tr>
                        <th>报关单序列号</th>
                        <td>{{billInfo.Declaration}}</td>
                        <th>报关公司ID</th>
                        <td>{{billInfo.DeclareTaxId}}</td>
                    </tr>
                    <tr>
                        <th>报关公司</th>
                        <td>{{billInfo.DeclareName}}</td>
                        <th>备注</th>
                        <td>{{billInfo.Remarks}}</td>
                    </tr>
                    <tr>
                        <th>办公室</th>
                        <td>{{billInfo.Despacho}}</td>
                        <th>办公状态</th>
                        <td>{{billInfo.EstadoDespacho}}</td>
                    </tr>
                    <tr>
                        <th>代理商</th>
                        <td>{{billInfo.Agent}}</td>
                        <th>模态</th>
                        <td>{{billInfo.Modad}}</td>
                    </tr>
                    <tr>
                        <th>序列号</th>
                        <td colspan="3">{{billInfo.SerialNum}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
</div>
</template>

<script>
export default {
    props:["billInfo","ie"],
    data() {
        return {

        };
    },
    methods: {
        
    },
    mounted() {

    },
};
</script>

<style lang="scss" scoped>
.partItem {
    margin-bottom: 20px;
    /deep/.el-card__header {
        padding: 10px 20px;
        .headTitle {
            display: inline-block;
            padding-left: 10px;
            font-size: 14px;
            border-left: 2px solid var(--themeColor);
            font-weight: bold;
        }
    }
}
table {
    width: 100%;
    border-collapse: collapse;
    tr {
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;
        th,
        td {
            font-size: 12px;
            color: #666;
            height: 40px;
            border: 1px solid #eaeaea;
            text-align: center;
        }
        th {
            background-color: #f5f7fa;
            font-weight: 700;
        }
        .bg {
            background-color: var(--themeColor);
            color: #fff;
            font-weight: 700;
            opacity: 0.8;
        }
    }
}
</style>