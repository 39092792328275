<template>
<div>
    <div v-if="ie=='i'">
        <!-- 博茨瓦纳(BWA)进口提单明细 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th width="25%">原产国</th>
                        <td width="25%">{{billInfo.Country}}</td>
                        <th width="25%">数量单位</th>
                        <td width="25%">{{billInfo.QuantityUnit}}</td>
                    </tr>
                    <tr>
                        <th>毛重</th>
                        <td>{{billInfo.Weight}}</td>
                        <th>毛重单位</th>
                        <td>{{billInfo.WeightUnit}}</td>
                    </tr>
                    <tr>
                        <th>净重</th>
                        <td>{{billInfo.NetWeight}}</td>
                        <th>净重单位</th>
                        <td>{{billInfo.NetWeightUnit}}</td>
                    </tr>
                    <tr>
                        <th>包装数</th>
                        <td>{{billInfo.PackagesNumber}}</td>
                        <th>包装类型</th>
                        <td>{{billInfo.PackageType}}</td>
                    </tr>
                    <tr>
                        <th>项目编号</th>
                        <td>{{billInfo.ItemNo}}</td>
                        <th>报关处</th>
                        <td>{{billInfo.DeclarationOffice}}</td>
                    </tr>
                    <tr>
                        <th>出口国</th>
                        <td>{{billInfo.ExportCountry}}</td>
                        <th>目的国</th>
                        <td>{{billInfo.DestinationCountry}}</td>
                    </tr>
                    <tr>
                        <th>进口港</th>
                        <td>{{billInfo.EntryPort}}</td>
                        <th>卸货港</th>
                        <td>{{billInfo.DischargePort}}</td>
                    </tr>
                    <tr>
                        <th>海关流程代码</th>
                        <td>{{billInfo.CPCCode}}</td>
                        <th>海关流程代码描述</th>
                        <td>{{billInfo.CPCDescription}}</td>
                    </tr>
                    <tr>
                        <th>每次点击费用组代码</th>
                        <td>{{billInfo.CPCGroupCode}}</td>
                        <th>每次点击费用组说明</th>
                        <td>{{billInfo.CPCGroupDescription}}</td>
                    </tr>
                    <tr>
                        <th>报关编号</th>
                        <td>{{billInfo.DeclarantTin}}</td>
                        <th>申报人</th>
                        <td>{{billInfo.Declarant}}</td>
                    </tr>
                    <tr>
                        <th>进口单号</th>
                        <td>{{billInfo.ImportShipmentId}}</td>
                        <th>进口国</th>
                        <td>{{billInfo.ImportCountry}}</td>
                    </tr>
                    <tr>
                        <th>FOB申报金额(普拉币)</th>
                        <td>{{billInfo.DeclaredFobValueInBotswananPula}} {{billInfo.DeclaredFobValueInBotswananPulaUnit}}</td>
                        <th>FOB申报金额(美金)</th>
                        <td>{{billInfo.FobValueInUSD}} {{billInfo.FobValueInUSDUnit}}</td>
                    </tr>
                    <tr>
                        <th>发票总金额(普拉币)</th>
                        <td>{{billInfo.TotalCargoInvoiceValueInBotswananPula}} {{billInfo.TotalCargoInvoiceValueInBotswananPulaUnit}}</td>
                        <th>发票总金额(美金)</th>
                        <td>{{billInfo.TotalCargoInvoiceValueInUSD}} {{billInfo.TotalCargoInvoiceValueInUSDUnit}}</td>
                    </tr>
                    <tr>
                        <th>运费(普拉币)</th>
                        <td>{{billInfo.FreightInBotswananPula}} {{billInfo.FreightInBotswananPulaUnit}}</td>
                        <th>保险费(普拉币)</th>
                        <td>{{billInfo.InsuranceInBotswananPula}} {{billInfo.InsuranceInBotswananPulaUnit}}</td>
                    </tr>
                    <tr>
                        <th>成本及运费(普拉币)</th>
                        <td>{{billInfo.CnfCifInBotswananPula}} {{billInfo.CnfCifInBotswananPulaUnit}}</td>
                        <th>成本及运费(美金)</th>
                        <td>{{billInfo.CnfCifInUSD}} {{billInfo.CnfCifInUSDUnit}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
    <div v-if="ie=='e'">
        <!-- 博茨瓦纳(BWA)出口提单明细 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th width="25%">原产国</th>
                        <td width="25%">{{billInfo.Country}}</td>
                        <th width="25%">数量单位</th>
                        <td width="25%">{{billInfo.QuantityUnit}}</td>
                    </tr>
                    <tr>
                        <th>毛重</th>
                        <td>{{billInfo.Weight}}</td>
                        <th>毛重单位</th>
                        <td>{{billInfo.WeightUnit}}</td>
                    </tr>
                    <tr>
                        <th>净重</th>
                        <td>{{billInfo.NetWeight}}</td>
                        <th>净重单位</th>
                        <td>{{billInfo.NetWeightUnit}}</td>
                    </tr>
                    <tr>
                        <th>包装数</th>
                        <td>{{billInfo.PackagesNumber}}</td>
                        <th>包装类型</th>
                        <td>{{billInfo.PackageType}}</td>
                    </tr>
                    <tr>
                        <th>项目编号</th>
                        <td>{{billInfo.ItemNo}}</td>
                        <th>报关处</th>
                        <td>{{billInfo.DeclarationOffice}}</td>
                    </tr>
                    <tr>
                        <th>出口国</th>
                        <td>{{billInfo.ExportCountry}}</td>
                        <th>目的国</th>
                        <td>{{billInfo.DestinationCountry}}</td>
                    </tr>
                    <tr>
                        <th>进口港</th>
                        <td>{{billInfo.EntryPort}}</td>
                        <th>卸货港</th>
                        <td>{{billInfo.DischargePort}}</td>
                    </tr>
                    <tr>
                        <th>海关流程代码</th>
                        <td>{{billInfo.CPCCode}}</td>
                        <th>海关流程代码描述</th>
                        <td>{{billInfo.CPCDescription}}</td>
                    </tr>
                    <tr>
                        <th>每次点击费用组代码</th>
                        <td>{{billInfo.CPCGroupCode}}</td>
                        <th>每次点击费用组说明</th>
                        <td>{{billInfo.CPCGroupDescription}}</td>
                    </tr>
                    <tr>
                        <th>报关编号</th>
                        <td>{{billInfo.DeclarantTin}}</td>
                        <th>申报人</th>
                        <td>{{billInfo.DeclarantName}}</td>
                    </tr>
                    <tr>
                        <th>出口单号</th>
                        <td>{{billInfo.ExportShipmentId}}</td>
                        <th>进口国</th>
                        <td>{{billInfo.ForeignImporterCountry}}</td>
                    </tr>
                    <tr>
                        <th>FOB申报金额(普拉币)</th>
                        <td>{{billInfo.DeclaredFobValueInBotswananPula}} {{billInfo.DeclaredFobValueInBotswananPulaUnit}}</td>
                        <th>FOB申报金额(美金)</th>
                        <td>{{billInfo.FobValueInUSD}} {{billInfo.FobValueInUSDUnit}}</td>
                    </tr>
                    <tr>
                        <th>发票总金额(普拉币)</th>
                        <td>{{billInfo.TotalCargoInvoiceValueInBotswananPula}} {{billInfo.TotalCargoInvoiceValueInBotswananPulaUnit}}</td>
                        <th>发票总金额(美金)</th>
                        <td>{{billInfo.TotalCargoInvoiceValueInUSD}} {{billInfo.TotalCargoInvoiceValueInUSDUnit}}</td>
                    </tr>
                    <tr>
                        <th>运费(普拉币)</th>
                        <td>{{billInfo.FreightInBotswananPula}} {{billInfo.FreightInBotswananPulaUnit}}</td>
                        <th>保险费(普拉币)</th>
                        <td>{{billInfo.InsuranceInBotswananPula}} {{billInfo.InsuranceInBotswananPulaUnit}}</td>
                    </tr>
                    <tr>
                        <th>CIF申报金额(普拉币)</th>
                        <td>{{billInfo.DeclaredCifValueInBotswananPula}} {{billInfo.DeclaredCifValueInBotswananPulaUnit}}</td>
                        <th>CIF申报金额(美金)</th>
                        <td>{{billInfo.CifValueInUSD}} {{billInfo.CifValueInUSDUnit}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
</div>
</template>

<script>
export default {
    props:["billInfo","ie"],
    data() {
        return {

        };
    },
    methods: {
        
    },
    mounted() {

    },
};
</script>

<style lang="scss" scoped>
.partItem {
    margin-bottom: 20px;
    /deep/.el-card__header {
        padding: 10px 20px;
        .headTitle {
            display: inline-block;
            padding-left: 10px;
            font-size: 14px;
            border-left: 2px solid var(--themeColor);
            font-weight: bold;
        }
    }
}
table {
    width: 100%;
    border-collapse: collapse;
    tr {
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;
        th,
        td {
            font-size: 12px;
            color: #666;
            height: 40px;
            border: 1px solid #eaeaea;
            text-align: center;
        }
        th {
            background-color: #f5f7fa;
            font-weight: 700;
        }
        .bg {
            background-color: var(--themeColor);
            color: #fff;
            font-weight: 700;
            opacity: 0.8;
        }
    }
}
</style>