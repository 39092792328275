import axios from "axios";

/**
 * 上传图片接口
 * @param {*} file
 */
async function uploadImage(file) {
    const from = new FormData();
    from.append("file", file);
    from.append("permission", "我的");
    from.append("remarkName", "");
    from.append("folderKey", "");
    const res = await axios.request({
        url: `https://crm.qixinerp.com/disk/File/Upload`,
        method: "POST",
        data: from,
    });

    const imgUrl = res.data.fileUrl;
    return imgUrl;
}

export default uploadImage;