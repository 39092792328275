<template>
  <div>
    <el-input v-show="!newTrendsFormExpanded" v-model="newTrendsForm.content" placeholder="请输入跟进内容" size="small" @click.native="newTrendsFormExpanded = true" />
    <!-- 完整的添加新的跟进动态表单 -->
    <div v-show="newTrendsFormExpanded" v-loading="loading.form">
      <div style="border: 1px solid #dcdfe6; border-radius: 4px">
        <el-input ref="textareaElement" v-model="newTrendsForm.content" :rows="6" type="textarea" placeholder="请输入跟进内容" data-style="border" />
        <!-- 上传的附件列表 -->
        <ul class="newTrendsForm-attachments">
          <li v-for="(item, index) of newTrendsForm.attachments" :key="item.url">
            <div style="display: flex; align-items: center; margin: 0 5px">
              <el-image v-if="isImageFile(item.name)" :src="item.url" :preview-src-list="[item.url]" style="width: 30px; height: 30px" />
              <i v-else class="el-icon-document" style="font-size: 30px"></i>
            </div>
            <div>
              <p style="font-size: 14px" @click="downloadFile(item.url)">{{ item.name }}</p>
              <p style="font-size: 14px">{{ item.size }}</p>
            </div>
            <span class="delete" title="删除" @click="newTrendsForm.attachments.splice(index, 1)">x</span>
          </li>
        </ul>
        <!-- 控件区 (选择联系人，下次跟进时间，附件) -->
        <div style="display: flex; justify-content: space-between; background: #f0f0f0">
          <el-select v-model="newTrendsForm.contact" placeholder="请选择联系人" size="mini" data-style="border bgc">
            <el-option v-for="item of contactOptions" :key="item.key" :label="item.nickname" :value="item.nickname" />
          </el-select>
          <div>
            <el-date-picker style="width: 135px" v-model="newTrendsForm.nextDate" type="date" placeholder="下次跟进日期" size="mini" data-style="border bgc" :picker-options="setDisabled" />
            <el-time-select style="width: 135px" v-model="newTrendsForm.nextTime" placeholder="下次跟进时间" size="mini" data-style="border bgc" />
            <input type="file" style="display: none" ref="uploadFileInput" @change="handleUploadFileInputChange" />
            <el-tooltip effect="dark" content="上传附件" placement="right">
              <span style="border: 1px #d9d9d9 solid; width: 24px; height: 24px; display: inline-grid; place-items: center" @click="handleUploadFileSpanClick">
                <i class="el-icon-paperclip"></i>
              </span>
            </el-tooltip>
          </div>
        </div>
      </div>
      <!-- 控件区 (取消，发布) -->
      <div style="margin-top: 10px; display: flex; justify-content: flex-end">
        <el-button @click="newTrendsFormExpanded = false" size="mini">取 消</el-button>
        <el-button type="primary" size="mini" @click="handlePublishButtonClick" :loading="loading.publishButton">发 布</el-button>
      </div>
    </div>
    <!-- 跟进记录 -->
    <div v-show="recordYears.length !== 0">
      <!-- 搜索控件区 (跟进人，联系人，类型) -->
      <div style="margin: 10px 0; display: flex; justify-content: space-between">
        <el-select v-if="role !== '代理商'" v-model="searchForm.recorder" style="width: 135px" data-style="border" placeholder="跟进人" size="mini" clearable @change="handleSearchFormChange">
          <el-option v-for="(item, index) of recorderOptions" :key="index" :label="item.name" :value="item.name" />
        </el-select>
        <el-select v-model="searchForm.contact" style="width: 135px" data-style="border" placeholder="联系人" size="mini" clearable @change="handleSearchFormChange">
          <el-option v-for="item in contactOptions" :key="item.key" :label="item.nickname" :value="item.nickname" />
        </el-select>
        <el-select v-model="searchForm.type" style="width: 135px" data-style="border" placeholder="类型" size="mini" clearable @change="handleSearchFormChange">
          <el-option-group label="系统跟进">
            <el-option label="邮件来往" value="邮件来往" />
            <el-option label="公司信息变更" value="公司信息变更" />
            <el-option label="联系人信息变更" value="联系人信息变更" />
          </el-option-group>
          <el-option-group label="类型">
            <el-option label="含图片" value="含图片" />
            <el-option label="含附件" value="含附件" />
          </el-option-group>
        </el-select>
      </div>
      <!-- 每年的跟进记录 -->
      <el-collapse v-if="recordYears.length !== 0" v-model="activeNames">
        <el-collapse-item v-show="item.count !== 0" v-for="item of recordYears" :key="item.year" :title="`${item.year}年 (${item.count})`" :name="item.year">
          <div v-if="records[item.year]">
            <el-timeline>
              <el-timeline-item v-for="(unit,index) of records[item.year].data" :key="unit.key">
                <div style="display: flex; justify-content: space-between; font-size: 14px; color: #909399">
                  <span>{{ unit.contactName }}&ensp;&ensp;{{ unit.followTime }}</span>
                  <div>
                    <span style="margin-right: 12px;">{{ unit.follower }}</span>
                    <i v-if="comConfig.isDeleteFollowRecords && userInfo.uKey == unit.followerKey"
                        @click="delOneRecord(records[item.year].data,unit,index)" class="el-icon-delete"></i>
                  </div>
                </div>
                <div style="margin: 10px 0">
                  <div v-html="unit.followContent"></div>
                </div>
                <div v-if="unit.nextFollowTime" style="color: #909399; font-size: 12px">
                  <p>下次跟进时间：{{ unit.nextFollowTime }}</p>
                </div>
                <!-- 文档附件 -->
                <ul class="attachments-list-timeline">
                  <li v-for="attachment of unit.annex" :key="attachment.url" :title="attachment.name">
                    <div class="attachments-list-timeline-item not-pic" v-if="!isImageFile(attachment.name)">
                      <div style="display: flex; align-items: center; margin: 0 5px">
                        <i class="el-icon-document" style="font-size: 30px"></i>
                      </div>
                      <div>
                        <p style="font-size: 14px" class="title" @click="downloadFile(attachment.url)">{{ attachment.name }}</p>
                        <p style="font-size: 14px" class="size">{{ attachment.size }}</p>
                      </div>
                    </div>
                  </li>
                </ul>
                <!-- 图片附件 -->
                <ul class="attachments-list-timeline">
                  <li v-for="attachment of unit.annex" :key="attachment.url" :title="attachment.name">
                    <div class="attachments-list-timeline-item" v-if="isImageFile(attachment.name)">
                      <el-image style="width: 120px; height: 120px" :src="attachment.url" :preview-src-list="[attachment.url]" />
                    </div>
                  </li>
                </ul>
              </el-timeline-item>
            </el-timeline>
            <el-pagination :current-page="records[item.year].pageIndex" @current-change="handlePaginationPageIndexChange($event, item.year)" :page-size="10" :total="records[item.year].total" :hide-on-single-page="true" layout="prev, pager, next" />
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <!-- 无跟进记录 -->
    <div v-show="isDynamicEmpty">
      <div style="display: flex; flex-direction: column; align-items: center; color: #c0c4cc">
        <img src="@/assets/img/nodata.png" alt="" />
        <div style="font-size: 14px;color: #909399;margin-top: 10px;">暂无跟进记录</div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

export default {
  /**
   * customerKey 客户 key
   * expand 添加新的跟进动态表单是否展开
   * separate 给客户单独的账号添加动态
   * viewAccount 预览的账号
   */
  props: ["customerKey", "expand", "separate", "viewAccount"],
  data() {
    const role = unescape(window.localStorage.getItem("role"));
    const recorder = role === "代理商" ? JSON.parse(window.localStorage.getItem("userInfo")).userName : "";
    return {
      role, //角色
      newTrendsFormExpanded: this.expand || false, //添加新的跟进动态表单是否展开
      contactOptions: [], //联系人列表
      newTrendsForm: {
        content: "", //跟进内容
        contact: "", //联系人
        nextDate: "", //下次跟进日期
        nextTime: "", //下次跟进时间
        attachments: [], //附件  {name: string, size: string, url: string}[]
      }, //添加新的跟进动态表单
      recorderOptions: [], //跟进人列表
      searchForm: {
        recorder, //跟进人
        contact: "", //联系人
        type: "", //类型
      }, //搜索表单
      activeNames: [], //打开的 el-collapse-item 的 name
      recordYears: [], //跟进记录的年份
      records: {}, //跟进记录
      loading: {
        form: false,
        publishButton: false,
      },
      //设置禁用日期
      setDisabled:{
        disabledDate(time){
            // return time.getTime() > Date.now();  // 可选历史天、可选当前天、不可选未来天
            // return time.getTime() > Date.now() - 8.64e7;  // 可选历史天、不可选当前天、不可选未来天
            return time.getTime() < Date.now() - 8.64e7;  // 不可选历史天、可选当前天、可选未来天
            // return time.getTime() < Date.now(); // 不可选历史天、不可选当前天、可选未来天
        }
      },
      //公司系统配置
      comConfig: localStorage.comConfig ? JSON.parse(localStorage.comConfig) : {},
      //用户信息
      userInfo: localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {},
    };
  },
  computed: {
    /**
     * 跟进记录是否为空
     * @listens this.recordYears
     * @returns {boolean}
     */
    isDynamicEmpty() {
      if (this.recordYears.length === 0) {
        return true;
      }
      for (const item of this.recordYears) {
        if (item.count !== 0) {
          return false;
        }
      }
      return true;
    },
  },
  methods: {
    //获取联系人列表
    async getContactOptions() {
      const res = await this.$axios({
        method: "get",
        url: `${this.$customerUrl}/Company/GetContacters`,
        params: {
          customerKey: this.customerKey,
        },
      });
      if (res.isSuccess) {
        return Promise.resolve(res.data);
      }
      return Promise.resolve([]);
    },
    //input[type="file"] change 时触发
    handleUploadFileInputChange($event) {
      const file = $event.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
      formData.append("permission", "我的");
      formData.append("remarkName", "");
      formData.append("folderKey", "");
      this.loading.form = true;
      this.$axios({
        url: `${this.$diskUrl}/File/Upload`,
        method: "post",
        data: formData,
      }).then((res) => {
        this.loading.form = false;
        if (res.isSuccess) {
          const data = res.data;
          this.newTrendsForm.attachments.push({
            name: data.remarkName,
            size: data.fileSize,
            url: data.fileUrl,
          });
        }
      });
    },
    //点击上传附件图标
    handleUploadFileSpanClick() {
      this.$refs.uploadFileInput.click();
    },
    //下载文件
    downloadFile(url) {
      window.open(url, "_blank");
    },
    //判断是否为图片文件
    isImageFile(fileName) {
      if (fileName) {
        return ["png", "jpg", "jpeg"].includes(fileName.substring(fileName.lastIndexOf(".") + 1));
      }
      return false;
    },
    //重置添加新跟进动态表单
    resetNewTrendsForm() {
      this.newTrendsForm = {
        content: "",
        contact: "",
        nextDate: "",
        nextTime: "",
        attachments: [],
      };
    },
    //点击发布按钮，添加新的跟进动态
    async handlePublishButtonClick() {
      //验证跟进内容
      if (this.newTrendsForm.content.trim() === "") {
        this.$message.warning("跟进内容不得为空");
        return false;
      }
      if (this.separate) {
        this.newTrendsForm.content = this.viewAccount + "<br/>" + this.newTrendsForm.content;
      }
      // console.log(this.newTrendsForm.content);
      //验证下次跟进时间
      const date = this.newTrendsForm.nextDate;
      const time = this.newTrendsForm.nextTime;
      if (Boolean(date) !== Boolean(time)) {
        if (!date) {
          this.$message.warning("请选择下次跟进日期");
        }
        if (!time) {
          this.$message.warning("请选择下次跟进时间");
        }
        return false;
      }
      //获取完整的下次跟进时间
      const nextFullTime = (() => {
        const date = dayjs(this.newTrendsForm.nextDate);
        const year = date.year();
        const month = date.month();
        const day = date.date();
        const time = dayjs(this.newTrendsForm.nextTime, "HH:mm");
        const hour = time.hour();
        const minute = time.minute();
        return new Date(Date.UTC(year, month, day, hour, minute));
      })();
      this.loading.publishButton = true;
      const res = await this.$axios({
        method: "post",
        url: this.$customerUrl + "/Company/AddFollowRecord",
        data: {
          customerKey: this.customerKey,
          contactPerson: this.newTrendsForm.contact,
          followType: "",
          followContent: this.newTrendsForm.content,
          nextFollowTime: nextFullTime,
          annex: this.newTrendsForm.attachments,
        },
      });
      this.loading.publishButton = false;
      if (res.isSuccess) {
        this.resetNewTrendsForm();
        this.newTrendsFormExpanded = false;
        const currentYear = dayjs().year();
        if (this.recordYears.some((item) => item.year == currentYear)) {
          //如果当前年份已有跟进记录记录，直接获取新的跟进记录
          this.getRecord(currentYear, 1).then((record) => {
            this.records[currentYear] = record;
            this.refreshCollapseItemTitle(currentYear);
          });
        } else {
          //否则先获取年份，再获取新的跟进记录
          this.getRecordYears().then((recordYears) => {
            this.recordYears.unshift(recordYears[0]);
            this.getRecord(currentYear, 1).then((record) => {
              this.$set(this.records, currentYear, record);
              this.refreshCollapseItemTitle(currentYear);
            });
          });
        }
      }
    },
    //获取跟进人列表
    async getRecorderOptions() {
      const res = await this.$axios({
        method: "get",
        url: `${this.$customerUrl}/Company/GetFollower`,
        params: {
          customerKey: this.customerKey,
        },
      });
      if (res.isSuccess) {
        return Promise.resolve(res.data);
      }
      return Promise.resolve([]);
    },
    //刷新所有跟进记录，在搜索控件区改变时调用
    refreshAllRecords() {
      this.recordYears.forEach((item) => {
        const year = item.year;
        this.getRecord(year, 1).then((record) => {
          this.records[year] = record;
          this.refreshCollapseItemTitle(year);
        });
      });
    },
    //当搜索表单内容改变时触发
    handleSearchFormChange() {
      this.refreshAllRecords();
    },
    //获取跟进记录的年份
    async getRecordYears() {
      const res = await this.$axios({
        method: "post",
        url: `${this.$customerUrl}/Company/GetFollowRecord`,
        data: {
          customerKey: this.customerKey,
        },
      });
      if (res.isSuccess) {
        return Promise.resolve(res.data.dateList);
      }
      return Promise.resolve([]);
    },
    /**
     * 刷新 el-collapse-item 的 title 属性
     * @param year 年
     */
    refreshCollapseItemTitle(year) {
      const index = this.recordYears.findIndex((item) => item.year == year);
      this.recordYears[index].count = this.records[year].total;
    },
    /**
     * 获取单年跟进记录
     * @param year 年
     * @param pageIndex 页码
     * @returns {{total: number, data: Array}}
     */
    async getRecord(year, pageIndex) {
      const res = await this.$axios({
        method: "post",
        url: `${this.$customerUrl}/Company/GetFollowRecord`,
        data: {
          customerKey: this.customerKey,
          beginTime: `${year}-01-01 00:00:00`,
          endTime: `${year}-12-31 23:59:59`,
          pageSize: 10,
          pageIndex,
          //搜索条件
          recorder: this.searchForm.recorder,
          contact: this.searchForm.contact,
          type: this.searchForm.type,
        },
      });
      if (res.isSuccess) {
        const record = {
          total: res.data.followList.totalItems,
          pageIndex,
          data: res.data.followList.datas,
        };
        return Promise.resolve(record);
      }
      return Promise.resolve({ total: 0, data: [] });
    },
    //分页器 currentPage 改变时触发
    async handlePaginationPageIndexChange(pageIndex, year) {
      const record = await this.getRecord(year, pageIndex);
      this.records[year] = record;
    },

    //删除本人跟进记录
    delOneRecord(list,unit,index){
        this.$confirm('此操作将删除该条跟进记录,是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            // console.log(list,unit,index);
            this.$axios.get(`${this.$customerUrl}/Company/DeleteFollowRecords?key=${unit.key}`).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    list.splice(index, 1);
                    this.$message({ type: 'success', message: '删除成功!'});
                }
            });
        }).catch(() => {});
    },
  },
  mounted() {
    //获取联系人列表
    this.getContactOptions().then((contactOptions) => {
      this.contactOptions = contactOptions;
    });
    //获取跟进人列表
    this.getRecorderOptions().then((recorderOptions) => {
      this.recorderOptions = recorderOptions;
    });
    //获取所有跟进记录
    this.getRecordYears().then((recordYears) => {
      this.recordYears = recordYears;
      //没有跟进记录，直接结束
      if (this.recordYears.length === 0) {
        return;
      }
      this.activeNames.push(this.recordYears[0].year);
      this.recordYears.forEach((item) => {
        const year = item.year;
        this.getRecord(year, 1).then((record) => {
          this.$set(this.records, year, record);
          this.refreshCollapseItemTitle(year);
        });
      });
    });
    //注册 auto-focus 事件
    this.$on("auto-focus", () => {
      this.newTrendsFormExpanded = true;
      this.$nextTick(() => {
        this.$refs.textareaElement.focus();
      });
    });
  },
};
</script>

<style lang="scss" scoped>
.newTrendsForm-attachments {
  height: max-content;
  li {
    position: relative;
    display: inline-flex;
    padding-right: 24px;
    .delete {
      color: transparent;
      position: absolute;
      top: -2px;
      right: 2px;
    }
    &:hover {
      .delete {
        color: #333;
      }
    }
  }
}
.attachments-list-timeline {
  list-style: none;
  height: max-content;
  margin-bottom: 10px;
  .attachments-list-timeline-item {
    margin-right: 15px;
    display: inline-flex;
    &.not-pic {
      background: #f8f8f8;
      padding: 0.5em 10px 0.5em 0;
      border: 1px solid #e6e6e6;
      border-radius: 4px;
    }
    p {
      &.title {
        width: 120px;
        margin-bottom: 0.5em;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      &.size {
        color: #909090;
      }
    }
  }
  li {
    display: inline-block;
  }
}

/* 改变element-ui组件样式 */
/deep/ [data-style~="border"] {
  border: none;
}
[data-style~="border"] /deep/ input.el-input__inner {
  border: none !important;
  &::placeholder {
    color: #5d5d5d;
  }
}
[data-style~="bgc"] /deep/ input.el-input__inner {
  background: #f0f0f0 !important;
}

.el-icon-delete{
    cursor: pointer;
    margin-right: 12px;
}
</style>
