var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.dialogVisible)?_c('div',[_c('div',{staticClass:"header"},[_c('div',{staticClass:"title"},[_vm._v("我的签名")]),_c('div',{staticClass:"describe"},[_vm._v("管理您的个性签名")]),_c('el-button',{staticClass:"addBtn",attrs:{"size":"small","type":"primary","icon":"el-icon-plus"},on:{"click":_vm.toNew}},[_vm._v("新建")])],1),_c('el-table',{attrs:{"data":_vm.SignList,"header-cell-style":{'background-color':'#F3F3F3','color':'#3A3A3A'},"row-style":_vm.rowStyle}},[_c('el-table-column',{attrs:{"prop":"name","label":"签名名称"}}),_c('el-table-column',{attrs:{"prop":"createDate","label":"创建时间"}}),_c('el-table-column',{attrs:{"label":"操作","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',[_c('div',{staticClass:"handleEditBtn",on:{"click":function($event){return _vm.toEdit(scope.row)}}},[_vm._v("编辑")]),_c('div',{staticClass:"handleDelBtn",on:{"click":function($event){return _vm.toDel(scope.row)}}},[_vm._v("删除")])])]}}],null,false,4003983739)})],1)],1):_vm._e(),(_vm.dialogVisible)?_c('div',[_c('div',{staticClass:"header"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.dialogTitle))])]),_c('el-input',{staticStyle:{"margin-bottom":"20px"},attrs:{"size":"small","placeholder":"签名名称"},model:{value:(_vm.submitForm.name),callback:function ($$v) {_vm.$set(_vm.submitForm, "name", $$v)},expression:"submitForm.name"}}),_c('Editor',{attrs:{"placeholder":"签名内容","init":{
            language: 'zh_CN',
            resize: false,
            height: 'calc(100vh - 380px)',
            branding: false,
            menubar: false,
            toolbar: 'undo redo | emoticons | image link | formatselect fontsizeselect forecolor | bold underline italic | alignleft aligncenter alignright | preview fullscreen insertVariable',
            plugins: 'emoticons image link preview fullscreen',
            paste_data_images: true,
            urlconverter_callback: _vm.urlconverter_callback,
            images_upload_handler: _vm.images_upload_handler},"tinymceScriptSrc":"tinymce/tinymce.min.js","apiKey":"l6y3tipsjmozdy96gs2qcbjjjaauujp07q4r0rpnyxqjh4ac"},model:{value:(_vm.submitForm.htmlContent),callback:function ($$v) {_vm.$set(_vm.submitForm, "htmlContent", $$v)},expression:"submitForm.htmlContent"}}),_c('div',{staticStyle:{"margin-top":"20px","display":"flex","justify-content":"flex-end"}},[_c('el-button',{attrs:{"size":"small","type":"primary","loading":_vm.btnLoading},on:{"click":_vm.onSubmit}},[_vm._v("确 认")]),_c('el-button',{attrs:{"size":"small"},on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v("取 消")])],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }