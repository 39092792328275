<script>
import Vue from 'vue'
import { reactive, ref, defineComponent } from '@vue/composition-api'
import axios from '@/assets/js/axios'
import dayjs from 'dayjs'

const _cache = {
  count: 0,
  followerOption: []
}

export default defineComponent({
  props: {
    stageKey: {
      type: String,
      default: ''
    },
    lockCustomer: {
      type: Boolean,
      default: false
    },
    customerName: {
      type: String,
      default: ''
    },
    customerKey: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const ukey = ref(JSON.parse(window.localStorage.getItem('userInfo')).uKey || '')
    const form = reactive({
      name: '',
      customer: '',
      amount: 0,
      currency: 'CNY',
      creator: {
        userName: JSON.parse(window.localStorage.getItem('userInfo')).userCnName,
        ukey: ukey.value,
        isAdmin: false,
        isCreate: true
      },
      follower: [],
      isFollowerEditing: false,
      isEndUnlimited: false,
      end: dayjs().add(7, 'days').format('YYYY-MM-DD')
    })
    const customerNameShow = ref('')
    if (props.lockCustomer) {
      form.customer = props.customerKey
      customerNameShow.value = props.customerName
    }
    const customerOption = ref([])
    const customerOptionLoading = ref(false)
    const followerOption = ref(_cache.followerOption)
    if ((_cache.count >= 5 || _cache.followerOption.length === 0) && !(_cache.count = 0)) {
      (async () => {
        try {
          const res = await axios({
            method: 'GET',
            url: Vue.prototype.$sjUrl + '/BusinessChance/GetMemebers',
          })
          _cache.followerOption = res.data
        } catch {
          _cache.followerOption = []
        } finally {
          followerOption.value = _cache.followerOption
        }
      })()
    }
    const currencyOptions = ref([]);
    (async () => {
      try {
        const res = await axios({
          method: 'GET',
          url: Vue.prototype.$customerUrl + '/v1/Config/GetCurrencyConfig'
        })
        currencyOptions.value = res.data
      } catch {
        currencyOptions.value = []
      }
    })()
    const handleCancelClick = () => {
      emit('cancel')
    }
    const handleConfirmClick = async () => {
      try {
        loading.value = true
        const res = await axios({
          method: 'POST',
          url: Vue.prototype.$sjUrl + '/BusinessChance/AddBusiness',
          data: {
            stageKey: props.stageKey,
            name: form.name,
            companyKey: form.customer,
            currency: form.currency,
            exchange: '',
            amount: form.amount,
            endTime: form.isEndUnlimited ? '' : dayjs(form.end).format('YYYY-MM-DD'),
            members: [form.creator, ...form.follower.map(item => ({
              userName: item.name,
              ukey: item.ukey,
              isAdmin: false,
              isCreate: false
            }))]
          }
        })
        if (!res.isSuccess) throw new Error()
        emit('done')
      } catch { null } finally { loading.value = false }
    }
    _cache.count++
    const getCustomerOption = async (value) => {
      try {
        customerOptionLoading.value = true
        const res = await axios({
          method: 'POST',
          url: Vue.prototype.$customerUrl + '/Company/Private',
          data: {
            pageIndex: 1,
            pageSize: 10,
            name: value || ''
          }
        })
        if (!res.isSuccess) throw new Error()
        customerOption.value = res.data.datas.map(item => ({ label: item.companyName, value: item.companyKey }))
      } catch {
        customerOption.value = []
      } finally {
        customerOptionLoading.value = false
      }
    }
    getCustomerOption()
    const disabledDate = (date) => {
      return dayjs().isAfter(dayjs(date))
    }
    return {
      loading,
      ukey,
      form,
      handleCancelClick,
      handleConfirmClick,
      customerOption,
      customerOptionLoading,
      followerOption,
      getCustomerOption,
      customerNameShow,
      currencyOptions,
      disabledDate
    }
  }
})
</script>

<template>
  <el-dialog :visible="true" width="360px" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
    <div class="new-card-dialog" v-loading="loading">
      <div class="top">
        <span>新增商机</span>
        <i @click="handleCancelClick" class="el-icon-close" style="cursor: pointer"></i>
      </div>
      <div class="body">
        <div class="item required">
          <span class="label">商机名称：</span>
          <el-input v-model.trim="form.name" size="small" placeholder="请输入" />
        </div>
        <div class="item required">
          <span class="label">公司名称：</span>
          <el-input v-if="$props.lockCustomer" v-model="customerNameShow" disabled size="small" />
          <el-select v-else v-model="form.customer" :loading="customerOptionLoading" filterable remote :remote-method="getCustomerOption" size="small" style="width: 100%" placeholder="请选择">
            <el-option v-for="item of customerOption" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </div>
        <div style="display: flex; align-items: center">
          <div class="item">
            <span class="label">货币：</span>
            <el-select filterable v-model="form.currency" size="small" placeholder="请选择" style="width: 100%">
              <el-option v-for="item of currencyOptions" :key="item.key" :value="item.currency" />
            </el-select>
          </div>
          <div class="item">
            <span class="label" style="width: auto; margin-left: 5px">金额：</span>
            <el-input-number class="input-number" v-model.trim="form.amount" size="small" placeholder="请输入" />
          </div>
        </div>
        <div class="item" style="min-height: 45px; height: auto">
          <span class="label">跟进人：</span>
          <div style="display: flex; align-items: center; flex-wrap: wrap; margin-top: -5px; padding-bottom: 5px">
            <div style="margin: 5px 5px 0 0; width: 26px; height: 26px; border-radius: 50%; background: var(--themeColor); font-size: 12px; color: #fff; display: flex; align-items: center; justify-content: center">{{ form.creator.userName.slice(0, 2) }}</div>
            <div v-for="(item, index) of form.follower" :key="index" style="margin: 5px 5px 0 0; width: 26px; height: 26px; border-radius: 50%; background: var(--themeColor); font-size: 12px; color: #fff; display: flex; align-items: center; justify-content: center">{{ item.name.slice(0, 2) }}</div>
            <i @click="form.isFollowerEditing = true" class="el-icon-circle-plus-outline" style="font-size: 28px; cursor: pointer; margin: 5px 5px 0 0"></i>
          </div>
        </div>
        <div v-show="form.isFollowerEditing" class="item" style="min-height: 45px; height: auto">
          <span class="label"></span>
          <el-select size="mini" v-model="form.follower" multiple value-key="ukey">
            <el-option v-for="item of followerOption" :key="item.ukey" :label="item.name" :value="item" :disabled="item.ukey === ukey" />
          </el-select>
          <el-tag @click="form.isFollowerEditing = false" size="mini" style="cursor: pointer">完成</el-tag>
        </div>
        <div class="item">
          <span class="label">结束日期：</span>
          <div style="display: flex; align-items: center; width: 100%">
            <!-- <el-checkbox v-model="form.isEndUnlimited" style="margin-right: 17px">不限</el-checkbox> -->
            <el-date-picker :picker-options="{ disabledDate }" :clearable="false" value-format="yyyy-MM-dd" :disabled="form.isEndUnlimited" v-model="form.end" style="width: 100%" size="small" />
          </div>
        </div>
      </div>
      <div class="foot">
        <el-button @click="handleConfirmClick" type="primary" size="small">创建</el-button>
        <el-button @click="handleCancelClick" size="small">取消</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<style scoped lang="scss">
::v-deep .el-dialog__header {
  display: none;
}
::v-deep .el-dialog__body {
  padding: 0;
}
.new-card-dialog {
  box-sizing: border-box;
  font-size: 16px;
}
.top {
  box-sizing: border-box;
  height: 60px;
  border-bottom: 1px solid #d9d9d9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #3a3a3a;
  padding: 0 20px;
}
.body {
  padding: 0 20px;
  font-size: 14px;
  color: #b7b9bc;
}
.item {
  box-sizing: border-box;
  height: 45px;
  display: flex;
  align-items: center;
  &.required {
    .label {
      &::before {
        display: inline;
        content: "*";
        color: #ff0000;
        position: absolute;
        left: -8px;
      }
    }
  }
  .label {
    position: relative;
    display: inline-block;
    overflow: visible;
    flex-shrink: 0;
    width: 70px;
  }
}
.input-number {
  width: 100%;
  ::v-deep .el-input-number__decrease,
  ::v-deep .el-input-number__increase {
    display: none;
  }
  ::v-deep .el-input__inner {
    padding-left: 15px;
    text-align: left;
  }
}
.foot {
  box-sizing: border-box;
  height: 60px;
  border-top: 1px solid #d9d9d9;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 20px;
}
</style>
