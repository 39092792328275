<template>
	<div>
		<div style="height: 60px; padding-left: 10px; display: flex; align-items: center; font-size: 14px; color: #707070">
			<span style="flex-shrink: 0">日期：</span>
			<el-date-picker type="daterange" @change="dateChange" v-model="form.date" value-format="yyyy-MM-dd" size="small" unlink-panels />
			<span style="flex-shrink: 0; margin-left: 20px;">组织：</span>
			<el-cascader class="c-el-cascader-wrapper" :show-all-levels="false" v-model="form.range" :options="belongOptions" :props="{ value: 'key', label: 'name', checkStrictly: true }" size="small" />
		</div>
		<el-row :gutter="16" v-loading="panelData.loading">
			<el-col :span="3">
				<div class="smallCard">
					<div class="tt">年商机总金额</div>
					<div class="profit utils-omit-1" style="width: 100%">{{ panelData['1'].amount }}</div>
					<div style="display: flex;border-top:1px solid #eee;padding-top:5px;">
						<div class="twoField" style="border-right:1px solid #eee;">
							<div class="value">{{ panelData['1'].count }}</div>
							<div class="label">数量</div>
						</div>
						<div class="twoField">
							<div class="value"> </div>
							<div class="label"> </div>
						</div>
					</div>
				</div>
			</el-col>
			<el-col :span="3">
				<div class="smallCard">
					<div class="tt">推进阶段</div>
					<div class="progress">
						<el-progress type="dashboard" :percentage="panelData['2'].percentage" color="#1989fa" :stroke-width="16"></el-progress>
					</div>
					<div style="display: flex;border-top:1px solid #eee;padding-top:5px;">
						<div class="twoField" style="border-right:1px solid #eee;">
							<div class="value">{{ panelData['2'].count }}</div>
							<div class="label">商机数</div>
						</div>
						<div class="twoField">
							<div class="value">{{ panelData['2'].amount }}</div>
							<div class="label">金额</div>
						</div>
					</div>
				</div>
			</el-col>
			<el-col :span="3">
				<div class="smallCard">
					<div class="tt">赢单阶段</div>
					<div class="progress">
						<el-progress type="dashboard" :percentage="panelData['3'].percentage" color="#1989fa" :stroke-width="16"></el-progress>
					</div>
					<div style="display: flex;border-top:1px solid #eee;padding-top:5px;">
						<div class="twoField" style="border-right:1px solid #eee;">
							<div class="value">{{ panelData['3'].count }}</div>
							<div class="label">商机数</div>
						</div>
						<div class="twoField">
							<div class="value">{{ panelData['3'].amount }}</div>
							<div class="label">金额</div>
						</div>
					</div>
				</div>
			</el-col>
			<el-col :span="3">
				<div class="smallCard">
					<div class="tt">输单阶段</div>
					<div class="progress">
						<el-progress type="dashboard" :percentage="panelData['4'].percentage" color="#1989fa" :stroke-width="16"></el-progress>
					</div>
					<div style="display: flex;border-top:1px solid #eee;padding-top:5px;">
						<div class="twoField" style="border-right:1px solid #eee;">
							<div class="value">{{ panelData['4'].count }}</div>
							<div class="label">商机数</div>
						</div>
						<div class="twoField">
							<div class="value">{{ panelData['4'].amount }}</div>
							<div class="label">金额</div>
						</div>
					</div>
				</div>
			</el-col>
			<el-col :span="6">
				<div class="smallCard">
					<div class="tt">商机收入</div>
					<div class="profit">{{ panelData['5'].data }}</div>
					<div style="display: flex;border-top:1px solid #eee;padding-top:5px;">
						<div class="twoField" style="border-right:1px solid #eee;">
							<div class="value"> </div>
							<div class="label"> </div>
						</div>
						<div class="twoField">
							<div class="value"> </div>
							<div class="label"> </div>
						</div>
					</div>
				</div>
			</el-col>
			<el-col :span="6">
				<div class="smallCard">
					<div class="tt">商机支出</div>
					<div class="profit">{{ panelData['6'].data }}</div>
					<div style="display: flex;border-top:1px solid #eee;padding-top:5px;">
						<div class="twoField" style="border-right:1px solid #eee;">
							<div class="value"> </div>
							<div class="label"> </div>
						</div>
						<div class="twoField">
							<div class="value"> </div>
							<div class="label"> </div>
						</div>
					</div>
				</div>
			</el-col>
		</el-row>
		<div class="grid-container" style="height: calc(100% - 292px)">
			<div class="grid-item-panel" v-loading="incomeCustomer.loading">
				<div class="grid-item-panel-title">
					<div style="display: flex; align-items: flex-end">
						<span class="grid-item-panel-title-text">商机阶段推进</span>
						<span class="grid-item-panel-title-sub-text" style="margin-left: 10px">{{ incomeCustomer.date }}</span>
					</div>
					<div class="icon-container">
						<img src="@/assets/icon/refresh.png" />
						<img src="@/assets/icon/close.png" />
					</div>
				</div>
				<div ref="incomeCustomer-dom" class="grid-item-panel-chart-dom"></div>
			</div>
			<div class="grid-item-panel" v-loading="incomeExpendCondition.loading">
				<div class="grid-item-panel-title">
					<div style="display: flex; align-items: flex-end">
						<span class="grid-item-panel-title-text">销售预测</span>
						<span class="grid-item-panel-title-sub-text" style="margin-left: 10px">{{ incomeExpendCondition.date }}</span>
					</div>
					<div class="icon-container">
						<img src="@/assets/icon/refresh.png" />
						<img src="@/assets/icon/close.png" />
					</div>
				</div>
				<div class="grid-item-panel-chart-dom" style="height: calc(100% - 90px)">
					<el-table :data="incomeExpendCondition.tableData" height="100%" size="mini">
						<el-table-column label="" prop="date" show-overflow-tooltip />
						<el-table-column label="进行中商机金额" prop="ongoingOpportunitiesAmount" show-overflow-tooltip />
						<el-table-column label="平均订单金额" prop="ongoingOpportunitiesAverageAmount" show-overflow-tooltip />
						<el-table-column label="赢单商机金额" prop="winOrderAmount" show-overflow-tooltip />
						<el-table-column label="总预测金额" prop="forecastAmount" show-overflow-tooltip />
					</el-table>
				</div>
				<div style="display: flex; justify-content: flex-end">
					<el-pagination :total="incomeExpendCondition._cache.length" :page-size="incomeExpendCondition.pagation.pageSize" :current-page.sync="incomeExpendCondition.pagation.pageIndex" @current-change="getYucePage" layout="total, jumper, prev, pager, next" background />
				</div>
			</div>
			<div class="grid-item-panel" v-loading="expendMoneyForm.loading">
				<div class="grid-item-panel-title">
					<div style="display: flex; align-items: flex-end">
						<span class="grid-item-panel-title-text">商机输单原因分析</span>
						<span class="grid-item-panel-title-sub-text" style="margin-left: 10px">{{ expendMoneyForm.date }}</span>
					</div>
					<div class="icon-container">
						<img src="@/assets/icon/refresh.png" />
						<img src="@/assets/icon/close.png" />
					</div>
				</div>
				<div ref="expendMoneyForm-dom" class="grid-item-panel-chart-dom"></div>
			</div>
			<div class="grid-item-panel" v-loading="fenbu.loading">
				<div class="grid-item-panel-title">
					<div style="display: flex; align-items: flex-end">
						<span class="grid-item-panel-title-text">商机分布</span>
						<span class="grid-item-panel-title-sub-text" style="margin-left: 10px">{{ fenbu.date }}</span>
					</div>
					<div class="icon-container">
						<img src="@/assets/icon/refresh.png" />
						<img src="@/assets/icon/close.png" />
					</div>
				</div>
				<div ref="fenbu-dom" class="grid-item-panel-chart-dom"></div>
			</div>
		</div>
	</div>
</template>

<script>
import * as echarts from "echarts";
import axios from '@/assets/js/axios'
import Vue from 'vue'
import { disposeTree } from '../Business/Index.vue'
import dayjs from 'dayjs'
export default Vue.extend({
	chartInstance: {
		incomeCustomer: null,
		expendMoneyForm: null,
		fenbu: null
	},
	data() {
		const date = [dayjs().subtract(90, 'days').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')];
		return {
			form: {
				range: [''],
				date,
			},
			belongOptions: [],
			panelData: {
				loading: false,
				'1': {},
				'2': {},
				'3': {},
				'4': {},
				'5': {},
				'6': {}
			},
			// 商机阶段推进
			incomeCustomer: {
				loading: false,
				date: ''
			},
			// 商机输单原因分析
			expendMoneyForm: {
				loading: false,
				date: ''
			},
			// 销售预测
			incomeExpendCondition: {
				loading: false,
				date: '',
				tableData: [],
				pagation: {
					pageIndex: 1,
					pageSize: 10,
				},
				_cache: []
			},
			// 商机分布
			fenbu: {
				loading: false,
				date: ''
			},
		};
	},
	watch: {

		'form.range'() {
			this.getPanelData()
			this.getTuijin()
			this.getYuce()
			this.getShudanyuanyin()
			this.getFenbu()
		}
	},
	methods: {
		async getPanelData() {
			try {
				this.panelData.loading = true
				const [startTime, endTime] = this.form.date || [];
				const organizationKey = this.form.range[this.form.range.length - 1]
				const res = await axios({
					method: 'POST',
					url: Vue.prototype.$customerUrl + '/Report/GetTopData',
					data: {
						startTime,
						endTime,
						organizationKey
					}
				})
				const data = res.data
				this.panelData = {
					'1': {
						count: data.totalOpportunity.count,
						amount: data.totalOpportunity.money
					},
					'2': {
						percentage: Number.parseFloat(data.stageAmount.proportion),
						count: data.stageAmount.count,
						amount: data.stageAmount.money
					},
					'3': {
						percentage: Number.parseFloat(data.winAmount.proportion),
						count: data.winAmount.count,
						amount: data.winAmount.money
					},
					'4': {
						percentage: Number.parseFloat(data.deliveryOrderAmount.proportion),
						count: data.deliveryOrderAmount.count,
						amount: data.deliveryOrderAmount.money
					},
					'5': {
						data: data.opportunityRevenue
					},
					'6': {
						data: data.opportunityExpenditure
					}
				}
			} catch {
				null
			} finally {
				this.panelData.loading = false
			}
		},
		async getTuijin() {
			try {
				this.incomeCustomer.loading = true
				const [startTime, endTime] = this.form.date || [];
				const organizationKey = this.form.range[this.form.range.length - 1]
				const res = await axios({
					method: 'POST',
					url: Vue.prototype.$customerUrl + '/Report/GetOpportunityStage',
					data: {
						startTime,
						endTime,
						organizationKey
					}
				})
				const data = res.data
				this.$options.chartInstance.incomeCustomer.setOption({
					series: [
						{
							name: "商机阶段推进",
							data: data.map(item => ({
								name: item.name,
								value: item.count,
								amount: item.amount
							}))
						}
					]
				});
				this.incomeCustomer.date = this.form.date.join(' —— ')
			} catch {
				null
			} finally {
				this.incomeCustomer.loading = false
			}
		},
		async getYuce() {
			try {
				this.incomeExpendCondition.loading = true
				const [startTime, endTime] = this.form.date || [];
				const organizationKey = this.form.range[this.form.range.length - 1]
				const res = await axios({
					method: 'POST',
					url: Vue.prototype.$customerUrl + '/Report/GetForecast',
					data: {
						startTime,
						endTime,
						organizationKey
					}
				})
				const data = res.data
				this.incomeExpendCondition._cache = data
				this.incomeExpendCondition.date = this.form.date.join(' —— ')
				this.incomeExpendCondition.pagation.pageIndex = 1
				this.getYucePage(this.incomeExpendCondition.pagation.pageIndex)
			} catch {
				null
			} finally {
				this.incomeExpendCondition.loading = false
			}
		},
		getYucePage(pageIndex) {
			const s = (pageIndex - 1) * this.incomeExpendCondition.pagation.pageSize
			const e = pageIndex * this.incomeExpendCondition.pagation.pageSize
			this.incomeExpendCondition.tableData = this.incomeExpendCondition._cache.slice(s, e)
		},
		async getShudanyuanyin() {
			try {
				this.expendMoneyForm.loading = true
				const [startTime, endTime] = this.form.date || [];
				const organizationKey = this.form.range[this.form.range.length - 1]
				const res = await axios({
					method: 'POST',
					url: Vue.prototype.$customerUrl + '/Report/GetDeliveryOrderReason',
					data: {
						startTime,
						endTime,
						organizationKey
					}
				})
				const data = res.data
				this.$options.chartInstance.expendMoneyForm.setOption({
					series: [
						{
							name: "商机输单原因分析",
							data: data.map(item => ({
								name: item.name,
								value: item.count
							}))
						},
					]
				});
				this.expendMoneyForm.date = this.form.date.join(' —— ')
			} catch {
				null
			} finally {
				this.expendMoneyForm.loading = false
			}
		},
		async getFenbu() {
			try {
				this.fenbu.loading = true
				const [startTime, endTime] = this.form.date || [];
				const organizationKey = this.form.range[this.form.range.length - 1]
				const res = await axios({
					method: 'POST',
					url: Vue.prototype.$customerUrl + '/Report/GetOpportunityDistribution',
					data: {
						startTime,
						endTime,
						organizationKey
					}
				})
				const data = res.data
				this.$options.chartInstance.fenbu.setOption({
					series: [
						{
							name: "商机分布",
							data: data.map(item => ({
								name: item.name,
								value: item.count
							}))
						},
					]
				});
				this.fenbu.date = this.form.date.join(' —— ')
			} catch {
				null
			} finally {
				this.fenbu.loading = false
			}
		},

        dateChange(val){
            if (val) {
				this.getPanelData()
				this.getTuijin()
				this.getYuce()
				this.getShudanyuanyin()
				this.getFenbu()
                localStorage.setItem("timeRange",val);
			}
        }
	},
	mounted() {
        //获取上次选择的时间段
        if(localStorage.timeRange){
            this.form.date = localStorage.timeRange.split(',');
        }

		axios({
			method: 'GET',
			url: Vue.prototype.$personnelUrl + '/Organization/FindOrganizationDrop'
		}).then(res => {
			const data = res.data
			data.unshift({
				key: '',
				name: '我的',
				children: []
			})
			for (const item of data) {
				disposeTree(item)
			}
			this.belongOptions = data
		})
		this.getPanelData()
		this.getTuijin()
		this.getYuce()
		this.getShudanyuanyin()
		this.getFenbu()
		this.$options.chartInstance.incomeCustomer = echarts.init(
			this.$refs["incomeCustomer-dom"]
		);
		this.$options.chartInstance.incomeCustomer.setOption({
			legend: {
				orient: "vertical",
				top: "center",
				right: 100,
				textStyle: {
					color: "#7C90A5",
				},
				itemWidth: 14,
				itemHeight: 14,
				icon: "circle",
			},
			tooltip: {
				formatter(param) {
					const { name, value, amount } = param.data
					return `
						<p>${name}</p>
						<p>订单数：${value} 金额：${amount}</p>
					`
				}
			},
			series: [
				{
					name: "商机阶段推进",
					type: "funnel",
					left: 110,
					right: 200,
					label: {
						show: true,
						position: "inside",
						color: "#fff",
						fontWeight: "bold",
						fontSize: 16,
					},
					sort: "none",
					data: []
				},
			],
			color: ["#2560A3", "#8897C0", "#DC7683", "#DFD982", "#9BC586", "#67A0C8", "#62D0D3"],
		});
		this.$options.chartInstance.expendMoneyForm = echarts.init(
			this.$refs["expendMoneyForm-dom"]
		);
		this.$options.chartInstance.expendMoneyForm.setOption({
			legend: {
				top: "middle",
				type: "scroll",
				orient: "vertical",
				right: "5%",
				icon: "circle",
				textStyle: {
					color: "#7C90A5",
				},
				itemWidth: 14,
				itemHeight: 14,
			},
			tooltip: {},
			series: [
				{
					name: "商机输单原因分析",
					type: "pie",
					radius: ["30%", "70%"],
					center: ["40%", "50%"],
					label: {
						show: true,
						color: "#94979C",
					},
					labelLine: {
						smooth: true,
						lineStyle: {
							color: "#94979C",
						},
					},
					emphasis: {
						scale: false,
					},
					data: [],
				},
			],
			color: [
				"#62D0D3",
				"#67A0C8",
				"#9BC586",
				"#DFD982",
				"#DC7683",
				"#8897C0",
				"#2560A3",
			],
		});
		this.incomeExpendCondition.tableData = [];
		this.$options.chartInstance.fenbu = echarts.init(
			this.$refs["fenbu-dom"]
		);
		this.$options.chartInstance.fenbu.setOption({
			legend: {
				top: "middle",
				type: "scroll",
				orient: "vertical",
				right: "5%",
				icon: "circle",
				textStyle: {
					color: "#7C90A5",
				},
				itemWidth: 14,
				itemHeight: 14
			},
			tooltip: {},
			series: [
				{
					name: "商机分布",
					type: "pie",
					radius: ["30%", "70%"],
					center: ["40%", "50%"],
					label: {
						show: true,
						color: "#94979C",
					},
					labelLine: {
						smooth: true,
						lineStyle: {
							color: "#94979C",
						},
					},
					emphasis: {
						scale: false,
					},
					data: []
				},
			],
			color: [
				"#62D0D3",
				"#67A0C8",
				"#9BC586",
				"#DFD982",
				"#DC7683",
				"#8897C0",
				"#2560A3",
			],
		});
	}
})
</script>

<style lang="scss" scoped>
.smallCard {
	background-color: #fff;
	padding: 10px;
	height: 200px;

	.tt {
		font-weight: bold;
		font-size: 18px;
	}

	.profit {
		font-size: 30px;
		line-height: 130px;
		text-align: center;
	}

	.oneField {
		width: 50%;
		height: 32px;
		line-height: 32px;
		padding: 0 5px;
		font-size: 14px;

		.label {
			padding-right: 10px;
			color: #9a9a9a;
		}
	}

	.progress {
		margin: auto;
		text-align: center;
	}

	.twoField {
		width: 50%;
		text-align: center;
		font-size: 14px;

		.label {
			color: #9a9a9a;
		}
	}
}

.grid-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.grid-item-panel {
	width: calc(50% - 10px);
	margin-top: 20px;
	position: relative;
	height: 380px;
	background: #fff;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

$grid-item-panel-title-height: 42px;

.grid-item-panel-title {
	box-sizing: border-box;
	height: $grid-item-panel-title-height;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 14px 0 20px;

	&.bb {
		border-bottom: 1px solid #e6e7eb;
	}
}

.grid-item-panel-title-text {
	font-size: 18px;
	color: #1b2841;
	font-weight: bold;
}

.grid-item-panel-title-sub-text {
	font-size: 14px;
	color: #c7c7c7;
}

.icon-container {
	display: flex;
	align-items: center;

	img {
		cursor: pointer;

		&:last-child {
			margin-left: 10px;
		}
	}
}

.grid-item-select {
	position: absolute;
	z-index: 2;
	left: 20px;
	top: $grid-item-panel-title-height;
	border: 1px solid #c7c7c7;
	border-radius: 2px;
	width: 80px;
	font-size: 14px;
	color: #7c90a5;
	cursor: pointer;
	background: #fff;
}

.grid-item-select-option {
	height: 28px;
	display: flex;
	align-items: center;
	justify-content: center;

	&:hover,
	&.active {
		background: var(--themeColor);
		color: #fff;
	}
}

.grid-item-panel-chart-dom {
	box-sizing: border-box;
	height: calc(100% - #{$grid-item-panel-title-height});
}
</style>
