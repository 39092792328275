<template>
    <div>
        <div style="height: 60px; padding-left: 10px; display: flex; align-items: center; font-size: 14px; color: #707070">
            <span style="flex-shrink: 0">日期：</span>
            <el-date-picker type="daterange" @change="dateChange" v-model="form.date" value-format="yyyy-MM-dd" size="small" unlink-panels />
        </div>
        <div class="grid-container" style="height: calc(100% - 60px)">
            <div class="grid-item-panel" style="width: 100%;" v-loading="performanceAnalysis.loading">
                <div class="grid-item-panel-title bb">
                    <div style="display: flex; align-items: center;">
                        <span class="grid-item-panel-title-text">业绩分析</span>
                        <span class="grid-item-panel-title-sub-text" style="margin-left: 10px">
                            <el-select v-model="filterYear" placeholder="年份" size="small" style="width:100px;" @change="getPerformanceAnalysis">
                                <el-option label="2021年" value="2021"></el-option>
                                <el-option label="2022年" value="2022"></el-option>
                                <el-option label="2023年" value="2023"></el-option>
                            </el-select>
                            <el-select v-model="filterMonth" placeholder="月份" size="small" style="width:100px;" @change="getPerformanceAnalysis">
                                <el-option label="1月" value="1"></el-option>
                                <el-option label="2月" value="2"></el-option>
                                <el-option label="3月" value="3"></el-option>
                                <el-option label="4月" value="4"></el-option>
                                <el-option label="5月" value="5"></el-option>
                                <el-option label="6月" value="6"></el-option>
                                <el-option label="7月" value="7"></el-option>
                                <el-option label="8月" value="8"></el-option>
                                <el-option label="9月" value="9"></el-option>
                                <el-option label="10月" value="10"></el-option>
                                <el-option label="11月" value="11"></el-option>
                                <el-option label="12月" value="12"></el-option>
                            </el-select>
                        </span>
                    </div>
                    <div class="icon-container">
                        <img src="@/assets/icon/refresh.png" />
                        <img src="@/assets/icon/close.png" />
                    </div>
                </div>
                <div class="grid-item-panel-chart-dom">
                    <el-table class="custom-el-table" :data="performanceAnalysis.tableData" height="calc(100% - 20px)">
                        <el-table-column label="#" type="index" width="50" align="center"></el-table-column>
                        <el-table-column label="月份" prop="month" show-overflow-tooltip width="50" />
                        <el-table-column label="员工" prop="userName" show-overflow-tooltip />
                        <el-table-column label="总金额" prop="totalPrice" show-overflow-tooltip />
                        <el-table-column label="单数" prop="orderNum" show-overflow-tooltip />
                        <el-table-column label="均单价" prop="avgPrice" show-overflow-tooltip />
                        <el-table-column label="最大单价" prop="maxPrice" show-overflow-tooltip />
                        <el-table-column label="最近成交日期" prop="lastDate" show-overflow-tooltip />
                        <el-table-column label="最近成交金额" prop="lastPrice" show-overflow-tooltip />
                        <el-table-column label="未开单天数" prop="notBilledDay" show-overflow-tooltip />
                    </el-table>
                </div>
            </div>

            <div class="grid-item-panel" v-loading="genjinjilukehu.loading">
                <div class="grid-item-panel-title bb">
                    <div style="display: flex; align-items: flex-end">
                        <span class="grid-item-panel-title-text">跟进记录-客户</span>
                        <span class="grid-item-panel-title-sub-text" style="margin-left: 10px">{{ genjinjilukehu.date }}</span>
                    </div>
                    <div class="icon-container">
                        <img src="@/assets/icon/refresh.png" />
                        <img src="@/assets/icon/close.png" />
                    </div>
                </div>
                <div class="grid-item-panel-chart-dom">
                    <el-table class="custom-el-table" :data="genjinjilukehu.tableData" height="calc(100% - 20px)">
                        <el-table-column label="#" prop="order" width="50px" align="center" />
                        <el-table-column label="员工" prop="staff" width="80px" show-overflow-tooltip />
                        <el-table-column label="快速记录" prop="quick" show-overflow-tooltip />
                        <el-table-column label="电话" prop="phone" show-overflow-tooltip />
                        <el-table-column label="会面" prop="meet" show-overflow-tooltip />
                        <el-table-column label="日程跟进" prop="schedule" show-overflow-tooltip />
                        <el-table-column label="报价" prop="quote" show-overflow-tooltip />
                        <el-table-column label="销售订单" prop="sale" show-overflow-tooltip />
                    </el-table>
                </div>
            </div>
            <div class="grid-item-panel" v-loading="gongzuoliangtongji.loading">
                <div class="grid-item-panel-title bb">
                    <div style="display: flex; align-items: flex-end">
                        <span class="grid-item-panel-title-text">工作量统计</span>
                        <span class="grid-item-panel-title-sub-text" style="margin-left: 10px">{{ gongzuoliangtongji.date }}</span>
                    </div>
                    <div class="icon-container">
                        <img src="@/assets/icon/refresh.png" />
                        <img src="@/assets/icon/close.png" />
                    </div>
                </div>
                <div class="grid-item-panel-chart-dom">
                    <el-table class="custom-el-table" :data="gongzuoliangtongji.tableData" height="calc(100% - 20px)">
                        <el-table-column label="#" prop="order" width="50px" align="center" />
                        <el-table-column label="员工" prop="staff" width="80px" show-overflow-tooltip />
                        <el-table-column label="新建客户数" prop="customer" show-overflow-tooltip />
                        <el-table-column label="新建跟进" prop="follow" show-overflow-tooltip />
                        <el-table-column label="新建报价" prop="quote" show-overflow-tooltip />
                        <el-table-column label="新建订单数" prop="sale" show-overflow-tooltip />
                        <el-table-column label="已成交客户数" prop="deal" show-overflow-tooltip />
                        <el-table-column label="私海客户数" prop="self" show-overflow-tooltip />
                    </el-table>
                </div>
            </div>

            <div class="grid-item-panel" v-loading="kehuguanjianxingwei.loading">
                <div class="grid-item-panel-title bb">
                    <div style="display: flex; align-items: flex-end">
                        <span class="grid-item-panel-title-text">客户-关键行为</span>
                        <span class="grid-item-panel-title-sub-text" style="margin-left: 10px">{{ kehuguanjianxingwei.date }}</span>
                    </div>
                    <div class="icon-container">
                        <img src="@/assets/icon/refresh.png" />
                        <img src="@/assets/icon/close.png" />
                    </div>
                </div>
                <div class="grid-item-panel-chart-dom">
                    <el-table class="custom-el-table" :data="kehuguanjianxingwei.tableData" height="calc(100% - 20px)">
                        <el-table-column label="#" prop="order" width="50px" align="center" />
                        <el-table-column label="员工" prop="staff" show-overflow-tooltip />
                        <el-table-column label="新建客户数" prop="new" show-overflow-tooltip />
                        <el-table-column label="跟进客户数" prop="follow" show-overflow-tooltip />
                        <el-table-column label="公海获取客户数" prop="get" show-overflow-tooltip />
                        <el-table-column label="释放到公海客户数" prop="release" show-overflow-tooltip />
                    </el-table>
                </div>
            </div>
            <div class="grid-item-panel" v-loading="yuangongguanjianxingwei.loading">
                <div class="grid-item-select" style="margin-top: 12px" v-if="yuangongguanjianxingwei.options.length !== 0">
                    <div @click="setTypeOrKey('yuangongguanjianxingwei', 'activeKey', item.key)" class="grid-item-select-option" :class="{ active: yuangongguanjianxingwei.activeKey === item.key }" v-for="item of yuangongguanjianxingwei.options" :key="item.key">{{ item.name }}</div>
                </div>
                <div class="grid-item-panel-title bb">
                    <div style="display: flex; align-items: flex-end">
                        <span class="grid-item-panel-title-text">员工关键行为</span>
                    </div>
                    <div class="icon-container">
                        <img src="@/assets/icon/refresh.png" />
                        <img src="@/assets/icon/close.png" />
                    </div>
                </div>
                <div ref="yuangongguanjianxingwei-dom" class="grid-item-panel-chart-dom"></div>
            </div>

            <div class="grid-item-panel" v-loading="yuangongdingdanjianyuepaiming.loading">
                <div class="grid-item-select" style="margin-top: 12px">
                    <div @click="setTypeOrKey('yuangongdingdanjianyuepaiming', 'type', '0')" class="grid-item-select-option" :class="{ active: yuangongdingdanjianyuepaiming.type === '0' }">签约金额</div>
                    <div @click="setTypeOrKey('yuangongdingdanjianyuepaiming', 'type', '1')" class="grid-item-select-option" :class="{ active: yuangongdingdanjianyuepaiming.type === '1' }">签单数</div>
                </div>
                <div class="grid-item-panel-title bb">
                    <div style="display: flex; align-items: flex-end">
                        <span class="grid-item-panel-title-text">员工订单签约排名</span>
                        <span class="grid-item-panel-title-sub-text" style="margin-left: 10px">{{ yuangongdingdanjianyuepaiming.date }}</span>
                    </div>
                    <div class="icon-container">
                        <img src="@/assets/icon/refresh.png" />
                        <img src="@/assets/icon/close.png" />
                    </div>
                </div>
                <div class="grid-item-panel-chart-dom" style="padding: 0 90px 0 110px">
                    <el-table class="custom-el-table-rank" :data="yuangongdingdanjianyuepaiming.tableData" :show-header="false" height="calc(100% - 20px)">
                        <el-table-column width="50px" align="center">
                            <template slot-scope="{ row }">
                                <img v-if="row.rankSrc" :src="row.rankSrc" />
                                <span v-else>{{ row.rank }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="name" />
                        <el-table-column align="right">
                            <template slot-scope="{ row }">
                                <span>{{ row.amount }}</span>
                                <span style="color: #7c90a5; margin-left: 9px">万元</span>
                            </template>
                        </el-table-column>
                        <el-table-column width="40px" align="center">
                            <template slot-scope="{ row }">
                                <span v-if="row.status === '-'">{{ row.status }}</span>
                                <img v-else :src="row.status" />
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <div class="grid-item-panel" v-loading="yuangongdingdanjianyuequshi.loading">
                <div class="grid-item-select" style="margin-top: 12px" v-if="yuangongdingdanjianyuequshi.options.length !== 0">
                    <div @click="setTypeOrKey('yuangongdingdanjianyuequshi', 'activeKey', item.key)" class="grid-item-select-option" :class="{ active: yuangongdingdanjianyuequshi.activeKey === item.key }" v-for="item of yuangongdingdanjianyuequshi.options" :key="item.key">{{ item.name }}</div>
                </div>
                <div class="grid-item-panel-title bb">
                    <div style="display: flex; align-items: flex-end">
                        <span class="grid-item-panel-title-text">员工订单签约趋势</span>
                    </div>
                    <div class="icon-container">
                        <img src="@/assets/icon/refresh.png" />
                        <img src="@/assets/icon/close.png" />
                    </div>
                </div>
                <div ref="yuangongdingdanjianyuequshi-dom" class="grid-item-panel-chart-dom"></div>
            </div>
        </div>
    </div>
</template>

<script>
import * as echarts from "echarts";
import dayjs from "dayjs";
const medals = [
    require("@/assets/icon/medal-1.png"),
    require("@/assets/icon/medal-2.png"),
    require("@/assets/icon/medal-3.png"),
];
const arrow = [
    "-",
    require("@/assets/icon/arrow-increase.png"),
    require("@/assets/icon/arrow-decrease.png"),
];

export default {
    chartInstance: {
        yuangongguanjianxingwei: null,
        yuangongdingdanjianyuequshi: null,
    },
    data() {
        const date = [
            dayjs().subtract(90, "days").format("YYYY-MM-DD"),
            dayjs().format("YYYY-MM-DD"),
        ];
        return {
            form: { date },
            filterYear: dayjs().year().toString(),
            filterMonth: (dayjs().month() + 1).toString(),
            performanceAnalysis: {
                loading: false,
                tableData: [],
            },

            genjinjilukehu: {
                loading: false,
                date: date.join(" —— "),
                tableData: [],
            },
            gongzuoliangtongji: {
                loading: false,
                date: date.join(" —— "),
                tableData: [],
            },

            kehuguanjianxingwei: {
                loading: false,
                date: date.join(" —— "),
                tableData: [],
            },
            yuangongguanjianxingwei: {
                loading: false,
                options: [],
                activeKey: "",
            },

            yuangongdingdanjianyuepaiming: {
                loading: false,
                date: date.join(" —— "),
                type: "0",
                tableData: [],
            },
            yuangongdingdanjianyuequshi: {
                loading: false,
                options: [],
                activeKey: "",
            },
        };
    },
    watch: {
        "yuangongguanjianxingwei.activeKey"() {
            this.getYuangongguanjianxingwei();
        },
        "yuangongdingdanjianyuepaiming.type"() {
            this.getYuangongdingdanjianyuepaiming();
        },
        "yuangongdingdanjianyuequshi.activeKey"() {
            this.getYuangongdingdanjianyuequshi();
        },
    },
    methods: {
        setTypeOrKey(mod, key, value) {
            this[mod][key] = value;
        },

        async getPerformanceAnalysis() {
            try {
                this.performanceAnalysis.loading = true;
                const res = await this.$axios({
                    method: "POST",
                    url: this.$customerUrl + "/Report/GetPerformanceAnalysis",
                    data: {
                        year: this.filterYear,
                        month: this.filterMonth,
                        organizationKey: "",
                    },
                });
                this.performanceAnalysis.tableData = res.data;
            } catch {
                null;
            } finally {
                this.performanceAnalysis.loading = false;
            }
        },

        async getGenjinjilukehu() {
            const [startDate, endDate] = this.form.date || [];
            if (!(startDate && endDate)) {
                return;
            }
            try {
                this.genjinjilukehu.loading = true;
                const res = await this.$axios({
                    method: "GET",
                    url: this.$customerUrl + "/Report/FollowRecordCustomers",
                    params: {
                        startDate,
                        endDate,
                    },
                });
                const data = res.data;
                this.genjinjilukehu.tableData = data.map((item, index) => ({
                    order: index + 1,
                    staff: item.name,
                    quick: item.followNum,
                    phone: item.phoneNum,
                    meet: item.meetNum,
                    schedule: item.scheduleFollowNum,
                    quote: item.offerNum,
                    sale: item.orderNum,
                }));
                this.genjinjilukehu.date = this.form.date.join(" —— ");
            } catch {
                null;
            } finally {
                this.genjinjilukehu.loading = false;
            }
        },
        async getGongzuoliangtongji() {
            const [startDate, endDate] = this.form.date || [];
            if (!(startDate && endDate)) {
                return;
            }
            try {
                this.gongzuoliangtongji.loading = true;
                const res = await this.$axios({
                    method: "GET",
                    url: this.$customerUrl + "/Report/WorkloadStatis",
                    params: {
                        startDate,
                        endDate,
                    },
                });
                const data = res.data;
                this.gongzuoliangtongji.tableData = data.map((item, index) => ({
                    order: index + 1,
                    staff: item.userCnName,
                    customer: item.newCustomer,
                    follow: item.newFollow,
                    quote: item.newOffer,
                    sale: item.newOrder,
                    deal: item.dealCustomer,
                    self: item.privateCustomer,
                }));
                this.gongzuoliangtongji.date = this.form.date.join(" —— ");
            } catch {
                null;
            } finally {
                this.gongzuoliangtongji.loading = false;
            }
        },

        async getKehuguanjianxingwei() {
            const [startDate, endDate] = this.form.date || [];
            if (!(startDate && endDate)) {
                return;
            }
            try {
                this.kehuguanjianxingwei.loading = true;
                const res = await this.$axios({
                    method: "GET",
                    url: this.$customerUrl + "/Report/KeyBehaviorsCustomer",
                    params: {
                        startDate,
                        endDate,
                    },
                });
                const data = res.data;
                this.kehuguanjianxingwei.tableData = data.map(
                    (item, index) => ({
                        order: index + 1,
                        staff: item.userCnName,
                        new: item.newCustomer,
                        follow: item.followCustomer,
                        get: item.publicCustomer,
                        release: item.publicCustomer,
                    })
                );
                this.kehuguanjianxingwei.date = this.form.date.join(" —— ");
            } catch {
                null;
            } finally {
                this.kehuguanjianxingwei.loading = false;
            }
        },
        async getYuangongguanjianxingwei() {
            try {
                this.yuangongguanjianxingwei.loading = true;
                const res = await this.$axios({
                    method: "GET",
                    url: this.$customerUrl + "/Report/StaffKeyBehaviors",
                    params: {
                        ukey: this.yuangongguanjianxingwei.activeKey || "",
                    },
                });
                const data = res.data;
                if (!this.yuangongguanjianxingwei.options.length) {
                    this.yuangongguanjianxingwei.options = data.staffs;
                }
                this.$options.chartInstance.yuangongguanjianxingwei.setOption({
                    xAxis: {
                        data: data.month,
                    },
                    series: data.series,
                });
            } catch {
                null;
            } finally {
                this.yuangongguanjianxingwei.loading = false;
            }
        },

        async getYuangongdingdanjianyuepaiming() {
            const [startDate, endDate] = this.form.date || [];
            if (!(startDate && endDate)) {
                return;
            }
            try {
                this.yuangongdingdanjianyuepaiming.loading = true;
                const url = ["/Report/ContractedAmount", "/Report/OrderRanks"];
                const res = await this.$axios({
                    method: "GET",
                    url:
                        this.$customerUrl +
                        url[+this.yuangongdingdanjianyuepaiming.type],
                    params: {
                        startDate,
                        endDate,
                    },
                });
                const data = res.data;
                this.yuangongdingdanjianyuepaiming.tableData = data.map(
                    (item) => ({
                        key: item.key,
                        rank: item.rank,
                        rankSrc: medals[+item.rank - 1],
                        name: item.name,
                        amount: item.amount,
                        status: arrow[+item.status],
                    })
                );
                this.yuangongdingdanjianyuepaiming.date =
                    this.form.date.join(" —— ");
            } catch {
                null;
            } finally {
                this.yuangongdingdanjianyuepaiming.loading = false;
            }
        },
        async getYuangongdingdanjianyuequshi() {
            try {
                this.yuangongdingdanjianyuequshi.loading = true;
                const res = await this.$axios({
                    method: "GET",
                    url: this.$customerUrl + "/Report/StaffOrderTrend",
                    params: {
                        ukey: this.yuangongdingdanjianyuequshi.activeKey || "",
                    },
                });
                const data = res.data;
                if (!this.yuangongdingdanjianyuequshi.options.length) {
                    this.yuangongdingdanjianyuequshi.options = data.staffs;
                }
                this.$options.chartInstance.yuangongdingdanjianyuequshi.setOption(
                    {
                        xAxis: {
                            data: data.month,
                        },
                        series: data.series,
                    }
                );
            } catch {
                null;
            } finally {
                this.yuangongdingdanjianyuequshi.loading = false;
            }
        },

        dateChange(val) {
            if (val) {
                this.getGenjinjilukehu();
                this.getGongzuoliangtongji();
                this.getKehuguanjianxingwei();
                this.getYuangongdingdanjianyuepaiming();
                localStorage.setItem("timeRange", val);
            }
        },
    },
    mounted() {
        //获取上次选择的时间段
        if (localStorage.timeRange) {
            this.form.date = localStorage.timeRange.split(",");
        }
        //员工业绩分析
        this.getPerformanceAnalysis();

        this.$options.chartInstance.yuangongguanjianxingwei = echarts.init(
            this.$refs["yuangongguanjianxingwei-dom"]
        );
        this.$options.chartInstance.yuangongguanjianxingwei.setOption({
            legend: {
                top: 5,
                right: 30,
                icon: "circle",
                itemWidth: 8,
                itemHeight: 8,
                itemStyle: {
                    opacity: 1,
                },
                itemGap: 20,
            },
            grid: {
                containLabel: true,
                top: 40,
                left: 115,
                right: 30,
                bottom: 20,
            },
            xAxis: {
                axisLine: {
                    lineStyle: {
                        color: "#BFBFBF",
                    },
                },
                axisTick: {
                    alignWithLabel: true,
                },
                axisLabel: {
                    color: "#595959",
                },
                data: [],
            },
            yAxis: {
                axisLabel: {
                    color: "#595959",
                },
                splitLine: {
                    lineStyle: {
                        color: "#E7E7E7",
                        type: "dashed",
                    },
                },
            },
            tooltip: {
                trigger: "axis",
            },
            series: [
                {
                    name: "新建客户数",
                    type: "line",
                    itemStyle: {
                        opacity: 0,
                    },
                    data: [],
                },
                {
                    name: "跟进客户数",
                    type: "line",
                    itemStyle: {
                        opacity: 0,
                    },
                    data: [],
                },
                {
                    name: "公海获取",
                    type: "line",
                    itemStyle: {
                        opacity: 0,
                    },
                    data: [],
                },
                {
                    name: "释放到公海",
                    type: "line",
                    itemStyle: {
                        opacity: 0,
                    },
                    data: [],
                },
            ],
            color: ["#2560A3", "#62D0D3", "#DFD982", "#67A0C8"],
        });
        this.$options.chartInstance.yuangongdingdanjianyuequshi = echarts.init(
            this.$refs["yuangongdingdanjianyuequshi-dom"]
        );
        this.$options.chartInstance.yuangongdingdanjianyuequshi.setOption({
            legend: {
                top: 5,
                right: 30,
                icon: "circle",
                itemWidth: 8,
                itemHeight: 8,
                itemStyle: {
                    opacity: 1,
                },
                itemGap: 20,
            },
            grid: {
                containLabel: true,
                top: 40,
                left: 115,
                right: 30,
                bottom: 20,
            },
            xAxis: {
                axisLine: {
                    lineStyle: {
                        color: "#BFBFBF",
                    },
                },
                axisTick: {
                    alignWithLabel: true,
                },
                axisLabel: {
                    color: "#595959",
                },
                data: [],
            },
            yAxis: {
                axisLabel: {
                    color: "#595959",
                },
                splitLine: {
                    lineStyle: {
                        color: "#E7E7E7",
                        type: "dashed",
                    },
                },
            },
            tooltip: {
                trigger: "axis",
            },
            series: [
                {
                    name: "签单金额",
                    type: "line",
                    itemStyle: {
                        opacity: 0,
                    },
                    data: [],
                },
            ],
            color: ["#2560A3"],
        });

        this.getGenjinjilukehu();
        this.getGongzuoliangtongji();

        this.getKehuguanjianxingwei();
        this.getYuangongguanjianxingwei();

        this.getYuangongdingdanjianyuepaiming();
        this.getYuangongdingdanjianyuequshi();
    },
};
</script>

<style lang="scss" scoped>
.grid-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: -20px;
}
.grid-item-panel {
    width: calc(50% - 10px);
    margin-top: 20px;
    position: relative;
    height: 380px;
    background: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}
$grid-item-panel-title-height: 42px;
.grid-item-panel-title {
    box-sizing: border-box;
    height: $grid-item-panel-title-height;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 14px 0 20px;
    &.bb {
        border-bottom: 1px solid #e6e7eb;
    }
}
.grid-item-panel-title-text {
    font-size: 18px;
    color: #1b2841;
    font-weight: bold;
}
.grid-item-panel-title-sub-text {
    font-size: 14px;
    color: #c7c7c7;
}
.icon-container {
    display: flex;
    align-items: center;
    img {
        cursor: pointer;
        &:last-child {
            margin-left: 10px;
        }
    }
}
.grid-item-select {
    max-height: calc(100% - #{$grid-item-panel-title-height} - 30px);
    overflow-y: auto;
    position: absolute;
    z-index: 2;
    left: 20px;
    top: $grid-item-panel-title-height;
    border: 1px solid #c7c7c7;
    border-radius: 2px;
    width: 80px;
    font-size: 14px;
    color: #7c90a5;
    cursor: pointer;
    background: #fff;
}
.grid-item-select-option {
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover,
    &.active {
        background: var(--themeColor);
        color: #fff;
    }
}
.grid-item-panel-chart-dom {
    box-sizing: border-box;
    height: calc(100% - #{$grid-item-panel-title-height});
}
.custom-el-table {
    &.el-table::before {
        height: 0 !important;
        background: none !important;
    }
    font-size: 14px;
    ::v-deep .el-table__cell {
        height: 30px;
        padding: 0;
        border: none;
    }
    ::v-deep thead .el-table__cell {
        color: #3a3a3a;
        font-weight: normal;
    }
    ::v-deep tbody .el-table__cell {
        color: #1a2745;
        font-weight: normal;
    }
    ::v-deep th {
        border: none !important;
    }
    ::v-deep tbody tr {
        &:nth-child(odd) {
            background: #e3eaef;
        }
    }
}
.custom-el-table-rank {
    &.el-table::before {
        height: 0 !important;
        background: none !important;
    }
    font-size: 14px;
    ::v-deep .el-table__cell {
        height: 25px;
        padding: 0;
        border: none;
    }
    ::v-deep tbody {
        .el-table__cell {
            color: #3f404a;
            font-weight: normal;
        }
        .cell {
            padding: 0;
        }
        tr:hover {
            td {
                background: #e3eaef !important;
            }
        }
    }
}
</style>