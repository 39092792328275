<template>
    <div>
        <div class="header">
            <div class="title">应用IP黑名单</div>
            <el-button class="addBtn" @click="toNew" size="small" type="primary" icon="el-icon-plus">添加</el-button>
        </div>
        <el-table :data="Blacklist" :header-cell-style="{'background-color':'#F3F3F3','color':'#3A3A3A'}" :row-style="rowStyle">
            <el-table-column label="IP段">
                <template slot-scope="scope">
                    {{scope.row.ipStart}}
                    <span style="padding: 0 10px;">至</span>
                    {{scope.row.ipEnd}}
                </template>
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间"></el-table-column>
            <el-table-column label="是否启用">
                <template slot-scope="scope">
                    <el-switch v-model="scope.row.isEnable" @change="setIsEnable(scope.row)" style="padding-right:5px;"></el-switch>
                    <span v-if="scope.row.isEnable">启用</span>
                    <span v-else>禁用</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="200">
                <template slot-scope="scope">
                    <div>
                        <div class="handleEditBtn" @click="toEdit(scope.row)">编辑</div>
                        <div class="handleDelBtn" @click="toDel(scope.row)">删除</div>
                    </div>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="700px">
            <el-form :model="submitForm" label-width="80px" size="small">
                <el-form-item label="IP段" required>
                    <el-input v-model="submitForm.ipStart" style="width:220px;"></el-input>
                    <span style="padding: 0 10px;">至</span>
                    <el-input v-model="submitForm.ipEnd" style="width:220px;"></el-input>
                </el-form-item>
                <el-form-item label="是否启用">
                    <el-switch v-model="submitForm.isEnable"></el-switch>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" @click="onSubmit" :loading="btnLoading">确 认</el-button>
                <el-button size="small" @click="dialogVisible = false">取 消</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            specialKey: "",
            ukey: "",
            Blacklist: [],
            dialogVisible: false,
            dialogTitle: "",
            submitForm: {},
            btnLoading: false,
        };
    },
    methods: {
        rowStyle({rowIndex}){
            // 表格隔行换色
            if(rowIndex % 2 == 1){
                return {'background-color':'#F9FAFC'}
            }
        },

        getList(){
            this.$axios.post(`${this.$openUrl}/ApplyManage/GetIpRights`,{
                "specialKey": this.specialKey,
                "type": 1,  // 0 白名单 1黑名单
                "pageIndex": 1,
                "pageSize": 10
            }).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.Blacklist = data.datas;
                }
            });
        },
        toNew(){
            this.dialogTitle = "添加IP黑名单";
            this.dialogVisible = true;
            this.submitForm = {
                "specialKey": this.specialKey,
                "ipStart": "",
                "ipEnd": "",
                "isEnable": true,
                "type": 1,
                "ukey": this.ukey
            };
        },
        toEdit(row){
            this.dialogTitle = "编辑IP黑名单";
            this.dialogVisible = true;
            this.submitForm = {
                "key": row.key,
                "ipStart": row.ipStart,
                "ipEnd": row.ipEnd,
                "isEnable": row.isEnable,
                "ukey": this.ukey
            };
        },
        onSubmit(){
            const {ipStart,ipEnd} = this.submitForm;
            if(!(ipStart && ipEnd)){
                this.$message({ message: '请输入IP段', type: 'warning'});
                return false;
            }
            if(this.dialogTitle == "添加IP黑名单"){
                this.btnLoading = true;
                this.$axios.post(`${this.$openUrl}/ApplyManage/AddIpRights`,this.submitForm).then((res) => {
                    const { isSuccess } = res;
                    this.btnLoading = false;
                    if (isSuccess) {
                        this.dialogVisible = false;
                        this.$message({ message: '添加成功', type: 'success'});
                        this.getList();
                    }
                });
            }
            if(this.dialogTitle == "编辑IP黑名单"){
                this.btnLoading = true;
                this.$axios.post(`${this.$openUrl}/ApplyManage/UpdateIpRights`,this.submitForm).then((res) => {
                    const { isSuccess } = res;
                    this.btnLoading = false;
                    if (isSuccess) {
                        this.dialogVisible = false;
                        this.$message({ message: '编辑成功', type: 'success'});
                        this.getList();
                    }
                });
            }
        },
        toDel(row){
            this.$confirm(`是否删除该IP黑名单?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$axios.get(`${this.$openUrl}/ApplyManage/DeleteIpRights?key=${row.key}&ukey=${this.ukey}`).then(res => {
                    const {isSuccess} = res;
                    if(isSuccess){
                        this.$message({type: 'success',message: '删除成功!'});
                        this.getList();
                    }
                });
            }).catch(() => {});
        },
        setIsEnable(row){
            this.$axios.get(`${this.$openUrl}/ApplyManage/SetIpRightsIsEnable?key=${row.key}`).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    this.$message({ message: '设置成功', type: 'success'});
                }
            });
        },
    },
    mounted() {
        this.specialKey = this.$route.query.specialKey;
        this.ukey = JSON.parse(localStorage.userInfo).uKey;
        this.getList();
    },
};
</script>

<style lang="scss" scoped>
.header{
    margin-bottom: 10px;
    overflow: hidden;
    height: 36px;
    line-height: 36px;
    .title{
        float: left;
        font-size: 18px;
        color: #333;
        font-weight: 700;
    }
    .addBtn{
        float: right;
    }
}
</style>