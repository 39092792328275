<template>
<div>
    <div v-if="ie=='i'">
        <!-- 喀麦隆(CMR)进口提单明细 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th width="25%">申报数量</th>
                        <td width="25%">{{billInfo.DeclaranceQuantity}}</td>
                        <th width="25%">原产国</th>
                        <td width="25%">{{billInfo.CountryStd}}</td>
                    </tr>
                    <tr>
                        <th>进口模式</th>
                        <td>{{billInfo.ImpType}}</td>
                        <th>报关号</th>
                        <td>{{billInfo.DeclarantNumber}}</td>
                    </tr>
                    <tr>
                        <th>海关代码</th>
                        <td>{{billInfo.CustomsCode}}</td>
                        <th>代理报关机构</th>
                        <td>{{billInfo.AgentDeclarant}}</td>
                    </tr>
                    <tr>
                        <th>报关完税价格（非洲法郎）</th>
                        <td>{{billInfo.DeclarceValue}}</td>
                        <th>每份报关净收税（非洲法郎）</th>
                        <td>{{billInfo.UnitDeclarceValue}}</td>
                    </tr>
                    <tr>
                        <th>包装数</th>
                        <td>{{billInfo.PackageNumber}}</td>
                        <th>支付关税（非洲法郎）</th>
                        <td>{{billInfo.PaymentOfDuty}}</td>
                    </tr>
                    <tr>
                        <th>申报人</th>
                        <td>{{billInfo.Declarant}}</td>
                        <th>清关号</th>
                        <td>{{billInfo.ClearanceNumber}}</td>
                    </tr>
                    <tr>
                        <th>净重</th>
                        <td>{{billInfo.NetWeight}}</td>
                        <th>净重单位</th>
                        <td>{{billInfo.NetWeightUnit}}</td>
                    </tr>
                    <tr>
                        <th>计税重量</th>
                        <td>{{billInfo.TaxWeight}}</td>
                        <th>计税重量单位</th>
                        <td>{{billInfo.TaxWeightUnit}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
    <div v-if="ie=='e'">
        <!-- 喀麦隆(CMR)出口提单明细 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th width="25%">申报数量</th>
                        <td width="25%">{{billInfo.DeclaranceQuantity}}</td>
                        <th width="25%">货源国</th>
                        <td width="25%">{{billInfo.OriginCountry}}</td>
                    </tr>
                    <tr>
                        <th>出口模式</th>
                        <td>{{billInfo.ExpType}}</td>
                        <th>报关号</th>
                        <td>{{billInfo.DeclarantNumber}}</td>
                    </tr>
                    <tr>
                        <th>海关代码</th>
                        <td>{{billInfo.CustomsCode}}</td>
                        <th>代理报关机构</th>
                        <td>{{billInfo.AgentDeclarant}}</td>
                    </tr>
                    <tr>
                        <th>清关代码</th>
                        <td>{{billInfo.ManifestNumber}}</td>
                        <th>报关完税价格（非洲法郎）</th>
                        <td>{{billInfo.DeclarceValue}}</td>
                    </tr>
                    <tr>
                        <th>净收税（非洲法郎）</th>
                        <td>{{billInfo.NetTax}}</td>
                        <th>支付关税（非洲法郎）</th>
                        <td>{{billInfo.PaymentOfDuty}}</td>
                    </tr>
                    <tr>
                        <th>申报人</th>
                        <td>{{billInfo.Declarant}}</td>
                        <th>清关号</th>
                        <td>{{billInfo.ClearanceNumber}}</td>
                    </tr>
                    <tr>
                        <th>净重</th>
                        <td>{{billInfo.NetWeight}}</td>
                        <th>净重单位</th>
                        <td>{{billInfo.NetWeightUnit}}</td>
                    </tr>
                    <tr>
                        <th>计税重量</th>
                        <td>{{billInfo.TaxWeight}}</td>
                        <th>计税重量单位</th>
                        <td>{{billInfo.TaxWeightUnit}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
</div>
</template>

<script>
export default {
    props:["billInfo","ie"],
    data() {
        return {

        };
    },
    methods: {
        
    },
    mounted() {

    },
};
</script>

<style lang="scss" scoped>
.partItem {
    margin-bottom: 20px;
    /deep/.el-card__header {
        padding: 10px 20px;
        .headTitle {
            display: inline-block;
            padding-left: 10px;
            font-size: 14px;
            border-left: 2px solid var(--themeColor);
            font-weight: bold;
        }
    }
}
table {
    width: 100%;
    border-collapse: collapse;
    tr {
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;
        th,
        td {
            font-size: 12px;
            color: #666;
            height: 40px;
            border: 1px solid #eaeaea;
            text-align: center;
        }
        th {
            background-color: #f5f7fa;
            font-weight: 700;
        }
        .bg {
            background-color: var(--themeColor);
            color: #fff;
            font-weight: 700;
            opacity: 0.8;
        }
    }
}
</style>