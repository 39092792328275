<template>
<div style="display: flex;">
    <ClueNav @chooseNav="chooseNav" :currentPath="currentPath" />
    <div class="viewbox">
        <div class="title">搜联系人WhatsApp</div>
        <div class="searchBox">
            <el-select v-model="searchTerms.country" filterable size="small" style="width:100px;" :disabled="searchLoading">
                <el-option v-for="ele of countryOption" :key="ele.name" :value="ele.tel" :label="ele.name" />
            </el-select>
            <el-input placeholder="搜索关键字" size="small" prefix-icon="el-icon-search" v-model="searchTerms.keyword" style="width:400px;" :disabled="searchLoading"></el-input>
            <el-button type="primary" plain size="small" @click="toSearch" style="width:150px;" :loading="searchLoading">
                {{searchLoading ? "搜索中" : "搜索(加入任务队列)"}}
            </el-button>
            <el-button size="small" v-if="searchLoading" @click="isStopSearch = true;">停止搜索</el-button>
            <!-- <el-checkbox v-model="searchTerms.isTask" style="margin-left:20px;">加入任务队列</el-checkbox> -->
            <!-- <TaskList style="float:right;" /> -->
        </div>

        <div class="searchLogList">
            <div class="tt">任务列表</div>
            <TaskList :key="uniqueKey" />
        </div>

        <!-- <div class="searchLogList" v-show="!isClickSearch && searchLogList.length !== 0">
            <div class="tt">搜索记录</div>
            <div class="logItem" v-for="(item,index) in searchLogList" :key="index">
                <span>{{item.createTime}}</span>
                <span style="padding-left: 10px;">搜索关键字：</span>
                <span style="color:var(--themeColor)">{{item.keyword}}</span>
            </div>
        </div> -->

        <!-- <div class="searchBefore" v-show="!isClickSearch && searchLogList.length == 0">
            暂无搜索记录,输入关键字搜索!
        </div> -->

        <div class="searchResult" v-show="isClickSearch">
            <el-empty v-if="isStopSearch && resultList.length == 0" description="搜索无数据"></el-empty>
            <div v-else>
                <div class="resultNumber">
                    <div>为您找到<span>{{resultList.length}}</span>个联系人</div>
                    <div style="font-size: 14px;padding-left: 30px;">
                        搜索结果将自动同步到：
                        <span style="cursor: pointer;" @click="chooseNav('/clue/myClue')">我的线索-联系人线索</span>
                    </div>
                </div>
                <el-table :data="resultList.slice(20*(resultPageIndex-1),20*resultPageIndex)" height="calc(100vh - 285px)" :header-cell-style="{'color':'#3A3A3A'}">
                    <el-table-column prop="whatsapp" label="whatsapp"></el-table-column>
                    <el-table-column prop="email" label="邮箱"></el-table-column>
                    <el-table-column prop="country" label="国家"></el-table-column>
                    <el-table-column prop="title" label="标题" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="desc" label="描述" show-overflow-tooltip></el-table-column>
                </el-table>
                <div style="height:40px;">
                    <el-pagination background layout="total,prev,pager,next" :total="resultList.length" :page-size="20" style="float:right;margin-top:4px;" :current-page.sync="resultPageIndex"></el-pagination>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import ClueNav from "@/components/ClueNav.vue";
import { data as countryOption } from '@/assets/js/country.js';
import TaskList from "./subcom/TaskList.vue";
export default {
    components: { ClueNav,TaskList },
    props: {
        handleSelect: {
            type: Function,
            default: null,
        },
    },
    data() {
        return {
            currentPath: "/clue/searchWhatsApp",
            searchLoading: false,
            searchTerms: {
                "country": "",
                "keyword": "", //搜索关键字
                "start": 0,
                "platform": "",
                "pattern": "",
                "isFirst": false,
                "isTask": true, //启动任务模式
                "taskKey": "",
            },
            resultList: [],
            isClickSearch: false,
            resultPageIndex: 1,

            strategyOption: [], //搜索策略配置
            countryOption: [], //国家配置
            isStopSearch: false, //是否停止搜索
            strategyIndex: 0,
            //搜索记录
            searchLogList: [],
            uniqueKey: null,
        };
    },
    methods: {
        chooseNav(index) {
            this.handleSelect(index);
            this.$router.push(index);
        },

        //点击搜索
        toSearch(){
            this.searchTerms.keyword = this.searchTerms.keyword.trim();
            if(!this.searchTerms.keyword){
                this.$message({ message: '搜索关键字不能为空!', type: 'warning'});
                return false;
            }
            if(this.searchTerms.isTask){
                this.isClickSearch = false;
            }else{
                this.isClickSearch = true;
            }
            this.resultPageIndex = 1;
            this.searchLoading = true;
            this.resultList = [];
            this.strategyIndex = 0;
            this.isStopSearch = false;
            this.searchTerms.isFirst = true;
            this.requestPort(0);
        },
        requestPort(startIndex){
            if (this.strategyIndex >= this.strategyOption.length || this.isStopSearch) {
                this.searchLoading = false;
                this.$message({ message: '搜索完毕', type: 'success'});
                return false;
            }
            this.searchTerms.start = startIndex;
            this.searchTerms.platform = this.strategyOption[this.strategyIndex].platform;
            this.searchTerms.pattern = this.strategyOption[this.strategyIndex].pattern;
            this.$axios.post(`${this.$clueUrl}/MyClue/GoogleSearch`,this.searchTerms).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    if(this.searchTerms.isTask){
                        this.searchLoading = false;
                        this.$message({ message: '任务添加完成', type: 'success'});
                        this.uniqueKey = new Date().getTime();
                        return false;
                    }
                    this.searchTerms.isFirst = false;
                    this.resultList = this.resultList.concat(data.result);
                    if(data.isEnd){
                        this.strategyIndex += 1;
                        this.requestPort(0);
                    }else{
                        this.requestPort(startIndex + 10);
                    }
                }
            });
        },


        //获取搜索策略配置
        getSearchStrategyList(){
            this.$axios.get(`${this.$clueUrl}/MyClue/GetSearchStrategyList`).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    this.strategyOption = data;
                }
            });
        },

        //获取搜索记录
        getSearchRecords(){
            this.$axios.post(`${this.$clueUrl}/MyClue/GetSearchLog`,{
                "type": 1,
                "pageIndex": 1,
                "pageSize": 10
            }).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    this.searchLogList = data.datas;
                }
            });
        },

    },
    mounted() {
        this.getSearchStrategyList();
        this.countryOption = countryOption;
        // this.getSearchRecords();
    },
};
</script>

<style lang="scss" scoped>
@import '@/scss/common.scss';
.viewbox{
    width: calc(100% - 200px);
    height: calc(100vh - 80px);
    box-sizing: border-box;
    overflow: auto;
    background: #FFFFFF;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    padding: 20px;
    .title{
        font-size: 16px;
        font-weight: bold;
        height: 32px;
        line-height: 32px;
        margin-bottom: 10px;
    }
    .searchBox{
        margin-bottom: 10px;
    }
    .searchLogList{
        margin-bottom: 10px;
        padding: 10px 20px;
        color: #9a9a9a;
        border: 1px solid #dadada;
        box-sizing: border-box;
        .tt{
            color: #3A3A3A;
            margin-bottom: 10px;
        }
        .logItem{
            height: 24px;
            line-height: 24px;
            color: #6a6a6a;
        }
    }
    .searchBefore{
        padding: 100px 0;
        line-height: 60px;
        text-align: center;
        font-size: 40px;
        color: #9a9a9a;
        border: 1px solid #dadada;
        box-sizing: border-box;
    }
    .searchResult{
        height: calc(100% - 84px);
        border: 1px solid #dadada;
        box-sizing: border-box;
        .resultNumber{
            height: 40px;
            line-height: 40px;
            background: #F1F1F1;
            color: #3A3A3A;
            padding-left: 20px;
            display: flex;
            span{
                padding: 0 10px;
                color: var(--themeColor);
            }
        }
    }
}
</style>