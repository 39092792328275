<template>
    <div class="personalInfo">
        <div class="onetitle">
            <span>个人资料</span>
            <el-button style="float:right;" size="small" @click="toEditStaff">编 辑</el-button>
            <el-button style="float:right;margin-right: 10px;" size="small" @click="toSetPwd">修改密码</el-button>
        </div>
        <div style="display:flex;margin:20px 0;" v-loading="infoLoading">
            <div class="headportrait">
                <el-avatar class="avatarImg" :size="80" :src="staffInfo.headPortraitUrl"></el-avatar>
                <el-upload :action="uploadUrl" :headers="headers" :data="uploadData" :show-file-list="false" :before-upload="beforeUpload" :on-success="uploadSuccess">
                    <el-button type="info" plain size="mini" style="margin-top:10px;">更换头像</el-button>
                </el-upload>
            </div>
            <div class="infoBox" v-if="!editVisible">
                <div class="infoTop">
                    <span class="name">{{staffInfo.name}}</span>
                    <span class="account">{{staffInfo.enterpriseMailbox}}</span>
                    <el-tag effect="plain" size="small" type="success" v-show="staffInfo.isAdmin">管理员</el-tag>
                    <el-tag effect="plain" size="small" type="danger" v-show="staffInfo.isStop">已停用</el-tag>
                </div>
                <div class="infoItem">
                    <div class="label">性别:</div>
                    <div class="value">{{staffInfo.sex == 1 ? '男' : '女'}}</div>
                </div>
                <div class="infoItem">
                    <div class="label">出生日期:</div>
                    <div class="value">{{staffInfo.birthday ? staffInfo.birthday : "-"}}</div>
                </div>
                <div class="infoItem">
                    <div class="label">入职时间:</div>
                    <div class="value">{{staffInfo.entryTime ? staffInfo.entryTime : "-"}}</div>
                </div>
                <div class="infoItem">
                    <div class="label">关联微信:</div>
                    <div class="value" style="padding-right:20px;">
                        {{staffInfo.openId ? '已关联' : '无'}}
                        <img v-if="staffInfo.openId" src="@/assets/img/wechat.png" alt="" />
                    </div>
                    <el-link v-if="staffInfo.openId" @click="disassociate" type="primary" :underline="false">解除关联</el-link>
                </div>
                <!-- <div class="infoItem">
                    <div class="label">微信号:</div>
                    <div class="value" style="padding-right:20px;">
                        {{staffInfo.weChatName ? staffInfo.weChatName : '-'}}
                        <img v-if="staffInfo.weChatName" src="@/assets/img/wechat.png" alt="" />
                    </div>
                    <el-link v-if="staffInfo.weChatName" @click="unbindChat" type="primary" :underline="false">解绑微信</el-link>
                    <el-link v-else @click="toBindWeChat" type="primary" :underline="false">绑定微信</el-link>
                </div> -->
                <div class="infoItem">
                    <div class="label">手机号:</div>
                    <div class="value" style="padding-right:20px;">
                        {{staffInfo.phone ? staffInfo.phone : "-"}}
                        <img v-if="staffInfo.phone" src="@/assets/img/tel.png" alt="" />
                    </div>
                    <el-link @click="modifyVisible = true;" type="primary" :underline="false">更换手机号</el-link>
                </div>
                <!-- <div class="infoItem">
                    <div class="label">邮箱:</div>
                    <div class="value">{{staffInfo.email ? staffInfo.email : "-"}}</div>
                </div> -->
                <el-divider></el-divider>
                <div class="infoItem">
                    <div class="label">企业邮箱:</div>
                    <div class="value">{{staffInfo.enterpriseMailbox}}</div>
                </div>
                <div class="infoItem">
                    <div class="label">部门:</div>
                    <div class="value">
                        <div v-for="ele in staffInfo.departments" :key="ele.key">
                            <span>{{ele.name}}</span>
                            <img v-show="ele.isManager" src="@/assets/img/main.png" alt="" />
                        </div>
                    </div>
                </div>
                <div class="infoItem">
                    <div class="label">职位:</div>
                    <div class="value">{{staffInfo.position ? staffInfo.position : "-"}}</div>
                </div>
                <div class="infoItem">
                    <div class="label">直属上级:</div>
                    <div class="value">{{staffInfo.directSuperiorName ? staffInfo.directSuperiorName : "-"}}</div>
                </div>
            </div>
            <div class="editBox" v-else>
                <div class="formBox">
                    <div class="title">基本信息</div>
                    <el-form class="content" :model="editForm" label-width="120px" size="small">
                        <el-form-item label="姓名" required>
                            <el-input v-model="editForm.name" class="w220"></el-input>
                        </el-form-item>
                        <el-form-item label="性别">
                            <el-radio-group v-model="editForm.sex">
                                <el-radio :label="1">男</el-radio>
                                <el-radio :label="0">女</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="出生日期">
                            <el-date-picker v-model="editForm.birthday" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
                        </el-form-item>
                        <el-form-item label="手机号" required>
                            <el-input v-model="editForm.phone" class="w220" disabled></el-input>
                        </el-form-item>
                        <!-- <el-form-item label="邮箱">
                            <el-input v-model="editForm.email" class="w220"></el-input>
                        </el-form-item> -->
                    </el-form>
                </div>
                <div class="submitBtn">
                    <el-button size="small" type="primary" @click="editSubmit" :loading="btnLoading">确 认</el-button>
                    <el-button size="small" @click="editCancel">取 消</el-button>
                </div>
            </div>
        </div>
        <!-- <div class="onetitle">其他同事</div>
        <el-table :data="staffList" :header-cell-style="{'background-color':'#F3F3F3','color':'#3A3A3A'}">
            <el-table-column prop="userCnName" label="姓名"></el-table-column>
            <el-table-column prop="mailAddress" label="邮箱"></el-table-column>
            <el-table-column prop="phone" label="手机号"></el-table-column>
        </el-table>
        <el-pagination :hide-on-single-page="totalItems<=10" layout="prev,pager,next" :total="totalItems" :current-page.sync="pageIndex" @current-change="pageChange"></el-pagination> -->

        <el-dialog title="更换手机号" :visible.sync="modifyVisible" width="400px">
            <el-form ref="registerForm" label-width="60px" size="small">
                <el-form-item>
                    <div slot="label" class="label">手机号</div>
                    <el-input v-model="modifyPhone" placeholder="手机号" clearable></el-input>
                </el-form-item>
                <el-form-item>
                    <div slot="label" class="label">验证码</div>
                    <div style="display:flex;">
                        <el-input v-model="verifyCode" placeholder="短信验证码" clearable></el-input>
                        <el-button :disabled="btnHtml != '获取验证码'" @click="getVerifyCode">{{btnHtml}}</el-button>
                    </div>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="modifyVisible = false;">取 消</el-button>
                <el-button size="small" type="primary" @click="modifySubmit" :loading="btnLoading">确 认</el-button>
            </span>
        </el-dialog>

        
        <el-dialog title="修改密码" :visible.sync="setPwdVisible" width="400px">
            <el-form :model="setPwdForm" ref="setPwdForm" :rules="pwdRules" label-width="80px" size="small">
                <el-form-item label="旧密码" prop="oldPassWord">
                    <el-input v-model="setPwdForm.oldPassWord" type="password" clearable show-password></el-input>
                </el-form-item>
                <el-form-item label="新密码" prop="newPassWord">
                    <el-input v-model="setPwdForm.newPassWord" type="password" clearable show-password></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="againPassWord">
                    <el-input v-model="setPwdForm.againPassWord" type="password" clearable show-password></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="setPwdVisible = false;">取 消</el-button>
                <el-button size="small" type="primary" @click="setPwdOk" :loading="btnLoading">确 认</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
export default {
    data() {
        var validatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入新密码'));
            } else {
                if (this.setPwdForm.againPassWord !== '') {
                    this.$refs.setPwdForm.validateField('againPassWord');
                }
                callback();
            }
        };
        var validatePass2 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码'));
            } else if (value !== this.setPwdForm.newPassWord) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        };
        return {
            uKey: "",
            companyKey: "",
            staffInfo: {},
            infoLoading: false,
            defaultImg: "https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png",
            //编辑员工信息
            editVisible: false,
            editForm: {},
            btnLoading: false,
            //更换头像
            uploadUrl: `${this.$diskUrl}/File/Upload`,
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('token'),
            },
            uploadData: {
                folderKey: "0",
                remarkName: "",
                permission: '我的'
            },
            
            //同事成员列表
            staffList: [],
            pageIndex: 1,
            totalItems: 0,

            //更换手机号功能
            modifyVisible: false,
            modifyPhone: "",
            verifyCode: "",
            btnHtml: "获取验证码",
            
            //修改密码
            setPwdVisible: false,
            setPwdForm: {
                ukey: "",
                oldPassWord: "",
                newPassWord: "",
                againPassWord: "",
            },
            pwdRules: {
                oldPassWord: [{ required: true, message: '请输入旧密码', trigger: 'blur' }],
                newPassWord: [{ required: true, validator: validatePass, trigger: 'blur' }],
                againPassWord: [{ required: true, validator: validatePass2, trigger: 'blur' }],
            },
        };
    },
    methods: {
        //修改密码
        toSetPwd(){
            this.setPwdVisible = true;
            this.setPwdForm = {
                ukey: this.uKey,
                oldPassWord: "",
                newPassWord: "",
                againPassWord: "",
            }
        },
        setPwdOk(){
            this.$refs["setPwdForm"].validate((valid) => {
                if (valid) {
                    this.btnLoading = true;
                    this.$axios.post(`${this.$personnelUrl}/Staff/UpdatePassWord`, this.setPwdForm).then((res) => {
                        const { isSuccess } = res;
                        this.btnLoading = false;
                        this.setPwdVisible = false;
                        if (isSuccess) {
                            this.$message({type: 'success',message: '修改密码成功!'});
                        }
                    })
                }
            });
        },

        //更换手机号功能
        getVerifyCode(){
            if(!this.modifyPhone){
                this.$message({message: '请输入手机号获取验证码',type: 'warning'});
                return false;
            }
            //验证用户是否存在
            this.$axios.post(`${this.$authUrl}/api/Account/VerifyUser`,{
                companyName: "",
                email: "",
                phone: this.modifyPhone,
            }).then((res) => {
                //不存在就可以发送手机验证码
                if(res === false){
                    this.sendCode();
                }
            });
        },
        sendCode(){
            this.$axios.post(`${this.$authUrl}/api/Account/SendSms`,{
                phone: this.modifyPhone,
            }).then((res) => {
                if(res === true){
                    this.$message({message: "手机验证码已发送",type: 'success'});
                    var num = 60;
                    var timer = setInterval(() => {
                        if(num == 0){
                            clearInterval(timer)
                            this.btnHtml = "获取验证码"
                        }else{
                            this.btnHtml = `倒计时${num}s`
                            num--
                        }
                    }, 1000);
                }
            });
        },
        modifySubmit(){
            if(!this.modifyPhone || !this.verifyCode){
                this.$message({message: '手机号,验证码不能为空!',type: 'warning'});
                return false;
            }
            this.btnLoading = true;
            this.$axios.post(`${this.$personnelUrl}/Staff/UpdatePhone`,{
                ukey: this.uKey,
                phone: this.modifyPhone,
                verifyCode: this.verifyCode,
            }).then((res) => {
                const { isSuccess } = res;
                this.btnLoading = false;
                if (isSuccess) {
                    this.modifyVisible = false;
                    this.$message({type: 'success',message: '更换手机号成功!'});
                    this.getPersonalInfo();
                }
            })
        },

        //解除关联
        disassociate(){
            this.$confirm(`是否解除关联微信?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(() => {
                this.$axios.get(`${this.$personnelUrl}/Staff/UnboundPWechat?ukey=${this.uKey}`).then((res) => {
                    const { isSuccess } = res;
                    if (isSuccess) {
                        this.$message({type: 'success',message: '解除成功!'});
                        location.reload();
                        this.$router.go(0);
                    }
                })
            }).catch(() => {});
        },

        //解绑微信账号
        // unbindChat(){
        //     this.$confirm(`是否解绑微信账号?`, '提示', {
        //         confirmButtonText: '确定',
        //         cancelButtonText: '取消',
        //     }).then(() => {
        //         this.$axios.get(`${this.$personnelUrl}/Staff/UnboundWechat?ukey=${this.uKey}`).then((res) => {
        //             const { isSuccess } = res;
        //             if (isSuccess) {
        //                 this.$message({type: 'success',message: '解绑微信账号成功!'});
        //                 this.getPersonalInfo();
        //             }
        //         })
        //     }).catch(() => {});
        // },
        //绑定微信
        // toBindWeChat(){
        //     window.open(`https://open.weixin.qq.com/connect/qrconnect?appid=wxc1188a2db178f69f&redirect_uri=https://oa.lsmaps.com/auth/callback/Wechart?ukey=${this.uKey}|https://oa.lsmaps.com&response_type=code&scope=snsapi_login&state=CRM&href=https://vip.lsmaps.com/context/css/wx.css`)
        // },

        //获取个人资料信息
        getPersonalInfo(){
            this.infoLoading = true;
            this.$axios.get(`${this.$personnelUrl}/Staff/GetUserDetail?ukey=${this.uKey}`).then((res) => {
                const { isSuccess, data } = res;
                this.infoLoading = false;
                if (isSuccess) {
                    data.headPortraitUrl = data.headPortraitUrl ? data.headPortraitUrl : data.wechatAvatar ? data.wechatAvatar : this.defaultImg;
                    this.staffInfo = data;
                }
            })
        },
        //编辑
        toEditStaff(){
            this.editVisible = true;
            const {ukey,name,email,phone,position,sex,birthday,directSuperiorUkey,entryTime,departments,enterpriseMailbox} = this.staffInfo;
            this.editForm = {
                ukey,name,email,phone,position,sex,birthday,directSuperiorUkey,entryTime,departments,enterpriseMailbox
            }
        },
        editSubmit(){
            const {name,phone} = this.editForm;
            if(!name){ this.$message({ message: "请填写姓名" , type: 'warning'}); return false; }
            if(!phone){ this.$message({ message: "请填写手机号" , type: 'warning'}); return false; }

            this.btnLoading = true;
            this.$axios.post(`${this.$personnelUrl}/Staff/UpdateUser`,this.editForm).then((res) => {
                const { isSuccess } = res;
                this.btnLoading = false;
                if (isSuccess) {
                    this.editVisible = false;
                    this.$message({type: 'success',message: '编辑成功!'});
                    this.getPersonalInfo();
                }
            })
        },
        editCancel(){
            this.editVisible = false;
            this.getPersonalInfo();
        },
        //更换头像
        beforeUpload(file) {
            var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
            const isImg = testmsg === "png" || testmsg === "jpg" || testmsg === "jpeg";
            const isLt10M = file.size / 1024 / 1024 < 10;
            if (!isImg) {
                this.$message({ message: "上传文件只能是图片!", type: "warning"});
            }
            if (!isLt10M) {
                this.$message({ message: "上传文件大小不能超过10MB!", type: "warning"});
            }
            return isImg && isLt10M;
        },
        uploadSuccess(response){
            const {isSuccess,data} = response;
            if(isSuccess){
                const {ukey,name,email,phone,position,sex,birthday,directSuperiorUkey,entryTime,departments} = this.staffInfo;
                this.$axios.post(`${this.$personnelUrl}/Staff/UpdateUser`,{
                    ukey,name,email,phone,position,sex,birthday,directSuperiorUkey,entryTime,departments,
                    headPortraitUrl: data.fileUrl,
                }).then((res) => {
                    const { isSuccess } = res;
                    if (isSuccess) {
                        this.$message({type: 'success',message: '更换头像成功!'});
                        this.getPersonalInfo();
                    }
                })
            }
        },

        // 获取同事成员列表
        getStaffList(){
            this.$axios.post(`${this.$authUrl}/api/Staff`,{
                "companyKey": this.companyKey,
                "pageIndex": this.pageIndex,
                "pageSize": 10
            }).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.staffList = data.datas.filter(ele => ele.uKey != this.uKey);
                    this.totalItems = data.totalItems;
                }
            });
        },
        pageChange(page){
            this.pageIndex = page;
            this.getStaffList();
        }
    },
    mounted() {
        this.uKey = JSON.parse(localStorage.userInfo).uKey;
        this.companyKey = JSON.parse(localStorage.userInfo).companyKey;
        //获取个人资料信息
        this.getPersonalInfo();
        // 获取同事成员列表
        // this.getStaffList();
        
    },
    
};
</script>

<style lang="scss" scoped>
.personalInfo{
    background: #FFFFFF;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    padding: 20px;
    .onetitle{
        height: 40px;
        line-height: 40px;
        font-size: 24px;
        font-weight: bold;
        color: #2E4756;
        padding-left: 50px;
        padding-bottom: 10px;
        border-bottom: 1px solid #D3DCE6;
    }

    .headportrait{
        width: 120px;
        text-align: center;
    }
    .infoBox{
        flex-grow: 1;
        margin-left: 30px;
        .infoTop{
            line-height: 40px;
            margin-bottom: 10px;
            color: #092338;
            .name{
                width: 80px;
                display: inline-block;
                text-align: right;
                font-size: 20px;
                font-weight: bold;
            }
            .account{
                font-size: 14px;
                padding-left: 20px;
            }
        }
        .infoItem{
            font-size: 14px;
            line-height: 32px;
            display: flex;
            .label{
                width: 80px;
                display: inline-block;
                text-align: right;
                color: #858585;
            }
            .value{
                margin-left: 20px;
                display: inline-block;
                color: #092338;
                img{
                    vertical-align: middle;
                    padding-left: 10px;
                }
            }
        }
    }
    .editBox{
        flex-grow: 1;
        margin-left: 30px;
        .formBox {
            margin-top: 20px;
            .title {
                margin-bottom: 20px;
                line-height: 16px;
                font-size: 16px;
                font-weight: bold;
                color: #2a3f58;
                padding-left: 10px;
                border-left: 4px solid var(--themeColor);
            }
            .content {
                .w220{
                    width: 220px;
                }
                .iconBtn{
                    vertical-align: middle;
                    cursor: pointer;
                    margin-left: 20px;
                    margin-right: 20px;
                }
            }
        }
        .submitBtn {
            border-top: 1px solid #e0e6ed;
            padding-top: 20px;
            padding-left: 40px;
        }
    }
}
</style>