<template>
<div>
    <div v-if="ie=='i'">
        <!-- 哥伦比亚(COL)进口提单明细 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th width="25%">提单号</th>
                        <td width="25%">{{billInfo.TransportNumber}}</td>
                        <th width="25%">进口商ID</th>
                        <td width="25%">{{billInfo.ImporterId}}</td>
                    </tr>
                    <tr>
                        <th>进口商邮箱</th>
                        <td>{{billInfo.ContactEmail}}</td>
                        <th>出口商邮箱</th>
                        <td>{{billInfo.ExporterPhoneEmail}}</td>
                    </tr>
                    <tr>
                        <th>进口商电话</th>
                        <td>{{billInfo.ImporterPhone}}</td>
                        <th>进口商传真</th>
                        <td>{{billInfo.ContactFax}}</td>
                    </tr>
                    <tr>
                        <th>联系人</th>
                        <td>{{billInfo.Contact}}</td>
                        <th>公司类型</th>
                        <td>{{billInfo.CompanyType}}</td>
                    </tr>
                    <tr>
                        <th>公司认证数字ID</th>
                        <td>{{billInfo.CompanyCheckDigitId}}</td>
                        <th>有否UAP平台</th>
                        <td>{{billInfo.Uap}}</td>
                    </tr>
                    <tr>
                        <th>是否出口企业</th>
                        <td colspan="3">{{billInfo.Altex}}</td>
                    </tr>
                    <tr>
                        <th>产品编码描述</th>
                        <td colspan="3">{{billInfo.HsCodeDes}}</td>
                    </tr>
                    <tr>
                        <th>协调编码</th>
                        <td colspan="3">{{billInfo.HarmonizedCode}}</td>
                    </tr>
                    <tr>
                        <th>协调编码英文描述</th>
                        <td colspan="3">{{billInfo.HarmonizedCodeDes}}</td>
                    </tr>
                    <tr>
                        <th>净重</th>
                        <td>{{billInfo.NetWeight}}</td>
                        <th>总包装数</th>
                        <td>{{billInfo.TotalBundles}}</td>
                    </tr>
                    <tr>
                        <th>FOB单价（美元）</th>
                        <td>{{billInfo.FobUnit}}</td>
                        <th>FOB总价（美元）</th>
                        <td>{{billInfo.UsFob}}</td>
                    </tr>
                    <tr>
                        <th>CIF总价（美元）</th>
                        <td colspan="3">{{billInfo.Value}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">运输信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">进口报关号</th>
                        <td width="25%">{{billInfo.ControlId}}</td>
                        <th width="25%">开票日期</th>
                        <td width="25%">{{billInfo.DateInvoice}}</td>
                    </tr>
                    <tr>
                        <th>发票号码</th>
                        <td>{{billInfo.Invoice}}</td>
                        <th>起运国</th>
                        <td>{{billInfo.DepartureCountry}}</td>
                    </tr>
                    <tr>
                        <th>免税区</th>
                        <td>{{billInfo.FreeZone}}</td>
                        <th>入港所属洲</th>
                        <td>{{billInfo.DepartmentDestinationState}}</td>
                    </tr>
                    <tr>
                        <th>运输方式</th>
                        <td>{{billInfo.Typeoftransport}}</td>
                        <th>运输公司</th>
                        <td>{{billInfo.TransportCompany}}</td>
                    </tr>
                    <tr>
                        <th>运输公司所在国</th>
                        <td>{{billInfo.TransporterFlag}}</td>
                        <th>运费</th>
                        <td>{{billInfo.UsFreight}}</td>
                    </tr>
                    <tr>
                        <th>保险</th>
                        <td>{{billInfo.UsInsurance}}</td>
                        <th>其他运费保险</th>
                        <td>{{billInfo.OtherFreight}}</td>
                    </tr>
                    <tr>
                        <th>税收（美元）</th>
                        <td>{{billInfo.Tax}}</td>
                        <th>海关</th>
                        <td>{{billInfo.Custom}}</td>
                    </tr>
                    <tr>
                        <th>海关中间机构</th>
                        <td>{{billInfo.CustomsIntermediaries}}</td>
                        <th>海关仓库</th>
                        <td>{{billInfo.CustomWarehouse}}</td>
                    </tr>
                    <tr>
                        <th>银行</th>
                        <td>{{billInfo.Bank}}</td>
                        <th>银行分支</th>
                        <td>{{billInfo.BankBranch}}</td>
                    </tr>
                    <tr>
                        <th>比率</th>
                        <td>{{billInfo.Rate}}</td>
                        <th>支付方式</th>
                        <td>{{billInfo.PayMethod}}</td>
                    </tr>
                    <tr>
                        <th>总海关价格</th>
                        <td>{{billInfo.TotalCustomValue}}</td>
                        <th>总付费</th>
                        <td>{{billInfo.TotalPaid}}</td>
                    </tr>
                    <tr>
                        <th>海关关税百分比</th>
                        <td>{{billInfo.CustomTariffPercentage}}</td>
                        <th>IVA税百分比</th>
                        <td>{{billInfo.IvaTaxPercentage}}</td>
                    </tr>
                    <tr>
                        <th>IVA税付</th>
                        <td colspan="3">{{billInfo.IvaTaxPaid}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">货物信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">货单日期</th>
                        <td width="25%">{{billInfo.ManifestDate}}</td>
                        <th width="25%">货单号</th>
                        <td width="25%">{{billInfo.ManifestNumber}}</td>
                    </tr>
                    <tr>
                        <th>来源国</th>
                        <td>{{billInfo.Country}}</td>
                        <th>原产洲</th>
                        <td>{{billInfo.OriginContinent}}</td>
                    </tr>
                    <tr>
                        <th>注册号</th>
                        <td>{{billInfo.RegistryNumber}}</td>
                        <th>检验办公室</th>
                        <td>{{billInfo.ApprovalOffice}}</td>
                    </tr>
                    <tr>
                        <th>检验年份</th>
                        <td>{{billInfo.ApprovedYear}}</td>
                        <th>进口程序</th>
                        <td>{{billInfo.ImportModality}}</td>
                    </tr>
                    <tr>
                        <th>进口注册文件</th>
                        <td colspan="3">{{billInfo.ImportRegistryDoc}}</td>
                    </tr>
                    <tr>
                        <th>SITC编号</th>
                        <td colspan="3">{{billInfo.Sitc}}</td>
                    </tr>
                    <tr>
                        <th>SITC描述</th>
                        <td colspan="3">{{billInfo.SitcDescription}}</td>
                    </tr>
                    <tr>
                        <th>国际标准行业分类</th>
                        <td>{{billInfo.Isic}}</td>
                        <th>国际标准行业分类描述</th>
                        <td>{{billInfo.IsicDescription}}</td>
                    </tr>
                    <tr>
                        <th>CUODE</th>
                        <td>{{billInfo.Cuode}}</td>
                        <th>CUODE描述</th>
                        <td>{{billInfo.CuodeDescription}}</td>
                    </tr>
                    <tr>
                        <th>协议</th>
                        <td>{{billInfo.Agreement}}</td>
                        <th>包装类型</th>
                        <td>{{billInfo.PackagingType}}</td>
                    </tr>
                    <tr>
                        <th>用户类型</th>
                        <td>{{billInfo.UserType}}</td>
                        <th>报关类型</th>
                        <td>{{billInfo.DeclarationType}}</td>
                    </tr>
                    <tr>
                        <th>进口类型</th>
                        <td>{{billInfo.ImportType}}</td>
                        <th>接受日期</th>
                        <td>{{billInfo.AcceptanceDate}}</td>
                    </tr>
                    <tr>
                        <th>接受号</th>
                        <td>{{billInfo.AcceptanceNumber}}</td>
                        <th>倾销比率</th>
                        <td>{{billInfo.DumpingRate}}</td>
                    </tr>
                    <tr>
                        <th>基础倾销</th>
                        <td>{{billInfo.DumpingBase}}</td>
                        <th>倾销总价</th>
                        <td>{{billInfo.DumpingTotalValue}}</td>
                    </tr>
                    <tr>
                        <th>倾销付款</th>
                        <td colspan="3">{{billInfo.DumpingPaid}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
    <div v-if="ie=='e'">
        <!-- 哥伦比亚(COL)出口提单明细 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th width="25%">出口报关号</th>
                        <td width="25%">{{billInfo.ControlId}}</td>
                        <th width="25%">出口商ID</th>
                        <td width="25%">{{billInfo.ExporterId}}</td>
                    </tr>
                    <tr>
                        <th>进口商城市</th>
                        <td>{{billInfo.DepartmentDestination}}</td>
                        <th>出口商城市</th>
                        <td>{{billInfo.ContactCity}}</td>
                    </tr>
                    <tr>
                        <th>地区</th>
                        <td>{{billInfo.ContactState}}</td>
                        <th>联系人</th>
                        <td>{{billInfo.Contact}}</td>
                    </tr>
                    <tr>
                        <th>出口商电话</th>
                        <td>{{billInfo.Telephone}}</td>
                        <th>出口商传真</th>
                        <td>{{billInfo.ContactFax}}</td>
                    </tr>
                    <tr>
                        <th>出口商邮箱</th>
                        <td>{{billInfo.ContactEmail}}</td>
                        <th>公司认证数字ID</th>
                        <td>{{billInfo.CompanyCheckDigitId}}</td>
                    </tr>
                    <tr>
                        <th>公司类型</th>
                        <td>{{billInfo.CompanyType}}</td>
                        <th>出口类型</th>
                        <td>{{billInfo.ExportType}}</td>
                    </tr>
                    <tr>
                        <th>有否UAP平台</th>
                        <td>{{billInfo.Uap}}</td>
                        <th>是否出口企业</th>
                        <td>{{billInfo.Altex}}</td>
                    </tr>
                    <tr>
                        <th>协调编码</th>
                        <td colspan="3">{{billInfo.HarmonizedCode}}</td>
                    </tr>
                    <tr>
                        <th>协调编码英文描述</th>
                        <td colspan="3">{{billInfo.HarmonizedCodeDes}}</td>
                    </tr>
                    <tr>
                        <th>净重</th>
                        <td>{{billInfo.NetWeight}}</td>
                        <th>FOB单价（美元）</th>
                        <td>{{billInfo.FobUnit}}</td>
                    </tr>
                    <tr>
                        <th>FOB总价（美元）</th>
                        <td>{{billInfo.Value}}</td>
                        <th>CIF总价（美元）</th>
                        <td>{{billInfo.TotalCif}}</td>
                    </tr>
                    <tr>
                        <th>价值成本</th>
                        <td>{{billInfo.ValueCosts}}</td>
                        <th>附加价值</th>
                        <td>{{billInfo.AddedValue}}</td>
                    </tr>
                    <tr>
                        <th>Cop金额</th>
                        <td colspan="3">{{billInfo.ValueCop}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">运输信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">起运地区</th>
                        <td width="25%">{{billInfo.ProcedenceState}}</td>
                        <th width="25%">离港城市</th>
                        <td width="25%">{{billInfo.DepartureCity}}</td>
                    </tr>
                    <tr>
                        <th>免税区</th>
                        <td>{{billInfo.FreeZone}}</td>
                        <th>目的国</th>
                        <td>{{billInfo.Country}}</td>
                    </tr>
                    <tr>
                        <th>目的洲</th>
                        <td>{{billInfo.DestinyContinent}}</td>
                        <th>申报公司ID</th>
                        <td>{{billInfo.DeclarantNumber}}</td>
                    </tr>
                    <tr>
                        <th>申报公司</th>
                        <td>{{billInfo.DeclarantCompany}}</td>
                        <th>运输方式</th>
                        <td>{{billInfo.Typeoftransport}}</td>
                    </tr>
                    <tr>
                        <th>海关</th>
                        <td>{{billInfo.Custom}}</td>
                        <th>运输信息</th>
                        <td>{{billInfo.TransportInfo}}</td>
                    </tr>
                    <tr>
                        <th>出口运输</th>
                        <td>{{billInfo.ExportTransit}}</td>
                        <th>支付方式</th>
                        <td>{{billInfo.PayMethod}}</td>
                    </tr>
                    <tr>
                        <th>运费</th>
                        <td>{{billInfo.UsFreight}}</td>
                        <th>保险</th>
                        <td>{{billInfo.UsInsurance}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">货物信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">自动装货号</th>
                        <td width="25%">{{billInfo.ShipmentAuthorization}}</td>
                        <th width="25%">装货日期</th>
                        <td width="25%">{{billInfo.LoadingDate}}</td>
                    </tr>
                    <tr>
                        <th>装货海关</th>
                        <td>{{billInfo.LoadingCustom}}</td>
                        <th>装货</th>
                        <td>{{billInfo.Loading}}</td>
                    </tr>
                    <tr>
                        <th>原产地</th>
                        <td>{{billInfo.OriginState}}</td>
                        <th>制度</th>
                        <td>{{billInfo.Regime}}</td>
                    </tr>
                    <tr>
                        <th>协议</th>
                        <td colspan="3">{{billInfo.Agreement}}</td>
                    </tr>
                    <tr>
                        <th>SITC编号</th>
                        <td colspan="3">{{billInfo.Sitc}}</td>
                    </tr>
                    <tr>
                        <th>SITC描述</th>
                        <td colspan="3">{{billInfo.SitcDescription}}</td>
                    </tr>
                    <tr>
                        <th>国际标准行业分类</th>
                        <td>{{billInfo.Isic}}</td>
                        <th>国际标准行业分类描述</th>
                        <td>{{billInfo.IsicDescription}}</td>
                    </tr>
                    <tr>
                        <th>CUODE</th>
                        <td>{{billInfo.Cuode}}</td>
                        <th>CUODE描述</th>
                        <td>{{billInfo.CuodeDescription}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
</div>
</template>

<script>
export default {
    props:["billInfo","ie"],
    data() {
        return {

        };
    },
    methods: {
        
    },
    mounted() {

    },
};
</script>

<style lang="scss" scoped>
.partItem {
    margin-bottom: 20px;
    /deep/.el-card__header {
        padding: 10px 20px;
        .headTitle {
            display: inline-block;
            padding-left: 10px;
            font-size: 14px;
            border-left: 2px solid var(--themeColor);
            font-weight: bold;
        }
    }
}
table {
    width: 100%;
    border-collapse: collapse;
    tr {
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;
        th,
        td {
            font-size: 12px;
            color: #666;
            height: 40px;
            border: 1px solid #eaeaea;
            text-align: center;
        }
        th {
            background-color: #f5f7fa;
            font-weight: 700;
        }
        .bg {
            background-color: var(--themeColor);
            color: #fff;
            font-weight: 700;
            opacity: 0.8;
        }
    }
}
</style>