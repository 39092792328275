<template>
  <div id="tab-encode-encode-analyse-4" class="tab-encode-encode-analyse-4" v-show="!hidden">
    <div class="title" ref="title">
      <div class="text">
        <div style="font-weight: bold">
          【<span style="color: var(--themeColor, #3152AF)">{{ $store.getters.titleText }}</span
          >四位HS编码排名】
        </div>
        <span class="sub-title">(该报表不包含船运数据)</span>
      </div>
      <div style="display: flex; align-items: center">
        <el-radio-group v-model="controlForm.option" size="mini" :disabled="loading">
          <el-radio-button :label="0">频次</el-radio-button>
          <el-radio-button :label="1">金额</el-radio-button>
          <el-radio-button :label="2">数量</el-radio-button>
          <el-radio-button :label="3">重量</el-radio-button>
        </el-radio-group>
        <img v-show="false" class="icon" src="@/firm/images/company/icon-download.png" style="margin-left: 1em" />
      </div>
    </div>
    <div class="content">
      <el-skeleton v-show="loading" :rows="20" animated />
      <encode-analyse-unit v-show="!loading" @start="start" @sucess="sucess" @fail="fail" anchor="HSBM4" :option="controlForm.option" :refresh-trigger="trigger" title="【四位HS编码】" :bar-color="$store.getters.barColor" http-url="/search/Credit/Ranking/HsCode4" />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapMutations } from "vuex";
import encodeAnalyseUnit from "./components/encode-analyse-unit.vue";
import LazyOperation from "@/firm/plugins/lazy-execute";
import { AnchorInditor } from "../../utils";

let lazyOperation = null;

export default Vue.extend({
  components: {
    encodeAnalyseUnit,
  },
  data() {
    return {
      loading: true,
      hidden: false,
      controlForm: {
        option: 0,
      },
      trigger: 0,
    };
  },
  computed: {
    ...mapState(["companyType"]),
    ...mapState("controlFormGlobal", {
      ie: "ie",
      dataCountry: "dataCountry",
      dateRange: "dateRange",
      hsCode: "hsCode",
      importer: "importer",
      exporter: "exporter",
      country: "country",

      exHsCode: "exHsCode",
      exExporter: "exExporter",
      exImporter: "exImporter",
      exCountry: "exCountry",
    }),
  },
  watch: {
    ie() {
      this.hidden = false;
      lazyOperation?.reobserve();
    },
    dataCountry() {
      this.hidden = false;
      lazyOperation?.reobserve();
    },
    dateRange() {
      this.hidden = false;
      lazyOperation?.reobserve();
    },
    hsCode() {
      this.hidden = false;
      lazyOperation?.reobserve();
    },
    importer() {
      this.hidden = false;
      lazyOperation?.reobserve();
    },
    exporter() {
      this.hidden = false;
      lazyOperation?.reobserve();
    },
    country() {
      this.hidden = false;
      lazyOperation?.reobserve();
    },

    exHsCode() {
      this.hidden = false;
      lazyOperation?.reobserve();
    },
    exExporter() {
      this.hidden = false;
      lazyOperation?.reobserve();
    },
    exImporter() {
      this.hidden = false;
      lazyOperation?.reobserve();
    },
    exCountry() {
      this.hidden = false;
      lazyOperation?.reobserve();
    },

    companyType() {
      this.hidden = false;
      lazyOperation?.reobserve();
    },
  },
  methods: {
    ...mapMutations("anchor", {
      setCurrent: "setCurrent",
    }),
    start() {
      this.loading = true;
    },
    sucess() {
      this.hidden = false;
      this.loading = false;
    },
    fail() {
      this.hidden = true;
      this.loading = false;
    },
  },
  mounted() {
    lazyOperation = new LazyOperation(() => {
      this.loading = true;
      this.trigger++;
    }, this.$el);
    new AnchorInditor(() => {
      this.setCurrent("HSBM4");
    }, this.$refs["title"]);
  },
});
</script>

<style lang="scss" scoped>
@import "@/firm/scss/mixin.scss";

.tab-encode-encode-analyse-4 {
  margin-top: 1em;
  background: #fff;
  padding: 1rem;
  padding-top: 0;
  @include mixin-panel-title;
}
</style>
