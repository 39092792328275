<template>
    <div v-loading="infoLoading">
        <div class="w100" v-if="tipMsg">
            <div style="font-size:44px;color:#6a6a6a;padding-top:200px;">{{ tipMsg }}</div>
        </div>
        <div v-else>
            <div class="w100" v-if="isVerifyPwd">
                <div style="padding-top: 200px;width:200px;margin: auto;">
                    <div style="color: #6a6a6a;">请输入密码验证</div>
                    <el-input v-model="inputPwd" size="small" style="margin: 15px 0;" show-password></el-input>
                    <el-button type="primary" size="small" :disabled="!inputPwd.trim()" style="width: 200px;" @click="verifySharePwd">提 交</el-button>
                </div>
            </div>
            <div v-else>
                <div class="w100" v-if="fileCategory == 'img'">
                    <img :src="fileUrl" />
                </div>
                <div v-else-if="fileCategory == 'video'">
                    <video ref="videoDom" class="video-js vjs-default-skin vjs-big-play-centered"
                        controls preload="auto" align="center" :style="{ width: '100vw', height: '100vh' }">
                        <source :src="fileUrl" />
                    </video>
                </div>
                <el-button class="addBtn" v-if="isShowJionBtn" type="primary" plain size="small" @click="jionMydisk" :loading="btnLoading">加入我的网盘</el-button>
            </div>
        </div>

        
        <!-- <div class="w100" v-if="fileCategory == 'img'">
            <img :src="fileUrl" />
        </div>
        <div v-else-if="fileCategory == 'word' || fileCategory == 'excel'">
            <iframe class="child" frameborder="0" :style="{ width: '100vw', height: '100vh' }"
                :src="'https://view.officeapps.live.com/op/view.aspx?src=' + this.fileUrl">
            </iframe>
        </div>
        <div v-else-if="fileCategory == 'video'">
            <video ref="videoDom" class="video-js vjs-default-skin vjs-big-play-centered"
                controls preload="auto" align="center" :style="{ width: '100vw', height: '100vh' }">
                <source :src="m3u8Path" />
            </video>
        </div>
        <div class="w100" v-else>
            <div style="font-size:44px;color:#6a6a6a;padding-top:200px;">暂不支持预览</div>
        </div> -->
    </div>
</template>

<script>
import videojs from "video.js";
import "video.js/dist/video-js.css";
export default {
    data() {
        return {
            uParam: "",
            infoLoading: false,
            tipMsg: "加载中...",
            isVerifyPwd: true,
            fileCategory: "",
            fileUrl: "",
            inputPwd: "",
            player: null,
            isShowJionBtn: false,
            btnLoading: false,
        };
    },
    methods: {
        getShareInfo(){
            this.infoLoading = true;
            this.$axios.get(`${this.$diskUrl}/Open/GetShareInfo?u=${this.uParam}`).then((res) => {
                const { isSuccess, data } = res;
                this.infoLoading = false;
                if(isSuccess){
                    const { fileCategory, isVerifyPwd, msg, url } = data;
                    if (msg) {
                        this.tipMsg = msg;
                    }else{
                        this.tipMsg = "";
                        this.isVerifyPwd = isVerifyPwd;
                        this.fileCategory = fileCategory;
                        this.fileUrl = url;
                    }
                }
            })
        },
        verifySharePwd(){
            this.$axios.get(`${this.$diskUrl}/Open/VerifySharePwd?u=${this.uParam}&pwd=${this.inputPwd}`).then((res) => {
                const { isSuccess, data } = res;
                if(isSuccess){
                    this.isVerifyPwd = false;
                    this.fileUrl = data;
                }            
            })
        },

        jionMydisk(){
            this.btnLoading = true;
            this.$axios.get(`${this.$diskUrl}/File/AddMyNetdisk?u=${this.uParam}`).then((res) => {
                const { isSuccess } = res;
                this.btnLoading = false;
                if(isSuccess){
                    this.$message({ message: '加入成功!', type: 'success'});
                    this.isShowJionBtn = false;
                }            
            })
        }
    },
    mounted() {
        this.uParam = this.$route.query.u;
        this.getShareInfo();
        this.isShowJionBtn = localStorage.getItem("token") ? true : false;
    },
    watch:{
        isVerifyPwd(value){
            if(!value){
                if(this.fileCategory == 'video'){
                    this.$nextTick(() => {
                        this.player = videojs(this.$refs.videoDom);
                    });
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.w100{
    width: 100vw;
    height: 100vh;
    text-align: center;
}
.addBtn{
    position: absolute;
    top: 0;
    right: 0;
}
</style>