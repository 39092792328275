<template>
    <div v-if="infoVisible">
        <el-dialog title="文件详情" :visible.sync="infoVisible" :width="dialogWidth" @close="$emit('closedialog')">
            <div style="display:flex;" v-loading="infoLoading">
                <div class="previewBox" v-show="infoObj.fileCategory == 'img'">
                    <el-image style="width:100%;height:100%;" :src="infoObj.fileUrl" :preview-src-list="[infoObj.fileUrl]"></el-image>
                </div>
                <div class="previewBox" v-show="infoObj.fileCategory == 'video'">
                    <video ref="videoDom" v-if="infoObj.isFragmentationExis" class="video-js vjs-default-skin vjs-big-play-centered"
                        controls preload="auto" align="center" :style="{ width: '100%', height: '100%' }">
                        <source :src="infoObj.m3u8Path" />
                    </video>
                    <div v-else>
                        <el-empty description="视频处理中"></el-empty>
                    </div>
                </div>
                <div class="infoBox">
                    <div class="infoItem">
                        <div class="label">文件名称</div>
                        <div class="value">
                            <span>{{infoObj.fileName}}</span>
                            <el-tag type="warning" size="mini" effect="plain" v-if="infoObj.fileCategory == 'video' && !infoObj.isFragmentationExis">
                                视频处理中
                            </el-tag>
                        </div>
                    </div>
                    <div class="infoItem">
                        <div class="label">文件大小</div>
                        <div class="value">{{infoObj.fileSize}}</div>
                    </div>
                    <div class="infoItem">
                        <div class="label">所属文件夹</div>
                        <div class="value">{{infoObj.folder}}</div>
                    </div>
                    <div class="infoItem">
                        <div class="label">创建人</div>
                        <div class="value">{{infoObj.createUserName}}</div>
                    </div>
                    <div class="infoItem">
                        <div class="label">创建时间</div>
                        <div class="value">{{infoObj.createTime}}</div>
                    </div>
                    <div class="infoItem" v-if="infoObj.updateTime">
                        <div class="label">修改时间</div>
                        <div class="value">{{infoObj.updateTime}}</div>
                    </div>
                    <div class="footer">
                        <el-button @click="fileDownload(infoObj)" type="primary" size="small">下 载</el-button>
                        <el-button @click="$emit('closedialog')" size="small">关 闭</el-button>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
    
<script>
import videojs from "video.js";
import "video.js/dist/video-js.css";
export default {
    props: ["infoVisible", "infoKey"],
    data() {
        return {
            infoObj: {},
            infoLoading: false,
            player: null,
        };
    },
    computed:{
        forbidPreview(){
            return ["zip","exe","other"].includes(this.infoObj.fileCategory);
        },
        dialogWidth(){
            return ["img","video"].includes(this.infoObj.fileCategory) ? "850px" : "450px";
        }
    },
    methods: {
        //获取文件详情
        getFileDetail(){
            this.infoObj = { fileUrl: "" };
            this.infoLoading = true;
            this.$axios.get(`${this.$diskUrl}/Open/GetFileDetail?filekey=${this.infoKey}`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.infoLoading = false;
                    this.infoObj = data;
                    this.infoObj.createTime = this.infoObj.createTime.slice(0,10);
                    // console.log(this.infoObj);
                    if(this.infoObj.fileCategory == 'video' && this.infoObj.isFragmentationExis){
                        this.$nextTick(() => {
                            this.player = videojs(this.$refs.videoDom);
                        });
                    }
                }
            })
        },
        
        //文件下载
        fileDownload(item){
            const link = document.createElement('a');
            link.href = item.fileUrl;
            link.download = item.fileName;
            link.target = '_blank';
            (document.body || document.documentElement).appendChild(link);
            link.click();
            link.remove();
        },

    },
    mounted() {

    },
    watch:{
        //文件key改变时，重新获取文件详情
        infoKey(){
            this.getFileDetail();
        },
        infoVisible(value){
            if(value){
                if(this.infoObj.fileCategory == 'video' && this.infoObj.isFragmentationExis){
                    this.$nextTick(() => {
                        this.player = videojs(this.$refs.videoDom);
                    });
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
/deep/.el-dialog__header{
    display: none;
}
/deep/.el-dialog__body {
    padding: 0;
}
.previewBox{
    width: 400px;
    height: 400px;
}
.infoBox{
    width: 450px;
    height: 400px;
    padding: 20px 20px 20px 0;
    box-sizing: border-box;
    position: relative;
    .footer{
        display: flex;
        justify-content: flex-end;
        position: absolute;
        right: 20px;
        bottom: 20px;
    }
    .infoItem{
        line-height: 32px;
        margin-bottom: 10px;
        display: flex;
        .label{
            width: 80px;
            text-align: right;
            color: #9a9a9a;
            font-weight: bold;
        }
        .value{
            width: calc(100% - 100px);
            margin-left: 20px;
            color: #000;
        }
    }
}
</style>