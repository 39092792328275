<template>
    <div class="productDetails">
        <div class="topContent">
            <div class="topTitle">
                <div class="txt">产品详情</div>
                <div class="stateColor"></div>
                <div class="stateText">{{detailsInfo.isState}}</div>
            </div>
            <div class="rightHandle">
                <div v-if="detailsInfo.isAttention" class="fill" @click="attentionChange(false)">
                    <img src="@/assets/icon/xin1.png" alt="" />
                    已关注
                </div>
                <div v-else class="blank" @click="attentionChange(true)">
                    <img src="@/assets/icon/xin2.png" alt="" />
                    关注
                </div>
                <div v-if="detailsInfo.isTop" class="fill" @click="topChange(false)">
                    <img src="@/assets/icon/ding1.png" alt="" />
                    已置顶
                </div>
                <div v-else class="blank" @click="topChange(true)">
                    <img src="@/assets/icon/ding2.png" alt="" />
                    置顶
                </div>
                <!-- <el-button size="small" type="primary">编辑</el-button>
                <el-dropdown>
                    <el-button size="small" style="margin-left:10px;">
                        更多<i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item>更多操作1</el-dropdown-item>
                        <el-dropdown-item>更多操作2</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown> -->
            </div>
            <div class="productName">
                <img src="@/assets/img/productImg.png" alt="" />
                <div style="padding-left:20px">
                    <div class="nameSpan" v-show="!isEdit">
                        {{detailsInfo.productName}}
                        <i class="el-icon-edit" @click="isEdit = true;"></i>
                    </div>
                    <el-input size="mini" v-show="isEdit" v-model="detailsInfo.productName" @blur="editFinish"></el-input>
                    <!-- <div class="tagInfo">
                        <el-tag v-for="item in tagList" :key="item" type="warning" effect="dark" size="mini">{{item}}</el-tag>
                    </div> -->
                </div>
            </div>
            <div class="topInfo">
                <div class="infoItem">
                    <div class="label">产品编号:</div>
                    <div class="value">{{detailsInfo.productNo}}</div>
                </div>
                <div class="infoItem">
                    <div class="label">供应商:</div>
                    <div class="value">{{detailsInfo.supplier}}</div>
                </div>
                <div class="infoItem">
                    <div class="label">产品分类:</div>
                    <div class="value">
                        <el-select v-model="detailsInfo.groupName" style="width:100%;" size="small">
                            <el-option :value="detailsInfo.groupID" style="height: auto">
                                <el-tree :data="productTreeData" :props="defaultProps" @node-click="chooseTransferGroup"></el-tree>
                            </el-option>
                        </el-select>
                    </div>
                </div>
            </div>
        </div>

        <div class="tabCard">
            <DetailsTwo />
        </div>
    </div>
</template>

<script>
import DetailsTwo from "../OrderManage/ChildComponents/DetailsTwo.vue";     //详情信息
export default {
    components: { DetailsTwo },
    data() {
        return {
            tagList: [],
            //产品详情展示
            productKey: "",
            detailsInfo: {},
            // 编辑产品名称
            isEdit: false,
            // 修改产品分类
            productTreeData: [],
            defaultProps: {
                children: "children",
                label: "name",
            },
        };
    },
    methods: {
        //获取产品详情
        getProductInfo(){
            this.$axios.get(`${this.$productUrl}/api/Product/Detail?key=${this.productKey}`).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    this.detailsInfo = data;
                    document.title = `产品详情-${this.detailsInfo.productName}`
                }
            });
        },
        //是否关注
        attentionChange(value){
            this.$axios.get(`${this.$productUrl}/api/Product/SetProductIsTopOrIsAttention?productKey=${this.productKey}&type=1&state=${value}`).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    this.$message({ message: '操作成功', type: 'success'});
                    this.detailsInfo.isAttention = value;
                }
            });
        },
        //是否置顶
        topChange(value){
            this.$axios.get(`${this.$productUrl}/api/Product/SetProductIsTopOrIsAttention?productKey=${this.productKey}&type=0&state=${value}`).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    this.$message({ message: '操作成功', type: 'success'});
                    this.detailsInfo.isTop = value;
                }
            });
        },     

        //编辑产品名称
        editFinish(){
            this.isEdit = false;
            this.$axios.post(`${this.$productUrl}/api/Product/AddUpdate`,this.detailsInfo).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    this.$message({ message: '修改名称成功', type: 'success'});
                }
            });
        },

        //获取产品分类数据
        getProductClassifyData(){
            this.$axios.get(`${this.$productUrl}/api/ProductGroup/GetAll`).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    this.productTreeData = data;
                }
            });
        },
        chooseTransferGroup(data){
            this.detailsInfo.groupName = data.name;
            this.detailsInfo.groupID = data.key;
            this.$axios.post(`${this.$productUrl}/api/Product/AddUpdate`,this.detailsInfo).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    this.$message({ message: '修改分类成功', type: 'success'});
                }
            });
        },

    },
    mounted() {
        this.productKey = this.$route.query.productKey;
        this.getProductInfo();      //获取产品详情

        this.getProductClassifyData();
    },
};
</script>

<style lang="scss" scoped>
.productDetails{
    .topContent{
        background: rgba($color: #FFF, $alpha: 0.6);
        padding: 20px;
        position: relative;
        .topTitle{
            display: flex;
            height: 28px;
            line-height: 28px;
            .txt{
                font-size: 20px;
                font-weight: bold;
                color: #383838;
            }
            .stateColor{
                margin: 8px 5px 0 30px;
                width: 12px;
                height: 12px;
                background: #20BC5F;
                border-radius: 12px;
            }
            .stateText{
                font-size: 14px;
                color: #707070;
            }
        }
        .rightHandle{
            position: absolute;
            top: 20px;
            right: 20px;
            .fill{
                display: inline-block;
                margin-right: 10px;
                cursor: pointer;
                padding: 0 10px 0 25px;
                text-align: center;
                height: 30px;
                line-height: 30px;
                border-radius: 2px;
                background: #FFA42D;
                color: #FFFFFF;
                font-size: 12px;
                position: relative;
                img{
                    position: absolute;
                    top: 9px;
                    left: 10px;
                }
            }
            .blank{
                display: inline-block;
                margin-right: 10px;
                cursor: pointer;
                padding: 0 10px 0 25px;
                text-align: center;
                height: 28px;
                line-height: 28px;
                border-radius: 2px;
                background: #FFFFFF;
                border: 1px solid #D7D7D8;
                color: #FFA42D;
                font-size: 12px;
                position: relative;
                img{
                    position: absolute;
                    top: 9px;
                    left: 10px;
                }
            }
        }
        .productName{
            margin-top: 15px;
            display: flex;
            .nameSpan{
                font-weight: bold;
                color: #092338;
                cursor: pointer;
                .el-icon-edit{
                    display: none;
                }
                &:hover .el-icon-edit{
                    display: inline-block;
                }
            }
        }
        .topInfo{
            margin-top: 15px;
            display: flex;
            .infoItem{
                width: 200px;
                margin-right: 20px;
                .label{
                    height: 20px;
                    line-height: 20px;
                    font-size: 14px;
                    color: #5E6D82;
                }
                .value{
                    height: 28px;
                    line-height: 28px;
                    font-size: 14px;
                    color: #383838;
                }
            }
        }
    }
    .tabCard{
        margin-top: 20px;
        background: #FFFFFF;
        padding: 20px;
    }
}
</style>
