<template>
    <div class="detailsView" v-loading="infoLoading">
        <div class="topInfo">
            <div class="businessName">{{viewInfo.businessName}}</div>
            <div class="country">
                <img :src="viewInfo.nationalFlag" />
                <span>{{viewInfo.countryCn}}</span>
            </div>
            <el-row :gutter="24">
                <el-col :span="12" class="fieldItem">语言：{{viewInfo.language}}</el-col>
                <el-col :span="12" class="fieldItem">成立年份：{{viewInfo.yearStarted}}</el-col>
                <el-col :span="12" class="fieldItem">网址：
                    <el-link :underline="false" type="primary" @click="openWebsite(viewInfo.website)">{{viewInfo.website}}</el-link>
                </el-col>
                <el-col :span="12" class="fieldItem">邮政编码：{{viewInfo.postCode}}</el-col>
                <el-col :span="12" class="fieldItem">地址：{{viewInfo.address1}}</el-col>
                <el-col :span="12" class="fieldItem">数据更新日期：{{viewInfo.reportDate}}</el-col>
            </el-row>
        </div>
        <el-card>
            <div class="enterpriseInfo">
                <div class="hasAdd" v-if="viewInfo.isMyClue">已加入我的线索</div>
                <div class="addBtn" @click="addMyClueTwo(viewInfo)" :class="isDisable ? 'forbidden' :''" v-else>
                    加入我的线索
                </div>
                <div class="title">企业信息</div>
                <el-row :gutter="0" class="content">
                    <el-col :span="12" class="fieldItem">
                        <span class="label">国家：</span>
                        <span class="value">{{viewInfo.country}}{{viewInfo.countryCode ? `(${viewInfo.countryCode})`: "" }}</span>
                    </el-col>
                    <el-col :span="12" class="fieldItem">
                        <span class="label">省：</span>
                        <span class="value">{{viewInfo.province}}{{viewInfo.provinceCode ? `(${viewInfo.provinceCode})`: "" }}</span>
                    </el-col>
                    <el-col :span="12" class="fieldItem">
                        <span class="label">城市：</span>
                        <span class="value">{{viewInfo.city}}{{viewInfo.cityCode ? `(${viewInfo.cityCode})`: "" }}</span>
                    </el-col>
                    <el-col :span="12" class="fieldItem">
                        <span class="label">地区：</span>
                        <span class="value">{{viewInfo.region}}{{viewInfo.regionCode ? `(${viewInfo.regionCode})`: "" }}</span>
                    </el-col>
                    <el-col :span="12" class="fieldItem">
                        <span class="label">状态信息：</span>
                        <span class="value">{{viewInfo.statusCodeName}}</span>
                    </el-col>
                    <el-col :span="12" class="fieldItem">
                        <span class="label">数据更新日期：</span>
                        <span class="value">{{viewInfo.reportDate}}</span>
                    </el-col>
                    <el-col :span="12" class="fieldItem">
                        <span class="label">商品名：</span>
                        <span class="value">{{viewInfo.tradeName}}</span>
                    </el-col>
                    <el-col :span="12" class="fieldItem">
                        <span class="label">货币：</span>
                        <span class="value">{{viewInfo.currency}}</span>
                    </el-col>
                    <el-col :span="12" class="fieldItem">
                        <span class="label">地理等级：</span>
                        <span class="value">{{viewInfo.geoLevelDescription}}</span>
                    </el-col>
                    <el-col :span="12" class="fieldItem">
                        <span class="label">电话：</span>
                        <span class="value">{{viewInfo.phone}}</span>
                    </el-col>
                    <el-col :span="12" class="fieldItem">
                        <span class="label">传真：</span>
                        <span class="value">{{viewInfo.fax}}</span>
                    </el-col>
                    <el-col :span="12" class="fieldItem">
                        <span class="label">邮箱：</span>
                        <span class="value">{{viewInfo.email}}</span>
                    </el-col>
                    <el-col :span="12" class="fieldItem">
                        <span class="label">网域：</span>
                        <span class="value">{{viewInfo.webDomain}}</span>
                    </el-col>
                    <el-col :span="12" class="fieldItem">
                        <span class="label">网站IP地址：</span>
                        <span class="value">{{viewInfo.websiteIpAddress}}</span>
                    </el-col>
                    <el-col :span="12" class="fieldItem">
                        <span class="label">销售额：</span>
                        <span class="value">
                            {{viewInfo.salesVolumeDollars}}美元
                            {{viewInfo.salesVolumeEuros}}欧元
                        </span>
                    </el-col>
                    <el-col :span="12" class="fieldItem">
                        <span class="label">销售额：</span>
                        <span class="value">{{viewInfo.salesVolume}}({{viewInfo.currency}})</span>
                    </el-col>
                    <el-col :span="12" class="fieldItem">
                        <span class="label">员工总数：</span>
                        <span class="value">{{viewInfo.employeesTotal}}</span>
                    </el-col>
                    <el-col :span="12" class="fieldItem">
                        <span class="label">工商编号：</span>
                        <span class="value">{{viewInfo.nationalID}}</span>
                    </el-col>
                    <el-col :span="12" class="fieldItem">
                        <span class="label">公司购买力：</span>
                        <span class="value">{{viewInfo.publishingStrength}}</span>
                    </el-col>
                    <el-col :span="12" class="fieldItem">
                        <span class="label">是否销售：</span>
                        <span class="value">{{viewInfo.hasMarketability}}</span>
                    </el-col>
                    <el-col :span="12" class="fieldItem">
                        <span class="label">进出口代理：</span>
                        <span class="value">{{viewInfo.importExportAgentCodeDescription}}</span>
                    </el-col>
                    <el-col :span="12" class="fieldItem">
                        <span class="label">商业形式：</span>
                        <span class="value">{{viewInfo.legalStatusCodeDescription}}</span>
                    </el-col>
                    <el-col :span="12" class="fieldItem">
                        <span class="label">CEO：</span>
                        <span class="value">{{viewInfo.ceoName}}</span>
                    </el-col>
                    <el-col :span="12" class="fieldItem">
                        <span class="label">职位：</span>
                        <span class="value">{{viewInfo.ceoTitle}}</span>
                    </el-col>
                    <el-col :span="12" class="fieldItem">
                        <span class="label">性别：</span>
                        <span class="value">{{viewInfo.ceoGender}}</span>
                    </el-col>
                    <el-col :span="12" class="fieldItem">
                        <span class="label">全球公司数：</span>
                        <span class="value">{{viewInfo.familyMembers}}</span>
                    </el-col>
                    <el-col :span="24" class="fieldItem" v-if="viewInfo.parentLinkage">
                        <span class="label">母公司：</span>
                        <span class="label">公司名：</span>
                        <span class="value">{{viewInfo.parentLinkage.businessName}}</span>
                        <span class="label">国家：</span>
                        <span class="value">{{viewInfo.parentLinkage.country}}</span>
                        <span class="label">省：</span>
                        <span class="value">{{viewInfo.parentLinkage.province}}</span>
                        <span class="label">城市名：</span>
                        <span class="value">{{viewInfo.parentLinkage.cityName}}</span>
                        <span class="label">街道：</span>
                        <span class="value">{{viewInfo.parentLinkage.streetAddress}}</span>
                        <span class="label">邮编：</span>
                        <span class="value">{{viewInfo.parentLinkage.postalCode}}</span>
                    </el-col>
                    <el-col :span="24" class="fieldItem" v-if="viewInfo.domesticLinkage">
                        <span class="label">国内主公司：</span>
                        <span class="label">公司名：</span>
                        <span class="value">{{viewInfo.domesticLinkage.businessName}}</span>
                        <span class="label">国家：</span>
                        <span class="value">{{viewInfo.domesticLinkage.country}}</span>
                        <span class="label">省：</span>
                        <span class="value">{{viewInfo.domesticLinkage.province}}</span>
                        <span class="label">城市名：</span>
                        <span class="value">{{viewInfo.domesticLinkage.cityName}}</span>
                        <span class="label">街道：</span>
                        <span class="value">{{viewInfo.domesticLinkage.streetAddress}}</span>
                        <span class="label">邮编：</span>
                        <span class="value">{{viewInfo.domesticLinkage.postalCode}}</span>
                    </el-col>
                    <el-col :span="24" class="fieldItem" v-if="viewInfo.globalLinkage">
                        <span class="label">全球主公司：</span>
                        <span class="label">公司名：</span>
                        <span class="value">{{viewInfo.globalLinkage.businessName}}</span>
                        <span class="label">国家：</span>
                        <span class="value">{{viewInfo.globalLinkage.country}}</span>
                        <span class="label">省：</span>
                        <span class="value">{{viewInfo.globalLinkage.province}}</span>
                        <span class="label">城市名：</span>
                        <span class="value">{{viewInfo.globalLinkage.cityName}}</span>
                        <span class="label">街道：</span>
                        <span class="value">{{viewInfo.globalLinkage.streetAddress}}</span>
                        <span class="label">邮编：</span>
                        <span class="value">{{viewInfo.globalLinkage.postalCode}}</span>
                    </el-col>
                </el-row>
            </div>
            <div class="infoItem">
                <div class="title">金融信息</div>
                <el-table :data="viewInfo.financialHistory" size="small" border>
                    <el-table-column prop="yearStat" label="年份统计"></el-table-column>
                    <el-table-column prop="salesVolume" label="销售额">
                        <template slot-scope="scope">
                            {{scope.row.salesVolume}} {{scope.row.currency}}                            
                        </template>
                    </el-table-column>
                    <el-table-column prop="salesVolumeEuros" label="销售额(欧元)"></el-table-column>
                    <el-table-column prop="salesVolumeDollars" label="销售额(美元)"></el-table-column>
                    <el-table-column prop="salesVolumeReliabilityCodeDescription" label="销售额可靠性"></el-table-column>
                    <el-table-column prop="employeesTotal" label="员工数"></el-table-column>
                    <el-table-column prop="employeesTotalReliabilityCodeDescription" label="员工数可靠性"></el-table-column>
                </el-table>
            </div>
            <div class="infoItem">
                <div class="title">高管信息</div>
                <el-table :data="viewInfo.executives" size="small" border>
                    <el-table-column prop="fullName" label="姓名"></el-table-column>
                    <el-table-column prop="gender" label="性别" width="180"></el-table-column>
                    <el-table-column prop="position" label="职位" width="80"></el-table-column>
                    <el-table-column prop="title" label="职称"></el-table-column>
                </el-table>
            </div>
            <div class="infoItem">
                <div class="title">行业分类(SIC)</div>
                <el-table :data="viewInfo.sicIndustryCodes" size="small" border>
                    <el-table-column prop="code" label="代码" width="100"></el-table-column>
                    <el-table-column prop="describeCn" label="描述(中文)"></el-table-column>
                    <el-table-column prop="describe" label="描述(英文)"></el-table-column>
                </el-table>
            </div>
            <div class="infoItem">
                <div class="title">行业分类(NACE)</div>
                <el-table :data="viewInfo.naceIndustryCodes" size="small" border>
                    <el-table-column prop="code" label="代码" width="100"></el-table-column>
                    <el-table-column prop="describeCn" label="描述(中文)"></el-table-column>
                    <el-table-column prop="describe" label="描述(英文)"></el-table-column>
                </el-table>
            </div>
            <div class="infoItem" v-if="viewInfo.contacts.length != 0">
                <div class="title">外贸邮联系人</div>
                <el-table :data="viewInfo.contacts" size="small" border>
                    <el-table-column prop="name" label="姓名" width="250"></el-table-column>
                    <el-table-column prop="companyName" label="公司名" width="120"></el-table-column>
                    <el-table-column prop="email" label="邮箱地址"></el-table-column>
                    <el-table-column prop="position" label="职位" width="120"></el-table-column>
                </el-table>
            </div>
        </el-card>
    </div>
</template>

<script>
export default {
    data() {
        return {
            uniqueId: 0,
            infoLoading: false,
            viewInfo: {
                parentLinkage: null,    //母公司
                domesticLinkage: null,  //国内主公司
                globalLinkage: null,    //全球主公司
                financialHistory: [],   //金融信息
                executives: [],         //高管信息
                sicIndustryCodes: [],   //行业分类(SIC)
                naceIndustryCodes: [],  //行业分类(NACE)
                contacts: [],           //外贸邮联系人
            },
            isDisable: false,
        };
    },
    methods: {
        openWebsite(url){
            let jumpUrl = url.indexOf("http") > -1 ? url : "http://" + url;
            let routeData = this.$router.resolve({
                path: "/jumpWeb",
                query: { jumpUrl: jumpUrl },
            });
            window.open(routeData.href, "_blank");
        },
        //加入我的线索
        addMyClueTwo(item){
            if(this.isDisable){
                return false;
            }
            let submitObj = {
                "clueSource": 10,
                "clueRelationKey": "",
                "companyName": item.businessName,
                "country": item.countryCn,
                "legalPerson": "",
                "contactNumber": "",
                "registrationTime": "",
                "employeesNumber": item.employeesTotal,
                "officialWebsite": item.website,
                "corporateEmail": item.email,
                "businessAddress": item.address,
                "companyProfile": "",
                "industrys": [],
            }
            this.isDisable = true;
            this.$axios.post(`${this.$clueUrl}/MyClue/AddMyClue`, submitObj).then((res) => {
                const { isSuccess } = res;
                this.isDisable = false;
                if (isSuccess) {
                    this.$message({ message: '添加成功', type: 'success'});
                    item.isMyClue = true;
                }
            });
        },
    },
    mounted() {
        this.uniqueId = this.$route.query.uniqueId;
        // 查询infobel公司详情
        this.infoLoading = true;
        this.$axios.get(`${this.$clueUrl}/BusinessData/Infobel/Info?uniqueId=${this.uniqueId}`).then((res) => {
            const { isSuccess, data } = res;
            this.infoLoading = false;
            if (isSuccess) {
                this.viewInfo = data;
                this.viewInfo.countryCn = this.$route.query.countryCn;
                this.viewInfo.nationalFlag = window.getFlagUrlByCountry?window.getFlagUrlByCountry(this.viewInfo.countryCn):'';
                // console.log(data);
            }
        });
    },
};
</script>

<style lang="scss" scoped>
.detailsView{
    width: 1200px;
    margin: auto;
    .topInfo{
        height: 200px;
        padding: 40px;
        background-image: url("../../assets/cluePicture/infobel_bg.png");
        background-position: center;
        background-size: 100% 100%;
        color: #fff;
        .businessName{
            font-weight: bold;
            font-size: 28px;
            margin-bottom: 20px;
        }
        .country{
            font-size: 16px;
            height: 32px;
            line-height: 32px;
            margin-bottom: 10px;
            img{
                vertical-align: middle;
                margin-right: 10px;
                height: 16px;
            }
        }
        .fieldItem{
            font-size: 14px;
            height: 28px;
            line-height: 28px;
        }
    }
    .enterpriseInfo{
        .title{
            color: #2A3F58FF;
            font-size: 18px;
            height: 22px;
            line-height: 22px;
            padding-left: 8px;
            border-left: 5px solid var(--themeColor);
            margin-bottom: 10px;
        }
        .content{
            background: #FFFAF5;
            padding: 10px;
            font-size: 14px;
            .fieldItem{
                // height: 30px;
                line-height: 30px;
                .label{
                    color: #8492A6;
                    padding-left: 10px;
                }
                .value{
                    color: #6a6a6a;
                }
            }
        }
    }
    .infoItem{
        margin-top: 20px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
        .title{
            padding: 0 12px;
            height: 40px;
            line-height: 40px;
            font-size: 16px;
            background: #E5E9F2;
            color: #353A3E;
            font-weight: bold;
        }
    }
}
.enterpriseInfo{
    position: relative;
}
.addBtn{
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 10px;
    height: 28px;
    line-height: 28px;
    font-size: 14px;
    color: var(--themeColor);
    background: #eaeef7;
    border: 1px solid #adbadf;
    border-radius: 4px;
    cursor: pointer;
    &:hover{
        background: var(--themeColor);
        color: #FFFFFF;
    }
    img{
        position: absolute;
        left: 10px;
        top: 5px;
    }
}
.forbidden{
    cursor: not-allowed;
}
.hasAdd{
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 10px;
    height: 28px;
    line-height: 28px;
    font-size: 14px;
    color: #B7B7B7;
    background: rgba(183, 183, 183, 0.2);
    border: 1px solid #B7B7B7;
    border-radius: 4px;
}
</style>