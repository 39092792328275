<template>
    <div class="searchBox">
        <div class="title">
            <span>公司规模</span>
        </div>
        <el-form :model="scaleForm" labelPosition='top' size="small">
            <el-row :gutter="30">
                <el-col :span="6">
                    <el-form-item label="员工人数区间">
                        <el-input type="number" v-model="scaleForm.employeesTotalFrom" @change="fieldChange" style="width:calc(50% - 20px)"></el-input>
                        <span style="width:40px;display: inline-block;text-align: center;">至</span>
                        <el-input type="number" v-model="scaleForm.employeesTotalTo" @change="fieldChange" style="width:calc(50% - 20px)"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="销售额区间">
                        <el-input type="number" v-model="scaleForm.salesVolumeFrom" @change="fieldChange" style="width:calc(50% - 60px)"></el-input>
                        <span style="width:40px;display: inline-block;text-align: center;">至</span>
                        <el-input type="number" v-model="scaleForm.salesVolumeTo" @change="fieldChange" style="width:calc(50% - 60px)"></el-input>
                        <el-select v-model="scaleForm.salesVolumeCurrency" style="width:80px;">
                            <el-option label="美元" :value="1"></el-option>
                            <el-option label="欧元" :value="2"></el-option>
                            <el-option label="本地货币" :value="0"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="家庭成员数区间">
                        <el-input type="number" v-model="scaleForm.familyMembersFrom" @change="fieldChange" style="width:calc(50% - 20px)"></el-input>
                        <span style="width:40px;display: inline-block;text-align: center;">至</span>
                        <el-input type="number" v-model="scaleForm.familyMembersTo" @change="fieldChange" style="width:calc(50% - 20px)"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
export default {
    data() {
        return {
            scaleForm: {
                employeesTotalFrom: "",
                employeesTotalTo: "",
                salesVolumeFrom: "",
                salesVolumeTo: "",
                salesVolumeCurrency: 1,
                familyMembersFrom: "",
                familyMembersTo: "",               
            },
        };
    },
    methods: {
        fieldChange(){
            this.$emit('inputComplete',this.scaleForm);
        },
    },
    mounted() {
        
    },
};
</script>

<style lang="scss" scoped>
.searchBox{
    border-top: 1px solid #E6E6E6;
    padding-left: 40px;
    .title{
        color: #3A3A3A;
        font-weight: bold;
        font-size: 16px;
        margin: 10px 0;
    }
}
</style>