<template>
    <div>
        <InterfaceName :interfaceName="interfaceName" />
        <InterfaceSpe :requestUrl="requestUrl" :requestMethod="requestMethod" />
        <InputParam :inputTableData="inputTableData" />
        <ReturnParam :returnObj="returnObj" />
    </div>
</template>

<script>
import InterfaceName from "../Acomponents/InterfaceName.vue";
import InterfaceSpe from "../Acomponents/InterfaceSpe.vue";
import InputParam from "../Acomponents/InputParam.vue";
import ReturnParam from "../Acomponents/ReturnParam.vue";

export default {
    components: { InterfaceName, InterfaceSpe, InputParam, ReturnParam },
    data() {
        return {
            interfaceName: "获取我的订单",
            requestUrl: "https://crm.qixinerp.com/open/Order/List",
            requestMethod: "POST",
            inputTableData: [],
            returnObj: {},
        };
    },
    methods: {},
    mounted() {
        this.inputTableData = [
            { paramName: "orderNo",valueType: "string",explain: "订单编号",required: false },
            { paramName: "orderName",valueType: "string",explain: "订单名称",required: false },
            { paramName: "customerName",valueType: "string",explain: "客户名称",required: false },
            { paramName: "createTime",valueType: "string",explain: "创建时间",required: false },
            { paramName: "orderStatus",valueType: "string",explain: "订单状态",required: false },
            { paramName: "pageIndex",valueType: "integer",explain: "当前页码",required: true },
            { paramName: "pageSize",valueType: "integer",explain: "每页条数",required: true },
        ];
        this.returnObj = {
            "isSuccess": true,
            "errorMessage": null,
            "data": {
                "datas": [
                    {
                        "key": "0f5ac92de3aa43a9a02b0c2b9b5f26c0",
                        "uniqueKey": "d4aa3ce6ebfb46c082ece303f4d4e0f1",
                        "orderName": "0511采购",
                        "orderNo": "20230511161933114",
                        "orderDate": "2023-05-11",
                        "orderAmount": 80000,
                        "customerName": "GLADEMIR BUSI",
                        "customerKey": "61a683bdee08474198fe2c101a242bec",
                        "orderStatus": "草稿",
                        "currency": "CNY",
                        "deliveryDate": "2023-05-18",
                        "expectedReceiptDate": "2023-05-18"
                    },
                ],
                "pageSize": 10,
                "pageIndex": 1,
                "totalItems": 1
            },
        }
    },
};
</script>

<style lang="scss" scoped>

</style>