<template>
    <div>
        <!-- <div class="header">
            <img class="logoImg" src="@/assets/img/smallLogo.png" alt="" />
            <span class="txt">企芯</span>
        </div> -->
        <div class="w">
            <div class="leftBox">
                <div class="title">企芯{{versionValue}}({{yearValue}})</div>
                <div class="content" v-if="versionValue=='启航版'">
                    <div class="moduleTitle">获客模块</div>
                    <div class="permissionItem">
                        <div class="leftName">社媒获客</div>
                        <div class="piece">
                            <i class="el-icon-circle-check"></i>
                        </div>
                    </div>
                    <div class="permissionItem">
                        <div class="leftName">客户线索</div>
                        <div class="piece">
                            <i class="el-icon-circle-check"></i>
                        </div>
                    </div>
                    <div class="permissionItem">
                        <div class="leftName">商机推荐</div>
                        <div class="piece">
                            <i class="el-icon-circle-check"></i>
                        </div>
                    </div>
                    <div class="permissionItem">
                        <div class="leftName">企业资讯</div>
                        <div class="piece">
                            <i class="el-icon-circle-close"></i>
                        </div>
                    </div>
                    <div class="permissionItem">
                        <div class="leftName">客户管理</div>
                        <div class="piece">
                            <i class="el-icon-circle-close"></i>
                        </div>
                    </div>
                    <div class="permissionItem">
                        <div class="leftName">客户营销（EDM、WA等）</div>
                        <div class="piece">
                            <i class="el-icon-circle-close"></i>
                        </div>
                    </div>
                    <div class="permissionItem">
                        <div class="leftName">贸易全景</div>
                        <div class="piece">
                            <i class="el-icon-circle-close"></i>
                        </div>
                    </div>
                    <div class="moduleTitle">营销模块</div>
                    <div class="permissionItem">
                        <div class="leftName">订单管理</div>
                        <div class="piece">
                            <i class="el-icon-circle-close"></i>
                        </div>
                    </div>
                    <div class="permissionItem">
                        <div class="leftName">出口选品 ToB</div>
                        <div class="piece">
                            <i class="el-icon-circle-close"></i>
                        </div>
                    </div>
                    <div class="permissionItem">
                        <div class="leftName">电商选品 ToC</div>
                        <div class="piece">
                            <i class="el-icon-circle-check"></i>
                        </div>
                    </div>
                    <div class="permissionItem">
                        <div class="leftName">预警提示</div>
                        <div class="piece">
                            <i class="el-icon-circle-check"></i>
                        </div>
                    </div>
                    <div class="permissionItem">
                        <div class="leftName">产品手册配置</div>
                        <div class="piece">
                            <i class="el-icon-circle-check"></i>
                        </div>
                    </div>
                    <div class="permissionItem">
                        <div class="leftName">合同、报价配置</div>
                        <div class="piece">
                            <i class="el-icon-circle-check"></i>
                        </div>
                    </div>
                    <div class="moduleTitle">工具类</div>
                    <div class="permissionItem">
                        <div class="leftName">全球企业风险核实</div>
                        <div class="piece">
                            <i class="el-icon-circle-close"></i>
                        </div>
                    </div>
                    <div class="permissionItem">
                        <div class="leftName">营销分析</div>
                        <div class="piece">
                            <i class="el-icon-circle-close"></i>
                        </div>
                    </div>
                    <div class="permissionItem">
                        <div class="leftName">进出口验证</div>
                        <div class="piece">
                            <i class="el-icon-circle-check"></i>
                        </div>
                    </div>
                    <div class="permissionItem">
                        <div class="leftName">API服务</div>
                        <div class="piece">
                            <i class="el-icon-circle-close"></i>
                        </div>
                    </div>
                    <div class="permissionItem">
                        <div class="leftName">集团ERP中台服务</div>
                        <div class="piece">
                            <i class="el-icon-circle-close"></i>
                        </div>
                    </div>
                    <div class="permissionItem">
                        <div class="leftName">外贸工具</div>
                        <div class="piece">
                            <i class="el-icon-circle-close"></i>
                        </div>
                    </div>
                    <div class="permissionItem">
                        <div class="leftName">行业分析</div>
                        <div class="piece">
                            <i class="el-icon-circle-close"></i>
                        </div>
                    </div>
                </div>
                <div class="content" v-if="versionValue=='企业版'">
                    <div class="moduleTitle">获客模块</div>
                    <div class="permissionItem">
                        <div class="leftName">社媒获客</div>
                        <div class="piece">
                            <i class="el-icon-circle-check"></i>
                        </div>
                    </div>
                    <div class="permissionItem">
                        <div class="leftName">客户线索</div>
                        <div class="piece">
                            <i class="el-icon-circle-check"></i>
                        </div>
                    </div>
                    <div class="permissionItem">
                        <div class="leftName">商机推荐</div>
                        <div class="piece">
                            <i class="el-icon-circle-check"></i>
                        </div>
                    </div>
                    <div class="permissionItem">
                        <div class="leftName">企业资讯</div>
                        <div class="piece">
                            <i class="el-icon-circle-check"></i>
                        </div>
                    </div>
                    <div class="permissionItem">
                        <div class="leftName">客户管理</div>
                        <div class="piece">
                            <i class="el-icon-circle-check"></i>
                        </div>
                    </div>
                    <div class="permissionItem">
                        <div class="leftName">客户营销（EDM、WA等）</div>
                        <div class="piece">
                            <i class="el-icon-circle-check"></i>
                        </div>
                    </div>
                    <div class="permissionItem">
                        <div class="leftName">贸易全景</div>
                        <div class="piece">
                            <i class="el-icon-circle-check"></i>
                        </div>
                    </div>
                    <div class="moduleTitle">营销模块</div>
                    <div class="permissionItem">
                        <div class="leftName">订单管理</div>
                        <div class="piece">
                            <i class="el-icon-circle-close"></i>
                        </div>
                    </div>
                    <div class="permissionItem">
                        <div class="leftName">出口选品 ToB</div>
                        <div class="piece">
                            <i class="el-icon-circle-close"></i>
                        </div>
                    </div>
                    <div class="permissionItem">
                        <div class="leftName">电商选品 ToC</div>
                        <div class="piece">
                            <i class="el-icon-circle-check"></i>
                        </div>
                    </div>
                    <div class="permissionItem">
                        <div class="leftName">预警提示</div>
                        <div class="piece">
                            <i class="el-icon-circle-check"></i>
                        </div>
                    </div>
                    <div class="permissionItem">
                        <div class="leftName">产品手册配置</div>
                        <div class="piece">
                            <i class="el-icon-circle-check"></i>
                        </div>
                    </div>
                    <div class="permissionItem">
                        <div class="leftName">合同、报价配置</div>
                        <div class="piece">
                            <i class="el-icon-circle-check"></i>
                        </div>
                    </div>
                    <div class="moduleTitle">工具类</div>
                    <div class="permissionItem">
                        <div class="leftName">全球企业风险核实</div>
                        <div class="piece">
                            <i class="el-icon-circle-close"></i>
                        </div>
                    </div>
                    <div class="permissionItem">
                        <div class="leftName">营销分析</div>
                        <div class="piece">
                            <i class="el-icon-circle-close"></i>
                        </div>
                    </div>
                    <div class="permissionItem">
                        <div class="leftName">进出口验证</div>
                        <div class="piece">
                            <i class="el-icon-circle-check"></i>
                        </div>
                    </div>
                    <div class="permissionItem">
                        <div class="leftName">API服务</div>
                        <div class="piece">
                            <i class="el-icon-circle-close"></i>
                        </div>
                    </div>
                    <div class="permissionItem">
                        <div class="leftName">集团ERP中台服务</div>
                        <div class="piece">
                            <i class="el-icon-circle-close"></i>
                        </div>
                    </div>
                    <div class="permissionItem">
                        <div class="leftName">外贸工具</div>
                        <div class="piece">
                            <i class="el-icon-circle-close"></i>
                        </div>
                    </div>
                    <div class="permissionItem">
                        <div class="leftName">行业分析</div>
                        <div class="piece">
                            <i class="el-icon-circle-close"></i>
                        </div>
                    </div>
                </div>
                <div class="title" style="margin-top:20px;font-size:24px;">
                    <span>加油包</span>
                    <span style="font-size:14px;padding-left:20px;color:#6a6a6a;">购买请咨询客服</span>
                </div>
                <div class="content">
                    <div class="permissionItem">风险验证：500次；</div>
                    <div class="permissionItem">群发；</div>
                    <div class="permissionItem">WA官方接口；</div>
                    <div class="permissionItem">商机推荐次数；</div>
                    <div class="permissionItem">行业报告份数；</div>
                    <div class="permissionItem">开放工具：HS查询，船公司查询，港口查询，</div>
                    <div class="permissionItem">国家查询；公司查询；时区；汇率；GDP等；</div>
                </div>
            </div>
            <div class="rightBox">
                <div class="chooseField" style="margin-top: 20px;">
                    <div class="label">产品选择：</div>
                    <div class="value">
                        <el-radio-group v-model="versionValue" @change="getPrice" disabled>
                            <el-radio label="启航版" border style="width:120px;text-align: center;">启航版</el-radio>
                            <el-radio label="企业版" border style="width:120px;text-align: center;">企业版</el-radio>
                        </el-radio-group>
                    </div>
                </div>
                <div class="chooseField">
                    <div class="label">使用期限：</div>
                    <div class="value">
                        <el-radio-group v-model="yearValue" @change="getPrice" disabled>
                            <el-radio label="一年" border style="width:120px;text-align: center;">一年</el-radio>
                            <el-radio label="两年" border style="width:120px;text-align: center;">两年</el-radio>
                        </el-radio-group>
                    </div>
                </div>
                <div class="chooseField">
                    <div class="label">原价：</div>
                    <div class="value">
                        <span class="originalPrice">¥{{originalPrice}}</span>
                        <span class="unit">元</span>
                    </div>
                </div>
                <div class="chooseField">
                    <div class="label">折扣价：</div>
                    <div class="value">
                        <span class="discountPrice">¥{{discountPrice}}</span>
                        <span class="unit">元</span>
                        <span class="validity">折扣价格有效期：{{satrtTime}} 至 {{endTime}}</span>
                    </div>
                </div>
                <div class="chooseField">
                    <div class="label">支付方式：</div>
                    <div class="value">
                        <el-radio-group v-model="payment" @change="payChange" :disabled="codeLoading">
                            <el-radio :label="1" border style="width:250px;text-align: center;" class="payItem">
                                <img src="@/assets/img/zhifuone.png" alt="" />
                                <span>微信支付</span>
                            </el-radio>
                            <el-radio :label="2" border style="width:250px;text-align: center;" class="payItem">
                                <img src="@/assets/img/zhifutwo.png" alt="" />
                                <span>支付宝支付</span>
                            </el-radio>
                        </el-radio-group>
                    </div>
                </div>
                <div class="codeBox" v-loading="codeLoading">
                    <CountDown class="count_down" v-if="isShowCount" :endTime="timeRxpire" />
                    <img v-if="payment == 1" :src="wxCode" alt="" />
                    <iframe v-if="payment == 2" id="alipayFrame" style="width: 265px;height: 265px;margin:0;padding:0;border: none;"></iframe>
                </div>
                <div class="contactus">联系我们</div>
                <div class="otherInfo">
                    <span>电话：400 118 1622 (工作日 9:00 - 18:00)</span>
                    <span style="margin-left: 60px;">邮箱：info@lsmaps.com</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
let timer = null;
import { Decrypt } from '@/assets/js/secret.js'
import CountDown from "./CountDown.vue"
export default {
    components: { CountDown },
    data() {
        return {
            versionValue: "启航版",
            yearValue: "一年",
            payment: 0,
            codeLoading: false,
            orderNo: "",
            wxCode: "",
            //获取套餐配置
            setMealOption: [],
            //获取价格
            originalPrice: 0,
            discountPrice: 0,
            satrtTime: "",
            endTime: "",
            setMealKey: "",
            //倒计时
            isShowCount: false,
            timeRxpire: 0,
        };
    },
    methods: {
        //获取套餐配置
        getSetMeal(){
            this.$axios.post(`https://admin.qixinerp.com/admin/Common/GetSetMealList`,{}).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    this.setMealOption = data;
                    this.getPrice();
                }
            }); 
        },
        //获取价格
        getPrice(){
            let setMealItem  = this.setMealOption.filter(item => item.setMealName == this.versionValue)[0];
            let selectobj = setMealItem.setMeals.filter(ele => ele.serviceLifeStr == this.yearValue)[0];
            // console.log(selectobj);
            this.originalPrice = selectobj.originalPrice;
            this.discountPrice = selectobj.discountPrice;
            this.satrtTime = selectobj.discountSatrtTime;
            this.endTime = selectobj.discountEndTime;
            this.setMealKey = selectobj.key;
            // 使用参数携带订单号
            if(this.$route.query.orderNo){
                this.orderNo = Decrypt(this.$route.query.orderNo);  //解密订单号
                this.payment = this.$route.query.payType * 1;
                if(this.payment == 1){ this.WeChatPay(); }
                if(this.payment == 2){ this.Alipay(); }
            }
        },
        //选择支付方式
        payChange(value){
            this.$axios.get(`${this.$payUrl}/SetMeal/PurchaseSetMeal?setMealKey=${this.setMealKey}`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.orderNo = data; // 生成订单号
                    if(value == 1){ this.WeChatPay(); }
                    if(value == 2){ this.Alipay(); }
                }
            })
        },
        WeChatPay(){
            this.codeLoading = true;
            this.$axios.get(`${this.$payUrl}/WxPay/UnifiedOrder?orderNo=${this.orderNo}`).then((res) => {
                const { isSuccess, data } = res;
                this.codeLoading = false;
                if (isSuccess) {
                    // 生成微信付款码
                    this.wxCode = `${this.$payUrl}/QRCoder/GetQrCode?url=${data.url}`;
                    // 检查用户是否支付
                    this.checkIsPay();
                    // 订单倒计时
                    this.isShowCount = true;
                    this.timeRxpire = new Date(data.timeRxpire).getTime();
                }
            })
        },
        Alipay(){
            this.codeLoading = true;
            this.$axios.get(`${this.$payUrl}/Alipay/UnifiedOrder?orderNo=${this.orderNo}`).then((res) => {
                const { isSuccess, data } = res;
                this.codeLoading = false;
                if (isSuccess) {
                    // 生成支付宝付款码
                    let o = document.getElementById("alipayFrame")
                    let ed = document.all ? o.contentWindow.document : o.contentDocument
                    ed.open()
                    ed.write(data.form) //放入支付宝的表单数据
                    ed.close()
                    ed.contentEditable = true
                    ed.designMode = 'on'
                    // 检查用户是否支付
                    this.checkIsPay();
                    // 订单倒计时
                    this.isShowCount = true;
                    this.timeRxpire = new Date(data.timeRxpire).getTime();
                }
            })
        },

        // 检查用户是否支付
        checkIsPay(){
            clearInterval(timer);
            timer = setInterval(() => {
                this.getOrderPayState();
            }, 2000);
        },
        // 获取订单支付状态
        getOrderPayState(){
            this.$axios.get(`${this.$payUrl}/SetMeal/GetOrderPayState?orderNo=${this.orderNo}`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    if(data == "支付成功"){
                        clearInterval(timer);
                        //支付成功后跳转页面
                        this.$router.push(`/PaySuccess`);
                    }
                    if(data != "待支付"){
                        clearInterval(timer);
                    }
                }
            })
        },

    },
    mounted() {
        this.versionValue = localStorage.getItem("versionValue") ?? "启航版";
        this.yearValue = localStorage.getItem("yearValue") ?? "一年";
        this.getSetMeal();

    },
};
</script>

<style lang="scss" scoped>
.header{
    height: 80px;
    line-height: 80px;
    background: var(--themeColor);
    box-shadow: 3px 0px 10px 1px rgba(0,0,0,0.1600);
    display: flex;
    .logoImg{
        margin: 25px 25px 25px 20px;
    }
    .txt{
        font-size: 30px;
        font-weight: bold;
        color: #FFFFFF;
    }
}
.w{
    width: 1100px;
    margin: auto;
    background: #FFFFFF;
    display: flex;
    .leftBox{
        box-sizing: border-box;
        padding: 20px;
        box-shadow: 0px 0px 10px 1px rgba(51,57,97,0.3500);
        width: 400px;
        // height: 700px;
        background: #FFFFFF;
        .title{
            font-size: 30px;
            font-weight: bold;
            color: #225EC4;
            margin-bottom: 10px;
        }
        .moduleTitle{
            height: 50px;
            line-height: 50px;
            padding-left: 30px;
            font-size: 20px;
            font-weight: bold;
            color: #000000;
            border-bottom: 1px solid #D9E4ED;
        }
        .permissionItem{
            height: 50px;
            line-height: 50px;
            display: flex;
            border-bottom: 1px solid #D9E4ED;
            padding-left: 30px;
            .leftName{
                width: 220px;
                font-size: 16px;
                color: #333333;
            }
            .piece{
                width: 150px;
                text-align: center;
            }
        }
        .el-icon-circle-check{
            color: #000000;
            font-size: 20px;
        }
        .el-icon-circle-close{
            color: #DFDFDF;
            font-size: 20px;
        }
    }
    .rightBox{
        margin-left: 50px;
        width: 650px;
        .chooseField{
            height: 40px;
            line-height: 40px;
            margin-bottom: 10px;
            display: flex;
            .label{
                width: 100px;
                font-size: 20px;
                text-align: right;
                color: #2C405A;
            }
            .value{
                .originalPrice{
                    font-size: 20px;
                    color: #2C405A;
                    text-decoration: line-through;
                }
                .discountPrice{
                    font-size: 30px;
                    color: #FF8C19;
                }
                .unit{
                    padding-left: 10px;
                    font-size: 20px;
                    color: #1A1A1A;
                }
                .validity{
                    font-size: 14px;
                    margin-left: 50px;
                    color: #2C405A;
                }
            }
        }
        .codeBox{
            margin: 40px auto;
            width: 260px;
            height: 260px;
            background-color: #fff;
            position: relative;
            .count_down{
                position: absolute;
                display: inline-block;
                width: 260px;
                text-align: center;
                left: 0;
                top: -30px;
            }
        }
        .contactus{
            width: 200px;
            height: 48px;
            line-height: 48px;
            text-align: center;
            font-size: 20px;
            color: #FEFEFF;
            background: linear-gradient(180deg, #76C1F7 0%, #2C7EE2 100%);
            border-radius: 34px 34px 34px 34px;
            cursor: pointer;
        }
        .otherInfo{
            height: 48px;
            line-height: 48px;
            font-size: 16px;
            color: #A0A4AF;
        }
    }
}
.payItem{
    position: relative;
    img{
        position: absolute;
        top: 5px;
        left: 20px;
    }
}
</style>