<template>
    <div>
        <InterfaceName :interfaceName="interfaceName" />
        <InterfaceSpe :requestUrl="requestUrl" :requestMethod="requestMethod" />
        <InputParam :inputTableData="inputTableData" />
        <ReturnParam :returnObj="returnObj" />
    </div>
</template>

<script>
import InterfaceName from "../Acomponents/InterfaceName.vue";
import InterfaceSpe from "../Acomponents/InterfaceSpe.vue";
import InputParam from "../Acomponents/InputParam.vue";
import ReturnParam from "../Acomponents/ReturnParam.vue";

export default {
    components: { InterfaceName, InterfaceSpe, InputParam, ReturnParam },
    data() {
        return {
            interfaceName: "添加到我的客户",
            requestUrl: "https://crm.qixinerp.com/open/Customer/AddMyCustomer",
            requestMethod: "GET",
            inputTableData: [],
            returnObj: {},
        };
    },
    methods: {},
    mounted() {
        this.inputTableData = [
            {paramName: "customerKey",valueType: "string",explain: "客户key",required: true},
        ];
        this.returnObj = {
            isSuccess: true,
            errorMessage: null,
            data: true,
        };
    },
};
</script>

<style lang="scss" scoped>

</style>