<template>
    <div>
        <img class="bgImg" src="@/assets/default/three.png" alt="" />
        <div class="describe">暂无您搜索的内容</div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        };
    },
    methods: {
        
    },
    mounted() {

    },
};
</script>

<style lang="scss" scoped>
.bgImg{
    display: block;
    margin: 200px auto 30px;
}
.describe{
    text-align: center;
    font-size: 24px;
    color: #333333;
}
</style>