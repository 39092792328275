<template>
    <div v-loading="loading">
        <!-- 韩国 -->
        <el-descriptions :column="2" border :labelStyle="{width:'15%'}" :contentStyle="{width:'35%'}">
            <el-descriptions-item label="公司代码">{{businessInfo.companyCode}}</el-descriptions-item>
            <el-descriptions-item label="公司名">{{businessInfo.companyName}}</el-descriptions-item>
            <el-descriptions-item label="公司名英文">{{businessInfo.companyNameEn}}</el-descriptions-item>
            <el-descriptions-item label="行业">{{businessInfo.industry}}</el-descriptions-item>
            <el-descriptions-item label="法人">{{businessInfo.legalPerson}}</el-descriptions-item>
            <el-descriptions-item label="地址">{{businessInfo.address}}</el-descriptions-item>
            <el-descriptions-item label="Office">{{businessInfo.office}}</el-descriptions-item>
        </el-descriptions>
    </div>
</template>

<script>
export default {
    data() {
        return {
            businessInfo: {},
            loading: false,
        };
    },
    methods: {
        getInfo(){
            let companyName = encodeURIComponent(this.$route.query.companyName);
            let country = this.$route.query.country;
            this.loading = true;
            this.$axios.get(`${this.$clueUrl}/BuyerLeads/GetBusinessInformation?country=${country}&company=${companyName}`).then((res) => {
                const { isSuccess, data } = res;
                this.loading = false;
                if (isSuccess) {
                    this.businessInfo = data;
                }
            });
        },
    },
    mounted() {
        this.getInfo();
    },
};
</script>

<style lang="scss" scoped>

</style>