<template>
    <div class="viewbox">
        <div class="viewOne">
            <p class="title">商业数据线索</p>
            <p class="describe">在有效时间内获取最全面的商业资讯 结合全球200多个国家3亿多企业联系方式及其深度报告,一键挖掘全球市场和新客户</p>
        </div>

        <div class="viewTwo">
            <div class="title">
                <p>定制权威情报</p>
                <p>轻松掌握全球市场</p>
            </div>
            <div class="describe">
                <p>1200万+欧美企业决策者的邮箱或电话</p>
                <p style="height:50px;"></p>
                <p>1000+行业细分</p>
            </div>
            <div class="openBtn">去开通</div>
            <div class="cardItem cardOne">
                <img src="@/assets/cluePicture/icon1.png" alt="" />
                <div class="p1">直接联系信息</div>
                <div class="p2">获得目标企业决策人和邮箱让您绕过重重阻碍直接与决策人对话</div>
            </div>
            <div class="cardItem cardTwo">
                <img src="@/assets/cluePicture/icon2.png" alt="" />
                <div class="p1">行业洞察</div>
                <div class="p2">涵盖行业背景及深度信息助您了解客户业务瓶颈及面临的机遇和挑战以及实用行业要点供参考</div>
            </div>
            <div class="cardItem cardThree">
                <img src="@/assets/cluePicture/icon3.png" alt="" />
                <div class="p1">家族企业链</div>
                <div class="p2">利用企业的内部关联撬动新的销售机会有利于大客户的区域化管理</div>
            </div>
        </div>

        <div class="viewThree">
            <div class="title">选择适合您的产品</div>
            <div class="chooseContent">
                <div class="chooseCard freeBox">
                    <div class="name">体验版</div>
                    <div class="price">免费</div>
                    <div class="effect"></div>
                    <p>点数</p>
                    <p style="color:#65C8FE;">10次</p>
                    <p>1管理员用户</p>
                    <div class="btn">去体验</div>
                </div>
                <div class="chooseCard vipOneBox">
                    <div class="name">
                        <img src="@/assets/cluePicture/vip1.png" alt="" style="padding-right:10px;" />高级版
                    </div>
                    <div class="price">¥20000</div>
                    <div class="effect">对成长中的公司</div>
                    <p>点数</p>
                    <p style="color:#4F9AFF;">100/次</p>
                    <p>1管理员用户</p>
                    <div class="btn">购买</div>
                </div>
                <div class="chooseCard vipTwoBox">
                    <div class="name">
                        <img src="@/assets/cluePicture/vip2.png" alt="" style="padding-right:10px;" />尊享版
                    </div>
                    <div class="price">¥30000</div>
                    <div class="effect">有利于团队成长</div>
                    <p>点数</p>
                    <p style="color:#9C683F;">10000/次</p>
                    <p>10管理员用户</p>
                    <div class="btn">购买</div>
                </div>
            </div>
        </div>

        <div class="viewFour">
            <div class="title">技术实力</div>
            <p class="pItme">
                <img src="@/assets/cluePicture/icon4.png" alt="" />
                <span>近百个数据资源全球各国海关数据官方授权按天数据更新</span>
            </p>
            <p class="pItme">
                <img src="@/assets/cluePicture/icon4.png" alt="" />
                <span>基于大数据处理平台和数十年相关数据处理经验，沉淀出一套卓越的数据清晰流程</span>
            </p>
            <p class="pItme">
                <img src="@/assets/cluePicture/icon4.png" alt="" />
                <span>AI人工智能技术组成的贸易画像技术，有效识别异常贸易数据</span>
            </p>
            <p class="pItme">
                <img src="@/assets/cluePicture/icon4.png" alt="" />
                <span>全部产品自主知识产权，多项软件专利技术</span>
            </p>
            <p class="pItme">
                <img src="@/assets/cluePicture/icon4.png" alt="" />
                <span>自主研发搜索分析引擎，超多难度实现各种数据挖掘</span>
            </p>
        </div>

        <div class="copyright">©Copyright 2020 宗恩信息科技（上海）有限公司保留所有权利    沪ICP备2020033602号</div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        };
    },
    methods: {
        
    },
    mounted() {
        
    },
};
</script>

<style lang="scss" scoped>
.viewbox{
    background: #FFFFFF;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    width: 1680px;
    margin: auto;
    .viewOne{
        background: url("../../assets/cluePicture/layer1.png") no-repeat center transparent;
        background-size: cover;
        width: 1680px;
        height: 817px;
        position: relative;
        .title{
            position: absolute;
            top: 210px;
            left: 195px;
            width: 360px;
            font-size: 60px;
            font-weight: bold;
            color: #FFFFFF;
        }
        .describe{
            position: absolute;
            top: 320px;
            left: 195px;
            width: 548px;
            font-size: 26px;
            color: #FFFFFF;
        }
    }

    .viewTwo{
        margin-top: 230px;
        background: url("../../assets/cluePicture/layer2.png") no-repeat center transparent;
        background-size: cover;
        width: 1680px;
        height: 769px;
        position: relative;
        .title{
            position: absolute;
            top: -60px;
            left: 195px;
            font-size: 36px;
            line-height: 50px;
            font-weight: bold;
            color: #333333;
        }
        .describe{
            position: absolute;
            top: 100px;
            left: 195px;
            font-size: 20px;
            line-height: 28px;
            color: #282331;
            opacity: 0.8;
        }
        .openBtn{
            position: absolute;
            top: 240px;
            left: 195px;
            width: 193px;
            height: 55px;
            text-align: center;
            line-height: 55px;
            font-size: 20px;
            color: #0951B2;
            background: rgba(143, 73, 249, 0);
            border: 2px solid #0951B2;
            cursor: pointer;
        }
        .cardItem{
            background: #FFFFFF;
            box-shadow: 0px 0px 19px rgba(215, 215, 215, 0.35);
            border-radius: 10px;
            padding: 45px 50px 40px 30px;
            box-sizing: border-box;
            .p1{
                font-size: 22px;
                line-height: 35px;
                font-weight: bold;
                color: #282331;
            }
            .p2{
                margin-top: 40px;
                font-size: 18px;
                line-height: 27px;
                color: #282331;
                opacity: 0.8;
            }
        }
        .cardOne{
            position: absolute;
            top: 42px;
            left: 668px;
            width: 386px;
            height: 433px;
        }
        .cardTwo{
            position: absolute;
            top: -140px;
            right: 185px;
            width: 386px;
            height: 399px;
        }
        .cardThree{
            position: absolute;
            top: 314px;
            right: 185px;
            width: 386px;
            height: 359px;
        }
    }

    .viewThree{
        margin-top: 75px;
        .title{
            font-size: 36px;
            font-weight: bold;
            color: #282331;
            margin-left: 195px;
        }
        .chooseContent{
            margin-top: 60px;
            margin-left: 155px;
            display: flex;
            .chooseCard{
                box-sizing: border-box;
                padding-top: 70px;
                .name{
                    font-size: 20px;
                    font-weight: bold;
                    line-height: 32px;
                    color: #282331;
                    display: flex;
                }
                .price{
                    margin-top: 30px;
                    width: 113px;
                    height: 60px;
                    text-align: center;
                    line-height: 60px;
                    border-radius: 10px;
                    font-size: 25px;
                    color: #FFFFFF;
                }
                .effect{
                    margin-top: 38px;
                    height: 59px;
                    font-size: 18px;
                    line-height: 28px;
                    color: #282331;
                    opacity: 0.8;
                }
                p{
                    font-size: 16px;
                    line-height: 35px;
                    color: #282331;
                }
                .btn{
                    margin-top: 60px;
                    text-align: center;
                    height: 49px;
                    line-height: 49px;
                    font-size: 20px;
                    color: #FFFFFF;
                    cursor: pointer;
                }
            }
            .freeBox{
                background: url("../../assets/cluePicture/box1.png") no-repeat center transparent;
                background-size: cover;
                width: 496px;
                height: 646px;
                padding-left: 90px;
                .price{
                    background: #65C8FE;
                    box-shadow: 0px 10px 35px rgba(180, 144, 239, 0.35);
                }
                .btn{
                    background: #65C8FE;
                    width: 118px;
                }
            }
            .vipOneBox{
                background: url("../../assets/cluePicture/box2.png") no-repeat center transparent;
                background-size: cover;
                width: 457px;
                height: 607px;
                padding-left: 70px;
                .price{
                    background: #4F9AFF;
                    box-shadow: 0px 10px 35px rgba(147, 236, 203, 0.35);
                }
                .btn{
                    width: 95px;
                    background: #4F9AFF;
                }
            }
            .vipTwoBox{
                background: url("../../assets/cluePicture/box3.png") no-repeat center transparent;
                background-size: cover;
                width: 457px;
                height: 607px;
                padding-left: 70px;
                .price{
                    background: #9C683F;
                    box-shadow: 0px 10px 35px rgba(250, 197, 249, 0.35);
                }
                .btn{
                    width: 95px;
                    background: linear-gradient(180deg, #D0B096 0%, #AC7F5A 100%);
                }
            }
        }
    }

    .viewFour{
        margin-top: 45px;
        .title{
            font-size: 36px;
            font-weight: bold;
            color: #282331;
            text-align: center;
        }
        .pItme{
            margin-left: 195px;
            margin-top: 70px;
            span{
                padding-left: 20px;
                font-size: 18px;
                line-height: 32px;
                color: #282331;
            }
        }
    }

    .copyright{
        margin-top: 110px;
        width: 1680px;
        height: 65px;
        line-height: 65px;
        font-size: 24px;
        text-align: center;
        color: #C2C2C2;
        background: #F2F2F2;
    }
}
</style>