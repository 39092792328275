<template>
  <div id="abroad-bank" class="abroad-bank" v-show="totalData.length !== 0">
    <div class="title" ref="title">
      <p>拥有业务的银行或金融机构信息</p>
      <feedback position="拥有业务的银行或金融机构信息" url="" />
      <!-- <div class="flex-container">
        <img class="icon" src="@/firm/images/company/icon-feedback.png" style="margin-left: 1em" />
        <span class="text-with-icon" style="color: var(--themeColor, #3152AF)">反馈</span>
      </div> -->
    </div>
    <div>
      <el-table :data="tableData" border :header-cell-style="{ background: '#f8faff', color: '#1F2D3D' }">
        <el-table-column prop="name" label="银行或金融机构的名称" />
      </el-table>
      <div style="margin-top: 1em; display: flex; justify-content: flex-end">
        <el-pagination background layout="total, prev, pager, next" :total="pagination.total" :page-size="pagination.pageSize" :current-page="pagination.pageIndex" @current-change="handlePageIndexChange" hide-on-single-page />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapMutations } from "vuex";
import { AnchorInditor } from "../../../utils";

export default Vue.extend({
  props: ["banks"],
  watch: {
    banks() {
      try {
        this.totalData = this.banks;
        this.tableData = this.totalData.slice(0, this.pagination.pageSize);
        this.pagination.total = this.banks.length;
        if (this.totalData.length !== 0) {
          this.setAnchorFlag([["YH", true]]);
        }
      } catch {
        this.totalData = [];
        this.tableData = [];
        this.pagination.total = 0;
        this.setAnchorFlag([["YH", false]]);
      }
    },
  },
  data() {
    return {
      totalData: [],
      tableData: [],
      pagination: {
        total: 0,
        pageSize: 10,
        pageIndex: 1,
      },
    };
  },
  methods: {
    ...mapMutations("anchor", {
      setAnchorFlag: "setAnchorFlag",
      setCurrent: "setCurrent",
    }),
    //分页器页码改变时触发
    handlePageIndexChange(pageIndex) {
      const size = this.pagination.pageSize;
      this.tableData = this.totalData.slice((pageIndex - 1) * size, pageIndex * size);
    },
  },
  mounted() {
    new AnchorInditor(() => {
      this.setCurrent("YH");
    }, this.$refs["title"]);
  },
});
</script>

<style lang="scss" scoped>
@import "@/firm/scss/mixin.scss";

.abroad-bank {
  background: #fff;
  padding: 1rem;
  padding-top: 0;
  @include mixin-panel-title;
}
</style>
