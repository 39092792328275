<template>
    <div id="app">
        <router-view />
        <ThemePicker @change="themeChange" v-show="false"></ThemePicker>
    </div>
</template>

<script>
import ThemePicker from '@/components/ThemePicker.vue';
export default {
    name: "App",
    components:{ThemePicker},
    methods: {
        //切换主题色
        themeChange(val){
            this.$store.dispatch('changeSetting',{
                key: 'theme',
                value: val
            })
        },
    },
};
</script>

<style>
* {
    padding: 0;
    margin: 0;
}
body{
    min-width: 1200px;
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI','Noto Sans SC','STHeiti Light','Source Han Sans CN','SimHei',"Times New Roman",Georgia,Serif,SimHei;
}

/* 手势样式 */
.pointer{
    cursor: pointer;
}
/* 设置标签样式 */
.el-tag {
    margin-left: 5px;
}
.el-tag + .el-tag {
    margin-left: 5px;
}
.el-tag--dark{
    border-color: transparent !important;
}
.el-tag--dark .el-tag__close:hover{
    background: transparent !important;
}
/* 设置对话框样式 */
.el-dialog__title {
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    color: #3A3A3A;
}
.el-dialog__body {
    padding: 10px 20px;
}
.el-dialog{
    margin-bottom: 0 !important;
}
/* 表单label样式 */
.el-form-item__label{
    color: #5E6D82 !important;
}


/* 加载后面页码和它后面的li隐藏 */
.el-pager .btn-quicknext{
    display: none;
}
.el-pager .btn-quicknext+li{
    display: none;
}



/* 底部分页器 */
.bottomPagination{
    width: calc(100vw - 70px) !important;
}
/* 超出隐藏 */
.ellipsis{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
/* 文件类型图标 */
.fileIcon{
    width: 24px;
    height: 32px;
}
/* 日程提醒类型 */
.el-notification__icon {
    height: 16px !important;
    width: 16px !important;
    font-size: 16px !important;
    margin-top: 5px;
    border-radius: 50%;
}
.icontype0{
    background-color: #FEC000;
}
.icontype1{
    background-color: #4472C4;
}
.icontype2{
    background-color: #41B052;
}
.icontype3{
    background-color: #FF504F;
}
.textOverFour{
    display: -webkit-box;
    overflow: hidden;
    white-space: normal !important;
    text-overflow: ellipsis;
    word-wrap: break-word;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}
.generateBox p{
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 5px;
    color: #212529;
}
.setTipText{
    font-weight: 400;
    color: #6a6a6a;
    font-size: 14px;
}
</style>
