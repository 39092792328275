<template>
    <div>
        <div class="headerBox" v-loading="infoLoading">
            <div style="margin-bottom: 8px;">
                <div class="name">{{infoObj.company}}</div>
                <div class="country">
                    <img :src="flagUrl" style="margin-right:5px;height:14px" />
                    <span>{{dataCountryCn}}({{threeEnCountryCode}})</span>
                </div>
            </div>
            <div style="display:flex;">
                <div class="oneField">
                    <div class="color99">频次</div>
                    <div class="color3a">{{infoObj.frequency}}</div>
                </div>
                <div class="oneField">
                    <div class="color99">金额</div>
                    <div class="color3a">{{infoObj.value}}</div>
                </div>
                <div class="oneField">
                    <div class="color99">数量</div>
                    <div class="color3a">{{infoObj.quantity}}</div>
                </div>
                <div class="oneField">
                    <div class="color99">重量</div>
                    <div class="color3a">{{infoObj.weight}}</div>
                </div>
            </div>  
        </div>

        <div class="cardBox" v-loading="infoLoading" style="margin-top: 20px;">
            <div v-if="marketAnalysis.length != 0 || productAnalysis.length != 0">
                <div class="sublevel">（1）市场排名</div>
                <el-table :data="marketAnalysis.slice(10*(this.marketPageIndex-1),10*this.marketPageIndex)" :header-cell-style="{'background-color':'#E5E9F2','color':'#1F2D3D'}">
                    <el-table-column prop="marketCountry" label="国家"></el-table-column>
                    <el-table-column prop="frequency" label="频次"></el-table-column>
                    <el-table-column prop="value" label="金额"></el-table-column>
                    <el-table-column prop="quantity" label="数量"></el-table-column>
                    <el-table-column prop="weight" label="重量"></el-table-column>
                </el-table>
                <div style="overflow:hidden;">
                    <el-pagination background layout="total,prev,pager,next" :total="marketAnalysis.length" style="float:right;margin-top:15px;" :current-page.sync="marketPageIndex"></el-pagination>
                </div>
                <div class="sublevel">（2）编码排名</div>
                <el-table :data="productAnalysis.slice(10*(this.productPageIndex-1),10*this.productPageIndex)" :header-cell-style="{'background-color':'#E5E9F2','color':'#1F2D3D'}">
                    <el-table-column prop="hsCode" label="HS编码"></el-table-column>
                    <el-table-column prop="frequency" label="频次"></el-table-column>
                    <el-table-column prop="value" label="金额"></el-table-column>
                    <el-table-column prop="quantity" label="数量"></el-table-column>
                    <el-table-column prop="weight" label="重量"></el-table-column>
                </el-table>
                <div style="overflow:hidden;">
                    <el-pagination background layout="total,prev,pager,next" :total="productAnalysis.length" style="float:right;margin-top:15px;" :current-page.sync="productPageIndex"></el-pagination>
                </div>
            </div>
            <div v-else>
                <el-empty description="暂无数据"></el-empty>
            </div>
        </div>

        <div class="cardBox">
            <div class="title">商业数据</div>
            <BusinessData />
        </div>

    </div>
</template>

<script>
import BusinessData from "./BusinessInfo/BusinessData.vue"
export default {
    components: { BusinessData },
    data() {
        return {
            company: "",
            dataCountryCn: "",
            threeEnCountryCode: "",
            flagUrl: "",
            infoLoading: false,
            infoObj: {},

            // 市场排名 编码排名
            marketAnalysis: [],
            productAnalysis : [],
            marketPageIndex: 1,
            productPageIndex: 1,
        };
    },
    methods: {
        getCompanyInfo(){
            this.infoLoading = true;
            this.$axios.get(`${this.$clueUrl}/Product/GetCompanyInfo?company=${this.company}&country=${this.threeEnCountryCode}`).then((res) => {
                const { isSuccess, data } = res;
                this.infoLoading = false;
                if (isSuccess) {
                    this.infoObj = data.company;
                    this.marketAnalysis = data.marketRanks;
                    this.productAnalysis = data.hsCodeRanks;
                }
            });
        }
    },
    mounted() {
        this.company = encodeURIComponent(this.$route.query.company);
        this.dataCountryCn = this.$route.query.dataCountryCn;
        this.threeEnCountryCode = this.$route.query.threeEnCountryCode;
        this.flagUrl = window.getFlagUrlByCountry ? window.getFlagUrlByCountry(this.dataCountryCn) : '';
        this.getCompanyInfo();
    },
};
</script>

<style lang="scss" scoped>
.headerBox {
    background: #ffffff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    padding: 20px;
    font-size: 14px;
    position: relative;
    .name{
        display: inline-block;
        color: var(--themeColor);
    }
    .country{
        display: inline-block;
        margin-left: 20px;
    }
    .color99{    
        color: #999999;
    }
    .color3a{    
        color: #3A3A3A;
    }
    .primaryColor{
        color: var(--themeColor);
        cursor: pointer;
    }
    .oneField{
        width: 200px;
        text-align: center;
    }
    .twoField{
        line-height: 28px;
    }
    .addBtn{
        position: absolute;
        top: 20px;
        right: 20px;
        padding: 0 10px;
        height: 28px;
        line-height: 28px;
        font-size: 14px;
        color: var(--themeColor);
        background: rgba(183, 183, 183, 0.2);
        border: 1px solid var(--themeColor);
        border-radius: 4px;
        cursor: pointer;
        &:hover{
            background: var(--themeColor);
            color: #FFFFFF;
        }
        img{
            position: absolute;
            left: 10px;
            top: 5px;
        }
    }
    .forbidden{
        cursor: not-allowed;
    }
    .hasAdd{
        position: absolute;
        top: 20px;
        right: 20px;
        padding: 0 10px;
        height: 28px;
        line-height: 28px;
        font-size: 14px;
        color: #B7B7B7;
        background: rgba(183, 183, 183, 0.2);
        border: 1px solid #B7B7B7;
        border-radius: 4px;
    }
}

.cardBox{
    background: #ffffff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    padding: 15px 20px;
    margin-top: 20px;
    .title{   
        font-size: 20px;
        height: 20px;
        line-height: 20px;
        font-weight: bold;
        color: #3A3A3A;
        padding-left: 8px;
        border-left: 3px solid var(--themeColor);
        margin-bottom: 15px;
    }
    .sublevel{
        margin-top: 20px;
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 20px;
        font-weight: bold;
        color: #3A3A3A;
    }
    .progress{
        width: 240px;
        display: inline-block;
    }
}
</style>