<template>
    <div v-loading="loading">
        <!-- 19个国家的工商信息字段 -->
        <el-descriptions :column="2" border :labelStyle="{width:'15%'}" :contentStyle="{width:'35%'}">
            <el-descriptions-item label="公司代码">{{businessInfo.companyId}}</el-descriptions-item>
            <el-descriptions-item label="公司名">{{businessInfo.company}}</el-descriptions-item>
            <el-descriptions-item label="公司名标准">{{businessInfo.companyStd}}</el-descriptions-item>
            <el-descriptions-item label="公司电话">{{businessInfo.phone}}</el-descriptions-item>
            <el-descriptions-item label="公司传真">{{businessInfo.fax}}</el-descriptions-item>
            <el-descriptions-item label="公司地址">{{businessInfo.address}}</el-descriptions-item>
            <el-descriptions-item label="临近地区">{{businessInfo.neighborhood}}</el-descriptions-item>
            <el-descriptions-item label="公司所在城市">{{businessInfo.city}}</el-descriptions-item>
            <el-descriptions-item label="公司所在县区">{{businessInfo.region}}</el-descriptions-item>
            <el-descriptions-item label="时区名称">{{businessInfo.timezone}}</el-descriptions-item>
            <el-descriptions-item label="时区">{{businessInfo.hours}}</el-descriptions-item>
            <el-descriptions-item label="网站">{{businessInfo.website}}</el-descriptions-item>
            <el-descriptions-item label="公司Facebook账号">{{businessInfo.facebook}}</el-descriptions-item>
            <el-descriptions-item label="Yelp">{{businessInfo.yelp}}</el-descriptions-item>
            <el-descriptions-item label="谷歌地图">{{businessInfo.googleMaps}}</el-descriptions-item>
            <el-descriptions-item label="黄页">{{businessInfo.yellowpages}}</el-descriptions-item>
            <el-descriptions-item label="成立时间">{{businessInfo.lastUpdated}}</el-descriptions-item>
            <el-descriptions-item label="类别1">{{businessInfo.category1}}</el-descriptions-item>
            <el-descriptions-item label="类别2">{{businessInfo.category2}}</el-descriptions-item>
            <el-descriptions-item label="联系人">{{businessInfo.contact}}</el-descriptions-item>
            <el-descriptions-item label="联系人职位">{{businessInfo.position}}</el-descriptions-item>
            <el-descriptions-item label="性别">{{businessInfo.gender}}</el-descriptions-item>
            <el-descriptions-item label="电子邮件">{{businessInfo.emails}}</el-descriptions-item>
            <el-descriptions-item label="联系人传真">{{businessInfo.contactFax}}</el-descriptions-item>
            <el-descriptions-item label="联系人电话">{{businessInfo.contactPhone}}</el-descriptions-item>
            <el-descriptions-item label="联系人所在地区">{{businessInfo.contactRegion}}</el-descriptions-item>
            <el-descriptions-item label="联系人所在城市">{{businessInfo.contactCity}}</el-descriptions-item>
        </el-descriptions>
    </div>
</template>

<script>
export default {
    data() {
        return {
            businessInfo: {},
            loading: false,
        };
    },
    methods: {
        getInfo(){
            let companyName = encodeURIComponent(this.$route.query.companyName);
            let country = this.$route.query.country;
            this.loading = true;
            this.$axios.get(`${this.$clueUrl}/BuyerLeads/GetBusinessInformation?country=${country}&company=${companyName}`).then((res) => {
                const { isSuccess, data } = res;
                this.loading = false;
                if (isSuccess) {
                    this.businessInfo = data;
                }
            });
        },
    },
    mounted() {
        this.getInfo();
    },
};
</script>

<style lang="scss" scoped>

</style>