<template>
    <div>
        <InterfaceName :interfaceName="interfaceName" />
        <InterfaceSpe :requestUrl="requestUrl" :requestMethod="requestMethod" />
        <ReturnParam :returnObj="returnObj" />
    </div>
</template>

<script>
import InterfaceName from "../Acomponents/InterfaceName.vue";
import InterfaceSpe from "../Acomponents/InterfaceSpe.vue";
import ReturnParam from "../Acomponents/ReturnParam.vue";

export default {
    components: { InterfaceName, InterfaceSpe, ReturnParam },
    data() {
        return {
            interfaceName: "获取客户规模",
            requestUrl: "https://crm.qixinerp.com/open/Customer/GetCompanySize",
            requestMethod: "GET",
            returnObj: {},
        };
    },
    methods: {},
    mounted() {
        this.returnObj = {
            isSuccess: true,
            errorMessage: null,
            data: [  
                { name: "少于59人" },
                { name: "60-149人" },
                { name: "150-499人" },
                { name: "500-999人" },
                { name: "1000-4999人" },
                { name: "5000人以上" },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>

</style>