<template>
    <div class="orderDetails">
        <div class="topContent" v-loading="infoLoading">
            <div class="leftInfo">
                <span class="orderName">{{detailsInfo.orderName}}</span> 
                <el-tag type="info" effect="dark" size="small" v-if="detailsInfo.orderStatus == '已作废'">已作废</el-tag>
            </div>
            <div class="rightHandle">
                <template v-if="detailsInfo.orderStatus == '已作废'">
                    <el-button size="small" @click="aNewOrder">重新生成订单</el-button>
                </template>
                <template v-else>
                    <el-button size="small" @click="toInvalidOrder">作废订单</el-button>
                    <el-button size="small" @click="exportOrder" :loading="downloadLoading">导出订单</el-button>
                    <el-button size="small" @click="toEditOrder">编辑订单</el-button>
                    <el-button size="small" @click="toCopyOrder">复制订单</el-button>
                    <!-- <el-button size="small" @click="toPrint">打印订单</el-button> -->
                 </template>
            </div>
            <div class="topInfo">
                <el-form label-width="120px" size="small">
                    <el-row :gutter="16">
                        <el-col :span="6">
                            <el-form-item label="订单编号:">
                                <span class="valueColor">{{detailsInfo.orderNo}}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="订单金额:">
                                <span class="valueColor">{{detailsInfo.orderAmount}}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="订单日期:">
                                <span class="valueColor">{{detailsInfo.orderDate}}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="交货日期:">
                                <span class="valueColor">{{detailsInfo.deliveryDate}}</span>
                                <el-tag type="success" size="small" v-if="isOverdue">已完成</el-tag>
                                <el-tag size="small" v-if="!isOverdue">待交货</el-tag>
                                <el-tag size="small" v-if="!isOverdue">剩余{{remainDays}}天交货</el-tag>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="预计到账日期:">
                                <span class="valueColor">{{detailsInfo.expectedReceiptDate}}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="付款方式:">
                                <span class="valueColor">{{detailsInfo.paymentMethod || "--"}}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="价格条款:">
                                <span class="valueColor">{{detailsInfo.priceTerms || "--"}}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="银行信息:">
                                <span class="valueColor">{{detailsInfo.bankInfo || "--"}}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="目的港:">
                                <span class="valueColor">{{detailsInfo.portOfDestination || "--"}}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="起运港:">
                                <span class="valueColor">{{detailsInfo.portOfShipment || "--"}}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="备注:">
                                <span class="valueColor">{{detailsInfo.remarks || "--"}}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
        </div>

        <div class="tabCard">
            <DetailsOne />
            <!-- <el-tabs v-if="false">
                <el-tab-pane label="订单详情">
                    <DetailsOne />
                </el-tab-pane>
                <el-tab-pane label="收款明细">
                    <div class="shoukuan">
                        <div class="describe">
                            <el-button size="small" type="primary" icon="el-icon-plus" @click="toNewCollection">新建收入</el-button>
                            <span>订单金额：{{detailsInfo.orderAmount}} 元</span>
                            <span>已支付：{{totalCollectionAmount}} 元</span>  
                            <span>待支付：{{detailsInfo.orderAmount - totalCollectionAmount}} 元</span>
                        </div>
                        <el-table :data="gatherList" :header-cell-style="{'background-color':'#F3F3F3','color':'#3A3A3A'}" :row-style="rowStyle">
                            <el-table-column prop="collectionNo" label="收汇单号"></el-table-column>
                            <el-table-column prop="payeeCollectionDate" label="收汇日期"></el-table-column>
                            <el-table-column prop="collectionCurrency" label="收汇币种"></el-table-column>
                            <el-table-column prop="exchangeRate" label="汇率"></el-table-column>
                            <el-table-column prop="usExchangeRate" label="美金汇率"></el-table-column>
                            <el-table-column prop="collectionNetAmount" label="收汇净额"></el-table-column>
                            <el-table-column prop="usCollectionNetAmount" label="收汇净额($)"></el-table-column>
                            <el-table-column prop="payeeCollectionAmount" label="收汇金额"></el-table-column>
                            <el-table-column prop="handler" label="经手人"></el-table-column>
                            <el-table-column prop="remarks" label="备注"></el-table-column>
                        </el-table>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="支出明细">
                    <div class="zhichu">
                        <el-button size="small" type="primary" icon="el-icon-plus" @click="toNewSpending" style="margin-bottom: 10px;">新建支出</el-button>
                        <el-table :data="spendingList" :header-cell-style="{'background-color':'#F3F3F3','color':'#3A3A3A'}" :row-style="rowStyle">
                            <el-table-column prop="collectionNo" label="收汇单号"></el-table-column>
                            <el-table-column prop="payeeCollectionDate" label="收汇日期"></el-table-column>
                            <el-table-column prop="collectionCurrency" label="收汇币种"></el-table-column>
                            <el-table-column prop="exchangeRate" label="汇率"></el-table-column>
                            <el-table-column prop="usExchangeRate" label="美金汇率"></el-table-column>
                            <el-table-column prop="collectionNetAmount" label="收汇净额"></el-table-column>
                            <el-table-column prop="usCollectionNetAmount" label="收汇净额($)"></el-table-column>
                            <el-table-column prop="payeeCollectionAmount" label="收汇金额"></el-table-column>
                            <el-table-column prop="handler" label="经手人"></el-table-column>
                            <el-table-column prop="remarks" label="备注"></el-table-column>
                        </el-table>
                    </div>
                </el-tab-pane>
            </el-tabs> -->
        </div>

        <!-- 关联收款功能 -->
        <!-- <el-dialog title="关联收款" :visible.sync="relevanceVisible" width="500px">
            <el-select v-model="collectionKeys" placeholder="请选择收款单" multiple style="width:100%;">
                <el-option v-for="item in collectionDropList" :key="item.key" :label="item.collectionNo" :value="item.key"></el-option>
            </el-select>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" @click="relevanceSubmit">关联</el-button>
                <el-button size="small" @click="relevanceVisible = false">取 消</el-button>
            </span>
        </el-dialog> -->

    </div>
</template>

<script>
import DetailsOne from "../OrderManage/ChildComponents/DetailsOne.vue";     //详情信息

import dayjs from "dayjs";
var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);
export default {
    components: { DetailsOne },
    props: {
        handleSelect: {
            type: Function,
            default: null,
        },
    },
    data() {
        return {
            orderKey: "",
            infoLoading: false,
            detailsInfo: {},
            gatherList: [],
            totalCollectionAmount: 0,
            spendingList: [],
            //关联收款功能
            collectionDropList: [],
            relevanceVisible: false,
            collectionKeys: [],

            downloadLoading: false,

            //交货日期提醒
            isOverdue: false,
            remainDays: 0,
        };
    },
    methods: {
        rowStyle({rowIndex}){
            // 表格隔行换色
            if(rowIndex % 2 == 1){
                return {'background-color':'#F9FAFC'}
            }
        },
        //作废订单
        toInvalidOrder(){
            this.$confirm('是否作废该订单?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$axios.post(`${this.$orderUrl}/api/Order/ChangeStatus`,{
                    orderKey: this.orderKey,
                    orderStatus: "已作废",
                }).then(res => {
                    const {isSuccess} = res;
                    if(isSuccess){
                        this.$message({type: 'success',message: '作废订单成功!'});
                        this.getOrderInfo();
                    }
                });
            }).catch(() => {});
        },
        //导出订单
        exportOrder(){
            this.downloadLoading = true;
            this.$axios({
                url: `${this.$orderUrl}/api/Order/ExportExcel?key=${this.orderKey}`,
                method: 'GET',
                responseType: 'blob',
            }).then(response => {
                this.downloadLoading = false;
                var blob = new Blob([response]);
                var link= document.createElement('a');
                link.download = `${this.detailsInfo.orderName}.xlsx`;
                link.href = URL.createObjectURL(blob);
                link.click();
                this.$message({ message: "导出订单成功",type: 'success' });
            });
        },
        //重新生成订单
        aNewOrder(){
            let routeData = this.$router.resolve({
                path: "/orderManage/addOrder",
                query: { orderKey: this.orderKey, optype: 'regen' },
            });
            window.open(routeData.href, "_blank");
        },
        //编辑订单
        toEditOrder(){
            let routeData = this.$router.resolve({
                path: "/orderManage/addOrder",
                query: { orderKey: this.orderKey },
            });
            window.open(routeData.href, "_blank");
        },
        //复制订单
        toCopyOrder(){
            let routeData = this.$router.resolve({
                path: "/orderManage/addOrder",
                query: { orderKey: this.orderKey, optype: "copy" },
            });
            window.open(routeData.href, "_blank");
        },
        //前往打印订单
        toPrint(){
            let routeData = this.$router.resolve({
                path: "/printOrder",
                query: { orderKey: this.orderKey },
            });
            window.open(routeData.href, "_blank");
        },

        //获取订单信息
        getOrderInfo(){
            this.infoLoading = true;
            this.$axios.get(`${this.$orderUrl}/api/Order/GetOrderDetail?key=${this.orderKey}`).then((res) => {
                const { isSuccess , data } = res;
                this.infoLoading = false;
                if (isSuccess) {
                    this.detailsInfo = data.order;
                    document.title = `订单详情-${this.detailsInfo.orderName}`
                    //交货日期提醒
                    this.isOverdue = dayjs().isAfter(dayjs(data.order.deliveryDate));
                    this.remainDays = dayjs(data.order.deliveryDate).diff(dayjs(),'day');
                    // console.log(this.isOverdue);
                    // console.log(this.remainDays);
                }
            });
        },

        /** 收款明细--支出明细--屏蔽 **/
        shield(){
            //获取收款明细
            // getGatherInfo(){
            //     this.$axios.get(`${this.$orderUrl}/api/Order/GetCollectionList?orderKey=${this.orderKey}&type=1`).then((res) => {
            //         const { isSuccess , data } = res;
            //         if (isSuccess) {
            //             this.gatherList = data;
            //             let total = 0;
            //             this.gatherList.forEach(ele =>{
            //                 total += ele.payeeCollectionAmount;
            //             })
            //             this.totalCollectionAmount = total;
            //         }
            //     });
            // },
            // toNewCollection(){
            //     this.handleSelect(`/orderManage/addCollection?orderKey=${this.orderKey}`);
            //     this.$router.push(`/orderManage/addCollection?orderKey=${this.orderKey}`);
            // },
            //获取支出明细
            // getSpendingInfo(){
            //     this.$axios.get(`${this.$orderUrl}/api/Order/GetCollectionList?orderKey=${this.orderKey}&type=2`).then((res) => {
            //         const { isSuccess , data } = res;
            //         if (isSuccess) {
            //             this.spendingList = data;
            //         }
            //     });
            // },
            // toNewSpending(){
            //     this.handleSelect(`/orderManage/addSpending?orderKey=${this.orderKey}`);
            //     this.$router.push(`/orderManage/addSpending?orderKey=${this.orderKey}`);
            // },
            //获取收款下拉框数据
            // getCollectionDropList(){
            //     this.$axios.get(`${this.$orderUrl}/api/Collection/GetCollectionDropList`).then((res) => {
            //         const { isSuccess , data } = res;
            //         if (isSuccess) {
            //             this.collectionDropList = data;
            //         }
            //     });
            // },
            // relevanceSubmit(){
            //     if(this.collectionKeys.length == 0){
            //         this.$message({ message: '请选择收款单', type: 'warning'});
            //         return false;
            //     }
            //     this.$axios.post(`${this.$orderUrl}/api/Order/RelatedCollection`,{
            //         orderKey: this.orderKey,
            //         orderNo: this.detailsInfo.orderNo,
            //         collectionKeys: this.collectionKeys,
            //     }).then((res) => {
            //         const { isSuccess } = res;
            //         if (isSuccess) {
            //             this.relevanceVisible = false;
            //             this.$message({ message: '关联成功', type: 'success'});
            //             this.getGatherInfo();
            //         }
            //     });
            // },
        }

    },
    mounted() {
        this.orderKey = this.$route.query.orderKey;
        this.getOrderInfo();    //获取订单信息

        // this.getGatherInfo();   //获取收款明细
        // this.getSpendingInfo(); //获取支出明细
        // this.getCollectionDropList();   //获取收款下拉框数据
    },
};
</script>

<style lang="scss" scoped>
.orderDetails{
    .topContent{
        background: #FFFFFF;
        padding: 20px;
        position: relative;
        .leftInfo{
            display: flex;
            align-items: center;
            .orderName{
                height: 32px;
                line-height: 32px;
                font-size: 20px;
                font-weight: bold;
                color: #383838;
                padding-right: 20px;
            }
        }
        .rightHandle{
            position: absolute;
            top: 20px;
            right: 20px;
        }
        .topInfo{
            margin-top: 10px;
            .el-form-item {
                margin-bottom: 0;
            }
            .valueColor{
                color: #3A3A3A;
            }
            .infoItem{
                // width: 150px;
                margin-right: 50px;
                .label{
                    height: 20px;
                    line-height: 20px;
                    font-size: 14px;
                    color: #5E6D82;
                }
                .value{
                    height: 28px;
                    line-height: 28px;
                    font-size: 14px;
                    color: #383838;
                }
            }
        }
    }
    .tabCard{
        margin-top: 20px;
        background: #FFFFFF;
        padding: 20px;
        .shoukuan{
            .describe{
                line-height: 28px;
                font-size: 14px;
                font-weight: bold;
                margin-bottom: 10px;
                span{
                    padding-left: 20px;
                }
            }
        }
    }
}
/deep/.el-dialog__body {
    padding: 10px 20px;
}
</style>