<script>
import Vue from 'vue'
import router from "@/router";
import { ref, defineComponent } from '@vue/composition-api'
import axios from '@/assets/js/axios'
import ShowTooltipOverflowText from '@/components/ShowTooltipOverflowText.vue'
import { Message } from 'element-ui'
export default defineComponent({
    components: {
        ShowTooltipOverflowText
    },
    props: ['businessKey','customerKey'],
    setup(props) {
        const isExpand = ref(true)
        const list = ref([]);
        const getList = async () => {
                try {
                const res = await axios({
                    method: 'POST',
                    url: Vue.prototype.$sjUrl + '/BusinessChance/GetPageOrderOrQuote',
                    data: {
                        businessKey: props.businessKey,
                        type: 0, //0订单 1报价单
                        pageIndex: 1,
                        pageSize: 50
                    }
                })
                list.value = res.data.datas;
            } catch { null }
        }
        getList()

        const toAdd = () => {
            let location = router.resolve({
                path: "/orderManage/addOrder",
                query: { customerKey: props.customerKey, businessKey: props.businessKey },
            });
            window.open(location.href, "_blank");
        }

        const toDetails = (item) =>{
            let routeData = router.resolve({
                path: "/orderManage/orderDetails",
                query: { orderKey: item.uniqueKey },
            });
            window.open(routeData.href, "_blank");
        } 

        const dialogVisible = ref(false);
        const btnLoading = ref(false);
        const optionList = ref([]);
        const chooseKey = ref("");
        const openDialog = () =>{
            dialogVisible.value = true;
            chooseKey.value = "";
            getOption();
        }
        const getOption = () =>{
            axios.post(`${Vue.prototype.$orderUrl}/api/Order/V2/GetOrders`,{
                pageIndex: 1,
                pageSize: 200,
            }).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    optionList.value = data.datas;
                }
            });
        }
        const onSubmit = () =>{
            if(!chooseKey.value){return false;}
            btnLoading.value = true;
            axios.post(`${Vue.prototype.$sjUrl}/BusinessChance/AddBusinessChanceRelation`,{
                dataKey: chooseKey.value,
                businessKey: props.businessKey,
                type: 0,
            }).then((res) => {
                btnLoading.value = false;
                const { isSuccess } = res;
                if (isSuccess) {
                    dialogVisible.value = false;
                    Message.success('关联成功')
                    getList()
                }
            });
        }

        return {
            isExpand,
            list,
            toAdd,
            toDetails,
            //关联订单
            dialogVisible,
            btnLoading,
            optionList,
            chooseKey,
            openDialog,
            getOption,
            onSubmit,
        }
    }
})
</script>

<template>
    <div>
        <div class="top" @click="isExpand = !isExpand">
            <span>{{ `订单(${list.length})` }}</span>
            <div style="display: flex; align-items: center">
                <img @click.stop="openDialog" src="@/assets/img/add-icon.png" style="margin-right: 20px" />
                <i :class="isExpand ? 'el-icon-arrow-down' : 'el-icon-arrow-up'" style="font-size: 20px"></i>
            </div>
        </div>
        <div class="body" :class="isExpand ? 'expand' : ''">
            <el-scrollbar style="height: 100%">
                <div class="item" v-for="item of list" :key="item.key">
                    <div style="display: flex; align-items: center; justify-content: space-between; color: #707070">
                        <span>{{item.dataName}}</span>
                        <span>{{item.createTime}}</span>
                        <span>CNY{{item.dataAmount}}</span>
                        <span style="width: 30px;cursor: pointer;color: var(--themeColor);" @click="toDetails(item)">查看</span>
                    </div>
                </div>
            </el-scrollbar>
        </div>

        <el-dialog title="关联订单" :visible.sync="dialogVisible" width="500px" append-to-body>
            <el-select v-model="chooseKey" placeholder="请选择订单" size="small">
                <el-option v-for="item in optionList" :key="item.uniqueKey" :label="item.orderName" :value="item.uniqueKey">
                    <span>{{item.orderName}}({{item.orderNo}})</span>
                </el-option>
            </el-select>
            <el-button size="small" @click="toAdd">新建订单</el-button>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" @click="onSubmit" :loading="btnLoading">确 认</el-button>
                <el-button size="small" @click="dialogVisible = false">取 消</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<style scoped lang="scss">
.top {
    cursor: pointer;
    box-sizing: border-box;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 17px;
    background: #e5e9f2;
    font-size: 16px;
    border: 1px solid #d5d5d5;
    color: #000;
}
.body {
    box-sizing: border-box;
    border: 1px solid #d5d5d5;
    border-top: none;
    height: 0;
    overflow: hidden;
    transition: all 0.3s;
    &.expand {
        height: 120px;
    }
}
::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
}
.item {
    padding: 5px 20px;
    font-size: 14px;
    border-bottom: 1px solid #d5d5d5;
}
</style>
