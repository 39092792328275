<template>
  <div class="view">
    <el-button @click="$emit('close')" style="position: absolute; top: 20px; right: 20px;" size="small">关闭</el-button>
    <div class="title-panel">联系人导入</div>
    <div style="display: flex;justify-content: space-between;">
      <div style="padding-left: 60px; padding-top: 30px">
        <div style="display: flex">
          <div style="height: 100%">
            <el-steps direction="vertical" :active="1" space="120px">
              <el-step title="填写表格" />
              <el-step title="上传表格" />
              <!-- <el-step title="选择分组" /> -->
            </el-steps>
          </div>
          <div style="display: flex; flex-direction: column; margin-left: 50px">
            <div style="height: 100%; display: inline-flex">
              <div style="color: #707070">按导入模板，填写表格</div>
              <div style="margin-left: 34px; cursor: pointer; height: max-content; display: flex; align-items: center">
                <img src="@/assets/icon/xls.png" />
                <span @click="handleDownloadTemplate" style="color: var(--themeColor); text-decoration: underline">联系人导入模板.xlsx</span>
              </div>
              <div style="margin-left: 36px; color: #c7c7c7">
                <svg xmlns="http://www.w3.org/2000/svg" width="16.001" height="16.001" viewBox="0 0 16.001 16.001">
                  <g transform="translate(61 -62.89)">
                    <path fill="#f96b0d" d="M-53,78.891a8.024,8.024,0,0,1-8-8,8.024,8.024,0,0,1,8-8,8.024,8.024,0,0,1,8,8,8.024,8.024,0,0,1-8,8Zm0-14.858a6.878,6.878,0,0,0-6.857,6.857A6.878,6.878,0,0,0-53,77.748a6.878,6.878,0,0,0,6.857-6.857A6.878,6.878,0,0,0-53,64.033Zm0,8a.573.573,0,0,1-.571-.571v-4A.573.573,0,0,1-53,66.89a.573.573,0,0,1,.571.571v4a.573.573,0,0,1-.571.571Zm0,2.857a.573.573,0,0,1-.571-.571v-.571A.573.573,0,0,1-53,73.176a.573.573,0,0,1,.571.571v.571A.573.573,0,0,1-53,74.89Zm0,0" transform="translate(0)" />
                  </g>
                </svg>
                单次导入不超过1000条
              </div>
            </div>
            <div style="height: 100%">
              <el-upload ref="upload" :headers="headers" :action="uploadUrl" :auto-upload="false" :multiple="false" :limit="1" :data="uploadData" :on-success="uploadOk">
                <el-button slot="trigger" size="small" type="primary" plain>上传表格</el-button>
                <span style="margin-left: 22px; color: #707070">表格大小控制在5M以内</span>
              </el-upload>
            </div>
           <!--  <div style="height: 100%">
              <el-select v-model="formp.groupKey" style="width: 100px" placeholder="分组" size="small">
                <el-option v-for="item of grpus" :key="item.key" :label="item.groupName" :value="item.key" />
              </el-select>
            </div> -->
          </div>
        </div>
        <div style="position: relative; bottom: 60px">
          <el-button type="primary" size="small" @click="submitUpload">一键导入</el-button>
        </div>
      </div>
      <!-- <div style="box-sizing: border-box;margin-top: 28px;padding: 30px;width: 574px;flex-shrink: 0;border: 1px solid #E1E1E1;">
        <div style="color: #3a3a3a; font-weight: bold; font-size: 18px;">导入历史</div>
        <el-table :data="[{}]" size="small" :header-cell-style="{ fontWeight: 'normal', color: '#3A3A3A' }" :row-style="({ rowIndex }) => rowIndex % 2 ? { background: '#fff' } : { background: '#F0F4F7' }">
          <el-table-column label="文件">
            <template>
              <div style="display: flex;align-items: center;">
                <img src="@/assets/icon/xls.png" style="margin-right: 5px" />
                <span>导入客户模板.xlsx</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="t" label="导入时间" />
        </el-table>
      </div> -->
    </div>
  </div>
</template>

<script>
import axios from '@/assets/js/axios'
export default {
  data() {
    return {
      uploadUrl: `${this.$marketUrl}/WhatsApp/ExcelExportContacts`,
      headers: {
        authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      uploadData: {},
      grpus: [],
      formp: {
        groupKey: ''
      }
    };
  },
  methods: {

    handleDownloadTemplate() {
      window.open("https://crm.qixinerp.com/ossfile/联系人导入模板.xlsx", "_blank");
    },
    submitUpload() {
      this.$refs.upload.submit();
    },
    uploadOk(response) {
      const { isSuccess, errorMessage, data } = response;
      if (isSuccess) {
        this.$alert(data, '导入结果', {
          confirmButtonText: '确定',
          callback: () => { }
        });
      } else {
        this.$message({ message: errorMessage, type: 'warning' });
      }
    },
  },
  mounted() {
    axios({
      method: 'GET',
      url: this.$marketUrl + '/WhatsApp/GetGroup'
    }).then(res => {
      this.grpus = res.data
      console.log(res.data)
      this.formp.groupKey = this.grpus[0].key
    })
  }
};
</script>

<style lang="scss" scoped>
.view {
  position: absolute;
  z-index: 201;
  width: 100%;
  height: 100%;
  background: #fff;
  font-size: 14px;

  ::v-deep .el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell {
    background: #E4EAEF !important;
  }
}

.title-panel {
  color: #3a3a3a;
  font-size: 24px;
  padding-left: 40px;
  height: 75px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e6e7eb;
  font-weight: bold;
}
</style>
