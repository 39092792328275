<template>
    <div class="FinanceNav">
        <div class="navItem" @click="chooseNav('/finance/performanceManage')" :class="{ active: currentPath === '/finance/performanceManage' }">
            业绩管理
        </div>
        <div class="navItem" @click="chooseNav('/finance/receiptRecord')" :class="{ active: currentPath === '/finance/receiptRecord' }">
            到账记录
        </div>
    </div>
</template>

<script>
export default {
    props: ["currentPath"],
    data() {
        return {

        };
    },
    methods: {
        chooseNav(index) {
            this.$emit("chooseNav",index);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/scss/common.scss';
.FinanceNav {
    width: 200px;
    height: calc(100vh - 140px);
    background: #ffffff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    .oneClass{
        padding: 5px 15px;
        height: 24px;
        line-height: 24px;
        font-size: 12px;
        color: #aaa;
    }

    .navItem {
        cursor: pointer;
        padding: 0 15px;
        height: 40px;
        line-height: 40px;
        color: #000;
        font-size: 14px;
        &.active {
            color: #fff;
            background: $themeColor;
        }
        img{
            vertical-align: text-bottom;
            margin-right: 5px;
        }
    }
}
</style>