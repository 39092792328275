/* eslint-disable */
import * as echarts from 'echarts'
import '@/firm/utils/world.js'
import '@/firm/utils/china.js'

export default echarts

const countries = [
  'Afghanistan',
  'Singapore',
  'Angola',
  'Albania',
  'United Arab Emirates',
  'Argentina',
  'Armenia',
  'French Southern and Antarctic Lands',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Burundi',
  'Belgium',
  'Benin',
  'Burkina Faso',
  'Bangladesh',
  'Bulgaria',
  'The Bahamas',
  'Bosnia and Herzegovina',
  'Belarus',
  'Belize',
  'Bermuda',
  'Bolivia',
  'Brazil',
  'Brunei',
  'Bhutan',
  'Botswana',
  'Central African Republic',
  'Canada',
  'Switzerland',
  'Chile',
  'China',
  'Ivory Coast',
  'Cameroon',
  'Democratic Republic of the Congo',
  'Republic of the Congo',
  'Colombia',
  'Costa Rica',
  'Cuba',
  'Northern Cyprus',
  'Cyprus',
  'Czech Republic',
  'Germany',
  'Djibouti',
  'Denmark',
  'Dominican Republic',
  'Algeria',
  'Ecuador',
  'Egypt',
  'Eritrea',
  'Spain',
  'Estonia',
  'Ethiopia',
  'Finland',
  'Fiji',
  'Falkland Islands',
  'France',
  'Gabon',
  'United Kingdom',
  'Georgia',
  'Ghana',
  'Guinea',
  'Gambia',
  'Guinea Bissau',
  'Greece',
  'Greenland',
  'Guatemala',
  'French Guiana',
  'Guyana',
  'Honduras',
  'Croatia',
  'Haiti',
  'Hungary',
  'Indonesia',
  'India',
  'Ireland',
  'Iran',
  'Iraq',
  'Iceland',
  'Israel',
  'Italy',
  'Jamaica',
  'Jordan',
  'Japan',
  'Kazakhstan',
  'Kenya',
  'Kyrgyzstan',
  'Cambodia',
  'Kosovo',
  'Kuwait',
  'Laos',
  'Lebanon',
  'Liberia',
  'Libya',
  'Sri Lanka',
  'Lesotho',
  'Lithuania',
  'Luxembourg',
  'Latvia',
  'Morocco',
  'Moldova',
  'Madagascar',
  'Mexico',
  'Macedonia',
  'Mali',
  'Myanmar',
  'Montenegro',
  'Mongolia',
  'Mozambique',
  'Mauritania',
  'Malawi',
  'Malaysia',
  'Namibia',
  'New Caledonia',
  'Niger',
  'Nigeria',
  'Nicaragua',
  'Netherlands',
  'Norway',
  'Nepal',
  'New Zealand',
  'Oman',
  'Pakistan',
  'Panama',
  'Peru',
  'Philippines',
  'Papua New Guinea',
  'Poland',
  'Puerto Rico',
  'North Korea',
  'Portugal',
  'Paraguay',
  'Qatar',
  'Romania',
  'Russia',
  'Rwanda',
  'Western Sahara',
  'Saudi Arabia',
  'Sudan',
  'South Sudan',
  'Senegal',
  'Solomon Islands',
  'Sierra Leone',
  'El Salvador',
  'Somaliland',
  'Somalia',
  'Republic of Serbia',
  'Suriname',
  'Slovakia',
  'Slovenia',
  'Sweden',
  'Swaziland',
  'Syria',
  'Chad',
  'Togo',
  'Thailand',
  'Tajikistan',
  'Turkmenistan',
  'East Timor',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'United Republic of Tanzania',
  'Uganda',
  'Ukraine',
  'Uruguay',
  'United States',
  'Uzbekistan',
  'Venezuela',
  'Vietnam',
  'Vanuatu',
  'West Bank',
  'Yemen',
  'South Africa',
  'Zambia',
  'Korea',
  'Tanzania',
  'Zimbabwe',
  'Congo',
  'Central African Rep.',
  'Serbia',
  'Bosnia and Herz.',
  'Czech Rep.',
  'W. Sahara',
  'Lao PDR',
  'Dem.Rep.Korea',
  'Falkland Is.',
  'Timor-Leste',
  'Solomon Is.',
  'Palestine',
  'N. Cyprus',
  'Aland',
  'Fr. S. Antarctic Lands',
  'Mauritius',
  'Comoros',
  'Eq. Guinea',
  'Guinea-Bissau',
  'Dominican Rep.',
  'Saint Lucia',
  'Dominica',
  'Antigua and Barb.',
  'U.S. Virgin Is.',
  'Montserrat',
  'Grenada',
  'Barbados',
  'Samoa',
  'Bahamas',
  'Cayman Is.',
  'Faeroe Is.',
  'IsIe of Man',
  'Malta',
  'Jersey',
  'Cape Verde',
  'Turks and Caicos Is.',
  'St. Vin. and Gren.',
  'Dem. Rep. Congo',
  'S. Sudan',
  "Côte d'Ivoire"
]

export { countries }
