<template>
    <div>
        <div class="titleName">
            <span>{{titleList[type]}}</span>
            <el-button v-if="isAdmin" type="primary" size="mini" @click="addItem">新增字段</el-button>
            <span v-else class="setTipText">非管理员无法设置操作,请联系管理员操作</span>
        </div>
        <div class="content" v-loading="btnLoading">
            <div class="topItem">
                <div class="group">字段分组</div>
                <div class="name">字段名称</div>
                <div class="isShow" v-if="isAdmin">是否展示</div>
                <div class="operate" v-if="isAdmin">操作</div>
            </div>
            <div v-if="isAdmin">
            <VueDraggable v-model="fieldList" group="site" chosen-class="domChosen" animation="1000">
                <div class="oneItem" v-for="(item,index) in fieldList" :key="index" v-show="!item.isDelete">
                    <div class="group">
                        <el-input v-model="item.groupName" size="small"></el-input>
                    </div>
                    <div class="name">
                        <el-input v-model="item.field" size="small"></el-input>
                    </div>
                    <div class="isShow" >
                        <el-switch v-model="item.isShow"></el-switch>
                    </div>
                    <div class="operate">
                        <div class="handleDelBtn" style="margin:0;" @click="delItem(item)">删除</div>
                    </div>
                </div>
            </VueDraggable>
            </div>
            <div v-else class="twoItem" v-for="(item,index) in fieldList" :key="index" v-show="!item.isDelete">
                <div class="group">
                    {{ item.groupName }}
                </div>
                <div class="name">
                    {{ item.field }}
                </div>
            </div>
        </div>
        <div class="saveBtn">
            <el-button v-if="isAdmin" style="width:120px;" type="primary" size="small" @click="saveFieldConfig" :loading="btnLoading">保 存</el-button>
        </div>
    </div>
</template>

<script>
import VueDraggable from 'vuedraggable'
export default {
    components: { VueDraggable },
    props:["type"],
    data() {
        return {
            titleList:["客户字段","订单字段","产品字段","报价单字段","供应商字段","采购记录字段"],
            btnLoading: false,
            fieldList: [],
            isAdmin: false,
        };
    },
    methods: {
        //获取字段配置
        getFieldConfig(){
            this.btnLoading = true;
            this.$axios.post(`${this.$customerUrl}/DynamicField/GetFields`,{
                type: this.type * 1,
                field: "",
            }).then((res) => {
                const { isSuccess, data } = res;
                this.btnLoading = false;
                if (isSuccess) {
                    this.fieldList = data;
                    if(data.length == 0 && this.isAdmin){
                        this.addItem();
                    }
                }
            });
        },
        //保存字段配置
        saveFieldConfig(){
            if(!this.isAdmin){
                this.$message({ message: '非管理员无法保存字段,请联系管理员操作', type: 'warning'});
                return false;
            }
            this.fieldList = this.fieldList.filter(ele => ele.field.trim() != "");
            this.btnLoading = true;
            this.$axios.post(`${this.$customerUrl}/DynamicField/SaveField`, this.fieldList).then((res) => {
                const { isSuccess, data } = res;
                this.btnLoading = false;
                if (isSuccess) {
                    this.$message({ message: '配置成功', type: 'success'});
                }
            });
        },
        //新增字段
        addItem(){
            this.fieldList.push({
                key: "",
                groupName: "补充字段",
                field: "",
                fieldType: "input",
                isShow: true,
                type: this.type * 1,
                isDelete: false,
            })
        },
        delItem(item){
            item.isDelete = true;
        }
    },
    mounted() {
        this.isAdmin = JSON.parse(localStorage.userInfo).isAdmin;

        this.getFieldConfig();
    },
    watch: {
        type(){
            this.getFieldConfig();
        }
    }
};
</script>
<style lang="scss" scoped>
.titleName{
    height: 32px;
    line-height: 32px;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
}
.content{
    .topItem{
        height: 40px;
        line-height: 40px;
        display: flex;
        font-size: 14px;
        background: #fafafa;
    }
    .oneItem{
        height: 40px;
        line-height: 40px;
        display: flex;
        font-size: 14px;
        cursor: move;
        margin-bottom: 5px;
        border: solid 2px transparent;
    }
    .twoItem{
        height: 40px;
        line-height: 40px;
        display: flex;
        font-size: 14px;
        margin-bottom: 5px;
        border: solid 2px transparent;
    }
    .group{
        width: 180px;
        padding: 0 10px;
        box-sizing: border-box;
    }
    .name{
        width: calc(100% - 540px);
        padding: 0 10px;
        box-sizing: border-box;
    }
    .isShow,.operate{
        width: 180px;
        padding: 0 10px;
        box-sizing: border-box;
        text-align: center;
    }
}
.saveBtn{
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
    span{
        line-height: 32px;
        font-size: 14px;
        color: #6a6a6a;
        padding-right: 20px;
    }
}

.domChosen{
    background-color: #f1f1f1;
    border: solid 2px var(--themeColor) !important;
}
</style>