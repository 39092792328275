<template>
    <div class="viewbox">
        <div class="boxOne">
            <div class="title">财务收入</div>
            <div class="rightHandle">
                <!-- <el-button size="small" type="primary" icon="el-icon-plus" @click="toNewCollection">新建收入</el-button> -->
            </div>
        </div>

        <div class="boxTwo">
            <div class="txt">已选择{{chooseList.length}}个收入</div>
            <div class="rightHandle">
                <el-input v-model="filterParam.orderNo" placeholder="订单编号" prefix-icon="el-icon-search" size="small" style="width:180px;margin-right:10px;" @keyup.enter.native="toSearch"></el-input>

                <el-input v-model="filterParam.customerName" placeholder="公司名称" size="small" style="width:180px;margin-right:10px;" @keyup.enter.native="toSearch"></el-input>
                
                <el-input v-model="filterParam.collectionNo" placeholder="收汇单号" size="small" style="width:180px;margin-right:10px;" @keyup.enter.native="toSearch"></el-input>

                <el-date-picker v-model="filterParam.createTime" type="date" value-format="yyyy-MM-dd" placeholder="创建时间" size="small" style="width:180px;" @change="toSearch"></el-date-picker>
            </div>
        </div>

        <div class="boxTable">
            <el-table style="width:100%;cursor: pointer;" height="calc(100vh - 250px)" v-loading="tableLoading" :data="collectionList"
                :header-cell-style="{'background-color':'#F2F2F2','color':'#3A3A3A'}" :row-style="rowStyle"
                @selection-change="selectionChange">
                <el-table-column type="selection" width="45"></el-table-column>

                <el-table-column prop="collectionNo" label="收汇单号" width="200">
                    <template slot-scope="scope">
                        <el-link @click.stop="toDetails(scope.row)">{{scope.row.collectionNo}}</el-link>
                    </template>
                </el-table-column>
                <el-table-column prop="payerDate" label="收汇日期" width="120"></el-table-column>
                <el-table-column label="收汇客户" width="280">
                    <template slot-scope="scope">
                        <div class="company">{{scope.row.payer}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="payerContacts" label="联系人"></el-table-column>
                <el-table-column prop="collectionCurrency" label="收汇币种"></el-table-column>
                <el-table-column label="收汇金额">
                    <template slot-scope="scope">
                        <div class="amount">{{scope.row.payeeCollectionAmount}}</div>
                    </template>
                </el-table-column>
                <!-- <el-table-column label="我方公司">
                    <template slot-scope="scope">
                        <div class="company">{{scope.row.weCompany}}</div>
                    </template>
                </el-table-column> -->
                <el-table-column prop="handler" label="经手人"></el-table-column>
                <el-table-column prop="paymentName" label="款项名称"></el-table-column>
                <el-table-column prop="remarks" label="备注"></el-table-column>
                <!-- <el-table-column label="操作" width="100">
                    <template slot-scope="scope">
                        <el-link @click.stop="toDetails(scope.row)">查看</el-link>
                    </template>
                </el-table-column> -->
            </el-table>
        </div>

        <div class="bottomPagination">
            <el-pagination background layout="total,sizes,prev,pager,next" :page-sizes="[10, 20, 40]" :page-size.sync="pageSize" @size-change="pageSizeChange" :total="totalItems" style="float:right;margin:15px 20px;" :current-page.sync="pageIndex" @current-change="pageIndexChange"></el-pagination>
        </div>

    </div>
</template>

<script>
export default {
    props: {
        handleSelect: {
            type: Function,
            default: null,
        },
    },
    data() {
        return {
            // 过滤字段
            filterParam: {
                orderNo: "",
                customerName: "",
                collectionNo: "",
                createTime: "",
            },
            //列表展示
            tableLoading: false,
            collectionList: [],
            totalItems: 0,
            pageIndex: 1,
            pageSize: 10,

            chooseList: [],
        };
    },
    methods: {
        rowStyle({rowIndex}){
            // 表格隔行换色
            if(rowIndex % 2 == 1){
                return {'background-color':'#F9FAFC'}
            }
        },

        toNewCollection(){
            this.handleSelect(`/orderManage/addCollection`);
            this.$router.push(`/orderManage/addCollection`);
        },
        toSearch(){
            this.pageIndex = 1;
            this.getList();
        },
        getList(){
            const {orderNo,collectionNo,customerName,createTime} = this.filterParam;
            this.tableLoading = true;
            this.$axios.post(`${this.$orderUrl}/api/Collection/GetCollectionList`,{
                orderNo,collectionNo,customerName,createTime,
                type: 1,    //财务收入列表
                pageIndex: this.pageIndex,
                pageSize: this.pageSize
            }).then((res) => {
                const { isSuccess , data } = res;
                this.tableLoading = false;
                if (isSuccess) {
                    this.collectionList = data.datas;
                    this.totalItems = data.totalItems;
                }
            });
        },
        pageIndexChange(page) {
            this.pageIndex = page;
            this.getList();
        },
        pageSizeChange() {
            this.pageIndex = 1;
            this.getList();
        },
        //前往收入详情
        toDetails(row){
            // this.handleSelect(`/orderManage/collectionDetails?collectionKey=${row.key}`);
            // this.$router.push(`/orderManage/collectionDetails?collectionKey=${row.key}`);
            let routeData = this.$router.resolve({
                path: "/orderManage/collectionDetails",
                query: { collectionKey: row.key },
            });
            window.open(routeData.href, "_blank");
        },

        selectionChange(value){
            console.log(value);
            this.chooseList = value;
        },

    },
    mounted() {
        this.getList();
    },
};
</script>

<style lang="scss" scoped>
.viewbox{
    background: rgba($color: #FFFFFF, $alpha: 0.7);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    margin-bottom: 60px;
    .boxOne,.boxTwo{
        overflow: hidden;
        padding: 8px 0;
        line-height: 32px;
        border-bottom: 1px solid #E6E7EB;
        .title{
            float: left;
            margin-left: 40px;
            font-size: 24px;
            font-weight: bold;
            color: #3A3A3A;
        }
        .txt{
            float: left;
            margin-left: 40px;
            font-size: 16px;
            color: #7C90A5;
        }
        .rightHandle{
            float: right;
            margin-right: 20px;
        }
    }

    .boxTable{
        .company{
            color: #004594;
        }
        .amount{
            color: #20BC5F;
        }
    }

    .bottomPagination{
        position: fixed;
        bottom: 0;
        right: 0;
        z-index: 10;
        width: calc(100vw - 200px);
        height: 60px;
        background: #FFFFFF;
        box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.16);
    }
}
</style>