<template>
    <div>
        <div class="header">
            <div class="title">我的邮箱</div>
            <div class="describe">管理您的邮箱</div> 
            <el-button class="addBtn" @click="toNew" size="small" type="primary" icon="el-icon-plus">新建</el-button>
        </div>
        <el-table :data="eMailList" :header-cell-style="{'background-color':'#F3F3F3','color':'#3A3A3A'}" :row-style="rowStyle">
            <el-table-column prop="eMail" label="邮箱">
                <template slot-scope="scope">
                    <span>{{ scope.row.eMail }}</span>
                    <el-tag type="danger" size="mini" v-if="scope.row.isError">异常</el-tag>
                    <el-tag type="success" size="mini" v-else>正常</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="createDate" label="创建时间"></el-table-column>
            <el-table-column label="操作" width="200">
                <template slot-scope="scope">
                    <div>
                        <div class="handleEditBtn" style="width:60px;" @click="toEdit(scope.row)">修改密码</div>
                        <div class="handleDelBtn" @click="toDel(scope.row)">删除</div>
                    </div>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="640px">
            <el-form :model="submitForm" label-width="100px" size="small">
                <el-form-item label="邮箱账号">
                    <el-input v-model="submitForm.eMail" placeholder="邮箱账号" clearable :disabled="dialogTitle=='修改密码'"></el-input>
                </el-form-item>
                <el-form-item label="邮箱密码">
                    <el-input v-model="submitForm.eMailPwd" placeholder="邮箱密码" show-password clearable></el-input>
                </el-form-item>
                <template v-if="dialogTitle=='添加邮箱'">
                    <el-form-item label="邮箱类型">
                        <el-radio-group v-model="submitForm.type" size="small">
                            <el-radio v-for="item in emailTypeOption" :key="item" :label="item" border></el-radio>
                            <!-- <el-radio label="" border>手动填写</el-radio> -->
                        </el-radio-group>
                    </el-form-item>
                    <template v-if="!submitForm.type">
                        <el-form-item label="smtp域名">
                            <el-input v-model="submitForm.smtpHost" placeholder="smtp域名" clearable></el-input>
                        </el-form-item>
                        <el-form-item label="imap域名">
                            <el-input v-model="submitForm.imapHost" placeholder="imap域名" clearable></el-input>
                        </el-form-item>
                        <div style="line-height: 32px;color: #6a6a6a;margin-bottom: 12px">*联系公司邮箱管理员</div>
                    </template>
                    <el-form-item label="拉取历史邮件">
                        <el-radio-group v-model="submitForm.pullingType" size="small">
                            <el-radio-button :label="-1">不拉取</el-radio-button>
                            <el-radio-button :label="0">最近一周</el-radio-button>
                            <el-radio-button :label="1">最近1个月</el-radio-button>
                            <el-radio-button :label="2">最近3个月</el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                </template>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" @click="onSubmit" :loading="btnLoading">确 认</el-button>
                <el-button size="small" @click="dialogVisible = false">取 消</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            eMailList: [],
            dialogVisible: false,
            dialogTitle: "",
            submitForm: {},
            btnLoading: false,
            //邮箱类型下拉框
            emailTypeOption: [],
        };
    },
    methods: {
        rowStyle({rowIndex}){
            // 表格隔行换色
            if(rowIndex % 2 == 1){
                return {'background-color':'#F9FAFC'}
            }
        },

        getList(){
            this.$axios.get(`${this.$mailUrl}/v1/config/SendCollectMail`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.eMailList = data;
                }
            });
        },
        toNew(){
            this.dialogTitle = "添加邮箱";
            this.dialogVisible = true;
            this.submitForm = {
                key: "",
                eMail: "",
                eMailPwd: "",
                smtpHost: "",
                imapHost: "",
                type: "腾讯企业邮箱",
                pullingType: 0, //-1(不拉取) 0(最近一周) 1(最近1个月) 2(最近3个月)
            };
        },
        toEdit(row){
            this.dialogTitle = "修改密码";
            this.dialogVisible = true;
            this.submitForm = {
                key: row.key,
                eMail: row.eMail,
                eMailPwd: "",
                smtpHost: row.smtpHost,
                imapHost: row.imapHost,
                type: row.type,
                pullingType: row.pullingType,
            };
        },
        onSubmit(){
            // console.log(this.submitForm);
            const {eMail,eMailPwd,smtpHost,imapHost,type} = this.submitForm;
            if(!(eMail && eMailPwd)){
                this.$message({ message: '请完善邮箱和密码!', type: 'warning'});
                return false;
            }
            if(!type && !(smtpHost && imapHost)){
                this.$message({ message: '请选择邮箱类型或者手动填写域名!', type: 'warning'});
                return false;
            }
            this.btnLoading = true;
            this.$axios.post(`${this.$mailUrl}/v1/config/SendCollectMail`,this.submitForm).then((res) => {
                const { isSuccess, errorMessage } = res;
                this.btnLoading = false;
                if (isSuccess) {
                    this.dialogVisible = false;
                    this.$message({ message: '提交成功', type: 'success'});
                    this.getList();
                }
                if (errorMessage == "服务器连接失败") {
                    this.submitForm.type = "";
                }
            });
        },
        toDel(row){
            this.$confirm(`是否删除该邮箱：<strong style='color:var(--themeColor);'>${row.eMail}</strong>?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                dangerouslyUseHTMLString: true
            }).then(() => {
                this.$axios.delete(`${this.$mailUrl}/v1/config/SendCollectMail?key=${row.key}`).then(res => {
                    const {isSuccess} = res;
                    if(isSuccess){
                        this.$message({type: 'success',message: '删除成功!'});
                        this.getList();
                    }
                });
            }).catch(() => {});
        },

        //获取邮箱类型
        getType(){
            this.$axios.get(`${this.$mailUrl}/v1/config/SendCollectMail/GetEmailType`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.emailTypeOption = data;
                }
            });
        }

    },
    mounted() {
        this.getList();
        this.getType();
    },
};
</script>

<style lang="scss" scoped>
.header{
    margin-bottom: 20px;
    overflow: hidden;
    height: 32px;
    line-height: 32px;
    .title{
        float: left;
        font-size: 16px;
        color: #333;
        font-weight: bold;
    }
    .describe{
        float: left;
        margin-left: 20px;
        font-size: 12px;
        color: #708090;
    }
    .addBtn{
        float: right;
    }
}
.el-radio--small.is-bordered{
    margin-right: 10px;
    margin-bottom: 10px;
}
.el-radio.is-bordered+.el-radio.is-bordered{
    margin-left: 0;
}
</style>