<template>
    <div class="CustomerData">
        <div class="EnterpriseInfo">
            <div class="title">企业信息</div>
            <el-descriptions :column="2" border>
                <el-descriptions-item label="公司名">Apple Inc.</el-descriptions-item>
                <el-descriptions-item label="国家">United States</el-descriptions-item>
                <el-descriptions-item label="行业状态">Active</el-descriptions-item>
                <el-descriptions-item label="注册时间">1977-01-01</el-descriptions-item>
                <el-descriptions-item label="传真">---</el-descriptions-item>
                <el-descriptions-item label="邮编">95014-0642</el-descriptions-item>
                <el-descriptions-item label="官网">
                    <el-link type="primary">https://www.apple.com.cn/</el-link>
                </el-descriptions-item>
                <el-descriptions-item label="法人">Timothy D Cook</el-descriptions-item>
                <el-descriptions-item label="经度,纬度">-122.0124，39.9075</el-descriptions-item>
                <el-descriptions-item label="地理精度">Town Centroid</el-descriptions-item>
                <el-descriptions-item label="默认使用货币">USD</el-descriptions-item>
                <el-descriptions-item label="年收入">274515000000 USD</el-descriptions-item>
                <el-descriptions-item label="语言">English</el-descriptions-item>
                <el-descriptions-item label="注册业务成立日期">2010-03-03</el-descriptions-item>
                <el-descriptions-item label="联系电话">4089961010</el-descriptions-item>
                <el-descriptions-item label="在职员工人数">2000</el-descriptions-item>
                <el-descriptions-item label="所属洲" :span="2">North America</el-descriptions-item>
                <el-descriptions-item label="地址" :span="2">
                    2950 N Madera Rd Simi Valley, CA 93065-6230, USA 2950 N Madera Rd
                </el-descriptions-item>
            </el-descriptions>
        </div>

        <div style="margin-top: 20px;">
            <div class="title">企业地址</div>
            <el-empty description="暂无数据"></el-empty>
        </div>

        <div style="margin-top: 20px;">
            <div class="title">财务信息</div>
            <el-table :data="financialList" border :header-cell-style="{'background-color':'#F8FAFF','color':'#1F2D3D'}">
                <el-table-column prop="name" label="#"></el-table-column>
                <el-table-column prop="reportValue" label="最新财务报表"></el-table-column>
                <el-table-column prop="reportValue" label="财务标准报表"></el-table-column>
                <el-table-column prop="reportValue" label="最新季度报表"></el-table-column>
                <el-table-column prop="reportValue" label="季度标准报表"></el-table-column>
            </el-table>
        </div>

        <div style="margin-top: 20px;">
            <div class="title">联系人信息</div>
            <el-table :data="contactList" border :header-cell-style="{'background-color':'#F8FAFF','color':'#1F2D3D'}">
                <el-table-column prop="sort" label="#" width="50"></el-table-column>
                <el-table-column prop="name" label="姓名"></el-table-column>
                <el-table-column prop="emailAddress" label="邮箱地址"></el-table-column>
                <el-table-column prop="position" label="职位"></el-table-column>
            </el-table>
        </div>

        <div style="margin-top: 20px;">
            <div class="title">雇员信息</div>
            <el-table :data="employeeList" border :header-cell-style="{'background-color':'#F8FAFF','color':'#1F2D3D'}">
                <el-table-column prop="name" label="姓名"></el-table-column>
                <el-table-column prop="gender" label="性别"></el-table-column>
                <el-table-column prop="professionalTitle" label="职称"></el-table-column>
                <el-table-column prop="role" label="管理角色"></el-table-column>
            </el-table>
        </div>

        <div style="margin-top: 20px;">
            <div class="title">UsSIC——美国SIC行业分类代码</div>
            <el-row :gutter="30">
                <el-col :span="8" class="cardItem" v-for="item in UsSIC" :key="item.sort">
                    <el-card>
                        <div class="sort">{{item.sort}}</div>
                        <div class="code">{{item.code}}</div>
                        <div class="name">{{item.name}}</div>
                    </el-card>
                </el-col>
            </el-row>
        </div>

        <div style="margin-top: 20px;">
            <div class="title">NAICS——北美工业分类系统</div>
            <el-row :gutter="30">
                <el-col :span="8" class="cardItem" v-for="item in NAICS" :key="item.sort">
                    <el-card>
                        <div class="sort">{{item.sort}}</div>
                        <div class="code">{{item.code}}</div>
                        <div class="name">{{item.name}}</div>
                    </el-card>
                </el-col>
            </el-row>
        </div>

        <div style="margin-top: 20px;">
            <div class="title">公司曾用名</div>
            <el-table :data="formerNameList" border :header-cell-style="{'background-color':'#F8FAFF','color':'#1F2D3D'}">
                <el-table-column prop="name" label="名称"></el-table-column>
                <el-table-column prop="startTime" label="开始时间"></el-table-column>
                <el-table-column prop="endTime" label="结束时间"></el-table-column>
            </el-table>
        </div>

        <div style="margin-top: 20px;">
            <div class="title">社会评论</div>
            <el-table :data="socialCommentList" border :header-cell-style="{'background-color':'#F8FAFF','color':'#1F2D3D'}">
                <el-table-column prop="keyword" label="关键词" width="180"></el-table-column>
                <el-table-column prop="abstract" label="摘要信息"></el-table-column>
            </el-table>
        </div>

        <div style="margin-top: 20px;">
            <div class="title">历史雇佣情况</div>
            <el-table :data="employmentStatusList" border :header-cell-style="{'background-color':'#F8FAFF','color':'#1F2D3D'}">
                <el-table-column prop="totalNum" label="总人数(合并/单个实体/仅总部)"></el-table-column>
                <el-table-column prop="lowerlimit" label="人数下限"></el-table-column>
                <el-table-column prop="upperlimit" label="人数上限"></el-table-column>
                <el-table-column prop="reportDate" label="报告日期"></el-table-column>
                <el-table-column prop="range" label="员工人数范围"></el-table-column>
                <el-table-column prop="otherValue" label="员工数据的完整性"></el-table-column>
                <el-table-column prop="otherValue" label="计算描述"></el-table-column>
            </el-table>
        </div>

        <div style="margin-top: 20px;">
            <div class="title">拥有业务的银行或金融机构信息</div>
            <el-table :data="bankList" border :header-cell-style="{'background-color':'#F8FAFF','color':'#1F2D3D'}">
                <el-table-column prop="name" label="银行或金融机构的名称"></el-table-column>
            </el-table>
        </div>

        <div style="margin-top: 20px;">
            <div class="title">竞争对手信息</div>
            <el-table :data="competitorList" border :header-cell-style="{'background-color':'#F8FAFF','color':'#1F2D3D'}">
                <el-table-column prop="duns" label="邓白氏码"></el-table-column>
                <el-table-column prop="companyName" label="公司名称"></el-table-column>
                <el-table-column prop="peopleNumber" label="雇佣人数"></el-table-column>
                <el-table-column prop="salesRevenue" label="销售收入"></el-table-column>
                <el-table-column prop="money" label="发行股本金额"></el-table-column>
            </el-table>
        </div>

        <div style="margin-top: 20px;">
            <div class="title">资本细节</div>
            <el-table :data="capitalList" border :header-cell-style="{'background-color':'#F8FAFF','color':'#1F2D3D'}">
                <el-table-column prop="type" label="资本类型"></el-table-column>
                <el-table-column prop="registrationData" label="资本额首次登记日期"></el-table-column>
                <el-table-column prop="money" label="资本金额"></el-table-column>
                <el-table-column prop="currency" label="资本货币"></el-table-column>
            </el-table>
        </div>

        <div style="margin-top: 20px;">
            <div class="title">其他信息</div>
            <div class="otherInfo">
                <div class="infoItem">
                    <div class="label">公司状态：</div>
                    <div class="value">Division or Single Location</div>
                </div>
                <div class="infoItem">
                    <div class="label">成立年份：</div>
                    <div class="value">1993</div>
                </div>
                <div class="infoItem">
                    <div class="label">员工数：</div>
                    <div class="value">41</div>
                </div>
                <div class="infoItem">
                    <div class="label">官网：</div>
                    <div class="value">www.kingsbridgeinc.com</div>
                </div>
                <div class="infoItem">
                    <div class="label">国家：</div>
                    <div class="value">USA</div>
                </div>
                <div class="infoItem">
                    <div class="label">地区、州：</div>
                    <div class="value">CA</div>
                </div>
                <div class="infoItem">
                    <div class="label">城市：</div>
                    <div class="value">Simi Valley</div>
                </div>
                <div class="infoItem">
                    <div class="label">地址：</div>
                    <div class="value">2950 N Madera Rd Simi Valley, CA 93065-6230, USA 2950 N Madera Rd</div>
                </div>
                <div class="infoItem">
                    <div class="label">邮编：</div>
                    <div class="value">930656230</div>
                </div>
                <div class="infoItem">
                    <div class="label">产品：</div>
                    <div class="value">5199-NONDURABLE GOODS, NEC</div>
                </div>
                <div class="infoItem">
                    <div class="label">联系人：</div>
                    <div class="value">Paul J Foster</div>
                </div>
                <div class="infoItem">
                    <div class="label">职位：</div>
                    <div class="value">President</div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            financialList: [],
            contactList: [],
            employeeList: [],
            UsSIC: [],
            NAICS: [],
            formerNameList: [],
            socialCommentList: [],
            employmentStatusList: [],
            bankList: [],
            competitorList: [],
            capitalList: [],
        };
    },
    methods: {
        //获取财务信息
        getFinancialInfo(){
            this.financialList = [
                {name: "数据来源", reportValue: "Morningstar" },
                {name: "报表截止时间", reportValue: "2020-09-30" },
                {name: "报表持续时间", reportValue: "12M" },
                {name: "财政结束月份", reportValue: "September" },
                {name: "提交财务报表日期", reportValue: "2020-10-30" },
            ]
        },
        //获取联系人信息
        getContactInfo(){
            this.contactList = [
                {sort:'1', name:'T001', emailAddress: 'T001@lsmaps.com', position: '技术人员'},
                {sort:'2', name:'T002', emailAddress: 'T002@lsmaps.com', position: '技术人员'},
                {sort:'3', name:'T003', emailAddress: 'T003@lsmaps.com', position: '技术人员'},
                {sort:'4', name:'T004', emailAddress: 'T004@lsmaps.com', position: '技术人员'},
                {sort:'5', name:'T005', emailAddress: 'T005@lsmaps.com', position: '技术人员'},
            ]
        },
        //获取雇员信息
        getEmployeeInfo(){
            this.employeeList = [
                {name: 'ARTHUR D LEVINSON', gender: '男', professionalTitle: 'Chairman of the Board', role: 'Chairman of the Board'},
                {name: 'JEFF WILLIAMS', gender: '男', professionalTitle: 'Chairman of the Board', role: 'Chairman of the Board'},
                {name: 'PENG XIAN XIN', gender: '男', professionalTitle: 'Chairman of the Board', role: 'Chairman of the Board'},
                {name: 'SHEN YUAN', gender: '男', professionalTitle: 'Chairman of the Board', role: 'Chairman of the Board'},
                {name: 'CAO FEI FEI', gender: '男', professionalTitle: 'Chairman of the Board', role: 'Chairman of the Board'},
            ]
        },
        //获取代码信息
        getCodeInfo(){
            this.UsSIC = [
                {sort:1, code:'01', name:'农业生产农作物'},
                {sort:2, code:'1222', name:'地下开采（亚烟煤）'},
                {sort:3, code:'2022', name:'天然,加工干酪'},
            ];
            this.NAICS = [
                {sort:1, code:'443142', name:'Electronics stores'},
                {sort:2, code:'423430', name:'Computer and Computer Periph'},
                {sort:3, code:'443142', name:'Electronics stores'},
            ];
        },
        //获取公司曾用名
        getFormerName(){
            this.formerNameList = [
                {name:'Beijing Xiaomi Technology Co., Ltd.', startTime:'2020-02-10', endTime:'2021-02-10'}
            ]
        },
        //获取社会评论   
        getSocialComment(){
            this.socialCommentList = [
                {keyword: 'Short Company Profile',abstract: "Ask Siri to name the most successful company in the world and it might respond: Apple. And it''''s not just out of familial pride. Apple consistently ranks highly in profit, revenue, market capitalization, and consumer cachet and it was the first to reach a trillion dollar market cap. The iPhone, in its 13th year, has been the company''''s golden goose, generating tens of billions in revenue and profit. Other Apple products and services include Mac computers and iPad tablets as well as the App Store, Apple Music, the Apple Watch, and other wearable"},
                {keyword: 'Short Company Profile',abstract: "Ask Siri to name the most successful company in the world and it might respond: Apple. And it''''s not just out of familial pride. Apple consistently ranks highly in profit, revenue, market capitalization, and consumer cachet and it was the first to reach a trillion dollar market cap. The iPhone, in its 13th year, has been the company''''s golden goose, generating tens of billions in revenue and profit. Other Apple products and services include Mac computers and iPad tablets as well as the App Store, Apple Music, the Apple Watch,"},
                {keyword: 'Short Company Profile',abstract: "Ask Siri to name the most successful company in the world and it might respond: Apple. And it''''s not just out of familial pride. Apple consistently ranks highly in profit, revenue, market capitalization, and consumer cachet and it was the first to reach a trillion dollar market cap. The iPhone, in its 13th year, has been the company''''s golden goose, generating tens of billions in revenue and profit. Other Apple products and services include Mac computers and iPad tablets as well as the App Store, Apple Music, the Apple Watch, and other wearable "},
            ]
        },
        //获取历史雇佣情况
        getHistoricalEmployment(){
            this.employmentStatusList = [
                {totalNum: '4720', lowerlimit: '4720', upperlimit: '-', reportDate: '2020-10-10', range: 'Individual', otherValue: '-'},
                {totalNum: '4720', lowerlimit: '4720', upperlimit: '-', reportDate: '2020-10-10', range: 'Individual', otherValue: '-'},
                {totalNum: '4720', lowerlimit: '4720', upperlimit: '-', reportDate: '2020-10-10', range: 'Individual', otherValue: '-'},
                {totalNum: '4720', lowerlimit: '4720', upperlimit: '-', reportDate: '2020-10-10', range: 'Individual', otherValue: '-'},
                {totalNum: '4720', lowerlimit: '4720', upperlimit: '-', reportDate: '2020-10-10', range: 'Individual', otherValue: '-'},
            ]
        },
        //获取拥有业务的银行或金融机构信息
        getBankInfo(){
            this.bankList = [
                {name:'CIBC'},
                {name:'TD Canada Trust'},
                {name:'Scotia Bank'},
                {name:'National Bank of Canada'},
            ]
        },
        //获取竞争对手信息
        getCompetitorInfo(){
            this.competitorList = [
                {duns:'687967554', companyName: 'Samsung Electronics Co., Ltd.',peopleNumber: '104355',salesRevenue:'149014827136', money:'503681'},
                {duns:'687967554', companyName: 'Samsung Electronics Co., Ltd.',peopleNumber: '104355',salesRevenue:'149014827136', money:'503681'},
                {duns:'687967554', companyName: 'Samsung Electronics Co., Ltd.',peopleNumber: '104355',salesRevenue:'149014827136', money:'503681'},
                {duns:'687967554', companyName: 'Samsung Electronics Co., Ltd.',peopleNumber: '104355',salesRevenue:'149014827136', money:'503681'},
                {duns:'687967554', companyName: 'Samsung Electronics Co., Ltd.',peopleNumber: '104355',salesRevenue:'149014827136', money:'503681'},
            ]
        },
        //获取资本细节
        getCapitalDetails(){
            this.capitalList = [
                {type:'Ordinary Share Capital (GmbH)', registrationData:'2018-10-23', money: '338810300', currency: 'EUR'},
                {type:'Ordinary Share Capital (GmbH)', registrationData:'2018-10-23', money: '338810300', currency: 'EUR'},
                {type:'Ordinary Share Capital (GmbH)', registrationData:'2018-10-23', money: '338810300', currency: 'EUR'},
                {type:'Ordinary Share Capital (GmbH)', registrationData:'2018-10-23', money: '338810300', currency: 'EUR'},
                {type:'Ordinary Share Capital (GmbH)', registrationData:'2018-10-23', money: '338810300', currency: 'EUR'},
            ]
        },
    },
    mounted() {
        this.getFinancialInfo();        //获取财务信息
        this.getContactInfo();          //获取联系人信息
        this.getEmployeeInfo();         //获取雇员信息
        this.getCodeInfo();             //获取代码信息
        this.getFormerName();           //获取公司曾用名
        this.getSocialComment();        //获取社会评论
        this.getHistoricalEmployment(); //获取历史雇佣情况
        this.getBankInfo();             //获取拥有业务的银行或金融机构信息
        this.getCompetitorInfo();       //获取竞争对手信息
        this.getCapitalDetails();       //获取资本细节
    },
};
</script>

<style lang="scss" scoped>
@import '@/scss/common.scss';
.CustomerData{
    .title{
        height: 16px;
        line-height: 16px;
        font-size: 16px;
        font-weight: bold;
        color: #2A3F58;
        padding-left: 6px;
        border-left: 4px solid $themeColor;
        margin-bottom: 15px;
    }
    .cardItem{
        /deep/.el-card__body {
            padding: 10px 10px 10px 30px;
        }
        position: relative;
        .sort{
            position: absolute;
            top: 5px;
            left: 25px;
            color: $themeColor;
        }
        .code{
            color: #2E4756;
            line-height: 24px;
        }
        .name{
            font-size: 14px;
            color: #909090;
            line-height: 24px;
        }
    }
    .otherInfo{
        background: #F8FAFF;
        border: 1px solid #D7D7D8;
        padding: 10px;
        .infoItem{
            display: flex;
            line-height: 28px;
            color: #1F2D3D;
            font-size: 14px;
            .label{
                width: 80px;
                text-align: right;
            }
            .value{
                margin-left: 20px;
            }
        }
    }
}
</style>