<template>
    <div>
        <!-- 阿根廷进口提单明细/阿根廷出口提单明细(字段共用) -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr v-if="ie=='i'">
                        <th width="25%">进口编号</th>
                        <td width="25%">{{billInfo.ImportId}}</td>
                        <th width="25%">进口商ID</th>
                        <td width="25%">{{billInfo.ImporterId}}</td>
                    </tr>
                    <tr v-if="ie=='e'">
                        <th width="25%">出口编号</th>
                        <td width="25%">{{billInfo.ExportId}}</td>
                        <th width="25%">出口商ID</th>
                        <td width="25%">{{billInfo.ExporterId}}</td>
                    </tr>
                    <tr>
                        <th>型号</th>
                        <td>{{billInfo.Variety}}</td>
                        <th>品牌</th>
                        <td>{{billInfo.Brand}}</td>
                    </tr>
                    <tr>
                        <th>FOB总价（美元）</th>
                        <td>{{billInfo.TotalFobUsd}}</td>
                        <th>CIF总价（美元）</th>
                        <td>{{billInfo.TotalCifUsd}}</td>
                    </tr>
                    <tr>
                        <th>总运费</th>
                        <td>{{billInfo.Freightus}}</td>
                        <th>总保险</th>
                        <td>{{billInfo.InsuranceUs}}</td>
                    </tr>
                    <tr>
                        <th>单项FOB总价</th>
                        <td>{{billInfo.TotalItemFobUsd}}</td>
                        <th>单项CIF总价</th>
                        <td>{{billInfo.TotalItemCifUsd}}</td>
                    </tr>
                    <tr>
                        <th>单项运费</th>
                        <td>{{billInfo.FreightItem}}</td>
                        <th>单项保险</th>
                        <td>{{billInfo.InsuranceItem}}</td>
                    </tr>
                    <tr>
                        <th>项与子项编号</th>
                        <td>{{billInfo.ItemSubitem}}</td>
                        <th>子项数量</th>
                        <td>{{billInfo.StatQuantity}} {{billInfo.StatUnitOfMeasure}}</td>
                    </tr>
                    <tr>
                        <th>子项FOB单价(美元)</th>
                        <td>{{billInfo.UnitFobUsd}}</td>
                        <th>子项FOB单价(货币)</th>
                        <td>{{billInfo.UnitaryCurrency}} {{billInfo.Currency}}</td>
                    </tr>
                    <tr>
                        <th>子项FOB总价(货币)</th>
                        <td>{{billInfo.TotalFobCurrency}} {{billInfo.Currency}}</td>
                        <th>总包装数量</th>
                        <td>{{billInfo.Numberofpackages}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
        
        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">运输信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">{{ie=='i' ? "来源国" : "目的国"}}</th>
                        <td width="25%">{{billInfo.Country}}</td>
                        <th width="25%">供应国</th>
                        <td width="25%">{{billInfo.Adqcountry}}</td>
                    </tr>
                    <tr>
                        <th>关区</th>
                        <td>{{billInfo.Custom}}</td>
                        <th>起运港</th>
                        <td>{{billInfo.Embarqport}}</td>
                    </tr>
                    <tr>
                        <th>货物状态</th>
                        <td>{{billInfo.GoodCondition}}</td>
                        <th>运输方式</th>
                        <td>{{billInfo.Typeoftransport}}</td>
                    </tr>
                    <tr>
                        <th>运输航次编号</th>
                        <td>{{billInfo.TransportNum}}</td>
                        <th>成交方式</th>
                        <td>{{billInfo.Incoterms}}</td>
                    </tr>
                    <tr>
                        <th>属性</th>
                        <td>{{billInfo.Details}}</td>
                        <th>条件类型</th>
                        <td>{{billInfo.Subregimen}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">计税信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">计税基数</th>
                        <td width="25%">{{billInfo.TaxBase ? billInfo.TaxBase : 0 +' USD'}}</td>
                        <th width="25%">计税单价</th>
                        <td width="25%">{{billInfo.TaxBaseSubitemUsd ? billInfo.TaxBaseSubitemUsd : 0 +' USD'}}</td>
                    </tr>
                    <tr>
                        <th>关税百分比</th>
                        <td colspan="3">{{billInfo.TariffPercent ? billInfo.TariffPercent : 0 +' %'}}</td>
                    </tr>
                    <tr>
                        <th>关税金额</th>
                        <td>{{billInfo.TariffUsd ? billInfo.TariffUsd : 0 +' USD'}}</td>
                        <th>关税金额（保证金）</th>
                        <td>{{billInfo.TariffGuarantee ? billInfo.TariffGuarantee : 0}}</td>
                    </tr>
                    <tr>
                        <th>统计关税百分比</th>
                        <td>{{billInfo.StatisticalPercent ? billInfo.StatisticalPercent : 0 +' %'}}</td>
                        <th>统计关税金额</th>
                        <td>{{billInfo.StatisticalUsd ? billInfo.StatisticalUsd : 0 +' USD'}}</td>
                    </tr>
                    <tr>
                        <th>增值税百分比</th>
                        <td>{{billInfo.VatPercent ? billInfo.VatPercent : 0 +' %'}}</td>
                        <th>增值税</th>
                        <td>{{billInfo.Vat ? billInfo.Vat : 0 +' USD'}}</td>
                    </tr>
                    <tr>
                        <th>增值税（保证金）</th>
                        <td>{{billInfo.VatGuarantee ? billInfo.VatGuarantee : 0}}</td>
                        <th>附加增值税百分比</th>
                        <td>{{billInfo.AdditionalVatPercent ? billInfo.AdditionalVatPercent : 0 +' %'}}</td>
                    </tr>
                    <tr>
                        <th>附加增值税</th>
                        <td>{{billInfo.AdditionalVat ? billInfo.AdditionalVat : 0 +' USD'}}</td>
                        <th>总收入比</th>
                        <td>{{billInfo.GrossIncomePercent ? billInfo.GrossIncomePercent : 0 +' %'}}</td>
                    </tr>
                    <tr>
                        <th>总收入</th>
                        <td>{{billInfo.GrossIncome ? billInfo.GrossIncome : 0 +' USD'}}</td>
                        <th>总收益比</th>
                        <td>{{billInfo.GainsPercent ? billInfo.GainsPercent : 0 +' %'}}</td>
                    </tr>
                    <tr>
                        <th>总收益税</th>
                        <td>{{billInfo.GainsTaxesUsd ? billInfo.GainsTaxesUsd : 0 +' USD'}}</td>
                        <th>收益税（保证金）</th>
                        <td>{{billInfo.GainsTaxesGuarantee ? billInfo.GainsTaxesGuarantee : 0}}</td>
                    </tr>
                    <tr>
                        <th>SIM税百分比</th>
                        <td>{{billInfo.SimTaxPercent ? billInfo.SimTaxPercent : 0 +' %'}}</td>
                        <th>SIM税</th>
                        <td>{{billInfo.SimTax ? billInfo.SimTax : 0}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
</template>

<script>
export default {
    props:["billInfo","ie"],
    data() {
        return {

        };
    },
    methods: {
        
    },
    mounted() {

    },
};
</script>

<style lang="scss" scoped>
.partItem {
    margin-bottom: 20px;
    /deep/.el-card__header {
        padding: 10px 20px;
        .headTitle {
            display: inline-block;
            padding-left: 10px;
            font-size: 14px;
            border-left: 2px solid var(--themeColor);
            font-weight: bold;
        }
    }
}
table {
    width: 100%;
    border-collapse: collapse;
    tr {
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;
        th,
        td {
            font-size: 12px;
            color: #666;
            height: 40px;
            border: 1px solid #eaeaea;
            text-align: center;
        }
        th {
            background-color: #f5f7fa;
            font-weight: 700;
        }
        .bg {
            background-color: var(--themeColor);
            color: #fff;
            font-weight: 700;
            opacity: 0.8;
        }
    }
}
</style>