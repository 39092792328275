<template>
    <div class="searchBox">
        <div class="title">
            <span>联系信息</span>
        </div>
        <el-form :model="contactForm" labelPosition='top' size="small">
            <el-row :gutter="30">
                <el-col :span="6">
                    <el-form-item label="电话号码">
                        <el-input v-model="contactForm.phoneNumber" @change="fieldChange" placeholder="电话号码" style="width:100%;"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="邮箱">
                        <el-input v-model="contactForm.email" @change="fieldChange" placeholder="邮箱" style="width:100%;"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="CEO名称">
                        <el-input v-model="contactForm.ceoName" @change="fieldChange" placeholder="CEO名称" style="width:100%;"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="筛选传真">
                        <el-checkbox v-model="contactForm.hasFax" @change="fieldChange">筛选有传真记录</el-checkbox>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="筛选电话">
                        <el-checkbox v-model="contactForm.hasPhone" @change="fieldChange">筛选有电话记录</el-checkbox>
                        <el-checkbox v-show="contactForm.hasPhone" v-model="contactForm.hasPhoneDeduplicated" @change="fieldChange">过滤重复电话记录</el-checkbox>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="筛选邮箱">
                        <el-checkbox v-model="contactForm.hasEmail" @change="fieldChange">筛选有邮箱记录</el-checkbox>
                        <el-checkbox v-show="contactForm.hasEmail" v-model="contactForm.hasEmailDeduplicated" @change="fieldChange">过滤重复邮箱记录</el-checkbox>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
export default {
    data() {
        return {
            contactForm: {
                phoneNumber: "",
                email: "",
                ceoName: "",
                hasFax: false,
                hasPhone: false,
                hasPhoneDeduplicated: false,
                hasEmail: false,
                hasEmailDeduplicated: false,
            },
        };
    },
    methods: {
        fieldChange(){
            if(this.contactForm.hasPhone == false){
                this.contactForm.hasPhoneDeduplicated = false;
            }
            if(this.contactForm.hasEmail == false){
                this.contactForm.hasEmailDeduplicated = false;
            }
            this.$emit('inputComplete',this.contactForm);
        },
    },
    mounted() {

    },
};
</script>

<style lang="scss" scoped>
.searchBox{
    border-top: 1px solid #E6E6E6;
    padding-left: 40px;
    .title{
        color: #3A3A3A;
        font-weight: bold;
        font-size: 16px;
        margin: 10px 0;
    }
}
</style>