<template>
<div>
    <div v-if="ie=='i'">
        <!-- 菲律宾(PHL)进口提单明细 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th>提单号</th>
                        <td colspan="3">{{billInfo.BillOfLading}}</td>
                    </tr>
                    <tr>
                        <th width="25%">运输日期</th>
                        <td width="25%">{{billInfo.DeliveryDate}}</td>
                        <th width="25%">清关日期</th>
                        <td width="25%">{{billInfo.ClearanceDate}}</td>
                    </tr>
                    <tr>
                        <th>清关港</th>
                        <td>{{billInfo.LadingPort}}</td>
                        <th>卸货港</th>
                        <td>{{billInfo.UnLadingPort}}</td>
                    </tr>
                    <tr>
                        <th>原产国</th>
                        <td>{{billInfo.Country}}</td>
                        <th>船名</th>
                        <td>{{billInfo.VesselName}}</td>
                    </tr>
                    <tr>
                        <th>集装箱数量</th>
                        <td>{{billInfo.ContainerQuantity}}</td>
                        <th>标记</th>
                        <td>{{billInfo.ConsigementMarks}}</td>
                    </tr>
                    <tr>
                        <th>包装数量</th>
                        <td>{{billInfo.PackageNumber}} {{billInfo.PackageUnit}}</td>
                        <th>净重</th>
                        <td>{{billInfo.NetWeight}}</td>
                    </tr>
                    <tr>
                        <th>FOB总价（美元）</th>
                        <td>{{billInfo.Value}}</td>
                        <th>FOB单价（美元）</th>
                        <td>{{billInfo.FobUsdUnit}}</td>
                    </tr>
                    <tr>
                        <th>CIF总价（美元）</th>
                        <td>{{billInfo.CifUsd}}</td>
                        <th>CIF单价（美元）</th>
                        <td>{{billInfo.CifUsdUnit}}</td>
                    </tr>
                    <tr>
                        <th>保险</th>
                        <td>{{billInfo.InsuranceUsd}}</td>
                        <th>运费</th>
                        <td>{{billInfo.FreightUsd}}</td>
                    </tr>
                    <tr>
                        <th>汇率</th>
                        <td>{{billInfo.ExchangeRate}}</td>
                        <th>币种</th>
                        <td>{{billInfo.Currency}}</td>
                    </tr>
                    <tr>
                        <th>进口税</th>
                        <td>{{billInfo.DutiableValuePeso}}</td>
                        <th>进口增值税</th>
                        <td>{{billInfo.DutiableVatValuePeso}}</td>
                    </tr>
                    <tr>
                        <th>适用税率</th>
                        <td>{{billInfo.ApllcableDutyRate}}</td>
                        <th>总进口关税</th>
                        <td>{{billInfo.TotalImportDutyPeso}}</td>
                    </tr>
                    <tr>
                        <th>总应缴增值税</th>
                        <td>{{billInfo.TotalVatPayablePeso}}</td>
                        <th>消费税</th>
                        <td>{{billInfo.ExcisePayablePeso}}</td>
                    </tr>
                    <tr>
                        <th>罚金</th>
                        <td>{{billInfo.PenaltyPeso}}</td>
                        <th>其他应缴关税</th>
                        <td>{{billInfo.OtherPayablePeso}}</td>
                    </tr>
                    <tr>
                        <th>进口关税及税收</th>
                        <td>{{billInfo.ImportDutyTaxPeso}}</td>
                        <th>清关通道</th>
                        <td>{{billInfo.ClearanceChannel}}</td>
                    </tr>
                    <tr>
                        <th>适用责任协议</th>
                        <td>{{billInfo.DutySectionApplied}}</td>
                        <th>国际贸易术语</th>
                        <td>{{billInfo.Incoterms}}</td>
                    </tr>
                    <tr>
                        <th>特殊贸易协议</th>
                        <td colspan="3">{{billInfo.AppliedAgreement}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
    <div v-if="ie=='e'">
        <!-- 菲律宾(PHL)出口提单明细 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th>提单号</th>
                        <td colspan="3">{{billInfo.BillOfLading}}</td>
                    </tr>
                    <tr>
                        <th width="25%">运输日期</th>
                        <td width="25%">{{billInfo.DeliveryDate}}</td>
                        <th width="25%">清关日期</th>
                        <td width="25%">{{billInfo.ClearanceDate}}</td>
                    </tr>
                    <tr>
                        <th>目的国</th>
                        <td>{{billInfo.Country}}</td>
                        <th>起运港</th>
                        <td>{{billInfo.LadingPort}}</td>
                    </tr>
                    <tr>
                        <th>船名</th>
                        <td>{{billInfo.VesselName}}</td>
                        <th>集装箱数量</th>
                        <td>{{billInfo.ContainerQuantity}}</td>
                    </tr>
                    <tr>
                        <th>包装数量</th>
                        <td>{{billInfo.PackageNumber}} {{billInfo.PackageUnit}}</td>
                        <th>净重</th>
                        <td>{{billInfo.NetWeight}}</td>
                    </tr>
                    <tr>
                        <th>FOB总价（美元）</th>
                        <td>{{billInfo.Value}}</td>
                        <th>运费</th>
                        <td>{{billInfo.Freight}}</td>
                    </tr>
                    <tr>
                        <th>汇率</th>
                        <td>{{billInfo.ExchangeRate}}</td>
                        <th>币种</th>
                        <td>{{billInfo.Currency}}</td>
                    </tr>
                    <tr>
                        <th>进口税</th>
                        <td>{{billInfo.DutiableValuePeso}}</td>
                        <th>进口类型</th>
                        <td>{{billInfo.ImportType}}</td>
                    </tr>
                    <tr>
                        <th>适用责任协议</th>
                        <td>{{billInfo.DutySectionApplied}}</td>
                        <th>国际贸易术语</th>
                        <td>{{billInfo.Incoterms}}</td>
                    </tr>
                    <tr>
                        <th>特殊贸易协议</th>
                        <td>{{billInfo.AppliedAgreement}}</td>
                        <th>标记</th>
                        <td>{{billInfo.ConsigementMarks}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
</div>
</template>

<script>
export default {
    props:["billInfo","ie"],
    data() {
        return {

        };
    },
    methods: {
        
    },
    mounted() {
        
    },
};
</script>

<style lang="scss" scoped>
.partItem {
    margin-bottom: 20px;
    /deep/.el-card__header {
        padding: 10px 20px;
        .headTitle {
            display: inline-block;
            padding-left: 10px;
            font-size: 14px;
            border-left: 2px solid var(--themeColor);
            font-weight: bold;
        }
    }
}
table {
    width: 100%;
    border-collapse: collapse;
    tr {
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;
        th,
        td {
            font-size: 12px;
            color: #666;
            height: 40px;
            border: 1px solid #eaeaea;
            text-align: center;
        }
        th {
            background-color: #f5f7fa;
            font-weight: 700;
        }
        .bg {
            background-color: var(--themeColor);
            color: #fff;
            font-weight: 700;
            opacity: 0.8;
        }
    }
}
</style>