<template>
  <div id="tab-record-country-enterprise" class="tab-record-country-enterprise">
    <div class="title" ref="title">
      <p>交易记录</p>
      <div style="display: flex; align-items: center">
        <el-radio-group v-model="controlForm.option" size="mini" :disabled="loading">
          <el-radio-button :label="5">日期</el-radio-button>
          <el-radio-button :label="1">金额</el-radio-button>
          <el-radio-button :label="2">数量</el-radio-button>
          <el-radio-button :label="3">重量</el-radio-button>
        </el-radio-group>
        <img class="icon" src="@/firm/images/company/icon-refresh.png" style="margin-left: 1em" @click="refresh" />
      </div>
    </div>
    <div class="content">
      <el-skeleton :loading="loading" animated>
        <template slot="template">
          <el-skeleton :rows="20" />
        </template>
        <template>
          <el-table :data="tableData" :header-cell-style="{ background: '#E5E9F2', color: '#1F2D3D' }" :row-style="tableRowStyleCallBack" @row-click="handleTableRowClick">
            <el-table-column prop="dataSourceCountry" label="数据源" />
            <el-table-column prop="dateStr" label="日期" width="95px" />
            <el-table-column prop="hsCode" label="HS" />
            <el-table-column label="采购商" width="120px">
              <template slot-scope="{ row }">
                <!--@click.stop="openWindow(row.importerUrl)"-->
                <div>{{ row.importer }}</div>
              </template>
            </el-table-column>
            <el-table-column label="供应商" width="120px">
              <template slot-scope="{ row }">
                <!--@click.stop="openWindow(row.exporterUrl)"-->
                <div>{{ row.exporter }}</div>
              </template>
            </el-table-column>
            <el-table-column label="商品描述" width="220px">
              <template slot-scope="{ row }">
                <div>
                  <!--class="cursor-pointer"  style="color: var(--themeColor, #3152AF)"  @click.stop="openWindow(row.productUrl)"-->
                  <p style="position: relative">
                    <el-tooltip effect="light" :content="row.product" placement="top-start">
                      <span>{{ row.product | cutString }}</span>
                    </el-tooltip>
                    <!-- <span class="cursor-pointer" style="color: #1f2d3d; position: absolute; right: 5px; background: #fff" @click.stop="showTranslateDialog(row)">翻译</span> -->
                  </p>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="countryStd" label="国家" />
            <!-- <el-table-column prop="dataType" label="数据类型" /> -->
            <el-table-column prop="weight" label="重量" />
            <el-table-column prop="quantity" label="数量" />
            <el-table-column prop="value" label="金额" />
          </el-table>
          <div style="margin-top: 1em; display: flex; justify-content: flex-end">
            <el-pagination background layout="total, prev, pager, next" :total="pagination.total" :page-size="pagination.pageSize" :current-page="pagination.pageIndex" @current-change="handlePageIndexChange" />
          </div>
        </template>
      </el-skeleton>
    </div>
    <el-dialog :visible="translateDialog.vivible" width="540px" custom-class="translate-dialog" :show-close="false" :close-on-press-escape="false" :modal="false">
      <div>
        <p style="margin-bottom: 18px; font-size: 18px; color: #1f2d3d">信息</p>
        <div style="border: 1px solid #d3dce6; padding: 30px; color: #2a3f58; border-radius: 2px">
          <div>
            <p style="font-size: 16px; line-height: 1.5em; font-weight: 500">产品描述</p>
            <div style="font-size: 14px; line-height: 1.5em">{{ translateDialog.product }}</div>
          </div>
          <div style="border-top: 1px solid #e0e6ed; margin: 8px 0"></div>
          <div>
            <p style="font-size: 16px; line-height: 1.5em; font-weight: 500">译文</p>
            <div style="font-size: 14px; line-height: 1.5em">{{ translateDialog.translation }}</div>
          </div>
        </div>
        <div style="display: flex; justify-content: flex-end; margin-top: 20px">
          <el-button type="primary" @click="translateDialog.vivible = false" size="small">确定</el-button>
        </div>
      </div>
    </el-dialog>
    <el-drawer :visible.sync="rowDrawer.vivible" size="611px" custom-class="row-drawer" direction="rtl" :with-header="false">
      <div>
        <el-row>
          <el-col :span="8">
            <div class="container pl border t l title-color">数据源</div>
          </el-col>
          <el-col :span="16">
            <div class="container border t r">
              <div class="pl border l text-color">{{ rowDrawer.dataSourceCountry }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <div class="container pl border t l title-color">日期</div>
          </el-col>
          <el-col :span="16">
            <div class="container border t r">
              <div class="pl border l text-color">{{ rowDrawer.date }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <div class="container pl border t l title-color">HS编码</div>
          </el-col>
          <el-col :span="16">
            <div class="container border t r">
              <div class="pl border l text-color">{{ rowDrawer.hsCode }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="8">
            <div class="container pl border t l title-color" style="height: 100%; display: flex; align-items: center; box-sizing: border-box">进口商</div>
          </el-col>
          <el-col :span="16">
            <div class="border t r" style="padding: 13px 0">
              <div class="pl border l text-color">{{ rowDrawer.importer }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="8">
            <div class="container pl border t l title-color" style="height: 100%; display: flex; align-items: center; box-sizing: border-box">出口商</div>
          </el-col>
          <el-col :span="16">
            <div class="border t r" style="padding: 13px 0">
              <div class="pl border l text-color">{{ rowDrawer.exporter }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <div class="container pl border t l title-color">国家</div>
          </el-col>
          <el-col :span="16">
            <div class="container border t r">
              <div class="pl border l text-color">{{ rowDrawer.countryStd }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <div class="container pl border t l title-color">数据类型</div>
          </el-col>
          <el-col :span="16">
            <div class="container border t r">
              <div class="pl border l text-color">{{ rowDrawer.dataType }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <div class="container pl border t l title-color">重量</div>
          </el-col>
          <el-col :span="16">
            <div class="container border t r">
              <div class="pl border l text-color">{{ rowDrawer.weight }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <div class="container pl border t l title-color">数量</div>
          </el-col>
          <el-col :span="16">
            <div class="container border t r">
              <div class="pl border l text-color">{{ rowDrawer.quantity }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <div class="container pl border t l title-color">金额</div>
          </el-col>
          <el-col :span="16">
            <div class="container border t r">
              <div class="pl border l text-color">{{ rowDrawer.value }}</div>
            </div>
          </el-col>
        </el-row>
        <!-- <el-row>
          <el-col :span="8"><div class="container pl border t l title-color">频次均价</div></el-col>
          <el-col :span="16"
            ><div class="container border t r">
              <div class="pl border l text-color">{{ rowDrawer.frequencyAverage }}</div>
            </div></el-col
          >
        </el-row> -->
        <el-row>
          <el-col :span="8">
            <div class="container pl border t l title-color">数量均价</div>
          </el-col>
          <el-col :span="16">
            <div class="container border t r">
              <div class="pl border l text-color">{{ rowDrawer.quantityAverage }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <div class="container pl border t l title-color">重量均价</div>
          </el-col>
          <el-col :span="16">
            <div class="container border t r">
              <div class="pl border l text-color">{{ rowDrawer.weightAverage }}</div>
            </div>
          </el-col>
        </el-row>
        <!-- <el-row>
          <el-col :span="8"><div class="container pl border t l title-color">集装箱号</div></el-col>
          <el-col :span="16"
            ><div class="container border t r">
              <div class="pl border l text-color">{{ rowDrawer.containerNumber }}</div>
            </div></el-col
          >
        </el-row> -->
        <el-row v-show="rowDrawer.billNo">
          <el-col :span="8">
            <div class="container pl border t l title-color">提单号</div>
          </el-col>
          <el-col :span="16">
            <div class="container border t r">
              <div class="pl border l text-color">{{ rowDrawer.billNo }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="8">
            <div class="container pl border t l b title-color" style="height: 100%; display: flex; align-items: center; box-sizing: border-box">商品描述</div>
          </el-col>
          <el-col :span="16">
            <div class="border t r b" style="padding: 13px 0">
              <div class="pl border l text-color">{{ rowDrawer.product }}</div>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "@/firm/plugins/axios";
import { mapState, mapGetters, mapMutations } from "vuex";
import { tableRowStyleCallBack } from "@/firm/utils";
import LazyOperation from "@/firm/plugins/lazy-execute";
import { AnchorInditor } from "../../utils";

let lazyOperation = null;

export default Vue.extend({
  data() {
    return {
      loading: true,
      translateDialog: {
        vivible: false,
        product: "",
        translation: "",
      },
      rowDrawer: {
        vivible: false,
        dataSourceCountry: "", //数据源
        date: "", //日期
        hsCode: "", //HS
        importer: "", //进口商
        exporter: "", //出口商
        product: "", //商品描述
        //descriptionCN: "", //商品描述(译文)
        countryStd: "", //国家
        dataType: "", //数据类型
        weight: "", //重量
        quantity: "", //数量
        value: "", //金额

        //frequencyAverage: "", //频次均价
        quantityAverage: "", //数量均价
        weightAverage: "", //重量均价
        //containerNumber: "", //集装箱号
        billNo: "", //提单号
      },
      controlForm: {
        option: 5,
      },
      tableData: [],
      pagination: {
        total: 0,
        pageSize: 10,
        pageIndex: 1,
      },
    };
  },
  computed: {
    ...mapState(["company", "companyType"]),
    ...mapState("controlFormGlobal", {
      ie: "ie",
      dataCountry: "dataCountry",
      dateRange: "dateRange",
      hsCode: "hsCode",
      importer: "importer",
      exporter: "exporter",
      country: "country",

      exHsCode: "exHsCode",
      exExporter: "exExporter",
      exImporter: "exImporter",
      exCountry: "exCountry",
    }),
    ...mapGetters("controlFormGlobal", {
      startTime: "startTime",
      endTime: "endTime",
    }),
  },
  filters: {
    cutString(val) {
      if (val.length > 80) {
        return `${val.slice(0, 80)}...`;
      } else {
        return val;
      }
    },
  },
  watch: {
    "controlForm.option"() {
      lazyOperation?.reobserve();
    },
    ie() {
      lazyOperation?.reobserve();
    },
    dataCountry() {
      lazyOperation?.reobserve();
    },
    dateRange() {
      lazyOperation?.reobserve();
    },
    hsCode() {
      lazyOperation?.reobserve();
    },
    importer() {
      lazyOperation?.reobserve();
    },
    exporter() {
      lazyOperation?.reobserve();
    },
    country() {
      lazyOperation?.reobserve();
    },

    exHsCode() {
      lazyOperation?.reobserve();
    },
    exExporter() {
      lazyOperation?.reobserve();
    },
    exImporter() {
      lazyOperation?.reobserve();
    },
    exCountry() {
      lazyOperation?.reobserve();
    },

    companyType() {
      lazyOperation?.reobserve();
    },
  },
  methods: {
    ...mapMutations("anchor", {
      setCurrent: "setCurrent",
    }),
    openWindow(url) {
      if (url) {
        window.open(url, '_blank')
      }
    },
    tableRowStyleCallBack,
    async showTranslateDialog(row) {
      this.translateDialog.product = row.product;
      //this.translateDialog.translation = row.descriptionCN;
      this.translateDialog.vivible = true;
      console.log(row);
      const res = await axios({
        method: "post",
        url: "https://func.lsmaps.com/api/RestTranslate",
        params: {
          text: row.product,
          key: row.infoKey,
          from: "auto",
          to: "zh",
        },
      });
      console.log(res.data);
    },
    handleTableRowClick(row) {
      this.rowDrawer.dataSourceCountry = row.dataSourceCountry; //数据源
      this.rowDrawer.date = row.date; //日期
      this.rowDrawer.hsCode = row.hsCode; //HS
      this.rowDrawer.importer = row.importer; //进口商
      this.rowDrawer.exporter = row.exporter; //出口商
      this.rowDrawer.product = row.product; //商品描述
      //this.rowDrawer.descriptionCN = row.descriptionCN; //商品描述(译文)
      this.rowDrawer.countryStd = row.countryStd; //国家
      this.rowDrawer.dataType = row.dataType; //数据类型
      this.rowDrawer.weight = row.weight; //重量
      this.rowDrawer.quantity = row.quantity; //数量
      this.rowDrawer.value = row.value; //金额

      //this.rowDrawer.frequencyAverage = row.frequencyAverage; //频次均价
      this.rowDrawer.quantityAverage = row.quantityAverage; //数量均价
      this.rowDrawer.weightAverage = row.weightAverage; //重量均价
      //this.rowDrawer.containerNumber = row.containerNumber; //集装箱号
      this.rowDrawer.billNo = row.billNo; //提单号

      this.rowDrawer.vivible = true;
    },
    refresh() {
      const pageIndex = this.pagination.pageIndex;
      this.getTableData(pageIndex);
    },
    handlePageIndexChange(pageIndex) {
      this.getTableData(pageIndex);
    },
    async getTableData(pageIndex = 1) {
      try {
        this.loading = true;
        const res = await axios({
          method: "post",
          url: "/search/Credit/Trade/Search",
          data: {
            pageIndex: pageIndex,
            pageSize: this.pagination.pageSize,
            ie: this.ie,
            importer: this.importer,
            exporter: this.exporter,
            dataCountry: this.dataCountry,
            country: this.country,
            startTime: this.startTime,
            endTime: this.endTime,
            hsCode: this.hsCode,
            sortType: this.controlForm.option,
            noHsCode: this.exHsCode,
            noImporter: this.exImporter,
            noExporter: this.exExporter,
            noCountry: this.exCountry,
            container: null,
            company: this.company,
            companyType: this.companyType,
          },
        });
        this.tableData = res.data.result.trades;
        this.pagination.total = res.data.result.totalCount;
        this.pagination.pageIndex = pageIndex;
      } catch (error) {
        this.tableData = [];
        this.pagination.total = 0;
        this.pagination.pageIndex = 1;
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async openWindowCompany(impExport) {
      try {
        const res = await axios({
          method: "post",
          url: "https://vip.lsmaps.com/api/RestUser/EncryptCompany",
          params: {
            ie: this.ie.toUpperCase(),
            impExport,
            company: this.company,
          },
          data: {
            ie: this.ie.toUpperCase(),
            impExport,
            company: this.company,
          },
        });
        console.log(res);
        //window.open(`https://vip.lsmaps.com/Company/StatisticalAnalysis?u=${}`)
      } catch (error) {
        console.log(error);
      }
    },
    async openWindowDescription(row) {
      try {
        const ie = this.ie.toUpperCase();
        const { countryCode, dateStr, key, dataType, billNo } = row;
        const res = await axios({
          method: "post",
          url: "https://vip.lsmaps.com/api/RestUser/EncryptCountryInfo",
          params: {
            ie,
            countryCode,
            dateStr,
            key,
            dataType,
            billOfLadingNbr: billNo,
          },
          data: {
            ie,
            countryCode,
            dateStr,
            key,
            dataType,
            billOfLadingNbr: billNo,
          },
        });
        console.log(res);
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    lazyOperation = new LazyOperation(() => {
      this.getTableData();
    }, this.$el);
    new AnchorInditor(() => {
      this.setCurrent("TABJYJL");
    }, this.$refs["title"]);
  },
});
</script>

<style lang="scss" scoped>
@import "@/firm/scss/mixin.scss";

.tab-record-country-enterprise {
  margin-top: 1em;
  background: #fff;
  padding: 1rem;
  padding-top: 0;
  @include mixin-panel-title;

  .content {
    color: #1f2d3d;
  }
}

::v-deep .translate-dialog {
  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    padding: 20px;
  }
}

::v-deep .row-drawer {
  font-size: 14px;
  padding: 30px;

  .el-drawer__body {
    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: #d5d5d5;
    }

    scrollbar-width: thin;
    scrollbar-color: #d5d5d5 #fff;
  }

  .container {
    display: flex;
    height: 3em;
    align-items: center;
  }

  .title-color {
    color: #8492a6;
  }

  .text-color {
    color: #1f2d3d;
  }

  .pl {
    padding-left: 1em;
  }

  .border {
    border-style: solid;
    border-color: #d7d7d8;
    border-width: 0;

    &.t {
      border-top-width: 1px;
    }

    &.r {
      border-right-width: 1px;
    }

    &.b {
      border-bottom-width: 1px;
    }

    &.l {
      border-left-width: 1px;
    }
  }
}
</style>
