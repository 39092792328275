<template>
<div>
    <ClueNav @chooseNav="chooseNav" :currentPath="currentPath" />
    <div class="viewbox">
        <!-- <div class="header">
            <img class="logo" src="@/assets/cluePicture/logo-customs.png" alt="" />
            <div>
                <div class="theme">海关数据线索</div>
                <div class="txt">统计来自海关数据的相关线索</div>
            </div>
        </div> -->
    
        <div class="searchTerms">
            <div class="searchBox" style="margin: 10px 0;">
                <el-select v-model="searchType" placeholder="请选择" style="width:120px;">
                    <el-option label="公司" value="1"></el-option>
                    <el-option label="hscode" value="2"></el-option>
                    <el-option label="产品描述" value="3"></el-option>
                </el-select>
                <el-input placeholder="关键字" prefix-icon="el-icon-search" v-model="searchValue" @keyup.enter.native="onSearch" style="width:600px;"></el-input>
                <el-button type="primary" style="margin-left:20px;" @click="onSearch" :loading="searchLoading">搜索</el-button>
            </div>    
            <div class="itemTerm">
                <div class="name">洲:</div>
                <div class="spanList">
                    <div class="spanItem" :class="{'active':continentIndex==''}" @click="getCountrys('')">不限</div>
                    <div class="spanItem" v-for="ele in continentList" :key="ele.name" :class="{'active':continentIndex==ele.name}" @click="getCountrys(ele.name)">
                        {{ele.name}}({{ele.count}})
                    </div>
                </div>
            </div>
            <div class="itemTerm" v-show="isShowCountry">
                <div class="name">国家:</div>
                <div class="spanList">
                    <div class="spanItem" :class="{'active':countryIndex==''}" @click="clickCountry('')">不限</div>
                    <div class="spanItem" v-for="(ele,index) in countryList" v-show="index < showCountryNumMax" :key="ele.country" :class="{'active':countryIndex==ele.country}" @click="clickCountry(ele.country)">
                        {{ele.country}}({{ele.count}})
                    </div>
                    <div class="spanItem" style="color:var(--themeColor);" v-show="showCountryNumMax < countryList.length" @click="showCountryNumMax = countryList.length;">查看更多</div>
                </div>
            </div>
            <div class="itemTerm">
                <div class="name">二位编码:</div>
                <div class="spanList">
                    <div class="spanItem" :class="{'active':twoCodeIndex==''}" @click="getFourCode('')">不限</div>
                    <div class="spanItem" v-for="(ele,index) in twoCodeList" v-show="index < showTwoCodeNumMax" :key="ele.name" :class="{'active':twoCodeIndex==ele.name}" @click="getFourCode(ele.name)">
                        {{ele.name}}({{ele.count}})
                    </div>
                    <div class="spanItem" style="color:var(--themeColor);" v-show="showTwoCodeNumMax < twoCodeList.length" @click="showTwoCodeNumMax = twoCodeList.length;">查看更多</div>
                </div>
            </div>
            <div class="itemTerm" v-show="isShowFourCode">
                <div class="name">四位编码:</div>
                <div class="spanList">
                    <div class="spanItem" :class="{'active':fourCodeIndex==''}" @click="getSixCode('')">不限</div>
                    <div class="spanItem" v-for="(ele,index) in fourCodeList" v-show="index < showFourCodeNumMax" :key="ele.name" :class="{'active':fourCodeIndex==ele.name}" @click="getSixCode(ele.name)">
                        {{ele.name}}({{ele.count}})
                    </div>
                    <div class="spanItem" style="color:var(--themeColor);" v-show="showFourCodeNumMax < fourCodeList.length" @click="showFourCodeNumMax = fourCodeList.length;">查看更多</div>
                </div>
            </div>
            <div class="itemTerm" v-show="isShowSixCode">
                <div class="name">六位编码:</div>
                <div class="spanList">
                    <div class="spanItem" :class="{'active':sixCodeIndex==''}" @click="clickSix('')">不限</div>
                    <div class="spanItem" v-for="(ele,index) in sixCodeList" v-show="index < showSixCodeNumMax" :key="ele.name" :class="{'active':sixCodeIndex==ele.name}" @click="clickSix(ele.name)">
                        {{ele.name}}({{ele.count}})
                    </div>
                    <div class="spanItem" style="color:var(--themeColor);" v-show="showSixCodeNumMax < sixCodeList.length" @click="showSixCodeNumMax = sixCodeList.length;">查看更多</div>
                </div>
            </div>
            <div class="itemTerm">
                <div class="name">数据年限:</div>
                <el-radio-group v-model="dataYear" size="mini" @change="yearChange">
                    <el-radio-button label="1">近1年</el-radio-button>
                    <el-radio-button label="3">近3年</el-radio-button>
                    <el-radio-button label="5">近5年</el-radio-button>
                </el-radio-group>
            </div>
            <!-- <div class="itemTerm">
                <div class="name">查询类型:</div>
                <el-radio-group v-model="searchType" size="mini">
                    <el-radio-button label="1">公司</el-radio-button>
                    <el-radio-button label="2">hscode</el-radio-button>
                    <el-radio-button label="3">产品描述</el-radio-button>
                </el-radio-group>
            </div> -->
        </div>
    </div>

    <div class="viewbox" style="margin-top:20px;">
        <div class="searchResult noResult" v-show="totalItems==0" v-loading="searchLoading">
            <img class="hintImg" src="@/assets/cluePicture/hint.png" alt="" />
            <img class="noImg" src="@/assets/cluePicture/no1.png" alt="" />
            <div class="hintTxt">海关数据</div>
        </div>

        <div class="searchResult" v-show="totalItems!=0" v-loading="searchLoading">
            <div class="title">为您找到<span style="color:#FF2D2D;">{{totalItems}}</span>条相关结果</div>
            <div class="resultList">
                <div class="resultItem" v-for="item in resultList" :key="item.key">
                    <div style="margin-bottom: 8px;">
                        <div class="name" @click="toDetails(item)">{{item.company}}</div>
                        <div class="country">
                            <img :src="item.flagUrl" style="margin-right:5px;height:14px" />
                            <span>{{item.country}}</span>
                        </div>
                    </div>
                    <div style="display:flex;">
                        <div class="oneField">
                            <div class="color99">最新交易日期</div>
                            <div class="color3a">{{item.lastDate}}</div>
                        </div>
                        <div class="oneField">
                            <div class="color99">交易总次数</div>
                            <div class="color3a">{{item.totalNum}}</div>
                        </div>
                        <div class="oneField">
                            <div class="color99">交易国家数</div>
                            <div class="color3a">{{item.countryNum}}</div>
                        </div>
                        <div class="oneField">
                            <div class="color99">交易伙伴数</div>
                            <div class="color3a">{{item.partnerNum}}</div>
                        </div>
                        <!-- <div class="oneField">
                            <div class="color99">与(XX搜索词)相关采购单数</div>
                            <div class="color3a">IBM CORP</div>
                        </div> -->
                        <!-- <div class="oneField">
                            <div class="color99">采购商数量</div>
                            <div class="color3a">42家</div>
                        </div> -->
                        <!-- <div class="oneField">
                            <div class="color99">供应商数量</div>
                            <div class="color3a">51家</div>
                        </div> -->
                    </div>
                    <div class="twoField" v-if="item.hsCode.length != 0">
                        <span class="color99">HS编码：</span>
                        <span class="color3a" style="padding-right:10px;" v-for="(ele,index) in item.hsCode" :key="index" v-show="index<10" v-html="ele"></span>
                        <span class="primaryColor" style="margin-left:10px;" @click="toMoreCode(item)">[查看更多]</span>
                    </div>
                    <div class="twoField" v-if="item.productDesc.length != 0">
                        <span class="color99">商品描述：</span>
                        <span class="color3a" v-html="item.productDesc[0]"></span>
                        <span class="primaryColor" style="margin-left:10px;" @click="toMoreDes(item)">[查看更多]</span>
                    </div>
                    <div class="hasAdd" v-if="item.isMyCustomer">已加入我的客户</div>
                    <div class="addBtn" @click="addCustomer(item)" v-else>
                        <img src="@/assets/cluePicture/addCustomer.png" alt="" />加入我的客户
                    </div>
                </div>
                <el-pagination background layout="total,sizes,prev,pager,next" :page-sizes="[10, 20, 40]" :page-size.sync="pageSize" @size-change="pageSizeChange" :total="totalItems" style="float:right;margin-top:15px;" :current-page.sync="pageIndex" @current-change="pageIndexChange"></el-pagination>
            </div>
        </div>

        <!-- 验证解锁 -->
        <el-dialog title="验证解锁" :visible.sync="dialogVisible" width="500px" :show-close="false"
            :close-on-click-modal="false" :close-on-press-escape="false">
            <p style="line-height:28px;font-weight:bold;">请求过于频繁，请输入验证码：</p>
            <div style="display:flex;">
                <el-input v-model="imgCode" size="small"></el-input>
                <img class="pointer" :src="imgSrc" alt="" @click="openVerify" />
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" :disabled="!imgCode" @click="finishVerify">确 认</el-button>
            </span>
        </el-dialog>

        
        <!-- 查看更多(HS编码)(商品描述) -->
        <MoreCode v-if="moreCodeVisible" :viewKey="viewKey" :dataYear="dataYear" @close="moreCodeVisible = false;" />

        <MoreDes v-if="moreDesVisible" :viewKey="viewKey" :dataYear="dataYear" @close="moreDesVisible = false;" />

    </div>
</div>
</template>

<script>
import ClueNav from "@/components/ClueNav.vue";
import MoreCode from "./MoreInfo/MoreCode.vue"
import MoreDes from "./MoreInfo/MoreDes.vue"
export default {
    components: { ClueNav, MoreCode , MoreDes },
    props: {
        handleSelect: {
            type: Function,
            default: null,
        },
    },
    data() {
        return {
            currentPath: "/clue/customsClue",
            //搜索条件：洲，国家
            continentList: [],
            continentIndex: "",
            countryList: [],
            countryIndex: "",
            isShowCountry: false,
            showCountryNumMax: 10,
            //搜索条件：编码
            twoCodeList: [],
            twoCodeIndex: "",
            fourCodeList: [],
            fourCodeIndex: "",
            sixCodeList: [],
            sixCodeIndex: "",
            isShowFourCode: false,
            isShowSixCode: false,
            showTwoCodeNumMax: 10,
            showFourCodeNumMax: 10,
            showSixCodeNumMax: 10,
            //搜索条件：数据年限
            dataYear: "1",
            //搜索类型，搜索关键字
            searchType: "1", // 1 查公司 2查hscode 3 查产品描述
            searchValue: "",
            //搜索结果
            searchLoading: false,
            resultList: [],
            totalItems: 0,
            pageIndex: 1,
            pageSize: 10,
            //验证解锁
            dialogVisible: false,
            imgCode: "",
            imgKey: "",
            imgSrc: "",
            //查看更多(HS编码)(商品描述)
            moreCodeVisible: false,
            moreDesVisible: false,
            viewKey: "",
        };
    },
    methods: {
        chooseNav(index){
            this.handleSelect(index);
            this.$router.push(index);
        },

        getContinents(){
            this.$axios.get(`${this.$clueUrl}/ClickHouse/GetContinents?year=${this.dataYear}`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.continentList = data;
                }
            });
        },
        getCountrys(continent){
            this.continentIndex = continent;
            this.countryIndex = "";
            this.countryList = [];
            this.showCountryNumMax = 10;
            this.onSearch();
            if(continent){
                this.isShowCountry = true;
                this.$axios.get(`${this.$clueUrl}/ClickHouse/GetCountrys?year=${this.dataYear}&continent=${continent}`).then((res) => {
                    const { isSuccess, data } = res;
                    if (isSuccess) {
                        this.countryList = data;
                    }
                });
            }else{
                this.isShowCountry = false;
            }
        },
        getTwoCode(){
            this.showTwoCodeNumMax = 10;
            this.$axios.get(`${this.$clueUrl}/ClickHouse/GetTwoCode?year=${this.dataYear}`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.twoCodeList = data;
                }
            });
        },
        getFourCode(code){
            this.twoCodeIndex = code;
            this.fourCodeIndex = "";
            this.sixCodeIndex = "";
            this.fourCodeList = [];
            this.sixCodeList = [];
            this.showFourCodeNumMax = 10;
            this.onSearch();
            if(code){
                this.isShowFourCode = true;
                this.isShowSixCode = false;
                this.$axios.get(`${this.$clueUrl}/ClickHouse/GetFourCode?year=${this.dataYear}&towCode=${code}`).then((res) => {
                    const { isSuccess, data } = res;
                    if (isSuccess) {
                        this.fourCodeList = data;
                    }
                });
            }else{
                this.isShowFourCode = false;
                this.isShowSixCode = false;
            }
        },
        getSixCode(code){
            this.fourCodeIndex = code;
            this.sixCodeIndex = "";
            this.sixCodeList = [];
            this.showSixCodeNumMax = 10;
            this.onSearch();
            if(code){
                this.isShowSixCode = true;
                this.$axios.get(`${this.$clueUrl}/ClickHouse/GetSixCode?year=${this.dataYear}&fourCode=${code}`).then((res) => {
                    const { isSuccess, data } = res;
                    if (isSuccess) {
                        this.sixCodeList = data;
                    }
                });
            }else{
                this.isShowSixCode = false;
            }
        },
        clickCountry(country){
            this.countryIndex = country;
            this.onSearch();
        },
        clickSix(code){
            this.sixCodeIndex = code;
            this.onSearch();
        },
        yearChange(value){
            this.dataYear = value;
            // 数据年限改变，重新获取洲列表和二位编码列表
            this.getContinents();
            this.getTwoCode();
            // 初始化数据
            this.continentIndex = "";
            this.countryIndex = "";
            this.isShowCountry = false;
            this.showCountryNumMax = 10;
            this.twoCodeIndex = "";
            this.fourCodeIndex = "";
            this.sixCodeIndex = "";
            this.isShowFourCode = false;
            this.isShowSixCode = false;
            this.showTwoCodeNumMax = 10;
            this.showFourCodeNumMax = 10;
            this.showSixCodeNumMax = 10;
            // 搜索数据
            this.onSearch();
        },

        //获取搜索结果
        getResult(){
            // 编码整理
            var hsCode = "";
            if(this.sixCodeIndex != ""){
                hsCode = this.sixCodeIndex;
            }else if(this.fourCodeIndex != ""){
                hsCode = this.fourCodeIndex;
            }else{
                hsCode = this.twoCodeIndex;
            }
            //请求接口获取数据
            this.searchLoading = true;
            this.$axios.post(`${this.$clueUrl}/ClickHouse/SearchCustomsData`,{
                "continent": this.continentIndex,
                "country": this.countryIndex,
                "hsCode": hsCode,
                "year": this.dataYear,
                "type": this.searchType,
                "keyword": this.searchValue,
                "pageIndex": this.pageIndex,
                "pageSize": this.pageSize
            }).then((res) => {
                const { isSuccess, data, errorMessage } = res;
                this.searchLoading = false;
                if (isSuccess) {
                    this.resultList = data.datas.map(item=>({
                        ...item,
                        flagUrl: window.getFlagUrlByCountry?window.getFlagUrlByCountry(item.country):'',
                    }))
                    this.totalItems = data.totalItems;
                    if(this.totalItems == 0){
                        this.$message({ message: '搜索无数据,请修改搜索条件重试!',type: 'warning' });
                    }
                }else{
                    if(errorMessage == "请求过于频繁，请输入验证码"){
                        this.openVerify();
                    }
                }
            });
        },
        onSearch(){
            // if(!this.searchValue){
            //     this.$message({ message: '请输入关键字搜索',type: 'warning' });
            //     return false;
            // }
            this.pageIndex = 1;
            this.getResult();
        },
        pageIndexChange(page) {
            this.pageIndex = page;
            if(this.pageIndex > 30){
                this.pageIndex = 30;
                this.$notify({ title: '警告',message: '目前线索模块处于免费试用阶段,搜索页码不能超过30!',type: 'warning' });
                return false;
            }
            this.getResult();
        },
        pageSizeChange() {
            this.pageIndex = 1;
            this.getResult();
        },

        //前往线索详情
        toDetails(row){
            // 验证查看详情次数
            this.$axios.get(`${this.$clueUrl}/SecurityStrategy/CheckDetailsCount`).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    let routeData = this.$router.resolve({
                        path: "/clue/clueDetails",
                        query: { key: row.key, companyName: row.company, clueType: 'customs', year: this.dataYear},
                    });
                    window.open(routeData.href, "_blank");
                }
            });
        },

        //加入我的客户
        addCustomer(item){
            let submitObj = {
                companyKey: JSON.parse(localStorage.userInfo).companyKey,
                userKey: JSON.parse(localStorage.userInfo).uKey,
                name: item.company,
                country: item.country,
                webSite: "",
                call: "",
                address: "",
            }
            this.$axios.post(`${this.$customerUrl}/Common/AddCustomer`, submitObj).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    this.$message({ message: '添加成功', type: 'success'});
                    item.isMyCustomer = true;
                }
            });
        },

        //查看更多(HS编码)(商品描述)
        toMoreCode(item){
            this.moreCodeVisible = true;
            this.viewKey = item.key;
        },
        toMoreDes(item){
            this.moreDesVisible = true;
            this.viewKey = item.key;
        },

        //请求频繁，需要输入验证解锁
        openVerify(){
            this.dialogVisible = true;
            this.$axios.get(`${this.$clueUrl}/SecurityStrategy/GetVerifycode`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.imgKey = data.key;
                    this.imgSrc = data.image;
                }
            });
        },
        finishVerify(){
            this.$axios.get(`${this.$clueUrl}/SecurityStrategy/VerifyVerificationCode?key=${this.imgKey}&code=${this.imgCode}`).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {                  
                    this.dialogVisible = false;
                    this.imgCode = "";
                    this.onSearch();
                }
            });           
        },

    },
    mounted() {
        this.getContinents();
        this.getTwoCode();

        // this.getResult();
    },
};
</script>

<style lang="scss" scoped>
@import '@/scss/common.scss';
.viewbox{
    // background: rgba($color: #FFFFFF, $alpha: 0.7);
    background: #FFFFFF;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    padding: 20px;
    .header{
        display: flex;
        padding-bottom: 20px;
        border-bottom: 1px solid #E1E1E1;
        .logo{
            padding: 0 15px;
        }
        .theme{
            font-size: 20px;
            line-height: 32px;
            font-weight: bold;
            color: #092338;
        }
        .txt{
            font-size: 14px;
            line-height: 20px;
            color: #092338;
        }
    }

    .searchTerms{
        // margin-top: 20px;
        .itemTerm{
            line-height: 26px;
            margin-bottom: 10px;
            overflow: hidden;
            .name{
                float: left;
                width: 65px;
                font-size: 14px;
                color: #092338;
                text-align: right;
                margin-right: 15px;
            }
            .spanList{
                float: left;
                width: calc(100% - 80px);
            }
            .spanItem{
                display: inline-block;
                font-size: 14px;
                color: #092338;
                padding: 0 10px;
                margin-right: 15px;
                cursor: pointer;
            }
            .active{
                color: #FFFFFF;
                background: $themeColor;
                border-radius: 2px;
            }
        }
    }

    .searchResult{
        // margin-top: 20px;
        .title{
            height: 40px;
            line-height: 40px;
            background: #F1F1F1;
            color: #3A3A3A;
            padding-left: 20px;
        }
        .resultList{
            padding: 20px;
            overflow: hidden;
            .resultItem{
                font-size: 14px;
                padding: 10px 0;
                border-bottom: 1px solid #E8E8E8;
                position: relative;
                .name{
                    display: inline-block;
                    color: var(--themeColor);
                    text-decoration: underline;
                    cursor: pointer;
                }
                .country{
                    display: inline-block;
                    margin-left: 20px;
                }
                .color99{    
                    color: #999999;
                }
                .color3a{    
                    color: #3A3A3A;
                }
                .primaryColor{
                    color: var(--themeColor);
                    cursor: pointer;
                }
                .oneField{
                    width: 200px;
                    text-align: center;
                }
                .twoField{
                    line-height: 28px;
                }
                .addBtn{
                    position: absolute;
                    top: 10px;
                    right: 0;
                    padding: 0 10px 0 32px;
                    height: 28px;
                    line-height: 28px;
                    font-size: 14px;
                    color: #FFFFFF;
                    background: var(--themeColor);
                    border-radius: 4px;
                    cursor: pointer;
                    &:hover{
                       opacity: 0.7;
                    }
                    img{
                        position: absolute;
                        left: 10px;
                        top: 5px;
                    }
                }
                .hasAdd{
                    position: absolute;
                    top: 10px;
                    right: 0;
                    padding: 0 10px;
                    height: 28px;
                    line-height: 28px;
                    font-size: 14px;
                    color: #B7B7B7;
                    background: rgba(183, 183, 183, 0.2);
                    border: 1px solid #B7B7B7;
                    border-radius: 4px;
                }
            }
        }
    }
    .noResult{
        overflow: hidden;
        .hintImg{
            float: left;
            width: 800px;
        }
        .noImg{
            float: left;
            margin-left: 80px;
            margin-top: 200px;
            width: 100px;
            height: 100px;
        }
        .hintTxt{
            float: left;
            margin-left: 20px;
            margin-top: 200px;
            font-size: 80px;
            line-height: 100px;
            font-weight: bold;
            color: #DFDFDF;
        }
    }

    /deep/.el-dialog__body {
        padding: 10px 20px;
    }
}
</style>