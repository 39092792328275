<template>
    <div>
        <InterfaceName :interfaceName="interfaceName" />
        <InterfaceSpe :requestUrl="requestUrl" :requestMethod="requestMethod" />
        <InputParam :inputTableData="inputTableData" />
        <ReturnParam :returnObj="returnObj" />   
    </div>
</template>

<script>
import InterfaceName from "../Acomponents/InterfaceName.vue";
import InterfaceSpe from "../Acomponents/InterfaceSpe.vue";
import InputParam from "../Acomponents/InputParam.vue";
import ReturnParam from "../Acomponents/ReturnParam.vue";

export default {
    components: { InterfaceName, InterfaceSpe, InputParam, ReturnParam },
    data() {
        return {
            interfaceName: "获取供应商列表",
            requestUrl: "https://crm.qixinerp.com/open/Procure/Supplier/List",
            requestMethod: "POST",
            inputTableData: [],
            returnObj: {},
        };
    },
    methods: {
        
    },
    mounted() {
        this.inputTableData = [
            {paramName: "manufacturerName", valueType: "string", explain: "采购商名称", required: false},
            {paramName: "pageIndex", valueType: "integer", explain: "当前页码", required: true},
            {paramName: "pageSize", valueType: "integer", explain: "每页条数", required: true},
        ]
        this.returnObj = {
            "isSuccess": true,
            "errorMessage": null,
            "data": {
                "datas": [
                    {
                        "key": "1aca8340d887417e9757e0e54b4303f0",
                        "manufacturerNo": "JCC23042671650",
                        "manufacturerName": "JINHUA COOFIXTOOLS COMPANY",
                        "city": "",
                        "placeOrigin": "",
                        "contactsName": "Zhang San",
                        "contactsPhone": "86-57987237671",
                        "createTime": "2023-04-26 16:25:36"
                    },
                    {
                        "key": "7753e78756e840f1a1ea14a6f95243ea",
                        "manufacturerNo": "23042192170",
                        "manufacturerName": "东莞市力平电子制品有限公司",
                        "city": "",
                        "placeOrigin": "乌兹别克斯坦",
                        "contactsName": "曾太源",
                        "contactsPhone": "13533998466     17873686566",
                        "createTime": "2023-04-21 16:35:21"
                    },
                    {
                        "key": "49defb9184044ea8b7f5bea714a0452c",
                        "manufacturerNo": "23042191916",
                        "manufacturerName": "上海日上车用橡胶件有限公司",
                        "city": "",
                        "placeOrigin": "俄语区国家",
                        "contactsName": "蔡先生",
                        "contactsPhone": "18701810017",
                        "createTime": "2023-04-21 15:59:40"
                    },
                ],
                "pageSize": 10,
                "pageIndex": 1,
                "totalItems": 3
            },
        }
    },
};
</script>

<style lang="scss" scoped>

</style>