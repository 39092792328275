<template>
    <div class="staffSearch">
        <div class="title">员工搜索</div>
        <div class="condition">
            <el-input v-model="searchValue" size="small" prefix-icon="el-icon-search" placeholder="搜索人员" style="width:400px;"></el-input>
            <el-button type="primary" size="small" style="margin-left:10px;" @click="onSearch">查 找</el-button>
            <el-select v-model="staffType" placeholder="请选择" size="small" style="margin-left:10px;width:100px;">
                <el-option label="在职人员" value="在职人员"></el-option>
                <el-option label="离职人员" value="离职人员"></el-option>
            </el-select>
        </div>
        <div class="tableData" v-loading="tableLoading">
            <div class="staffItem" v-for="item in staffList" :key="item.key">
                <el-avatar class="avatarImg" :size="80" src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"></el-avatar>
                <el-row :gutter="24">
                    <el-col :span="8" class="field">
                        <div class="label">姓名：</div>
                        <div class="value">{{item.name}}</div>
                    </el-col>
                    <el-col :span="8" class="field">
                        <div class="label">性别：</div>
                        <div class="value">男</div>
                    </el-col>
                    <el-col :span="8" class="field">
                        <div class="label">生日：</div>
                        <div class="value">1996-10-01</div>
                    </el-col>
                    <el-col :span="8" class="field">
                        <div class="label">邮箱：</div>
                        <div class="value">pxx.peng@lsmaps.com</div>
                    </el-col>
                    <el-col :span="8" class="field">
                        <div class="label">微信：</div>
                        <div class="value">
                            <img src="@/assets/img/wechat.png" alt="" />18186473547
                        </div>
                    </el-col>
                    <el-col :span="8" class="field">
                        <div class="label">电话：</div>
                        <div class="value">
                            <img src="@/assets/img/tel.png" alt="" />18186473547
                        </div>
                    </el-col>
                    <el-col :span="8" class="field">
                        <div class="label">部门：</div>
                        <div class="value">销售部</div>
                    </el-col>
                    <el-col :span="8" class="field">
                        <div class="label">职位：</div>
                        <div class="value">销售</div>
                    </el-col>
                </el-row>
                <div class="operationBtn">
                    <el-button type="primary" plain size="mini">查看</el-button>
                    <el-dropdown size="mini">
                        <el-button type="info" plain size="mini" style="margin-left:10px;">操作</el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item>编辑</el-dropdown-item>
                            <el-dropdown-item>调整部门</el-dropdown-item>
                            <el-dropdown-item>设为负责人</el-dropdown-item>
                            <el-dropdown-item>员工离职</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
            <el-pagination v-show="totalItems>10" background layout="total,prev,pager,next" :total="totalItems" style="float:right;margin:10px 0;" :current-page.sync="pageIndex" @current-change="pageIndexChange"></el-pagination>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            searchValue: "",
            staffType: "在职人员",
            //列表展示
            tableLoading: false,
            staffList: [],
            totalItems: 3,
            pageIndex: 1,
        };
    },
    methods: {
        onSearch() {
            console.log(this.searchValue);
        },
        getStaffList(){
            this.tableLoading = true;
            this.staffList = [
                {key: 1, name: "张三" },
                {key: 2, name: "李四" },
                {key: 3, name: "王五" },
            ]
            setTimeout(() => {
                this.tableLoading = false;
            }, 500);
        },
        pageIndexChange(page){
            this.pageIndex = page;
            this.getStaffList();
        },
    },
    mounted() {
        this.getStaffList();
    },
};
</script>

<style lang="scss" scoped>
.staffSearch {
    background: rgba($color: #ffffff, $alpha: 0.7);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    padding: 10px 20px;
    .title {
        font-size: 24px;
        font-weight: bold;
        color: #2e4756;
        padding-bottom: 12px;
        padding-left: 12px;
        border-bottom: 1px solid #d3dce6;
    }
    .condition {
        margin: 15px 0;
    }
    .tableData{
        .staffItem{
            height: 120px;
            background: #FFFFFF;
            border: 1px solid #EBEBEB;
            margin-bottom: 12px;
            position: relative;
            display: flex;
            .manageImg{
                position: absolute;
                top: 0;
                left: 0;
            }
            .avatarImg{
                margin-left: 40px;
                margin-top: 20px;
            }
            .operationBtn{
                width: 130px;
                margin-top: 40px;
            }
            .el-row{
                margin: 12px 0 0 12px !important;
                width: 75%;
                .field{
                    line-height: 32px;
                    display: flex;
                    font-size: 14px;
                    .label{
                        width: 50px;
                        color: #858585;
                    }
                    .value{
                        flex-grow: 1;
                        color: #092338;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        img{
                            vertical-align: middle;
                            padding-right: 5px;
                        }
                    }
                }
            }
        }
    }
}
</style>