<template>
    <div class="view">
        <el-card class="cardBox" :loading="cardLoading">
            <div class="header">
                <div class="title">应用管理</div>
                
                <el-button type="primary" size="small" style="float:right;margin-right:20px;" @click="toAdd">创建应用</el-button>
            </div>
            <el-row :gutter="24">
                <el-empty v-show="applyList.length==0" :image-size="200"></el-empty>
                <el-col :span="12" v-for="(item,index) in applyList" :key="index">
                    <el-card class="cardItem">
                        <div slot="header">
                            <span class="applyName">{{item.applyName}}</span>
                            <div style="float: right;">
                                <el-button style="padding:3px 0;" type="text" @click="viewDetails(item)">查看详情</el-button>
                                <el-button style="padding:3px 0;color:#f56c6c;" type="text" @click="deleteApply(item)">删除应用</el-button>
                            </div>
                        </div>
                        <div class="content">
                            <div class="field">
                                <div class="label">是否启用:</div>
                                <div class="value">
                                    <el-switch v-model="item.isEnable" @change="setIsEnable(item)"></el-switch>
                                </div>
                            </div>
                            <div class="field">
                                <div class="label">应用代号:</div>
                                <div class="value">{{item.applyCode}}</div>
                            </div>
                            <div class="field">
                                <div class="label">SpecialKey:</div>
                                <div class="value">{{item.specialKey}}</div>
                            </div>
                            <div class="field">
                                <div class="label">SpecialSecret:</div>
                                <div class="value">
                                    <span style="padding-right:20px;">{{item.specialSecret}}</span>
                                    <el-button type="text" @click="resetkey(item)">重置</el-button>
                                </div>
                            </div>
                            <div class="field">
                                <div class="label">剩余点数:</div>
                                <div class="value">{{item.point}}</div>
                            </div>
                            <div class="field">
                                <div class="label">网络钩子:</div>
                                <div class="value">{{item.webhooks || "--"}}</div>
                            </div>
                        </div>
                    </el-card>
                </el-col>
            </el-row>
            <el-pagination v-show="totalItems > 10" background layout="total,prev,pager,next" :total="totalItems" style="margin-top:15px;" :current-page.sync="pageIndex" @current-change="pageIndexChange"></el-pagination>
        </el-card>

        <el-dialog title="创建应用" :visible.sync="addVisible" width="500px">
            <el-form :model="addForm" label-width="100px" size="small">
                <el-form-item label="应用名称" required>
                    <el-input v-model="addForm.applyName"></el-input>
                </el-form-item>
                <el-form-item label="应用代号" required>
                    <el-input v-model="addForm.applyCode"></el-input>
                </el-form-item>
                <el-form-item label="开始日期">
                    <el-date-picker v-model="addForm.startDate" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
                </el-form-item>
                <el-form-item label="结束日期">
                    <el-date-picker v-model="addForm.endDate" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
                </el-form-item>
                <el-form-item label="点数">
                    <el-input-number v-model="addForm.point" :controls="false" :min="0"></el-input-number>
                </el-form-item>
                <el-form-item label="是否启用">
                    <el-switch v-model="addForm.isEnable"></el-switch>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" :loading="btnLoading" @click="addApply">确 认</el-button>
                <el-button size="small" @click="addVisible = false">取 消</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
export default {
    data() {
        return {
            ukey: "",
            //应用列表
            cardLoading: false,
            applyList: [],
            pageIndex: 1,
            totalItems: 0,
            //创建应用
            addVisible: false,
            addForm: {},
            btnLoading: false,
        };
    },
    methods: {
        //获取应用列表
        getApply(){
            this.cardLoading = true;
            this.$axios.post(`${this.$openUrl}/ApplyManage/GetApplyList`, {
                "ukey": this.ukey,
                "pageIndex": this.pageIndex,
                "pageSize": 10
            }).then((res) => {
                this.cardLoading = false;
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.applyList = data.datas;
                    this.totalItems = data.totalItems;
                }
            });
        },
        pageIndexChange(page){
            this.pageIndex = page;
            this.getApply();
        },

        //创建应用
        toAdd(){
            this.addVisible = true;
            this.addForm = {
                "ukey": this.ukey,
                "applyName": "",
                "applyCode": "",
                "startDate": "",
                "endDate": "",
                "point": 0,
                "isEnable": true
            }
        },
        addApply(){
            const {applyName,applyCode} = this.addForm;
            if(!(applyName && applyCode)){
                this.$message({ message: '请确保信息填写完整!', type: 'warning'});
                return false;
            }
            this.btnLoading = true;
            this.$axios.post(`${this.$openUrl}/ApplyManage/CreateApply`,this.addForm).then((res) => {
                this.btnLoading = false;
                this.addVisible = false;
                const { isSuccess } = res;
                if (isSuccess) {
                    this.$message({ message: '创建成功', type: 'success'});
                    this.pageIndexChange(1);
                }
            });
        },
        //设置应用是否启用
        setIsEnable(item){
            this.$axios.get(`${this.$openUrl}/ApplyManage/SetApplyIsEnable?specialKey=${item.specialKey}`).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    this.$message({ message: '设置成功', type: 'success'});
                    this.pageIndexChange(1);
                }
            });
        },
        //重置密钥
        resetkey(item){
            this.$axios.get(`${this.$openUrl}/ApplyManage/Resetkey?specialKey=${item.specialKey}&ukey=${this.ukey}`).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    this.$message({ message: '重置成功', type: 'success'});
                    this.pageIndexChange(1);
                }
            });
        },
        //删除应用
        deleteApply(item){
            this.$confirm(`是否删除该应用：<strong style='color:var(--themeColor);'>${item.applyName}</strong>?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                dangerouslyUseHTMLString: true
            }).then(() => {
                this.$axios.get(`${this.$openUrl}/ApplyManage/DeleteApply?specialKey=${item.specialKey}&ukey=${this.ukey}`).then((res) => {
                    const { isSuccess } = res;
                    if (isSuccess) {
                        this.$message({ message: '删除成功', type: 'success'});
                        this.pageIndexChange(1);
                    }
                });
            }).catch(() => {});
        },
        //查看详情
        viewDetails(item){
            let routeData = this.$router.resolve({
                path: "/appDetails",
                query: { specialKey: item.specialKey },
            });
            window.open(routeData.href, "_blank");
        },

        
    },
    mounted() {
        this.ukey = JSON.parse(localStorage.userInfo).uKey;
        this.getApply();    //获取应用列表
    },
};
</script>

<style lang="scss" scoped>
.view{
    width: 100vw;
    height: 100vh;
    background-color: #fafafa;
    padding: 20px;
    box-sizing: border-box;
    .cardBox{
        width: 1200px;
        margin: auto;
    }
    .header{
        overflow: hidden;
        .title{
            font-weight: bold;
            font-size: 20px;
            float: left;
        }
    }
    .cardItem{
        margin-top: 20px;
        .applyName{
            font-weight: bold;
            font-size: 16px;
        }
        .field{
            display: flex;
            font-size: 16px;
            line-height: 40px;
            .label{
                width: 120px;
                color: #3a3a3a;
                text-align: right;
                margin-right: 30px;
            }
            .value{
                color: #6a6a6a;
            }
        }
    }
}
</style>