<template>
<div v-if="infoVisible">
    <el-dialog title="文件详情" :visible.sync="infoVisible" :width="dialogWidth" @close="$emit('closedialog')">
        <div style="display:flex;" v-loading="infoLoading">
            <div class="previewBox" v-show="infoObj.fileCategory == 'img'">
                <!-- <img style="width:100%;height:100%;" :src="infoObj.fileUrl" /> -->
                <el-image style="width:100%;height:100%;" :src="infoObj.fileUrl" :preview-src-list="[infoObj.fileUrl]"></el-image>
            </div>
            <div class="previewBox" v-show="infoObj.fileCategory == 'video'">
                <video ref="videoDom" v-if="infoObj.isFragmentationExis" class="video-js vjs-default-skin vjs-big-play-centered"
                    controls preload="auto" align="center" :style="{ width: '100%', height: '100%' }">
                    <source :src="infoObj.m3u8Path" />
                </video>
                <div v-else>
                    <el-empty description="视频处理中"></el-empty>
                </div>
            </div>
            <div class="infoBox">
                <div class="infoItem">
                    <div class="label">文件名称</div>
                    <div class="value">
                        <span>{{infoObj.fileName}}</span>
                        <el-tag type="warning" size="mini" effect="plain" v-if="infoObj.fileCategory == 'video' && !infoObj.isFragmentationExis">
                            视频处理中
                        </el-tag>
                    </div>
                </div>
                <div class="infoItem">
                    <div class="label">文件大小</div>
                    <div class="value">{{infoObj.fileSize}}</div>
                </div>
                <!-- <div class="infoItem">
                    <div class="label">网络路径</div>
                    <div class="value">
                        {{infoObj.fileUrl}}
                        <span class="copyUrl" @click="copyUrl(infoObj.fileUrl)">复制路径</span>
                    </div>
                </div> -->
                <div class="infoItem">
                    <div class="label">所属文件夹</div>
                    <div class="value">{{infoObj.folder}}</div>
                </div>
                <div class="infoItem">
                    <div class="label">创建人</div>
                    <div class="value">{{infoObj.createUserName}}</div>
                </div>
                <div class="infoItem">
                    <div class="label">创建时间</div>
                    <div class="value">{{infoObj.createTime}}</div>
                </div>
                <div class="infoItem" v-if="infoObj.updateTime">
                    <div class="label">修改时间</div>
                    <div class="value">{{infoObj.updateTime}}</div>
                </div>
                <div class="footer">
                    <!-- <el-button v-show="!forbidPreview" @click="onPreview" type="primary" plain size="small">预 览</el-button> -->
                    <el-button @click="toSetLink" type="primary" plain size="small" v-if="infoObj.fileCategory == 'img'">外链观看</el-button>
                    <el-button @click="toSetLink" type="primary" plain size="small" v-if="infoObj.fileCategory == 'video' && infoObj.isFragmentationExis">外链观看</el-button>
                    <el-button @click="fileDownload(infoObj)" type="primary" size="small">下 载</el-button>
                    <el-button @click="$emit('closedialog')" size="small">关 闭</el-button>
                </div>
            </div>
        </div>
        <el-dialog width="500px" title="设置有效期和密码" :visible.sync="setVisible" append-to-body>
            <div class="setTitle">设置有效期和密码</div>
            <div class="setBox">
                <el-form label-width="100px" size="small">
                    <el-form-item label="设置有效期">
                        <el-radio-group v-model="setForm.day">
                            <el-radio :label="1">1天</el-radio>
                            <el-radio :label="3">3天</el-radio>
                            <el-radio :label="7">7天</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="设置密码">
                        <el-input placeholder="密码(选填)" v-model="setForm.password"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="setVisible = false" size="small">取 消</el-button>
                <el-button type="primary" @click="shareLink" size="small">生成链接</el-button>
            </div>
        </el-dialog>
    </el-dialog>
</div>
</template>

<script>
import videojs from "video.js";
import "video.js/dist/video-js.css";
export default {
    props: ["infoVisible", "infoKey"],
    data() {
        return {
            infoObj: {},
            infoLoading: false,
            player: null,
            //外链观看(设置有效期和密码)
            setVisible: false,
            setForm:{
                day: 1,
                password: "",
            }
        };
    },
    computed:{
        forbidPreview(){
            return ["zip","exe","other"].includes(this.infoObj.fileCategory);
        },
        dialogWidth(){
            return ["img","video"].includes(this.infoObj.fileCategory) ? "850px" : "450px";
        }
    },
    methods: {
        //获取文件详情
        getFileDetail(){
            this.infoObj = { fileUrl: "" };
            this.infoLoading = true;
            this.$axios.get(`${this.$diskUrl}/File/GetFileDetail?filekey=${this.infoKey}`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.infoLoading = false;
                    this.infoObj = data;
                    this.infoObj.createTime = this.infoObj.createTime.slice(0,10);
                    // console.log(this.infoObj);
                    if(this.infoObj.fileCategory == 'video' && this.infoObj.isFragmentationExis){
                        this.$nextTick(() => {
                            this.player = videojs(this.$refs.videoDom);
                        });
                    }
                }
            })
        },
        // 复制url路径
        copyUrl(fileUrl){
            const input = document.createElement('input');
            document.body.appendChild(input);
            input.setAttribute('value', fileUrl);
            input.select();
            if (document.execCommand('copy')) {
                document.execCommand('copy');
            }
            document.body.removeChild(input);
            this.$message({message: '已复制该链接',type: 'success'});
        },
        //文件下载
        fileDownload(item){
            const link = document.createElement('a');
            link.href = item.fileUrl;
            link.download = item.fileName;
            link.target = '_blank';
            (document.body || document.documentElement).appendChild(link);
            link.click();
            link.remove();
            this.recordDownload();
        },
        //下载记录
        recordDownload(){
            this.$axios.get(`${this.$diskUrl}/File/Download?filekey=${this.infoKey}`);
        },
        //预览文件
        // onPreview(){
        //     if(["html","pdf","txt","audio"].includes(this.infoObj.fileCategory)){
        //         window.open(this.infoObj.fileUrl);
        //     }else{
        //         const { fileCategory, fileUrl, m3u8Path, isFragmentationExis } = this.infoObj;
        //         if(fileCategory == 'video' && !isFragmentationExis){
        //             this.$message({message: '视频处理中,请稍后再试!',type: 'warning'});
        //             return false;
        //         }
        //         // console.log(fileCategory, fileUrl, m3u8Path);
        //         let routeData = this.$router.resolve({
        //             path: "/filePreview",
        //             query: { fileCategory, fileUrl, m3u8Path },
        //         });
        //         window.open(routeData.href, "_blank");
        //     }
        // },

        toSetLink(){
            this.setVisible = true;
            this.setForm = {
                day: 1,
                password: "",
            }
        },
        shareLink(){
            const {day,password} = this.setForm;
            this.$axios.get(`${this.$diskUrl}/File/GetShareLink?fileKey=${this.infoKey}&day=${day}&pwd=${password.trim()}`).then((res) => {
                const { isSuccess, data } = res;
                this.setVisible = false;
                if (isSuccess) {
                    this.copyUrl(data);
                }
            })
        },
        
    },
    mounted() {
        // this.getFileDetail();
    },
    watch:{
        //文件key改变时，重新获取文件详情
        infoKey(){
            this.getFileDetail();
        },
        infoVisible(value){
            if(value){
                if(this.infoObj.fileCategory == 'video' && this.infoObj.isFragmentationExis){
                    this.$nextTick(() => {
                        this.player = videojs(this.$refs.videoDom);
                    });
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
/deep/.el-dialog__header{
    display: none;
}
/deep/.el-dialog__body {
    padding: 0;
}
.previewBox{
    width: 400px;
    height: 400px;
}
.infoBox{
    width: 450px;
    height: 400px;
    padding: 20px 20px 20px 0;
    box-sizing: border-box;
    position: relative;
    .footer{
        display: flex;
        justify-content: flex-end;
        position: absolute;
        right: 20px;
        bottom: 20px;
    }
    .infoItem{
        line-height: 32px;
        margin-bottom: 10px;
        display: flex;
        .label{
            width: 80px;
            text-align: right;
            color: #9a9a9a;
            font-weight: bold;
        }
        .value{
            width: calc(100% - 100px);
            margin-left: 20px;
            color: #000;
            .copyUrl{
                color: var(--themeColor);
                cursor: pointer;
                padding-left: 10px;
            }
        }
    }
}
.setTitle{
    font-size: 16px;
    font-weight: bold;
    line-height: 40px;
    padding: 0 20px;
    color: #3a3a3a;
}
.setBox{
    padding: 20px;
}
</style>