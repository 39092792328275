<template>
    <div v-loading="infoLoading">
        <div class="w100" v-if="tipMsg">
            <div style="font-size:44px;color:#6a6a6a;padding-top:200px;">{{ tipMsg }}</div>
        </div>
        <div v-else>
            <div class="w100" v-if="isVerifyPwd">
                <div style="padding-top: 200px;width:200px;margin: auto;">
                    <div style="color: #6a6a6a;">请输入密码验证</div>
                    <el-input v-model="inputPwd" size="small" style="margin: 15px 0;" show-password></el-input>
                    <el-button type="primary" size="small" :disabled="!inputPwd.trim()" style="width: 200px;" @click="verifySharePwd">提 交</el-button>
                </div>
            </div>
            <div v-else>
                <div class="contentBox">
                    <div class="topHeader">
                        <img v-if="BreadcrumbList.length > 1" src="@/assets/doc/goBack.png" alt="" class="goBack" @click="goBack" />
                        <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb">
                            <el-breadcrumb-item v-for="(item,index) in BreadcrumbList" :key="item.key">
                                <span @click="goJump(index)" class="spanItem">{{item.name}}</span>
                            </el-breadcrumb-item>
                        </el-breadcrumb>
                    </div>
                    <div class="tableHeader">
                        <div class="name">文件夹名/文件名</div>
                        <div class="size">大小</div>
                        <!-- <div class="creator">创建人</div> -->
                        <!-- <div class="time">创建时间</div> -->
                    </div>
                    <div class="tableBody" v-loading="tableLoading">
                        <div v-if="currentFolderList.length == 0 && FileList.length == 0">
                            <el-empty description="暂无数据"></el-empty>
                        </div>
                        <!-- 文件夹列表 -->
                        <div class="FolderItem" v-for="item in currentFolderList" :key="item.key">
                            <div class="name">
                                <img class="imgIcon" src="@/assets/doc/folderIcon.png" alt="" />
                                <span class="pointer" @click="getFolderContent(item)">{{item.folderName}}</span>
                                <el-tooltip effect="dark" content="属性" placement="top">
                                    <i class="el-icon-info" style="margin-left: 5px;cursor: pointer;" @click="viewProperty(item)"></i>
                                </el-tooltip>
                            </div>
                            <div class="size"></div>
                            <!-- <div class="creator">{{item.userCnName}}</div> -->
                            <!-- <div class="time">{{item.timeExhibition}}</div> -->
                        </div>
                        <!-- 文件列表 -->
                        <div class="FileItem" v-for="item in FileList" :key="item.key" >
                            <div class="name">
                                <img class="imgIcon fileIcon" :src="item.fileIcon" alt="" />
                                <span class="pointer" @click="viewFileDetails(item)">{{item.remarkName}}</span>
                            </div>
                            <div class="size">{{item.fileSize}}</div>
                            <!-- <div class="creator">{{item.userCnName}}</div> -->
                            <!-- <div class="time">{{item.timeExhibition}}</div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 文件详情 -->
        <FileInfo :infoVisible="infoVisible" :infoKey="infoKey" @closedialog="infoVisible = false;" />

        <!-- 文件夹属性 -->
        <FolderInfo :propertyVisible="propertyVisible" :propertyLoading="propertyLoading" :propertyInfo="propertyInfo" @closedialog="propertyVisible = false;" />
    </div>
</template>

<script>
import FileInfo from "./openCom/OpenFileInfo.vue"
import FolderInfo from "./openCom/OpenFolderInfo.vue"
export default {
    components: { FileInfo, FolderInfo },
    data() {
        return {
            uParam: "",
            infoLoading: false,
            tipMsg: "加载中...",
            isVerifyPwd: true,
            inputPwd: "",
            //文件夹、文件列表
            BreadcrumbList:[{name: '全部文件',key: "0"}],
            tableLoading: false,
            originFolderList: [],
            currentFolderList: [],
            FileList: [],
            //文件详情
            infoVisible: false,
            infoKey: "",
            //文件夹属性
            propertyVisible: false,
            propertyLoading: false,
            propertyInfo: {},
        };
    },
    methods: {
        getShareInfo(){
            this.infoLoading = true;
            this.$axios.get(`${this.$diskUrl}/Open/GetShareFolderInfo?u=${this.uParam}`).then((res) => {
                const { isSuccess, data } = res;
                this.infoLoading = false;
                if(isSuccess){
                    const { isVerifyPwd, msg, folderKey, folderName } = data;
                    if (msg) {
                        this.tipMsg = msg;
                    }else{
                        this.tipMsg = "";
                        this.isVerifyPwd = isVerifyPwd;
                        this.originFolderList = [{key: folderKey, folderName: folderName}];
                        this.currentFolderList = this.originFolderList;
                    }
                }
            })
        },
        verifySharePwd(){
            this.$axios.get(`${this.$diskUrl}/Open/VerifySharePwd?u=${this.uParam}&pwd=${this.inputPwd}`).then((res) => {
                const { isSuccess } = res;
                if(isSuccess){
                    this.isVerifyPwd = false;
                }            
            })
        },

        //获取文件夹内容
        getFolderContent(item){
            this.BreadcrumbList.push({key: item.key, name: item.folderName,});
            this.currentFolderKey = item.key;
            this.getList(this.currentFolderKey);
        },
        //返回上一级文件夹
        goBack(){
            const BreadcrumbList = this.BreadcrumbList;
            if(BreadcrumbList.length == 1){
                return false;
            }
            BreadcrumbList.pop();
            this.BreadcrumbList = BreadcrumbList;
            this.currentFolderKey = BreadcrumbList[BreadcrumbList.length-1].key;
            this.getList(this.currentFolderKey);
        },
        //跳转指定文件夹
        goJump(index){
            const BreadcrumbList = this.BreadcrumbList;
            if(BreadcrumbList.length == 1){
                return false;
            }
            BreadcrumbList.splice(index + 1,BreadcrumbList.length);
            this.BreadcrumbList = BreadcrumbList;
            this.currentFolderKey = BreadcrumbList[BreadcrumbList.length-1].key;
            this.getList(this.currentFolderKey);
        },
        getList(folderKey){
            if(folderKey == "0"){
                this.currentFolderList = this.originFolderList;
                this.FileList = [];
                return false;
            }
            this.tableLoading = true;
            this.$axios.get(`${this.$diskUrl}/Open/GetShareFolderContent?folderKey=${folderKey}`).then((res) => {
                const { isSuccess, data } = res;
                this.tableLoading = false;
                if(isSuccess){
                    this.currentFolderList = data.folders;
                    data.files.forEach(item =>{
                        item.fileIcon = require(`@/assets/doc/file_${item.fileCategory}.png`)
                    })
                    this.FileList = data.files;
                }            
            })
        },

        //查看文件详情
        viewFileDetails(item){ 
            this.infoVisible = true;
            this.infoKey = item.key;
        },
        //查看文件夹属性
        viewProperty(item){
            this.propertyVisible = true;
            this.propertyLoading = true;
            this.$axios.get(`${this.$diskUrl}/Open/GetFolderDetail?folderKey=${item.key}`).then(res =>{
                const {isSuccess, data} = res;
                this.propertyLoading = false;
                if(isSuccess){
                    this.propertyInfo = {
                        ...data,
                        location: this.BreadcrumbList.map(item => item.name).join('\\')
                    }
                }
            })
        },

    },
    mounted() {
        this.uParam = this.$route.query.u;
        this.getShareInfo();
    },
};
</script>

<style lang="scss" scoped>
.w100{
    width: 100vw;
    height: 100vh;
    text-align: center;
}
.contentBox{
    margin: 20px auto;
    width: 1000px;
    height: calc(100vh - 40px);
    background: #FFFFFF;
    box-shadow: 0px 1px 6px 1px rgba(0, 0, 0, 0.16);
    .topHeader{
        height: 45px;
        line-height: 45px;
        background: #FBFBFB;
        padding: 0 15px 0 30px;
        position: relative;
        display: flex;
        justify-content: space-between;
        .goBack{
            position: absolute;
            top: 0;
            bottom: 0; 
            left: 30px;
            margin: auto;
            cursor: pointer;
        }
        .breadcrumb{
            margin-left: 50px;
            display: inline-block;
            line-height: 45px;
            .spanItem{
                cursor: pointer;
                font-size: 16px;
                font-weight: bold;
                color: #3A3A3A;
            }
            /deep/.el-breadcrumb__separator[class*=icon] {
                margin: 0 10px;
                font-size: 16px;
                font-weight: bold;
                color: #9C9C9C;
            }
        }
    }
    .tableHeader{
        height: 45px;
        line-height: 45px;
        background: #F3F3F3;
        font-size: 16px;
        font-weight: bold;
        color: #3A3A3A;
        display: flex;
        padding: 0 20px;
    }
    .tableBody{
        padding-bottom: 10px;
        height: calc(100vh - 140px);
        overflow: overlay;
        .FolderItem,.FileItem{
            padding: 0 10px;
            display: flex;
            height: 50px;
            line-height: 50px;
            border-bottom: 1px solid #EEEEEE;
            font-size: 14px;
            color: #1A2745;
            .imgIcon{
                vertical-align: middle;
                padding: 0 20px 0 40px;
            }
        } 
    }
    .tableHeader,.FolderItem,.FileItem{
        position: relative;
        .name{
            width: calc(100% - 150px);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .size{
            width: 130px;
            padding: 0 10px;
            text-align: center;
        }
        // .creator{
        //     width: 130px;
        //     padding: 0 10px;
        //     text-align: center;
        // }
        // .time{
        //     width: 180px;
        //     padding: 0 10px;
        //     text-align: center;
        // }
    }
}
</style>