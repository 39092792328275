<template>
    <div v-loading="loading">
        <el-tabs type="border-card" v-if="socialMediaList.length > 0">
            <el-tab-pane v-for="(item,index) in socialMediaList" :key="index">
                <span slot="label">
                    <img class="iconImg" :src="item.iconImg" alt="">
                    <span>{{item.source}}</span> 
                </span>
                <div class="infoItem" v-for="(ele,i) in item.socialMedias.slice(0,5)" :key="i">
                    <div class="companyName">{{ ele.company || "--"}}</div>
                    <el-row :gutter="24">
                        <el-col :span="8" class="fieldItem">
                            <span class="color99">公司名称：</span>
                            <span class="color3a">{{ ele.company || "--" }}</span>
                        </el-col>
                        <el-col :span="16" class="fieldItem">
                            <span class="color99">网址：</span>
                            <span v-if="ele.website" class="primaryColor" @click="openWebsite(ele.website)">{{ele.website}}</span>
                            <span v-else class="color3a">--</span>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="8" class="fieldItem">
                            <span class="color99">电话：</span>
                            <span class="color3a">{{ ele.phone || "--" }}</span>
                        </el-col>
                        <el-col :span="8" class="fieldItem">
                            <span class="color99">星级：</span>
                            <span class="color3a">{{ ele.star || "--" }}</span>
                        </el-col>
                        <el-col :span="8" class="fieldItem">
                            <span class="color99">营业时间：</span>
                            <span class="color3a">{{ ele.businessTime || "--" }}</span>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="24" class="fieldItem">
                            <span class="color99">地址：</span>
                            <span class="color3a">{{ ele.address || "--" }}</span>
                        </el-col>
                        <el-col :span="24" class="fieldItem">
                            <span class="color99">地区：</span>
                            <span class="color3a">{{ ele.area || "--" }}</span>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="16" class="fieldItem">
                            <span class="color99">链接：</span>
                            <span v-if="ele.pageUrl" class="primaryColor" @click="openWebsite(ele.pageUrl)">{{ele.pageUrl}}</span>
                            <span v-else class="color3a">--</span>
                        </el-col>
                        <el-col :span="8" class="fieldItem">
                            <span class="color99">行业：</span>
                            <span class="color3a">{{ ele.industry || "--" }}</span>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="24" class="fieldItem">
                            <div v-for="element in ele.imgs" :key="element" style="display: inline-block;margin-right:10px;">
                                <el-image style="width: 100px; height: 100px" :src="element" :preview-src-list="ele.imgs"></el-image>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </el-tab-pane>
        </el-tabs>
        <el-empty description="暂无社媒信息" v-else></el-empty>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            myClueKey: "",
            socialMediaList: [],
        };
    },
    methods: {
        openWebsite(url){
            let jumpUrl = url.indexOf("http") > -1 ? url : "http://" + url;
            let routeData = this.$router.resolve({
                path: "/jumpWeb",
                query: { jumpUrl: jumpUrl },
            });
            window.open(routeData.href, "_blank");
        },

        //获取社媒信息
        getSocialMedia(){
            this.loading = true;
            this.$axios.get(`${this.$clueUrl}/MyClue/v2/GetSocialMedia?myClueKey=${this.myClueKey}`).then((res) => {
                const { isSuccess, data } = res;
                this.loading = false;
                if (isSuccess) {
                    let iconList = ['facebook','googlemap','yellowpages','yelp','twitter','linkedin'];
                    this.socialMediaList = data.map(item => ({
                        ...item,
                        iconImg: iconList.includes(item.source) ? require(`@/assets/cluePicture/${item.source}.png`) : "",
                    }));
                    // console.log(this.socialMediaList);
                }
            });
        },
    },
    mounted() {
        this.myClueKey = this.$route.query.myClueKey;
        // 获取社媒信息
        this.getSocialMedia();
    },
};
</script>

<style lang="scss" scoped>
.infoItem{
    font-size: 14px;
    border-top: 1px solid #dcdcdc;
    padding: 10px;
    &:first-child{
        border: none;
    }
}
.iconImg{
    vertical-align: middle;
    padding-right: 10px;
}
.companyName{
    font-size: 24px;
    color: var(--themeColor);
    margin-bottom: 10px;
}
.fieldItem {
    line-height: 28px;
    margin-bottom: 5px;
}
.color99 {
    color: #999999;
}
.color3a {
    color: #3A3A3A;
}
.primaryColor {
    color: var(--themeColor);
    cursor: pointer;
}
</style>