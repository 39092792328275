<template>
<div>
    <div v-if="ie=='i'">
        <!-- 哈萨克斯坦(KAZ)进口提单明细 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th width="25%">报关单号</th>
                        <td width="25%">{{billInfo.DeclarationNumber}}</td>
                        <th width="25%">进口商ID</th>
                        <td width="25%">{{billInfo.ImporterId}}</td>
                    </tr>
                    <tr>
                        <th>数量单位</th>
                        <td>{{billInfo.ExtraUnit}}</td>
                        <th>汇率</th>
                        <td>{{billInfo.ExchangeRate}}</td>
                    </tr>
                    <tr>
                        <th>净重</th>
                        <td>{{billInfo.Weight ? billInfo.Weight+'kg':''}}</td>
                        <th>毛重</th>
                        <td>{{billInfo.GrossWeight ? billInfo.GrossWeight+'kg':''}}</td>
                    </tr>
                    <tr>
                        <th>金额（美元）</th>
                        <td>{{billInfo.CargoValue ? billInfo.CargoValue+'USD':''}}</td>
                        <th>金额（坚戈）</th>
                        <td>{{billInfo.Value ? billInfo.Value+'Tenge':''}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">运输信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">贸易国</th>
                        <td width="25%">{{billInfo.TradeCountry}}</td>
                        <th width="25%">起运国</th>
                        <td width="25%">{{billInfo.FromCountry}}</td>
                    </tr>
                    <tr>
                        <th>来源国</th>
                        <td>{{billInfo.Country}}</td>
                        <th>目的国</th>
                        <td>{{billInfo.DestinationCountry}}</td>
                    </tr>
                    <tr>
                        <th>运输方式编号</th>
                        <td>{{billInfo.TransType}}</td>
                        <th>成交方式</th>
                        <td>{{billInfo.Incoterms}}</td>
                    </tr>
                    <tr>
                        <th>进出口类型</th>
                        <td>{{billInfo.Type}}</td>
                        <th>海关港口</th>
                        <td>{{billInfo.CustomerCode}}</td>
                    </tr>
                    <tr>
                        <th>海关系统代码</th>
                        <td>{{billInfo.CustomProcedureCode}}</td>
                        <th>跨境类型编号</th>
                        <td>{{billInfo.TransTypeCrossBorder}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
    <div v-if="ie=='e'">
        <!-- 哈萨克斯坦(KAZ)出口提单明细 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th width="25%">报关单号</th>
                        <td width="25%">{{billInfo.DeclarationNumber}}</td>
                        <th width="25%">进口商ID</th>
                        <td width="25%">{{billInfo.ExporterId}}</td>
                    </tr>
                    <tr>
                        <th>数量单位</th>
                        <td>{{billInfo.ExtraUnit}}</td>
                        <th>汇率</th>
                        <td>{{billInfo.ExchangeRate}}</td>
                    </tr>
                    <tr>
                        <th>净重</th>
                        <td>{{billInfo.Weight ? billInfo.Weight+'kg':''}}</td>
                        <th>毛重</th>
                        <td>{{billInfo.GrossWeight ? billInfo.GrossWeight+'kg':''}}</td>
                    </tr>
                    <tr>
                        <th>金额（美元）</th>
                        <td>{{billInfo.CargoValue ? billInfo.CargoValue+'USD':''}}</td>
                        <th>金额（坚戈）</th>
                        <td>{{billInfo.Value ? billInfo.Value+'Tenge':''}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">运输信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">贸易国</th>
                        <td width="25%">{{billInfo.TradeCountry}}</td>
                        <th width="25%">起运国</th>
                        <td width="25%">{{billInfo.FromCountry}}</td>
                    </tr>
                    <tr>
                        <th>来源国</th>
                        <td>{{billInfo.OriginCountry}}</td>
                        <th>目的国</th>
                        <td>{{billInfo.Country}}</td>
                    </tr>
                    <tr>
                        <th>运输方式编号</th>
                        <td>{{billInfo.TransType}}</td>
                        <th>成交方式</th>
                        <td>{{billInfo.Incoterms}}</td>
                    </tr>
                    <tr>
                        <th>进出口类型</th>
                        <td>{{billInfo.Type}}</td>
                        <th>海关港口</th>
                        <td>{{billInfo.CustomerCode}}</td>
                    </tr>
                    <tr>
                        <th>海关系统代码</th>
                        <td>{{billInfo.CustomProcedureCode}}</td>
                        <th>跨境类型编号</th>
                        <td>{{billInfo.TransTypeCrossBorder}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
</div>
</template>

<script>
export default {
    props:["billInfo","ie"],
    data() {
        return {

        };
    },
    methods: {
        
    },
    mounted() {

    },
};
</script>

<style lang="scss" scoped>
.partItem {
    margin-bottom: 20px;
    /deep/.el-card__header {
        padding: 10px 20px;
        .headTitle {
            display: inline-block;
            padding-left: 10px;
            font-size: 14px;
            border-left: 2px solid var(--themeColor);
            font-weight: bold;
        }
    }
}
table {
    width: 100%;
    border-collapse: collapse;
    tr {
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;
        th,
        td {
            font-size: 12px;
            color: #666;
            height: 40px;
            border: 1px solid #eaeaea;
            text-align: center;
        }
        th {
            background-color: #f5f7fa;
            font-weight: 700;
        }
        .bg {
            background-color: var(--themeColor);
            color: #fff;
            font-weight: 700;
            opacity: 0.8;
        }
    }
}
</style>