<template>
  <div class="enterprise-info" id="enterprise-info-basic-info-QYGK">
    <div class="header">
      <div class="information">
        <div class="img-wrapper" ref="qiyegaikuang">
          <img src="@/firm/images/company/image-company.png" class="image" />
        </div>
        <div class="info-wrapper">
          <div class="item" style="display: flex; justify-content: space-between; align-items: center">
            <div style="display: flex; align-items: center" @mouseenter="showCountryEdit = true" @mouseleave="showCountryEdit = false">
              <span @click="onCopy(information.name)" style="font-weight: bold; font-size: 20px; cursor: pointer">{{ information.name }}</span>
              <img :src="information.flagSrc" style="margin-left: 12px; height: 14px" />
              <span style="margin-left:6px">{{ information.country }}</span>
              <div style="display: flex; align-items: center">
                <el-tooltip effect="dark" content="Facebook">
                  <img src="@/firm/images/company/icon-facebook.png" style="margin-right: 0.5em" @click="navToFacebook" class="cursor-pointer" />
                </el-tooltip>
                <el-tooltip effect="dark" content="Twitter">
                  <img src="@/firm/images/company/icon-twitter.png" style="margin-right: 0.5em" @click="navToTwitter" class="cursor-pointer" />
                </el-tooltip>
                <el-tooltip effect="dark" content="Bing 网络搜索公司信息">
                  <img src="@/firm/images/company/icon-bing.png" style="margin-right: 0.5em" @click="navToBing" class="cursor-pointer" />
                </el-tooltip>
                <!-- <el-tooltip effect="dark" content="外贸邮搜索该公司邮箱" v-if="waimaoyouwebsite">
                  <img src="@/firm/images/company/icon-mail.png" style="margin-right: 0.5em" @click="navToMail" class="cursor-pointer" />
                </el-tooltip> -->
                <span v-if="false" style="font-size: 12px; color: #909090">数据更新：{{ information.updateDate }}</span>
              </div>
              <span @click="handleCountryFlagClick" v-show="showCountryEdit" style="margin-left:12px;cursor:pointer;color:var(--themeColor, #3152AF)">修改企业所属国</span>
              <span v-if="false" @click="linkc.dialog = true" style="margin-left: 10px;color: var(--themeColor, #3152AF);cursor: pointer;font-size: small;">商业数据已购买的公司</span>
              <el-dialog custom-class="ergheoghro-23r32oiro2-ggj3i4" title="关联商业数据我已购买公司" width="70%" :close-on-click-modal="false" :visible.sync="linkc.dialog">
                <div v-loading="linkc.loading">
                  <div v-if="linkc.permisson">
                    <el-form size="mini" inline>
                      <el-form-item label="国家：">
                        <el-select v-model="linkc.optionc">
                          <el-option v-for="item of linkc.country" :key="item" :label="item" :value="item" />
                        </el-select>
                      </el-form-item>
                      <el-form-item label="公司名：">
                        <el-input v-model="linkc.otincn" />
                      </el-form-item>
                      <el-form-item>
                        <el-button type="primary" @click="getLinkCDialogData({ index: 1 })">搜索</el-button>
                      </el-form-item>
                    </el-form>
                    <el-table :data="linkc.data" size="mini" height="45vh">
                      <el-table-column show-overflow-tooltip prop="companyName" label="公司名">
                      </el-table-column>
                      <el-table-column show-overflow-tooltip prop="country" label="国家" />
                      <el-table-column show-overflow-tooltip label="网址">
                        <template slot-scope="{row}">
                          <span style="cursor: pointer" @click="interceptLink(row.website)">{{ row.website }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column show-overflow-tooltip label="来源">
                        <template slot-scope="{row}">
                          <span v-if="row.type === 'InfobelInfo'">商业数据</span>
                          <span v-else>企业资信</span>
                        </template>
                      </el-table-column>
                      <el-table-column show-overflow-tooltip label="切换公司绑定的公司信息？">
                        <template slot-scope="{row}">
                          <el-button @click="refreshhhh(row.searchKey)" type="primary" size="mini">确定</el-button>
                        </template>
                      </el-table-column>
                    </el-table>
                    <el-pagination @current-change="getLinkCDialogData({})" background layout="prev, pager, next" :current-page.sync="linkc.page.index" :page-size="linkc.page.size" :total="linkc.total" />
                  </div>
                  <el-empty v-else description="无商业数据权限" />
                </div>
              </el-dialog>
            </div>
            <el-button :loading="adddddddddd.l" v-if="adddddddddd.v" @click="addddddd" type="primary" size="mini">加入我的线索</el-button>
            <el-button v-else size="mini" disabled>已加入我的线索</el-button>
          </div>
          <JoinCustomer :joinDialogVisible="joinDialogVisible" :clickItem="clickItem" @closeDialog="joinDialogVisible = false" @onFinish="onFinish" />
          <div class="item" style="font-size: 14px; display: flex; align-items: center">
            <span>公司官网：</span>
            <span v-for="item of gongsiwangzhi" :key="item" @click="navToWeb(item)" class="cursor-pointer" style="margin-right: 5px;color:var(--themeColor, #3152AF)">{{ item }}；</span>
            <span v-if="false" @click="showEidtNetDialog = true; editNetAddress = gongsiwangzhi.join(';')" style="color: var(--themeColor, #3152AF); font-size: 12px; cursor: pointer">
              <i class="el-icon-edit"></i>编辑
            </span>
          </div>
          <div class="item" style="font-size: 14px; display: flex; align-items: center">
            <span style="margin-left: 2em">标签：</span>
            <add-tag v-if="information.name" :company="information.name" />
          </div>
          <div class="item" style="border-top: 2px dashed #d7d7d8"></div>
          <div v-if="false" class="item" style="font-size: 14px; display: flex">
            <span style="flex-shrink: 0">相似公司({{ information.similar.length }})：</span>
            <div style="font-size: 14px; display: flex; align-items: center; flex-wrap: wrap">
              <span class="tag active" style="margin-left: 0.5em; margin-bottom: 0.5em" v-for="(item, index) of information.similar.slice(0, 5)" :key="index">{{ item }}</span>
              <span v-show="combaineCompany.showAll" class="tag active" style="margin-left: 0.5em; margin-bottom: 0.5em" v-for="(item, index) of information.similar.slice(5)" :key="index">{{ item }}</span>
              <span v-if="information.similar.length > 5" @click="combaineCompany.showAll = !combaineCompany.showAll" class="tag active border cursor-pointer" style="margin-left: 0.5em; margin-bottom: 0.5em">{{ combaineCompany.showAll ? "折叠" : "展开更多" }}</span>
              <span class="tag active border cursor-pointer" style="margin-left: 0.5em; margin-bottom: 0.5em" @click="ccc">合并公司</span>
            </div>
          </div>
        </div>
      </div>
      <div class="image-container" v-show="information.images.length !== 0">
        <el-image :preview-src-list="information.images" v-for="(item, index) of information.images" :key="index" :src="item" style="margin-right: 1rem; height: 158px" />
      </div>
      <div class="notify" v-if="updateInfo.text && updateInfo.date">
        <img src="@/firm/images/company/new/dongtai.png" style="margin: 0 23px 0 20px" />
        <div class="text-a">
          <span>{{ updateInfo.text }}</span>
          <span style="margin-left: 14px">{{ updateInfo.date }}</span>
        </div>
      </div>
    </div>
    <basic-info-domestic v-if="region === 'domestic'" :company="information.name" :MPsetInformation="setInformation" />
    <basic-info-abroad v-if="region === 'abroad'" :company="information.name" :MPsetInformation="setInformation" />
    <el-dialog :visible="showEidtNetDialog" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
      <div v-loading="editNetAddressLoading">
        <el-input v-model="editNetAddress" placeholder="请输入公司网址，多个网址用;（英文分号）分隔" size="small" />
        <div style="display: flex; justify-content: flex-end; margin-top: 20px">
          <el-button @click="showEidtNetDialog = false" size="mini">取 消</el-button>
          <el-button type="primary" @click="modifyNetAddress" size="mini">确 定</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="提示" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" :visible="showDiaglog">
      <p>当前页面已经过期</p>
      <p>关闭当前页面，从企业资信页面重新查看</p>
    </el-dialog>
    <combaine-company v-if="combaineCompany.render" :company="information.name" @destroy="combaineCompany.render = false" />
    <el-dialog title="修改企业所属国家" width="360px" :visible.sync="showCountryDialog" v-loading="countryDialogLoading">
      <p style="color: #f56c6c; margin-bottom: 1em">提示：企业所属国家仅可修改一次！</p>
      <span>国家：</span>
      <el-select :filterable="true" v-model="sourceCountryValue" v-if="sourceCountry.length !== 0" size="mini">
        <el-option v-for="item of sourceCountry" :key="item.twoCode" :label="item.countryCn" :value="[item.countryCn, item.twoCode]" />
      </el-select>
      <div style="display: flex; justify-content: flex-end; margin-top: 0.5em">
        <el-popconfirm title="提示：企业所属国家仅可修改一次！" icon-color="red" @confirm="handlesourceCountryChangeFunc">
          <el-button slot="reference" size="mini" type="primary">确认</el-button>
        </el-popconfirm>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapMutations } from "vuex";
import axios from "@/firm/plugins/axios";
import dayjs from "@/firm/plugins/dayjs";
import { AnchorInditor } from "../../utils";
import { Message } from "element-ui";

import combaineCompany from "../../components/combaine-company.vue";

import addTag from "../../components/add-tag.vue";
import basicInfoDomestic from "./basic-info-domestic.vue";
import basicInfoAbroad from "./basic-info-abroad.vue";
import { interceptLink } from "@/firm/utils/link";

import JoinCustomer from "@/views/ClueManage/components/JoinCustomer.vue"

export default Vue.extend({
  components: {
    JoinCustomer,
    addTag,
    combaineCompany,
    basicInfoDomestic,
    basicInfoAbroad,
  },
  computed: mapState(["region", "companyType"]),
  data() {
    return {
      clickItem: {},
      joinDialogVisible: false,
      linkc: {
        loading: false,
        dialog: false,
        optionc: '不限',
        otincn: '',
        country: [],
        data: [],
        total: 0,
        page: {
          index: 1,
          size: 10
        },
        permisson: false
      },
      gongsiwangzhi: [],
      showCountryEdit: false,
      showCountryDialog: false,
      countryDialogLoading: false,
      sourceCountry: [],
      sourceCountryValue: [],
      combaineCompany: {
        render: false,
        showAll: false,
      },
      showEidtNetDialog: false,
      editNetAddress: "",
      editNetAddressLoading: false,
      showDiaglog: false,
      information: {
        name: "", //"SHREE ENTERPRISES", //企业名
        updateDate: "", //"2020-09-5 17:00", //数据更新时间
        flagSrc: "", //"https://vip.lsmaps.com/context/image/world-icon/tw.jpg", //国旗url
        country: "", //"the Republic of China", //国家
        //type: "", //公司类型  'Importer'|'Exporter'
        netaddress: "", //"https://shree-enterprises.com", //公司网址
        similar: [], //["SHREE ENTERPRISES", "SHREE ENTERPRISES", "SHREE ENTERPRISES", "SHREE ENTERPRISES", "SHREE ENTERPRISES", "SHREE ENTERPRISES", "SHREE ENTERPRISES"], //相似公司
        images: [], //["http://img.mp.itc.cn/upload/20170714/cf0669348a3b49fd801a49ed9a24e33a_th.jpg", "http://img.mp.itc.cn/upload/20170714/cf0669348a3b49fd801a49ed9a24e33a_th.jpg", "http://img.mp.itc.cn/upload/20170714/cf0669348a3b49fd801a49ed9a24e33a_th.jpg","http://img.mp.itc.cn/upload/20170714/cf0669348a3b49fd801a49ed9a24e33a_th.jpg","http://img.mp.itc.cn/upload/20170714/cf0669348a3b49fd801a49ed9a24e33a_th.jpg","http://img.mp.itc.cn/upload/20170714/cf0669348a3b49fd801a49ed9a24e33a_th.jpg"], //公司图片url
      },
      updateInfo: {
        text: "",
        date: "",
      },
      waimaoyouwebsite: "",
      adddddddddd: {
        l: false,
        v: false
      }
    };
  },
  watch: {
    'information.netaddress'() {
      this.setWMYurl()
    }
  },
  methods: {
    ...mapMutations(["setFlagOfDomestic", "appStart", "setCompany", "setType", "setSimilar", 'setWebsite']),
    ...mapMutations("controlFormGlobal", {
      setControlFormGlobal: "setControlFormGlobal",
    }),
    ...mapMutations("anchor", {
      setAnchorFlag: "setAnchorFlag",
      setCurrent: "setCurrent",
    }),
    addddddd() {
      const item = (() => {
        try {
          return JSON.parse(window.sessionStorage.getItem('xiansuo-2r23fafaw')) || {}
        } catch {
          return {}
        }
      })()
      //console.log(item)
      /* let industrys = [];
      if (item.sicModels) {
        item.sicModels.forEach(element => {
          industrys.push(`(${element.primaryCode})${element.sicDescription}`)
        })
      }
      let submitObj = {
        "clueSource": item.dataType,
        "clueRelationKey": item.key,
        "companyName": item.company,
        "country": item.country,
        "legalPerson": "",
        "contactNumber": "",
        "registrationTime": item.incorporationDateStr,
        "employeesNumber": 0,
        "officialWebsite": item.website ? item.website : item.domain,
        "corporateEmail": item.email,
        "businessAddress": item.address ? item.address : item.address1,
        "companyProfile": "",
        "industrys": industrys,
      } */
      let submitObj = item
      this.adddddddddd.l = true
      this.$axios.post(`${this.$clueUrl}/MyClue/AddMyClue`, submitObj).then((res) => {
        this.adddddddddd.l = false
        const { isSuccess } = res;
        if (isSuccess) {
          this.$message({ message: '添加成功', type: 'success' });
          this.adddddddddd.v = false
        }
      });
    },
    onFinish() {
      this.joinDialogVisible = false;
      this.$message({ message: '加入成功', type: 'success' });
      //this.getMyClue();
    },
    joinMyCustomer() {
      this.joinDialogVisible = true;
      this.clickItem = {
        businessAddress: "",
        clueRelationKey: "",
        clueSource: 6,
        companyName: this.information.name,
        companyProfile: "",
        contactNumber: "",
        contactsCount: 100,
        corporateEmail: "",
        country: "",
        createTime: "",
        employeesNumber: 0,
        flagUrl: undefined,
        industry: "",
        isMyCustomer: false,
        key: "",
        legalPerson: "",
        officialWebsite: "",
        registrationTime: "",
        sendTime: "--",
        sourceStr: "",
        state: 0
      }
      //this.clickItem = item;
    },
    refreshhhh(keyword) {
      this.linkc.loading = true
      axios({
        method: 'GET',
        url: '/search/Credit/SwitchCompanyInfo',
        params: { keyword }
      }).then((res => {
        //  console.log(res)
        this.setInformation(res.data.result);
        this.linkc.dialog = false

        window.location.reload()
        /* this.linkc.otincn=''
        this.linkc.optionc='不限'
        this.getLinkCDialogData({index:1}) */
      })).finally(() => {
        this.linkc.loading = false
      })
    },
    getLinkCDialogData(option) {
      null
      /* this.linkc.loading = true
      axios({
        method: 'POST',
        url: '/search/Credit/GetMineBusinessCompany',
        data: {
          "pageIndex": option.index || this.linkc.page.index,
          "pageSize": this.linkc.page.size,
          "companyName": this.linkc.otincn,
          "country": this.linkc.optionc
        }
      }).then(res => {
        console.log({ flag: res.data.result })
        const r = res.data.result
        this.linkc.permisson = r.businessDataModels
        this.linkc.country = r.countrys || []
        this.linkc.data = r.data
        this.linkc.total = r.totalItems
        option.index && (this.linkc.page.index = option.index)
      }).finally(() => {
        this.linkc.loading = false
      }) */
    },
    navToWeb(url) {
      // window.open(url, '_blank')
      interceptLink(url)
    },
    ccc() {
      //console.log('okk')
      this.combaineCompany.render = true
      //console.log(this.combaineCompany.render)
    },
    async onCopy(text) {
      try {
        await navigator.clipboard.writeText(text)
        Message.success('复制成功')
      } catch {
        null
      }
    },
    setInformation(param) {
      (this.information)[param[0]] = param[1];
    },
    navToBroswer() {
      interceptLink(this.information.netaddress);
    },
    navToFacebook() {
      interceptLink(`www.facebook.com/search/pages/?q=${this.information.name}`);
    },
    navToTwitter() {
      interceptLink(`twitter.com/search?vertical=default&q=${this.information.name}`);
    },
    navToBing() {
      interceptLink(`cn.bing.com/search?q=${this.information.name}`);
    },
    navToMail() {
      window.open(this.waimaoyouwebsite, "_blank");
    },
    async modifyNetAddress() {
      try {
        const url = this.editNetAddress.replace(/\s/g, '');
        if (url) {
          this.editNetAddressLoading = true;
          const res = await axios({
            method: "get",
            url: "/search/Credit/UpdateCompanyUrl",
            params: {
              keyword: url,
            },
          });
          if (res.data.result) {
            Message({
              message: "修改成功",
              type: "success",
            });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          } else {
            throw new Error(res.data.msg.info);
          }
        } else {
          Message({
            message: "请输入公司网址",
            type: "warning",
          });
        }
      } catch (err) {
        Message({
          message: err,
          type: "warning",
        });
      } finally {
        this.editNetAddressLoading = false;
      }
    },
    handleCountryFlagClick() {
      if (this.sourceCountry.length !== 0) {
        this.showCountryDialog = true;
      }
    },
    handlesourceCountryChangeFunc() {
      this.handlesourceCountryChange(this.sourceCountryValue)
    },
    async handlesourceCountryChange(value) {
      try {
        this.countryDialogLoading = true;
        await axios({
          method: "GET",
          url: "/search/Credit/BusinessDataIDR",
          params: {
            keyword: value[1],
          },
        });
        window.location.reload();
      } catch {
        Message.error("修改企业所属国家失败");
      } finally {
        this.countryDialogLoading = false;
      }
    },
    setWMYurl: (function () {
      let controller = new AbortController()
      var _self = this
      return function () {
        controller.abort()
        controller = new AbortController()
        /* axios({
          signal: controller.signal,
          method: "GET",
          url: "/search/Credit/EdmLoginUrl",
          params: {
            website: this.information.netaddress
          }
        }).then((res) => {
          this.waimaoyouwebsite = res.data.result || "";
        }) */
      }
    })()
  },
  async created() {
    try {
      const res = await axios({
        method: "get",
        url: "/search/Credit/FirmInfo",
      });
      const data = res.data.result;

      //设置时间---start
      setTimeout(() => {
        (async () => {
          try {
            const resultTime = await axios({
              method: "get",
              url: "/search/Credit/LatestTime",
            });
            const data = resultTime.data.result;
            if (data.timeBelong && [1, 2, 3, 5].includes(data.timeBelong)) {
              const map = {
                1: "一年",
                2: "两年",
                3: "三年",
                5: "五年",
              };
              this.setControlFormGlobal([
                ["dateRangeNum", data.timeBelong],
                ["dateRange", map[data.timeBelong]],
              ]);
            }
          } catch {
            console.log("设置时间错误");
          }
        })();
      });
      //设置时间---end

      const updateDate = dayjs().format("YYYY-MM-DD HH:mm");
      this.setSimilar(data.mergerCompany);
      this.information = {
        ...this.information,
        name: data.company,
        updateDate,
        similar: data.mergerCompany,
        flagSrc: data.nationalFlag || '',
        country: data.countryCn || ''
      };
      /* if (data.isCnCompany) {
        this.setFlagOfDomestic("domestic");
      } else {
        this.setFlagOfDomestic("abroad");
      } */
      this.setFlagOfDomestic("abroad");
      this.setCompany(data.company);
      this.setType(data.importerOrExporter.toLowerCase());

      /* axios({
        method: "GET",
        url: "/search/Credit/GetUpdateRecord",
      })
        .then((res) => {
          this.updateInfo = {
            text: res.data.result.datas,
            date: dayjs.tz(res.data.result.dateTime).format("YYYY-MM-DD"),
          };
        })
        .catch((e) => console.log(e)); */
      axios({
        method: 'get',
        url: '/search/Credit/EnterpriseLibrary/Pictures',
        params: {
          companyName: data.company
        }
      }).then(res => {
        this.information.images = res.data.result || [];
      }).catch(() => {
        this.information.images = [];
      })

      axios({
        method: 'POST',
        url: '/salesclue/MyClue/IsMyClues',
        data: [data.company]
      }).then(res => {
        try {
          if (res.data.result.length === 0) {
            this.adddddddddd.v = true
          }
        } catch {
          null
        }
      })

      //App启动
      this.appStart();
      document.title = `${data.company}公司- 企业资信|跨境搜`;
      setTimeout(() => {
        this.setAnchorFlag([["ready", true]]);
      }, 3000);
    } catch (err) {
      console.log(err);
      //this.showDiaglog = true;
    } finally {
      new AnchorInditor(() => {
        this.setCurrent("QYGK");
      }, this.$refs["qiyegaikuang"]);
    }
  },
  mounted() {
    this.getLinkCDialogData({})
    this.setWMYurl()
    axios({
      method: "get",
      url: "/search/Credit/IDRDataCountry",
    }).then((res) => {
      // [ "美国", "US" ], [ "中国", "CN" ]
      if (res.data.result && res.data.result.length !== 0) {
        this.sourceCountry = res.data.result;
      }
    });
  },
});
</script>

<style lang="scss" scoped>
.notify {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 46px;
  margin-top: 20px;
  margin-bottom: -30px;
  border-radius: 7px;
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  border-top: 1px solid #d7d7d8;

  .text-a {
    span {
      word-break: break-all;
    }
  }
}

.enterprise-info {
  font-size: 13px;
  //margin-top: 1rem;
  background: #fff;

  .header {
    padding: 1rem;

    .information {
      display: flex;

      //grid-template-columns: 180px calc(100% - 180px);
      .img-wrapper {
        width: 180px;
        //width: 100%;
        height: 180px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        border: 2px #d7d7d8 solid;

        .image {
          max-width: 100%;
          max-height: 100%;
        }
      }

      .info-wrapper {
        width: 100%;
        box-sizing: border-box;
        padding-left: 1rem;

        .tag {
          display: inline-block;
          padding: 2px 5px;
          border-radius: 5px;
          font-size: 12px;

          &.active {
            background: #bac3de;
            color: var(--themeColor, #3152AF);
          }

          &.border {
            border: 1px solid var(--themeColor, #3152AF);
            padding: 0px 3px;
          }
        }

        .item {
          margin-bottom: 1rem;
        }
      }
    }

    .image-container {
      margin-top: 1em;
      overflow-x: auto;
      overflow-y: hidden;
      height: 158px;
      display: flex;
      flex-wrap: nowrap;
    }
  }
}
</style>
