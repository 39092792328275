/**
 * @description 表格的行样式回调
 * @param param 
 * @returns 
 */
function tableRowStyleCallBack(param) {
  const { rowIndex } = param;
  if (rowIndex % 2 === 0) {
    return {
      background: "#ffffff",
    };
  } else {
    return {
      background: "#F9FAFC",
    };
  }
}

export {
  tableRowStyleCallBack
}
