<template>
    <div>
        <div v-if="!dialogVisible">
            <div class="header">
                <div class="title">我的模板</div>
                <div class="describe">管理您的邮件模板</div>
                <el-button class="addBtn" @click="toNew" size="small" type="primary" icon="el-icon-plus">新建</el-button>
            </div>
            <el-table :data="TemplateList" :header-cell-style="{'background-color':'#F3F3F3','color':'#3A3A3A'}" :row-style="rowStyle">
                <el-table-column prop="name" label="模板名称"></el-table-column>
                <el-table-column prop="createDate" label="创建时间"></el-table-column>
                <el-table-column label="操作" width="200">
                    <template slot-scope="scope">
                        <div>
                            <div class="handleEditBtn" @click="toEdit(scope.row)">编辑</div>
                            <div class="handleDelBtn" @click="toDel(scope.row)">删除</div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div v-if="dialogVisible">
            <div class="header">
                <div class="title">{{ dialogTitle }}</div>
            </div>
            <el-input v-model="submitForm.name" size="small" placeholder="模板名称" style="margin-bottom: 20px;"></el-input>
            <Editor v-model="submitForm.htmlContent" placeholder="模板内容" :init="{
                language: 'zh_CN',
                resize: false,
                height: 'calc(100vh - 380px)',
                branding: false,
                menubar: false,
                toolbar: 'undo redo | emoticons | image link | formatselect fontsizeselect forecolor | bold underline italic | alignleft aligncenter alignright | preview fullscreen insertVariable',
                plugins: 'emoticons image link preview fullscreen',
                paste_data_images: true,
                urlconverter_callback,
                images_upload_handler}"
                tinymceScriptSrc="tinymce/tinymce.min.js"
                apiKey="l6y3tipsjmozdy96gs2qcbjjjaauujp07q4r0rpnyxqjh4ac">
            </Editor>
            <div style="margin-top: 20px;display: flex;justify-content: flex-end;">
                <el-button size="small" type="primary" @click="onSubmit" :loading="btnLoading">确 认</el-button>
                <el-button size="small" @click="dialogVisible = false">取 消</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';
export default {
    components: { Editor },
    data() {
        return {
            TemplateList: [],
            dialogVisible: false,
            dialogTitle: "",
            submitForm: {},
            btnLoading: false,
        };
    },
    methods: {
        rowStyle({rowIndex}){
            // 表格隔行换色
            if(rowIndex % 2 == 1){
                return {'background-color':'#F9FAFC'}
            }
        },
        urlconverter_callback(url, node, on_save, name){
            // console.log('图片链接', url );
		    return url;
        },
        async images_upload_handler(blobInfo) {
            try {
                const formData = new FormData();
                formData.append("folderKey", "0");
                formData.append("remarkName", "");
                formData.append("permission", "我的");
                formData.append("file", blobInfo.blob());
                const res = await this.$axios({
                    method: "POST",
                    url: this.$diskUrl + "/OtherFile/Upload",
                    data: formData,
                });
                return res.fileUrl;
            } catch (error) {
                this.$message.error("插入图片失败");
            }
        },

        getList(){
            this.$axios.get(`${this.$mailUrl}/v1/config/Template`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.TemplateList = data;
                }
            });
        },
        toNew(){
            this.dialogTitle = "添加模板";
            this.dialogVisible = true;
            this.submitForm = {
                key: "",
                name: "",
                htmlContent: "",
            };
        },
        toEdit(row){
            this.dialogTitle = "编辑模板";
            this.dialogVisible = true;
            this.submitForm = {
                key: row.key,
                name: row.name,
                htmlContent: row.htmlContent,
            };
        },
        onSubmit(){
            const {name,htmlContent} = this.submitForm;
            if(!name){
                this.$message({ message: '请输入模板名称', type: 'warning'});
                return false;
            }
            if(!htmlContent){
                this.$message({ message: '请输入模板内容', type: 'warning'});
                return false;
            }
            this.btnLoading = true;
            this.$axios.post(`${this.$mailUrl}/v1/config/Template`,this.submitForm).then((res) => {
                const { isSuccess } = res;
                this.btnLoading = false;
                if (isSuccess) {
                    this.dialogVisible = false;
                    this.$message({ message: '提交成功', type: 'success'});
                    this.getList();
                }
            });
        },
        toDel(row){
            this.$confirm(`是否删除该模板：<strong style='color:var(--themeColor);'>${row.name}</strong>?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                dangerouslyUseHTMLString: true
            }).then(() => {
                this.$axios.delete(`${this.$mailUrl}/v1/config/Template?key=${row.key}`).then(res => {
                    const {isSuccess} = res;
                    if(isSuccess){
                        this.$message({type: 'success',message: '删除成功!'});
                        this.getList();
                    }
                });
            }).catch(() => {});
        }
    },
    mounted() {
        this.getList();
    },
};
</script>

<style lang="scss" scoped>
.header{
    margin-bottom: 20px;
    overflow: hidden;
    height: 32px;
    line-height: 32px;
    .title{
        float: left;
        font-size: 16px;
        color: #333;
        font-weight: bold;
    }
    .describe{
        float: left;
        margin-left: 20px;
        font-size: 12px;
        color: #708090;
    }
    .addBtn{
        float: right;
    }
}
</style>