<template>
    <div>
        <el-dialog title="HS编码" :visible="true" width="1000px" :show-close="false"
            :close-on-click-modal="false" :close-on-press-escape="false">
            <div :loading="infoLoading">
                <div class="header">
                    <el-radio-group v-model="codeType" size="small" @change="typeChange">
                        <el-radio-button label="2">二位</el-radio-button>
                        <el-radio-button label="4">四位</el-radio-button>
                        <el-radio-button label="6">六位</el-radio-button>
                    </el-radio-group>
                    <!-- 搜索自动匹配 -->
                    <el-input placeholder="编码搜索" @input="onSearch" v-model="inputValue" size="small" style="width:200px;" clearable>
                        <el-button slot="append" icon="el-icon-search" @click="onSearch"></el-button>
                    </el-input>
                </div>
                <div class="titlebar">
                    <div class="oneItem">编码</div>
                    <div class="twoItem">交易次数</div>
                    <div class="twoItem">金额(美金)</div>
                </div>
                <div v-if="showCodeList.length != 0">
                    <div class="content" v-for="(ele,index) in showCodeList.slice((pageIndex-1)*10,pageIndex*10)" :key="index">
                        <div class="oneItem">
                            <span class="hsCode">{{ele.hsCode}}</span>
                            <span>{{ele.describeCn}}</span>
                        </div>
                        <div class="twoItem">{{ele.frequency}}</div>
                        <div class="twoItem">{{ele.value}}</div>
                    </div>
                </div>
                <div v-else>
                    <el-empty :image-size="50"></el-empty>
                </div>
                <div class="footer">
                    <el-pagination background layout="total, prev, pager, next" :total="showCodeList.length" v-show="showCodeList.length > 10"
                    @current-change="pageIndexChange" :current-page="pageIndex" style="float:left;"></el-pagination>
                    <el-button size="small" @click="closeDialog" style="float:right;">关 闭</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import CnHs2_2 from "@/assets/json/CnHs2_2.json";
import CnHs2_4 from "@/assets/json/CnHs2_4.json";
import CnHs2_6 from "@/assets/json/CnHs2_6.json";
export default {
    props: ["viewKey","dataYear"],
    data() {
        return {
            infoLoading: false,
            codeType: "2",
            inputValue: "",
            twoCode: [],
            fourCode: [],
            sixCode: [],
            showCodeList: [],
            pageIndex: 1,
        };
    },
    methods: {
        closeDialog(){
            this.$emit('close');
        },

        //获取编码信息
        getCodeInfo(){
            this.infoLoading = true;
            this.$axios.get(`${this.$clueUrl}/ClickHouse/GetMoreHscode?key=${this.viewKey}&year=${this.dataYear}`).then((res) => {
                const { isSuccess, data } = res;
                this.infoLoading = false;
                if (isSuccess) {
                    //匹配HS编码中文
                    data.twoCode.forEach(ele =>{
                        let aList = CnHs2_2.list.filter(item => item.HsCode == ele.hsCode);
                        if(aList.length > 0){
                            ele.describeCn = "(" + aList[0].Product + ")";
                        }else{
                            ele.describeCn = "";
                        }
                    })
                    data.fourCode.forEach(ele =>{
                        let bList = CnHs2_4.list.filter(item => item.HsCode == ele.hsCode);
                        if(bList.length > 0){
                            ele.describeCn = "(" + bList[0].Product + ")";
                        }else{
                            ele.describeCn = "";
                        }
                    })
                    data.sixCode.forEach(ele =>{
                        let cList = CnHs2_6.list.filter(item => item.HsCode == ele.hsCode);
                        if(cList.length > 0){
                            ele.describeCn = "(" + cList[0].Product + ")";
                        }else{
                            ele.describeCn = "";
                        }
                    })
                    this.twoCode = data.twoCode;
                    this.fourCode = data.fourCode;
                    this.sixCode = data.sixCode;
                    this.typeChange(this.codeType);
                }
            });
        },
        typeChange(value){
            this.pageIndex = 1;
            if(value == 2){
                this.showCodeList = this.twoCode.filter(ele => ele.hsCode.indexOf(this.inputValue) >=0);
            }
            if(value == 4){
                this.showCodeList = this.fourCode.filter(ele => ele.hsCode.indexOf(this.inputValue) >=0);
            }
            if(value == 6){
                this.showCodeList = this.sixCode.filter(ele => ele.hsCode.indexOf(this.inputValue) >=0);
            }
        },
        onSearch(){
            this.typeChange(this.codeType);
        },
        pageIndexChange(page){
            this.pageIndex = page;
        },
    },
    mounted() {
        this.getCodeInfo(); //获取编码信息
    },

};
</script>

<style lang="scss" scoped>
.header{
    display: flex;
    justify-content: space-between;
}
.titlebar{
    height: 32px;
    line-height: 32px;
    background-color: #ddd;
    display: flex;
    margin-top: 10px;
    .oneItem{
        padding-left: 20px;
        line-height: 32px;
        width: 70%;
        text-align: left;
    }
    .twoItem{
        width: 15%;
        text-align: center;
    }
}
.content{
    display: flex;
    max-height: 350px;
    overflow: auto;
    .oneItem{
        padding-left: 20px;
        line-height: 32px;
        width: 70%;
        text-align: left;
        .hsCode{
            color: var(--themeColor);
            font-weight: bold;
        }
    }
    .twoItem{
        width: 15%;
        text-align: center;
    }
}
.footer{
    overflow: hidden;
    margin: 10px 0;
}
</style>