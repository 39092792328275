<template>
    <div class="staffList">
        <div class="addTip" v-show="tipContent" @click="toAudit">{{tipContent}}</div>

        <div style="display: flex;margin-top:20px;">
            <div class="leftBox">
                <div class="header">
                    <div class="title">组织架构</div>
                    <div><el-input v-model="searchValue" @input="onSearch" clearable size="small" prefix-icon="el-icon-search" placeholder="搜索人员"></el-input></div>
                </div>
                <el-tree :data="treeData" :props="defaultProps" node-key="key" :current-node-key="currentNodeKey" highlight-current default-expand-all @node-click="handleNodeClick">
                    <span class="tree-node" slot-scope="{ data }">
                        <span class="leftSpan">{{ data.name }} ({{data.count}})</span>
                        <span v-show="isAdmin">
                            <el-dropdown size="mini">
                                <span @click.stop="() =>{}"><i class="el-icon-more"></i></span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item @click.native="() => option(data,'add')">添加子部门</el-dropdown-item>
                                    <el-dropdown-item @click.native="() => option(data,'update')">修改名称</el-dropdown-item>
                                    <el-dropdown-item @click.native="() => option(data,'delete')">删除</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </span>
                    </span>
                </el-tree>
            </div>
            <div class="rightBox" v-loading="tableLoading">
                <div class="topHeader">
                    <div class="breadcrumb">{{clickNodeName}}</div>
                    <!-- <el-breadcrumb separator="/" class="breadcrumb">
                        <el-breadcrumb-item>武汉技术部</el-breadcrumb-item>
                        <el-breadcrumb-item>后端开发</el-breadcrumb-item>
                    </el-breadcrumb> -->
                    <div style="float: right;">
                        <el-select v-model="isQuit" @change="pageIndexChange(1)" placeholder="请选择" size="small"
                         style="margin-right:10px;width:100px;">
                            <el-option label="在职人员" :value="false"></el-option>
                            <el-option label="离职人员" :value="true"></el-option>
                        </el-select>
                        <el-button v-show="isAdmin" size="small" @click="importStaff">员工导入</el-button>
                        <el-button v-show="isAdmin" size="small" @click="addStaff">新增成员</el-button>
                        <el-button v-show="isAdmin" size="small" @click="inviteJoin">邀请加入</el-button>
                    </div>
                </div>
                <el-empty :image-size="200" v-show="staffList.length==0"></el-empty>
                <div class="staffItem" v-for="item in staffList" :key="item.key">
                    <img class="manageImg" v-show="item.isManager" src="@/assets/img/manage.png" alt="" />
                    <el-popover trigger="click">
                        <ColleagueCardVue :ckey="item.ukey" />
                        <el-avatar slot="reference" class="avatarImg" :size="80" :src="item.headPortrait"></el-avatar>
                    </el-popover>
                    <el-row :gutter="24">
                        <el-col :span="8" class="field">
                            <div class="label">姓名：</div>
                            <div class="value">
                                {{item.name}}
                                <el-tag effect="plain" size="small" type="success" v-show="item.isAdmin">管理员</el-tag>
                                <el-tag effect="plain" size="small" type="primary" v-show="item.isFinance">财务</el-tag>
                                <el-tag effect="plain" size="small" type="danger" v-show="item.isStop">已停用</el-tag>
                                <el-tag effect="plain" size="small" type="danger" v-show="item.isQuit">已离职</el-tag>
                            </div>
                        </el-col>
                        <el-col :span="8" class="field">
                            <div class="label">性别：</div>
                            <div class="value">{{item.sex}}</div>
                        </el-col>
                        <el-col :span="8" class="field">
                            <!-- <div class="label">生日：</div>
                            <div class="value">{{item.birthday}}</div> -->
                            <span style="color: #858585;">入职时间：</span>
                            <span style="color: #092338;">{{item.entryTime}}</span>
                        </el-col>
                        <el-col :span="8" class="field">
                            <div class="label">账号：</div>
                            <div class="value">{{item.email}}</div>
                        </el-col>
                        <el-col :span="8" class="field">
                            <div class="label">微信：</div>
                            <div class="value">
                                <img src="@/assets/img/wechat.png" alt="" />{{item.weChatName}}
                            </div>
                        </el-col>
                        <el-col :span="8" class="field">
                            <div class="label">电话：</div>
                            <div class="value">
                                <img src="@/assets/img/tel.png" alt="" />{{item.phone}}
                            </div>
                        </el-col>
                        <el-col :span="8" class="field">
                            <div class="label">部门：</div>
                            <div class="value">{{item.organization}}</div>
                        </el-col>
                        <el-col :span="8" class="field">
                            <div class="label">职位：</div>
                            <div class="value">{{item.position}}</div>
                        </el-col>
                        <el-col :span="8" class="field" v-show="item.kjsAccount">
                            <span style="color: #858585;">跨境搜账号：</span>
                            <span style="color: #092338;">{{item.kjsAccount}}</span>
                        </el-col>
                    </el-row>
                    <div class="operationBtn">
                        <el-button type="primary" plain size="mini" @click="toStaffDetails(item)">查看</el-button>
                        <el-dropdown size="mini">
                            <el-button type="info" plain size="mini" style="margin-left:10px;" v-show="isAdmin">操作</el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item v-show="userInfo.isAdmin" @click.native="toSetCountry(item)">
                                    设置国家权限
                                </el-dropdown-item>
                                <!-- 跨境搜账号关联和解除 -->
                                <el-dropdown-item v-show="userInfo.isAdmin" @click.native="kjsBind_Unbind(item)">
                                    {{item.kjsAccount ? "解除跨境搜账号" : "关联跨境搜账号"}}
                                </el-dropdown-item>
                                <el-dropdown-item @click.native="setManager(item)">
                                    {{item.isManager ? "设为成员" : "设为负责人"}}
                                </el-dropdown-item>
                                <el-dropdown-item v-show="userInfo.isAdmin" @click.native="setFinance(item)">
                                    {{item.isFinance ? "取消财务" : "设为财务"}}
                                </el-dropdown-item>
                                <el-dropdown-item @click.native="toDimission(item)">员工离职</el-dropdown-item>
                                <!-- <el-dropdown-item @click.native="deleteStaff(item)">删除</el-dropdown-item> -->
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </div>
                <el-pagination v-show="totalItems>10" background layout="total,prev,pager,next" :total="totalItems" style="float:right;margin:10px 0;" :current-page.sync="pageIndex" @current-change="pageIndexChange"></el-pagination>
            </div>
        </div>

        <!-- 添加子部门 -->
        <el-dialog title="添加子部门" :visible.sync="addVisible" width="30%">
            <el-input v-model="addName" placeholder="请输入子部门名称" size="small"></el-input>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addVisible = false" size="small">取 消</el-button>
                <el-button type="primary" @click="addOrganization" size="small" :loading="btnLoading">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 修改名称 -->
        <el-dialog title="修改名称" :visible.sync="updateVisible" width="30%">
            <el-input v-model="updateName" size="small"></el-input>
            <span slot="footer" class="dialog-footer">
                <el-button @click="updateVisible = false" size="small">取 消</el-button>
                <el-button type="primary" @click="updateOrganization" size="small" :loading="btnLoading">确 定</el-button>
            </span>
        </el-dialog>

        <!-- 邀请加入 -->
        <el-dialog title="邀请员工加入企芯CRM" :visible.sync="inviteVisible" width="500px">
            <div style="display:flex;">
                <div style="width:60px;height:32px;line-height:32px;">链接:</div>
                <el-input v-model="inviteObj.url" size="small" style="width:300px;"></el-input>
                <el-button type="primary" size="small" @click="copyUrl">复制链接</el-button>
            </div>
            <div style="display:flex;">
                <div style="width:60px;height:32px;line-height:32px;">二维码:</div>
                <img :src="inviteObj.codeBase64Img" alt="" />
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="inviteVisible = false" size="small">关 闭</el-button>
            </span>
        </el-dialog>

        <!-- 员工离职 -->
        <el-dialog title="员工离职" :visible.sync="dimissionVisible" width="400px">
            <el-form class="content" :model="dimissionObj" label-width="100px" size="small">
                <el-form-item label="离职成员">
                    <span style="color: var(--themeColor);">{{dimissionName}}</span>
                </el-form-item>
                <el-form-item label="客户转移" required>
                    <el-select v-model="dimissionObj.customerTransferUkey" placeholder="请选择人员" filterable>
                        <el-option v-for="item in staffOption" :key="item.ukey" :label="item.name" :value="item.ukey">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="商机转移" required>
                    <el-select v-model="dimissionObj.businessChanceTransferUkey" placeholder="请选择人员" filterable>
                        <el-option v-for="item in staffOption" :key="item.ukey" :label="item.name" :value="item.ukey">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="订单转移" required>
                    <el-select v-model="dimissionObj.orderTransferUkey" placeholder="请选择人员" filterable>
                        <el-option v-for="item in staffOption" :key="item.ukey" :label="item.name" :value="item.ukey">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dimissionVisible = false" size="small">取 消</el-button>
                <el-button type="primary" @click="dimissionSubmit" size="small" :loading="btnLoading">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog title="关联跨境搜账号" :visible.sync="relevanceVisible" width="1000px">
            <!-- <el-select v-model="kjsUkey" placeholder="选择关联账号" size="small" filterable>
                <el-option v-for="item in vipAccountOption" :key="item.ukey" :label="item.account" :value="item.ukey" :disabled="item.isDisable">
                </el-option>
            </el-select> -->
            <el-table :data="vipAccountOption" style="height: 520px;overflow: auto;">
                <el-table-column prop="account" label="账号"></el-table-column>
                <el-table-column prop="name" label="姓名"></el-table-column>
                <el-table-column prop="companyName" label="公司"></el-table-column>
                <el-table-column prop="phone" label="手机号"></el-table-column>
                <el-table-column label="关联账号">
                    <template slot-scope="{row}">
                        <span v-if="row.isDisable" style="color: var(--themeColor);">已关联</span>
                        <el-button v-else type="primary" plain @click="relevanceFinish(row)" size="small" :loading="btnLoading">关联账号</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
                <el-button @click="relevanceVisible = false" size="small">关 闭</el-button>
                <!-- <el-button type="primary" @click="relevanceFinish" size="small" :loading="btnLoading">确 定</el-button> -->
            </span>
        </el-dialog>

        <el-dialog title="设置国家权限" :visible.sync="setCountryVisible" width="1000px">
            <div style="margin-bottom: 20px;">
                <el-radio v-model="setCountryForm.isInclude" :label="true">包含选择的国家</el-radio>
                <el-radio v-model="setCountryForm.isInclude" :label="false">排除选择的国家</el-radio>
            </div>
            <el-select v-model="setCountryForm.country" placeholder="选择国家" size="small" style="width:100%;" filterable clearable multiple>
                <el-option v-for="item of countryOptions" :key="item" :value="item" />
            </el-select>
            <div style="font-size: 14px;color: #858585;margin-top: 20px;">
                *tip：不选国家即表示全部国家权限!
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="setCountryVisible = false" size="small">关 闭</el-button>
                <el-button type="primary" @click="setCountryFinish" size="small" :loading="btnLoading">确 定</el-button>
            </span>
        </el-dialog>
        
    </div>
</template>

<script>
import ColleagueCardVue from '@/components/ColleageCard.vue';
export default {
    components:{ColleagueCardVue},
    props: {
        handleSelect: {
            type: Function,
            default: null,
        },
    },
    data() {
        return {
            userInfo: {},
            isAdmin: false,     //操作权限

            btnLoading: false,
            searchValue: "",
            isQuit: false,
            //列表展示
            tableLoading: false,
            staffList: [],
            totalItems: 0,
            pageIndex: 1,
            //组织结构
            treeData: [],
            defaultProps: {
                children: "children",
                label: "name",
            },
            currentNodeKey: "", //当前节点key
            currentParentStr: "", //当前节点组织树编码
            clickNodeName: "", //当前节点名称

            //添加子部门，修改名称操作
            operationObj: "",
            addVisible: false,
            addName: "",
            updateVisible: false,
            updateName: "",

            //邀请加入
            inviteVisible: false,
            inviteObj: {},
            //审核提示
            tipContent: "",
            //员工离职
            dimissionVisible: false,
            dimissionName: "",
            dimissionObj: {},
            staffOption: [],
            //关联跨境搜账号
            relevanceVisible: false,
            vipAccountOption: [],
            crmUkey: "",
            kjsUkey: "",
            //设置国家权限
            countryOptions: window.ContryList,
            setCountryVisible: false,
            setCountryForm:{
                ukey: "",
                isInclude: false,
                country: [],
            }
        };
    },
    methods: {
        importStaff(){
            this.handleSelect(`/staff/importStaff`);
            this.$router.push(`/staff/importStaff`);
        },
        addStaff(){
            this.handleSelect(`/staff/addStaff`);
            this.$router.push(`/staff/addStaff`);
        },
        //邀请加入
        inviteJoin(){
            // 生成二维码和链接
            this.$axios.get(`${this.$personnelUrl}/EntryApplication/GetQRCodeAndUrl`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.inviteVisible = true;
                    this.inviteObj = data;
                }
            })
        },
        copyUrl(){
            //复制url路径
            const input = document.createElement('input');
            document.body.appendChild(input);
            input.setAttribute('value', this.inviteObj.url);
            input.select();
            if (document.execCommand('copy')) {
                document.execCommand('copy');
            }
            document.body.removeChild(input);
            this.$message({message: '复制成功',type: 'success'});
        },
        //获取审核提示
        getTip(){
            this.$axios.get(`${this.$personnelUrl}/EntryApplication/GetTip`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.tipContent = data;
                }
            })
        },
        toAudit(){
            this.handleSelect(`/staff/staffAudit`);
            this.$router.push(`/staff/staffAudit`);
        },

        getStaffList(){
            this.tableLoading = true;
            this.$axios.post(`${this.$personnelUrl}/Organization/GetUsersByOrganizationTree`,{
                parentStr: this.currentParentStr,
                phone: "",
                name: this.searchValue,
                email: "",
                isQuit: this.isQuit,
                pageIndex: this.pageIndex,
                pageSize: 10,
            }).then((res) => {
                const { isSuccess, data } = res;
                this.tableLoading = false;
                if (isSuccess) {
                    this.staffList = data.datas;
                    this.totalItems = data.totalItems;
                    this.staffList.forEach(item =>{
                        item.headPortrait = item.headPortrait ? item.headPortrait : item.wechatAvatar ? item.wechatAvatar : "https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
                    })
                }
            });
        },
        pageIndexChange(page){
            this.pageIndex = page;
            this.getStaffList();
        },
        onSearch(){
            this.pageIndex = 1;
            this.getStaffList();
        },
        toStaffDetails(item){
            let routeData = this.$router.resolve({
                path: "/staff/staffDetails",
                query: { staffkey: item.ukey },
            });
            window.open(routeData.href, "_blank");
        },

        //组织结构
        getTreeData(){
            this.$axios.get(`${this.$personnelUrl}/Organization/GetOrganization`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.treeData = data;
                    //默认初始值
                    this.currentNodeKey = data[0].key;
                    this.currentParentStr = data[0].parentStr;
                    this.clickNodeName = data[0].name;
                    this.pageIndex = 1;
                    this.getStaffList();
                }
            })
        },
        handleNodeClick(data) {
            this.currentNodeKey = data.key;
            this.currentParentStr = data.parentStr;
            this.clickNodeName = data.name;
            this.pageIndex = 1;
            this.getStaffList();
        },
        option(data, type) {
            this.operationObj = data;
            if (type == "add") {
                this.addVisible = true;
                this.addName = "";
            } else if (type == "update") {
                this.updateVisible = true;
                this.updateName = data.name;
            } else if (type == "delete") {
                this.$confirm("删除该组织会将组织里面的人员全部释放。是否确认删除?","提示",{
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    this.$axios.get(`${this.$personnelUrl}/Organization/DeleteOrganization?key=${data.key}`).then((res) => {
                        const { isSuccess } = res;
                        if (isSuccess) {
                            this.$message({message: "删除成功",type: "success",});
                            this.getTreeData();
                        }
                    });
                }).catch(() => {});
            }
        },
        addOrganization() {
            if (!this.addName) {
                this.$message({ message: "请填写部门名称", type: "warning" });
                return false;
            }
            this.btnLoading = true;
            this.$axios.post(`${this.$personnelUrl}/Organization/AddOrganization`, {
                key: "",
                name: this.addName,
                parentStr: this.operationObj.parentStr,
                parentId: this.operationObj.id,
            }).then((res) => {
                const { isSuccess } = res;
                this.addVisible = false;
                this.btnLoading = false;
                if (isSuccess) {
                    this.$message({ message: "添加成功", type: "success" });
                    this.getTreeData();
                }
            });
        },
        updateOrganization() {
            const { key, parentStr, parentId } = this.operationObj;
            if (!this.updateName) {
                this.$message({ message: "请填写部门名称", type: "warning" });
                return false;
            }
            this.btnLoading = true;
            this.$axios.post(`${this.$personnelUrl}/Organization/UpdateOrganization`, {
                key: key,
                name: this.updateName,
                parentStr: parentStr,
                parentId: parentId,
            }).then((res) => {
                const { isSuccess } = res;
                this.updateVisible = false;
                this.btnLoading = false;
                if (isSuccess) {
                    this.$message({ message: "编辑成功", type: "success" });
                    this.getTreeData();
                }
            });
        },

        //更多操作
        setManager(item){
            this.$axios.post(`${this.$personnelUrl}/Organization/SetManager`, {
                "organizationKey": this.currentNodeKey,
                "uKey": item.ukey,
                "isManager": !item.isManager
            }).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    this.$message({ message: "操作成功", type: "success" });
                    this.getStaffList();
                }
            });
        },
        setFinance(item){
            this.$axios.get(`${this.$personnelUrl}/Staff/SetFinance?ukey=${item.ukey}`).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    this.$message({ message: "操作成功", type: "success" });
                    this.getStaffList();
                }
            });
        },
        deleteStaff(item){
            this.$confirm(`是否删除该员工：<strong style='color:var(--themeColor);'>${item.name}</strong>?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                dangerouslyUseHTMLString: true
            }).then(() => {
                this.$axios.get(`${this.$personnelUrl}/Staff/DeleteUser?ukey=${item.ukey}`).then((res) => {
                    const { isSuccess } = res;
                    if (isSuccess) {
                        this.$message({type: 'success',message: '删除成功!'});
                        this.getStaffList();
                    }
                })
            }).catch(() => {});
        },
        toDimission(item){
            this.dimissionVisible = true;
            this.dimissionName = item.name;
            this.dimissionObj = {
                "resignationUkey": item.ukey,
                "customerTransferUkey": "",
                "businessChanceTransferUkey": "",
                "orderTransferUkey": "",
            }
        },
        dimissionSubmit(){
            const {customerTransferUkey,businessChanceTransferUkey,orderTransferUkey} = this.dimissionObj;
            if(!customerTransferUkey){
                this.$message({ message: "请选择客户转移成员", type: "warning" });
                return false;
            }
            if(!businessChanceTransferUkey){
                this.$message({ message: "请选择商机转移成员", type: "warning" });
                return false;
            }
            if(!orderTransferUkey){
                this.$message({ message: "请选择订单转移成员", type: "warning" });
                return false;
            }
            this.btnLoading = true;
            this.$axios.post(`${this.$personnelUrl}/Staff/Resignation`,this.dimissionObj).then((res) => {
                const { isSuccess } = res;
                this.dimissionVisible = false;
                this.btnLoading = false;
                if (isSuccess) {
                    this.$message({ message: "离职成功", type: "success" });
                    this.getStaffList();
                }
            });
        },
        // 转移人员下拉框
        getUsers(){
            this.$axios.get(`${this.$personnelUrl}/EntryApplication/GetUsers`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.staffOption = data;
                }
            });
        },

        //跨境搜账号关联和解除
        kjsBind_Unbind(item){
            if(item.kjsAccount){
                this.$confirm(`是否解除跨境搜账号?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    this.$axios.get(`${this.$personnelUrl}/Staff/UnbindKjsAccount?crmUkey=${item.ukey}`).then((res) => {
                        const { isSuccess } = res;
                        if (isSuccess) { 
                            this.$message({ message: "解除跨境搜账号成功", type: "success" });
                            this.getStaffList();
                        }
                    });
                }).catch(() => {});
            }else{
                this.relevanceVisible = true;
                this.crmUkey = item.ukey;
                this.kjsUkey = "";
                //获取公司对应跨境搜账号列表
                this.$axios.get(`${this.$personnelUrl}/Staff/GetVipAccounts`).then((res) => {
                    const { isSuccess, data } = res;
                    if (isSuccess) {
                        this.vipAccountOption = data;
                    }
                });
            }
        },
        relevanceFinish(row){
            this.kjsUkey = row.ukey;
            // if(!this.kjsUkey){
            //     this.$message({ message: "请选择关联账号", type: "warning" });
            //     return false;
            // }
            this.btnLoading = true;
            this.$axios.get(`${this.$personnelUrl}/Staff/BindKjsAccount?crmUkey=${this.crmUkey}&kjsUkey=${this.kjsUkey}`).then((res) => {
                const { isSuccess } = res;
                this.relevanceVisible = false;
                this.btnLoading = false;
                if (isSuccess) {
                    this.$message({ message: "关联跨境搜账号成功", type: "success" });
                    this.getStaffList();
                }
            });
        },


        //设置国家权限(员工查看公海客户的国家权限)
        toSetCountry(item){
            this.setCountryVisible = true;
            this.$axios.get(`${this.$customerUrl}/v1/Config/GetCountryPermissions?ukey=${item.ukey}`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.setCountryForm = data;
                }
            });
        },
        setCountryFinish(){
            // console.log(this.setCountryForm);
            this.btnLoading = true;
            this.$axios.post(`${this.$customerUrl}/v1/Config/SaveCountryPermissions`,this.setCountryForm).then((res) => {
                const { isSuccess } = res;
                this.setCountryVisible = false;
                this.btnLoading = false;
                if (isSuccess) {
                    this.$message({ message: "设置完成!", type: "success" });
                }
            });
        },
    },
    mounted() {
        this.userInfo = JSON.parse(localStorage.userInfo);
        this.isAdmin = this.userInfo.isAdmin || this.userInfo.isManager;  //公司管理员和部门经理都可以操作
        this.getTreeData(); //获取组织结构
        this.getUsers();    //转移人员下拉框
        if(this.isAdmin){
            this.getTip();      //获取审核提示
        }
    },
};
</script>

<style lang="scss" scoped>
.staffList{
    background: rgba($color: #FFFFFF, $alpha: 0.7);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    padding: 10px 20px;
    .addTip{
        height: 36px;
        line-height: 36px;
        background: #E7E7E7;
        font-size: 14px;
        color: #FF2D2D;
        padding-left: 20px;
        cursor: pointer;
    }
    .leftBox {
        width: 200px;
        height: calc(100vh - 200px);
        background: #FFFFFF;
        border: 1px solid #DFE5EC;
        border-radius: 4px;
        box-sizing: border-box;
        .header{
            padding: 10px;
            .title{
                font-size: 16px;
                margin-bottom: 5px;
                font-weight: bold;
                color: #1F2D3D;
            }
        }
        .tree-node {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            padding-right: 10px;
        }
        .leftSpan{
            display: inline-block;
            max-width: 130px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
    .rightBox{
        width: calc(100% - 220px);
        padding-left: 20px;
        overflow: hidden;
        .topHeader{
            margin-bottom: 20px;
            overflow: hidden;
            .breadcrumb{
                float: left;
                height: 32px;
                line-height: 32px;
            }
        }
        .staffItem{
            height: 120px;
            background: #FFFFFF;
            border: 1px solid #EBEBEB;
            margin-bottom: 12px;
            position: relative;
            display: flex;
            .manageImg{
                position: absolute;
                top: 0;
                left: 0;
            }
            .avatarImg{
                margin-left: 40px;
                margin-top: 20px;
            }
            .operationBtn{
                width: 130px;
                margin-top: 40px;
            }
            .el-row{
                margin: 12px 0 0 12px !important;
                width: 75%;
                .field{
                    line-height: 32px;
                    display: flex;
                    font-size: 14px;
                    .label{
                        width: 50px;
                        color: #858585;
                    }
                    .value{
                        flex-grow: 1;
                        color: #092338;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        img{
                            vertical-align: middle;
                            padding-right: 5px;
                        }
                    }
                }
            }
        }
    }
}
/deep/.el-dialog__body {
    padding: 10px 20px;
}
</style>
