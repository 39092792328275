/* import DoggyLazyExecute from 'doggy-lazy-execute'

export default DoggyLazyExecute
 */

// source code from https://github.com/Doggy-068/doggy-lazy-execute/blob/main/src/index.ts
// modify on 2021/08/10


class LazyOprationInToView {
  observer = null
  isObservered = false
  timer = NaN

  // 取消监听
  unobserve() {
    if (this.isObservered && this.observer) {
      this.observer.unobserve(this.element)
      this.isObservered = false
    }
  }

  // 再次监听
  reobserve() {
    if (!this.isObservered && this.observer) {
      this.observer.observe(this.element)
      this.isObservered = true
    }
  }

  // 销毁实例
  dispose() {
    if (this.observer) {
      this.observer.disconnect()
      this.observer = null
      this.isObservered = false
    }
  }

  /**
   * 
   * @param callback 执行的回调函数
   * @param element 监测的DOM元素
   * @param options 配置项
   */
  constructor(callback, element, options) {
    this.element = element
    this.observer = new IntersectionObserver((changes) => {
      if (changes[0].intersectionRatio > 0) {
        this.timer = setTimeout(() => {
          callback()
          this.unobserve()
        }, 300)
      } else {
        clearTimeout(this.timer)
      }
    }, {
      threshold: options?.threshold || [0]
    })
    this.observer.observe(this.element)
    this.isObservered = true
  }
}

export default LazyOprationInToView
