<template>
  <div class="compete-analyse-afverfdef-new" :class="{ full: control.full }" id="compete-analyse" v-loading="control.loading" v-show="control.show">
    <div class="title" ref="title">
      <p>企业图谱</p>
      <div style="display: flex; align-items: center">
        <el-tooltip effect="dark" content="保存为图片" placement="top-start">
          <img @click="saveImage" src="@/firm/images/company/icon-download.png" style="margin-left: 1em; cursor: pointer" />
        </el-tooltip>
        <el-tooltip effect="dark" content="刷新" placement="top-start">
          <img @click="refresh" src="@/firm/images/company/icon-refresh.png" style="margin-left: 15px; margin-right: 5px; cursor: pointer" />
        </el-tooltip>
        <el-tooltip v-show="control.full" effect="dark" content="取消全屏" placement="top-start">
          <i class="el-icon-close" style="font-size: 30px; color: #e9911b; cursor: pointer" @click="closeGraphFull"></i>
        </el-tooltip>
        <el-tooltip v-show="!control.full" effect="dark" content="全屏" placement="top-start">
          <img class="icon" src="@/firm/images/company/icon-expand.png" style="margin-left: 1em" @click="graphFull" />
        </el-tooltip>
      </div>
    </div>
    <div ref="container" class="graph" :class="{ full: control.full }"></div>
  </div>
</template>

<script>
import G6, { rectConfig, color as classifyToColor } from "./utils";
import { mapState, mapGetters, mapMutations } from "vuex";
import { disableScroll, enableScroll } from "../graph/utils";
import axios from "@/firm/plugins/axios";
import { Message } from "@/firm/plugins/element";
import { AnchorInditor } from "../../utils";

function transImageUrlToClassify(imgSrc) {
  if (imgSrc.includes("company")) {
    return "root";
  } else if (imgSrc.includes("peer")) {
    return "peer";
  } else if (imgSrc.includes("compete")) {
    return "compete";
  } else if (imgSrc.includes("buyer")) {
    return "buyer";
  } else if (imgSrc.includes("vendor")) {
    return "vendor";
  } else {
    return "";
  }
}

let graph = null;

export default {
  data() {
    return {
      control: {
        show: true,
        loading: false,
        full: false,
      },
    };
  },
  computed: {
    ...mapState(["company"]),
    ...mapGetters("controlFormGlobal", {
      startTime: "startTime",
      endTime: "endTime",
    }),
  },
  watch: {
    "control.option"() {
      this.refresh();
    },
  },
  methods: {
    ...mapMutations("anchor", {
      setAnchorFlag: "setAnchorFlag",
      setCurrent: "setCurrent",
    }),
    saveImage() {
      graph.downloadFullImage(`${this.company}企业图谱`, "image/png", {
        backgroundColor: "#ffffff",
      });
    },
    async refresh() {
      try {
        this.control.loading = true;
        const data = await this.getInitData();
        if (!data) {
          throw new Error();
        }
        graph.data(data);
        graph.render();
        graph.fitCenter();
      } catch {
        console.log("刷新失败");
      } finally {
        this.control.loading = false;
      }
    },
    graphFull() {
      this.control.full = true;
      disableScroll();
      this.$nextTick(() => {
        graph.changeSize(this.$refs["container"].clientWidth, this.$refs["container"].clientHeight);
      });
    },
    closeGraphFull() {
      this.control.full = false;
      enableScroll();
      this.$nextTick(() => {
        graph.changeSize(this.$refs["container"].clientWidth, this.$refs["container"].clientHeight);
      });
    },
    async getInitData() {
      try {
        const res = null
        /* const res = (
          await axios({
            method: "GET",
            url: "/api/Neo4j/CompanyMap",
            params: {
              company: this.company,
            },
          })
        ).data.result; */
        const data = {
          id: res.id,
          classify: transImageUrlToClassify(res.img),
          label: res.label,
          direct: res.direct,
          children: [],
          collapsed: false,
          leaf: false,
        };
        for (const item of res.children) {
          const classify = transImageUrlToClassify(item.img);
          if (["buyer", "vendor"].includes(classify)) {
            data.children.push({
              id: item.id,
              classify,
              label: item.label,
              direct: item.direct,
              children: item.children,
              collapsed: true,
              leaf: false,
              frequency: String(item.countNum),
            });
          }
        }
        return data;
      } catch {
        return null;
      }
    },
    async getChildren(company, parentClassify, direct) {
      try {
        const classify = { buyer: "compete", vendor: "peer" }[parentClassify];
        const res = null
        /* const res = (
          await axios({
            method: "get",
            url: "/api/Neo4j/CompanyMap",
            params: {
              company,
            },
          })
        ).data.result; */
        const children = [];
        for (const item of res.children) {
          if (transImageUrlToClassify(item.img) === classify) {
            children.push({
              id: item.id,
              classify,
              label: item.label,
              direct: direct,
              children: item.children,
              collapsed: true,
              leaf: true,
              frequency: String(item.countNum),
            });
          }
        }
        return children;
      } catch {
        return [];
      }
    },
  },
  async mounted() {
    try {
      const tooltip = new G6.Tooltip({
        itemTypes: ["node"],
        getContent(e) {
          try {
            const name = e.target.get("name");
            if (name === "label-text") {
              const nodeModel = e.item.getModel();
              return `
                <div>
                  <p>${{ peer: "同行", compete: "竞争对手", buyer: "采购商", vendor: "供应商" }[nodeModel.classify]}</p>
                  <p>${nodeModel.label}</p>
                  <p>频次：${nodeModel.frequency}</p>
                </div>
              `;
            }
            throw new Error();
          } catch {
            return "";
          }
        },
        shouldBegin(e) {
          try {
            const nodeModel = e.item.getModel();
            if (e.target.get("name") === "label-text" && ["peer", "compete", "buyer", "vendor"].includes(nodeModel.classify)) {
              return true;
            } else {
              throw new Error();
            }
          } catch {
            return false;
          }
        },
      });
      const rootLength = G6.Util.getTextSize(this.company, 12)[0] + 12;
      graph = new G6.TreeGraph({
        container: this.$refs["container"],
        modes: {
          default: ["collapse-expand", "drag-canvas", "zoom-canvas"],
        },
        plugins: [tooltip],
        layout: {
          type: "compactBox",
          direction: "H",
          getHGap(node) {
            if (node.classify === "root") {
              return rootLength / 2;
            }
            return 40;
          },
          getVGap() {
            return 5;
          },
          getHeight() {
            return rectConfig.height;
          },
          getWidth() {
            return rectConfig.width;
          },
          getSide(node) {
            return node.data.direct;
          },
        },
        defaultNode: {
          type: "rect-node-ojnklnvgcg-new",
        },
        defaultEdge: {
          type: "step-line-ojnklnvgcg",
        },
      });
      graph.on("node:click", async (evt) => {
        const item = evt.item;
        const nodeModel = item.getModel();
        const { classify, leaf, children } = nodeModel;
        if (["buyer", "vendor"].includes(classify) && children.length === 0 && !leaf) {
          this.control.loading = true;
          const company = nodeModel.label;
          const parentClassify = nodeModel.classify;
          const direct = nodeModel.direct;
          const children = await this.getChildren(company, parentClassify, direct);
          this.control.loading = false;
          if (children.length === 0) {
            if (parentClassify === "vendor") {
              Message.warning("该供应商下无同行");
              return;
            } else if (parentClassify === "buyer") {
              Message.warning("该采购商下无竞争对手");
              return;
            }
          }
          const nodeId = item.getID();
          graph.updateChildren(children, nodeId);
          nodeModel.collapsed = !nodeModel.collapsed;
          graph.layout();
        }
        graph.setItemState(item, "collapsed", nodeModel.collapsed);
      });
      const data = await this.getInitData();
      if (!data) {
        throw new Error("initData失败");
      }
      graph.edge((edge) => {
        const targetId = edge.target;
        const targetNode = graph.findById(targetId);
        const nodeClassify = targetNode.getModel().classify;
        if (nodeClassify.includes("second")) {
          return {
            type: "step-line-okj6uhgb",
          };
        } else {
          return {
            type: "step-line-okj6uhgb",
            color: classifyToColor[nodeClassify],
          };
        }
      });
      graph.data(data);
      graph.render();
      graph.fitCenter();
    } catch (error) {
      console.log({
        position: "新树图错误",
        message: error,
      });
      this.control.show = false;
      this.setAnchorFlag([["JZDSXX", false]]);
    }
    new AnchorInditor(() => {
      this.setCurrent("JZDSXX");
    }, this.$refs["title"]);
  },
};
</script>

<style lang="scss" scoped>
@import "@/firm/scss/mixin.scss";

.compete-analyse-afverfdef-new {
  box-sizing: border-box;
  margin-top: 1em;
  background: #fff;
  padding: 1rem;
  padding-top: 0;
  @include mixin-panel-title;

  &.full {
    margin: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999;
  }
}

.graph {
  height: 500px;

  &.full {
    height: calc(100vh - 64px - 10px);
  }
}
</style>
