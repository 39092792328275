<template>
    <div>
        <h2>供应商信息推送</h2>
        <div class="title">推送规则</div>
        <p>当配置了回调接口后，用户在采购需求上填写供应商信息，企芯会采异步的方式调用回调接口，推送数据给客户，推送的请求方式是POST,客户直接获取响应body中的数据即可</p>
        <p>请在收到推送请求后第一时间返回HTTP状态码STATUS=200,我方才认为本次推送成功。如果3000ms之后没有收到200响应，会认定本次推送异常，并发起重试推送。重试次数3次，每次间隔5分钟</p>

        <div class="title">推送请求方式</div>
        <p>POST</p>
        <p>Content-Type: application/json</p>

        <div class="title">请求参数</div>
        <el-table class="table" :data="inputTableData" :header-cell-style="{'background-color':'#F2F2F2','color':'#3A3A3A'}">
            <el-table-column prop="paramName" label="参数名"></el-table-column>
            <el-table-column prop="valueType" label="值类型"></el-table-column>
            <el-table-column prop="explain" label="说明"></el-table-column>
        </el-table>

        <div class="title">请求示例</div>
        <pre v-highlightjs><code class="language-json">{{exampleCode}}</code></pre>
    </div>
</template>

<script>
export default {
    data() {
        return {
            inputTableData:[],
            exampleCode:`{
    "type":"Procure",
    "data":{
        "procureKey":"79081671709a4c6e90c7819d4d4ed4d0",
        "title":"排气管垫片",
        "company":"xx公司",
        "contacts":"张三",
        "contactPhone":"181xxxx6677",
        "describe":"产品尺寸要求，材质要求，spec规格要求等信息，价格都不等",
        "markdownRequest":{
            "msgtype":"markdown",
            "markdown":{
                "content":"# 【排气管】采购需求信息推送 姓名:<font color='warning'>张三</font> 公司:<font color='warning'>测试公司</font> 电话:<font color='warning'>4124124124</font> 备注:<font color='warning'>hajdahfkahfkasj</font>"
            }
        }
    }
}`,
        };
    },
    methods: {

    },
    mounted() {
        this.inputTableData = [
            {paramName: "type", valueType: "string", explain: "数据类型 Procure 采购信息" },
            {paramName: "data", valueType: "object", explain: "数据" },
            {paramName: "data:procureKey", valueType: "string", explain: "采购需求key" },
            {paramName: "data:title", valueType: "string", explain: "采购标题" },
            {paramName: "data:company", valueType: "string", explain: "供应商" },
            {paramName: "data:contacts", valueType: "string", explain: "联系人" },
            {paramName: "data:contactPhone", valueType: "string", explain: "联系人电话" },
            {paramName: "data:describe", valueType: "string", explain: "供应描述" },
            {paramName: "data:markdownRequest", valueType: "object", explain: "企业微信机器人消息推送入参" },
        ]
    },
};
</script>

<style lang="scss" scoped>
h2 {
    padding-bottom: 10px;
    border-bottom: 1px solid #d9d9d9;
}
p{
    font-size: 14px;
    line-height: 28px;
    color: #333;
}
.title {
    font-weight: bold;
    font-size: 20px;
    line-height: 40px;
    margin-top: 20px;
}
.table{
    border: 1px solid #d7d7d7;
}
</style>