<template>
    <div class="SideSearch">
        <div class="formGroup">
            <el-radio-group v-model="fieldForm.ie" size="small" @change="toSearch" style="margin-left: 50px;">
                <el-radio-button label="i">进口</el-radio-button>
                <el-radio-button label="e">出口</el-radio-button>
            </el-radio-group>
        </div>
        <div class="formGroup">
            <el-date-picker v-model="fieldForm.startDate" type="date" value-format="yyyy-MM-dd" placeholder="开始日期" size="small" @change="fieldChange"></el-date-picker>
        </div>
        <div class="formGroup">
            <el-date-picker v-model="fieldForm.endDate" type="date" value-format="yyyy-MM-dd" placeholder="结束日期" size="small" @change="fieldChange"></el-date-picker>
        </div>
        <div class="formGroup">
            <div class="label">国家</div>
            <el-input v-model="fieldForm.country" placeholder="国家" size="small" @change="fieldChange"></el-input>
        </div>
        <div class="formGroup">
            <div class="label">HS编码</div>
            <el-input v-model="fieldForm.hsCode" placeholder="HS编码" size="small" @change="fieldChange"></el-input>
        </div>
        <div class="formGroup">
            <div class="label">产品描述</div>
            <el-input v-model="fieldForm.product" placeholder="产品描述" size="small" @change="fieldChange"></el-input>
        </div>
        <div class="formGroup">
            <div class="label">进口商</div>
            <el-input v-model="fieldForm.importer" placeholder="进口商" size="small" @change="fieldChange"></el-input>
        </div>
        <div class="formGroup">
            <div class="label">出口商</div>
            <el-input v-model="fieldForm.exporter" placeholder="出口商" size="small" @change="fieldChange"></el-input>
        </div>
        <div class="formGroup">
            <div class="label">提单号</div>
            <el-input v-model="fieldForm.billNo" placeholder="提单号" size="small" @change="fieldChange"></el-input>
        </div>
        <div class="formGroup">
            <div class="label">重量</div>
            <el-input type="number" v-model="fieldForm.minWeight" @change="fieldChange" size="small" style="width:calc(50% - 10px)"></el-input>
            <span style="width:20px;display: inline-block;text-align: center;">-</span>
            <el-input type="number" v-model="fieldForm.maxWeight" @change="fieldChange" size="small" style="width:calc(50% - 10px)"></el-input>
        </div>
        <div class="formGroup">
            <div class="label">数量</div>
            <el-input type="number" v-model="fieldForm.minQuantity" @change="fieldChange" size="small" style="width:calc(50% - 10px)"></el-input>
            <span style="width:20px;display: inline-block;text-align: center;">-</span>
            <el-input type="number" v-model="fieldForm.maxQuantity" @change="fieldChange" size="small" style="width:calc(50% - 10px)"></el-input>
        </div>
        <div class="formGroup">
            <div class="label">价格</div>
            <el-input type="number" v-model="fieldForm.minPrice" @change="fieldChange" size="small" style="width:calc(50% - 10px)"></el-input>
            <span style="width:20px;display: inline-block;text-align: center;">-</span>
            <el-input type="number" v-model="fieldForm.maxPrice" @change="fieldChange" size="small" style="width:calc(50% - 10px)"></el-input>
        </div>
        <div class="formGroup">
            <div class="label">金额</div>
            <el-input type="number" v-model="fieldForm.minValue" @change="fieldChange" size="small" style="width:calc(50% - 10px)"></el-input>
            <span style="width:20px;display: inline-block;text-align: center;">-</span>
            <el-input type="number" v-model="fieldForm.maxValue" @change="fieldChange" size="small" style="width:calc(50% - 10px)"></el-input>
        </div>
        <div class="formGroup">
            <el-checkbox v-model="includeOne" @change="fieldChange">包含船运数据</el-checkbox>
        </div>
        <div class="formGroup">
            <el-checkbox v-model="includeTwo" @change="fieldChange">过滤空进口商/出口商</el-checkbox>
        </div>
        <div class="formGroup">
            <el-checkbox v-model="includeThree" @change="fieldChange">过滤物流货代</el-checkbox>
        </div>
        <div class="formGroup">
            <el-button type="primary" size="small" @click="toSearch" style="width:120px;margin-left: 50px;">确 认</el-button>
        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs';
export default {
    data() {
        return {
            fieldForm:{
                ie: "i",
                startDate: "2020-01-01",
                endDate: dayjs().format('YYYY-MM-DD'),
                hsCode: "",
                product: "",
                importer: "",
                exporter: "",
                country: "",
                port: "",
                unLoadingPort: "",
                billNo: "",
                minWeight: null,
                maxWeight: null,
                minQuantity: null,
                maxQuantity: null,
                minPrice: null,
                maxPrice: null,
                minValue: null,
                maxValue: null,
                dataClass: 2,
                isNotNullImporter: false,
                isNotNullExporter: false,
                isNotImporterForwarder: false,
                isNotExporterForwarder: false,
            },
            includeOne: true,
            includeTwo: true,
            includeThree: true,
        };
    },
    methods: {
        fieldChange(){
            let {minWeight,maxWeight,minQuantity,maxQuantity,minPrice,maxPrice,minValue,maxValue} = this.fieldForm;
            this.fieldForm.minWeight = minWeight ? minWeight * 1 : 0;
            this.fieldForm.maxWeight = maxWeight ? maxWeight * 1 : 0;
            this.fieldForm.minQuantity = minQuantity ? minQuantity * 1 : 0;
            this.fieldForm.maxQuantity = maxQuantity ? maxQuantity * 1 : 0;
            this.fieldForm.minPrice = minPrice ? minPrice * 1 : 0;
            this.fieldForm.maxPrice = maxPrice ? maxPrice * 1 : 0;
            this.fieldForm.minValue = minValue ? minValue * 1 : 0;
            this.fieldForm.maxValue = maxValue ? maxValue * 1 : 0;
            this.fieldForm.dataClass = this.includeOne ? 2 : 0;
            this.fieldForm.isNotNullImporter = this.includeTwo;
            this.fieldForm.isNotNullExporter = this.includeTwo;
            this.fieldForm.isNotImporterForwarder = this.includeThree;
            this.fieldForm.isNotExporterForwarder = this.includeThree;
            this.$emit('inputComplete',this.fieldForm);
        },

        toSearch(){
            this.fieldChange();
            this.$emit('toSearch');
        }
    },
    mounted() {
        this.fieldForm.ie = this.$route.query.ie;
    },
};
</script>

<style lang="scss" scoped>
.SideSearch{
    padding: 10px 20px;
    box-sizing: border-box;
    // height: 100vh;
    overflow: auto;
    .title{
        font-size: 16px;
        height: 32px;
        line-height: 32px;
        font-weight: 600;
        color: #000;
        border-bottom: 1px solid #eaeaea;
        margin-bottom: 10px;
    }
    .formGroup{
        margin-bottom: 5px;
        .label{
            font-size: 14px;
            color: #333333;
            margin-bottom: 4px;
        }
    }
}
</style>