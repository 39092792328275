<template>
    <div>
        <InterfaceName :interfaceName="interfaceName" />
        <InterfaceSpe :requestUrl="requestUrl" :requestMethod="requestMethod" />
        <InputParam :inputTableData="inputTableData" />
        <ReturnParam :returnObj="returnObj" />   
    </div>
</template>

<script>
import InterfaceName from "../Acomponents/InterfaceName.vue";
import InterfaceSpe from "../Acomponents/InterfaceSpe.vue";
import InputParam from "../Acomponents/InputParam.vue";
import ReturnParam from "../Acomponents/ReturnParam.vue";

export default {
    components: { InterfaceName, InterfaceSpe, InputParam, ReturnParam },
    data() {
        return {
            interfaceName: "添加采购需求",
            requestUrl: "https://crm.qixinerp.com/open/PurchaseDemand/Save",
            requestMethod: "POST",
            inputTableData: [],
            returnObj: {},
        };
    },
    methods: {
        
    },
    mounted() {
        this.inputTableData = [
            {paramName: "title", valueType: "string", explain: "标题", required: true},
            {paramName: "describe", valueType: "string", explain: "需求描述", required: true},
            {paramName: "purchaseStartDate", valueType: "string", explain: "采购开始日期", required: true},
            {paramName: "purchaseEndDate", valueType: "string", explain: "采购结束日期", required: false},
            {paramName: "purchaseVolume", valueType: "string", explain: "采购数量", required: true},
            {paramName: "unit", valueType: "string", explain: "数量单位", required: true},
            {paramName: "requirement", valueType: "string", explain: "采购要求", required: true},
            {paramName: "company", valueType: "string", explain: "公司", required: true},
            {paramName: "contacts", valueType: "string", explain: "联系人", required: true},
            {paramName: "contactPhone", valueType: "string", explain: "联系人电话", required: true},
            {paramName: "contactEmail", valueType: "string", explain: "联系人邮箱", required: true},
            {paramName: "annexs", valueType: "array", explain: "附件", required: true},
            {paramName: "annexs:name", valueType: "string", explain: "附件名称", required: true},
            {paramName: "annexs:url", valueType: "string", explain: "附件路径", required: true},
            {paramName: "annexs:type", valueType: "int", explain: "附件类型(0图片 1附件 2视频)", required: true},
            {paramName: "placeDelivery", valueType: "string", explain: "交货地", required: false},
            {paramName: "purchasingCountry", valueType: "string", explain: "采购国", required: false},
            {paramName: "urgentStatus", valueType: "int", explain: "状态(1加急 0一般)", required: false},
            {paramName: "termPayment", valueType: "string", explain: "付款条件", required: false},
            {paramName: "settlementCurrency", valueType: "string", explain: "结算货币", required: false},
            {paramName: "deliveryTerms", valueType: "string", explain: "交货条款", required: false},
            {paramName: "industry", valueType: "string", explain: "行业", required: false},
        ]
        this.returnObj = {
            isSuccess: true,
            errorMessage: null,
            data: "af19a3cbd7094bafa19e629b04b482ae",
        };
    },
};
</script>

<style lang="scss" scoped>

</style>