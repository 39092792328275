<template>
    <div class="staffDetails">
        <div class="operation" v-show="isAdmin">
            <el-button size="small" @click="toEditStaff">编 辑</el-button>
            <el-button size="small" @click="stopOrStart">{{staffInfo.isStop ? "启 用" : "停 用"}}</el-button>
        </div>
        <div style="display:flex;" v-loading="infoLoading">
            <div class="headportrait">
                <el-avatar class="avatarImg" :size="80" :src="staffInfo.headPortraitUrl"></el-avatar>
                <el-upload :action="uploadUrl" :headers="headers" :data="uploadData" :show-file-list="false" :before-upload="beforeUpload" :on-success="uploadSuccess">
                    <el-button v-show="isAdmin" type="info" plain size="mini" style="margin-top:10px;">更换头像</el-button>
                </el-upload>
            </div>
            <div class="infoBox" v-if="!editVisible">
                <div class="infoTop">
                    <span class="name">{{staffInfo.name}}</span>
                    <span class="account">{{staffInfo.enterpriseMailbox}}</span>
                    <el-tag effect="plain" size="small" type="success" v-show="staffInfo.isAdmin">管理员</el-tag>
                    <el-tag effect="plain" size="small" type="danger" v-show="staffInfo.isStop">已停用</el-tag>
                    <el-tag effect="plain" size="small" type="danger" v-show="staffInfo.isQuit">已离职</el-tag>
                </div>
                <div class="infoItem">
                    <div class="label">性别:</div>
                    <div class="value">{{staffInfo.sex == 1 ? '男' : '女'}}</div>
                </div>
                <div class="infoItem">
                    <div class="label">出生日期:</div>
                    <div class="value">{{staffInfo.birthday ? staffInfo.birthday : "-"}}</div>
                </div>
                <div class="infoItem">
                    <div class="label">入职时间:</div>
                    <div class="value">{{staffInfo.entryTime ? staffInfo.entryTime : "-"}}</div>
                </div>
                <div class="infoItem">
                    <div class="label">微信号:</div>
                    <div class="value">
                        {{staffInfo.weChatName ? staffInfo.weChatName : '-'}}
                        <img v-if="staffInfo.weChatName" src="@/assets/img/wechat.png" alt="" />
                    </div>
                </div>
                <div class="infoItem">
                    <div class="label">手机号:</div>
                    <div class="value">
                        {{staffInfo.phone ? staffInfo.phone : "-"}}
                        <img v-if="staffInfo.phone" src="@/assets/img/tel.png" alt="" />
                    </div>
                </div>
                <!-- <div class="infoItem">
                    <div class="label">邮箱:</div>
                    <div class="value">{{staffInfo.email ? staffInfo.email : "-"}}</div>
                </div> -->
                <el-divider></el-divider>
                <div class="infoItem">
                    <div class="label">企业邮箱:</div>
                    <div class="value">{{staffInfo.enterpriseMailbox}}</div>
                </div>
                <div class="infoItem">
                    <div class="label">部门:</div>
                    <div class="value">
                        <div v-for="ele in staffInfo.departments" :key="ele.key">
                            <span>{{ele.name}}</span>
                            <img v-show="ele.isManager" src="@/assets/img/main.png" alt="" />
                        </div>
                    </div>
                </div>
                <div class="infoItem">
                    <div class="label">职位:</div>
                    <div class="value">{{staffInfo.position ? staffInfo.position : "-"}}</div>
                </div>
                <div class="infoItem">
                    <div class="label">直属上级:</div>
                    <div class="value">{{staffInfo.directSuperiorName ? staffInfo.directSuperiorName : "-"}}</div>
                </div>
                <div class="infoItem">
                    <div class="label">采购模块权限:</div>
                    <div class="value">{{staffInfo.isProcureModel ? "有" : "无"}}</div>
                </div>
                <div class="infoItem">
                    <div class="label">导出客户权限:</div>
                    <div class="value">{{staffInfo.isExport ? "有" : "无"}}</div>
                </div>
            </div>
            <div class="editBox" v-else>
                <div class="formBox">
                    <div class="title">入职人员基本信息</div>
                    <el-form class="content" :model="editForm" label-width="120px" size="small">
                        <el-form-item label="姓名" required>
                            <el-input v-model="editForm.name" class="w220"></el-input>
                        </el-form-item>
                        <el-form-item label="性别">
                            <el-radio-group v-model="editForm.sex">
                                <el-radio :label="1">男</el-radio>
                                <el-radio :label="0">女</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="出生日期">
                            <el-date-picker v-model="editForm.birthday" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
                        </el-form-item>
                        <el-form-item label="手机号" required>
                            <el-input v-model="editForm.phone" class="w220"></el-input>
                        </el-form-item>
                        <!-- <el-form-item label="邮箱">
                            <el-input v-model="editForm.email" class="w220"></el-input>
                        </el-form-item> -->
                    </el-form>
                </div>
                <div class="formBox">
                    <div class="title">人员权限分配</div>
                    <el-form class="content" :model="editForm" label-width="120px" size="small">
                        <el-form-item label="企业邮箱" required>
                            <el-input v-model="editForm.enterpriseMailbox" class="w220" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="入职时间" required>
                            <el-date-picker v-model="editForm.entryTime" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
                        </el-form-item>
                        <el-form-item label="部门" required>
                            <div v-for="(ele,index) in editForm.departments" :key="index">
                                <el-select v-model="ele.name" placeholder="请选择部门" size="small">
                                    <el-option :value="ele.key" style="height: auto">
                                        <el-tree :data="treeData" :props="defaultProps" @node-click="(data)=>chooseDepartment(data,ele)"></el-tree>
                                    </el-option>
                                </el-select>
                                <img class="iconBtn" src="@/assets/img/add2.png" alt="" @click="addDepartment" v-if="index==0" />
                                <img class="iconBtn" src="@/assets/img/jian2.png" alt="" @click="delDepartment(index)" v-else />
                                <span style="color:#5E6D82;">部门负责人：</span>
                                <el-checkbox v-model="ele.isManager">是</el-checkbox>
                            </div>
                        </el-form-item>
                        <el-form-item label="职位" required>
                            <!-- <el-input v-model="editForm.position" class="w220"></el-input> -->
                            <el-select v-model="editForm.position" filterable>
                                <el-option v-for="item in positionOption" :key="item.key" :label="item.name" :value="item.name"></el-option>
                                <div class="toConfig" @click="toConfig">
                                    <i class="el-icon-plus"></i>
                                    <span>配置职位</span>
                                </div>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="直属上级" required>
                            <el-select v-model="editForm.directSuperiorUkey" placeholder="请选择">
                                <el-option v-for="item in superiorOption" :key="item.ukey" :label="item.name" :value="item.ukey">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="采购模块权限">
                            <el-radio v-model="editForm.isProcureModel" :label="true">有</el-radio>
                            <el-radio v-model="editForm.isProcureModel" :label="false">无</el-radio>
                        </el-form-item>
                        <el-form-item label="导出客户权限">
                            <el-radio v-model="editForm.isExport" :label="true">有</el-radio>
                            <el-radio v-model="editForm.isExport" :label="false">无</el-radio>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="submitBtn">
                    <el-button size="small" type="primary" @click="editSubmit" :loading="btnLoading">确 认</el-button>
                    <el-button size="small" @click="editCancel">取 消</el-button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            isAdmin: false,     //操作权限

            staffkey: "",
            staffInfo: {},
            infoLoading: false,
            defaultImg: "https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png",
            //编辑员工信息
            editVisible: false,
            editForm: {},
            btnLoading: false,
            
            //更换头像
            uploadUrl: `${this.$diskUrl}/File/Upload`,
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('token'),
            },
            uploadData: {
                folderKey: "0",
                remarkName: "",
                permission: '我的'
            },
            //组织结构
            treeData: [],
            defaultProps: {
                children: "children",
                label: "name",
            },
            //直属上级选择下拉框
            superiorOption: [],
            //职位配置
            positionOption: [],
        };
    },
    methods: {
        //获取员工详情
        getDetail(){
            this.infoLoading = true;
            this.$axios.get(`${this.$personnelUrl}/Staff/GetUserDetail?ukey=${this.staffkey}`).then((res) => {
                const { isSuccess, data } = res;
                this.infoLoading = false;
                if (isSuccess) {
                    data.headPortraitUrl = data.headPortraitUrl ? data.headPortraitUrl : data.wechatAvatar ? data.wechatAvatar : this.defaultImg;
                    this.staffInfo = data;
                    document.title = `[${data.name}]-员工详情`
                }
            })
        },

        //停用/启用账号
        stopOrStart(){
            this.$confirm(`是否<strong style='color:var(--themeColor);'>${this.staffInfo.isStop ? "启用" : "停用" }</strong>该账号?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                dangerouslyUseHTMLString: true
            }).then(() => {
                this.$axios.get(`${this.$personnelUrl}/Staff/Stop?ukey=${this.staffkey}`).then((res) => {
                    const { isSuccess } = res;
                    if (isSuccess) {
                        this.$message({type: 'success',message: '操作成功!'});
                        this.getDetail();
                    }
                })
            }).catch(() => {});
        },

        //编辑员工信息
        toEditStaff(){
            this.editVisible = true;
            const {ukey,name,email,phone,position,sex,birthday,directSuperiorUkey,entryTime,departments,enterpriseMailbox,isProcureModel,isExport} = this.staffInfo;
            this.editForm = {
                ukey,name,email,phone,position,sex,birthday,directSuperiorUkey,entryTime,departments,enterpriseMailbox,isProcureModel,isExport
            }
        },
        editSubmit(){
            const {name,phone} = this.editForm;
            if(!name){ this.$message({ message: "请填写姓名" , type: 'warning'}); return false; }
            if(!phone){ this.$message({ message: "请填写手机号" , type: 'warning'}); return false; }
            const {enterpriseMailbox,entryTime,departments,position,directSuperiorUkey} = this.editForm;
            if(!enterpriseMailbox){ this.$message({ message: "请填写企业邮箱" , type: 'warning'}); return false;}
            if(!entryTime){ this.$message({ message: "请选择入职时间" , type: 'warning'}); return false;}
            if(departments[0].key == ""){ this.$message({ message: "请选择部门" , type: 'warning'}); return false;}
            if(!position){ this.$message({ message: "请填写职位" , type: 'warning'}); return false;}
            if(!directSuperiorUkey){ this.$message({ message: "请选择上级" , type: 'warning'}); return false;}
            this.editForm.departments = this.editForm.departments.filter(ele => ele.key != "");

            this.btnLoading = true;
            this.$axios.post(`${this.$personnelUrl}/Staff/UpdateUser`,this.editForm).then((res) => {
                const { isSuccess } = res;
                this.btnLoading = false;
                if (isSuccess) {
                    this.editVisible = false;
                    this.$message({type: 'success',message: '编辑成功!'});
                    this.getDetail();
                }
            })
        },
        editCancel(){
            this.editVisible = false;
            this.getDetail();
        },

        //更换头像
        beforeUpload(file) {
            var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
            const isImg = testmsg === "png" || testmsg === "jpg" || testmsg === "jpeg";
            const isLt10M = file.size / 1024 / 1024 < 10;
            if (!isImg) {
                this.$message({ message: "上传文件只能是图片!", type: "warning"});
            }
            if (!isLt10M) {
                this.$message({ message: "上传文件大小不能超过10MB!", type: "warning"});
            }
            return isImg && isLt10M;
        },
        uploadSuccess(response){
            const {isSuccess,data} = response;
            if(isSuccess){
                const {ukey,name,email,phone,position,sex,birthday,directSuperiorUkey,entryTime,departments} = this.staffInfo;
                this.$axios.post(`${this.$personnelUrl}/Staff/UpdateUser`,{
                    ukey,name,email,phone,position,sex,birthday,directSuperiorUkey,entryTime,departments,
                    headPortraitUrl: data.fileUrl,
                }).then((res) => {
                    const { isSuccess } = res;
                    if (isSuccess) {
                        this.$message({type: 'success',message: '更换头像成功!'});
                        this.getDetail();
                    }
                })
            }
        },

        //直属上级选择下拉框
        getUsers(){
            this.$axios.get(`${this.$personnelUrl}/EntryApplication/GetUsers`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.superiorOption = data;
                }
            });
        },
        //获取组织结构
        getTreeData(){
            this.$axios.get(`${this.$personnelUrl}/Organization/GetOrganization`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.treeData = data;
                }
            })
        },
        chooseDepartment(data,ele) {
            ele.key = data.key;
            ele.name = data.name;
        },
        addDepartment(){
            this.editForm.departments.push({ 
                key: "",
                name: "",
                isManager: false
            })
        },
        delDepartment(index){
            this.editForm.departments.splice(index, 1)
        },

        //获取职位配置
        getPosition(){
            this.$axios.get(`${this.$customerUrl}/v1/Config/GetPosition`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.positionOption = data;
                }
            });
        },
        toConfig(){
            let routeData = this.$router.resolve({
                path: "/settingIndex",
                query: { type: "system" },
            });
            window.open(routeData.href, "_blank");
        },
    },
    mounted() {
        const userInfo = JSON.parse(localStorage.userInfo);
        this.isAdmin = userInfo.isAdmin || userInfo.isManager;  //公司管理员和部门经理都可以操作
        this.staffkey = this.$route.query.staffkey;
        this.getDetail();       //获取员工详情
        this.getUsers();        //直属上级选择下拉框
        this.getTreeData();     //获取组织结构
        this.getPosition();     //获取职位配置
    },
};
</script>

<style lang="scss" scoped>
.staffDetails{
    background: #FFFFFF;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    height: calc(100vh - 120px);
    padding: 20px;
    overflow: auto;
    .operation{
        margin-bottom: 30px;
    }
    .headportrait{
        width: 120px;
        text-align: center;
    }
    .infoBox{
        flex-grow: 1;
        margin-left: 30px;
        .infoTop{
            line-height: 40px;
            margin-bottom: 10px;
            color: #092338;
            .name{
                width: 80px;
                display: inline-block;
                text-align: right;
                font-size: 20px;
                font-weight: bold;
            }
            .account{
                font-size: 14px;
                padding-left: 20px;
            }
        }
        .infoItem{
            font-size: 14px;
            line-height: 32px;
            display: flex;
            .label{
                width: 100px;
                display: inline-block;
                text-align: right;
                color: #858585;
            }
            .value{
                margin-left: 20px;
                display: inline-block;
                color: #092338;
                img{
                    vertical-align: middle;
                    padding-left: 10px;
                }
            }
        }
    }
    .editBox{
        flex-grow: 1;
        margin-left: 30px;
        .formBox {
            margin-top: 20px;
            .title {
                margin-bottom: 20px;
                line-height: 16px;
                font-size: 16px;
                font-weight: bold;
                color: #2a3f58;
                padding-left: 10px;
                border-left: 4px solid var(--themeColor);
            }
            .content {
                .w220{
                    width: 220px;
                }
                .iconBtn{
                    vertical-align: middle;
                    cursor: pointer;
                    margin-left: 20px;
                    margin-right: 20px;
                }
            }
        }
        .submitBtn {
            border-top: 1px solid #e0e6ed;
            padding-top: 20px;
            padding-left: 40px;
        }
    }
}
.toConfig{
    cursor: pointer;
    text-align: center;
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    color: #6a6a6a;
    &:hover{
        background-color: #F5F7FA;
    }
}
</style>