<template>
<div>
    <div v-if="ie=='i'">
        <!-- 乌兹别克斯坦(UZB)进口提单明细 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th width="25%">进口商ID</th>
                        <td width="25%">{{billInfo.ImporterCode}}</td>
                        <th width="25%">数量单位</th>
                        <td width="25%">{{billInfo.QuantityUnit}}</td>
                    </tr>
                    <tr>
                        <th>发票金额（美元）</th>
                        <td>{{billInfo.Value}}</td>
                        <th>发票单价（美元）</th>
                        <td>{{billInfo.UnitPrice}}</td>
                    </tr>
                    <tr>
                        <th>海关价值（美元）</th>
                        <td>{{billInfo.CustomsValue}}</td>
                        <th>统计价值（美元）</th>
                        <td>{{billInfo.StatValueUsd}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">运输信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">原产国</th>
                        <td width="25%">{{billInfo.OriginCountryCode}}</td>
                        <th width="25%">原产地</th>
                        <td width="25%">{{billInfo.OriginPlace}}</td>
                    </tr>
                    <tr>
                        <th>中转国</th>
                        <td>{{billInfo.Country}}</td>
                        <th>海关制度</th>
                        <td>{{billInfo.DeclarationType}}</td>
                    </tr>
                    <tr>
                        <th>监督方法</th>
                        <td>{{billInfo.CargoMovementTypeName}}</td>
                        <th>成交方式</th>
                        <td>{{billInfo.DeliverConditionLetter}}</td>
                    </tr>
                    <tr>
                        <th>运输方式</th>
                        <td colspan="3">{{billInfo.TransportType}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
    <div v-if="ie=='e'">
        <!-- 乌兹别克斯坦(UZB)出口提单明细 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th width="25%">出口商ID</th>
                        <td width="25%">{{billInfo.ExporterCode}}</td>
                        <th width="25%">数量单位</th>
                        <td width="25%">{{billInfo.QuantityUnit}}</td>
                    </tr>
                    <tr>
                        <th>发票金额（美元）</th>
                        <td>{{billInfo.Value}}</td>
                        <th>发票单价（美元）</th>
                        <td>{{billInfo.UnitPrice}}</td>
                    </tr>
                    <tr>
                        <th>海关价值（美元）</th>
                        <td>{{billInfo.CustomsValue}}</td>
                        <th>统计价值（美元）</th>
                        <td>{{billInfo.StatValueUsd}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">运输信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">目的国</th>
                        <td width="25%">{{billInfo.Country}}</td>
                        <th width="25%">目的地区</th>
                        <td width="25%">{{billInfo.DestPlace}}</td>
                    </tr>
                    <tr>
                        <th>中转国</th>
                        <td>{{billInfo.OriginCountryCode}}</td>
                        <th>海关制度</th>
                        <td>{{billInfo.DeclarationTypeCode}}</td>
                    </tr>
                    <tr>
                        <th>监督方法</th>
                        <td>{{billInfo.CargoMovementTypeName}}</td>
                        <th>成交方式</th>
                        <td>{{billInfo.DeliverConditionLetter}}</td>
                    </tr>
                    <tr>
                        <th>运输方式</th>
                        <td colspan="3">{{billInfo.TransportType}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">其他信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">进出口类型</th>
                        <td width="25%">{{billInfo.Type}}</td>
                        <th width="25%">进出口类型代码</th>
                        <td width="25%">{{billInfo.TypeCode}}</td>
                    </tr>
                    <tr>
                        <th>声明类型名称</th>
                        <td>{{billInfo.DeclarationType}}</td>
                        <th>上一个声明类型</th>
                        <td>{{billInfo.PrevDeclarationTypeCode}}</td>
                    </tr>
                    <tr>
                        <th>货物运输类型代码</th>
                        <td>{{billInfo.CargoMovementTypeCode}}</td>
                        <th>上一个声明类型名称</th>
                        <td>{{billInfo.PrevDeclarationType}}</td>
                    </tr>
                    <tr>
                        <th>交付条件编号代码</th>
                        <td>{{billInfo.DeliverConditionNumber}}</td>
                        <th>交付条件名称</th>
                        <td>{{billInfo.DeliverConditionName}}</td>
                    </tr>
                    <tr>
                        <th>交付条件名称</th>
                        <td colspan="3">{{billInfo.TransportTypeCode}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
</div>
</template>

<script>
export default {
    props:["billInfo","ie"],
    data() {
        return {

        };
    },
    methods: {
        
    },
    mounted() {

    },
};
</script>

<style lang="scss" scoped>
.partItem {
    margin-bottom: 20px;
    /deep/.el-card__header {
        padding: 10px 20px;
        .headTitle {
            display: inline-block;
            padding-left: 10px;
            font-size: 14px;
            border-left: 2px solid var(--themeColor);
            font-weight: bold;
        }
    }
}
table {
    width: 100%;
    border-collapse: collapse;
    tr {
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;
        th,
        td {
            font-size: 12px;
            color: #666;
            height: 40px;
            border: 1px solid #eaeaea;
            text-align: center;
        }
        th {
            background-color: #f5f7fa;
            font-weight: 700;
        }
        .bg {
            background-color: var(--themeColor);
            color: #fff;
            font-weight: 700;
            opacity: 0.8;
        }
    }
}
</style>