<template>
    <div>
        <el-card v-loading="infoLoading">
            <div class="topInfo">
                <div class="productImg">
                    <el-image :src="detailInfo.imgs[0]" :preview-src-list="detailInfo.imgs"></el-image>
                    <span class="tagnum">{{detailInfo.imgs.length}}</span>
                </div>
                <div class="infoItem">
                    <div class="companyName">
                        <span>{{detailInfo.companyName}}</span>
                        <div class="iconImg" v-for="ele in detailInfo.socialMedias" :key="ele.socialMediaType">
                            <el-tooltip effect="dark" :content="ele.socialMediaUrl" placement="top">
                                <img :src="require(`@/assets/ai/${ele.socialMediaType}.png`)" alt="" @click="openWebsite(ele.socialMediaUrl)" />
                            </el-tooltip>
                        </div>
                        <el-tag size="small" v-if="detailInfo.isMyClue" style="margin-left:30px;">已加入我的线索</el-tag>
                    </div>
                    <div style="margin-top:5px;">
                        <div class="field">
                            <span>行业：</span>
                            <span>{{detailInfo.industry}}</span>
                        </div>
                        <div class="field">
                            <span>成立时间：</span>
                            <span>{{detailInfo.established}}</span>
                        </div>
                        <div class="field">
                            <span>网址：</span>
                            <span class="primaryColor" @click="openWebsite(detailInfo.website)">{{detailInfo.website}}</span>
                        </div>
                        <div class="field">
                            <span>国家：</span>
                            <img :src="detailInfo.flagUrl" style="margin-right:5px;height:14px" />
                            <span>{{detailInfo.country}}</span>
                        </div>
                    </div>
                    <div style="margin-top:5px;">
                        <div class="field">
                            <span>联系人数：</span>
                            <span>{{detailInfo.contactsNum}}</span>
                        </div>
                        <div class="field">
                            <span>地址：</span>
                            <span>{{detailInfo.address}}</span>
                        </div>
                    </div>
                    <div style="margin-top:5px;">
                        <el-tag v-for="ele in detailInfo.keywords" :key="ele" type="warning" effect="dark" size="mini">{{ele}}</el-tag>
                    </div>
                </div>
                <div class="operation">
                    <div v-if="!detailInfo.isMyClue">
                        <el-button style="width:120px;" type="primary" size="small" @click="addMyClue(detailInfo)" :loading="btnLoading">
                            加入我的线索
                        </el-button>
                    </div>
                </div>
            </div>
        </el-card>

        <div class="tabCard" v-loading="infoLoading">
            <el-tabs>
                <el-tab-pane label="公司信息">
                    <div class="companyInfo" v-if="isShowInfo">
                        <div class="oneItem" v-if="graphJson.title">
                            <span class="key">title：</span>
                            <span class="value">{{graphJson.title}}</span>
                        </div>
                        <div class="oneItem" v-if="graphJson.type">
                            <span class="key">type：</span>
                            <span class="value">{{graphJson.type}}</span>
                        </div>
                        <div class="oneItem" v-if="graphJson.description">
                            <span class="key">description：</span>
                            <span class="value">{{graphJson.description}}</span>
                            <el-link v-if="graphJson.source" :href="graphJson.source.link" target="_blank" type="primary" :underline="false">{{graphJson.source.name}}</el-link>
                        </div>
                        <div class="oneItem" v-for="ele in dictionarys" :key="ele.key">
                            <span class="key">{{ele.key}}：</span>
                            <span class="value">{{ele.value}}</span>
                        </div>
                    </div>
                    <el-empty v-else :image-size="200" description="暂无数据"></el-empty>
                </el-tab-pane>
                <el-tab-pane label="联系人信息">
                    <div slot="label">
                        <span>联系人信息</span>
                        <span>({{contactTotal || "无"}})</span>
                    </div>
                    <div class="contactBox" v-loading="tableLoading">
                        <el-empty v-if="contactTotal==0" :image-size="200" description="暂无联系人"></el-empty>
                        <el-table v-else :data="contactList" border :header-cell-style="{'background-color':'#F8FAFF','color':'#1F2D3D'}">
                            <el-table-column prop="name" label="联系人"></el-table-column>
                            <el-table-column prop="email" label="邮箱"></el-table-column>
                            <el-table-column prop="position" label="职位"></el-table-column>
                        </el-table>
                        <el-pagination v-if="contactTotal > 10" background layout="total,prev,pager,next" :total="contactTotal" style="float:right;margin-top:15px;" :current-page.sync="contactPageIndex" @current-change="contactPageChange"></el-pagination>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="新闻信息">
                    <News />
                </el-tab-pane>
                <el-tab-pane label="招聘信息">
                    <RecruitInfo />
                </el-tab-pane>
                <el-tab-pane label="海关数据">
                    <div class="customsBox" v-if="marketAnalysis.length != 0 || productAnalysis.length != 0 || vendorAnalysis.length != 0">
                        <div class="sublevel">（1）市场分析 - 共 {{marketAnalysis.length}} 条</div>
                        <el-table :data="marketAnalysis.slice(10*(this.marketPageIndex-1),10*this.marketPageIndex)" :header-cell-style="{'background-color':'#E5E9F2','color':'#1F2D3D'}">
                            <el-table-column type="index" width="50" align="center"></el-table-column>
                            <el-table-column prop="name" label="国家"></el-table-column>
                            <el-table-column prop="frequency" label="频次"></el-table-column>
                            <el-table-column prop="value" label="金额"></el-table-column>
                            <el-table-column prop="quantity" label="数量"></el-table-column>
                            <el-table-column prop="weight" label="重量"></el-table-column>
                            <el-table-column prop="proportion" label="频次占比" width="320px">
                                <template slot-scope="scope">
                                    <el-progress class="progress" color="#746B63" :show-text="false" :stroke-width="16" :percentage="scope.row.proportion*1"></el-progress>
                                    <span>{{scope.row.proportion+"%"}}</span>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div style="overflow:hidden;">
                            <el-pagination background layout="total,prev,pager,next" :total="marketAnalysis.length" style="float:right;margin-top:15px;" :current-page.sync="marketPageIndex"></el-pagination>
                        </div>
                        <div class="sublevel">（2）产品分析 - 共 {{productAnalysis.length}} 条</div>
                        <el-table :data="productAnalysis.slice(10*(this.productPageIndex-1),10*this.productPageIndex)" :header-cell-style="{'background-color':'#E5E9F2','color':'#1F2D3D'}">
                            <el-table-column type="index" width="50" align="center"></el-table-column>
                            <el-table-column prop="name" label="HS编码"></el-table-column>
                            <el-table-column prop="frequency" label="频次"></el-table-column>
                            <el-table-column prop="value" label="金额"></el-table-column>
                            <el-table-column prop="quantity" label="数量"></el-table-column>
                            <el-table-column prop="weight" label="重量"></el-table-column>
                            <el-table-column prop="proportion" label="频次占比" width="320px">
                                <template slot-scope="scope">
                                    <el-progress class="progress" color="#E99019" :show-text="false" :stroke-width="16" :percentage="scope.row.proportion*1"></el-progress>
                                    <span>{{scope.row.proportion+"%"}}</span>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div style="overflow:hidden;">
                            <el-pagination background layout="total,prev,pager,next" :total="productAnalysis.length" style="float:right;margin-top:15px;" :current-page.sync="productPageIndex"></el-pagination>
                        </div>
                        <div class="sublevel">（3）供应商分析 - 共 {{vendorAnalysis.length}} 条</div>
                        <el-table :data="vendorAnalysis.slice(10*(this.vendorPageIndex-1),10*this.vendorPageIndex)" :header-cell-style="{'background-color':'#E5E9F2','color':'#1F2D3D'}">
                            <el-table-column type="index" width="50" align="center"></el-table-column>
                            <el-table-column prop="name" label="供应商"></el-table-column>
                            <el-table-column prop="frequency" label="频次"></el-table-column>
                            <el-table-column prop="value" label="金额"></el-table-column>
                            <el-table-column prop="quantity" label="数量"></el-table-column>
                            <el-table-column prop="weight" label="重量"></el-table-column>
                            <el-table-column prop="proportion" label="频次占比" width="320px">
                                <template slot-scope="scope">
                                    <el-progress class="progress" color="#B28B7C" :show-text="false" :stroke-width="16" :percentage="scope.row.proportion*1"></el-progress>
                                    <span>{{scope.row.proportion+"%"}}</span>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div style="overflow:hidden;">
                            <el-pagination background layout="total,prev,pager,next" :total="vendorAnalysis.length" style="float:right;margin-top:15px;" :current-page.sync="vendorPageIndex"></el-pagination>
                        </div>
                    </div>
                    <el-empty v-else :image-size="200" description="暂无海关数据"></el-empty>
                </el-tab-pane>
                <el-tab-pane label="产品图片">
                    <div slot="label">
                        <span>产品图片</span>
                        <span>({{imgUrlList.length || "无"}})</span>
                    </div>
                    <div class="imgUrlBox" v-if="imgUrlList.length > 0">
                        <el-image v-for="ele in imgUrlList" :key="ele" :src="ele" :preview-src-list="imgUrlList"></el-image>
                    </div>
                    <el-empty v-else :image-size="200" description="暂无产品图片"></el-empty>
                </el-tab-pane>
            </el-tabs>
        </div>

        <!-- 相关公司信息 -->

        
        <div class="correlationCompany" v-if="correlationList.length != 0">
            <div class="correlationItem" v-for="(item,index) in correlationList" :key="index" @click="openLink(item.link)">
                <div class="imageBox">
                    <el-image :src="item.image"></el-image>
                </div>
                <el-link type="primary">{{item.name}}</el-link>
            </div>
            <div style="margin-top:20px;">
                <el-link type="primary" :href="correlationLink" target="_blank">查看更多</el-link>
            </div>
        </div>
 

    </div>
</template>

<script>
import News from "./components/News.vue"
import RecruitInfo from "./components/RecruitInfo.vue"
export default {
    components: { News, RecruitInfo },
    data() {
        return {
            companyName: "",
            detailInfo: {
                imgs: [],
                isMyClue: false,
            },
            infoLoading: false,
            btnLoading: false,
            //海关数据
            marketAnalysis: [],
            productAnalysis: [],
            vendorAnalysis: [],
            marketPageIndex: 1,
            productPageIndex: 1,
            vendorPageIndex: 1,
            //联系人
            tableLoading: false,
            contactList: [],
            contactTotal: 0,
            contactPageIndex: 1,
            //产品图片
            imgUrlList: [],
            //相关公司信息
            correlationList:[],
            correlationLink: "",
            //新增公司信息
            isShowInfo: false,
            graphJson: {},
            dictionarys: [],
        };
    },
    methods: {
        //打开网址
        openWebsite(url) {
            let jumpUrl = url.indexOf("http") > -1 ? url : "http://" + url;
            let routeData = this.$router.resolve({
                path: "/jumpWeb",
                query: { jumpUrl: jumpUrl },
            });
            window.open(routeData.href, "_blank");
        },
        //加入我的线索
        addMyClue(item) {
            let submitObj = {
                clueSource: 7, //来源：AI推荐
                clueRelationKey: "",
                companyName: item.companyName,
                country: item.country,
                legalPerson: "",
                contactNumber: "",
                registrationTime: item.established,
                employeesNumber: item.contactsNum,
                officialWebsite: item.website,
                corporateEmail: "",
                businessAddress: item.address,
                companyProfile: "",
                industrys: item.industry ? [item.industry] : [],
            };
            this.btnLoading = true;
            this.$axios.post(`${this.$clueUrl}/MyClue/AddMyClue`, submitObj).then((res) => {
                const { isSuccess } = res;
                this.btnLoading = false;
                if (isSuccess) {
                    this.$message({ message: "添加成功", type: "success" });
                    this.detailInfo.isMyClue = true;
                }
            });
        },
        openLink(link){
            window.open(link);
        },

        // 获取公司详情信息
        getCompanyDetail() {
            this.infoLoading = true;
            this.$axios.get(`${this.$clueUrl}/Recommendation/GetRECCompanyDetail?companyName=${this.companyName}`).then((res) => {
                const { isSuccess, data } = res;
                this.infoLoading = false;
                if (isSuccess) {
                    data.flagUrl = window.getFlagUrlByCountry ? window.getFlagUrlByCountry(data.country) : "";
                    this.detailInfo = data;
                    this.imgUrlList = data.imgs;
                    // 获取公司联系人
                    this.getContacts();
                    // 获取相关公司信息
                    if(data.graphJson){
                        this.isShowInfo = true;
                        let graphJson = JSON.parse(data.graphJson);
                        this.graphJson = graphJson;
                        if(graphJson.people_also_search_for){
                            this.correlationList = graphJson.people_also_search_for;
                            this.correlationLink = graphJson.people_also_search_for_link;
                        }
                    }
                    // console.log(this.correlationList);
                    // console.log(this.correlationLink);
                    this.dictionarys = data.dictionarys;
                }
            });
        },

        // 获取海关数据(市场分析,产品分析,供应商分析)
        getCustomsData() {
            // 先获取SearchKey
            this.$axios.get(`${this.$clueUrl}/BuyerLeads/GetSearchKey?company=${this.companyName}`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    let searchKey = data;
                    // 通过SearchKey获取 市场分析数据
                    this.$axios
                        .get(
                            `${this.$clueUrl}/BuyerLeads/GetCountryRanking?searchKey=${searchKey}`
                        )
                        .then((res) => {
                            const { isSuccess, data } = res;
                            if (isSuccess) {
                                data.forEach((element) => {
                                    element.value =
                                        element.value.toFixed(2);
                                    element.quantity =
                                        element.quantity.toFixed(2);
                                    element.weight =
                                        element.weight.toFixed(2);
                                });
                                this.marketAnalysis = data;
                            }
                        });
                    // 通过SearchKey获取 产品分析数据
                    this.$axios
                        .get(
                            `${this.$clueUrl}/BuyerLeads/GetHsCodeRanking?searchKey=${searchKey}`
                        )
                        .then((res) => {
                            const { isSuccess, data } = res;
                            if (isSuccess) {
                                data.forEach((element) => {
                                    element.value =
                                        element.value.toFixed(2);
                                    element.quantity =
                                        element.quantity.toFixed(2);
                                    element.weight =
                                        element.weight.toFixed(2);
                                });
                                this.productAnalysis = data;
                            }
                        });
                    // 通过SearchKey获取 供应商分析数据
                    this.$axios
                        .get(
                            `${this.$clueUrl}/BuyerLeads/GetImporterRanking?searchKey=${searchKey}`
                        )
                        .then((res) => {
                            const { isSuccess, data } = res;
                            if (isSuccess) {
                                data.forEach((element) => {
                                    element.value =
                                        element.value.toFixed(2);
                                    element.quantity =
                                        element.quantity.toFixed(2);
                                    element.weight =
                                        element.weight.toFixed(2);
                                });
                                this.vendorAnalysis = data;
                            }
                        });
                }
            });
        },

        // 获取公司联系人
        getContacts(){
            this.tableLoading = true;
            this.$axios.post(`${this.$clueUrl}/Recommendation/GetRECContacts`,{
                "domain": this.detailInfo.website,
                "pageIndex": this.contactPageIndex,
                "pageSize": 10
            }).then((res) => {
                const { isSuccess, data } = res;
                this.tableLoading = false;
                if (isSuccess) {
                    this.contactList = data.datas;
                    this.contactTotal = data.totalItems;
                }
            });
        },
        contactPageChange(page){
            this.contactPageIndex = page;
            this.getContacts();
        },
    },
    mounted() {
        this.companyName = encodeURIComponent(this.$route.query.companyName);
        this.getCompanyDetail();    // 获取公司详情信息
        this.getCustomsData();      // 获取海关数据
    },
};
</script>

<style lang="scss" scoped>
@import '@/scss/common.scss';
.topInfo {
    display: flex;
    .productImg {
        border: 1px solid rgba($color: #000, $alpha: 0.1);
        width: 120px;
        height: 120px;
        margin-right: 20px;
        position: relative;
        cursor: pointer;
        .el-image {
            width: 120px;
            height: 120px;
        }
        .tagnum {
            position: absolute;
            background: rgba($color: #000, $alpha: 0.5);
            color: #fff;
            width: 30px;
            height: 24px;
            text-align: center;
            right: 0;
            bottom: 0;
        }
    }
    .infoItem {
        width: calc(100% - 260px);
        color: #1a2745;
        .companyName {
            font-size: 18px;
            line-height: 30px;
            font-weight: bold;
            text-decoration: underline;
            .iconImg {
                margin-left: 10px;
                cursor: pointer;
                display: inline-block;
            }
        }
        .field {
            margin-right: 30px;
            font-size: 14px;
            display: inline-block;
            .primaryColor {
                color: $themeColor;
                cursor: pointer;
            }
        }
    }
    .operation {
        width: 120px;
    }
}
.tabCard {
    margin-top: 20px;
    background: #fff;
    padding: 0 20px 20px;
    min-height: 400px;
}
.customsBox {
    background: #ffffff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    padding: 15px 20px;
    .title {
        font-size: 20px;
        height: 20px;
        line-height: 20px;
        font-weight: bold;
        color: #3a3a3a;
        padding-left: 8px;
        border-left: 3px solid $themeColor;
        margin-bottom: 15px;
    }
    .sublevel {
        // margin-top: 20px;
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 20px;
        font-weight: bold;
        color: #3a3a3a;
    }
    .progress {
        width: 240px;
        display: inline-block;
    }
}
.contactBox{
    overflow:hidden;
}
.imgUrlBox{
    width: 100%;
    .el-image{
        width: 120px;
        height: 120px;
        margin-right: 20px;
        margin-bottom: 20px;
    }
}
.correlationCompany{
    display: flex;
    justify-content : center;
    margin-top: 20px;
    padding: 20px;
    // background-color: #fff;
    .correlationItem{
        width: 72px;
        margin-right: 20px;
        cursor: pointer;
        .el-image{
            display: block;
            width: 72px;
            height: 72px;
            border-radius: 8px;
        }
    }
}
.companyInfo{
    .oneItem{
        line-height: 32px;
        .key{
            color: #6c6c6c;
        }
        .value{
            color: #3a3a3a;
        }
        .el-link{
            margin-left: 20px;
        }
    }
}
</style>