<template>
    <div>
        <InterfaceName :interfaceName="interfaceName" />
        <InterfaceSpe :requestUrl="requestUrl" :requestMethod="requestMethod" />
        <InputParam :inputTableData="inputTableData" />
        <ReturnParam :returnObj="returnObj" />
    </div>
</template>

<script>
import InterfaceName from "../Acomponents/InterfaceName.vue";
import InterfaceSpe from "../Acomponents/InterfaceSpe.vue";
import InputParam from "../Acomponents/InputParam.vue";
import ReturnParam from "../Acomponents/ReturnParam.vue";

export default {
    components: { InterfaceName, InterfaceSpe, InputParam, ReturnParam },
    data() {
        return {
            interfaceName: "查询用户邮件",
            requestUrl: "https://crm.qixinerp.com/open/Email/GetEmails",
            requestMethod: "POST",
            inputTableData: [],
            returnObj: {},
        };
    },
    methods: {},
    mounted() {
        this.inputTableData = [
            {
                paramName: "email",
                valueType: "string",
                explain: "邮箱",
                required: true,
            },
            {
                paramName: "ukey",
                valueType: "string",
                explain: "用户key",
                required: true,
            },
            {
                paramName: "pageIndex",
                valueType: "integer",
                explain: "当前页码",
                required: true,
            },
            {
                paramName: "pageSize",
                valueType: "integer",
                explain: "每页条数",
                required: true,
            },
        ];
        this.returnObj = {
            isSuccess: true,
            errorMessage: null,
            data: {
                datas: [
                    {
                        key: "622dd099-9a09-449d-b4cb-991b5f7bb9cb",
                        recipients: null,
                        cc: null,
                        bcc: null,
                        subject: "test",
                        eMail: "pxx.peng@lsmaps.com",
                        fromMail: null,
                        htmlContent: null,
                        attachments: null,
                        lables: [],
                        createDate: "2022-05-26 11:03:39",
                        isOpen: true,
                        isCollection: false,
                    },
                    {
                        key: "d0e3cd19-4b15-4a35-b241-d9f0cc7ba4d7",
                        recipients: null,
                        cc: null,
                        bcc: null,
                        subject: "test",
                        eMail: "pxx.peng@lsmaps.com",
                        fromMail: null,
                        htmlContent: null,
                        attachments: null,
                        lables: [],
                        createDate: "2022-05-18 10:57:19",
                        isOpen: true,
                        isCollection: false,
                    },
                    {
                        key: "3eb730d4-d8c5-482b-8bd4-149e677fb75c",
                        recipients: null,
                        cc: null,
                        bcc: null,
                        subject: "test",
                        eMail: "pxx.peng@lsmaps.com",
                        fromMail: null,
                        htmlContent: null,
                        attachments: null,
                        lables: [],
                        createDate: "2022-05-17 09:34:24",
                        isOpen: true,
                        isCollection: false,
                    },
                    {
                        key: "4c83b91f0a4c42b29afa560d79bdf65b",
                        recipients: null,
                        cc: null,
                        bcc: null,
                        subject: "回复：贸易数据更新",
                        eMail: "pxx.peng@lsmaps.com",
                        fromMail: "miao.du@lsmaps.com",
                        htmlContent: null,
                        attachments: null,
                        lables: [],
                        createDate: "2022-05-12 09:15:51",
                        isOpen: false,
                        isCollection: false,
                    },
                    {
                        key: "17bf3caf9291434fa6dd8ab3ccc4c399",
                        recipients: null,
                        cc: null,
                        bcc: null,
                        subject: "贸易数据更新",
                        eMail: "pxx.peng@lsmaps.com",
                        fromMail: "kang.song@lsmaps.com",
                        htmlContent: null,
                        attachments: null,
                        lables: [],
                        createDate: "2022-05-11 19:23:04",
                        isOpen: false,
                        isCollection: false,
                    },
                    {
                        key: "f8bf4c4a5a664583bc5993536b0e98c6",
                        recipients: null,
                        cc: null,
                        bcc: null,
                        subject: "【OA：接口请求服务端报错提醒】",
                        eMail: "pxx.peng@lsmaps.com",
                        fromMail: "yuan.shen@lsmaps.com",
                        htmlContent: null,
                        attachments: null,
                        lables: [],
                        createDate: "2022-05-11 17:52:25",
                        isOpen: true,
                        isCollection: false,
                    },
                    {
                        key: "3936470aad0d4e36bd03efa39fdcfc52",
                        recipients: null,
                        cc: null,
                        bcc: null,
                        subject: "【OA：接口请求服务端报错提醒】",
                        eMail: "pxx.peng@lsmaps.com",
                        fromMail: "yuan.shen@lsmaps.com",
                        htmlContent: null,
                        attachments: null,
                        lables: [],
                        createDate: "2022-05-11 17:46:15",
                        isOpen: true,
                        isCollection: false,
                    },
                    {
                        key: "100550d7366a4b35bf4fff520f80cdfc",
                        recipients: null,
                        cc: null,
                        bcc: null,
                        subject: "【OA：接口请求服务端报错提醒】",
                        eMail: "pxx.peng@lsmaps.com",
                        fromMail: "yuan.shen@lsmaps.com",
                        htmlContent: null,
                        attachments: null,
                        lables: [],
                        createDate: "2022-05-11 17:46:15",
                        isOpen: false,
                        isCollection: false,
                    },
                    {
                        key: "bb6f7a56b2f34c55babdfaa6f6e1066b",
                        recipients: null,
                        cc: null,
                        bcc: null,
                        subject: "【OA：接口请求服务端报错提醒】",
                        eMail: "pxx.peng@lsmaps.com",
                        fromMail: "yuan.shen@lsmaps.com",
                        htmlContent: null,
                        attachments: null,
                        lables: [],
                        createDate: "2022-05-11 17:45:21",
                        isOpen: false,
                        isCollection: false,
                    },
                    {
                        key: "97b4e3809d55408d92de74fe52aecfa2",
                        recipients: null,
                        cc: null,
                        bcc: null,
                        subject: "【OA：接口请求服务端报错提醒】",
                        eMail: "pxx.peng@lsmaps.com",
                        fromMail: "yuan.shen@lsmaps.com",
                        htmlContent: null,
                        attachments: null,
                        lables: [],
                        createDate: "2022-05-11 17:45:15",
                        isOpen: false,
                        isCollection: false,
                    },
                ],
                pageSize: 10,
                pageIndex: 1,
                totalItems: 9190,
            },
        };
    },
};
</script>

<style lang="scss" scoped>

</style>