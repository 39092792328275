<template>
    <div>
        <div style="margin-bottom:20px;display:flex;">
            <el-input v-model="customsDataName" style="width:320px" size="small"></el-input>
            <el-button type="primary" plain @click="onSave" size="small">保 存</el-button>
        </div>
        <div class="customsBox" v-if="marketAnalysis.length != 0 || productAnalysis.length != 0 || vendorAnalysis.length != 0">
            <div class="sublevel">（1）市场分析 - 共 {{marketAnalysis.length}} 条</div>
            <el-table :data="marketAnalysis.slice(10*(this.marketPageIndex-1),10*this.marketPageIndex)" :header-cell-style="{'background-color':'#E5E9F2','color':'#1F2D3D'}">
                <el-table-column type="index" width="50" align="center"></el-table-column>
                <el-table-column prop="name" label="国家"></el-table-column>
                <el-table-column prop="frequency" label="频次"></el-table-column>
                <el-table-column prop="value" label="金额"></el-table-column>
                <el-table-column prop="quantity" label="数量"></el-table-column>
                <el-table-column prop="weight" label="重量"></el-table-column>
                <el-table-column prop="proportion" label="频次占比" width="320px">
                    <template slot-scope="scope">
                        <el-progress class="progress" color="#746B63" :show-text="false" :stroke-width="16" :percentage="scope.row.proportion*1"></el-progress>
                        <span>{{scope.row.proportion+"%"}}</span>
                    </template>
                </el-table-column>
            </el-table>
            <div style="overflow:hidden;">
                <el-pagination background layout="total,prev,pager,next" :total="marketAnalysis.length" style="float:right;margin-top:15px;" :current-page.sync="marketPageIndex"></el-pagination>
            </div>
            <div class="sublevel">（2）产品分析 - 共 {{productAnalysis.length}} 条</div>
            <el-table :data="productAnalysis.slice(10*(this.productPageIndex-1),10*this.productPageIndex)" :header-cell-style="{'background-color':'#E5E9F2','color':'#1F2D3D'}">
                <el-table-column type="index" width="50" align="center"></el-table-column>
                <el-table-column prop="name" label="HS编码"></el-table-column>
                <el-table-column prop="frequency" label="频次"></el-table-column>
                <el-table-column prop="value" label="金额"></el-table-column>
                <el-table-column prop="quantity" label="数量"></el-table-column>
                <el-table-column prop="weight" label="重量"></el-table-column>
                <el-table-column prop="proportion" label="频次占比" width="320px">
                    <template slot-scope="scope">
                        <el-progress class="progress" color="#E99019" :show-text="false" :stroke-width="16" :percentage="scope.row.proportion*1"></el-progress>
                        <span>{{scope.row.proportion+"%"}}</span>
                    </template>
                </el-table-column>
            </el-table>
            <div style="overflow:hidden;">
                <el-pagination background layout="total,prev,pager,next" :total="productAnalysis.length" style="float:right;margin-top:15px;" :current-page.sync="productPageIndex"></el-pagination>
            </div>
            <div class="sublevel">（3）供应商分析 - 共 {{vendorAnalysis.length}} 条</div>
            <el-table :data="vendorAnalysis.slice(10*(this.vendorPageIndex-1),10*this.vendorPageIndex)" :header-cell-style="{'background-color':'#E5E9F2','color':'#1F2D3D'}">
                <el-table-column type="index" width="50" align="center"></el-table-column>
                <el-table-column prop="name" label="供应商"></el-table-column>
                <el-table-column prop="frequency" label="频次"></el-table-column>
                <el-table-column prop="value" label="金额"></el-table-column>
                <el-table-column prop="quantity" label="数量"></el-table-column>
                <el-table-column prop="weight" label="重量"></el-table-column>
                <el-table-column prop="proportion" label="频次占比" width="320px">
                    <template slot-scope="scope">
                        <el-progress class="progress" color="#B28B7C" :show-text="false" :stroke-width="16" :percentage="scope.row.proportion*1"></el-progress>
                        <span>{{scope.row.proportion+"%"}}</span>
                    </template>
                </el-table-column>
            </el-table>
            <div style="overflow:hidden;">
                <el-pagination background layout="total,prev,pager,next" :total="vendorAnalysis.length" style="float:right;margin-top:15px;" :current-page.sync="vendorPageIndex"></el-pagination>
            </div>
        </div>
        <el-empty v-else :image-size="200" description="暂无海关数据"></el-empty>
    </div>
</template>

<script>
export default {
    props: ["companyName"],
    data() {
        return {
            //海关数据
            marketAnalysis: [],
            productAnalysis: [],
            vendorAnalysis: [],
            marketPageIndex: 1,
            productPageIndex: 1,
            vendorPageIndex: 1,
            customsDataName: "",
        };
    },
    methods: {
        // 获取海关数据(市场分析,产品分析,供应商分析)
        getCustomsData() {
            // 先获取SearchKey
            this.$axios.get(`${this.$clueUrl}/BuyerLeads/GetSearchKey?company=${encodeURIComponent(this.customsDataName)}`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    let searchKey = data;
                    // 通过SearchKey获取 市场分析数据
                    this.$axios
                        .get(
                            `${this.$clueUrl}/BuyerLeads/GetCountryRanking?searchKey=${searchKey}`
                        )
                        .then((res) => {
                            const { isSuccess, data } = res;
                            if (isSuccess) {
                                data.forEach((element) => {
                                    element.value =
                                        element.value.toFixed(2);
                                    element.quantity =
                                        element.quantity.toFixed(2);
                                    element.weight =
                                        element.weight.toFixed(2);
                                });
                                this.marketAnalysis = data;
                            }
                        });
                    // 通过SearchKey获取 产品分析数据
                    this.$axios
                        .get(
                            `${this.$clueUrl}/BuyerLeads/GetHsCodeRanking?searchKey=${searchKey}`
                        )
                        .then((res) => {
                            const { isSuccess, data } = res;
                            if (isSuccess) {
                                data.forEach((element) => {
                                    element.value =
                                        element.value.toFixed(2);
                                    element.quantity =
                                        element.quantity.toFixed(2);
                                    element.weight =
                                        element.weight.toFixed(2);
                                });
                                this.productAnalysis = data;
                            }
                        });
                    // 通过SearchKey获取 供应商分析数据
                    this.$axios
                        .get(
                            `${this.$clueUrl}/BuyerLeads/GetImporterRanking?searchKey=${searchKey}`
                        )
                        .then((res) => {
                            const { isSuccess, data } = res;
                            if (isSuccess) {
                                data.forEach((element) => {
                                    element.value =
                                        element.value.toFixed(2);
                                    element.quantity =
                                        element.quantity.toFixed(2);
                                    element.weight =
                                        element.weight.toFixed(2);
                                });
                                this.vendorAnalysis = data;
                            }
                        });
                }
            });
        },

        onSave(){
            let customerKey = this.$route.query.customerKey;
            this.$axios.get(`${this.$customerUrl}/Company/SaveCustomsDataName?name=${this.customsDataName}&customerKey=${customerKey}`).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    this.$message({ type: 'success', message: '保存成功!'});
                    this.getCustomsData();
                }
            });
        },
        
    },
    mounted() {
        
    },
    watch:{
        companyName(){
            this.customsDataName = this.companyName;
            this.getCustomsData();      // 获取海关数据
        }
    }
};
</script>

<style lang="scss" scoped>
.customsBox {
    background: #ffffff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    padding: 15px 20px;
    .sublevel {
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 20px;
        font-weight: bold;
        color: #3a3a3a;
    }
    .progress {
        width: 240px;
        display: inline-block;
    }
}
</style>