<template>
    <div style="overflow: hidden;" v-loading="tradeRecord.tableLoading">
        <el-empty v-if="tradeRecord.totalItems==0" description="搜索无数据" :image-size="200"></el-empty>
        <el-table v-else :data="tradeRecord.tableData">
            <el-table-column prop="dataCountryCn" label="数据来源"></el-table-column>
            <el-table-column prop="date" label="日期"></el-table-column>
            <el-table-column prop="hsCode" label="HS编码">
                <template slot-scope="scope">
                    <div v-html="scope.row.hsCode"></div>
                </template>
            </el-table-column>
            <el-table-column prop="importer" label="进口商">
                <template slot-scope="scope">
                    <div v-if="scope.row.importer == 'N/A'">{{scope.row.importer}}</div>
                    <div v-else style="cursor: pointer;" class="textOverFour commonColor" @click="toCompanyDetail(scope.row.importer,'importer',scope.row.dataCountryCn,scope.row.threeEnCountryCode)" v-html="scope.row.importer"></div>
                </template>
            </el-table-column>
            <el-table-column prop="exporter" label="出口商">
                <template slot-scope="scope">
                    <div v-if="scope.row.exporter == 'N/A'">{{scope.row.exporter}}</div>
                    <div v-else style="cursor: pointer;" class="textOverFour commonColor" @click="toCompanyDetail(scope.row.exporter,'exporter',scope.row.dataCountryCn,scope.row.threeEnCountryCode)" v-html="scope.row.exporter"></div>
                </template>
            </el-table-column>
            <el-table-column prop="product" label="产品描述">
                <template slot-scope="scope">
                    <div style="cursor: pointer;">
                        <div class="textOverFour commonColor" @click="toBill(scope.row)" v-html="scope.row.product"></div>
                        <span @click="toTranslate(scope.row)">翻译</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="countryCn" label="国家"></el-table-column>
            <el-table-column prop="dataType" label="数据类型"></el-table-column>
            <el-table-column prop="weight" label="重量">
                <template slot-scope="{row}">
                    {{row.weight}} {{row.weightUnit}}
                </template>
            </el-table-column>
            <el-table-column prop="quantity" label="数量">
                <template slot-scope="{row}">
                    {{row.quantity}} {{row.quantityUnit}}
                </template>
            </el-table-column>
            <el-table-column prop="value" label="金额">
                <template slot-scope="{row}">
                    {{row.value}} {{row.valueUnit}}
                </template>
            </el-table-column>
        </el-table>
        <el-pagination hide-on-single-page background layout="total,prev,pager,next" :total="tradeRecord.totalItems" style="float:right;margin:15px 20px;" :current-page.sync="tradeRecord.pageIndex" @current-change="getResult"></el-pagination>

        <el-dialog title="翻译" :visible.sync="dialogVisible" width="500px">
            <div class="translateBox">
                <div class="title">产品描述</div>
                <div class="text">{{originText}}</div>
                <el-divider></el-divider>
                <div class="title">译文</div>
                <div class="text" v-loading="spanLoading">{{resultText}}</div>
                <el-divider></el-divider>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false" size="small">关 闭</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: ["searchField"],
    data() {
        return {
            tradeRecord:{
                tableLoading: false,
                tableData: [],
                pageIndex: 1,
                totalItems: 0,
            },
            dialogVisible: false,
            originText: "",
            resultText: "",
            spanLoading: false,
        };
    },
    methods: {
        //翻译功能
        toTranslate(row){
            this.dialogVisible = true;
            this.spanLoading = true;
            this.originText = row.product.replace('<mark>','').replace('</mark>','');
            this.$axios.post(`${this.$searchUrl}/Trade/Translation`, {
                from: "auto",
                to: "zh",
                text: this.originText,
            }).then((res) => {
                const { isSuccess, data } = res;
                this.spanLoading = false;
                if (isSuccess) {
                    this.resultText = data.trans_result[0].dst;
                }
            }).catch(() => {
                this.dialogVisible = false;
                this.resultText = "";
            })
        },
        //获取贸易数据结果
        getResult(pageIndex){
            this.tradeRecord.pageIndex = pageIndex;
            this.tradeRecord.tableLoading = true;
            this.$axios.post(`${this.$searchUrl}/Trade`, {
                ...this.searchField,
                pageIndex: pageIndex,
            }).then((res) => {
                const { isSuccess, data, errorMessage } = res;
                this.tradeRecord.tableLoading = false;
                if (isSuccess) {
                    if(errorMessage == "无数据"){
                        this.$message({ message: '搜索暂无数据返回!', type: 'warning'});
                        this.tradeRecord.tableData = [];
                        this.tradeRecord.totalItems = 0;
                        return false;
                    }
                    // console.log(data.data);
                    this.tradeRecord.tableData = data.data;
                    this.tradeRecord.totalItems = data.total;
                }
            });
        },

        //跳转公司详情
        toCompanyDetail(companyName,isImpExp,dataCountryCn,threeEnCountryCode){
            companyName = companyName.replace(/<mark>/g,'').replace(/<\/mark>/g,'');//将mark标签替换掉空字符
            companyName = escape(companyName);
            let routeData = this.$router.resolve({
                path: "/AkeySearch/CompanyDetails",
                query: { 
                    companyName: companyName,
                    isImpExp: isImpExp,
                    ie: this.searchField.ie,
                    country: dataCountryCn,
                    countryCode: threeEnCountryCode,
                },
            });
            window.open(routeData.href, "_blank");
        },
        //跳转提单详情
        toBill(row){
            if(row.dataType == 'Shi'){
                let routeData = this.$router.resolve({
                    path: "/AkeySearch/Shiplading",
                    query: { 
                        tradeKey: row.infoKey,
                        dataCountryCn: row.dataCountryCn,
                        threeEnCountryCode: row.threeEnCountryCode,
                        ie: this.searchField.ie,
                    },
                });
                window.open(routeData.href, "_blank");
            }else{
                let routeData = this.$router.resolve({
                    path: "/AkeySearch/Billoflading",
                    query: { 
                        tradeKey: row.infoKey,
                        dataCountryCn: row.dataCountryCn,
                        threeEnCountryCode: row.threeEnCountryCode,
                        ie: this.searchField.ie,
                    },
                });
                window.open(routeData.href, "_blank");
            }
        }
    },
    mounted() {

    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog__body{
    padding: 10px 20px;
}
.translateBox{
    .title{
        line-height: 32px;
        font-size: 16px;
        font-weight: bold;
        color: #333;
    }
    .text{
        line-height: 24px;
        color: #666;
    }
}
</style>