<template>
    <el-dialog title="定制AI推荐" :visible.sync="dialogVisible" width="1000px" top="50px" @close="$emit('closeDialog')">
        <div class="title">推荐地区：</div>
        <Region @onSave="chooseArea" :countryList="countryList" />
        
        <div class="title">HS编码：</div>
        <div class="inputBox">
            <el-input v-model="addValueOne" placeholder="HS编码" size="small"></el-input>
            <el-button type="primary" size="small" @click="addHsCode" :disabled="!addValueOne.trim()">添加</el-button>
        </div>
        <div class="preview">
            <el-tag v-for="(tag,index) in hsCodeList" :key="index" closable @close="delHsCode(index)">
                {{tag}}
            </el-tag>
        </div>

        <div class="title">关键词：</div>
        <div class="inputBox">
            <el-input v-model="addValueTwo" placeholder="关键词" size="small"></el-input>
            <el-button type="primary" size="small" @click="addKeyword" :disabled="!addValueTwo.trim()">添加</el-button>
        </div>
        <div class="preview">
            <el-tag v-for="(tag,index) in keywordList" :key="index" closable @close="delKeyword(index)">
                {{tag}}
            </el-tag>
        </div>
        
        <div class="title">产品型号：</div>
        <div class="inputBox">
            <el-input v-model="addValueThree" placeholder="产品型号" size="small"></el-input>
            <el-button type="primary" size="small" @click="addModel" :disabled="!addValueThree.trim()">添加</el-button>
        </div>
        <div class="preview">
            <el-tag v-for="(tag,index) in modelList" :key="index" closable @close="delModel(index)">
                {{tag}}
            </el-tag>
        </div>
        
        <span slot="footer" class="dialog-footer">
            <el-button size="small" type="primary" @click="onSave" :loading="btnLoading">保 存</el-button>
        </span>
    </el-dialog>
</template>

<script>
import Region from "./components/Region.vue"
export default {
    props: ["dialogVisible"],
    components: { Region },
    data() {
        return {
            arr: [],
            entiryType:'All',
            countryList: [],
            btnLoading: false,
            addValueOne: "",
            hsCodeList: [],
            addValueTwo: "",
            keywordList: [],
            addValueThree: "",
            modelList: [],
        };
    },
    
    methods: {
        addHsCode(){
            this.hsCodeList.push(this.addValueOne.trim())
            this.addValueOne = "";
        },
        delHsCode(index){
            this.hsCodeList.splice(index, 1);
        },

        addKeyword(){
            this.keywordList.push(this.addValueTwo.trim())
            this.addValueTwo = "";
        },
        delKeyword(index){
            this.keywordList.splice(index, 1);
        },

        addModel(){
            this.modelList.push(this.addValueThree.trim())
            this.addValueThree = "";
        },
        delModel(index){
            this.modelList.splice(index, 1);
        },

        //勾选推荐地区
        chooseArea(value){
            this.countryList = value;
        },
        //保存关键词 保存国家
        onSave(){
            
            this.btnLoading = true;
            const form={
                country:(()=>{
                    try{
                        const v=[]
                        for(const i of this.countryList){
                            for(const s of this.arr){
                              if(i===s.countryCn){
                                v.push({
                                    country:i,
                                    threeCountryCode:s.threeCountryCode
                                })
                              }
                            }
                        }
                        
                        return v
                    }catch{
                        return []
                    }
                })(),
                entiryType: this.entiryType,
                hsCode: this.hsCodeList,
                keyword: this.keywordList,
                productModel: this.modelList,
            }
            this.$axios({
                method:'post',
                url:`${this.$clueUrl}/api/AIRec/UserAIRecSetting`,
                data:form
            }).then((res) => {
                const { isSuccess } = res;
                this.btnLoading = false;
                if (isSuccess) {
                    this.$emit('closeDialog');
                    this.$alert('保存成功，请次日查看关键字相关内容。', '结果', {
                        confirmButtonText: '确定',
                        type: "success",
                    });
                }
            });
            
        },
    },
    async mounted() {
     

        await (async()=>{
            const res=   await  this.$axios.get(`${this.$clueUrl}/api/AIRec/GetCountry`)
            const arr=[]
            res.data.forEach(element => {
                arr.push(...element.country)
            });
            this.arr=arr
        })();

        await (async()=>{
            const {data}=   await  this.$axios.get(`${this.$clueUrl}/api/AIRec/GetUserAIRecSetting`)
            // console.log(data);
            this.countryList = data.countrys;
            this.hsCodeList = data.hsCodes;
            this.keywordList = data.keywords;
            this.modelList = data.productModels;
        })()
        
    },
};
</script>

<style lang="scss" scoped>
.title{
    font-size: 16px;
    margin-bottom: 10px;
    margin-top: 10px;
    font-weight: bold;
    color: #3A3A3A;
}
.preview{
    min-height: 100px;
    background: #EFEFEF;
    border-radius: 4px;
    padding: 20px;
    box-sizing: border-box;
}
.inputBox{
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
}
.tuijian{
    min-height: 100px;
}


/deep/ .el-dialog__body{
    border-top: 1px solid #D9D9D9;
    border-bottom: 1px solid #D9D9D9;
    padding: 0 20px;
}
</style>