<template>
    <div>
        <el-dialog title="商品描述" :visible="true" width="80%" top="40px" :show-close="false"
            :close-on-click-modal="false" :close-on-press-escape="false">
            <el-input placeholder="商品描述搜索" v-model="prodecuDesc" size="small" clearable>
                <el-button slot="append" icon="el-icon-search" @click="onSearch"></el-button>
            </el-input>
            <el-descriptions v-if="desList.length != 0" :column="1" border :labelStyle="{width:'50px',textAlign:'center'}">
                <el-descriptions-item v-for="(ele,index) in desList.slice((pageIndex-1)*10,pageIndex*10)" :key="index" :label="index+1+(pageIndex-1)*10">
                    <div v-html="ele"></div>
                </el-descriptions-item>
            </el-descriptions>
            <div v-else>
                <el-empty :image-size="50"></el-empty>
            </div>
            <div class="footer">
                <el-pagination background layout="total, prev, pager, next" :total="desList.length" v-show="desList.length > 10"
                @current-change="pageIndexChange" :current-page="pageIndex" style="float:left;"></el-pagination>
                <el-button size="small" @click="closeDialog" style="float:right;">关 闭</el-button>
            </div>          
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: ["viewKey","dataYear"],
    data() {
        return {
            prodecuDesc: "",
            desList: [],
            pageIndex: 1,
        };
    },
    methods: {
        closeDialog(){
            this.$emit('close');
        },

        //获取商品描述信息
        getDesInfo(){
            this.$axios.get(`${this.$clueUrl}/ClickHouse/GetMoreProdecuDesc?key=${this.viewKey}&year=${this.dataYear}&prodecuDesc=${this.prodecuDesc}`)
            .then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.desList = data;
                }
            });
        },
        pageIndexChange(page){
            this.pageIndex = page;
        },
        onSearch(){
            this.pageIndex = 1;
            this.getDesInfo();
        }
    },
    mounted() {
        this.getDesInfo();  //获取商品描述信息
    },
};
</script>

<style lang="scss" scoped>
.footer{
    overflow: hidden;
    margin: 10px 0;
}
</style>