<template>
  <div id="abroad-captical" class="abroad-captical" v-show="totalData.length !== 0">
    <div class="title" ref="title">
      <p>资本细节</p>
      <feedback position="资本细节" url="" />
      <!-- <div class="flex-container">
        <img class="icon" src="@/firm/images/company/icon-feedback.png" style="margin-left: 1em" />
        <span class="text-with-icon" style="color: var(--themeColor, #3152AF)">反馈</span>
      </div> -->
    </div>
    <div>
      <el-table :data="tableData" border :header-cell-style="{ background: '#f8faff', color: '#1F2D3D' }">
        <el-table-column label="资本类型">
          <template slot-scope="{ row }">
            <div>{{ row.capitalType.description }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="startDate" label="资本额首次登记日期" />
        <el-table-column label="资本金额">
          <template slot-scope="{ row }">
            <div>{{ row.capitalAmount.value }}</div>
          </template>
        </el-table-column>
        <el-table-column label="资本货币">
          <template slot-scope="{ row }">
            <div>{{ row.capitalAmount.currency }}</div>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 1em; display: flex; justify-content: flex-end">
        <el-pagination background layout="total, prev, pager, next" :total="pagination.total" :page-size="pagination.pageSize" :current-page="pagination.pageIndex" @current-change="handlePageIndexChange" hide-on-single-page />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapMutations } from "vuex";
import { AnchorInditor } from "../../../utils";

export default Vue.extend({
  props: ["capitalDetails"],
  watch: {
    capitalDetails() {
      try {
        this.totalData = this.capitalDetails;
        this.tableData = this.totalData.slice(0, this.pagination.pageSize);
        this.pagination.total = this.capitalDetails.length;
        if (this.totalData.length !== 0) {
          this.setAnchorFlag([["ZBXJ", true]]);
        }
      } catch {
        this.totalData = [];
        this.tableData = [];
        this.pagination.total = 0;
        this.setAnchorFlag([["ZBXJ", false]]);
      }
    },
  },
  data() {
    return {
      totalData: [],
      tableData: [],
      pagination: {
        total: 0,
        pageSize: 10,
        pageIndex: 1,
      },
    };
  },
  methods: {
    ...mapMutations("anchor", {
      setAnchorFlag: "setAnchorFlag",
      setCurrent: "setCurrent",
    }),
    //分页器页码改变时触发
    handlePageIndexChange(pageIndex) {
      const size = this.pagination.pageSize;
      this.tableData = this.totalData.slice((pageIndex - 1) * size, pageIndex * size);
    },
  },
  mounted() {
    new AnchorInditor(() => {
      this.setCurrent("ZBXJ");
    }, this.$refs["title"]);
  },
});
</script>

<style lang="scss" scoped>
@import "@/firm/scss/mixin.scss";

.abroad-captical {
  background: #fff;
  padding: 1rem;
  padding-top: 0;
  @include mixin-panel-title;
}
</style>
