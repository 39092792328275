<template>
    <div>
        <el-card class="boxCard">
            <div slot="header" class="clearfix">
                <span style="padding-right:20px;">采购码列表</span>
                <el-button size="small" type="primary" @click="toAdd">添加采购码</el-button>
                <div style="float: right;display: flex;">
                    <el-input v-model="searchName" size="small" clearable></el-input>
                    <el-button size="small" type="primary" @click="onSearch">搜索</el-button>
                </div>
            </div>
            <el-table :data="tableData" style="width: 100%" v-loading="tableLoading">
                <el-table-column prop="name" label="中文名"></el-table-column>
                <el-table-column prop="nameEn" label="英文名"></el-table-column>
                <el-table-column prop="company" label="公司"></el-table-column>
                <el-table-column prop="department" label="部门"></el-table-column>
                <el-table-column prop="createTime" label="创建时间" width="200"></el-table-column>
                <el-table-column prop="url" label="专属地址" width="300"></el-table-column>
                <el-table-column prop="code" label="二维码" width="150">
                    <template slot-scope="scope">
                        <div style="display: flex;align-items: center;">
                            <img :src="scope.row.code" alt="" style="width:50px;" />
                            <el-link :href="scope.row.code" :download="scope.row.name+'.jpeg'">下载二维码</el-link>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="270">
                    <template slot-scope="scope">
                        <!-- <div v-if="scope.row.isStop" style="color:#F56C6C;">已停用</div>
                        <el-button v-else size="small" type="danger" @click="onStop(scope.row)">停用</el-button> -->
                        <el-button size="small" @click="toEdit(scope.row)">修改</el-button>
                        <el-button size="small" @click="toViewVersion(scope.row)">修改历史</el-button>
                        <el-button size="small" @click="toViewStat(scope.row)">统计信息</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination hide-on-single-page background layout="total,prev,pager,next" :total="totalItems" style="float:right;margin:10px 0;" :current-page.sync="pageIndex" @current-change="getList"></el-pagination>
        </el-card>

        <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="500px">
            <el-form :model="submitData" label-width="100px" size="small">
                <el-form-item label="中文名">
                    <el-input v-model="submitData.name"></el-input>
                </el-form-item>
                <el-form-item label="英文名">
                    <el-input v-model="submitData.nameEn"></el-input>
                </el-form-item>
                <el-form-item label="公司">
                    <el-input v-model="submitData.company"></el-input>
                </el-form-item>
                <el-form-item label="部门">
                    <el-input v-model="submitData.department"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false;" size="small">取 消</el-button>
                <el-button :disabled="!submitData.name" type="primary" @click="onSubmit" size="small" :loading="btnLoading">提交</el-button>
            </span>
        </el-dialog>


        <el-dialog title="历史版本" :visible.sync="versionVisible" width="800px">
            <el-table :data="versionList" style="width: 100%" v-loading="tableLoading">
                <el-table-column prop="name" label="中文名"></el-table-column>
                <el-table-column prop="nameEn" label="英文名"></el-table-column>
                <el-table-column prop="company" label="公司"></el-table-column>
                <el-table-column prop="department" label="部门"></el-table-column>
                <el-table-column prop="createTime" label="创建时间" width="200"></el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
                <el-button @click="versionVisible = false;" size="small">关 闭</el-button>
            </span>
        </el-dialog>

        <el-dialog title="统计信息" :visible.sync="statVisible" width="1200px" top="50px">
            <div style="overflow: hidden;">
                <el-date-picker v-model="statForm.startDate" type="date" value-format="yyyy-MM-dd"  placeholder="开始日期" size="mini" :clearable="false" @change="getStat"></el-date-picker>
                <span style="padding:0 10px;">至</span>
                <el-date-picker v-model="statForm.endDate" type="date" value-format="yyyy-MM-dd"  placeholder="结束日期" size="mini" :clearable="false" @change="getStat"></el-date-picker>
                <el-table :data="statList" style="width: 100%" v-loading="tableLoading">
                    <el-table-column prop="createTimeStr" label="访问时间" width="160"></el-table-column>
                    <el-table-column prop="country" label="国家" width="120"></el-table-column>
                    <el-table-column prop="province" label="省" width="120"></el-table-column>
                    <el-table-column prop="city" label="市" width="120"></el-table-column>
                    <el-table-column prop="ip" label="IP地址" width="120"></el-table-column>
                    <el-table-column prop="system" label="操作系统" width="120"></el-table-column>
                    <el-table-column prop="language" label="语言" width="120"></el-table-column>
                    <el-table-column prop="userID" label="用户ID"></el-table-column>
                </el-table>
                <el-pagination hide-on-single-page background layout="total,prev,pager,next" :total="statTotal" style="float:right;margin:10px 0;" :current-page.sync="statForm.pageIndex" @current-change="getStat"></el-pagination>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="statVisible = false;" size="small">关 闭</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import dayjs from "dayjs";
export default {
    data() {
        return {
            //采购码列表
            tableLoading: false,
            searchName: "",
            tableData:[],
            pageIndex: 1,
            totalItems: 0,
            //生成采购码
            dialogTitle: "",
            dialogVisible: false,
            btnLoading: false,
            submitData:{},
            //修改历史
            versionVisible: false,
            versionList: [],
            //获取统计信息
            statVisible: false,
            statForm:{
                no: "",
                startDate: "",
                endDate: "",
                pageIndex: 1,
                pageSize: 10,
            },
            statList: [],
            statTotal: 0,
        };
    },
    methods: {
        //生成采购码
        toAdd(){
            this.dialogTitle = "生成采购码";
            this.dialogVisible = true;
            this.submitData = {
                key: "",
                name: "",
                nameEn: "",
                company: "",
                department: "",
            }
        },
        toEdit(row){
            this.dialogTitle = "编辑采购码";
            this.dialogVisible = true;
            this.submitData = {
                key: row.key,
                name: row.name,
                nameEn: row.nameEn,
                company: row.company,
                department: row.department,
            }
        },
        onSubmit(){
            this.btnLoading = true;
            this.$axios.post(`${this.$personnelUrl}/EntryApplication/SavePurchaseCode`,this.submitData).then((res) => {
                const { isSuccess } = res;
                this.btnLoading = false;
                this.dialogVisible = false;
                if (isSuccess) {
                    this.$message({message: '操作成功',type: 'success'});
                    this.onSearch();
                }
            })
        },

        //采购码列表
        getList(){
            this.tableLoading = true;
            this.$axios.post(`${this.$personnelUrl}/EntryApplication/GetPurchaseCode`,{
                name: this.searchName,
                pageIndex: this.pageIndex,
                pageSize: 10,
            }).then((res) => {
                const { isSuccess, data } = res;
                this.tableLoading = false;
                if (isSuccess) {
                    this.tableData = data.datas;
                    this.totalItems = data.totalItems;
                }
            })
        },
        onSearch(){
            this.pageIndex = 1;
            this.getList();
        },
        //停用
        onStop(row){
            this.$axios.get(`${this.$personnelUrl}/EntryApplication/StopPurchaseCode?key=${row.key}`).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    this.$message({message: '停用成功',type: 'success'});
                    this.getList();
                }
            })
        },
        //查看修改历史
        toViewVersion(row){
            this.versionVisible = true;
            this.$axios.get(`${this.$personnelUrl}/EntryApplication/GetHistoryPurchaseCode?key=${row.key}`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.versionList = data;
                }
            })
        },

        //获取统计信息
        toViewStat(row){
            this.statVisible = true;
            this.statForm = {
                no: row.no,
                startDate: dayjs().format('YYYY-MM-DD'),
                endDate: dayjs().add(1, 'day').format('YYYY-MM-DD'),
                pageIndex: 1,
                pageSize: 10,
            };
            this.getStat();
        },
        getStat(){
            this.tableLoading = true;
            this.$axios.post(`${this.$personnelUrl}/EntryApplication/GetTongJi`,this.statForm).then((res) => {
                const { isSuccess, data } = res;
                this.tableLoading = false;
                if (isSuccess) {
                    this.statList = data.datas;
                    this.statTotal = data.totalItems;
                }
            })
        },

    },
    mounted() {
        this.getList();
    },
};
</script>

<style lang="scss" scoped>
.boxCard {
    // width: 1200px;
    margin: 20px;
}
</style>