<template>
  <div id="abroad-finance" class="abroad-finance" v-show="!hidden">
    <div class="title" ref="title">
      <p>财务信息</p>
      <feedback position="财务信息" url="" />
    </div>
    <el-table :data="industryData" border :header-cell-style="{ background: '#f8faff', color: '#1F2D3D' }">
      <el-table-column prop="particularYear" label="年度" />
      <el-table-column prop="annualIncome" label="年收入" />
      <el-table-column prop="currency" label="货币" />
      <el-table-column prop="employeesNumber" label="员工数" />
    </el-table>
  </div>
</template>

<script>
import Vue from "vue";
import { mapMutations, mapState } from "vuex";
import axios from "@/firm/plugins/axios";
import { AnchorInditor } from "../../../utils";

export default Vue.extend({
  data() {
    return {
      loading: false,
      industryData: [],
      hidden: true,
    };
  },
  computed: {
    ...mapState(["company"]),
  },
  methods: {
    ...mapMutations("anchor", {
      setAnchorFlag: "setAnchorFlag",
      setCurrent: "setCurrent",
    }),
  },
  async mounted() {
    new AnchorInditor(() => {
      this.setCurrent("CWXX");
    }, this.$refs["title"]);
    try {
      this.loading = true
      const res = await axios({
        method: 'GET',
        url: '/search/Credit/EnterpriseLibrary/Financials',
        params: {
          companyName: this.company,
          countryCode: this.$route.query.countryCode
        }
      })
      this.industryData = res.data.result
      if (this.industryData.length === 0) throw new Error()
      this.hidden = false
      this.setAnchorFlag([["CWXX", true]])
    } catch {
      null
    } finally {
      this.loading = false
    }
  },
});
</script>

<style lang="scss" scoped>
@import "@/firm/scss/mixin.scss";

.abroad-finance {
  background: #fff;
  padding: 1rem;
  padding-top: 0;
  @include mixin-panel-title;
}
</style>
