<template>
    <div class="viewBox">
        <div class="header">《上海企芯信息科技有限公司服务协议》</div>
        <p>欢迎使用上海企芯信息科技有限公司产品及服务。</p>
        <p>提示：在使用上海企芯信息科技有限公司产品及服务之前，您应当认真阅读并遵守《上海企芯信息科技有限公司服务协议》（以下简称“本协议”）以及《上海企芯信息科技有限公司隐私政策》（以下简称“隐私政策”），充分理解各条款内容，特别是免除或者限制责任的条款，我们将以加粗字体显示，您应重点阅读。</p>
        <p>当您按照企业组织创建页面提示填写信息，勾选“我已阅读并同意《服务协议》及《隐私政策》”并完成企芯企业组织创建时；或您使用企业组织分配的账号密码激活账号，勾选“我已阅读并同意《服务协议》及《隐私政策》”并完成首次登陆时；或经登陆页面提示登录即视为同意《服务协议》及《隐私政策》，您点击登陆完成日常登陆时，即表示您已充分阅读、理解并接受本协议及隐私政策的全部内容，本协议及隐私政策即产生法律约束力，届时您不应以未实际阅读相关内容或者未获得企芯对您问询的解答等理由，主张本协议及隐私政策无效或要求撤销。</p>
        <div>
            <div class="title">一、缔约主体</div>
            <p>本协议由企业组织用户（含管理员、最终用户、外部授权用户）（以下简称“用户”或“您”）与上海企芯信息科技有限公司及相关上海企芯信息科技有限公司服务的关联公司（以下合称 “企芯”或“我们”）在上海市七宝区共同缔结。</p>
        </div>
        <div>
            <div class="title">二、协议内容和效力</div>
            <p>2.1 本协议内容包括本协议正文及所有我们已经发布或将来可能发布的隐私政策、规则、声明、通知、警示、提示、说明（以下简称“规则”）。前述规则为本协议不可分割的组成部分，与本协议具有同等法律效力。</p>
            <p>2.2 企芯有权根据需要不时制订、修改本协议及相关规则，变更后的协议和规则会在官方主页（crm.qixinerp.com）或系统站内通知（包括我们会通过小红点进行通知或弹窗提示），并经（7）天公告完毕后即取代原协议及规则生效。如您不同意相关变更，应当立即停止使用企芯服务；如您继续使用企芯服务，即表示您已接受经修订的协议和规则。</p>
        </div>
        <div>
            <div class="title">三、服务内容</div>
            <p>3.1 企芯产品和服务包括以“企芯”网站（crm.qixinerp.com）、“企芯”各版本客户端应用程序、小程序、供第三方网站和应用程序使用的“企芯”软件开发工具包（SDK）和应用程序编程接口（API）以及随技术发展出现的我们向您提供的新形态产品及服务，以上统称“企芯服务”。企芯客户端应用程序提供包括但不限于Windows、Mac、iOS、Android以及其他操作系统适配服务等多个应用版本。用户可自行选择与所安装终端设备相匹配的软件版本。本协议自动适用于用户所使用的所有版本的软件和服务。</p>
            <p>3.2 企芯服务仅面向企业组织（含其创建的最终用户）提供数字化在线移动办公、沟通与协同服务，暂不支持非企业组织用户创建的个人用户使用企芯服务。企芯为企业组织提供的服务包括但不限于：</p>
            <p>1) 企芯工作台服务：为企业提供营销管理、销售管理、订单管理、服务管理、客户管理、产品管理等业务数字化体系建设服务。</p>
            <p>2) 企芯协同办公服务：诸如通讯录创建及管理、邮箱服务（接入的第三方服务）、智能办公应用（审批、表单、日志）、企业邮箱（快捷入口）、等功能或服务。</p>
            <p>3) 企芯PaaS能力服务：提供易用的API，SDK等开发组件，可以集成第三方软件，实现与企业ERP、进销存、财务等系统无缝对接，助力企业系统升级。</p>
            <p>4) 企芯其他增值服务。</p>
            <p>企业组织用户及管理员理解并同意，上述功能服务的使用取决于您实际购买开通的具体服务，且可能根据用户需求的变化，服务版本差异、或服务提供方的单方判断而被优化或修改，或因定期、不定期的维护而暂缓提供。对于企业组织用户通过企芯PaaS能力集成的第三方应用，由第三方应用服务商就其服务的真实性、合法性、安全性及有效性承担全部责任。</p>
            <p>3.3 企芯依据与企业组织签订的服务合同等相关协议及本协议、许可您对企芯软件进行非独占、非排他、不可转让的一般使用权。本协议未明示授权的其他一切权利仍由企芯公司保留，您在行使这些权利时须另外取得企芯公司的书面许可。</p>
            <p>3.4 为了改善用户体验、完善服务内容及形式，企芯将不断努力开发新的服务，并为用户不时提供软件更新，包括可能采取软件替换、修改、功能强化、版本升级等形式。用户理解并同意，企芯有权自行决定对服务或服务任何部分及其相关功能、应用软件进行变更、升级、修改，并可能通过企芯官网、企芯客户端或相应应用实现开发者信息页面向用户通知软件更新情况。</p>
            <p>3.5 本协议不适用于第三方通过前述网页或企芯客户端直接向您提供的服务（例如相关服务的提供以您已按该第三方要求注册账号或您与该第三方已直接签订或通过企芯间接签订对您及第三方具有约束力的法律文件为前提条件）（统称“第三方服务”），包括但不限于您通过应用程序开发接口（API）接入企芯平台的任何应用、小程序、H5等形式服务、企业集成应用服务以及通过第三方SDK或调用第三方API数据接口使用的第三方应用服务，例如您通过企芯客户端企业办公支付功能实现的收付、转账等支付相关功能服务。</p>
        </div>
        <div>
            <div class="title">四、注册及账号管理</div>
            <p>4.1账号类别及功能</p>
            <p>1)企芯企业组织用户基于人力资源管理、信息安全管控及办公管理需求，通过在企芯平台搭建企业组织架构并为在职/在岗人员（“组织成员”）配置最终用户账号，为组织成员提供在线移动办公、沟通协同的工具，例如最终用户可创建审批、内部群组、添加组织内好友、使用内部文档及知识库等。</p>
            <p>2)企芯企业组织用户基于业务管理场景下连接业务上下游企业的需求，通过开通企芯互联功能，可与其他企芯企业组织用户建立企业组织之间的业务协同，例如添加外部联系人、创建/加入外部群组、业务数据交互。企芯企业组织用户也可为被连接企业（“被授权企业”）购买企芯账号（简称“外部授权账号”）并开通企芯互联功能，从而实现企业组织用户与外部授权账号之间的业务协同。</p>
            <p>4.2帐号权责</p>
            <p>4.2.1企业组织用户理解并同意：</p>
            <p>1) 管理员有权代表企业组织管理企业组织帐号，包括但不限于向最终用户配置帐号（设置子管理员、其他最终用户）、查看最终用户帐号的登录信息和数据安全情况，停用、限制权限或注销最终用户的帐号等。管理员根据相关页面进行的设置和操作行为均代表企业组织用户行为。如企业组织用户有任何异议，请自行管理和解决相关管理员的授权或配置相应权限，或及时联系企芯客服协助进行管理员重设。企芯不就企业组织用户与管理员之间的授权行为或基于实际登录企业组织用户管理平台系统的设置和操作行为，向企业组织用户承担任何责任。</p>
            <p>2) 您保证创建的最终用户是具备完全民事权利能力和与所从事的民事行为相适应的行为能力的自然人，否则您将承担因此而导致的一切后果，且企芯公司有权向您索偿。</p>
            <p>3) 企业组织用户应对企业组织账号信息及其组织架构内的最终用户帐号产生的任何内容（例如头像、名称、用户说明等注册信息，或通过文字、语音、图片、视频等形式发送、转发、回复、评论、发布日志、公告等信息或内容）进行管理，包括确保信息真实有效、不侵害其他用户或第三方合法权益。如果企业组织用户发现或收到他人举报或投诉最终用户产生的内容有违法违规、违反公司内部管理规定等情况，企业组织用户应及时对最终用户帐号及相关内容进行处理。</p>
            <p>4) 最终用户使用企芯相关功能/应用过程中提交或产生的数据（含最终用户的个人信息）均为企业组织控制数据，企芯将根据企业组织用户管理员上载、配置、开通、设置、发布、传输、存储、共享、删除、撤回、注销以及其他代表企业组织的操作行为和指令，收集、存储、使用、加工、传输、共享、删除企业组织控制数据。</p>
            <p>5) 依据法律规定，为订立、履行最终用户作为一方当事人的合同所必需，或者按照依法制定的劳动规章制度和依法签订的集体合同实施人力资源管理所必需，企业组织用户有权作为个人信息处理者，收集、处理最终用户登录和使用帐号期间提供或生成的个人信息。企芯仅为企业组织用户选定的受托处理者，仅限于为实现服务目的而处理最终用户的部分个人信息。除《企芯隐私政策》规定的共享委托处理情形外，未经管理员同意，企芯将不会转委托第三方处理最终用户个人信息。未免疑义，在第三方服务中，企业组织用户及其管理员是最终用户个人信息的处理者，因此企芯不就第三方应用服务商处理最终用户个人信息承担任何责任。</p>
            <p>6) 企业组织账号（含创建的最终用户账号）权益归属企业组织用户所有。企业组织帐号下的最终用户账号由企业组织用户管控，企业组织用户及其控制的最终用户在使用企芯服务时应严格遵守本协议，如有违反本协议行为，由企业组织用户承担全部责任。</p>
            <p>7) 您购买的外部授权账号，由您分配给被授权企业使用。外部授权账号开通后由被授权企业进行管理。您亦有权在软件服务期限内撤销被授权企业对于外部授权账号的使用授权，并回收后重新分配给其他企业组织使用。</p>
            <p>4.2.2 管理员理解并同意：</p>
            <p>1) 管理员帐号为代表企业组织的最终用户帐号，管理员帐号实施的操作、行为和指令，将被默认代表企业组织用户。请您确保已获得企芯企业组织用户的充分授权并能够代表企芯企业组织用户身份行事。企芯有理由认为您与您所在的企业组织签署了相应的服务协议和隐私政策或其他具有相同或类似性质的协议或规则，并受其约束，因此而引起的纠纷，我们将无义务解决且不承担相关责任。</p>
            <p>2) 您应保障管理员帐号的保密性和登录企业管理工作台信息的安全性。您应自行添加、指定或变更管理成员和/或子管理员，并对管理成员的行为自行承担责任。企芯根据管理员/子管理员帐号的行为对所在企业组织成员服务的调整亦由您及代表的企业组织用户承担后果，因此给企芯或他人造成损失的，您及所属的企业组织用户应当予以赔偿。</p>
            <p>3) 基于使用企芯服务而向组织成员收集和处理个人信息前，您应确保向组织成员充分说明法律依据，诸如为订立、履行个人作为一方当事人的合同所必需，或者按照依法制定的劳动规章制度和依法签订的集体合同实施人力资源管理所必需。</p>
            <p>4) 您应在开通外部授权账号前或者变更被授权企业前，提供被授权企业的身份信息，并确保已获得被授权企业的充分授权。</p>
            <p>5) 您在开通和使用企业互联功能前应充分理解相关风险和后果，对于您代表的企业组织用户与其他企业组织用户之间基于企业互联功能使用引发的任何纠纷，企芯不承担任何责任。</p>
            <p>6) 您应保守使用企芯服务过程中知悉的企业商业秘密、保护最终用户个人信息及其他权益。</p>
            <p>4.2.3 最终用户理解并同意：</p>
            <p>1) 您应当是具备完全民事权利能力和与所从事的民事行为相适应的行为能力的自然人。若您不具备前述主体资格，请勿使用企芯服务，否则您及您的监护人应承担因此而导致的一切后果，且企芯公司有权注销您的账户，并向您及您的监护人索偿。</p>
            <p>2) 您的账户、密码不得以任何方式出租、转让、赠与或继承，或被提供予他人使用。请您务必妥善保管您的账户、密码和/或身份要素信息，并对利用该账户和密码/身份信息所进行的一切活动（包括但不限于网上点击同意或提交各类规则协议或支付购买、分享资讯或图片、发起电话会议等）负全部责任。如您的账户遭到未获授权的使用或者发生其他任何安全问题时，请您立即联系您所在企业组织的管理员。</p>
            <p>3) 您使用的账号权益归属相应的企业组织用户所有，您在遵守本协议及相关企芯服务规范同时，还应当按照所属企业组织的规定使用企芯服务。</p>
            <p>4) 您基于使用企芯服务而提供或生成的个人信息，由您所属的企业组织用户作为处理者进行管理控制，企芯仅为接受企业组织用户委托的受托处理者。除《企芯隐私政策》规定的共享委托处理情形外，未经管理员同意，企芯将不会转委托第三方处理您的个人信息。</p>
            <p>4.2.4 被授权企业用户理解并同意：您使用的外部授权账号是基于购买方的授权，如您在授权期限内基于该外部授权账号自行采购了其他企芯服务（简称“自购服务”），自购服务的连续运行取决于如下任一条件满足：</p>
            <p>1)外部授权账号的使用授权未被购买方撤销；</p>
            <p>2)或外部授权账号使用授权被购买方撤销情形下，您已及时自行购买了企芯企业组织用户账号。</p>
            <p>对于未满足上述条件导致自购服务不可用或中断的，我们不承担任何责任。</p>
            <p>4.3 账号注销</p>
            <p>若企业组织用户购买的企芯产品服务到期后持续停用达到30天的或者企业组织用户主动申请账户注销的，我们会在履行法律规定的义务（例如数据备份、数据最低保存期限义务）后定期批量删除相关数据并注销该企业组织账号（包括其创建的最终用户账号）。如最终用户就其使用的账号申请注销，应联系所属的企业组织管理员处理，我们不接受最终用户就其使用的账号提出的注销申请。企业组织用户应在到期停用前自助导出相关数据或不迟于停用后30日内申请企芯协助导出相关数据（因协助导出发生的相关费用由企业组织用户承担）。如企业组织用户注销（主动或被动注销），企芯将对账户相关内容及信息依法删除，因企业组织用户未及时进行数据导出备份导致账户注销后全部数据、信息等被清空、丢失等的损失，企芯无需向企业组织用户承担任何责任。</p>
        </div>
        <div>
            <div class="title">五、服务使用规范</div>
            <p>5.1 您承诺不会利用本服务进行任何违法或不当的活动，包括但不限于下列行为</p>
            <p>5.1.1 制作、复制、发布含有下列内容之一的信息：</p>
            <p>1) 反对宪法所确定的基本原则的；</p>
            <p>2) 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
            <p>3) 损害国家荣誉和利益的；</p>
            <p>4) 歪曲、丑化、亵渎、否定英雄烈士事迹和精神，以侮辱、诽谤或者其他方式侵害英雄烈士的姓名、肖像、名誉、荣誉的；</p>
            <p>5) 宣扬恐怖主义、极端主义或者煽动实施恐怖活动、极端主义活动的；</p>
            <p>6) 煽动民族仇恨、民族歧视、破坏民族团结的；</p>
            <p>7) 破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
            <p>8) 散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
            <p>9) 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>
            <p>10) 侮辱或者诽谤他人，侵害他人名誉、隐私、肖像和其他合法权益的；</p>
            <p>11) 含有虚假、冒充、诈骗、误导、胁迫、侵害他人知识产权及商业秘密、骚扰内容的；</p>
            <p>12) 含有中国或其您所在国国家管辖法所适用的法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其它内容的。</p>
            <p>5.1.2对本软件和服务从事以下行为：</p>
            <p>1) 将有关干扰、破坏或限制企芯软件、硬件或通讯设备功能的软件病毒或其他计算机代码、档案、方法和程序之资料，加以上载、张贴、发送电子邮件或以其他方式向公众传播，无论这些行为是否为商业目的；</p>
            <p>2) 通过非企芯公司开发、授权的第三方软件、插件、外挂、系统，登录或使用企芯，或制作、发布、传播上述工具；</p>
            <p>3) 自行、授权他人或利用第三方软件对本软件及其组件、模块、数据等进行干扰或违反任何关于本服务连线网络之规定、程序、政策或规范，包括但不限于利用技术非法侵入、破坏企芯服务平台之服务器系统，或者修改、增加、删除、窃取、截留、替换企芯服务平台之客户端或服务器系统中的数据，或者非法挤占企芯服务平台之服务器空间，或者实施其他的使之超负荷运行的行为。</p>
            <p>5.1.3使用本服务过程中从事下列行为：</p>
            <p>1) 对企芯服务任何部分或企芯服务之使用或获得，进行复制、拷贝、出售、转售或用于任何侵犯企芯知识产权、商业秘密、合法权益的商业目的，包括但不限于如下行为：删除或修改企芯服务平台上的版权信息，或者伪造ICP/IP地址或者数据包的名称；进行编译、反编译、反向工程或者以其他方式破解企芯服务平台的行为；</p>
            <p>2) 冒充企芯或使人误认为企芯所为；</p>
            <p>3) 以任何形式侵犯企芯公司的商业利益，或从事任何可能对企芯造成损害或不利于企芯的行为，包括但不限于进行任何破坏企芯服务平台使用公平性或者其他影响其正常使用秩序的行为，如利用BUG（又叫“漏洞”或者“缺陷”）来获得不正当的非法利益；</p>
            <p>4) 其他违反遵守法律法规（中国法律、法规、规章、政策及规范性文件）、社会主义制度、国家利益、公民合法利益、公共秩序、社会道德风尚和信息真实性等“七条底线”要求的行为。</p>
            <p>5.2 如您在企芯客户端使用支付公司提供的支付功能，例如收付、转账等功能的，应当依照法律、法规等规范性文件的规定开展，不得以非法方式使用收付、转账功能，也不得将支付、转账功能用于实施非法活动，例如实施赌博、非法吸收公众存款、集资诈骗、开展传销等违法犯罪行为，或为违法犯罪行为提供协助。因此给企芯或他人造成损失的，您应当予以赔偿。</p>
            <p>5.3 您充分了解并同意，您必须为您帐号下的一切行为负责，包括您所发表的任何内容以及由此产生的任何后果。您应对使用本服务时接触到的内容（包括但不限于任何推广或宣传信息）自行加以判断，并承担因使用内容而引起的所有风险，包括因对内容的正确性、完整性或实用性的依赖而产生的风险。企芯无法且不会对您因前述风险而导致的任何损失或损害承担责任。</p>
            <p>5.4 您充分了解并同意，企芯有权依法对您使用服务的情况进行审查、监督并根据本协议采取相应行动，包括但不限于删除信息、中止或终止服务及向有关机关报告。</p>
        </div>
        <div>
            <div class="title">六、服务费用</div>
            <p>6.1 本服务的任何免费试用或免费功能和服务不应视为企芯放弃后续收费的权利。企芯有权提前7天在企芯官网上和/或具体企芯服务应用内以公告的形式通知您收费标准及收费方式。除我们与您另有约定外，若您继续使用相关企芯服务，则需按企芯公布的收费标准支付相应费用。</p>
            <p>6.2 所有费用需通过企芯接受的支付方式支付。前述使用费不包含相关汇款等支出，否则您应补足付款或自行支付该费用。</p>
            <p>6.3 您应当自行支付使用本服务可能产生的上网费以及其他第三方收取的通讯费、信息费等。</p>
            <p>6.4 您理解并同意，您使用的本软件中的多方通信服务、语音服务及培训助手等服务，是基于电信运营商及云服务商提供的服务，因此企芯及或电信运营商、云服务商将直接或间接向您收取一定通信费用。</p>
        </div>
        <div>
            <div class="title">七、第三方应用及服务</div>
            <p>7.1 为便于您基于企芯平台使用部分第三方应用，企芯与授权的第三方应用建立合作，在企芯服务中可能包含能跳转到第三方所提供的在线服务或资源的链接，或能让您直接访问该等服务或资源，例如企芯接入支付公司为您提供收付、转账、扫码收款等支付功能；接入视频会议服务商为您提供视频会议服务；接入百度地图、高德地图等地图服务商以便您使用企芯的定位功能；以及接入第三方提供的培训直播、扫名片、AI图片识别、电子签章、呼叫中心等。您了解并同意，企芯仅为了您便利操作而提供该功能模块或服务使用入口，相关服务由前述第三方提供，我们无法控制您对该等第三方服务或资源的使用，企芯公司不对您对该应用的使用承担任何责任。</p>
            <p>7.2 您了解并同意，如我们对企芯服务及与第三方应用的合作方式做出调整、中止或终止而对第三方应用服务产生影响的，企芯不承担相应责任。</p>
            <p>7.3 您通过第三方应用或服务使用企芯时，企芯可能会调用第三方系统或者通过第三方支持您的使用或访问，使用或访问的结果由该第三方提供。</p>
            <p>7.4 您理解并同意，您在使用企芯服务中的第三方应用及服务时，除遵守本协议的约定外，还应遵守第三方用户协议等规定。您在选择使用第三服务前应充分了解第三方服务的产品功能、服务协议及隐私保护政策。</p>
        </div>
        <div>
            <div class="title">八、服务中止或终止</div>
            <p>8.1 鉴于互联网服务的特殊性，企芯有权对服务内容进行变更，也可能会中断、中止或终止全部或者任一部分的服务，包括免费服务和/或收费服务，并在企芯官网上或在企芯服务应用内以公示的形式通知您。</p>
            <p>8.2 您了解并同意，企芯可能定期或不定期地对提供网络服务的平台设备、设施和软硬件进行维护或检修，我们将提前通告您，如因此类情况而造成收费服务在合理时间内中止的，企芯无需承担责任。</p>
            <p>8.3 如存在下列违约情形之一，企芯有权立即对您中止或终止服务，并要求您赔偿损失：</p>
            <p>1)您违反第四条注册及账号管理义务；</p>
            <p>2)您使用收费服务但未按规定支付相应服务费；</p>
            <p>3)您违反第五条服务使用规范之规定。</p>
        </div>
        <div>
            <div class="title">九、隐私政策</div>
            <p>9.1 您在企芯注册的账户具有密码保护功能，以确保您基本信息资料的安全，请您妥善保管账户及密码信息。因您自身原因导致的数据丢失或被盗以及在本软件及服务中相关数据的删除或储存失败的责任由您自行承担。</p>
            <p>9.2 企芯会按现有技术，努力采取各种合理的物理、电子和管理方面的安全措施来保护您的信息，使您存储在企芯中的信息和通信内容不会被泄漏、毁损或者丢失，包括但不限于SSL、信息加密存储、数据中心的访问控制。我们对可能接触到信息的员工或外包人员也采取了严格管理，包括但不限于根据岗位的不同采取不同的权限控制，与他们签署保密协议，监控他们的操作情况等措施。但同时也请您注意在信息网络上不存在绝对完善的安全措施，请妥善保管好相关信息。</p>
            <p>9.3 其他隐私条款见《企芯隐私政策》。</p>
        </div>
        <div>
            <div class="title">十、知识产权</div>
            <p>10.1 您了解及同意，除第三方服务外的本协议项下服务包含的所有产品、技术、软件、程序、源代码、商标（包括但不限于“企芯”、“crm.qixinerp.com”等文字、图形及其组合）、服务名称、服务标识、商号、徽记、Logo、应用程序编程接口（API）及服务自带的其他信息（包括但不限于文字、图像、图片、照片、音频、视频、图表、色彩、网站架构、版面或界面设计、电子文档）的所有知识产权（包括但不限于版权、商标权、专利权、商业秘密等）及相关权利均归企芯或其关联公司所有。</p>
            <p>10.2 您应保证，对于上述权利您不得（并不得允许任何第三人）实施包括但不限于复制、传播、展示、镜像、上传、下载、出租、出借、出售、修改、转载、汇编、发表、出版、还原工程、反向汇编、反向编译，或以其它方式发现原始码等的行为，也不得向他人表明您有权展示、使用或做其他处理。</p>
            <p>10.3 您理解并同意授权企芯在宣传和推广中使用您的名称、商标、商号、服务标识，但仅限于表明您属于我们的客户或合作伙伴。</p>
        </div>
        <div>
            <div class="title">十一、有限责任</div>
            <p>11.1 服务将按“现状”和按“可得到”的状态提供。企芯在此明确声明对服务不作任何明示或暗示的保证，包括但不限于对服务的可适用性，没有错误或疏漏，持续性，准确性，可靠性，及时性，适用于某一特定用途之类的保证，声明或承诺。</p>
            <p>11.2 企芯不对由于Internet连接故障，电脑、通讯或其他系统的故障，电力故障，黑客攻击、罢工，暴乱，起义，骚乱，火灾，洪水，风暴，爆炸，战争，政府行为等不可抗力，或您自行负责的生产力或生产资料不足，或国际、国内法院的命令而造成的不能服务或延迟服务承担责任。</p>
            <p>11.3 不论是否可以预见，不论是源于何种形式的行为，企芯不对由以下原因造成的任何损失承担责任：</p>
            <p>1) 您通过本服务向第三方购买或获取任何产品，样品，数据，信息或进行交易等；</p>
            <p>2) 非企芯过错导致的未经授权的存取或修改数据或数据的传输；</p>
            <p>3) 第三方向您所作的陈述或行为。</p>
            <p>11.4 您充分了解并同意，鉴于互联网环境的特殊性，您在服务中自主分享的信息及个人资料有可能会被他人复制、转载、擅改或做其它非法用途；您在此已充分意识此类风险的存在，并确认此等风险完全由您自行承担，企芯对此不承担任何责任。</p>
            <p>11.5 您了解并同意，在使用服务过程中可能存在来自任何他人的包括威胁性的、诽谤性的、令人反感的或非法的内容或行为或对他人权利的侵犯及匿名或冒名的信息的风险，基于服务使用规范所述，该等风险应由您自行承担，企芯对此不承担任何责任。</p>
        </div>
        <div>
            <div class="title">十二、违约责任及赔偿</div>
            <p>12.1 企业组织用户同意并接受：如果企芯发现或收到他人举报您或您控制的最终用户有违反本协议约定的，企芯有权依照相关法律法规的规定对相关举报内容核实、转通知、删除、屏蔽、限制、暂停您部分或全部服务（包括但不限于限制增加成员、限制发送消息等）、终止您使用部分或全部本服务、注销您的账号及向有关机关报告，并追究您的法律责任等措施。</p>
            <p>12.2 企业组织用户同意并接受：如您违反本协议因而衍生或导致任何第三人向企芯及其关联公司提出任何索赔或请求，或企芯及其关联公司因此而发生任何损失，您同意将足额进行赔偿。</p>
        </div>
        <div>
            <div class="title">十三、有效通知</div>
            <p>13.1 企芯向您发出的任何通知，可采用电子邮件、企芯页面公开区域公告、系统内提示、手机短信或常规信件等任何一种方式，该等通知自送达之日视为有效通知。</p>
            <p>13.2 您同意，您向企芯发出的任何通知应发至第十五条公示的联系方式。</p>
        </div>
        <div>
            <div class="title">十四、争议解决及其他</div>
            <p>14.1 本协议之解释与适用，以及与本协议有关的争议，均应依照中华人民共和国法律予以处理，并排除任何冲突法的适用，并以上海市最高人民法院或有专属管辖权的法院作为第一审法院。</p>
            <p>14.2 如本协议的任何条款被视作无效或无法执行，则上述条款可被分离，其余部份则仍具有法律效力。</p>
            <p>14.3 企芯于用户过失或违约时放弃本协议规定的权利的，不得视为其对用户的其他或以后同类之过失或违约行为弃权。</p>
            <p>14.4 本协议应取代双方此前就本协议任何事项达成的全部口头和书面协议、安排、谅解和通信。</p>
            <p>14.5 您理解并同意，企芯公司有权自主决定经营策略并根据业务调整情况将本协议项下的全部权利义务一并转移给其关联公司，转让将以本协议规定的方式通知，用户承诺对此不持有异议。</p>
        </div>
        <div>
            <div class="title">十五、联系方式</div>
            <p>您可以拨打企芯5*9小时（9:00-18:00）客服热线（400-118-1622）或通过以下联系地址与我们联系：上海企芯信息科技有限公司</p>
            <p>联系部门： 企芯市场部</p>
            <p>电子邮箱：info@dqxx.com.cn</p>
            <p>联系地址：上海闵行区新龙路1333弄96号万科七宝国际29 幢303室</p>
        </div>
        <div>
            <div class="title">附录：定义</div>
            <p>1.1 企芯企业/组织用户：是指企业或其他组织申请在企芯平台上创建企业组织架构，并为组织成员（最终用户）配置账号。完成企芯企业组织创建后，由企业组织授权管理员配置、开通企芯服务并管理最终用户的使用行为。企芯企业/组织用户包括但不限于法人、政府机构、其他组织、合伙或个体工商户等。</p>
            <p>1.2 企芯企业/组织管理员：指经企芯企业组织用户授权或指定，拥有企芯企业组织用户管理平台系统操作权限，代表企业组织用户开通、管理和使用企芯服务的最终用户，诸如有权管理所属企业组织通讯录及组织成员，进行企芯工作台的相关操作和配置、设定子管理员、管理组织内的最终用户，代表所属企业组织对接和管理第三方应用、确认和签署相应企芯在线协议，申请注销所属企业组织账号等。企业用组织管理员可以为一人或多人，简称“管理员”。</p>
            <p>1.3 企芯企业/组织最终用户：是指被企业/组织用户或管理员创建，作为该企业/组织用户在“企芯”平台搭建的企业/组织架构的成员，并在企业/组织用户或管理员设置的权限内使用该企业/组织用户开通的“企芯”服务，包括管理员、其他成员用户，以下统称“您”。</p>
            <p>1.4 企芯外部授权用户：企芯企业组织用户基于业务管理场景下连接业务上下游企业的需求，将购买的企芯账号（简称“外部授权账号”）授权给被连接企业（简称“被授权企业”）使用，该被授权企业为外部授权用户，外部授权账号类别包括：企芯企业/组织账号及企业端口账号。企业端口账号仅具有企芯企业/组织账号部分使用功能。</p>
            <p>1.5 企业组织控制数据：指企业组织用户及其最终用户使用企芯服务过程中提交或产生的信息和数据，包括企业组织用户及其管理员提交或要求最终用户提供的信息、企业组织用户分配给最终用户的信息，具体关于企业组织控制数据相关约定，请参见《企芯隐私政策》。</p>
            <p>1.6 个人信息处理者：是指在个人信息处理活动中自主决定处理目的、处理方式的组织、个人。在企业组织用户及最终用户使用“企芯”服务进行移动办公、沟通与协同时，“企芯”服务相关的个人信息处理者为企业/组织用户，我们作为受托处理者，依据管理员操作、配置等指令，对企业组织控制数据（含个人信息）进行收集、存储、使用、加工、传输、提供、公开、删除等数据处理。</p>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        };
    },
    methods: {

    },
    mounted() {

    },
};
</script>

<style lang="scss" scoped>
.viewBox{
    width: 1000px;
    margin: auto;
    padding: 20px;
    .header{
        font-size: 24px;
        font-weight: bold;
        line-height: 48px;
        text-align: center;
    }
    .title{
        margin-top: 10px;
        font-size: 20px;
        font-weight: bold;
    }
    p{
        font-size: 16px;
        line-height: 32px;
        text-indent: 2em;
    }
}
</style>