import Vue from 'vue'
import axios from '@/assets/js/axios'
import SparkMD5 from 'spark-md5'

/**
 * 
 * @param {File} file 上传的文件
 * @param {(percentage: number) => void | undefined} onProgress 回调函数，监听进度
 * @returns {Promise<{name: string, size: string, url: string} | null>} 
 */
export const uploadFile = async (file, onProgress) => {
    try {
        const size = file.size
        const hash = new SparkMD5().append(`${file.name}${new Date().getTime()}`).end()
        const CHUNK_SIZE = (() => {
            const pieces = 20
            const min = 100 * 1024
            const max = 200 * 1024
            if (size < min * pieces) {
                return min
            } else if (size > max * pieces) {
                return max
            } else {
                return Math.floor(size / pieces)
            }
        })()
        const chunks = []
        for (let i = 0; i * CHUNK_SIZE < size; i++) {
            const blob = file.slice(i * CHUNK_SIZE, (i + 1) * CHUNK_SIZE)
            chunks.push(blob)
        }
        const formDataList = []
        for (let i = 0, len = chunks.length; i < len; i++) {
            const formData = new FormData()
            formData.append('file', chunks[i])
            formData.append('chunk', i.toString())
            formData.append('guid', hash)
            formDataList.push(formData)
        }
        for (let i = 0, len = formDataList.length; i < len; i++) {
            const formData = formDataList[i]
            await axios({
                method: 'POST',
                url: Vue.prototype.$diskUrl + '/FragmentUpload/Upload',
                data: formData
            })
            onProgress ? onProgress(Math.ceil(((i + 1) / len) * 100)) : null
        }
        const res = await axios({
            method: 'GET',
            url: Vue.prototype.$diskUrl + '/FragmentUpload/Merge',
            params: {
                guid: hash,
                filename: file.name
            }
        })
        return {
            remarkName: file.name,
            fileSize: file.size,
            fileUrl: 'https://crm.qixinerp.com/ossfile' + res.data.filePath,
            fileName: res.data.remarkName,
            fileMd5: res.data.fileMD5,
            m3u8Path: res.data.m3u8FilePath,
        }
    } catch {
        return null
    }
}