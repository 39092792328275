<script>
import Vue from "vue";
// import router from '@/router'
import { reactive, watch, ref, nextTick } from "@vue/composition-api";
import axios from "@/assets/js/axios";
import dayjs from "dayjs";
var relativeTime = require("dayjs/plugin/relativeTime");
require("dayjs/locale/zh-cn");
dayjs.locale("zh-cn");
dayjs.extend(relativeTime);
import { MessageBox, Message  } from 'element-ui';
import CMenu from "./components/Menu.vue";
import UseTemp from "./WAMass/components/UseTemp.vue"
import ContactInfo from "./components/ContactInfo.vue"
export default Vue.extend({
    components: { CMenu, UseTemp, ContactInfo },
    setup() {
        //用户信息
        const userInfo = JSON.parse(window.localStorage.getItem("userInfo"));

        //获取whatsApp信息
        const ConfigInfo = reactive({
            whatsApp: "",
            whatsAppHeadImg: "",
            whatsAppId: "",
            whatsAppName: "",
            remainCount: 0,
        });

        (async () => {
            try {
                const res = await axios({
                    method: "GET",
                    url: Vue.prototype.$marketUrl + "/WhatsApp/v2/FindConfig",
                });
                const {
                    whatsApp,
                    whatsAppHeadImg,
                    whatsAppId,
                    whatsAppName,
                    totalFreeCount,
                    sendCountGear,
                } = res.data;
                ConfigInfo.whatsApp = whatsApp;
                ConfigInfo.whatsAppHeadImg = whatsAppHeadImg;
                ConfigInfo.whatsAppId = whatsAppId;
                ConfigInfo.whatsAppName = whatsAppName;
                ConfigInfo.remainCount = totalFreeCount - sendCountGear;
                getLeftDialogs();
            } catch (error) {
                null;
            }
        })();

        const dialogPageIndex = ref(1);
        const dialogList = ref([]);
        const dialogLoading = ref(false);
        const isLoadOver = ref(false);
        const keyword = ref("");
        //获取左侧聊天对象
        const getLeftDialogs = () => {
            dialogLoading.value = true;
            axios({
                method: "POST",
                url: Vue.prototype.$marketUrl + "/WhatsApp/v2/GetDialogs",
                data: {
                    pageIndex: dialogPageIndex.value,
                    pageSize: 50,
                    ukey: userInfo.uKey,
                    keyword: keyword.value,
                },
            }).then((res) => {
                if(res.data.datas.length == 0){
                    isLoadOver.value = true;
                    Message.warning('数据已加载完!')
                    return false;
                }
                dialogList.value = dialogList.value.concat(res.data.datas);
                if (dialogPageIndex.value == 1 && dialogList.value.length > 0) {
                    let whatsApp = dialogList.value[0].whatsApp;
                    messageObj.clickWhatsApp = whatsApp;
                    getMessages(whatsApp);
                    sendMagessForm.whatsApp = whatsApp;
                }
            }).finally(() => {
                dialogLoading.value = false;
            });
        };
        const handleScroll = (e) =>{
            let el = e.target;
            if(el.scrollTop + el.clientHeight >= el.scrollHeight){
                if(isLoadOver.value){return false;}
                if(dialogLoading.value){return false;}
                dialogPageIndex.value = dialogPageIndex.value + 1;
                getLeftDialogs();
            }
        }
        const onSearch = () =>{
            if(dialogLoading.value){return false;}
            dialogPageIndex.value = 1;
            dialogList.value = [];
            isLoadOver.value = false;
            getLeftDialogs();
        }

        // 获取消息
        const messageObj = reactive({
            clickWhatsApp: "",
            chatlist: [],
        });
        const scrollDiv = ref();
        const getMessages = (whatsApp) => {
            axios({
                method: "POST",
                url: Vue.prototype.$marketUrl + "/WhatsApp/v2/GetMessages",
                data: {
                    pageIndex: 1,
                    pageSize: 500,
                    whatsApp: whatsApp,
                    whatsAppId: ConfigInfo.whatsAppId,
                },
            }).then((res) => {
                messageObj.chatlist = res.data.datas;
                // console.log(messageObj.chatlist);
                // 滚动到底部
                nextTick(() => {
                    scrollDiv.value.scrollTo({
                        top: scrollDiv.value.scrollHeight,
                        behavior: "smooth",
                    });
                });
            });
        };

        watch(
            () => messageObj.clickWhatsApp,
            (whatsApp) => {
                if (whatsApp) {
                    getMessages(whatsApp);
                    sendMagessForm.whatsApp = whatsApp;
                }
            }
        );

        const onRefresh = () => {
            dialogPageIndex.value = 1;
            dialogList.value = [];
            isLoadOver.value = false;
            getLeftDialogs();
        };

        //发送消息
        const sendMagessForm = reactive({
            ukey: userInfo.uKey, 
            companyKey: userInfo.companyKey, 
            whatsApp: "", 
            msagess: "", 
            imgUrl: "", 
            linkUrl: "", 
        });
        const sendLoading = ref(false);

        const sendtextmsg = async () => {
            try {
                sendLoading.value = true;
                const res = await axios({
                    method: "POST",
                    url: Vue.prototype.$marketUrl + "/WhatsApp/v2/NewSendMagess",
                    data: sendMagessForm,
                });
                if (res.isSuccess) {
                    sendMagessForm.msagess = "";
                    sendMagessForm.imgUrl = "";
                    sendMagessForm.linkUrl = "";
                    onRefresh();
                }
            } catch {
                null;
            } finally {
                sendLoading.value = false;
            }
        };
        //使用模板
        const dialogVisible = ref(false);
        const tempSend = (value) => {
            dialogVisible.value = false;
            sendMagessForm.msagess = value.sendText;
            sendMagessForm.imgUrl = value.imgUrl;
            sendMagessForm.linkUrl = value.link;
            sendtextmsg();
        }
        //联系人资料
        const drawerVisible = ref(false);
        const currentInfo = ref({});
        const openContactInfo = (infoValue) =>{
            drawerVisible.value = true;
            // console.log(infoValue);
            currentInfo.value = infoValue;
        }

        //上传文件
        const inputtttttt = ref(null);
        const fileuploaddd = async (event) => {
            try {
                sendLoading.value = true;
                const file = event.target.files[0];
                const formData = new FormData();
                formData.append("folderKey", "0");
                formData.append("remarkName", "");
                formData.append("permission", "我的");
                formData.append("file", file);
                const res = await axios({
                    headers: {
                        Authorization:
                            "Bearer " + window.localStorage.getItem("token"),
                    },
                    method: "POST",
                    url: Vue.prototype.$diskUrl + "/OtherFile/Upload",
                    data: formData,
                });

                const { fileName, fileUrl } = res;
                const resssss = await axios({
                    method: "POST",
                    url: Vue.prototype.$marketUrl + "/WhatsApp/WA/SendFile",
                    data: {
                        base64: fileUrl,
                        fileName: fileName,
                        phone: messageObj.clickWhatsApp,
                        ukey: userInfo.uKey,
                        companyKey: "",
                        eventKey: "",
                    },
                });

                if (resssss.data.sent) {
                    onRefresh();
                }
            } catch (error) {
                null;
            } finally {
                sendLoading.value = false;
            }
        };

        return {
            sendtextmsg,
            sendLoading,
            getLeftDialogs,
            getMessages,
            sendMagessForm,
            ConfigInfo,
            messageObj,
            onRefresh,
            scrollDiv,
            dayjs,
            dialogLoading,
            dialogPageIndex,
            keyword,
            onSearch,
            dialogList,
            handleScroll,
            userInfo,
            inputtttttt,
            fileuploaddd,
            //打开链接
            toOpen(url) {
                window.open(url, "_blank");
            },
            //使用模板
            dialogVisible,
            tempSend,
            //联系人资料
            drawerVisible,
            currentInfo,
            openContactInfo,
        };
    },
});
</script>

<template>
    <div class="container" style="width: 100%; height: 100%">
        <div style="display: flex; width: 100%; height: calc(100%)">
            <div>
                <CMenu active="TaskDashboardMWA" />
            </div>
            <div class="view" style="width: 100%; padding: 0;">
                <div style="width: 373px; flex-shrink: 0;box-sizing: border-box;" v-loading="dialogLoading">
                    <!-- 左侧头部：登录的whatsApp信息 -->
                    <div style="padding: 10px 20px">
                        <div style="display: inline-flex;align-items: center;">
                            <img style="width: 40px; height:40px;" :src="ConfigInfo.whatsAppHeadImg || require('@/assets/img/headImg.png')" />
                            <div style="margin-left:20px;">
                                <div style="font-size:20px;">{{ConfigInfo.whatsAppName}}</div>
                                <!-- <div style="color:#6a6a6a;font-size:14px;">今日剩余发送量：{{ConfigInfo.remainCount}}</div> -->
                            </div>
                        </div>
                    </div>
                    <!-- 搜索框 -->
                    <div class="searchBox">
                        <el-input  prefix-icon="el-icon-search" v-model="keyword" placeholder="手机号/姓名" size="small" clearable @keyup.enter.native="onSearch"></el-input>
                    </div>
                    <!-- 左侧主题内容：拉取所有聊天对话 -->
                    <div style="overflow-y: auto; height: calc(100% - 105px);" @scroll="handleScroll($event)">
                        <div @click="messageObj.clickWhatsApp = item.whatsApp" :class="{ 'activeMes': item.whatsApp === messageObj.clickWhatsApp }" v-for="item of dialogList" :key="item.whatsApp" class="oneItem">
                            <div class="touxiang" @click.stop="openContactInfo(item)">
                                {{ item.name ? item.name.slice(0, 1) : item.whatsApp.slice(0,1) }}
                            </div>
                            <div style="width: 100%;overflow: hidden;">
                                <div style="font-size: 15px;color: #2A3F58;">{{ item.name ? item.name : item.whatsApp }}</div>
                                <div class="utils-omit-1">{{item.textMessage}}</div>
                            </div>
                            <div style="flex-shrink: 0;margin-left: 10px;">
                                <span style="color: #B9B9B9;font-size: 13px;margin-right: 23px;">{{ dayjs(item.sendTime).subtract(8,'hours').fromNow() }}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- 右侧：具体聊天内容 -->
                <div style="width: 100%;background: #F6F6F6">
                    <div style="height: 48px;display: flex;align-items: center;border-bottom: 2px solid #D5DAE1;">
                        <span style="color: #2A3F58;font-weight: bold; font-size: 22px;margin-left: 19px;">{{ messageObj.clickWhatsApp }}</span>
                    </div>
                    <div style="overflow-y: auto;height: calc(100% - 170px);" ref="scrollDiv">
                        <div v-for="(item,i) of messageObj.chatlist" :key="i">
                            <div style="text-align: center;color:#AEAEAE;font-size: 15px;margin: 5px 0;">{{dayjs(item.sendTime).fromNow()}}</div>
                            <div v-if="!item.isMe" style="margin-left: 73px;color: #2A3F58;font-size: 14px;">{{ item.twName }}</div>

                            <div style="display: flex;align-items: flex-start;margin-bottom: 27px;" :style="item.isMe ? { flexDirection: 'row-reverse' } : {}">
                                <img v-if="item.isMe" style="margin: 0 11px;flex-shrink: 0;width: 50px;height: 50px;border-radius: 100%;" :src="ConfigInfo.whatsAppHeadImg || require('@/assets/img/headImg.png')" />
                                <div v-else style="margin: 0 11px;flex-shrink: 0;width: 50px;height: 50px;border-radius: 100%;background: #69A2D0;font-size: 22px;font-weight: bold;display: flex;align-items: center;justify-content: center;color: #fff;">
                                    {{ item.twName ? item.twName.slice(0, 1) : item.twId.slice(0,1) }}
                                </div>
                                <div style="width: 100%;display: flex;" :style="item.isMe ? { justifyContent: 'flex-end' } : {}">
                                    <div :style="item.isMe ? { margin: '0 0 0 100px', background: '#FFF' } : { margin: '0 100px 0 0' }" style="width: fit-content;background: #D6E1FF;font-size: 14px;color: #3a3a3a;box-shadow: 0px 0px 4px 1px rgba(0,0,0,0.12);padding: 14px 16px;word-break: break-all;border-radius: 4px;position:relative;">
                                        <el-tooltip effect="dark" content="消息发送失败" placement="top">
                                            <i class="el-icon-warning" v-if="item.isFailed"></i>
                                        </el-tooltip>
                                        <el-tooltip effect="dark" content="已读" placement="top">
                                            <i class="el-icon-check" v-if="item.isRead"></i>
                                        </el-tooltip>
                                        <!-- 音频 -->
                                        <div v-if="item.messageType === 'audio'">
                                            <audio :src="item.fileUrl" controls></audio>
                                        </div>
                                        <!-- 视频 -->
                                        <div v-if="item.messageType === 'video'">
                                            <video :src="item.fileUrl" controls width="500" height="300"></video>
                                        </div>
                                        <!-- 文件 -->
                                        <div v-if="item.messageType === 'file'" @click="toOpen(item.fileUrl)">
                                            <img src="@/assets/icon/fujian.png" />
                                            <span>{{ item.fileName }}</span>
                                        </div>
                                        <!-- 图片 -->
                                        <div v-if="item.messageType === 'image' || item.messageType === 'sticker'">
                                            <el-image :src="item.fileUrl" fit="cover" :preview-src-list="[item.fileUrl]"
                                             style="width:330px;height:160px;"></el-image>
                                        </div>
                                        <!-- 文本 -->
                                        <p class="textContent" v-show="item.textMessage">{{item.textMessage}}</p>
                                        <!-- 链接 -->
                                        <div v-show="item.link">
                                            <div class="bottomLink" @click="toOpen(item.link)">
                                                <img style="width:24px;height:24px;" src="@/assets/img/link.png" alt="" />
                                                <span>点击了解更多</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-loading="sendLoading" class="bottomBox">
                        <!-- <input v-show=false type="file" ref="inputtttttt" @change="fileuploaddd" />
            <svg @click="inputtttttt.click()" style="position: absolute; left: 50px; top: 10px;z-index: 2;cursor: pointer;" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
              <rect fill="none" width="20" height="20" />
              <g transform="translate(1.234 0.27)">
                <path fill="#99a9bf" stroke="#99a9bf" stroke-width="0.3px" d="M467.2,216.269a2.16,2.16,0,0,1-1.371-.49,2.192,2.192,0,0,1-.311-3.084l6.672-8.176a.266.266,0,0,1,.025-.03,2.742,2.742,0,0,1,3.862-.339,2.868,2.868,0,0,1,.422,3.952l-5.1,6.579a.428.428,0,1,1-.675-.525l5.1-6.577a2.007,2.007,0,0,0-.3-2.775,1.886,1.886,0,0,0-2.657.231.13.13,0,0,1-.018.02l-6.672,8.179a1.336,1.336,0,0,0,.191,1.881,1.32,1.32,0,0,0,1.775-.1,2,2,0,0,1-.271-2.7l2.6-3.435a1.464,1.464,0,0,1,2.034-.3l.045.035a1.408,1.408,0,0,1,.221,1.976l-3.88,4.859-.015.018a2.164,2.164,0,0,1-1.683.8Zm4.447-7.114a.614.614,0,0,0-.49.241l-2.6,3.435a1.142,1.142,0,0,0,.131,1.524l3.428-4.291a.551.551,0,0,0-.088-.776l-.018-.015a.6.6,0,0,0-.359-.118Zm0,0" transform="translate(-463.138 -199.444)" />
                <path fill="#99a9bf" stroke="#99a9bf" stroke-width="0.3px" d="M419.891,93.461h-12.38A2.515,2.515,0,0,1,405,90.95V76.511A2.515,2.515,0,0,1,407.511,74h7.7a2.1,2.1,0,0,1,1.406.537l5.078,4.548a2.109,2.109,0,0,1,.7,1.572V90.95a2.515,2.515,0,0,1-2.511,2.511Zm-12.38-18.607a1.659,1.659,0,0,0-1.657,1.657V90.95a1.659,1.659,0,0,0,1.657,1.657h12.38a1.659,1.659,0,0,0,1.657-1.657V80.657a1.267,1.267,0,0,0-.417-.937l-5.08-4.548a1.256,1.256,0,0,0-.839-.319Zm0,0" transform="translate(-405 -74)" />
              </g>
            </svg> -->
                        <el-input v-model="sendMagessForm.msagess" :rows="5" resize="none" style="border: none;" type="textarea" />
                        <el-button class="sendBtn" icon="el-icon-s-promotion" @click="sendtextmsg" size="small"
                         :disabled="!sendMagessForm.msagess.trim()">发 送</el-button>
                        <el-button class="useBtn" @click="dialogVisible = true;" size="small">图文模板</el-button>
                    </div>
                </div>

                <!-- 使用模板 -->
                <UseTemp :dialogVisible="dialogVisible" @onClose="dialogVisible = false;" @onSend="tempSend" />

                <!-- 联系人资料 -->
                <ContactInfo :drawerVisible="drawerVisible" :currentInfo="currentInfo" @onClose="drawerVisible = false;" />
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.bottomBox {
    position: relative;
    box-sizing: border-box;
    height: 120px;
    background: #fff;
    border: 1px solid #C0CCDA;
    border-radius: 4px;
    ::v-deep .el-textarea__inner {
        border: none;
    }
    .sendBtn{
        position: absolute;
        right: 1px;
        bottom: 1px;
    }
    .useBtn{
        position: absolute;
        right: 80px;
        bottom: 1px;
    }
}

.oneItem {
    height: 67px;
    display: flex;
    align-items: center;
    background: #fff;
    cursor: pointer;
    &:hover,
    &.activeMes {
        background: #f6f6f6;
    }
}
.touxiang{
    margin: 0  13px 0 22px;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    border-radius: 100%; 
    background: #447DAC; 
    color: #fff;
    font-size: 17px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
}

.view {
    box-sizing: border-box;
    padding: 20px;
    width: 100%;
    height: 100%;
    background: #fff;
    display: flex;

    ::v-deep
        .el-table--enable-row-hover
        .el-table__body
        tr:hover
        > td.el-table__cell {
        background: #e3eaef !important;
    }
}

.card {
    width: 100%;
    border: 1px solid #cdcdcd;
    border-radius: 4px;
    display: flex;
    padding: 10px;

    img {
        margin-right: 10px;
    }

    &:not(:last-of-type) {
        margin-right: 20px;
    }
}

.item {
    border-bottom: 1px solid #cdcdcd;
    display: flex;
    align-items: center;
    position: relative;
    margin-right: 20px;
    padding: 20px 0;
}

.el-icon-warning {
    cursor: pointer;
    color: #f56c6c;
    position: absolute;
    top: 0;
    left: -20px;
    font-size: 18px;
}
.el-icon-check{
    cursor: pointer;
    color: #67C23A;
    position: absolute;
    top: 0;
    left: -20px;
    font-size: 18px;
}

.searchBox{
    display: flex;
    padding:5px 10px;
    /deep/.el-input__inner{
        border-radius: 16px;
    }
}

.textContent{
    max-width: 330px;
    font-size: 14px;
    color: #3a3a3a;
    line-height: 20px;
}
.bottomLink{
    margin-top: 5px;
    border-top: 1px solid #dadada;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #00a5f4;
    height: 30px;
    cursor: pointer;
}
</style>
