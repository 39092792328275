import axios from 'axios'
import { Message, MessageBox,Notification } from 'element-ui';
import {delCookie} from './cookie'

// 请求拦截器，在请求发到接口之前
axios.interceptors.request.use(function(config) {
    let token = localStorage.getItem('token')
    if (token) { // 判断是否存在token，如果存在的话，则每个http header都加上token
        config.headers.Authorization = 'Bearer ' + token;
    }

    return config
}, function(error) {
    Message.error({
        message: '加载超时'
    })
    return Promise.reject(error)
})

// 响应拦截器，在响应回到页面之前
axios.interceptors.response.use(function(response) {
    // console.log(response);
    let count = 0;
    if (response.data.errorMessage == "用户已在其他地方登录，请重新登录") {
        MessageBox({
            closeOnClickModal: false,
            showClose: false,
            title: '下线通知',
            message: '账号在另一处登录或长时间无操作强制退出，请重新登录',
            confirmButtonText: '确认',
            callback: () => {
                window.location.href = '/' //跳回登录页
            },
        });
        localStorage.removeItem("token");
        localStorage.removeItem("userInfo");
        delCookie("token");
        count = 1;
    }
    if (response.data.errorMessage == "很抱歉，您无权限访问") {
        MessageBox({
            closeOnClickModal: false,
            showClose: false,
            title: '登录失效',
            message: '登录信息失效，请重新登录',
            confirmButtonText: '确认',
            callback: () => {
                window.location.href = '/' //跳回登录页
            },
        });
        localStorage.removeItem("token");
        localStorage.removeItem("userInfo");
        delCookie("token");
        count = 1;
    }
    if (response.data.errorMessage == "用户信息已修改，请重新登录") {
        MessageBox({
            closeOnClickModal: false,
            showClose: false,
            title: '登录失效',
            message: '用户信息已修改，请重新登录',
            confirmButtonText: '确认',
            callback: () => {
                window.location.href = '/' //跳回登录页
            },
        });
        localStorage.removeItem("token");
        localStorage.removeItem("userInfo");
        delCookie("token");
        count = 1;
    }
    if (response.data.errorMessage == "验证码超时"){
        count = 1;
    }
    // 系统错误内部打印
    if (response.data.isSystem){
        count = 1;
        console.error(response.data.errorMessage);
    }
    if (count == 1){ return false; }
    if (response.data.isSuccess == false && count == 0) {
        Notification.warning({ message: response.data.errorMessage,position: "top-right",duration: 2000 })
        // return false;
    }
    
    return response.data
}, function(error) {
    Message.error({
        message: '加载失败'
    })
    return Promise.reject(error)
})

export default axios