<template>
  <div class="summary-card" v-loading="loading" id="summary-card">
    <div class="card-item" v-for="item of cardItems" :key="item.title">
      <img class="image" :src="item.icon" />
      <div>
        <p class="title" style="margin-top: 0.5625rem; display: flex; align-items: center">
          <span> {{ item.title }}</span>
          <el-tooltip :content="item.tooltip" placement="bottom-start">
            <i class="el-icon-question" style="color: #909090; font-size: 0.8em; margin-left: 3px"></i>
          </el-tooltip>
        </p>
        <p class="data">{{ item.data }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "@/firm/plugins/axios";
import { mapState, mapGetters, mapMutations } from "vuex";
import LazyOperation from "@/firm/plugins/lazy-execute";
import { AnchorInditor } from "../utils";

let lazyOperation = null;

export default Vue.extend({
  data() {
    return {
      loading: false,
      cardItems: [
        {
          //0
          title: "总频次",
          icon: require("@/firm/images/company/card-icon/icon-pinci.png"),
          data: "0",
          tooltip: "展示统计时间范围内该公司的交易总次数",
        },
        {
          //1
          title: "供货商",
          icon: require("@/firm/images/company/card-icon/icon-chukou.png"),
          data: "0",
          tooltip: "展示统计时间范围内该公司的交易的供货商数据",
        },
        {
          //2
          title: "采购商",
          icon: require("@/firm/images/company/card-icon/icon-jinkou.png"),
          data: "0",
          tooltip: "展示统计时间范围内该公司的交易的采购商数据",
        },
        {
          //3
          title: "同行公司",
          icon: require("@/firm/images/company/card-icon/icon-tonghang.png"),
          data: "0",
          tooltip: "展示统计时间范围内该公司的交易的供货商的采购商，即竞争对手",
        },
        {
          //4
          title: "合作伙伴",
          icon: require("@/firm/images/company/card-icon/icon-hezuo.png"),
          data: "0",
          tooltip: "展示统计时间范围内该公司的交易的采购商的供货商",
        },
        {
          //5
          title: "总金额",
          icon: require("@/firm/images/company/card-icon/icon-jiner.png"),
          data: "0",
          tooltip: "展示统计时间范围内该公司的交易总金额",
        },
        {
          //6
          title: "总重量",
          icon: require("@/firm/images/company/card-icon/icon-zhongliang.png"),
          data: "0",
          tooltip: "展示统计时间范围内该公司的交易的采购商数据",
        },
        {
          //7
          title: "总数量",
          icon: require("@/firm/images/company/card-icon/icon-shuliang.png"),
          data: "0",
          tooltip: "展示统计时间范围内该公司的交易的供应商数据",
        },
        {
          //8
          title: "均价(金额/重量)",
          icon: require("@/firm/images/company/card-icon/icon-junjia-z.png"),
          data: "0",
          tooltip: "展示统计时间范围内该公司的交易的重量均价，总金额/总重量",
        },
        {
          //9
          title: "均价(金额/数量)",
          icon: require("@/firm/images/company/card-icon/icon-junjia-s.png"),
          data: "0",
          tooltip: "展示统计时间范围内该公司的交易的数量均价，总金额/总数量",
        },
      ],
    };
  },
  computed: {
    ...mapState(["company", "companyType"]),
    ...mapState("controlFormGlobal", {
      ie: "ie",
      dataCountry: "dataCountry",
      dateRange: "dateRange",
      hsCode: "hsCode",
      importer: "importer",
      exporter: "exporter",
      country: "country",

      exHsCode: "exHsCode",
      exExporter: "exExporter",
      exImporter: "exImporter",
      exCountry: "exCountry",
    }),
    ...mapGetters("controlFormGlobal", {
      startTime: "startTime",
      endTime: "endTime",
    }),
  },
  watch: {
    dateRange() {
      lazyOperation?.reobserve();
    },
    dataCountry() {
      lazyOperation?.reobserve();
    },
    hsCode() {
      lazyOperation?.reobserve();
    },
    importer() {
      lazyOperation?.reobserve();
    },
    exporter() {
      lazyOperation?.reobserve();
    },
    country() {
      lazyOperation?.reobserve();
    },

    exHsCode() {
      lazyOperation?.reobserve();
    },
    exExporter() {
      lazyOperation?.reobserve();
    },
    exImporter() {
      lazyOperation?.reobserve();
    },
    exCountry() {
      lazyOperation?.reobserve();
    },

    companyType() {
      lazyOperation?.reobserve();
    },
  },
  methods: {
    ...mapMutations("anchor", {
      setCurrent: "setCurrent",
    }),
    reset() {
      for (let i = 0, len = this.cardItems.length; i < len; i++) {
        this.cardItems[i].data = "0";
      }
    },
    async getData() {
      try {
        this.reset();
        this.loading = true;
        const res = await axios({
          method: "post",
          url: "/search/Credit/Summary",
          data: {
            threeEnCountryCode: this.dataCountry,
            //beginDate: "2019-01-01", //,this.startTime,
            //endDate: "2019-12-31", //this.endTime,
            beginDate: this.startTime,
            endDate: this.endTime,
            hsCode: this.hsCode,
            importer: this.importer,
            exporter: this.exporter,
            country: this.country,
            noHsCode: this.exHsCode,
            noExporter: this.exExporter,
            noImporter: this.exImporter,
            noCountry: this.exCountry,
            company: this.company,
            companyType: this.companyType,
          },
        });
        const data = res.data.result;
        this.cardItems[0].data = data.frequency; //频次
        this.cardItems[1].data = data.exporterQty; //供货商
        this.cardItems[2].data = data.importerQty; //采购商
        this.cardItems[5].data = data.value; //金额
        this.cardItems[6].data = data.weight; //重量
        this.cardItems[7].data = data.quantity; //数量
        this.cardItems[8].data = Number(data.weightAverage).toFixed(2) || "0"; //均价(金额/重量)
        this.cardItems[9].data = Number(data.quantityAverage).toFixed(2) || "0"; //均价(金额/数量)
      } catch {
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    lazyOperation = new LazyOperation(() => {
      this.getData();
    }, this.$el);
    new AnchorInditor(() => {
      this.setCurrent("HZSJ");
    }, this.$el);
  },
});
</script>

<style lang="scss" scoped>
.summary-card {
  background: #fff;
  padding: 1.875rem;
  padding-bottom: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .card-item {
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
    margin-bottom: 1.5em;
    width: 200px;
    display: flex;
    .image {
      width: 30px;
      height: 30px;
      //margin: 0.5em;
      margin: 18px 10px;
    }
    .data {
      font-weight: bold;
      color: var(--themeColor, #3152AF);
      font-size: 22px;
    }
  }
}
</style>
