<template>
    <div class="teamManage">
        <div class="header">
            <el-button size="small" type="primary" icon="el-icon-plus" @click="toNewMember">新增成员</el-button>
            <div class="nodeName">{{clickNodeName}}</div>
        </div>

        <div style="display: flex;">
            <div class="organization">
                <el-tree :data="treeData" :props="defaultProps" node-key="key" :current-node-key="currentNodeKey" highlight-current default-expand-all @node-click="handleNodeClick">
                    <span class="tree-node" slot-scope="{ data }">
                        <span>{{ data.name }} ({{data.count}}) </span>
                        <span>
                            <el-dropdown size="mini">
                                <span style="color:'var(--themeColor)';" @click.stop="() =>{}"><i class="el-icon-more"></i></span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item @click.native="() => option(data,'add')">添加子部门</el-dropdown-item>
                                    <el-dropdown-item @click.native="() => option(data,'update')">修改名称</el-dropdown-item>
                                    <el-dropdown-item @click.native="() => option(data,'delete')">删除</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </span>
                    </span>
                </el-tree>
            </div>
            <div class="rightBox">
                <div class="searchBox">
                    <el-input v-model="searchName" placeholder="名称" size="small" style="width:200px;margin-right:15px;"></el-input>
                    <el-button size="small" type="primary" icon="el-icon-search" @click="onSearch">搜索</el-button>
                    <el-button type="primary" size="small" style="float:right;" @click="distributionStaff">分配员工</el-button>
                </div>
                
                <div class="boxTable">
                    <el-table style="width:100%;cursor: pointer;" v-loading="tableLoading" :data="staffList" :header-cell-style="{'background-color':'#F3F3F3','color':'#3A3A3A'}" :row-style="rowStyle">
                        <el-table-column prop="name" label="姓名"></el-table-column>
                        <el-table-column prop="sex" label="性别"></el-table-column>
                        <el-table-column prop="email" label="邮箱"></el-table-column>
                        <el-table-column prop="phone" label="手机号"></el-table-column>
                        <el-table-column prop="position" label="职位"></el-table-column>
                        <el-table-column label="操作" width="150">
                            <template slot-scope="scope">
                                <el-link type="primary" @click.stop="toEditMember(scope.row)">编辑</el-link>
                                <span style="padding:0 5px;color:#C7C7C7;">|</span>
                                <el-popconfirm title="确认移除该员工？" @confirm="toRemove(scope.row)">
                                    <el-link type="danger" slot="reference">移除</el-link>
                                </el-popconfirm>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
              
                <el-pagination background layout="total,sizes,prev,pager,next" :page-sizes="[10, 20, 40]" :page-size.sync="pageSize" @size-change="pageSizeChange" :total="totalItems" style="float:right;margin:15px 0;" :current-page.sync="pageIndex" @current-change="pageIndexChange"></el-pagination>

            </div>
        </div>

        <!-- 添加子部门 -->
        <el-dialog title="添加子部门" :visible.sync="addVisible" width="30%">
            <el-input v-model="addName" placeholder="请输入子部门名称" size="small"></el-input>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addVisible = false" size="small">取 消</el-button>
                <el-button type="primary" @click="addOrganization" size="small" :loading="btnLoading">确 定</el-button>
            </span>
        </el-dialog>

        <!-- 编辑名称 -->
        <el-dialog title="修改名称" :visible.sync="updateVisible" width="30%">
            <el-input v-model="updateName" size="small"></el-input>
            <span slot="footer" class="dialog-footer">
                <el-button @click="updateVisible = false" size="small">取 消</el-button>
                <el-button type="primary" @click="updateOrganization" size="small" :loading="btnLoading">确 定</el-button>
            </span>
        </el-dialog>

        <!-- 分配员工 -->
        <el-dialog title="分配员工" :visible.sync="allotVisible" width="800px">
            <el-radio-group v-model="allotType" size="small" @change="allotPageChange(1)" style="margin-bottom:10px;">
                <el-radio-button label="0">未分配</el-radio-button>
                <el-radio-button label="1">已分配</el-radio-button>
            </el-radio-group>
            <el-input placeholder="搜索员工" v-model="allotKeyword" size="small">
                <el-button slot="append" icon="el-icon-search" @click="allotPageChange(1)"></el-button>
            </el-input>
            <div style="overflow: hidden;">
                <el-table :data="allotList" @selection-change="handleSelectionChange" style="width: 100%" v-loading="allotLoading" size="small">
                    <el-table-column type="selection" width="55"></el-table-column>
                    <el-table-column prop="name" label="姓名"></el-table-column>
                    <el-table-column prop="enName" label="英文名称"></el-table-column>
                    <el-table-column prop="phone" label="手机号"></el-table-column>
                    <el-table-column prop="email" label="邮箱"></el-table-column>
                </el-table>
                <el-pagination background layout="prev, pager, next" :page-size="10" :total="allotTotalItems" small style="float:right;margin: 10px 0;" v-show="allotTotalItems != 0" @current-change="allotPageChange" :current-page="allotPageIndex"></el-pagination>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="allotVisible = false" size="small">取 消</el-button>
                <el-button type="primary" @click="distributionStaffOk" size="small" :loading="btnLoading">确 定</el-button>
            </span>
        </el-dialog>



        <!-- 新增成员/编辑成员 -->
        <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="500px">
            <el-form :model="submitData" label-width="100px" size="small">
                <el-form-item label="真实姓名">
                    <el-input v-model="submitData.userCnName"></el-input>
                </el-form-item>
                <el-form-item label="性别">
                    <el-radio v-model="submitData.sex" :label="1">男</el-radio>
                    <el-radio v-model="submitData.sex" :label="0">女</el-radio>
                </el-form-item>
                <el-form-item label="邮箱">
                    <el-input v-model="submitData.mailAddress"></el-input>
                </el-form-item>
                <el-form-item label="手机号">
                    <el-input v-model="submitData.phone"></el-input>
                </el-form-item>
                <el-form-item label="职位">
                    <el-input v-model="submitData.position"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="onSubmit" size="small" :loading="btnLoading">提 交</el-button>
                <el-button @click="dialogVisible = false;" size="small">取 消</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
export default {
    data() {
        return {
            treeData: [],
            defaultProps: {
                children: "children",
                label: "name",
            },
            currentNodeKey: "", //当前节点key
            currentParentStr: "", //当前节点组织树编码
            clickNodeName: "", //当前节点名称

            //列表展示
            tableLoading: false,
            staffList: [],
            totalItems: 0,
            pageIndex: 1,
            pageSize: 10,
            searchName: "",
            //添加子部门，修改名称操作
            operationObj: "",
            addVisible: false,
            addName: "",
            updateVisible: false,
            updateName: "",
            //分配员工
            allotVisible: false,
            allotLoading: false,
            allotKeyword: "",
            allotType: "0",
            allotList: [],
            allotPageIndex: 1,
            allotTotalItems: 0,
            selectionList: [],
            //新增成员/编辑成员
            dialogTitle: "",
            dialogVisible: false,
            btnLoading: false,
            submitData: {},
        };
    },
    methods: {
        rowStyle({rowIndex}){
            // 表格隔行换色
            if(rowIndex % 2 == 1){
                return {'background-color':'#F9FAFC'}
            }
        },

        //获取列表
        onSearch(){
            this.pageIndex = 1;
            this.getStaffList();
        },
        getStaffList(){
            this.tableLoading = true;
            this.$axios.post(`${this.$personnelUrl}/Organization/GetUsersByOrganizationTree`,{
                parentStr: this.currentParentStr,
                phone: "",
                name: this.searchName,
                email: "",
                pageIndex: this.pageIndex,
                pageSize: 10,
            }).then((res) => {
                const { isSuccess, data } = res;
                this.tableLoading = false;
                if (isSuccess) {
                    this.staffList = data.datas;
                    this.totalItems = data.totalItems;
                }
            });
        },
        pageIndexChange(page) {
            this.pageIndex = page;
            this.getStaffList();
        },
        pageSizeChange() {
            this.pageIndex = 1;
            this.getStaffList();
        },
        
        //新增成员/编辑成员
        toNewMember() {
            this.dialogTitle = '新增成员';
            this.dialogVisible = true;
            this.submitData = {
                userCnName: "",
                mailAddress: "",
                phone: "",
                position: "",
                sex: 1,
            }
        },
        toEditMember(row){
            this.dialogTitle = '编辑成员';
            this.dialogVisible = true;
            this.submitData = {
                ukey: row.ukey,
                userCnName: row.name,
                mailAddress: row.email,
                phone: row.phone,
                position: row.position,
                sex: row.sex == '女' ? 0 : 1,
            }
        },
        onSubmit(){
            if(this.dialogTitle == '新增成员'){
                this.btnLoading = true;
                this.$axios.post(`${this.$authUrl}/api/Staff/Add`, this.submitData).then((res) => {
                    const { isSuccess } = res;
                    this.dialogVisible = false;
                    this.btnLoading = false;
                    if (isSuccess) {
                        this.$message({ message: "新增成功", type: "success" });
                        this.getStaffList();
                    }
                });
            }
            if(this.dialogTitle == '编辑成员'){
                this.btnLoading = true;
                this.$axios.post(`${this.$authUrl}/api/Staff/Update`, this.submitData).then((res) => {
                    const { isSuccess } = res;
                    this.dialogVisible = false;
                    this.btnLoading = false;
                    if (isSuccess) {
                        this.$message({ message: "编辑成功", type: "success" });
                        this.getStaffList();
                    }
                });
            }
        },
        //移除成员
        toRemove(row){
            this.$axios.post(`${this.$personnelUrl}/Organization/BulkDeleteOrganizationRelation`,{
                uKeys:[row.ukey],
                organizationKey: this.currentNodeKey,
            }).then(res=>{
                if(res.isSuccess){
                    this.getStaffList();
                }
            })
        },

        //组织结构
        getTreeData(){
            this.$axios.get(`${this.$personnelUrl}/Organization/GetOrganization`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.treeData = data;
                    //默认初始值
                    this.currentNodeKey = data[0].key;
                    this.currentParentStr = data[0].parentStr;
                    this.clickNodeName = data[0].name;
                    this.pageIndex = 1;
                    this.getStaffList();
                }
            })
        },
        handleNodeClick(data) {
            this.currentNodeKey = data.key;
            this.currentParentStr = data.parentStr;
            this.clickNodeName = data.name;
            this.pageIndex = 1;
            this.getStaffList();
        },
        option(data, type) {
            this.operationObj = data;
            if (type == "add") {
                this.addVisible = true;
                this.addName = "";
            } else if (type == "update") {
                this.updateVisible = true;
                this.updateName = data.name;
            } else if (type == "delete") {
                this.$confirm("删除该组织会将组织里面的人员全部释放。是否确认删除?","提示",{
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    this.$axios.get(`${this.$personnelUrl}/Organization/DeleteOrganization?key=${data.key}`).then((res) => {
                        const { isSuccess } = res;
                        if (isSuccess) {
                            this.$message({message: "删除成功",type: "success",});
                            this.getTreeData();
                        }
                    });
                }).catch(() => {});
            }
        },
        addOrganization() {
            if (!this.addName) {
                this.$message({ message: "请填写部门名称", type: "warning" });
                return false;
            }
            this.btnLoading = true;
            this.$axios.post(`${this.$personnelUrl}/Organization/AddOrganization`, {
                key: "",
                name: this.addName,
                parentStr: this.operationObj.parentStr,
                parentId: this.operationObj.id,
            }).then((res) => {
                const { isSuccess } = res;
                this.addVisible = false;
                this.btnLoading = false;
                if (isSuccess) {
                    this.$message({ message: "添加成功", type: "success" });
                    this.getTreeData();
                }
            });
        },
        updateOrganization() {
            const { key, parentStr, parentId } = this.operationObj;
            if (!this.updateName) {
                this.$message({ message: "请填写部门名称", type: "warning" });
                return false;
            }
            this.btnLoading = true;
            this.$axios.post(`${this.$personnelUrl}/Organization/UpdateOrganization`, {
                key: key,
                name: this.updateName,
                parentStr: parentStr,
                parentId: parentId,
            }).then((res) => {
                const { isSuccess } = res;
                this.updateVisible = false;
                this.btnLoading = false;
                if (isSuccess) {
                    this.$message({ message: "编辑成功", type: "success" });
                    this.getTreeData();
                }
            });
        },

        //分配员工
        distributionStaff() {
            this.allotVisible = true;
            this.allotPageIndex = 1;
            this.getAllotList();
        },
        getAllotList() {
            this.allotLoading = true;
            this.$axios.post(`${this.$personnelUrl}/Organization/GetDistribution`, {
                name: this.allotKeyword,
                type: this.allotType,
                pageIndex: this.allotPageIndex,
                pageSize: 10,
            }).then((res) => {
                const { isSuccess, data } = res;
                this.allotLoading = false;
                if (isSuccess) {
                    this.allotList = data.datas;
                    this.allotTotalItems = data.totalItems;
                }
            });
        },
        allotPageChange(currentPage) {
            this.allotPageIndex = currentPage;
            this.getAllotList();
        },
        handleSelectionChange(val) {
            this.selectionList = val;
        },
        distributionStaffOk() {
            const users = [];
            this.selectionList.forEach((item) => {
                users.push({
                    uKey: item.ukey,
                    isManager: false,
                });
            });
            this.btnLoading = true;
            this.$axios.post(`${this.$personnelUrl}/Organization/DistributionOrganization`,{
                organizationKey: this.currentNodeKey,
                users: users,
            }).then((res) => {
                const { isSuccess } = res;
                this.allotVisible = false;
                this.btnLoading = false;
                if (isSuccess) {
                    this.$message({ message: "分配成功", type: "success" });
                    this.pageIndex = 1;
                    this.getStaffList();
                }
            });
        },
    },
    mounted() {
        this.getTreeData();
    },
};
</script>

<style lang="scss" scoped>
.teamManage {
    background: #ffffff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    height: calc(100vh - 80px);
    .header {
        padding: 20px;
        height: 32px;
        line-height: 32px;
        border-bottom: 1px solid #e6e7eb;
        display: flex;
        .nodeName{
            display: inline-block;
            font-size: 20px;
            margin-left: 100px;
        }
    }
    .organization {
        width: 200px;
        height: calc(100vh - 155px);
        border-right: 1px solid #e6e7eb;
        box-sizing: border-box;
        .tree-node {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            padding-right: 8px;
        }
    }
    .rightBox{
        width: calc(100% - 240px);
        padding: 20px;
        overflow: hidden;
        .searchBox{
            margin-bottom: 20px;
        }
        .txt {
            height: 450px;
            overflow: auto;
            line-height: 40px;
            
        }
    }
}
/deep/.el-dialog__body {
    padding: 10px 20px;
}
</style>