<template>
    <div class="viewBox">
        <div class="leftBox">
            <div class="leftTop">
                <div style="color:#090909;font-size:18px;margin-bottom:10px;">可用发件量: {{surplusCount}}封</div>
                <div class="lineItem">
                    <p style="flex-shrink:0;color:#9C9C9C;font-size:14px;">任务主题</p>
                    <el-input class="elinput" v-model="sendParams.subject" size="small" />
                </div>
                <div class="lineItem" style="cursor: pointer;" @click="joinDialogVisible = true;">
                    <p style="flex-shrink:0;color:#9C9C9C;font-size:14px;">收件人</p>
                    <i class="el-icon-circle-plus-outline"></i>
                    <div class="receiveList">
                        <el-tag size="mini" v-for="(ele,index) in sendParams.subscribers.slice(0,5)" :key="index">
                            {{ele.name}}&lt;{{ele.email}}&gt;
                        </el-tag>
                    </div>
                    <p style="color:#9C9C9C;font-size:14px;padding-left:10px;">
                        共<span style="color:var(--themeColor);">{{sendParams.subscribers.length}}</span>个
                    </p>
                </div>
                <div class="EditorContent">
                    <Editor v-model="sendParams.bodyHtml" :init="{
                        language: 'zh_CN',
                        resize: false,
                        height: 'calc(100vh - 380px)',
                        branding: false,
                        menubar: false,
                        toolbar: 'undo redo | emoticons | image link | formatselect fontsizeselect forecolor | bold underline italic | alignleft aligncenter alignright | preview fullscreen insertVariable',
                        plugins: 'emoticons image link preview fullscreen',
                        setup,images_upload_handler,init_instance_callback}"
                        tinymceScriptSrc="tinymce/tinymce.min.js"
                        apiKey="l6y3tipsjmozdy96gs2qcbjjjaauujp07q4r0rpnyxqjh4ac">
                    </Editor>
                </div>
                <div class="attach_timing">
                    <input @change="onInputChange" ref="InputDom" type="file" v-show="false" />
                    <div style="margin-right:20px;cursor: pointer;" @click="uploadClick(1)">
                        <img src="../image/paperclip-gray.png" />
                        <span style="margin-left: 5px">附件</span>
                    </div>
                    <div style="margin-right:20px;cursor: pointer;" @click="uploadClick(2)">
                        <img src="../image/paperclip-orange.png" />
                        <span style="margin-left: 5px">超大附件</span>
                    </div>
                    <el-checkbox v-model="sendParams.isTiming" @change="timeClick">定时发送</el-checkbox>
                    <el-date-picker ref="datepicker" v-model="sendParams.sendTime" value-format="yyyy-MM-dd HH:mm:ss" class="c-el-date-picker" type="datetime" size="mini" placeholder="定时发送时间" style="width: 180px;" />
                </div>
                <el-progress v-if="progressVisible" :text-inside="true" :stroke-width="20" :percentage="progressNumber"></el-progress>
                <div class="attachList">
                    <div class="attachItem" v-for="(item, index) of sendParams.attachments" :key="item.fileUrl">
                        <div style="display: flex;align-items: center;font-size: 14px;">
                            <i class="el-icon-document"></i>
                            <span style="margin-left: 5px">{{item.fileName}}</span>
                            <span style="margin-left: 10px">{{item.fileSize}}</span>
                        </div>
                        <i @click="sendParams.attachments.splice(index, 1)" class="el-icon-close" style="cursor: pointer;color: #F56C6C;"></i>
                    </div>
                </div>
            </div>
            <div class="leftBottom">
                <el-form size="small" :inline="true">
                    <el-form-item label="发件人邮箱" required>
                        <el-select v-model="sendParams.senderAddress" filterable allow-create default-first-option @change="chooseEmail" style="width:200px;">
                            <el-option v-for="item in emailOption" :key="item.email" :label="item.email" :value="item.email">
                                <span>邮箱:{{item.email}}</span>
                                <span style="padding: 0 10px;"></span>
                                <span>昵称:{{item.name}}</span>
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="发件人昵称" required>
                        <el-input v-model="sendParams.senderNickName" placeholder="发件人昵称" style="width:120px;" />
                    </el-form-item>
                </el-form>
                <div class="lastOperation">
                    <el-button size="small" @click="onSave" :loading="btnLoading">保存草稿</el-button>
                    <el-button type="primary" size="small" @click="onSend" :loading="btnLoading">发 送</el-button>
                </div>
            </div>
        </div>
        <div class="rightBox">
            <RightOption @useTemplate="useTemplate" @fillMail="fillMail" />
        </div>

        <JoinRecipients :joinDialogVisible="joinDialogVisible" @closeDialog="joinDialogVisible = false" @onFinish="onFinishSelect" />
    </div>
</template>

<script>
var preProssesInnerHtml = "";
import Editor from '@tinymce/tinymce-vue';
import { getTinymce } from '@tinymce/tinymce-vue/lib/cjs/main/ts/TinyMCE';
import JoinRecipients from "./components/JoinRecipients.vue";
import RightOption from "./components/RightOption.vue";
import { uploadFile } from './api';
export default {
    components: { Editor, JoinRecipients, RightOption },
    data() {
        return {
            surplusCount: 0,
            //主要参数
            sendParams:{
                eventNo: "",
                subscribers: [],
                subject: "",
                bodyHtml: "",
                isTiming: false,
                sendTime: "",
                attachments: [],
                taskName: "",
                senderAddress: "",
                senderNickName: "",
                replyEmail: "",
                taksType: 0,
                templateKey: "",
            },
            btnLoading: false,
            //其他
            emailOption: [],
            joinDialogVisible: false,
            myClueKeys: [],
            //上传
            uploadType: 1,
            progressVisible: false,
            progressNumber: 0,
            //个性签名
            signList: [],
        };
    },
    methods: {
        //插入变量
        setup(editor){
            let themeColor = localStorage.getItem('theme');
            editor.ui.registry.addMenuButton('insertVariable', {
                text: '邮件变量',
                fetch: (callback) => {
                    var items = [
                        {
                            type: 'menuitem',
                            text: '姓名变量',
                            onAction: () => {
                                var contentText = editor.getContent();
                                if (contentText.indexOf("[FIRSTNAME]") < 0) {
                                    editor.insertContent(`<span contenteditable="false" style="color:${themeColor};cursor: pointer;">[FIRSTNAME]</span>`);
                                }
                            }
                        },
                        {
                            type: 'menuitem',
                            text: '公司名变量',
                            onAction: () => {
                                var contentText = editor.getContent();
                                if (contentText.indexOf("[COMPANY]") < 0) {
                                    editor.insertContent(`<span contenteditable="false" style="color:${themeColor};cursor: pointer;">[COMPANY]</span>`);
                                }
                            }
                        },
                        {
                            type: 'menuitem',
                            text: '个性签名',
                            onAction: () => {
                                if(this.signList.length == 0){
                                    this.$message({ message: '暂无个性签名,请前往设置页面制定签名!', type: 'warning'});
                                }else{
                                    let htmlContent = this.signList[0].htmlContent;
                                    editor.insertContent(htmlContent);
                                }
                            }
                        },
                    ];
                    callback(items);
                }
            });
        },
        init_instance_callback(editor){
            editor.on('BeforeExecCommand', (e)=> {
                if (e.command == "mcePreview") {
                    preProssesInnerHtml = editor.getContent();
                    if(this.sendParams.subscribers.length > 0 ){
                        let cItem = this.sendParams.subscribers[0];
                        let previewHtml = preProssesInnerHtml.replace("[FIRSTNAME]",cItem.name).replace("[COMPANY]",cItem.customerName);
                        editor.setContent(previewHtml);
                    }
                }
            });
            editor.on("ExecCommand", (e)=> {
                if (e.command == "mcePreview") {
                    editor.setContent(preProssesInnerHtml);
                    editor.insertContent('');
                }
            });
        },
        //插入图片
        async images_upload_handler(blobInfo) {
            try {
                const formData = new FormData();
                formData.append("folderKey", "0");
                formData.append("remarkName", "");
                formData.append("permission", "我的");
                formData.append("file", blobInfo.blob());
                const res = await this.$axios({
                    method: "POST",
                    url: this.$diskUrl + "/OtherFile/Upload",
                    data: formData,
                });
                return res.fileUrl;
            } catch (error) {
                this.$message.error("插入图片失败");
            }
        },
        //获取发送点数
        getSendPoint(){
            this.$axios({
                method: "GET",
                url: this.$marketUrl + "/api/Bill/GetSendPoint",
            }).then(res =>{
                this.surplusCount = res.data.surplusCount;
            })
        },
        //获取邮箱配置
        getEmailBox(){
            this.$axios({method: 'GET',url: this.$marketUrl + '/MassSending/GetEmailBox',}).then((res) =>{
                const {isSuccess ,data} = res;
                if (isSuccess) {
                    data.forEach(ele =>{
                        ele.email = ele.key;
                        ele.name = ele.value;
                    })
                    this.emailOption = data;
                }
            })
        },
        chooseEmail(value){
            let aItem = this.emailOption.filter(ele => ele.email == value);
            if(aItem.length > 0){
                this.sendParams.senderNickName = aItem[0].name;
            }
        },
        //勾选收件人完成
        onFinishSelect(recipient, checkedKey){
            this.joinDialogVisible = false;
            this.sendParams.subscribers = recipient;
            this.myClueKeys = checkedKey;
        },
        //使用模板
        useTemplate(subject,textHtml,templateKey){
            this.sendParams.subject = subject;
            this.sendParams.bodyHtml = textHtml;
            this.sendParams.templateKey = templateKey;
        },
        //使用AI写信
        fillMail(generateHtml){
            this.sendParams.bodyHtml = generateHtml;
        },
        //上传:附件(1)/超大附件(2)
        uploadClick(type){
            this.uploadType = type;
            this.$refs.InputDom.click();
        },
        async onInputChange(event){
            const file = event.target.files[0];
            if(this.uploadType == 1 && file.size >= 100 * 1024 * 1024){
                this.$message({message: '超过100M附件请上传到超大附件!',type: 'warning'});
                return false;
            }
            this.progressVisible = true;
            this.progressNumber = 0;
            const resObj = await uploadFile(file,(number)=>{
                this.progressNumber = number;
            })
            this.progressVisible = false;
            if(this.uploadType == 1){
                const {remarkName,fileUrl,formatFileSize} = resObj;
                resObj && this.sendParams.attachments.push({ fileName: remarkName,fileUrl,fileSize: formatFileSize });
            }
            if(this.uploadType == 2){
                const {remarkName,fileUrl,formatFileSize} = resObj;
                this.$axios.post(`${this.$diskUrl}/Clound/SaveCloudAttachment`,{
                    folderKey: "0",
                    ...resObj,
                }).then((res) => {
                    const { isSuccess } = res;
                    if (isSuccess) {
                        this.sendParams.attachments.push({ fileName: remarkName,fileUrl,fileSize: formatFileSize });
                    }
                })
            }
        },
        //勾选定时发送，日历自动聚焦
        timeClick(value){
            if(value){
                setTimeout(() => {
                    this.$refs.datepicker.$el.firstElementChild.focus();
                }, 200)
            }
        },


        //保存草稿以及发送功能
        isBefore(){
            this.sendParams.taskName = this.sendParams.subject;
            this.sendParams.replyEmail = this.sendParams.senderAddress;
            // console.log(this.sendParams);
            const {subject,subscribers,bodyHtml,senderAddress,senderNickName} = this.sendParams;
            if(!subject){ this.$message({message: '请填写任务主题!',type: 'warning'}); return false; }
            if(subscribers.length==0){ this.$message({message: '请选择收件人!',type: 'warning'}); return false; }
            if(!bodyHtml){ this.$message({message: '请填写邮件内容!',type: 'warning'}); return false; }
            if(!senderAddress){ this.$message({message: '请填写发件人邮箱!',type: 'warning'}); return false; }
            if(!senderNickName){ this.$message({message: '请填写发件人昵称!',type: 'warning'}); return false; }
            return true;
        },
        async onSave(){
            if(this.isBefore()){
                this.btnLoading = true;
                const res = await this.$axios({
                    method: 'POST',
                    url: this.$marketUrl + '/MassSending/SaveEvent',
                    data: this.sendParams,
                })
                this.btnLoading = false;
                if (res.isSuccess) {
                    this.$message({message: '保存成功!',type: 'success'});
                    this.$router.replace({ name: 'TaskDashboard' })
                }
            }
        },
        async onSend(){
            if(this.isBefore()){
                this.btnLoading = true;
                const res = await this.$axios({
                    method: 'POST',
                    url: this.$marketUrl + '/MassSending/SaveEvent',
                    data: this.sendParams,
                })
                const eventNo = res.data;
                this.btnLoading = false;
                if (res.isSuccess) {
                    const resTwo = await this.$axios({
                        method: 'GET',
                        url: this.$marketUrl + '/MassSending/SendEvent',
                        params: {
                            eventNo: eventNo,
                        }
                    })
                    // console.log(eventNo);
                    if (resTwo.isSuccess) {
                        if (this.myClueKeys.length > 0) {
                            this.$axios({
                                method: 'POST',
                                url: this.$clueUrl + '/MyClue/AddClueMassSendingRelation',
                                data: {
                                    eventNo: eventNo,
                                    myClueKeys: this.myClueKeys,
                                }
                            })
                        }
                        this.$message({message: '发送成功!',type: 'success'});
                        this.$router.replace({ name: 'TaskDashboard' })
                    }
                }
            }
        },

        //编辑内容初始化
        editContentInit(){
            let eventNo = this.$route.query.eventNo;
            if(eventNo){
                this.$axios({
                    method: 'GET',
                    url: this.$marketUrl + '/MassSending/GetEventDetailed',
                    params: { eventNo }
                }).then(res => {
                    const {isSuccess, data} = res;
                    if(isSuccess){
                        this.sendParams = data;
                    }
                })
            }
        },

        //获取个性签名
        getSign(){
            this.$axios.get(`${this.$mailUrl}/v1/config/Sign`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.signList = data;
                }
            });
        },
    },
    mounted() {
        this.getSendPoint();
        this.getEmailBox();
        this.editContentInit();
        this.getSign();
    },
};
</script>

<style lang="scss" scoped>
.viewBox{
    display: flex;
    .leftBox{
        width: calc(100% - 520px);
        height: calc(100vh - 80px);
        .leftTop{
            width: 100%;
            height: calc(100vh - 180px);
            background: #ffffff;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            box-sizing: border-box;
            padding: 20px;
            overflow: auto;
            .lineItem{
                display: flex;
                align-items: center;
                border-bottom: 1px solid #C7C7C7;
                margin-bottom: 10px;
                height: 32px;
                .elinput{
                    /deep/.el-input__inner {
                        border: none;
                    }
                }
            }
            .el-icon-circle-plus-outline{
                cursor: pointer;
                color: var(--themeColor);
                font-size: 20px;
                margin: 0 5px;
            }
        }
        .leftBottom{
            width: 100%;
            height: 80px;
            margin-top: 20px;
            background: #ffffff;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            box-sizing: border-box;
            padding: 24px;
            display: flex;
            justify-content: space-between;
        }
    }
    .rightBox{
        margin-left: 20px;
        width: 500px;
        height: calc(100vh - 80px);
        background: #ffffff;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    }
}
.receiveList{
    display: flex;
    width: calc(100% - 140px);
    height: 32px;
    align-items: center;
    overflow: hidden;
}
.attach_timing{
    display: flex;
    height: 32px;
    line-height: 32px;
    background: #FAFAFA;
    padding: 0 20px;
    font-size: 14px;
    color: var(--themeColor);
}
.attachList{
    .attachItem{
        display: flex;
        align-items: center; 
        justify-content: space-between;
    }
}
.c-el-date-picker {
    ::v-deep .el-input__inner {
        border: none;
    }
}
</style>