<template>
<div>
    <div v-if="ie=='i'">
        <!-- 吉尔吉斯斯坦(KGZ)进口提单明细 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th width="25%">进口商代码</th>
                        <td width="25%">{{billInfo.ImporterCode}}</td>
                        <th width="25%">进口商电话</th>
                        <td width="25%">{{billInfo.ImporterTel}}</td>
                    </tr>
                    <tr>
                        <th>进口商ID</th>
                        <td>{{billInfo.ImporterId}}</td>
                        <th>进出口类型</th>
                        <td>{{billInfo.Type}}</td>
                    </tr>
                    <tr>
                        <th>净重</th>
                        <td>{{billInfo.Weight}}</td>
                        <th>毛重</th>
                        <td>{{billInfo.GrossWeight}}</td>
                    </tr>
                    <tr>
                        <th>原产国</th>
                        <td>{{billInfo.Country}}</td>
                        <th>中转国</th>
                        <td>{{billInfo.DestinationCountry}}</td>
                    </tr>
                    <tr>
                        <th>起运国</th>
                        <td>{{billInfo.FromCountry}}</td>
                        <th>起运国简称</th>
                        <td>{{billInfo.FromCountryCode}}</td>
                    </tr>
                    <tr>
                        <th>汇率</th>
                        <td>{{billInfo.ExchangeRate}}</td>
                        <th>美元汇率</th>
                        <td>{{billInfo.ExchangeRateToUsd}}</td>
                    </tr>
                    <tr>
                        <th>原产地区</th>
                        <td>{{billInfo.OriginPlace}}</td>
                        <th>运输方式</th>
                        <td>{{billInfo.TransportType}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">其他信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">报关单号</th>
                        <td width="25%">{{billInfo.DeclarationNumber}}</td>
                        <th width="25%">项目编号</th>
                        <td width="25%">{{billInfo.Item}}</td>
                    </tr>
                    <tr>
                        <th>子项编号</th>
                        <td>{{billInfo.Subitem}}</td>
                        <th>声明类型代码</th>
                        <td>{{billInfo.DeclarationTypeCode}}</td>
                    </tr>
                    <tr>
                        <th>海关办公室</th>
                        <td>{{billInfo.Customer2}}</td>
                        <th>当局海关</th>
                        <td>{{billInfo.Customer}}</td>
                    </tr>
                    <tr>
                        <th>包装数量</th>
                        <td>{{billInfo.PackagesComplete}}</td>
                        <th>无包装数量</th>
                        <td>{{billInfo.PackagesInComplete}}</td>
                    </tr>
                    <tr>
                        <th>Dop数量</th>
                        <td>{{billInfo.QuantityDop}}</td>
                        <th>货物总价值</th>
                        <td>{{billInfo.TotalCargoValue}}</td>
                    </tr>
                    <tr>
                        <th>海关价值</th>
                        <td>{{billInfo.CustomsValue}}</td>
                        <th>货物价值</th>
                        <td>{{billInfo.CargoValue}}</td>
                    </tr>
                    <tr>
                        <th>统计价值</th>
                        <td>{{billInfo.StatValueUsd}}</td>
                        <th>国际贸易术语</th>
                        <td>{{billInfo.Incoterms}}</td>
                    </tr>
                    <tr>
                        <th>车辆数量</th>
                        <td>{{billInfo.VehicleNum}}</td>
                        <th>海关税</th>
                        <td>{{billInfo.Customtax}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
    <div v-if="ie=='e'">
        <!-- 吉尔吉斯斯坦(KGZ)出口提单明细 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th width="25%">报关单号</th>
                        <td width="25%">{{billInfo.DeclarationNumber}}</td>
                        <th width="25%">进口商电话</th>
                        <td width="25%">{{billInfo.ImporterTel}}</td>
                    </tr>
                    <tr>
                        <th>进口商ID</th>
                        <td>{{billInfo.ImporterId}}</td>
                        <th>进口商代码</th>
                        <td>{{billInfo.ImporterCode}}</td>
                    </tr>
                    <tr>
                        <th>出口商ID</th>
                        <td>{{billInfo.ExporterId}}</td>
                        <th>供应商代码</th>
                        <td>{{billInfo.ExporterCode}}</td>
                    </tr>
                    <tr>
                        <th>净重</th>
                        <td>{{billInfo.Weight}}</td>
                        <th>毛重</th>
                        <td>{{billInfo.GrossWeight}}</td>
                    </tr>
                    <tr>
                        <th>包装数量</th>
                        <td>{{billInfo.PackagesComplete}}</td>
                        <th>无包装数量</th>
                        <td>{{billInfo.PackagesInComplete}}</td>
                    </tr>
                    <tr>
                        <th>Dop数量</th>
                        <td>{{billInfo.QuantityDop}}</td>
                        <th>货物总价值</th>
                        <td>{{billInfo.TotalCargoValue}}</td>
                    </tr>
                    <tr>
                        <th>汇率</th>
                        <td>{{billInfo.ExchangeRate}}</td>
                        <th>美元汇率</th>
                        <td>{{billInfo.ExchangeRateToUsd}}</td>
                    </tr>
                    <tr>
                        <th>货物价值</th>
                        <td>{{billInfo.CargoValue}}</td>
                        <th>海关价值</th>
                        <td>{{billInfo.CustomsValue}}</td>
                    </tr>
                    <tr>
                        <th>统计价值</th>
                        <td>{{billInfo.StatValueUsd}}</td>
                        <th>交付条件</th>
                        <td>{{billInfo.DeliverCondition}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">运输信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">原产国</th>
                        <td width="25%">{{billInfo.OriginCountry}}</td>
                        <th width="25%">目的国</th>
                        <td width="25%">{{billInfo.Country}}</td>
                    </tr>
                    <tr>
                        <th>贸易国</th>
                        <td>{{billInfo.TradeCountry}}</td>
                        <th>起运国</th>
                        <td>{{billInfo.ManufacturerCountry}}</td>
                    </tr>
                    <tr>
                        <th>进出口类型</th>
                        <td>{{billInfo.Type}}</td>
                        <th>声明类型代码</th>
                        <td>{{billInfo.DeclarationTypeCode}}</td>
                    </tr>
                    <tr>
                        <th>当局海关</th>
                        <td>{{billInfo.Customer}}</td>
                        <th>海关办公室</th>
                        <td>{{billInfo.Customer2}}</td>
                    </tr>
                    <tr>
                        <th>项目编号</th>
                        <td>{{billInfo.Item}}</td>
                        <th>子项编号</th>
                        <td>{{billInfo.Subitem}}</td>
                    </tr>
                    <tr>
                        <th>原产地区</th>
                        <td>{{billInfo.OriginPlace}}</td>
                        <th>运输方式</th>
                        <td>{{billInfo.TransportType}}</td>
                    </tr>
                    <tr>
                        <th>车辆数量</th>
                        <td>{{billInfo.VehicleNum}}</td>
                        <th>海关税</th>
                        <td>{{billInfo.Customtax}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
</div>
</template>

<script>
export default {
    props:["billInfo","ie"],
    data() {
        return {

        };
    },
    methods: {
        
    },
    mounted() {

    },
};
</script>

<style lang="scss" scoped>
.partItem {
    margin-bottom: 20px;
    /deep/.el-card__header {
        padding: 10px 20px;
        .headTitle {
            display: inline-block;
            padding-left: 10px;
            font-size: 14px;
            border-left: 2px solid var(--themeColor);
            font-weight: bold;
        }
    }
}
table {
    width: 100%;
    border-collapse: collapse;
    tr {
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;
        th,
        td {
            font-size: 12px;
            color: #666;
            height: 40px;
            border: 1px solid #eaeaea;
            text-align: center;
        }
        th {
            background-color: #f5f7fa;
            font-weight: 700;
        }
        .bg {
            background-color: var(--themeColor);
            color: #fff;
            font-weight: 700;
            opacity: 0.8;
        }
    }
}
</style>