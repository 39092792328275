<template>
<div>
    <div v-if="ie=='i'">
        <!-- 乍得(TCD)进口提单明细 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th width="25%">类型</th>
                        <td width="25%">{{billInfo.Type}}</td>
                        <th width="25%">代理报关</th>
                        <td width="25%">{{billInfo.AgentDeclarat}}</td>
                    </tr>
                    <tr>
                        <th>系统号</th>
                        <td>{{billInfo.ImportDeclarationNumber}}</td>
                        <th>进口商代码</th>
                        <td>{{billInfo.ImporterCode}}</td>
                    </tr>
                    <tr>
                        <th>总项发票金额</th>
                        <td>{{billInfo.TotalInvoiceAmount}} {{billInfo.TotalInvoiceAmountUnit}}</td>
                        <th>固定关税</th>
                        <td>{{billInfo.FixedTariff}}</td>
                    </tr>
                    <tr>
                        <th>申报保证金</th>
                        <td>{{billInfo.DeclareMargin}}</td>
                        <th>包装数</th>
                        <td>{{billInfo.Packages}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">运输信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">原产国</th>
                        <td width="25%">{{billInfo.Country}}</td>
                        <th width="25%">目的国</th>
                        <td width="25%">{{billInfo.DestinationCountry}}</td>
                    </tr>
                    <tr>
                        <th>出口国</th>
                        <td>{{billInfo.ExportCountry}}</td>
                        <th>报关处</th>
                        <td>{{billInfo.CustomsOffice}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
    <div v-if="ie=='e'">
        <!-- 乍得(TCD)出口提单明细 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th width="25%">类型</th>
                        <td width="25%">{{billInfo.Type}}</td>
                        <th width="25%">代理报关</th>
                        <td width="25%">{{billInfo.AgentDeclarat}}</td>
                    </tr>
                    <tr>
                        <th>系统号</th>
                        <td>{{billInfo.ImportDeclarationNumber}}</td>
                        <th>进口商代码</th>
                        <td>{{billInfo.ImporterCode}}</td>
                    </tr>
                    <tr>
                        <th>总项发票金额</th>
                        <td>{{billInfo.TotalInvoiceAmount}} {{billInfo.TotalInvoiceAmountUnit}}</td>
                        <th>固定关税</th>
                        <td>{{billInfo.FixedTariff}}</td>
                    </tr>
                    <tr>
                        <th>申报保证金</th>
                        <td>{{billInfo.DeclareMargin}}</td>
                        <th>包装数</th>
                        <td>{{billInfo.Packages}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">运输信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">原产国</th>
                        <td width="25%">{{billInfo.Country}}</td>
                        <th width="25%">目的国</th>
                        <td width="25%">{{billInfo.DestinationCountry}}</td>
                    </tr>
                    <tr>
                        <th>出口国</th>
                        <td>{{billInfo.ExportCountry}}</td>
                        <th>报关处</th>
                        <td>{{billInfo.CustomsOffice}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
</div>
</template>

<script>
export default {
    props:["billInfo","ie"],
    data() {
        return {

        };
    },
    methods: {
        
    },
    mounted() {

    },
};
</script>

<style lang="scss" scoped>
.partItem {
    margin-bottom: 20px;
    /deep/.el-card__header {
        padding: 10px 20px;
        .headTitle {
            display: inline-block;
            padding-left: 10px;
            font-size: 14px;
            border-left: 2px solid var(--themeColor);
            font-weight: bold;
        }
    }
}
table {
    width: 100%;
    border-collapse: collapse;
    tr {
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;
        th,
        td {
            font-size: 12px;
            color: #666;
            height: 40px;
            border: 1px solid #eaeaea;
            text-align: center;
        }
        th {
            background-color: #f5f7fa;
            font-weight: 700;
        }
        .bg {
            background-color: var(--themeColor);
            color: #fff;
            font-weight: 700;
            opacity: 0.8;
        }
    }
}
</style>