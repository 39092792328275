<template>
<div>
    <div v-if="ie=='i'">
        <!-- 巴西进口提单明细 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th width="25%">进口商海关注册号</th>
                        <td width="25%">{{billInfo.ImporterCnjp}}</td>
                        <th width="25%">进口商所在洲</th>
                        <td width="25%">{{billInfo.ImporterState}}</td>
                    </tr>
                    <tr>
                        <th>进口商所在市</th>
                        <td>{{billInfo.ImporterMunicipality}}</td>
                        <th>进口商所在区</th>
                        <td>{{billInfo.ImporterDistrict}}</td>
                    </tr>
                    <tr>
                        <th>进口商邮编</th>
                        <td colspan="3">{{billInfo.ImporterZip}}</td>
                    </tr>
                    <tr>
                        <th>FOB总价（美元）</th>
                        <td>{{billInfo.Value}}</td>
                        <th>FOB单价（美元）</th>
                        <td>{{billInfo.UnitFob}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
        
        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">运输信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">运输方式</th>
                        <td width="25%">{{billInfo.TransportType}}</td>
                        <th width="25%">目的港</th>
                        <td width="25%">{{billInfo.ArrivalPlace}}</td>
                    </tr>
                    <tr>
                        <th>来源国</th>
                        <td>{{billInfo.Country}}</td>
                        <th>出发港</th>
                        <td>{{billInfo.OriginPort}}</td>
                    </tr>
                    <tr>
                        <th>目的国</th>
                        <td>{{billInfo.DestinationCountry}}</td>
                        <th>体积（TEU）</th>
                        <td>{{billInfo.VolumeTEU}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
    <div v-if="ie=='e'">
        <!-- 巴西出口提单明细 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th width="25%">出口商海关注册号</th>
                        <td width="25%">{{billInfo.ExporterCnjp}}</td>
                        <th width="25%">出口商所在州</th>
                        <td width="25%">{{billInfo.ExporterState}}</td>
                    </tr>
                    <tr>
                        <th>出口商所在市</th>
                        <td>{{billInfo.ExporterMunicipality}}</td>
                        <th>出口商所在区</th>
                        <td>{{billInfo.ExporterDistrict}}</td>
                    </tr>
                    <tr>
                        <th>出口商邮编</th>
                        <td colspan="3">{{billInfo.ExporterZip}}</td>
                    </tr>
                    <tr>
                        <th>FOB总价（美元）</th>
                        <td>{{billInfo.Value}}</td>
                        <th>FOB单价（美元）</th>
                        <td>{{billInfo.UnitFob}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">运输信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">运输方式</th>
                        <td width="25%">{{billInfo.TransportType}}</td>
                        <th width="25%">出发港</th>
                        <td width="25%">{{billInfo.DeparturePort}}</td>
                    </tr>
                    <tr>
                        <th>出发港所属国</th>
                        <td>{{billInfo.OriginPortCountry}}</td>
                        <th>目的国</th>
                        <td>{{billInfo.Country}}</td>
                    </tr>
                    <tr>
                        <th>体积（TEU）</th>
                        <td colspan="3">{{billInfo.Volume}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
</div>
</template>

<script>
export default {
    props:["billInfo","ie"],
    data() {
        return {

        };
    },
    methods: {
        
    },
    mounted() {
        
    },
};
</script>

<style lang="scss" scoped>
.partItem {
    margin-bottom: 20px;
    /deep/.el-card__header {
        padding: 10px 20px;
        .headTitle {
            display: inline-block;
            padding-left: 10px;
            font-size: 14px;
            border-left: 2px solid var(--themeColor);
            font-weight: bold;
        }
    }
}
table {
    width: 100%;
    border-collapse: collapse;
    tr {
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;
        th,
        td {
            font-size: 12px;
            color: #666;
            height: 40px;
            border: 1px solid #eaeaea;
            text-align: center;
        }
        th {
            background-color: #f5f7fa;
            font-weight: 700;
        }
        .bg {
            background-color: var(--themeColor);
            color: #fff;
            font-weight: 700;
            opacity: 0.8;
        }
    }
}
</style>