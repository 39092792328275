<template>
<div style="display: flex;">
    <ClueNav @chooseNav="chooseNav" :currentPath="currentPath" />
    <div class="viewbox">
        <div class="searchTerms" v-loading="searchLoading">
            <div class="itemTerm">
                <div class="name">产品大类:</div>
                <div class="spanList">
                    <div class="spanItem" :class="{'active':parentId==0}" @click="getProducts(0)">不限</div>
                    <div class="spanItem" v-for="ele in productTypeList" :key="ele.id" :class="{'active':parentId==ele.id}" @click="getProducts(ele.id)">
                        {{ele.name}}
                    </div>
                </div>
            </div>
            <div class="itemTerm">
                <div class="name">产品细分:</div>
                <div class="spanList">
                    <div class="spanItem" :class="{'active':productKeys.length==0}" @click="clickProduct('')">不限</div>
                    <div class="spanItem" v-for="(ele,index) in productsList" v-show="index < showProductNumMax" :key="ele.key" :class="{'active':productKeys.includes(ele.key)}" @click="clickProduct(ele.key)">
                        {{ele.name}}({{ele.count}})
                    </div>
                    <div class="spanItem" style="color:var(--themeColor);" v-if="showProductNumMax < productsList.length" @click="showProductNumMax = productsList.length;">查看更多</div>
                    <div class="spanItem" style="color:var(--themeColor);" v-else @click="showProductNumMax = 6;">收起</div>
                </div>
            </div>
            <div class="itemTerm">
                <div class="name">国家:</div>
                <div class="spanList">
                    <div class="spanItem" :class="{'active':countryName==''}" @click="clickCountry('')">不限</div>
                    <div class="spanItem" v-for="(ele,index) in countryList" v-show="index < showCountryNumMax" :key="ele.country" :class="{'active':countryName==ele.country}" @click="clickCountry(ele.country)">
                        {{ele.country}}({{ele.count}})
                    </div>
                    <div class="spanItem" style="color:var(--themeColor);" v-if="showCountryNumMax < countryList.length" @click="showCountryNumMax = countryList.length;">查看更多</div>
                    <div class="spanItem" style="color:var(--themeColor);" v-else @click="showCountryNumMax = 6;">收起</div>
                </div>
            </div>
            <div class="itemTerm">
                <div class="name">排序类型:</div>
                <el-select v-model="sortType" size="small" style="width:120px;" @change="onSearch">
                    <el-option label="频次排序" :value="0"></el-option>
                    <el-option label="金额排序" :value="1"></el-option>
                    <el-option label="数量排序" :value="2"></el-option>
                    <el-option label="重量排序" :value="3"></el-option>
                </el-select>
                <span style="padding: 0 10px;"></span>
                <el-checkbox v-model="isFilterForwarder">是否过滤货代</el-checkbox>
                <el-checkbox v-model="isFilterBank">是否过滤银行</el-checkbox>
                <el-checkbox v-model="isFilterOnlineRetailers">过滤电商关键词</el-checkbox>
                <el-checkbox v-model="isFilterBigBrand">过滤大品牌关键词</el-checkbox>
                <el-checkbox v-model="isFilterCustomsBroker">过滤报关行关键词</el-checkbox>
            </div>
            <div class="itemTerm">
                <div class="label">频次:</div>
                <el-input-number v-model="frequencyStart" size="mini" :controls="false"></el-input-number>
                <span style="padding:0 10px;color: #6c6c6c;">至</span>
                <el-input-number v-model="frequencyEnd" size="mini" :controls="false"></el-input-number>
                <span style="padding: 0 10px;"></span>
                <div class="label">金额:</div>
                <el-input-number v-model="valueStart" size="mini" :controls="false"></el-input-number>
                <span style="padding:0 10px;color: #6c6c6c;">至</span>
                <el-input-number v-model="valueEnd" size="mini" :controls="false"></el-input-number>
                <span style="padding: 0 10px;"></span>
                <div class="label">数量:</div>
                <el-input-number v-model="quantityStart" size="mini" :controls="false"></el-input-number>
                <span style="padding:0 10px;color: #6c6c6c;">至</span>
                <el-input-number v-model="quantityEnd" size="mini" :controls="false"></el-input-number>
                <span style="padding: 0 10px;"></span>
                <div class="label">重量:</div>
                <el-input-number v-model="weightStart" size="mini" :controls="false"></el-input-number>
                <span style="padding:0 10px;color: #6c6c6c;">至</span>
                <el-input-number v-model="weightEnd" size="mini" :controls="false"></el-input-number>
            </div>
            <div class="itemTerm">
                <div class="name">公司名称:</div>
                <el-input placeholder="公司名称" prefix-icon="el-icon-search" v-model="companyName" @keyup.enter.native="onSearch" style="width:500px;" size="small"></el-input>
                <el-button type="primary" size="small" style="margin-left:20px;" @click="onSearch" :loading="searchLoading">搜索</el-button>
            </div>
            <div class="itemTerm">
                <el-radio-group v-model="ieType" size="small" @change="typeChange">
                    <!-- 1进口商 2出口商 -->
                    <el-radio-button :label="1">进口商</el-radio-button>
                    <el-radio-button :label="2">出口商</el-radio-button>
                    <el-radio-button :label="3">中国供应商</el-radio-button>
                </el-radio-group>
            </div>
        </div>

        <div style="margin-top:20px;">
            <div class="searchResult noResult" v-show="totalItems==0" v-loading="searchLoading">
                <el-empty description="暂无数据"></el-empty>
            </div>

            <div class="searchResult" v-show="totalItems!=0" v-loading="searchLoading">
                <div class="title">
                    <div>为您找到<span style="color:#FF2D2D;">{{totalItems}}</span>条相关结果</div>
                    <el-button type="primary" size="small" @click="addMyClue" :loading="btnLoading" :disabled="chooseList.length == 0">加入我的线索</el-button>
                </div>
                <div class="resultList">
                    <div class="resultItem" v-for="(item,index) in resultList" :key="index">
                        <div style="margin-bottom: 8px;">
                            <div class="name" @click="toDetails(item)">{{item.company}}</div>
                            <div class="country">
                                <img :src="item.flagUrl" style="margin-right:5px;height:14px" />
                                <span>{{item.dataCountryCn}}({{item.threeEnCountryCode}})</span>
                            </div>
                            <el-tag effect="dark" type="info" size="mini" v-if="item.isForwarder">货代</el-tag>
                            <el-tag effect="dark" type="info" size="mini" v-if="item.isBank">银行</el-tag>
                            <el-tag effect="dark" type="info" size="mini" v-if="item.isECommerce">电商</el-tag>
                            <el-tag effect="dark" type="info" size="mini" v-if="item.isBigBrand">大品牌</el-tag>
                            <el-tag effect="dark" type="info" size="mini" v-if="item.isCustomsBroker">报关行</el-tag>
                            <el-tag effect="dark" type="info" size="mini" v-if="item.isLogistics">物流</el-tag>
                            <el-tag effect="dark" type="info" size="mini" v-if="item.isBonded">保税/仓储</el-tag>
                            <el-tag effect="dark" type="info" size="mini" v-if="item.isIeAgent">进出口代理</el-tag>
                            <el-dropdown @command="(command) => handleCommand(item,command)">
                                <span>
                                    <el-button size="mini" style="margin-left:20px;" v-if="isPermission">
                                        标记<i class="el-icon-arrow-down el-icon--right"></i>
                                    </el-button>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item :command="1">{{item.isForwarder ? '取消标记货代' : '标记货代'}}</el-dropdown-item>
                                    <el-dropdown-item :command="2">{{item.isBank ? '取消标记银行' : '标记银行'}}</el-dropdown-item>
                                    <el-dropdown-item :command="3">{{item.isECommerce ? '取消标记电商' : '标记电商'}}</el-dropdown-item>
                                    <el-dropdown-item :command="4">{{item.isBigBrand ? '取消标记大品牌' : '标记大品牌'}}</el-dropdown-item>
                                    <el-dropdown-item :command="5">{{item.isCustomsBroker ? '取消标记报关行' : '标记报关行'}}</el-dropdown-item>
                                    <el-dropdown-item :command="6">{{item.isLogistics ? '取消标记物流' : '标记物流'}}</el-dropdown-item>
                                    <el-dropdown-item :command="7">{{item.isBonded ? '取消标记保税/仓储' : '标记保税/仓储'}}</el-dropdown-item>
                                    <el-dropdown-item :command="8">{{item.isIeAgent ? '取消标记进出口代理' : '标记进出口代理'}}</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                        <div style="display:flex;">
                            <div class="oneField">
                                <div class="color99">频次</div>
                                <div class="color3a">{{item.frequency}}</div>
                            </div>
                            <div class="oneField">
                                <div class="color99">金额</div>
                                <div class="color3a">{{item.value}}</div>
                            </div>
                            <div class="oneField">
                                <div class="color99">数量</div>
                                <div class="color3a">{{item.quantity}}</div>
                            </div>
                            <div class="oneField">
                                <div class="color99">重量</div>
                                <div class="color3a">{{item.weight}}</div>
                            </div>
                        </div>
                        <div class="twoField" v-show="$isTest && item.urls">
                            <span class="color99">企业官网：</span>
                            <span class="primaryColor" v-for="url in item.urls" :key="url" @click="openWebsite(url)">{{url}}</span>
                        </div>
                        <div>
                            <span class="color99">产品标签：</span>
                            <span class="tagSpan" v-for="(ele,i) in item.productType" :key="i" v-html="ele"></span>
                            <!-- <el-tag style="margin-top:10px;" v-for="(ele,i) in item.productType" :key="i" color="var(--themeColor)" effect="dark" size="mini">
                                {{ele}}
                            </el-tag> -->
                        </div>
                        <template>
                            <div class="hasAdd" v-if="item.isMyClue">已加入我的线索</div>
                            <el-checkbox class="checkbox" @change="getChooseList" v-else v-model="item.isChecked"></el-checkbox>
                        </template>
                    </div>
                    <el-pagination background layout="total,sizes,prev,pager,next" :page-sizes="[10, 20, 40]" :page-size.sync="pageSize" @size-change="pageSizeChange" :total="totalItems" style="float:right;margin-top:15px;" :current-page.sync="pageIndex" @current-change="pageIndexChange"></el-pagination>
                </div>
            </div>

            <!-- 验证解锁 -->
            <el-dialog title="验证解锁" :visible.sync="dialogVisible" width="500px" :show-close="false"
                :close-on-click-modal="false" :close-on-press-escape="false">
                <p style="line-height:28px;font-weight:bold;">请求过于频繁，请输入验证码：</p>
                <div style="display:flex;">
                    <el-input v-model="imgCode" size="small"></el-input>
                    <img class="pointer" :src="imgSrc" alt="" @click="openVerify" />
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button size="small" type="primary" :disabled="!imgCode" @click="finishVerify">确 认</el-button>
                </span>
            </el-dialog>

        </div>
    </div>
</div>
</template>

<script>
import ClueNav from "@/components/ClueNav.vue";
import {toFirm} from '@/utils'
import { Switch } from 'element-ui';
export default {
    components: { ClueNav },
    props: {
        handleSelect: {
            type: Function,
            default: null,
        },
    },
    data() {
        return {
            currentPath: "/clue/productClueSearch",
            //产品大类
            productTypeList: [],
            parentId: 0,
            //产品细分
            productsList: [],
            productKey: "",
            productKeys: [],
            showProductNumMax: 6,
            countryList: [],
            showCountryNumMax: 6,
            //添加公司名搜索和排序
            companyName: "",
            sortType: 0,    // 0频次 1金额 2数量 3重量
            countryName: "",
            frequencyStart: 0,
            frequencyEnd: 0,
            valueStart: 0,
            valueEnd: 0,
            quantityStart: 0,
            quantityEnd: 0,
            weightStart: 0,
            weightEnd: 0,
            isFilterForwarder: true,
            isFilterBank: true,
            isFilterOnlineRetailers: true,
            isFilterBigBrand: false,
            isFilterCustomsBroker: false,
            ieType: 1,  //1进口商 2出口商
            //搜索结果
            searchLoading: false,
            resultList: [],
            totalItems: 0,
            pageIndex: 1,
            pageSize: 10,
            //验证解锁
            dialogVisible: false,
            imgCode: "",
            imgKey: "",
            imgSrc: "",
            btnLoading: false,
            chooseList: [],
            isPermission: false,
        };
    },
    methods: {
        chooseNav(index){
            this.handleSelect(index);
            this.$router.push(index);
        },

        //获取产品大类
        getProductType(){
            this.$axios.get(`${this.$clueUrl}/Product/GetProductType`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.productTypeList = data;
                    this.getProducts(0);
                }
            });
        },
        //获取产品细分
        getProducts(parentId){
            this.productKeys = [];
            this.showProductNumMax = 6;
            this.parentId = parentId;
            this.$axios.get(`${this.$clueUrl}/Product/GetProducts?parentId=${this.parentId}&ieType=${this.ieType}`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.productsList = data;
                }
            });
            if(parentId){
                this.onSearch();
            }
        },
        clickProduct(key){
            if(key){
                if(this.productKeys.includes(key)){
                    this.productKeys = this.productKeys.filter(ele => ele != key);
                }else{
                    this.productKeys.push(key);
                }
            }else{
                this.productKeys = [];
            }
            this.onSearch();
        },
        //获取产品国家
        getProductsCountry(){
            this.showCountryNumMax = 6;
            this.$axios.get(`${this.$clueUrl}/Product/GetProductsCountry?ieType=${this.ieType}`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.countryList = data;
                }
            });
        },
        clickCountry(value){
            this.countryName = value;
            this.onSearch();
        },

        typeChange(){
            this.getProducts(this.parentId);
            this.getProductsCountry();
            this.onSearch();
        },

        //获取搜索结果
        getResult(){
            this.searchLoading = true;
            this.$axios.post(`${this.$clueUrl}/Product/V2/GetCompany`,{
                productKey: this.productKey,
                bigParentIds: this.parentId,
                productKeys: this.productKeys,
                country: this.countryName,
                company: this.companyName,
                sort: this.sortType,
                frequencyStart: this.frequencyStart,
                frequencyEnd: this.frequencyEnd,
                valueStart: this.valueStart,
                valueEnd: this.valueEnd,
                quantityStart: this.quantityStart,
                quantityEnd: this.quantityEnd,
                weightStart: this.weightStart,
                weightEnd: this.weightEnd,
                isFilterForwarder: this.isFilterForwarder,
                isFilterBank: this.isFilterBank,
                isFilterOnlineRetailers: this.isFilterOnlineRetailers,
                isFilterBigBrand: this.isFilterBigBrand,
                isFilterCustomsBroker: this.isFilterCustomsBroker,
                ieType: this.ieType,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
            }).then((res) => {
                const { isSuccess, data, errorMessage } = res;
                this.searchLoading = false;
                if (isSuccess) {
                    this.resultList = data.datas.map(item=>({
                        ...item,
                        flagUrl: window.getFlagUrlByCountry?window.getFlagUrlByCountry(item.dataCountryCn):'',
                        isChecked: false,
                    }))
                    this.totalItems = data.totalItems;
                    this.chooseList = [];
                    if(this.totalItems == 0){
                        this.$message({ message: '搜索无数据,请修改搜索条件重试!',type: 'warning' });
                    }
                }else{
                    if(errorMessage == "请求过于频繁，请输入验证码"){
                        this.openVerify();
                    }
                }
            });
        },
        onSearch(){
            this.pageIndex = 1;
            this.getResult();
        },
        pageIndexChange(page) {
            this.pageIndex = page;
            if(this.pageIndex > 30){
                this.pageIndex = 30;
                this.$notify({ title: '警告',message: '目前线索模块处于免费试用阶段,搜索页码不能超过30!',type: 'warning' });
                return false;
            }
            this.getResult();
        },
        pageSizeChange() {
            this.pageIndex = 1;
            this.getResult();
        },

        //前往线索详情
        toDetails(row){
            if(this.ieType == 3){
                //中国供应商跳转详情
                let routeData = this.$router.resolve({
                    path: "/clue/QichachaDetails",
                    query: { company: row.company, dataCountryCn: row.dataCountryCn },
                });
                window.open(routeData.href, "_blank");
                return false;
            }
            //console.log(row)
           // toFirm({item:{isMyClue:true,...row},company:row.company,countryCode:row.threeEnCountryCode})
           toFirm({item:{
            "clueSource": 8,
                    "clueRelationKey": "",
                    "companyName": row.company,
                    "country": row.dataCountryCn,
                    "legalPerson": "",
                    "contactNumber": "",
                    "registrationTime": "",
                    "employeesNumber": 0,
                    "officialWebsite": "",
                    "corporateEmail": "",
                    "businessAddress": "",
                    "companyProfile": "",
                    "industrys": [],
            isMyClue:row.isMyClue
           },company:row.companyEn,countryCode:row.threeEnCountryCode})
            // 验证查看详情次数
            // this.$axios.get(`${this.$clueUrl}/SecurityStrategy/CheckDetailsCount`).then((res) => {
            //     const { isSuccess } = res;
            //     if (isSuccess) {
            //         let routeData = this.$router.resolve({
            //             path: "/clue/productClueDetails",
            //             query: { company: row.company, dataCountryCn: row.dataCountryCn, threeEnCountryCode: row.threeEnCountryCode  },
            //         });
            //         window.open(routeData.href, "_blank");
            //     }
            // });
        },

        //加入我的线索
        getChooseList(){
            this.chooseList = this.resultList.filter(item => item.isChecked);
        },
        addMyClue(){
            let submitList = [];
            this.chooseList.forEach(item =>{
                submitList.push({
                    "clueSource": 8,
                    "clueRelationKey": "",
                    "companyName": item.company,
                    "country": item.dataCountryCn,
                    "legalPerson": "",
                    "contactNumber": "",
                    "registrationTime": "",
                    "employeesNumber": 0,
                    "officialWebsite": "",
                    "corporateEmail": "",
                    "businessAddress": "",
                    "companyProfile": "",
                    "industrys": [],
                })
            })
            // console.log(submitList);
            this.btnLoading = true;
            this.$axios.post(`${this.$clueUrl}/MyClue/batchAddMyClue`, submitList).then((res) => {
                const { isSuccess } = res;
                this.btnLoading = false;
                if (isSuccess) {
                    this.$message({ message: '添加成功', type: 'success'});
                    this.getResult();
                }
            });
        },

        //打开网址
        openWebsite(url){
            let jumpUrl = url.indexOf("http") > -1 ? url : "http://" + url;
            let routeData = this.$router.resolve({
                path: "/jumpWeb",
                query: { jumpUrl: jumpUrl },
            });
            window.open(routeData.href, "_blank");
        },

        //请求频繁，需要输入验证解锁
        openVerify(){
            this.dialogVisible = true;
            this.$axios.get(`${this.$clueUrl}/SecurityStrategy/GetVerifycode`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.imgKey = data.key;
                    this.imgSrc = data.image;
                }
            });
        },
        finishVerify(){
            this.$axios.get(`${this.$clueUrl}/SecurityStrategy/VerifyVerificationCode?key=${this.imgKey}&code=${this.imgCode}`).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    this.dialogVisible = false;
                    this.imgCode = "";
                    this.onSearch();
                }
            });
        },

        //标记/取消标记
        handleCommand(item,command){
            let parameter = {
                companyName: item.company,
                type: command,
                isCancel: false,
            };
            if(command == 1){ parameter.isCancel = item.isForwarder; }  //货代
            if(command == 2){ parameter.isCancel = item.isBank; }       //银行
            if(command == 3){ parameter.isCancel = item.isECommerce; }  //电商
            if(command == 4){ parameter.isCancel = item.isBigBrand; }   //大品牌
            if(command == 5){ parameter.isCancel = item.isCustomsBroker; }   //报关行
            if(command == 6){ parameter.isCancel = item.isLogistics; }   //物流
            if(command == 7){ parameter.isCancel = item.isBonded; }   //保税/仓储
            if(command == 8){ parameter.isCancel = item.isIeAgent; }   //进出口代理
            this.$axios.post(`${this.$clueUrl}/Product/UpdateProductFilter`, parameter).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    console.log(parameter);
                    this.$message({ message: '标记完成,数据改动可能会有延迟', type: 'success'});
                    this.getResult();
                }
            });
        }

    },
    mounted() {
        this.getProductType(); //获取产品大类
        this.getProductsCountry();  //获取产品国家

        // this.getResult();

        let userInfo = JSON.parse(localStorage.userInfo);
        if(userInfo.companyKey == "52b9b01c3c67494c8114f35e7b912779"){
            this.isPermission = true;
        }
        if(userInfo.mailAddress.indexOf('guodong.liu@lsmaps.com') >= 0){
            this.isPermission = true;
        }
    },
};
</script>

<style lang="scss" scoped>
@import '@/scss/common.scss';
.viewbox{
    width: calc(100% - 200px);
    height: calc(100vh - 80px);
    box-sizing: border-box;
    overflow: auto;
    background: #FFFFFF;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    padding: 20px;
    .header{
        display: flex;
        padding-bottom: 20px;
        border-bottom: 1px solid #E1E1E1;
        .logo{
            padding: 0 15px;
        }
        .theme{
            font-size: 20px;
            line-height: 32px;
            font-weight: bold;
            color: #092338;
        }
        .txt{
            font-size: 14px;
            line-height: 20px;
            color: #092338;
        }
    }

    .searchTerms{
        // margin-top: 20px;
        .itemTerm{
            line-height: 26px;
            margin-bottom: 10px;
            overflow: hidden;
            .name{
                float: left;
                width: 65px;
                font-size: 14px;
                color: #092338;
                text-align: right;
                margin-right: 15px;
                font-weight: bold;
            }
            .label{
                display: inline-block;
                width: 65px;
                font-size: 14px;
                color: #092338;
                text-align: right;
                margin-right: 15px;
                font-weight: bold;
            }
            .spanList{
                float: left;
                width: calc(100% - 80px);
            }
            .spanItem{
                display: inline-block;
                font-size: 14px;
                color: #092338;
                padding: 0 10px;
                margin-right: 15px;
                cursor: pointer;
            }
            .active{
                color: #FFFFFF;
                background: $themeColor;
                border-radius: 2px;
            }
        }
    }

    .searchResult{
        // margin-top: 20px;
        .title{
            height: 40px;
            line-height: 40px;
            background: #F1F1F1;
            color: #3A3A3A;
            padding-left: 20px;
            display: flex;
            justify-content: space-between;
        }
        .resultList{
            padding: 20px;
            overflow: hidden;
            .resultItem{
                font-size: 14px;
                padding: 10px 0;
                border-bottom: 1px solid #E8E8E8;
                position: relative;
                .name{
                    display: inline-block;
                    color: var(--themeColor);
                    text-decoration: underline;
                    cursor: pointer;
                }
                .country{
                    display: inline-block;
                    margin-left: 20px;
                }
                .color99{
                    color: #999999;
                }
                .color3a{
                    color: #3A3A3A;
                }
                .primaryColor{
                    color: var(--themeColor);
                    cursor: pointer;
                    padding-right: 30px;
                }
                .oneField{
                    width: 200px;
                    text-align: center;
                }
                .twoField{
                    line-height: 28px;
                }
                .tagSpan{
                    display: inline-block;
                    margin-top: 5px;
                    margin-right: 5px;
                    color: #3A3A3A;
                }
                .hasAdd{
                    position: absolute;
                    top: 10px;
                    right: 0;
                    padding: 0 10px;
                    height: 28px;
                    line-height: 28px;
                    font-size: 14px;
                    color: #B7B7B7;
                    background: rgba(183, 183, 183, 0.2);
                    border: 1px solid #B7B7B7;
                    border-radius: 4px;
                }
                .checkbox{
                    position: absolute;
                    top: 15px;
                    left: -20px;
                }
            }
        }
    }
    .noResult{
        overflow: hidden;
        .hintImg{
            float: left;
            width: 800px;
        }
        .noImg{
            float: left;
            margin-left: 80px;
            margin-top: 200px;
            width: 100px;
            height: 100px;
        }
        .hintTxt{
            float: left;
            margin-left: 20px;
            margin-top: 200px;
            font-size: 80px;
            line-height: 100px;
            font-weight: bold;
            color: #DFDFDF;
        }
    }

    /deep/.el-dialog__body {
        padding: 10px 20px;
    }
}
</style>
