<template>
    <div class="addStaff">
        <div class="formBox">
            <div class="title">入职人员基本信息</div>
            <el-form class="content" :model="newStaffForm" label-width="120px" size="small">
                <el-form-item label="姓名" required>
                    <el-input v-model="newStaffForm.name" class="w220"></el-input>
                </el-form-item>
                <el-form-item label="性别">
                    <el-radio-group v-model="newStaffForm.sex">
                        <el-radio :label="1">男</el-radio>
                        <el-radio :label="0">女</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="出生日期">
                    <el-date-picker v-model="newStaffForm.birthday" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
                </el-form-item>
                <el-form-item label="手机号" required>
                    <el-input v-model="newStaffForm.phone" class="w220"></el-input>
                </el-form-item>
                <!-- <el-form-item label="邮箱">
                    <el-input v-model="newStaffForm.email" class="w220"></el-input>
                </el-form-item> -->
            </el-form>
        </div>
        <div class="formBox">
            <div class="title">人员权限分配</div>
            <el-form class="content" :model="newStaffForm" label-width="120px" size="small">
                <el-form-item label="企业邮箱" required>
                    <el-input v-model="newStaffForm.account" class="w220"></el-input>
                </el-form-item>
                <el-form-item label="入职时间" required>
                    <el-date-picker v-model="newStaffForm.entryTime" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
                </el-form-item>
                <el-form-item label="部门" required>
                    <div v-for="(ele,index) in newStaffForm.departments" :key="index">
                        <el-select v-model="ele.name" placeholder="请选择部门" size="small">
                            <el-option :value="ele.key" style="height: auto">
                                <el-tree :data="treeData" :props="defaultProps" @node-click="(data)=>chooseDepartment(data,ele)"></el-tree>
                            </el-option>
                        </el-select>
                        <img class="iconBtn" src="@/assets/img/add2.png" alt="" @click="addDepartment" v-if="index==0" />
                        <img class="iconBtn" src="@/assets/img/jian2.png" alt="" @click="delDepartment(index)" v-else />
                        <span style="color:#5E6D82;">部门负责人：</span>
                        <el-checkbox v-model="ele.isManager">是</el-checkbox>
                    </div>
                </el-form-item>
                <el-form-item label="职位" required>
                    <!-- <el-input v-model="newStaffForm.position" class="w220"></el-input> -->
                    <el-select v-model="newStaffForm.position" filterable>
                        <el-option v-for="item in positionOption" :key="item.key" :label="item.name" :value="item.name"></el-option>
                        <div class="toConfig" @click="toConfig">
                            <i class="el-icon-plus"></i>
                            <span>配置职位</span>
                        </div>
                    </el-select>
                </el-form-item>
                <el-form-item label="直属上级" required>
                    <el-select v-model="newStaffForm.directSuperiorUkey" placeholder="请选择">
                        <el-option v-for="item in superiorOption" :key="item.ukey" :label="item.name" :value="item.ukey">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="采购模块权限">
                    <el-radio v-model="newStaffForm.isProcureModel" :label="true">有</el-radio>
                    <el-radio v-model="newStaffForm.isProcureModel" :label="false">无</el-radio>
                </el-form-item>
                <el-form-item label="导出客户权限">
                    <el-radio v-model="newStaffForm.isExport" :label="true">有</el-radio>
                    <el-radio v-model="newStaffForm.isExport" :label="false">无</el-radio>
                </el-form-item>
            </el-form>
        </div>
        <div class="submitBtn">
            <el-button type="primary" size="small" @click="onSubmit" :loading="btnLoading" :disabled="isSubmit">新增员工</el-button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        handleSelect: {
            type: Function,
            default: null,
        },
    },
    data() {
        return {
            newStaffForm:{
                name: "",
                sex: 1,
                birthday: "",
                phone: "",
                email: "",
                account: "",
                entryTime: "",
                departments: [{ key: "",name: "",isManager: false }],
                position: "",
                directSuperiorUkey: "",
                headPortraitUrl: "",
                isProcureModel: false, //是否拥有采购模块权限
                isExport: false,    //是否拥有导出客户权限
            },
            isSubmit: false,
            btnLoading: false,

            //组织结构
            treeData: [],
            defaultProps: {
                children: "children",
                label: "name",
            },
            //直属上级选择下拉框
            superiorOption: [],
            //职位配置
            positionOption: [],
        };
    },
    methods: {
        // 新增员工提交
        onSubmit() {
            const {name,phone} = this.newStaffForm;
            if(!name){ this.$message({ message: "请填写姓名" , type: 'warning'}); return false; }
            if(!phone){ this.$message({ message: "请填写手机号" , type: 'warning'}); return false; }
            const {account,entryTime,departments,position,directSuperiorUkey} = this.newStaffForm;
            if(!account){ this.$message({ message: "请填写企业邮箱" , type: 'warning'}); return false;}
            if(!entryTime){ this.$message({ message: "请选择入职时间" , type: 'warning'}); return false;}
            if(departments[0].key == ""){ this.$message({ message: "请选择部门" , type: 'warning'}); return false;}
            if(!position){ this.$message({ message: "请填写职位" , type: 'warning'}); return false;}
            if(!directSuperiorUkey){ this.$message({ message: "请选择上级" , type: 'warning'}); return false;}
            this.newStaffForm.departments = this.newStaffForm.departments.filter(ele => ele.key != "");

            this.btnLoading = true;
            this.$axios.post(`${this.$personnelUrl}/Staff/AddUser`, this.newStaffForm).then((res) => {
                const { isSuccess } = res;
                this.btnLoading = false;
                if (isSuccess) {
                    this.isSubmit = true;
                    this.$message({ message: "新增员工完成" , type: 'success'});
                    this.handleSelect(`/staff/staffList`);
                    this.$router.push(`/staff/staffList`);
                }
            });
        },

        //直属上级选择下拉框
        getUsers(){
            this.$axios.get(`${this.$personnelUrl}/EntryApplication/GetUsers`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.superiorOption = data;
                }
            });
        },
        //获取组织结构
        getTreeData(){
            this.$axios.get(`${this.$personnelUrl}/Organization/GetOrganization`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.treeData = data;
                }
            })
        },
        chooseDepartment(data,ele) {
            ele.key = data.key;
            ele.name = data.name;
        },
        addDepartment(){
            this.newStaffForm.departments.push({ 
                key: "",
                name: "",
                isManager: false
            })
        },
        delDepartment(index){
            this.newStaffForm.departments.splice(index, 1)
        },

        //获取职位配置
        getPosition(){
            this.$axios.get(`${this.$customerUrl}/v1/Config/GetPosition`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.positionOption = data;
                }
            });
        },
        toConfig(){
            let routeData = this.$router.resolve({
                path: "/settingIndex",
                query: { type: "system" },
            });
            window.open(routeData.href, "_blank");
        },

    },
    mounted() {
        this.getUsers();        //直属上级选择下拉框
        this.getTreeData();     //获取组织结构
        this.getPosition();     //获取职位配置
    },
};
</script>

<style lang="scss" scoped>
.addStaff{
    background: #ffffff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    padding: 20px;
    .formBox {
        margin-top: 20px;
        .title {
            margin-bottom: 20px;
            line-height: 16px;
            font-size: 16px;
            font-weight: bold;
            color: #2a3f58;
            padding-left: 10px;
            border-left: 4px solid var(--themeColor);
        }
        .content {
            .w220{
                width: 220px;
            }
            .iconBtn{
                vertical-align: middle;
                cursor: pointer;
                margin-left: 20px;
                margin-right: 20px;
            }
        }
    }
    .submitBtn {
        border-top: 1px solid #e0e6ed;
        padding-top: 20px;
        padding-left: 40px;
    }
}
.toConfig{
    cursor: pointer;
    text-align: center;
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    color: #6a6a6a;
    &:hover{
        background-color: #F5F7FA;
    }
}
</style>