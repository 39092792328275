<template>
    <div>
        <el-card>
            <div class="header">管理员系统配置流程</div>
            <div class="boxItem">
                <div class="title">客户设置</div>
                <div class="describe">设置公司分组，掌握客户动态</div>
                <div class="boundary"></div>
                <div class="setItem">
                    <div class="name">
                        <span class="sp1">1、公海分组</span>
                        <span class="sp2" v-show="PublicGroupOption.length>0"><img src="@/assets/icon/haveSet.png" alt=""> 已填写</span>
                    </div>
                    <div class="setContent" v-show="PublicGroupOption.length>0">
                        <span class="spanItem" v-for="(element,index) in PublicGroupOption" :key="index">
                            {{element.name}}
                        </span>
                    </div>
                    <el-button size="mini" v-show="PublicGroupOption.length==0" @click="goSee">去看看</el-button>
                </div>
                <div class="setItem">
                    <div class="name">
                        <span class="sp1">2、客户标签</span>
                        <span class="sp2" v-show="TagOption.length>0"><img src="@/assets/icon/haveSet.png" alt=""> 已填写</span>
                    </div>
                    <div class="setContent" v-show="TagOption.length>0">
                        <el-tag class="spanItem" v-for="(element,index) in TagOption" :key="index" :color="element.color" effect="dark" size="mini">
                            {{element.name}}
                        </el-tag>
                    </div>
                    <el-button size="mini" v-show="TagOption.length==0" @click="goSee">去看看</el-button>
                </div>
                <div class="setItem">
                    <div class="name">
                        <span class="sp1">3、公司来源</span>
                        <span class="sp2" v-show="SourceOption.length>0"><img src="@/assets/icon/haveSet.png" alt=""> 已填写</span>
                    </div>
                    <div class="setContent" v-show="SourceOption.length>0">
                        <span class="spanItem" v-for="(element,index) in SourceOption" :key="index">
                            {{element.name}}
                        </span>
                    </div>
                    <el-button size="mini" v-show="SourceOption.length==0" @click="goSee">去看看</el-button>
                </div>
                <div class="setItem">
                    <div class="name">
                        <span class="sp1">4、公司状态</span>
                        <span class="sp2" v-show="StatuOption.length>0"><img src="@/assets/icon/haveSet.png" alt=""> 已填写</span>
                    </div>
                    <div class="setContent" v-show="StatuOption.length>0">
                        <span class="spanItem" v-for="(element,index) in StatuOption" :key="index">
                            {{element.name}}
                        </span>
                    </div>
                    <el-button size="mini" v-show="StatuOption.length==0" @click="goSee">去看看</el-button>
                </div>
            </div>
            <div class="boxItem">
                <div class="title">商机设置</div>
                <div class="describe">商机设置流程后，及时跟进客户，促进商机转化为订单</div>
                <div class="boundary"></div>
                <div class="setItem">
                    <div class="name">
                        <span class="sp1">1、商机流程设置</span>
                        <span class="sp2" v-show="StageOption.length>0"><img src="@/assets/icon/haveSet.png" alt=""> 已填写</span>
                    </div>
                    <div class="setContent" v-show="StageOption.length>0">
                        <span class="spanItem" v-for="(element,index) in StageOption" :key="index">
                            {{element.stageName}}
                        </span>
                    </div>
                    <el-button size="mini" v-show="StageOption.length==0" @click="goSee">去看看</el-button>
                </div>
                <div class="setItem">
                    <div class="name">
                        <span class="sp1">2、输单原因</span>
                        <span class="sp2" v-show="ReasonOption.length>0"><img src="@/assets/icon/haveSet.png" alt=""> 已填写</span>
                    </div>
                    <div class="setContent" v-show="ReasonOption.length>0">
                        <span class="spanItem" v-for="(element,index) in ReasonOption" :key="index">
                            {{element.reason}}
                        </span>
                    </div>
                    <el-button size="mini" v-show="ReasonOption.length==0" @click="goSee">去看看</el-button>
                </div>
                <div class="setItem">
                    <div class="name">
                        <span class="sp1">3、货币</span>
                        <span class="sp2" v-show="CurrencyOption.length>0"><img src="@/assets/icon/haveSet.png" alt=""> 已填写</span>
                    </div>
                    <div class="setContent" v-show="CurrencyOption.length>0">
                        <span class="spanItem" v-for="(element,index) in CurrencyOption" :key="index">
                            {{element.currency}}
                        </span>
                    </div>
                    <el-button size="mini" v-show="CurrencyOption.length==0" @click="goSee">去看看</el-button>
                </div>
            </div>
            <div class="boxItem">
                <div class="title">产品设置</div>
                <div class="describe">添加公司产品，助力销售和订单数据</div>
            </div>
        </el-card>

        <el-card style="margin-top:20px;">
            <div class="header">进阶设置</div>
            <div class="boxItem">
                <div class="title">客户导入</div>
                <div class="describe">将客户信息导入到我的客户中，快捷跟进</div>
                <el-button class="importBtn" size="mini" @click="toImportOne">立即导入</el-button>
            </div>
            <div class="boxItem">
                <div class="title">产品导入</div>
                <div class="describe">将产品信息导入到我的产品中</div>
                <el-button class="importBtn" size="mini" @click="toImportTwo">立即导入</el-button>
            </div>
        </el-card>
    </div>
</template>

<script>
export default {
    props: {
        handleSelect: {
            type: Function,
            default: null,
        },
    },
    data() {
        return {
            //客户设置
            PublicGroupOption: [],
            TagOption: [],
            SourceOption: [],
            StatuOption: [],
            //商机设置
            StageOption: [],
            ReasonOption: [],
            CurrencyOption: [],
        };
    },
    methods: {
        goSee(){
            this.handleSelect(`/settingIndex`);
            this.$router.push(`/settingIndex`);
        },
        toImportOne(){
            this.handleSelect(`/customerManage/import`);
            this.$router.push(`/customerManage/import`);
        },
        toImportTwo(){
            this.handleSelect(`/orderManage/productImport`);
            this.$router.push(`/orderManage/productImport`);
        },

        //公海分组配置
        getPublicGroup(){
            this.$axios.get(`${this.$customerUrl}/v1/Config/PublicGroup`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.PublicGroupOption = data;
                }
            });
        },
        //客户标签配置
        getCustomerTag(){
            this.$axios.get(`${this.$customerUrl}/v1/Config/Tag`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.TagOption = data;
                }
            });
        },
        //公司来源配置
        getCustomerSource(){
            this.$axios.get(`${this.$customerUrl}/v1/Config/Source`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.SourceOption = data;
                }
            });
        },
        //公司状态配置
        getCustomerStatu(){
            this.$axios.get(`${this.$customerUrl}/v1/Config/Statu`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.StatuOption = data;
                }
            });
        },

        //商机设置
        getBusiness(){
            this.$axios.get(`${this.$sjUrl}/BusinessChance/GetStages`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.StageOption = data;
                }
            });
            this.$axios.get(`${this.$customerUrl}/v1/Config/GetOrderInputReason`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.ReasonOption = data;
                }
            });
            this.$axios.get(`${this.$customerUrl}/v1/Config/GetCurrencyConfig`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.CurrencyOption = data;
                }
            });
        },

    },
    mounted() {
        this.getPublicGroup();      //公海分组配置
        this.getCustomerTag();      //客户标签配置
        this.getCustomerSource();   //公司来源配置
        this.getCustomerStatu();    //公司状态配置

        this.getBusiness();         //商机设置 
    },
};
</script>

<style lang="scss" scoped>
.header{
    height: 30px;
    line-height: 30px;
    font-size: 20px;
    font-weight: bold;
    color: var(--themeColor);
}
.boxItem{
    background: #F6F8FA;
    border: 1px solid #CFCFCF;
    padding: 10px 20px;
    margin-top: 10px;
    position: relative;
    .title{
        font-size: 18px;
        line-height: 30px;
        font-weight: bold;
        color: #1F2D3D;
    }
    .describe{
        font-size: 14px;
        line-height: 28px;
        color: #A5A5A5;
    }
    .boundary{
        padding-bottom: 5px;
        border-bottom: 1px solid #C7C7C7;
    }
    .setItem{
        margin-top: 10px;
        .name{
            .sp1{
                padding-right: 25px;
            }
            .sp2{
                color:#20BC5F;
            }
        }
        img{
            vertical-align: sub;
        }
    }
    .setContent{
        margin-top: 10px;
        padding: 10px 20px;
        background: #EFEFEF;
        color: #3A3A3A;
        line-height: 30px;
        .spanItem{
            margin-right: 20px;
        }
    }
    .importBtn{
        position: absolute;
        top: 25px;
        right: 50px;
    }
}
</style>