<template>
    <div>
        <InterfaceName :interfaceName="interfaceName" />
        <InterfaceSpe :requestUrl="requestUrl" :requestMethod="requestMethod" />
        <InputParam :inputTableData="inputTableData" />
        <ReturnParam :returnObj="returnObj" />
    </div>
</template>

<script>
import InterfaceName from "../Acomponents/InterfaceName.vue";
import InterfaceSpe from "../Acomponents/InterfaceSpe.vue";
import InputParam from "../Acomponents/InputParam.vue";
import ReturnParam from "../Acomponents/ReturnParam.vue";

export default {
    components: { InterfaceName, InterfaceSpe, InputParam, ReturnParam },
    data() {
        return {
            interfaceName: "绑定邮箱",
            requestUrl: "https://crm.qixinerp.com/open/Email/BindEmail",
            requestMethod: "POST",
            inputTableData: [],
            returnObj: {},
        };
    },
    methods: {},
    mounted() {
        this.inputTableData = [
            {
                paramName: "eMail",
                valueType: "string",
                explain: "邮箱",
                required: true,
            },
            {
                paramName: "eMailPwd",
                valueType: "string",
                explain: "邮箱密码",
                required: true,
            },
            {
                paramName: "smtpHost",
                valueType: "string",
                explain: "smtp域名",
                required: true,
            },
            {
                paramName: "imapHost",
                valueType: "string",
                explain: "imap域名",
                required: true,
            },
            {
                paramName: "type",
                valueType: "string",
                explain: "邮箱类型",
                required: true,
            },
            {
                paramName: "ukey",
                valueType: "string",
                explain: "用户key",
                required: true,
            },
        ];
        this.returnObj = {
            isSuccess: true,
            errorMessage: null,
            data: true,
        };
    },
};
</script>

<style lang="scss" scoped>
</style>