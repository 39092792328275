<template>
<div style="display: flex;">
    <OrderNav @chooseNav="chooseNav" :currentPath="currentPath" />
    <div class="viewbox">
        <div class="boxOne">
            <div class="title">订单管理</div>
            <!-- 管理员可以查看整个部门 -->
            <PowerSelect defaultText="我的订单" @onChoose="onChoose" />

            <div class="rightHandle">
                <el-button size="small" type="primary" icon="el-icon-plus" @click="toNewOrder">新建订单</el-button>
            </div>
        </div>

        <div class="boxTwo">
            <div class="txt">已选择{{chooseList.length}}个订单</div>
            <div class="rightHandle">
                <el-checkbox v-model="isInvalid" style="margin-right:10px;" @change="toSearch">作废订单</el-checkbox>
                <el-input v-model="filterParam.orderNo" placeholder="订单编号" prefix-icon="el-icon-search" size="small" style="width:180px;margin-right:10px;" @keyup.enter.native="toSearch"></el-input>

                <el-input v-model="filterParam.orderName" placeholder="订单名称" size="small" style="width:180px;margin-right:10px;" @keyup.enter.native="toSearch"></el-input>

                <el-input v-model="filterParam.customerName" placeholder="公司名称" size="small" style="width:180px;margin-right:10px;" @keyup.enter.native="toSearch"></el-input>
                
                <el-date-picker v-model="filterParam.createTime" type="date" value-format="yyyy-MM-dd" placeholder="创建时间" size="small" style="width:180px;" @change="toSearch"></el-date-picker>
            </div>
        </div>

        <div class="boxTable">
            <el-table style="width:100%;" height="calc(100vh - 300px)" v-loading="tableLoading" :data="orderList"
                :header-cell-style="{'background-color':'#F2F2F2','color':'#3A3A3A'}" :row-style="rowStyle"
                @selection-change="selectionChange">
                <el-table-column type="selection" width="45"></el-table-column>

                <el-table-column prop="orderNo" label="订单编号">
                    <template slot-scope="scope">
                        <el-link @click.stop="toDetails(scope.row)">{{scope.row.orderNo}}</el-link>
                    </template>
                </el-table-column>
                <el-table-column prop="orderName" label="订单名称"></el-table-column>
                <el-table-column prop="orderDate" label="订单时间"></el-table-column>
                <el-table-column prop="deliveryDate" label="交货日期"></el-table-column>
                <el-table-column prop="expectedReceiptDate" label="预计到账日期"></el-table-column>
                <el-table-column prop="customerName" label="公司名称">
                    <template slot-scope="scope">
                        <el-popover trigger="hover">
                            <CompanyCard v-if="scope.row.card1Show" :ckey="scope.row.customerKey" />
                            <el-link slot="reference" type="primary" @click.stop="openCustomer(scope.row)">
                                <span @mouseenter="scope.row.card1Show = true;">{{ scope.row.customerName }}</span>
                            </el-link>
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column prop="orderAmount" label="实际金额">
                    <template slot-scope="scope">
                        {{scope.row.orderAmount}} CNY
                    </template>
                </el-table-column>
                <el-table-column label="订单状态">
                    <template slot-scope="scope">
                        {{scope.row.orderStatus}}
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-link type="info" @click.stop="toDetails(scope.row)">查看</el-link>
                        <span style="padding:0 5px;"></span>
                        <el-link type="info" @click.stop="toEditOrder(scope.row)">编辑</el-link>
                        <span style="padding:0 5px;"></span>
                        <el-link type="info" @click.stop="toCopyOrder(scope.row)">复制</el-link>
                        <span style="padding:0 5px;"></span>
                        <el-link v-if="orderIsReview && scope.row.orderStatus=='审核中'" type="primary" @click.stop="toAuditOrder(scope.row)">审核</el-link>
                        <!-- <el-link type="info" @click.stop="toDelOrder(scope.row)">删除</el-link> -->
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <div class="bottomPagination">
            <el-button style="float:left;margin:15px 20px;" size="small" icon="el-icon-refresh" @click="onRefresh">刷 新</el-button>
            <el-pagination background layout="total,sizes,prev,pager,next" :page-sizes="[10, 20, 40]" :page-size.sync="pageSize" @size-change="pageSizeChange" :total="totalItems" style="float:right;margin:15px 20px;" :current-page.sync="pageIndex" @current-change="pageIndexChange"></el-pagination>
        </div>

        <el-dialog title="订单审核" :visible.sync="auditVisible" width="600px">
            <el-select v-model="auditForm.suppliers" size="small" multiple filterable style="width:100%;" placeholder="选择供应商">
                <el-option v-for="item in supplierOption" :key="item.key" :label="item.manufacturerName" :value="item.key"></el-option>
                <div class="newPurchaser" @click="newPurchaser">
                    <i class="el-icon-plus"></i>
                    <span>新建供应商</span>
                </div>
                <div slot="empty">
                    <div class="newPurchaser" @click="newPurchaser">
                        <i class="el-icon-plus"></i>
                        <span>新建供应商</span>
                    </div>
                </div>
            </el-select>
            <span slot="footer" class="dialog-footer">
                <el-button @click="auditVisible = false;" size="small">取 消</el-button>
                <el-button type="primary" @click="auditComplete" :loading="btnLoading" size="small">通 过</el-button>
            </span>
        </el-dialog>

    </div>
</div>
</template>

<script>
import OrderNav from "@/components/OrderNav.vue";
import CompanyCard from "@/components/CompanyCard.vue"; //公司卡片
import PowerSelect from "@/components/PowerSelect.vue";
export default {
    components: { OrderNav, CompanyCard, PowerSelect },
    props: {
        handleSelect: {
            type: Function,
            default: null,
        },
    },
    data() {
        return {
            currentPath: "/orderManage/orderList",
            organizationKey: "",
            salekey: "",
            // 过滤字段
            filterParam: {
                orderNo: "",
                customerName: "",
                orderName: "",
                createTime: "",
            },
            isInvalid: false,
            //列表展示
            tableLoading: false,
            orderList: [],
            totalItems: 0,
            pageIndex: 1,
            pageSize: 10,

            chooseList: [],
            //订单审核权限
            orderIsReview: false,
            auditVisible: false,
            auditForm: {},
            btnLoading: false,
            supplierOption: [],
        };
    },
    methods: {
        chooseNav(index){
            this.handleSelect(index);
            this.$router.push(index);
        },

        onRefresh(){
            this.filterParam = {
                orderNo: "",
                customerName: "",
                orderName: "",
                createTime: "",
            };
            this.toSearch();
        },
        rowStyle({rowIndex}){
            // 表格隔行换色
            if(rowIndex % 2 == 1){
                return {'background-color':'#F9FAFC'}
            }
        },
        //查看客户详情
        openCustomer(row) {
            let routeData = this.$router.resolve({
                path: "/customerManage/customerDetailsPrivate",
                query: { customerKey: row.customerKey },
            });
            window.open(routeData.href, "_blank");
        },

        toNewOrder(){
            let routeData = this.$router.resolve({
                path: "/orderManage/addOrder",
            });
            window.open(routeData.href, "_blank");
        },
        toEditOrder(row){
            let routeData = this.$router.resolve({
                path: "/orderManage/addOrder",
                query: { orderKey: row.uniqueKey },
            });
            window.open(routeData.href, "_blank");
        },
        toCopyOrder(row){
            let routeData = this.$router.resolve({
                path: "/orderManage/addOrder",
                query: { orderKey: row.uniqueKey, optype: "copy" },
            });
            window.open(routeData.href, "_blank");
        },

        toDelOrder(row){
            this.$confirm('是否删除该订单?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$axios.get(`${this.$orderUrl}/api/Order/Delete?orderKey=${row.uniqueKey}`).then(res => {
                    const {isSuccess} = res;
                    if(isSuccess){
                        this.$message({type: 'success',message: '删除成功!'});
                        this.getOrderList();
                    }
                });
            }).catch(() => {});
        },

        //订单审核功能
        toAuditOrder(row){
            this.auditVisible = true;
            this.auditForm = {
                orderKey: row.uniqueKey,
                orderStatus: row.orderStatus,
                suppliers: [],
            }
            this.getPurchaser();
        },
        auditComplete(){
            if(this.auditForm.suppliers.length == 0){
                this.$message({type: 'warning',message: '请选择供应商!'});
                return false;
            }
            this.auditForm.orderStatus = "已完成";
            this.btnLoading = true;
            this.$axios.post(`${this.$orderUrl}/api/Order/ChangeStatus`, this.auditForm).then(res => {
                const {isSuccess} = res;
                this.btnLoading = false;
                this.auditVisible = false;
                if(isSuccess){
                    this.$message({type: 'success',message: '审核成功!'});
                    this.getOrderList();
                }
            });
        },
 
        toSearch(){
            this.pageIndex = 1;
            this.getOrderList();
        },

        //获取订单列表
        getOrderList(){
            const {orderNo,orderName,customerName,createTime} = this.filterParam;
            this.tableLoading = true;
            this.$axios.post(`${this.$orderUrl}/api/Order/V2/GetOrders`,{
                orderNo,orderName,customerName,createTime,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
                organizationKey: this.organizationKey,
                ukey: this.salekey,
                orderStatus: this.isInvalid ? "已作废" : "",
            }).then((res) => {
                const { isSuccess , data } = res;
                this.tableLoading = false;
                if (isSuccess) {
                    this.orderList = data.datas.map(item=>({
                        ...item,
                        card1Show: false,
                    }))
                    this.totalItems = data.totalItems;
                }
            });
        },
        pageIndexChange(page) {
            this.pageIndex = page;
            this.getOrderList();
        },
        pageSizeChange() {
            this.pageIndex = 1;
            this.getOrderList();
        },
        //前往订单详情
        toDetails(row){
            let routeData = this.$router.resolve({
                path: "/orderManage/orderDetails",
                query: { orderKey: row.uniqueKey },
            });
            window.open(routeData.href, "_blank");
        },

        selectionChange(value){
            console.log(value);
            this.chooseList = value;
        },

        //选择组织结构、员工
        onChoose(valueOne,valueTwo){
            this.organizationKey = valueOne;
            this.salekey = valueTwo;
            this.onRefresh();
        },


        //获取供应商
        getPurchaser(){
            this.$axios.post(`${this.$orderUrl}/Purchase/GetPurchaser`,{
                manufacturerName: "",
                pageIndex: 1,
                pageSize: 100,
            }).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    this.supplierOption = data.datas;
                }
            });
        },
        //新建供应商
        newPurchaser(){
            let routeData = this.$router.resolve({
                path: "/purchase/newPurchaser",
            });
            window.open(routeData.href, "_blank");
        },
        
    },
    mounted() {
        this.getOrderList();    //获取订单列表

        //主账号和有采购权限的部门经理拥有审核权限
        let userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {};
        if(userInfo.isAdmin || (userInfo.isManager && userInfo.isProcureModel)){
            this.orderIsReview = true;
        }
    },
};
</script>

<style lang="scss" scoped>
.viewbox{
    width: calc(100% - 200px);
    height: calc(100vh - 140px);
    background: rgba($color: #FFFFFF, $alpha: 0.7);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    margin-bottom: 60px;
    .boxOne,.boxTwo{
        overflow: hidden;
        padding: 8px 0;
        line-height: 32px;
        border-bottom: 1px solid #E6E7EB;
        .title{
            float: left;
            margin-left: 40px;
            font-size: 24px;
            font-weight: bold;
            color: #3A3A3A;
        }
        .txt{
            float: left;
            margin-left: 40px;
            font-size: 16px;
            color: #7C90A5;
        }
        .rightHandle{
            float: right;
            margin-right: 20px;
        }
    }

    .bottomPagination{
        position: fixed;
        bottom: 0;
        right: 0;
        z-index: 10;
        width: calc(100vw - 200px);
        height: 60px;
        background: #FFFFFF;
        box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.16);
    }
}
.newPurchaser{
    cursor: pointer;
    text-align: center;
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    color: #6a6a6a;
    &:hover{
        background-color: #F5F7FA;
    }
}
</style>