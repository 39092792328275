<template>
  <div id="contry-analyse" class="contry-analyse" v-loading="loading">
    <div class="title" ref="title">
      <p style="font-weight: bold">
        <span style="color: var(--themeColor, #3152AF)">{{ $store.getters.titleText }}</span
        >国家排名
      </p>
      <div style="display: flex; align-items: center">
        <el-radio-group v-model="controlForm.option" size="mini">
          <el-radio-button :label="0">频次</el-radio-button>
          <el-radio-button :label="1">金额</el-radio-button>
          <el-radio-button :label="2">数量</el-radio-button>
          <el-radio-button :label="3">重量</el-radio-button>
        </el-radio-group>
        <img v-show="false" class="icon" src="@/firm/images/company/icon-download.png" style="margin-left: 1em" />
      </div>
    </div>
    <div class="content">
      <div ref="chart" style="width: 400px; height: 400px"></div>
      <div style="width: calc(100% - 400px)">
        <el-table :data="tableData" height="360px" :header-cell-style="{ background: '#E5E9F2', color: '#1F2D3D' }" :row-style="tableRowStyleCallBack">
          <el-table-column prop="order" align="center" width="40px" />
          <el-table-column label="国家" show-overflow-tooltip>
            <template slot-scope="{ row }">
              <div style="display: flex; align-items: center">
                <img :src="row.flagSrc" style="height: 1em; margin-right: 6px; box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16)" />
                {{ row.countryCN }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="frequencyStr" label="频次" show-overflow-tooltip />
          <el-table-column prop="amountStr" label="金额" show-overflow-tooltip />
          <el-table-column prop="countStr" label="数量" show-overflow-tooltip />
          <el-table-column prop="weightStr" label="重量" show-overflow-tooltip />
        </el-table>
        <div style="margin-top: 1em; display: flex; justify-content: flex-end">
          <el-pagination background layout="total, prev, pager, next" :total="pagination.total" :page-size="pagination.pageSize" :current-page="pagination.pageIndex" @current-change="handlePageIndexChange" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import echarts from "@/firm/plugins/echarts";
//import echarts from "@/firm/plugins/echarts4";
import { tableRowStyleCallBack } from "@/firm/utils";
import axios from "@/firm/plugins/axios";
import { mapState, mapGetters, mapMutations } from "vuex";
import LazyOperation from "@/firm/plugins/lazy-execute";
import { AnchorInditor } from "../utils";

let chartInstance = null;
let lazyOperation = null;

export default Vue.extend({
  data() {
    return {
      loading: false,
      controlForm: {
        option: 0,
      },
      tableData: [],
      pagination: {
        total: 0,
        pageSize: 10,
        pageIndex: 1,
      },
    };
  },
  computed: {
    ...mapState(["company", "companyType"]),
    ...mapState("controlFormGlobal", {
      ie: "ie",
      dataCountry: "dataCountry",
      dateRange: "dateRange",
      hsCode: "hsCode",
      importer: "importer",
      exporter: "exporter",
      country: "country",

      exHsCode: "exHsCode",
      exExporter: "exExporter",
      exImporter: "exImporter",
      exCountry: "exCountry",
    }),
    ...mapGetters("controlFormGlobal", {
      startTime: "startTime",
      endTime: "endTime",
    }),
  },
  watch: {
    "controlForm.option"() {
      lazyOperation?.reobserve();
    },
    ie() {
      lazyOperation?.reobserve();
    },
    dataCountry() {
      lazyOperation?.reobserve();
    },
    dateRange() {
      lazyOperation?.reobserve();
    },
    hsCode() {
      lazyOperation?.reobserve();
    },
    importer() {
      lazyOperation?.reobserve();
    },
    exporter() {
      lazyOperation?.reobserve();
    },
    country() {
      lazyOperation?.reobserve();
    },

    exHsCode() {
      lazyOperation?.reobserve();
    },
    exExporter() {
      lazyOperation?.reobserve();
    },
    exImporter() {
      lazyOperation?.reobserve();
    },
    exCountry() {
      lazyOperation?.reobserve();
    },

    companyType() {
      lazyOperation?.reobserve();
    },
  },
  methods: {
    ...mapMutations("anchor", {
      setCurrent: "setCurrent",
    }),
    handlePageIndexChange(pageIndex) {
      this.getTableData(pageIndex);
    },
    tableRowStyleCallBack,
    reset() {
      this.tableData = [];
      chartInstance?.setOption({
        series: [
          {
            name: "map",
            data: [],
          },
        ],
      });
    },
    async getTableData(pageIndex = 1) {
      try {
        this.loading = true;
        const res = await axios({
          method: "post",
          url: "/search/Credit/Ranking/Country",
          data: {
            pageIndex,
            pageSize: this.pagination.pageSize,
            ie: this.ie,
            importer: this.importer,
            exporter: this.exporter,
            dataCountry: this.dataCountry,
            country: this.country,
            startTime: this.startTime,
            endTime: this.endTime,
            hsCode: this.hsCode,
            sortType: this.controlForm.option,
            noHsCode: this.exHsCode,
            noExporter: this.exExporter,
            noImporter: this.exImporter,
            noCountry: this.exCountry,
            company: this.company,
            companyType: this.companyType,
          },
        });
        this.tableData = res.data.result.data;
        //console.log(res.data.result.total);
        this.pagination.total = res.data.result.total;
        this.pagination.pageIndex = res.data.result.pageIndex;
        //this.refreshChartInstance();
      } catch {
        this.loading = false;
      } finally {
        this.loading = false;
      }
      /* this.tableData = [
        {
          order: 1, //排名
          flagSrc: "https://vip.lsmaps.com/context/image/world-icon/tw.jpg", //国旗url
          country: "CHINA", //表格内国家名
          countryCN: "中国", //国家中文名
          name: "China", //echarts地图name
          frequencyStr: "2,250", //频次
          frequency: 2250, //频次，转数字
          amountStr: "43243.62(USD)", //金额
          amount: 43243.62, //金额，转数字
          countStr: "85832356.41", //数量
          count: 85832356.41, //数量，转数字
          weightStr: "1641999.00", //重量
          weight: 1641999.0, //重量，转数字
        },
      ] as any; */
    },
    async refreshChartInstance() {
      // eslint-disable-next-line
      const _this = this;
      const res = await axios({
        method: "post",
        url: "/search/Credit/Ranking/Country",
        data: {
          pageIndex: 1,
          pageSize: 50,
          ie: this.ie,
          importer: this.importer,
          exporter: this.exporter,
          dataCountry: this.dataCountry,
          country: this.country,
          startTime: this.startTime,
          endTime: this.endTime,
          hsCode: this.hsCode,
          sortType: this.controlForm.option,
          noHsCode: this.exHsCode,
          noExporter: this.exExporter,
          noImporter: this.exImporter,
          noCountry: this.exCountry,
          company: this.company,
          companyType: this.companyType,
        },
      });
      const data = res.data.result.data;
      //console.log(data);
      chartInstance?.setOption({
        tooltip: {
          trigger: "item",
          formatter: function (param) {
            const data = param.data;
            if (data) {
              return `
              ${param.data.text}<br />
              排名：${param.data.order}<br />
              ${["频次", "金额", "数量", "重量"][_this.controlForm.option]}：${param.data.value}
            `;
            } else {
              return "";
            }
          },
        },
        visualMap: {
          type: "continuous",
          max: data[0][["frequency", "amount", "count", "weight"][_this.controlForm.option]],
          calculable: true,
        },
        series: [
          {
            name: "mapw",
            data: data.map((item) => ({
              name: item.name,
              text: item.countryCN,
              order: item.order,
              value: item[["frequency", "amount", "count", "weight"][_this.controlForm.option]],
            })),
          },
        ],
      });
    },
  },
  mounted() {
    chartInstance = echarts.init(this.$refs["chart"]);
    chartInstance.setOption({
      visualMap: {
        type: "continuous",
        calculable: true,
        inRange: {
          color: ["#FAE7D9", "#5E3828"],
        },
      },
      series: [
        {
          name: "mapw",
          type: "map",
          mapType: "world",
          data: [
            /* {
              name: "China",
              text: "1",
              order: 1,
              value: "123",
            }, */
          ],
        },
      ],
    });
    //this.refreshChartInstance();
    lazyOperation = new LazyOperation(() => {
      this.reset();
      this.getTableData();
      this.refreshChartInstance();
    }, this.$el);
    new AnchorInditor(() => {
      this.setCurrent("GJFX");
    }, this.$refs["title"]);
  },
});
</script>

<style lang="scss" scoped>
@import "@/firm/scss/mixin.scss";

.contry-analyse {
  margin-top: 1rem;
  background: #fff;
  padding: 1rem;
  padding-top: 0;
  @include mixin-panel-title;
  .content {
    display: flex;
  }
}
</style>
