<template>
    <el-dialog :visible.sync="joinDialogVisible" width="1000px" top="100px" @close="$emit('closeDialog')">
        <div slot="title">
            <span style="margin-right:20px;font-weight:bold;">完善客户资料</span>
            <el-checkbox v-model="isConversion">同时转化为商机</el-checkbox>
        </div>
        <div class="customerBox">
            <div style="margin-bottom: 8px;">
                <div class="companyName">{{joinForm.companyName}}</div>
                <div class="country">
                    <img :src="joinForm.flagUrl" style="margin-right:5px;height:14px" />
                    <span>{{joinForm.country}}</span>
                </div>
            </div>
            <el-row :gutter="24">
                <el-col :span="6">
                    <div class="labelColor">公司分组:</div>
                    <el-select v-model="joinForm.privateGroupKey" placeholder="请选择" size="small">
                        <el-option v-for="item in PrivateGroupOption" :key="item.key" :label="item.name" :value="item.key"></el-option>
                    </el-select>
                </el-col>
                <el-col :span="6">
                    <div class="labelColor">公司状态:</div>
                    <el-select v-model="joinForm.customerStatuKey" placeholder="请选择" size="small">
                        <el-option v-for="item in StatuOption" :key="item.key" :label="item.name" :value="item.key"></el-option>
                    </el-select>
                </el-col>
                <el-col :span="6">
                    <div class="labelColor">公司来源:</div>
                    <el-select v-model="joinForm.customerSourceKey" placeholder="请选择" size="small">
                        <el-option v-for="item in SourceOption" :key="item.key" :label="item.name" :value="item.key"></el-option>
                    </el-select>
                </el-col>
                <el-col :span="6">
                    <div class="labelColor">星级:</div>
                    <el-rate v-model="joinForm.star"></el-rate>
                </el-col>
            </el-row>
            <div class="sjInfo" v-if="isConversion">
                <div class="tt">商机信息</div>
                <el-row :gutter="24">
                    <el-col :span="6">
                        <div class="labelColor">商机名称:</div>
                        <el-input v-model="sjForm.name" size="small"></el-input>
                    </el-col>
                    <el-col :span="6">
                        <div class="labelColor">商机状态:</div>
                        <el-select v-model="sjForm.stageKey" placeholder="请选择" size="small">
                            <el-option v-for="item in StageOption" :key="item.stageKey" :label="item.stageName" :value="item.stageKey"></el-option>
                        </el-select>
                    </el-col>
                    <el-col :span="6">
                        <div class="labelColor">货币:</div>
                        <el-select v-model="sjForm.currency" placeholder="请选择" size="small">
                            <el-option v-for="item in CurrencyOption" :key="item.currency" :label="item.currency" :value="item.currency"></el-option>
                        </el-select>
                    </el-col>
                    <el-col :span="6">
                        <div class="labelColor">金额:</div>
                        <el-input-number v-model="sjForm.amount" :min="0" size="small"></el-input-number>
                    </el-col>
                </el-row>
            </div>
            <div class="contactInfo" v-loading="dataLoading">
                <div class="tt">
                    <div>
                        <span style="padding-right:10px;">联系人</span>
                        <el-checkbox v-model="joinForm.isAll" :disabled="totalContact==0">全部加入</el-checkbox>
                    </div>
                    <div>
                        <el-tag effect="plain" size="mini" class="pointer" @click="addItemContact">新增</el-tag>
                        <el-tag v-if="totalContact > pageIndex*10" effect="plain" size="mini" class="pointer" @click="getNextContact">加载更多</el-tag>
                    </div>
                </div>
                <p>1.勾选全部加入:自动将全部联系人加入。</p>
                <p>2.不勾选全部加入:自行编辑下方联系人信息加入。</p>
                <el-row :gutter="24" style="max-height: 400px;overflow-x: hidden;overflow-y: scroll;">
                    <el-col :span="12" v-for="(item,index) in showContactList" :key="index">
                        <div class="contactItem">
                            <div style="display: flex;justify-content: space-between;">
                                <div style="font-size:16px;">联系人{{index+1}}</div>
                                <el-tag effect="plain" size="mini" type="danger" class="pointer" @click="deleteItemContact(index)">删除</el-tag>
                            </div>
                            <div style="display: flex;margin-top:10px;justify-content: space-between;">
                                <div class="width:45%;display: flex;">
                                    <span class="labelColor">昵称：</span>
                                    <el-input v-model="item.name" size="mini" style="width:160px;"></el-input>
                                </div>
                                <div class="width:45%;display: flex;">
                                    <span class="labelColor">职位：</span>
                                    <el-input v-model="item.position" size="mini" style="width:160px;"></el-input>
                                </div>
                            </div>
                            <div style="display: flex;margin-top:10px;justify-content: space-between;">
                                <div class="width:45%;display: flex;">
                                    <span class="labelColor">邮箱：</span>
                                    <el-input v-model="item.email" size="mini" style="width:160px;"></el-input>
                                </div>
                                <div class="width:45%;display: flex;">
                                    <span class="labelColor">电话：</span>
                                    <el-input v-model="item.tel" size="mini" style="width:160px;"></el-input>
                                </div>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="$emit('closeDialog')">取 消</el-button>
            <el-button size="small" type="primary" @click="joinSubmit" :loading="btnLoading">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>
import dayjs from 'dayjs'
export default {
    props: ["joinDialogVisible","clickItem"],
    data() {
        return {
            //加入我的客户
            btnLoading: false,            
            joinForm: {},
            isConversion: false,    //是否转化为商机
            sjForm: {},
            //下拉框配置
            PrivateGroupOption: [],
            StatuOption: [],
            SourceOption: [],
            StageOption: [],
            CurrencyOption: [],
            //选择联系人
            dataLoading: false,
            pageIndex: 1,
            showContactList: [],
            totalContact: 0,
        };
    },
    methods: {
        initForm(item){
            //添加客户表单
            this.joinForm = {
                companyName: item.companyName,
                flagUrl: item.flagUrl,
                country: item.country,
                myClueKey: item.key || item.myClueKey,
                privateGroupKey: "",
                customerStatuKey: "",
                customerSourceKey: "",
                star: 0,
                contacts: [],
                isAll: true,  //默认联系人全部加入
            }
            // 添加商机表单
            let userInfo = JSON.parse(localStorage.userInfo);
            this.sjForm = {
                stageKey: "",
                name: "",
                companyKey: "",
                currency: "CNY",
                amount: 0,
                endTime: dayjs().add(7, 'day').format('YYYY-MM-DD'),
                members: [
                    {
                        "userName": userInfo.userCnName,
                        "ukey": userInfo.uKey,
                        "isAdmin": false,
                        "isCreate": true,
                    }
                ]
            }
            //下拉框配置
            this.getPrivateGroup();
            this.getCustomerStatu();
            this.getCustomerSource();
            this.getStages();
            this.getCurrencyConfig();
            //获取线索联系人列表
            this.pageIndex = 1;
            this.showContactList = [];
            this.getContactsList();
        },
        joinSubmit(){
            if(this.isConversion && !this.sjForm.name){
                this.$message({ message: '商机名称不能为空', type: 'warning'});
                return false;
            }
            this.btnLoading = true;
            this.joinForm.contacts = this.showContactList;
            this.$axios.post(`${this.$customerUrl}/Company/ClueAddMyCustomer`,this.joinForm).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    if(this.isConversion){
                        this.sjForm.companyKey = data;  //加入客户成功后返回的data数据为客户key
                        this.$axios.post(`${this.$sjUrl}/BusinessChance/AddBusiness`,this.sjForm).then((res) => {
                            const { isSuccess } = res;
                            if (isSuccess) {
                                this.btnLoading = false;
                                this.$emit('onFinish');
                            }
                        });                        
                    }else{
                        this.btnLoading = false;
                        this.$emit('onFinish');
                    }  
                }
            });
        },

        //下拉框配置
        getPrivateGroup(){
            this.$axios.get(`${this.$customerUrl}/v1/Config/PrivateGroup`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.PrivateGroupOption = data;
                    this.joinForm.privateGroupKey = this.PrivateGroupOption.filter(item => item.isDefault)[0].key;
                }
            });
        },
        getCustomerStatu(){
            this.$axios.get(`${this.$customerUrl}/v1/Config/Statu`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.StatuOption = data;
                    this.joinForm.customerStatuKey = this.StatuOption.filter(item => item.isDefault)[0].key;
                }
            });
        },
        getCustomerSource(){
            this.$axios.get(`${this.$customerUrl}/v1/Config/Source`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.SourceOption = data;
                    this.joinForm.customerSourceKey = this.SourceOption.filter(item => item.isDefault)[0].key;
                }
            });
        },
        getStages(){
            this.$axios.get(`${this.$sjUrl}/BusinessChance/GetStages`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.StageOption = data.filter(item => item.stageName != "输单");
                    this.sjForm.stageKey = this.StageOption[0].stageKey;
                }
            });
        },
        getCurrencyConfig(){
            this.$axios.get(`${this.$customerUrl}/v1/Config/GetCurrencyConfig`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.CurrencyOption = data;
                }
            });
        },

        //获取线索联系人列表
        getContactsList(){
            this.dataLoading = true;
            this.$axios.post(`${this.$clueUrl}/MyClue/V2/GetContactsList`,{
                myClueKey: this.joinForm.myClueKey,
                pageIndex: this.pageIndex,
                pageSize: 10,
            }).then((res) => {
                const { isSuccess, data } = res;
                this.dataLoading = false;
                if (isSuccess) {
                    this.showContactList = this.showContactList.concat(data.datas);
                    this.totalContact = data.totalItems;
                    if(this.totalContact == 0){
                        this.joinForm.isAll = false;
                        this.addItemContact();
                        return false;
                    }
                    if(this.totalContact <= this.pageIndex*10){
                        this.$message({message: '联系人已加载完毕',type: 'success' });
                    }
                }
            });
        },
        getNextContact(){
            this.pageIndex++;
            this.getContactsList();
        },
        addItemContact(){
            this.showContactList.push({
                "email": "",
                "name": "",
                "position": "",
                "tel": "",
                "isMain": false
            })
        },
        deleteItemContact(index){
            this.showContactList.splice(index,1);
        },
    },
    mounted() {
        
    },
    watch:{
        joinDialogVisible(){
            if(this.$props.joinDialogVisible){
                this.initForm(this.$props.clickItem);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.customerBox{
    .companyName{
        display: inline-block;
        color: #3A3A3A;
        font-size: 18px;
        font-weight: bold;
    }
    .country{
        display: inline-block;
        margin-left: 20px;
        img{
            vertical-align: middle;
        }
    }
    .labelColor{
        color: #5E6D82;
    }
    .sjInfo{
        margin-top: 20px;
        padding: 15px 20px;
        background: #DEEAF2;
        border-radius: 2px 2px 2px 2px;
        .tt{
            color: #3A3A3A;
            font-weight: bold;
            height: 20px;
            line-height: 20px;
            padding-left: 5px;
            border-left: 3px solid var(--themeColor);
            margin-bottom: 10px;
        }
    }
    .contactInfo{
        margin-top: 20px;
        overflow: hidden;
        .tt{
            color: #3A3A3A;
            font-weight: bold;
            height: 20px;
            line-height: 20px;
            padding-left: 5px;
            border-left: 3px solid var(--themeColor);
            margin-bottom: 10px;
            display: flex;
            justify-content: space-between;
        }
        .contactItem{
            background: #F6F6F6;
            padding: 10px 20px;
            margin-bottom: 10px;
        }
    }
}
</style>