<template>
    <div>
        <div class="title">接口说明</div>
        <p>请求地址：{{requestUrl}}</p>
        <p>请求方法：{{requestMethod}}</p>
        <p v-if="!isHide">请求头：Token (调用接口的凭证，通过获取Token 接口获得)</p>
    </div>
</template>

<script>
export default {
    props: ["requestUrl", "requestMethod","isHide"],
    data() {
        return {};
    },
    methods: {},
    mounted() {},
};
</script>

<style lang="scss" scoped>
.title {
    font-weight: bold;
    font-size: 20px;
    line-height: 40px;
    margin-top: 20px;
}
p {
    line-height: 28px;
    color: #333;
}
</style>