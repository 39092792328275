<template>
    <div>
        <InterfaceName :interfaceName="interfaceName" />
        <InterfaceSpe :requestUrl="requestUrl" :requestMethod="requestMethod" />
        <InputParam :inputTableData="inputTableData" />
        <ReturnParam :returnObj="returnObj" />   
    </div>
</template>

<script>
import InterfaceName from "../Acomponents/InterfaceName.vue";
import InterfaceSpe from "../Acomponents/InterfaceSpe.vue";
import InputParam from "../Acomponents/InputParam.vue";
import ReturnParam from "../Acomponents/ReturnParam.vue";

export default {
    components: { InterfaceName, InterfaceSpe, InputParam, ReturnParam },
    data() {
        return {
            interfaceName: "获取产品详情",
            requestUrl: "https://crm.qixinerp.com/open/Product/Detail",
            requestMethod: "GET",
            inputTableData: [],
            returnObj: {},
        };
    },
    methods: {
        
    },
    mounted() {
        this.inputTableData = [
            {paramName: "productKey", valueType: "string", explain: "产品key", required: true},
        ]
        this.returnObj = {
            "isSuccess": true,
            "errorMessage": null,
            "data": {
                "groupName": "鞋类",
                "updateUser": "彭贤鑫",
                "updateTime": "2023-04-19 17:29:52",
                "createUserName": "彭贤鑫",
                "createTime": "2022-01-12 15:48:00",
                "isTop": true,
                "isAttention": true,
                "isState": "有效",
                "key": "6903c47a216b48559c2ab33e247f7969",
                "productNo": "CS001",
                "images": "http://qixin-crm-disk.oss-cn-shanghai.aliyuncs.com/92606a12-11f2-4305-b19e-a06e503ec76a.png",
                "productName": "耐克鞋子",
                "dimension": "0.1m*0.1m*0.1m",
                "specDesc": "合格",
                "ingredient": "人工合成橡胶",
                "supplier": "耐克",
                "measureUnit": "PCS",
                "hscode": "11220033",
                "grade": "A+",
                "basicModel": "J123456",
                "productionProcess": "工艺1",
                "productionStandard": "标准",
                "declareElement": "要素1",
                "productIns": "ins",
                "productCns": "cns",
                "ecNumber": "100",
                "isCommodityInspection": true,
                "isNeedPurchase": true,
                "isSale": true,
                "isSelfProduced": true,
                "brand": "耐克",
                "insuredBaseQuantity": "200",
                "preferredContainer": "300",
                "alias": "鞋子",
                "isDangerousGoods": false,
                "qualityGuaranteePeriod": "无",
                "sellPrice": 599,
                "taxIncludingPrice": 800,
                "rotationChart": [
                    "http://qixin-crm-disk.oss-cn-shanghai.aliyuncs.com/92606a12-11f2-4305-b19e-a06e503ec76a.png"
                ],
                "groupID": "6cfa0e29b4cb42098fbdc0ebed5f5d53",
                "description": ""
            },
        }
    },
};
</script>

<style lang="scss" scoped>

</style>