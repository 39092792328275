<script>
import Vue from 'vue'
import { reactive, ref, watch } from '@vue/composition-api'
import { Message } from 'element-ui'
import axios from 'axios'
import qs from 'qs'
export default Vue.extend({
    props:{
        handSubject:{
            type: String
        }
    },
setup(props, { emit }) {
    const form = reactive({
        taskName: '',
        senderAddress: '',
        senderNickName: '',
        replyEmail: '',
        isTiming: false,
        sendTime: '',
        taksType: 0 // as number
    })
    watch(() => props.handSubject,
        (newVal, oldVal) => {
            //主题名称填充邮件标题
            form.taskName = props.handSubject;
        }
    )

    const onDone = (type) => {
        if (!form.taskName) {
            Message.warning('请输入主题名称')
            return
        }
        if (!form.senderAddress) {
            Message.warning('请输入发件人邮箱')
            return
        }
        if (!form.senderNickName) {
            Message.warning('请输入发件人昵称')
            return
        }
        form.replyEmail = form.senderAddress;
        if(type == 1){
            emit('done', form)
        }else if(type == 2){
            emit('save', form)
        }
    }

    const datepicker = ref(null);
    const timeClick = (value) =>{
        if(value){
            setTimeout(() => {
                //勾选定时发送，日历自动聚焦
                datepicker.value.$el.firstElementChild.focus();
            }, 200)
        }
    }

    const emailOption = ref([]);
    const getEmailBox = () =>{
        axios({
          method: 'GET',
          url: Vue.prototype.$marketUrl + '/MassSending/GetEmailBox',
        }).then((res) =>{
            const {isSuccess ,data} = res;
            if (isSuccess) {
                data.forEach(ele =>{
                    ele.email = ele.key;
                    ele.name = ele.value;
                })
                emailOption.value = data;
            }
        })
    }
    getEmailBox();
    const chooseEmail = (value) =>{
        let aItem = emailOption.value.filter(ele => ele.email == value);
        if(aItem.length > 0){
            form.senderNickName = aItem[0].name;
        }
    }

    const url = window.location.href
    const index = url.lastIndexOf('?')
    const eventNo = qs.parse(url.slice(index + 1))['eventNo'] || '';
    if(eventNo){
        (async () =>{
            const res = await axios({
                method: 'GET',
                url: Vue.prototype.$marketUrl + '/MassSending/GetEventDetailed',
                params: { eventNo }
            })
            if(res.isSuccess){
                const {taskName,senderAddress,senderNickName,replyEmail,isTiming,sendTime,taksType} = res.data;
                form.taskName = taskName;
                form.senderAddress = senderAddress;
                form.senderNickName = senderNickName;
                form.replyEmail = replyEmail;
                form.isTiming = isTiming;
                form.sendTime = isTiming ? sendTime : "";
                form.taksType = taksType;
            }
        })()
    }

    return {
      form,
      onDone,
      datepicker,
      timeClick,
      emailOption,
      getEmailBox,
      chooseEmail,
    }
  }
})
</script>

<template>
  <div class="content-step-3">
    <div style="position: absolute; bottom: 20px; right: 20px">
      <el-button @click="$emit('prev')" type="primary" size="mini">上一步</el-button>
      <el-button @click="onDone(2)" type="primary" size="mini">保存草稿</el-button>
      <el-button @click="onDone(1)" type="primary" size="mini">发送</el-button>
    </div>
    <div style="width: 574px; padding-top: 20px">
      <el-form size="small" label-position="top">
        <el-form-item label="主题名称" required>
          <el-input v-model.trim="form.taskName" placeholder="请输入主题名称，不会展示给收件人" />
        </el-form-item>
        <el-form-item label="发件人邮箱（可填可选）" required>
            <!-- <el-input v-model.trim="form.senderAddress" placeholder="发件人邮箱" /> -->
            <el-select v-model="form.senderAddress" filterable allow-create default-first-option style="width:100%;" @change="chooseEmail">
                <el-option v-for="item in emailOption" :key="item.email" :label="item.email" :value="item.email">
                    <span>邮箱:{{item.email}}</span>
                    <span style="padding: 0 10px;"></span>
                    <span>昵称:{{item.name}}</span>
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="发件人昵称" required>
          <el-input v-model.trim="form.senderNickName" placeholder="发件人昵称" />
        </el-form-item>
        <!-- <el-form-item label="回复邮件" required>
          <el-input v-model.trim="form.replyEmail" placeholder="回复邮件时邮箱" />
        </el-form-item> -->
        <el-form-item label="定时发信">
            <el-checkbox v-model="form.isTiming" @change="timeClick">定时发送</el-checkbox>
            <el-date-picker ref="datepicker" v-model="form.sendTime" value-format="yyyy-MM-dd HH:mm:ss" class="c-el-date-picker" type="datetime" size="mini" placeholder="定时发送时间" />
        </el-form-item>
        <!-- <el-form-item label="任务类型" required>
          <el-radio-group v-model="form.taksType" style="width: 100%">
            <el-radio :label="0">通知</el-radio>
            <el-radio :label="1">邀请</el-radio>
            <el-radio :label="2">开发信</el-radio>
            <el-radio :label="3">广告</el-radio>
          </el-radio-group>
        </el-form-item> -->
      </el-form>
    </div>
  </div>
</template>

<style scoped lang="scss">
.content-step-3 {
  box-sizing: border-box;
  height: 100%;
  padding: 20px;
  padding-top: 0;
  position: relative;
  display: flex;
  justify-content: center;
}
.c-el-date-picker {
  ::v-deep .el-input__inner {
    border: none;
  }
}
</style>
