<template>
<div>
    <div v-if="ie=='i'">
        <!-- 印度尼西亚(IDN)进口提单明细 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th width="25%">日期编码</th>
                        <td width="25%">{{billInfo.CustomsDataCode}}</td>
                        <th width="25%">进出口类型</th>
                        <td width="25%">{{billInfo.Type}}</td>
                    </tr>
                    <tr>
                        <th>进口商序列号</th>
                        <td colspan="3">{{billInfo.ImporterTaxId}}</td>
                    </tr>
                    <tr>
                        <th>出口商所在国</th>
                        <td>{{billInfo.ExporterCountry}}</td>
                        <th>出口商所在国家代码</th>
                        <td>{{billInfo.ExporterCountryCode}}</td>
                    </tr>
                    <tr>
                        <th>品牌</th>
                        <td>{{billInfo.Brand}}</td>
                        <th>数量单位</th>
                        <td>{{billInfo.Unit}}</td>
                    </tr>
                    <tr>
                        <th>净重</th>
                        <td>{{billInfo.Weight ? billInfo.Weight+'KGS':''}}</td>
                        <th>CIF金额(美元)</th>
                        <td>{{billInfo.Value}}</td>
                    </tr>
                    <tr>
                        <th>CIF金额(外币)</th>
                        <td>{{billInfo.TotalCifValueInFC}}</td>
                        <th>外币</th>
                        <td>{{billInfo.InvoiceCurrency}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
        
        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">运输信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th>运输方式代码</th>
                        <td colspan="3">{{billInfo.ShipmentMode}}</td>
                    </tr>
                    <tr>
                        <th width="25%">原产国</th>
                        <td width="25%">{{billInfo.Country}}</td>
                        <th width="25%">原产国家代码</th>
                        <td width="25%">{{billInfo.OriginCountryCode}}</td>
                    </tr>
                    <tr>
                        <th>起运港</th>
                        <td>{{billInfo.LoadingPort}}</td>
                        <th>起运港代码</th>
                        <td>{{billInfo.LoadingPortCode}}</td>
                    </tr>
                    <tr>
                        <th>卸货港</th>
                        <td>{{billInfo.UnLoadingPort}}</td>
                        <th>卸货港代码</th>
                        <td>{{billInfo.PelBongka}}</td>
                    </tr>
                    <tr>
                        <th>转运港</th>
                        <td>{{billInfo.TransShipmentPort}}</td>
                        <th>清关港口代码</th>
                        <td>{{billInfo.CustomsPort}}</td>
                    </tr>
                    <tr>
                        <th>保险</th>
                        <td>{{billInfo.Insurance}}</td>
                        <th>运费</th>
                        <td>{{billInfo.Freight}}</td>
                    </tr>
                    <tr>
                        <th>包装数量</th>
                        <td>{{billInfo.PackagesQuantity}}</td>
                        <th>包装类型</th>
                        <td>{{billInfo.PackageType}}</td>
                    </tr>
                    <tr>
                        <th>适用进口关税</th>
                        <td>{{billInfo.ImportDuty}}</td>
                        <th>适用进口增值税</th>
                        <td>{{billInfo.ImportVat}}</td>
                    </tr>
                    <tr>
                        <th>适用所得税</th>
                        <td>{{billInfo.ImportTax}}</td>
                        <th>适用进口奢侈品关税</th>
                        <td>{{billInfo.ImportLuxuryGoods}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
    <div v-if="ie=='e'">
        <!-- 印度尼西亚(IDN)出口提单明细 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th width="25%">日期编码</th>
                        <td width="25%">{{billInfo.CustomsDataCode}}</td>
                        <th width="25%">出口报关号</th>
                        <td width="25%">{{billInfo.ExportDeclarationNumber}}</td>
                    </tr>
                    <tr>
                        <th>进口商所在国</th>
                        <td>{{billInfo.ImporterCountry}}</td>
                        <th>进口国家码</th>
                        <td>{{billInfo.ImporterCityCode}}</td>
                    </tr>
                    <tr>
                        <th>数量单位</th>
                        <td>{{billInfo.MeasrueUnit}}</td>
                        <th>净重</th>
                        <td>{{billInfo.NetWeight}}</td>
                    </tr>
                    <tr>
                        <th>单位净重</th>
                        <td>{{billInfo.UnitNetWeight}}</td>
                        <th>FOB金额</th>
                        <td>{{billInfo.Value}}</td>
                    </tr>
                    <tr>
                        <th>原始FOB金额</th>
                        <td>{{billInfo.OriginFobValue}}</td>
                        <th>单位FOB金额</th>
                        <td>{{billInfo.UnitFobValue}}</td>
                    </tr>
                    <tr>
                        <th>关税代码</th>
                        <td>{{billInfo.TaxNo}}</td>
                        <th>外币</th>
                        <td>{{billInfo.InvoiceCurrency}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
        
        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">运输信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">目的港所在国</th>
                        <td width="25%">{{billInfo.Country}}</td>
                        <th width="25%">目的国代码</th>
                        <td width="25%">{{billInfo.DestinationCityCode}}</td>
                    </tr>
                    <tr>
                        <th>起运港</th>
                        <td>{{billInfo.LoadingPort}}</td>
                        <th>起运港代码</th>
                        <td>{{billInfo.PodCode}}</td>
                    </tr>
                    <tr>
                        <th>卸货港</th>
                        <td>{{billInfo.UnLoadingPort}}</td>
                        <th>卸货港代码</th>
                        <td>{{billInfo.PofCode}}</td>
                    </tr>
                    <tr>
                        <th>目的港</th>
                        <td>{{billInfo.DestinationPort}}</td>
                        <th>出口港</th>
                        <td>{{billInfo.ExportPort}}</td>
                    </tr>
                    <tr>
                        <th>保险</th>
                        <td>{{billInfo.Insurance}}</td>
                        <th>运费</th>
                        <td>{{billInfo.Freight}}</td>
                    </tr>
                    <tr>
                        <th>承运人代码</th>
                        <td>{{billInfo.FlagCode}}</td>
                        <th>承运人</th>
                        <td>{{billInfo.Carrier}}</td>
                    </tr>
                    <tr>
                        <th>承运人原产国</th>
                        <td>{{billInfo.Flag}}</td>
                        <th>集装箱数量</th>
                        <td>{{billInfo.ContainerQuantity}}</td>
                    </tr>
                    <tr>
                        <th>包装类型</th>
                        <td>{{billInfo.PackageType}}</td>
                        <th>包装数量</th>
                        <td>{{billInfo.PackagesQuantity}}</td>
                    </tr>
                    <tr>
                        <th>印尼清关海关</th>
                        <td>{{billInfo.ClearingCustoms}}</td>
                        <th>印尼报关海关</th>
                        <td>{{billInfo.LoadingCustoms}}</td>
                    </tr>
                    <tr>
                        <th>贸易方式</th>
                        <td>{{billInfo.Incoterms}}</td>
                        <th>运输航班</th>
                        <td>{{billInfo.VoyFlight}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
</div>
</template>

<script>
export default {
    props:["billInfo","ie"],
    data() {
        return {

        };
    },
    methods: {
        
    },
    mounted() {

    },
};
</script>

<style lang="scss" scoped>
.partItem {
    margin-bottom: 20px;
    /deep/.el-card__header {
        padding: 10px 20px;
        .headTitle {
            display: inline-block;
            padding-left: 10px;
            font-size: 14px;
            border-left: 2px solid var(--themeColor);
            font-weight: bold;
        }
    }
}
table {
    width: 100%;
    border-collapse: collapse;
    tr {
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;
        th,
        td {
            font-size: 12px;
            color: #666;
            height: 40px;
            border: 1px solid #eaeaea;
            text-align: center;
        }
        th {
            background-color: #f5f7fa;
            font-weight: 700;
        }
        .bg {
            background-color: var(--themeColor);
            color: #fff;
            font-weight: 700;
            opacity: 0.8;
        }
    }
}
</style>