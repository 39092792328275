<template>
  <div id="abroad-naics" class="abroad-naics" v-show="!hidden">
    <div class="title" style="padding-left: 1rem" ref="title">
      <p>NAICS——北美工业分类系统</p>
      <feedback position="NAICS——北美工业分类系统" url="" />
    </div>
    <div class="content">
      <div v-for="(item, index) of industryData" :key="index">
        <el-tooltip class="item" effect="light" placement="top-start">
          <div slot="content">{{ item.industryCode }}<br />{{ item.industryDescCn }}</div>
          <div class="item">
            <!-- <span class="badge">{{ index + 1 }}</span> -->
            <p class="title">{{ `${item.industryCode}` }}</p>
            <p class="description">{{ item.industryDescCn }}</p>
          </div>
        </el-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapMutations, mapState } from "vuex";
import axios from "@/firm/plugins/axios";
import { AnchorInditor } from "../../../utils";

export default Vue.extend({
  data() {
    return {
      loading: false,
      industryData: [],
      hidden: true,
    };
  },
  computed: {
    ...mapState(["company"]),
  },
  methods: {
    ...mapMutations("anchor", {
      setAnchorFlag: "setAnchorFlag",
      setCurrent: "setCurrent",
    }),
  },
  async mounted() {
    new AnchorInditor(() => {
      this.setCurrent("NAICS");
    }, this.$refs["title"]);
    try {
      this.loading = true
      const res = await axios({
        method: 'GET',
        url: '/search/Credit/EnterpriseLibrary/IndustryNace',
        params: {
          companyName: this.company,
          countryCode: this.$route.query.countryCode
        }
      })
      this.industryData = res.data.result
      if (this.industryData.length === 0) throw new Error()
      this.hidden = false
      this.setAnchorFlag([["NAICS", true]])
    } catch {
      null
    } finally {
      this.loading = false
    }
  },
});
</script>

<style lang="scss" scoped>
@import "@/firm/scss/mixin.scss";

.abroad-naics {
  background: #fff;
  padding: 1rem;
  padding-left: 0;
  padding-top: 0;
  @include mixin-panel-title;

  .content {
    display: flex;
    flex-wrap: wrap;

    .item {
      border-right: 5px solid #fff;
      box-sizing: border-box;
      border-bottom: 15px solid #fff;
      height: 80px;
      overflow: hidden;
      margin-left: 33px;
      margin-bottom: 33px;
      width: 250px;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
      border-radius: 4px;
      position: relative;

      .badge {
        font-size: 13px;
        position: absolute;
        top: 5px;
        left: 5px;
        color: var(--themeColor, #3152AF);
      }

      $paddingLeft: 2em;

      .title {
        height: auto;
        margin-left: $paddingLeft;
        margin-top: 12px;
        font-size: 13px;
        color: var(--themeColor, #3152AF);
      }

      .description {
        margin-left: $paddingLeft;
        font-size: 13px;
        margin-bottom: 12px;
        color: #909090;
      }
    }
  }
}
</style>
