<template>
  <!-- 国外企业，企业信息 -->
  <div v-loading="loading">
    <div id="enterprise-info-basic-info" class="enterprise-info-basic-info" v-show="!hidden">
      <div class="title" ref="title">
        <div style="display: flex; align-items: center">
          <p>企业信息</p>
          <!--   <span v-if="sourceCountry.length !== 0" style="color: #999; margin-left: 2em">修改数据源国家：</span>
          <el-select @change="handlesourceCountryChange" v-model="sourceCountryValue" v-if="sourceCountry.length !== 0" size="mini">
            <el-option v-for="item of sourceCountry" :key="item.twoCode" :label="item.countryCn" :value="[item.countryCn, item.twoCode]" />
          </el-select> -->
          <span v-if="false" @click="linkc.dialog = true" style="margin-left: 10px;color: var(--themeColor, #3152AF);cursor: pointer;font-size: small;">商业数据已购买的公司</span>
          <el-dialog custom-class="ergheoghro-23r32oiro2-ggj3i4" title="关联商业数据我已购买公司" width="70%" :close-on-click-modal="false" :visible.sync="linkc.dialog">
            <div v-loading="linkc.loading">
              <div v-if="linkc.permisson">
                <el-form size="mini" inline>
                  <el-form-item label="国家：">
                    <el-select v-model="linkc.optionc">
                      <el-option v-for="item of linkc.country" :key="item" :label="item" :value="item" />
                    </el-select>
                  </el-form-item>
                  <el-form-item label="公司名：">
                    <el-input v-model="linkc.otincn" />
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" @click="getLinkCDialogData({ index: 1 })">搜索</el-button>
                  </el-form-item>
                </el-form>
                <el-table :data="linkc.data" size="mini" height="45vh">
                  <el-table-column show-overflow-tooltip prop="companyName" label="公司名">
                  </el-table-column>
                  <el-table-column show-overflow-tooltip prop="country" label="国家" />
                  <el-table-column show-overflow-tooltip label="网址">
                    <template slot-scope="{row}">
                      <span style="cursor: pointer" @click="interceptLink(row.website)">{{ row.website }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column show-overflow-tooltip label="来源">
                    <template slot-scope="{row}">
                      <span v-if="row.type === 'InfobelInfo'">商业数据</span>
                      <span v-else>企业资信</span>
                    </template>
                  </el-table-column>
                  <el-table-column show-overflow-tooltip label="切换公司绑定的公司信息？">
                    <template slot-scope="{row}">
                      <el-button @click="refreshhhh(row.searchKey)" type="primary" size="mini">确定</el-button>
                    </template>
                  </el-table-column>
                </el-table>
                <el-pagination @current-change="getLinkCDialogData({})" background layout="prev, pager, next" :current-page.sync="linkc.page.index" :page-size="linkc.page.size" :total="linkc.total" />
              </div>
              <el-empty v-else description="无商业数据权限" />
            </div>
          </el-dialog>
        </div>
        <feedback position="企业信息" url="" />
        <!-- <div class="flex-container">
          <img class="icon" src="@/firm/images/company/icon-feedback.png" style="margin-left: 1em" />
          <span class="text-with-icon" style="color: var(--themeColor, #3152AF)">反馈</span>
        </div> -->
      </div>
      <div style="border: 1px solid #d7d7d8;">
        <el-row>
          <el-col :span="4">
            <div class="container pl border  ">公司名称</div>
          </el-col>
          <el-col :span="8">
            <div class="container border ">
              <div class="pl border l">{{ newinfo.companyName || '-' }}</div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="container border ">
              <div class="pl border l">国家</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="container border">
              <div class="pl border l">
                <div style="display: flex;align-items: center;">
                  <span>{{ newinfo.countryCn || '-' }}</span>
                  <img v-if="newinfo.nationalFlag" :src="newinfo.nationalFlag" style="height: 1em; margin-left: 0.5em;" />
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <div class="container pl border  t">详细地址</div>
          </el-col>
          <el-col :span="20">
            <div class="container border t ">
              <div class="pl border l">{{ newinfo.address || '-' }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <div class="container pl border t ">经度</div>
          </el-col>
          <el-col :span="8">
            <div class="container border t">
              <div class="pl border l">{{ newinfo.longitude || '-' }}</div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="container border t">
              <div class="pl border l">纬度</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="container border t ">
              <div class="pl border l">{{ newinfo.latitude || '-' }}</div>
            </div>
          </el-col>
        </el-row>
        <!-- <el-row>
          <el-col :span="4">
            <div class="container pl border  t">地理精度</div>
          </el-col>
          <el-col :span="20">
            <div class="container border t ">
              <div class="pl border l">{{ newinfo.geographicLongitude || '-' }}</div>
            </div>
          </el-col>
        </el-row> -->
        <el-row>
          <el-col :span="4">
            <div class="container pl border t ">公司类型</div>
          </el-col>
          <el-col :span="8">
            <div class="container border t">
              <div class="pl border l">{{ newinfo.companyType || '-' }}</div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="container border t">
              <div class="pl border l">状态</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="container border t ">
              <div class="pl border l">{{ newinfo.status || '-' }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <div class="container pl border t ">法人</div>
          </el-col>
          <el-col :span="8">
            <div class="container border t">
              <div class="pl border l">{{ newinfo.legalPerson || '-' }}</div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="container border t">
              <div class="pl border l">家庭成员数量</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="container border t ">
              <div class="pl border l">{{ newinfo.familyNumber || '-' }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <div class="container pl border t ">注册时间</div>
          </el-col>
          <el-col :span="8">
            <div class="container border t">
              <div class="pl border l">{{ newinfo.registrationDate || '-' }}</div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="container border t">
              <div class="pl border l">注册号</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="container border t ">
              <div class="pl border l">{{ newinfo.registrationNumber || '-' }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <div class="container pl border  t">经营范围</div>
          </el-col>
          <el-col :span="20">
            <div class="container border t ">
              <div class="pl border l">{{ newinfo.businessline || '-' }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <div class="container pl border  t">简介</div>
          </el-col>
          <el-col :span="20">
            <div class="container border t ">
              <div class="pl border l">{{ newinfo.companyProfile || '-' }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <div class="container pl border  t">电话</div>
          </el-col>
          <el-col :span="20">
            <div class="container border t ">
              <div class="pl border l">{{ newinfo.phone || '-' }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <div class="container pl border t ">传真</div>
          </el-col>
          <el-col :span="8">
            <div class="container border t">
              <div class="pl border l">{{ newinfo.faxNumber || '-' }}</div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="container border t">
              <div class="pl border l">网址</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="container border t ">
              <div class="pl border l">{{ newinfo.website || '-' }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <div class="container pl border t  ">年收入</div>
          </el-col>
          <el-col :span="8">
            <div class="container border t ">
              <div class="pl border l">{{ newinfo.reverueAmount || '-' }}</div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="container border t ">
              <div class="pl border l">员工人数</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="container border t  ">
              <div class="pl border l">{{ newinfo.employeesNumber || '-' }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <div class="container pl border  t ">股权信息</div>
          </el-col>
          <el-col :span="20">
            <div class="container border t  ">
              <div class="pl border l" style="width: 100%;">
                <template v-if="newinfo2.length">
                  <el-table :data="newinfo2" size="mini">
                    <el-table-column prop="shareHoldersName" label="股东" />
                    <el-table-column prop="shareHoldersDirect" label="占比(%)" />
                  </el-table>
                </template>
                <div v-else>-</div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- <div>
        <el-row>
          <el-col :span="4">
            <div class="container pl border t l">公司名</div>
          </el-col>
          <el-col :span="8">
            <div class="container border t">
              <div class="pl border l">{{ information.companyName }}</div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="container border t">
              <div class="pl border l">国家</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="container border t r">
              <div class="pl border l">{{ information.country }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <div class="container pl border t l">语言</div>
          </el-col>
          <el-col :span="8">
            <div class="container border t">
              <div class="pl border l">{{ information.language }}</div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="container border t">
              <div class="pl border l">行业状态</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="container border t r">
              <div class="pl border l">{{ information.industryStatus }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <div class="container pl border t l">注册时间</div>
          </el-col>
          <el-col :span="8">
            <div class="container border t">
              <div class="pl border l">{{ information.registrationTime }}</div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="container border t">
              <div class="pl border l">联系电话</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="container border t r">
              <div class="pl border l">{{ information.contactPhone }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <div class="container pl border t l">传真</div>
          </el-col>
          <el-col :span="8">
            <div class="container border t">
              <div class="pl border l">{{ information.fax }}</div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="container border t">
              <div class="pl border l">邮编</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="container border t r">
              <div class="pl border l">{{ information.zip }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <div class="container pl border l t">地址</div>
          </el-col>
          <el-col :span="20">
            <div class="container border t r">
              <div class="pl border l">{{ information.address }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <div class="container pl border t l">所属洲</div>
          </el-col>
          <el-col :span="8">
            <div class="container border t">
              <div class="pl border l">{{ information.state }}</div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="container border t">
            </div>
          </el-col>
          <el-col :span="8">
            <div class="container border t r">
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <div class="container pl border t l">法定代表人</div>
          </el-col>
          <el-col :span="8">
            <div class="container border t">
              <div class="pl border l">{{ information.juridicalPerson }}</div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="container border t">
            </div>
          </el-col>
          <el-col :span="8">
            <div class="container border t r">
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <div class="container pl border l t">注册地址</div>
          </el-col>
          <el-col :span="20">
            <div class="container border t r">
              <div class="pl border l">{{ information.registrationAddress }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <div class="container pl border l t">实体业务运营的描述</div>
          </el-col>
          <el-col :span="20">
            <div class="container border t r">
              <el-tooltip :content="information.businessDescription" placement="top" effect="light">
                <div class="pl border l signle-line-omit">{{ information.businessDescription }}</div>
              </el-tooltip>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <div class="container pl border t l">经度,纬度</div>
          </el-col>
          <el-col :span="8">
            <div class="container border t">
              <div class="pl border l">{{ information.latitudeAndLongitude }}</div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="container border t">
              <div class="pl border l">地理精度</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="container border t r">
              <div class="pl border l">{{ information.geographicalAccuracy }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <div class="container pl border l t">统计区cbsa名</div>
          </el-col>
          <el-col :span="20">
            <div class="container border t r">
              <div class="pl border l">{{ information.cbsaName }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <div class="container pl border t l">统计区cbsa码</div>
          </el-col>
          <el-col :span="8">
            <div class="container border t">
              <div class="pl border l">{{ information.cbsaCode }}</div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="container border t">
              <div class="pl border l">人口排名等级</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="container border t r">
              <div class="pl border l">{{ information.populationRanking }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <div class="container pl border l t">企业持续经营的描述</div>
          </el-col>
          <el-col :span="20">
            <div class="container border t r">
              <div class="pl border l">{{ information.description }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <div class="container pl border t l">特许经营类型</div>
          </el-col>
          <el-col :span="8">
            <div class="container border t">
              <div class="pl border l">{{ information.franchiseType }}</div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="container border t">
              <div class="pl border l">默认使用货币</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="container border t r">
              <div class="pl border l">{{ information.currency }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <div class="container pl border l t b">年收入</div>
          </el-col>
          <el-col :span="20">
            <div class="container border t r b">
              <div class="pl border l">{{ information.annualIncome }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
            <div class="container pl border l b">在职员工(人数+信息范围)</div>
          </el-col>
          <el-col :span="20">
            <div class="container border r b">
              <div class="pl border l">
                <p style="display: inline; margin-right: 1.5em" v-for="(item, index) of information.employeesNumArray" :key="index">
                  <span>{{ item.value }}</span>
                  <span>（{{ item.informationScopeDescription }}）</span>
                </p>
              </div>
            </div>
          </el-col>
        </el-row>
      </div> -->
    </div>
    <abroad-social v-if="showSocial" />
    <aborad-souyu v-if="false" />
    <aborad-address :location="newinfoAddress" :location-address="newinfo.address" v-if="ready" />
    <aborad-finance />
    <aborad-contact />
    <aborad-contact-duns />
    <aborad-employee :currentPrincipals="currentPrincipals" />
    <!-- <aborad-industry /> -->
    <!-- <aborad-sitc /> -->
    <aborad-ussic />
    <aborad-naics />
    <aborad-industry />
    <aborad-user-name :formerPrimaryNames="formerPrimaryNames" />
    <aborad-summary :summary="summary" />
    <aborad-employee-history :numberOfEmployeesHistory="numberOfEmployeesHistory" />
    <aborad-bank :banks="banks" />
    <aborad-competitor :competitors="competitors" />
    <aborad-captical :capitalDetails="capitalDetails" />
    <aborad-other :other="other" />
  </div>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import axios from "@/firm/plugins/axios";
import abroadSocial from "./abroad/abroad-social-new.vue";
import aboradSouyu from "./abroad/abroad-souyu.vue";
import aboradAddress from "./abroad/abroad-address.vue";
import aboradFinance from "./abroad/abroad-finance.vue";
import aboradContact from "./abroad/abroad-contact.vue";
import aboradContactDuns from "./abroad/abroad-contact-duns.vue";
import aboradEmployee from "./abroad/abroad-employee.vue";
import aboradIndustry from "./abroad/abroad-industry.vue";
//import aboradSitc from "./abroad/abroad-sitc.vue";
import aboradUssic from "./abroad/abroad-ussic.vue";
import aboradNaics from "./abroad/abroad-naics.vue";
import aboradUserName from "./abroad/abroad-used-name.vue";
import aboradSummary from "./abroad/abroad-summary.vue";
import aboradEmployeeHistory from "./abroad/abroad-employee-history.vue";
import aboradBank from "./abroad/abroad-bank.vue";
import aboradCompetitor from "./abroad/abroad-competitor.vue";
import aboradCaptical from "./abroad/abroad-captical.vue";
import aboradOther from "./abroad/abroad-other.vue";
import { mapMutations } from "vuex";
import { AnchorInditor } from "../../utils";
//import { Message } from "@/firm/plugins/element";
import { interceptLink } from "@/firm/utils/link";

export default Vue.extend({
  props: ["company", "MPsetInformation"],
  components: {
    abroadSocial,
    aboradSouyu,
    aboradAddress,
    aboradFinance,
    aboradContact,
    aboradContactDuns,
    aboradEmployee,
    aboradIndustry,
    //aboradSitc,
    aboradUssic,
    aboradNaics,
    aboradUserName,
    aboradSummary,
    aboradEmployeeHistory,
    aboradBank,
    aboradCompetitor,
    aboradCaptical,
    aboradOther,
  },
  data() {
    return {
      //[newinfo.latitude,newinfo.longitude]
      newinfoAddress: [],
      linkc: {
        loading: false,
        dialog: false,
        optionc: '不限',
        otincn: '',
        country: [],
        data: [],
        total: 0,
        page: {
          index: 1,
          size: 10
        },
        permisson: false
      },
      showSocial: true, //挂载 社媒信息推荐
      ready: false, //地图组件开始加载
      hidden: false,
      loading: false,
      information: {
        companyName: "", //公司名
        country: "", //国家
        language: "", //语言
        industryStatus: "", //行业状态
        registrationTime: "", //注册时间
        contactPhone: "", //联系电话
        fax: "", //传真
        zip: "", //邮编
        address: "", //地址
        state: "", //所属洲
        netAddress: "", //官网
        juridicalPerson: "", //法人
        dateBusinessEstablished: "", //注册业务成立日期
        registrationAddress: "", //注册地址
        businessDescription: "", //实体业务运营的描述
        latitudeAndLongitude: "", //经度,纬度
        geographicalAccuracy: "", //地理精度
        cbsaName: "", //统计区cbsa名
        cbsaCode: "", //统计区cbsa码
        populationRanking: "", //人口排名等级
        description: "", //企业持续经营的描述
        franchiseType: "", //特许经营类型
        employeesNumArray: [],
        employeesNum: "", //在职员工数
        informationDescription: "", //信息范围说明
        currency: "", //默认使用货币
        annualIncome: "", //年收入
      },
      thirdPartyFinancials: null,
      currentPrincipals: null,
      industryCodes: [],
      naics: [],
      formerPrimaryNames: [],
      summary: [],
      numberOfEmployeesHistory: [],
      banks: [],
      competitors: [],
      capitalDetails: [],
      other: {
        statue: "", //公司状态
        year: "", //成立年份
        count: "", //员工数
        web: "", //官网
        country: "", //国家
        area: "", //地区、洲
        city: "", //城市
        address: "", //地址
        zip: "", //邮编
        product: "", //产品
        contact: "", //联系人
        position: "", //职位
      },
      locationAddress: "",
      sourceCountry: [],
      sourceCountryValue: [],
      newinfo: {},
      newinfo2: []
    };
  },
  computed: {
    ...mapState(["experiemntRender", "done"]),
  },
  methods: {
    ...mapMutations(["setWebsite"]),
    ...mapMutations("anchor", {
      setAnchorFlag: "setAnchorFlag",
      setCurrent: "setCurrent",
    }),
    interceptLink,
    refreshhhh(keyword) {
      this.linkc.loading = true
      axios({
        method: 'GET',
        url: '/search/Credit/SwitchCompanyInfo',
        params: { keyword }
      }).then((res => {
        //  console.log(res)
        this.setInformation(res.data.result);
        this.linkc.dialog = false

        window.location.reload()
        /* this.linkc.otincn=''
        this.linkc.optionc='不限'
        this.getLinkCDialogData({index:1}) */
      })).finally(() => {
        this.linkc.loading = false
      })
    },
    getLinkCDialogData(option) {
      null
      /* this.linkc.loading = true
      axios({
        method: 'POST',
        url: '/search/Credit/GetMineBusinessCompany',
        data: {
          "pageIndex": option.index || this.linkc.page.index,
          "pageSize": this.linkc.page.size,
          "companyName": this.linkc.otincn,
          "country": this.linkc.optionc
        }
      }).then(res => {
        console.log({ flag: res.data.result })
        const r = res.data.result
        this.linkc.permisson = r.businessDataModels
        this.linkc.country = r.countrys || []
        this.linkc.data = r.data
        this.linkc.total = r.totalItems
        option.index && (this.linkc.page.index = option.index)
      }).finally(() => {
        this.linkc.loading = false
      }) */
    },
    navToBroswer() {
      interceptLink(this.information.netAddress);
    },
    setInformation(data) {
      console.log({
        name: "标识",
        data: data.organization,
      });
      this.information = {
        companyName: data.organization?.primaryName || "-",
        country: data.result.data.country || "-", //"United States", //国家
        language: data.result.data.language || "-", //"English", //语言
        industryStatus: data.result.data.operatingStatus || "-", //"Active", //行业状态
        registrationTime: data.organization.incorporatedDate || data.organization.controlOwnershipDate || '-',// data.organization.controlOwnershipDate || "-", //"1977", //注册时间
        contactPhone: data.result.data.phoneNumber || "-", //"4089961010", //联系电话
        fax: data.result.data.faxNumber || "-", //传真
        zip: data.result.data.postalCode || "-", //邮编
        address: data.result.data.address || "-", //地址
        state: data.result.data.continentalRegion || "-", //所属洲
        netAddress: data.result.data.website || "", //官网
        juridicalPerson: data.organization.mostSeniorPrincipals[0]?.fullName || "-", //"Timothy D Cook", //法人
        dateBusinessEstablished: data.organization.incorporatedDate || "-", //"2010-03-03", //注册业务成立日期
        registrationAddress: data.organization.legalForm ? data.organization.legalForm.registrationLocation?.addressRegion || "-" : "-", //"2950 N Madera Rd Simi Valley, CA 93065-6230, USA 2950 N Madera Rd", //注册地址
        businessDescription: data.organization.operations ? data.organization.operations[0]?.description || "-" : "-", //"-", //实体业务运营的描述
        latitudeAndLongitude: `${data.organization.primaryAddress.longitude || "-"}，${data.organization.primaryAddress.latitude || "-"}`, //"-122.0124，39.9075", //经度,纬度
        geographicalAccuracy: data.organization.primaryAddress.geographicalPrecision?.description || "-", //"Town Centroid", //地理精度
        cbsaName: data.organization.primaryAddress.statisticalArea.cbsaName || "-", //"San Jose-Sunnyvale-Santa Clara CA", //统计区cbsa名
        cbsaCode: data.organization.primaryAddress.statisticalArea.cbsaCode || "-", //"41940", //统计区cbsa码
        populationRanking: data.organization.primaryAddress.statisticalArea.populationRank?.rankNumber || "-", //"9", //人口排名等级
        description: data.organization.activities ? data.organization.activities[0]?.description || "-" : "-", //"-", //企业持续经营的描述
        franchiseType: data.organization.franchiseOperationType ? data.organization.franchiseOperationType.description || "-" : "-", //"Franchisee owned", //特许经营类型
        employeesNumArray: data.organization.numberOfEmployees || [],
        employeesNum: data.organization.numberOfEmployees[0]?.value || "-", //"2000", //在职员工数
        informationDescription: data.organization.numberOfEmployees[0]?.informationScopeDescription || "-", //"Headquarters Only (Employs Here)", //信息范围说明
        currency: data.organization.defaultCurrency || "-", //"USD", //默认使用货币
        annualIncome: data.organization.financials[0]?.yearlyRevenue[0]?.value || "-", //"274515000000 USD", //年收入
      };
    },
    /*  async handlesourceCountryChange(value: any) {
      try {
        this.loading = true;
        await axios({
          method: "GET",
          url: "/search/Credit/BusinessDataIDR",
          params: {
            keyword: value[1],
          },
        });
        window.location.reload();
      } catch {
        Message.error("修改数据源国家失败");
      } finally {
        this.loading = false;
      }
    }, */
  },
  async mounted() {
    try {
      this.getLinkCDialogData({})
      //this.loading = true;

      const result = await axios({
        method: "get",
        url: "/search/Credit/Contact",
        params: {
          keyword: this.company,
        },
      });
      const url1 = result.data.result?.url;
      let urlFlag = true;
      if (url1) {
        console.log(url1);
        this.MPsetInformation(["netaddress", url1]);
        //this.setWebsite(url1);
        this.showSocial = true;

        urlFlag = false;
      }

      const res = null
      /* const res = await axios({
        method: "get",
        url: "/search/Credit/CompanyProfile",
        params: {
          keyword: this.company,
          countryCode: this.$route.query.countryCode
        },
      }); */
      try {
        res.data.result.result;
      } catch {
        //this.hidden = true;
        null
      }
      const data = res.data.result;
      //console.log(data);
      if (!data.result) {
        //this.setAnchorFlag([["QYXX", false]]);
        for (const key in this.information) {
          (this.information)[key] = "-";
        }
        //this.hidden = true;
        throw new Error();
      }
      //this.setAnchorFlag([["QYXX", true]]);
      /* console.log({
        name: "转换后",
        data,
      }); */
      this.setInformation(data);
      if (urlFlag) {
        this.MPsetInformation(["netaddress", this.information.netAddress]);
        //this.setWebsite(this.information.netAddress);
        this.showSocial = true;
      }
      this.thirdPartyFinancials = data.organization.thirdPartyFinancials;
      this.currentPrincipals = data.organization.currentPrincipals;
      this.industryCodes = data.industryCodes || [];
      this.formerPrimaryNames = data.organization.formerPrimaryNames || [];
      this.summary = data.organization.summary || [];
      this.naics = data.naics ?? [];
      this.numberOfEmployeesHistory = data.organization.numberOfEmployeesHistory || [];
      this.banks = data.organization.banks || [];
      this.competitors = data.organization.competitors || [];
      this.capitalDetails = data.organization.capitalDetails || [];
      this.other = {
        statue: "", //公司状态
        year: "", //成立年份
        count: "", //员工数
        web: data.result.data.website || "", //"", //官网
        country: data.result.data.country || "", //"", //国家
        area: "", //地区、洲
        city: "", //城市
        address: data.result.data.address || "", //"", //地址
        zip: data.result.data.postalCode || "", //"", //邮编
        product: "", //产品
        contact: "", //联系人
        position: "", //职位
      };
      this.locationAddress = data.organization.primaryAddress.streetName && data.organization.primaryAddress.addressCountry.name ? `(${data.organization.primaryAddress.streetName}, ${data.organization.primaryAddress.addressCountry.name})` : "";
      /* axios({
        method: 'GET',
        url: '/search/Credit/GetUrl'
      }).then(res => {
        if (!this.information.netAddress && res.data.result && res.data.result.length) {
          this.information.netAddress = res.data.result[0]
          this.MPsetInformation(["netaddress", this.information.netAddress]);
        }
      }) */
    } catch (err) {
      console.log(err);
    } finally {
      // this.loading = false;
      //this.ready = true;
      null
    }
    new AnchorInditor(() => {
      this.setCurrent("QYXX");
    }, this.$refs["title"]);

    this.loading = true
    axios({
      method: 'GET',
      url: '/search/Credit/EnterpriseLibraryInfo',
      params: {
        companyName: this.company,
        countryCode: this.$route.query.countryCode
      }
    }).then(res => {
      try {
        //console.log({ asdfghjkl: res })
        console.log({ '新企业信息': res.data.result })
        if (res.data.result) {
          this.newinfo = res.data.result
          this.newinfoAddress = [this.newinfo.latitude, this.newinfo.longitude]
          this.ready = true;
          this.setAnchorFlag([["QYXX", true]]);
          this.hidden = false
        } else {
          throw new Error()
        }
      } catch {
        this.newinfo = {}
        this.setAnchorFlag([["QYXX", false]]);
        this.hidden = true
      }
    }).finally(() => {
      this.loading = false;
    })
    axios({
      method: 'GET',
      url: '/search/Credit/EnterpriseLibrary/Shareholders',
      params: {
        companyName: this.company,
        countryCode: this.$route.query.countryCode
      }
    }).then(res => {
      this.newinfo2 = res.data.result || []
    })


    //---------------------------------------------
    /* axios({
      method: "get",
      url: "/search/Credit/IDRDataCountry",
    }).then((res) => {
      // [ "美国", "US" ], [ "中国", "CN" ]
      if (res.data.result && res.data.result.length !== 0) {
        this.sourceCountry = res.data.result;
        console.log(res.data.result);
      }
    }); */
  },
});
</script>

<style lang="scss">
.ergheoghro-23r32oiro2-ggj3i4 {
  .el-dialog__body {
    padding-top: 5px;
  }
}
</style>

<!-- 与 basic-info-domestic 共用样式 -->
