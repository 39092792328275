<template>
    <div v-loading="loading" class="module">
        <div class="updateBtn">
            <el-link icon="el-icon-refresh" :underline="false" type="primary" @click="updateJobs">更新招聘信息</el-link>
        </div>
        <el-empty v-if="jobsList.length == 0" description="暂无招聘信息"></el-empty>
        <div class="content" v-else>
            <div class="itemCard" v-for="(item,index) in jobsList" :key="index" @click="openJobs(item.link)">
                <div class="title">{{item.title}}</div>
                <div class="companyName">{{item.companyName}}</div>
                <div class="location">{{item.location}}</div>
                <div class="via">{{item.via}}</div>
                <div class="extensions">{{item.extensions}}</div>
                <img class="tj" src="@/assets/ai/tj.png" alt="" />
            </div>
        </div>
        
    </div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            companyName: "",
            jobsList: [],
        };
    },
    methods: {
        //获取招聘信息
        getJobs(){
            this.loading = true;
            this.$axios.get(`${this.$clueUrl}/Recommendation/GetRECJobs?companyName=${this.companyName}`).then((res) => {
                const { isSuccess, data } = res;
                this.loading = false;
                if (isSuccess) {
                    this.jobsList = data;
                }
            });
        },
        //更新招聘信息
        updateJobs(){
            this.loading = true;
            this.$axios.get(`${this.$clueUrl}/Recommendation/UpdateRECJobs?companyName=${this.companyName}`).then((res) => {
                const { isSuccess, data } = res;
                this.loading = false;
                if (isSuccess) {
                    this.jobsList = data;
                }
            });
        },
        //打开招聘链接
        openJobs(link){
            window.open(link)
        },

    },
    mounted() {
        this.companyName = encodeURIComponent(this.$route.query.companyName);
        this.getJobs();
    },
};
</script>

<style lang="scss" scoped>
@import '@/scss/common.scss';
.module{
    position: relative;
    .updateBtn{
        position: absolute;
        right: 10px;
        top: 0;
    }
}
.content{
    width: 600px;
    border: 1px solid #c9c9c9;
}

.itemCard{
    width: 600px;
    overflow: auto;
    cursor: pointer;
    border-bottom: 1px solid #c9c9c9;
    padding: 10px 20px;
    box-sizing: border-box;
    position: relative;
    .title{
        color: $themeColor;
        font-size: 20px;
        line-height: 24px;
    }
    .companyName{
        color: #000;
        font-size: 14px;
        line-height: 20px;
    }
    .location,.via{
        color: #70757a;
        font-size: 14px;
        line-height: 20px;
    }
    .extensions{
        color: #70757a;
        font-size: 14px;
        line-height: 20px;
    }
    .tj{
        position: absolute;
        right: 10px;
        top: 10px;
        width: 20px;
    }
    &:hover .title{
        text-decoration: underline;
    }
    &:hover{
        background: #fafafa;
    }
}   
.itemCard:last-child{
    border-bottom: none;
}
</style>