<template>
<div>
    <div v-if="ie=='i'">
        <!-- 危地马拉(GTM)进口提单明细 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th width="25%">提单号</th>
                        <td width="25%">{{billInfo.BlNumber}}</td>
                        <th width="25%">进口类型</th>
                        <td width="25%">{{billInfo.ImpType}}</td>
                    </tr>
                    <tr>
                        <th>数量单位</th>
                        <td>{{billInfo.Unit}}</td>
                        <th>净重</th>
                        <td>{{billInfo.NetWeight}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">运输信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">来源国</th>
                        <td width="25%">{{billInfo.Country}}</td>
                        <th width="25%">中转国</th>
                        <td width="25%">{{billInfo.TransitCountry}}</td>
                    </tr>
                    <tr>
                        <th>目的国</th>
                        <td>{{billInfo.DestinationCountry}}</td>
                        <th>起运港</th>
                        <td>{{billInfo.LoadingPort}}</td>
                    </tr>
                    <tr>
                        <th>卸货港</th>
                        <td>{{billInfo.LandingPort}}</td>
                        <th>船名</th>
                        <td>{{billInfo.Ship}}</td>
                    </tr>
                    <tr>
                        <th>航次号</th>
                        <td>{{billInfo.VoyageNumber}}</td>
                        <th>船运公司</th>
                        <td>{{billInfo.ShipCompany}}</td>
                    </tr>
                    <tr>
                        <th>包装类型</th>
                        <td>{{billInfo.Package}}</td>
                        <th>集装箱号</th>
                        <td>{{billInfo.ContainerNum}}</td>
                    </tr>
                    <tr>
                        <th>货柜尺寸</th>
                        <td>{{billInfo.Size}}</td>
                        <th>货物状态</th>
                        <td>{{billInfo.Status}}</td>
                    </tr>
                    <tr>
                        <th>货源国</th>
                        <td colspan="3">{{billInfo.ProvenanceCountry}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
    <div v-if="ie=='e'">
        <!-- 危地马拉(GTM)出口提单明细 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th width="25%">提单号</th>
                        <td width="25%">{{billInfo.BlNumber}}</td>
                        <th width="25%">出口类型</th>
                        <td width="25%">{{billInfo.ExpType}}</td>
                    </tr>
                    <tr>
                        <th>数量单位</th>
                        <td>{{billInfo.Unit}}</td>
                        <th>净重</th>
                        <td>{{billInfo.NetWeight}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">运输信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">来源国</th>
                        <td width="25%">{{billInfo.OriginCountry}}</td>
                        <th width="25%">中转国</th>
                        <td width="25%">{{billInfo.TransitCountry}}</td>
                    </tr>
                    <tr>
                        <th>目的国</th>
                        <td>{{billInfo.Country}}</td>
                        <th>起运港</th>
                        <td>{{billInfo.LoadingPort}}</td>
                    </tr>
                    <tr>
                        <th>卸货港</th>
                        <td>{{billInfo.LandingPort}}</td>
                        <th>船名</th>
                        <td>{{billInfo.Ship}}</td>
                    </tr>
                    <tr>
                        <th>航次号</th>
                        <td>{{billInfo.VoyageNumber}}</td>
                        <th>船运公司</th>
                        <td>{{billInfo.ShipCompany}}</td>
                    </tr>
                    <tr>
                        <th>集装箱号</th>
                        <td>{{billInfo.ContainerNum}}</td>
                        <th>货柜尺寸</th>
                        <td>{{billInfo.Size}}</td>
                    </tr>
                    <tr>
                        <th>包装类型</th>
                        <td>{{billInfo.Package}}</td>
                        <th>货物状态</th>
                        <td>{{billInfo.Status}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
</div>
</template>

<script>
export default {
    props:["billInfo","ie"],
    data() {
        return {

        };
    },
    methods: {
        
    },
    mounted() {

    },
};
</script>

<style lang="scss" scoped>
.partItem {
    margin-bottom: 20px;
    /deep/.el-card__header {
        padding: 10px 20px;
        .headTitle {
            display: inline-block;
            padding-left: 10px;
            font-size: 14px;
            border-left: 2px solid var(--themeColor);
            font-weight: bold;
        }
    }
}
table {
    width: 100%;
    border-collapse: collapse;
    tr {
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;
        th,
        td {
            font-size: 12px;
            color: #666;
            height: 40px;
            border: 1px solid #eaeaea;
            text-align: center;
        }
        th {
            background-color: #f5f7fa;
            font-weight: 700;
        }
        .bg {
            background-color: var(--themeColor);
            color: #fff;
            font-weight: 700;
            opacity: 0.8;
        }
    }
}
</style>