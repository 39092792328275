<template>
    <div>
        <InterfaceName :interfaceName="interfaceName" />
        <InterfaceSpe :requestUrl="requestUrl" :requestMethod="requestMethod" />
        <InputParam :inputTableData="inputTableData" />
        <ReturnParam :returnObj="returnObj" />
    </div>
</template>

<script>
import InterfaceName from "../Acomponents/InterfaceName.vue";
import InterfaceSpe from "../Acomponents/InterfaceSpe.vue";
import InputParam from "../Acomponents/InputParam.vue";
import ReturnParam from "../Acomponents/ReturnParam.vue";

export default {
    components: { InterfaceName, InterfaceSpe, InputParam, ReturnParam },
    data() {
        return {
            interfaceName: "获取登陆日志",
            requestUrl: "https://crm.qixinerp.com/open/Staff/GetLoginLog",
            requestMethod: "POST",
            inputTableData: [],
            returnObj: {},
        };
    },
    methods: {},
    mounted() {
        this.inputTableData = [
            {
                paramName: "startTime",
                valueType: "string",
                explain: "开始时间",
                required: false,
            },
            {
                paramName: "endTime",
                valueType: "string",
                explain: "结束时间",
                required: false,
            },
            {
                paramName: "account",
                valueType: "string",
                explain: "账号",
                required: false,
            },
            {
                paramName: "pageIndex",
                valueType: "integer",
                explain: "当前页码",
                required: true,
            },
            {
                paramName: "pageSize",
                valueType: "integer",
                explain: "每页条数",
                required: true,
            },
        ];
        this.returnObj = {
            isSuccess: true,
            errorMessage: null,
            data: {
                datas: [  
                    {
                        loginTime: "2022-05-27 18:34:40",
                        loginIp: "27.19.155.113",
                        location: "湖北省武汉市",
                        loginType: "账户密码登录",
                        account: "pxx.peng@lsmaps.com",
                        loginStatus: "登录成功",
                    },
                    {
                        loginTime: "2022-05-27 18:13:11",
                        loginIp: "113.57.105.149",
                        location: "湖北省武汉市",
                        loginType: "账户密码登录",
                        account: "yu.gao@lsmaps.com",
                        loginStatus: "登录成功",
                    },
                ],
                pageSize: 10,
                pageIndex: 1,
                totalItems: 2,
            },
        };
    },
};
</script>

<style lang="scss" scoped>

</style>