<template>
    <el-dialog title="添加标签" :visible.sync="addTagVisible" width="500px" :show-close="false">
        <!-- 选择标签配置 -->
        <el-form v-show="addType == 'choose'" label-width="100px" label-suffix=":">
            <el-button size="small" @click="addType = 'write'" style="margin-bottom: 10px;">自定义标签</el-button>
            <el-form-item label="选择标签">
                <el-select v-model="lableKeys" multiple placeholder="请选择标签" style="width: 100%" size="small">
                    <el-option v-for="item in tagOption" :disabled="item.disabled" :key="item.key" :label="item.text" :value="item.key">
                        <div style="display: flex; justify-content: space-between; align-items: center">
                            <div>
                                <span :style="{'background': item.color || '#e6a23c','width': '1em','height': '1em','display': 'inline-block'}"></span>
                                <span style="margin-left: 10px">{{ item.text }}</span>
                            </div>
                            <div style="color: #999;">已标记 {{ item.markCount }} 个</div>
                        </div>
                    </el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <!-- 自定义标签 -->
        <el-form v-show="addType == 'write'" label-width="100px" label-suffix=":">
            <el-button size="small" @click="addType = 'choose'" style="margin-bottom: 10px;">选择标签配置</el-button>
            <el-form-item label="标签名称">
                <el-input v-model.trim="labelName" size="small" placeholder="标签名称"></el-input>
            </el-form-item>
            <el-form-item label="标签颜色">
                <div style="display: flex; align-items: center; height: 40px;">
                    <div class="colorItem" v-for="item of predefineColors" :key="item" @click="labelColor = item"
                     :style="{ 'background' : item , 'boxShadow': item === labelColor ? '0 0 3px 2px #40a9ff' : 'none'}"></div>
                </div>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="$emit('onClose')" size="small">关 闭</el-button>
            <el-button @click="onSubmit" :loading="btnLoading" :disabled="isForbid" size="small" type="primary">确 认</el-button>
        </span>
    </el-dialog>
</template>

<script>
export default {
    props:["addTagVisible","signFileKey","oldLables"],
    data() {
        return {
            addType: "choose",  //choose or write
            lableKeys: [],
            labelName: "",
            labelColor: "#EA9426",
            btnLoading: false,
            predefineColors: ["#EA9426","#f759ab","#9254de","#597ef7","#40a9ff","#36cfc9","#73d13d","#ffec3d","#ff4d4f","#ff7a45"],
            tagOption: [],
        };
    },
    computed:{
        isForbid(){
            return !this.labelName && this.lableKeys.length == 0;
        }
    },
    methods: {
        onSubmit(){
            this.btnLoading = true;
            let dataList = [];
            this.signFileKey.forEach(ele=>{
                dataList.push({
                    "dataKey": ele,
                    "type": 0,
                })
            })
            this.$axios.post(`${this.$diskUrl}/Lable/BatchSaveFileOrFolderLable`,{
                "datas": dataList,
                "lableKeys": this.lableKeys,
                "text": this.labelName,
                "color": this.labelColor,
            }).then((res) => {
                const { isSuccess } = res;
                this.btnLoading = false;
                if (isSuccess) {
                    this.$emit('onSubmit');
                }
            });
        },

        //获取标签配置
        getTagConfig(){
            this.$axios.get(`${this.$diskUrl}/Lable/GetLableConfig`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    const set = new Set();
                    this.oldLables.forEach((item) => {
                        set.add(item.lableKey);
                    });
                    this.tagOption = data.map((item) => ({
                        ...item,
                        disabled: set.has(item.key),
                    }));
                    // console.log(this.tagOption);
                }
            })
        },

    },
    mounted() {

    },
    watch:{
        addTagVisible(value){
            if(value){
                this.addType = "choose";
                this.lableKeys = [];
                this.labelName = "";
                this.labelColor = "#EA9426";
                this.getTagConfig();
            }
        }
    }
};
</script>

<style lang='scss' scoped>
.colorItem{
    display: inline-block;
    width: 24px;
    height: 24px;
    cursor: pointer;
    margin-right: 10px;
}
</style>