<script lang="js">
import { defineComponent, ref, watch } from '@vue/composition-api'
import mapboxgl from 'mapbox-gl'
export default defineComponent({
  model: {
    prop: 'visible',
    event: 'update:visible'
  },
  props: {
    visible: Boolean
  },
  emits: ['confirm'],
  setup(props, ctx) {
    const { emit } = ctx
    const mapDom = ref(null)
    const MapSingleton = (() => {
      let instance = null
      const constructor = () => {
        instance = new mapboxgl.Map({
          container: mapDom.value,
          style: 'mapbox://styles/mapbox/streets-v12',
          center: region.value.lngLat
        })
        instance.on('load', () => {
          const marker = MarkerSingleton.getInstance()
          marker.setLngLat(region.value.lngLat)
          marker.addTo(instance)
        })
      }
      return {
        getInstance: () => {
          if (instance === null) {
            constructor()
          }
          return instance
        }
      }
    })()
    const MarkerSingleton = (() => {
      let instance = null
      const constructor = () => {
        instance = new mapboxgl.Marker({
          color: 'var(--themeColor)'
        })
      }
      return {
        getInstance: () => {
          if (instance === null) {
            constructor()
          }
          return instance
        }
      }
    })()
    const initMap = () => {
      MarkerSingleton.getInstance()
      MapSingleton.getInstance()
    }
    const options = [{
      label: '美国[华盛顿]',
      flag: '',
      lngLat: [-77.01, 38.91]
    }, {
      label: '美国[洛杉矶]',
      flag: '',
      lngLat: [-118.15, 34.01]
    }, {
      label: '英国[伦敦]',
      flag: '',
      lngLat: [0.15, 51.30]
    }, {
      label: '日本[东京]',
      flag: '',
      lngLat: [139.44, 35.41]
    }, {
      label: '中国[北京]',
      flag: '',
      lngLat: [116, 40]
    }, {
      label: '俄罗斯[莫斯科]',
      flag: '',
      lngLat: [37.5, 55.5]
    }, {
      label: '俄罗斯[新西伯利亚]',
      flag: '',
      lngLat: [82.55, 55.2]
    }, {
      label: '澳大利亚[悉尼]',
      flag: '',
      lngLat: [150.53, -33.55]
    }]
    const region = ref(options[0])
    watch(region, value => {
      const marker = MarkerSingleton.getInstance()
      marker.setLngLat(value.lngLat)
      const map = MapSingleton.getInstance()
      map.setCenter(value.lngLat)
      timeZone.value = calculateTimezoneByLngLat(value.lngLat)
    })
    const calculateTimezoneByLngLat = (lngLat) => {
      const longitude = lngLat[0]
      if (longitude < -180 || longitude > 180) {
        throw new Error('经度错误，合法取值范围[-180, 180]')
      }
      if (-7.5 < longitude && longitude <= 7.5) {
        return '中时区'
      } else if (7.5 < longitude && longitude <= 22.5) {
        return '东一区'
      } else if (22.5 < longitude && longitude <= 37.5) {
        return '东二区'
      } else if (37.5 < longitude && longitude <= 52.5) {
        return '东三区'
      } else if (52.5 < longitude && longitude <= 67.5) {
        return '东四区'
      } else if (67.5 < longitude && longitude <= 82.5) {
        return '东五区'
      } else if (82.5 < longitude && longitude <= 97.5) {
        return '东六区'
      } else if (97.5 < longitude && longitude <= 112.5) {
        return '东七区'
      } else if (112.5 < longitude && longitude <= 127.5) {
        return '东八区'
      } else if (127.5 < longitude && longitude <= 142.5) {
        return '东九区'
      } else if (142.5 < longitude && longitude <= 157.5) {
        return '东十区'
      } else if (157.5 < longitude && longitude <= 172.5) {
        return '东十一区'
      } else if ((172.5 < longitude && longitude <= 180) || (-180 <= longitude && longitude <= -172.5)) {
        return '东西十二区'
      } else if (-172.5 < longitude && longitude <= -157.5) {
        return '西十一区'
      } else if (-157.5 < longitude && longitude <= -142.5) {
        return '西十区'
      } else if (-142.5 < longitude && longitude <= -127.5) {
        return '西九区'
      } else if (-127.5 < longitude && longitude <= -112.5) {
        return '西八区'
      } else if (-112.5 < longitude && longitude <= -97.5) {
        return '西七区'
      } else if (-97.5 < longitude && longitude <= -82.5) {
        return '西六区'
      } else if (-82.5 < longitude && longitude <= -67.5) {
        return '西五区'
      } else if (-67.5 < longitude && longitude <= -52.5) {
        return '西四区'
      } else if (-52.5 < longitude && longitude <= -37.5) {
        return '西三区'
      } else if (-37.5 < longitude && longitude <= -22.5) {
        return '西二区'
      } else if (-22.5 < longitude && longitude <= -7.5) {
        return '西一区'
      } else {
        return '未知时区'
      }
    }
    const timeZone = ref(calculateTimezoneByLngLat(region.value.lngLat))
    const onConfirm = () => {
      emit('confirm', {
        region: region.value.label,
        timeZone: timeZone.value
      })
      emit('update:visible', false)
    }
    return { props, emit, mapDom, initMap, options, region, timeZone, onConfirm }
  }
})
</script>

<template>
    <el-dialog @opened="initMap" :visible="props.visible" :close-on-press-escape="false" :close-on-click-modal="false" :show-close="false">
        <template slot="header">
            <p>地区时区选择器</p>
        </template>
        <div>
            <el-form inline label-suffix="：" size="small">
                <el-form-item label="地区">
                    <el-select filterable placeholder="未知地区" v-model="region" value-key="lngLat">
                        <el-option v-for="option of options" :key="option.label" :label="option.label" :value="option" />
                    </el-select>
                </el-form-item>
                <el-form-item label="时区">
                    <span>{{ timeZone }}</span>
                </el-form-item>
            </el-form>
            <div ref="mapDom" style="height: 300px"></div>
        </div>
        <template slot="footer">
            <el-button type="primary" @click="onConfirm" size="small">确认</el-button>
            <el-button @click="emit('update:visible', false)" size="small">关闭</el-button>
        </template>
    </el-dialog>
</template>

<style scoped lang="scss">
::v-deep .el-select {
    border-bottom: 1px solid grey;
}

::v-deep .el-input__inner {
    border: none;
    padding-left: 0;
}

::v-deep .el-input__suffix {
    display: none;
}
</style>

