<template>
    <div class="newBox">
        <div class="header">{{theme}}</div>
        <div class="title">基本信息</div>
        <el-form :model="submitForm" label-width="100px" size="small">
            <el-row :gutter="16">
                <el-col :span="8">
                    <el-form-item label="采购计划" required>
                        <el-input v-model="submitForm.procurementPlan" class="w100" @blur="generateNumber"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="采购编号" required>
                        <el-input v-model="submitForm.purchaseNo" class="w100"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="采购日期" required>
                        <el-date-picker v-model="submitForm.purchaseDate" type="date" value-format="yyyy-MM-dd" placeholder="选择日期" class="w100"></el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="交货日期" required>
                        <el-date-picker v-model="submitForm.deliveryDate" type="date" value-format="yyyy-MM-dd" placeholder="选择日期" class="w100"></el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="跟进人" required>
                        <el-select v-model="submitForm.followerKey" size="small" filterable placeholder="跟进人"
                         class="w100" @change="chooseOne">
                            <el-option v-for="item in staffOption" :key="item.key" :label="item.name" :value="item.key"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div class="title">产品及供应商信息</div>
        <el-form :model="submitForm" label-width="100px" size="small">
            <el-row :gutter="16">
                <el-col :span="8">
                    <el-form-item label="供应商" required>
                        <el-select v-model="submitForm.manufacturerKey" size="small" filterable placeholder="选择供应商"
                         class="w100" @change="chooseTwo">
                            <el-option v-for="item in purchaserList" :key="item.key" :label="item.manufacturerName" :value="item.key"></el-option>
                            <div class="newPurchaser" @click="newPurchaser">
                                <i class="el-icon-plus"></i>
                                <span>新建供应商</span>
                            </div>
                            <div slot="empty">
                                <div class="newPurchaser" @click="newPurchaser">
                                    <i class="el-icon-plus"></i>
                                    <span>新建供应商</span>
                                </div>
                            </div>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="联系人" required>
                        <el-select v-model="submitForm.contactsKey" size="small" filterable placeholder="选择联系人" class="w100">
                            <el-option v-for="item in contactOption" :key="item.key" :label="item.contactsName" :value="item.key"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <el-table :data="purchasingProducts" border :header-cell-style="{'background-color':'#F3F3F3','color':'#3A3A3A'}">
            <el-table-column label="产品名称" width="200">
                <template slot-scope="scope">
                    <span style="color: #ff2d2d">*</span>
                    <el-select v-model="scope.row.productKey" filterable placeholder="请选择产品" size="small" style="width:160px;"
                        @change="(value) => selectProduct(value,scope.row)">
                        <el-option v-for="item in productOption" :key="item.key" :label="item.productName" :value="item.key">
                            {{item.productName}}(编号:{{item.productNo}})(规格:{{item.dimension}})
                        </el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column label="单价" width="160">
                <template slot-scope="scope">
                    <el-input-number v-model="scope.row.unitPrice" size="small" :min="0" :controls="false" @blur="calcTotalPrice(scope.row)"></el-input-number>
                </template>
            </el-table-column>
            <el-table-column label="订购数量" width="160">
                <template slot-scope="scope">
                    <el-input-number v-model="scope.row.num" size="small" :min="0" :controls="false" @blur="calcTotalPrice(scope.row)"></el-input-number>
                </template>
            </el-table-column>
            <el-table-column label="总价" width="160">
                <template slot-scope="scope">
                    <el-input-number v-model="scope.row.totalPrice" size="small" :min="0" :controls="false" @blur="calcCNYPrice(scope.row)"></el-input-number>
                </template>
            </el-table-column>
            <el-table-column label="币种" width="120">
                <template slot-scope="scope">
                    <el-select v-model="scope.row.currency" size="small" filterable class="w100" @change="(value)=>chooseCurrency(value,scope.row)">
                        <el-option v-for="item in currencyOption" :key="item.key" :label="item.currency" :value="item.currency"></el-option>
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column label="汇率" width="160">
                <template slot-scope="scope">
                    <el-input-number :disabled="scope.row.currency=='CNY'" v-model="scope.row.exchangeRate" size="small" :min="0" :controls="false" @blur="calcCNYPrice(scope.row)"></el-input-number>
                </template>
            </el-table-column>
            <el-table-column label="人民币总价" width="160">
                <template slot-scope="scope">
                    <el-input-number :disabled="scope.row.currency=='CNY'" v-model="scope.row.cnyTotalPrice" size="small" :min="0" :controls="false" @blur="calcRate(scope.row)"></el-input-number>
                </template>
            </el-table-column>
            <el-table-column label="备注">
                <template slot-scope="scope">
                    <el-input v-model="scope.row.remarks" size="small"></el-input>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="60">
                <template slot-scope="scope">
                    <div class="handleDelBtn" @click="delProductItem(scope.$index)">删除</div>
                </template>
            </el-table-column>
        </el-table>
        <div class="bottomAddBtn" @click="addProductItem">
            <img src="@/assets/icon/plus.png" alt="" />新增产品
        </div>
        <div class="summation">
            <div>
                <span>采购产品数量：</span>
                <span style="padding-left:10px;">{{productTotalNum}}</span>
            </div>
            <div>
                <span>采购产品金额：</span>
                <span>CNY</span>
                <span style="padding-left:10px;">{{productTotalPrice}}</span>
            </div>
        </div>

        <div class="title" style="margin-top:20px;">贸易费用</div>
        <div class="tradeCost">
            <div v-show="tradeExpenses.length == 0" style="text-align: center;color: #7C90A5;padding: 10px;">暂无贸易费用</div>
            <div v-for="(item,index) in tradeExpenses" :key="index" class="itemCost">
                <span style="margin-right: 20px;">{{index+1}}</span>
                <div style="margin-right: 20px;">
                    <span>项目：</span>
                    <el-select v-model="item.project" size="small" style="width:180px;" filterable allow-create>
                        <el-option v-for="item in projectOption" :key="item.key" :label="item.name" :value="item.name"></el-option>
                        <div class="toConfig" @click="toConfig">
                            <i class="el-icon-plus"></i>
                            <span>配置费用</span>
                        </div>
                    </el-select>
                </div>
                <div style="margin-right: 20px;">
                    <span>金额：</span>
                    <el-input-number v-model="item.totalPrice" size="small" :min="0" :controls="false" @blur="calcCNYPrice(item)"></el-input-number>
                </div>
                <div style="margin-right: 20px;">
                    <span>币种：</span>
                    <el-select v-model="item.currency" size="small" filterable style="width:100px;" @change="(value)=>chooseCurrency(value,item)">
                        <el-option v-for="item in currencyOption" :key="item.key" :label="item.currency" :value="item.currency"></el-option>
                    </el-select>
                </div>
                <div style="margin-right: 20px;">
                    <span>汇率：</span>
                    <el-input-number :disabled="item.currency=='CNY'" v-model="item.exchangeRate" size="small" :min="0" :controls="false" @blur="calcCNYPrice(item)"></el-input-number>
                </div>
                 <div style="margin-right: 20px;">
                    <span>人民币金额：</span>
                    <el-input-number :disabled="item.currency=='CNY'" v-model="item.cnyTotalPrice" size="small" :min="0" :controls="false" @blur="calcRate(item)"></el-input-number>
                </div>
                <div class="handleDelBtn" @click="delCostItem(index)">删除</div>
            </div>
        </div>
        <div class="bottomAddBtn" @click="addCostItem">
            <img src="@/assets/icon/plus.png" alt="" />新增费用
        </div>
        <div class="summation">
            <div></div>
            <div>
                <span>贸易费用金额：</span>
                <span>CNY</span>
                <span style="padding-left:10px;">{{tradeExpensesTotalPrice}}</span>
            </div>
        </div>
        <div class="summation" style="font-size: 16px;font-weight: bold;">
            <div></div>
            <div>
                <span>合计总金额：</span>
                <span>CNY</span>
                <span style="padding-left:10px;">{{allTotalPrice}}</span>
            </div>
        </div>

        <div class="submitBtn">
            <el-button type="primary" @click="onSubmit" :loading="btnLoading" :disabled="isSubmit" size="small">
                立即提交
            </el-button>
        </div>
    </div>
</template>

<script>
import dayjs from "dayjs";
export default {
    props: {
        handleSelect: {
            type: Function,
            default: null,
        },
    },
    data() {
        return {
            theme: "新增采购记录",
            btnLoading: false,
            isSubmit: false,
            submitForm: {
                "key": "",
                "procurementPlan": "",
                "purchaseNo": "",
                "purchaseDate": dayjs().format('YYYY-MM-DD'),
                "deliveryDate": dayjs().format('YYYY-MM-DD'),
                "followerKey": "",
                "followerName": "",
                "customerName": "",
                "customerKey": "",
                "manufacturerKey": "",
                "manufacturerName": "",
                "manufacturerNo": "",
                "settlementCurrency": "CNY",
                "contactsKey": "",
            },
            purchasingProducts: [], //采购产品
            tradeExpenses: [],      //贸易费用

            staffOption: [],
            purchaserList: [],
            currencyOption: [],
            productOption: [],
            contactOption: [],
            projectOption: [
                {key: 1, name: "关税"},
                {key: 2, name: "运费"},
                {key: 3, name: "保险"},
                {key: 4, name: "国内运费"},
            ],
        };
    },
    computed:{
        //采购产品数量
        productTotalNum(){
            let productTotalNum = 0;
            this.purchasingProducts.forEach(ele =>{
                if(ele.productKey){
                    productTotalNum += ele.num;
                }
            })
            return productTotalNum;
        },
        //采购产品金额
        productTotalPrice(){
            let productTotalPrice = 0;
            this.purchasingProducts.forEach(ele =>{
                if(ele.productKey){
                    productTotalPrice += ele.cnyTotalPrice;
                }
            })
            return productTotalPrice.toFixed(2) * 1;
        },
        //贸易费用金额
        tradeExpensesTotalPrice(){
            let tradeExpensesTotalPrice = 0;
            this.tradeExpenses.forEach(ele =>{
                tradeExpensesTotalPrice += ele.cnyTotalPrice;
            })
            return tradeExpensesTotalPrice.toFixed(2) * 1;
        },
        //合计总金额
        allTotalPrice(){
            let allTotalPrice = this.productTotalPrice + this.tradeExpensesTotalPrice;
            return allTotalPrice.toFixed(2) * 1;
        },
    },
    methods: {
        //获取所有成员集合
        getStaffBox(){
            this.$axios.get(`${this.$personnelUrl}/Organization/FindStaffBox`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.staffOption = data;
                }
            })
        },
        //获取供应商
        getPurchaser(){
            this.$axios.post(`${this.$orderUrl}/Purchase/GetPurchaser`,{
                manufacturerName: "",
                pageIndex: 1,
                pageSize: 100,
            }).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    this.purchaserList = data.datas;
                }
            });
        },
        chooseOne(value){
            let aItem = this.staffOption.filter(ele => ele.key == value)[0];
            this.submitForm.followerName = aItem.name;
        },
        chooseTwo(value){
            let bItem = this.purchaserList.filter(ele => ele.key == value)[0];
            this.submitForm.manufacturerName = bItem.manufacturerName;
            this.submitForm.manufacturerNo = bItem.manufacturerNo;
            //获取对应供应商联系人列表配置
            this.$axios.get(`${this.$orderUrl}/Purchase/V2/GetPurchaserDetail?key=${value}`).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    this.contactOption = data.contacts;
                    this.submitForm.contactsKey = data.contacts[0].key;
                }
            });
        },
        //获取币种配置
        getCurrencyConfig(){
            this.$axios.get(`${this.$customerUrl}/v1/Config/GetCurrencyConfig`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.currencyOption = data;
                }
            })
        },
        
        //获取产品列表
        getProductList(){
            this.$axios.post(`${this.$productUrl}/api/Product/List`,{
                pageIndex: 1,
                pageSize: 500
            }).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    this.productOption = data.datas;
                }
            });
        },        
        addProductItem(){
            this.purchasingProducts.push({
                "productKey": "",
                "productNo": "",
                "productName": "",
                "specifications": "",
                "productDescription": "",
                "unitPrice": 0,
                "totalPrice": 0,
                "num": 1,
                "measureUnit": "",
                "otherExpenses": 0,
                "currency": "CNY",
                "exchangeRate": "1",
                "cnyTotalPrice": 0,
                "remarks": ""
            })
        },
        delProductItem(index){
            this.purchasingProducts.splice(index,1);
        },

        addCostItem(){
            this.tradeExpenses.push({
                "project": "",
                "totalPrice": 0,
                "currency": "CNY",
                "exchangeRate": "1",
                "cnyTotalPrice": 0,
                "remarks": "",
            })
        },
        delCostItem(index){
            this.tradeExpenses.splice(index,1);
        },

        //选择产品
        selectProduct(value,row){
            let pItem = this.productOption.filter(ele => ele.key == value)[0];
            row.unitPrice = pItem.taxIncludingPrice;
            this.calcTotalPrice(row);
        },
        //通过单价和数量,计算总价
        calcTotalPrice(row){
            row.totalPrice = row.num * row.unitPrice;
            this.calcCNYPrice(row);
        },
        //选择货币类型
        chooseCurrency(value,row){
            let cItem = this.currencyOption.filter(ele => ele.currency == value)[0];
            row.exchangeRate = cItem.exchangeRate;
            this.calcCNYPrice(row);
        },
        //通过总价和汇率,计算人名币总价
        calcCNYPrice(row){
            row.cnyTotalPrice = row.totalPrice * row.exchangeRate * 1;
            row.cnyTotalPrice = row.cnyTotalPrice.toFixed(2) * 1;
        },
        //通过总价和人民币总价,计算汇率
        calcRate(row){
            row.exchangeRate = row.cnyTotalPrice / row.totalPrice;
            row.exchangeRate = row.exchangeRate.toFixed(8) * 1;
        },



        onSubmit(){
            let {procurementPlan,purchaseNo,purchaseDate,deliveryDate,followerKey,manufacturerKey,contactsKey} = this.submitForm;
            if(!procurementPlan){ this.$message({ message: '请填写采购计划', type: 'warning'}); return false; }
            if(!purchaseNo){ this.$message({ message: '请填写采购编号', type: 'warning'}); return false; }
            if(!purchaseDate){ this.$message({ message: '请选择采购日期', type: 'warning'}); return false; }
            if(!deliveryDate){ this.$message({ message: '请选择交货日期', type: 'warning'}); return false; }
            if(!followerKey){ this.$message({ message: '请选择跟进人', type: 'warning'}); return false; }
            if(!manufacturerKey){ this.$message({ message: '请选择供应商', type: 'warning'}); return false; }
            if(!contactsKey){ this.$message({ message: '请选择联系人', type: 'warning'}); return false; }
            if(this.productTotalNum == 0){
                this.$message({ message: '请添加采购产品', type: 'warning'}); 
                return false;
            }
            if(this.productTotalPrice == 0){
                this.$message({ message: '采购金额不能为0', type: 'warning'}); 
                return false;
            }
            this.purchasingProducts = this.purchasingProducts.filter(ele => ele.productKey !== "");
            this.tradeExpenses = this.tradeExpenses.filter(ele => ele.project !== "");
            // console.log(this.submitForm);
            //保存采购记录
            this.btnLoading = true;
            this.$axios.post(`${this.$orderUrl}/Purchase/SavePurchasingRecord`, {
                ...this.submitForm,
                purchasingProducts: this.purchasingProducts,
                tradeExpenses: this.tradeExpenses,
            }).then((res) => {
                const { isSuccess, data } = res;
                this.btnLoading = false;
                if (isSuccess) {
                    this.$message({ message: '提交成功', type: 'success'});
                    this.isSubmit = true;
                    this.handleSelect(`/purchase/recordDetails?key=${data}`);
                    this.$router.push(`/purchase/recordDetails?key=${data}`);
                }
            });
        },

        //新建供应商
        newPurchaser(){
            let routeData = this.$router.resolve({
                path: "/purchase/newPurchaser",
            });
            window.open(routeData.href, "_blank");
        },

        getDetail(key){
            this.$axios.get(`${this.$orderUrl}/Purchase/GetPurchasingRecordDetail?key=${key}`).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    this.submitForm = data;
                    this.purchasingProducts = data.purchasingProducts;
                    this.tradeExpenses = data.tradeExpenses;
                    this.chooseTwo(this.submitForm.manufacturerKey);
                    //复制采购记录
                    let optype = this.$route.query.optype;
                    if(optype == 'copy'){
                        this.theme = "复制采购记录";
                        document.title = "复制采购记录";
                        this.submitForm.key = "";
                    }
                }
            });
        },

        generateNumber(){
            if(this.submitForm.procurementPlan){
                this.$axios.get(`${this.$orderUrl}/Purchase/GetNo?name=${this.submitForm.procurementPlan}`).then((res) => {
                    const { isSuccess , data } = res;
                    if (isSuccess) {
                        this.submitForm.purchaseNo = data;
                    }
                });
            }
        },

        //获取费用配置
        getProject(){
            this.$axios.get(`${this.$customerUrl}/v1/Config/GetTradeExpensesProject`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.projectOption = data;
                }
            });
        },
        toConfig(){
            let routeData = this.$router.resolve({
                path: "/settingIndex",
                query: { type: "system" },
            });
            window.open(routeData.href, "_blank");
        },

    },
    mounted() {
        //获取下拉框配置项
        this.getStaffBox();
        this.getPurchaser();
        this.getCurrencyConfig();
        this.getProductList();
        this.getProject();

        let key = this.$route.query.key;
        if(key){
            this.theme = "编辑采购记录";
            document.title = "编辑采购记录";
            this.getDetail(key);
        }else{
            this.addProductItem();
        }
       
    },
};
</script>

<style lang="scss" scoped>
.newBox{
    background: #FFFFFF;
    padding: 10px 20px;
    .header{
        height: 32px;
        line-height: 32px;
        font-size: 20px;
        font-weight: bold;
        color: #000;
        margin-bottom: 10px;
    }
    .title{
        height: 20px;
        line-height: 20px;
        font-size: 16px;
        font-weight: bold;
        color: #3A3A3A;
        margin-bottom: 10px;
        border-left: 3px solid var(--themeColor);
        padding-left: 5px;
    }
    .w100{
        width: 100%;
        height: 32px;
    }
    .submitBtn{
        display: flex;
        margin-top: 20px;
        justify-content: flex-end;
    }
}
.bottomAddBtn{
    cursor: pointer;
    height: 48px;
    line-height: 48px;
    text-align: center;
    font-size: 16px;
    color: #7C90A5;
    border: 1px solid #EBEEF5;
    border-top: none;
    img{
        vertical-align: middle;
        padding-right: 10px;
    }
}
.handleDelBtn{
    cursor: pointer;
    display: inline-block;
    text-align: center;
    width: 40px;
    height: 20px;
    line-height: 18px;
    font-size: 12px;
    background: rgba(255, 86, 86, 0.26);
    border: 1px solid rgba(255, 86, 86, 0.3);
    border-radius: 2px;
    color: #FF5656;
}
.summation{
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    color: #3a3a3a;
    display: flex;
    justify-content: space-between;
}
.newPurchaser{
    cursor: pointer;
    text-align: center;
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    color: #6a6a6a;
    &:hover{
        background-color: #F5F7FA;
    }
}
.tradeCost{
    border: 1px solid #EBEEF5;
    color: #5E6D82;
    font-size: 14px;
    .itemCost{
        display: flex;
        height: 32px;
        line-height: 32px;
        padding: 10px;
    }
    .itemCost:nth-child(2n+1){
        background: #F8F7F7;
    }
    .itemCost:nth-child(2n){
        background: #E5E9F0;
    }
    .handleDelBtn{
        margin-top: 6px;
    }
}
.toConfig{
    cursor: pointer;
    text-align: center;
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    color: #6a6a6a;
    &:hover{
        background-color: #F5F7FA;
    }
}
</style>