<template>
    <div style="position: relative;">
        <el-container style="height: 100vh">
            <el-aside v-if="isColourless==0" class="aside1" width="70px">
                <img class="logoImg" src="@/assets/img/smallLogo.png" alt="" />
                <div v-if="userInfo.isFinance" class="navItem" @click="clickNav('/finance/performanceManage')" :class="{'active':choosePath.indexOf('/finance')>-1}">
                    <img class="navImg" src="@/assets/navicon/013.png" alt="" />
                    <div>财务</div>
                </div>
                <div v-else>
                <div class="navItem" @click="clickNav('/homepage/workbench')" :class="{'active':choosePath.indexOf('/homepage/workbench')>-1}">
                    <img class="navImg" src="@/assets/navicon/001.png" alt="" />
                    <div>工作台</div>
                </div>
                <div class="navItem" @click="clickNav('/mailIndex')" :class="{'active':choosePath.indexOf('/mailIndex')>-1}">
                    <img class="navImg" src="@/assets/navicon/002.png" alt="" />
                    <div>邮箱</div>
                </div>
                <!-- isOpenClueModel 控制线索模块权限 v-show="userInfo.isOpenClueModel"  -->
                <!-- <div class="navItem" @click="clickNav('/clue/myClue')" :class="{'active':choosePath.indexOf('/clue')>-1}">
                    <img class="navImg" src="@/assets/navicon/003.png" alt="" />
                    <div>线索</div>
                </div> -->
                <!-- isCustomsData 控制海关数据权限 v-show="userInfo.isCustomsData"  -->
                <!-- <div class="navItem" @click="clickNav('/AkeySearch/TradeData')" :class="{'active':choosePath.indexOf('/AkeySearch')>-1}">
                    <img class="navImg" src="@/assets/navicon/004.png" alt="" />
                    <div>海关数据</div>
                </div> -->
                <div class="navItem" v-show="this.$isTest" @click="clickNav('/AI/TodayNew')" :class="{'active':choosePath.indexOf('/AI')>-1}">
                    <img class="navImg" src="@/assets/navicon/005.png" alt="" />
                    <div>AI推荐</div>
                </div>
                <!-- isOpenMarketingModel 控制营销模块权限 v-show="userInfo.isOpenMarketingModel" -->
                <!-- <div class="navItem" @click="clickNav('/marketing/TaskDashboard')" :class="{'active':choosePath.indexOf('/marketing')>-1}">
                    <img class="navImg" src="@/assets/navicon/006.png" alt="" />
                    <div>营销</div>
                </div> -->
                <div class="navItem" @click="clickNav('/customerManage/myCustomer')" :class="{'active':choosePath.indexOf('/customerManage')>-1}">
                    <img class="navImg" src="@/assets/navicon/007.png" alt="" />
                    <div>客户</div>
                </div>
                <div class="navItem" @click="clickNav('/business/businessManage')" :class="{'active':choosePath.indexOf('/business/businessManage')>-1}">
                    <img class="navImg" src="@/assets/navicon/008.png" alt="" />
                    <div>商机</div>
                </div>
                <div class="navItem" @click="clickNav('/orderManage/productManage')" :class="{'active':choosePath.indexOf('/orderManage/productManage')>-1}">
                    <img class="navImg" src="@/assets/navicon/010.png" alt="" />
                    <div>产品</div>
                </div>
                <div class="navItem" @click="clickNav('/orderManage/orderList')" :class="{'active':choosePath.indexOf('/quotationList')>-1 ||choosePath.indexOf('/orderList')>-1}">
                    <img class="navImg" src="@/assets/navicon/009.png" alt="" />
                    <div>订单</div>
                </div>
                <div v-show="userInfo.isProcureModel" class="navItem" @click="clickNav('/purchase/recordList')" :class="{'active':choosePath.indexOf('/purchase')>-1}">
                    <img class="navImg" src="@/assets/navicon/011.png" alt="" style="width:22px;" />
                    <div>采购</div>
                </div>
                <div class="navItem" @click="clickNav('/report/index')" :class="{'active':choosePath.indexOf('/report')>-1}">
                    <img class="navImg" src="@/assets/navicon/012.png" alt="" />
                    <div>BI报表</div>
                </div>
                <div class="navItem" @click="clickNav('/performanceTarget')" :class="{'active':choosePath.indexOf('/performanceTarget')>-1}">
                    <img class="navImg" src="@/assets/navicon/014.png" alt="" />
                    <div>目标</div>
                </div>
                </div>
            </el-aside>
            <!-- 无色背景橙色主题 -->
            <el-aside v-if="isColourless==1" class="aside2" width="70px">
                <img class="logoImg" src="@/assets/img/smallLogo.png" alt="" />
                <div v-if="userInfo.isFinance" class="navItem" @click="clickNav('/finance/performanceManage')" :class="{'active':choosePath.indexOf('/finance')>-1}">
                    <img class="navImg" src="@/assets/navicon/a013.png" alt="" />
                    <div>财务</div>
                </div>
                <div v-else>
                <div class="navItem" @click="clickNav('/homepage/workbench')" :class="{'active':choosePath.indexOf('/homepage/workbench')>-1}">
                    <img class="navImg" src="@/assets/navicon/a001.png" alt="" />
                    <div>工作台</div>
                </div>
                <div class="navItem" @click="clickNav('/mailIndex')" :class="{'active':choosePath.indexOf('/mailIndex')>-1}">
                    <img class="navImg" src="@/assets/navicon/a002.png" alt="" />
                    <div>邮箱</div>
                </div>
                <!-- isOpenClueModel 控制线索模块权限 v-show="userInfo.isOpenClueModel"  -->
                <!-- <div class="navItem" @click="clickNav('/clue/myClue')" :class="{'active':choosePath.indexOf('/clue')>-1}">
                    <img class="navImg" src="@/assets/navicon/a003.png" alt="" />
                    <div>线索</div>
                </div> -->
                <!-- isCustomsData 控制海关数据权限 v-show="userInfo.isCustomsData"  -->
                <!-- <div class="navItem" @click="clickNav('/AkeySearch/TradeData')" :class="{'active':choosePath.indexOf('/AkeySearch')>-1}">
                    <img class="navImg" src="@/assets/navicon/a004.png" alt="" />
                    <div>海关数据</div>
                </div> -->
                <div class="navItem" v-show="this.$isTest" @click="clickNav('/AI/TodayNew')" :class="{'active':choosePath.indexOf('/AI')>-1}">
                    <img class="navImg" src="@/assets/navicon/a005.png" alt="" />
                    <div>AI推荐</div>
                </div>
                <!-- isOpenMarketingModel 控制营销模块权限 v-show="userInfo.isOpenMarketingModel" -->
                <!-- <div class="navItem" @click="clickNav('/marketing/TaskDashboard')" :class="{'active':choosePath.indexOf('/marketing')>-1}">
                    <img class="navImg" src="@/assets/navicon/a006.png" alt="" />
                    <div>营销</div>
                </div> -->
                <div class="navItem" @click="clickNav('/customerManage/myCustomer')" :class="{'active':choosePath.indexOf('/customerManage')>-1}">
                    <img class="navImg" src="@/assets/navicon/a007.png" alt="" />
                    <div>客户</div>
                </div>
                <div class="navItem" @click="clickNav('/business/businessManage')" :class="{'active':choosePath.indexOf('/business/businessManage')>-1}">
                    <img class="navImg" src="@/assets/navicon/a008.png" alt="" />
                    <div>商机</div>
                </div>
                <div class="navItem" @click="clickNav('/orderManage/productManage')" :class="{'active':choosePath.indexOf('/orderManage/productManage')>-1}">
                    <img class="navImg" src="@/assets/navicon/a010.png" alt="" />
                    <div>产品</div>
                </div>
                <div class="navItem" @click="clickNav('/orderManage/orderList')" :class="{'active':choosePath.indexOf('/quotationList')>-1 ||choosePath.indexOf('/orderList')>-1}">
                    <img class="navImg" src="@/assets/navicon/a009.png" alt="" />
                    <div>订单</div>
                </div>
                <div v-show="userInfo.isProcureModel" class="navItem" @click="clickNav('/purchase/recordList')" :class="{'active':choosePath.indexOf('/purchase')>-1}">
                    <img class="navImg" src="@/assets/navicon/a011.png" alt="" />
                    <div>采购</div>
                </div>
                <div class="navItem" @click="clickNav('/report/index')" :class="{'active':choosePath.indexOf('/report')>-1}">
                    <img class="navImg" src="@/assets/navicon/a012.png" alt="" />
                    <div>BI报表</div>
                </div>
                <div class="navItem" @click="clickNav('/performanceTarget')" :class="{'active':choosePath.indexOf('/performanceTarget')>-1}">
                    <img class="navImg" src="@/assets/navicon/a014.png" alt="" />
                    <div>目标</div>
                </div>
                </div>
            </el-aside>
            <!-- 无色背景黑色主题 -->
            <el-aside v-if="isColourless==2" class="aside2" width="70px" style="background: #F8F7FC;">
                <img class="logoImg" src="@/assets/img/smallLogo.png" alt="" />
                <div v-if="userInfo.isFinance" class="navItem" @click="clickNav('/finance/performanceManage')" :class="{'active':choosePath.indexOf('/finance')>-1}">
                    <img class="navImg" src="@/assets/navicon/b013.png" alt="" />
                    <div>财务</div>
                </div>
                <div v-else>
                <div class="navItem" @click="clickNav('/homepage/workbench')" :class="{'active':choosePath.indexOf('/homepage/workbench')>-1}">
                    <img class="navImg" src="@/assets/navicon/b001.png" alt="" />
                    <div>工作台</div>
                </div>
                <div class="navItem" @click="clickNav('/mailIndex')" :class="{'active':choosePath.indexOf('/mailIndex')>-1}">
                    <img class="navImg" src="@/assets/navicon/b002.png" alt="" />
                    <div>邮箱</div>
                </div>
                <!-- isOpenClueModel 控制线索模块权限 v-show="userInfo.isOpenClueModel"  -->
                <!-- <div class="navItem" @click="clickNav('/clue/myClue')" :class="{'active':choosePath.indexOf('/clue')>-1}">
                    <img class="navImg" src="@/assets/navicon/b003.png" alt="" />
                    <div>线索</div>
                </div> -->
                <!-- isCustomsData 控制海关数据权限 v-show="userInfo.isCustomsData"  -->
                <!-- <div class="navItem" @click="clickNav('/AkeySearch/TradeData')" :class="{'active':choosePath.indexOf('/AkeySearch')>-1}">
                    <img class="navImg" src="@/assets/navicon/b004.png" alt="" />
                    <div>海关数据</div>
                </div> -->
                <div class="navItem" v-show="this.$isTest" @click="clickNav('/AI/TodayNew')" :class="{'active':choosePath.indexOf('/AI')>-1}">
                    <img class="navImg" src="@/assets/navicon/b005.png" alt="" />
                    <div>AI推荐</div>
                </div>
                <!-- isOpenMarketingModel 控制营销模块权限 v-show="userInfo.isOpenMarketingModel" -->
                <!-- <div class="navItem" @click="clickNav('/marketing/TaskDashboard')" :class="{'active':choosePath.indexOf('/marketing')>-1}">
                    <img class="navImg" src="@/assets/navicon/b006.png" alt="" />
                    <div>营销</div>
                </div> -->
                <div class="navItem" @click="clickNav('/customerManage/myCustomer')" :class="{'active':choosePath.indexOf('/customerManage')>-1}">
                    <img class="navImg" src="@/assets/navicon/b007.png" alt="" />
                    <div>客户</div>
                </div>
                <div class="navItem" @click="clickNav('/business/businessManage')" :class="{'active':choosePath.indexOf('/business/businessManage')>-1}">
                    <img class="navImg" src="@/assets/navicon/b008.png" alt="" />
                    <div>商机</div>
                </div>
                <div class="navItem" @click="clickNav('/orderManage/productManage')" :class="{'active':choosePath.indexOf('/orderManage/productManage')>-1}">
                    <img class="navImg" src="@/assets/navicon/b010.png" alt="" />
                    <div>产品</div>
                </div>
                <div class="navItem" @click="clickNav('/orderManage/orderList')" :class="{'active':choosePath.indexOf('/quotationList')>-1 ||choosePath.indexOf('/orderList')>-1}">
                    <img class="navImg" src="@/assets/navicon/b009.png" alt="" />
                    <div>订单</div>
                </div>
                <div v-show="userInfo.isProcureModel" class="navItem" @click="clickNav('/purchase/recordList')" :class="{'active':choosePath.indexOf('/purchase')>-1}">
                    <img class="navImg" src="@/assets/navicon/b011.png" alt="" />
                    <div>采购</div>
                </div>
                <div class="navItem" @click="clickNav('/report/index')" :class="{'active':choosePath.indexOf('/report')>-1}">
                    <img class="navImg" src="@/assets/navicon/b012.png" alt="" />
                    <div>BI报表</div>
                </div>
                <div class="navItem" @click="clickNav('/performanceTarget')" :class="{'active':choosePath.indexOf('/performanceTarget')>-1}">
                    <img class="navImg" src="@/assets/navicon/b014.png" alt="" />
                    <div>目标</div>
                </div>
                </div>
            </el-aside>
            <el-main style="padding:0px;">
                <div class="header">
                    <!-- 头部标签页屏蔽 -->
                    <div class="tabList" v-if="false">
                        <div class="tabItem" v-for="(item,index) in chooseTabs" :class="{'active':choosePath == item.path}" :key="index" @click="clickTab(item)">
                            <img v-if="item.title=='工作台'" src="@/assets/img/home.png" alt="" />
                            <span v-else>{{item.title}}</span>
                            <i class="el-icon-close" style="padding-left:5px;" @click.stop="removeTab(item)" v-show="index!=0"></i>
                        </div>
                    </div>
                    <div class="rightOption">
                        <el-tooltip effect="dark" content="AI聊天" placement="bottom">
                            <img class="optionImg" src="@/assets/img/option6.png" alt="" @click="toChatAI" />
                        </el-tooltip>
                        <!-- <el-tooltip effect="dark" content="企芯助手" placement="bottom">
                            <img class="optionImg" style="width: 20px;height: 20px;" src="@/assets/helper/client.png" alt="" @click="toInstallClient" />
                        </el-tooltip> -->
                        <el-tooltip effect="dark" content="客服" placement="bottom">
                            <img class="optionImg" style="width: 20px;height: 20px;" src="@/assets/img/option5.png" alt="" @click="openService" />
                        </el-tooltip>
                        <el-tooltip effect="dark" content="网盘" placement="bottom">
                            <img class="optionImg" src="@/assets/img/option4.png" alt="" @click="toDisk" />
                        </el-tooltip>
                        <el-tooltip effect="dark" content="日历" placement="bottom">
                            <img class="optionImg" src="@/assets/img/option1.png" alt="" @click="toCalendar" />
                        </el-tooltip>
                        <el-tooltip effect="dark" content="设置" placement="bottom">
                            <img class="optionImg" src="@/assets/img/option2.png" alt="" @click="toSet" />
                        </el-tooltip>
                        <!-- <img class="optionImg" src="@/assets/img/option3.png" alt="" /> -->
                        <!-- 个人信息 -->
                        <el-popover placement="bottom" trigger="hover" width="320">
                            <div class="block" slot="reference">
                                <el-avatar :size="30" :src="userInfo.headPortraitUrl ? userInfo.headPortraitUrl : circleUrl"></el-avatar>
                            </div>
                            <div class="personCenter">
                                <div style="display: flex;">
                                    <el-avatar :size="40" :src="userInfo.headPortraitUrl ? userInfo.headPortraitUrl : circleUrl"></el-avatar>
                                    <div style="padding-left:20px">
                                        <div style="font-size: 16px;font-weight: bold;color: #353A3E;">
                                            {{userInfo.companyCnFullName}}
                                            <el-tag effect="plain" size="small" type="success" v-show="userInfo.isAdmin">管理员</el-tag>
                                        </div>
                                        <div style="font-size: 14px;color: #9C9C9C;">{{userInfo.userCnName + userInfo.mailAddress}}</div>
                                        <!-- 升级的套餐版本 -->
                                        <div class="setMealName" v-if="userInfo.setMealName">{{userInfo.setMealName}}</div>
                                    </div>
                                </div>
                                <div style="display:flex; justify-content: space-between;margin:10px 0;">
                                    <div style="color: #9C9C9C;">
                                        <span style="cursor: pointer;color:var(--themeColor)" @click="toLoginLog">上次登录</span>
                                        <span style="margin-left:10px;">{{userInfo.loginTime}}</span>
                                    </div>
                                    <div style="color: #9C9C9C;"><span>{{userInfo.location}}</span></div>
                                    <!-- <el-link type="primary" :underline="false" @click="toPersonalInfo">查看更多</el-link> -->
                                </div>
                                <div style="margin-bottom: 10px;position: relative;display: flex;align-items: center;font-size: 14px;">
                                    <img style="margin-right: 12px" src="@/assets/workbench/111.png" />
                                    <span style="color: #203651">我的积分：</span>
                                    <span style="color:#353A3E;font-weight: bold;">{{jifenTotal}}</span>
                                    <span @click="toJiFen" style="cursor: pointer;position: absolute;right: 0;color: #225EC4;font-size: 12px;">去兑换</span>
                                </div>
                                <p style="border-top:1px solid #D5D8DD;"></p>
                                <!-- (营销模块/线索模块)点数使用情况 -->
                                <!-- <div class="points" style="padding: 10px 0;">
                                    <div v-show="userInfo.isOpenMarketingModel">
                                        <p>已营销邮件点数：{{userInfo.useMarketingSendPoint}} / {{userInfo.marketingSendPoint}}</p>
                                        <el-progress :show-text="false" :stroke-width="10" :percentage="userInfo.marketingSendRatio*100"></el-progress>
                                        <p style="padding: 5px 0;"></p>
                                    </div>
                                    <div v-show="userInfo.isOpenClueModel">
                                        <p>使用线索搜索点数：{{userInfo.useClueSearchPoint}} / {{userInfo.clueSearchPoint}}</p>
                                        <el-progress :show-text="false" :stroke-width="10" :percentage="userInfo.clueSearchRatio*100"></el-progress>
                                        <p style="padding: 5px 0;"></p>
                                    </div>
                                    <div v-show="userInfo.isOpenClueModel">
                                        <p>查看线索详情点数：{{userInfo.useClueDetailPoint}} / {{userInfo.clueDetailPoint}}</p>
                                        <el-progress :show-text="false" :stroke-width="10" :percentage="userInfo.clueDetailRatio*100"></el-progress>
                                        <p style="padding: 5px 0;"></p>
                                    </div>
                                    <div style="display:flex; justify-content: space-between;" v-show="this.$isTest">
                                        <div style="color: #9C9C9C;">
                                            {{userInfo.fewDays ? userInfo.fewDays +'天后点数重置' : '--' }}
                                        </div>
                                        <div>
                                            <el-link type="primary" :underline="false" @click="toMyInvoice">我的发票</el-link>
                                            <span style="padding:0 5px;"></span>
                                            <el-link type="primary" :underline="false" @click="toMyPay">我的支付</el-link>
                                            <span style="padding:0 5px;"></span>
                                            <el-link type="primary" :underline="false" @click="toUpgrade">套餐升级</el-link>
                                        </div>
                                    </div>
                                </div> -->
                                <!-- <p style="border-top:1px solid #D5D8DD;"></p> -->
                                <div style="display:flex;padding:10px;">
                                    <div style="width: 150px;border-right: 1px solid #D5D8DD;">
                                        <div class="clickItem" @click="toPersonalInfo">个人资料</div>
                                    </div>
                                    <div style="width: 150px;">
                                        <div class="clickItem" @click="toStaff">员工管理</div>
                                    </div>
                                </div>
                                <p style="border-top:1px solid #D5D8DD;"></p>
                                <div style="display:flex;padding:10px;">
                                    <div style="width: 150px;border-right: 1px solid #D5D8DD;">
                                        <div class="clickItem" @click="toOpenPlatform">开放平台</div>
                                    </div>
                                    <div style="width: 150px;">
                                        <div class="clickItem" @click="toWorkOrder">工单管理</div>
                                    </div>
                                </div>
                                <p style="border-top:1px solid #D5D8DD;"></p>
                                <div style="margin-top:10px;height: 28px;line-height: 28px;">
                                    <!-- 切换语言 -->
                                    <!-- <el-select v-show="userInfo.companyCnFullName=='技术部测试账号'" v-model="currentLanguage" @change="changeLanguage" size="mini" style="width:80px;">
                                        <el-option label="中文" value="zh"></el-option>
                                        <el-option label="英文" value="en"></el-option>
                                        <el-option label="韩文" value="ko"></el-option>
                                    </el-select>
                                    <el-link v-show="userInfo.companyCnFullName=='技术部测试账号'" @click="toTest">切换语言</el-link> -->
                                    <!-- <el-link href="/#/updateLog" target="_blank" :underline="false">更新日志</el-link> -->
                                    <el-button v-if="$isTest" size="small" @click="translateVisible=true;">翻译功能</el-button>
                                    <span style="cursor:pointer;color:#909399;float:right;" @click="logOut">退出登录</span>
                                </div>
                            </div>
                        </el-popover>
                    </div>
                    <!-- 切换主题色 -->
                    <div class="otherOption" @click="themeSwitch">
                        <img src="@/assets/img/switch.png" alt="" />
                        <span>主题切换</span>
                    </div>
                    <div class="otherOption" style="margin-right:10px;" @click="openJianZhan" v-if="userInfo.isOpenShop">
                        <span>我的网站</span>
                    </div>
                    <div class="otherOption" style="margin-right:10px;" @click="openAlibaba" v-if="this.$isTest">
                        <!-- <span>Alibaba公司</span> -->
                        <span>中国供应商</span>
                    </div>
                    <!-- 义乌塞尔(特殊处理) -->
                    <el-link class="otherOption" style="margin-right:10px;" href="/#/purchaseDemand" target="_blank" :underline="false"
                        v-if="userInfo.companyKey=='8967ee9a38054e4d9e68ad800533d223'">
                        <span>我发布的采购</span>
                    </el-link>
                </div>
                <div class="content">
                    <!-- <keep-alive :exclude="['workOrderList']">
                        <router-view :handleSelect="(index) => handleSelect(index)" />
                    </keep-alive> -->
                    <router-view :userInfo="userInfo" :handleSelect="(index) => handleSelect(index)" @change="themeChange" />
                </div>
            </el-main>
        </el-container>

        <el-dialog title="关联微信" :visible.sync="publicVisible" width="500px" @close="closePublic" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false">
            <div style="text-align:center;" v-loading="codeLoading">
                <img :src="imgCodeSrc" alt="" style="width:300px;height:300px;" />
            </div>
            <span style="cursor:pointer;color:#909399;float:right;" @click="logOut">退出登录</span>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="closePublic">关 闭</el-button>
            </span>
        </el-dialog>


        <el-dialog :title="pushTitle" :visible.sync="pushVisible" width="1000px" :show-close="false">
            <div>
                <span>来源：跨境搜</span>
                <span style="padding-left: 10px;">推荐日期：{{ pushDate }}</span>
            </div>
            <el-table :data="pushCompanyList" style="width: 100%" size="small" height="500px">
                <!-- <el-table-column label="是否存在">
                    <template slot-scope="{row}">
                        <span style="color: #F56C6C;" v-if="row.isExist">是</span>
                        <span v-else>否</span>
                    </template>
                </el-table-column> -->
                <!-- <el-table-column prop="createTime" label="推送时间" width="140"></el-table-column> -->
                <el-table-column prop="name" label="客户名称" width="140"></el-table-column>
                <el-table-column prop="nickname" label="简称"></el-table-column>
                <!-- <el-table-column prop="source" label="来源"></el-table-column> -->
                <el-table-column prop="mainIndustryValue" label="所属行业"></el-table-column>
                <el-table-column prop="mainBusinessTypeValue" label="公司类型"></el-table-column>
                <el-table-column prop="companyScaleValue" label="规模"></el-table-column>
                <el-table-column prop="country" label="国家地区"></el-table-column>
                <el-table-column prop="webSite" label="网址"></el-table-column>
                <el-table-column prop="fax" label="传真"></el-table-column>
                <!-- <el-table-column prop="star" label="星级"></el-table-column> -->
                <el-table-column prop="address" label="地址" show-overflow-tooltip width="140"></el-table-column>
            </el-table>
            <div style="color: #909399;margin-top: 8px">*已存在的客户不会加入我的客户</div>
            <span slot="footer" style="display: flex; justify-content: space-between;">
                <el-button @click="onRefuse" :loading="btnLoading" size="small">取 消</el-button>
                <el-button type="primary" @click="onReceive" :loading="btnLoading" size="small">加入我的客户</el-button>
            </span>
        </el-dialog>


        <img v-if="$isTest" class="aiLogo" @click="openTradeAi" src="@/assets/img/TradeAI.png" alt="" />

        <el-dialog title="TradeAI" :visible.sync="tradeAiDialog" width="1000px" top="30px">
            <div v-loading="tradeLoading" v-if="tradeAiDialog">
                <iframe ref="iframe" :src="tradeAiUrl" style="width:1000px;height:800px;" frameborder="0"></iframe>
            </div>
        </el-dialog>


        <el-dialog title="翻译功能" :visible.sync="translateVisible" width="600px">
            <el-input type="textarea" :rows="6" placeholder="请输入翻译内容" v-model="translateContent"></el-input>
            <span slot="footer">
                <el-button type="primary" size="small" :disabled="!translateContent.trim()" @click="onTranslate">翻译</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import crypto from 'crypto-js'
import dayjs from 'dayjs'

const encrypted = (() => {
    const str = `${dayjs().format('YYYY-MM-DD HH:mm:ss')}/KJSLSMAPS`
    const key = crypto.enc.Utf8.parse('JwCvog1l')
    const iv = crypto.enc.Utf8.parse('JwCvog1l')
    return crypto.DES.encrypt(str, key, {
        iv,
        mode: crypto.mode.CBC,
        padding: crypto.pad.Pkcs7
    }).toString()
})()

let timer = null;
let tokenDetector = null;
let scheduleDetector = null;
export default {
    data() {
        return {
            isColourless: 1,
            //Tab相关参数
            originalList: [
                { title: "工作台", path: "/homepage/index" },
                { title: "工作台", path: "/homepage/workbench" },
                { title: "个人资料", path: "/homepage/personalInfo" },
                { title: "日程表", path: "/homepage/calendar" },
                { title: "多语种测试", path: "/homepage/multilanguage" },
                { title: "管理员系统配置", path: "/homepage/adminConfig" },
                { title: "个人使用配置", path: "/homepage/personalConfig" },
                // 客户管理
                { title: "公海客户", path: "/customerManage/seasCustomer" },
                { title: "我的客户", path: "/customerManage/myCustomer" },
                { title: "透视客户", path: "/customerManage/perspCustomer" },
                { title: "客户详情", path: "/customerManage/customerDetailsPublic" },
                { title: "客户详情", path: "/customerManage/customerDetailsPrivate" },
                { title: "导入客户", path: "/customerManage/import" },
                { title: "客户动态", path: "/customerManage/dynamic" },
                // 订单管理
                { title: "订单管理", path: "/orderManage/orderList" },
                { title: "财务收入", path: "/orderManage/orderCollection" },
                { title: "财务支出", path: "/orderManage/orderSpending" },
                { title: "产品管理", path: "/orderManage/productManage" },
                { title: "新建订单", path: "/orderManage/addOrder" },
                { title: "订单详情", path: "/orderManage/orderDetails" },
                { title: "新建收入", path: "/orderManage/addCollection" },
                { title: "收入详情", path: "/orderManage/collectionDetails" },
                { title: "新建支出", path: "/orderManage/addSpending" },
                { title: "支出详情", path: "/orderManage/spendingDetails" },
                { title: "产品详情", path: "/orderManage/productDetails" },
                { title: "产品导入", path: "/orderManage/productImport" },
                // 报价单管理
                { title: "报价单管理", path: "/quotation/quotationList" },
                { title: "新建报价单", path: "/quotation/newQuotation" },
                { title: "报价单详情", path: "/quotation/quotationDetails" },
                // BI报表
                { title: "BI报表", path: "/report/index" },
                { title: "客户分析", path: "/report/customerAnalysis" },
                { title: "订单分析", path: "/report/orderAnalysis" },
                { title: "产品分析", path: "/report/productAnalysis" },
                { title: "员工分析", path: "/report/staffAnalysis" },
                // 商机模块
                { title: "商机管理", path: "/business/businessManage" },
                { title: "商机详情", path: "/business/BusinessDetailPanel" },
                // 线索模块
                { title: "查找线索", path: "/clue/findClue" },
                { title: "采购商线索", path: "/clue/buyerClue" },
                { title: "工商信息线索", path: "/clue/businessClue" },
                { title: "展会线索", path: "/clue/exhibitionClue" },
                { title: "商业数据线索", path: "/clue/commercialClue" },
                { title: "海关数据线索", path: "/clue/customsClue" },
                { title: "招标信息线索", path: "/clue/tenderClue" },
                { title: "社媒线索", path: "/clue/socialMediaClue" },
                { title: "线索详情", path: "/clue/clueDetails" },
                { title: "介绍详情", path: "/clue/introduce" },
                { title: "全网搜索", path: "/clue/wholeSearch" },
                { title: "线索搜索", path: "/clue/allClue" },
                { title: "我的线索", path: "/clue/myClue" },
                { title: "我的线索详情", path: "/clue/myClueDetails" },
                { title: "线索导入", path: "/clue/clueImport" },
                { title: "商业数据搜索", path: "/clue/businessDataSearch" },
                { title: "商业数据详情", path: "/clue/businessDataDetails" },
                // 营销模块
                { title: "营销看板", path: "/marketing/TaskDashboard" },
                { title: "统计报告", path: "/marketing/Report" },
                { title: "新建营销", path: "/marketing/New" },
                // 员工模块
                { title: "员工管理", path: "/staff/staffList" },
                { title: "员工搜索", path: "/staff/staffSearch" },
                { title: "员工详情", path: "/staff/staffDetails" },
                { title: "员工审核", path: "/staff/staffAudit" },
                { title: "入职审核", path: "/staff/auditDetails" },
                { title: "新增员工", path: "/staff/addStaff" },
                { title: "员工导入", path: "/staff/importStaff" },
                // 工单模块
                { title: "新建工单", path: "/workOrder/newWorkOrder" },
                { title: "工单列表", path: "/workOrder/workOrderList" },
                { title: "工单详情", path: "/workOrder/workOrderDetails" },
                //其他
                { title: "团队管理", path: "/teamManage" },
                { title: "网盘", path: "/diskIndex" },
                { title: '邮箱', path: '/mailIndex' },
                { title: "设置", path: "/settingIndex" },
                { title: "云文档", path: "/cloudDoc" },
                { title: "绩效目标", path: "/performanceTarget" },
                //AI推荐
                { title: "推荐广场", path: "/AI/RecommendSquare" },
                { title: "新增推荐", path: "/AI/TodayNew" },
                { title: "AI详情", path: "/AI/AIDetails" },
            ],
            chooseTitle: "",
            choosePath: "",
            chooseTabs: [
                { title: "工作台", path: "/homepage/workbench" },
            ],
            //默认图像
            circleUrl: require("@/assets/img/headImg.png"),
            userInfo: {
                headPortraitUrl: "",
                companyCnFullName: "",
                userCnName: "",
                mailAddress: "",
                loginTime: "",
                location: "",
                //点数占比默认值(防止报错)
                marketingSendRatio: 0,
                clueSearchRatio: 0,
                clueDetailRatio: 0,
            },
            //关注公众号
            publicVisible: false,
            ticket: "",
            imgCodeSrc: "",
            codeLoading: false,
            //切换语言
            currentLanguage: this.$i18n.locale,
            jifenTotal: 0,

            //推送公司
            pushTitle: "",
            pushDate: "",
            pushVisible: false,
            pushCompanyList: [],
            btnLoading: false,

            //tradeAi对话框
            tradeAiDialog: false,
            tradeAiUrl: "",
            translateVisible: false,
            translateContent: "",
            tradeLoading: false,
        };
    },
    updated() {
        if (this.$refs.iframe) {
            if (this.$refs.iframe.attachEvent) {
                this.$refs.iframe.attachEvent('onload', () => {
                    this.tradeLoading = false;
                });
            } else {
                this.$refs.iframe.onload = () => {
                    this.tradeLoading = false;
                };
            }
        }
    },
    methods: {
        openTradeAi(){
            this.tradeLoading = true;
            this.tradeAiDialog = true;
            let token = this.userInfo.traderToken;
            this.tradeAiUrl = `http://122.144.179.60:1111/#/mainDialog?&token=${token}`;
        },
        onTranslate(){
            this.tradeLoading = true;
            this.tradeAiDialog = true;
            let token = this.userInfo.traderToken;
            let sendValue = encodeURIComponent(this.translateContent);
            this.tradeAiUrl = `http://122.144.179.60:1111/#/mainDialog?&token=${token}&sendType=translate&sendValue=${sendValue}`;
        },

        //切换语言
        changeLanguage(value) {
            console.log(value);
            this.$i18n.locale = value;
            localStorage.setItem('languageSet', this.$i18n.locale);
        },
        toTest() {
            this.handleSelect(`/homepage/multilanguage`);
            this.$router.push(`/homepage/multilanguage`);
        },
        themeSwitch(){
            this.$router.push(`/homepage/stylePage`);
        },
        openJianZhan(){
            this.$axios.get(`${this.$authUrl}/api/User`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    window.open(data);
                }
            })
        },
        openAlibaba(){
            window.open(`https://crm.qixinerp.com/shop/#/search?u=${this.userInfo.uKey}`);
        },

        clickNav(index) {
            this.handleSelect(index);
            this.$router.push(index);
        },

        //Tab相关函数
        handleSelect(index) {
            this.choosePath = index;
            let alist = this.originalList.filter(element => index.indexOf(element.path) > -1);
            if (alist.length > 0) {
                this.chooseTitle = alist[0].title;
            }
            // 打开的tab集合中：不存在选中的导航是需要添加一个新的tab
            const boole = this.chooseTabs.some(item => item.path == this.choosePath);
            if (!boole) {
                this.chooseTabs.push({
                    title: this.chooseTitle,
                    path: this.choosePath
                });
            }
        },
        removeTab(item) {
            let tabs = this.chooseTabs;
            // 关闭点击的tab
            this.chooseTabs = tabs.filter((tab) => tab.path !== item.path);
            if (this.choosePath === item.path) {
                // 当前页面的tab == 关闭点击的tab 需要重新跳转路由
                tabs.forEach((tab, index) => {
                    if (tab.path === item.path) {
                        let nextTab = tabs[index + 1] || tabs[index - 1];
                        if (nextTab) {
                            this.choosePath = nextTab.path;
                            this.chooseTitle = nextTab.title;
                            this.$router.push(this.choosePath);
                        }
                    }
                });
            }
        },
        clickTab(item) {
            this.chooseTitle = item.title;
            this.choosePath = item.path;
            this.$router.push(this.choosePath);
        },
        initTab() {
            this.choosePath = this.$route.fullPath;
            let alist = this.originalList.filter(element => this.choosePath.indexOf(element.path) > -1);
            if (alist.length > 0) {
                this.chooseTitle = alist[0].title;
            }
            if (this.choosePath.indexOf("/homepage/workbench") < 0) {
                this.chooseTabs.push({
                    title: this.chooseTitle,
                    path: this.choosePath
                });
            }
        },

        //退出登录
        logOut() {
            localStorage.removeItem("token");
            localStorage.removeItem("userInfo");
            this.$cookieStore.delCookie("token");
            this.$router.push('/login');
        },

        //获取用户信息
        getUserInfo() {
            this.$axios.get(`${this.$authUrl}/api/User/Get`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    localStorage.setItem("userInfo", JSON.stringify(data));
                    data.loginTime = data.loginTime.replace("T", " ").substring(5, 16);
                    data.mailAddress = "<" + data.mailAddress + ">";
                    this.userInfo = data;
                    // 是否关注公众号
                    if (data.isBindPublic === false && !localStorage.getItem('isCloseWx')) {
                        this.publicVisible = true;
                        this.createCode();
                    }

                    this.$axios({
                        method: 'GET',
                        url: this.$OAService + '/GetTotalIntegral',
                        headers: {
                            AccessToken: encrypted
                        },
                        params: {
                            ukey: JSON.parse(window.localStorage.getItem('userInfo'))['uKey'],
                            project: 'crm'
                        }
                    }).then(res => {
                        // console.log(res.data);
                        this.jifenTotal = res.data;
                    })
                }
            });
            //获取用户系统配置
            this.$axios.get(`${this.$customerUrl}/v1/Config/GetUserSysConfig`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    localStorage.setItem('sysConfig',JSON.stringify(data));
                    if(data && data.theme){
                        // console.log('userConfig:',data);
                        let value = data.theme ? data.theme : "#E1902B";
                        localStorage.setItem('theme', value);
                        document.getElementsByTagName('body')[0].style.setProperty('--themeColor', value);
                        let lightColor = "";
                        if(value == "#2E4756"){ lightColor = "#4B6A7D"; }
                        if(value == "#3152AF"){ lightColor = "#5F7AC7"; }
                        if(value == "#E78438"){ lightColor = "#E4AD82"; }
                        if(value == "#33A85E"){ lightColor = "#75C794"; }
                        if(value == "#141414"){ lightColor = "#434343"; }
                        if(value == "#606266"){ lightColor = "#909399"; }
                        if(value == "#E1902B"){
                            lightColor = "#F0D9C9";
                            this.isColourless = 1;
                        }else if(value == "#3E3E3E"){
                            lightColor = "#EEEEEE";
                            this.isColourless = 2;
                        }else{
                            this.isColourless = 0;
                        }
                        document.getElementsByTagName('body')[0].style.setProperty('--lightColor', lightColor);
                    }
                }
            });
        },
        themeChange(value){
            if(value == "#E1902B"){
                this.isColourless = 1;
            }else if(value == "#3E3E3E"){
                this.isColourless = 2;
            }else{
                this.isColourless = 0;
            }
        },

        createCode() {
            this.codeLoading = true;
            this.$axios.get(`${this.$authUrl}/WX/Create`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.ticket = data.ticket;
                    this.imgCodeSrc = `${this.$authUrl}/WX/GetQrCode?ticket=${this.ticket}`;
                    this.codeLoading = false;
                    let num = 0;
                    timer = setInterval(() => {
                        num++
                        this.CheckQrLogin();
                        if (num >= 60) {
                            clearInterval(timer);
                        }
                    }, 1000);
                }
            });
        },
        CheckQrLogin() {
            this.$axios.get(`${this.$authUrl}/WX/CheckQrLogin?ticket=${this.ticket}&ukey=${this.userInfo.uKey}`).then((res) => {
                const { isSuccess, data, errorMessage } = res;
                if (isSuccess) {
                    clearInterval(timer);
                    if (data.isBind) {
                        this.publicVisible = false;
                        this.$message({ message: "关注成功", type: 'success' });
                    }
                }
                if(errorMessage == "已绑定其它账号不能重复绑定"){
                    clearInterval(timer);
                }
            });
        },
        closePublic() {
            clearInterval(timer);
            this.publicVisible = false;
            localStorage.setItem('isCloseWx',true);
        },

        toPersonalInfo() {
            this.handleSelect(`/homepage/personalInfo`);
            this.$router.push(`/homepage/personalInfo`);
        },
        toLoginLog() {
            this.handleSelect(`/homepage/loginLog`);
            this.$router.push(`/homepage/loginLog`);
        },
        toCalendar() {
            this.handleSelect(`/homepage/calendar`);
            this.$router.push(`/homepage/calendar`);
        },
        toSet() {
            this.handleSelect(`/settingIndex`);
            this.$router.push(`/settingIndex`);
        },
        toDisk() {
            // 网盘V2
            this.handleSelect(`/cloudDoc`);
            this.$router.push(`/cloudDoc`);
            // 网盘V1
            // this.handleSelect(`/diskIndex`);
            // this.$router.push(`/diskIndex`);

        },
        toTeamManage() {
            this.handleSelect(`/teamManage`);
            this.$router.push(`/teamManage`);
        },
        toOpenPlatform() {
            let routeData = this.$router.resolve({
                path: "/openDoc",
            });
            window.open(routeData.href, "_blank");
        },
        toUpgrade() {
            let routeData = this.$router.resolve({
                path: "/SelectProduct",
            });
            window.open(routeData.href, "_blank");
        },
        toMyPay() {
            let routeData = this.$router.resolve({
                path: "/PurchaseRecord",
            });
            window.open(routeData.href, "_blank");
        },
        toMyInvoice() {
            let routeData = this.$router.resolve({
                path: "/MyInvoice",
            });
            window.open(routeData.href, "_blank");
        },
        toStaff() {
            this.handleSelect(`/staff/staffList`);
            this.$router.push(`/staff/staffList`);
        },
        toWorkOrder() {
            this.handleSelect(`/workOrder/workOrderList`);
            this.$router.push(`/workOrder/workOrderList`);
        },
        toJiFen() {
            let routeData = this.$router.resolve({
                path: "/JiFen",
            });
            window.open(routeData.href, "_blank");
        },
        //客服中心
        openService() {
            const { mailAddress, phone, uKey } = this.userInfo;
            let url = `https://yzf.qq.com/xv/web/static/chat/index.html?sign=37ef9b97877604937445cdeb4ab2b2347cf4c2a1a8fda5d26628aac35a36f5a4937b415c45c8c72e35c204a2b1bc26079ef2b3&uid=${uKey}&c1=${mailAddress}&c2=${phone}`
            window.open(url);
            // window.yzf && window.yzf.init({
            //     sign: "37ef9b97877604937445cdeb4ab2b2347cf4c2a1a8fda5d26628aac35a36f5a4937b415c45c8c72e35c204a2b1bc26079ef2b3",
            //     uid: uKey,
            //     data: {
            //         c1: mailAddress,
            //         c2: phone,
            //         c3: "",
            //         c4: "",
            //         c5: "",
            //     },
            //     selector: "",
            // });
            // window.yzf.close(); 关闭已打开的回话窗口
        },
        //企芯助手
        toInstallClient(){
            let routeData = this.$router.resolve({
                path: "/clientInstall",
            });
            window.open(routeData.href, "_blank");
        },
        //AI聊天
        toChatAI(){
            let routeData = this.$router.resolve({
                path: "/ChatAI",
            });
            window.open(routeData.href, "_blank");
        },

        getRemind() {
            this.$axios.get(`${this.$customerUrl}/WorkBench/GetRemind?type=0`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    data.forEach(element => {
                        element.isBeoverdue = element.isBeoverdue ? "已过期" : "";
                        let message = element.remindTime + " " + element.isBeoverdue;
                        setTimeout(() => {
                            this.$notify({
                                iconClass: `icontype${element.scheduleType}`,
                                title: element.remindContent,
                                message: message,
                                position: 'bottom-right',
                                duration: 0,
                            });
                        }, 500);
                    });
                }
            })
        },

        //推送公司提醒
        pushRemind(){
            this.$axios.get(`${this.$customerUrl}/WorkBench/GetPushCompanyRemind`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess && data) {
                    this.pushTitle = `企芯AI为你推荐${data}家采购商`;
                    this.getPushList();
                    // const h = this.$createElement;
                    // const notifyContent = h('div',{
                    //     style: 'cursor: pointer;',
                    //     on: { click: this.getPushList }
                    // }, `有${data}家公司待领取,点击查看!`);
                    // this.$notify({
                    //     iconClass: `icontype0`,
                    //     title: '推送提醒',
                    //     message: notifyContent,
                    //     position: 'bottom-right',
                    //     duration: 0,
                    // });
                }
            })
        },
        getPushList(){
            this.pushVisible = true;
            this.$axios.get(`${this.$customerUrl}/WorkBench/GetPushCompanys`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.pushCompanyList = data.pushCustomers;
                    this.pushDate = data.date;
                }
            })
        },
        onReceive(){
            this.btnLoading = true;
            this.$axios.post(`${this.$customerUrl}/Company/AcceptPushCompany`, this.pushCompanyList).then((res) => {
                const { isSuccess, data } = res;
                this.btnLoading = false;
                if (isSuccess) {
                    this.$message({message: data ,type: 'info'});
                    this.pushVisible = false;
                    this.$router.push(`/customerManage/myCustomer`);
                }
            })
        },
        onRefuse(){
            this.$confirm('取消后本次推荐客户将失效?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.btnLoading = true;
                this.$axios.get(`${this.$customerUrl}/Company/RejectPushCompany`).then((res) => {
                    const { isSuccess } = res;
                    this.btnLoading = false;
                    if (isSuccess) {
                        this.pushVisible = false;
                    }
                })
            }).catch(() => { });
        },

    },
    created(){
        let themeValue = localStorage.getItem("theme");
        if(themeValue == "#E1902B"){
            this.isColourless = 1;
        }else if(themeValue == "#3E3E3E"){
            this.isColourless = 2;
        }else{
            this.isColourless = 0;
        }
    },
    mounted() {

        this.initTab();

        this.getUserInfo();

        //检测用户token是否存在
        tokenDetector = setInterval(() => {
            let token = localStorage.getItem("token");
            //不存在返回登录页面
            if (!token) {
                this.$msgbox({
                    closeOnClickModal: false,
                    showClose: false,
                    title: '登录失效',
                    message: '登录信息失效，请重新登录',
                    confirmButtonText: '确认',
                    callback: () => {
                        this.$router.push('/login');
                    },
                });
                clearInterval(tokenDetector);
            }
        }, 5000);

        //日程通知
        this.getRemind();
        scheduleDetector = setInterval(() => {
            this.getRemind();
        }, 10000);

        //推送公司提醒
        this.pushRemind();

        //获取公司系统配置
        this.$axios.get(`${this.$customerUrl}/v1/Config/GetCompanyConfig`).then((res) => {
            const { isSuccess, data } = res;
            if (isSuccess) {
                localStorage.setItem('comConfig',JSON.stringify(data));
            }
        });
     
    },
    destroyed() {
        clearInterval(timer);
        clearInterval(tokenDetector);
        clearInterval(scheduleDetector);
    }
};
</script>

<style lang="scss" scoped>
@import '@/scss/common.scss';

// /deep/ .el-dialog__body{
    // padding: 0;
// }
.aiLogo{
    position: absolute;
    bottom: 16px;
    right: 16px;
    cursor: pointer;
    z-index: 99;
}

.aside1 {
    background-color: $themeColor;
    scrollbar-width: none;
    -ms-overflow-style: none;
    padding: 10px 0;
    .logoImg {
        margin-left: 18px;
        margin-bottom: 20px;
    }

    .navItem {
        margin: auto;
        margin-top: 10px;
        cursor: pointer;
        width: 50px;
        height: 50px;
        color: #FFFFFF;
        font-size: 12px;
        border-radius: 4px 4px 4px 4px;
        text-align: center;

        .navImg {
            margin-top: 5px;
        }

        &:hover {
            background-color: $lightColor;
        }
    }

    .active {
        background-color: $lightColor;
    }
}
.aside2 {
    background-color: #FFFFFF;
    scrollbar-width: none;
    -ms-overflow-style: none;
    padding: 10px 0;
    .logoImg {
        margin-left: 18px;
        margin-bottom: 20px;
    }

    .navItem {
        margin: auto;
        margin-top: 10px;
        cursor: pointer;
        width: 50px;
        height: 50px;
        color: $themeColor;
        font-size: 12px;
        border-radius: 4px 4px 4px 4px;
        text-align: center;

        .navImg {
            margin-top: 5px;
        }

        &:hover {
            background-color: $lightColor;
        }
    }

    .active {
        background-color: $lightColor;
    }
}
.aside1::-webkit-scrollbar {
    display: none;
}
.aside2::-webkit-scrollbar {
    display: none;
}

.header {
    width: calc(100vw - 70px);
    height: 50px;
    background: #F9F9FA;
    overflow: hidden;

    .tabList {
        width: calc(100vw - 310px);
        padding-top: 10px;
        height: 40px;
        float: left;
        overflow: hidden;

        .tabItem {
            float: left;
            line-height: 40px;
            padding: 0 15px;
            border: 1px solid #F5F5F5;
            border-radius: 4px 4px 0px 0px;
            margin-left: 5px;
            font-size: 14px;
            font-weight: 700;
            color: #2E4756;
            cursor: pointer;
        }

        .active {
            background: #EFF0F3;
        }
    }

    .rightOption {
        width: 280px;
        height: 50px;
        float: right;
        box-sizing: border-box;
        padding: 15px;
        position: relative;

        .optionImg {
            margin-right: 15px;
            cursor: pointer;
            display: inline-block;
        }

        .block {
            position: absolute;
            z-index: 9;
            right: 20px;
            top: 10px;
            cursor: pointer;
        }
    }

    .otherOption{
        float: right;
        margin-top: 12px;
        padding: 0 16px;
        height: 24px;
        line-height: 24px;
        font-size: 12px;
        background: rgba(144,147,153,0.1);
        border-radius: 12px 12px 12px 12px;
        border: 1px solid #909399;
        color: #909399;
        text-align: center;
        cursor: pointer;
        img{
            vertical-align: middle;
            padding-right: 5px;
        }
    }
}

.content {
    height: calc(100vh - 80px);
    padding: 15px;
    background: #EBEBEE;
    overflow: auto;
}

.clickItem {
    width: 80px;
    margin: auto;
    height: 28px;
    line-height: 28px;
    text-align: center;
    cursor: pointer;
    color: #666;

    &:hover {
        color: $themeColor;
        background-color: #e6f7ff;
    }
}

.setMealName {
    padding: 0 10px;
    width: 40px;
    text-align: center;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    color: #A26B0F;
    background: linear-gradient(90deg, #FEE1B2 0%, #F0B553 100%);
    border-radius: 10px;
}
</style>
