<template>
    <div>
        <InterfaceName :interfaceName="interfaceName" />
        <InterfaceSpe :requestUrl="requestUrl" :requestMethod="requestMethod" />
        <InputParam :inputTableData="inputTableData" />
        <ReturnParam :returnObj="returnObj" />
    </div>
</template>

<script>
import InterfaceName from "../Acomponents/InterfaceName.vue";
import InterfaceSpe from "../Acomponents/InterfaceSpe.vue";
import InputParam from "../Acomponents/InputParam.vue";
import ReturnParam from "../Acomponents/ReturnParam.vue";

export default {
    components: { InterfaceName, InterfaceSpe, InputParam, ReturnParam },
    data() {
        return {
            interfaceName: "添加跟进记录",
            requestUrl: "https://crm.qixinerp.com/open/Customer/AddFollowRecord",
            requestMethod: "POST",
            inputTableData: [],
            returnObj: {},
        };
    },
    methods: {},
    mounted() {
        this.inputTableData = [
            {paramName: "customerKey", valueType: "string", explain: "客户key", required: true},
            {paramName: "contactPerson", valueType: "string", explain: "联系人名称", required: false},
            {paramName: "followType", valueType: "string", explain: "跟进类型", required: false},
            {paramName: "followContent", valueType: "string", explain: "跟进内容", required: false},
            {paramName: "nextFollowTime", valueType: "string", explain: "下次跟进时间", required: false},
            {paramName: "location", valueType: "string", explain: "位置", required: false},
            {paramName: "annex", valueType: "array", explain: "附件集合", required: false},
            {paramName: "annex:name", valueType: "string", explain: "附件名称", required: false},
            {paramName: "annex:url", valueType: "string", explain: "附件地址", required: false},
            {paramName: "annex:size", valueType: "string", explain: "附件大小", required: false},
        ]
        this.returnObj = {
            isSuccess: true,
            errorMessage: null,
            data: true,
        };
    },
};
</script>

<style lang="scss" scoped>

</style>