<template>
    <div class="OrderNav">
        <div class="navItem" @click="chooseNav('/orderManage/orderList')" :class="{ active: currentPath === '/orderManage/orderList' }">我的订单</div>
        <div class="navItem" @click="chooseNav('/quotation/quotationList')" :class="{ active: currentPath === '/quotation/quotationList' }">我的报价单</div>
        
        <!-- <div class="navItem" @click="chooseNav('/purchase/purchaserManage')" :class="{ active: currentPath === '/purchase/purchaserManage' }">采购商</div> -->
        <!-- <div class="navItem" @click="chooseNav('/purchase/recordList')" :class="{ active: currentPath === '/purchase/recordList' }">采购记录</div> -->
    </div>
</template>

<script>
export default {
    props: ["currentPath"],
    data() {
        return {

        };
    },
    methods: {
        chooseNav(index) {
            this.$emit("chooseNav",index);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/scss/common.scss';
.OrderNav {
    width: 200px;
    height: calc(100vh - 140px);
    background: #ffffff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    .oneClass{
        padding: 5px 15px;
        height: 24px;
        line-height: 24px;
        font-size: 12px;
        color: #aaa;
    }

    .navItem {
        cursor: pointer;
        padding: 0 15px;
        height: 40px;
        line-height: 40px;
        color: #000;
        font-size: 14px;
        &.active {
            color: #fff;
            background: $themeColor;
        }
        img{
            vertical-align: text-bottom;
            margin-right: 5px;
        }
    }
}
</style>