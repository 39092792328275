<template>
    <div style="overflow: hidden;" v-loading="suppliersRanking.tableLoading">
        <div id="suppliersDom" style="width:100%;height:300px;"></div>
        <div style="display: flex;justify-content: flex-end;padding: 0 20px;">
            <el-button type="primary" plain size="small" @click="batchAddMyClue" :loading="btnLoading"
             :disabled="selectionList.length == 0">加入我的线索</el-button>
        </div>
        <el-table :data="suppliersRanking.tableData" @selection-change="selectionChange" ref="multipleTable">
            <el-table-column type="selection" width="45"></el-table-column>
            <el-table-column prop="name" label="公司名称">
                <template slot-scope="scope">
                    <div v-if="scope.row.name == 'N/A'">{{scope.row.name}}</div>
                    <div v-else style="cursor: pointer;" class="textOverFour commonColor" @click="toCompanyDetail(scope.row.name,'exporter','','')" v-html="scope.row.name"></div>
                </template>
            </el-table-column>
            <el-table-column prop="frequency" label="频次"></el-table-column>
            <el-table-column prop="weight" label="重量"></el-table-column>
            <el-table-column prop="quantity" label="数量"></el-table-column>
            <el-table-column prop="value" label="金额"></el-table-column>
        </el-table>
        <el-pagination hide-on-single-page background layout="total,prev,pager,next" :total="suppliersRanking.totalItems" style="float:right;margin:15px 20px;" :current-page.sync="suppliersRanking.pageIndex" @current-change="getSuppliers"></el-pagination>
    </div>
</template>

<script>
import * as echarts from "echarts";
var suppliersChart = null;
export default {
    props: ["searchField"],
    data() {
        return {
            suppliersRanking:{
                tableLoading: false,
                tableData: [],
                pageIndex: 1,
                totalItems: 0,
            },
            //批量加入我的线索
            btnLoading: false,
            selectionList: [],
        };
    },
    methods: {
        //获取供应商排名
        getSuppliers(pageIndex){
            this.suppliersRanking.pageIndex = pageIndex;
            this.suppliersRanking.tableLoading = true;
            this.$axios.post(`${this.$searchUrl}/Trade/Ranking/Exporter`, {
                ...this.searchField,
                pageIndex: pageIndex,
            }).then((res) => {
                const { isSuccess, data, errorMessage } = res;
                this.suppliersRanking.tableLoading = false;
                if (isSuccess) {
                    if(errorMessage == "无数据"){
                        this.$message({ message: '搜索暂无数据返回!', type: 'warning'});
                        return false;
                    }
                    // console.log(data.rankings);
                    data.rankings.forEach(item =>{
                        item.frequency = item.frequency.toFixed(2);
                        item.weight = item.weight.toFixed(2);
                        item.quantity = item.quantity.toFixed(2);
                        item.value = item.value.toFixed(2);
                    })
                    this.suppliersRanking.tableData = data.rankings;
                    this.suppliersRanking.totalItems = data.total;
                    if(pageIndex == 1){
                        this.renderChart(data.rankings,"suppliersDom","供应商TOP10排名");
                    }
                }
            });
        },
        //渲染图表
        renderChart(rankings,dom,title){
            let Top10 = [];
            rankings.forEach(item =>{
                Top10.push({name: item.name, value: item.frequency})
            })
            suppliersChart = echarts.init(document.getElementById(dom));
            suppliersChart.setOption(this.commonOption(Top10,title));
            suppliersChart.resize();
        },
        //公用的图表配置
        commonOption(Top10,title){
            return {
                title: {
                    text: title,
                    textStyle: {
                        fontSize: 14,
                    },
                    left: "center",
                },
                tooltip: {
                    trigger: "item",
                    formatter: "{b} : {c} ({d}%)",
                },
                series: [
                    {
                        type: "pie",
                        radius: "50%",
                        center: ["50%", "50%"],
                        data: Top10,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: "rgba(0, 0, 0, 0.5)",
                            },
                        },
                    },
                ],
            };
        },

        //跳转公司详情
        toCompanyDetail(companyName,isImpExp,dataCountryCn,threeEnCountryCode){
            companyName = companyName.replace(/<mark>/g,'').replace(/<\/mark>/g,'');//将mark标签替换掉空字符
            companyName = escape(companyName);
            let routeData = this.$router.resolve({
                path: "/AkeySearch/CompanyDetails",
                query: { 
                    companyName: companyName,
                    isImpExp: isImpExp,
                    ie: this.searchField.ie,
                    country: dataCountryCn,
                    countryCode: threeEnCountryCode,
                },
            });
            window.open(routeData.href, "_blank");
        },


        //批量加入我的线索
        selectionChange(value) {
            this.selectionList = value;
        },
        batchAddMyClue(){
            let submitList = [];
            this.selectionList.forEach(item =>{
                submitList.push({
                    "clueSource": 1, //海关数据
                    "clueRelationKey": "",
                    "companyName": item.name,
                    "country": "",
                    "legalPerson": "",
                    "contactNumber": "",
                    "registrationTime": "",
                    "employeesNumber": 0,
                    "officialWebsite": "",
                    "corporateEmail": "",
                    "businessAddress": "",
                    "companyProfile": "",
                    "industrys": [],
                })
            })
            this.btnLoading = true;
            this.$axios.post(`${this.$clueUrl}/MyClue/batchAddMyClue`, submitList).then((res) => {
                const { isSuccess } = res;
                this.btnLoading = false;
                if (isSuccess) {
                    this.$message({ message: '添加成功', type: 'success'});
                    this.selectionList = [];
                    this.$refs.multipleTable.clearSelection();
                }
            });
        },

    },
    mounted() {

    },
};
</script>

<style lang="scss" scoped>
</style>