<!-- 当内容过长被隐藏时显示 tooltip -->

<script>
import { ref, onMounted, onUpdated, defineComponent } from '@vue/composition-api'

function getIsShowTooltip(textContent) {
  const container = textContent.value.parentElement
  const containerWidth = container.getClientRects()[0].width - (parseInt(container.style.paddingLeft || 0)) - (parseInt(container.style.paddingRight || 0))
  const textContentWidth = textContent.value.getClientRects()[0].width
  if (textContentWidth > containerWidth) {
    return true
  }
  return false
}

export default defineComponent({
  props: {
    text: {
      type: String,
      required: true
    },
    width: {
      type: String,
      required: true
    },
    customeStyle: {
      required: false
    }
  },
  setup() {
    const textContent = ref(null)
    const showTooltip = ref(false)
    onMounted(() => {
      showTooltip.value = getIsShowTooltip(textContent)
    })
    onUpdated(() => {
      showTooltip.value = getIsShowTooltip(textContent)
    })
    return {
      textContent,
      showTooltip
    }
  }
})
</script>

<template>
  <el-tooltip :disabled="!showTooltip" effect="light" placement="top-start" :content="$props.text">
    <p class="show-tooltip-overflow-text p" :style="[{ width: $props.width }, $props.customeStyle || {}]" style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap">
      <span ref="textContent" class="show-tooltip-overflow-text span">{{ $props.text }}</span>
    </p>
  </el-tooltip>
</template>

<style scoped lang="scss">
.show-tooltip-overflow-text {
  &.span {
    padding: 0;
  }
}
</style>
