<template>
<div>
    <div v-if="ie=='i'">
        <!-- 尼日利亚(NGA)进口提单明细 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th>进口商代码</th>
                        <td colspan="3">{{billInfo.ImporterCode}}</td>
                    </tr>
                    <tr>
                        <th width="25%">原产国</th>
                        <td width="25%">{{billInfo.Country}}</td>
                        <th width="25%">项目编号</th>
                        <td width="25%">{{billInfo.ItemNo}}</td>
                    </tr>
                    <tr>
                        <th>进口港</th>
                        <td>{{billInfo.PortName}}</td>
                        <th>SGD_NUMBER</th>
                        <td>{{billInfo.SGDNumber}}</td>
                    </tr>
                    <tr>
                        <th>出口国</th>
                        <td>{{billInfo.ExportCountry}}</td>
                        <th>注册日期</th>
                        <td>{{billInfo.RegisterDate}}</td>
                    </tr>
                    <tr>
                        <th>收据号</th>
                        <td>{{billInfo.ReceiptNumber}}</td>
                        <th>发票日期</th>
                        <td>{{billInfo.ReceiptDate}}</td>
                    </tr>
                    <tr>
                        <th>集装箱数量</th>
                        <td>{{billInfo.ContainerQuantity}}</td>
                        <th>集装箱编号</th>
                        <td>{{billInfo.ContainerNumber}}</td>
                    </tr>
                    <tr>
                        <th>集装箱类型</th>
                        <td>{{billInfo.ContainerType}}</td>
                        <th>章节</th>
                        <td>{{billInfo.Chapter}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
    <div v-if="ie=='e'">
        <!-- 尼日利亚(NGA)出口提单明细 -->
    </div>
</div>
</template>

<script>
export default {
    props:["billInfo","ie"],
    data() {
        return {

        };
    },
    methods: {
        
    },
    mounted() {

    },
};
</script>

<style lang="scss" scoped>
.partItem {
    margin-bottom: 20px;
    /deep/.el-card__header {
        padding: 10px 20px;
        .headTitle {
            display: inline-block;
            padding-left: 10px;
            font-size: 14px;
            border-left: 2px solid var(--themeColor);
            font-weight: bold;
        }
    }
}
table {
    width: 100%;
    border-collapse: collapse;
    tr {
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;
        th,
        td {
            font-size: 12px;
            color: #666;
            height: 40px;
            border: 1px solid #eaeaea;
            text-align: center;
        }
        th {
            background-color: #f5f7fa;
            font-weight: 700;
        }
        .bg {
            background-color: var(--themeColor);
            color: #fff;
            font-weight: 700;
            opacity: 0.8;
        }
    }
}
</style>