<template>
    <el-drawer title="联系人资料" :visible.sync="drawerVisible" :size="460" :with-header="false" @close="$emit('onClose')">
        <div class="title">
            <span>联系人资料</span>
            <i class="el-icon-edit-outline" @click="toEdit"></i>
        </div>
        <div class="editContent" v-if="isEdit">
            <el-form :model="editForm" label-width="100px" size="small" label-position="left">
                <el-form-item label="WhatsApp账号">
                    <el-input v-model="editForm.phone" disabled></el-input>
                </el-form-item>
                <el-form-item label="姓名">
                    <el-input v-model="editForm.name"></el-input>
                </el-form-item>
                <el-form-item label="邮箱">
                    <el-input v-model="editForm.email"></el-input>
                </el-form-item>
                <el-form-item label="所属公司">
                    <el-input v-model="editForm.company"></el-input>
                </el-form-item>
                <el-form-item label="行业">
                    <el-input v-model="editForm.industry"></el-input>
                </el-form-item>
                <el-form-item label="国家">
                    <el-input v-model="editForm.country"></el-input>
                </el-form-item>
                <el-form-item label="所属分组">
                    <el-input v-model="editForm.groupKey"></el-input>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input type="textarea" v-model="editForm.remarks"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="editComplete" :loading="btnLoading">确认</el-button>
                    <el-button @click="isEdit = false;">取消</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="infoContent" v-else>
            <div style="display: flex;margin-bottom: 20px;">
                <div class="touxiang">
                    {{ showInfo.name ? showInfo.name.slice(0, 1) : showInfo.whatsApp.slice(0,1) }}
                </div>
                <div class="nameValue">{{showInfo.name}}</div>
            </div>
            <el-form label-width="100px" size="small" label-position="left">
                <el-form-item label="WhatsApp账号">
                    <span>{{showInfo.whatsApp}}</span>
                </el-form-item>
                <el-form-item label="邮箱">
                    <span>{{showInfo.email}}</span>
                </el-form-item>
                <el-form-item label="所属公司">
                    <span>{{showInfo.company}}</span>
                </el-form-item>
                <el-form-item label="行业">
                    <span>{{showInfo.industry}}</span>
                </el-form-item>
                <el-form-item label="国家">
                    <span>{{showInfo.country}}</span>
                </el-form-item>
                <el-form-item label="所属分组">
                    <span>{{showInfo.groupKey}}</span>
                </el-form-item>
                <el-form-item label="群发次数">
                    <span>{{showInfo.massCount}}次</span>
                </el-form-item>
                <el-form-item label="上次沟通时间">
                    <span>{{showInfo.sendTimeStr}}</span>
                </el-form-item>
                <el-form-item label="备注">
                    <span>{{showInfo.remarks}}</span>
                </el-form-item>
            </el-form>
        </div>
    </el-drawer>
</template>

<script>
export default {
    props: ["drawerVisible","currentInfo"],
    data() {
        return {
            showInfo: {},
            isEdit: false,
            btnLoading: false,
            editForm: {},
        };
    },
    methods: {
        toEdit(){
            this.isEdit = true;
            const {whatsApp,name,remarks,email,company,industry,country,groupKey} = this.showInfo;
            this.editForm = {
                "phone": whatsApp,
                "name": name,
                "remarks": remarks,
                "email": email,
                "company": company,
                "industry": industry,
                "country": country,
                "groupKey": groupKey,
            }
        },
        editComplete(){
            this.btnLoading = true;
            this.$axios({
                method: "POST",
                url: this.$marketUrl + "/WhatsApp/SaveContactInfo",
                data: this.editForm,
            }).then(res =>{
                const {isSuccess} = res;
                this.btnLoading = false;
                this.isEdit = false;
                if(isSuccess){
                    this.$message({ type: 'success', message: '编辑成功!'});
                    this.showInfo.whatsApp = this.editForm.phone;
                    this.showInfo.name = this.editForm.name;
                    this.showInfo.remarks = this.editForm.remarks;
                    this.showInfo.email = this.editForm.email;
                    this.showInfo.company = this.editForm.company;
                    this.showInfo.industry = this.editForm.industry;
                    this.showInfo.country = this.editForm.country;
                    this.showInfo.groupKey = this.editForm.groupKey;
                }
            })
        },
    },
    mounted() {
        
    },
    watch:{
        "currentInfo.whatsApp"(){
            this.showInfo = this.$props.currentInfo;
            // console.log(this.showInfo);
        }
    }
};
</script>

<style lang='scss' scoped>
.title{
    font-size: 16px;
    font-weight: bold;
    color: #3A3A3A;
    height: 40px;
    line-height: 40px;
    padding-left: 20px;
    .el-icon-edit-outline{
        cursor: pointer;
        font-size: 20px;
        margin-left: 10px;
        color: var(--themeColor);
    }
}
.editContent{
    padding: 20px;
}
.infoContent{
    padding: 20px;
}
.touxiang{
    margin-right: 20px;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    border-radius: 100%; 
    background: #447DAC; 
    color: #fff;
    font-size: 17px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
}
.nameValue{
    font-size: 16px;
    font-weight: bold;
    color: #3A3A3A;
    line-height: 40px;
}
</style>