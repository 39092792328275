<template>
    <div class="pageBox">
        <div class="header">
            <el-button type="primary" @click="uploadDialogVisible = true;">上传文件</el-button>
            <!-- <el-button>删 除</el-button> -->
            <el-input class="searchBox" placeholder="请输入搜索内容" v-model="searchValue" prefix-icon="el-icon-search" clearable>
                <el-button slot="append" @click="onSearch">文件搜索</el-button>
            </el-input>
        </div>
        <div class="contentBox" v-loading="boxLoading">
            <div class="categoryChoose">
                <div class="categoryName">类别:</div>
                <el-radio-group v-model="categoryValue" size="small" @change="onSearch">
                    <el-radio-button label="">全部</el-radio-button>
                    <el-radio-button label="营销推广"></el-radio-button>
                    <el-radio-button label="外贸"></el-radio-button>
                    <el-radio-button label="外汇"></el-radio-button>
                    <el-radio-button label="推广资料"></el-radio-button>
                    <el-radio-button label="客群维护"></el-radio-button>
                    <el-radio-button label="产品资料"></el-radio-button>
                </el-radio-group>
            </div>
            <el-empty description="暂无数据" v-if="totalItems == 0"></el-empty>
            <el-row :gutter="12" v-else>
                <el-col :span="4" v-for="(item,index) in cardList" :key="index" style="margin-top:20px;">
                    <div class="cardItem">
                        <el-image :src="item.previewUrl" style="height:150px;width:100%;"></el-image>
                        <div class="describe">{{item.describe}}</div>
                        <div class="bottom">
                            <div class="price">
                                价格：<span style="color:var(--themeColor);">{{item.price}}</span>
                            </div>
                            <!-- <el-link type="primary" :underline="false">查看详情>></el-link> -->
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>

        <!-- 文件集市上传文件 -->
        <el-dialog title="上传文件" :visible.sync="uploadDialogVisible" width="600px">
            <el-form :model="marketSaveFile" label-width="80px" size="small">
                <el-form-item label="类别">
                    <el-radio-group v-model="marketSaveFile.type" size="small">
                        <el-radio-button label="营销推广"></el-radio-button>
                        <el-radio-button label="外贸"></el-radio-button>
                        <el-radio-button label="外汇"></el-radio-button>
                        <el-radio-button label="推广资料"></el-radio-button>
                        <el-radio-button label="客群维护"></el-radio-button>
                        <el-radio-button label="产品资料"></el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="预览图">
                    <el-image v-if="marketSaveFile.previewUrl" style="width: 150px; height: 150px" :src="marketSaveFile.previewUrl"
                     :preview-src-list="[marketSaveFile.previewUrl]"></el-image>
                    <el-upload v-else :action="uploadUrl" :headers="headers" :data="uploadData" list-type="picture-card" :limit="1"
                        :before-upload="beforeUpload" :on-success="imgUploadSuccess">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="描述">
                     <el-input type="textarea" v-model="marketSaveFile.describe"></el-input>
                </el-form-item>
                <el-form-item label="价格">
                    <el-input-number v-model="marketSaveFile.price" :min="0"></el-input-number>
                </el-form-item>
                <el-form-item>
                    <input @change="onInputChange" ref="InputDom" type="file" v-show="false" />
                    <el-button class="uploadBtn" type="primary" @click="uploadClick">上传文件</el-button>
                </el-form-item>
                <el-form-item v-if="progressVisible">
                    <el-progress :text-inside="true" :stroke-width="20" :percentage="progressNumber"></el-progress>
                </el-form-item>
            </el-form>
        </el-dialog>

    </div>
</template>

<script>
import { uploadFile } from './api/upload.js'
export default {
    data() {
        return {
            searchValue: "",
            categoryValue: "",
            boxLoading: false,
            cardList: [],
            pageIndex: 1,
            totalItems: 0,

            // 上传文件
            uploadDialogVisible: false,
            marketSaveFile: {
                fileKey: "",
                type: "营销推广",
                previewUrl: "",
                describe: "",
                price: 0,
            },
            progressVisible: false,
            progressNumber: 0,
            //图片上传
            uploadUrl: `${this.$diskUrl}/OtherFile/Upload`,
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('token'),
            },
            uploadData: {
                folderKey: "0",
                remarkName: "",
                permission: '我的'
            },

        };
    },
    methods: {
        //文件搜索
        onSearch() {
            this.pageIndex = 1;
            this.getMarketFile();
        },

        //图片上传
        beforeUpload(file) {
            var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
            const isImg = testmsg === "png" || testmsg === "jpg" || testmsg === "jpeg";
            const isLt10M = file.size / 1024 / 1024 < 10;
            if (!isImg) {
                this.$message({ message: "上传文件只能是图片!", type: "warning"});
            }
            if (!isLt10M) {
                this.$message({ message: "上传文件大小不能超过10MB!", type: "warning"});
            }
            return isImg && isLt10M;
        },
        imgUploadSuccess(response){
            this.marketSaveFile.previewUrl = response.fileUrl;
        },
        //上传文件
        uploadClick(){
            if(!this.marketSaveFile.previewUrl){
                this.$message({message: "请上传预览图!",type: 'warning'});
                return false;
            }
            if(!this.marketSaveFile.describe){
                this.$message({message: "请填写描述!",type: 'warning'});
                return false;
            }
            if(this.marketSaveFile.price == 0){
                this.$message({message: "价格不能为0!",type: 'warning'});
                return false;
            }
            this.$refs.InputDom.click();
        },
        async onInputChange(event){
            const file = event.target.files[0]
            this.progressVisible = true;
            this.progressNumber = 0;
            const resObj = await uploadFile(file,(number)=>{
                this.progressNumber = number;
            })
            this.progressVisible = false;
            if(resObj){ 
                const resOne = await this.$axios.post(`${this.$diskUrl}/File/SaveFileInfo`,{
                    ...this.uploadData,
                    ...resObj,
                })
                if(!resOne.data.key){
                    this.$message({message: "上传失败!",type: 'warning'});
                    return false;
                }
                this.marketSaveFile.fileKey = resOne.data.key;
                // 保存文件集市
                const resTwo = await this.$axios.post(`${this.$diskUrl}/Market/SaveFile`,this.marketSaveFile)
                if(resTwo.isSuccess){
                    this.uploadDialogVisible = false;
                    this.$message({message: "上传成功",type: 'success'});
                    this.getMarketFile();
                    this.marketSaveFile = {
                        fileKey: "",
                        type: "营销推广",
                        previewUrl: "",
                        describe: "",
                        price: 0,
                    }
                }
            }
        },
        
        //获取集市文件
        getMarketFile(){
            this.boxLoading = true;
            this.$axios.post(`${this.$diskUrl}/Market/GetMarkets`,{
                "type": this.categoryValue,
                "describe": this.searchValue,
                "pageIndex": this.pageIndex,
                "pageSize": 20,
            }).then((res) => {
                this.boxLoading = false;
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.cardList = data.datas;
                    this.totalItems = data.totalItems;
                }
            });
        },
        pageChange(page){
            this.pageIndex = page;
            this.getMarketFile();
        },

    },
    mounted() {
        this.getMarketFile();
    },
};
</script>

<style lang="scss" scoped>
.pageBox {
    padding: 20px;
    .header {
        margin-bottom: 20px;
        overflow: hidden;
        .searchBox {
            width: 500px;
            float: right;
        }
    }
    .contentBox {
        height: calc(100vh - 180px);
        background: #ffffff;
        box-shadow: 0px 1px 6px 1px rgba(0, 0, 0, 0.16);
        padding: 20px;
        box-sizing: border-box;
        .categoryChoose {
            display: flex;
            .categoryName {
                width: 50px;
                height: 32px;
                line-height: 32px;
            }
        }
        .cardItem {
            background: #ffffff;
            box-shadow: 0px 6px 10px 1px rgba(0, 0, 0, 0.13);
            border: 1px solid #eaeef5;
            padding: 10px;
            .describe {
                height: 56px;
                line-height: 28px;
                color: #666666;
                overflow: hidden;
                -webkit-line-clamp: 2;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
            }
            .bottom {
                font-size: 14px;
                margin-top: 10px;
                display: flex;
                justify-content: space-between;
            }
        }
    }
}
</style>