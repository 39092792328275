<template>
    <div>
        <InterfaceName :interfaceName="interfaceName" />
        <InterfaceSpe :requestUrl="requestUrl" :requestMethod="requestMethod" />
        <InputParam :inputTableData="inputTableData" />
        <ReturnParam :returnObj="returnObj" />   
    </div>
</template>

<script>
import InterfaceName from "../Acomponents/InterfaceName.vue";
import InterfaceSpe from "../Acomponents/InterfaceSpe.vue";
import InputParam from "../Acomponents/InputParam.vue";
import ReturnParam from "../Acomponents/ReturnParam.vue";

export default {
    components: { InterfaceName, InterfaceSpe, InputParam, ReturnParam },
    data() {
        return {
            interfaceName: "保存采购记录",
            requestUrl: "https://crm.qixinerp.com/open/Procure/PurchasingRecord/Save",
            requestMethod: "POST",
            inputTableData: [],
            returnObj: {},
        };
    },
    methods: {
        
    },
    mounted() {
        this.inputTableData = [
            {paramName: "key", valueType: "string", explain: "唯一key", required: true},
            {paramName: "procurementPlan", valueType: "string", explain: "采购计划", required: true},
            {paramName: "purchaseNo", valueType: "string", explain: "采购编号", required: true},
            {paramName: "purchaseDate", valueType: "string", explain: "采购日期", required: true},
            {paramName: "deliveryDate", valueType: "string", explain: "交货日期", required: true},
            {paramName: "followerKey", valueType: "string", explain: "跟进人key", required: true},
            {paramName: "followerName", valueType: "string", explain: "跟进人", required: true},
            {paramName: "manufacturerKey", valueType: "string", explain: "厂商key", required: true},
            {paramName: "manufacturerName", valueType: "string", explain: "厂商名称", required: true},
            {paramName: "manufacturerNo", valueType: "string", explain: "厂商编号", required: true},  
            {paramName: "contactsKey", valueType: "string", explain: "联系人key", required: true},
            {paramName: "settlementCurrency", valueType: "string", explain: "交易货币", required: false},

            {paramName: "purchasingProducts", valueType: "array", explain: "产品", required: true},
            {paramName: "purchasingProducts:productKey", valueType: "string", explain: "产品key", required: true},
            {paramName: "purchasingProducts:productNo", valueType: "string", explain: "产品编号", required: true},
            {paramName: "purchasingProducts:productName", valueType: "string", explain: "产品名称", required: true},
            {paramName: "purchasingProducts:specifications", valueType: "string", explain: "产品规格", required: true},
            {paramName: "purchasingProducts:productDescription", valueType: "string", explain: "产品描述", required: true},
            {paramName: "purchasingProducts:totalPrice", valueType: "number", explain: "总价格", required: true},
            {paramName: "purchasingProducts:num", valueType: "integer", explain: "数量", required: true},
            {paramName: "purchasingProducts:measureUnit", valueType: "string", explain: "计量单位", required: true},
            {paramName: "purchasingProducts:otherExpenses", valueType: "number", explain: "其它费用", required: false},
            {paramName: "purchasingProducts:currency", valueType: "string", explain: "货币", required: true},
            {paramName: "purchasingProducts:remarks", valueType: "string", explain: "备注", required: false},
            {paramName: "purchasingProducts:exchangeRate", valueType: "string", explain: "汇率", required: true},
            {paramName: "purchasingProducts:cnyTotalPrice", valueType: "number", explain: "人民币总价格", required: true},
            {paramName: "purchasingProducts:unitPrice", valueType: "number", explain: "单价", required: true},
     
            {paramName: "tradeExpenses", valueType: "array", explain: "贸易费用", required: false},
            {paramName: "tradeExpenses:project", valueType: "string", explain: "项目", required: false},
            {paramName: "tradeExpenses:totalPrice", valueType: "number", explain: "金额", required: false},
            {paramName: "tradeExpenses:currency", valueType: "string", explain: "货币", required: false},
            {paramName: "tradeExpenses:exchangeRate", valueType: "string", explain: "汇率", required: false},
            {paramName: "tradeExpenses:cnyTotalPrice", valueType: "number", explain: "人民币金额", required: false},
            {paramName: "tradeExpenses:remarks", valueType: "string", explain: "备注", required: false},
        ]
        this.returnObj = {
            "isSuccess": true,
            "errorMessage": null,
            "data": "32b25b9e26e9468ba748c26bc8f49660",
        }
    },
};
</script>

<style lang="scss" scoped>

</style>