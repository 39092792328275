<template>
    <div class="entryWrite">
        <div class="title">加入企芯CRM</div>
        <el-form class="content" :model="entryForm" label-width="80px" size="small">
            <el-form-item label="姓名" required class="formItem">
                <el-input v-model="entryForm.name"></el-input>
            </el-form-item>
            <el-form-item label="性别" required class="formItem">
                <el-radio-group v-model="entryForm.sex" style="padding-left:15px;">
                    <el-radio :label="1">男</el-radio>
                    <el-radio :label="0">女</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="出生日期" required class="formItem">
                <el-date-picker v-model="entryForm.birthday" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
            </el-form-item>
            <el-form-item label="手机号" required class="formItem">
                <el-input v-model="entryForm.phone"></el-input>
            </el-form-item>
            <!-- <el-form-item label="邮箱" class="formItem">
                <el-input v-model="entryForm.email"></el-input>
            </el-form-item> -->
        </el-form>
        <el-button class="saveBtn" type="primary" size="small" @click="onSave" :disabled="isFinish">保 存</el-button>
        <div class="errorMsg" v-show="errorMsg">{{errorMsg}}</div>
        <div class="successMsg" v-show="isFinish">提交成功</div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            entryForm: {
                "key": "",
                "name": "",
                "sex": 1,
                "birthday": "1996-01-01",
                "phone": "",
                "email": ""
            },
            errorMsg: "",
            isFinish: false,
        };
    },
    methods: {
        onSave(){
            const {name,birthday,phone} = this.entryForm;
            if(!name){ this.errorMsg = "请填写姓名"; return false;}
            if(!birthday){ this.errorMsg = "请选择出生日期"; return false;}
            if(!phone){ this.errorMsg = "请填写手机号"; return false;}
            this.$axios.post(`${this.$personnelUrl}/Open/AddEntryInformation`,this.entryForm).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    this.isFinish = true;   //提交成功
                }
            })
        },
    },
    mounted() {
        this.entryForm.key = this.$route.query.key;
    },
};
</script>

<style lang="scss" scoped>
.entryWrite{
    width: 100%;
    height: 100%;
    margin: auto;
    background: #fafafa;
    .title{
        // text-align: center;
        font-size: 18px;
        font-weight: bold;
        color: #3A3A3A;
        line-height: 36px;
        padding: 10px 0 10px 170px;
    }
    .content{
        background: #FFF;
        .formItem{
            margin-left: 15px;
            margin-right: 15px;
            padding-top: 5px;
            padding-bottom: 5px;
            border-bottom: 1px solid #EDEDED;
        }
        .el-form-item{
            margin-bottom: 0;
        }
        /deep/.el-input__inner {
            border: none;
        }
    }
    .saveBtn{
        display: flex;
        justify-content: center;
        width: 120px;
        margin: 20px 0 20px 160px;
    }
    .errorMsg{
        margin-top: 20px;
        text-align: center;
        line-height: 32px;
        font-size: 16px;
        color: #f56c6c;
    }
    .successMsg{
        margin-top: 20px;
        text-align: center;
        line-height: 32px;
        font-size: 16px;
        color: #67c23a;
    }
}
</style>