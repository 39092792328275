<template>
    <div>
        <InterfaceName :interfaceName="interfaceName" />
        <InterfaceSpe :requestUrl="requestUrl" :requestMethod="requestMethod" />
        <InputParam :inputTableData="inputTableData" />
        <ReturnParam :returnObj="returnObj" />
    </div>
</template>

<script>
import InterfaceName from "../Acomponents/InterfaceName.vue";
import InterfaceSpe from "../Acomponents/InterfaceSpe.vue";
import InputParam from "../Acomponents/InputParam.vue";
import ReturnParam from "../Acomponents/ReturnParam.vue";

export default {
    components: { InterfaceName, InterfaceSpe, InputParam, ReturnParam },
    data() {
        return {
            interfaceName: "获取用户列表",
            requestUrl: "https://crm.qixinerp.com/open/Staff/GetStaff",
            requestMethod: "POST",
            inputTableData: [],
            returnObj: {},
        };
    },
    methods: {},
    mounted() {
        this.inputTableData = [
            {
                paramName: "name",
                valueType: "string",
                explain: "姓名",
                required: false,
            },
            {
                paramName: "account",
                valueType: "string",
                explain: "账号",
                required: false,
            },
            {
                paramName: "phone",
                valueType: "string",
                explain: "手机号",
                required: false,
            },
            {
                paramName: "pageIndex",
                valueType: "integer",
                explain: "当前页码",
                required: true,
            },
            {
                paramName: "pageSize",
                valueType: "integer",
                explain: "每页条数",
                required: true,
            },
        ];
        this.returnObj = {
            isSuccess: true,
            errorMessage: null,
            data: {
                datas: [
                    {
                        ukey: "f4a2780e5d354c2cb79b6c165b3d7ff3",
                        name: "张三",
                        headPortraitUrl: "",
                        company: "技术部测试账号",
                        companyKey: "52b9b01c3c67494c8114f35e7b912779",
                        isAdmin: false,
                        effectiveStartTime: "2021-12-30",
                        effectiveEndTime: "2022-12-30",
                        account: "zhangshan@lsmaps.com",
                        phone: "183527362782",
                        isStop: false,
                        sex: "男",
                    },
                    {
                        ukey: "7af9a5793d2b413b9945d24143bbd19a",
                        name: "测试",
                        headPortraitUrl: "",
                        company: "技术部测试账号",
                        companyKey: "52b9b01c3c67494c8114f35e7b912779",
                        isAdmin: false,
                        effectiveStartTime: "2021-12-30",
                        effectiveEndTime: "2022-12-30",
                        account: "424241702@qq.com",
                        phone: "15002705177",
                        isStop: false,
                        sex: "男",
                    }, 
                ],
                pageSize: 10,
                pageIndex: 1,
                totalItems: 2,
            },
        };
    },
};
</script>

<style lang="scss" scoped>

</style>