<template>
    <div v-loading="loading">
        <!-- 秘鲁 -->
        <el-descriptions :column="2" border :labelStyle="{width:'15%'}" :contentStyle="{width:'35%'}">
            <el-descriptions-item label="公司代码">{{businessInfo.companyId}}</el-descriptions-item>
            <el-descriptions-item label="公司名">{{businessInfo.companyName}}</el-descriptions-item>
            <el-descriptions-item label="公司名标准">{{businessInfo.companyStd}}</el-descriptions-item>
            <el-descriptions-item label="商业活动说明">{{businessInfo.commercialActivityDescription}}</el-descriptions-item>
            <el-descriptions-item label="区">{{businessInfo.area}}</el-descriptions-item>
            <el-descriptions-item label="区域规格">{{businessInfo.areaSpecification}}</el-descriptions-item>
            <el-descriptions-item label="分区">{{businessInfo.subArea}}</el-descriptions-item>
            <el-descriptions-item label="商业名称">{{businessInfo.commercialName}}</el-descriptions-item>
            <el-descriptions-item label="产品服务">{{businessInfo.productServices}}</el-descriptions-item>
            <el-descriptions-item label="组">{{businessInfo.group}}</el-descriptions-item>
            <el-descriptions-item label="零件组">{{businessInfo.partGroup}}</el-descriptions-item>
            <el-descriptions-item label="家族企业">{{businessInfo.familyCompany}}</el-descriptions-item>
            <el-descriptions-item label="在职员工人数">{{businessInfo.employeesNumber}}</el-descriptions-item>
            <el-descriptions-item label="分行数">{{businessInfo.branchesNumber}}</el-descriptions-item>
            <el-descriptions-item label="注册日期">{{businessInfo.registrationDate}}</el-descriptions-item>
            <el-descriptions-item label="公司规模">{{businessInfo.companySize}}</el-descriptions-item>
            <el-descriptions-item label="国内或国外">{{businessInfo.nationalOrForeign}}</el-descriptions-item>
            <el-descriptions-item label="国外百分比">{{businessInfo.foreignPercentage}}</el-descriptions-item>
            <el-descriptions-item label="州">{{businessInfo.state}}</el-descriptions-item>
            <el-descriptions-item label="州百分比">{{businessInfo.statePercentage}}</el-descriptions-item>
            <el-descriptions-item label="资质认证">{{businessInfo.certification}}</el-descriptions-item>
            <el-descriptions-item label="社会贡献">{{businessInfo.contributorResolution}}</el-descriptions-item>
            <el-descriptions-item label="地址">{{businessInfo.address}}</el-descriptions-item>
            <el-descriptions-item label="区">{{businessInfo.district}}</el-descriptions-item>
            <el-descriptions-item label="省">{{businessInfo.province}}</el-descriptions-item>
            <el-descriptions-item label="部门">{{businessInfo.department}}</el-descriptions-item>
            <el-descriptions-item label="电话号码1">{{businessInfo.phoneNumber1}}</el-descriptions-item>
            <el-descriptions-item label="电话号码2">{{businessInfo.phoneNumber2}}</el-descriptions-item>
            <el-descriptions-item label="电话号码3">{{businessInfo.phoneNumber3}}</el-descriptions-item>
            <el-descriptions-item label="手机号码1">{{businessInfo.mobileNumber1}}</el-descriptions-item>
            <el-descriptions-item label="手机号码2">{{businessInfo.mobileNumber2}}</el-descriptions-item>
            <el-descriptions-item label="传真号码1">{{businessInfo.faxNumber1}}</el-descriptions-item>
            <el-descriptions-item label="传真号码2">{{businessInfo.faxNumber2}}</el-descriptions-item>
            <el-descriptions-item label="[CorporativeEmail]">{{businessInfo.corporativeEmail}}</el-descriptions-item>
            <el-descriptions-item label="网站">{{businessInfo.website}}</el-descriptions-item>
            <el-descriptions-item label="公司Facebook账号">{{businessInfo.facebook}}</el-descriptions-item>
            <el-descriptions-item label="推特">{{businessInfo.twitter}}</el-descriptions-item>
            <el-descriptions-item label="领英">{{businessInfo.linkedin}}</el-descriptions-item>
            <el-descriptions-item label="私人/公共">{{businessInfo.privateOrPublic}}</el-descriptions-item>
            <el-descriptions-item label="2018年排名">{{businessInfo.rank2018}}</el-descriptions-item>
            <el-descriptions-item label="2017年排名">{{businessInfo.rank2017}}</el-descriptions-item>
            <el-descriptions-item label="2016年排名">{{businessInfo.rank2016}}</el-descriptions-item>
            <el-descriptions-item label="2018年总发票金额（最低秘鲁币种）">{{businessInfo.totalInvoice2018InPeruvianSolesMinimum}}</el-descriptions-item>
            <el-descriptions-item label="2018年总发票金额（最高秘鲁币种）">{{businessInfo.totalInvoice2018InPeruvianSolesMaximum}}</el-descriptions-item>
            <el-descriptions-item label="2018年总发票金额（最低美金）">{{businessInfo.totalInvoice2018InUSDMinimum}}</el-descriptions-item>
            <el-descriptions-item label="2018年总发票金额（最高美金）">{{businessInfo.totalInvoice2018InUSDMaximum}}</el-descriptions-item>
            <el-descriptions-item label="2017年总发票金额（最低秘鲁币种）">{{businessInfo.totalInvoice2017InPeruvianSoleSMinimum}}</el-descriptions-item>
            <el-descriptions-item label="2017年总发票金额（最高秘鲁币种）">{{businessInfo.totalInvoice2017InPeruvianSoleSMaximum}}</el-descriptions-item>
            <el-descriptions-item label="2017年总发票金额（最低美金）">{{businessInfo.totalInvoice2017InUSDMinimum}}</el-descriptions-item>
            <el-descriptions-item label="2017年总发票金额（最高美金）">{{businessInfo.totalInvoice2017InUSDMaximum}}</el-descriptions-item>
            <el-descriptions-item label="2016年总发票金额（最低秘鲁币种）">{{businessInfo.totalInvoice2016InPeruvianSolesMinimum}}</el-descriptions-item>
            <el-descriptions-item label="2016年总发票金额（最高秘鲁币种）">{{businessInfo.totalInvoice2016InPeruvianSolesMaximum}}</el-descriptions-item>
            <el-descriptions-item label="2016年总发票金额（最低美金）">{{businessInfo.totalInvoice2016InUSDMinimum}}</el-descriptions-item>
            <el-descriptions-item label="2016年总发票金额（最高美金）">{{businessInfo.totalInvoice2016InUSDMaximum}}</el-descriptions-item>
            <el-descriptions-item label="2019年收入">{{businessInfo.incomes2019}}</el-descriptions-item>
            <el-descriptions-item label="网络实用程序2019">{{businessInfo.netUtility2019}}</el-descriptions-item>
        </el-descriptions>
    </div>
</template>

<script>
export default {
    data() {
        return {
            businessInfo: {},
            loading: false,
        };
    },
    methods: {
        getInfo(){
            let companyName = encodeURIComponent(this.$route.query.companyName);
            let country = this.$route.query.country;
            this.loading = true;
            this.$axios.get(`${this.$clueUrl}/BuyerLeads/GetBusinessInformation?country=${country}&company=${companyName}`).then((res) => {
                const { isSuccess, data } = res;
                this.loading = false;
                if (isSuccess) {
                    this.businessInfo = data;
                }
            });
        },
    },
    mounted() {
        this.getInfo();
    },
};
</script>

<style lang="scss" scoped>

</style>