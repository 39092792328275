<template>
    <div style="display: flex;">
        <ClueNav @chooseNav="chooseNav" :currentPath="currentPath" />
        <div class="viewbox">
            <el-tabs v-model="tabActiveName">
                <el-tab-pane label="公司线索" name="公司线索"></el-tab-pane>
                <el-tab-pane label="联系人线索" name="联系人线索"></el-tab-pane>
            </el-tabs>
            <div v-show="tabActiveName=='公司线索'">
            <div class="header">
                <div class="leftSearch">
                    <!-- 0 联系人名称  1 联系人邮箱  2 公司名称 3 电话 4 网址 5 法人 -->
                    <el-select size="small" v-model="searchTerms.type" placeholder="请选择" style="width:120px;">
                        <el-option label="公司名称" :value="2"></el-option>
                        <el-option label="联系人名称" :value="0"></el-option>
                        <el-option label="联系人邮箱" :value="1"></el-option>
                        <el-option label="电话" :value="3"></el-option>
                        <el-option label="网址" :value="4"></el-option>
                        <el-option label="法人" :value="5"></el-option>
                    </el-select>
                    <el-input placeholder="搜索关键字" size="small" prefix-icon="el-icon-search" v-model="searchTerms.keyword" style="width:300px;"></el-input>
                    <el-button type="primary" plain size="small" @click="onSearch" style="margin: 0 20px;">搜 索</el-button>
                </div>
                <div class="rightOption">
                    <el-button size="small" type="primary" @click="toNewClue">新增线索</el-button>
                    <el-button size="small" type="info" plain @click="clueImport">线索导入</el-button>
                </div>
            </div>
            <div class="searchContent" v-loading="tableLoading">
                <el-table :data="resultList" :header-cell-style="{ 'background-color': '#E9E9E9', 'color': '#3A3A3A' }" @selection-change="selectionChange" :row-key="(row) => {return row.key}">
                    <el-table-column type="selection" width="45" :reserve-selection="true"></el-table-column>
                    <el-table-column label="线索信息">
                        <template slot="header">
                            共有<span style="font-size: 18px;color: var(--themeColor);padding: 0 5px;">{{ totalItems }}</span>条线索
                            <el-button size="small" type="primary" plain @click="toMergedCompany" v-show="selectionList.length >= 2">合并公司</el-button>
                            <el-button size="small" type="primary" plain @click="toMassMuch(1)" v-show="selectionList.length > 0">邮件营销</el-button>
                            <el-button size="small" type="primary" plain @click="toMassMuch(2)" v-show="selectionList.length > 0">WhatsApp群发</el-button>
                            <!-- 公司线索导出屏蔽 -->
                            <!-- <el-button size="small" type="primary" plain @click="toExport" v-show="selectionList.length > 0">线索导出</el-button> -->
                            <el-button size="small" type="info" plain @click="batchRemove" v-show="selectionList.length > 0">批量移出</el-button>
                        </template>
                        <template slot-scope="scope">
                            <div class="clueInfo">
                                <div class="contactsNum">
                                    <div>联系人</div>
                                    <div style="font-size:18px;">{{ scope.row.contactsCount }}</div>
                                </div>
                                <div style="width:calc(100% - 80px);">
                                    <div style="margin-bottom: 8px;">
                                        <div class="companyName" @click="toDetails(scope.row)">{{ scope.row.companyName }}</div>
                                        <div class="country">
                                            <img :src="scope.row.flagUrl" style="margin-right:5px;height:14px" />
                                            <span>{{ scope.row.country }}</span>
                                        </div>
                                        <el-tag size="small" type="success">来源：{{ scope.row.sourceStr }}</el-tag>
                                        <el-tag v-if="scope.row.isMyCustomer" size="small" type="success">我的客户</el-tag>
                                    </div>
                                    <div style="display: flex;">
                                        <!-- <div class="field">
                                            <span class="color99">法人：</span>
                                            <span class="color3a">{{ scope.row.legalPerson || "--" }}</span>
                                        </div> -->
                                        <div class="field">
                                            <span class="color99">电话：</span>
                                            <span class="color3a">{{ scope.row.contactNumber || "--" }}</span>
                                        </div>
                                        <div class="field">
                                            <span class="color99">注册时间：</span>
                                            <span class="color3a">{{ scope.row.registrationTime || "--" }}</span>
                                        </div>
                                        <div class="field">
                                            <span class="color99">年收入：</span>
                                            <span class="color3a">{{ scope.row.reverueAmount || "--" }}</span>
                                        </div>
                                    </div>
                                    <div style="display: flex;">
                                        <div class="field">
                                            <span class="color99">员工人数：</span>
                                            <span class="color3a">{{ scope.row.employeesNumber || "--" }}</span>
                                        </div>
                                        <div class="field">
                                            <span class="color99">网址：</span>
                                            <span class="primaryColor" @click="openWebsite(scope.row.officialWebsite)">{{ scope.row.officialWebsite || "--" }}</span>
                                        </div>
                                        <!-- <div class="field">
                                            <span class="color99">企业邮箱：</span>
                                            <span class="color3a">{{ scope.row.corporateEmail || "--" }}</span>
                                        </div> -->
                                        <div class="field">
                                            <span class="color99">传真：</span>
                                            <span class="color3a">{{ scope.row.faxNumber || "--" }}</span>
                                        </div>
                                    </div>
                                    <div class="field">
                                        <span class="color99">详细地址：</span>
                                        <span class="color3a">{{ scope.row.businessAddress || "--" }}</span>
                                    </div>
                                    <!-- <div class="field">
                                        <span class="color99">企业简介：</span>
                                        <span class="color3a">{{ scope.row.companyProfile || "--" }}</span>
                                    </div> -->
                                    <!-- <div class="field">
                                        <span class="color99">行业：</span>
                                        <span class="color3a">{{ scope.row.industry || "--" }}</span>
                                    </div> -->
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column width="210">
                        <template slot="header">
                            <el-checkbox v-model="searchTerms.isContacts" @change="onSearch">有联系人</el-checkbox>
                            <el-checkbox v-model="searchTerms.isMarketing" @change="onSearch">已营销</el-checkbox>
                        </template>
                        <template slot-scope="scope">
                            <span>最近营销：{{ scope.row.sendTime }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column width="150">
                        <template slot="header">
                            <el-checkbox v-model="isTimeSort" @change="sortChange">线索时间排序</el-checkbox>
                        </template>
                        <template slot-scope="scope">
                            <div v-show="!scope.row.isMyCustomer">
                                <el-button type="primary" plain size="small" @click="joinMyCustomer(scope.row)">加入我的客户</el-button>
                            </div>
                           
                            <el-dropdown>
                                <el-button type="info" plain size="small" style="margin-top:10px;">
                                    更多操作<i class="el-icon-arrow-down el-icon--right"></i>
                                </el-button>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item @click.native="toUpdateClue(scope.row.key)">编辑我的线索</el-dropdown-item>
                                    <el-dropdown-item :disabled="scope.row.contactsCount == 0" @click.native="toMassOne(scope.row)">邮件营销</el-dropdown-item>
                                    <el-dropdown-item :disabled="scope.row.contactsCount == 0" @click.native="toMassTwo(scope.row)">WhatsApp群发</el-dropdown-item>
                                    <el-dropdown-item @click.native="removeClue(scope.row)">移出我的线索</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination v-show="totalItems != 0" background layout="total,sizes,prev,pager,next" :page-sizes="[10, 20, 40]" :page-size.sync="pageSize" @size-change="pageSizeChange" :total="totalItems" style="float:right;margin-top:15px;" :current-page.sync="pageIndex" @current-change="pageIndexChange"></el-pagination>
            </div>
            </div>
            <div v-show="tabActiveName=='联系人线索'">
                <ContactClue />
            </div>
        </div>

        <!-- 加入我的客户 -->
        <JoinCustomer :joinDialogVisible="joinDialogVisible" :clickItem="clickItem" @closeDialog="joinDialogVisible = false" @onFinish="onFinish" />


        <el-dialog title="新增线索" :visible.sync="newClueVisible" width="800px" top="100px">
            <el-form :model="newClueForm" label-width="80px" size="small">
                <el-row :gutter="24">
                    <el-col :span="12">
                        <el-form-item label="公司名称" required>
                            <el-input v-model="newClueForm.companyName"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="所属国家" required>
                            <el-input v-model="newClueForm.country"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="法人">
                            <el-input v-model="newClueForm.legalPerson"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="电话">
                            <el-input v-model="newClueForm.contactNumber"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="注册时间">
                            <el-date-picker style="width:100%" v-model="newClueForm.registrationTime" type="date" value-format="yyyy-MM-dd" placeholder="注册时间"></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="员工人数">
                            <el-input-number style="width:100%" v-model="newClueForm.employeesNumber" :min="0" :controls="false"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="网址">
                            <el-input v-model="newClueForm.officialWebsite"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="企业邮箱">
                            <el-input v-model="newClueForm.corporateEmail"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="详细地址">
                            <el-input v-model="newClueForm.businessAddress"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="企业简介">
                            <el-input type="textarea" v-model="newClueForm.companyProfile"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="行业">
                            <el-select v-model="newClueForm.industrys" multiple filterable allow-create default-first-option placeholder="输入行业格式:(行业代码)行业描述;回车确认;" style="width:100%;">
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="联系人" required>
                            <div class="contactBox">
                                <div style="display: flex;margin-bottom: 10px;" v-for="(item, index) in newClueForm.contacts" :key="index">
                                    <div style="display: flex;padding-right:10px;">
                                        <span style="color:#5E6D82;padding-right:5px;">昵称:</span>
                                        <el-input v-model="item.name" style="width:120px;"></el-input>
                                    </div>
                                    <div style="display: flex;padding-right:10px;">
                                        <span style="color:#5E6D82;padding-right:5px;">职位:</span>
                                        <el-input v-model="item.position" style="width:120px;"></el-input>
                                    </div>
                                    <div style="display: flex;padding-right:10px;">
                                        <span style="color:#5E6D82;padding-right:5px;">邮箱:</span>
                                        <el-input v-model="item.email" style="width:180px;"></el-input>
                                    </div>
                                    <div style="width:60px">
                                        <i class="el-icon-circle-plus-outline plus" @click="addItemContact"></i>
                                        <i v-show="index != 0" class="el-icon-remove-outline remove" @click="deleteItemContact(index)"></i>
                                    </div>
                                </div>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="newClueVisible = false;">取 消</el-button>
                <el-button size="small" type="primary" @click="newSubmit" :loading="btnLoading">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog title="编辑线索" :visible.sync="editClueVisible" width="1000px" top="50px">
            <div style="height:calc(100vh - 240px);overflow-x: hidden;overflow-y: auto;" v-loading="infoLoading">
                <el-form :model="editClueForm" label-width="100px" size="small">
                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-form-item label="公司名称" required>
                                <el-input v-model="editClueForm.companyName" disabled></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="国家" required>
                                <el-input v-model="editClueForm.country"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="详细地址">
                                <div v-for="(ele,index) in editClueForm.businessAddresss" :key="index" class="flexBox" style="width: 100%;">
                                    <el-input v-model="editClueForm.businessAddresss[index]"></el-input>
                                    <i class="el-icon-circle-plus-outline" v-if="index == 0" @click="addItem('businessAddresss')"></i>
                                    <i class="el-icon-remove-outline" v-else @click="delItem('businessAddresss',index)"></i>
                                </div>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="洲">
                                <el-input v-model="editClueForm.continent"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="城市">
                                <el-input v-model="editClueForm.city"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="公司类型">
                                <el-input v-model="editClueForm.companyType"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="状态">
                                <el-input v-model="editClueForm.companyStatus"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="注册时间">
                                <el-date-picker style="width:100%" v-model="editClueForm.registrationTime" type="date" value-format="yyyy-MM-dd" placeholder="注册时间"></el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="员工人数">
                                <el-input-number style="width:100%" v-model="editClueForm.employeesNumber" :min="0" :controls="false"></el-input-number>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="邮政编码">
                                <el-input v-model="editClueForm.postCode"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="传真">
                                <el-input v-model="editClueForm.faxNumber"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="电话">
                                <div v-for="(ele,index) in editClueForm.contactNumbers" :key="index" class="flexBox">
                                    <el-input v-model="editClueForm.contactNumbers[index]"></el-input>
                                    <i class="el-icon-circle-plus-outline" v-if="index == 0" @click="addItem('contactNumbers')"></i>
                                    <i class="el-icon-remove-outline" v-else @click="delItem('contactNumbers',index)"></i>
                                </div>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="网址">
                                <el-row :gutter="6">
                                    <div v-for="(ele,index) in editClueForm.officialWebsites" :key="index" class="flexBox">
                                        <el-input v-model="editClueForm.officialWebsites[index]"></el-input>
                                        <i class="el-icon-circle-plus-outline" v-if="index == 0" @click="addItem('officialWebsites')"></i>
                                        <i class="el-icon-remove-outline" v-else @click="delItem('officialWebsites',index)"></i>
                                    </div>
                                </el-row>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="年收入">
                                <div v-for="(ele,index) in editClueForm.reverueAmounts" :key="index" class="flexBox">
                                    <el-input v-model="editClueForm.reverueAmounts[index]"></el-input>
                                    <i class="el-icon-circle-plus-outline" v-if="index == 0" @click="addItem('reverueAmounts')"></i>
                                    <i class="el-icon-remove-outline" v-else @click="delItem('reverueAmounts',index)"></i>
                                </div>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="国内主要国家">
                                <el-input v-model="editClueForm.majorCountryIn"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="国外主要国家或地区">
                                <el-input v-model="editClueForm.majorCountryOut"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="经营范围">
                                <el-input type="textarea" v-model="editClueForm.businessline"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="历史沿革">
                                <el-input type="textarea" v-model="editClueForm.history"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="主营业务说明">
                                <el-input type="textarea" v-model="editClueForm.tradeEn"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="产品和服务">
                                <el-input type="textarea" v-model="editClueForm.productService"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="nuts1">
                                <el-input v-model="editClueForm.nuts1"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="nuts2">
                                <el-input v-model="editClueForm.nuts2"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="nuts3">
                                <el-input v-model="editClueForm.nuts3"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="简介">
                                <el-input type="textarea" v-model="editClueForm.companyProfile"></el-input>
                            </el-form-item>
                        </el-col>
                        <!-- 新增4个社媒信息字段: FaceBook主页 Twitter主页 Instagram主页 Linkedin主页 -->
                        <el-col :span="12">
                            <el-form-item label="FaceBook">
                                <el-input v-model="editClueForm.faceBook"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="Twitter">
                                <el-input v-model="editClueForm.twitter"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="Instagram">
                                <el-input v-model="editClueForm.instagram"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="Linkedin">
                                <el-input v-model="editClueForm.linkedin"></el-input>
                            </el-form-item>
                        </el-col>

                        <!-- <el-col :span="24">
                            <el-form-item label="行业">
                                <el-select v-model="editClueForm.industrys" multiple filterable allow-create default-first-option placeholder="输入行业格式:(行业代码)行业描述;回车确认;" style="width:100%;">
                                </el-select>
                            </el-form-item>
                        </el-col> -->
                    </el-row>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="editClueVisible = false;">取 消</el-button>
                <el-button size="small" type="primary" @click="updateSubmit" :loading="btnLoading">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog title="合并公司" :visible.sync="mergedVisible" width="800px" top="50px">
            <div class="mergedBox">
                <div class="ttt">请选择合并到哪家公司:</div>
                <div class="mergedItem" v-for="item in selectionList" :key="item.key">
                    <el-radio v-model="mainKey" :label="item.key">{{item.companyName}}</el-radio>
                    <el-tag effect="plain" size="mini" v-show="mainKey == item.key">主</el-tag>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="mergedVisible = false;">取 消</el-button>
                <el-button size="small" type="primary" @click="mergedFinish" :loading="btnLoading">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog title="线索导出" :visible.sync="exportVisible" width="500px">
            <div style="margin-bottom: 16px;">
                <span>预计导出：</span>
                <span style="color: var(--themeColor);font-size: 18px;">{{ exportNum.numOne }} </span>
                <span>个公司，</span>
                <span style="color: var(--themeColor);font-size: 18px;">{{ exportNum.numTwo }} </span>
                <span>个联系人。</span>
            </div>
            <el-progress v-if="exportLoading" :text-inside="true" :stroke-width="20" :percentage="exportProgress"></el-progress>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="exportVisible = false;">取 消</el-button>
                <el-button size="small" type="primary" @click="clueExport" :loading="exportLoading">导 出</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import ClueNav from "@/components/ClueNav.vue";
import ContactClue from "./ContactClue/ContactClue.vue";
import JoinCustomer from "./components/JoinCustomer.vue"
import dayjs from 'dayjs'
import { toFirm } from '@/utils'
export default {
    components: { ClueNav, ContactClue, JoinCustomer },
    props: {
        handleSelect: {
            type: Function,
            default: null,
        },
    },
    data() {
        return {
            currentPath: "/clue/myClue",
            tabActiveName: "公司线索",
            //搜索条件
            searchTerms: {
                type: 2,
                keyword: "",
                isContacts: false,
                isMarketing: false,
                sort: 0,
            },
            tableLoading: false,
            resultList: [],
            totalItems: 0,
            pageIndex: 1,
            pageSize: 10,
            selectionList: [],
            //线索时间排序
            isTimeSort: false,

            //加入我的客户
            joinDialogVisible: false,
            clickItem: {},

            //新增线索
            newClueVisible: false,
            newClueForm: {},
            btnLoading: false,

            //编辑线索
            editClueVisible: false,
            editClueForm: {},
            infoLoading: false,

            //合并公司功能
            mergedVisible: false,
            mainKey: "",
            mergeKeys: [],

            //线索导出
            exportVisible: false,
            exportLoading: false,
            exportProgress: 0,
            exportNum: {
                numOne: 0,
                numTwo: 0,
            }
        };
    },
    methods: {
        chooseNav(index) {
            this.handleSelect(index);
            this.$router.push(index);
        },

        selectionChange(value) {
            this.selectionList = value;
        },


        //获取我的线索
        getMyClue() {
            this.tableLoading = true;
            this.$axios.post(`${this.$clueUrl}/MyClue/GetMyClue`, {
                ...this.searchTerms,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
            }).then((res) => {
                const { isSuccess, data } = res;
                this.tableLoading = false;
                if (isSuccess) {
                    this.resultList = data.datas.map(item => ({
                        ...item,
                        flagUrl: window.getFlagUrlByCountry ? window.getFlagUrlByCountry(item.country) : '',
                        sendTime: item.sendTime ? dayjs(item.sendTime).format("MM-DD HH:mm") : "--",
                    }))
                    this.totalItems = data.totalItems;
                }
            });
        },
        onSearch() {
            this.pageIndex = 1;
            this.getMyClue();
        },
        pageIndexChange(page) {
            this.pageIndex = page;
            this.getMyClue();
        },
        pageSizeChange() {
            this.pageIndex = 1;
            this.getMyClue();
        },
        sortChange() {
            this.searchTerms.sort = this.isTimeSort ? 1 : 0;
            this.onSearch();
        },


        //打开网址
        openWebsite(url) {
            let jumpUrl = url.indexOf("http") > -1 ? url : "http://" + url;
            let routeData = this.$router.resolve({
                path: "/jumpWeb",
                query: { jumpUrl: jumpUrl },
            });
            window.open(routeData.href, "_blank");
        },
        //查看我的线索详情
        toDetails(item) {
            //console.log(item)
            // toFirm({ item: { isMyClue: true, ...item }, company: item.companyName, countryCode: item.threeEnCountryCode })
            let routeData = this.$router.resolve({
                path: "/clue/myClueDetails",
                query: { myClueKey: item.key, companyName: item.companyName, country: item.country },
            });
            window.open(routeData.href, "_blank");
        },

        //邮件营销(1) WhatsApp群发(2)
        toMassMuch(type) {
            let clueKeyList = this.selectionList.filter(item => item.contactsCount != 0).map(item => item.key);
            if (clueKeyList.length == 0) {
                this.$message({ message: '联系人数量为空,无法群发!', type: 'warning' });
                return false;
            }
            localStorage.setItem('clueKeyList', clueKeyList);
            if(type == 1){
                let routeData = this.$router.resolve({
                    // path: '/marketing/New',
                    path: '/marketing/MailMarketing',
                    query: { isClueMass: true },
                });
                window.open(routeData.href, "_blank");
            }
            if(type == 2){
                let routeData = this.$router.resolve({
                    // path: '/marketingWA/NewWA',
                    path: '/marketingWA/WAMass',
                    query: { isClueMass: true },
                });
                window.open(routeData.href, "_blank");
            }
        },
        toMassOne(item) {
            if (item.contactsCount == 0) {
                this.$message({ message: '联系人数量为空,无法群发!', type: 'warning' });
                return false;
            }
            let clueKeyList = [item.key];
            localStorage.setItem('clueKeyList', clueKeyList);
            let routeData = this.$router.resolve({
                // path: '/marketing/New',
                path: '/marketing/MailMarketing',
                query: { isClueMass: true },
            });
            window.open(routeData.href, "_blank");
        },
        toMassTwo(item) {
            if (item.contactsCount == 0) {
                this.$message({ message: '联系人数量为空,无法群发!', type: 'warning' });
                return false;
            }
            let clueKeyList = [item.key];
            localStorage.setItem('clueKeyList', clueKeyList);
            let routeData = this.$router.resolve({
                // path: '/marketingWA/NewWA',
                path: '/marketingWA/WAMass',
                query: { isClueMass: true },
            });
            window.open(routeData.href, "_blank");
        },
        //线索导出功能
        toExport(){
            this.exportVisible = true;
            this.exportProgress = 0;
            this.exportNum.numOne = this.selectionList.length;
            this.exportNum.numTwo = this.selectionList.reduce((total,item)=>{
                return total + item.contactsCount;
            },0)
        },
        clueExport(){
            let keyList = this.selectionList.map(item => item.key);
            this.exportLoading = true;
            this.$axios({
                url: `${this.$clueUrl}/MyClue/ExportMyClue`,
                method: 'POST',
                responseType: 'blob',
                data: keyList,
                onDownloadProgress: (progressEvent) => {
                    this.exportProgress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                },
            }).then(async(response) => {
                setTimeout(() => {
                    this.exportVisible = false;
                    this.exportLoading = false;
                }, 1000);
                if(response.type != "application/vnd.ms-excel"){
                    let jsonData = JSON.parse(await response.text());
                    this.$notify({ message: jsonData.errorMessage, position: "top-right",duration: 2000,type: 'warning' })
                    return false;
                }
                var blob = new Blob([response]);
                var link= document.createElement('a');
                link.download = '我的线索.xlsx';
                link.href = URL.createObjectURL(blob);
                link.click();
                this.$message({message: "线索导出成功",type: 'success'});
            }).catch(() => {
                this.exportLoading = false;
            })
        },

        //移出我的线索
        removeClue(item) {
            this.$confirm('此操作将移出我的线索, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios.get(`${this.$clueUrl}/MyClue/RemoveMyClue?key=${item.key}`).then((res) => {
                    const { isSuccess } = res;
                    if (isSuccess) {
                        this.$message({ type: 'success', message: '移出成功!' });
                        this.getMyClue();
                    }
                });
            }).catch(() => { });
        },
        //批量移出
        batchRemove() {
            let keyList = this.selectionList.map(item => item.key);
            this.$confirm('此操作将移出勾选的线索, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios.post(`${this.$clueUrl}/MyClue/BatchRemoveMyClue`, keyList).then((res) => {
                    const { isSuccess } = res;
                    if (isSuccess) {
                        this.$message({ type: 'success', message: '批量移出成功!' });
                        this.getMyClue();
                    }
                });
            }).catch(() => { });
        },

        //加入我的客户
        joinMyCustomer(item) {
            this.joinDialogVisible = true;
            console.log(item)
            this.clickItem = item;
        },
        onFinish() {
            this.joinDialogVisible = false;
            this.$message({ message: '加入成功', type: 'success' });
            this.getMyClue();
        },

        //新增线索
        toNewClue() {
            this.newClueVisible = true;
            this.newClueForm = {
                "clueRelationKey": "",
                "companyName": "",  //公司名称
                "country": "",      //国家
                "legalPerson": "",  //法人
                "contactNumber": "",    //电话
                "registrationTime": "", //注册时间
                "employeesNumber": 0,   //员工人数
                "officialWebsite": "",  //网址
                "corporateEmail": "",   //企业邮箱
                "businessAddress": "",  //详细地址
                "companyProfile": "",   //企业简介
                "clueSource": 5,
                "industrys": [],
                "contacts": [
                    {
                        "myClueKey": "",
                        "email": "",
                        "name": "",
                        "position": "",
                        "rank": "",
                        "sex": 0,
                        "tel": ""
                    }
                ]
            }
        },
        addItemContact() {
            this.newClueForm.contacts.push({
                "myClueKey": "",
                "email": "",
                "name": "",
                "position": "",
                "rank": "",
                "sex": 0,
                "tel": ""
            })
        },
        deleteItemContact(index) {
            this.newClueForm.contacts = this.newClueForm.contacts.filter((e, i) => i != index);
        },
        newSubmit() {
            const { companyName, country } = this.newClueForm;
            if (!(companyName && country)) {
                this.$message({ message: '公司名称,国家不能为空!', type: 'warning' });
                return false;
            }
            const firstItem = this.newClueForm.contacts[0];
            if (!(firstItem.name && firstItem.position && firstItem.email)) {
                this.$message({ message: '请填写联系人信息!', type: 'warning' });
                return false;
            }
            this.btnLoading = true;
            this.$axios.post(`${this.$clueUrl}/MyClue/AddMyClue`, this.newClueForm).then((res) => {
                const { isSuccess } = res;
                this.btnLoading = false;
                if (isSuccess) {
                    this.newClueVisible = false;
                    this.$message({ message: '新增线索成功', type: 'success' });
                    this.onSearch();
                }
            });
        },

        //线索导入
        clueImport() {
            this.handleSelect(`/clue/clueImport`);
            this.$router.push(`/clue/clueImport`);
        },

        //编辑我的线索
        toUpdateClue(key){
            this.editClueVisible = true;
            this.infoLoading = true;
            this.$axios.get(`${this.$clueUrl}/MyClue/GetMyClueDetail?myClueKey=${key}`).then((res) => {
                const { isSuccess, data } = res;
                this.infoLoading = false;
                if (isSuccess) {
                    const row = data;
                    this.editClueForm = {
                        "key": key,
                        "companyName": row.companyName,
                        "country": row.country,
                        "legalPerson": row.legalPerson,
                        "contactNumbers": row.contactNumbers.length >0 ? row.contactNumbers : [""],
                        "registrationTime": row.registrationTime,
                        "employeesNumber": row.employeesNumber,
                        "officialWebsites": row.officialWebsites.length >0 ? row.officialWebsites : [""],
                        "corporateEmail": row.corporateEmail,
                        "businessAddresss": row.businessAddresss.length >0 ? row.businessAddresss : [""],
                        "companyProfile": row.companyProfile,
                        "clueSource": row.clueSource,
                        "industrys": row.industry.split(','),
                        "reverueAmounts": row.reverueAmounts.length >0 ? row.reverueAmounts : [""],
                        "faxNumber": row.faxNumber,
                        "companyStatus": row.companyStatus,
                        "longitude": row.longitude,
                        "latitude": row.latitude,
                        "geographicLongitude": row.geographicLongitude,
                        "companyType": row.companyType,
                        "familyNumber": row.familyNumber,
                        "businessline": row.businessline,
                        "registrationNumber": row.registrationNumber,
                        //补充字段
                        "postCode": row.postCode,
                        "city": row.city,
                        "nuts1": row.nuts1,
                        "nuts2": row.nuts2,
                        "nuts3": row.nuts3,
                        "continent": row.continent,
                        "history": row.history,
                        "majorCountryIn": row.majorCountryIn,
                        "majorCountryOut": row.majorCountryOut,
                        "tradeEn": row.tradeEn,
                        "productService": row.productService,
                        "ussicCoreCode": row.ussicCoreCode,
                        //社媒信息字段
                        "faceBook": row.faceBook,
                        "twitter": row.twitter,
                        "instagram": row.instagram,
                        "linkedin": row.linkedin,
                    }
                }
            });
        },
        addItem(type){
            this.editClueForm[type].push("");
            // console.log(this.editClueForm[type]);
        },
        delItem(type,index){
            this.editClueForm[type] = this.editClueForm[type].filter((ele,i) => i != index);
            // console.log(this.editClueForm[type]);
        },
        updateSubmit(){
            this.btnLoading = true;
            this.$axios.post(`${this.$clueUrl}/MyClue/UpdateMyClue`, this.editClueForm).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    this.btnLoading = false;
                    this.editClueVisible = false;
                    this.$message({ message: '编辑线索成功', type: 'success' });
                    this.onSearch();
                }
            });
        },

        //合并公司功能
        toMergedCompany(){
            this.mergedVisible = true;
            this.mainKey = this.selectionList[0].key;
            this.mergeKeys = this.selectionList.map(item => item.key);
        },
        mergedFinish(){
            this.btnLoading = true;
            this.$axios.post(`${this.$clueUrl}/MyClue/ConsolidatedCompany`,{
                mainKey: this.mainKey,
                mergeKeys: this.mergeKeys,
            }).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    this.btnLoading = false;
                    this.mergedVisible = false;
                    this.$message({ message: '合并成功', type: 'success' });
                    this.onSearch();
                }
            });
        },

    },
    mounted() {
        this.getMyClue();   //获取我的线索
    },
};
</script>

<style lang="scss" scoped>
.viewbox {
    width: calc(100% - 200px);
    height: calc(100vh - 80px);
    box-sizing: border-box;
    overflow: auto;
    background: #ffffff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    padding: 0 20px 20px;

    .header {
        display: flex;
        justify-content: space-between;
    }

    .searchContent {
        margin-top: 10px;
        overflow: hidden;
    }

    .clueInfo {
        display: flex;
        justify-content: center;
        align-items: center;
        .contactsNum {
            // margin-top: 50px;
            margin-right: 20px;
            width: 80px;
            height: 56px;
            background: var(--themeColor);
            border-radius: 8px 8px 8px 8px;
            color: #FFFFFF;
            line-height: 28px;
            text-align: center;
            opacity: 0.8;
        }

        .companyName {
            display: inline-block;
            color: var(--themeColor);
            text-decoration: underline;
            cursor: pointer;
        }

        .country {
            display: inline-block;
            margin-left: 20px;

            img {
                vertical-align: middle;
            }
        }

        .field {
            line-height: 28px;
            margin-right: 30px;
        }

        .color99 {
            color: #999999;
        }

        .color3a {
            color: #3A3A3A;
        }

        .primaryColor {
            color: var(--themeColor);
            cursor: pointer;
        }
    }
}

/deep/ .el-dialog__body {
    border-top: 1px solid #D9D9D9;
    border-bottom: 1px solid #D9D9D9;
    padding: 20px;
}

/deep/ .el-form-item__label {
    color: #B7B9BC;
}

.contactBox {
    width: 100%;
    padding: 10px 20px;
    box-sizing: border-box;
    background: #F6F6F6;
    border-radius: 2px 2px 2px 2px;

    .plus {
        font-size: 20px;
        cursor: pointer;
    }

    .remove {
        font-size: 20px;
        cursor: pointer;
        margin-left: 10px;
    }
}

.mergedBox{
    .ttt{
        font-size: 16px;
        margin-bottom: 10px;
        color: #F56C6C;
    }
    .mergedItem{
        height: 28px;
        line-height: 28px;
    }
}

.flexBox{
    display: inline-flex;
    align-items: center;
    width: 33%;
}
.el-icon-circle-plus-outline,.el-icon-remove-outline{
    font-size: 20px;
    cursor: pointer;
}
</style>
