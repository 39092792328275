import G6 from "@antv/g6"

export const color = {
  root: "var(--themeColor, #3152AF)",
  peer: "#0E932E", //同行
  compete: "#FF2D2D", //竞争对手
  buyer: "#BB8862", //采购商
  vendor: '#E5694F', // 供应商
  default: '#000'
}

export const rectConfig = {
  width: 200,
  height: 24
}

function transformStr(text, fontSize, length, max) {
  try {
    if (G6.Util.getTextSize(text, fontSize)[0] > length) {
      return text.substring(0, max) + '...'
    } else {
      return text
    }
  } catch {
    return ''
  }
}

G6.registerNode(
  'rect-node-ojnklnvgcg-new',
  {
    draw(cfg, group) {
      const leaf = cfg.leaf
      const classify = cfg.classify
      const node = group.addShape('rect', {
        attrs: {
          width: classify === 'root' ? G6.Util.getTextSize(cfg.label, 12)[0] + 12 : rectConfig.width,
          height: rectConfig.height,
          radius: 2,
          stroke: color[classify] || color['default'],
          lineWidth: 1,
          fill: classify === 'root' ? color[classify] : '#fff',
        }
      })
      if (cfg.direct === 'right') {
        group.addShape('text', {
          attrs: {
            y: (rectConfig.height + 12) / 2,
            x: 6,
            fontSize: 12,
            text: transformStr(cfg.label, 12, 120, leaf ? 18 : 16),
            fill: '#303133',
            textAlign: 'left'
          },
          name: 'label-text'
        })
        group.addShape('text', {
          attrs: {
            y: (rectConfig.height + 12) / 2,
            x: leaf ? rectConfig.width - 10 : rectConfig.width - 25,
            fontSize: 12,
            text: cfg.frequency,
            fill: color[classify],
            textAlign: 'right'
          },
        })
        if (!leaf) {
          group.addShape('circle', {
            attrs: {
              y: rectConfig.height / 2,
              x: rectConfig.width - 12,
              r: 6,
              stroke: '#909399'
            }
          })
          group.addShape('text', {
            attrs: {
              y: (rectConfig.height + 10) / 2,
              x: rectConfig.width - 12,
              text: cfg.collapsed ? '+' : '-',
              fontSize: 10,
              fill: '#909399',
              textAlign: 'center'
            },
            name: 'collapse-text'
          })
        }
      } else if (cfg.direct === 'left') {
        group.addShape('text', {
          attrs: {
            y: (rectConfig.height + 12) / 2,
            x: rectConfig.width - 6,
            fontSize: 12,
            text: transformStr(cfg.label, 12, 120, leaf ? 18 : 16),
            fill: '#303133',
            textAlign: 'right'
          },
          name: 'label-text'
        })
        group.addShape('text', {
          attrs: {
            y: (rectConfig.height + 12) / 2,
            x: leaf ? 10 : 25,
            fontSize: 12,
            text: cfg.frequency,
            fill: color[classify],
            textAlign: 'left'
          },
        })
        if (!leaf) {
          group.addShape('circle', {
            attrs: {
              y: rectConfig.height / 2,
              x: 12,
              r: 6,
              stroke: '#909399',
            }
          })
          group.addShape('text', {
            attrs: {
              y: (rectConfig.height + 10) / 2,
              x: 12,
              text: cfg.collapsed ? '+' : '-',
              fontSize: 10,
              fill: '#909399',
              textAlign: 'center'
            },
            name: 'collapse-text'
          })
        }
      } else {
        group.addShape('text', {
          attrs: {
            y: (rectConfig.height + 12) / 2,
            x: (G6.Util.getTextSize(cfg.label, 12)[0] + 12) / 2,
            fontSize: 12,
            text: cfg.label,
            fill: '#fff',
            textAlign: 'center'
          },
          name: 'label-text'
        })
      }

      // 进口-出口文本
      /* if(classify==='root'){
        group.addShape('text',{
          attrs: {
            y: 12,
            x:0 - ( G6.Util.getTextSize(cfg.label, 12)[0] + 12)/2,
            fontSize: 12,
            text:  '进口',
            fill: '#000'
          }
        })
        group.addShape('text',{
          attrs: {
            y: 12,
            x: ( G6.Util.getTextSize(cfg.label, 12)[0] + 22),
            fontSize: 12,
            text:  '出口',
            fill: '#000'
          }
        })
      } *//* else if (classify==='buyer'){
        group.addShape('text',{
          attrs: {
            y: 12,
            x: rectConfig.width + 10,
            fontSize: 12,
            text:  '',
            fill: '#000'
          },
          name: 'jinchukou-text'
        })
      } */
      //---------------------------

      return node
    },
    setState(name, value, item) {
      if (name === 'collapsed') {
        const group = item.getContainer()
        const collapseText = group.find((e) => e.get("name") === "collapse-text")
        collapseText ? collapseText.attr({
          text: value ? '+' : '-'
        }) : null
        /* const jinchukouText=group.find((e)=> e.get("name") === "jinchukou-text")
        jinchukouText?jinchukouText.attr({
          text:value?'':'进口'
        }):null */
      }
    },
    getAnchorPoints() {
      return [
        [0.5, 0.5]
      ];
    }
  },
  'rect'
)

G6.registerEdge(
  "step-line-ojnklnvgcg",
  {
    getControlPoints: function getControlPoints(cfg) {
      const startPoint = cfg.startPoint;
      const endPoint = cfg.endPoint;
      return [
        startPoint,
        {
          x: (startPoint.x + endPoint.x) / 2,
          y: startPoint.y,
        }, {
          x: (startPoint.x + endPoint.x) / 2,
          y: endPoint.y
        },
        endPoint,
      ];
    },
  },
  "polyline"
)

export default G6
