<template>
    <div>
        <el-card class="viewBox" v-loading="searchLoading">
            <div class="header">
                <div class="title">今日新增推荐</div>
                <div class="quantity">
                    今天新增<span>{{resultNumObj.newCompanyNum}}</span>家公司，
                    <span>{{resultNumObj.contactsNum}}</span>个联系人
                </div>
                <div class="rightOption">
                    <el-button size="mini" @click="toViewHistory">历史新增</el-button>
                </div>
            </div>
            <div class="resultContent" v-if="totalItems > 0">
                <div class="resultItem" v-for="item in resultList" :key="item.key">
                    <!-- <div class="productImg">
                        <el-image :src="item.imgs[0]" :preview-src-list="item.imgs"></el-image>
                        <span class="tagnum">{{item.imgs.length}}</span>
                    </div> -->
                    <div class="infoItem">
                        <div class="companyName">
                            <span @click="toDetails(item)">{{item.companyName}}</span>
                            <div class="iconImg" v-for="(ele,index) in item.socialMedias" :key="index">
                                <el-tooltip effect="dark" :content="ele.socialMediaUrl" placement="top">
                                    <!-- <img :src="require(`@/assets/ai/${ele.socialMediaType}.png`)" alt="" @click="openWebsite(ele.socialMediaUrl)" /> -->
                                    <img v-if="ele.socialMediaType=='Facebook'" :src="require(`@/assets/ai/Facebook.png`)" alt="" @click="openWebsite(ele.socialMediaUrl)" />
                                    <img v-else-if="ele.socialMediaType=='Instagram'" :src="require(`@/assets/ai/Instagram.png`)" alt="" @click="openWebsite(ele.socialMediaUrl)" />
                                    <img v-else-if="ele.socialMediaType=='Linkedin'" :src="require(`@/assets/ai/Linkedin.png`)" alt="" @click="openWebsite(ele.socialMediaUrl)" />
                                    <img v-else-if="ele.socialMediaType=='Pinterest'" :src="require(`@/assets/ai/Pinterest.png`)" alt="" @click="openWebsite(ele.socialMediaUrl)" />
                                    <img v-else-if="ele.socialMediaType=='Twitter'" :src="require(`@/assets/ai/Twitter.png`)" alt="" @click="openWebsite(ele.socialMediaUrl)" />
                                    <img v-else-if="ele.socialMediaType=='YouTube'" :src="require(`@/assets/ai/YouTube.png`)" alt="" @click="openWebsite(ele.socialMediaUrl)" />
                                </el-tooltip>
                            </div>
                            <el-tag size="small" v-if="item.isMyClue" style="margin-left:30px;">已加入我的线索</el-tag>
                        </div>
                        <div style="margin-top:5px;">
                            <div class="field">
                                <span>行业：</span>
                                <span>{{item.industry}}</span>
                            </div>
                            <div class="field">
                                <span>成立时间：</span>
                                <span style="color:#DC7E11;">{{item.established}}</span>
                            </div>
                            <div class="field">
                                <span>网址：</span>
                                <span class="primaryColor" @click="openWebsite(item.website)">{{item.website}}</span>
                            </div>
                            <div class="field">
                                <span>国家：</span>
                                <img :src="item.flagUrl" style="margin-right:5px;height:14px" />
                                <span style="color:#DC7E11;">{{item.country}}</span>
                            </div>
                        </div>
                        <div style="margin-top:5px;">
                            <div class="field">
                                <span>联系人数：</span>
                                <span>{{item.contactsNum}}</span>
                            </div>
                            <div class="field">
                                <span>地址：</span>
                                <span>{{item.address}}</span>
                            </div>
                            <!-- <div class="field">
                                <span>推荐日期：</span>
                                <span>{{item.recommendedDate}}</span>
                            </div>
                            <div class="field">
                                <span>评分：</span>
                                <span>{{item.score}}</span>
                            </div> -->
                        </div>
                        <div class="newfieldBox">
                            <div class="fieldOne">
                                <span class="label">最近贸易：</span>
                                <span>{{item.tradeLastTime}}</span>
                            </div>
                            <div class="fieldTwo">
                                <span class="label">贸易国家：</span>
                                <span>{{item.tradeCountry}}</span>
                            </div>
                            <div class="fieldOne">
                                <span class="label">货量情况：</span>
                                <span>{{item.frequencyInfo}}(近一年{{item.frequency}}单)</span>
                            </div>
                            <div class="fieldTwo">
                                <span class="label">贸易产品：</span>
                                <span>{{item.tradeProduct}}</span>
                            </div>
                            <div class="fieldThree">
                                <span class="label">海关编码：</span>
                                <span>{{item.tradeHsCode}}</span>
                            </div>
                            <div class="fieldThree">
                                <span class="label">起运港口：</span>
                                <span>{{item.portLoading}}</span>
                            </div>
                            <div class="fieldThree">
                                <span class="label">目的港口：</span>
                                <span>{{item.portDischarge}}</span>
                            </div>
                        </div>
                        <div style="margin-top:5px;">
                            <el-tag v-for="ele in item.keywords" :key="ele" type="warning" effect="dark" size="mini">{{ele}}</el-tag>
                        </div>
                        <div style="margin-top:10px;">
                            <div class="productImg" v-for="(ele,index) in item.imgs" :key="index" v-show="index < 5">
                                <el-image :src="ele" :preview-src-list="item.imgs"></el-image>
                            </div>
                        </div>
                    </div>
                    <div class="operation">
                        <div style="margin-top:20px;" v-if="!item.isMyClue">
                            <el-button style="width:120px;" type="primary" size="small" @click="addMyClue(item)" :loading="btnLoading">
                                加入我的线索
                            </el-button>
                        </div>
                        <!-- <div style="margin-top:20px;">
                            <el-button style="width:120px;" size="small">不感兴趣</el-button>
                        </div> -->
                    </div>
                </div>
                <el-pagination v-show="totalItems > 0" background layout="total,prev,pager,next" :total="totalItems" style="float:right;margin-top:15px;" :current-page.sync="pageIndex" @current-change="pageIndexChange"></el-pagination>
            </div>
            <div class="noResult" v-if="totalItems == 0">
                <img src="@/assets/ai/noResultTwo.png" alt="">
                <p>搜索结果推荐内容较少，建议修改<span class="dingzi" @click="dialogVisible = true;">定制AI推荐</span>关键词</p>
                <p>搜索结果推荐内容待更新，建议次日再看</p>
            </div>

            <!-- 定制弹框 -->
            <CustomDialog :dialogVisible="dialogVisible" @closeDialog="dialogVisible = false;" />

        </el-card>
    </div>
</template>

<script>
import CustomDialog from "./CustomDialog.vue";
export default {
    components: { CustomDialog },
    data() {
        return {
            btnLoading: false,
            resultNumObj: {
                newCompanyNum: 0,
                contactsNum: 0,
            },
            //获取推荐列表数据
            searchLoading: true,
            allList: [],
            resultList: [],
            totalItems: 0,
            pageIndex: 1,

            //定制AI推荐
            dialogVisible: false,
        };
    },
    methods: {
        //查看历史新增
        toViewHistory(){
            let routeData = this.$router.resolve({
                path: "/AI/RecommendSquare",
            });
            window.open(routeData.href, "_blank");
        },
        //查看详情
        toDetails(item){
            let routeData = this.$router.resolve({
                path: "/AI/AIDetails",
                query: {companyName: item.companyName },
            });
            window.open(routeData.href, "_blank");
        },

        getResult(){
            this.searchLoading = true;
            //this.$axios.get(`${this.$clueUrl}/Recommendation/GetRECCompany`).then((res) => {
            this.$axios.get(`${this.$clueUrl}/api/AIRec/GetNewAICompany`).then((res) => {
                const { isSuccess, data } = res;
                this.searchLoading = false;
                if (isSuccess) {
                    // console.log(data);
                    this.resultNumObj = data;
                    this.allList = data.recCompanys.map(item=>({
                        ...item,
                        flagUrl: window.getFlagUrlByCountry?window.getFlagUrlByCountry(item.country):'',
                    }))
                    this.resultList = this.allList.slice((this.pageIndex-1) * 10,this.pageIndex * 10);
                    this.totalItems = data.recCompanys.length;
                }
            });
        },
        pageIndexChange(page) {
            this.pageIndex = page;
            this.resultList = this.allList.slice((this.pageIndex-1) * 10,this.pageIndex * 10);
        },
        //打开网址
        openWebsite(url){
            let jumpUrl = url.indexOf("http") > -1 ? url : "http://" + url;
            let routeData = this.$router.resolve({
                path: "/jumpWeb",
                query: { jumpUrl: jumpUrl },
            });
            window.open(routeData.href, "_blank");
        },
        //加入我的线索
        addMyClue(item){
            let submitObj = {
                "clueSource": 7,    //来源：AI推荐
                "clueRelationKey": "",
                "companyName": item.companyName,
                "country": item.country,
                "legalPerson": "",
                "contactNumber": "",
                "registrationTime": item.established,
                "employeesNumber": item.contactsNum,
                "officialWebsite": item.website,
                "corporateEmail": "",
                "businessAddress": item.address,
                "companyProfile": "",
                "industrys": item.industry ? [item.industry] : [],
            }
            this.btnLoading = true;
            this.$axios.post(`${this.$clueUrl}/MyClue/AddMyClue`, submitObj).then((res) => {
                const { isSuccess } = res;
                this.btnLoading = false;
                if (isSuccess) {
                    this.$message({ message: '添加成功', type: 'success'});
                    item.isMyClue = true;
                }
            });
        },

    },
    mounted() {
        this.getResult();
    },
};
</script>

<style lang="scss" scoped>
@import '@/scss/common.scss';
.viewBox{
    background: rgba($color: #FFFFFF, $alpha: 0.7);
    min-height: 800px;
    .header{
        height: 30px;
        line-height: 30px;
        padding-bottom: 10px;
        border-bottom: 1px solid #E6E7EB;
        overflow: hidden;
        .title{
            font-size: 20px;
            font-weight: bold;
            color: #3A3A3A;
            float: left;
        }
        .quantity{
            margin-left: 20px;
            font-size: 16px;
            color: #919191;
            float: left;
            span{
                color: #DC7E11;
                padding: 0 5px;
                font-weight: bold;
            }
        }
        .rightOption{
            float: right;
        }
    }
    .resultContent{
        margin-top: 20px;
        overflow: hidden;
        .resultItem{
            display: flex;
            padding: 20px;
            cursor: pointer;
            .productImg{
                width: 120px;
                height: 120px;
                margin-right: 20px;
                position: relative;
                display: inline-block;
                .el-image{
                    width: 120px;
                    height: 120px;
                }
                .tagnum{
                    position: absolute;
                    background: rgba($color: #000, $alpha: 0.5);
                    color: #fff;
                    width: 30px;
                    height: 24px;
                    text-align: center;
                    right: 0;
                    bottom: 0;
                }
            }
            .infoItem{
                width: calc(100% - 120px);
                color: #1A2745;
                .companyName{
                    font-size: 18px;
                    line-height: 30px;
                    font-weight: bold;
                    text-decoration: underline;
                    .iconImg{
                        margin-left: 10px;
                        cursor: pointer;
                        display: inline-block;
                    }
                }
                .field{
                    margin-right: 30px;
                    font-size: 14px;
                    display: inline-block;
                    .primaryColor{
                        color: $themeColor;
                        cursor: pointer;
                    }
                }
            }
            .operation{
                width: 120px;
                display: none;
            }
            &:hover{
                background: #E3EAEF;
            }
            &:hover .operation{
                display: inline-block;
            }
        }
    }
    .noResult{
        width: 700px;
        text-align: center;
        margin: 80px auto;
        p{
            font-size: 30px;
            color: #999999;
            margin-top: 10px;
            .dingzi{
                cursor: pointer;
                color: $themeColor;
            }
        }
    }
}
.newfieldBox{
    margin-top: 5px;
    background: #ECEFF4;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    .fieldOne{
        width: 30%;
        line-height: 28px;
    }
    .fieldTwo{
        width: 70%;
        line-height: 28px;
    }
    .fieldThree{
        width: 100%;
        line-height: 28px;
    }
    .label{
        color: #8492A6;
    }
}
</style>