<template>
  <div id="abroad-employee-history" class="abroad-employee-history" v-show="numberOfEmployeesHistory.length !== 0">
    <div class="title" ref="title">
      <p>历史雇佣情况</p>
      <feedback position="历史雇佣情况" url="" />
      <!-- <div class="flex-container">
        <img class="icon" src="@/firm/images/company/icon-feedback.png" style="margin-left: 1em" />
        <span class="text-with-icon" style="color: var(--themeColor, #3152AF)">反馈</span>
      </div> -->
    </div>
    <el-table :data="numberOfEmployeesHistory" border :header-cell-style="{ background: '#f8faff', color: '#1F2D3D' }">
      <el-table-column prop="value" label="总人数(合并/单个实体/仅总部)" width="222px" />
      <el-table-column prop="minimumValue" label="人数下限" />
      <el-table-column prop="maximumValue" label="人数上限" />
      <el-table-column prop="employeeFiguresDate" label="报告日期" />
      <el-table-column prop="informationScopeDescription" label="员工人数范围" />
      <el-table-column prop="reliabilityDescription" label="员工数据的完整性" />
      <el-table-column label="计算描述">
        <template slot-scope="{ row }">
          <div>{{ row.employeeCategories[0].employmentBasisDescription }}</div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import Vue from "vue";
import { mapMutations } from "vuex";
import { AnchorInditor } from "../../../utils";

export default Vue.extend({
  props: ["numberOfEmployeesHistory"],
  watch: {
    numberOfEmployeesHistory() {
      try {
        if (this.numberOfEmployeesHistory.length > 0) {
          this.setAnchorFlag([["LSGYQK", true]]);
        } else {
          throw new Error();
        }
      } catch {
        this.setAnchorFlag([["LSGYQK", false]]);
      }
    },
  },
  methods: {
    ...mapMutations("anchor", {
      setAnchorFlag: "setAnchorFlag",
      setCurrent: "setCurrent",
    }),
  },
  mounted() {
    new AnchorInditor(() => {
      this.setCurrent("LSGYQK");
    }, this.$refs["title"]);
  },
});
</script>

<style lang="scss" scoped>
@import "@/firm/scss/mixin.scss";

.abroad-employee-history {
  background: #fff;
  padding: 1rem;
  padding-top: 0;
  @include mixin-panel-title;
}
</style>
