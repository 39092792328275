<script lang="js">
import { defineComponent, onMounted, reactive, getCurrentInstance } from '@vue/composition-api'
import { exportExcel } from './util/export-excel'
import axios from '@/assets/js/axios'
import { MODE, BASE_URL, BASE_URL_ASSET } from './constant'
import luckyexcel from 'luckyexcel'

export default defineComponent({
  setup() {
    const vm = getCurrentInstance()
    const workbook = reactive({
      name: {
        value: '',
        disabled: false
      }
    })
    const onWorkbookExport = () => exportExcel(window.luckysheet.getAllSheets(), workbook.name.value, true)
    const onWorkbookSave = async () => {
      if (MODE === 'excel') {
        try {
          const blob = await exportExcel(window.luckysheet.getAllSheets(), workbook.name.value, false)
          const file = new File([blob], `${workbook.name.value}.xlsx`, { type: 'application/vnd.ms-excel;charset=utf-8' })
          const formData = new FormData()
          formData.append('file', file)
          const res = await axios({
            method: 'POST',
            url: BASE_URL + '/upload',
            data: formData
          })
          console.log(res)
        } catch (error) {
          console.log(error)
        }
      } else {
        try {
          const blob = new Blob([JSON.stringify(window.luckysheet.getAllSheets())])
          const file = new File([blob], `${workbook.name.value}.json`, { type: 'application/json' })
          const formData = new FormData()
          formData.append('file', file)
          const res = await axios({
            method: 'POST',
            url: BASE_URL + '/upload',
            data: formData
          })
          console.log(res)
        } catch (error) {
          console.log(error)
        }
      }
    }
    const LUCKYSHEET_OPTION = {
      container: 'luckysheet-dom-uj3h4gf5',
      lang: 'zh',
      myFolderUrl: '',
      showinfobar: false
    }
    onMounted(() => {
      const { filename, version } = vm.proxy.$route.query
      if (filename && version) {
        workbook.name.disabled = true
        if (MODE === 'excel') {
          axios({
            method: 'GET',
            url: `${BASE_URL_ASSET}/${filename}/${version}`,
            responseType: 'blob'
          }).then(blob => {
            const file = new File([blob], `${filename}.xlsx`, { type: 'application/vnd.ms-excel;charset=utf-8' })
            luckyexcel.transformExcelToLucky(file, result => {
              if (result.sheets == null || result.sheets.length == 0) {
                console.log('导入失败')
              } else {
                window.luckysheet.create({
                  ...LUCKYSHEET_OPTION,
                  data: result.sheets,
                  hook: {
                    workbookCreateAfter() {
                      workbook.name.value = filename
                    }
                  }
                })
              }
            })
          })
        } else {
          axios({
            method: 'GET',
            url: `${BASE_URL_ASSET}/${filename}/${version}`,
            responseType: 'blob'
          }).then(async blob => {
            const data = JSON.parse(await blob.text())
            window.luckysheet.create({
              ...LUCKYSHEET_OPTION,
              data,
              hook: {
                workbookCreateAfter() {
                  workbook.name.value = filename
                }
              }
            })
          })
        }
      } else {
        window.luckysheet.create({
          ...LUCKYSHEET_OPTION,
          hook: {
            workbookCreateAfter() {
              workbook.name.value = window.luckysheet.getWorkbookName() || ''
            }
          }
        })
      }
    })
    return {
      workbook,
      onWorkbookExport,
      onWorkbookSave
    }
  }
})
</script>

<template>
    <div class="page-excel">
        <div class="info-bar">
            <el-form inline size="mini" label-suffix="：">
                <el-form-item label="工作簿名称">
                    <el-input v-model.trim="workbook.name.value" :disabled="workbook.name.disabled" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onWorkbookExport">导出工作簿</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="success" @click="onWorkbookSave">保存工作簿</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="excel-dom" id="luckysheet-dom-uj3h4gf5"></div>
    </div>
</template>

<style lang="scss" scoped>
.page-excel {
    width: 100vw;
    height: 100vh;
}

$info-bar-height: 100px;

.info-bar {
    width: 100%;
    height: $info-bar-height;
}

.excel-dom {
    width: 100%;
    height: calc(100% - #{$info-bar-height});
}
</style>
