<template>
<div style="display: flex;">
    <OrderNav @chooseNav="chooseNav" :currentPath="currentPath" />
    <div class="viewbox">
        <div class="boxOne">
            <div class="title">报价单管理</div>
            <!-- 管理员可以查看整个部门 -->
            <PowerSelect defaultText="我的报价单" @onChoose="onChoose" />

            <div class="rightHandle">
                <el-button size="small" type="primary" icon="el-icon-plus" @click="toNewQuotation">新建报价单</el-button>
                <el-link href="/#/quotation/templateManage" target="_blank" :underline="false">
                    <el-button size="small" style="margin-left:20px;">模板管理</el-button>
                </el-link>
            </div>
        </div>

        <div class="boxTwo">
            <div class="txt">已选择{{chooseList.length}}个报价单</div>
            <div class="rightHandle">
                <el-input v-model="filterParam.quoteName" placeholder="搜索报价单" prefix-icon="el-icon-search" size="small" style="width:180px;margin-right:10px;" @keyup.enter.native="toSearch"></el-input>

                <el-input v-model="filterParam.customerName" placeholder="公司名称" size="small" style="width:180px;margin-right:10px;" @keyup.enter.native="toSearch"></el-input>

                <el-date-picker v-model="filterParam.startTime" type="date" value-format="yyyy-MM-dd" placeholder="创建时间" size="small" style="width:180px;" @change="toSearch"></el-date-picker>
            </div>
        </div>

        <div class="boxTable">
            <el-table style="width:100%;" height="calc(100vh - 300px)" v-loading="tableLoading" :data="quotationList"
                :header-cell-style="{'background-color':'#F2F2F2','color':'#3A3A3A'}" :row-style="rowStyle"
                @selection-change="selectionChange">
                <el-table-column type="selection" width="45"></el-table-column>
                <el-table-column label="报价单名称">
                    <template slot-scope="scope">
                        <el-link @click.stop="toDetails(scope.row)">{{scope.row.quoteName}}</el-link>
                    </template>
                </el-table-column>
                <el-table-column prop="quotationDate" label="报价时间"></el-table-column>
                <el-table-column prop="expirationTime" label="报价有效期"></el-table-column>
                <el-table-column prop="customerName" label="公司名称">
                    <template slot-scope="scope">
                        <el-popover trigger="hover">
                            <CompanyCard v-if="scope.row.card1Show" :ckey="scope.row.customerKey" />
                            <el-link slot="reference" type="primary" @click.stop="openCustomer(scope.row)">
                                <span @mouseenter="scope.row.card1Show = true;">{{ scope.row.customerName }}</span>
                            </el-link>
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column label="报价单状态">
                    <template slot-scope="scope">
                        <div style="display: flex;">
                            <span class="globe" :class="'stateColor_'+scope.row.state"></span>
                            <span>{{scope.row.stateStr}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="实际金额">
                    <template slot-scope="scope">
                        {{scope.row.totalPrice}} CNY
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-link type="info" @click.stop="toDetails(scope.row)">查看</el-link>
                        <span style="padding:0 5px;"></span>
                        <el-link type="info" @click.stop="toEditQuotation(scope.row)">编辑</el-link>
                        <span style="padding:0 5px;"></span>
                        <el-link type="info" @click.stop="toCopyQuotation(scope.row)">复制</el-link>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <div class="bottomPagination">
            <el-button style="float:left;margin:15px 20px;" size="small" icon="el-icon-refresh" @click="onRefresh">刷 新</el-button>
            <el-pagination background layout="total,sizes,prev,pager,next" :page-sizes="[10, 20, 40]" :page-size.sync="pageSize" @size-change="pageSizeChange" :total="totalItems" style="float:right;margin:15px 20px;" :current-page.sync="pageIndex" @current-change="pageIndexChange"></el-pagination>
        </div>

    </div>
</div>
</template>

<script>
import OrderNav from "@/components/OrderNav.vue";
import CompanyCard from "@/components/CompanyCard.vue"; //公司卡片
import PowerSelect from "@/components/PowerSelect.vue";
export default {
    components: { OrderNav, CompanyCard, PowerSelect },
    props: {
        handleSelect: {
            type: Function,
            default: null,
        },
    },
    data() {
        return {
            currentPath: "/quotation/quotationList",
            organizationKey: "",
            salekey: "",
            // 过滤字段
            filterParam: {
                quoteName: "",
                customerName: "",
                startTime: "",
                endTime: "",
            },
            //列表展示
            tableLoading: false,
            quotationList: [],
            totalItems: 0,
            pageIndex: 1,
            pageSize: 10,

            chooseList: [],
        };
    },
    methods: {
        chooseNav(index){
            this.handleSelect(index);
            this.$router.push(index);
        },

        onRefresh(){
            this.filterParam = {
                quoteName: "",
                customerName: "",
                startTime: "",
                endTime: "",
            };
            this.toSearch();
        },
        rowStyle({rowIndex}){
            // 表格隔行换色
            if(rowIndex % 2 == 1){
                return {'background-color':'#F9FAFC'}
            }
        },
        //查看客户详情
        openCustomer(row) {
            let routeData = this.$router.resolve({
                path: "/customerManage/customerDetailsPrivate",
                query: { customerKey: row.customerKey },
            });
            window.open(routeData.href, "_blank");
        },

        toNewQuotation(){
            let routeData = this.$router.resolve({
                path: "/quotation/newQuotation",
            });
            window.open(routeData.href, "_blank");
        },
        toEditQuotation(row){
            let routeData = this.$router.resolve({
                path: "/quotation/newQuotation",
                query: { quotationKey: row.uniqueKey, optype: "edit" },
            });
            window.open(routeData.href, "_blank");
        },
        toCopyQuotation(row){
            let routeData = this.$router.resolve({
                path: "/quotation/newQuotation",
                query: { quotationKey: row.uniqueKey, optype: "copy" },
            });
            window.open(routeData.href, "_blank");
        },

        toSearch(){
            this.pageIndex = 1;
            this.getQuotationList();
        },

        //获取报价单列表
        getQuotationList(){
            const {quoteName,customerName,startTime,endTime} = this.filterParam;
            this.tableLoading = true;
            this.$axios.post(`${this.$sjUrl}/Quote/GetQuotes`,{
                quoteName,customerName,startTime,endTime,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
                organizationKey: this.organizationKey,
                ukey: this.salekey,
            }).then((res) => {
                const { isSuccess , data } = res;
                this.tableLoading = false;
                if (isSuccess) {
                    this.quotationList = data.datas.map(item=>({
                        ...item,
                        card1Show: false,
                    }))
                    this.totalItems = data.totalItems;
                }
            });
        },
        pageIndexChange(page) {
            this.pageIndex = page;
            this.getQuotationList();
        },
        pageSizeChange() {
            this.pageIndex = 1;
            this.getQuotationList();
        },
        //前往报价单详情
        toDetails(row){
            let routeData = this.$router.resolve({
                path: "/quotation/quotationDetails",
                query: { quotationKey: row.uniqueKey },
            });
            window.open(routeData.href, "_blank");
        },

        selectionChange(value){
            console.log(value);
            this.chooseList = value;
        },

        
        //选择组织结构、员工
        onChoose(valueOne,valueTwo){
            this.organizationKey = valueOne;
            this.salekey = valueTwo;
            this.onRefresh();
        },
        
    },
    mounted() {
        this.getQuotationList();    //获取报价单列表
    },
};
</script>

<style lang="scss" scoped>
.viewbox{
    width: calc(100% - 200px);
    height: calc(100vh - 140px);
    background: rgba($color: #FFFFFF, $alpha: 0.7);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    margin-bottom: 60px;
    .boxOne,.boxTwo{
        overflow: hidden;
        padding: 8px 0;
        line-height: 32px;
        border-bottom: 1px solid #E6E7EB;
        .title{
            float: left;
            margin-left: 40px;
            font-size: 24px;
            font-weight: bold;
            color: #3A3A3A;
        }
        .txt{
            float: left;
            margin-left: 40px;
            font-size: 16px;
            color: #7C90A5;
        }
        .rightHandle{
            float: right;
            margin-right: 20px;
        }
    }

    .bottomPagination{
        position: fixed;
        bottom: 0;
        right: 0;
        z-index: 10;
        width: calc(100vw - 200px);
        height: 60px;
        background: #FFFFFF;
        box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.16);
    }
}
// 报价单状态
.globe{
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    margin-top: 4px;
    margin-right: 8px;
}
.stateColor_0{
    background: #DBDBDB;
}
.stateColor_1{
    background: #DBDBDB;
}
.stateColor_2{
    background: #20BC5F;
}
.stateColor_3{
    background: #519DFF;
}
.stateColor_4{
    background: #353A3E;
}
.stateColor_5{
    background: #20BC5F;
}
.stateColor_6{
    background: #519DFF;
}
.stateColor_7{
    background: #353A3E;
}
</style>