<script>
import Vue from 'vue'
//import router from '@/router'
import { reactive, ref } from '@vue/composition-api'
import axios from '@/assets/js/axios'
//import dayjs from 'dayjs'
import CMenu from './components/Menu.vue'
import PExoprt from './components/PExport.vue'
import { Message } from 'element-ui'
import { watchDebounced } from '@vueuse/core'
import { data as countrys } from './c'
import MarketingNav from "@/components/MarketingNav.vue";
export default Vue.extend({
  components: {
    MarketingNav,
    CMenu,
    PExoprt
  },
  setup() {
    const grpus = ref([]);


    let ppssCache = []; // {pattern,platform}[]
    (async () => {
      try {
        const re = await axios({
          method: 'GET',
          url: Vue.prototype.$marketUrl + '/WhatsApp/GetSearchStrategyList'
        })
        ppssCache = re.data
      } catch {
        null
      }
    })()
    const dadaf = reactive({
      loadinf: false,
      data: []
    })
    const formafre = reactive({
      "keyword": "",
      "start": 0,
      "platform": "",
      "pattern": "",
      "country": ""
    })

    const machiePro = {
      start: 0,
      poin: 0,
      stop: false,
      axios: null
    }
    const machieMO = () => {
      machiePro.start = 0
      machiePro.poin = 0
      machiePro.stop = false
      machieExe()
    }
    const machieExe = async () => {
      try {
        if (machiePro.poin >= ppssCache.length || machiePro.stop) {
          throw new Error()
        }
        dadaf.loadinf = true
        const controller = new AbortController();
        machiePro.axios = controller
        const r = await axios({
          signal: controller.signal,
          method: 'POST',
          url: Vue.prototype.$marketUrl + '/WhatsApp/GoogleSearch',
          data: {
            "keyword": formafre.keyword,
            "start": machiePro.start,
            "platform": ppssCache[machiePro.poin].pattern,
            "pattern": ppssCache[machiePro.poin].pattern,
            "country": formafre.country
          }
        })
        const data = r.data
        //dadaf.data.push(...data.result)
        if (!data.isEnd) {
          machiePro.start += 10
          machieExe()
        } else {
          machiePro.poin += 1
          machieExe()
        }
      } catch (e) {
        dadaf.loadinf = false
        return
      } finally {
        glist()
      }
    }
    const machieStop = () => {
      machiePro.stop = true
      machiePro.axios.abort()
    }


    const formp = reactive({
      "key": "",
      "name": "",
      "whatsApp": "",
      "source": "",
      "groupKey": "",
      "email": "",
      "remark": "",
      "companyName": ""
    })

    axios({
      method: 'GET',
      url: Vue.prototype.$marketUrl + '/WhatsApp/GetGroup'
    }).then(res => {
      grpus.value = res.data
      formp.groupKey = grpus.value[0].key
    })

    const fomload = ref(false)


    const list = reactive({
      f: {
        "name": "",
        "whatsApp": "",
        "isReply": false,
        "groupName": ""
      },
      loading: false,
      data: [],
      page: {
        total: 0,
        size: 10,
        index: 1
      }
    })
    const glist = async (pageindex = 1) => {
      try {
        list.loading = true
        const r = await axios({
          method: 'POST',
          data: {
            "pageIndex": pageindex,
            "pageSize": 10,
            "name": list.f.name,
            "whatsApp": list.f.whatsApp,
            "isReply": list.f.isReply,
            "groupName": list.f.groupName,
          },
          url: Vue.prototype.$marketUrl + '/WhatsApp/GetContactsList'
        })
        list.data = r.data.datas
        list.page.index = pageindex
        list.page.total = r.data.totalItems
      } catch (error) {
        null
      } finally {
        list.loading = false
      }
    }
    // glist()
    watchDebounced([() => list.f.name, () => list.f.whatsApp], () => glist(), { debounce: 500 })


    const showModal = ref(false)
    const showAddDialog = ref(false)
    const renderImporModal = ref(false)

    // 存档客户
    const archiveList = reactive({
      loading: false,
      data: [],
      checked: [],
      pageIndex: 1,
      totalItems: 0,
    })
    const getUserArchive = async () => {
      try {
        archiveList.loading = true;
        archiveList.data = []
        const res = await axios({
          method: 'POST',
          url: Vue.prototype.$marketUrl + '/WhatsApp/FindUserArchive',
          data: {
            pageIndex: archiveList.pageIndex,
            pageSize: 10,
          }
        })
        archiveList.data = res.data.datas;
        archiveList.totalItems = res.data.totalItems;
      } catch {
        archiveList.data = []
      } finally {
        archiveList.loading = false;
      }
    }
    getUserArchive();
    const archiveChange = (page) => {
      archiveList.pageIndex = page;
      getUserArchive();
    }

    const viewDetail = reactive({
        visible: false,
        data: [],
        pageIndex: 1,
        totalItems: 0,
        archiveKey: [],
    })
    const toViewArchive = (row) => {
        viewDetail.visible = true;
        viewDetail.data = [];
        viewDetail.pageIndex = 1;
        viewDetail.totalItems = 0;
        viewDetail.archiveKey = [row.key];
        getArchiveDetail();
    }
    const getArchiveDetail = async () => {
      try {
        const res = await axios.post(Vue.prototype.$marketUrl + '/WhatsApp/ArchiveDetail', viewDetail.archiveKey);
        viewDetail.data = res.data;
        viewDetail.totalItems = res.data.length;
      } catch {
        return null;
      }
    }

    return {
      list,
      showModal,
      showAddDialog,
      renderImporModal,
      formp,
      grpus,
      fomload,
      chooseNav: (index) => {
        this.handleSelect(index);
        this.$router.push(index);
      },
      glist,
      formafre,
      dadaf,
      machieMO,
      machieStop,
      countrys,
      creat: async () => {
        try {
          if (!formp.whatsApp) {
            Message.error('手机号不得为空')
            return
          }
          await axios({
            method: 'POST',
            data: formp,
            url: Vue.prototype.$marketUrl + '/WhatsApp/SaveContacts'
          })
          showAddDialog.value = false
          formp.key = ''
          formp.name = ''
          formp.whatsApp = ''
          formp.source = ''
          formp.email = ''
          formp.remark = ''
          formp.companyName = ''
          glist()
        } catch {
          null
        } finally {
          fomload.value = false
        }
      },
        //存档客户
      archiveList,
      getUserArchive,
      archiveChange,
      viewDetail,
      toViewArchive,
      getArchiveDetail,
    }
  }
})
</script>

<template>
  <div style=" width: 100%; height: 100%;position: relative;">
    <PExoprt v-if="renderImporModal" @close="renderImporModal = false" />
    <!-- <MarketingNav @chooseNav="chooseNav" currentPath="/marketingWA/TaskDashboardWA" /> -->
    <div style="display: flex; width: 100%; height: calc(100%);">
      <div v-show="showModal" style="display: flex;justify-content: center;position: absolute;background: #fff;top: 0;left: 0;width: 100%;height: 100%;z-index: 200;">
        <div style="width: 55%;max-width: 873px;margin-top: 160px;">
          <!-- <div style="display: grid; grid-template-columns: repeat(4,1fr);gap: 20px;">
          <el-input value="" size="small" placeholder="国家" />
          <el-input value="" size="small" placeholder="行业" />
          <el-input value="" size="small" placeholder="网址" />
          <el-input value="" size="small" placeholder="电话" />
        </div> -->
          <div style="position: relative;margin-top: 23px;display: grid; grid-template-columns: repeat(4,1fr);gap: 20px;">
            <el-input prefix-icon="el-icon-search" v-model="formafre.keyword" size="small" placeholder="关键字" style="grid-column-start: 1;grid-column-end: 4;" />
            <el-select v-model="formafre.country" placeholder="全部" size="small">
              <el-option v-for="ie of countrys" :key="ie.name" :value="ie.tel" :label="ie.name" />
            </el-select>
            <el-button :loading="dadaf.loadinf" @click="machieMO(); showModal = false" size="small" type="primary" style="position: absolute;right:-20px; transform: translateX(100%);">确定</el-button>
            <el-button size="small" @click="showModal = false" style="position: absolute;right:-40px; transform: translateX(200%);">关闭</el-button>
          </div>
          <!-- <el-table :data="dadaf.data">

        </el-table> -->
        </div>
      </div>
      <el-dialog v-loading="fomload" title="新增联系人" :visible="showAddDialog" width="500px" :show-close="false">
        <el-form label-width="100px" size="small" label-suffix="：">
          <el-form-item label="姓名">
            <el-input v-model="formp.name" />
          </el-form-item>
          <el-form-item label="手机号" required>
            <el-input v-model="formp.whatsApp" />
          </el-form-item>
          <el-form-item label="来源">
            <el-input v-model="formp.source" />
          </el-form-item>
          <el-form-item label="分组">
            <el-select v-model="formp.groupKey" style="width: 100%">
              <el-option v-for="item of grpus" :key="item.key" :label="item.groupName" :value="item.key" />
            </el-select>
          </el-form-item>
          <el-form-item label="邮箱">
            <el-input v-model="formp.email" />
          </el-form-item>
          <el-form-item label="备注">
            <el-input v-model="formp.remark" />
          </el-form-item>
          <el-form-item label="公司名称">
            <el-input v-model="formp.companyName" />
          </el-form-item>
        </el-form>
        <span slot="footer" v-loading="fomload">
          <el-button @click="creat" size="small" type="primary">立即创建</el-button>
          <el-button @click="showAddDialog = false" size="small">取 消</el-button>
        </span>
      </el-dialog>
      <div>
        <CMenu active="TaskDashboardPWA" />
      </div>
      <div class="view" style="width: 100%;">
        <div style="display: flex; justify-content: space-between; padding-bottom: 20px;">
          <span style="color: #3A3A3A;font-weight: bold;font-size: 18px;">存档客户</span>
          <div style="display: flex; align-items: center">
            <!-- <el-button :loading="dadaf.loadinf" @click="showModal = true" size="small" type="primary">全网搜索联系人</el-button> -->
            <!-- <el-button @click="machieStop" v-if="dadaf.loadinf" size="small">停止全网搜</el-button> -->
            <!-- <el-button @click="showAddDialog = true" size="small">新建联系人</el-button> -->
            <!-- <el-button @click="renderImporModal = true" size="small">联系人导入</el-button> -->
            <!-- <el-button size="small">管理</el-button> -->
          </div>
        </div>
        <!-- 联系人列表屏蔽 -->
        <!-- <div style="display: flex; justify-content: space-between; padding: 20px 0; border-top: 1px solid #E6E7EB;border-bottom: 1px solid #E6E7EB;">
          <div style="display: flex; align-items: center">
            <el-input v-model="list.f.name" placeholder="联系人名称" style="width:150px;margin-right: 20px" prefix-icon="el-icon-search" size="small" />
            <el-input v-model="list.f.whatsApp" placeholder="手机号" size="small" style="width: 150px;margin-right: 20px" />
            <el-select @change="glist()" v-model="list.f.isReply" placeholder="是否回复" size="small" style="width:90px;margin-right: 20px">
              <el-option label="已回复" :value="true" />
              <el-option label="未回复" :value="false" />
            </el-select>
            <el-select @change="glist()" v-model="list.f.groupName" style="width: 100px" placeholder="分组" size="small">
              <el-option label="全部分组" value="" />
              <el-option v-for="item of grpus" :key="item.key" :label="item.groupName" :value="item.groupName" />
            </el-select>
          </div>
        </div>
        <div v-loading="list.loading || dadaf.loadinf" style="box-sizing: border-box; height: calc(100% - 120px);">
          <div style="height: 100%;overflow-y: auto;" v-loading="list.loading">
            <el-table :data="list.data" :header-cell-style="{ fontWeight: 'normal', color: '#3A3A3A' }" :row-style="({ rowIndex }) => rowIndex % 2 ? { background: '#fff' } : { background: '#F0F4F7' }">
              <el-table-column label="头像">
                <template slot-scope="{row}">
                  <img v-if="row.avatar" style="width: 25px;height: 25px" :src="row.avatar" />
                  <div v-else style="display: flex;width: 25px;height: 25px;justify-content: center; align-items: center; color: #fff;background: #7B9CB7;border-radius: 50%;">{{  row.name.slice(0, 1)  }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="name" label="姓名" />
              <el-table-column prop="whatsApp" label="手机号" />
              <el-table-column label="WhatsApp验证">
                <template slot-scope="{row}">
                  <div v-if="row.isVerify">
                    <i class="el-icon-success" style="color: #67C23A"></i>
                    <span style="margin-left: 5px">已验证</span>
                  </div>
                  <div v-else style="color:#9C9C9C ;">未验证</div>
                </template>
              </el-table-column>
              <el-table-column prop="source" label="来源" />
              <el-table-column prop="groupName" label="分组" />
              <el-table-column prop="marketingCount" label="已营销次数" />
              <el-table-column label="是否回复">
                <template slot-scope="{row}">
                  <div v-if="row.isReply" style="color: #20BC5F;">已回复</div>
                  <div v-else style="color: #9C9C9C">未回复</div>
                </template>
              </el-table-column>
            </el-table>
            <div style="display: flex; justify-content: flex-end">
              <el-pagination hide-on-single-page @current-change="glist" :current-page.sync="list.page.index" :page-size="list.page.size" :total="list.page.total" background layout="jumper,total,prev, pager, next" />
            </div>
          </div>
        </div> -->

        <!-- 存档客户 -->
        <el-table :data="archiveList.data" v-loading="archiveList.loading" :header-cell-style="{ background: '#F0F0F0', color: '#797979' }">
            <el-table-column prop="keyword" label="存档名称" />
            <el-table-column prop="createTime" label="存档时间" />
            <el-table-column prop="searchType" label="搜索类型" />
            <el-table-column prop="phoneNumber" label="WhatsApp数" />
            <el-table-column label="操作">
                <template slot-scope="{row}">
                    <el-button type="text" size="small" @click="toViewArchive(row)">查看详情</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div style="display: flex; justify-content: flex-end; margin-top:10px;">
            <el-pagination hide-on-single-page :current-page.sync="archiveList.pageIndex" :total="archiveList.totalItems" @current-change="archiveChange" background layout="prev, pager, next" />
        </div>
        <el-dialog title="存档详情" :visible="viewDetail.visible" width="800px" :show-close="false">
            <el-table :data="viewDetail.data.slice((viewDetail.pageIndex-1)*10,viewDetail.pageIndex*10)" :header-cell-style="{ background: '#F0F0F0', color: '#797979' }">
                <el-table-column prop="whatsApp" label="whatsApp" />
                <el-table-column prop="country" label="国家" />
                <el-table-column prop="title" label="标题" show-overflow-tooltip />
                <el-table-column prop="describe" label="内容描述" show-overflow-tooltip />
            </el-table>
            <div style="display: flex; justify-content: flex-end; margin-top:10px;">
                <!-- @current-change="getArchiveDetail" -->
                <el-pagination hide-on-single-page :current-page.sync="viewDetail.pageIndex" :total="viewDetail.totalItems" background layout="prev, pager, next" />
            </div>
            <span slot="footer">
                <el-button @click="viewDetail.visible = false" size="small">关 闭</el-button>
            </span>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.view {
  box-sizing: border-box;
  padding: 20px;
  width: 100%;
  height: 100%;
  background: #fff;

  ::v-deep .el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell {
    background: #E4EAEF !important;
  }
}

.card {
  width: 100%;
  border: 1px solid #CDCDCD;
  border-radius: 4px;
  display: flex;
  padding: 10px;

  img {
    margin-right: 10px;
  }


  &:not(:last-of-type) {
    margin-right: 20px;
  }
}

.item {
  border-bottom: 1px solid #CDCDCD;
  display: flex;
  align-items: center;
  position: relative;
  margin-right: 20px;
  padding: 20px 0;
}

.el-icon-refresh,
.el-icon-loading {
  cursor: pointer;
  padding-left: 5px;
  font-size: 20px;
  color: var(--themeColor);
}

::v-deep .el-input--mini .el-input__inner {
  border: none;
}

.el-icon-search {
  cursor: pointer;
  line-height: 28px;
  color: #3a3a3a;
}

.draft,
.dingshi,
.withdraw {
  display: inline-block;

  .elseOption {
    display: none;
  }
}

.item:hover .elseOption {
  cursor: pointer;
  display: inline-block;
}

.custome-sleect {
  * {
    line-height: normal !important;
    height: auto;
  }
}
</style>
