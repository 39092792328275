import { render, staticRenderFns } from "./SearchWhatsApp.vue?vue&type=template&id=545731a3&scoped=true&"
import script from "./SearchWhatsApp.vue?vue&type=script&lang=js&"
export * from "./SearchWhatsApp.vue?vue&type=script&lang=js&"
import style0 from "./SearchWhatsApp.vue?vue&type=style&index=0&id=545731a3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "545731a3",
  null
  
)

export default component.exports