<template>
    <div v-if="value">
        <el-tooltip effect="dark" content="未知" placement="top">
            <img class="stateIcon" v-if="isVerify==0" src="@/assets/img/state_0.png" alt="" />
        </el-tooltip>
        <el-tooltip effect="dark" content="有效" placement="top">
            <img class="stateIcon" v-if="isVerify==1" src="@/assets/img/state_1.png" alt="" />
        </el-tooltip>
        <el-tooltip effect="dark" content="无效" placement="top">
            <img class="stateIcon" v-if="isVerify==2" src="@/assets/img/state_2.png" alt="" />
        </el-tooltip>
        <span>{{value}}</span>
        <el-tooltip effect="dark" content="写邮件" placement="top-start">
            <i class="el-icon-s-promotion" @click="sendMail(value)"></i>
        </el-tooltip>
    </div>
</template>

<script>

export default {
    props:["value","isVerify"],
    data() {
        return {};
    },
    methods: {
        sendMail(mail) {
            window.open(
                `${process.env.VUE_APP_MAIL_BASE}#/index/editor?type=send&receiver=${mail}`,
                "_blank"
            );
        },
    },
    mounted() {
       
    },
};
</script>

<style lang="scss" scoped>
.stateIcon{
    display: inline-block;
    cursor: pointer;
    margin-right: 5px;
}
.el-icon-s-promotion{
    font-size: 20px;
    cursor: pointer;
    padding-left: 5px;
    color: var(--themeColor);
}
</style>