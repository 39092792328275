<template>
    <div class="viewbox">
        <div class="boxOne">
            <div class="title">模板管理</div>
            <div class="rightHandle">
                <el-button size="small" type="primary" icon="el-icon-plus" @click="toNewTemplate">新建模板</el-button>
            </div>
        </div>
        <div class="boxTable">
            <el-table v-loading="tableLoading" :data="templateList" :header-cell-style="{ 'background-color': '#F2F2F2', 'color': '#3A3A3A' }">
                <el-table-column prop="templateName" label="模板名称"></el-table-column>
                <el-table-column prop="createTime" label="创建时间"></el-table-column>
                <el-table-column prop="updateTime" label="修改时间"></el-table-column>
                <el-table-column prop="templateContent" label="模板内容">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" @click="toView(scope.row)">查看模板</el-button>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" @click="toEdit(scope.row)" v-if="!scope.row.isSysTem">编辑模板</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination hide-on-single-page background layout="total,prev,pager,next" :total="totalItems" style="float:right;margin:10px;" :current-page.sync="pageIndex" @current-change="pageIndexChange"></el-pagination>
        </div>

        <el-dialog title="模板内容" :visible.sync="dialogVisible" width="1200px">
            <div v-html="viewContent"></div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false" size="small">关 闭</el-button>
            </span>
        </el-dialog>
    </div>

</template>
    
<script>
export default {
    data() {
        return {
            tableLoading: false,
            templateList: [],
            totalItems: 0,
            pageIndex: 1,
            dialogVisible: false,
            viewContent: "",
        };
    },
    methods: {
        //新建模板
        toNewTemplate() {
            let routeData = this.$router.resolve({
                path: "/quotation/customTemplate",
            });
            window.open(routeData.href, "_blank");
        },
        //编辑模板
        toEdit(row) {
            let routeData = this.$router.resolve({
                path: "/quotation/customTemplate",
                query: { templateKey: row.key },
            });
            window.open(routeData.href, "_blank");
        },

        getTemplate() {
            this.tableLoading = true;
            this.$axios.post(`${this.$sjUrl}/ExportTemplate/Pages`,{
                "templateName": "",
                "pageIndex": 1,
                "pageSize": 10
            }).then((res) => {
                const { isSuccess , data } = res;
                this.tableLoading = false;
                if (isSuccess) {
                    this.templateList = data.datas;
                    this.totalItems = data.totalItems;
                }
            });
        },
        pageIndexChange(page) {
            this.pageIndex = page;
            this.getTemplate();
        },

        //查看模板内容
        toView(row){
            this.dialogVisible = true;
            this.viewContent = row.templateContent;
        }
    },
    mounted() {
        this.getTemplate();
    },
};
</script>
    
<style lang="scss" scoped>
.viewbox {
    background: rgba($color: #FFFFFF, $alpha: 0.7);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    margin-bottom: 60px;

    .boxOne,
    .boxTwo {
        overflow: hidden;
        padding: 8px 0;
        line-height: 32px;
        border-bottom: 1px solid #E6E7EB;

        .cascader {
            float: left;
            margin-left: 20px;
            width: 100px;

            ::v-deep .el-input__inner {
                border: none;
                background: none;
            }
        }

        .title {
            float: left;
            margin-left: 40px;
            font-size: 24px;
            font-weight: bold;
            color: #3A3A3A;
        }

        .txt {
            float: left;
            margin-left: 40px;
            font-size: 16px;
            color: #7C90A5;
        }

        .rightHandle {
            float: right;
            margin-right: 20px;
        }
    }

    .boxTable {
        overflow: hidden;
        padding: 8px 0;
        line-height: 32px;
        border-bottom: 1px solid #E6E7EB;
    }

}
</style>