<template>
    <div>
        <div class="recordBox">
            <el-link v-show="activeName==1" class="toAddInvoice" type="primary" :underline="false" href="/#/AddInvoice">开发票</el-link>
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="已完成" name="1"></el-tab-pane>
                <el-tab-pane label="待支付" name="0"></el-tab-pane>
                <el-tab-pane label="所有订单" name=" "></el-tab-pane>
                <el-tab-pane label="其它" name="2"></el-tab-pane>
                <div class="recordList" v-loading="tableLoading">
                    <el-empty description="暂无数据" v-if="totalItems == 0"></el-empty>
                    <div class="recordItem" v-for="item in orderList" :key="item.key">
                        <div class="title">
                            <span class="createTime">{{item.createTime}}</span>
                            <span class="orderNo">订单号：{{item.orderNo}}</span>
                            <el-tag type="success" v-if="item.isBilling" size="small">已开票</el-tag>
                            <CountDown class="count_down" v-if="item.stateStr == '待支付'" :endTime="item.timeRxpire" />
                        </div>
                        <div class="content">
                            <el-row :gutter="24" >
                                <el-col :span="4">
                                    <span style="font-weight: bold;">{{item.description}}</span>
                                </el-col>
                                <el-col :span="4">
                                    <span>购买期限：{{item.serviceLifeStr}}</span>
                                </el-col>
                                <el-col :span="4">
                                    <span>原价：￥{{item.originalPrice}}</span>
                                </el-col>
                                <el-col :span="4">
                                    <span style="color: #E56210;">折扣价：￥{{item.discountPrice}}</span>
                                </el-col>
                                <el-col :span="4">
                                    <span v-if="item.stateStr == '待支付'" style="color:#F56C6C">{{item.stateStr}}</span>
                                    <span v-else-if="item.stateStr == '支付成功'" style="color:#67C23A">{{item.stateStr}}</span>
                                    <span v-else style="color:#909399">{{item.stateStr}}</span>
                                </el-col>
                                <el-col :span="4">
                                    <span v-if="item.stateStr == '待支付'" class="toPay" @click="toPay(item)">去支付</span>
                                </el-col>
                            </el-row>
                            <el-row :gutter="24" style="margin-top:10px;">
                                <el-col :offset="4" :span="8">
                                    <span>产品有效期：{{item.startTime}} 至 {{item.endTime}}</span>
                                </el-col>
                            </el-row>
                        </div>
                    </div>
                </div>
                <el-pagination v-show="totalItems > 10" background layout="total,prev,pager,next" :total="totalItems" style="float:right;margin:15px 20px;" :current-page.sync="pageIndex" @current-change="pageChange"></el-pagination>
            </el-tabs>
        </div>
    </div>
</template>

<script>
import { Encrypt } from '@/assets/js/secret.js'
import CountDown from "./CountDown.vue"
export default {
    components: { CountDown },
    data() {
        return {
            activeName: "1",
            tableLoading: false,
            orderList: [],
            totalItems: 0,
            pageIndex: 1,
        };
    },
    methods: {
        handleClick(tab) {
            this.activeName = tab.name;
            this.pageIndex = 1;
            this.getOrderRecord();
        },

        //获取订单列表
        getOrderRecord(){
            this.tableLoading = true
            this.$axios.post(`${this.$payUrl}/SetMeal/GetSetMealOrders`,{
                "state": this.activeName,
                "pageIndex": this.pageIndex,
                "pageSize": 10
            }).then((res) => {
                const { isSuccess, data } = res;
                this.tableLoading = false;
                if (isSuccess) {
                    data.datas.forEach(element => {
                        element.timeRxpire = new Date(element.timeRxpire).getTime();
                    });
                    this.orderList = data.datas;
                    this.totalItems = data.totalItems;
                }
            })
        },
        pageChange(page) {
            this.pageIndex = page;
            this.getOrderRecord();
        },

        //去支付
        toPay(item){
            let payType = item.payType == "微信支付" ? 1 : 2;
            let orderNo = Encrypt(item.orderNo);    //加密订单号
            this.$router.push(`/PayforOrder?orderNo=${orderNo}&payType=${payType}`);
            localStorage.setItem("versionValue",item.setMealName);
            localStorage.setItem("yearValue",item.serviceLifeStr);
        }
    },
    mounted() {
        this.getOrderRecord();
    },
};
</script>

<style lang="scss" scoped>
.recordBox{
    width: 1200px;
    margin: auto;
    background: #FFFFFF;
    padding: 0 20px;
    position: relative;
    .toAddInvoice{
        position: absolute;
        top: 10px;
        right: 30px;
        z-index: 99;
    }
    .recordItem{
        margin-bottom: 20px;
        background: #FFFFFF;
        border: 1px solid #DCDBDB;
        .title{
            height: 40px;
            line-height: 40px;
            font-size: 16px;
            color: #3A3A3A;
            background: #EEF3F9;
            border-bottom: 1px solid #E9E9E9;
            .createTime{
                font-weight: bold;
                margin-left: 20px;
            }
            .orderNo{
                margin-left: 50px;
            }
            .count_down{
                float: right;
                margin-right: 20px;
                color:#F56C6C;
            }
        }
        .content{
            padding: 20px;
            color: #3A3A3A;
            font-size: 14px;
            .toPay{
                cursor: pointer;
                color: var(--themeColor);
            }
        }
    }
}
</style>