<template>
    <div class="MyInvoice">
        <el-link class="toAddInvoice" type="primary" :underline="false" href="/#/AddInvoice">开发票</el-link>
        <el-tabs v-model="activeName">
            <el-tab-pane label="我的发票" name="我的发票">
                <div v-loading="tableLoading" style="overflow: hidden;">
                    <div class="header">
                        <div class="orderInfo">订单详情</div>
                        <div class="creationTime">开票时间</div>
                        <div class="invoiceType">发票类型</div>
                        <div class="status">状态</div>
                        <div class="operation">操作</div>
                    </div>
                    <el-empty description="暂无数据" v-if="totalItems == 0"></el-empty>
                    <div class="invoiceItem" v-for="item in invoiceList" :key="item.key">
                        <div class="title">
                            <span>发票单号：</span>
                            <span style="font-weight: bold;">{{item.invoiceNo}}</span>
                            <span style="margin-left: 20px;">发票抬头：</span>
                            <span style="font-weight: bold;">{{item.invoiceTitle}}</span>
                            <span style="margin-left: 20px;">税务登记号：</span>
                            <span style="font-weight: bold;">{{item.taxId}}</span>
                        </div>
                        <div class="content">
                            <div class="orderInfo">
                                <div v-for="ele in item.orderDescriptions" :key="ele.orderNo">
                                    <span>{{ele.orderNo}}</span>
                                    <span style="margin-left: 20px;font-weight: bold;">{{ele.description}}</span>
                                </div>
                                <div class="courierNumber" v-if="item.courierNumber">
                                    <span>快递单号：</span>
                                    <span>{{item.courierNumber}}</span>
                                </div>
                            </div>
                            <div class="creationTime">
                                {{item.createTime}}
                            </div>
                            <div class="invoiceType">
                                {{item.invoiceType}}
                            </div>
                            <div class="status">
                                <!-- 0待开票 1已开票 2驳回 -->
                                <span v-if="item.state == 0" style="color:#909399;">{{item.stateStr}}</span>
                                <span v-if="item.state == 1" style="color:#67C23A;">{{item.stateStr}}</span>
                                <span v-if="item.state == 2" style="color:#F56C6C;">{{item.stateStr}}</span>
                            </div>
                            <div class="operation">
                                <el-link v-if="item.downLoadUrl" icon="el-icon-download" @click="download(item.downLoadUrl)">下载发票</el-link>
                            </div>
                        </div>
                    </div>
                </div>
                <el-pagination v-show="totalItems > 10" background layout="total,prev,pager,next" :total="totalItems" style="float:right;margin:15px 20px;" :current-page.sync="pageIndex" @current-change="pageChange"></el-pagination>
            </el-tab-pane>

            <el-tab-pane label="发票信息" name="发票信息">
                <el-form label-width="140px" :model="invoiceInfo" size="small" style="width:40%;">
                    <el-form-item label="开具类型：" required>
                        <el-radio v-model="invoiceInfo.openType" :label="0" border>单位</el-radio>
                        <el-radio v-model="invoiceInfo.openType" :label="1" border>个人</el-radio>
                    </el-form-item>
                    <el-form-item label="发票抬头：" required>
                        <el-input v-model="invoiceInfo.invoiceTitle"></el-input>
                    </el-form-item>
                    <el-form-item label="发票类型：" required>
                        <el-radio v-model="invoiceInfo.invoiceType" :label="0" border>增值税普通发票</el-radio>
                        <el-radio v-model="invoiceInfo.invoiceType" :label="1" border>增值税专用发票</el-radio>
                    </el-form-item>
                    <el-form-item label="税务登记号：" required>
                        <el-input v-model="invoiceInfo.taxId"></el-input>
                    </el-form-item>
                    <el-form-item label="基开户银行名称：">
                        <el-input v-model="invoiceInfo.bankName"></el-input>
                    </el-form-item>
                    <el-form-item label="基开户账号：">
                        <el-input v-model="invoiceInfo.account"></el-input>
                    </el-form-item>
                    <el-form-item label="注册场所地址：">
                        <el-input v-model="invoiceInfo.registerAddress"></el-input>
                    </el-form-item>
                    <el-form-item label="注册固定电话：">
                        <el-input v-model="invoiceInfo.registerPhone"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="saveInfo" :loading="btnLoading">保 存</el-button>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
export default {
    data() {
        return {
            activeName: "我的发票",
            tableLoading: false,
            invoiceList: [],
            totalItems: 0,
            pageIndex: 1,

            //发票信息
            invoiceInfo: {
                key: "",
                openType: 0,
                invoiceTitle: "",
                invoiceType: 0,
                taxId: "",
                bankName: "",
                account: "",
                registerAddress: "",
                registerPhone: "",
            },
            btnLoading: false,

        };
    },
    methods: {
        //获取发票列表
        getList() {
            this.tableLoading = true;
            this.$axios.post(`${this.$orderUrl}/Invoice/GetInvoiceApplys`, {
                pageIndex: this.pageIndex,
                pageSize: 10,
            }).then((res) => {
                const { isSuccess, data } = res;
                this.tableLoading = false;
                if (isSuccess) {
                    this.invoiceList = data.datas;
                    this.totalItems = data.totalItems;
                }
            });
        },
        pageChange(page) {
            this.pageIndex = page;
            this.getList();
        },


        //获取发票信息
        getInfo(){
            this.$axios.get(`${this.$orderUrl}/Invoice/GetInvoices`).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    if(data.length > 0){
                        this.invoiceInfo = data[0];
                    }
                }
            });
        },
        //保存发票信息
        saveInfo(){
            if(!this.invoiceInfo.invoiceTitle || !this.invoiceInfo.taxId){
                this.$message({ message: '请填写发票抬头和税务登记号!', type: 'warning'});
                return false;
            }
            this.btnLoading = true;
            this.$axios.post(`${this.$orderUrl}/Invoice/SaveInvoice`,this.invoiceInfo).then((res) => {
                const { isSuccess } = res;
                this.btnLoading = false;
                if (isSuccess) {
                    this.$message({ message: '保存成功', type: 'success'});
                }
            });
        },

        //下载发票
        download(url){
            window.open(url);
        }

    },
    mounted() {
        this.getList();
        this.getInfo();
    },
};
</script>

<style lang="scss" scoped>
.MyInvoice {
    width: 1200px;
    min-height: 800px;
    margin: auto;
    background: #ffffff;
    padding: 0 20px 20px;
    position: relative;
    .toAddInvoice{
        position: absolute;
        top: 10px;
        right: 30px;
        z-index: 99;
    }
    .header{
        padding: 0 20px;
        display: flex;
        height: 40px;
        line-height: 40px;
        background: #F5F5F5;
        color: #3a3a3a;
    }
    .invoiceItem {
        margin-top: 15px;
        background: #ffffff;
        border: 1px solid #dcdbdb;
        .title {
            padding: 0 20px;
            height: 40px;
            line-height: 40px;
            color: #3a3a3a;
            background: #eef3f9;
            border-bottom: 1px solid #e9e9e9;
        }
        .content {
            padding: 20px;
            display: flex;
            color: #3a3a3a;
            font-size: 14px;
            .courierNumber{
                height: 30px;
                line-height: 30px;
                margin-top: 10px;
                padding: 0 20px;
                background: #F1F1F1;
                color: #919191;
            }
        }
    }
    .orderInfo{
        width: 50%;
        padding-right: 50px;
        box-sizing: border-box;
    }
    .creationTime{
        width: 15%;
    }
    .invoiceType{
        width: 15%;
    }
    .status{
        width: 10%;
    }
    .operation{
        width: 10%;
    }
}
</style>