<template>
  <!-- 空数据遮罩层 -->
  <div class="empty-mask-layer">
    <div class="flex-container">
      <img src="@/firm/images/empty-icon.png" style="width: 80%" />
      <p style="margin-top: 1em">暂无数据</p>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({});
</script>

<style lang="scss" scoped>
.empty-mask-layer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  .flex-container {
    color: #9a9fb4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>
