<template>
    <div>
        <InterfaceName :interfaceName="interfaceName" />
        <InterfaceSpe :requestUrl="requestUrl" :requestMethod="requestMethod" />
        <InputParam :inputTableData="inputTableData" />
        <ReturnParam :returnObj="returnObj" />
    </div>
</template>

<script>
import InterfaceName from "../Acomponents/InterfaceName.vue";
import InterfaceSpe from "../Acomponents/InterfaceSpe.vue";
import InputParam from "../Acomponents/InputParam.vue";
import ReturnParam from "../Acomponents/ReturnParam.vue";

export default {
    components: { InterfaceName, InterfaceSpe, InputParam, ReturnParam },
    data() {
        return {
            interfaceName: "获取客户联系人",
            requestUrl: "https://crm.qixinerp.com/open/Customer/GetCustomerContacts",
            requestMethod: "GET",
            inputTableData: [],
            returnObj: {},
        };
    },
    methods: {},
    mounted() {
        this.inputTableData = [
            {
                paramName: "customerKey",
                valueType: "string",
                explain: "客户key",
                required: true,
            },
        ];
        this.returnObj = {
            isSuccess: true,
            errorMessage: null,
            data: [
                {
                    key: "d0a75a5dd104407f969deb0c7346b8c3",
                    companyKey: "0cd973cf575c4ebf83f5f5a045b64e3a",
                    isMain: true,
                    nickname: "测试联系人",
                    email: "123456@qq.com",
                    workRank: "无",
                    position: "无",
                    call: "12345678912",
                    linkedin: "",
                    twitter: "",
                    gender: "男",
                    birthday: "2022-01-26 00:00:00",
                    note: "",
                    images: null,
                }
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
</style>