<template>
<div>
    <div v-if="ie=='i'">
        <!-- 美国进口提单明细 -->
        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">运输信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">起运港</th>
                        <td width="25%">{{billInfo.LoadingPort}}</td>
                        <th width="25%">起运城市</th>
                        <td width="25%">{{billInfo.ExporterCity}}</td>
                    </tr>
                    <tr>
                        <th>起运国</th>
                        <td>{{billInfo.DepartureCountry}}</td>
                        <th>起运洲</th>
                        <td>{{billInfo.DepartureState}}</td>
                    </tr>
                    <tr>
                        <th>目的港</th>
                        <td>{{billInfo.DpOfUnlading99}}</td>
                        <th>目的地区</th>
                        <td>{{billInfo.UsaRegion}}</td>
                    </tr>
                    <tr>
                        <th>目的洲</th>
                        <td>{{billInfo.DestinationState}}</td>
                        <th>来源国</th>
                        <td>{{billInfo.Country}}</td>
                    </tr>
                    <tr>
                        <th>原产洲</th>
                        <td>{{billInfo.OriginState}}</td>
                        <th>承运人</th>
                        <td>{{billInfo.Carrier}}</td>
                    </tr>
                    <tr>
                        <th>运输方式</th>
                        <td>{{billInfo.ModeTransport}}</td>
                        <th>船名</th>
                        <td>{{billInfo.VesselName}}</td>
                    </tr>
                    <tr>
                        <th>国际海事组织编码</th>
                        <td>{{billInfo.ImoCode}}</td>
                        <th>船舶航行ID</th>
                        <td>{{billInfo.VesselVoyageID}}</td>
                    </tr>
                    <tr>
                        <th>总体积（TEU）</th>
                        <td>{{billInfo.VolumeTEU}}</td>
                        <th>集装箱数</th>
                        <td>{{billInfo.ContainerQuantity}}</td>
                    </tr>
                    <tr>
                        <th>高柜/箱</th>
                        <td>{{billInfo.HighCube}}</td>
                        <th>集装箱尺寸</th>
                        <td>{{billInfo.ContainerSize}}</td>
                    </tr>
                    <tr>
                        <th>公吨数</th>
                        <td>{{billInfo.MetricTons}}</td>
                        <th>产地</th>
                        <td>{{billInfo.ReceiptPlace}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">集装箱信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">过境货</th>
                        <td width="25%">{{billInfo.Frob}}</td>
                        <th width="25%">清单数量</th>
                        <td width="25%">{{billInfo.ManifestNumber}}</td>
                    </tr>
                    <tr>
                        <th>保税的代码</th>
                        <td>{{billInfo.InbondCode}}</td>
                        <th>有拼箱</th>
                        <td>{{billInfo.HasLcl}}</td>
                    </tr>
                    <tr>
                        <th>集装箱号</th>
                        <td>{{billInfo.Container}}</td>
                        <th>体积（TEU）</th>
                        <td>{{billInfo.VolumeContainerTEU}}</td>
                    </tr>
                    <tr>
                        <th>集装箱标志</th>
                        <td>{{billInfo.MarksNumbers}}</td>
                        <th>拼箱</th>
                        <td>{{billInfo.DividedLCL}}</td>
                    </tr>
                    <tr>
                        <th>集装箱服务类型</th>
                        <td>{{billInfo.ContainerTypeofService}}</td>
                        <th>集装箱类型</th>
                        <td>{{billInfo.Harmonized}}</td>
                    </tr>
                    <tr>
                        <th>集装箱描述代码</th>
                        <td>{{billInfo.ContainerDescCode}}</td>
                        <th>集装箱装载状态</th>
                        <td>{{billInfo.ContainerLoadStatus}}</td>
                    </tr>
                    <tr>
                        <th>危险货物</th>
                        <td colspan="3">{{billInfo.DangerousGoods}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
    <div v-if="ie=='e'">
        <!-- 美国出口提单明细 -->
        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">HsCode信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">子项编码</th>
                        <td width="25%">{{billInfo.ByHsCodeHs}}</td>
                        <th width="25%">子项Teus数量</th>
                        <td width="25%">{{billInfo.ByHsCodeTeusQuantity}}</td>
                    </tr>
                    <tr>
                        <th>子项总集装箱数量</th>
                        <td>{{billInfo.ByHsCodeContainerQuantity}}</td>
                        <th>子项总公吨数</th>
                        <td>{{billInfo.ByHsCodeMetricTons}}</td>
                    </tr>
                    <tr>
                        <th>子项美元总金额</th>
                        <td>{{billInfo.ByHsCodeValue}}</td>
                        <th>子项总重量</th>
                        <td>{{billInfo.TotalWeight}}</td>
                    </tr>
                    <tr>
                        <th>子项总重量单位</th>
                        <td>{{billInfo.TotalWeightunit}}</td>
                        <th>高柜/箱</th>
                        <td>{{billInfo.HighCube}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">货物信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">公吨数</th>
                        <td width="25%">{{billInfo.MetricTons}}</td>
                        <th width="25%">最终目的国</th>
                        <td width="25%">{{billInfo.Country}}</td>
                    </tr>
                    <tr>
                        <th>Hs编码描述</th>
                        <td colspan="3">{{billInfo.HsDescription}}</td>
                    </tr>
                    <tr>
                        <th>SITC</th>
                        <td>{{billInfo.SitcCode}} {{billInfo.SitcDescription}}</td>
                        <th>日期</th>
                        <td>{{billInfo.ArrivalDate}}</td>
                    </tr>
                    <tr>
                        <th>货物类型</th>
                        <td>{{billInfo.CargoType}}</td>
                        <th>航次号</th>
                        <td>{{billInfo.Voyage}}</td>
                    </tr>
                    <tr>
                        <th>是否集装箱</th>
                        <td>{{billInfo.Containerized}}</td>
                        <th>船名</th>
                        <td>{{billInfo.VesselName}}</td>
                    </tr>
                    <tr>
                        <th>申报接货地区</th>
                        <td>{{billInfo.ReceiptPlaceDeclared}}</td>
                        <th>接货地区</th>
                        <td>{{billInfo.ReceiptPlace}}</td>
                    </tr>
                    <tr>
                        <th>接货地区州</th>
                        <td>{{billInfo.ReceiptPlaceState}}</td>
                        <th>美国港口,起运港</th>
                        <td>{{billInfo.LoadingPort}}</td>
                    </tr>
                    <tr>
                        <th>美国地,起运地区</th>
                        <td>{{billInfo.LoadingUsRegion}}</td>
                        <th>申报目的港</th>
                        <td>{{billInfo.DestinationPortDeclared}}</td>
                    </tr>
                    <tr>
                        <th>目的港</th>
                        <td>{{billInfo.DestinationPort}}</td>
                        <th>目的港所在国</th>
                        <td>{{billInfo.DestinationPortCountry}}</td>
                    </tr>
                    <tr>
                        <th>目的港所在地区</th>
                        <td>{{billInfo.DestinationPortRegion}}</td>
                        <th>最终申报目的地</th>
                        <td>{{billInfo.DestinationPlaceDeclared}}</td>
                    </tr>
                    <tr>
                        <th>最终目的地</th>
                        <td>{{billInfo.DestinationPlace}}</td>
                        <th>最终目的地区</th>
                        <td>{{billInfo.DestinationRegion}}</td>
                    </tr>
                    <tr>
                        <th>集装箱数</th>
                        <td>{{billInfo.ContainerQuantity}}</td>
                        <th>集装箱是否拼箱</th>
                        <td>{{billInfo.ContainerLclFcl}}</td>
                    </tr>
                    <tr>
                        <th>集装箱短描述</th>
                        <td colspan="3">{{billInfo.ShortContainerDesc}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
</div>
</template>

<script>
export default {
    props:["billInfo","ie"],
    data() {
        return {

        };
    },
    methods: {
        
    },
    mounted() {

    },
};
</script>

<style lang="scss" scoped>
.partItem {
    margin-bottom: 20px;
    /deep/.el-card__header {
        padding: 10px 20px;
        .headTitle {
            display: inline-block;
            padding-left: 10px;
            font-size: 14px;
            border-left: 2px solid var(--themeColor);
            font-weight: bold;
        }
    }
}
table {
    width: 100%;
    border-collapse: collapse;
    tr {
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;
        th,
        td {
            font-size: 12px;
            color: #666;
            height: 40px;
            border: 1px solid #eaeaea;
            text-align: center;
        }
        th {
            background-color: #f5f7fa;
            font-weight: 700;
        }
        .bg {
            background-color: var(--themeColor);
            color: #fff;
            font-weight: 700;
            opacity: 0.8;
        }
    }
}
</style>