<template>
    <div class="tabCard">
        <el-tabs :value="value">
            <el-tab-pane name="0" label="文件夹">
                <Folder />
            </el-tab-pane>
            <el-tab-pane name="1" label="标签">
                <Label />
            </el-tab-pane>
            <el-tab-pane name="2" label="黑名单">
                <Blacklist />
            </el-tab-pane>
            <el-tab-pane name="3" label="邮箱管理">
                <MailboxManage  />
            </el-tab-pane>
            <el-tab-pane name="4" label="签名">
                <Sign />
            </el-tab-pane>
            <el-tab-pane name="5" label="模板">
                <Template />
            </el-tab-pane>
            <el-tab-pane name="6" label="常规设置">
                <Routine />
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import Routine from "./EmailSet/Routine.vue"
import Folder from "./EmailSet/Folder.vue"
import Label from "./EmailSet/Label.vue"
import Blacklist from "./EmailSet/Blacklist.vue"
import MailboxManage from "./EmailSet/MailboxManage.vue"
import Sign from "./EmailSet/Sign.vue"
import Template from "./EmailSet/Template.vue"
export default {
    components: { Routine,Folder,Label,Blacklist,MailboxManage,Sign,Template },
    data() {
        return {
            value: "0",
        };
    },
    methods: {
        
    },
    mounted() {
        const { index } = this.$route.query;
        if(index){
            this.value = index;
        }
    },
};
</script>

<style lang="scss" scoped>
.tabCard{
    background: #FFFFFF;
    padding: 0 20px 20px;
}
</style>