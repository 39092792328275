<template>
    <div v-loading="loading">
        <!-- 哥斯达黎加 -->
        <el-descriptions :column="2" border :labelStyle="{width:'15%'}" :contentStyle="{width:'35%'}">
            <el-descriptions-item label="公司代码">{{businessInfo.companyId}}</el-descriptions-item>
            <el-descriptions-item label="公司名">{{businessInfo.company}}</el-descriptions-item>
            <el-descriptions-item label="公司名标准">{{businessInfo.companyStd}}</el-descriptions-item>
            <el-descriptions-item label="公司类型">{{businessInfo.companyType}}</el-descriptions-item>
            <el-descriptions-item label="公司状态">{{businessInfo.status}}</el-descriptions-item>
            <el-descriptions-item label="公司地址">{{businessInfo.address}}</el-descriptions-item>
            <el-descriptions-item label="公司地址名">{{businessInfo.addressName}}</el-descriptions-item>
            <el-descriptions-item label="公司电话">{{businessInfo.telephone}}</el-descriptions-item>
            <el-descriptions-item label="公司传真">{{businessInfo.fax}}</el-descriptions-item>
            <el-descriptions-item label="公司邮箱">{{businessInfo.email}}</el-descriptions-item>
            <el-descriptions-item label="公司邮编">{{businessInfo.postal}}</el-descriptions-item>
            <el-descriptions-item label="区域代码">{{businessInfo.areaCode}}</el-descriptions-item>
            <el-descriptions-item label="联系人">{{businessInfo.contact}}</el-descriptions-item>
            <el-descriptions-item label="Office">{{businessInfo.office}}</el-descriptions-item>
            <el-descriptions-item label="工厂">{{businessInfo.factory}}</el-descriptions-item>
            <el-descriptions-item label="董事会">{{businessInfo.boardDirectors}}</el-descriptions-item>
            <el-descriptions-item label="公司网站">{{businessInfo.website}}</el-descriptions-item>
            <el-descriptions-item label="联系人电话">{{businessInfo.contactPhone}}</el-descriptions-item>
            <el-descriptions-item label="联系人传真">{{businessInfo.contactFax}}</el-descriptions-item>
            <el-descriptions-item label="联系人邮箱">{{businessInfo.contactEmail}}</el-descriptions-item>
        </el-descriptions>
    </div>
</template>

<script>
export default {
    data() {
        return {
            businessInfo: {},
            loading: false,
        };
    },
    methods: {
        getInfo(){
            let companyName = encodeURIComponent(this.$route.query.companyName);
            let country = this.$route.query.country;
            this.loading = true;
            this.$axios.get(`${this.$clueUrl}/BuyerLeads/GetBusinessInformation?country=${country}&company=${companyName}`).then((res) => {
                const { isSuccess, data } = res;
                this.loading = false;
                if (isSuccess) {
                    this.businessInfo = data;
                }
            });
        },
    },
    mounted() {
        this.getInfo();
    },
};
</script>

<style lang="scss" scoped>

</style>