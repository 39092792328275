<template>
    <div>
        <div style="display: flex;height: 32px;align-items: center;">
            <span style="font-size: 14px;padding-right: 30px;">回复/转发前缀:</span>
            <!-- @input="setRoutine" -->
            <el-radio-group v-model="routineInfo.language">
                <el-radio label="cn">回复:/转发:</el-radio>
                <el-radio label="en">Re:/Fw:</el-radio>
            </el-radio-group>
        </div>

        <div style="margin-top: 20px;">
            <el-checkbox v-model="routineInfo.isAutoReply">启用自动回复</el-checkbox>
            <div style="height:20px;"></div>
            <Editor v-model="routineInfo.autoReplyHtml" placeholder="自动回复内容" :init="{
                language: 'zh_CN',
                resize: false,
                height: '300px',
                branding: false,
                menubar: false,
                toolbar: 'undo redo | emoticons | image link | formatselect fontsizeselect forecolor | bold underline italic | alignleft aligncenter alignright | preview fullscreen insertVariable',
                plugins: 'emoticons image link preview fullscreen',
                paste_data_images: true,
                urlconverter_callback,
                images_upload_handler}"
                tinymceScriptSrc="tinymce/tinymce.min.js"
                apiKey="l6y3tipsjmozdy96gs2qcbjjjaauujp07q4r0rpnyxqjh4ac">
            </Editor>
        </div>
        <div style="display: flex;margin-top: 20px;align-items: center;">
            <el-checkbox v-model="routineInfo.isAutoReplyDate">在以下时间段内自动回复</el-checkbox>
            <span style="padding: 0 10px;"></span>
            <el-date-picker v-model="routineInfo.autoReplyDateStart" :disabled="!routineInfo.isAutoReplyDate" size="small" type="date" value-format="yyyy-MM-dd" placeholder="开始日期"></el-date-picker>
            <span style="padding: 0 10px;"></span>
            <el-date-picker v-model="routineInfo.autoReplyDateEnd" :disabled="!routineInfo.isAutoReplyDate" size="small" type="date" value-format="yyyy-MM-dd" placeholder="结束日期"></el-date-picker>
        </div>
        <div style="margin-top: 20px;">
            <el-button type="primary" @click="setRoutine" size="small">保存设置</el-button>
        </div>
    </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';
export default {
    components: { Editor },
    data() {
        return {
            routineInfo:{
                "emailKey": "",
                "language": "cn",
                "fontSize": "",
                "pageSize": 10,
                "signKey": "",
                "isNextMail": false,
                "isMailList": false,

                "isAutoReply": false,
                "isAutoReplyDate": false,
                "autoReplyDateStart": "",
                "autoReplyDateEnd": "",
                "autoReplyHtml": "",
            }
        };
    },
    methods: {
        urlconverter_callback(url, node, on_save, name){
            // console.log('图片链接', url );
		    return url;
        },
        async images_upload_handler(blobInfo) {
            try {
                const formData = new FormData();
                formData.append("folderKey", "0");
                formData.append("remarkName", "");
                formData.append("permission", "我的");
                formData.append("file", blobInfo.blob());
                const res = await this.$axios({
                    method: "POST",
                    url: this.$diskUrl + "/OtherFile/Upload",
                    data: formData,
                });
                return res.fileUrl;
            } catch (error) {
                this.$message.error("插入图片失败");
            }
        },

        getRoutine(){
            this.$axios.get(`${this.$mailUrl}/v1/config/Routine`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.routineInfo = {
                        emailKey: data.emailKey,
                        language: data.language,
                        fontSize: data.fontSize,
                        pageSize: data.pageSize,
                        signKey: data.signKey,
                        isNextMail: data.isNextMail,
                        isMailList: data.isMailList,
                        isAutoReply: data.isAutoReply,
                        isAutoReplyDate: data.isAutoReplyDate,
                        autoReplyDateStart: data.autoReplyDateStart,
                        autoReplyDateEnd: data.autoReplyDateEnd,
                        autoReplyHtml: data.autoReplyHtml,
                    }
                }
            });
        },

        setRoutine(){
            // console.log(this.routineInfo);
            this.$axios.post(`${this.$mailUrl}/v1/config/Routine`,this.routineInfo).then((res) => {
                const { isSuccess } = res;
                if (isSuccess) {
                    this.$message({ message: '设置成功', type: 'success'});
                }
            });
        },
    },
    mounted() {
        this.getRoutine();
    },
};
</script>

<style lang="scss" scoped>

</style>