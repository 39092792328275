<template>
    <div class="PurchaseDetails">
        <div class="headline">采购详情</div>
        <div class="oneItem">
            <div class="leftImg">
                <el-image style="width: 100px; height: 100px" :src="showImgUrl" :preview-src-list="previewList"></el-image>
            </div>
            <div class="rightBox">
                <div>
                    <span class="title">{{detailsInfo.title}}</span>
                    <span class="date">{{detailsInfo.purchaseStartDate}} to {{detailsInfo.purchaseEndDate}}</span>
                    <el-tag effect="dark" type="success" size="mini" v-if="detailsInfo.expireStatus==1">长期</el-tag>
                    <el-tag effect="dark" type="info" size="mini" v-if="detailsInfo.expireStatus==2">过期</el-tag>
                    <el-tag effect="dark" type="warning" size="mini" v-if="detailsInfo.urgentStatus==1">紧急</el-tag>
                </div>
                <div class="oneHang" style="display: flex;">
                    <div style="margin-right:10px;"><span class="label">交货地：</span>{{detailsInfo.placeDelivery || "--"}}</div>
                    <div style="margin-right:10px;"><span class="label">采购国：</span>{{detailsInfo.purchasingCountry || "--"}}</div>
                </div>
                <div class="oneHang" style="display: flex;">
                    <div style="margin-right:10px;"><span class="label">公司：</span>{{detailsInfo.company || "--"}}</div>
                </div>
                <div class="oneHang" style="display: flex;">
                    <div style="margin-right:10px;"><span class="label">联系人：</span>{{detailsInfo.contacts || "--"}}</div>
                    <div style="margin-right:10px;"><span class="label">电话：</span>{{detailsInfo.contactPhone || "--"}}</div>
                    <div style="margin-right:10px;"><span class="label">邮箱：</span>{{detailsInfo.contactEmail || "--"}}</div>
                </div>
                <div class="oneHang">
                    <span class="label">采购量：</span>{{detailsInfo.purchaseVolume || "--"}} {{detailsInfo.unit}}
                </div>
                <div class="oneHang">
                    <span class="label">需求描述：</span>{{detailsInfo.describe || "--"}}
                </div>
                <div class="oneHang">
                    <span class="label">采购要求：</span>{{detailsInfo.requirement || "--"}}
                </div>
            </div>
        </div>
        <div class="picture" v-if="imgList.length >= 2">
            <div class="tt">图片信息</div>
            <el-image class="imgItem" v-for="(item,index) in imgList" :key="index" :src="item.imgUrl" :preview-src-list="previewList"></el-image>
        </div>
        <div class="fujian" style="margin-top: 10px;">
            <div class="tt">附件列表</div>
            <div v-if="attachmentList.length > 0">
                <div class="attchItem" v-for="(item,index) in attachmentList" :key="index">
                    <img src="@/assets/icon/fujian.png" />
                    <el-link :href="item.imgUrl" :underline="false" target="_blank">
                        <span style="padding-right: 10px;">{{item.imgName}}</span>
                        <i class="el-icon-download" style="font-size: 20px;"></i>
                    </el-link>
                </div>
            </div>
            <div v-else>
                <span style="padding-left:10px;">暂无数据</span>
            </div>
        </div>
        <div class="supplier" style="margin-top: 10px;">
            <div class="tt">供应商信息</div>
            <el-table :data="suppliers" style="width: 100%" stripe size="small">
                <el-table-column prop="company" label="供应商"></el-table-column>
                <el-table-column prop="contacts" label="联系人"></el-table-column>
                <el-table-column prop="contactPhone" label="联系人电话"></el-table-column>
                <el-table-column prop="describe" label="供应描述"></el-table-column>
                <el-table-column prop="createTime" label="创建时间"></el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            //采购详情
            detailsInfo: {},
            imgList: [],
            attachmentList: [],
            previewList: [],
            showImgUrl: "",
            suppliers: [],
        };
    },
    methods: {
        getDetails(){
            let key = this.$route.query.key;
            let ukey = JSON.parse(localStorage.userInfo).uKey; //记录用户进入详情
            this.$axios.get(`${this.$OAService}/GetPurchaseDemandDetails?key=${key}&ukey=${ukey}`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.detailsInfo = data;
                    this.imgList = data.imgs.filter(ele => ele.type == 0);
                    this.attachmentList = data.imgs.filter(ele => ele.type == 1);
                    this.previewList = this.imgList.map(ele => ele.imgUrl);
                    this.showImgUrl = this.imgList[0].imgUrl;
                    this.suppliers = data.suppliers;
                }
            })
        },
    },
    mounted() {
        this.getDetails();
    },
};
</script>

<style lang="scss" scoped>
.PurchaseDetails{
    background: #FFFFFF;
    padding: 10px 20px;
    overflow: hidden;
    .headline{
        font-size: 20px;
        line-height: 40px;
        color: #333;
        font-weight: bold;
    }
    .oneItem{
        padding: 10px;
        display: flex;
        .leftImg{
            margin-right: 20px;
        }
        .rightBox{
            font-size: 14px;
            color: #3A3A3A;
            .title{
                display: inline-block;
                font-size: 18px;
                height: 32px;
                line-height: 32px;
                color: #333;
                font-weight: bold;
            }
            .date{
                display: inline-block;
                padding-left: 20px;
            }
            .oneHang{
                line-height: 24px;
            }
            .label{
                color: #999999;
            }
        }
    }
    .picture,.fujian,.supplier{
        .tt{
            height: 20px;
            line-height: 20px;
            font-size: 16px;
            font-weight: bold;
            color: #3A3A3A;
            margin-bottom: 10px;
            border-left: 3px solid #E6A23C;
            padding-left: 5px;
        }
        .imgItem{
            width: 80px;
            height: 80px;
            display: inline-block;
            margin-right: 10px;
        }
        .attchItem{
            display: inline-flex;
            width: 180px;
            height: 60px;
            background: #d9d9d9;
            margin-right: 10px;
            img{
                width: 27px;
                height: 33px;
                margin: 12px;
            }
        }
    }
}
</style>