<script>
import Vue from 'vue'
import { reactive, ref } from '@vue/composition-api'
import { Message } from 'element-ui'
import Editor from '@tinymce/tinymce-vue'
import axios from 'axios'
import qs from 'qs'
import { uploadFile } from '../api'
export default Vue.extend({
  components: { Editor },
  setup(props, { emit }) {
    const InputDom = ref()
    const loading = ref(false)
    const isWriteEmail = ref(false)

    const form = reactive({
      subject: '',
      bodyHtml: '',
      attachments: [], // as { fileName: string, fileUrl: string, fileSize: string }[]
      templateKey: '',
      //   isTiming: false,
      //   sendTime: ''
    })
    const onInputChange = async (event) => {
      const file = event.target.files[0]
      loading.value = true
      const res = await uploadFile(file)
      loading.value = false
      res && form.attachments.push({ fileName: res.name, fileUrl: res.url, fileSize: res.size })
    }
    const onDone = () => {
      if (form.subject && form.bodyHtml) {
        emit('done', form)
      } else {
        Message.warning('请输入主题和邮件内容')
      }
    }
    // 获取模板列表
    const templateList = reactive({
      myTemplate: [],
      maxShowNum: 5,
      tabName: "节日模板",
      tabList: [],
      tabTemplate: [],
    })
    const getTemplate = async () => {
      try {
        const res = await axios({
          method: 'GET',
          url: Vue.prototype.$marketUrl + '/MassSending/GetEmailTemplateList',
        })
        if (res.isSuccess) {
          templateList.tabList = res.data.filter(ele => ele.typeName != "我的模板");
          templateList.myTemplate = res.data.filter(ele => ele.typeName == "我的模板")[0].datas;
          templateList.tabTemplate = res.data.filter(ele => ele.typeName == templateList.tabName)[0].datas;
        }
      } catch {
        templateList.myTemplate = [];
        templateList.tabTemplate = [];
      }
    }

    getTemplate();

    const tabClick = (value) => {
      templateList.tabName = value;
      templateList.tabTemplate = templateList.tabList.filter(ele => ele.typeName == templateList.tabName)[0].datas;
    }

    // 获取模板内容
    const getTempContent = async (templateKey) => {
      const res = await axios({
        method: 'GET',
        url: Vue.prototype.$marketUrl + '/MassSending/GetEmailTemplateByKey',
        params: {
          templatekey: templateKey
        }
      })
      if (res.isSuccess) {
        isWriteEmail.value = true;
        form.subject = res.data.subject;
        form.bodyHtml = res.data.textHtml;
        form.templateKey = templateKey;
      }
    }
    const goBack = () => {
      isWriteEmail.value = false;
    }
    const onPreview = (ele) => {
      window.open(ele.htmlImg);
    }

    const emailVariable = ref();

    const chooseVariable = (value) => {
      if (form.bodyHtml.indexOf(value) == -1) {
        form.bodyHtml = form.bodyHtml + value;
      }
      emailVariable.value = "";
    }

    const url = window.location.href
    const index = url.lastIndexOf('?')
    const eventNo = qs.parse(url.slice(index + 1))['eventNo'] || '';
    if (eventNo) {
      (async () => {
        const res = await axios({
          method: 'GET',
          url: Vue.prototype.$marketUrl + '/MassSending/GetEventDetailed',
          params: { eventNo }
        })
        if (res.isSuccess) {
          isWriteEmail.value = true;
          const { subject, bodyHtml, attachments, templateKey } = res.data;
          form.subject = subject;
          form.bodyHtml = bodyHtml;
          form.attachments = attachments;
          form.templateKey = templateKey;
        }
      })()
    }

    return {
      InputDom,
      loading,
      isWriteEmail,
      templateList,
      form,
      onInputChange,
      onDone,
      getTemplate,
      tabClick,
      getTempContent,
      goBack,
      onPreview,
      emailVariable,
      chooseVariable,
      images_upload_handler: async (blobInfo) => {
        try {
          const formData = new FormData()
          formData.append('folderKey', '0')
          formData.append('remarkName', '')
          formData.append('permission', '我的')
          formData.append('file', blobInfo.blob())
          const res = await axios({
            headers: {
              Authorization: 'Bearer ' + window.localStorage.getItem('token')
            },
            method: 'POST',
            url: Vue.prototype.$diskUrl + '/OtherFile/Upload',
            data: formData
          })
          return res.fileUrl;
        } catch (error) {
          Message.error('插入图片失败')
        }
      },
      u() { InputDom.value.click() }
    }
  }
})
</script>

<template>
  <div class="content-step-2">
    <div style="position: absolute; bottom: 20px; right: 20px">
      <el-button @click="$emit('prev')" type="primary" size="mini">上一步</el-button>
      <el-button @click="onDone" type="primary" size="mini">下一步</el-button>
    </div>
    <div class="chooseTemplate" v-show="!isWriteEmail">
      <div class="title">我的模板</div>
      <div class="templateList">
        <div class="templateItem" @click="isWriteEmail = true; form.templateKey = ''; form.subject = ''; form.bodyHtml = '';">
          <i class="el-icon-plus"></i>
        </div>
        <div class="templateItem" v-for="ele in templateList.myTemplate.slice(0, templateList.maxShowNum)" :key="ele.templateKey">
          <el-image class="el-image" :src="ele.htmlImg" fit="cover"></el-image>
          <div class="backdrop">
            <div class="preview" @click="onPreview(ele)">预览模板</div>
            <div class="touse" @click="getTempContent(ele.templateKey)">使用模板</div>
          </div>
          <div class="theme utils-omit-1" :title="ele.subject">{{  ele.subject  }}</div>
        </div>
        <!-- 我的模板：查看更多；收起更多； -->
        <div class="viewMore" v-if="templateList.myTemplate.length > 5">
          <el-link type="primary" :underline="false" v-if="templateList.maxShowNum < templateList.myTemplate.length" @click="templateList.maxShowNum = templateList.myTemplate.length">查看更多</el-link>
          <el-link type="primary" :underline="false" v-else @click="templateList.maxShowNum = 5">收起更多</el-link>
        </div>
      </div>
      <el-radio-group v-model="templateList.tabName" style="margin-bottom: 10px;" size="small" @change="tabClick">
        <el-radio-button v-for="(item, index) in templateList.tabList" :key="index" :label="item.typeName"></el-radio-button>
      </el-radio-group>
      <div class="templateList">
        <div class="templateItem afrgerngneignitngtnrign" v-for="ele in templateList.tabTemplate" :key="ele.templateKey">
          <el-image class="el-image" :src="ele.htmlImg" fit="cover"></el-image>
          <div class="backdrop">
            <div class="preview" @click="onPreview(ele)">预览模板</div>
            <div class="touse" @click="getTempContent(ele.templateKey)">使用模板</div>
          </div>
          <div class="theme utils-omit-1" :title="ele.subject">{{  ele.subject  }}</div>
        </div>
      </div>
    </div>
    <div class="writeEmail" v-show="isWriteEmail">
      <el-page-header @back="goBack" title="选择模板" content=""></el-page-header>
      <div style="display: flex; align-items: center; border-bottom: 1px solid #C7C7C7; margin-bottom: 10px">
        <p style="display: flex; justify-content: space-between; flex-shrink: 0; width: 80px; color: #9C9C9C; font-size: 14px">
          <span>主</span>
          <span>题：</span>
        </p>
        <el-input v-model.trim="form.subject" class="el-input-no-border" size="small" />
      </div>
      <div v-loading="loading" style="box-sizing: border-box; height: 70px">
        <div @click="u" style="cursor: pointer; width: fit-content; font-size: 14px; height: 26px; display: flex; align-items: center; overflow: hidden">
          <img src="../../image/paperclip-orange.png" />
          <span style="margin-left: 5px">附件</span>
        </div>
        <input @change="onInputChange" ref="InputDom" type="file" v-show="false" />
        <div style="height: 44px; overflow-y: auto">
          <div v-for="(item, index) of form.attachments" :key="item.fileUrl" style="display: flex; align-items: center; justify-content: space-between">
            <div style="display: flex; align-items: center; font-size: 14px">
              <i class="el-icon-document"></i>
              <span style="margin-left: 5px">{{  item.fileName  }}</span>
              <span style="margin-left: 10px">{{  item.fileSize  }}</span>
            </div>
            <i @click="form.attachments.splice(index, 1)" class="el-icon-close" style="cursor: pointer; color: #F56C6C"></i>
          </div>
        </div>
      </div>
      <div style="height: calc(100% - 180px)" class="EditorContent">
        <Editor v-model="form.bodyHtml" :init="{
            language: 'zh_CN',
            resize: false,
            height: '590px',
            branding: false,
            menubar: false,
            toolbar: 'undo redo | emoticons | image link | formatselect fontsizeselect forecolor | bold underline italic | alignleft aligncenter alignright | preview fullscreen',
            plugins: 'emoticons image link preview fullscreen',
            images_upload_handler}"
            tinymceScriptSrc="tinymce/tinymce.min.js"    
            apiKey="l6y3tipsjmozdy96gs2qcbjjjaauujp07q4r0rpnyxqjh4ac" />
        <el-select v-model="emailVariable" placeholder="邮件变量" @change="chooseVariable" size="small" class="selectBtn">
          <el-option label="姓名变量" value="[FIRSTNAME]"></el-option>
          <el-option label="公司名变量" value="[COMPANY]"></el-option>
        </el-select>
      </div>
      <!-- <div style="height: 32px; display: flex; align-items: center; overflow: hidden">
      <el-checkbox v-model="form.isTiming">定时发送</el-checkbox>
      <el-date-picker v-model="form.sendTime" value-format="yyyy-MM-dd HH:mm:ss" class="c-el-date-picker" type="datetime" size="mini" placeholder="定时发送时间" />
    </div> -->
    </div>
  </div>
</template>

<style scoped lang="scss">
.afrgerngneignitngtnrign:hover {
  .theme {
    color: #F56C6C !important;

  }
}

.content-step-2 {
  box-sizing: border-box;
  height: 100%;
  padding: 20px;
  padding-top: 0;
  position: relative;
}

.el-input-no-border {
  ::v-deep .el-input__inner {
    border: none;
  }
}

.c-el-date-picker {
  ::v-deep .el-input__inner {
    border: none;
  }
}

.chooseTemplate {
  height: calc(100% - 30px);
  overflow: auto;

  .title {
    height: 28px;
    line-height: 28px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .templateList {
    display: flex;
    flex-wrap: wrap;

    .viewMore {
      width: 150px;
      height: 200px;
      text-align: center;
      line-height: 200px;

      .el-link {
        font-size: 20px;
      }
    }
  }

  .templateItem {
    width: 150px;
    height: 200px;
    border: 1px solid #c9c9c9;
    padding: 10px;
    box-sizing: border-box;
    cursor: pointer;
    margin-right: 20px;
    margin-bottom: 40px;
    text-align: center;
    position: relative;

    .el-image {
      width: 128px;
      height: 178px;
      object-fit: cover;
    }

    .el-icon-plus {
      font-size: 40px;
      line-height: 178px;
    }

    .theme {
      margin-top: 10px;
      color: #6a6a6a;
      font-size: 14px;
    }

  }

  .backdrop {
    width: 150px;
    height: 200px;
    display: none;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    left: 0;

    .preview {
      position: absolute;
      left: 0;
      bottom: 45px;
      width: 100%;
      height: 30px;
      line-height: 30px;
      background-color: #ccc;
      color: #fff;
    }

    .touse {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 30px;
      line-height: 30px;
      background-color: #ccc;
      color: #fff;
      &:hover{
        background-color: var(--themeColor);
      }
    }
  }

  .templateItem:hover .backdrop {
    display: inline-block;
  }
}

.writeEmail {
  height: calc(100% - 30px);
  overflow: auto;
}

.EditorContent {
  position: relative;

  .selectBtn {
    position: absolute;
    top: 4px;
    left: 650px;
    z-index: 1000;
    width: 120px;
  }

  ::v-deep .el-input__inner {
    border: none;
  }
}
</style>
