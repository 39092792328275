<template>
  <!-- 复用 -->
  <!-- encode-analyse-x -->
  <div class="tab-encode-encode-analyse-unit">
    <div class="content">
      <div>
        <div class="bar-item" v-for="item of barItems" :key="item.encode">
          <div class="encode">{{ item.encode }}</div>
          <el-progress style="width: 390px; flex-shrink: 0" :color="barColor" :stroke-width="16" :percentage="item.percentage" :show-text="false" />
          <div class="indicator">{{ item.indicator }}</div>
          <div class="description">({{ item.name }})</div>
        </div>
      </div>
      <el-table :data="tableData" size="mini" style="font-size: 14px; color: #1f2d3d" :header-cell-style="{ background: '#E5E9F2', color: '#1F2D3D' }">
        <el-table-column prop="order" width="50px" align="center" />
        <el-table-column prop="encode" label="HS编码" align="center" width="80px" />
        <el-table-column prop="name" label="商品名称" show-overflow-tooltip />
        <el-table-column prop="frequencyStr" label="频次" width="120px" show-overflow-tooltip />
        <el-table-column prop="amountStr" label="金额" width="120px" show-overflow-tooltip />
        <el-table-column prop="countStr" label="数量" width="120px" show-overflow-tooltip />
        <el-table-column prop="weightStr" label="重量" width="120px" show-overflow-tooltip />
      </el-table>
      <div style="margin-top: 1em; display: flex; justify-content: flex-end">
        <el-pagination background layout="total, prev, pager, next" :total="pagination.total" :page-size="pagination.pageSize" :current-page="pagination.pageIndex" @current-change="handlePageIndexChange" />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters, mapMutations } from "vuex";
import axios from "@/firm/plugins/axios";

export default Vue.extend({
  inheritAttrs: false,
  props: ["anchor", "option", "refreshTrigger", "barColor", "httpUrl", "sitc"],
  data() {
    return {
      tableData: [],
      barItems: [],
      pagination: {
        total: 0,
        pageSize: 10,
        pageIndex: 1,
      },
    };
  },
  computed: {
    ...mapState(["company", "companyType"]),
    ...mapState("controlFormGlobal", {
      ie: "ie",
      dataCountry: "dataCountry",
      dateRange: "dateRange",
      hsCode: "hsCode",
      importer: "importer",
      exporter: "exporter",
      country: "country",

      exHsCode: "exHsCode",
      exExporter: "exExporter",
      exImporter: "exImporter",
      exCountry: "exCountry",
    }),
    ...mapGetters("controlFormGlobal", {
      startTime: "startTime",
      endTime: "endTime",
    }),
  },
  watch: {
    option() {
      this.getTable();
    },
    refreshTrigger() {
      this.getTable();
    },
  },
  methods: {
    ...mapMutations("anchor", {
      setAnchorFlag: "setAnchorFlag",
    }),
    //分页器页码改变时触发
    handlePageIndexChange(pageIndex) {
      this.getTable(pageIndex);
    },
    async getTable(pageIndex = 1) {
      try {
        this.$emit("start");
        const res = await axios({
          method: "post",
          url: this.httpUrl,
          data: {
            pageIndex,
            pageSize: this.pagination.pageSize,
            ie: this.ie,
            importer: this.importer,
            exporter: this.exporter,
            dataCountry: this.dataCountry,
            country: this.country,
            startTime: this.startTime,
            endTime: this.endTime,
            hsCode: this.hsCode,
            sortType: this.option,
            noHsCode: this.exHsCode,
            noImporter: this.exImporter,
            noExporter: this.exExporter,
            noCountry: this.exCountry,
            company: this.company,
            companyType: this.companyType,
          },
        });
        if (res.data.result.data.length === 0) {
          throw new Error();
        }
        this.tableData = res.data.result.data;
        this.pagination.total = res.data.result.total;
        this.pagination.pageIndex = pageIndex;
        const keys = ["frequency", "amount", "count", "weight"];
        const base = this.tableData[0][keys[this.option]] / 0.8;
        this.barItems = this.tableData.map((item) => ({
          encode: item.encode,
          percentage: (item[keys[this.option]] / base) * 100,
          indicator: item[`${keys[this.option]}Str`],
          name: item.name,
        }));
        this.setAnchorFlag([[this.anchor, true]]);
        this.$emit("sucess");
      } catch {
        this.setAnchorFlag([[this.anchor, false]]);
        this.$emit("fail");
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.tab-encode-encode-analyse-unit {
  color: #2a3f58;
  .title {
    padding-left: 0.5em;
    margin-bottom: 0.8em;
  }
  .content {
    padding-bottom: 1em;
  }
  .bar-item {
    display: flex;
    align-items: center;
    margin-bottom: 0.8em;
    $padding-horzon: 12px;
    .encode {
      flex-shrink: 0;
      width: 6em;
      text-align: right;
      padding-right: $padding-horzon;
    }
    .indicator {
      flex-shrink: 0;
      word-break: break-all;
      width: 7em;
      padding-right: 2 * $padding-horzon;
      padding-left: $padding-horzon;
    }
    .description {
      display: flex;
      align-items: center;
      color: #8492a6;
    }
  }
}
</style>
