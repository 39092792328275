<template>
        <el-dialog title="行业分类(NACE)" :visible.sync="NACE_dialogVisible" width="800px" @close="$emit('closeDialog')">
            <el-input v-model="searchCode" @input="onSearch" size="small" style="margin-bottom:10px;" placeholder="请输入NACE编号" clearable></el-input>
            <div class="selectBox">
                <div class="leftBox">
                    <div class="twoCodeItem" v-for="item in NACETwoCodeList" :key="item.code" @click="NACE_chooseTwoCode = item.code;"
                        :class="{'active':item.code == NACE_chooseTwoCode}">
                        {{item.describeCn}}({{item.code}})
                    </div>
                </div>
                <div class="rightBox">
                    <div class="fourCodeItem" v-for="item in NACEFourCodeList" :key="item.code" @click="clickNACEFourCode(item)"
                        :class="{'active':item.isSelect == true}">
                        {{item.describeCn}}({{item.code}})
                    </div>
                </div>
            </div>
             <div class="haveSelectBox">
                <span class="">已选择：</span>
                <el-tag v-for="(item,index) in NACE_selectList" :key="item.code" closable type="info" style="margin-bottom: 5px;"
                    @close="closeNACEOne(item,index)">
                    {{item.describeCn}}({{item.code}})
                </el-tag>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="NACE_reset">重 置</el-button>
                <el-button size="small" type="primary" @click="NACE_selectSubmit">确 定</el-button>
            </span>
        </el-dialog>
</template>

<script>
export default {
    props: ["NACE_dialogVisible"],
    data() {
        return {
            searchCode: "",
            NACEOption: [],
            NACE_chooseTwoCode: "",
            NACE_selectList: [],
        };
    },
    computed:{
        NACETwoCodeList(){
            let leftItem = this.NACEOption.filter(item => item.code.indexOf(this.searchCode.slice(0,2)) > -1);
            return leftItem;
        },
        NACEFourCodeList(){
            let oneItem = this.NACEOption.filter(item => item.code == this.NACE_chooseTwoCode);
            if(oneItem.length > 0){
                let twoItem = oneItem[0].industryCodes.filter(item => item.code.indexOf(this.searchCode) > -1);
                return twoItem;
            }else{
                return [];
            }
        },
    },
    methods: {
        //查询infobel 行业代码信息(NACE)
        getNACE(){
            this.$axios.get(`${this.$clueUrl}/BusinessData/Infobel/IndustryCodeNACE`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    data.forEach(element =>{
                        element.industryCodes.forEach(item =>{
                            item.isSelect = false;
                        })
                    })
                    this.NACEOption = data;
                    this.NACE_chooseTwoCode = data[0].code;  //默认选择第一个
                }
            });
        },
        clickNACEFourCode(item){
            if(item.isSelect){
                item.isSelect = false;
            }else{
                item.isSelect = true;
            }
            this.getSelectList();
        },
        closeNACEOne(closeItem,index){
            this.NACE_selectList.splice(index, 1);
            this.NACEOption.forEach(element =>{
                element.industryCodes.forEach(item =>{
                    if(item.code == closeItem.code){
                        item.isSelect = false;
                    }
                })
            })
        },
        getSelectList(){
            let oneList = [];
            this.NACEOption.forEach(element =>{
                element.industryCodes.forEach(item =>{
                    if(item.isSelect){
                        oneList.push({
                            describeCn: item.describeCn,
                            code: item.code
                        })
                    }
                })
            })
            this.NACE_selectList = oneList;
        },
        NACE_reset(){
            this.NACEOption.forEach(element =>{
                element.industryCodes.forEach(item =>{
                    item.isSelect = false;
                })
            })
            this.NACE_selectList = [];
            this.$emit('closeDialog');
            this.$emit('onSubmit',this.NACE_selectList);
        },
        NACE_selectSubmit(){
            this.$emit('closeDialog');
            this.$emit('onSubmit',this.NACE_selectList);        
        },

        onSearch(){
            if(this.NACETwoCodeList.length){
                this.NACE_chooseTwoCode = this.NACETwoCodeList[0].code;
            }
        },
    },
    mounted() {
        this.getNACE();
    },
};
</script>

<style lang="scss" scoped>
.selectBox{
    display: flex;
    border: 1px solid #D7D7D8;
    .leftBox{
        width: 40%;
        height: 320px;
        background-color: #EFEFEF;
        overflow-y: scroll;
        cursor: pointer;
        .twoCodeItem{
            padding: 0 20px;
            height: 40px;
            line-height: 40px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            &:hover{
                color: var(--themeColor);
            }
        }
        .active{
            color: var(--themeColor);
        }
    }
    .rightBox{
        width: 60%;
        height: 320px;
        overflow-y: scroll;
        cursor: pointer;
        .fourCodeItem{
            padding: 0 20px;
            height: 40px;
            line-height: 40px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            &:hover{
                color: var(--themeColor);
                background-color: #EFEFEF;
            }
        }
        .active{
            color: var(--themeColor);
        }
    }
}
.haveSelectBox{
    margin-top: 10px;
    border: 1px solid #D7D7D8;
    padding: 10px;
}
/deep/ .el-dialog__body{
    border-top: 1px solid #D9D9D9;
    border-bottom: 1px solid #D9D9D9;
    padding: 20px;
}
</style>