<template>
    <div class="AddInvoice">
        <el-alert title="先勾选订单信息,后填写发票信息。" type="warning"></el-alert>
        <div class="boxItem">
            <div class="title">订单信息</div>
            <el-table size="small" :data="orderList" @selection-change="selectionChange">
                <el-table-column type="selection" width="45"></el-table-column>
                <el-table-column prop="orderNo" label="订单编号"></el-table-column>
                <el-table-column prop="description" label="订单描述"></el-table-column>
                <el-table-column label="付款金额">
                    <template slot-scope="scope">
                        <span class="primaryColor">{{scope.row.discountPrice}}</span>元
                    </template>
                </el-table-column>
                <el-table-column prop="payTime" label="付款时间"></el-table-column>
            </el-table>
            <div class="payTotalMoney">
                开具发票合计金额：<span class="primaryColor">{{payTotalMoney}}</span>元
            </div>
        </div>
        <div class="boxItem" style="margin-top: 20px;">
            <div class="title">发票信息</div>
            <el-form label-width="140px" :model="invoiceInfo" size="small" style="width:40%;margin-top: 20px;">
                <el-form-item label="开具类型：" required>
                    <el-radio v-model="invoiceInfo.openType" :label="0" border>单位</el-radio>
                    <el-radio v-model="invoiceInfo.openType" :label="1" border>个人</el-radio>
                </el-form-item>
                <el-form-item label="发票抬头：" required>
                    <el-input v-model="invoiceInfo.invoiceTitle"></el-input>
                </el-form-item>
                <el-form-item label="发票类型：" required>
                    <el-radio v-model="invoiceInfo.invoiceType" :label="0" border>增值税普通发票</el-radio>
                    <el-radio v-model="invoiceInfo.invoiceType" :label="1" border>增值税专用发票</el-radio>
                </el-form-item>
                <el-form-item label="税务登记号：" required>
                    <el-input v-model="invoiceInfo.taxId"></el-input>
                </el-form-item>
                <el-form-item label="基开户银行名称：">
                    <el-input v-model="invoiceInfo.bankName"></el-input>
                </el-form-item>
                <el-form-item label="基开户账号：">
                    <el-input v-model="invoiceInfo.account"></el-input>
                </el-form-item>
                <el-form-item label="注册场所地址：">
                    <el-input v-model="invoiceInfo.registerAddress"></el-input>
                </el-form-item>
                <el-form-item label="注册固定电话：">
                    <el-input v-model="invoiceInfo.registerPhone"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitInvoice" :loading="btnLoading">开发票</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            //订单信息
            orderList: [],
            chooseOrder: [],
            payTotalMoney: 0,

            //发票信息
            invoiceInfo: {
                key: "",
                openType: 0,
                invoiceTitle: "",
                invoiceType: 0,
                taxId: "",
                bankName: "",
                account: "",
                registerAddress: "",
                registerPhone: "",
            },
            btnLoading: false,
        };
    },
    methods: {
        selectionChange(value){
            this.chooseOrder = value.map(ele => ele.orderNo);
            this.payTotalMoney = value.reduce((total,item) => {
                return total + item.discountPrice;
            } , 0);
        },

        //获取订单
        getOrder(){
            this.$axios.get(`${this.$payUrl}/SetMeal/GetNoBillingOrder`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.orderList = data;
                }
            });
        },

        //获取发票信息
        getInfo(){
            this.$axios.get(`${this.$orderUrl}/Invoice/GetInvoices`).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    if(data.length > 0){
                        this.invoiceInfo = data[0];
                    }
                }
            });
        },

        //开发票
        submitInvoice(){
            if(this.chooseOrder.length == 0){
                this.$message({ message: '请勾选需要开票的订单!', type: 'warning'});
                return false;
            }
            if(!this.invoiceInfo.invoiceTitle || !this.invoiceInfo.taxId){
                this.$message({ message: '请填写发票抬头和税务登记号!', type: 'warning'});
                return false;
            }
            this.btnLoading = true;
            this.$axios.post(`${this.$orderUrl}/Invoice/SubmitInvoiceApply`,{
                orderNo: this.chooseOrder,
                ...this.invoiceInfo,
            }).then((res) => {
                const { isSuccess } = res;
                this.btnLoading = false;
                if (isSuccess) {
                    this.$router.push(`/MyInvoice`);
                    this.$message({ message: '提交成功,请等待工作人员审批。', type: 'success'});
                }
            });
        },

    },
    mounted() {
        //获取订单
        this.getOrder();
        //获取发票信息
        this.getInfo();
    },
};
</script>

<style lang="scss" scoped>
.AddInvoice {
    width: 1200px;
    margin: auto;
    .boxItem {
        padding: 0 20px 20px;
        background: #ffffff;
        box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.16);
        .title {
            font-size: 16px;
            font-weight: bold;
            height: 40;
            line-height: 40px;
            border-bottom: 1px solid #d9d9d9;
        }
    }
    .primaryColor{
        color: var(--themeColor);
        padding: 0 5px;
        font-size: 20px;
    }
    .payTotalMoney{
        text-align: right;
        font-size: 14px;
        height: 40px;
        line-height: 40px;
        color: #3a3a3a;
    }
}
</style>