<template>
<div>
    <div v-if="ie=='i'">
        <!-- 厄瓜多尔(ECU)进口提单明细 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th width="25%">提单号</th>
                        <td width="25%">{{billInfo.BlNumber}}</td>
                        <th width="25%">报关号</th>
                        <td width="25%">{{billInfo.BENo}}</td>
                    </tr>
                    <tr>
                        <th>进口商ID</th>
                        <td>{{billInfo.ImporterId}}</td>
                        <th>进口商电话</th>
                        <td>{{billInfo.Phone}}</td>
                    </tr>
                    <tr>
                        <th>进口商传真</th>
                        <td>{{billInfo.Fax}}</td>
                        <th>进口商邮箱</th>
                        <td>{{billInfo.Email}}</td>
                    </tr>
                    <tr>
                        <th>进口商主营业务</th>
                        <td colspan="3">{{billInfo.ImporterMainBusiness}}</td>
                    </tr>
                    <tr>
                        <th>6位HS编码</th>
                        <td>{{billInfo.HarmonizedCodePro}}</td>
                        <th>6位HS编码描述</th>
                        <td>{{billInfo.HarmonizedCodeDesc}}</td>
                    </tr>
                    <tr>
                        <th>产品状况</th>
                        <td>{{billInfo.Condition}}</td>
                        <th>品牌</th>
                        <td>{{billInfo.Brand}}</td>
                    </tr>
                    <tr>
                        <th>FOB总项总价</th>
                        <td>{{billInfo.TotalFob}}</td>
                        <th>项目编号</th>
                        <td>{{billInfo.ItemNumber}}</td>
                    </tr>
                    <tr>
                        <th>净重</th>
                        <td>{{billInfo.Weight}}</td>
                        <th>毛重</th>
                        <td>{{billInfo.GrossWeight}}</td>
                    </tr>
                    <tr>
                        <th>总项总净重</th>
                        <td>{{billInfo.TotalNetWeight}}</td>
                        <th>总项总毛重</th>
                        <td>{{billInfo.TotalGrossWeight}}</td>
                    </tr>
                    <tr>
                        <th>FOB总价（美元）</th>
                        <td>{{billInfo.Value}}</td>
                        <th>FOB单价（美元）</th>
                        <td>{{billInfo.FobUnit}}</td>
                    </tr>
                    <tr>
                        <th>CIF总价（美元）</th>
                        <td colspan="3">{{billInfo.Cif}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">运输信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">运输方式</th>
                        <td width="25%">{{billInfo.TransportType}}</td>
                        <th width="25%">运输单号</th>
                        <td width="25%">{{billInfo.ConocimientoEmb}}</td>
                    </tr>
                    <tr>
                        <th>运输公司</th>
                        <td>{{billInfo.TransCompany}}</td>
                        <th>船名/航班</th>
                        <td>{{billInfo.Ship}}</td>
                    </tr>
                    <tr>
                        <th>起运港</th>
                        <td>{{billInfo.LoadingPort}}</td>
                        <th>起运国</th>
                        <td>{{billInfo.LoadingCountry}}</td>
                    </tr>
                    <tr>
                        <th>来源国</th>
                        <td>{{billInfo.Country}}</td>
                        <th>目的国</th>
                        <td>{{billInfo.DestinationCountry}}</td>
                    </tr>
                    <tr>
                        <th>发货日期</th>
                        <td>{{billInfo.DeliveryDate}}</td>
                        <th>发货商</th>
                        <td>{{billInfo.ShipperCompany}}</td>
                    </tr>
                    <tr>
                        <th>发货国</th>
                        <td>{{billInfo.ProcedureCountry}}</td>
                        <th>出仓日期</th>
                        <td>{{billInfo.OutWarehouseDate}}</td>
                    </tr>
                    <tr>
                        <th>抵达日期</th>
                        <td>{{billInfo.ArrivalDate}}</td>
                        <th>付款日期</th>
                        <td>{{billInfo.PaymentDate}}</td>
                    </tr>
                    <tr>
                        <th>贸易商</th>
                        <td>{{billInfo.TraderCompany}}</td>
                        <th>收货商</th>
                        <td>{{billInfo.ReceiverCompany}}</td>
                    </tr>
                    <tr>
                        <th>联系人</th>
                        <td>{{billInfo.Contact}}</td>
                        <th>总项总运费（美元）</th>
                        <td>{{billInfo.TotalFreight}}</td>
                    </tr>
                    <tr>
                        <th>总项总保险（美元）</th>
                        <td>{{billInfo.TotalInsurance}}</td>
                        <th>保险(美元)</th>
                        <td>{{billInfo.Insurance}}</td>
                    </tr>
                    <tr>
                        <th>运费(美元)</th>
                        <td>{{billInfo.Freight}}</td>
                        <th>集装箱数量</th>
                        <td>{{billInfo.Container}}</td>
                    </tr>
                    <tr>
                        <th>包装数量</th>
                        <td>{{billInfo.Packages}}</td>
                        <th>包装类型</th>
                        <td>{{billInfo.PackageType}}</td>
                    </tr>
                    <tr>
                        <th>仓储公司</th>
                        <td>{{billInfo.CommercialDeposit}}</td>
                        <th>结算日期</th>
                        <td>{{billInfo.ManifestDate}}</td>
                    </tr>
                    <tr>
                        <th>CIF总项总价（美元）</th>
                        <td colspan="3">{{billInfo.TotalCif}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">海关信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">进口类型</th>
                        <td width="25%">{{billInfo.ImportType}}</td>
                        <th width="25%">进口申报数量</th>
                        <td width="25%">{{billInfo.ImpDeclarationNum}}</td>
                    </tr>
                    <tr>
                        <th>海关</th>
                        <td>{{billInfo.Customs}}</td>
                        <th>代理报关机构</th>
                        <td>{{billInfo.CustomsAgent}}</td>
                    </tr>
                    <tr>
                        <th>海关代理国际编号</th>
                        <td>{{billInfo.Refrendo}}</td>
                        <th>报关单序列号</th>
                        <td>{{billInfo.DeclarationNumber}}</td>
                    </tr>
                    <tr>
                        <th>货运代理</th>
                        <td>{{billInfo.ShippingAgency}}</td>
                        <th>海关货物总价值</th>
                        <td>{{billInfo.TotalValue}}</td>
                    </tr>
                    <tr>
                        <th>审查类型</th>
                        <td>{{billInfo.ReviewType}}</td>
                        <th>审查方</th>
                        <td>{{billInfo.Reviewer}}</td>
                    </tr>
                    <tr>
                        <th>从价税率/从价计征</th>
                        <td>{{billInfo.AdvaloremRate}}</td>
                        <th>从价税</th>
                        <td>{{billInfo.Adval}}</td>
                    </tr>
                    <tr>
                        <th>货币单位</th>
                        <td>{{billInfo.Currency}}</td>
                        <th>应纳税总额</th>
                        <td>{{billInfo.TotalImponible}}</td>
                    </tr>
                    <tr>
                        <th>装运日</th>
                        <td>{{billInfo.ShipDate}}</td>
                        <th>海关机构</th>
                        <td>{{billInfo.CustomsAgency}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
    <div v-if="ie=='e'">
        <!-- 厄瓜多尔(ECU)出口提单明细 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th width="25%">提单号</th>
                        <td width="25%">{{billInfo.BlNumber}}</td>
                        <th width="25%">报关号</th>
                        <td width="25%">{{billInfo.BENo}}</td>
                    </tr>
                    <tr>
                        <th>出口商ID</th>
                        <td>{{billInfo.ExporterId}}</td>
                        <th>出口商电话</th>
                        <td>{{billInfo.Phone}}</td>
                    </tr>
                    <tr>
                        <th>出口商传真</th>
                        <td>{{billInfo.Fax}}</td>
                        <th>出口商邮箱</th>
                        <td>{{billInfo.Email1}} {{billInfo.Email2}}</td>
                    </tr>
                    <tr>
                        <th>出口商主营业务</th>
                        <td colspan="3">{{billInfo.ExporterActividadPrincipal}}</td>
                    </tr>
                    <tr>
                        <th>6位HS编码</th>
                        <td>{{billInfo.HarmonizedCodePro}}</td>
                        <th>6位HS编码描述</th>
                        <td>{{billInfo.HarmonizedCodeDesc}}</td>
                    </tr>
                    <tr>
                        <th>产品状况</th>
                        <td>{{billInfo.Condition}}</td>
                        <th>品牌</th>
                        <td>{{billInfo.Brand}}</td>
                    </tr>
                    <tr>
                        <th>FOB总项总价</th>
                        <td>{{billInfo.TotalFob}}</td>
                        <th>项目编号</th>
                        <td>{{billInfo.ItemNumber}}</td>
                    </tr>
                    <tr>
                        <th>净重</th>
                        <td>{{billInfo.Weight}}</td>
                        <th>毛重</th>
                        <td>{{billInfo.GrossWeight}}</td>
                    </tr>
                    <tr>
                        <th>总项总净重</th>
                        <td>{{billInfo.TotalNetWeight}}</td>
                        <th>总项总毛重</th>
                        <td>{{billInfo.TotalGrossWeight}}</td>
                    </tr>
                    <tr>
                        <th>FOB总价（美元）</th>
                        <td>{{billInfo.Value}}</td>
                        <th>FOB单价（美元）</th>
                        <td>{{billInfo.FobUnit}}</td>
                    </tr>
                    <tr>
                        <th>CIF总价（美元）</th>
                        <td colspan="3">{{billInfo.Cif}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">运输信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">运输方式</th>
                        <td width="25%">{{billInfo.TransportType}}</td>
                        <th width="25%">运输单号</th>
                        <td width="25%">{{billInfo.ConocimientoEmb}}</td>
                    </tr>
                    <tr>
                        <th>运输公司</th>
                        <td>{{billInfo.TransCompany}}</td>
                        <th>船名/航班</th>
                        <td>{{billInfo.Ship}}</td>
                    </tr>
                    <tr>
                        <th>起运港</th>
                        <td>{{billInfo.LoadingPort}}</td>
                        <th>来源国</th>
                        <td>{{billInfo.OriginCountry}}</td>
                    </tr>
                    <tr>
                        <th>目的国</th>
                        <td>{{billInfo.Country}}</td>
                        <th>抵达日期</th>
                        <td>{{billInfo.ArrivalDate}}</td>
                    </tr>
                    <tr>
                        <th>保险(美元)</th>
                        <td>{{billInfo.Insurance}}</td>
                        <th>运费(美元)</th>
                        <td>{{billInfo.Freight}}</td>
                    </tr>
                    <tr>
                        <th>集装箱数量</th>
                        <td>{{billInfo.Container}}</td>
                        <th>包装数量</th>
                        <td>{{billInfo.Packages}}</td>
                    </tr>
                    <tr>
                        <th>包装类型</th>
                        <td>{{billInfo.PackageType}}</td>
                        <th>结算日期</th>
                        <td>{{billInfo.ManifestDate}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">海关信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">出口类型</th>
                        <td width="25%">{{billInfo.ExportType}}</td>
                        <th width="25%">海关</th>
                        <td width="25%">{{billInfo.Customs}}</td>
                    </tr>
                    <tr>
                        <th>代理报关机构</th>
                        <td>{{billInfo.CustomsAgent}}</td>
                        <th>海关机构</th>
                        <td>{{billInfo.CustomsAgency}}</td>
                    </tr>
                    <tr>
                        <th>海关代理国际编号</th>
                        <td>{{billInfo.Refrendo}}</td>
                        <th>报关单序列号</th>
                        <td>{{billInfo.DeclarationNumber}}</td>
                    </tr>
                    <tr>
                        <th>货运代理</th>
                        <td>{{billInfo.ShippingAgency}}</td>
                        <th>审查类型</th>
                        <td>{{billInfo.ReviewType}}</td>
                    </tr>
                    <tr>
                        <th>审查方</th>
                        <td>{{billInfo.Reviewer}}</td>
                        <th>从价税率</th>
                        <td>{{billInfo.AdvaloremRate}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
</div>
</template>

<script>
export default {
    props:["billInfo","ie"],
    data() {
        return {

        };
    },
    methods: {
        
    },
    mounted() {

    },
};
</script>

<style lang="scss" scoped>
.partItem {
    margin-bottom: 20px;
    /deep/.el-card__header {
        padding: 10px 20px;
        .headTitle {
            display: inline-block;
            padding-left: 10px;
            font-size: 14px;
            border-left: 2px solid var(--themeColor);
            font-weight: bold;
        }
    }
}
table {
    width: 100%;
    border-collapse: collapse;
    tr {
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;
        th,
        td {
            font-size: 12px;
            color: #666;
            height: 40px;
            border: 1px solid #eaeaea;
            text-align: center;
        }
        th {
            background-color: #f5f7fa;
            font-weight: 700;
        }
        .bg {
            background-color: var(--themeColor);
            color: #fff;
            font-weight: 700;
            opacity: 0.8;
        }
    }
}
</style>