<template>
    <div class="PurchaseDemand">
        <div class="headline">采购需求</div>
        <div class="filterBox">
            <el-select class="filterItem" v-model="filterCondition.type" size="small" @change="toSearch">
                <el-option v-for="item in optionOne" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
            <el-input class="filterItem" v-model="filterCondition.title" placeholder="标题" size="small" clearable></el-input>
            <el-input class="filterItem" v-model="filterCondition.describe" placeholder="采购描述" size="small" clearable></el-input>
            <el-input class="filterItem" v-model="filterCondition.requirement" placeholder="采购要求" size="small" clearable></el-input>
            <el-input class="filterItem" v-model="filterCondition.placeDelivery" placeholder="交货地" size="small" clearable></el-input>
            <el-select class="filterItem" v-model="filterCondition.purchasingCountry" placeholder="采购国" size="small" clearable @change="toSearch">
                <el-option v-for="item in optionTwo" :key="item" :label="item" :value="item"></el-option>
            </el-select>
            <el-button type="primary" size="small" @click="toSearch" style="margin-left: 10px;">搜 索</el-button>
        </div>
        <div class="oneItem" v-for="(item, index) in demandList" :key="index">
            <div class="leftImg">
                <el-image style="width: 100px; height: 100px" :src="item.showImgUrl" :preview-src-list="item.previewList"></el-image>
            </div>
            <div class="rightBox">
                <div>
                    <span class="title" @click="toViewDetails(item)">{{item.title}}</span>
                    <span class="date">{{item.purchaseStartDate}} to {{item.purchaseEndDate}}</span>
                    <el-tag effect="dark" type="success" size="mini" v-if="item.expireStatus==1">长期</el-tag>
                    <el-tag effect="dark" type="info" size="mini" v-if="item.expireStatus==2">过期</el-tag>
                    <el-tag effect="dark" type="warning" size="mini" v-if="item.urgentStatus==1">紧急</el-tag>
                </div>
                <div class="oneHang" style="display: flex;">
                    <div style="margin-right:10px;"><span class="label">交货地：</span>{{item.placeDelivery}}</div>
                    <div style="margin-right:10px;"><span class="label">采购国：</span>{{item.purchasingCountry}}</div>
                    <!-- <div style="margin-right:10px;"><span class="label">公司：</span>{{item.company}}</div>
                    <div style="margin-right:10px;"><span class="label">联系人：</span>{{item.contacts}}</div>
                    <div style="margin-right:10px;"><span class="label">电话：</span>{{item.contactPhone}}</div>
                    <div style="margin-right:10px;"><span class="label">邮箱：</span>{{item.contactEmail}}</div> -->
                </div>
                <div class="oneHang">
                    <span class="label">采购量：</span>{{item.purchaseVolume}} {{item.unit}}
                </div>
                <div class="oneHang">
                    <span class="label">需求描述：</span>{{item.describe}}
                </div>
                <div class="oneHang">
                    <span class="label">采购要求：</span>{{item.requirement}}
                </div>
                <el-button class="viewBtn" type="primary" size="small" @click="toViewDetails(item)">查看详情</el-button>
            </div>
        </div>
        <el-empty description="暂无数据" v-show="totalItems == 0"></el-empty>
        <el-pagination hide-on-single-page background layout="total,prev,pager,next" :total="totalItems" style="float:right;margin:15px 20px;" :current-page.sync="pageIndex" @current-change="pageChange"></el-pagination>
    </div>
</template>

<script>
export default {
    data() {
        return {
            demandList: [],
            pageIndex: 1,
            totalItems: 0,

            filterCondition:{
                type: "",               //""全部  0近一月 1近三月 2近半年 3近一年
                title: "",              //标题
                describe: "",           //采购描述
                requirement: "",        //采购要求
                placeDelivery: "",      //交货地
                purchasingCountry: "",  //采购国
            },
            optionOne:[],
            optionTwo:[],

            isYiwu: false,
        };
    },
    methods: {
        //采购需求
        getDemand(){
            let {title,describe,requirement,placeDelivery,purchasingCountry,type} = this.filterCondition;
            this.$axios.post(`${this.$OAService}/GetPurchaseDemandList`,{
                title,describe,requirement,placeDelivery,purchasingCountry,type,
                pageIndex: this.pageIndex,
                pageSize: 10,
            }).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    data.datas.forEach(item => {
                        item.previewList = item.imgs.filter(ele => ele.type == 0).map(ele => ele.imgUrl);
                        item.showImgUrl = item.imgs.filter(ele => ele.type == 0)[0].imgUrl;
                    });
                    this.demandList = data.datas;
                    this.totalItems = data.totalItems;
                }
            })
        },
        pageChange(page) {
            this.pageIndex = page;
            this.getDemand();
        },
        toSearch(){
            this.pageIndex = 1;
            this.getDemand();
        },

        //查看详情
        toViewDetails(item){
            // 义乌塞尔(特殊处理)
            let companyKey = JSON.parse(localStorage.userInfo).companyKey;
            this.isYiwu = companyKey == "8967ee9a38054e4d9e68ad800533d223" ? true : false;
            if(this.isYiwu){
                let routeData = this.$router.resolve({
                    path: "/purchaseDetailsTwo",
                    query: { key: item.key },
                });
                window.open(routeData.href, "_blank");
            }else{
                let routeData = this.$router.resolve({
                    path: "/purchaseDetails",
                    query: { key: item.key },
                });
                window.open(routeData.href, "_blank");
            }
        },

        //获取下拉框
        getSelectOption(){
            this.optionOne = [
                {value: "", label: "全部"},
                {value: "0", label: "近一月"},
                {value: "1", label: "近三月"},
                {value: "2", label: "近半年"},
                {value: "3", label: "近一年"},
            ]
            this.$axios.get(`${this.$OAService}/GetPurchasingCountryBox`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.optionTwo = data;
                    console.log(this.optionTwo);
                }
            })
        }
    },
    mounted() {
        this.getDemand();
        this.getSelectOption();
    },
};
</script>

<style lang="scss" scoped>
.PurchaseDemand{
    background: #FFFFFF;
    padding: 10px 20px;
    overflow: hidden;
    .headline{
        font-size: 20px;
        line-height: 40px;
        color: #333;
        font-weight: bold;
    }
    .oneItem{
        padding: 10px;
        border-bottom: 1px dashed #cfcfcf;
        display: flex;
        position: relative;
        .leftImg{
            margin-right: 20px;
        }
        .rightBox{
            font-size: 14px;
            color: #3A3A3A;
            .title{
                display: inline-block;
                font-size: 18px;
                height: 32px;
                line-height: 32px;
                color: #333;
                font-weight: bold;
                cursor: pointer;
            }
            .date{
                display: inline-block;
                padding-left: 20px;
            }
            .oneHang{
                line-height: 24px;
            }
            .label{
                color: #999999;
            }
        }
        .viewBtn{
            position: absolute;
            right: 0;
            top: 50px;
        }
    }
    .filterBox{
        display: flex;
        height: 32px;
        margin-bottom: 10px;
        .filterItem{
            margin-left: 10px;
            width: 150px;
        }
    }
}
</style>