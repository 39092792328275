<script>
import Vue from 'vue'
import { ref, defineComponent } from '@vue/composition-api'
import axios from '@/assets/js/axios'
import ShowTooltipOverflowText from '@/components/ShowTooltipOverflowText.vue'
export default defineComponent({
  components: {
    ShowTooltipOverflowText
  },
  props: ['businessKey'],
  setup(props) {
    const isExpand = ref(true)
    const list = ref([]);
    const getList = async () => {
      try {
        const res = await axios({
          method: 'GET',
          url: Vue.prototype.$sjUrl + '/BusinessChance/GetAnnexInfos',
          params: {
            businessKey: props.businessKey
          }
        })
        list.value = res.data.map(item => ({
          key: item.key,
          url: item.url,
          name: item.name,
          date: item.createTime,
          person: item.userCnName
        }))
      } catch { null }
    }
    getList()
    const inputDOM = ref()
    const handleAddClick = () => {
      inputDOM.value.click()
    }
    const onInputFileChange = async (event) => {
      try {
        const file = event.target.files[0]
        const formData = new FormData()
        formData.append('file', file)
        formData.append('permission', '我的')
        formData.append('remarkName', '')
        formData.append('folderKey', '')
        const res = await axios({
          method: 'POST',
          url: Vue.prototype.$diskUrl + '/File/Upload',
          data: formData
        })
        const data = res.data
        await axios({
          method: 'POST',
          url: Vue.prototype.$sjUrl + '/BusinessChance/AddAnnex',
          data: [{
            businessChanceKey: props.businessKey,
            name: data.remarkName,
            url: data.fileUrl,
            size: data.fileSize
          }]
        })
        getList()
      } catch { null }
    }
    const handleDeleteClick = async (key) => {
      try {
        const res = await axios({
          method: 'GET',
          url: Vue.prototype.$sjUrl + '/BusinessChance/DeleteAnnex',
          params: {
            key
          }
        })
        if (!res.isSuccess) throw new Error()
        getList()
      } catch { null }
    }
    return {
      isExpand,
      list,
      inputDOM,
      handleAddClick,
      onInputFileChange,
      handleDeleteClick,
      download(url) {
        window.open(url, '_blank')
      }
    }
  }
})
</script>

<template>
  <div>
    <div class="top" @click="isExpand = !isExpand">
      <span>{{ `附件(${list.length})` }}</span>
      <div style="display: flex; align-items: center">
        <input @change="onInputFileChange" ref="inputDOM" type="file" v-show="false" />
        <img @click="handleAddClick" src="@/assets/img/add-icon.png" style="margin-right: 20px" />
        <i :class="isExpand ? 'el-icon-arrow-down' : 'el-icon-arrow-up'" style="font-size: 20px"></i>
      </div>
    </div>
    <div class="body" :class="isExpand ? 'expand' : ''">
      <el-scrollbar style="height: 100%">
        <div class="item" v-for="item of list" :key="item.key">
          <div style="display: flex; align-items: center; justify-content: space-between; color: #707070">
            <span>{{ item.date }}</span>
            <div style="display: flex; align-items: center">
              <span>{{ item.person }}</span>
              <i @click="handleDeleteClick(item.key)" class="el-icon-delete" style="color: #F56C6C; cursor: pointer; margin-left: 20px"></i>
            </div>
          </div>
          <div style="display: flex; align-items: center">
            <img src="@/assets/img/annex-icon.png" style="margin-right: 5px" />
            <ShowTooltipOverflowText @click.native="download(item.url)" :text="item.name" width="calc(100% - 30px)" :custome-style="{ cursor: 'pointer', textDecoration: 'underline', color: 'var(--themeColor)' }" />
          </div>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<style scoped lang="scss">
.top {
  cursor: pointer;
  box-sizing: border-box;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 17px;
  background: #e5e9f2;
  font-size: 16px;
  border: 1px solid #d5d5d5;
  color: #000;
}
.body {
  box-sizing: border-box;
  border: 1px solid #d5d5d5;
  border-top: none;
  height: 0;
  overflow: hidden;
  transition: all 0.3s;
  &.expand {
    height: 120px;
  }
}
::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
}
.item {
  padding: 5px 20px;
  font-size: 14px;
  border-bottom: 1px solid #d5d5d5;
}
</style>
