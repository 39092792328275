<template>
    <div>
        <InterfaceName :interfaceName="interfaceName" />
        <InterfaceSpe :requestUrl="requestUrl" :requestMethod="requestMethod" />
        <InputParam :inputTableData="inputTableData" />
        <ReturnParam :returnObj="returnObj" />
    </div>
</template>

<script>
import InterfaceName from "../Acomponents/InterfaceName.vue";
import InterfaceSpe from "../Acomponents/InterfaceSpe.vue";
import InputParam from "../Acomponents/InputParam.vue";
import ReturnParam from "../Acomponents/ReturnParam.vue";

export default {
    components: { InterfaceName, InterfaceSpe, InputParam, ReturnParam },
    data() {
        return {
            interfaceName: "预览邮件内容",
            requestUrl: "https://crm.qixinerp.com/open/Email/PreviewMail",
            requestMethod: "GET",
            inputTableData: [],
            returnObj: {},
        };
    },
    methods: {},
    mounted() {
        this.inputTableData = [
            {
                paramName: "key",
                valueType: "string",
                explain: "邮箱",
                required: true,
            },
        ];
        this.returnObj = {
            isSuccess: true,
            errorMessage: null,
            data: {
                key: "622dd099-9a09-449d-b4cb-991b5f7bb9cb",
                recipients: [],
                cc: [],
                bcc: [],
                subject: "test",
                eMail: "pxx.peng@lsmaps.com",
                fromMail: "pxx.peng@lsmaps.com",
                fromMailName: "",
                htmlContent: "<p>dd<br><br></p>\n<p>2490565266@qq.com</p>",
                attachments: [],
                lables: [],
                createDate: "2022-05-26 11:03:39",
                isOpen: false,
                isCollection: false,
            },
        };
    },
};
</script>

<style lang="scss" scoped>

</style>