<template>
    <div class="TradeData">
        <div class="leftSide" v-show="sidebarVisible">
            <AdvancedSearch @inputComplete="inputComplete" />
        </div>
        <div class="rightSide" ref="rightSide">
            <el-tabs v-model="tabActiveName" @tab-click="topTypeChange">
                <el-tab-pane label="公司" name="公司"></el-tab-pane>
                <el-tab-pane label="产品" name="产品"></el-tab-pane>
                <el-tab-pane label="HS编码" name="HS编码"></el-tab-pane>
                <el-tab-pane label="进口商" name="进口商"></el-tab-pane>
                <el-tab-pane label="出口商" name="出口商"></el-tab-pane>
            </el-tabs>
            <div class="searchBox">
                <el-input prefix-icon="el-icon-search" v-model="searchField.keyDoc" :placeholder="inputplaceholder" @keyup.enter.native="toSearch"></el-input>
                <div class="btnStates" @click="screenDialogVisible = true;">
                    <!-- <img src="@/assets/aSearch/btnStates.png" alt="" /> -->
                   <svg t="1687227812799" class="svgIcon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2650" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24"><path d="M480 64C214.848 64 0 278.848 0 544S214.848 1024 480 1024 960 809.152 960 544 745.152 64 480 64zM893.696 512l-126.784 0c-5.504-138.56-45.952-260.992-107.136-342.72C790.144 231.936 881.984 360.64 893.696 512zM702.4 512 512 512 512 132.736C614.848 160.128 694.336 317.312 702.4 512zM448 132.736 448 512 257.6 512C265.664 317.312 345.152 160.128 448 132.736zM300.288 169.28C239.04 251.008 198.656 373.44 193.152 512L66.304 512C78.016 360.64 169.856 231.936 300.288 169.28zM66.304 576l126.784 0c5.504 138.56 45.952 260.992 107.136 342.72C169.856 856.064 78.016 727.36 66.304 576zM257.6 576 448 576l0 379.264C345.152 927.872 265.664 770.688 257.6 576zM512 955.264 512 576l190.4 0C694.336 770.688 614.848 927.872 512 955.264zM659.712 918.72c61.248-81.728 101.632-204.16 107.136-342.72l126.784 0C881.984 727.36 790.144 856.064 659.712 918.72z" p-id="2651" fill="var(--themeColor)"></path></svg>
                </div>
                <el-button type="primary" style="width:150px;margin:0 10px;" @click="toSearch">搜 索</el-button>
                <div class="btnScreen" @click="sidebarSwitch">
                    <!-- <img src="@/assets/aSearch/btnScreen.png" alt="" /> -->
                    <svg t="1687228199482" class="svgIcon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3666" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24"><path d="M874.4 219.2L576 576.8v208c0 4.8-2.4 9.6-5.6 12.8l-96 78.4c-10.4 8.8-26.4 0.8-26.4-12.8V576.8L149.6 219.2c-8.8-10.4-1.6-26.4 12-26.4h700c13.6 0 21.6 16 12.8 26.4z" p-id="3667" fill="var(--themeColor)"></path></svg>
                </div>
            </div>
            <div class="searchBefore" v-show="!isHaveSearch">
                <div v-if="recordList.length > 0" class="historyBox">
                    <div class="ttt">搜索记录</div>
                    <div class="historyItem" v-for="(item,index) in recordList" :key="index" @click="clikRecord(item.request)">
                        <div class="describe">{{item.requestDisplay}}</div>
                        <div class="time">{{item.createTimeStr}}</div>
                    </div>
                </div>
                <el-empty v-else description="暂无搜索记录" :image-size="200"></el-empty>
            </div>
            <div class="searchAfter" v-show="isHaveSearch">
                <div class="navTradeTab">
                    <div class="tabItem" :class="{'active':resultActive==0}" @click="tradeTabClick(0)">结果</div>
                    <div class="tabItem" :class="{'active':resultActive==1}" @click="tradeTabClick(1)">采购商</div>
                    <div class="tabItem" :class="{'active':resultActive==2}" @click="tradeTabClick(2)">供应商</div>
                    <div class="tabItem" :class="{'active':resultActive==3}" @click="tradeTabClick(3)">可视化排名</div>
                    <div class="rightOption">
                        <el-link type="primary" :underline="false" @click="exportResult" v-loading="exportLoading">
                            <i class="el-icon-download"></i>
                            <span>导出</span>
                        </el-link>
                        <span style="font-size: 14px; color: #6a6a6a;">(剩余{{ exportCount }}次)</span>
                    </div>
                </div>
                <div class="searchContent" v-show="resultActive==0">
                    <TradeRecord ref="TradeRecord" :searchField="searchField" />
                </div>
                <div class="searchContent" v-show="resultActive==1">
                    <BuyersRanking ref="BuyersRanking" :searchField="searchField" />
                </div>
                <div class="searchContent" v-show="resultActive==2">
                    <SuppliersRanking ref="SuppliersRanking" :searchField="searchField" />                    
                </div>
                <div class="searchContent" v-show="resultActive==3">
                    <VisualRanking ref="VisualRanking" :searchField="searchField" />
                </div>
            </div>
        </div>

        <!-- 筛选国家 -->
        <ScreenCountry :screenDialogVisible="screenDialogVisible" @onSave="countryCodeSave" />

    </div>
</template>

<script>
import AdvancedSearch from "./components/AdvancedSearch";
import ScreenCountry from "./components/ScreenCountry";
import TradeRecord from "../ComResult/TradeRecord";
import BuyersRanking from "../ComResult/BuyersRanking";
import SuppliersRanking from "../ComResult/SuppliersRanking";
import VisualRanking from "../ComResult/VisualRanking";
import dayjs from 'dayjs';
export default {
    components: { AdvancedSearch, ScreenCountry, TradeRecord, BuyersRanking, SuppliersRanking, VisualRanking },
    data() {
        return {
            //高级筛选
            sidebarVisible: true,
            //筛选国家
            screenDialogVisible: false,
            //搜索条件
            tabActiveName: "公司",
            inputplaceholder: "公司,使用逗号分隔多个关键字",
            searchField: {
                "pageIndex": 1,
                "pageSize": 10,
                "keyDoc": "",
                "keyDocType": 0,
                "optionalKeyDoc": "",
                "optionalType": 0,
                "dataClass": 2,
                "ie": "i",
                "startDate": "2020-01-01",
                "endDate": dayjs().format('YYYY-MM-DD'),
                "minValue": 0,
                "maxValue": 0,
                "minQuantity": 0,
                "maxQuantity": 0,
                "minWeight": 0,
                "maxWeight": 0,
                "minPrice": 0,
                "maxPrice": 0,
                "minWeightPrice": 0,
                "maxWeightPrice": 0,
                "isNotNullImporter": true,
                "isNotNullExporter": true,
                "isNotImporterForwarder": true,
                "isNotExporterForwarder": true,
                "billNo": "",
                "brand": "",
                "country": "",
                "countryCode": "",
                "exporter": "",
                "hsCode": "",
                "importer": "",
                "manufacturCompany": "",
                "port": "",
                "unLoadingPort": "",
                "product": "",
                "threeEnCountryCode": "",
                "destinationCountry": "",
                "dataSourceContinent": "",
                "container": "",
                "transportMode": "",
                "sortType": 0,
                "size": 0,
                "customerId": "",
                "reportType": 0,
                "tandType": 5,
                "multiDataCountryCode": "",
                "noThreeEnCountryCode": "",
                "noCountryCode": "",
                "noHsCode": "",
                "noImporter": "",
                "noExporter": "",
                "noProduct": "",
                "noCountry": "",
                "noMinValue": 0,
                "noMaxValue": 0,
                "noMinQuantity": 0,
                "noMaxQuantity": 0,
                "noMinWeight": 0,
                "noMaxWeight": 0
            },
            isHaveSearch: false,
            //搜索(结果0/采购商1/供应商2/可视化排名3)
            resultActive: 0,
            hasClickOne: false,
            hasClickTwo: false,
            hasClickThree: false,
            //展示搜索记录
            recordList: [],
            //导出数据
            exportLoading: false,
            exportCount: 0,
        };
    },
    methods: {
        //左侧边栏显示隐藏
        sidebarSwitch(){
            this.sidebarVisible = !this.sidebarVisible;
            if(this.sidebarVisible){
                this.$refs.rightSide.style.width = "calc(100vw - 260px)"
            }else{
                this.$refs.rightSide.style.width = "100vw";
            }
        },
        //左侧高级搜索条件改变
        inputComplete(value){
            console.log('高级搜索条件',value);
            this.searchField = Object.assign(this.searchField,value);
        },
        //头部关键词类型改变
        topTypeChange(){
            this.searchField.keyDoc = "";   //清空输入框值
            switch (this.tabActiveName) {
                case "公司":
                    this.searchField.keyDocType = 0;
                    this.inputplaceholder = "公司,使用逗号分隔多个关键字";
                    break;
                case "产品":
                    this.searchField.keyDocType = 1;
                    this.inputplaceholder = "产品名称或描述,使用逗号分隔多个关键字";
                    break;
                case "HS编码":
                    this.searchField.keyDocType = 2;
                    this.inputplaceholder = "HS编码,使用逗号分隔多个关键字";
                    break;
                case "进口商":
                    this.searchField.keyDocType = 3;
                    this.inputplaceholder = "进口商,使用逗号分隔多个关键字";
                    break;
                case "出口商":
                    this.searchField.keyDocType = 4;
                    this.inputplaceholder = "出口商,使用逗号分隔多个关键字";
                    break;
                default:
                    break;
            }
        },
        //筛选国家码保存
        countryCodeSave(value){
            console.log(value);
            this.screenDialogVisible = false;
            this.searchField.threeEnCountryCode = value.threeEnCountryCode;
            this.searchField.ie = value.ie;
            this.searchField.dataClass = value.dataClass;
        },

        //点击搜索
        toSearch(){
            if(this.searchField.keyDoc.trim() == ""){
                this.$message({ message: '搜索关键字不能为空!', type: 'warning'});
                return false;
            }
            this.isHaveSearch = true;
            this.resultActive = 0;
            this.hasClickOne = false;
            this.hasClickTwo = false;
            this.hasClickThree = false;
            //获取贸易数据结果
            this.$refs.TradeRecord.getResult(1);
        },
        //搜索结果切换
        tradeTabClick(type){
            if(this.searchField.keyDoc.trim() == ""){
                this.$message({ message: '搜索关键字不能为空!', type: 'warning'});
                return false;
            }
            this.resultActive = type;
            if(type == 1 && !this.hasClickOne){
                this.hasClickOne = true;
                this.$refs.BuyersRanking.getBuyers(1);
            }else if(type == 2 && !this.hasClickTwo){
                this.hasClickTwo = true;
                this.$refs.SuppliersRanking.getSuppliers(1);
            }else if(type == 3 && !this.hasClickThree){
                this.hasClickThree = true;
                this.$refs.VisualRanking.getRanking(1);
            }
        },

        //获取搜索记录
        getSearchHistory(){
            this.$axios.get(`${this.$searchUrl}/Trade/SearchLogTop50`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.recordList = data.slice(0,10);
                    this.recordList.forEach(element => {
                        element.createTimeStr = element.createTime.slice(0,19).replace('T',' ');
                    });
                    // console.log(this.recordList);
                }
            });
        },
        //点击搜索记录
        clikRecord(request){
            Object.assign(this.searchField, request)
            switch (this.searchField.keyDocType) {
                case 0: this.tabActiveName = "公司"; break;
                case 1: this.tabActiveName = "产品"; break;
                case 2: this.tabActiveName = "HS编码"; break;
                case 3: this.tabActiveName = "进口商"; break;
                case 4: this.tabActiveName = "出口商"; break;
                default: break;
            }
            this.toSearch();
        },

        //导出数据
        exportResult(){
            if(this.exportCount == 0){
                this.$message({message: "导出次数不够!",type: 'warning'});
                return false;
            }
            this.$confirm(`今日还剩余${this.exportCount}次,是否导出数据?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.exportLoading = true;
                this.$axios({
                    url: `${this.$searchUrl}/Trade/ExportReportSearch`,
                    method: 'POST',
                    responseType: 'blob',
                    data: this.searchField,
                }).then(async(response) => {
                    this.exportLoading = false;
                    if(response.type != "application/vnd.ms-excel"){
                        let jsonData = JSON.parse(await response.text());
                        this.$notify({ message: jsonData.errorMessage, position: "top-right",duration: 2000,type: 'warning' })
                        return false;
                    }
                    var blob = new Blob([response]);
                    var link= document.createElement('a');
                    link.download = `海关数据-${this.tabActiveName}-${this.searchField.keyDoc}.xlsx`;
                    link.href = URL.createObjectURL(blob);
                    link.click();
                    this.$message({message: "导出成功",type: 'success'});
                    this.exportCount--;
                }).catch(() => {
                    this.exportLoading = false;
                })
            }).catch(() => {});
        },
        async getDownloadCount(){
            const response = await this.$axios.get(`${this.$searchUrl}/Trade/GetDownloadCount`);
            this.exportCount = response.data;
        }

    },
    mounted() {
        //没有海关数据查看权限
        let isCustomsData = JSON.parse(localStorage.userInfo).isCustomsData;
        if(!isCustomsData){
            this.$router.push(`/noPower`);
        }
        //获取搜索记录
        this.getSearchHistory();
        //获取导出次数
        this.getDownloadCount();
    },
};
</script>

<style lang="scss" scoped>
@import '@/scss/common.scss';
.TradeData{
    width: calc(100vw - 100px);
    height: calc(100vh - 80px);
    box-sizing: border-box;
    display: flex;
    background-color: #fff;
    .leftSide{
        width: 260px;
        height: calc(100vh - 80px);
        overflow: auto;
        box-shadow: 2px 0 0 0 rgba( 0, 0, 0, 0.1);
    }
    .rightSide{
        width: calc(100vw - 360px);
        height: calc(100vh - 80px);
        padding: 0 20px 20px 20px;
        box-sizing: border-box;
        .searchBox{
            padding: 20px;
            border: 1px solid #d8d8d8;
            box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.2);
            border-radius: 4px;
            height: 40px;
            display: flex;
            .el-input{
                width: calc(100% - 250px);
            }
            /deep/ .el-input__inner{                
                border: none;
                font-size: 20px;
            }
            /deep/ .el-icon-search{
                font-size: 20px;
            }
            .btnStates, .btnScreen{
                display: inline-block;
                cursor: pointer;
                width: 40px;
                height: 40px;
                text-align: center;
                background-color: var(--lightColor);
                border-radius: 2px;
                .svgIcon{
                    margin-top: 8px;
                }
            }
        }
        .searchAfter{
            margin-top: 20px;
            .navTradeTab{
                display: flex;
                position: relative;
                .tabItem{
                    width: 150px;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    margin-right: 10px;
                    background-color: #f5f6f8;
                    border: 1px solid #eaeaea;
                    border-bottom: none;
                    color: #666;
                    font-size: 14px;
                    cursor: pointer;
                }
                .active{
                    background-color: #fff;
                    color: $themeColor;
                }
                .rightOption{
                    position: absolute;
                    right: 20px;
                    top: 0;
                    height: 40px;
                    display: flex;
                    align-items: center;
                }
            }
            .searchContent{
                height: calc(100vh - 300px);
                overflow: auto;
                border: 1px solid #eaeaea;
            }
        }
    }
}
.historyBox{
    margin-top: 20px;
    padding: 10px 0;
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    .ttt{
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        color: #000;
        margin-bottom: 8px;
        padding-left: 20px;
    }
    .historyItem{
        padding: 0 20px;
        height: 40px;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        &:hover{
            background-color: #fafafa;
        }
        .describe{
            width: calc(100% - 150px);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #333;
        }
        .time{
            width: 135px;
            color: #999;
        }
    }
}
</style>