<script>
import Vue from "vue";
import { ref } from "@vue/composition-api";
import Step1 from "./components/Step1.vue";
import Step3 from "./components/Step3.vue";
import axios from "@/assets/js/axios";
import qs from "qs";
import { Message } from "element-ui";
import router from "@/router";
export default Vue.extend({
    components: { Step1, Step3 },
    setup() {
        const step = ref(1);

        const loading = ref(false);
        const form = {
            eventNo: "",
            subscribers: [],
            subject: "",
            bodyHtml: "",
            attachments: [],
            isTiming: false,
            sendTime: "",
            templateKey: "",
        };
        const myClueKeys = ref();

        let fronvContacts = [];

        const onStep1Done = (recipient, checkedKey) => {
            form.subscribers = recipient;
            fronvContacts = recipient;
            myClueKeys.value = checkedKey;
            step.value = 2;
        };

        const onSend = async (val) => {
            try {
                const fronv = val;
                fronv.contacts = fronvContacts.map((item) => ({
                    name: item.name,
                    whatsApp: item.whatsApp,
                    isVerify: item.isVerify,
                }));
                loading.value = true;
                const res = await axios({
                    method: "POST",
                    url: Vue.prototype.$marketUrl + "/WhatsApp/v2/SaveTask",
                    data: fronv,
                });
                if (res.isSuccess) {
                    const resTwo = await axios({
                        method: "GET",
                        url:
                            Vue.prototype.$marketUrl + "/WhatsApp/v2/SendEvent",
                        params: {
                            eventKey: res.data,
                        },
                    });
                    if (resTwo.isSuccess) {
                        Message.success("发送成功");
                        router.replace({ name: "TaskDashboardWA" });
                    }
                }
            } catch (error) {
                Message.error(error);
            } finally {
                loading.value = false;
            }
        };

        const url = window.location.href;
        const index = url.lastIndexOf("?");
        const eventNo = qs.parse(url.slice(index + 1))["eventNo"] || "";
        if (eventNo) {
            form.eventNo = eventNo;
            document.title = "营销模块-编辑营销";
        }

        const onSave = async (val) => {
            try {
                const fronv = val;
                fronv.contacts = fronvContacts.map((item) => ({
                    name: item.name,
                    whatsApp: item.whatsApp,
                    isVerify: item.isVerify,
                }));
                loading.value = true;
                const res = await axios({
                    method: "POST",
                    url: Vue.prototype.$marketUrl + "/WhatsApp/v2/SaveTask",
                    data: fronv,
                });
                if (res.isSuccess) {
                    Message.success("保存成功");
                    router.replace({ name: "TaskDashboardWA" });
                }
            } catch (error) {
                Message.error(error);
            } finally {
                loading.value = false;
            }
        };

        return {
            step,
            loading,
            myClueKeys,
            onStep1Done,
            onSend,
            onSave,
        };
    },
});
</script>

<template>
    <div v-loading="loading" class="view">
        <div style="position: relative; box-sizing: border-box; height: 80px; display: flex; align-items: center;">
            <el-tabs ref="ElTabsStep" class="step" style="width: 100%" :before-leave="() => false">
                <el-tab-pane>
                    <template #label>
                        <div :class="[step >= 1 ? 'active' : '']" style="display: flex; align-items: center">
                            <div class="step-icon">1</div>
                            <span>选择联系人</span>
                        </div>
                    </template>
                </el-tab-pane>
                <el-tab-pane>
                    <template #label>
                        <div :class="[step >= 2 ? 'active' : '']" style="display: flex; align-items: center">
                            <div class="step-icon">2</div>
                            <span>任务设置</span>
                        </div>
                    </template>
                </el-tab-pane>
            </el-tabs>
        </div>
        <div class="content-wrapper">
            <Step1 v-show="step === 1" @done="onStep1Done" />
            <Step3 v-show="step === 2" @done="onSend" @save="onSave" @prev="step -= 1" />
        </div>
    </div>
</template>

<style scoped lang="scss">
.view {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background: #fff;
}

.step {
    ::v-deep .el-tabs__nav-scroll {
        display: flex;
        justify-content: center;
    }

    .active {
        color: var(--themeColor);

        .step-icon {
            border-color: var(--themeColor);
            background: var(--themeColor);
            color: #fff;
        }
    }

    .step-icon {
        margin-right: 0.5em;
        width: 1.3em;
        height: 1.3em;
        border-radius: 50%;
        border: 1px solid #919191;
        color: #919191;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.content-wrapper {
    box-sizing: border-box;
    height: calc(100% - 80px);
}
</style>
