<template>
    <div class="detailsBox">
        <div class="header">
            <span>{{detailsInfo.manufacturerName}}-{{detailsInfo.manufacturerNo}}</span>
            <div class="rightOption">
                <el-button size="small" @click="toEdit">编 辑</el-button>
                <el-button size="small" @click="toCopy">复 制</el-button>
            </div>
        </div>
        <div class="title">基本信息</div>
        <el-form :model="detailsInfo" label-width="120px" size="small" label-suffix=":">
            <el-row :gutter="16">
                <el-col :span="8">
                    <el-form-item label="厂商编号">
                        <span class="w100">{{detailsInfo.manufacturerNo}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="厂商名称">
                        <span class="w100">{{detailsInfo.manufacturerName}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="公司主页">
                        <span class="w100">{{detailsInfo.companyHomepage}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="所在城市">
                        <span class="w100">{{detailsInfo.city}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="所在省份">
                        <span class="w100">{{detailsInfo.province}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="境内货源地">
                        <span class="w100">{{detailsInfo.placeOrigin}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="邮政编码">
                        <span class="w100">{{detailsInfo.postalCode}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="详细地址">
                        <span class="w100">{{detailsInfo.address}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="信用等级">
                        <span class="w100">{{detailsInfo.creditRating}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="厂商来源">
                        <span class="w100">{{detailsInfo.manufacturerSource}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="业务范围">
                        <span class="w100">{{detailsInfo.businessScope}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="结算方式">
                        <span class="w100">{{detailsInfo.settlementMethod}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="合作等级">
                        <span class="w100">{{detailsInfo.cooperationLevel}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="能否开票">
                        <span class="w100">{{detailsInfo.isInvoicing}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="公司税号">
                        <span class="w100">{{detailsInfo.companyTaxNo}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="开户银行">
                        <span class="w100">{{detailsInfo.depositBank}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="银行账号">
                        <span class="w100">{{detailsInfo.bankAccount}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="最近成交日期">
                        <span class="w100">{{detailsInfo.latestTransactionDate}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="银行地址">
                        <span class="w100">{{detailsInfo.bankAddress}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="开发日期">
                        <span class="w100">{{detailsInfo.developmentDate}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="采购总金额">
                        <span class="w100" style="color:var(--themeColor);font-weight:bold;">{{detailsInfo.totalProcurement}}</span>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div class="title">联系人信息</div>
        <el-table :data="detailsInfo.contacts" border :header-cell-style="{'background-color':'#F3F3F3','color':'#3A3A3A'}">
            <el-table-column label="联系人姓名">
                <template slot-scope="scope">
                    {{scope.row.contactsName}}
                </template>
            </el-table-column>
            <el-table-column label="联系人部门">
                <template slot-scope="scope">
                    {{scope.row.contactsDepartment}}
                </template>
            </el-table-column>
            <el-table-column label="性别">
                <template slot-scope="scope">
                    {{scope.row.contactsSex}}
                </template>
            </el-table-column>
            <el-table-column label="职务">
                <template slot-scope="scope">
                    {{scope.row.contactsPost}}
                </template>
            </el-table-column>
            <el-table-column label="电话">
                <template slot-scope="scope">
                    {{scope.row.contactsTel}}
                </template>
            </el-table-column>
            <el-table-column label="手机号">
                <template slot-scope="scope">
                    {{scope.row.contactsPhone}}
                </template>
            </el-table-column>
            <el-table-column label="传真">
                <template slot-scope="scope">
                    {{scope.row.contactsFax}}
                </template>
            </el-table-column>
            <el-table-column label="邮箱">
                <template slot-scope="scope">
                    {{scope.row.contactsEmail}}
                </template>
            </el-table-column>
        </el-table>

        <!-- 附件管理 -->
        <Fujian :documentKey="$route.query.key" type="supplierFujian" />

    </div>
</template>

<script>
import Fujian from "@/components/Fujian.vue";
export default {
    components: { Fujian },
    data() {
        return {
            detailsInfo: {
                "key": "",
                "manufacturerNo": "",
                "manufacturerName": "",
                "companyHomepage": "",
                "city": "",
                "province": "",
                "placeOrigin": "",
                "postalCode": "",
                "address": "",
                "creditRating": "",
                "manufacturerSource": "",
                "businessScope": "",
                "settlementMethod": "",
                "cooperationLevel": "",
                "isInvoicing": "",
                "companyTaxNo": "",
                "depositBank": "",
                "bankAccount": "",
                "bankAddress": "",
                "latestTransactionDate": "",
                "developmentDate": "",
                "contacts": [],
            },
        };
    },
    methods: {
        //获取供应商详情
        getDetail(key){
            this.$axios.get(`${this.$orderUrl}/Purchase/V2/GetPurchaserDetail?key=${key}`).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    this.detailsInfo = data;
                }
            });
        },
        
        toEdit(){
            let routeData = this.$router.resolve({
                path: "/purchase/newPurchaser",
                query: { key: this.$route.query.key },
            });
            window.open(routeData.href, "_blank");            
        },
        toCopy(){
            let routeData = this.$router.resolve({
                path: "/purchase/newPurchaser",
                query: { key: this.$route.query.key, optype: "copy" },
            });
            window.open(routeData.href, "_blank");            
        },
    },
    mounted() {
        let key = this.$route.query.key;
        if(key){
            this.getDetail(key); //获取供应商详情
        }
    },
};
</script>

<style lang="scss" scoped>
.detailsBox{
    background: #FFFFFF;
    padding: 10px 20px;
    .header{
        height: 32px;
        line-height: 32px;
        font-size: 20px;
        font-weight: bold;
        color: #000;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
    }
    .title{
        height: 20px;
        line-height: 20px;
        font-size: 16px;
        font-weight: bold;
        color: #3A3A3A;
        margin-bottom: 10px;
        border-left: 3px solid var(--themeColor);
        padding-left: 5px;
    }
    .w100{
        width: 100%;
        height: 32px;
        color: #333;
    }
}
</style>