<template>
    <div>
        <div class="filterBox">
            <span style="flex-shrink: 0">时间：</span>
            <el-select v-model="filterYear" placeholder="年份" size="small" style="width:100px;" @change="onFilterAll">
                <el-option label="2021年" value="2021"></el-option>
                <el-option label="2022年" value="2022"></el-option>
                <el-option label="2023年" value="2023"></el-option>
            </el-select>
            <el-select v-model="filterMonth" placeholder="月份" size="small" style="width:100px;" @change="onFilterAll" clearable>
                <el-option label="整年" value=""></el-option>
                <el-option label="1月" value="1"></el-option>
                <el-option label="2月" value="2"></el-option>
                <el-option label="3月" value="3"></el-option>
                <el-option label="4月" value="4"></el-option>
                <el-option label="5月" value="5"></el-option>
                <el-option label="6月" value="6"></el-option>
                <el-option label="7月" value="7"></el-option>
                <el-option label="8月" value="8"></el-option>
                <el-option label="9月" value="9"></el-option>
                <el-option label="10月" value="10"></el-option>
                <el-option label="11月" value="11"></el-option>
                <el-option label="12月" value="12"></el-option>
            </el-select>
			<span style="flex-shrink: 0; margin-left: 20px;">组织：</span>
            <el-cascader class="cascader" :show-all-levels="false" v-model="belongValue" :options="belongOptions" :props="{ value: 'key', label: 'name', checkStrictly: true }" size="small" @change="onFilterAll" style="width:150px;" />
		</div>

        <div class="topStat" v-loading="statLoading">
            <div class="statBox">
                <div class="ttt">我的线索</div>
                <div style="display: flex;padding:15px;">
                    <div class="statItem" v-for="item in statDataList" :key="item.name">
                        <div class="label">{{item.name}}</div>
                        <div class="value">{{item.num}}</div>
                        <div class="ratio">
                            <el-tooltip effect="dark" placement="bottom">
                                <div slot="content">同比:{{item.yoyNum}}</div>
                                <div>
                                    <span style="color: #9C9C9C;">同比:</span>
                                    <span>{{item.yoyNumRate == "-" ? "-" : item.yoyNumRate + "%"}}</span>
                                    <i class="el-icon-caret-top" v-if="item.yoyNumRate > 0"></i>
                                    <i class="el-icon-caret-bottom" v-if="item.yoyNumRate < 0"></i>
                                </div>
                            </el-tooltip>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="reportBox">
            <div class="reportItem">
                <div class="reportTitle">
                    <div style="font-size: 18px;color: #1b2841;font-weight: bold;">我的公司线索</div>
                    <div style="display: flex;">
                        <img src="@/assets/icon/refresh.png" style="cursor: pointer;" />
                        <img src="@/assets/icon/close.png" style="margin-left: 10px;cursor: pointer;" />
                    </div>
                </div>
                <div v-loading="reportOneLoading">
                    <div class="filterItem">
                        <span style="flex-shrink: 0;">时间：</span>
                        <el-select v-model="filterFormOne.year" placeholder="年份" size="small" style="width:100px;" @change="onFilterOne">
                            <el-option label="2021年" value="2021"></el-option>
                            <el-option label="2022年" value="2022"></el-option>
                            <el-option label="2023年" value="2023"></el-option>
                        </el-select>
                        <el-select v-model="filterFormOne.month" placeholder="月份" size="small" style="width:100px;" @change="onFilterOne" clearable>
                            <el-option label="整年" value=""></el-option>
                            <el-option label="1月" value="1"></el-option>
                            <el-option label="2月" value="2"></el-option>
                            <el-option label="3月" value="3"></el-option>
                            <el-option label="4月" value="4"></el-option>
                            <el-option label="5月" value="5"></el-option>
                            <el-option label="6月" value="6"></el-option>
                            <el-option label="7月" value="7"></el-option>
                            <el-option label="8月" value="8"></el-option>
                            <el-option label="9月" value="9"></el-option>
                            <el-option label="10月" value="10"></el-option>
                            <el-option label="11月" value="11"></el-option>
                            <el-option label="12月" value="12"></el-option>
                        </el-select>
                        <span style="flex-shrink: 0; margin-left: 20px;">组织：</span>
                        <el-cascader class="cascader" :show-all-levels="false" v-model="filterFormOne.orgValue" :options="belongOptions" :props="{ value: 'key', label: 'name', checkStrictly: true }" size="small" @change="onFilterOne" style="width:150px;" />
                    </div>
                    <el-table :data="reportOneList" :header-cell-style="headerCellStyle" size="small" height="285">
                        <el-table-column label="公司" prop="companyName" show-overflow-tooltip />
                        <el-table-column label="网址" prop="officialWebsite" show-overflow-tooltip />
                        <el-table-column label="员工人数" prop="employeesNumber" show-overflow-tooltip />
                        <el-table-column label="电话" prop="contactNumber" show-overflow-tooltip />
                        <el-table-column label="注册时间" prop="registrationTime" show-overflow-tooltip />
                    </el-table>
                    <el-pagination hide-on-single-page background layout="total,prev,pager,next" :page-size="5" :total="reportOneTotal" style="float:right;margin:10px 20px;" :current-page.sync="filterFormOne.pageIndex" @current-change="getReportOne"></el-pagination>
                </div>
            </div>
            <div class="reportItem">
                <div class="reportTitle">
                    <div style="font-size: 18px;color: #1b2841;font-weight: bold;">我的联系人线索</div>
                    <div style="display: flex;">
                        <img src="@/assets/icon/refresh.png" style="cursor: pointer;" />
                        <img src="@/assets/icon/close.png" style="margin-left: 10px;cursor: pointer;" />
                    </div>
                </div>
                <div v-loading="reportTwoLoading">
                    <div class="filterItem">
                        <span style="flex-shrink: 0;">时间：</span>
                        <el-select v-model="filterFormTwo.year" placeholder="年份" size="small" style="width:100px;" @change="onFilterTwo">
                            <el-option label="2021年" value="2021"></el-option>
                            <el-option label="2022年" value="2022"></el-option>
                            <el-option label="2023年" value="2023"></el-option>
                        </el-select>
                        <el-select v-model="filterFormTwo.month" placeholder="月份" size="small" style="width:100px;" @change="onFilterTwo" clearable>
                            <el-option label="整年" value=""></el-option>
                            <el-option label="1月" value="1"></el-option>
                            <el-option label="2月" value="2"></el-option>
                            <el-option label="3月" value="3"></el-option>
                            <el-option label="4月" value="4"></el-option>
                            <el-option label="5月" value="5"></el-option>
                            <el-option label="6月" value="6"></el-option>
                            <el-option label="7月" value="7"></el-option>
                            <el-option label="8月" value="8"></el-option>
                            <el-option label="9月" value="9"></el-option>
                            <el-option label="10月" value="10"></el-option>
                            <el-option label="11月" value="11"></el-option>
                            <el-option label="12月" value="12"></el-option>
                        </el-select>
                        <span style="flex-shrink: 0; margin-left: 20px;">组织：</span>
                        <el-cascader class="cascader" :show-all-levels="false" v-model="filterFormTwo.orgValue" :options="belongOptions" :props="{ value: 'key', label: 'name', checkStrictly: true }" size="small" @change="onFilterTwo" style="width:150px;" />
                    </div>
                    <el-table :data="reportTwoList" :header-cell-style="headerCellStyle" size="small" height="285">
                        <el-table-column label="姓名" prop="name" show-overflow-tooltip />
                        <el-table-column label="职位" prop="position" show-overflow-tooltip />
                        <el-table-column label="公司" prop="companyName" show-overflow-tooltip />
                        <el-table-column label="国家" prop="country" show-overflow-tooltip />
                        <el-table-column label="手机号" prop="tel" show-overflow-tooltip />
                    </el-table>
                    <el-pagination hide-on-single-page background layout="total,prev,pager,next" :page-size="5" :total="reportTwoTotal" style="float:right;margin:10px 20px;" :current-page.sync="filterFormTwo.pageIndex" @current-change="getReportTwo"></el-pagination>
                </div>
            </div>

            <div class="reportItem" v-if="false">
                <div class="reportTitle">
                    <div style="font-size: 18px;color: #1b2841;font-weight: bold;">公司线索搜索记录</div>
                    <div style="display: flex;">
                        <img src="@/assets/icon/refresh.png" style="cursor: pointer;" />
                        <img src="@/assets/icon/close.png" style="margin-left: 10px;cursor: pointer;" />
                    </div>
                </div>
                <div v-loading="reportThreeLoading">
                    <div class="filterItem">
                        <span style="flex-shrink: 0;">时间：</span>
                        <el-select v-model="filterFormThree.year" placeholder="年份" size="small" style="width:100px;" @change="onFilterThree">
                            <el-option label="2021年" value="2021"></el-option>
                            <el-option label="2022年" value="2022"></el-option>
                            <el-option label="2023年" value="2023"></el-option>
                        </el-select>
                        <el-select v-model="filterFormThree.month" placeholder="月份" size="small" style="width:100px;" @change="onFilterThree" clearable>
                            <el-option label="整年" value=""></el-option>
                            <el-option label="1月" value="1"></el-option>
                            <el-option label="2月" value="2"></el-option>
                            <el-option label="3月" value="3"></el-option>
                            <el-option label="4月" value="4"></el-option>
                            <el-option label="5月" value="5"></el-option>
                            <el-option label="6月" value="6"></el-option>
                            <el-option label="7月" value="7"></el-option>
                            <el-option label="8月" value="8"></el-option>
                            <el-option label="9月" value="9"></el-option>
                            <el-option label="10月" value="10"></el-option>
                            <el-option label="11月" value="11"></el-option>
                            <el-option label="12月" value="12"></el-option>
                        </el-select>
                        <span style="flex-shrink: 0; margin-left: 20px;">组织：</span>
                        <el-cascader class="cascader" :show-all-levels="false" v-model="filterFormThree.orgValue" :options="belongOptions" :props="{ value: 'key', label: 'name', checkStrictly: true }" size="small" @change="onFilterThree" style="width:150px;" />
                    </div>
                    <el-table :data="reportThreeList" :header-cell-style="headerCellStyle" size="small" height="285">
                        <el-table-column label="关键字" prop="value1" show-overflow-tooltip />
                        <el-table-column label="总条数" prop="value2" show-overflow-tooltip />
                        <el-table-column label="联系人数量" prop="value3" show-overflow-tooltip />
                        <el-table-column label="搜索时间" prop="value4" show-overflow-tooltip />
                    </el-table>
                    <el-pagination hide-on-single-page background layout="total,prev,pager,next" :page-size="5" :total="reportThreeTotal" style="float:right;margin:10px 20px;" :current-page.sync="filterFormThree.pageIndex" @current-change="getReportThree"></el-pagination>
                </div>
            </div>

            <div class="reportItem">
                <div class="reportTitle">
                    <div style="font-size: 18px;color: #1b2841;font-weight: bold;">联系人邮箱线索搜索记录</div>
                    <div style="display: flex;">
                        <img src="@/assets/icon/refresh.png" style="cursor: pointer;" />
                        <img src="@/assets/icon/close.png" style="margin-left: 10px;cursor: pointer;" />
                    </div>
                </div>
                <div v-loading="reportFourLoading">
                    <div class="filterItem">
                        <span style="flex-shrink: 0;">时间：</span>
                        <el-select v-model="filterFormFour.year" placeholder="年份" size="small" style="width:100px;" @change="onFilterFour">
                            <el-option label="2021年" value="2021"></el-option>
                            <el-option label="2022年" value="2022"></el-option>
                            <el-option label="2023年" value="2023"></el-option>
                        </el-select>
                        <el-select v-model="filterFormFour.month" placeholder="月份" size="small" style="width:100px;" @change="onFilterFour" clearable>
                            <el-option label="整年" value=""></el-option>
                            <el-option label="1月" value="1"></el-option>
                            <el-option label="2月" value="2"></el-option>
                            <el-option label="3月" value="3"></el-option>
                            <el-option label="4月" value="4"></el-option>
                            <el-option label="5月" value="5"></el-option>
                            <el-option label="6月" value="6"></el-option>
                            <el-option label="7月" value="7"></el-option>
                            <el-option label="8月" value="8"></el-option>
                            <el-option label="9月" value="9"></el-option>
                            <el-option label="10月" value="10"></el-option>
                            <el-option label="11月" value="11"></el-option>
                            <el-option label="12月" value="12"></el-option>
                        </el-select>
                        <span style="flex-shrink: 0; margin-left: 20px;">组织：</span>
                        <el-cascader class="cascader" :show-all-levels="false" v-model="filterFormFour.orgValue" :options="belongOptions" :props="{ value: 'key', label: 'name', checkStrictly: true }" size="small" @change="onFilterFour" style="width:150px;" />
                    </div>
                    <el-table :data="reportFourList" :header-cell-style="headerCellStyle" size="small" height="285">
                        <el-table-column label="(域名/公司)关键词" prop="keyword" show-overflow-tooltip />
                        <el-table-column label="邮箱个数" prop="count" show-overflow-tooltip />
                        <el-table-column label="联系人数量" prop="count" show-overflow-tooltip />
                        <el-table-column label="搜索时间" prop="createTime" show-overflow-tooltip />
                    </el-table>
                    <el-pagination hide-on-single-page background layout="total,prev,pager,next" :page-size="5" :total="reportFourTotal" style="float:right;margin:10px 20px;" :current-page.sync="filterFormFour.pageIndex" @current-change="getReportFour"></el-pagination>
                </div>
            </div>
            <div class="reportItem">
                <div class="reportTitle">
                    <div style="font-size: 18px;color: #1b2841;font-weight: bold;">联系人WhatsApp线索搜索记录</div>
                    <div style="display: flex;">
                        <img src="@/assets/icon/refresh.png" style="cursor: pointer;" />
                        <img src="@/assets/icon/close.png" style="margin-left: 10px;cursor: pointer;" />
                    </div>
                </div>
                <div v-loading="reportFiveLoading">
                    <div class="filterItem">
                        <span style="flex-shrink: 0;">时间：</span>
                        <el-select v-model="filterFormFive.year" placeholder="年份" size="small" style="width:100px;" @change="onFilterFive">
                            <el-option label="2021年" value="2021"></el-option>
                            <el-option label="2022年" value="2022"></el-option>
                            <el-option label="2023年" value="2023"></el-option>
                        </el-select>
                        <el-select v-model="filterFormFive.month" placeholder="月份" size="small" style="width:100px;" @change="onFilterFive" clearable>
                            <el-option label="整年" value=""></el-option>
                            <el-option label="1月" value="1"></el-option>
                            <el-option label="2月" value="2"></el-option>
                            <el-option label="3月" value="3"></el-option>
                            <el-option label="4月" value="4"></el-option>
                            <el-option label="5月" value="5"></el-option>
                            <el-option label="6月" value="6"></el-option>
                            <el-option label="7月" value="7"></el-option>
                            <el-option label="8月" value="8"></el-option>
                            <el-option label="9月" value="9"></el-option>
                            <el-option label="10月" value="10"></el-option>
                            <el-option label="11月" value="11"></el-option>
                            <el-option label="12月" value="12"></el-option>
                        </el-select>
                        <span style="flex-shrink: 0; margin-left: 20px;">组织：</span>
                        <el-cascader class="cascader" :show-all-levels="false" v-model="filterFormFive.orgValue" :options="belongOptions" :props="{ value: 'key', label: 'name', checkStrictly: true }" size="small" @change="onFilterFive" style="width:150px;" />
                    </div>
                    <el-table :data="reportFiveList" :header-cell-style="headerCellStyle" size="small" height="285">
                        <el-table-column label="关键词" prop="keyword" show-overflow-tooltip />
                        <el-table-column label="WhatsApp数量" prop="waCount" show-overflow-tooltip />
                        <el-table-column label="状态" prop="statusStr" show-overflow-tooltip />
                        <el-table-column label="搜索时间" prop="createTime" show-overflow-tooltip />
                    </el-table>
                    <el-pagination hide-on-single-page background layout="total,prev,pager,next" :page-size="5" :total="reportFiveTotal" style="float:right;margin:10px 20px;" :current-page.sync="filterFormFive.pageIndex" @current-change="getReportFive"></el-pagination>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            headerCellStyle:{'background-color':'#F1F4F8','color':'#3A3A3A'},
            //日期筛选
            filterYear: "2023",
            filterMonth: "",
            //组织筛选
            belongValue: [""],
            belongOptions: [],
            //统计数据
            statLoading: false,
            statDataList: [],
            //我的公司线索
            filterFormOne:{
                orgValue: [""],
                year: "2023",
                month: "",
                pageIndex: 1,
                pageSize: 5,
            },
            reportOneLoading: false,
            reportOneList: [],
            reportOneTotal: 0,
            //我的联系人线索
            filterFormTwo:{
                orgValue: [""],
                year: "2023",
                month: "",
                pageIndex: 1,
                pageSize: 5,
            },
            reportTwoLoading: false,
            reportTwoList: [],
            reportTwoTotal: 0,
            //公司线索搜索记录
            filterFormThree:{
                orgValue: [""],
                year: "2023",
                month: "",
                pageIndex: 1,
                pageSize: 5,
            },
            reportThreeLoading: false,
            reportThreeList: [],
            reportThreeTotal: 0,
            //联系人邮箱线索搜索记录
            filterFormFour:{
                orgValue: [""],
                year: "2023",
                month: "",
                pageIndex: 1,
                pageSize: 5,
            },
            reportFourLoading: false,
            reportFourList: [],
            reportFourTotal: 0,
            //联系人WhatsApp线索搜索记录
            filterFormFive:{
                orgValue: [""],
                year: "2023",
                month: "",
                pageIndex: 1,
                pageSize: 5,
            },
            reportFiveLoading: false,
            reportFiveList: [],
            reportFiveTotal: 0,
        };
    },
    methods: {
        //获取组织结构树下拉框
        getOrganizationDrop(){
            this.$axios.get(`${this.$personnelUrl}/Organization/FindOrganizationDrop`).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    data.unshift({
                        key: '',
                        name: '我的',
                        children: []
                    })
                    for (const item of data) {
                        this.disposeTree(item)
                    }
                    this.belongOptions = data;
                }
            });
        },
        disposeTree(node){
            if (node.children.length) {
                for (const item of node.children) {
                    this.disposeTree(item)
                }
            } else {
                delete node.children
            }
        },

        //筛选过滤
        onFilterAll(){
            console.log(this.filterYear);
            console.log(this.filterMonth);
            console.log(this.belongValue);
            
            this.getStatData();

            this.filterFormOne.year = this.filterYear;
            this.filterFormOne.month = this.filterMonth;
            this.filterFormOne.orgValue = this.belongValue;
            this.onFilterOne();

            this.filterFormTwo.year = this.filterYear;
            this.filterFormTwo.month = this.filterMonth;
            this.filterFormTwo.orgValue = this.belongValue;
            this.onFilterTwo();

            // this.filterFormThree.year = this.filterYear;
            // this.filterFormThree.month = this.filterMonth;
            // this.filterFormThree.orgValue = this.belongValue;
            // this.onFilterThree();

            this.filterFormFour.year = this.filterYear;
            this.filterFormFour.month = this.filterMonth;
            this.filterFormFour.orgValue = this.belongValue;
            this.onFilterFour();

            this.filterFormFive.year = this.filterYear;
            this.filterFormFive.month = this.filterMonth;
            this.filterFormFive.orgValue = this.belongValue;
            this.onFilterFive();
        },

        //获取顶部统计数据
        getStatData(){
            this.statLoading = true;
            let organizationKey = this.belongValue[this.belongValue.length-1];
            this.$axios.post(`${this.$customerUrl}/Report/GetClueTopData`,{
                "year": this.filterYear,
                "month": this.filterMonth,
                "organizationKey": organizationKey,
            }).then((res) => {
                const { isSuccess , data } = res;
                this.statLoading = false;
                if (isSuccess) {
                    this.statDataList = data;
                }
            });
        },

        onFilterOne(){
            this.filterFormOne.pageIndex = 1;
            this.getReportOne();
        },
        onFilterTwo(){
            this.filterFormTwo.pageIndex = 1;
            this.getReportTwo();
        },
        // onFilterThree(){
        //     this.filterFormThree.pageIndex = 1;
        //     this.getReportThree();
        // },
        onFilterFour(){
            this.filterFormFour.pageIndex = 1;
            this.getReportFour();
        },
        onFilterFive(){
            this.filterFormFive.pageIndex = 1;
            this.getReportFive();
        },

        //我的公司线索
        getReportOne(){
            let {orgValue,year,month,pageIndex,pageSize} = this.filterFormOne;
            let organizationKey = orgValue[orgValue.length-1];
            this.reportOneLoading = true;
            this.$axios.post(`${this.$customerUrl}/Report/GetMyClue`,{
                "year": year,
                "month": month,
                "organizationKey": organizationKey,
                "pageIndex": pageIndex,
                "pageSize": pageSize,
            }).then((res) => {
                const { isSuccess , data } = res;
                this.reportOneLoading = false;
                if (isSuccess) {
                    this.reportOneList = data.datas;
                    this.reportOneTotal = data.totalItems;
                }
            });            
        },
        //我的联系人线索
        getReportTwo(){
            let {orgValue,year,month,pageIndex,pageSize} = this.filterFormTwo;
            let organizationKey = orgValue[orgValue.length-1];
            this.reportTwoLoading = true;
            this.$axios.post(`${this.$customerUrl}/Report/GetContactsClue`,{
                "year": year,
                "month": month,
                "organizationKey": organizationKey,
                "pageIndex": pageIndex,
                "pageSize": pageSize,
            }).then((res) => {
                const { isSuccess , data } = res;
                this.reportTwoLoading = false;
                if (isSuccess) {
                    this.reportTwoList = data.datas;
                    this.reportTwoTotal = data.totalItems;
                }
            });
        },
        //公司线索搜索记录
        // getReportThree(){
        //     this.reportThreeLoading = true;
        //     this.reportThreeList = [{
        //         value1:"Apple.Inc",value2:"12324",value3:"321",value4:"2020-12-12 ",
        //     }]
        //     this.reportThreeTotal = 33;
        //     setTimeout(() => {
        //         this.reportThreeLoading = false;
        //     }, 1000);
        // },
        //联系人邮箱线索搜索记录
        getReportFour(){
            let {orgValue,year,month,pageIndex,pageSize} = this.filterFormFour;
            let organizationKey = orgValue[orgValue.length-1];
            this.reportFourLoading = true;
            this.$axios.post(`${this.$customerUrl}/Report/GetSearchEmailLog`,{
                "year": year,
                "month": month,
                "organizationKey": organizationKey,
                "pageIndex": pageIndex,
                "pageSize": pageSize,
            }).then((res) => {
                const { isSuccess , data } = res;
                this.reportFourLoading = false;
                if (isSuccess) {
                    this.reportFourList = data.datas;
                    this.reportFourTotal = data.totalItems;
                }
            });
        },
        //联系人WhatsApp线索搜索记录
        getReportFive(){
            let {orgValue,year,month,pageIndex,pageSize} = this.filterFormFive;
            let organizationKey = orgValue[orgValue.length-1];
            this.reportFiveLoading = true;
            this.$axios.post(`${this.$customerUrl}/Report/GetGoogleSearchTask`,{
                "status": 9,
                "year": year,
                "month": month,
                "organizationKey": organizationKey,
                "pageIndex": pageIndex,
                "pageSize": pageSize,
            }).then((res) => {
                const { isSuccess , data } = res;
                this.reportFiveLoading = false;
                if (isSuccess) {
                    this.reportFiveList = data.datas;
                    this.reportFiveTotal = data.totalItems;
                }
            });
        },

    },
    mounted() {
        //获取组织结构树下拉框
        this.getOrganizationDrop();
        //获取顶部统计数据
        this.getStatData();

        this.getReportOne();
        this.getReportTwo();
        // this.getReportThree();
        this.getReportFour();
        this.getReportFive();
    },
};
</script>

<style lang="scss" scoped>
.filterBox{
    height: 60px;
    padding-left: 10px;
    display: flex; 
    align-items: center;
    font-size: 14px;
    color: #707070;
}
.topStat{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 150px;
}
.statBox{
    width: 100%;
    position: relative;
    height: 150px;
    background: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    .ttt{
        font-size: 16px;
        color: #1B2841;
        font-weight: bold;
        height: 32px;
        line-height: 32px;
        padding-left: 20px;
        border-bottom: 1px solid #E6E6E6;
    }
    .statItem{
        text-align: center;
        width: 25%;
        border-left: 1px solid #E6E6E6;
        .label{
            font-size: 16px;
            line-height: 24px;
            font-weight: bold;
            color: #1B2841;
        }
        .value{
            font-size: 32px;
            color: #1B2841;
        }
        .ratio{
            line-height: 24px;
            color: #9C9C9C;
            .el-icon-caret-top{
                color: #2DC76D;
            }
            .el-icon-caret-bottom{
                color: #F56C6C;
            }
        }
    }
    .statItem:first-child{
        border-left: none;
    }
}
.reportBox{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .reportItem{
        width: calc(50% - 10px);
        margin-top: 20px;
        position: relative;
        height: 420px;
        background: #fff;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        .reportTitle {
            box-sizing: border-box;
            height: 42px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 14px 0 20px;
        }
    }
}
.filterItem{
    padding-left: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 14px;
    color: #707070;
    margin-bottom: 5px;
}
</style>