<script>
import Vue from "vue";
import { reactive, watch } from "@vue/composition-api";
import axios from "@/assets/js/axios";
export default Vue.extend({
    props: ["ukeys"],
    setup(props) {

        const tableData = reactive({
            locationAnalysis: [],
            deviceAnalysis: [],
            opneTimeSlotAnalysis: [],
            languageAnalysis: [],
        });
        
        watch(() => props.ukeys, ()=>{
            axios({
                method: "POST",
                url: Vue.prototype.$marketUrl + "/MassAnalysis/GroupPortrait",
                data: props.ukeys,
            }).then((res) => {
                tableData.locationAnalysis = res.data.locationAnalysis;
                tableData.deviceAnalysis = res.data.deviceAnalysis;
                tableData.opneTimeSlotAnalysis = res.data.opneTimeSlotAnalysis;
                tableData.languageAnalysis = res.data.languageAnalysis;
            });
        })

        return {
            tableData,
        };
    },
});
</script>

<template>
    <div style="height: 100%; overflow-y: auto; font-size: 14px">
        <div class="tableBox">
            <div class="block">
                <p class="title">
                    <span>客户地理位置</span>
                </p>
                <el-table :data="tableData.locationAnalysis" size="mini">
                    <el-table-column prop="country" label="国家" />
                    <el-table-column prop="count" label="数量(收件人个数)" />
                    <el-table-column label="占比">
                        <template slot-scope="{row}">{{ row.proportion }}%</template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="block">
                <p class="title">
                    <span>设备</span>
                </p>
                <el-table :data="tableData.deviceAnalysis" size="mini">
                    <el-table-column prop="device" label="设备" />
                    <el-table-column prop="count" label="数量" />
                    <el-table-column label="占比">
                        <template slot-scope="{row}">{{ row.proportion }}%</template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="block">
                <p class="title">
                    <span>看信时段</span>
                </p>
                <el-table :data="tableData.opneTimeSlotAnalysis" size="mini">
                    <el-table-column prop="timeSlot" label="时段" />
                    <el-table-column prop="count" label="数量(次)" />
                    <el-table-column label="占比(次)">
                        <template slot-scope="{row}">{{ row.proportion }}%</template>
                    </el-table-column>
                    <el-table-column prop="peopleCount" label="数量(人)" />
                    <el-table-column label="占比(人)">
                        <template slot-scope="{row}">{{ row.peopleProportion }}%</template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="block">
                <p class="title">
                    <span>语言分析</span>
                </p>
                <el-table :data="tableData.languageAnalysis" size="mini">
                    <el-table-column prop="language" label="语言" />
                    <el-table-column prop="count" label="数量" />
                    <el-table-column label="占比">
                        <template slot-scope="{row}">{{ row.proportion }}%</template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">

.tableBox {
    column-count: 2;
    margin-top: 20px;
}
.block {
    margin-bottom: 1em;
}

.title {
    padding-left: 0.5em;
    border-left: 3px solid var(--themeColor);
}
</style>
