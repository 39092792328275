<template>
    <div>
        <el-card>
            <div>{{$t('test.title')}}</div>
            <div>{{$t('test.content')}}</div>
            <el-button size="small" @click="changeLanguage('zh')">{{$t('test.btnTextOne')}}</el-button>
            <el-button size="small" @click="changeLanguage('en')">{{$t('test.btnTextTwo')}}</el-button>
            <el-button size="small" @click="changeLanguage('ko')">{{$t('test.btnTextThree')}}</el-button>
        </el-card>
    </div>
</template>

<script>
export default {
    data() {
        return {

        };
    },
    methods: {
        //切换语言
        changeLanguage(value){
            this.$i18n.locale = value;
            localStorage.setItem('languageSet',this.$i18n.locale);
        },
    },
    mounted() {

    },
};
</script>

<style lang="scss" scoped>

</style>