<script lang="js">
import { defineComponent, reactive, getCurrentInstance } from '@vue/composition-api'
import axios from '@/assets/js/axios'
import { BASE_URL } from './constant'

export default defineComponent({
  setup() {
    const vm = getCurrentInstance()
    const data = reactive({
      list: []
    })
    axios({
      method: 'GET',
      url: BASE_URL + '/list'
    }).then(res => {
      data.list = res
    })
    const onCreatExcel = () => {
      window.open(vm.proxy.$router.resolve({
        name: 'Excel'
      }).href)
    }
    const onEdit = (command) => {
      const { filename, version } = command
      window.open(vm.proxy.$router.resolve({
        name: 'Excel',
        query: {
          filename,
          version
        }
      }).href)
    }
    return {
      data,
      onCreatExcel,
      onEdit
    }
  }
})
</script>

<template>
    <div>
        <el-form size="mini">
            <el-form-item>
                <el-button type="primary" @click="onCreatExcel">新建 Excel</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="data.list" size="mini">
            <el-table-column label="文件名">
                <template slot-scope="{row}">
                    <el-dropdown @command="onEdit">
                        <span class="el-dropdown-link">
                            {{ row.filename }}<i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item v-for="version of row.versions" :command="{ filename: row.filename, version }" :key="version">{{ version }}</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>
