<template>
    <el-dialog @open="onOpen" title="添加标签" :visible="visible" v-loading="loading" width="600px" :show-close="false" :close-on-click-modal="false">
        <div style="display: flex; justify-content: space-between">
            <el-select value-key="key" v-model="form.value" multiple placeholder="请选择" style="width: 100%" size="small">
                <el-option v-for="item in form.options" :disabled="item.disabled" :key="item.key" :label="item.name" :value="item">
                    <div style="display: flex; justify-content: space-between; align-items: center">
                        <div>
                            <span :style="{ background: item.color || '#e6a23c', width: '1em', height: '1em', display: 'inline-block' }"></span>
                            <span style="margin-left: 10px">{{ item.name }}</span>
                        </div>
                    </div>
                </el-option>
                <div class="toConfig" @click="toConfig">
                    <i class="el-icon-plus"></i>
                    <span>配置标签</span>
                </div>
                <div slot="empty">
                    <div class="toConfig" @click="toConfig">
                        <i class="el-icon-plus"></i>
                        <span>配置标签</span>
                    </div>
                </div>
            </el-select>
        </div>
        <div slot="footer">
            <el-button @click="$emit('cancel')" size="small">取 消</el-button>
            <el-button type="primary" @click="handleConfirmClick" size="small">确 定</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    props: ["visible", "oldTags", "companykey"],
    data() {
        return {
            loading: false,
            form: {
                options: [],
                value: [],
            },
        };
    },
    methods: {
        async getOptions() {
            try {
                const res = await this.$axios({
                    method: "GET",
                    url: this.$customerUrl + "/v1/Config/Tag",
                });
                const set = new Set();
                this.oldTags.forEach((item) => {
                    set.add(item.lableKey);
                });
                this.form.options = res.data.map((item) => ({
                    ...item,
                    disabled: set.has(item.key),
                }));
            } catch {
                this.form.options = [];
            }
        },
        async handleConfirmClick() {
            try {
                this.loading = true;
                await this.$axios({
                    method: "POST",
                    url: this.$customerUrl + "/Company/AddTag",
                    data: {
                        companykey: this.companykey,
                        tags: this.form.value.map((item) => ({
                            tagKey: item.key,
                            name: item.name,
                            color: item.color,
                        })),
                    },
                });
                this.getOptions();
                this.$emit("success");
                this.$emit("update:visible", false);
            } catch {
                null;
            } finally {
                this.loading = false;
            }
        },
        onOpen() {
            this.form.value = [];
            this.getOptions();
        },

        toConfig(){
            let routeData = this.$router.resolve({
                path: "/settingIndex",
                query: { activeName: 3 },
            });
            window.open(routeData.href, "_blank");
        },
    },
};
</script>

<style lang="scss" scoped>
.toConfig{
    cursor: pointer;
    text-align: center;
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    color: #6a6a6a;
    &:hover{
        background-color: #F5F7FA;
    }
}
</style>