<template>
    <div>
        <InterfaceName :interfaceName="interfaceName" />
        <InterfaceSpe :requestUrl="requestUrl" :requestMethod="requestMethod" />
        <InputParam :inputTableData="inputTableData" />
        <ReturnParam :returnObj="returnObj" />   
    </div>
</template>

<script>
import InterfaceName from "../Acomponents/InterfaceName.vue";
import InterfaceSpe from "../Acomponents/InterfaceSpe.vue";
import InputParam from "../Acomponents/InputParam.vue";
import ReturnParam from "../Acomponents/ReturnParam.vue";

export default {
    components: { InterfaceName, InterfaceSpe, InputParam, ReturnParam },
    data() {
        return {
            interfaceName: "保存产品",
            requestUrl: "https://crm.qixinerp.com/open/Product/Save",
            requestMethod: "POST",
            inputTableData: [],
            returnObj: {},
        };
    },
    methods: {
        
    },
    mounted() {
        this.inputTableData = [
            {paramName: "key", valueType: "string", explain: "唯一key", required: true},
            {paramName: "productNo", valueType: "string", explain: "产品编号", required: true},
            {paramName: "productName", valueType: "string", explain: "产品名称", required: true},
            {paramName: "sellPrice", valueType: "number", explain: "成本价(不含税)", required: true},
            {paramName: "taxIncludingPrice", valueType: "number", explain: "成本价(含税)", required: false},
            {paramName: "images", valueType: "string", explain: "产品主图", required: true},
            {paramName: "rotationChart", valueType: "array", explain: "轮播图", required: true},
            {paramName: "groupID", valueType: "string", explain: "产品分组", required: true},
            {paramName: "dimension", valueType: "string", explain: "尺寸信息", required: false},
            {paramName: "specDesc", valueType: "string", explain: "规格说明", required: false},
            {paramName: "ingredient", valueType: "string", explain: "成分", required: false},
            {paramName: "supplier", valueType: "string", explain: "供应商", required: false},
            {paramName: "measureUnit", valueType: "string", explain: "计量单位", required: false},
            {paramName: "hscode", valueType: "string", explain: "海关编码", required: false},
            {paramName: "grade", valueType: "string", explain: "等级", required: false},
            {paramName: "basicModel", valueType: "string", explain: "基本型号", required: false},
            {paramName: "isDangerousGoods", valueType: "boolean", explain: "是否危险品", required: false},
            {paramName: "description", valueType: "string", explain: "产品介绍", required: false},
        ]
        this.returnObj = {
            "isSuccess": true,
            "errorMessage": null,
            "data": true,
        }
    },
};
</script>

<style lang="scss" scoped>

</style>