<template>
    <div class="view">
        <div class="title-panel">
            <i class="el-icon-back" @click="$router.go(-1)"></i>
            <span>客户导入</span>
        </div>
        <div style="padding-left: 80px; padding-top: 30px">
            <div style="display: flex">
                <div style="height: 100%">
                    <el-steps direction="vertical" :active="1" space="120px">
                        <el-step title="填写表格" />
                        <el-step title="上传表格" />
                        <el-step title="选择导入分组" />
                        <!-- <el-step title="导入模式" /> -->
                    </el-steps>
                </div>
                <div style="display: flex; flex-direction: column; margin-left: 50px">
                    <div style="height: 100%; display: inline-flex">
                        <div style="color: #707070">按导入模板，填写表格</div>
                        <div style="margin-left: 34px; cursor: pointer; height: max-content; display: flex; align-items: center">
                            <img src="@/assets/icon/xls.png" />
                            <span @click="handleDownloadTemplate" style="color: var(--themeColor); text-decoration: underline">客户导入模板.xls</span>
                        </div>
                        <div style="margin-left: 36px; color: #c7c7c7">单次导入不超过1000条</div>
                    </div>
                    <div style="height: 100%">
                            <el-upload ref="upload" :headers="headers" :action="uploadUrl" :auto-upload="false" :multiple="false" :limit="1" :data="uploadData" :on-success="uploadOk">
                            <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                            <span style="margin-left: 22px; color: #707070">表格大小控制在5M以内</span>
                        </el-upload>
                    </div>
                    <div style="height: 100%">
                        <div style="display: flex; align-items: center">
                            <span style="color: #ff2d2d">*</span>
                            <span style="color: #707070; display: inline-block; width: 80px">公海/私海</span>
                            <el-select v-model="uploadData.type" size="small">
                                <el-option :value="0" label="导入公海分组" />
                                <el-option :value="1" label="导入私海分组" />
                            </el-select>
                        </div>
                        <div style="display: flex; align-items: center; margin-top: 10px" v-if="uploadData.type == 0">
                            <span style="color: #ff2d2d">*</span>
                            <span style="color: #707070; display: inline-block; width: 80px">公海分组</span>
                            <el-select v-model="uploadData.groupKey" size="small">
                                <el-option v-for="item in PublicGroupOption" :key="item.key" :label="item.name" :value="item.key"></el-option>
                            </el-select>
                        </div>
                        <div style="display: flex; align-items: center; margin-top: 10px" v-if="uploadData.type == 1">
                            <span style="color: #ff2d2d">*</span>
                            <span style="color: #707070; display: inline-block; width: 80px">私海分组</span>
                            <el-select v-model="uploadData.groupKey" size="small">
                                <el-option v-for="item in PrivateGroupOption" :key="item.key" :label="item.name" :value="item.key"></el-option>
                            </el-select>
                        </div>
                    </div>
                    <!-- <div style="height: 100%">
                        <el-radio-group v-model="form.type">
                            <el-radio label="不导入" />
                            <el-radio label="更新" />
                        </el-radio-group>
                    </div> -->
                </div>
            </div>
            <div style="position: relative; bottom: 60px; top: 0px;">
                <el-button type="primary" size="small" @click="submitUpload">一键导入</el-button>
                <!-- <div style="padding-top: 20px">
                    <span style="color: #1f2d3d; font-weight: bold">导入进度</span>
                    <el-progress :percentage="50" style="width: 750px" />
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            uploadUrl: `${this.$customerUrl}/Company/ImportCompany`,
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('token'),
            },
            uploadData: {
                type: 1,   //0公海 1私海
                groupKey: "",
            },
            // 分组配置
            PrivateGroupOption: [],
            PublicGroupOption: [],
        };
    },
    methods: {
        handleDownloadTemplate() {
            window.open("https://crm.qixinerp.com/ossfile/CompanyTemp.xlsx","_blank");
        },
        submitUpload() {
            this.$refs.upload.submit();
        },
        uploadOk(response){
            const {isSuccess,errorMessage} = response;
            if(isSuccess){
                this.$message({message: "导入成功",type: 'success'});
            }else{
                this.$message({message: errorMessage,type: 'warning'});
            }
        },

        // 私海分组配置
        getPrivateGroup(){
            this.$axios.get(`${this.$customerUrl}/v1/Config/PrivateGroup`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.PrivateGroupOption = data;
                }
            });
        },
        //公海分组配置
        getPublicGroup(){
            this.$axios.get(`${this.$customerUrl}/v1/Config/PublicGroup`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.PublicGroupOption = data;
                }
            });
        },
    },
    mounted(){
        this.getPrivateGroup();
        this.getPublicGroup();
    }
};
</script>

<style lang="scss" scoped>
.view {
    width: 100%;
    height: 100%;
    background: #fff;
    font-size: 14px;
}
.title-panel {
    color: #3a3a3a;
    font-size: 24px;
    padding-left: 40px;
    height: 75px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e6e7eb;
    .el-icon-back{
        cursor: pointer;
        padding-right: 20px;
    }
}
</style>
