<template>
    <div class="collectionDetails">
        <div class="topContent">
            <div class="title">财务收入详情</div>
            <div class="rightHandle">
                <!-- <el-button size="small">关联收款</el-button>
                <el-button size="small">关联回款</el-button>
                <el-dropdown>
                    <el-button size="small" style="margin-left:10px;">
                        更多<i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item>更多操作1</el-dropdown-item>
                        <el-dropdown-item>更多操作2</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown> -->
            </div>
            <div class="topInfo">
                <div class="infoItem">
                    <div class="label">客户简称:</div>
                    <div class="value">{{collectInfo.nickname}}</div>
                </div>
                <div class="infoItem">
                    <div class="label">客户编号:</div>
                    <div class="value">{{collectInfo.customerNo}}</div>
                </div>
                <div class="infoItem">
                    <div class="label">关联订单:</div>
                    <div class="value">{{collectInfo.orderNumber}}</div>
                </div>
            </div>
        </div>

        <div class="tabCard">
            <el-tabs>
                <el-tab-pane label="收入信息">
                    <div class="oneSide">
                        <div class="title">
                            <img src="@/assets/img/money.png" alt="" />
                            <span class="sp1">付款方：{{collectInfo.payer}}</span>
                        </div>
                        <el-form :model="collectInfo" label-width="80px" size="small" style="margin-top:20px;">
                            <el-row :gutter="16">
                                <el-col :span="12">
                                    <el-form-item label="支付方式">
                                        <el-input v-model="collectInfo.payerPayType"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="联系人">
                                        <el-input v-model="collectInfo.payerContacts"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="开户银行">
                                        <el-input v-model="collectInfo.payerBank"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="支付账号">
                                        <el-input v-model="collectInfo.payerPayAccount"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="付款日期">
                                        <el-date-picker v-model="collectInfo.payerDate" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="付款金额">
                                        <el-input-number v-model="collectInfo.payerPayAmount" :min="0" :controls="false" class="green"></el-input-number>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-form>
                    </div>
                    <div class="twoSide">
                        <div class="title">
                            <span class="sp2">{{collectInfo.payee}}</span>
                        </div>
                        <el-form :model="collectInfo" label-width="80px" size="small" style="margin-top:20px;">
                            <el-row :gutter="16">
                                <el-col :span="12">
                                    <el-form-item label="收款方式">
                                        <el-input v-model="collectInfo.payeePaymentMethod"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="跟进人">
                                        <el-input v-model="collectInfo.payeeFollowUp"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="收款银行">
                                        <el-input v-model="collectInfo.payeeBank"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="收款账号">
                                        <el-input v-model="collectInfo.payeeCollectionAccount"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="收款日期">
                                        <el-date-picker v-model="collectInfo.payeeCollectionDate" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="收款金额">
                                        <el-input-number v-model="collectInfo.payeeCollectionAmount" :min="0" :controls="false"></el-input-number>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-form>
                    </div>

                    <el-form :model="collectInfo" label-width="100px" size="small" style="margin-top:20px;">
                        <el-row :gutter="16">
                            <el-col :span="8">
                                <el-form-item label="款项名称">
                                    <el-input v-model="collectInfo.paymentName" class="w220"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="收汇币种">
                                    <el-input v-model="collectInfo.collectionCurrency" class="w220"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="汇率">
                                    <el-input-number v-model="collectInfo.exchangeRate" :min="0" :controls="false" class="w220"></el-input-number>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="美金汇率">
                                    <el-input-number v-model="collectInfo.usExchangeRate" :min="0" :controls="false" class="w220"></el-input-number>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="收汇净额">
                                    <el-input-number v-model="collectInfo.collectionNetAmount" :min="0" :controls="false" class="w220"></el-input-number>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="收汇净额($)">
                                    <el-input-number v-model="collectInfo.usCollectionNetAmount" :min="0" :controls="false" class="w220"></el-input-number>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="经手人">
                                    <el-input v-model="collectInfo.handler" class="w220"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="订单已收金额">
                                    <el-input-number v-model="collectInfo.amountReceived" :min="0" :controls="false" class="w220"></el-input-number>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="订单待收金额">
                                    <el-input-number v-model="collectInfo.remainingAmount" :min="0" :controls="false" class="w220"></el-input-number>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="备注说明">
                                    <el-input v-model="collectInfo.remarks" class="w220"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </el-tab-pane>
                <!-- <el-tab-pane label="发票及附件">
                    <div style="margin-bottom:20px;">
                        <el-button size="small" type="primary" icon="el-icon-plus" @click="toNewInvoice">新建</el-button>
                    </div>
                    <el-table :data="invoiceList" :header-cell-style="{'background-color':'#F3F3F3','color':'#3A3A3A'}" :row-style="rowStyle">
                        <el-table-column prop="number" label="收款编号"></el-table-column>
                        <el-table-column prop="lookupType" label="抬头类型"></el-table-column>
                        <el-table-column prop="invoiceTitle" label="发票抬头/税号"></el-table-column>
                        <el-table-column prop="invoiceAmount" label="发票金额"></el-table-column>
                        <el-table-column prop="company" label="开票公司"></el-table-column>
                        <el-table-column prop="applicant" label="申请人"></el-table-column>
                        <el-table-column prop="applyTime" label="申请时间"></el-table-column>
                        <el-table-column prop="state" label="状态"></el-table-column>
                        <el-table-column prop="financialStaff" label="财务人员"></el-table-column>
                        <el-table-column prop="handleTime" label="处理时间"></el-table-column>
                        <el-table-column label="操作" width="100">
                            <template slot-scope="scope">
                                <el-link type="primary" @click.stop="toViewMore(scope.row)">查看更多</el-link>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-tab-pane> -->
            </el-tabs>
        </div>

        <el-dialog title="开具发票" :visible.sync="invoiceVisible" width="500px" top="5vh">
            <div class="dialogTitle">基本信息：</div>
            <el-form :model="invoiceForm" label-width="100px" size="small">
                <el-form-item label="发票类型:">
                    <el-input v-model="invoiceForm.invoiceType"></el-input>
                </el-form-item>
                <el-form-item label="抬头类型:">
                    <el-radio-group v-model="invoiceForm.lookupType">
                        <el-radio label="企业单位">企业单位</el-radio>
                        <el-radio label="个人/非企单位">个人/非企单位</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="发票抬头:">
                    <el-input v-model="invoiceForm.invoiceTitle"></el-input>
                </el-form-item>
                <el-form-item label="税号:">
                    <el-input v-model="invoiceForm.taxNumber"></el-input>
                </el-form-item>
                <el-form-item label="币种:">
                    <el-input v-model="invoiceForm.currency"></el-input>
                </el-form-item>
                <el-form-item label="发票内容:">
                    <el-input v-model="invoiceForm.invoiceContent"></el-input>
                </el-form-item>
            </el-form>
            <div class="dialogTitle">更多内容（选填）：</div>
            <el-form :model="invoiceForm" label-width="100px" size="small">
                <el-form-item label="备注:">
                    <el-input v-model="invoiceForm.remark"></el-input>
                </el-form-item>
                <el-form-item label="公司地址:">
                    <el-input v-model="invoiceForm.companyAddress"></el-input>
                </el-form-item>
                <el-form-item label="公司电话:">
                    <el-input v-model="invoiceForm.companyTel"></el-input>
                </el-form-item>
                <el-form-item label="银行账户:">
                    <el-input v-model="invoiceForm.account"></el-input>
                </el-form-item>
                <el-form-item label="开户银行:">
                    <el-input v-model="invoiceForm.bank"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" @click="newInvoiceSubmit">立即创建</el-button>
                <el-button size="small" @click="invoiceVisible = false">取 消</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
export default {
    data() {
        return {
            collectionKey: "",
            collectInfo: {},     // 收汇信息         
           
            // 发票及附件
            invoiceList: [],
            invoiceVisible: false,
            invoiceForm: {},
        };
    },
    methods: {
        rowStyle({rowIndex}){
            // 表格隔行换色
            if(rowIndex % 2 == 1){
                return {'background-color':'#F9FAFC'}
            }
        },

        //获取收汇信息
        getCollectInfo(){
            this.$axios.get(`${this.$orderUrl}/api/Collection/GetCollectionDetail?collectionKey=${this.collectionKey}`).then((res) => {
                const { isSuccess , data } = res;
                if (isSuccess) {
                    this.collectInfo = data;
                }
            });
        },

        //获取发票及附件
        getInvoiceInfo(){
            let obj = {
                lookupType: "企业单位",
                invoiceTitle: "湖北境外消费商品有限公司 9131",
                invoiceAmount: "157.12CNY",
                company: "南京跨境搜有限公司",
                applicant: "张晓晓",
                applyTime: "2020/12/12 10:10:00",
                state: "申请中",
                financialStaff: "王进",
                handleTime: "2020/12/12 10:10:00",
            }
            this.invoiceList = [
                {number:'FB-001', ...obj},
                {number:'FB-002', ...obj},
                {number:'FB-003', ...obj},
                {number:'FB-004', ...obj},
                {number:'FB-005', ...obj},
            ]
        },

        toViewMore(row){
            console.log('查看更多',row)
        },
        //新建发票
        toNewInvoice(){
            this.invoiceVisible = true;
            this.invoiceForm = {
                invoiceType: "",
                lookupType: "",
                invoiceTitle: "",
                taxNumber: "",
                currency: "",
                invoiceContent: "",
                remark: "",
                companyAddress: "",
                companyTel: "",
                account: "",
                bank: "",
            }
        },
        newInvoiceSubmit(){
            this.invoiceVisible = false;
            console.log(this.invoiceForm);
        }

    },
    mounted() {
        this.collectionKey = this.$route.query.collectionKey;
        this.getCollectInfo();    //获取收汇信息

        this.getInvoiceInfo();   //获取发票及附件
    },
};
</script>

<style lang="scss" scoped>
.collectionDetails{
    .topContent{
        background: #FFFFFF;
        padding: 20px;
        position: relative;
        .title{
            height: 32px;
            line-height: 32px;
            font-size: 20px;
            font-weight: bold;
            color: #383838;
        }
        .rightHandle{
            position: absolute;
            top: 20px;
            right: 20px;
        }
        .topInfo{
            margin-top: 15px;
            display: flex;
            .infoItem{
                width: 33%;
                height: 32px;
                line-height: 32px;
                font-size: 14px;
                display: flex;
                .label{
                    width: 60px;
                    color: #5E6D82;
                }
                .value{
                    padding-left: 10px;
                    color: #383838;
                }
            }
        }
    }
    .tabCard{
        margin-top: 20px;
        background: #FFFFFF;
        padding: 0 20px 20px;
        .oneSide{
            background: #F6FBFE;
            border: 1px solid #5DE5EB;
        }
        .twoSide{
            background: rgba(215, 215, 216, 0.15);
            border: 1px solid #D7D7D8;
        }
        .oneSide,.twoSide{
            padding: 20px;
            margin-bottom: 20px;
            .title{
                height: 32px;
                line-height: 32px;
                font-size: 16px;
                font-weight: bold;
                position: relative;
                img{
                    position: absolute;
                    top: 8px;
                }
                .sp1{
                    padding-left: 20px;
                    color: #20BC5F;
                }
                .sp2{                   
                    color: #3A3A3A;
                }
            }
        }
    }

    .w220{
        width: 220px;
        height: 32px;
    }
    .green /deep/.el-input__inner{
        color: #20BC5F;
        font-weight: bold;
    }
    /deep/.el-dialog__body {
        padding: 10px 20px;
    }
    .dialogTitle {
        font-size: 16px;
        height: 24px;
        line-height: 24px;
        font-weight: bold;
        color: #092338;
        margin-bottom: 10px;
    }
}
</style>