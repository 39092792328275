<template>
    <div>
        <InterfaceName :interfaceName="interfaceName" />
        <InterfaceSpe :requestUrl="requestUrl" :requestMethod="requestMethod" />
        <InputParam :inputTableData="inputTableData" />
        <ReturnParam :returnObj="returnObj" />   
    </div>
</template>

<script>
import InterfaceName from "../Acomponents/InterfaceName.vue";
import InterfaceSpe from "../Acomponents/InterfaceSpe.vue";
import InputParam from "../Acomponents/InputParam.vue";
import ReturnParam from "../Acomponents/ReturnParam.vue";

export default {
    components: { InterfaceName, InterfaceSpe, InputParam, ReturnParam },
    data() {
        return {
            interfaceName: "获取采购记录列表",
            requestUrl: "https://crm.qixinerp.com/open/Procure/PurchasingRecord/List",
            requestMethod: "POST",
            inputTableData: [],
            returnObj: {},
        };
    },
    methods: {
        
    },
    mounted() {
        this.inputTableData = [
            {paramName: "procurementPlan", valueType: "string", explain: "采购计划", required: false},
            {paramName: "purchaseNo", valueType: "string", explain: "采购编号", required: false},
            {paramName: "pageIndex", valueType: "integer", explain: "当前页码", required: true},
            {paramName: "pageSize", valueType: "integer", explain: "每页条数", required: true},
        ]
        this.returnObj = {
            "isSuccess": true,
            "errorMessage": null,
            "data": {
                "datas": [
                    {
                        "key": "35d70d004dbc44b4b87488a2cd91740d",
                        "purchaseDate": "2023-05-10",
                        "procurementPlan": "搅拌杯需求采购计划",
                        "purchaseNo": "23051066728",
                        "deliveryDate": "2023-05-25",
                        "customerName": "",
                        "customerKey": "",
                        "manufacturerKey": "83082d5a5bb949d8937499bd61f54992",
                        "manufacturerName": "帝擎信息科技（上海）有限公司",
                        "manufacturerNo": "23042164578",
                        "productInfo": {
                            "productKey": "6fd18b428fea414f8493c09fc0482495",
                            "productNo": "CP96511",
                            "productName": "搅拌杯",
                            "specifications": "",
                            "productDescription": "",
                            "totalPrice": 0,
                            "num": 500,
                            "measureUnit": "个",
                            "otherExpenses": 0,
                            "currency": "CNY",
                            "remarks": "",
                            "exchangeRate": null,
                            "cnyTotalPrice": 0,
                            "unitPrice": 0
                        },
                        "purchasingTotalPrice": 0,
                        "purchasingTotalNum": 500,
                        "settlementCurrency": "CNY"
                    },
                ],
                "pageSize": 10,
                "pageIndex": 1,
                "totalItems": 1
            },
        }
    },
};
</script>

<style lang="scss" scoped>

</style>