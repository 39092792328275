import { render, staticRenderFns } from "./UKR.vue?vue&type=template&id=511d8514&scoped=true&"
import script from "./UKR.vue?vue&type=script&lang=js&"
export * from "./UKR.vue?vue&type=script&lang=js&"
import style0 from "./UKR.vue?vue&type=style&index=0&id=511d8514&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "511d8514",
  null
  
)

export default component.exports