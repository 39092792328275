<template>
    <el-dialog title="使用模板" :visible.sync="dialogVisible" width="1000px" top="5vh">
        <div class="rightBox">
            <div class="fillParameter">
                <div class="parameterItem">
                    <span class="leftlabel">消息类型：</span>
                    <el-select class="rightItem" v-model="typeValue" placeholder="请选择" size="small" :disabled="isUseTemp">
                        <el-option v-for="item in MsgTypeOption" :key="item.name" :label="item.name" :value="item.name"></el-option>
                    </el-select>
                </div>
                <div class="parameterItem">
                    <span class="leftlabel">{{isUseTemp?"消息模板：":""}}</span>
                    <el-select v-if="isUseTemp" class="rightItem" v-model="sendParams.templateKey" @change="chooseTemp" clearable placeholder="请选择" size="small">
                        <el-option v-for="item in templateList" :key="item.key" :label="item.templateName" :value="item.key"></el-option>
                    </el-select>
                    <div class="rightItem" v-else>
                        <span style="font-size:14px;color:var(--themeColor);cursor: pointer;" @click="isUseTemp=true;">使用消息模板</span>
                    </div>
                </div>
                <div class="parameterItem">
                    <span class="leftlabel"></span>
                    <div class="rightItem fillContent">
                        <div v-show="isShowOne" style="margin-bottom:10px;">
                            <el-upload :action="uploadUrl" :headers="headers" :data="uploadData" :show-file-list="false" :before-upload="beforeUpload" :on-progress="uploading" :on-success="uploadOk" :on-error="uploadError" >
                                <div class="uploadBtn" v-loading="uploadLoading">
                                    <img v-if="sendParams.imgUrl" :src="sendParams.imgUrl" alt="">
                                    <i v-else class="el-icon-plus"></i>
                                </div>
                            </el-upload>
                        </div>
                        <div v-show="isShowTwo">
                            <el-input type="textarea" placeholder="请输入消息文本" v-model="sendParams.sendText" maxlength="1000" show-word-limit></el-input>
                        </div>
                        <div v-show="isShowThree" style="margin-top:10px;">
                            <el-input v-model="sendParams.link" placeholder="按钮链接路径" size="small"></el-input>
                        </div>
                    </div>
                </div>
                <el-button type="primary" size="small" :loading="btnLoading" @click="onSend">立即发送</el-button>
                <el-button @click="$emit('onClose')" size="small">关 闭</el-button>
            </div>
            <div class="previewBox">
                <MessagePreview :valueOne="sendParams.imgUrl" :valueTwo="sendParams.sendText" :valueThree="sendParams.link"
                :isShowOne="isShowOne" :isShowTwo="isShowTwo" :isShowThree="isShowThree" />
            </div>
        </div>
    </el-dialog>
</template>

<script>
import MessagePreview from "./MessagePreview.vue";
export default {
    props: ["dialogVisible"],
    components: { MessagePreview },
    data() {
        return {
            sendParams:{
                templateKey: "",
                sendText: "",
                imgUrl: "",
                link: "",
            },
            btnLoading: false,
            //消息类型
            MsgTypeOption: [],
            typeValue: "文字",
            //上传图片
            uploadUrl: `${this.$diskUrl}/File/Upload`,
            headers: { authorization: 'Bearer ' + localStorage.getItem('token') },
            uploadData: { folderKey: "0", remarkName: "", permission: '我的' },
            uploadLoading: false,
            //消息模板
            templateList: [],
            isUseTemp: true,
        };
    },
    computed:{
        isShowOne(){
            return this.typeValue.includes("图片");
        },
        isShowTwo(){
            return this.typeValue.includes("文字");
        },
        isShowThree(){
            return this.typeValue.includes("按钮");
        },
    },
    methods: {
        //消息模板列表
        getTemplates(){
            this.$axios({method: "GET", url: this.$marketUrl + "/WhatsApp/v2/GetTemplates",}).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.templateList = data;
                }
            })
        },
        chooseTemp(value){
            if(!value){
                this.isUseTemp = false;
                return false;
            }
            let cItem = this.templateList.filter(item => item.key == value)[0];
            this.typeValue = this.MsgTypeOption.filter(item => item.value == cItem.templateTypeKey)[0].name;
            this.sendParams.imgUrl = cItem.fileUrl;
            this.sendParams.sendText = cItem.content;
            this.sendParams.link = cItem.url;
        },
        //消息类型下拉框
        getMsgType(){
            this.$axios({method: 'GET',url: this.$marketUrl + '/WhatsApp/GetMsgType',}).then((res) =>{
                const {isSuccess ,data} = res;
                if (isSuccess) {
                    this.MsgTypeOption = data;
                }
            })
        },
        //上传图片
        beforeUpload(file) {
            var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
            const isImg = testmsg === "png" || testmsg === "jpg" || testmsg === "jpeg";
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isImg) {
                this.$message({ message: "请上传图片(png/jpg/jpeg)!", type: "warning"});
            }
            if (!isLt2M) {
                this.$message({ message: "图片大小不能超过2MB!", type: "warning"});
            }
            return isImg && isLt2M;
        },
        uploading(){ 
            this.uploadLoading = true;
        },
        uploadOk(response){
            this.uploadLoading = false;
            const {isSuccess, data} = response;
            if(isSuccess){
                this.sendParams.imgUrl = data.fileUrl;
                this.$message({message: "上传成功",type: 'success'});
            }
        },
        uploadError(){
            this.uploadLoading = false;
            this.$message({message: "上传失败",type: 'warning'});
        },

        //立即群发
        isBefore(){
            const {imgUrl,sendText,link} = this.sendParams;
            if(this.isShowOne && !imgUrl.trim()){
                this.$message({message: '请上传图片!',type: 'warning'});
                return false;
            }
            if(this.isShowTwo && !sendText.trim()){
                this.$message({message: '请输入消息文本!',type: 'warning'});
                return false;
            }
            if(this.isShowThree && !link.trim()){
                this.$message({message: '请填写按钮链接路径!',type: 'warning'});
                return false;
            }
            return true;
        },
        async onSend(){
            if(this.isBefore()){
                // console.log(this.sendParams);
                this.$emit('onSend',this.sendParams);
                //清空数据
                this.sendParams = {
                    templateKey: "",
                    sendText: "",
                    imgUrl: "",
                    link: "",
                }
            }
        },

    },
    mounted() {
        this.getTemplates();//消息模板列表
        this.getMsgType(); //消息类型下拉框
    },
};
</script>

<style lang='scss' scoped>
/deep/.el-dialog__header{
    display: none;
}
/deep/.el-dialog__body{
    height: 90vh;
    box-sizing: border-box;
    padding: 20px;
}
.rightBox{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    box-sizing: border-box;
    .fillParameter{
        width: calc(100% - 400px);
        height: 100%;
        .parameterItem{
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            .leftlabel{
                width: 80px;
                display: inline-block;
                color: #5E6D82;
                font-size: 14px;
            }
            .rightItem{
                width: calc(100% - 80px);
            }
            .fillContent{
                padding: 10px;
                box-sizing: border-box;
                background: #F2F2F2;
                border: 1px solid #D8D8D8;
                /deep/.el-textarea__inner{
                    height: 170px;
                }
                .uploadBtn{
                    width: 100px;
                    height: 100px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 4px;
                    border: 1px solid #C3C3C3;
                    box-sizing: border-box;
                    background: #FFF;
                    &:hover{
                        border-color: var(--themeColor);
                    }
                    img{
                        width: 100%;
                        height: 100%;
                        border-radius: 4px;
                    }
                    .el-icon-plus{
                        font-size: 40px;
                        color: #C3C3C3;
                    }
                    &:hover .el-icon-plus{
                        color: var(--themeColor);
                    }
                }
            }
        }
    }
    .previewBox{
        width: 380px;
        height: 100%;
    }
}
</style>