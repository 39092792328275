<template>
  <div class="nav-bar">
    <div class="indicator">
      <div class="content" v-show="indicatorTilte">{{ indicatorTilte }}</div>
    </div>
    <div @click="setType('importer')" class="control-item cursor-pointer" :class="{ highlight: companyType === 'importer' }">
      <img :src="companyType === 'importer' ? icon.importh : icon.import" />
      <span>采购数据</span>
    </div>
    <div @click="setType('exporter')" class="control-item cursor-pointer" :class="{ highlight: companyType === 'exporter' }">
      <img :src="companyType === 'exporter' ? icon.exporth : icon.export" />
      <span>供应数据</span>
    </div>
    <div class="company">
      <div>
        <img src="@/firm/images/company/nav-bar/company.png" />
        <span style="font-weight: bold">{{ company }}</span>
      </div>
      <span style="color: #909090; font-size: 13px; padding-left: 30px">该公司的{{ companyType === "importer" ? "采购" : "供应" }}数据</span>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapMutations } from "vuex";

export default Vue.extend({
  data() {
    return {
      icon: {
        import: require("@/firm/images/company/nav-bar/import.png"),
        importh: require("@/firm/images/company/nav-bar/import-h.png"),
        export: require("@/firm/images/company/nav-bar/export.png"),
        exporth: require("@/firm/images/company/nav-bar/export-h.png"),
      },
    };
  },
  computed: {
    ...mapState(["company", "companyType"]),
    ...mapState("anchor", {
      currentAnchor: "current",
    }),
    indicatorTilte() {
      switch (this.currentAnchor) {
        case "HSBM6":
        case "HSBM6C":
        case "HSBM6CC":
        case "HSBM6CCC":
        case "HSBM4":
        case "HSBM4C":
        case "HSBM4CC":
        case "HSBM4CCC":
        case "HSBM2":
        case "HSBM2C":
        case "HSBM2CC":
        case "HSBM2CCC":
        case "SITCRANK":
        case "SITCCOUNTRY":
        case "SITCCOUNTRYC":
        case "SITCCOMPANY":
          return "产品分析";
        case "GJFX":
        case "COUNTRENCODE":
        case "COUNTRYENCODECOM":
        case "COUNTRYCOM":
        case "COUNTRYCOMENCODE":
        case "SSZ":
          return "国家分析";
        case "COMPAANLYSE":
        case "COMPECODE":
        case "COMPECODECOUNTR":
        case "COMPIMPORTER":
        case "COMPIMPORTERENCODE":
        case "COMPEXPORTER":
        case "COMPEXPORTERENCODE":
        case "gxtixcc":
        case "gxticce":
        case "gtxicdce":
          return "企业分析";
        case "GNWQYG":
        case "GNGK":
        case "GNWXHG":
        case "GWGK":
          return "港口分析";
        default:
          return "";
      }
    },
  },
  methods: {
    ...mapMutations(["setType"]),
  },
});
</script>

<style lang="scss" scoped>
.nav-bar {
  margin-top: 1em;
  position: relative;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  z-index: 99;
  background: #fff;
  border-bottom: 2px solid #f1f2f6;
  .indicator {
    .content {
      font-weight: bold;
      font-size: 18px;
      margin: 0 20px;
      padding-left: 5px;
      border-left: 5px var(--themeColor, #3152AF) solid;
    }
  }
  .control-item {
    width: 160px;
    height: 62px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.highlight {
      background:#bac3de;
      color: var(--themeColor, #3152AF);
    }
    img {
      margin-right: 6px;
    }
  }
  .company {
    min-width: 240px;
    position: absolute;
    right: 20px;
    img {
      margin-right: 10px;
    }
  }
}
</style>
