<template>
    <div>
        <div class="header">
            <div class="title">邮箱地址黑名单</div>
            <div class="describe">黑名单中邮箱对应的邮件将自动收取到垃圾邮件(不会收到新邮件提醒)</div>
        </div>
        <div class="inputBox">
            <el-input v-model="emailValue" placeholder="邮箱地址" size="small" style="width:500px;margin-right:20px;"></el-input>
            <el-button type="primary" size="small" @click="toAddBlackOne" :loading="btnLoading">添加到黑名单</el-button>
        </div>
        <el-table :data="emailBlacklist" :header-cell-style="{'background-color':'#F3F3F3','color':'#3A3A3A'}">
            <el-table-column prop="email" label="邮箱地址"></el-table-column>
            <el-table-column prop="createDate" label="创建时间"></el-table-column>
            <el-table-column label="操作" width="200">
                <template slot-scope="scope">
                    <div class="handleDelBtn" @click="toDelBlack(scope.row)">删除</div>
                </template>
            </el-table-column>
        </el-table>

        
        <div class="header" style="margin-top:30px;">
            <div class="title">域名黑名单</div>
            <div class="describe">黑名单中域名对应的邮件将自动收取到垃圾邮件(不会收到新邮件提醒)</div>
        </div>
        <div class="inputBox">
            <el-input v-model="domainValue" placeholder="域名" size="small" style="width:500px;margin-right:20px;"></el-input>
            <el-button type="primary" size="small" @click="toAddBlackTwo" :loading="btnLoading">添加到黑名单</el-button>
        </div>
        <el-table :data="domainBlacklist" :header-cell-style="{'background-color':'#F3F3F3','color':'#3A3A3A'}">
            <el-table-column prop="domain" label="域名"></el-table-column>
            <el-table-column prop="createDate" label="创建时间"></el-table-column>
            <el-table-column label="操作" width="200">
                <template slot-scope="scope">
                    <div class="handleDelBtn" @click="toDelBlack(scope.row)">删除</div>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
export default {
    data() {
        return {
            emailValue: "",
            domainValue: "",
            emailBlacklist: [],
            domainBlacklist: [],
            btnLoading: false,
        };
    },
    methods: {
        getList(){
            this.$axios.get(`${this.$mailUrl}/v1/config/Blacklist`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.emailBlacklist = data.filter(item => item.email != "");
                    this.domainBlacklist = data.filter(item => item.domain != "");
                }
            });
        },
        toAddBlackOne(){
            if(!this.emailValue){ return false; }
            this.btnLoading = true;
            this.$axios.post(`${this.$mailUrl}/v1/config/Blacklist`,{
                "key": "",
                "email": this.emailValue,
                "domain": "",
            }).then((res) => {
                const { isSuccess } = res;
                this.btnLoading = false;
                if (isSuccess) {
                    this.emailValue = "";
                    this.$message({ message: '添加成功', type: 'success'});
                    this.getList();
                }
            });
        },
        toAddBlackTwo(){
            if(!this.domainValue){ return false; }
            this.btnLoading = true;
            this.$axios.post(`${this.$mailUrl}/v1/config/Blacklist`,{
                "key": "",
                "email": "",
                "domain": this.domainValue,
            }).then((res) => {
                const { isSuccess } = res;
                this.btnLoading = false;
                if (isSuccess) {
                    this.domainValue = "";
                    this.$message({ message: '添加成功', type: 'success'});
                    this.getList();
                }
            });
        },
        toDelBlack(row){
            this.$confirm(`是否删除该黑名单?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$axios.delete(`${this.$mailUrl}/v1/config/Blacklist?key=${row.key}`).then(res => {
                    const {isSuccess} = res;
                    if(isSuccess){
                        this.$message({type: 'success',message: '删除成功!'});
                        this.getList();
                    }
                });
            }).catch(() => {});
        },
    },
    mounted() {
        this.getList();
    },
};
</script>

<style lang="scss" scoped>
.header{
    margin-bottom: 10px;
    overflow: hidden;
    height: 32px;
    line-height: 32px;
    .title{
        float: left;
        font-size: 16px;
        color: #333;
        font-weight: bold;
    }
    .describe{
        float: left;
        margin-left: 20px;
        font-size: 12px;
        color: #708090;
    }
}
.inputBox{
    display: flex;
    margin-bottom: 10px;
}
</style>