<script>
export default {
  data() {
    return {
      src: process.env.VUE_APP_MAIL_BASE
    }
  }
}
</script>

<template>
  <div v-if="src" style="width: 100%; height: 100%">
    <iframe :src="src" style="width: 100%; height: 100%; border: none"></iframe>
  </div>
</template>
