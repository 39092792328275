<template>
<div style="display: flex;">
    <FinanceNav @chooseNav="chooseNav" :currentPath="currentPath" />
    <div class="viewbox">
        <div class="boxOne">
            <div class="title">到账记录</div>
            <div class="rightHandle"></div>
        </div>

        <div class="boxTwo">
            <div style="display: flex;">
                <el-input class="searchItem" v-model="filterParam.customerName" placeholder="公司名称" size="small" clearable></el-input>
                <el-select class="searchItem" v-model="filterParam.userName" placeholder="选择员工" filterable size="small" clearable @change="toSearch">
                    <el-option v-for="item in staffOption" :key="item.key" :label="item.name" :value="item.name"></el-option>
                </el-select>
                <el-input class="searchItem" v-model="filterParam.orderNo" placeholder="订单编号" size="small" clearable></el-input>

                <span style="padding-left:10px;color:#6a6a6a;font-size:14px;">到账金额:</span>
                <el-input-number v-model="filterParam.startReceivedAmount" :step="2" size="small" :controls="false"></el-input-number>
                <span style="padding:0 5px;color:#6a6a6a;font-size:14px;">至</span>
                <el-input-number v-model="filterParam.endReceivedAmount" :step="2" size="small" :controls="false"></el-input-number>
                <el-button type="primary" size="small" @click="toSearch" style="margin-left:10px;">搜 索</el-button>
                
                <!-- <span style="padding-left:10px;color:#6a6a6a;font-size:14px;">预计到款日期:</span>
                <el-date-picker v-model="filterParam.satrtExpectedReceiptDate" placeholder="起始日期" type="date" value-format="yyyy-MM-dd" size="small" style="width:150px;" @change="toSearch"></el-date-picker>
                <span style="padding:0 5px;color:#6a6a6a;font-size:14px;">至</span>
                <el-date-picker v-model="filterParam.endExpectedReceiptDate" placeholder="结束日期" type="date" value-format="yyyy-MM-dd" size="small" style="width:150px;" @change="toSearch"></el-date-picker> -->
            </div>
            <div style="margin-top:10px;display: flex;">
                <!-- <span style="padding-left:10px;color:#6a6a6a;font-size:14px;">应收款金额:</span>
                <el-input-number v-model="filterParam.startOrderAmount" :step="2" size="small" :controls="false"></el-input-number>
                <span style="padding:0 5px;color:#6a6a6a;font-size:14px;">至</span>
                <el-input-number v-model="filterParam.endOrderAmount" :step="2" size="small" :controls="false"></el-input-number> -->
            </div>
        </div>

        <div class="boxTable">
            <el-table style="width:100%;" height="calc(100vh - 300px)" v-loading="tableLoading" :data="recordList"
                :header-cell-style="{'background-color':'#F2F2F2','color':'#3A3A3A'}" :row-style="rowStyle">
                <el-table-column prop="uName" label="员工" width="80"></el-table-column>
                <el-table-column prop="orderNo" label="订单编号"></el-table-column>
                <el-table-column prop="customerName" label="公司名称"></el-table-column>
                <el-table-column prop="remittanceCompany" label="汇款公司"></el-table-column>
                <el-table-column label="汇款金额">
                    <template slot-scope="scope">
                        <div>{{scope.row.receiptAmount}} {{scope.row.currency}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="receiptDate" label="汇款时间"></el-table-column>
                <el-table-column prop="remittingBank" label="汇款银行"></el-table-column>
                <el-table-column prop="ourAccount" label="我方账户"></el-table-column>
                <el-table-column label="付款凭证">
                    <template slot-scope="scope">
                        <el-image style="width:48px;height:48px" :src="scope.row.paymentVoucher" :preview-src-list="[scope.row.paymentVoucher]"></el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="userName" label="经办人"></el-table-column>
                <el-table-column prop="createTime" label="创建时间"></el-table-column>
                <el-table-column prop="remark" label="汇款备注"></el-table-column>
            </el-table>
        </div>

        <div class="bottomPagination">
            <el-button style="float:left;margin:15px 20px;" size="small" icon="el-icon-refresh" @click="onRefresh">刷 新</el-button>
            <el-pagination background layout="total,sizes,prev,pager,next" :page-sizes="[10, 20, 40]" :page-size.sync="pageSize" @size-change="pageSizeChange" :total="totalItems" style="float:right;margin:15px 20px;" :current-page.sync="pageIndex" @current-change="pageIndexChange"></el-pagination>
        </div>

    </div>
</div>
</template>

<script>
import FinanceNav from "@/components/FinanceNav.vue";
export default {
    components: { FinanceNav },
    props: {
        handleSelect: {
            type: Function,
            default: null,
        },
    },
    data() {
        return {
            currentPath: "/finance/receiptRecord",
            // 过滤字段
            filterParam: {
                customerName: "",
                userName: "",
                orderNo: "",
                satrtExpectedReceiptDate: "",
                endExpectedReceiptDate: "",
                startOrderAmount: 0,
                endOrderAmount: 0,
                startReceivedAmount: 0,
                endReceivedAmount: 0,
                isCheckOut: 0,
            },
            staffOption: [],
            //列表展示
            tableLoading: false,
            recordList: [],
            totalItems: 0,
            pageIndex: 1,
            pageSize: 10,
        };
    },
    methods: {
        chooseNav(index){
            this.handleSelect(index);
            this.$router.push(index);
        },
        rowStyle({rowIndex}){
            if(rowIndex % 2 == 1){
                return {'background-color':'#F9FAFC'}
            }
        },

        getStaffBox(){
            this.$axios.get(`${this.$personnelUrl}/Organization/FindStaffBox`).then((res) => {
                const { isSuccess, data } = res;
                if (isSuccess) {
                    this.staffOption = data;
                }
            })
        },

        onRefresh(){
            this.filterParam = {
                customerName: "",
                userName: "",
                orderNo: "",
                satrtExpectedReceiptDate: "",
                endExpectedReceiptDate: "",
                startOrderAmount: 0,
                endOrderAmount: 0,
                startReceivedAmount: 0,
                endReceivedAmount: 0,
                isCheckOut: 0,
            };
            this.toSearch();
        },
        toSearch(){
            this.pageIndex = 1;
            this.getList();
        },
        getList(){
            this.tableLoading = true;
            this.$axios.post(`${this.$orderUrl}/Performance/PageRecordReceipt`,{
                ...this.filterParam,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
            }).then((res) => {
                const { isSuccess , data } = res;
                this.tableLoading = false;
                if (isSuccess) {
                    this.recordList = data.datas;
                    this.totalItems = data.totalItems;
                }
            });
        },
        pageIndexChange(page) {
            this.pageIndex = page;
            this.getList();
        },
        pageSizeChange() {
            this.pageIndex = 1;
            this.getList();
        },

    },
    mounted() {
        this.getStaffBox();
        this.getList(); //获取到账记录列表
    },
};
</script>

<style lang="scss" scoped>
.viewbox{
    width: calc(100% - 200px);
    height: calc(100vh - 140px);
    background: rgba($color: #FFFFFF, $alpha: 0.7);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    margin-bottom: 60px;
    .boxOne,.boxTwo{
        overflow: hidden;
        padding: 8px 0;
        line-height: 32px;
        border-bottom: 1px solid #E6E7EB;
        .title{
            float: left;
            margin-left: 40px;
            font-size: 24px;
            font-weight: bold;
            color: #3A3A3A;
        }
        .rightHandle{
            float: right;
            margin-right: 20px;
        }
        .searchItem{
            width: 160px;
            margin-left: 10px;
        }
    }
    .bottomPagination{
        position: fixed;
        bottom: 0;
        right: 0;
        z-index: 10;
        width: calc(100vw - 70px);
        height: 60px;
        background: #FFFFFF;
        box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.16);
    }
}
</style>