<template>
    <div>
        <InterfaceName :interfaceName="interfaceName" />
        <InterfaceSpe :requestUrl="requestUrl" :requestMethod="requestMethod" />
        <ReturnParam :returnObj="returnObj" />
    </div>
</template>

<script>
import InterfaceName from "../Acomponents/InterfaceName.vue";
import InterfaceSpe from "../Acomponents/InterfaceSpe.vue";
import ReturnParam from "../Acomponents/ReturnParam.vue";

export default {
    components: { InterfaceName, InterfaceSpe, ReturnParam },
    data() {
        return {
            interfaceName: "获取客户来源",
            requestUrl: "https://crm.qixinerp.com/open/Customer/GetCustomerSource",
            requestMethod: "GET",
            returnObj: {},
        };
    },
    methods: {},
    mounted() {
        this.returnObj = {
            isSuccess: true,
            errorMessage: null,
            data: [
                {
                    key: "d0196c5c743e4064bc037811c4791251",
                    name: "电商平台",
                    index: 0,
                    isDefault: false,
                    isSystem: false,
                },
                {
                    key: "52a4f653982b4094b7a6bc3de8c9adc1",
                    name: "跨境搜",
                    index: 0,
                    isDefault: false,
                    isSystem: false,
                },
                {
                    key: "6a83e725cd6a4271a173e68f443a147e",
                    name: "货代物流",
                    index: 0,
                    isDefault: false,
                    isSystem: false,
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>

</style>