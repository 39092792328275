<template>
    <div>
        <InterfaceName :interfaceName="interfaceName" />
        <InterfaceSpe :requestUrl="requestUrl" :requestMethod="requestMethod" />
        <InputParam :inputTableData="inputTableData" />
        <ReturnParam :returnObj="returnObj" />
    </div>
</template>

<script>
import InterfaceName from "../Acomponents/InterfaceName.vue";
import InterfaceSpe from "../Acomponents/InterfaceSpe.vue";
import InputParam from "../Acomponents/InputParam.vue";
import ReturnParam from "../Acomponents/ReturnParam.vue";

export default {
    components: { InterfaceName, InterfaceSpe, InputParam, ReturnParam },
    data() {
        return {
            interfaceName: "获取客户详情",
            requestUrl: "https://crm.qixinerp.com/open/Customer/GetCustomerDetail",
            requestMethod: "GET",
            inputTableData: [],
            returnObj: {},
        };
    },
    methods: {},
    mounted() {
        this.inputTableData = [
            {paramName: "customerKey",valueType: "string",explain: "客户key",required: true},
        ];
        this.returnObj = {
            "isSuccess": true,
            "errorMessage": null,
            "data": {
                "tags": [
                    {
                        "lableKey": "eb94f1d693a349779fe7ecb8b3f2d3f2",
                        "key": "7f8e4cfa06a8451db8bac46374a7bbb9",
                        "name": "家用电器",
                        "color": "#1890ff"
                    },
                    {
                        "lableKey": "138fe056efac445cab54400db7719c4e",
                        "key": "31b3a15fb764475d836c2ad96f2c2286",
                        "name": "医药",
                        "color": "#52c41a"
                    }
                ],
                "note": "添加到我的客户",
                "isPrivate": true,
                "slightCollisionRisk": [],
                "seriousCollisionRisk": [],
                "companyKey": "1382994122284bc8a5d77f0fed4f87b2",
                "companyNo": "CBS095ae0",
                "name": "PRIME CHAMP PRODUCTION B.V.",
                "nickname": null,
                "customerSourceKey": "d0196c5c743e4064bc037811c4791251",
                "mainIndustryValue": null,
                "mainBusinessTypeValue": null,
                "companyScaleValue": "少于59人",
                "country": "荷兰",
                "timeZone": null,
                "star": 0,
                "webSite": "primechamp.com",
                "fax": null,
                "areaCodeValue": null,
                "call": [],
                "address": "DONKSTRAAT 18",
                "images": null,
                "contacters": [],
                "privateGroupKey": "4062575c0b234ee2bbf915985a991daa",
                "publicGroupKey": "7726caf7a22a497e9b05bb7e3f565b7c",
                "statuName": "无",
                "statuColor": "#d9d9d9",
                "statuKey": "8f38d722ad244aac8e4fc83b14cec73d",
                "mainContacterName": null,
                "mainEmail": null,
                "isAttention": false,
                "isTop": false,
                "isFollow": true,
                "stageKey": null,
                "score": 60,
                "tradeType": "未知",
                "createTime": "2022-05-20 17:51:35",
                "createUser": "彭贤鑫",
                "followUpUser": "彭贤鑫",
                "customsDataName": "NIKE CANADA CORP"
            },
        }
    },
};
</script>

<style lang="scss" scoped>
</style>