<template>
<div>
    <div v-if="ie=='i'">
        <!-- 墨西哥进口提单明细 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th width="25%">提单号</th>
                        <td width="25%">{{billInfo.DeclareNum}}</td>
                        <th width="25%">出口商ID</th>
                        <td width="25%">{{billInfo.ExporterId}}</td>
                    </tr>
                    <tr>
                        <th>进口商所在国</th>
                        <td>{{billInfo.ImporterCountry}}</td>
                        <th>收货人税号</th>
                        <td>{{billInfo.ImporterTaxNumber}}</td>
                    </tr>
                    <tr>
                        <th>出口商所在国</th>
                        <td>{{billInfo.ExporterCountry}}</td>
                        <th>发货人SIC代码</th>
                        <td>{{billInfo.ExporterSicCodes}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th width="25%">产品分类代码</th>
                        <td width="25%">{{billInfo.NaicsCode}}</td>
                        <th width="25%">计量单位</th>
                        <td width="25%">{{billInfo.MeasureUnit}}</td>
                    </tr>
                    <tr>
                        <th>汇率</th>
                        <td>{{billInfo.ExchangeRate}}</td>
                        <th>美元单价</th>
                        <td>{{billInfo.ValueUnit}}</td>
                    </tr>
                    <tr>
                        <th>墨西哥比索单价</th>
                        <td>{{billInfo.MexicanPesoasUnit}}</td>
                        <th>CIF墨西哥比索金额</th>
                        <td>{{billInfo.MexicanPesoasValue}}</td>
                    </tr>
                    <tr>
                        <th>申报墨西哥比索金额</th>
                        <td>{{billInfo.MxPesosComerValue}}</td>
                        <th>发票金额</th>
                        <td>{{billInfo.InvoiceValue}}</td>
                    </tr>
                    <tr>
                        <th>发票币种</th>
                        <td>{{billInfo.InvoiceCurrency}}</td>
                        <th>商业数量</th>
                        <td>{{billInfo.ComerQuantity}}</td>
                    </tr>
                    <tr>
                        <th>商业数量单位</th>
                        <td>{{billInfo.ComerUnit}}</td>
                        <th>北美自由贸易协定税率</th>
                        <td>{{billInfo.NaftaTaxRate}}</td>
                    </tr>
                    <tr>
                        <th>品牌</th>
                        <td>{{billInfo.Brand}}</td>
                        <th>型号</th>
                        <td>{{billInfo.Variety}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">运输信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th>运输方式</th>
                        <td colspan="3">{{billInfo.TransportMethod}}</td>
                    </tr>
                    <tr>
                        <th width="25%">运费值（MXN）</th>
                        <td width="25%">{{billInfo.FreightValueMXN}}</td>
                        <th width="25%">保险价值（MXN）</th>
                        <td width="25%">{{billInfo.InsuranceValueMXN}}</td>
                    </tr>
                    <tr>
                        <th>关区/港口/所属洲</th>
                        <td>{{billInfo.CustomStatePort}}</td>
                        <th>关区</th>
                        <td>{{billInfo.Custom}}</td>
                    </tr>
                    <tr>
                        <th>关区Key</th>
                        <td>{{billInfo.CustomKey}}</td>
                        <th>报关ID</th>
                        <td>{{billInfo.DeclarationId}}</td>
                    </tr>
                    <tr>
                        <th>港口</th>
                        <td>{{billInfo.Port}}</td>
                        <th>所属洲</th>
                        <td>{{billInfo.State}}</td>
                    </tr>
                    <tr>
                        <th>制度Key</th>
                        <td>{{billInfo.RegimeKey}}</td>
                        <th>海关制度</th>
                        <td>{{billInfo.Regime}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">货物信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">贸易方式</th>
                        <td width="25%">{{billInfo.Incoterm}}</td>
                        <th width="25%">来源国</th>
                        <td width="25%">{{billInfo.Country}}</td>
                    </tr>
                    <tr>
                        <th>SITC编号</th>
                        <td colspan="3">{{billInfo.Sitc}}</td>
                    </tr>
                    <tr>
                        <th>SITC描述</th>
                        <td colspan="3">{{billInfo.SitcDescription}}</td>
                    </tr>
                    <tr>
                        <th>是否集装箱</th>
                        <td>{{billInfo.IsContainerized}}</td>
                        <th>体积（TEU）</th>
                        <td>{{billInfo.VolumeTEU}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
    <div v-if="ie=='e'">
        <!-- 墨西哥出口提单明细 -->
        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th width="25%">提单号</th>
                        <td width="25%">{{billInfo.DeclareNum}}</td>
                        <th width="25%">进口商ID</th>
                        <td width="25%">{{billInfo.ImporterId}}</td>
                    </tr>
                    <tr>
                        <th>进口商所在国</th>
                        <td>{{billInfo.ImporterCountry}}</td>
                        <th>收货人税号</th>
                        <td>{{billInfo.ImporterTaxNumber}}</td>
                    </tr>
                    <tr>
                        <th>出口商所在国</th>
                        <td>{{billInfo.ExporterCountry}}</td>
                        <th>发货人SIC代码</th>
                        <td>{{billInfo.ExporterSicCodes}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <table>
                <tbody>
                    <tr>
                        <th width="25%">产品分类代码</th>
                        <td width="25%">{{billInfo.NaicsCode}}</td>
                        <th width="25%">计量单位</th>
                        <td width="25%">{{billInfo.MeasureUnit}}</td>
                    </tr>
                    <tr>
                        <th>汇率</th>
                        <td>{{billInfo.ExchangeRate}}</td>
                        <th>美元单价</th>
                        <td>{{billInfo.ValueUnit}}</td>
                    </tr>
                    <tr>
                        <th>墨西哥比索单价</th>
                        <td>{{billInfo.MexicanPesoasUnit}}</td>
                        <th>CIF墨西哥比索金额</th>
                        <td>{{billInfo.MexicanPesoasValue}}</td>
                    </tr>
                    <tr>
                        <th>申报墨西哥比索金额</th>
                        <td>{{billInfo.MxPesosComerValue}}</td>
                        <th>商业价值</th>
                        <td>{{billInfo.MxPesosComerValue}}</td>
                    </tr>
                    <tr>
                        <th>发票金额</th>
                        <td>{{billInfo.InvoiceValue}}</td>
                        <th>发票币种</th>
                        <td>{{billInfo.InvoiceCurrency}}</td>
                    </tr>
                    <tr>
                        <th>商业数量</th>
                        <td>{{billInfo.ComerQuantity}}</td>
                        <th>商业数量单位</th>
                        <td>{{billInfo.ComerUnit}}</td>
                    </tr>
                    <tr>
                        <th>北美自由贸易协定税率</th>
                        <td colspan="3">{{billInfo.NaftaTaxRate}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">运输信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">运输方式</th>
                        <td width="25%">{{billInfo.TransportMethod}}</td>
                        <th width="25%">目的国</th>
                        <td width="25%">{{billInfo.Country}}</td>
                    </tr>
                    <tr>
                        <th>关区/港口/所属洲</th>
                        <td colspan="3">{{billInfo.CustomStatePort}}</td>
                    </tr>
                    <tr>
                        <th>运费值（MXN）</th>
                        <td>{{billInfo.FreightValueMXN}}</td>
                        <th>保险价值（MXN）</th>
                        <td>{{billInfo.InsuranceValueMXN}}</td>
                    </tr>
                    <tr>
                        <th>代理海关</th>
                        <td>{{billInfo.CustomBroker}}</td>
                        <th>海关制度</th>
                        <td>{{billInfo.Regime}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>

        <el-card class="partItem">
            <div slot="header">
                <span class="headTitle">货物信息</span>
            </div>
            <table>
                <tbody>
                    <tr>
                        <th width="25%">买家</th>
                        <td width="25%">{{billInfo.PurchaseCountry}}</td>
                        <th width="25%">货物目的地</th>
                        <td width="25%">{{billInfo.ItemDestination}}</td>
                    </tr>
                    <tr>
                        <th>SITC编号</th>
                        <td colspan="3">{{billInfo.Sitc}}</td>
                    </tr>
                    <tr>
                        <th>SITC描述</th>
                        <td colspan="3">{{billInfo.SitcDescription}}</td>
                    </tr>
                    <tr>
                        <th>是否集装箱</th>
                        <td>{{billInfo.IsContainerized}}</td>
                        <th>体积（TEU）</th>
                        <td>{{billInfo.VolumeTEU}}</td>
                    </tr>
                </tbody>
            </table>
        </el-card>
    </div>
</div>
</template>

<script>
export default {
    props:["billInfo","ie"],
    data() {
        return {

        };
    },
    methods: {
        
    },
    mounted() {

    },
};
</script>

<style lang="scss" scoped>
.partItem {
    margin-bottom: 20px;
    /deep/.el-card__header {
        padding: 10px 20px;
        .headTitle {
            display: inline-block;
            padding-left: 10px;
            font-size: 14px;
            border-left: 2px solid var(--themeColor);
            font-weight: bold;
        }
    }
}
table {
    width: 100%;
    border-collapse: collapse;
    tr {
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;
        th,
        td {
            font-size: 12px;
            color: #666;
            height: 40px;
            border: 1px solid #eaeaea;
            text-align: center;
        }
        th {
            background-color: #f5f7fa;
            font-weight: 700;
        }
        .bg {
            background-color: var(--themeColor);
            color: #fff;
            font-weight: 700;
            opacity: 0.8;
        }
    }
}
</style>