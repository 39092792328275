<template>
<div style="display: flex;">
    <CustomerNav @chooseNav="chooseNav" :currentPath="currentPath" />
    <div class="viewbox">
        <div class="boxOne">
            <div class="title">客户动态</div>
            <!-- 管理员可以查看整个部门 -->
            <PowerSelect defaultText="仅我的客户" @onChoose="onChoose" />
        </div>

        <div class="boxTwo">
            <div class="rightHandle">
                <el-input v-model="filterParam.customerName" placeholder="公司名称" size="small" style="width:180px;margin-right:10px;" @keyup.enter.native="toSearch"></el-input>

                <el-input v-model="filterParam.content" placeholder="内容" size="small" style="width:180px;margin-right:10px;" @keyup.enter.native="toSearch"></el-input>

                <el-input v-model="filterParam.type" placeholder="类型" size="small" style="width:180px;margin-right:10px;" @keyup.enter.native="toSearch"></el-input>
                
                <el-date-picker v-model="filterParam.beginTime" type="date" value-format="yyyy-MM-dd" placeholder="开始时间" size="small" style="width:180px;margin-right:10px;" @change="toSearch"></el-date-picker>

                <el-date-picker v-model="filterParam.endTime" type="date" value-format="yyyy-MM-dd" placeholder="结束时间" size="small" style="width:180px;" @change="toSearch"></el-date-picker>
                
            </div>
        </div>

        <div class="boxTable" v-loading="tableLoading">
            <el-empty v-if="totalItems==0" description="暂无数据" style="height:calc(100vh - 300px);"></el-empty>
            <el-table v-else style="width:100%;cursor: pointer;" height="calc(100vh - 300px)" :data="dynamicList"
                :header-cell-style="{'background-color':'#F2F2F2','color':'#3A3A3A'}" :row-style="rowStyle">
                <el-table-column label="公司名称" show-overflow-tooltip width="300px">
                    <template slot-scope="scope">
                        <el-popover trigger="hover">
                            <CompanyCard :ckey="scope.row.customerKey" />
                            <el-link slot="reference" type="primary" @click.stop="openCustomer(scope.row)">{{ scope.row.customerName }}</el-link>
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column prop="contactPerson" label="联系人" width="100px">
                    <template slot-scope="scope">
                        <el-popover trigger="hover">
                            <ContactCard :ckey="scope.row.contactsKey" />
                            <div slot="reference">{{scope.row.contactPerson}}</div>
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column prop="followContent" label="跟进内容" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div v-html="scope.row.followContent"></div>
                    </template>
                </el-table-column>
                <el-table-column prop="followTime" label="跟进时间" width="150px"></el-table-column>
                <el-table-column prop="followType" label="跟进类型" width="100px"></el-table-column>
                <el-table-column prop="follower" label="跟进人" width="100px">
                    <template slot-scope="scope">
                        <el-popover trigger="hover">
                            <ColleageCard :ckey="scope.row.followerKey" />
                            <div slot="reference">{{scope.row.follower}}</div>
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column prop="nextFollowTime" label="下次跟进时间" width="150px"></el-table-column>
                <el-table-column label="附件" width="80px">
                    <template slot-scope="scope">
                        <el-button type="text" size="mini" v-if="scope.row.annex.length > 0" @click="viewAnnex(scope.row.annex)">查看</el-button>
                        <span v-else>暂无附件</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <div class="bottomPagination">
            <el-button style="float:left;margin:15px 20px;" size="small" icon="el-icon-refresh" @click="onRefresh">刷 新</el-button>
            <el-pagination background layout="total,sizes,prev,pager,next" :page-sizes="[10, 20, 40]" :page-size.sync="pageSize" @size-change="pageSizeChange" :total="totalItems" style="float:right;margin:15px 20px;" :current-page.sync="pageIndex" @current-change="pageIndexChange"></el-pagination>
        </div>

        <el-dialog title="附件" :visible.sync="viewAnnexVisible" width="500px">
            <div v-for="(row,index) in annexList" :key="index" style="display:inline-block;margin-right:10px;">
                <div v-if="isImageFile(row.name)">
                    <el-image style="width: 60px; height: 60px" :src="row.url" :preview-src-list="[row.url]" />
                    <div>{{row.name}}</div>
                </div>
                <el-link type="primary" v-else :href="row.url" :underline="false" target="_blank">{{row.name}}</el-link>
            </div>
            <div slot="footer">
                <el-button type="primary" @click="viewAnnexVisible = false" size="small">确 定</el-button>
            </div>
        </el-dialog>

    </div>
</div>
</template>

<script>
import CustomerNav from "@/components/CustomerNav.vue";
import dayjs from "dayjs";
import CompanyCard from "@/components/CompanyCard.vue"; //公司卡片
import ContactCard from "@/components/ContactCard.vue"; //联系人卡片
import ColleageCard from "@/components/ColleageCard.vue"; //同事卡片
import PowerSelect from "@/components/PowerSelect.vue";
export default {
    components: { CustomerNav, CompanyCard , ContactCard , ColleageCard, PowerSelect },
    props: {
        handleSelect: {
            type: Function,
            default: null,
        },
    },
    data() {
        return {
            currentPath: "/customerManage/dynamic",
            organizationKey: "",
            salekey: "",
            // 过滤字段
            filterParam: {
                "beginTime": dayjs().startOf('month').format('YYYY-MM-DD'),//开始时间
                "endTime": dayjs().endOf('month').format('YYYY-MM-DD'),//结束时间
                "customerName": "",//公司名称
                "content": "",//内容
                "type": "",//类型
            },
            //列表展示
            tableLoading: false,
            dynamicList: [],
            totalItems: 0,
            pageIndex: 1,
            pageSize: 10,
            //附件
            viewAnnexVisible: false,
            annexList: [],
        };
    },
    methods: {
        chooseNav(index){
            this.handleSelect(index);
            this.$router.push(index);
        },

        onRefresh(){
            this.filterParam = {
                "beginTime": dayjs().startOf('month').format('YYYY-MM-DD'),
                "endTime": dayjs().endOf('month').format('YYYY-MM-DD'),
                "customerName": "",
                "content": "",
                "type": "",
            };
            this.toSearch();
        },
        rowStyle({rowIndex}){
            // 表格隔行换色
            if(rowIndex % 2 == 1){
                return {'background-color':'#F9FAFC'}
            }
        },

        
        toSearch(){
            this.pageIndex = 1;
            this.getDynamic();
        },

        //获取动态列表
        getDynamic(){
            const {beginTime,endTime,customerName,content,type} = this.filterParam;
            this.tableLoading = true;
            this.$axios.post(`${this.$customerUrl}/Company/FindfollowRecordList`,{
                beginTime,endTime,customerName,content,type,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
                organizationKey: this.organizationKey,
                ukey: this.salekey,
            }).then((res) => {
                const { isSuccess , data } = res;
                this.tableLoading = false;
                if (isSuccess) {
                    this.dynamicList = data.datas;
                    this.totalItems = data.totalItems;
                }
            });
        },
        pageIndexChange(page) {
            this.pageIndex = page;
            this.getDynamic();
        },
        pageSizeChange() {
            this.pageIndex = 1;
            this.getDynamic();
        },

        //查看客户详情
        openCustomer(row) {
            let routeData = this.$router.resolve({
                path: "/customerManage/customerDetailsPrivate",
                query: { customerKey: row.customerKey },
            });
            window.open(routeData.href, "_blank");
        },
        //判断是否为图片文件
        isImageFile(fileName) {
            return ["png", "jpg", "jpeg"].includes(fileName.substring(fileName.lastIndexOf(".") + 1));
        },
        //查看附件内容
        viewAnnex(annex){
            this.viewAnnexVisible = true;
            this.annexList = annex;     //附件列表
        },

        //选择组织结构、员工
        onChoose(valueOne,valueTwo){
            this.organizationKey = valueOne;
            this.salekey = valueTwo;
            this.onRefresh();
        },


    },
    mounted() {
        this.getDynamic();    //获取动态列表
    },
};
</script>

<style lang="scss" scoped>
.viewbox{
    width: calc(100% - 200px);
    height: calc(100vh - 140px);
    background: rgba($color: #FFFFFF, $alpha: 0.7);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    margin-bottom: 60px;
    .boxOne,.boxTwo{
        overflow: hidden;
        padding: 8px 0;
        line-height: 32px;
        border-bottom: 1px solid #E6E7EB;
        .title{
            float: left;
            margin-left: 40px;
            font-size: 24px;
            font-weight: bold;
            color: #3A3A3A;
        }
        .txt{
            float: left;
            margin-left: 40px;
            font-size: 16px;
            color: #7C90A5;
        }
        .rightHandle{
            float: right;
            margin-right: 20px;
        }
    }

    .boxTable{
        background-color: #fff;
        .companyName{
            font-size: 14px;
            font-weight: bold;
        }
    }

    .bottomPagination{
        position: fixed;
        bottom: 0;
        right: 0;
        z-index: 10;
        width: calc(100vw - 200px);
        height: 60px;
        background: #FFFFFF;
        box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.16);
    }
}
</style>